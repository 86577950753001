const isNotSpecialMetric = (
  m: Metrics.Metric | undefined,
): m is
  | Metrics.NormalMetric
  | Metrics.SingleUseMetric
  | Metrics.CompoundMetric => {
  if (!m) {
    return false;
  }

  return 'type' in m ? m.type !== 'special' : false;
};

export default isNotSpecialMetric;
