import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import Colors2 from '../../theme/Colors2';
import Tooltip from '../Tooltip';
import { ReactComponent as LinkIcon } from './link.svg';

const EntityDetailsLinkButton = styled.div`
  display: flex;
  padding: 3px;
  border-radius: 2px;
  border: 1px solid transparent;
  cursor: pointer;

  &:hover {
    border: 1px solid ${Colors2.Grey['5']};
    background-color: white;
  }
`;

const EntityButton = styled(EntityDetailsLinkButton)`
  padding: 2px 1px;
  border-radius: 4px;
`;

const Interaction = ({
  text,
  onClick,
  tooltip,
}: {
  text: string;
  onClick: (event: any) => void;
  tooltip?: string;
}) => (
  <Tooltip content={tooltip}>
    <EntityButton onClick={onClick}>
      <span style={{ color: Colors2.Secondary.info }}>{text}</span>
    </EntityButton>
  </Tooltip>
);

const Link = ({
  to,
  tooltip,
  onClick,
}: {
  to: string;
  tooltip?: string;
  onClick?: () => void;
}) => (
  <RouterLink to={to} target="_blank" onClick={onClick}>
    <Tooltip content={tooltip}>
      <EntityDetailsLinkButton>
        <LinkIcon />
      </EntityDetailsLinkButton>
    </Tooltip>
  </RouterLink>
);

const EntityButtons = {
  Interaction,
  Link,
};

export default EntityButtons;
