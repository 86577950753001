import React from 'react';
import Card from 'components/Common/Card/index';
import Row from 'components/Common/Row';
import Loading from '../../Loading';
import Typography from 'kingpin/atoms/Typography';
import toSentenceCase from '../../../services/toSentenceCase';
import BaseDataToggleButton from 'components/DriverBonus/BaseDataToggleButton';
import styled from 'styled-components';
import FilterToggle from '../FilterToggle';
import NoDocumentsSrc from '../../../images/no-documents.svg';

const LinkButton = styled.div`
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const NoRecordsImg = styled.img`
  height: 120px;
`;

const BoardDataCardSection = ({
  cards,
  updateCardData,
  onShowMoreClicked,
  onShowLessClicked,
  dataType,
  scoringDataType,
  isLoading,
  filterLabel,
  isV2FilterOn,
  isFilterToggle,
  onFilterToggled,
  section,
}: {
  cards: DriverBonusSlideOutCard[];
  updateCardData: (
    cardId: string,
    newCardData: { [key: string]: string | undefined },
  ) => void;
  onShowMoreClicked: (cardId: string) => void;
  onShowLessClicked: (cardId: string) => void;
  dataType: string;
  scoringDataType?: string;
  isLoading: boolean;
  filterLabel?: string;
  isV2FilterOn: boolean;
  isFilterToggle?: boolean;
  onFilterToggled: (newStatus: boolean) => void;
  section: BoardSlideOutGridSection | BoardSlideOutDataSection;
}) => (
  <div>
    <Row spaceBetween centerAlign style={{ marginBottom: 8 }}>
      <Typography.Header type="H5">
        {section.label ? section.label : toSentenceCase(dataType)}
      </Typography.Header>
      {isFilterToggle && (
        <FilterToggle
          label={filterLabel}
          isV2FilterOn={isV2FilterOn}
          onIsV2FilterOnChanged={onFilterToggled}
        />
      )}
    </Row>
    {isLoading && (
      <div style={{ marginBottom: 22 }}>
        <Loading isSmall />
      </div>
    )}
    {!isLoading && (
      <div>
        {cards.length === 0 && (
          <Card
            style={{
              marginBottom: 22,
              padding: '18px',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              display: 'flex',
            }}
          >
            <NoRecordsImg src={NoDocumentsSrc} />
            <div style={{ marginTop: 24 }}>
              <Typography.Body type="Body 12">
                {section.dataSourceFilterV2 &&
                section.dataSourceFilterV2.isOnEmptyMessage &&
                isV2FilterOn
                  ? section.dataSourceFilterV2.label
                  : 'No records found'}
              </Typography.Body>
            </div>
          </Card>
        )}
        {cards.map((card) => (
          <Card key={card.id} style={{ marginBottom: 22, padding: '8px 18px' }}>
            <Row
              centerAlign
              style={{ justifyContent: 'flex-end', marginBottom: 8 }}
            >
              {scoringDataType && (
                <BaseDataToggleButton
                  id={card.id}
                  scoringDataType={scoringDataType}
                  isExcluded={card.isExcluded}
                  excludedBy={card.excludedBy}
                  updateCardData={updateCardData}
                />
              )}
            </Row>
            <div style={{ marginBottom: 22 }}>
              {Object.keys(card.data).map((field) => (
                <Row key={field}>
                  <div style={{ flex: 1 }}>
                    <Typography.Body type="Body 12">
                      {toSentenceCase(field)}
                    </Typography.Body>
                  </div>
                  <div style={{ flex: 2 }}>
                    <Typography.Body type="Body 12">
                      {card.data[field]}
                    </Typography.Body>
                  </div>
                </Row>
              ))}
            </div>
            {!section.disableShowMore && (
              <Row style={{ justifyContent: 'flex-end' }}>
                <LinkButton
                  onClick={
                    card.isShowingMore
                      ? () => onShowLessClicked(card.id)
                      : () => onShowMoreClicked(card.id)
                  }
                >
                  <Typography.Body type="Link">
                    {card.isShowingMore ? 'Show less' : 'Show more'}
                  </Typography.Body>
                </LinkButton>
              </Row>
            )}
          </Card>
        ))}
      </div>
    )}
  </div>
);

export default BoardDataCardSection;
