import React from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import Button from 'kingpin/atoms/Button';
import Typography from 'kingpin/atoms/Typography';
import Inputs from 'components/Inputs';

import WidgetsIcon from '../../../images/widgets.svg';
import Row from '../../Common/Row';
import EmptyState from '../../EmptyState';
import PermissionGates from '../../PermissionGates';
import PERMISSIONS from '../../../permissionDefinitions';

const EmptyDashboard = ({
  isOpen,
  open,
  close,
  isDisabled,
  widgetOptions,
  onAddClicked,
}: {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  isDisabled: boolean;
  widgetOptions: RadioOption[];
  onAddClicked: () => void;
}) => (
  <React.Fragment>
    <PermissionGates.Has
      requiredPermission={PERMISSIONS.DASHBOARDS.EDIT}
      fallback={
        <EmptyState
          text={'There are no widgets to display'}
          iconSrc={WidgetsIcon}
        />
      }
    >
      <EmptyState
        text={'There are no widgets to display'}
        iconSrc={WidgetsIcon}
        buttonText={'Add Elements'}
        onButtonClicked={isOpen ? close : open}
      />
    </PermissionGates.Has>
    <ModalTransition>
      {isOpen && (
        <Modal onClose={close} shouldScrollInViewport={false} autoFocus={false}>
          <div style={{ marginTop: 24, marginBottom: 24, padding: '0px 16px' }}>
            <Typography.Header type="H5">Add Element</Typography.Header>
            <Inputs.Dropdown options={widgetOptions} />
            <Row style={{ marginTop: 32 }} spaceBetween>
              <Button
                onClick={close}
                type="Ghost"
                size="Small"
                label="Cancel"
              />
              <Button
                onClick={onAddClicked}
                isDisabled={isDisabled}
                type="Primary"
                size="Small"
                label="Add"
              />
            </Row>
          </div>
        </Modal>
      )}
    </ModalTransition>
  </React.Fragment>
);

export default EmptyDashboard;
