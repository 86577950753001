import React, { ChangeEvent, useCallback, useState } from 'react';
import firebase from 'firebase/compat/app';
import Inputs from 'components/Inputs';
import Button from 'kingpin/atoms/Button';
import Typography from 'kingpin/atoms/Typography';
import InputError from '../../components/InputError';
import ShowPassword from '../../components/ShowPassword';

const EmailPasswordLoginForm = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [isHidingPassword, setIsHidingPassword] = useState<boolean>(true);
  const [generalError, setGeneralError] = useState<string | undefined>();
  const [emailError, setEmailError] = useState<string | undefined>();
  const [passwordError, setPasswordError] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onEmailChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }, []);

  const onPasswordChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setPassword(event.target.value);
    },
    [],
  );

  const onSubmit = useCallback(() => {
    setEmailError(undefined);
    setPasswordError(undefined);
    setGeneralError(undefined);

    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => firebase.auth().signInWithEmailAndPassword(email, password))
      .then(() => {
        setIsLoading(false);
      })
      .catch((error: firebase.FirebaseError) => {
        if (error.code === 'auth/invalid-email') {
          setEmailError('This email format is invalid');
        } else if (
          error.code === 'auth/user-not-found' ||
          error.code === 'auth/wrong-password'
        ) {
          setPasswordError('You have entered an invalid username or password');
        } else if (error.code === 'auth/too-many-requests') {
          setGeneralError(
            'We have detected unusual activity and temporarily locked your' +
              ' account. Please try again later',
          );
        } else {
          setGeneralError(
            'We could not log you in. Please contact customer support',
          );
        }
        setIsLoading(false);
      });
  }, [email, password]);

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <Typography.Header type="H5">Email</Typography.Header>
        <Inputs.TextInput
          value={email}
          onChange={onEmailChanged}
          style={{ marginBottom: emailError ? 4 : 0 }}
          type="email"
          name="email"
          autoComplete={'true'}
          inputSize="Medium"
        />
        {emailError && <InputError text={emailError} />}
      </div>
      <div style={{ marginBottom: 16 }}>
        <Typography.Header type="H5">Password</Typography.Header>
        <Inputs.TextInput
          value={password}
          onChange={onPasswordChanged}
          style={{ marginBottom: passwordError ? 4 : 0 }}
          name="password"
          type={isHidingPassword ? 'password' : 'text'}
          autoComplete={'true'}
          inputSize="Medium"
        />
        {passwordError && <InputError text={passwordError} />}
        <ShowPassword
          isHiding={isHidingPassword}
          setIsHiding={setIsHidingPassword}
        />
      </div>
      <div>
        {generalError && <InputError text={generalError} />}
        <Button
          label="Log In"
          onClick={onSubmit}
          isLoading={isLoading}
          type="Primary"
          size="Small"
        />
      </div>
    </div>
  );
};

export default EmailPasswordLoginForm;
