import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

const buildQuery = (modelType: Costs.ModelType) => ({
  query: gql`
    query getGroupFields($modelType: CostModelType!) {
      getGroupFields(modelType: $modelType) {
        dataType
        fields
      }
    }
  `,
  variables: {
    modelType,
  },
});

const getGroupFields = async (
  modelType: Costs.ModelType,
  client: ApolloClient<NormalizedCacheObject>,
): Promise<Costs.CohortFieldsResult> => {
  const gqlQuery = buildQuery(modelType);
  const response = await client.query(gqlQuery);
  return response.data.getGroupFields;
};

export default getGroupFields;
