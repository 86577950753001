import firebase from 'firebase/compat/app';
import STORE_CONSTANTS from '../constants';

export const SINGLE_METRIC_DATE_MATRICES_DOC = 'singleMetricDateMatrices';
export const CURRENT_METRIC_DATE_MATRICES_VERSION = 'v1';

const singleMetricDateMatrixConverter = {
  toFirestore(
    matrix: VisualisationDefinitions.SingleMetricDateMatrix,
  ): firebase.firestore.DocumentData {
    return { ...matrix };
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ): VisualisationDefinitions.SingleMetricDateMatrix {
    const data = {
      ...snapshot.data(options),
      id: snapshot.id,
    };
    return data as VisualisationDefinitions.SingleMetricDateMatrix;
  },
};

const getSingleMetricDateMatricesRef = ({
  accountId,
  db,
}: {
  accountId: string;
  db?: firebase.firestore.Firestore;
}) => {
  return (db ? db : firebase.firestore())
    .collection('accounts')
    .doc(accountId)
    .collection(STORE_CONSTANTS.VISUALISATIONS_COLLECTION)
    .doc(SINGLE_METRIC_DATE_MATRICES_DOC)
    .collection(CURRENT_METRIC_DATE_MATRICES_VERSION)
    .withConverter(singleMetricDateMatrixConverter);
};

export default getSingleMetricDateMatricesRef;
