import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import LandingPage from '../../components/LandingPage';
import Branding from '../../components/Branding';
import Card from '../../components/Common/Card';
import Row from '../../components/Common/Row';
import { LogInLink } from '../../components/ForgotPasswordLink/ForgotPasswordLink';
import Colors from '../../theme/colors';
import Button from '../../kingpin/atoms/Button';
import Typography from 'kingpin/atoms/Typography';
import TextInput from '../../kingpin/atoms/TextInput';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .iycCLd {
    background-color: ${Colors.WHITE} !important;
  }
`;

const Title = styled.h1`
  margin-bottom: 24px;
`;

const ButtonWrapper = styled.div`
  margin-top: 24px;
`;

const cardStyle = (useMobileLayout: boolean) => {
  if (useMobileLayout) {
    return {
      width: '100%',
    };
  }
  return {
    width: 466,
    padding: '24px 40px',
  };
};

const WallBoardSetUp = ({
  accessCode,
  onAccessCodeChange,
  onLogInClicked,
  isLoading,
}: {
  accessCode: string;
  onAccessCodeChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onLogInClicked: () => void;
  isLoading: boolean;
}) => (
  <LandingPage>
    <React.Fragment>
      <Branding />
      <Card style={cardStyle(false)}>
        <Layout>
          <Title>
            <Typography.Header type="H1">Access Wall Board</Typography.Header>
          </Title>
        </Layout>
        <div style={{ width: '100%', marginBottom: 32 }}>
          <TextInput
            label={'Access Code'}
            value={accessCode}
            onChange={onAccessCodeChange}
          />
        </div>
        <ButtonWrapper style={{ width: '100%' }}>
          <Button
            onClick={onLogInClicked}
            type="Secondary"
            size="Small"
            isLoading={isLoading}
            label="Start"
          />
        </ButtonWrapper>
        <Row spaceBetween centerAlign>
          <LogInLink />
        </Row>
      </Card>
    </React.Fragment>
  </LandingPage>
);

export default WallBoardSetUp;
