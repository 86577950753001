import React from 'react';
import sectionTypeCheckers from '../sectionTypeCheckers';
import Performance from '../Sections/Performance';
import Metric from '../Sections/Metric';
import Grid from '../Sections/Grid';
import Properties from '../Sections/Properties';
import Comments from 'components/Comments';
import useCommentsWidgetProps from 'components/PerformanceBoards/hooks/useCommentsWidgetProps';
import TargetProgress from '../Sections/TargetProgress';

const DisplaySection = ({
  section,
}: {
  section: PerformanceBoardTypes.SlideOut.SlideOutSection;
}) => {
  const {
    commentableId,
    commentableType,
    emailLink,
    mentionedEmailBody,
    watcherEmailBody,
  } = useCommentsWidgetProps();
  if (sectionTypeCheckers.isPerformanceSection(section)) {
    return <Performance section={section} />;
  }
  if (sectionTypeCheckers.isTargetProgressSection(section)) {
    return <TargetProgress section={section} />;
  }
  if (sectionTypeCheckers.isMetricSection(section)) {
    return <Metric section={section} />;
  }
  if (sectionTypeCheckers.isGridSection(section)) {
    return <Grid section={section} />;
  }
  if (sectionTypeCheckers.isPropertiesSection(section)) {
    return <Properties section={section} />;
  }
  if (
    sectionTypeCheckers.isCommentSection(section) &&
    commentableId &&
    commentableType &&
    emailLink
  ) {
    return (
      <Comments
        commentableId={commentableId}
        commentableType={commentableType}
        mentionedEmailBody={mentionedEmailBody}
        watcherEmailBody={watcherEmailBody}
        emailLink={emailLink}
      />
    );
  }
  return null;
};

export default DisplaySection;
