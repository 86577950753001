import React from 'react';
import Row from 'components/Common/Row';
import Typography from 'kingpin/atoms/Typography';
import Colors2 from 'theme/Colors2';
import { COSTS_COL_WIDTH, COSTS_LIST_ITEM_HEIGHT } from '../consts';
import { Col } from './ActivityGrid';
import useHoverRow from './hooks/useHoverRow';

const GroupItemLeft = ({ label }: { label: string }) => {
  const { disableHover } = useHoverRow();

  return (
    <div
      onMouseEnter={disableHover}
      style={{
        backgroundColor: '#f4f4f4',
        borderBottom: `1px solid ${Colors2.GridBorder}`,
        borderLeft: `1px solid ${Colors2.GridBorder}`,
      }}
    >
      <Col
        maxWidth
        style={{
          position: 'sticky',
          height: `${COSTS_LIST_ITEM_HEIGHT - 1}px`,
        }}
      >
        <Col maxWidth>
          <Row centerAlign>
            <div
              style={{
                marginLeft: '16px',
                paddingRight: '24px',
                maxWidth: `${COSTS_COL_WIDTH - 8}px`,
                overflow: 'hidden',
              }}
            >
              <Typography.Body isEllipsis type="Label">
                {label}
              </Typography.Body>
            </div>
          </Row>
        </Col>
      </Col>
    </div>
  );
};

export default GroupItemLeft;
