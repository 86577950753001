import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import useFieldDefinitions from '../../../../../../hooks/useFieldDefinitions';
import AnalyticsContext from '../../../../../../contexts/AnalyticsContext';

const useFieldOptions = ({
  selectedMetric,
  setField,
  selectedDataset,
  aggFunc,
  setAggFunc,
}: {
  selectedMetric?: Metrics.NormalMetric;
  setField: React.Dispatch<React.SetStateAction<string>>;
  selectedDataset?: string;
  aggFunc: AggFunc | undefined;
  setAggFunc: React.Dispatch<React.SetStateAction<AggFunc | undefined>>;
}) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const [dataSourceForFieldFilter, setDataSourceForFieldFilter] = useState<
    string[] | undefined
  >(selectedMetric ? [selectedMetric.dataType] : undefined);
  const { fieldDefinitions } = useFieldDefinitions(dataSourceForFieldFilter);
  const [fieldOptions, setFieldOptions] = useState<DropdownOption[]>([]);
  useEffect(() => {
    if (selectedDataset) {
      setDataSourceForFieldFilter([selectedDataset]);
    }
  }, [selectedDataset]);

  useEffect(() => {
    setFieldOptions(
      _.sortBy(
        _.uniqBy(fieldDefinitions, 'field').map((f) => {
          return {
            label: f.alias ? f.alias : f.field,
            value: f.field,
            onSelected: () => {
              if (aggFunc && !f.aggFuncs.includes(aggFunc)) {
                setAggFunc(undefined);
              }
              setField(f.field);
              trackEvent('Metric Builder - Field selected', { field: f.field });
            },
          };
        }),
        'label',
      ),
    );
  }, [aggFunc, fieldDefinitions, setAggFunc, setField, trackEvent]);

  return fieldOptions;
};

export default useFieldOptions;
