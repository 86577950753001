import numberToCommaString from 'hooks/useValueFormatters/numberToCommaString';
import formatFloat from '../../api/getChartSeries/formatFloat';
import { ValueFormatterFunc, ValueFormatterParams } from 'ag-grid-community';

const isValid = (params: ValueFormatterParams<any>) => {
  if (typeof params.value === 'undefined' || params.value === null) {
    return false;
  }
  return true;
};

export const defaultFormatter: ValueFormatterFunc = (params) => {
  if (params && isValid(params)) {
    if (Array.isArray(params.value)) {
      if (params.value.length > 0 && typeof params.value[0] === 'object') {
        return '-';
      }

      return params.value.join(', ');
    } else {
      if (typeof params.value === 'object') {
        return '-';
      }

      if (typeof params.value === 'number') {
        return numberToCommaString({ value: formatFloat(params.value, 2) });
      } else {
        return params.value;
      }
    }
  }
  return '-';
};
