import React from 'react';

import PermissionGates from '../../../../../components/PermissionGates';
import PERMISSIONS from '../../../../../permissionDefinitions';
import WizardItem from './WizardItem';
import ConfigItem from './ConfigItem';
import DatasetItem from './DatasetItem';

const ListItemContainer = ({ item }: { item: DataManager.DatasetListItem }) => {
  if (item.type === 'savedWizard') {
    return (
      <PermissionGates.Has
        requiredPermission={
          PERMISSIONS.DATA_MANAGEMENT.UPDATE_PERFORMANCE_DATASET_CONFIG
        }
      >
        <WizardItem item={item.content} />
      </PermissionGates.Has>
    );
  } else if (item.type === 'performanceConfig') {
    return <ConfigItem item={item.content} />;
  } else if (item.type === 'baseView') {
    return <DatasetItem baseView={item.content} />;
  }

  return null;
};

export default ListItemContainer;
