import React, { useCallback, useContext, useState } from 'react';
import STORE from 'store';
import AccountPickerContext from 'contexts/AccountPickerContext';
import ManageAccessModal from './ManageAccessModal';
import CurrentUserContext from '../../../contexts/CurrentUserContext';
import getTimeStamp from '../../../getTimeStamp';
import portalTypeCheckers from '../../../types/portalTypeCheckers';

const ManageAccessModalContainer = ({
  type,
  typeId,
  contentName,
  close,
  isPublished,
}: {
  type: ExecutivePortalPublishableContentType;
  typeId: string;
  contentName: string;
  close: () => void;
  isPublished: boolean;
}) => {
  const { id: currentUserId } = useContext(CurrentUserContext);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedPortals, setSelectedPortals] = useState<
    (ExecutivePortal | EngagementPortal)[]
  >([]);
  const [successMessage, setSuccessMessage] = useState<string | undefined>();

  const showSuccessMessage = useCallback(() => {
    const message =
      selectedPortals.length === 1
        ? `Published to '${selectedPortals[0].name}' Portal`
        : `Published to ${selectedPortals.length} Portals`;
    setSuccessMessage(message);
    setTimeout(() => {
      setSuccessMessage(undefined);
    }, 10000);
  }, [selectedPortals]);

  const onPublishClicked = useCallback(async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    const promises = selectedPortals.map((portal) => {
      const newPortal: EngagementPortal | ExecutivePortal = {
        ...portal,
        reportIds: [...portal.reportIds],
        dashboardIds: [...portal.dashboardIds],
        scorecardIds: [...portal.scorecardIds],
        updatedBy: currentUserId,
        updatedOn: getTimeStamp(),
      };
      if (type === 'report') {
        newPortal.reportIds.push(typeId);
      }
      if (type === 'dashboard') {
        newPortal.dashboardIds.push(typeId);
      }
      if (type === 'scorecard') {
        newPortal.scorecardIds.push(typeId);
      }

      if (portalTypeCheckers.isEngagementPortal(newPortal)) {
        return STORE.contentDistributions
          .getEngagementPortalsRef({ accountId: selectedAccountId })
          .doc(portal.id)
          .set(newPortal);
      } else {
        return STORE.contentDistributions
          .getExecutivePortalsRef({ accountId: selectedAccountId })
          .doc(portal.id)
          .set(newPortal);
      }
    });

    await Promise.all(promises);
    showSuccessMessage();
    setSelectedPortals([]);
    setIsLoading(false);
  }, [
    currentUserId,
    isLoading,
    selectedAccountId,
    selectedPortals,
    showSuccessMessage,
    type,
    typeId,
  ]);

  return (
    <ManageAccessModal
      selectedPortals={selectedPortals}
      setSelectedPortals={setSelectedPortals}
      isPublished={isPublished}
      contentName={contentName}
      type={type}
      typeId={typeId}
      isLoading={isLoading}
      successMessage={successMessage}
      onPublishClicked={onPublishClicked}
      close={close}
    />
  );
};

export default ManageAccessModalContainer;
