import React, { useCallback, useContext, useEffect, useState } from 'react';
import { COSTS_LIST_ITEM_HEIGHT } from '../consts';
import { Col, CostRowElement } from './ActivityGrid';
import { CellPreview } from './CategoryItemRight';
import Typography from 'kingpin/atoms/Typography';
import ActivityGridContext from '../context/ActivityGridContext';
import CostsShowContext from '../context/CostsShowContext';
import Colors2 from 'theme/Colors2';
import useValueFormatters from 'hooks/useValueFormatters';
import useHoverRow from './hooks/useHoverRow';
import Loading from 'components/Loading/Loading';

const TotalItemRightInterval = ({
  group,
  interval,
}: {
  group: Costs.Group;
  interval: Period;
}) => {
  const { intervals, getCostValue, getCpmValue, focusedInterval } =
    useContext(ActivityGridContext);
  const { costModel } = useContext(CostsShowContext);
  const { formatValue } = useValueFormatters();
  const [value, setValue] = useState<{
    totalCost: string;
    totalCostPerMile: string;
  }>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getTotal = useCallback(
    async ({ interval, group }: { interval: Period; group: Costs.Group }) => {
      if (!costModel) {
        return {
          totalCost: '0',
          totalCostPerMile: '0',
        };
      }

      let totalCost = 0;
      let totalCostPerMile = 0;

      for (const category of costModel.categories) {
        const costValue = getCostValue({
          startDate: interval.startDate,
          groupDefinition: group,
          costFieldName: category.costFieldName,
        });
        const cpmValue = await getCpmValue({
          startDate: interval.startDate,
          groupDefinition: group,
          costFieldName: category.costFieldName,
        });
        const cost = costValue || 0;
        const costPerMile = cpmValue || 0;

        totalCost += cost;
        totalCostPerMile += costPerMile;
      }

      const formatting = {
        prefix: 'currency',
        currencyType: 'USD',
        precision: 0,
      } as MetricFormatting;
      const valueTotalCost = isNaN(Number(totalCost)) ? 0 : totalCost;
      const formattedTotalCost = formatValue({
        value: valueTotalCost,
        formatting,
      });
      const valueTotalCostPerMile = isNaN(Number(totalCostPerMile))
        ? 0
        : totalCostPerMile;
      const formattedTotalCostPerMile = formatValue({
        value: valueTotalCostPerMile,
        formatting: {
          ...formatting,
          precision: 3,
        },
      });

      return {
        totalCost: formattedTotalCost,
        totalCostPerMile: formattedTotalCostPerMile,
      };
    },
    [costModel, formatValue, getCostValue, getCpmValue],
  );

  useEffect(() => {
    let isActive = true;

    getTotal({ interval, group }).then((newValue) => {
      if (isActive) {
        setValue(newValue);
        setIsLoading(false);
      }
    });
    return () => {
      isActive = false;
    };
  }, [getTotal, group, interval]);

  const isLastInterval =
    interval.startDate === intervals[intervals.length - 1].startDate;

  return (
    <Col minWidth key={`${interval.label}-${group ? group.groupName : ''}`}>
      {!isLastInterval && (
        <div
          style={{
            height: `${COSTS_LIST_ITEM_HEIGHT}px`,
            flex: 1,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <CellPreview
            style={{
              backgroundColor:
                focusedInterval === interval ? '#F9F9F9' : '#F9F9F9',
            }}
          >
            {isLoading && (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Loading isTiny />
              </div>
            )}
            {!isLoading && (
              <div
                style={{
                  marginRight:
                    interval === intervals[intervals.length - 1]
                      ? '4px'
                      : '13px',
                  display: 'flex',
                }}
              >
                <Typography.Body
                  type="Body 12"
                  color={
                    interval === intervals[intervals.length - 1]
                      ? Colors2.Grey['3']
                      : undefined
                  }
                >
                  {value ? value.totalCost : 0}
                </Typography.Body>
              </div>
            )}
          </CellPreview>
        </div>
      )}

      <div
        style={{
          height: `${COSTS_LIST_ITEM_HEIGHT}px`,
          flex: 1,
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <CellPreview
          style={{
            backgroundColor:
              focusedInterval === interval ? '#F9F9F9' : '#F9F9F9',
            borderRight: `1px solid ${Colors2.Grey['7']}`,
          }}
        >
          {isLoading && (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Loading isTiny />
            </div>
          )}
          {!isLoading && (
            <div style={{ marginRight: 4, display: 'flex' }}>
              <Typography.Body
                type="Body 12"
                color={
                  interval === intervals[intervals.length - 1]
                    ? Colors2.Grey['3']
                    : undefined
                }
              >
                {value ? value.totalCostPerMile : 0}
              </Typography.Body>
            </div>
          )}
        </CellPreview>
      </div>
    </Col>
  );
};

const TotalItemRight = ({ group }: { group: Costs.Group }) => {
  const { intervals } = useContext(ActivityGridContext);
  const { disableHover } = useHoverRow();

  return (
    <CostRowElement centerAlign onMouseEnter={disableHover}>
      {intervals.map((interval) => {
        return (
          <TotalItemRightInterval
            key={`${interval.label}-${group ? group.groupName : ''}`}
            group={group}
            interval={interval}
          />
        );
      })}
    </CostRowElement>
  );
};

export default TotalItemRight;
