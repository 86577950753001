import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import Typography from '../../kingpin/atoms/Typography';
import OmniSearch from './OmniSearch';
import multiFieldSearch from '../../api/multiFieldSearch';
import usePopup from '../../hooks/usePopup';
import { DESTINATION_ZIP_CODE, ORIGIN_ZIP_CODE } from './constants';
import GqlClientContext from '../../contexts/GqlClientContext';
import Icon from '../../kingpin/atoms/Icon';
import Colors2 from '../../theme/Colors2';
import useDateScope from '../../hooks/useDateScope';
import { CommitmentQueryInputButtonDiv } from '../CustomerLaneCommitsBoard/NewCommitPopup/CustomerSearch/CustomerSearch';

const ManualBuilder = ({
  searchFields,
  field,
  setField,
  value,
  setValue,
  filterInput,
  searchPlaceholder,
  isDisabled,
}: {
  searchFields: string[];
  field?: string;
  setField?: (f: string | undefined) => void;
  value?: string;
  setValue: (v: string | undefined) => void;
  filterInput: FilterInput;
  searchPlaceholder?: string;
  isDisabled?: boolean;
}) => {
  const { isOpen, open, close } = usePopup();

  const { client } = useContext(GqlClientContext);
  const dateScope = useDateScope({});
  const [searchText, setSearchText] = useState<string>('');
  const [searchResults, setSearchResults] = useState<MultiFieldSearchResults>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const onSearchTextChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    open();
  };

  useEffect(() => {
    setIsLoading(true);
    multiFieldSearch({
      fields: searchFields,
      value: searchText,
      filters: [filterInput],
      dateScope,
      client,
    }).then((results) => {
      if (
        searchFields.includes(ORIGIN_ZIP_CODE) &&
        !!results[ORIGIN_ZIP_CODE]
      ) {
        const hasSearchTextValue = results[ORIGIN_ZIP_CODE].find(
          (value) => value === searchText,
        );
        if (hasSearchTextValue) {
          setSearchResults(results);
          setIsLoading(false);
        } else {
          const newResults = {
            ...results,
            [ORIGIN_ZIP_CODE]: [`${searchText}*`, ...results[ORIGIN_ZIP_CODE]],
          };

          setSearchResults(newResults);
          setIsLoading(false);
        }
      } else if (
        searchFields.includes(DESTINATION_ZIP_CODE) &&
        !!results[DESTINATION_ZIP_CODE]
      ) {
        const hasSearchTextValue = results[DESTINATION_ZIP_CODE].find(
          (value) => value === searchText,
        );
        if (hasSearchTextValue) {
          setSearchResults(results);
          setIsLoading(false);
        } else {
          const newResults = {
            ...results,
            [DESTINATION_ZIP_CODE]: [
              `${searchText}*`,
              ...results[DESTINATION_ZIP_CODE],
            ],
          };
          setSearchResults(newResults);
          setIsLoading(false);
        }
      } else {
        setSearchResults(results);
        setIsLoading(false);
      }
    });
  }, [client, dateScope, filterInput, searchFields, searchText]);

  const onSearchItemClicked = (field: string, value: string) => {
    if (setField) {
      setField(field);
    }
    setValue(value);
    close();
  };

  const onSearchFocus = () => {
    open();
  };

  const onSearchBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    // @ts-ignore
    if (event.relatedTarget && event.relatedTarget.nodeName === 'INPUT') {
      close();
    }
  };

  const onRemove = () => {
    if (setField) {
      setField(undefined);
    }
    setValue(undefined);
  };

  const isFilled = (setField ? !!field : true) && !!value;

  const selectedSearchResults = {} as MultiFieldSearchResults;
  if (field && value) {
    selectedSearchResults[field] = [value];
  }

  if (isDisabled && !isFilled) {
    return null;
  }

  if (isDisabled || isFilled) {
    return (
      <CommitmentQueryInputButtonDiv
        style={{
          cursor: isDisabled ? 'not-allowed' : 'auto',
        }}
      >
        <Typography.Body type={'Body 12'}>{value}</Typography.Body>
        {!isDisabled && (
          <div
            style={{ padding: 4, marginTop: 3, cursor: 'pointer' }}
            onClick={onRemove}
          >
            <Icon icon="cross" color={Colors2.Grey['4']} />
          </div>
        )}
      </CommitmentQueryInputButtonDiv>
    );
  }

  return (
    <React.Fragment>
      <OmniSearch
        searchText={searchText}
        onSearchTextChanged={onSearchTextChanged}
        searchFields={searchFields}
        searchResults={searchResults}
        isLoading={isLoading}
        onSearchItemClicked={onSearchItemClicked}
        selectedSearchResults={selectedSearchResults}
        isOpen={isOpen}
        close={close}
        autoFocus={false}
        onSearchFocus={onSearchFocus}
        onSearchBlur={onSearchBlur}
        searchPlaceholder={searchPlaceholder}
      />
    </React.Fragment>
  );
};

export default ManualBuilder;
