import useMetric from './useMetric';
import { useCallback, useEffect, useState } from 'react';

const useV5RollingTrendCardDefinition = (chartDef: V5ChartDefinition) => {
  const getDefinition = useCallback(() => {
    return {
      ...chartDef,
      dimensionA: undefined,
      dimensionB: undefined,
      series: [chartDef.series[0]],
      trendByCalendarInterval: undefined,
      trendByFixedIntervalDays: 30,
    };
  }, [chartDef]);

  const [definition, setDefinition] = useState<V5ChartDefinition>(() =>
    getDefinition(),
  );
  const metricId = chartDef.series[0].metricId;
  const metric = useMetric(metricId);

  if (!metric) {
    throw new Error(`Metric ${metricId} not found`);
  }

  useEffect(() => {
    setDefinition(getDefinition());
  }, [getDefinition]);

  return {
    fixedTrendCardDefinition: definition,
  };
};

export default useV5RollingTrendCardDefinition;
