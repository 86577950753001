import React, { ChangeEvent } from 'react';
import Button from 'kingpin/atoms/Button';
import {
  GadgetFormInner,
  GadgetFormPreview,
  GadgetFormWrapper,
} from '../V5GadgetForm/V5GadgetForm';
import { Section } from '../MyAccount/Profile/styles';
import Typography from 'kingpin/atoms/Typography';
import Inputs from '../Inputs';
import Row from '../Common/Row';
import RemindersGadgetPreview from './RemindersGadgetPreview';

const RemindersGadgetForm = ({
  isGadgetBuilder,
  // Base
  name,
  onNameChanged,
  description,
  onDescriptionChanged,
  // Reminders
  eventOptions,
  // Misc
  isValid,
  isLoading,
  onSave,
  draftGadget,
  selectedEventLabels,
}: {
  isGadgetBuilder?: boolean;
  // Base
  name: string;
  onNameChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  description: string;
  onDescriptionChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  // Gauge
  eventOptions: DropdownOption[];
  // Misc
  isValid: boolean;
  isLoading: boolean;
  onSave: () => void;
  draftGadget?: VisualisationDefinitions.Reminders;
  selectedEventLabels: string;
}) => (
  <GadgetFormWrapper>
    <GadgetFormInner isGadgetBuilder={!!isGadgetBuilder}>
      <Section>
        <Typography.Header type="H4">Reminders</Typography.Header>
      </Section>
      {/* Base */}
      <Section>
        <Typography.Body type="Label">Name</Typography.Body>
        <Inputs.TextInput
          placeholder="Name"
          value={name}
          onChange={onNameChanged}
          data-testid={'reminders-name'}
        />
      </Section>
      <Section>
        <Typography.Body type="Label">Description</Typography.Body>
        <Inputs.TextInput
          placeholder="Description"
          value={description}
          onChange={onDescriptionChanged}
        />
      </Section>
      {/* Gauge */}
      <Section>
        <Typography.Body type="Label">Events</Typography.Body>
        <Inputs.Dropdown
          isMulti
          options={eventOptions}
          selectedLabel={selectedEventLabels}
          testId={'reminders-events'}
        />
      </Section>
      {isGadgetBuilder && (
        <Section>
          <Row centerAlign style={{ justifyContent: 'flex-end' }}>
            <Button
              onClick={onSave}
              isDisabled={!isValid}
              isLoading={isLoading}
              type="Primary"
              size="Small"
              label="Save"
            />
          </Row>
        </Section>
      )}
    </GadgetFormInner>
    {isGadgetBuilder && isValid && (
      <GadgetFormPreview>
        {draftGadget && <RemindersGadgetPreview gadget={draftGadget} />}
      </GadgetFormPreview>
    )}
  </GadgetFormWrapper>
);

export default RemindersGadgetForm;
