import React from 'react';
import ModalDialog, { ModalTransition } from '@atlaskit/modal-dialog';
import InviteUsersForm from '../../InviteUsersForm';
import { UnsavedChangesConfirmation } from '../../ConfirmationModal';
import Button from '../../../kingpin/atoms/Button';
import SeatsInfo from './SeatsInfo';

const NewUserButton = ({
  open,
  close,
  isOpen,
  hasUnsavedChangesRef,
  isConfirmingClose,
  onModalClose,
  setIsConfirmingClose,
  hasSeatsRemaining,
}: {
  open: () => void;
  close: () => void;
  isOpen: boolean;
  hasUnsavedChangesRef: React.MutableRefObject<boolean>;
  isConfirmingClose: boolean;
  onModalClose: () => void;
  setIsConfirmingClose: React.Dispatch<React.SetStateAction<boolean>>;
  hasSeatsRemaining: boolean;
}) => {
  return (
    <>
      <div style={{ padding: '16px 10px' }}>
        <Button
          size="Small"
          type="Primary"
          onClick={open}
          label={'New User'}
          isDisabled={!hasSeatsRemaining}
          isDisabledMessage={`You have no seats remaining`}
        />
        <ModalTransition>
          {isOpen && (
            <ModalDialog
              onClose={onModalClose}
              shouldScrollInViewport={false}
              autoFocus={false}
              width={1200}
            >
              <InviteUsersForm
                close={close}
                hasUnsavedChangesRef={hasUnsavedChangesRef}
              />
            </ModalDialog>
          )}
        </ModalTransition>
        {isOpen && (
          <UnsavedChangesConfirmation
            isOpen={isConfirmingClose}
            close={() => {
              setIsConfirmingClose(false);
            }}
            onConfirmed={close}
          />
        )}
        <SeatsInfo />
      </div>
    </>
  );
};

export default NewUserButton;
