import React from 'react';

import FlexCentered from './Common/FlexCentered';
import { Img } from '../screens/LegacyHome/RecentsCard/RecentsCard';
import ResourceIcon from '../images/report-icon.svg';
import Typography from 'kingpin/atoms/Typography';

const NoIndexItems = ({ message }: { message: string }) => (
  <FlexCentered style={{ height: '100%' }}>
    <Img src={ResourceIcon} />
    <Typography.Body type="Body 14">{message}</Typography.Body>
  </FlexCentered>
);

export default NoIndexItems;
