import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';

import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

import config from '../../firebaseConfig/config.json';
import Loading from '../Loading/Loading';
import FlexCentered from './FlexCentered';

const FirebaseGate = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [firebaseReady, setFirebaseReady] = useState<boolean>(false);
  useEffect(() => {
    if (!firebase.apps.length) {
      firebase.initializeApp(config);
    }
    setFirebaseReady(true);
  }, []);

  if (!firebaseReady) {
    return (
      <FlexCentered style={{ height: '100vh' }}>
        <Loading isGrouped />
      </FlexCentered>
    );
  }

  return <>{children}</>;
};

export default FirebaseGate;
