import React, { useEffect, useState } from 'react';
import useGetFilterType from './useGetFilterType';

const useConditionOptions = (
  field: string | undefined,
  condition: FilterBuilder.Condition | undefined,
  setCondition: React.Dispatch<
    React.SetStateAction<FilterBuilder.Condition | undefined>
  >,
) => {
  const [options, setOptions] = useState<DropdownOption[]>([]);
  const getFilterType = useGetFilterType();

  useEffect(() => {
    if (field === undefined) {
      setOptions([]);
      return;
    }

    const filterType = getFilterType(field);
    if (filterType === 'number') {
      setOptions([
        {
          label: 'Equals',
          isSelected: condition === 'is',
          onSelected: () => {
            setCondition('is');
          },
        },
        {
          label: 'Greater than or equal to',
          isSelected: condition === 'gte',
          onSelected: () => {
            setCondition('gte');
          },
        },
        {
          label: 'Less than or equal to',
          isSelected: condition === 'lte',
          onSelected: () => {
            setCondition('lte');
          },
        },
        {
          label: 'Greater than',
          isSelected: condition === 'gt',
          onSelected: () => {
            setCondition('gt');
          },
        },
        {
          label: 'Less than',
          isSelected: condition === 'lt',
          onSelected: () => {
            setCondition('lt');
          },
        },
      ]);
      return;
    }

    if (filterType === 'text') {
      setOptions([
        {
          label: 'in',
          isSelected: condition === 'in',
          onSelected: () => {
            setCondition('in');
          },
        },
      ]);
      return;
    }

    if (filterType === 'boolean') {
      setOptions([
        {
          label: 'is',
          isSelected: condition === 'is',
          onSelected: () => {
            setCondition('is');
          },
        },
        {
          label: 'is not',
          isSelected: condition === 'is_not',
          onSelected: () => {
            setCondition('is_not');
          },
        },
      ]);
      return;
    }
  }, [condition, field, getFilterType, setCondition]);

  return options;
};

export default useConditionOptions;
