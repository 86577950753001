/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import ScalingNumber from './ScalingNumber';
import UNIT_LOCALES from '../../propTypes/unitLocales';
import useValueFormatters from '../../hooks/useValueFormatters';

const ScalingNumberContainer = ({
  number,
  metricId,
  center,
  color,
}: {
  number: number;
  metricId: string;
  center?: boolean;
  color?: string;
}) => {
  const { formatMetric } = useValueFormatters();
  const formattedNumber = formatMetric({
    metricId,
    value: number,
  });

  return (
    <ScalingNumber
      formattedNumber={formattedNumber}
      center={center}
      color={color}
    />
  );
};

ScalingNumberContainer.propTypes = {
  number: PropTypes.number,
  unitsLocale: PropTypes.oneOf([UNIT_LOCALES.METRIC, UNIT_LOCALES.IMPERIAL]),
  center: PropTypes.bool,
  color: PropTypes.string,
};

ScalingNumberContainer.defaultProps = {
  center: true,
};

export default ScalingNumberContainer;
