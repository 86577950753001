const GREEN_DESATURATED = '#E0FFE8';
const GREEN = '#A6F4C5';
const RED_DESATURATED = '#FFEAEA';
const RED = '#FECDCA';
const AMBER = '#FFF6E4';
const WHITE = '#ffffff';

const GREEN_TEXT = '#05603A';
const AMBER_TEXT = '#7A4807';
const RED_TEXT = '#AD261D';
const GREY_TEXT = '#414141';
const BLACK_TEXT = '#000000';

const GREEN_DESATURATED_CHART = '#a5f2c3';
const GREEN_CHART = '#79d79e';
const RED_DESATURATED_CHART = '#ffb0ac';
const RED_CHART = '#f28b86';
const AMBER_CHART = '#fce090';

export const SCORING_TEXT_COLORS = {
  GREEN_TEXT,
  AMBER_TEXT,
  RED_TEXT,
  GREY_TEXT,
  BLACK_TEXT,
};

export const SCORING_CHART_COLORS = {
  GREEN_DESATURATED_CHART,
  GREEN_CHART,
  RED_DESATURATED_CHART,
  RED_CHART,
  AMBER_CHART,
};

export const SCORING_BACKGROUNDS = {
  GREEN_DESATURATED,
  GREEN,
  RED,
  RED_DESATURATED,
  AMBER,
};

export const ScoringBandColors = {
  1: [RED_DESATURATED, GREEN_DESATURATED],
  2: [RED_DESATURATED, AMBER, GREEN_DESATURATED],
  3: [RED, AMBER, GREEN_DESATURATED, GREEN],
  4: [RED, RED_DESATURATED, GREEN_DESATURATED, GREEN],
  5: [RED, RED_DESATURATED, AMBER, GREEN_DESATURATED, GREEN],
  6: [RED_DESATURATED, WHITE, GREEN_DESATURATED],
};

export const getSuccessThresholdIndex = (
  scoringBandId: Scorecards.ScoringBandId,
  isColorsReversed: boolean,
): number => {
  if (scoringBandId === 1) {
    return 0;
  } else if (scoringBandId === 2) {
    return isColorsReversed ? 0 : 1;
  } else if (scoringBandId === 3 || scoringBandId === 4) {
    return isColorsReversed ? 1 : 1;
  } else if (scoringBandId === 5) {
    return isColorsReversed ? 1 : 2;
  } else if (scoringBandId === 6) {
    return isColorsReversed ? 0 : 1;
  } else {
    const e = new Error();
    e.name = 'Unknown scoringBandId found';
    throw e;
  }
};

export const getTextColor = ({ color }: { color: string }) => {
  switch (color) {
    case SCORING_BACKGROUNDS.GREEN_DESATURATED:
    case SCORING_BACKGROUNDS.GREEN:
      return SCORING_TEXT_COLORS.GREEN_TEXT;
    case SCORING_BACKGROUNDS.AMBER:
      return SCORING_TEXT_COLORS.AMBER_TEXT;
    case SCORING_BACKGROUNDS.RED_DESATURATED:
    case SCORING_BACKGROUNDS.RED:
      return SCORING_TEXT_COLORS.RED_TEXT;
    default:
      return SCORING_TEXT_COLORS.GREY_TEXT;
  }
};

export const getChartColor = ({ color }: { color: string }) => {
  switch (color) {
    case SCORING_BACKGROUNDS.GREEN_DESATURATED:
      return SCORING_CHART_COLORS.GREEN_DESATURATED_CHART;
    case SCORING_BACKGROUNDS.GREEN:
      return SCORING_CHART_COLORS.GREEN_CHART;
    case SCORING_BACKGROUNDS.AMBER:
      return SCORING_CHART_COLORS.AMBER_CHART;
    case SCORING_BACKGROUNDS.RED_DESATURATED:
      return SCORING_CHART_COLORS.RED_DESATURATED_CHART;
    case SCORING_BACKGROUNDS.RED:
      return SCORING_CHART_COLORS.RED_CHART;
    default:
      return SCORING_TEXT_COLORS.GREY_TEXT;
  }
};
