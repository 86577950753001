import React from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import ConfigureMetricList from '../ConfigureMetricList';
import { DateInputContextType } from '../../../contexts/DateInputContext';
import { Option } from '../../../kingpin/atoms/ContextMenu';

const EditListButton = ({
  open,
  close,
  isOpen,
  metricListGadget,
  originalListContext,
}: {
  open: () => void;
  close: () => void;
  isOpen: boolean;
  metricListGadget: MetricListGadgetType;
  originalListContext: DateInputContextType;
}) => (
  <React.Fragment>
    <Option
      onClick={open}
      label={'Edit List'}
      icon={'edit-filled'}
      shouldHideInsteadOfCloseMenu
    />
    <ModalTransition>
      {isOpen && (
        <Modal
          onClose={close}
          shouldScrollInViewport={false}
          autoFocus={false}
          width={'95vw'}
          height={'80vh'}
        >
          <ConfigureMetricList
            metricListGadget={metricListGadget}
            close={close}
            originalListContext={originalListContext}
          />
        </Modal>
      )}
    </ModalTransition>
  </React.Fragment>
);

export default EditListButton;
