import React, { useContext } from 'react';
import styled from 'styled-components';
import { COSTS_LIST_ITEM_HEIGHT } from '../consts';
import Colors2 from 'theme/Colors2';
import { Col, CostRowElement } from './ActivityGrid';
import ActivityGridContext from '../context/ActivityGridContext';
import useHoverRow from './hooks/useHoverRow';
import CostPerMileCell from './CostPerMileCell';
import CostInput from './CostsInput';

export const CellPreview = styled.div<{
  isSelected?: boolean;
}>`
  height: ${COSTS_LIST_ITEM_HEIGHT}px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: relative;

  padding: 8px;

  border-bottom: 1px solid ${Colors2.GridBorder};
  border-right: 1px solid ${Colors2.GridBorder};

  &:hover {
    border: ${(props) =>
      props.isSelected
        ? `1px solid ${Colors2.AvatarColors[2].text}`
        : '1px solid #B1D9FF'};
  }
`;

const CategoryItemRight = ({
  group,
  category,
  hoverId,
}: {
  group: Costs.Group;
  category: Costs.CostCategory;
  hoverId: string;
}) => {
  const { intervals, hoveredCategory } = useContext(ActivityGridContext);
  const { triggerHover } = useHoverRow(hoverId);

  return (
    <CostRowElement
      centerAlign
      isHovered={hoverId === hoveredCategory}
      onMouseEnter={triggerHover}
    >
      {intervals.map((interval) => {
        const isLastInterval =
          interval.startDate === intervals[intervals.length - 1].startDate;

        return (
          <Col
            minWidth
            key={`${interval.label}-${group.groupName}-${category.costFieldName}`}
          >
            {!isLastInterval && (
              <div
                style={{
                  height: `${COSTS_LIST_ITEM_HEIGHT}px`,
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <CostInput
                  interval={interval}
                  fieldName={category.costFieldName}
                  group={group}
                  isLastInterval={
                    interval.startDate ===
                    intervals[intervals.length - 1].startDate
                  }
                  hoverId={hoverId}
                />
              </div>
            )}

            <div
              style={{
                height: `${COSTS_LIST_ITEM_HEIGHT}px`,
                flex: 1,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <CostPerMileCell
                interval={interval}
                fieldName={category.costFieldName}
                group={group}
                hoverId={hoverId}
              />
            </div>
          </Col>
        );
      })}
    </CostRowElement>
  );
};

export default CategoryItemRight;
