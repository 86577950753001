import DATASET_TREND_BUTTON from '../constants';

const buildMetric = ({
  dataset,
  field,
  aggFunc,
}: {
  dataset: string;
  field: string;
  aggFunc: AggFunc;
}) => {
  return {
    id: `search-screen-${dataset}-${DATASET_TREND_BUTTON.DEFAULT_FIELD}`,
    filters: {},
    field: field,
    aggFunc: aggFunc,
    dataType: dataset,
  };
};

export default buildMetric;
