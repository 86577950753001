import PropTypes from 'prop-types';

export const FIX_TYPES = [
  'currency',
  'capacity',
  'distance',
  'weight',
  'temperature',
  'percentage',
  'fuelEfficiency',
  'time',
];

const chartDefinitionPropType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  gridDefinition: PropTypes.string,
  type: PropTypes.oneOf([
    'timeSeries',
    'pie',
    'bar',
    'horizontalBar',
    'smartCard',
    'card',
  ]),
  prefix: PropTypes.oneOf(FIX_TYPES),
  postfix: PropTypes.oneOf(FIX_TYPES),
  series: PropTypes.arrayOf(PropTypes.shape({ query: PropTypes.string })),
  tags: PropTypes.arrayOf(PropTypes.string),
});

export default chartDefinitionPropType;
