import React from 'react';

import Dropdown from '../../../Inputs/Dropdown';
import toSentenceCase from '../../../../services/toSentenceCase';

const BonusPeriodSelector = ({
  bonusPeriods,
  setSelectedBonusPeriod,
  selectedPeriod,
  onDatePickerOpen,
  onDatePickerClose,
}: {
  bonusPeriods: BonusPeriod[];
  setSelectedBonusPeriod: (bp: BonusPeriod) => void;
  selectedPeriod: BonusPeriod;
  onDatePickerOpen?: () => void;
  onDatePickerClose?: () => void;
}) => (
  <>
    <Dropdown
      onOpen={onDatePickerOpen}
      onClose={onDatePickerClose}
      selectedLabel={selectedPeriod.label}
      options={bonusPeriods.map((bp) => ({
        label: `${bp.label} - ${toSentenceCase(bp.status, true)}`,
        onSelected: () => setSelectedBonusPeriod(bp),
        id: bp.id,
      }))}
    />
  </>
);

export default BonusPeriodSelector;
