import React from 'react';

interface ScorecardContextType {
  scorecard: Scorecards.Scorecard;
  updateScorecard: (updatedScorecard: Scorecards.Scorecard) => Promise<void>;
  periods: Period[];
  selectedPeriods: Period[];
  setSelectedPeriods: React.Dispatch<React.SetStateAction<Period[]>>;
  isLoading: boolean;
  data?: Goals.MetricResult[];
  poppedUpKpi?: Scorecards.ScorecardKpi | Scorecards.ManualKpiRow;
  setPoppedUpKpi: React.Dispatch<
    React.SetStateAction<
      Scorecards.ScorecardKpi | Scorecards.ManualKpiRow | undefined
    >
  >;
  isAddKpiEnabled?: boolean;
  isDragAndDropEnabled?: boolean;
  isKpisDisabled?: boolean;
  isMenuEnabled?: boolean;
  toIsoDate: (
    date: Scorecards.WeekDate | Scorecards.IsoDate,
  ) => Scorecards.IsoDate;
  kpisListDivRef: React.MutableRefObject<HTMLDivElement | null>;
  kpisListDivParentScrollerRef: React.MutableRefObject<HTMLDivElement | null>;
  manualKpiIdBeingEdited: string | undefined;
  setManualKpiIdBeingEdited: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  highlightKpi: (kpiId: string) => void;
  kpiBeingHighlighted: string | undefined;
  hoveredKpiId: string | undefined;
  setHoveredKpiId: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const ScorecardContext = React.createContext<ScorecardContextType>(
  {} as ScorecardContextType,
);

export default ScorecardContext;
