import { useCallback } from 'react';
import mergeFilterInputs from '../../../mergeFilterInputs';

const useBuildSearchArgs = () => {
  const buildSearchArgs = useCallback(
    ({
      entity,
      searchText,
      isActiveFilterDisabled,
    }: {
      entity: EntityDetails.Entity;
      searchText: string;
      isActiveFilterDisabled: boolean;
    }): GlobalSearch.SearchArgs => {
      const groupBy: V5GroupBy = {
        field: entity.primaryField,
        type: 'text',
      };

      const secondaryMetrics: MetricInput[] = entity.secondaryFields.map(
        (sf) => ({
          id: sf,
          field: sf,
          dataType: entity.entityDataset,
          aggFunc: 'last',
          filters: {},
        }),
      );
      const contextMetrics: MetricInput[] = entity.contextFields.map((sf) => ({
        id: sf,
        field: sf,
        dataType: entity.entityDataset,
        aggFunc: 'last',
        filters: {},
      }));

      const searchFilter: FilterInput = {
        wildcardFilters: [
          { field: entity.primaryField, pattern: `*${searchText}*` },
        ],
      };
      const filters = isActiveFilterDisabled
        ? [searchFilter]
        : [mergeFilterInputs(entity.isActiveFilter, searchFilter)];

      return {
        groupBy,
        secondaryMetrics,
        contextMetrics,
        filters,
      };
    },
    [],
  );

  return buildSearchArgs;
};

export default useBuildSearchArgs;
