import React, { useCallback } from 'react';
import CloseButton from 'components/CloseButton';
import Row from 'components/Common/Row';
import Typography from 'kingpin/atoms/Typography';
import Colors2 from 'theme/Colors2';
import DragHandle from 'components/DragAndDropList/DragHandle';
import DragAndDropList from 'components/DragAndDropList';
import Column from 'screens/DataManager/DatasetDefinitions/Column';

const FieldsListItem = ({
  field,
  setFields,
}: {
  field: string;
  setFields: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const onRemoveClicked = useCallback(() => {
    setFields((fields) => fields.filter((f) => f !== field));
  }, [field, setFields]);

  return (
    <Row
      spaceBetween
      centerAlign
      key={field}
      style={{
        border: `1px solid ${Colors2.Grey['8']}`,
        marginTop: '-1px',
      }}
    >
      <Column style={{ minHeight: 42 }}>
        <DragHandle testId={`${field}-drag`} />
        <Typography.Body type="Body 12">{field}</Typography.Body>
      </Column>
      <CloseButton isDark close={onRemoveClicked} />
    </Row>
  );
};

const FieldsList = ({
  fields,
  setFields,
}: {
  fields: string[];
  setFields: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  if (!fields) {
    return null;
  }

  return (
    <DragAndDropList
      items={fields}
      onOrderChanged={setFields}
      droppableId="fieldId"
      renderItem={(field) => (
        <FieldsListItem field={field} setFields={setFields} />
      )}
    />
  );
};

export default FieldsList;
