import baseOptions from './baseOptions';
import formatDateLabel from '../formatDateLabel';
import buildDrillDown from '../../../buildDrillDown';
import abbreviateNumber from '../../../utils/abbreviateNumber';
import base from './baseOptions';

const bar = ({
  title,
  openMenu,
  groupField,
  fieldType,
  chartDef,
  showDataLabels,
  fontSize,
  isHistogram,
  formatMetric,
  showLegend,
}) => ({
  ...baseOptions(formatMetric),
  chart: {
    ...baseOptions(formatMetric).chart,
    marginTop: 24,
    type: 'column',
  },
  title: {
    ...baseOptions(formatMetric).title,
    text: title,
  },
  yAxis: [
    {
      ...baseOptions(formatMetric).yAxis[0],
      title: {
        enabled: false,
      },
    },
  ],
  legend: {
    ...base(formatMetric).legend,
    enabled: showLegend,
  },
  plotOptions: {
    column: {
      borderRadius: '5%',
      dataLabels: {
        fontWeight: '300',
        enabled: !!showDataLabels,
        color: 'black',
        formatter: function () {
          const { y, series } = this;
          const { step, metricId } = series.userOptions;
          const index = this.point.index;
          const isHidden = !!step && index % step !== 0 && isHistogram;
          const abbreviated = abbreviateNumber(y);
          const formattedY = formatMetric({
            metricId,
            value: abbreviated ? abbreviated.abbreviated : y,
          }).trim();
          if (isHidden) {
            return '';
          }
          return `<b>${formattedY}</b>`;
        },
        style: {
          fontSize,
          textOutline: false,
        },
      },
    },
    series: {
      findNearestPointBy: 'xy',
      allowPointSelect: true,
      point: {
        events: {
          click: function (e) {
            const selectedIndex = e.point.x;
            const terms = e.point.series.data;
            const series = e.point.series.chart.series;
            if (openMenu) {
              const { metricIds } = this.series.userOptions;
              const selectedTerm = terms.find((t, index) => {
                return index === selectedIndex;
              });
              openMenu(
                metricIds,
                buildDrillDown(
                  groupField,
                  fieldType,
                  selectedTerm.category,
                  chartDef,
                ),
              );
            } else {
              // @ts-ignore
              const { onDrillDown } = this.series.userOptions;
              if (!onDrillDown) {
                alert('Coming soon...');
                return;
              }
              if (series.length > 1) {
                // multi series
                series[0].data.forEach((d, dataIndex) => {
                  if (dataIndex === selectedIndex) {
                    onDrillDown(d.name || d.category, e.ctrlKey || e.metaKey);
                  }
                });
              } else {
                // single series
                terms.forEach((t, index) => {
                  if (index === selectedIndex) {
                    onDrillDown(t.name || t.category, e.ctrlKey || e.metaKey);
                  }
                });
              }
            }
          },
        },
      },
    },
  },
  tooltip: {
    ...baseOptions(formatMetric).tooltip,
    formatter: function () {
      const { x, points } = this;
      if (points.length === 0) {
        return '';
      }

      const { interval } = points[0].series.userOptions;
      const headerText = (() => {
        if (interval) {
          return formatDateLabel(x, interval);
        } else {
          return x;
        }
      })();

      let pointsHtml = '';
      points.forEach((p) => {
        const { metricId } = p.series.userOptions;

        const formattedY = formatMetric({ value: p.y, metricId });
        pointsHtml += `<div><span style="color:${p.color}">● </span><span style="color: #878F9D;">${p.series.name}: </span><b>${formattedY}</b></div><br/>`;
      });

      return `<div>${headerText}<br/>${pointsHtml}`;
    },
  },
});

export default bar;
