import React from 'react';

const OrderDetailsMap = ({
  mapDivRef,
  height,
}: {
  mapDivRef: React.RefObject<HTMLDivElement>;
  height?: number;
}) => (
  <div
    id="map"
    ref={mapDivRef}
    style={{
      width: '100%',
      height: height ? height : '100%',
      marginBottom: 16,
    }}
  />
);

export default OrderDetailsMap;
