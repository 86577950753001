import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useTenantQueryParam = () => {
  const { search } = useLocation();
  const getTenant = useCallback(() => {
    const queryParams = new URLSearchParams(search);
    if (queryParams.has('tenant')) {
      return queryParams.get('tenant') as string;
    }
  }, [search]);
  const [tenant, setTenant] = useState<string | undefined>(() => getTenant());
  useEffect(() => {
    setTenant(getTenant());
  }, [getTenant]);

  return tenant;
};

export default useTenantQueryParam;
