import React, { ChangeEvent } from 'react';
import Button from 'kingpin/atoms/Button';

import Preview from './Preview';
import Row from '../../../Common/Row';
import TextInput from 'kingpin/atoms/TextInput';
import toSentenceCase from '../../../../services/toSentenceCase';
import Divider from '../Divider';

const WildcardPicker = ({
  pattern,
  mode,
  onValueChanged,
  onConfirm,
  isEditing,
  wildcardFilter,
  isValid,
  editingId,
}: {
  pattern: string;
  mode: string;
  onValueChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  onConfirm: () => void;
  isEditing: boolean;
  wildcardFilter: WildcardFilter;
  isValid: boolean;
  editingId?: string;
}) => (
  <div>
    <div style={{ marginBottom: 8, padding: '0px 8px' }}>
      <TextInput
        value={pattern}
        onChange={onValueChanged}
        inputSize="Small"
        placeholder={toSentenceCase(mode, true)}
      />
    </div>
    <Divider />
    <Preview wildcardFilter={wildcardFilter} editingId={editingId} />
    <Divider />
    <Row
      centerAlign
      style={{ justifyContent: 'flex-end', marginTop: 16, padding: '0px 8px' }}
    >
      <Button
        onClick={onConfirm}
        type="Primary"
        size="Small"
        label={isEditing ? 'Update' : 'Add'}
        isDisabled={!isValid}
      />
    </Row>
  </div>
);

export default WildcardPicker;
