import { useContext, useEffect, useState } from 'react';
import toSentenceCase from '../../../../../../services/toSentenceCase';
import PerformanceWizardContext from '../PerformanceWizardContext';

const useCadenceOptions = () => {
  const { wizardState, setWizardState } = useContext(PerformanceWizardContext);
  const [cadenceOptions, setCadenceOptions] = useState<DropdownOption[]>([]);
  useEffect(() => {
    const intervals = ['day' as 'day', 'week' as 'week', 'month' as 'month'];
    setCadenceOptions(
      intervals.map((interval) => ({
        label: `${toSentenceCase(interval)}(s)`,
        value: interval,
        isSelected: wizardState.config.datasetStep.interval === interval,
        onSelected: () => {
          setWizardState((s) => ({
            ...s,
            config: {
              ...s.config,
              datasetStep: {
                ...s.config.datasetStep,
                interval,
                startDate: undefined,
              },
            },
          }));
        },
      })),
    );
  }, [setWizardState, wizardState.config.datasetStep.interval]);

  return cadenceOptions;
};

export default useCadenceOptions;
