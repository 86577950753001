import React, { useCallback, useContext } from 'react';
import { ListItem } from 'components/Inputs/Dropdown';
import Typography from 'kingpin/atoms/Typography';
import AnalyticsContext from 'contexts/AnalyticsContext';

import TargetListContext from '../../contexts/TargetListContext';

const DuplicateRules = ({
  target,
  close,
}: {
  target: Targets.Wizard.DataTypeTarget;
  close: () => void;
}) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const { onDuplicateRules } = useContext(TargetListContext);
  const onClicked = useCallback(() => {
    onDuplicateRules(target);
    trackEvent('Targets - Duplicate Rules Clicked');
    close();
  }, [close, onDuplicateRules, target, trackEvent]);

  return (
    <ListItem onClick={onClicked}>
      <Typography.Body type="Body 12">Duplicate Rules</Typography.Body>
    </ListItem>
  );
};

export default DuplicateRules;
