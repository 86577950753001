import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

const getFieldsWhichHaveTargets = async ({
  dataType,
  client,
}: {
  dataType: string;
  client: ApolloClient<NormalizedCacheObject>;
}) => {
  const query = {
    query: gql`
      query getFieldsWhichHaveTargets($dataType: String!) {
        getFieldsWhichHaveTargets(dataType: $dataType)
      }
    `,
    variables: {
      dataType,
    },
  };

  const response = await client.query(query);
  return response.data.getFieldsWhichHaveTargets as string[];
};

export default getFieldsWhichHaveTargets;
