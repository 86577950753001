import React from 'react';
import Typography from 'kingpin/atoms/Typography';
import styled from 'styled-components';
import isoDateToAmerican from '../../../../isoDateToAmerican';
import Colors2 from '../../../../theme/Colors2';

const LoadsCountText = styled.div<{ hasLoads: boolean }>`
  margin-right: 16px;
`;

const QueryLoads = ({
  loadsCount,
  isLoading,
  hasLoads,
  earliest,
}: {
  loadsCount: number;
  isLoading: boolean;
  hasLoads: boolean;
  earliest?: string;
}) => (
  <LoadsCountText hasLoads={hasLoads}>
    {earliest && (
      <Typography.Body
        type={'Body 12'}
        color={hasLoads ? undefined : Colors2.Secondary.error}
      >
        {`${isLoading ? '...' : loadsCount} Loads since ${isoDateToAmerican(
          earliest,
        )}`}
      </Typography.Body>
    )}
    {!earliest && (
      <Typography.Body
        type={'Body 12'}
      >{`${isLoading ? '...' : loadsCount} Loads`}</Typography.Body>
    )}
  </LoadsCountText>
);

export default QueryLoads;
