import React, { useContext } from 'react';
import styled from 'styled-components';
import RuleNumber from './RuleNumber';
import Typography from 'kingpin/atoms/Typography';
import TargetFormContext from '../../contexts/TargetFormContext';

const RuleNumberCol = styled.div`
  width: 96px;
`;

const GroupLabel = ({
  isFallbackGroup,
  group,
}: {
  isFallbackGroup: boolean;
  group: Targets.Wizard.TargetGroup;
}) => {
  const { isTargetedByGroup } = useContext(TargetFormContext);

  if (isTargetedByGroup) {
    return (
      <RuleNumberCol>
        {isFallbackGroup && (
          <Typography.Body type="Body 12">If no rules match</Typography.Body>
        )}
        {!isFallbackGroup && <RuleNumber group={group} />}
      </RuleNumberCol>
    );
  }

  return null;
};

export default GroupLabel;
