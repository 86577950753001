import React from 'react';

import Typography from 'kingpin/atoms/Typography';
import Row from '../../../Common/Row';
import Button from 'kingpin/atoms/Button';
import PopupSideBar from './PopupStyles/PopupSideBar';

const SideBar = ({
  datasetOptions,
  isShowAllEnabled,
  otherDatasetOptions,
  onShowAllDatasetsClicked,
  isShowAllDatasetsButtonDisabled,
}: {
  datasetOptions: DropdownOption[];
  isShowAllEnabled: boolean;
  otherDatasetOptions: DropdownOption[];
  onShowAllDatasetsClicked: () => void;
  isShowAllDatasetsButtonDisabled?: boolean;
}) => (
  <PopupSideBar.SideBarDiv>
    <Row spaceBetween centerAlign style={{ marginBottom: 10 }}>
      <Typography.Header type="H5">Filter by Dataset</Typography.Header>
      {!isShowAllEnabled && otherDatasetOptions.length > 0 && (
        <Button
          label="Show All"
          onClick={onShowAllDatasetsClicked}
          isDisabled={isShowAllDatasetsButtonDisabled}
          type="Ghost"
          size="Small"
        />
      )}
    </Row>
    <PopupSideBar.SideBarContentDiv>
      <PopupSideBar.ListOuterDiv>
        <PopupSideBar.ListInnerDiv>
          <div style={{ marginTop: 8 }}>
            {isShowAllEnabled && (
              <Typography.Header type="H5">In-use</Typography.Header>
            )}
            {datasetOptions.map((o) => (
              <PopupSideBar.ListItem
                data-testid={`dataset-${o.value}`}
                isSelected={!!o.isSelected}
                key={o.label}
                onClick={o.onSelected}
                centerAlign
                spaceBetween
              >
                <Typography.Body type="Body 12">{o.label}</Typography.Body>
                {o.isSelected && <PopupSideBar.CheckMark />}
              </PopupSideBar.ListItem>
            ))}
          </div>

          {isShowAllEnabled && (
            <div style={{ marginTop: 16 }}>
              <Typography.Header type="H5">All other metrics</Typography.Header>
              {otherDatasetOptions.map((o) => (
                <PopupSideBar.ListItem
                  data-testid={`dataset-${o.value}`}
                  isSelected={!!o.isSelected}
                  key={o.label}
                  onClick={o.onSelected}
                  centerAlign
                  spaceBetween
                >
                  <Typography.Body type="Body 12">{o.label}</Typography.Body>
                  {o.isSelected && <PopupSideBar.CheckMark />}
                </PopupSideBar.ListItem>
              ))}
            </div>
          )}
        </PopupSideBar.ListInnerDiv>
      </PopupSideBar.ListOuterDiv>
    </PopupSideBar.SideBarContentDiv>
  </PopupSideBar.SideBarDiv>
);

export default SideBar;
