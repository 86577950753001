import React, { useContext } from 'react';
import Nav from './Nav';
import { Wrapper } from '../CustomerLaneCommitsBoard/SlideOut/SlideOut';
import SlideOutHeading from './SlideOutHeading';
import DriverSummarySection from './DriverSummarySection';
import BoardDataSection from './BoardDataCardSection';
import DriverDataHeaderSection from './DriverDataHeaderSection';
import isDriverPerformanceSlideOutDataSection, {
  isCommentsSection,
  isDriverSummarySlideOutDataSection,
  isDriverSummarySlideOutGridSection,
  isMetricsSection,
  isPerformanceSection,
} from '../../isDriverPerformanceSlideOutDataSection';
import BoardDataGridSection from './BoardDataGridSection';
import DriverScoreProvider from '../../contextProviders/DriverScoreProvider';
import isDriverPerformanceBoard from '../../isDriverPerformanceBoard';
import BoardSlideOutContext from '../../contexts/BoardSlideOutContext';
import Performance from './Performance';
import Metrics from './Metrics';
import Comments from '../Comments';
import Typography from 'kingpin/atoms/Typography';
import BoardContext from '../../contexts/BoardContext';

const MaybeDriverScoreProvider = ({
  children,
  period,
}: {
  children: JSX.Element | JSX.Element[];
  period?: BonusPeriod;
}) => {
  const { board } = useContext(BoardContext);
  const { drillDownField, selectedFieldValue } =
    useContext(BoardSlideOutContext);

  if (
    drillDownField === 'driver' &&
    !!selectedFieldValue &&
    period &&
    isDriverPerformanceBoard(board)
  ) {
    return (
      <DriverScoreProvider driver={selectedFieldValue} bonusPeriod={period}>
        {children}
      </DriverScoreProvider>
    );
  } else {
    return <>{children}</>;
  }
};

const BoardSlideOut = ({
  activeNavElement,
  onNavScroll,
  close,
  headingText,
  sections,
  headerSection,
  selectedFieldValue,
  selectedBonusPeriod,
  periodStart,
  board,
  filterInput,
  filterInputThisPeriodOnly,
  dateScope,
  dateScopeThisPeriodOnly,
  isGoal,
}: {
  activeNavElement: 'data' | 'review';
  onNavScroll: (event: React.UIEvent<HTMLDivElement>) => void;
  close: () => void;
  headingText: string;
  headerSection?:
    | BoardSlideOutGridSection
    | BoardSlideOutDataSection
    | DriverPerformanceSlideOutSummarySection;
  sections: (
    | BoardSlideOutGridSection
    | BoardSlideOutDataSection
    | DriverPerformanceSlideOutSummarySection
    | BoardSlideOutPerformanceSection
    | BoardSlideOutMetricsSection
    | BoardCommentsSection
  )[];
  selectedFieldValue: string;
  selectedBonusPeriod?: BonusPeriod;
  periodStart: string;
  board: GeneralBoard | DriverPerformanceBoard;
  filterInput?: FilterInput;
  filterInputThisPeriodOnly?: FilterInput;
  dateScope?: DateRangeInput;
  dateScopeThisPeriodOnly?: DateRangeInput;
  isGoal: boolean;
}) => (
  <Wrapper>
    <Nav
      activeNavElement={activeNavElement}
      hideEscalationSection={
        isDriverPerformanceBoard(board) ? !!board.hideEscalationSection : true
      }
    />
    <div style={{ width: 'calc(100% - 48px)' }}>
      <SlideOutHeading close={close} headingText={headingText} />
      <MaybeDriverScoreProvider period={selectedBonusPeriod}>
        <div
          key={`${periodStart}-${selectedFieldValue}`}
          onScroll={onNavScroll}
          id={'board-slide-scroll-area'}
          style={{
            height: 'calc(100% - 80px)',
            width: '100%',
            overflowY: 'scroll',
            padding: '20px 24px',
          }}
        >
          <div id="board-slide-data">
            {headerSection &&
              isDriverPerformanceSlideOutDataSection(headerSection) && (
                <DriverDataHeaderSection
                  section={headerSection}
                  key={headerSection.dataType}
                />
              )}
            {headerSection &&
              isDriverSummarySlideOutDataSection(headerSection) &&
              selectedBonusPeriod && (
                <DriverSummarySection
                  bonusPeriod={selectedBonusPeriod}
                  driver={selectedFieldValue}
                  category={headerSection.category}
                />
              )}
            {headerSection &&
              isDriverSummarySlideOutGridSection(headerSection) &&
              selectedBonusPeriod && (
                <BoardDataGridSection
                  section={headerSection}
                  key={headerSection.dataType}
                />
              )}
            {sections.map((section) => {
              if (isDriverSummarySlideOutGridSection(section)) {
                return (
                  <div style={{ marginBottom: 24 }}>
                    <BoardDataGridSection
                      section={section}
                      key={section.dataType}
                    />
                  </div>
                );
              } else if (isDriverPerformanceSlideOutDataSection(section)) {
                return (
                  <div style={{ marginBottom: 24 }}>
                    <BoardDataSection
                      section={section}
                      key={section.dataType}
                    />
                  </div>
                );
              } else if (isPerformanceSection(section)) {
                return (
                  <div style={{ marginBottom: 24 }}>
                    <Performance
                      config={section.config}
                      key={`${section.config.targetField} - ${section.config.totalField} - ${section.config.cadenceTotalField}`}
                      filterInput={filterInput}
                      dateScope={dateScope}
                    />
                  </div>
                );
              } else if (isMetricsSection(section)) {
                return (
                  <div style={{ marginBottom: 24 }}>
                    <Metrics
                      key={Math.random()}
                      title={section.title}
                      filterInput={
                        section.thisPeriodOnly
                          ? filterInputThisPeriodOnly
                          : filterInput
                      }
                      dateScope={
                        section.thisPeriodOnly
                          ? dateScopeThisPeriodOnly
                          : dateScope
                      }
                    />
                  </div>
                );
              } else if (
                isDriverSummarySlideOutDataSection(section) &&
                !!selectedBonusPeriod
              ) {
                return (
                  <div style={{ marginBottom: 24 }}>
                    <DriverSummarySection
                      key={`summary-${selectedFieldValue}-${section.category}`}
                      bonusPeriod={selectedBonusPeriod}
                      driver={selectedFieldValue}
                      category={section.category}
                    />
                  </div>
                );
              } else if (isCommentsSection(section) && !isGoal) {
                return (
                  <div style={{ marginBottom: 24 }}>
                    <Typography.Header type="H5">Comments</Typography.Header>
                    <Comments
                      commentableId={`${selectedFieldValue}-${periodStart}`}
                      commentableType={section.commentableType}
                      emailLink={window.location.pathname}
                      mentionedEmailBody={`mentioned you in the slide out on ${section.commentableType}`}
                      watcherEmailBody={`commented on the slide out on ${section.commentableType}`}
                    />
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
      </MaybeDriverScoreProvider>
    </div>
  </Wrapper>
);

export default BoardSlideOut;
