import React, { useContext, useState } from 'react';
import BaseDataToggleButton from './BaseDataToggleButton';
import { gql } from '@apollo/client';
import CurrentUserContext from '../../../contexts/CurrentUserContext';
import useUser from '../../../hooks/useUser';
import BonusPeriodsContext from '../../../contexts/BonusPeriodsContext';
import AnalyticsContext from '../../../contexts/AnalyticsContext';
import BoardContext from '../../../contexts/BoardContext';
import isDriverPerformanceBoard from '../../../isDriverPerformanceBoard';
import GqlClientContext from '../../../contexts/GqlClientContext';

interface Props {
  id: string;
  scoringDataType: string;
  isExcluded: boolean;
  excludedBy?: string;
  updateCardData: (
    cardId: string,
    newCardData: { [key: string]: string | undefined },
  ) => void;
}

const BaseDataToggleButtonContainer = ({
  id,
  scoringDataType,
  isExcluded,
  excludedBy,
  updateCardData,
}: Props) => {
  const { client } = useContext(GqlClientContext);
  const { selectedBonusPeriod } = useContext(BonusPeriodsContext);
  const { user: excludedByUser } = useUser(excludedBy);
  const { id: currentUserId, isFleetOpsStaff } = useContext(CurrentUserContext);
  const { board } = useContext(BoardContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isInteractive =
    !!selectedBonusPeriod &&
    (selectedBonusPeriod.status === 'in review' ||
      selectedBonusPeriod.status === 'active');

  const onExcludeClicked = () => {
    if (!isInteractive || !selectedBonusPeriod) {
      return;
    }

    const mutation = {
      mutation: gql`
        mutation excludeFromScoring(
          $scoringPeriodId: String!
          $scoringDataType: ScoringDataType!
          $id: String!
          $excludedBy: String!
        ) {
          excludeFromScoring(
            scoringPeriodId: $scoringPeriodId
            scoringDataType: $scoringDataType
            id: $id
            excludedBy: $excludedBy
          )
        }
      `,
      variables: {
        scoringPeriodId: selectedBonusPeriod.id,
        scoringDataType,
        id,
        excludedBy: currentUserId,
      },
    };
    setIsLoading(true);
    client.mutate(mutation).then((response) => {
      trackEvent('Driver Bonus - Exclude', {
        bonusPeriodId: selectedBonusPeriod.id,
        bonusPeriodLabel: selectedBonusPeriod.label,
        scoringDataType: scoringDataType,
        boardId: board.id,
        boardName: board.name,
      });
      updateCardData(id, response.data.eligible);
      setIsLoading(false);
    });
  };

  const onIncludeClicked = () => {
    if (!isInteractive || !selectedBonusPeriod) {
      return;
    }

    const mutation = {
      mutation: gql`
        mutation includeInScoring(
          $scoringPeriodId: String!
          $scoringDataType: ScoringDataType!
          $id: String!
          $includedBy: String!
        ) {
          includeInScoring(
            scoringPeriodId: $scoringPeriodId
            scoringDataType: $scoringDataType
            id: $id
            includedBy: $includedBy
          )
        }
      `,
      variables: {
        scoringPeriodId: selectedBonusPeriod.id,
        scoringDataType,
        id,
        includedBy: currentUserId,
      },
    };
    setIsLoading(true);
    client.mutate(mutation).then((response) => {
      trackEvent('Driver Bonus - Include', {
        bonusPeriodId: selectedBonusPeriod.id,
        bonusPeriodLabel: selectedBonusPeriod.label,
        scoringDataType: scoringDataType,
        boardId: board.id,
        boardName: board.name,
      });
      updateCardData(id, response.data.includeInScoring);
      setIsLoading(false);
    });
  };

  if (
    board &&
    isDriverPerformanceBoard(board) &&
    (board.assignedToId.includes(currentUserId) || isFleetOpsStaff)
  ) {
    return (
      <BaseDataToggleButton
        isExcluded={isExcluded}
        excludedByUser={excludedByUser}
        onExcludeClicked={onExcludeClicked}
        onIncludeClicked={onIncludeClicked}
        isLoading={isLoading}
        isInteractive={isInteractive}
      />
    );
  } else {
    return null;
  }
};

export default BaseDataToggleButtonContainer;
