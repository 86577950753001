import React from 'react';
import styled from 'styled-components';
import Typography from 'kingpin/atoms/Typography';
import Number from './Number';
import DropDown from './Dropdown';
import IntervalBadge from 'components/IntervalBadge';

const PickerDiv = styled.div`
  height: 38px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const LeftDiv = styled.div`
  height: 38px;
  border: 1px solid #dcdcdc;
  background-color: #f7f7fb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 4px 0px 0px 4px;
  padding: 0px 16px;
`;

const MiddleDiv = styled.div`
  height: 38px;
  padding: 0px 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  flex: 1;
`;

const RightDiv = styled.div`
  height: 38px;
  padding: 0px 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #dcdcdc;
  border-radius: 0px 4px 4px 0px;
`;

const IntervalPicker = ({
  intervalLength,
  intervalOptions,
  onIntervalLengthChanged,
  isDisabled,
}: {
  intervalLength: number;
  intervalOptions: DropdownOption[];
  onIntervalLengthChanged: (newLength: number | undefined) => void;
  isDisabled?: boolean;
}) => {
  if (isDisabled) {
    const interval = intervalOptions.find((o) => o.isSelected);
    if (!interval) {
      return null;
    }

    return (
      <IntervalBadge
        intervalLength={intervalLength}
        interval={interval.value as PerformanceConfiguration.Interval}
      />
    );
  }

  return (
    <PickerDiv>
      <LeftDiv>
        <Typography.Body type="Body 12">Every</Typography.Body>
      </LeftDiv>
      <MiddleDiv>
        <Number
          setValue={onIntervalLengthChanged}
          value={intervalLength}
          noBorders
          testId={'interval-length'}
        />
      </MiddleDiv>
      <RightDiv>
        <DropDown options={intervalOptions} noBorders />
      </RightDiv>
    </PickerDiv>
  );
};

export default IntervalPicker;
