import React, { useContext, useState } from 'react';
import BoardSlideOut from './BoardSlideOut';
import BoardSlideOutContext from '../../contexts/BoardSlideOutContext';
import Loading from '../Loading/Loading';
import BonusPeriodsContext from '../../contexts/BonusPeriodsContext';
import BoardContext from '../../contexts/BoardContext';
import toSentenceCase from '../../services/toSentenceCase';
import isDriverPerformanceBoard from '../../isDriverPerformanceBoard';
import isCustomerLaneBoard from '../../isCustomerLaneBoard';
import PeriodsContext from '../../contexts/PeriodsContext';
import { useSlideOutFilterInput } from './BoardDataCardSection';
import GoalContext from '../../contexts/GoalContext';
import isPerformanceBoard from '../../isPerformanceBoard';
import { Z_INDEX } from '../../constants';

const SlideOutContainer = () => {
  const { close, sections, drillDownField, selectedFieldValue } =
    useContext(BoardSlideOutContext);
  const [activeNavElement, setActiveNavElement] = useState<'data' | 'review'>(
    'data',
  );
  const { selectedBonusPeriod } = useContext(BonusPeriodsContext);
  const { selectedPeriod } = useContext(PeriodsContext);
  const { board } = useContext(BoardContext);
  const { goal } = useContext(GoalContext);
  const { filterInput, dateScope } = useSlideOutFilterInput(undefined, false);
  const {
    filterInput: filterInputThisPeriodOnly,
    dateScope: dateScopeThisPeriodOnly,
  } = useSlideOutFilterInput(undefined, false, true);

  if (isCustomerLaneBoard(board)) {
    return null;
  }

  if (isPerformanceBoard(board)) {
    return null;
  }

  const onNavScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const elem = event.currentTarget;
    const dataElem = document.getElementById('board-slide-data');
    const reviewElem = document.getElementById('driver-slide-review');

    if (!dataElem || !reviewElem) {
      return;
    }

    if (elem.scrollTop >= dataElem.clientHeight) {
      setActiveNavElement('review');
    } else {
      setActiveNavElement('data');
    }
  };

  if (!selectedFieldValue) {
    return <Loading />;
  }

  return (
    <BoardSlideOut
      activeNavElement={activeNavElement}
      onNavScroll={onNavScroll}
      close={close}
      headingText={`${toSentenceCase(
        drillDownField,
      )} Summary: ${selectedFieldValue}`}
      sections={sections}
      selectedFieldValue={selectedFieldValue}
      selectedBonusPeriod={selectedBonusPeriod}
      headerSection={
        isDriverPerformanceBoard(board) ? board.sidePanelHeader : undefined
      }
      periodStart={
        selectedBonusPeriod
          ? selectedBonusPeriod.startDate
          : selectedPeriod
            ? selectedPeriod.startDate
            : ''
      }
      board={board}
      filterInput={filterInput}
      filterInputThisPeriodOnly={filterInputThisPeriodOnly}
      dateScope={dateScope}
      dateScopeThisPeriodOnly={dateScopeThisPeriodOnly}
      isGoal={goal !== undefined}
    />
  );
};

const Gate = () => {
  const { isOpen } = useContext(BoardSlideOutContext);
  if (isOpen) {
    return (
      <div
        style={{
          height: '100%',
          minWidth: 500,
          zIndex: Z_INDEX.GENERAL_BOARD_SLIDE_OUT,
          fontSize: 11,
        }}
      >
        <SlideOutContainer />
      </div>
    );
  } else {
    return null;
  }
};

export default Gate;
