import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import NotificationsPanel from 'components/NotificationsPanel';
import NotificationsContext from 'contexts/NotificationsContext';
import Colors2 from 'theme/Colors2';
import NavItem from './NavItem';

const StyleOverride = styled.div`
  .Canny_BadgeContainer .Canny_Badge {
    position: absolute;
    top: -1px;
    right: -1px;
    border-radius: 10px;
    background-color: ${Colors2.Primary['1']};
    padding: 3px;
    border: 1px solid transparent;
    cursor: pointer;
  }
`;

const NewNotificationsDot = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: ${Colors2.Primary['1']};
`;

const NotificationsButton = () => {
  const { openPanel, hasUnSeenNotifications } =
    useContext(NotificationsContext);
  useEffect(() => {
    if (process.env.NODE_ENV === 'test') {
      return;
    }
    // @ts-ignore
    if (window.Canny) {
      // @ts-ignore
      window.Canny('initChangelog', {
        appID: '6115464d9b41ec5b75c43d42',
        position: 'bottom',
        align: 'left',
      });
    }
  }, []);

  return (
    <>
      <NavItem
        icon={'bell'}
        iconColor={'#666666'}
        onClick={openPanel}
        isActiveOverride={false}
        to={'#'}
        renderExtra={
          hasUnSeenNotifications ? <NewNotificationsDot /> : undefined
        }
      />
      <StyleOverride>
        <NotificationsPanel isCard={false} />
      </StyleOverride>
    </>
  );
};

export default NotificationsButton;
