import React, { ChangeEvent } from 'react';
import { CellPreview } from '../CategoryItemRight';
import Colors2 from 'theme/Colors2';
import Typography from 'kingpin/atoms/Typography';
import TextInput from '../../../../../kingpin/atoms/TextInput';

const CostInput = ({
  interval,
  fieldName,
  group,
  isLastInterval,
  hoverId,
  focusedInterval,
  hoveredCategory,
  formatOngoingValue,
  costInputValue,
  formattedInputValue,
  isInvalid,
  inputRef,
  onChange,
}: {
  interval: Period;
  fieldName: string;
  group: Costs.Group;
  isLastInterval: boolean;
  hoverId: string;
  focusedInterval: Period | undefined;
  hoveredCategory: string | undefined;
  formatOngoingValue: ({
    value,
    isFocused,
  }: {
    value: string;
    isFocused: boolean;
  }) => string;
  costInputValue: string;
  formattedInputValue: string;
  isInvalid: boolean;
  inputRef: React.MutableRefObject<HTMLInputElement | null>;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <div style={{ width: '100%' }}>
      <CellPreview
        data-testid={`${interval.startDate}-${group.groupName}-${fieldName}`}
        style={{
          padding: '6px 8px',
          opacity: 1,
          backgroundColor:
            focusedInterval === interval
              ? '#F9F9F9'
              : hoverId === hoveredCategory
                ? undefined
                : '#ffffff',
        }}
      >
        {isLastInterval ? (
          <div style={{ marginRight: '4px' }}>
            <Typography.Body type="Body 12" color={Colors2.Grey['3']}>
              {formatOngoingValue({
                value: costInputValue ? costInputValue : '0',
                isFocused: false,
              })}
            </Typography.Body>
          </div>
        ) : (
          <TextInput
            inputVariant="Grid"
            value={formattedInputValue}
            state={isInvalid ? 'Error' : undefined}
            forwardRef={inputRef}
            onChange={onChange}
            data-testid={`input-${interval.startDate}-${group.groupName}-${fieldName}`}
          />
        )}
      </CellPreview>
    </div>
  );
};

export default CostInput;
