import React from 'react';

import NormalMetricForm from './NormalMetricForm';
import CompoundMetricForm from './CompoundMetricForm';
import metricTypeCheckers from '../../../../../types/metricTypeCheckers';

const MetricForm = ({
  mode,
  id,
  selectedMetric,
  showFlash,
  setHasUnsavedChanges,
  forcedDataset,
}: {
  mode: 'Metric' | 'CompoundMetric';
  id: string;
  selectedMetric?: Metrics.NormalMetric | Metrics.CompoundMetric;
  showFlash: (message: string) => void;
  setHasUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  forcedDataset?: string;
}) => {
  if (metricTypeCheckers.isNormalMetric(selectedMetric)) {
    return (
      <NormalMetricForm
        selectedMetric={selectedMetric}
        id={id}
        showFlash={showFlash}
        setHasUnsavedChanges={setHasUnsavedChanges}
        forcedDataset={forcedDataset}
      />
    );
  } else if (metricTypeCheckers.isCompoundMetric(selectedMetric)) {
    return (
      <CompoundMetricForm
        selectedMetric={selectedMetric}
        id={id}
        showFlash={showFlash}
        setHasUnsavedChanges={setHasUnsavedChanges}
      />
    );
  } else if (mode === 'Metric') {
    return (
      <NormalMetricForm
        id={id}
        showFlash={showFlash}
        setHasUnsavedChanges={setHasUnsavedChanges}
        forcedDataset={forcedDataset}
      />
    );
  } else if (mode === 'CompoundMetric') {
    return (
      <CompoundMetricForm
        id={id}
        showFlash={showFlash}
        setHasUnsavedChanges={setHasUnsavedChanges}
      />
    );
  } else {
    return null;
  }
};

export default MetricForm;
