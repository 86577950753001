import { useCallback, useEffect, useState } from 'react';

const useTrackFocusUsage = (
  trackEvent: (
    eventType: Analytics.EventType,
    eventProperties?: { [key: string]: string },
  ) => void,
) => {
  const [isVisible, setIsVisible] = useState<boolean>(true);

  const onVisibilityChanged = useCallback(() => {
    setIsVisible(document.visibilityState === 'visible');
  }, []);

  useEffect(() => {
    let isActive = true;
    if (isVisible) {
      setTimeout(() => {
        if (isActive) {
          trackEvent('Usage - Visible for over five seconds');
        }
      }, 5000);

      return () => {
        isActive = false;
      };
    }
  }, [isVisible, trackEvent]);

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChanged);
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChanged);
    };
  }, [onVisibilityChanged, trackEvent]);
};

export default useTrackFocusUsage;
