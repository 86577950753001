import React from 'react';

import ListItemWrapper from '../../DatasetDefinitions/ListItemWrapper';
import Column from '../../DatasetDefinitions/Column';
import Typography from 'kingpin/atoms/Typography';
import FleetOpsStaffOnly from '../../../../components/Common/FleetOpsStaffOnly';

const MetricListHeaders = () => (
  <ListItemWrapper
    style={{
      alignItems: 'center',
      backgroundColor: '#F8F8F8',
      borderRadius: '8px',
      borderBottom: 'unset',
      boxShadow: 'unset',
      height: '48px',
    }}
  >
    <Column style={{ flex: 2 }}>
      <Typography.Body type="Label">Metric Name</Typography.Body>
    </Column>
    <Column style={{ flex: 2 }}>
      <Typography.Body type="Label">Description</Typography.Body>
    </Column>
    <Column>
      <Typography.Body type="Label">Dataset</Typography.Body>
    </Column>
    <FleetOpsStaffOnly>
      <Column style={{ flex: 2 }}>
        <Typography.Body type="Label">Field(s) [staff only]</Typography.Body>
      </Column>
    </FleetOpsStaffOnly>
    <Column>
      <Typography.Body type="Label">Usage</Typography.Body>
    </Column>
    <Column>
      <Typography.Body type="Label">Last Updated By</Typography.Body>
    </Column>
    {/* Hover buttons */}
    <Column />
  </ListItemWrapper>
);

export default MetricListHeaders;
