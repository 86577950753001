import { useEffect } from 'react';
import captureException from './services/captureException';

// Used to prevent a connection issue message showing in index.html
const AppLoadedWatcher = () => {
  useEffect(() => {
    try {
      window.appLoaded = true;
      const notLoadedMessage = document.getElementById(
        'fleetops-app-not-loaded-message',
      );
      if (notLoadedMessage) {
        document.body.removeChild(notLoadedMessage);
      }
    } catch (ex) {
      captureException(ex);
    }
  }, []);

  return null;
};

export default AppLoadedWatcher;
