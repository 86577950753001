const getGroupByField = (
  chartDefinition: V5ChartDefinition,
  autoInterval?: AutoInterval,
) => {
  if (chartDefinition.dimensionA) {
    if (
      chartDefinition.dimensionA.fieldType === 'date' &&
      !chartDefinition.dimensionB
    ) {
      if (chartDefinition.dimensionA.isGroupByDayOfWeek) {
        return 'dayOfWeek';
      }

      if (chartDefinition.trendByCalendarInterval === 'auto' && autoInterval) {
        return autoInterval.interval;
      } else {
        if (chartDefinition.gadgetType === 'matrix') {
          return 'date';
        }
        return chartDefinition.trendByCalendarInterval || '';
      }
    } else if (chartDefinition.dimensionA.fieldType === 'long') {
      return chartDefinition.dimensionA.field;
    } else {
      return chartDefinition.dimensionA.field;
    }
  }

  return '';
};

export default getGroupByField;
