import React from 'react';
import firebase from 'firebase/compat/app';

interface NotificationsContextType {
  notifications: Notifications.Notification[];
  groupedNotifications: {
    title: string;
    notifications: Notifications.Notification[];
  }[];
  isLoading: boolean;
  isPanelOpen: boolean;
  openPanel: () => void;
  closePanel: () => void;
  markAllNotificationsAsSeen: () => Promise<void>;
  markAllNotificationsAsRead: () => Promise<void>;
  deleteAllNotifications: () => Promise<void>;
  getNotificationRef: (
    id: string,
  ) =>
    | firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
    | undefined;
  selectedNotificationId?: string;
  setSelectedNotificationId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  hasUnReadNotifications: boolean;
  hasUnSeenNotifications: boolean;
  sendNotification: (
    notification: Notifications.Notification,
    destinationUserId: string,
  ) => Promise<void>;
}

const NotificationsContext = React.createContext<NotificationsContextType>({
  notifications: [],
  groupedNotifications: [],
  isLoading: true,
  isPanelOpen: false,
  openPanel: () => {},
  closePanel: () => {},
  markAllNotificationsAsSeen: async () => {},
  markAllNotificationsAsRead: async () => {},
  deleteAllNotifications: async () => {},
  getNotificationRef: () => undefined,
  setSelectedNotificationId: () => {},
  hasUnReadNotifications: false,
  hasUnSeenNotifications: false,
  sendNotification: () => Promise.resolve(),
});

export default NotificationsContext;
