import React from 'react';

interface AvailableWorkSpacesContextType {
  availableWorkSpaces: (WorkSpace | TargetsApp.App)[];
  isLoading: boolean;
}

const AvailableWorkSpacesContext =
  React.createContext<AvailableWorkSpacesContextType>({
    availableWorkSpaces: [],
    isLoading: true,
  });

export default AvailableWorkSpacesContext;
