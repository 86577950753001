import { useContext } from 'react';
import GoalsContext from '../contexts/GoalsContext';
import ScorecardsContext from '../contexts/ScorecardsContext';
import WorkSpacesContext from '../contexts/WorkSpacesContext';

const useTaskItem = (task: Tasks.Task | undefined) => {
  const { goals } = useContext(GoalsContext);
  const { scorecards } = useContext(ScorecardsContext);
  const { allWorkSpaces } = useContext(WorkSpacesContext);

  if (task === undefined) {
    return undefined;
  }

  if (task.taskableType === 'Goal') {
    return goals.find((g) => g.id === task.taskableId);
  } else if (task.taskableType === 'Scorecard') {
    return scorecards.find((s) => s.id === task.taskableId);
  } else if (task.taskableType === 'WorkSpace') {
    return allWorkSpaces.find((s) => s.id === task.taskableId);
  } else {
    const error = new Error();
    error.name = `Unknown item type: ${task.taskableType}`;
    throw error;
  }
};

export default useTaskItem;
