import React, { ChangeEvent, useCallback, useContext, useState } from 'react';
import DatasetItem from './DatasetItem';
import { useDataTypeLastUpdatedAt } from '../../../../../../hooks/dashboards/useLastUpdatedAt';
import withoutNulls from '../../../../../../api/search/withoutNulls';
import BaseViewsContext from '../../../../../../contexts/BaseViewsContext';
import AnalyticsContext from '../../../../../../contexts/AnalyticsContext';
import useGetDatasetDefinition from '../../../../../../hooks/useGetDatasetDefinition';

const DatasetItemContainer = ({
  baseView,
  status,
}: {
  baseView: FleetOps.BaseView;
  status?: FleetOps.PerformanceDatasetStatus;
}) => {
  const { updateBaseView } = useContext(BaseViewsContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const { timeAgo: lastUpdatedAt } = useDataTypeLastUpdatedAt(baseView.type);
  const [currentName, setCurrentName] = useState<string>(
    baseView.nameAlias || '',
  );
  const getDatasetDefinition = useGetDatasetDefinition();

  const onDisplayNameChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newName = event.target.value;
      const newBaseView = {
        ...baseView,
        nameAlias: newName === '' ? undefined : newName,
      };
      updateBaseView(newBaseView);
      setCurrentName(newName);

      trackEvent('Datasets - Dataset - Display Name Changed');
    },
    [baseView, trackEvent, updateBaseView],
  );

  const onIsHiddenChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newIsHidden = event.target.checked;
      const newBaseView = (() => {
        if (newIsHidden) {
          return {
            ...baseView,
            isHidden: newIsHidden,
          };
        } else {
          const newView = { ...baseView };
          delete newView['isHidden'];
          return newView;
        }
      })();

      updateBaseView(withoutNulls(newBaseView));
    },
    [baseView, updateBaseView],
  );

  const onDatasetLinkClicked = useCallback(() => {
    const datasetDef = getDatasetDefinition(baseView.type);
    trackEvent('Data Manager - Dataset Opened', {
      dataset: baseView.type,
      isPerformanceDataset:
        datasetDef && datasetDef.isPerformance ? 'true' : 'false',
    });
  }, [baseView.type, getDatasetDefinition, trackEvent]);

  return (
    <DatasetItem
      status={status}
      currentName={currentName}
      baseView={baseView}
      lastUpdatedAt={lastUpdatedAt}
      onIsHiddenChanged={onIsHiddenChanged}
      onDisplayNameChanged={onDisplayNameChanged}
      onDatasetLinkClicked={onDatasetLinkClicked}
    />
  );
};

export default DatasetItemContainer;
