import React, { ChangeEvent, useContext, useState } from 'react';
import NewQuickFilterForm from './NewQuickFilterForm';
import aguid from 'aguid';

import ReportDrillDownsContext from '../../../../../contexts/ReportDrillDownsContext';
import createSavedFilter from '../../../../../api/createSavedFilter';
import BoardContext from '../../../../../contexts/BoardContext';
import AccountPickerContext from '../../../../../contexts/AccountPickerContext';
import ReportDrillDownsProvider from '../../../../../contextProviders/ReportDrillDownsProvider';

const NewQuickFilterFormContainer = ({ close }: { close: () => void }) => {
  const { accountRef } = useContext(AccountPickerContext);
  const { dataTypes } = useContext(ReportDrillDownsContext);
  const { board } = useContext(BoardContext);
  const [name, setName] = useState<string>('');
  const [variableDrillDowns] = useState<VariableDrillDownType[]>([]);
  const [drillDowns, setDrillDowns] = useState<FilterPlate[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const onNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const isValid = drillDowns.length !== 0;

  const onSave = () => {
    if (!isValid) {
      alert('Please input at least one filter');
    }

    const filter = {
      version: '2',
      id: aguid(),
      scope: [],
      drillDowns,
      source: `${board.dataType} - board: ${board.id}`,
      name,
      dateField: 'date',
    } as SavedFilter;

    setIsSaving(true);
    createSavedFilter(filter, accountRef).then(() => {
      setIsSaving(false);
      close();
    });
  };

  return (
    <ReportDrillDownsProvider
      drillDowns={drillDowns}
      setDrillDowns={setDrillDowns}
      variableDrillDowns={variableDrillDowns}
      dataTypes={dataTypes}
    >
      <NewQuickFilterForm
        name={name}
        onNameChanged={onNameChanged}
        close={close}
        onSave={onSave}
        isSaving={isSaving}
        isValid={isValid}
      />
    </ReportDrillDownsProvider>
  );
};

export default NewQuickFilterFormContainer;
