import React from 'react';
import styled from 'styled-components';
import { TABS } from '../../../propTypes/settings';
import Typography from 'kingpin/atoms/Typography';
import Base from '../Base';
import WhatIsSso from './WhatIsSso';
import Microsoft from './Microsoft';
import ErrorBanner, { SSO_SETUP_ERROR_MSG_KEY } from '../../Login/ErrorBanner';
import FeatureGate, { FEATURE_GATES } from '../../../components/FeatureGate';

const ScreenContent = styled.div`
  padding: 32px;
`;

const AvailableDiv = styled.div`
  margin: 24px 0px;
`;

const Available = () => (
  <AvailableDiv>
    <Typography.Header type="H5">Available</Typography.Header>
  </AvailableDiv>
);

const SsoConfigurationsContainer = () => {
  return (
    <FeatureGate featureName={FEATURE_GATES.MICROSOFT_SSO}>
      <Base selectedTab={TABS.SSO} title="SSO Configurations">
        <ScreenContent>
          <ErrorBanner errorKey={SSO_SETUP_ERROR_MSG_KEY} />
          <WhatIsSso />
          <Available />
          <Microsoft.IntegrationCard />
        </ScreenContent>
      </Base>
    </FeatureGate>
  );
};

export default SsoConfigurationsContainer;
