import moment from 'moment-timezone';
import getCalendarComparison from './getCalendarComparison';
import getCurrentDateRange from './getCurrentDateRange';
import getComparisonPeriod from './getComparisonPeriod';
import getVisSpecificComparison from './getVisSpecificComparison';
import captureException from '../../../services/captureException';

interface BuildDateRangeProps {
  relativeDateRange?: RelativeDateRange;
  comparison?: PersistedComparisonType;
  weekStartsOn: WeekStartsOn;
  dateRange?: DateRangeInput;
  advancedRelativeDateRange?: AdvancedRelativeDateRange;
  chartDef?: VisualisationDefinition;
  isMetricListItem?: boolean;
  selectedBonusPeriod?: BonusPeriod;
  bonusPeriods?: BonusPeriod[];
  selectedPeriod?: Period;
  periods: Period[];
  timezone?: string;
  isScorecardPopupReport?: boolean;
  isUpcomingEventsBoard?: boolean;
}

const getDateRange = ({
  relativeDateRange,
  comparison,
  weekStartsOn,
  advancedRelativeDateRange,
  dateRange,
  chartDef,
  isMetricListItem,
  selectedBonusPeriod,
  bonusPeriods,
  selectedPeriod,
  periods,
  isScorecardPopupReport,
  isUpcomingEventsBoard,
}: BuildDateRangeProps): DateRangeInput => {
  const currentDateRange = getCurrentDateRange({
    relativeDateRange,
    weekStartsOn,
    advancedRelativeDateRange,
    dateRange,
    selectedBonusPeriod,
    selectedPeriod,
    isScorecardPopupReport,
    isUpcomingEventsBoard,
  });
  if (!currentDateRange) {
    return {};
  }
  if (!comparison) {
    return currentDateRange;
  }

  const comparisonPeriod = getComparisonPeriod({
    comparison,
    selectedBonusPeriod,
    bonusPeriods,
    selectedPeriod,
    periods,
    isScorecardPopupReport,
  });
  if (comparisonPeriod) {
    return comparisonPeriod;
  }

  const visSpecificComparison = getVisSpecificComparison({
    relativeDateRange,
    comparison,
    chartDef,
    isMetricListItem,
    currentDateRange,
  });
  if (visSpecificComparison) {
    return visSpecificComparison;
  }

  return getCalendarComparison(
    currentDateRange,
    comparison,
    relativeDateRange ? relativeDateRange.interval : undefined,
    relativeDateRange,
  );
};

const captureTimezoneNotSet = () => {
  const e = new Error();
  e.name = 'Timezone not set for carrier';
  captureException(e);
};

const applyToLastHour = (
  dateRange: DateRangeInput,
  timezone?: string,
  isExtraDayRequired?: boolean,
): DateRangeInput => {
  if (!timezone) {
    captureTimezoneNotSet();
    return dateRange;
  }

  const { startDate, endDate, dateField } = dateRange;
  const newStart = startDate
    ? moment.tz(startDate, timezone).format('YYYY-MM-DDTHH:mm:ss')
    : undefined;
  const lastHour = moment().tz(timezone).hour();
  const newEnd = endDate
    ? moment
        .tz(endDate, timezone)
        .add({ day: isExtraDayRequired ? 1 : 0 })
        .set('hour', lastHour)
        .format('YYYY-MM-DDTHH:mm:ss')
    : undefined;
  return {
    startDate: newStart,
    endDate: newEnd,
    dateField,
  };
};

const buildDateRange = (props: BuildDateRangeProps): DateRangeInput => {
  const dateRangeResult = getDateRange(props);

  if (props.relativeDateRange) {
    const { currentToDate, type, interval } = props.relativeDateRange;
    if (currentToDate && type !== 'next') {
      const isExtraDayRequired =
        ['week', 'month'].includes(interval) &&
        currentToDate &&
        type === 'last';
      return applyToLastHour(
        dateRangeResult,
        props.timezone,
        isExtraDayRequired,
      );
    }
  }

  return dateRangeResult;
};

export default buildDateRange;
