import usePopup from '../../hooks/usePopup';
import NavItem from './NavItem';
import React, { useContext } from 'react';
import styled from 'styled-components';
import InlineDialog from '../../components/InlineDialog';
import Row from '../../components/Common/Row';
import Icon from '../atoms/Icon';
import Typography from '../atoms/Typography';
import Button from '../atoms/Button';
import { PortalsContext } from '../../contextProviders/PortalsProvider';
import { Z_INDEX } from '../../constants';

const OrangeDot = styled.div`
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #ffc381;
  right: 0px;
  z-index: ${Z_INDEX.SIDE_NAV_TOGGLE};
`;

const TryOurNewUiDiv = styled.div`
  padding: 16px 24px;
`;

const TryOutNewUI = () => {
  const { onPreviewNewUiClicked } = useContext(PortalsContext);

  return (
    <TryOurNewUiDiv>
      <Row style={{ gap: 8, marginBottom: 16 }} centerAlign>
        <Icon icon="speaker" height={16} width={20} />
        <Typography.Body type={'Label'}>Try Out Our New Layout</Typography.Body>
      </Row>
      <Row style={{ justifyContent: 'center' }}>
        <div style={{ width: 80 }}>
          <Button
            size={'Small'}
            type={'Primary'}
            onClick={onPreviewNewUiClicked}
            label={'Try Now'}
          />
        </div>
      </Row>
    </TryOurNewUiDiv>
  );
};

const PortalPreviewButton = () => {
  const { isOpen, open, close } = usePopup();

  return (
    <InlineDialog content={<TryOutNewUI />} onClose={close} isOpen={isOpen}>
      <div style={{ height: 36, position: 'relative', marginLeft: 8 }}>
        <OrangeDot />
        <NavItem
          testId={'portal-preview-button'}
          to={'#'}
          isActiveOverride={false}
          icon={'ui'}
          iconColor={'#666666'}
          onClick={open}
        />
      </div>
    </InlineDialog>
  );
};

export default PortalPreviewButton;
