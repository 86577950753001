import { useCallback, useEffect, useState } from 'react';

const useConstraints = ({
  noCompound,
  noSpecial,
  datasetMustIncludeField,
  metricIdsToConstrainBy,
  hideDatasetsWithNoMetrics,
  metricIdRedList,
  aggFuncRedList,
  aggFuncGreenList,
  datasetRedList,
  fieldRedList,
}: {
  noCompound?: boolean;
  noSpecial?: boolean;
  datasetMustIncludeField?: string;
  metricIdsToConstrainBy?: string[];
  hideDatasetsWithNoMetrics?: boolean;
  metricIdRedList?: string[];
  aggFuncRedList?: AggFunc[];
  aggFuncGreenList?: AggFunc[];
  datasetRedList?: string[];
  fieldRedList?: string[];
}) => {
  const getConstraints = useCallback(() => {
    return {
      noCompound,
      noSpecial,
      datasetMustIncludeField,
      datasetMustBeSharedWithMetricIds: metricIdsToConstrainBy
        ? metricIdsToConstrainBy.length === 0
          ? undefined
          : metricIdsToConstrainBy
        : undefined,
      hideDatasetsWithNoMetrics,
      metricIdRedList,
      aggFuncRedList,
      aggFuncGreenList,
      datasetRedList,
      fieldRedList,
    };
  }, [
    noCompound,
    noSpecial,
    datasetMustIncludeField,
    metricIdsToConstrainBy,
    hideDatasetsWithNoMetrics,
    metricIdRedList,
    aggFuncRedList,
    aggFuncGreenList,
    datasetRedList,
    fieldRedList,
  ]);
  const [constraints, setConstraints] = useState<MetricPickerOptionConstraints>(
    () => getConstraints(),
  );
  useEffect(() => {
    setConstraints(getConstraints());
  }, [getConstraints]);

  return constraints;
};

export default useConstraints;
