const getIsHistogram = (chartDef?: V5ChartDefinition): boolean => {
  if (!chartDef) {
    return false;
  }

  if (
    chartDef.dimensionA &&
    chartDef.dimensionA.fieldType &&
    (chartDef.dimensionA.fieldType === 'dateText' ||
      chartDef.dimensionA.fieldType === 'date')
  ) {
    return true;
  }

  if (chartDef.gadgetType === 'stackedArea') {
    return true;
  }
  return false;
};

export default getIsHistogram;
