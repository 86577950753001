import withoutNulls from '../expression/withoutNulls';
import STORE from '../../store';

const updateMetricListGadget = async (
  newMetricList: MetricListGadgetType,
  accountId: string,
) => {
  return STORE.visualisations
    .getMetricListsRef({ accountId })
    .doc(newMetricList.id)
    .set(withoutNulls(newMetricList));
};

export default updateMetricListGadget;
