import { useCallback, useContext, useEffect, useState } from 'react';
import { EngagementPortalsContext } from './EngagementPortalsProvider';
import { ExecutivePortalsContext } from './ExecutivePortalsProvider';

export interface PublishedLookup {
  reports: {
    [reportId: string]: (ExecutivePortal | EngagementPortal)[] | undefined;
  };
  dashboards: {
    [dashboardId: string]: (ExecutivePortal | EngagementPortal)[] | undefined;
  };
  scorecards: {
    [scorecardId: string]: (ExecutivePortal | EngagementPortal)[] | undefined;
  };
}

const usePublishedLookup = () => {
  const { allEngagementPortals } = useContext(EngagementPortalsContext);
  const { allExecutivePortals } = useContext(ExecutivePortalsContext);

  const buildLookup = useCallback((): PublishedLookup => {
    const portals = [...allExecutivePortals, ...allEngagementPortals];
    const newLookup: PublishedLookup = {
      reports: {},
      dashboards: {},
      scorecards: {},
    };

    portals.forEach((portal) => {
      portal.reportIds.forEach((reportId: string) => {
        const current = newLookup.reports[reportId] || [];
        if (!current.some((p) => p.id === portal.id)) {
          current.push(portal);
          newLookup.reports[reportId] = current;
        }
      });

      portal.dashboardIds.forEach((dashboardId: string) => {
        const current = newLookup.dashboards[dashboardId] || [];
        if (!current.some((p) => p.id === portal.id)) {
          current.push(portal);
          newLookup.dashboards[dashboardId] = current;
        }
      });

      portal.scorecardIds.forEach((scorecardId: string) => {
        const current = newLookup.scorecards[scorecardId] || [];
        if (!current.some((p) => p.id === portal.id)) {
          current.push(portal);
          newLookup.scorecards[scorecardId] = current;
        }
      });
    });

    return newLookup;
  }, [allEngagementPortals, allExecutivePortals]);

  const [publishedLookup, setPublishedLookup] = useState<PublishedLookup>(() =>
    buildLookup(),
  );

  useEffect(() => {
    setPublishedLookup(buildLookup());
  }, [buildLookup]);

  return publishedLookup;
};

export default usePublishedLookup;
