import React, { ChangeEvent } from 'react';

import MetricFormStyles from '../styles';
import Inputs from 'components/Inputs';
import Typography from 'kingpin/atoms/Typography';
import { Section } from '../../../../../../components/MyAccount/Profile/styles';
import FormattingInput from '../../../../../../components/FormattingInput';
import ReportDrillDowns from '../../../../../../components/ReportDrillDowns/ReportDrillDowns';
import PerformanceMetricWarning from './PerformanceMetricWarning';
import withDateFilter from '../../../../../../hocs/withDateFIlter';
import { LAST_365_DAYS_INCLUDING_TODAY } from '../../../../../../components/DateInput/constants';
import DatasetFilterBadges from './DatasetFilterBadges';

const MetricForm = ({
  name,
  onNameChanged,
  description,
  onDescriptionChanged,
  formatting,
  setFormatting,
  dataSource,
  dataSourceOptions,
  field,
  fieldOptions,
  aggFunc,
  aggFuncOptions,
  drillDowns,
  onAddFilterClicked,
  onFilterAdded,
  onFilterRemoved,
  isDatasetDisabled,
  metricDraft,
}: {
  name: string;
  onNameChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  description: string;
  onDescriptionChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  formatting: MetricFormatting;
  setFormatting: React.Dispatch<React.SetStateAction<MetricFormatting>>;
  dataSource?: string;
  dataSourceOptions: DropdownOption[];
  field: string;
  fieldOptions: DropdownOption[];
  aggFunc?: AggFunc;
  aggFuncOptions: DropdownOption[];
  drillDowns: FilterPlate[];
  onAddFilterClicked: () => void;
  onFilterAdded: () => void;
  onFilterRemoved: () => void;
  isDatasetDisabled: boolean;
  metricDraft?: Metrics.NormalMetric;
}) => {
  return (
    <MetricFormStyles.OuterDiv data-testid="edit-normal-metric-form">
      <MetricFormStyles.InnerDiv>
        <PerformanceMetricWarning metric={metricDraft} />
        <Section>
          <div style={{ marginBottom: 16 }}>
            <Typography.Body type="Label">Name</Typography.Body>
            <Inputs.TextInput
              autoFocus
              value={name}
              onChange={onNameChanged}
              data-testid="name"
              inputSize="Small"
              width={'100%'}
            />
          </div>
          <div style={{ marginBottom: 16 }}>
            <Typography.Body type="Label">Description</Typography.Body>
            <Inputs.TextInput
              value={description}
              onChange={onDescriptionChanged}
              inputSize="Small"
              width={'100%'}
            />
          </div>
          <div style={{ marginBottom: 16 }}>
            <Typography.Body type="Label">Dataset</Typography.Body>
            <Inputs.Dropdown
              options={dataSourceOptions}
              selectedValue={dataSource}
              unsetWidth
              placeholder="Choose dataset"
              isDisabled={isDatasetDisabled}
              isSearchEnabled
            />
            <DatasetFilterBadges drillDowns={drillDowns} />
          </div>
          <Section>
            <Typography.Body type="Label">Filters</Typography.Body>
            <div style={{ marginLeft: 1 }}>
              <ReportDrillDowns
                drillDowns={drillDowns}
                scope={window.emptyArray}
                onOpened={onAddFilterClicked}
                onFilterAdded={onFilterAdded}
                onFilterRemoved={onFilterRemoved}
                isDateScopeDisabled
              />
            </div>
          </Section>
          <div style={{ marginBottom: 16 }}>
            <Typography.Body type="Label">Field</Typography.Body>
            <Inputs.Dropdown
              testId={`metric-field-dropdown`}
              options={fieldOptions}
              selectedLabel={field}
              isDisabled={dataSource === undefined || dataSource === ''}
              unsetWidth
              placeholder="Choose field"
              isSearchEnabled
            />
          </div>
          <div style={{ marginBottom: 16 }}>
            <Typography.Body type="Label">Aggregation</Typography.Body>
            <Inputs.Dropdown
              options={aggFuncOptions}
              selectedLabel={aggFunc}
              unsetWidth
              placeholder="Choose aggregation function"
            />
          </div>
        </Section>
        <Section>
          <div style={{ marginBottom: 8 }}>
            <Typography.Body type="Label">
              Formatting (optional)
            </Typography.Body>
          </div>

          <FormattingInput
            formatting={formatting}
            setFormatting={setFormatting}
          />
        </Section>
      </MetricFormStyles.InnerDiv>
    </MetricFormStyles.OuterDiv>
  );
};

export default withDateFilter(MetricForm, {
  initialRelativeDateRange: LAST_365_DAYS_INCLUDING_TODAY,
});
