import { useCallback, useContext } from 'react';
import BaseViewsContext from '../../../../../contexts/BaseViewsContext';
import toSentenceCase from '../../../../../services/toSentenceCase';

const useGetDatasetLabel = () => {
  const { baseViews: baseViewDefinitions } = useContext(BaseViewsContext);

  const getDatasetLabel = useCallback(
    (dataset: FleetOps.DatasetDefinition | string) => {
      const type = typeof dataset === 'string' ? dataset : dataset.type;
      const bv = baseViewDefinitions[type];
      if (bv && bv.nameAlias) {
        return bv.nameAlias;
      }

      return toSentenceCase(type);
    },
    [baseViewDefinitions],
  );

  return getDatasetLabel;
};

export default useGetDatasetLabel;
