import React, { useContext, useEffect, useState } from 'react';
import EndCommitmentPopup from './EndCommitmentPopup';
import usePopup from '../../../hooks/usePopup';
import CommitmentQueryContext from '../../../contexts/CommitmentQueryContext';
import setCommitment from '../../../api/commitments/setCommitment';
import AccountPickerContext from '../../../contexts/AccountPickerContext';

const EndCommitmentPopupContainer = () => {
  const { mode, selectedCommitmentDoc, setSelectedCommitmentDoc, setMode } =
    useContext(CommitmentQueryContext);
  const { accountRef } = useContext(AccountPickerContext);

  // State
  const [draftCommit, setDraftCommit] = useState<Commitment | undefined>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { isOpen, open, close } = usePopup();
  const endDate = draftCommit ? draftCommit.endDate : undefined;

  // Effects
  useEffect(() => {
    if (!isOpen) {
      if (mode === 'end' && !!selectedCommitmentDoc) {
        open();
        setDraftCommit({ ...selectedCommitmentDoc });
      }
    }
  }, [isOpen, mode, open, selectedCommitmentDoc]);

  // Interactions
  const onEndDateChanged = (date: string) => {
    if (!draftCommit) {
      return;
    }

    if (date === '') {
      const newCommit = {
        ...draftCommit,
      };
      delete newCommit['endDate'];
      setDraftCommit(newCommit);
    } else {
      const newCommit = {
        ...draftCommit,
        endDate: date,
      };
      setDraftCommit(newCommit);
    }
  };

  const onClose = () => {
    setSelectedCommitmentDoc(undefined);
    setDraftCommit(undefined);
    setMode('create');
    close();
  };

  const onEndConfirmed = () => {
    if (!draftCommit) {
      return;
    }

    setIsSaving(true);
    setCommitment(draftCommit, accountRef).then(() => {
      setIsSaving(false);
      onClose();
    });
  };

  return (
    <EndCommitmentPopup
      isOpen={isOpen}
      close={onClose}
      onEndConfirmed={onEndConfirmed}
      endDate={endDate}
      onEndDateChanged={onEndDateChanged}
      isSaving={isSaving}
    />
  );
};

export default EndCommitmentPopupContainer;
