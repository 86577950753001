import React, { useCallback, useContext, useState } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import BulkGridActionsContext from '../../contexts/BulkGridActionsContext';
import Inputs from '../Inputs';

const SalesCommissionCell = (props: ICellRendererParams) => {
  const { selectedItems, setSelectedItems } = useContext(
    BulkGridActionsContext,
  );
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const id = props.node.data.id;
  const isTotalsRow = props.node.isRowPinned();
  const isSelected = isTotalsRow
    ? allSelected
    : selectedItems.some((item) => item === id);

  const onToggled = useCallback(() => {
    if (props.node.isRowPinned()) {
      if (!allSelected) {
        // @ts-ignore
        const allDocs = props.api.rowModel.datasource.documents.map(
          // @ts-ignore
          (d) => d.id,
        );
        setSelectedItems(allDocs);
        setAllSelected(true);
      } else {
        setSelectedItems([]);
        setAllSelected(false);
      }
      return;
    }

    if (isSelected) {
      setSelectedItems((currentItems) => currentItems.filter((i) => i !== id));
    } else {
      setSelectedItems((currentItems) => [...currentItems, id]);
    }
  }, [allSelected, id, isSelected, props.api, props.node, setSelectedItems]);

  const testId = isTotalsRow ? 'bulk-sales-totals' : `bulk-sales-${id}`;

  return (
    <Inputs.Checkbox
      isChecked={isSelected}
      onToggled={onToggled}
      testId={testId}
    />
  );
};

export default SalesCommissionCell;
