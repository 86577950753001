import { useContext, useEffect, useState } from 'react';
import * as _ from 'lodash';
import AccountPickerContext from '../../contexts/AccountPickerContext';
import STORE from '../../store';

const useGoals = () => {
  const [goals, setGoals] = useState<GeneralGoal[]>([]);
  const [isLoadingGoals, setIsLoadingGoals] = useState<boolean>(true);
  const { selectedAccountId, accountRef } = useContext(AccountPickerContext);
  useEffect(() => {
    setIsLoadingGoals(true);
    const listener = STORE.visualisations
      .getGoalsRef({ accountId: selectedAccountId })
      .onSnapshot(async (snapshot) => {
        const data: GeneralGoal[] = [];
        snapshot.docs.forEach((d) => {
          data.push(d.data());
        });
        const sorted = _.sortBy(data, 'name');
        setGoals(sorted.filter((g) => !g.isHidden));
        setIsLoadingGoals(false);
      });

    return () => {
      listener();
    };
  }, [accountRef, selectedAccountId]);

  return {
    goals,
    isLoadingGoals,
  };
};

export default useGoals;
