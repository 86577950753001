import moment from 'moment';

export const wasToday = (timestamp) => {
  const t = moment.utc(timestamp);
  const startOfToday = moment.utc().startOf('day');
  const endOfToday = moment.utc().endOf('day');
  return t.isBetween(startOfToday, endOfToday);
};

export const isFuture = (timestamp) => {
  const t = moment.utc(timestamp);
  const now = moment.utc();
  return now.diff(t) <= 0;
};

export const isPast = (timestamp) => !isFuture(timestamp);

export const within24Hours = (timestamp) => {
  const t = moment.utc(timestamp);
  const now = moment.utc();
  const diff = Math.abs(now.diff(t));
  return diff < 1000 * 60 * 60 * 24;
};

export const wasYesterday = (timestamp) => {
  const t = moment.utc(timestamp);
  const startOfYesterday = moment.utc().subtract(1, 'day').startOf('day');
  const endOfYesterday = moment.utc().subtract(1, 'day').endOf('day');

  return t.isBetween(startOfYesterday, endOfYesterday);
};

const friendlyTimestampFormat = (timestamp) => {
  try {
    const t = moment.utc(timestamp);
    if (wasToday(t)) {
      return `Today at ${t.format('HH:mm')}`;
    }

    if (wasYesterday(t)) {
      return `Yesterday at ${t.format('HH:mm')}`;
    }

    return t.format('DD/MM/YYYY HH:mm');
  } catch (ex) {
    return timestamp;
  }
};

export default friendlyTimestampFormat;
