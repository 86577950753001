import { useContext, useEffect, useState } from 'react';
import getReminderEventNames from './getReminderEventNames';
import GqlClientContext from '../../contexts/GqlClientContext';

const useEventTypes = () => {
  const { client } = useContext(GqlClientContext);
  const [eventTypes, setEventTypes] = useState<string[]>([]);

  useEffect(() => {
    getReminderEventNames(client).then(setEventTypes);
  }, [client]);

  return eventTypes;
};

export default useEventTypes;
