import React, { useContext } from 'react';
import styled from 'styled-components';
import NewCommitButton from '../NewCommitButton';
import Grid from './Grid';
import QuickFilters from './QuickFilters';
import ReportDrillDowns from '../ReportDrillDowns';
import Row from '../Common/Row';
import Settings from './Settings';
import OmniSearch from '../OmniSearch';
import DeleteCommitmentPopup from './DeleteCommitmentPopup';
import EndCommitmentPopup from './EndCommitmentPopup';
import PermissionGates from '../PermissionGates';
import PERMISSIONS from '../../permissionDefinitions';
import { CUSTOMER_LANE_COMMITMENTS, TOP_BAR_HEIGHT } from '../../constants';
import WorkSpaceContext from '../../contexts/WorkSpaceContext';
import BoardContext from '../../contexts/BoardContext';
import Typography from 'kingpin/atoms/Typography';
import Colors2 from '../../theme/Colors2';

export const BoardTopBar = styled.div`
  min-height: ${TOP_BAR_HEIGHT}px;
  height: ${TOP_BAR_HEIGHT}px;
  border-bottom: 1px solid ${Colors2.Border};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px;
  background-color: white;
`;

export const BoardFilterBar = styled.div<{ hasBorder?: boolean }>`
  min-height: ${TOP_BAR_HEIGHT}px;
  height: ${TOP_BAR_HEIGHT}px;
  padding: 0px 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-bottom: ${(props) =>
    props.hasBorder ? `1px solid ${Colors2.Border}` : 'unset'};
`;

export const BoardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

export const BoardMainContent = styled.div<{
  isWorkSpace: boolean;
  hasQuickFilters: boolean;
}>`
  flex: 1;
  padding: 0px 24px 16px 24px;
  overflow: hidden;
`;

const D_DATA_TYPES = [CUSTOMER_LANE_COMMITMENTS];

const CustomerLaneCommitBoard = ({
  board,
  onQuickFilterChanged,
  onManualFilterChanged,
}: {
  board: Board;
  onQuickFilterChanged: () => void;
  onManualFilterChanged: () => void;
}) => {
  const { quickFilters } = useContext(BoardContext);
  const { workSpace } = useContext(WorkSpaceContext);
  const isWorkSpace = !!workSpace;
  const hasQuickFilters = quickFilters.length > 0;

  return (
    <BoardWrapper data-testid="CustomerLaneCommitBoard">
      <BoardTopBar>
        <Typography.Header type="H5">{board.name}</Typography.Header>
        <PermissionGates.Has
          requiredPermission={
            PERMISSIONS.CUSTOMER_COMMITMENTS.MANAGE_COMMITMENTS
          }
        >
          <Row centerAlign>
            <NewCommitButton />
            <Settings />
          </Row>
        </PermissionGates.Has>
      </BoardTopBar>
      <BoardFilterBar hasBorder={hasQuickFilters}>
        <div style={{ marginRight: 8, width: 150 }}>
          <OmniSearch.DrillDownBuilder
            dataTypes={D_DATA_TYPES}
            onManualFilterChanged={onManualFilterChanged}
          />
        </div>
        <ReportDrillDowns onManualFilterChanged={onManualFilterChanged} />
      </BoardFilterBar>
      {hasQuickFilters && (
        <BoardFilterBar>
          <QuickFilters onQuickFilterChanged={onQuickFilterChanged} />
        </BoardFilterBar>
      )}
      <BoardMainContent
        isWorkSpace={isWorkSpace}
        hasQuickFilters={hasQuickFilters}
      >
        <Grid />
      </BoardMainContent>
      <DeleteCommitmentPopup />
      <EndCommitmentPopup />
    </BoardWrapper>
  );
};

export default CustomerLaneCommitBoard;
