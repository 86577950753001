import { ApisauceInstance } from 'apisauce';

const acceptInvitation = ({
  token,
  displayName,
  email,
  password,
  api,
}: {
  token: string;
  displayName: string;
  email: string;
  password: string;
  api: ApisauceInstance;
}) =>
  api.post<{ message?: string }, { message: string }>(`/invitations/accept`, {
    token,
    displayName,
    email,
    password,
  });

export default acceptInvitation;
