import React, { useCallback, useContext, useEffect, useState } from 'react';

import GeneralGoalListItem from './GeneralGoalListItem';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import GeneralGoalContext from '../../../../contexts/GeneralGoalContext';
import useMetric from '../../../../hooks/useMetric';
import {
  buildShowCampaignGoal,
  buildShowGlobalGoal,
} from '../../../../navigation/appRoutes';
import WorkSpaceContext from '../../../../contexts/WorkSpaceContext';
import visTypeCheckers from '../../../../types/visTypeCheckers';
import useValueFormatters from '../../../../hooks/useValueFormatters';

export const useGoalLink = (goal: GeneralGoal) => {
  const { generalWorkSpace } = useContext(WorkSpaceContext);
  const buildLink = useCallback(() => {
    if (generalWorkSpace) {
      const tab = generalWorkSpace.tabs.find((t) => t.type === 'goals');
      if (tab) {
        return buildShowCampaignGoal(goal.id, generalWorkSpace.id, tab.typeId);
      }
    }

    return buildShowGlobalGoal(goal.id);
  }, [generalWorkSpace, goal.id]);
  const [link, setLink] = useState(buildLink());

  useEffect(() => {
    setLink(buildLink());
  }, [buildLink]);

  return link;
};

const GeneralGoalListItemContainer = ({
  goal,
  dragHandleProps,
}: {
  goal: GeneralGoal;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
}) => {
  const { target } = useContext(GeneralGoalContext);
  const link = useGoalLink(goal);
  const [isAboveTarget, setIsAboveTarget] = useState<boolean | undefined>(
    undefined,
  );
  const { formatMetric } = useValueFormatters();
  const [timeText, setTimeText] = useState<string>();
  const [timeTooltip, setTimeTooltip] = useState<string>();

  const metric = useMetric(
    visTypeCheckers.isGeneralGoal(goal) ? goal.metricId : undefined,
  );

  const targetFormatted = formatMetric({
    metricId: goal.metricId,
    value: target,
  });
  const subTitle = metric ? metric.name : '';

  return (
    <GeneralGoalListItem
      subTitle={subTitle}
      goal={goal}
      targetFormatted={targetFormatted}
      timeText={timeText}
      setTimeText={setTimeText}
      timeTooltip={timeTooltip}
      setTimeTooltip={setTimeTooltip}
      isAboveTarget={isAboveTarget}
      setIsAboveTarget={setIsAboveTarget}
      dragHandleProps={dragHandleProps}
      link={link}
    />
  );
};

export default GeneralGoalListItemContainer;
