import React, { useCallback, useContext, useState } from 'react';
import CantSignInForm from './CantSignInForm';
import sendPasswordResetEmailUnauthenticated from '../../api/sendPasswordResetEmailUnauthenticated';
import Requested from './Requested';
import { useNavigate } from 'react-router-dom';
import appRoutes from '../../navigation/appRoutes';
import CloudFunctionClientContext from '../../contexts/CloudFunctionClientContext';

const CantSignInFormContainer = () => {
  const { api } = useContext(CloudFunctionClientContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [requested, setRequested] = useState(false);
  const [email, setEmail] = useState<string>('');
  const [requestedEmail, setRequestedEmail] = useState('');

  const onEmailChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const onSubmit = () => {
    setIsLoading(true);
    sendPasswordResetEmailUnauthenticated(email, api).then(() => {
      setRequestedEmail(email);
      setIsLoading(false);
      setRequested(true);
    });
  };

  const onBackClicked = useCallback(() => {
    navigate(appRoutes.home);
  }, [navigate]);

  if (requested) {
    return <Requested email={requestedEmail} onBackClicked={onBackClicked} />;
  }

  return (
    <CantSignInForm
      isLoading={isLoading}
      onSubmit={onSubmit}
      email={email}
      onEmailChanged={onEmailChanged}
    />
  );
};

export default CantSignInFormContainer;
