import React, { useContext } from 'react';
import styled from 'styled-components';

import Row from '../../../Common/Row';
import OmniSearch from '../../../OmniSearch';
import CommitmentQueryContext from '../../../../contexts/CommitmentQueryContext';
import CustomerSearch from '../CustomerSearch';
import {
  DESTINATION_FIELDS,
  ORIGIN_FIELDS,
} from '../../../OmniSearch/constants';

const InputWrapper = styled.div`
  margin-right: 8px;
`;

const CustomerPicker = () => {
  return (
    <InputWrapper>
      <CustomerSearch />
    </InputWrapper>
  );
};

const OriginPicker = () => {
  const {
    originField,
    setOriginField,
    originValue,
    setOriginValue,
    queryFilterInput,
    mode,
  } = useContext(CommitmentQueryContext);

  return (
    <InputWrapper>
      <OmniSearch.ManualBuilder
        searchPlaceholder="Origin"
        searchFields={ORIGIN_FIELDS}
        field={originField}
        setField={setOriginField as (f: string | undefined) => void}
        value={originValue}
        setValue={setOriginValue}
        filterInput={queryFilterInput}
        isDisabled={mode === 'renew' || mode === 'modify'}
      />
    </InputWrapper>
  );
};

const DestinationPicker = () => {
  const {
    destinationField,
    setDestinationField,
    destinationValue,
    setDestinationValue,
    queryFilterInput,
    mode,
  } = useContext(CommitmentQueryContext);

  return (
    <InputWrapper>
      <OmniSearch.ManualBuilder
        searchPlaceholder="Destination"
        searchFields={DESTINATION_FIELDS}
        field={destinationField}
        setField={setDestinationField as (f: string | undefined) => void}
        value={destinationValue}
        setValue={setDestinationValue}
        filterInput={queryFilterInput}
        isDisabled={mode === 'renew' || mode === 'modify'}
      />
    </InputWrapper>
  );
};

const CommodityPicker = () => {
  const { commodity, setCommodity, queryFilterInput, mode } = useContext(
    CommitmentQueryContext,
  );

  return (
    <InputWrapper>
      <OmniSearch.ManualBuilder
        searchPlaceholder="Commodity"
        searchFields={['commodity']}
        value={commodity}
        setValue={setCommodity}
        filterInput={queryFilterInput}
        isDisabled={mode === 'renew' || mode === 'modify'}
      />
    </InputWrapper>
  );
};

const QueryInputRow = () => (
  <div>
    <Row centerAlign>
      <CustomerPicker />
      <OriginPicker />
      <DestinationPicker />
      <CommodityPicker />
    </Row>
  </div>
);

export default QueryInputRow;
