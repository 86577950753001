import { useCallback, useContext } from 'react';
import updateDashboard from '../../api/updateDashboard';
import withoutNulls from '../../api/expression/withoutNulls';
import CurrentUserContext from '../../contexts/CurrentUserContext';
import AccountPickerContext from '../../contexts/AccountPickerContext';
import DashboardsContext from '../../contexts/DashboardsContext';
import getTimeStamp from '../../getTimeStamp';

const useUpdateVariableFilters = (dashboardTemplate?: DashboardTemplate) => {
  const currentUser = useContext(CurrentUserContext);
  const { accountRef } = useContext(AccountPickerContext);
  const { addOrUpdateDashboard } = useContext(DashboardsContext);

  const updateVariableFilters = useCallback(
    (newVariableFilters: VariableDrillDownType[]) => {
      if (!dashboardTemplate) {
        return;
      }

      const newDashboard = {
        ...dashboardTemplate,
        variableDrillDowns: newVariableFilters,
        updatedOn: getTimeStamp(),
        updatedBy: currentUser.id,
      };

      updateDashboard({
        id: dashboardTemplate.id,
        newDashboard: withoutNulls(newDashboard),
        accountRef,
      }).then(() => {
        addOrUpdateDashboard(newDashboard);
      });
    },
    [dashboardTemplate, currentUser.id, accountRef, addOrUpdateDashboard],
  );

  return dashboardTemplate ? updateVariableFilters : undefined;
};

export default useUpdateVariableFilters;
