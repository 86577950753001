import React, { useCallback, useEffect, useState } from 'react';

import Colors2 from 'theme/Colors2';
import Row from 'components/Common/Row';
import Button from 'kingpin/atoms/Button';
import Inputs from 'components/Inputs';
import CloseButton from 'components/CloseButton';
import Typography from 'kingpin/atoms/Typography';
import DragHandle from 'components/DragAndDropList/DragHandle';
import DragAndDropList from 'components/DragAndDropList';
import { useGetMetric } from 'screens/GoalShow/useGoalData';
import Column from 'screens/DataManager/DatasetDefinitions/Column';

const MetricListItem = ({
  metricId,
  metricIds,
  setMetricIds,
}: {
  metricId: string;
  metricIds: string[];
  setMetricIds: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const getMetric = useGetMetric();

  const onRemoveClicked = useCallback(() => {
    setMetricIds(metricIds.filter((id) => id !== metricId));
  }, [metricId, metricIds, setMetricIds]);

  const metric = getMetric(metricId);

  return (
    <Row
      spaceBetween
      key={metricId}
      style={{
        border: `1px solid ${Colors2.Grey['8']}`,
        marginTop: '-1px',
      }}
    >
      <Column style={{ minHeight: 42 }}>
        <DragHandle testId="test" />
        <Typography.Body type="Body 12">
          {metric && metric.name}
        </Typography.Body>
      </Column>
      <CloseButton isDark close={onRemoveClicked} />
    </Row>
  );
};

const MetricListInput = ({
  metricIds,
  setMetricIds,
}: {
  metricIds: string[];
  setMetricIds: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const [metricIdRedList, setMetricIdRedList] = useState<string[]>([]);

  useEffect(() => {
    setMetricIdRedList(metricIds);
  }, [metricIds]);

  const onMetricSelected = useCallback(
    (newMetricId: string) => {
      setMetricIds((ids) => [...ids, newMetricId]);
    },
    [setMetricIds],
  );

  return (
    <div>
      <Row spaceBetween centerAlign style={{ marginBottom: 16 }}>
        {metricIds.length > 0 && (
          <Typography.Body type="Label">
            Added Fields{' '}
            <span style={{ color: Colors2.Grey['5'] }}>
              ({metricIds.length})
            </span>
          </Typography.Body>
        )}
        <Inputs.MetricPicker
          metricIdRedList={metricIdRedList}
          isShowAllDatasetsButtonDisabled
          setMetricId={onMetricSelected}
          Button={(props) => (
            <Button
              type="Tertiary"
              size="Small"
              onClick={props.onClick}
              label={'Add Metric'}
            />
          )}
        />
      </Row>

      <DragAndDropList
        items={metricIds}
        onOrderChanged={setMetricIds}
        droppableId="metricId"
        renderItem={(metricId) => (
          <MetricListItem
            metricId={metricId}
            metricIds={metricIds}
            setMetricIds={setMetricIds}
          />
        )}
      />
    </div>
  );
};

export default MetricListInput;
