import React from 'react';

interface DashboardsContextType {
  allDashboards: PersistedDashboardType[];
  isLoading: boolean;
  addOrUpdateDashboard: (newDashboard: PersistedDashboardType) => void;
}
const DashboardsContext = React.createContext<DashboardsContextType>({
  allDashboards: [],
  isLoading: true,
  addOrUpdateDashboard: () => {},
});

export default DashboardsContext;
