import React, { useContext } from 'react';
import useMetric from '../../../hooks/useMetric';
import GeneralGoalContext from '../../../contexts/GeneralGoalContext';
import GoalTrendChart from './GoalTrendChart';
import visTypeCheckers from '../../../types/visTypeCheckers';

const GeneralGoalTrendChart = ({ isCompact }: { isCompact?: boolean }) => {
  const { goal, primaryKpiDataCumulated, primaryKpiData, isPrimaryCumulative } =
    useContext(GeneralGoalContext);
  const metric = useMetric(
    visTypeCheckers.isGeneralGoal(goal) ? goal.metricId : undefined,
  );

  return (
    <GoalTrendChart
      isCompact={isCompact}
      metric={metric}
      trend={
        primaryKpiDataCumulated
          ? primaryKpiDataCumulated.trend
          : primaryKpiData.trend
      }
      goal={goal}
      cadence={goal.cadence}
      target={goal.target || 0}
      advancedTarget={goal.advancedTarget}
      targetMode={goal.targetMode}
      isCumulative={isPrimaryCumulative}
    />
  );
};

export default GeneralGoalTrendChart;
