import styled from 'styled-components';
import { HideUnlessMouseOverCSS } from '../../../../../components/HideUnlessMouseOver';

const ListItem = styled.div<{ isHeader?: boolean }>`
  ${HideUnlessMouseOverCSS};
  height: 48px;
  min-height: 48px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding 0px 10px;
  
  box-shadow: 0px 1px 1px rgba(63, 63, 68, 0.12);
  
  ${(props) => props.isHeader && 'background-color: #F7F7FB;'}
  
  &:hover {
    background-color: #F7F7FB;
  }
`;

export default ListItem;
