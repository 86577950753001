const getQueryAlias = ({
  groupBy,
  trendByCalendarInterval,
  trendByFixedIntervalDays,
  groupByDayOfWeek,
  groupByRange,
  groupByInterval,
}: {
  groupBy?: V5GroupBy;
  trendByCalendarInterval?: FleetOps.Interval;
  trendByFixedIntervalDays?: number;
  groupByDayOfWeek?: boolean;
  groupByRange?: V5RangeAggregation;
  groupByInterval?: V5Histogram;
}) => {
  const base = 'aggregateCompoundMetric';
  const tags = [];
  if (groupBy) {
    tags.push(`ByTerm`);
  }
  if (groupByRange) {
    tags.push('ByRange');
  }
  if (groupByInterval) {
    tags.push('ByInterval');
  }

  if (trendByCalendarInterval) {
    tags.push('Trended');
  }
  if (trendByFixedIntervalDays) {
    tags.push('Trended');
  }
  if (groupByDayOfWeek) {
    tags.push('Trended');
  }

  if (tags.length === 0) {
    return base;
  }

  return `${base}${tags.join('')}`;
};

export default getQueryAlias;
