import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import Modal from '@atlaskit/modal-dialog';
import { ModalTransition } from '@atlaskit/modal-dialog';

import Colors2 from 'theme/Colors2';
import PerformanceBoardSettingsContext from '../contexts/PerformanceBoardSettingsContext';
import Typography from 'kingpin/atoms/Typography';
import useWarningConfirmation from 'components/ConfirmationModals/hooks/useWarningConfirmation';
import BoardGeneralSettings from './BoardGeneralSettings';
import BoardFieldsSettings from './BoardFieldsSettings/BoardFieldsSettings';
import BoardReasonCodeSettings from './BoardReasonCodeSettings/BoardReasonCodeSettings';
import BoardFiltersSettings from './BoardFiltersSettings/BoardFiltersSettings';
import QuickFiltersConfig from './BoardFiltersSettings/QuickFilters/QuickFiltersConfig';
import SettingsNavItem from './SettingsNavItem';
import Button from '../../../../kingpin/atoms/Button';

const ModalContent = styled.div`
  display: flex;
  height: 100%;
`;

const SettingsNav = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  width: 200px;
  background-color: ${Colors2.Grey['9']};
  border-right: 1px solid ${Colors2.Grey['8']};
  border-radius: 3px 0 0 3px;
`;

const SettingsPreview = styled.div<{ noPadding?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${(props) =>
    props.noPadding ? '24px 0px 32px 48px' : '24px 48px 32px 48px'};
  width: 600px;
  position: relative;
`;

const SettingsWizard = ({ close }: { close: () => void }) => {
  const { navState, hasUnsavedChanges } = useContext(
    PerformanceBoardSettingsContext,
  );
  const { getConfirmationThat, DangerConfirmation } = useWarningConfirmation({
    title: 'Unsaved Changes',
    continueText: 'Close without saving',
  });

  const onCloseClicked = useCallback(() => {
    if (!hasUnsavedChanges) {
      close();
      return;
    }

    getConfirmationThat(
      'If you navigate away, all changes made will be lost.',
    ).then((isConfirmed) => {
      if (isConfirmed) {
        close();
      }
    });
  }, [close, getConfirmationThat, hasUnsavedChanges]);

  return (
    <>
      <Modal
        width={'800px'}
        height={'70vh'}
        onClose={onCloseClicked}
        shouldScrollInViewport={false}
        autoFocus={false}
      >
        <ModalContent>
          <SettingsNav>
            <div style={{ marginLeft: 8 }}>
              <Typography.Header type={'H5'}>Settings</Typography.Header>
            </div>

            <SettingsNavItem type="General" />
            <SettingsNavItem type="Fields" />
            <SettingsNavItem type="Quick Filters" />
            <SettingsNavItem type="Reason Codes" />
          </SettingsNav>
          <SettingsPreview noPadding={navState.mode === 'Fields'}>
            <div
              style={{
                paddingTop: '16px',
                width: '100%',
                height: '100%',
                overflow: 'auto',
              }}
            >
              {navState.mode === 'General' && <BoardGeneralSettings />}
              {navState.mode === 'Fields' && <BoardFieldsSettings />}
              {navState.mode === 'Reason Codes' && <BoardReasonCodeSettings />}
              {navState.mode === 'Quick Filters' && <BoardFiltersSettings />}
              {navState.mode === 'Quick Filters Config' && (
                <QuickFiltersConfig quickFilter={navState.editQuickFilter} />
              )}
            </div>
            <div style={{ position: 'absolute', top: '20px', right: '16px' }}>
              <Button
                size="Small"
                type="Secondary"
                onClick={close}
                icon="cross"
              />
            </div>
          </SettingsPreview>
        </ModalContent>
      </Modal>
      <ModalTransition>{DangerConfirmation}</ModalTransition>
    </>
  );
};

export default SettingsWizard;
