const getResponseTerms = (
  response: MetricsResponse,
  groupByField: string,
  dimension?: Dimension,
): string[] | undefined => {
  if (groupByField === 'date' || groupByField === 'dayOfWeek' || !dimension) {
    return undefined;
  }

  if (dimension.fieldType !== 'text') {
    return undefined;
  }

  const terms = (response.map((h) => h[groupByField]) as string[]).filter(
    (t) => t !== '(Blank)',
  );

  return terms;
};

export default getResponseTerms;
