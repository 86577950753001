import { useCallback, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { useDataTypesFromMetricIds } from '../../hooks/useDataTypesFromSeriesAndMetricListItems';
import DatasetDefinitionsContext from '../../contexts/DatasetDefinitionsContext';
import useGetFieldLabel from '../../hooks/useGetFieldLabel';

const useRankingMatrixGroupByField = (
  metrics: VisualisationDefinitions.RankingMatrixMetric[],
  initialField?: string,
) => {
  const { datasets } = useContext(DatasetDefinitionsContext);
  const [selectedMetricIds, setSelectedMetricIds] = useState<string[]>([]);
  const dataTypes = useDataTypesFromMetricIds(selectedMetricIds);
  const { getFieldLabel } = useGetFieldLabel();

  const [groupByField, setGroupByField] = useState<string | undefined>(
    initialField,
  );
  const [groupingOptions, setGroupingOptions] = useState<DropdownOption[]>([]);

  useEffect(() => {
    setSelectedMetricIds(metrics.map((m) => m.metricId));
  }, [metrics]);

  useEffect(() => {
    const options = [] as DropdownOption[];
    dataTypes.forEach((dt) => {
      const set = datasets.find((s) => s.type === dt);
      if (set) {
        set.fields.forEach((f) => {
          if (f.type === 'text') {
            options.push({
              label: getFieldLabel({ field: f.field, dataType: f.type }),
              onSelected: () => {
                setGroupByField(f.field);
              },
            });
          }
        });
      }
    });

    setGroupingOptions(_.sortBy(options, 'label'));
  }, [dataTypes, datasets, getFieldLabel]);

  const clearGrouping = useCallback(() => {
    setGroupByField(undefined);
  }, []);

  return {
    groupByField,
    groupingOptions,
    clearGrouping,
  };
};

export default useRankingMatrixGroupByField;
