import React from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';

import Typography from 'kingpin/atoms/Typography';
import MonthlyTargetBuilder from './MonthlyTargetBuilder';
import Inputs from '../../../../Inputs';

const AdvancedTargetInput = ({
  startDate,
  endDate,
  interval,
  isKpi,
  target,
  setTarget,
  targetMode,
  onAdvancedClicked,
  onBasicClicked,

  cadence,
  isOpen,
  onSave,
  onClose,
  advancedTarget,
  setAdvancedTarget,
  sumOfAdvancedTargets,
  testIdPrefix,
}: {
  startDate: string;
  endDate: string;
  interval: FleetOps.Interval;
  isKpi?: boolean;
  target?: number;
  setTarget: React.Dispatch<React.SetStateAction<number | undefined>>;
  targetMode?: 'basic' | 'advanced';
  onAdvancedClicked: () => void;
  onBasicClicked: () => void;

  cadence: FleetOps.Interval;
  isOpen: boolean;
  onSave: () => void;
  onClose: () => void;
  advancedTarget: GoalAdvancedTarget;
  setAdvancedTarget: React.Dispatch<React.SetStateAction<GoalAdvancedTarget>>;
  sumOfAdvancedTargets: number;
  testIdPrefix: string;
}) => (
  <div>
    {targetMode === 'basic' && (
      <div>
        <Inputs.Number
          value={target}
          setValue={setTarget}
          placeholder="Enter value"
          testId={`${testIdPrefix}-basic-target`}
        />
        <Typography.Body type="Body 12">
          {`By default the ${
            isKpi ? 'kpi' : 'goal'
          } will be distributed equally for the selected
          interval. To set the ${
            isKpi ? 'kpi' : 'goal'
          } manually for each interval `}
          <Typography.Body
            type="Body 12"
            isLink
            onClick={onAdvancedClicked}
            data-testid={`${testIdPrefix}-start-advanced`}
          >
            click here
          </Typography.Body>
        </Typography.Body>
      </div>
    )}
    {targetMode === 'advanced' && (
      <div>
        <Typography.Body type="Body 12" isLink onClick={onAdvancedClicked}>
          {`Edit ${isKpi ? 'targets' : 'goals'}`}
        </Typography.Body>
        <Inputs.Number
          readOnly
          value={sumOfAdvancedTargets}
          setValue={setTarget}
          placeholder="Enter value"
        />
        <Typography.Body type="Body 12">
          {`The above ${isKpi ? 'kpi' : 'goal'} value is the sum of ${
            isKpi ? 'targets' : 'goals'
          } set on each interval for the chosen period. To set a single ${
            isKpi ? 'kpi' : 'goal'
          } value, `}
          <Typography.Body
            type="Body 12"
            isLink
            onClick={onBasicClicked}
            data-testid={`${testIdPrefix}-start-basic`}
          >
            click here
          </Typography.Body>
        </Typography.Body>
      </div>
    )}
    <ModalTransition>
      {isOpen && (cadence === 'month' || cadence === 'week') && (
        <Modal shouldScrollInViewport={false} autoFocus={false}>
          <MonthlyTargetBuilder
            startDate={startDate}
            endDate={endDate}
            interval={interval}
            advancedTarget={advancedTarget}
            setAdvancedTarget={setAdvancedTarget}
            onSave={onSave}
            onClose={onClose}
          />
        </Modal>
      )}
    </ModalTransition>
  </div>
);

export default AdvancedTargetInput;
