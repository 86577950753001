import isoDateToAmerican from './isoDateToAmerican';

const dateRangeInputToAmericanLabel = (dateRange?: DateRangeInput) => {
  if (!dateRange) {
    return '';
  }
  const s = isoDateToAmerican(
    dateRange.startDate ? dateRange.startDate : new Date().toISOString(),
  );
  if (dateRange.endDate) {
    const e = isoDateToAmerican(dateRange.endDate);
    if (s === e) {
      return s;
    } else {
      return `${s} - ${e}`;
    }
  } else {
    return s;
  }
};

export default dateRangeInputToAmericanLabel;
