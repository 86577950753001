import { useContext, useEffect, useState } from 'react';
import ReportDateDrillDownOptionsContext from '../contexts/ReportDateDrillDownOptionsContext';

const useDateFilterValues = (
  field?: string,
  fieldType?: FleetOps.FieldType,
) => {
  const [options, setOptions] = useState<DateOption[]>([]);
  const reportDateOptions = useContext(ReportDateDrillDownOptionsContext);

  useEffect(() => {
    if (fieldType === 'dateText' && reportDateOptions !== undefined) {
      const optionsForField = (() => {
        switch (field) {
          case 'day':
            return reportDateOptions.dayOptions;
          case 'week':
            return reportDateOptions.weekOptions;
          case 'month':
            return reportDateOptions.monthOptions;
          case 'quarter':
            return reportDateOptions.quarterOptions;
          case 'year':
            return reportDateOptions.yearOptions;
          default:
            return [];
        }
      })();
      setOptions(optionsForField);
    }
  }, [field, fieldType, reportDateOptions]);

  return {
    options,
  };
};

export default useDateFilterValues;
