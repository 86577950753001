import React from 'react';

interface ValidationReportContextType {
  metrics: Metrics.NormalMetric[];
  compoundMetrics: Metrics.CompoundMetric[];
}
const ValidationReportContext =
  React.createContext<ValidationReportContextType>(
    {} as ValidationReportContextType,
  );

export default ValidationReportContext;
