import React from 'react';

interface NavSideBarContextType {
  isOpen: boolean;
  open: () => void;
  close: () => void;
}

const NavSideBarContext = React.createContext<NavSideBarContextType>({
  isOpen: true,
  open: () => {},
  close: () => {},
});

export default NavSideBarContext;
