import React, { useContext } from 'react';
import Row from '../../../components/Common/Row';
import Typography from 'kingpin/atoms/Typography';
import colors from '../../../theme/colors';
import { UserIconFromId } from '../../../components/Common/UserIcon';
import Badge from '../../../components/Badge';
import WallboardContext from 'contexts/WallboardContext';

const OnTargetBadge = ({
  testId,
  isOnTarget,
  currentValueFormatted,
  targetValueFormatted,
  isCompact,
  accountableId,
  hideProgress,
}: {
  testId: string;
  isOnTarget: boolean;
  currentValueFormatted: string;
  targetValueFormatted: string;
  isCompact?: boolean;
  accountableId: string;
  hideProgress?: boolean;
}) => {
  const { isWallboard } = useContext(WallboardContext);

  return (
    <Row
      style={{
        padding: '16px 20px 0px 20px',
        fontSize: isWallboard ? '1em' : undefined,
      }}
      spaceBetween
      data-testid={testId}
    >
      {!hideProgress && (
        <Row style={{ marginRight: 64 }} centerAlign>
          <div style={{ marginRight: 8 }}>
            <Typography.Header
              type="H5"
              color={isOnTarget ? colors.MEDIUM_SEA_GREEN : colors.RADICAL_RED}
            >
              {currentValueFormatted}
            </Typography.Header>
          </div>
          <div style={{ marginRight: 8 }}>
            <Typography.Header type="H5">/</Typography.Header>
          </div>
          <Typography.Header type="H5">
            {targetValueFormatted}
          </Typography.Header>
        </Row>
      )}
      {hideProgress && <div />}
      {isCompact && <UserIconFromId userId={accountableId} />}
      {!isCompact && (
        <>
          {isOnTarget && <Badge text={'On Track'} badgeType={'Success'} />}
          {!isOnTarget && <Badge text={'Off Track'} badgeType={'Danger'} />}
        </>
      )}
    </Row>
  );
};

export default OnTargetBadge;
