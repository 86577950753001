import React from 'react';
import Typography from 'kingpin/atoms/Typography';
import { Col } from './ActivityGrid';
import { COSTS_LIST_ITEM_HEIGHT } from '../consts';
import useHoverRow from './hooks/useHoverRow';
import Colors2 from 'theme/Colors2';

const ColumnHeadingsLeft = () => {
  const { disableHover } = useHoverRow();

  return (
    <Col
      maxWidth
      onMouseEnter={disableHover}
      style={{
        position: 'sticky',
        zIndex: 1,
        backgroundColor: 'white',
        top: 0,
        padding: '8px 0',
        borderBottom: `1px solid ${Colors2.GridBorder}`,
        borderTop: `1px solid ${Colors2.GridBorder}`,
        borderLeft: `1px solid ${Colors2.GridBorder}`,
        height: `${COSTS_LIST_ITEM_HEIGHT * 2}px`,
      }}
    >
      <Col maxWidth>
        <div style={{ visibility: 'hidden' }}>
          <Typography.Body type="Body 12">-</Typography.Body>
        </div>
      </Col>
    </Col>
  );
};

export default ColumnHeadingsLeft;
