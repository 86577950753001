import React from 'react';
import Comments from './Comments';
import CommentsProvider from '../../contextProviders/CommentsProvider';

const CommentsContainer = ({
  commentableType,
  commentableId,
  linkTo,
  inputOnTop,
  disableBottomBorder,
  emailLink,
  watcherEmailBody,
  mentionedEmailBody,
  emailBodyLn1Extra,
  noScroll,
  extraWatcherIds,
  sendButtonText,
}: {
  commentableType: CommentableType;
  commentableId: string;
  linkTo?: string;
  inputOnTop?: boolean;
  disableBottomBorder?: boolean;
  emailLink: string;
  watcherEmailBody: string;
  mentionedEmailBody: string;
  emailBodyLn1Extra?: string;
  noScroll?: boolean;
  extraWatcherIds?: string[];
  sendButtonText?: string;
}) => {
  return (
    <CommentsProvider
      extraWatcherIds={extraWatcherIds}
      commentableType={commentableType}
      commentableId={commentableId}
      linkTo={linkTo}
    >
      <Comments
        inputOnTop={inputOnTop}
        disableBottomBorder={disableBottomBorder}
        emailLink={emailLink}
        watcherEmailBody={watcherEmailBody}
        mentionedEmailBody={mentionedEmailBody}
        emailBodyLn1Extra={emailBodyLn1Extra}
        noScroll={noScroll}
        sendButtonText={sendButtonText}
      />
    </CommentsProvider>
  );
};

export default CommentsContainer;
