import firebase from 'firebase/compat/app';

const STAFF_USERS_COLLECTION = 'fleetOpsStaffUsers';

const converter = {
  toFirestore(
    pendingUser: UserManagement.FleetOpsStaffUser,
  ): firebase.firestore.DocumentData {
    return pendingUser;
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ): UserManagement.FleetOpsStaffUser {
    const data = {
      ...snapshot.data(options),
    };

    return data as UserManagement.FleetOpsStaffUser;
  },
};

const getFleetOpsStaffUsersRef = ({
  db,
}: {
  db?: firebase.firestore.Firestore;
}) => {
  return (db ? db : firebase.firestore())
    .collection(STAFF_USERS_COLLECTION)
    .withConverter(converter);
};

export default getFleetOpsStaffUsersRef;
