const UPDATE_BONUS_PERIOD_STATUS: FleetOps.Permission =
  'fleetops.permissions.driver_bonus_update_bonus_period_status';
const INCLUDE_EXCLUDE_RECORDS: FleetOps.Permission =
  'fleetops.permissions.driver_bonus_include_exclude_records';
const OVERRIDE_CATEGORIES: FleetOps.Permission =
  'fleetops.permissions.driver_bonus_override_categories';
const SIGN_OFF: FleetOps.Permission =
  'fleetops.permissions.driver_bonus_sign_off';

const DRIVER_BONUS_PERMISSIONS = {
  UPDATE_BONUS_PERIOD_STATUS,
  INCLUDE_EXCLUDE_RECORDS,
  OVERRIDE_CATEGORIES,
  SIGN_OFF,
};
Object.freeze(DRIVER_BONUS_PERMISSIONS);

export default DRIVER_BONUS_PERMISSIONS;
