import { useCallback, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import getIdentifier from 'getIdentifier';
import isDefined from 'isDefined';
import SettingsContext from '../../contexts/PerformanceBoardSettingsContext';
import useGetFieldsWhichHaveTargets from 'components/PerformanceBoards/hooks/useGetFieldsWhichHaveTargets';
import useGetDatasetDefinition from 'hooks/useGetDatasetDefinition';
import useUpdateColumnsOrder from './useUpdateColumnsOrder';

const useColumnOrder = () => {
  const { board } = useContext(SettingsContext);
  const { fields: targetFields, isLoading } = useGetFieldsWhichHaveTargets({
    dataType: board.dataType,
  });
  const updateRemoteColumnsOrder = useUpdateColumnsOrder();
  const getDatasetDefinition = useGetDatasetDefinition();
  const [columnOrder, setColumnOrder] = useState<
    PerformanceBoardTypes.Column[]
  >(() => board.columnOrder);

  const getFields = useCallback(() => {
    // get all fields
    const dataset = getDatasetDefinition(board.dataType);
    const datasetFields = dataset
      ? dataset.fields.map((f) => f.field)
      : undefined;

    // get prev col order
    const prevFields = columnOrder.map((co) => co.field);

    // get newFields
    const newFields = datasetFields
      ? datasetFields.filter((dsField) => !prevFields.includes(dsField))
      : undefined;

    return { datasetFields, newFields };
  }, [board.dataType, columnOrder, getDatasetDefinition]);

  const updateColumnOrder = useCallback(
    ({ newOrder }: { newOrder: PerformanceBoardTypes.Column[] }) => {
      updateRemoteColumnsOrder({ newOrder });
      setColumnOrder(newOrder);
    },
    [updateRemoteColumnsOrder],
  );

  const getOrder = useCallback(
    ({
      datasetFields,
      newFields,
    }: {
      datasetFields: string[];
      newFields: string[];
    }) => {
      return [
        // Remove fields from column order that we no longer have in the dataset
        ...columnOrder.filter(
          (prevColumn) =>
            datasetFields.some(
              (dsField) =>
                dsField === prevColumn.field && prevColumn.type !== 'progress',
            ) ||
            (targetFields.includes(prevColumn.field) &&
              prevColumn.type === 'progress'),
        ),
        // Add new fields that exists on dataset but absent on column order
        ...newFields.map((field) => ({
          key: getIdentifier(`${field} - field`, true),
          field,
          visible: false,
          type: 'field' as 'field',
        })),
        // Add progress fields
        ...targetFields
          .map((progressField) => {
            if (
              columnOrder.some(
                (prevColumn) =>
                  prevColumn.field === progressField &&
                  prevColumn.type === 'progress',
              )
            ) {
              return undefined;
            } else {
              return {
                key: getIdentifier(`${progressField} - progress`, true),
                field: progressField,
                visible: false,
                type: 'progress' as 'progress',
              };
            }
          })
          .filter(isDefined),
        // Add status fields
        ...targetFields
          .map((progressField) => {
            if (
              columnOrder.some(
                (prevColumn) =>
                  prevColumn.field === progressField &&
                  prevColumn.type === 'status',
              )
            ) {
              return undefined;
            } else {
              return {
                key: getIdentifier(`${progressField} - status`, true),
                field: progressField,
                visible: false,
                type: 'status' as 'status',
              };
            }
          })
          .filter(isDefined),
      ];
    },
    [columnOrder, targetFields],
  );

  useEffect(() => {
    const { datasetFields, newFields } = getFields();
    if (!datasetFields || !newFields || isLoading) {
      return;
    }

    const newOrder = getOrder({ datasetFields, newFields });

    setColumnOrder((currentOrder) => {
      if (!_.isEqual(currentOrder, newOrder)) {
        return newOrder;
      }

      return currentOrder;
    });
  }, [getFields, getOrder, isLoading]);
  return {
    columnOrder,
    updateColumnOrder,
    isLoading,
  };
};

export default useColumnOrder;
