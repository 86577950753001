import React from 'react';
import {
  DraggableProvidedDraggableProps,
  DraggableProvidedDragHandleProps,
} from 'react-beautiful-dnd';

interface DragHandleContextType {
  dragHandleProps?: DraggableProvidedDragHandleProps;
  draggableProps?: DraggableProvidedDraggableProps; // Required for tests
}

const DragHandleContext = React.createContext<DragHandleContextType>({});

export default DragHandleContext;
