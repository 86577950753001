import React from 'react';
import Typography from 'kingpin/atoms/Typography';
import { Cell } from '../OrderDetailsTabs/Summary/SummaryTable/SummaryTable';

const DetailsSlideOutSummary = ({
  orderSummary,
}: {
  orderSummary: OrderSummary;
}) => (
  <div style={{ marginBottom: 16 }}>
    <div style={{ marginBottom: 24 }}>
      <Typography.Header type={'H5'}>Summary</Typography.Header>
    </div>
    <Cell label="Customer" value={orderSummary.customer} />
    <Cell label="Shipper" value={orderSummary.shipper} />
    <Cell label="Consignee" value={orderSummary.consignee} />
    <Cell label="Truck" value={orderSummary.trucks.join(', ')} />
  </div>
);

export default DetailsSlideOutSummary;
