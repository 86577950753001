import React, { useCallback, useContext } from 'react';

import TargetFormContext from '../../contexts/TargetFormContext';
import getIdentifier from '../../../../getIdentifier';
import Tooltip from '../../../Tooltip';
import AnalyticsContext from '../../../../contexts/AnalyticsContext';
import Button from 'kingpin/atoms/Button';
import useGetInitialCategories from '../../hooks/useGetInitialCategories';

const AddTargetButton = ({
  group,
  onTargetAdded,
}: {
  group: Targets.Wizard.TargetGroup;
  onTargetAdded?: () => void;
}) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const { setGroups } = useContext(TargetFormContext);
  const getInitialCategories = useGetInitialCategories();

  const onClick = useCallback(() => {
    trackEvent('Targets - Add Condition Clicked');
    setGroups((currentGroups) => {
      return currentGroups.map((g) => {
        if (g.key === group.key) {
          const newTarget = {
            key: getIdentifier(undefined, true),
            rules: [],
            isFallback: false,
            outputs: [],
            categories: getInitialCategories(),
          } as Targets.Wizard.Target;

          return {
            ...g,
            targets: [newTarget, ...g.targets.map((t) => ({ ...t }))],
          };
        }

        return g;
      });
    });
    if (onTargetAdded) {
      onTargetAdded();
    }
  }, [getInitialCategories, group.key, onTargetAdded, setGroups, trackEvent]);

  return (
    <div style={{ marginRight: 8 }} className="showOnMouseOver">
      <Tooltip content={'Add Condition'}>
        <Button
          type="Ghost"
          size="Small"
          label={'Add Condition'}
          onClick={onClick}
          icon="add"
          testId={'add-target-rule'}
        />
      </Tooltip>
    </div>
  );
};

export default AddTargetButton;
