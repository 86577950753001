import React from 'react';
import Typography from 'kingpin/atoms/Typography';

const BoardSettingsHeader = ({
  title,
  description,
}: {
  title: string;
  description?: string;
}) => {
  return (
    <>
      <Typography.Header type="H4">{title}</Typography.Header>
      {description && (
        <Typography.Body type="Label">{description}</Typography.Body>
      )}
      <div
        style={{
          boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.08)',
          width: '100%',
          height: '1px',
          marginBottom: '16px',
        }}
      ></div>
    </>
  );
};

export default BoardSettingsHeader;
