import React from 'react';

interface ScorecardsContextType {
  scorecards: Scorecards.Scorecard[];
  scorecardsLookup: { [id: string]: Scorecards.Scorecard | undefined };
  isLoading: boolean;
}

const ScorecardsContext = React.createContext<ScorecardsContextType>({
  scorecards: [],
  scorecardsLookup: {},
  isLoading: false,
});

export default ScorecardsContext;
