import React, { useCallback, useContext, useEffect, useState } from 'react';
import TargetBandsForm from './TargetBandsForm';
import ScorecardContext from '../../../../../contexts/ScorecardContext';
import useStartDateOptions from '../../../../../screens/ScorecardsIndex/ScorecardForm/useStartDateOptions';
import { ScoringBandColors } from '../../../../../screens/ScorecardsIndex/constants';
import AnalyticsContext from '../../../../../contexts/AnalyticsContext';
import isIsoTargets from '../../../../../types/scorecardDates/isIsoTargets';
import isIsoDate from '../../../../../types/scorecardDates/isIsoDate';
import targetDateToString from '../../../../../types/scorecardDates/targetDateToString';
import buildIsoWeekCompatabilityError from '../../../../../types/scorecardDates/buildIsoWeekCompatabilityError';

const isStringArray = (arr: (string | undefined)[]): arr is string[] => {
  return !arr.some((x) => x === undefined);
};

const TargetBandsFormContainer = ({
  targetBands,
  initialStartDate,
  isStartDateEditable,
  isColorOrderReversed,
  targets,
  onCancelled,
  onSaved,
  setCanSaveKpi,
  markHasUnsavedChanges,
}: {
  targetBands?: string[];
  initialStartDate?: Scorecards.WeekDate | Scorecards.IsoDate;
  isStartDateEditable: boolean;
  isColorOrderReversed: boolean;
  targets: Scorecards.WeekTargets | Scorecards.IsoTargets;
  onCancelled: () => void;
  onSaved: (
    startDate: Scorecards.WeekDate | Scorecards.IsoDate,
    newValidTarget: string[],
  ) => void;
  setCanSaveKpi: React.Dispatch<React.SetStateAction<boolean>>;
  markHasUnsavedChanges: () => void;
}) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const { scorecard } = useContext(ScorecardContext);
  const [currentTargetBandInputs, setCurrentTargetBandInputs] = useState<
    (string | undefined)[]
  >(() => {
    if (targetBands) {
      return targetBands.map((t) => t.toString());
    }
    const { scoringBandId } = scorecard;
    const colorsForBand = ScoringBandColors[scoringBandId];
    const newBands = [] as undefined[];
    for (let i = 0; i < colorsForBand.length - 1; i++) {
      newBands.push(undefined);
    }
    return newBands;
  });
  const [errors, setErrors] = useState<{
    isVisible: boolean;
    issues: {
      targets: boolean;
      startDate: boolean;
    };
  }>({
    isVisible: false,
    issues: {
      targets: false,
      startDate: false,
    },
  });
  const [startDate, setStartDate] = useState<
    Scorecards.WeekDate | Scorecards.IsoDate | undefined
  >(initialStartDate);
  const { startDateOptions, startDateLabel, todayOption } = useStartDateOptions(
    {
      cadence: scorecard.cadence,
      startDate,
      setStartDate,
      weekStartsOnOverride: scorecard.weekStartsOnOverride,
      minDate: scorecard.startDate,
      isFutureAllowed: true,
    },
  );

  const [isEditing, setIsEditing] = useState<boolean>(false);

  useEffect(() => {
    setCanSaveKpi(false);
    return () => {
      setCanSaveKpi(true);
    };
  }, [setCanSaveKpi]);

  useEffect(() => {
    if (startDate === undefined) {
      setIsEditing(!!targetBands);
      return;
    }
    if (isIsoTargets(targets) && isIsoDate(startDate)) {
      setIsEditing(targets.targets[startDate] !== undefined);
    } else if (!isIsoTargets(targets) && !isIsoDate(startDate)) {
      const targetYear = targets.targets[startDate.year];
      const isExistingTarget = targetYear
        ? targetYear[startDate.week] !== undefined
        : false;
      setIsEditing(isExistingTarget);
    } else {
      throw buildIsoWeekCompatabilityError();
    }
  }, [startDate, targetBands, targets]);

  const buildNewTargetBand = useCallback(() => {
    if (!isStringArray(currentTargetBandInputs)) {
      return undefined;
    }
    const targetAsNumbers = currentTargetBandInputs.map((x) =>
      Number.parseFloat(x),
    );
    if (targetAsNumbers.some((x) => isNaN(x))) {
      return undefined;
    }

    return currentTargetBandInputs;
  }, [currentTargetBandInputs]);

  useEffect(() => {
    setErrors((e) => ({
      ...e,
      issues: {
        startDate: startDate === undefined,
        targets: buildNewTargetBand() === undefined,
      },
    }));
  }, [buildNewTargetBand, startDate]);

  const onSaveClicked = useCallback(() => {
    const newTargetBand = buildNewTargetBand();
    if (!newTargetBand || !startDate) {
      setErrors((e) => ({ ...e, isVisible: true }));
      return;
    }

    const eventType = isEditing
      ? 'KPI List - KPI - Target Updated'
      : 'KPI List - KPI - Target Added';
    const startFrom = targetDateToString(startDate);
    trackEvent(eventType, {
      startFrom,
      scorecardName: scorecard.title,
      scorecardId: scorecard.id,
    });
    onSaved(startDate, newTargetBand);
  }, [
    buildNewTargetBand,
    isEditing,
    onSaved,
    scorecard.id,
    scorecard.title,
    startDate,
    trackEvent,
  ]);

  return (
    <TargetBandsForm
      isEditing={isEditing}
      currentTargetBandInputs={currentTargetBandInputs}
      setCurrentTargetBandInputs={setCurrentTargetBandInputs}
      isColorOrderReversed={isColorOrderReversed}
      scoringBandId={scorecard.scoringBandId}
      startDate={startDate}
      startDateOptions={startDateOptions}
      startDateLabel={startDateLabel}
      isStartDateEditable={isStartDateEditable}
      onSaveClicked={onSaveClicked}
      onCancelClicked={onCancelled}
      markHasUnsavedChanges={markHasUnsavedChanges}
      errors={errors}
      todayOption={todayOption}
    />
  );
};

export default TargetBandsFormContainer;
