import ListElements from '../../../ListElements';
import Row from '../../../../../../components/Common/Row';
import Button from 'kingpin/atoms/Button';
import Typography from 'kingpin/atoms/Typography';
import CheckMarkSrc from '../../images/Checkmark.svg';
import React from 'react';

const Menu = ({
  label,
  options,
  close,
  onBackClicked,
}: {
  label: string;
  options: DropdownOption[];
  close: () => void;
  onBackClicked: () => void;
}) => {
  return (
    <div>
      <ListElements.ListDiv>
        <Row spaceBetween centerAlign>
          <Row centerAlign>
            <div style={{ marginRight: 4 }}>
              <Button
                onClick={onBackClicked}
                icon="chevron-left"
                type="Ghost"
                size="Small"
              />
            </div>

            <Typography.Body type="Body 12">{label}</Typography.Body>
          </Row>

          <Button onClick={close} icon="cross" type="Ghost" size="Small" />
        </Row>
        {options.map((o) => (
          <ListElements.ListItem onClick={o.onSelected} key={o.label}>
            <Typography.Body type="Body 12">{o.label}</Typography.Body>
            {o.isSelected && <img src={CheckMarkSrc} alt="selected" />}
          </ListElements.ListItem>
        ))}
      </ListElements.ListDiv>
    </div>
  );
};

export default Menu;
