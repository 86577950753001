import _ from 'lodash';
import withoutNulls from './search/withoutNulls';
import firebase from 'firebase/compat/app';

export const buildBaseViewPath = (dataset: string, version: number) =>
  `${dataset}:${version}`;

// For use by the callback in BaseViewsProvider.
// Do not use this outside of the provided callback in BaseViewsProvider.
const updateBaseViewDoc = _.debounce(
  async (
    newBaseView: FleetOps.BaseView,
    accountRef: firebase.firestore.DocumentReference,
  ) => {
    await accountRef
      .collection('baseViews')
      .doc(buildBaseViewPath(newBaseView.type, newBaseView.version))
      .set(withoutNulls(newBaseView));
  },
  1000,
);

export default updateBaseViewDoc;
