import { ApisauceInstance } from 'apisauce';

const patchConfiguration = async (
  api: ApisauceInstance,
  permittedGroupIds: string[],
): Promise<boolean> => {
  const response = await api.patch('microsoftSso/', {
    permittedGroupIds,
  });
  return response.ok;
};

export default patchConfiguration;
