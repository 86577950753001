import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/auth';
import { useNavigate } from 'react-router-dom';

import AcceptInvite from './AcceptInvite';
import appRoutes from '../../navigation/appRoutes';
import AnalyticsContext from '../../contexts/AnalyticsContext';
import Loading from '../../components/Loading';
import CloudFunctionClientContext from '../../contexts/CloudFunctionClientContext';
import useOnPasswordChanged from './hooks/useOnPasswordChanged';
import useParseQueryParams from './hooks/useParseQueryParams';
import acceptInvitation from './api/acceptInvitation';
import getIsInvitationValid from './api/getIsInvitationValid';

export const VALID_PASSWORD_PATTERN =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9])[a-zA-Z\d\w\W]{10,}$/;

const AcceptInviteContainer = () => {
  const { api } = useContext(CloudFunctionClientContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const parseQueryParams = useParseQueryParams();
  const { token, email } = parseQueryParams();
  const navigate = useNavigate();
  const [displayName, setDisplayName] = useState<string>('');
  const [passwordErrors, setPasswordErrors] = useState<string[] | undefined>();
  const [isHidingPassword, setIsHidingPassword] = useState<boolean>(true);
  const onDisplayNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setDisplayName(event.target.value);
  };

  const [password, setPassword] = useState<string>('');

  const onPasswordChanged = useOnPasswordChanged({
    setPasswordErrors,
    setPassword,
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  useEffect(() => {
    trackEvent('Sign Ups - Invitation Screen Opened', {});
  }, [trackEvent]);

  const isValid =
    displayName !== '' &&
    password !== '' &&
    VALID_PASSWORD_PATTERN.test(password);

  const onSubmit = () => {
    if (!isValid) {
      return;
    }

    setPasswordErrors(undefined);
    setIsLoading(true);

    acceptInvitation({ token, displayName, email, password, api }).then(
      (response) => {
        if (response.ok) {
          trackEvent('Sign Ups - Invitation Accepted');
          firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(() => {
              setIsLoading(false);
              navigate(appRoutes.home);
            });
        } else {
          setErrorMessage(
            response.data ? response.data.message : 'Something went wrong',
          );
          setIsLoading(false);
        }
      },
    );
  };

  return (
    <AcceptInvite
      email={email}
      displayName={displayName}
      onDisplayNameChanged={onDisplayNameChanged}
      password={password}
      onPasswordChanged={onPasswordChanged}
      onSubmit={onSubmit}
      isLoading={isLoading}
      isValid={isValid}
      errorMessage={errorMessage}
      passwordErrors={passwordErrors}
      isHidingPassword={isHidingPassword}
      setIsHidingPassword={setIsHidingPassword}
    />
  );
};

const Gate = () => {
  const { api } = useContext(CloudFunctionClientContext);
  const parseQueryParams = useParseQueryParams();
  const { token } = parseQueryParams();
  const [isValidatingToken, setIsValidatingToken] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!api) {
      return;
    }

    getIsInvitationValid({ api, token }).then((response) => {
      if (response.ok && response.data) {
        const { isExpired, isUsed } = response.data;
        if (isUsed) {
          navigate(appRoutes.alreadyAcceptedInvitation);
        } else if (isExpired) {
          navigate(appRoutes.expiredInvitation);
        }
      }
      setIsValidatingToken(false);
    });
  }, [api, navigate, token]);

  if (isValidatingToken) {
    return <Loading />;
  }

  return <AcceptInviteContainer />;
};

export default Gate;
