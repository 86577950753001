import usePopup from '../../../hooks/usePopup';
import { useCallback, useState } from 'react';
import firebase from 'firebase/compat/app';

const useLinkWithPassword = () => {
  const { isOpen, open, close } = usePopup();
  const [pendingCredential, setPendingCredential] =
    useState<firebase.auth.AuthCredential>();
  const [email, setEmail] = useState<string>();
  const [isWrongPassword, setIsWrongPassword] = useState<boolean>(false);

  const onClosed = useCallback(() => {
    close();
    setPendingCredential(undefined);
    setEmail(undefined);
    setIsWrongPassword(false);
  }, [close]);

  const startLink = useCallback(
    ({
      pendingCredential,
      email,
    }: {
      pendingCredential: firebase.auth.AuthCredential;
      email: string;
    }) => {
      setPendingCredential(pendingCredential);
      setEmail(email);

      open();
    },
    [open],
  );

  const attemptLink = useCallback(
    async (password: string) => {
      if (!email) {
        const e = new Error();
        e.name = `Password Link Failed. Missing email`;
        throw e;
      }
      if (!pendingCredential) {
        const e = new Error();
        e.name = `Password Link Failed. Missing pendingCredential`;
        throw e;
      }

      const emailPasswordCredential = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .catch((ex: firebase.auth.AuthError) => {
          if (ex.code === 'auth/wrong-password') {
            setIsWrongPassword(true);
          }
        });

      if (emailPasswordCredential && emailPasswordCredential.user) {
        await emailPasswordCredential.user.linkWithCredential(
          pendingCredential,
        );
        onClosed();
      }
    },
    [email, onClosed, pendingCredential],
  );

  return {
    startLink,
    attemptLink,
    onClosed,
    isWrongPassword,
    isOpen,
  };
};

export default useLinkWithPassword;
