const isCompoundMetric = (
  m: Metrics.Metric | ExtendedVisualisationDefinition | undefined,
): m is Metrics.CompoundMetric => {
  if (!m) {
    return false;
  }

  return 'type' in m ? m.type === 'compound' : false;
};

export default isCompoundMetric;
