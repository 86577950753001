import styled from 'styled-components';
import Colors2 from '../../../theme/Colors2';

const ListDiv = styled.div`
  padding: 8px;

  max-height: 300px;
  overflow-y: auto;
`;

const ListItem = styled.div`
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8px;
  cursor: pointer;

  &:hover {
    background-color: ${Colors2.Primary['8']};
  }
`;

const ListElements = {
  ListDiv,
  ListItem,
};

export default ListElements;
