import React, { ChangeEvent, useContext, useState } from 'react';
import aguid from 'aguid';

import ReasonCodeForm from './ReasonCodeForm';
import createReasonCode from '../../../api/reasonCodes/createReasonCode';
import AccountPickerContext from '../../../contexts/AccountPickerContext';

const ReasonCodeFormContainer = ({
  close,
  dataType,
}: {
  close: () => void;
  dataType: string;
}) => {
  const { accountRef } = useContext(AccountPickerContext);

  const [code, setCode] = useState<string>('');
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const onCodeChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);
  };

  const onSave = () => {
    const newReasonCode = {
      id: aguid(),
      version: '2' as '2',
      boardType: dataType,
      code,
    };

    setIsSaving(true);
    createReasonCode(newReasonCode, accountRef).then(() => {
      setIsSaving(false);
      close();
    });
  };

  return (
    <ReasonCodeForm
      close={close}
      code={code}
      onCodeChanged={onCodeChanged}
      onSave={onSave}
      isSaving={isSaving}
    />
  );
};

export default ReasonCodeFormContainer;
