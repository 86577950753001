const buildContentViewId = (contentView: ContentView): string =>
  `${contentView.type}-${contentView.typeId}`;

export const buildContentViewIdAlt = ({
  type,
  typeId,
}: {
  type: string;
  typeId: string;
}): string => `${type}-${typeId}`;

export default buildContentViewId;
