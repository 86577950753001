import { useContext } from 'react';
import ReportDrillDownsContext from '../../../contexts/ReportDrillDownsContext';

const useSetDrillDowns = ({ isScope }: { isScope: boolean }) => {
  const { setDrillDowns: setRunTimeDs, setScope: setSavedDs } = useContext(
    ReportDrillDownsContext,
  );
  const setDrillDowns = isScope ? setSavedDs : setRunTimeDs;

  return setDrillDowns;
};

export default useSetDrillDowns;
