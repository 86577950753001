import React, { useContext, useEffect, useState } from 'react';
import ComparisonContext from '../../../contexts/ComparisonContext';
import SingleMetricDateMatrix from '../../SingleMetricDateMatrix';
import Card from 'components/Common/Card';
import Row from '../../Common/Row';
import ComparisonSelector from '../../Report/ComparisonSelector';
import ErrorBoundary from '../../Common/ErrorBoundary';
import DateInput from '../../DateInput';
import withDateFilter from '../../../hocs/withDateFIlter';
import { useDataTypesFromMetricId } from '../../../hooks/useDataTypesFromSeriesAndMetricListItems';
import DateInputContext from '../../../contexts/DateInputContext';
import VisualisationUsageDetails from '../../VisualisationUsageDetails';
import ReportDrillDownsProvider from '../../../contextProviders/ReportDrillDownsProvider';

const SingleMetricDateMatrixPreview = ({
  gadget,
}: {
  gadget: VisualisationDefinitions.SingleMetricDateMatrix;
}) => {
  const { setDataTypes } = useContext(DateInputContext);
  const dataTypes = useDataTypesFromMetricId(gadget.metricId);
  const [comparison, setComparison] = useState<
    PersistedComparisonType | undefined
  >({
    compareType: 'compareTo',
    relativeDateOption: 'last 7 days',
  });

  useEffect(() => {
    setDataTypes(dataTypes);
  }, [dataTypes, setDataTypes]);

  return (
    <ErrorBoundary>
      <ReportDrillDownsProvider>
        <ComparisonContext.Provider
          value={{
            currentComparison: comparison,
            setCurrentComparison: setComparison,
          }}
        >
          <Row spaceBetween style={{ marginBottom: 24 }}>
            <DateInput />
            <Row>
              <ComparisonSelector alwaysShowCompareTo />
              <VisualisationUsageDetails chatDefinition={gadget} />
            </Row>
          </Row>
          <Card style={{ height: 700 }}>
            <SingleMetricDateMatrix gadget={gadget} />
          </Card>
        </ComparisonContext.Provider>
      </ReportDrillDownsProvider>
    </ErrorBoundary>
  );
};

export default withDateFilter(SingleMetricDateMatrixPreview, {});
