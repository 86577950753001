import { backOff } from 'exponential-backoff';

const reloadWhenSafe = (altReload?: () => void) => {
  const getIsSafeToReload = async (): Promise<boolean> => {
    const response = await fetch(window.location.origin);
    return response.ok;
  };

  const attemptReload = async () => {
    const isSafe = await getIsSafeToReload();
    if (!isSafe) {
      const e = new Error();
      e.name = 'Failed to reload as internet connection unavailable';
      throw e;
    }

    if (altReload) {
      altReload();
    } else {
      window.location.reload();
    }
  };

  // Large values here to support wallboards
  return backOff(attemptReload, {
    startingDelay: 1000,
    numOfAttempts: 1000,
    maxDelay: 1000 * 5,
    delayFirstAttempt: true,
  });
};

export default reloadWhenSafe;
