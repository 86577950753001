import { useContext, useEffect, useState } from 'react';
import AccountPickerContext from '../contexts/AccountPickerContext';
import STORE from '../store';

const useGaugeDefinitions = () => {
  const [gaugeDefs, setGaugeDefs] = useState<VisualisationDefinitions.Gauge[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState(true);
  const { selectedAccountId } = useContext(AccountPickerContext);

  useEffect(() => {
    setIsLoading(true);

    const listener = STORE.visualisations
      .getGaugesRef({ accountId: selectedAccountId })
      .orderBy('name')
      .onSnapshot((snapshot) => {
        const data = [] as VisualisationDefinitions.Gauge[];
        snapshot.docs.forEach((d) => data.push(d.data()));
        setGaugeDefs(data);
        setIsLoading(false);
      });
    return () => {
      listener();
    };
  }, [selectedAccountId]);

  return { gaugeDefs, isLoading };
};

export default useGaugeDefinitions;
