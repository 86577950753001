import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import Button from 'kingpin/atoms/Button';
import EntityDefinitionsContext from 'contexts/EntityDefinitionsContext';
import useEntityLink from 'components/GlobalSearch/hooks/useEntityLink';

import SelectedEntityContext from '../../contexts/SelectedEntityContext';

const EntityAppLink = ({
  entity,
  selectedEntity,
}: {
  entity: EntityDetails.Entity;
  selectedEntity: string;
}) => {
  const link = useEntityLink({ entity, value: selectedEntity });

  if (!link) {
    return null;
  }

  return (
    <div style={{ marginRight: 8 }}>
      <Link to={link} target="_blank">
        <Button size={'Medium'} type={'Secondary'} icon={'open-link'} />
      </Link>
    </div>
  );
};

const Gate = () => {
  const { selectedEntity, entityField } = useContext(SelectedEntityContext);
  const { entityDefinitions } = useContext(EntityDefinitionsContext);

  const getEntityDefinition = useCallback(() => {
    return entityDefinitions.find((d) => d.primaryField === entityField);
  }, [entityDefinitions, entityField]);

  const [entityDefinition, setEntityDefinition] = useState<
    EntityDetails.Entity | undefined
  >(() => getEntityDefinition());

  useEffect(() => {
    setEntityDefinition(getEntityDefinition());
  }, [getEntityDefinition]);

  if (!entityDefinition || !selectedEntity) {
    return null;
  }

  return (
    <EntityAppLink entity={entityDefinition} selectedEntity={selectedEntity} />
  );
};

export default Gate;
