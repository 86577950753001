import isV5ChartDef from './isV5ChartDef';

const isGeneralGoal = (
  def?: ExtendedVisualisationDefinition,
): def is GeneralGoal => {
  if (isV5ChartDef(def)) {
    return false;
  }

  return !!def && def.type === 'General Goal';
};

export default isGeneralGoal;
