import React, { ChangeEvent, useContext, useState } from 'react';
import ReportForm from './ReportForm';
import { buildReportShow } from '../../navigation/appRoutes';
import ReportContext from '../../contexts/ReportContext';
import updateReport from '../../api/updateReport';
import CurrentUserContext from '../../contexts/CurrentUserContext';
import { DateTime } from 'luxon';
import updateRecentName from '../../api/recents/updateRecentName';
import updateFavouriteName from '../../api/favourites/updateFavouriteName';
import AnalyticsContext from '../../contexts/AnalyticsContext';
import AccountPickerContext from '../../contexts/AccountPickerContext';
import useContentSharedEmails from '../../hooks/useContentSharedEmails';
import { useNavigate } from 'react-router-dom';
import { PortalsContext } from '../../contextProviders/PortalsProvider';
import * as aguid from 'aguid';
import STORE from '../../store';
import useAddNewReportToCurrentPortal from '../../hooks/useAddNewReportToCurrentPortal';

const ReportFormContainer = ({
  isOpen,
  close,
  isSaveAs,
  isSavingAs,
  onSaveAs,
  onBackPressed,
}: {
  isOpen: boolean;
  close: () => void;
  isSaveAs?: boolean;
  isSavingAs?: boolean;
  onSaveAs?: ({ name }: { name: string }) => void;
  onBackPressed?: () => void;
}) => {
  const { selectedPortal } = useContext(PortalsContext);
  const { accountRef, selectedAccountId } = useContext(AccountPickerContext);
  const addNewReportToCurrentPortal = useAddNewReportToCurrentPortal();
  const navigate = useNavigate();
  const currentUser = useContext(CurrentUserContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { report } = useContext(ReportContext);
  const { trackEvent } = useContext(AnalyticsContext);

  const [title, setTitle] = useState<string>(report ? report.name : '');
  const [access, setAccess] = useState<ResourceAccess>(
    report && report.access
      ? report.access
      : {
          version: '2',
          type: 'Private',
        },
  );
  const { sendContentSharedEmails } = useContentSharedEmails();

  const onTitleChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const [isMarkedAsPopup, setIsMarkedAsPopup] = useState<boolean>(
    report ? !!report.isMarkedAsPopup : false,
  );
  const [isMarkedAsSEOnly, setIsMarkedAsSEOnly] = useState<boolean>(
    report ? !!report.isMarkedAsSEOnly : false,
  );

  const isValid = title !== '';

  const onSubmit = () => {
    if (!isValid || currentUser === undefined) {
      alert('Input is not valid');
      return;
    }
    if (isSaveAs && onSaveAs) {
      onSaveAs({ name: title });
    } else if (report) {
      setIsLoading(true);
      const newReport = {
        ...report,
        access,
        name: title,
        updatedOn: DateTime.utc().toISO(),
        updatedBy: currentUser.id,
        isMarkedAsPopup,
        isMarkedAsSEOnly,
      };
      updateReport(report.id, newReport, accountRef).then(() => {
        const requests = [
          updateRecentName({
            type: 'report',
            typeId: report.id,
            newName: title,
            userId: currentUser.id,
            accountRef,
          }),
          updateFavouriteName({
            type: 'report',
            typeId: report.id,
            newName: title,
            userId: currentUser.id,
            accountRef,
          }),
          sendContentSharedEmails({
            type: 'Resource Access',
            initialAccess: report.access,
            newAccess: newReport.access,
          }),
        ];
        Promise.all(requests).then(() => {
          setIsLoading(false);
          close();
        });
      });
    } else {
      setIsLoading(true);
      const newReport: PersistedReportType = {
        version: '7',
        id: aguid(),
        name: title,
        visibleSlicerFields: [],
        scope: [],
        drillDowns: [],
        canvas: {
          cards: [],
        },
        relativeDateRange: {
          interval: 'day',
          n: 30,
          type: 'last',
          currentToDate: true,
        },
        access,
        isMarkedAsPopup,
        isMarkedAsSEOnly,
        dateField: 'date',
        createdBy: currentUser.id,
        createdOn: DateTime.utc().toISO(),
        updatedBy: currentUser.id,
        updatedOn: DateTime.utc().toISO(),
      };
      STORE.getReportsRef({ accountId: selectedAccountId })
        .doc(newReport.id)
        .set(newReport)
        .then(async () => {
          await addNewReportToCurrentPortal(newReport.id);
          trackEvent('Report - Index - Create Report - Saved');
          navigate(
            buildReportShow({
              id: newReport.id,
              isEditing: true,
              portal: selectedPortal,
            }),
          );

          await sendContentSharedEmails({
            type: 'Resource Access',
            initialAccess: undefined,
            newAccess: access,
          });
          setIsLoading(false);
          close();
        });
    }
  };

  return (
    <ReportForm
      isEditing={!!report}
      isOpen={isOpen}
      onSubmit={onSubmit}
      close={close}
      isLoading={isLoading || !!isSavingAs}
      isValid={isValid}
      title={title}
      onTitleChanged={onTitleChanged}
      isSaveAs={!!isSaveAs}
      onBackPressed={onBackPressed}
      access={access}
      setAccess={setAccess}
      canSetToPrivate={
        report
          ? report.createdBy === currentUser.id || currentUser.isFleetOpsStaff
          : true
      }
      isMarkedAsSEOnly={isMarkedAsSEOnly}
      setIsMarkedAsSEOnly={setIsMarkedAsSEOnly}
      isMarkedAsPopup={isMarkedAsPopup}
      setIsMarkedAsPopup={setIsMarkedAsPopup}
    />
  );
};

export default ReportFormContainer;
