import React from 'react';

interface PerformanceDatasetMetricsContextType {
  getIsPerformanceDatasetMetric: (metricId?: string) => boolean;
}

const PerformanceDatasetMetricsContext =
  React.createContext<PerformanceDatasetMetricsContextType>({
    getIsPerformanceDatasetMetric: () => {
      const error = new Error();
      error.name = 'getIsPerformanceDatasetMetric is not defined';
      throw error;
    },
  });

export default PerformanceDatasetMetricsContext;
