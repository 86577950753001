const isDriverPerformanceSlideOutDataSection = (
  section:
    | BoardSlideOutGridSection
    | BoardSlideOutDataSection
    | DriverPerformanceSlideOutSummarySection
    | BoardSlideOutPerformanceSection
    | BoardSlideOutMetricsSection
    | BoardCommentsSection,
): section is BoardSlideOutDataSection => {
  return (
    section.sectionType === 'DataSection' || section.sectionType === undefined
  );
};

export const isDriverSummarySlideOutDataSection = (
  section:
    | BoardSlideOutGridSection
    | BoardSlideOutDataSection
    | DriverPerformanceSlideOutSummarySection
    | BoardSlideOutPerformanceSection
    | BoardSlideOutMetricsSection
    | BoardCommentsSection,
): section is DriverPerformanceSlideOutSummarySection => {
  return section.sectionType === 'SummarySection';
};

export const isDriverSummarySlideOutGridSection = (
  section:
    | BoardSlideOutGridSection
    | BoardSlideOutDataSection
    | DriverPerformanceSlideOutSummarySection
    | BoardSlideOutPerformanceSection
    | BoardSlideOutMetricsSection
    | BoardCommentsSection,
): section is BoardSlideOutGridSection => {
  return section.sectionType === 'GridSection';
};

export const isPerformanceSection = (
  section:
    | BoardSlideOutGridSection
    | BoardSlideOutDataSection
    | DriverPerformanceSlideOutSummarySection
    | BoardSlideOutPerformanceSection
    | BoardSlideOutMetricsSection
    | BoardCommentsSection,
): section is BoardSlideOutPerformanceSection => {
  return section.sectionType === 'PerformanceSection';
};

export const isMetricsSection = (
  section:
    | BoardSlideOutGridSection
    | BoardSlideOutDataSection
    | DriverPerformanceSlideOutSummarySection
    | BoardSlideOutPerformanceSection
    | BoardSlideOutMetricsSection
    | BoardCommentsSection,
): section is BoardSlideOutMetricsSection => {
  return section.sectionType === 'MetricsSection';
};

export const isCommentsSection = (
  section:
    | BoardSlideOutGridSection
    | BoardSlideOutDataSection
    | DriverPerformanceSlideOutSummarySection
    | BoardSlideOutPerformanceSection
    | BoardSlideOutMetricsSection
    | BoardCommentsSection,
): section is BoardCommentsSection => {
  return section.sectionType === 'CommentsSection';
};

export default isDriverPerformanceSlideOutDataSection;
