import React from 'react';
import styled from 'styled-components';
import TargetFieldList from './TargetFieldList';
import SelectAFieldMessage from './SelectAFieldMessage';
import TargetList from './TargetList';
import TargetForm from './TargetForm';
import BottomBar from './BottomBar';
import GlowingDiv from './GlowingDiv';

const ManagerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
`;

const Column = styled.div<{ width?: number; borderRight?: boolean }>`
  display: flex;
  flex: unset;
  width: ${(props) => (props.width ? `${props.width}px` : 'unset')};
  flex: ${(props) => (props.width ? `unset` : '1')};
  flex-direction: column;

  border-right: ${(props) =>
    props.borderRight ? '1px solid #EBEBEB' : 'unset'};
  height: 100%;
`;

const TargetManager = ({
  close,
  dataset,
  selectedField,
  onFieldSelected,
  selectedTarget,
  onAddTargetClicked,
  copyingFromTarget,
}: {
  close?: () => void;
  dataset: string;
  selectedField?: string;
  onFieldSelected: (field: string) => void;
  selectedTarget?: Targets.Wizard.DataTypeTarget;
  onAddTargetClicked: () => void;
  copyingFromTarget?: Targets.Wizard.DataTypeTarget;
}) => (
  <Container data-testid="target-manager">
    <ManagerWrapper>
      <Column width={280} borderRight>
        <TargetFieldList
          dataset={dataset}
          selectedField={selectedField}
          onFieldSelected={onFieldSelected}
        />
      </Column>
      {selectedField === undefined && (
        <Column>
          <SelectAFieldMessage />
        </Column>
      )}
      {selectedField !== undefined && (
        <>
          <Column width={300} borderRight>
            <TargetList
              isCreatingNewTarget={selectedTarget === undefined}
              selectedTarget={selectedTarget}
              onAddTargetClicked={onAddTargetClicked}
            />
          </Column>
          <Column
            data-testid="target-manager-form"
            style={{ overflowX: 'hidden' }}
          >
            <GlowingDiv>
              <>
                {selectedTarget && copyingFromTarget === undefined && (
                  <TargetForm
                    key={`${dataset}-${selectedField}-${selectedTarget.id}`}
                    target={selectedTarget}
                    dataType={dataset}
                    targetField={selectedField}
                    isCopyingFromTarget={false}
                  />
                )}
                {selectedTarget === undefined && (
                  <TargetForm
                    key={
                      copyingFromTarget
                        ? `copying-from-${copyingFromTarget.id}-${selectedField}`
                        : `${dataset}-${selectedField}`
                    }
                    dataType={dataset}
                    targetField={selectedField}
                    isCopyingFromTarget={copyingFromTarget !== undefined}
                    target={copyingFromTarget}
                  />
                )}
              </>
            </GlowingDiv>
          </Column>
        </>
      )}
    </ManagerWrapper>
    <BottomBar close={close} />
  </Container>
);

export default TargetManager;
