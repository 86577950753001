import React, { useContext, useEffect, useState } from 'react';
import WidgetGallery from './WidgetGallery';
import WidgetGalleryContext from '../../contexts/WidgetGalleryContext';

const WidgetGalleryContainer = () => {
  const {
    isOpen,
    close,
    isConfiguringDashboardGadget,
    selectedDefinitions,
    definitions,
    isDashboard,
    isCreatingNewChart,
  } = useContext(WidgetGalleryContext);
  const [dashboardGadgetChart, setDashboardGadgetChart] =
    useState<VisualisationDefinition>();

  useEffect(() => {
    if (!isOpen) {
      setDashboardGadgetChart(undefined);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isDashboard) {
      return;
    }

    if (selectedDefinitions && selectedDefinitions.length > 0 && definitions) {
      setDashboardGadgetChart(
        definitions.find((d) => d.id === selectedDefinitions[0]),
      );
    }
  }, [definitions, isDashboard, selectedDefinitions]);

  return (
    <WidgetGallery
      isOpen={isOpen}
      close={close}
      isConfiguringDashboardGadget={isConfiguringDashboardGadget}
      dashboardGadgetChart={dashboardGadgetChart}
      isCreatingNewChart={isCreatingNewChart}
    />
  );
};

export default WidgetGalleryContainer;
