import { useCallback, useContext } from 'react';
import moment from 'moment';
import { IBasePaceCalcArgs } from '../types';
import ExcludedPaceDatesContext from '../../../../contexts/ExcludedPaceDatesContext';
import dateRangeInputToAmericanLabel from '../../../../dateRangeInputToAmericanLabel';

const useTooltipDateRanges = ({
  args,
}: {
  args: IBasePaceCalcArgs | undefined;
}) => {
  const { dateRanges: allExcludedDateRanges } = useContext(
    ExcludedPaceDatesContext,
  );

  const getExcludedDateRangeLabels = useCallback(() => {
    if (!args) {
      return [];
    }

    const excludedDates = args.comparisonPeriod.exclude;
    const excludedRanges = allExcludedDateRanges.filter((range) => {
      const { startDate, endDate } = range;
      if (!startDate || !endDate) {
        return false;
      }

      return excludedDates.some((date) => {
        return date >= startDate && date <= endDate;
      });
    });

    return excludedRanges.map(dateRangeInputToAmericanLabel);
  }, [allExcludedDateRanges, args]);

  const getActualDateRange = useCallback(() => {
    if (!args) {
      return '';
    }

    const dateScope = args.dateScope[0];
    if (!dateScope) {
      return '';
    }

    const { startDate, endDate } = dateScope;
    if (!startDate || !endDate) {
      return '';
    }

    const left = moment.utc(startDate).format("MMM DD'YY");
    const right = moment.utc(endDate).format("MMM DD'YY");
    return `${left} - ${right}`;
  }, [args]);

  const getAverageDateRange = useCallback(() => {
    if (!args) {
      return '';
    }

    const { startDate, endDate } = args.comparisonPeriod;
    const left = moment.utc(startDate).format("MMM DD'YY");
    const right = moment.utc(endDate).format("MMM DD'YY");
    return `${left} - ${right}`;
  }, [args]);

  return {
    getAverageDateRange,
    getActualDateRange,
    getExcludedDateRangeLabels,
  };
};

export default useTooltipDateRanges;
