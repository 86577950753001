import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Typography from 'kingpin/atoms/Typography';

import GeneralGoalTrendChart from '../../../../screens/GoalShow/GoalTrendChart/GeneralGoalTrendChart';
import GoalKpisVis from '../../../../screens/GoalShow/GoalKpiVis/GeneralGoaKpisVis';
import Row from '../../../Common/Row';
import styled from 'styled-components';
import { UserIconFromId } from '../../../Common/UserIcon';
import Menu from '../../GoalListItem/GeneralGoalListItem/Menu';
import Badge from '../../../Badge';
import { buildShowWorkSpace } from '../../../../navigation/appRoutes';
import OpenTasksLink from '../../OpenTasksLink';
import OverdueTasksLink from '../../OverDueTasksLink';
import PermissionGates from '../../../PermissionGates';
import PERMISSIONS from '../../../../permissionDefinitions';
import MetricsInfoButton from '../../../MetricsInfoButton';
import WallboardContext from 'contexts/WallboardContext';

const KpiWrapper = styled.div<{ isWallboard?: boolean }>`
  height: ${(props) => (props.isWallboard ? 'auto' : '210px')};
  overflow-y: auto;
`;

const GeneralGoalCard = ({
  goal,
  link,
  isCompact,
  workspaceName,
  workSpaceId,
}: {
  goal: GeneralGoal;
  link?: string;
  isCompact?: boolean;
  workspaceName?: string;
  workSpaceId?: string;
}) => {
  const { isWallboard } = useContext(WallboardContext);

  return (
    <div>
      {!!link && (
        <Row spaceBetween centerAlign>
          <Link to={link}>
            <Typography.Body type="Link" isEllipsis>
              {goal.title}
            </Typography.Body>
          </Link>
          {!isCompact && (
            <Row centerAlign>
              <PermissionGates.Has
                requiredPermission={PERMISSIONS.GOALS.CRUD_GOALS}
              >
                <Menu goal={goal} />
              </PermissionGates.Has>
              <MetricsInfoButton visualisation={goal} />
            </Row>
          )}
          {isCompact && workspaceName && workSpaceId && (
            <Row centerAlign>
              <Link to={buildShowWorkSpace(workSpaceId)}>
                <Badge text={workspaceName} badgeType="Default" />
              </Link>
            </Row>
          )}
        </Row>
      )}
      {!link && <Typography.Header type={'H5'}>{goal.title}</Typography.Header>}
      {isCompact && (
        <>
          <div style={{ marginLeft: -20, marginRight: -20 }}>
            <GeneralGoalTrendChart isCompact />
          </div>
        </>
      )}
      {!isCompact && (
        <>
          <div
            style={{
              height: isWallboard ? '50%' : '250px',
              marginLeft: -20,
              marginRight: -20,
            }}
          >
            <GeneralGoalTrendChart />
          </div>
          <KpiWrapper className="hiding-scrollbar" isWallboard={isWallboard}>
            <GoalKpisVis isCard />
          </KpiWrapper>
          <Row spaceBetween>
            <Row centerAlign style={{ height: 40 }}>
              <OpenTasksLink link={link} />
              <OverdueTasksLink link={link} />
            </Row>
            <Row centerAlign>
              <div style={{ marginRight: 8 }}>
                <Typography.Body type="Label">Accountable:</Typography.Body>
              </div>
              <div>
                <UserIconFromId userId={goal.accountableId} />
              </div>
            </Row>
          </Row>
        </>
      )}
    </div>
  );
};

export default GeneralGoalCard;
