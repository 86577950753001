import React from 'react';
import Inputs from 'components/Inputs';

const ChartTypePicker = ({ options }: { options: DropdownOption[] }) => (
  <div style={{ marginLeft: 12, marginRight: 12 }}>
    <Inputs.Dropdown options={options} label="Chart Type" />
  </div>
);

export default ChartTypePicker;
