import React from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { Item } from '../QuickFilters/QuickFilters';
import Button from 'kingpin/atoms/Button';

import Row from '../../../Common/Row';
import Label from '../../../V5GadgetForm/Label';
import Inputs from '../../../Inputs';
import { InputWrapper } from '../../../V5GadgetForm/V5GadgetForm';
import FormHeader from '../../../../kingpin/forms/FormHeader';
import Form from '../../../../kingpin/forms/Form';
import FormContent from '../../../../kingpin/forms/FormContent';
import Colors2 from '../../../../theme/Colors2';

const Columns = ({
  onDragEnd,
  columnOrder,
  showColumn,
  hideColumn,
  close,
  isSizedToFitOverridden,
  onIsSizedToFitChange,
}: {
  onDragEnd: (result: DropResult) => void;
  columnOrder: { field: string; visible: boolean }[];
  showColumn: (field: string) => void;
  hideColumn: (field: string) => void;
  close: () => void;
  isSizedToFitOverridden?: boolean;
  onIsSizedToFitChange: (newValue: boolean) => void;
}) => (
  <Form>
    <FormHeader title={'Columns'} onClose={close} />
    <FormContent>
      <InputWrapper>
        <Label>Is sized to fit</Label>
        <Inputs.Toggle
          value={!!isSizedToFitOverridden}
          onChange={onIsSizedToFitChange}
        />
      </InputWrapper>
      <div
        style={{
          minHeight: 240,
          marginBottom: 24,
          paddingBottom: 12,
          borderBottom: `1px solid ${Colors2.Border}`,
        }}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={'metrics-drop'}>
            {(provided) => (
              <div ref={provided.innerRef}>
                {columnOrder.map((f, index) => (
                  <Draggable draggableId={f.field} index={index} key={f.field}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Item>
                          {f.field}
                          {f.visible && (
                            <Button
                              onClick={() => hideColumn(f.field)}
                              type="Secondary"
                              size="Small"
                              icon="cross"
                            />
                          )}
                          {!f.visible && (
                            <Button
                              onClick={() => showColumn(f.field)}
                              type="Secondary"
                              size="Small"
                              icon="checkmark"
                            />
                          )}
                        </Item>
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </FormContent>
    <Row style={{ justifyContent: 'space-between' }}>
      <Button onClick={close} type="Ghost" size="Small" label="Close" />
    </Row>
  </Form>
);

export default Columns;
