import React from 'react';
import Colors2 from '../theme/Colors2';
import Typography from 'kingpin/atoms/Typography';
import Row from './Common/Row';
import Icon from '../kingpin/atoms/Icon';

const Warning = ({
  warnings,
  isFirstLineBold,
}: {
  warnings: string[];
  isFirstLineBold?: boolean;
}) => {
  return (
    <Row
      style={{
        backgroundColor: Colors2.AvatarColors['3'].background,
        padding: 8,
      }}
    >
      <Icon icon="warning" color={Colors2.Secondary.warning} />
      <div>
        {warnings.map((warning, index) => (
          <div style={{ marginLeft: 8 }} key={warning}>
            <Typography.Body
              type="Body 12"
              color={isFirstLineBold && index === 0 ? 'black' : undefined}
            >
              {warning}
            </Typography.Body>
          </div>
        ))}
      </div>
    </Row>
  );
};

export default Warning;
