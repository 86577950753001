import React from 'react';
import Cell from '../../V5Gadget/Matrix/Cell';

import styled from 'styled-components';

const Wrapper = styled.div`
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const PerformanceCell = ({
  children,
  isCurrentMonth,
  isInput,
  onClick,
}: {
  children: JSX.Element | JSX.Element[];
  isCurrentMonth: boolean;
  isInput?: boolean;
  onClick?: () => void;
}) => (
  <Wrapper>
    <Cell
      onClick={onClick}
      style={{
        backgroundColor: isCurrentMonth ? `#E5F0FA` : undefined,
        height: 32,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: isInput ? 0 : '0px 6px',
      }}
    >
      {children}
    </Cell>
  </Wrapper>
);

export default PerformanceCell;
