import { useCallback, useContext, useEffect, useState } from 'react';
import SingleUseMetricPopupContext from '../../../../../contexts/SingleUseMetricPopupContext';
import InSituMetricEditorContext from '../../../../../contexts/InSituMetricEditorContext';

const useActions = (
  onSave: ({
    statusOverride,
    isSaveAs,
  }: {
    statusOverride?: Metrics.MetricStatus;
    isSaveAs?: boolean;
  }) => void,
  editingState: EditingState,
  isEditing: boolean,
  usageCount: number,
) => {
  const { isSingleUsePopup, isSaveToDataManager } = useContext(
    SingleUseMetricPopupContext,
  );
  const { isInSituEditor } = useContext(InSituMetricEditorContext);
  const [primaryAction, setPrimaryAction] = useState<{
    label: string;
    onClick: () => void;
    isDisabled?: boolean;
  }>({ label: isEditing ? 'Save' : 'Create Metric', onClick: () => {} });
  const [secondaryActions, setSecondaryActions] = useState<
    | {
        label: string;
        onClick: () => void;
      }[]
    | undefined
  >(undefined);

  const onSaveAsClicked = useCallback(() => {}, []);

  useEffect(() => {
    if (isSingleUsePopup) {
      setPrimaryAction(() => {
        const statusOverride = isSaveToDataManager ? 'public' : 'single use';
        switch (editingState) {
          case 'new metric':
            return {
              label: 'Add To Gadget',
              onClick: () => onSave({ statusOverride }),
            };
          case 'no changes':
            return {
              label: 'Update',
              onClick: window.tokenFunction,
              isDisabled: true,
            };
          case 'has changes':
            return {
              label: 'Update',
              onClick: () => onSave({ statusOverride }),
            };
          case 'invalid form':
          default:
            return {
              label: 'Add To Gadget',
              onClick: window.tokenFunction,
              isDisabled: true,
            };
        }
      });
      return;
    } else if (isInSituEditor) {
      setPrimaryAction(() => {
        switch (editingState) {
          case 'new metric':
            return {
              label: 'Add To Gadget',
              onClick: () => {
                const e = new Error();
                e.name = 'Unexpected state: new metric';
                throw e;
              },
            };
          case 'no changes':
          case 'no changes - archived':
            return {
              label: 'Update',
              onClick: window.tokenFunction,
              isDisabled: true,
            };
          case 'has changes':
          case 'has changes - archived':
            return {
              label: 'Update',
              onClick: () => onSave({}),
            };
          case 'invalid form':
            return {
              label: isEditing ? 'Save' : 'Create Metric',
              onClick: window.tokenFunction,
              isDisabled: true,
            };
        }
      });
      return;
    }

    setPrimaryAction(() => {
      switch (editingState) {
        case 'new metric':
          return {
            label: 'Create Metric',
            onClick: () => onSave({}),
          };
        case 'no changes':
          return {
            label: 'Save Changes',
            onClick: window.tokenFunction,
            isDisabled: true,
          };
        case 'no changes - archived':
          return {
            label: 'Unarchive',
            onClick: () => onSave({ statusOverride: 'public' }),
          };
        case 'has changes':
          return {
            label: 'Save Changes',
            onClick: () => onSave({}),
          };

        case 'has changes - archived':
          return {
            label: 'Save & Unarchive',
            onClick: () => onSave({ statusOverride: 'public' }),
          };
        case 'invalid form':
        default:
          return {
            label: isEditing ? 'Save' : 'Create Metric',
            onClick: window.tokenFunction,
            isDisabled: true,
          };
      }
    });
  }, [
    editingState,
    isEditing,
    isInSituEditor,
    isSaveToDataManager,
    isSingleUsePopup,
    onSave,
  ]);

  useEffect(() => {
    if (isSingleUsePopup || isInSituEditor) {
      setSecondaryActions(undefined);
      return;
    }
    setSecondaryActions(() => {
      switch (editingState) {
        case 'new metric':
          return undefined;
        case 'no changes':
        case 'has changes':
          return [
            {
              label: 'Save As',
              onClick: () =>
                onSave({ statusOverride: 'public', isSaveAs: true }),
            },
            ...(usageCount === 0
              ? [
                  {
                    label: 'Archive',
                    onClick: () => onSave({ statusOverride: 'archived' }),
                  },
                ]
              : []),
          ];
        case 'no changes - archived':
          return [
            {
              label: 'Save As',
              onClick: () =>
                onSave({ statusOverride: 'public', isSaveAs: true }),
            },
          ];
        case 'has changes - archived':
          return [
            {
              label: 'Save Changes',
              onClick: () => onSave({}),
            },
            {
              label: 'Save As',
              onClick: () =>
                onSave({ statusOverride: 'public', isSaveAs: true }),
            },
          ];
        case 'invalid form':
        default:
          return undefined;
      }
    });
  }, [
    editingState,
    isInSituEditor,
    isSingleUsePopup,
    onSave,
    onSaveAsClicked,
    usageCount,
  ]);

  return {
    primaryAction,
    secondaryActions,
  };
};

export default useActions;
