import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import Button from 'kingpin/atoms/Button';

import usePopup from '../../../../hooks/usePopup';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import Typography from 'kingpin/atoms/Typography';
import Row from '../../../../components/Common/Row';
import triggerPipeline from './triggerPipeline';
import moment from 'moment';
import Colors2 from '../../../../theme/Colors2';
import CloudFunctionClientContext from '../../../../contexts/CloudFunctionClientContext';
import TextInput from '../../../../kingpin/atoms/TextInput';

const Section = styled.div`
  margin-bottom: 16px;
`;

const MAX_DAYS = 31 * 6;

const TriggerPipelineButtonContainer = ({ dataType }: { dataType: string }) => {
  const { api } = useContext(CloudFunctionClientContext);
  const { isOpen, open, close } = usePopup();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string | undefined>();
  const [endDate, setEndDate] = useState<string | undefined>();
  const selectedDays =
    startDate !== undefined && endDate !== undefined
      ? Math.abs(moment(startDate).diff(endDate, 'day'))
      : undefined;
  const isValid =
    startDate !== undefined &&
    endDate !== undefined &&
    Math.abs(moment(startDate).diff(endDate, 'day')) < MAX_DAYS &&
    startDate < endDate;

  useEffect(() => {
    setIsLoading(false);
    setStartDate(undefined);
    setEndDate(undefined);
  }, [isOpen]);

  const onStartDateChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setStartDate(event.target.value);
    },
    [],
  );

  const onEndDateChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setEndDate(event.target.value);
    },
    [],
  );

  const onTriggerClicked = useCallback(() => {
    if (!startDate || !endDate || !isValid) {
      alert('Invalid input!');
      return;
    }
    setIsLoading(true);
    triggerPipeline({
      dataType,
      startDate,
      endDate,
      api,
    }).then((response) => {
      if (!response.ok) {
        alert('Something went wrong!');
      } else {
        alert('Trigger successful!');
      }
      setIsLoading(false);
      close();
    });
  }, [api, close, dataType, endDate, isValid, startDate]);

  return (
    <>
      <Button
        onClick={open}
        type="Ghost"
        size="Small"
        label="Trigger Pipeline"
      />
      {isOpen && (
        <ModalTransition>
          <Modal
            onClose={close}
            shouldScrollInViewport={false}
            autoFocus={false}
          >
            <div style={{ padding: '16px 24px' }}>
              <div style={{ marginBottom: 24 }}>
                <Typography.Header type="H4">{dataType}</Typography.Header>
              </div>
              <Section>
                <TextInput
                  type="date"
                  value={startDate || ''}
                  label={'Start Date'}
                  onChange={onStartDateChange}
                  data-testid={'startDate'}
                />
              </Section>
              <Section>
                <TextInput
                  type="date"
                  value={endDate || ''}
                  label={'End Date'}
                  onChange={onEndDateChange}
                  data-testid={'endDate'}
                />
              </Section>
              {!!startDate &&
                !!endDate &&
                selectedDays &&
                selectedDays > MAX_DAYS && (
                  <>
                    <div style={{ marginBottom: 4 }}>
                      <Typography.Body
                        type="Body 12"
                        color={Colors2.Secondary.error}
                      >
                        {`Invalid date range. You may only trigger a pipeline for up to ${MAX_DAYS} days (~ 6 months).`}
                      </Typography.Body>
                    </div>
                    <div style={{ marginBottom: 4 }}>
                      <Typography.Body
                        type="Body 12"
                        color={Colors2.Secondary.error}
                      >
                        {`You have selected ${selectedDays} days.`}
                      </Typography.Body>
                    </div>
                  </>
                )}

              {!!startDate && !!endDate && startDate > endDate && (
                <div style={{ marginBottom: 4 }}>
                  <Typography.Body type="Body 12">
                    The start date must be before the end date
                  </Typography.Body>
                </div>
              )}
              <Row
                style={{ justifyContent: 'flex-end', marginTop: 32 }}
                centerAlign
              >
                <Button
                  size="Small"
                  type="Primary"
                  onClick={onTriggerClicked}
                  isLoading={isLoading}
                  isDisabled={!isValid || isLoading}
                  label="Trigger pipeline"
                />
              </Row>
            </div>
          </Modal>
        </ModalTransition>
      )}
    </>
  );
};

export default TriggerPipelineButtonContainer;
