import React, { useContext } from 'react';
import styled from 'styled-components';
import TargetSrc from './images/target.png';
import { TopBarElem } from './TargetFieldList/TargetFieldList';
import Typography from 'kingpin/atoms/Typography';
import useEntityField from './hooks/useEntityField';
import TargetListContext from './contexts/TargetListContext';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const MessageDiv = styled(Container)`
  justify-content: center;
  align-items: center;
`;

const Img = styled.img`
  margin-bottom: 32px;
`;

const SelectAFieldMessage = () => {
  const { dataset } = useContext(TargetListContext);
  const entityField = useEntityField(dataset);

  return (
    <Container>
      <TopBarElem />
      <MessageDiv>
        <Img src={TargetSrc} />
        <Typography.Header type="H4">
          Select a Field to Set Targets
        </Typography.Header>
        <Typography.Body type="Body 12">
          {`Track performance by setting targets for your ${entityField}(s)`}
        </Typography.Body>
      </MessageDiv>
    </Container>
  );
};

export default SelectAFieldMessage;
