import React, { ChangeEvent, useContext, useState } from 'react';
import VideoCardForm from './VideoCardForm';
import AccountContext from '../../contexts/AccountContext';
import aguid from 'aguid';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import captureException from '../../services/captureException';
import buildCardLayout from 'contextProviders/WidgetGalleryProvider/buildCardLayout';
import findStartingY from 'contextProviders/WidgetGalleryProvider/findStartingY';

const VideoCardFormContainer = ({
  close,
  isOpen,
  setCurrentCanvas,
  startEditing,
  canvasMode,
}: {
  close: () => void;
  isOpen: boolean;
  setCurrentCanvas: React.Dispatch<React.SetStateAction<Canvas>>;
  startEditing: () => void;
  canvasMode: CanvasMode;
}) => {
  const { id } = useContext(AccountContext);
  const [videoId] = useState(() => aguid());
  const [isUploadingVideo, setIsUploadingVideo] = useState<boolean>(false);
  const [videoSrc, setVideoSrc] = useState<string | undefined>();

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length === 1) {
      setIsUploadingVideo(true);
      const file = event.target.files[0];
      firebase
        .storage()
        .ref(`accounts/${id}/videoCards/${videoId}`)
        .put(file)
        .then(() => {
          setIsUploadingVideo(false);
          const fr = new FileReader();
          fr.readAsDataURL(file);
          fr.onloadend = () => {
            const r = fr.result;
            if (typeof r === 'string') {
              setVideoSrc(r);
            }
          };
        })
        .catch((ex) => {
          captureException(ex);
        });
    }
  };

  const onSave = () => {
    if (!videoSrc && !isUploadingVideo) {
      return;
    }
    setCurrentCanvas((c) => {
      const newCard = (() => {
        return {
          layout: buildCardLayout(0, findStartingY(c.cards, canvasMode)),
          content: {
            type: 'Video' as 'Video',
            videoSrc: `accounts/${id}/videoCards/${videoId}`,
          },
        };
      })();

      return {
        ...c,
        cards: [...c.cards, newCard],
      };
    });
    startEditing();
    close();
  };

  const isValid = !!videoSrc && !isUploadingVideo;

  return (
    <VideoCardForm
      onSave={onSave}
      close={close}
      isOpen={isOpen}
      onChange={onChange}
      isUploadingVideo={isUploadingVideo}
      videoSrc={videoSrc}
      isValid={isValid}
    />
  );
};

export default VideoCardFormContainer;
