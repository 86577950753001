import { useContext } from 'react';
import ReportContext from '../../../contexts/ReportContext';
import DashboardGadgetContext from '../../../contexts/DashboardGadgetContext';

const useIsReportOrDashboardCard = () => {
  const { report } = useContext(ReportContext);
  const { dashboardGadget } = useContext(DashboardGadgetContext);

  return {
    isDashboardGadget: !!dashboardGadget,
    isReport: !!report,
  };
};

export default useIsReportOrDashboardCard;
