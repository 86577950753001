import React from 'react';
import styled from 'styled-components';
import Typography from 'kingpin/atoms/Typography';
import toSentenceCase from 'services/toSentenceCase';

const PickerDiv = styled.div`
  height: 24px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Section = styled.div`
  background-color: #f7f7fb;
  padding: 0px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const LeftDiv = styled(Section)`
  border: 1px solid #dcdcdc;
  border-radius: 4px 0px 0px 4px;
`;

const MiddleDiv = styled(Section)`
  border: 1px solid #dcdcdc;
`;

const RightDiv = styled(Section)`
  border: 1px solid #dcdcdc;
  border-radius: 0px 4px 4px 0px;
`;

const IntervalBadge = ({
  intervalLength,
  interval,
}: {
  intervalLength: number;
  interval: PerformanceConfiguration.Interval;
}) => (
  <PickerDiv>
    <LeftDiv>
      <Typography.Body type="Body 12">Every</Typography.Body>
    </LeftDiv>
    <MiddleDiv>
      <Typography.Body type="Body 12">{intervalLength}</Typography.Body>
    </MiddleDiv>
    <RightDiv>
      <Typography.Body type="Body 12">{`${toSentenceCase(
        interval,
      )}(s)`}</Typography.Body>
    </RightDiv>
  </PickerDiv>
);

export default IntervalBadge;
