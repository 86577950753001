import React from 'react';
import Bar from './Bar';
import FixedTrendCard from './FixedTrendCard';
import RollingTrendCard from './RollingTrendCard';
import SmartCard from './SmartCard';
import TimeSeries from './TimeSeries';
import TreeMap from './TreeMap';
import Pie from './Pie';
import Card from './Card/CardContent';
import Matrix from './Matrix';
import StackedArea from './StackedArea';
import SimpleGrid from './SimpleGrid';
import StackedBar from './StackedBar';

const V5Gadget = ({
  chartDefinition,
  chartRef,
  useSmallNoDataMessage,
}: {
  chartDefinition: V5ChartDefinition;
  chartRef: React.MutableRefObject<any>;
  useSmallNoDataMessage: boolean;
}) => {
  switch (chartDefinition.gadgetType) {
    case 'matrix':
      return <Matrix chartDefinition={chartDefinition} />;
    case 'bar':
      return (
        <Bar
          chartDefinition={chartDefinition}
          chartRef={chartRef}
          useSmallNoDataMessage={useSmallNoDataMessage}
        />
      );
    case 'stackedBar':
      return (
        <StackedBar chartDefinition={chartDefinition} chartRef={chartRef} />
      );
    case 'stackedArea':
      return (
        <StackedArea chartDefinition={chartDefinition} chartRef={chartRef} />
      );
    case 'horizontalBar':
      return (
        <Bar
          chartDefinition={chartDefinition}
          chartRef={chartRef}
          isHorizontal
          useSmallNoDataMessage={useSmallNoDataMessage}
        />
      );
    case 'combo':
      return (
        <Bar
          chartDefinition={chartDefinition}
          chartRef={chartRef}
          useSmallNoDataMessage={useSmallNoDataMessage}
          isCombo
        />
      );
    case 'card':
      return <Card chartDefinition={chartDefinition} />;
    case 'fixedTrendCard':
      return <FixedTrendCard chartDefinition={chartDefinition} />;
    case 'rollingTrendCard':
      return <RollingTrendCard chartDefinition={chartDefinition} />;
    case 'pie':
      return (
        <Pie
          chartDefinition={chartDefinition}
          chartRef={chartRef}
          useSmallNoDataMessage={useSmallNoDataMessage}
        />
      );
    case 'smartCard':
      return <SmartCard chartDefinition={chartDefinition} />;
    case 'timeSeries':
      return (
        <TimeSeries
          chartDefinition={chartDefinition}
          chartRef={chartRef}
          useSmallNoDataMessage={useSmallNoDataMessage}
        />
      );
    case 'treeMap':
      return (
        <TreeMap
          chartDefinition={chartDefinition}
          chartRef={chartRef}
          useSmallNoDataMessage={useSmallNoDataMessage}
        />
      );
    case 'simpleGrid':
      return <SimpleGrid chartDefinition={chartDefinition} />;
    default:
      return null;
  }
};

export default V5Gadget;
