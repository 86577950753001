import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import getIdentifier from 'getIdentifier';

const buildQuery = ({
  reminderEventNames,
  dateScope,
  filters,
}: {
  reminderEventNames: string[];
  dateScope: DateRangeInput;
  filters: FilterInput[];
}) => ({
  query: gql`
    query getReminders(
      $reminderEventNames: [String]!
      $dateScope: DateRangeInput!
      $filters: [FilterInput]!
    ) {
      getReminders(
        reminderEventNames: $reminderEventNames
        dateScope: $dateScope
        filters: $filters
      ) {
        name
        date
        driver
        driverName
        employeeName
        truck
        overdue
        description
      }
    }
  `,
  variables: {
    reminderEventNames,
    dateScope,
    filters,
  },
});

const getReminders = async ({
  reminderEventNames,
  dateScope,
  filters,
  client,
}: {
  reminderEventNames: string[];
  dateScope: DateRangeInput;
  filters: FilterInput[];
  client: ApolloClient<NormalizedCacheObject>;
}): Promise<Reminder[]> => {
  const gqlQuery = buildQuery({
    reminderEventNames,
    dateScope,
    filters,
  });
  const response = await client.query(gqlQuery);
  return response.data.getReminders.map((r: Reminder) => ({
    ...r,
    key: getIdentifier(),
  }));
};

export default getReminders;
