import React from 'react';
import SpecialMetricsContext from '../contexts/SpecialMetricsContext';
import useSpecialMetrics from '../hooks/useSpecialMetrics';

const SpecialMetricsProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { specialMetrics, isLoading: isLoadingSpecialMetrics } =
    useSpecialMetrics();

  return (
    <SpecialMetricsContext.Provider
      value={{
        specialMetrics,
        isLoading: isLoadingSpecialMetrics,
      }}
    >
      {children}
    </SpecialMetricsContext.Provider>
  );
};

export default SpecialMetricsProvider;
