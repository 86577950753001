import React from 'react';
import styled from 'styled-components';
import Typography from 'kingpin/atoms/Typography';
import Button from 'kingpin/atoms/Button';
import Colors2 from '../../../../../theme/Colors2';

const Wrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid ${Colors2.Border};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 24px;
`;

const NewCommitmentSlideOutHeading = ({
  close,
  headingText,
}: {
  close: () => void;
  headingText: string;
}) => (
  <Wrapper>
    <div style={{ marginBottom: 24 }}>
      <div style={{ marginBottom: 4 }}>
        <Typography.Header type={'H4'}>{headingText}</Typography.Header>
      </div>
      <Typography.Header type={'H5'} color={Colors2.Grey['4']}>
        Please fill out the form below
      </Typography.Header>
    </div>
    <Button size={'Small'} type={'Secondary'} icon={'cross'} onClick={close} />
  </Wrapper>
);

export default NewCommitmentSlideOutHeading;
