import React, { useContext, useEffect } from 'react';
import DashboardGadgetFilteringButton from './DashboardGadgetFilteringButton';
import ReportDrillDownsContext from '../../contexts/ReportDrillDownsContext';
import DashboardGadgetContext from '../../contexts/DashboardGadgetContext';
import ConfigureDashboardGadgetContext from '../../contexts/ConfigureDashboardGadgetContext';
import DashboardContext from '../../contexts/DashboardContext';
import PermissionGates from '../PermissionGates';
import PERMISSIONS from '../../permissionDefinitions';
import ContextMenuContext from '../../kingpin/atoms/ContextMenu/ContextMenuContext';

const DashboardGadgetFilteringButtonContainer = () => {
  const { dashboardGadget, setIsEditing } = useContext(DashboardGadgetContext);
  const { closeMenu } = useContext(ContextMenuContext);
  const { open } = useContext(ConfigureDashboardGadgetContext);

  const onOpen = () => {
    open(dashboardGadget);
    closeMenu();
  };

  useEffect(() => {
    return () => {
      setIsEditing(false);
    };
  }, [setIsEditing]);

  return <DashboardGadgetFilteringButton open={onOpen} />;
};

const RenderGate = () => {
  const { templatedFrom } = useContext(DashboardContext);
  const { isForDashboardCard } = useContext(ReportDrillDownsContext);
  if (!isForDashboardCard || !!templatedFrom) {
    return null;
  }

  return (
    <PermissionGates.Has requiredPermission={PERMISSIONS.DASHBOARDS.EDIT}>
      <DashboardGadgetFilteringButtonContainer />
    </PermissionGates.Has>
  );
};

export default RenderGate;
