import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import Tooltip from 'components/Tooltip';
import Typography from 'kingpin/atoms/Typography';
import ConfigureMicrosoftSsoContext from '../contexts/ConfigureMicrosoftSsoContext';
import Colors2 from '../../../../../theme/Colors2';
import Button from 'kingpin/atoms/Button';
import AnalyticsContext from '../../../../../contexts/AnalyticsContext';

const CurrentGroupsDiv = styled.div`
  padding-top: 12px;
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 0px 16px;
`;

const PlateDiv = styled.div`
  padding: 4px 8px;
  background-color: ${Colors2.AvatarColors['1'].background};
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const GroupPlate = ({ group }: { group: MicrosoftSso.Group }) => {
  const { setSelectedGroups } = useContext(ConfigureMicrosoftSsoContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const onRemoveClicked = useCallback(() => {
    trackEvent('SSO Configurations - Microsoft - Group Removed');
    setSelectedGroups((currentGroups) =>
      currentGroups.filter((g) => g.id !== group.id),
    );
  }, [group.id, setSelectedGroups, trackEvent]);

  return (
    <PlateDiv>
      <div style={{ marginRight: 8 }}>
        <Tooltip content={group.id}>
          <Typography.Body type="Body 12">{group.displayName}</Typography.Body>
        </Tooltip>
      </div>
      <Button
        icon="cross"
        type="Ghost"
        size="Small"
        onClick={onRemoveClicked}
      />
    </PlateDiv>
  );
};

const CurrentGroups = () => {
  const { selectedGroups } = useContext(ConfigureMicrosoftSsoContext);

  return (
    <CurrentGroupsDiv>
      {selectedGroups.map((group) => (
        <GroupPlate group={group} key={group.id} />
      ))}
    </CurrentGroupsDiv>
  );
};

export default CurrentGroups;
