import { useContext } from 'react';
import AccountContext from '../../contexts/AccountContext';
import moment from 'moment';
import buildGoalFilterInput from '../../utils/goalsHelpers/buildGoalFilterInput';
import GeneralGoalContext from '../../contexts/GeneralGoalContext';
import useValueFormatters from '../useValueFormatters';
import useToDrillDowns from 'components/Report/useToReportDrillDown';

export const intervalToHuman = (i: FleetOps.Interval | '') => {
  switch (i) {
    case 'day':
      return 'Daily';
    case 'week':
      return 'Weekly';
    case 'month':
      return 'Monthly';
    case 'quarter':
      return 'Quarterly';
    case 'year':
      return 'Yearly';
    case '':
      return '';
    default:
      throw new Error(`Unknown interval: ${i}`);
  }
};

const useGoalProgressBar = (goal: GeneralGoal) => {
  const { primaryKpiData, target, targetValueForNow, dateBuckets } =
    useContext(GeneralGoalContext);
  const { isDemoAccount, demoAccountNow } = useContext(AccountContext);
  const { formatMetric } = useValueFormatters();
  const today = moment(
    isDemoAccount && demoAccountNow ? demoAccountNow : undefined,
  )
    .utc()
    .format('YYYY-MM-DD');
  const toDrillDowns = useToDrillDowns();
  const { dateScope } = buildGoalFilterInput(goal, toDrillDowns);

  const { aboveTargetIsGood } = goal;
  const currentValue =
    primaryKpiData && primaryKpiData.value ? primaryKpiData.value : 0;
  const isAboveTarget =
    currentValue !== undefined ? currentValue > targetValueForNow : false;

  const interval = '';

  const progressPercent = (currentValue / target) * 100;

  const isAboveTargetForNow =
    currentValue !== undefined ? currentValue > targetValueForNow : false;

  const numIntervals = dateBuckets.length;
  const passedIntervals =
    primaryKpiData && primaryKpiData.trend
      ? primaryKpiData.trend.filter((t) => t.date <= today).length
      : 0;
  const remainingIntervalsMessage = `${numIntervals - passedIntervals} ${
    goal.cadence
  } remaining`;

  const performanceText = (() => {
    const distanceFromTarget =
      currentValue !== undefined ? currentValue - targetValueForNow : 0;

    return formatMetric({
      value: Math.abs(distanceFromTarget),
      metricId: goal.metricId,
    });
  })();

  const remaining = formatMetric({
    value: target - currentValue,
    metricId: goal.metricId,
  });

  return {
    currentValue: formatMetric({
      value: currentValue,
      metricId: goal.metricId,
    }),
    target: formatMetric({
      value: target,
      metricId: goal.metricId,
    }),
    targetValueForNow: formatMetric({
      value: targetValueForNow,
      metricId: goal.metricId,
    }),
    performanceText,
    isAboveTarget,
    aboveTargetIsGood,
    interval: intervalToHuman(interval),
    progressPercent,
    remainingIntervals: remainingIntervalsMessage,
    isAboveTargetForNow,
    remaining,
    isLoading: !primaryKpiData,
    dateRange: dateScope,
  };
};

export default useGoalProgressBar;
