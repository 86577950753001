import React, { useCallback, useContext, useEffect, useState } from 'react';
import ConfigureMicrosoftSsoContext from '../contexts/ConfigureMicrosoftSsoContext';
import useUsersInSelectedGroups from './useUsersInGroups';

const useFilteredUsers = () => {
  const { selectedGroups } = useContext(ConfigureMicrosoftSsoContext);
  const { users, isLoading, refreshUsers } = useUsersInSelectedGroups();
  const [filteredUsers, setFilteredUsers] = useState<
    MicrosoftSso.UserWithMemberships[]
  >([]);
  const [searchText, setSearchText] = useState<string>('');
  const [filteredGroups, setFilteredGroups] = useState<string[] | undefined>();
  const [groupFilterOptions, setGroupFilterOptions] = useState<
    DropdownOption[]
  >([]);

  const getMembersInGroup = useCallback(
    (group: MicrosoftSso.Group): number => {
      return users.filter((u) =>
        u.groupMemberships.some((g) => g.id === group.id),
      ).length;
    },
    [users],
  );

  const onSearchTextChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
    },
    [],
  );

  useEffect(() => {
    const allOption: DropdownOption = {
      label: `All Users (${users.length})`,
      onSelected: () => setFilteredGroups(undefined),
      isSelected: filteredGroups === undefined,
    };
    const groupOptions = selectedGroups.map((group) => {
      const isSelected = filteredGroups
        ? filteredGroups.includes(group.id)
        : false;
      return {
        label: `${group.displayName} (${getMembersInGroup(group)})`,
        onSelected: () =>
          setFilteredGroups((current) => {
            if (current === undefined) {
              if (isSelected) {
                return undefined;
              }

              return [group.id];
            } else {
              if (isSelected) {
                return current.filter((c) => c !== group.id);
              }

              return [...current, group.id];
            }
          }),
        isSelected,
      };
    });
    setGroupFilterOptions([allOption, ...groupOptions]);
  }, [filteredGroups, getMembersInGroup, selectedGroups, users.length]);

  useEffect(() => {
    setFilteredUsers(
      users.filter((u) => {
        const searchTextMatch =
          u.displayName.toLowerCase().includes(searchText.toLowerCase()) ||
          u.email.toLowerCase().includes(searchText.toLowerCase());
        const groupMatch = filteredGroups
          ? u.groupMemberships.some((g) => filteredGroups.includes(g.id))
          : true;

        return searchTextMatch && groupMatch;
      }),
    );
  }, [filteredGroups, searchText, users]);

  return {
    filteredUsers,
    searchText,
    onSearchTextChanged,
    groupFilterOptions,
    isLoading,
    refreshUsers,
    users,
  };
};

export default useFilteredUsers;
