import { useCallback, useContext, useEffect, useState } from 'react';
import DatasetDefinitionsContext from '../../../../../../contexts/DatasetDefinitionsContext';

const RESERVED_DATASETS = ['commission'];

const useIsNameInvalid = (name: string | undefined) => {
  const { datasets } = useContext(DatasetDefinitionsContext);
  const getIsNameInvalid = useCallback(() => {
    if (name && RESERVED_DATASETS.includes(name)) {
      return true;
    }

    return datasets.some((ds) => ds.type === name);
  }, [datasets, name]);
  const [isNameInvalid, setIsNameInvalid] = useState<boolean>(() =>
    getIsNameInvalid(),
  );

  useEffect(() => {
    setIsNameInvalid(getIsNameInvalid());
  }, [getIsNameInvalid]);

  return isNameInvalid;
};

export default useIsNameInvalid;
