import React from 'react';
import styled from 'styled-components';

import MicrosoftIconSrc from './microsoft-icon.svg';
import Colors2 from '../../../theme/Colors2';
import Typography from 'kingpin/atoms/Typography';
import Row from '../../../components/Common/Row';

const IconImg = styled.img`
  margin-right: 8px;
`;

const Button = styled.div`
  padding: 0px 16px;
  cursor: pointer;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: white;
  border: 1px solid #8c8c8c;
  border-radius: 3px;

  height: 41px;

  &:hover {
    background-color: ${Colors2.Primary['8']};
  }
`;

const Icon = () => <IconImg src={MicrosoftIconSrc} />;

const MicrosoftLoginButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button onClick={onClick}>
      <Row centerAlign>
        <Icon />
        <Typography.Body type="Button Text">
          Sign in with Microsoft
        </Typography.Body>
      </Row>
    </Button>
  );
};

export default MicrosoftLoginButton;
