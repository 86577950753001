import { gql } from '@apollo/client';

const buildAggregateCompoundMetricQuery = ({
  metrics,
  expressions,
  filterInput,
  dateScope,
  query,
  alias,
  groupBy,
  trendByCalendarInterval,
  trendByFixedIntervalDays,
  groupByDayOfWeek,
  groupByRanges,
  groupByInterval,
}: {
  metrics: MetricInput[];
  expressions: Expression2[];
  filterInput: FilterInput[];
  dateScope: DateRangeInput;
  query: string;
  alias: string;
  groupBy?: V5GroupBy;
  trendByCalendarInterval?: FleetOps.Interval;
  trendByFixedIntervalDays?: number;
  groupByDayOfWeek?: boolean;
  groupByRanges?: V5RangeAggregation;
  groupByInterval?: V5Histogram;
}) => {
  return {
    query: gql`
      query ${alias}(
        $metrics: [MetricInput]!
        $filters: [FilterInput]!
        $dateScope: [DateRangeInput!]!
        $expressions: [Expression2]!
        $groupBy: GroupBy
        $trendByCalendarInterval: Interval
        $trendByFixedIntervalDays: Int
        $groupByDayOfWeek: Boolean
        $groupByRanges: RangeAggregation
        $groupByInterval: Histogram
      ) {
        ${query}(
          metrics: $metrics
          filters: $filters
          dateScope: $dateScope
          expressions: $expressions
          groupBy: $groupBy
          trendByCalendarInterval: $trendByCalendarInterval
          trendByFixedIntervalDays: $trendByFixedIntervalDays
          groupByDayOfWeek: $groupByDayOfWeek
          groupByRanges: $groupByRanges
          groupByInterval: $groupByInterval
        )
      }
    `,
    variables: {
      metrics,
      filters: filterInput,
      dateScope,
      expressions,
      groupBy,
      trendByCalendarInterval,
      trendByFixedIntervalDays,
      groupByDayOfWeek,
      groupByRanges,
      groupByInterval,
    },
  };
};

export default buildAggregateCompoundMetricQuery;
