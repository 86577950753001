import { ReactText, useContext, useEffect, useState } from 'react';
import getMetrics from '../useMetricMatrix/getMetrics';
import DashboardGadgetContext from '../../contexts/DashboardGadgetContext';
import ComparisonContext from '../../contexts/ComparisonContext';
import useUsedMetrics from '../useUsedMetrics';
import GqlClientContext from '../../contexts/GqlClientContext';
import useDateScope from '../useDateScope';
import { toInterval } from '../../components/V5Gadget/toAutoInterval';
import moment from 'moment';
import useFilterInput from '../useFilterInput';
import useLockedDebouncedEffect from '../useLockedDebouncedEffect';
import useToMetricInput from 'hooks/useToMetricInput';

const useGetMetricsInputs = (
  chartDef: V5ChartDefinition,
  autoInterval?: AutoInterval,
) => {
  // Context
  const { client } = useContext(GqlClientContext);
  const filterInput = useFilterInput(
    chartDef.dimensionA ? chartDef.dimensionA.field : undefined,
  );
  const toMetricInput = useToMetricInput();
  const { dashboardGadget } = useContext(DashboardGadgetContext);
  const { currentComparison: comparison } = useContext(ComparisonContext);

  // State
  const [currentInput, setCurrentInput] = useState<
    Queries.GetMetricsInput | undefined
  >();
  const [previousInput, setPreviousInput] = useState<
    Queries.GetMetricsInput | undefined
  >();

  // Hooks
  const currentDateScope = useDateScope({});
  const previousDateScope = useDateScope({
    comparison,
  });
  const { usedMetrics } = useUsedMetrics(chartDef);

  // Effects
  useEffect(() => {
    setCurrentInput({
      chartDef,
      filterInput,
      dateScope: currentDateScope,
      toMetricInput,
      usedMetrics,
      isDashboardGadget: !!dashboardGadget,
      autoInterval,
      client,
    });
  }, [
    autoInterval,
    chartDef,
    client,
    currentDateScope,
    dashboardGadget,
    filterInput,
    toMetricInput,
    usedMetrics,
  ]);

  useEffect(() => {
    if (!comparison) {
      setPreviousInput(undefined);
      return;
    }

    setPreviousInput({
      chartDef,
      filterInput,
      dateScope: previousDateScope,
      toMetricInput,
      usedMetrics,
      isDashboardGadget: !!dashboardGadget,
      autoInterval,
      client,
    });
  }, [
    autoInterval,
    chartDef,
    client,
    comparison,
    dashboardGadget,
    filterInput,
    previousDateScope,
    toMetricInput,
    usedMetrics,
  ]);

  return {
    currentInput,
    previousInput,
  };
};

const useMetricResponses = (
  chartDef: V5ChartDefinition,
  autoInterval?: AutoInterval,
) => {
  // Context
  const { currentComparison: comparison } = useContext(ComparisonContext);

  // State
  const [currentResponse, setCurrentResponse] = useState<
    MetricsResponse | undefined
  >();
  const [previousResponse, setPreviousResponse] = useState<
    MetricsResponse | undefined
  >();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Hooks
  const { currentInput, previousInput } = useGetMetricsInputs(
    chartDef,
    autoInterval,
  );

  // Effects
  useLockedDebouncedEffect({
    args: currentInput,
    callback: getMetrics,
    responseHandler: setCurrentResponse,
  });
  useLockedDebouncedEffect({
    args: previousInput,
    callback: getMetrics,
    responseHandler: setPreviousResponse,
  });

  useEffect(() => {
    setIsLoading(true);
  }, [currentInput, previousInput]);

  useEffect(() => {
    if (comparison) {
      setIsLoading(!currentResponse || !previousResponse);
    } else {
      setIsLoading(!currentResponse);
    }
  }, [comparison, currentResponse, previousResponse]);

  return {
    currentResponse,
    previousResponse,
    isLoadingResponses: isLoading,
  };
};

const useV5ChartData = (
  chartDef: V5ChartDefinition,
  autoInterval?: AutoInterval,
) => {
  // Context
  const { currentComparison: comparison } = useContext(ComparisonContext);

  // State
  const [data, setData] = useState<V5ChartData>();
  const [comparisonData, setComparisonData] = useState<V5ChartData>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Hooks
  const { currentResponse, previousResponse, isLoadingResponses } =
    useMetricResponses(chartDef, autoInterval);
  const { directlyUsed } = useUsedMetrics(chartDef);

  // Effects
  useEffect(() => {
    if (!currentResponse) {
      return;
    }

    const { series } = chartDef;
    const result = {} as V5ChartData;
    series.forEach((s) => {
      const { metricId } = s;
      const metric = directlyUsed.find((o) => o.id === metricId);
      if (!metric) {
        console.warn(`Metric ${metricId} not found`);
      } else {
        result[metricId] = {
          name: s.displayName ? s.displayName : metric.name,
          metric,
          response: currentResponse as { [key: string]: ReactText }[],
        };
      }
    });
    setData(result);
  }, [chartDef, currentResponse, directlyUsed]);

  useEffect(() => {
    if (!previousResponse || !comparison || !currentResponse) {
      return;
    }

    const { series } = chartDef;

    const responseWithMissingDates = (() => {
      let newResponse = [...previousResponse];
      if (newResponse.length === 0) {
        return newResponse;
      }
      const interval = toInterval(newResponse[0].interval);
      if (!interval) {
        return newResponse;
      }
      if (
        comparison.compareType === 'lastYear' &&
        newResponse.length >= 2 &&
        currentResponse.length >= 2
      ) {
        const lastItemInPrev = newResponse[newResponse.length - 1].date;
        const lastItemInCurrent =
          currentResponse[currentResponse.length - 1].date;

        if (
          moment(lastItemInCurrent).get(interval) !==
          moment(lastItemInPrev).get(interval)
        ) {
          newResponse.pop();
        }
      }
      const expectedNumDates = currentResponse.length;
      if (newResponse.length < expectedNumDates) {
        const missingNumDates = expectedNumDates - newResponse.length;

        for (let i = missingNumDates; i > 0; i--) {
          const firstDate = newResponse[0].date;
          const date = moment(firstDate)
            .subtract({ [interval]: 1 })
            .format('YYYY-MM-DD');
          const newItem = {
            date,
          };
          series.forEach((s) => {
            // @ts-ignore
            newItem[s.metricId] = undefined;
          });
          newResponse = [newItem, ...newResponse];
        }
      }
      return newResponse;
    })();

    const result = {} as V5ChartData;
    series.forEach((s) => {
      const { metricId } = s;
      const metric = directlyUsed.find((o) => o.id === metricId);
      if (!metric) {
        console.warn(`Metric ${metricId} not found`);
      } else {
        result[metricId] = {
          name: s.displayName ? s.displayName : metric.name,
          metric,
          response: responseWithMissingDates as {
            [key: string]: ReactText;
          }[],
        };
      }
    });

    setComparisonData(result);
  }, [chartDef, comparison, currentResponse, previousResponse, directlyUsed]);

  useEffect(() => {
    if (comparison) {
      setIsLoading(isLoadingResponses || !data || !comparisonData);
    } else {
      setIsLoading(isLoadingResponses || !data);
    }
  }, [comparison, comparisonData, data, isLoadingResponses]);

  return { data, isLoading, comparisonData };
};

export default useV5ChartData;
