import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Typography from 'kingpin/atoms/Typography';

import { Label } from '../../MyAccount/Profile/styles';
import DimensionRangeInput from '../DimensionRangeInput';
import Inputs from 'components/Inputs';
import Colors2 from '../../../theme/Colors2';

const Positioner = styled.div`
  background-color: ${Colors2.Grey['9']};
  padding: 12px;
  margin-bottom: 8px;
`;

const DInput = ({
  label,
  dimension,
  setDimension,
  availableDimensions,
}: {
  label: string;
  dimension?: Dimension;
  setDimension: React.Dispatch<React.SetStateAction<Dimension | undefined>>;
  availableDimensions: Dimension[];
}) => {
  const [options, setOptions] = useState<DropdownOption[]>([]);
  useEffect(() => {
    const base = availableDimensions.map((d) => ({
      label: d.field,
      isSelected: dimension && dimension.field === d.field,
      onSelected: () => {
        setDimension({
          field: d.field,
          isGroupByDayOfWeek: d.isGroupByDayOfWeek,
          fieldType: d.fieldType,
        });
      },
    }));

    setOptions([
      {
        label: 'None',
        isSelected: dimension === undefined,
        onSelected: () => {
          setDimension(undefined);
        },
      },
      ...base,
    ]);
  }, [dimension, availableDimensions, setDimension]);

  const isRangeDimension =
    dimension &&
    (dimension.fieldType === 'float' || dimension.fieldType === 'long');

  const setRangeInput = useCallback(
    (input: CustomDimensionRangeInput | FixedDimensionRangeInput) => {
      setDimension((d: Dimension | undefined) => {
        if (!d) {
          return;
        }
        return {
          ...d,
          rangeInput: input,
        };
      });
    },
    [setDimension],
  );

  return (
    <Positioner>
      <Label>
        <Typography.Body type="Label">{label}</Typography.Body>
      </Label>
      <Inputs.Dropdown options={options} isSearchEnabled />
      {dimension && isRangeDimension && (
        <DimensionRangeInput
          input={dimension.rangeInput}
          setInput={setRangeInput}
        />
      )}
    </Positioner>
  );
};

const DimensionsInput = ({
  availableDimensions,
  dimensionA,
  setDimensionA,
  dimensionB,
  setDimensionB,
  isMultipleDimensionChart,
}: {
  availableDimensions: Dimension[];
  dimensionA?: Dimension;
  setDimensionA: React.Dispatch<React.SetStateAction<Dimension | undefined>>;
  dimensionB?: Dimension;
  setDimensionB: React.Dispatch<React.SetStateAction<Dimension | undefined>>;
  isMultipleDimensionChart: boolean;
}) => (
  <React.Fragment>
    <DInput
      label="A"
      dimension={dimensionA}
      availableDimensions={availableDimensions}
      setDimension={setDimensionA}
    />
    {isMultipleDimensionChart && (
      <DInput
        label="B"
        dimension={dimensionB}
        availableDimensions={availableDimensions}
        setDimension={setDimensionB}
      />
    )}
  </React.Fragment>
);

export default DimensionsInput;
