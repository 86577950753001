import { useCallback } from 'react';
import { toFixedPlate } from 'components/Report/useToReportDrillDown';
import filterPlateTypeCheckers from 'types/filterPlateTypeCheckers';

const useToPlatesWithoutVariables = () => {
  const toPlatesWithoutVariables = useCallback(
    ({
      plates,
      variableDrillDowns,
    }: {
      plates: FilterPlate[];
      variableDrillDowns: VariableDrillDownType[];
    }) => {
      return plates.map((plate) => {
        if (filterPlateTypeCheckers.isVariable(plate)) {
          return toFixedPlate({ plate, variableDrillDowns });
        } else if (filterPlateTypeCheckers.isNotVariable(plate)) {
          return plate;
        } else {
          const error = new Error();
          error.name = `Unknown plate type: ${plate}`;
          throw error;
        }
      });
    },
    [],
  );
  return toPlatesWithoutVariables;
};

export default useToPlatesWithoutVariables;
