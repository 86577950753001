import { useCallback, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import useCostIntervalLookup from './useCostIntervalLookup';
import GqlClientContext from 'contexts/GqlClientContext';
import setIntervalCosts from '../../api/setIntervalCosts';
import ToastContext from 'contexts/ToastContext';
import AnalyticsContext from 'contexts/AnalyticsContext';

const useChanges = (activityCosts: Costs.PersistedCostModel | undefined) => {
  const { client } = useContext(GqlClientContext);
  const { showToast } = useContext(ToastContext);
  const { trackEvent } = useContext(AnalyticsContext);

  const {
    getPersistableCosts,
    checkIsCpmEstimated,
    intervalCosts,
    refreshCosts,
    getCostValue,
    getCpmValue,
    getDefaultCostLookup,
    setCostsLookup,
    intervals,
    updateCostValue,
    isLoading,
  } = useCostIntervalLookup(activityCosts);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const [isDiscardedChanges, setIsDiscardedChanges] = useState<boolean>(false);

  const isValidInterval = useCallback(
    (costInterval: Costs.IntervalCostsInput): boolean => {
      return costInterval.groupCosts.every((g) => {
        return g.costs.every((c) => {
          const userInputValue = getCostValue({
            startDate: costInterval.startDate,
            groupDefinition: g.groupDefinition,
            costFieldName: c.costFieldName,
          });

          return userInputValue !== undefined;
        });
      });
    },
    [getCostValue],
  );

  const isChangedInterval = useCallback(
    (costInterval: Costs.IntervalCostsInput): boolean => {
      return costInterval.groupCosts.some((g) => {
        return g.costs.some((c) => {
          const userInputValue = getCostValue({
            startDate: costInterval.startDate,
            groupDefinition: g.groupDefinition,
            costFieldName: c.costFieldName,
          });

          const persistedInterval =
            getDefaultCostLookup()[costInterval.startDate];
          const persistedCost = persistedInterval
            ? persistedInterval.groups[g.groupDefinition.groupName].costs[
                c.costFieldName
              ]
            : undefined;

          return userInputValue !== persistedCost;
        });
      });
    },
    [getCostValue, getDefaultCostLookup],
  );

  // Estimated if (not in persisted) AND (filled) AND (not changed)
  const checkIsIntervalEstimated = useCallback(
    (startDate: string): boolean => {
      if (!intervalCosts || intervalCosts.length === 0) {
        return false;
      }

      const newInterval = getPersistableCosts().find(
        (i) => i.startDate === startDate,
      );

      return (
        !intervalCosts.find((c) => c.startDate === startDate) &&
        !!newInterval &&
        isValidInterval(newInterval) &&
        !isChangedInterval(newInterval)
      );
    },
    [getPersistableCosts, intervalCosts, isChangedInterval, isValidInterval],
  );

  const getMutatedCosts = useCallback(() => {
    if (intervalCosts === undefined) {
      return [];
    }

    const persistedCosts = [...intervalCosts];
    const newCosts = getPersistableCosts();

    const newIntervals = newCosts
      .filter(
        (c1) => !persistedCosts.some((c2) => c2.startDate === c1.startDate),
      )
      .filter(isValidInterval)
      .filter((c) => !checkIsIntervalEstimated(c.startDate));

    const existingIntervals = newCosts.filter(isValidInterval).filter((c1) => {
      const existingInterval = persistedCosts.find(
        (c2) => c2.startDate === c1.startDate,
      );
      if (!existingInterval) {
        return false;
      }

      return !_.isEqual(
        c1.groupCosts.map((g) => g.costs),
        existingInterval.groupCosts.map((g) => g.costs),
      );
    });

    return [...newIntervals, ...existingIntervals].map((interval) => ({
      ...interval,
      groupCosts: interval.groupCosts.map((groupCost) => ({
        groupDefinition: groupCost.groupDefinition,
        costs: groupCost.costs,
      })),
    }));
  }, [
    getPersistableCosts,
    intervalCosts,
    checkIsIntervalEstimated,
    isValidInterval,
  ]);

  const getHasUnsavedChanged = useCallback(() => {
    return getPersistableCosts().some(isChangedInterval);
  }, [getPersistableCosts, isChangedInterval]);

  const discardChanges = useCallback(() => {
    setCostsLookup(getDefaultCostLookup());
    setIsDiscardedChanges(true);
    showToast('Changes discarded');
    trackEvent('Costs - Discarded Changes', {
      costModel: activityCosts ? activityCosts.modelType : 'undefined model',
    });
  }, [
    getDefaultCostLookup,
    setCostsLookup,
    showToast,
    trackEvent,
    activityCosts,
  ]);

  const saveChanges = useCallback(async () => {
    if (activityCosts === undefined) {
      const err = new Error();
      err.name = 'Cost model not found';
      throw err;
    }

    await setIntervalCosts({
      costModelId: activityCosts.id,
      intervalCosts: getMutatedCosts(),
      client,
    });
    await refreshCosts();
    showToast('Changes saved');

    if (activityCosts.isDraft) {
      trackEvent('Costs - Draft Saved', {
        costModel: activityCosts.modelType,
      });
    } else {
      trackEvent('Costs - Updated Costs', {
        costModel: activityCosts.modelType,
      });
    }
  }, [
    activityCosts,
    client,
    getMutatedCosts,
    refreshCosts,
    showToast,
    trackEvent,
  ]);

  const getIsValid = useCallback(() => {
    if (intervals.length === 0) {
      return false;
    }

    const mutatedCosts = getMutatedCosts();
    return mutatedCosts.length !== 0 && mutatedCosts.every(isValidInterval);
  }, [getMutatedCosts, intervals.length, isValidInterval]);

  const getInvalidIntervals = useCallback(() => {
    if (intervals.length === 0) {
      return [];
    }

    const newCosts = getPersistableCosts();
    const changedCosts = newCosts.filter(isChangedInterval);

    return changedCosts.filter((changedCosts) => {
      return !isValidInterval(changedCosts);
    });
  }, [
    getPersistableCosts,
    intervals.length,
    isChangedInterval,
    isValidInterval,
  ]);

  // Effect
  useEffect(() => {
    if (!isLoading) {
      setHasUnsavedChanges(getHasUnsavedChanged());
    }
  }, [getHasUnsavedChanged, isLoading]);

  const [isValid, setIsValid] = useState<boolean>(getIsValid());
  const [invalidIntervals, setInvalidIntervals] =
    useState<Costs.IntervalCostsInput[]>(getInvalidIntervals);
  const [isSavingError, setIsSavingError] = useState<boolean>(false);

  useEffect(() => {
    if (isSavingError && invalidIntervals.length === 0) {
      setIsSavingError(false);
    }
  }, [invalidIntervals.length, isSavingError]);

  useEffect(() => {
    setIsValid(getIsValid());
  }, [getIsValid]);

  useEffect(() => {
    setInvalidIntervals(getInvalidIntervals());
  }, [getInvalidIntervals]);

  return {
    discardChanges,
    saveChanges,
    isValid,
    invalidIntervals,
    isSavingError,
    setIsSavingError,
    hasUnsavedChanges,
    isDiscardedChanges,
    getCostValue,
    getCpmValue,
    updateCostValue,
    getPersistableCosts,
    intervals,
    isLoading,
    setIsDiscardedChanges,
    checkIsIntervalEstimated,
    getMutatedCosts,
    checkIsCpmEstimated,
  };
};

export default useChanges;
