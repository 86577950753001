import React, { useContext, useState } from 'react';
import Columns from './Columns';
import BoardContext from '../../../../contexts/BoardContext';
import { DropResult } from 'react-beautiful-dnd';
import updateBoard from '../../../../api/boards/updateBoard';
import ModalDialog, { ModalTransition } from '@atlaskit/modal-dialog';
import getBaseViewFields from '../../../Grid/getBaseViewFields';
import BaseViewsContext from '../../../../contexts/BaseViewsContext';
import {
  CONTRACT_ON_TARGET_CELL_FIELD,
  CONTRACT_PROGRESS_CELL_FIELD,
  ON_TARGET_CELL_FIELD,
  PROGRESS_CELL_FIELD,
} from '../../../Grid/gridConstants';
import isDefined from '../../../../isDefined';
import isCustomerLaneBoard from '../../../../isCustomerLaneBoard';
import { getSpecialCellField } from '../../../../hooks/useColumnDefs';
import AccountPickerContext from '../../../../contexts/AccountPickerContext';
import isPerformanceBoard from '../../../../isPerformanceBoard';

// TODO: We need to handle performance boards here.
// The special cells were previously configured.
// They should now be derived.
// Possibly performance boards should have their own file for this feature?

const ColumnsContainer = ({
  baseView,
  close,
}: {
  baseView: FleetOps.BaseView;
  close: () => void;
}) => {
  const { accountRef } = useContext(AccountPickerContext);
  const { board } = useContext(BoardContext);
  const [isSizedToFitOverridden, setIsSizedToFitOverridden] = useState<
    boolean | undefined
  >(isPerformanceBoard(board) ? undefined : board.isSizedToFitOverridden);
  if (isPerformanceBoard(board)) {
    return null;
  }
  const { columnOrder } = board;
  const newColumns = getBaseViewFields(baseView).filter(
    (c) => !columnOrder.some((cO) => cO.field === c.field),
  );

  const extraColumns = isCustomerLaneBoard(board)
    ? [
        PROGRESS_CELL_FIELD,
        CONTRACT_PROGRESS_CELL_FIELD,
        ON_TARGET_CELL_FIELD,
        CONTRACT_ON_TARGET_CELL_FIELD,
      ]
    : isPerformanceBoard(board)
      ? window.emptyArray
      : board.specialCells
        ? board.specialCells.map(getSpecialCellField)
        : window.emptyArray;

  const netColumnOrder = [
    ...columnOrder.filter(
      (cO) =>
        getBaseViewFields(baseView).some((c) => c.field === cO.field) ||
        extraColumns.includes(cO.field),
    ),
    ...newColumns.map((c) => ({ field: c.field, visible: false })),
    ...extraColumns
      .map((special) => {
        if (columnOrder.some((o) => o.field === special)) {
          return undefined;
        } else {
          return {
            field: special,
            visible: false,
          };
        }
      })
      .filter(isDefined),
  ];

  const onDragEnd = (result: DropResult) => {
    const { draggableId, destination } = result;
    if (!destination) {
      return;
    }

    const itemsWithoutDropped = netColumnOrder.filter(
      (cId) => cId.field !== draggableId,
    );
    const theItem = netColumnOrder.find((c) => c.field === draggableId);
    if (!theItem) {
      throw new Error('Item not found');
    }

    const newOrder = [
      ...itemsWithoutDropped.slice(0, destination.index),
      { field: draggableId, visible: theItem.visible },
      ...itemsWithoutDropped.slice(
        destination.index,
        itemsWithoutDropped.length,
      ),
    ];

    const newBoard = {
      ...board,
      columnOrder: newOrder,
    };

    updateBoard(newBoard, accountRef);
  };

  const showColumn = (field: string) => {
    const newOrder = netColumnOrder.map((c) => {
      if (c.field === field) {
        return {
          field: c.field,
          visible: true,
        };
      } else {
        return c;
      }
    });
    const newBoard = {
      ...board,
      columnOrder: newOrder,
    };

    updateBoard(newBoard, accountRef);
  };

  const hideColumn = (field: string) => {
    const newOrder = netColumnOrder.map((c) => {
      if (c.field === field) {
        return {
          field: c.field,
          visible: false,
        };
      } else {
        return c;
      }
    });
    const newBoard = {
      ...board,
      columnOrder: newOrder,
    };

    updateBoard(newBoard, accountRef);
  };

  const onIsSizedToFitChange = (newValue: boolean) => {
    setIsSizedToFitOverridden(newValue);
    const newBoard = {
      ...board,
      isSizedToFitOverridden: newValue,
    };

    updateBoard(newBoard, accountRef);
  };

  return (
    <Columns
      onDragEnd={onDragEnd}
      columnOrder={netColumnOrder}
      showColumn={showColumn}
      hideColumn={hideColumn}
      close={close}
      isSizedToFitOverridden={isSizedToFitOverridden}
      onIsSizedToFitChange={onIsSizedToFitChange}
    />
  );
};

const Gate = ({
  close,
  isOpen,
  dataType,
}: {
  close: () => void;
  isOpen: boolean;
  dataType: string;
}) => {
  const { baseViews } = useContext(BaseViewsContext);
  const baseView = baseViews[dataType];

  if (baseView) {
    return (
      <ModalTransition>
        {isOpen && (
          <ModalDialog
            onClose={close}
            shouldScrollInViewport={false}
            autoFocus={false}
          >
            {isOpen && <ColumnsContainer baseView={baseView} close={close} />}
          </ModalDialog>
        )}
      </ModalTransition>
    );
  } else {
    return null;
  }
};

export default Gate;
