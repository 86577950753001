import React, { useCallback } from 'react';

import styled from 'styled-components';

import Typography from 'kingpin/atoms/Typography';
import Row from 'components/Common/Row';
import { ReactComponent as SeeMoreIcon } from '../../images/SeeMoreArrow.svg';
import EntityRow from '../EntityRow';
import EntityHeaderRow from '../EntityHeaderRow';

const WrapperDiv = styled.div`
  margin-bottom: 32px;
`;
const TitleDiv = styled.div`
  margin-bottom: 24px;
`;

const SeeMoreRow = styled(Row)`
  height: 70px;
`;

const EntityResults = ({
  result,
  onEntityNavigate,
  isShowingFirstFour,
  setSelectedEntity,
}: {
  result: GlobalSearch.Result;
  onEntityNavigate: (entity: EntityDetails.Entity) => void;
  isShowingFirstFour: boolean;
  setSelectedEntity: React.Dispatch<
    React.SetStateAction<EntityDetails.Entity | undefined>
  >;
}) => {
  const { matches, entity, searchText } = result;
  const itemsToRender = isShowingFirstFour ? matches.slice(0, 4) : matches;
  const isMore = isShowingFirstFour
    ? matches.length > itemsToRender.length
    : false;
  const hasItems = matches.length > 0;

  const onShowMoreClicked = useCallback(() => {
    setSelectedEntity(entity);
  }, [entity, setSelectedEntity]);

  if (!hasItems) {
    return null;
  }

  return (
    <WrapperDiv>
      <TitleDiv>
        <Typography.Header type="H5">{`${entity.name}s Found`}</Typography.Header>
      </TitleDiv>
      <EntityHeaderRow entity={entity} />
      {itemsToRender.map((match) => (
        <EntityRow
          key={match.primaryFieldValue}
          entity={entity}
          match={match}
          searchText={searchText}
          onEntityNavigate={onEntityNavigate}
        />
      ))}
      <SeeMoreRow centerAlign>
        {isMore && (
          <>
            <div style={{ marginRight: 10 }}>
              <Typography.Body
                type="Link"
                onClick={onShowMoreClicked}
              >{`See More ${entity.name}s`}</Typography.Body>
            </div>
            <SeeMoreIcon />
          </>
        )}
      </SeeMoreRow>
    </WrapperDiv>
  );
};

export default EntityResults;
