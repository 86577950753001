import React, { ChangeEvent } from 'react';
import Inputs from 'components/Inputs';
import Button from 'kingpin/atoms/Button';
import Typography from 'kingpin/atoms/Typography';
import TextInput from 'kingpin/atoms/TextInput';

const CustomItem = ({
  item,
  items,
  setItems,
}: {
  item: CustomDimensionInputItem;
  items: CustomDimensionInputItem[];
  setItems: (i: CustomDimensionInputItem[]) => void;
}) => {
  const { label, from, to } = item;
  const updateSelf = (newVersion: CustomDimensionInputItem) => {
    const newItems = items.map((i) => {
      if (i.id === newVersion.id) {
        return newVersion;
      }
      return i;
    });
    setItems(newItems);
  };
  const onLabelChanged = (event: ChangeEvent<HTMLInputElement>) => {
    updateSelf({ ...item, label: event.target.value });
  };
  const onFromChanged = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      updateSelf({ ...item, from: undefined });
    }
    updateSelf({ ...item, from: Number(event.target.value) });
  };
  const onToChanged = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      updateSelf({ ...item, to: undefined });
    }
    updateSelf({ ...item, to: Number(event.target.value) });
  };

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <div style={{ marginBottom: 4 }}>
          <Typography.Body type="Label">Label</Typography.Body>
        </div>
        <TextInput value={label} onChange={onLabelChanged} />
      </div>

      <div style={{ marginBottom: 16 }}>
        <div style={{ marginBottom: 4 }}>
          <Typography.Body type="Label">From</Typography.Body>
        </div>
        <TextInput
          value={from ? from.toString() : ''}
          onChange={onFromChanged}
          type="number"
        />
      </div>

      <div style={{ marginBottom: 16 }}>
        <div style={{ marginBottom: 4 }}>
          <Typography.Body type="Label">To</Typography.Body>
        </div>
        <TextInput
          value={to ? to.toString() : ''}
          onChange={onToChanged}
          type="number"
        />
      </div>
    </div>
  );
};

const DimensionRangeInput = ({
  mode,
  modeOptions,
  items,
  onAddItem,
  setItems,
  fixed,
  onFixedChanged,
}: {
  mode: string;
  modeOptions: RadioOption[];
  items: CustomDimensionInputItem[];
  onAddItem: () => void;
  setItems: (i: CustomDimensionInputItem[]) => void;
  fixed?: number;
  onFixedChanged: (event: ChangeEvent<HTMLInputElement>) => void;
}) => (
  <div style={{ marginBottom: 24 }}>
    <div style={{ marginBottom: 16 }}>
      <div style={{ marginBottom: 4 }}>
        <Typography.Body type="Label">Mode</Typography.Body>
      </div>
      <Inputs.Radio options={modeOptions} />
    </div>

    {mode === 'fixed' && (
      <div style={{ marginBottom: 16 }}>
        <div style={{ marginBottom: 4 }}>
          <Typography.Body type="Label">Fixed Interval</Typography.Body>
        </div>
        <TextInput
          value={fixed ? fixed.toString() : ''}
          onChange={onFixedChanged}
          type="number"
        />
      </div>
    )}
    {mode === 'custom' && (
      <div>
        {items.map((i) => (
          <CustomItem key={i.id} item={i} items={items} setItems={setItems} />
        ))}
        <Button
          onClick={onAddItem}
          label="Add Range"
          type="Primary"
          size="Small"
        />
      </div>
    )}
  </div>
);

export default DimensionRangeInput;
