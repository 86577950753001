import { useCallback, useContext } from 'react';
import _ from 'lodash';
import DatasetDefinitionsContext from '../../contexts/DatasetDefinitionsContext';

const useGetPerformanceUsage = () => {
  const { performanceConfigs } = useContext(DatasetDefinitionsContext);

  const getPerformanceUsage = useCallback(
    (
      metric: Metrics.NormalMetric | Metrics.CompoundMetric,
    ): { name: string; id: string }[] => {
      const usage = [] as { name: string; id: string }[];
      performanceConfigs.forEach((c) => {
        if (c.metrics.some((m) => m.metricId === metric.id)) {
          usage.push({
            name: c.name,
            id: c.id,
          });
        }
      });

      return _.uniqBy(usage, 'id');
    },
    [performanceConfigs],
  );

  return getPerformanceUsage;
};

export default useGetPerformanceUsage;
