const VIEW_BILLING: FleetOps.Permission =
  'fleetops.permissions.billing_view_billing';
const EDIT_BILLING: FleetOps.Permission =
  'fleetops.permissions.billing_modify_billing';
const MODIFY_BILLING_CUSTOMER: FleetOps.Permission =
  'fleetops.permissions.billing_modify_billing_customer';

const BILLING_PERMISSIONS = {
  VIEW_BILLING,
  EDIT_BILLING,
  MODIFY_BILLING_CUSTOMER,
};
Object.freeze(BILLING_PERMISSIONS);

export default BILLING_PERMISSIONS;
