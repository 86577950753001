import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';

import useTargets from './hooks/useTargets';
import TargetManager from './TargetManager';
import TargetListContext from './contexts/TargetListContext';
import useTargetListCallbacks from './hooks/useTargetListCallbacks';

const useSelectDefaultTarget = ({
  selectedField,
  setTargetsForField,
  targets,
  copyingFromTarget,
  setSelectedTarget,
}: {
  selectedField?: string;
  setTargetsForField: React.Dispatch<
    React.SetStateAction<Targets.Wizard.DataTypeTarget[]>
  >;
  targets: Targets.Wizard.DataTypeTarget[];
  copyingFromTarget?: Targets.Wizard.DataTypeTarget;
  setSelectedTarget: React.Dispatch<
    React.SetStateAction<Targets.Wizard.DataTypeTarget | undefined>
  >;
}) => {
  const getPastTarget = useCallback(() => {
    const today = moment().format('YYYY-MM-DD');
    const pastTargets = targets
      .filter((t) => t.target === selectedField)
      .filter((t) => t.effectiveDate <= today);
    if (pastTargets.length === 0) {
      return undefined;
    }

    return pastTargets[0];
  }, [selectedField, targets]);

  useEffect(() => {
    if (selectedField === undefined) {
      setTargetsForField([]);
      return;
    }

    const newTargetsForField = targets.filter(
      (t) => t.target === selectedField,
    );
    setTargetsForField(newTargetsForField);

    // Select current ongoing target
    if (copyingFromTarget === undefined) {
      setSelectedTarget((previouslySelected) => {
        const updatedVersionOfPreviouslySelected =
          previouslySelected && previouslySelected.target === selectedField
            ? targets.find((t) => t.id === previouslySelected.id)
            : undefined;
        if (updatedVersionOfPreviouslySelected) {
          return updatedVersionOfPreviouslySelected;
        }
        return getPastTarget();
      });
    }
  }, [
    copyingFromTarget,
    getPastTarget,
    selectedField,
    setSelectedTarget,
    setTargetsForField,
    targets,
  ]);
};

const TargetManagerContainer = ({
  dataset,
  close,
  hasUnsavedChanges,
  setHasUnsavedChanges,
  afterTargetCreatedOrUpdated,
}: {
  dataset: string;
  close?: () => void;
  hasUnsavedChanges: boolean;
  setHasUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  afterTargetCreatedOrUpdated?: () => void;
}) => {
  const { targets, refreshTargets } = useTargets(dataset);
  const [selectedField, setSelectedField] = useState<string | undefined>();
  const [targetsForField, setTargetsForField] = useState<
    Targets.Wizard.DataTypeTarget[]
  >([]);
  const [selectedTarget, setSelectedTarget] = useState<
    Targets.Wizard.DataTypeTarget | undefined
  >();
  const [copyingFromTarget, setCopyingFromTarget] = useState<
    Targets.Wizard.DataTypeTarget | undefined
  >();
  const [isAddTargetPrimary, setIsAddTargetPrimary] = useState<boolean>(true);
  const {
    onFieldSelected,
    onAddTargetClicked,
    onCopyRulesToField,
    onDuplicateRules,
    onTargetSelected,
  } = useTargetListCallbacks({
    selectedField,
    setSelectedField,
    setCopyingFromTarget,
    setSelectedTarget,
  });
  useSelectDefaultTarget({
    selectedField,
    setTargetsForField,
    targets,
    copyingFromTarget,
    setSelectedTarget,
  });

  const onTargetCreated = useCallback(() => {
    setCopyingFromTarget(undefined);
    if (afterTargetCreatedOrUpdated) {
      afterTargetCreatedOrUpdated();
    }
  }, [afterTargetCreatedOrUpdated]);

  const onTargetUpdated = useCallback(() => {
    if (afterTargetCreatedOrUpdated) {
      afterTargetCreatedOrUpdated();
    }
  }, [afterTargetCreatedOrUpdated]);

  return (
    <TargetListContext.Provider
      value={{
        dataset,
        selectedField,
        onCopyRulesToField,
        onTargetSelected,
        onDuplicateRules,
        refreshTargets,
        targetsForField,
        targets,
        onTargetCreated,
        onTargetUpdated,
        isAddTargetPrimary,
        setIsAddTargetPrimary,
        hasUnsavedChanges,
        setHasUnsavedChanges,
      }}
    >
      <TargetManager
        close={close}
        dataset={dataset}
        selectedField={selectedField}
        onFieldSelected={onFieldSelected}
        selectedTarget={selectedTarget}
        onAddTargetClicked={onAddTargetClicked}
        copyingFromTarget={copyingFromTarget}
      />
    </TargetListContext.Provider>
  );
};

export default TargetManagerContainer;
