import { useContext, useEffect, useState } from 'react';
import useGetDatasetLabel from 'components/Inputs/MetricPicker/MetricPickerPopup/hooks/useGetDatasetLabel';
import SelectedEntityContext from 'components/PerformanceBoards/contexts/SelectedEntityContext';
import DatasetDefinitionsContext from 'contexts/DatasetDefinitionsContext';

const useDataOptions = ({
  dataset,
  setDataset,
  setFields,
}: {
  dataset: string | undefined;
  setDataset: React.Dispatch<React.SetStateAction<string | undefined>>;

  setFields: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const { entityField } = useContext(SelectedEntityContext);
  const { datasets } = useContext(DatasetDefinitionsContext);
  const getDatasetLabel = useGetDatasetLabel();

  const [options, setOptions] = useState<DropdownOption[]>([]);
  useEffect(() => {
    const allowedEntityDatasets = datasets
      .filter((dataset) =>
        dataset.fields.some((field) => field.field === entityField),
      )
      .map((d) => d.type);

    const newOptions = allowedEntityDatasets.map((d) => ({
      label: getDatasetLabel(d),
      value: getDatasetLabel(d),
      isSelected: dataset === d,
      onSelected: () => {
        setDataset(d);
        setFields([]);
      },
    }));

    setOptions(newOptions);
  }, [dataset, datasets, entityField, getDatasetLabel, setDataset, setFields]);

  return options;
};

export default useDataOptions;
