import styled from 'styled-components';

const DatePickerIconFix = styled.div`
  svg {
    fill: white;
  }

  div > div > div:first-of-type {
    &:hover {
      background-color: white !important;
    }
  }
`;

export default DatePickerIconFix;
