import firebase from 'firebase/compat/app';
import STORE_CONSTANTS from '../constants';

const SHARED_COLLECTION = 'shared_content';
const CURRENT_VERSION = 'v1';

const converter = {
  toFirestore(
    sharedContent: PersistedSharedContent,
  ): firebase.firestore.DocumentData {
    return sharedContent;
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot<PersistedSharedContent>,
    options: firebase.firestore.SnapshotOptions,
  ): PersistedSharedContent {
    const data = {
      ...snapshot.data(options),
    };

    data.scorecardIds = data.scorecardIds || [];
    return data;
  },
};

const getSharedContentRef = ({
  accountId,
  userId,
  db,
}: {
  accountId: string;
  userId: string;
  db?: firebase.firestore.Firestore;
}) => {
  return (db ? db : firebase.firestore())
    .collection('accounts')
    .doc(accountId)
    .collection(STORE_CONSTANTS.USERS_COLLECTION)
    .doc(userId)
    .collection(SHARED_COLLECTION)
    .doc(CURRENT_VERSION)
    .withConverter(converter);
};

export default getSharedContentRef;
