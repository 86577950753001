import MetricPreview from '../MetricPreview';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import EyeSrc from './images/Eye.svg';
import TimeSrc from './images/Time.svg';
import UsageTab from '../MetricSlideOut/UsageTab';
import useMetricUsageReport from '../../../../hooks/useMetricUsageReport';
import Timeline from '../../../../components/Timeline';
import Accordion from './Accordion';
import Card from '../../../../components/Common/Card';
import AnalyticsContext from '../../../../contexts/AnalyticsContext';

type ExpandableItem = 'Usage' | 'Timeline' | undefined;

const Preview = ({
  metricDraft,
}: {
  metricDraft?: Metrics.NormalMetric | Metrics.CompoundMetric;
}) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const usageReport = useMetricUsageReport(metricDraft);
  const [expandedItem, setExpandedItem] = useState<ExpandableItem>();
  const toggleUsage = useCallback(() => {
    setExpandedItem((current) => {
      if (current === 'Usage') {
        return undefined;
      }

      return 'Usage';
    });
  }, []);
  const toggleTimeline = useCallback(() => {
    setExpandedItem((current) => {
      if (current === 'Timeline') {
        return undefined;
      }

      return 'Timeline';
    });
  }, []);
  useEffect(() => {
    if (!metricDraft) {
      return;
    }

    if (expandedItem === 'Usage') {
      trackEvent('Metric Builder - Usage opened', {
        metricType: metricDraft.type,
      });
    } else if (expandedItem === 'Timeline') {
      trackEvent('Metric Builder - Timeline opened', {
        metricType: metricDraft.type,
      });
    }
  }, [expandedItem, metricDraft, trackEvent]);

  return (
    <div>
      {!!metricDraft && (
        <div style={{ marginBottom: 24 }}>
          <MetricPreview metric={metricDraft} />
        </div>
      )}
      <Card style={{ paddingLeft: 16, paddingRight: 16 }}>
        {metricDraft && usageReport && (
          <Accordion
            isDisabled={usageReport.usageCount === 0}
            isOpen={expandedItem === 'Usage'}
            onToggled={toggleUsage}
            text={`Usage (${usageReport.usageCount})`}
            iconSrc={EyeSrc}
          >
            <UsageTab metric={metricDraft} />
          </Accordion>
        )}
        <Accordion
          isOpen={expandedItem === 'Timeline'}
          onToggled={toggleTimeline}
          text="Timeline"
          iconSrc={TimeSrc}
          noBorder
        >
          <Timeline isFullHeight />
        </Accordion>
      </Card>
    </div>
  );
};

export default Preview;
