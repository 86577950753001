import { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import STORE from 'store';
import getIdentifier from 'getIdentifier';
import appRoutes, { buildGadgetShow } from 'navigation/appRoutes';
import AccountPickerContext from 'contexts/AccountPickerContext';
import { PaceMatrixFormTypes } from './types';
import { PortalsContext } from '../../../contextProviders/PortalsProvider';

const useFormActions = ({
  formState,
  editingPaceGadget,
}: {
  formState: PaceMatrixFormTypes.FormState;
  editingPaceGadget?: VisualisationDefinitions.PaceMatrix;
}) => {
  const navigate = useNavigate();
  const { selectedPortal } = useContext(PortalsContext);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSave = useCallback(() => {
    if (!formState.draftGadget) {
      return;
    }

    const newGadget = formState.draftGadget;
    setIsLoading(true);
    STORE.visualisations
      .getPaceMatricesRef({
        accountId: selectedAccountId,
      })
      .doc(newGadget.id)
      .set(newGadget)
      .then(() => {
        navigate(buildGadgetShow(newGadget.id, selectedPortal));
        setIsLoading(false);
      });
    setIsLoading(true);
  }, [formState.draftGadget, navigate, selectedAccountId, selectedPortal]);

  const onSaveAs = useCallback(() => {
    if (!formState.draftGadget) {
      return;
    }

    const newId = getIdentifier();
    const newGadget = {
      ...formState.draftGadget,
      id: newId,
    };

    setIsLoading(true);
    STORE.visualisations
      .getPaceMatricesRef({
        accountId: selectedAccountId,
      })
      .doc(newGadget.id)
      .set(newGadget)
      .then(() => {
        navigate(buildGadgetShow(newId, selectedPortal));
        setIsLoading(false);
      });
    setIsLoading(true);
  }, [formState.draftGadget, navigate, selectedAccountId, selectedPortal]);

  const onDelete = useCallback(() => {
    if (!editingPaceGadget) {
      return;
    }
    setIsLoading(true);
    STORE.visualisations
      .getPaceMatricesRef({ accountId: selectedAccountId })
      .doc(editingPaceGadget.id)
      .update({
        isHidden: true,
        hiddenOn: new Date().toISOString(),
      })
      .then(() => {
        navigate(appRoutes.loggedIn.gadgetBuilderNew);
        setIsLoading(false);
      });
  }, [editingPaceGadget, navigate, selectedAccountId]);

  return {
    onSave,
    onSaveAs,
    onDelete,
    isLoading,
  };
};

export default useFormActions;
