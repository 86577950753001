import { useCallback, useContext } from 'react';
import BaseViewsContext from '../../../../contexts/BaseViewsContext';

const useUpdateView = ({
  field,
  baseView,
}: {
  field: FleetOps.Field;
  baseView: FleetOps.BaseView;
}) => {
  const { updateBaseView } = useContext(BaseViewsContext);

  const updateView = useCallback(
    (newView: FleetOps.BaseViewField) => {
      const newFields = {
        ...baseView.fields,
        [field.field]: newView,
      };
      const newBaseView = {
        ...baseView,
        fields: newFields,
      };
      updateBaseView(newBaseView);
    },
    [baseView, field.field, updateBaseView],
  );

  return updateView;
};

export default useUpdateView;
