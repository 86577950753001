import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import aguid from 'aguid';

import V5GadgetForm from './V5GadgetForm';
import V5GadgetFormContext from '../../contexts/V5GadgetFormContext';
import ComparisonContext from '../../contexts/ComparisonContext';
import { buildV5GadgetShow } from '../../navigation/appRoutes';
import useSelectedGadget from '../../hooks/useSelectedGadget';
import useDataTypesFromSeriesAndMetricListItems from '../../hooks/useDataTypesFromSeriesAndMetricListItems';
import isSingleMetricDateMatrix from '../../types/visTypeCheckers/isSingleMetricDateMatrix';
import _ from 'lodash';
import withoutNulls from '../../api/search/withoutNulls';
import AnalyticsContext from '../../contexts/AnalyticsContext';
import isGauge from '../../types/visTypeCheckers/isGauge';
import withDateFilter from '../../hocs/withDateFIlter';
import isRemindersGadget from '../../types/visTypeCheckers/isRemindersGadget';
import DateInputContext from '../../contexts/DateInputContext';
import AccountPickerContext from '../../contexts/AccountPickerContext';
import isRankingMatrix from '../../types/visTypeCheckers/isRankingMatrix';
import STORE from '../../store';
import useCopyVisHelpers from '../../hooks/useCopyVisHelpers';
import visTypeCheckers from '../../types/visTypeCheckers';
import LocalTimelineProvider from '../../contextProviders/TimelineProvider/LocalTimelineProvider';
import { PortalsContext } from '../../contextProviders/PortalsProvider';

const dimensionCharts = [
  'bar',
  'horizontalBar',
  'pie',
  'timeSeries',
  'treeMap',
  'combo',
  'matrix',
  'stackedBar',
  'stackedArea',
] as GadgetType[];
const multiDimensionCharts = [
  'timeSeries',
  'matrix',
  'stackedBar',
  'stackedArea',
];
const multipleSeriesCharts = [
  'bar',
  'horizontalBar',
  'timeSeries',
  'combo',
  'matrix',
] as GadgetType[];

const multipleYAxisCharts = ['combo'];

const defaultStackedBarOptions = {
  direction: 'horizontal' as StackedBarDirection,
  asPercentage: false,
  altPivot: false,
};

const defaultStackedAreaOptions = {
  altPivot: false,
};

const V5GadgetFormContainer = ({
  selected,
  defaultType,
  isGadgetBuilder,
  onChange,
}: {
  selected?: V5ChartDefinition;
  defaultType?: GadgetType;
  isGadgetBuilder: boolean;
  onChange?: (newDef: V5ChartDefinition) => void;
}) => {
  const { selectedPortal } = useContext(PortalsContext);
  const navigate = useNavigate();
  const { generateNewVisDef } = useCopyVisHelpers();
  const { selectedAccountId } = useContext(AccountPickerContext);
  const { setDataTypes } = useContext(DateInputContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [id] = useState<string>(() => (selected ? selected.id : aguid()));
  const [isEditing, setIsEditing] = useState<boolean>(!!selected);
  const [name, setName] = useState<string>(selected ? selected.name : '');
  const [description, setDescription] = useState<string>(
    selected && selected.description ? selected.description : '',
  );
  const [gadgetType, setGadgetType] = useState<GadgetType>(
    selected ? selected.gadgetType : defaultType ? defaultType : 'bar',
  );
  const [series, setSeries] = useState<V5ChartDefinitionSeries[]>(
    selected ? selected.series : [],
  );
  const [dimensionA, setDimensionA] = useState<Dimension | undefined>(
    selected ? selected.dimensionA : undefined,
  );
  const [dimensionB, setDimensionB] = useState<Dimension | undefined>(
    selected ? selected.dimensionB : undefined,
  );
  const [groupBySortBy, setGroupBySortBy] = useState<SortBy | undefined>(
    selected ? selected.groupBySortBy || 'desc' : undefined,
  );
  const [groupSortByOptions, setGroupSortByOptions] = useState<RadioOption[]>(
    [],
  );
  useEffect(() => {
    setGroupSortByOptions([
      {
        label: 'Disabled',
        isSelected: groupBySortBy === undefined,
        onSelected: () => {
          setGroupBySortBy(undefined);
        },
        key: 'disabled',
      },
      {
        label: 'Asc',
        isSelected: groupBySortBy === 'asc',
        onSelected: () => {
          setGroupBySortBy('asc');
        },
        key: 'asc',
      },
      {
        label: 'Desc',
        isSelected: groupBySortBy === 'desc',
        onSelected: () => {
          setGroupBySortBy('desc');
        },
        key: 'desc',
      },
    ]);
  }, [groupBySortBy]);
  const [groupByLimit, setGroupByLimit] = useState<number | undefined>(
    selected ? selected.groupByLimit : undefined,
  );
  const [groupByLimitMode, setGroupByLimitMode] = useState<
    'top n' | 'show all' | undefined
  >(selected ? selected.groupByLimitMode : undefined);
  const [trendingMode, setTrendingMode] = useState<
    'calendarInterval' | 'fixedInterval'
  >(
    selected
      ? selected.trendByFixedIntervalDays
        ? 'fixedInterval'
        : 'calendarInterval'
      : 'calendarInterval',
  );
  const [trendingModeOptions, setTrendingModeOptions] = useState<RadioOption[]>(
    [],
  );
  useEffect(() => {
    setTrendingModeOptions([
      {
        key: 'c',
        label: 'Calendar Interval',
        isSelected: trendingMode === 'calendarInterval',
        onSelected: () => setTrendingMode('calendarInterval'),
      },
      {
        key: 'f',
        label: 'Fixed Interval',
        isSelected: trendingMode === 'fixedInterval',
        onSelected: () => setTrendingMode('fixedInterval'),
      },
    ]);
  }, [trendingMode]);

  const [trendByCalendarInterval, setTrendByCalendarInterval] = useState<
    FleetOps.Interval | 'auto' | undefined
  >(selected ? selected.trendByCalendarInterval : undefined);
  const [excludePartialIntervals, setExcludePartialIntervals] = useState(
    selected ? selected.excludePartialIntervals : false,
  );
  const [trendByFixedIntervalDays, setTrendByFixedIntervalDays] = useState<
    number | undefined
  >(selected ? selected.trendByFixedIntervalDays : undefined);
  const [stackedBarOptions, setStackedBarOptions] = useState<
    StackedBarOptions | undefined
  >(
    selected && selected.stackedBarOptions
      ? selected.stackedBarOptions
      : defaultType === 'stackedBar'
        ? defaultStackedBarOptions
        : undefined,
  );
  const [stackedAreaOptions, setStackedAreaOptions] = useState<
    StackedAreaOptions | undefined
  >(
    selected && selected.stackedAreaOptions
      ? selected.stackedAreaOptions
      : defaultType === 'stackedArea'
        ? defaultStackedAreaOptions
        : undefined,
  );
  const [gridConfig, setGridConfig] = useState<SimpleGridConfig | undefined>(
    selected && selected.simpleGridConfig
      ? selected.simpleGridConfig.fieldAliases
        ? selected.simpleGridConfig
        : {
            ...selected.simpleGridConfig,
            fieldAliases: selected.simpleGridConfig.fields.map((f) => ({
              field: f,
              alias: _.startCase(f),
            })),
          }
      : defaultType === 'simpleGrid'
        ? {
            dataType: '',
            fields: [],
            fieldAliases: [],
            sort: [],
          }
        : undefined,
  );
  const [showDataLabels, setShowDataLabels] = useState<boolean | undefined>(
    selected ? selected.showDataLabels : false,
  );
  const [isSizedToFitOverridden, setIsSizedToFitOverridden] = useState<
    boolean | undefined
  >(selected ? selected.isSizedToFitOverridden : false);
  const [draftChart, setDraftChart] = useState<V5ChartDefinition | undefined>();
  const dataTypesFromSeries = useDataTypesFromSeriesAndMetricListItems(series);

  useEffect(() => {
    setDataTypes(dataTypesFromSeries);
  }, [dataTypesFromSeries, setDataTypes]);

  const isDimensionalChart = dimensionCharts.includes(gadgetType);
  const isMultipleDimensionChart =
    multiDimensionCharts.includes(gadgetType) && series.length < 2;
  const isMultipleSeriesChart =
    multipleSeriesCharts.includes(gadgetType) && dimensionB === undefined;
  const isMultipleYAxisChart = multipleYAxisCharts.includes(gadgetType);

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const onDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const onGadgetTypeChange = (newType: GadgetType) => {
    if (
      newType !== 'bar' &&
      newType !== 'horizontalBar' &&
      newType !== 'combo' &&
      newType !== 'treeMap'
    ) {
      setShowDataLabels(undefined);
    }
    if (newType === 'stackedBar') {
      setStackedBarOptions(defaultStackedBarOptions);
    } else {
      setStackedBarOptions(undefined);
    }

    if (newType === 'stackedArea') {
      setStackedAreaOptions(defaultStackedAreaOptions);
    } else {
      setStackedAreaOptions(undefined);
    }

    if (newType === 'simpleGrid') {
      setGridConfig({
        dataType: '',
        fields: [],
        sort: [],
        fieldAliases: [],
      });
      setSeries([]);
    } else {
      setGridConfig(undefined);
    }

    trackEvent('Gadget Builder - Type selected', {
      gadgetType: newType,
    });
    setGadgetType(newType);
  };

  const onGroupByLimitChange = (event: ChangeEvent<HTMLInputElement>) => {
    setGroupByLimit(Number(event.target.value));
  };

  const onTrendByCalendarIntervalChange = (
    newValue: FleetOps.Interval | 'auto',
  ) => {
    setTrendByCalendarInterval(newValue);
  };

  const onExcludePartialIntervalsChange = (newValue: boolean) => {
    setExcludePartialIntervals(newValue);
  };

  const onIsSizedToFitChange = (newValue: boolean) => {
    setIsSizedToFitOverridden(newValue);
  };

  const onTrendByFixedIntervalChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setTrendByFixedIntervalDays(Number(event.target.value));
  };

  const isShowingGroupingInputs = (() => {
    if (dimensionA) {
      if (
        dimensionA.fieldType === 'text' ||
        dimensionA.fieldType === 'boolean'
      ) {
        return true;
      }
    }

    if (dimensionB) {
      if (
        dimensionB.fieldType === 'text' ||
        dimensionB.fieldType === 'boolean'
      ) {
        return true;
      }
    }

    return false;
  })();

  const isShowingTrendingInputs = (() => {
    if (dimensionA) {
      if (dimensionA.fieldType === 'date' && dimensionA.field !== 'dayOfWeek') {
        return true;
      }
    }

    if (dimensionB) {
      if (dimensionB.fieldType === 'date' && dimensionB.field !== 'dayOfWeek') {
        return true;
      }
    }

    return false;
  })();

  const isGroupingByDayOfWeek = (() => {
    if (dimensionA) {
      if (dimensionA.field === 'dayOfWeek') {
        return true;
      }
    }

    if (dimensionB) {
      if (dimensionB.field === 'dayOfWeek') {
        return true;
      }
    }
  })();

  useEffect(() => {
    if (trendingMode === 'calendarInterval') {
      setTrendByFixedIntervalDays(undefined);
    }

    if (trendingMode === 'fixedInterval') {
      setTrendByCalendarInterval(undefined);
    }
  }, [trendingMode]);

  useEffect(() => {
    if (gadgetType === 'timeSeries') {
      setDimensionA({
        field: 'date',
        fieldType: 'date',
        isGroupByDayOfWeek: false,
      });
    }
  }, [gadgetType]);

  useEffect(() => {
    if (!isShowingTrendingInputs) {
      setTrendByFixedIntervalDays(undefined);
      setTrendByCalendarInterval(undefined);
    } else {
      if (!trendByCalendarInterval) {
        setTrendByCalendarInterval('auto');
      }
    }
  }, [isShowingTrendingInputs, trendByCalendarInterval]);

  useEffect(() => {
    if (!isMultipleSeriesChart && series.length > 1) {
      setSeries([series[0]]);
    }

    if (!isDimensionalChart) {
      setDimensionA(undefined);
      setDimensionB(undefined);
    }
  }, [series, isMultipleSeriesChart, isDimensionalChart]);

  useEffect(() => {
    setIsEditing(!!selected);
  }, [selected]);

  useEffect(() => {
    if (
      !isMultipleYAxisChart &&
      (series.some((s) => s.opposite) ||
        series.some((s) => s.type) ||
        series.some((s) => s.plotOnSeparateAxis))
    ) {
      setSeries(
        series.map((s) => ({
          id: s.id,
          metricId: s.metricId,
          smartCardComparison: 'delta',
        })),
      );
    }
  }, [series, isMultipleYAxisChart]);

  useEffect(() => {
    if (series.length === 0 && gridConfig === undefined) {
      setDraftChart(undefined);
      return;
    }
    const newDraftChart = {
      id,
      description,
      name,
      gadgetType,
      series,
      groupBySortBy,
      groupByLimit,
      groupByLimitMode,
      trendByCalendarInterval,
      trendByFixedIntervalDays,
      excludePartialIntervals,
      groupByDayOfWeek: isGroupingByDayOfWeek,
      dimensionA,
      dimensionB,
      stackedBarOptions,
      stackedAreaOptions,
      simpleGridConfig: gridConfig,
      showDataLabels,
      isSizedToFitOverridden,
    };

    setDraftChart(newDraftChart);
  }, [
    description,
    dimensionA,
    dimensionB,
    excludePartialIntervals,
    gadgetType,
    gridConfig,
    groupByLimit,
    groupByLimitMode,
    groupBySortBy,
    id,
    isGroupingByDayOfWeek,
    isSizedToFitOverridden,
    name,
    series,
    showDataLabels,
    stackedAreaOptions,
    stackedBarOptions,
    trendByCalendarInterval,
    trendByFixedIntervalDays,
  ]);

  useEffect(() => {
    if (!!draftChart && !!onChange) {
      onChange(draftChart);
    }
  }, [draftChart, onChange]);

  const onSave = useCallback(() => {
    if (!draftChart || isLoading) {
      return;
    }
    setIsLoading(true);

    STORE.visualisations
      .getChartDefsRef({ accountId: selectedAccountId })
      .doc(draftChart.id)
      .set(draftChart)
      .then(() => {
        setIsLoading(false);
        const event = (
          selected ? 'Gadget Builder - Updated' : 'Gadget  Builder - Created'
        ) as Analytics.EventType;
        trackEvent(event, {
          gadgetName: draftChart.name,
          gadgetId: draftChart.id,
        });
        navigate(buildV5GadgetShow(draftChart.id, selectedPortal));
      });
  }, [
    selectedPortal,
    draftChart,
    navigate,
    isLoading,
    selected,
    selectedAccountId,
    trackEvent,
  ]);

  const onSaveAs = useCallback(async () => {
    if (!draftChart || isLoading) {
      return;
    }
    setIsLoading(true);

    const newChart = await generateNewVisDef({
      ...withoutNulls(draftChart),
      id: aguid(),
    });

    if (!visTypeCheckers.isV5ChartDef(newChart)) {
      const e = new Error();
      e.name = 'onSaveAs: Unexpected chart def';
      throw e;
    }

    await STORE.visualisations
      .getChartDefsRef({ accountId: selectedAccountId })
      .doc(newChart.id)
      .set(newChart)
      .then(() => {});
    setIsLoading(false);
    trackEvent('Gadget Builder - Saved as', {
      gadgetName: draftChart.name,
      gadgetId: draftChart.id,
    });
    navigate(buildV5GadgetShow(newChart.id, selectedPortal));
  }, [
    draftChart,
    isLoading,
    generateNewVisDef,
    selectedAccountId,
    trackEvent,
    navigate,
    selectedPortal,
  ]);

  const onDelete = useCallback(() => {
    if (!draftChart) {
      return;
    }
    setIsLoading(true);
    STORE.visualisations
      .getChartDefsRef({ accountId: selectedAccountId })
      .doc(draftChart.id)
      .delete()
      .then(() => {
        trackEvent('Gadget Builder - Deleted', {
          gadgetName: draftChart.name,
          gadgetId: draftChart.id,
        });
        setIsLoading(false);
      });
  }, [draftChart, selectedAccountId, trackEvent]);

  const isValidChart = (() => {
    if (gadgetType === 'stackedArea' || gadgetType === 'stackedBar') {
      return !!dimensionA && !!dimensionB;
    }
    return true;
  })();

  return (
    <ComparisonContext.Provider
      value={{
        currentComparison: {
          compareType: 'previous',
        },
        setCurrentComparison: () => {},
      }}
    >
      <V5GadgetFormContext.Provider
        value={{
          isEditing,
          series,
          setSeries,
          dimensionA,
          setDimensionA,
          dimensionB,
          setDimensionB,
          stackedBarOptions,
          setStackedBarOptions,
          stackedAreaOptions,
          setStackedAreaOptions,
          isLoading,
          onSave,
          onSaveAs,
          onDelete,
          draftChart,
          isDimensionalChart,
          isMultipleDimensionChart,
          isMultipleSeriesChart,
          isMultipleYAxisChart,
          showDataLabels,
          setShowDataLabels,
          groupByLimitMode,
          setGroupByLimitMode,
          setGroupByLimit,
        }}
      >
        <V5GadgetForm
          name={name}
          onNameChange={onNameChange}
          description={description}
          onDescriptionChange={onDescriptionChange}
          gadgetType={gadgetType}
          onGadgetTypeChange={onGadgetTypeChange}
          isShowingGroupingInputs={isShowingGroupingInputs}
          groupSortByOptions={groupSortByOptions}
          groupByLimit={groupByLimit}
          onGroupByLimitChange={onGroupByLimitChange}
          isShowingTrendingInputs={isShowingTrendingInputs}
          trendingMode={trendingMode}
          trendingModeOptions={trendingModeOptions}
          trendByCalendarInterval={trendByCalendarInterval}
          onTrendByCalendarIntervalChange={onTrendByCalendarIntervalChange}
          excludePartialIntervals={!!excludePartialIntervals}
          onExcludePartialIntervalsChange={onExcludePartialIntervalsChange}
          trendByFixedIntervalDays={trendByFixedIntervalDays}
          onTrendByFixedIntervalChange={onTrendByFixedIntervalChange}
          draftChart={draftChart}
          isDimensionalChart={isDimensionalChart}
          isValidChart={isValidChart}
          gridConfig={gridConfig}
          setGridConfig={setGridConfig}
          isGadgetBuilder={isGadgetBuilder}
          isSizedToFitOverridden={isSizedToFitOverridden}
          onIsSizedToFitChange={onIsSizedToFitChange}
        />
      </V5GadgetFormContext.Provider>
    </ComparisonContext.Provider>
  );
};

const SelectedProvider = ({
  defaultType,
  isGadgetBuilder,
  dashboardGadgetChart,
  onChange,
}: {
  defaultType?: GadgetType;
  isGadgetBuilder: boolean;
  dashboardGadgetChart?: VisualisationDefinition;
  onChange?: (newDef: V5ChartDefinition) => void;
}) => {
  const { selected } = useSelectedGadget(dashboardGadgetChart);

  if (isSingleMetricDateMatrix(selected)) {
    return null;
  }
  if (isGauge(selected)) {
    return null;
  }
  if (isRemindersGadget(selected)) {
    return null;
  }
  if (isRankingMatrix(selected)) {
    return null;
  }

  return (
    <LocalTimelineProvider sources={window.emptyArray}>
      <V5GadgetFormContainer
        key={selected ? selected.id : undefined}
        selected={selected}
        defaultType={defaultType}
        isGadgetBuilder={isGadgetBuilder}
        onChange={onChange}
      />
    </LocalTimelineProvider>
  );
};

export default withDateFilter(SelectedProvider, {});
