import React, { useContext } from 'react';
import _ from 'lodash';
import DateFieldsContext from '../../../contexts/DateFieldsContext';
import DateInputContext from '../../../contexts/DateInputContext';
import Inputs from '../../Inputs';
import useGetDatasetLabel from '../../Inputs/MetricPicker/MetricPickerPopup/hooks/useGetDatasetLabel';
import Typography from 'kingpin/atoms/Typography';

export const buildDateFieldOptions = ({
  dateFields,
  dataTypes,
  dateField,
  setDateField,
  trackQueryDateSelected,
  getDatasetLabel,
}: {
  dateFields: DateField[];
  dataTypes: string[];
  dateField: string | undefined;
  setDateField:
    | ((newDateField: string) => void)
    | React.Dispatch<React.SetStateAction<string>>
    | React.Dispatch<React.SetStateAction<string | undefined>>;
  trackQueryDateSelected?: () => void;
  getDatasetLabel: (dataset: FleetOps.DatasetDefinition | string) => string;
}) => {
  const filteredDateFields = dateFields
    .sort((a, b) => {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    })
    .filter((df) => {
      if (dataTypes.length > 0) {
        return df.datasets.some((set) => dataTypes.includes(set));
      } else {
        return df;
      }
    });

  return filteredDateFields.map((d) => {
    const usedDataTypes = _.uniq(
      dataTypes.filter((dt) => d.datasets.includes(dt)),
    );

    if (usedDataTypes.length === 1) {
      return {
        label: d.name,
        isSelected: d.field === dateField,
        value: d.field,
        onSelected: () => {
          setDateField(d.field);
          if (trackQueryDateSelected) {
            trackQueryDateSelected();
          }
        },
      };
    } else {
      return {
        label: `(${usedDataTypes.map(getDatasetLabel).join(' | ')}).${d.name}`,
        value: d.field,
        isSelected: d.field === dateField,
        onSelected: () => {
          setDateField(d.field);
          if (trackQueryDateSelected) {
            trackQueryDateSelected();
          }
        },
      };
    }
  });
};

const DateFieldPickerContainer = ({
  trackQueryDateSelected,
}: {
  trackQueryDateSelected?: () => void;
}) => {
  const getDatasetLabel = useGetDatasetLabel();
  const { setDateField, dataTypes, dateField } = useContext(DateInputContext);

  const { dateFields } = useContext(DateFieldsContext);

  if (!setDateField) {
    return null;
  }

  const options = buildDateFieldOptions({
    dataTypes,
    dateFields,
    dateField,
    setDateField,
    trackQueryDateSelected,
    getDatasetLabel,
  });

  return (
    <div data-testid="date-field-picker">
      <div style={{ marginBottom: 4 }}>
        <Typography.Body type={'Label'}>Query Date</Typography.Body>
      </div>
      <Inputs.Dropdown placeholder={'Query date'} options={options} isShort />
    </div>
  );
};

export default DateFieldPickerContainer;
