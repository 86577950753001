import React, { useCallback, useContext, useEffect, useState } from 'react';
import Loading from '../components/Loading';
import STORE from '../store';
import AccountPickerContext from '../contexts/AccountPickerContext';
import CurrentUserContext from '../contexts/CurrentUserContext';
import useLockedDebouncedEffect from '../hooks/useLockedDebouncedEffect';
import ImpersonatorContext from '../contexts/ImpersonatorContext';

export const useContentSettings = ({
  userId,
  accountId,
}: {
  userId: string;
  accountId: string;
}) => {
  const [contentSettings, setContentSettings] = React.useState<
    UserManagement.ContentSettings | undefined
  >();
  const [args, setArgs] = useState<{ userId: string; accountId: string }>({
    userId,
    accountId,
  });
  useEffect(() => {
    setArgs({
      userId,
      accountId,
    });
  }, [accountId, userId]);

  const callback = useCallback(
    async ({
      userId,
      accountId,
    }: {
      userId: string;
      accountId: string;
    }): Promise<UserManagement.ContentSettings> => {
      setContentSettings(undefined);
      return STORE.users
        .getUserContentSettingsRef({ accountId, userId })
        .get()
        .then((doc) => {
          if (!doc.exists) {
            return {
              mode: 'normal',
            };
          }
          const newSettings = doc.data();
          if (!newSettings) {
            return {
              mode: 'normal',
            };
          }

          return newSettings;
        });
    },
    [],
  );

  const responseHandler = useCallback(
    (newSettings: UserManagement.ContentSettings) => {
      setContentSettings(newSettings);
    },
    [],
  );

  useLockedDebouncedEffect({
    args,
    callback,
    responseHandler,
  });

  return contentSettings;
};

export const CurrentUserContentSettingsContext = React.createContext<{
  contentSettings: UserManagement.ContentSettings;
}>({
  contentSettings: { mode: 'normal' },
});

const CurrentUserContentSettingsProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { selectedAccountId } = useContext(AccountPickerContext);
  const currentUser = useContext(CurrentUserContext);
  const { impersonatorId } = useContext(ImpersonatorContext);
  const userId = impersonatorId ? impersonatorId : currentUser.id;
  const contentSettings = useContentSettings({
    userId,
    accountId: selectedAccountId,
  });

  if (!contentSettings) {
    return <Loading isBlocking />;
  }

  return (
    <CurrentUserContentSettingsContext.Provider value={{ contentSettings }}>
      {children}
    </CurrentUserContentSettingsContext.Provider>
  );
};

export default CurrentUserContentSettingsProvider;
