import { useCallback, useContext, useMemo, useState } from 'react';
import AccountPickerContext from 'contexts/AccountPickerContext';
import usePopup from 'hooks/usePopup';
import STORE from 'store';

import RemoveUserAccessConfirmation from './RemoveUserAccessConfirmation';

const useRemoveUserAccessConfirmation = ({
  user,
  type,
  typeId,
  contentName,
  refreshSharedWith,
}: {
  user: UserManagement.PendingUser | UserManagement.SignedUpUser;
  type: 'report' | 'dashboard' | 'scorecard' | 'workSpace';
  typeId: string;
  contentName: string;
  refreshSharedWith: () => void;
}) => {
  const { selectedAccountId } = useContext(AccountPickerContext);
  const { isOpen, open, close } = usePopup();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onRemoveConfirmed = useCallback(async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    const sharedContentRef = STORE.users.getSharedContentRef({
      accountId: selectedAccountId,
      userId: user.id,
    });
    const sharedContentDoc = await sharedContentRef.get();
    const sharedContent = sharedContentDoc.data();
    if (!sharedContent) {
      return;
    }

    const newSharedContent: PersistedSharedContent = {
      ...sharedContent,
    };

    if (type === 'report') {
      newSharedContent.reportIds = newSharedContent.reportIds.filter(
        (rId) => rId !== typeId,
      );
    }
    if (type === 'dashboard') {
      newSharedContent.dashboardIds = newSharedContent.dashboardIds.filter(
        (dId) => dId !== typeId,
      );
    }
    if (type === 'scorecard') {
      newSharedContent.scorecardIds = newSharedContent.scorecardIds.filter(
        (dId) => dId !== typeId,
      );
    }
    if (type === 'workSpace') {
      newSharedContent.workspaceIds = newSharedContent.workspaceIds.filter(
        (dId) => dId !== typeId,
      );
    }

    await sharedContentRef.set(newSharedContent);
    setIsLoading(false);
    refreshSharedWith();
  }, [isLoading, refreshSharedWith, selectedAccountId, type, typeId, user.id]);

  const RemoveConfirmation = useMemo(
    () => (
      <RemoveUserAccessConfirmation
        isOpen={isOpen}
        close={close}
        user={user}
        onRemoveConfirmed={onRemoveConfirmed}
        isLoading={isLoading}
        type={type}
        typeId={typeId}
        contentName={contentName}
      />
    ),
    [
      close,
      contentName,
      isLoading,
      isOpen,
      onRemoveConfirmed,
      type,
      typeId,
      user,
    ],
  );

  return {
    RemoveConfirmation,
    onRemoveClicked: open,
  };
};

export default useRemoveUserAccessConfirmation;
