import React, { useEffect, useState } from 'react';
import Typography from 'kingpin/atoms/Typography';
import styled from 'styled-components';
import Row from '../../components/Common/Row';
import ErrorBanner, { AUTH_ERROR_MSG_KEY } from './ErrorBanner';
import Legal from './Legal';
import EmailPasswordLoginForm from './EmailPasswordLoginForm';
import MicrosoftLoginButton from './MicrosoftLoginButton';
import AuthLinks from './AuthLinks';
import { ReactComponent as Logo } from './Logo.svg';
import { ReactComponent as OrDivider } from './OrDivider.svg';
import MicrosoftTenantRequired from './MicrosoftTenantRequired';
import useTenantQueryParam from './useTenantQueryParam';

const Page = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 50px 0px;
  overflow-y: scroll;
`;

const Column = styled.div`
  width: 344px;
`;

const Section = styled.div`
  margin-bottom: 16px;
`;

const useEnsureIntercomIsOff = () => {
  useEffect(() => {
    // @ts-ignore
    if (window.Intercom) {
      // @ts-ignore
      window.Intercom('shutdown');
    }
  }, []);
};

const LoginScreen = () => {
  const tenant = useTenantQueryParam();
  useEnsureIntercomIsOff();
  const [isTenantRequired, setIsTenantRequired] = useState<
    | {
        isRequired: boolean;
        email: string;
      }
    | undefined
  >(undefined);

  if (isTenantRequired) {
    return (
      <MicrosoftTenantRequired
        email={isTenantRequired.email}
        setIsTenantRequired={setIsTenantRequired}
      />
    );
  }

  return (
    <Page>
      <ErrorBanner errorKey={AUTH_ERROR_MSG_KEY} />
      <Column>
        <Row style={{ marginBottom: 72, justifyContent: 'center' }}>
          <Logo />
        </Row>
        <Section>
          <Typography.Header type="H1">Log In</Typography.Header>
        </Section>
        {!tenant && (
          <>
            <Section>
              <EmailPasswordLoginForm />
            </Section>
            <Section>
              <OrDivider />
            </Section>
          </>
        )}
        <Section>
          <MicrosoftLoginButton
            setIsTenantRequired={setIsTenantRequired}
            tenant={tenant}
          />
        </Section>
        {!tenant && <AuthLinks />}
      </Column>
      <Column>
        <Legal />
      </Column>
    </Page>
  );
};

export default LoginScreen;
