import usePopup from '../../../../hooks/usePopup';
import { ModalTransition } from '@atlaskit/modal-dialog';

import React, { useCallback, useEffect, useState } from 'react';
import MetricPopup from '../../../../screens/DataManager/Metrics/MetricPopup';
import { UnsavedChangesConfirmation } from '../../../ConfirmationModal';
import SingleUseMetricPopupContext from '../../../../contexts/SingleUseMetricPopupContext';

const SingleUseMetricBuilderPopup = ({
  isOpen,
  close,
  onSingleUseMetricSaved,
  cancelButtonText,
}: {
  isOpen: boolean;
  close: () => void;
  onSingleUseMetricSaved: (metric: Metrics.Metric) => void;
  cancelButtonText: string;
}) => {
  const {
    isOpen: isConfirmOpen,
    open: openConfirm,
    close: closeConfirm,
  } = usePopup();

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const onPopupCloseConfirmed = useCallback(() => {
    closeConfirm();
    close();
  }, [close, closeConfirm]);

  const onPopupDismissed = useCallback(() => {
    if (!hasUnsavedChanges) {
      onPopupCloseConfirmed();
      return;
    }

    openConfirm();
  }, [hasUnsavedChanges, onPopupCloseConfirmed, openConfirm]);

  const [isSaveToDataManager, setIsSaveToDataManager] =
    useState<boolean>(false);

  useEffect(() => {
    setIsSaveToDataManager(false);
    setHasUnsavedChanges(false);
  }, [isOpen]);

  return (
    <SingleUseMetricPopupContext.Provider
      value={{
        isSingleUsePopup: true,
        onSingleUseMetricSaved,
        isSaveToDataManager,
        setIsSaveToDataManager,
        cancelButtonText,
      }}
    >
      <ModalTransition>
        {isOpen && (
          <MetricPopup
            mode="Metric"
            close={onPopupDismissed}
            hasUnsavedChanges={hasUnsavedChanges}
            setHasUnsavedChanges={setHasUnsavedChanges}
          />
        )}
      </ModalTransition>
      <UnsavedChangesConfirmation
        onConfirmed={onPopupCloseConfirmed}
        close={closeConfirm}
        isOpen={isConfirmOpen}
      />
    </SingleUseMetricPopupContext.Provider>
  );
};

export default SingleUseMetricBuilderPopup;
