import React, { useCallback, useContext, useState } from 'react';

import Notification from './Notification';
import moment from 'moment';
import NotificationsContext from '../../../contexts/NotificationsContext';
import { useNavigate } from 'react-router-dom';

const getTimeAgoText = (n: Notifications.BasicNotification) => {
  const createdOn = n.createdOn;
  return moment.utc(createdOn).fromNow();
};

const NotificationContainer = ({
  notification,
  selected,
}: {
  notification: Notifications.BasicNotification;
  selected: boolean;
}) => {
  // Context
  const { getNotificationRef, setSelectedNotificationId } =
    useContext(NotificationsContext);
  const navigate = useNavigate();

  // State
  const [hovering, setHovering] = useState(false);
  const [mouseOverClear, setMouseOverClear] = useState(false);
  const [mouseOverReadStatus, setMouseOverReadStatus] = useState(false);
  const [timeAgoText] = useState<string>(getTimeAgoText(notification));

  // Callbacks
  const markNotificationAsRead = useCallback(() => {
    const ref = getNotificationRef(notification.id);
    if (ref) {
      ref.update({ read: true });
    }
  }, [getNotificationRef, notification.id]);

  const markNotificationAsUnRead = useCallback(() => {
    const ref = getNotificationRef(notification.id);
    if (ref) {
      ref.update({ read: false });
    }
  }, [getNotificationRef, notification.id]);

  const clearNotification = useCallback(() => {
    const ref = getNotificationRef(notification.id);
    if (ref) {
      ref.update({ deleted: true });
    }
  }, [getNotificationRef, notification.id]);

  const selectNotification = useCallback(() => {
    setSelectedNotificationId(notification.id);
  }, [notification.id, setSelectedNotificationId]);

  const onLinkClicked = useCallback(() => {
    navigate(notification.link);
  }, [navigate, notification.link]);

  const onMouseInNotification = () => {
    setHovering(false);
  };

  const onMouseOutNotification = () => {
    setHovering(false);
  };

  const onMouseInReadStatus = () => {
    setMouseOverReadStatus(true);
    setMouseOverClear(false);
  };

  const onMouseOutReadStatus = () => {
    setMouseOverReadStatus(false);
    setMouseOverClear(false);
  };

  const onMouseInClear = () => {
    setMouseOverReadStatus(false);
    setMouseOverClear(true);
  };

  const onMouseOutClear = () => {
    setMouseOverReadStatus(false);
    setMouseOverClear(false);
  };

  const markAsRead = () => {
    if (!notification.read) {
      markNotificationAsRead();
    }
  };

  const toggleIsRead = () => {
    if (!notification.read) {
      markNotificationAsRead();
    } else {
      markNotificationAsUnRead();
    }
  };

  const showingClear = selected || hovering || mouseOverClear;

  return (
    <React.Fragment>
      <Notification
        selected={selected}
        selectNotification={selectNotification}
        onMouseOutNotification={onMouseOutNotification}
        onMouseInNotification={onMouseInNotification}
        titleText={notification.inAppSubject}
        contentText={notification.inAppBody}
        linkText={notification.inAppLinkText}
        onLinkClicked={onLinkClicked}
        showingClear={showingClear}
        clearNotification={clearNotification}
        onMouseOutClear={onMouseOutClear}
        onMouseInClear={onMouseInClear}
        mouseOverClear={mouseOverClear}
        read={notification.read}
        markAsRead={markAsRead}
        onMouseOutReadStatus={onMouseOutReadStatus}
        onMouseInReadStatus={onMouseInReadStatus}
        mouseOverReadStatus={mouseOverReadStatus}
        timeAgoText={timeAgoText}
        toggleIsRead={toggleIsRead}
      />
    </React.Fragment>
  );
};

export default NotificationContainer;
