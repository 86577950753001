import { useCallback, useEffect, useState } from 'react';

const useNavigationState = ({
  filteredCoreMetrics,
  filteredOtherMetrics,
}: {
  filteredCoreMetrics: Metrics.Metric[];
  filteredOtherMetrics: Metrics.Metric[];
}) => {
  const [mode, setMode] = useState<'core' | 'other'>('core');
  const getNavigationState = useCallback(() => {
    return {
      currentMode: filteredCoreMetrics.length > 0 ? mode : 'other',
      coreLabel: `Core Metrics (${filteredCoreMetrics.length})`,
      otherLabel: `Other Metrics  (${filteredOtherMetrics.length})`,
      onCoreClicked: () => {
        setMode('core');
      },
      onOtherClicked: () => {
        setMode('other');
      },
      filteredCoreMetrics,
      filteredOtherMetrics,
      isTabsEnabled: filteredCoreMetrics.length > 0,
    };
  }, [filteredCoreMetrics, filteredOtherMetrics, mode]);

  const [navigationState, setNavigationState] =
    useState<MetricPickerNavigationState>(getNavigationState());

  useEffect(() => {
    setNavigationState(getNavigationState());
  }, [getNavigationState]);

  return navigationState;
};

export default useNavigationState;
