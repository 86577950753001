import React, { useContext, useEffect, useState } from 'react';
import BaseViewsContext from '../../../../../../contexts/BaseViewsContext';
import DatasetItem from '../DatasetItem';
import NoBaseViewItem from './NoBaseViewItem';

const ConfigItem = ({ item }: { item: FleetOps.PerformanceDatasetConfig }) => {
  const [baseView, setBaseView] = useState<FleetOps.BaseView>();
  const { baseViews, isLoading } = useContext(BaseViewsContext);

  // Initial setBaseView, setCurrentName
  useEffect(() => {
    if (item.status === 'creating' || isLoading) {
      return;
    }

    if (baseView) {
      return;
    }

    const bv = baseViews[item.dataType];
    if (bv) {
      setBaseView(bv);
    }
  }, [baseView, baseViews, isLoading, item.dataType, item.status]);

  if (baseView === undefined) {
    return (
      <NoBaseViewItem
        config={item}
        status={item.status}
        dataType={item.dataType}
      />
    );
  }

  return <DatasetItem baseView={baseView} status={item.status} />;
};

export default ConfigItem;
