import React from 'react';
import GridCellErrorBoundary from '../../GridCellErrorBoundary';
import { ICellRendererParams } from 'ag-grid-community';
import Cell from '../../V5Gadget/Matrix/Cell';

const PaceCell = (params: ICellRendererParams) => {
  return (
    <Cell rightAlign>
      <span>{params.valueFormatted}</span>
    </Cell>
  );
};

const Gate = (params: ICellRendererParams) => (
  <GridCellErrorBoundary
    params={params}
    childComponent={<PaceCell {...params} />}
  />
);

export default Gate;
