const executivePortalRoutes = {
  home: '/:portal',
  reportsIndex: '/:portal/reports',
  reportShow: '/:portal/reports/:reportId',
  dashboardsIndex: '/:portal/dashboards',
  dashboardShow: '/:portal/dashboards/:dashboardId',
  scorecardsIndex: '/:portal/kpi-lists',
  scorecardShow: '/:portal/kpi-lists/:scorecardId',
  targetAppShow: '/:portal/target-apps/:targetAppId',
  users: '/:portal/users',
};

export const executivePortalRouteBuilders = (portalSlug: string) => ({
  home: `${portalSlug}`,
  reportsIndex: `/${portalSlug}/reports`,
  reportShow: (reportId: string) => `/${portalSlug}/reports/${reportId}`,
  dashboardsIndex: `/${portalSlug}/dashboards`,
  dashboardShow: (dashboardId: string) =>
    `/${portalSlug}/dashboards/${dashboardId}`,
  scorecardsIndex: `/${portalSlug}/kpi-lists`,
  scorecardShow: (scorecardId: string) =>
    `/${portalSlug}/kpi-lists/${scorecardId}`,
  targetAppShow: (targetAppId: string) =>
    `/${portalSlug}/target-apps/${targetAppId}`,
  users: `/${portalSlug}/users`,
});

export default executivePortalRoutes;
