export const words = (text: string) => text.split(/\s(?!\s)/gm);

export const currentWord = (commentText: string) =>
  words(commentText).reverse()[0];

export const isMention = (word: string) => word.match(/^@/g);

export const removeSpecialCharacters = (word: string) => {
  if (word === '@') {
    return '';
  }

  const m = word.match(/(\w+)/g);
  if (!m) {
    return word;
  }
  return m.join('');
};
