import buildFilterInput from './utils/buildFilterInput';

const toFilterInput = (
  savedFilter: SavedFilter,
  variableDrillDowns: VariableDrillDownType[],
  toDrillDowns: ({
    plates,
    variableDrillDowns,
  }: {
    plates?: FilterPlate[];
    variableDrillDowns?: VariableDrillDownType[];
  }) => ReportDrillDownType[],
): FilterInput =>
  buildFilterInput({
    scopes: toDrillDowns({
      plates: savedFilter.scope,
      variableDrillDowns,
    }),
    drillDowns: toDrillDowns({
      plates: savedFilter.drillDowns,
      variableDrillDowns,
    }),
  });

export default toFilterInput;
