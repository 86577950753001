import React from 'react';
import Button from 'kingpin/atoms/Button';

const RemoveOverrideButton = ({
  onRemoveClicked,
  isSaving,
}: {
  onRemoveClicked: () => void;
  isSaving: boolean;
}) => (
  <Button
    type="Ghost"
    size="Small"
    isLoading={isSaving}
    isDisabled={isSaving}
    label="Remove Override"
    onClick={onRemoveClicked}
    icon="cross"
  />
);

export default RemoveOverrideButton;
