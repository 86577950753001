import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Typography from '../../kingpin/atoms/Typography';

import Row from '../Common/Row';
import colors from '../../theme/colors';
import Icon from '../../kingpin/atoms/Icon';
import Button from '../../kingpin/atoms/Button';

const FlashWrapper = styled.div<{ type: 'success' | 'info' }>`
  background-color: ${(props) =>
    props.type === 'success' ? colors.MEDIUM_SEA_GREEN : colors.NAVY_BLUE};
  color: white;
  min-height: 42px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px;
  fontsize: 17px;
  line-height: 21px;
`;

const FlashOuter = styled.div`
  position: relative;
  z-index: 9001;
  width: 100%;
`;

const FlashInner = styled.div`
  position: absolute;
  width: 100%;
`;

const variants = {
  enter: {
    y: 0,
    opacity: 1,
    delay: 300,
    transition: {
      default: { duration: 0.3 },
    },
  },
  exit: {
    y: -50,
    opacity: 0,
    transition: { duration: 0.15 },
  },
};

const Flash = ({
  isVisible,
  message,
  messageHtml,
  type,
  onDismissed,
}: {
  isVisible: boolean;
  message?: string;
  messageHtml?: JSX.Element;
  type: 'success' | 'info';
  onDismissed?: () => void;
}) => (
  <FlashOuter>
    <FlashInner>
      {isVisible && (
        <motion.div
          key="flash"
          data-testid="flash"
          variants={variants}
          initial="enter"
          exit="exit"
        >
          <Row centerAlign>
            <FlashWrapper type={type}>
              <Row spaceBetween centerAlign>
                <Row centerAlign>
                  <div style={{ marginRight: 8, display: 'flex' }}>
                    {type === 'success' && (
                      <Icon icon="checkmark" color={'white'} />
                    )}
                    {type === 'info' && (
                      <div style={{ display: 'flex' }}>
                        <Icon icon="info" color="white" />
                      </div>
                    )}
                  </div>
                  {messageHtml ? (
                    messageHtml
                  ) : (
                    <Typography.Body type={'Body 12'} color="white">
                      {message ? message : ''}
                    </Typography.Body>
                  )}
                </Row>
                {onDismissed && (
                  <Button
                    size={'Small'}
                    type={'Secondary'}
                    icon={'cross'}
                    onClick={onDismissed}
                  />
                )}
              </Row>
            </FlashWrapper>
          </Row>
        </motion.div>
      )}
    </FlashInner>
  </FlashOuter>
);

export default Flash;
