import React from 'react';
import CantSignInForm from '../../components/CantSignInForm';
import LandingPage from '../../components/LandingPage';

const CantSignIn = () => (
  <LandingPage>
    <CantSignInForm />
  </LandingPage>
);

export default CantSignIn;
