import { useCallback, useContext } from 'react';
import STORE from 'store';
import AccountPickerContext from 'contexts/AccountPickerContext';
import BoardContext from 'contexts/BoardContext';
import CurrentUserContext from 'contexts/CurrentUserContext';
import isPerformanceBoard from 'isPerformanceBoard';
import getTimeStamp from 'getTimeStamp';

const useUpdateColumnsOrder = () => {
  const currentUser = useContext(CurrentUserContext);
  const { board } = useContext(BoardContext);
  const { selectedAccountId } = useContext(AccountPickerContext);

  const updateColumnsOrder = useCallback(
    ({ newOrder }: { newOrder: PerformanceBoardTypes.Column[] }) => {
      if (!isPerformanceBoard(board)) {
        return;
      }

      const newBoard = {
        ...board,
        columnOrder: newOrder,
        updatedBy: currentUser.id,
        updatedOn: getTimeStamp(),
      };
      STORE.contentDistributions
        .getPerformanceBoardsRef({ accountId: selectedAccountId })
        .doc(newBoard.id)
        .set(newBoard);
    },
    [board, currentUser.id, selectedAccountId],
  );

  return updateColumnsOrder;
};

export default useUpdateColumnsOrder;
