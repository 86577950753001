import React from 'react';

import FlexCentered from './Common/FlexCentered';
import { Img } from '../screens/LegacyHome/RecentsCard/RecentsCard';
import NoIndexItemsFoundSrc from '../images/no-index-items-found.svg';
import Typography from 'kingpin/atoms/Typography';

const NoIndexItemsFound = () => (
  <FlexCentered style={{ height: '100%' }}>
    <Img src={NoIndexItemsFoundSrc} />
    <div style={{ marginBottom: 4 }}>
      <Typography.Body type="Body 14">
        No items were found to match your search
      </Typography.Body>
    </div>

    <Typography.Body type="Body 14">Try modifying your filters</Typography.Body>
  </FlexCentered>
);

export default NoIndexItemsFound;
