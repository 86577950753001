import React, { useCallback, useEffect, useState } from 'react';
import Typography from 'kingpin/atoms/Typography';
import reloadWhenSafe from '../reloadWhenSafe';
import LandingPage from '../components/LandingPage';
import Branding from '../components/Branding';
import captureException from '../services/captureException';
import ReloadContext from 'contexts/ReloadContext';

const ReloadProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [isReloading, setIsReloading] = useState<boolean>();

  useEffect(() => {
    if (isReloading) {
      setTimeout(reloadWhenSafe, 3000);
    }
  }, [isReloading]);

  const reload = useCallback((error: Error) => {
    setIsReloading(true);
    captureException(error);
  }, []);

  if (isReloading) {
    return (
      <LandingPage style={{ height: 'unset', minHeight: '100vh' }}>
        <Branding />
        <Typography.Header type={'H4'}>
          FleetOps has encountered an issue and will attempt to restart shortly.
        </Typography.Header>
        <Typography.Header type={'H4'}>
          If this issue persists, please contact customer support
        </Typography.Header>
      </LandingPage>
    );
  } else {
    return (
      <ReloadContext.Provider value={{ reload }}>
        {children}
      </ReloadContext.Provider>
    );
  }
};

export default ReloadProvider;
