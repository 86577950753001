import { useCallback, useEffect, useState } from 'react';

const useSelectedLabel = (options: ExclusiveDropdownOption[]) => {
  const getLabel = useCallback((): string | undefined => {
    const selectedOptions = options.filter((o) => o.isSelected);
    if (selectedOptions.length === 0) {
      return undefined;
    }

    const newLabel = selectedOptions.reduce((wipLabel, option, index) => {
      if (index === 0) {
        return option.label;
      }
      return `${wipLabel}, ${option.label}`;
    }, '');

    return newLabel;
  }, [options]);
  const [label, setLabel] = useState<string | undefined>(() => getLabel());
  useEffect(() => {
    setLabel(getLabel());
  }, [getLabel]);

  return label;
};

export default useSelectedLabel;
