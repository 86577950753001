import React, { ChangeEvent } from 'react';
import { DateTime } from 'luxon';
import TextInput from 'kingpin/atoms/TextInput';
import Typography from 'kingpin/atoms/Typography';
import Inputs from 'components/Inputs';
import { InputSection, InputWrapper } from '../styles';
import WarningTextDiv from '../WarningTextDiv';

const AwardedLoads = ({
  commits,
  onCommitsChanged,
  cadence,
  cadenceOptions,
  startDate,
  onStartDateChanged,
  endDate,
  isEndDateInPast,
  onEndDateChanged,
  showWarnings,
}: {
  commits?: number;
  onCommitsChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  cadence: 'weekly' | 'monthly' | 'quarterly' | 'yearly';
  cadenceOptions: DropdownOption[];

  startDate?: string;
  onStartDateChanged: (date: string) => void;

  endDate?: string;
  isEndDateInPast: boolean;
  onEndDateChanged: (date: string) => void;
  showWarnings: boolean;
}) => (
  <InputSection>
    <div style={{ marginBottom: 24 }}>
      <Typography.Header type="H5">Awarded Loads</Typography.Header>
    </div>
    <InputWrapper>
      <div style={{ marginBottom: 4 }}>
        <Typography.Body type="Label">* Effective Date</Typography.Body>
      </div>
      <TextInput
        value={startDate || ''}
        onChange={(event) => onStartDateChanged(event.target.value)}
        type="date"
        state={showWarnings && !startDate ? 'Error' : undefined}
        placeholder={DateTime.local().startOf('day').toISODate()}
      />
    </InputWrapper>
    <InputWrapper>
      <div style={{ marginBottom: 4 }}>
        <Typography.Body type="Label">* End Date</Typography.Body>
      </div>
      {showWarnings && isEndDateInPast && (
        <WarningTextDiv style={{ marginBottom: 4 }}>
          <Typography.Body type={'Body 14'}>
            Cannot end in the past
          </Typography.Body>
        </WarningTextDiv>
      )}
      <TextInput
        value={endDate || ''}
        onChange={(event) => onEndDateChanged(event.target.value)}
        type="date"
        state={
          showWarnings && (isEndDateInPast || !endDate) ? 'Error' : undefined
        }
        placeholder={DateTime.local()
          .startOf('day')
          .plus({ year: 1 })
          .toISODate()}
      />
    </InputWrapper>
    <InputWrapper>
      <div style={{ marginBottom: 4 }}>
        <Typography.Body type="Label">* Quantity</Typography.Body>
      </div>
      <TextInput
        type="number"
        value={commits ? commits.toString(10) : ''}
        onChange={onCommitsChanged}
        state={showWarnings && !commits ? 'Error' : undefined}
      />
    </InputWrapper>
    <InputWrapper>
      <div style={{ marginBottom: 4 }}>
        <Typography.Body type="Label">* Loads per</Typography.Body>
      </div>
      <Inputs.Dropdown
        options={cadenceOptions}
        hasError={showWarnings && !cadence}
      />
    </InputWrapper>
  </InputSection>
);

export default AwardedLoads;
