import { useCallback } from 'react';
import { isNumber } from '../KpiRowRight/KpiValue';

const useKpiValue = () => {
  const getKpiValue = useCallback(
    ({ trend, period }: { trend: Goals.TrendResult[]; period: Period }) => {
      const trendItem = trend.find((t) => t.date === period.startDate);
      if (!trendItem) {
        return undefined;
      }

      const newValue = trendItem.value;
      if (isNumber(newValue)) {
        return newValue;
      } else {
        return undefined;
      }
    },
    [],
  );

  return getKpiValue;
};

export default useKpiValue;
