import { useCallback, useContext, useEffect, useState } from 'react';
import DatasetDefinitionsContext from '../../../../../contexts/DatasetDefinitionsContext';
import BaseViewsContext from '../../../../../contexts/BaseViewsContext';
import CurrentUserContext from '../../../../../contexts/CurrentUserContext';
import useSavedDatasetWizards from './useSavedDatasetWizards';

const usePerformanceDatasetsList = (searchText: string) => {
  const { performanceConfigs, isLoading: isLoadingDatasets } = useContext(
    DatasetDefinitionsContext,
  );
  const { baseViewsLastUpdatedAtLookup, getDatasetLabel, baseViews } =
    useContext(BaseViewsContext);
  const { isFleetOpsStaff } = useContext(CurrentUserContext);

  const [performanceDatasetList, setPerformanceDatasetList] = useState<
    {
      item: DataManager.DatasetListItem;
      name: string;
      lastUpdated?: number;
    }[]
  >([]);
  const { isLoadingSavedWizards, savedWizards } = useSavedDatasetWizards();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getName = useCallback(
    (item: DataManager.DatasetListItem) => {
      if (item.type === 'savedWizard') {
        return item.content.config.datasetStep.name || '';
      } else if (item.type === 'performanceConfig') {
        return getDatasetLabel(item.content.dataType);
      }

      return '';
    },
    [getDatasetLabel],
  );

  const getLastUpdated = useCallback(
    (item: DataManager.DatasetListItem) => {
      if (item.type === 'savedWizard') {
        return undefined;
      } else if (item.type === 'performanceConfig') {
        return baseViewsLastUpdatedAtLookup[item.content.dataType];
      }
    },
    [baseViewsLastUpdatedAtLookup],
  );

  // setPerformanceDatasetsList
  useEffect(() => {
    const fullList = [
      ...performanceConfigs.map((c) => ({
        type: 'performanceConfig' as 'performanceConfig',
        content: c,
      })),
      ...savedWizards.map((c) => ({
        type: 'savedWizard' as 'savedWizard',
        content: c,
      })),
    ];

    const filteredList = fullList
      .filter((item) => {
        return getName(item).toLowerCase().includes(searchText.toLowerCase());
      })
      .filter((pds) => {
        if (pds.type === 'savedWizard') {
          return true;
        }
        const bv = baseViews[pds.content.dataType];
        if (!bv) {
          return true;
        }

        if (bv.isHidden && !isFleetOpsStaff) {
          return false;
        }

        return true;
      });

    setPerformanceDatasetList(
      filteredList.map((item) => ({
        item,
        name: getName(item),
        lastUpdated: getLastUpdated(item),
      })),
    );
  }, [
    baseViews,
    getLastUpdated,
    getName,
    isFleetOpsStaff,
    performanceConfigs,
    savedWizards,
    searchText,
  ]);

  // setIsLoading
  useEffect(() => {
    setIsLoading(isLoadingDatasets || isLoadingSavedWizards);
  }, [isLoadingDatasets, isLoadingSavedWizards]);

  return {
    performanceDatasetList,
    isLoading,
  };
};

export default usePerformanceDatasetsList;
