import React, { useCallback, useContext } from 'react';
import Row from 'components/Common/Row';
import WarningSvg from '../icons/warning.svg';
import Typography from 'kingpin/atoms/Typography';
import CostsShowContext from '../context/CostsShowContext';
import useIntervals from './hooks/useIntervals';

const SaveErrorMessage = ({
  invalidIntervals,
}: {
  invalidIntervals: Costs.IntervalCostsInput[];
}) => {
  const { costModel } = useContext(CostsShowContext);
  const { startDateToPeriod } = useIntervals({ activityCosts: costModel });

  const getIntervalsText = useCallback(() => {
    const text: string[] = [];
    invalidIntervals.forEach((interval) =>
      text.push(
        startDateToPeriod({
          startDate: interval.startDate,
          interval: costModel ? costModel.interval : 'month',
        }).label,
      ),
    );
    return text.join(', ');
  }, [costModel, invalidIntervals, startDateToPeriod]);

  return (
    <div
      style={{
        backgroundColor: '#FFE3E3',
        padding: '16px',
        borderRadius: '8px',
        width: '100%',
        marginBottom: '20px',
      }}
    >
      <Row style={{ marginBottom: '8px' }}>
        <img src={WarningSvg} style={{ marginRight: '8px' }} alt="warning" />
        <Typography.Body type="Body 14">
          {`Please complete or discard your changes for the following ${
            costModel ? costModel.interval : ''
          }(s):`}
        </Typography.Body>
      </Row>
      <Typography.Body type="Body 12">{getIntervalsText()}</Typography.Body>
    </div>
  );
};

export default SaveErrorMessage;
