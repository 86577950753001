const Colors2 = {
  Primary: {
    1: '#DF3E56',
    2: '#CC2941',
    3: '#F1C1C8',
    4: '#352D43',
    5: '#3F384E',
    6: '#6264A7',
    7: '#EBEBEB',
    8: '#F2F2F2',
  },
  Grey: {
    1: '#1E1A26',
    2: '#5C5C5C',
    3: '#828282',
    4: '#919191',
    5: '#A1A1A1',
    6: '#C3C3C3',
    7: '#DCDCDC',
    8: '#E9E9E9',
    9: '#FBFBFE',
    10: '#FFFFFF',
  },
  GridOddRow: '#F7F9FB', // This is also hard coded in styles.scss
  GridBorder: '#F0F0F0',
  Secondary: {
    info: '#1252F7',
    success: '#6FBD84',
    error: '#D66969',
    errorBackground: '#fff2f8',
    warning: '#F3C25F',
  },
  AvatarColors: {
    1: {
      background: '#E7E4F7',
      text: '#6859B5',
    },
    2: {
      background: '#D4E6FF',
      text: '#1D63Be',
    },
    3: {
      background: '#FFF3E3',
      text: '#9B5C03',
    },
    4: {
      background: '#E7E9ED',
      text: '#515D72',
    },
    5: {
      background: '#DDF8F1',
      text: '#06795C',
    },
    6: {
      background: '#DAF5FB',
      text: '#147286',
    },
    7: {
      background: '#FEEEF4',
      text: '#AB4367',
    },
    8: {
      background: '#FDE9E9',
      text: '#C9181E',
    },
  },
  Border: '#eeeff1',
};

export default Colors2;
