import React from 'react';
import { ModalTransition } from '@atlaskit/modal-dialog';
import usePopup from 'hooks/usePopup';

import WizardItem from './WizardItem';
import PerformanceDatasetWizard from '../../PerformanceDatasetWizard';

const WizardItemContainer = ({
  item,
}: {
  item: PerformanceConfiguration.WizardState;
}) => {
  const { isOpen, open, close } = usePopup();
  const { name, entity } = item.config.datasetStep;

  return (
    <>
      <WizardItem name={name} onEditClicked={open} entity={entity} />
      <ModalTransition>
        {isOpen && (
          <PerformanceDatasetWizard close={close} initialState={item} />
        )}
      </ModalTransition>
    </>
  );
};

export default WizardItemContainer;
