import { useCallback, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import MetricOptionsContext from '../../../../../contexts/MetricOptionsContext';
import useGetMetricDatasets from './useGetMetricDatasets';
import metricTypeCheckers from '../../../../../types/metricTypeCheckers';
import useAllowedDatasets from './useAllowedDatasets';
import CompoundMetricsContext from '../../../../../contexts/CompoundMetricsContext';

const useAllowedMetrics = (constraints: MetricPickerOptionConstraints) => {
  const { metricOptions } = useContext(MetricOptionsContext);
  const { compoundMetricInputsLookup } = useContext(CompoundMetricsContext);
  const getMetricDatasets = useGetMetricDatasets();
  const allowedDatasets = useAllowedDatasets(constraints);
  const getUsedDatasets = useGetMetricDatasets();

  const getMetricAggFuncs = useCallback(
    (metric: Metrics.Metric) => {
      if (metricTypeCheckers.isNormalMetric(metric)) {
        return [metric.aggFunc];
      } else if (metricTypeCheckers.isSpecialMetric(metric)) {
        return undefined;
      } else if (metricTypeCheckers.isCompoundMetric(metric)) {
        const inputMetrics = compoundMetricInputsLookup[metric.id];
        if (!inputMetrics) {
          return undefined;
        }
        return inputMetrics.map((m) => m.aggFunc);
      } else {
        const e = new Error();
        e.name = `getMetricAggFuncs: Unknown metric type found`;
        console.warn(metric);
        throw e;
      }
    },
    [compoundMetricInputsLookup],
  );

  const getAllowedMetrics = useCallback(() => {
    let results = metricOptions.filter((metric) => {
      const metricDatasets = getMetricDatasets(metric).map((s) => s.type);
      return allowedDatasets.some((s) => metricDatasets.includes(s.type));
    });

    if (constraints.noCompound !== undefined) {
      results = results.filter((metric) => {
        if (!constraints.noCompound) {
          return true;
        }

        return !metricTypeCheckers.isCompoundMetric(metric);
      });
    }

    if (constraints.noSpecial !== undefined) {
      results = results.filter((metric) => {
        if (!constraints.noSpecial) {
          return true;
        }

        return !metricTypeCheckers.isSpecialMetric(metric);
      });
    }

    if (constraints.metricIdRedList !== undefined) {
      results = results.filter((metric) => {
        if (!constraints.metricIdRedList) {
          return true;
        }

        if (constraints.metricIdRedList.includes(metric.id)) {
          return false;
        }

        return true;
      });
    }

    if (constraints.aggFuncRedList !== undefined) {
      results = results.filter((metric) => {
        const redList = constraints.aggFuncRedList;
        if (!redList) {
          return true;
        }

        const usedAggFuncs = getMetricAggFuncs(metric);
        if (!usedAggFuncs) {
          return true;
        }

        const hasRedListedAggFunc = usedAggFuncs.some((af) =>
          redList.includes(af),
        );

        if (hasRedListedAggFunc) {
          return false;
        }
        return true;
      });
    }

    if (constraints.aggFuncGreenList !== undefined) {
      results = results.filter((metric) => {
        const greenList = constraints.aggFuncGreenList;
        if (!greenList) {
          return true;
        }

        const usedAggFuncs = getMetricAggFuncs(metric);
        if (!usedAggFuncs) {
          return true;
        }

        const hasGreenListedAggFunc = usedAggFuncs.some((af) =>
          greenList.includes(af),
        );

        if (!hasGreenListedAggFunc) {
          return false;
        }
        return true;
      });
    }

    if (constraints.datasetRedList !== undefined) {
      results = results.filter((metric) => {
        const redList = constraints.datasetRedList;
        if (!redList) {
          return true;
        }

        const usedDatasets = getUsedDatasets(metric).map((ds) => ds.type);
        if (
          redList.some((bannedDataset) => usedDatasets.includes(bannedDataset))
        ) {
          return false;
        }

        return true;
      });
    }

    if (constraints.fieldRedList !== undefined) {
      results = results.filter((metric) => {
        if (!metricTypeCheckers.isNormalMetric(metric)) {
          return true;
        }
        const redList = constraints.fieldRedList;
        if (!redList) {
          return true;
        }

        return !redList.includes(metric.field);
      });
    }

    return results;
  }, [
    allowedDatasets,
    constraints.aggFuncGreenList,
    constraints.aggFuncRedList,
    constraints.datasetRedList,
    constraints.fieldRedList,
    constraints.metricIdRedList,
    constraints.noCompound,
    constraints.noSpecial,
    getMetricAggFuncs,
    getMetricDatasets,
    getUsedDatasets,
    metricOptions,
  ]);

  const [allowedMetrics, setAllowedMetrics] = useState<Metrics.Metric[]>(() =>
    getAllowedMetrics(),
  );
  useEffect(() => {
    setAllowedMetrics(_.sortBy(getAllowedMetrics(), 'name'));
  }, [getAllowedMetrics]);

  return allowedMetrics;
};

export default useAllowedMetrics;
