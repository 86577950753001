const CITY_NAMES = [
  'New York City',
  'Los Angeles',
  'Chicago',
  'Houston',
  'Phoenix',
  'San Antonio',
  'Philadelphia',
  'San Diego',
  'Dallas',
  'Austin',
  'San Jose',
  'Fort Worth',
  'Jacksonville',
  'Charlotte',
  'Columbus',
  'Indianapolis',
  'San Francisco',
  'Seattle',
  'Denver',
  'Washington',
  'Boston',
  'El Paso',
  'Nashville',
  'Oklahoma City',
  'Las Vegas',
  'Portland',
  'Detroit',
  'Memphis',
  'Louisville',
  'Milwaukee',
  'Baltimore',
  'Albuquerque',
  'Tucson',
  'Mesa',
  'Fresno',
  'Atlanta',
  'Sacramento',
  'Kansas City',
  'Colorado Springs',
  'Raleigh',
  'Miami',
  'Omaha',
  'Long Beach',
  'Virginia Beach',
  'Oakland',
  'Minneapolis',
  'Tampa',
  'Tulsa',
  'Arlington',
  'Aurora',
  'Wichita',
  'Bakersfield',
  'New Orleans',
  'Cleveland',
  'Henderson',
  'Anaheim',
  'Honolulu',
  'Riverside',
  'Santa Ana',
  'Corpus Christi',
  'Lexington',
  'San Juan',
  'Stockton',
  'St. Paul',
  'Cincinnati',
  'Irvine',
  'Greensboro',
  'Pittsburgh',
  'Lincoln',
  'Durham',
  'Orlando',
  'St. Louis',
  'Chula Vista',
  'Plano',
  'Newark',
  'Anchorage',
  'Fort Wayne',
  'Chandler',
  'Reno',
  'North Las Vegas',
  'Scottsdale',
  'St. Petersburg',
  'Laredo',
  'Gilbert',
  'Toledo',
  'Lubbock',
  'Madison',
  'Glendale',
  'Jersey City',
  'Buffalo',
  'Chesapeake',
  'Winston-Salem',
  'Fremont',
  'Norfolk',
  'Frisco',
  'Paradise',
  'Irving',
  'Garland',
  'Richmond',
  'Arlington',
  'Boise',
  'Spokane',
  'Hialeah',
  'Moreno Valley',
  'Tacoma',
  'Port St. Lucie',
  'McKinney',
  'Fontana',
  'Modesto',
  'Fayetteville',
  'Baton Rouge',
  'San Bernardino',
  'Santa Clarita',
  'Cape Coral',
  'Des Moines',
  'Tempe',
  'Huntsville',
  'Oxnard',
  'Spring Valley',
  'Birmingham',
  'Rochester',
  'Overland Park',
  'Grand Rapids',
  'Yonkers',
  'Salt Lake City',
  'Columbus',
  'Augusta',
  'Amarillo',
  'Tallahassee',
  'Ontario',
  'Montgomery',
  'Little Rock',
  'Akron',
  'Huntington Beach',
  'Grand Prairie',
  'Glendale',
  'Sioux Falls',
  'Sunrise Manor',
  'Aurora',
  'Vancouver',
  'Knoxville',
  'Peoria',
  'Mobile',
  'Chattanooga',
  'Worcester',
  'Brownsville',
  'Fort Lauderdale',
  'Newport News',
  'Elk Grove',
  'Providence',
  'Shreveport',
  'Salem',
  'Pembroke Pines',
  'Eugene',
  'Rancho Cucamonga',
  'Cary',
  'Santa Rosa',
  'Fort Collins',
  'Oceanside',
  'Corona',
  'Enterprise',
  'Garden Grove',
  'Springfield',
  'Clarksville',
  'Murfreesboro',
  'Lakewood',
  'Bayamon',
  'Killeen',
  'Alexandria',
  'Midland',
  'Hayward',
  'Hollywood',
  'Salinas',
  'Lancaster',
  'Macon',
  'Surprise',
  'Kansas City',
  'Sunnyvale',
  'Palmdale',
  'Bellevue',
  'Springfield',
  'Denton',
  'Jackson',
  'Escondido',
  'Pomona',
  'Naperville',
  'Roseville',
  'Thornton',
  'Round Rock',
  'Pasadena',
  'Joliet',
  'Carrollton',
  'McAllen',
  'Paterson',
  'Rockford',
  'Waco',
  'Bridgeport',
  'Miramar',
  'Olathe',
  'Metairie',
];

export default CITY_NAMES;
