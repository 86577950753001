import React, { useContext, useState } from 'react';
import { GridApi } from 'ag-grid-community';

import DashboardGadgetContext from '../../contexts/DashboardGadgetContext';
import GqlClientProvider from '../../contextProviders/GqlClientProvider';
import CanvasCard from '../Visualisations/CanvasCard';
import { TopBar } from '../V5Gadget/Matrix';
import DashboardGadgetBottomBar from '../DashboardGadgetBottomBar';
import PaceMatrixContent from './PaceMatrixContent';
import { ChartTopBar } from '../Chart';

const PaceMatrix = ({
  gadget,
}: {
  gadget: VisualisationDefinitions.PaceMatrix;
}) => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const { dashboardGadget } = useContext(DashboardGadgetContext);

  return (
    <CanvasCard
      isContentWithoutPadding
      topBar={
        gridApi && !gadget.isCardMode ? (
          <TopBar
            gridApi={gridApi}
            exportName={gadget.name}
            chartDefinition={gadget}
            title={gadget.name}
          />
        ) : (
          <ChartTopBar chartDefinition={gadget} />
        )
      }
      bottomBar={dashboardGadget ? <DashboardGadgetBottomBar /> : undefined}
      content={
        <GqlClientProvider visName={gadget.name} visId={gadget.id}>
          <PaceMatrixContent
            gadget={gadget}
            gridApi={gridApi}
            setGridApi={setGridApi}
          />
        </GqlClientProvider>
      }
    />
  );
};

export default PaceMatrix;
