const getIsRangeChart = (chartDef?: V5ChartDefinition): boolean => {
  if (!chartDef) {
    return false;
  }

  return (
    chartDef.dimensionA !== undefined &&
    chartDef.dimensionA.rangeInput !== undefined
  );
};

export default getIsRangeChart;
