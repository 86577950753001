import React from 'react';
import usePopup from 'hooks/usePopup';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import Button from 'kingpin/atoms/Button';
import EntityForm from './EntityForm';

const EntitySettingsButton = ({
  entity,
  isAccessOnly,
}: {
  entity: EntityDetails.Entity;
  isAccessOnly?: boolean;
}) => {
  const { isOpen, open, close } = usePopup();

  return (
    <>
      <Button
        type="Tertiary"
        size="Small"
        onClick={open}
        icon={'settings-empty'}
        testId={`edit-${entity.id}`}
      />
      <ModalTransition>
        {isOpen && (
          <Modal
            shouldScrollInViewport={false}
            autoFocus={false}
            onClose={close}
          >
            <EntityForm
              entity={entity}
              close={close}
              isAccessOnly={isAccessOnly}
            />
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};

export default EntitySettingsButton;
