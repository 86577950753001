import React, { useCallback, useContext } from 'react';
import CurrentUserContext from '../../../../contexts/CurrentUserContext';
import SingleUseMetricPopupContext from '../../../../contexts/SingleUseMetricPopupContext';
import { useNavigate } from 'react-router-dom';
import AnalyticsContext from '../../../../contexts/AnalyticsContext';
import AccountPickerContext from '../../../../contexts/AccountPickerContext';
import useMetricsTimelineEventCreator from '../MetricPopup/MetricForm/NormalMetricForm/useMetricsTimelineEventCreator';
import useInputMetricsPublisher from '../MetricPopup/MetricActions/useInputMetricsPublisher';
import metricTypeCheckers from '../../../../types/metricTypeCheckers';
import getIdentifier from '../../../../getIdentifier';
import getTimeStamp from '../../../../getTimeStamp';
import updateMetric from '../../../../api/metrics/updateMetric';
import createMetric from '../../../../api/metrics/createMetric';
import updateCompoundMetric from '../../../../api/compoundMetrics/updateCompoundMetric';
import createCompoundMetric from '../../../../api/compoundMetrics/createCompoundMetric';
import InSituMetricEditorContext from '../../../../contexts/InSituMetricEditorContext';
import usePerformanceConfigurationDependencies from '../MetricPopup/MetricForm/usePerformanceConfigurationDependencies';
import useConfirmation from '../../DatasetDefinitions/DatasetsIndex/PerformanceDatasetWizard/useConfirmation';
import PerformanceDatasetsSaveWarning from '../PerformanceDatasetsSaveWarning';
import MetricOptionsContext from 'contexts/MetricOptionsContext';
import LocalTimelineContext from 'contexts/Timeline/LocalTimelineContext';

const useSaveMetric = ({
  metricDraft,
  selectedMetric,
  setStatus,
  showFlash,
  setIsLoading,
}: {
  metricDraft?: Metrics.NormalMetric | Metrics.CompoundMetric;
  selectedMetric?: Metrics.NormalMetric | Metrics.CompoundMetric;
  setStatus: React.Dispatch<React.SetStateAction<Metrics.MetricStatus>>;
  showFlash: (message: string) => void;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { usedBy, isUsedInPerformanceConfig, usageCount } =
    usePerformanceConfigurationDependencies(metricDraft);
  const { id: currentUserId } = useContext(CurrentUserContext);
  const { onSingleUseMetricSaved, isSingleUsePopup } = useContext(
    SingleUseMetricPopupContext,
  );
  const { isInSituEditor, onInSituEditorSaved } = useContext(
    InSituMetricEditorContext,
  );
  const { metricOptionsLookup } = useContext(MetricOptionsContext);
  const currentUser = useContext(CurrentUserContext);
  const { addEvent } = useContext(LocalTimelineContext);
  const navigate = useNavigate();
  const { trackEvent } = useContext(AnalyticsContext);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const createTimelineEvents = useMetricsTimelineEventCreator({
    metricDraft,
    selectedMetric,
  });
  const publishInputMetrics = useInputMetricsPublisher(metricDraft);
  const { getConfirmation, ConfirmationModal } = useConfirmation({
    title: `Warning: ${usageCount} Dataset(s) will be affected`,
    bodyComponent: (
      <PerformanceDatasetsSaveWarning
        metricName={metricDraft ? metricDraft.name : 'NA'}
        affectedDatasets={usedBy}
      />
    ),
    confirmText: 'Confirm & Save changes',
  });

  const getOnSaveSuccessTrackingEvent = useCallback(
    (isSaveAs: boolean): Analytics.EventType | undefined => {
      const isUpdate = selectedMetric !== undefined && !isSaveAs;
      const isCreate = selectedMetric === undefined && !isSaveAs;
      if (isSaveAs) {
        return 'Metric Builder - Metric Saved As';
      } else if (isCreate) {
        return 'Metric Builder - Metric Created';
      } else if (isUpdate) {
        return 'Metric Builder - Metric Updated';
      }

      return undefined;
    },
    [selectedMetric],
  );

  const onSaveSuccess = useCallback(
    async (
      newMetric: Metrics.NormalMetric | Metrics.CompoundMetric,
      isSaveAs: boolean,
      newMetricId: string,
    ) => {
      if (!metricDraft) {
        return;
      }
      const newStatus = newMetric.status;
      const isUpdate = selectedMetric !== undefined && !isSaveAs;
      const event = getOnSaveSuccessTrackingEvent(isSaveAs);
      if (event) {
        await trackEvent(event, {
          metricName: metricDraft.name,
          metricId: newMetricId,
          metricType: metricDraft.type,
        });
      }

      await createTimelineEvents(newStatus, isSaveAs, newMetricId);
      await publishInputMetrics(newStatus);
      showFlash(isUpdate ? 'Metric Updated' : 'Metric Created');
      setStatus(newStatus);

      if (isSingleUsePopup) {
        onSingleUseMetricSaved(newMetric);
      } else if (isInSituEditor) {
        onInSituEditorSaved(newMetric);
      } else {
        navigate({ search: `metricId=${newMetricId}` });
      }

      setIsLoading(false);
    },
    [
      createTimelineEvents,
      getOnSaveSuccessTrackingEvent,
      isInSituEditor,
      isSingleUsePopup,
      metricDraft,
      onInSituEditorSaved,
      onSingleUseMetricSaved,
      publishInputMetrics,
      navigate,
      selectedMetric,
      setIsLoading,
      setStatus,
      showFlash,
      trackEvent,
    ],
  );

  const onMetricUnarchived = useCallback(
    async (metric: Metrics.NormalMetric) => {
      if (addEvent) {
        await addEvent({
          actionText: 'Unarchived metric',
          contextText: `"${metric.name}"`,
          destinationOverride: { id: metric.id, type: 'Metric' },
        });
      }
      await trackEvent('Metric Builder - Metric Unarchived', {
        metricType: metric.type,
      });

      await onSaveSuccess(metric, false, metric.id);
    },
    [addEvent, onSaveSuccess, trackEvent],
  );

  const onSave = useCallback(
    async ({
      statusOverride,
      isSaveAs,
    }: {
      statusOverride?: Metrics.MetricStatus;
      isSaveAs?: boolean;
    }) => {
      if (!metricDraft) {
        return;
      }

      if (isUsedInPerformanceConfig) {
        const confirmation = await getConfirmation();
        if (!confirmation) {
          return;
        }
      }

      const newStatus = statusOverride ? statusOverride : metricDraft.status;

      // When anarchiving compound metric, unarchive all archived normal metrics inside of that.
      if (
        metricTypeCheckers.isCompoundMetric(metricDraft) &&
        statusOverride &&
        statusOverride === 'public'
      ) {
        const promises = metricDraft.metricIds.map((metricId) => {
          const metric = metricOptionsLookup[metricId];
          if (
            metricTypeCheckers.isNormalMetric(metric) &&
            metric.status === 'archived'
          ) {
            const newMetric = {
              ...metric,
              updatedBy: currentUser.id,
              status: 'public' as 'public',
            };
            return updateMetric(metric.id, newMetric, selectedAccountId).then(
              () => onMetricUnarchived(metric),
            );
          }
          return Promise.resolve();
        });

        await Promise.all(promises);
      }

      const newMetric = {
        ...metricDraft,
        id: isSaveAs ? getIdentifier() : metricDraft.id,
        status: newStatus,
        updatedBy: currentUserId,
        updatedOn: getTimeStamp(),
        createdOn:
          isSaveAs || !selectedMetric
            ? getTimeStamp()
            : selectedMetric.createdOn,
        createdBy:
          isSaveAs || !selectedMetric
            ? currentUserId
            : selectedMetric.createdBy,
      };

      setIsLoading(true);

      if (metricTypeCheckers.isNormalMetric(newMetric)) {
        if (selectedMetric && !isSaveAs) {
          await updateMetric(newMetric.id, newMetric, selectedAccountId);
        } else {
          await createMetric(newMetric, selectedAccountId);
        }
      } else if (metricTypeCheckers.isCompoundMetric(newMetric)) {
        if (selectedMetric && !isSaveAs) {
          await updateCompoundMetric(
            newMetric.id,
            newMetric,
            selectedAccountId,
          );
        } else {
          await createCompoundMetric(newMetric, selectedAccountId);
        }
      } else {
        const e = new Error('');
        e.name = `Unknown metric type: ${metricDraft.type}`;
        throw e;
      }

      if (statusOverride === 'archived') {
        await trackEvent('Metric Builder - Metric Archived', {
          metricType: newMetric.type,
        });
      } else if (statusOverride === 'public') {
        await trackEvent('Metric Builder - Metric Unarchived', {
          metricType: newMetric.type,
        });
      }
      await onSaveSuccess(newMetric, !!isSaveAs, newMetric.id);
    },
    [
      metricDraft,
      isUsedInPerformanceConfig,
      currentUserId,
      selectedMetric,
      setIsLoading,
      onSaveSuccess,
      getConfirmation,
      metricOptionsLookup,
      currentUser.id,
      selectedAccountId,
      onMetricUnarchived,
      trackEvent,
    ],
  );

  return {
    onSave,
    ConfirmationModal,
  };
};

export default useSaveMetric;
