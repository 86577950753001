import visTypeCheckers from 'types/visTypeCheckers';

const buildShowAllKey = ({
  chartDef,
  dashboardGadget,
}: {
  chartDef: VisualisationDefinitions.SingleMetricDateMatrix;
  dashboardGadget?: DashboardGadget;
}) => {
  if (
    dashboardGadget &&
    visTypeCheckers.isSingleMetricDateMatrix(dashboardGadget.chartDef)
  ) {
    return `${dashboardGadget.id}-show-all-portal`;
  }

  return `${chartDef.id}-show-all-portal`;
};

export default buildShowAllKey;
