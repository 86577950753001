import formatFloat from '../../../api/getChartSeries/formatFloat';

const getDiffPercentage = ({
  actual,
  average,
}: {
  actual: number;
  average: number;
}) => {
  if (average === 0) {
    return '';
  }

  return `(${formatFloat((actual / average) * 100 - 100, 2)}%)`;
};

export default getDiffPercentage;
