import { useEffect, useState } from 'react';
import useV5RollingTrendCardDefinition from './useV5RollingTrendCardDefinition';
import useV5ChartData from './useV5ChartData';
import { getItemFor } from '../components/V5Gadget/trendCardHelpers';

const useV5RollingTrendCard = (chartDefinition: V5ChartDefinition) => {
  const [trendItems, setTrendItems] = useState<TrendCardItem[]>([]);

  const { fixedTrendCardDefinition } =
    useV5RollingTrendCardDefinition(chartDefinition);

  const { data, isLoading } = useV5ChartData(fixedTrendCardDefinition);

  useEffect(() => {
    if (!data) {
      return;
    }

    const { response, metric } = Object.values(data)[0];
    const items = [];
    const metricId = chartDefinition.series[0].metricId;
    const { formatting } = metric;
    const { precision } = formatting;
    items.push(
      getItemFor({
        offset: 0,
        label: 'Last 30 days',
        data: response,
        precision,
        metricId,
      }),
    );
    items.push(
      getItemFor({
        offset: 1,
        label: 'Previous 30 days',
        data: response,
        precision,
        metricId,
      }),
    );
    items.push(
      getItemFor({
        offset: 3,
        label: 'three months ago',
        data: response,
        precision,
        metricId,
      }),
    );
    items.push(
      getItemFor({
        offset: 6,
        label: 'six months ago',
        data: response,
        precision,
        metricId,
      }),
    );
    items.push(
      getItemFor({
        offset: 12,
        label: 'twelve months ago',
        data: response,
        precision,
        metricId,
      }),
    );
    const validItems = items.filter((i) => i !== undefined) as TrendCardItem[];
    setTrendItems(validItems);
  }, [isLoading, data, chartDefinition.series]);

  return {
    trendItems,
    isLoading,
  };
};

export default useV5RollingTrendCard;
