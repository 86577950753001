import React from 'react';

interface DateFieldsContextType {
  dateFields: DateField[];
}

const DateFieldsContext = React.createContext<DateFieldsContextType>({
  dateFields: [],
});

export default DateFieldsContext;
