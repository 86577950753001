import React, { useCallback, useEffect, useState } from 'react';
import GroupedLoadingContext from '../contexts/GroupedLoadingContext';
import Loading from '../components/Loading/Loading';
import { motion } from 'framer-motion';
import aguid from 'aguid';
import FlexCentered from '../components/Common/FlexCentered';

const loadingTimeout = 2000;
const minimumLoadingTime = 400;

const variants = {
  loading: {
    opacity: 0,
    transition: { duration: 0.35 },
  },
  loaded: {
    opacity: 1,
    transition: { duration: 0.35 },
  },
};

const GroupedLoadingProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [id] = useState(aguid());
  const [loaders, setLoaders] = useState<Loader[]>([]);
  const [lastLoaderAddedAt, setLastLoaderAddedAt] = useState<number>(
    new Date().getTime(),
  );
  const [isLoading, setIsLoading] = useState(true);
  const [useLongTimeout, setUseLongTimeout] = useState(false);

  const addLoader = (l: Loader) => {
    if (loaders.some((loader) => l.id === loader.id)) {
      return;
    }
    setLoaders((ls) => [...ls, l]);
    setLastLoaderAddedAt(new Date().getTime());
  };

  const restartLoading = useCallback(() => {
    setIsLoading(true);
    setUseLongTimeout(true);
  }, []);

  const getIsLoading = useCallback(() => {
    const now = new Date().getTime();
    const timeout = useLongTimeout ? loadingTimeout * 10 : loadingTimeout;
    const hasTimedOut = lastLoaderAddedAt + timeout < now;
    const hasLoadedForMinimumTime =
      lastLoaderAddedAt + minimumLoadingTime < now;
    if (!hasLoadedForMinimumTime) {
      return true;
    }

    if (hasTimedOut) {
      return false;
    }

    return loaders.some((l) => l.isLoading);
  }, [lastLoaderAddedAt, useLongTimeout, loaders]);

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    const interval = setInterval(() => {
      const isLoading = getIsLoading();
      setIsLoading(isLoading);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [getIsLoading, isLoading]);

  const removeLoader = (l: Loader) => {
    setLoaders((ls) => ls.filter((loader) => loader.id !== l.id));
  };

  const updateLoader = (l: Loader) => {
    setLoaders(
      loaders.map((loader) => {
        if (loader.id === l.id) {
          return l;
        }
        return loader;
      }),
    );
  };

  return (
    <React.Fragment>
      {isLoading && (
        <FlexCentered style={{ height: '100vh' }}>
          <Loading key={id} isGrouped isFinishedLoading={!isLoading} />
        </FlexCentered>
      )}
      <GroupedLoadingContext.Provider
        value={{
          isLoading,
          addLoader,
          removeLoader,
          updateLoader,
          restartLoading,
        }}
      >
        <motion.div
          animate={
            process.env.NODE_ENV !== 'test' && isLoading ? 'loading' : 'loaded'
          }
          variants={variants}
          style={{
            visibility:
              process.env.NODE_ENV === 'test'
                ? 'visible'
                : isLoading
                  ? 'hidden'
                  : 'visible',
            height: isLoading ? 0 : '100%',
          }}
        >
          {children}
        </motion.div>
      </GroupedLoadingContext.Provider>
    </React.Fragment>
  );
};

export default GroupedLoadingProvider;
