const CUSTOMER_NAMES = [
  'Kellogg Co.',
  'Smithfield Foods Inc.',
  'General Mills Inc. ',
  'Mars Inc.',
  'Cargill Inc. ',
  'Saputo Inc. ',
  'Hormel Foods Corp. ',
  "Pilgrim's Pride",
  'Molson Coors Co.D',
  'Dean Foods Co.',
  'J.M. Smucker Co. ',
  'Dr Pepper Snapple Group',
  'Hershey Co.',
  'TreeHouse Foods Inc.',
  'Danone North America',
  'Bimbo Bakeries USA',
  'Campbell Soup Co. ',
  'Post Holdings Inc. ',
  'Agropur Cooperative ',
  'Perdue Farms Inc. ',
  'Dairy Farmers of America ',
  'Flowers Foods Inc.',
  "Land O'Lakes Inc.",
  'Lactalis American Group',
  'Constellation Brands ',
  'Unilever U.S.',
  'E&J Gallo Winery',
  'Sanderson Farms',
  'Pinnacle Foods',
  'Prairie Farms Dairy Inc. ',
  'California Dairies Inc.',
  'Great Lakes Cheese Co.',
  'Koch Foods Inc.',
  'McCain Foods ',
  'Maple Leaf Foods',
  'Beam Suntory Inc. ',
  'Rich Products Corp.',
  'Foster Farms LLC',
  'American Foods Group LLC ',
  'Schreiber Foods Inc.',
  'Colgate-Palmolive Co.',
  'Wayne Farms LLC',
  'H.P. Hood Inc.',
  'OSI Group',
  'McCormick & Co. Inc. ',
  'Hilmar Cheese Co.',
  'Ferrero USA',
  'Brown-Forman Corp. ',
  'Keystone Foods',
  'Seaboard Corp.',
  'Weston Foods',
  'Premium Brands Holdings Corp.',
  'J. R. Simplot Co. ',
  'Hain Celestial Group ',
  'Associated Milk Producers',
  'Grassland Dairy',
  'B&G Foods',
  'Del Monte Pacific Ltd. ',
  "Schwan's Co.",
  'Triumph Foods',
  'Cal-Maine Foods ',
  'Leprino Foods Co.',
  'McKee Foods Corp.',
  'Hearthside Food Solutions LLC',
  'Seneca Foods Inc. ',
  'Chobani Inc.',
  'Borden Dairy Co.',
  "Reser's Fine Foods",
  'Lancaster Colony Corp. ',
  'CROPP Cooperative/Organic Valley',
  'Johnsonville',
  'J&J Snack Foods ',
  'Darigold ',
  'Golden State Foods',
  'Sargento Foods Inc. ',
  'Wells Enteprises Inc.',
  'American Crystal Sugar Co. ',
  'Lindt & Sprungli',
  'Agri-Mark',
  'National Beverage Corp. ',
  'Bonduelle N.A.',
  'Foremost Farms USA',
  'Boston Beer Co.',
  "Gorton's Seafood ",
  'SugarCreek',
  'Hostess Brands Inc.',
  'Gilster-Mary Lee Corp.',
  'Glanbia USA',
  'Cott Corp.',
  'Monogram Foods LLC',
  'Walmart Inc.',
  'Reyes Holdings',
  'Halliburton Co.',
  'U.S. Foods',
  'Nutrien',
  'McLane Co.',
  'Schlumberger Limited',
  'Performance Food Group',
  'Keurig Dr Pepper',
  'C & J Energy Services',
  'Basic Energy Services',
  'United Rentals Inc.',
  'CHS Inc.',
  'Gordon Food Service',
  'American Air Liquide Holdings',
  'Clean Harbors Inc.',
  'DOT Foods',
  'The Quikrete Cos.',
  'Gibson Energy Inc.',
  'Sunbelt Rentals',
  'FTS International',
  'Core-Mark Holding Co.',
  'Femsa Logistica',
  'Armor-Tile',
  'Atlas EPS',
  'DEWALT',
  'GAF',
  'Grabber',
  'Larsen Products',
  'Meadow Burke',
  'Propex Geotextile Systems',
  'ROCKWOOL ',
  'Sika Scofield',
  'SpecChem',
  'Stego',
  'Tensar International Corporation',
  'Access Tile',
  'ACH Foam',
  'Aco',
  'Acudor',
  'ADA Solutions',
  'Adeka',
  'ADS Pipe',
  'Aervoe',
  'American Gypsum',
  'AMICO',
  'Apoc',
  'Aquafin',
  'ARDEX',
  'Armstrong',
  'ATAS Metal Roofing',
  'Atlas Construction',
  'Atlas Roofing',
  'Backer Rod',
  'BarSplice',
  'Bilco',
  'Blue Ridge Fiberboard',
  'Boise Cascade',
  'BoMetals',
  'Bostik',
  'Brickform',
  'Butterfield Color',
  'Carlisle',
  'Carlisle SynTec',
  'CEMCO',
  'Cerro Flow Products',
  'CertainTeed',
  'CETCO',
  'C.I.M.',
  'ClarkDietrich',
  'Continental Building Products',
  'Crane Composites',
  'CTS Cement / Rapid Set',
  'Curecrete',
  'DAP',
  'Davis Colors',
  'Dayton Concrete Accessories',
  'Dayton Superior',
  'Demolition Technologies',
  'Dow Building Solutions',
  'Dow Corning',
  'Dryvit',
  'DuPont Weatherization Systems',
  'Emseal',
  'Euclid Chemical',
  'Finish Line',
  'Firestone',
  'Fire Trak',
  'Five Star',
  'Form-A-Key',
  'Fortifiber',
  'Fry Reglet',
  'GCP Applied Technologies ',
  'Geocel',
  'Georgia-Pacific',
  'GE Silicones',
  "Glaze 'N Seal",
  'Halsteel',
  'Heckmann Building Products',
  'Henry',
  'Hilti',
  'Hohmann and Barnard',
  'Homasote',
  'Hoover Treated Wood Products',
  'Hydrotech',
  'Inland Coatings',
  'InterWrap',
  'ITP',
  'ITW Buildex',
  'ITW Polymers Coatings',
  'James Hardie',
  'JL Industries',
  'JM',
  'JP Specialties',
  'Karnak',
  'Knauf',
  'LafargeHolcim Ltd',
  'LaHabra',
  'Lambert',
  'L&M Construction Chemicals',
  'Loctite PL',
  'LP',
  'Lyons',
  'MAPEI',
  'MarinoWARE',
  'Marlite',
  'Master Builders Solutions',
  'MBA Building Supplies',
  'Meadows',
  'Metal Sales',
  'Metzger McGuire',
  'MFM',
  'Mueller Industries',
  'Mule-Hide Products',
  'Multicoat Products',
  'National Gypsum',
  'Neogard',
  'Nichiha',
  'Norchem',
  'Nox-Crete',
  'Nudo',
  'OMG ',
  'OSI',
  'Owens Corning',
  'PABCO Gypsum',
  'Parex',
  'Pecora',
  'Phillips',
  'PNA',
  'Poly-America',
  'Polycast',
  'PrimeSource',
  'Propex Fibermesh',
  'PROSOCO',
  'QC Construction Products',
  'QUIKRETE',
  'Radius Track',
  'Ramset',
  'Ram Steel Framing',
  'Raven Industries',
  'RectorSeal',
  'Red Head',
  'Reef Industries',
  'RJD Industries',
  'Rmax',
  'Roadware',
  'Roseburg',
  'Sansoe',
  'Senergy / BASF',
  'Sherwin Williams',
  'Sika',
  'Sika Sarnafil',
  'Sika Waterproofing ',
  'Simpson Anchors',
  'Simpson Strong-Tie',
  'Sinak',
  'Soilworks',
  'Sonoco / Sonotube',
  'Spec Formliners',
  'Spec Mix',
  'SSMA',
  'Steel Structural Products',
  'STI',
  'Sto Corp.',
  'Tamko',
  'Tectum',
  'Telling Industries',
  'TenCate Geosynthetics ',
  'Thermafiber',
  'The STEEL Network',
  'Tree Island',
  'Tremco',
  'Tremco Roofing',
  'TRI-BUILT',
  'Trim-Tex',
  'Trus Joist',
  'UL Systems',
  'Unistrut',
  'USG',
  'USP ',
  'US Spec',
  'Velux',
  'Versico Roofing Systems',
  'Vulcraft',
  'White Cap Private Label',
  'Williams Brothers',
  'Williams Products',
  'Wind-Lock',
  'Wire-Bond',
  'Xypex',
  '3M',
];

export default CUSTOMER_NAMES;
