import { useContext, useEffect, useState } from 'react';
import AccountPickerContext from '../contexts/AccountPickerContext';
import STORE from '../store';

const useRankingMatrixDefinitions = () => {
  const [rankingMatrixDefs, setRankingMatrixDefs] = useState<
    VisualisationDefinitions.RankingMatrix[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const { selectedAccountId } = useContext(AccountPickerContext);

  useEffect(() => {
    setIsLoading(true);

    const listener = STORE.visualisations
      .getRankingMatricesRef({
        accountId: selectedAccountId,
      })
      .orderBy('name')
      .onSnapshot((snapshot) => {
        const data = [] as VisualisationDefinitions.RankingMatrix[];
        snapshot.docs.forEach((d) => data.push(d.data()));
        setRankingMatrixDefs(data);
        setIsLoading(false);
      });
    return () => {
      listener();
    };
  }, [selectedAccountId]);

  return { rankingMatrixDefs, isLoading };
};

export default useRankingMatrixDefinitions;
