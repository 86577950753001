import React from 'react';
import Modal from '@atlaskit/modal-dialog';
import Button from 'kingpin/atoms/Button';
import Typography from 'kingpin/atoms/Typography';
import Row from '../../../Common/Row';
import Inputs from 'components/Inputs';
import Form from '../../../../kingpin/forms/Form';
import FormHeader from '../../../../kingpin/forms/FormHeader';
import FormContent from '../../../../kingpin/forms/FormContent';

const PublishMetricsPopup = ({
  close,
  onMetricToggled,
  onCompoundMetricToggled,
  unpublishedMetrics,
  unpublishedCompoundMetrics,
  getSelectedCount,
  onPublishConfirmed,
  isSaving,
}: {
  close: () => void;
  onMetricToggled: (metric: Metrics.Metric) => void;
  onCompoundMetricToggled: (metric: Metrics.CompoundMetric) => void;
  unpublishedMetrics: {
    metric: Metrics.NormalMetric;
    isConfirmed: boolean;
  }[];
  unpublishedCompoundMetrics: {
    compoundMetric: Metrics.CompoundMetric;
    isConfirmed: boolean;
  }[];
  getSelectedCount: () => number;
  onPublishConfirmed: () => void;
  isSaving: boolean;
}) => (
  <Modal onClose={close} shouldScrollInViewport={false} autoFocus={false}>
    <Form>
      <FormHeader title={'Core Metrics'} onClose={close} />
      <FormContent>
        <Typography.Body type="Body 12">
          Help users find metrics by adding them to the core list inside the
          metric picker
        </Typography.Body>
        {unpublishedMetrics.length > 0 && (
          <div style={{ marginBottom: 16 }}>
            <div style={{ marginBottom: 4 }}>
              <Typography.Body type="Label">Metrics</Typography.Body>
            </div>
            {unpublishedMetrics.map((m) => (
              <Row key={m.metric.id} style={{ marginBottom: 6 }}>
                <div
                  style={{
                    marginRight: 8,
                  }}
                >
                  <Inputs.Checkbox
                    testId={`publish-toggle-${m.metric.id}`}
                    isChecked={m.isConfirmed}
                    onToggled={() => onMetricToggled(m.metric)}
                  />
                </div>

                <Typography.Body type="Body 12">
                  {m.metric.name}
                </Typography.Body>
              </Row>
            ))}
          </div>
        )}

        {unpublishedCompoundMetrics.length > 0 && (
          <div style={{ marginBottom: 16 }}>
            <div style={{ marginBottom: 4 }}>
              <Typography.Body type="Label">Compound Metrics</Typography.Body>
            </div>
            {unpublishedCompoundMetrics.map((cm) => (
              <Row key={cm.compoundMetric.id} style={{ marginBottom: 6 }}>
                <div
                  style={{
                    marginRight: 8,
                  }}
                >
                  <Inputs.Checkbox
                    testId={`publish-toggle-${cm.compoundMetric.id}`}
                    isChecked={cm.isConfirmed}
                    onToggled={() => onCompoundMetricToggled(cm.compoundMetric)}
                  />
                </div>
                <Typography.Body type="Body 12">
                  {cm.compoundMetric.name}
                </Typography.Body>
              </Row>
            ))}
          </div>
        )}

        {unpublishedCompoundMetrics.length === 0 &&
          unpublishedMetrics.length === 0 && (
            <div style={{ marginBottom: 16 }}>
              <Typography.Body type="Body 12">
                Every metric on this report is already in the Core List
              </Typography.Body>
            </div>
          )}
      </FormContent>
      <Row spaceBetween>
        <Button label={'Cancel'} onClick={close} type="Tertiary" size="Small" />
        <Button
          type="Primary"
          size="Small"
          onClick={onPublishConfirmed}
          isDisabled={getSelectedCount() === 0 || isSaving}
          isLoading={isSaving}
          testId={'confirm-publish'}
          label={`Add to Core List (${getSelectedCount()} selected)`}
        />
      </Row>
    </Form>
  </Modal>
);

export default PublishMetricsPopup;
