import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

const updatePerformanceDatasetConfig = async ({
  updateArgs,
  client,
}: {
  updateArgs: PerformanceConfiguration.UpdatePerformanceDatasetArgs;
  client: ApolloClient<NormalizedCacheObject>;
}): Promise<string> => {
  const mutation = {
    mutation: gql`
      mutation updatePerformanceDatasetConfig(
        $id: String!
        $metrics: [PerformanceMetricInput]!
        $fields: [PerformanceFieldsInput!]!
        $entityWindowLength: Int
        $entityWindowDateField: String
      ) {
        updatePerformanceDatasetConfig(
          id: $id
          metrics: $metrics
          fields: $fields
          entityWindowLength: $entityWindowLength
          entityWindowDateField: $entityWindowDateField
        )
      }
    `,
    variables: {
      ...updateArgs,
    },
  };

  const response = await client.mutate(mutation);
  return response.data.updatePerformanceDatasetConfig;
};

export default updatePerformanceDatasetConfig;
