import firebase from 'firebase/compat/app';
import { WALLBOARDS_COLLECTION } from '../constants';

const createWallBoard = async (
  newWallBoard: Wallboard,
  accountRef: firebase.firestore.DocumentReference,
) => {
  return accountRef
    .collection(WALLBOARDS_COLLECTION)
    .doc(newWallBoard.id)
    .set(newWallBoard);
};

export const updateWallBoard = async (
  updatedWallBoard: Wallboard,
  accountRef: firebase.firestore.DocumentReference,
) => {
  return accountRef
    .collection(WALLBOARDS_COLLECTION)
    .doc(updatedWallBoard.id)
    .set(updatedWallBoard);
};

export const deleteWallBoard = async (
  id: string,
  accountRef: firebase.firestore.DocumentReference,
) => {
  return accountRef.collection(WALLBOARDS_COLLECTION).doc(id).delete();
};

export default createWallBoard;
