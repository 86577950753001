import React from 'react';

interface MetricOptionsContextType {
  metricOptions: Metrics.Metric[];
  metricOptionsLookup: { [metricId: string]: Metrics.Metric | undefined };
  metricOptionsNoSpecials: (
    | Metrics.NormalMetric
    | Metrics.SingleUseMetric
    | Metrics.CompoundMetric
  )[];
  normalMetrics: Metrics.NormalMetric[];
  datasetMetricOptions: {
    [dataset: string]:
      | {
          metrics: Metrics.NormalMetric[];
          compoundMetrics: Metrics.CompoundMetric[];
          specialMetrics: Metrics.SpecialMetric[];
        }
      | undefined;
  };
  isLoading: boolean;
}

const MetricOptionsContext = React.createContext<MetricOptionsContextType>({
  metricOptions: [],
  metricOptionsNoSpecials: [],
  normalMetrics: [],
  datasetMetricOptions: {},
  metricOptionsLookup: {},
  isLoading: true,
});

export default MetricOptionsContext;
