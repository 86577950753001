import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import ListItemWrapper from '../../DatasetDefinitions/ListItemWrapper';
import Column from '../../DatasetDefinitions/Column';
import Typography from 'kingpin/atoms/Typography';
import getMetricDescription from '../../../../utils/metrics/getMetricDescription';
import UserFromId from '../../../../components/UserFromId';
import useMetricUsageReport from '../../../../hooks/useMetricUsageReport';
import MetricFiltersBar from '../MetricFiltersBar';
import MetricListStyles from './styles';
import Button from 'kingpin/atoms/Button';
import ContentMenu from './ContentMenu';
import MetricListHeaders from './MetricListHeaders';
import CoreBadge from '../CoreBadge';
import SPECIFIC_SET_ROUTES from '../../SpecificSet/routes';
import useMetricDatasetLabel from '../../../../components/Inputs/MetricPicker/MetricPickerPopup/hooks/useMetricdatasetLabel';
import AnalyticsContext from '../../../../contexts/AnalyticsContext';
import NoMetrics from './NoMetrics';
import LoadingMetrics from './LoadingMetrics';
import ConditionalLink from '../ConditionalLink';
import FleetOpsStaffOnly from '../../../../components/Common/FleetOpsStaffOnly';
import FieldLabelColumn from './FieldLabelColumn';
import DataManagerSpecificSetContext from '../../../../contexts/DataManagerSpecificSetContext';
import { PortalsContext } from '../../../../contextProviders/PortalsProvider';

const DatasetsCol = ({ metric }: { metric: Metrics.CompoundMetric }) => {
  const { selectedPortal } = useContext(PortalsContext);
  const { datasetLabel, datasets } = useMetricDatasetLabel(metric);

  if (datasets.length === 1) {
    return (
      <ConditionalLink
        text={datasetLabel}
        to={SPECIFIC_SET_ROUTES.buildCompoundMetrics(
          datasets[0].type,
          selectedPortal,
        )}
        dataset={datasets[0].type}
      />
    );
  } else {
    return <Typography.Body type="Body 12">{datasetLabel}</Typography.Body>;
  }
};

const MetricListItem = ({
  metric,
  openMetricSlideOut,
  openMetricPopup,
}: {
  metric: Metrics.CompoundMetric;
  openMetricSlideOut: (metric: Metrics.CompoundMetric) => void;
  openMetricPopup: (
    metric: Metrics.NormalMetric | Metrics.CompoundMetric,
  ) => void;
}) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const usageReport = useMetricUsageReport(metric);
  const [usage, setUsage] = useState<string>('');
  useEffect(() => {
    if (usageReport) {
      setUsage(usageReport.usageCount.toString(10));
      return;
    }

    setUsage('');
  }, [usageReport]);

  const onSelected = useCallback(() => {
    openMetricSlideOut(metric);
  }, [metric, openMetricSlideOut]);

  const onEditClicked = useCallback(() => {
    trackEvent('Metrics - Edit Clicked', { metricType: metric.type });
    openMetricPopup(metric);
  }, [metric, openMetricPopup, trackEvent]);

  return (
    <>
      <Column
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          flex: 2,
        }}
      >
        <Typography.Body type="Link" onClick={onSelected}>
          {metric.name}
        </Typography.Body>
        <CoreBadge metric={metric} />
      </Column>
      <Column style={{ flex: 2 }}>
        <Typography.Body type="Body 12">
          {getMetricDescription(metric)}
        </Typography.Body>
      </Column>
      <Column>
        <DatasetsCol metric={metric} />
      </Column>
      <FleetOpsStaffOnly>
        <FieldLabelColumn metric={metric} />
      </FleetOpsStaffOnly>
      <Column>
        <Typography.Body type="Body 12">{usage}</Typography.Body>
      </Column>
      <Column>
        <UserFromId userId={metric.updatedBy} />
      </Column>
      <Column
        style={{ flexDirection: 'row', justifyContent: 'flex-end' }}
        className="showOnMouseOver"
      >
        <div style={{ marginRight: 8 }}>
          <Button
            onClick={onEditClicked}
            label="Edit"
            testId={`edit-${metric.id}`}
            type="Ghost"
            size="Small"
          />
        </div>
        <ContentMenu metric={metric} openMetricPopup={openMetricPopup} />
      </Column>
    </>
  );
};

const MemoizedMetricListItem = memo(MetricListItem);

const CompoundMetricList = ({
  isLoading,
  metrics,
  openMetricSlideOut,
  openMetricPopup,
  onCreateMetricClicked,
}: {
  isLoading: boolean;
  metrics: Metrics.CompoundMetric[];
  openMetricSlideOut: (
    metric: Metrics.NormalMetric | Metrics.CompoundMetric,
  ) => void;
  openMetricPopup: (
    metric: Metrics.NormalMetric | Metrics.CompoundMetric,
  ) => void;
  onCreateMetricClicked: () => void;
}) => {
  const { isOnSpecificSet } = useContext(DataManagerSpecificSetContext);
  return (
    <MetricListStyles.Wrapper>
      <MetricFiltersBar mode="CompoundMetric" />
      <div>
        <MetricListHeaders />
        <MetricListStyles.ListWrapper isSpecificDataSet={isOnSpecificSet}>
          <MetricListStyles.List>
            {metrics.map((m) => (
              <ListItemWrapper key={m.id}>
                <MemoizedMetricListItem
                  metric={m}
                  key={m.id}
                  openMetricSlideOut={openMetricSlideOut}
                  openMetricPopup={openMetricPopup}
                />
              </ListItemWrapper>
            ))}
            {metrics.length === 0 && !isLoading && (
              <NoMetrics onCreateClicked={onCreateMetricClicked} />
            )}
            {metrics.length === 0 && isLoading && <LoadingMetrics />}
          </MetricListStyles.List>
        </MetricListStyles.ListWrapper>
      </div>
    </MetricListStyles.Wrapper>
  );
};

export default CompoundMetricList;
