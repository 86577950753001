import React from 'react';

import Typography from 'kingpin/atoms/Typography';
import Row from '../../Common/Row';
import useUser from '../../../hooks/useUser';
import UserIcon from '../../Common/UserIcon';
import getUserDisplayName from '../../../contextProviders/UsersProvider/getUserDisplayName';
import userTypeCheckers from '../../../contextProviders/UsersProvider/userTypeCheckers';

export const UserRow = ({
  userId,
  noEmail,
}: {
  userId: string;
  noEmail?: boolean;
  isLarge?: boolean;
}) => {
  const { user } = useUser(userId);
  if (user) {
    return (
      <Row centerAlign>
        <div>
          <UserIcon {...user} />
        </div>
        <div>
          <div>
            <Typography.Body type="Body 12">
              {getUserDisplayName(user)}
            </Typography.Body>
          </div>
          {!noEmail && (
            <div>
              <Typography.Body type="Label">
                {userTypeCheckers.isNotWallboardUser(user) ? user.email : ''}
              </Typography.Body>
            </div>
          )}
        </div>
      </Row>
    );
  } else {
    return null;
  }
};
