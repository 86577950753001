import { useCallback, useEffect, useState } from 'react';
import useDateScope from '../../../../hooks/useDateScope';
import moment from 'moment';

const useDays = () => {
  const { startDate, endDate } = useDateScope({});
  const getDays = useCallback(() => {
    if (!startDate || !endDate) {
      return [];
    }
    if (startDate > endDate) {
      return [];
    }

    const newDays: { label: string; date: string }[] = [];
    let current = moment(startDate).format('YYYY-MM-DD');
    while (current <= endDate) {
      newDays.push({
        date: current,
        label: moment(current).format('MM/DD'),
      });

      current = moment.utc(current).add({ day: 1 }).format('YYYY-MM-DD');
    }

    return newDays;
  }, [endDate, startDate]);
  const [days, setDays] = useState<{ label: string; date: string }[]>(() =>
    getDays(),
  );
  useEffect(() => {
    setDays(getDays());
  }, [getDays]);

  return days;
};

export default useDays;
