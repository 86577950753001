import { useCallback, useContext } from 'react';
import _ from 'lodash';
import ScorecardsContext from '../../contexts/ScorecardsContext';
import { isKpiRow } from 'hooks/kpiTypeCheckers';

const useGetScorecardUsage = () => {
  const { scorecards } = useContext(ScorecardsContext);

  const getScorecardUsage = useCallback(
    (
      metric: Metrics.NormalMetric | Metrics.CompoundMetric,
    ): Scorecards.Scorecard[] => {
      const usage = [] as Scorecards.Scorecard[];
      scorecards.forEach((s) => {
        if (s.kpis.filter(isKpiRow).some((k) => k.metricId === metric.id)) {
          usage.push(s);
        }
      });

      return _.uniqBy(usage, 'id');
    },
    [scorecards],
  );

  return getScorecardUsage;
};

export default useGetScorecardUsage;
