import formatDateLabel from '../../components/V5Gadget/formatDateLabel';
import moment from 'moment';

const formatDate = ({
  value,
  dateFormat,
}: {
  value: string | number;
  dateFormat?: string;
}): string => {
  if (!moment(value).isValid()) {
    return String(value);
  }

  switch (dateFormat) {
    case 'MM/DD-DD':
      return formatDateLabel(value, 'week', true);
    case 'MMM/DD/YY HH:mm':
      return moment.utc(value).format('MMM/DD/YY HH:mm');
    case 'MM/DD/YY HH:mm':
      return moment.utc(value).format('MM/DD/YY HH:mm');
    case 'HH:mm':
      return moment.utc(value).format('HH:mm');
    case 'MM/DD/YY':
      return moment.utc(value).format('MM/DD/YY');
    case 'MMM/DD/YY':
    default:
      return moment.utc(value).format('MMM/DD/YY');
  }
};

export default formatDate;
