import { TOP_BAR_HEIGHT } from '../../constants';

const NAVIGATION = {
  TOP_HEIGHT: TOP_BAR_HEIGHT,
  COLLAPSED_NAV_WIDTH: 48,
  OPENED_NAV_WIDTH: 228,
  ITEM_HEIGHT: 32,
};

export default NAVIGATION;
