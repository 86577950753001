import React, { useCallback, useContext } from 'react';
import { Col } from './Kpis';
import ScorecardContext from 'contexts/ScorecardContext';
import { Z_INDEX } from '../../../constants';
import { KPI_HEADER_COL_HEIGHT } from './constants';
import Typography from 'kingpin/atoms/Typography';
import Colors2 from 'theme/Colors2';

const ColumnHeadingsLeft = () => {
  const { setHoveredKpiId } = useContext(ScorecardContext);

  const onKpiMouseEnter = useCallback(() => {
    setHoveredKpiId(undefined);
  }, [setHoveredKpiId]);

  return (
    <Col
      maxWidth
      onMouseEnter={onKpiMouseEnter}
      style={{
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
        zIndex: Z_INDEX.SCORECARD_COLUMN_HEADING,
        padding: '8px 0',
        height: `${KPI_HEADER_COL_HEIGHT + 2}px`,
        borderBottom: `1px solid ${Colors2.GridBorder}`,
        borderTop: `1px solid ${Colors2.GridBorder}`,
      }}
    >
      <div style={{ visibility: 'hidden' }}>
        <Typography.Body type="Body 12">-</Typography.Body>
      </div>
    </Col>
  );
};

export default ColumnHeadingsLeft;
