import useMetricUsageReport from '../../../../../hooks/useMetricUsageReport';
import { useEffect, useState } from 'react';

const usePerformanceConfigurationDependencies = (
  metric?: Metrics.NormalMetric | Metrics.CompoundMetric,
) => {
  const usageReport = useMetricUsageReport(metric);
  const [usedBy, setUsedBy] = useState<{ id: string; name: string }[]>([]);
  useEffect(() => {
    if (!usageReport) {
      setUsedBy([]);
      return;
    }

    setUsedBy(usageReport.performanceConfigurations);
  }, [usageReport]);

  return {
    usageCount: usedBy.length,
    isUsedInPerformanceConfig: usedBy.length > 0,
    usedBy,
  };
};

export default usePerformanceConfigurationDependencies;
