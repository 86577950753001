import React, { ChangeEvent } from 'react';

interface MetricFilteringContextType {
  filteredMetrics: Metrics.NormalMetric[];
  filteredCompoundMetrics: Metrics.CompoundMetric[];
  isLoadingMetrics: boolean;
  isLoadingCompoundMetrics: boolean;
  searchText: string;
  onSearchTextChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  datasetOptions: DropdownOption[];
  usageOptions: DropdownOption[];
  statusOptions: DropdownOption[];
  sortOptions: DropdownOption[];
  filterPlates: { label: string; key: string; onRemoved: () => void }[];
  isDatasetFilterDisabled: boolean;
  isClearButtonVisible: boolean;
  onClearFiltersClicked: () => void;
  onClearDatasetsClicked?: () => void;
  onClearStatusClicked?: () => void;
  onClearUsageClicked?: () => void;
}

const MetricFilteringContext = React.createContext<MetricFilteringContextType>({
  filteredMetrics: [],
  filteredCompoundMetrics: [],
  isLoadingMetrics: true,
  isLoadingCompoundMetrics: true,
  searchText: '',
  onSearchTextChanged: () => {},
  datasetOptions: [],
  usageOptions: [],
  statusOptions: [],
  sortOptions: [],
  filterPlates: [],
  isDatasetFilterDisabled: false,
  isClearButtonVisible: false,
  onClearFiltersClicked: () => {},
  onClearDatasetsClicked: undefined,
  onClearStatusClicked: undefined,
  onClearUsageClicked: undefined,
});

export default MetricFilteringContext;
