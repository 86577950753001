import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

const buildQuery = (costModelId: string) => ({
  query: gql`
    query getIntervalCosts($costModelId: ID!) {
      getIntervalCosts(costModelId: $costModelId) {
        startDate
        endDate
        groupCosts {
          groupDefinition {
            groupName
            fields
          }
          costs {
            costFieldName
            value
          }
          costPerMile {
            costFieldName
            value
          }
          totals {
            totalMiles
            totalDays
          }
        }
      }
    }
  `,
  variables: {
    costModelId,
  },
});

const getIntervalCosts = async (
  costModelId: string,
  client: ApolloClient<NormalizedCacheObject>,
): Promise<Costs.IntervalCosts[]> => {
  const gqlQuery = buildQuery(costModelId);
  const response = await client.query(gqlQuery);
  return response.data.getIntervalCosts;
};

export default getIntervalCosts;
