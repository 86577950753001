import React, { useContext } from 'react';

import GridProvider from '../../../contextProviders/GridProvider';
import Grid from '../../Grid';
import CommitmentQueryContext from '../../../contexts/CommitmentQueryContext';
import CardContext from 'contexts/CardContext';
import BaseViewsContext from '../../../contexts/BaseViewsContext';
import useBoardBaseView from '../../../hooks/useBoardBaseView';
import BoardContext from '../../../contexts/BoardContext';
import useWindowSize from '../../../hooks/useWindowSize';
import { CUSTOMER_LANE_COMMITMENTS } from '../../../constants';
import isPerformanceBoard from '../../../isPerformanceBoard';

const GridContainer = ({ baseView }: { baseView: FleetOps.BaseView }) => {
  const { isMobile } = useWindowSize();
  const { board } = useContext(BoardContext);
  const { getContextMenuItems } = useContext(CommitmentQueryContext);
  const newBaseView = useBoardBaseView(baseView);

  if (isPerformanceBoard(board)) {
    return null;
  }

  return (
    <CardContext.Provider
      value={{
        i: '',
        w: 1,
        h: 1,
      }}
    >
      <GridProvider
        dataType={CUSTOMER_LANE_COMMITMENTS}
        baseViewOverride={newBaseView}
      >
        <Grid
          isBoard
          getContextMenuItems={getContextMenuItems}
          layoutOnFirstRender={true}
          layoutOnColumnChange={false}
          layoutOnModelUpdated={false}
          autoSizeSecondColumnOnly={board.isSizedToFitOverridden && !isMobile}
          hasBorders={true}
          hasDarkHeader={true}
        />
      </GridProvider>
    </CardContext.Provider>
  );
};

const Gate = () => {
  const { baseViews } = useContext(BaseViewsContext);
  const baseView = baseViews[CUSTOMER_LANE_COMMITMENTS];

  if (baseView) {
    return <GridContainer baseView={baseView} />;
  } else {
    return null;
  }
};

export default Gate;
