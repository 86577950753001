const isDriverPerformanceBoard = (
  board:
    | PerformanceBoardTypes.Board
    | DriverPerformanceBoard
    | CustomerLaneBoard
    | GeneralBoard
    | undefined,
): board is DriverPerformanceBoard => {
  return !!board && board.category === 'Driver Performance';
};

export default isDriverPerformanceBoard;
