import React from 'react';
import Row from 'components/Common/Row';
import Inputs from 'components/Inputs';
import Typography from 'kingpin/atoms/Typography';

const ActiveFilterToggle = ({
  isActiveFilterDisabled,
  setIsActiveFilterDisabled,
}: {
  isActiveFilterDisabled: boolean;
  setIsActiveFilterDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <Row centerAlign>
      <div style={{ marginRight: 8 }}>
        <Inputs.Toggle
          value={isActiveFilterDisabled}
          onChange={setIsActiveFilterDisabled}
          testId={`toggle-active`}
        />
      </div>
      <Typography.Body type="Label">Show Inactive</Typography.Body>
    </Row>
  );
};

export default ActiveFilterToggle;
