import React from 'react';

interface CommentsContextType {
  isLoading: boolean;
  comments: CommentType[];
  commentableId: string;
  commentableType: CommentableType;
  linkTo?: string;
  watchers: string[];
}

const CommentsContext = React.createContext<CommentsContextType>(
  {} as CommentsContextType,
);

export default CommentsContext;
