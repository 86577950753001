import Badge from '../../../../components/Badge';
import React from 'react';

const UpdatingBadge = () => (
  <div style={{ marginLeft: 8 }}>
    <Badge
      isLoading
      text={'Dataset updating - this may take a while'}
      badgeType={'Warning'}
    />
  </div>
);

export default UpdatingBadge;
