import { addBreadCrumb } from '../services/captureException';

const getCurrentBuildNumber = async () => {
  try {
    const request = await fetch(`${window.location.origin}/version.json`);

    if (request.ok) {
      const data = await request.json();
      const buildId = data.buildId as string;
      return {
        n: Number.parseInt(buildId, 10),
        builtOn: data.builtOn as string,
      };
    } else {
      return undefined;
    }
  } catch (ex) {
    addBreadCrumb({
      type: 'Failed to get latest version.json',
      message: ex.message,
      data: ex,
    });
    return undefined;
  }
};

export default getCurrentBuildNumber;
