import { useContext, useEffect, useState } from 'react';
import getUploadDataTypes from '../../api/getUploadDataTypes';
import CloudFunctionClientContext from 'contexts/CloudFunctionClientContext';
import FeatureGatesContext from '../../../../../../contexts/FeatureGatesContext';

const useGetUploadDataTypes = () => {
  const { isCustomerAwardsTrackerEnabled } = useContext(FeatureGatesContext);
  const { api } = useContext(CloudFunctionClientContext);
  const [dataTypes, setDataTypes] = useState<DataManager.UploadDataType[]>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isActive = true;
    setIsLoading(true);

    getUploadDataTypes(api).then((response) => {
      if (!isActive) {
        return;
      }
      if (response.data === undefined) {
        setDataTypes(undefined);
        return;
      }

      const filteredTypes = response.data.filter((t) => {
        if (isCustomerAwardsTrackerEnabled) {
          return true;
        }

        return t.dataType !== 'customerLaneAwards';
      });
      const sortedFilteredTypes = filteredTypes.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      setDataTypes(sortedFilteredTypes);
      setIsLoading(false);
    });

    return () => {
      isActive = false;
    };
  }, [api, isCustomerAwardsTrackerEnabled]);

  return {
    dataTypes,
    isLoading,
  };
};

export default useGetUploadDataTypes;
