import { useCallback, useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import ReportDrillDownFormContext from '../../../contexts/ReportDrillDownFormContext';
import ReportDrillDownsContext from '../../../contexts/ReportDrillDownsContext';

const useContextualDataTypes = () => {
  const { dataset } = useContext(ReportDrillDownFormContext);
  const { dataTypes: contextDataTypes } = useContext(ReportDrillDownsContext);

  const getContextualDataTypes = useCallback((): string[] => {
    const currentContext = contextDataTypes || [];

    if (dataset) {
      return _.uniq([...currentContext, dataset]);
    }
    return currentContext;
  }, [contextDataTypes, dataset]);

  const [contextualDataTypes, setContextualDataTypes] = useState<string[]>(() =>
    getContextualDataTypes(),
  );

  useEffect(() => {
    setContextualDataTypes(getContextualDataTypes());
  }, [getContextualDataTypes]);

  return contextualDataTypes;
};

export default useContextualDataTypes;
