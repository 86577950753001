import { useContext, useEffect, useState } from 'react';
import AccountPickerContext from '../contexts/AccountPickerContext';

const useCommitments = (type: CommitmentType) => {
  const [commitments, setCommitments] = useState<Commitment[]>([]);
  const { accountRef } = useContext(AccountPickerContext);

  useEffect(() => {
    if (process.env.NODE_ENV === 'test') {
      return;
    }

    const listener = accountRef
      .collection('commitments')
      .onSnapshot((snapshot: any) => {
        const data = [] as Commitment[];
        snapshot.docs.forEach((d: any) =>
          data.push({
            id: d.id,
            ...d.data(),
          } as Commitment),
        );
        setCommitments(data);
      });
    return () => {
      listener();
    };
  }, [accountRef, type]);

  return commitments;
};

export default useCommitments;
