import React from 'react';
import Typography from 'kingpin/atoms/Typography';

const CanvasCardTitle = ({ title }: { title: string }) => {
  return (
    <Typography.Header type="H6" isEllipsis>
      {title}
    </Typography.Header>
  );
};

export default CanvasCardTitle;
