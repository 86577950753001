import { useCallback, useContext, useEffect, useState } from 'react';
import EntityDefinitionsContext from 'contexts/EntityDefinitionsContext';

const useIsEnabled = () => {
  const { entityDefinitions } = useContext(EntityDefinitionsContext);
  const getIsEnabled = useCallback(() => {
    return entityDefinitions.some((e) => e.isEnabled);
  }, [entityDefinitions]);
  const [isEnabled, setIsEnabled] = useState<boolean>(getIsEnabled);
  useEffect(() => {
    setIsEnabled(getIsEnabled());
  }, [getIsEnabled]);

  return isEnabled;
};

export default useIsEnabled;
