import isNormalMetric from './isNormalMetric';
import isCompoundMetric from './isCompoundMetric';
import isSpecialMetric from './isSpecialMetric';

const isMetric = (
  m: ExtendedVisualisationDefinition | Metrics.Metric,
): m is Metrics.Metric => {
  if (!m) {
    return false;
  }

  if (isNormalMetric(m) || isCompoundMetric(m) || isSpecialMetric(m)) {
    return true;
  }

  return false;
};

export default isMetric;
