import { useContext, useEffect, useState } from 'react';
import EntityDefinitionsContext from '../../../contexts/EntityDefinitionsContext';

const useEnabledEntities = () => {
  const { entityDefinitions } = useContext(EntityDefinitionsContext);
  const [enabledEntities, setEnabledEntities] = useState<string[]>([]);
  useEffect(() => {
    setEnabledEntities(
      entityDefinitions.filter((e) => e.isEnabled).map((e) => `${e.name}s`),
    );
  }, [entityDefinitions]);

  return enabledEntities;
};

export default useEnabledEntities;
