import React from 'react';
import styled from 'styled-components';
import Typography from 'kingpin/atoms/Typography';
import Colors2 from '../../../../../theme/Colors2';

const GroupOption = styled.div`
  padding: 8px 0px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
    background-color: ${Colors2.Grey['9']};
  }
`;

const GroupOptionsList = ({
  options,
  hasSearchText,
}: {
  options: DropdownOption[];
  hasSearchText: boolean;
}) => (
  <div
    style={{
      maxHeight: 400,
      overflowY: 'scroll',
    }}
  >
    {hasSearchText && (
      <div>
        <Typography.Body type="Body 12">
          {`${options.length} Match${options.length > 1 ? 's' : ''} Found`}
        </Typography.Body>
      </div>
    )}
    {options.map((group) => (
      <GroupOption key={group.value} onClick={group.onSelected}>
        <div>
          <Typography.Body type="Body 12">{`${group.label} `}</Typography.Body>
        </div>
        {group.value && (
          <div>
            <Typography.Body type="Body 12">{group.value}</Typography.Body>
          </div>
        )}
      </GroupOption>
    ))}
  </div>
);

export default GroupOptionsList;
