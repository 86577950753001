import React, { useContext } from 'react';

import Grid from '../Grid';
import ExportButton from '../Grid/ExportButton';
import GridContext from '../../contexts/GridContext';
import toSentenceCase from '../../services/toSentenceCase';
import Form from 'kingpin/forms/Form';
import FormHeader from '../../kingpin/forms/FormHeader';
import styled from 'styled-components';

const PopupGridStyles = styled.div`
  flex: 1;
`;

const TopBar = ({ close }: { close: () => void }) => {
  const { titleOverride, dataType } = useContext(GridContext);
  const title = titleOverride ? titleOverride : toSentenceCase(`${dataType}`);

  return (
    <FormHeader
      title={title}
      onClose={close}
      titleExtra={
        <div style={{ marginLeft: 16 }}>
          <ExportButton />
        </div>
      }
    />
  );
};

const PopupGrid = ({ close }: { close: () => void }) => (
  <Form
    style={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    }}
    onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
      event.stopPropagation()
    }
  >
    <TopBar close={close} />
    <PopupGridStyles>
      <Grid
        isPopupGrid
        layoutOnFirstRender={false}
        layoutOnColumnChange={false}
        layoutOnModelUpdated={true}
        hasBorders={true}
        hasDarkHeader={true}
      />
    </PopupGridStyles>
  </Form>
);

export default PopupGrid;
