import React from 'react';
import styled, { css } from 'styled-components';
import Typography from 'kingpin/atoms/Typography';
import Colors from '../../theme/colors';

const centerCss = css`
  align-self: center;
  text-align: center;
`;

const Current = styled.div<{ center?: boolean }>`
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${Colors.TEXT};
  font-weight: 700;
  ${(props) => props.center && centerCss};

  ${(props) =>
    !!props.color &&
    `
      color: ${props.color}
    `}
`;

const ScalingNumber = ({
  formattedNumber,
  center,
  color,
}: {
  formattedNumber: string;
  center?: boolean;
  color?: string;
}) => (
  <Current center={center} color={color}>
    <Typography.Header type={'H2'}>{formattedNumber}</Typography.Header>
  </Current>
);

export default ScalingNumber;
