import React, { useState } from 'react';
import MetricList from './MetricList';
import { DropResult } from 'react-beautiful-dnd';
import usePopup from '../../../../hooks/usePopup';

const MetricListContainer = ({
  metricList,
  setMetricList,
  metricListOrder,
  setMetricListOrder,
}: {
  metricList: MetricListItemType[];
  setMetricList: React.Dispatch<React.SetStateAction<MetricListItemType[]>>;
  metricListOrder: string[];
  setMetricListOrder: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const {
    isOpen: isMetricEditOpen,
    open: openMetricEdit,
    close: closeMetricEdit,
  } = usePopup();
  const {
    isOpen: isConfirmationOpen,
    open: openConfirmation,
    close: closeConfirmation,
  } = usePopup();
  const [removingItemId, setRemovingItemId] = useState<string | undefined>();
  const [editingMetric, setEditingMetric] = useState<
    MetricListItemType | undefined
  >();

  const startEditingMetric = (metricItem: MetricListItemType) => {
    setEditingMetric(metricItem);
    openMetricEdit();
  };

  const onDragEnd = (result: DropResult) => {
    const { draggableId, destination } = result;
    if (!destination) {
      return;
    }

    const itemsWithoutDropped = metricListOrder.filter(
      (metricItem) => metricItem !== draggableId,
    );
    const theItem = metricListOrder.find(
      (metricItemId) => metricItemId === draggableId,
    );
    if (!theItem) {
      throw new Error('Item not found');
    }

    const newOrder = [
      ...itemsWithoutDropped.slice(0, destination.index),
      draggableId,
      ...itemsWithoutDropped.slice(
        destination.index,
        itemsWithoutDropped.length,
      ),
    ];

    setMetricListOrder(newOrder);
  };

  const onRemoveItemConfirmed = () => {
    if (removingItemId) {
      setMetricList((currentList) =>
        currentList.filter((m) => m.id !== removingItemId),
      );
      setMetricListOrder((currentList) =>
        currentList.filter((mId) => mId !== removingItemId),
      );
      closeConfirmation();
    }
  };

  const onRemoveItemClicked = (itemId: string) => {
    openConfirmation();
    setRemovingItemId(itemId);
  };

  const onRemoveItemCanceled = () => {
    closeConfirmation();
    setRemovingItemId(undefined);
  };

  const orderedMetricList = metricListOrder
    .map((id) => {
      const m = metricList.find((metric) => metric.id === id);
      return m;
    })
    .filter((m) => !!m) as MetricListItemType[];

  return (
    <MetricList
      onDragEnd={onDragEnd}
      metricList={orderedMetricList}
      metricBeingRemoved={metricList.find((m) => m.id === removingItemId)}
      isRemovingMetric={isConfirmationOpen}
      onRemoveItemClicked={onRemoveItemClicked}
      onRemoveItemConfirmed={onRemoveItemConfirmed}
      onRemoveItemCanceled={onRemoveItemCanceled}
      editingMetric={editingMetric}
      startEditingMetric={startEditingMetric}
      closeMetricEdit={closeMetricEdit}
      isMetricEditOpen={isMetricEditOpen}
      setMetricList={setMetricList}
    />
  );
};

export default MetricListContainer;
