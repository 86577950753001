import { useCallback, useContext, useState } from 'react';
import TargetFormContext from '../contexts/TargetFormContext';

const useIsGlowOnMount = (group: Targets.Wizard.TargetGroup) => {
  const { editingTarget } = useContext(TargetFormContext);
  const getIsGlowOnMount = useCallback(() => {
    if (
      editingTarget &&
      editingTarget.groups.some((g) => g.key === group.key)
    ) {
      return false;
    }

    return true;
  }, [editingTarget, group.key]);
  const [isGlowOnMount] = useState<boolean>(() => getIsGlowOnMount());

  return isGlowOnMount;
};

export default useIsGlowOnMount;
