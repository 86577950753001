import React from 'react';
import Typography from 'kingpin/atoms/Typography';
import FleetopsButton from 'kingpin/atoms/Button';
import Colors from '../../../theme/colors';
import CloseImage from '../../../images/close.svg';

import {
  Container,
  Header,
  RowSection,
  Button,
  Profiler,
  ReadStatusContainer,
  ReadStatusInner,
  Body,
  Content,
  Footer,
  Tooltip,
  TooltipText,
  ClearButton,
  CloseIcon,
} from './styles';
import Colors2 from '../../../theme/Colors2';

const Notification = ({
  selected,
  selectNotification,
  onMouseOutNotification,
  onMouseInNotification,
  titleText,
  showingClear,
  clearNotification,
  onMouseOutClear,
  onMouseInClear,
  mouseOverClear,
  read,
  markAsRead,
  toggleIsRead,
  onMouseOutReadStatus,
  onMouseInReadStatus,
  mouseOverReadStatus,
  contentText,
  linkText,
  timeAgoText,
  onLinkClicked,
}: {
  selected: boolean;
  selectNotification: () => void;
  onMouseOutNotification: () => void;
  onMouseInNotification: () => void;
  titleText: string;
  showingClear: boolean;
  clearNotification: () => void;
  onMouseOutClear: () => void;
  onMouseInClear: () => void;
  mouseOverClear: boolean;
  read: boolean;
  markAsRead: () => void;
  toggleIsRead: () => void;
  onMouseOutReadStatus: () => void;
  onMouseInReadStatus: () => void;
  mouseOverReadStatus: boolean;
  contentText?: string;
  linkText: string;
  timeAgoText: string;
  onLinkClicked: () => void;
}) => (
  <Container
    id="Notification"
    selected={selected}
    onClick={selectNotification}
    onBlur={onMouseOutNotification}
    onMouseLeave={onMouseOutNotification}
    onFocus={onMouseInNotification}
    onMouseEnter={onMouseInNotification}
  >
    <Header>
      <RowSection style={{ overflow: 'hidden' }}>
        <Profiler color={Colors.NAVY_BLUE} />
        <Typography.Body type={'Body 14'} isEllipsis>
          {titleText}
        </Typography.Body>
      </RowSection>
      <RowSection>
        {showingClear && (
          <Button>
            <ClearButton
              onClick={(
                event: React.MouseEvent<HTMLDivElement, MouseEvent>,
              ) => {
                event.stopPropagation();
                clearNotification();
              }}
              onBlur={onMouseOutClear}
              onFocus={onMouseInClear}
              onMouseEnter={onMouseInClear}
              onMouseLeave={onMouseOutClear}
            >
              <CloseIcon src={CloseImage} />
            </ClearButton>
            <Tooltip>
              <TooltipText visible={mouseOverClear} bottom>
                Clear
              </TooltipText>
            </Tooltip>
          </Button>
        )}
        <Button>
          <ReadStatusContainer
            onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              event.stopPropagation();
              toggleIsRead();
            }}
            onBlur={onMouseOutReadStatus}
            onFocus={onMouseInReadStatus}
            onMouseEnter={onMouseInReadStatus}
            onMouseLeave={onMouseOutReadStatus}
          >
            <ReadStatusInner read={read} />
          </ReadStatusContainer>
          <Tooltip>
            <TooltipText visible={mouseOverReadStatus}>
              {read && 'Mark as un-read'}
              {!read && 'Mark as read'}
            </TooltipText>
          </Tooltip>
        </Button>
      </RowSection>
    </Header>
    <Body>
      <Content>
        <Typography.Body type={'Body 14'} isEllipsis={!selected}>
          {contentText}
        </Typography.Body>
      </Content>
      <Footer>
        <RowSection style={{ justifyContent: 'space-between', width: '100%' }}>
          <div style={{ marginRight: 4 }}>
            <FleetopsButton
              type={'Secondary'}
              size={'Small'}
              label={linkText}
              onClick={(
                event: React.MouseEvent<HTMLDivElement, MouseEvent>,
              ) => {
                event.stopPropagation();
                markAsRead();
                onLinkClicked();
              }}
            />
          </div>
          <div style={{ display: 'flex' }}>
            <Typography.Body type={'Body 12'} color={Colors2.Grey['5']}>
              {timeAgoText}
            </Typography.Body>
          </div>
        </RowSection>
      </Footer>
    </Body>
  </Container>
);

export default Notification;
