import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import _ from 'lodash';

import RelativeInput from './RelativeInput';
import DateInputContext from '../../../contexts/DateInputContext';
import {
  CURRENT_QUARTER,
  LAST_30_DAYS,
  LAST_MONTH,
  MAX_WIDTH_CURRENT_DATE_RANGE,
  MAX_WIDTH_NON_CURRENT_DATE_RANGE,
  NEXT_WEEK,
  YEAR_TO_DATE,
  YESTERDAY,
} from '../constants';
import createRecentlyUsedRollingWindow from './createRecentlyUsedRollingWindow';
import CurrentUserContext from '../../../contexts/CurrentUserContext';
import { DateTime } from 'luxon';
import AnalyticsContext from '../../../contexts/AnalyticsContext';
import useDateScope from '../../../hooks/useDateScope';
import AccountPickerContext from '../../../contexts/AccountPickerContext';
import isAdvancedRollingWindow from './isAdvancedRollingWindow';
import PeriodsContext from '../../../contexts/PeriodsContext';

const buildLabel = (
  window: RelativeDateRange | AdvancedRelativeDateRange,
): string => {
  if (isAdvancedRollingWindow(window)) {
    return `${buildLabel(window.from)} -> ${buildLabel(window.to)}`;
  } else {
    const base = (() => {
      if (window.n && window.n > 1) {
        return `${_.startCase(window.type)} ${window.n} ${_.startCase(
          window.interval,
        )}s`;
      }

      return `${_.startCase(window.type)} ${_.startCase(window.interval)}`;
    })();

    if (window.currentToDate) {
      if (window.type === 'current') {
        return `${base} (to date)`;
      } else {
        const postfix = (() => {
          switch (window.interval) {
            case 'day':
              return '(include today)';
            case 'week':
              return '(include this week)';
            case 'month':
              return '(include this month)';
            case 'quarter':
              return '(include this quarter)';
            case 'year':
              return '(include this year)';
            default:
              return '';
          }
        })();
        return `${base} ${postfix}`;
      }
    } else {
      return base;
    }
  }
};

const RelativeInputContainer = ({
  updateDialogLayout,
}: {
  updateDialogLayout: () => void;
}) => {
  const {
    setDateRange,
    relativeDateRange,
    advancedRelativeDateRange,
    setRelativeDateRange,
    setAdvancedRelativeDateRange,
    recentlyUsedRollingWindows,
    setIsManuallyEntered,
  } = useContext(DateInputContext);
  const { setSelectedPeriod } = useContext(PeriodsContext);
  const { startDate, endDate } = useDateScope({});
  const { trackEvent } = useContext(AnalyticsContext);
  const newRecentRef = useRef<RelativeDateRange | AdvancedRelativeDateRange>();
  const { id: userId } = useContext(CurrentUserContext);
  const { accountRef } = useContext(AccountPickerContext);
  const lastRollingRef = useRef<RelativeDateRange>(
    relativeDateRange ? relativeDateRange : LAST_30_DAYS,
  );
  const lastAdvancedRef = useRef<AdvancedRelativeDateRange>(
    advancedRelativeDateRange
      ? advancedRelativeDateRange
      : { from: LAST_30_DAYS, to: NEXT_WEEK },
  );
  const [mode, setMode] = useState<'rolling' | 'advanced' | 'custom'>(() =>
    relativeDateRange
      ? 'rolling'
      : advancedRelativeDateRange
        ? 'advanced'
        : 'custom',
  );

  useEffect(() => {
    setMode(
      relativeDateRange
        ? 'rolling'
        : advancedRelativeDateRange
          ? 'advanced'
          : 'custom',
    );
  }, [advancedRelativeDateRange, relativeDateRange]);

  useEffect(() => {
    updateDialogLayout();
  }, [updateDialogLayout]);

  const getIsValid = useCallback(
    (dateRange: RelativeDateRange | AdvancedRelativeDateRange) => {
      const isRangeValid = (range: RelativeDateRange) => {
        if (range.type === 'current') {
          return true;
        }

        return range.n !== undefined;
      };
      if (isAdvancedRollingWindow(dateRange)) {
        const isToValid = isRangeValid(dateRange.to);
        const isFromValid = isRangeValid(dateRange.from);
        return isToValid && isFromValid;
      }

      return isRangeValid(dateRange);
    },
    [],
  );

  useEffect(() => {
    return () => {
      if (newRecentRef.current && getIsValid(newRecentRef.current)) {
        if (isAdvancedRollingWindow(newRecentRef.current)) {
          trackEvent('DateInput - Advanced Rolling Window Applied', {
            label: buildLabel(newRecentRef.current),
          });
        } else {
          trackEvent('DateInput - Basic Rolling Window Applied', {
            label: buildLabel(newRecentRef.current),
          });
        }
        createRecentlyUsedRollingWindow({
          accountRef,
          userId,
          window: {
            value: newRecentRef.current,
            usedOn: DateTime.utc().toISO(),
          },
        });
      }
    };
  }, [accountRef, getIsValid, trackEvent, userId]);

  const isInvalid = !!startDate && !!endDate && startDate > endDate;

  const updateRelativeDate = useCallback(
    (newRel: RelativeDateRange) => {
      setRelativeDateRange(newRel);
      setAdvancedRelativeDateRange(undefined);
      setDateRange(undefined);
      newRecentRef.current = newRel;
      lastRollingRef.current = newRel;
      setIsManuallyEntered(true);
      setSelectedPeriod(undefined);
    },
    [
      setAdvancedRelativeDateRange,
      setDateRange,
      setIsManuallyEntered,
      setRelativeDateRange,
      setSelectedPeriod,
    ],
  );

  const updateAdvancedDate = useCallback(
    (newAdv: AdvancedRelativeDateRange) => {
      setAdvancedRelativeDateRange(newAdv);
      setRelativeDateRange(undefined);
      setDateRange(undefined);
      newRecentRef.current = newAdv;
      lastAdvancedRef.current = newAdv;
      setIsManuallyEntered(true);
    },
    [
      setAdvancedRelativeDateRange,
      setDateRange,
      setIsManuallyEntered,
      setRelativeDateRange,
    ],
  );

  const buildQuickOptions = useCallback(() => {
    const base = recentlyUsedRollingWindows.map((rw) => ({
      label: buildLabel(rw.value),
      onClick: () => {
        if (isAdvancedRollingWindow(rw.value)) {
          updateAdvancedDate(rw.value);
        } else {
          updateRelativeDate(rw.value);
        }
      },
      isSelected: isAdvancedRollingWindow(rw.value)
        ? _.isEqual(rw.value, advancedRelativeDateRange)
        : _.isEqual(rw.value, relativeDateRange),
    }));

    // Default options
    const defaultOptions = [
      {
        label: 'Yesterday',
        onClick: () => {
          updateRelativeDate(YESTERDAY);
        },
        isSelected:
          !!relativeDateRange && _.isEqual(relativeDateRange, YESTERDAY),
      },
      {
        label: 'Last Month',
        onClick: () => {
          updateRelativeDate(LAST_MONTH);
        },
        isSelected:
          !!relativeDateRange && _.isEqual(relativeDateRange, LAST_MONTH),
      },
      {
        label: 'Year to Date',
        onClick: () => {
          updateRelativeDate(YEAR_TO_DATE);
        },
        isSelected:
          !!relativeDateRange && _.isEqual(relativeDateRange, YEAR_TO_DATE),
      },
      {
        label: 'Current Quarter',
        onClick: () => {
          updateRelativeDate(CURRENT_QUARTER);
        },
        isSelected:
          !!relativeDateRange && _.isEqual(relativeDateRange, CURRENT_QUARTER),
      },
      {
        label: 'Next Week',
        onClick: () => {
          updateRelativeDate(NEXT_WEEK);
        },
        isSelected:
          !!relativeDateRange && _.isEqual(relativeDateRange, NEXT_WEEK),
      },
    ];
    const numberToAdd = 5 - base.length;
    for (let i = 0; i < numberToAdd; i++) {
      base.push(defaultOptions[i]);
    }
    return base;
  }, [
    advancedRelativeDateRange,
    recentlyUsedRollingWindows,
    relativeDateRange,
    updateAdvancedDate,
    updateRelativeDate,
  ]);

  const [quickOptions, setQuickOptions] = useState(() => buildQuickOptions());

  useEffect(() => {
    setQuickOptions(buildQuickOptions);
  }, [buildQuickOptions]);

  const maxWidth = relativeDateRange
    ? relativeDateRange.type === 'current'
      ? MAX_WIDTH_CURRENT_DATE_RANGE
      : MAX_WIDTH_NON_CURRENT_DATE_RANGE
    : undefined;

  return (
    <RelativeInput
      quickOptions={quickOptions}
      updateRelativeDate={updateRelativeDate}
      updateAdvancedDate={updateAdvancedDate}
      advancedRelativeDate={advancedRelativeDateRange}
      relativeDate={relativeDateRange}
      mode={mode}
      setMode={setMode}
      lastRollingRef={lastRollingRef}
      lastAdvancedRef={lastAdvancedRef}
      isInvalid={isInvalid}
      maxWidth={maxWidth}
    />
  );
};

export default RelativeInputContainer;
