import isV5ChartDef from './isV5ChartDef';

const isRemindersGadget = (
  def?: ExtendedVisualisationDefinition,
): def is VisualisationDefinitions.Reminders => {
  if (isV5ChartDef(def)) {
    return false;
  }

  return !!def && def.type === 'Reminders';
};

export default isRemindersGadget;
