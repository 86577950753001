import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import MetricForm from './MetricForm';
import CurrentUserContext from '../../../../../../contexts/CurrentUserContext';
import DatasetDefinitionsContext from '../../../../../../contexts/DatasetDefinitionsContext';
import AnalyticsContext from '../../../../../../contexts/AnalyticsContext';
import DateInputContext from '../../../../../../contexts/DateInputContext';
import fiToDrillDowns from './fiToDrillDowns';
import getTimeStamp from '../../../../../../getTimeStamp';
import ReportDrillDownsProvider from '../../../../../../contextProviders/ReportDrillDownsProvider';
import ReactPortal from '../../../../../../components/ReactPortal';
import MetricPopupConstants from '../../constants';
import Preview from '../../Preview';
import MetricActions from '../../MetricActions';
import buildFilterInput from '../../../../../../utils/buildFilterInput';
import withoutNulls from '../../../../../../api/search/withoutNulls';
import useDataSourceOptions from './useDataSourceOptions';
import useFieldOptions from './useFieldOptions';
import useAggFuncOptions from './useAggFuncOptions';
import useFieldDef from './useFieldDef';
import CoreToggle from '../CoreToggle';
import SingleUseMetricPopupContext from '../../../../../../contexts/SingleUseMetricPopupContext';
import useEditingState from '../../MetricActions/useEditingState';
import InSituMetricEditorContext from '../../../../../../contexts/InSituMetricEditorContext';
import filterPlateTypeCheckers from 'types/filterPlateTypeCheckers';
import DatasetFiltersContext from 'screens/DataManager/DatasetFilters/context/DatasetFiltersContext';
import { datasetFilterToPlate } from 'screens/DataManager/DatasetFilters/providers/DatasetFiltersProvider';
import useToDrillDowns from 'components/Report/useToReportDrillDown';

const toPlates = (drillDowns: ReportDrillDownType[]): FilterPlate[] => {
  return drillDowns.map((d) => ({
    id: d.id,
    type: 'Fixed',
    fixedValue: d,
  }));
};

const MetricFormContainer = ({
  id,
  selectedMetric,
  forcedDataset,
  showFlash,
  setHasUnsavedChanges,
}: {
  id: string;
  selectedMetric?: Metrics.NormalMetric;
  forcedDataset?: string;
  showFlash: (message: string) => void;
  setHasUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { isInSituEditor } = useContext(InSituMetricEditorContext);
  const { isSingleUsePopup } = useContext(SingleUseMetricPopupContext);
  const currentUser = useContext(CurrentUserContext);
  const { datasets } = useContext(DatasetDefinitionsContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const { dataTypes, setDataTypes } = useContext(DateInputContext);
  const { getDefaultDatasetFilterPlates, datasetFilters } = useContext(
    DatasetFiltersContext,
  );
  const toDrillDowns = useToDrillDowns();

  const [name, setName] = useState<string>(
    selectedMetric ? selectedMetric.name : '',
  );
  const [description, setDescription] = useState<string>(
    selectedMetric && selectedMetric.description
      ? selectedMetric.description
      : '',
  );
  const [status, setStatus] = useState<Metrics.MetricStatus>(
    selectedMetric ? selectedMetric.status : 'public',
  );
  const [formatting, setFormatting] = useState<MetricFormatting>(
    selectedMetric && selectedMetric.formatting
      ? selectedMetric.formatting
      : {},
  );
  const [dataSource, setDataSource] = useState<string | undefined>(
    selectedMetric ? selectedMetric.dataType : forcedDataset,
  );
  const [field, setField] = useState<string>(
    selectedMetric ? selectedMetric.field : '',
  );
  const fieldDef = useFieldDef({ field, selectedMetric });
  const [aggFunc, setAggFunc] = useState<AggFunc | undefined>(
    selectedMetric ? selectedMetric.aggFunc : undefined,
  );
  const [drillDowns, setDrillDowns] = useState<FilterPlate[]>(() =>
    selectedMetric
      ? [
          ...toPlates(fiToDrillDowns(selectedMetric.filters, datasets)),
          ...datasetFilters
            .filter((df) => selectedMetric.datasetFilterIds?.includes(df.id))
            .map(datasetFilterToPlate),
        ]
      : forcedDataset
        ? getDefaultDatasetFilterPlates({ dataset: forcedDataset })
        : [],
  );

  const [filterInput, setFilterInput] = useState<FilterInput>(() =>
    buildFilterInput({
      scopes: [],
      drillDowns: toDrillDowns({
        plates: drillDowns.filter(filterPlateTypeCheckers.isNotDataset),
        variableDrillDowns: [],
      }),
    }),
  );

  const [isValid, setIsValid] = useState<boolean>(false);
  const [metricDraft, setMetricDraft] = useState<
    Metrics.NormalMetric | undefined
  >();
  const editingState = useEditingState({ selectedMetric, metricDraft });
  useEffect(() => {
    if (selectedMetric) {
      setHasUnsavedChanges(
        editingState !== 'no changes' &&
          editingState !== 'no changes - archived',
      );
      return;
    }

    setHasUnsavedChanges(
      name !== '' ||
        description !== '' ||
        dataSource !== (forcedDataset ? forcedDataset : undefined) ||
        field !== '' ||
        aggFunc !== undefined ||
        drillDowns.length > 0,
    );
  }, [
    aggFunc,
    dataSource,
    description,
    drillDowns.length,
    editingState,
    field,
    forcedDataset,
    name,
    selectedMetric,
    setHasUnsavedChanges,
  ]);

  const onDatasetChanged = useCallback(() => {
    setField('');
    setAggFunc(undefined);
  }, []);
  const dataSourceOptions = useDataSourceOptions({
    setDataSource,
    onDatasetChanged,
    setDrillDowns,
  });
  const fieldOptions = useFieldOptions({
    selectedDataset: dataSource,
    selectedMetric,
    setField,
    aggFunc,
    setAggFunc,
  });
  const aggFuncOptions = useAggFuncOptions({
    selectedDataset: dataSource,
    field,
    setAggFunc,
  });

  useEffect(() => {
    setDataTypes(dataSource ? [dataSource] : window.emptyArray);
  }, [dataSource, setDataTypes]);

  const onNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const onDescriptionChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const onAddFilterClicked = useCallback(() => {
    trackEvent('Metric Builder - Add filter clicked');
  }, [trackEvent]);

  const onFilterAdded = useCallback(() => {
    trackEvent('Metric Builder - Filter added');
  }, [trackEvent]);

  const onFilterRemoved = useCallback(() => {
    trackEvent('Metric Builder - Filter removed');
  }, [trackEvent]);

  useEffect(() => {
    setIsValid(
      name !== '' && dataSource !== '' && field !== '' && aggFunc !== undefined,
    );
  }, [aggFunc, dataSource, field, name]);

  useEffect(() => {
    if (!isValid) {
      setMetricDraft(undefined);
      return;
    }

    const newMetric = {
      id,
      name,
      description,
      dataType: dataSource,
      field,
      status,
      aggFunc,
      filters: filterInput,
      datasetFilterIds: drillDowns
        .filter(filterPlateTypeCheckers.isDataset)
        .map((dsFilter) => dsFilter.id),
      formatting,
      type: 'normal',
      createdBy: selectedMetric ? selectedMetric.createdBy : currentUser.id,
      createdOn: selectedMetric ? selectedMetric.createdOn : getTimeStamp(),
      updatedBy: currentUser.id,
      updatedOn: getTimeStamp(),
    } as Metrics.NormalMetric;
    setMetricDraft(withoutNulls(newMetric));
  }, [
    aggFunc,
    currentUser.id,
    dataSource,
    description,
    field,
    fieldDef,
    filterInput,
    formatting,
    id,
    isValid,
    name,
    selectedMetric,
    status,
    drillDowns,
  ]);

  useEffect(() => {
    setFilterInput(
      buildFilterInput({
        scopes: [],
        drillDowns: toDrillDowns({
          plates: drillDowns.filter(filterPlateTypeCheckers.isNotDataset),
          variableDrillDowns: [],
        }),
      }),
    );
  }, [drillDowns, toDrillDowns]);

  return (
    <ReportDrillDownsProvider
      drillDowns={drillDowns}
      setDrillDowns={setDrillDowns}
      dataTypes={dataTypes}
    >
      <>
        <MetricForm
          name={name}
          onNameChanged={onNameChanged}
          description={description}
          onDescriptionChanged={onDescriptionChanged}
          formatting={formatting}
          setFormatting={setFormatting}
          dataSource={dataSource}
          dataSourceOptions={dataSourceOptions}
          field={field}
          fieldOptions={fieldOptions}
          aggFunc={aggFunc}
          aggFuncOptions={aggFuncOptions}
          drillDowns={drillDowns}
          onAddFilterClicked={onAddFilterClicked}
          onFilterAdded={onFilterAdded}
          onFilterRemoved={onFilterRemoved}
          isDatasetDisabled={!!forcedDataset}
          metricDraft={metricDraft}
        />
        {isValid && !!metricDraft && (
          <ReactPortal
            elementId={
              isInSituEditor
                ? MetricPopupConstants.PREVIEW_IN_SITU_DIV_ID
                : isSingleUsePopup
                  ? MetricPopupConstants.PREVIEW_SINGLE_USE_DIV_ID
                  : MetricPopupConstants.PREVIEW_DIV_ID
            }
          >
            <Preview metricDraft={metricDraft} />
          </ReactPortal>
        )}
        <ReactPortal
          elementId={
            isInSituEditor
              ? MetricPopupConstants.PRIMARY_ACTION_IN_SITU_DIV_ID
              : isSingleUsePopup
                ? MetricPopupConstants.PRIMARY_ACTION_SINGLE_USE_DIV_ID
                : MetricPopupConstants.PRIMARY_ACTION_DIV_ID
          }
        >
          <MetricActions
            metricDraft={metricDraft}
            selectedMetric={selectedMetric}
            setStatus={setStatus}
            showFlash={showFlash}
          />
        </ReactPortal>
        <ReactPortal
          elementId={
            isInSituEditor
              ? MetricPopupConstants.CORE_TOGGLE_IN_SITU_DIV_ID
              : isSingleUsePopup
                ? MetricPopupConstants.CORE_TOGGLE_SINGLE_USE_DIV_ID
                : MetricPopupConstants.CORE_TOGGLE_DIV_ID
          }
        >
          <CoreToggle
            status={status}
            setStatus={setStatus}
            selectedMetric={selectedMetric}
          />
        </ReactPortal>
      </>
    </ReportDrillDownsProvider>
  );
};

export default MetricFormContainer;
