import React from 'react';
import useV5ChartData from '../../../../hooks/useV5ChartData';
import Loading from '../../../Loading';
import useMetric from '../../../../hooks/useMetric';
import CardContent from './CardContent';

const CardContainer = ({
  chartDefinition,
}: {
  chartDefinition: V5ChartDefinition;
}) => {
  const { data, isLoading } = useV5ChartData(chartDefinition);
  const { series } = chartDefinition;
  const metric = useMetric(series.length > 0 ? series[0].metricId : undefined);

  if (!data || !metric) {
    if (!metric) {
      return null;
    }
    return <Loading />;
  }

  const number = (() => {
    try {
      const d = Object.values(data)[0];
      return d.response[0][d.metric.id] as number;
    } catch (ex) {
      return 0;
    }
  })();

  return (
    <CardContent isLoading={isLoading} number={number} metricId={metric.id} />
  );
};

export default CardContainer;
