import React from 'react';
import Typography from 'kingpin/atoms/Typography';
import Inputs from 'components/Inputs';
import { HIDE_UNLESS_HOVER_CLASS } from 'components/HideUnlessMouseOver';
import Row from 'components/Common/Row';
import MetricsInfoButton from 'components/MetricsInfoButton';
import ListItem from '../../ListItem';
import ColDiv from '../ColDiv';
import RemoveButton from './RemoveButton';
import Tooltip from '../../../../../../../components/Tooltip';
import TextInput from '../../../../../../../kingpin/atoms/TextInput';

const PerformanceMetricItem = ({
  metric,
  datasets,
  performanceMetric,
  datePeriodOptions,
  onDateFieldSelected,
  onFieldNameChanged,
  isFieldNameValid,
  onDatePeriodLengthChanged,
  isReadOnly,
  isExistingMetric,
}: {
  metric: Metrics.Metric;
  datasets: string;
  performanceMetric: PerformanceConfiguration.Client.PerformanceMetric;
  datePeriodOptions: DropdownOption[];
  onDateFieldSelected: (newField: string) => void;
  onFieldNameChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isFieldNameValid: {
    isValid: boolean;
    error: string;
  };
  onDatePeriodLengthChanged: (newLength: number | undefined) => void;
  isReadOnly?: boolean;
  isExistingMetric: boolean;
}) => {
  return (
    <ListItem>
      <ColDiv flex={1}>
        <Typography.Body type="Body 12">{metric.name}</Typography.Body>
      </ColDiv>

      <ColDiv flex={1}>
        {process.env.NODE_ENV === 'test' && isFieldNameValid.error && (
          <div data-testid={`${metric.id}-${isFieldNameValid.error}`} />
        )}
        <Tooltip
          content={
            isFieldNameValid.error === '' ? undefined : isFieldNameValid.error
          }
          shouldMaintainConstantDomTree
        >
          <Inputs.TextInput
            value={performanceMetric.fieldName || ''}
            onChange={onFieldNameChanged}
            state={
              !isFieldNameValid.isValid
                ? 'Error'
                : isReadOnly || isExistingMetric
                  ? 'Disabled'
                  : undefined
            }
            readOnly={isReadOnly || isExistingMetric}
            data-testid={`${metric.id}-fieldName`}
            width="100%"
          />
        </Tooltip>
      </ColDiv>
      <ColDiv flex={0.5}>
        <Typography.Body type="Body 12">{datasets}</Typography.Body>
      </ColDiv>
      <ColDiv flex={0.5}>
        <Inputs.MetricDateFieldPicker
          metric={metric}
          dateField={performanceMetric.dateField}
          setDateField={onDateFieldSelected}
          testId={`${metric.id}-date`}
        />
      </ColDiv>
      <ColDiv flex={0.5}>
        <Inputs.Dropdown
          options={datePeriodOptions}
          testId={`${metric.id}-date-period-type`}
          unsetWidth
        />
      </ColDiv>
      <ColDiv flex={0.5}>
        <TextInput
          type="number"
          value={performanceMetric.datePeriodLength.toString(10)}
          onChange={(v) =>
            onDatePeriodLengthChanged(
              v.target.value === ''
                ? undefined
                : Number.parseInt(v.target.value),
            )
          }
          state={isReadOnly ? 'Disabled' : undefined}
          readOnly={isReadOnly || isExistingMetric}
          data-testid={`${metric.id}-date-period-length`}
          width="100%"
        />
      </ColDiv>
      <ColDiv
        className={
          process.env.NODE_ENV === 'test' ? undefined : HIDE_UNLESS_HOVER_CLASS
        }
        isEnd
      >
        <Row centerAlign>
          <MetricsInfoButton visualisation={metric} />
          {!isReadOnly && (
            <div style={{ marginLeft: 8 }}>
              <RemoveButton performanceMetric={performanceMetric} />
            </div>
          )}
        </Row>
      </ColDiv>
    </ListItem>
  );
};

export default PerformanceMetricItem;
