import { useContext, useEffect, useState } from 'react';
import ReportsContext from '../contexts/ReportsContext';
import cardTypeCheckers from '../types/cardTypeCheckers';

const useVisualisationUsageInfo = (
  chartDefinition: VisualisationDefinition | undefined,
) => {
  const { allReports } = useContext(ReportsContext);
  const [reports, setReports] = useState<PersistedReportType[]>([]);

  useEffect(() => {
    if (!chartDefinition) {
      setReports([]);
      return;
    }
    const usedReports = allReports.filter((report) => {
      return report.canvas.cards
        .filter(cardTypeCheckers.isChartDefinition)
        .some((c) => c.content.chartDefinitionId === chartDefinition.id);
    });

    setReports(usedReports);
  }, [allReports, chartDefinition]);

  return {
    reports,
  };
};

export default useVisualisationUsageInfo;
