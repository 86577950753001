import React from 'react';

interface LastViewedAtContextType {
  getLastViewedAt: (
    type:
      | 'report'
      | 'dashboard'
      | 'workspace'
      | 'wallboard'
      | 'board'
      | 'scorecard',
    typeId: string,
  ) => string | undefined;
}

const LastViewedAtContext = React.createContext<LastViewedAtContextType>({
  getLastViewedAt: () => {
    return undefined;
  },
});

export default LastViewedAtContext;
