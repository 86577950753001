import React, { useContext } from 'react';
import CanvasCard from '../../Visualisations/CanvasCard';
import { ChartTopBar } from '../../Chart';
import TreeMapContent from './TreeMapContent';
import DashboardGadgetBottomBar from '../../DashboardGadgetBottomBar';
import DashboardGadgetContext from '../../../contexts/DashboardGadgetContext';

const TreeMapContainer = ({
  chartDefinition,
  chartRef,
  useSmallNoDataMessage,
}: {
  chartDefinition: V5ChartDefinition;
  chartRef: React.MutableRefObject<any>;
  useSmallNoDataMessage: boolean;
}) => {
  const { dashboardGadget } = useContext(DashboardGadgetContext);

  return (
    <CanvasCard
      topBar={<ChartTopBar chartDefinition={chartDefinition} />}
      content={
        <TreeMapContent
          chartDefinition={chartDefinition}
          chartRef={chartRef}
          useSmallNoDataMessage={useSmallNoDataMessage}
        />
      }
      bottomBar={dashboardGadget ? <DashboardGadgetBottomBar /> : undefined}
    />
  );
};

export default TreeMapContainer;
