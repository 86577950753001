import React from 'react';

interface ReportDateDrillDownOptionsType {
  dayOptions: DateOption[];
  weekOptions: DateOption[];
  monthOptions: DateOption[];
  quarterOptions: DateOption[];
  yearOptions: DateOption[];
}

const ReportDateDrillDownOptionsContext =
  React.createContext<ReportDateDrillDownOptionsType>({
    dayOptions: [],
    weekOptions: [],
    monthOptions: [],
    quarterOptions: [],
    yearOptions: [],
  });

export default ReportDateDrillDownOptionsContext;
