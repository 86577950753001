import React, { useCallback } from 'react';
import firebase from 'firebase/compat/app';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import MicrosoftLoginButton from './MicrosoftLoginButton';
import useLinkWithPassword from './useLinkWithPassword';
import LinkWithPasswordIdentity from './LinkWithPasswordIdentity';

const MicrosoftLoginButtonContainer = ({
  tenant,
  setIsTenantRequired,
}: {
  tenant?: string;
  setIsTenantRequired: React.Dispatch<
    React.SetStateAction<{ email: string; isRequired: boolean } | undefined>
  >;
}) => {
  const { startLink, attemptLink, onClosed, isWrongPassword, isOpen } =
    useLinkWithPassword();

  const getProvider = useCallback(() => {
    const provider = new firebase.auth.OAuthProvider('microsoft.com');
    provider.setCustomParameters({
      tenant,
      prompt: 'select_account',
    });

    return provider;
  }, [tenant]);

  const onClick = useCallback(() => {
    const provider = getProvider();

    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => firebase.auth().signInWithPopup(provider))
      .catch((error: firebase.auth.AuthError) => {
        if (error.code === 'auth/account-exists-with-different-credential') {
          const { credential: pendingCredential, email } = error;
          if (!email || !pendingCredential) {
            return;
          }

          firebase
            .auth()
            .fetchSignInMethodsForEmail(email)
            .then((acceptedProviders) => {
              if (acceptedProviders.includes('password')) {
                startLink({ pendingCredential, email });
              } else if (acceptedProviders.includes('microsoft.com')) {
                setIsTenantRequired({ email, isRequired: true });
              } else {
                const e = new Error();
                e.name = 'No accepted providers found for email';
                e.message = email;
                alert('Something went wrong');
              }
            });
        }
      });
  }, [getProvider, setIsTenantRequired, startLink]);

  return (
    <>
      <MicrosoftLoginButton onClick={onClick} />
      <ModalTransition>
        {isOpen && (
          <Modal
            onClose={onClosed}
            shouldScrollInViewport={false}
            autoFocus={false}
          >
            <LinkWithPasswordIdentity
              attemptLink={attemptLink}
              isWrongPassword={isWrongPassword}
              onCancelClicked={onClosed}
            />
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};

export default MicrosoftLoginButtonContainer;
