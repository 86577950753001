import React from 'react';

const ConfigureMicrosoftSsoContext =
  React.createContext<MicrosoftSso.ConfigureContext>({
    availableGroups: [],
    initialGroups: [],
    selectedGroups: [],
    setIsValid: () => {
      const e = new Error();
      e.name = 'setIsValid is not defined';
      throw e;
    },
    setSelectedGroups: () => {
      const e = new Error();
      e.name = 'setSelectedGroups is not defined';
      throw e;
    },
  });

export default ConfigureMicrosoftSsoContext;
