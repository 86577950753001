import { useCallback, useContext, useEffect, useState } from 'react';
import { ExecutivePortalsContext } from './ExecutivePortalsProvider';
import { EngagementPortalsContext } from './EngagementPortalsProvider';
import useAdminPortal from './useAdminPortal';
import isDefined from '../../isDefined';

const useHasAvailablePortal = () => {
  const { availableExecutivePortals } = useContext(ExecutivePortalsContext);
  const { availableEngagementPortals } = useContext(EngagementPortalsContext);
  const adminPortal = useAdminPortal();

  const getAvailablePortals = useCallback(
    () =>
      [
        adminPortal,
        ...availableExecutivePortals,
        ...availableEngagementPortals,
      ].filter(isDefined),
    [adminPortal, availableEngagementPortals, availableExecutivePortals],
  );
  const getHasAvailablePortal = useCallback(() => {
    return getAvailablePortals().length > 0;
  }, [getAvailablePortals]);

  const [availablePortals, setAvailablePortals] = useState<Portal[]>(() =>
    getAvailablePortals(),
  );
  const [hasAvailablePortal, setHasAvailablePortal] = useState<boolean>(() =>
    getHasAvailablePortal(),
  );

  useEffect(() => {
    setAvailablePortals(getAvailablePortals());
    setHasAvailablePortal(getHasAvailablePortal());
  }, [getAvailablePortals, getHasAvailablePortal]);

  return {
    availablePortals,
    hasAvailablePortal,
  };
};

export default useHasAvailablePortal;
