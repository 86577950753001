import { useCallback, useContext, useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import { ApisauceInstance } from 'apisauce';

import ImpersonatorContext from '../../contexts/ImpersonatorContext';
import CurrentUserContext from '../../contexts/CurrentUserContext';
import { AUTH_ERROR_MSG_KEY } from '../../screens/Login/ErrorBanner';

interface AccessProfile {
  accounts: { [accountId: string]: { permissions: string[]; roles: string[] } };
}

const useCurrentPermissions = (api: ApisauceInstance | undefined) => {
  const { impersonatorId } = useContext(ImpersonatorContext);
  const currentUser = useContext(CurrentUserContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [permissions, setPermissions] = useState<FleetOps.Permission[]>([]);
  const [accessProfile, setAccessProfile] = useState<
    AccessProfile | undefined
  >();

  const fetchPermissions = useCallback(async (): Promise<
    FleetOps.Permission[]
  > => {
    if (!api) {
      return [];
    }

    if (impersonatorId) {
      const response = await api.get<
        { permissions: FleetOps.Permission[] },
        { errorMessage: string }
      >(`/userProfiles/${impersonatorId}`);

      if (response.ok && !!response.data) {
        return response.data.permissions;
      }
    } else {
      const response = await api.get<
        { permissions: FleetOps.Permission[] },
        { error: string }
      >('/');
      if (response.ok && !!response.data) {
        return response.data.permissions;
      } else if (!response.ok) {
        if (response.data && response.data.error) {
          const error = response.data.error;
          let errorMessage;
          if (error === 'Forbidden: Session identity provider not permitted') {
            errorMessage =
              'Your organization has enabled SSO. Please' +
              ' sign in with Microsoft';
          } else if (
            error === 'Forbidden: Access not granted to this account'
          ) {
            errorMessage =
              'You do not have access to FleetOps. Contact your IT admin';
          }

          if (errorMessage) {
            window.localStorage.setItem(AUTH_ERROR_MSG_KEY, errorMessage);
          }
          await firebase.auth().signOut();
        }
      } else {
        await firebase.auth().signOut();
      }
    }

    return [];
  }, [api, impersonatorId]);

  useEffect(() => {
    // We need to know when the current user's roles and permissions change
    const listener = firebase
      .firestore()
      .collection('userProfiles')
      .doc(currentUser.id)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const newProfile = doc.data() as AccessProfile;
          setAccessProfile(newProfile);
        }
      });
    return () => {
      listener();
    };
  }, [currentUser.id]);

  useEffect(() => {
    let isActive = true;
    setIsLoading(true);
    fetchPermissions().then((newPermissions) => {
      if (isActive) {
        setPermissions(newPermissions);
        setIsLoading(false);
      }
    });

    return () => {
      isActive = false;
    };
  }, [api, accessProfile, fetchPermissions]);

  return {
    currentPermissions: permissions,
    isLoading,
  };
};

export default useCurrentPermissions;
