import React from 'react';
import LastViewedAtProvider from '../LastViewedAtProvider';
import NavSidebarProvider from '../NavSideBarProvider';
import FavouritesProvider from '../FavouritesProvider';
import DashboardMetricUsageLookupProvider from '../DashboardMetricUsageLookupProvider';
import MetricUsageLookupProvider from '../MetricUsageLookupProvider';
import DatasetFiltersUsageLookupProvider from '../../screens/DataManager/DatasetFilters/providers/DatasetFiltersUsageLookupProvider';
import SharedProvider from '../SharedProvider';
import AvailableWorkSpacesProvider from '../AvailableWorkSpacesProvider';

const ContentDependantProviders = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => (
  <AvailableWorkSpacesProvider>
    <LastViewedAtProvider>
      <NavSidebarProvider>
        <FavouritesProvider>
          <DashboardMetricUsageLookupProvider>
            <MetricUsageLookupProvider>
              <DatasetFiltersUsageLookupProvider>
                <SharedProvider>{children}</SharedProvider>
              </DatasetFiltersUsageLookupProvider>
            </MetricUsageLookupProvider>
          </DashboardMetricUsageLookupProvider>
        </FavouritesProvider>
      </NavSidebarProvider>
    </LastViewedAtProvider>
  </AvailableWorkSpacesProvider>
);

export default ContentDependantProviders;
