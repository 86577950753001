import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import NewUserButton from './NewUserButton';
import usePopup from '../../../hooks/usePopup';
import UsersSettingsContext from '../../../contexts/UsersSettingsContext';

const NewUserButtonContainer = () => {
  const { isOpen, open, close } = usePopup();
  const { isSsoAccount, isLoadingIsSsoAccount, hasSeatsRemaining } =
    useContext(UsersSettingsContext);
  const hasUnsavedChangesRef = useRef<boolean>(false);
  const [isConfirmingClose, setIsConfirmingClose] = useState<boolean>(false);

  const onModalClose = useCallback(() => {
    if (hasUnsavedChangesRef.current) {
      setIsConfirmingClose(true);
    } else {
      close();
    }
  }, [close]);

  useEffect(() => {
    setIsConfirmingClose(false);
  }, [isOpen]);

  if (isLoadingIsSsoAccount || isSsoAccount) {
    return null;
  }

  return (
    <NewUserButton
      open={open}
      close={close}
      isOpen={isOpen}
      hasUnsavedChangesRef={hasUnsavedChangesRef}
      isConfirmingClose={isConfirmingClose}
      onModalClose={onModalClose}
      setIsConfirmingClose={setIsConfirmingClose}
      hasSeatsRemaining={hasSeatsRemaining}
    />
  );
};

export default NewUserButtonContainer;
