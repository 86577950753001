import { useEffect, useState } from 'react';

import useDateScope from '../../hooks/useDateScope';
import isNormalMetric from '../../types/metricTypeCheckers/isNormalMetric';

const usePopupDateRangeOverride = (metric: Metrics.Metric | undefined) => {
  const dateScope = useDateScope({});
  const [override, setOverride] = useState<DateRangeInput | undefined>();

  useEffect(() => {
    if (!metric) {
      setOverride(undefined);
    } else if (isNormalMetric(metric)) {
      if (
        metric.aggFunc === 'count_first_day' ||
        metric.aggFunc === 'sum_first_day'
      ) {
        setOverride({
          ...dateScope,
          endDate: dateScope.startDate,
        });
      } else if (
        metric.aggFunc === 'count_last_day' ||
        metric.aggFunc === 'sum_last_day'
      ) {
        setOverride({
          ...dateScope,
          startDate: dateScope.endDate,
        });
      } else {
        setOverride(undefined);
      }
    } else {
      setOverride(undefined);
    }
  }, [dateScope, metric]);

  return override;
};

export default usePopupDateRangeOverride;
