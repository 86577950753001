import Typography from 'kingpin/atoms/Typography';
import React from 'react';

const DetailsTabItem = ({
  header,
  children,
}: {
  header: string;
  children: JSX.Element;
}) => {
  return (
    <>
      <div style={{ marginBottom: 24 }}>
        <Typography.Header type="H5">{header}</Typography.Header>
        {children}
      </div>
    </>
  );
};

export default DetailsTabItem;
