import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import useCloseOnEscape from './useCloseOnEscape';
import useResults from './useResults';
import AnalyticsContext from '../../../contexts/AnalyticsContext';

const useSearch = ({
  isOpen,
  onSearchCancelled,
  setSelectedEntity,
}: {
  isOpen: boolean;
  onSearchCancelled: () => void;
  setSelectedEntity: React.Dispatch<
    React.SetStateAction<EntityDetails.Entity | undefined>
  >;
}) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [isNoResults, setIsNoResults] = useState<boolean>(false);
  const [isActiveFilterDisabled, setIsActiveFilterDisabled] =
    useState<boolean>(false);
  const { results, setResults } = useResults({
    searchText,
    isActiveFilterDisabled,
    setIsLoading,
    setIsNoResults,
  });

  const resetState = useCallback(() => {
    setSearchText('');
    setIsLoading(true);
    setIsNoResults(false);
    setResults({ results: [], searchText: '' });
    setSelectedEntity(undefined);
    setIsActiveFilterDisabled(false);
  }, [setResults, setSelectedEntity]);

  useCloseOnEscape({ isOpen, close: onSearchCancelled, resetState });

  const onSearchTextChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (searchText === '') {
        trackEvent('Entity Details - Search - Began Writing');
      }
      setSearchText(event.target.value);
    },
    [searchText, trackEvent],
  );

  useEffect(() => {
    if (isNoResults) {
      trackEvent('Entity Details - Search - No Result Found');
    }
  }, [isNoResults, trackEvent]);

  return {
    searchText,
    onSearchTextChanged,
    isLoading,
    isNoResults,
    isActiveFilterDisabled,
    setIsActiveFilterDisabled,
    results,
  };
};

export default useSearch;
