import styled from 'styled-components';

const CenteredImg = styled.img<{ isEditing: boolean }>`
  width: 100%;
  height: 100%;
  object-fit: contain;
  ${(props) =>
    props.isEditing &&
    `
    height: calc(100% - 36px)
  `}
`;

export default CenteredImg;
