import React, { useCallback, useEffect, useState } from 'react';
import { CURRENCY_TYPES } from 'hooks/useValueFormatters';
import Dropdown from 'components/Inputs/Dropdown';

const useCurrencyTypeOptions = ({
  setCurrencyType,
}: {
  setCurrencyType: React.Dispatch<
    React.SetStateAction<CurrencyType | undefined>
  >;
}) => {
  const getCurrencyOptions = useCallback(
    () =>
      CURRENCY_TYPES.map((t) => ({
        label: t,
        value: t,
        onSelected: () => {
          setCurrencyType(t as CurrencyType);
        },
      })),
    [setCurrencyType],
  );
  const [currencyTypeOptions, setCurrecyTypeOptions] =
    useState<DropdownOption[]>(getCurrencyOptions);
  useEffect(() => {
    setCurrecyTypeOptions(getCurrencyOptions());
  }, [getCurrencyOptions]);

  return currencyTypeOptions;
};

const CurrencyTypeDropdown = ({
  currencyType,
  setCurrencyType,
}: {
  currencyType?: CurrencyType;
  setCurrencyType: React.Dispatch<
    React.SetStateAction<CurrencyType | undefined>
  >;
}) => {
  const currencyTypeOptions = useCurrencyTypeOptions({
    setCurrencyType,
  });
  return (
    <Dropdown
      options={currencyTypeOptions}
      testId={'metric-currencyType'}
      selectedLabel={currencyType || 'USD'}
    />
  );
};

export default CurrencyTypeDropdown;
