import React, { useContext, useEffect, useState } from 'react';
import ScorecardsContext from '../../contexts/ScorecardsContext';
import Scorecard from './index';

const ScorecardGadget = ({ scorecardId }: { scorecardId: string }) => {
  const { scorecards } = useContext(ScorecardsContext);
  const [scorecard, setScorecard] = useState<Scorecards.Scorecard>();

  useEffect(() => {
    setScorecard(scorecards.find((s) => s.id === scorecardId));
  }, [scorecardId, scorecards]);

  if (!scorecard) {
    return null;
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Scorecard scorecard={scorecard} isAddKpiEnabled={false} />
    </div>
  );
};

export default ScorecardGadget;
