import React, { useCallback, useEffect, useState } from 'react';
import AuthenticationContext from '../contexts/AuthenticationContext';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import Loading from '../components/Loading/Loading';
import FlexCentered from '../components/Common/FlexCentered';

const AuthenticationProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [uid, setUid] = useState<string | undefined>();
  const [email, setEmail] = useState<string | undefined>();
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [isWallboardUser, setIsWallBoardUser] = useState<boolean>();
  const [wallBoardAccountId, setWallBoardAccountId] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isClaimsEvaluated, setIsClaimsEvaluated] = useState<boolean>(false);

  const onLoggedOut = useCallback(() => {
    setUid(undefined);
    setEmail(undefined);
    setAuthenticated(false);
    setIsSuperAdmin(false);
    setIsWallBoardUser(false);
    setWallBoardAccountId(undefined);
  }, []);

  useEffect(() => {
    let isActive = true;
    firebase.auth().onAuthStateChanged((updatedSession) => {
      if (!isActive) {
        return;
      }

      setAuthenticated(!!updatedSession);
      if (updatedSession) {
        setUid(updatedSession.uid);
        setEmail(updatedSession.email ? updatedSession.email : 'Not available');
        updatedSession.getIdTokenResult().then(({ claims }) => {
          if (isActive) {
            setIsSuperAdmin(claims.isSuperAdmin);
            setIsWallBoardUser(!!claims.wallboardId);
            setWallBoardAccountId(
              Array.isArray(claims.accountId)
                ? claims.accountId[0]
                : claims.accountId,
            );
            setIsClaimsEvaluated(true);
          }
        });
      } else {
        onLoggedOut();
      }
      setIsLoading(false);
    });

    return () => {
      isActive = false;
    };
  }, [onLoggedOut]);

  if (isLoading || (authenticated && (!uid || !email || !isClaimsEvaluated))) {
    return (
      <FlexCentered style={{ height: '100vh' }}>
        <Loading isGrouped />
      </FlexCentered>
    );
  }

  return (
    <AuthenticationContext.Provider
      value={{
        authenticated,
        isLoading,
        uid,
        isSuperAdmin,
        isWallboardUser,
        wallBoardAccountId,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationProvider;
