import React from 'react';
import styled from 'styled-components';
import Typography from 'kingpin/atoms/Typography';

import Colors2 from '../../../../theme/Colors2';
import Button from '../../../../kingpin/atoms/Button';

const Wrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid ${Colors2.Border};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 24px;
`;

const Heading = ({
  lane,
  customer,
  close,
}: {
  lane: string;
  customer: string;
  close: () => void;
}) => (
  <Wrapper>
    <div>
      <div style={{ marginBottom: 4 }}>
        <Typography.Header type={'H4'}>{lane}</Typography.Header>
      </div>
      <Typography.Header type="H5" color={Colors2.Grey['4']}>
        {customer}
      </Typography.Header>
    </div>

    <Button size={'Small'} type={'Secondary'} onClick={close} icon={'cross'} />
  </Wrapper>
);

export default Heading;
