import React, { useContext } from 'react';
import CardContext from '../../../../contexts/CardContext';
import FlexCentered from '../../../Common/FlexCentered';
import Loading from '../../../Loading';
import ScalingNumber from '../../../ScalingNumber';

const CardContent = ({
  isLoading,
  number,
  metricId,
}: {
  isLoading: boolean;
  number: number;
  metricId: string;
}) => {
  const { h } = useContext(CardContext);

  return (
    <FlexCentered
      style={{
        height: '100%',
        width: '100%',
        fontSize: h === 1 ? '0.8em' : '2em',
        lineHeight: h === 1 ? '1.3em' : '2.2em',
        alignItems: 'flex-start',
      }}
    >
      {isLoading && (
        <FlexCentered style={{ width: '100%' }}>
          <Loading />
        </FlexCentered>
      )}
      {!isLoading && <ScalingNumber number={number} metricId={metricId} />}
    </FlexCentered>
  );
};

export default CardContent;
