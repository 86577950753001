import React from 'react';

interface WorkSpacesContextType {
  allWorkSpaces: (WorkSpace | TargetsApp.App)[];
  isLoading: boolean;
}

const WorkSpacesContext = React.createContext<WorkSpacesContextType>({
  allWorkSpaces: [],
  isLoading: true,
});

export default WorkSpacesContext;
