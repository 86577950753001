import React, { useCallback, useContext } from 'react';
import MetricMatrixContext from 'contexts/MetricMatrixContext';
import GridRowCountContext from 'contexts/GridRowCountContext';
import DateInputContext from 'contexts/DateInputContext';
import DashboardGadgetContext from 'contexts/DashboardGadgetContext';
import VariableFiltersContext from 'contexts/VariableFiltersContext';
import ReportsContext from 'contexts/ReportsContext';
import BoardsContext from 'contexts/BoardsContext';
import CardContext from 'contexts/CardContext';
import Row from 'components/Common/Row';
import GridCellErrorBoundary from 'components/GridCellErrorBoundary';
import Tooltip from 'components/Tooltip';
import useDateScope from 'hooks/useDateScope';
import useIsGridLinksDisabled from 'hooks/useIsGridLinksDisabled';
import useObfuscator from 'hooks/useObfuscator';
import visTypeCheckers from 'types/visTypeCheckers';
import isV5ChartDef from 'types/visTypeCheckers/isV5ChartDef';
import { FleetopsGridCellRendererParams } from 'types/agGrid';

import Cell from '../Cell';
import usePopup from './usePopup';
import EntityLink from './EntityLink';
import EntityButtons from 'components/EntityButtons';

const GroupingCellRenderer = ({
  value,
  node,
  data,
  colDef,
}: FleetopsGridCellRendererParams) => {
  const { onDrillDown } = useContext(MetricMatrixContext);
  const { rowCount } = useContext(GridRowCountContext);
  const { dateField } = useContext(DateInputContext);
  const dateScope = useDateScope({});
  const { dashboardGadget } = useContext(DashboardGadgetContext);
  const { variableFilters } = useContext(VariableFiltersContext);
  const { chartDefinition } = useContext(CardContext);
  const { allReports } = useContext(ReportsContext);
  const { boards } = useContext(BoardsContext);

  const isLinksDisabled = useIsGridLinksDisabled();
  const getChartDefDimensionField = useCallback(() => {
    if (!chartDefinition) {
      return undefined;
    }

    if (visTypeCheckers.isV5ChartDef(chartDefinition)) {
      return chartDefinition.dimensionA && chartDefinition.dimensionA.field;
    }

    if (visTypeCheckers.isGauge(chartDefinition)) {
      return chartDefinition.peerGroup;
    }

    if (visTypeCheckers.isRankingMatrix(chartDefinition)) {
      return chartDefinition.groupByField;
    }

    if (visTypeCheckers.isSingleMetricDateMatrix(chartDefinition)) {
      return chartDefinition.groupByField;
    }

    if (visTypeCheckers.isPaceMatrix(chartDefinition)) {
      return chartDefinition.groupByField;
    }

    return undefined;
  }, [chartDefinition]);
  const { isObfuscating, obfuscatedValue } = useObfuscator({
    value,
    field: getChartDefDimensionField(),
  });

  const openPopupReport = usePopup({
    dashboardGadget,
    filterInput: data.filterInput as undefined | FilterInput,
    rawDate: data['raw-date'],
    term: value,
    variableFilters,
    dateField,
    dateScope,
    autoIntervalResponse: data['interval'],
  });
  const isSingleDimensionGroupByDayOfWeek = (() => {
    if (dashboardGadget) {
      if (chartDefinition) {
        if (isV5ChartDef(chartDefinition)) {
          return (
            chartDefinition.groupByDayOfWeek &&
            chartDefinition.dimensionB === undefined
          );
        }
      }
    }
    return false;
  })();

  const isTermClickable = (() => {
    if (isLinksDisabled) {
      return false;
    }
    if (node.isRowPinned()) {
      return undefined;
    }
    if (isSingleDimensionGroupByDayOfWeek) {
      return false;
    }

    if (dashboardGadget) {
      const reportFound =
        !!dashboardGadget.reportDrillDownId &&
        allReports.find(
          (report) => report.id === dashboardGadget.reportDrillDownId,
        );
      const boardFound =
        !!dashboardGadget.boardDrillDownId &&
        boards.find((board) => board.id === dashboardGadget.boardDrillDownId);
      return reportFound || boardFound;
    }

    return true;
  })();

  const onClick = (event: any) => {
    event.stopPropagation();
    if (openPopupReport) {
      openPopupReport();
    } else {
      const isDateGroup = data['date'] !== undefined;
      if (isDateGroup) {
        const rawDate = data['raw-date'];
        onDrillDown(rawDate, event.metaKey || event.ctrlKey);
      } else {
        onDrillDown(value, event.metaKey || event.ctrlKey);
      }
    }
  };

  if (value === 'Total' && node.rowPinned) {
    return (
      <Cell>
        <span>{`${rowCount} Rows`}</span>
      </Cell>
    );
  }

  const valueToDisplay = isObfuscating ? obfuscatedValue : value;

  const tooltipContent =
    typeof valueToDisplay === 'string' ? valueToDisplay : '';

  let termComponent: JSX.Element | null = null;
  if (!isTermClickable) {
    termComponent = (
      <Tooltip content={tooltipContent}>
        <span>{valueToDisplay}</span>
      </Tooltip>
    );
  } else {
    termComponent = (
      <EntityButtons.Interaction
        onClick={onClick}
        text={value}
        tooltip={tooltipContent}
      />
    );
  }

  return (
    <Cell>
      <Row spaceBetween centerAlign>
        {termComponent}
        <EntityLink colDef={colDef} value={valueToDisplay} node={node} />
      </Row>
    </Cell>
  );
};

const Gate = (params: FleetopsGridCellRendererParams) => (
  <GridCellErrorBoundary
    params={params}
    childComponent={<GroupingCellRenderer {...params} />}
  />
);

export default Gate;
