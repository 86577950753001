import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Tooltip from '../../../components/Tooltip';

const EllipsisCss = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
`;

const H1 = styled.h1<{ isEllipsis: boolean; color?: string }>`
  font-family: Inter;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  color: ${(props) => props.color || '#1a1919'};

  ${(props) => props.isEllipsis && EllipsisCss};
`;
const H2 = styled.h2<{ isEllipsis: boolean; color?: string }>`
  font-family: Inter;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  color: ${(props) => props.color || '#1a1919'};

  ${(props) => props.isEllipsis && EllipsisCss};
`;

const H3 = styled.h3<{
  isEllipsis: boolean;
  color?: string;
  textAlign?: 'center' | 'left' | 'right';
}>`
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  color: ${(props) => props.color || '#1a1919'};
  text-align: ${(props) => props.textAlign || 'left'};

  ${(props) => props.isEllipsis && EllipsisCss};
`;

const H4 = styled.h4<{ isEllipsis: boolean; color?: string }>`
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  color: ${(props) => props.color || '#1a1919'};

  ${(props) => props.isEllipsis && EllipsisCss};
`;

const H5 = styled.h5<{ isEllipsis: boolean; color?: string }>`
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  color: ${(props) => props.color || '#1a1919'};

  ${(props) => props.isEllipsis && EllipsisCss};
`;

const H6 = styled.h6<{ isEllipsis: boolean; color?: string }>`
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  color: ${(props) => props.color || '#1a1919'};

  ${(props) => props.isEllipsis && EllipsisCss};
`;

type HeaderType = 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'H6';

const Header = ({
  type,
  children,
  isEllipsis = false,
  color,
  textAlign,
}: {
  type: HeaderType;
  children: string[] | string | undefined | JSX.Element | JSX.Element[] | null;
  isEllipsis?: boolean;
  color?: string;
  textAlign?: 'center' | 'left' | 'right';
}) => {
  const ref = useRef<HTMLHeadingElement>(null);
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false);
  useEffect(() => {
    const e = ref.current;
    if (!e) {
      return;
    }
    setIsOverflowing(e.offsetWidth < e.scrollWidth);
  }, [children]);

  const Component = (() => {
    if (type === 'H1') {
      return (
        <H1 isEllipsis={isEllipsis} color={color} ref={ref}>
          {children}
        </H1>
      );
    }
    if (type === 'H2') {
      return (
        <H2 isEllipsis={isEllipsis} color={color} ref={ref}>
          {children}
        </H2>
      );
    }
    if (type === 'H3') {
      return (
        <H3
          isEllipsis={isEllipsis}
          color={color}
          ref={ref}
          textAlign={textAlign}
        >
          {children}
        </H3>
      );
    }
    if (type === 'H4') {
      return (
        <H4 isEllipsis={isEllipsis} color={color} ref={ref}>
          {children}
        </H4>
      );
    }
    if (type === 'H5') {
      return (
        <H5 isEllipsis={isEllipsis} color={color} ref={ref}>
          {children}
        </H5>
      );
    }
    if (type === 'H6') {
      return (
        <H6 isEllipsis={isEllipsis} color={color} ref={ref}>
          {children}
        </H6>
      );
    }

    console.warn(`Unknown header type encountered: ${type}`);
    return (
      <H5 isEllipsis={isEllipsis} color={color} ref={ref}>
        {children}
      </H5>
    );
  })();

  if (isEllipsis) {
    return (
      <Tooltip content={isOverflowing ? children : undefined}>
        {Component}
      </Tooltip>
    );
  }

  return <>{Component}</>;
};

export default Header;
