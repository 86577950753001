import React, { ChangeEvent } from 'react';
import Button from 'kingpin/atoms/Button';

import {
  GadgetFormInner,
  GadgetFormInnerContent,
  GadgetFormInnerTopBar,
  GadgetFormPreview,
  GadgetFormWrapper,
} from '../V5GadgetForm/V5GadgetForm';
import Typography from 'kingpin/atoms/Typography';
import { Section } from '../MyAccount/Profile/styles';
import Inputs from '../Inputs';
import PeerGroupInput from './PeerGroupInput';
import Row from '../Common/Row';
import EntityInput from './EntityInput';
import GaugePreview from './GaugePreview';

const GaugeForm = ({
  isGadgetBuilder,
  // Base
  name,
  onNameChanged,
  description,
  onDescriptionChanged,
  metricId,
  setMetricId,
  metricDisplayName,
  onMetricDisplayNameChanged,
  setMetricDisplayName,
  //   Gauge
  peerGroup,
  setPeerGroup,
  entity,
  setEntity,
  // Misc
  isValid,
  isLoading,
  onSave,
  draftGauge,
}: {
  isGadgetBuilder?: boolean;
  // Base
  name: string;
  onNameChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  description: string;
  onDescriptionChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  metricId?: string;
  setMetricId: React.Dispatch<React.SetStateAction<string | undefined>>;
  metricDisplayName: string;
  onMetricDisplayNameChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  setMetricDisplayName: React.Dispatch<React.SetStateAction<string>>;
  //   Gauge
  peerGroup?: string;
  setPeerGroup: React.Dispatch<React.SetStateAction<string | undefined>>;
  entity?: string;
  setEntity: React.Dispatch<React.SetStateAction<string | undefined>>;
  // Misc
  isValid: boolean;
  isLoading: boolean;
  onSave: () => void;
  draftGauge?: VisualisationDefinitions.Gauge;
}) => (
  <GadgetFormWrapper>
    <GadgetFormInner isGadgetBuilder={!!isGadgetBuilder}>
      <GadgetFormInnerTopBar isGadgetBuilder={!!isGadgetBuilder}>
        <Typography.Header type="H4">Gauge</Typography.Header>
      </GadgetFormInnerTopBar>
      <GadgetFormInnerContent isGadgetBuilder={!!isGadgetBuilder}>
        {/* Base */}
        <Section>
          <Typography.Body type="Label">Name</Typography.Body>
          <Inputs.TextInput
            placeholder="Name"
            value={name}
            onChange={onNameChanged}
          />
        </Section>
        <Section>
          <Typography.Body type="Label">Description</Typography.Body>
          <Inputs.TextInput
            placeholder="Name"
            value={description}
            onChange={onDescriptionChanged}
          />
        </Section>
        <Section>
          <Typography.Body type="Label">Metric</Typography.Body>
          <Inputs.MetricPicker
            metricId={metricId}
            setMetricId={setMetricId}
            noSpecials
            setMetricDisplayName={setMetricDisplayName}
          />
        </Section>
        <Section>
          <Typography.Body type="Label">Metric Display Name</Typography.Body>
          <Inputs.TextInput
            value={metricDisplayName}
            onChange={onMetricDisplayNameChanged}
            placeholder="Display name"
          />
        </Section>
        {/* Gauge */}
        <Section>
          <Typography.Body type="Label">Peer Group</Typography.Body>
          <PeerGroupInput
            peerGroup={peerGroup}
            setPeerGroup={setPeerGroup}
            metricId={metricId}
            setEntity={setEntity}
          />
        </Section>
        <Section>
          <Typography.Body type="Label">Entity</Typography.Body>
          <EntityInput
            peerGroup={peerGroup}
            metricId={metricId}
            entity={entity}
            setEntity={setEntity}
          />
        </Section>
        {isGadgetBuilder && (
          <Section>
            <Row centerAlign style={{ justifyContent: 'flex-end' }}>
              <Button
                onClick={onSave}
                isDisabled={!isValid}
                isLoading={isLoading}
                label="Save"
                type="Primary"
                size="Small"
              />
            </Row>
          </Section>
        )}
      </GadgetFormInnerContent>
    </GadgetFormInner>
    {isGadgetBuilder && (
      <GadgetFormPreview>
        {draftGauge && <GaugePreview gauge={draftGauge} />}
      </GadgetFormPreview>
    )}
  </GadgetFormWrapper>
);

export default GaugeForm;
