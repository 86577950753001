import isComparisonSeriesItem from '../../isComparisonSeriesItem';

const getCompareDateTimeFor = (
  x: number,
  series: (HistogramSeriesItem | ComparisonSeriesItem | BarSeriesItem)[],
): number => {
  const lastPeriodSeries = series[1];
  if (lastPeriodSeries && isComparisonSeriesItem(lastPeriodSeries)) {
    const matchingDataItem = lastPeriodSeries.data.find((d) => d.x === x);
    if (!matchingDataItem) {
      return x;
    }
    return matchingDataItem.datetime;
  } else {
    return x;
  }
};

export default getCompareDateTimeFor;
