import React, { useContext } from 'react';
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';

import TopNavigator from './TopNavigator';
import { CLOSED_NAV_WIDTH, OPENED_NAV_WIDTH } from '../../../navigation/styles';
import Colors from '../../../theme/colors';
import NavSideBarContext from '../../../contexts/NavSideBarContext';
import CostsShowProvider from '../Costs/providers/CostsShowProvider';
import CostsShowContext from '../Costs/context/CostsShowContext';
import ActivityGridProvider from '../Costs/providers/ActivityGridProvider';
import InfoBanner from 'components/InfoBanner';
import Typography from 'kingpin/atoms/Typography';
import Loading from 'components/Loading/Loading';

const OPEN_NAV_OFFSET = OPENED_NAV_WIDTH;
const CLOSED_NAV_OFFSET = CLOSED_NAV_WIDTH;

const Wrapper = styled.div<{ isMainNavOpen: boolean }>`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100vh;
  width: calc(
    100vw -
      ${(props) =>
        props.isMainNavOpen ? OPEN_NAV_OFFSET : CLOSED_NAV_OFFSET}px
  );

  background-color: ${Colors.BLUE_GREY};
`;

const CostsSet = () => {
  const { isOpen: isMainNavOpen } = useContext(NavSideBarContext);
  const { costModel, isLoadingCosts, isLoadingModel } =
    useContext(CostsShowContext);

  if (isLoadingCosts || isLoadingModel) {
    return <Loading />;
  }

  return (
    <ActivityGridProvider activityCosts={costModel}>
      <Wrapper isMainNavOpen={isMainNavOpen}>
        <TopNavigator />
        {costModel?.isDraft && (
          <InfoBanner isShort>
            <Typography.Body type="Body 12">
              The current costs configuration is in a draft state. Reporting
              data will be available after the changes are published. To publish
              please email support@fleetops.com
            </Typography.Body>
          </InfoBanner>
        )}
        <Outlet />
      </Wrapper>
    </ActivityGridProvider>
  );
};

const Gate = () => {
  return (
    <CostsShowProvider modelType={'activity'}>
      <CostsSet />
    </CostsShowProvider>
  );
};

export default Gate;
