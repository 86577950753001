import { useCallback, useContext, useEffect, useState } from 'react';
import DashboardsContext from '../../contexts/DashboardsContext';
import cardTypeCheckers from '../../types/cardTypeCheckers';

const useScorecardUsageReport = (scorecard?: Scorecards.Scorecard) => {
  const { allDashboards } = useContext(DashboardsContext);
  const findUsage = useCallback(() => {
    const newUsage = { dashboards: [] } as Scorecards.UsageReport;
    if (!scorecard) {
      return newUsage;
    }
    allDashboards.forEach((dashboard) => {
      const isOnThisDashboard = dashboard.canvas.cards
        .filter(cardTypeCheckers.isScorecard)
        .some((c) => c.content.scorecardId === scorecard.id);

      if (isOnThisDashboard) {
        newUsage.dashboards.push({ id: dashboard.id, name: dashboard.name });
      }
    });

    return newUsage;
  }, [allDashboards, scorecard]);

  const findIsUsedInMultiplePlaces = useCallback(
    (report: Scorecards.UsageReport) => {
      return report.dashboards.length > 1;
    },
    [],
  );

  const findIsUsed = useCallback((report: Scorecards.UsageReport) => {
    return report.dashboards.length > 0;
  }, []);

  const [usage, setUsage] = useState<Scorecards.UsageReport>(findUsage());
  const [isUsed, setIsUsed] = useState<boolean>(findIsUsed(usage));
  const [isUsedInMultiplePlaces, setIsUsedInMultiplePlaces] = useState<boolean>(
    findIsUsedInMultiplePlaces(usage),
  );
  const [usageWarningMessage, setUsageWarningMessage] = useState<string>('');

  useEffect(() => {
    setUsage(findUsage());
  }, [findUsage]);

  useEffect(() => {
    setIsUsedInMultiplePlaces(findIsUsedInMultiplePlaces(usage));
  }, [findIsUsedInMultiplePlaces, usage]);

  useEffect(() => {
    setIsUsed(findIsUsed(usage));
  }, [findIsUsed, usage]);

  useEffect(() => {
    if (!isUsedInMultiplePlaces) {
      setUsageWarningMessage('');
      return;
    }

    let message = 'This KPI List appears in multiple dashboards:';
    usage.dashboards.forEach((d) => {
      message += `\n- ${d.name}`;
    });
    setUsageWarningMessage(message);
  }, [isUsedInMultiplePlaces, usage.dashboards]);

  return {
    usage,
    isUsedInMultiplePlaces,
    usageWarningMessage,
    isUsed,
  };
};

export default useScorecardUsageReport;
