export const BREAK_POINTS = {
  DESKTOP_XXS: 1280,
  DESKTOP_XS: 1366,
  DESKTOP_S: 1536,
  DESKTOP_M: 1600,
  DESKTOP_L: 1920,
};

const MEDIA_QUERIES = {
  DESKTOP_XXS: `(min-width: ${BREAK_POINTS.DESKTOP_XXS}px)`,
  DESKTOP_XS: `(min-width: ${BREAK_POINTS.DESKTOP_XS}px)`,
  DESKTOP_S: `(min-width: ${BREAK_POINTS.DESKTOP_S}px)`,
  DESKTOP_M: `(min-width: ${BREAK_POINTS.DESKTOP_M}px)`,
  DESKTOP_L: `(min-width: ${BREAK_POINTS.DESKTOP_L}px)`,
};

export default MEDIA_QUERIES;
