import Typography from 'kingpin/atoms/Typography';
import React from 'react';
import useOnEntityLinkClicked from './useOnEntityLinkClicked';
import Loading from '../../../components/Loading/Loading';
import Row from '../../../components/Common/Row';
import Colors2 from '../../../theme/Colors2';

const EntityListItemLink = ({ entity }: { entity: EntityDetails.Entity }) => {
  const { onClick, isLoading, hasNoEntities } = useOnEntityLinkClicked(entity);

  if (!onClick) {
    let right: JSX.Element;
    if (isLoading) {
      right = <Loading isTiny />;
    } else if (hasNoEntities) {
      right = (
        <Typography.Body type="Body 12" color={Colors2.Secondary.error}>
          No entities found
        </Typography.Body>
      );
    } else {
      right = <></>;
    }

    return (
      <Row centerAlign>
        <Typography.Body type="Body 12">{entity.name}</Typography.Body>
        <div style={{ marginLeft: 8, display: 'flex' }}>{right}</div>
      </Row>
    );
  }

  return (
    <Typography.Body
      type="Body 12"
      isLink
      onClick={onClick}
      data-testid={`link-${entity.id}`}
    >
      {entity.name}
    </Typography.Body>
  );
};

export default EntityListItemLink;
