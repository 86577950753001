import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import Button from 'kingpin/atoms/Button';

import Colors from '../../../../theme/colors';
import Row from '../../../Common/Row';
import Loading from '../../../Loading';

import Icon from '../../../../kingpin/atoms/Icon';
import TextInput from '../../../../kingpin/atoms/TextInput';
import Typography from 'kingpin/atoms/Typography';
import Colors2 from '../../../../theme/Colors2';
import Divider from '../Divider';

export const FieldItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  width: calc(100% - 12px);
  cursor: pointer;
  padding-left: 4px;
  padding-right: 8px;

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background-color: #f2f2f2;
  }
`;

const FieldList = styled.div`
  height: 192px;
  overflow-y: auto;
  padding: 0 8px;
  margin: 8px 0;
`;
interface SelectedListProps {
  isSearching: boolean;
}
const SelectedList = styled.div<SelectedListProps>``;

const ClearSelected = styled.div`
  margin-top: 8px;
  padding-left: 4px;
  margin-bottom: 4px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const PreSelected = ({
  alreadySelectedKeywords,
  onKeywordSelected,
  onKeywordDeselected,
  selectedOptions,
  isSearching,
  clearSelectedOptions,
  canClearSelectedOptions,
}: {
  alreadySelectedKeywords: string[];
  selectedOptions: string[];
  onKeywordSelected: (k: string) => void;
  onKeywordDeselected: (k: string) => void;
  isSearching: boolean;
  canClearSelectedOptions: boolean;
  clearSelectedOptions: () => void;
}) => (
  <SelectedList isSearching={isSearching}>
    {canClearSelectedOptions && (
      <ClearSelected onClick={clearSelectedOptions}>
        <Typography.Body type="Label" color={Colors2.Grey['5']}>
          Clear selected items
        </Typography.Body>
      </ClearSelected>
    )}
    {alreadySelectedKeywords.map((o) => (
      <FieldItem
        key={o}
        onClick={() => {
          if (selectedOptions.includes(o)) {
            onKeywordDeselected(o);
          } else {
            onKeywordSelected(o);
          }
        }}
      >
        <Typography.Body type={'Body 12'}>{o}</Typography.Body>
        {selectedOptions.includes(o) && (
          <Icon icon="checkmark" color={Colors.FLEETOPS_PURPLE} />
        )}
      </FieldItem>
    ))}
  </SelectedList>
);

const KeywordPicker = ({
  isSearching,
  canClearSelectedOptions,
  alreadySelectedKeywords,
  options,
  selectedOptions,
  searchText,
  onSearchTextChanged,
  onKeywordSelected,
  onKeywordDeselected,
  onConfirm,
  isEditing,
  isLoadingDataTypes,
  clearSelectedOptions,
  onBlanksToggled,
  showBlanksTicked,
  isValid,
}: {
  isSearching: boolean;
  canClearSelectedOptions: boolean;
  alreadySelectedKeywords?: string[];
  options: string[];
  selectedOptions: string[];
  searchText: string;
  onSearchTextChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeywordSelected: (k: string) => void;
  onKeywordDeselected: (k: string) => void;
  onConfirm: () => void;
  isEditing: boolean;
  isLoadingDataTypes: boolean;
  clearSelectedOptions: () => void;
  onBlanksToggled: () => void;
  showBlanksTicked: boolean;
  isValid: boolean;
}) => (
  <div>
    <div style={{ marginBottom: 8, padding: '0px 8px' }}>
      <TextInput
        value={searchText}
        onChange={onSearchTextChanged}
        icon="search"
        inputSize="Small"
        placeholder="Search"
        autoFocus
      />
    </div>
    <Divider />
    <FieldList>
      {options.length === 0 && isLoadingDataTypes && <Loading isSmall />}
      {alreadySelectedKeywords && alreadySelectedKeywords.length > 0 && (
        <PreSelected
          isSearching={isSearching}
          alreadySelectedKeywords={alreadySelectedKeywords}
          canClearSelectedOptions={canClearSelectedOptions}
          onKeywordDeselected={onKeywordDeselected}
          onKeywordSelected={onKeywordSelected}
          selectedOptions={selectedOptions}
          clearSelectedOptions={clearSelectedOptions}
        />
      )}
      <FieldItem onClick={onBlanksToggled}>
        <Typography.Body type="Body 12">(Blank)</Typography.Body>
        {showBlanksTicked && (
          <Icon icon="checkmark" color={Colors.FLEETOPS_PURPLE} />
        )}
      </FieldItem>
      {options.map((o) => (
        <FieldItem
          key={o}
          onClick={() => {
            if (selectedOptions.includes(o)) {
              onKeywordDeselected(o);
            } else {
              onKeywordSelected(o);
            }
          }}
        >
          <Typography.Body type="Body 12">{o}</Typography.Body>
          {selectedOptions.includes(o) && (
            <Icon icon="checkmark" color={Colors.FLEETOPS_PURPLE} />
          )}
        </FieldItem>
      ))}
      {options.length === 0 && !isLoadingDataTypes && (
        <div style={{ marginLeft: 4, marginTop: 4 }}>
          <Typography.Body type="Body 12">No options found</Typography.Body>
        </div>
      )}
    </FieldList>
    <Divider />
    <Row
      centerAlign
      style={{ justifyContent: 'flex-end', marginTop: 8, padding: '0px 8px' }}
    >
      <Button
        type="Primary"
        size="Small"
        onClick={onConfirm}
        isDisabled={!isValid}
        testId={'drilldown-add'}
        label={isEditing ? 'Update' : 'Add'}
      />
    </Row>
  </div>
);

export default KeywordPicker;
