import { useContext, useEffect, useState } from 'react';
import DatasetDefinitionsContext from '../contexts/DatasetDefinitionsContext';
import BaseViewsContext from '../contexts/BaseViewsContext';

const useFieldDefinitions = (dataTypes?: string[]) => {
  const { baseViews } = useContext(BaseViewsContext);
  const { datasets: allDatasets } = useContext(DatasetDefinitionsContext);
  const [fieldDefinitions, setFieldDefinitions] = useState<FleetOps.Field[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    const datasets = (() => {
      if (dataTypes && dataTypes.length > 0) {
        return dataTypes
          .map((dt) => allDatasets.find((d) => d.type === dt))
          .filter((d) => !!d) as FleetOps.DatasetDefinition[];
      } else {
        return allDatasets;
      }
    })();

    const fieldDefs = [] as FleetOps.Field[];
    datasets.forEach((dataset) => {
      fieldDefs.push(
        ...dataset.fields
          .filter((f) => f.type !== 'geo_point')
          .map((f) => {
            const baseView = baseViews[dataset.type];
            if (baseView) {
              const baseFieldView = baseView.fields[f.field];
              if (baseFieldView) {
                return {
                  ...f,
                  alias: baseFieldView.nameAlias,
                  dataType: dataset.type,
                };
              } else {
                return f;
              }
            } else {
              return f;
            }
          }),
      );
    });

    setFieldDefinitions(fieldDefs);
    setIsLoading(false);
  }, [allDatasets, baseViews, dataTypes]);

  return { fieldDefinitions, isLoading };
};

export default useFieldDefinitions;
