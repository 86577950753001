import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import Colors2 from '../../../../theme/Colors2';
import ShowOnMouseOverCss from '../../../../cssSnippets/ShowOnMouseOverCss';

const ContainerPadding = css`
  padding: 24px 16px;
`;

const Block = styled.div`
  ${ContainerPadding}
  ${ShowOnMouseOverCss}
  overflow-x: hidden;
`;

const ContainerDiv = styled(motion.div)`
  background-color: ${Colors2.Grey['9']};
  border: 1px solid #e8e8e8;
  border-radius: 6px;

  margin-bottom: 32px;

  position: relative;
  width: 100%;
`;

const GroupInputStyles = {
  Block,
  ContainerDiv,
};

export default GroupInputStyles;
