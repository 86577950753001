import React, { useContext } from 'react';

import ComparisonContext from '../../../contexts/ComparisonContext';
import Card from '../Card';
import CanvasCard from '../../Visualisations/CanvasCard';
import DashboardGadgetBottomBar from '../../DashboardGadgetBottomBar';
import SmartCardContent from './SmartCardContent';
import { ChartTopBar } from '../../Chart';
import DashboardGadgetContext from '../../../contexts/DashboardGadgetContext';

const SmartCardContainer = ({
  chartDefinition,
}: {
  chartDefinition: V5ChartDefinition;
}) => {
  const { dashboardGadget } = useContext(DashboardGadgetContext);

  return (
    <CanvasCard
      topBar={<ChartTopBar chartDefinition={chartDefinition} />}
      content={<SmartCardContent chartDefinition={chartDefinition} />}
      bottomBar={dashboardGadget ? <DashboardGadgetBottomBar /> : undefined}
    />
  );
};

const Gate = ({ chartDefinition }: { chartDefinition: V5ChartDefinition }) => {
  const { currentComparison: comparison } = useContext(ComparisonContext);

  if (comparison && comparison.compareType === 'compareTo') {
    return <Card chartDefinition={chartDefinition} />;
  }

  return <SmartCardContainer chartDefinition={chartDefinition} />;
};

export default Gate;
