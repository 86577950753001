import { useCallback, useContext } from 'react';
import BaseViewsContext from '../contexts/BaseViewsContext';
import metricTypeCheckers from '../types/metricTypeCheckers';
import CompoundMetricsContext from '../contexts/CompoundMetricsContext';
import toSentenceCase from 'services/toSentenceCase';

const useGetFieldLabel = () => {
  const { baseViews } = useContext(BaseViewsContext);
  const { compoundMetricInputsLookup } = useContext(CompoundMetricsContext);

  const getFieldLabel = useCallback(
    ({
      field,
      dataType,
      isVerbose,
    }: {
      field: string;
      dataType: string;
      isVerbose?: boolean;
    }): string => {
      const baseView = baseViews[dataType];
      if (!baseView) {
        if (process.env.NODE_ENV !== 'test') {
          console.warn(
            `getFieldLabel: BaseView not found for Datatype: ${dataType}`,
          );
        }
        return toSentenceCase(field);
      }

      const baseField = baseView.fields[field];
      if (!baseField) {
        if (process.env.NODE_ENV !== 'test') {
          console.warn(
            `getFieldLabel: BaseField not found for Datatype:Field - ${dataType}:${field}`,
          );
        }

        return toSentenceCase(field);
      }

      if (baseField.nameAlias) {
        return isVerbose
          ? `${field} (alias: ${baseField.nameAlias})`
          : baseField.nameAlias;
      }

      return toSentenceCase(field);
    },
    [baseViews],
  );

  const getMetricFieldLabel = useCallback(
    (metric: Metrics.NormalMetric | Metrics.CompoundMetric) => {
      if (metricTypeCheckers.isNormalMetric(metric)) {
        return getFieldLabel({
          field: metric.field,
          dataType: metric.dataType,
        });
      }

      const inputMetrics = compoundMetricInputsLookup[metric.id];
      if (!inputMetrics) {
        return '';
      }

      return inputMetrics
        .map((iM) =>
          getFieldLabel({
            field: iM.field,
            dataType: iM.dataType,
          }),
        )
        .join(', ');
    },
    [compoundMetricInputsLookup, getFieldLabel],
  );

  return { getMetricFieldLabel, getFieldLabel };
};

export default useGetFieldLabel;
