import { useContext, useEffect, useState } from 'react';
import AccountPickerContext from '../contexts/AccountPickerContext';
import STORE from '../store';
import isDefined from '../isDefined';
import MetricsContext from '../contexts/MetricsContext';

export const buildCompoundMetricsLookup = ({
  allCompoundMetrics,
  allMetrics,
}: {
  allCompoundMetrics: Metrics.CompoundMetric[];
  allMetrics: Metrics.NormalMetric[];
}) => {
  const newLookup = {} as {
    [compoundMetricId: string]: Metrics.NormalMetric[] | undefined;
  };
  allCompoundMetrics.forEach((compoundMetric) => {
    const inputMetrics = compoundMetric.metricIds
      .map((mid) => allMetrics.find((m) => m.id === mid))
      .filter(isDefined);

    newLookup[compoundMetric.id] = inputMetrics;
  });

  return newLookup;
};

const useCompoundMetrics = () => {
  const { allMetrics } = useContext(MetricsContext);

  const [compoundMetrics, setCompoundMetrics] = useState<
    Metrics.CompoundMetric[]
  >([]);
  const [isLoadingCompoundMetrics, setIsLoadingCompoundMetrics] =
    useState<boolean>(true);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const [compoundMetricInputsLookup, setCompoundMetricInputsLookup] = useState<{
    [compoundMetricId: string]: Metrics.NormalMetric[] | undefined;
  }>({});

  useEffect(() => {
    const listener = STORE.metricDefinitions
      .getCompoundMetricsRef({ accountId: selectedAccountId })
      .onSnapshot((snapshot) => {
        const data: Metrics.CompoundMetric[] = [];
        snapshot.docs.forEach((d) => {
          const item = d.data();
          if (!item.isHidden) {
            data.push(item);
          }
        });

        setCompoundMetrics(data);
        setCompoundMetricInputsLookup(
          buildCompoundMetricsLookup({ allCompoundMetrics: data, allMetrics }),
        );
        setIsLoadingCompoundMetrics(false);
      });
    return () => {
      listener();
    };
  }, [allMetrics, selectedAccountId]);

  return {
    compoundMetrics,
    isLoadingCompoundMetrics,
    compoundMetricInputsLookup,
  };
};

export default useCompoundMetrics;
