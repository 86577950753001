import React, { useEffect, useState } from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';

import MetricsInfoButton from './MetricsInfoButton';
import usePopup from '../../hooks/usePopup';
import MetricInfoConstants from './constants';
import MetricInfoPopup from './MetricInfoPopup';
import ErrorBoundary from '../Common/ErrorBoundary';
import MetricsInfoPopupContext from 'contexts/MetricsInfoPopupContext';
import useMetricsInfoFromVis from './hooks/useMetricsInfoFromVis';
import getMetricInfoLabel from './MetricInfoPopup/getMetricInfoLabel';
import useWindowSize from '../../hooks/useWindowSize';
import MEDIA from '../../media';

const MetricsInfoButtonContainer = ({
  visualisation,
}: {
  visualisation?: ExtendedVisualisationDefinition | Metrics.Metric;
}) => {
  const { windowWidth } = useWindowSize();
  const metricsInfo = useMetricsInfoFromVis(visualisation);
  const [tooltip, setTooltip] = useState<string>('');
  useEffect(() => {
    if (metricsInfo.length === 0) {
      setTooltip('');
    } else if (metricsInfo.length === 1) {
      setTooltip(`Click to see ${getMetricInfoLabel(metricsInfo[0])}`);
    } else {
      setTooltip(`Click to see metric definitions`);
    }
  }, [metricsInfo]);
  const { isOpen, open, close } = usePopup();

  if (metricsInfo.length === 0 || !visualisation) {
    return null;
  }

  const baseWidth =
    windowWidth <= MEDIA.MEASUREMENTS.WIDTH_BREAKPOINT_SMALL
      ? MetricInfoConstants.POPUP_WIDTH_SMALL
      : MetricInfoConstants.POPUP_WIDTH;
  return (
    <>
      <MetricsInfoButton onClick={open} tooltip={tooltip} />
      <ModalTransition>
        {isOpen && (
          <Modal
            width={
              metricsInfo.length > 1
                ? `calc(${baseWidth} + ${MetricInfoConstants.SIDEBAR_WIDTH})`
                : baseWidth
            }
            height={MetricInfoConstants.POPUP_HEIGHT}
            onClose={close}
            shouldScrollInViewport={false}
            autoFocus={false}
          >
            <MetricInfoPopup metricsInfo={metricsInfo} close={close} />
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};

const Gate = ({
  visualisation,
}: {
  visualisation?: ExtendedVisualisationDefinition | Metrics.Metric;
}) => (
  <ErrorBoundary isHiddenError>
    <MetricsInfoPopupContext.Provider value={{ vis: visualisation }}>
      <MetricsInfoButtonContainer visualisation={visualisation} />
    </MetricsInfoPopupContext.Provider>
  </ErrorBoundary>
);

export default Gate;
