import { useCallback, useContext } from 'react';
import AnalyticsContext from 'contexts/AnalyticsContext';
import WallboardContext from 'contexts/WallboardContext';
import filterPlateTypeCheckers from 'types/filterPlateTypeCheckers';
import useSetDrillDowns from './useSetDrillDowns';
import useIsReportOrDashboardCard from './useIsReportOrDashboardCard';

const useOnDrillDownConfirmed = ({
  close,
  drillDown,
  isScope,
  onUpdated,
  onManualFilterChanged,
  onFilterAdded,
  stage,
  isEditing,
}: {
  close: () => void;
  drillDown?: ReportDrillDownType;
  isScope: boolean;
  onUpdated?: (newDrillDown: ReportDrillDownType) => void;
  onManualFilterChanged?: () => void;
  onFilterAdded?: () => void;
  stage: 'fieldSelection' | 'valueSelection';
  isEditing: boolean;
}) => {
  const { isReport, isDashboardGadget } = useIsReportOrDashboardCard();

  const { isWallboard } = useContext(WallboardContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const setDrillDowns = useSetDrillDowns({ isScope });

  const onConfirmed = useCallback(
    (newDrillDown: ReportDrillDownType) => {
      const newPlate: FilterPlate = {
        id: newDrillDown.id,
        type: 'Fixed',
        fixedValue: newDrillDown,
      };
      if (stage === 'fieldSelection') {
        return;
      }
      if (isReport) {
        if (isScope) {
          trackEvent('Report - Edit - Scope Changed');
        } else {
          trackEvent('Report - Filter Changed');
        }
      }
      if (isDashboardGadget) {
        trackEvent('Dashboard Card - Edit - Filter Changed', {
          isWallboardSlide: isWallboard ? 'true' : 'false',
        });
      }
      if (onManualFilterChanged) {
        onManualFilterChanged();
      }

      if (onFilterAdded && !isEditing) {
        onFilterAdded();
      }
      if (drillDown === undefined) {
        setDrillDowns((currentDrills) => [...currentDrills, newPlate]);
      } else {
        if (onUpdated) {
          onUpdated(newDrillDown);
          close();
        } else {
          setDrillDowns((currentDrillDowns) => {
            return currentDrillDowns.map((d) => {
              if (
                filterPlateTypeCheckers.isFixed(d) &&
                d.fixedValue.id === newPlate.id
              ) {
                // This condition exists to handle dodgy data regarding
                // gadgets which were copied from a templated dashboard
                // where the base ID of a plate is set to something unexpected.

                // Review this for deletion come summer 2023
                // It's possible a migration is required, but we cannot
                // justify the engineering effort for this today
                return newPlate;
              } else if (d.id === newPlate.id) {
                return newPlate;
              } else {
                return d;
              }
            });
          });
        }
      }

      if (!onUpdated) {
        close();
      }
    },
    [
      close,
      drillDown,
      isDashboardGadget,
      isEditing,
      isReport,
      isScope,
      isWallboard,
      onFilterAdded,
      onManualFilterChanged,
      onUpdated,
      setDrillDowns,
      stage,
      trackEvent,
    ],
  );

  return onConfirmed;
};

export default useOnDrillDownConfirmed;
