import STORE from '../../store';

const updateGoal = async (newGoal: GeneralGoal, accountId: string) => {
  const { id } = newGoal;
  await STORE.visualisations
    .getGoalsRef({ accountId })
    .doc(id)

    .set(newGoal);
};

export default updateGoal;
