const MODAL_PADDING_HORIZONTAL = 24;
const MODAL_PADDING_VERTICAL = 16;

const DEFAULT_WIZARD_STATE = {
  currentStep: 'dataset set up' as 'dataset set up',
  isDatasetStepComplete: false,
  isMetricsStepComplete: false,
  isFieldsStepComplete: false,
  config: {
    datasetStep: {
      interval: 'week' as 'week',
      intervalLength: 1,
    },
    fieldsStep: {
      fields: [],
    },
    metricsStep: {
      metrics: [],
    },
  },
  isValid: false,
};

const PERFORMANCE_WIZARD_CONSTANTS = {
  MODAL_PADDING_VERTICAL,
  MODAL_PADDING_HORIZONTAL,
  DEFAULT_WIZARD_STATE,
};

export default PERFORMANCE_WIZARD_CONSTANTS;
