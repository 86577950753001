import React, { useCallback, useEffect, useState } from 'react';
import Modal from '@atlaskit/modal-dialog';
import styled from 'styled-components';

import Row from '../Common/Row';

import Button from 'kingpin/atoms/Button';
import Icon from './Icon';
import Typography from 'kingpin/atoms/Typography';
import Colors2 from '../../theme/Colors2';
import Inputs from '../Inputs';

const MainDiv = styled.div`
  background-color: white;
`;
const BodyDiv = styled.div`
  padding: 40px 32px 24px 32px;
  border-bottom: solid 1px ${Colors2.Primary['8']};

  display: flex;
  flex-direction: column;
  align-items: center;
`;
const BottomBarDiv = styled.div`
  padding: 16px 32px;
`;

const DangerConfirmationModal = ({
  title,
  body,
  checkboxText,
  isOpen,
  close,
  onConfirmed,
}: {
  title: string;
  body: string;
  checkboxText: string;
  isOpen: boolean;
  close: () => void;
  onConfirmed: () => void;
}) => {
  const [isCheckboxTicked, setIsCheckboxTicked] = useState<boolean>(false);
  const onConfirmClicked = useCallback(() => {
    if (!isCheckboxTicked) {
      return;
    }

    onConfirmed();
  }, [isCheckboxTicked, onConfirmed]);

  useEffect(() => {
    if (!isOpen) {
      setIsCheckboxTicked(false);
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      onClose={close}
      shouldScrollInViewport={false}
      autoFocus={false}
      width={448}
    >
      <MainDiv>
        <BodyDiv>
          <Icon type={'danger'} />
          <div style={{ marginBottom: 12 }}>
            <Typography.Header type="H4">{title}</Typography.Header>
          </div>
          <div style={{ marginBottom: 24 }}>
            <Typography.Body type="Body 12">{body}</Typography.Body>
          </div>
          <Row centerAlign>
            <div style={{ marginRight: 16 }}>
              <Inputs.Checkbox
                isChecked={isCheckboxTicked}
                onToggled={setIsCheckboxTicked}
                testId={'confirm-danger-checkbox'}
              />
            </div>
            <Typography.Body type="Body 12">{checkboxText}</Typography.Body>
          </Row>
        </BodyDiv>
        <BottomBarDiv>
          <Row spaceBetween centerAlign>
            <Button
              type="Tertiary"
              size="Small"
              onClick={close}
              label="Cancel"
            />
            <Button
              testId={!isCheckboxTicked ? undefined : 'confirm-danger'}
              onClick={onConfirmClicked}
              label="Continue"
              isDisabled={!isCheckboxTicked}
              type="Primary"
              size="Small"
            />
          </Row>
        </BottomBarDiv>
      </MainDiv>
    </Modal>
  );
};

export default DangerConfirmationModal;
