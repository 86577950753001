import useIsOnProductTour from '../../hooks/useIsOnProductTour';
import React, { useContext } from 'react';
import WallboardContext from '../../contexts/WallboardContext';

const ShowOnMouseOverUnlessTour = ({
  children,
  isForcedVisible,
}: {
  children: JSX.Element | JSX.Element[];
  isForcedVisible?: boolean;
}) => {
  const { isWallboard, isWallboardBuilder } = useContext(WallboardContext);
  const isVisible =
    useIsOnProductTour() || isForcedVisible || process.env.NODE_ENV === 'test';

  if (isWallboard && !isWallboardBuilder) {
    return null;
  }

  return <div className={isVisible ? '' : 'showOnMouseOver'}>{children}</div>;
};

export default ShowOnMouseOverUnlessTour;
