import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import RemindersGadgetContent from './RemindersGadgetContent';
import useDateScope from '../../../hooks/useDateScope';
import useFilterInput from '../../../hooks/useFilterInput';
import getReminders from './getReminders';
import moment from 'moment';
import GqlClientContext from '../../../contexts/GqlClientContext';

const useReminders = ({
  allowedEvents,
  eventTypes,
}: {
  allowedEvents: string[];
  eventTypes: string[];
}) => {
  const { client } = useContext(GqlClientContext);
  const dateScope = useDateScope({});
  const filterInput = useFilterInput();
  const [reminders, setReminders] = useState<Reminder[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    let isActive = true;
    setIsLoading(true);
    getReminders({
      reminderEventNames: eventTypes.length > 0 ? eventTypes : allowedEvents,
      filters: [filterInput],
      dateScope,
      client,
    }).then((newReminders) => {
      if (isActive) {
        setReminders(newReminders);
        setIsLoading(false);
      }
    });

    return () => {
      isActive = false;
    };
  }, [allowedEvents, client, dateScope, eventTypes, filterInput]);

  return { reminders, isLoading };
};

const useSortedReminders = (reminders: Reminder[]) => {
  const [pastReminders, setPastReminders] = useState<SortedReminders[]>([]);
  const [futureReminders, setFutureReminders] = useState<SortedReminders[]>([]);
  const [overdueReminders, setOverdueReminders] = useState<Reminder[]>([]);
  const [hasEvents, setHasEvents] = useState<boolean>(reminders.length > 0);

  useEffect(() => {
    const allDates = _.uniq(reminders.map((r) => r.date)).sort();
    // Past Dates
    const pastDates = allDates.filter((d) =>
      moment(d).add({ day: 1 }).isBefore(moment()),
    );
    const newPast = pastDates.reduce((acc, date) => {
      acc.push({
        date,
        reminders: reminders.filter((r) => r.date === date && !r.overdue),
      });
      return acc;
    }, [] as SortedReminders[]);
    setPastReminders(newPast.filter((s) => s.reminders.length > 0));

    // Future Dates
    const futureDates = allDates.filter((d) => !pastDates.includes(d));
    const newFuture = futureDates.reduce((acc, date) => {
      acc.push({
        date,
        reminders: reminders.filter((r) => r.date === date && !r.overdue),
      });
      return acc;
    }, [] as SortedReminders[]);
    setFutureReminders(newFuture);

    setHasEvents(reminders.length > 0);
  }, [reminders]);

  useEffect(() => {
    setOverdueReminders(reminders.filter((r) => r.overdue));
  }, [reminders]);

  return { pastReminders, futureReminders, overdueReminders, hasEvents };
};

const RemindersGadgetContainer = ({
  gadget,
  allowedEvents,
  setAllowedEvents,
  eventTypes,
}: {
  gadget: VisualisationDefinitions.Reminders;
  allowedEvents: string[];
  setAllowedEvents: React.Dispatch<React.SetStateAction<string[]>>;
  eventTypes: string[];
}) => {
  const { reminders, isLoading } = useReminders({
    allowedEvents,
    eventTypes,
  });
  const { pastReminders, futureReminders, overdueReminders, hasEvents } =
    useSortedReminders(reminders);

  useEffect(() => {
    setAllowedEvents(gadget.events);
  }, [gadget.events, setAllowedEvents]);

  return (
    <RemindersGadgetContent
      pastReminders={pastReminders}
      futureReminders={futureReminders}
      isLoading={isLoading}
      overdueReminders={overdueReminders}
      hasEvents={hasEvents}
    />
  );
};

export default RemindersGadgetContainer;
