import React, { useContext, useEffect, useState } from 'react';
import AccountPickerContext from '../../contexts/AccountPickerContext';
import ScorecardsContext from '../../contexts/ScorecardsContext';
import STORE from 'store';

const useScorecards = () => {
  const { accountRef, selectedAccountId } = useContext(AccountPickerContext);
  const [scorecards, setScorecards] = useState<Scorecards.Scorecard[]>([]);
  const [scorecardsLookup, setScorecardsLookup] = useState<{
    [id: string]: Scorecards.Scorecard | undefined;
  }>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setScorecards([]);
    setIsLoading(true);

    STORE.visualisations
      .getScorecardsRef({ accountId: selectedAccountId })
      .onSnapshot((snapshot) => {
        const data: Scorecards.Scorecard[] = [];
        snapshot.docs.forEach((doc) => data.push(doc.data()));
        setScorecards(data.filter((s) => !s.isHidden));
        setIsLoading(false);
      });
  }, [accountRef, selectedAccountId]);

  useEffect(() => {
    const newLookup = {} as {
      [id: string]: Scorecards.Scorecard | undefined;
    };
    scorecards.forEach((s) => {
      newLookup[s.id] = s;
    });
    setScorecardsLookup(newLookup);
  }, [scorecards]);

  return {
    scorecards,
    scorecardsLookup,
    isLoading,
  };
};

const ScorecardsProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { scorecards, scorecardsLookup, isLoading } = useScorecards();

  return (
    <ScorecardsContext.Provider
      value={{ scorecards, scorecardsLookup, isLoading }}
    >
      {children}
    </ScorecardsContext.Provider>
  );
};

export default ScorecardsProvider;
