import styled, { css } from 'styled-components';
import Colors from '../../../theme/colors';
import Measurements from '../../../theme/measurements';

const secondaryStyles = css`
  background-color: ${Colors.FLEETOPS_RED};
`;

const noPaddingStyle = css`
  // This might be confusing. Look at the margin / padding in the main styles.
  margin: unset;
`;

const CardHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${Colors.WHITE};
  padding: ${Measurements.CARD_PADDING};
  ${(props) => props.noPadding && noPaddingStyle};
  ${(props) => props.secondary && secondaryStyles};
`;

export default CardHeading;
