import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

const publishCostModel = async ({
  costModelId,
  client,
}: {
  costModelId: string;
  client: ApolloClient<NormalizedCacheObject>;
}): Promise<string> => {
  const mutation = {
    mutation: gql`
      mutation publishCostModel($costModelId: ID!) {
        publishCostModel(costModelId: $costModelId)
      }
    `,
    variables: {
      costModelId,
    },
  };

  const response = await client.mutate(mutation);
  return response.data.publishCostModel;
};

export default publishCostModel;
