import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Cell from '../V5Gadget/Matrix/Cell';
import DetailsSlideOutContext from '../../contexts/DetailsSlideOutContext';
import GridCellErrorBoundary from '../GridCellErrorBoundary';
import useIsGridLinksDisabled from '../../hooks/useIsGridLinksDisabled';
import { ICellRendererParams } from 'ag-grid-community';

const LinkText = styled.span`
  color: #0041ea !important;
  cursor: pointer;
  &:hover {
    color: #1275f7 !important;
  }
`;

const JobCellRenderer = ({ data, node }: ICellRendererParams) => {
  const { setSelectedOrderNumber, openDetailsSlide } = useContext(
    DetailsSlideOutContext,
  );
  const isLinksDisabled = useIsGridLinksDisabled();
  const getBaseValueToUse = useCallback(() => {
    const { jobNo, orderNo } = data;
    if (jobNo) {
      return jobNo as string | string[];
    } else if (orderNo) {
      return orderNo as string | string[];
    } else {
      return undefined;
    }
  }, [data]);
  const [baseValueToUse, setBaseValueToUse] = useState<
    string[] | string | undefined
  >(getBaseValueToUse());
  const getValueToRender = useCallback(() => {
    if (Array.isArray(baseValueToUse)) {
      return baseValueToUse.join(', ');
    }

    return baseValueToUse;
  }, [baseValueToUse]);
  const [valueToRender, setValueToRender] = useState(getValueToRender());

  useEffect(() => {
    setBaseValueToUse(getBaseValueToUse());
  }, [getBaseValueToUse, setBaseValueToUse]);

  useEffect(() => {
    setValueToRender(getValueToRender());
  }, [getValueToRender]);

  const onClick = useCallback(() => {
    if (Array.isArray(baseValueToUse)) {
      return undefined;
    }

    setSelectedOrderNumber(baseValueToUse);
    openDetailsSlide();
  }, [baseValueToUse, openDetailsSlide, setSelectedOrderNumber]);

  if (!data) {
    return null;
  }

  if ((node.rowPinned && node.rowPinned === 'bottom') || isLinksDisabled) {
    return (
      <Cell>
        <span>{valueToRender}</span>
      </Cell>
    );
  }

  if (Array.isArray(baseValueToUse)) {
    return (
      <Cell>
        <span>{valueToRender}</span>
      </Cell>
    );
  }

  if (baseValueToUse === '-') {
    return (
      <Cell>
        <span>-</span>
      </Cell>
    );
  }

  return (
    <Cell onClick={onClick}>
      <LinkText>{valueToRender}</LinkText>
    </Cell>
  );
};
JobCellRenderer.propTypes = {
  data: PropTypes.shape({
    jobNo: PropTypes.string,
  }),
};

const Gate = (params: ICellRendererParams) => (
  <GridCellErrorBoundary
    params={params}
    childComponent={<JobCellRenderer {...params} />}
  />
);

export default Gate;
