import React, { useContext, useEffect, useState } from 'react';

import { DATA_MANAGER_TOP_ACTION_DIV_ID } from '../../AllSets/constants';
import ReactPortal from '../../../../components/ReactPortal';
import DatasetDefinitionsContext from '../../../../contexts/DatasetDefinitionsContext';
import Row from '../../../../components/Common/Row';
import TimelineButton from './TimelineButton';
import AddPerformanceDatasetFieldsButton from './AddPerformanceDatasetFieldsButton';

const PerformanceDatasetTopActions = ({ dataset }: { dataset?: string }) => {
  const [config, setConfig] = useState<
    FleetOps.PerformanceDatasetConfig | undefined
  >();
  const { getPerformanceConfig } = useContext(DatasetDefinitionsContext);

  // setConfig
  useEffect(() => {
    if (!dataset) {
      setConfig(undefined);
      return;
    }

    setConfig(getPerformanceConfig(dataset));
  }, [dataset, getPerformanceConfig, setConfig]);

  if (!config) {
    return null;
  }

  return (
    <ReactPortal elementId={DATA_MANAGER_TOP_ACTION_DIV_ID}>
      <Row>
        <div style={{ marginRight: 16 }}>
          <AddPerformanceDatasetFieldsButton config={config} />
        </div>
        <TimelineButton config={config} />
      </Row>
    </ReactPortal>
  );
};

export default PerformanceDatasetTopActions;
