import React from 'react';
import styled, { css } from 'styled-components';
import Colors2 from '../../theme/Colors2';
import CheckedImg from './checked.png';

const DisabledCss = css`
  background-color: ${Colors2.Grey['5']};
  cursor: not-allowed;
`;

const Unchecked = styled.div<{ isSmall?: boolean; isDisabled?: boolean }>`
  width: ${(props) => (props.isSmall ? '12px' : '16px')};
  height: ${(props) => (props.isSmall ? '12px' : '16px')};
  border: 1px solid ${Colors2.Grey['7']};
  border-radius: 2px;
  cursor: pointer;
  user-select: none;

  ${(props) => props.isDisabled && DisabledCss}
`;

const Checked = styled.div<{ isSmall?: boolean; isDisabled?: boolean }>`
  width: ${(props) => (props.isSmall ? '12px' : '16px')};
  height: ${(props) => (props.isSmall ? '12px' : '16px')};
  border: 1px solid ${Colors2.Secondary.info};
  border-radius: 2px;
  background-color: ${Colors2.Secondary.info};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;

  ${(props) => props.isDisabled && DisabledCss}
`;

const Checkbox = ({
  isChecked,
  onToggled,
  isSmall,
  testId,
  isDisabled,
}: {
  isChecked: boolean;
  onToggled: (newValue: boolean) => void;
  isSmall?: boolean;
  testId?: string;
  isDisabled?: boolean;
}) => {
  if (isChecked) {
    return (
      <Checked
        onClick={isDisabled ? undefined : () => onToggled(false)}
        isSmall={isSmall}
        data-testid={testId}
        isDisabled={isDisabled}
      >
        <img src={CheckedImg} alt="checked" />
      </Checked>
    );
  } else {
    return (
      <Unchecked
        onClick={isDisabled ? undefined : () => onToggled(true)}
        isSmall={isSmall}
        data-testid={testId}
        isDisabled={isDisabled}
      />
    );
  }
};

export default Checkbox;
