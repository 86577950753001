export const TABS = {
  USERS: 'users',
  LOCALE: 'locale',
  TARGET: 'slas',
  BILLING: 'billing',
  INTEGRATIONS: 'connectors',
  DATA: 'data',
  GOALS: 'goals',
  ADVANCED: 'advanced',
  COMMISSIONS: 'commissions',
  SSO: 'SSO Configurations',
  COPY_CONTENT: 'Copy Content',
};
