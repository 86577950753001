import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import Tooltip from './Tooltip';

import Cell from './V5Gadget/Matrix/Cell';
import colors from '../theme/colors';
import Row from './Common/Row';
import { DeltaNumber } from './OnTargetCellRenderer';

import { ArrowElem } from './Gadget/Delta';
import GridCellErrorBoundary from './GridCellErrorBoundary';
import formatFloat from '../api/getChartSeries/formatFloat';
import useValueFormatters from 'hooks/useValueFormatters';
import CommentsContext from '../contexts/CommentsContext';
import BoardContext from 'contexts/BoardContext';
import Badge from './Badge';
import Colors2 from '../theme/Colors2';
import isCustomerLaneBoard from 'isCustomerLaneBoard';
import cadenceToLabel from './BoardSlideOut/Performance/cadenceToLabel';
import Typography from 'kingpin/atoms/Typography';

const CommentsBadge = () => {
  const { comments } = useContext(CommentsContext);
  if (!comments) {
    return null;
  }

  if (comments.length > 0) {
    return (
      <div style={{ marginTop: 12 }}>
        <Badge
          text={`${comments.length} comment${comments.length > 1 ? 's' : ''}`}
          badgeType="Default"
        />
      </div>
    );
  } else {
    return null;
  }
};

export const TooltipContent = ({
  progressText,
  onTargetText,
  dateText,
  isGood,
  tooltipTitle,
  delta,
}: {
  progressText: string;
  onTargetText: string;
  dateText?: string;
  isGood: boolean;
  tooltipTitle: string;
  delta?: number;
}) => {
  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <Typography.Body type={'Body 14'}>{tooltipTitle}</Typography.Body>
      </div>
      <div style={{ marginBottom: 4 }}>
        <Typography.Body type={'Body 14'}>{progressText}</Typography.Body>
      </div>
      {dateText !== undefined && (
        <Typography.Body type={'Body 14'} color={Colors2.Grey['5']}>
          {dateText}
        </Typography.Body>
      )}
      <Row centerAlign style={{ justifyContent: 'center' }}>
        <ArrowElem
          isGood={isGood}
          delta={delta ? delta : isGood ? 1 : -1}
          isGrid={false}
        />
        <Typography.Body
          type={'Body 14'}
          color={isGood ? colors.MEDIUM_SEA_GREEN : colors.RADICAL_RED}
        >
          {onTargetText}
        </Typography.Body>
      </Row>

      <CommentsBadge />
    </div>
  );
};

export const getProgressCellText = (
  data: any,
  cell: BoardProgressCell,
  formatValue: ({
    formatting,
    value,
  }: {
    formatting: MetricFormatting;
    value: number;
  }) => string,
  fieldView?: FleetOps.BaseViewField,
) => {
  const total = data[cell.totalField];
  const commitment = data[cell.targetField];
  const precision = fieldView ? fieldView.formatting.precision : 0;
  const prefix = fieldView ? fieldView.formatting.prefix : undefined;
  const postfix = fieldView ? fieldView.formatting.postfix : undefined;
  if (
    commitment === undefined ||
    typeof commitment !== 'number' ||
    typeof total !== 'number'
  ) {
    return '-';
  }

  const formatting = {
    prefix,
    precision,
    postfix,
  } as MetricFormatting;

  const a = formatValue({ value: total, formatting });

  const b = formatValue({ value: commitment, formatting });

  return `${a} / ${b}`;
};

const useCadence = (
  params: ICellRendererParams,
  cell: BoardProgressCell | undefined,
): CommitmentCadence | undefined => {
  const { board } = useContext(BoardContext);
  const getCadence = useCallback(() => {
    if (!cell) {
      return;
    }

    if (isCustomerLaneBoard(board)) {
      return 'weekly';
    }

    if (cell.cadenceField) {
      return params.data[cell.cadenceField];
    }

    return cell.cadence;
  }, [board, params, cell]);

  const [cadence, setCadence] = useState<CommitmentCadence>(() => getCadence());
  useEffect(() => {
    setCadence(getCadence());
  }, [getCadence]);

  return cadence;
};

const ProgressCellRenderer = (params: ICellRendererParams) => {
  const { colDef } = params;
  const cell = colDef
    ? (colDef.cellRendererParams.cell as BoardProgressCell)
    : undefined;
  const cadence = useCadence(params, cell);
  const { formatValue } = useValueFormatters();

  if (!colDef || !cell) {
    return <Cell />;
  }
  const fieldView = colDef.cellRendererParams.fieldView as
    | FleetOps.BaseViewField
    | undefined;

  const diff = params.data[cell.differenceField];

  const cellText = getProgressCellText(
    params.data,
    cell,
    formatValue,
    fieldView,
  );
  if (cellText === '-') {
    return <Cell rightAlign>{cellText}</Cell>;
  }
  const isGood = diff >= 0;
  const color = isGood ? colors.MEDIUM_SEA_GREEN : colors.RADICAL_RED;

  const adverb = isGood ? 'ahead of' : 'behind';
  const prettyCadence = cadence
    ? cadenceToLabel(cadence).toString()
    : undefined;

  const onTargetText = `${Math.abs(formatFloat(diff, 0))} ${adverb} target`;
  const dateText = cadence ? `This ${prettyCadence}` : undefined;

  return (
    <Cell rightAlign>
      <Tooltip
        isAltTooltip
        content={
          <TooltipContent
            progressText={cellText}
            onTargetText={onTargetText}
            dateText={dateText}
            isGood={isGood}
            tooltipTitle={cell.tooltipTitle}
          />
        }
      >
        <Row centerAlign style={{ justifyContent: 'flex-end' }}>
          <DeltaNumber color={color}>{cellText}</DeltaNumber>
        </Row>
      </Tooltip>
    </Cell>
  );
};

const Gate = (params: ICellRendererParams) => (
  <GridCellErrorBoundary
    params={params}
    childComponent={<ProgressCellRenderer {...params} />}
  />
);

export default Gate;
