import React, { useCallback, useEffect, useState } from 'react';

import useFieldOptions from './useFieldOptions';
import useGetFilterType from './useGetFilterType';
import useStateValidator from './useStateValidator';
import getIdentifier from '../../../../getIdentifier';
import useConditionOptions from './useConditionOptions';
import useTextOptions from './useTextOptions';
import useBooleanOptions from './useBooleanOptions';
import FilterForm from './FilterForm';

const FilterFormContainer = ({
  addFilter,
  updateFilter,
  removeFilter,
  filter,
  close,
}: {
  addFilter?: (filter: FilterBuilder.Client.FieldFilter) => void;
  updateFilter?: (filter: FilterBuilder.Client.FieldFilter) => void;
  removeFilter?: (filter: FilterBuilder.Client.FieldFilter) => void;
  filter?: FilterBuilder.Client.FieldFilter;
  close: () => void;
}) => {
  const getFilterType = useGetFilterType();
  const [field, setField] = useState<string | undefined>(
    filter ? filter.field : undefined,
  );
  const [filterType, setFilterType] = useState<
    FilterBuilder.FilterType | undefined
  >(filter ? getFilterType(filter.field) : undefined);
  const [condition, setCondition] = useState<
    FilterBuilder.Condition | undefined
  >(filter ? filter.condition : undefined);
  const [value, setValue] = useState<
    string | number | string[] | boolean | undefined
  >(filter ? filter.value : undefined);

  const onFieldSelected = useCallback(
    ({
      field,
      condition,
    }: {
      field: string;
      condition: FilterBuilder.Condition | undefined;
    }) => {
      setCondition(condition);
      setField(field);
    },
    [],
  );
  const { fieldOptions, recentFieldOptions } = useFieldOptions(
    field,
    onFieldSelected,
  );
  const conditionOptions = useConditionOptions(field, condition, setCondition);
  const { options: textOptions, isLoading: isLoadingTextOptions } =
    useTextOptions({
      value,
      setValue,
      field,
    });
  const booleanOptions = useBooleanOptions({ value, setValue });
  useStateValidator({ value, filterType, field });
  const onNumberInputChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(Number.parseFloat(event.target.value));
    },
    [],
  );

  const onSaveClicked = useCallback(() => {
    if (field === undefined || condition === undefined || value === undefined) {
      return;
    }

    const newFilter = {
      key: filter ? filter.key : getIdentifier(undefined, true),
      field,
      condition,
      value,
    };

    if (filter) {
      if (updateFilter) {
        updateFilter(newFilter);
      }
    } else {
      if (addFilter) {
        addFilter(newFilter);
      }
    }
    close();
  }, [addFilter, close, condition, field, filter, updateFilter, value]);

  const onRemoveClicked = useCallback(() => {
    if (removeFilter && filter) {
      removeFilter(filter);
    }
  }, [filter, removeFilter]);

  useEffect(() => {
    if (!field) {
      setFilterType(undefined);
      return;
    }

    setFilterType(getFilterType(field));
  }, [field, getFilterType]);

  return (
    <FilterForm
      field={field}
      fieldOptions={fieldOptions}
      recentFieldOptions={recentFieldOptions}
      condition={condition}
      conditionOptions={conditionOptions}
      filterType={filterType}
      booleanOptions={booleanOptions}
      textOptions={textOptions}
      value={value}
      onSaveClicked={onSaveClicked}
      onNumberInputChanged={onNumberInputChanged}
      filter={filter}
      onRemoveClicked={filter && removeFilter ? onRemoveClicked : undefined}
      isLoadingTextOptions={isLoadingTextOptions}
    />
  );
};

export default FilterFormContainer;
