import { useContext, useEffect, useState } from 'react';
import useMetricUsageReport from '../../../../../hooks/useMetricUsageReport';
import InSituMetricEditorContext from '../../../../../contexts/InSituMetricEditorContext';

const useShouldWarn = (
  metric: Metrics.NormalMetric | Metrics.CompoundMetric | undefined,
) => {
  const { isInSituEditor } = useContext(InSituMetricEditorContext);
  const usageReport = useMetricUsageReport(metric);
  const [shouldWarn, setShouldWarn] = useState<boolean>(false);
  const [usageCount, setUsageCount] = useState<number | undefined>();

  useEffect(() => {
    if (usageReport && usageReport.usageCount > 1 && isInSituEditor) {
      setShouldWarn(true);
      setUsageCount(usageReport.usageCount);
      return;
    }

    setShouldWarn(false);
    setUsageCount(undefined);
  }, [isInSituEditor, usageReport]);

  return {
    shouldWarn,
    usageCount,
  };
};
export default useShouldWarn;
