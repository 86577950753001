import { useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import ChartDefinitionsContext from '../contexts/ChartDefinitionsContext';

const useSelectedGadget = (dashboardGadgetChart?: VisualisationDefinition) => {
  const { gadgetId } = useParams<{ gadgetId?: string }>();
  const { definitionsLookup } = useContext(ChartDefinitionsContext);

  const [selected, setSelected] = useState<
    VisualisationDefinition | undefined
  >();

  useEffect(() => {
    if (dashboardGadgetChart) {
      setSelected(dashboardGadgetChart);
    } else if (gadgetId) {
      setSelected(definitionsLookup[gadgetId]);
    }
  }, [dashboardGadgetChart, definitionsLookup, gadgetId]);

  return {
    selected,
  };
};

export default useSelectedGadget;
