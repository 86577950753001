import React from 'react';
import BaseStep from './BaseStep';
import GroupStep from './GroupStep';
import CategoriesStep from './CategoriesStep';
import OutputsStep from './OutputsStep';
import styled from 'styled-components';
import Colors2 from 'theme/Colors2';

export const AccordionContent = styled.div<{ isActive: boolean }>`
  height: ${(props) => (props.isActive ? 'fit-content' : '0')};
  overflow: hidden;
  transition: height 0.2s ease-out;
  border-bottom: ${(props) =>
    props.isActive ? `1px solid ${Colors2.Grey['8']}` : 'none'};
`;

const ConfigSteps = () => {
  return (
    <>
      <BaseStep />
      <GroupStep />
      <CategoriesStep />
      <OutputsStep />
    </>
  );
};

export default ConfigSteps;
