import React from 'react';
import { Option } from '../../../kingpin/atoms/ContextMenu';
import Button from '../../../kingpin/atoms/Button';

const ExportButton = ({
  onClick,
  id,
  isContextMenuItem,
}: {
  onClick: () => void;
  id: string;
  isContextMenuItem?: boolean;
}) => (
  <div id={'export-grid'}>
    {isContextMenuItem && (
      <Option id={id} onClick={onClick} label={'Export'} icon={'export'} />
    )}
    {!isContextMenuItem && (
      <div id={id}>
        <Button
          size={'Small'}
          type={'Primary'}
          icon={'export'}
          label="Export"
          onClick={onClick}
        />
      </div>
    )}
  </div>
);

export default ExportButton;
