import React from 'react';

const BoardSlideOutContext = React.createContext<BoardSlideOutContextType>({
  selectFieldValue: () => {},
  drillDownField: '',
  close: () => {},
  isOpen: false,
  sections: [],
  gridRequiresRefresh: false,
  setGridRequiresRefresh: () => {},
});

export default BoardSlideOutContext;
