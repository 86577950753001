import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import UpArrowGreenSrc from 'images/up-arrow-green.svg';
import DownArrowRedSrc from 'images/down-arrow-red.svg';

import Cell from './V5Gadget/Matrix/Cell';
import colors from '../theme/colors';
import Row from './Common/Row';
import styled from 'styled-components';
import GridCellErrorBoundary from './GridCellErrorBoundary';
import useValueFormatters from 'hooks/useValueFormatters';
import Typography from '../kingpin/atoms/Typography';

export const ArrowBackdrop = styled.div<{ color: string }>`
  width: 14px;
  height: 14px;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
  background-color: ${(props) => props.color}20;
`;

export const DeltaNumber = ({
  color,
  children,
}: {
  color: string;
  children: JSX.Element | JSX.Element[] | null | undefined | string;
}) => {
  if (children === null) {
    return null;
  }

  return (
    <div>
      <Typography.Body type={'Body 12'} color={color}>
        {children}
      </Typography.Body>
    </div>
  );
};

export const Arrow = styled.img`
  width: 14px;
  height: 14px;
`;

const formatDelta = (
  delta: number,
  formatValue: ({
    formatting,
    value,
  }: {
    formatting: MetricFormatting;
    value: number;
  }) => string,
  fieldView?: FleetOps.BaseViewField,
) => {
  const precision = fieldView ? fieldView.formatting.precision : 0;
  const prefix = fieldView ? fieldView.formatting.prefix : undefined;
  const postfix = fieldView ? fieldView.formatting.postfix : undefined;

  const formatting = {
    prefix,
    precision,
    postfix,
  } as MetricFormatting;

  return formatValue({ value: delta, formatting });
};

const OnTargetCellRenderer = (props: ICellRendererParams) => {
  const { formatValue } = useValueFormatters();
  const { colDef } = props;
  if (!colDef) {
    return <Cell />;
  }
  const cell = colDef.cellRendererParams.cell as BoardOnTargetCell | undefined;
  const fieldView = colDef.cellRendererParams.fieldView as
    | FleetOps.BaseViewField
    | undefined;
  if (!cell) {
    return null;
  }
  const delta = props.data[cell.differenceField];
  if (!delta) {
    return null;
  }

  if (delta >= 0) {
    const color = colors.MEDIUM_SEA_GREEN;
    return (
      <Cell rightAlign>
        <Row centerAlign style={{ justifyContent: 'flex-end' }}>
          <ArrowBackdrop color={color}>
            <Arrow src={UpArrowGreenSrc} />
          </ArrowBackdrop>
          <DeltaNumber color={color}>
            {formatDelta(delta, formatValue, fieldView)}
          </DeltaNumber>
        </Row>
      </Cell>
    );
  } else {
    const color = colors.RADICAL_RED;
    return (
      <Cell rightAlign>
        <Row centerAlign style={{ justifyContent: 'flex-end' }}>
          <ArrowBackdrop color={color}>
            <Arrow src={DownArrowRedSrc} />
          </ArrowBackdrop>
          <DeltaNumber color={color}>
            {formatDelta(delta, formatValue, fieldView)}
          </DeltaNumber>
        </Row>
      </Cell>
    );
  }
};

const Gate = (params: ICellRendererParams) => (
  <GridCellErrorBoundary
    params={params}
    childComponent={<OnTargetCellRenderer {...params} />}
  />
);

export default Gate;
