import firebase from 'firebase/compat/app';
import STORE_CONSTANTS from './constants';
import withoutNulls from '../api/search/withoutNulls';

const previousUploadConverter = {
  toFirestore(
    previousUpload: DataManager.PreviousUpload,
  ): firebase.firestore.DocumentData {
    return withoutNulls({ ...previousUpload });
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ): DataManager.PreviousUpload {
    const data = {
      ...snapshot.data(options),
    };
    return data as DataManager.PreviousUpload;
  },
};

const getPreviousUploadsRef = ({
  accountId,
  db,
}: {
  accountId: string;
  db?: firebase.firestore.Firestore;
}) => {
  return (db ? db : firebase.firestore())
    .collection('accounts')
    .doc(accountId)
    .collection(STORE_CONSTANTS.PREVIOUS_UPLOADS_COLLECTION)
    .withConverter(previousUploadConverter);
};

export default getPreviousUploadsRef;
