import React, { useEffect, useState } from 'react';
import FormattingInput from './FormattingInput';

const FIX_OPTIONS = [
  'currency',
  'percentage',
  'duration',
  'fuelEfficiency',
  'distance',
  'weight',
  'temperature',
] as PreOrPostFix[];

const FormattingInputContainer = ({
  formatting,
  setFormatting,
  isDisabled,
}: {
  formatting: MetricFormatting;
  setFormatting: React.Dispatch<React.SetStateAction<MetricFormatting>>;
  isDisabled?: boolean;
}) => {
  const { precision, prefix, postfix, positiveDeltaIsGood, currencyType } =
    formatting;
  const [currentCurrencyType, setCurrentCurrencyType] = useState<
    CurrencyType | undefined
  >(currencyType);
  const [currentPrecision, setCurrentPrevision] = useState<number | undefined>(
    precision,
  );
  const [currentPositiveDeltaIsGood, setCurrentPositiveDeltaIsGood] = useState<
    boolean | undefined
  >(positiveDeltaIsGood);
  const [prefixOptions] = useState<DropdownOption[]>([
    {
      label: 'None',
      onSelected: () => {
        setFormatting((f) => ({ ...f, prefix: undefined }));
      },
    },
    ...FIX_OPTIONS.map((o) => ({
      label: o,
      onSelected: () => {
        setFormatting((f) => ({ ...f, prefix: o }));
      },
    })),
  ]);
  const [postfixOptions] = useState<DropdownOption[]>([
    {
      label: 'None',
      onSelected: () => {
        setFormatting((f) => ({ ...f, postfix: undefined }));
      },
    },
    ...FIX_OPTIONS.map((o) => ({
      label: o,
      onSelected: () => {
        setFormatting((f) => ({ ...f, postfix: o }));
      },
    })),
  ]);

  useEffect(() => {
    setFormatting((f) => ({ ...f, currencyType: currentCurrencyType }));
  }, [currentCurrencyType, setFormatting]);

  useEffect(() => {
    setFormatting((f) => ({ ...f, precision: currentPrecision }));
  }, [currentPrecision, setFormatting]);

  useEffect(() => {
    setFormatting((f) => ({
      ...f,
      positiveDeltaIsGood: currentPositiveDeltaIsGood,
    }));
  }, [currentPositiveDeltaIsGood, setFormatting]);

  return (
    <FormattingInput
      precision={precision}
      setPrecision={setCurrentPrevision}
      prefix={prefix}
      prefixOptions={prefixOptions}
      currencyType={currencyType}
      setCurrencyType={setCurrentCurrencyType}
      postfix={postfix}
      postfixOptions={postfixOptions}
      positiveDeltaIsGood={!!positiveDeltaIsGood}
      setPositiveDeltaIsGood={setCurrentPositiveDeltaIsGood}
      isDisabled={isDisabled}
    />
  );
};

export default FormattingInputContainer;
