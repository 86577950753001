import { useCallback, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import ExcludedPaceDatesContext from 'contexts/ExcludedPaceDatesContext';

const useExcludedDates = () => {
  const [excludedDates, setExcludedDates] = useState<string[]>([]);
  const { dateRanges } = useContext(ExcludedPaceDatesContext);

  const getExcludedDates = useCallback((range: DateRangeInput) => {
    const newExcludedDates: string[] = [];
    const { startDate, endDate } = range;
    if (!startDate || !endDate) {
      return [];
    }
    let currentDate = startDate;
    while (currentDate <= endDate) {
      newExcludedDates.push(currentDate);
      currentDate = moment(currentDate).add({ day: 1 }).format('YYYY-MM-DD');
    }

    return newExcludedDates;
  }, []);

  useEffect(() => {
    const newExcludedDates = dateRanges
      .map(getExcludedDates)
      .reduce((a, b) => [...a, ...b], []);

    setExcludedDates(_.uniq(newExcludedDates));
  }, [dateRanges, getExcludedDates]);

  return excludedDates;
};

export default useExcludedDates;
