import React, { useEffect, useState } from 'react';
import GridProvider from '../../../../contextProviders/GridProvider';
import Grid from '../../../Grid';
import isDefined from '../../../../isDefined';
import _ from 'lodash';
import { GridApi } from 'ag-grid-community';

const createBaseViewOverride = ({
  simpleGridConfig,
  baseView,
}: {
  simpleGridConfig: SimpleGridConfig;
  baseView: FleetOps.BaseView;
}): FleetOps.BaseView => {
  const newFields = {} as {
    [field: string]: FleetOps.BaseViewField | undefined;
  };
  if (simpleGridConfig.fieldAliases) {
    simpleGridConfig.fieldAliases.forEach((field) => {
      const base = baseView.fields[field.field];
      if (base) {
        newFields[field.field] = {
          ...base,
          nameAlias: field.alias,
        };
      }
    });
  } else {
    simpleGridConfig.fields.forEach((field) => {
      const base = baseView.fields[field];
      if (base) {
        newFields[field] = {
          ...base,
          nameAlias: _.startCase(base.field),
        };
      }
    });
  }

  const newBaseView = {
    ...baseView,
    fields: newFields,
    fieldOrder: Object.values(newFields)
      .filter(isDefined)
      .map((f) => f.field),
  };

  return newBaseView;
};

const SimpleGridContent = ({
  chartDefinition,
  simpleGridConfig,
  parentBaseView,
  gridApi,
  setGridApi,
}: {
  chartDefinition: V5ChartDefinition;
  simpleGridConfig: SimpleGridConfig;
  parentBaseView: FleetOps.BaseView;
  gridApi?: GridApi;
  setGridApi: React.Dispatch<React.SetStateAction<GridApi | undefined>>;
}) => {
  const { sort } = simpleGridConfig;
  const [baseView, setBaseView] = useState<FleetOps.BaseView>(() =>
    createBaseViewOverride({ simpleGridConfig, baseView: parentBaseView }),
  );

  useEffect(() => {
    setBaseView(
      createBaseViewOverride({ simpleGridConfig, baseView: parentBaseView }),
    );
  }, [parentBaseView, simpleGridConfig]);

  return (
    <GridProvider
      key={sort.map((s) => s.field).join(',')}
      dataType={baseView.type}
      baseViewOverride={baseView}
      sortOverride={sort}
      titleOverride={chartDefinition.name}
      gridApi={gridApi}
      setGridApi={setGridApi}
    >
      <Grid disableAutoLayout hasBorders={false} hasDarkHeader={false} />
    </GridProvider>
  );
};

export default SimpleGridContent;
