import React from 'react';
import PropTypes from 'prop-types';

import Screen from '../../components/Common/ScreenLayout/Screen';
import Content from '../../components/Common/ScreenLayout/Content';

import toSentenceCase from '../../services/toSentenceCase';
import { TABS } from '../../propTypes/settings';
import FleetOpsSuspense from '../../FleetOpsSuspense';
import SettingsTopBar from './SettingsTopBar';

const BaseSettings = ({
  selectedTab,
  children,
  title,
  rightButton,
  leftButton,
}: {
  selectedTab?: string;
  children: JSX.Element | JSX.Element[];
  title?: string;
  rightButton?: JSX.Element;
  leftButton?: JSX.Element;
}) => {
  return (
    <Screen>
      <Content>
        <div style={{ width: '100%' }}>
          <SettingsTopBar
            title={title ? title : toSentenceCase(selectedTab || '')}
            rightButton={rightButton}
            leftButton={leftButton}
          />
          <FleetOpsSuspense>{children}</FleetOpsSuspense>
        </div>
      </Content>
    </Screen>
  );
};

BaseSettings.propTypes = {
  selectedTab: PropTypes.oneOf(Object.values(TABS)),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  title: PropTypes.string,
};

export default BaseSettings;
