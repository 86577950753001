import React from 'react';

import Row from '../../../Common/Row';
import SideBar from './SideBar';
import ListTop from './ListTop';
import BottomBar from './BottomBar';
import List from './List';
import MetricUpdateBanner from './MetricUpdateBanner';
import PopupStyles from './PopupStyles';

const MetricPickerPopup = ({
  datasetOptions,
  otherDatasetOptions,
  isShowAllEnabled,
  onShowAllDatasetsClicked,
  filteredMetrics,
  searchText,
  onSearchTextChanged,
  onCreateSingleUseMetricClicked,
  navigationState,
  onMetricClicked,
  onMetricConfirmed,
  currentSelectedMetric,
  isShowAllDatasetsButtonDisabled,
  close,
}: {
  datasetOptions: DropdownOption[];
  otherDatasetOptions: DropdownOption[];
  isShowAllEnabled: boolean;
  onShowAllDatasetsClicked: () => void;
  filteredMetrics: Metrics.Metric[];
  searchText: string;
  onSearchTextChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCreateSingleUseMetricClicked: () => void;
  navigationState: MetricPickerNavigationState;
  onMetricClicked: (metric: Metrics.Metric) => void;
  onMetricConfirmed: () => void;
  currentSelectedMetric?: Metrics.Metric;
  isShowAllDatasetsButtonDisabled?: boolean;
  close: () => void;
}) => (
  <PopupStyles.PopupContent.ContentOuter data-testid={'metric-picker-popup'}>
    <MetricUpdateBanner metrics={filteredMetrics} />
    <Row>
      <SideBar
        datasetOptions={datasetOptions}
        isShowAllEnabled={isShowAllEnabled}
        otherDatasetOptions={otherDatasetOptions}
        onShowAllDatasetsClicked={onShowAllDatasetsClicked}
        isShowAllDatasetsButtonDisabled={isShowAllDatasetsButtonDisabled}
      />
      <PopupStyles.PopupContent.ContentDiv>
        <ListTop
          searchText={searchText}
          onSearchTextChanged={onSearchTextChanged}
          onCreateSingleUseMetricClicked={onCreateSingleUseMetricClicked}
          navigationState={navigationState}
        />
        <List
          metrics={filteredMetrics}
          onMetricClicked={onMetricClicked}
          selectedMetricId={
            currentSelectedMetric ? currentSelectedMetric.id : undefined
          }
        />
      </PopupStyles.PopupContent.ContentDiv>
    </Row>
    <BottomBar
      onMetricConfirmed={onMetricConfirmed}
      currentSelectedMetric={currentSelectedMetric}
      close={close}
    />
  </PopupStyles.PopupContent.ContentOuter>
);

export default MetricPickerPopup;
