import _ from 'lodash';

const buildPeriodComparisonDate = ({
  periods,
  selectedPeriod,
}: {
  selectedPeriod?: Period;
  periods?: Period[];
}): DateRangeInput | undefined => {
  if (selectedPeriod && periods) {
    const selectedPeriodIndex = periods.findIndex((p) =>
      _.isEqual(p, selectedPeriod),
    );
    const comparePeriodIndex = selectedPeriodIndex + 1;
    if (comparePeriodIndex < periods.length) {
      const comparePeriod = periods[comparePeriodIndex];
      return {
        startDate: comparePeriod.startDate,
        endDate: comparePeriod.endDate,
      };
    }
  }
};

export default buildPeriodComparisonDate;
