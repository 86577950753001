import STORE from '../../store';

const deleteScorecard = async (
  scorecard: Scorecards.Scorecard,
  accountId: string,
) => {
  const { id } = scorecard;

  await STORE.visualisations.getScorecardsRef({ accountId }).doc(id).delete();
};

export default deleteScorecard;
