import React, { useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';

import DataManagerSpecificSetContext from '../../../contexts/DataManagerSpecificSetContext';
import Typography from 'kingpin/atoms/Typography';
import AnalyticsContext from '../../../contexts/AnalyticsContext';
import useGetDatasetDefinition from '../../../hooks/useGetDatasetDefinition';

const ConditionalLink = ({
  text,
  to,
  dataset,
  noMargin,
}: {
  text: string;
  to: string;
  dataset: string;
  noMargin?: boolean;
}) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const { isOnSpecificSet } = useContext(DataManagerSpecificSetContext);
  const getDatasetDefinition = useGetDatasetDefinition();

  const onLinkClicked = useCallback(() => {
    const datasetDef = getDatasetDefinition(dataset);
    trackEvent('Data Manager - Dataset Opened', {
      dataset,
      isPerformanceDataset:
        datasetDef && datasetDef.isPerformance ? 'true' : 'false',
    });
  }, [dataset, getDatasetDefinition, trackEvent]);

  if (isOnSpecificSet) {
    return <Typography.Body type="Body 12">{text}</Typography.Body>;
  } else {
    return (
      <Link to={to} onClick={onLinkClicked}>
        <Typography.Body type="Body 12">{text}</Typography.Body>
      </Link>
    );
  }
};

export default ConditionalLink;
