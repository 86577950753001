import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

const buildQuery = ({
  query,
  dataType,
  filters,
  fields,
  groupBy,
  sortBy,
  dateScope,
}: {
  query: string;
  dataType: string;
  filters: FilterInput[];
  dateScope: DateRangeInput;
  fields: GridAggregateField[];
  groupBy: string;
  sortBy: GridSortField[];
}) => ({
  query: gql`
    query ${query}(
      $dataType: String!
      $filters: [FilterInput]!
      $dateScope: [DateRangeInput!]!
      $fields: [AggregateField]!
      $groupBy: String!
      $sortBy: [SortField]!
    ) {
      ${query} (
        dataType: $dataType
        filters: $filters
        dateScope: $dateScope
        fields: $fields
        groupBy: $groupBy
        sortBy: $sortBy
      )
    }
  `,
  variables: {
    dataType,
    filters,
    dateScope,
    fields,
    groupBy,
    sortBy,
  },
});

const fetchTotalsGroupedByTerm = async ({
  query,
  dataType,
  filters,
  dateScope,
  fields,
  groupBy,
  sortBy,
  client,
}: {
  query: string;
  dataType: string;
  filters: FilterInput[];
  dateScope: DateRangeInput;
  fields: GridAggregateField[];
  groupBy: string;
  sortBy: GridSortField[];
  client: ApolloClient<NormalizedCacheObject>;
}) => {
  const gqlQuery = buildQuery({
    query,
    dataType,
    filters,
    dateScope,
    fields,
    groupBy,
    sortBy,
  });
  const response = await client.query(gqlQuery);
  return response.data[query];
};

export default fetchTotalsGroupedByTerm;
