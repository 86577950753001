import { useCallback, useContext } from 'react';
import ChartDefinitionsContext from '../../contexts/ChartDefinitionsContext';
import isUsedInChart from './isUsedInChart';

const useGetChartDefinitionUsage = () => {
  const { definitions } = useContext(ChartDefinitionsContext);

  const getChartDefinitionUsage = useCallback(
    (
      metric: Metrics.NormalMetric | Metrics.CompoundMetric,
    ): VisualisationDefinition[] => {
      return definitions.filter((def) => {
        return isUsedInChart(metric, def);
      });
    },
    [definitions],
  );

  return getChartDefinitionUsage;
};

export default useGetChartDefinitionUsage;
