import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

const buildQuery = () => ({
  query: gql`
    query getPerformanceEntities2 {
      getPerformanceEntities2 {
        entity
        dataTypes
      }
    }
  `,
});

const getEntityOptions = async (
  client: ApolloClient<NormalizedCacheObject>,
): Promise<{ entity: string; dataTypes: string[] }[]> => {
  const gqlQuery = buildQuery();
  const response = await client.query(gqlQuery);
  return response.data.getPerformanceEntities2;
};

export default getEntityOptions;
