import moment from 'moment';

const isoDateToAmerican = (isoDate: string): string => {
  if (isoDate.includes('T')) {
    return moment.utc(isoDate).format("MMM DD 'YY HH:mm");
  }
  return moment.utc(isoDate).format("MMM DD 'YY");
};

export default isoDateToAmerican;
