import React from 'react';

interface PopupInnerContextType {
  isPopup: boolean;
}

const PopupInnerContext = React.createContext<PopupInnerContextType>({
  isPopup: false,
});

export default PopupInnerContext;
