import { useCallback, useContext, useEffect, useState } from 'react';
import LocalTimelineContext from '../../../../../contexts/Timeline/LocalTimelineContext';
import DatasetDefinitionsContext from '../../../../../contexts/DatasetDefinitionsContext';
import PerformanceWizardContext from './PerformanceWizardContext';
import { useGetMetric } from '../../../../GoalShow/useGoalData';
import isDefined from '../../../../../isDefined';
import useGetFieldLabel from 'hooks/useGetFieldLabel';

const useCreateUpdateTimelineEvents = () => {
  const { wizardState } = useContext(PerformanceWizardContext);
  const { addEvent } = useContext(LocalTimelineContext);
  const { performanceConfigs } = useContext(DatasetDefinitionsContext);
  const getMetric = useGetMetric();
  const { getFieldLabel } = useGetFieldLabel();
  const [originalConfig, setOriginalConfig] =
    useState<FleetOps.PerformanceDatasetConfig>();

  // setOriginalConfig
  useEffect(() => {
    if (!wizardState.id) {
      setOriginalConfig(undefined);
      return;
    }
    setOriginalConfig(performanceConfigs.find((c) => c.id === wizardState.id));
  }, [performanceConfigs, wizardState.id]);

  const getAddedRemovedMetrics = useCallback((): {
    addedMetrics: Metrics.Metric[];
    removedMetrics: Metrics.Metric[];
  } => {
    if (!originalConfig) {
      return { addedMetrics: [], removedMetrics: [] };
    }

    const originalMetricIds = originalConfig.metrics.map((m) => m.metricId);
    const newMetricIds = wizardState.config.metricsStep.metrics.map(
      (m) => m.metricId,
    );
    const addedMetricIds = newMetricIds.filter(
      (mid) => !originalMetricIds.includes(mid),
    );
    const addedMetrics = addedMetricIds.map(getMetric).filter(isDefined);

    const removedMetricIds = originalMetricIds.filter(
      (mid) => !newMetricIds.includes(mid),
    );
    const removedMetrics = removedMetricIds.map(getMetric).filter(isDefined);

    return { addedMetrics, removedMetrics };
  }, [getMetric, originalConfig, wizardState.config.metricsStep.metrics]);

  const getAddedRemovedFieldLabels = useCallback((): {
    addedFieldLabels: string[];
    removedFieldLabels: string[];
  } => {
    if (!originalConfig) {
      return { addedFieldLabels: [], removedFieldLabels: [] };
    }

    const originalFields = originalConfig.fields
      .map(({ dataType, fields }) =>
        fields.map((f) =>
          getFieldLabel({ dataType, field: f, isVerbose: true }),
        ),
      )
      .reduce((a, b) => [...a, ...b], []);
    const newFields = wizardState.config.fieldsStep.fields
      .map(({ dataType, fields }) =>
        fields.map((f) =>
          getFieldLabel({ dataType, field: f, isVerbose: true }),
        ),
      )
      .reduce((a, b) => [...a, ...b], []);

    const addedFieldLabels = newFields.filter(
      (f) => !originalFields.includes(f),
    );
    const removedFieldLabels = originalFields.filter(
      (f) => !newFields.includes(f),
    );

    return {
      addedFieldLabels,
      removedFieldLabels,
    };
  }, [getFieldLabel, originalConfig, wizardState.config.fieldsStep.fields]);

  const getChangedDateFieldMetrics = useCallback((): {
    metricName: string;
    previousDateField: string;
    newDateField: string;
  }[] => {
    if (!originalConfig) {
      return [];
    }

    return originalConfig.metrics
      .map(({ metricId, dateField }) => {
        const newConfig = wizardState.config.metricsStep.metrics.find(
          (m) => m.metricId === metricId,
        );
        if (!newConfig) {
          return undefined;
        }

        if (newConfig.dateField === dateField) {
          return undefined;
        }

        const metric = getMetric(metricId);
        if (!metric) {
          return undefined;
        }

        return {
          metricName: metric.name,
          previousDateField: dateField,
          newDateField: newConfig.dateField,
        };
      })
      .filter(isDefined);
  }, [getMetric, originalConfig, wizardState.config.metricsStep.metrics]);

  const createUpdateTimelineEvents = useCallback(async () => {
    if (!addEvent) {
      return;
    }

    const { addedMetrics, removedMetrics } = getAddedRemovedMetrics();
    if (addedMetrics.length > 0) {
      await addEvent({
        actionText: 'Added Metrics',
        contextText: `${addedMetrics.map((m) => `"${m.name}"`).join(', ')}`,
      });
    }

    if (removedMetrics.length > 0) {
      await addEvent({
        actionText: 'Removed Metrics',
        contextText: `${removedMetrics.map((m) => `"${m.name}"`).join(', ')}`,
      });
    }

    const { addedFieldLabels, removedFieldLabels } =
      getAddedRemovedFieldLabels();
    if (addedFieldLabels.length > 0) {
      await addEvent({
        actionText: 'Added Fields',
        contextText: `${addedFieldLabels.map((f) => `"${f}"`).join(', ')}`,
      });
    }

    if (removedFieldLabels.length > 0) {
      await addEvent({
        actionText: 'Removed Fields',
        contextText: `${addedFieldLabels.map((f) => `"${f}"`).join(', ')}`,
      });
    }

    const changedDateFieldMetrics = getChangedDateFieldMetrics();
    const promises = changedDateFieldMetrics.map(
      ({ metricName, previousDateField, newDateField }) => {
        return addEvent({
          actionText: `Updated date field on metric "${metricName}"`,
          contextText: `From "${previousDateField}" to "${newDateField}"`,
        });
      },
    );
    await Promise.all(promises);
  }, [
    addEvent,
    getAddedRemovedFieldLabels,
    getAddedRemovedMetrics,
    getChangedDateFieldMetrics,
  ]);

  return createUpdateTimelineEvents;
};

export default useCreateUpdateTimelineEvents;
