import STORE from '../../store';

const saveRankingMatrix = ({
  accountId,
  rankingMatrix,
}: {
  accountId: string;
  rankingMatrix: VisualisationDefinitions.RankingMatrix;
}) => {
  return STORE.visualisations
    .getRankingMatricesRef({ accountId })
    .doc(rankingMatrix.id)
    .set(rankingMatrix);
};

export default saveRankingMatrix;
