import { useEffect, useState } from 'react';
import { isKpiRow } from 'hooks/kpiTypeCheckers';
import useValueFormatters from 'hooks/useValueFormatters';

const useKpiFormatter = ({
  kpi,
  metric,
  value,
}: {
  kpi: Scorecards.ScorecardKpi | Scorecards.ManualKpiRow;
  metric?: Metrics.Metric;
  value: number | undefined;
}) => {
  const [name, setName] = useState<string>('');
  const [manualKpiFormatting, setManualKpiFormatting] =
    useState<MetricFormatting>();
  const [formattedValue, setFormattedValue] = useState<string | undefined>();
  const { formatMetric, formatValue } = useValueFormatters();

  useEffect(() => {
    if (isKpiRow(kpi)) {
      setName(kpi.displayName ? kpi.displayName : metric ? metric.name : '');

      if (value === undefined || !metric) {
        setFormattedValue(undefined);
        return;
      }

      setFormattedValue(formatMetric({ metricId: metric.id, value }));
    } else {
      setName(kpi.label);

      const formatting =
        kpi.formatting === 'Currency'
          ? ({
              prefix: 'currency',
              currencyType: kpi.currencyType,
            } as MetricFormatting)
          : kpi.formatting === 'Percentage'
            ? ({
                postfix: 'percentage',
              } as MetricFormatting)
            : {};

      setManualKpiFormatting(formatting);

      setFormattedValue(
        value === undefined ? undefined : formatValue({ value, formatting }),
      );
    }
  }, [formatMetric, formatValue, kpi, metric, value]);
  return { name, formattedValue, manualKpiFormatting };
};

export default useKpiFormatter;
