import { useCallback, useContext } from 'react';
import TargetListContext from '../contexts/TargetListContext';
import TargetFormContext from '../contexts/TargetFormContext';

const useGetForcedGroupField = () => {
  const { isEditing } = useContext(TargetFormContext);
  const { targets } = useContext(TargetListContext);

  const getTargetsForField = useCallback(
    (field: string) => {
      return targets.filter((t) => t.target === field);
    },
    [targets],
  );

  const getForcedGroupField = useCallback(
    (field: string) => {
      const targetsForField = getTargetsForField(field);
      if (isEditing) {
        if (targetsForField.length === 0 || targetsForField.length === 1) {
          return undefined;
        }
      } else {
        if (targetsForField.length === 0) {
          return undefined;
        }
      }

      return targetsForField[0].groupField;
    },
    [getTargetsForField, isEditing],
  );

  return getForcedGroupField;
};

export default useGetForcedGroupField;
