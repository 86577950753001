import getPortalSlug from '../../../navigation/getPortalSlug';

const BASE = `data-manager/costs/activity`;
const buildActivityCostsBase = (portal: Portal | undefined) =>
  `/${getPortalSlug(portal)}${BASE}`;

const buildActivityCostsNew = (portal: Portal | undefined) =>
  `/${getPortalSlug(portal)}${BASE}/new`;

const buildActivityCostsEdit = (portal: Portal | undefined) =>
  `/${getPortalSlug(portal)}${BASE}/edit`;

const COSTS_SET_ROUTES = {
  ACTIVITY_BASE: BASE,
  buildActivityCostsBase,
  ACTIVITY_BASE_NEW: `/${BASE}/new`,
  buildActivityCostsNew,
  ACTIVITY_BASE_EDIT: `/${BASE}/edit`,
  buildActivityCostsEdit,
};

export default COSTS_SET_ROUTES;
