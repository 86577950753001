import React, { useContext, useEffect } from 'react';

import AuthenticationContext from '../../contexts/AuthenticationContext';
import Loading from '../../components/Loading/Loading';
import firebase from 'firebase/compat/app';
import { useNavigate } from 'react-router-dom';
import AppRoutes from '../../navigation/appRoutes';

const SignOutScreen = () => {
  const navigate = useNavigate();
  const { authenticated } = useContext(AuthenticationContext);
  useEffect(() => {
    firebase.auth().signOut();
  }, []);

  useEffect(() => {
    if (!authenticated) {
      navigate(AppRoutes.home);
    }
  }, [authenticated, navigate]);

  return <Loading />;
};

export default SignOutScreen;
