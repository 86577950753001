import React, { useContext, useEffect, useState } from 'react';
import DateInputContext from '../contexts/DateInputContext';
import { LAST_MONTH } from '../components/DateInput/constants';
import useRecentlyUsedRollingWindows from '../components/DateInput/RelativeInput/useRecentlyUsedRollingWindows';
import DateFieldsProvider from './DateFieldsProvider';
import DateFieldsContext from '../contexts/DateFieldsContext';

export interface DateInputProviderProps {
  children?: JSX.Element | JSX.Element[];
  initialDateRange?: DateRangeInput;
  initialRelativeDateRange?: RelativeDateRange;
  initialAdvancedRelativeDateRange?: AdvancedRelativeDateRange;
  initialDateField?: string;
  initialDataTypes?: string[];
}

const InitialDateFieldNameProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { dateFields } = useContext(DateFieldsContext);
  const { dateField, setDateFieldName } = useContext(DateInputContext);

  useEffect(() => {
    const selectedField = dateFields.find((f) => f.field === dateField);
    if (selectedField) {
      setDateFieldName(selectedField.name);
    } else {
      setDateFieldName(dateField);
    }
  }, [dateField, dateFields, setDateFieldName]);

  return <>{children}</>;
};

const DateInputProvider = ({
  children,
  initialDateRange,
  initialDateField,
  initialRelativeDateRange,
  initialDataTypes,
  initialAdvancedRelativeDateRange,
}: DateInputProviderProps) => {
  const recentlyUsedRollingWindows = useRecentlyUsedRollingWindows();
  const [dateRange, setDateRange] = useState<DateRangeInput | undefined>(
    initialDateRange,
  );
  const [relativeDateRange, setRelativeDateRange] = useState<
    RelativeDateRange | undefined
  >(() => {
    if (initialRelativeDateRange) {
      return initialRelativeDateRange;
    }

    if (!initialDateRange && !initialAdvancedRelativeDateRange) {
      return LAST_MONTH;
    }
  });
  const [advancedRelativeDateRange, setAdvancedRelativeDateRange] = useState<
    AdvancedRelativeDateRange | undefined
  >(initialAdvancedRelativeDateRange);
  const [dateField, setDateField] = useState<string>(
    initialDateField ? initialDateField : 'date',
  );
  const [dateFieldName, setDateFieldName] = useState<string>(
    initialDateField ? initialDateField : 'date',
  );
  const [dataTypes, setDataTypes] = useState<string[]>(
    initialDataTypes ? initialDataTypes : [],
  );
  const [isManuallyEntered, setIsManuallyEntered] = useState<boolean>(false);

  return (
    <DateInputContext.Provider
      value={{
        dateRange,
        setDateRange,
        relativeDateRange,
        setRelativeDateRange,
        advancedRelativeDateRange,
        setAdvancedRelativeDateRange,
        dateField,
        setDateField,
        dateFieldName,
        setDateFieldName,
        dataTypes,
        setDataTypes,
        recentlyUsedRollingWindows,
        isManuallyEntered,
        setIsManuallyEntered,
      }}
    >
      <DateFieldsProvider dataTypes={dataTypes}>
        <InitialDateFieldNameProvider>
          <>{children}</>
        </InitialDateFieldNameProvider>
      </DateFieldsProvider>
    </DateInputContext.Provider>
  );
};

export default DateInputProvider;
