import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

const buildQuery = ({
  accountId,
  orderNumber,
}: {
  accountId: string;
  orderNumber: string;
}) => ({
  query: gql`
    query GetOrderSummary($accountId: String!, $orderNo: String!) {
      getOrderSummary(accountId: $accountId, orderNo: $orderNo) {
        customer
        commodity
        shipper
        consignee
        startLocation
        started
        plannedStarted
        finishLocation
        finished
        plannedFinished
        trucks
        trailers
        drivers
      }
    }
  `,
  variables: {
    accountId,
    orderNo: orderNumber,
  },
});

const getOrderSummary = async (
  accountId: string,
  orderNumber: string,
  client: ApolloClient<NormalizedCacheObject>,
) => {
  const query = buildQuery({ accountId, orderNumber });
  const response = await client.query(query);
  return response.data.getOrderSummary;
};

export default getOrderSummary;
