import React from 'react';

interface BoardsContextType {
  boards: (PerformanceBoardTypes.Board | Board)[];
}

const BoardsContext = React.createContext<BoardsContextType>({
  boards: [],
} as BoardsContextType);

export default BoardsContext;
