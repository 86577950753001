import React from 'react';
import Button from 'kingpin/atoms/Button';
import Row from '../Common/Row';

const ReportEditingButtons = ({
  onSave,
  onCancel,
  onSaveAs,
}: {
  onSave: () => void;
  onCancel: () => void;
  onSaveAs: () => void;
}) => (
  <Row centerAlign style={{ height: '100%' }}>
    <Button type="Ghost" size="Small" onClick={onCancel} label="Cancel" />
    <div style={{ marginRight: 8 }} />
    <Button type="Secondary" size="Small" onClick={onSaveAs} label="Save as" />
    <div style={{ marginRight: 8 }} />
    <Button type="Primary" size="Small" onClick={onSave} label="Save" />
  </Row>
);

export default ReportEditingButtons;
