import React, { useContext, useEffect, useState } from 'react';
import AccountContext from '../../../../contexts/AccountContext';
import useV5ChartData from '../../../../hooks/useV5ChartData';
import Loading from '../../../Loading';
import pie from '../../highchartOptions/pieOptions';
import { ChartContent } from '../../../Chart';
import mapGraphqlDayNumberToString from '../../../../mapGraphqlDayNumberToString';
import useChartDrillDowns from '../../../../hooks/useChartDrillDowns';
import useMetric from '../../../../hooks/useMetric';
import DashboardContextMenuContext from '../../../../contexts/DashboardContextMenuContext';
import metricTypeCheckers from '../../../../types/metricTypeCheckers';
import useValueFormatters from '../../../../hooks/useValueFormatters';
import { HighchartsReactRefObject } from 'highcharts-react-official';

interface PieSeriesItem {
  name: string;
  data: { name: string; y: number }[];
  prefix?: PreOrPostFix;
  postfix?: PreOrPostFix;
}

const getSeriesFor = (
  data: V5ChartData,
  chartDef: V5ChartDefinition,
): PieSeriesItem[] => {
  const firstOption = Object.values(data)[0];
  const metric = firstOption.metric;
  const metricIds = (() => {
    if (metricTypeCheckers.isCompoundMetric(metric)) {
      return metric.metricIds;
    }

    return [metric.id];
  })();
  const metricId = firstOption.metric.id;
  const total = Object.values(firstOption.response)
    .map((item) => item[metricId] as number)
    .reduce((accumulator: number, value: number) => accumulator + value, 0);

  const sortedResponse = firstOption.response.sort((rA, rB) => {
    const a = ((rA[metricId] as number) / total) * 100;
    const b = ((rB[metricId] as number) / total) * 100;

    if (a > b) {
      return -1;
    } else if (a === b) {
      return 0;
    } else {
      return 1;
    }
  });

  if (chartDef.groupByDayOfWeek) {
    const firstSeries = {
      name: firstOption.metric.name,
      data: sortedResponse.map((grouping) => ({
        name: mapGraphqlDayNumberToString(grouping['dayOfWeek'] as string),
        y: ((grouping[metricId] as number) / total) * 100,
      })),
      metricIds,
      animation: false,
    };
    return [firstSeries];
  }
  if (chartDef.trendByCalendarInterval || chartDef.trendByFixedIntervalDays) {
    const firstSeries = {
      name: firstOption.metric.name,
      data: sortedResponse.map((grouping) => ({
        name: grouping['date'] as string,
        y: ((grouping[metricId] as number) / total) * 100,
      })),
      metricIds,
      animation: false,
    };
    return [firstSeries];
  }

  if (chartDef.dimensionA) {
    const groupKey = chartDef.dimensionA.field;
    const firstSeries = {
      name: firstOption.metric.name,
      data: sortedResponse.map((grouping) => ({
        name: grouping[groupKey] as string,
        y: ((grouping[metricId] as number) / total) * 100,
      })),
      metricIds,
      animation: false,
    };
    return [firstSeries];
  }
  return [];
};

const PieContent = ({
  chartDefinition,
  chartRef,
  useSmallNoDataMessage,
}: {
  chartDefinition: V5ChartDefinition;
  chartRef: React.MutableRefObject<HighchartsReactRefObject | null>;
  useSmallNoDataMessage: boolean;
}) => {
  // Context
  const { unitsLocale } = useContext(AccountContext);
  const { openMenu } = useContext(DashboardContextMenuContext);

  // State
  const [groupField] = useState(() => {
    if (chartDefinition.dimensionA) {
      return chartDefinition.dimensionA.field;
    }

    return 'date';
  });
  const [options, setOptions] = useState<Highcharts.Options | undefined>();
  const [isNoDataToDisplay, setIsNoDataToDisplay] = useState<boolean>(false);

  // Hooks
  const { data, isLoading } = useV5ChartData(chartDefinition);
  const { formatMetric } = useValueFormatters();
  const metric = useMetric(chartDefinition.series[0].metricId);
  const { onDrillDown, syncChart } = useChartDrillDowns(
    groupField,
    chartRef,
    'pie',
    chartDefinition,
  );

  // Effects
  useEffect(() => {
    if (!metric) {
      return;
    }

    const newOptions = {
      ...pie({
        formatMetric,
        title: ' ',
        precision: metric.formatting.precision,
        openMenu,
        groupField,
        fieldType: chartDefinition.dimensionA
          ? chartDefinition.dimensionA.fieldType
          : undefined,
        chartDef: chartDefinition,
        fontSize: 11,
      }),
    };

    setOptions(newOptions as any as Highcharts.Options);
  }, [
    chartDefinition,
    chartRef,
    formatMetric,
    groupField,
    metric,
    onDrillDown,
    openMenu,
    syncChart,
    unitsLocale,
  ]);

  useEffect(() => {
    if (!data) {
      return;
    }

    const series = getSeriesFor(data, chartDefinition).map((s) => ({
      ...s,
      type: 'pie' as 'pie',
      onDrillDown,
    }));
    const isNoData =
      series.length === 1 &&
      (series[0].data.length === 0 || !series[0].data.some((d) => !isNaN(d.y)));

    setIsNoDataToDisplay(isNoData);
    setOptions((current) => ({
      ...current,
      series,
    }));
  }, [chartDefinition, chartRef, data, onDrillDown]);

  useEffect(() => {
    syncChart();
  }, [syncChart, onDrillDown, data]);

  if (!data || !metric || isLoading) {
    return <Loading />;
  }

  return (
    <ChartContent
      chartRef={chartRef}
      options={options}
      noDataToDisplay={isNoDataToDisplay}
      useSmallNoDataMessage={useSmallNoDataMessage}
    />
  );
};

export default PieContent;
