import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Cell from './V5Gadget/Matrix/Cell';
import CustomerLaneCommitsContext from 'contexts/CustomerLaneCommitsContext';
import CustomerLaneSlideOutContext from '../contexts/CustomerLaneSlideOut';
import {
  buildCustomerLaneSlideOutSearch,
  buildCustomerLaneSlideOutSearchQuery,
} from '../navigation/appRoutes';
import BoardContext from '../contexts/BoardContext';
import GridCellErrorBoundary from './GridCellErrorBoundary';
import useIsGridLinksDisabled from '../hooks/useIsGridLinksDisabled';
import { DASHBOARDS_COLLECTION } from '../constants';
import { FleetopsGridCellRendererParams } from '../types/agGrid';
import EntityLink from './V5Gadget/Matrix/GroupingCellRenderer/EntityLink';
import Row from './Common/Row';
import EntityButtons from './EntityButtons';
import Tooltip from './Tooltip';

const CustomerLaneCellRenderer = (props: FleetopsGridCellRendererParams) => {
  const { value, data, node, colDef } = props;
  const { open } = useContext(CustomerLaneSlideOutContext);
  const { setCommitmentSlideoutParams } = useContext(
    CustomerLaneCommitsContext,
  );

  const { board } = useContext(BoardContext);
  const navigate = useNavigate();
  const location = useLocation();
  const isLinksDisabled = useIsGridLinksDisabled();

  const isTotalRow = node.rowPinned && node.rowPinned === 'bottom';
  const hasOnClick = !window.location.href.includes('search') && !isTotalRow;
  const onClick = () => {
    if (location.pathname.includes('dashboards')) {
      setCommitmentSlideoutParams({
        lane: value,
        documentId: data.configId,
        customerType: data.customer ? 'customer' : 'billTo',
        customer: data.customer ? data.customer : data.billTo,
      });
      open();
    } else if (
      location.pathname.includes('workspaces') ||
      location.pathname.includes('performance-apps') ||
      location.pathname.includes(DASHBOARDS_COLLECTION)
    ) {
      const searchQuery = buildCustomerLaneSlideOutSearchQuery({
        lane: value,
        documentId: data.configId,
        customerType: data.customer ? 'customer' : 'billTo',
        customer: data.customer ? data.customer : data.billTo,
      });
      const newUri = `${location.pathname}?${searchQuery}`;
      navigate(newUri);
      if (open) {
        open();
      }
    } else {
      const search = buildCustomerLaneSlideOutSearch({
        boardId: board.id,
        lane: value,
        documentId: data.configId,
        customerType: data.customer ? 'customer' : 'billTo',
        customer: data.customer ? data.customer : data.billTo,
      });
      navigate(search);
      if (open) {
        open();
      }
    }
  };

  if (hasOnClick && !isLinksDisabled) {
    return (
      <Cell>
        <Row spaceBetween centerAlign>
          <EntityButtons.Interaction
            onClick={onClick}
            text={value}
            tooltip={value}
          />
          <EntityLink colDef={colDef} value={value} node={node} />
        </Row>
      </Cell>
    );
  } else {
    return (
      <Cell>
        <Row spaceBetween centerAlign>
          <Tooltip content={value}>
            <span>{value}</span>
          </Tooltip>
          <EntityLink colDef={colDef} value={value} node={node} />
        </Row>
      </Cell>
    );
  }
};

const Gate = (params: FleetopsGridCellRendererParams) => (
  <GridCellErrorBoundary
    params={params}
    childComponent={<CustomerLaneCellRenderer {...params} />}
  />
);

export default Gate;
