import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.div`
  margin-right: 16px;
  padding: 8px;
  backgroung-color: white;
  border-radius: 3px;
  border: 1px solid rgba(53, 45, 67, 0.12);
  box-shadow: 0 3px 8px 3px rgba(53, 45, 67, 0.07);
`;

const IconImg = styled.img`
  width: 24px;
  height: 24px;
`;

const WidgetIcon = ({ iconSvg }: { iconSvg: string }) => {
  return (
    <IconWrapper>
      <IconImg src={iconSvg} />
    </IconWrapper>
  );
};

export default WidgetIcon;
