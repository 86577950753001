import { useCallback, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import PerformanceWizardContext from '../PerformanceWizardContext';
import useGetMetricDatasets from '../../../../../../components/Inputs/MetricPicker/MetricPickerPopup/hooks/useGetMetricDatasets';
import MetricOptionsContext from '../../../../../../contexts/MetricOptionsContext';
import isDefined from '../../../../../../isDefined';

const useGetDatasetsForPerformanceMetrics = () => {
  const getMetricDatasets = useGetMetricDatasets();
  const { metricOptionsLookup } = useContext(MetricOptionsContext);

  const getDatasetsForPerformanceMetrics = useCallback(
    (
      performanceMetrics: PerformanceConfiguration.Client.PerformanceMetric[],
    ) => {
      const metricIds = performanceMetrics.map((m) => m.metricId);
      const metrics = metricIds
        .map((mId) => metricOptionsLookup[mId])
        .filter(isDefined);
      const datasets = metrics.reduce(
        (currentDatasets, metric) => [
          ...currentDatasets,
          ...getMetricDatasets(metric).map((ds) => ds.type),
        ],
        [] as string[],
      );
      const uniqDatasets = _.uniq(datasets);
      return uniqDatasets;
    },
    [getMetricDatasets, metricOptionsLookup],
  );

  return getDatasetsForPerformanceMetrics;
};

const usePerformanceMetricDatasets = () => {
  const { wizardState } = useContext(PerformanceWizardContext);
  const getDatasetsForPerformanceMetrics =
    useGetDatasetsForPerformanceMetrics();
  const [datasets, setDatasets] = useState<string[]>([]);

  useEffect(() => {
    setDatasets(
      getDatasetsForPerformanceMetrics(wizardState.config.metricsStep.metrics),
    );
  }, [
    getDatasetsForPerformanceMetrics,
    wizardState.config.metricsStep.metrics,
  ]);

  return datasets;
};

export default usePerformanceMetricDatasets;
