import React, { useCallback, useContext } from 'react';
import TimelineEvent from './TimelineEvent';
import OrderDetailsContext from '../../../contexts/OrderDetailsContext';
import isStop from '../../../isStop';
import StopIcon from '../../../images/timeline-stop.svg';
import MoveIcon from '../../../images/timeline-move.svg';
import moment from 'moment';
import displayTime from '../../../formatters/displayTime';

const getStopSubLabel = (stop: Stop) => {
  if (stop.location) {
    return `at ${stop.location}`;
  } else {
    return '';
  }
};

const getMoveSubLabel = (move: Move) => {
  if (move.transitTimeDurationMinutes) {
    return `for ${displayTime(move.transitTimeDurationMinutes, {
      fullUnits: true,
    })}`;
  } else {
    return '';
  }
};

const formatTime = (time: string) => moment.utc(time).format('kk:mm');

const getStopTimeLabel = (stop: Stop) => {
  if (stop.arrivedTimeStamp) {
    return `${formatTime(stop.arrivedTimeStamp)}`;
  }
  return '';
};

const getMoveTimeLabel = (move: Move) => {
  if (!move.startTimeStamp || !move.endTimeStamp) {
    return '';
  }
  return `${formatTime(move.startTimeStamp)} - ${formatTime(
    move.endTimeStamp,
  )}`;
};

const TimelineEventContainer = ({
  timelineEvent,
}: {
  timelineEvent: TimelineEvent;
}) => {
  const { selectedEvent, setSelectedEvent } = useContext(OrderDetailsContext);
  const isSelected =
    selectedEvent && selectedEvent.clientId === timelineEvent.clientId;
  const onClick = useCallback(() => {
    if (isSelected) {
      setSelectedEvent(undefined);
    } else {
      setSelectedEvent(timelineEvent);
    }
  }, [timelineEvent, isSelected, setSelectedEvent]);

  const event = timelineEvent.event;
  const label = isStop(event) ? (event.stage ? event.stage : 'Stop') : 'Move';
  const subLabel = isStop(event)
    ? getStopSubLabel(event)
    : getMoveSubLabel(event);
  const timeLabel = isStop(event)
    ? getStopTimeLabel(event)
    : getMoveTimeLabel(event);
  const underLabel = isStop(event)
    ? event.dwellTimeDurationMinutes
      ? `Stopped for ${event.dwellTimeDurationMinutes} mins`
      : ''
    : '';

  const iconSrc = isStop(event) ? StopIcon : MoveIcon;

  return (
    <TimelineEvent
      isSelected={!!isSelected}
      onClick={onClick}
      iconSrc={iconSrc}
      label={label}
      subLabel={subLabel}
      timeLabel={timeLabel}
      underLabel={underLabel}
    />
  );
};

export default TimelineEventContainer;
