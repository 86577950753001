import aguid from 'aguid';
import { DateTime } from 'luxon';
import { getEmailHtmlContent } from './newComment';

const mentionedInComment = ({
  commenter,
  comment,
  link,
  emailBodyLn1,
  emailBodyLn1Extra,
}: {
  commenter: string;
  comment: CommentType;
  link: string;
  emailBodyLn1: string;
  emailBodyLn1Extra?: string;
}): Notifications.BasicNotification => ({
  id: aguid(),
  isBasic: true,
  type: 'MENTIONED_IN_COMMENT',
  seen: false,
  read: false,
  deleted: false,
  createdOn: DateTime.utc().toISO(),
  emailSent: false,
  link,
  inAppSubject: `${commenter} mentioned you on a ${comment.commentableType}`,
  emailSubject: `${commenter} mentioned you on a ${comment.commentableType}`,
  inAppLinkText: 'View Comment',
  emailBodyLn1: `${commenter} ${emailBodyLn1}`,
  emailBodyLn1Extra,
  emailHtmlContent: getEmailHtmlContent(comment),
  emailLinkText: 'Log in to reply',
});

export default mentionedInComment;
