import React, { useCallback, useContext, useEffect, useState } from 'react';
import RolesContext from '../../contexts/RolesContext';

const HasAny = ({
  requiredPermissions,
  children,
  fallback,
}: {
  requiredPermissions: FleetOps.Permission[];
  children: JSX.Element | JSX.Element[];
  fallback?: JSX.Element | JSX.Element[];
}) => {
  const { currentPermissions } = useContext(RolesContext);
  const getIsVisible = useCallback(
    () =>
      requiredPermissions.some((permission) =>
        currentPermissions.includes(permission),
      ),
    [currentPermissions, requiredPermissions],
  );
  const [isVisible, setIsVisible] = useState<boolean>(getIsVisible);

  useEffect(() => {
    setIsVisible(getIsVisible());
  }, [currentPermissions, getIsVisible, requiredPermissions]);

  if (isVisible) {
    return <>{children}</>;
  }

  if (fallback) {
    return <>{fallback}</>;
  }

  return null;
};

export default HasAny;
