import React from 'react';
import Button from 'kingpin/atoms/Button';

import Card from '../Common/Card';
import Branding from '../Branding';
import Typography from 'kingpin/atoms/Typography';
import Row from '../Common/Row';

const Requested = ({
  email,
  onBackClicked,
}: {
  email: string;
  onBackClicked: () => void;
}) => (
  <React.Fragment>
    <Branding />
    <Card style={{ width: 466, padding: '31px 44px 31px 32px' }}>
      <div style={{ marginBottom: 36 }}>
        <Typography.Header type="H4">Thank you!</Typography.Header>
        <Typography.Body type="Body 12">
          {`If an account exists for ${email}, you will receive an email with instructions to reset your password.`}
        </Typography.Body>
        <Typography.Body type="Body 12">
          {`Can't locate the email? Check your spam and make sure you submitted the correct email address`}
        </Typography.Body>
      </div>
      <Row style={{ justifyContent: 'flex-end' }}>
        <Button
          onClick={onBackClicked}
          label="Back to Log In"
          type="Primary"
          size="Small"
        />
      </Row>
    </Card>
  </React.Fragment>
);

export default Requested;
