import React, { useCallback, useContext, useEffect, useState } from 'react';
import BoardDataCardSection from './BoardDataCardSection';
import { gql } from '@apollo/client';

import BoardContext from '../../../contexts/BoardContext';

import BoardSlideOutContext from '../../../contexts/BoardSlideOutContext';
import PeriodsContext from '../../../contexts/PeriodsContext';
import isGeneralBoard from '../../../isBoardWithSlideOut';
import moment from 'moment';
import BaseViewsContext from '../../../contexts/BaseViewsContext';
import BonusPeriodsContext from '../../../contexts/BonusPeriodsContext';
import { toDateRange } from '../../../contexts/BoardContext';
import captureException from '../../../services/captureException';
import mergeFilterInputs from '../../../mergeFilterInputs';
import GqlClientContext from '../../../contexts/GqlClientContext';
import useNetworkingEffect from '../../../hooks/useNetworkingEffect';
import useValueFormatters from '../../../hooks/useValueFormatters';
import { usePlatesToFilterInput } from 'screens/GoalShow/useGoalData';

const buildQuery = ({
  dataType,
  filters,
  dateScope,
}: {
  dataType: string;
  filters: FilterInput;
  dateScope: DateRangeInput;
}) => ({
  query: gql`
    query FetchRecords(
      $dataType: String!
      $filters: [FilterInput]!
      $dateScope: [DateRangeInput!]!
      $sortBy: [SortField]
    ) {
      fetchRecords(
        dataType: $dataType
        filters: $filters
        dateScope: $dateScope
        sortBy: $sortBy
      )
    }
  `,
  variables: {
    dataType,
    filters: [filters],
    dateScope,
    sortBy: {
      field: 'date',
      sort: 'desc',
    },
  },
});

export const useSlideOutFilterInput = (
  section?: BoardSlideOutGridSection | BoardSlideOutDataSection,
  isV2FilterOn?: boolean,
  thisPeriodOnly?: boolean,
  thisPeriodOnlyToDate?: boolean,
  filterBySelectedOnly?: boolean,
) => {
  const { board } = useContext(BoardContext);
  const { drillDownField, selectedFieldValue } =
    useContext(BoardSlideOutContext);
  const { selectedBonusPeriod: bonusPeriod } = useContext(BonusPeriodsContext);
  const { selectedPeriod: period } = useContext(PeriodsContext);
  const [filterInput, setFilterInput] = useState<FilterInput | undefined>();
  const [dateScope, setDateScope] = useState<DateRangeInput | undefined>();
  const platesToFilterInput = usePlatesToFilterInput();

  useEffect(() => {
    if ((!bonusPeriod && !period) || !board) {
      return;
    }
    const periodToUse = isGeneralBoard(board) ? period : bonusPeriod;
    if (periodToUse === undefined) {
      return;
    }

    const dateRangeToUse = (() => {
      if (filterBySelectedOnly) {
        return {};
      }

      if (isGeneralBoard(board)) {
        const startDate = (() => {
          if (thisPeriodOnly) {
            return periodToUse.startDate;
          } else {
            return moment(periodToUse.endDate)
              .subtract(
                board.slideOutRelativeDateRange.n,
                board.slideOutRelativeDateRange.interval,
              )
              .format('YYYY-MM-DD');
          }
        })();

        const endDate = (() => {
          if (thisPeriodOnlyToDate) {
            const today = moment().format('YYYY-MM-DD');
            if (periodToUse.endDate > today) {
              return today;
            } else {
              return periodToUse.endDate;
            }
          } else {
            return periodToUse.endDate;
          }
        })();
        return {
          endDate,
          startDate,
        };
      } else {
        return toDateRange(periodToUse);
      }
    })();
    setDateScope(dateRangeToUse);

    const baseFilterInput = {
      keywords: [
        {
          field: drillDownField,
          values: selectedFieldValue ? [selectedFieldValue] : [],
        },
      ],
      ranges: [],
      dataType: section ? [section.dataType] : [board.dataType],
      booleanFilters: [],
    } as FilterInput;

    const filterInput = (() => {
      if (filterBySelectedOnly) {
        return baseFilterInput;
      }

      if (section && section.dataSourceFilterV2) {
        if (isV2FilterOn) {
          return mergeFilterInputs(
            baseFilterInput,
            platesToFilterInput(section.dataSourceFilterV2.plates),
          );
        } else {
          return baseFilterInput;
        }
      } else {
        return baseFilterInput;
      }
    })();

    setFilterInput(filterInput);
  }, [
    board,
    bonusPeriod,
    drillDownField,
    filterBySelectedOnly,
    isV2FilterOn,
    period,
    platesToFilterInput,
    section,
    selectedFieldValue,
    thisPeriodOnly,
    thisPeriodOnlyToDate,
  ]);

  return { filterInput, dateScope };
};

export const useSectionData = (
  section: BoardSlideOutGridSection | BoardSlideOutDataSection,
) => {
  const { client } = useContext(GqlClientContext);
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isV2FilterOn, setIsV2FilterOn] = useState<boolean>(
    section.dataSourceFilterV2
      ? section.dataSourceFilterV2.isOnByDefault
      : false,
  );
  const { filterInput, dateScope } = useSlideOutFilterInput(
    section,
    isV2FilterOn,
    section.thisPeriodOnly,
    section.thisPeriodOnlyToDate,
    section.filterBySelectedOnly,
  );

  const filterLabel = section.dataSourceFilterV2
    ? section.dataSourceFilterV2.label
    : undefined;

  const isFilterToggle = section.dataSourceFilterV2
    ? section.dataSourceFilterV2.isToggle
    : undefined;

  const onFilterToggled = (newStatus: boolean) => {
    setIsV2FilterOn(newStatus);
  };

  const getData = useCallback(async () => {
    if (!filterInput || !dateScope) {
      return;
    }
    const query = buildQuery({
      dataType: section.dataType,
      filters: filterInput,
      dateScope,
    });
    const response = await client.query(query);
    setData(response.data.fetchRecords);
  }, [client, dateScope, filterInput, section.dataType]);

  useNetworkingEffect(() => {
    let isActive = true;
    setIsLoading(true);
    getData().then(() => {
      if (isActive) {
        setIsLoading(false);
      }
    });

    return () => {
      isActive = false;
    };
  }, [getData]);

  return {
    data,
    isLoading,
    onFilterToggled,
    isV2FilterOn,
    filterLabel,
    isFilterToggle,
  };
};

const BoardDataCardSectionContainer = ({
  section,
}: {
  section: BoardSlideOutDataSection;
}) => {
  const { dataType, scoringDataType } = section;
  const { baseViews } = useContext(BaseViewsContext);
  const {
    data,
    isLoading,
    filterLabel,
    isV2FilterOn,
    isFilterToggle,
    onFilterToggled,
  } = useSectionData(section);
  const { formatField } = useValueFormatters();
  const baseView = baseViews[dataType];

  const [cards, setCards] = useState<DriverBonusSlideOutCard[]>([]);
  useEffect(() => {
    setCards(
      data.map((d) => {
        const card = {
          id: '',
          isShowingMore: false,
          isExcluded: false,
          data: {},
        } as DriverBonusSlideOutCard;
        section.cardDef.forEach(({ field }) => {
          const baseField = baseView ? baseView.fields[field] : undefined;
          card.data[
            baseField && baseField.nameAlias ? baseField.nameAlias : field
          ] = formatField({ field, dataset: dataType, value: d[field] });
          card.id = d['id'];
          card.isShowingMore = false;
          card.isExcluded = !d['eligible'];
          card.excludedBy = d['excludedBy'];
        });
        return card;
      }),
    );
  }, [baseView, data, dataType, formatField, section.cardDef]);

  const onShowMoreClicked = (cardId: string) => {
    const card = cards.find((c) => c.id === cardId);
    const cardData = data.find((d) => d['id'] === cardId);

    if (!!card && !!cardData) {
      const newCard = {
        ...card,
        isShowingMore: true,
      };
      Object.keys(cardData).forEach((key) => {
        if (key !== 'id' && key !== 'type') {
          const baseField = baseView ? baseView.fields[key] : undefined;
          if (!baseField) {
            newCard.data[key] = '-';
          } else {
            newCard.data[key] = formatField({
              field: baseField.field,
              dataset: baseField.dataset,
              value: cardData[key],
            });
          }
        }
      });
      setCards((cards) => {
        return cards.map((c) => {
          if (c.id === cardId) {
            return newCard;
          } else {
            return c;
          }
        });
      });
    }
  };

  const onShowLessClicked = (cardId: string) => {
    const card = cards.find((c) => c.id === cardId);
    const cardData = data.find((d) => d['id'] === cardId);

    if (!!card && !!cardData) {
      const newCard = {
        ...card,
        data: {},
        isShowingMore: false,
      };
      section.cardDef.forEach(({ field }) => {
        // @ts-ignore
        newCard.data[field] = cardData[field];
      });
      setCards((cards) => {
        return cards.map((c) => {
          if (c.id === cardId) {
            return newCard;
          } else {
            return c;
          }
        });
      });
    }
  };

  const updateCardData = (
    cardId: string,
    newCardData: { [key: string]: string | number | undefined },
  ) => {
    const card = cards.find((c) => c.id === cardId);
    if (!card) {
      captureException(new Error('Card not found. Could not update UI.'));
      return;
    }

    const newCard = {
      ...card,
      data: {},
      isExcluded: !newCardData['eligible'],
      excludedBy: newCardData['excludedBy'],
    } as DriverBonusSlideOutCard;

    if (newCard.isShowingMore) {
      Object.keys(newCardData).forEach((key) => {
        newCard.data[key] = newCardData[key];
      });
    } else {
      section.cardDef.forEach(({ field }) => {
        newCard.data[field] = newCardData[field];
      });
    }
    setCards((currentCards) => {
      return currentCards.map((c) => {
        if (c.id === newCard.id) {
          return newCard;
        } else {
          return c;
        }
      });
    });
  };

  return (
    <BoardDataCardSection
      cards={cards}
      onShowMoreClicked={onShowMoreClicked}
      onShowLessClicked={onShowLessClicked}
      isLoading={isLoading}
      dataType={dataType}
      scoringDataType={scoringDataType}
      updateCardData={updateCardData}
      filterLabel={filterLabel}
      isV2FilterOn={isV2FilterOn}
      isFilterToggle={isFilterToggle}
      onFilterToggled={onFilterToggled}
      section={section}
    />
  );
};

export default BoardDataCardSectionContainer;
