import React, { useContext } from 'react';
import Colors2 from 'theme/Colors2';
import Inputs from 'components//Inputs';
import useTargetValueModeOptions from '../../../hooks/useTargetValueModeOptions';
import TargetFormContext from '../../../contexts/TargetFormContext';

const ModeDropdown = ({
  target,
  group,
}: {
  target: Targets.Wizard.Target;
  group: Targets.Wizard.TargetGroup;
}) => {
  const { isShowingValidationFeedback, isGridMode } =
    useContext(TargetFormContext);
  const modeOptions = useTargetValueModeOptions({
    target,
    group,
  });

  if (!isGridMode) {
    const isInputInvalid =
      isShowingValidationFeedback &&
      target.categories.length > 0 &&
      target.categories[0].value;
    return (
      <div>
        <Inputs.Dropdown
          testId="target-mode-dropdown"
          options={modeOptions}
          buttonStyle={
            target.categories.length > 0
              ? isInputInvalid
                ? {
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderBottomRightRadius: 0,
                    borderTopRightRadius: 0,
                    borderLeftColor: Colors2.Secondary.error,
                    borderBottomColor: Colors2.Secondary.error,
                    borderTopColor: Colors2.Secondary.error,
                    borderRightColor: Colors2.Secondary.error,
                    backgroundColor: Colors2.Secondary.errorBackground,
                  }
                : {
                    borderBottomRightRadius: 0,
                    borderTopRightRadius: 0,
                    borderRightColor: 'transparent',
                  }
              : undefined
          }
        />
      </div>
    );
  }

  return (
    <div>
      <Inputs.Dropdown testId="target-mode-dropdown" options={modeOptions} />
    </div>
  );
};

export default ModeDropdown;
