import { useContext } from 'react';
import { PortalsContext } from '../contextProviders/PortalsProvider';

const NonPortalAccountsGate = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { isPortalsEnabled } = useContext(PortalsContext);

  if (isPortalsEnabled) {
    return null;
  }

  return <>{children}</>;
};

export default NonPortalAccountsGate;
