import React from 'react';
import LandingPage from '../../components/LandingPage';
import Branding from '../../components/Branding';
import Card from '../../components/Common/Card';
import Typography from 'kingpin/atoms/Typography';
import Row from '../../components/Common/Row';
import { Link } from 'react-router-dom';
import appRoutes from '../../navigation/appRoutes';
import styled from 'styled-components';
import Colors from '../../theme/colors';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .iycCLd {
    background-color: ${Colors.WHITE} !important;
  }
`;

const ExpiredInvitation = () => (
  <LandingPage>
    <Branding />
    <Card style={{ width: 466, padding: '31px 44px 31px 32px' }}>
      <Layout>
        <Typography.Header type="H4">
          This invitation is no longer valid
        </Typography.Header>
        <Typography.Body type="Body 12">
          Please contact your account administrator
        </Typography.Body>
        <Row style={{ marginTop: 48 }}>
          <div style={{ marginRight: 4 }}>
            <Typography.Body type="Body 12">
              Already have an account?
            </Typography.Body>
          </div>
          <Link to={appRoutes.logIn}>
            <Typography.Body type="Link">Log in</Typography.Body>
          </Link>
        </Row>
      </Layout>
    </Card>
  </LandingPage>
);

export default ExpiredInvitation;
