import React, { useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';

import SlideOutContext from '../contexts/SlideOutContext';
import { Z_INDEX } from '../../../constants';
import Colors2 from '../../../theme/Colors2';

const DURATION = 300;
export const SLIDE_OUT_WIDTH = 440;

const SlideOutDiv = styled(motion.div)`
  height: 100%;
  background-color: white;
  z-index: ${Z_INDEX.SLIDE_OUT};
  position: absolute;
  width: ${SLIDE_OUT_WIDTH}px;
  right: 0px;
  box-shadow: 0px 1px 12px 5px #3f3f441a;
  border-radius: 2px;
  z-index: 1;
  border: 1px solid ${Colors2.Border};
`;

const variants = {
  opening: {
    right: -SLIDE_OUT_WIDTH,
  },
  open: {
    right: 0,
  },
  closing: {
    right: -SLIDE_OUT_WIDTH,
  },
  closed: {
    right: -SLIDE_OUT_WIDTH,
  },
};

const SlideOut = ({
  children,
}: {
  children: JSX.Element | JSX.Element[] | false;
}) => {
  const { isOpen } = useContext(SlideOutContext);
  const [isCloseComplete, setIsCloseComplete] = useState<boolean>(true);

  useEffect(() => {
    if (isOpen) {
      setIsCloseComplete(false);
      return;
    }
    const t = setTimeout(() => {
      setIsCloseComplete(true);
    }, DURATION);

    return () => {
      clearTimeout(t);
    };
  }, [isOpen]);

  if (isCloseComplete) {
    return null;
  }

  return (
    <SlideOutDiv
      variants={variants}
      animate={isOpen ? 'open' : isCloseComplete ? 'closed' : 'closing'}
      initial="opening"
    >
      {children}
    </SlideOutDiv>
  );
};

export default SlideOut;
