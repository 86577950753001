import React from 'react';
import styled from 'styled-components';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import 'react-quill/dist/quill.snow.css';
import Button from 'kingpin/atoms/Button';

import Row from '../Common/Row';
import ReactQuill from 'react-quill';

const ModalWrapper = styled.div`
  padding: 8px 16px;
`;

const ContentWrapper = styled.div`
  margin-bottom: 8px;
`;

const TextCardForm = ({
  text,
  onChange,
  onSave,
  isOpen,
  close,
}: {
  text: string;
  onChange: (t: string) => void;
  onSave: () => void;
  isOpen: boolean;
  close: () => void;
}) => (
  <ModalTransition>
    {isOpen && (
      <Modal onClose={close} shouldScrollInViewport={false} autoFocus={false}>
        <ModalWrapper>
          <ContentWrapper>
            <ReactQuill theme="snow" value={text} onChange={onChange} />
          </ContentWrapper>
          <Row spaceBetween centerAlign style={{ marginTop: 12 }}>
            <Button onClick={close} type="Ghost" size="Small" label="Cancel" />
            <Button onClick={onSave} type="Primary" size="Small" label="Save" />
          </Row>
        </ModalWrapper>
      </Modal>
    )}
  </ModalTransition>
);

export default TextCardForm;
