const VIEW_ALL_PRIVATE: FleetOps.Permission =
  'fleetops.permissions.content_access_view_all_private';
const VIEW_ALL_USERS: FleetOps.Permission =
  'fleetops.permissions.content_access_view_all_users';
const VIEW_ALL_ADMIN_ONLY: FleetOps.Permission =
  'fleetops.permissions.content_access_view_all_admin_only';

const SET_ANY_AVAILABLE_TO_PRIVATE: FleetOps.Permission =
  'fleetops.permissions.content_access_set_any_available_to_private';
const SET_ANY_AVAILABLE_TO_ADMIN_ONLY: FleetOps.Permission =
  'fleetops.permissions.content_access_set_any_available_to_admin_only';
const SET_ANY_AVAILABLE_TO_USERS_OR_PUBLIC: FleetOps.Permission =
  'fleetops.permissions.content_access_set_any_available_to_users_or_public';

const CREATE_PORTALS: FleetOps.Permission =
  'fleetops.permissions.content_access_create_portals';
const VIEW_ADMIN_PORTAL: FleetOps.Permission =
  'fleetops.permissions.content_access_view_admin_portal';
const ADD_USERS_TO_PORTAL: FleetOps.Permission =
  'fleetops.permissions.content_access_add_users_to_portal';

const MANAGE_REPORT_INDEX_FILTERS: FleetOps.Permission =
  'fleetops.permissions.content_access_manage_report_index_filters';
const VIEW_SE_ONLY_REPORTS: FleetOps.Permission =
  'fleetops.permissions.content_access_manage_view_se_only_reports';

const CONTENT_ACCESS_PERMISSIONS = {
  VIEW_ALL_PRIVATE,
  VIEW_ALL_USERS,
  VIEW_ALL_ADMIN_ONLY,
  SET_ANY_AVAILABLE_TO_PRIVATE,
  SET_ANY_AVAILABLE_TO_ADMIN_ONLY,
  SET_ANY_AVAILABLE_TO_USERS_OR_PUBLIC,
  CREATE_PORTALS,
  VIEW_ADMIN_PORTAL,
  ADD_USERS_TO_PORTAL,
  MANAGE_REPORT_INDEX_FILTERS,
  VIEW_SE_ONLY_REPORTS,
};
Object.freeze(CONTENT_ACCESS_PERMISSIONS);

export default CONTENT_ACCESS_PERMISSIONS;
