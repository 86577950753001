import React from 'react';
import SearchResults from './SearchResults';

const SearchResultsContainer = ({
  searchFields,
  searchResults,
  onSearchItemClicked,
  selectedSearchResults,
  close,
}: {
  searchFields: string[];
  searchResults: MultiFieldSearchResults;
  onSearchItemClicked: (field: string, value: string) => void;
  selectedSearchResults: MultiFieldSearchResults;
  close: () => void;
}) => {
  const searchFieldsWithResults = searchFields.filter(
    (field) => searchResults[field] && searchResults[field].length > 0,
  );
  const hasResults = searchFieldsWithResults.length > 0;

  return (
    <SearchResults
      searchFields={searchFieldsWithResults}
      searchResults={searchResults}
      hasResults={hasResults}
      onSearchItemClicked={onSearchItemClicked}
      selectedSearchResults={selectedSearchResults}
      close={close}
    />
  );
};

export default SearchResultsContainer;
