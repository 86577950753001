import React, { useCallback, useEffect, useState } from 'react';
import Inputs from '../Inputs';
import Typography from 'kingpin/atoms/Typography';
import isDefined from '../../isDefined';
import useRankingUsedMetrics from './useRankingUsedMetrics';
import { RadioOption } from '../Inputs/Radio';
import Colors2 from '../../theme/Colors2';

const RankingMatrixSortingInput = ({
  sorting,
  setSorting,
  metrics,
}: {
  sorting: VisualisationDefinitions.RankingMatrixSorting;
  setSorting: React.Dispatch<
    React.SetStateAction<VisualisationDefinitions.RankingMatrixSorting>
  >;
  metrics: VisualisationDefinitions.RankingMatrixMetric[];
}) => {
  const usedMetrics = useRankingUsedMetrics(metrics);
  const [orderOptions, setOrderOptions] = useState<DropdownOption[]>([]);
  const [metricOptions, setMetricOptions] = useState<DropdownOption[]>([]);

  useEffect(() => {
    setOrderOptions([
      {
        label: 'Ascending',
        onSelected: () => {
          setSorting((current) => ({ ...current, order: 'asc' }));
        },
      },
      {
        label: 'Descending',
        onSelected: () => {
          setSorting((current) => ({ ...current, order: 'desc' }));
        },
      },
    ]);
  }, [setSorting]);

  useEffect(() => {
    const newOptions = metrics
      .map((m) => {
        const theMetric = usedMetrics.find((used) => used.id === m.metricId);
        if (!theMetric) {
          return undefined;
        }

        return {
          label: m.displayName ? m.displayName : theMetric.name,
          onSelected: () => {
            setSorting((current) => ({ ...current, metricId: theMetric.id }));
          },
        };
      })
      .filter(isDefined);

    setMetricOptions(newOptions);
  }, [metrics, setSorting, usedMetrics]);

  const onAlphabeticalSelected = useCallback(() => {
    setSorting({
      mode: 'alphabetical',
      order: 'asc',
    });
  }, [setSorting]);

  const onMetricSelected = useCallback(() => {
    if (metrics.length === 0) {
      return;
    }
    setSorting({
      mode: 'metric',
      order: 'desc',
      metricId: metrics[0].metricId,
    });
  }, [metrics, setSorting]);

  const metricSortingLabel = (() => {
    if (sorting.mode === 'metric' && sorting.metricId) {
      const metric = usedMetrics.find((m) => sorting.metricId === m.id);
      const rankingMetric = metrics.find(
        (m) => sorting.metricId === m.metricId,
      );
      if (metric && rankingMetric) {
        return rankingMetric.displayName
          ? rankingMetric.displayName
          : metric.name;
      }
    }

    return undefined;
  })();

  return (
    <>
      <RadioOption
        option={{
          key: 'a',
          isSelected: sorting.mode === 'alphabetical',
          onSelected: onAlphabeticalSelected,
          label: 'Alphabetical',
        }}
      />
      {sorting.mode === 'alphabetical' && (
        <div
          style={{
            padding: '4px 8px',
            backgroundColor: Colors2.Grey['9'],
            marginBottom: 8,
          }}
        >
          <div style={{ marginBottom: 8 }}>
            <Typography.Body type="Label">Order</Typography.Body>
            <Inputs.Dropdown
              unsetWidth
              options={orderOptions}
              selectedLabel={
                sorting.order === 'asc' ? 'Ascending' : 'Descending'
              }
            />
          </div>
        </div>
      )}
      <RadioOption
        option={{
          key: 'm',
          isSelected: sorting.mode === 'metric',
          onSelected: onMetricSelected,
          label: 'Metric',
          testId: 'metric-sorting',
          isDisabled: usedMetrics.length === 0,
        }}
      />
      {sorting.mode === 'metric' && (
        <div
          style={{
            padding: '4px 8px',
            backgroundColor: Colors2.Grey['9'],
            marginBottom: 8,
          }}
        >
          <div style={{ marginBottom: 8 }}>
            <Typography.Body type="Label">Metric</Typography.Body>
            <Inputs.Dropdown
              unsetWidth
              options={metricOptions}
              selectedLabel={metricSortingLabel}
            />
          </div>
          <div style={{ marginBottom: 8 }}>
            <Typography.Body type="Label">Order</Typography.Body>
            <Inputs.Dropdown
              unsetWidth
              options={orderOptions}
              selectedLabel={
                sorting.order === 'asc' ? 'Ascending' : 'Descending'
              }
            />
          </div>
        </div>
      )}
    </>
  );
};

export default RankingMatrixSortingInput;
