// useEffect wrapped around a tailing debounce
// - This will allow state to "settle" before we send any requests
import { DependencyList, EffectCallback, useEffect } from 'react';
const DELAY = 300;

// Deprecated. See useLockedDebouncedEffect
const useNetworkingEffect = (
  effect: EffectCallback,
  dependencies: DependencyList,
) => {
  useEffect(() => {
    const timerId = setTimeout(effect, DELAY);
    return () => {
      clearTimeout(timerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};

export default useNetworkingEffect;
