import React from 'react';
import styled from 'styled-components';

import Colors2 from '../theme/Colors2';
import Icon from '../kingpin/atoms/Icon';

const Banner = styled.div<{ isShort?: boolean }>`
  border-radius: 4px;
  background-color: ${Colors2.AvatarColors['2'].background};
  padding: ${(props) => (props.isShort ? '12px 32px' : '24px 16px')};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: ${(props) => (props.isShort ? 'center' : 'flex-start')};
`;

const InfoBanner = ({
  children,
  isShort,
}: {
  children: JSX.Element | JSX.Element[];
  isShort?: boolean;
}) => (
  <Banner isShort={isShort}>
    <div style={{ marginRight: 8, display: 'flex' }}>
      <Icon icon="info" />
    </div>
    <div style={{ display: 'flex' }}>{children}</div>
  </Banner>
);

export default InfoBanner;
