import React from 'react';

interface SelectedEntityContextType {
  entityField: string;
  selectedEntity?: string;
  onEntitySelected: (newValue: string) => void;
}

const SelectedEntityContext = React.createContext<SelectedEntityContextType>({
  entityField: 'NA',
  selectedEntity: undefined,
  onEntitySelected: () => {
    const e = new Error();
    e.name = `onEntitySelected is not defined`;
    throw e;
  },
});

export default SelectedEntityContext;
