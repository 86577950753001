import React from 'react';
import Metric from './Metric';
import Card from '../../Common/Card/index';
import Typography from 'kingpin/atoms/Typography';
import Colors2 from '../../../theme/Colors2';

const Metrics = ({
  metrics,
  data,
  dateRange,
  title,
  homeViz,
}: {
  metrics: Metrics.Metric[];
  data: MetricsResponse;
  dateRange: string;
  title: string;
  homeViz?: boolean;
}) => (
  <div data-testid={'metric-preview'}>
    <div style={{ marginBottom: 16 }}>
      {!homeViz && <Typography.Header type="H4">{title}</Typography.Header>}
      <Typography.Header type={'H5'} color={Colors2.Grey['4']}>
        {dateRange}
      </Typography.Header>
    </div>
    <Card style={{ borderRadius: '4px' }}>
      {metrics.map((m) => (
        <Metric key={m.id} metric={m} data={data} />
      ))}
    </Card>
  </div>
);

export default Metrics;
