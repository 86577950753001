import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import search from 'hooks/grids/search';

const getGridData = async ({
  dataType,
  filters,
  dateScope,
  sortBy,
  client,
  searchAfter,
  includeFields,
}: {
  dataType: string;
  filters: FilterInput[];
  dateScope: DateRangeInput;
  sortBy: SortField[];
  client: ApolloClient<NormalizedCacheObject>;
  searchAfter?: string;
  includeFields?: string[];
}): Promise<{
  count: number;
  searchAfter: string;
  documents: ElasticDocument[];
}> =>
  search({
    dataType,
    filters,
    dateScope,
    sortBy,
    client,
    searchAfter,
    includeFields,
  });

export default getGridData;
