import React, { useContext, useEffect } from 'react';
import PopupGridButton from './PopupGridButton';
import usePopup from '../../hooks/usePopup';
import isV5ChartDef from '../../types/visTypeCheckers/isV5ChartDef';
import useMetric from '../../hooks/useMetric';
import ErrorBoundary from '../Common/ErrorBoundary';
import PopupGridMetricPickerButton from './PopupGridMetricPickerButton';
import getGridQueryOverride, {
  getGridCountQueryOverride,
  getGridTotalsQueryOverride,
} from './getGridQueryOverride';
import AnalyticsContext from '../../contexts/AnalyticsContext';
import DashboardContext from '../../contexts/DashboardContext';
import BaseViewsContext from '../../contexts/BaseViewsContext';
import metricTypeCheckers from '../../types/metricTypeCheckers';
import usePopupDateRangeOverride from './usePopupDateRangeOverride';
import ContextMenuContext from '../../kingpin/atoms/ContextMenu/ContextMenuContext';

const V5 = ({ chartDefinition }: { chartDefinition: V5ChartDefinition }) => {
  const { hideMenu } = useContext(ContextMenuContext);
  const { setIsPopupGridOpen } = useContext(DashboardContext);
  const {
    isOpen: isGridOpen,
    open: openGrid,
    close: closeGrid,
  } = usePopup(false, undefined, true);
  const { isLoading } = useContext(BaseViewsContext);
  const metricId = chartDefinition.series[0].metricId;
  const metric = useMetric(metricId);
  const dateScopeOverride = usePopupDateRangeOverride(metric);
  const { trackEvent } = useContext(AnalyticsContext);
  const onGridOpened = () => {
    hideMenu();
    openGrid();
  };

  useEffect(() => {
    if (isGridOpen) {
      trackEvent('Card - Popup Grid Opened');
    }
  }, [isGridOpen, trackEvent]);

  useEffect(() => {
    setIsPopupGridOpen(isGridOpen);
  }, [isGridOpen, setIsPopupGridOpen]);

  if (!metric) {
    return null;
  }

  if (metricTypeCheckers.isCompoundMetric(metric)) {
    if (isLoading) {
      return null;
    }

    return <PopupGridMetricPickerButton metricIds={metric.metricIds} />;
  } else {
    const dataType = metric.dataType;

    return (
      <PopupGridButton
        isGridOpen={isGridOpen}
        openGrid={onGridOpened}
        closeGrid={closeGrid}
        isLoadingGridDefinitions={isLoading}
        dataType={dataType}
        gridQueryOverride={getGridQueryOverride(metric)}
        gridCountQueryOverride={getGridCountQueryOverride(metric)}
        gridTotalsQueryOverride={getGridTotalsQueryOverride(metric)}
        dateScopeOverride={dateScopeOverride}
        metricFiltering={
          metricTypeCheckers.isSpecialMetric(metric)
            ? undefined
            : metric.filters
        }
      />
    );
  }
};

const PopupGridButtonContainer = ({
  chartDefinition,
}: {
  chartDefinition: VisualisationDefinition;
}) => {
  if (isV5ChartDef(chartDefinition)) {
    return (
      <ErrorBoundary>
        <V5 chartDefinition={chartDefinition} />
      </ErrorBoundary>
    );
  } else {
    return null;
  }
};

export default PopupGridButtonContainer;
