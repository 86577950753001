import React, { ChangeEvent, useEffect, useState } from 'react';

import LocalTasksContext from '../../contexts/Tasks/LocalTasksContext';
import useTasks from './useTasks';

const getDueDateOrder = (a: Tasks.Task, b: Tasks.Task) => {
  if (a.dueDate < b.dueDate) {
    return -1;
  } else if (a.dueDate === b.dueDate) {
    return 0;
  } else {
    return 1;
  }
};

const LocalTasksProvider = ({
  sources,
  destination,
  assignableUserIds,
  children,
}: {
  sources: Tasks.TaskSource[];
  destination?: Tasks.TaskSource;
  assignableUserIds?: string[];
  children: JSX.Element | JSX.Element[];
}) => {
  const {
    tasks,
    onTaskCompletedToggled,
    onTaskUpdated,
    onTaskCreated,
    onTaskDeleted,
    isLoading,
  } = useTasks(sources, destination);
  const [searchText, setSearchText] = useState<string>('');
  const [filteredTasks, setFilteredTasks] = useState<Tasks.Task[]>([]);
  const [isShowingCompleted, setIsShowingCompleted] = useState<boolean>(false);
  const [isAllTasksComplete, setIsAllTasksComplete] = useState<boolean>(false);
  const [isNoTasks, setIsNoTasks] = useState<boolean>(false);

  const onSearchTextChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  useEffect(() => {
    const newFilteredTasks = tasks
      .filter((task) => {
        if (searchText === '') {
          return task;
        } else {
          return task.title.toLowerCase().includes(searchText.toLowerCase());
        }
      })
      .filter((task) => {
        if (isShowingCompleted) {
          return task;
        }
        return !task.isComplete;
      })
      .sort((a, b) => {
        if (a.isComplete && b.isComplete) {
          return getDueDateOrder(a, b);
        } else if (!a.isComplete && !b.isComplete) {
          return getDueDateOrder(a, b);
        } else {
          if (a.isComplete && !b.isComplete) {
            return 1;
          } else if (!a.isComplete && b.isComplete) {
            return -1;
          }

          return 0;
        }
      });

    setFilteredTasks(newFilteredTasks);
  }, [isShowingCompleted, searchText, tasks]);

  useEffect(() => {
    if (isLoading) {
      setIsNoTasks(false);
      setIsAllTasksComplete(false);
      return;
    }
    setIsNoTasks(tasks.length === 0);
    setIsAllTasksComplete(
      filteredTasks.length === 0 &&
        tasks.length !== 0 &&
        !tasks.some((t) => !t.isComplete),
    );
  }, [filteredTasks.length, isLoading, tasks, tasks.length]);

  return (
    <LocalTasksContext.Provider
      value={{
        tasks: filteredTasks,
        isLoading,
        sources,
        destination,
        onTaskCompletedToggled,
        onTaskUpdated,
        onTaskCreated,
        onTaskDeleted,
        searchText,
        onSearchTextChanged,
        isShowingCompleted,
        setIsShowingCompleted,
        assignableUserIds,

        isAllTasksComplete,
        isNoTasks,
      }}
    >
      {children}
    </LocalTasksContext.Provider>
  );
};

export default LocalTasksProvider;
