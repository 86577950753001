import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { DateTime } from 'luxon';

import AwardedLoads from './AwardedLoads';
import NewCommitSlideOutContext from '../../../../../contexts/NewCommitSlideOutContext';

const AwardedLoadsContainer = () => {
  // State
  const { draftCommit, setDraftCommit, showWarnings } = useContext(
    NewCommitSlideOutContext,
  );
  const { loads, cadence, startDate, endDate } = draftCommit;

  // Interactions
  const onCommitsChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const newCommit = {
      ...draftCommit,
      loads: Number.parseInt(event.target.value),
    };
    setDraftCommit(newCommit);
  };

  const [cadenceOptions, setCadenceOptions] = useState<DropdownOption[]>([]);
  useEffect(() => {
    setCadenceOptions([
      {
        label: 'Weekly',
        isSelected: 'weekly' === cadence,
        onSelected: () => {
          setDraftCommit((c) => ({ ...c, cadence: 'weekly' }));
        },
      },
      {
        label: 'Monthly',
        isSelected: 'monthly' === cadence,
        onSelected: () => {
          setDraftCommit((c) => ({ ...c, cadence: 'monthly' }));
        },
      },
      {
        label: 'Quarterly',
        isSelected: 'quarterly' === cadence,
        onSelected: () => {
          setDraftCommit((c) => ({ ...c, cadence: 'quarterly' }));
        },
      },
      {
        label: 'Yearly',
        isSelected: 'yearly' === cadence,
        onSelected: () => {
          setDraftCommit((c) => ({ ...c, cadence: 'yearly' }));
        },
      },
    ]);
  }, [cadence, setDraftCommit]);

  const onStartDateChanged = (date: string) => {
    if (date === '') {
      const newCommit = {
        ...draftCommit,
      };
      delete newCommit['startDate'];
      setDraftCommit(newCommit);
    } else {
      const newEndDate = DateTime.fromISO(date).plus({ year: 1 }).toISODate();
      const newCommit = {
        ...draftCommit,
        startDate: date,
        endDate: newEndDate,
      };
      setDraftCommit(newCommit);
    }
  };

  const onEndDateChanged = (date: string) => {
    if (date === '') {
      const newCommit = {
        ...draftCommit,
      };
      delete newCommit['endDate'];
      setDraftCommit(newCommit);
    } else {
      const newCommit = {
        ...draftCommit,
        endDate: date,
      };
      setDraftCommit(newCommit);
    }
  };

  const isEndDateInPast =
    !!draftCommit.endDate &&
    DateTime.fromISO(draftCommit.endDate).toSeconds() <
      DateTime.local().toSeconds();

  return (
    <AwardedLoads
      commits={loads}
      onCommitsChanged={onCommitsChanged}
      cadence={cadence}
      cadenceOptions={cadenceOptions}
      startDate={startDate}
      onStartDateChanged={onStartDateChanged}
      endDate={endDate}
      isEndDateInPast={isEndDateInPast}
      onEndDateChanged={onEndDateChanged}
      showWarnings={showWarnings}
    />
  );
};

export default AwardedLoadsContainer;
