import { getMetricLinkForDataset } from '../SpecificSet/routes';
import metricTypeCheckers from '../../../types/metricTypeCheckers';
import getPortalSlug from '../../../navigation/getPortalSlug';

const BASE = 'data-manager';

export const buildBase = (portal: Portal | undefined) => {
  return `/${getPortalSlug(portal)}${BASE}`;
};

export const getMetricLink = ({
  metric,
  portal,
  dataset,
}: {
  metric: Metrics.Metric;
  dataset?: string;
  portal: Portal | undefined;
}) => {
  if (dataset) {
    return getMetricLinkForDataset(metric, dataset, portal);
  }

  if (metricTypeCheckers.isCompoundMetric(metric)) {
    return `${buildBase(portal)}/compound-metrics?metricId=${metric.id}`;
  } else if (metricTypeCheckers.isNormalMetric(metric)) {
    return `${buildBase(portal)}/metrics?metricId=${metric.id}`;
  } else {
    return '';
  }
};

export const buildShowDatasets = (portal: Portal | undefined) => {
  return buildBase(portal);
};

export const buildShowMetrics = (portal: Portal | undefined) => {
  return `${buildBase(portal)}/metrics`;
};

export const buildShowMetric = (
  metricId: string,
  portal: Portal | undefined,
) => {
  return `${buildBase(portal)}/metrics/${metricId}`;
};

export const buildShowCompoundMetrics = (portal: Portal | undefined) => {
  return `${buildBase(portal)}/compound-metrics`;
};

export const buildShowCompoundMetric = (
  metricId: string,
  portal: Portal | undefined,
) => {
  return `${buildBase(portal)}/compound-metrics/${metricId}`;
};

export const buildShowCosts = (portal: Portal | undefined) => {
  return `${buildBase(portal)}/costs`;
};

export const buildShowSearch = (portal: Portal | undefined) => {
  return `${buildBase(portal)}/search`;
};

export const buildShowEntities = (portal: Portal | undefined) => {
  return `${buildBase(portal)}/entities`;
};

const ALL_SETS_ROUTES = {
  BASE,
  DATASETS: `/${BASE}`,
  METRICS: `/${BASE}/metrics`,
  SHOW_METRIC: `/${BASE}/metrics/:metricId`,
  COMPOUND_METRICS: `/${BASE}/compound-metrics`,
  COSTS: `/${BASE}/costs`,
  SHOW_COMPOUND_METRIC: `/${BASE}/compound-metrics/:compoundMetricId`,
  SEARCH: `/${BASE}/search`,
  ENTITIES: `/${BASE}/entities`,
};

export default ALL_SETS_ROUTES;
