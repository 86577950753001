import React, { useCallback, useContext, useState } from 'react';
import Button from 'kingpin/atoms/Button';

import ScorecardContext from 'contexts/ScorecardContext';
import useKpiTrend from '../useKpiTrend';
import KpiRowElement from '../KpiRowElement';
import { KPI_ROW_HEIGHT } from '../KpiRowRight/constants';
import { Col } from '../Kpis';
import ManualKpiInputProvider from '../ManualKpiInputProvider';
import ManualKpiInputContext from '../ManualKpiInputContext';
import Row from 'components/Common/Row';
import KpiValue from '../KpiRowRight/KpiValue';
import KpiInput from './KpiInput';

const ManualKpiRowRight = ({
  kpi,
  isLastRow,
}: {
  kpi: Scorecards.ManualKpiRow;
  isLastRow?: boolean;
}) => {
  const {
    selectedPeriods,
    manualKpiIdBeingEdited,
    kpiBeingHighlighted,
    hoveredKpiId,
    setHoveredKpiId,
  } = useContext(ScorecardContext);
  const { values, setValues, onSave, onDiscardChanges } = useContext(
    ManualKpiInputContext,
  );
  const trend = useKpiTrend(kpi);

  const [invalidPeriods, setInvalidPeriods] = useState<string[]>([]);

  const buildInitialKpiData = useCallback(
    ({ period }: { period: Period }) => {
      const existingKpi = kpi.data.find((d) => d.date === period.startDate);
      if (existingKpi) {
        return existingKpi;
      } else {
        return { date: period.startDate };
      }
    },
    [kpi.data],
  );

  const onRowHover = useCallback(() => {
    setHoveredKpiId(kpi.id);
  }, [kpi.id, setHoveredKpiId]);

  return (
    <KpiRowElement
      centerAlign
      isLastRow={isLastRow}
      isExtended={manualKpiIdBeingEdited === kpi.id}
      isHighlighted={kpiBeingHighlighted === kpi.id}
      isHovered={hoveredKpiId === kpi.id}
      onMouseEnter={onRowHover}
    >
      {!!trend && (
        <>
          {manualKpiIdBeingEdited === kpi.id ? (
            <>
              <div style={{ flex: 1 }}>
                <Row>
                  {selectedPeriods.map((period) => (
                    <Col
                      minWidth
                      key={`${period.startDate}-${kpi.id}`}
                      alignRight
                      style={{ height: `${KPI_ROW_HEIGHT}px` }}
                    >
                      <KpiInput
                        initialData={buildInitialKpiData({ period })}
                        values={values}
                        setValues={setValues}
                        kpi={kpi}
                        period={period}
                        invalidPeriods={invalidPeriods}
                        setInvalidPeriods={setInvalidPeriods}
                      />
                    </Col>
                  ))}
                </Row>
                <div
                  style={{
                    position: 'sticky',
                    left: 'calc(100% - 216px)',
                    width: '208px',
                  }}
                >
                  <Row
                    centerAlign
                    style={{
                      padding: '16px 8px',
                      height: '62px',
                    }}
                  >
                    <div style={{ marginRight: '16px' }}>
                      <Button
                        label="Discard Changes"
                        onClick={onDiscardChanges}
                        type="Ghost"
                        size="Small"
                      />
                    </div>
                    <Button
                      label="Save"
                      onClick={onSave}
                      isDisabled={invalidPeriods.length !== 0}
                      type="Primary"
                      size="Small"
                    />
                  </Row>
                </div>
              </div>
            </>
          ) : (
            <>
              {selectedPeriods.map((period) => (
                <Col
                  minWidth
                  key={`${period.startDate}-${kpi.id}`}
                  alignRight
                  style={{ height: `${KPI_ROW_HEIGHT}px` }}
                >
                  <KpiValue period={period} kpi={kpi} trend={trend} />
                </Col>
              ))}
            </>
          )}
        </>
      )}
    </KpiRowElement>
  );
};

const Gate = ({
  kpi,
  isLastRow,
}: {
  kpi: Scorecards.ManualKpiRow;
  isLastRow?: boolean;
}) => {
  return (
    <ManualKpiInputProvider kpi={kpi}>
      <ManualKpiRowRight kpi={kpi} isLastRow={isLastRow} />
    </ManualKpiInputProvider>
  );
};

export default Gate;
