import React, { useCallback, useContext } from 'react';
import SingleUseMetricPopupContext from '../../../../../contexts/SingleUseMetricPopupContext';
import Row from '../../../../../components/Common/Row';
import Inputs from '../../../../../components/Inputs';
import Typography from 'kingpin/atoms/Typography';
import InfoIcon from '../../../../../components/InfoIcon';
import AnalyticsContext from '../../../../../contexts/AnalyticsContext';

const SaveToDataManagerCheckbox = () => {
  const { isSingleUsePopup, isSaveToDataManager, setIsSaveToDataManager } =
    useContext(SingleUseMetricPopupContext);
  const { trackEvent } = useContext(AnalyticsContext);

  const onToggled = useCallback(
    (newValue: boolean) => {
      setIsSaveToDataManager(newValue);
      if (newValue) {
        trackEvent('Single use metric - Save to data manager - checked');
      } else {
        trackEvent('Single use metric - Save to data manager - unchecked');
      }
    },
    [setIsSaveToDataManager, trackEvent],
  );

  if (!isSingleUsePopup) {
    return null;
  }

  return (
    <Row style={{ marginRight: 24 }} centerAlign>
      <div style={{ marginRight: 8 }}>
        <Inputs.Checkbox
          isChecked={isSaveToDataManager}
          onToggled={onToggled}
        />
      </div>

      <Typography.Body type="Body 12">Save To Data Manager</Typography.Body>
      <div style={{ marginLeft: 8 }}>
        <InfoIcon tooltip="Checking this option will save this metric to the Data Manager for re-use though the metric picker" />
      </div>
    </Row>
  );
};

export default SaveToDataManagerCheckbox;
