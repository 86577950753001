import React, { useContext } from 'react';
import GeneralGoalContext from '../../../contexts/GeneralGoalContext';
import useMetric from '../../../hooks/useMetric';
import OnTargetBadge from './OnTargetBadge';
import useValueFormatters from '../../../hooks/useValueFormatters';

const GeneralOnTargetBadge = ({ isCompact }: { isCompact?: boolean }) => {
  const { goal, primaryKpiData, target, targetValueForNow, currentValue } =
    useContext(GeneralGoalContext);
  const { formatMetric } = useValueFormatters();
  const metric = useMetric(goal.metricId);
  const { aboveTargetIsGood } = goal;
  if (!primaryKpiData || !primaryKpiData.trend || !metric) {
    return null;
  }

  const currentValueFormatted = formatMetric({
    value: currentValue,
    metricId: metric.id,
  });

  const targetValueFormatted = formatMetric({
    value: target,
    metricId: metric.id,
  });

  const isOnTarget = (() => {
    if (currentValue === undefined) {
      return false;
    }

    if (aboveTargetIsGood) {
      return currentValue > targetValueForNow;
    } else {
      return targetValueForNow > currentValue;
    }
  })();

  const testId = `${currentValueFormatted} / ${targetValueFormatted}`;

  return (
    <OnTargetBadge
      isOnTarget={isOnTarget}
      targetValueFormatted={targetValueFormatted}
      currentValueFormatted={currentValueFormatted}
      accountableId={goal.accountableId}
      isCompact={isCompact}
      testId={testId}
    />
  );
};

export default GeneralOnTargetBadge;
