import IntegrationCard from './IntegrationCard';
import ManageGroupsPopup from './ManageGroupsPopup';
import ConsentRedirect from './ConsentRedirect';

const Microsoft = {
  IntegrationCard,
  ManageGroupsPopup,
  ConsentRedirect,
};

export default Microsoft;
