import React from 'react';
import moment from 'moment';
import { DateTime } from 'luxon';

import Tooltip from '../../Tooltip';
import Cell from '../../V5Gadget/Matrix/Cell';
import Row from '../../Common/Row';

import Colors from '../../../theme/colors';
import dateRangeInputToAmericanLabel from '../../../dateRangeInputToAmericanLabel';
import { ArrowElem } from '../../Gadget/Delta';
import toSentenceCase from '../../../services/toSentenceCase';
import Colors2 from '../../../theme/Colors2';
import Typography from 'kingpin/atoms/Typography';
import formatFloat from '../../../api/getChartSeries/formatFloat';
import useValueFormatters from '../../../hooks/useValueFormatters';

const isComparison = (v: MatrixComparisonCell) => {
  return v.type === 'delta' || v.type === 'percentDelta';
};

const Delta = ({
  isGood,
  isUp,
  text,
}: {
  isGood: boolean;
  isUp: boolean;
  text: string;
}) => {
  return (
    <Row
      centerAlign
      style={{
        width: 'unset',
        justifyContent: 'center',
      }}
    >
      <ArrowElem isGood={isGood} delta={isUp ? 1 : -1} isGrid />
      <span
        style={{ color: isGood ? Colors.MEDIUM_SEA_GREEN : Colors.RADICAL_RED }}
      >
        {text}
      </span>
    </Row>
  );
};

const ToolTipSection = ({
  topText,
  middleText,
  bottomText,
}: {
  topText: string;
  middleText: string;
  bottomText: string;
}) => (
  <div
    style={{
      backgroundColor: Colors2.Grey['9'],
      minWidth: 200,
      minHeight: 75,
      paddingTop: 8,
      paddingBottom: 8,
    }}
  >
    <Row centerAlign style={{ justifyContent: 'center', marginBottom: 8 }}>
      <Typography.Body type="Body 12">{topText}</Typography.Body>
    </Row>
    <Row centerAlign style={{ justifyContent: 'center', marginBottom: 8 }}>
      <Typography.Header type="H5">{middleText}</Typography.Header>
    </Row>
    <Row centerAlign style={{ justifyContent: 'center' }}>
      <Typography.Body type="Body 12">{bottomText}</Typography.Body>
    </Row>
  </div>
);

const DeltaTooltip = ({
  cell,
  isGood,
}: {
  cell: MatrixComparisonCell;
  isGood: boolean;
}) => {
  const { formatMetric } = useValueFormatters();
  if (cell.comparison && cell.comparisonDelta) {
    const currentFormatted = formatMetric({
      metricId: cell.metricId,
      value: cell.value,
    });
    const previousFormatted = formatMetric({
      metricId: cell.metricId,
      value: cell.comparison,
    });

    const comparisonHeader = (() => {
      if (cell.interval === 'day') {
        const dayOfWeek = DateTime.fromISO(cell.date).weekdayLong;
        return `Avg. for ${dayOfWeek}s`;
      } else {
        return `${toSentenceCase(cell.interval)}ly Avg.`;
      }
    })();

    const headingText = (() => {
      const { comparisonDelta, comparisonDeltaPercent, formatting } = cell;
      const prefix = formatFloat(comparisonDelta, formatting.precision);

      if (
        !comparisonDeltaPercent ||
        comparisonDeltaPercent === Infinity ||
        comparisonDeltaPercent === -Infinity ||
        Number.isNaN(comparisonDeltaPercent)
      ) {
        return `${prefix}`;
      } else {
        const postfix = formatFloat(
          cell.comparisonDeltaPercent,
          cell.formatting.precision,
        );
        return `${prefix} (${postfix}%)`;
      }
    })();

    return (
      <div style={{ padding: 8, minWidth: 160 }}>
        <div style={{ marginBottom: 24, marginTop: 8 }}>
          <Row style={{ justifyContent: 'center' }} centerAlign>
            <Delta
              isGood={isGood}
              isUp={cell.comparisonDelta >= 0}
              text={headingText}
            />
          </Row>
        </div>
        <Row centerAlign>
          <ToolTipSection
            topText={
              cell.interval === 'day'
                ? moment.utc(cell.date).format("ddd, MMM DD'YY")
                : dateRangeInputToAmericanLabel(cell.valueDateRange)
            }
            middleText={currentFormatted}
            bottomText={''}
          />
          <div
            style={{
              color: Colors.GREY,
              marginLeft: 16,
              marginRight: 16,
            }}
          >
            vs
          </div>
          <ToolTipSection
            topText={comparisonHeader}
            middleText={previousFormatted}
            bottomText={dateRangeInputToAmericanLabel(cell.comparisonDateRange)}
          />
        </Row>
      </div>
    );
  } else {
    return null;
  }
};

const HistogramCellRenderer = ({
  value: v,
}: {
  value: MatrixComparisonCell;
}) => {
  const { formatMetric } = useValueFormatters();
  if (v === undefined) {
    return (
      <Cell>
        <span>-</span>
      </Cell>
    );
  }
  if (isComparison(v)) {
    const {
      comparison,
      comparisonDelta,
      formatting,
      type,
      comparisonDeltaPercent,
    } = v;

    if (
      comparisonDelta === null ||
      comparisonDeltaPercent === null ||
      comparisonDelta === undefined ||
      comparisonDeltaPercent === undefined
    ) {
      return (
        <Cell>
          <span>-</span>
        </Cell>
      );
    }
    if (comparison !== 0) {
      const { positiveDeltaIsGood } = formatting;
      const isGood = positiveDeltaIsGood
        ? comparisonDelta >= 0
        : comparisonDelta <= 0;

      const text = (() => {
        if (type === 'delta') {
          return formatFloat(comparisonDelta, formatting.precision).toString();
        } else if (type === 'percentDelta') {
          if (
            comparisonDeltaPercent === Infinity ||
            comparisonDeltaPercent === -Infinity ||
            Number.isNaN(comparisonDeltaPercent)
          ) {
            return undefined;
          } else {
            return `${comparisonDeltaPercent}%`;
          }
        } else {
          return undefined;
        }
      })();

      if (text) {
        return (
          <Row centerAlign>
            <Tooltip
              content={<DeltaTooltip cell={v} isGood={isGood} />}
              isAltTooltip
            >
              <Delta isGood={isGood} isUp={comparisonDelta >= 0} text={text} />
            </Tooltip>
          </Row>
        );
      } else {
        return (
          <Cell>
            <span>-</span>
          </Cell>
        );
      }
    } else {
      return (
        <Cell>
          <span>-</span>
        </Cell>
      );
    }
  } else {
    return (
      <Cell rightAlign>
        <span>{formatMetric({ value: v.value, metricId: v.metricId })}</span>
      </Cell>
    );
  }
};

export default HistogramCellRenderer;
