import React, { useContext } from 'react';

import PerformanceWizardContext from '../PerformanceWizardContext';
import MetricListItem from './PerformanceMetricItem';
import ListItem from '../ListItem';
import ColDiv from './ColDiv';
import Typography from 'kingpin/atoms/Typography';
import List from '../../../../../../components/List';
import Tooltip from '../../../../../../components/Tooltip';

const Headings = () => (
  <ListItem isHeader>
    <ColDiv flex={1}>
      <Typography.Body type="Label" isEllipsis>
        Metric Name
      </Typography.Body>
    </ColDiv>
    <ColDiv flex={1}>
      <Typography.Body type="Label" isEllipsis>
        Output Field Name
      </Typography.Body>
    </ColDiv>
    <ColDiv flex={0.5}>
      <Typography.Body type="Label" isEllipsis>
        Datasets
      </Typography.Body>
    </ColDiv>
    <ColDiv flex={0.5}>
      <Typography.Body type="Label" isEllipsis>
        Query Date
      </Typography.Body>
    </ColDiv>
    <ColDiv flex={0.5}>
      <Typography.Body type="Label" isEllipsis>
        Date Period
      </Typography.Body>
    </ColDiv>
    <ColDiv flex={0.5}>
      <Tooltip content={'Date Period Length'}>
        <Typography.Body type="Label" isEllipsis>
          Date Period Length
        </Typography.Body>
      </Tooltip>
    </ColDiv>
    <ColDiv />
  </ListItem>
);

const PerformanceMetricsList = ({ isReadOnly }: { isReadOnly?: boolean }) => {
  const { wizardState } = useContext(PerformanceWizardContext);
  const metrics = wizardState.config.metricsStep.metrics;
  return (
    <List
      Body={
        <div>
          {metrics.map((m) => (
            <MetricListItem
              performanceMetric={m}
              key={m.metricId}
              isReadOnly={isReadOnly}
            />
          ))}
        </div>
      }
      Headers={<Headings />}
    />
  );
};

export default PerformanceMetricsList;
