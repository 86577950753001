const isNumber = (x: any | number): x is number => {
  return !isNaN(x);
};

const trimTrailingZeros = (x: string) => {
  if (!x.includes('.')) {
    return x;
  }
  const [pre, post] = x.split('.');
  const shouldTrimZeros = post === '00' || post === '0';
  if (shouldTrimZeros) {
    return pre;
  }

  const trailing = x.split('.')[1];
  if (trailing.length > 2) {
    return `${pre}.${post[0]}${post[1]}`;
  }

  return x;
};

const format = (x: number) => {
  return trimTrailingZeros(x.toPrecision());
};

const abbreviateNumber = (x: any | number) => {
  if (!isNumber(x) || typeof x === 'string') {
    if (process.env.NODE_ENV !== 'test') {
      console.warn(`abbreviateNumber: ${x} is not a number`);
    }
    return undefined;
  }

  const shouldAbbreviate = Math.abs(x) >= 10000;
  if (!shouldAbbreviate) {
    return undefined;
  }

  const tenK = 10000;
  const million = tenK * 100;
  const billion = million * 1000;
  const trillion = billion * 1000;

  const thousands = tenK.toString().length;
  const millions = million.toString().length;
  const billions = billion.toString().length;
  const trillions = trillion.toString().length;

  const numDigits = Math.abs(Math.floor(x)).toString().length;

  if (numDigits >= thousands && numDigits < millions) {
    const n = format(x / 1000);
    const abbreviation = 'K';
    return {
      n,
      abbreviation,
      abbreviated: `${n}${abbreviation}`,
    };
  } else if (numDigits >= millions && numDigits < billions) {
    const n = format(x / million);
    const abbreviation = 'M';
    return {
      n,
      abbreviation,
      abbreviated: `${n}${abbreviation}`,
    };
  } else if (numDigits >= billions && numDigits < trillions) {
    const n = format(x / billion);
    const abbreviation = 'B';
    return {
      n,
      abbreviation,
      abbreviated: `${n}${abbreviation}`,
    };
  } else if (numDigits >= trillions) {
    const n = format(x / trillion);
    const abbreviation = 'T';
    return {
      n,
      abbreviation,
      abbreviated: `${n}${abbreviation}`,
    };
  } else {
    return undefined;
  }
};

export default abbreviateNumber;
