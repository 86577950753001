export const ORIGIN_ZIP_CODE = 'originZipCode';
export const ORIGIN_FIELDS = [
  'startCityAndState',
  'originState',
  ORIGIN_ZIP_CODE,
  'pickUpArea',
  'originRegion',
  'ORIGCITY',
  'ORIGPROV',
];

export const DESTINATION_ZIP_CODE = 'destinationZipCode';
export const DESTINATION_FIELDS = [
  'finishCityAndState',
  'destinationState',
  DESTINATION_ZIP_CODE,
  'destinationArea',
  'destinationRegion',
  'DESTCITY',
  'DESTPROV',
];
