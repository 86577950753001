import React, { useContext, useEffect, useState } from 'react';
import DataTypePicker from './DataTypePicker';
import WidgetGalleryContext from '../../../contexts/WidgetGalleryContext';

const DataTypePickerContainer = () => {
  const { dataTypeOptions, onDataTypeSelected, selectedDataSet } =
    useContext(WidgetGalleryContext);
  const [options, setOptions] = useState<DropdownOption[]>([]);
  useEffect(() => {
    setOptions(
      dataTypeOptions.map((d) => ({
        label: d.label,
        onSelected: () => onDataTypeSelected(d.value),
        isSelected: selectedDataSet === d.value,
      })),
    );
  }, [dataTypeOptions, onDataTypeSelected, selectedDataSet]);

  return <DataTypePicker dataTypeOptions={options} />;
};

export default DataTypePickerContainer;
