import React from 'react';
import styled from 'styled-components';
import Loading from 'components/Loading';

const WrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 70px;
`;

const LoadingSearch = () => (
  <WrapperDiv>
    <Loading />
  </WrapperDiv>
);

export default LoadingSearch;
