import React, { useContext } from 'react';
import moment from 'moment';

import PerformanceCell from '../../components/PerformanceTargetsBoard/PerformanceCell/PerformanceCell';
import Colors2 from '../../theme/Colors2';
import Tooltip from '../../components/Tooltip';
import { TooltipContent } from '../../components/ProgressCellRenderer';
import Row from '../../components/Common/Row';
import { DeltaNumber } from '../../components/OnTargetCellRenderer';
import AccountContext from '../../contexts/AccountContext';
import { getKpiTargetForMonth } from '../../components/Goals/GeneralGoalForm/KpiForm/AdvancedTargetInput/MonthlyTargetBuilder';
import colors from '../../theme/colors';
import { KPICellParams } from './GoalKpiVis/GoalKpiVis';
import useMetric from '../../hooks/useMetric';
import formatFloat from '../../api/getChartSeries/formatFloat';
import GeneralGoalContext from '../../contexts/GeneralGoalContext';
import metricTypeCheckers from '../../types/metricTypeCheckers';
import visTypeCheckers from '../../types/visTypeCheckers';
import useValueFormatters from '../../hooks/useValueFormatters';

const useGoalInterval = (params: KPICellParams) => {
  const { formatMetric } = useValueFormatters();
  const {
    goal,
    isPrimaryCumulative,
    dateBuckets,
    currentDateBucket,
    lastCompletedDateBucket,
  } = useContext(GeneralGoalContext);
  const { isDemoAccount, demoAccountNow } = useContext(AccountContext);
  const { date, isCard } = params.colDef.cellRendererParams;
  const metric = useMetric(
    visTypeCheckers.isGeneralGoal(goal) ? goal.metricId : undefined,
  );

  if (!metric || !visTypeCheckers.isGeneralGoal(goal)) {
    return {
      hasActual: false,
      isCurrentMonth: false,
      popupText: '',
      onTargetText: '',
      isGood: false,
      diff: 0,
      metricName: '',
      actualFormatted: '',
      expectedFormatted: '',
      color: colors.RADICAL_RED,
    };
  }
  const { formatting } = metric;
  const expected = (() => {
    const baseExpected = (() => {
      if (goal.targetMode === 'basic') {
        const t = goal.target === undefined ? 0 : goal.target;
        if (isPrimaryCumulative) {
          return t / dateBuckets.length;
        } else {
          return t;
        }
      } else if (goal.targetMode === 'advanced') {
        return getKpiTargetForMonth(goal.advancedTarget, date);
      } else {
        return 0;
      }
    })();

    const isCurrentMonth = currentDateBucket === date;
    const isProrateEligible = metricTypeCheckers.isNormalMetric(metric)
      ? metric.aggFunc === 'sum' || metric.aggFunc === 'count'
      : false;
    if (isCurrentMonth && isProrateEligible) {
      const expectedPerDay = (() => {
        if (goal.cadence === 'week') {
          return baseExpected / 7;
        }
        return baseExpected / moment(date).daysInMonth();
      })();
      const now = moment(
        isDemoAccount && demoAccountNow ? demoAccountNow : undefined,
      );
      const elapsedDays = Math.abs(now.diff(moment(date), 'days')) + 1;
      return formatFloat(expectedPerDay * elapsedDays, formatting.precision);
    } else {
      return formatFloat(baseExpected, formatting.precision);
    }
  })();
  const actual =
    params.value === null || params.value === undefined
      ? null
      : formatFloat(params.value, formatting.precision);
  const isGood = formatting.positiveDeltaIsGood
    ? actual >= expected
    : actual <= expected;
  const diff = actual - expected;
  const adverb = (() => {
    if (isGood) {
      if (formatting.positiveDeltaIsGood) {
        return 'ahead of';
      } else {
        return 'behind';
      }
    } else {
      if (formatting.positiveDeltaIsGood) {
        return 'behind';
      } else {
        return 'ahead of';
      }
    }
  })();
  const actualFormatted = formatMetric({ value: actual, metricId: metric.id });
  const expectedFormatted = formatMetric({
    value: expected,
    metricId: metric.id,
  });
  const diffFormatted = formatMetric({
    value: Math.abs(diff),
    metricId: metric.id,
  });
  const onTargetText = `${diffFormatted} ${adverb} target`;
  const popupText = `${actualFormatted} / ${expectedFormatted}`;
  const color = isGood ? colors.MEDIUM_SEA_GREEN : colors.RADICAL_RED;
  const hasActual = actual !== null;

  return {
    hasActual,
    isCurrentMonth: date === lastCompletedDateBucket && !isCard,
    popupText,
    onTargetText,
    isGood,
    diff,
    metricName: metric.name,
    actualFormatted,
    expectedFormatted,
    color,
  };
};

const GoalIntervalRenderer = (params: KPICellParams) => {
  const {
    isCurrentMonth,
    popupText,
    onTargetText,
    isGood,
    diff,
    metricName,
    actualFormatted,
    expectedFormatted,
    color,
    hasActual,
  } = useGoalInterval(params);

  if (!hasActual) {
    return (
      <PerformanceCell isCurrentMonth={isCurrentMonth} isInput>
        <DeltaNumber color={Colors2.Grey['4']}>{expectedFormatted}</DeltaNumber>
      </PerformanceCell>
    );
  }

  return (
    <PerformanceCell isCurrentMonth={isCurrentMonth} isInput>
      <Tooltip
        isAltTooltip
        content={
          <TooltipContent
            progressText={popupText}
            onTargetText={onTargetText}
            isGood={isGood}
            delta={diff}
            tooltipTitle={metricName}
          />
        }
      >
        <Row centerAlign style={{ justifyContent: 'flex-end' }}>
          <DeltaNumber color={color}>{actualFormatted}</DeltaNumber>
        </Row>
      </Tooltip>
    </PerformanceCell>
  );
};

export default GoalIntervalRenderer;
