import React from 'react';

interface CustomerLaneSlideOutContextType {
  lane?: string;
  customer?: string;
  documentId?: string;
  isCustomerLaneSlideOpen: boolean;
  open: () => void;
  close: () => void;
}

const CustomerLaneSlideOutContext =
  React.createContext<CustomerLaneSlideOutContextType>(
    {} as CustomerLaneSlideOutContextType,
  );

export default CustomerLaneSlideOutContext;
