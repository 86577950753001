import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

const buildQuery = ({
  query,
  from,
  limit,
  sortBy,
  dataType,
  filters,
  dateScope,
}: {
  query: string;
  from: number;
  limit: number;
  sortBy: GridSortField[];
  dataType: string;
  filters: FilterInput[];
  dateScope: DateRangeInput;
}) => ({
  query: gql`
    query ${query}(
      $from: Int
      $limit: Int
      $sortBy: [SortField]
      $dataType: String!
      $filters: [FilterInput]!
      $dateScope: [DateRangeInput!]!
    ) {
      ${query} (
        from: $from
        limit: $limit
        sortBy: $sortBy
        dataType: $dataType
        filters: $filters
        dateScope: $dateScope
      )
    }
  `,
  variables: {
    from,
    limit,
    sortBy,
    dataType,
    filters,
    dateScope,
  },
});

const buildQueryWithoutFrom = ({
  query,
  limit,
  sortBy,
  dataType,
  filters,
  dateScope,
}: {
  query: string;
  limit: number;
  sortBy: GridSortField[];
  dataType: string;
  filters: FilterInput[];
  dateScope: DateRangeInput;
}) => ({
  query: gql`
      query ${query}(
          $limit: Int
          $sortBy: [SortField]
          $dataType: String!
          $filters: [FilterInput]!
          $dateScope: [DateRangeInput!]!
      ) {
          ${query} (
          limit: $limit
          sortBy: $sortBy
          dataType: $dataType
          filters: $filters
          dateScope: $dateScope
          )
      }
  `,
  variables: {
    limit,
    sortBy,
    dataType,
    filters,
    dateScope,
  },
});

const getGridRows = async ({
  query,
  from,
  limit,
  sortBy,
  dataType,
  filters,
  client,
  dateScope,
}: {
  query: string;
  from?: number;
  limit: number;
  sortBy: GridSortField[];
  dataType: string;
  filters: FilterInput[];
  client: ApolloClient<NormalizedCacheObject>;
  dateScope: DateRangeInput;
}) => {
  const gqlQuery = from
    ? buildQuery({
        query,
        from,
        limit,
        sortBy,
        dataType,
        filters,
        dateScope,
      })
    : buildQueryWithoutFrom({
        query,
        limit,
        sortBy,
        dataType,
        filters,
        dateScope,
      });
  const response = await client.query(gqlQuery);
  return response.data[query];
};

export default getGridRows;
