import { useCallback, useContext, useEffect, useState } from 'react';
import useLockedDebouncedEffect from 'hooks/useLockedDebouncedEffect';
import WallboardContext from 'contexts/WallboardContext';
import {
  IPaceCalculateForExpressionArgs,
  IPaceCalculateForMetricArgs,
  IPaceHistogramTerm,
  IQueryResponse,
} from '../types';
import calculatePaceForMetric from '../api/calculatePaceForMetric';
import calculatePaceForExpression from '../api/calculatePaceForExpression';
import calculateTotalsPaceForMetric from '../api/calculateTotalsPaceForMetric';
import calculateTotalsPaceForExpression from '../api/calculateTotalsPaceForExpression';
import useMetricArgs from './useMetricArgs';
import useCompoundMetricArgs from './useCompoundMetricArgs';
import useTooltipDateRanges from './useTooltipDateRanges';

const isExpressionArgs = (
  args: IPaceCalculateForMetricArgs | IPaceCalculateForExpressionArgs,
): args is IPaceCalculateForExpressionArgs => {
  return 'expression' in args;
};

const usePaceQuery = ({
  paceMatrix,
}: {
  paceMatrix: VisualisationDefinitions.PaceMatrix;
}) => {
  const { isWallboard } = useContext(WallboardContext);
  const metricArgs = useMetricArgs({ paceMatrix });
  const compoundMetricArgs = useCompoundMetricArgs({ paceMatrix });
  const {
    getAverageDateRange,
    getActualDateRange,
    getExcludedDateRangeLabels,
  } = useTooltipDateRanges({
    args: metricArgs ? metricArgs : compoundMetricArgs,
  });
  const [response, setResponse] = useState<IQueryResponse>();
  const [isLoadingResponse, setIsLoadingResponse] = useState<boolean>(true);

  const getMetricPaceData = useCallback(
    async (args: IPaceCalculateForMetricArgs): Promise<IQueryResponse> => {
      const results: IPaceHistogramTerm[] = [];
      const totalsPromise = calculateTotalsPaceForMetric(args);
      let currentPagePromise = calculatePaceForMetric(args);
      let [totals, currentPage] = await Promise.all([
        totalsPromise,
        currentPagePromise,
      ]);
      if (isWallboard) {
        return {
          terms: currentPage,
          totals,
        };
      }

      while (currentPage.length > 0) {
        results.push(...currentPage);
        const newArgs: IPaceCalculateForMetricArgs = {
          ...args,
          groupBy: {
            ...args.groupBy,
            after: currentPage[currentPage.length - 1].name,
          },
        };
        currentPage = await calculatePaceForMetric(newArgs);
      }

      return {
        terms: results,
        totals,
      };
    },
    [isWallboard],
  );

  const getExpressionPaceData = useCallback(
    async (args: IPaceCalculateForExpressionArgs): Promise<IQueryResponse> => {
      const results: IPaceHistogramTerm[] = [];
      const totalsPromise = calculateTotalsPaceForExpression(args);
      let currentPagePromise = calculatePaceForExpression(args);
      let [totals, currentPage] = await Promise.all([
        totalsPromise,
        currentPagePromise,
      ]);

      if (isWallboard) {
        return {
          terms: currentPage,
          totals,
        };
      }

      while (currentPage.length > 0) {
        results.push(...currentPage);
        const newArgs: IPaceCalculateForExpressionArgs = {
          ...args,
          groupBy: {
            ...args.groupBy,
            after: currentPage[currentPage.length - 1].name,
          },
        };
        currentPage = await calculatePaceForExpression(newArgs);
      }

      return {
        terms: results,
        totals,
      };
    },
    [isWallboard],
  );

  const getPaceData = useCallback(
    async (
      args: IPaceCalculateForMetricArgs | IPaceCalculateForExpressionArgs,
    ): Promise<IQueryResponse> => {
      setIsLoadingResponse(true);
      if (isExpressionArgs(args)) {
        return getExpressionPaceData(args);
      }
      return getMetricPaceData(args);
    },
    [getExpressionPaceData, getMetricPaceData],
  );

  const responseHandler = useCallback((response: IQueryResponse) => {
    setResponse(response);
    setIsLoadingResponse(false);
  }, []);

  useEffect(() => {
    setIsLoadingResponse(true);
  }, [metricArgs, compoundMetricArgs]);

  useLockedDebouncedEffect({
    args: metricArgs ? metricArgs : compoundMetricArgs,
    callback: getPaceData,
    responseHandler,
  });

  return {
    response,
    isLoadingResponse,
    getAverageDateRange,
    getActualDateRange,
    getExcludedDateRangeLabels,
  };
};

export default usePaceQuery;
