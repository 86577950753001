import { useEffect, useState } from 'react';
import useGetInitialCategories from './useGetInitialCategories';
import useTargetFormCallbacks from './useTargetFormCallbacks';

const useTargetValueModeOptions = ({
  target,
  group,
}: {
  target: Targets.Wizard.Target;
  group: Targets.Wizard.TargetGroup;
}) => {
  const { updateTarget } = useTargetFormCallbacks();
  const getInitialCategories = useGetInitialCategories();
  const [options, setOptions] = useState<DropdownOption[]>([]);

  useEffect(() => {
    setOptions([
      {
        label: 'Target Is',
        isSelected: target.categories.length > 0,
        onSelected: () => {
          const newTarget: Targets.Wizard.Target = {
            ...target,
            categories: getInitialCategories(),
          };
          updateTarget({
            group,
            target: newTarget,
          });
        },
      },
      {
        label: 'No Target',
        isSelected: target.categories.length === 0,
        onSelected: () => {
          const newTarget: Targets.Wizard.Target = {
            ...target,
            categories: [],
          };

          console.log(newTarget);
          updateTarget({
            target: newTarget,
            group,
          });
        },
      },
    ]);
  }, [getInitialCategories, group, target, updateTarget]);

  return options;
};

export default useTargetValueModeOptions;
