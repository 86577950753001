import firebase from 'firebase/compat/app';
import { COPY_CONTENT_RECEIPTS_COLLECTION } from './constants';
import { CopyContent } from '../screens/CopyContent/types';

const copyContentReceiptConverter = {
  toFirestore(
    copyContentReceipt: CopyContent.NewContentReceipt,
  ): firebase.firestore.DocumentData {
    return copyContentReceipt;
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot<CopyContent.NewContentReceipt>,
    options: firebase.firestore.SnapshotOptions,
  ): CopyContent.NewContentReceipt {
    const receipt = snapshot.data(options);
    if (!receipt.selectedContent.boards) {
      receipt.selectedContent.boards = [];
    }

    return receipt;
  },
};

const getCopyContentReceiptsRef = ({
  accountId,
  db,
}: {
  accountId: string;
  db?: firebase.firestore.Firestore;
}) => {
  return (db ? db : firebase.firestore())
    .collection('accounts')
    .doc(accountId)
    .collection(COPY_CONTENT_RECEIPTS_COLLECTION)
    .withConverter(copyContentReceiptConverter);
};

export default getCopyContentReceiptsRef;
