import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Typography from '../../../atoms/Typography';
import TextInput from '../../../atoms/TextInput';
import Button from '../../../atoms/Button';
import EntityDefinitionsContext from '../../../../contexts/EntityDefinitionsContext';
import useEntity from '../../../../hooks/useEntity';
import Dropdown from '../../../../components/Inputs/Dropdown';

const EntityLinksDiv = styled.div`
  background-color: #fbfbfb;
  padding: 16px;
  gap: 16px;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: #f0f0f0;
`;

const EntityLinksGridDiv = styled.div`
  margin-bottom: 16px;
`;

const RowDiv = styled.div<{ isHeaders?: boolean }>`
  display: flex;
  flex-direction: row;

  border-width: 1px 1px 0 1px;
  &:last-of-type {
    border-bottom-width: 1px;
  }
  border-style: solid;
  border-color: #e0e0e0;

  background-color: ${(props) => (props.isHeaders ? '#f6f6f6' : '#FFFFFF')};

  width: 100%;
  height: ${(props) => (props.isHeaders ? 40 : 48)}px;
  gap: 8px;
`;

const ColDiv = styled.div<{ isEnd?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: ${(props) => (props.isEnd ? '32px' : '100%')};
  padding-left: 8px;
  padding-right: 8px;
`;

const EntityLinkInput = ({
  link,
  setEntityLinks,
}: {
  link: EngagementPortalEntityLink;
  setEntityLinks: React.Dispatch<
    React.SetStateAction<EngagementPortalEntityLink[]>
  >;
}) => {
  const { entity } = useEntity(link.entityId);

  const onChangeNavLabel = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setEntityLinks((currentLinks) => {
        return currentLinks.map((cl) => {
          if (cl.entityId === link.entityId) {
            return {
              ...cl,
              navLabel: event.target.value,
            };
          }

          return cl;
        });
      });
    },
    [link.entityId, setEntityLinks],
  );

  const onChangeUsersLabel = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setEntityLinks((currentLinks) => {
        return currentLinks.map((cl) => {
          if (cl.entityId === link.entityId) {
            return {
              ...cl,
              usersTabLabel: event.target.value,
            };
          }

          return cl;
        });
      });
    },
    [link.entityId, setEntityLinks],
  );

  const onRemoveClicked = useCallback(() => {
    setEntityLinks((currentLinks) => {
      return currentLinks.filter((cl) => {
        return cl.entityId !== link.entityId;
      });
    });
  }, [link.entityId, setEntityLinks]);

  if (!entity) {
    return null;
  }

  return (
    <RowDiv>
      <ColDiv>
        <Typography.Body type={'Body 13'}>{entity.name}</Typography.Body>
      </ColDiv>
      <ColDiv>
        <TextInput
          value={link.navLabel}
          onChange={onChangeNavLabel}
          data-testid={'entity-nav-label'}
        />
      </ColDiv>
      <ColDiv>
        <TextInput
          value={link.usersTabLabel}
          onChange={onChangeUsersLabel}
          data-testid={'entity-users-label'}
        />
      </ColDiv>
      <ColDiv isEnd>
        <Button
          size={'Small'}
          type={'Ghost'}
          onClick={onRemoveClicked}
          icon={'cross'}
        />
      </ColDiv>
    </RowDiv>
  );
};

const AddEntityButton = ({
  entityLinks,
  setEntityLinks,
}: {
  entityLinks: EngagementPortalEntityLink[];
  setEntityLinks: React.Dispatch<
    React.SetStateAction<EngagementPortalEntityLink[]>
  >;
}) => {
  const { entityDefinitions } = useContext(EntityDefinitionsContext);

  const getOptions = useCallback((): DropdownOption[] => {
    return entityDefinitions
      .filter((ed) => !entityLinks.some((el) => el.entityId === ed.id))
      .map((entity) => {
        return {
          label: entity.name,
          isSelected: false,
          onSelected: () => {
            setEntityLinks((currentLinks) => {
              return [
                ...currentLinks,
                {
                  entityId: entity.id,
                  navLabel: entity.name,
                  usersTabLabel: entity.name,
                },
              ];
            });
          },
        };
      });
  }, [entityDefinitions, entityLinks, setEntityLinks]);
  const [options, setOptions] = useState<DropdownOption[]>([]);
  useEffect(() => {
    setOptions(getOptions);
  }, [getOptions]);

  return (
    <div style={{ width: 150 }}>
      <Dropdown options={options} label={'Add Entity'} />
    </div>
  );
};

const EntityLinksInput = ({
  entityLinks,
  setEntityLinks,
}: {
  entityLinks: EngagementPortalEntityLink[];
  setEntityLinks: React.Dispatch<
    React.SetStateAction<EngagementPortalEntityLink[]>
  >;
}) => {
  return (
    <EntityLinksDiv>
      <div style={{ marginBottom: 16 }}>
        <Typography.Body type={'Label'}>
          Select Entities to enable Resource Navigator views in this portal
        </Typography.Body>
      </div>
      {entityLinks.length > 0 && (
        <EntityLinksGridDiv>
          <RowDiv>
            <ColDiv>
              <Typography.Body type={'Label'}>Entity</Typography.Body>
            </ColDiv>
            <ColDiv>
              <Typography.Body type={'Label'}>Nav Label</Typography.Body>
            </ColDiv>
            <ColDiv>
              <Typography.Body type={'Label'}>Users Tab Label</Typography.Body>
            </ColDiv>
            <ColDiv isEnd />
          </RowDiv>
          {entityLinks.map((link) => (
            <EntityLinkInput
              link={link}
              setEntityLinks={setEntityLinks}
              key={link.entityId}
            />
          ))}
        </EntityLinksGridDiv>
      )}
      <AddEntityButton
        entityLinks={entityLinks}
        setEntityLinks={setEntityLinks}
      />
    </EntityLinksDiv>
  );
};

export default EntityLinksInput;
