import React from 'react';
import { Col, CostRowElement } from './ActivityGrid';
import Row from 'components/Common/Row';
import Typography from 'kingpin/atoms/Typography';
import useHoverRow from './hooks/useHoverRow';
import Colors2 from 'theme/Colors2';

const TotalItemLeft = () => {
  const { disableHover } = useHoverRow();

  return (
    <CostRowElement
      onMouseEnter={disableHover}
      centerAlign
      style={{
        width: '100%',
        borderBottom: `1px solid ${Colors2.GridBorder}`,
        borderLeft: `1px solid ${Colors2.GridBorder}`,
        backgroundColor: '#F9F9F9',
      }}
    >
      <Col maxWidth>
        <Row centerAlign spaceBetween style={{ width: '100%' }}>
          <Row centerAlign>
            <div
              style={{
                marginLeft: '24px',
                display: 'flex',
                paddingRight: '24px',
                whiteSpace: 'nowrap',
              }}
            >
              <Typography.Body type="Body 12">Total Costs</Typography.Body>
            </div>
          </Row>
        </Row>
      </Col>
    </CostRowElement>
  );
};

export default TotalItemLeft;
