import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { GRID_PAGE_SIZE } from '../../constants';

const search = async ({
  dataType,
  filters,
  dateScope,
  sortBy,
  client,
  searchAfter,
  includeFields,
}: {
  dataType: string;
  filters: FilterInput[];
  dateScope: DateRangeInput;
  sortBy: SortField[];
  client: ApolloClient<NormalizedCacheObject>;
  searchAfter?: string;
  includeFields?: string[];
}): Promise<{
  count: number;
  searchAfter: string;
  documents: ElasticDocument[];
}> => {
  const query = {
    query: gql`
      query Search2(
        $dataType: String!
        $filters: [FilterInput]!
        $dateScope: [DateRangeInput!]!
        $sortBy: [SortField]
        $size: Int
        $searchAfter: String
        $includeFields: [String]
      ) {
        search2(
          dataType: $dataType
          filters: $filters
          dateScope: $dateScope
          sortBy: $sortBy
          size: $size
          searchAfter: $searchAfter
          includeFields: $includeFields
        ) {
          count
          documents
          searchAfter
        }
      }
    `,
    variables: {
      dataType,
      filters,
      dateScope,
      sortBy,
      size: GRID_PAGE_SIZE,
      searchAfter,
      includeFields,
    },
  };

  const response = await client.query(query);
  return response.data.search2;
};

export default search;
