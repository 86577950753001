import React from 'react';
import ReactPortal from 'components/ReactPortal';
import { DATA_MANAGER_TOP_ACTION_DIV_ID } from '../AllSets/constants';
import CreateEntityButton from './CreateEntityButton';
import EntityDefinitions from './EntityDefinitions';
import useEntityList from './useEntityList';
import PermissionGates from 'components/PermissionGates';
import PERMISSIONS from '../../../permissionDefinitions';

const EntityDefinitionsContainer = () => {
  const { searchText, items, onSearchTextChanged, isLoading } = useEntityList();

  return (
    <>
      <ReactPortal elementId={DATA_MANAGER_TOP_ACTION_DIV_ID}>
        <PermissionGates.Has
          requiredPermission={PERMISSIONS.DATA_MANAGEMENT.ENTITY_CONFIGURATION}
        >
          <CreateEntityButton />
        </PermissionGates.Has>
      </ReactPortal>
      <EntityDefinitions
        searchText={searchText}
        items={items}
        onSearchTextChanged={onSearchTextChanged}
        isLoading={isLoading}
      />
    </>
  );
};

export default EntityDefinitionsContainer;
