import React, { MutableRefObject, useCallback, useState } from 'react';
import HighchartsReact, {
  HighchartsReactRefObject,
} from 'highcharts-react-official';
import Highcharts from 'highcharts';
import Histograms from 'highcharts/modules/histogram-bellcurve';
import ChartEvents from 'highcharts-custom-events';
import Highmaps from 'highcharts/modules/map';
import SolidGauge from 'highcharts/modules/solid-gauge.js';
import HighchartsMore from 'highcharts/highcharts-more.js';
import Drilldown from 'highcharts/modules/drilldown';
import Treemap from 'highcharts/modules/treemap';
import Row from './Common/Row';
import DashboardGadgetReportLinkButton from './DashboardGadgetReportLinkButton';
import PopupGridButton from './PopupGridButton';
import DashboardGadgetFilteringButton from './DashboardGadgetFilteringButton';
import ShowOnMouseOverUnlessTour from './ShowOnMouseOverUnlessTour';
import CopyGadgetButton from './CopyGadgetButton';
import NoDataToDisplay from './V5Gadget/NoDataToDisplay';
import FlexCentered from './Common/FlexCentered';
import CanvasCardTitle from './CanvasCardTitle';
import CanvasCard from './Visualisations/CanvasCard';
import MetricsInfoButton from './MetricsInfoButton';
import { Z_INDEX } from '../constants';
import ContextMenu from '../kingpin/atoms/ContextMenu';
import isRemindersGadget from '../types/visTypeCheckers/isRemindersGadget';

Histograms(Highcharts);
HighchartsMore(Highcharts);
ChartEvents(Highcharts);
Highmaps(Highcharts);
Drilldown(Highcharts);
Treemap(Highcharts);
SolidGauge(Highcharts);
// @ts-ignore
Highcharts.SVGRenderer.prototype.symbols['c-rect'] = function (x, y, w, h) {
  const move = ['M', x, y + h / 2];
  const draw = ['L', x + w, y + h / 2];

  return [...move, ...draw];
};

export const ChartTopBar = ({
  chartDefinition,
  extraControl,
  isForcedVisible,
}: {
  chartDefinition?: VisualisationDefinition;
  extraControl?: JSX.Element;
  isForcedVisible?: boolean;
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const onMenuOpen = useCallback(() => {
    setIsMenuOpen(true);
  }, []);
  const onMenuClose = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  if (!chartDefinition) {
    return null;
  }

  return (
    <Row
      spaceBetween
      style={{
        zIndex: 'unset',
        width: '100%',
        height: '100%',
      }}
      centerAlign
    >
      <CanvasCardTitle title={chartDefinition.name} />
      <Row centerAlign>
        <ShowOnMouseOverUnlessTour>
          <DashboardGadgetReportLinkButton />
        </ShowOnMouseOverUnlessTour>
        <ShowOnMouseOverUnlessTour isForcedVisible={isForcedVisible}>
          <>
            {!!extraControl && (
              <div style={{ marginRight: 8 }}>{extraControl}</div>
            )}
          </>
        </ShowOnMouseOverUnlessTour>
        {!isRemindersGadget(chartDefinition) && (
          <ShowOnMouseOverUnlessTour>
            <div style={{ marginRight: 8 }}>
              <MetricsInfoButton visualisation={chartDefinition} />
            </div>
          </ShowOnMouseOverUnlessTour>
        )}
        <ShowOnMouseOverUnlessTour
          isForcedVisible={isForcedVisible || isMenuOpen}
        >
          <ContextMenu
            buttonType="Ghost"
            onOpen={onMenuOpen}
            onClose={onMenuClose}
          >
            <PopupGridButton chartDefinition={chartDefinition} />
            <DashboardGadgetFilteringButton />
            <CopyGadgetButton />
          </ContextMenu>
        </ShowOnMouseOverUnlessTour>
      </Row>
    </Row>
  );
};

export const ChartContent = ({
  chartRef,
  options,
  zIndex,
  noDataToDisplay,
  useSmallNoDataMessage,
}: {
  chartRef: MutableRefObject<HighchartsReactRefObject | null>;
  options: any;
  zIndex?: number;

  noDataToDisplay?: boolean;
  useSmallNoDataMessage?: boolean;
}) => {
  if (noDataToDisplay) {
    return (
      <NoDataToDisplay size={useSmallNoDataMessage ? 'small' : 'normal'} />
    );
  } else {
    return (
      <FlexCentered
        style={{ height: '100%', width: '100%', padding: '0px 8px' }}
      >
        <HighchartsReact
          ref={chartRef}
          highcharts={Highcharts}
          options={options}
          containerProps={{
            style: {
              width: '100%',
              height: '100%',
              zIndex: zIndex === undefined ? Z_INDEX.CHART : zIndex,
            },
          }}
        />
      </FlexCentered>
    );
  }
};

const Chart = ({
  chartRef,
  options,
  zIndex,
  chartDefinition,
  bottomBar,
  noDataToDisplay,
  useSmallNoDataMessage,
  isGauge,
}: {
  chartRef: MutableRefObject<any>;
  options: any;
  zIndex?: number;
  chartDefinition?:
    | VisualisationDefinitions.Gauge
    | VisualisationDefinitions.SingleMetricDateMatrix
    | V5ChartDefinition;
  bottomBar?: JSX.Element | JSX.Element[];
  noDataToDisplay?: boolean;
  useSmallNoDataMessage?: boolean;
  isGauge?: boolean;
}) => (
  <CanvasCard
    topBar={
      chartDefinition ? (
        <ChartTopBar chartDefinition={chartDefinition} />
      ) : undefined
    }
    content={
      <ChartContent
        chartRef={chartRef}
        options={options}
        zIndex={zIndex}
        noDataToDisplay={noDataToDisplay}
        useSmallNoDataMessage={useSmallNoDataMessage}
      />
    }
    bottomBar={bottomBar}
    isBottomBarAbsolute={isGauge}
  />
);

export default Chart;
