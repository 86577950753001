import React, { useContext, useEffect, useState } from 'react';
import DataManagerSpecificSetContext from 'contexts/DataManagerSpecificSetContext';
import DatasetDefinitionsContext from '../contexts/DatasetDefinitionsContext';
import BaseViewsContext from '../contexts/BaseViewsContext';
import { useParams } from 'react-router-dom';

const DataManagerSpecificSetProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { dataset } = useParams<{
    dataset?: string;
  }>();
  const [baseView, setBaseView] = useState<FleetOps.BaseView>();
  const [datasetDef, setDatasetDef] = useState<FleetOps.DatasetDefinition>();
  const { datasets } = useContext(DatasetDefinitionsContext);
  const { baseViews } = useContext(BaseViewsContext);

  useEffect(() => {
    if (!dataset) {
      return;
    }

    const bv = baseViews[dataset];
    const ds = datasets.find((d) => d.type === dataset);

    setBaseView(bv);
    setDatasetDef(ds);
  }, [baseViews, dataset, datasets]);

  if (!baseView || !datasetDef) {
    return null;
  }

  return (
    <DataManagerSpecificSetContext.Provider
      value={{
        baseView,
        datasetDef,
        isOnSpecificSet: true,
      }}
    >
      {children}
    </DataManagerSpecificSetContext.Provider>
  );
};

export default DataManagerSpecificSetProvider;
