import React, { useContext } from 'react';
import { PortalsContext } from 'contextProviders/PortalsProvider';
import portalTypeCheckers from 'types/portalTypeCheckers';
import EngagementPortalNavSection from './EngagementPortalNavSection';
import AdminPortalNavSection from './AdminPortalNavSection';
import ExecutivePortalNavSection from './ExecutivePortalNavSection';

const PortalNavSection = () => {
  const { selectedPortal, lastSelectedPortal } = useContext(PortalsContext);
  const portalToShow = selectedPortal ? selectedPortal : lastSelectedPortal;

  if (!portalToShow) {
    return null;
  } else if (portalTypeCheckers.isExecutivePortal(portalToShow)) {
    return <ExecutivePortalNavSection portal={portalToShow} />;
  } else if (portalTypeCheckers.isEngagementPortal(portalToShow)) {
    return <EngagementPortalNavSection portal={portalToShow} />;
  } else if (portalTypeCheckers.isAdminPortal(portalToShow)) {
    return <AdminPortalNavSection />;
  }

  return null;
};

export default PortalNavSection;
