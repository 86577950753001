import React from 'react';
import ListWrapper from '../ListWrapper';
import List from '../List';
import FieldListItem from './FieldListItem';
import FieldSlideOut from '../FieldSlideOut';
import Headers from './Headers';
import DragAndDropList from '../../../../components/DragAndDropList';
import TextInput from '../../../../kingpin/atoms/TextInput';
import Row from 'components/Common/Row';

const Dataset = ({
  searchText,
  baseViewFields,
  onSearchTextChanged,
  baseView,
  onOrderChanged,
  canDrag,
}: {
  searchText: string;
  baseViewFields: FleetOps.BaseViewField[];
  onSearchTextChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
  baseView: FleetOps.BaseView;
  onOrderChanged: (newOrder: FleetOps.BaseViewField[]) => void;
  canDrag: boolean;
}) => (
  <div>
    <div style={{ padding: '16px 24px' }}>
      <Row>
        <div
          style={{
            minWidth: 160,
          }}
        >
          <TextInput
            value={searchText}
            onChange={onSearchTextChanged}
            data-testid="dataset-search"
            inputSize="Small"
            placeholder="Search"
            icon="search"
          />
        </div>
      </Row>
    </div>
    <ListWrapper>
      <Headers />
      <List>
        {canDrag && (
          <DragAndDropList
            items={baseViewFields}
            onOrderChanged={onOrderChanged}
            droppableId={'fields'}
            renderItem={(fieldView) => (
              <FieldListItem
                fieldView={fieldView}
                baseView={baseView}
                canDrag
              />
            )}
          />
        )}
        {!canDrag &&
          baseViewFields.map((fieldView) => (
            <FieldListItem
              fieldView={fieldView}
              baseView={baseView}
              canDrag={false}
              key={`${fieldView.field}-item`}
            />
          ))}
      </List>
    </ListWrapper>
    <FieldSlideOut />
  </div>
);

export default Dataset;
