import firebase from 'firebase/compat/app';
import STORE_CONSTANTS from '../constants';
import withoutNulls from '../../api/search/withoutNulls';

export const CHART_DEFS_DOC = 'chartDefs';
export const CURRENT_CHART_DEFS_VERSION = 'v1';

const chartDefConverter = {
  toFirestore(
    chartDefinition: V5ChartDefinition,
  ): firebase.firestore.DocumentData {
    return withoutNulls({ ...chartDefinition });
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ): V5ChartDefinition {
    const data = {
      ...snapshot.data(options),
      id: snapshot.id,
    };
    return data as V5ChartDefinition;
  },
};

const getChartDefsRef = ({
  accountId,
  db,
  versionOverride,
}: {
  accountId: string;
  db?: firebase.firestore.Firestore;
  versionOverride?: string;
}) => {
  return (db ? db : firebase.firestore())
    .collection('accounts')
    .doc(accountId)
    .collection(STORE_CONSTANTS.VISUALISATIONS_COLLECTION)
    .doc(CHART_DEFS_DOC)
    .collection(versionOverride ? versionOverride : CURRENT_CHART_DEFS_VERSION)
    .withConverter(chartDefConverter);
};

export default getChartDefsRef;
