import React from 'react';
import styled from 'styled-components';
import Button from 'kingpin/atoms/Button';

import Typography from 'kingpin/atoms/Typography';
import Dropdown from '../../Dropdown';
import Inputs from '../../index';
import Row from '../../../Common/Row';
import SearchableListPicker from '../../SearchableListPicker';
import CloseButton from '../../../CloseButton';
import FlexCentered from '../../../Common/FlexCentered';
import Loading from '../../../Loading';

const ContentDiv = styled.div`
  padding: 8px;
  width: 250px;
`;

const Block = styled.div`
  margin-bottom: 8px;
  padding-bottom: 8px;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.08);
`;

const HeadingDiv = styled.div`
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.08);

  margin-bottom: 8px;
  padding 0px 8px;
  height: 48px;
`;

const Heading = ({ children }: { children: JSX.Element | JSX.Element[] }) => (
  <HeadingDiv>
    <Row spaceBetween centerAlign style={{ height: '100%' }}>
      {children}
    </Row>
  </HeadingDiv>
);

const FilterForm = ({
  field,
  fieldOptions,
  recentFieldOptions,
  condition,
  conditionOptions,
  filterType,
  booleanOptions,
  textOptions,
  value,
  onSaveClicked,
  onNumberInputChanged,
  filter,
  onRemoveClicked,
  isLoadingTextOptions,
}: {
  field?: string;
  fieldOptions: DropdownOption[];
  recentFieldOptions: DropdownOption[];
  condition?: FilterBuilder.Condition;
  conditionOptions: DropdownOption[];
  filterType?: FilterBuilder.FilterType;
  booleanOptions: DropdownOption[];
  textOptions: DropdownOption[];
  value: string | number | string[] | boolean | undefined;
  onSaveClicked: () => void;
  onNumberInputChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
  filter?: FilterBuilder.Client.FieldFilter;
  onRemoveClicked?: () => void;
  isLoadingTextOptions: boolean;
}) => {
  if (field === undefined) {
    return (
      <ContentDiv>
        <Heading>
          <Typography.Header type="H5">If:</Typography.Header>
        </Heading>
        <SearchableListPicker
          options={fieldOptions}
          recentOptions={recentFieldOptions}
        />
      </ContentDiv>
    );
  }

  if (condition === undefined) {
    return (
      <ContentDiv>
        <Heading>
          <Typography.Header type="H5">{field}: </Typography.Header>
        </Heading>
        <SearchableListPicker options={conditionOptions} />
      </ContentDiv>
    );
  }

  if (filterType === 'text') {
    return (
      <ContentDiv>
        <Heading>
          <>
            <Typography.Header type="H5">{`${field} ${condition}:`}</Typography.Header>
            {!!onRemoveClicked && (
              <CloseButton close={onRemoveClicked} testId="remove-filter" />
            )}
          </>
        </Heading>
        <Block
          style={{
            minHeight: isLoadingTextOptions ? '250px' : 'unset',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <SearchableListPicker options={textOptions} />
          {isLoadingTextOptions && (
            <FlexCentered>
              <Loading />
            </FlexCentered>
          )}
        </Block>
        <Row style={{ justifyContent: 'flex-end', padding: 8 }}>
          <div>
            <Button
              label={filter ? 'Update' : 'Add'}
              onClick={onSaveClicked}
              type="Primary"
              size="Small"
            />
          </div>
        </Row>
      </ContentDiv>
    );
  }

  if (filterType === 'boolean') {
    return (
      <ContentDiv>
        <Heading>
          <>
            <Typography.Header type="H5">{`${field} ${condition}:`}</Typography.Header>
            {!!onRemoveClicked && (
              <CloseButton close={onRemoveClicked} testId="remove-filter" />
            )}
          </>
        </Heading>
        <Block>
          <SearchableListPicker options={booleanOptions} />
        </Block>
        <Row style={{ justifyContent: 'flex-end', padding: 8 }}>
          <div>
            <Button
              label={filter ? 'Update' : 'Add'}
              onClick={onSaveClicked}
              type="Primary"
              size="Small"
            />
          </div>
        </Row>
      </ContentDiv>
    );
  }

  if (filterType === 'number') {
    return (
      <ContentDiv>
        <Heading>
          <>
            <Typography.Header type="H5">{`${field} ${condition}`}</Typography.Header>
            {!!onRemoveClicked && (
              <CloseButton close={onRemoveClicked} testId="remove-filter" />
            )}
          </>
        </Heading>

        <Block>
          <Row centerAlign style={{ padding: 8 }}>
            <div style={{ marginRight: 8 }}>
              <Dropdown options={conditionOptions} unsetWidth />
            </div>
            <Inputs.TextInput
              data-testid="filter-value"
              placeholder="Enter a value"
              type="number"
              value={typeof value === 'number' ? value.toString(10) : ''}
              onChange={onNumberInputChanged}
            />
          </Row>
        </Block>
        <Row style={{ justifyContent: 'flex-end', padding: 8 }}>
          <div>
            <Button
              label={filter ? 'Update' : 'Add'}
              onClick={onSaveClicked}
              type="Primary"
              size="Small"
            />
          </div>
        </Row>
      </ContentDiv>
    );
  }

  return null;
};

export default FilterForm;
