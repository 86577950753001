import React, { useCallback, useContext, useEffect, useState } from 'react';
import WizardStep from 'components/WizardStep';
import PerformanceWizardContext from './PerformanceWizardContext';

const WizardStepContainer = ({
  step,
  number,
  text,
}: {
  step: PerformanceConfiguration.WizardStep;
  number: number;
  text: string;
}) => {
  const { wizardState, setWizardState } = useContext(PerformanceWizardContext);
  const getStepState = useCallback(
    ({
      step,
      wizardState,
    }: {
      step: PerformanceConfiguration.WizardStep;
      wizardState: PerformanceConfiguration.WizardState;
    }): WizardStepState => {
      if (wizardState.currentStep === step) {
        return 'active';
      }

      if (step === 'dataset set up') {
        if (wizardState.isDatasetStepComplete) {
          return 'complete';
        }
      } else if (step === 'metrics') {
        if (wizardState.isMetricsStepComplete) {
          return 'complete';
        }
      } else if (step === 'fields') {
        if (wizardState.isFieldsStepComplete) {
          return 'complete';
        }
      } else if (step === 'confirmation') {
        if (
          wizardState.isDatasetStepComplete &&
          wizardState.isMetricsStepComplete &&
          wizardState.isFieldsStepComplete
        ) {
          return 'complete';
        }
      }

      return 'un-complete';
    },
    [],
  );
  const [stepState, setStepState] = useState<WizardStepState>(() =>
    getStepState({ step, wizardState }),
  );
  useEffect(() => {
    setStepState(getStepState({ step, wizardState }));
  }, [getStepState, step, wizardState]);
  const onClick = useCallback(() => {
    setWizardState((s) => ({
      ...s,
      currentStep: step,
    }));
  }, [setWizardState, step]);

  return (
    <WizardStep
      label={text}
      number={number}
      stepState={stepState}
      onClick={onClick}
      testId={`wizard-go-to-${step}`}
    />
  );
};

export default WizardStepContainer;
