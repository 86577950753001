import React from 'react';
import Row from 'components/Common/Row';
import DragHandle from 'components/DragAndDropList/DragHandle';
import Button from 'kingpin/atoms/Button';
import Typography from 'kingpin/atoms/Typography';
import Column from 'screens/DataManager/DatasetDefinitions/Column';
import Colors2 from 'theme/Colors2';

const GroupButtonOverride =
  ({
    isAllOther,
    onEditClick,
    isEditing,
    group,
    onRemoveClicked,
    isDraft,
  }: {
    isAllOther: boolean;
    onEditClick: () => void;
    isEditing: boolean;
    group?: Costs.Group;
    onRemoveClicked: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    isDraft: boolean;
  }) =>
  ({ open }: { open: () => void }) => {
    if (!group) {
      return null;
    }

    const onClick = () => {
      if (isAllOther) {
        return;
      }

      onEditClick();
      open();
    };

    return (
      <Row
        spaceBetween
        centerAlign
        style={{
          border: `1px solid ${Colors2.Grey['8']}`,
          marginTop: '-1px',
          cursor: isAllOther ? 'default' : 'pointer',
        }}
        onClick={onClick}
      >
        <Column style={{ minHeight: 42 }}>
          <DragHandle testId={`${group.groupName}-drag`} />
          <Typography.Body type="Body 12">{group.groupName}</Typography.Body>
        </Column>
        {!isAllOther && (!isEditing || isDraft) && (
          <div style={{ marginRight: '8px' }}>
            <Button
              type="Ghost"
              size="Small"
              testId={`remove-${group.groupName}`}
              onClick={onRemoveClicked}
              icon={'cross'}
            />
          </div>
        )}
      </Row>
    );
  };

export default GroupButtonOverride;
