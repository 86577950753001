import React, { useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import Row from '../../../Common/Row';
import usePopup from '../../../../hooks/usePopup';
import TargetFormContext from '../../contexts/TargetFormContext';
import TargetsDragHandleContext from '../../contexts/TargetsDragHandleContext';
import GroupLabel from './GroupLabel';
import GroupInputStyles from './styles';
import DragHandle from './DragHandle';
import GroupNameInput from './GroupNameInput';
import AddTargetButton from './AddTargetButton';
import TargetInput from './TargetInput';
import FallbackTargetInput from './FallbackTargetInput';
import GroupContextMenu from './GroupContextMenu';
import Typography from 'kingpin/atoms/Typography';
import useEntityField from '../../hooks/useEntityField';
import useIsTargetValid from '../../hooks/useIsTargetValid';
import GroupsScrollingContext from '../../contexts/GroupsScrollingContext';
import Button from '../../../../kingpin/atoms/Button';

const DraggableTargetInput = ({
  group,
  target,
  index,
}: {
  group: Targets.Wizard.TargetGroup;
  target: Targets.Wizard.Target;
  index: number;
}) => (
  <Draggable
    draggableId={target.key}
    index={index}
    type={`${group.key}-targets`}
  >
    {(draggableProvider) => (
      <>
        <div
          ref={draggableProvider.innerRef}
          {...draggableProvider.draggableProps}
        >
          <TargetsDragHandleContext.Provider
            value={{
              isGroupBlock: false,
              dragHandleProps: draggableProvider.dragHandleProps
                ? draggableProvider.dragHandleProps
                : undefined,
            }}
          >
            <TargetInput group={group} target={target} key={target.key} />
          </TargetsDragHandleContext.Provider>
        </div>
      </>
    )}
  </Draggable>
);

const TargetInputs = ({ group }: { group: Targets.Wizard.TargetGroup }) => {
  const fallbackTarget = group.targets[group.targets.length - 1];
  const withoutFallbackTarget = group.targets.filter(
    (t) => t.key !== fallbackTarget.key,
  );

  return (
    <>
      <Droppable droppableId={group.key} type={`${group.key}-targets`}>
        {(provided) => (
          <div ref={provided.innerRef} style={{ overflow: 'hidden' }}>
            {withoutFallbackTarget.map((t, index) => (
              <DraggableTargetInput
                target={t}
                group={group}
                index={index}
                key={t.key}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </>
  );
};

const variants = {
  opened: {
    height: 'unset',
    opacity: 1,
  },
  closing: {
    height: 0,
    opacity: 0,
  },
  closed: {
    height: 0,
    opacity: 0,
  },
};

// Re-use the existing validation logic
const useIsGroupForcedOpen = (group: Targets.Wizard.TargetGroup): boolean => {
  const { isShowingValidationFeedback } = useContext(TargetFormContext);
  const [groups, setGroups] = useState<Targets.Wizard.TargetGroup[]>([group]);
  useEffect(() => {
    setGroups([group]);
  }, [group]);

  // Inject values for groupField, effectiveDate as we are not
  // interested in validation against those here
  const isValid = useIsTargetValid({
    groupField: '',
    groups,
    effectiveDate: '',
  });

  return !isValid && isShowingValidationFeedback;
};

const TallGroupInput = ({ group }: { group: Targets.Wizard.TargetGroup }) => {
  const fallbackTarget = group.targets[group.targets.length - 1];
  const { isOpen, open, close } = usePopup(true);
  const isForcedOpen = useIsGroupForcedOpen(group);
  const [isCloseComplete, setIsCloseComplete] = useState<boolean>(true);
  const { isTargetedByGroup, dataType } = useContext(TargetFormContext);
  const { determineIfIsScrolling } = useContext(GroupsScrollingContext);
  const entityField = useEntityField(dataType);

  useEffect(() => {
    return () => {
      determineIfIsScrolling();
    };
  }, [determineIfIsScrolling, isOpen, isCloseComplete]);

  useEffect(() => {
    if (isForcedOpen) {
      open();
    }
  }, [isForcedOpen, open]);

  useEffect(() => {
    if (isOpen) {
      setIsCloseComplete(false);
      return;
    }
    const t = setTimeout(() => {
      setIsCloseComplete(true);
    }, 300);

    return () => {
      clearTimeout(t);
    };
  }, [isOpen]);

  return (
    <>
      {isTargetedByGroup && (
        <>
          <Row>
            <Row style={{ height: 34 }} centerAlign>
              <DragHandle />
              <GroupLabel group={group} isFallbackGroup={false} />
            </Row>

            <div style={{ width: '100%' }}>
              <Row centerAlign spaceBetween>
                <Row centerAlign>
                  <GroupNameInput group={group} />
                </Row>
                <Row centerAlign>
                  <AddTargetButton group={group} onTargetAdded={open} />
                  <div style={{ marginRight: 4 }}>
                    <Button
                      type="Ghost"
                      size="Small"
                      isDisabled={isForcedOpen}
                      onClick={isOpen ? close : open}
                      icon={isOpen ? 'chevron-down' : 'chevron-right'}
                    />
                  </div>

                  <GroupContextMenu group={group} />
                </Row>
              </Row>
            </div>
          </Row>
        </>
      )}
      {!isTargetedByGroup && (
        <div style={{ marginLeft: 16, marginRight: 16 }}>
          <Row spaceBetween centerAlign>
            <Typography.Body type="Body 12">{`For all ${entityField}(s)`}</Typography.Body>
            <AddTargetButton group={group} />
          </Row>
        </div>
      )}

      <motion.div
        variants={variants}
        animate={isOpen ? 'opened' : isCloseComplete ? 'closed' : 'closing'}
        initial="opened"
        style={isCloseComplete && !isOpen ? { display: 'none' } : undefined}
        onAnimationComplete={determineIfIsScrolling}
      >
        <GroupInputStyles.Block
          style={{
            marginLeft: isTargetedByGroup ? 21 : 0,
            paddingTop: 16,
            paddingBottom: 0,
          }}
        >
          <TargetInputs group={group} />
          <div style={{ marginTop: 24 }}>
            <FallbackTargetInput group={group} target={fallbackTarget} />
          </div>
        </GroupInputStyles.Block>
      </motion.div>
    </>
  );
};

export default TallGroupInput;
