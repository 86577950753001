import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import Button from 'kingpin/atoms/Button';
import styled from 'styled-components';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import _ from 'lodash';

import Row from '../../Common/Row';
import V5GadgetFormContext from '../../../contexts/V5GadgetFormContext';
import MetricOptionsContext from '../../../contexts/MetricOptionsContext';
import Inputs from 'components/Inputs';
import Label from '../Label';
import toSentenceCase from '../../../services/toSentenceCase';
import Colors2 from '../../../theme/Colors2';
import Typography from 'kingpin/atoms/Typography';
import usePopup from '../../../hooks/usePopup';
import MetricPickerPopup from '../../Inputs/MetricPicker/MetricPickerPopup';
import AnalyticsContext from '../../../contexts/AnalyticsContext';
import SingleUseMetricBuilderPopup from '../../Inputs/MetricPicker/MetricPickerPopup/SingleUseMetricBuilderPopup';
import PermissionGates from '../../PermissionGates';
import PERMISSIONS from '../../../permissionDefinitions';

const OrderItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  background-color: white;
  align-items: center;
  margin-bottom: 8px;
  height: 32px;
  padding-left: 8px;
  border: 1px solid ${Colors2.Grey['7']};
`;

const ItemWrapper = styled.div`
  margin-bottom: 16px;
  background-color: ${Colors2.Grey['9']};
  padding: 12px;
`;

const compareSortOptions = [
  'delta asc',
  'delta desc',
  'abs delta asc',
  'abs delta desc',
  'percent delta asc',
  'percent delta desc',
  'abs percent delta asc',
  'abs percent delta desc',
];

const InputWrapper = styled.div`
  margin-bottom: 8px;
`;

const Item = ({
  metricIdsToConstrainBy,
  series,
  updateSeries,
  removeSeries,
  isMultipleYAxisChart,
  numSeries,
}: {
  metricIdsToConstrainBy: string[];
  series: V5ChartDefinitionSeries;
  updateSeries: (s: V5ChartDefinitionSeries) => void;
  removeSeries: (s: V5ChartDefinitionSeries) => void;
  isMultipleYAxisChart: boolean;
  numSeries: number;
}) => {
  const { draftChart } = useContext(V5GadgetFormContext);
  const isMatrix = draftChart && draftChart.gadgetType === 'matrix';
  const isSmartCard = draftChart && draftChart.gadgetType === 'smartCard';
  const { metricOptions } = useContext(MetricOptionsContext);
  const [displayName, setDisplayName] = useState<string>(() => {
    if (series.displayName) {
      return series.displayName;
    }
    const selected = metricOptions.find((m) => m.id === series.metricId);
    if (selected) {
      return selected.name;
    }
    return '';
  });

  const onPlotOnSeparateYAxisChange = (newValue: boolean) => {
    updateSeries({ ...series, plotOnSeparateAxis: newValue });
  };
  const onOppositeChange = (newValue: boolean) => {
    updateSeries({ ...series, opposite: newValue });
  };
  const onPlotAverageChange = (newValue: boolean) => {
    updateSeries({ ...series, plotAverage: newValue });
  };
  const onPlotTrendChange = (newValue: boolean) => {
    updateSeries({ ...series, plotTrend: newValue });
  };
  const onTypeChange = (newType: string) => {
    updateSeries({ ...series, type: newType });
  };
  const onSortByMetricIdChanged = (newValue: boolean) => {
    updateSeries({ ...series, sortByMetricId: newValue });
  };
  const onIsStatusFlagChanged = (newValue: boolean) => {
    updateSeries({ ...series, isStatusFlag: newValue });
  };
  const onMatrixCellTypeChanged = (newType?: MatrixCellType) => {
    updateSeries({ ...series, matrixCellType: newType });
  };
  const onMatrixCellSortChanged = (newType: MatrixCompareSortType) => {
    updateSeries({ ...series, sortByComparison: newType });
  };
  const onSmartCardComparison = (newType?: MatrixCellType) => {
    updateSeries({ ...series, smartCardComparison: newType });
  };
  useEffect(() => {
    if (!isMatrix && !!series.matrixCellType && draftChart !== undefined) {
      updateSeries({ ...series, matrixCellType: undefined });
    }
  }, [draftChart, isMatrix, series, updateSeries]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateDisplayName = useCallback(
    _.debounce((newName: string) => {
      updateSeries({ ...series, displayName: newName });
    }, 500),
    [series, updateSeries],
  );

  const onDisplayNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setDisplayName(event.target.value);
    updateDisplayName(event.target.value);
  };

  const onNewMetricSelected = useCallback(
    (newMetric: Metrics.Metric | undefined) => {
      if (!newMetric) {
        removeSeries(series);
        return;
      }

      const newS = {
        ...series,
        metricId: newMetric.id,
        displayName: newMetric.name,
      };

      setDisplayName(newMetric.name);
      updateSeries(newS);
    },
    [series, updateSeries, removeSeries],
  );

  return (
    <ItemWrapper>
      <Label>Metric</Label>
      <InputWrapper>
        <Row spaceBetween>
          <Inputs.MetricPicker
            setMetricId={window.tokenFunction}
            onMetricSelected={onNewMetricSelected}
            metricId={series.metricId}
            metricIdsToConstrainBy={
              numSeries === 1 ? window.emptyArray : metricIdsToConstrainBy
            }
          />
          <div>
            <Button
              onClick={() => removeSeries(series)}
              icon="cross"
              type="Secondary"
              size="Small"
            />
          </div>
        </Row>
      </InputWrapper>

      <InputWrapper>
        <Label>Display Name</Label>
        <Inputs.TextInput
          value={displayName}
          onChange={onDisplayNameChanged}
          inputSize="Small"
          width="100%"
        />
      </InputWrapper>
      <InputWrapper>
        <Label>Sort by</Label>
        <Inputs.Toggle
          value={!!series.sortByMetricId}
          onChange={onSortByMetricIdChanged}
        />
      </InputWrapper>

      {isMatrix && (
        <InputWrapper>
          <Label>Is status flag</Label>
          <Inputs.Toggle
            value={!!series.isStatusFlag}
            onChange={onIsStatusFlagChanged}
          />
        </InputWrapper>
      )}
      {isSmartCard && (
        <InputWrapper>
          <Label>Cell comparison</Label>
          <Inputs.Dropdown
            selectedLabel={series.smartCardComparison}
            options={['delta', 'percentDelta', 'both', 'none'].map((o) => ({
              label: o ? o : 'None',
              onSelected: () =>
                onSmartCardComparison(o as MatrixCellType | undefined),
            }))}
          />
        </InputWrapper>
      )}
      {isMultipleYAxisChart && (
        <React.Fragment>
          <InputWrapper>
            <Label>Separate Y axis</Label>
            <Inputs.Toggle
              value={!!series.plotOnSeparateAxis}
              onChange={onPlotOnSeparateYAxisChange}
            />
          </InputWrapper>

          <InputWrapper>
            <Label>Opposite</Label>
            <Inputs.Toggle
              value={!!series.opposite}
              onChange={onOppositeChange}
            />
          </InputWrapper>

          <InputWrapper>
            <Label>Plot average</Label>
            <Inputs.Toggle
              value={!!series.plotAverage}
              onChange={onPlotAverageChange}
            />
          </InputWrapper>

          <InputWrapper>
            <Label>Plot trend</Label>
            <Inputs.Toggle
              value={!!series.plotTrend}
              onChange={onPlotTrendChange}
            />
          </InputWrapper>

          <InputWrapper>
            <Label>Type</Label>
            <Inputs.Dropdown
              selectedLabel={series.type}
              options={['column', 'bar', 'line'].map((o) => ({
                label: toSentenceCase(o),
                onSelected: () => {
                  onTypeChange(o);
                },
              }))}
            />
          </InputWrapper>
        </React.Fragment>
      )}
      {isMatrix && (
        <React.Fragment>
          <InputWrapper>
            <Label>Cell comparison</Label>
            <Inputs.Dropdown
              selectedLabel={series.matrixCellType}
              options={['delta', 'percentDelta', 'both', undefined].map(
                (o) => ({
                  label: o ? o : 'None',
                  onSelected: () => {
                    onMatrixCellTypeChanged(o as MatrixCellType | undefined);
                  },
                }),
              )}
            />
          </InputWrapper>
          {series.matrixCellType && (
            <InputWrapper>
              <Label>Sort by comparison</Label>
              <Inputs.Dropdown
                selectedLabel={series.sortByComparison}
                options={[...compareSortOptions, undefined].map((o) => ({
                  label: o ? o : 'None',
                  onSelected: () => {
                    onMatrixCellSortChanged(o as MatrixCompareSortType);
                  },
                }))}
              />
            </InputWrapper>
          )}
        </React.Fragment>
      )}
    </ItemWrapper>
  );
};

const OrderItemName = ({ series }: { series: V5ChartDefinitionSeries }) => {
  const { metricOptions } = useContext(MetricOptionsContext);
  const label = (() => {
    if (series.displayName) {
      return series.displayName;
    }
    const selected = metricOptions.find((m) => m.id === series.metricId);
    if (selected) {
      return selected.name;
    }
    return '';
  })();

  return <Typography.Body type="Body 12">{label}</Typography.Body>;
};

const AddSeriesButton = ({
  onAddSeries,
  metricIdsToConstrainBy,
}: {
  onAddSeries: (metric: Metrics.Metric) => void;
  metricIdsToConstrainBy: string[];
}) => {
  const { isOpen, open, close } = usePopup();
  const {
    isOpen: isSingleUseOpen,
    open: openSingleUse,
    close: closeSingleUse,
  } = usePopup();
  const { trackEvent } = useContext(AnalyticsContext);

  const onClick = useCallback(() => {
    trackEvent('Gadget Builder - Add Metric Clicked');
    open();
  }, [open, trackEvent]);

  const onSingleUseClicked = useCallback(() => {
    trackEvent('Gadget builder - Create Single use metric clicked');
    openSingleUse();
  }, [openSingleUse, trackEvent]);

  const onSingleUseMetricSaved = useCallback(
    (metric: Metrics.Metric) => {
      trackEvent('Single use metric - Created');
      onAddSeries(metric);
      closeSingleUse();
    },
    [closeSingleUse, onAddSeries, trackEvent],
  );

  return (
    <>
      <Row>
        <div style={{ marginRight: 8 }}>
          <Button
            onClick={onClick}
            label="Add Metric"
            type="Primary"
            size="Small"
          />
        </div>
        <PermissionGates.Has
          requiredPermission={
            PERMISSIONS.DATA_MANAGEMENT.SINGLE_USE_METRICS_CONFIG
          }
        >
          <Button
            onClick={onSingleUseClicked}
            label="Create Single-use Metric"
            type="Tertiary"
            size="Small"
          />
        </PermissionGates.Has>
      </Row>
      <MetricPickerPopup
        isOpen={isOpen}
        close={close}
        setSelectedMetric={onAddSeries}
        metricIdsToConstrainBy={metricIdsToConstrainBy}
      />
      <SingleUseMetricBuilderPopup
        close={closeSingleUse}
        onSingleUseMetricSaved={onSingleUseMetricSaved}
        isOpen={isSingleUseOpen}
        cancelButtonText="Back to Gadget Settings"
      />
    </>
  );
};

const SeriesInput = ({
  isAddButtonVisible,
  series,
  onAddSeries,
  updateSeries,
  removeSeries,
  isMultipleYAxisChart,
  onDrop,
  metricIdsToConstrainBy,
}: {
  isAddButtonVisible: boolean;
  series: V5ChartDefinitionSeries[];
  onAddSeries: (metric: Metrics.Metric) => void;
  updateSeries: (s: V5ChartDefinitionSeries) => void;
  removeSeries: (s: V5ChartDefinitionSeries) => void;
  isMultipleYAxisChart: boolean;
  onDrop: (r: DropResult) => void;
  metricIdsToConstrainBy: string[];
}) => (
  <div>
    {series.length > 1 && (
      <ItemWrapper>
        <div style={{ marginBottom: 8 }}>
          <Typography.Body type="Label">Order</Typography.Body>
        </div>
        <DragDropContext onDragEnd={onDrop}>
          <Droppable droppableId={'seriesInput'}>
            {(provided) => (
              <div ref={provided.innerRef}>
                {series.map((s, index) => (
                  <Draggable draggableId={s.id} index={index} key={s.id}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <OrderItem>
                          <OrderItemName series={s} />
                        </OrderItem>
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </ItemWrapper>
    )}
    {series.map((s) => (
      <Item
        key={s.id}
        series={s}
        metricIdsToConstrainBy={metricIdsToConstrainBy}
        updateSeries={updateSeries}
        removeSeries={removeSeries}
        isMultipleYAxisChart={isMultipleYAxisChart}
        numSeries={series.length}
      />
    ))}
    {isAddButtonVisible && (
      <Row centerAlign>
        <AddSeriesButton
          onAddSeries={onAddSeries}
          metricIdsToConstrainBy={metricIdsToConstrainBy}
        />
      </Row>
    )}
  </div>
);

export default SeriesInput;
