import _ from 'lodash';

const toSentenceCase = (
  string: string,
  ignoreCamelCaseRequirement?: boolean,
) => {
  try {
    if (_.camelCase(string) !== string && !ignoreCamelCaseRequirement) {
      return string;
    }
    const withoutDots = string.replaceAll('.', ' ');

    const words = withoutDots.replace(/([A-Z])/g, ' $1');
    return words.charAt(0).toUpperCase() + words.slice(1);
  } catch (ex) {
    return string;
  }
};

export default toSentenceCase;
