import { Section } from '../MyAccount/Profile/styles';
import Divider from '../Common/Card/Divider';
import React from 'react';

const Group = ({
  title,
  children,
}: {
  title: string;
  children: JSX.Element | JSX.Element[];
}) => (
  <Section>
    <h3>{title}</h3>
    <Divider />
    {children}
  </Section>
);

export default Group;
