import React from 'react';
import styled from 'styled-components';
import Tooltip from '../Tooltip';

import Row from '../Common/Row';
import ImageCardEditForm from './ImageCardEditForm';
import ShowOnMouseOverUnlessTour from '../ShowOnMouseOverUnlessTour';
import StretchImg from './StretchImg';
import CenteredImg from './CenterImg';
import Button from 'kingpin/atoms/Button';

const Wrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  flex: 1;

  .showOnMouseOver {
    visibility: hidden;
  }

  &:hover {
    > .showOnMouseOver {
      visibility: visible;
    }
  }
`;

const ImageGadget = ({
  src,
  openEditForm,
  closeEditForm,
  isEditFormOpen,
  isEditing,
  imageLayout,
  card,
}: {
  src: string;
  openEditForm: () => void;
  closeEditForm: () => void;
  isEditFormOpen: boolean;
  isEditing: boolean;
  imageLayout: ImageLayout;
  card: CanvasCard.ImageCard;
}) => (
  <Wrapper>
    {!isEditing && (
      <ShowOnMouseOverUnlessTour>
        <Row
          style={{
            position: 'absolute',
            right: 13,
            top: 5,
            justifyContent: 'flex-end',
          }}
        >
          <Tooltip content={'Edit'}>
            <Button
              icon="edit-filled"
              onClick={openEditForm}
              type="Secondary"
              size="Small"
            />
          </Tooltip>
        </Row>
      </ShowOnMouseOverUnlessTour>
    )}
    {imageLayout === 'stretch' && (
      <StretchImg src={src} isEditing={isEditing} />
    )}
    {imageLayout === 'center' && (
      <CenteredImg src={src} isEditing={isEditing} />
    )}
    {isEditFormOpen && (
      <ImageCardEditForm
        close={closeEditForm}
        isOpen={isEditFormOpen}
        card={card}
      />
    )}
  </Wrapper>
);

export default ImageGadget;
