import React from 'react';
import moment from 'moment';
import Row from 'components/Common/Row';
import Loading from 'components/Loading';
import Typography from 'kingpin/atoms/Typography';
import userTypeCheckers from 'contextProviders/UsersProvider/userTypeCheckers';
import useUser from 'hooks/useUser';
import Colors2 from 'theme/Colors2';

const PreviousUploadItem = ({
  previousUpload,
  isLast,
}: {
  previousUpload: DataManager.PreviousUpload;
  isLast: boolean;
}) => {
  const { user, isLoading } = useUser(previousUpload.uploadedBy);

  if (user && userTypeCheckers.isWallboardUser(user)) {
    return null;
  }

  return (
    <Row
      style={{
        borderBottom: isLast ? undefined : `1px solid ${Colors2.Grey['8']}`,
        padding: '12px 16px',
        justifyContent: 'space-between',
        height: '44px',
      }}
      centerAlign
    >
      <Row centerAlign>
        {isLoading && <Loading isSmall />}
        {!isLoading && user && (
          <div
            style={{
              marginRight: '24px',
              width: '148px',
              display: 'flex',
            }}
          >
            <Typography.Body isEllipsis type="Body 12">
              {user.displayName}
            </Typography.Body>
          </div>
        )}

        <div
          style={{
            color: Colors2.Grey['3'],
            width: '116px',
            display: 'flex',
          }}
        >
          <Typography.Body isEllipsis type="Body 12">
            {previousUpload.fileName}
          </Typography.Body>
        </div>
      </Row>
      <div style={{ display: 'flex' }}>
        <Typography.Body type="Body 12">
          {moment(previousUpload.uploadedOn).format('MMM DD ‘YY HH:mm')}
        </Typography.Body>
      </div>
    </Row>
  );
};

export default PreviousUploadItem;
