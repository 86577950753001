import React, { useCallback, useContext, useState } from 'react';
import ReportContextMenu from './ReportContextMenu';
import usePopup from '../../../hooks/usePopup';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import ReportForm from '../../../components/ReportForm';
import ReportContext from '../../../contexts/ReportContext';
import deleteReport from '../../../api/deleteReport';
import ConfirmationModal from '../../../components/ConfirmationModal';
import AnalyticsContext from '../../../contexts/AnalyticsContext';
import AccountPickerContext from '../../../contexts/AccountPickerContext';

const ReportContextMenuContainer = ({
  report,
}: {
  report: PersistedReportType;
}) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const { accountRef } = useContext(AccountPickerContext);

  const {
    isOpen: isSettingsOpen,
    open: openSettings,
    close: closeSettings,
  } = usePopup();
  const {
    isOpen: isDeleteConfirmOpen,
    open: openDeleteConfirm,
    close: closeDeleteConfirm,
  } = usePopup();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSettingClicked = () => {
    if (isLoading) {
      return;
    }
    openSettings();
  };

  const onDeleteClicked = useCallback(() => {
    if (isLoading) {
      return;
    }
    openDeleteConfirm();
  }, [isLoading, openDeleteConfirm]);

  const onDeleteConfirmed = useCallback(() => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    deleteReport(report.id, accountRef).then(() => {
      trackEvent('Report - Deleted');
      setIsLoading(false);
      closeDeleteConfirm();
    });
  }, [accountRef, closeDeleteConfirm, isLoading, report.id, trackEvent]);

  return (
    <React.Fragment>
      <ReportContextMenu
        report={report}
        onSettingClicked={onSettingClicked}
        onDeleteClicked={onDeleteClicked}
      />
      <ConfirmationModal
        isOpen={isDeleteConfirmOpen}
        close={closeDeleteConfirm}
        title={'Delete Report?'}
        body={`Are you sure you want to delete the ${report.name} report?`}
        confirmText={'Delete'}
        onConfirmed={onDeleteConfirmed}
      />
      <ReportContext.Provider
        value={{
          report,
          currentScope: report.scope,
          currentDrillDowns: report.drillDowns ? report.drillDowns : [],
          reset: () => {},
        }}
      >
        <ModalTransition>
          {isSettingsOpen && (
            <Modal>
              <ReportForm isOpen={isSettingsOpen} close={closeSettings} />
            </Modal>
          )}
        </ModalTransition>
      </ReportContext.Provider>
    </React.Fragment>
  );
};

export default ReportContextMenuContainer;
