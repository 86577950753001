import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import formatFloat from '../../../api/getChartSeries/formatFloat';
import runDateHistogramAggregationQuery from './runDateHistogramAggregationQuery';
import migrateRelativeDateRange from '../../../migrateRelativeDateRange';
import relativeDateRangeToDateRange from '../../../relativeDateRangeToDateRange';

export const buildDateHistogramAggregationQuery = ({
  metrics,
  expression,
  filters,
  toMetricInput,
  dateScope,
  dateInterval,
  groupBy,
  comparison,
}: {
  metrics: MetricInput[];
  expression?: Expression2;
  filters: FilterInput[];
  toMetricInput: (metric: MetricInput | Metrics.NormalMetric) => MetricInput;
  dateScope: DateRangeInput;
  dateInterval: FleetOps.Interval;
  groupByField: string;
  comparison: GqlComparison;
  groupBy: V5GroupBy;
}) => {
  return {
    query: gql`
      query dateHistogramAggregation(
        $metrics: [MetricInput]!
        $expression: Expression2
        $filters: [FilterInput]!
        $dateScope: [DateRangeInput!]!
        $dateInterval: Interval!
        $groupBy: GroupBy
        $comparison: Comparison
      ) {
        dateHistogramAggregation(
          metrics: $metrics
          expression: $expression
          filters: $filters
          dateScope: $dateScope
          groupBy: $groupBy
          dateInterval: $dateInterval
          comparison: $comparison
        )
      }
    `,
    variables: {
      metrics: metrics.map(toMetricInput),
      filters: filters,
      dateScope,
      expression,
      dateInterval,
      groupBy,
      comparison,
    },
  };
};

const toGqlComparison = (
  comparison: PersistedComparisonType,
  weekStartsOn: WeekStartsOn,
  filters: FilterInput[],
  dateScope: DateRangeInput,
): GqlComparison | undefined => {
  if (comparison.relativeDateOption) {
    const { startDate: dateScopeStartDate } = dateScope;
    const { startDate, endDate } = relativeDateRangeToDateRange({
      relativeDateRange: migrateRelativeDateRange(
        comparison.relativeDateOption,
      ),
      startOfWeek: weekStartsOn,
      comparedTo: dateScopeStartDate,
    });
    return {
      compareType: comparison.compareType,
      startDate,
      endDate,
    };
  } else {
    return undefined;
  }
};

const refineResponse = ({
  response,
  gadget,
  comparisonDateRange,
}: {
  response: SingleMetricDateMatrixResponseRaw;
  gadget: VisualisationDefinitions.SingleMetricDateMatrix;
  comparisonDateRange: { startDate: string; endDate: string };
}): SingleMetricDateMatrixResponse => {
  const { groupByField, metricId } = gadget;
  const buildPoint = (p: HistogramPointRaw): HistogramPoint => {
    const date = p['date'];
    const value = p[metricId];
    const comparison = p[`${metricId}_comparison`] as number | null;
    const comparisonDelta = p[`${metricId}_delta`] as number | null;
    const interval = p['interval'];
    const valueDateRange = {
      startDate: p['date'],
      endDate: p['endDate'],
    };
    const comparisonDeltaPercent = comparisonDelta
      ? formatFloat((comparisonDelta / value) * 100, 2)
      : null;

    return {
      date,
      value,
      valueDateRange,
      comparison,
      comparisonDelta,
      comparisonDeltaPercent,
      comparisonDateRange,
      interval,
    };
  };

  return {
    histogram: response.histogram ? response.histogram.map(buildPoint) : [],
    terms: response.terms
      ? response.terms.map((r) => {
          const groupTotal = r[metricId] as number;
          const group = r[groupByField] as string;
          return {
            group,
            groupTotal,
            histogram: r.histogram.map(buildPoint),
          };
        })
      : [],
    // We can find more terms than totalTerms in the case
    // where the terms includes a "Blank" term
    isFullSet: response.terms
      ? response.terms.length >= response.totalTerms
      : true,
    totalTerms: response.totalTerms,
  };
};

const buildGroupBy = (
  gadget: VisualisationDefinitions.SingleMetricDateMatrix,
  limitOverride?: number,
): V5GroupBy => {
  return {
    field: gadget.groupByField,
    type: 'text',
    limit: limitOverride ? limitOverride : gadget.limit,
    sortByMetric:
      gadget.serverSortBy === 'metric (asc)' ||
      gadget.serverSortBy === 'metric (desc)'
        ? gadget.metricId
        : undefined,
    sort:
      gadget.serverSortBy === 'metric (asc)' ||
      gadget.serverSortBy === 'alpha (asc)'
        ? 'asc'
        : 'desc',
  };
};

const dateHistogramAggregation = async ({
  metrics,
  expression,
  filterInput,
  toMetricInput,
  dateScope,
  groupByField,
  dateInterval,
  comparison,
  gadget,
  weekStartsOn,
  limitOverride,
  client,
}: {
  metrics: Metrics.NormalMetric[];
  expression?: Expression2;
  filterInput: FilterInput[];
  toMetricInput: (metric: Metrics.NormalMetric | MetricInput) => MetricInput;
  dateScope: DateRangeInput;
  groupByField: string;
  dateInterval: FleetOps.Interval;
  comparison: PersistedComparisonType;
  gadget: VisualisationDefinitions.SingleMetricDateMatrix;
  weekStartsOn: WeekStartsOn;
  limitOverride?: number;
  client: ApolloClient<NormalizedCacheObject>;
}) => {
  const gqlComparison = toGqlComparison(
    comparison,
    weekStartsOn,
    filterInput,
    dateScope,
  );
  if (!gqlComparison || !gqlComparison.startDate || !gqlComparison.endDate) {
    return {
      histogram: [],
      terms: [],
      isFullSet: true,
      totalTerms: 0,
    };
  }

  const query = buildDateHistogramAggregationQuery({
    metrics: metrics.map(toMetricInput),
    expression,
    filters: filterInput,
    toMetricInput,
    dateScope,
    groupByField,
    dateInterval,
    groupBy: buildGroupBy(gadget, limitOverride),
    comparison: gqlComparison,
  });

  const response = await runDateHistogramAggregationQuery(query, client);
  const rawResponse = response.data
    .dateHistogramAggregation as SingleMetricDateMatrixResponseRaw;

  return refineResponse({
    response: rawResponse,
    gadget,
    comparisonDateRange: {
      startDate: gqlComparison.startDate,
      endDate: gqlComparison.endDate,
    },
  });
};

export default dateHistogramAggregation;
