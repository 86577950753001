import firebase from 'firebase/compat/app';
import STORE_CONSTANTS from '../../store/constants';

const deleteReasonCode = async (
  reasonCode: ReasonCode,
  accountRef: firebase.firestore.DocumentReference,
) => {
  await accountRef
    .collection(STORE_CONSTANTS.REASON_CODES_COLLECTION)
    .doc(reasonCode.id)
    .delete();
  return reasonCode;
};

export default deleteReasonCode;
