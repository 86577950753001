import { gql } from '@apollo/client';
import { IPaceCalculateForExpressionArgs, IPaceHistogramTerm } from '../types';

const calculateTotalsPaceForExpression = async ({
  client,
  metrics,
  expression,
  dateScope,
  filters,
  groupBy,
  comparisonPeriod,
}: IPaceCalculateForExpressionArgs): Promise<IPaceHistogramTerm> => {
  const query = {
    query: gql`
      query calculatePaceForExpression(
        $expression: Expression2
        $metrics: [MetricInput!]!
        $dateScope: [DateRangeInput!]!
        $filters: [FilterInput]!
        $comparisonPeriod: ComparisonInput
      ) {
        calculatePaceForExpression(
          expression: $expression
          metrics: $metrics
          dateScope: $dateScope
          filters: $filters
          comparisonPeriod: $comparisonPeriod
        ) {
          name
          average
          actual
          actualDiff
          pace
          paceDiff
          goal
          histogram {
            date
            average
            actual
            actualDiff
          }
        }
      }
    `,
    variables: {
      metrics,
      expression,
      dateScope,
      filters,
      groupBy,
      comparisonPeriod,
    },
  };

  const response = await client.query<{
    calculatePaceForExpression: IPaceHistogramTerm[];
  }>(query);
  return response.data.calculatePaceForExpression[0];
};

export default calculateTotalsPaceForExpression;
