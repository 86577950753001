import React from 'react';
import { DropResult } from 'react-beautiful-dnd';

interface TargetFormContextType {
  editingTarget?: Targets.Wizard.DataTypeTarget;

  dataType: string;
  targetField: string;
  effectiveDate?: string;
  groupField?: string;
  groups: Targets.Wizard.TargetGroup[];
  outputs: Targets.Wizard.OutputField[];
  setOutputs: React.Dispatch<
    React.SetStateAction<Targets.Wizard.OutputField[]>
  >;
  isGridMode: boolean;
  isTargetedByGroup: boolean;
  setGroups: React.Dispatch<React.SetStateAction<Targets.Wizard.TargetGroup[]>>;
  onDragEnd: (dr: DropResult) => void;
  isValid: boolean;
  isSaving: boolean;
  onSaveClickedConfirmed: () => void;
  isCopyingFromTarget: boolean;
  isDuplicateRuleAvailable: boolean;
  groupNameValues: string[];
  isEditing: boolean;
  nextGroupColorIndex: number;
  setNextGroupColorIndex: React.Dispatch<React.SetStateAction<number>>;
  isShowingValidationFeedback: boolean;
  setIsShowingValidationFeedback: React.Dispatch<React.SetStateAction<boolean>>;
  isBelowTargetDesirable: boolean;
  setIsBelowTargetDesirable: React.Dispatch<React.SetStateAction<boolean>>;
  isTrackingFullPeriod: boolean;
  setIsTrackingFullPeriod: React.Dispatch<React.SetStateAction<boolean>>;
}

const TargetFormContext = React.createContext<TargetFormContextType>({
  dataType: '',
  targetField: '',
  groups: [],
  outputs: [],
  setOutputs: () => {
    const e = new Error();
    e.name = 'setOutputs is not defined';
    throw e;
  },
  isGridMode: false,
  isTargetedByGroup: false,
  setGroups: () => {
    const e = new Error();
    e.name = 'setGroups is not defined';
    throw e;
  },
  onDragEnd: () => {
    const e = new Error();
    e.name = 'onDragEnd is not defined';
    throw e;
  },
  isValid: false,
  isSaving: false,
  onSaveClickedConfirmed: () => {
    const e = new Error();
    e.name = 'onSaveClickedConfirmed is not defined';
    throw e;
  },
  isCopyingFromTarget: false,
  isDuplicateRuleAvailable: false,
  groupNameValues: [],
  isEditing: false,
  nextGroupColorIndex: 0,
  setNextGroupColorIndex: () => {
    const e = new Error();
    e.name = 'setNextGroupColorIndex is not defined';
    throw e;
  },
  isShowingValidationFeedback: false,
  setIsShowingValidationFeedback: () => {
    const e = new Error();
    e.name = 'setIsShowingValidationFeedback is not defined';
    throw e;
  },
  isTrackingFullPeriod: false,
  setIsTrackingFullPeriod: () => {
    const e = new Error();
    e.name = 'setIsFullPeriod is not defined';
    throw e;
  },
  isBelowTargetDesirable: false,
  setIsBelowTargetDesirable: () => {
    const e = new Error();
    e.name = 'setIsBelowTargetDesirable is not defined';
    throw e;
  },
});

export default TargetFormContext;
