import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

const setIntervalCosts = async ({
  costModelId,
  intervalCosts,
  client,
}: {
  costModelId: string;
  intervalCosts: Costs.IntervalCostsInput[];
  client: ApolloClient<NormalizedCacheObject>;
}): Promise<boolean> => {
  const mutation = {
    mutation: gql`
      mutation setIntervalCosts(
        $costModelId: ID!
        $intervalCosts: [CostsForIntervalInput!]!
      ) {
        setIntervalCosts(
          costModelId: $costModelId
          intervalCosts: $intervalCosts
        )
      }
    `,
    variables: {
      costModelId,
      intervalCosts,
    },
  };

  const response = await client.mutate(mutation);
  return response.data.setIntervalCosts;
};

export default setIntervalCosts;
