import firebase from 'firebase/compat/app';
import STORE_CONSTANTS from './constants';

const datasetConverter = {
  toFirestore(
    dataset: FleetOps.DatasetDefinition,
  ): firebase.firestore.DocumentData {
    throw new Error(
      `The client should never write to datasets directly (${dataset.type})`,
    );
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ): FleetOps.DatasetDefinition {
    const { version, type, fields, isPerformance, isTargetable, name } =
      snapshot.data(options);
    const dataset = {
      version,
      type,
      fields,
      isPerformance,
      isTargetable,
      name,
    } as FleetOps.DatasetDefinition;

    return dataset;
  },
};

const getDatasetsRef = ({
  accountId,
  db,
}: {
  accountId: string;
  db?: firebase.firestore.Firestore;
}) => {
  return (db ? db : firebase.firestore())
    .collection('accounts')
    .doc(accountId)
    .collection(STORE_CONSTANTS.DATASETS_COLLECTION)
    .where('version', '==', 1)
    .withConverter(datasetConverter);
};

export default getDatasetsRef;
