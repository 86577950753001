import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import AddMetricButton from './AddMetricButton';
import aguid from 'aguid';
import createSavedFilter from '../../../../api/createSavedFilter';
import usePopup from '../../../../hooks/usePopup';
import VariableFiltersContext from '../../../../contexts/VariableFiltersContext';
import useMetric from '../../../../hooks/useMetric';
import MetricOptionsContext from '../../../../contexts/MetricOptionsContext';
import isDefined from '../../../../isDefined';
import AccountPickerContext from '../../../../contexts/AccountPickerContext';
import ReportDrillDownsProvider from '../../../../contextProviders/ReportDrillDownsProvider';
import metricTypeCheckers from '../../../../types/metricTypeCheckers';
import EntityDetailsContext from '../../../../screens/EntityDetailsShow/EntityDetailsContext';

const AddMetricButtonContainer = ({
  onAddMetricItem,
}: {
  onAddMetricItem: (newMetricItem: MetricListItemType) => void;
}) => {
  const { accountRef } = useContext(AccountPickerContext);

  const { isOpen, open, close } = usePopup();
  const [reportDrillDownId, setReportDrillDownId] = useState<
    string | undefined
  >(undefined);
  const [boardDrillDownId, setBoardDrillDownId] = useState<string | undefined>(
    undefined,
  );
  const [name, setName] = useState<string>('');
  const [metricId, setMetricId] = useState<string | undefined>('');
  const metric = useMetric(metricId);
  const { variableFilters } = useContext(VariableFiltersContext);
  const [drillDowns, setDrillDowns] = useState<FilterPlate[]>([]);
  const [scope, setScope] = useState<FilterPlate[]>([]);
  const { normalMetrics } = useContext(MetricOptionsContext);
  const entityDetailsContext = useContext(EntityDetailsContext);

  const createNewMetricItem = async () => {
    if (metricId && name !== '') {
      const savedFilterId = aguid();
      await createSavedFilter(
        {
          version: '2',
          id: savedFilterId,
          scope,
          drillDowns,
          source: 'metric-list-item',
          dateField: 'date',
        },
        accountRef,
      );
      const newItem: MetricListItemType = {
        id: aguid(),
        name,
        metricId,
        savedFilterId,
        reportDrillDownId,
        boardDrillDownId,
        isEntityFilterEnabled: !!entityDetailsContext,
      };
      onAddMetricItem(newItem);
      close();
      setMetricId(undefined);
      setName('');
      setDrillDowns([]);
      setScope([]);
      setBoardDrillDownId(undefined);
      setReportDrillDownId(undefined);
    }
  };

  const onNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  useEffect(() => {
    if (!metricId) {
      return;
    }

    if (!metric) {
      return;
    }

    setName(metric.name);
  }, [metric, metricId]);

  const dataSources = (() => {
    if (!metricId) {
      return [];
    }
    if (!metric) {
      return [];
    }
    const sources = [] as string[];
    if (metricTypeCheckers.isCompoundMetric(metric)) {
      const baseMetrics = metric.metricIds
        .map((mId) => {
          return normalMetrics.find((m) => m.id === mId);
        })
        .filter(isDefined);

      baseMetrics.forEach((m) => {
        sources.push(m.dataType);
      });
    } else if (metricTypeCheckers.isSpecialMetric(metric)) {
      sources.push(metric.dataType);
    } else {
      sources.push(metric.dataType);
    }

    return sources;
  })();

  return (
    <ReportDrillDownsProvider
      drillDowns={drillDowns}
      setDrillDowns={setDrillDowns}
      variableDrillDowns={variableFilters}
      scope={scope}
      setScope={setScope}
      dataTypes={dataSources}
    >
      <AddMetricButton
        isOpen={isOpen}
        open={open}
        close={close}
        name={name}
        onNameChanged={onNameChanged}
        createNewMetricItem={createNewMetricItem}
        metricId={metricId}
        setMetricId={setMetricId}
        reportDrillDownId={reportDrillDownId}
        setReportDrillDownId={setReportDrillDownId}
        boardDrillDownId={boardDrillDownId}
        setBoardDrillDownId={setBoardDrillDownId}
      />
    </ReportDrillDownsProvider>
  );
};

export default AddMetricButtonContainer;
