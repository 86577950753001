import React from 'react';

interface ComparisonContextType {
  currentComparison?: PersistedComparisonType;
  setCurrentComparison?: React.Dispatch<
    React.SetStateAction<PersistedComparisonType | undefined>
  >;
}

const ComparisonContext = React.createContext<ComparisonContextType>(
  {} as ComparisonContextType,
);

export default ComparisonContext;
