import React, { useCallback, useState } from 'react';
import usePopup from '../hooks/usePopup';
import DashboardContextMenuContext from 'contexts/DashboardContextMenuContext';
import DashboardContextMenu from '../components/DashboardContextMenu';

const DashboardContextMenuProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [metricIds, setMetricIds] = useState<string[]>([]);
  const [selectedMetric, setSelectedMetric] = useState<
    Metrics.NormalMetric | Metrics.SpecialMetric | undefined
  >();
  const [drillDown, setDrillDown] = useState<ReportDrillDownType | undefined>();
  const { isOpen, open, close } = usePopup();

  const openMenu = useCallback(
    (mIds: string[], drill: ReportDrillDownType) => {
      setMetricIds(mIds);
      setDrillDown(drill);
      open();
    },
    [open],
  );

  const closeMenu = useCallback(() => {
    setMetricIds([]);
    setSelectedMetric(undefined);
    setDrillDown(undefined);
    close();
  }, [close]);

  return (
    <DashboardContextMenuContext.Provider
      value={{
        isOpen,
        drillDown,
        openMenu,
        closeMenu,
        metricIds,
        selectedMetric,
        setSelectedMetric,
      }}
    >
      <React.Fragment>
        {children}
        <DashboardContextMenu />
      </React.Fragment>
    </DashboardContextMenuContext.Provider>
  );
};

export default DashboardContextMenuProvider;
