import { useCallback, useContext, useEffect, useState } from 'react';
import relativeDateRangeToDateRange from '../../../relativeDateRangeToDateRange';
import AccountContext from '../../../contexts/AccountContext';
import { LAST_13_WEEKS } from '../../DateInput/constants';
import TargetFormContext from '../contexts/TargetFormContext';
import useFilterInputForGroup from './useFilterInputForGroup';
import aggregateMetric from '../../../api/aggregateMetric';
import GqlClientContext from '../../../contexts/GqlClientContext';
import useValueFormatters from '../../../hooks/useValueFormatters';
import useToMetricInput from 'hooks/useToMetricInput';

const useAvgValueForGroup = ({
  group,
}: {
  group: Targets.Wizard.TargetGroup;
}) => {
  const { client } = useContext(GqlClientContext);
  const { weekStartsOn } = useContext(AccountContext);
  const { formatField } = useValueFormatters();
  const filterInput = useFilterInputForGroup({ group });
  const toMetricInput = useToMetricInput();
  const { dataType, targetField } = useContext(TargetFormContext);
  const [avgValue, setAvgValue] = useState<string | number | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [dateScope] = useState(
    relativeDateRangeToDateRange({
      relativeDateRange: LAST_13_WEEKS,
      startOfWeek: weekStartsOn,
    }),
  );
  const [avgValueTooltip, setAvgValueTooltip] = useState<string | undefined>();

  const getAvg = useCallback((): Promise<number | undefined> => {
    if (!filterInput) {
      return Promise.resolve(undefined);
    }

    return aggregateMetric(
      {
        id: 'na',
        field: targetField,
        dataType,
        aggFunc: 'avg',
        filters: {},
      },
      [filterInput],
      dateScope,
      toMetricInput,
      client,
    ).then((r) => {
      return r.length > 0 ? r[0]['na'] : undefined;
    });
  }, [client, dataType, dateScope, filterInput, targetField, toMetricInput]);

  useEffect(() => {
    let isActive = true;
    setIsLoading(true);
    getAvg().then((newAvgValue) => {
      if (isActive) {
        const formatted = formatField({
          value: newAvgValue,
          field: targetField,
          dataset: dataType,
        });
        setAvgValue(formatted);
        setIsLoading(false);
      }
    });

    return () => {
      isActive = false;
    };
  }, [dataType, formatField, getAvg, targetField]);

  useEffect(() => {
    if (avgValue === undefined) {
      setAvgValueTooltip(undefined);
      return;
    }

    setAvgValueTooltip(`Avg Last 13 Weeks: ${avgValue}`);
  }, [avgValue]);

  return { avgValue, isLoading, avgValueTooltip };
};

export default useAvgValueForGroup;
