import firebase from 'firebase/compat/app';
import STORE_CONSTANTS from '../constants';
import withoutNulls from '../../api/search/withoutNulls';

export const GOALS_DOC = 'goals';
export const CURRENT_GOALS_VERSION = 'v1';

const goalConverter = {
  toFirestore(goal: GeneralGoal): firebase.firestore.DocumentData {
    return withoutNulls({ ...goal });
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ): GeneralGoal {
    const data = snapshot.data(options);
    return data as GeneralGoal;
  },
};

const getGoalsRef = ({
  accountId,
  db,
}: {
  accountId: string;
  db?: firebase.firestore.Firestore;
}) => {
  return (db ? db : firebase.firestore())
    .collection('accounts')
    .doc(accountId)
    .collection(STORE_CONSTANTS.VISUALISATIONS_COLLECTION)
    .doc(GOALS_DOC)
    .collection(CURRENT_GOALS_VERSION)
    .withConverter(goalConverter);
};

export default getGoalsRef;
