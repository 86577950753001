// List of available roles, to be used in user management
import { ApisauceInstance } from 'apisauce';
import { useEffect, useState } from 'react';
import PERMISSIONS from '../../permissionDefinitions';

const useAvailableRoles = ({
  api,
  currentPermissions,
}: {
  api: ApisauceInstance | undefined;
  currentPermissions: FleetOps.Permission[];
}) => {
  const [roles, setRoles] = useState<FleetOps.RoleDefinition[]>([]);

  useEffect(() => {
    if (!api) {
      return;
    }

    if (!currentPermissions.includes(PERMISSIONS.USER_MANAGEMENT.MODIFY_USER)) {
      setRoles([]);
      return;
    }

    api
      .get<{
        [roleId: string]: {
          label: string;
          permissions: string[];
          visible: boolean;
        };
      }>('/roles/')
      .then((response) => {
        if (response.ok && !!response.data) {
          const newRoles = [] as FleetOps.RoleDefinition[];
          Object.entries(response.data).forEach(
            ([roleId, { label, permissions, visible }]) => {
              newRoles.push({
                label,
                visible,
                permissions,
                id: roleId as FleetOps.Role,
              });
            },
          );
          setRoles(newRoles.filter((role) => role.visible));
        }
      });
  }, [api, currentPermissions]);

  return roles;
};

export default useAvailableRoles;
