import React from 'react';
import styled, { css } from 'styled-components';
import Colors2 from '../theme/Colors2';
import Row from './Common/Row';
import Typography from 'kingpin/atoms/Typography';

const StepNumberActiveCss = css`
  background-color: ${Colors2.Primary['1']};
`;
const StepNumberUnCompleteCss = css`
  border: 1px solid #dcdcdc;
  background-color: white;
`;
const StepNumberCompleteCss = css`
  background-color: #ffebee;
`;

const StepNumberDiv = styled.div<{
  stepState: WizardStepState;
}>`
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;

  ${(props) => {
    if (props.stepState === 'active') {
      return StepNumberActiveCss;
    } else if (props.stepState === 'un-complete') {
      return StepNumberUnCompleteCss;
    } else if (props.stepState === 'complete') {
      return StepNumberCompleteCss;
    }
  }}
`;

const StepDiv = styled.div<{ onClick?: () => void }>`
  cursor: ${(props) => (props.onClick ? 'pointer' : 'not-allowed')};
  &:hover {
    opacity: ${(props) => (props.onClick ? '0.7' : '1')};
  }
`;

const WizardStep = ({
  label,
  number,
  stepState,
  onClick,
  testId,
}: {
  label: string;
  number: number;
  stepState: WizardStepState;
  onClick: () => void;
  testId?: string;
}) => {
  return (
    <div style={{ marginRight: 32 }}>
      <StepDiv
        onClick={stepState === 'complete' ? onClick : undefined}
        data-testid={testId}
      >
        <Row centerAlign>
          <StepNumberDiv stepState={stepState}>
            <Typography.Body
              type="Body 12"
              color={stepState === 'active' ? 'white' : undefined}
            >
              {number}
            </Typography.Body>
          </StepNumberDiv>
          <Typography.Body type="Body 12">{label}</Typography.Body>
        </Row>
      </StepDiv>
    </div>
  );
};

export default WizardStep;
