import React from 'react';

interface CardContextType {
  i: string;
  w: number;
  h: number;
  chartDefinition?: VisualisationDefinition;
  isForConfigureDashboardGadgetPopup?: boolean;
}

const CardContext = React.createContext<CardContextType>({
  isForConfigureDashboardGadgetPopup: false,
} as CardContextType);

export default CardContext;
