import React, { useCallback, useContext } from 'react';
import ScorecardContext from '../../../../contexts/ScorecardContext';
import useMetric from '../../../../hooks/useMetric';
import useKpiTrend from '../useKpiTrend';
import KpiRowRight from './KpiRowRight';

const KpiRowRightContainer = ({
  kpi,
  isLastRow,
}: {
  kpi: Scorecards.ScorecardKpi;
  isLastRow?: boolean;
}) => {
  const { selectedPeriods, setHoveredKpiId, hoveredKpiId } =
    useContext(ScorecardContext);
  const trend = useKpiTrend(kpi);
  const metric = useMetric(kpi.metricId);

  const onRowHover = useCallback(() => {
    setHoveredKpiId(kpi.id);
  }, [kpi.id, setHoveredKpiId]);

  return (
    <KpiRowRight
      periods={selectedPeriods}
      kpi={kpi}
      metric={metric}
      isLastRow={isLastRow}
      trend={trend}
      onRowHover={onRowHover}
      isHovered={hoveredKpiId === kpi.id}
    />
  );
};

export default KpiRowRightContainer;
