import React, { useCallback, useContext } from 'react';
import { ModalTransition } from '@atlaskit/modal-dialog';
import AnalyticsContext from 'contexts/AnalyticsContext';
import BoardContext from 'contexts/BoardContext';
import FileUploadModal from 'screens/DataManager/DatasetDefinitions/DatasetsIndex/FileUploads/FileUploadModal';
import isDriverPerformanceBoard from 'isDriverPerformanceBoard';

const UploadData = ({
  isOpen,
  close,
  dataType,
}: {
  isOpen: boolean;
  close: () => void;
  dataType: DataManager.UploadDataType | undefined;
}) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const { board } = useContext(BoardContext);

  const onUploadSuccess = useCallback(() => {
    if (!dataType) {
      return;
    }

    if (isDriverPerformanceBoard(board)) {
      trackEvent('Driver Bonus - Upload Data - Success', {
        type: dataType.name,
      });
    }
  }, [board, dataType, trackEvent]);

  const onUploadFailure = useCallback(() => {
    if (!dataType) {
      return;
    }

    if (isDriverPerformanceBoard(board)) {
      trackEvent('Driver Bonus - Upload Data - Failure', {
        type: dataType.name,
      });
    }
  }, [board, dataType, trackEvent]);

  return (
    <ModalTransition>
      {isOpen && dataType && (
        <FileUploadModal
          dataType={dataType}
          close={close}
          onUploadSuccess={onUploadSuccess}
          onUploadFailure={onUploadFailure}
        />
      )}
    </ModalTransition>
  );
};

export default UploadData;
