import { useCallback, useContext } from 'react';
import _ from 'lodash';

import { useGetCadence } from '../../../contextProviders/BoardPeriodsProvider';
import useWarningConfirmation from '../../ConfirmationModals/hooks/useWarningConfirmation';
import formatDateLabel from '../../V5Gadget/formatDateLabel';
import TargetFormContext from '../contexts/TargetFormContext';
import TargetListContext from '../contexts/TargetListContext';

const useTargetSaveConfirmation = () => {
  const { editingTarget, dataType, isCopyingFromTarget, effectiveDate } =
    useContext(TargetFormContext);
  const { targetsForField } = useContext(TargetListContext);
  const getCadence = useGetCadence(dataType);

  const { DangerConfirmation, getConfirmationThat } = useWarningConfirmation({
    title: 'Target Will Change',
  });

  const getDateLabel = useCallback(
    (date: string) => {
      return formatDateLabel(
        date,
        getCadence().cadence,
        false,
        undefined,
        undefined,
        true,
      );
    },
    [getCadence],
  );

  const getNextTarget = useCallback(
    (target: Targets.Wizard.DataTypeTarget) => {
      const sortedTargetsForField = _.sortBy(targetsForField, 'effectiveDate');
      const editingTargetIndex = sortedTargetsForField.findIndex(
        (t) => t.id === target.id,
      );
      const nextTargetIndex = editingTargetIndex + 1;
      const nextTarget =
        sortedTargetsForField.length > nextTargetIndex
          ? sortedTargetsForField[nextTargetIndex]
          : undefined;
      return nextTarget;
    },
    [targetsForField],
  );

  const buildConfirmationBody = useCallback(
    (affectedTarget: Targets.Wizard.DataTypeTarget) => {
      const startDateLabel = getDateLabel(affectedTarget.effectiveDate);
      const nextTarget = getNextTarget(affectedTarget);
      const dateRange = nextTarget
        ? `${startDateLabel} to ${getDateLabel(nextTarget.effectiveDate)}`
        : `${startDateLabel}`;

      return `Target from ${dateRange} will change.\nIf you have set Reason codes, they may become invalid.`;
    },
    [getDateLabel, getNextTarget],
  );

  const getAffectedTarget = useCallback(():
    | Targets.Wizard.DataTypeTarget
    | undefined => {
    if (editingTarget && !isCopyingFromTarget) {
      return editingTarget;
    }

    if (!effectiveDate) {
      return undefined;
    }

    if (targetsForField.length === 0) {
      return undefined;
    }

    const targetsBeforeStartDate = targetsForField.filter(
      (t) => t.effectiveDate < effectiveDate,
    );

    if (targetsBeforeStartDate.length === 0) {
      return undefined;
    }

    const affectedTarget = targetsBeforeStartDate[0];
    const isAffectedTargetToLastOne =
      targetsForField[0].id === affectedTarget.id;
    if (isAffectedTargetToLastOne) {
      return undefined;
    }

    return affectedTarget;
  }, [editingTarget, effectiveDate, isCopyingFromTarget, targetsForField]);

  const getConfirmation = useCallback((): Promise<boolean> => {
    const affectedTarget = getAffectedTarget();
    if (!affectedTarget) {
      return Promise.resolve(true);
    }

    return getConfirmationThat(buildConfirmationBody(affectedTarget));
  }, [buildConfirmationBody, getAffectedTarget, getConfirmationThat]);

  return {
    TargetSaveConfirmation: DangerConfirmation,
    getConfirmation,
  };
};

export default useTargetSaveConfirmation;
