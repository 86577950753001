import useWindowSize from './useWindowSize';
import MEDIA from '../media';

const useModalWidth = () => {
  const { windowWidth } = useWindowSize();

  return windowWidth <= MEDIA.MEASUREMENTS.WIDTH_BREAKPOINT_MEDIUM &&
    windowWidth > MEDIA.MEASUREMENTS.WIDTH_BREAKPOINT_SMALL
    ? '75vw'
    : windowWidth <= MEDIA.MEASUREMENTS.WIDTH_BREAKPOINT_SMALL
      ? '90vw'
      : '60vw';
};

export default useModalWidth;
