import React, { useContext } from 'react';
import Heading from './Heading';
import CustomerLaneSlideOutContext from '../../../../contexts/CustomerLaneSlideOut';

const HeadingContainer = () => {
  const { lane, customer, close } = useContext(CustomerLaneSlideOutContext);

  if (!lane || !customer) {
    return null;
  }

  return <Heading lane={lane} customer={customer} close={close} />;
};

export default HeadingContainer;
