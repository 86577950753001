import STORE from '../../store';

const deleteRankingMatrix = ({
  accountId,
  rankingMatrix,
}: {
  accountId: string;
  rankingMatrix: VisualisationDefinitions.RankingMatrix;
}) => {
  return STORE.visualisations
    .getRankingMatricesRef({ accountId })
    .doc(rankingMatrix.id)
    .delete();
};

export default deleteRankingMatrix;
