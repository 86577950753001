import React from 'react';
import styled from 'styled-components';

import Colors2 from 'theme/Colors2';
import Row from 'components/Common/Row';
import Typography from 'kingpin/atoms/Typography';
import LeftSvg from '../../icons/left.svg';

const LeftImg = styled.img`
  width: 16px;
  height: 16px;
`;

const RightIcon = () => <LeftImg src={LeftSvg} />;

export const Button = styled.div`
  padding: 8px;
  background-color: ${Colors2.Primary['8']};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

const SlideOutWizardHeader = ({
  title,
  back,
}: {
  title: string;
  back: () => void;
}) => {
  return (
    <Row centerAlign style={{ height: '100%' }}>
      <Button onClick={back}>
        <RightIcon />
      </Button>
      <Row centerAlign style={{ height: '100%' }}>
        <Typography.Header type="H4">{title}</Typography.Header>
      </Row>
    </Row>
  );
};

export default SlideOutWizardHeader;
