import { useCallback, useContext, useEffect, useState } from 'react';
import FavouritesContext from '../contexts/FavouritesContext';
import ImpersonatorContext from 'contexts/ImpersonatorContext';

const useFavouriteToggle = ({
  type,
  typeId,
  name,
}: {
  type:
    | 'report'
    | 'dashboard'
    | 'wallboard'
    | 'workspace'
    | 'scorecard'
    | 'targetsApp';
  typeId: string;
  name: string;
}) => {
  const { isLoading, getIsFavourite, onFavouriteToggled } =
    useContext(FavouritesContext);
  const { impersonatorId } = useContext(ImpersonatorContext);
  const [isFavourite, setIsFavourite] = useState<boolean>(
    getIsFavourite(type, typeId),
  );

  useEffect(() => {
    setIsFavourite(getIsFavourite(type, typeId));
  }, [getIsFavourite, type, typeId]);

  const onToggled = useCallback(
    (event: React.MouseEvent<any>) => {
      event.preventDefault();
      event.stopPropagation();
      if (impersonatorId) {
        alert('Toggling favorites is disabled in impersonation mode.');
        return;
      }

      onFavouriteToggled(type, typeId, name);
    },
    [name, onFavouriteToggled, type, typeId, impersonatorId],
  );

  return {
    isLoading,
    onFavouriteToggled: onToggled,
    isFavourite,
  };
};

export default useFavouriteToggle;
