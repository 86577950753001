import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import SlideOutContext from '../contexts/SlideoutContext';
import Row from 'components/Common/Row';
import Typography from 'kingpin/atoms/Typography';
import Button from 'kingpin/atoms/Button';

const TopBarSlideout = styled.div`
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.08);
  margin-bottom: 8px;
  padding: 16px 24px;
`;

const SlideoutHeader = ({ name }: { name: string }) => {
  const { setIsOpen } = useContext(SlideOutContext);

  const close = useCallback(() => {
    setIsOpen(false);

    // trackEvent('Board - Slide out closed');
  }, [setIsOpen]);

  return (
    <TopBarSlideout>
      <Row centerAlign spaceBetween style={{ height: '100%' }}>
        <Row centerAlign style={{ height: '100%' }}>
          <Typography.Header type="H4">{name}</Typography.Header>
        </Row>
        <Button type="Secondary" size="Small" onClick={close} icon="cross" />
      </Row>
    </TopBarSlideout>
  );
};

export default SlideoutHeader;
