import React from 'react';
import Row from '../../../../components/Common/Row';
import Typography from 'kingpin/atoms/Typography';
import Button from 'kingpin/atoms/Button';
import UserFromId from '../../../../components/UserFromId';
import CoreBadge from '../CoreBadge';

const LastUpdatedBy = ({ userId }: { userId?: string }) => {
  if (!userId) {
    return null;
  }

  return (
    <Row centerAlign>
      <div style={{ marginRight: 8, display: 'flex' }}>
        <Typography.Body type="Body 12">Last updated by:</Typography.Body>
      </div>
      <UserFromId userId={userId} />
    </Row>
  );
};

const Headings = ({
  metric,
  close,
  onEditClicked,
}: {
  metric: Metrics.NormalMetric | Metrics.CompoundMetric;
  close: () => void;
  onEditClicked: () => void;
}) => {
  return (
    <div style={{ marginBottom: 8 }}>
      <Row spaceBetween style={{ marginBottom: 12 }} centerAlign>
        <Row centerAlign>
          <div style={{ marginRight: 12 }}>
            <Typography.Header type="H2">{metric.name}</Typography.Header>
          </div>
          <CoreBadge metric={metric} />
        </Row>
        <Row>
          <div style={{ marginRight: 8 }}>
            <Button
              label="Edit Metric"
              onClick={onEditClicked}
              size="Small"
              type="Primary"
            />
          </div>

          <div>
            <Button
              onClick={close}
              icon="cross"
              type="Secondary"
              size="Small"
            />
          </div>
        </Row>
      </Row>
      <LastUpdatedBy userId={metric.updatedBy} />
    </div>
  );
};

export default Headings;
