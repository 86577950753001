import React, { useCallback, useContext, useEffect, useState } from 'react';
import aguid from 'aguid';
import { DropResult } from 'react-beautiful-dnd';

import SeriesInput from './SeriesInput';
import V5GadgetFormContext from '../../../contexts/V5GadgetFormContext';

const SeriesInputContainer = () => {
  const { series, setSeries, isMultipleSeriesChart, isMultipleYAxisChart } =
    useContext(V5GadgetFormContext);
  const [metricIdsToConstrainBy, setMetricIdsToConstrainBy] = useState<
    string[]
  >([]);
  const isAddButtonVisible = series.length === 0 || isMultipleSeriesChart;

  useEffect(() => {
    setMetricIdsToConstrainBy(series.map((s) => s.metricId));
  }, [series]);

  const onAddSeries = useCallback(
    (metric: Metrics.Metric) => {
      setSeries([
        ...series,
        {
          id: aguid(),
          metricId: metric.id,
          smartCardComparison: 'delta',
        },
      ]);
    },
    [series, setSeries],
  );

  const updateSeries = useCallback(
    (newS: V5ChartDefinitionSeries) => {
      setSeries((currentSeries) =>
        currentSeries.map((s) => {
          if (s.id === newS.id) {
            return newS;
          }
          return s;
        }),
      );
    },
    [setSeries],
  );

  const removeSeries = (newS: V5ChartDefinitionSeries) => {
    setSeries(series.filter((s) => s.id !== newS.id));
  };

  const onDrop = (result: DropResult) => {
    const { draggableId, destination } = result;
    if (!destination) {
      return;
    }

    const itemsWithoutDropped = series.filter((s) => s.id !== draggableId);
    const theItem = series.find((s) => s.id === draggableId);
    if (!theItem) {
      throw new Error('Item not found');
    }

    const newSeries = [
      ...itemsWithoutDropped.slice(0, destination.index),
      theItem,
      ...itemsWithoutDropped.slice(
        destination.index,
        itemsWithoutDropped.length,
      ),
    ];

    setSeries(newSeries);
  };

  return (
    <SeriesInput
      isAddButtonVisible={isAddButtonVisible}
      isMultipleYAxisChart={isMultipleYAxisChart}
      series={series}
      onAddSeries={onAddSeries}
      updateSeries={updateSeries}
      removeSeries={removeSeries}
      onDrop={onDrop}
      metricIdsToConstrainBy={metricIdsToConstrainBy}
    />
  );
};

export default SeriesInputContainer;
