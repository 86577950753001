import { useCallback, useContext, useEffect, useState } from 'react';
import MetricOptionsContext from '../contexts/MetricOptionsContext';

const useMetric = (metricId?: string) => {
  const { metricOptionsLookup } = useContext(MetricOptionsContext);
  const getMetric = useCallback(() => {
    if (metricId === undefined) {
      return undefined;
    }
    return metricOptionsLookup[metricId];
  }, [metricId, metricOptionsLookup]);
  const [metric, setMetric] = useState<Metrics.Metric | undefined>(() =>
    getMetric(),
  );
  useEffect(() => {
    setMetric(getMetric());
  }, [getMetric]);

  if (!metricId) {
    return undefined;
  }

  return metric;
};

export default useMetric;
