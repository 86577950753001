import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import formatDateLabel from '../components/V5Gadget/formatDateLabel';

const buildQuery = ({
  dateScope,
  dataType,
  cadence,
  cadenceInterval,
}: {
  dateScope: DateRangeInput;
  dataType: string;
  cadence?: 'day' | 'week' | 'month';
  cadenceInterval?: number;
}) => ({
  query: gql`
    query getDateRangePeriods(
      $dateScope: DateRangeInput!
      $dataType: String!
      $cadence: Cadence!
      $cadenceInterval: Int
    ) {
      getDateRangePeriods(
        dateScope: $dateScope
        dataType: $dataType
        cadence: $cadence
        cadenceInterval: $cadenceInterval
      ) {
        startDate
        endDate
      }
    }
  `,
  variables: {
    dateScope,
    dataType,
    cadence: cadence ? cadence : 'week',
    cadenceInterval,
  },
});

const getDateRangePeriods = async ({
  dateScope,
  dataType,
  client,
  cadence,
  cadenceInterval,
}: {
  dateScope: DateRangeInput;
  dataType: string;
  client: ApolloClient<NormalizedCacheObject>;
  cadence: 'day' | 'week' | 'month';
  cadenceInterval: number;
}): Promise<Period[]> => {
  const query = buildQuery({ dataType, dateScope, cadenceInterval, cadence });
  const response = await client.query(query);
  return response.data.getDateRangePeriods.map(
    (p: { startDate: string; endDate: string }) => ({
      ...p,
      label: formatDateLabel(p.startDate, cadence, true),
    }),
  );
};

export default getDateRangePeriods;
