import React from 'react';
import { ModalTransition } from '@atlaskit/modal-dialog';

import Button from 'kingpin/atoms/Button';
import PermissionGates from '../../PermissionGates';
import PERMISSIONS from '../../../permissionDefinitions';

import usePopup from '../../../hooks/usePopup';
import PublishMetricsPopup from './PublishMetricsPopup';

const REQ_PERMISSIONS = [
  PERMISSIONS.DATA_MANAGEMENT.DATA_MANAGER_ACCESS,
  PERMISSIONS.DATA_MANAGEMENT.REUSABLE_METRICS_CONFIG,
];

const PublishMetricsButton = () => {
  const { isOpen, open, close } = usePopup();

  return (
    <PermissionGates.HasAll requiredPermissions={REQ_PERMISSIONS}>
      <Button
        onClick={open}
        label="Add Metrics to Core List"
        type="Primary"
        size="Small"
      />
      <ModalTransition>
        {isOpen && <PublishMetricsPopup close={close} />}
      </ModalTransition>
    </PermissionGates.HasAll>
  );
};

export default PublishMetricsButton;
