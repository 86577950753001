import firebase from 'firebase/compat/app';
import { WORKSPACES_COLLECTION } from '../constants';
import withoutNulls from '../api/search/withoutNulls';

const workspaceConverter = {
  toFirestore(workspace: WorkSpace): firebase.firestore.DocumentData {
    return withoutNulls(workspace);
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot<WorkSpace>,
    options: firebase.firestore.SnapshotOptions,
  ): WorkSpace {
    return snapshot.data(options);
  },
};

const getWorkspacesRef = ({
  accountId,
  db,
}: {
  accountId: string;
  db?: firebase.firestore.Firestore;
}) => {
  return (db ? db : firebase.firestore())
    .collection('accounts')
    .doc(accountId)
    .collection(WORKSPACES_COLLECTION)
    .withConverter(workspaceConverter);
};

export default getWorkspacesRef;
