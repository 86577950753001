import { useCallback, useContext, useEffect } from 'react';
import _ from 'lodash';
import ScorecardContext from 'contexts/ScorecardContext';
import AnalyticsContext from 'contexts/AnalyticsContext';
import isIsoTargets from 'types/scorecardDates/isIsoTargets';
import isIsoDate from 'types/scorecardDates/isIsoDate';
import buildIsoWeekCompatabilityError from 'types/scorecardDates/buildIsoWeekCompatabilityError';

const useTargetBandsUtils = ({
  setIsAddingNewTargetBand,
  markHasUnsavedChanges,
  isTargetsDisabled,
  targets,
  setTargets,
  setSortedTargetBands,
  setProrationOverride,
  setIsColorOrderReversed,
}: {
  setIsAddingNewTargetBand: (value: React.SetStateAction<boolean>) => void;
  markHasUnsavedChanges: () => void;
  isTargetsDisabled: boolean;
  targets: Scorecards.WeekTargets | Scorecards.IsoTargets;
  setTargets: (
    value: React.SetStateAction<Scorecards.WeekTargets | Scorecards.IsoTargets>,
  ) => void;
  setSortedTargetBands: React.Dispatch<
    React.SetStateAction<
      {
        band: string[] | undefined;
        date: Scorecards.IsoDate | Scorecards.WeekDate;
      }[]
    >
  >;
  setProrationOverride: (
    value: React.SetStateAction<boolean | undefined>,
  ) => void;
  setIsColorOrderReversed: (value: React.SetStateAction<boolean>) => void;
}) => {
  const { scorecard, toIsoDate } = useContext(ScorecardContext);
  const { trackEvent } = useContext(AnalyticsContext);

  const onAddClicked = useCallback(() => {
    setIsAddingNewTargetBand(true);
    markHasUnsavedChanges();
  }, [markHasUnsavedChanges, setIsAddingNewTargetBand]);

  const onTargetBandSaved = useCallback(
    (
      newStartDate: Scorecards.WeekDate | Scorecards.IsoDate,
      newBands: string[],
    ) => {
      setTargets((currentTargets) => {
        if (isIsoTargets(currentTargets) && isIsoDate(newStartDate)) {
          return {
            ...currentTargets,
            targets: {
              ...currentTargets.targets,
              [newStartDate]: newBands,
            },
          };
        } else if (!isIsoTargets(currentTargets) && !isIsoDate(newStartDate)) {
          const year = currentTargets.targets[newStartDate.year] || {};
          const newYear = {
            ...year,
            [newStartDate.week]: newBands,
          };
          return {
            ...currentTargets,
            targets: {
              ...currentTargets.targets,
              [newStartDate.year]: newYear,
            },
          };
        } else {
          throw buildIsoWeekCompatabilityError();
        }
      });
      markHasUnsavedChanges();
    },
    [markHasUnsavedChanges, setTargets],
  );

  const onNewTargetBandSaved = useCallback(
    (
      newStartDate: Scorecards.WeekDate | Scorecards.IsoDate,
      newBands: string[],
    ) => {
      onTargetBandSaved(newStartDate, newBands);
      setIsAddingNewTargetBand(false);
      markHasUnsavedChanges();
    },
    [markHasUnsavedChanges, onTargetBandSaved, setIsAddingNewTargetBand],
  );

  const onReverseColorOrderToggled = useCallback(() => {
    trackEvent('KPI List - KPI - Reverse Color Order Clicked', {
      scorecardName: scorecard.title,
      scorecardId: scorecard.id,
    });
    setIsColorOrderReversed((r) => !r);
    markHasUnsavedChanges();
  }, [
    markHasUnsavedChanges,
    scorecard.id,
    scorecard.title,
    setIsColorOrderReversed,
    trackEvent,
  ]);

  const onDynamicTargetOverrideToggled = useCallback(
    (newValue: boolean) => {
      trackEvent('KPI List - KPI - Dynamic target override Clicked', {
        scorecardName: scorecard.title,
        scorecardId: scorecard.id,
      });
      setProrationOverride(newValue);
      markHasUnsavedChanges();
    },
    [
      markHasUnsavedChanges,
      scorecard.id,
      scorecard.title,
      setProrationOverride,
      trackEvent,
    ],
  );

  const onNewTargetBandCancelled = useCallback(() => {
    setIsAddingNewTargetBand(false);
  }, [setIsAddingNewTargetBand]);

  useEffect(() => {
    if (isTargetsDisabled) {
      return;
    }

    if (Object.keys(targets.targets).length === 0) {
      setIsAddingNewTargetBand(true);
    }
  }, [isTargetsDisabled, setIsAddingNewTargetBand, targets.targets]);

  useEffect(() => {
    const newSorted = (() => {
      if (isIsoTargets(targets)) {
        const allBands = Object.entries(targets.targets).map(
          ([startDate, band]) => {
            return {
              date: startDate,
              band,
            };
          },
        );
        return _.sortBy(allBands, 'date').reverse();
      } else {
        const allBands = [] as {
          band: string[] | undefined;
          date: Scorecards.WeekDate;
        }[];
        Object.entries(targets.targets).forEach(([year, weeks]) => {
          if (weeks) {
            Object.entries(weeks).forEach(([week, band]) => {
              allBands.push({
                band,
                date: { week, year },
              });
            });
          }
        });

        return allBands.sort((a, b) => {
          if (toIsoDate(a.date) > toIsoDate(b.date)) {
            return -1;
          } else if (toIsoDate(a.date) < toIsoDate(b.date)) {
            return 1;
          } else {
            return 0;
          }
        });
      }
    })();
    setSortedTargetBands(newSorted);
  }, [setSortedTargetBands, targets, toIsoDate]);

  return {
    onAddClicked,
    onNewTargetBandSaved,
    onTargetBandSaved,
    onDynamicTargetOverrideToggled,
    onReverseColorOrderToggled,
    onNewTargetBandCancelled,
  };
};

export default useTargetBandsUtils;
