import { useContext, useEffect, useState } from 'react';
import UsersContext from '../contexts/UsersContext';

const useUser = (userId: string | undefined) => {
  const [user, setUser] = useState<UserManagement.User | undefined>();
  const { allUsers, isLoading } = useContext(UsersContext);
  useEffect(() => {
    setUser(allUsers.find((u) => u.id === userId));
  }, [userId, allUsers]);

  return {
    user,
    isLoading,
  };
};

export default useUser;
