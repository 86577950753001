import { ApisauceInstance } from 'apisauce';

const updateUserRole = (
  userId: string,
  roles: string[],
  permissions: FleetOps.Permission[],
  api: ApisauceInstance,
) => {
  return api.patch(`/users/${userId}/role`, {
    roles,
    permissions,
  });
};

export default updateUserRole;
