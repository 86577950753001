import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import usePopup from '../../hooks/usePopup';
import InlineDialog from '../InlineDialog';
import SingleItemPicker from '../SingleItemPicker';
import ReportsContext from '../../contexts/ReportsContext';
import Row from '../Common/Row';
import BoardsContext from '../../contexts/BoardsContext';
import useMetric from '../../hooks/useMetric';
import ReportDrillDownsContext from '../../contexts/ReportDrillDownsContext';
import Typography from 'kingpin/atoms/Typography';
import metricTypeCheckers from '../../types/metricTypeCheckers';
import Icon from '../../kingpin/atoms/Icon';
import { DropDownButtonDiv } from '../Inputs/Dropdown';
import Colors2 from '../../theme/Colors2';
import RolesContext from '../../contexts/RolesContext';
import PERMISSIONS from '../../permissionDefinitions';
import { AfterReportNameBadge } from '../../screens/Reports/Reports';

const Positioner = styled.div<{ isBlockInput?: boolean }>`
  margin-bottom: ${(props) => (props.isBlockInput ? '24px' : '0px')};
`;

const isBoard = (
  r: PersistedReportType | Board | PerformanceBoardTypes.Board | undefined,
): r is Board | PerformanceBoardTypes.Board => {
  if (r === undefined || 'canvas' in r) {
    return false;
  }

  return true;
};

const usePermittedReports = (isAllReportsAllowed: boolean) => {
  const { currentPermissions } = useContext(RolesContext);
  const { allReports } = useContext(ReportsContext);
  const getPermittedReports = useCallback(() => {
    if (isAllReportsAllowed) {
      return allReports;
    }

    if (
      currentPermissions.includes(
        PERMISSIONS.CONTENT_ACCESS.VIEW_SE_ONLY_REPORTS,
      )
    ) {
      return allReports;
    }

    return allReports.filter((r) => !r.isMarkedAsSEOnly);
  }, [allReports, currentPermissions, isAllReportsAllowed]);
  const [permittedReports, setPermittedReports] = useState<
    PersistedReportType[]
  >(() => getPermittedReports());
  useEffect(() => {
    setPermittedReports(getPermittedReports());
  }, [getPermittedReports]);

  return permittedReports;
};

const ReportSelector = ({
  reportId,
  setReportId,
  label,
  isBlockInput,
  boardId,
  setBoardId,
  metricId,
  placeholder,
  trackDrillDownAdded,
  onChanged,
  isAllReportsAllowed,
}: {
  reportId?: string;
  setReportId: React.Dispatch<React.SetStateAction<string | undefined>>;
  label?: string;
  isBlockInput?: boolean;
  boardId?: string;
  setBoardId: React.Dispatch<React.SetStateAction<string | undefined>>;
  metricId?: string;
  placeholder?: string;
  trackDrillDownAdded?: ({
    type,
    id,
    name,
  }: {
    type: 'Report' | 'Board';
    id: string;
    name: string;
  }) => void;
  onChanged?: () => void;
  isAllReportsAllowed?: boolean;
}) => {
  const { isOpen, open, close } = usePopup();
  const { boards } = useContext(BoardsContext);
  const { dataTypes } = useContext(ReportDrillDownsContext);
  const metric = useMetric(metricId);
  const drillDownBoardOptions =
    !!metric && metricTypeCheckers.isNormalMetric(metric)
      ? boards.filter((b) => b.dataType === metric.dataType)
      : dataTypes
        ? boards.filter((b) => dataTypes.includes(b.dataType))
        : [];

  const [searchText, setSearchText] = useState<string>('');
  const onSearchTextChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };
  const permittedReports = usePermittedReports(!!isAllReportsAllowed);
  const filtered = (() => {
    const preFiltered = [...drillDownBoardOptions, ...permittedReports] as (
      | PersistedReportType
      | Board
    )[];

    if (searchText === '') {
      return _.sortBy(preFiltered, 'name') as (PersistedReportType | Board)[];
    }

    const f = preFiltered.filter((o) =>
      o.name.toLowerCase().includes(searchText.toLowerCase()),
    );

    return _.sortBy(f, 'name') as (PersistedReportType | Board)[];
  })();

  const selected = reportId
    ? permittedReports.find((m) => m.id === reportId)
    : boardId
      ? boards.find((b) => b.id !== undefined && b.id === boardId)
      : undefined;

  const onSelected = (r: PersistedReportType | Board | undefined) => {
    if (onChanged) {
      onChanged();
    }

    if (r) {
      if (isBoard(r)) {
        setBoardId(r.id);
        setReportId(undefined);
        if (trackDrillDownAdded) {
          trackDrillDownAdded({
            type: 'Board',
            id: r.id,
            name: r.name,
          });
        }
      } else {
        setReportId(r.id);
        setBoardId(undefined);
        if (trackDrillDownAdded) {
          trackDrillDownAdded({
            type: 'Report',
            id: r.id,
            name: r.name,
          });
        }
      }
    } else {
      setReportId(undefined);
      setBoardId(undefined);
    }
    close();
  };

  return (
    <Positioner isBlockInput={isBlockInput}>
      {label && (
        <div style={{ marginBottom: 4 }}>
          <Typography.Body type="Label">{label}</Typography.Body>
        </div>
      )}
      <InlineDialog
        isOpen={isOpen}
        onClose={close}
        content={
          <SingleItemPicker
            onClearClicked={
              selected
                ? () => {
                    onSelected(undefined);
                  }
                : undefined
            }
            searchText={searchText}
            onSearchTextChanged={onSearchTextChanged}
            isLoading={false}
            options={filtered.map((m) => ({
              key: m.id,
              label: m.name,
              value: m,
              renderRight: isBoard(m) ? undefined : (
                <AfterReportNameBadge report={m} />
              ),
            }))}
            onOptionSelected={onSelected}
          />
        }
      >
        <DropDownButtonDiv isOpen={isOpen} onClick={isOpen ? close : open}>
          <Row centerAlign style={{ width: '100%', flex: 1 }}>
            <Row spaceBetween style={{ width: '100%', flex: 1 }} centerAlign>
              <Typography.Body
                type="Placeholder"
                color={selected ? Colors2.Grey['1'] : Colors2.Grey['5']}
              >
                {selected
                  ? selected.name
                  : placeholder
                    ? placeholder
                    : drillDownBoardOptions.length > 0
                      ? 'Select a Report or Board'
                      : 'Select a Report'}
              </Typography.Body>
              <div style={{ marginLeft: 8, display: 'flex' }}>
                <Icon icon="chevron-down" />
              </div>
            </Row>
          </Row>
        </DropDownButtonDiv>
      </InlineDialog>
    </Positioner>
  );
};

export default ReportSelector;
