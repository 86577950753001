import { useCallback, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import TargetFormContext from '../../contexts/TargetFormContext';
import DatasetDefinitionsContext from '../../../../contexts/DatasetDefinitionsContext';
import TargetListContext from '../../contexts/TargetListContext';

const useIsFieldNameValid = (fieldName: string) => {
  const { targetsForField, selectedField } = useContext(TargetListContext);
  const { dataType } = useContext(TargetFormContext);
  const { datasets } = useContext(DatasetDefinitionsContext);

  const getIsCamelCase = useCallback((): boolean => {
    return _.camelCase(fieldName) === fieldName;
  }, [fieldName]);
  const getIsAlphabet = useCallback((): boolean => {
    const regex = /^[A-Za-z]+$/;
    return regex.test(fieldName);
  }, [fieldName]);
  const getIsPrefixedWithTargetedField = useCallback((): boolean => {
    if (!selectedField) {
      return false;
    }
    return (
      fieldName.includes(selectedField) &&
      fieldName.indexOf(selectedField) === 0
    );
  }, [fieldName, selectedField]);
  const getIsNewField = useCallback((): boolean => {
    const dataset = datasets.find((ds) => ds.type === dataType);
    if (!dataset) {
      const e = new Error();
      e.name = 'TargetManager: dataset not found';
      throw e;
    }
    return !dataset.fields.some((f) => f.field === fieldName);
  }, [dataType, datasets, fieldName]);
  const getIsOnExistingTarget = useCallback((): boolean => {
    return targetsForField.some((t) =>
      t.outputFields.some((f) => f.fieldName === fieldName),
    );
  }, [fieldName, targetsForField]);

  const getIsValid = useCallback((): boolean => {
    const isCamelCase = getIsCamelCase();
    const isAlphabet = getIsAlphabet();
    const isNewField = getIsNewField();
    const isPrefixedWithTargetedField = getIsPrefixedWithTargetedField();
    const isOnExistingTarget = getIsOnExistingTarget();

    return (
      isCamelCase &&
      isAlphabet &&
      isPrefixedWithTargetedField &&
      (isOnExistingTarget ? true : isNewField)
    );
  }, [
    getIsAlphabet,
    getIsCamelCase,
    getIsNewField,
    getIsOnExistingTarget,
    getIsPrefixedWithTargetedField,
  ]);

  const [isValid, setIsValid] = useState<boolean>(() => getIsValid());

  useEffect(() => {
    setIsValid(getIsValid());
  }, [getIsValid]);

  return isValid;
};

export default useIsFieldNameValid;
