import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import useIsScrolling from './useIsScrolling';

const ScrollingDivShadowCss = css`
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    pointer-events: none;
    box-shadow: inset 0px -13px 17px -10px #e3e3e3;
  }
`;

const ScrollableOuter = styled.div<{ isScrolling?: boolean }>`
  display: flex;
  flex: 1;
  position: relative;
  overflow: hidden;

  transition: box-shadow 12.5s;
  ${(props) => props.isScrolling && ScrollingDivShadowCss}
`;

const Scrollable = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

const Inner = styled.div`
  max-height: 0;
`;

const ShadowedList = ({
  children,
}: {
  children: JSX.Element | JSX.Element[] | null;
}) => {
  const scrollerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const isScrolling = useIsScrolling({
    scrollerRef,
    contentRef,
  });

  return (
    <ScrollableOuter isScrolling={isScrolling}>
      <Scrollable ref={scrollerRef}>
        <Inner ref={contentRef}>{children}</Inner>
      </Scrollable>
    </ScrollableOuter>
  );
};

export default ShadowedList;
