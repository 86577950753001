import React, { useCallback, useContext } from 'react';
import AnalyticsContext from '../../../contexts/AnalyticsContext';

const useTargetListCallbacks = ({
  selectedField,
  setSelectedField,
  setCopyingFromTarget,
  setSelectedTarget,
}: {
  selectedField?: string;
  setSelectedField: React.Dispatch<React.SetStateAction<string | undefined>>;
  setCopyingFromTarget: React.Dispatch<
    React.SetStateAction<Targets.Wizard.DataTypeTarget | undefined>
  >;
  setSelectedTarget: React.Dispatch<
    React.SetStateAction<Targets.Wizard.DataTypeTarget | undefined>
  >;
}) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const onFieldSelected = useCallback(
    (field: string) => {
      if (selectedField === field) {
        return;
      }

      trackEvent('Targets - Field Selected', { field });

      setSelectedField(field);
      setCopyingFromTarget(undefined);
    },
    [selectedField, setCopyingFromTarget, setSelectedField, trackEvent],
  );

  const onTargetSelected = useCallback(
    (target: Targets.Wizard.DataTypeTarget) => {
      setSelectedTarget(target);
      setCopyingFromTarget(undefined);
    },
    [setCopyingFromTarget, setSelectedTarget],
  );

  const onAddTargetClicked = useCallback(() => {
    trackEvent('Targets - Add Target Clicked', { targetField: selectedField });
    setSelectedTarget(undefined);
    setCopyingFromTarget(undefined);
  }, [selectedField, setCopyingFromTarget, setSelectedTarget, trackEvent]);

  const onCopyRulesToField = useCallback(
    ({
      field,
      target,
    }: {
      field: string;
      target: Targets.Wizard.DataTypeTarget;
    }) => {
      setSelectedField(field);
      setSelectedTarget(undefined);
      setCopyingFromTarget(target);
    },
    [setCopyingFromTarget, setSelectedField, setSelectedTarget],
  );

  const onDuplicateRules = useCallback(
    (target: Targets.Wizard.DataTypeTarget) => {
      setSelectedTarget(undefined);
      setCopyingFromTarget(target);
    },
    [setCopyingFromTarget, setSelectedTarget],
  );

  return {
    onFieldSelected,
    onAddTargetClicked,
    onCopyRulesToField,
    onDuplicateRules,
    onTargetSelected,
  };
};

export default useTargetListCallbacks;
