import React, { useCallback, useEffect, useState } from 'react';
import LandingPage from './LandingPage';
import Branding from './Branding';
import Typography from 'kingpin/atoms/Typography';
import Loading from './Loading';
import getCurrentBuildNumber from '../api/getCurrentBuildNumber';
import moment from 'moment';

const BlockExpiredPreviewSites = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [isVersionExpired, setIsVersionExpired] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [builtOn, setBuiltOn] = useState<string>();

  const onVisibilityChanged = useCallback(() => {
    setIsVisible(document.visibilityState === 'visible');
  }, []);

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChanged);
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChanged);
    };
  }, [onVisibilityChanged]);

  const initialCheckIfExpired = useCallback(() => {
    setIsLoading(true);
    getCurrentBuildNumber().then((n) => {
      setIsVersionExpired(n === undefined);
      if (n) {
        setBuiltOn(n.builtOn);
      }
      setIsLoading(false);
    });
  }, []);

  const checkIfExpired = useCallback(() => {
    if (!builtOn) {
      return;
    }

    if (moment(builtOn).add({ days: 6 }).isBefore(moment())) {
      setIsVersionExpired(true);
    }
  }, [builtOn]);

  useEffect(() => {
    setInterval(checkIfExpired, 1000 * 60);
  }, [checkIfExpired]);

  useEffect(() => {
    initialCheckIfExpired();
  }, [initialCheckIfExpired]);

  useEffect(() => {
    if (isVisible) {
      checkIfExpired();
    }
  }, [checkIfExpired, isVisible]);

  if (isLoading) {
    return (
      <div data-testid="blocked-expired-loading">
        <Loading />;
      </div>
    );
  }

  if (isVersionExpired) {
    return (
      <LandingPage style={{ height: 'unset', minHeight: '100vh' }}>
        <Branding />
        <Typography.Header type="H4">
          This version has been expired
        </Typography.Header>
      </LandingPage>
    );
  }

  return <>{children}</>;
};

const Gate = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const [isPreviewSite] = useState<boolean>(
    window.location.origin.includes('.web.app'),
  );

  if (isPreviewSite) {
    return <BlockExpiredPreviewSites>{children}</BlockExpiredPreviewSites>;
  } else {
    return <>{children}</>;
  }
};

export default Gate;
