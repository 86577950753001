import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

const buildQuery = (totalsArgs: Costs.IntervalTotalsArgs) => ({
  query: gql`
    query getTotalMilesAndDays(
      $modelType: CostModelType!
      $activityCostCalculationMode: ActivityCostCalculationMode
      $dateField: String!
      $startDate: DateTime!
      $endDate: DateTime!
      $groupFieldName: String
      $groups: [GroupDefinitionInput!]
    ) {
      getTotalMilesAndDays(
        modelType: $modelType
        activityCostCalculationMode: $activityCostCalculationMode
        dateField: $dateField
        startDate: $startDate
        endDate: $endDate
        groupFieldName: $groupFieldName
        groups: $groups
      ) {
        totalMiles
        totalDays
      }
    }
  `,
  variables: {
    ...totalsArgs,
  },
});

const getTotalMilesAndDays = async (
  totalsArgs: Costs.IntervalTotalsArgs,
  client: ApolloClient<NormalizedCacheObject>,
): Promise<Costs.IntervalTotals[]> => {
  const gqlQuery = buildQuery(totalsArgs);
  const response = await client.query(gqlQuery);
  return response.data.getTotalMilesAndDays;
};

export default getTotalMilesAndDays;
