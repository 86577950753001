import React from 'react';

import Row from 'components/Common/Row';
import Typography from 'kingpin/atoms/Typography';
import Button from 'kingpin/atoms/Button';

const SlideOutInitialHeader = ({ close }: { close: () => void }) => {
  return (
    <Row centerAlign spaceBetween style={{ height: '100%' }}>
      <Row centerAlign style={{ height: '100%' }}>
        <Typography.Header type="H4">Add A Widget</Typography.Header>
      </Row>
      <Button onClick={close} icon={'cross'} type="Secondary" size="Small" />
    </Row>
  );
};

export default SlideOutInitialHeader;
