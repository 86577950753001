import { useEffect } from 'react';

const useStateValidator = ({
  field,
  filterType,
  value,
}: {
  field?: string;
  filterType?: FilterBuilder.FilterType;
  value: string | number | string[] | boolean | undefined;
}) => {
  useEffect(() => {
    if (
      field === undefined ||
      filterType === undefined ||
      value === undefined
    ) {
      return;
    }

    const e = new Error();
    e.name = 'Unexpected value for filterType';
    if (filterType === 'text' && !Array.isArray(value)) {
      throw e;
    }
    if (
      filterType === 'number' &&
      !(typeof value == 'string' || typeof value === 'number')
    ) {
      throw e;
    }
    if (filterType === 'boolean' && typeof value !== 'boolean') {
      throw e;
    }
  }, [field, filterType, value]);
};

export default useStateValidator;
