import * as _ from 'lodash';
import { removePartialIntervalsDates } from '../../removePartialIntervals';
import formatDateLabel from './formatDateLabel';

export const buildStackedSeries = ({
  rowData,
  groupKey,
  newCategories,
  newTermLookup,
  getChartColor,
  getChartColorIndex,
  metricId,
}: {
  rowData: MatrixRowData[];
  groupKey: string;
  newCategories: string[];
  newTermLookup: { [term: string]: string };
  getChartColor: (term: string) => string;
  getChartColorIndex: (term: string) => number | undefined;
  metricId?: string;
}): StackedSeries[] => {
  return rowData.map((rD) => ({
    name: rD[groupKey] as string,
    data: (() => {
      const cloned = { ...rD };
      delete cloned[groupKey];
      return newCategories.map((category) =>
        Number(cloned[newTermLookup[category]]),
      );
    })(),
    color: getChartColor(rD[groupKey] as string),
    colorIndex: getChartColorIndex(rD[groupKey] as string),
    metricId,
  }));
};

export const buildCategories = (
  rowData: MatrixRowData[],
  groupKey: string,
  chartDef: V5ChartDefinition,
  autoInterval?: AutoInterval,
) => {
  if (rowData.length === 0) {
    return { termLookup: {}, prettyDates: [] };
  }

  if (chartDef.trendByCalendarInterval) {
    const dateScope = (() => {
      try {
        return Object.values(rowData)[0].dateScope as DateRangeInput;
      } catch (ex) {
        return undefined;
      }
    })();
    const allDates = (() => {
      const dates = [] as string[];
      rowData.forEach((row) => {
        Object.keys(row)
          .filter((d) => d !== groupKey)
          .forEach((d) => {
            dates.push(d);
          });
      });
      return _.uniq(dates).sort();
    })();

    const interval = autoInterval
      ? autoInterval.interval
      : chartDef.trendByCalendarInterval;

    const finalDates = chartDef.excludePartialIntervals
      ? removePartialIntervalsDates(allDates, interval, dateScope)
      : allDates;

    const termLookup = {} as { [term: string]: string };
    const prettyDates = [] as string[];

    finalDates.forEach((d) => {
      const pD = formatDateLabel(d, interval, true);
      prettyDates.push(pD);
      termLookup[pD] = d;
    });

    return {
      termLookup,
      prettyDates,
    };
  } else {
    const r = [] as string[];
    rowData.forEach((rD) => {
      const categories = Object.keys(rD).filter(
        (key) => key !== groupKey,
      ) as string[];
      categories.forEach((s) => {
        r.push(s);
      });
    });

    const termLookup = {} as { [term: string]: string };
    const prettyDates = _.uniq(r) as string[];
    prettyDates.forEach((pD) => {
      termLookup[pD] = pD;
    });

    return {
      termLookup,
      prettyDates,
    };
  }
};

export const buildStackedSeriesAlt = ({
  rowData,
  groupKey,
  chartDef,
  getChartColor,
  getChartColorIndex,
  autoInterval,
}: {
  rowData: MatrixRowData[];
  groupKey: string;
  chartDef: V5ChartDefinition;
  getChartColor: (term: string) => string;
  getChartColorIndex: (term: string) => number | undefined;
  autoInterval?: AutoInterval;
}): StackedSeries[] => {
  const { prettyDates } = buildCategories(
    rowData,
    groupKey,
    chartDef,
    autoInterval,
  );

  return prettyDates.map(
    (sN) =>
      ({
        name: sN,
        data: (() => {
          const d = [] as number[];
          rowData.forEach((rD) => {
            d.push(Number(rD[sN]));
          });

          return d;
        })(),
        color: getChartColor(sN),
        colorIndex: getChartColorIndex(sN),
      }) as StackedSeries,
  );
};

export const buildCategoriesAlt = (
  rowData: MatrixRowData[],
  groupKey: string,
): string[] => {
  if (rowData.length === 0) {
    return [];
  }
  const groups = [] as string[];
  rowData.forEach((rD) => {
    groups.push(rD[groupKey] as string);
  });
  return groups;
};
