import React from 'react';
import styled from 'styled-components';
import Typography from 'kingpin/atoms/Typography';
import Button from 'kingpin/atoms/Button';
import Colors2 from '../../theme/Colors2';

const Wrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid ${Colors2.Border};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 24px;
`;

const SlideOutHeading = ({
  close,
  headingText,
}: {
  close: () => void;
  headingText: string;
}) => (
  <Wrapper>
    <Typography.Header type="H5">{headingText}</Typography.Header>
    <Button size={'Small'} type={'Secondary'} onClick={close} icon={'cross'} />
  </Wrapper>
);

export default SlideOutHeading;
