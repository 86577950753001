import React from 'react';
import ReportsContext from 'contexts/ReportsContext';
import useReports from '../hooks/reports/useReports';

const ReportsProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { allReports, isLoading, reports } = useReports();

  return (
    <ReportsContext.Provider
      value={{
        allReports,
        availableReports: reports,
        isLoading,
      }}
    >
      {children}
    </ReportsContext.Provider>
  );
};

export default ReportsProvider;
