import React, { useCallback, useContext } from 'react';
import CurrentUserContext from '../contexts/CurrentUserContext';
import AnalyticsContext from '../contexts/AnalyticsContext';
import AccountPickerContext from '../contexts/AccountPickerContext';
import FavouritesContext from '../contexts/FavouritesContext';
import usePopup from 'hooks/usePopup';
import useFavourites from '../hooks/useFavourites';
import useFavouritesOrder from '../hooks/useFavouritesOrder';
import unFavourite from '../api/favourites/unFavourite';
import setFavouritesOrder from '../api/setFavouritesOrder';
import markItemAsFavourite from '../api/markItemAsFavourite';
import { buildContentViewIdAlt } from '../buildContentViewId';

const FavouritesProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { accountRef } = useContext(AccountPickerContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const currentUser = useContext(CurrentUserContext);
  const { favourites, setFavourites, isLoading } = useFavourites();
  const { favouritesOrder } = useFavouritesOrder();
  const {
    open: openSlideout,
    close: closeSlideout,
    isOpen: isOpenSlideout,
  } = usePopup();

  const getIsFavourite = useCallback(
    (
      type:
        | 'report'
        | 'dashboard'
        | 'wallboard'
        | 'workspace'
        | 'scorecard'
        | 'targetsApp',
      typeId: string,
    ) => {
      return favourites.some((f) => f.type === type && f.typeId === typeId);
    },
    [favourites],
  );

  const onFavouriteToggled = useCallback(
    (
      type:
        | 'report'
        | 'dashboard'
        | 'wallboard'
        | 'workspace'
        | 'scorecard'
        | 'targetsApp',
      typeId: string,
      name: string,
    ) => {
      const userId = currentUser.id;
      if (getIsFavourite(type, typeId)) {
        unFavourite({
          type,
          typeId,
          currentOrder: favouritesOrder,
          userId,
          accountRef,
        });
      } else {
        const newOrder = [
          buildContentViewIdAlt({ type, typeId }),
          ...favouritesOrder,
        ];
        setFavouritesOrder({
          order: newOrder,
          userId,
          accountRef,
        }).then(() => {
          markItemAsFavourite({ type, typeId, userId, name, accountRef }).then(
            () => {
              trackEvent('Nav - Favourite Added', {
                type,
                typeId,
              });
            },
          );
        });
      }
    },
    [accountRef, currentUser.id, favouritesOrder, getIsFavourite, trackEvent],
  );

  return (
    <FavouritesContext.Provider
      value={{
        favourites,
        setFavourites,
        isLoading,
        favouritesOrder,
        onFavouriteToggled,
        getIsFavourite,
        openSlideout,
        closeSlideout,
        isOpenSlideout,
      }}
    >
      {children}
    </FavouritesContext.Provider>
  );
};

export default FavouritesProvider;
