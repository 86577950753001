import React from 'react';

interface InlineDialogContextType {
  isInlineDialog: boolean;
}

const InlineDialogContext = React.createContext<InlineDialogContextType>({
  isInlineDialog: false,
});

export default InlineDialogContext;
