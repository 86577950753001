import React, { useCallback } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { GROUPS_DROPPABLE_ID } from '../constants';
import { getReorderedItems } from '../../DragAndDropList';

const useDropHandlers = (
  setGroups: React.Dispatch<React.SetStateAction<Targets.Wizard.TargetGroup[]>>,
) => {
  const onGroupReordered = useCallback(
    (dropResult: DropResult) => {
      setGroups((currentGroups) =>
        getReorderedItems({ items: currentGroups, dropResult }),
      );
    },
    [setGroups],
  );

  const onTargetReordered = useCallback(
    (groupId: string, dropResult: DropResult) => {
      setGroups((currentGroups) => {
        return currentGroups.map((g) => {
          if (g.key !== groupId) {
            return g;
          }

          const newTargets = getReorderedItems({
            items: g.targets,
            dropResult,
          });
          return {
            ...g,
            targets: newTargets,
          };
        });
      });
    },
    [setGroups],
  );

  const onDragEnd = useCallback(
    (dropResult: DropResult) => {
      if (!dropResult.destination) {
        return;
      }
      if (dropResult.destination.droppableId === GROUPS_DROPPABLE_ID) {
        onGroupReordered(dropResult);
      } else {
        // Dropped in a group's targets list
        onTargetReordered(dropResult.destination.droppableId, dropResult);
      }
    },
    [onGroupReordered, onTargetReordered],
  );

  return onDragEnd;
};

export default useDropHandlers;
