import { useEffect, useRef, useState } from 'react';
import _ from 'lodash';

const useCanvasWidth = (currentCanvas: Canvas) => {
  const [width, setWidth] = useState<number | undefined>();
  const canvasDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!canvasDivRef.current) return;
    if (process.env.NODE_ENV === 'test') {
      setWidth(1000);
      return;
    }
    const resizeObserver = new ResizeObserver(
      _.debounce(() => {
        if (!canvasDivRef.current) {
          setWidth(undefined);
          return;
        }

        setWidth(canvasDivRef.current.clientWidth);
      }, 200),
    );
    resizeObserver.observe(canvasDivRef.current);
    return () => resizeObserver.disconnect();
  }, [canvasDivRef, currentCanvas]);

  return {
    canvasDivRef,
    canvasWidth: width,
  };
};

export default useCanvasWidth;
