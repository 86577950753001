import React, { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Row from 'components/Common/Row';
import Typography from 'kingpin/atoms/Typography';
import Card from 'components/Common/Card';
import ScorecardContext from 'contexts/ScorecardContext';
import GridTargets from './GridTargets';

export const Col = styled.div<{ isWide?: boolean }>`
  flex: ${(props) => (props.isWide ? 1.5 : 1)};
  margin-right: 16px;
  flex-direction: column;
  overflow: hidden;
`;

const KpiGrid = ({
  kpi,
  trend,
  metric,
  slideoutPeriod,
}: {
  kpi: Scorecards.ScorecardKpi | Scorecards.ManualKpiRow;
  trend: Goals.TrendResult[];
  metric?: Metrics.Metric;
  slideoutPeriod: Period;
}) => {
  const { selectedPeriods } = useContext(ScorecardContext);
  const selectedRowRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (
      selectedRowRef &&
      !!selectedRowRef.current &&
      !!selectedRowRef.current.scrollIntoView
    ) {
      selectedRowRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });
    }
  }, [kpi, slideoutPeriod]);

  return (
    <div data-testid={'grid-preview'}>
      <Card>
        <Row
          centerAlign
          style={{
            padding: '8px',
            boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.12)',
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            minWidth: 'fit-content',
          }}
        >
          <Col>
            <Typography.Body type="Body 12">Period</Typography.Body>
          </Col>

          <Col>
            <Typography.Body type="Body 12">Target</Typography.Body>
          </Col>
          <Col>
            <Typography.Body type="Body 12">Actual</Typography.Body>
          </Col>
          <Col isWide>
            <Typography.Body type="Body 12">Diff</Typography.Body>
          </Col>
        </Row>
        <div
          style={{
            maxHeight: '208px',
            overflowY: 'scroll',
          }}
        >
          {[...selectedPeriods].reverse().map((p) => (
            <Row
              ref={p === slideoutPeriod ? selectedRowRef : null}
              key={p.startDate}
              centerAlign
              style={{
                backgroundColor: p === slideoutPeriod ? '#E5F0FA' : undefined,
                boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.08)',
                padding: '8px',
                minHeight: 32,
                minWidth: 'fit-content',
              }}
            >
              <GridTargets kpi={kpi} trend={trend} metric={metric} period={p} />
            </Row>
          ))}
        </div>
      </Card>
    </div>
  );
};

export default KpiGrid;
