import { useContext, useEffect, useState } from 'react';
import MetricOptionsContext from '../../contexts/MetricOptionsContext';
import isDefined from '../../isDefined';

const useRankingUsedMetrics = (
  rankingMatrixMetrics: VisualisationDefinitions.RankingMatrixMetric[],
) => {
  const { metricOptionsLookup } = useContext(MetricOptionsContext);
  const [metrics, setMetrics] = useState<Metrics.Metric[]>([]);

  useEffect(() => {
    const usedMetrics = rankingMatrixMetrics
      .map((rankingMetric) => {
        return metricOptionsLookup[rankingMetric.metricId];
      })
      .filter(isDefined);
    setMetrics(usedMetrics);
  }, [metricOptionsLookup, rankingMatrixMetrics]);

  return metrics;
};

export default useRankingUsedMetrics;
