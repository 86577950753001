// TODO: Bring the various board type checkers together into an index
// similar to the metricTypeCheckers
const isPerformanceBoard = (
  board:
    | PerformanceBoardTypes.Board
    | DriverPerformanceBoard
    | CustomerLaneBoard
    | GeneralBoard
    | undefined,
): board is PerformanceBoardTypes.Board => {
  return !!board && board.category === 'Performance';
};

export default isPerformanceBoard;
