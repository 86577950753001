import React from 'react';

interface DriverBonusContextType {
  workspace?: WorkSpace;
}

const DriverBonusConfigContext = React.createContext<DriverBonusContextType>(
  {} as DriverBonusContextType,
);

export default DriverBonusConfigContext;
