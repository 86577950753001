import isV5ChartDef from './isV5ChartDef';

const isRankingMatrix = (
  def?: ExtendedVisualisationDefinition,
): def is VisualisationDefinitions.RankingMatrix => {
  if (isV5ChartDef(def)) {
    return false;
  }

  return !!def && def.type && def.type === 'RankingMatrix';
};

export default isRankingMatrix;
