import { useCallback, useContext } from 'react';
import MetricOptionsContext from '../../contexts/MetricOptionsContext';
import BaseViewsContext from '../../contexts/BaseViewsContext';
import AccountContext from '../../contexts/AccountContext';
import captureException from '../../services/captureException';
import ensureNDecimalPlaces from './ensureNDecimalPlaces';
import numberToCommaString from './numberToCommaString';
import buildFix from './buildFix';
import isNormalMetric from '../../types/metricTypeCheckers/isNormalMetric';
import formatDate from './formatDate';
import formatFloat from '../../api/getChartSeries/formatFloat';
import { GEO_POINT_CELL } from '../../components/Grid/constants';

const DATE_AGG_FUNCS = ['first', 'last', 'min', 'max'];
export const CURRENCY_TYPES = ['USD', 'CAD'];

const formatGeoPoint = ({
  value,
  geoPointDisplay,
}: {
  value: number[];
  geoPointDisplay?: FleetOps.GeoPointDisplayFormat;
}) => {
  // GQL will always give us back long then lat.
  if (geoPointDisplay === 'lat, long') {
    const reversed = [...value].reverse();
    return reversed.join(', ');
  }

  return value.join(', ');
};

const useValueFormatters = () => {
  const { metricOptionsLookup } = useContext(MetricOptionsContext);
  const { baseViews } = useContext(BaseViewsContext);
  const { unitsLocale } = useContext(AccountContext);

  const captureMetricNotFound = useCallback((metricId?: string) => {
    const error = new Error(metricId);
    error.name = `Metric not found`;
    captureException(error);
  }, []);

  const getBaseFieldView = useCallback(
    ({ field, dataset }: { field: string; dataset: string }) => {
      const bv = baseViews[dataset];
      if (!bv) {
        return undefined;
      }

      return bv.fields[field];
    },
    [baseViews],
  );

  const formatValue = useCallback(
    ({
      formatting,
      value,
    }: {
      formatting: MetricFormatting;
      value: number;
    }) => {
      const {
        prefix: prefixType,
        postfix: postfixType,
        currencyType,
        precision,
        isCommasDisabled,
      } = formatting;
      const prefix = buildFix({
        fixType: prefixType,
        unitsLocale,
        currencyType,
      });
      const postfix = buildFix({
        fixType: postfixType,
        unitsLocale,
        currencyType,
      });
      const n = formatFloat(value, precision);
      const isNegative = value < 0;
      const body = ensureNDecimalPlaces({
        value: numberToCommaString({
          value: Math.abs(n),
          isCommasDisabled: isCommasDisabled,
        }),
        precision,
      });

      const base = `${prefix}${body}${postfix}`;
      if (isNegative && prefixType === 'currency') {
        return `(${base})`;
      } else if (isNegative) {
        return `-${base}`;
      }
      return base;
    },
    [unitsLocale],
  );

  const formatMetric = useCallback(
    ({
      metricId,
      draftMetric,
      value,
    }: {
      metricId?: string;
      draftMetric?: Metrics.Metric;
      value: string | string[] | number | null | undefined;
    }) => {
      if (value === null || value === undefined) {
        return '-';
      }

      if (metricId === undefined && draftMetric === undefined) {
        return value.toString();
      }

      const metric = draftMetric
        ? draftMetric
        : metricId
          ? metricOptionsLookup[metricId]
          : undefined;
      if (!metric) {
        captureMetricNotFound(metricId);
        return value.toString();
      }

      if (isNormalMetric(metric)) {
        const baseFieldView = getBaseFieldView({
          field: metric.field,
          dataset: metric.dataType,
        });
        if (
          baseFieldView &&
          baseFieldView.type === 'date' &&
          DATE_AGG_FUNCS.includes(metric.aggFunc) &&
          !Array.isArray(value)
        ) {
          return formatDate({ value, dateFormat: baseFieldView.dateFormat });
        }
      }

      if (typeof value === 'string' || Array.isArray(value)) {
        const { prefix: prefixType, postfix: postfixType } = metric.formatting;
        const prefix = buildFix({ fixType: prefixType, unitsLocale });
        const postfix = buildFix({ fixType: postfixType, unitsLocale });

        if (Array.isArray(value)) {
          return `${prefix}${value.join(', ')}${postfix}`;
        }
        return `${prefix}${value}${postfix}`;
      }

      const { formatting } = metric;
      return formatValue({ value, formatting });
    },
    [
      captureMetricNotFound,
      formatValue,
      getBaseFieldView,
      metricOptionsLookup,
      unitsLocale,
    ],
  );

  const formatField = useCallback(
    ({
      field,
      dataset,
      value,
    }: {
      field: string;
      dataset: string;
      value: string | number | null | undefined | number[];
    }): string | number => {
      if (value === null || value === undefined) {
        return '-';
      }

      const baseFieldView = getBaseFieldView({ field, dataset });
      if (!baseFieldView) {
        if (Array.isArray(value)) {
          return value.join(',');
        }
        return value;
      }

      if (
        (baseFieldView.type === 'date' || baseFieldView.cellType === 'Date') &&
        !Array.isArray(value)
      ) {
        return formatDate({ value, dateFormat: baseFieldView.dateFormat });
      }

      if (baseFieldView.cellType === GEO_POINT_CELL && Array.isArray(value)) {
        return formatGeoPoint({
          value,
          geoPointDisplay: baseFieldView.geoPointDisplay,
        });
      }

      const { prefix: prefixType, postfix: postfixType } = (() => {
        if (baseFieldView.cellType) {
          switch (baseFieldView.cellType) {
            case 'Currency':
              return {
                prefix: 'currency' as 'currency',
                postfix: undefined,
              };
            case 'Percentage':
              return {
                prefix: undefined,
                postfix: 'percentage' as 'percentage',
              };
            default:
              return {
                prefix: undefined,
                postfix: undefined,
              };
          }
        }

        return baseFieldView.formatting;
      })();

      if (typeof value === 'string') {
        const prefix = buildFix({ fixType: prefixType, unitsLocale });
        const postfix = buildFix({ fixType: postfixType, unitsLocale });

        return `${prefix}${value}${postfix}`;
      }

      if (Array.isArray(value)) {
        return value.join(', ');
      }

      const { formatting } = { ...baseFieldView };
      formatting['prefix'] = prefixType;
      formatting['postfix'] = postfixType;

      return formatValue({ value, formatting });
    },
    [formatValue, getBaseFieldView, unitsLocale],
  );

  const formatFileSize = useCallback((bytes: number) => {
    const thresh = 1000;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = ['kB', 'MB', 'GB', 'TB'];
    let u = -1;
    const r = 10;

    do {
      bytes /= thresh;
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
    );

    return bytes.toFixed(1) + ' ' + units[u];
  }, []);

  return { formatMetric, formatField, formatValue, formatFileSize };
};

export default useValueFormatters;
