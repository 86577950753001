import firebase from 'firebase/compat/app';
import { buildContentViewIdAlt } from '../../buildContentViewId';
import { DateTime } from 'luxon';

const trackLastViewedAt = async ({
  userId,
  type,
  typeId,
  accountRef,
}: {
  userId: string;
  type:
    | 'report'
    | 'dashboard'
    | 'wallboard'
    | 'workspace'
    | 'board'
    | 'scorecard'
    | 'targetsApp';
  typeId: string;
  accountRef: firebase.firestore.DocumentReference;
}) => {
  const lastViewedRef = accountRef
    .collection('users')
    .doc(userId)
    .collection('navigation')
    .doc('lastViewed');

  await firebase.firestore().runTransaction((transaction) => {
    return transaction.get(lastViewedRef).then((doc) => {
      const docData = (() => {
        if (doc.exists) {
          const data = doc.data();
          if (data) {
            return data.lastViewedAtData as LastViewedAtData;
          } else {
            return {};
          }
        } else {
          return {};
        }
      })();

      const lastViewedId = buildContentViewIdAlt({ type, typeId });
      const newDocData = {
        ...docData,
        [lastViewedId]: DateTime.utc().toISO(),
      };
      return transaction.set(lastViewedRef, { lastViewedAtData: newDocData });
    });
  });
};

export default trackLastViewedAt;
