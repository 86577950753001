import _ from 'lodash';
import aggregateMetricMatrix from '../../api/aggregateMetricMatrix';
import buildGroupBy from '../useV5ChartData/buildGroupBy';
import buildGroupByInterval from '../useV5ChartData/buildGroupByInterval';
import buildGroupByRanges from '../useV5ChartData/buildGroupByRanges';
import getGroupByField from './getGroupByField';
import metricTypeCheckers from '../../types/metricTypeCheckers';
import isDefined from '../../isDefined';

const getMetrics = async ({
  chartDef,
  filterInput,
  getTotals,
  usedMetrics,
  autoInterval,
  dateScope,
  toMetricInput,
  client,
  limitedKeywordFilter,
  isIgnoringLimit,
}: Queries.GetMetricsInput): Promise<MetricsResponse> => {
  const groupByField = getGroupByField(chartDef, autoInterval);

  const normalMetrics = usedMetrics.filter(metricTypeCheckers.isNormalMetric);
  const nonCompoundMetrics = usedMetrics.filter(
    metricTypeCheckers.isNotCompoundMetric,
  );
  const compoundMetrics = usedMetrics.filter(
    metricTypeCheckers.isCompoundMetric,
  );
  const metricsFromCompoundMetrics = (() => {
    let metrics = [] as Metrics.NormalMetric[];
    compoundMetrics.forEach((compoundM) => {
      const metricsForM = compoundM.metricIds
        .map((mId) => normalMetrics.find((m) => m.id === mId))
        .filter(isDefined);
      metrics = [...metrics, ...metricsForM];
    });
    return _.uniqBy(metrics, 'id');
  })();
  const queryMetrics = _.uniqBy(
    [...nonCompoundMetrics, ...metricsFromCompoundMetrics],
    'id',
  );

  const filterInputWithLimits = ((): FilterInput | undefined => {
    if (limitedKeywordFilter) {
      return {
        ...filterInput,
        keywords: [
          ...(filterInput.keywords || []).filter(
            // Remove existing keywords for the grouped term
            (k) => k.field !== groupByField,
          ),
          limitedKeywordFilter,
        ],
      };
    }
    return undefined;
  })();

  const queryExpressions = usedMetrics
    .filter(metricTypeCheckers.isCompoundMetric)
    .map((m) => {
      return {
        id: m.id,
        expression: m.expression,
      } as Expression2;
    });

  const shouldApplyAutoTrend = (() => {
    const autoTrendTypes = [
      'timeSeries',
      'bar',
      'horizontalBar',
      'combo',
      'matrix',
    ];
    return (
      !chartDef.trendByCalendarInterval &&
      autoTrendTypes.includes(chartDef.gadgetType) &&
      chartDef.groupByDayOfWeek === false
    );
  })();
  return aggregateMetricMatrix({
    client,
    metrics: queryMetrics,
    expressions: queryExpressions,
    filterInput: [filterInputWithLimits ? filterInputWithLimits : filterInput],
    groupBy:
      groupByField && !getTotals
        ? buildGroupBy(chartDef, isIgnoringLimit)
        : undefined,
    toMetricInput,
    trendByCalendarInterval:
      getTotals && !filterInputWithLimits
        ? undefined
        : shouldApplyAutoTrend
          ? 'auto'
          : chartDef.trendByCalendarInterval,
    trendByFixedIntervalDays:
      getTotals && !filterInputWithLimits
        ? undefined
        : chartDef.trendByFixedIntervalDays,
    groupByDayOfWeek:
      getTotals && !filterInputWithLimits
        ? undefined
        : chartDef.groupByDayOfWeek,
    groupByRange:
      getTotals && !filterInputWithLimits
        ? undefined
        : buildGroupByRanges(chartDef),
    groupByInterval:
      getTotals && !filterInputWithLimits
        ? undefined
        : buildGroupByInterval(chartDef),
    dateScope,
  });
};

export default getMetrics;
