import { useContext } from 'react';
import AccountContext from '../../contexts/AccountContext';
import {
  NATIONAL_CARRIERS_ACCOUNT_ID,
  NAVAJO_ACCOUNT_ID,
  STAGING_ACCOUNT_ID,
  STAGING_ACCOUNT_DCI_ID,
} from '../../constants';

const useIsDriverBonusRolesEnabled = () => {
  const { id: accountId } = useContext(AccountContext);

  return [
    NATIONAL_CARRIERS_ACCOUNT_ID,
    NAVAJO_ACCOUNT_ID,
    STAGING_ACCOUNT_ID,
    STAGING_ACCOUNT_DCI_ID,
  ].includes(accountId);
};

export default useIsDriverBonusRolesEnabled;
