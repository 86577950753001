import { useCallback, useContext, useEffect, useState } from 'react';
import GqlClientContext from 'contexts/GqlClientContext';
import useFilterInput from 'hooks/useFilterInput';
import useDateScope from 'hooks/useDateScope';
import { toInterval } from 'components/V5Gadget/toAutoInterval';

import runAggregation from './runAggregation';
import useToMetricInput from 'hooks/useToMetricInput';

const useTrendData = ({ metric }: { metric: MetricInput }) => {
  const { client } = useContext(GqlClientContext);
  const [data, setData] = useState<MetricResponse[] | undefined>();
  const [interval, setInterval] = useState<FleetOps.Interval | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const filterInput = useFilterInput();
  const dateScope = useDateScope({});
  const toMetricInput = useToMetricInput();

  const getMetric = useCallback(
    (): Metrics.SingleUseMetric => ({
      id: metric.id,
      name: 'Search Screen Graph (Internal)',
      description: '',
      formatting: {},
      type: 'normal' as 'normal',
      filters: {},
      dataType: metric.dataType,
      field: metric.field,
      aggFunc: metric.aggFunc,
      status: 'single use' as 'single use',
      createdBy: 'system',
      createdOn: '',
      updatedBy: 'system',
      updatedOn: '',
    }),
    [metric.aggFunc, metric.dataType, metric.field, metric.id],
  );

  useEffect(() => {
    let isActive = true;
    setIsLoading(true);
    runAggregation({
      client,
      metric: getMetric(),
      filterInput: [filterInput],
      dateScope,
      toMetricInput,
    }).then((newData) => {
      if (!isActive) {
        return;
      }

      setData(newData);
      if (newData.length > 0) {
        setInterval(toInterval(newData[0]['interval']));
      }
      setIsLoading(false);
    });

    return () => {
      isActive = false;
    };
  }, [client, dateScope, filterInput, getMetric, toMetricInput]);

  return {
    data,
    interval,
    isLoading,
  };
};

export default useTrendData;
