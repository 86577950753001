const PADDING = '24px';
const PADDING_SMALL = '14px 24px 12px 24px';
const POPUP_HEIGHT = '80vh';
const POPUP_HEIGHT_SMALL = '83vh';
const PREVIEW_DIV_ID = 'metric-popup-preview';
const PRIMARY_ACTION_DIV_ID = 'metric-popup-action';
const CORE_TOGGLE_DIV_ID = 'metric-popup-core-toggle';

const PREVIEW_SINGLE_USE_DIV_ID = 'metric-popup-preview-single-use';
const PRIMARY_ACTION_SINGLE_USE_DIV_ID = 'metric-popup-action-single-use';
const CORE_TOGGLE_SINGLE_USE_DIV_ID = 'metric-popup-core-toggle-single-use';

const PREVIEW_IN_SITU_DIV_ID = 'metric-popup-preview-in-situ';
const PRIMARY_ACTION_IN_SITU_DIV_ID = 'metric-popup-action-in-situ';
const CORE_TOGGLE_IN_SITU_DIV_ID = 'metric-popup-core-toggle-in-situ';

const MetricPopupConstants = {
  POPUP_HEIGHT_SMALL,
  PADDING,
  PADDING_SMALL,
  POPUP_HEIGHT,
  PREVIEW_DIV_ID,
  PRIMARY_ACTION_DIV_ID,
  CORE_TOGGLE_DIV_ID,
  PREVIEW_SINGLE_USE_DIV_ID,
  PRIMARY_ACTION_SINGLE_USE_DIV_ID,
  CORE_TOGGLE_SINGLE_USE_DIV_ID,
  PREVIEW_IN_SITU_DIV_ID,
  PRIMARY_ACTION_IN_SITU_DIV_ID,
  CORE_TOGGLE_IN_SITU_DIV_ID,
};

export default MetricPopupConstants;
