import React from 'react';
import styled from 'styled-components';

import CollapseSrc from './collapse.png';
import ExpandSrc from './expand.png';
import Colors2 from '../../theme/Colors2';
import Typography from 'kingpin/atoms/Typography';
import Row from '../Common/Row';
import MEDIA_QUERIES from '../../mediaQueries';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  box-shadow:
    0px 3px 2px -1px rgba(0, 0, 0, 0.02),
    0px 1px 1px -1px rgba(0, 0, 0, 0.04);
`;
const getHeight = ({ isDashboardCard }: { isDashboardCard: boolean }) => {
  if (isDashboardCard) {
    return 24;
  } else {
    return 32;
  }
};

const Left = styled.div<{
  isSelected: boolean;
  isDisabled?: boolean;
  isNarrowCard?: boolean;
  isDashboardCard?: boolean;
}>`
  height: ${(props) =>
    getHeight({ isDashboardCard: !!props.isDashboardCard })}px;
  background-color: ${(props) => (props.isDisabled ? '#E9E9E9' : 'white')};

  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border: 1px solid;
  border-color: ${(props) =>
    props.isSelected ? Colors2.Secondary.info : '#E0E0E0'};
  border-right: ${(props) =>
    props.isSelected
      ? `1px solid ${Colors2.Secondary.info}`
      : `1px solid transparent`};

  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};

  @media ${MEDIA_QUERIES.DESKTOP_XXS} {
    width: ${(props) => (props.isNarrowCard ? 60 : 117)}px;
  }
  @media ${MEDIA_QUERIES.DESKTOP_XS} {
    width: 117px;
  }
`;
const Right = styled.div<{
  isSelected: boolean;
  isDisabled?: boolean;
  isDashboardCard?: boolean;
}>`
  width: 117px;
  height: ${(props) =>
    getHeight({ isDashboardCard: !!props.isDashboardCard })}px;
  background-color: ${(props) => (props.isDisabled ? '#E9E9E9' : 'white')};

  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border: 1px solid;
  border-color: ${(props) =>
    props.isSelected ? Colors2.Secondary.info : '#E0E0E0'};

  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
`;

const Buttons = ({
  leftText,
  rightText,
  isLeftSelected,
  isRightSelected,
  onLeftClicked,
  onRightClicked,
  isDisabled,
  isNarrowCard,
  isDashboardCard,
  isOpen,
}: {
  leftText: string;
  rightText: string;
  isLeftSelected: boolean;
  isRightSelected: boolean;
  onLeftClicked: () => void;
  onRightClicked: () => void;
  isDisabled?: boolean;
  isNarrowCard?: boolean;
  isDashboardCard?: boolean;
  isOpen: boolean;
}) => (
  <Wrapper className={isOpen ? '' : 'opaque-unless-mouse-over'}>
    <Left
      isSelected={isLeftSelected}
      onClick={onLeftClicked}
      data-testid="date-left"
      isDisabled={isDisabled}
      isNarrowCard={isNarrowCard}
      isDashboardCard={isDashboardCard}
    >
      <Row
        centerAlign
        spaceBetween
        style={{ height: '100%', padding: '0px 8px' }}
      >
        <Typography.Body
          type="Placeholder"
          color={Colors2.Grey['1']}
          isEllipsis
        >
          {leftText}
        </Typography.Body>
        <img src={isLeftSelected ? CollapseSrc : ExpandSrc} alt="tick" />
      </Row>
    </Left>
    <Right
      isSelected={isRightSelected}
      onClick={onRightClicked}
      data-testid="date-right"
      isDisabled={isDisabled}
      isDashboardCard={isDashboardCard}
    >
      <Row
        centerAlign
        spaceBetween
        style={{ height: '100%', padding: '0px 8px' }}
      >
        <Typography.Body type="Placeholder" color={Colors2.Grey['1']}>
          {rightText}
        </Typography.Body>
        <img src={isRightSelected ? CollapseSrc : ExpandSrc} alt="tick" />
      </Row>
    </Right>
  </Wrapper>
);

export default Buttons;
