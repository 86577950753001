import React from 'react';
import useSharedContent from './useSharedContent';

interface SharedContextType {
  sharedContent: SharedContent;
  isLoading: boolean;
}

export const SharedContext = React.createContext<SharedContextType>({
  sharedContent: {
    reports: [],
    dashboards: [],
    workSpaces: [],
    scorecards: [],
  },
  isLoading: true,
});

const SharedProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { sharedContent, isLoading } = useSharedContent();
  return (
    <SharedContext.Provider value={{ sharedContent, isLoading }}>
      {children}
    </SharedContext.Provider>
  );
};

export default SharedProvider;
