import STORE from '../../store';

const updateScorecardDoc = async (
  newScorecard: Scorecards.Scorecard,
  accountId: string,
) => {
  await STORE.visualisations
    .getScorecardsRef({ accountId })
    .doc(newScorecard.id)
    .set(newScorecard);
};

export default updateScorecardDoc;
