import React from 'react';
import Typography from 'kingpin/atoms/Typography';
import Colors2 from '../../../../theme/Colors2';

const Commitment = ({
  ln1,
  ln2,
  ln3,
}: {
  ln1: string;
  ln2: string;
  ln3: string;
}) => (
  <div style={{ marginBottom: 24 }}>
    <div style={{ marginBottom: 4 }}>
      <Typography.Header type={'H4'}>Commitment</Typography.Header>
    </div>
    <div style={{ marginBottom: 4 }}>
      <Typography.Header type={'H5'} color={Colors2.Grey['4']}>
        {ln1}
      </Typography.Header>
    </div>
    <div>
      <Typography.Header type={'H5'} color={Colors2.Grey['4']}>
        {ln2}
      </Typography.Header>
    </div>
    <div>
      <Typography.Header type={'H5'} color={Colors2.Grey['4']}>
        {ln3}
      </Typography.Header>
    </div>
  </div>
);

export default Commitment;
