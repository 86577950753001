import { useContext, useEffect, useState } from 'react';
import BaseViewsContext from '../../../../../../contexts/BaseViewsContext';
import AnalyticsContext from '../../../../../../contexts/AnalyticsContext';
import useSelectDefaultDatasetFilters from 'hooks/useSelectDefaultDatasetFilters';

const useDataSourceOptions = ({
  setDataSource,
  onDatasetChanged,
  setDrillDowns,
}: {
  setDataSource: React.Dispatch<React.SetStateAction<string | undefined>>;
  onDatasetChanged: () => void;
  setDrillDowns: React.Dispatch<React.SetStateAction<FilterPlate[]>>;
}) => {
  const { dataTypeOptions } = useContext(BaseViewsContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const [dataSourceOptions, setDataSourceOptions] = useState<DropdownOption[]>(
    [],
  );
  const handleSelectDefaultDatasetFilters = useSelectDefaultDatasetFilters();

  useEffect(() => {
    setDataSourceOptions(
      dataTypeOptions.map(({ label, value }) => {
        return {
          label,
          value,
          onSelected: () => {
            const dataset = value;

            handleSelectDefaultDatasetFilters({
              dataset,
              setDrillDowns,
            });

            setDataSource(dataset);

            trackEvent('Metric Builder - Datasource selected', {
              dataset,
            });
            onDatasetChanged();
          },
        };
      }),
    );
  }, [
    dataTypeOptions,
    handleSelectDefaultDatasetFilters,
    onDatasetChanged,
    setDataSource,
    setDrillDowns,
    trackEvent,
  ]);

  return dataSourceOptions;
};

export default useDataSourceOptions;
