import moment from 'moment';

export const formatVerboseWeek = ({
  startDate,
  isShort,
  isYearImportant,
}: {
  startDate: string;
  isShort: boolean;
  isYearImportant: boolean;
}) => {
  const startMoment = moment(startDate);
  if (isShort && isYearImportant) {
    return startMoment.format(`MMM DD 'YY`);
  } else if (isShort && !isYearImportant) {
    return startMoment.format(`MMM DD`);
  }

  const endMoment = moment.utc(startDate).add({ day: 6 });
  const isEndingOnNewMonth = startMoment.month() !== endMoment.month();
  const isEndingOnNewYear = startMoment.year() !== endMoment.year();
  const yearFormatter = isYearImportant ? " 'YY" : '';
  if (isEndingOnNewMonth && isEndingOnNewYear) {
    return `${startMoment.format(
      `MMM DD ${yearFormatter}`,
    )} - ${endMoment.format(`MMM DD ${yearFormatter}`)}`;
  } else if (isEndingOnNewMonth) {
    return `${startMoment.format(
      `MMM DD ${yearFormatter}`,
    )} - ${endMoment.format(`MMM DD ${yearFormatter}`)}`;
  } else {
    return `${startMoment.format(`MMM DD`)} - ${endMoment.format(
      `DD ${yearFormatter}`,
    )}`;
  }
};

const formatDateLabel = (
  term: string | number,
  interval: FleetOps.Interval,
  useShortLabel?: boolean,
  dateRange?: { startDate: string; endDate?: string },
  isMatrix?: boolean,
  isYearImportant?: boolean,
) => {
  let endDay;
  let endYear;
  let endQ;

  const t = dateRange ? dateRange.startDate : term;
  const tEnd = dateRange ? dateRange.endDate : undefined;

  switch (interval) {
    case 'hour':
      if (useShortLabel) {
        return moment.utc(t).format('MM/DD ha');
      } else {
        return moment.utc(t).format("MMM DD 'YY ha");
      }
    case 'month':
      if (useShortLabel) {
        return moment.utc(t).format("MM 'YY");
      } else {
        return moment.utc(t).format("MMM 'YY");
      }
    case 'week':
      // We are adding 6 days here to reflect how our queries work
      // eslint-disable-next-line no-case-declarations
      endDay = tEnd
        ? moment.utc(tEnd).format('DD')
        : moment.utc(term).add(6, 'd').format('DD');

      if (isYearImportant) {
        if (useShortLabel) {
          return moment.utc(t).format(`MM/DD 'YY`);
        } else {
          return moment.utc(t).format(`MM/DD-${endDay} 'YY`);
        }
      } else {
        if (useShortLabel) {
          return moment.utc(t).format(`MM/DD`);
        } else {
          return moment.utc(t).format(`MM/DD-${endDay}`);
        }
      }
    case 'year':
      endYear = tEnd
        ? moment.utc(tEnd).format('YYYY')
        : moment.utc(term).add(1, 'y').format('YYYY');
      return moment.utc(t).format(`YYYY - ${endYear}`);
    case 'quarter':
      endQ = tEnd
        ? moment.utc(tEnd).format("Q 'YY")
        : moment.utc(term).add(1, 'quarter').format("Q 'YY");

      if (moment.utc(t).format("Q 'YY") === endQ) {
        return moment.utc(t).format(`[Q]Q' YY`);
      } else {
        return moment.utc(t).format(`[Q]Q' YY - [Q]${endQ}`);
      }
    case 'day':
    case 'auto':
    default:
      if (isMatrix) {
        if (useShortLabel) {
          return moment.utc(t).format('ddd, MM/DD');
        } else {
          return moment.utc(t).format("ddd, MMM DD 'YY");
        }
      } else {
        if (useShortLabel) {
          return moment.utc(t).format('MM/DD');
        } else {
          return moment.utc(t).format("MMM DD 'YY");
        }
      }
  }
};

export default formatDateLabel;
