const FIVE_LETTER_CODES = [
  'GYYVW',
  'GJRSL',
  'ERQZZ',
  'ZZEPF',
  'NYYIK',
  'JHMXS',
  'EUWWK',
  'TMFVW',
  'DFKWO',
  'RLFIB',
  'ZJIHI',
  'BCZTE',
  'YGDUT',
  'NUGNF',
  'POGTH',
  'EBWPN',
  'HJELQ',
  'STRGO',
  'XKQZM',
  'KFIGH',
  'YSQZE',
  'OCZKQ',
  'DQZME',
  'NRXTE',
  'PFVDQ',
  'NBXAI',
  'ORSSH',
  'UNYDZ',
  'GVBBX',
  'IMXGZ',
  'JPFHJ',
  'HTDLU',
  'UYOLF',
  'UCQCD',
  'OKWND',
  'IIZDS',
  'EIGCU',
  'ZJRFT',
  'KHDXP',
  'AGHTK',
  'FMCNY',
  'ZURBS',
  'DPFND',
  'PSDMM',
  'VNWEG',
  'HFIDZ',
  'KKANG',
  'JLKDB',
  'JJXEB',
  'KALSL',
];

export default FIVE_LETTER_CODES;
