import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

const buildQuery = (
  metrics: MetricInput[],
  filterInput: FilterInput[],
  expressions: Expression2[],
  dateScope: DateRangeInput,
  peerGroup: string,
  entity: string,
) => {
  return {
    query: gql`
      query peerComparison(
        $metrics: [MetricInput]!
        $filters: [FilterInput]!
        $dateScope: [DateRangeInput!]!
        $expressions: [Expression2]!
        $peerGroup: String!
        $entity: String!
      ) {
        peerComparison(
          metrics: $metrics
          filters: $filters
          dateScope: $dateScope
          expressions: $expressions
          peerGroup: $peerGroup
          entity: $entity
        )
      }
    `,
    variables: {
      metrics: metrics.map((m) => ({
        id: m.id,
        field: m.field,
        dataType: m.dataType,
        aggFunc: m.aggFunc,
        filters: m.filters,
      })),
      filters: filterInput,
      dateScope,
      expressions,
      peerGroup,
      entity,
    },
  };
};

const getPeerComparison = async ({
  filterInput,
  dateScope,
  metrics,
  expressions,
  peerGroup,
  entity,
  client,
}: {
  filterInput: FilterInput[];
  dateScope: DateRangeInput;
  metrics: MetricInput[];
  expressions: Expression2[];
  peerGroup: string;
  entity: string;
  client: ApolloClient<NormalizedCacheObject>;
}): Promise<GaugeResponse> => {
  const query = buildQuery(
    metrics,
    filterInput,
    expressions,
    dateScope,
    peerGroup,
    entity,
  );

  const response = await client.query(query);
  return response.data.peerComparison;
};

export default getPeerComparison;
