import { Link } from 'react-router-dom';
import Typography from 'kingpin/atoms/Typography';
import React, { useContext } from 'react';
import LocalTasksContext from '../../contexts/Tasks/LocalTasksContext';

const OpenTasksLink = ({ link }: { link?: string }) => {
  const { tasks } = useContext(LocalTasksContext);
  const openTasks = tasks.filter((t) => !t.isComplete);

  if (openTasks.length === 0 || !link) {
    return null;
  } else {
    return (
      <div style={{ marginRight: 32 }}>
        <Link to={link}>
          <Typography.Body type="Link">
            {`${openTasks.length} open task${openTasks.length > 1 ? 's' : ''}`}
          </Typography.Body>
        </Link>
      </div>
    );
  }
};

export default OpenTasksLink;
