import React from 'react';
import styled from 'styled-components';
import Colors from 'theme/colors';
import Typography from 'kingpin/atoms/Typography';

const List = styled.div`
  background-color: white;
  border-radius: 3px;
  border: 1px ${Colors.LOGAN} solid;
  width: 90%;
`;

const Option = styled.div`
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 3px;

  &:hover {
    background-color: #eee;
  }
`;

const DashboardContextMenu = ({
  metrics,
  label,
  setSelectedMetric,
}: {
  metrics: (Metrics.NormalMetric | Metrics.SpecialMetric)[];
  label: string;
  setSelectedMetric: React.Dispatch<
    React.SetStateAction<
      Metrics.NormalMetric | Metrics.SpecialMetric | undefined
    >
  >;
}) => (
  <List>
    {metrics.map((m) => (
      <Option
        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          setSelectedMetric(m);
          e.preventDefault();
          e.stopPropagation();
        }}
        key={m.id}
      >
        <Typography.Body type={'Label'}>
          {label !== '' ? `View ${m.name} for ${label}` : `View ${m.name}`}
        </Typography.Body>
      </Option>
    ))}
  </List>
);

export default DashboardContextMenu;
