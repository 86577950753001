const isEmptyFilter = (filter: FilterInput) => {
  if (filter.wildcardFilters && filter.wildcardFilters.length > 0) {
    return false;
  }

  if (filter.booleanFilters && filter.booleanFilters.length > 0) {
    return false;
  }

  if (filter.ranges && filter.ranges.length > 0) {
    return false;
  }

  if (filter.keywords && filter.keywords.length > 0) {
    return false;
  }

  if (filter.mustNotExistFilters && filter.mustNotExistFilters.length > 0) {
    return false;
  }

  if (filter.mustExistFilters && filter.mustExistFilters.length > 0) {
    return false;
  }

  return true;
};

export default isEmptyFilter;
