import React, { useCallback, useContext, useEffect, useState } from 'react';
import { GridApi, GridReadyEvent, SortChangedEvent } from 'ag-grid-community';
import _ from 'lodash';

import Grid from '../../Grid/Grid';
import buildGridOptions from '../../Grid/buildGridOptions';
import MetricMatrixContext from '../../../contexts/MetricMatrixContext';
import useChartDrillDowns from '../../../hooks/useChartDrillDowns';
import useColDefs from './useColDefs';
import useRowData from './useRowData';
import useSingleMetricDateMatrixData from './useSingleMetricDateMatrixData';
import SingleMetricMatrixContext from '../../../contexts/SingleMetricMatrixContext';
import Flash from '../../Flash/Flash';
import Typography from 'kingpin/atoms/Typography';
import useRowHighlighter from '../../../hooks/useRowHighlighter';
import Loading from '../../Loading';
import useWindowSize from '../../../hooks/useWindowSize';
import ShowAll from './ShowAll';
import useIsCompatibleComparison from '../../../hooks/useIsCompatableComparison';
import IncompatibleComparison from '../../IncompatibleComparison';
import { RowHeightContext } from '../../../contextProviders/RowHeightProvider';
const aguid = require('aguid');

const SingleMetricDateMatrixContentContainer = ({
  gadget,
  gridApi,
  setGridApi,
}: {
  gadget: VisualisationDefinitions.SingleMetricDateMatrix;
  gridApi?: GridApi;
  setGridApi: React.Dispatch<React.SetStateAction<GridApi | undefined>>;
}) => {
  // Context
  const isCompatibleComparison = useIsCompatibleComparison(gadget);
  const { rowHeight } = useContext(RowHeightContext);

  // State
  const [visId] = useState<string>(() => aguid());
  const [isFlashVisible, setIsFlashVisible] = useState<boolean>(false);

  // Hooks
  const {
    response,
    onShowAllPressed,
    isLoading,
    isInitialLoading,
    isLoadingAll,
    totalItems,
    currentItems,
    orderedBy,
    isAllDataInitiallyAvailable,
  } = useSingleMetricDateMatrixData(gadget);
  const { colDefs } = useColDefs(
    response,
    gadget,
    !!gadget.isSizedToFitOverridden,
  );
  useRowHighlighter({
    gridApi,
    drillField: gadget.groupByField,
    visId,
  });
  const { isMobile } = useWindowSize();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dismissFlash = useCallback(
    _.debounce(() => {
      setIsFlashVisible(false);
    }, 10000),
    [],
  );

  const onColumnSort = useCallback(
    (event: SortChangedEvent) => {
      // We can find more terms than totalTerms in the case
      // where the terms includes a "Blank" term
      if (
        isAllDataInitiallyAvailable ||
        event.api.getDisplayedRowCount() >= totalItems
      ) {
        return;
      }

      setIsFlashVisible(true);
      dismissFlash();
    },
    [isAllDataInitiallyAvailable, totalItems, dismissFlash],
  );

  const { rowData, totalsRowData } = useRowData({ response, colDefs, gadget });
  const { onDrillDown } = useChartDrillDowns(
    gadget.groupByField,
    undefined,
    'matrix',
  );

  const gridOptions = {
    ...buildGridOptions({
      columnDefs: colDefs,
      rowModelType: 'clientSide' as 'clientSide',
      rowHeight,
    }),
    rowData,
    context: {
      onDrillDown,
    },
    rowSelection: 'multiple' as 'multiple',
    suppressCellFocus: true,
  };

  const onGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api);
  };

  useEffect(() => {
    if (gridApi && rowData && colDefs.length > 0 && response) {
      gridApi.setGridOption('rowData', rowData);
    }
  }, [colDefs.length, gridApi, response, rowData]);

  useEffect(() => {
    if (gridApi && response && response.isFullSet) {
      gridApi.setGridOption('columnDefs', colDefs);
    }
  }, [colDefs, gridApi, response]);

  useEffect(() => {
    if (gridApi) {
      if (isLoading) {
        gridApi.showLoadingOverlay();
      } else {
        gridApi.hideOverlay();
      }
    }
  }, [gridApi, isLoading]);

  if (!isCompatibleComparison) {
    return <IncompatibleComparison />;
  }

  if (isInitialLoading) {
    return <Loading />;
  }

  return (
    <MetricMatrixContext.Provider
      value={{
        onDrillDown,
      }}
    >
      <SingleMetricMatrixContext.Provider
        value={{
          isLoadingAll,
          isAllDataLoaded: response ? response.isFullSet : true,
          onShowAllPressed,
          currentItems,
          totalItems,
          orderedBy,
          isAllDataInitiallyAvailable,
          chartDef: gadget,
        }}
      >
        <ShowAll />
        <div style={{ position: 'absolute', width: '100%' }}>
          <Flash
            type="info"
            isVisible={isFlashVisible}
            onDismissed={() => {
              setIsFlashVisible(false);
            }}
            messageHtml={
              <div>
                <Typography.Body
                  type="Body 12"
                  color={'white'}
                >{`Only sorting the ${currentItems} currently displayed`}</Typography.Body>
                <br />
                <Typography.Body type="Body 12">
                  {"Click 'Load All' and then sort to sort all items"}
                </Typography.Body>
              </div>
            }
          />
        </div>
        <Grid
          columnDefs={colDefs}
          onGridReady={onGridReady}
          gridOptions={gridOptions}
          totalsRow={totalsRowData}
          gridId={visId}
          onSortChanged={onColumnSort}
          autoSizeFirstColumnOnly={gadget.isSizedToFitOverridden && !isMobile}
          layoutOnFirstRender={true}
          layoutOnColumnChange={true}
          layoutOnModelUpdated={true}
          hasBorders={false}
          hasDarkHeader={false}
        />
      </SingleMetricMatrixContext.Provider>
    </MetricMatrixContext.Provider>
  );
};

export default SingleMetricDateMatrixContentContainer;
