import React from 'react';
import Typography from 'kingpin/atoms/Typography';
import ConfirmationModal from '../../ConfirmationModal';

const BonusSignOffConfirmation = ({
  board,
  close,
  isOpen,
  onConfirm,
  isLoading,
}: {
  board: DriverPerformanceBoard;
  close: () => void;
  isOpen: boolean;
  onConfirm: () => void;
  isLoading: boolean;
}) => (
  <ConfirmationModal
    isOpen={isOpen}
    close={close}
    title={`Signing off ${board.name}`}
    bodyComponent={
      <div>
        <div style={{ marginBottom: 36 }}>
          <Typography.Body type="Body 12">
            Are you sure you want to proceed?
          </Typography.Body>
        </div>
      </div>
    }
    confirmText={'Proceed'}
    onConfirmed={onConfirm}
    isLoading={isLoading}
  />
);

export default BonusSignOffConfirmation;
