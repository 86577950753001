import React, { ChangeEvent, useState } from 'react';
import VideoCardEditForm from './VideoCardEditForm';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import captureException from '../../../services/captureException';

const VideoCardEditFormContainer = ({
  close,
  isOpen,
  storagePath,
  originalSrc,
  setSrc,
}: {
  close: () => void;
  isOpen: boolean;
  storagePath: string;
  originalSrc: string;
  setSrc: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
  const [isUploadingVideo, setIsUploadingVideo] = useState<boolean>(false);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length === 1) {
      setIsUploadingVideo(true);
      const file = event.target.files[0];
      firebase
        .storage()
        .ref(storagePath)
        .put(file, { cacheControl: 'max-age=604800' })
        .then((snapshot) => {
          setIsUploadingVideo(false);
          snapshot.ref.getDownloadURL().then((newUrl) => {
            setSrc(newUrl);
          });
        })
        .catch((ex) => {
          captureException(ex);
        });
    }
  };

  return (
    <VideoCardEditForm
      close={close}
      isOpen={isOpen}
      videoSrc={originalSrc}
      isUploadingVideo={isUploadingVideo}
      onChange={onChange}
    />
  );
};

export default VideoCardEditFormContainer;
