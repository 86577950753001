import React from 'react';
import Tooltip from '../Tooltip';
import Button from '../../kingpin/atoms/Button';

const MetricsInfoButton = ({
  onClick,
  tooltip,
}: {
  onClick: () => void;
  tooltip: string;
}) => (
  <Tooltip content={tooltip}>
    <Button
      testId={'metric-info'}
      onClick={onClick}
      isDisabled={false}
      icon={'help'}
      type="Ghost"
      size="Small"
    />
  </Tooltip>
);

export default MetricsInfoButton;
