import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

const removeReasonCode = async ({
  documentId,
  documentType,
  client,
}: {
  documentId: string;
  documentType: string;
  client: ApolloClient<NormalizedCacheObject>;
}): Promise<void> => {
  const mutation = {
    mutation: gql`
      mutation RemoveReasonCodes($dataType: String!, $id: String!) {
        removeReasonCodes(dataType: $dataType, id: $id)
      }
    `,
    variables: {
      id: documentId,
      dataType: documentType,
    },
  };

  await client.mutate(mutation);
};

export default removeReasonCode;
