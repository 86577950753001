import React, { useCallback, useContext } from 'react';
import DatasetSetUpStep from './DatasetSetUpStep';
import useEntityOptions from './useEntityOptions';
import useDateOptions from './useDateOptions';
import useCadenceOptions from './useCadenceOptions';
import PerformanceWizardContext from '../PerformanceWizardContext';
import useEntityWindowDateFieldOptions from './useEntityWindowDateFieldOptions';

const DatasetSetUpStepContainer = ({
  onCloseClicked,
  goToNextStep,
}: {
  onCloseClicked: () => void;
  goToNextStep: () => void;
}) => {
  const { wizardState, setWizardState, isReadOnly } = useContext(
    PerformanceWizardContext,
  );
  const { entityOptions, entityDataTypeOptions, ConfirmationModal } =
    useEntityOptions();
  const dateOptions = useDateOptions();
  const cadenceOptions = useCadenceOptions();
  const entityWindowDateFieldOptions = useEntityWindowDateFieldOptions();
  const onIntervalLengthChanged = useCallback(
    (newLength: number | undefined) => {
      if (newLength === undefined) {
        return;
      }

      setWizardState((s) => ({
        ...s,
        config: {
          ...s.config,
          datasetStep: {
            ...s.config.datasetStep,
            intervalLength: newLength,
          },
        },
      }));
    },
    [setWizardState],
  );
  const onDatasetNameChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setWizardState((s) => ({
        ...s,
        config: {
          ...s.config,
          datasetStep: {
            ...s.config.datasetStep,
            name: e.target.value,
          },
        },
      }));
    },
    [setWizardState],
  );

  const onEntitySearchWindowChanged = useCallback(
    (newWindow: number | undefined) => {
      setWizardState((s) => ({
        ...s,
        config: {
          ...s.config,
          datasetStep: {
            ...s.config.datasetStep,
            entityWindowLength: newWindow ? newWindow : 1,
          },
        },
      }));
    },
    [setWizardState],
  );

  // sanitise name input
  const onDatasetNameBlur = useCallback(() => {
    setWizardState((s) => ({
      ...s,
      config: {
        ...s.config,
        datasetStep: {
          ...s.config.datasetStep,
          name: (s.config.datasetStep.name || '').replaceAll('  ', ' '),
        },
      },
    }));
  }, [setWizardState]);

  const onReportingWeekChanged = useCallback(
    (newStart: WeekStartsOn) => {
      setWizardState((s) => ({
        ...s,
        config: {
          ...s.config,
          datasetStep: {
            ...s.config.datasetStep,
            startOfWeek: newStart,
            startDate: undefined,
          },
        },
      }));
    },
    [setWizardState],
  );

  return (
    <>
      <DatasetSetUpStep
        isEditing={wizardState.isEditing}
        isReadOnly={isReadOnly}
        wizardState={wizardState}
        entityOptions={entityOptions}
        entityDataTypeOptions={entityDataTypeOptions}
        entityWindowDateFieldOptions={entityWindowDateFieldOptions}
        onEntitySearchWindowChanged={onEntitySearchWindowChanged}
        onIntervalLengthChanged={onIntervalLengthChanged}
        cadenceOptions={cadenceOptions}
        onDatasetNameChanged={onDatasetNameChanged}
        onDatasetNameBlur={onDatasetNameBlur}
        onReportingWeekChanged={onReportingWeekChanged}
        dateOptions={dateOptions}
        onCloseClicked={onCloseClicked}
        goToNextStep={goToNextStep}
      />
      {ConfirmationModal}
    </>
  );
};

export default DatasetSetUpStepContainer;
