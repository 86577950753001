import React from 'react';

import styled from 'styled-components';

const Style = styled.div`
  height: 31px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Column = ({
  children,
  style,
}: {
  children: JSX.Element | JSX.Element[];
  style?: any;
}) => <Style style={style}>{children}</Style>;

export default Column;
