import React, { ChangeEvent } from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import Button from 'kingpin/atoms/Button';

import Row from '../../Common/Row';
import Typography from 'kingpin/atoms/Typography';
import Search from '../../Search';
import styled from 'styled-components';

import Icon from '../../../kingpin/atoms/Icon';
import Form from '../../../kingpin/forms/Form';
import FormHeader from '../../../kingpin/forms/FormHeader';
import FormContent from '../../../kingpin/forms/FormContent';

const List = styled.div`
  height: 500px;
  overflow-y: auto;
  margin-bottom: 24px;
`;

const Item = styled.div<{ noHover?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 48px;

  padding-left: 16px;
  padding-right: 8px;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.08);

  ${(props) =>
    !props.noHover &&
    `
    cursor: pointer;
    &:hover {
    background-color: #f2f2f2;
  }`}
`;

const AddScorecardForm = ({
  isOpen,
  close,
  searchText,
  selectedScorecardId,
  onSearchTextChanged,
  filteredScorecards,
  onScorecardClicked,
  onAddClicked,
}: {
  isOpen: boolean;
  close: () => void;
  searchText: string;
  selectedScorecardId?: string;
  onSearchTextChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  filteredScorecards: Scorecards.Scorecard[];
  onScorecardClicked: (scorecard: Scorecards.Scorecard) => void;
  onAddClicked: () => void;
}) => (
  <ModalTransition>
    {isOpen && (
      <Modal onClose={close} shouldScrollInViewport={false} autoFocus={false}>
        <Form>
          <FormHeader title={'Add KPI List'} onClose={close} />
          <FormContent>
            <div style={{ marginBottom: 12 }}>
              <Search
                searchText={searchText}
                onSearchTextChanged={onSearchTextChanged}
                isShort
              />
            </div>
            <List className="hiding-scrollbar">
              {filteredScorecards.map((scorecard) => (
                <Item
                  key={scorecard.id}
                  onClick={() => onScorecardClicked(scorecard)}
                >
                  <Typography.Body type="Link">
                    {scorecard.title}
                  </Typography.Body>
                  {scorecard.id === selectedScorecardId && (
                    <Icon icon="checkmark" />
                  )}
                </Item>
              ))}
            </List>
            <Row spaceBetween centerAlign style={{ marginBottom: 24 }}>
              <Button
                onClick={close}
                label="Cancel"
                type="Ghost"
                size="Small"
              />
              <Button
                onClick={onAddClicked}
                isDisabled={selectedScorecardId === undefined}
                label="Add"
                type="Primary"
                size="Small"
              />
            </Row>
          </FormContent>
        </Form>
      </Modal>
    )}
  </ModalTransition>
);

export default AddScorecardForm;
