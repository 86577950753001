import React, { useCallback, useContext, useEffect, useState } from 'react';
import DatasetDefinitionsContext from 'contexts/DatasetDefinitionsContext';
import useGetDatasetLabel from 'components/Inputs/MetricPicker/MetricPickerPopup/hooks/useGetDatasetLabel';

const usePrimaryDatasetOptions = ({
  entityDataset,
  setPrimaryField,
  setEntityDataset,
  setSecondaryFields,
}: {
  entityDataset: string | undefined;
  setEntityDataset: React.Dispatch<React.SetStateAction<string | undefined>>;
  setPrimaryField: React.Dispatch<React.SetStateAction<string | undefined>>;
  setSecondaryFields: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const getDatasetLabel = useGetDatasetLabel();
  const { datasets } = useContext(DatasetDefinitionsContext);
  const buildOptions = useCallback((): DropdownOption[] => {
    return datasets.map((ds) => ({
      value: ds.type,
      label: getDatasetLabel(ds),
      isSelected: entityDataset === ds.type,
      onSelected: () => {
        setPrimaryField(undefined);
        setSecondaryFields([]);
        setEntityDataset(ds.type);
      },
    }));
  }, [
    datasets,
    getDatasetLabel,
    entityDataset,
    setEntityDataset,
    setPrimaryField,
    setSecondaryFields,
  ]);
  const [options, setOptions] = useState<DropdownOption[]>(buildOptions());
  useEffect(() => {
    setOptions(buildOptions());
  }, [buildOptions]);

  return options;
};

export default usePrimaryDatasetOptions;
