import firebase from 'firebase/compat/app';
import { BOARDS_COLLECTION, CUSTOMER_LANE_COMMITMENTS } from '../constants';
import isDriverPerformanceBoard from '../isDriverPerformanceBoard';

const migrateBoardV1toV2 = (board: Board): Board => {
  if (board.columnOrder) {
    return board;
  } else {
    return {
      ...board,
      columnOrder: [],
    };
  }
};

const migrateBoardToV3 = (board: Board): Board => {
  // @ts-ignore
  if (board.version === '2' || !board.version) {
    // @ts-ignore
    if (board.type === 'customerLaneCommits') {
      return {
        ...board,
        // @ts-ignore
        version: '3',
        category: 'Customer Lane Commitments',
        dataType: CUSTOMER_LANE_COMMITMENTS,
      };
    } else {
      return {
        ...board,
        // @ts-ignore
        version: '3',
        // @ts-ignore
        dataType: board.type,
      };
    }
  } else {
    return board;
  }
};

const migrateBoardToV4 = (board: Board): Board => {
  // @ts-ignore
  if (board.version === '3') {
    if (isDriverPerformanceBoard(board)) {
      const assignedToId = (() => {
        if (board.assignedToId) {
          if (Array.isArray(board.assignedToId)) {
            return board.assignedToId;
          } else {
            return [board.assignedToId];
          }
        } else {
          return [];
        }
      })();
      return {
        ...board,
        version: '4',
        assignedToId,
      };
    } else {
      return {
        ...board,
        version: '4',
      };
    }
  } else {
    return board;
  }
};

const boardsConverter = {
  toFirestore(board: Board): firebase.firestore.DocumentData {
    return board;
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot<Board>,
    options: firebase.firestore.SnapshotOptions,
  ): Board {
    const data = {
      ...snapshot.data(options),
    };
    return migrateBoardToV4(migrateBoardToV3(migrateBoardV1toV2(data)));
  },
};

const getBoardsRef = ({
  accountId,
  db,
}: {
  accountId: string;
  db?: firebase.firestore.Firestore;
}) => {
  return (db ? db : firebase.firestore())
    .collection('accounts')
    .doc(accountId)
    .collection(BOARDS_COLLECTION)
    .withConverter(boardsConverter);
};

export default getBoardsRef;
