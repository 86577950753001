import React, { useCallback, useContext, useEffect, useState } from 'react';
import AccountContext from 'contexts/AccountContext';
import GqlClientContext from 'contexts/GqlClientContext';
import { useGetCadence } from 'contextProviders/BoardPeriodsProvider';
import AnalyticsContext from 'contexts/AnalyticsContext';
import relativeDateRangeToDateRange from 'relativeDateRangeToDateRange';
import getDateRangePeriodsForTargets from '../api/getDateRangePeriodsForTargets';
import { LAST_3_YEARS_TO_DATE } from '../../DateInput/constants';
import TargetListContext from '../contexts/TargetListContext';
import useDangerConfirmation from '../../ConfirmationModals/hooks/useDangerConfirmation';
import useCadenceLabel from './useCadenceLabel';
import moment from 'moment';
import TargetFormContext from '../contexts/TargetFormContext';

const useTargetDateOptions = ({
  dataType,
  effectiveDate,
  setEffectiveDate,
}: {
  dataType: string;
  effectiveDate?: string;
  setEffectiveDate: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const { targetsForField } = useContext(TargetListContext);
  const { setIsShowingValidationFeedback } = useContext(TargetFormContext);
  const { weekStartsOn } = useContext(AccountContext);
  const { client } = useContext(GqlClientContext);
  const { getConfirmationThat, DangerConfirmation } = useDangerConfirmation({
    title: 'Target Already Exists',
    checkboxText:
      'I understand that my current targets will be replaced with new targets and this action cannot be undone',
  });
  const getCadence = useGetCadence(dataType);
  const cadenceLabel = useCadenceLabel(dataType);
  const [dateOptions, setDateOptions] = useState<DropdownOption[]>([]);
  const [defaultScrollToLabel, setDefaultScrollToLabel] = useState<
    string | undefined
  >();

  const isDateUsed = useCallback(
    (startDate: string) =>
      targetsForField.some((t) => t.effectiveDate === startDate),
    [targetsForField],
  );

  const buildConfirmationBody = useCallback((date: string) => {
    return `There is already a target starting on ${date}.\nThis target will be deleted. If you have Reason codes set they may become invalid.`;
  }, []);

  useEffect(() => {
    const { cadence, cadenceInterval } = getCadence();
    let isActive = true;

    getDateRangePeriodsForTargets({
      dateScope: relativeDateRangeToDateRange({
        relativeDateRange: LAST_3_YEARS_TO_DATE,
        startOfWeek: weekStartsOn,
      }),
      dataType,
      client,
      cadence,
      cadenceInterval,
    }).then((newPeriods) => {
      if (!isActive) {
        return;
      }

      const closestPeriod = (() => {
        const today = moment().format('YYYY-MM-DD');
        if (newPeriods.length === 0) {
          return undefined;
        }
        let closest = newPeriods[0];
        newPeriods.forEach((p) => {
          if (p.startDate <= today) {
            closest = p;
          }
        });
        return closest;
      })();

      const newDefaultScrollLabel = closestPeriod
        ? `${closestPeriod.label}`
        : undefined;
      setDateOptions(
        newPeriods.map(({ startDate, label }) => ({
          label:
            closestPeriod &&
            closestPeriod.startDate === startDate &&
            newDefaultScrollLabel
              ? newDefaultScrollLabel
              : label,
          value: startDate,
          onSelected: async () => {
            if (isDateUsed(startDate)) {
              const isConfirmed = await getConfirmationThat(
                buildConfirmationBody(label),
              );
              if (!isConfirmed) {
                return;
              }
            }
            trackEvent('Targets - Selected Start Date');
            setEffectiveDate(startDate);
            setIsShowingValidationFeedback(false);
          },
          isSelected: effectiveDate === startDate,
        })),
      );
      setDefaultScrollToLabel(newDefaultScrollLabel);
    });

    return () => {
      isActive = false;
    };
  }, [
    trackEvent,
    buildConfirmationBody,
    client,
    dataType,
    effectiveDate,
    getCadence,
    getConfirmationThat,
    isDateUsed,
    setEffectiveDate,
    weekStartsOn,
    setIsShowingValidationFeedback,
  ]);

  return {
    dateOptions,
    DangerConfirmation,
    cadenceLabel,
    defaultScrollToLabel,
  };
};

export default useTargetDateOptions;
