const CITY_STATE_LANES = [
  'Austin,TX -> Wichita,KA',
  'San Jose,CA -> Bakersfield,CA',
  'Fort Worth,TX -> New Orleans,LA',
  'Jacksonville,FL -> Cleveland,OH',
  'Charlotte,NC -> Henderson,NV',
  'Columbus,OH -> Anaheim,CA',
  'Indianapolis,IN -> Honolulu,HI',
  'San Francisco,CA -> Riverside,CA',
  'Seattle,WA -> Santa Ana,CA',
  'Denver,CO -> Corpus Christi,TX',
  'Washington,DC -> Lexington,KY',
  'Boston,MA -> San Juan,PR',
  'El Paso,TX -> Stockton,CA',
  'Nashville,TN -> St. Paul,MN',
  'Oklahoma City,OK -> Cincinnati,OH',
  'Las Vegas,NV -> Irvine,CA',
  'Portland,OR -> Greensboro,NC',
  'Detroit,MI -> Pittsburgh,PA',
  'Memphis,TN -> Lincoln,NE',
  'Louisville,KY -> Durham,NC',
  'Milwaukee,WI -> Orlando,FL',
  'Baltimore,MD -> St. Louis,MO',
  'Albuquerque,NM -> Chula Vista,CA',
  'Tucson,AZ -> Plano,TX',
  'Mesa,AZ -> Newark,NJ',
];

export default CITY_STATE_LANES;
