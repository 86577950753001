import React, { useCallback, useContext, useEffect, useState } from 'react';
import ColorDot from '../ColorDot';
import getIdentifier from '../../../../getIdentifier';
import getTimeStamp from '../../../../getTimeStamp';
import CurrentUserContext from '../../../../contexts/CurrentUserContext';
import useUrlSlug from './useUrlSlug';

const deriveUrlSlug = (name: string) => {
  return name.replace(/[^a-zA-Z]/g, '').toLowerCase();
};

const useFormState = () => {
  const { id: currentUserId } = useContext(CurrentUserContext);
  const [portalType, setPortalType] = useState<
    'Executive Portal' | 'Engagement Portal'
  >('Executive Portal');
  const getPortalTypeOptions = useCallback(() => {
    return [
      {
        label: 'Executive Portal',
        isSelected: portalType === 'Executive Portal',
        onSelected: () => {
          setPortalType('Executive Portal');
        },
      },
      {
        label: 'Engagement Portal',
        isSelected: portalType === 'Engagement Portal',
        onSelected: () => {
          setPortalType('Engagement Portal');
        },
      },
    ];
  }, [portalType]);
  const [portalTypeOptions, setPortalTypeOptions] = useState<DropdownOption[]>(
    () => getPortalTypeOptions(),
  );
  useEffect(() => {
    setPortalTypeOptions(getPortalTypeOptions());
  }, [getPortalTypeOptions, portalType]);

  const { urlSlug, setUrlSlug, onUrlSlugChanged, isUrlSlugValid } =
    useUrlSlug();
  const [name, setName] = useState<string>('');
  const onNameChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newName = event.target.value;
      setName((currentName) => {
        setUrlSlug((currentSlug) => {
          const currentDerivedSlug = deriveUrlSlug(currentName);
          if (currentDerivedSlug === currentSlug) {
            // Update the slug if it is in sync
            return deriveUrlSlug(newName);
          }

          // Keep the current slug as it has been given an explicit value
          return currentSlug;
        });

        // Set the new name
        return newName;
      });
    },
    [setUrlSlug],
  );

  const [color, setColor] = useState<PortalColor>('blue');
  const getColorOptions = useCallback(() => {
    const colors: PortalColor[] = [
      'blue',
      'green',
      'yellow',
      'orange',
      'red',
      'magenta',
      'navy',
      'grey',
      'lightgrey',
    ];
    return colors.map((c) => ({
      isSelected: color === c,
      onSelected: () => {
        setColor(c);
      },
      key: c,
      label: '',
      renderLabel: () => <ColorDot color={c} key={c} width={20} height={20} />,
    }));
  }, [color]);
  const [colorOptions, setColorOptions] = useState<DropdownOption[]>(() =>
    getColorOptions(),
  );
  useEffect(() => {
    setColorOptions(getColorOptions());
  }, [getColorOptions]);
  const [targetsLink, setTargetsLink] = useState<EngagementPortalTargetLink>({
    isEnabled: false,
  });
  const [entityLinks, setEntityLinks] = useState<EngagementPortalEntityLink[]>(
    [],
  );

  const [draftPortal, setDraftPortal] = useState<Portal | undefined>();
  useEffect(() => {
    if (name === '' || !isUrlSlugValid) {
      setDraftPortal(undefined);
      return;
    }

    if (portalType === 'Executive Portal') {
      const newPortal: ExecutivePortal = {
        id: getIdentifier(),
        type: 'ExecutivePortal',
        name,
        urlSlug,
        color,
        managerIds: [],
        dashboardIds: [],
        reportIds: [],
        scorecardIds: [],
        createdOn: getTimeStamp(),
        updatedOn: getTimeStamp(),
        createdBy: currentUserId,
        updatedBy: currentUserId,
      };
      setDraftPortal(newPortal);
      return;
    }

    if (portalType === 'Engagement Portal') {
      const newPortal: EngagementPortal = {
        id: getIdentifier(),
        type: 'Engagement Portal',
        name,
        urlSlug,
        color,
        entityLinks,
        targetsLink,
        managerIds: [],
        reportIds: [],
        dashboardIds: [],
        scorecardIds: [],
        createdOn: getTimeStamp(),
        updatedOn: getTimeStamp(),
        createdBy: currentUserId,
        updatedBy: currentUserId,
      };
      setDraftPortal(newPortal);
      return;
    }
  }, [
    color,
    currentUserId,
    entityLinks,
    isUrlSlugValid,
    name,
    portalType,
    targetsLink,
    urlSlug,
  ]);

  return {
    name,
    onNameChanged,
    portalType,
    portalTypeOptions,
    color,
    colorOptions,
    draftPortal,
    urlSlug,
    onUrlSlugChanged,
    isUrlSlugValid,
    targetsLink,
    setTargetsLink,
    entityLinks,
    setEntityLinks,
  };
};

export default useFormState;
