import React, { useCallback, useContext, useEffect, useState } from 'react';
import AdvancedTargetInput from './AdvancedTargetInput';
import usePopup from '../../../../../hooks/usePopup';
import getDateBuckets from '../../../../../screens/GoalShow/getDateBuckets';
import { getKpiTargetForMonth } from './MonthlyTargetBuilder';
import AccountContext from '../../../../../contexts/AccountContext';

const AdvancedTargetInputContainer = ({
  isKpi,
  target,
  setTarget,
  advancedTarget,
  setAdvancedTarget,
  targetMode,
  setTargetMode,
  startDate,
  endDate,
  cadence,
  testIdPrefix,
}: {
  isKpi?: boolean;
  target?: number;
  setTarget: React.Dispatch<React.SetStateAction<number | undefined>>;
  advancedTarget: GoalAdvancedTarget;
  setAdvancedTarget: React.Dispatch<React.SetStateAction<GoalAdvancedTarget>>;
  targetMode?: 'basic' | 'advanced';
  setTargetMode: React.Dispatch<
    React.SetStateAction<'basic' | 'advanced' | undefined>
  >;

  startDate: string;
  endDate: string;
  cadence: FleetOps.Interval;
  testIdPrefix: string;
}) => {
  const [dateIntervals, setDateIntervals] = useState<string[]>([]);
  const { weekStartsOn } = useContext(AccountContext);
  const { isOpen, open, close } = usePopup();
  const [draftAdvancedTarget, setDraftAdvancedTarget] =
    useState<GoalAdvancedTarget>(advancedTarget);
  const onAdvancedClicked = useCallback(() => {
    setDraftAdvancedTarget(advancedTarget);
    open();
  }, [advancedTarget, open]);
  const onBasicClicked = useCallback(() => {
    setTargetMode('basic');
    setAdvancedTarget({});
  }, [setAdvancedTarget, setTargetMode]);
  const onSave = useCallback(() => {
    setAdvancedTarget(draftAdvancedTarget);
    setTargetMode('advanced');
    close();
  }, [close, draftAdvancedTarget, setAdvancedTarget, setTargetMode]);
  useEffect(() => {
    const newDateIntervals = getDateBuckets({
      startDate,
      endDate,
      interval: cadence,
      weekStartsOn,
    });
    setDateIntervals(newDateIntervals);
  }, [cadence, endDate, startDate, weekStartsOn]);

  const sumOfAdvancedTargets = dateIntervals.reduce((total, currentDate) => {
    return total + getKpiTargetForMonth(advancedTarget, currentDate);
  }, 0);

  return (
    <AdvancedTargetInput
      isKpi={isKpi}
      target={target}
      setTarget={setTarget}
      targetMode={targetMode}
      onAdvancedClicked={onAdvancedClicked}
      onBasicClicked={onBasicClicked}
      isOpen={isOpen}
      onSave={onSave}
      onClose={close}
      advancedTarget={draftAdvancedTarget}
      setAdvancedTarget={setDraftAdvancedTarget}
      cadence={cadence}
      sumOfAdvancedTargets={sumOfAdvancedTargets}
      startDate={startDate}
      endDate={endDate}
      interval={cadence}
      testIdPrefix={testIdPrefix}
    />
  );
};

export default AdvancedTargetInputContainer;
