const MANAGE_COMMITMENTS: FleetOps.Permission =
  'fleetops.permissions.customer_commitments_manage_commitments';
const ADD_REASON_CODES: FleetOps.Permission =
  'fleetops.permissions.customer_commitments_add_reason_codes';

const CUSTOMER_COMMITMENTS_PERMISSIONS = {
  MANAGE_COMMITMENTS,
  ADD_REASON_CODES,
};
Object.freeze(CUSTOMER_COMMITMENTS_PERMISSIONS);

export default CUSTOMER_COMMITMENTS_PERMISSIONS;
