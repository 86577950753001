import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

const buildQuery = () => ({
  query: gql`
    query getReminderEventNames {
      getReminderEventNames
    }
  `,
});

const getReminderEventNames = async (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  const gqlQuery = buildQuery();
  const response = await client.query(gqlQuery);
  return response.data.getReminderEventNames;
};

export default getReminderEventNames;
