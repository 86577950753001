import React from 'react';
import Badge from '../../../../../components/Badge';

const MetricStatusBadge = ({ status }: { status: Metrics.MetricStatus }) => {
  if (status === 'core') {
    return <Badge text="Core" badgeType={'Success'} />;
  } else if (status === 'public') {
    return null;
  } else if (status === 'single use') {
    return <Badge text="Single use" badgeType="Default" />;
  } else if (status === 'archived') {
    return <Badge text="Archived" badgeType={'Warning'} />;
  } else {
    return null;
  }
};

export default MetricStatusBadge;
