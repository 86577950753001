import React from 'react';

interface ToastContextType {
  showToast: (message: string, top?: number) => void;
}

const ToastContext = React.createContext<ToastContextType>({
  showToast: () => '',
});

export default ToastContext;
