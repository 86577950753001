import { useCallback, useContext, useEffect, useState } from 'react';
import MetricOptionsContext from '../contexts/MetricOptionsContext';
import isDefined from '../isDefined';
import metricTypeCheckers from '../types/metricTypeCheckers';
import _ from 'lodash';

const useUsedMetrics = (chartDef: V5ChartDefinition) => {
  const { metricOptionsLookup } = useContext(MetricOptionsContext);
  const getUsedMetrics = useCallback((): {
    directlyUsed: Metrics.Metric[];
    inputsForCompounds: Metrics.NormalMetric[];
    usedMetrics: Metrics.Metric[];
  } => {
    const directlyUsed = _.uniqBy(
      chartDef.series
        .map((s) => s.metricId)
        .map((mId) => metricOptionsLookup[mId])
        .filter(isDefined),
      'id',
    );
    const inputsForCompounds = _.uniqBy(
      directlyUsed
        .filter(metricTypeCheckers.isCompoundMetric)
        .reduce((acc, cm) => {
          const inputs = cm.metricIds
            .map((mid) => metricOptionsLookup[mid])
            .filter(isDefined)
            .filter(metricTypeCheckers.isNormalMetric);
          return [...acc, ...inputs];
        }, [] as Metrics.NormalMetric[]),
      'id',
    );

    return {
      directlyUsed,
      inputsForCompounds,
      usedMetrics: [...directlyUsed, ...inputsForCompounds],
    };
  }, [chartDef.series, metricOptionsLookup]);
  const [usedMetrics, setUsedMetrics] = useState<{
    directlyUsed: Metrics.Metric[];
    inputsForCompounds: Metrics.NormalMetric[];
    usedMetrics: Metrics.Metric[];
  }>(() => getUsedMetrics());

  useEffect(() => {
    setUsedMetrics(getUsedMetrics());
  }, [getUsedMetrics]);

  return usedMetrics;
};

export default useUsedMetrics;
