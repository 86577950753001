import React from 'react';
import ErrorBoundary from '../Common/ErrorBoundary';
import Card from '../Common/Card';
import RemindersGadget from '../RemindersGadget';

const RemindersGadgetPreview = ({
  gadget,
}: {
  gadget: VisualisationDefinitions.Reminders;
}) => (
  <ErrorBoundary>
    <Card>
      <RemindersGadget gadget={gadget} />
    </Card>
  </ErrorBoundary>
);

export default RemindersGadgetPreview;
