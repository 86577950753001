import React from 'react';
import { MOCK_USERS } from '../__mocks__/mockUsers';

interface UsersContextType {
  allUsers: UserManagement.User[];
  isLoading: boolean;
}

const UsersContext = React.createContext<UsersContextType>({
  allUsers: process.env.NODE_ENV === 'test' ? MOCK_USERS : [],
  isLoading: true,
});

export default UsersContext;
