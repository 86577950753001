import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import createGoal from '../createGoal';
import GeneralGoalForm from './GeneralGoalForm';
import GoalsContext from '../../../contexts/GoalsContext';
import AnalyticsContext from '../../../contexts/AnalyticsContext';
import CurrentUserContext from '../../../contexts/CurrentUserContext';
import { DateTime } from 'luxon';
import isDefined from '../../../isDefined';
import { useDataTypesFromMetricId } from '../../../hooks/useDataTypesFromSeriesAndMetricListItems';
import NotificationFactory from '../../../NotificationFactory';
import NotificationsContext from '../../../contexts/NotificationsContext';
import AccountPickerContext from '../../../contexts/AccountPickerContext';
import useUsers from '../../../hooks/useUsers';
import ReportDrillDownsProvider from '../../../contextProviders/ReportDrillDownsProvider';
import useCopyVisHelpers from '../../../hooks/useCopyVisHelpers';
import useContentSharedEmails from '../../../hooks/useContentSharedEmails';
import _ from 'lodash';

const aguid = require('aguid');

interface Props {
  close: () => void;
  goal?: GeneralGoal;
  isCopyingGoal?: boolean;
}

export const useCollaboratorNames = (userIds: string[]) => {
  const users = useUsers();

  return userIds
    .map((u) => users.find((user) => user.id === u))
    .filter(isDefined)
    .map((u) => u.displayName)
    .join(', ');
};

const GeneralGoalFormContainer = ({ close, goal, isCopyingGoal }: Props) => {
  const isEditing = !!goal && !isCopyingGoal;
  const { selectedAccountId } = useContext(AccountPickerContext);
  const { updateGoal } = useContext(GoalsContext);
  const currentUser = useContext(CurrentUserContext);
  const { sendNotification } = useContext(NotificationsContext);

  const [id] = useState<string>(() => {
    if (goal && !isCopyingGoal) {
      return goal.id;
    } else {
      return aguid();
    }
  });
  const [title, setTitle] = useState<string>(goal ? goal.title : '');
  const [metricId, setMetricId] = useState<string | undefined>(
    goal ? goal.metricId : undefined,
  );
  const [drillDowns, setDrillDowns] = useState<FilterPlate[]>(
    goal ? goal.drillDowns : [],
  );
  const [kpis, setKpis] = useState<GoalKPI[]>(goal ? goal.kpis : []);
  const [cadence, setCadence] = useState<FleetOps.Interval>(
    goal ? goal.cadence : 'month',
  );
  const [cadenceOptions, setCadenceOptions] = useState<RadioOption[]>([]);
  useEffect(() => {
    setCadenceOptions(
      ['week' as 'week', 'month' as 'month'].map((c) => ({
        key: c,
        isSelected: cadence === c,
        onSelected: () => {
          setCadence(c);
        },
        label: _.startCase(c),
        value: c,
        name: 'cadence',
      })),
    );
  }, [cadence]);
  const [aboveTargetIsGood, setAboveTargetIsGood] = useState(
    goal ? goal.aboveTargetIsGood : true,
  );
  const [aboveTargetIsGoodOptions, setAboveTargetIsGoodOptions] = useState<
    RadioOption[]
  >([]);
  useEffect(() => {
    setAboveTargetIsGoodOptions([
      {
        label: 'Good',
        onSelected: () => setAboveTargetIsGood(true),
        key: 'good',
        isSelected: aboveTargetIsGood,
      },
      {
        label: 'Bad',
        onSelected: () => setAboveTargetIsGood(false),
        key: 'bad',
        isSelected: !aboveTargetIsGood,
      },
    ]);
  }, [aboveTargetIsGood]);

  const [targetValue, setTargetValue] = useState<number | undefined>(
    goal ? goal.target : undefined,
  );
  const [advancedTarget, setAdvancedTarget] = useState<GoalAdvancedTarget>(
    goal ? goal.advancedTarget : {},
  );
  const [targetMode, setTargetMode] = useState<
    'basic' | 'advanced' | undefined
  >(goal ? goal.targetMode : 'basic');
  const [fixedStartDate, setFixedStartDate] = useState<string>(
    goal && goal.fixedStartDate
      ? goal.fixedStartDate
      : DateTime.local().startOf('year').toISODate(),
  );
  const [fixedEndDate, setFixedEndDate] = useState<string>(
    goal && goal.fixedEndDate
      ? goal.fixedEndDate
      : DateTime.local().endOf('year').toISODate(),
  );
  const [dateField, setDateField] = useState<string | undefined>(
    goal ? goal.dateField : undefined,
  );
  const [visualiseAs, setVisualiseAs] = useState<'line' | 'bar'>(
    goal ? goal.visualiseAs : 'line',
  );
  const [visualiseAsOptions, setVisualiseAsOptions] = useState<RadioOption[]>(
    [],
  );
  useEffect(() => {
    setVisualiseAsOptions([
      {
        label: 'Line chart',
        onSelected: () => setVisualiseAs('line'),
        key: 'line',
        isSelected: visualiseAs === 'line',
      },
      {
        label: 'Bar chart',
        onSelected: () => setVisualiseAs('bar'),
        key: 'bar',
        isSelected: visualiseAs === 'bar',
      },
    ]);
  }, [visualiseAs]);

  const [accountableId, setAccountableId] = useState<string[]>(
    goal ? [goal.accountableId] : [],
  );
  const [collaboratorIds, setCollaboratorIds] = useState<string[]>(
    goal ? goal.collaboratorIds : [],
  );
  const [draftGoal, setDraftGoal] = useState<GeneralGoal | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dataTypes = useDataTypesFromMetricId(metricId);
  const { generateNewGoalDef } = useCopyVisHelpers();

  const { trackEvent } = useContext(AnalyticsContext);
  const collaboratorNames = useCollaboratorNames(collaboratorIds);
  const accountableName = useCollaboratorNames(accountableId);
  const { sendContentSharedEmails } = useContentSharedEmails();

  useEffect(() => {
    trackEvent('Goal Form - Opened');
  }, [trackEvent]);

  useEffect(() => {
    if (fixedStartDate === '') {
      setFixedStartDate(DateTime.local().startOf('year').toISODate());
    }

    if (fixedEndDate === '') {
      setFixedEndDate(DateTime.local().endOf('year').toISODate());
    }
  }, [fixedEndDate, fixedStartDate]);

  const onTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const isValid =
    !!metricId &&
    title !== '' &&
    accountableId !== undefined &&
    !!fixedStartDate &&
    !!fixedEndDate &&
    fixedStartDate !== '' &&
    fixedEndDate !== '' &&
    accountableId.length === 1 &&
    ((targetMode === 'basic' && targetValue !== undefined) ||
      (targetMode === 'advanced' && Object.keys(advancedTarget).length > 0));

  useEffect(() => {
    if (!isValid || metricId === undefined || targetMode === undefined) {
      setDraftGoal(undefined);
      return;
    }

    setDraftGoal({
      type: 'General Goal',
      version: '3',
      id,
      title,
      metricId,
      target: targetValue,
      drillDowns,
      cadence,
      kpis,
      aboveTargetIsGood,
      fixedStartDate,
      fixedEndDate,
      visualiseAs,
      targetMode,
      advancedTarget,
      dateField,
      createdBy: goal && !isCopyingGoal ? goal.createdBy : currentUser.id,
      createdOn:
        goal && !isCopyingGoal ? goal.createdOn : DateTime.utc().toISO(),
      updatedBy: currentUser.id,
      updatedOn: DateTime.utc().toISO(),
      accountableId: accountableId[0],
      collaboratorIds,
    });
  }, [
    aboveTargetIsGood,
    accountableId,
    advancedTarget,
    cadence,
    collaboratorIds,
    currentUser.id,
    dateField,
    drillDowns,
    fixedEndDate,
    fixedStartDate,
    goal,
    id,
    isCopyingGoal,
    isValid,
    kpis,
    metricId,
    targetMode,
    targetValue,
    title,
    visualiseAs,
  ]);

  const sendCreationNotifications = useCallback(
    async (newGoal: GeneralGoal) => {
      if (!draftGoal) {
        return;
      }
      const accountableN = NotificationFactory.madeAccountableForGoal({
        editorName: currentUser.displayName,
        goal: draftGoal,
      });
      const collaboratorN = NotificationFactory.addedAsCollaboratorOfGoal({
        editorName: currentUser.displayName,
        goal: draftGoal,
      });

      await sendNotification(accountableN, newGoal.accountableId);
      await sendContentSharedEmails({
        type: 'Assigned List',
        initialAssigned: undefined,
        newAssigned: [draftGoal.accountableId, ...draftGoal.collaboratorIds],
      });
      await Promise.all(
        newGoal.collaboratorIds.map((cId) =>
          sendNotification(collaboratorN, cId),
        ),
      );
    },
    [
      currentUser.displayName,
      draftGoal,
      sendContentSharedEmails,
      sendNotification,
    ],
  );

  const sendUpdatedNotifications = useCallback(
    async (newGoal: GeneralGoal, oldGoal: GeneralGoal) => {
      if (!draftGoal) {
        return;
      }
      const accountableN = NotificationFactory.madeAccountableForGoal({
        editorName: currentUser.displayName,
        goal: draftGoal,
      });
      const collaboratorN = NotificationFactory.addedAsCollaboratorOfGoal({
        editorName: currentUser.displayName,
        goal: draftGoal,
      });

      if (newGoal.accountableId !== oldGoal.accountableId) {
        await sendNotification(accountableN, newGoal.accountableId);
      }
      await sendContentSharedEmails({
        type: 'Assigned List',
        initialAssigned: [oldGoal.accountableId, ...oldGoal.collaboratorIds],
        newAssigned: [draftGoal.accountableId, ...draftGoal.collaboratorIds],
      });
      await Promise.all(
        newGoal.collaboratorIds
          .filter((cId) => !oldGoal.collaboratorIds.includes(cId))
          .map((cId) => sendNotification(collaboratorN, cId)),
      );
    },
    [
      currentUser.displayName,
      draftGoal,
      sendContentSharedEmails,
      sendNotification,
    ],
  );

  const onSubmit = useCallback(async () => {
    if (isLoading) {
      return;
    }
    if (!draftGoal) {
      return;
    }
    setIsLoading(true);
    if (isEditing && !isCopyingGoal && goal) {
      trackEvent('Goal Form - Edit Saved');
      updateGoal(draftGoal).then(() => {
        sendUpdatedNotifications(draftGoal, goal).then(() => {
          setIsLoading(false);
          close();
        });
      });
    } else if (isCopyingGoal) {
      trackEvent('Goal Form - Created');
      const newGoalDef = await generateNewGoalDef(draftGoal);
      createGoal({
        goal: newGoalDef,
        accountId: selectedAccountId,
      }).then(() => {
        sendCreationNotifications(newGoalDef).then(() => {
          setIsLoading(false);
          close();
        });
      });
    } else {
      trackEvent('Goal Form - Created');
      createGoal({
        goal: draftGoal,
        accountId: selectedAccountId,
      }).then(() => {
        sendCreationNotifications(draftGoal).then(() => {
          setIsLoading(false);
          close();
        });
      });
    }
  }, [
    isLoading,
    draftGoal,
    isEditing,
    isCopyingGoal,
    goal,
    trackEvent,
    updateGoal,
    sendUpdatedNotifications,
    close,
    generateNewGoalDef,
    selectedAccountId,
    sendCreationNotifications,
  ]);

  const onClose = () => {
    if (isEditing) {
      trackEvent('Goal Form - Edit Cancelled');
    } else {
      trackEvent('Goal Form - Creation Cancelled');
    }
    close();
  };

  return (
    <ReportDrillDownsProvider
      drillDowns={drillDowns}
      setDrillDowns={setDrillDowns}
      dataTypes={dataTypes}
    >
      <GeneralGoalForm
        metricId={metricId}
        setMetricId={setMetricId}
        title={title}
        onTitleChange={onTitleChange}
        targetValue={targetValue}
        setTargetValue={setTargetValue}
        aboveTargetIsGoodOptions={aboveTargetIsGoodOptions}
        visualiseAsOptions={visualiseAsOptions}
        kpis={kpis}
        setKpis={setKpis}
        cadence={cadence}
        cadenceOptions={cadenceOptions}
        fixedStartDate={fixedStartDate}
        setFixedStartDate={setFixedStartDate}
        fixedEndDate={fixedEndDate}
        setFixedEndDate={setFixedEndDate}
        accountableId={accountableId}
        collaboratorIds={collaboratorIds}
        setCollaboratorIds={setCollaboratorIds}
        isValid={isValid}
        isLoading={isLoading}
        close={onClose}
        onSubmit={onSubmit}
        isEditing={isEditing}
        collaboratorNames={collaboratorNames || ''}
        setAccountableId={setAccountableId}
        accountableName={accountableName || ''}
        targetMode={targetMode}
        setTargetMode={setTargetMode}
        advancedTarget={advancedTarget}
        setAdvancedTarget={setAdvancedTarget}
        dateField={dateField}
        setDateField={setDateField}
        dataTypes={dataTypes}
      />
    </ReportDrillDownsProvider>
  );
};

export default GeneralGoalFormContainer;
