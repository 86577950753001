import { useContext, useState } from 'react';
import _ from 'lodash';
import SingleUseMetricPopupContext from '../../../../../contexts/SingleUseMetricPopupContext';
import useNetworkingEffect from '../../../../../hooks/useNetworkingEffect';

const useEditingState = ({
  selectedMetric,
  metricDraft,
}: {
  selectedMetric?: Metrics.NormalMetric | Metrics.CompoundMetric;
  metricDraft?: Metrics.NormalMetric | Metrics.CompoundMetric;
}): EditingState => {
  const { isSaveToDataManager, isSingleUsePopup } = useContext(
    SingleUseMetricPopupContext,
  );
  const [state, setState] = useState<EditingState>('no changes');

  useNetworkingEffect(() => {
    setState(() => {
      if (!metricDraft) {
        return 'invalid form';
      }

      if (!selectedMetric) {
        return 'new metric';
      }

      if (isSingleUsePopup && isSaveToDataManager) {
        return 'has changes';
      }

      if (
        _.isEqual(selectedMetric, {
          ...metricDraft,
          updatedBy: selectedMetric.updatedBy,
          updatedOn: selectedMetric.updatedOn,
        })
      ) {
        // No changes
        if (selectedMetric.status === 'archived') {
          return 'no changes - archived';
        }

        return 'no changes';
      } else {
        // Has changed
        if (selectedMetric.status === 'archived') {
          return 'has changes - archived';
        }

        return 'has changes';
      }
    });
  }, [isSaveToDataManager, isSingleUsePopup, metricDraft, selectedMetric]);

  return state;
};

export default useEditingState;
