import firebase from 'firebase/compat/app';
import STORE_CONSTANTS from '../constants';
import withoutNulls from '../../api/search/withoutNulls';

export const DATASET_FILTER_DOC = 'datasetFilters';
export const CURRENT_DATASET_FILTER_VERSION = 'v1';

const datasetFilterConverter = {
  toFirestore(databaseFilter: DatasetFilter): firebase.firestore.DocumentData {
    return withoutNulls({ ...databaseFilter });
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ): DatasetFilter {
    const data = snapshot.data(options);
    return data as DatasetFilter;
  },
};

const getDatasetFilterRef = ({ accountId }: { accountId: string }) => {
  return firebase
    .firestore()
    .collection('accounts')
    .doc(accountId)
    .collection(STORE_CONSTANTS.FILTER_DEFINITIONS_COLLECTION)
    .doc(DATASET_FILTER_DOC)
    .collection(CURRENT_DATASET_FILTER_VERSION)
    .withConverter(datasetFilterConverter);
};

export default getDatasetFilterRef;
