import React from 'react';
import useDriverScore from '../hooks/useDriverScore';
import Loading from '../components/Loading';
import Typography from 'kingpin/atoms/Typography';
import DriverScoreContext from '../contexts/DriverScoreContext';

const DriverScoreProvider = ({
  driver,
  bonusPeriod,
  children,
}: {
  driver: string;
  bonusPeriod: BonusPeriod;
  children: JSX.Element | JSX.Element[];
}) => {
  const { driverScore, isLoading, setDriverScore } = useDriverScore({
    driver,
    bonusPeriod,
  });

  if (isLoading) {
    return <Loading />;
  }

  if (!driverScore) {
    return (
      <Typography.Body type="Body 12">
        Bonus Information not found
      </Typography.Body>
    );
  }

  return (
    <DriverScoreContext.Provider
      value={{
        driverScore,
        setDriverScore,
      }}
    >
      {children}
    </DriverScoreContext.Provider>
  );
};

export default DriverScoreProvider;
