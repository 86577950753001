import React from 'react';

interface GridRowCountContextType {
  rowCount: number;
}

const GridRowCountContext = React.createContext<GridRowCountContextType>({
  rowCount: 0,
});

export default GridRowCountContext;
