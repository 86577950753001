import React, { useCallback, useContext, useEffect, useState } from 'react';
import toSentenceCase from '../../../services/toSentenceCase';
import isDefined from '../../../isDefined';
import getIdentifier from '../../../getIdentifier';
import BaseViewsContext from '../../../contexts/BaseViewsContext';
import useGetFilteredAndSortedMetrics from './useGetFilteredAndSortedMetrics';
import MetricOptionsContext from '../../../contexts/MetricOptionsContext';
import useNetworkingEffect from '../../../hooks/useNetworkingEffect';
import AnalyticsContext from '../../../contexts/AnalyticsContext';
import metricTypeCheckers from '../../../types/metricTypeCheckers';

const useFilterOptions = ({
  filters,
  setFilters,
  isDatasetFilterDisabled,
  mode,
}: {
  filters: MetricFilters;
  setFilters: React.Dispatch<React.SetStateAction<MetricFilters>>;
  isDatasetFilterDisabled: boolean;
  mode: 'Metric' | 'CompoundMetric';
}) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const { metricOptionsNoSpecials } = useContext(MetricOptionsContext);
  const { baseViews, getDatasetLabel } = useContext(BaseViewsContext);
  const getFilteredAndSortedMetrics = useGetFilteredAndSortedMetrics();

  const [statusOptions, setStatusOptions] = useState<DropdownOption[]>([]);
  const [datasetOptions, setDatasetOptions] = useState<DropdownOption[]>([]);
  const [usageOptions, setUsageOptions] = useState<DropdownOption[]>([]);
  const [filterPlates, setFilterPlates] = useState<
    {
      key: string;
      label: string;
      onRemoved: () => void;
    }[]
  >([]);
  const [coreLabel, setCoreLabel] = useState<string>('Core');
  const [archivedLabel, setArchivedLabel] = useState<string>('Archived');

  useNetworkingEffect(() => {
    const filter = {
      ...filters,
      status: 'core',
    };
    const coreMetrics = getFilteredAndSortedMetrics({
      metrics: metricOptionsNoSpecials,
      filters: filter,
      sortMode: 'Alphabetical',
    }).filter((m) => {
      if (mode === 'Metric') {
        return metricTypeCheckers.isNormalMetric(m);
      } else if (mode === 'CompoundMetric') {
        return metricTypeCheckers.isCompoundMetric(m);
      }
      return false;
    });

    setCoreLabel(`Core (${coreMetrics.length})`);
  }, [filters, getFilteredAndSortedMetrics, metricOptionsNoSpecials, mode]);

  useNetworkingEffect(() => {
    const filter = {
      ...filters,
      status: 'archived',
    };
    const archivedMetrics = getFilteredAndSortedMetrics({
      metrics: metricOptionsNoSpecials,
      filters: filter,
      sortMode: 'Alphabetical',
    }).filter((m) => {
      if (mode === 'Metric') {
        return metricTypeCheckers.isNormalMetric(m);
      } else if (mode === 'CompoundMetric') {
        return metricTypeCheckers.isCompoundMetric(m);
      }
      return false;
    });

    setArchivedLabel(`Archived (${archivedMetrics.length})`);
  }, [filters, getFilteredAndSortedMetrics, metricOptionsNoSpecials, mode]);

  const onStatusSelected = useCallback(
    (status: Metrics.MetricStatus | undefined) => {
      setFilters((currentFilters) => {
        const currentStatus = currentFilters.status;
        if (currentStatus !== status) {
          return {
            ...currentFilters,
            status: status,
          };
        }

        return {
          ...currentFilters,
          status: undefined,
        };
      });
    },
    [setFilters],
  );

  const onDatasetSelected = useCallback(
    (dataset: string) => {
      trackEvent('Metrics - Dataset filter applied');

      setFilters((currentFilters) => {
        const currentDataset = currentFilters.dataset;
        if (currentDataset !== dataset) {
          return {
            ...currentFilters,
            dataset,
          };
        }

        return {
          ...currentFilters,
          dataset: undefined,
        };
      });
    },
    [setFilters, trackEvent],
  );

  const onIsUsedSelected = useCallback(
    (isUsed: boolean) => {
      trackEvent('Metrics - Usage filter applied');

      setFilters((currentFilters) => {
        const isAlreadySelected = filters.isUsed === isUsed;

        if (isAlreadySelected) {
          return {
            ...currentFilters,
            isUsed: undefined,
          };
        }

        return {
          ...currentFilters,
          isUsed,
        };
      });
    },
    [filters.isUsed, setFilters, trackEvent],
  );

  useEffect(() => {
    const newOptions = [
      {
        label: 'Core & Other Metrics',
        value: undefined,
        onSelected: () => {
          onStatusSelected(undefined);
          trackEvent('Metrics - Status filter applied');
        },
      },
      {
        label: coreLabel,
        value: 'Core',
        isSelected: filters.status === 'core',
        onSelected: () => {
          onStatusSelected('core');
          trackEvent('Metrics - Status filter applied');
        },
      },
      {
        label: archivedLabel,
        value: 'Archived',
        isSelected: filters.status === 'archived',
        onSelected: () => {
          onStatusSelected('archived');
          trackEvent('Metrics - Status filter applied');
        },
      },
    ];

    setStatusOptions(newOptions);
  }, [archivedLabel, coreLabel, filters.status, onStatusSelected, trackEvent]);

  useEffect(() => {
    const newOptions = Object.values(baseViews)
      .filter(isDefined)
      .map((view) => ({
        label: getDatasetLabel(view.type),
        value: view.type,
        onSelected: () => onDatasetSelected(view.type),
        isSelected: !!filters.dataset && filters.dataset === view.type,
      }));

    setDatasetOptions(newOptions);
  }, [baseViews, filters.dataset, getDatasetLabel, onDatasetSelected]);

  useEffect(() => {
    const newOptions = [
      {
        label: 'In use',
        value: 'in use',
        onSelected: () => onIsUsedSelected(true),
        isSelected: filters.isUsed === undefined ? false : filters.isUsed,
      },
      {
        label: 'Not In Use',
        value: 'not in use',
        onSelected: () => onIsUsedSelected(false),
        isSelected: filters.isUsed === undefined ? false : !filters.isUsed,
      },
    ];

    setUsageOptions(newOptions);
  }, [filters.isUsed, onIsUsedSelected]);

  useEffect(() => {
    const { isUsed, status, dataset } = filters;

    const newPlates = [];
    if (isUsed !== undefined) {
      newPlates.push({
        key: getIdentifier(),
        label: isUsed ? 'In use' : 'Not in use',
        onRemoved: () => onIsUsedSelected(isUsed),
      });
    }
    if (status !== undefined) {
      newPlates.push({
        key: getIdentifier(),
        label: toSentenceCase(status),
        onRemoved: () => onStatusSelected(status),
      });
    }
    if (dataset !== undefined && !isDatasetFilterDisabled) {
      newPlates.push({
        key: getIdentifier(),
        label: getDatasetLabel(dataset),
        onRemoved: () => onDatasetSelected(dataset),
      });
    }
    setFilterPlates(newPlates);
  }, [
    filters,
    getDatasetLabel,
    isDatasetFilterDisabled,
    onDatasetSelected,
    onIsUsedSelected,
    onStatusSelected,
  ]);

  return {
    statusOptions,
    datasetOptions,
    usageOptions,
    filterPlates,
  };
};

export default useFilterOptions;
