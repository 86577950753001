import Typography from 'kingpin/atoms/Typography';
import React from 'react';

import styled from 'styled-components';
import Row from '../../../../../../components/Common/Row';
import { ReactComponent as WarningIcon } from './WarningIcon.svg';
import Colors2 from '../../../../../../theme/Colors2';

const WarningDiv = styled.div`
  background-color: ${Colors2.AvatarColors['8'].background};
  padding: 18px 16px;
  margin-bottom: 16px;
  border-radius: 6px;
`;

const CurrentUserLosingAccess = () => {
  return (
    <WarningDiv>
      <Row>
        <div style={{ marginRight: 12 }}>
          <WarningIcon />
        </div>
        <div>
          <div style={{ marginBottom: 4 }}>
            <Typography.Header type="H5">
              You are not included in the added group(s)
            </Typography.Header>
          </div>
          <div>
            <Typography.Body type="Body 12">
              You cannot proceed until you have been added
            </Typography.Body>
          </div>
        </div>
      </Row>
    </WarningDiv>
  );
};

export default CurrentUserLosingAccess;
