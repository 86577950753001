import React from 'react';
import styled from 'styled-components';
import Typography from 'kingpin/atoms/Typography';

import { Section } from '../../../../../../components/MyAccount/Profile/styles';
import Colors2 from '../../../../../../theme/Colors2';
import usePerformanceConfigurationDependencies from '../usePerformanceConfigurationDependencies';
import Icon from '../../../../../../kingpin/atoms/Icon';

const Banner = styled.div<{ backgroundColor: string }>`
  border-radius: 4px;
  background-color: ${(props) => props.backgroundColor};
  padding: 10px 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const PerformanceMetricWarning = ({
  metric,
}: {
  metric?: Metrics.NormalMetric | Metrics.CompoundMetric;
}) => {
  const { usageCount } = usePerformanceConfigurationDependencies(metric);
  if (usageCount === 0) {
    return null;
  }

  const color = Colors2.AvatarColors['8'];
  return (
    <Section>
      <Banner
        backgroundColor={color.background}
        style={{ alignItems: 'center' }}
      >
        <div style={{ marginRight: 8 }}>
          <Icon icon="warning" />
        </div>

        <Typography.Body type="Body 12">
          Edits will affect {usageCount} Datasets
        </Typography.Body>
      </Banner>
    </Section>
  );
};

export default PerformanceMetricWarning;
