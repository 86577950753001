import { useCallback, useContext, useEffect, useState } from 'react';
import CloudFunctionClientContext from '../../../../../contexts/CloudFunctionClientContext';
import getConfiguration from '../api/getConfiguration';

const useConfiguration = () => {
  const [config, setConfig] = useState<MicrosoftSso.MicrosoftConfig>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { usEastApi: api } = useContext(CloudFunctionClientContext);

  useEffect(() => {
    getConfiguration(api).then((response) => {
      setConfig(response.data);
      setIsLoading(false);
    });
  }, [api]);

  const refreshConfig = useCallback(() => {
    setIsLoading(true);
    getConfiguration(api).then((response) => {
      setConfig(response.data);
      setIsLoading(false);
    });
  }, [api]);

  return {
    isLoading,
    config,
    refreshConfig,
  };
};

export default useConfiguration;
