import React from 'react';
import Typography from 'kingpin/atoms/Typography';
import Colors2 from '../../../theme/Colors2';
import Row from '../../Common/Row';
import styled from 'styled-components';
import Loading from '../../Loading/Loading';
import getUserDisplayName from '../../../contextProviders/UsersProvider/getUserDisplayName';

const LinkButton = styled.div`
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const BaseDataToggleButton = ({
  isExcluded,
  excludedByUser,
  onExcludeClicked,
  onIncludeClicked,
  isLoading,
  isInteractive,
}: {
  isExcluded: boolean;
  excludedByUser?: UserManagement.User;
  onExcludeClicked: () => void;
  onIncludeClicked: () => void;
  isLoading: boolean;
  isInteractive: boolean;
}) => (
  <Row style={{ justifyContent: 'flex-end', marginRight: 12 }}>
    {isLoading && <Loading isTiny />}
    {!isLoading && isInteractive && (
      <LinkButton onClick={isExcluded ? onIncludeClicked : onExcludeClicked}>
        {isExcluded && (
          <>
            <Typography.Body color={Colors2.Secondary.info} type="Body 12">
              {excludedByUser
                ? `Include (Excluded by ${getUserDisplayName(excludedByUser)})`
                : 'Include'}
            </Typography.Body>
          </>
        )}
      </LinkButton>
    )}
    {!isLoading && !isInteractive && (
      <>
        {isExcluded && (
          <Typography.Body type="Body 12">
            {excludedByUser
              ? `Include (Excluded by ${getUserDisplayName(excludedByUser)})`
              : 'Excluded'}
          </Typography.Body>
        )}
        {!isExcluded && (
          <Typography.Body type="Body 12">Included</Typography.Body>
        )}
      </>
    )}
  </Row>
);

export default BaseDataToggleButton;
