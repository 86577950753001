import React, { useCallback, useContext } from 'react';
import CopyGadgetButton from './CopyGadgetButton';
import CopyGadgetContext from '../../contexts/CopyGadgetContext';
import DashboardContext from '../../contexts/DashboardContext';
import ReportContext from '../../contexts/ReportContext';
import DashboardGadgetContext from '../../contexts/DashboardGadgetContext';
import CardContext from '../../contexts/CardContext';
import ReportDrillDownsContext from '../../contexts/ReportDrillDownsContext';
import ComparisonContext from '../../contexts/ComparisonContext';
import MetricListContext from '../../contexts/MetricListContext';
import WorkSpaceContext from '../../contexts/WorkSpaceContext';
import AnalyticsContext from '../../contexts/AnalyticsContext';
import DateInputContext from '../../contexts/DateInputContext';
import getGadgetType from '../../getGadgetType';
import PermissionGates from '../PermissionGates';
import PERMISSIONS from '../../permissionDefinitions';
import ContextMenuContext from '../../kingpin/atoms/ContextMenu/ContextMenuContext';

const CopyGadgetButtonContainer = () => {
  const { trackEvent } = useContext(AnalyticsContext);
  const { hideMenu } = useContext(ContextMenuContext);
  const { dashboard } = useContext(DashboardContext);
  const { report } = useContext(ReportContext);
  const { workSpace } = useContext(WorkSpaceContext);
  const { currentComparison } = useContext(ComparisonContext);
  const { dashboardGadget } = useContext(DashboardGadgetContext);
  const { chartDefinition, w, h } = useContext(CardContext);
  const { metricList } = useContext(MetricListContext);
  const { drillDowns, scope } = useContext(ReportDrillDownsContext);
  const { dateRange, dateField, relativeDateRange, advancedRelativeDateRange } =
    useContext(DateInputContext);

  const { startCopyDashboardGadget, startCopyReportGadget } =
    useContext(CopyGadgetContext);

  const onClick = useCallback(() => {
    if (!!dashboard && !!report) {
      return;
    }
    const gadgetType = (() => {
      if (dashboardGadget) {
        return getGadgetType(dashboardGadget.chartDef);
      }

      if (chartDefinition) {
        return getGadgetType(chartDefinition);
      }

      if (metricList) {
        return 'MetricList';
      }

      return '';
    })();
    trackEvent('Copy Gadget - Button Pressed', {
      currentResourceType: dashboard ? 'Dashboard' : 'Report',
      currentResourceId: dashboard ? dashboard.id : report ? report.id : '...',
      gadgetType,
    });

    if (!!dashboard && !!dashboardGadget) {
      const g = {
        ...dashboardGadget,
        relativeDateRange,
        dateRange,
        advancedRelativeDateRange: advancedRelativeDateRange,
      };
      if (workSpace && workSpace.campaignType === 'driverBonus') {
        g.isBonusPeriodMode = true;
      }
      startCopyDashboardGadget(g, w, h, dashboard);
      hideMenu();
      return;
    }

    if (!!dashboard && !!metricList) {
      const l = {
        ...metricList,
        dateRange,
        relativeDateRange,
        advancedRelativeDateRange,
      };
      startCopyDashboardGadget(l, w, h, dashboard);
      hideMenu();
      return;
    }

    if (!!report && !!chartDefinition) {
      startCopyReportGadget({
        chartDef: chartDefinition,
        drillDowns,
        scope,
        dateField: dateField ? dateField : 'date',
        dateRange,
        advancedRelativeDateRange,
        relativeDateRange,
        comparison: currentComparison,
        w,
        h,
        reportId: report.id,
        weekStartsOnOverride: report.weekStartsOnOverride,
      });
      hideMenu();
      return;
    }
  }, [
    dashboard,
    report,
    trackEvent,
    dashboardGadget,
    metricList,
    chartDefinition,
    relativeDateRange,
    dateRange,
    advancedRelativeDateRange,
    workSpace,
    startCopyDashboardGadget,
    w,
    h,
    hideMenu,
    startCopyReportGadget,
    drillDowns,
    scope,
    dateField,
    currentComparison,
  ]);

  if (!!dashboard && !!report) {
    return null;
  }
  const requiredPermission = report
    ? PERMISSIONS.REPORT_MANAGEMENT.COPY_GADGET_TO_DASHBOARD
    : PERMISSIONS.DASHBOARDS.EDIT;

  return (
    <PermissionGates.Has requiredPermission={requiredPermission}>
      <CopyGadgetButton onClick={onClick} />
    </PermissionGates.Has>
  );
};

export default CopyGadgetButtonContainer;
