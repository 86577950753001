import React, { useEffect, useState } from 'react';
import FilterMenu from './FilterMenu';
import usePopup from '../../../../../../hooks/usePopup';

const FilterMenuContainer = ({
  filteredMetrics,
}: {
  filteredMetrics: (Metrics.NormalMetric | Metrics.CompoundMetric)[];
}) => {
  const { isOpen, open, close } = usePopup();
  const [step, setStep] = useState<
    'Filter by' | 'Datasets' | 'Status' | 'Usage'
  >('Filter by');

  useEffect(() => {
    setStep('Filter by');
  }, [isOpen]);

  useEffect(() => {
    close();
  }, [close, filteredMetrics]);

  return (
    <FilterMenu
      isOpen={isOpen}
      open={open}
      close={close}
      step={step}
      setStep={setStep}
    />
  );
};

export default FilterMenuContainer;
