import React, { useContext, useEffect, useState } from 'react';
import DateInputContext from '../contexts/DateInputContext';
import BonusPeriodsContext from '../contexts/BonusPeriodsContext';
import WorkSpaceContext from '../contexts/WorkSpaceContext';
import useDashboardGadgetDataTypes from '../hooks/useDashboardGadgetDataTypes';
import { toDateRange } from '../contexts/BoardContext';
import { parseSavedDateRange } from '../components/ConfigureDashboardGadget';
import migrateRelativeDateRange from '../migrateRelativeDateRange';
import Loading from '../components/Loading';
import ComparisonContext from '../contexts/ComparisonContext';
import withDateFilter from '../hocs/withDateFIlter';
import useDataTypesFromSeriesAndMetricListItems from '../hooks/useDataTypesFromSeriesAndMetricListItems';
import PeriodsContext from '../contexts/PeriodsContext';
import WeekStartsOnOverrideContext from '../contexts/WeekStartsOnOverrideContext';

const isMetricList = (
  gadget: DashboardGadget | MetricListGadgetType,
): gadget is MetricListGadgetType => {
  // @ts-ignore
  return gadget.list !== undefined;
};

const useSetInitialDateFilters = (
  gadget: DashboardGadget | MetricListGadgetType,
) => {
  const {
    setDateField,
    setDateRange,
    setRelativeDateRange,
    setAdvancedRelativeDateRange,
    setDataTypes,
  } = useContext(DateInputContext);
  const { selectedBonusPeriod } = useContext(BonusPeriodsContext);
  const { selectedPeriod } = useContext(PeriodsContext);

  const { workSpace } = useContext(WorkSpaceContext);
  const [isInitialDateSet, setIsInitialDateSet] = useState<boolean>(false);
  const usedDataTypes = isMetricList(gadget)
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useDataTypesFromSeriesAndMetricListItems(gadget.list)
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useDashboardGadgetDataTypes(gadget);

  useEffect(() => {
    const initialDateRange = (() => {
      if (selectedPeriod) {
        return {
          startDate: selectedPeriod.startDate,
          endDate: selectedPeriod.endDate,
        };
      }

      const bonusDateRange = selectedBonusPeriod
        ? toDateRange(selectedBonusPeriod)
        : undefined;

      if (
        bonusDateRange &&
        !!workSpace &&
        workSpace.campaignType === 'driverBonus'
      ) {
        return bonusDateRange;
      }

      if (bonusDateRange && gadget.isBonusPeriodMode) {
        return bonusDateRange;
      }
      return parseSavedDateRange(gadget.dateRange);
    })();

    setDateRange(initialDateRange);
    setRelativeDateRange(
      gadget.relativeDateRange
        ? migrateRelativeDateRange(gadget.relativeDateRange)
        : undefined,
    );
    setAdvancedRelativeDateRange(gadget.advancedRelativeDateRange);
    setDateField(gadget.dateField || 'date');

    setIsInitialDateSet(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDataTypes(usedDataTypes);
  }, [setDataTypes, usedDataTypes]);

  return isInitialDateSet;
};

const useBonusDateSync = (gadget: DashboardGadget | MetricListGadgetType) => {
  const {
    setDateRange,
    setDateField,
    setRelativeDateRange,
    setAdvancedRelativeDateRange,
  } = useContext(DateInputContext);
  const { workSpace } = useContext(WorkSpaceContext);
  const { selectedBonusPeriod } = useContext(BonusPeriodsContext);
  const { selectedPeriod } = useContext(PeriodsContext);

  useEffect(() => {
    const bonusDateRange = selectedBonusPeriod
      ? toDateRange(selectedBonusPeriod)
      : undefined;

    if (selectedPeriod) {
      setDateRange({
        startDate: selectedPeriod.startDate,
        endDate: selectedPeriod.endDate,
      });
      setDateField('date');
      setRelativeDateRange(undefined);
      setAdvancedRelativeDateRange(undefined);
    } else if (
      bonusDateRange &&
      !!workSpace &&
      workSpace.campaignType === 'driverBonus'
    ) {
      setDateRange(bonusDateRange);
      setDateField(bonusDateRange.dateField || 'date');
      setRelativeDateRange(undefined);
      setAdvancedRelativeDateRange(undefined);
    } else if (bonusDateRange && gadget.isBonusPeriodMode) {
      setDateRange(bonusDateRange);
      setDateField(bonusDateRange.dateField || 'date');
      setRelativeDateRange(undefined);
      setAdvancedRelativeDateRange(undefined);
    } else {
      setDateRange(parseSavedDateRange(gadget.dateRange));
      setDateField(gadget.dateField);
      setRelativeDateRange(gadget.relativeDateRange);
      setAdvancedRelativeDateRange(gadget.advancedRelativeDateRange);
    }
  }, [
    gadget.advancedRelativeDateRange,
    gadget.dateField,
    gadget.dateRange,
    gadget.isBonusPeriodMode,
    gadget.relativeDateRange,
    selectedBonusPeriod,
    selectedPeriod,
    setAdvancedRelativeDateRange,
    setDateField,
    setDateRange,
    setRelativeDateRange,
    workSpace,
  ]);
};

const DashboardGadgetDateScopeProvider = ({
  children,
  dashboardGadget,
}: {
  children: JSX.Element | JSX.Element[];
  dashboardGadget: DashboardGadget | MetricListGadgetType;
}) => {
  const isInitialDateSet = useSetInitialDateFilters(dashboardGadget);
  useBonusDateSync(dashboardGadget);
  const [currentComparison, setCurrentComparison] = useState<
    PersistedComparisonType | undefined
  >(dashboardGadget.comparison);

  useEffect(() => {
    setCurrentComparison(dashboardGadget.comparison);
  }, [dashboardGadget.comparison]);

  if (!isInitialDateSet) {
    return <Loading />;
  }

  return (
    <WeekStartsOnOverrideContext.Provider
      value={{
        weekStartsOnOverride: dashboardGadget.weekStartsOnOverride,
      }}
    >
      <ComparisonContext.Provider
        value={{ currentComparison, setCurrentComparison }}
      >
        {children}
      </ComparisonContext.Provider>
    </WeekStartsOnOverrideContext.Provider>
  );
};

export default withDateFilter(DashboardGadgetDateScopeProvider, {});
