import React from 'react';
import Row from 'components/Common/Row';
import { ArrowElem } from 'components/Gadget/Delta';
import Typography from 'kingpin/atoms/Typography';
import useValueFormatters from 'hooks/useValueFormatters';
import Colors from '../../../../theme/colors';

const TargetProgress = ({
  isReversed,
  successThreshold,
  value,
  metric,
  manualKpiFormatting,
  isShort,
}: {
  isReversed: boolean;
  successThreshold: number | undefined;
  value: number | undefined;
  metric: Metrics.Metric | undefined;
  manualKpiFormatting: MetricFormatting | undefined;
  isShort?: boolean;
}) => {
  const { formatMetric, formatValue } = useValueFormatters();
  if (value === undefined || successThreshold === undefined) {
    return isShort ? <Typography.Body type="Body 12">-</Typography.Body> : null;
  }

  const isGood = isReversed
    ? value <= successThreshold
    : value > successThreshold;

  const delta = value - successThreshold;
  const diff = Math.abs(value - successThreshold);

  const formattedDiff = metric
    ? formatMetric({ metricId: metric.id, value: diff })
    : formatValue({ value: diff, formatting: manualKpiFormatting || {} });

  return isGood ? (
    <Row centerAlign>
      <ArrowElem isGood={isGood} delta={delta} isGrid={true} />
      <Typography.Body type="Body 12" color={Colors.MEDIUM_SEA_GREEN}>
        {isShort
          ? formattedDiff
          : `${formattedDiff} ${isReversed ? 'Below' : 'Above'} Target`}
      </Typography.Body>
    </Row>
  ) : (
    <Row centerAlign>
      <ArrowElem isGood={isGood} delta={delta} isGrid={true} />
      <Typography.Body type="Body 12" color={Colors.RADICAL_RED}>
        {isShort
          ? formattedDiff
          : `${formattedDiff} ${isReversed ? 'Above' : 'Below'} Target`}
      </Typography.Body>
    </Row>
  );
};

export default TargetProgress;
