import React from 'react';

interface ConfigureDashboardGadgetContextType {
  isOpen: boolean;
  open: (dashboardGadget: DashboardGadget) => void;
  close: () => void;
}

const ConfigureDashboardGadgetContext =
  React.createContext<ConfigureDashboardGadgetContextType>({
    isOpen: false,
    open: () => {},
    close: () => {},
  });

export default ConfigureDashboardGadgetContext;
