import React, { useContext } from 'react';
import Typography from 'kingpin/atoms/Typography';
import { Col } from './KpiGrid';
import useKpiValue from '../hooks/useKpiValue';
import useKpiFormatter from '../hooks/useKpiFormatter';
import useValueFormatters from 'hooks/useValueFormatters';
import TargetProgress from './TargetProgress';
import { getColName } from '../ColumnHeadingsRight';
import ScorecardContext from 'contexts/ScorecardContext';
import useTargetBands from '../hooks/useTargetBands';

const GridTargets = ({
  kpi,
  trend,
  metric,
  period,
}: {
  kpi: Scorecards.ScorecardKpi | Scorecards.ManualKpiRow;
  trend: Goals.TrendResult[];
  metric?: Metrics.Metric;
  period: Period;
}) => {
  const { scorecard } = useContext(ScorecardContext);
  const { formatMetric, formatValue } = useValueFormatters();
  const getKpiValue = useKpiValue();
  const value = getKpiValue({ trend, period });
  const { formattedValue, manualKpiFormatting } = useKpiFormatter({
    kpi,
    value,
    metric,
  });
  const { successThreshold } = useTargetBands(kpi, period);
  return (
    <>
      <Col>
        <Typography.Body type="Body 12" isEllipsis>
          {getColName(period, scorecard.cadence)}
        </Typography.Body>
      </Col>
      <Col>
        <Typography.Body type="Body 12" isEllipsis>
          {successThreshold
            ? metric
              ? formatMetric({ metricId: metric.id, value: successThreshold })
              : formatValue({
                  value: successThreshold,
                  formatting: manualKpiFormatting || {},
                })
            : '-'}
        </Typography.Body>
      </Col>
      <Col>
        <Typography.Body type="Body 12" isEllipsis>
          {formattedValue || '-'}
        </Typography.Body>
      </Col>
      <Col isWide>
        <TargetProgress
          isReversed={kpi.isColorOrderReversed}
          successThreshold={successThreshold}
          value={value}
          metric={metric}
          manualKpiFormatting={manualKpiFormatting}
          isShort
        />
      </Col>
    </>
  );
};

export default GridTargets;
