import buildContentViewId, {
  buildContentViewIdAlt,
} from '../../buildContentViewId';
import firebase from 'firebase/compat/app';

const updateRecentName = async ({
  type,
  typeId,
  userId,
  newName,
  accountRef,
}: {
  type: 'report' | 'dashboard' | 'wallboard' | 'workspace';
  typeId: string;
  userId: string;
  newName: string;
  accountRef: firebase.firestore.DocumentReference;
}): Promise<void> => {
  const recentsRef = accountRef
    .collection('users')
    .doc(userId)
    .collection('navigation')
    .doc('recents');
  const recentsDoc = await recentsRef.get();

  if (recentsDoc.exists) {
    const data = recentsDoc.data();
    if (data) {
      const currentRecents = data.recents as ContentView[];
      const newRecents = currentRecents.map((r) => {
        if (buildContentViewId(r) === buildContentViewIdAlt({ type, typeId })) {
          return {
            ...r,
            name: newName,
          };
        } else {
          return r;
        }
      });
      return recentsRef.set({
        recents: newRecents,
      });
    }
  }
};

export default updateRecentName;
