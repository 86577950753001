import React from 'react';
import styled from 'styled-components';

import Colors2 from '../theme/Colors2';
import Row from './Common/Row';
import Typography from 'kingpin/atoms/Typography';

const Wrapper = styled.div<{ isDisabled?: boolean }>`
  padding: 0px 16px;
  cursor: pointer;
  text-align: center;
  max-width: 200px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  background-color: ${Colors2.Secondary['warning']};
  border-radius: 3px;

  height: 30px;

  &:hover {
    opacity: 0.7;
  }

  ${(props) => props.isDisabled && 'opacity: 0.5;'}
`;

const WarningButton = ({
  text,
  onClick,
  isDisabled,
  isLoading,
  icon,
  testId,
}: {
  text?: string;
  onClick: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  icon?: JSX.Element;
  testId?: string;
}) => (
  <Wrapper
    onClick={isDisabled ? undefined : onClick}
    isDisabled={isDisabled}
    data-testid={testId}
  >
    {!isLoading && (
      <Row centerAlign>
        {icon}
        <Typography.Body type="Button Text" isEllipsis>
          {text}
        </Typography.Body>
      </Row>
    )}
    {isLoading && (
      <Typography.Body type="Button Text">Loading...</Typography.Body>
    )}
  </Wrapper>
);

export default WarningButton;
