import React, { useCallback, useState } from 'react';
import BulkGridActionsContext from 'contexts/BulkGridActionsContext';

const BulkGridActionsProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [isBulkActionLoading, setIsBulkActionLoading] =
    useState<boolean>(false);
  const onBulkActionCommitted = useCallback(() => {
    setIsBulkActionLoading(true);
  }, []);

  return (
    <BulkGridActionsContext.Provider
      value={{
        selectedItems,
        setSelectedItems,
        isBulkActionLoading,
        onBulkActionCommitted,
      }}
    >
      {children}
    </BulkGridActionsContext.Provider>
  );
};

export default BulkGridActionsProvider;
