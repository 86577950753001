import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import getTimeStamp from 'getTimeStamp';
import getIdentifier from 'getIdentifier';
import CurrentUserContext from 'contexts/CurrentUserContext';
import usePerformanceSlideOutFilterInput from 'components/PerformanceBoards/hooks/usePerformanceSlideOutFilterInput';
import useFullDataInterval from 'components/PerformanceBoards/hooks/useFullDataInterval';
import useSaveWidget from 'components/PerformanceBoards/hooks/useSaveWidget';
import MetricSlideoutWizard from './MetricSlideoutWizard';

export const WidgetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 32px;
`;

const MetricSlideoutWizardContainer = ({
  close,
  editingSection,
}: {
  close: () => void;
  editingSection: PerformanceBoardTypes.SlideOut.MetricsSection | undefined;
}) => {
  const { id: currentUserId } = useContext(CurrentUserContext);

  const [id] = useState<string>(
    editingSection ? editingSection.id : getIdentifier(),
  );
  const [createdBy] = useState<string>(
    editingSection ? editingSection.createdBy : currentUserId,
  );
  const [createdOn] = useState<string>(
    editingSection ? editingSection.createdOn : getTimeStamp(),
  );
  const [title, setTitle] = useState<string>(
    editingSection ? editingSection.title : '',
  );
  const [metricIds, setMetricIds] = useState<string[]>(
    editingSection ? editingSection.metricIds : [],
  );
  const [widgetDateRange, setWidgetDateRange] =
    useState<PerformanceBoardTypes.SlideOut.SlideOutDateRange>({
      mode: editingSection ? editingSection.dateRange.mode : 'Current Interval',
      n:
        editingSection && editingSection.dateRange.mode === 'Last'
          ? editingSection.dateRange.n
          : 1,
      isToDate: editingSection ? editingSection.dateRange.isToDate : false,
    });
  const [metricSection, setMetricSection] = useState<
    PerformanceBoardTypes.SlideOut.MetricsSection | undefined
  >();

  const filterInput = usePerformanceSlideOutFilterInput();
  const { dateScope } = useFullDataInterval(widgetDateRange);
  const saveWidget = useSaveWidget({
    section: metricSection,
    editingSection,
  });

  const isDisabled =
    metricIds.length === 0 ||
    title === '' ||
    (!widgetDateRange.n && widgetDateRange.mode === 'Last');

  const onTitleChanged = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setTitle(event.target.value);
  };

  useEffect(() => {
    if (isDisabled) {
      setMetricSection(undefined);
      return;
    }

    const newMetricSection = {
      id: id,
      createdBy: createdBy,
      updatedBy: currentUserId,
      createdOn: createdOn,
      updatedOn: getTimeStamp(),
      sectionType: 'Metric Widget' as 'Metric Widget',
      title: title,
      metricIds: metricIds,
      dateRange: widgetDateRange,
    };

    setMetricSection(newMetricSection);
  }, [
    createdBy,
    createdOn,
    currentUserId,
    id,
    isDisabled,
    metricIds,
    title,
    widgetDateRange,
  ]);

  return (
    <MetricSlideoutWizard
      saveWidget={saveWidget}
      title={title}
      onTitleChanged={onTitleChanged}
      widgetDateRange={widgetDateRange}
      setWidgetDateRange={setWidgetDateRange}
      isDisabled={isDisabled}
      filterInput={filterInput}
      dateScope={dateScope}
      metricIds={metricIds}
      setMetricIds={setMetricIds}
      metricSection={metricSection}
      close={close}
    />
  );
};

export default MetricSlideoutWizardContainer;
