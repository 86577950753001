import React, { useContext, useState } from 'react';
import DetailsSlideOut from './DetailsSlideOut';
import DetailsSlideOutContext from '../../contexts/DetailsSlideOutContext';
import OrderDetailsProvider from '../../contextProviders/OrderDetailsProvider';
import ErrorBoundary from '../Common/ErrorBoundary';

const DetailsSlideOutContainer = () => {
  const { selectedOrderNumber } = useContext(DetailsSlideOutContext);
  const [activeNavElement, setActiveNavElement] =
    useState<SlideOutNavElement>('summary');
  const onNavScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const elem = event.currentTarget;
    const summaryElem = document.getElementById('details-slide-summary');
    const timelineElem = document.getElementById('details-slide-timeline');
    const commentsElem = document.getElementById('details-slide-comments');

    if (!summaryElem || !timelineElem || !commentsElem) {
      return;
    }

    if (
      elem.scrollTop >=
      summaryElem.clientHeight + timelineElem.clientHeight
    ) {
      setActiveNavElement('comments');
    } else if (elem.scrollTop >= summaryElem.clientHeight) {
      setActiveNavElement('timeline');
    } else {
      setActiveNavElement('summary');
    }
  };

  if (!selectedOrderNumber) {
    return null;
  }

  return (
    <OrderDetailsProvider orderNumber={selectedOrderNumber}>
      <DetailsSlideOut
        activeNavElement={activeNavElement}
        onNavScroll={onNavScroll}
      />
    </OrderDetailsProvider>
  );
};

const Gate = () => {
  return (
    <ErrorBoundary>
      <DetailsSlideOutContainer />
    </ErrorBoundary>
  );
};

export default Gate;
