import { useCallback, useContext } from 'react';
import store from 'store';
import AccountPickerContext from 'contexts/AccountPickerContext';
import SlideOutContext from '../contexts/SlideOutContext';
import CurrentUserContext from 'contexts/CurrentUserContext';
import getTimeStamp from 'getTimeStamp';

const useSaveWidget = ({
  section,
  editingSection,
}: {
  section: PerformanceBoardTypes.SlideOut.SectionWidgets | undefined;
  editingSection: PerformanceBoardTypes.SlideOut.SectionWidgets | undefined;
}) => {
  const { selectedAccountId } = useContext(AccountPickerContext);
  const currentUser = useContext(CurrentUserContext);
  const { board, setNavState, flash } = useContext(SlideOutContext);

  const saveWidget = useCallback(() => {
    if (!section) {
      return;
    }

    const newSlideout = (() => {
      if (editingSection) {
        flash.setFlashMessage(`${section.title} Edited`);
        return board.slideout.map((s) => {
          if (s.id === editingSection.id) {
            return section;
          }
          return s;
        });
      }
      flash.setFlashMessage(`${section.title} Added`);
      return [{ ...section }, ...board.slideout];
    })();

    const newBoard = {
      ...board,
      slideout: newSlideout,
      updatedBy: currentUser.id,
      updatedOn: getTimeStamp(),
    };
    store.contentDistributions
      .getPerformanceBoardsRef({ accountId: selectedAccountId })
      .doc(board.id)
      .set(newBoard)
      .then(() => {
        setNavState({
          slideoutMode: 'Home',
        });
        flash.setIsVisible(true);
      });
  }, [
    board,
    currentUser.id,
    editingSection,
    flash,
    section,
    selectedAccountId,
    setNavState,
  ]);

  return saveWidget;
};

export default useSaveWidget;
