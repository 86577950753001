import React, {
  ComponentType,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';

import usePopup from '../../../hooks/usePopup';
import MetricOptionsContext from '../../../contexts/MetricOptionsContext';
import { DropdownButton } from '../Dropdown';
import MetricPickerPopup from './MetricPickerPopup';
import InlineDialog from '../../InlineDialog';
import ListPicker from '../ListPicker';
import MetricEditor from './MetricEditor';
import useCanEditMetric from './useCanEditMetric';
import AnalyticsContext from '../../../contexts/AnalyticsContext';
import metricTypeCheckers from '../../../types/metricTypeCheckers';

const Positioner = styled.div`
  .WpXpw {
    min-width: 330px;
    max-width: unset;
  }
`;

const MetricPicker = ({
  metricId,
  setMetricId,
  noSpecials,
  setMetricDisplayName,
  testIdPrefix,
  datasetMustIncludeField,
  placeholder,
  onMetricSelected,
  metricIdsToConstrainBy,
  Button,
  isShowAllDatasetsButtonDisabled,
  metricIdRedList,
  aggFuncRedList,
  aggFuncGreenList,
  datasetRedList,
  fieldRedList,
}: {
  metricId?: string;
  setMetricId:
    | React.Dispatch<React.SetStateAction<string | undefined>>
    | ((newMetricId: string) => void);
  noSpecials?: boolean;
  setMetricDisplayName?: React.Dispatch<React.SetStateAction<string>>;
  testIdPrefix?: string;
  datasetMustIncludeField?: string;
  placeholder?: string;
  onMetricSelected?: (metric?: Metrics.Metric) => void;
  metricIdsToConstrainBy?: string[];
  Button?: ComponentType<{ onClick: () => void }>;
  isShowAllDatasetsButtonDisabled?: boolean;
  metricIdRedList?: string[];
  aggFuncRedList?: AggFunc[];
  aggFuncGreenList?: AggFunc[];
  datasetRedList?: string[];
  fieldRedList?: string[];
}) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const { isOpen, open, close } = usePopup();
  const {
    isOpen: isMetricPickerOpen,
    open: openMetricPicker,
    close: closeMetricPicker,
  } = usePopup();
  const {
    isOpen: isMetricEditorOpen,
    open: openMetricEditor,
    close: closeMetricEditor,
  } = usePopup();
  const { metricOptions, metricOptionsNoSpecials } =
    useContext(MetricOptionsContext);
  const getSelectedMetric = useCallback(() => {
    return metricId
      ? (noSpecials ? metricOptionsNoSpecials : metricOptions).find(
          (m) => m.id === metricId,
        )
      : undefined;
  }, [metricId, metricOptions, metricOptionsNoSpecials, noSpecials]);
  const [selectedMetric, setSelectedMetric] = useState<
    Metrics.Metric | undefined
  >(() => getSelectedMetric());
  useEffect(() => {
    setSelectedMetric(getSelectedMetric());
  }, [
    getSelectedMetric,
    metricId,
    metricOptions,
    metricOptionsNoSpecials,
    noSpecials,
  ]);
  const canEditMetric = useCanEditMetric(selectedMetric);

  const onSelected = useCallback(
    (m: Metrics.Metric) => {
      trackEvent('Metric Picker - Metric Selected');
      setMetricId(m.id);
      if (setMetricDisplayName) {
        setMetricDisplayName(m.name);
      }
      if (onMetricSelected) {
        onMetricSelected(m);
      }
      close();
    },
    [close, onMetricSelected, setMetricDisplayName, setMetricId, trackEvent],
  );

  const onEditMetricClicked = useCallback(() => {
    openMetricEditor();
    trackEvent('Metric Picker - Edit Metric Clicked');
    close();
  }, [close, openMetricEditor, trackEvent]);
  const onChangeSelectedMetricClicked = useCallback(() => {
    openMetricPicker();
    close();
  }, [close, openMetricPicker]);
  const onDropdownClicked = useCallback(() => {
    if (isOpen) {
      close();
      return;
    }

    if (selectedMetric) {
      open();
    } else {
      onChangeSelectedMetricClicked();
    }
  }, [close, isOpen, onChangeSelectedMetricClicked, open, selectedMetric]);
  const [options, setOptions] = useState<
    {
      label: string;
      onClick: () => void;
      isDisabled?: boolean;
      tooltip?: string;
    }[]
  >([]);
  useEffect(() => {
    if (canEditMetric) {
      setOptions([
        {
          label: 'Change Selected Metric',
          onClick: onChangeSelectedMetricClicked,
        },
        { label: 'Edit Metric', onClick: onEditMetricClicked },
      ]);
      return;
    }

    setOptions([
      {
        label: 'Change Selected Metric',
        onClick: onChangeSelectedMetricClicked,
      },
      {
        label: 'Edit Metric',
        onClick: window.tokenFunction,
        isDisabled: true,
        tooltip: metricTypeCheckers.isSpecialMetric(selectedMetric)
          ? 'This metric is managed by FleetOps Support and cannot be edited'
          : 'You do not have the permission to edit this',
      },
    ]);
  }, [
    canEditMetric,
    onChangeSelectedMetricClicked,
    onEditMetricClicked,
    selectedMetric,
  ]);

  return (
    <Positioner>
      <InlineDialog
        isOpen={isOpen}
        onClose={close}
        content={<ListPicker options={options} />}
      >
        <>
          {!!Button && <Button onClick={onDropdownClicked} />}
          {Button === undefined && (
            <DropdownButton
              testId={
                testIdPrefix ? `${testIdPrefix}-metric-picker` : 'metric-picker'
              }
              open={onDropdownClicked}
              isOpen={isOpen}
              close={close}
              selectedLabel={selectedMetric ? selectedMetric.name : undefined}
              placeholder={placeholder ? placeholder : 'Select a metric'}
              isPlaceholder={selectedMetric === undefined}
              unsetWidth
            />
          )}
        </>
      </InlineDialog>
      <MetricPickerPopup
        isOpen={isMetricPickerOpen}
        close={closeMetricPicker}
        setSelectedMetric={onSelected}
        selectedMetric={selectedMetric}
        datasetMustIncludeField={datasetMustIncludeField}
        metricIdRedList={metricIdRedList}
        metricIdsToConstrainBy={metricIdsToConstrainBy}
        aggFuncRedList={aggFuncRedList}
        aggFuncGreenList={aggFuncGreenList}
        datasetRedList={datasetRedList}
        fieldRedList={fieldRedList}
        isShowAllDatasetsButtonDisabled={isShowAllDatasetsButtonDisabled}
        noSpecial={noSpecials}
      />
      <MetricEditor
        isOpen={isMetricEditorOpen}
        close={closeMetricEditor}
        selectedMetric={selectedMetric}
        singleUseEditCancelButtonText={'Cancel'}
      />
    </Positioner>
  );
};

export default MetricPicker;
