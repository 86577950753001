import { useContext } from 'react';
import ComparisonContext from '../contexts/ComparisonContext';
import isSingleMetricDateMatrix from '../types/visTypeCheckers/isSingleMetricDateMatrix';
import isV5ChartDef from '../types/visTypeCheckers/isV5ChartDef';
import isPaceMatrix from '../types/visTypeCheckers/isPaceMatrix';
import { ALLOWED_PACE_OPTIONS } from '../components/PaceMatrix/PaceMatrixContent/constants';

const useIsCompatibleComparison = (gadget: VisualisationDefinition) => {
  const { currentComparison } = useContext(ComparisonContext);

  if (isSingleMetricDateMatrix(gadget)) {
    return currentComparison && currentComparison.compareType === 'compareTo';
  }

  if (isPaceMatrix(gadget)) {
    return (
      currentComparison &&
      currentComparison.compareType === 'compareTo' &&
      currentComparison.relativeDateOption &&
      ALLOWED_PACE_OPTIONS.includes(currentComparison.relativeDateOption)
    );
  }

  if (
    isV5ChartDef(gadget) &&
    gadget.dimensionB &&
    gadget.dimensionB.fieldType === 'date'
  ) {
    const hasCompareCells = gadget.series.some(
      (s) => s.matrixCellType !== undefined && s.matrixCellType !== 'none',
    );
    if (!hasCompareCells) {
      return true;
    }

    return (
      currentComparison === undefined ||
      (currentComparison && currentComparison.compareType === 'previous')
    );
  }

  return true;
};

export default useIsCompatibleComparison;
