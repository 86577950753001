import { useContext, useEffect, useState } from 'react';
import CostsWizardContext from '../../context/CostsWizardContext';
import useCategories from '../../CostsShow/hooks/useCategories';

const useCategoriesOptions = () => {
  const { wizardState, setWizardState } = useContext(CostsWizardContext);
  const [options, setOptions] = useState<DropdownOption[]>([]);
  const { allCategories, isLoading } = useCategories();

  useEffect(() => {
    if (allCategories) {
      // don't show categories that are already picked
      const newOptions = allCategories
        .filter((cat) => {
          if (
            wizardState.config.categoriesStep.categories.find(
              (stateCat) =>
                stateCat.fieldName === cat.fieldName &&
                stateCat.name === cat.name,
            )
          ) {
            return false;
          } else {
            return cat;
          }
        })
        .map((cat) => ({
          value: cat.fieldName,
          label: cat.name,
          isSelected: false,
          onSelected: async () => {
            setWizardState((s) => ({
              ...s,
              config: {
                ...s.config,
                categoriesStep: {
                  categories: [...s.config.categoriesStep.categories, cat],
                },
              },
            }));
          },
        }));
      setOptions(newOptions);
    }
  }, [
    allCategories,
    setWizardState,
    wizardState.config.categoriesStep.categories,
  ]);
  return {
    options,
    isLoading,
  };
};

export default useCategoriesOptions;
