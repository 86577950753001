import { useEffect, useState } from 'react';
import useGetIsExistingMetric from './useGetIsExistingMetric';

const useIsExistingMetric = (
  performanceMetric: PerformanceConfiguration.Client.PerformanceMetric,
) => {
  const getIsExistingMetric = useGetIsExistingMetric();
  const [isExistingMetric, setIsExistingMetric] = useState<boolean>(() =>
    getIsExistingMetric(performanceMetric),
  );
  useEffect(() => {
    setIsExistingMetric(getIsExistingMetric(performanceMetric));
  }, [getIsExistingMetric, performanceMetric, setIsExistingMetric]);

  return isExistingMetric;
};

export default useIsExistingMetric;
