const COPY_GADGET_TO_DASHBOARD: FleetOps.Permission =
  'fleetops.permissions.report_management_copy_gadget_to_dashboard';
const EDIT_LAYOUT: FleetOps.Permission =
  'fleetops.permissions.report_management_edit_layout';
const ADD_REMOVE_VISUALISATIONS: FleetOps.Permission =
  'fleetops.permissions.report_management_add_remove_visualisations';
const COPY: FleetOps.Permission = 'fleetops.permissions.report_management_copy';
const DELETE: FleetOps.Permission =
  'fleetops.permissions.report_management_delete';
const CREATE: FleetOps.Permission =
  'fleetops.permissions.report_management_create';

const REPORT_MANAGEMENT_PERMISSIONS = {
  COPY_GADGET_TO_DASHBOARD,
  EDIT_LAYOUT,
  ADD_REMOVE_VISUALISATIONS,
  COPY,
  DELETE,
  CREATE,
};
Object.freeze(REPORT_MANAGEMENT_PERMISSIONS);

export default REPORT_MANAGEMENT_PERMISSIONS;
