import React from 'react';
import { Link } from 'react-router-dom';
import Typography from 'kingpin/atoms/Typography';
import HighlightedText from './SearchResults/HighlightedText';
import useEntityLink from '../hooks/useEntityLink';
import GlobalSearchStyles from './styles';

const EntityRow = ({
  searchText,
  entity,
  match,
  onEntityNavigate,
}: {
  searchText: string;
  entity: EntityDetails.Entity;
  match: {
    primaryFieldValue: string;
    secondaryFields: {
      field: string;
      value: string | number;
      formattedValue: string | number;
    }[];
    contextFields: {
      field: string;
      value: string | number;
      formattedValue: string | number;
    }[];
  };
  onEntityNavigate: (entity: EntityDetails.Entity) => void;
}) => {
  const link = useEntityLink({ entity, value: match.primaryFieldValue });

  let primaryFieldComponent: JSX.Element;
  if (link) {
    const onClick = () => {
      onEntityNavigate(entity);
    };
    primaryFieldComponent = (
      <Link to={link} onClick={onClick}>
        <HighlightedText
          text={match.primaryFieldValue}
          searchText={searchText}
          isLink={true}
        />
      </Link>
    );
  } else {
    primaryFieldComponent = (
      <HighlightedText
        text={match.primaryFieldValue}
        searchText={searchText}
        isLink={false}
      />
    );
  }

  return (
    <GlobalSearchStyles.GridRow key={match.primaryFieldValue}>
      <GlobalSearchStyles.Col>{primaryFieldComponent}</GlobalSearchStyles.Col>
      {match.secondaryFields.map((f) => (
        <GlobalSearchStyles.Col key={f.field}>
          <Typography.Body type="Body 12">{f.formattedValue}</Typography.Body>
        </GlobalSearchStyles.Col>
      ))}
      {match.contextFields.map((f) => (
        <GlobalSearchStyles.Col key={f.field}>
          <Typography.Body type="Body 12">{f.formattedValue}</Typography.Body>
        </GlobalSearchStyles.Col>
      ))}
    </GlobalSearchStyles.GridRow>
  );
};

export default EntityRow;
