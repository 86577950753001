import React, { ChangeEvent, useState } from 'react';
import DOMPurify from 'dompurify';
import IFrameForm from './IFrameForm';
import buildCardLayout from 'contextProviders/WidgetGalleryProvider/buildCardLayout';
import findStartingY from 'contextProviders/WidgetGalleryProvider/findStartingY';

const IFrameFormContainer = ({
  close,
  isOpen,
  setCurrentCanvas,
  startEditing,
  canvasMode,
}: {
  close: () => void;
  isOpen: boolean;
  setCurrentCanvas: React.Dispatch<React.SetStateAction<Canvas>>;
  startEditing: () => void;
  canvasMode: CanvasMode;
}) => {
  const [snippet, setSnippet] = useState<string>('');

  const onSnippetChanged = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setSnippet(event.target.value);
  };
  const onSave = () => {
    setCurrentCanvas((c) => {
      const newCard = (() => {
        return {
          layout: buildCardLayout(0, findStartingY(c.cards, canvasMode)),
          content: {
            type: 'iFrame' as 'iFrame',
            iFrameSnippet: DOMPurify.sanitize(snippet, {
              ADD_TAGS: ['iframe'],
            }),
          },
        };
      })();

      return {
        ...c,
        cards: [...c.cards, newCard],
      };
    });
    startEditing();
  };

  return (
    <IFrameForm
      snippet={snippet}
      onSnippetChanged={onSnippetChanged}
      onSave={onSave}
      close={close}
      isOpen={isOpen}
    />
  );
};

export default IFrameFormContainer;
