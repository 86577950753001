import Has from './Has';
import HasAll from './HasAll';
import HasAny from './HasAny';

const PermissionGates = {
  Has,
  HasAll,
  HasAny,
};

export default PermissionGates;
