import React from 'react';
import ResetPasswordForm from '../../components/ResetPasswordForm';
import LandingPage from '../../components/LandingPage';

const ResetPassword = () => (
  <LandingPage>
    <ResetPasswordForm />
  </LandingPage>
);

export default ResetPassword;
