import React, { useContext, useEffect, useState } from 'react';

import Metric from './Metric';
import AccountContext from '../../../../contexts/AccountContext';
import useValueFormatters from '../../../../hooks/useValueFormatters';

const MetricContainer = ({
  metric,
  data,
}: {
  metric: Metrics.Metric;
  data: MetricsResponse;
}) => {
  const { unitsLocale } = useContext(AccountContext);
  const { formatMetric } = useValueFormatters();
  const [value, setValue] = useState<string>('...');

  useEffect(() => {
    const v = data[0][metric.id] as number;
    const formattedValue = formatMetric({ value: v, metricId: metric.id });
    setValue(formattedValue);
  }, [data, formatMetric, metric, metric.id, unitsLocale]);

  return <Metric value={value} label={metric.name} testId={metric.id} />;
};

export default MetricContainer;
