import React from 'react';
import Alert from '../../../kingpin/Alert';
import Row from '../../Common/Row';
import Icon from '../../../kingpin/atoms/Icon';
import Typography from '../../../kingpin/atoms/Typography';
import usePortalAccess from './usePortalAccess';

const PortalAccessAlert = ({
  type,
  typeId,
  user,
  contentName,
}: {
  type: 'report' | 'dashboard' | 'scorecard' | 'workSpace';
  typeId: string;
  user: UserManagement.SignedUpUser | UserManagement.PendingUser;
  contentName: string;
}) => {
  const accessPortals = usePortalAccess({ type, typeId, user });

  if (accessPortals.length === 0) {
    return null;
  }

  const accessMessage =
    accessPortals.length === 1
      ? `'${accessPortals[0].name}'`
      : `these portals; ${accessPortals.map((p) => `'${p.name}'`).join(', ')}`;

  return (
    <div style={{ marginTop: 24 }}>
      <Alert
        title={
          <Row>
            <div style={{ display: 'flex', marginRight: 8 }}>
              <Icon icon={'info'} width={16} height={16} />
            </div>
            <Typography.Body
              type={'Label'}
            >{`Please Note: ${user.displayName} still has access to '${contentName}' because it's part of ${accessMessage}, which ${user.displayName} can access.`}</Typography.Body>
          </Row>
        }
        type={'Info'}
      />
    </div>
  );
};

export default PortalAccessAlert;
