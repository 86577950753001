import firebase from 'firebase/compat/app';

const deleteComment = async (
  comment: CommentType,
  accountRef: firebase.firestore.DocumentReference,
) => {
  return accountRef.collection('comments').doc(comment.id).delete();
};

export default deleteComment;
