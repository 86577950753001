import usePortalAccess from './usePortalAccess';
import Icon from '../../../kingpin/atoms/Icon';
import Row from '../../Common/Row';
import React from 'react';
import Tooltip from '../../Tooltip';
import Typography from '../../../kingpin/atoms/Typography';

const UserOptionRight = ({
  user,
  type,
  typeId,
  isSelected,
}: {
  user: UserManagement.SignedUpUser | UserManagement.PendingUser;
  type: 'report' | 'dashboard' | 'scorecard' | 'workSpace';
  typeId: string;
  isSelected: boolean;
}) => {
  const accessPortals = usePortalAccess({ type, typeId, user });
  const portalsMessage = accessPortals.map((p) => p.name).join(', ');

  return (
    <Row centerAlign>
      <div
        style={
          accessPortals.length > 0
            ? { marginRight: 8 }
            : { visibility: 'hidden', marginRight: 8 }
        }
      >
        <Tooltip
          content={`This user is in the ${portalsMessage} portal${accessPortals.length > 1 ? 's' : ''} where this asset already exists`}
        >
          <Typography.Body
            type={'Button Text'}
            color="#0041EA"
            data-testid={
              accessPortals.length > 0
                ? `${user.displayName}-already-has-access`
                : undefined
            }
          >
            Already has Portal Access
          </Typography.Body>
        </Tooltip>
      </div>
      <div style={isSelected ? {} : { visibility: 'hidden' }}>
        <Icon icon={'checkmark'} />
      </div>
    </Row>
  );
};

export default UserOptionRight;
