import React from 'react';
import styled from 'styled-components';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import ContextMenu, { Option, Break } from 'kingpin/atoms/ContextMenu';
import Row from '../../../Common/Row';
import ShowOnMouseOverUnlessTour from '../../../ShowOnMouseOverUnlessTour';
import Button from 'kingpin/atoms/Button';
import Typography from 'kingpin/atoms/Typography';
import useMetric from '../../../../hooks/useMetric';
import KpiRowElement from '../KpiRowElement';
import { Col } from '../Kpis';
import PermissionGates from '../../../PermissionGates';
import PERMISSIONS from '../../../../permissionDefinitions';
import Colors2 from '../../../../theme/Colors2';

export const List = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;

  button {
    width: 100%;
  }
`;

const KpiName = ({
  kpi,
  hasMargin,
}: {
  kpi: Scorecards.ScorecardKpi;
  hasMargin: boolean;
}) => {
  const metric = useMetric(kpi.metricId);
  const name = kpi.displayName ? kpi.displayName : metric ? metric.name : '';

  return (
    <div
      style={{
        maxWidth: 'calc(100% - 16px)',
        marginLeft: hasMargin ? '8px' : undefined,
        overflow: 'hidden',
      }}
    >
      <Typography.Body type="Body 12" isEllipsis>
        {name}
      </Typography.Body>
    </div>
  );
};

const KpiRowLeft = ({
  dragHandleProps,
  kpi,
  onEditClicked,
  onDeleteClicked,
  isLastRow,
  isHovered,
  onRowHover,
}: {
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
  kpi: Scorecards.ScorecardKpi;
  onEditClicked: () => void;
  onDeleteClicked: () => void;
  isLastRow?: boolean;
  isHovered: boolean;
  onRowHover: () => void;
}) => (
  <KpiRowElement
    centerAlign
    style={{
      width: '100%',
      borderBottom: `1px solid ${Colors2.Border}`,
    }}
    isLastRow={isLastRow}
    isHovered={isHovered}
    onMouseEnter={onRowHover}
  >
    <Col maxWidth>
      <Row centerAlign spaceBetween style={{ width: '100%' }}>
        <Row centerAlign style={{ width: 'calc(100% - 24px)' }}>
          {!!dragHandleProps && (
            <div>
              <ShowOnMouseOverUnlessTour>
                <div {...dragHandleProps}>
                  <Button type="Ghost" size="Small" icon="drag" />
                </div>
              </ShowOnMouseOverUnlessTour>
            </div>
          )}
          <KpiName kpi={kpi} hasMargin={!dragHandleProps} />
        </Row>
        <div style={{ marginRight: 4, width: '24px' }}>
          <PermissionGates.Has
            requiredPermission={PERMISSIONS.SCORECARDS.CRUD_SCORECARD}
          >
            <ShowOnMouseOverUnlessTour isForcedVisible={isHovered}>
              <ContextMenu buttonType={'Ghost'}>
                <Option label={'Edit KPI'} onClick={onEditClicked} />
                <Break />
                <Option label={'Delete'} onClick={onDeleteClicked} isDanger />
              </ContextMenu>
            </ShowOnMouseOverUnlessTour>
          </PermissionGates.Has>
        </div>
      </Row>
    </Col>
  </KpiRowElement>
);

export default KpiRowLeft;
