import React from 'react';
import styled from 'styled-components';
import Button from 'kingpin/atoms/Button';
import Typography from 'kingpin/atoms/Typography';

import Row from '../Common/Row';
import ReasonCodeForm from './ReasonCodeForm';
import Form from '../../kingpin/forms/Form';
import FormHeader from '../../kingpin/forms/FormHeader';
import FormContent from '../../kingpin/forms/FormContent';
import Colors2 from '../../theme/Colors2';

const Item = styled.div`
  height: 52px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${Colors2.Border};
  padding: 0px 8px;
  border-radius: 8px;
`;

const ReasonCodes = ({
  reasonCodes,
  isAddingNewReasonCode,
  setIsAddingNewReasonCode,
  close,
  onDeleteCodeClicked,
  dataType,
}: {
  reasonCodes: ReasonCode[];
  isAddingNewReasonCode: boolean;
  setIsAddingNewReasonCode: React.Dispatch<React.SetStateAction<boolean>>;
  close: () => void;
  onDeleteCodeClicked: (code: ReasonCode) => void;
  dataType: string;
}) => {
  if (isAddingNewReasonCode) {
    return (
      <ReasonCodeForm
        close={() => setIsAddingNewReasonCode(false)}
        dataType={dataType}
      />
    );
  } else {
    return (
      <Form>
        <FormHeader title="Reason Codes" onClose={close} />
        <FormContent>
          <div style={{ marginBottom: 24 }}>
            {reasonCodes.map((r) => (
              <Item key={r.id}>
                <Typography.Body type={'Body 12'}>{r.code}</Typography.Body>
                <Button
                  size={'Small'}
                  type={'Secondary'}
                  onClick={() => onDeleteCodeClicked(r)}
                  icon="cross"
                />
              </Item>
            ))}
          </div>
        </FormContent>
        <Row style={{ justifyContent: 'space-between' }}>
          <Button onClick={close} size="Small" type="Ghost" label="Close" />
          <Button
            size="Small"
            type="Primary"
            label="Create new reason code"
            onClick={() => {
              setIsAddingNewReasonCode(true);
            }}
          />
        </Row>
      </Form>
    );
  }
};

export default ReasonCodes;
