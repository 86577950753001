import React from 'react';
import MetricStatusBadge from './MetricPopup/MetricActions/MetricStatusBadge';
import metricTypeCheckers from '../../../types/metricTypeCheckers';

const CoreBadge = ({ metric }: { metric: Metrics.Metric }) => {
  if (metricTypeCheckers.isSpecialMetric(metric)) {
    return null;
  }

  if (metric.status !== 'core') {
    return null;
  }

  return (
    <div style={{ marginRight: 8 }}>
      <MetricStatusBadge status={metric.status} />
    </div>
  );
};

export default CoreBadge;
