import React, { useCallback, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import CostsWizardContext, {
  DEFAULT_STATE,
} from '../context/CostsWizardContext';
import useStateTransformers from '../Settings/hooks/useStateTransformers';
import useCategories from '../CostsShow/hooks/useCategories';
import { RADIO_OPTIONS_MAP } from '../consts';
import CostsShowContext from '../context/CostsShowContext';
import useGetLastQuarter from '../Settings/hooks/useGetLastQuarter';
import useGetActivitiesDateFields from '../Settings/hooks/useGetActivitiesDateFields';
import Loading from 'components/Loading/Loading';
import RolesContext from 'contexts/RolesContext';
import PERMISSIONS from 'permissionDefinitions';

const CostsWizardProvider = ({
  children,
  initialState,
}: {
  children: JSX.Element | JSX.Element[];
  initialState?: Costs.PersistedCostModel;
}) => {
  const { currentPermissions } = useContext(RolesContext);
  const { intervalCosts, isLoadingCosts } = useContext(CostsShowContext);
  const { toWizard } = useStateTransformers();
  const { dateFields, isLoading: isDateFieldsLoading } =
    useGetActivitiesDateFields();
  const { allCategories, isLoading: isCategoriesLoading } = useCategories();
  const [defaultWizardState, setDefaultWizardState] =
    useState<Costs.WizardState>(_.cloneDeep(DEFAULT_STATE));
  const [wizardState, setWizardState] = useState<Costs.WizardState>(
    _.cloneDeep(DEFAULT_STATE),
  );
  const [persistedWizardState, setPersistedWizardState] =
    useState<Costs.WizardState>();

  const [isEditing, setIsEditing] = useState<boolean>(
    !!intervalCosts && !isLoadingCosts,
  );

  const [hasEditPermission, setHasEditPermission] = useState<boolean>(false);
  const getLastQuarter = useGetLastQuarter({ wizardState });

  useEffect(() => {
    if (!isLoadingCosts) {
      setIsEditing(!!intervalCosts);
    }
  }, [intervalCosts, isLoadingCosts]);

  useEffect(() => {
    if (isEditing && initialState && !isCategoriesLoading) {
      const initialWizardState = toWizard(initialState, allCategories);
      setWizardState(initialWizardState);
      setPersistedWizardState(initialWizardState);
    }
  }, [allCategories, initialState, isCategoriesLoading, isEditing, toWizard]);

  // Set Default startDate
  useEffect(() => {
    if (isEditing) {
      return;
    }
    const intervalModes = getLastQuarter().reverse();

    if (
      !defaultWizardState.config.basicStep.startDate &&
      intervalModes.length !== 0
    ) {
      setDefaultWizardState({
        ...defaultWizardState,
        config: {
          ...defaultWizardState.config,
          basicStep: {
            ...defaultWizardState.config.basicStep,
            startDate: intervalModes[0].value,
          },
        },
      });
    }
  }, [defaultWizardState, getLastQuarter, isEditing]);

  // Set Default activity date field
  useEffect(() => {
    if (isEditing) {
      return;
    }

    if (!defaultWizardState.config.basicStep.field && !isDateFieldsLoading) {
      setDefaultWizardState((s) => ({
        ...s,
        config: {
          ...s.config,
          basicStep: { ...s.config.basicStep, field: dateFields[0] },
        },
      }));
    }
  }, [
    dateFields,
    defaultWizardState.config.basicStep.field,
    isDateFieldsLoading,
    isEditing,
  ]);

  useEffect(() => {
    if (isEditing) {
      return;
    }

    if (
      defaultWizardState.config.basicStep.field &&
      !wizardState.config.basicStep.field
    ) {
      setWizardState((s) => ({
        ...s,
        config: {
          ...s.config,
          basicStep: {
            ...s.config.basicStep,
            field: defaultWizardState.config.basicStep.field,
          },
        },
      }));
    }

    if (
      defaultWizardState.config.basicStep.startDate &&
      !wizardState.config.basicStep.startDate
    ) {
      setWizardState({
        ...wizardState,
        config: {
          ...wizardState.config,
          basicStep: {
            ...wizardState.config.basicStep,
            startDate: defaultWizardState.config.basicStep.startDate,
          },
        },
      });
    }
  }, [
    defaultWizardState.config.basicStep.field,
    defaultWizardState.config.basicStep.startDate,
    isEditing,
    wizardState,
  ]);

  const getIsValid = useCallback(() => {
    const isValid =
      !!wizardState.config.basicStep.activityCostCalculationMode &&
      !!wizardState.config.basicStep.interval &&
      !!wizardState.config.basicStep.field &&
      !!wizardState.config.basicStep.startDate &&
      (wizardState.config.groupStep.type === RADIO_OPTIONS_MAP.evenly ||
        (wizardState.config.groupStep.field !== undefined &&
          !!wizardState.config.groupStep.groups &&
          wizardState.config.groupStep.groups.length !== 0)) &&
      wizardState.config.categoriesStep.categories.length !== 0;

    return isValid;
  }, [
    wizardState.config.basicStep.activityCostCalculationMode,
    wizardState.config.basicStep.field,
    wizardState.config.basicStep.interval,
    wizardState.config.basicStep.startDate,
    wizardState.config.categoriesStep.categories.length,
    wizardState.config.groupStep.field,
    wizardState.config.groupStep.groups,
    wizardState.config.groupStep.type,
  ]);

  useEffect(() => {
    const isValid = getIsValid();

    setWizardState((s) => ({
      ...s,
      isValid,
    }));
  }, [getIsValid]);

  const getHasBreakingChanges = useCallback(() => {
    if (!initialState) {
      return false;
    }

    // We should not change certain fields
    const hasBreakingFieldUpdates =
      wizardState.config.basicStep.interval !== initialState?.interval ||
      wizardState.config.basicStep.startOfWeek !== initialState?.startOfWeek ||
      wizardState.config.basicStep.startDate !== initialState?.startDate ||
      (wizardState.config.groupStep.field || null) !==
        initialState?.groupFieldName;

    const isOldASubsetOfNew = (() => {
      const newCategoryNames = wizardState.config.categoriesStep.categories.map(
        (c) => c.fieldName,
      );
      const newGroupNames = wizardState.config.groupStep.groups.map(
        (g) => g.groupName,
      );

      const oldCategoryNames = initialState.categories.map(
        (c) => c.costFieldName,
      );
      const oldGroupNames = initialState.groups.map((g) => g.groupName);

      return (
        oldGroupNames.every((oldGroupName) =>
          newGroupNames.some((ngn) => ngn === oldGroupName),
        ) &&
        oldCategoryNames.every((oldCategoryName) =>
          newCategoryNames.some((ngn) => ngn === oldCategoryName),
        )
      );
    })();

    const hasBreakingChanges = hasBreakingFieldUpdates || !isOldASubsetOfNew;

    return hasBreakingChanges;
  }, [
    initialState,
    wizardState.config.basicStep.interval,
    wizardState.config.basicStep.startDate,
    wizardState.config.basicStep.startOfWeek,
    wizardState.config.categoriesStep.categories,
    wizardState.config.groupStep.field,
    wizardState.config.groupStep.groups,
  ]);

  useEffect(() => {
    const hasBreakingChanges = getHasBreakingChanges();
    setWizardState((s) => ({
      ...s,
      hasBreakingChanges,
    }));
  }, [getHasBreakingChanges]);

  useEffect(() => {
    const newHasEditPermission = currentPermissions.includes(
      wizardState.isDraft
        ? PERMISSIONS.DATA_MANAGEMENT.ACTIVITY_COSTS_MANAGE_DRAFT
        : PERMISSIONS.DATA_MANAGEMENT.ACTIVITY_COSTS_MANAGE_PUBLISHED,
    );
    setHasEditPermission(newHasEditPermission);
  }, [currentPermissions, getHasBreakingChanges, wizardState.isDraft]);

  if (isLoadingCosts || isCategoriesLoading || isDateFieldsLoading) {
    return <Loading />;
  }

  return (
    <CostsWizardContext.Provider
      value={{
        wizardState,
        setWizardState,
        isEditing: !!initialState,
        persistedWizardState,
        defaultWizardState,
        hasEditPermission,
      }}
    >
      {children}
    </CostsWizardContext.Provider>
  );
};

export default CostsWizardProvider;
