import { useCallback, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import AccountContext from 'contexts/AccountContext';
import relativeDateRangeToDateRange from 'relativeDateRangeToDateRange';
import getDateBuckets from 'screens/GoalShow/getDateBuckets';

const useIntervals = ({
  activityCosts,
}: {
  activityCosts: Costs.PersistedCostModel | undefined;
}) => {
  const { weekStartsOn } = useContext(AccountContext);
  const [res, setRes] = useState<Period[]>([]);

  const startDateToPeriod = useCallback(
    ({
      startDate,
      interval,
    }: {
      startDate: string;
      interval: Costs.IntervalType;
    }) => {
      if (interval === 'month') {
        const startMoment = moment(startDate);

        return {
          startDate: startMoment.format('YYYY-MM-DD'),
          endDate: startMoment.endOf('month').format('YYYY-MM-DD'),
          label: startMoment.format("MMM 'YY"),
        };
      } else {
        const startMoment = moment(startDate);
        const endMoment = startMoment.add(6, 'days');

        return {
          startDate: moment(startDate).format('YYYY-MM-DD'),
          endDate: endMoment.format('YYYY-MM-DD'),
          label: `${moment(startDate).format('DD')} - ${endMoment.format(
            "DD MMM 'YY",
          )}`,
        };
      }
    },
    [],
  );

  useEffect(() => {
    if (!activityCosts) {
      return;
    }
    const startOfWeek = activityCosts.startOfWeek;

    const { endDate } = relativeDateRangeToDateRange({
      relativeDateRange: {
        interval: activityCosts.interval,
        n: 1,
        type: 'last' as 'last',
        currentToDate: true,
      },
      startOfWeek: startOfWeek || weekStartsOn,
    });

    const startDate = activityCosts.startDate;

    if (!endDate) {
      const error = new Error();
      error.name = 'endDate is undefined';
      throw error;
    }

    const startDates = getDateBuckets({
      startDate,
      endDate,
      weekStartsOn: startOfWeek || weekStartsOn,
      interval: activityCosts.interval,
    });

    if (activityCosts.interval === 'month') {
      setRes(
        startDates.map((startDate) =>
          startDateToPeriod({ startDate, interval: 'month' }),
        ),
      );
    } else {
      setRes(
        startDates.map((startDate) =>
          startDateToPeriod({ startDate, interval: 'week' }),
        ),
      );
    }
  }, [activityCosts, startDateToPeriod, weekStartsOn]);

  return { res, startDateToPeriod };
};

export default useIntervals;
