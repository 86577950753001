import React from 'react';
import styled from 'styled-components';
import Colors2 from 'theme/Colors2';
import Badge from 'components/Badge';
import Row from 'components/Common/Row';
import Typography from 'kingpin/atoms/Typography';
import Loading from '../../../components/Loading/Loading';
import FlexCentered from '../../../components/Common/FlexCentered';

const IntegrationCardDiv = styled.div<{ onClick?: () => void }>`
  width: 400px;
  min-height: 141px;
  padding: 16px;
  margin-bottom: 32px;
  border-radius: 8px;
  background-color: white;
  border: 1px solid ${Colors2.Primary['8']};
  box-shadow:
    0px 0px 0px 1px rgba(63, 63, 68, 0.07),
    0px 1px 3px 0px rgba(63, 63, 68, 0.12);

  cursor: ${(props) => (props.onClick ? 'pointer' : 'not-allowed')};
  &:hover {
    opacity: ${(props) => (props.onClick ? '0.7' : '1')};
  }
`;

export const LoadingCard = () => (
  <IntegrationCardDiv style={{ display: 'flex' }}>
    <FlexCentered>
      <Loading />
    </FlexCentered>
  </IntegrationCardDiv>
);

const IntegrationCard = ({
  onClick,
  title,
  icon,
  description,
  config,
}: {
  onClick?: () => void;
  title: string;
  icon: JSX.Element;
  description: string;
  config?: MicrosoftSso.MicrosoftConfig;
}) => {
  let badge;
  if (config === undefined) {
    badge = <Badge text="Not Available" badgeType={'Danger'} />;
  } else if (config.status === 'active') {
    badge = <Badge text="Active" badgeType={'Success'} />;
  } else if (config.status === 'pending configuration') {
    badge = <Badge text="Ready for configuration" badgeType="Info" />;
  } else if (config.status === 'pending permission grant') {
    badge = <Badge text="Available" badgeType="Default" />;
  } else if (config.status === 'disabled') {
    badge = <Badge text="Disabled" badgeType="Default" />;
  }

  return (
    <IntegrationCardDiv onClick={onClick}>
      <Row style={{ marginBottom: 16 }} centerAlign spaceBetween>
        {icon}
        {badge}
      </Row>
      <Typography.Header type="H5">{title}</Typography.Header>
      <Typography.Body type="Body 12">{description}</Typography.Body>
    </IntegrationCardDiv>
  );
};

export default IntegrationCard;
