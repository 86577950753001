import React, { useContext, useEffect, useState } from 'react';
import GoalProvider from 'contextProviders/GoalProvider';
import GoalCard from 'components/Goals/GoalCard';
import GoalsContext from 'contexts/GoalsContext';

const GoalGadget = ({ goalId }: { goalId: string }) => {
  const { goals } = useContext(GoalsContext);
  const [goal, setGoal] = useState<GeneralGoal>();

  useEffect(() => {
    setGoal(goals.find((g) => g.id === goalId));
  }, [goalId, goals]);

  if (!goal) {
    return null;
  }

  return (
    <div style={{ padding: 8, width: '100%', height: '100%' }}>
      <GoalProvider goal={goal}>
        <GoalCard goal={goal} />
      </GoalProvider>
    </div>
  );
};

export default GoalGadget;
