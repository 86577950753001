import STORE from '../../store';

const updateCompoundMetric = async (
  id: string,
  newMetric: Metrics.CompoundMetric,
  selectedAccountId: string,
) => {
  return STORE.metricDefinitions
    .getCompoundMetricsRef({ accountId: selectedAccountId })
    .doc(id)
    .set(newMetric);
};

export default updateCompoundMetric;
