import React from 'react';
import './configureSentry';

import {
  createBrowserRouter,
  Route,
  RouterProvider,
  Routes,
} from 'react-router-dom';
import styled from 'styled-components';
import 'sanitize.css/sanitize.css';
import './fonts/fonts.css';
import 'intersection-observer';
import Navigation from './navigation';
// Load the favicon and the .htaccess file

// eslint-disable-next-line
import '!file-loader?name=[name].[ext]!./images/favicon.ico';
// eslint-disable-next-line
import '!file-loader?name=[name].[ext]!./images/favicon-notification.ico';
// eslint-disable-next-line
import '!file-loader?name=[name].[ext]!./images/login-background.jpg';
// Import i18n messages
// Import CSS reset and Global Styles
import GlobalStyle from './global-styles';
import ErrorBoundary from './components/Common/ErrorBoundary';
import OnlineOnly from './components/OnlineOnly';
import FirebaseGate from './components/Common/FirebaseGate';
import AuthenticationProvider from './contextProviders/AuthenticationProvider';
import './windowConstants';
import GroupedLoadingProvider from './contextProviders/GroupedLoadingProvider';
import appRoutes from './navigation/appRoutes';
import SignOutScreen from './screens/SignOut';
import BlockExpiredPreviewSites from './components/BlockExpiredPreviewSites';
import ReloadProvider from './contextProviders/ReloadProvider';
import AppLoadedWatcher from './AppLoadedWatcher';
import FleetOpsSuspense from './FleetOpsSuspense';
import { TOOLTIP_PORTAL_DIV_ID } from './components/Tooltip';
import { INLINE_DIALOG_PORTAL_DIV_ID } from './components/InlineDialog';

const AppDiv = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  overflow: hidden;
`;

const Root = () => (
  <Routes>
    <Route path={appRoutes.loggedIn.signOut} element={<SignOutScreen />} />
    <Route
      path="*"
      element={
        <ErrorBoundary shouldWallboardAutoReloadApp isReloadable>
          <GroupedLoadingProvider>
            <FleetOpsSuspense>
              <Navigation />
              <div id={TOOLTIP_PORTAL_DIV_ID} />
              <div id={INLINE_DIALOG_PORTAL_DIV_ID} />
            </FleetOpsSuspense>
          </GroupedLoadingProvider>
        </ErrorBoundary>
      }
    />
  </Routes>
);

const router = createBrowserRouter([{ path: '*', element: <Root /> }]);

const App = () => (
  <AppDiv>
    <ErrorBoundary isReloadable>
      <OnlineOnly>
        <BlockExpiredPreviewSites>
          <ReloadProvider>
            <FirebaseGate>
              <AuthenticationProvider>
                <RouterProvider router={router} />
              </AuthenticationProvider>
            </FirebaseGate>
          </ReloadProvider>
        </BlockExpiredPreviewSites>
      </OnlineOnly>
      <AppLoadedWatcher />
    </ErrorBoundary>
    <GlobalStyle />
  </AppDiv>
);

export default App;
