import React from 'react';

interface MetricListContextType {
  metricList?: MetricListGadgetType;
}

const MetricListContext = React.createContext<MetricListContextType>({
  metricList: undefined,
});

export default MetricListContext;
