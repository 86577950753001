import React, { ChangeEvent } from 'react';
import MetricFormStyles from '../styles';
import Typography from 'kingpin/atoms/Typography';
import Inputs from '../../../../../../components/Inputs';
import Row from '../../../../../../components/Common/Row';
import Tooltip from '../../../../../../components/Tooltip';
import FormattingInput from '../../../../../../components/FormattingInput';
import MetricsInfoButton from '../../../../../../components/MetricsInfoButton';
import Expression from '../../../../../../components/MetricsInfoButton/Expression';
import InputMetrics from './InputMetrics';

const CompoundMetricForm = ({
  name,
  onNameChanged,
  description,
  onDescriptionChanged,
  normalMetrics,
  selectedMetricIds,
  selectedMetrics,
  onAddMetric,
  onRemoveMetric,
  expression,
  onExpressionChanged,
  formatting,
  setFormatting,
  onMetricPickerOpened,
}: {
  name: string;
  onNameChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  description: string;
  onDescriptionChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  normalMetrics: Metrics.NormalMetric[];
  selectedMetricIds: string[];
  selectedMetrics: Metrics.NormalMetric[];
  onAddMetric: (mId: string) => void;
  onRemoveMetric: (mId: string) => void;
  expression: string;
  onExpressionChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  formatting: MetricFormatting;
  setFormatting: React.Dispatch<React.SetStateAction<MetricFormatting>>;
  onMetricPickerOpened: () => void;
}) => (
  <MetricFormStyles.OuterDiv data-testid="edit-compound-metric-form">
    <MetricFormStyles.InnerDiv>
      <div style={{ marginBottom: 16 }}>
        <Typography.Body type="Label">Name</Typography.Body>
        <Inputs.TextInput
          autoFocus
          value={name}
          onChange={onNameChanged}
          data-testid={'name'}
        />
      </div>
      <div style={{ marginBottom: 16 }}>
        <Typography.Body type="Label">Description</Typography.Body>
        <Inputs.TextInput value={description} onChange={onDescriptionChanged} />
      </div>
      <div style={{ marginBottom: 16 }}>
        <InputMetrics
          normalMetrics={normalMetrics}
          selectedMetricIds={selectedMetricIds}
          onAddMetric={onAddMetric}
          onRemoveMetric={onRemoveMetric}
          onOpened={onMetricPickerOpened}
        />
      </div>
      <div style={{ marginBottom: 16 }}>
        <Row spaceBetween centerAlign>
          <Typography.Body type="Label">Expression</Typography.Body>

          <Tooltip
            hideTooltipOnClick
            isAltTooltip
            content={
              <Expression
                usedMetrics={selectedMetrics}
                expression={expression}
              />
            }
          >
            <Typography.Body type="Body 12">Preview</Typography.Body>
          </Tooltip>
          <MetricsInfoButton />
        </Row>
        <Inputs.TextInput
          value={expression}
          onChange={onExpressionChanged}
          data-testid={'expression'}
        />
      </div>

      <div style={{ marginBottom: 8 }}>
        <Typography.Body type="Label">Formatting (optional)</Typography.Body>
      </div>
      <FormattingInput formatting={formatting} setFormatting={setFormatting} />
    </MetricFormStyles.InnerDiv>
  </MetricFormStyles.OuterDiv>
);

export default CompoundMetricForm;
