import React from 'react';
import Button from 'kingpin/atoms/Button';
import Typography from 'kingpin/atoms/Typography';

import Nav from './Nav';
import { Wrapper } from '../../SlideOut/SlideOut';
import NewCommitmentSlideOutConstants from './constants';
import Summary from './Summary';
import AwardedLoads from './AwardedLoads';
import Revenue from './Revenue';
import NewCommitmentSlideOutHeading from './NewCommitmentSlideOutHeading';
import Row from '../../../Common/Row';
import Flash from '../../../Flash';
import WarningTextDiv from './WarningTextDiv';
import Colors from '../../../../theme/colors';
import Icon from '../../../../kingpin/atoms/Icon';
import Colors2 from '../../../../theme/Colors2';

const NewCommitmentSlideOut = ({
  activeNavElement,
  onNavScroll,
  onActionButtonClicked,
  isSaving,
  warningText,
  actionButtonText,
  showWarnings,
  isFlashVisible,
  setIsFlashVisible,
}: {
  activeNavElement: NewCommitmentSlideOutNavElement;
  onNavScroll: (event: React.UIEvent<HTMLDivElement>) => void;
  onActionButtonClicked: () => void;
  isSaving: boolean;
  warningText?: string;
  actionButtonText: string;
  showWarnings: boolean;
  isFlashVisible: boolean;
  setIsFlashVisible: React.Dispatch<React.SetStateAction<boolean>>;
}) => (
  <Wrapper>
    <Nav activeNavElement={activeNavElement} />
    <div style={{ width: '100%' }}>
      <NewCommitmentSlideOutHeading />
      <div
        onScroll={onNavScroll}
        id={NewCommitmentSlideOutConstants.NAV_SCROLLER_ID}
        style={{
          height: 'calc(100% - 124px)',
          width: '100%',
          overflowY: 'scroll',
          padding: '20px 24px',
        }}
      >
        <Flash
          isFlashVisible={isFlashVisible}
          setIsFlashVisible={setIsFlashVisible}
          type="success"
          message="Commit Created"
        />
        <div id={NewCommitmentSlideOutConstants.SUMMARY_ID}>
          <Summary />
        </div>
        <div id={NewCommitmentSlideOutConstants.AWARDED_LOADS_ID}>
          <AwardedLoads />
        </div>
        <div id={NewCommitmentSlideOutConstants.REVENUE_ID}>
          <Revenue />
          {warningText && (
            <WarningTextDiv>
              <Row centerAlign>
                <Icon icon="warning" color={Colors.RADICAL_RED} />
                <Typography.Body
                  type={'Body 14'}
                  color={Colors2.Secondary.error}
                >
                  Warning:
                </Typography.Body>
              </Row>
              {warningText}
            </WarningTextDiv>
          )}
          {showWarnings && (
            <WarningTextDiv>
              <Row centerAlign>
                <Icon icon="warning" color={Colors.RADICAL_RED} />
                <Typography.Body
                  type={'Body 14'}
                  color={Colors2.Secondary.error}
                >
                  Warning:
                </Typography.Body>
              </Row>
              <Typography.Body type={'Body 14'}>
                Some inputs were not valid
              </Typography.Body>
            </WarningTextDiv>
          )}
          <Button
            isDisabled={isSaving}
            onClick={onActionButtonClicked}
            isLoading={isSaving}
            type="Primary"
            size="Small"
            label={actionButtonText}
          />
        </div>
      </div>
    </div>
  </Wrapper>
);

export default NewCommitmentSlideOut;
