import { ApisauceInstance } from 'apisauce';

const inviteUser = ({
  email,
  roles,
  permissions,
  isBillingInvite,
  isTmsInvite,
  assignedAssets,
  api,
}: {
  email: string;
  roles: string[];
  permissions: FleetOps.Permission[];
  isBillingInvite: boolean;
  isTmsInvite: boolean;
  assignedAssets: {
    workSpaceIds: string[];
    reportIds: string[];
    dashboardIds: string[];
  };
  api: ApisauceInstance;
}) =>
  api.post(`/users/invite`, {
    email,
    roles,
    permissions,
    isBillingInvite,
    isTmsInvite,
    assignedAssets,
  });

export default inviteUser;
