import { useContext, useEffect, useState } from 'react';
import AccountPickerContext from '../contexts/AccountPickerContext';
import STORE from '../store';

const useMetrics = () => {
  const [metrics, setMetrics] = useState<Metrics.NormalMetric[]>([]);
  const [isLoadingMetrics, setIsLoadingMetrics] = useState(true);
  const { selectedAccountId } = useContext(AccountPickerContext);

  useEffect(() => {
    const listener = STORE.metricDefinitions
      .getNormalMetricsRef({
        accountId: selectedAccountId,
      })
      .onSnapshot((snapshot) => {
        const data: Metrics.NormalMetric[] = [];
        snapshot.docs.forEach((d) => {
          data.push(d.data());
        });
        setMetrics(data.filter((m) => !m.isHidden));
        setIsLoadingMetrics(false);
      });
    return () => {
      listener();
    };
  }, [selectedAccountId]);

  return {
    metrics,
    isLoadingMetrics,
  };
};

export default useMetrics;
