import React from 'react';
import Row from 'components/Common/Row';
import Loading from 'components/Loading';

import ListWrapper from '../DatasetDefinitions/ListWrapper';
import List from '../DatasetDefinitions/List';
import ListItem from './ListItem';
import TextInput from '../../../kingpin/atoms/TextInput';

const EntityDefinitions = ({
  searchText,
  items,
  onSearchTextChanged,
  isLoading,
}: {
  searchText: string;
  items: EntityDetails.Entity[];
  onSearchTextChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isLoading: boolean;
}) => (
  <div style={{ backgroundColor: 'white' }}>
    <div style={{ padding: '24px  32px' }}>
      <Row centerAlign spaceBetween>
        <div style={{ width: 280, height: 32 }}>
          <TextInput
            value={searchText}
            onChange={onSearchTextChanged}
            icon="search"
            placeholder="Search"
            autoFocus
          />
        </div>
      </Row>
    </div>
    <ListWrapper>
      <List>
        {isLoading && <Loading />}
        {!isLoading && (
          <>
            {items.map((item) => (
              <ListItem key={item.id} entity={item} />
            ))}
          </>
        )}
      </List>
    </ListWrapper>
  </div>
);

export default EntityDefinitions;
