import firebase from 'firebase/compat/app';

const setTask = (
  task: Tasks.Task,
  accountRef: firebase.firestore.DocumentReference,
) => {
  return accountRef.collection('tasks').doc(task.id).set(task);
};

export default setTask;
