import React, { useCallback, useContext } from 'react';
import Typography from 'kingpin/atoms/Typography';
import Dropdown from 'components/Inputs/Dropdown';
import Button from 'kingpin/atoms/Button';
import Row from 'components/Common/Row';
import StepHeader from './StepHeader';
import { AccordionContent } from './ConfigSteps';
import useIntervalOptions from './hooks/useIntervalOptions';
import useDateFieldsOptions from './hooks/useDateFieldsOptions';
import useStartDateOptions from './hooks/useStartDateOptions';
import CostsWizardContext from '../context/CostsWizardContext';
import WeekStartsOnOverrideSelector from 'components/Report/WeekStartsOnOverrideSelector';
import Loading from 'components/Loading/Loading';
import useActivityTypeOptions from './hooks/useActivityTypeOptions';

const BaseStep = () => {
  const { wizardState, setWizardState, isEditing, hasEditPermission } =
    useContext(CostsWizardContext);
  const activityTypeOptions = useActivityTypeOptions();
  const intervalOptions = useIntervalOptions();
  const startDateOptions = useStartDateOptions();
  const { options: fieldsOptions, isLoading: isDateFieldOptionsLoading } =
    useDateFieldsOptions();

  const setWeekStartsOn = useCallback(
    (newStart: WeekStartsOn) => {
      setWizardState((current) => ({
        ...current,
        config: {
          ...current.config,
          basicStep: {
            ...current.config.basicStep,
            startOfWeek: newStart,
          },
        },
      }));
    },
    [setWizardState],
  );

  const isInputsDisabled =
    (isEditing && !wizardState.isDraft) || !hasEditPermission;

  if (
    wizardState.config.basicStep.field === undefined ||
    isDateFieldOptionsLoading
  ) {
    return <Loading />;
  }

  return (
    <>
      <StepHeader headerStep="base" headerText="1. Basic Settings" />
      <AccordionContent
        isActive={wizardState.currentStep === 'base'}
        style={{
          height: wizardState.currentStep === 'base' ? '525px' : '0',
          transition: 'height: .2s  ease-out',
        }}
      >
        <div style={{ padding: '32px' }}>
          <div style={{ marginBottom: '24px' }}>
            <div style={{ marginBottom: '4px' }}>
              <Typography.Header type="H5">
                What activity type do you want to apply costs on
              </Typography.Header>
            </div>
            <div style={{ display: 'inline-block' }}>
              <Dropdown
                options={activityTypeOptions}
                isDisabled={isInputsDisabled}
                testId={`activity-type-disabled:${isInputsDisabled}`}
              />
            </div>
          </div>

          <div style={{ marginBottom: '24px' }}>
            <div style={{ marginBottom: '4px' }}>
              <Typography.Header type="H5">
                How often do you want to enter your costs?
              </Typography.Header>
            </div>
            <div style={{ display: 'inline-block' }}>
              <Dropdown
                options={intervalOptions}
                isDisabled={isInputsDisabled}
                testId={`intervals-disabled:${isInputsDisabled}`}
              />
            </div>
          </div>

          {wizardState.config.basicStep.interval === 'week' && (
            <div style={{ marginBottom: '24px' }}>
              <div style={{ marginBottom: '4px' }}>
                <Typography.Header type="H5">
                  What is the first day of the reporting week?
                </Typography.Header>
              </div>
              <div style={{ display: 'inline-block' }}>
                <WeekStartsOnOverrideSelector
                  weekStartsOnOverride={
                    wizardState.config.basicStep.startOfWeek
                  }
                  setWeekStartsOnOverride={setWeekStartsOn}
                  isDisabled={isInputsDisabled}
                />
              </div>
            </div>
          )}

          <div style={{ marginBottom: '24px' }}>
            <div style={{ marginBottom: '4px' }}>
              <Typography.Header type="H5">
                How far back should we start applying costs?
              </Typography.Header>
            </div>

            <div style={{ display: 'inline-block' }}>
              <Dropdown
                options={startDateOptions}
                placeholder="Select Start Date"
                isDisabled={isInputsDisabled}
              />
            </div>
          </div>

          <div style={{ marginBottom: '24px' }}>
            <div style={{ marginBottom: '4px' }}>
              <Typography.Header type="H5">
                Apply costs to all completed activities where
              </Typography.Header>
            </div>

            <div style={{ display: 'inline-block', marginBottom: '4px' }}>
              <Dropdown
                buttonStyle={{
                  minWidth: 120,
                }}
                options={fieldsOptions}
                isDisabled={
                  (isEditing && !wizardState.isDraft) || !hasEditPermission
                }
                renderSelectedLabel={
                  isDateFieldOptionsLoading
                    ? () => <Loading isTiny />
                    : undefined
                }
              />
            </div>
            <div style={{ marginBottom: '8px' }}>
              <Typography.Header type="H5">
                is between the start and end dates of the month
              </Typography.Header>
            </div>
          </div>

          <Row style={{ justifyContent: 'flex-end' }}>
            <Button
              type="Tertiary"
              size="Small"
              testId="cost-next-base"
              onClick={() => {
                setWizardState((s) => ({
                  ...s,
                  currentStep: 'group',
                }));
              }}
              label="Next Step"
            />
          </Row>
        </div>
      </AccordionContent>
    </>
  );
};

export default BaseStep;
