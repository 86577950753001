import React from 'react';
import Event from './Event';

import EmptyTimeline from './EmptyTimeline';
import Loading from '../Loading';
import styled from 'styled-components';

const ListWrapper = styled.div<{ isFullHeight?: boolean }>`
  padding: ${(props) => (props.isFullHeight ? '0px' : '0px 16px')};
  overflow-y: auto;
  height: calc(100% - ${(props) => (props.isFullHeight ? 0 : 72)}px);
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Timeline = ({
  events,
  isLoading,
  isFullHeight,
}: {
  events: Timeline.Event[];
  isLoading: boolean;
  isFullHeight?: boolean;
}) => (
  <>
    {isLoading && <Loading />}
    {!isLoading && (
      <ListWrapper isFullHeight={isFullHeight}>
        {events.map((e) => (
          <Event key={e.id} event={e} />
        ))}
        {events.length === 0 && !isLoading && <EmptyTimeline />}
      </ListWrapper>
    )}
  </>
);

export default Timeline;
