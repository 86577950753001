import { useState } from 'react';
import obfuscate from './obfuscate';

const isObfuscatingParam = new URLSearchParams(window.location.search).get(
  'obfuscating',
);
const IS_OBFUSCATING = !!isObfuscatingParam;

const useObfuscator = ({
  field,
  value,
  isPercentage,
}: {
  field?: string;
  value?: string | string[] | number;
  isPercentage?: boolean;
}) => {
  const isDollarValue =
    (!!value &&
      !Array.isArray(value) &&
      typeof value === 'string' &&
      value.includes('$')) ||
    typeof value === 'number';

  const [isObfuscating] = useState<boolean>(
    IS_OBFUSCATING && (!!field || isDollarValue),
  );
  const [obfuscatedValue] = useState<string>(() =>
    isObfuscating
      ? obfuscate({ field, value, isDollarValue, isPercentage })
      : '',
  );

  return {
    isObfuscating,
    obfuscatedValue,
  };
};

export default useObfuscator;
