import React, { useState } from 'react';
import ChartV2 from 'components/ChartV2';
import Loading from 'components/Loading/Loading';
import Row from 'components/Common/Row';
import FlexCentered from 'components/Common/FlexCentered';
import Typography from 'kingpin/atoms/Typography';
import Inputs from 'components/Inputs';
import DATASET_TREND_BUTTON from '../constants';
import useOptions from './useOptions';
import Tooltip from './Tooltip';
import useChartTitle from './useChartTitle';
import useFieldOptions from './useFieldOptions';
import buildMetric from './buildMetric';

const DatasetTrendContainer = ({ dataset }: { dataset: string }) => {
  const [metric, setMetric] = useState<MetricInput>(
    buildMetric({
      dataset,
      field: DATASET_TREND_BUTTON.DEFAULT_FIELD,
      aggFunc: 'count',
    }),
  );
  const fieldOptions = useFieldOptions({
    metric,
    setMetric,
    dataset,
  });
  const { options, isLoading, interval } = useOptions({
    metric,
  });
  const title = useChartTitle({ interval, metric });

  return (
    <>
      <Row centerAlign spaceBetween style={{ marginBottom: 12 }}>
        <Typography.Header type="H5">{title}</Typography.Header>
        <div style={{ width: 200 }}>
          <Inputs.Dropdown options={fieldOptions} isSearchEnabled />
        </div>
      </Row>
      <div
        style={{
          height: DATASET_TREND_BUTTON.TREND_HEIGHT,
        }}
      >
        {isLoading && (
          <FlexCentered style={{ height: '100%' }}>
            <Loading />
          </FlexCentered>
        )}
        {!isLoading && (
          <ChartV2
            options={options}
            TooltipComponent={Tooltip}
            testId="dataset-search-trend"
          />
        )}
      </div>
    </>
  );
};

export default DatasetTrendContainer;
