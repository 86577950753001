import styled from 'styled-components';

const Column = styled.div<{ onClick?: any; flex?: number }>`
  flex: ${(props) => (props.flex ? props.flex : 1)};
  display: flex;
  margin-right: 24px;
  align-items: center;
  min-height: 48px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default Column;
