import React, { useContext } from 'react';
import Typography from 'kingpin/atoms/Typography';

import CustomerSearch from '../../CustomerSearch';
import CommitmentQueryContext from '../../../../../contexts/CommitmentQueryContext';
import OmniSearch from '../../../../OmniSearch';
import { InputSection, InputWrapper } from '../styles';
import {
  DESTINATION_FIELDS,
  ORIGIN_FIELDS,
} from '../../../../OmniSearch/constants';
import toSentenceCase from '../../../../../services/toSentenceCase';

const CustomerPicker = () => {
  const { customerField } = useContext(CommitmentQueryContext);

  return (
    <InputWrapper>
      <div style={{ marginBottom: 4 }}>
        <Typography.Body type="Label">
          {`* ${customerField ? toSentenceCase(customerField) : ''}`}
        </Typography.Body>
      </div>
      <CustomerSearch />
    </InputWrapper>
  );
};

const OriginPicker = () => {
  const {
    originField,
    setOriginField,
    originValue,
    setOriginValue,
    queryFilterInput,
    mode,
  } = useContext(CommitmentQueryContext);

  return (
    <InputWrapper>
      <div style={{ marginBottom: 4 }}>
        <Typography.Body type="Label">* Origin</Typography.Body>
      </div>
      <OmniSearch.ManualBuilder
        searchPlaceholder="Origin"
        searchFields={ORIGIN_FIELDS}
        field={originField}
        setField={setOriginField as (v: string | undefined) => void}
        value={originValue}
        setValue={setOriginValue}
        filterInput={queryFilterInput}
        isDisabled={mode === 'renew' || mode === 'modify'}
      />
    </InputWrapper>
  );
};

const DestinationPicker = () => {
  const {
    destinationField,
    setDestinationField,
    destinationValue,
    setDestinationValue,
    queryFilterInput,
    mode,
  } = useContext(CommitmentQueryContext);

  return (
    <InputWrapper>
      <div style={{ marginBottom: 4 }}>
        <Typography.Body type="Label">* Destination</Typography.Body>
      </div>
      <OmniSearch.ManualBuilder
        searchPlaceholder="Destination"
        searchFields={DESTINATION_FIELDS}
        field={destinationField}
        setField={setDestinationField as (v: string | undefined) => void}
        value={destinationValue}
        setValue={setDestinationValue}
        filterInput={queryFilterInput}
        isDisabled={mode === 'renew' || mode === 'modify'}
      />
    </InputWrapper>
  );
};

const CommodityPicker = () => {
  const { commodity, setCommodity, queryFilterInput, mode } = useContext(
    CommitmentQueryContext,
  );

  return (
    <InputWrapper>
      <div style={{ marginBottom: 4 }}>
        <Typography.Body type="Label">Commodity</Typography.Body>
      </div>
      <OmniSearch.ManualBuilder
        searchPlaceholder="Commodity"
        searchFields={['commodity']}
        value={commodity}
        setValue={setCommodity}
        filterInput={queryFilterInput}
        isDisabled={mode === 'renew' || mode === 'modify'}
      />
    </InputWrapper>
  );
};

const Summary = () => (
  <InputSection>
    <div style={{ marginBottom: 24 }}>
      <Typography.Header type="H5">Summary</Typography.Header>
    </div>
    <CustomerPicker />
    <OriginPicker />
    <DestinationPicker />
    <CommodityPicker />
  </InputSection>
);

export default Summary;
