import React from 'react';
import styled from 'styled-components';
import Colors2 from '../../theme/Colors2';
import DangerSrc from './images/danger.png';
import WarningSrc from './images/warning.png';

const IconBackdrop = styled.div<{ type: 'danger' | 'warning' }>`
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: ${(props) =>
    props.type === 'danger'
      ? Colors2.AvatarColors['8'].background
      : Colors2.AvatarColors['3'].background};

  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom 16px;
`;

const IconImg = styled.img`
  margin-bottom: 1px;
`;

const Icon = ({ type }: { type: 'danger' | 'warning' }) => (
  <IconBackdrop type={type}>
    <IconImg src={type === 'danger' ? DangerSrc : WarningSrc} />
  </IconBackdrop>
);

export default Icon;
