import React, { useContext } from 'react';
import Row from 'components/Common/Row';
import Typography from 'kingpin/atoms/Typography';
import EntityDetailsContext from 'screens/EntityDetailsShow/EntityDetailsContext';
import Inputs from '../Inputs';

const EntityFilterToggle = ({
  isEntityFilterEnabled,
  setIsEntityFilterEnabled,
}: {
  isEntityFilterEnabled: boolean;
  setIsEntityFilterEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <Row centerAlign>
      <div style={{ marginRight: 8 }}>
        <Inputs.Toggle
          value={isEntityFilterEnabled}
          onChange={setIsEntityFilterEnabled}
        />
      </div>
      <div style={{ marginRight: 8 }}>
        <Typography.Body type="Label">
          Filter by selected entity
        </Typography.Body>
      </div>
    </Row>
  );
};

const Gate = ({
  isEntityFilterEnabled,
  setIsEntityFilterEnabled,
}: {
  isEntityFilterEnabled: boolean;
  setIsEntityFilterEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const isEntityApp = !!useContext(EntityDetailsContext);

  if (!isEntityApp) {
    return null;
  }

  return (
    <EntityFilterToggle
      isEntityFilterEnabled={isEntityFilterEnabled}
      setIsEntityFilterEnabled={setIsEntityFilterEnabled}
    />
  );
};

export default Gate;
