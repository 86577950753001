import { getLayout } from '../../components/ReportCanvas/buildLayout';

const findStartingY = (cards: CanvasCard.Card[], canvasMode: CanvasMode) => {
  if (cards.length === 0) {
    return 0;
  }

  const biggestYCard = cards.reduce(
    (currentBiggest, card) =>
      getLayout(card, canvasMode).y > getLayout(currentBiggest, canvasMode).y
        ? card
        : currentBiggest,
    cards[0],
  );
  return (
    getLayout(biggestYCard, canvasMode).y +
    getLayout(biggestYCard, canvasMode).h
  );
};

export default findStartingY;
