const isGeneralBoard = (
  board:
    | PerformanceBoardTypes.Board
    | DriverPerformanceBoard
    | CustomerLaneBoard
    | GeneralBoard
    | undefined,
): board is GeneralBoard => {
  return !!board && board.category === 'General';
};

export default isGeneralBoard;
