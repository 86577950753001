import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Button from 'kingpin/atoms/Button';

import appRoutes from '../../../navigation/appRoutes';
import Typography from 'kingpin/atoms/Typography';
import Inputs from '../../../components/Inputs';
import Row from '../../../components/Common/Row';

const Wrapper = styled.div`
  padding: 16px 24px;
  background-color: white;
`;

const LinkWithPasswordIdentity = ({
  attemptLink,
  isWrongPassword,
  onCancelClicked,
}: {
  attemptLink: (password: string) => void;
  isWrongPassword: boolean;
  onCancelClicked: () => void;
}) => {
  const [password, setPassword] = useState<string>('');
  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  }, []);

  const onLinkClicked = useCallback(() => {
    attemptLink(password);
  }, [attemptLink, password]);

  return (
    <Wrapper>
      <div style={{ marginBottom: 16 }}>
        <Typography.Header type="H5">
          Enter your FleetOps password
        </Typography.Header>
      </div>
      <div style={{ marginBottom: 16 }}>
        <Typography.Body type="Body 12">
          As this is your first time signing in with Microsoft, we require your
          FleetOps password to link your existing account with Microsoft. You
          will not be asked for your FleetOps password again.
        </Typography.Body>
      </div>
      <div style={{ marginBottom: 16 }}>
        <Inputs.TextInput
          type="password"
          name="password"
          // @ts-ignore
          autoComplete
          value={password}
          onChange={onChange}
          isInvalid={isWrongPassword}
        />
        {isWrongPassword && (
          <div>
            <Typography.Body type="Body 12">Incorrect Password</Typography.Body>
          </div>
        )}
      </div>
      <div style={{ marginBottom: 16 }}>
        <Link to={appRoutes.forgotPassword}>
          <Typography.Body type="Body 12">I forgot my password</Typography.Body>
        </Link>
      </div>
      <Row centerAlign spaceBetween>
        <Button
          label="Cancel"
          onClick={onCancelClicked}
          type="Tertiary"
          size="Small"
        />
        <Button
          label={'Continue'}
          onClick={onLinkClicked}
          type="Primary"
          size="Small"
        />
      </Row>
    </Wrapper>
  );
};

export default LinkWithPasswordIdentity;
