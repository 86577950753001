import React, { useContext, useEffect, useState } from 'react';
import V5GadgetForm from '../V5GadgetForm';
import Dropdown from '../Inputs/Dropdown';
import toSentenceCase from '../../services/toSentenceCase';
import SingleMetricDateMatrixForm from './SingleMetricDateMatrixForm';
import useSelectedGadget from '../../hooks/useSelectedGadget';
import isV5ChartDef from '../../types/visTypeCheckers/isV5ChartDef';
import AnalyticsContext from '../../contexts/AnalyticsContext';
import GaugeForm from '../GaugeForm';
import isSingleMetricDateMatrix from '../../types/visTypeCheckers/isSingleMetricDateMatrix';
import isGauge from '../../types/visTypeCheckers/isGauge';
import RemindersGadgetForm from '../RemindersGadgetForm';
import isRemindersGadget from '../../types/visTypeCheckers/isRemindersGadget';
import getGadgetType from '../../getGadgetType';
import RankingMatrixForm from '../RankingMatrixForm';
import isRankingMatrix from '../../types/visTypeCheckers/isRankingMatrix';
import isPaceMatrix from '../../types/visTypeCheckers/isPaceMatrix';
import PaceMatrixFormContainer from './PaceMatrixForm';

export const V5_GADGET_TYPES: GadgetType[] = [
  'rollingTrendCard',
  'fixedTrendCard',
  'smartCard',
  'card',
  'timeSeries',
  'pie',
  'bar',
  'horizontalBar',
  'simpleGrid',
  'treeMap',
  'combo',
  'matrix',
  'stackedArea',
  'stackedBar',
];

export const GadgetFormContainer = ({
  selected,
  onChange,
}: {
  selected?: VisualisationDefinition;
  onChange?: React.Dispatch<
    React.SetStateAction<VisualisationDefinition | undefined>
  >;
}) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const [selectedGadgetType, setSelectedGadgetType] = useState<
    GadgetType | VisualisationDefinitions.GadgetType | undefined
  >(() => {
    if (selected) {
      return getGadgetType(selected);
    }
    return undefined;
  });

  useEffect(() => {
    if (selected) {
      trackEvent('Gadget Builder - Edit Opened', {
        gadgetName: selected.name,
        gadgetId: selected.id,
      });
    } else {
      trackEvent('Gadget Builder - New Opened');
    }
  }, [selected, trackEvent]);

  useEffect(() => {
    if (selected) {
      setSelectedGadgetType(getGadgetType(selected));
    }
  }, [selected]);

  const v5GadgetOptions = V5_GADGET_TYPES.map((t) => ({
    label: toSentenceCase(t),

    onSelected: () => {
      setSelectedGadgetType(t);
      trackEvent('Gadget Builder - Type selected', {
        gadgetType: t,
      });
    },
  }));

  if (selectedGadgetType) {
    if (selectedGadgetType === 'SingleMetricDateMatrix') {
      return (
        <SingleMetricDateMatrixForm
          gadget={isSingleMetricDateMatrix(selected) ? selected : undefined}
          isGadgetBuilder={!onChange}
          onChange={onChange}
        />
      );
    } else if (selectedGadgetType === 'Gauge') {
      return (
        <GaugeForm
          gauge={isGauge(selected) ? selected : undefined}
          isGadgetBuilder={!onChange}
          onChange={onChange}
        />
      );
    } else if (selectedGadgetType === 'Reminders') {
      return (
        <RemindersGadgetForm
          gadget={isRemindersGadget(selected) ? selected : undefined}
          isGadgetBuilder={!onChange}
          onChange={onChange}
        />
      );
    } else if (selectedGadgetType === 'RankingMatrix') {
      return (
        <RankingMatrixForm
          gadget={isRankingMatrix(selected) ? selected : undefined}
          isGadgetBuilder={!onChange}
          onChange={onChange}
        />
      );
    } else if (selectedGadgetType === 'PaceMatrix') {
      return (
        <PaceMatrixFormContainer
          isGadgetBuilder={!onChange}
          onDraftChanged={onChange}
          editingPaceGadget={isPaceMatrix(selected) ? selected : undefined}
        />
      );
    } else {
      return (
        <V5GadgetForm
          defaultType={selectedGadgetType}
          isGadgetBuilder={!onChange}
          onChange={onChange}
        />
      );
    }
  } else {
    return (
      <div style={{ padding: 16 }}>
        <Dropdown
          isSearchEnabled
          testId="gadget-picker"
          placeholder={'Gadget Type'}
          options={[
            ...v5GadgetOptions,
            {
              label: 'Single Metric Date Matrix',
              onSelected: () => {
                setSelectedGadgetType('SingleMetricDateMatrix');
                trackEvent('Gadget Builder - Type selected', {
                  gadgetType: 'SingleMetricDateMatrix',
                });
              },
            },
            {
              label: 'Matrix (% of total, Rank)',
              onSelected: () => {
                setSelectedGadgetType('RankingMatrix');
                trackEvent('Gadget Builder - Type selected', {
                  gadgetType: 'RankingMatrix',
                });
              },
            },
            {
              label: 'Pace Matrix',
              onSelected: () => {
                setSelectedGadgetType('PaceMatrix');
                trackEvent('Gadget Builder - Type selected', {
                  gadgetType: 'PaceMatrix',
                });
              },
            },
            {
              label: 'Gauge',
              onSelected: () => {
                setSelectedGadgetType('Gauge');
                trackEvent('Gadget Builder - Type selected', {
                  gadgetType: 'Gauge',
                });
              },
            },
            {
              label: 'Reminders',
              onSelected: () => {
                setSelectedGadgetType('Reminders');
                trackEvent('Gadget Builder - Type selected', {
                  gadgetType: 'Reminders',
                });
              },
            },
          ]}
        />
      </div>
    );
  }
};

const SelectedProvider = ({
  onChange, // For use on the dashboard gadget builder popup
}: {
  onChange?: React.Dispatch<
    React.SetStateAction<VisualisationDefinition | undefined>
  >;
}) => {
  const { selected } = useSelectedGadget();

  if (selected && !isV5ChartDef(selected)) {
    return <GadgetFormContainer selected={selected} key={selected.id} />;
  } else {
    return <GadgetFormContainer onChange={onChange} />;
  }
};

export default SelectedProvider;
