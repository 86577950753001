import metricTypeCheckers from '../../types/metricTypeCheckers';

const getGridQueryOverride = (
  metric: Metrics.SpecialMetric | Metrics.NormalMetric,
): string | undefined => {
  const gridQueryOverride = metricTypeCheckers.isSpecialMetric(metric)
    ? metric.gridQueries
      ? metric.gridQueries.query
      : undefined
    : undefined;

  return gridQueryOverride;
};

export const getGridTotalsQueryOverride = (
  metric: Metrics.SpecialMetric | Metrics.NormalMetric,
): string | undefined => {
  const gridQueryOverride = metricTypeCheckers.isSpecialMetric(metric)
    ? metric.gridQueries
      ? metric.gridQueries.totals
      : undefined
    : undefined;

  return gridQueryOverride;
};

export const getGridCountQueryOverride = (
  metric: Metrics.SpecialMetric | Metrics.NormalMetric,
): string | undefined => {
  const gridQueryOverride = metricTypeCheckers.isSpecialMetric(metric)
    ? metric.gridQueries
      ? metric.gridQueries.count
      : undefined
    : undefined;

  return gridQueryOverride;
};

export default getGridQueryOverride;
