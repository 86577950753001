import React, { useContext, useEffect, useState } from 'react';
import AnalyticsContext from '../../../../../../contexts/AnalyticsContext';
import useFieldDefinitions from '../../../../../../hooks/useFieldDefinitions';

const useAggFuncOptions = ({
  selectedDataset,
  setAggFunc,
  field,
}: {
  selectedDataset?: string;
  setAggFunc: React.Dispatch<React.SetStateAction<AggFunc | undefined>>;
  field: string;
}) => {
  const [dataSourceForFieldFilter, setDataSourceForFieldFilter] = useState<
    string[] | undefined
  >(selectedDataset ? [selectedDataset] : undefined);
  const { fieldDefinitions } = useFieldDefinitions(dataSourceForFieldFilter);
  const { trackEvent } = useContext(AnalyticsContext);

  const [aggFuncOptions, setAggFuncOptions] = useState<DropdownOption[]>([]);
  useEffect(() => {
    setDataSourceForFieldFilter(
      selectedDataset ? [selectedDataset] : undefined,
    );
  }, [selectedDataset]);

  useEffect(() => {
    const newAggFuncs = ((): AggFunc[] => {
      if (field) {
        const def = fieldDefinitions.find((d) => d.field === field);
        if (def) {
          return def.aggFuncs;
        }
        return [];
      } else {
        return [];
      }
    })();

    const newAggFuncOptions = newAggFuncs.map((f) => ({
      label: f,
      onSelected: () => {
        setAggFunc(f);
        trackEvent('Metric Builder - Agg Func selected', { aggFunc: f });
      },
    }));
    setAggFuncOptions(newAggFuncOptions);
  }, [field, fieldDefinitions, setAggFunc, trackEvent]);

  return aggFuncOptions;
};

export default useAggFuncOptions;
