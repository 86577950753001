const isVariable = (filter: FilterPlate): filter is VariableFilterPlate =>
  filter.type === 'Variable';

const isFixed = (filter: FilterPlate): filter is FixedFilterPlate =>
  filter.type === 'Fixed';

const isDataset = (filter: FilterPlate): filter is DatasetFilterPlate =>
  filter.type === 'Dataset';

const isNotDataset = (
  filter: FilterPlate,
): filter is VariableFilterPlate | FixedFilterPlate => !isDataset(filter);

const isNotVariable = (
  filter: FilterPlate,
): filter is DatasetFilterPlate | FixedFilterPlate => !isVariable(filter);

const filterPlateTypeCheckers = {
  isVariable,
  isFixed,
  isDataset,
  isNotDataset,
  isNotVariable,
};

export default filterPlateTypeCheckers;
