import firebase from 'firebase/compat/app';
import STORE_CONSTANTS from '../constants';
import withoutNulls from '../../api/search/withoutNulls';

export const COMPOUND_METRICS_DOC = 'compoundMetrics';
export const CURRENT_COMPOUND_METRIC_VERSION = 'v1';

const compoundMetricConverter = {
  toFirestore(
    compoundMetric: Metrics.CompoundMetric,
  ): firebase.firestore.DocumentData {
    return withoutNulls({ ...compoundMetric });
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ): Metrics.CompoundMetric {
    const data = snapshot.data(options);
    return data as Metrics.CompoundMetric;
  },
};

const getCompoundMetricsRef = ({
  accountId,
  db,
}: {
  accountId: string;
  db?: firebase.firestore.Firestore;
}) => {
  return (db ? db : firebase.firestore())
    .collection('accounts')
    .doc(accountId)
    .collection(STORE_CONSTANTS.METRIC_DEFINITIONS_COLLECTION)
    .doc(COMPOUND_METRICS_DOC)
    .collection(CURRENT_COMPOUND_METRIC_VERSION)
    .withConverter(compoundMetricConverter);
};

export default getCompoundMetricsRef;
