import React, { useContext, useEffect, useState } from 'react';
import MentionSuggestions from './MentionSuggestions';
import captureException from '../../../services/captureException';
import useUsers from '../../../hooks/useUsers';
import LocalTasksContext from '../../../contexts/Tasks/LocalTasksContext';

const stripCase = (string: string) => string.toLowerCase();

const includesString = (superSet: string, subSet: string) => {
  try {
    return stripCase(superSet).includes(stripCase(subSet));
  } catch (ex) {
    captureException(ex);
    return false;
  }
};

const MentionSuggestionsContainer = ({
  mentionInput,
  close,
  onSuggestionSelected,
}: {
  mentionInput: string;
  close: () => void;
  onSuggestionSelected: (
    u:
      | UserManagement.SignedUpUser
      | UserManagement.FleetOpsStaffUser
      | UserManagement.PendingUser,
  ) => void;
}) => {
  const { assignableUserIds } = useContext(LocalTasksContext);
  const [focusIndex, setFocusIndex] = useState<number>(0);
  const users = useUsers(true, assignableUserIds);
  const suggestions = users.filter((user) =>
    includesString(user.displayName, mentionInput),
  );

  useEffect(() => {
    const boundBySuggestionsCount = (number: number) => {
      if (number === -1) {
        return suggestions.length - 1;
      }

      return number % suggestions.length;
    };

    const listenForArrows = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowUp':
          event.preventDefault();
          setFocusIndex(boundBySuggestionsCount(focusIndex - 1));
          break;
        case 'ArrowDown':
          event.preventDefault();
          setFocusIndex(boundBySuggestionsCount(focusIndex + 1));
          break;
        case 'Enter':
          event.preventDefault();
          onSuggestionSelected(suggestions[focusIndex]);
          break;
        default:
          break;
      }
    };

    document.addEventListener('keydown', listenForArrows);
    document.addEventListener('click', close);

    return () => {
      document.removeEventListener('keydown', listenForArrows);
      document.removeEventListener('click', close);
    };
  }, [focusIndex, close, onSuggestionSelected, suggestions]);

  return (
    <MentionSuggestions
      focusIndex={focusIndex}
      setFocusIndex={setFocusIndex}
      mentionInput={mentionInput}
      onSuggestionSelected={onSuggestionSelected}
      suggestions={suggestions}
    />
  );
};

export default MentionSuggestionsContainer;
