import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from 'kingpin/atoms/Button';

import Typography from 'kingpin/atoms/Typography';
import Inputs from 'components/Inputs';
import Row from 'components/Common/Row';
import WeekStartsOnOverrideSelector from 'components/Report/WeekStartsOnOverrideSelector';
import StepContent, { StepContentBody } from '../StepContent';
import DatasetNameSuggestion from './DatasetNameSuggestion';
import useIsNameInvalid from './useIsNameInvalid';
import Colors2 from '../../../../../../theme/Colors2';

const Section = styled.div`
  margin-bottom: 16px;
  width: 400px;
`;

const LabelDiv = styled.div`
  margin-bottom: 4px;
`;

const DatasetSetUpStep = ({
  isEditing,
  wizardState,
  entityOptions,
  entityDataTypeOptions,
  entityWindowDateFieldOptions,
  onIntervalLengthChanged,
  onEntitySearchWindowChanged,
  cadenceOptions,
  onDatasetNameChanged,
  onDatasetNameBlur,
  onReportingWeekChanged,
  dateOptions,
  onCloseClicked,
  goToNextStep,
  isReadOnly,
}: {
  isEditing: boolean;
  wizardState: PerformanceConfiguration.WizardState;
  entityOptions: DropdownOption[];
  entityDataTypeOptions: DropdownOption[];
  entityWindowDateFieldOptions: DropdownOption[];
  onIntervalLengthChanged: (newLength: number | undefined) => void;
  onEntitySearchWindowChanged: (newWindow: number | undefined) => void;
  cadenceOptions: DropdownOption[];
  onDatasetNameChanged: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDatasetNameBlur: () => void;
  onReportingWeekChanged: (newStart: WeekStartsOn) => void;
  dateOptions: DropdownOption[];
  onCloseClicked: () => void;
  goToNextStep: () => void;
  isReadOnly: boolean;
}) => {
  const [isNextStepEnabled, setIsNextStepEnabled] = useState<boolean>(true);
  const isNameInvalid = useIsNameInvalid(wizardState.config.datasetStep.name);
  useEffect(() => {
    const step = wizardState.config.datasetStep;
    const {
      entity,
      intervalLength,
      interval,
      name,
      startDate,
      entityDataTypes,
    } = step;
    setIsNextStepEnabled(
      !!entity &&
        intervalLength > 0 &&
        !!interval &&
        !!name &&
        name !== '' &&
        !!startDate &&
        !!entityDataTypes &&
        entityDataTypes.length > 0,
    );
  }, [wizardState.config.datasetStep]);

  return (
    <StepContent>
      <StepContentBody>
        <div style={{ marginBottom: 24 }}>
          <Typography.Header type="H4">New Dataset</Typography.Header>
        </div>
        <Section>
          <LabelDiv>
            <Typography.Body type="Label">Select an entity</Typography.Body>
          </LabelDiv>
          <div>
            <Inputs.Dropdown
              options={entityOptions}
              placeholder="Select an Entity"
              isDisabled={isEditing || isReadOnly}
              testId="entity-dropdown"
            />
          </div>
        </Section>
        <Section>
          <LabelDiv>
            <Typography.Body type="Label">
              Select entity datatype(s)
            </Typography.Body>
          </LabelDiv>
          <div>
            <Inputs.Dropdown
              fullWidth
              options={entityDataTypeOptions}
              placeholder="Select Entity DataType(s)"
              isDisabled={isEditing || isReadOnly}
              testId="entity-datatype-dropdown"
              isMulti
            />
          </div>
        </Section>
        <Section>
          <LabelDiv>
            <Typography.Body type="Label">
              Set the entity search window date field
            </Typography.Body>
          </LabelDiv>
          <div>
            <Inputs.Dropdown
              fullWidth
              options={entityWindowDateFieldOptions}
              placeholder="Select Entity Search Window Date Field"
              testId="entity-window-date-field-dropdown"
            />
          </div>
        </Section>
        <Section>
          <LabelDiv>
            <Typography.Body type="Label">
              Set the entity search window
            </Typography.Body>
          </LabelDiv>
          <div>
            <Inputs.Number
              value={wizardState.config.datasetStep.entityWindowLength}
              setValue={onEntitySearchWindowChanged}
              testId={'entity-search-window'}
            />
          </div>
        </Section>
        {wizardState.config.datasetStep.interval === 'week' && (
          <Section>
            <LabelDiv>
              <Typography.Body type="Label">Reporting Week:</Typography.Body>
            </LabelDiv>
            <WeekStartsOnOverrideSelector
              isLabelDisabled
              setWeekStartsOnOverride={onReportingWeekChanged}
              weekStartsOnOverride={wizardState.config.datasetStep.startOfWeek}
              isDisabled={isEditing || isReadOnly}
            />
          </Section>
        )}
        <Section>
          <LabelDiv>
            <Typography.Body type="Label">
              Set the interval which dataset is recorded
            </Typography.Body>
          </LabelDiv>
          <div>
            <Inputs.IntervalPicker
              intervalOptions={cadenceOptions}
              intervalLength={wizardState.config.datasetStep.intervalLength}
              onIntervalLengthChanged={onIntervalLengthChanged}
              isDisabled={isEditing || isReadOnly}
            />
          </div>
        </Section>
        <Section>
          <LabelDiv>
            <Typography.Body type="Label">
              Select the date from which the dataset is to be generated
            </Typography.Body>
          </LabelDiv>
          <div>
            <Inputs.Dropdown
              testId={'start-date-dropdown'}
              options={dateOptions}
              unsetWidth
              isDisabled={isReadOnly}
            />
          </div>
        </Section>
        <Section>
          <LabelDiv>
            <Typography.Body type="Label">Dataset Name</Typography.Body>
          </LabelDiv>
          <div>
            <Inputs.TextInput
              width="100%"
              inputSize="Small"
              value={wizardState.config.datasetStep.name || ''}
              onChange={onDatasetNameChanged}
              readOnly={isEditing || isReadOnly}
              onBlur={onDatasetNameBlur}
              data-testid="ds-name-input"
              state={
                isNameInvalid && !isEditing
                  ? 'Error'
                  : isEditing || isReadOnly
                    ? 'Disabled'
                    : undefined
              }
            />
          </div>
          {!isEditing && isNameInvalid && (
            <Typography.Body type="Body 12">
              A dataset already exists with this name
            </Typography.Body>
          )}
          <DatasetNameSuggestion />
        </Section>
      </StepContentBody>
      <Row
        spaceBetween
        style={{ borderTop: `1px solid ${Colors2.Border}`, paddingTop: '32px' }}
      >
        <Button
          onClick={onCloseClicked}
          label="Close"
          type="Tertiary"
          size="Small"
        />
        <Button
          onClick={goToNextStep}
          label="Next Step"
          isDisabled={!isNextStepEnabled}
          type="Primary"
          size="Small"
        />
      </Row>
    </StepContent>
  );
};

export default DatasetSetUpStep;
