import React, { useContext } from 'react';

import DashboardGadgetBottomBar from '../../DashboardGadgetBottomBar';
import CanvasCard from '../../Visualisations/CanvasCard';
import CardContent from './CardContent';
import { ChartTopBar } from '../../Chart';
import DashboardGadgetContext from '../../../contexts/DashboardGadgetContext';

const CardContainer = ({
  chartDefinition,
}: {
  chartDefinition: V5ChartDefinition;
}) => {
  const { dashboardGadget } = useContext(DashboardGadgetContext);

  return (
    <CanvasCard
      topBar={<ChartTopBar chartDefinition={chartDefinition} />}
      content={<CardContent chartDefinition={chartDefinition} />}
      bottomBar={dashboardGadget ? <DashboardGadgetBottomBar /> : undefined}
    />
  );
};

export default CardContainer;
