import React from 'react';

interface GlobalTimelineContextType {
  events: Timeline.Event[];
  isLoading: boolean;
}

const GlobalTimelineContext = React.createContext<GlobalTimelineContextType>({
  events: [],
  isLoading: true,
});

export default GlobalTimelineContext;
