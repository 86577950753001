import Toggle from './Toggle';
import TextArea from './TextArea';
import Dropdown from './Dropdown';
import Checkbox from './Checkbox';
import Number from './Number';
import ListPicker from './ListPicker';
import MetricPicker from './MetricPicker';
import IntervalPicker from './IntervalPicker';
import MetricDateFieldPicker from './MetricDateFieldPicker';
import FieldsPicker from './FieldsPicker';
import MultiSelectDropdown from './ExclusiveDropdown';
import FilterBuilder from './FilterBuilder';
import SearchableListPicker from './SearchableListPicker';
import AddUsersSearch from './AddUsersSearch';
import Radio from './Radio';
import TextInput from '../../kingpin/atoms/TextInput';
import ExclusiveDropdown from './ExclusiveDropdown';

const Inputs = {
  Toggle,
  Number,
  TextArea,
  TextInput,
  Dropdown,
  Radio,
  Checkbox,
  ListPicker,
  MetricPicker,
  IntervalPicker,
  MetricDateFieldPicker,
  FieldsPicker,
  MultiSelectDropdown,
  FilterBuilder,
  SearchableListPicker,
  AddUsersSearch,
  ExclusiveDropdown,
};

export default Inputs;
