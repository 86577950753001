import React, { useCallback, useContext, useEffect, useState } from 'react';
import useRecents from '../hooks/useRecents';
import buildContentViewId, {
  buildContentViewIdAlt,
} from '../buildContentViewId';
import LastViewedAtContext from '../contexts/LastViewedAtContext';
import CurrentUserContext from '../contexts/CurrentUserContext';
import AccountPickerContext from '../contexts/AccountPickerContext';

const LastViewedAtProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { id: currentUserId } = useContext(CurrentUserContext);
  const { recents } = useRecents();
  const [lastViewedAtData, setLastViewedAtData] = useState<LastViewedAtData>(
    {},
  );
  const { accountRef } = useContext(AccountPickerContext);

  useEffect(() => {
    if (process.env.NODE_ENV === 'test') {
      return;
    }
    const listener = accountRef
      .collection('users')
      .doc(currentUserId)
      .collection('navigation')
      .doc('lastViewed')
      .onSnapshot((snapshot) => {
        if (!snapshot.exists) {
          return;
        }

        const data = snapshot.data();
        if (!data) {
          return;
        }
        const lastViews = data.lastViewedAtData as LastViewedAtData;
        setLastViewedAtData(lastViews);
      });

    return listener;
  }, [accountRef, currentUserId]);

  const getLastViewedAt = useCallback(
    (
      type:
        | 'report'
        | 'dashboard'
        | 'workspace'
        | 'wallboard'
        | 'board'
        | 'scorecard',
      typeId: string,
    ): string | undefined => {
      const contentViewId = buildContentViewIdAlt({ typeId, type });
      if (lastViewedAtData && lastViewedAtData[contentViewId]) {
        return lastViewedAtData[contentViewId];
      }

      const contentView = recents.find(
        (r) =>
          buildContentViewIdAlt({ typeId, type }) === buildContentViewId(r),
      );
      if (contentView) {
        return contentView.createdOn;
      } else {
        return undefined;
      }
    },
    [lastViewedAtData, recents],
  );

  return (
    <LastViewedAtContext.Provider value={{ getLastViewedAt }}>
      {children}
    </LastViewedAtContext.Provider>
  );
};

export default LastViewedAtProvider;
