import React, { ComponentType } from 'react';
import DateInputProvider, {
  DateInputProviderProps,
} from '../contextProviders/DateInputProvider';

export default function withDateFilter<T>(
  Component: ComponentType<T>,
  dateProps: DateInputProviderProps,
) {
  const EnhancedComponent = (props: T) => {
    return (
      <DateInputProvider {...dateProps}>
        {/*
    // @ts-ignore */}
        <Component {...props} />
      </DateInputProvider>
    );
  };

  return EnhancedComponent;
}
