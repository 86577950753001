import React from 'react';
import styled from 'styled-components';

import useScorecardUsageReport from './useScorecardUsageReport';
import Colors2 from '../../theme/Colors2';
import Typography from 'kingpin/atoms/Typography';
import Icon from '../../kingpin/atoms/Icon';

const Banner = styled.div`
  border-radius: 4px;
  background-color: ${Colors2.AvatarColors['3'].background};
  padding: 10px 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 16px;
`;

const ScorecardUsageWarning = ({
  scorecard,
}: {
  scorecard: Scorecards.Scorecard;
}) => {
  const { usageWarningMessage, isUsedInMultiplePlaces } =
    useScorecardUsageReport(scorecard);

  if (!isUsedInMultiplePlaces) {
    return null;
  }

  return (
    <Banner data-testid="usage-warning">
      <div style={{ marginRight: 8 }}>
        <Icon icon="warning" color={Colors2.Secondary.warning} />
      </div>
      <div style={{ whiteSpace: 'pre-line' }}>
        <Typography.Body type="Body 12">{usageWarningMessage}</Typography.Body>
      </div>
    </Banner>
  );
};

export default ScorecardUsageWarning;
