import React, { useCallback, useContext, useState } from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import { DateTime } from 'luxon';

import usePopup from '../hooks/usePopup';
import CopyGadgetContext from '../contexts/CopyGadgetContext';
import CurrentUserContext from '../contexts/CurrentUserContext';
import buildCardLayout from './WidgetGalleryProvider/buildCardLayout';
import findStartingY from './WidgetGalleryProvider/findStartingY';
import createDashboardAlt from '../api/createDashboardAlt';
import createDashboardGadget from '../api/createDashboardGadget';
import updateDashboard from '../api/updateDashboard';
import CopyGadgetModal from '../components/CopyGadgetModal';
import withoutNulls from '../api/search/withoutNulls';
import { useNavigate } from 'react-router-dom';
import {
  buildDashboardShow,
  buildShowWallBoardConfig,
} from '../navigation/appRoutes';
import createSavedFilter from '../api/createSavedFilter';
import isDefined from '../isDefined';
import captureException from '../services/captureException';
import createMetricListGadget from '../api/metricListGadgets/createMetricListGadget';
import AnalyticsContext from '../contexts/AnalyticsContext';
import VariableFiltersContext from '../contexts/VariableFiltersContext';
import SavedFiltersContext from '../contexts/SavedFiltersContext';
import DashboardsContext from '../contexts/DashboardsContext';
import { updateWallBoard } from '../api/createWallBoard';
import AccountPickerContext from '../contexts/AccountPickerContext';
import LocalTimelineProvider from './TimelineProvider/LocalTimelineProvider';
import useCopyVisHelpers from '../hooks/useCopyVisHelpers';
import { PortalsContext } from './PortalsProvider';
const aguid = require('aguid');

const isMetricList = (
  gadget: MetricListGadgetType | DashboardGadget,
): gadget is MetricListGadgetType => {
  return (
    // @ts-ignore
    gadget.list !== undefined &&
    // @ts-ignore
    gadget.listOrder !== undefined &&
    // @ts-ignore
    gadget.chartDef === undefined
  );
};

const CopyGadgetProvider = ({
  children,
  currentDashboard,
}: {
  children: JSX.Element | JSX.Element[];
  currentDashboard?: {
    id: string;
    setCurrentCanvas: React.Dispatch<React.SetStateAction<Canvas>>;
    setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  };
}) => {
  const { selectedPortal } = useContext(PortalsContext);
  const { accountRef, selectedAccountId } = useContext(AccountPickerContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const { savedFilters } = useContext(SavedFiltersContext);
  const navigate = useNavigate();
  const { isOpen, open, close: closePopup } = usePopup();
  const { id: currentUserId } = useContext(CurrentUserContext);
  const { variableFilters } = useContext(VariableFiltersContext);
  const { addOrUpdateDashboard } = useContext(DashboardsContext);
  const [gadgetToCopy, setGadgetToCopy] = useState<
    DashboardGadget | undefined
  >();
  const [metricListToCopy, setMetricListToCopy] = useState<
    MetricListGadgetType | undefined
  >();
  const [copyGadgetTo, setCopyGadgetTo] = useState<
    'new dashboard' | 'existing dashboard' | 'wallboard' | undefined
  >();
  const [sourceCardW, setSourceCardW] = useState<number>(4);
  const [sourceCardH, setSourceCardH] = useState<number>(7);
  const [sourceDashboard, setSourceDashboard] = useState<
    PersistedDashboardType | undefined
  >();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { generateNewVisDef, getMetricIdForNewVis } = useCopyVisHelpers();

  const close = useCallback(() => {
    closePopup();
    setGadgetToCopy(undefined);
    setCopyGadgetTo(undefined);
  }, [closePopup]);

  const startCopyDashboardGadget = useCallback(
    (
      gadget: DashboardGadget | MetricListGadgetType,
      w: number,
      h: number,
      d: PersistedDashboardType,
    ) => {
      setSourceCardH(h);
      setSourceCardW(w);
      setSourceDashboard(d);
      if (isMetricList(gadget)) {
        setMetricListToCopy(gadget);
      } else {
        setGadgetToCopy({
          ...gadget,
          id: aguid(),
          createdBy: currentUserId,
          createdOn: DateTime.utc().toISO(),
          updatedBy: currentUserId,
          updatedOn: DateTime.utc().toISO(),
        });
      }
      open();
    },
    [currentUserId, open],
  );

  const startCopyReportGadget = useCallback(
    ({
      chartDef,
      drillDowns,
      scope,
      dateRange,
      relativeDateRange,
      advancedRelativeDateRange,
      dateField,
      comparison,
      w,
      h,
      reportId,
      weekStartsOnOverride,
    }: {
      chartDef: VisualisationDefinition;
      drillDowns: FilterPlate[];
      scope: FilterPlate[];
      dateRange?: DateRangeInput;
      relativeDateRange?: RelativeDateRange;
      advancedRelativeDateRange?: AdvancedRelativeDateRange;
      dateField: string;
      comparison?: PersistedComparisonType;
      h: number;
      w: number;
      reportId: string;
      weekStartsOnOverride?: WeekStartsOn;
    }) => {
      setSourceCardH(h);
      setSourceCardW(w);
      const newGadget = {
        id: aguid(),
        drillDowns,
        scope,
        dateRange,
        relativeDateRange,
        advancedRelativeDateRange,
        dateField,
        chartDef,
        comparison,
        createdBy: currentUserId,
        createdOn: DateTime.utc().toISO(),
        updatedBy: currentUserId,
        updatedOn: DateTime.utc().toISO(),
        reportDrillDownId: reportId,
        weekStartsOnOverride,
      };
      setGadgetToCopy(newGadget);
      open();
    },
    [currentUserId, open],
  );

  const cloneFilterPlate = useCallback(
    (
      plate: FilterPlate,
      destinationDashboard?: PersistedDashboardType,
    ): FilterPlate | undefined => {
      if (plate.type === 'Variable' && !!plate.variableId) {
        if (
          destinationDashboard &&
          currentDashboard &&
          currentDashboard.id === destinationDashboard.id
        ) {
          return {
            ...plate,
          }; // Keep the variables
        }

        const v = variableFilters.find((f) => f.id === plate.variableId);
        if (!v) {
          return undefined;
        }
        return {
          id: v.value.id,
          type: 'Fixed',
          fixedValue: v.value,
        };
      } else {
        return {
          ...plate,
        };
      }
    },
    [currentDashboard, variableFilters],
  );

  const cloneMetricListItem = useCallback(
    async (
      item: MetricListItemType,
      destinationDashboard?: PersistedDashboardType,
    ) => {
      const filter = savedFilters.find((sf) => sf.id === item.savedFilterId);
      if (filter) {
        const newFilter = (() => {
          const drillDowns = filter.drillDowns
            .map((d) => cloneFilterPlate(d, destinationDashboard))
            .filter(isDefined);
          const scope = filter.scope
            .map((d) => cloneFilterPlate(d, destinationDashboard))
            .filter(isDefined);

          return {
            ...filter,
            drillDowns,
            scope,
            id: aguid(),
          };
        })();
        await createSavedFilter(newFilter, accountRef);
        return {
          newItem: {
            ...item,
            metricId: await getMetricIdForNewVis(item.metricId),
            savedFilterId: newFilter.id,
          },
          oldId: item.id,
        };
      }
      const error = new Error(`${item.savedFilterId}`);
      error.name = 'cloneMetricListItem - saved filter not found';
      captureException(error);
      return undefined;
    },
    [accountRef, cloneFilterPlate, getMetricIdForNewVis, savedFilters],
  );

  const createMetricListCopy = useCallback(
    async (
      metricList: MetricListGadgetType,
      destinationDashboard?: PersistedDashboardType,
    ) => {
      const newListRequests = metricList.list.map((item) =>
        cloneMetricListItem(item, destinationDashboard),
      );
      const newListItemsResponses = await Promise.all(newListRequests).then(
        (items) => items.filter(isDefined),
      );
      const newListItems = newListItemsResponses.map((i) => i.newItem);
      const newOrder = metricList.listOrder
        .map((oldId) => {
          const response = newListItemsResponses.find((r) => r.oldId === oldId);
          if (response) {
            return response.newItem.id;
          }
          return undefined;
        })
        .filter(isDefined);

      const newMetricList = {
        ...metricList,
        id: aguid(),
        list: newListItems,
        listOrder: newOrder,
        createdBy: currentUserId,
        createdOn: DateTime.utc().toISO(),
        updatedBy: currentUserId,
        updatedOn: DateTime.utc().toISO(),
      };
      await createMetricListGadget({
        id: newMetricList.id,
        name: newMetricList.name,
        currentUserId,
        list: withoutNulls(newMetricList),
        accountId: selectedAccountId,
      });
      return newMetricList;
    },

    [cloneMetricListItem, currentUserId, selectedAccountId],
  );

  const generateNewCard = useCallback(
    async ({
      dashboard,
      isForWallboard,
    }: {
      dashboard?: PersistedDashboardType;
      isForWallboard?: boolean;
    }): Promise<CanvasCard.Card | undefined> => {
      const newId = aguid();
      if (gadgetToCopy) {
        const newChartDef = await generateNewVisDef(gadgetToCopy.chartDef);
        const gadgetToCopyWithFilters = {
          ...gadgetToCopy,
          chartDef: newChartDef,
          drillDowns: gadgetToCopy.drillDowns
            .map((d) => cloneFilterPlate(d, dashboard))
            .filter(isDefined),
          scope: gadgetToCopy.scope
            .map((s) => cloneFilterPlate(s, dashboard))
            .filter(isDefined),
        };
        await createDashboardGadget(
          withoutNulls(gadgetToCopyWithFilters),
          selectedAccountId,
        );
        return {
          layout: isForWallboard
            ? buildCardLayout(0, 0, newId, 12, 20)
            : buildCardLayout(
                0,
                dashboard
                  ? findStartingY(dashboard.canvas.cards, 'desktop')
                  : 0,
                newId,
                sourceCardW,
                sourceCardH,
              ),
          content: {
            type: 'Dashboard Gadget',
            dashboardGadgetId: gadgetToCopy.id,
          },
        };
      } else if (metricListToCopy) {
        const newList = await createMetricListCopy(metricListToCopy, dashboard);
        return {
          layout: isForWallboard
            ? buildCardLayout(0, 0, newId, 12, 20)
            : buildCardLayout(
                0,
                dashboard
                  ? findStartingY(dashboard.canvas.cards, 'desktop')
                  : 0,
                newId,
                sourceCardW,
                sourceCardH,
              ),
          content: {
            type: 'Metric List',
            metricListId: newList.id,
          },
        };
      }
      return undefined;
    },
    [
      cloneFilterPlate,
      createMetricListCopy,
      gadgetToCopy,
      generateNewVisDef,
      metricListToCopy,
      selectedAccountId,
      sourceCardH,
      sourceCardW,
    ],
  );

  const createDashboard = useCallback(
    async (title: string, access: ResourceAccess) => {
      const newCard = await generateNewCard({});

      if (!newCard) {
        setIsLoading(false);
        alert('Something went wrong');
        const error = new Error('');
        error.name = 'Failed to create new card';
        captureException(error);
        return;
      }
      setIsLoading(true);

      const newDashboard = {
        version: '5' as '5',
        id: aguid(),
        name: title,
        canvas: {
          cards: [newCard],
        },
        isTemplate: false,
        variableDrillDowns: [],
        access,
        createdBy: currentUserId,
        createdOn: DateTime.utc().toISO(),
        updatedBy: currentUserId,
        updatedOn: DateTime.utc().toISO(),
      };

      await createDashboardAlt({
        dashboard: newDashboard,
        accountRef,
      });
      trackEvent('Copy Gadget - Completed', {
        mode: 'New Dashboard',
        dashboardId: newDashboard.id,
      });

      addOrUpdateDashboard(newDashboard);
      navigate(
        buildDashboardShow({
          id: newDashboard.id,
          isEditing: true,
          portal: selectedPortal,
        }),
      );
    },
    [
      accountRef,
      addOrUpdateDashboard,
      currentUserId,
      generateNewCard,
      navigate,
      selectedPortal,
      trackEvent,
    ],
  );

  const copyToDashboard = useCallback(
    async (dashboard: PersistedDashboardType) => {
      setIsLoading(true);
      const newCard = await generateNewCard({ dashboard });
      if (!newCard) {
        setIsLoading(false);
        alert('Something went wrong');
        const error = new Error('');
        error.name = 'Failed to create new card';
        captureException(error);
        return;
      }

      const newDashboard = {
        ...dashboard,
        canvas: {
          ...dashboard.canvas,
          cards: [...dashboard.canvas.cards, newCard],
        },
        updatedBy: currentUserId,
        updatedOn: DateTime.utc().toISO(),
      };

      await updateDashboard({
        id: dashboard.id,
        newDashboard,
        accountRef,
      });
      trackEvent('Copy Gadget - Completed', {
        mode: 'Existing Dashboard',
        dashboardId: newDashboard.id,
      });

      setIsLoading(false);
      addOrUpdateDashboard(newDashboard);
      if (currentDashboard && currentDashboard.id === newDashboard.id) {
        currentDashboard.setCurrentCanvas(newDashboard.canvas);
        currentDashboard.setIsEditing(true);
      }
      navigate(
        buildDashboardShow({
          id: newDashboard.id,
          isEditing: true,
          portal: selectedPortal,
        }),
      );
      close();
    },
    [
      generateNewCard,
      currentUserId,
      accountRef,
      trackEvent,
      addOrUpdateDashboard,
      currentDashboard,
      navigate,
      selectedPortal,
      close,
    ],
  );

  const copyToWallboard = useCallback(
    async (wallboard: Wallboard) => {
      setIsLoading(true);
      const newCard = await generateNewCard({ isForWallboard: true });

      if (!newCard) {
        setIsLoading(false);
        alert('Something went wrong');
        const error = new Error('');
        error.name = 'Failed to create new card';
        captureException(error);
        return;
      }

      const name = gadgetToCopy
        ? gadgetToCopy.chartDef.name
        : metricListToCopy
          ? metricListToCopy.name
          : '';

      const newDashboard = {
        version: '5' as '5',
        id: aguid(),
        name,
        canvas: {
          cards: [newCard],
        },
        isTemplate: false,
        variableDrillDowns: [],
        access: {
          version: '2' as '2',
          type: 'Private' as 'Private',
        },
        createdBy: currentUserId,
        createdOn: DateTime.utc().toISO(),
        updatedBy: currentUserId,
        updatedOn: DateTime.utc().toISO(),
        isWallboardSlide: true,
        wallboardId: wallboard.id,
      };

      await createDashboardAlt({
        dashboard: newDashboard,
        accountRef,
      });
      trackEvent('Copy Gadget - Completed', {
        mode: 'New Dashboard',
        dashboardId: newDashboard.id,
      });
      addOrUpdateDashboard(newDashboard);

      const newWallboard = {
        ...wallboard,
        wallBoardDashboardIds: [
          ...wallboard.wallBoardDashboardIds,
          newDashboard.id,
        ],
      };
      updateWallBoard(newWallboard, accountRef).then(() => {
        navigate(
          `${buildShowWallBoardConfig(newWallboard.id, selectedPortal)}?slide=${
            newDashboard.id
          }`,
        );
      });
    },
    [
      generateNewCard,
      gadgetToCopy,
      metricListToCopy,
      currentUserId,
      accountRef,
      trackEvent,
      addOrUpdateDashboard,
      navigate,
      selectedPortal,
    ],
  );

  return (
    <CopyGadgetContext.Provider
      value={{
        isOpen,
        close,
        startCopyReportGadget,
        startCopyDashboardGadget,
        createDashboard,
        copyToDashboard,
        copyToWallboard,
        copyGadgetTo,
        setCopyGadgetTo,
        sourceDashboard,
        isLoading,
      }}
    >
      {children}
      <ModalTransition>
        {isOpen && (
          <Modal
            onClose={close}
            shouldScrollInViewport={false}
            autoFocus={false}
          >
            <CopyGadgetModal />
          </Modal>
        )}
      </ModalTransition>
    </CopyGadgetContext.Provider>
  );
};

const Gate = ({
  children,
  currentDashboard,
}: {
  children: JSX.Element | JSX.Element[];
  currentDashboard?: {
    id: string;
    setCurrentCanvas: React.Dispatch<React.SetStateAction<Canvas>>;
    setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  };
}) => (
  <LocalTimelineProvider sources={window.emptyArray}>
    <CopyGadgetProvider currentDashboard={currentDashboard}>
      {children}
    </CopyGadgetProvider>
  </LocalTimelineProvider>
);

export default Gate;
