import React from 'react';
import { ApisauceInstance } from 'apisauce';

interface CloudFunctionClientContextType {
  api: ApisauceInstance;
  usEastApi: ApisauceInstance;
  apiGateway: ApisauceInstance;
}

const CloudFunctionClientContext =
  React.createContext<CloudFunctionClientContextType>({
    api: window.defaultApi,
    usEastApi: window.defaultApi,
    apiGateway: window.defaultApi,
  });

export default CloudFunctionClientContext;
