import { useCallback, useContext, useEffect, useState } from 'react';
import WorkSpaceContext from '../contexts/WorkSpaceContext';
import PopupContext from '../contexts/PopupContext';
import AnalyticsContext from '../contexts/AnalyticsContext';

const useBoardTracking = ({
  board,
  onSlideOutClosed,
}: {
  board: Board | PerformanceBoardTypes.Board;
  onSlideOutClosed?: () => void;
}) => {
  const { workSpace } = useContext(WorkSpaceContext);
  const { trackEvent } = useContext(AnalyticsContext);

  const { isOpen: isPopupOpened, origin: popupOrigin } =
    useContext(PopupContext);
  const [hasTrackedOpen, setHasTrackedOpen] = useState<boolean>(false);

  const onQuickFilterChanged = useCallback(() => {
    const props = {
      boardId: board.id,
      boardName: board.name,
    };
    if (workSpace) {
      // @ts-ignore
      props.workSpaceId = workSpace.id;
    }
    trackEvent('Board - Quick filter changed', props);
    if (onSlideOutClosed) {
      onSlideOutClosed();
    }
  }, [board.id, board.name, onSlideOutClosed, trackEvent, workSpace]);

  const onManualFilterChanged = useCallback(() => {
    const props = {
      boardId: board.id,
      boardName: board.name,
    };
    if (workSpace) {
      // @ts-ignore
      props.workSpaceId = workSpace.id;
    }

    trackEvent('Board - Manual filter changed', props);
    if (onSlideOutClosed) {
      onSlideOutClosed();
    }
  }, [board.id, board.name, onSlideOutClosed, trackEvent, workSpace]);

  useEffect(() => {
    if (hasTrackedOpen) {
      return;
    }
    const props = {
      boardId: board.id,
      boardName: board.name,
    };
    if (workSpace) {
      // @ts-ignore
      props.workSpaceId = workSpace.id;
    }

    if (isPopupOpened) {
      trackEvent('Popup Board - Opened', {
        ...props,
        origin: popupOrigin,
      });
    } else {
      trackEvent('Board - Opened', props);
    }
    setHasTrackedOpen(true);
  }, [
    board.id,
    board.name,
    hasTrackedOpen,
    isPopupOpened,
    popupOrigin,
    trackEvent,
    workSpace,
  ]);

  return {
    onQuickFilterChanged,
    onManualFilterChanged,
  };
};

export default useBoardTracking;
