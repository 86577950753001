import React, { useCallback, useContext, useEffect } from 'react';
import MetricSlideOut from './MetricSlideOut';
import AnalyticsContext from '../../../../contexts/AnalyticsContext';

const MetricSlideOutContainer = ({
  close,
  metric,
  openMetricPopup,
}: {
  close: () => void;
  metric: Metrics.NormalMetric | Metrics.CompoundMetric;
  openMetricPopup: (
    metric?: Metrics.NormalMetric | Metrics.CompoundMetric,
  ) => void;
}) => {
  const { trackEvent } = useContext(AnalyticsContext);

  const onEditClicked = useCallback(() => {
    trackEvent('Metrics - View Slideout - Edit Metric Clicked', {
      metricType: metric.type,
    });
    openMetricPopup(metric);
  }, [metric, openMetricPopup, trackEvent]);

  useEffect(() => {
    trackEvent('Metrics - View Slideout opened', {
      metricType: metric.type,
    });
  }, [metric.type, trackEvent]);

  return (
    <MetricSlideOut
      close={close}
      metric={metric}
      onEditClicked={onEditClicked}
    />
  );
};

export default MetricSlideOutContainer;
