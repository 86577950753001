import React, { useCallback, useContext } from 'react';
import PerformanceWizardContext from './PerformanceWizardContext';
import Button from 'kingpin/atoms/Button';
import STORE from '../../../../../store';
import AccountPickerContext from '../../../../../contexts/AccountPickerContext';

const SaveAndCloseButton = () => {
  const { selectedAccountId } = useContext(AccountPickerContext);

  const { wizardState, isSaving, setIsSaving, close, isReadOnly } = useContext(
    PerformanceWizardContext,
  );

  const onSaveAndCloseClicked = useCallback(() => {
    if (wizardState.isEditing) {
      return;
    }

    setIsSaving(true);
    STORE.savedProgresses
      .getPerformanceDatasetsRef({
        accountId: selectedAccountId,
      })
      .doc(wizardState.firestoreId)
      .set(wizardState)
      .then(() => {
        setIsSaving(false);
        close();
      });
  }, [close, selectedAccountId, setIsSaving, wizardState]);

  if (wizardState.isEditing || isReadOnly) {
    return null;
  }

  return (
    <div style={{ marginRight: 8 }}>
      <Button
        type="Tertiary"
        size="Small"
        onClick={onSaveAndCloseClicked}
        label="Save & Close"
        isLoading={isSaving}
      />
    </div>
  );
};

export default SaveAndCloseButton;
