import styled, { css } from 'styled-components';
import MetricPickerPopupConstants from '../constants';
import Colors2 from '../../../../../theme/Colors2';
import Row from '../../../../Common/Row';
import MetricStatusBadge from '../../../../../screens/DataManager/Metrics/MetricPopup/MetricActions/MetricStatusBadge';
import CheckmarkIcon from '../images/Checkmark.svg';
import React from 'react';
import Constants from '../constants';

const ContentOuter = styled.div``;

const ContentDiv = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
`;

const ListTopDiv = styled.div`
  padding-left: ${MetricPickerPopupConstants.PADDING_HORIZONTAL};
  padding-right: ${MetricPickerPopupConstants.PADDING_HORIZONTAL};
  padding-top: ${MetricPickerPopupConstants.PADDING_TOP};
`;

const ListOuterOuterDiv = styled.div`
  height: calc(
    ${MetricPickerPopupConstants.POPUP_HEIGHT} -
      ${MetricPickerPopupConstants.BOTTOM_BAR_HEIGHT} -
      ${MetricPickerPopupConstants.TOP_BAR_HEIGHT}
  );
  display: flex;
`;

const ListOuterDiv = styled.div`
  flex: 1;
  overflow-y: auto;
  padding-left: ${MetricPickerPopupConstants.PADDING_HORIZONTAL};
  padding-right: ${MetricPickerPopupConstants.PADDING_HORIZONTAL};
`;

const ListInnerDiv = styled.div`
  overflow-y: auto;
  border: 1px solid #e9e9e9;
  margin-bottom: 16px;
`;

const SelectedItemCss = css`
  border-left: 2px solid ${Colors2.Secondary.info};
  background-color: #fbfbfe;
`;

const DisabledListItemDivCss = css`
  cursor: not-allowed;
  background-color: #f7f7fb;
`;

const ListItemDiv = styled.div<{ isSelected: boolean; isDisabled?: boolean }>`
  height: 88px;
  padding: 11px 16px;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  overflow-x: auto;

  ${(props) => props.isSelected && SelectedItemCss};
  cursor: pointer;
  &:hover {
    background-color: ${Colors2.Grey['9']};
  }

  ${(props) => props.isDisabled && DisabledListItemDivCss}

  ::-webkit-scrollbar {
    width: 4px;
    height: 6px;
    background-color: transparent;
  }
`;

const IconImg = styled.img`
  width: 12px;
  height: 12px;
`;

const STATUS_WIDTH = 100;

const DetailsCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: calc(100% - ${STATUS_WIDTH}px);
  width: calc(100% - ${STATUS_WIDTH}px);
  position: relative;
`;

const EllipsisDiv = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StatusIcons = ({
  isSelected,
  isCore,
}: {
  isSelected: boolean;
  isCore: boolean;
}) => (
  <Row
    centerAlign
    style={{
      width: STATUS_WIDTH,
      paddingRight: 16,
    }}
    spaceBetween
  >
    <div>{isCore && <MetricStatusBadge status="core" />}</div>
    {isSelected && <IconImg src={CheckmarkIcon} />}
  </Row>
);

const BottomBarDiv = styled(Row)`
  height: ${Constants.BOTTOM_BAR_HEIGHT};
  border-top: 1px solid #e5e5e5;
  padding-left: ${Constants.PADDING_HORIZONTAL};
  padding-right: ${Constants.PADDING_HORIZONTAL};
`;

const PopupContent = {
  ContentOuter,
  ContentDiv,
  ListTopDiv,
  ListOuterOuterDiv,
  ListOuterDiv,
  ListInnerDiv,
  ListItemDiv,
  DetailsCol,
  EllipsisDiv,
  StatusIcons,
  BottomBarDiv,
};

export default PopupContent;
