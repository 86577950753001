import React, { useCallback, useContext } from 'react';
import styled, { css } from 'styled-components';
import Typography from 'kingpin/atoms/Typography';
import Button from 'kingpin/atoms/Button';
import Row from 'components/Common/Row';
import Inputs from 'components/Inputs';
import TargetFormContext from '../../../contexts/TargetFormContext';
import getIdentifier from '../../../../../getIdentifier';
import useTargetFormCallbacks from '../../../hooks/useTargetFormCallbacks';

const GridDiv = styled.div`
  display: grid;
  margin-top: 8px;
  overflow-x: scroll;
`;

const GridRow = styled(Row)`
  height: 44px;
  background-color: white;
`;

const TopRow = styled(GridRow)`
  background-color: #e7e7e7;
`;

const HeaderBorderMixin = css`
  border-width: 1px 0 1px 1px;
  border-style: solid;
  border-color: #e0e0e0;
`;

const NormalBorderMixin = css`
  border-width: 0 0 1px 1px;
  border-style: solid;
  border-color: #e9e9e9;
`;

const HeaderCol = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  width: 278px;

  ${HeaderBorderMixin};
`;

const HeaderEndCol = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;

  ${HeaderBorderMixin};
  border-right-width: 1px;
`;

const Col = styled.div`
  padding: 8px;
  width: 278px;

  ${NormalBorderMixin};
`;

const EndCol = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 8px;
  width: 45px;

  ${NormalBorderMixin};
  border-right-width: 1px;
`;

const CategoryRow = ({
  target,
  category,
  group,
  categoryIndex,
}: {
  target: Targets.Wizard.Target;
  category: Targets.Wizard.Categorisation;
  group: Targets.Wizard.TargetGroup;
  categoryIndex: number;
}) => {
  const { updateTarget } = useTargetFormCallbacks();

  const getOperatorLabel = useCallback(() => {
    switch (category.operator) {
      case 'gt':
        return 'Greater than';
      case 'gte':
        return 'Greater than / equal to';
      case 'lt':
        return 'Less than';
      case 'lte':
        return 'Less than / equal to';
      default:
        const e = new Error();
        e.name = 'TargetManager: Operator not found';
        throw e;
    }
  }, [category.operator]);

  const onValueChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const categoryIndex = target.categories.findIndex(
        (c) => c.key === category.key,
      );
      const shouldUpdateLastRow =
        categoryIndex === target.categories.length - 2;

      const newTarget: Targets.Wizard.Target = {
        ...target,
        categories: target.categories.map((c, index) => {
          if (c.key === category.key) {
            return {
              ...c,
              value: event.target.value,
            };
          }

          if (shouldUpdateLastRow && c.isLastRow) {
            return {
              ...c,
              value: event.target.value,
            };
          }

          return c;
        }),
      };
      updateTarget({
        target: newTarget,
        group,
      });
    },
    [category.key, group, target, updateTarget],
  );

  const onOutputChanged = useCallback(
    ({
      event,
      outputName,
    }: {
      event: React.ChangeEvent<HTMLInputElement>;
      outputName: string;
    }) => {
      const newTarget: Targets.Wizard.Target = {
        ...target,
        categories: target.categories.map((c) => {
          if (c.key === category.key) {
            return {
              ...c,
              outputs: c.outputs.map((o, index) => {
                if (o.fieldName === outputName) {
                  return {
                    ...o,
                    fieldValue: event.target.value,
                  };
                }

                return o;
              }),
            };
          }

          return c;
        }),
      };

      updateTarget({
        target: newTarget,
        group,
      });
    },
    [category.key, group, target, updateTarget],
  );

  return (
    <GridRow>
      <Col>
        <Typography.Body type="Body 12">{getOperatorLabel()}</Typography.Body>
      </Col>
      <Col>
        <Inputs.TextInput
          data-testid={`target-${categoryIndex}`}
          value={category.value}
          onChange={onValueChanged}
          state={category.isLastRow ? 'Disabled' : undefined}
        />
      </Col>
      {category.outputs.map((output) => (
        <Col key={output.fieldName}>
          <Inputs.TextInput
            data-testid={`target-${output.fieldName}-${categoryIndex}`}
            type={output.fieldType}
            value={output.fieldValue}
            onChange={(event) =>
              onOutputChanged({ event, outputName: output.fieldName })
            }
          />
        </Col>
      ))}
      <EndCol />
    </GridRow>
  );
};

const GridInput = ({
  target,
  group,
}: {
  target: Targets.Wizard.Target;
  group: Targets.Wizard.TargetGroup;
}) => {
  const { outputs } = useContext(TargetFormContext);
  const { updateTarget } = useTargetFormCallbacks();
  const onAddCategoryClicked = useCallback(() => {
    const operator = target.categories[0].operator;

    const newTarget: Targets.Wizard.Target = {
      ...target,
      categories: [
        {
          key: getIdentifier(undefined, true),
          isLastRow: false,
          value: '',
          operator,
          outputs: outputs.map((o) => ({
            fieldType: o.fieldType,
            fieldValue: '',
            fieldName: o.fieldName,
          })),
        },
        ...target.categories,
      ],
    };

    updateTarget({ target: newTarget, group });
  }, [group, outputs, target, updateTarget]);

  if (target.categories.length === 0) {
    return null;
  }

  return (
    <GridDiv>
      <TopRow>
        <HeaderCol>
          <Typography.Body type="Label">Target Bands</Typography.Body>
        </HeaderCol>
        <HeaderCol>
          <Typography.Body type="Label">Target</Typography.Body>
        </HeaderCol>
        {outputs.map((output) => (
          <HeaderCol key={output.fieldName}>
            <Typography.Body type="Label">{output.fieldName}</Typography.Body>
          </HeaderCol>
        ))}
        <HeaderEndCol>
          <Button
            size={'Medium'}
            type={'Ghost'}
            icon={'add'}
            onClick={onAddCategoryClicked}
            testId="add-band"
          />
        </HeaderEndCol>
      </TopRow>
      {target.categories.map((category, categoryIndex) => (
        <CategoryRow
          key={category.key}
          category={category}
          group={group}
          target={target}
          categoryIndex={categoryIndex}
        />
      ))}
    </GridDiv>
  );
};

export default GridInput;
