import React, { useCallback, useContext, useEffect, useState } from 'react';
import SlideOutContext from '../../../contexts/SlideOutContext';
import useGetFieldLabel from '../../../../../hooks/useGetFieldLabel';

const useTargetFieldOptions = ({
  fields,
  setFields,
}: {
  fields: string[];
  setFields: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const { performanceFields, performanceDataset } = useContext(SlideOutContext);
  const { getFieldLabel } = useGetFieldLabel();

  const getOptions = useCallback((): DropdownOption[] => {
    return performanceFields.map((field) => {
      const isSelected = fields.some((f) => f === field);
      const label = getFieldLabel({ field, dataType: performanceDataset });
      const onSelected = () => {
        setFields((currentFields) => {
          if (isSelected) {
            return currentFields.filter((f) => f !== field);
          } else {
            return [...currentFields, field];
          }
        });
      };
      return {
        label,
        isSelected,
        onSelected,
      };
    });
  }, [fields, getFieldLabel, performanceDataset, performanceFields, setFields]);
  const [options, setOptions] = useState<DropdownOption[]>(() => getOptions());
  useEffect(() => {
    setOptions(getOptions);
  }, [getOptions]);

  return options;
};

export default useTargetFieldOptions;
