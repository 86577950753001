import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import useGetFieldLabel from 'hooks/useGetFieldLabel';
import useGetDatasetDefinition from 'hooks/useGetDatasetDefinition';
import DATASET_TREND_BUTTON from '../constants';
import buildMetric from './buildMetric';

const useFieldOptions = ({
  metric,
  setMetric,
  dataset,
}: {
  metric: MetricInput;
  setMetric: React.Dispatch<React.SetStateAction<MetricInput>>;
  dataset: string;
}) => {
  const { getFieldLabel } = useGetFieldLabel();
  const [options, setOptions] = useState<DropdownOption[]>([]);
  const getDatasetDefinition = useGetDatasetDefinition();
  useEffect(() => {
    const datasetDef = getDatasetDefinition(dataset);
    if (!datasetDef) {
      setMetric(
        buildMetric({
          field: DATASET_TREND_BUTTON.DEFAULT_FIELD,
          aggFunc: 'count',
          dataset,
        }),
      );
      setOptions([]);
      return;
    }

    setOptions(
      _.sortBy(
        datasetDef.fields
          .filter(
            (f) =>
              f.type !== 'date' &&
              f.type !== 'dateText' &&
              f.type !== 'geo_point',
          )
          .filter((f) => {
            if (f.type === 'text') {
              return f.aggFuncs.includes('count');
            } else {
              return f.aggFuncs.includes('sum');
            }
          })
          .map((f) => {
            const label = getFieldLabel({
              field: f.field,
              dataType: dataset,
              isVerbose: true,
            });
            const isSelected = metric.field === f.field;
            const onSelected = () => {
              setMetric(
                buildMetric({
                  field: f.field,
                  aggFunc: f.type === 'text' ? 'count' : 'sum',
                  dataset,
                }),
              );
            };

            return {
              label,
              isSelected,
              onSelected,
            };
          }),
        'label',
      ),
    );
  }, [dataset, getDatasetDefinition, getFieldLabel, metric.field, setMetric]);

  return options;
};

export default useFieldOptions;
