import React from 'react';

interface RolesContextType {
  availableRoles: FleetOps.RoleDefinition[];
  primaryRoles: FleetOps.RoleDefinition[];
  grantableRoles: FleetOps.RoleDefinition[];
  additionalRoles: FleetOps.RoleDefinition[];
  currentPermissions: FleetOps.Permission[];
  isLoading: boolean;
}

const DEFAULT_ROLES_CONTEXT = {
  availableRoles: [],
  primaryRoles: [],
  grantableRoles: [],
  additionalRoles: [],
  currentPermissions: [],
  isLoading: true,
};

const RolesContext = React.createContext<RolesContextType>(
  DEFAULT_ROLES_CONTEXT,
);

export default RolesContext;
