import React from 'react';

import Typography from 'kingpin/atoms/Typography';
import InfoBanner from '../../../../../../components/InfoBanner';

const UpdateWarning = () => (
  <InfoBanner>
    <Typography.Body type="Body 12">
      Selecting ‘Update Dataset’ will begin re-processing data, Please note that
      viewing/editing the dataset will be disabled until the processing is
      complete.
    </Typography.Body>
    <br />
    <Typography.Body type="Body 12">Est. Time 30m</Typography.Body>
  </InfoBanner>
);

export default UpdateWarning;
