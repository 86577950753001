import React from 'react';
import { COSTS_LIST_ITEM_HEIGHT } from '../consts';
import useHoverRow from './hooks/useHoverRow';
import Colors2 from 'theme/Colors2';

const GroupItemRight = () => {
  const { disableHover } = useHoverRow();

  return (
    <div
      onMouseEnter={disableHover}
      style={{
        height: `${COSTS_LIST_ITEM_HEIGHT}px`,
        width: '100%',
        backgroundColor: '#f4f4f4',
        borderBottom: `1px solid ${Colors2.GridBorder}`,
        borderRight: `1px solid ${Colors2.Grey['7']}`,
      }}
    ></div>
  );
};

export default GroupItemRight;
