import { useContext, useEffect, useState } from 'react';
import CostsWizardContext from '../../context/CostsWizardContext';
import AccountContext from 'contexts/AccountContext';
import toSentenceCase from '../../../../../services/toSentenceCase';

const useActivityTypeOptions = () => {
  const { wizardState, setWizardState } = useContext(CostsWizardContext);
  const { weekStartsOn } = useContext(AccountContext);
  const [options, setOptions] = useState<DropdownOption[]>([]);

  useEffect(() => {
    const types: Costs.ActivityCostCalculationMode[] = [
      'all',
      'truckOnly',
      'driverOnly',
    ];

    const newOptions = types.map((t) => {
      return {
        label: toSentenceCase(t),
        value: t,
        isSelected:
          wizardState.config.basicStep.activityCostCalculationMode === t,
        onSelected: () => {
          setWizardState({
            ...wizardState,
            config: {
              ...wizardState.config,
              basicStep: {
                ...wizardState.config.basicStep,
                activityCostCalculationMode: t,
              },
            },
          });
        },
      };
    });

    setOptions(newOptions);
  }, [setWizardState, weekStartsOn, wizardState]);

  return options;
};

export default useActivityTypeOptions;
