import React from 'react';

import Badge from '../../../../components/Badge';

const CreatingBadge = () => (
  <div style={{ marginLeft: 8 }}>
    <Badge isLoading text={'Processing (Est. 30m)'} badgeType={'Warning'} />
  </div>
);

export default CreatingBadge;
