import React from 'react';
import Button from 'kingpin/atoms/Button';
import Typography from 'kingpin/atoms/Typography';

const AddNewRewardButton = ({
  isQueryValid,
  onClick,
  configForQuery,
  mode,
}: {
  isQueryValid: boolean;
  onClick: () => void;
  configForQuery?: Commitment;
  mode: CommitmentFormPopupMode;
}) => (
  <React.Fragment>
    {!configForQuery && (
      <Button
        onClick={onClick}
        isDisabled={!isQueryValid}
        label={'Add New Award'}
        type="Primary"
        size="Small"
      />
    )}
    {!!configForQuery && mode === 'create' && (
      <div>
        <Typography.Body type="Body 14">
          A commitment already exists for this lane. Please update the existing
          one below.
        </Typography.Body>
      </div>
    )}
  </React.Fragment>
);

export default AddNewRewardButton;
