import React, { useCallback, useEffect, useState } from 'react';
import TargetFieldList from './TargetFieldList';
import useTargetFields from '../hooks/useTargetFields';
import useGetFieldLabel from '../../../hooks/useGetFieldLabel';

const TargetFieldListContainer = ({
  selectedField,
  onFieldSelected,
  dataset,
}: {
  selectedField?: string;
  onFieldSelected: (f: string) => void;
  dataset: string;
}) => {
  const { getFieldLabel } = useGetFieldLabel();
  const { targetFields, isLoadingTargetFields } = useTargetFields(dataset);
  const [searchText, setSearchText] = useState<string>('');
  const onSearchTextChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
    },
    [],
  );
  const [filteredFields, setFilteredFields] = useState<
    { rawField: string; label: string }[]
  >(() => targetFields);
  useEffect(() => {
    if (searchText === '') {
      setFilteredFields(targetFields);
      return;
    }

    setFilteredFields(
      targetFields.filter((f) => {
        return (
          f.rawField.toLowerCase().includes(searchText.toLowerCase()) ||
          f.label.toLowerCase().includes(searchText.toLowerCase())
        );
      }),
    );
  }, [dataset, getFieldLabel, searchText, targetFields]);

  return (
    <TargetFieldList
      selectedField={selectedField}
      onFieldSelected={onFieldSelected}
      isLoadingTargetFields={isLoadingTargetFields}
      fields={filteredFields}
      searchText={searchText}
      onSearchTextChanged={onSearchTextChanged}
    />
  );
};

export default TargetFieldListContainer;
