import React, { useCallback } from 'react';
import styled from 'styled-components';
import Button from 'kingpin/atoms/Button';
import { TARGET_SAVE_DIV_ID } from './constants';
import useHasUnsavedChanges from './hooks/useHasUnsavedChanges';
import useWarningConfirmation from '../ConfirmationModals/hooks/useWarningConfirmation';
import { ModalTransition } from '@atlaskit/modal-dialog';

const BottomBarDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ebebeb;
  padding: 16px 24px;
`;

const BottomBar = ({ close }: { close?: () => void }) => {
  const hasUnsavedChanges = useHasUnsavedChanges();
  const { getConfirmationThat, DangerConfirmation } = useWarningConfirmation({
    title: 'Unsaved Changes',
    continueText: 'Continue without saving',
  });
  const onCloseClicked = useCallback(() => {
    if (!close) {
      return;
    }

    if (!hasUnsavedChanges) {
      close();
      return;
    }

    getConfirmationThat(
      'If you navigate away, all changes made will be lost.' +
        ' Are sure you want to cancel Target setting?',
    ).then((isConfirmed) => {
      if (isConfirmed) {
        close();
      }
    });
  }, [getConfirmationThat, hasUnsavedChanges, close]);

  return (
    <>
      <BottomBarDiv>
        {!!close && (
          <Button
            type="Tertiary"
            size="Small"
            onClick={onCloseClicked}
            label="Back To Settings"
          />
        )}

        <div id={TARGET_SAVE_DIV_ID} />
      </BottomBarDiv>
      <ModalTransition>{DangerConfirmation}</ModalTransition>
    </>
  );
};

export default BottomBar;
