import { useCallback, useContext, useEffect, useState } from 'react';
import { ApisauceInstance, create } from 'apisauce';

import VersionContext from '../../contexts/VersionContext';
import AccountContext from '../../contexts/AccountContext';
import env from '../../.env.json';
import {
  addProblemMonitor,
  setAuthorizationToken,
} from '../../services/buildCloudFunctionClient';

const usePermissionsApi = () => {
  const { buildNumber } = useContext(VersionContext);
  const { id: accountId } = useContext(AccountContext);
  const getApi = useCallback(() => {
    const newApi = create({
      baseURL: env.PERMISSIONS_API,
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'x-account-id': accountId,
        'x-build-number': buildNumber,
      },
    });
    addProblemMonitor(newApi);
    setAuthorizationToken(newApi);

    return newApi;
  }, [accountId, buildNumber]);
  const [api, setApi] = useState<ApisauceInstance>(() => getApi());

  useEffect(() => {
    setApi(getApi());
  }, [getApi]);

  return api;
};

export default usePermissionsApi;
