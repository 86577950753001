import React from 'react';
import styled from 'styled-components';
import ContextMenu from '../../../../ContextMenu';
import { Option } from 'kingpin/atoms/ContextMenu';

const List = styled.div`
  display: flex;
  flex-direction: column;

  button {
    width: 100%;
  }
`;

const Menu = ({
  isOpen,
  open,
  close,
  onEditClicked,
  onDeleteClicked,
  onCopyClicked,
  isWorkspace,
}: {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  onEditClicked: () => void;
  onDeleteClicked: () => void;
  onCopyClicked: () => void;
  isWorkspace: boolean;
}) => (
  <div style={{ marginRight: 8 }}>
    <ContextMenu open={open} close={close} isOpen={isOpen}>
      <List>
        <Option onClick={onEditClicked} label={'Edit'} />
        <Option onClick={onCopyClicked} label={'Copy'} />
        <Option
          onClick={onDeleteClicked}
          label={isWorkspace ? 'Remove' : 'Delete'}
        />
      </List>
    </ContextMenu>
  </div>
);

export default Menu;
