import { useContext, useEffect, useState } from 'react';
import AccountPickerContext from '../contexts/AccountPickerContext';

const useTextCardContent = (id: string) => {
  const [textContent, setTextContent] = useState<string>('');
  const [isCenterAlign, setIsCenterAlign] = useState<boolean | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { accountRef } = useContext(AccountPickerContext);

  useEffect(() => {
    if (process.env.NODE_ENV === 'test') {
      return;
    }

    const listener = accountRef
      .collection('textCardContents')
      .doc(id)
      .onSnapshot((s) => {
        const d = s.data();
        if (d) {
          setTextContent(d.textContent);
          setIsCenterAlign(!!d.isCenterAlign);
          setIsLoading(false);
        }
      });

    return () => {
      listener();
    };
  }, [accountRef, id]);

  return {
    text: textContent,
    isCenterAlign,
    isLoading,
  };
};

export default useTextCardContent;
