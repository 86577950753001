import React from 'react';

import WorkSpacesContext from 'contexts/WorkSpacesContext';
import useAllWorkSpaces from 'hooks/workspaces/useAllWorkSpaces';

const WorkSpacesProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { allWorkSpaces, isLoading } = useAllWorkSpaces();

  return (
    <WorkSpacesContext.Provider value={{ allWorkSpaces, isLoading }}>
      {children}
    </WorkSpacesContext.Provider>
  );
};

export default WorkSpacesProvider;
