import React, { useContext, useEffect, useState } from 'react';
import GoalKpiVis from './GoalKpiVis';
import GeneralGoalContext from '../../../contexts/GeneralGoalContext';
import MetricOptionsContext from '../../../contexts/MetricOptionsContext';

const GeneralGoalKpisVis = ({ isCard }: { isCard?: boolean }) => {
  const { primaryKpiData, secondaryKpisData, goal } =
    useContext(GeneralGoalContext);
  const { metricOptions } = useContext(MetricOptionsContext);

  const [topGoalRow, setTopGoalRow] = useState<{
    result: Goals.GoalMetricResult;
    metric: Metrics.Metric;
  }>();

  useEffect(() => {
    const m = metricOptions.find((metric) => metric.id === goal.metricId);
    if (!m) {
      setTopGoalRow(undefined);
      return;
    }

    setTopGoalRow({
      result: primaryKpiData,
      metric: m,
    });
  }, [goal.metricId, metricOptions, primaryKpiData]);

  return (
    <GoalKpiVis
      kpis={goal.kpis}
      kpiResults={secondaryKpisData}
      primaryTrend={primaryKpiData}
      isCard={isCard}
      topGoalRow={topGoalRow}
    />
  );
};

export default GeneralGoalKpisVis;
