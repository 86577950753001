import React from 'react';
import Row from 'components/Common/Row';
import Button from 'kingpin/atoms/Button';

const ConfirmationButton =
  ({
    popupOptions,
    isEditing,
    handleUpdate,
  }: {
    popupOptions: ExclusiveDropdownOption[];
    isEditing: boolean;
    handleUpdate: () => void;
  }) =>
  ({ close }: { close: () => void }) => {
    const onClick = () => {
      handleUpdate();
      close();
    };

    return (
      <>
        <div
          style={{
            boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.08)',
            height: '1px',
            width: '100%',
            marginBottom: '12px',
          }}
        />
        <Row style={{ justifyContent: 'flex-end' }}>
          <Button
            type={'Primary'}
            size="Small"
            label={isEditing ? 'Update' : 'Add'}
            onClick={onClick}
            isDisabled={!popupOptions.find((o) => o.isSelected)}
          />
        </Row>
      </>
    );
  };

export default ConfirmationButton;
