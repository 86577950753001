import React from 'react';

interface ManualKpiInputContextType {
  onSave: () => void;
  onDiscardChanges: () => void;
  values: Goals.TrendResult[];
  setValues: React.Dispatch<React.SetStateAction<Goals.TrendResult[]>>;
}

const ManualKpiInputContext = React.createContext<ManualKpiInputContextType>(
  {} as ManualKpiInputContextType,
);

export default ManualKpiInputContext;
