import React, { useContext } from 'react';
import CanvasCard from '../../Visualisations/CanvasCard';
import { TrendCardTopBar } from '../../Gadget/TrendCard';
import DashboardGadgetBottomBar from '../../DashboardGadgetBottomBar';
import RollingTrendCardContent from './RollingTrendCardContent';
import DashboardGadgetContext from '../../../contexts/DashboardGadgetContext';

const RollingTrendCardContainer = ({
  chartDefinition,
}: {
  chartDefinition: V5ChartDefinition;
}) => {
  const { dashboardGadget } = useContext(DashboardGadgetContext);

  return (
    <CanvasCard
      topBar={
        <TrendCardTopBar
          name={chartDefinition.name}
          chartDefinition={chartDefinition}
        />
      }
      content={<RollingTrendCardContent chartDefinition={chartDefinition} />}
      bottomBar={dashboardGadget ? <DashboardGadgetBottomBar /> : undefined}
    />
  );
};
export default RollingTrendCardContainer;
