import { useContext, useEffect, useState } from 'react';
import AccountPickerContext from '../../contexts/AccountPickerContext';
import CurrentUserContext from '../../contexts/CurrentUserContext';
import STORE from '../../store';
import ImpersonatorContext from '../../contexts/ImpersonatorContext';

const usePersistedSharedContent = () => {
  const { selectedAccountId } = useContext(AccountPickerContext);
  const { id: currentUserId } = useContext(CurrentUserContext);
  const { impersonatorId } = useContext(ImpersonatorContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [persistedSharedContent, setPersistedSharedContent] =
    useState<PersistedSharedContent>({
      dashboardIds: [],
      reportIds: [],
      workspaceIds: [],
      scorecardIds: [],
    });

  useEffect(() => {
    setIsLoading(true);
    STORE.users
      .getSharedContentRef({
        accountId: selectedAccountId,
        userId: impersonatorId ? impersonatorId : currentUserId,
      })
      .get()
      .then((doc) => {
        const newSharedContent = doc.data();
        if (newSharedContent) {
          setPersistedSharedContent(newSharedContent);
        } else {
          setPersistedSharedContent({
            dashboardIds: [],
            reportIds: [],
            workspaceIds: [],
            scorecardIds: [],
          });
        }
        setIsLoading(false);
      });
  }, [currentUserId, impersonatorId, selectedAccountId]);

  return {
    persistedSharedContent,
    isLoadingPersistedSharedContent: isLoading,
  };
};

export default usePersistedSharedContent;
