import { ICellRendererParams } from 'ag-grid-community';
import Cell from './V5Gadget/Matrix/Cell';
import React from 'react';

const GridCellErrorBoundary = ({
  params,
  childComponent,
}: {
  params: ICellRendererParams;
  childComponent: React.ReactNode;
}) => {
  if (!params.data) {
    return <Cell>-</Cell>;
  }

  return <React.Fragment>{childComponent}</React.Fragment>;
};

export default GridCellErrorBoundary;
