import React, { useContext, useEffect, useState } from 'react';
import Typography from 'kingpin/atoms/Typography';
import Row from 'components/Common/Row';
import DateInput from 'components/DateInput';
import ComparisonSelector from 'components/Report/ComparisonSelector';
import Tooltip from 'components/Tooltip';
import VisualisationUsageDetails from 'components/VisualisationUsageDetails';
import Card from 'components/Common/Card';
import PaceMatrix from 'components/PaceMatrix';
import { GadgetFormPreview } from 'components/V5GadgetForm/V5GadgetForm';
import ErrorBoundary from 'components/Common/ErrorBoundary';
import ReportDrillDownsProvider from 'contextProviders/ReportDrillDownsProvider';
import ComparisonContext from 'contexts/ComparisonContext';
import { PaceMatrixFormTypes } from './types';
import withDateFilter from '../../../hocs/withDateFIlter';
import DateInputContext from '../../../contexts/DateInputContext';
import { useDataTypesFromMetricId } from '../../../hooks/useDataTypesFromSeriesAndMetricListItems';

const Preview = ({
  paceGadget,
}: {
  paceGadget: VisualisationDefinitions.PaceMatrix;
}) => {
  const { setDataTypes } = useContext(DateInputContext);
  const dataTypes = useDataTypesFromMetricId(paceGadget.metricId);
  const [comparison, setComparison] = useState<
    PersistedComparisonType | undefined
  >({
    compareType: 'compareTo',
    relativeDateOption: 'last 4 weeks',
  });

  useEffect(() => {
    setDataTypes(dataTypes);
  }, [dataTypes, setDataTypes]);

  return (
    <ErrorBoundary>
      <ReportDrillDownsProvider>
        <ComparisonContext.Provider
          value={{
            currentComparison: comparison,
            setCurrentComparison: setComparison,
          }}
        >
          <Row spaceBetween style={{ marginBottom: 24 }}>
            <DateInput />
            <Row>
              <ComparisonSelector alwaysShowCompareTo />
              <Tooltip
                content={
                  <VisualisationUsageDetails chatDefinition={paceGadget} />
                }
                isAltTooltip
                hideTooltipOnClick
              >
                <Typography.Body type="Link">Usage</Typography.Body>
              </Tooltip>
            </Row>
          </Row>
          <Card style={{ height: 700 }}>
            <PaceMatrix gadget={paceGadget} />
          </Card>
        </ComparisonContext.Provider>
      </ReportDrillDownsProvider>
    </ErrorBoundary>
  );
};

const Gate = ({
  formState,
  isGadgetBuilder,
}: {
  formState: PaceMatrixFormTypes.FormState;
  isGadgetBuilder?: boolean;
}) => {
  if (!isGadgetBuilder) {
    return null;
  }

  if (!formState.draftGadget) {
    return (
      <GadgetFormPreview>
        <Typography.Body type="Body 12">
          Please fill out the form for a preview
        </Typography.Body>
      </GadgetFormPreview>
    );
  }

  return (
    <GadgetFormPreview>
      <Preview paceGadget={formState.draftGadget} />
    </GadgetFormPreview>
  );
};

export default withDateFilter(Gate, {});
