const isNotCompoundMetric = (
  m: Metrics.Metric | undefined,
): m is
  | Metrics.NormalMetric
  | Metrics.SingleUseMetric
  | Metrics.SpecialMetric => {
  if (!m) {
    return false;
  }

  return m.type !== 'compound';
};

export default isNotCompoundMetric;
