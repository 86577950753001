import React, { useCallback, useContext } from 'react';
import moment from 'moment';
import ScorecardContext from 'contexts/ScorecardContext';
import Typography from 'kingpin/atoms/Typography';
import { Col } from './Kpis';
import Row from 'components/Common/Row';
import { Z_INDEX } from '../../../constants';
import { KPI_HEADER_COL_HEIGHT } from './constants';
import Colors2 from '../../../theme/Colors2';

export const getColName = (
  period: Period,
  cadence: 'week' | 'month',
  isFull?: boolean,
): string => {
  if (cadence === 'month') {
    return moment(period.startDate).format(isFull ? "MMMM 'YY" : "MMM 'YY");
  } else {
    return isFull
      ? `${moment(period.startDate).format('MMM DD')} - ${moment(
          period.endDate,
        ).format('MMM DD')}`
      : period.label;
  }
};

const ColumnItem = ({
  period,
  noBorder,
}: {
  period: Period;
  noBorder: boolean;
}) => {
  const { scorecard } = useContext(ScorecardContext);
  return (
    <Col
      minWidth
      alignRight
      style={{
        borderRight: noBorder ? undefined : `1px solid ${Colors2.GridBorder}`,
        padding: '8px 0',
        height: KPI_HEADER_COL_HEIGHT,
      }}
    >
      <div style={{ paddingRight: '8px', display: 'flex' }}>
        <Typography.Body type="Body 12" color={Colors2.Grey['2']}>
          {getColName(period, scorecard.cadence)}
        </Typography.Body>
      </div>
    </Col>
  );
};

const ColumnHeadingsRight = () => {
  const { selectedPeriods, setHoveredKpiId } = useContext(ScorecardContext);

  const onKpiMouseEnter = useCallback(() => {
    setHoveredKpiId(undefined);
  }, [setHoveredKpiId]);

  return (
    <Row
      centerAlign
      onMouseEnter={onKpiMouseEnter}
      style={{
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
        zIndex: Z_INDEX.SCORECARD_COLUMN_HEADING,
        borderBottom: `1px solid ${Colors2.GridBorder}`,
        borderTop: `1px solid ${Colors2.GridBorder}`,
        borderRight: `1px solid ${Colors2.GridBorder}`,
      }}
    >
      {selectedPeriods.map((p) => (
        <ColumnItem
          key={p.startDate}
          period={p}
          noBorder={p === selectedPeriods[selectedPeriods.length - 1]}
        />
      ))}
    </Row>
  );
};

export default ColumnHeadingsRight;
