import { useContext, useEffect, useState } from 'react';
import RolesContext from '../../../contexts/RolesContext';
import metricTypeCheckers from '../../../types/metricTypeCheckers';
import PERMISSIONS from '../../../permissionDefinitions';

const useCanEditMetric = (metric?: Metrics.Metric) => {
  const { currentPermissions } = useContext(RolesContext);
  const [canEdit, setCanEdit] = useState<boolean>(false);

  useEffect(() => {
    if (!metric || metricTypeCheckers.isSpecialMetric(metric)) {
      setCanEdit(false);
      return;
    }

    if (metricTypeCheckers.isSingleUseMetric(metric)) {
      setCanEdit(
        currentPermissions.includes(
          PERMISSIONS.DATA_MANAGEMENT.SINGLE_USE_METRICS_CONFIG,
        ),
      );
      return;
    }

    setCanEdit(
      currentPermissions.includes(
        PERMISSIONS.DATA_MANAGEMENT.REUSABLE_METRICS_CONFIG,
      ) &&
        currentPermissions.includes(
          PERMISSIONS.DATA_MANAGEMENT.DATA_MANAGER_ACCESS,
        ),
    );
  }, [currentPermissions, metric]);

  return canEdit;
};

export default useCanEditMetric;
