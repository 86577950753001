import React, { useCallback, useContext } from 'react';
import Colors2 from 'theme/Colors2';
import Inputs from 'components/Inputs';
import Tooltip from 'components/Tooltip';
import TargetFormContext from '../../../contexts/TargetFormContext';
import useTargetFormCallbacks from '../../../hooks/useTargetFormCallbacks';

const SimpleInput = ({
  target,
  group,
  avgValueTooltip,
}: {
  target: Targets.Wizard.Target;
  group: Targets.Wizard.TargetGroup;
  avgValueTooltip?: string;
}) => {
  const { updateTarget } = useTargetFormCallbacks();
  const { groupField, isShowingValidationFeedback } =
    useContext(TargetFormContext);
  const isInputInvalid =
    isShowingValidationFeedback &&
    target.categories.length > 0 &&
    target.categories[0].value === '';

  const onValueChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      const newTarget: Targets.Wizard.Target = {
        ...target,
        categories: [
          {
            ...target.categories[0],
            value: newValue,
          },
        ],
      };
      updateTarget({
        target: newTarget,
        group,
      });
    },
    [group, target, updateTarget],
  );

  if (target.categories.length === 0) {
    return null;
  }

  return (
    <Tooltip content={avgValueTooltip}>
      <div style={{ borderLeft: `1px solid ${Colors2.Grey['7']}` }}>
        <Inputs.TextInput
          state={isInputInvalid ? 'Error' : undefined}
          autoFocus={groupField === '*' && target.key === group.targets[0].key}
          data-testid="target-value-input"
          type="number"
          placeholder="Enter Target"
          value={target.categories[0].value}
          onChange={onValueChanged}
          style={{
            borderLeftColor: 'transparent',
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
          }}
          inputSize="Small"
        />
      </div>
    </Tooltip>
  );
};

export default SimpleInput;
