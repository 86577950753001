import React from 'react';
import styled from 'styled-components';
import Colors from 'theme/colors';
import Typography from 'kingpin/atoms/Typography';

import Card from 'components/Common/Card';
import Icon from '../../../kingpin/atoms/Icon';

export const WrapperOuter = styled.div`
  position: relative;
  z-index: 20;
`;

export const WrapperInner = styled.div`
  position: absolute;
  top: 4px;
  width: 300px;
`;

export const FieldGroup = styled.div`
  margin-bottom: 8px;
`;

export const ValueItem = styled.div`
  color: ${Colors.TEXT};
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 4px;

  &:hover {
    background-color: #f2f2f2;
  }
`;

export const ClickEater = styled.div<{ onClick: any }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
`;

const SearchResults = ({
  searchFields,
  searchResults,
  hasResults,
  onSearchItemClicked,
  selectedSearchResults,
  close,
}: {
  searchFields: string[];
  searchResults: MultiFieldSearchResults;
  hasResults: boolean;
  onSearchItemClicked: (field: string, value: string) => void;
  selectedSearchResults: MultiFieldSearchResults;
  close: () => void;
}) => (
  <React.Fragment>
    <WrapperOuter>
      <WrapperInner>
        <Card style={{ maxHeight: 400, overflowY: 'scroll', padding: 8 }}>
          {hasResults && (
            <React.Fragment>
              {searchFields.map((field) => (
                <FieldGroup key={field}>
                  <div>
                    <Typography.Body type={'Label'}>{field}</Typography.Body>
                  </div>
                  {searchResults[field].map((value) => (
                    <ValueItem
                      key={`${value}-${field}`}
                      onClick={() => {
                        onSearchItemClicked(field, value);
                      }}
                    >
                      <Typography.Body type={'Body 12'}>
                        {value}
                      </Typography.Body>
                      {selectedSearchResults[field] &&
                        selectedSearchResults[field].includes(value) && (
                          <Icon icon="checkmark" />
                        )}
                    </ValueItem>
                  ))}
                </FieldGroup>
              ))}
            </React.Fragment>
          )}
          {!hasResults && (
            <Typography.Body type={'Label'}>No results found</Typography.Body>
          )}
        </Card>
      </WrapperInner>
    </WrapperOuter>
    <ClickEater onClick={close} />
  </React.Fragment>
);

export default SearchResults;
