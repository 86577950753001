import { useCallback, useContext } from 'react';
import ActivityGridContext from '../../context/ActivityGridContext';

const useHoverRow = (hoverId?: string) => {
  const { setHoveredCategory } = useContext(ActivityGridContext);

  const disableHover = useCallback(() => {
    setHoveredCategory(undefined);
  }, [setHoveredCategory]);

  const triggerHover = useCallback(() => {
    setHoveredCategory(hoverId);
  }, [hoverId, setHoveredCategory]);

  return { disableHover, triggerHover };
};

export default useHoverRow;
