import React, { useContext, useEffect, useRef } from 'react';
import AreaChart from './AreaChart';
import useGoalAreaChart from '../../../../../../hooks/goals/useGoalAreaChart';
import areaOptions from 'components/V5Gadget/highchartOptions/areaOptions';
import getTimeTooltip from '../../getTimeTooltip';
import getTimeText from '../../getTimeText';
import GeneralGoalContext from '../../../../../../contexts/GeneralGoalContext';
import useValueFormatters from '../../../../../../hooks/useValueFormatters';

const AreaChartContainer = ({
  isCard,
  setTimeText,
  setTimeTooltip,
  noPadding,
  setIsAboveTarget,
}: {
  isCard?: boolean;
  setTimeText?: (text: string) => void;
  setTimeTooltip?: (text: string) => void;
  noPadding?: boolean;
  setIsAboveTarget?: (b: boolean) => void;
}) => {
  const { goal } = useContext(GeneralGoalContext);
  const { formatMetric } = useValueFormatters();

  const {
    series,
    isLoading,
    currentValue,
    performanceText,
    aboveTargetIsGood,
    isAboveTarget,
    remainingIntervals,
    dateRange,
  } = useGoalAreaChart(goal);

  useEffect(() => {
    if (dateRange && setTimeText && setTimeTooltip) {
      setTimeText(getTimeText(goal, dateRange));
      setTimeTooltip(getTimeTooltip(goal, dateRange));
    }
  }, [goal, dateRange, setTimeText, setTimeTooltip]);
  const chartRef = useRef<any>();
  const options = {
    ...areaOptions({
      title: '',
      sparkline: true,
      doubleSparkHeight: false,
      formatMetric,
    }),
    series,
  };

  useEffect(() => {
    if (chartRef && chartRef.current) {
      chartRef.current.chart.reflow();
    }
  });

  useEffect(() => {
    if (setIsAboveTarget) {
      setIsAboveTarget(isAboveTarget);
    }
  }, [isAboveTarget, setIsAboveTarget]);

  const noData = !series || !series[0] || !series[0].data;

  return (
    <AreaChart
      isCard={isCard}
      options={options}
      chartRef={chartRef}
      isLoading={isLoading}
      currentValue={currentValue}
      performanceText={performanceText}
      aboveTargetIsGood={aboveTargetIsGood}
      isAboveTarget={isAboveTarget}
      remainingIntervals={remainingIntervals}
      noPadding={noPadding}
      noData={noData}
    />
  );
};

export default AreaChartContainer;
