import React, { useCallback, useContext, useState } from 'react';
import PerformanceWizardContext from './PerformanceWizardContext';
import DatasetDefinitionsContext from '../../../../../contexts/DatasetDefinitionsContext';
import GqlClientContext from '../../../../../contexts/GqlClientContext';
import Button from 'kingpin/atoms/Button';
import updatePerformanceDatasetConfig from './updatePerformanceDatasetConfig';
import LocalTimelineProvider from '../../../../../contextProviders/TimelineProvider/LocalTimelineProvider';
import useCreateUpdateTimelineEvents from './useCreateUpdateTimelineEvents';

const UpdateButton = () => {
  const { refreshPerformanceConfigs } = useContext(DatasetDefinitionsContext);
  const { client } = useContext(GqlClientContext);
  const { wizardState, setIsSaving, isSaving, close } = useContext(
    PerformanceWizardContext,
  );
  const createUpdateTimelineEvents = useCreateUpdateTimelineEvents();

  const getUpdateArgs = useCallback(():
    | PerformanceConfiguration.UpdatePerformanceDatasetArgs
    | undefined => {
    if (!wizardState.id) {
      const err = new Error();
      err.name = `getUpdateArgs: Wizard State Id not defined`;
      throw err;
    }
    const { startDate, entityWindowLength, entityWindowDateField } =
      wizardState.config.datasetStep;
    const { metrics } = wizardState.config.metricsStep;
    const { fields } = wizardState.config.fieldsStep;

    if (metrics.length > 0 && !!startDate) {
      return {
        id: wizardState.id,
        entityWindowLength,
        entityWindowDateField,
        metrics: metrics.map((m) => ({
          metricId: m.metricId,
          fieldName: m.fieldName,
          datePeriodType: m.datePeriodType,
          datePeriodLength: m.datePeriodLength,
          dateField: m.dateField,
        })),
        fields,
        startDate,
      };
    }

    return undefined;
  }, [
    wizardState.config.datasetStep,
    wizardState.config.fieldsStep,
    wizardState.config.metricsStep,
    wizardState.id,
  ]);

  const onUpdateClicked = useCallback(async () => {
    if (isSaving || !wizardState.isEditing) {
      return;
    }
    const updateArgs = getUpdateArgs();
    if (!updateArgs) {
      return;
    }

    setIsSaving(true);
    await updatePerformanceDatasetConfig({
      client,
      updateArgs,
    });
    await createUpdateTimelineEvents();
    await refreshPerformanceConfigs();
    setIsSaving(false);
    close();
  }, [
    createUpdateTimelineEvents,
    client,
    close,
    getUpdateArgs,
    isSaving,
    refreshPerformanceConfigs,
    setIsSaving,
    wizardState.isEditing,
  ]);

  if (!wizardState.isEditing) {
    return null;
  }

  return (
    <Button
      type="Primary"
      size="Small"
      onClick={onUpdateClicked}
      label="Update dataset"
      isDisabled={isSaving}
    />
  );
};

const Gate = () => {
  const { wizardState, isReadOnly } = useContext(PerformanceWizardContext);
  const [timelineDestination] = useState<Timeline.EventSource>({
    type: 'PerformanceDataset' as 'PerformanceDataset',
    id: wizardState.id ? wizardState.id : 'na',
  });

  if (timelineDestination.id === 'na' || timelineDestination.id === undefined) {
    return null;
  }

  if (isReadOnly) {
    return null;
  }

  return (
    <LocalTimelineProvider
      destination={timelineDestination}
      sources={window.emptyArray}
    >
      <UpdateButton />
    </LocalTimelineProvider>
  );
};

export default Gate;
