import React, { useContext, useCallback } from 'react';
import { ModalTransition } from '@atlaskit/modal-dialog';
import Button from 'kingpin/atoms/Button';

import Colors2 from '../../../theme/Colors2';
import Typography from 'kingpin/atoms/Typography';
import Row from '../../Common/Row';
import TargetFormContext from '../contexts/TargetFormContext';
import useHasUnsavedChanges from '../hooks/useHasUnsavedChanges';
import useTargetSaveConfirmation from '../hooks/useTargetSaveConfirmation';
import TargetListContext from '../contexts/TargetListContext';

const TRANSLAND_PERF_APP_ID = '5c3f6e4c-66ed-4580-ba1d-a09f9de9381f';

const SaveButton = () => {
  const { isSaving, onSaveClickedConfirmed } = useContext(TargetFormContext);
  const { isAddTargetPrimary } = useContext(TargetListContext);
  const isTranslandTargets = window.location.href.includes(
    TRANSLAND_PERF_APP_ID,
  );

  const hasUnsavedChanges = useHasUnsavedChanges();
  const { TargetSaveConfirmation, getConfirmation } =
    useTargetSaveConfirmation();

  const onSaveClicked = useCallback(async () => {
    const confirmed = await getConfirmation();
    if (!confirmed) {
      return;
    }
    onSaveClickedConfirmed();
  }, [getConfirmation, onSaveClickedConfirmed]);

  return (
    <>
      <Row centerAlign>
        {hasUnsavedChanges && (
          <div style={{ marginRight: 14 }}>
            <Typography.Body type="Body 12" color={Colors2.Secondary.error}>
              Unsaved Changes
            </Typography.Body>
          </div>
        )}
        {isAddTargetPrimary && (
          <Button
            isDisabled={!hasUnsavedChanges || isTranslandTargets}
            onClick={onSaveClicked}
            isLoading={isSaving}
            label="Save Target"
            type="Tertiary"
            size="Small"
          />
        )}
        {!isAddTargetPrimary && (
          <Button
            isDisabled={!hasUnsavedChanges || isTranslandTargets}
            onClick={onSaveClicked}
            isLoading={isSaving}
            label="Save Target"
            type="Primary"
            size="Small"
          />
        )}
      </Row>

      <ModalTransition>{TargetSaveConfirmation}</ModalTransition>
    </>
  );
};

export default SaveButton;
