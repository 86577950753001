import React from 'react';
import NewCommitPopup from './NewCommitPopup';
import withDateFilter from '../../../hocs/withDateFIlter';
import { LAST_180_DAYS } from '../../DateInput/constants';

const NewCommitPopupContainer = () => {
  return <NewCommitPopup />;
};

export default withDateFilter(NewCommitPopupContainer, {
  initialRelativeDateRange: LAST_180_DAYS,
});
