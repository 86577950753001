import React from 'react';

import KeywordFilterPlate from './KeywordFilterPlate';
import RangeFilterPlate from './RangeFilterPlate';
import WildcardFilterPlate from './WildcardFilterPlate';
import BooleanFilterPlate from './BooleanFilterPlate';
import MustExistFilterPlate from './MustExistFilterPlate';
import MustNotExistFilterPlate from './MustNotExistFilterPlate';
import styled from 'styled-components';

const FilterItemWrapper = styled.div`
  margin-bottom: 8px;
`;

const FilterPlates = ({ filters }: { filters: FilterInput }) => {
  const {
    keywords,
    ranges,
    wildcardFilters,
    booleanFilters,
    mustExistFilters,
    mustNotExistFilters,
  } = filters;

  return (
    <>
      {!!keywords &&
        keywords.map((kw) => (
          <FilterItemWrapper key={Math.random()}>
            <KeywordFilterPlate filter={kw} />
          </FilterItemWrapper>
        ))}
      {!!ranges &&
        ranges.map((r) => (
          <FilterItemWrapper key={Math.random()}>
            <RangeFilterPlate filter={r} />
          </FilterItemWrapper>
        ))}
      {!!wildcardFilters &&
        wildcardFilters.map((f) => (
          <FilterItemWrapper key={Math.random()}>
            <WildcardFilterPlate filter={f} />
          </FilterItemWrapper>
        ))}
      {!!booleanFilters &&
        booleanFilters.map((f) => (
          <FilterItemWrapper key={Math.random()}>
            <BooleanFilterPlate filter={f} />
          </FilterItemWrapper>
        ))}
      {mustExistFilters &&
        mustExistFilters.map((f) => (
          <FilterItemWrapper key={Math.random()}>
            <MustExistFilterPlate field={f} />
          </FilterItemWrapper>
        ))}
      {mustNotExistFilters &&
        mustNotExistFilters.map((f) => (
          <FilterItemWrapper key={Math.random()}>
            <MustNotExistFilterPlate field={f} />
          </FilterItemWrapper>
        ))}
    </>
  );
};

export default FilterPlates;
