import { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import chartColors from 'theme/chartColors';
import Colors2 from 'theme/Colors2';

import DATASET_TREND_BUTTON from '../constants';
import useSeries from './useSeries';

const useOptions = ({ metric }: { metric: MetricInput }) => {
  const {
    series,
    categories,
    interval,
    isLoading: isLoadingSeries,
  } = useSeries({
    metric,
  });
  const [options, setOptions] = useState<Highcharts.Options>({ series });

  useEffect(() => {
    setOptions({
      colors: chartColors,
      series,
      accessibility: {
        enabled: false,
      },
      chart: {
        marginTop: 16,
        type: 'line',
        backgroundColor: 'transparent',
        height: DATASET_TREND_BUTTON.TREND_HEIGHT,
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        categories,
        title: {
          text: undefined,
        },
        crosshair: {
          color: Colors2.Grey['4'],
          width: 2,
        },
        tickColor: Colors2.Grey['8'],
        lineColor: Colors2.Grey['8'],
        tickInterval: 1,
        labels: {
          style: {
            color: Colors2.Grey['7'],
          },
        },
      },
      yAxis: {
        gridLineColor: Colors2.Grey['8'],
        title: {
          text: undefined,
        },
        allowDecimals: false,
        labels: {
          reserveSpace: false,
          align: 'left',
          x: 1,
          y: -4,
          style: {
            color: Colors2.Grey['7'],
          },
        },
      },
      plotOptions: {
        series: {
          marker: {
            animation: false,
          },
        },
      },
      credits: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
      tooltip: {
        shared: false,
        shadow: false,
        borderRadius: 8,
        padding: 0,
        outside: true,
        split: false,
        borderWidth: 0,
        backgroundColor: 'white',
      },
    });
  }, [categories, series]);

  return { options, isLoading: isLoadingSeries, interval };
};

export default useOptions;
