import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import Row from 'components/Common/Row';
import Button from 'kingpin/atoms/Button';
import Typography from 'kingpin/atoms/Typography';
import Colors2 from 'theme/Colors2';

import { ReactComponent as InfoIcon } from './info.svg';
import AnalyticsContext from '../../../contexts/AnalyticsContext';

const Banner = styled.div`
  padding: 16px;
  background-color: ${Colors2.Grey['8']};
  border-radius: 8px;
`;

const WhatIsSso = () => {
  const { trackEvent } = useContext(AnalyticsContext);
  const onLearnMoreClicked = useCallback(() => {
    trackEvent('SSO Configurations - Clicked Learn More');
  }, [trackEvent]);

  return (
    <Banner>
      <Row spaceBetween centerAlign>
        <Row>
          <div style={{ marginRight: 8 }}>
            <InfoIcon />
          </div>
          <div>
            <div style={{ marginBottom: 4 }}>
              <Typography.Body type="Body 12">What is SSO</Typography.Body>
            </div>
            <div>
              <Typography.Body type="Body 12">
                SSO (Single Sign-On) allows users to use a single set of login
                credentials to access multiple applications or systems.
              </Typography.Body>
            </div>
          </div>
        </Row>
        <div>
          <a
            href="https://help.fleetops.com/en/articles/8408168-set-up-single-sign-on-sso-using-microsoft#h_da7213f8ce"
            target="_blank"
            rel="noreferrer"
          >
            <Button
              type="Tertiary"
              size="Small"
              onClick={onLearnMoreClicked}
              label="Learn More"
            />
          </a>
        </div>
      </Row>
    </Banner>
  );
};

export default WhatIsSso;
