import React from 'react';
import OnboardingContext from './OnboardingContext';

const OnboardingProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  return (
    <OnboardingContext.Provider
      value={{
        isOnboarding: true,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export default OnboardingProvider;
