import React from 'react';
import Row from 'components/Common/Row';
import Inputs from 'components/Inputs';
import Typography from 'kingpin/atoms/Typography';
import Button from 'kingpin/atoms/Button';
import Colors2 from 'theme/Colors2';
import useFieldPickerState from './useFieldPickerState';

const FieldsListHeading = ({
  fields,
  setFields,
  dataset,
}: {
  fields: string[];
  setFields: React.Dispatch<React.SetStateAction<string[]>>;
  dataset: string;
}) => {
  const { selectedFields, onFieldsConfirmed, datasetGreenList } =
    useFieldPickerState({
      fields,
      dataset,
      setFields,
    });

  return (
    <Row centerAlign spaceBetween style={{ marginBottom: 16 }}>
      {fields.length > 0 && (
        <Typography.Body type="Label">
          Added Fields{' '}
          <span style={{ color: Colors2.Grey['5'] }}>({fields.length})</span>
        </Typography.Body>
      )}
      <Inputs.FieldsPicker
        datasetsGreenList={datasetGreenList}
        selectedFields={selectedFields}
        onFieldsConfirmed={onFieldsConfirmed}
        isPerformanceDatasetsAllowed
        Button={(props) => (
          <Button
            type="Tertiary"
            size="Small"
            onClick={props.onClick}
            label={'Add field(s)'}
          />
        )}
      />
    </Row>
  );
};

export default FieldsListHeading;
