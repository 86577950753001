import React, { useContext, useEffect, useState } from 'react';
import ChartTypePicker from './ChartTypePicker';
import WidgetGalleryContext from '../../../contexts/WidgetGalleryContext';
import ALL_CHART_TYPES_OPTION from './allChartTypesOption';
import { V5_GADGET_TYPES } from 'components/GadgetForm';

const BASE_CHART_TYPES: (
  | GadgetType
  | 'SingleMetricDateMatrix'
  | 'PaceMatrix'
)[] = [...V5_GADGET_TYPES, 'SingleMetricDateMatrix', 'PaceMatrix'];

const SPECIAL_CHART_TYPE = {
  label: 'Matrix (% of total, Rank)',
  value: 'RankingMatrix' as 'RankingMatrix',
};

const ChartTypePickerContainer = () => {
  const [options, setOptions] = useState<DropdownOption[]>([]);
  const { selectedChartType, setSelectedChartType } =
    useContext(WidgetGalleryContext);

  useEffect(() => {
    const noneOption = {
      label: ALL_CHART_TYPES_OPTION,
      isSelected: selectedChartType === undefined,
      onSelected: () => {
        setSelectedChartType(undefined);
      },
    };

    const baseOptions = BASE_CHART_TYPES.map((type) => ({
      label: type,
      isSelected: selectedChartType === type,
      onSelected: () => {
        setSelectedChartType(type);
      },
    }));

    const rankingOption = {
      label: SPECIAL_CHART_TYPE.label,
      isSelected: selectedChartType === SPECIAL_CHART_TYPE.value,
      onSelected: () => {
        setSelectedChartType(SPECIAL_CHART_TYPE.value);
      },
    };

    setOptions([noneOption, ...baseOptions, rankingOption]);
  }, [selectedChartType, setSelectedChartType]);

  return <ChartTypePicker options={options} />;
};

export default ChartTypePickerContainer;
