import React, { useCallback, useEffect, useState } from 'react';
import Inputs from '../../../components/Inputs';
import useUpdateView from './FieldSlideOut/useUpdateView';
import useTrackSettingsChanged from './FieldSlideOut/useTrackFieldSettingsChanged';
import { GEO_POINT_CELL } from '../../../components/Grid/constants';

const getCellTypes = (field: FleetOps.Field): FleetOps.CellType[] => {
  switch (field.type) {
    case 'long':
    case 'float':
    case 'int':
      return ['Currency', 'Percentage', 'Number', 'Status Flag'];
    case 'date':
      return ['Date'];
    case 'geo_point':
      return [GEO_POINT_CELL];
    case 'text':
    default:
    case 'boolean':
      return ['Text'];
  }
};

const NUMBER_CELLS = ['Currency', 'Number', 'Percentage'];

export const buildFormatting = (
  cellType: FleetOps.CellType,
  currencyType?: CurrencyType,
): MetricFormatting => {
  switch (cellType) {
    case 'Currency':
      return {
        prefix: 'currency',
        postfix: undefined,
        currencyType,
        precision: 2,
        positiveDeltaIsGood: true,
      };
    case 'Number':
      return {
        prefix: undefined,
        postfix: undefined,
        precision: 2,
        positiveDeltaIsGood: true,
      };
    case 'Percentage':
      return {
        prefix: undefined,
        postfix: 'percentage',
        precision: 2,
        positiveDeltaIsGood: true,
      };
    case 'Status Flag':
    case 'Date':
    case 'Text':
    default:
      return {
        prefix: undefined,
        postfix: undefined,
        precision: undefined,
        positiveDeltaIsGood: undefined,
      };
  }
};

const useCellTypeOptions = ({
  field,
  fieldView,
  baseView,
}: {
  field: FleetOps.Field;
  fieldView: FleetOps.BaseViewField;
  baseView: FleetOps.BaseView;
}) => {
  const updateView = useUpdateView({ field, baseView });
  const [cellTypes] = useState(getCellTypes(field));
  const trackSettingsChanged = useTrackSettingsChanged({ field, baseView });

  const getCellTypeOptions = useCallback(
    () =>
      cellTypes.map((t) => ({
        label: t,
        value: t,
        onSelected: () => {
          const newView = {
            ...fieldView,
            cellType: t,
            formatting: buildFormatting(t),
            precision:
              NUMBER_CELLS.includes(t) && field.type === 'float'
                ? 2
                : fieldView.precision,
          };

          updateView(newView);
          trackSettingsChanged();
        },
      })),
    [cellTypes, field.type, fieldView, trackSettingsChanged, updateView],
  );
  const [cellTypeOptions, setCellTypeOptions] =
    useState<DropdownOption[]>(getCellTypeOptions);
  useEffect(() => {
    setCellTypeOptions(getCellTypeOptions());
  }, [getCellTypeOptions]);

  return cellTypeOptions;
};

const FieldCellTypeDropdown = ({
  field,
  fieldView,
  baseView,
  testId,
}: {
  field: FleetOps.Field;
  fieldView: FleetOps.BaseViewField;
  baseView: FleetOps.BaseView;
  testId?: string;
}) => {
  const cellTypeOptions = useCellTypeOptions({ field, fieldView, baseView });

  return (
    <Inputs.Dropdown
      options={cellTypeOptions}
      testId={testId}
      selectedLabel={fieldView.cellType ? fieldView.cellType : 'None'}
    />
  );
};

export default FieldCellTypeDropdown;
