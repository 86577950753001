import { useCallback, useContext } from 'react';
import DatasetDefinitionsContext from '../contexts/DatasetDefinitionsContext';

const useGetDatasetDefinition = () => {
  const { datasets } = useContext(DatasetDefinitionsContext);
  const getDatasetDefinition = useCallback(
    (dataset: string): FleetOps.DatasetDefinition | undefined => {
      return datasets.find((d) => d.type === dataset);
    },
    [datasets],
  );

  return getDatasetDefinition;
};

export default useGetDatasetDefinition;
