import React, { useCallback, useContext, useEffect, useState } from 'react';

import RolesContext from '../../contexts/RolesContext';

const Has = ({
  requiredPermission,
  children,
  fallback,
}: {
  requiredPermission: FleetOps.Permission;
  children: JSX.Element | JSX.Element[];
  fallback?: JSX.Element | JSX.Element[];
}) => {
  const { currentPermissions } = useContext(RolesContext);
  const getIsVisible = useCallback(
    () => currentPermissions.includes(requiredPermission),
    [currentPermissions, requiredPermission],
  );
  const [isVisible, setIsVisible] = useState<boolean>(getIsVisible);

  useEffect(() => {
    setIsVisible(getIsVisible());
  }, [currentPermissions, getIsVisible, requiredPermission]);

  if (isVisible) {
    return <>{children}</>;
  }

  if (fallback) {
    return <>{fallback}</>;
  }

  return null;
};

export default Has;
