import { useContext, useEffect, useState } from 'react';
import AccountPickerContext from '../../contexts/AccountPickerContext';
import store from '../../store';

const useAllWorkSpaces = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [allWorkSpaces, setAllWorkSpaces] = useState(
    [] as (WorkSpace | TargetsApp.App)[],
  );
  const [otherWorkSpaces, setOtherWorkSpaces] = useState([] as WorkSpace[]);
  const [targetApps, setTargetApps] = useState([] as TargetsApp.App[]);
  const { selectedAccountId } = useContext(AccountPickerContext);

  useEffect(() => {
    const listener = store
      .getWorkspacesRef({
        accountId: selectedAccountId,
      })
      .onSnapshot((snapshot) => {
        const data: WorkSpace[] = [];
        snapshot.docs.forEach((d) => {
          const newWorkspace: WorkSpace = d.data();
          if (!newWorkspace.id) {
            newWorkspace.id = d.id;
          }

          data.push(newWorkspace);
        });
        setOtherWorkSpaces(
          // TODO: Filter out workspaces which contain boards
          // as they will be migrated to target apps
          // @ts-ignore
          data.filter(
            (ws) =>
              !ws.isHidden &&
              // @ts-ignore
              ws.campaignType !== 'projectManagement' &&
              // @ts-ignore
              ws.campaignType !== 'performance',
          ),
        );
        setIsLoading(false);
      });
    return () => {
      listener();
    };
  }, [selectedAccountId]);

  useEffect(() => {
    const l = store.contentDistributions
      .getTargetsApps({ accountId: selectedAccountId })
      .onSnapshot((snapshot) => {
        setTargetApps(snapshot.docs.map((d) => d.data()));
      });

    return () => {
      l();
    };
  }, [selectedAccountId]);

  useEffect(() => {
    setAllWorkSpaces([...otherWorkSpaces, ...targetApps]);
  }, [otherWorkSpaces, targetApps]);

  return {
    allWorkSpaces,
    isLoading,
  };
};

export default useAllWorkSpaces;
