import React from 'react';

interface PopupContextType {
  selectedReport?: PersistedReportType;
  setSelectedReport: React.Dispatch<
    React.SetStateAction<PersistedReportType | undefined>
  >;
  selectedBoard?: PerformanceBoardTypes.Board | Board;
  setSelectedBoard: React.Dispatch<
    React.SetStateAction<PerformanceBoardTypes.Board | Board | undefined>
  >;
  dashboardGadget?: DashboardGadget;
  setDashboardGadget: React.Dispatch<
    React.SetStateAction<DashboardGadget | undefined>
  >;
  setUnSavedFilter: React.Dispatch<
    React.SetStateAction<UnSavedFilter | undefined>
  >;
  isOpen: boolean;
  close: () => void;
  openPopupReport: (
    drillDowns: ReportDrillDownType[],
    origin: 'Dashboard' | 'Goal' | 'Scorecard',
    scorecardPeriodSettings?: {
      selectedPeriod: Period;
      availablePeriods: Period[];
      kpi: Scorecards.ScorecardKpi | Scorecards.ManualKpiRow;
    },
  ) => void;
  openPopupBoard: ({
    popupFilters,
    scope,
    bonusPeriodId,
    popupDateRange,
    origin,
    scorecardPeriodSettings,
  }: {
    popupFilters: FilterPlate[];
    scope: FilterPlate[];
    bonusPeriodId?: string;
    popupDateRange?: DateRangeInput;
    origin: 'Dashboard' | 'Goal' | 'Scorecard';
    scorecardPeriodSettings?: {
      selectedPeriod: Period;
      availablePeriods: Period[];
      kpi: Scorecards.ScorecardKpi;
    };
  }) => void;
  poppedUpKpi?: Scorecards.ScorecardKpi | Scorecards.ManualKpiRow;
  origin?: 'Dashboard' | 'Goal' | 'Scorecard';
}

const PopupContext = React.createContext<PopupContextType>(
  {} as PopupContextType,
);

export default PopupContext;
