import React, { useContext } from 'react';
import ResetReportButton from './ResetReportButton';
import ReportContext from '../../../contexts/ReportContext';

const ResetReportButtonContainer = () => {
  const { reset } = useContext(ReportContext);

  return <ResetReportButton onClick={reset} />;
};

export default ResetReportButtonContainer;
