/* eslint-disable no-undef */
import React, { useContext } from 'react';
import CurrentUserContext from '../../contexts/CurrentUserContext';
import ImpersonatorContext from '../../contexts/ImpersonatorContext';

const FleetOpsStaffOnly = ({
  children,
  fallback,
}: {
  children: JSX.Element | JSX.Element[];
  fallback?: JSX.Element | JSX.Element[];
}) => {
  const { impersonatorId } = useContext(ImpersonatorContext);
  const { isFleetOpsStaff } = useContext(CurrentUserContext);

  if (isFleetOpsStaff && impersonatorId === undefined) {
    return <React.Fragment>{children}</React.Fragment>;
  } else if (fallback) {
    return <>{fallback}</>;
  } else {
    return null;
  }
};

export default FleetOpsStaffOnly;
