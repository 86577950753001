import isV5ChartDef from '../../types/visTypeCheckers/isV5ChartDef';
import isSingleMetricDateMatrix from '../../types/visTypeCheckers/isSingleMetricDateMatrix';
import isRankingMatrix from '../../types/visTypeCheckers/isRankingMatrix';
import isGauge from '../../types/visTypeCheckers/isGauge';
import isRemindersGadget from '../../types/visTypeCheckers/isRemindersGadget';
import captureException from '../../services/captureException';
import isPaceMatrix from '../../types/visTypeCheckers/isPaceMatrix';

const isUsedInChart = (
  metric: Metrics.NormalMetric | Metrics.CompoundMetric,
  def: VisualisationDefinition,
) => {
  if (isV5ChartDef(def)) {
    return def.series.some((s) => s.metricId === metric.id);
  } else if (isSingleMetricDateMatrix(def)) {
    return def.metricId === metric.id;
  } else if (isRankingMatrix(def)) {
    return def.metrics.some((m) => m.metricId === metric.id);
  } else if (isGauge(def)) {
    return def.metricId === metric.id;
  } else if (isRemindersGadget(def)) {
    return false;
  } else if (isPaceMatrix(def)) {
    return def.metricId === metric.id;
  } else {
    const e = new Error();
    e.name = `Unknown definition type`;
    captureException(e);
    return false;
  }
};

export default isUsedInChart;
