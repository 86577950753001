import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Typography from 'kingpin/atoms/Typography';

import Row from '../../Common/Row';
import toSentenceCase from '../../../services/toSentenceCase';
import chartDefinitionPropType from '../../../propTypes/chartDefinition';
import ErrorBoundary from '../../Common/ErrorBoundary';
import V5Gadget from '../../V5Gadget';
import isV5ChartDef from '../../../types/visTypeCheckers/isV5ChartDef';
import { Circle } from 'rc-progress';
import colors from '../../../theme/colors';
import SingleMetricDateMatrixContainer from '../../SingleMetricDateMatrix';
import isSingleMetricDateMatrix from '../../../types/visTypeCheckers/isSingleMetricDateMatrix';
import isRankingMatrix from '../../../types/visTypeCheckers/isRankingMatrix';
import RankingMatrix from '../../RankingMatrix';
import isGauge from '../../../types/visTypeCheckers/isGauge';
import Gauge from '../../Gauge';
import Colors2 from '../../../theme/Colors2';

const PreviewDescription = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`;

const WidgetPreview = ({
  id,
  name,
  description,
  type,
  isPreviewingChart,
  onMouseOver,
  onMouseOut,
  definition,
  revealProgress,
}: {
  id: string;
  name: string;
  description?: string;
  type: string;
  isPreviewingChart: boolean;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  definition: VisualisationDefinition;
  revealProgress: number;
}) => (
  <React.Fragment>
    {!isPreviewingChart && (
      <div
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseOut}
        style={{
          flex: 1,
          flexDirection: 'column',
          alignItems: 'flex-start',
          height: '100%',
          marginBottom: 16,
          padding: 32,
        }}
      >
        <Row
          style={{ justifyContent: 'space-between', alignItems: 'flex-start' }}
        >
          <div>
            <div style={{ marginBottom: 24 }}>
              <Typography.Header type={'H5'}>{name}</Typography.Header>
            </div>
            <div>
              <Typography.Body type="Body 14">{description}</Typography.Body>
            </div>
            <div>
              <Typography.Body type="Body 14">
                {toSentenceCase(type)}
              </Typography.Body>
            </div>
            <div>
              <Typography.Body type="Body 12" color={Colors2.Grey['5']}>
                {id}
              </Typography.Body>
            </div>
          </div>
          {revealProgress > 0 && (
            <div style={{ width: 40 }}>
              <Circle
                strokeWidth={12}
                trailWidth={8}
                percent={revealProgress}
                strokeColor={colors.FLEETOPS_RED}
                trailColor={'#CCCCCC'}
              />
            </div>
          )}
        </Row>
      </div>
    )}

    {isPreviewingChart && (
      <React.Fragment>
        <PreviewDescription>
          <span>{`(${description})`}</span>
        </PreviewDescription>
        <ErrorBoundary>
          <div style={{ display: 'flex', flex: 1, height: '100%' }}>
            {isV5ChartDef(definition) && (
              <V5Gadget chartDefinition={definition} />
            )}
            {isSingleMetricDateMatrix(definition) && (
              <SingleMetricDateMatrixContainer gadget={definition} />
            )}
            {isRankingMatrix(definition) && (
              <RankingMatrix gadget={definition} />
            )}
            {isGauge(definition) && <Gauge gauge={definition} />}
          </div>
        </ErrorBoundary>
      </React.Fragment>
    )}
  </React.Fragment>
);

WidgetPreview.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  isPreviewingChart: PropTypes.bool,
  showPreview: PropTypes.func,
  definition: chartDefinitionPropType,
  isGrid: PropTypes.bool,
  isV4: PropTypes.bool,
};

export default WidgetPreview;
