import React, { useCallback, useContext, useEffect, useState } from 'react';
import GqlClientContext from '../../../contexts/GqlClientContext';
import EntityDefinitionsContext from '../../../contexts/EntityDefinitionsContext';
import runAggregation from '../runAggregation';
import isDefined from '../../../isDefined';
import useBuildSearchArgs from './useBuildSearchArgs';
import useBuildResultMatch from './useBuildResultMatch';
import useSortMatches from './useSortMatches';

const DEBOUNCE_MS = process.env.NODE_ENV === 'test' ? 200 : 1000;

const DATE_SCOPE: DateRangeInput = {};

const useResults = ({
  searchText,
  isActiveFilterDisabled,
  setIsLoading,
  setIsNoResults,
}: {
  searchText: string;
  isActiveFilterDisabled: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsNoResults: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { client } = useContext(GqlClientContext);
  const { entityDefinitions } = useContext(EntityDefinitionsContext);
  const [results, setResults] = useState<GlobalSearch.SearchResults>({
    results: [],
    searchText,
  });
  const buildSearchArgs = useBuildSearchArgs();
  const buildResultMatch = useBuildResultMatch();
  const sortMatches = useSortMatches();

  const performanceSearch = useCallback(async () => {
    const enabledEntities = entityDefinitions
      .filter((e) => e.isEnabled)
      .sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        } else if (a.name === b.name) {
          return 0;
        } else {
          return -1;
        }
      });
    const promises = enabledEntities.map(async (entity) => {
      const searchArgs = buildSearchArgs({
        entity,
        searchText,
        isActiveFilterDisabled,
      });
      const { contextMetrics, secondaryMetrics, filters, groupBy } = searchArgs;
      const metrics = [...secondaryMetrics, ...contextMetrics];
      const response = await runAggregation({
        client,
        metrics,
        dateScope: DATE_SCOPE,
        filters,
        groupBy,
      });

      const matches = response
        .map((r) => buildResultMatch({ response: r, searchArgs, entity }))
        .filter(isDefined);
      const sortedMatches = sortMatches({ matches, searchText });

      const result: GlobalSearch.Result = {
        searchText,
        entity,
        matches: sortedMatches,
      };

      return result;
    });

    const responses = await Promise.all(promises);
    const result: GlobalSearch.SearchResults = {
      searchText,
      results: responses,
    };

    setIsNoResults(responses.every((r) => r.matches.length === 0));

    return result;
  }, [
    buildResultMatch,
    buildSearchArgs,
    client,
    entityDefinitions,
    isActiveFilterDisabled,
    searchText,
    setIsNoResults,
    sortMatches,
  ]);

  useEffect(() => {
    if (searchText.length === 0) {
      return;
    }
    let isActive = true;
    setIsLoading(true);

    setTimeout(async () => {
      if (!isActive) {
        return;
      }

      const newResults = await performanceSearch();
      if (!isActive) {
        return;
      }
      setResults(newResults);
      setIsLoading(false);
    }, DEBOUNCE_MS);

    return () => {
      isActive = false;
    };
  }, [performanceSearch, searchText.length, setIsLoading]);

  return {
    results,
    setResults,
  };
};

export default useResults;
