const VIEW_DATA: FleetOps.Permission = 'fleetops.permissions.view_data';
const VIEW_REVENUE: FleetOps.Permission = 'fleetops.permissions.view_revenue';
const VIEW_ACCIDENTS: FleetOps.Permission =
  'fleetops.permissions.view_accidents';
const VIEW_DRIVER_PAY: FleetOps.Permission =
  'fleetops.permissions.view_driver_pay';

const VIEW_DATA_PERMISSIONS = {
  VIEW_DATA,
  VIEW_REVENUE,
  VIEW_ACCIDENTS,
  VIEW_DRIVER_PAY,
};
Object.freeze(VIEW_DATA_PERMISSIONS);

export default VIEW_DATA_PERMISSIONS;
