import { useCallback, useContext, useEffect, useState } from 'react';
import EntityDefinitionsContext from '../contexts/EntityDefinitionsContext';

export const useGetEntity = () => {
  const { entityDefinitions } = useContext(EntityDefinitionsContext);

  const getEntity = useCallback(
    (entityId: string): EntityDetails.Entity | undefined => {
      return entityDefinitions.find(
        (entityDefinition) => entityDefinition.id === entityId,
      );
    },
    [entityDefinitions],
  );

  return getEntity;
};

const useGetEntityApp = () => {
  const { entityDetailsApps } = useContext(EntityDefinitionsContext);

  const getEntityApp = useCallback(
    (entityId: string): EntityDetails.App | undefined => {
      return entityDetailsApps.find((a) => a.entityId === entityId);
    },
    [entityDetailsApps],
  );

  return getEntityApp;
};

const useEntity = (entityId: string) => {
  const getEntity = useGetEntity();
  const getEntityApp = useGetEntityApp();
  const [entity, setEntity] = useState<EntityDetails.Entity | undefined>(() =>
    getEntity(entityId),
  );
  useEffect(() => {
    setEntity(getEntity(entityId));
  }, [entityId, getEntity]);

  const [app, setApp] = useState<EntityDetails.App | undefined>(() =>
    getEntityApp(entityId),
  );
  useEffect(() => {
    setApp(getEntityApp(entityId));
  }, [entityId, getEntityApp]);

  return { entity, app };
};

export default useEntity;
