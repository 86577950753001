import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import ReactPortal from './ReactPortal';
import { FADE_IN_ELEMENT_ID, Z_INDEX } from '../constants';

interface FadeInProps {
  height: number;
  $isVisible: boolean;
}

const FadeInDiv = styled(motion.div)<FadeInProps>`
  height: ${(props) => props.height}px;
  background-color: white;
  z-index: ${Z_INDEX.SLIDE_OUT};
  position: absolute;
  width: 100vw;
  right: 0px;
  bottom: 0px;
  display: ${(props) => (props.$isVisible ? 'block' : 'none')};
`;

const variants = {
  opened: {
    opacity: 1,
    transition: {
      default: { duration: 0.15 },
    },
  },
  closed: {
    opacity: 0,
  },
};

const FadeIn = ({
  isOpen,
  children,
}: {
  isOpen: boolean;
  children: JSX.Element | JSX.Element[] | false;
}) => {
  const getHeight = useCallback(() => {
    return window.document.body.clientHeight;
  }, []);
  const [height, setHeight] = useState<number>(getHeight);
  const onResize = useCallback(() => {
    setHeight(getHeight());
  }, [getHeight]);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [getHeight, onResize]);

  return (
    <ReactPortal elementId={FADE_IN_ELEMENT_ID}>
      <FadeInDiv
        height={height}
        animate={isOpen ? 'opened' : 'closed'}
        initial="closed"
        variants={variants}
        $isVisible={isOpen}
      >
        {children}
      </FadeInDiv>
    </ReactPortal>
  );
};

export default FadeIn;
