import React, { useCallback, useState } from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import Typography from 'kingpin/atoms/Typography';
import usePopup from '../../../../hooks/usePopup';
import KpiForm from '../KpiForm';
import KPIInputs from './KPIInputs';

const KPIInputsContainer = ({
  kpis,
  setKpis,
  cadence,
  startDate,
  endDate,
}: {
  kpis: GoalKPI[];
  setKpis: React.Dispatch<React.SetStateAction<GoalKPI[]>>;
  cadence: FleetOps.Interval;
  startDate: string;
  endDate?: string;
}) => {
  const { isOpen, open, close } = usePopup();
  const [editingKpi, setEditingKpi] = useState<GoalKPI | undefined>();
  const onKpiDeleted = useCallback(
    (kpi: GoalKPI) => {
      setKpis((currentKpis) => currentKpis.filter((k) => k.id !== kpi.id));
    },
    [setKpis],
  );

  const onClose = useCallback(() => {
    close();
    setEditingKpi(undefined);
  }, [close]);
  const onKpiSaved = useCallback(
    async (kpi: GoalKPI) => {
      setKpis((currentKpis) => {
        if (currentKpis.some((k) => k.id === kpi.id)) {
          return currentKpis.map((k) => {
            if (k.id === kpi.id) {
              return kpi;
            }
            return k;
          });
        } else {
          return [...currentKpis, kpi];
        }
      });
      onClose();
    },
    [onClose, setKpis],
  );
  const onKpiStartEdit = useCallback(
    (kpi: GoalKPI) => {
      setEditingKpi(kpi);
      open();
    },
    [open],
  );
  const onAddKpiClicked = useCallback(() => {
    open();
  }, [open]);

  return (
    <>
      <Typography.Body type="Label">Key Performance Indicators</Typography.Body>
      <KPIInputs
        kpis={kpis}
        onKpiDeleted={onKpiDeleted}
        onAddKpiClicked={onAddKpiClicked}
        onKpiStartEdit={onKpiStartEdit}
      />
      <ModalTransition>
        {isOpen && (
          <Modal shouldScrollInViewport={false} autoFocus={false}>
            <KpiForm
              kpi={editingKpi}
              onClose={onClose}
              onSave={onKpiSaved}
              cadence={cadence}
              startDate={startDate}
              endDate={endDate}
            />
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};

export default KPIInputsContainer;
