import React from 'react';
import { ReportDrillDownFormStage } from 'contexts/ReportDrillDownFormContext';

import Colors from 'theme/colors';
import styled from 'styled-components';
import FieldSelectionTop from './FieldSelectionTop';
import ValueSelectionTop from './ValueSelectionTop';
import FieldPicker from './FieldPicker';
import BooleanPicker from './BooleanPicker';
import RangePicker from './RangePicker';
import DatePicker from './DatePicker';
import TextDrillDownForm from './TextDrillDownForm';

const Top = styled.div`
  background-color: ${Colors.WHITE};
  padding: 20px 8px;
`;

const Body = styled.div`
  background-color: ${Colors.BLUE_GREY};

  box-shadow: 0 0 0 1px rgba(53, 45, 67, 0.07);
`;

const ReportDrillDownForm = ({
  stage,
  fieldType,
}: {
  stage: ReportDrillDownFormStage;
  fieldType?: FleetOps.FieldType;
}) => (
  <div style={{ fontSize: 11 }}>
    <Top>
      {stage === 'fieldSelection' && <FieldSelectionTop />}
      {stage === 'valueSelection' && <ValueSelectionTop />}
    </Top>
    <Body>
      {fieldType === undefined && <FieldPicker />}
      {fieldType === 'text' && <TextDrillDownForm />}
      {(fieldType === 'dateText' || fieldType === 'date') && <DatePicker />}
      {fieldType === 'boolean' && <BooleanPicker />}
      {(fieldType === 'float' || fieldType === 'long') && <RangePicker />}
    </Body>
  </div>
);

export default ReportDrillDownForm;
