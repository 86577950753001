import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

const assignReasonCode = async ({
  documentId,
  documentType,
  reasonCodes,
  reasonNote,
  client,
}: {
  documentId: string;
  documentType: string;
  reasonCodes: ReasonCode[];
  reasonNote?: string | null;
  client: ApolloClient<NormalizedCacheObject>;
}): Promise<void> => {
  const mutation = {
    mutation: gql`
      mutation AssignReasonCodes2(
        $dataType: String!
        $id: String!
        $reasonCodes: [String!]!
        $reasonNote: String
      ) {
        assignReasonCodes2(
          dataType: $dataType
          id: $id
          reasonCodes: $reasonCodes
          reasonNote: $reasonNote
        )
      }
    `,
    variables: {
      id: documentId,
      dataType: documentType,
      reasonCodes: reasonCodes.map((c) => c.code),
      reasonNote: reasonNote === null ? undefined : reasonNote,
    },
  };

  await client.mutate(mutation);
};

export default assignReasonCode;
