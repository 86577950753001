import React from 'react';

interface DashboardGadgetContextType {
  dashboardGadget: DashboardGadget;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}

const DashboardGadgetContext = React.createContext<DashboardGadgetContextType>(
  {} as DashboardGadgetContextType,
);

export default DashboardGadgetContext;
