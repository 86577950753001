import React from 'react';
import EntityDefinitionsProvider from '../EntityDefinitionsProvider';
import SavedFiltersProvider from '../SavedFiltersProvider';
import WallboardsProvider from '../WallboardsProvider';
import DashboardsProvider from '../DashboardsProvider';
import WorkSpacesProvider from '../WorkSpacesProvider';
import BoardsProvider from '../BoardsProvider';
import ScorecardsProvider from '../ScorecardsProvider';
import ReportsProvider from '../ReportsProvider';
import ChartDefinitionsProvider from '../ChartDefinitionsProvider';
import GoalsProvider from '../GoalsProvider';
import DashboardGadgetsProvider from '../DashboardGadgetsProvider';

const ContentProviders = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => (
  <EntityDefinitionsProvider>
    <SavedFiltersProvider>
      <WallboardsProvider>
        <DashboardsProvider>
          <WorkSpacesProvider>
            <BoardsProvider>
              <ScorecardsProvider>
                <ReportsProvider>
                  <ChartDefinitionsProvider>
                    <GoalsProvider>
                      <DashboardGadgetsProvider>
                        {children}
                      </DashboardGadgetsProvider>
                    </GoalsProvider>
                  </ChartDefinitionsProvider>
                </ReportsProvider>
              </ScorecardsProvider>
            </BoardsProvider>
          </WorkSpacesProvider>
        </DashboardsProvider>
      </WallboardsProvider>
    </SavedFiltersProvider>
  </EntityDefinitionsProvider>
);

export default ContentProviders;
