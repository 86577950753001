import React from 'react';
import styled, { css } from 'styled-components';
import Icon from './Icon';
import Loading from '../../components/Loading/Loading';
import Typography from './Typography';
import Colors2 from 'theme/Colors2';

type TextInputVariant = 'Grid' | 'Date';
type TextInputSize = 'Small' | 'Medium';
type TextInputState = 'Disabled' | 'Error';
export type TextInputType =
  | 'text'
  | 'number'
  | 'password'
  | 'date'
  | 'checkbox'
  | 'email';

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  testId?: string;
  label?: string;
  placeholder?: string;
  inputSize?: TextInputSize;
  state?: TextInputState;
  inputVariant?: TextInputVariant;
  type?: TextInputType;
  errorMessage?: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  ['data-testid']?: string;
  icon?: Kingpin.Icon;
  autoFocus?: boolean;
  width?: string;
  maxLength?: number;
  isLoading?: boolean;
  isTransparentBorders?: boolean;
  forwardRef?: React.MutableRefObject<HTMLInputElement | null>;
  isAutocompleteDisabled?: boolean;
  /**
   * @deprecated Use standartized styles defined in TextInputVariant.
   */
  style?: React.CSSProperties | undefined;
}

const HEIGHT_MEDIUM = 36;
const HEIGHT_SMALL = 32;

const PlaceholderCss = css<{ height: number }>`
  &::placeholder {
    color: #8e8e8e;

    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: ${(props) => props.height}px;
  }
`;

const GridInputCss = css`
  max-height: 22px;
  min-height: 22px;
  text-align: right;
`;

const DateInputCss = css`
  padding: 0;
  text-align: center;
`;

const MediumCss = css<{
  inputSize: TextInputSize;
  inputState?: TextInputState;
  hasIcon: boolean;
}>`
  padding: ${(props) => (props.hasIcon ? '6px 12px 6px 28px' : '6px 12px')};
`;

const SmallCss = css<{
  inputSize: TextInputSize;
  inputState?: TextInputState;
  hasIcon: boolean;
}>`
  padding: ${(props) => (props.hasIcon ? '3px 12px 3px 28px' : '3px 12px')};
`;

const DefaultStateCss = css<{ isTransparentBorders?: boolean }>`
  border: 1px solid
    ${(props) => (props.isTransparentBorders ? 'transparent' : '#e0e0e0')};
  background: #fff;

  box-shadow:
    0px 3px 2px -1px rgba(0, 0, 0, 0.02),
    0px 1px 1px -1px rgba(0, 0, 0, 0.04);
`;

const DisabledStateCss = css`
  border: 1px solid #e0e0e0;
  background: #f6f6f6;

  box-shadow:
    0px 3px 2px -1px rgba(0, 0, 0, 0.02),
    0px 1px 1px -1px rgba(0, 0, 0, 0.04);

  &:hover {
    cursor: not-allowed;
  }
`;

const FocusedStateCss = css<{
  inputState?: TextInputState;
}>`
  &:focus {
    border: ${(props) =>
      props.inputState === 'Error'
        ? `1px solid ${Colors2.Secondary.error}`
        : '1px solid #1252f7'};
    background: #fff;

    box-shadow: 0px 0px 0px 2px rgba(93, 138, 255, 0.25);
  }

  &:focus-within {
    outline: unset;
  }
`;

const ErrorStateCss = css`
  border: 1px solid ${Colors2.Secondary.error};
  background: #fff;

  /* Shadow/Button */
  box-shadow:
    0px 3px 2px -1px rgba(0, 0, 0, 0.02),
    0px 1px 1px -1px rgba(0, 0, 0, 0.04);
`;

const Input = styled.input<{
  inputSize: TextInputSize;
  inputState?: TextInputState;
  inputVariant?: TextInputVariant;
  hasIcon: boolean;
  width?: string;
  height: number;
  isTransparentBorders?: boolean;
}>`
  display: flex;
  height: ${(props) => props.height}px;
  min-height: ${(props) => props.height}px;
  width: ${(props) => props.width || '100%'};
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: ${(props) => props.height}px;

  ${(props) => props.hasIcon && 'padding-left: 16px;'}

  ${PlaceholderCss};
  // Size
  ${({ inputSize }) => inputSize === 'Small' && SmallCss}
  ${({ inputSize }) => inputSize === 'Medium' && MediumCss}
  
  // State
  ${({ inputState }) => {
    switch (inputState) {
      case 'Disabled':
        return DisabledStateCss;
      case 'Error':
        return ErrorStateCss;
      default:
        return DefaultStateCss;
    }
  }}

  ${(props) => props.inputState !== 'Disabled' && FocusedStateCss}

  // Variant
  ${({ inputVariant }) => {
    switch (inputVariant) {
      case 'Grid':
        return GridInputCss;
      case 'Date':
        return DateInputCss;
      default:
        return '';
    }
  }}
`;

const ErrorSpan = styled.span`
  color: ${Colors2.Secondary.error};

  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const ErrorDiv = styled.div`
  margin-top: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    height: 16px;
    width: 16px;
    margin-right: 8px;
  }
`;

const ICON_HEIGHT = 16;
const InputIcon = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 8px;
  margin: auto 0;
  height: ${ICON_HEIGHT}px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  svg {
    height: ${ICON_HEIGHT}px;
    width: ${ICON_HEIGHT}px;
  }
`;

const LoadingIcon = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 8px;
  margin: auto 0;
  height: ${ICON_HEIGHT}px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  svg {
    height: ${ICON_HEIGHT}px;
    width: ${ICON_HEIGHT}px;
  }
`;

const getHeight = (size: TextInputSize | undefined) => {
  switch (size) {
    case 'Medium':
      return HEIGHT_MEDIUM;
    case 'Small':
    default:
      return HEIGHT_SMALL;
  }
};

const TextInput = (props: TextInputProps) => {
  const height = getHeight(props.inputSize);

  const Component = (
    <Input
      {...props}
      inputSize={props.inputSize || 'Small'}
      inputState={props.state}
      inputVariant={props.inputVariant}
      type={props.type || 'text'}
      hasIcon={!!props.icon}
      readOnly={props.state === 'Disabled'}
      disabled={props.state === 'Disabled'}
      autoComplete={props.isAutocompleteDisabled ? 'new-password' : 'undefined'}
      height={height}
      ref={props.forwardRef}
    />
  );

  const { label, errorMessage, icon, isLoading } = props;

  // Allow for inline rendering
  if (!label && !errorMessage && !icon && !isLoading) {
    return <>{Component}</>;
  }

  // More complex states cannot be used inline
  return (
    <div style={{ width: '100%' }}>
      {label && (
        <div style={{ marginBottom: 4 }}>
          <Typography.Body type={'Label'}>{label}</Typography.Body>
        </div>
      )}
      <div style={{ position: 'relative' }}>
        {icon && (
          <InputIcon>
            <Icon icon={icon} color={'#8E8E8E'} />
          </InputIcon>
        )}
        {Component}
        {isLoading && (
          <LoadingIcon>
            <Loading isTiny />
          </LoadingIcon>
        )}
      </div>
      {errorMessage && (
        <ErrorDiv>
          <Icon icon="cross" color={Colors2.Secondary.error} />
          <ErrorSpan>{errorMessage}</ErrorSpan>
        </ErrorDiv>
      )}
    </div>
  );
};

export default TextInput;
