import React, { useCallback, useContext, useEffect, useState } from 'react';
import PerformanceWizardContext from '../PerformanceWizardContext';
import Typography from 'kingpin/atoms/Typography';
import Row from '../../../../../../components/Common/Row';
import Button from 'kingpin/atoms/Button';
import GlowingDiv from '../../../../../../components/TargetManager/GlowingDiv';

const makeFirstCharacterCapital = (s: string) =>
  s[0].toUpperCase() + s.slice(1);

const DatasetNameSuggestion = () => {
  const { wizardState, setWizardState, isReadOnly } = useContext(
    PerformanceWizardContext,
  );

  const getSuggestedName = useCallback(() => {
    const { entity, interval } = wizardState.config.datasetStep;
    if (!entity || !interval) {
      return undefined;
    }
    const entityText = makeFirstCharacterCapital(entity);
    const intervalText = makeFirstCharacterCapital(interval);

    return `performance${entityText}${intervalText}s`;
  }, [wizardState.config.datasetStep]);
  const [suggestedName, setSuggestedName] = useState<string | undefined>(() =>
    getSuggestedName(),
  );
  useEffect(() => {
    setSuggestedName(getSuggestedName());
  }, [getSuggestedName]);

  const onClick = useCallback(() => {
    setWizardState((state) => ({
      ...state,
      config: {
        ...state.config,
        datasetStep: {
          ...state.config.datasetStep,
          name: suggestedName,
        },
      },
    }));
  }, [setWizardState, suggestedName]);

  if (
    isReadOnly ||
    !suggestedName ||
    suggestedName === wizardState.config.datasetStep.name
  ) {
    return null;
  }

  return (
    <div style={{ marginTop: 4 }}>
      <GlowingDiv glowOnMount>
        <Row centerAlign spaceBetween style={{ padding: 2 }}>
          <Typography.Body type="Body 12">{`Suggested Name: ${suggestedName}`}</Typography.Body>
          <Button onClick={onClick} label={'Apply'} type="Ghost" size="Small" />
        </Row>
      </GlowingDiv>
    </div>
  );
};

export default DatasetNameSuggestion;
