import * as aguid from 'aguid';
import CUSTOMER_NAMES from './customerNames';
import CITY_NAMES from './cityNames';
import COMMODITIES from './commodities';
import PERSON_NAMES from './personNames';
import NUMBERS from './numbers';
import THREE_LETTER_CODES from './threeLetterCodes';
import FIVE_LETTER_CODES from './fiveLetterCodes';
import TRUCK_MODELS from './truckModels';
import DATES from './dates';
import TRUCK_MANUFACTURERS from './truckManufacturers';
import TRAILER_TYPES from './trailerTypes';
import CITY_STATE_LANES from './cityStateLanes';
import numberToCommaString from '../useValueFormatters/numberToCommaString';

const CITY_FIELDS = [
  'destinationCity',
  'startCityAndState',
  'finishCityAndState',
  'nextCityAndState',
  'originCity',
  'destinationZone',
  'finishLocation',
  'truckFleetDescription',
  'nextCityAndState',
];
const CITY_STATE_FIELDS = ['cityStateLane', 'cityStateLanes', 'lane'];

const STATE_FIELDS = ['originState', 'destinationState', 'originZone'];

const NAME_FIELDS = [
  'driver',
  'driverName',
  'fleetManagerName',
  'fleetManager',
  'operationsUser',
  'customerServiceRep',
];

const RANDOM_NUMBER_FIELDS = [
  'revenueCode',
  'trailer',
  'reasonCode',
  'movementId',
  'truckFleet',
  'truck',
  'driverFleet',
];

const THREE_LETTER_CODE_FIELDS = ['deduct_code_id', 'owner'];
const FIVE_LETTER_CODE_FIELDS = ['payee_id', 'orderNo'];
const TRUCK_MODEL_FIELDS = ['model'];
const COMMODITY_FIELDS = ['commodity', 'commodities'];
const DATE_FIELDS = ['date'];
const TRUCK_MANUFACTURER_FIELDS = ['make'];
const TRAILER_TYPE_FIELDS = ['trailer_type'];
const COMPANY_FIELDS = [
  'driverType',
  'customerName',
  'customer',
  'billTo',
  'billToName',
];

const obfuscate = ({
  field,
  value,
  isDollarValue,
  isPercentage,
}: {
  field?: string;
  value?: string | string[] | number;
  isDollarValue?: boolean;
  isPercentage?: boolean;
}): string => {
  if (isPercentage && typeof value === 'number') {
    return (value * (Math.random() * (1.1 - 1.01) + 1.01)).toString();
  }

  if (isDollarValue && !Array.isArray(value) && value !== undefined) {
    const asNumber =
      typeof value == 'number'
        ? value
        : Number.parseFloat(value.replace('$', '').replaceAll(',', '').trim());
    const s = Math.random() * (1.1 - 1.01) + 1.01;
    const r = Math.floor(asNumber * s);
    return `$${numberToCommaString({ value: r })}`;
  }

  const key = aguid(Array.isArray(value) ? value.join(',') : value);
  const index = parseInt(key, 16);
  const getValue = (arr: string[]): string => arr[index % arr.length];
  if (!field) {
    return '-';
  }

  if (CITY_FIELDS.includes(field)) {
    return getValue(CITY_NAMES);
  } else if (NAME_FIELDS.includes(field)) {
    return getValue(PERSON_NAMES);
  } else if (RANDOM_NUMBER_FIELDS.includes(field)) {
    return getValue(NUMBERS);
  } else if (THREE_LETTER_CODE_FIELDS.includes(field)) {
    return getValue(THREE_LETTER_CODES);
  } else if (FIVE_LETTER_CODE_FIELDS.includes(field)) {
    return getValue(FIVE_LETTER_CODES);
  } else if (TRUCK_MODEL_FIELDS.includes(field)) {
    return getValue(TRUCK_MODELS);
  } else if (COMMODITY_FIELDS.includes(field)) {
    return getValue(COMMODITIES);
  } else if (DATE_FIELDS.includes(field) || field.includes('date')) {
    return getValue(DATES);
  } else if (TRUCK_MANUFACTURER_FIELDS.includes(field)) {
    return getValue(TRUCK_MANUFACTURERS);
  } else if (TRAILER_TYPE_FIELDS.includes(field)) {
    return getValue(TRAILER_TYPES);
  } else if (COMPANY_FIELDS.includes(field)) {
    return getValue(CUSTOMER_NAMES);
  } else if (
    field === 'dayOfWeek' &&
    value !== undefined &&
    !Array.isArray(value)
  ) {
    return value.toString();
  } else if (STATE_FIELDS.includes(field)) {
    return getValue(STATE_FIELDS);
  } else if (field === 'status') {
    return 'Active';
  } else if (CITY_STATE_FIELDS.includes(field)) {
    return getValue(CITY_STATE_LANES);
  }

  return field;
};

export default obfuscate;
