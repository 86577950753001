import { useEffect, useState } from 'react';
import useV5FixedTrendCardDefinition from './useV5FixedTrendCardDefinition';
import useV5ChartData from './useV5ChartData';
import { getItemFor } from '../components/V5Gadget/trendCardHelpers';

const useV5FixedTrendCard = (chartDefinition: V5ChartDefinition) => {
  const [trendItems, setTrendItems] = useState<TrendCardItem[]>([]);

  const { fixedTrendCardDefinition } =
    useV5FixedTrendCardDefinition(chartDefinition);

  const { data, isLoading } = useV5ChartData(fixedTrendCardDefinition);

  useEffect(() => {
    if (!data) {
      return;
    }

    const { response, metric } = Object.values(data)[0];
    const items = [];
    const metricId = chartDefinition.series[0].metricId;
    const { formatting } = metric;
    const { precision } = formatting;
    items.push(
      getItemFor({
        offset: 0,
        label: 'Last Full Month',
        data: response,
        precision,
        metricId,
      }),
    );
    items.push(
      getItemFor({
        offset: 1,
        label: 'Previous Month',
        data: response,
        precision,
        metricId,
      }),
    );
    items.push(
      getItemFor({
        offset: 3,
        label: '3 Months earlier',
        data: response,
        precision,
        metricId,
      }),
    );
    items.push(
      getItemFor({
        offset: 6,
        label: '6 Months earlier',
        data: response,
        precision,
        metricId,
      }),
    );
    items.push(
      getItemFor({
        offset: 12,
        label: '12 months earlier',
        data: response,
        precision,
        metricId,
      }),
    );
    const validItems = items.filter((i) => i !== undefined) as TrendCardItem[];
    setTrendItems(validItems);
  }, [isLoading, data, chartDefinition.series]);

  return {
    trendItems,
    isLoading,
  };
};

export default useV5FixedTrendCard;
