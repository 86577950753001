import { useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import ChartDefinitionsContext from '../../contexts/ChartDefinitionsContext';
import isV5ChartDef from '../../types/visTypeCheckers/isV5ChartDef';
import isSingleMetricDateMatrix from '../../types/visTypeCheckers/isSingleMetricDateMatrix';
import isDefined from '../../isDefined';
import canvasContentTypeCheckers from '../../types/cardTypeCheckers';
import MetricOptionsContext from '../../contexts/MetricOptionsContext';
import isRankingMatrix from '../../types/visTypeCheckers/isRankingMatrix';
import isGauge from '../../types/visTypeCheckers/isGauge';
import captureException from '../../services/captureException';
import metricTypeCheckers from '../../types/metricTypeCheckers';

const useMetricsOnReport = (report: PersistedReportType | undefined) => {
  const { metricOptions, normalMetrics } = useContext(MetricOptionsContext);
  const { definitions } = useContext(ChartDefinitionsContext);

  const [metrics, setMetrics] = useState<Metrics.NormalMetric[]>([]);
  const [compoundMetrics, setCompoundMetrics] = useState<
    Metrics.CompoundMetric[]
  >([]);
  const [specialMetrics, setSpecialMetrics] = useState<Metrics.SpecialMetric[]>(
    [],
  );
  const [usedDefinitions, setUsedDefinitions] = useState<
    VisualisationDefinition[]
  >([]);

  useEffect(() => {
    if (!report) {
      setUsedDefinitions([]);
      return;
    }

    const usedDefinitions = report.canvas.cards
      .map((c) => {
        if (canvasContentTypeCheckers.isChartDefinition(c)) {
          return definitions.find((d) => d.id === c.content.chartDefinitionId);
        }

        return undefined;
      })
      .filter(isDefined);
    setUsedDefinitions(usedDefinitions);
  }, [definitions, report]);

  useEffect(() => {
    if (!report) {
      return;
    }

    const unknownDefinitionType = usedDefinitions.find((d) => {
      const isV5 = isV5ChartDef(d);
      const isRankingMatrixG = isRankingMatrix(d);
      const isSingleMetricDate = isSingleMetricDateMatrix(d);
      const isGaugeG = isGauge(d);
      const isKnownType =
        isV5 || isRankingMatrixG || isSingleMetricDate || isGaugeG;
      if (isKnownType) {
        return false;
      }
      return true;
    });
    if (unknownDefinitionType) {
      const e = new Error();
      e.name = `Unknown Definition Type in Report: ${unknownDefinitionType.name} - ${report.id}`;
      captureException(e);
    }
  }, [report, usedDefinitions]);

  useEffect(() => {
    const metricIdFromSingleMetricDateMatrix = usedDefinitions
      .filter(isSingleMetricDateMatrix)
      .map((d) => d.metricId);
    const metricIdsFromRankingMatrix = usedDefinitions
      .filter(isRankingMatrix)
      .map((d) => d.metrics.map((m) => m.metricId))
      .reduce((acc, mIds) => {
        return [...acc, ...mIds];
      }, []);
    const metricIdsFromGauges = usedDefinitions
      .filter(isGauge)
      .map((g) => g.metricId);

    const metricIdsFromOtherChartTypes = [
      ...metricIdFromSingleMetricDateMatrix,
      ...metricIdsFromRankingMatrix,
      ...metricIdsFromGauges,
    ];

    const fromMatrix = (() => {
      const m = [] as Metrics.NormalMetric[];
      const cm = [] as Metrics.CompoundMetric[];
      const sm = [] as Metrics.SpecialMetric[];

      metricIdsFromOtherChartTypes.forEach((mId) => {
        const metric = metricOptions.find((m) => m.id === mId);
        if (metric) {
          if (metricTypeCheckers.isNormalMetric(metric)) {
            m.push(metric);
          } else if (metricTypeCheckers.isCompoundMetric(metric)) {
            cm.push(metric);

            const inputMetrics = metric.metricIds
              .map((inputMId) => {
                return normalMetrics.find((nm) => nm.id === inputMId);
              })
              .filter(metricTypeCheckers.isNormalMetric)
              .filter(isDefined);
            m.push(...inputMetrics);
          } else if (metricTypeCheckers.isSpecialMetric(metric)) {
            sm.push(metric);
          }
        }
      });

      return {
        m,
        cm,
        sm,
      };
    })();

    const fromSeries = (() => {
      const m = [] as Metrics.NormalMetric[];
      const cm = [] as Metrics.CompoundMetric[];
      const sm = [] as Metrics.SpecialMetric[];

      usedDefinitions.filter(isV5ChartDef).forEach((def) => {
        const { series } = def;
        series.forEach((s) => {
          const metric = metricOptions.find((m) => m.id === s.metricId);
          if (metric) {
            if (metricTypeCheckers.isNormalMetric(metric)) {
              m.push(metric);
            } else if (metricTypeCheckers.isCompoundMetric(metric)) {
              cm.push(metric);

              const inputMetrics = metric.metricIds
                .map((inputMId) => {
                  return normalMetrics.find((nm) => nm.id === inputMId);
                })
                .filter(isDefined)
                .filter(metricTypeCheckers.isNormalMetric);
              m.push(...inputMetrics);
            } else if (metricTypeCheckers.isSpecialMetric(metric)) {
              sm.push(metric);
            }
          }
        });
      });

      return {
        m,
        cm,
        sm,
      };
    })();

    setMetrics(_.uniq([...fromMatrix.m, ...fromSeries.m]));
    setCompoundMetrics(_.uniq([...fromMatrix.cm, ...fromSeries.cm]));
    setSpecialMetrics(_.uniq([...fromMatrix.sm, ...fromSeries.sm]));
  }, [metricOptions, normalMetrics, usedDefinitions]);

  return {
    metrics,
    compoundMetrics,
    specialMetrics,
  };
};

export default useMetricsOnReport;
