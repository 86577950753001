import React, { useContext } from 'react';
import FavouritesContext from '../../contexts/FavouritesContext';
import NavSideBarContext from 'contexts/NavSideBarContext';
import NavItem from './NavItem';
import Icon from 'kingpin/atoms/Icon';

const MainNavFavouritesSection = () => {
  const { openSlideout, isOpenSlideout, closeSlideout } =
    useContext(FavouritesContext);
  const { isOpen } = useContext(NavSideBarContext);

  return (
    <NavItem
      to={'#'}
      onClick={isOpenSlideout ? closeSlideout : openSlideout}
      tooltip={'Favorites'}
      label={'Favorites'}
      icon="start - filled"
      isActiveOverride={isOpenSlideout}
      renderExtra={
        <>
          {isOpen ? (
            <div
              style={{ display: 'flex', position: 'absolute', right: '8px' }}
            >
              <Icon icon="chevron-right" />
            </div>
          ) : null}
        </>
      }
      alternativeHighLight
    />
  );
};

export default MainNavFavouritesSection;
