const isV5ChartDef = (
  def?: any | ExtendedVisualisationDefinition,
): def is V5ChartDefinition => {
  return (
    def !== undefined &&
    def.series !== undefined &&
    def.gadgetType !== undefined
  );
};

export default isV5ChartDef;
