import { ApisauceInstance } from 'apisauce';

const sendMicrosoftGuestLinkEmail = ({
  api,
  email,
}: {
  api: ApisauceInstance;
  email: string;
}) =>
  api.post('microsoftLinkGuestAccountsSendLinkingEmail', {
    email,
  });

export default sendMicrosoftGuestLinkEmail;
