import { useCallback } from 'react';

const getSubstrings = ({
  searchText,
  value,
}: {
  searchText: string;
  value: string;
}): number => {
  return value.toLowerCase().split(searchText.toLowerCase()).length;
};

const useSortMatches = () => {
  const sortMatches = useCallback(
    ({
      matches,
      searchText,
    }: {
      matches: GlobalSearch.ResultMatch[];
      searchText: string;
    }) => {
      const matchesWithSubstrings = matches.map((match) => ({
        match,
        subStrings: getSubstrings({
          searchText,
          value: match.primaryFieldValue,
        }),
      }));

      const sortedMatches = matchesWithSubstrings
        .sort((a, b) => {
          const aV = a.subStrings;
          const bV = b.subStrings;
          if (aV > bV) {
            return -1;
          } else if (aV === bV) {
            if (a.match.primaryFieldValue > b.match.primaryFieldValue) {
              return 1;
            } else if (
              a.match.primaryFieldValue === b.match.primaryFieldValue
            ) {
              return 0;
            } else {
              return -1;
            }
          } else {
            return 1;
          }
        })
        .map((m) => m.match);

      return sortedMatches;
    },
    [],
  );

  return sortMatches;
};

export default useSortMatches;
