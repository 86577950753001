import { useCallback, useEffect, useState } from 'react';

const useIsTargetValid = ({
  groupField,
  groups,
  effectiveDate,
}: {
  groupField?: string;
  groups: Targets.Wizard.TargetGroup[];
  effectiveDate?: string;
}) => {
  const validateTarget = useCallback((): boolean => {
    const isMissingGroupField = groupField === undefined;

    const isAGroupMissingName =
      groupField !== '*' &&
      groups
        .filter((g) => !g.isFallback)
        .some((g) => g.groupName === undefined || g.groupName.length === 0);

    const allTargets = groups
      .map((g) => g.targets)
      .reduce((a, b) => [...a, ...b], []);

    const isAGroupMissingRules = allTargets
      .filter((t) => !t.isFallback)
      .some((t) => t.rules.length === 0);

    const hasAFallbackTargetGotRules = allTargets
      .filter((t) => t.isFallback)
      .some((t) => t.rules.length > 0);

    const hasUnsetTargetValue = allTargets.some((t) =>
      t.categories.some((c) => c.value === ''),
    );

    const hasUndefinedOutputField = allTargets.some((t) =>
      t.categories.some((c) =>
        c.outputs.some(
          (o) => o.fieldValue === undefined || o.fieldValue === '',
        ),
      ),
    );

    if (
      isAGroupMissingName ||
      isAGroupMissingRules ||
      isMissingGroupField ||
      hasAFallbackTargetGotRules ||
      hasUnsetTargetValue ||
      effectiveDate === undefined ||
      hasUndefinedOutputField
    ) {
      return false;
    }

    return true;
  }, [effectiveDate, groupField, groups]);

  const [isValid, setIsValid] = useState<boolean>(() => validateTarget());
  useEffect(() => {
    setIsValid(validateTarget());
  }, [validateTarget]);

  return isValid;
};

export default useIsTargetValid;
