import React from 'react';
import styled from 'styled-components';

const MenuPositioner = styled.div`
  transition-property: left, top, width, height;
  transition-timing-function: ease;
  border-radius: 3px;
  pointer-events: auto;
`;

const AbsoluteContainerOuter = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 10000;
`;

const AbsoluteContainerInner = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  pointer-events: none;
`;

const MenuPosition = ({
  children,
  closeMenu,
}: {
  children: JSX.Element | JSX.Element[];
  closeMenu: () => void;
}) => {
  return (
    <AbsoluteContainerOuter onClick={closeMenu}>
      <AbsoluteContainerInner>
        <MenuPositioner
          style={{
            position: 'absolute',
            left: 20,
            top: 20,
          }}
        >
          {children}
        </MenuPositioner>
      </AbsoluteContainerInner>
    </AbsoluteContainerOuter>
  );
};

export default MenuPosition;
