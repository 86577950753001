import React, { useContext } from 'react';
import Actions from './Actions';
import V5GadgetFormContext from '../../../contexts/V5GadgetFormContext';
import useVisualisationUsageInfo from '../../../hooks/useGadgetUsageInfo';

const useCanDelete = () => {
  const { draftChart, selectedSource } = useContext(V5GadgetFormContext);
  const { reports } = useVisualisationUsageInfo(draftChart);
  return reports.length === 0 && selectedSource !== 'global';
};

const ActionsContainer = () => {
  const { onSave, onDelete, isEditing, isLoading, onSaveAs } =
    useContext(V5GadgetFormContext);
  const canDelete = useCanDelete();

  return (
    <Actions
      onSave={onSave}
      onSaveAs={onSaveAs}
      onDelete={onDelete}
      isEditing={isEditing}
      isLoading={isLoading}
      canDelete={canDelete}
    />
  );
};

export default ActionsContainer;
