import React from 'react';
import styled from 'styled-components';

import Row from 'components/Common/Row';
import Typography from 'kingpin/atoms/Typography';
import colors from 'theme/colors';
import Colors2 from '../../theme/Colors2';

const OptionCircleOuter = styled.div<{
  isSelected: boolean;
  isDisabled?: boolean;
}>`
  border: 2px solid
    ${(props) => (props.isSelected ? colors.NAVY_BLUE : Colors2.Grey['5'])};
  background-color: white;
  width: 18px;
  height: 18px;
  border-radius: 11px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.isDisabled &&
    `
    cursor: not-allowed;
    background-color: ${Colors2.Grey['8']};
  `}
`;

const OptionCircleInner = styled.div<{ isDisabled?: boolean }>`
  width: 12px;
  height: 12px;
  border-radius: 8px;
  border: 2px solid white;

  ${(props) =>
    props.isDisabled &&
    `
    cursor: not-allowed;
  `}
  background-color: ${colors.NAVY_BLUE};
`;

const RadioOptionRow = styled(Row)<{ isDisabled?: boolean }>`
  cursor: pointer;
  ${(props) =>
    props.isDisabled &&
    `
    cursor: not-allowed;
  `}
  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
`;

export const RadioOption = ({ option }: { option: RadioOption }) => {
  const { isDisabled } = option;
  return (
    <RadioOptionRow
      centerAlign
      onClick={isDisabled ? undefined : option.onSelected}
      data-testid={option.testId}
      isDisabled={isDisabled}
    >
      <OptionCircleOuter isSelected={option.isSelected} isDisabled={isDisabled}>
        {option.isSelected && <OptionCircleInner isDisabled={isDisabled} />}
      </OptionCircleOuter>
      <Typography.Body type="Body 12">{option.label}</Typography.Body>
    </RadioOptionRow>
  );
};

const Radio = ({ options }: { options: RadioOption[] }) => {
  return (
    <div>
      {options.map((option) => (
        <RadioOption option={option} key={option.key} />
      ))}
    </div>
  );
};

export default Radio;
