import { useCallback, useContext, useEffect, useState } from 'react';

import MetricOptionsContext from '../../../../../contexts/MetricOptionsContext';
import LocalTimelineContext from '../../../../../contexts/Timeline/LocalTimelineContext';
import AccountPickerContext from '../../../../../contexts/AccountPickerContext';
import isDefined from '../../../../../isDefined';
import updateMetric from '../../../../../api/metrics/updateMetric';
import metricTypeCheckers from '../../../../../types/metricTypeCheckers';

const useInputMetricsPublisher = (
  metricDraft: Metrics.NormalMetric | Metrics.CompoundMetric | undefined,
) => {
  // We can't use the CompoundMetricContext lookup here as the compound metric
  // might not have been created yet.
  const { normalMetrics } = useContext(MetricOptionsContext);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const { addEvent } = useContext(LocalTimelineContext);
  const [inputMetrics, setInputMetrics] = useState<Metrics.NormalMetric[]>([]);

  useEffect(() => {
    if (!metricDraft || metricTypeCheckers.isNormalMetric(metricDraft)) {
      setInputMetrics([]);
      return;
    }

    // Can't use the lookup here as this metric is not reflective of what
    // is in the store
    setInputMetrics(
      metricDraft.metricIds
        .map((mid) => normalMetrics.find((m) => m.id === mid))
        .filter(isDefined)
        .filter(metricTypeCheckers.isNormalMetric),
    );
  }, [metricDraft, normalMetrics]);

  const publishInputMetrics = useCallback(
    async (newStatus: Metrics.MetricStatus) => {
      if (
        metricDraft === undefined ||
        newStatus !== 'core' ||
        metricTypeCheckers.isNormalMetric(metricDraft)
      ) {
        return;
      }

      const promises = inputMetrics.map(async (m) => {
        if (m.status === 'core') {
          return;
        }

        const newM = {
          ...m,
          status: 'core' as 'core',
        };

        return updateMetric(m.id, newM, selectedAccountId).then(async () => {
          const destination = {
            type: 'Metric' as 'Metric',
            id: m.id,
          };
          if (addEvent) {
            await addEvent({
              actionText: 'Added metric to core list',
              contextText: `"${newM.name}"`,
              destinationOverride: destination,
            });
          }
        });
      });

      await Promise.all(promises);
    },
    [selectedAccountId, addEvent, inputMetrics, metricDraft],
  );

  return publishInputMetrics;
};

export default useInputMetricsPublisher;
