import React from 'react';
import BoardsContext from '../contexts/BoardsContext';
import useBoards from '../hooks/useBoards';
import FeatureGate, { FEATURE_GATES } from '../components/FeatureGate';

const BoardsProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const boards = useBoards();

  return (
    <BoardsContext.Provider value={{ boards }}>
      {children}
    </BoardsContext.Provider>
  );
};

const Gate = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  return (
    <FeatureGate featureName={FEATURE_GATES.PERFORMANCE} fallback={children}>
      <BoardsProvider>{children}</BoardsProvider>
    </FeatureGate>
  );
};

export default Gate;
