import base from './baseOptions';

const sparkChart = (formatMetric, doubleSparkHeight) => {
  return {
    ...base(formatMetric).chart,
    type: 'area',
    margin: [2, 0, 2, 0],
    height: doubleSparkHeight ? 80 : 40,
    backgroundColor: null,
    spacingBottom: 0,
    marginBottom: -20,
  };
};

const normalChart = (formatMetric) => {
  return {
    ...base(formatMetric).chart,
    type: 'area',
    marginBottom: 0,
    marginTop: 67,
    marginLeft: -4,
    marginRight: -4,
  };
};

const area = ({ formatMetric, title, sparkline, doubleSparkHeight }) => ({
  ...base(formatMetric),
  chart: sparkline
    ? sparkChart(formatMetric, doubleSparkHeight)
    : normalChart(formatMetric),
  title: {
    ...base(formatMetric).title,
    x: 12,
    text: title,
  },
  xAxis: {
    visible: false,
    type: 'datetime',
    ...base(formatMetric).xAxis[0],
  },
  yAxis: {
    ...base(formatMetric).yAxis[0],
    visible: false,
  },
  legend: {
    enabled: false,
  },
  tooltip: {
    outside: true,
    enabled: sparkline ? true : false,
    pointFormatter: function () {
      const { metricId } = this.series.userOptions;
      const value = formatMetric({
        value: this.y,
        metricId,
      });

      return `<span style="font-size: 10px; font-family: Inter; color: #252525; line-height: 12px"><b>${value}</b></span>`;
    },
    headerFormat:
      '<span style="font-size: 12px; line-height: 15px; font-family: Inter;">{point.key}: </span>',
  },
  plotOptions: {
    area: {
      zoneAxis: 'y',
      cursor: 'pointer',
      marker: {
        enabled: false,
        symbol: 'circle',
        radius: 2,
        states: {
          hover: {
            enabled: true,
          },
        },
      },
    },
  },
});

export default area;
