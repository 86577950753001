import styled from 'styled-components';
import React from 'react';
import Typography from 'kingpin/atoms/Typography';
import Spinner from './Spinner';
import Colors from '../../theme/colors';

export const BadgeDiv = styled.div<{
  borderColor: string;
  backgroundColor: string;
}>`
  display: inline-flex;
  max-width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 6px;
  height: 24px;

  background: ${(props) => props.backgroundColor};
  border-radius: 16px;
  border: 1px solid ${(props) => props.borderColor};
`;

export type BadgeType = 'Default' | 'Info' | 'Warning' | 'Danger' | 'Success';

const getBorderColor = (type: BadgeType) => {
  switch (type) {
    case 'Default':
      return '#E0E0E0';
    case 'Info':
      return '#B0C0F0';
    case 'Warning':
      return '#FFC382';
    case 'Danger':
      return '#F8B0B4';
    case 'Success':
      return '#D2F0C0';
  }
};

const getBackgroundColor = (type: BadgeType) => {
  switch (type) {
    case 'Default':
      return '#FFFFFF';
    case 'Info':
      return '#F7F9FF';
    case 'Warning':
      return '#FFF2E4';
    case 'Danger':
      return Colors.RADICAL_RED_BACKDROP;
    case 'Success':
      return Colors.MEDIUM_SEA_GREEN_BACKDROP;
  }
};

const Badge = ({
  isLoading,
  text,
  iconBefore,
  iconAfter,
  badgeType,
}: {
  isLoading?: boolean;
  text: string;
  height?: number;
  iconBefore?: JSX.Element;
  iconAfter?: JSX.Element;
  badgeType: 'Default' | 'Info' | 'Warning' | 'Danger' | 'Success';
}) => (
  <BadgeDiv
    data-testid={`${text}-badge`}
    borderColor={getBorderColor(badgeType)}
    backgroundColor={getBackgroundColor(badgeType)}
  >
    {isLoading && <Spinner color="#EE8C00" />}
    {iconBefore}
    <Typography.Body type="Badge" isEllipsis>
      {text}
    </Typography.Body>
    {iconAfter}
  </BadgeDiv>
);

export default Badge;
