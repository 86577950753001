import firebase from 'firebase/compat/app';
import STORE_CONSTANTS from '../constants';
import withoutNulls from '../../api/search/withoutNulls';

export const METRIC_LISTS_DOC = 'metricLists';
export const CURRENT_METRIC_LISTS_VERSION = 'v1';

const metricListConverter = {
  toFirestore(
    metricList: MetricListGadgetType,
  ): firebase.firestore.DocumentData {
    return withoutNulls({ ...metricList });
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ): MetricListGadgetType {
    const data = {
      ...snapshot.data(options),
      id: snapshot.id,
    };
    return data as MetricListGadgetType;
  },
};

const getMetricListsRef = ({
  accountId,
  db,
}: {
  accountId: string;
  db?: firebase.firestore.Firestore;
}) => {
  return (db ? db : firebase.firestore())
    .collection('accounts')
    .doc(accountId)
    .collection(STORE_CONSTANTS.VISUALISATIONS_COLLECTION)
    .doc(METRIC_LISTS_DOC)
    .collection(CURRENT_METRIC_LISTS_VERSION)
    .withConverter(metricListConverter);
};

export default getMetricListsRef;
