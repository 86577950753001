import styled from 'styled-components';
import Colors from '../../../../theme/colors';
import Row from '../../../Common/Row';
import ReportIconImage from '../../../../images/report.svg';

export const FlexDiv = styled.div`
  padding: 12px 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const Wrapper = styled(Row)`
  min-height: 80px;

  .showOnMouseOver {
    visibility: hidden;
  }

  &:hover {
    .redOnHover {
      background-color: ${Colors.FLEETOPS_RED};
    }

    .greenOnHover {
      background-color: ${Colors.MEDIUM_SEA_GREEN};
    }

    .primaryOnHover {
      background-color: ${Colors.RADICAL_RED};
    }

    .showOnMouseOver {
      width: unset;
      visibility: visible;
    }
  }
`;

export const Placeholder = styled.div`
  padding: 12px 32px;
`;

const reportIconSize = '32px';

export const ReportIconSpacing = styled.div`
  width: ${reportIconSize};
  height: ${reportIconSize};
`;

export const ReportIconWrapper = styled.div`
  &:hover {
    .redOnHover {
      background-color: ${Colors.FLEETOPS_RED};
    }

    .greenOnHover {
      background-color: ${Colors.MEDIUM_SEA_GREEN};
    }

    .primaryOnHover {
      background-color: ${Colors.RADICAL_RED};
    }
  }
`;

export const ReportIcon = styled(ReportIconSpacing)`
  background-color: ${Colors.LOGAN};
  mask: url(${ReportIconImage}) no-repeat center;
`;
