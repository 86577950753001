import React, { ChangeEvent, useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import Button from 'kingpin/atoms/Button';
import LocalTimelineContext from 'contexts/Timeline/LocalTimelineContext';
import ScorecardContext from 'contexts/ScorecardContext';
import AnalyticsContext from 'contexts/AnalyticsContext';
import Row from 'components/Common/Row';
import Inputs from 'components/Inputs';
import Typography from 'kingpin/atoms/Typography';
import getIdentifier from '../../../getIdentifier';

const Wrapper = styled.div`
  padding: 16px;
`;

const GroupRowForm = ({
  close,
  editingRow,
}: {
  close: () => void;
  editingRow?: Scorecards.EmptyRow;
}) => {
  const { scorecard, updateScorecard } = useContext(ScorecardContext);
  const { addEvent } = useContext(LocalTimelineContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const [groupName, setGroupName] = useState<string>(
    editingRow ? editingRow.label : '',
  );

  const handleCancel = useCallback(() => {
    if (editingRow) {
      trackEvent('KPI List - Edit Group - Cancelled');
    }
    close();
  }, [close, editingRow, trackEvent]);

  const onTitleChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setGroupName(event.target.value);
  }, []);

  const saveRow = useCallback(() => {
    const groupRow: Scorecards.EmptyRow = {
      type: 'emptyRow' as 'emptyRow',
      id: editingRow ? editingRow.id : getIdentifier(),
      label: groupName,
    };

    const newKpis = editingRow
      ? scorecard.kpis.map((kpi) => {
          if (kpi.id === editingRow.id) {
            return groupRow;
          }
          return kpi;
        })
      : [groupRow, ...scorecard.kpis];

    const newScorecard: Scorecards.Scorecard = {
      ...scorecard,
      kpis: newKpis,
    };

    updateScorecard(newScorecard).then(async () => {
      if (addEvent) {
        await addEvent({
          actionText: `${editingRow ? 'updated' : 'added'} Grouping row`,
          contextText: groupName,
        });
      }

      if (editingRow) {
        trackEvent('KPI List - Group Row - Updated');
      } else {
        trackEvent('KPI List - Group Row - Created');
      }
    });

    close();
  }, [
    addEvent,
    close,
    editingRow,
    groupName,
    scorecard,
    trackEvent,
    updateScorecard,
  ]);

  return (
    <Wrapper>
      <Row centerAlign style={{ marginBottom: 24 }} spaceBetween>
        <Typography.Header type="H4">
          {editingRow ? 'Edit Group Row' : 'Create Group Row'}
        </Typography.Header>
        <Button
          size={'Small'}
          type={'Secondary'}
          icon="cross"
          onClick={handleCancel}
        />
      </Row>
      <div style={{ marginBottom: 32 }}>
        <Typography.Body type="Label">Title:</Typography.Body>
        <Inputs.TextInput
          value={groupName}
          onChange={onTitleChanged}
          data-testid="group-name-input"
          inputSize="Small"
          width="100%"
        />
      </div>
      <Row centerAlign spaceBetween>
        <Button
          label={'Cancel'}
          onClick={handleCancel}
          type="Tertiary"
          size="Small"
        />
        <Button
          onClick={saveRow}
          isDisabled={groupName === ''}
          label={editingRow ? 'Update' : 'Create'}
          type="Primary"
          size="Small"
        />
      </Row>
    </Wrapper>
  );
};

export default GroupRowForm;
