import { ApisauceInstance } from 'apisauce';

interface ProfileResponse {
  userProfile:
    | {
        roles: FleetOps.Role[] | null;
        permissions: FleetOps.Permission[] | null; // Explicit permissions
      }
    | undefined; // undefined if this uid has no access
  permissions: FleetOps.Permission[]; // Net permissions
}

const getUserAccountAccess = (userId: string, api: ApisauceInstance) =>
  api.get<ProfileResponse>(`/userProfiles/${userId}`);

export default getUserAccountAccess;
