import React, { useContext } from 'react';
import DetailsSlideOutTimeline from './DetailsSlideOutTimeline';
import OrderDetailsContext from '../../contexts/OrderDetailsContext';

const DetailsSlideOutTimelineContainer = () => {
  const { events } = useContext(OrderDetailsContext);

  return <DetailsSlideOutTimeline events={events} />;
};

export default DetailsSlideOutTimelineContainer;
