import React, { ChangeEvent } from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import styled from 'styled-components';
import Button from 'kingpin/atoms/Button';
import Typography from 'kingpin/atoms/Typography';
import Inputs from 'components/Inputs';

import { Label } from '../../MyAccount/Profile/styles';
import Loading from '../../Loading';
import Row from '../../Common/Row';
import Card from '../../Common/Card';
import StretchImg from '../StretchImg';
import CenteredImg from '../CenterImg';

const ModalWrapper = styled.div`
  padding: 8px 16px;
`;

const ContentWrapper = styled.div`
  margin-bottom: 8px;
`;

const ImageCardEditForm = ({
  close,
  isOpen,
  isUploadingImage,
  onChange,
  onLayoutToggled,
  imageLayout,
  imgSrc,
  isLoadingImgSrc,
  isSaving,
  onSave,
  hasImage,
}: {
  close: () => void;
  isOpen: boolean;
  isUploadingImage: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onLayoutToggled: (event: ChangeEvent<HTMLInputElement>) => void;
  imageLayout: 'stretch' | 'center';
  imgSrc?: string;
  isLoadingImgSrc: boolean;
  isSaving: boolean;
  onSave: () => void;
  hasImage: boolean;
}) => (
  <ModalTransition>
    {isOpen && (
      <Modal onClose={close} shouldScrollInViewport={false} autoFocus={false}>
        <ModalWrapper>
          <ContentWrapper>
            <Label>
              <Typography.Body type="Label">Image</Typography.Body>
            </Label>
            <input type="file" accept="image/*" onChange={onChange} />
          </ContentWrapper>
          <ContentWrapper>
            <Label>
              <Typography.Body type="Label">Stretch Image</Typography.Body>
            </Label>
            <Inputs.Toggle
              value={imageLayout === 'stretch'}
              onChange={(newValue) => {
                onLayoutToggled({
                  // @ts-ignore
                  target: { checked: newValue },
                });
              }}
            />
          </ContentWrapper>
          <ContentWrapper>
            {(isUploadingImage || (isLoadingImgSrc && hasImage)) && <Loading />}
            {!isUploadingImage && !isLoadingImgSrc && !!imgSrc && (
              <Card style={{ padding: 8 }}>
                {imageLayout === 'stretch' && (
                  <StretchImg src={imgSrc} isEditing={true} />
                )}
                {imageLayout === 'center' && (
                  <CenteredImg src={imgSrc} isEditing={true} />
                )}
              </Card>
            )}
          </ContentWrapper>
          <Row spaceBetween centerAlign style={{ marginTop: 12 }}>
            <Button onClick={close} size="Small" type="Ghost" label="Cancel" />
            <Button
              isDisabled={isSaving || isUploadingImage}
              isLoading={isSaving}
              onClick={onSave}
              label="Save"
              size="Small"
              type="Primary"
            />
          </Row>
        </ModalWrapper>
      </Modal>
    )}
  </ModalTransition>
);

export default ImageCardEditForm;
