import React, { ChangeEvent, useCallback } from 'react';
import Typography from 'kingpin/atoms/Typography';
import styled from 'styled-components';
import Row from '../../Common/Row';
import MetricInfoConstants from '../constants';
import getMetricInfoLabel from './getMetricInfoLabel';
import TextInput from '../../../kingpin/atoms/TextInput';
import Colors2 from '../../../theme/Colors2';

const ListOuterDiv = styled.div`
  flex: 1;
  overflow-y: auto;
`;
const ListInnerDiv = styled.div`
  overflow-y: auto;
`;

const ListItem = styled(Row)<{ isSelected: boolean }>`
  background-color: ${(props) => (props.isSelected ? '#F7F7F7' : 'white')};
  height: 40px;
  border-radius: 8px;
  cursor: pointer;
  padding: 8px 10px;
  align-items: center;

  &:hover {
    opacity: 0.7;
  }
`;

const SidebarDiv = styled.div`
  height: ${MetricInfoConstants.POPUP_HEIGHT};
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${Colors2.Border};
  padding: 12px 24px;
`;

const MetricItem = ({
  isSelected,
  onMetricInfoSelected,
  metricInfo,
}: {
  isSelected: boolean;
  onMetricInfoSelected: (
    m: MetricsInfoButton.MetricInfo | MetricsInfoButton.ManualKpiInfo,
  ) => void;
  metricInfo: MetricsInfoButton.MetricInfo | MetricsInfoButton.ManualKpiInfo;
}) => {
  const onClick = useCallback(() => {
    onMetricInfoSelected(metricInfo);
  }, [metricInfo, onMetricInfoSelected]);

  return (
    <ListItem
      isSelected={isSelected}
      onClick={onClick}
      data-testid={`metric-item-${getMetricInfoLabel(metricInfo)}`}
    >
      <Typography.Body type="Body 12">
        {getMetricInfoLabel(metricInfo)}
      </Typography.Body>
    </ListItem>
  );
};

const Sidebar = ({
  metricInfos,
  searchText,
  onSearchTextChanged,
  onMetricInfoSelected,
  isSidebarVisible,
  selectedMetricInfo,
}: {
  metricInfos: (
    | MetricsInfoButton.MetricInfo
    | MetricsInfoButton.ManualKpiInfo
  )[];
  searchText: string;
  onSearchTextChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  onMetricInfoSelected: (
    m: MetricsInfoButton.MetricInfo | MetricsInfoButton.ManualKpiInfo,
  ) => void;
  isSidebarVisible: boolean;
  selectedMetricInfo:
    | MetricsInfoButton.MetricInfo
    | MetricsInfoButton.ManualKpiInfo;
}) => {
  if (!isSidebarVisible) {
    return null;
  }

  return (
    <SidebarDiv>
      <Row
        centerAlign
        style={{ marginBottom: 12, height: MetricInfoConstants.TOP_BAR_HEIGHT }}
      >
        <Typography.Header type="H4">Metrics</Typography.Header>
      </Row>
      <div style={{ marginBottom: 8 }}>
        <TextInput
          value={searchText}
          onChange={onSearchTextChanged}
          icon="search"
          placeholder="Search"
          autoFocus
        />
      </div>
      <ListOuterDiv>
        <ListInnerDiv>
          {metricInfos.map((m) => (
            <MetricItem
              key={m.key}
              isSelected={m.key === selectedMetricInfo.key}
              onMetricInfoSelected={onMetricInfoSelected}
              metricInfo={m}
            />
          ))}
        </ListInnerDiv>
      </ListOuterDiv>
    </SidebarDiv>
  );
};

export default Sidebar;
