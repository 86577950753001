import { useCallback, useContext, useEffect, useState } from 'react';
import ComparisonContext from 'contexts/ComparisonContext';
import relativeDateRangeToDateRange from 'relativeDateRangeToDateRange';
import migrateRelativeDateRange from 'migrateRelativeDateRange';
import WeekStartsOnOverrideContext from 'contexts/WeekStartsOnOverrideContext';
import AccountContext from 'contexts/AccountContext';
import useDateScope from 'hooks/useDateScope';
import { IPaceComparisonInput } from '../types';
import { ALLOWED_PACE_OPTIONS } from '../constants';
import useExcludedDates from './useExcludedDates';

const useComparisonPeriod = () => {
  const { weekStartsOn: accountWeekStartsOn } = useContext(AccountContext);
  const { weekStartsOnOverride } = useContext(WeekStartsOnOverrideContext);
  const weekStartsOn = weekStartsOnOverride
    ? weekStartsOnOverride
    : accountWeekStartsOn;
  const { currentComparison: comparison } = useContext(ComparisonContext);
  const allExcludedDates = useExcludedDates();
  const { startDate: requestStartDate } = useDateScope({});

  const getComparisonPeriod = useCallback(():
    | IPaceComparisonInput
    | undefined => {
    if (!comparison) {
      return;
    }
    if (!comparison.relativeDateOption) {
      return;
    }
    if (!ALLOWED_PACE_OPTIONS.includes(comparison.relativeDateOption)) {
      return;
    }

    const comparisonDateRange = relativeDateRangeToDateRange({
      relativeDateRange: migrateRelativeDateRange(
        comparison.relativeDateOption,
      ),
      startOfWeek: weekStartsOn,
      comparedTo: requestStartDate,
    });

    const { startDate, endDate } = comparisonDateRange;
    if (!startDate || !endDate) {
      return;
    }

    const excludedDates = allExcludedDates.filter((d) => {
      return d >= startDate && d <= endDate;
    });

    return {
      startDate,
      endDate,
      exclude: excludedDates,
    };
  }, [requestStartDate, comparison, allExcludedDates, weekStartsOn]);

  const [comparisonPeriod, setComparisonPeriod] = useState<
    IPaceComparisonInput | undefined
  >(() => getComparisonPeriod());

  useEffect(() => {
    setComparisonPeriod(getComparisonPeriod());
  }, [getComparisonPeriod]);

  return comparisonPeriod;
};

export default useComparisonPeriod;
