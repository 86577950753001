import getFleetOpsStaffUsersRef from './getFleetOpsStaffUsersRef';
import getUsersRef from './getUsersRef';
import getSharedContentRef from './getSharedContentRef';
import getUserContentSettingsRef from './getUserContentSettingsRef';

const users = {
  getUsersRef,
  getFleetOpsStaffUsersRef,
  getSharedContentRef,
  getUserContentSettingsRef,
};

export default users;
