import { useContext, useEffect, useState } from 'react';
import CostsWizardContext from '../../context/CostsWizardContext';
import GqlClientContext from 'contexts/GqlClientContext';
import getGroupFields from '../../api/getGroupFields';

const useGroupFieldsOptions = () => {
  const { wizardState, setWizardState } = useContext(CostsWizardContext);
  const { client } = useContext(GqlClientContext);
  const [options, setOptions] = useState<DropdownOption[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [groupFields, setGroupFields] = useState<string[]>([]);
  const [dataType, setDataType] = useState<string>();

  useEffect(() => {
    let isActive = true;
    getGroupFields('activity', client).then((res) => {
      if (isActive) {
        setGroupFields(res.fields);
        setDataType(res.dataType);
        setIsLoading(false);
      }
    });
    return () => {
      isActive = false;
    };
  }, [client]);

  useEffect(() => {
    if (groupFields) {
      const newOptions = groupFields.map((gf) => ({
        value: gf,
        label: gf,
        isSelected: wizardState.config.groupStep.field
          ? wizardState.config.groupStep.field === gf
          : undefined,
        onSelected: async () => {
          if (
            wizardState.config.groupStep.field &&
            wizardState.config.groupStep.groups.length !== 0
          ) {
            const isConfirmed = window.confirm(
              'Changing field will erase all costs!',
            );
            if (isConfirmed) {
              // if confirm switching field - clear all costs and switch
              setWizardState((s) => ({
                ...s,
                config: {
                  ...s.config,
                  groupStep: { ...s.config.groupStep, groups: [] },
                },
              }));
              setWizardState((s) => ({
                ...s,
                config: {
                  ...s.config,
                  groupStep: { ...s.config.groupStep, field: gf },
                },
              }));
            }
          } else {
            setWizardState((s) => ({
              ...s,
              config: {
                ...s.config,
                groupStep: { ...s.config.groupStep, field: gf },
              },
            }));
          }
        },
      }));
      setOptions(newOptions);
    }
  }, [
    groupFields,
    setWizardState,
    wizardState.config.groupStep.field,
    wizardState.config.groupStep.groups.length,
  ]);

  return { options, isLoading, dataType };
};

export default useGroupFieldsOptions;
