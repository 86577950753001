import React from 'react';
import InlineDialog from '../InlineDialog';
import Button from 'kingpin/atoms/Button';

import ReportDrillDownForm from '../ReportDrillDownForm';

const AddReportDrillDownButton = ({
  isOpen,
  open,
  close,
  isShowingLabel,
  isScope,
  onManualFilterChanged,
  onFilterAdded,
  testIdPrefix,
  label,
  isDisabled,
  isDateScopeDisabled,
}: {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  isShowingLabel: boolean;
  isScope: boolean;
  onManualFilterChanged?: () => void;
  onFilterAdded?: () => void;
  testIdPrefix?: string;
  label?: string;
  isDisabled?: boolean;
  isDateScopeDisabled?: boolean;
}) => (
  <div>
    <InlineDialog
      isOpen={isOpen}
      onClose={close}
      content={
        <ReportDrillDownForm
          close={close}
          isScope={isScope}
          onManualFilterChanged={onManualFilterChanged}
          onFilterAdded={onFilterAdded}
          isDateScopeDisabled={isDateScopeDisabled}
        />
      }
    >
      <Button
        isDisabled={isDisabled}
        onClick={isOpen ? close : open}
        testId={testIdPrefix ? `${testIdPrefix}-add-filter` : 'add-filter'}
        type="Secondary"
        label={!isShowingLabel ? undefined : label ? label : 'Add Filter'}
        size="Small"
        icon="filters"
      />
    </InlineDialog>
  </div>
);

export default AddReportDrillDownButton;
