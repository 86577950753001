import React, { useCallback, useContext, useState } from 'react';
import GqlClientContext from '../../../contexts/GqlClientContext';
import updateTargetsMutation from '../api/updateTargets';
import createTargetsMutation from '../api/createTargets';
import TargetListContext from '../contexts/TargetListContext';
import AnalyticsContext from '../../../contexts/AnalyticsContext';
import ToastContext from '../../../contexts/ToastContext';
import { transformCategory } from '../dataTypeTargetTransforms';

const useOnSaveClicked = ({
  dataType,
  effectiveDate,
  groupField,
  isTrackingFullPeriod,
  groups,
  isEditing,
  isValid,
  target,
  setIsShowingValidationFeedback,
}: {
  dataType: string;
  effectiveDate?: string;
  groupField?: string;
  isTrackingFullPeriod: boolean;
  groups: Targets.Wizard.TargetGroup[];
  isEditing: boolean;
  isValid: boolean;
  target?: Targets.Wizard.DataTypeTarget;
  setIsShowingValidationFeedback: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { showToast } = useContext(ToastContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const { selectedField, refreshTargets, onTargetCreated, onTargetUpdated } =
    useContext(TargetListContext);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { client } = useContext(GqlClientContext);

  const toPersistedCohorts = useCallback(
    (groups: Targets.Wizard.TargetGroup[]): Targets.Persisted.Cohort[] => {
      if (!selectedField) {
        return [];
      }
      return groups.map((g) => {
        return {
          cohortName: g.groupName ? g.groupName : ['*'],
          filteredCategorisations: g.targets.map((t) => ({
            categories: t.categories.map((c, categoryIndex) => {
              return transformCategory({
                field: selectedField,
                category: c,
                isTrackingFullPeriod,
                categoryIndex,
                categories: t.categories,
              });
            }),
            rules: t.rules.map((r) => {
              return {
                condition: r.condition,
                value: r.value,
                field: r.field,
              };
            }),
          })),
        };
      });
    },
    [isTrackingFullPeriod, selectedField],
  );

  const onUpdateTargetClicked = useCallback(
    ({
      targetId,
      groups,
    }: {
      targetId: string;
      groups: Targets.Wizard.TargetGroup[];
    }) => {
      setIsSaving(true);
      const cohorts = toPersistedCohorts(groups);
      updateTargetsMutation({
        client,
        id: targetId,
        cohorts,
      }).then(() => {
        refreshTargets().then(() => {
          setIsSaving(false);
          showToast('Target Updated');
          onTargetUpdated();
        });
      });
    },
    [toPersistedCohorts, client, refreshTargets, showToast, onTargetUpdated],
  );

  const onCreateTargetClicked = useCallback(
    ({
      dataType,
      effectiveDate,
      groupField,
      groups,
    }: {
      dataType: string;
      effectiveDate: string;
      groupField: string;
      groups: Targets.Wizard.TargetGroup[];
    }) => {
      if (!selectedField) {
        return;
      }

      setIsSaving(true);
      const cohortInputs = toPersistedCohorts(groups);

      createTargetsMutation({
        client,
        dataType,
        cohorts: cohortInputs,
        cohortFieldName: groupField,
        effectiveDate,
      }).then(() => {
        refreshTargets().then(() => {
          setIsSaving(false);
          showToast('Target Created');
          onTargetCreated();
        });
      });
    },
    [
      client,
      onTargetCreated,
      refreshTargets,
      selectedField,
      showToast,
      toPersistedCohorts,
    ],
  );

  const onSaveClickedConfirmed = useCallback(() => {
    if (!isValid) {
      setIsShowingValidationFeedback(true);
      return;
    }
    trackEvent('Targets - Saved', { field: selectedField });

    if (isEditing) {
      if (target === undefined) {
        return;
      }
      onUpdateTargetClicked({
        groups,
        targetId: target.id,
      });
    } else {
      if (groupField === undefined || effectiveDate === undefined) {
        return;
      }

      onCreateTargetClicked({
        groups,
        groupField,
        dataType,
        effectiveDate,
      });
    }
  }, [
    dataType,
    effectiveDate,
    groupField,
    groups,
    isEditing,
    isValid,
    onCreateTargetClicked,
    onUpdateTargetClicked,
    selectedField,
    setIsShowingValidationFeedback,
    target,
    trackEvent,
  ]);

  return {
    onSaveClickedConfirmed,
    isSaving,
  };
};

export default useOnSaveClicked;
