import styled from 'styled-components';
import Colors from '../../../theme/colors';

interface ContainerProps {
  selected: boolean;
}
const Container = styled.div<ContainerProps>`
  padding: 10px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  background-color: ${(props) =>
    props.selected ? Colors.BLUE_GREY : Colors.WHITE};
  margin-bottom: 7px;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  cursor: pointer;

  &:hover {
    background-color: ${Colors.BLUE_GREY};
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  width: 100%;
  margin-bottom: 6px;
`;

const RowSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 7px;
`;

const Profiler = styled.div`
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  border-radius: 10px;
  background-color: ${(props) => props.color};
  margin-right: 8px;
`;

const ReadStatusContainer = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: ${Colors.SATURATED_ZIRCON};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.7;
  }
`;

const ReadStatusInner = styled.div<{ read: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${(props) =>
    props.read ? 'transparent' : Colors.NAVY_BLUE};
`;

const Body = styled.div`
  padding: 0px 28px;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 16px;
  background-color: ${Colors.SOLITUDE};
  padding: 4px 8px;
  border-radius: 4px;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const Tooltip = styled.div`
  position: relative;
  display: inline-block;
`;

const TooltipText = styled.span<{ visible: boolean; bottom?: boolean }>`
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  background-color: ${Colors.GUN_METAL};
  color: ${Colors.WHITE};
  text-align: center;
  width: 90px;
  padding: 3px 2px;
  font-weight: 500;
  font-size: 11px;
  border-radius: 3px;
  position: absolute;
  z-index: 1;

  ${(props) => {
    if (props.bottom) {
      return `
        right: -38px;
        top: 20px;
      `;
    }

    return `
      top: -10px;
      right: 20px;
      `;
  }};
`;

const ClearButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 2px;

  &:hover {
    opacity: 0.7;
    background-color: ${Colors.SOLITUDE};
  }
`;

const CloseIcon = styled.img`
  width: 10px;
  height: 10px;
`;

export {
  Container,
  Header,
  RowSection,
  Button,
  Profiler,
  ReadStatusContainer,
  ReadStatusInner,
  Body,
  Content,
  Footer,
  Tooltip,
  TooltipText,
  ClearButton,
  CloseIcon,
};
