import React from 'react';
import styled from 'styled-components';

import Typography from 'kingpin/atoms/Typography';

const Wrapper = styled.div`
  width: 100%;
  height: calc(100% - 80px);
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const EmptyTimeline = () => (
  <Wrapper>
    <Typography.Body type="Body 12">No events yet</Typography.Body>
  </Wrapper>
);

export default EmptyTimeline;
