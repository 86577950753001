import React, { useContext } from 'react';
import styled from 'styled-components';

import SlideOutContext from '../contexts/SlideoutContext';
import { Z_INDEX } from '../../../../constants';

export const SLIDE_OUT_WIDTH = 440;

const SlideOutDiv = styled.div`
  height: 100%;
  background-color: white;
  z-index: ${Z_INDEX.SLIDE_OUT};
  position: absolute;
  width: ${SLIDE_OUT_WIDTH}px;
  right: 0px;
  box-shadow: 0px 1px 12px 5px #3f3f441a;
  border-radius: 2px;
`;

const SlideOut = ({
  children,
}: {
  children: JSX.Element | JSX.Element[] | false;
}) => {
  const { isOpen } = useContext(SlideOutContext);

  if (!isOpen) {
    return null;
  }

  return <SlideOutDiv>{children}</SlideOutDiv>;
};

export default SlideOut;
