import React, { useCallback, useEffect, useState } from 'react';
import { ScoringBandColors } from '../constants';

import styled from 'styled-components';
import Inputs from '../../../components/Inputs';
import Row from '../../../components/Common/Row';
import Colors2 from 'theme/Colors2';

const ColorSquare = styled.div<{ color: string }>`
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: 1px solid ${Colors2.Grey['7']};
  background-color: ${(props) => props.color};
  margin-right: 4px;
`;

const ScoringBand = ({ id }: { id: Scorecards.ScoringBandId }) => {
  const colors = ScoringBandColors[id];

  return (
    <Row>
      {colors.map((c) => (
        <ColorSquare color={c} key={c} />
      ))}
    </Row>
  );
};

const ScoringBandPicker = ({
  scoringBandId,
  setScoringBandId,
}: {
  scoringBandId: Scorecards.ScoringBandId;
  setScoringBandId: React.Dispatch<
    React.SetStateAction<Scorecards.ScoringBandId>
  >;
}) => {
  const [options, setOptions] = useState<DropdownOption[]>([]);
  const renderSelectedLabel = useCallback(() => {
    return <ScoringBand id={scoringBandId} />;
  }, [scoringBandId]);

  useEffect(() => {
    setOptions(
      ([1, 2, 6, 3, 4, 5] as Scorecards.ScoringBandId[]).map((id) => {
        const renderLabel = function BandLabel() {
          return <ScoringBand id={id} />;
        };

        return {
          label: id.toString(),
          onSelected: () => {
            setScoringBandId(id);
          },
          renderLabel,
        };
      }),
    );
  }, [scoringBandId, setScoringBandId]);

  return (
    <Inputs.Dropdown
      options={options}
      selectedLabel={scoringBandId.toString()}
      renderSelectedLabel={renderSelectedLabel}
      testId="target-bands-picker"
    />
  );
};

export default ScoringBandPicker;
