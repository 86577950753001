import React, { ChangeEvent } from 'react';
import { InputWrapper } from '../styles';
import Typography from 'kingpin/atoms/Typography';
import TextInput from '../../../../../kingpin/atoms/TextInput';

const Revenue = ({
  rate,
  onRateChanged,
  mileage,
  onMileageChanged,
  lineHaulCharge,
  onLineHaulChargeChanged,
  showWarnings,
}: {
  rate?: number;
  onRateChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  mileage?: number;
  onMileageChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  lineHaulCharge?: number;
  onLineHaulChargeChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  showWarnings: boolean;
}) => (
  <div style={{ marginBottom: 24 }}>
    <div style={{ marginBottom: 24 }}>
      <Typography.Header type={'H5'}>Revenue</Typography.Header>
    </div>
    <InputWrapper>
      <div style={{ marginBottom: 4 }}>
        <Typography.Body type="Label">Rate</Typography.Body>
      </div>
      <TextInput
        value={rate ? rate.toString(10) : ''}
        onChange={onRateChanged}
        step={0.01}
        type="number"
      />
    </InputWrapper>
    <InputWrapper>
      <div style={{ marginBottom: 4 }}>
        <Typography.Body type="Label">Mileage</Typography.Body>
      </div>
      <TextInput
        value={mileage ? mileage.toString(10) : ''}
        onChange={onMileageChanged}
        step={0.01}
        type="number"
      />
    </InputWrapper>
    <InputWrapper>
      <div style={{ marginBottom: 4 }}>
        <Typography.Body type="Label">* Line Haul Revenue</Typography.Body>
      </div>
      <TextInput
        value={lineHaulCharge ? lineHaulCharge.toString(10) : ''}
        onChange={onLineHaulChargeChanged}
        step={0.01}
        type="number"
        state={showWarnings && !lineHaulCharge ? 'Error' : undefined}
      />
    </InputWrapper>
  </div>
);

export default Revenue;
