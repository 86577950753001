import React from 'react';

import styled from 'styled-components';
import ColorDot from 'kingpin/navigation/PortalPicker/ColorDot';
import Typography from 'kingpin/atoms/Typography';
import Button from 'kingpin/atoms/Button';

import Row from '../../Common/Row';
import useRemovePortalAccessConfirmation from './useRemovePortalAccessConfirmation';

const ItemDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 12px;

  &:not(:last-of-type) {
    border-bottom: 1px solid #e0e0e0;
  }
`;

const PortalWithAccess = ({
  portal,
  type,
  typeId,
  contentName,
}: {
  portal: ExecutivePortal | EngagementPortal;
  type: ExecutivePortalPublishableContentType;
  typeId: string;
  contentName: string;
}) => {
  const { RemoveConfirmation, onRemoveClicked } =
    useRemovePortalAccessConfirmation({
      portal,
      type,
      typeId,
      contentName,
    });

  return (
    <>
      <ItemDiv>
        <Row centerAlign>
          <div style={{ display: 'flex', marginRight: 8 }}>
            <ColorDot color={portal.color} width={8} height={8} />
          </div>
          <Typography.Body type={'Button Text'}>{portal.name}</Typography.Body>
        </Row>
        <Button
          size={'Small'}
          type={'Ghost'}
          icon="cross"
          onClick={onRemoveClicked}
          testId={`remove-from-${portal.id}`}
        />
      </ItemDiv>
      {RemoveConfirmation}
    </>
  );
};

export default PortalWithAccess;
