import React from 'react';
import styled from 'styled-components';

import FlexCentered from '../Common/FlexCentered';
import Typography from '../../kingpin/atoms/Typography';

const TINY = 8;
const BUTTON = 16;
const SMALL = 32;
const NORMAL = 48;

const getSize = (size: 'tiny' | 'button' | 'small' | 'normal') => {
  switch (size) {
    case 'button':
      return BUTTON;
    case 'small':
      return SMALL;
    case 'tiny':
      return TINY;
    case 'normal':
    default:
      return NORMAL;
  }
};

const getBorderSize = (size: 'tiny' | 'button' | 'small' | 'normal') => {
  switch (size) {
    case 'tiny':
      return 2;
    case 'button':
      return 2;
    case 'small':
      return 4;
    case 'normal':
    default:
      return 5;
  }
};

const Animation = styled.div<{
  size: 'tiny' | 'button' | 'small' | 'normal';
  color: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;

  .loader {
    width: ${(props) => getSize(props.size)}px;
    height: ${(props) => getSize(props.size)}px;
    border: ${(props) => getBorderSize(props.size)}px solid
      ${(props) => props.color};
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    position: relative;
    animation: pulse 1s linear infinite;
  }
  .loader:after {
    content: '';
    position: absolute;
    width: ${(props) => getSize(props.size)}px;
    height: ${(props) => getSize(props.size)}px;
    border: ${(props) => getBorderSize(props.size)}px solid
      ${(props) => props.color};
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: scaleUp 1s linear infinite;
  }

  @keyframes scaleUp {
    0% {
      transform: translate(-50%, -50%) scale(0);
    }
    60%,
    100% {
      transform: translate(-50%, -50%) scale(1);
    }
  }
  @keyframes pulse {
    0%,
    60%,
    100% {
      transform: scale(1);
    }
    80% {
      transform: scale(1.2);
    }
  }
`;

// eslint-disable-next-line
const Loading = React.memo(
  ({
    isSmall,
    isTiny,
    isButton,
    isGrouped,
    onLoadingAnimationFinished,
    isFinishedLoading,
  }: {
    isTiny?: boolean;
    isButton?: boolean;
    isSmall?: boolean;
    isGrouped?: boolean;
    onLoadingAnimationFinished?: () => void;
    isFinishedLoading?: boolean;
  }) => {
    const color = isGrouped ? '#c5cbff' : '#e8eaff';
    const size = isTiny
      ? 'tiny'
      : isButton
        ? 'button'
        : isSmall
          ? 'small'
          : 'normal';
    return (
      <FlexCentered style={{ padding: 4 }} data-testid="loading">
        <Animation size={size} color={color}>
          <span className="loader"></span>
        </Animation>
        {size === 'normal' && isGrouped && (
          <div style={{ marginTop: 8 }}>
            <Typography.Header type="H6">Loading</Typography.Header>
          </div>
        )}
      </FlexCentered>
    );
  },
);

export default Loading;
