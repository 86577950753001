import React, { useState } from 'react';
import SlideOutContext from '../contexts/SlideOutContext';

const SlideOutProvider = ({
  board,
  performanceFields,
  children,
}: {
  board: PerformanceBoardTypes.Board;
  performanceFields: string[];
  children: JSX.Element | JSX.Element[];
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [filterInput, setFilterInput] = useState<FilterInput | undefined>();
  const [navState, setNavState] =
    useState<PerformanceBoardTypes.SlideOut.NavState>({ slideoutMode: 'Home' });
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [flashMessage, setFlashMessage] = useState<string>('');

  const flash = {
    isVisible,
    setIsVisible,
    flashMessage,
    setFlashMessage,
  };

  return (
    <SlideOutContext.Provider
      value={{
        board,
        isOpen,
        setIsOpen,
        filterInput,
        setFilterInput,
        navState,
        setNavState,
        performanceFields,
        performanceDataset: board.dataType,
        flash,
      }}
    >
      {children}
    </SlideOutContext.Provider>
  );
};

export default SlideOutProvider;
