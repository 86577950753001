import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

const buildQuery = ({
  query,
  dataType,
  filters,
  fields,
  dateScope,
}: {
  query: string;
  dataType: string;
  filters: FilterInput[];
  fields: GridAggregateField[];
  dateScope: DateRangeInput;
}) => ({
  query: gql`
      query FetchTotals(
          $dataType: String!
          $filters: [FilterInput]!
          $dateScope: [DateRangeInput!]!
          $fields: [AggregateField]!
      ) {
          ${query} (
          dataType: $dataType
          filters: $filters
          dateScope: $dateScope
          fields: $fields
          )
      }
  `,
  variables: {
    dataType,
    filters,
    fields,
    dateScope,
  },
});

const getGridTotalsRow = async ({
  query,
  dataType,
  filters,
  fields,
  client,
  dateScope,
}: {
  query: string;
  dataType: string;
  filters: FilterInput[];
  fields: GridAggregateField[];
  client: ApolloClient<NormalizedCacheObject>;
  dateScope: DateRangeInput;
}): Promise<ElasticDocument[]> => {
  const gqlQuery = buildQuery({
    query,
    dataType,
    filters,
    fields,
    dateScope,
  });
  const response = await client.query(gqlQuery);
  return response.data[query];
};

export default getGridTotalsRow;
