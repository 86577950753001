import React, { useContext } from 'react';
import Typography from 'kingpin/atoms/Typography';

import Commitment from './Commitment';
import CustomerLaneSlideOutContext from '../../../../contexts/CustomerLaneSlideOut';
import Loading from '../../../Loading';
import useCommitments from '../../../../hooks/useCommitments';
import isoDateToAmerican from '../../../../isoDateToAmerican';
import useValueFormatters from 'hooks/useValueFormatters';
import cadenceToLabel from '../../../BoardSlideOut/Performance/cadenceToLabel';

const CommitmentContainer = ({ commitment }: { commitment: Commitment }) => {
  const { formatValue } = useValueFormatters();
  const { loads, cadence, startDate, endDate, mileage, rate, lineHaulCharge } =
    commitment;

  const formatting = {
    prefix: 'currency',
  } as MetricFormatting;

  const ln1 = `${loads} Loads per ${cadenceToLabel(cadence).toLowerCase()}`;
  const ln2 = (() => {
    let wip = '';
    if (lineHaulCharge) {
      wip += `LH Revenue: ${formatValue({
        value: lineHaulCharge,
        formatting,
      })}`;
    }

    if (mileage) {
      wip += ` Miles: ${mileage}`;
    }

    if (rate) {
      wip += ` Rate per Mile: ${formatValue({
        value: rate,
        formatting,
      })}`;
    }

    return wip;
  })();
  const ln3 = (() => {
    if (startDate && endDate) {
      return `Effective between ${isoDateToAmerican(
        startDate,
      )} - ${isoDateToAmerican(endDate)}`;
    } else if (startDate) {
      return `Effective from ${isoDateToAmerican(startDate)}`;
    } else if (endDate) {
      return `Effective until ${isoDateToAmerican(endDate)}`;
    } else {
      return `Effective forever`;
    }
  })();

  return <Commitment ln1={ln1} ln2={ln2} ln3={ln3} />;
};

const Gate = () => {
  const commitments = useCommitments('customerLaneCommitments');
  const { documentId } = useContext(CustomerLaneSlideOutContext);

  const commitment = commitments.find((c) =>
    documentId ? c.id === documentId : false,
  );

  if (!commitment) {
    return (
      <div style={{ marginBottom: 24 }}>
        <div style={{ marginBottom: 24 }}>
          <Typography.Header type={'H5'}>Commitment</Typography.Header>
        </div>
        <Loading isSmall />
      </div>
    );
  }

  return <CommitmentContainer commitment={commitment} />;
};

export default Gate;
