import React, { useCallback, useContext, useEffect, useState } from 'react';
import V5GadgetFormContext from '../../contexts/V5GadgetFormContext';
import Dropdown from '../Inputs/Dropdown';
import Label from './Label';

const useOptions = () => {
  const { groupByLimitMode, setGroupByLimitMode, setGroupByLimit } =
    useContext(V5GadgetFormContext);
  const buildOptions = useCallback(() => {
    const newOptions = [
      {
        label: 'Top N',
        isSelected: groupByLimitMode === 'top n',
        onSelected: () => {
          setGroupByLimitMode('top n');
          setGroupByLimit(10);
        },
      },
      {
        label: 'Show all',
        isSelected: groupByLimitMode === 'show all',
        onSelected: () => {
          setGroupByLimitMode('show all');
          setGroupByLimit(undefined);
        },
      },
    ];

    return newOptions;
  }, [groupByLimitMode, setGroupByLimit, setGroupByLimitMode]);

  const [options, setOptions] = useState<DropdownOption[]>(() =>
    buildOptions(),
  );

  useEffect(() => {
    setOptions(buildOptions());
  }, [buildOptions]);

  return options;
};

const GroupByLimitModeInput = () => {
  const { dimensionA, dimensionB } = useContext(V5GadgetFormContext);
  const dropdownOptions = useOptions();

  if (dimensionA && dimensionA.fieldType === 'text' && !dimensionB) {
    return (
      <div style={{ marginBottom: 8 }}>
        <Label>Limit Mode</Label>
        <Dropdown options={dropdownOptions} />
      </div>
    );
  }
  return null;
};

export default GroupByLimitModeInput;
