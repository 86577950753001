import React, { useContext } from 'react';
import PerformanceWizardContext from '../PerformanceWizardContext';
import FieldListItem from './FieldListItem';
import List from '../../../../../../components/List';

const FieldsList = ({ isReadOnly }: { isReadOnly?: boolean }) => {
  const { wizardState } = useContext(PerformanceWizardContext);
  const dataTypeFields = wizardState.config.fieldsStep.fields;

  return (
    <List
      Headers={null}
      Body={
        <div>
          {dataTypeFields.map(({ fields, dataType }) => (
            <div key={dataType}>
              {fields.map((field) => (
                <FieldListItem
                  key={`${field} - ${dataType}`}
                  field={field}
                  dataType={dataType}
                  isReadOnly={isReadOnly}
                />
              ))}
            </div>
          ))}
        </div>
      }
    />
  );
};

export default FieldsList;
