import { useCallback, useContext, useEffect, useState } from 'react';
import { ColDef, ColGroupDef, ValueFormatterParams } from 'ag-grid-community';
import moment from 'moment';
import useDateScope from 'hooks/useDateScope';
import useValueFormatters from 'hooks/useValueFormatters';
import toSentenceCase from 'services/toSentenceCase';

import { PACE_CELLS } from '../constants';
import { IPaceRow, TPaceColDef } from '../types';
import useDays from './useDays';
import { DateTime } from 'luxon';
import EntityDefinitionsContext from '../../../../contexts/EntityDefinitionsContext';
import useMetric from '../../../../hooks/useMetric';

const MIN_WIDTH = process.env.NODE_ENV === 'test' ? 70 : undefined;

const useColDefs = ({
  paceMatrix,
  getActualDateRange,
  getAverageDateRange,
  getExcludedDateRangeLabels,
}: {
  paceMatrix: VisualisationDefinitions.PaceMatrix;
  getActualDateRange: () => string;
  getAverageDateRange: () => string;
  getExcludedDateRangeLabels: () => string[];
}) => {
  const { getEntityDefinitionFor } = useContext(EntityDefinitionsContext);
  const metric = useMetric(paceMatrix.metricId);
  const { formatMetric } = useValueFormatters();
  const { startDate, endDate } = useDateScope({});
  const getTotalsHeaderName = useCallback(() => {
    const left = moment.utc(startDate).format('MM/DD');
    const right = moment.utc(endDate).format('MM/DD');
    return `${left} - ${right}`;
  }, [endDate, startDate]);

  const days = useDays();

  const getDayOfWeek = useCallback((date: string) => {
    return DateTime.fromISO(date).weekdayLong;
  }, []);

  const getSortOrder = useCallback(
    (
      by: 'Term' | 'Total' | 'Pace' | 'Total Chg' | 'Pace Chg',
    ): undefined | 'asc' | 'desc' => {
      if (paceMatrix.sortOrder.by !== by) {
        return undefined;
      }

      return paceMatrix.sortOrder.direction;
    },
    [paceMatrix.sortOrder.by, paceMatrix.sortOrder.direction],
  );

  const buildHistogramCols = useCallback(
    (metricName: string): ColGroupDef<IPaceRow>[] => {
      return days.map((day) => ({
        minWidth: MIN_WIDTH,
        headerName: day.label,
        children: [
          {
            minWidth: MIN_WIDTH,
            headerName: metricName,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            field: `histogram.${day.date}.actual`,
            cellRenderer: PACE_CELLS.CELL,
            type: 'numericColumn',
            cellClass: 'right-aligned-cell',
            valueFormatter: (v) => {
              if (v.value === undefined || v.value === null) {
                return '-';
              }

              return formatMetric({
                value: v.value,
                metricId: paceMatrix.metricId,
              });
            },
          },
          {
            minWidth: MIN_WIDTH,
            headerName: 'Chg',
            wrapHeaderText: true,
            autoHeaderHeight: true,
            field: `histogram.${day.date}.actualDiff`,
            cellRenderer: PACE_CELLS.DIFF,
            cellRendererParams: {
              metricId: paceMatrix.metricId,
              actualDayOfWeekHeading: getDayOfWeek(day.date),
              actualDateRangeText: moment.utc(day.date).format(`MMM DD'YY`),
              avgDayOfWeekHeading: `Avg. for ${getDayOfWeek(day.date)}s`,
              avgDateRangeText: getAverageDateRange(),
              excludedDateRangeLabels: getExcludedDateRangeLabels(),
              getActual: (data: IPaceRow) => {
                const dataPoint = data.histogram[day.date];
                if (!dataPoint) {
                  return null;
                }

                return dataPoint.actual;
              },
              getDiff: (data: IPaceRow) => {
                const dataPoint = data.histogram[day.date];
                if (!dataPoint) {
                  return null;
                }

                return dataPoint.actualDiff;
              },
              getAverage: (data: IPaceRow) => {
                const dataPoint = data.histogram[day.date];
                if (!dataPoint) {
                  return null;
                }

                return dataPoint.average;
              },
            },
          },
        ],
      }));
    },
    [
      days,
      formatMetric,
      getAverageDateRange,
      getDayOfWeek,
      getExcludedDateRangeLabels,
      paceMatrix.metricId,
    ],
  );

  const formatSimpleCellValue = useCallback(
    (v: ValueFormatterParams<IPaceRow, any>) => {
      if (v.value === undefined || v.value === null) {
        return '-';
      }

      return formatMetric({
        value: v.value,
        metricId: paceMatrix.metricId,
      });
    },
    [formatMetric, paceMatrix.metricId],
  );

  const buildTotalsCol = useCallback(
    (metricName: string): ColGroupDef<IPaceRow> | undefined => {
      const { hasPaceCol, hasTotalsCol, isTotalsAndPacePinnedRight } =
        paceMatrix;
      if (!paceMatrix.hasPaceCol && !paceMatrix.hasTotalsCol) {
        return undefined;
      }

      const children: ColDef<IPaceRow>[] = [];
      if (hasTotalsCol) {
        children.push({
          sort: getSortOrder('Total'),
          minWidth: MIN_WIDTH,
          headerName: metricName,
          wrapHeaderText: true,
          autoHeaderHeight: true,
          field: `totals.actual`,
          cellRenderer: PACE_CELLS.CELL,
          pinned: isTotalsAndPacePinnedRight ? 'right' : undefined,
          valueFormatter: formatSimpleCellValue,
          type: 'numericColumn',
          cellClass: 'right-aligned-cell',
        });
        children.push({
          sort: getSortOrder('Total Chg'),
          minWidth: MIN_WIDTH,
          headerName: 'Chg',
          wrapHeaderText: true,
          autoHeaderHeight: true,
          field: `totals.actualDiff`,
          cellRenderer: PACE_CELLS.DIFF,
          cellRendererParams: {
            metricId: paceMatrix.metricId,
            actualDateRangeText: getActualDateRange(),
            avgDateRangeText: getAverageDateRange(),
            excludedDateRangeLabels: getExcludedDateRangeLabels(),
            getActual: (data: IPaceRow) => {
              return data.totals.actual;
            },
            getAverage: (data: IPaceRow) => {
              if (
                data.totals.actual === null ||
                data.totals.actualDiff === null
              ) {
                return null;
              }
              return data.totals.actual - data.totals.actualDiff;
            },
            getDiff: (data: IPaceRow) => {
              return data.totals.actualDiff;
            },
          },
          pinned: isTotalsAndPacePinnedRight ? 'right' : undefined,
        });
      }

      if (hasPaceCol) {
        children.push({
          sort: getSortOrder('Pace'),
          minWidth: MIN_WIDTH,
          headerName: 'Pace',
          wrapHeaderText: true,
          autoHeaderHeight: true,
          field: `totals.pace`,
          cellRenderer: PACE_CELLS.CELL,
          pinned: isTotalsAndPacePinnedRight ? 'right' : undefined,
          valueFormatter: formatSimpleCellValue,
          type: 'numericColumn',
          cellClass: 'right-aligned-cell',
        });
        children.push({
          sort: getSortOrder('Pace Chg'),
          minWidth: MIN_WIDTH,
          headerName: 'Chg',
          wrapHeaderText: true,
          autoHeaderHeight: true,
          field: `totals.paceDiff`,
          cellRenderer: PACE_CELLS.DIFF,
          cellRendererParams: {
            metricId: paceMatrix.metricId,
            actualDateRangeText: getActualDateRange(),
            avgDateRangeText: getAverageDateRange(),
            excludedDateRangeLabels: getExcludedDateRangeLabels(),
            getActual: (data: IPaceRow) => {
              return data.totals.pace;
            },
            getAverage: (data: IPaceRow) => {
              if (data.totals.pace === null || data.totals.paceDiff === null) {
                return null;
              }

              return data.totals.pace - data.totals.paceDiff;
            },
            getDiff: (data: IPaceRow) => {
              return data.totals.paceDiff;
            },
          },
          pinned: isTotalsAndPacePinnedRight ? 'right' : undefined,
        });
      }

      return {
        headerName: getTotalsHeaderName(),
        children: children,
      };
    },
    [
      formatSimpleCellValue,
      getActualDateRange,
      getAverageDateRange,
      getExcludedDateRangeLabels,
      getSortOrder,
      getTotalsHeaderName,
      paceMatrix,
    ],
  );

  const buildColDefs = useCallback((): TPaceColDef[] => {
    if (!metric) {
      return [];
    }
    const metricName =
      paceMatrix.metricDisplayName && paceMatrix.metricDisplayName !== ''
        ? paceMatrix.metricDisplayName
        : metric.name;
    const groupCol: ColDef<IPaceRow> = {
      sort: getSortOrder('Term'),
      minWidth: MIN_WIDTH,
      headerName: toSentenceCase(paceMatrix.groupByField),
      pinned: 'left',
      wrapHeaderText: true,
      autoHeaderHeight: true,
      field: 'groupByField',
      cellRenderer: PACE_CELLS.GROUP,
      cellRendererParams: {
        entityDefinition: getEntityDefinitionFor(paceMatrix.groupByField),
      },
    };
    const histogramCols = buildHistogramCols(metricName);
    const totalCol = buildTotalsCol(metricName);

    const cols = [groupCol, ...histogramCols];

    if (totalCol) {
      cols.push(totalCol);
    }

    return cols;
  }, [
    buildHistogramCols,
    buildTotalsCol,
    getEntityDefinitionFor,
    getSortOrder,
    metric,
    paceMatrix.groupByField,
    paceMatrix.metricDisplayName,
  ]);

  const [colDefs, setColDefs] = useState<TPaceColDef[]>(() => buildColDefs());
  useEffect(() => {
    setColDefs(buildColDefs());
  }, [buildColDefs]);

  return colDefs;
};

export default useColDefs;
