import React, { useContext } from 'react';
import StackedAreaOptionsInput from './StackedAreaOptionsInput';
import V5GadgetFormContext from '../../../contexts/V5GadgetFormContext';

const StackedAreaOptionsInputContainer = () => {
  const { stackedAreaOptions, setStackedAreaOptions } =
    useContext(V5GadgetFormContext);
  if (!stackedAreaOptions) {
    return null;
  }

  const { altPivot } = stackedAreaOptions;

  const onAltPivotChanged = (newPivot: boolean) => {
    setStackedAreaOptions({
      ...stackedAreaOptions,
      altPivot: newPivot,
    });
  };

  return (
    <StackedAreaOptionsInput
      altPivot={altPivot}
      onAltPivotChanged={onAltPivotChanged}
    />
  );
};

export default StackedAreaOptionsInputContainer;
