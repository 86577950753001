import React from 'react';

export type ReportDrillDownFormStage = 'fieldSelection' | 'valueSelection';

interface ReportDrillDownFormContextType {
  isEditing: boolean;
  stage: ReportDrillDownFormStage;
  field?: string;
  dataset?: string;
  fieldType?: FleetOps.FieldType;
  drillDown?: ReportDrillDownType;
  onFieldSelected: (field: FleetOps.Field | DateBucketField) => void;
  onDrillDownConfirmed: (newDrillDown: ReportDrillDownType) => void;
  reset: () => void;
  onRemoved: () => void;
  onVariableFilterAdded: (vf: VariableDrillDownType) => void;
  onDatasetFilterAdded: (df: DatasetFilter) => void;
  isVariableFilterValuePicker: boolean;
  isScope: boolean;
  isDateScopeDisabled?: boolean;
}

const ReportDrillDownFormContext =
  React.createContext<ReportDrillDownFormContextType>(
    {} as ReportDrillDownFormContextType,
  );

export default ReportDrillDownFormContext;
