import React from 'react';

interface DashboardMetricUsageLookupContextType {
  dashboardMetricUsageLookup: FleetOps.DashboardMetricUsageLookup;
}

const DashboardMetricUsageLookupContext =
  React.createContext<DashboardMetricUsageLookupContextType>({
    dashboardMetricUsageLookup: {},
  });

export default DashboardMetricUsageLookupContext;
