import React from 'react';
import Row from '../../Common/Row';
import Button from 'kingpin/atoms/Button';

const Actions = ({
  onSave,
  onSaveAs,
  onDelete,
  isEditing,
  isLoading,
  canDelete,
}: {
  onSave: () => void;
  onSaveAs: () => void;
  onDelete: () => void;
  isEditing: boolean;
  isLoading: boolean;
  canDelete: boolean;
}) => (
  <React.Fragment>
    {isEditing && canDelete && (
      <Row style={{ marginBottom: 12 }}>
        <Button
          type="Tertiary"
          size="Small"
          onClick={onDelete}
          isDisabled={isLoading}
          label={'Delete'}
        />
      </Row>
    )}
    <Row centerAlign spaceBetween style={{ marginBottom: 12 }}>
      {isEditing && (
        <Button
          onClick={onSaveAs}
          label="Save As"
          size="Small"
          type="Tertiary"
        />
      )}
      {!isEditing && <div />}
      <Button
        onClick={onSave}
        isDisabled={isLoading}
        size={'Small'}
        type="Primary"
        label={isEditing ? 'Update' : 'Create'}
      />
    </Row>
  </React.Fragment>
);

export default Actions;
