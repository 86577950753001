import React, { useEffect, useState } from 'react';
import { useDataTypesFromMetricId } from '../../hooks/useDataTypesFromSeriesAndMetricListItems';
import useDateFields from '../../hooks/useDateFields';
import { buildDateFieldOptions } from '../DateInput/DateFieldPicker';
import useGetDatasetLabel from './MetricPicker/MetricPickerPopup/hooks/useGetDatasetLabel';
import Dropdown from './Dropdown';
import metricTypeCheckers from '../../types/metricTypeCheckers';

const MetricDateFieldPicker = ({
  metric,
  dateField,
  setDateField,
  isReadOnly,
  testId,
}: {
  metric: Metrics.Metric;
  dateField: string;
  setDateField:
    | React.Dispatch<React.SetStateAction<string>>
    | ((newDateField: string) => void);
  isReadOnly?: boolean;
  testId?: string;
}) => {
  const getDatasetLabel = useGetDatasetLabel();
  const dataTypes = useDataTypesFromMetricId(metric.id);
  const { dateFields } = useDateFields({
    dataTypes,
    mustBeAvailableOnAllDataTypes: metricTypeCheckers.isCompoundMetric(metric),
  });
  const [options, setOptions] = useState<DropdownOption[]>([]);
  useEffect(() => {
    setOptions(
      buildDateFieldOptions({
        dateFields,
        dataTypes,
        dateField,
        setDateField,
        getDatasetLabel,
      }).map((o) => ({
        ...o,
        isSelected: dateField === o.value,
      })),
    );
  }, [dataTypes, dateField, dateFields, getDatasetLabel, setDateField]);

  return (
    <>
      <Dropdown
        options={options}
        fullWidth
        isDisabled={isReadOnly}
        testId={testId}
      />
    </>
  );
};

export default MetricDateFieldPicker;
