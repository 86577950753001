import { gql } from '@apollo/client';
import { IPaceCalculateForMetricArgs, IPaceHistogramTerm } from '../types';

const calculateTotalsPaceForMetric = async ({
  client,
  metric,
  dateScope,
  filters,
  comparisonPeriod,
}: IPaceCalculateForMetricArgs): Promise<IPaceHistogramTerm> => {
  const query = {
    query: gql`
      query calculatePaceForMetric(
        $metric: MetricInput!
        $dateScope: [DateRangeInput!]!
        $filters: [FilterInput]!
        $comparisonPeriod: ComparisonInput
      ) {
        calculatePaceForMetric(
          metric: $metric
          dateScope: $dateScope
          filters: $filters
          comparisonPeriod: $comparisonPeriod
        ) {
          name
          average
          actual
          actualDiff
          pace
          paceDiff
          goal
          histogram {
            date
            average
            actual
            actualDiff
          }
        }
      }
    `,
    variables: {
      metric,
      dateScope,
      filters,
      comparisonPeriod,
    },
  };

  const response = await client.query<{
    calculatePaceForMetric: IPaceHistogramTerm[];
  }>(query);
  return response.data.calculatePaceForMetric[0];
};

export default calculateTotalsPaceForMetric;
