import React from 'react';
import styled from 'styled-components';

import Colors2 from 'theme/Colors2';
import Typography from 'kingpin/atoms/Typography';
import WidgetIcon from './WidgetIcon';

const WidgetItemStyled = styled.div<{ isDisabled?: boolean }>`
  margin-bottom: 26px;
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
`;

const WidgetItem = ({
  iconSvg,
  title,
  description,
  onClick,
  isDisabled,
}: {
  iconSvg: string;
  title: string;
  description: string;
  onClick: () => void;
  isDisabled?: boolean;
}) => {
  return (
    <WidgetItemStyled onClick={onClick} isDisabled={isDisabled}>
      <div style={{ opacity: `${isDisabled && '0.5'}` }}>
        <WidgetIcon iconSvg={iconSvg} />
      </div>
      <div>
        <div style={{ marginBottom: 8, opacity: `${isDisabled && '0.5'}` }}>
          <Typography.Header type="H5">{title}</Typography.Header>
        </div>

        <Typography.Body
          type="Body 12"
          color={isDisabled ? Colors2.Secondary.error : undefined}
        >
          {description}
        </Typography.Body>
      </div>
    </WidgetItemStyled>
  );
};

export default WidgetItem;
