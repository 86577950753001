import { useContext, useEffect, useState } from 'react';
import STORE from '../../store';
import CurrentUserContext from '../../contexts/CurrentUserContext';

const useStaffUsers = () => {
  const { isWallboardUser } = useContext(CurrentUserContext);
  const [staffUsers, setStaffUsers] = useState<
    UserManagement.FleetOpsStaffUser[]
  >([]);
  const [isLoadingStaffUsers, setIsLoadingStaffUsers] = useState<boolean>(true);

  useEffect(() => {
    if (isWallboardUser) {
      setIsLoadingStaffUsers(false);
      return;
    }

    let isActive = true;
    setIsLoadingStaffUsers(true);
    STORE.users
      .getFleetOpsStaffUsersRef({})
      .get()
      .then((usersSnapshot) => {
        if (!isActive) {
          return;
        }

        const users = usersSnapshot.docs.map((d) => d.data());
        setStaffUsers(users);
        setIsLoadingStaffUsers(false);
      });

    return () => {
      isActive = false;
    };
  }, [isWallboardUser]);

  return {
    staffUsers,
    isLoadingStaffUsers,
  };
};

export default useStaffUsers;
