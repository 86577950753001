import React from 'react';
import useFormState from './useFormState';
import useOnSave from './useOnSave';
import EntityForm from './EntityForm';

const EntityFormContainer = ({
  entity,
  isAccessOnly,
  close,
}: {
  entity?: EntityDetails.Entity;
  isAccessOnly?: boolean;
  close: () => void;
}) => {
  const {
    name,
    onNameChanged,
    entityDataset,
    entityDatasetOptions,
    primaryFieldOptions,
    access,
    setAccess,
    secondaryFieldOptions,
    contextFieldOptions,
    isEnabled,
    setIsEnabled,
    isActiveFilters,
    setIsActiveFilters,
    getNewDefinition,
    isValid,
  } = useFormState(entity);
  const { onSave, isLoading } = useOnSave({ isValid, getNewDefinition, close });

  return (
    <EntityForm
      name={name}
      onNameChanged={onNameChanged}
      entityDataset={entityDataset}
      entityDatasetOptions={entityDatasetOptions}
      primaryFieldOptions={primaryFieldOptions}
      access={access}
      setAccess={setAccess}
      secondaryFieldOptions={secondaryFieldOptions}
      contextFieldOptions={contextFieldOptions}
      isEnabled={isEnabled}
      setIsEnabled={setIsEnabled}
      isActiveFilters={isActiveFilters}
      setIsActiveFilters={setIsActiveFilters}
      onSave={onSave}
      isLoading={isLoading}
      isEditing={!!entity}
      isAccessOnly={!!isAccessOnly}
      isReadOnly={false}
      close={close}
      isValid={isValid}
    />
  );
};

export default EntityFormContainer;
