import React, { useCallback, useContext, useEffect } from 'react';
import EmptyReport from './EmptyReport';
import WidgetGalleryContext from '../../../contexts/WidgetGalleryContext';

const hasEditingSearchQuery = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const isEditing =
    urlParams.has('editing') && urlParams.get('editing') === 'true';
  return isEditing;
};

const EmptyReportContainer = () => {
  const { open } = useContext(WidgetGalleryContext);

  const onAddWidgetsClicked = useCallback(() => {
    open();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hasEditingSearchQuery()) {
      onAddWidgetsClicked();
    }
  }, [onAddWidgetsClicked]);

  return <EmptyReport onAddWidgetsClicked={onAddWidgetsClicked} />;
};

export default EmptyReportContainer;
