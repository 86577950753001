import React, { useContext } from 'react';

import FlexCentered from '../../../../components/Common/FlexCentered';
import MetricListStyles from './styles';
import Loading from '../../../../components/Loading';
import DataManagerSpecificSetContext from '../../../../contexts/DataManagerSpecificSetContext';

const LoadingMetrics = () => {
  const { isOnSpecificSet } = useContext(DataManagerSpecificSetContext);
  const offset = isOnSpecificSet
    ? MetricListStyles.SPECIFIC_SET_LIST_HEIGHT_OFFSET
    : MetricListStyles.ALL_SETS_LIST_HEIGHT_OFFSET;
  return (
    <FlexCentered
      style={{
        height: `calc(100vh - ${offset}px - 10px)`,
      }}
    >
      <Loading />
    </FlexCentered>
  );
};

export default LoadingMetrics;
