import React, { useCallback, useContext, useEffect } from 'react';
import { PortalsContext } from '../../contextProviders/PortalsProvider';

const useIsPublished = ({
  type,
  typeId,
}: {
  type: ExecutivePortalPublishableContentType;
  typeId: string;
}) => {
  const { publishedLookup } = useContext(PortalsContext);
  const getIsPublished = useCallback(() => {
    if (type === 'report') {
      const portals = publishedLookup.reports[typeId] || [];
      return portals.length > 0;
    }

    if (type === 'dashboard') {
      const portals = publishedLookup.dashboards[typeId] || [];
      return portals.length > 0;
    }

    if (type === 'scorecard') {
      const portals = publishedLookup.scorecards[typeId] || [];
      return portals.length > 0;
    }

    return false;
  }, [
    publishedLookup.dashboards,
    publishedLookup.reports,
    publishedLookup.scorecards,
    type,
    typeId,
  ]);
  const [isPublished, setIsPublished] = React.useState<boolean>(() =>
    getIsPublished(),
  );
  useEffect(() => {
    setIsPublished(getIsPublished());
  }, [getIsPublished]);

  return isPublished;
};

export default useIsPublished;
