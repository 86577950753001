import React, { useContext, useEffect, useState } from 'react';
import getTargetEntityFields from '../api/getTargetEntityFields';
import GqlClientContext from '../../../contexts/GqlClientContext';
import useGetFieldLabel from '../../../hooks/useGetFieldLabel';
import TargetFormContext from '../contexts/TargetFormContext';
import AnalyticsContext from '../../../contexts/AnalyticsContext';
import useTargetFormCallbacks from './useTargetFormCallbacks';

const useTargetEntityFields = (dataset: string) => {
  const { client } = useContext(GqlClientContext);
  const [targetEntityFields, setTargetEntityFields] = useState<string[]>([]);

  useEffect(() => {
    let isActive = true;
    getTargetEntityFields(client, dataset).then((fields) => {
      if (!isActive) {
        return;
      }
      setTargetEntityFields(fields);
    });

    return () => {
      isActive = false;
    };
  }, [client, dataset]);

  return targetEntityFields;
};

const useGroupFieldOptions = ({
  dataType,
  groupField,
  setGroupField,
  getConfirmation,
}: {
  dataType: string;
  groupField?: string;
  setGroupField: React.Dispatch<React.SetStateAction<string | undefined>>;
  getConfirmation: () => Promise<boolean>;
}) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const { setIsShowingValidationFeedback } = useContext(TargetFormContext);
  const { resetGroups } = useTargetFormCallbacks();
  const { getFieldLabel } = useGetFieldLabel();
  const entityFields = useTargetEntityFields(dataType);
  const [groupFieldOptions, setGroupFieldOptions] = useState<DropdownOption[]>(
    [],
  );

  useEffect(() => {
    const newOptions = entityFields.map((f) => ({
      label: getFieldLabel({ field: f, dataType }),
      isSelected: groupField === f,
      onSelected: async () => {
        const isConfirmed = await getConfirmation();
        if (!isConfirmed) {
          return;
        }
        setIsShowingValidationFeedback(false);
        trackEvent('Targets - Group Selected', { groupField: f });
        setGroupField(f);
        resetGroups(true);
      },
    }));

    setGroupFieldOptions(newOptions);
  }, [
    dataType,
    entityFields,
    getConfirmation,
    getFieldLabel,
    groupField,
    resetGroups,
    setGroupField,
    setIsShowingValidationFeedback,
    trackEvent,
  ]);

  return groupFieldOptions;
};

export default useGroupFieldOptions;
