import isRankingMatrix from './isRankingMatrix';
import isGauge from './isGauge';
import isRemindersGadget from './isRemindersGadget';
import isSingleMetricDateMatrix from './isSingleMetricDateMatrix';
import isV5ChartDef from './isV5ChartDef';
import isScorecard from './isScorecard';
import isGeneralGoal from './isGeneralGoal';
import isMetricList from './isMetricList';
import isPaceMatrix from './isPaceMatrix';

const visTypeCheckers = {
  isRankingMatrix,
  isGauge,
  isRemindersGadget,
  isSingleMetricDateMatrix,
  isMetricList,
  isV5ChartDef,
  isScorecard,
  isGeneralGoal,
  isPaceMatrix,
};

export default visTypeCheckers;
