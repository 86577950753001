import { useEffect, useState } from 'react';

import useDateScope from 'hooks/useDateScope';
import { intervalToHuman } from 'hooks/goals/useGoalProgressBar';
import isoDateToAmerican from 'isoDateToAmerican';

const useChartTitle = ({
  interval,
  metric,
}: {
  interval?: FleetOps.Interval;
  metric: MetricInput;
}) => {
  const { startDate, endDate } = useDateScope({});
  const [title, setTitle] = useState<string>('');

  useEffect(() => {
    if (!startDate || !endDate || !interval) {
      setTitle('');
      return;
    }

    setTitle(
      `${intervalToHuman(interval)} ${metric.aggFunc} of ${metric.field} on ${
        metric.dataType
      } - ${isoDateToAmerican(startDate)} - ${isoDateToAmerican(endDate)}`,
    );
  }, [
    endDate,
    interval,
    metric.aggFunc,
    metric.dataType,
    metric.field,
    startDate,
  ]);

  return title;
};

export default useChartTitle;
