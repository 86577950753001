import React from 'react';
import CustomerLaneSlideOutHeading from './Heading';

import styled from 'styled-components';
import Commitment from './Commitment';
import Performance from '../../BoardSlideOut/Performance';
import Comments from './Comments';
import Nav from './Nav';
import Metrics from '../../BoardSlideOut/Metrics';

export const Wrapper = styled.div`
  height: 100%;
  box-shadow:
    0 0 0 1px rgba(53, 45, 67, 0.07),
    -1px 0 3px 0 rgba(53, 45, 67, 0.12),
    -1px 0 12px 5px rgba(53, 45, 67, 0.1);
  display: flex;
  flex-direction: row;
  z-index: 99999;
`;

const SlideOut = ({
  activeNavElement,
  onNavScroll,
  config,
  gridFilter,
  metricsFilter,
  chartDateScope,
  gridDateScope,
}: {
  activeNavElement: CustomerLaneSlideOutNavElement;
  onNavScroll: (event: React.UIEvent<HTMLDivElement>) => void;
  config: TargetVisConfig;
  gridFilter?: FilterInput;
  metricsFilter?: FilterInput;
  chartDateScope?: DateRangeInput;
  gridDateScope?: DateRangeInput;
}) => (
  <Wrapper>
    <Nav activeNavElement={activeNavElement} />
    <div style={{ width: '100%' }}>
      <CustomerLaneSlideOutHeading />
      <div
        onScroll={onNavScroll}
        id={'details-slide-scroll-area'}
        style={{
          height: 'calc(100% - 84px)',
          width: '100%',
          overflowY: 'scroll',
          padding: '20px 24px',
        }}
      >
        <div id="details-slide-commitment">
          <Commitment />
        </div>
        <div id="details-slide-performance">
          <Performance
            config={config}
            filterInput={gridFilter}
            dateScope={chartDateScope}
          />
          <Metrics
            filterInput={metricsFilter}
            title={'Metrics'}
            dateScope={gridDateScope}
          />
        </div>
        <div id="details-slide-comments">
          <Comments />
        </div>
      </div>
    </div>
  </Wrapper>
);

const Gate = ({
  activeNavElement,
  onNavScroll,
  config,
  gridFilter,
  metricsFilter,
  chartDateScope,
  gridDateScope,
}: {
  activeNavElement: CustomerLaneSlideOutNavElement;
  onNavScroll: (event: React.UIEvent<HTMLDivElement>) => void;
  config: TargetVisConfig;
  gridFilter?: FilterInput;
  metricsFilter?: FilterInput;
  chartDateScope?: DateRangeInput;
  gridDateScope?: DateRangeInput;
}) => {
  return (
    <SlideOut
      activeNavElement={activeNavElement}
      onNavScroll={onNavScroll}
      config={config}
      gridFilter={gridFilter}
      metricsFilter={metricsFilter}
      chartDateScope={chartDateScope}
      gridDateScope={gridDateScope}
    />
  );
};

export default Gate;
