import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useGetMetric } from '../../../../../../GoalShow/useGoalData';
import PerformanceWizardContext from '../../PerformanceWizardContext';
import PerformanceMetricItem from './PerformanceMetricItem';
import useDatePeriodOptions from './useDatePeriodOptions';
import useMetricDatasetLabel from '../../../../../../../components/Inputs/MetricPicker/MetricPickerPopup/hooks/useMetricdatasetLabel';
import useGetIsMetricNameValid from '../useGetIsMetricNameValid';
import useIsExistingMetric from './useIsExistingMetric';

const PerformanceMetricItemContainer = ({
  performanceMetric,
  isReadOnly,
}: {
  performanceMetric: PerformanceConfiguration.Client.PerformanceMetric;
  isReadOnly?: boolean;
}) => {
  const { setWizardState } = useContext(PerformanceWizardContext);
  const datePeriodOptions = useDatePeriodOptions(performanceMetric);
  const getMetric = useGetMetric();
  const [metric, setMetric] = useState<Metrics.Metric | undefined>(() =>
    getMetric(performanceMetric.metricId),
  );
  const { datasetLabel } = useMetricDatasetLabel(metric);
  const getIsMetricNameValid = useGetIsMetricNameValid();
  const isExistingMetric = useIsExistingMetric(performanceMetric);
  const isFieldNameValid = getIsMetricNameValid(performanceMetric);

  // Set metric
  useEffect(() => {
    setMetric(getMetric(performanceMetric.metricId));
  }, [getMetric, performanceMetric.metricId]);

  const onDateFieldSelected = useCallback(
    (newDateField: string) => {
      setWizardState((s) => ({
        ...s,
        config: {
          ...s.config,
          metricsStep: {
            ...s.config.metricsStep,
            metrics: s.config.metricsStep.metrics.map((m) => {
              if (m.key === performanceMetric.key) {
                return {
                  ...m,
                  dateField: newDateField,
                };
              }
              return m;
            }),
          },
        },
      }));
    },
    [performanceMetric.key, setWizardState],
  );

  const onFieldNameChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newFieldName =
        event.target.value === '' ? undefined : event.target.value;

      setWizardState((s) => ({
        ...s,
        config: {
          ...s.config,
          metricsStep: {
            ...s.config.metricsStep,
            metrics: s.config.metricsStep.metrics.map((m) => {
              if (m.key === performanceMetric.key) {
                return {
                  ...m,
                  fieldName: newFieldName,
                };
              }
              return m;
            }),
          },
        },
      }));
    },
    [performanceMetric.key, setWizardState],
  );

  const onDatePeriodLengthChanged = useCallback(
    (newLength: number | undefined) => {
      setWizardState((s) => ({
        ...s,
        config: {
          ...s.config,
          metricsStep: {
            ...s.config.metricsStep,
            metrics: s.config.metricsStep.metrics.map((m) => {
              if (m.key === performanceMetric.key) {
                return {
                  ...m,
                  datePeriodLength: newLength !== undefined ? newLength : 1,
                };
              }
              return m;
            }),
          },
        },
      }));
    },
    [performanceMetric.key, setWizardState],
  );

  if (!metric) {
    return null;
  }

  return (
    <PerformanceMetricItem
      performanceMetric={performanceMetric}
      metric={metric}
      datasets={datasetLabel}
      onDateFieldSelected={onDateFieldSelected}
      datePeriodOptions={datePeriodOptions}
      onDatePeriodLengthChanged={onDatePeriodLengthChanged}
      isReadOnly={isReadOnly}
      isExistingMetric={isExistingMetric}
      onFieldNameChanged={onFieldNameChanged}
      isFieldNameValid={isFieldNameValid}
    />
  );
};

export default PerformanceMetricItemContainer;
