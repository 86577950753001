import InputError from 'components/InputError';
import Inputs from 'components/Inputs';
import ShowPassword from 'components/ShowPassword';
import Typography from 'kingpin/atoms/Typography';
import React, { ChangeEvent } from 'react';

const PasswordInput = ({
  password,
  onPasswordChanged,
  passwordErrors,
  isHidingPassword,
  setIsHidingPassword,
  label,
}: {
  password: string;
  onPasswordChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  passwordErrors?: string[];
  isHidingPassword: boolean;
  setIsHidingPassword: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
}) => {
  return (
    <>
      <div style={{ marginBottom: '4px' }}>
        <Typography.Body type="Label">{label}</Typography.Body>
      </div>
      <div style={{ position: 'relative' }}>
        <Inputs.TextInput
          value={password}
          onChange={onPasswordChanged}
          type={isHidingPassword ? 'password' : 'text'}
          name="password"
          data-testid="password-without-confirm"
        />
        <ShowPassword
          isHiding={isHidingPassword}
          setIsHiding={setIsHidingPassword}
        />
      </div>
      {passwordErrors && (
        <div style={{ marginTop: 4 }}>
          {passwordErrors.map((pe) => (
            <InputError text={pe} key={Math.random()} />
          ))}
        </div>
      )}
    </>
  );
};

export default PasswordInput;
