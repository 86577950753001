const CHART_1 = '#a7abea';
const CHART_2 = '#EAB16D';
const CHART_3 = '#7AB1D2';
const CHART_4 = '#EAD659';
const CHART_5 = '#6DDC8F';
const CHART_6 = '#EA962E';
const CHART_7 = '#A9D346';
const CHART_8 = '#EA60A8';
const CHART_9 = '#B0C95C';
const CHART_10 = '#C04FEA';
const CHART_11 = '#EA3E43';
const CHART_12 = '#194B3D';
const CHART_13 = '#191939';
const CHART_14 = '#1F5D4F';
const CHART_15 = '#224C19';
const CHART_16 = '#5C1F35';
const CHART_17 = '#C55259';
const CHART_18 = '#E05A00';
const CHART_19 = '#5364A7';
const CHART_20 = '#295D1F';
const CHART_21 = '#55C757';
const CHART_22 = '#1BBE95';
const CHART_23 = '#56C7BE';
const CHART_24 = '#673C18';
const CHART_25 = '#141E95';
const CHART_26 = '#1F306E';
const CHART_27 = '#4A1942';
const CHART_28 = '#6455C6';
const CHART_29 = '#C654A4';
const CHART_30 = '#5D501F';
const CHART_31 = '#C7B656';
const CHART_32 = '#48181B';
const CHART_33 = '#BA2020';
const CHART_34 = '#472518';
const CHART_35 = '#5D251F';
const CHART_36 = '#FFA70B';
const CHART_37 = '#F0D632';

const ChartColors = [
  CHART_1,
  CHART_2,
  CHART_3,
  CHART_4,
  CHART_5,
  CHART_6,
  CHART_7,
  CHART_8,
  CHART_9,
  CHART_10,
  CHART_11,
  CHART_12,
  CHART_13,
  CHART_14,
  CHART_15,
  CHART_16,
  CHART_17,
  CHART_18,
  CHART_19,
  CHART_20,
  CHART_21,
  CHART_22,
  CHART_23,
  CHART_24,
  CHART_25,
  CHART_26,
  CHART_27,
  CHART_28,
  CHART_29,
  CHART_30,
  CHART_31,
  CHART_32,
  CHART_33,
  CHART_34,
  CHART_35,
  CHART_36,
  CHART_37,
];

export default ChartColors;
