import { useCallback, useContext, useEffect, useState } from 'react';
import isDefined from '../../../isDefined';
import TargetFormContext from '../contexts/TargetFormContext';

const useGroupFieldKeywordFilter = (group: Targets.Wizard.TargetGroup) => {
  const { groupField, groups } = useContext(TargetFormContext);
  const getKeywordFilter = useCallback(() => {
    if (groupField === undefined || groupField === '*') {
      return undefined;
    }

    if (group.isFallback) {
      const usedValues = groups
        .map((g) => g.groupName)
        .filter(isDefined)
        .reduce((a, b) => [...a, ...b], []);

      return {
        field: groupField,
        values: usedValues,
        exclude: true,
      };
    }

    if (group.groupName === undefined) {
      return undefined;
    }

    return {
      field: groupField,
      values: group.groupName,
    };
  }, [group.groupName, group.isFallback, groupField, groups]);
  const [keywordFilter, setKeywordFilter] = useState<KeywordFilter | undefined>(
    () => getKeywordFilter(),
  );
  useEffect(() => {
    setKeywordFilter(getKeywordFilter());
  }, [getKeywordFilter]);

  return keywordFilter;
};

const useFilterInputForGroup = ({
  group,
}: {
  group: Targets.Wizard.TargetGroup;
}) => {
  const groupFieldKeywordFilter = useGroupFieldKeywordFilter(group);
  const getFilterInput = useCallback(() => {
    return {
      keywords: groupFieldKeywordFilter ? [groupFieldKeywordFilter] : undefined,
    };
  }, [groupFieldKeywordFilter]);

  const [filterInput, setFilterInput] = useState<FilterInput | undefined>(() =>
    getFilterInput(),
  );
  useEffect(() => {
    setFilterInput(getFilterInput());
  }, [getFilterInput]);

  return filterInput;
};

export default useFilterInputForGroup;
