import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import useFieldDefinitions from '../../../hooks/useFieldDefinitions';
import _ from 'lodash';
import ReportDrillDownFormContext from '../../../contexts/ReportDrillDownFormContext';
import ReportDrillDownsContext from '../../../contexts/ReportDrillDownsContext';
import SingleItemPicker from '../../SingleItemPicker';
import toSentenceCase from '../../../services/toSentenceCase';
import VariableFiltersContext from '../../../contexts/VariableFiltersContext';
import { getDrillDownFieldName } from 'hooks/useDrillDownFieldName';
import BaseViewsContext from '../../../contexts/BaseViewsContext';
import DashboardContext from '../../../contexts/DashboardContext';
import isDashboardTemplate from '../../../types/isDashboardTemplate';
import Badge from 'components/Badge';
import DatasetFiltersContext from 'screens/DataManager/DatasetFilters/context/DatasetFiltersContext';
import DatasetFilterList from './DatasetFilterList';

interface Option {
  key: string;
  label: string;
  value: any;
  renderRight?: JSX.Element;
}

export const isVariableDrillDown = (
  d: FleetOps.Field | DateBucketField | VariableDrillDownType | DatasetFilter,
): d is VariableDrillDownType => {
  return 'type' in d && d.type === 'Variable';
};

const isDatasetFilter = (
  d: FleetOps.Field | DateBucketField | VariableDrillDownType | DatasetFilter,
): d is DatasetFilter => {
  return 'type' in d && d.type === 'Dataset';
};

const FieldPickerContainer = () => {
  const { baseViews } = useContext(BaseViewsContext);
  const [searchText, setSearchText] = useState<string>('');
  const { dataTypes, drillDowns, scope, isForDatasetFilterPicker } = useContext(
    ReportDrillDownsContext,
  );
  const { variableFilters } = useContext(VariableFiltersContext);
  const { dashboard } = useContext(DashboardContext);
  const { fieldDefinitions, isLoading } = useFieldDefinitions(dataTypes);
  const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);
  const { getDataTypeFilterPlates } = useContext(DatasetFiltersContext);

  const savedDatasetFilters = getDataTypeFilterPlates(
    dataTypes || window.emptyArray,
  );

  const getBaseField = useCallback(
    (f: FleetOps.Field) => {
      if (!f.dataType) {
        return undefined;
      }

      const bv = baseViews[f.dataType];
      if (!bv) {
        return undefined;
      }

      return bv.fields[f.field];
    },
    [baseViews],
  );

  useEffect(() => {
    const allowedDefs = fieldDefinitions.filter(
      (d) => d.field !== 'type' && d.field !== 'id',
    );

    const baseDefs = allowedDefs.filter((f) => {
      const bf = getBaseField(f);
      if (!bf) {
        return true;
      }

      return bf.isVisible;
    });

    const variableOptions = (variableFilters || []).map((v) => ({
      key: v.id,
      label: getDrillDownFieldName(v.value, baseViews),
      value: v,
      renderRight: <Badge text="Variable Filter" badgeType={'Warning'} />,
    }));

    const defOptions = baseDefs
      .filter(
        (def) =>
          !variableOptions.some((vo) => vo.value.value.field === def.field),
      )
      .map((def) => {
        const bf = getBaseField(def);
        const alias = bf ? bf.nameAlias : def.alias;

        return {
          key: `${toSentenceCase(def.field)} - Fixed`,
          label: alias ? alias : def.field,
          value: def,
        };
      });

    const baseOptions = _.uniqBy([...defOptions], 'key');

    const unusedVariableOptions = variableOptions.filter(
      (vo) =>
        !drillDowns.some(
          (d) => d.type === 'Variable' && d.variableId === vo.value.id,
        ) &&
        !scope.some(
          (d) => d.type === 'Variable' && d.variableId === vo.value.id,
        ),
    );

    const pickerOptions = (() => {
      if (isDashboardTemplate(dashboard)) {
        return _.sortBy(
          [...baseOptions, ...unusedVariableOptions],
          'label',
        ) as Option[];
      } else {
        return _.sortBy([...baseOptions], 'label') as Option[];
      }
    })();

    const newFilteredOptions = (() => {
      if (searchText === '') {
        return pickerOptions;
      }

      return pickerOptions.filter((o) =>
        o.label.toLowerCase().includes(searchText.toLowerCase()),
      );
    })();

    setFilteredOptions(newFilteredOptions);
  }, [
    baseViews,
    fieldDefinitions,
    getBaseField,
    dashboard,
    searchText,
    variableFilters,
    scope,
    drillDowns,
  ]);

  const { onFieldSelected, onVariableFilterAdded, onDatasetFilterAdded } =
    useContext(ReportDrillDownFormContext);

  const onSearchTextChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const onOptionSelected = (
    field:
      | FleetOps.Field
      | DateBucketField
      | VariableDrillDownType
      | DatasetFilter,
  ) => {
    if (isVariableDrillDown(field)) {
      onVariableFilterAdded(field);
    } else if (isDatasetFilter(field)) {
      onDatasetFilterAdded(field);
    } else {
      onFieldSelected(field);
    }
  };

  return (
    <SingleItemPicker
      testId="drill-down-field-picker"
      options={filteredOptions}
      onOptionSelected={onOptionSelected}
      searchText={searchText}
      onSearchTextChanged={onSearchTextChanged}
      isLoading={isLoading}
      renderBeforeOptions={
        <DatasetFilterList
          isForDatasetFilterPicker={isForDatasetFilterPicker}
          onOptionSelected={onOptionSelected}
          savedDatasetFilters={savedDatasetFilters}
        />
      }
    />
  );
};

export default FieldPickerContainer;
