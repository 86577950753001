import React from 'react';
import styled from 'styled-components';
import SummaryIcon from 'images/details-slideout/summary.svg';
import Colors2 from '../../theme/Colors2';
import Icon from '../../kingpin/atoms/Icon';

const Wrapper = styled.div`
  width: 48px;
  height: 100%;
  background-color: #fbfbfe;
  box-shadow: 1px 0 0 0 rgba(53, 45, 67, 0.07);
  padding: 24px 8px;
`;

const Button = styled.div<{ isActive?: boolean }>`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 6px;
  border-radius: 4px;
  cursor: pointer;
  ${(props) => props.isActive && 'background-color: #ededf2;'}

  &:hover {
    opacity: 0.7;
  }
`;

const Img = styled.img``;

const scrollTo = (id: string) => {
  const scroller = document.getElementById('board-slide-scroll-area');

  const dataElem = document.getElementById('board-slide-data');
  const reviewElem = document.getElementById('driver-slide-review');

  if (!scroller || !dataElem || !reviewElem) {
    return;
  }

  if (id === 'board-slide-data') {
    scroller.scrollTop = 0;
  } else if (id === 'driver-slide-review') {
    scroller.scrollTop = dataElem.clientHeight;
  }
};

const Nav = ({
  activeNavElement,
  hideEscalationSection,
}: {
  activeNavElement: 'data' | 'review';
  hideEscalationSection: boolean;
}) => (
  <Wrapper>
    <Button
      isActive={'data' === activeNavElement}
      onClick={() => scrollTo('board-slide-data')}
    >
      <Img src={SummaryIcon} />
    </Button>
    {!hideEscalationSection && (
      <Button
        isActive={'review' === activeNavElement}
        onClick={() => scrollTo('driver-slide-review')}
      >
        <Icon icon="circleCheckmark" color={Colors2.Grey['1']} />
      </Button>
    )}
  </Wrapper>
);

export default Nav;
