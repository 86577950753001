import { useCallback, useContext, useEffect, useState } from 'react';
import FilterBuilderContext from '../FilterBuilderContext';
import useGetFieldLabel from '../../../../hooks/useGetFieldLabel';
import AccountPickerContext from '../../../../contexts/AccountPickerContext';

const DEFAULT_FIELD_TYPE_GREEN_LIST = [
  'text',
  'int',
  'float',
  'long',
  'boolean',
];
const DELIMITER = '----';

const useRecentFields = () => {
  const { selectedAccountId } = useContext(AccountPickerContext);
  const [recentFields, setRecentFields] = useState<string[]>([]);

  const getRecents = useCallback(() => {
    const storedFields = localStorage.getItem(
      `${selectedAccountId}-recent-target-fields`,
    );
    if (storedFields === null) {
      return [];
    }

    return storedFields.split(DELIMITER);
  }, [selectedAccountId]);

  const writeRecents = useCallback(
    (newRecents: string[]) => {
      localStorage.setItem(
        `${selectedAccountId}-recent-target-fields`,
        newRecents.join(DELIMITER),
      );
      setRecentFields(newRecents);
    },
    [selectedAccountId],
  );

  useEffect(() => {
    setRecentFields(getRecents());
  }, [getRecents, selectedAccountId]);

  const addToRecents = useCallback(
    (field: string) => {
      const recents = [...getRecents()];
      if (recents.includes(field)) {
        return;
      }

      const newRecents = [field, ...recents].filter((r, index) => {
        return index <= 4;
      });
      writeRecents(newRecents);
    },
    [getRecents, writeRecents],
  );

  return {
    recentFields,
    addToRecents,
  };
};

const useFieldOptions = (
  selectedField: string | undefined,
  onFieldSelected: ({
    field,
    condition,
  }: {
    field: string;
    condition: FilterBuilder.Condition | undefined;
  }) => void,
) => {
  const { recentFields, addToRecents } = useRecentFields();
  const { datasetDefinition, fieldFilterPredicate, filters } =
    useContext(FilterBuilderContext);
  const { getFieldLabel } = useGetFieldLabel();
  const [options, setOptions] = useState<DropdownOption[]>([]);
  const [recentOptions, setRecentOptions] = useState<DropdownOption[]>([]);

  useEffect(() => {
    setOptions(
      datasetDefinition.fields
        .filter((f) => {
          if (fieldFilterPredicate) {
            return fieldFilterPredicate(f);
          }

          return DEFAULT_FIELD_TYPE_GREEN_LIST.includes(f.type);
        })
        .filter(
          (f) => !filters.some((existingF) => existingF.field === f.field),
        )
        .map((f) => ({
          value: f.field,
          label: getFieldLabel({
            field: f.field,
            dataType: datasetDefinition.type,
          }),
          isSelected: f.field === selectedField,
          onSelected: () => {
            const newCondition = (() => {
              if (f.type === 'text') {
                return 'in';
              }
              return undefined;
            })();
            onFieldSelected({
              field: f.field,
              condition: newCondition,
            });
            addToRecents(f.field);
          },
        })),
    );
  }, [
    addToRecents,
    datasetDefinition.fields,
    datasetDefinition.type,
    fieldFilterPredicate,
    filters,
    getFieldLabel,
    onFieldSelected,
    selectedField,
  ]);

  useEffect(() => {
    setRecentOptions(
      options.filter((o) => !!o.value && recentFields.includes(o.value)),
    );
  }, [options, recentFields]);

  return {
    recentFieldOptions: recentOptions,
    fieldOptions: options,
  };
};

export default useFieldOptions;
