import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

const runAggregateCompoundMetricQuery = (
  query: any,
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return client.query(query);
};

export default runAggregateCompoundMetricQuery;
