import React, { ChangeEvent, useContext, useEffect, useState } from 'react';

import getTimeStamp from 'getTimeStamp';
import getIdentifier from 'getIdentifier';
import CurrentUserContext from 'contexts/CurrentUserContext';
import usePerformanceSlideOutFilterInput from 'components/PerformanceBoards/hooks/usePerformanceSlideOutFilterInput';
import useFullDataInterval from 'components/PerformanceBoards/hooks/useFullDataInterval';
import useSaveWidget from 'components/PerformanceBoards/hooks/useSaveWidget';
import GridSlideoutWizard from './GridSlideoutWizard';

const GridSlideoutWizardContainer = ({
  close,
  editingSection,
}: {
  close: () => void;
  editingSection: PerformanceBoardTypes.SlideOut.GridSection | undefined;
}) => {
  const { id: currentUserId } = useContext(CurrentUserContext);

  const [id] = useState<string>(
    editingSection ? editingSection.id : getIdentifier(),
  );
  const [createdBy] = useState<string>(
    editingSection ? editingSection.createdBy : currentUserId,
  );
  const [createdOn] = useState<string>(
    editingSection ? editingSection.createdOn : getTimeStamp(),
  );
  const [title, setTitle] = useState<string>(
    editingSection ? editingSection.title : '',
  );
  const [fields, setFields] = useState<string[]>(
    editingSection ? editingSection.fields : [],
  );
  const [dataset, setDataset] = useState<string | undefined>(
    editingSection ? editingSection.dataset : undefined,
  );

  const [widgetDateRange, setWidgetDateRange] =
    useState<PerformanceBoardTypes.SlideOut.SlideOutDateRange>({
      mode: editingSection ? editingSection.dateRange.mode : 'Current Interval',
      n:
        editingSection && editingSection.dateRange.mode === 'Last'
          ? editingSection.dateRange.n
          : 1,
      isToDate: editingSection ? editingSection.dateRange.isToDate : false,
    });

  const [gridSection, setGridSection] = useState<
    PerformanceBoardTypes.SlideOut.GridSection | undefined
  >();

  const filterInput = usePerformanceSlideOutFilterInput();
  const { dateScope } = useFullDataInterval(widgetDateRange);
  const saveWidget = useSaveWidget({
    section: gridSection,
    editingSection,
  });

  const isDisabled =
    !dataset ||
    dataset.length === 0 ||
    fields.length === 0 ||
    title === '' ||
    (!widgetDateRange.n && widgetDateRange.mode === 'Last');

  const onTitleChanged = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setTitle(event.target.value);
  };

  useEffect(() => {
    if (isDisabled) {
      setGridSection(undefined);
      return;
    }

    const newGridSection = {
      id: id,
      createdBy: createdBy,
      updatedBy: currentUserId,
      createdOn: createdOn,
      updatedOn: getTimeStamp(),
      sectionType: 'Simple Grid' as 'Simple Grid',
      title: title,
      dataset: dataset,
      fields: fields,
      dateRange: widgetDateRange,
    };

    setGridSection(newGridSection);
  }, [
    createdBy,
    createdOn,
    currentUserId,
    dataset,
    fields,
    id,
    isDisabled,
    title,
    widgetDateRange,
  ]);

  return (
    <GridSlideoutWizard
      saveWidget={saveWidget}
      title={title}
      onTitleChanged={onTitleChanged}
      widgetDateRange={widgetDateRange}
      setWidgetDateRange={setWidgetDateRange}
      fields={fields}
      setFields={setFields}
      dataset={dataset}
      setDataset={setDataset}
      isDisabled={isDisabled}
      filterInput={filterInput}
      dateScope={dateScope}
      gridSection={gridSection}
      close={close}
    />
  );
};

export default GridSlideoutWizardContainer;
