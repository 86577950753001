import styled from 'styled-components';
import NAVIGATION from '../../../kingpin/navigation/constants';
import { useContext } from 'react';
import UsersSettingsContext from '../../../contexts/UsersSettingsContext';
import Loading from '../../../components/Loading/Loading';
import NavItem from '../../../kingpin/navigation/NavItem';
import { buildShowUser } from '../../../navigation/appRoutes';
import NewUserButtonContainer from '../../../components/Settings/NewUserButton';
import Colors2 from '../../../theme/Colors2';
import { PortalsContext } from '../../../contextProviders/PortalsProvider';

const Top = styled.div`
  height: ${NAVIGATION.TOP_HEIGHT}px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;
  border-bottom: 1px solid ${Colors2.Border};
  width: 100%;

  span {
    color: #1e1a26;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; /* 185.714% */
    letter-spacing: -0.042px;
  }
`;

const SidebarDiv = styled.div`
  width: 260px;
  min-width: 260px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  border-right: 1px solid ${Colors2.Border};
  overflow: hidden;
  position: relative;
`;

const AddUserBtnDiv = styled.div`
  width: 100%;
`;

const UsersList = styled.div`
  width: 100%;
  padding: 16px 10px;
  overflow-y: auto;
`;

const UsersSidebar = () => {
  const { selectedPortal } = useContext(PortalsContext);
  const { users, isLoading } = useContext(UsersSettingsContext);

  return (
    <SidebarDiv>
      <Top>
        <span>Users</span>
      </Top>
      <AddUserBtnDiv>
        <NewUserButtonContainer />
      </AddUserBtnDiv>
      <UsersList>
        {isLoading && <Loading />}
        {users.map((user) => (
          <NavItem
            isTall
            key={user.id}
            to={buildShowUser(user.id, selectedPortal)}
            label={user.displayName}
          />
        ))}
      </UsersList>
    </SidebarDiv>
  );
};

export default UsersSidebar;
