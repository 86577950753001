import { useCallback, useContext, useEffect, useState } from 'react';
import DatasetDefinitionsContext from '../../../../../../contexts/DatasetDefinitionsContext';

const useDatasetRedList = () => {
  const { performanceDatasets } = useContext(DatasetDefinitionsContext);
  const getRedList = useCallback(() => {
    return [...performanceDatasets.map((ds) => ds.type), 'driverWeeks'];
  }, [performanceDatasets]);
  const [datasetRedList, setDatasetRedList] = useState<string[]>(() =>
    getRedList(),
  );

  useEffect(() => {
    setDatasetRedList(getRedList());
  }, [getRedList]);

  return datasetRedList;
};

export default useDatasetRedList;
