import { useCallback, useContext, useEffect, useState } from 'react';
import AccountPickerContext from 'contexts/AccountPickerContext';
import getPreviousUploads from '../../api/getPreviousUploads';

const useGetPreviousUploads = (dataType: string) => {
  const [previousUploads, setPreviousUploads] = useState<
    DataManager.PreviousUpload[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const { selectedAccountId } = useContext(AccountPickerContext);

  const getPreviousUploadsCallback = useCallback(async () => {
    const data = await getPreviousUploads(selectedAccountId, dataType);
    return data;
  }, [dataType, selectedAccountId]);

  const refreshPreviousUploads = useCallback(async () => {
    const newPreviousUploads = await getPreviousUploadsCallback();
    setPreviousUploads(newPreviousUploads);
    return;
  }, [getPreviousUploadsCallback]);

  useEffect(() => {
    let isActive = true;
    setIsLoading(true);
    getPreviousUploadsCallback().then((data) => {
      if (isActive) {
        setPreviousUploads(data);
        setIsLoading(false);
      }
    });

    return () => {
      isActive = false;
    };
  }, [getPreviousUploadsCallback]);

  return {
    previousUploads,
    isLoading,
    refreshPreviousUploads,
  };
};

export default useGetPreviousUploads;
