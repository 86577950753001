import React from 'react';
import DashboardsContext from 'contexts/DashboardsContext';
import useDashboards from '../../hooks/dashboards/useDashboards';

const DashboardsProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const {
    dashboards: allDashboards,
    isLoading,
    addOrUpdateDashboard,
  } = useDashboards();

  return (
    <DashboardsContext.Provider
      value={{
        allDashboards,
        isLoading,
        addOrUpdateDashboard,
      }}
    >
      {children}
    </DashboardsContext.Provider>
  );
};

export default DashboardsProvider;
