import { useCallback, useEffect, useState } from 'react';

const BASE_FIELD_RED_LIST = ['reasonCode', 'reasonNote'];

const useMetricFieldRedList = () => {
  const getRedList = useCallback(() => {
    return [...BASE_FIELD_RED_LIST];
  }, []);
  const [metricFieldRedList, setMetricFieldRedList] = useState<string[]>(() =>
    getRedList(),
  );
  useEffect(() => {
    setMetricFieldRedList(getRedList());
  }, [getRedList]);

  return metricFieldRedList;
};

export default useMetricFieldRedList;
