import React from 'react';
import PermissionGates from '../../../components/PermissionGates';
import PERMISSIONS from '../../../permissionDefinitions';
import ContextMenu, { Option, Info } from '../../../kingpin/atoms/ContextMenu';
import usePopup from '../../../hooks/usePopup';

const ReportContextMenu = ({
  report,
  onSettingClicked,
  onDeleteClicked,
}: {
  report: PersistedReportType;
  onSettingClicked: () => void;
  onDeleteClicked: () => void;
}) => {
  const { isOpen, open, close } = usePopup();
  return (
    <div className={isOpen ? '' : 'showOnMouseOver'}>
      <ContextMenu onOpen={open} onClose={close}>
        <PermissionGates.Has
          requiredPermission={
            PERMISSIONS.CONTENT_ACCESS.SET_ANY_AVAILABLE_TO_USERS_OR_PUBLIC
          }
        >
          <Option label={'Settings'} onClick={onSettingClicked} />
        </PermissionGates.Has>
        <Info {...report} />
        <PermissionGates.Has
          requiredPermission={PERMISSIONS.REPORT_MANAGEMENT.DELETE}
        >
          <Option label={'Delete'} onClick={onDeleteClicked} isDanger />
        </PermissionGates.Has>
      </ContextMenu>
    </div>
  );
};

export default ReportContextMenu;
