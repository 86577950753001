const moment = require('moment');

export const getMomentStartWeekday = (startOfWeek: WeekStartsOn): number => {
  switch (startOfWeek) {
    case 'SUN':
      return 0;
    case 'MON':
      return 1;
    case 'TUE':
      return 2;
    case 'WED':
      return 3;
    case 'THUR':
      return 4;
    case 'FRI':
      return 5;
    case 'SAT':
      return 6;
  }
};

const buildWeekStartsMomentConfig = (weekStartsOn: WeekStartsOn) => {
  const dow = getMomentStartWeekday(weekStartsOn);
  return {
    week: {
      dow,
      // doy is calculated as 7 + dow - janX, where janX is the x-th
      // January that must belong to the week No. 1.
      doy: 7 + dow - 1,
    },
  };
};

const configureWeekStartsLocale = (weekStartsOn: WeekStartsOn) => {
  const localeKey = `en-starting-${weekStartsOn}`;
  if (!moment.locales().includes(localeKey)) {
    moment.defineLocale(localeKey, buildWeekStartsMomentConfig(weekStartsOn));
  } else {
    moment.updateLocale(localeKey, buildWeekStartsMomentConfig(weekStartsOn));
  }
  moment.locale(localeKey);

  return localeKey;
};

export const floorIsoToWeekStart = (
  date: string,
  weekStartsOn: WeekStartsOn,
) => {
  return convertWeekDateToIsoDate(
    convertIsoDateToWeekDate(date, weekStartsOn),
    weekStartsOn,
  );
};

export const convertIsoDateToWeekDate = (
  date: string,
  weekStartsOn: WeekStartsOn,
): Scorecards.WeekDate => {
  const localeKey = configureWeekStartsLocale(weekStartsOn);
  const m = moment(date).locale(localeKey);
  return {
    week: m.week().toString(10),
    year: m.year().toString(10),
  };
};

export const convertWeekDateToIsoDate = (
  weekDate: Scorecards.WeekDate,
  weekStartsOn: WeekStartsOn,
) => {
  const localeKey = configureWeekStartsLocale(weekStartsOn);
  return moment()
    .locale(localeKey)
    .set({
      week: Number.parseInt(weekDate.week, 10),
      year: Number.parseInt(weekDate.year, 10),
    })
    .startOf('week')
    .format('YYYY-MM-DD');
};
