const VIEW_USER: FleetOps.Permission =
  'fleetops.permissions.user_management_view_user';
const INVITE_USER: FleetOps.Permission =
  'fleetops.permissions.user_management_invite_user';
const MODIFY_USER: FleetOps.Permission =
  'fleetops.permissions.user_management_modify_user';
const DELETE_USER: FleetOps.Permission =
  'fleetops.permissions.user_management_delete_user';
// legacy naming scheme
const CREATE_WALLBOARD: FleetOps.Permission =
  'fleetops.permissions.wallboards_create_wallboard';
// legacy naming scheme
const ASSIGN_ASSETS: FleetOps.Permission = 'fleetops.permissions.assign_assets';
const SEND_RESET_PASSWORD_EMAIL: FleetOps.Permission =
  'fleetops.permissions.user_management_send_reset_password_email';
const MANAGE_USER_INVITES: FleetOps.Permission =
  'fleetops.permissions.user_management_send_delete_user_invites';
const GRANT_INTEGRATION_PERMISSIONS: FleetOps.Permission =
  'fleetops.permissions.user_management_grant_revoke_integration_permissions';
const GRANT_BILLING_PERMISSIONS: FleetOps.Permission =
  'fleetops.permissions.user_management_grant_revoke_bill_permissions';
const GRANT_BOARD_OWNER_ROLE: FleetOps.Permission =
  'fleetops.permissions.user_management_grant_revoke_board_owner_role';
const GRANT_CAMPAIGN_OWNER_ROLE: FleetOps.Permission =
  'fleetops.permissions.user_management_grant_revoke_campaign_owner_role';
const GRANT_EDITOR_ROLE: FleetOps.Permission =
  'fleetops.permissions.user_management_grant_revoke_editor_role';
const GRANT_ADMIN_ROLE: FleetOps.Permission =
  'fleetops.permissions.user_management_grant_revoke_admin_role';
const CONFIGURE_SSO: FleetOps.Permission =
  'fleetops.permissions.user_management_configure_sso';

const USER_MANAGEMENT_PERMISSIONS = {
  VIEW_USER,
  INVITE_USER,
  MODIFY_USER,
  DELETE_USER,
  CREATE_WALLBOARD,
  ASSIGN_ASSETS,
  SEND_RESET_PASSWORD_EMAIL,
  MANAGE_USER_INVITES,
  GRANT_INTEGRATION_PERMISSIONS,
  GRANT_BILLING_PERMISSIONS,
  GRANT_BOARD_OWNER_ROLE,
  GRANT_CAMPAIGN_OWNER_ROLE,
  GRANT_EDITOR_ROLE,
  GRANT_ADMIN_ROLE,
  CONFIGURE_SSO,
};
Object.freeze(USER_MANAGEMENT_PERMISSIONS);

export default USER_MANAGEMENT_PERMISSIONS;
