import React, { ChangeEvent } from 'react';
import ExportModal from './ExportModal';

const ExportModalContainer = ({
  selectedUserIds,
  setSelectedUserIds,
  isExporting,
  startExport,
  notes,
  onNotesChanged,
  close,
}: {
  selectedUserIds: string[];
  setSelectedUserIds: React.Dispatch<React.SetStateAction<string[]>>;
  isExporting: boolean;
  startExport: () => void;
  notes: string;
  onNotesChanged: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  close: () => void;
}) => {
  const isDisabled = selectedUserIds.length === 0;

  return (
    <ExportModal
      setSelectedUserIds={setSelectedUserIds}
      selectedUserIds={selectedUserIds}
      isExporting={isExporting}
      startExport={startExport}
      notes={notes}
      onNotesChanged={onNotesChanged}
      isDisabled={isDisabled}
      close={close}
    />
  );
};

export default ExportModalContainer;
