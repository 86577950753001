import React from 'react';
import { ModalTransition } from '@atlaskit/modal-dialog';
import Button from 'kingpin/atoms/Button';

import usePopup from '../../../../../hooks/usePopup';
import useInitialWizardState from './useInitialWizardState';
import PerformanceDatasetWizard from '../../DatasetsIndex/PerformanceDatasetWizard';
import Tooltip from '../../../../../components/Tooltip';
import PermissionGates from '../../../../../components/PermissionGates';
import PERMISSIONS from '../../../../../permissionDefinitions';

const AddPerformanceDatasetFieldsButton = ({
  config,
}: {
  config: FleetOps.PerformanceDatasetConfig;
}) => {
  const initialWizardState = useInitialWizardState(config);
  const { isOpen, open, close } = usePopup();
  const isDisabled =
    config.status === 'updating' || config.status === 'creating';

  return (
    <>
      <PermissionGates.Has
        requiredPermission={
          PERMISSIONS.DATA_MANAGEMENT.UPDATE_PERFORMANCE_DATASET_CONFIG
        }
      >
        <Tooltip content={isDisabled ? 'This dataset is updating.' : undefined}>
          <Button
            type="Primary"
            size="Small"
            label="Add Fields"
            onClick={isDisabled ? undefined : open}
            icon="add"
            isDisabled={isDisabled}
          />
        </Tooltip>
      </PermissionGates.Has>
      <ModalTransition>
        {isOpen && (
          <PerformanceDatasetWizard
            close={close}
            initialState={initialWizardState}
          />
        )}
      </ModalTransition>
    </>
  );
};

export default AddPerformanceDatasetFieldsButton;
