import React from 'react';
import styled from 'styled-components';
import Typography from 'kingpin/atoms/Typography';

import Row from '../../../Common/Row';
import Colors2 from '../../../../theme/Colors2';

const CellWrapper = styled.div`
  border-bottom: 1px dashed #eceef1;
  margin-bottom: 8px;
  height: 32px;
`;

export const Cell = ({ label, value }: { label: string; value: string }) => (
  <CellWrapper>
    <Row spaceBetween centerAlign style={{ height: '100%' }}>
      <div style={{ marginBottom: 4 }}>
        <Typography.Body type={'Body 12'} color={Colors2.Grey['5']}>
          {label}
        </Typography.Body>
      </div>
      <div>
        <Typography.Body type={'Body 12'}>{value}</Typography.Body>
      </div>
    </Row>
  </CellWrapper>
);

const SummaryTable = ({ orderSummary }: { orderSummary: OrderSummary }) => (
  <Row spaceBetween>
    <div style={{ flex: 1, marginRight: 32 }}>
      <Cell label={'Customer'} value={orderSummary.customer} />
      <Cell label={'Shipper'} value={orderSummary.shipper} />
      <Cell label={'Consignee'} value={orderSummary.consignee} />
      <Cell label={'Started'} value={orderSummary.startLocation} />
      <Cell label={'Finish'} value={orderSummary.finishLocation} />
    </div>
    <div style={{ flex: 1 }}>
      <Cell label={'Truck'} value={orderSummary.trucks.join(', ')} />
      <Cell label={'Trailer'} value={orderSummary.trailers.join(', ')} />
      <Cell label={'Driver'} value={orderSummary.drivers.join(', ')} />
      <Cell label={'Commodity'} value={orderSummary.commodity} />
    </div>
  </Row>
);

export default SummaryTable;
