import React, { useCallback, useContext, useEffect, useState } from 'react';
import { GridApi } from 'ag-grid-community';
import { useNavigate } from 'react-router-dom';

import PeriodsContext from 'contexts/PeriodsContext';
import AnalyticsContext from 'contexts/AnalyticsContext';
import SelectedEntityContext from '../contexts/SelectedEntityContext';
import SlideOutContext from '../contexts/SlideOutContext';
import useEntityField from '../../TargetManager/hooks/useEntityField';
import useRowHighlighter from '../hooks/useRowHighlighter';
import useQueryParams from 'hooks/useQueryParams';

export const ENTITY_VALUE_QUERY_PARAM = 'ev';
export const PERIOD_QUERY_PARAM = 'p';

const useInitialEntityValue = () => {
  const urlParams = useQueryParams();

  const queriedEntityValue = urlParams.has(ENTITY_VALUE_QUERY_PARAM)
    ? (urlParams.get(ENTITY_VALUE_QUERY_PARAM) as string)
    : undefined;

  return queriedEntityValue;
};

const SelectedEntityProvider = ({
  board,
  gridApi,
  children,
}: {
  board: PerformanceBoardTypes.Board;
  gridApi: GridApi | undefined;
  children: JSX.Element | JSX.Element[];
}) => {
  const initialEntityValue = useInitialEntityValue();
  const { setIsOpen, isOpen } = useContext(SlideOutContext);
  const { selectedPeriod } = useContext(PeriodsContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const entityField = useEntityField(board.dataType);
  const navigate = useNavigate();
  const [selectedEntity, setSelectedEntity] = useState<string | undefined>();
  const onEntitySelected = useCallback(
    (newValue: string, skipUrlUpdate?: boolean) => {
      setSelectedEntity(newValue);
      setIsOpen(true);
      if (!skipUrlUpdate) {
        navigate({ search: `${ENTITY_VALUE_QUERY_PARAM}=${newValue}` });
      }

      trackEvent('Board - Slide out opened', {
        boardName: board.name,
        boardId: board.id,
        boardDataType: board.dataType,
      });
    },
    [setIsOpen, trackEvent, board, navigate],
  );

  useEffect(() => {
    if (isOpen) {
      return;
    }

    setSelectedEntity(undefined);
  }, [isOpen]);

  useEffect(() => {
    if (
      initialEntityValue &&
      selectedPeriod &&
      initialEntityValue !== selectedEntity
    ) {
      onEntitySelected(initialEntityValue, true);
    }
  }, [initialEntityValue, onEntitySelected, selectedEntity, selectedPeriod]);
  useRowHighlighter(gridApi, entityField, selectedEntity);

  if (!entityField) {
    return null;
  }

  return (
    <SelectedEntityContext.Provider
      value={{
        entityField,
        selectedEntity,
        onEntitySelected,
      }}
    >
      {children}
    </SelectedEntityContext.Provider>
  );
};

export default SelectedEntityProvider;
