import React from 'react';
import styled from 'styled-components';

import SummaryIcon from 'images/details-slideout/summary.svg';
import TruckIcon from 'images/details-slideout/truck.svg';
import CommentsIcon from 'images/details-slideout/comments.svg';

const Wrapper = styled.div`
  width: 48px;
  height: 100%;
  background-color: #fbfbfe;
  box-shadow: 1px 0 0 0 rgba(53, 45, 67, 0.07);
  padding: 24px 8px;
`;

const Button = styled.div<{ isActive?: boolean }>`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 6px;
  border-radius: 4px;
  cursor: pointer;
  ${(props) => props.isActive && 'background-color: #ededf2;'}

  &:hover {
    opacity: 0.7;
  }
`;

const Icon = styled.img``;

const scrollTo = (id: string) => {
  const scroller = document.getElementById('details-slide-scroll-area');

  const summaryElem = document.getElementById('details-slide-summary');
  const timelineElem = document.getElementById('details-slide-timeline');
  const commentsElem = document.getElementById('details-slide-comments');

  if (!scroller || !summaryElem || !timelineElem || !commentsElem) {
    return;
  }

  if (id === 'details-slide-summary') {
    scroller.scrollTop = 0;
  } else if (id === 'details-slide-timeline') {
    scroller.scrollTop = summaryElem.clientHeight;
  } else if (id === 'details-slide-comments') {
    scroller.scrollTop = summaryElem.clientHeight + timelineElem.clientHeight;
  }
};

const DetailsSlideOutNav = ({
  activeNavElement,
}: {
  activeNavElement: SlideOutNavElement;
}) => (
  <Wrapper>
    <Button
      isActive={'summary' === activeNavElement}
      onClick={() => scrollTo('details-slide-summary')}
    >
      <Icon src={SummaryIcon} />
    </Button>

    <Button
      isActive={'timeline' === activeNavElement}
      onClick={() => scrollTo('details-slide-timeline')}
    >
      <Icon src={TruckIcon} />
    </Button>
    <Button
      isActive={'comments' === activeNavElement}
      onClick={() => scrollTo('details-slide-comments')}
    >
      <Icon src={CommentsIcon} />
    </Button>
  </Wrapper>
);

export default DetailsSlideOutNav;
