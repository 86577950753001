import UNIT_LOCALES from '../../propTypes/unitLocales';
import captureException from '../../services/captureException';

const buildFix = ({
  fixType,
  unitsLocale = UNIT_LOCALES.METRIC,
  currencyType,
}: {
  fixType?: PreOrPostFix;
  unitsLocale?: string;
  currencyType?: CurrencyType;
}) => {
  if (fixType === undefined) {
    return '';
  }

  try {
    switch (fixType) {
      case 'currency':
        return unitsLocale === UNIT_LOCALES.METRIC
          ? '€'
          : currencyType === 'CAD'
            ? 'C$'
            : '$';
      case 'distance':
        return unitsLocale === UNIT_LOCALES.METRIC ? 'km' : 'mi';
      case 'weight':
        return unitsLocale === UNIT_LOCALES.METRIC ? 'kg' : 'lbs';
      case 'temperature':
        return unitsLocale === UNIT_LOCALES.METRIC ? 'c' : 'f';
      case 'percentage':
        return '%';
      case 'fuelEfficiency':
        return unitsLocale === UNIT_LOCALES.METRIC ? 'L/100kms' : 'M/G';
      case 'duration':
        return '';
      default:
        if (fixType) {
          captureException(new Error(`Unknown fixType: ${fixType}`));
        }
        return '';
    }
  } catch (ex) {
    captureException(ex);
    return '';
  }
};

export default buildFix;
