import formatFloat from '../../api/getChartSeries/formatFloat';
import moment from 'moment';

const formatDateLabel = (date: Date) => moment.utc(date).format('LL');
const formatDateLabelShort = (date: Date) =>
  moment.utc(date).format('MMM YYYY');

export const buildTrendCardItem = (
  label: string,
  value: number,
  date: string,
  compareValue?: number,
  precision?: number,
) => ({
  label,
  value: formatFloat(value, precision),
  date,
  dateLabel: formatDateLabelShort(new Date(date)),
  dateLabelTooltip: formatDateLabel(new Date(date)),
  delta: compareValue
    ? formatFloat(((compareValue - value) / value) * 100)
    : undefined,
});

export const getItemFor = ({
  offset,
  label,
  data,
  precision,
  metricId,
}: {
  offset: number;
  label: string;
  data: { [key: string]: number | string | FilterInput }[];
  precision?: number;
  metricId: string;
}) => {
  try {
    const item = data[data.length - offset - 2];
    const compareTo = (() => {
      if (offset > 0) {
        return data[data.length - 2][metricId] as number;
      }
      return undefined;
    })();

    return buildTrendCardItem(
      label,
      item[metricId] as number,
      item.date as string,
      compareTo,
      precision,
    );
  } catch (ex) {
    return undefined;
  }
};
