import React from 'react';
import usePopup from 'hooks/usePopup';
import Button from 'kingpin/atoms/Button';
import ErrorBoundary from 'components/Common/ErrorBoundary';
import ReactPortal from 'components/ReactPortal';
import Card from 'components/Common/Card';

import DatasetTrend from './DatasetTrend';
import DATASET_TREND_BUTTON from './constants';

const DatasetTrendButtonContainer = ({ dataset }: { dataset: string }) => {
  const { isOpen, open, close } = usePopup();

  return (
    <>
      <Button
        type="Secondary"
        size="Small"
        onClick={isOpen ? close : open}
        icon={'chart-outlined'}
        testId="search-trend-button"
      />
      {isOpen && (
        <ReactPortal elementId={DATASET_TREND_BUTTON.TREND_DIV_ID}>
          <Card
            style={{
              marginBottom: 24,
              padding: 8,
            }}
          >
            <ErrorBoundary>
              <DatasetTrend dataset={dataset} />
            </ErrorBoundary>
          </Card>
        </ReactPortal>
      )}
    </>
  );
};

export default DatasetTrendButtonContainer;
