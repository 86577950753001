import moment from 'moment';

const getNumberIntervals = (goal: GeneralGoal) => {
  const { fixedStartDate, fixedEndDate } = goal;
  const startMoment = moment.utc(fixedStartDate);
  const endMoment = moment.utc(fixedEndDate);
  return Math.abs(startMoment.diff(endMoment, 'day')) + 1;
};

export default getNumberIntervals;
