import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import CopyToClipboard from 'react-copy-to-clipboard';
import MetricPickerPopup from '../../../../../../components/Inputs/MetricPicker/MetricPickerPopup';
import { Label } from '../../../../../../components/MyAccount/Profile/styles';
import Row from '../../../../../../components/Common/Row';
import Typography from 'kingpin/atoms/Typography';
import Button from 'kingpin/atoms/Button';
import MetricEditor from '../../../../../../components/Inputs/MetricPicker/MetricEditor';
import usePopup from '../../../../../../hooks/usePopup';

interface CellProps {
  flex: number;
}
const Cell = styled.div<CellProps>`
  display: flex;
  flex: ${(props) => props.flex};
  align-items: center;
`;

const InputMetrics = ({
  normalMetrics,
  selectedMetricIds,
  onAddMetric,
  onRemoveMetric,
  onOpened,
}: {
  normalMetrics: Metrics.NormalMetric[];
  selectedMetricIds: string[];
  onAddMetric: (mId: string) => void;
  onRemoveMetric: (mId: string) => void;
  onOpened?: () => void;
}) => {
  const { isOpen, open: openPopup, close } = usePopup();
  const [selectedMetric, setSelectedMetric] = useState<
    Metrics.NormalMetric | undefined
  >();
  const {
    isOpen: isMetricEditorOpen,
    open: openMetricEditor,
    close: closeMetricEditor,
  } = usePopup();
  const onEditMetricClicked = useCallback(
    (metric: Metrics.NormalMetric) => {
      setSelectedMetric(metric);
      openMetricEditor();
    },
    [openMetricEditor],
  );

  useEffect(() => {
    if (!isMetricEditorOpen) {
      setSelectedMetric(undefined);
    }
  }, [isMetricEditorOpen]);

  const open = useCallback(() => {
    openPopup();
    if (onOpened) {
      onOpened();
    }
  }, [onOpened, openPopup]);

  return (
    <React.Fragment>
      <MetricPickerPopup
        isOpen={isOpen}
        close={close}
        noCompound
        setSelectedMetric={(m) => {
          onAddMetric(m.id);
          close();
        }}
        noSpecial
      />
      <Label>Selected metrics</Label>
      {selectedMetricIds.length > 0 && (
        <Row style={{ marginBottom: 4 }}>
          <Cell flex={1}>Name</Cell>
          <Cell flex={3}>Id</Cell>
          <Cell flex={1} />
        </Row>
      )}

      {normalMetrics
        .filter((m) => selectedMetricIds.includes(m.id))
        .map((m) => (
          <Row style={{ marginBottom: 4 }} key={`s-${m.id}`} spaceBetween>
            <Row style={{ flex: 4 }}>
              <Cell flex={1}>
                <Typography.Body
                  type="Link"
                  onClick={() => onEditMetricClicked(m)}
                >
                  {m.name}
                </Typography.Body>
              </Cell>
              <Cell flex={3}>
                <Typography.Body type="Body 12" isEllipsis>
                  {m.id}
                </Typography.Body>
              </Cell>
            </Row>
            <Row style={{ flex: 1, justifyContent: 'flex-end' }}>
              <div
                onMouseDown={(e) => {
                  e.preventDefault();
                }}
              >
                <CopyToClipboard text={m.id}>
                  <Button type="Ghost" size="Small" icon="copy" />
                </CopyToClipboard>
              </div>
              <div style={{ marginLeft: 8 }}>
                <Button
                  type="Ghost"
                  size="Small"
                  icon="cross"
                  onClick={() => onRemoveMetric(m.id)}
                />
              </div>
            </Row>
          </Row>
        ))}
      <div style={{ display: 'inline-block' }}>
        <Button
          type="Tertiary"
          size="Small"
          onClick={isOpen ? close : open}
          label="Add Metric"
        />
      </div>
      <MetricEditor
        isOpen={isMetricEditorOpen}
        close={closeMetricEditor}
        selectedMetric={selectedMetric}
        singleUseEditCancelButtonText="Cancel"
      />
    </React.Fragment>
  );
};

export default InputMetrics;
