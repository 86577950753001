import React, { MutableRefObject } from 'react';
import Spinner from 'components/AtlassianTemp/Spinner';
import Row from '../../../../../Common/Row';
import getColor from '../../getColor';
import Column from '../../Column';
import Loading from '../../../../../Loading';
import NoDataToDisplay from '../../../../../V5Gadget/NoDataToDisplay';
import Flexed from '../../Flexed';
import { FlexedWrapper } from '../ProgressBar/ProgressBar';
import Typography from 'kingpin/atoms/Typography';
import FlexCentered from '../../../../../Common/FlexCentered';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Icon from '../../../../../../kingpin/atoms/Icon';
import Colors2 from '../../../../../../theme/Colors2';

const AreaChart = ({
  isCard,
  options,
  chartRef,
  isLoading,
  currentValue,
  performanceText,
  aboveTargetIsGood,
  isAboveTarget,
  remainingIntervals,
  noPadding,
  noData,
}: {
  isCard?: boolean;
  options: any;
  chartRef: MutableRefObject<any>;
  isLoading: boolean;
  currentValue: string;
  performanceText: string;
  aboveTargetIsGood: boolean;
  isAboveTarget: boolean;
  remainingIntervals: string;
  noPadding?: boolean;
  noData?: boolean;
}) => (
  <React.Fragment>
    {isCard && (
      <Row centerAlign style={{ marginBottom: 16 }}>
        <div style={{ marginRight: 8 }}>
          <Typography.Header type="H5">Goal Progress</Typography.Header>
        </div>
        <Typography.Header type={'H5'} color={Colors2.Grey['5']}>
          {`(${remainingIntervals})`}
        </Typography.Header>
      </Row>
    )}
    {isLoading && (
      <FlexCentered
        style={{ marginTop: isCard ? 32 : 0, height: 'calc(100% - 38px)' }}
      >
        <Flexed flex={7}>
          <Loading isSmall />
        </Flexed>
      </FlexCentered>
    )}
    {!isLoading && (
      <FlexCentered
        style={{
          flex: 7,
          height: 'calc(100% - 38px)',
          width: '100%',
          flexDirection: isCard ? 'column' : 'row',
        }}
      >
        {noData && (
          <React.Fragment>
            <FlexCentered style={{ height: '100%', marginTop: 32 }}>
              <Flexed flex={3} style={noPadding ? { padding: 0 } : undefined}>
                <Column>
                  <NoDataToDisplay size={'tiny'} />
                </Column>
              </Flexed>
              <Flexed flex={4} style={noPadding ? { padding: 0 } : undefined} />
            </FlexCentered>
          </React.Fragment>
        )}
        {!noData && (
          <React.Fragment>
            <FlexedWrapper flex={3} noPadding={noPadding} isCard={isCard}>
              <Column style={{ width: '100%', alignItems: 'center' }}>
                <>
                  {currentValue !== undefined && (
                    <Typography.Body type={'Body 14'}>
                      {currentValue}
                    </Typography.Body>
                  )}
                  {currentValue === undefined && <Spinner />}
                </>
              </Column>
              <Column style={{ width: '100%', alignItems: 'center' }}>
                <Row centerAlign>
                  {isAboveTarget && (
                    <Icon
                      icon="arrow-up"
                      color={getColor(isAboveTarget, aboveTargetIsGood)}
                    />
                  )}
                  {!isAboveTarget && (
                    <Icon
                      icon="arrow-down"
                      color={getColor(isAboveTarget, aboveTargetIsGood)}
                    />
                  )}
                  <Typography.Header
                    type="H5"
                    color={getColor(isAboveTarget, aboveTargetIsGood)}
                  >
                    {performanceText}
                  </Typography.Header>
                </Row>
              </Column>
            </FlexedWrapper>
            <FlexedWrapper flex={4} noPadding={noPadding} isCard={isCard}>
              <div
                style={{
                  position: 'relative',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Column>
                  <HighchartsReact
                    ref={(c) => (chartRef.current = c)}
                    highcharts={Highcharts}
                    options={options}
                    containerProps={{
                      style: {
                        width: '100%',
                        height: '100%',
                      },
                    }}
                  />
                </Column>
                {!isCard && (
                  <>
                    <Column>
                      <Typography.Header type={'H5'} color={Colors2.Grey['5']}>
                        {remainingIntervals}
                      </Typography.Header>
                    </Column>
                  </>
                )}
              </div>
            </FlexedWrapper>
          </React.Fragment>
        )}
      </FlexCentered>
    )}
  </React.Fragment>
);

export default AreaChart;
