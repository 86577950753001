import isMetric from './isMetric';
import isNormalMetric from './isNormalMetric';
import isCompoundMetric from './isCompoundMetric';
import isSpecialMetric from './isSpecialMetric';
import isSingleUseMetric from './isSingleUseMetric';
import isNotCompoundMetric from './isNotCompoundMetric';
import isNotSpecialMetric from './isNotSpecialMetric';

const metricTypeCheckers = {
  isMetric,
  isNormalMetric,
  isCompoundMetric,
  isSpecialMetric,
  isNotSpecialMetric,
  isSingleUseMetric,
  isNotCompoundMetric,
};

export default metricTypeCheckers;
