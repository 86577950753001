import React, { useCallback, useEffect, useState } from 'react';
import Inputs from 'components/Inputs';
import { PaceMatrixFormTypes } from './types';
import LabeledSection from './LabeledSection';
import toSentenceCase from '../../../services/toSentenceCase';

const SortOrderInput = ({
  isCardMode,
  sortOrder,
  setSortOrder,
}: {
  isCardMode: boolean;
  sortOrder: PaceMatrixFormTypes.ISortOrder;
  setSortOrder: React.Dispatch<
    React.SetStateAction<PaceMatrixFormTypes.ISortOrder>
  >;
}) => {
  const getByOptions = useCallback((): RadioOption[] => {
    return [
      'Term' as 'Term',
      'Total' as 'Total',
      'Total Chg' as 'Total Chg',
      'Pace' as 'Pace',
      'Pace Chg' as 'Pace Chg',
    ].map((by) => ({
      key: by,
      label: by,
      isSelected: sortOrder.by === by,
      onSelected: () => {
        setSortOrder((o) => ({ ...o, by }));
      },
    }));
  }, [setSortOrder, sortOrder.by]);
  const getDirectionOptions = useCallback((): RadioOption[] => {
    return ['asc' as 'asc', 'desc' as 'desc'].map((direction) => ({
      key: direction,
      label: toSentenceCase(direction),
      isSelected: sortOrder.direction === direction,
      onSelected: () => {
        setSortOrder((o) => ({ ...o, direction }));
      },
    }));
  }, [setSortOrder, sortOrder.direction]);

  const [byOptions, setByOptions] = useState<RadioOption[]>(getByOptions());
  const [directionOptions, setDirectionOptions] =
    useState<RadioOption[]>(getByOptions());

  useEffect(() => {
    setByOptions(getByOptions());
  }, [getByOptions]);
  useEffect(() => {
    setDirectionOptions(getDirectionOptions());
  }, [getDirectionOptions]);

  if (isCardMode) {
    return null;
  }

  return (
    <>
      <LabeledSection label="Sort By">
        <Inputs.Dropdown options={byOptions} />
      </LabeledSection>
      <LabeledSection label="Sort Direction">
        <Inputs.Radio options={directionOptions} />
      </LabeledSection>
    </>
  );
};

export default SortOrderInput;
