import React from 'react';
import styled from 'styled-components';
import Row from 'components/Common/Row';
import { formatDateCell } from 'components/DateCell';
import Typography from 'kingpin/atoms/Typography';
import useGetFieldLabel from 'hooks/useGetFieldLabel';
import useValueFormatters from 'hooks/useValueFormatters';
import Loading from 'components/Loading/Loading';
import Card from 'components/Common/Card';
import useGridDocs from '../useGridDocs';
import { HEADER_SPAN_CSS, SPAN_CSS } from '../../../../Grid/StyleOverrides';

const Col = styled.div<{ isHeader?: boolean }>`
  flex: 1;
  width: 72px;
  flex-direction: column;
  margin-right: 16px;
  text-align: left;
  flex-wrap: wrap;

  word-wrap: break-word;

  span {
    ${(props) => (props.isHeader ? HEADER_SPAN_CSS : SPAN_CSS)}
  }
`;

const GridSlideoutPreview = ({
  dataType,
  filters,
  dateScope,
  fields,
  noMargin,
}: {
  dataType: string;
  filters: FilterInput;
  dateScope: DateRangeInput;
  fields: string[];
  noMargin?: boolean;
}) => {
  const { formatField } = useValueFormatters();
  const { getFieldLabel } = useGetFieldLabel();
  const { docs, isMoreDocsAvailable, isLoading } = useGridDocs({
    dataType,
    filters,
    dateScope,
  });

  return (
    <div
      style={{ marginBottom: noMargin ? undefined : '24px' }}
      data-testid={'grid-preview'}
    >
      {isLoading && (
        <div style={{ height: '100px', display: 'flex' }}>
          <Loading isSmall />
        </div>
      )}
      {!isLoading && docs.length !== 0 && (
        <Card
          style={{
            maxHeight: 300,
            overflow: 'scroll',
            padding: '8px',
            paddingTop: 0,
          }}
        >
          <Row
            centerAlign
            style={{
              padding: '8px',
              boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.12)',
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
              minWidth: 'fit-content',
            }}
          >
            <Col isHeader>
              <span>Date</span>
            </Col>
            {fields.map((field) => (
              <Col key={field} isHeader>
                <span>{getFieldLabel({ field, dataType })}</span>
              </Col>
            ))}
          </Row>
          {docs &&
            docs.map((doc) => (
              <Row
                key={doc['id']}
                centerAlign
                style={{
                  boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.08)',
                  padding: '4px',
                  minHeight: 32,
                  minWidth: 'fit-content',
                }}
              >
                <Col>
                  <Typography.Body type="Body 12">
                    {formatDateCell(doc['date'])}
                  </Typography.Body>
                </Col>
                {fields.map((field) => (
                  <Col key={field}>
                    <span>
                      {formatField({
                        value: doc[field],
                        field,
                        dataset: dataType,
                      })}
                    </span>
                  </Col>
                ))}
              </Row>
            ))}
        </Card>
      )}
      {!isLoading && docs.length === 0 && (
        <div style={{ marginTop: 8 }}>
          <Typography.Body type="Label">
            There is no data for the chosen fields
          </Typography.Body>
        </div>
      )}
      {isMoreDocsAvailable && (
        <div style={{ marginTop: 8 }}>
          <Typography.Body type="Label">More Data Is Available</Typography.Body>
        </div>
      )}
    </div>
  );
};

export default GridSlideoutPreview;
