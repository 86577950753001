import React, { useCallback, useContext, useState } from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';

import KpiRowLeft from './KpiRowLeft';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import ScorecardContext from '../../../../contexts/ScorecardContext';
import AnalyticsContext from '../../../../contexts/AnalyticsContext';
import LocalTimelineContext from '../../../../contexts/Timeline/LocalTimelineContext';
import { ScoringBandColors } from '../../../../screens/ScorecardsIndex/constants';
import useMetric from '../../../../hooks/useMetric';
import usePopup from '../../../../hooks/usePopup';
import ConfirmationModal from '../../../ConfirmationModal';
import ScorecardKpiForm from '../ScorecardKpiForm';
import ScorecardUsageWarning from '../../ScorecardUsageWarning';
import Typography from 'kingpin/atoms/Typography';

const DeleteKpiConfirmationBody = ({
  kpi,
}: {
  kpi: Scorecards.ScorecardKpi;
}) => {
  const { scorecard } = useContext(ScorecardContext);
  const metric = useMetric(kpi.metricId);

  const kpiName = kpi.displayName ? kpi.displayName : metric ? metric.name : '';
  return (
    <div>
      <ScorecardUsageWarning scorecard={scorecard} />
      <Typography.Body type="Body 12">
        {`Are you sure you want to delete the ${kpiName} KPI?`}
      </Typography.Body>
    </div>
  );
};

const KpiRowLeftContainer = ({
  kpi,
  dragHandleProps,
  isLastRow,
}: {
  kpi: Scorecards.ScorecardKpi;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
  isLastRow?: boolean;
}) => {
  const { scorecard, updateScorecard, setHoveredKpiId, hoveredKpiId } =
    useContext(ScorecardContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const { addEvent } = useContext(LocalTimelineContext);
  const [numColors] = useState<number>(
    () => ScoringBandColors[scorecard.scoringBandId].length,
  );
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const metric = useMetric(kpi.metricId);
  const { isOpen: isEditOpen, open: openEdit, close: closeEdit } = usePopup();
  const {
    isOpen: isCloseConfirmationOpen,
    open: openCloseConfirmation,
    close: closeCloseConfirmation,
  } = usePopup();
  const {
    isOpen: isConfirmDeleteOpen,
    open: openConfirmDelete,
    close: closeConfirmDelete,
  } = usePopup();

  const onCloseClicked = useCallback(() => {
    if (hasUnsavedChanges) {
      openCloseConfirmation();
      return;
    }

    closeEdit();
  }, [closeEdit, hasUnsavedChanges, openCloseConfirmation]);

  const onCloseConfirmed = useCallback(() => {
    closeCloseConfirmation();
    closeEdit();
    setHasUnsavedChanges(false);
  }, [closeEdit, closeCloseConfirmation]);

  const onDeleteConfirmed = useCallback(() => {
    const newKpis = scorecard.kpis.filter((k) => k.id !== kpi.id);
    const newScorecard = {
      ...scorecard,
      kpis: newKpis,
    };

    updateScorecard(newScorecard).then(async () => {
      if (addEvent) {
        await addEvent({
          actionText: 'removed KPI',
          contextText: `${
            kpi.displayName ? kpi.displayName : metric ? metric.name : ''
          }`,
        });
      }

      trackEvent('KPI List - KPI - Deleted', {
        scorecardName: scorecard.title,
        scorecardId: scorecard.id,
      });
    });
  }, [
    addEvent,
    kpi.displayName,
    kpi.id,
    metric,
    scorecard,
    trackEvent,
    updateScorecard,
  ]);

  const onDeleteClicked = useCallback(() => {
    openConfirmDelete();
  }, [openConfirmDelete]);

  const onEditClicked = useCallback(() => {
    trackEvent('KPI List - Edit KPI Clicked', {
      scorecardName: scorecard.title,
      scorecardId: scorecard.id,
    });
    openEdit();
  }, [openEdit, scorecard.id, scorecard.title, trackEvent]);

  const onRowHover = useCallback(() => {
    setHoveredKpiId(kpi.id);
  }, [kpi.id, setHoveredKpiId]);

  return (
    <>
      <KpiRowLeft
        dragHandleProps={dragHandleProps}
        kpi={kpi}
        onEditClicked={onEditClicked}
        onDeleteClicked={onDeleteClicked}
        isLastRow={isLastRow}
        onRowHover={onRowHover}
        isHovered={hoveredKpiId === kpi.id}
      />
      <ConfirmationModal
        isOpen={isConfirmDeleteOpen}
        close={closeConfirmDelete}
        title={'Delete KPI?'}
        bodyComponent={<DeleteKpiConfirmationBody kpi={kpi} />}
        confirmText={'Delete'}
        onConfirmed={onDeleteConfirmed}
      />

      <ModalTransition>
        {isEditOpen && (
          <Modal
            shouldScrollInViewport={false}
            autoFocus={false}
            width={Math.max(600, numColors * 150)}
          >
            <ScorecardKpiForm
              close={onCloseClicked}
              kpi={kpi}
              setHasUnsavedChanges={setHasUnsavedChanges}
              isCloseConfirmationOpen={isCloseConfirmationOpen}
              onCloseConfirmed={onCloseConfirmed}
              onCloseConfirmationClosed={closeCloseConfirmation}
            />
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};

export default KpiRowLeftContainer;
