import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import { Label } from 'components/MyAccount/Profile/styles';
import Row from '../Common/Row';
import Button from 'kingpin/atoms/Button';
import TextArea from '../Inputs/TextArea';

const ModalWrapper = styled.div`
  padding: 8px 16px;
`;

const IFrameForm = ({
  snippet,
  onSnippetChanged,
  onSave,
  close,
  isOpen,
}: {
  snippet: string;
  onSnippetChanged: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onSave: () => void;
  close: () => void;
  isOpen: boolean;
}) => (
  <ModalTransition>
    {isOpen && (
      <Modal onClose={close} shouldScrollInViewport={false} autoFocus={false}>
        <ModalWrapper>
          <Label>Snippet</Label>
          <TextArea
            onChange={onSnippetChanged}
            value={snippet}
            style={{ minHeight: 200 }}
          />
          <Row spaceBetween centerAlign style={{ marginTop: 12 }}>
            <Button onClick={close} type="Ghost" size="Small" label="Cancel" />
            <Button onClick={onSave} type="Primary" size="Small" label="Save" />
          </Row>
        </ModalWrapper>
      </Modal>
    )}
  </ModalTransition>
);

export default IFrameForm;
