import React, { useContext } from 'react';
import styled from 'styled-components';

import ImpersonatorContext from 'contexts/ImpersonatorContext';
import Typography from 'kingpin/atoms/Typography';
import Button from 'kingpin/atoms/Button';

import useUser from 'hooks/useUser';
import getUserDisplayName from '../../contextProviders/UsersProvider/getUserDisplayName';
import Row from '../Common/Row';
import Tooltip from '../Tooltip';
import Icon from '../../kingpin/atoms/Icon';
import CurrentUserContext from '../../contexts/CurrentUserContext';
import Colors2 from '../../theme/Colors2';

export const ImpersonationBannerWrap = styled.div<{ isOpen: boolean }>`
  background-color: gold;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${(props) => (props.isOpen ? '90%' : '40px')};
  margin: 16px auto;
  border-radius: 8px;
`;

const WarningIcon = () => {
  const { displayName: currentUserName } = useContext(CurrentUserContext);

  return (
    <div style={{ display: 'flex', marginRight: 8 }}>
      <Tooltip
        content={
          <div>
            <div style={{ marginBottom: 4 }}>
              <Typography.Body type="Body 14" color="white">
                Please avoid modifying content while in this mode.
              </Typography.Body>
            </div>
            <div>
              <Typography.Body type="Body 14" color="white">
                {`Any action taken will be recorded as being performed by ${currentUserName}.`}
              </Typography.Body>
            </div>
          </div>
        }
        isFlexAnchor
      >
        <Icon
          icon={'warning'}
          color={Colors2.Secondary.error}
          height={24}
          width={24}
        />
      </Tooltip>
    </div>
  );
};

const ImpersonatorBanner = ({ isOpen }: { isOpen: boolean }) => {
  const { impersonatorId, setImpersonatorId } = useContext(ImpersonatorContext);
  const { user } = useUser(impersonatorId);

  if (!impersonatorId) {
    return null;
  }

  return (
    <ImpersonationBannerWrap isOpen={isOpen}>
      {isOpen && (
        <>
          <div>
            <div>
              <Row centerAlign>
                <WarningIcon />
                <Typography.Header type="H5">
                  {`Viewing app as:`}
                </Typography.Header>
              </Row>
            </div>
            <div style={{ width: 160 }}>
              <Typography.Header type="H5" isEllipsis>
                {user && getUserDisplayName(user)}
              </Typography.Header>
            </div>
          </div>
        </>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
        }}
      >
        <Button
          testId="close"
          icon={isOpen ? 'cross' : 'eye'}
          onClick={() => {
            setImpersonatorId(undefined);
          }}
          type="Ghost"
          size="Small"
        />
      </div>
    </ImpersonationBannerWrap>
  );
};

export default ImpersonatorBanner;
