import React, { useContext } from 'react';
import { ModalTransition } from '@atlaskit/modal-dialog';
import Row from '../../Common/Row';
import Typography from 'kingpin/atoms/Typography';
import Inputs from '../../Inputs';
import useTargetDateOptions from '../hooks/useTargetDateOptions';
import TargetFormContext from '../contexts/TargetFormContext';

const EffectiveDateInput = ({
  dataType,
  effectiveDate,
  setEffectiveDate,
}: {
  dataType: string;
  effectiveDate?: string;
  setEffectiveDate: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
  const { isEditing, isShowingValidationFeedback } =
    useContext(TargetFormContext);
  const {
    dateOptions,
    DangerConfirmation,
    cadenceLabel,
    defaultScrollToLabel,
  } = useTargetDateOptions({
    dataType,
    effectiveDate,
    setEffectiveDate,
  });

  return (
    <>
      <Row centerAlign style={{ marginRight: 16 }}>
        <div style={{ marginRight: 16 }}>
          <Typography.Body type="Body 12">Start From</Typography.Body>
        </div>
        <Inputs.Dropdown
          placeholder={`Select Start ${cadenceLabel}`}
          options={dateOptions}
          isDisabled={isEditing}
          testId={
            dateOptions.length === 0 || isEditing
              ? undefined
              : 'effective-date-dropdown'
          }
          defaultScrollToLabel={defaultScrollToLabel}
          hasError={isShowingValidationFeedback && effectiveDate === undefined}
        />
      </Row>
      <ModalTransition>{DangerConfirmation}</ModalTransition>
    </>
  );
};

export default EffectiveDateInput;
