import React from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import ContextMenu, { Option, Break } from 'kingpin/atoms/ContextMenu';

import Row from '../../../Common/Row';
import ShowOnMouseOverUnlessTour from '../../../ShowOnMouseOverUnlessTour';
import Button from 'kingpin/atoms/Button';
import Typography from 'kingpin/atoms/Typography';
import KpiRowElement from '../KpiRowElement';
import { Col } from '../Kpis';
import PermissionGates from '../../../PermissionGates';
import PERMISSIONS from '../../../../permissionDefinitions';
import Colors2 from '../../../../theme/Colors2';

const CONTEXT_MENU_PERMISSIONS = [
  PERMISSIONS.SCORECARDS.CRUD_SCORECARD,
  PERMISSIONS.SCORECARDS.MANUAL_KPI_ENTRY,
];

const ManualKpiRowLeft = ({
  dragHandleProps,
  kpi,
  onEditClicked,
  onEnterValuesClicked,
  onDeleteClicked,
  isLastRow,
  manualKpiIdBeingEdited,
  isHighlighted,
  isHovered,
  onRowHover,
}: {
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
  kpi: Scorecards.ManualKpiRow;
  onEditClicked: () => void;
  onEnterValuesClicked: () => void;
  onDeleteClicked: () => void;
  isLastRow?: boolean;
  manualKpiIdBeingEdited: string | undefined;
  isHighlighted: boolean;
  isHovered: boolean;
  onRowHover: () => void;
}) => (
  <KpiRowElement
    centerAlign
    style={{
      width: '100%',
      borderBottom: `1px solid ${Colors2.Border}`,
    }}
    isLastRow={isLastRow}
    isExtended={manualKpiIdBeingEdited === kpi.id}
    isHighlighted={isHighlighted}
    isHovered={isHovered}
    onMouseEnter={onRowHover}
  >
    <Col maxWidth style={{ flexDirection: 'column' }}>
      <Row centerAlign spaceBetween style={{ width: '100%' }}>
        <Row centerAlign style={{ width: 'calc(100% - 24px)' }}>
          {!!dragHandleProps && (
            <div>
              <ShowOnMouseOverUnlessTour isForcedVisible={isHovered}>
                <div {...dragHandleProps}>
                  <Button type="Ghost" size="Small" icon="drag" />
                </div>
              </ShowOnMouseOverUnlessTour>
            </div>
          )}
          <Row
            style={{
              marginLeft: dragHandleProps ? undefined : '8px',
              width: '100%',
            }}
          >
            <div
              style={{
                maxWidth: `calc(100% - ${isHovered ? '88px' : '16px'})`,
                overflow: 'hidden',
              }}
            >
              <Typography.Body type="Body 12" isEllipsis>
                {kpi.label}
              </Typography.Body>
            </div>

            <div style={{ width: isHovered ? '72px' : 0 }}>
              <ShowOnMouseOverUnlessTour isForcedVisible={isHovered}>
                <div style={{ margin: '0 8px' }}>
                  <Typography.Body
                    type="Body 12"
                    isEllipsis
                    color={Colors2.Grey['5']}
                  >
                    (Manual)
                  </Typography.Body>
                </div>
              </ShowOnMouseOverUnlessTour>
            </div>
          </Row>
        </Row>
        <div style={{ marginRight: 4, width: '24px' }}>
          <PermissionGates.HasAny
            requiredPermissions={CONTEXT_MENU_PERMISSIONS}
          >
            <ShowOnMouseOverUnlessTour isForcedVisible={isHovered}>
              <ContextMenu
                testId={`manual-kpi-edit-${kpi.label}`}
                buttonType="Ghost"
              >
                <PermissionGates.Has
                  requiredPermission={PERMISSIONS.SCORECARDS.CRUD_SCORECARD}
                >
                  <Option onClick={onEditClicked} label="Edit KPI" />
                </PermissionGates.Has>

                <PermissionGates.Has
                  requiredPermission={PERMISSIONS.SCORECARDS.MANUAL_KPI_ENTRY}
                >
                  <Option
                    onClick={
                      manualKpiIdBeingEdited ? undefined : onEnterValuesClicked
                    }
                    isDisabled={!!manualKpiIdBeingEdited}
                    label="Enter Values"
                  />
                </PermissionGates.Has>
                <PermissionGates.Has
                  requiredPermission={PERMISSIONS.SCORECARDS.CRUD_SCORECARD}
                >
                  <Break />
                  <Option onClick={onDeleteClicked} isDanger label="Delete" />
                </PermissionGates.Has>
              </ContextMenu>
            </ShowOnMouseOverUnlessTour>
          </PermissionGates.HasAny>
        </div>
      </Row>
      {manualKpiIdBeingEdited === kpi.id && (
        <Row style={{ height: '46px' }}></Row>
      )}
    </Col>
  </KpiRowElement>
);
export default ManualKpiRowLeft;
