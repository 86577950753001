import React, { ChangeEvent } from 'react';
import Colors from 'theme/colors';
import styled from 'styled-components';
import Button from 'kingpin/atoms/Button';

import InlineDialog from '../../InlineDialog';
import Row from '../../Common/Row';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import MentionSuggestions from '../MentionSuggestions';
import colors from 'theme/colors';
import Typography from 'kingpin/atoms/Typography';
import Loading from '../../Loading/Loading';

export const COMMENT_INPUT_ID = 'comment-input';

export const CommentBox = styled.div<{ isFocused: boolean }>`
  width: 100%;
  margin: 3px;
  min-height: 100px;
  position: relative;
  background-color: ${Colors.WHITE};
  border-radius: 2px;
  padding-bottom: 1em;
  margin-bottom: 11px;
  border: ${(props) =>
    props.isFocused
      ? `1px solid ${Colors.NAVY_BLUE}`
      : '1px solid transparent'};
  box-shadow:
    0 1px 2px -1px rgba(9, 45, 66, 0.25),
    0 0 0 1px rgba(9, 45, 66, 0.08);

  div[contenteditable='true'] {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    color: #252525;
    padding: 9px 9px;

    &:empty:before {
      content: attr(placeholder);
      display: block;
      color: #aaa;
    }

    &:focus {
      outline: none !important;
    }
  }
`;

const CommentInputActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  bottom: 0px;
  right: 11px;
`;

const CreateComment = ({
  commentId,
  createComment,
  isFocused,
  onKeyDown,
  onChange,
  onFocus,
  onBlur,
  commentHtml,
  onMentionButtonClick,
  onSuggestionSelected,
  mentionInput,
  isSuggestionsOpen,
  closeSuggestions,
  cancelComment,
  isCreatingComment,
  disableBottomBorder,
  attachedFiles,
  isUploadingFiles,
  onFilesChange,
  onFileDeleted,
  sendButtonText,
}: {
  commentId: string;
  createComment: () => void;
  isFocused: boolean;
  onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  onChange: (event: ContentEditableEvent) => void;
  onFocus: () => void;
  onBlur: () => void;
  commentHtml: string;
  onMentionButtonClick: () => void;
  onSuggestionSelected: (
    u:
      | UserManagement.SignedUpUser
      | UserManagement.FleetOpsStaffUser
      | UserManagement.PendingUser,
  ) => void;
  mentionInput: string;
  isSuggestionsOpen: boolean;
  closeSuggestions: () => void;
  cancelComment: () => void;
  isCreatingComment: boolean;
  attachedFiles: {
    fileName: string;
    downloadUrl?: string;
    isUploading: boolean;
  }[];
  disableBottomBorder?: boolean;
  isUploadingFiles?: boolean;
  onFilesChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onFileDeleted: (fileName: string) => void;
  sendButtonText?: string;
}) => (
  <div
    style={{
      position: 'sticky',
      top: 0,
      backgroundColor: 'white',
      paddingBottom: 12,
      marginBottom: 12,
      borderBottom: disableBottomBorder
        ? undefined
        : `1px solid ${colors.ZIRCON}`,
    }}
  >
    <Row spaceBetween>
      <InlineDialog
        key={mentionInput}
        content={
          <MentionSuggestions
            mentionInput={mentionInput}
            close={closeSuggestions}
            onSuggestionSelected={onSuggestionSelected}
          />
        }
        isOpen={isSuggestionsOpen}
        onClose={closeSuggestions}
        placement="top"
      >
        <div />
      </InlineDialog>

      <CommentBox isFocused={isFocused}>
        <ContentEditable
          id={COMMENT_INPUT_ID}
          data-testid={'comment-input'}
          style={{ height: '100%' }}
          // @ts-ignore
          placeholder="Add a note, @name to mention..."
          dir="auto"
          onKeyDown={onKeyDown}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onChange}
          html={commentHtml}
        />
        <CommentInputActions>
          <label htmlFor={`comment-${commentId}-files`}>
            <Button type="Ghost" size="Small" icon="note-stack-add" />
          </label>
          <input
            data-testid={'comment-file-upload'}
            id={`comment-${commentId}-files`}
            type={'file'}
            multiple
            style={{ display: 'none' }}
            onChange={onFilesChange}
            accept={
              '.csv, text/plain, image/*, .pdf,' +
              ' application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
            }
          />

          <Button
            size={'Small'}
            type={'Ghost'}
            icon="mention"
            onClick={onMentionButtonClick}
          />
        </CommentInputActions>
      </CommentBox>
    </Row>
    {attachedFiles.length > 0 && (
      <div style={{ marginBottom: 24 }}>
        <div style={{ marginBottom: 4 }}>
          <Typography.Body type="Label">Attached Files</Typography.Body>
        </div>
        {attachedFiles.map((file) => (
          <Row centerAlign key={file.fileName} style={{ height: 26 }}>
            {file.isUploading && (
              <div style={{ width: 30 }}>
                <Loading isTiny />
              </div>
            )}
            {!file.isUploading && (
              <Button
                testId={'delete-file'}
                size={'Small'}
                type={'Secondary'}
                icon="cross"
                onClick={() => onFileDeleted(file.fileName)}
              />
            )}
            {file.downloadUrl && (
              <a
                href={file.downloadUrl}
                download
                target="_blank"
                rel="noreferrer"
              >
                <Typography.Body type="Link">{file.fileName}</Typography.Body>
              </a>
            )}
            {!file.downloadUrl && (
              <Typography.Body type="Body 12">{file.fileName}</Typography.Body>
            )}
          </Row>
        ))}
      </div>
    )}
    {(commentHtml !== '' || attachedFiles.length > 0) && !isUploadingFiles && (
      <Row style={{ marginTop: 12 }} centerAlign spaceBetween>
        <Button
          onClick={cancelComment}
          label="Cancel"
          type="Tertiary"
          size="Small"
        />
        <Button
          onClick={createComment}
          isLoading={isCreatingComment}
          testId="send-comment"
          label={sendButtonText ? sendButtonText : 'Send'}
          type="Primary"
          size="Small"
        />
      </Row>
    )}
  </div>
);

export default CreateComment;
