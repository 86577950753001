import filterPlateTypeCheckers from 'types/filterPlateTypeCheckers';
import fiToDrillDowns from 'screens/DataManager/Metrics/MetricPopup/MetricForm/NormalMetricForm/fiToDrillDowns';
import { useCallback, useContext } from 'react';
import DatasetFiltersContext from 'screens/DataManager/DatasetFilters/context/DatasetFiltersContext';
import DatasetDefinitionsContext from 'contexts/DatasetDefinitionsContext';

const transformFixedPlate = ({
  plate,
}: {
  plate: FixedFilterPlate;
}): ReportDrillDownType[] => {
  return [plate.fixedValue];
};

const transformVariablePlate = ({
  plate,
  variableDrillDowns,
}: {
  plate: VariableFilterPlate;
  variableDrillDowns?: VariableDrillDownType[];
}): ReportDrillDownType[] => {
  if (!variableDrillDowns) {
    console.warn('Variable drilldowns not defined');
    return [];
  }

  const varId = plate.variableId;
  if (varId) {
    const variableDrill = variableDrillDowns.find((d) => d.id === varId);
    if (variableDrill) {
      return [variableDrill.value];
    } else {
      return [];
    }
  } else {
    return [];
  }
};

const transformDatasetPlate = ({
  plate,
  datasetFilters,
  datasets,
}: {
  plate: DatasetFilterPlate;
  datasetFilters?: DatasetFilter[];
  datasets?: FleetOps.DatasetDefinition[];
}): ReportDrillDownType[] => {
  const dsFilter = datasetFilters?.find(
    (df) => df.id === plate.datasetFilterId,
  );
  if (!dsFilter || !datasetFilters || !datasets) {
    return [];
  }
  return fiToDrillDowns(dsFilter.filterInput, datasets);
};

const transformPlate = ({
  plate,
  variableDrillDowns,
  datasetFilters,
  datasets,
}: {
  plate: FilterPlate;
  variableDrillDowns?: VariableDrillDownType[];
  datasetFilters?: DatasetFilter[];
  datasets?: FleetOps.DatasetDefinition[];
}): ReportDrillDownType[] => {
  if (filterPlateTypeCheckers.isDataset(plate)) {
    return transformDatasetPlate({
      plate,
      datasetFilters,
      datasets,
    });
  } else if (filterPlateTypeCheckers.isFixed(plate)) {
    return transformFixedPlate({ plate });
  } else if (filterPlateTypeCheckers.isVariable(plate)) {
    return transformVariablePlate({
      plate,
      variableDrillDowns,
    });
  } else {
    return [];
  }
};

const useToDrillDowns = () => {
  const { datasetFilters } = useContext(DatasetFiltersContext);
  const { datasets } = useContext(DatasetDefinitionsContext);

  const toDrillDowns = useCallback(
    ({
      plates,
      variableDrillDowns,
    }: {
      plates?: FilterPlate[];
      variableDrillDowns?: VariableDrillDownType[];
    }): ReportDrillDownType[] => {
      if (!plates) {
        return [];
      }

      return plates
        .map((plate) =>
          transformPlate({
            plate,
            variableDrillDowns,
            datasetFilters,
            datasets,
          }),
        )
        .reduce((a, b) => [...a, ...b], []);
    },
    [datasetFilters, datasets],
  );

  return toDrillDowns;
};

export const toFixedPlate = ({
  plate,
  variableDrillDowns,
}: {
  plate: VariableFilterPlate;
  variableDrillDowns: VariableDrillDownType[];
}): FilterPlate => {
  const v = variableDrillDowns.find((d) => d.id === plate.variableId);
  if (v) {
    return {
      id: plate.id,
      type: 'Fixed',
      fixedValue: v.value,
    };
  } else {
    return plate;
  }
};

export default useToDrillDowns;
