import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import ReportContext from 'contexts/ReportContext';
import AccountContext from 'contexts/AccountContext';
import BaseViewsContext from 'contexts/BaseViewsContext';
import DateInputContext from 'contexts/DateInputContext';
import ReportDateDrillDownOptionsProvider from 'contextProviders/ReportDateDrillDownOptionsProvider';
import ReportDrillDownsProvider from 'contextProviders/ReportDrillDownsProvider';
import Loading from 'components/Loading';
import { LAST_30_DAYS_INCLUDING_TODAY } from 'components/DateInput/constants';
import withDateFilter from 'hocs/withDateFIlter';

import SearchScreen from './Search';

const useSelectedDatatype = () =>
  new URLSearchParams(useLocation().search).get('dataset');

const SearchScreenContainer = () => {
  const { weekStartsOn } = useContext(AccountContext);
  const navigate = useNavigate();
  const { dataset } = useParams<{ dataset?: string }>();
  const [isDatasetSelectDisabled] = useState<boolean>(!!dataset);

  const [datasetOptions, setDatasetOptions] = useState<DropdownOption[]>([]);
  const dataTypeFromSearch = useSelectedDatatype();
  const [dataType, setDataType] = useState<string>(
    dataTypeFromSearch ? dataTypeFromSearch : dataset ? dataset : '',
  );
  const lastDataType = useRef<string>(dataType);
  const [isResetting, setIsResetting] = useState<boolean>(false);
  const { baseViews, dataTypes, isLoading, dataTypeOptions } =
    useContext(BaseViewsContext);
  const { setDateField } = useContext(DateInputContext);

  useEffect(() => {
    setDatasetOptions(
      dataTypeOptions.map((o) => ({
        label: o.label,
        isSelected: o.value === dataType,
        onSelected: () => {
          setDataType(o.value);
          navigate({ search: `dataset=${o.value}` });
          setDateField('date');
        },
      })),
    );
  }, [dataType, dataTypeOptions, navigate, setDateField]);

  const { setDataTypes } = useContext(DateInputContext);
  useEffect(() => {
    if (
      (dataTypeFromSearch === '' || dataTypeFromSearch == null) &&
      !dataType &&
      dataTypes &&
      dataTypes.length > 0
    ) {
      setDataType(dataTypes[0]);
      navigate({ search: `dataset=${dataTypes[0]}` });
    }
  }, [dataType, dataTypeFromSearch, dataTypes, navigate]);

  useEffect(() => {
    setIsResetting(true);
  }, [baseViews]);

  const [currentDrillDowns, setCurrentDrillDowns] = useState<FilterPlate[]>([]);

  const [hasLeftDefaultState, setHasLeftDefaultState] =
    useState<boolean>(false);
  const [variableFilters] = useState<VariableDrillDownType[]>([]);
  const reset = () => {
    setCurrentDrillDowns([]);
  };

  useEffect(reset, [dataType]);

  useEffect(() => {
    setDataTypes([dataType]);
  }, [dataType, setDataTypes]);

  useEffect(() => {
    setHasLeftDefaultState(currentDrillDowns.length !== 0);
  }, [currentDrillDowns]);

  if (isLoading || dataType === '') {
    return <Loading />;
  }

  if (lastDataType.current !== dataType) {
    setIsResetting(true);
    lastDataType.current = dataType;
    return <Loading />;
  }

  if (isResetting) {
    setTimeout(
      () => {
        setIsResetting(false);
      },
      process.env.NODE_ENV === 'test' ? 100 : 1000,
    );
    return <Loading />;
  }

  return (
    <ReportContext.Provider
      value={{
        currentScope: window.emptyArray,
        currentDrillDowns,
        reset,
      }}
    >
      <ReportDateDrillDownOptionsProvider startOfWeek={weekStartsOn}>
        <ReportDrillDownsProvider
          drillDowns={currentDrillDowns}
          setDrillDowns={setCurrentDrillDowns}
          variableDrillDowns={variableFilters}
          dataType={dataType}
        >
          <SearchScreen
            key={dataType}
            dataType={dataType}
            datasetOptions={datasetOptions}
            hasLeftDefaultState={hasLeftDefaultState}
            isDatasetSelectDisabled={isDatasetSelectDisabled}
          />
        </ReportDrillDownsProvider>
      </ReportDateDrillDownOptionsProvider>
    </ReportContext.Provider>
  );
};

export default withDateFilter(SearchScreenContainer, {
  initialRelativeDateRange: LAST_30_DAYS_INCLUDING_TODAY,
});
