import styled from 'styled-components';

export const TooltipPrimitive = styled.div`
  z-index: 90001;
`;

export const TOOLTIP_COLOR = '#1a1919';

export const TooltipDiv = styled(TooltipPrimitive)<{ truncate?: boolean }>`
  background-color: #1a1919;
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: ${TOOLTIP_COLOR};

  /* Shadow/Shadow__S */
  box-shadow:
    0px 5px 3px -2px rgba(0, 0, 0, 0.02),
    0px 3px 1px -2px rgba(0, 0, 0, 0.06);

  word-wrap: break-word;
  overflow-wrap: break-word;

  color: white;

  /* Body/Medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */

  max-width: 400px;

  ${({ truncate }) =>
    truncate &&
    `
      max-width: 420px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `}
`;

export const AltTooltipDiv = styled(TooltipPrimitive)`
  background: white;
  border-radius: 4px;
  box-shadow:
    0 0 0 1px rgba(63, 63, 68, 0.14),
    0 1px 3px 0 rgba(63, 63, 68, 0.19),
    0 1px 2px 0 rgba(63, 63, 68, 0.14);
  box-sizing: content-box; /* do not set this to border-box or it will break the overflow handling */
  color: #333;
  padding: 4px 8px;
  max-width: 800px;
`;
