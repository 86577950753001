import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import getDateBuckets from '../../screens/GoalShow/getDateBuckets';
import moment from 'moment';
import AccountContext from '../../contexts/AccountContext';
import formatDateLabel from '../../components/V5Gadget/formatDateLabel';
import ScorecardContext from 'contexts/ScorecardContext';
import ScorecardsContext from '../../contexts/ScorecardsContext';
import updateScorecardDoc from '../../screens/ScorecardsIndex/updateScorecardDoc';
import AccountPickerContext from '../../contexts/AccountPickerContext';
import { convertWeekDateToIsoDate } from '../../types/scorecardDates/convertDates';
import isIsoDate from '../../types/scorecardDates/isIsoDate';
import GqlClientProvider from '../GqlClientProvider';
import WeekStartsOnOverrideContext from '../../contexts/WeekStartsOnOverrideContext';
import ErrorBoundary from '../../components/Common/ErrorBoundary';
import DashboardContext from '../../contexts/DashboardContext';
import useScorecardData from './useScorecardData';
import Loading from 'components/Loading/Loading';

interface ScorecardProviderGateProps {
  children: JSX.Element | JSX.Element[];
  scorecardId: string;
  isAddKpiEnabled?: boolean;
  isDragAndDropEnabled?: boolean;
  isKpisDisabled?: boolean;
  isMenuEnabled?: boolean;
}

interface ScorecardProviderProps extends ScorecardProviderGateProps {
  scorecard: Scorecards.Scorecard;
  setScorecard: React.Dispatch<
    React.SetStateAction<Scorecards.Scorecard | undefined>
  >;
}

const useScorecardPeriods = (
  toIsoDate: (date: Scorecards.WeekDate | Scorecards.IsoDate) => string,
  scorecard?: Scorecards.Scorecard,
) => {
  const { weekStartsOn, isDemoAccount, demoAccountNow } =
    useContext(AccountContext);
  const [periods, setPeriods] = useState<Period[]>([]);

  const getEndDate = useCallback(
    ({ startDate }: { startDate: string }) => {
      if (!scorecard) {
        return startDate;
      }

      if (scorecard.cadence === 'week') {
        return moment(startDate).add({ day: 6 }).format('YYYY-MM-DD');
      } else if (scorecard.cadence === 'month') {
        return moment(startDate).endOf('month').format('YYYY-MM-DD');
      } else {
        return startDate;
      }
    },
    [scorecard],
  );

  const buildPeriods = useCallback(() => {
    if (!scorecard) {
      return window.emptyArray;
    }

    const now = moment
      .utc(isDemoAccount && demoAccountNow ? demoAccountNow : undefined)
      .startOf('day')
      .format('YYYY-MM-DD');

    return getDateBuckets({
      startDate: toIsoDate(scorecard.startDate),
      endDate: now,
      interval: scorecard.cadence,
      weekStartsOn: scorecard.weekStartsOnOverride
        ? scorecard.weekStartsOnOverride
        : weekStartsOn,
    })
      .sort()
      .map((startDate) => {
        return {
          startDate,
          endDate: getEndDate({ startDate }),
        };
      })
      .map((dateRange) => ({
        ...dateRange,
        label: formatDateLabel(
          dateRange.startDate,
          scorecard.cadence,
          false,
          dateRange,
          false,
          false,
        ),
      }));
  }, [
    demoAccountNow,
    getEndDate,
    isDemoAccount,
    scorecard,
    toIsoDate,
    weekStartsOn,
  ]);

  useEffect(() => {
    setPeriods(buildPeriods());
  }, [buildPeriods]);

  return periods;
};

const ScorecardProvider = ({
  children,
  scorecard,
  setScorecard,
  isAddKpiEnabled,
  isDragAndDropEnabled,
  isKpisDisabled,
  isMenuEnabled,
}: ScorecardProviderProps) => {
  // Context
  const { weekStartsOn } = useContext(AccountContext);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const { dashboard } = useContext(DashboardContext);

  // State
  const [poppedUpKpi, setPoppedUpKpi] = useState<
    Scorecards.ScorecardKpi | Scorecards.ManualKpiRow
  >();
  const [selectedPeriods, setSelectedPeriods] = useState<Period[]>([]);
  const [manualKpiIdBeingEdited, setManualKpiIdBeingEdited] =
    useState<string>();
  const kpisListDivRef = useRef<HTMLDivElement>(null);
  const kpisListDivParentScrollerRef = useRef<HTMLDivElement>(null);
  const [kpiBeingHighlighted, setKpiBeingHighlighted] = useState<
    string | undefined
  >();
  const [hoveredKpiId, setHoveredKpiId] = useState<string | undefined>();

  // Callbacks
  const toIsoDate = useCallback(
    (date: Scorecards.IsoDate | Scorecards.WeekDate) => {
      if (isIsoDate(date)) {
        return date;
      }

      const startOfWeekToUse = scorecard.weekStartsOnOverride
        ? scorecard.weekStartsOnOverride
        : weekStartsOn;

      return convertWeekDateToIsoDate(date, startOfWeekToUse);
    },
    [scorecard, weekStartsOn],
  );

  const updateScorecard = useCallback(
    async (updatedScorecard: Scorecards.Scorecard) => {
      setScorecard(updatedScorecard);
      await updateScorecardDoc(updatedScorecard, selectedAccountId);
    },
    [selectedAccountId, setScorecard],
  );

  const highlightKpi = useCallback((kpiId: string) => {
    setKpiBeingHighlighted(kpiId);
    setTimeout(() => {
      setKpiBeingHighlighted(undefined);
    }, 2000);
  }, []);

  // Hooks
  const periods = useScorecardPeriods(toIsoDate, scorecard);
  const { data, isLoading } = useScorecardData({
    scorecard,
    selectedPeriods,
    isKpisDisabled,
  });

  // Effects
  useEffect(() => {
    setSelectedPeriods(periods.slice(dashboard === undefined ? -12 : -3));
  }, [dashboard, periods]);

  if (!scorecard) {
    return null;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <ScorecardContext.Provider
      value={{
        scorecard,
        periods,
        selectedPeriods,
        setSelectedPeriods,
        updateScorecard,
        isLoading,
        data,
        poppedUpKpi,
        setPoppedUpKpi,
        isAddKpiEnabled,
        isDragAndDropEnabled,
        isKpisDisabled,
        isMenuEnabled,
        toIsoDate,
        kpisListDivRef,
        kpisListDivParentScrollerRef,
        manualKpiIdBeingEdited,
        setManualKpiIdBeingEdited,
        highlightKpi,
        kpiBeingHighlighted,
        hoveredKpiId,
        setHoveredKpiId,
      }}
    >
      {children}
    </ScorecardContext.Provider>
  );
};

const Gate = (props: ScorecardProviderGateProps) => {
  const { scorecards } = useContext(ScorecardsContext);

  const [scorecard, setScorecard] = useState<
    Scorecards.Scorecard | undefined
  >();

  useEffect(() => {
    const newScorecard = scorecards.find((g) => g.id === props.scorecardId);
    setScorecard(newScorecard);
  }, [props.scorecardId, scorecards]);

  if (!scorecard) {
    return null;
  }

  return (
    <ErrorBoundary>
      <WeekStartsOnOverrideContext.Provider
        value={{
          weekStartsOnOverride: scorecard
            ? scorecard.weekStartsOnOverride
            : undefined,
        }}
      >
        <GqlClientProvider
          visId={scorecard.id}
          visType={'scorecard'}
          visName={scorecard.title}
        >
          <ScorecardProvider
            {...props}
            scorecard={scorecard}
            setScorecard={setScorecard}
          />
        </GqlClientProvider>
      </WeekStartsOnOverrideContext.Provider>
    </ErrorBoundary>
  );
};

export default Gate;
