import React, { useContext, useEffect, useState } from 'react';
import TextGadget from './TextGadget';
import useTextCardContent from '../../hooks/useTextCardContent';
import CanvasContext from '../../contexts/CanvasContext';
import AccountPickerContext from '../../contexts/AccountPickerContext';

const TextGadgetContainer = ({ textContentId }: { textContentId: string }) => {
  const { isEditing: isEditingCanvas } = useContext(CanvasContext);
  const { text, isCenterAlign, isLoading } = useTextCardContent(textContentId);
  const [currentText, setCurrentText] = useState<string>(text);
  const [currentIsAlign, setCurrentIsAlign] = useState<boolean | undefined>(
    isCenterAlign,
  );
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const startEditing = () => {
    setIsEditing(true);
  };
  const stopEditing = () => {
    setIsEditing(false);
  };

  const { accountRef } = useContext(AccountPickerContext);
  useEffect(() => {
    if (isEditingCanvas) {
      stopEditing();
    }
  }, [isEditingCanvas]);

  const onChange = (content: string) => {
    setCurrentText(content);
    if (accountRef && isEditing) {
      accountRef
        .collection('textCardContents')
        .doc(textContentId)
        .set({ textContent: content, isCenterAlign: !!currentIsAlign });
    }
  };

  const onAlignChanged = (newAlign: boolean) => {
    setCurrentIsAlign(newAlign);
    if (accountRef && isEditing) {
      accountRef
        .collection('textCardContents')
        .doc(textContentId)
        .set({ textContent: currentText, isCenterAlign: newAlign });
    }
  };

  useEffect(() => {
    if (currentText === '') {
      setCurrentText(text);
    }
  }, [currentText, text]);

  useEffect(() => {
    if (!isLoading) {
      setCurrentIsAlign(!!isCenterAlign);
    }
  }, [isCenterAlign, isLoading]);

  return (
    <TextGadget
      text={currentText}
      onChange={onChange}
      startEditing={startEditing}
      isEditing={isEditing}
      isCenterAlign={!!currentIsAlign}
      onAlignChanged={onAlignChanged}
      stopEditing={stopEditing}
      isEditingCanvas={isEditingCanvas}
    />
  );
};

export default TextGadgetContainer;
