import { useContext, useEffect, useState } from 'react';
import AccountPickerContext from '../contexts/AccountPickerContext';
import { DateTime } from 'luxon';
import CurrentUserContext, {
  CurrentUserContextType,
} from '../contexts/CurrentUserContext';
import { WALLBOARDS_COLLECTION } from '../constants';

const migrateWallBoard = (
  wb: Wallboard,
  currentUser: CurrentUserContextType,
): Wallboard => {
  // @ts-ignore
  if (wb.intervalTimeMs && !wb.intervalTimeSeconds) {
    // @ts-ignore
    wb.intervalTimeSeconds = Math.floor(wb.intervalTimeMs / 1000);
  }

  // @ts-ignore
  if (wb.version === undefined) {
    wb.version = '2';
    wb.updatedOn = DateTime.utc().toISO();
    wb.createdOn = DateTime.utc().toISO();
    wb.createdBy = currentUser.id;
    wb.updatedBy = currentUser.id;
  }

  return wb;
};

const useWallBoards = () => {
  const [wallBoards, setWallBoards] = useState<Wallboard[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { accountRef } = useContext(AccountPickerContext);
  const currentUser = useContext(CurrentUserContext);

  useEffect(() => {
    if (process.env.NODE_ENV === 'test') {
      return;
    }

    const listener = accountRef
      .collection(WALLBOARDS_COLLECTION)
      .onSnapshot((s) => {
        const data = [] as Wallboard[];
        s.docs.forEach((d: any) => data.push({ ...d.data() }));
        setWallBoards(data.map((wb) => migrateWallBoard(wb, currentUser)));
        setTimeout(() => {
          setIsLoading(false);
        }, 200);
      });

    return () => {
      listener();
    };
  }, [accountRef, currentUser]);

  return {
    wallBoards,
    isLoading,
  };
};

export default useWallBoards;
