import React, { useContext, useEffect, useRef, useState } from 'react';
import Highcharts, {
  Options as HighChartOptions,
  SeriesGaugeOptions,
} from 'highcharts/highcharts';

import usePeerComparison from './usePeerComparison';
import Loading from '../../Loading';
import { ChartContent } from '../../Chart';
import formatFloat from '../../../api/getChartSeries/formatFloat';
import AccountContext from '../../../contexts/AccountContext';
import FlexCentered from '../../Common/FlexCentered';
import useValueFormatters from '../../../hooks/useValueFormatters';

const useChartOptions = ({
  gauge,
}: {
  gauge: VisualisationDefinitions.Gauge;
}) => {
  const { unitsLocale } = useContext(AccountContext);
  const { data, isLoading, formatting } = usePeerComparison({ gauge });
  const [chartOptions, setChartOptions] = useState<
    HighChartOptions | undefined
  >();
  const [current, setCurrent] = useState<string>('');
  const { formatMetric } = useValueFormatters();

  useEffect(() => {
    if (isLoading || !data) {
      setChartOptions(undefined);
      return;
    }

    const min = formatFloat(data.min, formatting ? formatting.precision : 2);
    const max = formatFloat(data.max, formatting ? formatting.precision : 2);
    const value = formatFloat(
      data.value,
      formatting ? formatting.precision : 2,
    );

    const formattedValue = formatMetric({
      value: data.value,
      metricId: gauge.metricId,
    });
    setCurrent(formattedValue);

    const series = [
      {
        name: gauge.entity,
        data: [value],
      },
    ] as SeriesGaugeOptions[];

    setChartOptions({
      accessibility: {
        enabled: false,
      },
      chart: {
        type: 'solidgauge',
      },
      title: {
        text: undefined,
      },
      pane: {
        center: ['50%', '60%'],
        startAngle: -90,
        endAngle: 90,
        background: {
          // @ts-ignore
          backgroundColor:
            // @ts-ignore
            Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
          innerRadius: '60%',
          outerRadius: '100%',
          shape: 'arc',
        },
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            y: 30,
            borderWidth: 0,
            useHTML: true,
            crop: false,
            overflow: 'allow',
            style: { fontSize: `20px` },
            allowOverlap: true,
            formatter: () => {
              return current;
            },
            nullFormatter: () => current,
          },
        },
      },
      tooltip: {
        formatter: () => {
          return `${gauge.entity}: ${formattedValue}`;
        },
      },
      yAxis: {
        stops:
          formatting && formatting.positiveDeltaIsGood
            ? [
                [0.1, '#DF5353'],
                [0.5, '#DDDF0D'],
                [0.9, '#55BF3B'],
              ]
            : [
                [0.1, '#55BF3B'],
                [0.5, '#DDDF0D'],
                [0.9, '#DF5353'],
              ],
        lineWidth: 1,
        tickWidth: 1,
        tickAmount: 2,
        tickPositions: [min, max],
        title: {
          text: '',
        },
        labels: {
          style: {
            fontSize: `11px`,
          },
          y: 11,
          formatter: (x) => {
            return formatMetric({
              value: x.value,
              metricId: gauge.metricId,
            });
          },
        },
        min,
        max,
      },
      credits: {
        enabled: false,
      },
      series,
    });
  }, [
    current,
    data,
    formatMetric,
    formatting,
    gauge.entity,
    gauge.metricId,
    gauge.name,
    isLoading,
    unitsLocale,
  ]);

  return { chartOptions, current };
};
const GaugeContainer = ({
  gauge,
}: {
  gauge: VisualisationDefinitions.Gauge;
}) => {
  const chartRef = useRef<any>();

  const { chartOptions } = useChartOptions({ gauge });
  if (chartOptions === undefined) {
    return <Loading />;
  }

  return (
    <>
      <FlexCentered style={{ height: '100%', width: '100%' }}>
        <ChartContent options={chartOptions} chartRef={chartRef} />
      </FlexCentered>
    </>
  );
};

export default GaugeContainer;
