import React from 'react';
import ReportDrillDown from '../ReportDrillDown';
import AddReportDrillDownButton from '../AddReportDrillDownButton';
import Row from '../Common/Row';
import DatasetPlate from 'components/ReportDrillDown/DatasetPlate';
import filterPlateTypeCheckers from 'types/filterPlateTypeCheckers';

const ReportDrillDowns = ({
  drillDowns,
  scope,
  isScope,
  isReport,
  isDashboardCard,
  onManualFilterChanged,
  onOpened,
  onFilterAdded,
  onFilterRemoved,
  testIdPrefix,
  label,
  isDisabled,
  isDateScopeDisabled,
}: {
  drillDowns: FilterPlate[];
  scope: FilterPlate[];
  isScope?: boolean;
  isReport?: boolean;
  isDashboardCard?: boolean;
  onManualFilterChanged?: () => void;
  onOpened?: () => void;
  onFilterAdded?: () => void;
  onFilterRemoved?: () => void;
  testIdPrefix?: string;
  label?: string;
  isDisabled?: boolean;
  isDateScopeDisabled?: boolean;
}) => (
  <Row style={{ flexWrap: 'wrap', gap: 8 }}>
    {isScope && (
      <React.Fragment>
        {scope.map((d) => {
          if (filterPlateTypeCheckers.isDataset(d)) {
            return (
              <DatasetPlate
                key={d.id}
                datasetFilterId={d.datasetFilterId}
                isScope={isScope}
              />
            );
          } else {
            return (
              <ReportDrillDown
                drillDown={d}
                key={d.id}
                isScope={isScope}
                isReport={isReport}
                isDashboardCard={isDashboardCard}
                onManualFilterChanged={onManualFilterChanged}
                onFilterRemoved={onFilterRemoved}
                isDisabled={isDisabled}
                isDateScopeDisabled={isDateScopeDisabled}
              />
            );
          }
        })}
      </React.Fragment>
    )}
    {!isScope && (
      <React.Fragment>
        {drillDowns.map((d) => {
          if (filterPlateTypeCheckers.isDataset(d)) {
            return (
              <DatasetPlate
                key={d.id}
                datasetFilterId={d.datasetFilterId}
                isScope={false}
              />
            );
          } else {
            return (
              <ReportDrillDown
                drillDown={d}
                key={d.id}
                isScope={false}
                isReport={isReport}
                isDashboardCard={isDashboardCard}
                onManualFilterChanged={onManualFilterChanged}
                onFilterRemoved={onFilterRemoved}
                isDisabled={isDisabled}
                isDateScopeDisabled={isDateScopeDisabled}
              />
            );
          }
        })}
      </React.Fragment>
    )}

    <AddReportDrillDownButton
      isScope={!!isScope}
      isReport={isReport}
      onManualFilterChanged={onManualFilterChanged}
      onOpened={onOpened}
      onFilterAdded={onFilterAdded}
      testIdPrefix={testIdPrefix}
      label={label}
      isDisabled={isDisabled}
      isDateScopeDisabled={isDateScopeDisabled}
    />
  </Row>
);

export default ReportDrillDowns;
