const HEIGHT_BREAKPOINT = 720;
const WIDTH_BREAKPOINT_SMALL = 1366;
const WIDTH_BREAKPOINT_MEDIUM = 1600;
const SMALL_HEIGHT = `(max-height: ${HEIGHT_BREAKPOINT}px)`;
const MEDIUM_WIDTH = `(max-width: ${WIDTH_BREAKPOINT_MEDIUM}px)`;
const SMALL_WIDTH = `(min-width: ${WIDTH_BREAKPOINT_SMALL}px) and ${MEDIUM_WIDTH}`;

const QUERIES = {
  SMALL_HEIGHT,
  SMALL_WIDTH,
  MEDIUM_WIDTH,
};

const MEASUREMENTS = {
  HEIGHT_BREAKPOINT,
  WIDTH_BREAKPOINT_SMALL,
  WIDTH_BREAKPOINT_MEDIUM,
};

const MEDIA = {
  QUERIES,
  MEASUREMENTS,
};

export default MEDIA;
