import useMetric from '../../../hooks/useMetric';
import { useContext, useEffect, useState } from 'react';
import ComparisonContext from '../../../contexts/ComparisonContext';
import toSentenceCase from '../../../services/toSentenceCase';
import formatDateLabel from '../../V5Gadget/formatDateLabel';
import { getComparisonLabel } from '../../Report/ComparisonSelector/ComparisonSelector';
import moment from 'moment';
import { HISTOGRAM_CELL_RENDERER } from './constants';

const comparator =
  (
    type: 'delta' | 'percentDelta' | 'value',
    sort: VisualisationDefinitions.ClientSortBy | undefined,
    positiveDeltaIsGood: boolean,
  ) =>
  (aCell: MatrixComparisonCell, bCell: MatrixComparisonCell) => {
    const { a, isGoodA, b, isGoodB } = (() => {
      if (type === 'delta') {
        const a = aCell ? aCell.comparisonDelta || 0 : 0;
        const b = bCell ? bCell.comparisonDelta || 0 : 0;
        const isGoodA = positiveDeltaIsGood ? a >= 0 : a <= 0;
        const isGoodB = positiveDeltaIsGood ? b >= 0 : b <= 0;
        return { a, isGoodA, b, isGoodB };
      } else if (type === 'percentDelta') {
        const a = aCell ? aCell.comparisonDeltaPercent || 0 : 0;
        const b = bCell ? bCell.comparisonDeltaPercent || 0 : 0;
        const isGoodA = positiveDeltaIsGood ? a >= 0 : a <= 0;
        const isGoodB = positiveDeltaIsGood ? b >= 0 : b <= 0;
        return { a, isGoodA, b, isGoodB };
      } else {
        const a = aCell ? aCell.value || 0 : 0;
        const b = bCell ? bCell.value || 0 : 0;
        return { a, b, isGoodA: undefined, isGoodB: undefined };
      }
    })();
    const isChange = (sort && sort.includes('change')) || type === 'value';

    if (a === 0 && b === 0) {
      return 0; // do nothing
    }

    if (a === 0 || b === 0) {
      // -1 => a comes first
      // +1 => b comes first

      if (isChange) {
        // We are just looking at the raw movement
        if (a === 0) {
          return -1;
        }
        if (b === 0) {
          return 1;
        }
        return 0;
      } else {
        // We care about improvement / deterioration
        if (a === 0) {
          if (isGoodB) {
            return -1;
          } else {
            return 1;
          }
        }
        if (b === 0) {
          if (isGoodA) {
            return 1;
          } else {
            return -1;
          }
        }
      }
    }

    if (isChange) {
      const absA = Math.abs(a);
      const absB = Math.abs(b);
      return absA - absB;
    } else {
      const absA = Math.abs(a) * (isGoodA ? 1 : -1);
      const absB = Math.abs(b) * (isGoodB ? 1 : -1);
      return absA - absB;
    }
  };

const buildColDefChildren = ({
  histogramPoint,
  format,
  comparison,
  sort,
  metric,
  metricDisplayName,
  disableAutoLayout,
  isFirstCol,
}: {
  histogramPoint: HistogramPoint;
  format: MatrixCellType;
  comparison: PersistedComparisonType;
  sort?: VisualisationDefinitions.ClientSortBy;
  metric: Metrics.Metric;
  metricDisplayName: string;
  disableAutoLayout: boolean;
  isFirstCol?: boolean;
}) => {
  const base = {
    headerName: metricDisplayName,
    field: histogramPoint.date,
    cellRenderer: HISTOGRAM_CELL_RENDERER,
    suppressHeaderMenuButton: true,
    marryChildren: true,
    flex: disableAutoLayout ? 1 : undefined,
    headerClass: 'rightAlignedHeader',
    minWidth: metricDisplayName.length * 9,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    comparator: comparator(
      'value',
      sort,
      !!metric.formatting.positiveDeltaIsGood,
    ),
    valueFormatter: () => '',
  };

  const delta = {
    headerName: 'Chg',
    headerTooltip: `The difference between the current period and the ${getComparisonLabel(
      comparison,
    ).toLowerCase()}`,
    field: `${histogramPoint.date}-delta`,
    isLeftAlign: true,
    cellRenderer: HISTOGRAM_CELL_RENDERER,
    suppressHeaderMenuButton: true,
    minWidth: metricDisplayName.length * 9,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    flex: 1,
    comparator: comparator(
      'delta',
      sort,
      !!metric.formatting.positiveDeltaIsGood,
    ),
    sort:
      isFirstCol && sort
        ? sort.includes('asc')
          ? ('asc' as 'asc')
          : sort.includes('desc')
            ? ('desc' as 'desc')
            : undefined
        : undefined,
    valueFormatter: () => '',
  };

  const percentDelta = {
    headerName: '%Chg',
    headerTooltip: `The percentage change between the current period and the ${getComparisonLabel(
      comparison,
    ).toLowerCase()}`,
    field: `${histogramPoint.date}-percentDelta`,
    isLeftAlign: true,
    cellRenderer: HISTOGRAM_CELL_RENDERER,
    suppressHeaderMenuButton: true,
    minWidth: metricDisplayName.length * 9,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    flex: 1,
    comparator: comparator(
      'percentDelta',
      sort,
      !!metric.formatting.positiveDeltaIsGood,
    ),
    valueFormatter: () => '',
  };

  if (format === 'delta') {
    return [base, delta, { ...percentDelta, hide: true }];
  } else if (format === 'percentDelta') {
    return [base, percentDelta, { ...delta, hide: true }];
  } else if (format === 'both') {
    return [base, delta, percentDelta];
  } else {
    return [base];
  }
};

const useColDefs = (
  response: SingleMetricDateMatrixResponse | undefined,
  gadget: VisualisationDefinitions.SingleMetricDateMatrix,
  disableAutoLayout: boolean,
) => {
  const metric = useMetric(gadget.metricId);
  const { currentComparison } = useContext(ComparisonContext);
  const [colDefs, setColDefs] = useState<MatrixColDef[]>([]);

  useEffect(() => {
    if (response && currentComparison && metric) {
      const metricDisplayName = gadget.metricDisplayName || metric.name;

      const groupDef = {
        headerName: toSentenceCase(gadget.groupByField),
        headerTooltip: gadget.groupByField,
        field: gadget.groupByField,
        cellRenderer: 'groupingCellRenderer',
        suppressHeaderMenuButton: true,
        isLeftAlign: true,
        flex: disableAutoLayout ? 1 : undefined,
        suppressMovable: true,
        minWidth: metricDisplayName.length * 9,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      };

      const newColDefs = response.histogram.map((histogramPoint, index) => {
        const children = buildColDefChildren({
          histogramPoint,
          format: gadget.comparisonCellFormat,
          comparison: currentComparison,
          sort: gadget.clientSortBy,
          metric,
          metricDisplayName,
          disableAutoLayout,
          isFirstCol: index === 0,
        });

        const headerName =
          histogramPoint.interval === 'day'
            ? moment.utc(histogramPoint.date).format('ddd, MM/DD')
            : formatDateLabel(
                histogramPoint.date,
                histogramPoint.interval,
                true,
                histogramPoint.valueDateRange,
              );

        return {
          headerName,
          headerTooltip: histogramPoint.date,
          children,
          marryChildren: true,
          flex: disableAutoLayout ? 1 : undefined,
        };
      });

      setColDefs([groupDef, ...newColDefs]);
    }
  }, [
    currentComparison,
    gadget.comparisonCellFormat,
    gadget.groupByField,
    gadget.clientSortBy,
    metric,
    response,
    gadget.metricDisplayName,
    disableAutoLayout,
  ]);

  return {
    colDefs,
  };
};

export default useColDefs;
