import styled from 'styled-components';
import MetricPopupConstants from '../constants';
import MEDIA from '../../../../../media';

const OuterDiv = styled.div`
  height: calc(${MetricPopupConstants.POPUP_HEIGHT} - 162px);
  @media ${MEDIA.QUERIES.SMALL_HEIGHT} {
    height: calc(${MetricPopupConstants.POPUP_HEIGHT_SMALL} - 116px);
  }

  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const InnerDiv = styled.div`
  overflow-y: auto;
  padding: ${MetricPopupConstants.PADDING};

  @media ${MEDIA.QUERIES.SMALL_HEIGHT} {
    padding: ${MetricPopupConstants.PADDING_SMALL};
  }
`;

const MetricFormStyles = {
  OuterDiv,
  InnerDiv,
};

export default MetricFormStyles;
