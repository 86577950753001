import React, { useCallback } from 'react';
import { VALID_PASSWORD_PATTERN } from '..';

const useOnPasswordChanged = ({
  setPassword,
  setPasswordErrors,
}: {
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  setPasswordErrors: React.Dispatch<React.SetStateAction<string[] | undefined>>;
}) => {
  const onPasswordChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newPw = event.target.value;
      setPassword(newPw);
      const isValidPw = VALID_PASSWORD_PATTERN.test(newPw);

      if (isValidPw) {
        setPasswordErrors(undefined);
      } else {
        const isLongEnough = /^[a-zA-Z\d\w\W]{10,}$/.test(newPw);
        const hasOneCapital = /(?=.*[A-Z])/.test(newPw);
        const hasOneNumber = /(?=.*\d)/.test(newPw);
        const hasOneLowerCase = /(?=.*[a-z])/.test(newPw);

        const hasSpecialCharacter = /(?=.*[^a-zA-Z0-9])/.test(newPw);
        const errors = [];
        if (!isLongEnough) {
          errors.push('At least 10 characters in total');
        }
        if (!hasOneCapital) {
          errors.push('At least 1 uppercase character');
        }
        if (!hasOneNumber) {
          errors.push('At least 1 number');
        }
        if (!hasOneLowerCase) {
          errors.push('At least 1 lowercase character');
        }
        if (!hasSpecialCharacter) {
          errors.push('At least 1 special character');
        }
        setPasswordErrors(errors);
      }
    },
    [setPassword, setPasswordErrors],
  );

  return onPasswordChanged;
};

export default useOnPasswordChanged;
