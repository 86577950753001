import React, { ChangeEvent } from 'react';

interface EntityDetailsContextType {
  app: EntityDetails.App;
  entityFilter: FilterInput;
  entityFilterAsFilterPlates: FilterPlate[];
  selectedEntity: EntityDetails.SelectedEntity;
  entityOptions: DropdownOption[];
  onEntitySelected: (newEntity: string) => void;
  tabs: EntityDetails.ClientTab[];
  selectedTab: EntityDetails.ClientTab | undefined;
  entitySearchText: string;
  onEntitySearchTextChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  isLoadingEntityOptions: boolean;
}

const EntityDetailsContext = React.createContext<
  EntityDetailsContextType | undefined
>(undefined);

export default EntityDetailsContext;
