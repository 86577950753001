import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import ActivityGridContext from '../../context/ActivityGridContext';
import useValueFormatters from 'hooks/useValueFormatters';
import numberToCommaString from 'hooks/useValueFormatters/numberToCommaString';
import formatFloat from 'api/getChartSeries/formatFloat';
import CostInput from './CostsInput';

const castToString = (n: number | undefined) => {
  if (n === undefined) {
    return '';
  }

  return String(n);
};

const castToWithoutFormatting = (n: string) => {
  return n.replaceAll(',', '').replaceAll('$', '').replaceAll('€', '');
};

const CostInputContainer = ({
  interval,
  fieldName,
  group,
  isLastInterval,
  hoverId,
}: {
  interval: Period;
  fieldName: string;
  group: Costs.Group;
  isLastInterval: boolean;
  hoverId: string;
}) => {
  const {
    getCostValue,
    updateCostValue,
    isDiscardedChanges,
    setIsDiscardedChanges,
    setIsPendingUnfocus,
    setFocusedInterval,
    focusedInterval,
    isSavingError,
    invalidIntervals,
    hoveredCategory,
  } = useContext(ActivityGridContext);
  const { formatValue } = useValueFormatters();

  const [costInputValue, setCostInputValue] = useState<string>(
    castToString(
      getCostValue({
        startDate: interval.startDate,
        groupDefinition: group,
        costFieldName: fieldName,
      }),
    ),
  );

  const formatOngoingValue = useCallback(
    ({ value, isFocused }: { value: string; isFocused: boolean }) => {
      const formatting = {
        prefix: 'currency',
        currencyType: 'USD',
        precision: 0,
      } as MetricFormatting;

      const commaString = numberToCommaString({
        value: formatFloat(value, 0),
        isCommasDisabled: false,
      });

      const formatted = isFocused
        ? commaString
        : formatValue({
            value: Number(value),
            formatting,
          });

      return formatted;
    },
    [formatValue],
  );

  const [formattedInputValue, setFormattedInputValue] = useState<string>(
    !costInputValue || (!!costInputValue && isNaN(Number(costInputValue)))
      ? ''
      : formatOngoingValue({ value: costInputValue, isFocused: false }),
  );

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isDiscardedChanges) {
      const value = castToString(
        getCostValue({
          startDate: interval.startDate,
          groupDefinition: group,
          costFieldName: fieldName,
        }),
      );
      setCostInputValue(value !== undefined ? value : '');
      setFormattedInputValue(
        !value ? '' : formatOngoingValue({ value, isFocused: false }),
      );
      setIsDiscardedChanges(false);
    }
  }, [
    fieldName,
    formatOngoingValue,
    getCostValue,
    group,
    group.groupName,
    interval.startDate,
    isDiscardedChanges,
    setIsDiscardedChanges,
  ]);

  useEffect(() => {
    const newValue = castToString(
      getCostValue({
        startDate: interval.startDate,
        groupDefinition: group,
        costFieldName: fieldName,
      }),
    );
    if (newValue !== costInputValue) {
      setCostInputValue(newValue);
      setFormattedInputValue(newValue);
    }
  }, [costInputValue, fieldName, getCostValue, group, interval.startDate]);

  const getFormattedOngoingValue = useCallback(
    ({ isFocused, value }: { isFocused: boolean; value?: string }) => {
      const newValue = value || costInputValue;
      const formatted = formatOngoingValue({
        value: newValue,
        isFocused,
      });

      return !newValue || (!!newValue && isNaN(Number(newValue)))
        ? ''
        : formatted;
    },
    [costInputValue, formatOngoingValue],
  );

  const handleAddHighlight = useCallback(() => {
    setIsPendingUnfocus(false);
    setFocusedInterval(interval);
    const newFormattedInputValue = getFormattedOngoingValue({
      isFocused: true,
    });

    setFormattedInputValue(newFormattedInputValue);
  }, [
    getFormattedOngoingValue,
    interval,
    setFocusedInterval,
    setIsPendingUnfocus,
  ]);

  const handleRemoveHighlight = useCallback(() => {
    setIsPendingUnfocus(true);

    const newWithoutFormatting = castToWithoutFormatting(formattedInputValue);

    setCostInputValue(newWithoutFormatting);

    const newFormattedInputValue = getFormattedOngoingValue({
      isFocused: false,
      value: newWithoutFormatting,
    });

    setFormattedInputValue(newFormattedInputValue);
  }, [formattedInputValue, getFormattedOngoingValue, setIsPendingUnfocus]);

  useEffect(() => {
    const inputElem = inputRef.current;
    if (!inputElem) {
      return;
    }
    inputElem.addEventListener('focusin', handleAddHighlight);
    inputElem.addEventListener('focusout', handleRemoveHighlight);
    return () => {
      inputElem.removeEventListener('focusin', handleAddHighlight);
      inputElem.removeEventListener('focusout', handleRemoveHighlight);
    };
  }, [handleAddHighlight, handleRemoveHighlight]);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      const withoutFormatting = castToWithoutFormatting(newValue);

      const isInvalid = !!withoutFormatting && isNaN(Number(withoutFormatting));

      setCostInputValue(withoutFormatting);

      if (!isInvalid) {
        updateCostValue({
          startDate: interval.startDate,
          groupDefinitionName: group.groupName,
          costFieldName: fieldName,
          newValue:
            withoutFormatting === '' ? undefined : Number(withoutFormatting),
        });

        setFormattedInputValue(
          !withoutFormatting
            ? ''
            : formatOngoingValue({
                value: withoutFormatting,
                isFocused: true,
              }),
        );
      }
    },
    [
      fieldName,
      formatOngoingValue,
      group.groupName,
      interval.startDate,
      updateCostValue,
    ],
  );

  const isInvalid =
    isSavingError &&
    !!invalidIntervals.find(
      (invalidInterval) => invalidInterval.startDate === interval.startDate,
    ) &&
    costInputValue === '';

  return (
    <CostInput
      interval={interval}
      fieldName={fieldName}
      group={group}
      isLastInterval={isLastInterval}
      hoverId={hoverId}
      focusedInterval={focusedInterval}
      hoveredCategory={hoveredCategory}
      formatOngoingValue={formatOngoingValue}
      costInputValue={costInputValue}
      formattedInputValue={formattedInputValue}
      isInvalid={isInvalid}
      inputRef={inputRef}
      onChange={onChange}
    />
  );
};

export default CostInputContainer;
