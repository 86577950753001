import { useContext } from 'react';
import { PortalsContext } from '../../contextProviders/PortalsProvider';
import styled from 'styled-components';
import { ImpersonationBannerWrap } from './ImpersonatorBanner';
import Typography from '../../kingpin/atoms/Typography';
import Button from '../../kingpin/atoms/Button';

const BannerDiv = styled(ImpersonationBannerWrap)`
  background-color: #f7f9ff;
  border: 1px solid #bbccff;
  margin-bottom: 8px;
  flex-direction: column;
  padding: 12px 16px;
`;

const NewUiPreviewBanner = ({ isOpen }: { isOpen: boolean }) => {
  const { isPortalUIToggleEnabled, isPortalsEnabled, onRevertToOldUiClicked } =
    useContext(PortalsContext);

  if (!isPortalsEnabled || !isPortalUIToggleEnabled) {
    return null;
  }

  return (
    <BannerDiv isOpen={isOpen}>
      <div style={{ marginBottom: 4 }}>
        <Typography.Body type="Label">
          You are using our new layout
        </Typography.Body>
      </div>
      <Button
        size={'Small'}
        type={'Danger Secondary'}
        label={'Revert to the old layout'}
        onClick={onRevertToOldUiClicked}
      />
    </BannerDiv>
  );
};

export default NewUiPreviewBanner;
