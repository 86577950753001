import Typography from 'kingpin/atoms/Typography';
import React from 'react';

import styled from 'styled-components';
import Row from '../../../../../../components/Common/Row';
import { ReactComponent as WarningIcon } from './WarningIcon.svg';
import Colors2 from '../../../../../../theme/Colors2';

const WarningDiv = styled.div`
  background-color: ${Colors2.AvatarColors['3'].background};
  padding: 18px 16px;
  margin-bottom: 16px;
  border-radius: 6px;
`;

const UsersLosingAccessWarning = ({
  onExportListClicked,
  usersLosingAccess,
}: {
  onExportListClicked: () => void;
  usersLosingAccess: (
    | UserManagement.PendingUser
    | UserManagement.SignedUpUser
  )[];
}) => {
  const usersText = `${usersLosingAccess.length} user${
    usersLosingAccess.length > 1 ? 's' : ''
  }`;

  return (
    <WarningDiv>
      <Row>
        <div style={{ marginRight: 12 }}>
          <WarningIcon />
        </div>
        <div>
          <div style={{ marginBottom: 4 }}>
            <Typography.Header type="H5">
              {`${usersText} will lose access to FleetOps`}
            </Typography.Header>
          </div>
          <div>
            <Typography.Body type="Body 12">
              {`The groups you've added don't include ${usersText} who are already in the system.`}
            </Typography.Body>
          </div>
          <div style={{ marginTop: 4 }}>
            <Typography.Body type="Link" onClick={onExportListClicked}>
              Export list of missing users
            </Typography.Body>
          </div>
        </div>
      </Row>
    </WarningDiv>
  );
};

export default UsersLosingAccessWarning;
