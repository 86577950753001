import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import GlobalTasksContext from '../../contexts/Tasks/GlobalTasksContext';
import getTasksSnapshot from './getTasksSnapshot';
import AccountPickerContext from '../../contexts/AccountPickerContext';
import CurrentUserContext from '../../contexts/CurrentUserContext';

const GlobalTasksProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { isWallboardUser } = useContext(CurrentUserContext);
  const { accountRef } = useContext(AccountPickerContext);
  const [tasks, setTasks] = useState<Tasks.Task[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (isWallboardUser) {
      return;
    }

    const listener = getTasksSnapshot(accountRef).onSnapshot(
      (snapshot: any) => {
        const data: Tasks.Task[] = [];
        snapshot.docs.forEach((d: any) => data.push({ id: d.id, ...d.data() }));
        setTasks(_.sortBy(data, 'createdOn'));
        setIsLoading(false);
      },
    );
    return () => {
      listener();
    };
  }, [accountRef, isWallboardUser]);

  return (
    <GlobalTasksContext.Provider value={{ tasks, isLoading }}>
      {children}
    </GlobalTasksContext.Provider>
  );
};

export default GlobalTasksProvider;
