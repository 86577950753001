import React from 'react';

interface MetricsContextType {
  allMetrics: Metrics.NormalMetric[];
  isLoading: boolean;
}
const MetricsContext = React.createContext<MetricsContextType>({
  allMetrics: window.emptyArray,
  isLoading: true,
} as MetricsContextType);

export default MetricsContext;
