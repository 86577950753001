import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Typography from 'kingpin/atoms/Typography';
import Button from 'kingpin/atoms/Button';

import Colors2 from '../theme/Colors2';
import Row from './Common/Row';
import Badge from './Badge';
import AccessUsers from './AccessUsers';
import useFavouriteToggle from '../hooks/useFavouriteToggle';
import LinkWrapper from './LinkWrapper';
import ShowOnMouseOverUnlessTour from './ShowOnMouseOverUnlessTour';
import LastViewedAtContext from '../contexts/LastViewedAtContext';
import Tooltip from './Tooltip';
import { PortalsContext } from '../contextProviders/PortalsProvider';
import ColorDot from '../kingpin/navigation/PortalPicker/ColorDot';
import portalTypeCheckers from '../types/portalTypeCheckers';
import EntityDefinitionsContext from '../contexts/EntityDefinitionsContext';
import _ from 'lodash';

export const ListItemWrapper = styled.div`
  height: 48px;
  min-height: 48px;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid ${Colors2.Border};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8px;

  .showOnMouseOver {
    visibility: hidden;
  }

  &:hover {
    background-color: ${Colors2.Grey['9']};

    .showOnMouseOver {
      visibility: visible;
    }
  }
`;

const Left = styled.div`
  display: flex;
  flex: 5;
  flex-direction: row;
  align-items: center;
`;

const Right = styled.div`
  display: flex;
  flex: 4;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const FavouriteButton = ({
  isFavourite,
  onClick,
}: {
  isFavourite: boolean;
  onClick: (event: React.MouseEvent<any>) => void;
}) => {
  return (
    <Tooltip content={isFavourite ? 'Unfavorite' : 'Click to favorite'}>
      <Button
        size={'Small'}
        type={'Ghost'}
        onClick={onClick}
        icon={isFavourite ? 'start - filled' : 'star - empty'}
      />
    </Tooltip>
  );
};

export const ListHeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  border-radius: 8px;

  background-color: #f8f8f8;
  margin: 0px 28px 0px 24px;
  padding: 0px 8px;
`;

export const IndexListHeader = ({
  type,
}: {
  type: 'report' | 'dashboard' | 'wallboard' | 'workspace' | 'scorecard';
}) => {
  const { isPortalsEnabled } = useContext(PortalsContext);
  const getAccessLabel = useCallback(() => {
    if (!isPortalsEnabled) {
      return undefined;
    }

    if (type === 'report' || type === 'dashboard' || type === 'scorecard') {
      return 'Portal';
    }

    return undefined;
  }, [isPortalsEnabled, type]);
  const [accessLabel, setAccessLabel] = useState<string | undefined>(() =>
    getAccessLabel(),
  );
  useEffect(() => {
    setAccessLabel(getAccessLabel());
  }, [getAccessLabel]);

  return (
    <ListHeaderDiv>
      <Left>
        <div style={{ width: 32 }} />
        <Typography.Body type="Label">Name</Typography.Body>
      </Left>
      <Right>
        <Row style={{ flex: 1 }} />
        <div style={{ flex: 1 }}>
          {accessLabel && (
            <Typography.Body type="Label">{accessLabel}</Typography.Body>
          )}
        </div>
        <Row centerAlign spaceBetween style={{ flex: 1 }}>
          <Typography.Body type="Label">Last Viewed</Typography.Body>
          <div />
        </Row>
      </Right>
    </ListHeaderDiv>
  );
};

const PublishedOnPortals = ({
  portals,
}: {
  portals: (ExecutivePortal | EngagementPortal)[];
}) => {
  if (portals.length === 0) {
    return <Badge text={'Unpublished'} badgeType={'Default'} />;
  }
  if (portals.length === 1) {
    const portal = portals[0];
    const { name, color } = portal;
    return (
      <Badge
        text={name}
        badgeType={'Default'}
        iconBefore={
          <div style={{ marginRight: 8, display: 'flex' }}>
            <ColorDot color={color} width={8} height={8} />
          </div>
        }
      />
    );
  }

  return (
    <div style={{ display: 'flex' }}>
      <Tooltip content={portals.map((p) => p.name).join(', ')}>
        <Badge text={`${portals.length} Portals`} badgeType={'Default'} />
      </Tooltip>
    </div>
  );
};

const AccessColumn = ({
  typeId,
  type,
  access,
}: {
  typeId: string;
  type: 'report' | 'dashboard' | 'wallboard' | 'workspace' | 'scorecard';
  access?: ResourceAccess;
}) => {
  const { isPortalsEnabled, publishedLookup, availablePortals } =
    useContext(PortalsContext);
  const { entityDetailsApps } = useContext(EntityDefinitionsContext);

  const getUsedOnPortals = useCallback((): (
    | ExecutivePortal
    | EngagementPortal
  )[] => {
    const usedOnPortals: (ExecutivePortal | EngagementPortal)[] = [];

    if (type === 'report') {
      usedOnPortals.push(...(publishedLookup.reports[typeId] || []));
    }

    if (type === 'dashboard') {
      usedOnPortals.push(...(publishedLookup.dashboards[typeId] || []));
    }

    if (type === 'scorecard') {
      usedOnPortals.push(...(publishedLookup.scorecards[typeId] || []));
    }

    availablePortals
      .filter(portalTypeCheckers.isEngagementPortal)
      .forEach((p) => {
        p.entityLinks.forEach((e) => {
          const app = entityDetailsApps.find((a) => a.entityId === e.entityId);
          if (app) {
            app.tabs.forEach((tab) => {
              if (tab.type === type && tab.typeId === typeId) {
                usedOnPortals.push(p);
              }
            });
          }
        });
      });

    return _.uniqBy(usedOnPortals, 'id');
  }, [
    availablePortals,
    entityDetailsApps,
    publishedLookup.dashboards,
    publishedLookup.reports,
    publishedLookup.scorecards,
    type,
    typeId,
  ]);
  const [usedOnPortals, setUsedOnPortals] = useState<
    (EngagementPortal | ExecutivePortal)[]
  >(() => getUsedOnPortals());
  useEffect(() => {
    setUsedOnPortals(getUsedOnPortals());
  }, [getUsedOnPortals]);

  if (!isPortalsEnabled) {
    if (access) {
      return <AccessUsers access={access} />;
    }

    return null;
  }

  if (type === 'report') {
    return <PublishedOnPortals portals={usedOnPortals} />;
  }

  if (type === 'dashboard') {
    return <PublishedOnPortals portals={usedOnPortals} />;
  }

  if (type === 'scorecard') {
    return <PublishedOnPortals portals={usedOnPortals} />;
  }

  return null;
};

const IndexListItem = ({
  title,
  link,
  badges,
  access,
  contextMenu,
  afterName,
  type,
  typeId,
  isLastViewedAtDisabled,
}: {
  title: string;
  link: string;
  badges: {
    text: string;
  }[];
  access?: ResourceAccess;
  contextMenu?: JSX.Element;
  afterName?: JSX.Element;
  type: 'report' | 'dashboard' | 'wallboard' | 'workspace' | 'scorecard';
  typeId: string;
  isFirstItem?: boolean;
  isLastViewedAtDisabled?: boolean;
}) => {
  const { isFavourite, onFavouriteToggled } = useFavouriteToggle({
    type,
    typeId,
    name: title,
  });
  const { getLastViewedAt } = useContext(LastViewedAtContext);
  const lastViewedAt = getLastViewedAt(type, typeId);

  return (
    <ListItemWrapper>
      <Left>
        <div style={{ width: 32 }}>
          <ShowOnMouseOverUnlessTour>
            <Button
              size={'Small'}
              type={'Ghost'}
              icon={isFavourite ? 'start - filled' : 'star - empty'}
              onClick={onFavouriteToggled}
            />
          </ShowOnMouseOverUnlessTour>
        </div>

        <LinkWrapper>
          <Link to={link} className={`${type}-link`}>
            <Typography.Body type="Link">{title}</Typography.Body>
          </Link>
        </LinkWrapper>
        {!!afterName && <div style={{ marginLeft: 8 }}>{afterName}</div>}
      </Left>
      <Right>
        <Row style={{ flex: 1 }}>
          {badges.map((b) => (
            <div style={{ width: 150 }} key={b.text}>
              {b && <Badge text={b.text} badgeType="Default" />}
            </div>
          ))}
        </Row>
        <div style={{ flex: 1 }}>
          <AccessColumn type={type} typeId={typeId} access={access} />
        </div>

        <Row centerAlign spaceBetween style={{ flex: 1 }}>
          {!isLastViewedAtDisabled && (
            <Typography.Body type="Body 12">
              {lastViewedAt
                ? `${moment.utc(lastViewedAt).format("MMM DD 'YY")}`
                : '-'}
            </Typography.Body>
          )}
          {contextMenu}
        </Row>
      </Right>
    </ListItemWrapper>
  );
};

export default IndexListItem;
