import { DateTime } from 'luxon';
import STORE from '../../store';

const createMetricListGadget = async ({
  id,
  name,
  currentUserId,
  list,
  accountId,
}: {
  id: string;
  name: string;
  currentUserId: string;
  list?: MetricListGadgetType;
  accountId: string;
}) => {
  const newMetricList = list
    ? list
    : ({
        isBonusPeriodMode: false,
        id,
        type: 'Metric List',
        name,
        list: [],
        listOrder: [],
        relativeDateRange: {
          type: 'last',
          interval: 'month',
          n: 1,
          currentToDate: false,
        },
        dateField: 'date',
        createdBy: currentUserId,
        updatedBy: currentUserId,
        createdOn: DateTime.utc().toISO(),
        updatedOn: DateTime.utc().toISO(),
      } as MetricListGadgetType);

  return STORE.visualisations
    .getMetricListsRef({ accountId })
    .doc(newMetricList.id)
    .set(newMetricList);
};

export default createMetricListGadget;
