import React from 'react';

interface CanvasContextType {
  cardOnTopI?: string;
  setCardOnTopI: (i: string | undefined) => void;
  isEditing: boolean;
  canvas: Canvas;
  updateCard: (card: CanvasCard.Card) => void;
}

// @ts-ignore
const CanvasContext = React.createContext<CanvasContextType>({
  setCardOnTopI: () => {},
  updateCard: () => {},
} as CanvasContextType);

export default CanvasContext;
