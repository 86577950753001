import React, { useCallback, useEffect, useState } from 'react';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import aguid from 'aguid';
import useRowHighlighter from '../../../hooks/useRowHighlighter';
import useRowData from './hooks/useRowData';
import Grid from '../../Grid/Grid';
import useGridOptions from './hooks/useGridOptions';
import Loading from 'components/Loading/Loading';
import IncompatibleComparison from '../../IncompatibleComparison';
import useIsCompatibleComparison from '../../../hooks/useIsCompatableComparison';
import useChartDrillDowns from '../../../hooks/useChartDrillDowns';
import MetricMatrixContext from 'contexts/MetricMatrixContext';
import FlexCentered from '../../Common/FlexCentered';
import PaceCard from './PaceCard';

const PaceMatrixContentContainer = ({
  gadget,
  gridApi,
  setGridApi,
}: {
  gadget: VisualisationDefinitions.PaceMatrix;
  gridApi?: GridApi;
  setGridApi: React.Dispatch<React.SetStateAction<GridApi | undefined>>;
}) => {
  const [visId] = useState<string>(() => aguid());
  useRowHighlighter({
    gridApi,
    drillField: gadget.groupByField,
    visId,
  });
  const isCompatibleComparison = useIsCompatibleComparison(gadget);
  const {
    rowData,
    totalsRowData,
    isLoadingData,
    getActualDateRange,
    getAverageDateRange,
    getExcludedDateRangeLabels,
  } = useRowData({
    paceMatrix: gadget,
  });
  const { gridOptions, colDefs } = useGridOptions({
    paceMatrix: gadget,
    getActualDateRange,
    getAverageDateRange,
    getExcludedDateRangeLabels,
  });
  const { onDrillDown } = useChartDrillDowns(
    gadget.groupByField,
    undefined,
    'matrix',
  );

  const onGridReady = useCallback(
    (event: GridReadyEvent) => {
      setGridApi(event.api);
    },
    [setGridApi],
  );

  useEffect(() => {
    if (!gridApi || gridApi.isDestroyed()) {
      return;
    }

    gridApi.setGridOption('columnDefs', colDefs);
  }, [colDefs, gridApi]);

  useEffect(() => {
    if (!gridApi || gridApi.isDestroyed()) {
      return;
    }

    if (isLoadingData) {
      gridApi.showLoadingOverlay();
    } else {
      gridApi.hideOverlay();
    }
  }, [gridApi, isLoadingData]);

  if (!isCompatibleComparison) {
    return <IncompatibleComparison />;
  }

  if (!gridOptions || isLoadingData) {
    return (
      <FlexCentered style={{ height: '100%' }}>
        <Loading />
      </FlexCentered>
    );
  }

  if (gadget.isCardMode) {
    return (
      <PaceCard
        totalsRow={totalsRowData}
        paceMatrix={gadget}
        getActualDateRange={getActualDateRange}
        getAverageDateRange={getAverageDateRange}
        getExcludedDateRangeLabels={getExcludedDateRangeLabels}
      />
    );
  }

  return (
    <MetricMatrixContext.Provider
      value={{
        onDrillDown,
      }}
    >
      <Grid
        columnDefs={colDefs}
        onGridReady={onGridReady}
        rowData={rowData}
        gridOptions={gridOptions}
        gridId={visId}
        hasBorders={false}
        hasDarkHeader={false}
        totalsRow={totalsRowData}
      />
    </MetricMatrixContext.Provider>
  );
};

export default PaceMatrixContentContainer;
