const CRUD_SCORECARD: FleetOps.Permission =
  'fleetops.permissions.scorecard_management_crud';
const MANUAL_KPI_ENTRY: FleetOps.Permission =
  'fleetops.permissions.scorecard_manual_kpi_entry';

const SCORECARD_MANAGEMENT_PERMISSIONS = {
  CRUD_SCORECARD,
  MANUAL_KPI_ENTRY,
};
Object.freeze(SCORECARD_MANAGEMENT_PERMISSIONS);

export default SCORECARD_MANAGEMENT_PERMISSIONS;
