export const PROGRESS_CELL_FIELD = 'Progress';
export const CONTRACT_PROGRESS_CELL_FIELD = 'Contract Progress';
export const ON_TARGET_CELL_FIELD = 'On Target';
export const CONTRACT_ON_TARGET_CELL_FIELD = 'Contract On Target';
export const SPECIAL_COMMITMENT_FIELDS = [
  CONTRACT_ON_TARGET_CELL_FIELD,
  CONTRACT_PROGRESS_CELL_FIELD,
  ON_TARGET_CELL_FIELD,
  PROGRESS_CELL_FIELD,
];

export const PROGRESS_CELL_TYPE = 'Progress';

export const RECORDS_QUERY = 'fetchRecords';
export const COUNT_QUERY = 'countRecords';
export const TOTALS_QUERY = 'fetchTotals';
export const TOTALS_GROUPED_BY_TERM_QUERY = 'fetchTotalsGroupedByTerm';
