import Row from '../Common/Row';
import Typography from 'kingpin/atoms/Typography';
import Toggle from '../Inputs/Toggle';
import React from 'react';

const FilterToggle = ({
  label,
  isV2FilterOn,
  onIsV2FilterOnChanged,
}: {
  label?: string;
  isV2FilterOn: boolean;
  onIsV2FilterOnChanged: (newStatus: boolean) => void;
}) => {
  return (
    <Row centerAlign>
      <div style={{ marginRight: 8 }}>
        <Typography.Body type="Body 12">
          {label ? label : 'Show all'}
        </Typography.Body>
      </div>

      <Toggle value={isV2FilterOn} onChange={onIsV2FilterOnChanged} />
    </Row>
  );
};

export default FilterToggle;
