import isV5ChartDef from './isV5ChartDef';

const isPaceMatrix = (
  def?: ExtendedVisualisationDefinition,
): def is VisualisationDefinitions.PaceMatrix => {
  if (isV5ChartDef(def)) {
    return false;
  }

  return !!def && def.type && def.type === 'PaceMatrix';
};

export default isPaceMatrix;
