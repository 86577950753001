import { useCallback, useContext, useEffect, useState } from 'react';
import GqlClientContext from 'contexts/GqlClientContext';
import getCostCategories from '../../api/getCostCategories';

const useCategories = () => {
  const { client } = useContext(GqlClientContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [allCategories, setAllCategories] = useState<Costs.CostCategoryField[]>(
    [],
  );

  useEffect(() => {
    let isActive = true;
    getCostCategories(client).then((newCategories) => {
      if (isActive) {
        setAllCategories(newCategories);
        setIsLoading(false);
      }
    });
    return () => {
      isActive = false;
    };
  }, [client]);

  const toName = useCallback(
    ({ fieldName }: { fieldName: string }) => {
      const category = allCategories.find((cat) => cat.fieldName === fieldName);
      return category ? category.name : '';
    },
    [allCategories],
  );

  return { allCategories, toName, isLoading };
};

export default useCategories;
