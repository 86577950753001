import React from 'react';
import Button from '../../../../kingpin/atoms/Button';
import usePopup from '../../../../hooks/usePopup';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import AddFieldForm from './AddFieldForm';

const AddFieldButton = () => {
  const { isOpen, open, close } = usePopup();

  return (
    <>
      <Button type="Tertiary" size="Small" onClick={open} label={'Add Field'} />
      <ModalTransition>
        {isOpen && (
          <Modal>
            <AddFieldForm close={close} />
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};

export default AddFieldButton;
