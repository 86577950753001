import { DateTime } from 'luxon';
import firebase from 'firebase/compat/app';
import { buildContentViewIdAlt } from '../buildContentViewId';

const markItemAsFavourite = async ({
  type,
  typeId,
  name,
  userId,
  accountRef,
}: {
  type:
    | 'report'
    | 'dashboard'
    | 'wallboard'
    | 'workspace'
    | 'scorecard'
    | 'targetsApp';
  typeId: string;
  name: string;
  userId: string;
  accountRef: firebase.firestore.DocumentReference;
}) => {
  return accountRef
    .collection('users')
    .doc(userId)
    .collection('favourites')
    .doc(buildContentViewIdAlt({ typeId, type }))
    .set({
      version: '1',
      type,
      typeId,
      name,
      createdOn: DateTime.utc().toISO(),
    });
};

export default markItemAsFavourite;
