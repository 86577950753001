import isCustomRangeInput from '../../isCustomRangeInput';

const buildGroupByInterval = (
  def: V5ChartDefinition,
): V5Histogram | undefined => {
  if (
    def.dimensionA &&
    def.dimensionA.rangeInput &&
    !isCustomRangeInput(def.dimensionA.rangeInput)
  ) {
    return {
      field: def.dimensionA.field,
      interval: def.dimensionA.rangeInput.fixed,
    };
  }

  if (
    def.dimensionB &&
    def.dimensionB.rangeInput &&
    !isCustomRangeInput(def.dimensionB.rangeInput)
  ) {
    return {
      field: def.dimensionB.field,
      interval: def.dimensionB.rangeInput.fixed,
    };
  }
  return undefined;
};

export default buildGroupByInterval;
