import React from 'react';

interface PerformanceWizardContextType {
  wizardState: PerformanceConfiguration.WizardState;
  setWizardState: React.Dispatch<
    React.SetStateAction<PerformanceConfiguration.WizardState>
  >;
  originalConfig?: PerformanceConfiguration.WizardState;
  isSaving: boolean;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
  isReadOnly: boolean;
  close: () => void;
}

const PerformanceWizardContext =
  React.createContext<PerformanceWizardContextType>({
    // @ts-ignore
    wizardState: undefined,
    setWizardState: () => {
      const e = new Error('');
      e.name = 'setWizardState is not defined';
      throw e;
    },
    isSaving: true,
    isReadOnly: false,
    close: () => {
      const e = new Error('');
      e.name = 'close is not defined';
      throw e;
    },
  });

export default PerformanceWizardContext;
