import { useCallback, useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import withMetricDefs from '../withMetricDefs';
import MetricOptionsContext from '../contexts/MetricOptionsContext';
import metricTypeCheckers from '../types/metricTypeCheckers';
import isDefined from '../isDefined';
import CompoundMetricsContext from '../contexts/CompoundMetricsContext';

const useDataTypesFromSeriesAndMetricListItems = (
  series: (V5ChartDefinitionSeries | MetricListItemType)[],
) => {
  const { metricOptionsLookup } = useContext(MetricOptionsContext);
  const { getCompoundMetricWithMetricDefs } = useContext(
    CompoundMetricsContext,
  );
  const [dataTypes, setDataTypes] = useState<string[]>([]);

  useEffect(() => {
    const newDataTypes = [] as string[];
    const metricsInUse = series
      .map((s) => metricOptionsLookup[s.metricId])
      .filter(isDefined)
      .filter(metricTypeCheckers.isNormalMetric);
    const compoundMetricsInUse = series
      .map((s) => metricOptionsLookup[s.metricId])
      .filter(isDefined)
      .filter(metricTypeCheckers.isCompoundMetric);
    const specialMetricsInUse = series
      .map((s) => metricOptionsLookup[s.metricId])
      .filter(isDefined)
      .filter(metricTypeCheckers.isSpecialMetric);
    const compoundMetricsInUseWithMetricDefs = compoundMetricsInUse
      .map((m) => getCompoundMetricWithMetricDefs(m.id))
      .filter(isDefined);

    const dateFieldsInMetrics = metricsInUse.map((m) => m.dataType);
    newDataTypes.push(...dateFieldsInMetrics);

    const specialMetricDataTypes = specialMetricsInUse.map((m) => m.dataType);
    newDataTypes.push(...specialMetricDataTypes);

    compoundMetricsInUseWithMetricDefs.forEach((cM) => {
      const dts = cM.metrics.map((m) => m.dataType);
      newDataTypes.push(...dts);
    });

    setDataTypes(_.uniq(newDataTypes));
  }, [getCompoundMetricWithMetricDefs, metricOptionsLookup, series]);

  return dataTypes;
};

export const useDataTypesFromMetricIds = (metricIds: string[] | undefined) => {
  const { metricOptionsLookup } = useContext(MetricOptionsContext);
  const { getCompoundMetricWithMetricDefs } = useContext(
    CompoundMetricsContext,
  );

  const getDataTypes = useCallback(() => {
    if (!metricIds) {
      return window.emptyArray;
    }

    const newDataTypes = [] as string[];
    const metricsInUse = metricIds
      .map((mid) => metricOptionsLookup[mid])
      .filter(isDefined)
      .filter(metricTypeCheckers.isNormalMetric);
    const compoundMetricsInUse = metricIds
      .map((mid) => metricOptionsLookup[mid])
      .filter(isDefined)
      .filter(metricTypeCheckers.isCompoundMetric);
    const specialMetricsInUse = metricIds
      .map((mid) => metricOptionsLookup[mid])
      .filter(isDefined)
      .filter(metricTypeCheckers.isSpecialMetric);
    const compoundMetricsInUseWithMetricDefs = compoundMetricsInUse
      .map((m) => getCompoundMetricWithMetricDefs(m.id))
      .filter(isDefined);

    const dateFieldsInMetrics = metricsInUse.map((m) => m.dataType);
    newDataTypes.push(...dateFieldsInMetrics);

    const specialMetricDataTypes = specialMetricsInUse.map((m) => m.dataType);
    newDataTypes.push(...specialMetricDataTypes);

    compoundMetricsInUseWithMetricDefs.forEach((cM) => {
      const dts = cM.metrics.map((m) => m.dataType);
      newDataTypes.push(...dts);
    });

    return _.uniq(newDataTypes) as string[];
  }, [getCompoundMetricWithMetricDefs, metricIds, metricOptionsLookup]);

  const [dataTypes, setDataTypes] = useState<string[]>(() => getDataTypes());

  useEffect(() => {
    setDataTypes(getDataTypes());
  }, [getDataTypes]);

  return dataTypes;
};

export const useGetDataTypesFromMetricId = () => {
  const { metricOptions, normalMetrics } = useContext(MetricOptionsContext);

  const getDataTypesFromMetricId = useCallback(
    (metricId?: string) => {
      const metricInUse = normalMetrics.find((m) => m.id === metricId);
      const compoundMetricInUse = metricOptions
        .filter(metricTypeCheckers.isCompoundMetric)
        .find((m) => m.id === metricId);
      const specialMetricInUse = metricOptions
        .filter(metricTypeCheckers.isSpecialMetric)
        .find((m) => m.id === metricId);

      if (metricInUse) {
        return [metricInUse.dataType];
      } else if (compoundMetricInUse) {
        const compoundWithMetrics = withMetricDefs(
          compoundMetricInUse,
          normalMetrics,
        );
        return _.uniq(compoundWithMetrics.metrics.map((m) => m.dataType));
      } else if (specialMetricInUse) {
        return [specialMetricInUse.dataType];
      } else {
        return window.emptyArray;
      }
    },
    [metricOptions, normalMetrics],
  );

  return getDataTypesFromMetricId;
};

export const useDataTypesFromMetricId = (metricId?: string) => {
  const { metricOptions, normalMetrics } = useContext(MetricOptionsContext);
  const [dataTypes, setDataTypes] = useState<string[]>([]);
  const getDataTypesFromMetricId = useGetDataTypesFromMetricId();

  useEffect(() => {
    setDataTypes(getDataTypesFromMetricId(metricId));
  }, [getDataTypesFromMetricId, metricId, metricOptions, normalMetrics]);

  return dataTypes;
};

export default useDataTypesFromSeriesAndMetricListItems;
