import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Buttons from './Buttons';
import WidgetGalleryContext from '../../../contexts/WidgetGalleryContext';

const ButtonsContainer = ({ style }) => {
  const {
    close,
    addToReport,
    saving,
    selectedDefinitions,
    isDashboard,
    startConfigureDashboardGadget,
  } = useContext(WidgetGalleryContext);

  return (
    <Buttons
      style={style}
      cancel={close}
      addToReport={addToReport}
      saving={saving}
      selectedCount={selectedDefinitions.length}
      isDashboard={isDashboard}
      startConfigureDashboardGadget={startConfigureDashboardGadget}
    />
  );
};

ButtonsContainer.propTypes = {
  style: PropTypes.object,
};

export default ButtonsContainer;
