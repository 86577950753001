import aguid from 'aguid';
import { DateTime } from 'luxon';

const unAssignedFromTask = ({
  task,
  link,
}: {
  task: Tasks.Task;
  link: string;
}): Notifications.BasicNotification => ({
  id: aguid(),
  isBasic: true,
  type: 'REMOVED_FROM_TASK',
  seen: false,
  read: false,
  deleted: false,
  createdOn: DateTime.utc().toISO(),
  emailSent: false,
  link,
  inAppSubject: `You are no longer assigned to the task`,
  inAppBody: `${task.title}`,
  inAppLinkText: 'View task',
  emailSubject: `You are no longer assigned to a task on FleetOps`,
  emailBodyLn1: `You are no longer assigned to the task - ${task.title}`,
  emailBodyLn2: task.description,
  emailLinkText: 'Log in to view task',
});

export default unAssignedFromTask;
