import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import Typography from 'kingpin/atoms/Typography';

import Tooltip from '../../../Tooltip';
import Colors from 'theme/colors';
import Card from '../../../Common/Card';
import Row from '../../../Common/Row';
import { Wrapper } from './styles';
import getColor from './getColor';
import Column from './Column';
import Menu from './Menu';
import Flexed from './Flexed';
import { UserIconFromId } from '../../../Common/UserIcon';
import Colors2 from '../../../../theme/Colors2';
import ProgressBarContainer from './Visualisation/ProgressBar';
import AreaChartContainer from './Visualisation/AreaChart';
import FlexCentered from '../../../Common/FlexCentered';
import Loading from 'components/Loading';
import GeneralGoalContext from '../../../../contexts/GeneralGoalContext';
import PermissionGates from '../../../PermissionGates';
import PERMISSIONS from '../../../../permissionDefinitions';
import Icon from '../../../../kingpin/atoms/Icon';

interface IndicatorProps {
  backgroundColor: string;
}
const Indicator = styled.div<IndicatorProps>`
  min-height: 78px;
  width: 2px;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 3px;
`;

const DragWrapper = styled.div`
  position: absolute;
  height: 86px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:hover {
    background-color: ${Colors.BLUE_GREY};
  }
`;

const DragHandle = ({
  dragHandleProps,
}: {
  dragHandleProps: DraggableProvidedDragHandleProps;
}) => {
  return (
    <DragWrapper
      className={'showOnMouseOver'}
      onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
        event.stopPropagation()
      }
      {...dragHandleProps}
    >
      <Icon icon="drag" />
    </DragWrapper>
  );
};

export const Vis = ({
  isCard,
  setTimeText,
  setTimeTooltip,
  noPadding,
  setIsAboveTarget,
}: {
  isPrimaryCumulative?: boolean;
  isCard?: boolean;
  setTimeText?: (text: string) => void;
  setTimeTooltip?: (text: string) => void;
  noPadding?: boolean;
  setIsAboveTarget?: (b: boolean) => void;
}) => {
  const { isPrimaryCumulative } = useContext(GeneralGoalContext);
  if (isPrimaryCumulative === undefined) {
    if (isCard) {
      return (
        <div
          style={{
            height: '100%',
            width: '100%',
          }}
        >
          <Row centerAlign style={{ marginBottom: 16 }}>
            <Typography.Header type="H5">Goal Progress</Typography.Header>
          </Row>
          <FlexCentered style={{ height: 'calc(100% - 38px)', marginTop: 32 }}>
            <Flexed flex={1}>
              <Loading isSmall />
            </Flexed>
          </FlexCentered>
        </div>
      );
    } else {
      return (
        <FlexCentered style={{ height: '100%' }}>
          <Flexed flex={7}>
            <Loading isSmall />
          </Flexed>
        </FlexCentered>
      );
    }
  } else if (isPrimaryCumulative) {
    return (
      <ProgressBarContainer
        setTimeText={setTimeText}
        setTimeTooltip={setTimeTooltip}
        noPadding={noPadding}
        setIsAboveTarget={setIsAboveTarget}
        isCard={isCard}
      />
    );
  } else {
    return (
      <AreaChartContainer
        isCard={isCard}
        setTimeText={setTimeText}
        setTimeTooltip={setTimeTooltip}
        noPadding={noPadding}
        setIsAboveTarget={setIsAboveTarget}
      />
    );
  }
};

const GeneralGoalListItem = ({
  subTitle,
  goal,
  targetFormatted,
  timeText,
  timeTooltip,
  setTimeText,
  setTimeTooltip,
  isInReport,
  isAboveTarget,
  setIsAboveTarget,
  dragHandleProps,
  link,
}: {
  subTitle: string;
  goal: GeneralGoal;
  targetFormatted: string;
  timeText?: string;
  timeTooltip?: string;
  setTimeText: (text: string) => void;
  setTimeTooltip: (text: string) => void;
  isInReport?: boolean;
  isAboveTarget: boolean | undefined;
  setIsAboveTarget: (b: boolean) => void;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
  link?: string;
}) => (
  <React.Fragment>
    <Card
      style={{
        marginBottom: 14,
        width: '100%',
      }}
    >
      <Wrapper style={{ minHeight: 80 }}>
        {isAboveTarget !== undefined && (
          <Indicator
            backgroundColor={getColor(isAboveTarget, goal.aboveTargetIsGood)}
          />
        )}
        {!isInReport && dragHandleProps && (
          <DragHandle dragHandleProps={dragHandleProps} />
        )}
        <Flexed flex={5} style={{ justifyContent: 'space-around' }}>
          <Column>
            <>
              {!!link && (
                <Link to={link}>
                  <Typography.Header type={'H5'} color={Colors2.Secondary.info}>
                    {goal.title}
                  </Typography.Header>
                </Link>
              )}
              {!link && (
                <Typography.Header type={'H5'}>{goal.title}</Typography.Header>
              )}
            </>
          </Column>
          <Column>
            <Typography.Header type={'H5'} color={Colors2.Grey['5']}>
              {subTitle}
            </Typography.Header>
          </Column>
        </Flexed>
        <Vis
          setTimeText={setTimeText}
          setTimeTooltip={setTimeTooltip}
          setIsAboveTarget={setIsAboveTarget}
        />
        <Flexed flex={2}>
          <Tooltip content={timeTooltip}>
            <React.Fragment>
              <Column>
                <Typography.Body type={'Body 12'}>
                  <b>{targetFormatted}</b>
                </Typography.Body>
              </Column>
              <Column>
                <Typography.Header type={'H5'} color={Colors2.Grey['5']}>
                  {timeText}
                </Typography.Header>
              </Column>
            </React.Fragment>
          </Tooltip>
        </Flexed>
        <Flexed flex={1}>
          <UserIconFromId userId={goal.accountableId} />
        </Flexed>
        <Flexed flex={3}>
          <Row
            style={{
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <PermissionGates.Has
              requiredPermission={PERMISSIONS.GOALS.CRUD_GOALS}
            >
              <Menu goal={goal} />
            </PermissionGates.Has>
          </Row>
        </Flexed>
      </Wrapper>
    </Card>
  </React.Fragment>
);

export default GeneralGoalListItem;
