import React, { useCallback, useContext, useEffect, useState } from 'react';

import MetricListContent from './MetricListContent';
import PeriodsContext from '../../../contexts/PeriodsContext';
import BonusPeriodsContext from '../../../contexts/BonusPeriodsContext';
import WorkSpaceContext from '../../../contexts/WorkSpaceContext';
import useDataTypesFromSeriesAndMetricListItems from '../../../hooks/useDataTypesFromSeriesAndMetricListItems';
import DateInputContext from '../../../contexts/DateInputContext';
import Loading from '../../Loading';

export const MetricListContentContainer = ({
  metricListGadget,
}: {
  metricListGadget: MetricListGadgetType;
}) => {
  const { list, listOrder } = metricListGadget;
  const { selectedPeriod } = useContext(PeriodsContext);
  const { selectedBonusPeriod } = useContext(BonusPeriodsContext);
  const { workSpace } = useContext(WorkSpaceContext);
  const dataTypes = useDataTypesFromSeriesAndMetricListItems(
    metricListGadget.list,
  );
  const [isDateInitialised, setIsDateInitialised] = useState<boolean>(false);
  const {
    setDataTypes,
    setDateField,
    setDateRange,
    setRelativeDateRange,
    setAdvancedRelativeDateRange,
  } = useContext(DateInputContext);

  const getDateRange = useCallback(() => {
    if (selectedPeriod) {
      return {
        startDate: selectedPeriod.startDate,
        endDate: selectedPeriod.endDate,
      };
    }

    if (
      !!workSpace &&
      workSpace.campaignType === 'driverBonus' &&
      selectedBonusPeriod
    ) {
      return {
        startDate: selectedBonusPeriod.startDate,
        endDate: selectedBonusPeriod.endDate,
      };
    } else if (metricListGadget.isBonusPeriodMode && selectedBonusPeriod) {
      return {
        startDate: selectedBonusPeriod.startDate,
        endDate: selectedBonusPeriod.endDate,
      };
    } else {
      return metricListGadget.dateRange;
    }
  }, [
    selectedPeriod,
    workSpace,
    selectedBonusPeriod,
    metricListGadget.isBonusPeriodMode,
    metricListGadget.dateRange,
  ]);

  useEffect(() => {
    setDateRange(getDateRange());
    setRelativeDateRange(metricListGadget.relativeDateRange);
    setAdvancedRelativeDateRange(metricListGadget.advancedRelativeDateRange);
    setDateField(metricListGadget.dateField);
    setIsDateInitialised(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDataTypes(dataTypes);
  }, [dataTypes, setDataTypes]);

  const orderedMetricList = listOrder
    .map((id) => {
      return list.find((metric) => metric.id === id);
    })
    .filter((m) => !!m) as MetricListItemType[];

  const { comparison } = metricListGadget;

  if (!isDateInitialised) {
    return <Loading />;
  }

  return (
    <MetricListContent
      orderedMetricList={orderedMetricList}
      comparison={comparison}
      isBonusPeriodMode={metricListGadget.isBonusPeriodMode}
      bonusPeriodId={selectedBonusPeriod ? selectedBonusPeriod.id : undefined}
    />
  );
};

export default MetricListContentContainer;
