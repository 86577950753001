import React from 'react';
import NavItem from '../../NavItem';
import NavSection from '../../NavSection';
import { executivePortalRouteBuilders } from '../../../../navigation/portalRoutes/executivePortalRoutes';
import PERMISSIONS from '../../../../permissionDefinitions';
import PermissionGates from 'components/PermissionGates';

const ExecutivePortalNavSection = ({ portal }: { portal: ExecutivePortal }) => {
  const hasReports = portal.reportIds.length > 0;
  const hasScorecards = portal.scorecardIds.length > 0;

  return (
    <>
      <NavSection label="View">
        <>
          <NavItem
            to={executivePortalRouteBuilders(portal.urlSlug).dashboardsIndex}
            label={'Dashboards'}
            tooltip={'Dashboards'}
            icon={'dashboard'}
            onClickEvent={'Navigation Sidebar - Clicked Dashboards'}
          />
          {hasReports && (
            <NavItem
              to={executivePortalRouteBuilders(portal.urlSlug).reportsIndex}
              label={'Reports'}
              tooltip={'Reports'}
              icon={'find-in-page'}
              onClickEvent={'Navigation Sidebar - Clicked Reports'}
            />
          )}
          {hasScorecards && (
            <NavItem
              to={executivePortalRouteBuilders(portal.urlSlug).scorecardsIndex}
              label={'KPI Lists'}
              tooltip={'KPI Lists'}
              icon={'navigation-goals'}
              onClickEvent={'Navigation Sidebar - Clicked KPI Lists'}
            />
          )}
        </>
      </NavSection>
      <PermissionGates.Has
        requiredPermission={PERMISSIONS.CONTENT_ACCESS.ADD_USERS_TO_PORTAL}
      >
        <NavSection label="Build">
          <NavItem
            to={executivePortalRouteBuilders(portal.urlSlug).users}
            label={'Users'}
            icon={'group'}
            tooltip="Users"
          />
        </NavSection>
      </PermissionGates.Has>
    </>
  );
};

export default ExecutivePortalNavSection;
