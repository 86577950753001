import React from 'react';
import Button from 'kingpin/atoms/Button';

const DashboardGadgetReportLinkButton = ({
  onClick,
  testId,
}: {
  onClick?: () => void;
  testId: string;
}) => (
  <div style={{ marginRight: 8 }}>
    <Button
      icon="chart-outlined"
      onClick={onClick}
      testId={testId}
      type="Ghost"
      size="Small"
    />
  </div>
);

export default DashboardGadgetReportLinkButton;
