import React, { useCallback, useContext } from 'react';
import AnalyticsContext from 'contexts/AnalyticsContext';
import getIdentifier from 'getIdentifier';
import Colors2 from 'theme/Colors2';
import Button from 'kingpin/atoms/Button';

import TargetFormContext from '../contexts/TargetFormContext';
import { GROUPS_SCROLLER_ID } from '../constants';
import useGetInitialCategories from '../hooks/useGetInitialCategories';

const AddRuleButton = () => {
  const {
    setGroups,
    isTargetedByGroup,
    nextGroupColorIndex,
    setNextGroupColorIndex,
  } = useContext(TargetFormContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const getInitialCategories = useGetInitialCategories();

  const onAddRuleClicked = useCallback(() => {
    trackEvent('Targets - Add Rule clicked');
    setGroups((groups) => [
      {
        key: getIdentifier(undefined, true),
        isFallback: false,
        targets: [
          {
            rules: [],
            key: getIdentifier(undefined, true),
            isFallback: true,
            categories: getInitialCategories(),
            outputs: [],
          },
        ],
        colorIndex: nextGroupColorIndex,
      },
      ...groups,
    ]);
    setNextGroupColorIndex(
      (c) => (c + 1) % Object.values(Colors2.AvatarColors).length,
    );

    const scrollerElem = document.getElementById(GROUPS_SCROLLER_ID);
    if (scrollerElem) {
      scrollerElem.scrollTop = 0;
    }
  }, [
    getInitialCategories,
    nextGroupColorIndex,
    setGroups,
    setNextGroupColorIndex,
    trackEvent,
  ]);

  return (
    <>
      {isTargetedByGroup && (
        <Button
          type="Tertiary"
          size="Small"
          onClick={onAddRuleClicked}
          label={'Add Rule'}
        />
      )}
    </>
  );
};

export default AddRuleButton;
