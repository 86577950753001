import aguid from 'aguid';
import { DateTime } from 'luxon';

const assignedToTask = ({
  taskCreatorName,
  task,
  link,
}: {
  taskCreatorName: string;
  task: Tasks.Task;
  link: string;
}): Notifications.BasicNotification => ({
  id: aguid(),
  isBasic: true,
  type: 'ASSIGNED_TASK',
  seen: false,
  read: false,
  deleted: false,
  createdOn: DateTime.utc().toISO(),
  emailSent: false,
  link,
  inAppSubject: `${taskCreatorName} assigned you to a task`,
  inAppBody: `${task.title}`,
  inAppLinkText: 'View Task',
  emailSubject: `${taskCreatorName} assigned you a task on FleetOps`,
  emailBodyLn1: `${taskCreatorName} assigned you to the task:`,
  emailBodyLn1Extra: task.title,
  emailBodyLn2: `'${task.description}'`,
  emailLinkText: 'Log in to view task',
});

export default assignedToTask;
