import { useContext, useEffect, useState } from 'react';
import { useDataTypesFromMetricIds } from '../../../../../hooks/useDataTypesFromSeriesAndMetricListItems';
import DatasetDefinitionsContext from '../../../../../contexts/DatasetDefinitionsContext';
import MetricOptionsContext from '../../../../../contexts/MetricOptionsContext';
import BaseViewsContext from '../../../../../contexts/BaseViewsContext';

const useAllowedDatasets = (constraints: MetricPickerOptionConstraints) => {
  const datasetsFromMetricIds = useDataTypesFromMetricIds(
    constraints.datasetMustBeSharedWithMetricIds,
  );
  const { datasetMetricOptions } = useContext(MetricOptionsContext);
  const { datasets } = useContext(DatasetDefinitionsContext);
  const { baseViews } = useContext(BaseViewsContext);

  const [allowedDatasets, setAllowedDatasets] = useState<
    FleetOps.DatasetDefinition[]
  >([]);

  useEffect(() => {
    const newSets = datasets
      .filter((dataset) => {
        if (constraints.datasetMustBeSharedWithMetricIds) {
          return datasetsFromMetricIds.includes(dataset.type);
        }

        return true;
      })
      .filter((dataset) => {
        if (constraints.datasetMustIncludeField) {
          return dataset.fields.some(
            (f) => f.field === constraints.datasetMustIncludeField,
          );
        }

        return true;
      })
      .filter((dataset) => {
        if (!constraints.hideDatasetsWithNoMetrics) {
          return true;
        }

        const metricsForDataset = datasetMetricOptions[dataset.type];
        if (!metricsForDataset) {
          return false;
        }

        return (
          metricsForDataset.metrics.length > 0 ||
          metricsForDataset.compoundMetrics.length > 0 ||
          metricsForDataset.specialMetrics.length > 0
        );
      })
      .filter((dataset) => {
        if (!constraints.datasetRedList) {
          return true;
        }

        return !constraints.datasetRedList.includes(dataset.type);
      })
      .filter((dataset) => {
        const bv = baseViews[dataset.type];
        if (!bv) {
          return true;
        }
        return !bv.isHidden;
      });

    setAllowedDatasets(newSets);
  }, [
    baseViews,
    constraints.datasetMustBeSharedWithMetricIds,
    constraints.datasetMustIncludeField,
    constraints.datasetRedList,
    constraints.hideDatasetsWithNoMetrics,
    datasetMetricOptions,
    datasets,
    datasetsFromMetricIds,
  ]);

  return allowedDatasets;
};

export default useAllowedDatasets;
