import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LegacyAuthenticatedSwitch from './LegacyAuthenticatedSwitch';

const AdminPortalSwitch = () => {
  return (
    <Routes>
      <Route path="*" element={<LegacyAuthenticatedSwitch />} />
    </Routes>
  );
};

export default AdminPortalSwitch;
