import React, { useCallback } from 'react';
import styled from 'styled-components';
import Colors2 from '../../../theme/Colors2';
import Typography from 'kingpin/atoms/Typography';
import useUser from '../../../hooks/useUser';
import userTypeCheckers from '../../../contextProviders/UsersProvider/userTypeCheckers';
import moment from 'moment';
import Option from './Option';

const InfoDiv = styled.div``;

const Divider = styled.div`
  background-color: ${Colors2.GridBorder};
  margin: 8px;
  height: 1px;
`;

const Section = styled.div`
  padding: 8px;
`;

const InfoSection = ({
  label,
  person,
  date,
}: {
  label: string;
  person: string;
  date: string;
}) => (
  <Section>
    <div>
      <Typography.Body type={'Body 12'} color="#8E8E8E">
        {label}
      </Typography.Body>
    </div>
    <div>
      <Typography.Body type={'Body 12'} color="#8E8E8E">
        {person}
      </Typography.Body>
    </div>
    <div>
      <Typography.Body type={'Body 12'} color="#8E8E8E">
        {date}
      </Typography.Body>
    </div>
  </Section>
);

const InfoInner = ({
  createdBy,
  updatedBy,
  updatedOn,
  createdOn,
}: TimeStamped) => {
  const { user: createdByUser } = useUser(createdBy);
  const { user: updatedByUser } = useUser(updatedBy);

  const getName = useCallback((user: UserManagement.User | undefined) => {
    if (!user) {
      return 'NA';
    }

    if (userTypeCheckers.isSignedUpOrPendingUser(user)) {
      return user.displayName;
    }

    if (userTypeCheckers.isFleetOpsStaffUser(user)) {
      return 'FleetOps Staff';
    }

    return 'NA';
  }, []);

  const getDate = useCallback((date: string) => {
    return moment(date).format('MMM DD, YY @ HH:mm');
  }, []);

  return (
    <InfoDiv>
      <InfoSection
        label="Created By"
        person={getName(createdByUser)}
        date={getDate(createdOn)}
      />
      <Divider />
      <InfoSection
        label="Updated By"
        person={getName(updatedByUser)}
        date={getDate(updatedOn)}
      />
    </InfoDiv>
  );
};

const Info = (timestamped: TimeStamped) => {
  return (
    <Option
      label={'Info'}
      subMenu={<InfoInner {...timestamped} />}
      subMenuPlacement="left"
    />
  );
};

export default Info;
