import React from 'react';

interface ContextMenuContextType {
  closeMenu: () => void;
  hideMenu: () => void;
}

const ContextMenuContext = React.createContext<ContextMenuContextType>({
  closeMenu: () => {},
  hideMenu: window.tokenFunction,
});

export default ContextMenuContext;
