// Layout of this screen is a bit awkward. We need some conditional margin.
// This component drives that margin.
const AdditionalContentGate = ({
  successMessage,
  isShared,
  children,
}: {
  successMessage?: string;
  isShared: boolean;
  children: JSX.Element | JSX.Element[];
}) => {
  if (!(isShared || successMessage)) {
    return null;
  }
  return <div style={{ marginTop: 24 }}>{children}</div>;
};

export default AdditionalContentGate;
