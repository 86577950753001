export const INTERVAL_MAP = {
  Monthly: 'month' as 'month',
  Weekly: 'week' as 'week',
};

export const RADIO_OPTIONS_MAP = {
  evenly: 'Evenly across all activities each',
  separately: 'Separately for each group each',
};

export const RADIO_OPTIONS = [
  RADIO_OPTIONS_MAP.evenly,
  RADIO_OPTIONS_MAP.separately,
];

export const ALL_GROUP_NAME = 'all';

export const COSTS_GRID_TOP_BAR_HEIGHT = 87;

export const COSTS_GRID_TOP_BAR_HEIGHT_DRAFT = 132;

export const COSTS_LIST_ITEM_HEIGHT = 34;

export const COSTS_LIST_PADDING = 16;

export const COSTS_COL_WIDTH = 340;

export const COSTS_LEFT_COL_MIN_WIDTH = 200;
