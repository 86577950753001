import { useCallback, useContext } from 'react';
import getIdentifier from '../../../getIdentifier';
import TargetFormContext from '../contexts/TargetFormContext';

const useGetInitialCategories = () => {
  const { isBelowTargetDesirable, isGridMode, outputs } =
    useContext(TargetFormContext);

  const getInitialCategories =
    useCallback((): Targets.Wizard.Categorisation[] => {
      if (!isGridMode) {
        return [
          {
            key: getIdentifier(undefined, true),
            value: '',
            outputs: [],
            isLastRow: false,
            operator: isBelowTargetDesirable ? 'lte' : 'gte',
          },
        ];
      }

      return [
        {
          key: getIdentifier(undefined, true),
          value: '',
          outputs: outputs.map((o) => ({
            fieldName: o.fieldName,
            fieldValue: '',
            fieldType: o.fieldType,
          })),
          isLastRow: false,
          operator: isBelowTargetDesirable ? 'lte' : 'gte',
        },
        {
          key: getIdentifier(undefined, true),
          value: '',
          outputs: outputs.map((o) => ({
            fieldName: o.fieldName,
            fieldValue: '',
            fieldType: o.fieldType,
          })),
          isLastRow: true,
          operator: isBelowTargetDesirable ? 'gt' : 'lt',
        },
      ];
    }, [isBelowTargetDesirable, isGridMode, outputs]);

  return getInitialCategories;
};

export default useGetInitialCategories;
