import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import AnalyticsContext from 'contexts/AnalyticsContext';
import SelectedEntityContext from 'components/PerformanceBoards/contexts/SelectedEntityContext';
import useQueryParams from 'hooks/useQueryParams';
import SlideOutContext from '../../contexts/SlideOutContext';
import SlideOutInitialHeader from './SlideOutInitialHeader';
import SlideOutWizardHeader from './SlideOutWizardHeader';
import SlideOutHomeHeader from './SlideOutHomeHeader';
import { ENTITY_VALUE_QUERY_PARAM } from 'components/PerformanceBoards/providers/SelectedEntityProvider';

const TopBarSlideout = styled.div`
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.08);
  padding: 16px 24px;
`;

const SlideOutHeaderContainer = ({
  slideoutMode,
  type,
}: {
  slideoutMode?: PerformanceBoardTypes.SlideOut.ModeType;
  type?: PerformanceBoardTypes.SlideOut.SectionType;
}) => {
  const { setIsOpen, setNavState, board, navState } =
    useContext(SlideOutContext);
  const { selectedEntity } = useContext(SelectedEntityContext);
  const { trackEvent } = useContext(AnalyticsContext);

  const urlParams = useQueryParams();
  const navigate = useNavigate();

  const close = useCallback(() => {
    setIsOpen(false);
    urlParams.delete(ENTITY_VALUE_QUERY_PARAM);
    navigate({ search: urlParams.toString() });

    trackEvent('Board - Slide out closed');
  }, [navigate, setIsOpen, trackEvent, urlParams]);

  const back = useCallback(() => {
    if (board.slideout.length === 0) {
      setNavState({ slideoutMode: 'Empty' });
    } else {
      setNavState({ slideoutMode: 'Home' });
    }

    trackEvent('Board - Slide out - Edit Widget Back clicked');
  }, [board.slideout.length, setNavState, trackEvent]);

  if (slideoutMode === 'Empty') {
    return (
      <TopBarSlideout>
        <SlideOutInitialHeader close={close} />
      </TopBarSlideout>
    );
  } else if (navState.editingSection) {
    return (
      <TopBarSlideout>
        <SlideOutWizardHeader
          title={`Editing ${navState.editingSection.title}`}
          back={back}
        />
      </TopBarSlideout>
    );
  } else if (slideoutMode === 'Creating' && !!type) {
    return (
      <TopBarSlideout>
        <SlideOutWizardHeader title={type} back={back} />
      </TopBarSlideout>
    );
  } else if (slideoutMode === 'Home') {
    return (
      <TopBarSlideout>
        <SlideOutHomeHeader title={`${selectedEntity}:`} close={close} />
      </TopBarSlideout>
    );
  } else {
    return null;
  }
};

export default SlideOutHeaderContainer;
