import firebase from 'firebase/compat/app';
import { DateTime } from 'luxon';
import _ from 'lodash';

import buildContentViewId, {
  buildContentViewIdAlt,
} from '../../buildContentViewId';

const trackRecent = async ({
  userId,
  type,
  typeId,
  name,
  accountRef,
}: {
  userId: string;
  type:
    | 'report'
    | 'dashboard'
    | 'wallboard'
    | 'workspace'
    | 'board'
    | 'scorecard'
    | 'targetsApp';
  typeId: string;
  name?: string;
  accountRef: firebase.firestore.DocumentReference;
}) => {
  const recentsRef = accountRef
    .collection('users')
    .doc(userId)
    .collection('navigation')
    .doc('recents');

  await firebase.firestore().runTransaction((transaction) => {
    return transaction.get(recentsRef).then((recentDoc) => {
      const currentRecents = (() => {
        if (recentDoc.exists) {
          // @ts-ignore
          return recentDoc.data().recents as ContentView[];
        } else {
          return [];
        }
      })();

      const item = {
        version: '1',
        type,
        typeId,
        name,
        createdOn: DateTime.utc().toISO(),
      } as ContentView;

      const newRecents = (() => {
        const isExistingItem = currentRecents.some(
          (r) =>
            buildContentViewId(r) !== buildContentViewIdAlt({ type, typeId }),
        );
        if (isExistingItem) {
          const recentsWithoutItem = currentRecents.filter(
            (r) =>
              buildContentViewId(r) !== buildContentViewIdAlt({ typeId, type }),
          );
          return [item, ...recentsWithoutItem];
        } else {
          return [item, ...currentRecents];
        }
      })();

      const uniqNewRecents = _.uniqWith<ContentView>(newRecents, (a, b) => {
        return buildContentViewId(a) === buildContentViewId(b);
      });

      return transaction.set(recentsRef, { recents: uniqNewRecents });
    });
  });
};

export default trackRecent;
