const NUMBERS: string[] = [
  '63738',
  '01711',
  '06888',
  '00129',
  '17646',
  '47554',
  '50115',
  '20676',
  '30347',
  '83709',
  '66050',
  '61719',
  '95577',
  '87004',
  '14392',
  '59087',
  '63868',
  '24224',
  '71986',
  '30962',
  '37113',
  '28206',
  '30414',
  '10040',
  '11358',
  '70378',
  '71758',
  '31290',
  '27183',
  '88136',
  '63384',
  '90850',
  '93197',
  '66008',
  '93015',
  '56503',
  '86465',
  '14111',
  '51918',
  '34879',
  '10658',
  '16577',
  '48275',
  '36991',
  '02735',
  '96097',
  '23774',
  '56965',
  '93545',
  '29262',
];

export default NUMBERS;
