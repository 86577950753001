const withoutNulls = (data: any): any => {
  if (typeof data === 'string') {
    return data;
  }

  if (typeof data === 'number') {
    return data;
  }

  if (typeof data === 'boolean') {
    return data;
  }

  if (Array.isArray(data)) {
    return data.map(withoutNulls);
  }

  if (!data) {
    return undefined;
  }

  const clone = { ...data };
  Object.keys(clone).forEach((key) => clone[key] == null && delete clone[key]);
  if (clone['__typename']) {
    delete clone['__typename'];
  }

  Object.entries(clone).forEach(([key, value]) => {
    clone[key] = withoutNulls(value);
  });

  return clone;
};

export default withoutNulls;
