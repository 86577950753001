import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  ALL_GROUP_NAME,
  COSTS_COL_WIDTH,
  COSTS_GRID_TOP_BAR_HEIGHT,
  COSTS_GRID_TOP_BAR_HEIGHT_DRAFT,
  COSTS_LEFT_COL_MIN_WIDTH,
  COSTS_LIST_ITEM_HEIGHT,
  COSTS_LIST_PADDING,
} from '../consts';
import GroupItemLeft from './GroupItemLeft';
import Row from 'components/Common/Row';
import CategoryItemLeft from './CategoryItemLeft';
import ColumnHeadingsLeft from './ColumnHeadingsLeft';
import TotalItemLeft from './TotalItemLeft';
import ColumnHeadingsRight from './ColumnHeadingsRight';
import GroupItemRight from './GroupItemRight';
import TotalItemRight from './TotalItemRight';
import CategoryItemRight from './CategoryItemRight';
import Loading from 'components/Loading/Loading';
import ActivityGridContext from '../context/ActivityGridContext';
import useShadows from './hooks/useShadows';
import useHoverRow from './hooks/useHoverRow';
import SaveErrorMessage from './SaveErrorMessage';
import Colors2 from 'theme/Colors2';
import CostsShowContext from '../context/CostsShowContext';

export const Col = styled.div<{
  alignRight?: boolean;
  minWidth?: boolean;
  maxWidth?: boolean;
}>`
  flex: 1;
  max-width: ${(props) =>
    props.maxWidth ? `${COSTS_COL_WIDTH}px` : undefined};
  min-width: ${(props) =>
    props.minWidth
      ? `${COSTS_COL_WIDTH}px`
      : props.maxWidth
        ? `${COSTS_LEFT_COL_MIN_WIDTH}px`
        : undefined};
  width: ${(props) =>
    props.minWidth || props.maxWidth ? undefined : `${COSTS_COL_WIDTH}px`};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => (props.alignRight ? 'flex-end' : 'flex-start')};
`;

const GridWrapper = styled.div<{ isDraft?: boolean }>`
  width: 100%;
  height: ${(props) => `height: calc(
    100vh - ${props.isDraft ? COSTS_GRID_TOP_BAR_HEIGHT_DRAFT : COSTS_GRID_TOP_BAR_HEIGHT}px - ${COSTS_LIST_PADDING * 2}px
  )`};
  padding-top: 8px;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
`;

const Wrapper = styled.div<{ isDraft?: boolean }>`
  padding: 15px 28px;
  background-color: #fbfbfe;
  width: 100%;
  height: ${(props) => `calc(100vh - ${props.isDraft ? COSTS_GRID_TOP_BAR_HEIGHT_DRAFT : COSTS_GRID_TOP_BAR_HEIGHT}px`});
  overflow-y: auto;
  position: relative;
`;

const CostListLeftWrapper = styled.div<{ isShadowLeftVisible?: boolean }>`
  position: sticky;
  right: 10px;
  box-shadow: ${(props) =>
    props.isShadowLeftVisible ? '8px 0px 10px rgba(0, 0, 0, 0.04)' : undefined};
  border-right: 1px solid ${Colors2.Grey['7']};
`;

const CostListRightWrapper = styled.div`
  height: 100%;
`;

const InnerScrollableContent = styled.div`
  width: 100%;
  max-height: 100%;
  overflow: auto;
  overscroll-behavior-x: contain;
`;

export const CostRowElement = styled(Row)<{ isHovered?: boolean }>`
  height: ${COSTS_LIST_ITEM_HEIGHT}px;
  background-color: ${(props) => (props.isHovered ? '#E5F0FA' : undefined)};
`;

const CostListLeft = ({
  toName,
  groups,
  categories,
  isShadowLeftVisible,
}: {
  toName: ({ fieldName }: { fieldName: string }) => string;
  groups: Costs.Group[];
  categories: Costs.CostCategory[];
  isShadowLeftVisible: boolean;
}) => {
  return (
    <CostListLeftWrapper isShadowLeftVisible={isShadowLeftVisible}>
      <ColumnHeadingsLeft />
      {groups[0].groupName !== ALL_GROUP_NAME &&
        groups.map((group) => (
          <div key={group.groupName}>
            <GroupItemLeft label={group.groupName} />
            {categories.map((cat) => (
              <CategoryItemLeft
                key={`${group.groupName}-${cat.costFieldName}`}
                label={toName({ fieldName: cat.costFieldName })}
                hoverId={`${group.groupName}-${cat.costFieldName}`}
              />
            ))}
            <TotalItemLeft />
          </div>
        ))}
      {groups[0].groupName === ALL_GROUP_NAME && (
        <>
          {categories.map((cat) => (
            <CategoryItemLeft
              key={cat.costFieldName}
              label={toName({ fieldName: cat.costFieldName })}
              hoverId={cat.costFieldName}
            />
          ))}
          <TotalItemLeft />
        </>
      )}
    </CostListLeftWrapper>
  );
};

const CostListRight = ({
  groups,
  categories,
}: {
  groups: Costs.Group[];
  categories: Costs.CostCategory[];
}) => {
  return (
    <>
      <ColumnHeadingsRight />
      {groups[0].groupName !== ALL_GROUP_NAME &&
        groups.map((group) => (
          <div key={`${group.groupName}-right`}>
            <GroupItemRight />
            {categories.map((cat) => (
              <CategoryItemRight
                key={`${group.groupName}-${cat.costFieldName}-right`}
                group={group}
                category={cat}
                hoverId={`${group.groupName}-${cat.costFieldName}`}
              />
            ))}
            <TotalItemRight group={group} />
          </div>
        ))}
      {groups[0].groupName === ALL_GROUP_NAME && (
        <>
          {categories.map((cat) => (
            <CategoryItemRight
              key={`${cat.costFieldName}-right`}
              group={{ groupName: ALL_GROUP_NAME }}
              category={cat}
              hoverId={cat.costFieldName}
            />
          ))}
          <TotalItemRight group={groups[0]} />
        </>
      )}
    </>
  );
};

const ActivityGrid = ({
  activityCosts,
}: {
  activityCosts: Costs.PersistedCostModel | undefined;
}) => {
  const {
    costListDivRef,
    costListDivParentScrollerRef,
    invalidIntervals,
    isSavingError,
    toName,
  } = useContext(ActivityGridContext);

  const {
    isShadowLeftVisible,
    isShadowRightVisible,
    height,
    width,
    costScrollerRef,
    gridRef,
  } = useShadows();

  const { disableHover } = useHoverRow();

  if (!activityCosts) {
    return <Loading />;
  }

  return (
    <>
      <Wrapper
        ref={costListDivParentScrollerRef}
        isDraft={activityCosts.isDraft}
      >
        <GridWrapper ref={costListDivRef} isDraft={activityCosts.isDraft}>
          {isSavingError && (
            <SaveErrorMessage invalidIntervals={invalidIntervals} />
          )}
          <InnerScrollableContent
            className="hiding-scrollbar"
            ref={costScrollerRef}
            onMouseLeave={disableHover}
          >
            <div
              ref={gridRef}
              style={{
                display: 'flex',
                flexDirection: 'row',
                width,
              }}
            >
              <div
                style={{
                  position: 'sticky',
                  alignSelf: 'flex-start',
                  left: '0px',
                  zIndex: 2,
                  maxWidth: `${COSTS_COL_WIDTH}px`,
                  backgroundColor: 'white',
                }}
              >
                <CostListLeft
                  toName={toName}
                  groups={activityCosts.groups}
                  categories={activityCosts.categories}
                  isShadowLeftVisible={isShadowLeftVisible}
                />
              </div>

              <CostListRightWrapper style={{ flex: 1 }}>
                <CostListRight
                  groups={activityCosts.groups}
                  categories={activityCosts.categories}
                />
              </CostListRightWrapper>
              <div
                style={{
                  height,
                  position: 'absolute',
                  width: '20px',
                  marginRight: -20,
                  zIndex: 10,
                  right: 0,
                  boxShadow: isShadowRightVisible
                    ? '-8px 0px 10px rgba(0, 0, 0, 0.04)'
                    : '-1px 0px 0px rgba(0, 0, 0, 0.04)',
                }}
              ></div>
            </div>
          </InnerScrollableContent>
        </GridWrapper>
      </Wrapper>
    </>
  );
};

const Gate = () => {
  const { costModel: initialState } = useContext(CostsShowContext);

  return <ActivityGrid activityCosts={initialState} />;
};

export default Gate;
