const toRangeAggregation = (
  rangeInput: CustomDimensionRangeInput,
  field: string,
): V5RangeAggregation => {
  return {
    field,
    ranges: rangeInput.items.map((i) => ({
      key: i.label,
      from: i.from,
      to: i.to,
    })),
  };
};

export default toRangeAggregation;
