import React, { useContext } from 'react';
import DetailsSlideOutNav from '../DetailsSlideOutNav';
import DetailsSlideOutHeading from '../DetailsSlideOutHeading';
import DetailsSlideOutSummary from '../DetailsSlideOutSummary';
import DetailsSlideOutTimeline from '../DetailsSlideOutTimeline';

import styled from 'styled-components';
import OrderDetailsMapContainer from '../OrderDetailsMap';
import DetailsSlideOutComments from '../DetailsSlideOutComments';
import OrderDetailsContext from '../../contexts/OrderDetailsContext';
import Loading from '../Loading';

const Wrapper = styled.div`
  height: 100%;
  box-shadow:
    0 0 0 1px rgba(53, 45, 67, 0.07),
    -1px 0 3px 0 rgba(53, 45, 67, 0.12),
    -1px 0 12px 5px rgba(53, 45, 67, 0.1);
  display: flex;
  flex-direction: row;
  z-index: 99999;
`;

const DetailsSlideOut = ({
  activeNavElement,
  onNavScroll,
  isLoading,
}: {
  activeNavElement: SlideOutNavElement;
  onNavScroll: (event: React.UIEvent<HTMLDivElement>) => void;
  isLoading: boolean;
}) => (
  <Wrapper>
    {isLoading && <Loading />}
    {!isLoading && (
      <React.Fragment>
        <DetailsSlideOutNav activeNavElement={activeNavElement} />
        <div
          style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
        >
          <DetailsSlideOutHeading />
          <div
            onScroll={onNavScroll}
            id={'details-slide-scroll-area'}
            style={{
              flex: 1,
              overflowY: 'scroll',
              padding: '20px 24px',
            }}
          >
            <div id="details-slide-summary">
              <DetailsSlideOutSummary />
              <OrderDetailsMapContainer height={200} />
            </div>
            <div id="details-slide-timeline">
              <DetailsSlideOutTimeline />
            </div>
            <div id="details-slide-comments">
              <DetailsSlideOutComments />
            </div>
          </div>
        </div>
      </React.Fragment>
    )}
  </Wrapper>
);

const Gate = ({
  activeNavElement,
  onNavScroll,
}: {
  activeNavElement: SlideOutNavElement;
  onNavScroll: (event: React.UIEvent<HTMLDivElement>) => void;
}) => {
  const { isLoading } = useContext(OrderDetailsContext);

  return (
    <DetailsSlideOut
      activeNavElement={activeNavElement}
      onNavScroll={onNavScroll}
      isLoading={isLoading}
    />
  );
};

export default Gate;
