import React, { useContext, useState } from 'react';
import ManualTargets from './ManualTargets';
import ScorecardContext from 'contexts/ScorecardContext';
import useTargetBandsUtils from '../hooks/useTargetBandsUtils';

const ManualTargetsContainer = ({
  manualKpi,
  isShowingAdvanced,
  markHasUnsavedChanges,
  targets,
  setTargets,
  isTargetsDisabled,
  setIsColorOrderReversed,
  setProrationOverride,
  setIsTargetsDisabled,
  isColorOrderReversed,
  setCanSaveKpi,
  dynamicTargetOverride,
}: {
  manualKpi?: Scorecards.ManualKpiRow;
  isShowingAdvanced: boolean;
  markHasUnsavedChanges: () => void;
  targets: Scorecards.WeekTargets | Scorecards.IsoTargets;
  setTargets: React.Dispatch<
    React.SetStateAction<Scorecards.WeekTargets | Scorecards.IsoTargets>
  >;
  isTargetsDisabled: boolean;
  setIsColorOrderReversed: (value: React.SetStateAction<boolean>) => void;
  setProrationOverride: (
    value: React.SetStateAction<boolean | undefined>,
  ) => void;
  setIsTargetsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  isColorOrderReversed: boolean;
  setCanSaveKpi: React.Dispatch<React.SetStateAction<boolean>>;
  dynamicTargetOverride: boolean | undefined;
}) => {
  const { scorecard } = useContext(ScorecardContext);

  const [isAddingNewTargetBand, setIsAddingNewTargetBand] =
    useState<boolean>(!manualKpi);
  const [sortedTargetBands, setSortedTargetBands] = useState<
    {
      band: string[] | undefined;
      date: Scorecards.IsoDate | Scorecards.WeekDate;
    }[]
  >(window.emptyArray);

  const [isEditingDateTarget, setIsEditingDateTarget] =
    useState<boolean>(false);

  const {
    onAddClicked,
    onNewTargetBandSaved,
    onTargetBandSaved,
    onDynamicTargetOverrideToggled,
    onReverseColorOrderToggled,
    onNewTargetBandCancelled,
  } = useTargetBandsUtils({
    setIsAddingNewTargetBand,
    markHasUnsavedChanges,
    isTargetsDisabled,
    targets,
    setTargets,
    setSortedTargetBands,
    setProrationOverride,
    setIsColorOrderReversed,
  });

  return (
    <ManualTargets
      scorecard={scorecard}
      isShowingAdvanced={isShowingAdvanced}
      isTargetsDisabled={isTargetsDisabled}
      setIsTargetsDisabled={setIsTargetsDisabled}
      sortedTargetBands={sortedTargetBands}
      targets={targets}
      setTargets={setTargets}
      onTargetBandSaved={onTargetBandSaved}
      onNewTargetBandSaved={onNewTargetBandSaved}
      onAddClicked={onAddClicked}
      isAddingNewTargetBand={isAddingNewTargetBand}
      onNewTargetBandCancelled={onNewTargetBandCancelled}
      isEditingDateTarget={isEditingDateTarget}
      setIsEditingDateTarget={setIsEditingDateTarget}
      isColorOrderReversed={isColorOrderReversed}
      onReverseColorOrderToggled={onReverseColorOrderToggled}
      setCanSaveKpi={setCanSaveKpi}
      markHasUnsavedChanges={markHasUnsavedChanges}
      dynamicTargetOverride={dynamicTargetOverride}
      onDynamicTargetOverrideToggled={onDynamicTargetOverrideToggled}
    />
  );
};

export default ManualTargetsContainer;
