import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import Button from 'kingpin/atoms/Button';
import { ModalTransition } from '@atlaskit/modal-dialog';

import TargetListItem from './TargetListItem';
import {
  ListWrap,
  TopBarElem,
  List,
  ItemElem,
} from '../TargetFieldList/TargetFieldList';
import TickSrc from '../images/tick.png';
import Typography from 'kingpin/atoms/Typography';
import Row from '../../Common/Row';
import TargetListContext from '../contexts/TargetListContext';
import useHasUnsavedChanges from '../hooks/useHasUnsavedChanges';
import useWarningConfirmation from '../../ConfirmationModals/hooks/useWarningConfirmation';

const Container = styled.div`
  padding: 24px 24px 24px 32px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const TopBar = ({
  onAddTargetClicked,
}: {
  onAddTargetClicked?: () => void;
}) => {
  const { isAddTargetPrimary } = useContext(TargetListContext);
  const hasUnsavedChanges = useHasUnsavedChanges();
  const { getConfirmationThat, DangerConfirmation } = useWarningConfirmation({
    title: 'Unsaved Changes',
    continueText: 'Continue to new Target without saving',
  });
  const onClicked = useCallback(() => {
    if (!onAddTargetClicked) {
      return;
    }

    if (!hasUnsavedChanges) {
      onAddTargetClicked();
      return;
    }

    getConfirmationThat(
      'If you navigate away, all changes made will be lost.' +
        ' Are sure you want to cancel Target setting?',
    ).then((isConfirmed) => {
      if (isConfirmed) {
        onAddTargetClicked();
      }
    });
  }, [getConfirmationThat, hasUnsavedChanges, onAddTargetClicked]);

  return (
    <>
      <TopBarElem>
        <Row spaceBetween centerAlign style={{ width: '100%' }}>
          <Typography.Header type="H5">Target(s)</Typography.Header>
          {onAddTargetClicked && (
            <>
              {!isAddTargetPrimary && (
                <Button
                  onClick={onClicked}
                  label="Add Target"
                  type="Tertiary"
                  size="Small"
                />
              )}
              {isAddTargetPrimary && (
                <Button
                  onClick={onClicked}
                  label="Add Target"
                  type="Primary"
                  size="Small"
                />
              )}
            </>
          )}
        </Row>
      </TopBarElem>
      <ModalTransition>{DangerConfirmation}</ModalTransition>
    </>
  );
};

const TargetsBucket = ({
  label,
  targets,
  selectedTarget,
}: {
  label: string;
  targets: Targets.Wizard.DataTypeTarget[];
  selectedTarget?: Targets.Wizard.DataTypeTarget;
}) => {
  if (targets.length === 0) {
    return null;
  }

  return (
    <div style={{ marginBottom: 32 }}>
      <Row centerAlign style={{ marginBottom: 8 }}>
        <Typography.Header type="H5">{label}</Typography.Header>
        <Typography.Header type="H5">{`(${targets.length})`}</Typography.Header>
      </Row>

      {targets.map((t) => (
        <TargetListItem
          key={t.id}
          target={t}
          isSelected={!!selectedTarget && t.id === selectedTarget.id}
        />
      ))}
    </div>
  );
};

const CurrentTarget = ({
  target,
  selectedTarget,
}: {
  target?: Targets.Wizard.DataTypeTarget;
  selectedTarget?: Targets.Wizard.DataTypeTarget;
}) => {
  if (!target) {
    return null;
  }

  return (
    <div style={{ marginBottom: 32 }}>
      <Row centerAlign style={{ marginBottom: 8 }}>
        <Typography.Header type="H5">Ongoing</Typography.Header>
      </Row>

      <TargetListItem
        target={target}
        isSelected={!!selectedTarget && target.id === selectedTarget.id}
      />
    </div>
  );
};

const TargetList = ({
  pastTargets,
  futureTargets,
  currentTarget,
  selectedTarget,
  onAddTargetClicked,
  isCreatingNewTarget,
}: {
  pastTargets: Targets.Wizard.DataTypeTarget[];
  futureTargets: Targets.Wizard.DataTypeTarget[];
  currentTarget?: Targets.Wizard.DataTypeTarget;
  selectedTarget?: Targets.Wizard.DataTypeTarget;
  onAddTargetClicked: () => void;
  isCreatingNewTarget: boolean;
}) => (
  <>
    <TopBar
      onAddTargetClicked={isCreatingNewTarget ? undefined : onAddTargetClicked}
    />
    <Container>
      <ListWrap style={{ paddingRight: 8 }}>
        <List>
          {isCreatingNewTarget && (
            <div style={{ marginBottom: 16 }}>
              <ItemElem isSelected>
                <Row
                  centerAlign
                  spaceBetween
                  style={{ flex: 1, overflow: 'hidden' }}
                >
                  <Typography.Header type="H5">New Target*</Typography.Header>
                  <img src={TickSrc} alt="ticked" />
                </Row>
              </ItemElem>
            </div>
          )}
          <TargetsBucket
            label={'Future'}
            targets={futureTargets}
            selectedTarget={selectedTarget}
          />
          <CurrentTarget
            target={currentTarget}
            selectedTarget={selectedTarget}
          />
          <TargetsBucket
            label={'Historical'}
            selectedTarget={selectedTarget}
            targets={pastTargets}
          />
        </List>
      </ListWrap>
    </Container>
  </>
);

export default TargetList;
