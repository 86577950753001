import isIsoDate from './isIsoDate';

const targetDateToString = (
  date: Scorecards.WeekDate | Scorecards.IsoDate,
): string => {
  if (isIsoDate(date)) {
    return date;
  } else {
    return `y: ${date.year}, w: ${date.week}`;
  }
};

export default targetDateToString;
