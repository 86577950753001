import { useCallback, useContext } from 'react';
import FilterBuilderContext from '../FilterBuilderContext';

const useGetFilterType = () => {
  const { datasetDefinition } = useContext(FilterBuilderContext);
  const getFilterType = useCallback(
    (field: string): FilterBuilder.FilterType => {
      const fieldDef = datasetDefinition.fields.find((f) => f.field === field);
      if (!fieldDef) {
        const error = new Error();
        error.name = 'FilterBuilder: Field not found';
        throw error;
      }

      switch (fieldDef.type) {
        case 'boolean':
          return 'boolean';
        case 'text':
          return 'text';
        case 'float':
        case 'int':
        case 'long':
          return 'number';
        default:
          // eslint-disable-next-line no-case-declarations
          const err = new Error();
          err.name = 'FilterBuilder: unknown fieldType';
          throw err;
      }
    },
    [datasetDefinition.fields],
  );

  return getFilterType;
};

export default useGetFilterType;
