import React from 'react';

import Column from '../../Column';
import PermissionGates from '../../../../../components/PermissionGates';
import PERMISSIONS from '../../../../../permissionDefinitions';
import ListItemWrapper from '../../ListItemWrapper';

const ItemRow = ({
  datasetCol,
  nameCol,
  descriptionCol,
  hideCol,
  lastUpdatedCol,
  triggerCol,
}: {
  datasetCol?: JSX.Element;
  nameCol?: JSX.Element;
  descriptionCol?: JSX.Element;
  hideCol?: JSX.Element;
  lastUpdatedCol?: JSX.Element;
  triggerCol?: JSX.Element;
}) => (
  <ListItemWrapper>
    <Column>{datasetCol}</Column>
    <Column>{nameCol}</Column>
    <Column>{descriptionCol}</Column>
    <PermissionGates.Has
      requiredPermission={
        PERMISSIONS.DATA_MANAGEMENT.HIDE_DATASET_FROM_CUSTOMER
      }
    >
      <Column>{hideCol}</Column>
    </PermissionGates.Has>
    <Column>{lastUpdatedCol}</Column>
    <PermissionGates.Has
      requiredPermission={PERMISSIONS.DATA_MANAGEMENT.TRIGGER_ADF_PIPELINE}
    >
      <Column>{triggerCol}</Column>
    </PermissionGates.Has>
  </ListItemWrapper>
);

export default ItemRow;
