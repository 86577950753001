import React, { useContext, useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import FavouritesContext from 'contexts/FavouritesContext';
import Typography from 'kingpin/atoms/Typography';
import Button from 'kingpin/atoms/Button';
import { buildLink } from 'screens/LegacyHome/RecentsCard/RecentsCard';
import Tooltip from 'components/Tooltip';
import Row from 'components/Common/Row';
import { ResourceFavouriteButton } from 'components/ResourceTopBar';
import NavItem from './NavItem';
import NAVIGATION from './constants';
import AvailableWorkSpacesContext from '../../contexts/AvailableWorkSpacesContext';

const FavouriteItem = ({
  favouriteItem,
  label,
  isHovered,
}: {
  favouriteItem: ContentView;
  label: string;
  isHovered: boolean;
}) => {
  const [isDragHovered, setIsDragHovered] = useState<boolean>(false);

  return (
    <Row centerAlign>
      <div
        onMouseEnter={() => setIsDragHovered(true)}
        onMouseLeave={() => setIsDragHovered(false)}
        style={{
          visibility: isHovered ? 'visible' : 'hidden',
          margin: '0 -8px',
          opacity: isDragHovered ? 1 : 0.2,
        }}
      >
        <Tooltip content={'Drag'}>
          <Button size={'Small'} type={'Ghost'} icon="drag" />
        </Tooltip>
      </div>

      <div style={{ display: 'flex', margin: '0 4px' }}>
        <ResourceFavouriteButton
          type={favouriteItem.type}
          typeId={favouriteItem.typeId}
          name={favouriteItem.name}
        />
      </div>

      <div
        style={{
          width: `${NAVIGATION.OPENED_NAV_WIDTH - 64}px`,
          overflow: 'hidden',
        }}
      >
        <Typography.Body type="Button Text" isEllipsis ellipsisPosition="right">
          {label}
        </Typography.Body>
      </div>
    </Row>
  );
};

const FavouriteItemContainer = ({
  provided,
  item,
}: {
  provided: DraggableProvided;
  item: ContentView;
}) => {
  const { closeSlideout } = useContext(FavouritesContext);
  const { availableWorkSpaces } = useContext(AvailableWorkSpacesContext);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <NavItem
        onClick={closeSlideout}
        key={item.typeId}
        to={buildLink({ item, availableWorkSpaces, portal: undefined })}
        tooltip={item.name}
        icon={
          <FavouriteItem
            favouriteItem={item}
            label={item.name}
            isHovered={isHovered}
          />
        }
        label={item.name}
      />
    </div>
  );
};

export default FavouriteItemContainer;
