import Row from 'components/Common/Row';
import React, { useContext, useEffect, useState } from 'react';
import useGridDocs from '../useGridDocs';
import styled from 'styled-components';
import Colors2 from 'theme/Colors2';
import useGetFieldLabel from 'hooks/useGetFieldLabel';
import Typography from 'kingpin/atoms/Typography';
import useValueFormatters from 'hooks/useValueFormatters';
import Loading from 'components/Loading/Loading';
import PeriodsContext from 'contexts/PeriodsContext';

const Col = styled.div`
  flex: 1;
  flex-direction: column;
  margin-right: 16px;
  text-align: left;
  flex-wrap: wrap;

  word-wrap: break-word;
`;

const Card = styled.div`
  background-color: white;
  padding: 16px;
  border: 1px solid ${Colors2.Grey['7']};
  border-radius: 4px;
  box-shadow: 0 3px 3px 0 rgba(53, 45, 67, 0.07);
  margin-bottom: 8px;
`;

const PropertiesSlideout = ({
  dataType,
  filters,
  fields,
}: {
  dataType: string;
  filters: FilterInput;
  fields: string[];
}) => {
  const { selectedPeriod } = useContext(PeriodsContext);
  const { formatField } = useValueFormatters();
  const { getFieldLabel } = useGetFieldLabel();

  const [dateScope, setDateScope] = useState<DateRangeInput | undefined>(
    selectedPeriod
      ? {
          startDate: selectedPeriod.startDate,
          endDate: selectedPeriod.endDate,
        }
      : undefined,
  );

  useEffect(() => {
    setDateScope(
      selectedPeriod
        ? {
            startDate: selectedPeriod.startDate,
            endDate: selectedPeriod.endDate,
          }
        : undefined,
    );
  }, [selectedPeriod]);

  const { docs, isMoreDocsAvailable, isLoading } = useGridDocs({
    dataType,
    filters,
    dateScope,
  });

  return (
    <div data-testid={'properties-preview'}>
      <div style={{ width: '100%', maxHeight: 300, overflow: 'scroll' }}>
        {isLoading && (
          <div style={{ height: 100, display: 'flex' }}>
            <Loading isSmall />
          </div>
        )}
        {!isLoading && docs.length !== 0 && (
          <>
            {docs &&
              docs.map((doc) => (
                <Card key={doc['id']}>
                  {fields.map((field) => (
                    <Row
                      key={field}
                      centerAlign
                      style={{
                        padding: '4px 0',
                        minHeight: 16,
                      }}
                    >
                      <Col>
                        <Typography.Body type="Label">
                          {getFieldLabel({ field, dataType })}
                        </Typography.Body>
                      </Col>
                      <Col>
                        <Typography.Body type="Body 12">
                          {formatField({
                            value: doc[field],
                            field,
                            dataset: dataType,
                          })}
                        </Typography.Body>
                      </Col>
                    </Row>
                  ))}
                </Card>
              ))}
          </>
        )}
        {!isLoading && docs.length === 0 && (
          <div style={{ marginTop: 8 }}>
            <Typography.Body type="Label">
              There is no data for the chosen fields
            </Typography.Body>
          </div>
        )}
      </div>
      {isMoreDocsAvailable && (
        <div style={{ marginTop: 8 }}>
          <Typography.Body type="Label">More Data Is Available</Typography.Body>
        </div>
      )}
    </div>
  );
};

export default PropertiesSlideout;
