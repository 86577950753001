import React, { useContext } from 'react';
import styled from 'styled-components';
import NavSideBarContext from '../../contexts/NavSideBarContext';
import Colors2 from '../../theme/Colors2';

const NavSectionDiv = styled.div<{ hideBorderBottom?: boolean }>`
  padding: ${(props) => (props.hideBorderBottom ? '8px 8px 0px 8px' : '8px')};
  background-color: #fbfbfb;
  border-bottom: ${(props) =>
    props.hideBorderBottom ? 'unset' : `solid 1px ${Colors2.Border}`};
`;

const NavSectionLabelDiv = styled.div`
  padding-left: 8px;
  margin-bottom: 4px;

  span {
    color: #8e8e8e;
    font-family: Inter;
    font-weight: 500;
    font-size: 11px;
    font-style: normal;
    line-height: 14px; /* 107.692% */
    letter-spacing: -0.156px;
    text-transform: capitalize;
  }
`;

const NavSection = ({
  label,
  children,
  hideBorderBottom,
}: {
  label?: string;
  children?: JSX.Element | JSX.Element[];
  hideBorderBottom?: boolean;
}) => {
  const { isOpen } = useContext(NavSideBarContext);
  const isCollapsed = !isOpen;
  return (
    <NavSectionDiv hideBorderBottom={hideBorderBottom}>
      {label && !isCollapsed && (
        <NavSectionLabelDiv>
          <span>{label}</span>
        </NavSectionLabelDiv>
      )}
      {children}
    </NavSectionDiv>
  );
};

export default NavSection;
