import React from 'react';

import styled from 'styled-components';

import GridProvider from 'contextProviders/GridProvider';
import Row from 'components/Common/Row';
import ReportDrillDowns from 'components/ReportDrillDowns';
import ResetReportButton from 'components/Report/ResetReportButton';
import Grid from 'components/Grid';
import DateInput from 'components/DateInput';
import Inputs from 'components/Inputs';

import DatasetTrendButton from './DatasetTrendButton';
import DATASET_TREND_BUTTON from './DatasetTrendButton/constants';

const Content = styled.div`
  width: 100%;

  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
`;

const SearchScreen = ({
  dataType,
  datasetOptions,
  hasLeftDefaultState,
  isDatasetSelectDisabled,
}: {
  dataType: string;
  datasetOptions: DropdownOption[];
  hasLeftDefaultState: boolean;
  isDatasetSelectDisabled: boolean;
}) => (
  <div
    style={{
      padding: '16px 24px',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Row style={{ marginBottom: 16 }} spaceBetween>
      <Row
        centerAlign
        style={{
          flexWrap: 'wrap',
        }}
      >
        <div style={{ marginRight: 8 }}>
          <DateInput />
        </div>
        <ReportDrillDowns />
      </Row>
      <Row centerAlign>
        {hasLeftDefaultState && <ResetReportButton />}
        <div style={{ marginRight: 8 }}>
          <Inputs.Dropdown
            options={datasetOptions}
            isDisabled={isDatasetSelectDisabled}
          />
        </div>
        <DatasetTrendButton dataset={dataType} />
      </Row>
    </Row>
    <Content>
      <div id={DATASET_TREND_BUTTON.TREND_DIV_ID} />
      <div
        style={{
          height: '100%',
        }}
      >
        <GridProvider dataType={dataType}>
          <Grid
            layoutOnFirstRender={false}
            layoutOnColumnChange={false}
            layoutOnModelUpdated={false}
            hasBorders={true}
            hasDarkHeader={true}
            isSearchScreen
          />
        </GridProvider>
      </div>
    </Content>
  </div>
);

export default SearchScreen;
