import { useContext } from 'react';
import AccountContext from '../../contexts/AccountContext';
import {
  STAGING_ACCOUNT_DCI_ID,
  STAGING_ACCOUNT_GOGGINS_ID,
  WAYNE_TRANSPORT_ACCOUNT_ID,
  WESTSIDE_ACCOUNT_ID,
} from '../../constants';

const useIsDataPermissionsEnabled = () => {
  const { id: accountId } = useContext(AccountContext);

  return [
    WESTSIDE_ACCOUNT_ID,
    WAYNE_TRANSPORT_ACCOUNT_ID,
    STAGING_ACCOUNT_DCI_ID,
    STAGING_ACCOUNT_GOGGINS_ID,
  ].includes(accountId);
};

export default useIsDataPermissionsEnabled;
