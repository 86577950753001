import React from 'react';

interface DragAndDropListContextType {
  isDragging: boolean;
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
}

const DragAndDropListContext = React.createContext<DragAndDropListContextType>({
  isDragging: false,
  setIsDragging: window.tokenFunction,
});

export default DragAndDropListContext;
