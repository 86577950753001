import moment from 'moment';

const formatPartialDate = (date: string) => moment.utc(date).format('Do MMM');

const getTimeText = (goal: GeneralGoal, dateRange: DateRangeInput) => {
  const startD = dateRange.startDate
    ? dateRange.startDate
    : new Date().toISOString();
  const endD = dateRange.endDate ? dateRange.endDate : new Date().toISOString();

  return `${formatPartialDate(startD)} - ${formatPartialDate(endD)}`;
};

export default getTimeText;
