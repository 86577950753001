import { useCallback, useContext, useEffect, useState } from 'react';
import GqlClientContext from 'contexts/GqlClientContext';
import getIntervalCosts from '../../api/getIntervalCosts';

const useIntervalCosts = (costModelId: string | undefined) => {
  const { client } = useContext(GqlClientContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [intervalCosts, setIntervalCosts] = useState<Costs.IntervalCosts[]>();

  const getIntervalCostsCallback = useCallback(async () => {
    if (!costModelId) {
      setIsLoading(false);
      return;
    }

    const intervalCosts = await getIntervalCosts(costModelId, client);
    return intervalCosts;
  }, [client, costModelId]);

  const refreshCosts = useCallback(async () => {
    setIsLoading(true);
    const newCosts = await getIntervalCostsCallback();
    setIntervalCosts(newCosts);
    setIsLoading(false);
    return;
  }, [getIntervalCostsCallback]);

  useEffect(() => {
    let isActive = true;
    setIsLoading(true);
    getIntervalCostsCallback().then((intervalCosts) => {
      if (isActive) {
        setIntervalCosts(intervalCosts);
        setIsLoading(false);
      }
    });

    return () => {
      isActive = false;
    };
  }, [getIntervalCostsCallback]);

  return { intervalCosts, isLoading, refreshCosts };
};

export default useIntervalCosts;
