import React from 'react';

interface CompoundMetricsContextType {
  allCompoundMetrics: Metrics.CompoundMetric[];
  compoundMetricInputsLookup: {
    [compoundMetricId: string]: Metrics.NormalMetric[] | undefined;
  };
  getCompoundMetricWithMetricDefs: (
    compoundMetricId: string,
  ) => Metrics.CompoundMetricWithMetricDefs | undefined;
  isLoading: boolean;
}

const CompoundMetricsContext = React.createContext<CompoundMetricsContextType>({
  allCompoundMetrics: window.emptyArray,
  compoundMetricInputsLookup: window.emptyObject,
  getCompoundMetricWithMetricDefs: () => undefined,
  isLoading: true,
} as CompoundMetricsContextType);

export default CompoundMetricsContext;
