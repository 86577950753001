import styled from 'styled-components';

interface LabelProps {
  noMargin?: boolean;
}
export const Label = styled.div<LabelProps>`
  margin-bottom: ${(props) => (props.noMargin ? '0px' : '4px')};
`;

interface SectionProps {
  large?: boolean;
}
export const Section = styled.div<SectionProps>`
  margin-bottom: 16px;
`;

interface InputDivProps {
  fullWidth?: boolean;
  noMargin?: boolean;
  white?: boolean;
  autocomplete?: string;
}
export const InputDiv = styled.div<InputDivProps>`
  width: ${(props) => (props.fullWidth ? '100%' : 'calc(100% - 18px)')};
  margin-bottom: ${(props) => (props.noMargin ? '0px' : '24px')};
`;

export const Icon = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 20px;
`;

export const Close = styled.div`
  position: absolute;
  top: 25px;
  right: 16px;
`;

export const CloseIcon = styled.img`
  width: 24px;
  height: 24px;
`;
