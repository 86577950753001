import React, { useContext } from 'react';
import DetailsSlideOutHeading from './DetailsSlideOutHeading';
import DetailsSlideOutContext from '../../contexts/DetailsSlideOutContext';

const DetailsSlideOutHeadingContainer = () => {
  const { selectedOrderNumber, closeDetailsSlide } = useContext(
    DetailsSlideOutContext,
  );
  if (!selectedOrderNumber) {
    return null;
  }

  return (
    <DetailsSlideOutHeading
      orderNumber={selectedOrderNumber}
      close={closeDetailsSlide}
    />
  );
};

export default DetailsSlideOutHeadingContainer;
