import React, { useContext, useEffect, useRef } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import styled from 'styled-components';
import TargetFormContext from '../contexts/TargetFormContext';
import GroupsScrollingContext from '../contexts/GroupsScrollingContext';
import { GROUPS_SCROLLER_ID } from '../constants';
import useShadowIfDivIsScrolling, {
  ScrollingDivShadowCss,
} from '../../../hooks/useShadowIfDivIsScrolling';

const GroupsElem = styled.div<{ isScrolling: boolean }>`
  padding: 32px;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
  transition: box-shadow 12.5s;

  ${(props) => props.isScrolling && ScrollingDivShadowCss}
`;

const Inner = styled.div`
  max-height: 0;
`;

const GroupListsWrapper = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { groups } = useContext(TargetFormContext);
  const { onDragEnd } = useContext(TargetFormContext);
  const listRef = useRef<HTMLDivElement>(null);
  const { isScrolling, determineIfIsScrolling } =
    useShadowIfDivIsScrolling(listRef);

  useEffect(() => {
    determineIfIsScrolling();
  }, [groups, determineIfIsScrolling]);

  return (
    <GroupsScrollingContext.Provider
      value={{
        determineIfIsScrolling,
      }}
    >
      <GroupsElem
        id={GROUPS_SCROLLER_ID}
        isScrolling={isScrolling}
        ref={listRef}
      >
        <Inner>
          <DragDropContext onDragEnd={onDragEnd}>{children}</DragDropContext>
        </Inner>
      </GroupsElem>
    </GroupsScrollingContext.Provider>
  );
};

export default GroupListsWrapper;
