import React, { ChangeEvent } from 'react';
import Button from 'kingpin/atoms/Button';
import Row from '../Common/Row';
import AccessInput from '../AccessInput';
import TextInput from '../../kingpin/atoms/TextInput';
import Form from '../../kingpin/forms/Form';
import FormHeader from '../../kingpin/forms/FormHeader';
import FormContent from '../../kingpin/forms/FormContent';
import NonPortalAccountsGate from '../NonPortalAccountsGate';
import PermissionGates from '../PermissionGates';
import PERMISSIONS from '../../permissionDefinitions';
import Typography from '../../kingpin/atoms/Typography';
import Inputs from '../Inputs';

const ReportForm = ({
  isEditing,
  isOpen,
  onSubmit,
  close,
  isLoading,
  title,
  onTitleChanged,
  isValid,
  isSaveAs,
  onBackPressed,
  access,
  setAccess,
  canSetToPrivate,
  isMarkedAsPopup,
  setIsMarkedAsPopup,
  isMarkedAsSEOnly,
  setIsMarkedAsSEOnly,
}: {
  isEditing: boolean;
  isOpen: boolean;
  onSubmit: () => void;
  close: () => void;
  isLoading: boolean;
  title: string;
  onTitleChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  isValid: boolean;
  isSaveAs: boolean;
  onBackPressed?: () => void;
  access: ResourceAccess;
  setAccess: React.Dispatch<React.SetStateAction<ResourceAccess>>;
  canSetToPrivate?: boolean;
  isMarkedAsSEOnly: boolean;
  setIsMarkedAsSEOnly: React.Dispatch<React.SetStateAction<boolean>>;
  isMarkedAsPopup: boolean;
  setIsMarkedAsPopup: React.Dispatch<React.SetStateAction<boolean>>;
}) => (
  <React.Fragment>
    {isOpen && (
      <Form>
        <FormHeader
          title={
            isEditing ? 'Edit Report' : isSaveAs ? 'Save As' : 'Create Report'
          }
          onClose={close}
        />
        <FormContent>
          <div style={{ marginBottom: 16 }}>
            <TextInput
              inputSize="Small"
              maxLength={40}
              label={'Report Title (max. 40 characters)'}
              value={title}
              onChange={onTitleChanged}
              data-testid={'report-title-input'}
            />
          </div>
          <NonPortalAccountsGate>
            <div>
              <AccessInput
                access={access}
                setAccess={setAccess}
                canSetToPrivate={canSetToPrivate}
              />
            </div>
          </NonPortalAccountsGate>
          <PermissionGates.Has
            requiredPermission={
              PERMISSIONS.CONTENT_ACCESS.MANAGE_REPORT_INDEX_FILTERS
            }
          >
            <div>
              <Row centerAlign style={{ gap: 8, marginBottom: 16 }}>
                <Inputs.Toggle
                  value={isMarkedAsPopup}
                  onChange={setIsMarkedAsPopup}
                />
                <Typography.Body type={'Label'}>Is Popup</Typography.Body>
              </Row>
              <Row centerAlign style={{ gap: 8, marginBottom: 16 }}>
                <Inputs.Toggle
                  value={isMarkedAsSEOnly}
                  onChange={setIsMarkedAsSEOnly}
                />
                <Typography.Body type={'Label'}>Is SE Only</Typography.Body>
              </Row>
            </div>
          </PermissionGates.Has>
        </FormContent>

        {onBackPressed && (
          <Row spaceBetween>
            <Button
              onClick={close}
              type="Secondary"
              size="Small"
              label="Cancel"
            />
            <Row style={{ justifyContent: 'flex-end' }}>
              <Button
                onClick={onBackPressed}
                type="Secondary"
                size="Small"
                label="Back"
              />
              <div style={{ marginRight: 8 }} />
              <Button
                isDisabled={isLoading || !isValid}
                isLoading={isLoading}
                onClick={onSubmit}
                label={'Save'}
                type="Primary"
                size="Small"
              />
            </Row>
          </Row>
        )}
        {!onBackPressed && (
          <Row style={{ justifyContent: 'flex-end' }}>
            <Button
              onClick={close}
              label="Cancel"
              type="Secondary"
              size="Small"
            />
            <div style={{ marginRight: 8 }} />
            <Button
              isDisabled={isLoading || !isValid}
              isLoading={isLoading}
              onClick={onSubmit}
              label="Save"
              type="Primary"
              size="Small"
            />
          </Row>
        )}
      </Form>
    )}
  </React.Fragment>
);

export default ReportForm;
