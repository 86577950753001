import React, { useCallback, useContext, useEffect, useState } from 'react';
import AnalyticsContext from '../../../contexts/AnalyticsContext';

const useSortOptions = ({
  sortMode,
  setSortMode,
}: {
  sortMode: SortMode;
  setSortMode: React.Dispatch<React.SetStateAction<SortMode>>;
}) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const [sortOptions, setSortOptions] = useState<DropdownOption[]>([]);

  const onSortSelected = useCallback(
    (newSort: SortMode) => {
      setSortMode((currentMode) => {
        if (currentMode === newSort) {
          return currentMode;
        }

        return newSort;
      });
    },
    [setSortMode],
  );

  useEffect(() => {
    const newOptions = [
      {
        label: 'Alphabetical',
        value: 'Alphabetical',
        onSelected: () => {
          onSortSelected('Alphabetical');
          trackEvent('Metrics - Sort by alphabetical clicked');
        },
        isSelected: sortMode === 'Alphabetical',
      },
      {
        label: 'Usage',
        value: 'Usage',
        onSelected: () => {
          onSortSelected('Usage');
          trackEvent('Metrics - Sort by usage clicked');
        },
        isSelected: sortMode === 'Usage',
      },
    ];

    setSortOptions(newOptions);
  }, [onSortSelected, sortMode, trackEvent]);

  return sortOptions;
};

export default useSortOptions;
