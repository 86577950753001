import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import Button from 'kingpin/atoms/Button';

import MetricOptionsContext from '../../../../contexts/MetricOptionsContext';
import Row from '../../../Common/Row';
import Typography from 'kingpin/atoms/Typography';
import Colors2 from '../../../../theme/Colors2';
import Icon from '../../../../kingpin/atoms/Icon';

const RemoveButton = styled.div`
  height: 32px;
  padding: 8px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const List = styled.div`
  overflow-y: auto;
  max-height: 300px;
  margin-bottom: 12px;
`;

const ListItem = ({
  kpi,
  onKpiDeleted,
  onKpiStartEdit,
}: {
  kpi: GoalKPI;
  onKpiDeleted: (kpi: GoalKPI) => void;
  onKpiStartEdit: (kpi: GoalKPI) => void;
}) => {
  const { metricOptions } = useContext(MetricOptionsContext);
  const metric = metricOptions.find((mo) => mo.id === kpi.metricId);
  const onDeleted = useCallback(() => {
    onKpiDeleted(kpi);
  }, [kpi, onKpiDeleted]);
  const onEdit = useCallback(() => {
    onKpiStartEdit(kpi);
  }, [kpi, onKpiStartEdit]);

  if (!metric) {
    return null;
  }

  return (
    <Row spaceBetween centerAlign>
      <div>
        <Typography.Body type="Body 12">{metric.name}</Typography.Body>
      </div>
      <Row centerAlign>
        <RemoveButton
          onClick={onEdit}
          style={{ marginRight: 8 }}
          data-testid={`edit-kpi-${kpi.id}`}
        >
          <Icon icon="edit-filled" color={Colors2.Grey['1']} />
        </RemoveButton>
        <RemoveButton onClick={onDeleted}>
          <Icon icon="cross" color={Colors2.Grey['1']} />
        </RemoveButton>
      </Row>
    </Row>
  );
};

const KPIInputs = ({
  kpis,
  onAddKpiClicked,
  onKpiDeleted,
  onKpiStartEdit,
}: {
  kpis: GoalKPI[];
  onAddKpiClicked: () => void;
  onKpiDeleted: (kpi: GoalKPI) => void;
  onKpiStartEdit: (kpi: GoalKPI) => void;
}) => (
  <div>
    <List>
      {kpis.map((kpi) => (
        <ListItem
          key={kpi.id}
          kpi={kpi}
          onKpiDeleted={onKpiDeleted}
          onKpiStartEdit={onKpiStartEdit}
        />
      ))}
    </List>
    <Button
      onClick={onAddKpiClicked}
      type="Primary"
      size="Small"
      label="Add KPI"
      icon={'add'}
    />
  </div>
);

export default KPIInputs;
