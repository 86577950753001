import React from 'react';
import Typography from 'kingpin/atoms/Typography';
import Row from '../../Common/Row';
import styled from 'styled-components';
import Colors from 'theme/colors';
import Colors2 from '../../../theme/Colors2';

const Item = styled.div<{ isSelected: boolean }>`
  height: 50px;
  margin-bottom: 4px;
  background-color: ${(props) =>
    props.isSelected ? Colors.SATURATED_NAVY_BLUE : '#fafbfc'};
  padding: 8px;
  cursor: pointer;
`;

const Icon = styled.img`
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  margin-right: 4px;
`;

const TimelineEvent = ({
  iconSrc,
  label,
  subLabel,
  underLabel,
  timeLabel,
  isSelected,
  onClick,
}: {
  iconSrc: string;
  label: string;
  subLabel: string;
  underLabel: string;
  timeLabel: string;
  isSelected: boolean;
  onClick: () => void;
}) => (
  <Item onClick={onClick} isSelected={isSelected}>
    <Row>
      <Icon src={iconSrc} />
      <Row spaceBetween style={{ width: '100%' }}>
        <div>
          <div>
            <Typography.Body type={'Label'}>
              <b>{label}</b> {subLabel}
            </Typography.Body>
          </div>
          <div>
            <Typography.Body type={'Label'} color={Colors2.Grey['5']}>
              {underLabel}
            </Typography.Body>
          </div>
        </div>
        <div>
          <Typography.Body type={'Label'} color={Colors2.Grey['5']}>
            {timeLabel}
          </Typography.Body>
        </div>
      </Row>
    </Row>
  </Item>
);

export default TimelineEvent;
