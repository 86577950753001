import React, { useCallback, useContext, useRef, useState } from 'react';
import styled from 'styled-components';

import Typography from 'kingpin/atoms/Typography';
import { CellPreview } from '../ScorecardKpiForm/TargetBandsForm/TargetBandsForm';
import CommentsProvider from '../../../../contextProviders/CommentsProvider';
import SlideOutContext from '../contexts/SlideoutContext';
import CommentsContext from '../../../../contexts/CommentsContext';
import DashboardContext from 'contexts/DashboardContext';
import AnalyticsContext from 'contexts/AnalyticsContext';
import Colors2 from '../../../../theme/Colors2';
import { getTextColor } from 'screens/ScorecardsIndex/constants';
import Tooltip from '../../../Tooltip';
import PerformanceTooltip from './PerformanceTooltip';
import useScorecardPopupCommentId from '../../../../hooks/useScorecardPopupCommentId';
import useKpiValue from '../hooks/useKpiValue';
import useKpiFormatter from '../hooks/useKpiFormatter';
import useTargetBands from '../hooks/useTargetBands';
import useKpiColor from '../hooks/useKpiColor';

const OpacityWrapper = styled.div<{
  formattedValue?: string | undefined;
  cellHovered?: boolean;
}>`
  display: flex;
  opacity: ${(props) => (props.formattedValue || props.cellHovered ? 1 : 0.3)};
`;

const HasCommentsIndicator = () => {
  const { comments } = useContext(CommentsContext);
  if (!comments || comments.length === 0) {
    return null;
  }

  return (
    <div style={{ position: 'absolute', top: 0, right: 0 }}>
      <div
        style={{
          width: 0,
          height: 0,
          borderRight: `8px solid ${Colors2.AvatarColors['4'].text}`,
          borderLeft: '8px solid transparent',
          borderBottom: '8px solid transparent',
        }}
      />
    </div>
  );
};

export const isNumber = (value: number | null | undefined): value is number =>
  typeof value === 'number';

const KpiValue = ({
  period,
  kpi,
  trend,
  metric,
}: {
  period: Period;
  kpi: Scorecards.ScorecardKpi | Scorecards.ManualKpiRow;
  trend: Goals.TrendResult[];
  metric?: Metrics.Metric;
}) => {
  const {
    slideoutKpi,
    slideoutPeriod,
    isOpen,
    setIsOpen,
    setSlideoutKpi,
    setSlideoutPeriod,
    setSlideoutTrend,
    setSlideoutMetric,
  } = useContext(SlideOutContext);
  const { dashboard } = useContext(DashboardContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const { commentableId, commentableType } = useScorecardPopupCommentId({
    period,
    kpi,
  });
  const cellRef = useRef<HTMLDivElement | null>(null);
  const [cellHovered, setCellHovered] = useState<boolean>(false);

  const { targetBandsForPeriod, isDynamic, labelBandsForPeriod } =
    useTargetBands(kpi, period);
  const getKpiValue = useKpiValue();
  const value = getKpiValue({ trend, period });
  const { formattedValue } = useKpiFormatter({
    kpi,
    value,
    metric,
  });
  const getKpiColor = useKpiColor();
  const color = getKpiColor({ kpi, value, targetBandsForPeriod });

  const onClick = useCallback(() => {
    setIsOpen(true);
    setSlideoutKpi(kpi);
    setSlideoutPeriod(period);
    setSlideoutTrend(trend);
    setSlideoutMetric(metric);
    setTimeout(() => {
      if (!isOpen && cellRef.current && !!cellRef.current.scrollIntoView) {
        cellRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    }, 50);
    trackEvent('KPI List - KPI - Slideout opened');
  }, [
    isOpen,
    kpi,
    metric,
    period,
    setIsOpen,
    setSlideoutKpi,
    setSlideoutMetric,
    setSlideoutPeriod,
    setSlideoutTrend,
    trackEvent,
    trend,
  ]);

  const textColor = getTextColor({ color });

  if (!targetBandsForPeriod) {
    return null;
  }

  return (
    <CommentsProvider
      commentableType={commentableType}
      commentableId={commentableId}
    >
      <div style={{ width: '100%' }}>
        <Tooltip
          content={
            kpi.isTargetsDisabled ? null : (
              <PerformanceTooltip
                formattedValue={formattedValue || ''}
                kpi={kpi}
                cellColor={color}
                targetBandsForPeriod={labelBandsForPeriod}
                isDynamic={isDynamic}
              />
            )
          }
          isAltTooltip
        >
          <CellPreview
            onMouseEnter={() => setCellHovered(true)}
            onMouseLeave={() => setCellHovered(false)}
            onClick={dashboard ? undefined : onClick}
            ref={cellRef}
            cellColor={color}
            data-testid={`${kpi.id}-${period.startDate}`}
            isSelected={
              kpi === slideoutKpi && period === slideoutPeriod && isOpen
            }
          >
            <OpacityWrapper
              formattedValue={formattedValue}
              cellHovered={cellHovered}
            >
              <Typography.Body type="Body 12" color={textColor}>
                {formattedValue || '-'}
              </Typography.Body>
            </OpacityWrapper>

            <HasCommentsIndicator />
          </CellPreview>
        </Tooltip>
      </div>
    </CommentsProvider>
  );
};

export default KpiValue;
