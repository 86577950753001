import styled, { css } from 'styled-components';
import Colors from '../../../theme/colors';

export const heavyShadow =
  '0 0 3px 1px rgba(0,0,0,0.05), 0 1px 1px 0 rgba(9,30,66,0.25)';

const noPaddingStyle = css`
  padding: unset;
`;

const darkMode = css`
  background-color: ${Colors.FLEETOPS_PURPLE};
  box-shadow: unset;
  border: 2px solid #483e57;
  border-radius: 5px;

  &:hover {
    background-color: ${Colors.FLEETOPS_PURPLE};
    opacity: 0.7;
  }
`;

const getHoverBackgroundColor = (
  darkenOnHover?: boolean,
  noBackground?: boolean,
) => {
  if (noBackground) {
    return 'unset';
  }

  if (darkenOnHover) {
    return Colors.BLUE_GREY;
  }

  return Colors.WHITE;
};

const Card = styled.div<{
  darkenOnHover?: boolean;
  hideBorders?: boolean;
  heavyShadow?: boolean;
  compactShadow?: boolean;
  darkMode?: boolean;
  noBackground?: boolean;
  noPadding?: boolean;
  clickable?: boolean;
}>`
  position: relative;
  border-radius: 8px;
  border: ${(props) => (props.hideBorders ? 'unset' : '1px solid #ededed')};
  background-color: ${(props) => (props.noBackground ? 'unset' : Colors.WHITE)};
  ${(props) => props.noPadding && noPaddingStyle};
  pointer-events: auto;

  &:hover {
    background-color: ${(props) =>
      getHoverBackgroundColor(props.darkenOnHover, props.noBackground)};
  }

  ${(props) => props.darkMode && darkMode};
  cursor: ${(props) => (props.clickable ? 'pointer' : 'unset')};
  overflow: hidden;
`;

export default Card;
