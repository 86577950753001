import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Modal from '@atlaskit/modal-dialog';
import _ from 'lodash';
import Button from 'kingpin/atoms/Button';

import ConfigureMicrosoftSsoContext from '../contexts/ConfigureMicrosoftSsoContext';
import Row from 'components/Common/Row';

import CloudFunctionClientContext from 'contexts/CloudFunctionClientContext';
import ToastContext from 'contexts/ToastContext';
import useAvailableGroups from '../hooks/useAvailableGroups';
import patchConfiguration from '../api/patchConfiguration';
import Heading from './Heading';
import GroupInputCard from './GroupInputCard';
import UsersGrid from './UsersGrid';
import FlexCentered from '../../../../../components/Common/FlexCentered';
import Loading from 'components/Loading';
import CloseModal from '../../../../../components/CloseModal';
import useConfirmation from '../../../../DataManager/DatasetDefinitions/DatasetsIndex/PerformanceDatasetWizard/useConfirmation';
import AnalyticsContext from '../../../../../contexts/AnalyticsContext';

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;
  flex: 1;
  height: 100%;
  position: relative;
`;

const useButtonText = ({
  isSsoActive,
  selectedGroups,
  initialGroups,
}: {
  isSsoActive: boolean;
  selectedGroups: MicrosoftSso.Group[];
  initialGroups: MicrosoftSso.Group[];
}) => {
  const getBtnText = useCallback(() => {
    const hasChanges = !_.isEqual(selectedGroups, initialGroups);
    if (!isSsoActive) {
      return 'Activate SSO';
    }

    if (hasChanges) {
      return 'Update SSO Config';
    }

    return 'Sync Users';
  }, [initialGroups, isSsoActive, selectedGroups]);
  const [btnText, setBtnText] = useState<string>();
  useEffect(() => {
    setBtnText(getBtnText());
  }, [getBtnText]);

  return btnText;
};

const ManageGroupsPopupContainer = ({
  close,
  initialGroups,
  refreshConfig,
  isSsoActive,
}: {
  close: () => void;
  initialGroups: MicrosoftSso.Group[];
  refreshConfig: () => void;
  isSsoActive: boolean;
}) => {
  const {
    ConfirmationModal: UnsavedChangesConfirmationModal,
    getConfirmation: getCloseConfirmation,
  } = useConfirmation({
    title: 'Unsaved Changes',
    body:
      'If you navigate away, all changes made will be lost. Are you sure' +
      ' you want to discard these changes?',
    confirmText: 'Yes, Discard Changes',
  });
  const { availableGroups, isLoading } = useAvailableGroups();
  const { usEastApi: api } = useContext(CloudFunctionClientContext);
  const { showToast } = useContext(ToastContext);
  const { trackEvent } = useContext(AnalyticsContext);

  const [selectedGroups, setSelectedGroups] = useState<MicrosoftSso.Group[]>(
    () => initialGroups,
  );
  const buttonText = useButtonText({
    isSsoActive,
    selectedGroups,
    initialGroups,
  });
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    trackEvent('SSO Configurations - Microsoft - Opened');
  }, [trackEvent]);

  const onActiveSsoClicked = useCallback(() => {
    if (!isValid) {
      return;
    }

    setIsSaving(true);
    patchConfiguration(
      api,
      selectedGroups.map((g) => g.id),
    ).then((isOk) => {
      setIsSaving(false);
      if (isOk) {
        if (isSsoActive) {
          trackEvent('SSO Configurations - Microsoft - Clicked Activate');
          showToast('Sync in progress');
        } else {
          trackEvent('SSO Configurations - Microsoft - Updated Config');
          showToast('Setup in progress');
        }
        refreshConfig();
        close();
      } else {
        alert('Something has gone wrong');
      }
    });
  }, [
    api,
    close,
    isSsoActive,
    isValid,
    refreshConfig,
    selectedGroups,
    showToast,
    trackEvent,
  ]);

  const onCloseClicked = useCallback(() => {
    const hasChanges = !_.isEqual(
      selectedGroups.map((g) => g.id),
      initialGroups.map((g) => g.id),
    );
    if (hasChanges) {
      getCloseConfirmation().then((isConfirmed) => {
        trackEvent('SSO Configurations - Microsoft - Discarded changes');
        if (isConfirmed) {
          close();
        }
      });
    } else {
      close();
    }
  }, [close, getCloseConfirmation, initialGroups, selectedGroups, trackEvent]);

  return (
    <ConfigureMicrosoftSsoContext.Provider
      value={{
        initialGroups,
        selectedGroups,
        setSelectedGroups,
        availableGroups,
        setIsValid,
      }}
    >
      <Modal
        onClose={isSaving ? undefined : onCloseClicked}
        shouldScrollInViewport={false}
        autoFocus={false}
        width={'70vw'}
        height={'85vh'}
      >
        <ContentDiv>
          {(isSaving || isLoading) && (
            <FlexCentered>
              <Loading />
            </FlexCentered>
          )}
          {!(isSaving || isLoading) && (
            <>
              <CloseModal close={onCloseClicked} />

              <div
                style={{
                  overflow: 'hidden',
                  marginBottom: 24,
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  position: 'relative',
                }}
              >
                <Heading />
                <GroupInputCard />
                <UsersGrid selectedGroups={selectedGroups} />
              </div>
              <Row centerAlign spaceBetween>
                {!isSaving && (
                  <Button
                    onClick={onCloseClicked}
                    label="Cancel"
                    type="Tertiary"
                    size="Small"
                  />
                )}
                <Button
                  onClick={onActiveSsoClicked}
                  label={buttonText}
                  isDisabled={!isValid}
                  type="Primary"
                  size="Small"
                />
              </Row>
            </>
          )}
        </ContentDiv>
      </Modal>
      {UnsavedChangesConfirmationModal}
    </ConfigureMicrosoftSsoContext.Provider>
  );
};

export default ManageGroupsPopupContainer;
