import React from 'react';
import FallbackGroupInput from './FallbackGroupInput';
import FlatGroupInput from './FlatGroupInput';
import TallGroupInput from './TallGroupInput';
import useIsGlowOnMount from '../../hooks/useIsGlowOnMount';
import GroupInputStyles from './styles';
import GlowingDiv from '../../GlowingDiv';
import useHasUnsavedChanges from '../../hooks/useHasUnsavedChanges';

// "Opened" and "closed" conflict with deeper pose names
const variants = {
  fullDiv: { opacity: 1, height: 'unset' },
  shrunkDiv: { opacity: 0, height: 0 },
};

const RenderGroup = ({ group }: { group: Targets.Wizard.TargetGroup }) => {
  const isFlatGroup =
    group.targets.length === 1 && group.targets[0].rules.length === 0;

  if (group.isFallback) {
    return <FallbackGroupInput group={group} />;
  } else if (isFlatGroup) {
    return <FlatGroupInput group={group} />;
  } else {
    return <TallGroupInput group={group} />;
  }
};

const GroupInputContainer = ({
  group,
}: {
  group: Targets.Wizard.TargetGroup;
}) => {
  const hasUnsavedChanges = useHasUnsavedChanges();
  const isGlowOnMount = useIsGlowOnMount(group);

  return (
    <div id={group.key}>
      <GroupInputStyles.ContainerDiv
        animate="fullDiv"
        initial={hasUnsavedChanges ? 'shrunkDiv' : 'fullDiv'}
        variants={variants}
      >
        <GlowingDiv glowOnMount={isGlowOnMount}>
          <GroupInputStyles.Block>
            <div style={{ width: '100%', overflowX: 'hidden' }}>
              <RenderGroup group={group} />
            </div>
          </GroupInputStyles.Block>
        </GlowingDiv>
      </GroupInputStyles.ContainerDiv>
    </div>
  );
};

export default GroupInputContainer;
