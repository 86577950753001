import React from 'react';
import DatasetFilterPickerItem from 'components/DatasetFilterPickerItem';
import Typography from 'kingpin/atoms/Typography';
import Colors2 from 'theme/Colors2';

const DatasetFilterList = ({
  savedDatasetFilters,
  onOptionSelected,
  isForDatasetFilterPicker,
}: {
  savedDatasetFilters: DatasetFilter[];
  onOptionSelected: (
    field:
      | FleetOps.Field
      | DateBucketField
      | VariableDrillDownType
      | DatasetFilter,
  ) => void;
  isForDatasetFilterPicker?: boolean;
}) => {
  const isShowingDatasetFilterList =
    savedDatasetFilters &&
    savedDatasetFilters.length !== 0 &&
    !isForDatasetFilterPicker;

  if (!isShowingDatasetFilterList) {
    return null;
  }

  return (
    <>
      <div
        style={{
          borderBottom: `1px solid ${Colors2.Border}`,
          marginBottom: '8px',
        }}
      ></div>
      <div style={{ marginLeft: '4px' }}>
        <Typography.Header type="H6" color={Colors2.Grey['5']}>
          Saved Filters
        </Typography.Header>
      </div>

      {savedDatasetFilters.map((filter) => (
        <DatasetFilterPickerItem
          key={filter.id}
          filter={filter}
          onOptionSelected={onOptionSelected}
        />
      ))}
      <div
        style={{
          borderBottom: `1px solid ${Colors2.Border}`,
          marginBottom: '16px',
          marginTop: '8px',
        }}
      ></div>
      <div style={{ marginLeft: '4px' }}>
        <Typography.Header type="H6" color={Colors2.Grey['5']}>
          Fields
        </Typography.Header>
      </div>
    </>
  );
};

export default DatasetFilterList;
