import React from 'react';

interface DetailsSlideOutContextType {
  selectedOrderNumber?: string;
  setSelectedOrderNumber: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  isDetailsSlideOpen: boolean;
  openDetailsSlide: () => void;
  closeDetailsSlide: () => void;
}

const DetailsSlideOutContext = React.createContext<DetailsSlideOutContextType>(
  {} as DetailsSlideOutContextType,
);

export default DetailsSlideOutContext;
