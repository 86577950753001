import React from 'react';
import styled from 'styled-components';
import Typography from 'kingpin/atoms/Typography';
import Colors2 from '../../theme/Colors2';

const ContainerDiv = styled.div`
  text-align: center;

  a {
    color: ${Colors2.Grey['3']};
    text-decoration: underline;
  }
`;

const Legal = () => (
  <ContainerDiv>
    <Typography.Body type="Body 12">
      By signing up for FleetOps, you agree to our
    </Typography.Body>
    <br />
    <a
      href={'https://fleetops.com/terms'}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Typography.Body type="Link">Terms of Service</Typography.Body>
    </a>{' '}
    <Typography.Body type="Body 12">and </Typography.Body>
    <a
      href={'https://fleetops.com/privacy'}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Typography.Body type="Link">Privacy Policy</Typography.Body>
    </a>
  </ContainerDiv>
);

export default Legal;
