import React from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

interface TargetsDragHandleContextType {
  dragHandleProps?: DraggableProvidedDragHandleProps;
  isGroupBlock: boolean;
}

const TargetsDragHandleContext =
  React.createContext<TargetsDragHandleContextType>({
    isGroupBlock: false,
  });

export default TargetsDragHandleContext;
