import React from 'react';
import Button from 'kingpin/atoms/Button';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  margin-right: 16px;
`;

const ResetReportButton = ({ onClick }: { onClick: () => void }) => (
  <Wrapper>
    <Button onClick={onClick} type="Ghost" size="Small" label="Clear all" />
  </Wrapper>
);

export default ResetReportButton;
