import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import {
  ColDef,
  FirstDataRenderedEvent,
  GridOptions,
  GridReadyEvent,
  ModelUpdatedEvent,
  RowClickedEvent,
  SortChangedEvent,
} from 'ag-grid-community';
import styled from 'styled-components';

import StyleOverrides, {
  GRID_BORDERS_CSS,
  GRID_DARK_HEADER_CSS,
} from './StyleOverrides';
import Row from '../Common/Row';
import DetailsSlideOut from '../DetailsSlideOut';
import CustomerLaneSlideOut from '../CustomerLaneCommitsBoard/SlideOut';
import NewCommitmentSlideOut from '../CustomerLaneCommitsBoard/NewCommitPopup/NewCommitmentSlideOut';

// Ag-grid imports and setup
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';

import { LicenseManager } from 'ag-grid-enterprise';
import { agGridLicenseKey } from './license';
import BoardSlideOut from '../BoardSlideOut';
import NoDataToDisplay from '../V5Gadget/NoDataToDisplay';
import FlexCentered from '../Common/FlexCentered';
import Loading from '../Loading';
import GridFrameworkComponents from './GridFrameworkComponents';
import PerformanceBoard from 'components/PerformanceBoards';
import { Z_INDEX } from '../../constants';

LicenseManager.setLicenseKey(agGridLicenseKey);

const Wrapper = styled.div<{ hasBorders: boolean; hasDarkHeader: boolean }>`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  position: relative;
  overflow: hidden;

  ${(props) => props.hasBorders && GRID_BORDERS_CSS}
  ${(props) => props.hasDarkHeader && GRID_DARK_HEADER_CSS}
`;

const Left = styled.div<{ isDetailsSlideOpen?: boolean }>`
  height: 100%;
  width: ${(props) => (props.isDetailsSlideOpen ? '70%' : '100%')};
`;

// To be used in search screen, popup grids and boards
const buildOnFirstDataRendered = ({
  gridId,
  disableAutoLayout,
  skipFirstLayout,
  autoSizeFirstColumnOnly,
  autoSizeSecondColumnOnly,
}: {
  gridId: string;
  disableAutoLayout?: boolean;
  skipFirstLayout?: boolean;
  autoSizeFirstColumnOnly?: boolean;
  autoSizeSecondColumnOnly?: boolean;
}) => {
  let skipLayout = !!skipFirstLayout;

  return (event: FirstDataRenderedEvent | ModelUpdatedEvent) => {
    if (disableAutoLayout || skipLayout) {
      skipLayout = false;
      return;
    }

    const interval = setInterval(() => {
      const isReady = (() => {
        if (document.visibilityState !== 'visible') {
          return false;
        }
        const elem = document.getElementById(gridId);
        if (elem) {
          const container = elem.getElementsByClassName(
            'ag-center-cols-container',
          )[0];
          if (container) {
            const rows = container.getElementsByClassName('ag-row').length;
            if (rows > 0) {
              return true;
            }
          }
        }
        return false;
      })();

      if (isReady) {
        clearInterval(interval);

        const cols = event.api.getAllDisplayedColumns();
        if (cols === undefined || cols.length === 0) {
          return;
        }

        if (process.env.NODE_ENV === 'test') {
          return;
        }

        if (autoSizeFirstColumnOnly) {
          event.api.autoSizeColumns([cols[0].getId()]);
        } else if (autoSizeSecondColumnOnly) {
          event.api.autoSizeColumns([cols[1].getId()]);
        } else {
          event.api.autoSizeAllColumns(true);
        }
      }
    });
  };
};

const Grid = ({
  rowData,
  columnDefs,
  columnDefs2,
  onGridReady,
  gridOptions,
  totalsRow,
  gridId,
  bottomBar,
  isDetailsSlideOpen,
  isCustomerLaneSlideOpen,
  isNewCommitmentSlideOpen,
  isBoard,
  onRowClicked,
  onSortChanged,
  isPerformanceSettings,
  noDataToDisplay,
  isLoading,
  disableAutoLayout,
  layoutOnColumnChange,
  layoutOnFirstRender,
  layoutOnModelUpdated,
  autoSizeFirstColumnOnly,
  autoSizeSecondColumnOnly,
  hasBorders,
  hasDarkHeader,
  isSearchScreen,
}: {
  rowData?: any[];
  columnDefs: ColDef[];
  columnDefs2?: MatrixColDef[];
  gridOptions: GridOptions;
  gridId: string;
  totalsRow?: any;
  onGridReady?: (event: GridReadyEvent) => void;
  bottomBar?: JSX.Element | JSX.Element[];
  isDetailsSlideOpen?: boolean;
  isCustomerLaneSlideOpen?: boolean;
  isNewCommitmentSlideOpen?: boolean;
  isBoard?: boolean;
  onRowClicked?: (event: RowClickedEvent) => void;
  onSortChanged?: (event: SortChangedEvent) => void;
  disableAutoLayout?: boolean;
  isPerformanceSettings?: boolean;
  noDataToDisplay?: boolean;
  isLoading?: boolean;
  layoutOnModelUpdated?: boolean;
  layoutOnFirstRender?: boolean;
  layoutOnColumnChange?: boolean;
  autoSizeFirstColumnOnly?: boolean;
  autoSizeSecondColumnOnly?: boolean;
  hasBorders: boolean;
  hasDarkHeader: boolean;
  isSearchScreen?: boolean;
}) => (
  <>
    <Wrapper
      id={gridId}
      className={'gridWrapper'}
      data-testid="grid"
      hasBorders={hasBorders}
      hasDarkHeader={hasDarkHeader}
    >
      <Left isDetailsSlideOpen={isDetailsSlideOpen}>
        {isLoading && (
          <FlexCentered style={{ height: '100%' }}>
            <Loading />
          </FlexCentered>
        )}
        {noDataToDisplay && !isLoading && (
          <FlexCentered style={{ height: '100%' }}>
            <NoDataToDisplay size={'normal'} />
          </FlexCentered>
        )}
        {!noDataToDisplay && !isLoading && (
          <StyleOverrides
            isPerformanceSettings={isPerformanceSettings}
            clickable={!!onRowClicked}
            style={{
              height: '100%',
              width: '100%',
            }}
          >
            <div
              style={{
                height: '100%',
                width: '100%',
              }}
              className={`ag-theme-balham ag-theme-fleetops ${isSearchScreen ? 'ag-theme-fleetops-search' : ''}`}
            >
              <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs2 ? columnDefs2 : columnDefs}
                onRowClicked={onRowClicked}
                onGridReady={onGridReady}
                gridOptions={gridOptions}
                pinnedBottomRowData={totalsRow ? totalsRow : undefined}
                components={GridFrameworkComponents}
                loadingOverlayComponent={Loading}
                onSortChanged={onSortChanged}
                onFirstDataRendered={
                  layoutOnFirstRender
                    ? buildOnFirstDataRendered({
                        gridId,
                        disableAutoLayout,
                        skipFirstLayout: false,
                        autoSizeFirstColumnOnly,
                        autoSizeSecondColumnOnly,
                      })
                    : undefined
                }
                onModelUpdated={
                  layoutOnModelUpdated
                    ? buildOnFirstDataRendered({
                        gridId,
                        disableAutoLayout,
                        skipFirstLayout: true,
                        autoSizeFirstColumnOnly,
                        autoSizeSecondColumnOnly,
                      })
                    : undefined
                }
                onGridColumnsChanged={
                  layoutOnColumnChange
                    ? buildOnFirstDataRendered({
                        gridId,
                        disableAutoLayout,
                        skipFirstLayout: true,
                        autoSizeFirstColumnOnly,
                        autoSizeSecondColumnOnly,
                      })
                    : undefined
                }
              />
            </div>
          </StyleOverrides>
        )}
        <Row style={{ paddingLeft: 11, paddingRight: 11 }}>{bottomBar}</Row>
      </Left>
      {isDetailsSlideOpen && (
        <div
          style={{
            height: '100%',
            minWidth: 275,
            width: '30%',
            zIndex: Z_INDEX.GRID_SLIDE_OUT,
            fontSize: 11,
          }}
        >
          <DetailsSlideOut />
        </div>
      )}
      {isCustomerLaneSlideOpen && (
        <div
          style={{
            height: '100%',
            minWidth: 500,
            width: '35%',
            zIndex: Z_INDEX.GRID_SLIDE_OUT,
            fontSize: 11,
          }}
        >
          <CustomerLaneSlideOut />
        </div>
      )}
      {!isBoard && isNewCommitmentSlideOpen && (
        <div
          style={{
            height: '100%',
            minWidth: 275,
            width: '35%',
            zIndex: Z_INDEX.GRID_SLIDE_OUT,
            fontSize: 11,
          }}
        >
          <NewCommitmentSlideOut />
        </div>
      )}
      <BoardSlideOut />
      <PerformanceBoard.SlideOut />
    </Wrapper>
  </>
);

export default Grid;
