const isExecutivePortal = (
  portal: Portal | undefined,
): portal is ExecutivePortal => !!portal && portal.type === 'ExecutivePortal';

const isEngagementPortal = (
  portal: Portal | undefined,
): portal is EngagementPortal =>
  !!portal && portal.type === 'Engagement Portal';

const isAdminPortal = (portal: Portal | undefined): portal is AdminPortal =>
  !!portal && portal.type === 'Admin Portal';

const portalTypeCheckers = {
  isExecutivePortal,
  isEngagementPortal,
  isAdminPortal,
};

export default portalTypeCheckers;
