// Blur on escape pressed
import { useCallback, useEffect } from 'react';

const useCloseOnEscape = ({
  isOpen,
  close,
  resetState,
}: {
  isOpen: boolean;
  close: () => void;
  resetState: () => void;
}) => {
  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        resetState();
        close();
      }
    },
    [close, resetState],
  );

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('keydown', onKeyDown);
      return () => {
        window.removeEventListener('keydown', onKeyDown);
      };
    }

    return () => {
      resetState();
    };
  }, [isOpen, onKeyDown, resetState]);
};

export default useCloseOnEscape;
