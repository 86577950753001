import Colors2 from '../../theme/Colors2';

const ExpressionColors = [
  Colors2.Secondary.info,
  Colors2.Secondary.success,
  Colors2.Secondary.error,
  Colors2.Secondary.warning,
];

const getTermColor = (colorIndex: number): string => {
  const safeIndex = colorIndex % (ExpressionColors.length - 1);

  try {
    return ExpressionColors[safeIndex];
  } catch (ex) {
    return ExpressionColors[0];
  }
};

export default getTermColor;
