import { useCallback, useEffect, useState } from 'react';

const useSelectedFields = (
  initialFields: { dataType: string; fields: string[] }[],
) => {
  const [selectedFields, setSelectedFields] =
    useState<{ dataType: string; fields: string[] }[]>(initialFields);
  const [count, setCount] = useState<number>(0);

  // setCount
  useEffect(() => {
    let c = 0;
    selectedFields.forEach(({ fields }) => {
      c += fields.length;
    });
    setCount(c);
  }, [selectedFields]);

  const onFieldAdded = useCallback(
    ({ field, dataType }: { field: string; dataType: string }) => {
      setSelectedFields((currentFields) => {
        if (currentFields.some((f) => f.dataType === dataType)) {
          return currentFields.map((c) => {
            if (c.dataType === dataType) {
              return {
                ...c,
                fields: [...c.fields, field],
              };
            }
            return c;
          });
        } else {
          return [...currentFields, { dataType, fields: [field] }];
        }
      });
    },
    [],
  );

  const onFieldRemoved = useCallback(
    ({ field, dataType }: { field: string; dataType: string }) => {
      setSelectedFields((currentFields) => {
        const dataTypeFields = currentFields.find(
          (f) => f.dataType === dataType,
        );
        if (!dataTypeFields) {
          const e = new Error();
          e.name = `Datatype not found`;
          throw e;
        }

        if (dataTypeFields.fields.length === 1) {
          return currentFields.filter((c) => {
            if (c.dataType === dataType) {
              return false;
            }
            return true;
          });
        } else {
          return currentFields.map((c) => {
            if (c.dataType === dataType) {
              return {
                ...c,
                fields: c.fields.filter((f) => f !== field),
              };
            }
            return c;
          });
        }
      });
    },
    [],
  );

  return {
    selectedFields,
    selectedFieldsCount: count,
    onFieldAdded,
    onFieldRemoved,
  };
};

export default useSelectedFields;
