import React from 'react';
import styled, { css } from 'styled-components';
import colors from '../../../theme/colors';

const numberStyles = css`
  text-align: right;
  justify-content: flex-end;
`;

interface CellProps {
  rightAlign?: boolean;
  onClick?: (event: any) => void;
  children?: JSX.Element | JSX.Element[] | string;
  style?: any;
  disableLink?: boolean;
  warning?: boolean;
}

const warningStyles = css`
  border-left: 2px solid ${colors.AMBER};
  padding-left: 2px;
`;

const CellContainer = (props: CellProps) => {
  return <Cell {...props} />;
};

const Cell = styled.div<CellProps>`
  ${(props) => props.warning && warningStyles};
  ${(props) =>
    props.onClick &&
    !props.disableLink &&
    `
    cursor: pointer;
  `};
  ${(props) => props.rightAlign && numberStyles};
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;

  a {
    text-overflow: ellipsis !important;
    overflow: hidden !important;
  }

  div {
    text-overflow: ellipsis !important;
    overflow: hidden !important;
  }

  span {
    text-overflow: ellipsis !important;
    overflow: hidden !important;
  }
`;

export default CellContainer;
