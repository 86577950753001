import toSentenceCase from '../../../services/toSentenceCase';
import React, { ChangeEvent } from 'react';
import Row from '../../Common/Row';
import Dropdown from '../../Inputs/Dropdown';
import Inputs from '../../Inputs';
import Typography from 'kingpin/atoms/Typography';
import ChevronDownGrey from './ChevronDownGrey.svg';
import ChevronDown from './ChevronDown.png';
import ChevronUp from './ChevronUp.png';
import styled from 'styled-components';
import TextInput from '../../../kingpin/atoms/TextInput';
import Colors2 from '../../../theme/Colors2';

const ModeChangeWrapper = styled.div`
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const getLabel = (
  current: RelativeDateRange,
  interval: 'day' | 'week' | 'month' | 'quarter' | 'year',
): string => {
  if (current.type === 'current') {
    return toSentenceCase(interval);
  }

  if (current.n && current.n > 1) {
    return `${toSentenceCase(interval)}s`;
  }

  return toSentenceCase(interval);
};

const RelativeDateBuilder = ({
  testPrefix,
  current,
  setCurrent,
  isAdvanced,
  onModeTogglePressed,
  isPlaceholder,
}: {
  testPrefix: string;
  current: RelativeDateRange;
  setCurrent: (newD: RelativeDateRange) => void;
  isAdvanced?: boolean;
  onModeTogglePressed?: () => void;
  isPlaceholder?: boolean;
}) => (
  <div style={{ padding: 8 }}>
    <Row>
      {/* Inputs - Left */}
      <div>
        {/* Top */}
        <Row>
          <div style={{ width: 132, marginRight: 8 }}>
            <Dropdown
              isShort
              unsetWidth
              isPlaceholder={isPlaceholder}
              testId={`${testPrefix}relative-type-picker`}
              selectedLabel={toSentenceCase(current.type)}
              options={[
                {
                  label: 'Last',
                  onSelected: () => {
                    setCurrent({
                      ...current,
                      type: 'last',
                      n: current.n ? current.n : 1,
                      currentToDate: false,
                    });
                  },
                },
                {
                  label: 'Current',
                  onSelected: () => {
                    setCurrent({
                      ...current,
                      type: 'current',
                      n: undefined,
                      currentToDate: current.interval !== 'day',
                    });
                  },
                },
                {
                  label: 'Next',
                  onSelected: () => {
                    setCurrent({
                      ...current,
                      type: 'next',
                      n: current.n ? current.n : 1,
                      currentToDate: false,
                    });
                  },
                },
              ]}
            />
          </div>
          {current.type !== 'current' && (
            <div style={{ width: 132, marginRight: 8 }}>
              <TextInput
                inputSize="Small"
                data-testid={`${testPrefix}n`}
                type="number"
                style={isPlaceholder ? { color: Colors2.Grey['5'] } : undefined}
                state={current.n === undefined ? 'Error' : undefined}
                value={current.n ? current.n.toString(10) : ''}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  const newN = event.target.value;
                  if (newN === '') {
                    setCurrent({ ...current, n: undefined });
                  } else if (Number.parseInt(newN) > 0) {
                    setCurrent({ ...current, n: Number.parseInt(newN) });
                  }
                }}
                width={'100%'}
              />
            </div>
          )}

          <div style={{ width: 132 }}>
            <Dropdown
              isShort
              isPlaceholder={isPlaceholder}
              unsetWidth
              testId={`${testPrefix}relative-interval-picker`}
              selectedLabel={getLabel(current, current.interval)}
              options={[
                {
                  label: getLabel(current, 'day'),
                  onSelected: () => {
                    setCurrent({
                      ...current,
                      interval: 'day',
                      currentToDate: false,
                    });
                  },
                },
                {
                  label: getLabel(current, 'week'),
                  onSelected: () => {
                    setCurrent({
                      ...current,
                      interval: 'week',
                      currentToDate: false,
                    });
                  },
                },
                {
                  label: getLabel(current, 'month'),
                  onSelected: () => {
                    setCurrent({
                      ...current,
                      interval: 'month',
                      currentToDate: false,
                    });
                  },
                },
                {
                  label: getLabel(current, 'quarter'),
                  onSelected: () => {
                    setCurrent({
                      ...current,
                      interval: 'quarter',
                      currentToDate: false,
                    });
                  },
                },
                {
                  label: getLabel(current, 'year'),
                  onSelected: () => {
                    setCurrent({
                      ...current,
                      interval: 'year',
                      currentToDate: false,
                    });
                  },
                },
              ]}
            />
          </div>
        </Row>
        {/* Bottom */}
        {isAdvanced && onModeTogglePressed && (
          <ModeChangeWrapper>
            <Row
              style={{
                marginTop: 12,
                width: '100%',
                marginBottom: 4,
              }}
              centerAlign
              onClick={onModeTogglePressed}
            >
              <Typography.Body type="Link" color={Colors2.Secondary.info}>
                Basic
              </Typography.Body>
              <img
                src={ChevronUp}
                style={{ marginLeft: 4, width: 8, height: 5 }}
                alt="up"
              />
            </Row>
          </ModeChangeWrapper>
        )}
        {!isAdvanced && (
          <>
            <Row
              style={{
                marginTop: 12,
                width: '100%',
                marginBottom: 4,
              }}
              spaceBetween
              centerAlign
            >
              {/* Switch to advanced */}
              <ModeChangeWrapper>
                {onModeTogglePressed && (
                  <Row centerAlign onClick={onModeTogglePressed}>
                    <Typography.Body
                      type={'Body 12'}
                      color={
                        isPlaceholder
                          ? Colors2.Grey['5']
                          : Colors2.Secondary.info
                      }
                    >
                      Advanced
                    </Typography.Body>
                    <img
                      src={isPlaceholder ? ChevronDownGrey : ChevronDown}
                      style={{ marginLeft: 4, width: 8, height: 5 }}
                      alt="down"
                    />
                  </Row>
                )}
              </ModeChangeWrapper>
              {/* To date */}
              <Row centerAlign>
                {!(
                  current.interval === 'day' && current.type === 'current'
                ) && (
                  <>
                    <div style={{ marginRight: 4 }}>
                      {current.type === 'current' && (
                        <Typography.Body
                          type={'Body 12'}
                          color={isPlaceholder ? Colors2.Grey['5'] : undefined}
                        >
                          To Date
                        </Typography.Body>
                      )}
                      {current.type !== 'current' && (
                        <Typography.Body
                          type={'Body 12'}
                          color={isPlaceholder ? Colors2.Grey['5'] : undefined}
                        >
                          {`Include ${
                            current.interval === 'day'
                              ? 'Today'
                              : `Current ${toSentenceCase(
                                  current.interval,
                                )} To Date`
                          }`}
                        </Typography.Body>
                      )}
                    </div>

                    <Inputs.Checkbox
                      testId={`${testPrefix}relative-to-date`}
                      isSmall
                      isChecked={current.currentToDate}
                      onToggled={(newValue) => {
                        setCurrent({
                          ...current,
                          currentToDate: newValue,
                        });
                      }}
                    />
                  </>
                )}
              </Row>
            </Row>
          </>
        )}
      </div>
    </Row>
  </div>
);

export default RelativeDateBuilder;
