import { useState, useContext, useEffect, useCallback } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { DateTime } from 'luxon';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import AccountContext from '../../contexts/AccountContext';
import toSentenceCase from '../../services/toSentenceCase';
import mapGraphqlDayNumberToString from '../../mapGraphqlDayNumberToString';
import isCustomRangeInput from '../../isCustomRangeInput';
import DashboardGadgetContext from '../../contexts/DashboardGadgetContext';
import ComparisonContext from '../../contexts/ComparisonContext';
import { getComparisonLabel } from '../../components/Report/ComparisonSelector/ComparisonSelector';
import getComparison from '../../components/V5Gadget/Matrix/getComparison';
import formatDateLabel from '../../components/V5Gadget/formatDateLabel';
import getMatrixDefaultSort from '../../components/V5Gadget/Matrix/getMatrixDefaultSort';
import captureException from '../../services/captureException';
import {
  getEndOf,
  removePartialIntervalsDates,
} from '../../removePartialIntervals';
import toAutoInterval, {
  toInterval,
} from '../../components/V5Gadget/toAutoInterval';
import getGroupByField from './getGroupByField';
import MetricHeaderCell from '../../components/MetricHeaderCell';
import useUsedMetrics from '../useUsedMetrics';
import GqlClientContext from '../../contexts/GqlClientContext';
import useDateScope from '../useDateScope';
import BaseViewsContext from '../../contexts/BaseViewsContext';
import isDefined from '../../isDefined';
import WeekStartsOnOverrideContext from '../../contexts/WeekStartsOnOverrideContext';
import getOrderedDaysOfWeek from './getOrderedDaysOfWeek';
import buildTwoDimensionDateTotals from './buildTwoDimensionDateTotals';
import buildOneDimensionMetricTotals from './buildOneDimensionMetricTotals';
import buildTwoDimensionDayOfWeekTotals from './buildTwoDimensionDayOfWeekTotals';
import metricTypeCheckers from '../../types/metricTypeCheckers';
import isNormalMetric from '../../types/metricTypeCheckers/isNormalMetric';
import useValueFormatters from '../useValueFormatters';
import getTermedHistogramResponses from './getTermedHistogramResponses';
import getTopNTermsResponses from './getTopNTermsResponses';
import getAllCurrentAndPreviousTermResponses from './getAllCurrentAndPreviousTermResponses';
import useFilterInput from '../useFilterInput';
import EntityDefinitionsContext from '../../contexts/EntityDefinitionsContext';
import { FleetOpsCellRendererParams } from '../../types/agGrid';
import useLockedDebouncedEffect from '../useLockedDebouncedEffect';
import { ValueGetterParams } from 'ag-grid-community/dist/types/core/entities/colDef';
import useToMetricInput from 'hooks/useToMetricInput';

const shouldLeftAlignMetric = (metric?: Metrics.Metric) => {
  if (metric && metricTypeCheckers.isNormalMetric(metric)) {
    if (metric.aggFunc === 'first' || metric.aggFunc === 'last') {
      return true;
    }

    if (
      metric.field.toLowerCase().includes('date') &&
      (metric.aggFunc === 'max' || metric.aggFunc === 'min')
    ) {
      return true;
    }
  }
  return false;
};

const getFieldName = (field: string, baseView?: FleetOps.BaseView) => {
  if (!baseView) {
    return field;
  }

  const baseViewField = baseView.fields[field];
  if (!baseViewField) {
    return field;
  }

  return baseViewField.nameAlias ? baseViewField.nameAlias : field;
};

const isDeltaCol = (compareSort: MatrixCompareSortType): boolean => {
  return [
    'delta asc',
    'delta desc',
    'abs delta asc',
    'abs delta desc',
  ].includes(compareSort);
};

export const getFieldView = (
  metric: Metrics.Metric | undefined,
  baseViews: FleetOps.BaseView[],
): FleetOps.BaseViewField | undefined => {
  if (isNormalMetric(metric)) {
    const bv = baseViews.find((bv) => bv.type === metric.dataType);
    if (!bv) {
      return undefined;
    }

    const bf = bv.fields[metric.field];
    if (!bf) {
      return undefined;
    }

    return bf;
  }

  return undefined;
};

interface GetMetricMatrixProps {
  chartDef: V5ChartDefinition;
  baseViews: FleetOps.BaseView[];
  filterInput: FilterInput;
  unitsLocale: string;
  usedMetrics: Metrics.Metric[];
  skip2DRowDataFormatting?: boolean;
  isDashboardGadget?: boolean;
  currentDateScope: DateRangeInput;
  previousDateScope: DateRangeInput;
  toMetricInput: (metric: Metrics.NormalMetric | MetricInput) => MetricInput;
  comparison?: PersistedComparisonType;
  client: ApolloClient<NormalizedCacheObject>;
  defaultCompareSort?: string;
  compareSortType?: MatrixCompareSortType;
  currentSortBy?: string;
  weekStartsOn: WeekStartsOn;
  formatMetric: (args: {
    metricId: string;
    value: string | number | null | undefined;
  }) => string;
  getEntityDefinitionFor: (field: string) =>
    | {
        entity: EntityDetails.Entity;
        app: EntityDetails.App;
      }
    | undefined;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const getMetricMatrix = async ({
  chartDef,
  baseViews,
  filterInput,
  unitsLocale,
  usedMetrics,
  skip2DRowDataFormatting,
  isDashboardGadget,
  currentDateScope,
  previousDateScope,
  toMetricInput,
  comparison,
  client,
  defaultCompareSort,
  compareSortType,
  currentSortBy,
  weekStartsOn,
  formatMetric,
  getEntityDefinitionFor,
  setIsLoading,
}: GetMetricMatrixProps) => {
  const isBHistogram =
    chartDef.dimensionB &&
    chartDef.dimensionB.field === 'date' &&
    (!!chartDef.trendByCalendarInterval || !!chartDef.trendByFixedIntervalDays);
  const isBDayOfWeek =
    chartDef.dimensionB &&
    chartDef.dimensionB.field === 'dayOfWeek' &&
    chartDef.groupByDayOfWeek;
  const isTermedHistogram =
    chartDef.dimensionA &&
    chartDef.dimensionB &&
    (isBHistogram || isBDayOfWeek);
  const isTopN =
    !!chartDef.dimensionA && !chartDef.dimensionB && chartDef.groupByLimitMode
      ? chartDef.groupByLimitMode === 'top n'
      : chartDef.groupByLimit && chartDef.groupByLimit <= 100;

  const groupByField = getGroupByField(chartDef);
  setIsLoading(true);
  const responses = await (() => {
    if (isTermedHistogram) {
      return getTermedHistogramResponses({
        chartDef,
        filterInput,
        usedMetrics,
        isDashboardGadget,
        currentDateScope,
        toMetricInput,
        client,
        groupByField,
      });
    } else if (isTopN) {
      return getTopNTermsResponses({
        chartDef,
        filterInput,
        usedMetrics,
        isDashboardGadget,
        currentDateScope,
        previousDateScope,
        toMetricInput,
        client,
        groupByField,
        comparison,
      });
    } else {
      return getAllCurrentAndPreviousTermResponses({
        chartDef,
        filterInput,
        usedMetrics,
        isDashboardGadget,
        currentDateScope,
        toMetricInput,
        previousDateScope,
        client,
        groupByField,
        comparison,
      });
    }
  })();

  if (
    chartDef.excludePartialIntervals &&
    chartDef.dimensionA &&
    chartDef.dimensionA.fieldType === 'date'
  ) {
    const dates = responses[0].map((row) => row.date) as string[];
    if (responses[0][0] === undefined) {
      return {
        colDefs: [],
        rowData: [],
        totals: [],
      };
    }
    const interval = (() => {
      if (
        chartDef.trendByCalendarInterval &&
        chartDef.trendByCalendarInterval !== 'auto'
      ) {
        return chartDef.trendByCalendarInterval;
      } else {
        const autoIntervalResponse = responses[0][0].interval as
          | string
          | undefined;
        if (autoIntervalResponse) {
          const autoInterval = toAutoInterval(autoIntervalResponse);
          if (autoInterval) {
            return autoInterval.interval;
          }
        }
      }

      return undefined;
    })();

    if (interval) {
      const currentDateScope = responses[0][0].dateScope as
        | DateRangeInput
        | undefined;
      if (currentDateScope) {
        const validCurrentDates = removePartialIntervalsDates(
          dates,
          interval,
          currentDateScope,
        );
        responses[0] = responses[0].filter((row) =>
          validCurrentDates.includes(row.date as string),
        );
      }

      try {
        const previousDateScope = responses[2][0].dateScope as
          | DateRangeInput
          | undefined;

        if (previousDateScope) {
          const prevDates = responses[2].map((r) => r.date as string);
          const validPreviousDates = removePartialIntervalsDates(
            prevDates,
            interval,
            previousDateScope,
          );
          responses[2] = responses[2].filter((row) =>
            validPreviousDates.includes(row.date as string),
          );
        }
      } catch (ex) {
        const error = new Error();
        error.name = 'Removing partial intervals from previous period failed';
        captureException(error);
      }
    }
  }

  if (!groupByField) {
    console.warn(`Missing groupBy`);
    return {
      colDefs: [],
      rowData: [],
      totals: [],
    };
  }

  const isTwoDimensional = !!(
    chartDef.dimensionA &&
    chartDef.dimensionB &&
    responses[0].length &&
    responses[0].length > 0 &&
    responses[0][0].values
  );

  const getFloat = (n: string) => {
    try {
      const match = n.match(/\d+\.\d+/);
      if (match) {
        return Number.parseFloat(match[0]);
      } else {
        return 0;
      }
    } catch (ex) {
      return 0;
    }
  };

  const getNumber = (n: string) => {
    const maybeFloat = getFloat(n);
    if (maybeFloat === 0) {
      const match = n.match(/\d+/);
      if (match) {
        return Number.parseInt(match[0], 10);
      } else {
        return 0;
      }
    }
    return maybeFloat;
  };

  // @ts-ignore
  const twoDComparator = (aString, bString) => {
    const a = getNumber(aString);
    const b = getNumber(bString);
    return a - b;
  };

  const comparator = isTwoDimensional ? twoDComparator : () => 0;
  const rangeBuckets = (() => {
    if (!isTwoDimensional) {
      return [];
    }
    let autoInterval = undefined as AutoInterval | undefined;
    let dateScope = undefined as DateRangeInput | undefined;
    const buckets = [] as string[];
    responses[0].forEach((r) => {
      if (chartDef.dimensionB && chartDef.dimensionB.rangeInput) {
        // @ts-ignore
        r.values.forEach((v) => {
          buckets.push(v.key);
        });
      }

      if (chartDef.dimensionB && chartDef.dimensionB.fieldType === 'date') {
        dateScope = r['dateScope'] as DateRangeInput | undefined;
        // @ts-ignore
        r.values.forEach((v) => {
          buckets.push(v.date);
          if (v['interval']) {
            autoInterval = toAutoInterval(v['interval']);
          }
        });
      }
    });

    const uniqDates = _.uniq(buckets) as string[];

    if (chartDef.excludePartialIntervals) {
      const datesToUse = removePartialIntervalsDates(
        uniqDates,
        autoInterval ? autoInterval.interval : undefined,
        dateScope,
      );
      return datesToUse.sort();
    } else {
      return uniqDates.sort();
    }
  })();

  const buildColDef = <TData = any, TValue = any>({
    headerName,
    headerTooltip,
    field,
    comparator,
    isLeftAlign,
    isGroupingCell,
    isStatusFlag,
    metric,
    isComparisonCurrentValue,
  }: {
    headerName?: string;
    headerTooltip?: string;
    field: string;
    comparator?: (a: any, b: any) => number;
    isLeftAlign?: boolean;
    isGroupingCell?: boolean;
    isStatusFlag?: boolean;
    metric?: Metrics.Metric;
    isComparisonCurrentValue?: boolean;
  }) => {
    const cellRendererParams: FleetOpsCellRendererParams = {
      isLeftAlign: !!isLeftAlign,
      fieldView: getFieldView(metric, baseViews),
      metric,
      entityDefinition: getEntityDefinitionFor(field),
    };
    return {
      headerName: headerName ? headerName : field,
      headerTooltip: metric ? undefined : headerTooltip ? headerTooltip : field,
      field,
      comparator,
      cellRenderer: isGroupingCell
        ? 'groupingCellRenderer'
        : isStatusFlag
          ? 'statusFlagCell'
          : 'normalCellRenderer',
      cellRendererParams,
      headerClass: isLeftAlign ? undefined : 'rightAlignedHeader',
      suppressHeaderMenuButton: true,
      suppressMovable: isGroupingCell,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      flex: 1,
      valueGetter: (params: ValueGetterParams<TData, TValue>) => {
        if (params.data) {
          // ts-ignore should be avoided wherever possible.
          // However - This feature has bigger problems
          // @ts-ignore
          return params.data[field];
        }
        return undefined;
      },
      headerComponent: metric ? MetricHeaderCell : undefined,
      headerComponentParams: metric
        ? {
            metric,
            rightAlign: !isLeftAlign && !shouldLeftAlignMetric(metric),
            alias: headerName,
            sort:
              metric.id === currentSortBy ? chartDef.groupBySortBy : undefined,
            isComparisonCurrentValue,
          }
        : { isComparisonCurrentValue },
      sort: (() => {
        if (currentSortBy && metric && metric.id === currentSortBy) {
          return chartDef.groupBySortBy;
        }

        if (isGroupingCell && !currentSortBy) {
          return chartDef.groupBySortBy;
        }

        if (field === defaultCompareSort && compareSortType) {
          return compareSortType;
        }
      })(),
    };
  };

  const buildDeltaColDef = (
    metricId: string,
    comparison?: PersistedComparisonType,
  ) =>
    buildColDef({
      headerName: 'Change',
      isLeftAlign: true,
      field: `${metricId}-delta`,
      headerTooltip: `The difference between the current period and the ${getComparisonLabel(
        comparison,
      ).toLowerCase()}`,
      // @ts-ignores
      comparator: (a, b) => {
        const sortArgs = getMatrixDefaultSort(chartDef);
        const defaultSortMode = sortArgs ? sortArgs.defaultSortMode : 'abs';
        const isIgnoreWinningOrLosing = defaultSortMode.includes('abs');
        const { delta: deltaA, isGood: isGoodA } = getComparison({
          value: a.value,
          previous: a.previous,
          formatting: a.formatting,
          type: a.type,
        });
        const { delta: deltaB, isGood: isGoodB } = getComparison({
          value: b.value,
          previous: b.previous,
          formatting: b.formatting,
          type: b.type,
        });

        if (a.previous === 0 && b.previous === 0) {
          return 0; // do nothing
        }

        if (a.previous === 0 || b.previous === 0) {
          // -1 => a comes first
          // +1 => b comes first

          if (isIgnoreWinningOrLosing) {
            // We only care about the amount of movement
            if (a.previous === 0) {
              return -1;
            }
            if (b.previous === 0) {
              return 1;
            }
          } else {
            // We care about winners and losers
            if (a.previous === 0) {
              if (isGoodB) {
                return -1;
              } else {
                return 1;
              }
            }
            if (b.previous === 0) {
              if (isGoodA) {
                return 1;
              } else {
                return -1;
              }
            }
          }
        } else {
          if (isIgnoreWinningOrLosing) {
            const absA = Math.abs(deltaA);
            const absB = Math.abs(deltaB);
            return absA - absB;
          } else {
            const absA = Math.abs(deltaA) * (isGoodA ? 1 : -1);
            const absB = Math.abs(deltaB) * (isGoodB ? 1 : -1);
            return absA - absB;
          }
        }
      },
    });

  const buildPercentDeltaColDef = (metricId: string) =>
    buildColDef({
      headerName: '%Chg',
      isLeftAlign: true,
      field: `${metricId}-percentDelta`,
      headerTooltip: `The percentage change between the current period and the ${getComparisonLabel(
        comparison,
      ).toLowerCase()}`,
      // @ts-ignore
      comparator: (a, b) => {
        const sortArgs = getMatrixDefaultSort(chartDef);
        if (!sortArgs) {
          return 0;
        }
        const { defaultSortMode } = sortArgs;

        const { delta: deltaA, isGood: isGoodA } = getComparison({
          value: a.value,
          previous: a.previous,
          formatting: a.formatting,
          type: a.type,
        });
        const { delta: deltaB, isGood: isGoodB } = getComparison({
          value: b.value,
          previous: b.previous,
          formatting: b.formatting,
          type: b.type,
        });

        const isIgnoreWinningOrLosing = defaultSortMode.includes('abs');

        if (a.previous === 0 && b.previous === 0) {
          return 0; // do nothing
        }

        if (a.previous === 0 || b.previous === 0) {
          // -1 => a comes first
          // +1 => b comes first

          if (isIgnoreWinningOrLosing) {
            // We only care about the amount of movement
            if (a.previous === 0) {
              return -1;
            }
            if (b.previous === 0) {
              return 1;
            }
          } else {
            // We care about winners and losers
            if (a.previous === 0) {
              if (isGoodB) {
                return -1;
              } else {
                return 1;
              }
            }
            if (b.previous === 0) {
              if (isGoodA) {
                return 1;
              } else {
                return -1;
              }
            }
          }
        } else {
          if (isIgnoreWinningOrLosing) {
            return Math.abs(deltaA) - Math.abs(deltaB);
          } else {
            return deltaA - deltaB;
          }
        }
      },
    });

  const groupDefHeaderName = (() => {
    const baseView = baseViews.find((v) => {
      const f = v.fields[groupByField];
      if (f && f.nameAlias) {
        return v;
      }
      return undefined;
    });

    if (baseView) {
      return getFieldName(groupByField, baseView);
    }
    return toSentenceCase(groupByField, true);
  })();

  const groupDef = buildColDef({
    headerName: groupByField === 'date' ? 'date' : groupDefHeaderName,
    headerTooltip:
      groupByField === 'date' ? 'date' : toSentenceCase(groupByField),
    field: groupByField,
    comparator,
    isLeftAlign: true,
    isGroupingCell: true,
  });

  const rowData = buildRowData({
    response: responses[0] as { [key: string]: number | string }[],
    isTwoDimensional,
    chartDef,
    usedMetrics,
    skip2DRowDataFormatting,
    groupByField,
    skipRaw: true,
    weekStartsOn,
    formatMetric,
  });

  const isBDate = chartDef.dimensionB
    ? chartDef.dimensionB.fieldType === 'date'
    : false;

  const colDefs = (() => {
    if (isTwoDimensional && chartDef.dimensionB) {
      const bRange = chartDef.dimensionB.rangeInput;
      if (bRange && isCustomRangeInput(bRange)) {
        return [
          groupDef,
          ...rangeBuckets.map((bucketKey) =>
            buildColDef({ field: bucketKey, comparator }),
          ),
        ] as MatrixColDef[];
      } else if (isBDate && chartDef.series.length === 1) {
        const s = chartDef.series[0];
        const metric = usedMetrics.find((m) => m.id === s.metricId);
        if (metric) {
          return [
            groupDef,
            ...rangeBuckets.map((bucketKey, index) => {
              const baseDef = buildColDef({
                field: bucketKey,
                headerName: s.displayName ? s.displayName : metric.name,
                headerTooltip: s.displayName ? s.displayName : metric.name,
                comparator,
                isComparisonCurrentValue: true,
              });
              if (s.matrixCellType && comparison) {
                const children = (() => {
                  if (index === 0) {
                    return [baseDef];
                  }
                  if (s.matrixCellType === 'both') {
                    return [
                      baseDef,
                      buildDeltaColDef(bucketKey, comparison),
                      buildPercentDeltaColDef(bucketKey),
                    ];
                  } else if (s.matrixCellType === 'delta') {
                    return [baseDef, buildDeltaColDef(bucketKey, comparison)];
                  } else {
                    return [baseDef, buildPercentDeltaColDef(bucketKey)];
                  }
                })();
                return {
                  headerName: formatDateLabel(
                    bucketKey,
                    chartDef.trendByCalendarInterval
                      ? chartDef.trendByCalendarInterval
                      : 'auto',
                    true,
                    undefined,
                    chartDef.gadgetType === 'matrix',
                  ),
                  headerTooltip: formatDateLabel(
                    bucketKey,
                    chartDef.trendByCalendarInterval
                      ? chartDef.trendByCalendarInterval
                      : 'auto',
                    true,
                    undefined,
                    chartDef.gadgetType === 'matrix',
                  ),
                  marryChildren: true,
                  flex: 1,
                  children,
                };
              } else {
                return {
                  ...baseDef,
                  flex: 1,
                  headerName: formatDateLabel(
                    bucketKey,
                    chartDef.trendByCalendarInterval
                      ? chartDef.trendByCalendarInterval
                      : 'auto',
                    true,
                    undefined,
                    chartDef.gadgetType === 'matrix',
                  ),
                  headerTooltip: formatDateLabel(
                    bucketKey,
                    chartDef.trendByCalendarInterval
                      ? chartDef.trendByCalendarInterval
                      : 'auto',
                    true,
                    undefined,
                    chartDef.gadgetType === 'matrix',
                  ),
                };
              }
            }),
          ] as MatrixColDef[];
        } else {
          return [];
        }
      } else if (chartDef.dimensionB.isGroupByDayOfWeek) {
        return [
          groupDef,
          ...getOrderedDaysOfWeek(weekStartsOn).map((dayOfWeek) => {
            return buildColDef({
              field: dayOfWeek,
              headerName: dayOfWeek,
              headerTooltip: dayOfWeek,
              comparator,
            });
          }),
        ] as MatrixColDef[];
      } else {
        return [
          groupDef,
          ...rangeBuckets.map((bucketKey) => {
            // @ts-ignore
            const delta = rangeBuckets[1] - rangeBuckets[0];
            // @ts-ignore
            const name = `${bucketKey} - ${bucketKey + delta}`;
            return buildColDef({
              field: bucketKey.toString(),
              headerName: name,
              headerTooltip: name,
              comparator,
            });
          }),
        ] as MatrixColDef[];
      }
    } else {
      return [
        groupDef,
        ...chartDef.series
          .filter((s) => {
            const mId = s.metricId;
            const metric = usedMetrics.find((m) => m.id === mId);
            if (!metric) {
              const e = new Error(`Metric ${mId} not found`);
              captureException(e);
            }
            return !!metric;
          })
          .map((s) => {
            const mId = s.metricId;
            const metric = usedMetrics.find((m) => m.id === mId);
            if (!metric) {
              throw new Error(`Metric ${mId} not found`);
            }

            const baseDef = buildColDef({
              field: metric.id,
              headerName: s.displayName ? s.displayName : metric.name,
              headerTooltip: s.displayName ? s.displayName : metric.name,
              comparator,
              isStatusFlag: s.isStatusFlag,
              metric,
              isLeftAlign: shouldLeftAlignMetric(metric),
            });
            if (s.matrixCellType && comparison) {
              const children = (() => {
                if (s.matrixCellType === 'both') {
                  return [
                    baseDef,
                    buildDeltaColDef(metric.id, comparison),
                    buildPercentDeltaColDef(metric.id),
                  ];
                } else if (s.matrixCellType === 'delta') {
                  return [baseDef, buildDeltaColDef(metric.id, comparison)];
                } else {
                  return [baseDef, buildPercentDeltaColDef(metric.id)];
                }
              })();
              return {
                headerName: s.displayName ? s.displayName : metric.name,
                marryChildren: true,
                flex: 1,

                headerGroupComponent: metric ? MetricHeaderCell : undefined,
                headerGroupComponentParams: metric
                  ? {
                      metric,
                      rightAlign: false,
                      alias: s.displayName,
                    }
                  : undefined,
                children,
              };
            } else {
              return baseDef;
            }
          }),
      ] as MatrixColDef[];
    }
  })();

  const totals = (() => {
    if (isTwoDimensional) {
      const metricId = chartDef.series[0].metricId;
      const metric = usedMetrics.find((m) => m.id === metricId);
      if (!metric) {
        throw new Error(`Metric ${metricId} not found`);
      }
      const s = chartDef.series.find((s) => s.metricId === metric.id);

      if (
        chartDef.dimensionB &&
        chartDef.dimensionB.field === 'date' &&
        rowData.length > 0
      ) {
        return buildTwoDimensionDateTotals({
          chartDef,
          rangeBuckets,
          groupedHistogram: responses[0],
          histogram: responses[1],
          metricId,
          metric,
          unitsLocale,
          s,
          groupByField,
          formatMetric,
        });
      } else if (
        chartDef.dimensionB &&
        chartDef.dimensionB.isGroupByDayOfWeek
      ) {
        return buildTwoDimensionDayOfWeekTotals({
          groupedHistogram: responses[0],
          histogram: responses[1],
          metric,
          metricId,
          groupByField,
          formatMetric,
        });
      } else {
        const formatted = {};

        // @ts-ignore
        formatted[groupByField] = 'Total';
        return [formatted];
      }
    } else {
      return buildOneDimensionMetricTotals({
        chartDef,
        unitsLocale,
        groupByField,
        usedMetrics,
        currentTotal: responses[1],
        previousTotal: responses[3] || [],
        formatMetric,
      });
    }
  })();

  const comparisonRowData = (() => {
    if (!comparison) {
      return undefined;
    }
    const current = buildRowData({
      response: responses[0] as { [key: string]: number | string }[],
      isTwoDimensional,
      chartDef,
      usedMetrics,
      skip2DRowDataFormatting,
      groupByField,
      weekStartsOn,
      formatMetric,
    });

    if (
      isTwoDimensional &&
      chartDef.dimensionA &&
      chartDef.dimensionB &&
      chartDef.dimensionB.fieldType === 'date'
    ) {
      // Hotfix/2D date matrix rendering blanks
      const build2DDateComparisonRowData = (): MatrixComparisonRowData[] => {
        const comparisonData = [] as MatrixComparisonRowData[];
        if (chartDef.series.length !== 1) {
          return comparisonData;
        }
        const s = chartDef.series[0];
        const metric = usedMetrics.find((m) => m.id === s.metricId);
        if (!metric) {
          return comparisonData;
        }
        current.forEach((currentRow) => {
          const row = {} as MatrixComparisonRowData;
          if (chartDef.dimensionA && chartDef.dimensionB) {
            const dimensionA = chartDef.dimensionA.field;
            // @ts-ignore
            row[dimensionA] = currentRow[dimensionA];
            rangeBuckets.forEach((isoDate, index) => {
              if (index === 0) {
                // @ts-ignore
                row[isoDate] = currentRow[isoDate];
                // @ts-ignore
                row[`${isoDate}-raw`] = currentRow[`${isoDate}-raw`];
              } else {
                if (s.matrixCellType) {
                  // @ts-ignore
                  const current = currentRow[`${isoDate}-raw`];
                  const previousIsoDate = rangeBuckets[index - 1];
                  // @ts-ignore
                  const previous = currentRow[`${previousIsoDate}-raw`];
                  const currentDateRange = (() => {
                    const endDate = DateTime.fromMillis(
                      getEndOf(
                        DateTime.fromISO(isoDate).toMillis(),
                        chartDef.trendByCalendarInterval,
                      ),
                    )
                      .minus({ day: 1 })
                      .toISODate();
                    return {
                      startDate: isoDate,
                      endDate,
                    } as DateRangeInput;
                  })();
                  const previousDateRange = (() => {
                    const endDate = DateTime.fromMillis(
                      getEndOf(
                        DateTime.fromISO(previousIsoDate).toMillis(),
                        chartDef.trendByCalendarInterval,
                      ),
                    )
                      .minus({ day: 1 })
                      .toISODate();
                    return {
                      startDate: previousIsoDate,
                      endDate,
                    } as DateRangeInput;
                  })();
                  const deltaCol = {
                    value: current,
                    previous,
                    formatting: metric.formatting,
                    unitsLocale,
                    type: 'delta' as MatrixCellType,
                    currentDateRange,
                    previousDateRange,
                    metric,
                  };
                  const percentDeltaCol = {
                    value: current,
                    previous,
                    formatting: metric.formatting,
                    unitsLocale,
                    type: 'percentDelta' as MatrixCellType,
                    currentDateRange,
                    previousDateRange,
                    metric,
                  };

                  if (s.matrixCellType === 'both') {
                    // @ts-ignore
                    row[`${isoDate}-delta`] = deltaCol;
                    // @ts-ignore
                    row[`${isoDate}-percentDelta`] = percentDeltaCol;
                  } else if (s.matrixCellType === 'delta') {
                    // @ts-ignore
                    row[`${isoDate}-delta`] = deltaCol;
                  } else {
                    // @ts-ignore
                    row[`${isoDate}-percentDelta`] = percentDeltaCol;
                  }
                  // @ts-ignore
                  row[isoDate] = currentRow[isoDate];
                } else {
                  // @ts-ignore
                  row[isoDate] = currentRow[isoDate];
                }
              }
            });
            comparisonData.push(row);
          }
        });
        return comparisonData;
      };

      return build2DDateComparisonRowData();
    }

    if (
      isTwoDimensional &&
      chartDef.dimensionB &&
      (chartDef.dimensionB.isGroupByDayOfWeek || chartDef.dimensionB.rangeInput)
    ) {
      return rowData;
    }
    const previous = buildRowData({
      response: (responses[2] as { [key: string]: number | string }[]) || [],
      isTwoDimensional,
      chartDef,
      usedMetrics,
      skip2DRowDataFormatting,
      groupByField,
      weekStartsOn,
      formatMetric,
    });

    return buildComparisonRowData({
      current,
      previous,
      usedMetrics,
      chartDef,
      groupByField,
      unitsLocale,
      comparison,
      baseViews,
    });
  })();

  return {
    colDefs,
    rowData,
    comparisonRowData,
    totals,
  };
};

const buildComparisonRowData = ({
  current,
  previous,
  usedMetrics,
  chartDef,
  groupByField,
  unitsLocale,
  comparison,
  baseViews,
}: {
  current: MatrixRowData[];
  previous: MatrixRowData[];
  usedMetrics: Metrics.Metric[];
  chartDef: V5ChartDefinition;
  groupByField: string;
  unitsLocale: string;
  comparison?: PersistedComparisonType;
  baseViews: FleetOps.BaseView[];
}): MatrixComparisonRowData[] => {
  const isTimeTrend =
    chartDef.trendByCalendarInterval &&
    ['day', 'week', 'month', 'quarter', 'auto'].includes(
      chartDef.trendByCalendarInterval,
    );
  const isPreviousAcceptable =
    chartDef.series.some((s) => s.matrixCellType !== undefined) && !!comparison;
  const groupings =
    groupByField === 'date'
      ? current.map((r) => r[groupByField]).filter((g) => typeof g === 'string')
      : (_.uniq([
          ...current.map((r) => r[groupByField]),
          ...(isPreviousAcceptable ? previous.map((r) => r[groupByField]) : []),
        ]).filter((g) => typeof g === 'string') as string[]);

  return groupings.map((group, index) => {
    const currentRow = current.find((r) => r[groupByField] === group);
    const previousRow = (() => {
      if (chartDef.trendByCalendarInterval) {
        if (!comparison) {
          return undefined;
        } else if (comparison.compareType === 'previous') {
          if (index === 0) {
            return previous[previous.length - 1];
          } else {
            return current[index - 1];
          }
        } else {
          return previous[index];
        }
      } else {
        return previous.find((pR) => pR[groupByField] === group);
      }
    })();
    const comparisonRow = {} as MatrixComparisonRowData;
    if (isTimeTrend && chartDef.trendByCalendarInterval && currentRow) {
      comparisonRow['date'] = formatDateLabel(
        currentRow['raw-date'] as string,
        chartDef.trendByCalendarInterval,
        true,
        undefined,
        chartDef.gadgetType === 'matrix',
      );
      comparisonRow['raw-date'] = currentRow['raw-date']
        ? (currentRow['raw-date'] as string)
        : (currentRow[groupByField] as string);
    } else {
      comparisonRow[groupByField] = group as string;
    }

    const fieldsToMap = currentRow
      ? Object.keys(currentRow)
      : previousRow
        ? Object.keys(previousRow)
        : [];

    fieldsToMap.forEach((key) => {
      const m = usedMetrics.find((mo) => mo.id === key);
      if (m) {
        const s = chartDef.series.find((s) => s.metricId === m.id);
        if (s) {
          const currentDateRange = (() => {
            const indexToUse = (() => {
              if (current.length > index) {
                return index;
              } else if (!isTimeTrend && current.length > 0) {
                return 0;
              } else {
                return index;
              }
            })();
            if (current.length > indexToUse) {
              const rawDate = current[indexToUse]['raw-date'] as string;
              if (current[indexToUse].interval) {
                const interval = toInterval(
                  current[indexToUse].interval as string,
                );
                return {
                  startDate: rawDate,
                  endDate: moment(rawDate)
                    .add({ [interval || 'month']: 1 })
                    .subtract({ day: 1 })
                    .format('YYYY-MM-DD'),
                };
              } else {
                return current[indexToUse].dateScope as DateRangeInput;
              }
            }
          })();

          const previousDateRange = (() => {
            try {
              if (previousRow) {
                if (previousRow['raw-date'] && previousRow['interval']) {
                  const interval = toInterval(
                    previousRow['interval'] as string,
                  );
                  const rawDate = previousRow['raw-date'] as string;
                  return {
                    startDate: rawDate,
                    endDate: moment(rawDate)
                      .add({ [interval || 'month']: 1 })
                      .subtract({ day: 1 })
                      .format('YYYY-MM-DD'),
                  };
                }
                return previousRow['dateScope'] as DateRangeInput;
              }

              if (previous[0] && previous[0]['dateScope']) {
                return previous[0]['dateScope'] as DateRangeInput;
              }

              if (
                previous[0] &&
                previous[0]['raw-date'] &&
                previous[0]['interval']
              ) {
                const interval = toInterval(previous[0]['interval'] as string);
                const rawDate = previous[0]['raw-date'] as string;
                return {
                  startDate: rawDate,
                  endDate: moment(rawDate)
                    .add({ [interval || 'month']: 1 })
                    .subtract({ day: 1 })
                    .format('YYYY-MM-DD'),
                };
              }
              return undefined;
            } catch (ex) {
              ex.name = 'Comparison failed';
              captureException(ex);
              return undefined;
            }
          })();

          const isTextFieldType = (() => {
            if (!metricTypeCheckers.isNormalMetric(m)) {
              return undefined;
            }

            const bv = baseViews.find((b) => b.type === m.dataType);
            if (!bv) {
              return undefined;
            }
            const bf = bv.fields[m.field];
            if (!bf) {
              return undefined;
            }

            return bf.type === 'text';
          })();

          const isPreviousAcceptable =
            metricTypeCheckers.isNormalMetric(m) &&
            ['first', 'last'].includes(m.aggFunc) &&
            isTextFieldType &&
            !!comparison;
          if (s.matrixCellType) {
            const previous = previousRow
              ? (previousRow[`${m.id}-raw`] as string | number)
              : (0 as string | number);

            const deltaCol = {
              value: currentRow
                ? (currentRow[`${m.id}-raw`] as string | number)
                : isPreviousAcceptable
                  ? previous
                  : (0 as string | number),
              previous,
              formatting: m.formatting,
              unitsLocale,
              type: 'delta' as MatrixCellType,
              currentDateRange: currentDateRange,
              previousDateRange: previousDateRange,
              metric: m,
            };
            const percentDeltaCol = {
              value: currentRow
                ? (currentRow[`${m.id}-raw`] as string | number)
                : (0 as string | number),
              previous: previousRow
                ? (previousRow[`${m.id}-raw`] as string | number)
                : (0 as string | number),
              formatting: m.formatting,
              unitsLocale,
              type: 'percentDelta' as MatrixCellType,
              currentDateRange: currentDateRange,
              previousDateRange: previousDateRange,
              metric: m,
            };

            if (s.matrixCellType === 'both') {
              comparisonRow[`${m.id}-delta`] = deltaCol;
              comparisonRow[`${m.id}-percentDelta`] = percentDeltaCol;
            } else if (s.matrixCellType === 'delta') {
              comparisonRow[`${m.id}-delta`] = deltaCol;
            } else {
              comparisonRow[`${m.id}-percentDelta`] = percentDeltaCol;
            }

            // @ts-ignore
            comparisonRow['filterInput'] = currentRow
              ? (currentRow['filterInput'] as FilterInput | undefined)
              : undefined;
            comparisonRow[m.id] = currentRow
              ? (currentRow[m.id] as string)
              : '-';
          } else {
            // @ts-ignore
            comparisonRow['filterInput'] = currentRow
              ? (currentRow['filterInput'] as FilterInput | undefined)
              : undefined;
            const v =
              !!currentRow && currentRow[m.id] !== undefined
                ? (currentRow[m.id] as string)
                : isPreviousAcceptable
                  ? !!previousRow && previousRow[m.id] !== undefined
                    ? (previousRow[m.id] as string)
                    : '-'
                  : '-';
            comparisonRow[m.id] = v;
          }
        }
      }
    });

    return comparisonRow;
  });
};

const buildRowData = ({
  response,
  isTwoDimensional,
  chartDef,
  usedMetrics,
  skip2DRowDataFormatting,
  groupByField,
  skipRaw,
  weekStartsOn,
  formatMetric,
}: {
  response: { [key: string]: number | string }[];
  isTwoDimensional: boolean;
  chartDef: V5ChartDefinition;
  usedMetrics: Metrics.Metric[];
  skip2DRowDataFormatting?: boolean;
  groupByField: string;
  skipRaw?: boolean;
  weekStartsOn: WeekStartsOn;
  formatMetric: (args: {
    metricId: string;
    value: string | number | null | undefined;
  }) => string;
}) => {
  const orderedResponse = (() => {
    const isDayOfWeek =
      chartDef.dimensionA && chartDef.dimensionA.isGroupByDayOfWeek;

    if (!isDayOfWeek) {
      return response;
    }

    const orderedDays = getOrderedDaysOfWeek(weekStartsOn);
    return orderedDays
      .map((day) => {
        return response.find(
          (r) => mapGraphqlDayNumberToString(r['dayOfWeek'].toString()) === day,
        );
      })
      .filter(isDefined);
  })();

  return Object.values(orderedResponse).map((r) => {
    if (isTwoDimensional) {
      const metricId = chartDef.series[0].metricId;
      const metric = usedMetrics.find((m) => m.id === metricId);
      if (!metric) {
        throw new Error(`Metric ${metricId} not found`);
      }

      const formatted = {} as { [key: string]: string | number | undefined };
      formatted[groupByField] = r[groupByField];
      Object.values(r.values).forEach((v) => {
        if (chartDef.dimensionB && chartDef.dimensionB.rangeInput && v.key) {
          if (!skipRaw) {
            formatted[`${v.key.toString()}-raw`] = v[metricId];
          }
          if (skip2DRowDataFormatting) {
            formatted[v.key.toString()] = v[metricId] as string;
          } else {
            formatted[v.key.toString()] = formatMetric({
              value: v[metricId],
              metricId,
            });
          }
        }

        if (chartDef.dimensionB && chartDef.dimensionB.fieldType === 'date') {
          if (!skipRaw) {
            formatted[`${v.date}-raw`] = v[metricId];
          }
          if (skip2DRowDataFormatting) {
            formatted[v.date] = v[metricId] as string;
          } else {
            formatted[v.date] = formatMetric({
              value: v[metricId],
              metricId,
            });
          }
        }

        if (chartDef.dimensionB && chartDef.dimensionB.isGroupByDayOfWeek) {
          const dayOfWeek = mapGraphqlDayNumberToString(
            v['dayOfWeek'].toString(),
          );
          if (!skipRaw) {
            formatted[`${dayOfWeek}-raw`] = v[metricId];
          }
          if (skip2DRowDataFormatting) {
            formatted[dayOfWeek] = v[metricId] as string;
          } else {
            formatted[dayOfWeek] = formatMetric({
              value: v[metricId],
              metricId,
            });
          }
        }

        if (chartDef.dimensionA && chartDef.dimensionA.rangeInput) {
          if (isCustomRangeInput(chartDef.dimensionA.rangeInput)) {
            formatted[groupByField] = r['key'];
          }
        }
      });
      return formatted;
    } else {
      const formatted = { ...r };
      Object.entries(r).forEach(([key, value]) => {
        const metric = usedMetrics.find((m) => m.id === key);
        if (metric) {
          if (!skipRaw) {
            formatted[`${key}-raw`] = value;
          }

          formatted[key] = formatMetric({
            value,
            metricId: metric.id,
          });
        } else {
          if (key === 'dayOfWeek') {
            formatted[key] = mapGraphqlDayNumberToString(value.toString());
          } else if (key === 'date' && groupByField === 'day') {
            formatted['day'] = value;
          } else if (
            key === 'date' &&
            groupByField === 'date' &&
            chartDef.trendByCalendarInterval
          ) {
            formatted['date'] = formatDateLabel(
              value,
              chartDef.trendByCalendarInterval,
              true,
              undefined,
              chartDef.gadgetType === 'matrix',
            );
            formatted['raw-date'] = value;
          } else if (
            chartDef.dimensionA &&
            chartDef.dimensionA.rangeInput &&
            key === 'key'
          ) {
            const rangeInput = chartDef.dimensionA.rangeInput;
            if (isCustomRangeInput(rangeInput)) {
              formatted[chartDef.dimensionA.field] = value;
            } else {
              const { fixed } = rangeInput;
              if (fixed !== 1) {
                formatted[chartDef.dimensionA.field] = `${value} - ${
                  Number(value) + fixed
                }`;
              } else {
                formatted[chartDef.dimensionA.field] = `${value}`;
              }
            }
          } else {
            if (!skipRaw) {
              formatted[`${key}-raw`] = value;
            }
            formatted[key] = value;
          }
        }
      });

      return formatted;
    }
  });
};

const useMetricMatrix = ({
  chartDef,
  skip2DRowDataFormatting,
  currentSortBy,
}: {
  chartDef: V5ChartDefinition;
  skip2DRowDataFormatting?: boolean;
  currentSortBy?: string;
}) => {
  const { usedMetrics } = useUsedMetrics(chartDef);
  const { weekStartsOnOverride } = useContext(WeekStartsOnOverrideContext);
  const { unitsLocale, weekStartsOn } = useContext(AccountContext);
  const { baseViews } = useContext(BaseViewsContext);
  const { getEntityDefinitionFor } = useContext(EntityDefinitionsContext);
  const filterInput = useFilterInput(
    chartDef.dimensionA ? chartDef.dimensionA.field : undefined,
  );

  const { dashboardGadget } = useContext(DashboardGadgetContext);
  const { currentComparison } = useContext(ComparisonContext);
  const { client } = useContext(GqlClientContext);
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [columnDefs, setColDefs] = useState<MatrixColDef[]>([]);
  const [rowData, setRowData] = useState<MatrixRowData[]>([]);
  const [defaultCompareSort, setDefaultCompareSort] = useState<
    string | undefined
  >();
  const [isReady, setIsReady] = useState<boolean>(false);
  const [compareSortType, setCompareSortType] = useState<
    MatrixCompareSortType | undefined
  >();
  const [comparisonRowData, setComparisonRowData] = useState<
    MatrixComparisonRowData[] | undefined
  >([]);
  const [rowsToDisplay, setRowsToDisplay] = useState<
    MatrixComparisonRowData[] | MatrixRowData[]
  >([]);
  const [totals, setTotals] = useState<MatrixRowData[]>([]);
  const [inputProps, setInputProps] = useState<
    GetMetricMatrixProps | undefined
  >(undefined);
  const { formatMetric } = useValueFormatters();
  const toMetricInput = useToMetricInput();

  const currentDateScope = useDateScope({});
  const previousDateScope = useDateScope({
    comparison: currentComparison,
  });

  useEffect(() => {
    const v = (() => {
      const defaultSortArgs = getMatrixDefaultSort(chartDef);
      if (!defaultSortArgs) {
        return undefined;
      }
      const { defaultSortMode, defaultSortMetricId } = defaultSortArgs;
      setCompareSortType(defaultSortMode);
      if (isDeltaCol(defaultSortMode)) {
        return `${defaultSortMetricId}-delta`;
      } else {
        return `${defaultSortMetricId}-percentDelta`;
      }
    })();
    setDefaultCompareSort(v);
    setIsReady(true);
  }, [chartDef]);

  const getInputProps = useCallback((): GetMetricMatrixProps | undefined => {
    if (!isReady) {
      return undefined;
    }
    return {
      baseViews: Object.values(baseViews)
        .filter(isDefined)
        .filter((bv) =>
          usedMetrics
            .filter(metricTypeCheckers.isNormalMetric)
            .some((m) => m.dataType === bv.type),
        ),
      client,
      chartDef,
      filterInput,
      currentDateScope,
      previousDateScope,
      toMetricInput,
      unitsLocale,
      usedMetrics,
      skip2DRowDataFormatting,
      comparison: currentComparison,
      isDashboardGadget: !!dashboardGadget,
      defaultCompareSort,
      compareSortType,
      currentSortBy,
      weekStartsOn: weekStartsOnOverride ? weekStartsOnOverride : weekStartsOn,
      formatMetric,
      getEntityDefinitionFor,
      setIsLoading,
    };
  }, [
    baseViews,
    chartDef,
    client,
    compareSortType,
    currentComparison,
    currentDateScope,
    currentSortBy,
    dashboardGadget,
    defaultCompareSort,
    filterInput,
    formatMetric,
    getEntityDefinitionFor,
    isReady,
    previousDateScope,
    skip2DRowDataFormatting,
    toMetricInput,
    unitsLocale,
    usedMetrics,
    weekStartsOn,
    weekStartsOnOverride,
  ]);

  useEffect(() => {
    if (!isReady) {
      return;
    }
    setInputProps((currentProps) => {
      const newProps = getInputProps();
      if (_.isEqual(currentProps, newProps)) {
        return currentProps;
      }
      return newProps;
    });
  }, [getInputProps, isReady]);

  const responseHandler = useCallback((response: any) => {
    setTotals(response.totals);
    setColDefs(response.colDefs);
    setRowData(response.rowData);
    // @ts-ignore
    setComparisonRowData(response.comparisonRowData);
    if (response.comparisonRowData) {
      setRowsToDisplay(response.comparisonRowData);
    } else {
      setRowsToDisplay(response.rowData);
    }
    setIsLoading(false);
    setIsInitialLoading(false);
  }, []);

  useLockedDebouncedEffect({
    args: inputProps,
    responseHandler,
    callback: getMetricMatrix,
  });

  return {
    totals,
    chartDef,
    columnDefs,
    rowData,
    comparisonRowData,
    rowsToDisplay,
    defaultCompareSort,
    compareSortType,
    isLoading,
    isInitialLoading,
  };
};

export default useMetricMatrix;
