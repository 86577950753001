import { useCallback, useContext } from 'react';
import _ from 'lodash';
import GoalsContext from '../../contexts/GoalsContext';

const useGetGoalUsage = () => {
  const { goals } = useContext(GoalsContext);

  const getGoalUsage = useCallback(
    (metric: Metrics.NormalMetric | Metrics.CompoundMetric): GeneralGoal[] => {
      const usage = [] as GeneralGoal[];
      goals.forEach((g) => {
        if (
          g.metricId === metric.id ||
          g.kpis.some((k) => k.metricId === metric.id)
        ) {
          usage.push(g);
        }
      });

      return _.uniqBy(usage, 'id');
    },
    [goals],
  );

  return getGoalUsage;
};

export default useGetGoalUsage;
