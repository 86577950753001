import buildFilterInput from '../buildFilterInput';
import moment from 'moment';

const getFixedFilterInput = (
  goal: GeneralGoal,
  toDrillDowns: ({
    plates,
    variableDrillDowns,
  }: {
    plates?: FilterPlate[] | undefined;
    variableDrillDowns?: VariableDrillDownType[] | undefined;
  }) => ReportDrillDownType[],
) => {
  const { drillDowns, fixedStartDate, fixedEndDate } = goal;
  const today = moment().utc().format('YYYY-MM-DD');
  const endDateToUse = fixedEndDate > today ? today : fixedEndDate;

  const dateRange = (() => {
    if (!fixedStartDate || !endDateToUse) {
      throw new Error('Fixed goals must have start and end dates defined');
    }
    return {
      startDate: fixedStartDate,
      endDate: endDateToUse,
    };
  })();

  return {
    dateScope: dateRange as DateRangeInput,
    filterInput: buildFilterInput({
      scopes: [],
      drillDowns: toDrillDowns({
        plates: drillDowns,
        variableDrillDowns: [],
      }),
    }),
  };
};

export default getFixedFilterInput;
