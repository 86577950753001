import React from 'react';
import styled from 'styled-components';

import Colors2 from '../../../../theme/Colors2';
import AddIcon from './images/AddIcon.svg';

const AddButtonDiv = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${Colors2.Primary['1']};
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

const IconImg = styled.img`
  width: 9px;
  height: 9px;
`;

const AddButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <AddButtonDiv onClick={onClick}>
      <IconImg src={AddIcon} />
    </AddButtonDiv>
  );
};

export default AddButton;
