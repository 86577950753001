import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

const runDateHistogramAggregationQuery = (
  query: any,
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return client.query(query);
};

export default runDateHistogramAggregationQuery;
