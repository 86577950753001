import React from 'react';
import { Col } from '../Kpis';
import KpiValue from './KpiValue';
import KpiRowElement from '../KpiRowElement';
import { KPI_ROW_HEIGHT } from './constants';

const KpiRowRight = ({
  kpi,
  periods,
  trend,
  metric,
  isLastRow,
  isHovered,
  onRowHover,
}: {
  kpi: Scorecards.ScorecardKpi;
  periods: Period[];
  trend?: Goals.TrendResult[];
  metric?: Metrics.Metric;
  isLastRow?: boolean;
  isHovered: boolean;
  onRowHover: () => void;
}) => (
  <KpiRowElement
    centerAlign
    isLastRow={isLastRow}
    isHovered={isHovered}
    onMouseEnter={onRowHover}
  >
    {!!trend &&
      periods.map((period) => (
        <Col
          minWidth
          key={`${period.startDate}-${kpi.id}`}
          alignRight
          style={{ height: `${KPI_ROW_HEIGHT}px` }}
        >
          <KpiValue period={period} kpi={kpi} trend={trend} metric={metric} />
        </Col>
      ))}
  </KpiRowElement>
);

export default KpiRowRight;
