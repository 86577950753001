import React, { useCallback, useContext, useEffect, useState } from 'react';
import ColDiv from '../MetricsStep/ColDiv';
import Typography from 'kingpin/atoms/Typography';
import { HIDE_UNLESS_HOVER_CLASS } from '../../../../../../components/HideUnlessMouseOver';
import Row from '../../../../../../components/Common/Row';
import CloseButton from '../../../../../../components/CloseButton';
import ListItem from '../ListItem';
import useGetDatasetLabel from '../../../../../../components/Inputs/MetricPicker/MetricPickerPopup/hooks/useGetDatasetLabel';
import PerformanceWizardContext from '../PerformanceWizardContext';
import useGetFieldLabel from 'hooks/useGetFieldLabel';

const FieldListItem = ({
  field,
  dataType,
  isReadOnly,
}: {
  field: string;
  dataType: string;
  isReadOnly?: boolean;
}) => {
  const { setWizardState } = useContext(PerformanceWizardContext);
  const { getFieldLabel } = useGetFieldLabel();
  const getDatasetLabel = useGetDatasetLabel();
  const [fieldLabel, setFieldLabel] = useState<string>(() =>
    getFieldLabel({ field, dataType, isVerbose: true }),
  );
  const [dataTypeLabel, setDataTypeLabel] = useState<string>(() =>
    getDatasetLabel(dataType),
  );

  // setDataTypeLabel
  useEffect(() => {
    setDataTypeLabel(getDatasetLabel(dataType));
  }, [dataType, getDatasetLabel]);

  // getFieldLabel
  useEffect(() => {
    setFieldLabel(getFieldLabel({ field, dataType, isVerbose: true }));
  }, [dataType, field, getFieldLabel]);

  const onRemoveClicked = useCallback(() => {
    setWizardState((currentState) => {
      const fields = currentState.config.fieldsStep.fields;
      const dataTypeFields = fields.find((f) => f.dataType === dataType);
      if (!dataTypeFields) {
        const error = new Error(`field: ${field}, dataType: ${dataType}`);
        error.name = 'FieldListItem: could not remove field';
        throw error;
      }

      const newFields = (() => {
        if (dataTypeFields.fields.length === 1) {
          return fields.filter((f) => f.dataType !== dataType);
        } else {
          return fields.map((df) => {
            if (df.dataType === dataType) {
              return {
                ...df,
                fields: df.fields.filter((f) => f !== field),
              };
            } else {
              return df;
            }
          });
        }
      })();

      return {
        ...currentState,
        config: {
          ...currentState.config,
          fieldsStep: {
            ...currentState.config.fieldsStep,
            fields: newFields,
          },
        },
      };
    });
  }, [dataType, field, setWizardState]);

  return (
    <ListItem>
      <ColDiv flex={3}>
        <Typography.Body type="Body 12">{fieldLabel}</Typography.Body>
      </ColDiv>
      <ColDiv flex={2}>
        <Typography.Body type="Body 12">{dataTypeLabel}</Typography.Body>
      </ColDiv>
      <ColDiv className={HIDE_UNLESS_HOVER_CLASS} isEnd>
        {!isReadOnly && (
          <Row centerAlign>
            <CloseButton close={onRemoveClicked} />
          </Row>
        )}
      </ColDiv>
    </ListItem>
  );
};

export default FieldListItem;
