import React from 'react';

interface SingleUseMetricPopupContextType {
  isSingleUsePopup: boolean;
  isSaveToDataManager: boolean;
  setIsSaveToDataManager: React.Dispatch<React.SetStateAction<boolean>>;
  onSingleUseMetricSaved: (metric: Metrics.Metric) => void;
  cancelButtonText: string;
}

const SingleUseMetricPopupContext =
  React.createContext<SingleUseMetricPopupContextType>({
    isSingleUsePopup: false,
    onSingleUseMetricSaved: () => {
      const e = new Error();
      e.name = 'onSingleUseMetricSaved is not defined';
      throw e;
    },
    isSaveToDataManager: false,
    setIsSaveToDataManager: () => {
      const e = new Error();
      e.name = 'setIsSaveToDataManager is not defined';
      throw e;
    },
    cancelButtonText: 'Cancel',
  });

export default SingleUseMetricPopupContext;
