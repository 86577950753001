import React, { useCallback, useEffect, useState } from 'react';

const useIsScrolling = ({
  scrollerRef,
  contentRef,
  isHorizontalScroll,
}: {
  scrollerRef: React.RefObject<HTMLDivElement> | null;
  contentRef: React.RefObject<HTMLDivElement> | null;
  isHorizontalScroll?: boolean;
}) => {
  const getIsScrolling = useCallback(() => {
    if (!scrollerRef || !scrollerRef.current) {
      return false;
    }
    const element = scrollerRef.current;
    return isHorizontalScroll
      ? element.offsetWidth < element.scrollWidth
      : element.offsetHeight < element.scrollHeight;
  }, [isHorizontalScroll, scrollerRef]);

  const [isScrolling, setIsScrolling] = useState(() => getIsScrolling());

  // Can be used within a useEffect or useLayoutEffect by consuming component
  const determineIfIsScrolling = useCallback(() => {
    setIsScrolling(getIsScrolling());
  }, [getIsScrolling]);

  useEffect(() => {
    if (!contentRef || !contentRef.current) {
      return;
    }
    const elem = contentRef.current;
    const resizeObserver = new MutationObserver(() => {
      determineIfIsScrolling();
    });
    resizeObserver.observe(elem, {
      childList: true,
      subtree: true,
    });
    return () => {
      resizeObserver.disconnect();
    };
  }, [determineIfIsScrolling, contentRef]);

  useEffect(() => {
    determineIfIsScrolling();

    window.addEventListener('resize', determineIfIsScrolling);
    return () => {
      window.removeEventListener('resize', determineIfIsScrolling);
    };
  }, [determineIfIsScrolling]);

  return isScrolling;
};

export default useIsScrolling;
