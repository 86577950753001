import React, { useContext } from 'react';
import TopNavigator from './TopNavigator';
import DataManagerSpecificSetContext from '../../../../contexts/DataManagerSpecificSetContext';

const TopNavigatorContainer = () => {
  const { baseView } = useContext(DataManagerSpecificSetContext);

  return <TopNavigator baseView={baseView} />;
};

export default TopNavigatorContainer;
