import React, { useContext } from 'react';

import Row from '../../../Common/Row';
import Typography from 'kingpin/atoms/Typography';
import { NabTabLinkMargin, NavTabButton } from '../../../NavTab';
import PermissionGates from '../../../PermissionGates';
import PERMISSIONS from '../../../../permissionDefinitions';
import PopupStyles from './PopupStyles';
import PerformanceWizardContext from '../../../../screens/DataManager/DatasetDefinitions/DatasetsIndex/PerformanceDatasetWizard/PerformanceWizardContext';
import TextInput from '../../../../kingpin/atoms/TextInput';

const CreateSingleUseButton = ({
  onCreateSingleUseMetricClicked,
}: {
  onCreateSingleUseMetricClicked: () => void;
}) => {
  const { wizardState } = useContext(PerformanceWizardContext);
  if (wizardState !== undefined) {
    return null;
  }

  return (
    <PermissionGates.Has
      requiredPermission={PERMISSIONS.DATA_MANAGEMENT.SINGLE_USE_METRICS_CONFIG}
    >
      <Typography.Body type="Link" onClick={onCreateSingleUseMetricClicked}>
        Create Single-Use Metric
      </Typography.Body>
    </PermissionGates.Has>
  );
};

const ListTop = ({
  searchText,
  onSearchTextChanged,
  onCreateSingleUseMetricClicked,
  navigationState,
}: {
  searchText: string;
  onSearchTextChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCreateSingleUseMetricClicked: () => void;
  navigationState: MetricPickerNavigationState;
}) => (
  <PopupStyles.PopupContent.ListTopDiv>
    <Row centerAlign spaceBetween style={{ marginBottom: 16 }}>
      <Typography.Header type="H4">Select A Metric</Typography.Header>
      <CreateSingleUseButton
        onCreateSingleUseMetricClicked={onCreateSingleUseMetricClicked}
      />
    </Row>
    <TextInput
      value={searchText}
      onChange={onSearchTextChanged}
      icon="search"
      placeholder="Search"
      autoFocus
    />
    {navigationState.isTabsEnabled && (
      <Row>
        <div style={{ marginRight: NabTabLinkMargin }}>
          <NavTabButton
            isSelected={navigationState.currentMode === 'core'}
            title={navigationState.coreLabel}
            onClick={navigationState.onCoreClicked}
            testId="core-metrics-tab"
          />
        </div>

        <NavTabButton
          isSelected={navigationState.currentMode === 'other'}
          title={navigationState.otherLabel}
          onClick={navigationState.onOtherClicked}
          testId="other-metrics-tab"
        />
      </Row>
    )}
    {!navigationState.isTabsEnabled && <div style={{ marginBottom: 12 }} />}
  </PopupStyles.PopupContent.ListTopDiv>
);

export default ListTop;
