import React, { useCallback, useContext, useEffect } from 'react';
import AddReportDrillDownButton from './AddReportDrillDownButton';
import usePopup from '../../hooks/usePopup';
import AnalyticsContext from '../../contexts/AnalyticsContext';
import ReportContext from '../../contexts/ReportContext';
import PopupContext from '../../contexts/PopupContext';

const AddReportDrillDownButtonContainer = ({
  isScope,
  isReport,
  onManualFilterChanged,
  onOpened,
  onFilterAdded,
  testIdPrefix,
  label,
  isDisabled,
  isDateScopeDisabled,
}: {
  isScope: boolean;
  isReport?: boolean;
  onManualFilterChanged?: () => void;
  onOpened?: () => void;
  onFilterAdded?: () => void;
  testIdPrefix?: string;
  label?: string;
  isDisabled?: boolean;
  isDateScopeDisabled?: boolean;
}) => {
  const { isOpen, open: openPopup, close } = usePopup();
  const { trackEvent } = useContext(AnalyticsContext);
  const { isOpen: isPopupReportOpen } = useContext(PopupContext);
  const reportContext = useContext(ReportContext);

  const open = useCallback(() => {
    openPopup();
    if (onOpened) {
      onOpened();
    }
  }, [onOpened, openPopup]);

  useEffect(() => {
    if (
      isReport &&
      isOpen &&
      reportContext &&
      !!reportContext.report &&
      !isPopupReportOpen
    ) {
      trackEvent('Report - Add Filter Clicked', {
        reportName: reportContext.report.name,
        reportId: reportContext.report.id,
      });
    }
  }, [isOpen, isPopupReportOpen, isReport, reportContext, trackEvent]);

  const isShowingLabel = true;

  return (
    <AddReportDrillDownButton
      isScope={isScope}
      isOpen={isOpen}
      open={open}
      close={close}
      isShowingLabel={isShowingLabel}
      onManualFilterChanged={onManualFilterChanged}
      onFilterAdded={onFilterAdded}
      testIdPrefix={testIdPrefix}
      label={label}
      isDisabled={isDisabled}
      isDateScopeDisabled={isDateScopeDisabled}
    />
  );
};

export default AddReportDrillDownButtonContainer;
