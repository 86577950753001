const NAV_SCROLLER_ID = 'new-commitment-slide-scroller';
const SUMMARY_ID = 'new-commitment-slide-summary';
const AWARDED_LOADS_ID = 'new-commitment-awarded-loads';
const REVENUE_ID = 'new-commitment-revenue';

const NewCommitmentSlideOutConstants = {
  SUMMARY_ID,
  AWARDED_LOADS_ID,
  REVENUE_ID,
  NAV_SCROLLER_ID,
};

export default NewCommitmentSlideOutConstants;
