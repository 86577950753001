import STORE from '../../store';

const updateMetric = async (
  id: string,
  newMetric: Metrics.NormalMetric,
  selectedAccountId: string,
) => {
  return STORE.metricDefinitions
    .getNormalMetricsRef({ accountId: selectedAccountId })
    .doc(id)
    .set(newMetric);
};

export default updateMetric;
