import React, { useContext, useEffect, useState } from 'react';
import TargetFormContext from '../contexts/TargetFormContext';
import AnalyticsContext from '../../../contexts/AnalyticsContext';
import useEntityField from './useEntityField';
import useTargetFormCallbacks from './useTargetFormCallbacks';

const useModeOptions = ({
  dataType,
  groupField,
  setGroupField,
  getConfirmation,
}: {
  dataType: string;
  groupField?: string;
  setGroupField: React.Dispatch<React.SetStateAction<string | undefined>>;
  getConfirmation: () => Promise<boolean>;
}) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const { setGroups, setIsShowingValidationFeedback } =
    useContext(TargetFormContext);
  const { resetGroups } = useTargetFormCallbacks();

  const entityField = useEntityField(dataType);
  const [mode, setMode] = useState<undefined | 'By Group' | 'All'>(
    groupField === undefined
      ? undefined
      : groupField === '*'
        ? 'All'
        : 'By Group',
  );
  const [modeOptions, setModeOptions] = useState<DropdownOption[]>([]);

  useEffect(() => {
    const newOptions = [
      {
        label: `All ${entityField}s`,
        subLabel: `Target applies to all ${entityField}s`,
        isSelected: mode === 'All',
        onSelected: async () => {
          const isConfirmed = await getConfirmation();
          if (!isConfirmed) {
            return;
          }
          setMode('All');
          setIsShowingValidationFeedback(false);
          trackEvent('Targets - Mode - All Selected');
          setGroupField('*');
          setGroups([]);
          resetGroups(false);
        },
      },
      {
        label: `${entityField}s by Group`,
        subLabel: `Target applies to groups of ${entityField}s`,
        isSelected: mode === 'By Group',
        onSelected: async () => {
          const isConfirmed = await getConfirmation();
          if (!isConfirmed) {
            return;
          }
          setMode('By Group');
          setIsShowingValidationFeedback(false);
          trackEvent('Targets - Mode - Group By Selected');
          setGroupField(undefined);
          setGroups([]);
          resetGroups(true);
        },
      },
    ];
    setModeOptions(newOptions);
  }, [
    entityField,
    getConfirmation,
    mode,
    resetGroups,
    setGroupField,
    setGroups,
    setIsShowingValidationFeedback,
    trackEvent,
  ]);

  return {
    modeOptions,
    mode,
  };
};

export default useModeOptions;
