import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import ContextMenu from '../../../ContextMenu';
import usePopup from '../../../../hooks/usePopup';
import CopyRulesToField from './CopyRulesToField';
import DuplicateRules from './DuplicateRules';
import DeleteRules from './DeleteRules';
import LastUpdatedAt from './LastUpdatedAt';

const ItemGroup = styled.div`
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.08);
  padding-bottom: 8px;
  margin-bottom: 8px;
`;

const List = styled.div`
  padding: 8px;
`;

const Menu = styled.div`
  cursor: default;
`;

const TargetContextMenu = ({
  target,
}: {
  target: Targets.Wizard.DataTypeTarget;
}) => {
  const { isOpen, open, close } = usePopup();
  // The confirmation popup dom sits outside this section of the tree.
  // As a result, click events on it are closing the context menu.
  // To work around this, this variable lets us disable the closing of the menu
  const [isCloseDisabled, setIsCloseDisabled] = useState<boolean>(false);
  const lockParent = useCallback(() => {
    setIsCloseDisabled(true);
  }, []);
  const unLockParent = useCallback(() => {
    setIsCloseDisabled(false);
  }, []);

  return (
    <Menu
      className={isOpen ? undefined : 'showOnMouseOver'}
      onClick={(x) => {
        x.preventDefault();
        x.stopPropagation();
      }}
    >
      <ContextMenu
        open={open}
        close={isCloseDisabled ? window.tokenFunction : close}
        isOpen={isOpen}
        placement="right-start"
        testId="target-context-menu"
      >
        <>
          <List>
            <ItemGroup>
              <CopyRulesToField
                isCloseDisabled={isCloseDisabled}
                target={target}
                closeParent={close}
                lockParent={lockParent}
                unlockParent={unLockParent}
              />
              <DuplicateRules target={target} close={close} />
            </ItemGroup>
            <DeleteRules
              target={target}
              lockParent={lockParent}
              unLockParent={unLockParent}
              close={close}
            />
          </List>
          <LastUpdatedAt target={target} />
        </>
      </ContextMenu>
    </Menu>
  );
};

export default TargetContextMenu;
