import getFixedFilterInput from './getFixedFilterInput';

const buildGoalFilterInput = (
  goal: GeneralGoal,
  toDrillDowns: ({
    plates,
    variableDrillDowns,
  }: {
    plates?: FilterPlate[] | undefined;
    variableDrillDowns?: VariableDrillDownType[] | undefined;
  }) => ReportDrillDownType[],
): { filterInput: FilterInput; dateScope: DateRangeInput } => {
  return getFixedFilterInput(goal, toDrillDowns);
};

export default buildGoalFilterInput;
