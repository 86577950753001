import React, { useContext } from 'react';
import { DateTime } from 'luxon';

import AccountContext from '../../../../contexts/AccountContext';
import useV5ChartData from '../../../../hooks/useV5ChartData';
import Loading from '../../../Loading';
import formatFloat from '../../../../api/getChartSeries/formatFloat';
import SmartCardContent from 'components/V5Gadget/SmartCard/SmartCardContent/SmartCardContent';
import useMetric from '../../../../hooks/useMetric';
import ComparisonContext from '../../../../contexts/ComparisonContext';
import getComparison from '../../Matrix/getComparison';
import CardContext from '../../../../contexts/CardContext';
import Card from '../../Card/CardContent';
import FlexCentered from '../../../Common/FlexCentered';

const buildV5SmartCardProps = (
  data: V5ChartData,
  comparisonData: V5ChartData | undefined,
  chartDef: V5ChartDefinition,
  unitsLocale: string,
  formatting: MetricFormatting,
  metric: Metrics.Metric,
  smartCardComparison: MatrixCellType,
): SmartCardProps | undefined => {
  if (!comparisonData) {
    throw new Error('Missing comparison data!');
  }

  if (chartDef.series.length === 0) {
    throw new Error(`Must have series defined on chart: ${chartDef.id}`);
  }
  if (chartDef.series[0].id === undefined) {
    throw new Error(`Series[0] id must be defined on chart: ${chartDef.id}`);
  }

  const { name } = chartDef;
  const { precision, prefix, postfix, positiveDeltaIsGood } = formatting;
  const metricId = chartDef.series[0].metricId;
  if (!data[metricId] || !comparisonData[metricId]) {
    return undefined;
  }

  const currentData = Object.values(data[metricId].response)[0];
  const previousData = Object.values(comparisonData[metricId].response)[0];
  const currentDate = DateTime.fromISO(currentData.date as string, {
    zone: 'utc',
  });
  const previousDate = DateTime.fromJSDate(
    new Date(previousData.date as string),
    { zone: 'utc' },
  );
  const currentDateRange = currentData.dateScope as DateRangeInput;
  const previousDateRange = previousData.dateScope as DateRangeInput;

  const numberOfDays = Math.round(currentDate.diff(previousDate, 'days').days);
  const current = formatFloat(currentData[metricId], precision);
  const previous = formatFloat(previousData[metricId], precision);

  const { delta: rawDelta, isGood } = getComparison({
    value: current,
    previous,
    formatting,
    type: 'delta',
  });
  const { delta } = getComparison({
    value: current,
    previous,
    formatting,
    type: 'percentDelta',
  });

  return {
    name,
    current,
    delta,
    rawDelta,
    isGood,
    positiveDeltaIsGood,
    prefix,
    postfix,
    unitsLocale,
    numberOfDays,
    chartDefinition: chartDef,
    smartCardComparison,
    formatting,
    metricId,
    tooltipProps: {
      current,
      previous,
      currentDateRange,
      previousDateRange,
      metric,
    },
  };
};

const SmartCardContentContainer = ({
  chartDefinition,
}: {
  chartDefinition: V5ChartDefinition;
}) => {
  const { unitsLocale } = useContext(AccountContext);
  const { h } = useContext(CardContext);
  const { currentComparison: comparison } = useContext(ComparisonContext);
  const series = chartDefinition.series[0];
  const metric = useMetric(series.metricId);
  const { data, isLoading, comparisonData } = useV5ChartData(chartDefinition);

  if (!data || !metric) {
    return (
      <FlexCentered style={{ height: '100%' }}>
        <Loading />
      </FlexCentered>
    );
  }
  if (!comparison || !comparisonData) {
    return <Card chartDefinition={chartDefinition} />;
  }

  const props = buildV5SmartCardProps(
    data,
    comparisonData,
    chartDefinition,
    unitsLocale,
    metric.formatting,
    metric,
    // @ts-ignore
    series.smartCardComparison || series.smartCardComparision || 'percentDelta',
    // Default undefined into percentDelta. Warning. Legacy configs.
  );

  if (!props) {
    return <Loading />;
  }

  return <SmartCardContent {...props} isLoading={isLoading} h={h} />;
};

export default SmartCardContentContainer;
