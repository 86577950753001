import { gql } from '@apollo/client';

const buildCalculateScoresQuery = (input: Scorecards.ScorecardInput) => ({
  query: gql`
    query calculateScores(
      $metrics: [KPIInput]!
      $dateScope: DateRangeInput!
      $interval: Interval!
    ) {
      calculateScores(
        metrics: $metrics
        dateScope: $dateScope
        interval: $interval
      ) {
        id
        value
        trend {
          date
          value
        }
      }
    }
  `,
  variables: {
    metrics: input.metrics,
    dateScope: input.dateScope,
    interval: input.interval,
  },
});

export default buildCalculateScoresQuery;
