import React from 'react';

import styled, { css } from 'styled-components';

const OffPositionStyle = css`
  left: 6.25%;
  right: 56.25%;
  top: 12.5%;
  bottom: 12.5%;
`;

const OnPositionStyle = css`
  left: 56.25%;
  right: 6.25%;
  top: 12.5%;
  bottom: 12.5%;
`;

const Marker = styled.div<{ value: boolean }>`
  width: 12px;
  height: 12px;
  transition: left 0.25s;

  ${(props) => (props.value ? OnPositionStyle : OffPositionStyle)}
  position: absolute;
  background: #ffffff;
  position: relative;
  border-radius: 6px;
`;

const Backdrop = styled.div<{
  value: boolean;
  isDisabled: boolean;
  color?: string;
}>`
  width: 32px;
  height: 16px;
  background-color: ${(props) =>
    props.value ? props.color || '#016CD1' : '#dcdcdc'};
  border-radius: 17px;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
`;

const Toggle = ({
  value,
  onChange,
  testId,
  isDisabled,
  color,
}: {
  value: boolean;
  onChange: (newValue: boolean) => void;
  testId?: string;
  isDisabled?: boolean;
  color?: string;
}) => {
  return (
    <Backdrop
      color={color}
      isDisabled={!!isDisabled}
      data-testid={testId}
      onClick={() => {
        if (isDisabled) {
          return;
        }
        onChange(!value);
      }}
      value={value}
    >
      <Marker value={value} />
    </Backdrop>
  );
};

export default Toggle;
