import React, { useContext } from 'react';
import FlexCentered from '../Common/FlexCentered';
import CanvasCard from '../Visualisations/CanvasCard';
import { ChartTopBar } from '../Chart';
import DashboardGadgetBottomBar from '../DashboardGadgetBottomBar';
import GaugeContent from './GaugeContent';
import DashboardGadgetContext from '../../contexts/DashboardGadgetContext';

const GaugeContainer = ({
  gauge,
}: {
  gauge: VisualisationDefinitions.Gauge;
}) => {
  const { dashboardGadget } = useContext(DashboardGadgetContext);

  return (
    <FlexCentered style={{ height: '100%', width: '100%' }}>
      <CanvasCard
        topBar={<ChartTopBar chartDefinition={gauge} />}
        content={<GaugeContent gauge={gauge} />}
        bottomBar={dashboardGadget ? <DashboardGadgetBottomBar /> : undefined}
        isBottomBarAbsolute
      />
    </FlexCentered>
  );
};

export default GaugeContainer;
