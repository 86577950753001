import { useCallback, useEffect, useState } from 'react';

const useIsSelected = ({
  metric,
  selectedMetricId,
}: {
  metric: Metrics.Metric;
  selectedMetricId?: string;
}) => {
  const getIsSelected = useCallback(
    () => !!selectedMetricId && selectedMetricId === metric.id,
    [metric.id, selectedMetricId],
  );
  const [isSelected, setIsSelected] = useState<boolean>(
    !!selectedMetricId && selectedMetricId === metric.id,
  );
  useEffect(() => {
    setIsSelected(getIsSelected());
  }, [getIsSelected]);

  return isSelected;
};

export default useIsSelected;
