import React, { ChangeEvent } from 'react';
import CloseButton from 'components/CloseButton';
import Button from 'kingpin/atoms/Button';
import Typography from 'kingpin/atoms/Typography';
import Colors2 from 'theme/Colors2';
import UploadFileSvg from '../icons/uploadFile.svg';
import FilesSvg from '../icons/files.svg';
import useValueFormatters from 'hooks/useValueFormatters';
import { LabelDiv } from 'screens/Boards/PerformanceBoardForm';
import Row from 'components/Common/Row';
import useGetSampleFile from '../hooks/useGetSampleFile';
import DownloadSvg from '../icons/downloadBlue.svg';

const UploadSection = ({
  file,
  setFile,
  onFileUpload,
  isUploading,
  inputFile,
  onFileChange,
  fileType,
  dataType,
}: {
  file: File | undefined;
  setFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  onFileUpload: () => void;
  isUploading: boolean;
  inputFile: React.MutableRefObject<HTMLInputElement | null>;
  onFileChange: (e: ChangeEvent<HTMLInputElement>) => void;
  fileType: string;
  dataType: string;
}) => {
  const { formatFileSize } = useValueFormatters();
  const fileSrc = useGetSampleFile();

  return (
    <>
      <Row
        style={{
          width: '100%',
          marginBottom: 16,
          justifyContent: 'space-between',
        }}
      >
        <LabelDiv>
          <Typography.Body type={'Label'}>Add Your Upload</Typography.Body>
        </LabelDiv>
        {dataType === 'customerLaneAwards' && (
          <a href={fileSrc}>
            <Row centerAlign>
              <img
                src={DownloadSvg}
                alt="download"
                style={{ marginRight: '4px' }}
              />
              <Typography.Body type="Body 12" color={Colors2.Secondary.info}>
                Download Sample
              </Typography.Body>
            </Row>
          </a>
        )}
      </Row>
      <div style={{ width: '100%', marginBottom: '32px' }}>
        <div
          style={{
            border: `1px ${file ? 'solid' : 'dashed'} ${Colors2.Grey['7']}`,
            position: 'relative',
            borderRadius: '4px',
            padding: '24px 0',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={file ? FilesSvg : UploadFileSvg}
            style={{ marginBottom: '16px' }}
            alt="uploadFile"
          />
          {file && (
            <>
              <div style={{ position: 'absolute', top: '4px', right: '4px' }}>
                <CloseButton
                  close={() => {
                    setFile(undefined);
                  }}
                />
              </div>
              <div
                style={{
                  marginBottom: '8px',
                }}
              >
                <Typography.Header type={'H5'} color={Colors2.Secondary.info}>
                  {file.name}
                </Typography.Header>
              </div>

              <div
                style={{
                  marginBottom: 20,
                }}
              >
                <Typography.Body type="Body 12" color={Colors2.Grey['2']}>
                  {`${
                    file.type === 'text/csv' ? '.csv' : file.type
                  } | ${formatFileSize(file.size)}`}
                </Typography.Body>
              </div>

              <Button
                onClick={onFileUpload}
                isLoading={isUploading}
                type="Primary"
                size="Small"
                label="Upload File"
              />
            </>
          )}
          {!file && (
            <>
              <div style={{ marginBottom: 12 }}>
                <Typography.Body type="Label" color={Colors2.Grey['3']}>
                  No File Added
                </Typography.Body>
              </div>

              <Button
                type="Tertiary"
                size="Small"
                onClick={() => {
                  if (inputFile.current) {
                    inputFile.current.click();
                  }
                }}
                label={`Select ${fileType.toUpperCase()}`}
              />
              <input
                ref={inputFile}
                type="file"
                id="add-service-failure-file"
                data-testid="input-service-failure-file"
                style={{ display: 'none' }}
                onChange={onFileChange}
                accept={fileType}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default UploadSection;
