import env from '.env.json';

const BASE_APPLICATION_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : env.ENV === 'Staging'
      ? 'https://staging.fleetops.com'
      : 'https://app.fleetops.com';

export default BASE_APPLICATION_URL;
