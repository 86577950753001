import React, { useContext, useEffect, useState } from 'react';
import Performance from './Performance';
import Loading from '../../Loading/Loading';
import getGridRows from '../../../api/getGridRows';
import { RECORDS_QUERY } from '../../Grid/gridConstants';
import GqlClientContext from '../../../contexts/GqlClientContext';
import BaseViewsContext from '../../../contexts/BaseViewsContext';
import BoardContext from '../../../contexts/BoardContext';
import Typography from 'kingpin/atoms/Typography';
import { CUSTOMER_LANE_COMMITMENTS } from '../../../constants';

const useTableData = (
  filterInput: FilterInput,
  dateScope: DateRangeInput,
  config: TargetVisConfig,
) => {
  const { board } = useContext(BoardContext);
  const { client } = useContext(GqlClientContext);
  const [data, setData] = useState<MetricsResponse>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!filterInput) {
      return;
    }

    setIsLoading(true);
    let isActive = true;
    getGridRows({
      client,
      query: RECORDS_QUERY,
      limit: 12,
      sortBy: [
        {
          field: 'date',
          sort: 'desc',
        },
      ],
      dataType: board ? board.dataType : CUSTOMER_LANE_COMMITMENTS,
      filters: [filterInput],
      dateScope,
    }).then((r) => {
      if (!isActive) {
        return;
      }
      setData(r);
      setIsLoading(false);
    });

    return () => {
      isActive = false;
    };
  }, [
    filterInput,
    dateScope,
    client,
    config.totalField,
    config.targetField,
    config.differenceField,
    config.titleLabel,
    board,
  ]);

  return {
    data,
    isLoading,
  };
};

export const PerformanceContainer = ({
  filterInput,
  dateScope,
  config,
  isPerformanceBoard,
  homeViz,
  isGridHidden,
}: {
  filterInput: FilterInput;
  dateScope: DateRangeInput;
  config: TargetVisConfig;
  isPerformanceBoard?: boolean;
  homeViz?: boolean;
  isGridHidden?: boolean;
}) => {
  const { baseViews } = useContext(BaseViewsContext);
  const { board } = useContext(BoardContext);
  const dataType = board.dataType;
  const baseView = baseViews[dataType];

  const { data, isLoading } = useTableData(filterInput, dateScope, config);
  const { cadence } = config;
  const cadenceLabel =
    cadence === 'day' ? 'Day' : cadence === 'month' ? 'Month' : 'Week';

  if (!baseView) {
    return null;
  }

  return (
    <div data-testid={'performance-preview'}>
      <Performance
        title={config.title}
        data={data}
        isLoading={isLoading}
        cadenceLabel={cadenceLabel}
        config={config}
        dataType={dataType}
        cadence={cadence}
        isPerformanceBoard={isPerformanceBoard}
        homeViz={homeViz}
        isGridHidden={isGridHidden}
      />
    </div>
  );
};

const Gate = ({
  filterInput,
  dateScope,
  config,
}: {
  filterInput?: FilterInput;
  dateScope?: DateRangeInput;
  config: TargetVisConfig;
}) => {
  if (filterInput && dateScope) {
    return (
      <PerformanceContainer
        filterInput={filterInput}
        config={config}
        dateScope={dateScope}
      />
    );
  } else {
    return (
      <>
        <Typography.Header type="H5">{config.title}</Typography.Header>
        <Loading isSmall />
      </>
    );
  }
};

export default Gate;
