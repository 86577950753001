import { useEffect, useState } from 'react';
import { useGetCadence } from '../../../contextProviders/BoardPeriodsProvider';
import toSentenceCase from '../../../services/toSentenceCase';

const useCadenceLabel = (dataType: string) => {
  const getCadence = useGetCadence(dataType);
  const [label, setLabel] = useState<string>('');

  useEffect(() => {
    const { cadence } = getCadence();

    setLabel(toSentenceCase(cadence));
  }, [getCadence]);

  return label;
};

export default useCadenceLabel;
