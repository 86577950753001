import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import Button from 'kingpin/atoms/Button';
import Typography from 'kingpin/atoms/Typography';

import LandingPage from '../../components/LandingPage';
import Inputs from '../../components/Inputs';
import InputError from '../../components/InputError';
import OnboardingTopBar from './OnboardingTopBar';
import PasswordInput from './PasswordInput';

const Heading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 34px;
`;

const AcceptInvite = ({
  email,
  displayName,
  onDisplayNameChanged,
  password,
  onPasswordChanged,
  passwordErrors,
  onSubmit,
  isLoading,
  isValid,
  errorMessage,
  isHidingPassword,
  setIsHidingPassword,
}: {
  email: string;
  displayName: string;
  onDisplayNameChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  password: string;
  onPasswordChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  passwordErrors?: string[];
  onSubmit: () => void;
  isLoading: boolean;
  isValid: boolean;
  errorMessage?: string;
  isHidingPassword: boolean;
  setIsHidingPassword: React.Dispatch<React.SetStateAction<boolean>>;
}) => (
  <LandingPage style={{ width: 'unset' }}>
    <OnboardingTopBar />
    <div style={{ width: '400px' }}>
      <Heading>
        <div style={{ marginBottom: '8px' }}>
          <Typography.Header type="H2">{`Welcome ${
            email.split('@')[0]
          }`}</Typography.Header>
        </div>

        <Typography.Body type="Body 14">
          Please complete your profile details below
        </Typography.Body>
      </Heading>
      <div style={{ marginBottom: 16 }}>
        <div style={{ marginBottom: '4px' }}>
          <Typography.Body type="Label">Full Name</Typography.Body>
        </div>
        <Inputs.TextInput
          value={displayName}
          onChange={onDisplayNameChanged}
          data-testid="display-name-input"
        />
      </div>
      <div style={{ marginBottom: 16 }}>
        <div style={{ marginBottom: '4px' }}>
          <Typography.Body type="Label">Email</Typography.Body>
        </div>
        <Inputs.TextInput
          value={email}
          readOnly
          onChange={window.tokenFunction}
          state={'Disabled'}
        />
      </div>
      <div style={{ marginBottom: 16 }}>
        <PasswordInput
          password={password}
          onPasswordChanged={onPasswordChanged}
          passwordErrors={passwordErrors}
          isHidingPassword={isHidingPassword}
          setIsHidingPassword={setIsHidingPassword}
          label="Create Password"
        />
      </div>

      {errorMessage && <InputError text={errorMessage} />}
      <Button
        onClick={onSubmit}
        isDisabled={!isValid || isLoading}
        isLoading={isLoading}
        label="Sign Up"
        type="Primary"
        size="Small"
      />
    </div>
  </LandingPage>
);

export default AcceptInvite;
