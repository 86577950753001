import { useCallback, useEffect, useState } from 'react';

const useAlreadyInUseOptions = (options: ExclusiveDropdownOption[]) => {
  const getAlreadyInUse = useCallback(() => {
    return options.filter((o) => !!o.isInUseElsewhere);
  }, [options]);
  const [alreadyInUse, setAlreadyInUse] = useState<ExclusiveDropdownOption[]>(
    () => getAlreadyInUse(),
  );
  useEffect(() => {
    setAlreadyInUse(getAlreadyInUse());
  }, [getAlreadyInUse]);

  const getNotAlreadyInUse = useCallback(() => {
    return options.filter((o) => o.isInUseElsewhere === undefined);
  }, [options]);
  const [notAlreadyInUse, setNotAlreadyInUse] = useState<
    ExclusiveDropdownOption[]
  >(() => getNotAlreadyInUse());
  useEffect(() => {
    setNotAlreadyInUse(getNotAlreadyInUse());
  }, [getNotAlreadyInUse]);

  return {
    alreadyInUseOptions: alreadyInUse,
    notAlreadyInUseOptions: notAlreadyInUse,
  };
};

export default useAlreadyInUseOptions;
