import React from 'react';
import Typography from 'kingpin/atoms/Typography';
import Comments from '../Comments';
import { buildShowOrderDetails } from '../../navigation/appRoutes';

const DetailsSlideOutComments = ({
  selectedOrderNumber,
}: {
  selectedOrderNumber: string;
}) => (
  <div>
    <div style={{ marginBottom: 24 }}>
      <Typography.Header type={'H5'}>Comments</Typography.Header>
    </div>
    <Comments
      commentableId={selectedOrderNumber}
      commentableType="Order"
      mentionedEmailBody={`mentioned you in on an order - ${selectedOrderNumber}`}
      watcherEmailBody={`commented on an order - ${selectedOrderNumber}`}
      emailLink={buildShowOrderDetails(selectedOrderNumber)}
    />
  </div>
);

export default DetailsSlideOutComments;
