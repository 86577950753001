import React from 'react';

import ListItemWrapper from '../ListItemWrapper';
import Typography from 'kingpin/atoms/Typography';
import Column from '../Column';
import PERMISSIONS from '../../../../permissionDefinitions';
import PermissionGates from 'components/PermissionGates';

const Headers = () => (
  <ListItemWrapper
    style={{
      alignItems: 'center',
      backgroundColor: '#F8F8F8',
      borderRadius: '8px',
      borderBottom: 'unset',
      boxShadow: 'unset',
      height: '48px',
    }}
  >
    <Column>
      <Typography.Body type="Label">Dataset</Typography.Body>
    </Column>
    <Column>
      <Typography.Body type="Label">Display name</Typography.Body>
    </Column>
    <Column>
      <Typography.Body type="Label">Description</Typography.Body>
    </Column>
    <PermissionGates.Has
      requiredPermission={
        PERMISSIONS.DATA_MANAGEMENT.HIDE_DATASET_FROM_CUSTOMER
      }
    >
      <Column>
        <Typography.Body type="Label">Hidden from customer</Typography.Body>
      </Column>
    </PermissionGates.Has>
    <Column>
      <Typography.Body type="Label">Last updated</Typography.Body>
    </Column>
    <PermissionGates.Has
      requiredPermission={PERMISSIONS.DATA_MANAGEMENT.TRIGGER_ADF_PIPELINE}
    >
      {/* Trigger Pipeline */}
      <Column />
    </PermissionGates.Has>
  </ListItemWrapper>
);

export default Headers;
