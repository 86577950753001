import React, { useCallback, useEffect } from 'react';

import Typography from 'kingpin/atoms/Typography';
import usePopup from '../../../../../hooks/usePopup';
import InlineDialog from '../../../../../components/InlineDialog';
import ListElements from '../../ListElements';
import useShouldWarn from './useShouldWarn';
import ConfirmationModal from '../../../../../components/ConfirmationModal';
import Row from '../../../../../components/Common/Row';
import Button from '../../../../../kingpin/atoms/Button';

const SecondaryList = ({
  secondaryActions,
}: {
  secondaryActions: {
    label: string;
    onClick: () => void;
  }[];
}) => (
  <ListElements.ListDiv>
    {secondaryActions.map(({ label, onClick }) => (
      <ListElements.ListItem key={label} onClick={onClick}>
        <Typography.Body type="Body 12">{label}</Typography.Body>
      </ListElements.ListItem>
    ))}
  </ListElements.ListDiv>
);

const MetricActions = ({
  isDisabled,
  isLoading,
  primaryAction,
  secondaryActions,
  selectedMetric,
}: {
  isDisabled: boolean;
  isLoading: boolean;
  primaryAction: {
    label: string;
    onClick: () => void;
    isDisabled?: boolean;
  };
  secondaryActions:
    | {
        label: string;
        onClick: () => void;
      }[]
    | undefined;
  selectedMetric?: Metrics.NormalMetric | Metrics.CompoundMetric;
}) => {
  const { isOpen, open, close } = usePopup();
  const {
    isOpen: isWarningOpen,
    open: openWarning,
    close: closeWarning,
  } = usePopup();

  const { shouldWarn, usageCount } = useShouldWarn(selectedMetric);

  const onPrimaryClicked = useCallback(() => {
    if (shouldWarn) {
      openWarning();
      return;
    }

    if (primaryAction.isDisabled) {
      return;
    }

    primaryAction.onClick();
  }, [openWarning, primaryAction, shouldWarn]);
  const onWarningConfirmed = useCallback(() => {
    if (primaryAction.isDisabled) {
      return;
    }

    closeWarning();
    primaryAction.onClick();
  }, [closeWarning, primaryAction]);

  useEffect(() => {
    if (isDisabled) {
      close();
    }
  }, [close, isDisabled]);

  return (
    <>
      <Row centerAlign>
        <div>
          <Button
            size={'Small'}
            type={'Primary'}
            onClick={onPrimaryClicked}
            isDisabled={primaryAction.isDisabled}
            label={primaryAction.label}
            testId={
              isDisabled || primaryAction.isDisabled
                ? undefined
                : `action-${primaryAction.label}`
            }
          />
        </div>
        {secondaryActions && (
          <div style={{ marginLeft: 4 }}>
            <InlineDialog
              isOpen={isOpen}
              onClose={close}
              content={<SecondaryList secondaryActions={secondaryActions} />}
            >
              <Button
                size={'Small'}
                type={'Secondary'}
                isDisabled={isDisabled}
                onClick={isOpen ? close : open}
                icon="chevron-down"
              />
            </InlineDialog>
          </div>
        )}
      </Row>
      <ConfirmationModal
        isOpen={isWarningOpen}
        close={closeWarning}
        onConfirmed={onWarningConfirmed}
        confirmText={`Update ${usageCount} items`}
        cancelText={'Cancel'}
        title={'Warning'}
        bodyComponent={
          <Typography.Body type="Body 12">
            {`This Metric is associated with ${usageCount} items.
              
              All of which will be affected by this change.
              
              Please review the usage report before confirming this change.
              
              `}
          </Typography.Body>
        }
      />
    </>
  );
};

export default MetricActions;
