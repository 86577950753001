import metricTypeCheckers from '../../../types/metricTypeCheckers';
import { FILTER_ID_QUERY_PARAM } from '../DatasetFilters/constants';
import getPortalSlug from '../../../navigation/getPortalSlug';

const BASE = `data-manager/datasets/:dataset`;

const FIELD_SETTINGS = `/data-manager/datasets/:dataset/fields`;

const buildBase = (dataset: string, portal: Portal | undefined) =>
  `/${getPortalSlug(portal)}data-manager/datasets/${dataset}`;

const buildMetrics = (dataset: string, portal: Portal | undefined) =>
  `${buildBase(dataset, portal)}/metrics`;

const buildCompoundMetrics = (dataset: string, portal: Portal | undefined) =>
  `${buildBase(dataset, portal)}/compound-metrics`;

export const getMetricLinkForDataset = (
  metric: Metrics.Metric,
  dataset: string,
  portal: Portal | undefined,
) => {
  if (metricTypeCheckers.isCompoundMetric(metric)) {
    return `${buildCompoundMetrics(dataset, portal)}?metricId=${metric.id}`;
  } else if (metricTypeCheckers.isNormalMetric(metric)) {
    return `${buildMetrics(dataset, portal)}?metricId=${metric.id}`;
  } else {
    return '';
  }
};

const buildSearch = (dataset: string, portal: Portal | undefined) =>
  `${buildBase(dataset, portal)}/search`;

const buildFilters = ({
  dataset,
  portal,
  filterId,
}: {
  dataset: string;
  filterId?: string;
  portal: Portal | undefined;
}) =>
  `${buildBase(dataset, portal)}/filters${filterId ? `?${FILTER_ID_QUERY_PARAM}=${filterId}` : ''}`;

export const getValidationReportLink = ({
  dataset,
  reportId,
  portal,
}: {
  dataset: string;
  reportId: string;
  portal: Portal | undefined;
}) => `${buildBase(dataset, portal)}/validation-reports/${reportId}`;

const buildFieldSettings = (dataset: string, portal: Portal | undefined) =>
  `${buildBase(dataset, portal)}/fields`;

const SPECIFIC_SET_ROUTES = {
  BASE: `/${BASE}`,
  buildBase,
  FIELD_SETTINGS,
  buildFieldSettings,
  METRICS: `/${BASE}/metrics`,
  SHOW_METRIC: `/${BASE}/metrics/:metricId`,
  buildMetrics,
  COMPOUND_METRICS: `/${BASE}/compound-metrics`,
  SHOW_COMPOUND_METRIC: `/${BASE}/compound-metrics/:compoundMetricId`,
  buildCompoundMetrics,
  SHOW_REPORT: `/${BASE}/validation-reports/:reportId`,
  SHOW_SEARCH: `/${BASE}/search`,
  SHOW_FILTERS: `/${BASE}/filters`,
  buildSearch,
  buildFilters,
};

export default SPECIFIC_SET_ROUTES;
