import React from 'react';
import styled from 'styled-components';
import Typography from 'kingpin/atoms/Typography';
import Tooltip from 'components/Tooltip';
import useValueFormatters from 'hooks/useValueFormatters';
import useMetric from 'hooks/useMetric';
import Colors2 from 'theme/Colors2';

import getDiffPercentage from './getDiffPercentage';
import { IPaceRow } from './types';
import DiffTooltip from './DiffTooltip';
import getDiffColors from './getDiffColors';

const ContentDiv = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1px;
  flex: 1 0 0;
  align-self: stretch;
  height: 100%;
`;

const SideDivOuter = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;

  &:nth-of-type(2n) {
    border-left: 1px solid ${Colors2.Border};
  }
`;

const SideDivInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  gap: 4px;
`;

const Side = ({
  label,
  value,
  diff,
  metric,
  actualDateRange,
  averageDateRange,
  excludedDateRangeLabels,
}: {
  label: string;
  value: number;
  diff: number;
  metric: Metrics.Metric;
  actualDateRange: string;
  averageDateRange: string;
  excludedDateRangeLabels: string[];
}) => {
  const { formatMetric } = useValueFormatters();
  const { textColor, iconBackgroundColor } = getDiffColors({
    diff,
    positiveDeltaIsGood: metric.formatting.positiveDeltaIsGood,
  });
  const actualFormatted = formatMetric({
    value,
    metricId: metric.id,
  });
  const formattedDiff = formatMetric({
    metricId: metric.id,
    value: diff,
  });
  const average = value - diff;
  const diffPercentage = getDiffPercentage({
    actual: value,
    average,
  });
  const formattedAverage = formatMetric({
    metricId: metric.id,
    value: average,
  });
  const icon = diff < 0 ? 'arrow-down' : 'arrow-up';

  return (
    <SideDivOuter>
      <Tooltip
        isAltTooltip
        content={
          <DiffTooltip
            iconBackgroundColor={iconBackgroundColor}
            textColor={textColor}
            icon={icon}
            actualDateRange={actualDateRange}
            avgDateRange={averageDateRange}
            avgDayOfWeekHeading={'Historical Average'}
            actualDayOfWeekHeading={label}
            excludedDateRangeLabels={excludedDateRangeLabels}
            formatted={{
              actual: actualFormatted,
              avg: formattedAverage,
              diff: formattedDiff,
              diffPercentage: diffPercentage,
            }}
          />
        }
      >
        <SideDivInner>
          <Typography.Header type="H6">{label}</Typography.Header>
          <Typography.Header type="H2">{actualFormatted}</Typography.Header>
          <Typography.Header
            type="H6"
            color={textColor}
          >{`${formattedDiff} ${diffPercentage}`}</Typography.Header>
        </SideDivInner>
      </Tooltip>
    </SideDivOuter>
  );
};

const NoData = () => (
  <ContentDiv>
    <Typography.Header type={'H6'}>No Data</Typography.Header>
  </ContentDiv>
);

const PaceCard = ({
  actual,
  actualDiff,
  pace,
  paceDiff,
  paceMatrix,
  actualDateRange,
  averageDateRange,
  excludedDateRangeLabels,
}: {
  actual: number | null;
  actualDiff: number | null;
  pace: number | null;
  paceDiff: number | null;
  paceMatrix: VisualisationDefinitions.PaceMatrix;
  actualDateRange: string;
  averageDateRange: string;
  excludedDateRangeLabels: string[];
}) => {
  const metric = useMetric(paceMatrix.metricId);
  if (
    actual === null ||
    actualDiff === null ||
    pace === null ||
    paceDiff === null ||
    !metric
  ) {
    return <NoData />;
  }

  return (
    <ContentDiv>
      {paceMatrix.hasTotalsCol && (
        <Side
          label="Actual"
          value={actual}
          diff={actualDiff}
          metric={metric}
          actualDateRange={actualDateRange}
          averageDateRange={averageDateRange}
          excludedDateRangeLabels={excludedDateRangeLabels}
        />
      )}
      {paceMatrix.hasPaceCol && (
        <Side
          label="Pace"
          value={pace}
          diff={paceDiff}
          metric={metric}
          actualDateRange={actualDateRange}
          averageDateRange={averageDateRange}
          excludedDateRangeLabels={excludedDateRangeLabels}
        />
      )}
    </ContentDiv>
  );
};

const Gate = ({
  totalsRow,
  paceMatrix,
  getActualDateRange,
  getAverageDateRange,
  getExcludedDateRangeLabels,
}: {
  totalsRow: IPaceRow[];
  paceMatrix: VisualisationDefinitions.PaceMatrix;
  getActualDateRange: () => string;
  getAverageDateRange: () => string;
  getExcludedDateRangeLabels: () => string[];
}) => {
  if (totalsRow.length === 0) {
    return <NoData />;
  }
  const totals = totalsRow[0];
  const { actual, actualDiff, paceDiff, pace } = totals.totals;

  return (
    <PaceCard
      actual={actual}
      actualDiff={actualDiff}
      pace={pace}
      paceDiff={paceDiff}
      paceMatrix={paceMatrix}
      actualDateRange={getActualDateRange()}
      averageDateRange={getAverageDateRange()}
      excludedDateRangeLabels={getExcludedDateRangeLabels()}
    />
  );
};

export default Gate;
