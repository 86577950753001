import React from 'react';

import Button from 'kingpin/atoms/Button';
import styled from 'styled-components';

const Positioner = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 900001;
`;

const CloseModal = ({ close }: { close: () => void }) => (
  <Positioner>
    <Button
      testId="close"
      icon="cross"
      onClick={close}
      type="Secondary"
      size="Small"
    />
  </Positioner>
);

export default CloseModal;
