import { ApisauceInstance } from 'apisauce';

const triggerPipeline = ({
  dataType,
  startDate,
  endDate,
  api,
}: {
  dataType: string;
  startDate: string;
  endDate: string;
  api: ApisauceInstance;
}) =>
  api.post('/integrations/run_pipeline', {
    dataType,
    startDate,
    endDate,
  });

export default triggerPipeline;
