import isDateOptionEqual from './isDateOptionEqual';

const doesArrayContainDateOption = (
  options: DateOption[],
  option: DateOption,
) => {
  return options.some((o) => isDateOptionEqual(o, option));
};

export default doesArrayContainDateOption;
