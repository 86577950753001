import React from 'react';

export type DatasetFilterLookup = {
  [dataset: string]: DatasetFilter[] | undefined;
};

export type DatasetFilterIdLookup = {
  [datasetFilterId: string]: DatasetFilter | undefined;
};

interface DatasetFiltersContextType {
  datasetFilters: DatasetFilter[];
  getDefaultDatasetFilterPlates: ({
    dataset,
  }: {
    dataset: string;
  }) => DatasetFilterPlate[];
  getDataTypeFilterPlates: (dataTypes: string[]) => DatasetFilter[];
  datasetFilterLookup: DatasetFilterLookup;
  datasetFilterIdLookup: DatasetFilterIdLookup;
}

const DatasetFiltersContext = React.createContext<DatasetFiltersContextType>({
  datasetFilters: [],
  getDefaultDatasetFilterPlates: () => [],
  getDataTypeFilterPlates: () => [],
  datasetFilterLookup: {},
  datasetFilterIdLookup: {},
});

export default DatasetFiltersContext;
