import useGetDatasetDefinition from './useGetDatasetDefinition';
import { useCallback, useContext } from 'react';
import MetricOptionsContext from '../contexts/MetricOptionsContext';

// Find the metric (if any) which this metric's field
// is derived from on
const useGetOriginMetric = () => {
  const getDatasetDefinition = useGetDatasetDefinition();
  const { metricOptionsLookup } = useContext(MetricOptionsContext);

  const getOriginMetric = useCallback(
    (metric: Metrics.NormalMetric) => {
      const dataset = getDatasetDefinition(metric.dataType);
      if (!dataset) {
        return undefined;
      }

      const field = dataset.fields.find((f) => f.field === metric.field);
      if (!field || !field.metricId) {
        return undefined;
      }

      return metricOptionsLookup[field.metricId];
    },
    [getDatasetDefinition, metricOptionsLookup],
  );

  return getOriginMetric;
};

export default useGetOriginMetric;
