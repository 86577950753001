import { useContext, useEffect, useState } from 'react';
import GqlClientContext from 'contexts/GqlClientContext';
import getActivityDateFields from '../../api/getActivityDateFields';

const useGetActivitiesDateFields = () => {
  const { client } = useContext(GqlClientContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [dateFields, setDateFields] = useState<string[]>([]);

  useEffect(() => {
    let isActive = true;
    setIsLoading(true);
    getActivityDateFields(client).then((newFields) => {
      if (isActive) {
        setDateFields(newFields);
        setIsLoading(false);
      }
    });
    return () => {
      isActive = false;
    };
  }, [client]);

  return { dateFields, isLoading };
};

export default useGetActivitiesDateFields;
