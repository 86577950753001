import React, { useContext, useEffect, useState } from 'react';
import Flash from '../components/Flash';
import VersionContext from '../contexts/VersionContext';
import getCurrentBuildNumber from '../api/getCurrentBuildNumber';
import AnalyticsContext from '../contexts/AnalyticsContext';
import reloadWhenSafe from '../reloadWhenSafe';

const VersionProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const [isFlashVisible, setIsFlashVisible] = useState<boolean>(false);
  const [buildNumber, setBuildNumber] = useState<number | undefined>();

  useEffect(() => {
    getCurrentBuildNumber().then((n) => {
      if (!n) {
        return;
      }
      const lastVersion = window.localStorage.getItem('version');

      // eslint-disable-next-line eqeqeq
      if (lastVersion === null || Number.parseInt(lastVersion, 10) !== n.n) {
        setIsFlashVisible(true);
        if (n) {
          window.localStorage.setItem('version', n.n.toString());
        }
      }
      setBuildNumber(n.n);
    });
  }, []);

  useEffect(() => {
    if (!buildNumber) {
      return;
    }
    const interval = setInterval(
      () => {
        getCurrentBuildNumber().then((n) => {
          if (!n) {
            return;
          }

          if (n.n !== buildNumber) {
            trackEvent('App - Automatically Updated');
            setTimeout(() => {
              reloadWhenSafe();
            }, 1000);
          }
        });
      },
      1000 * 60 * 5,
    );
    return () => {
      clearInterval(interval);
    };
  }, [buildNumber, trackEvent]);

  return (
    <VersionContext.Provider value={{ buildNumber }}>
      <Flash
        isFlashVisible={isFlashVisible}
        setIsFlashVisible={setIsFlashVisible}
        type="info"
        message="FleetOps has been updated"
      />
      {children}
    </VersionContext.Provider>
  );
};

export default VersionProvider;
