import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import search from './grids/search';
import { toDateRange } from '../contexts/BoardContext';
import AccountContext from '../contexts/AccountContext';
import GqlClientContext from '../contexts/GqlClientContext';

const useDriverScore = ({
  driver,
  bonusPeriod,
}: {
  driver: string;
  bonusPeriod: BonusPeriod;
}) => {
  const { client } = useContext(GqlClientContext);
  const { driverScoreDataSet } = useContext(AccountContext);
  const [driverScore, setDriverScore] = useState<ElasticDocument | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const previousInputs = useRef<
    { driver: string; bonusPeriodId: string } | undefined
  >();

  const fetchData = useCallback(() => {
    if (!driverScoreDataSet) {
      return;
    }
    previousInputs.current = { bonusPeriodId: bonusPeriod.id, driver };
    setIsLoading(true);
    search({
      client,
      dataType: driverScoreDataSet,
      filters: [
        {
          keywords: [
            {
              field: 'driver',
              values: [driver],
            },
          ],
        },
      ],
      dateScope: toDateRange(bonusPeriod),
      sortBy: [],
    }).then(({ documents }) => {
      if (documents.length > 1 || documents.length === 0) {
        setDriverScore(undefined);
        setIsLoading(false);
      } else {
        setDriverScore(documents[0]);
        setIsLoading(false);
      }
    });
  }, [bonusPeriod, client, driver, driverScoreDataSet]);

  useEffect(() => {
    if (
      previousInputs.current &&
      previousInputs.current.bonusPeriodId === bonusPeriod.id &&
      previousInputs.current.driver === driver
    ) {
      return;
    }
    fetchData();
  }, [bonusPeriod.id, driver, fetchData]);

  return {
    fetchData,
    isLoading,
    driverScore,
    setDriverScore,
  };
};

export default useDriverScore;
