import React from 'react';
import styled from 'styled-components';

import StepContent, { StepContentBody } from '../StepContent';
import Typography from 'kingpin/atoms/Typography';
import Row from '../../../../../../components/Common/Row';
import Button from 'kingpin/atoms/Button';
import {
  NabTabLinkMargin,
  NavTabButton,
} from '../../../../../../components/NavTab';
import DetailsTab from './DetailsTab';
import MetricsTab from './MetricsTab';
import FieldsTab from './FieldsTab';
import SaveAndCloseButton from '../SaveAndCloseButton';
import UpdateWarning from './UpdateWarning';
import CreateWarning from './CreateWarning';
import CreateButton from '../CreateButton';
import UpdateButton from '../UpdateButton';

const TabContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  flex: 1;
`;

const BottomBarDiv = styled.div`
  padding-top: 24px;
  border-top: 1px solid #eeeeee;
`;

const ConfirmationStep = ({
  selectedTab,
  goToDetails,
  goToMetrics,
  goToFields,
  goToPreviousStep,
  isEditing,
}: {
  selectedTab: 'details' | 'metrics' | 'fields';
  goToDetails: () => void;
  goToMetrics: () => void;
  goToFields: () => void;
  goToPreviousStep: () => void;
  isEditing: boolean;
}) => (
  <StepContent>
    <StepContentBody>
      <Typography.Header type="H4">Confirm Dataset Details</Typography.Header>
      <Row style={{ marginBottom: 16 }}>
        <div style={{ marginRight: NabTabLinkMargin }}>
          <NavTabButton
            onClick={goToDetails}
            isSelected={selectedTab === 'details'}
            title="Details"
          />
        </div>
        <div style={{ marginRight: NabTabLinkMargin }}>
          <NavTabButton
            onClick={goToMetrics}
            isSelected={selectedTab === 'metrics'}
            title="Metrics"
          />
        </div>
        <NavTabButton
          onClick={goToFields}
          isSelected={selectedTab === 'fields'}
          title="Fields"
        />
      </Row>
      <TabContent>
        {selectedTab === 'details' && <DetailsTab />}
        {selectedTab === 'metrics' && <MetricsTab />}
        {selectedTab === 'fields' && <FieldsTab />}
      </TabContent>
    </StepContentBody>
    <BottomBarDiv>
      <div style={{ marginBottom: 24 }}>
        {isEditing && <UpdateWarning />}
        {!isEditing && <CreateWarning />}
      </div>
      <Row spaceBetween>
        <Button
          type="Tertiary"
          size="Small"
          onClick={goToPreviousStep}
          label="Back"
        />
        <Row>
          <SaveAndCloseButton />
          {!isEditing && <CreateButton />}
          {isEditing && <UpdateButton />}
        </Row>
      </Row>
    </BottomBarDiv>
  </StepContent>
);

export default ConfirmationStep;
