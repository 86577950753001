export const USER_ROLES: { [key: string]: FleetOps.Role } = {
  VIEWER: 'fleetops.roles.viewer',
  EDITOR: 'fleetops.roles.editor',
  ADMIN: 'fleetops.roles.admin',
  BOARD_OWNER: 'fleetops.roles.board_owner',
  CAMPAIGN_OWNER: 'fleetops.roles.campaign_owner',
  ACCOUNT_OWNER: 'fleetops.roles.account_owner',
  FLEETOPS_SUPPORT: 'fleetops.roles.fleetops_support',
  FLEETOPS_SALES: 'fleetops.roles.fleetops_sales',
  FLEETOPS_SUPER_ADMIN: 'fleetops.roles.superadmin',
};

export const FLEET_OPS_STAFF_ROLES = [
  USER_ROLES.FLEETOPS_SUPPORT,
  USER_ROLES.FLEETOPS_SALES,
  USER_ROLES.FLEETOPS_SUPER_ADMIN,
];

export default USER_ROLES;
