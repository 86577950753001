import useGetDatasetLabel from 'components/Inputs/MetricPicker/MetricPickerPopup/hooks/useGetDatasetLabel';
import DatasetDefinitionsContext from 'contexts/DatasetDefinitionsContext';
import { useContext, useEffect, useState } from 'react';

const usePerformanceDatasetsOptions = () => {
  const { performanceDatasets } = useContext(DatasetDefinitionsContext);
  const [datasetOptions, setDatasetOptions] = useState<DropdownOption[]>([]);
  const [selectedDataset, setSelectedDataset] =
    useState<FleetOps.DatasetDefinition>();
  const getDatasetLabel = useGetDatasetLabel();

  useEffect(() => {
    const newOptions = performanceDatasets.map((d) => ({
      label: getDatasetLabel(d),
      value: getDatasetLabel(d),
      isSelected: selectedDataset === d,
      onSelected: () => {
        setSelectedDataset(d);
      },
    }));

    setDatasetOptions(newOptions);
  }, [performanceDatasets, getDatasetLabel, selectedDataset]);

  return { datasetOptions, selectedDataset };
};

export default usePerformanceDatasetsOptions;
