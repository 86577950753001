import { isKpiRow } from 'hooks/kpiTypeCheckers';
import { useCallback } from 'react';
import captureException from 'services/captureException';
import visTypeCheckers from 'types/visTypeCheckers';

const useGetMetricIdsFromVis = () => {
  const getMetricIds = useCallback(
    (vis?: ExtendedVisualisationDefinition): string[] => {
      if (vis === undefined) {
        return [];
      } else if (visTypeCheckers.isGeneralGoal(vis)) {
        return [vis.metricId, ...vis.kpis.map((k) => k.metricId)];
      } else if (visTypeCheckers.isGauge(vis)) {
        return [vis.metricId];
      } else if (visTypeCheckers.isRankingMatrix(vis)) {
        return vis.metrics.map((m) => m.metricId);
      } else if (visTypeCheckers.isSingleMetricDateMatrix(vis)) {
        return [vis.metricId];
      } else if (visTypeCheckers.isRemindersGadget(vis)) {
        return [];
      } else if (visTypeCheckers.isMetricList(vis)) {
        return vis.list.map((li) => li.metricId);
      } else if (visTypeCheckers.isScorecard(vis)) {
        return vis.kpis.filter(isKpiRow).map((k) => k.metricId);
      } else if (visTypeCheckers.isV5ChartDef(vis)) {
        return vis.series.map((s) => s.metricId);
      } else if (visTypeCheckers.isPaceMatrix(vis)) {
        return [vis.metricId];
      } else {
        const e = new Error();
        e.name = 'Unknown vis type found';
        e.message = vis;
        captureException(e);
        return [];
      }
    },
    [],
  );

  return getMetricIds;
};

export default useGetMetricIdsFromVis;
