import firebase from 'firebase/compat/app';
import STORE_CONSTANTS from '../constants';
import withoutNulls from '../../api/search/withoutNulls';

export const PERFORMANCE_BOARDS_DOC = 'performanceBoards';
const CURRENT_PERFORMANCE_BOARDS_VERSION = 'v3';

const performanceBoardsConverter = {
  toFirestore(
    wizardState: PerformanceBoardTypes.Board,
  ): firebase.firestore.DocumentData {
    return withoutNulls({ ...wizardState });
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ): PerformanceBoardTypes.Board {
    const data = {
      ...snapshot.data(options),
    };
    return data as PerformanceBoardTypes.Board;
  },
};

const getPerformanceBoardsRef = ({
  accountId,
  db,
}: {
  accountId: string;
  db?: firebase.firestore.Firestore;
}) => {
  return (db ? db : firebase.firestore())
    .collection('accounts')
    .doc(accountId)
    .collection(STORE_CONSTANTS.CONTENT_DISTRIBUTIONS_COLLECTION)
    .doc(PERFORMANCE_BOARDS_DOC)
    .collection(CURRENT_PERFORMANCE_BOARDS_VERSION)
    .withConverter(performanceBoardsConverter);
};

export default getPerformanceBoardsRef;
