import React, { useCallback, useContext, useEffect, useState } from 'react';
import aguid from 'aguid';

import InviteUsersForm from './InviteUsersForm';
import WorkSpacesContext from '../../contexts/WorkSpacesContext';
import ReportsContext from '../../contexts/ReportsContext';
import DashboardsContext from '../../contexts/DashboardsContext';
import { UserInvitationRow } from './types';
import RolesContext from '../../contexts/RolesContext';
import inviteUser from '../../api/inviteUser';
import emailRegex from './emailRegex';
import SuccessFeedback from './SuccessFeedback';
import ProblemsFeedback from './ProblemsFeedback';
import AnalyticsContext from '../../contexts/AnalyticsContext';
import CurrentUserContext from '../../contexts/CurrentUserContext';
import isDefined from '../../isDefined';
import CloudFunctionClientContext from '../../contexts/CloudFunctionClientContext';
import useDefaultPermissions from './useDefaultPermissions';
import USER_ROLES from '../../roles';
import UsersSettingsContext from '../../contexts/UsersSettingsContext';

const useAvailableAssets = () => {
  const { id } = useContext(CurrentUserContext);
  const { allWorkSpaces } = useContext(WorkSpacesContext);
  const { allReports } = useContext(ReportsContext);
  const { allDashboards } = useContext(DashboardsContext);

  const accessOk = (a: ResourceAccess, createdById: string) =>
    (a.type === 'Private' && createdById === id) ||
    a.type === 'Users' ||
    a.type === 'Admin Only';

  const availableWorkspaces = allWorkSpaces.filter((w) =>
    accessOk(w.access, w.createdBy),
  );
  const availableReports = allReports.filter((r) =>
    accessOk(r.access, r.createdBy),
  );
  const availableDashboards = allDashboards
    .filter((d) => accessOk(d.access, d.createdBy))
    .filter(
      (d) => !d.isTemplate && !d.isEntityDetailsTab && !d.isWallboardSlide,
    );

  return {
    workSpaces: availableWorkspaces,
    reports: availableReports,
    dashboards: availableDashboards,
  };
};

const useWarning = (inviteRows: UserInvitationRow[]) => {
  const { allWorkSpaces } = useContext(WorkSpacesContext);
  const { allReports } = useContext(ReportsContext);
  const { allDashboards } = useContext(DashboardsContext);
  const [isShowingWarning, setIsShowingWarning] = useState<boolean>(false);
  const [warningContent, setWarningContent] = useState<string[]>([]);

  useEffect(() => {
    const usedWorkSpaces = inviteRows
      .map((r) => r.assets.workSpaceIds)
      .reduce((usedIds, ids) => {
        const newIds = [...usedIds];
        ids.forEach((id) => {
          if (!newIds.includes(id)) {
            newIds.push(id);
          }
        });

        return newIds;
      }, [] as string[])
      .map((id) => allWorkSpaces.find((ws) => ws.id === id))
      .filter(isDefined);
    const usedReports = inviteRows
      .map((r) => r.assets.reportIds)
      .reduce((usedIds, ids) => {
        const newIds = [...usedIds];
        ids.forEach((id) => {
          if (!newIds.includes(id)) {
            newIds.push(id);
          }
        });

        return newIds;
      }, [] as string[])
      .map((id) => allReports.find((ws) => ws.id === id))
      .filter(isDefined);
    const usedDashboards = inviteRows
      .map((r) => r.assets.dashboardIds)
      .reduce((usedIds, ids) => {
        const newIds = [...usedIds];
        ids.forEach((id) => {
          if (!newIds.includes(id)) {
            newIds.push(id);
          }
        });

        return newIds;
      }, [] as string[])
      .map((id) => allDashboards.find((ws) => ws.id === id))
      .filter(isDefined);

    const privateWorkspaces = usedWorkSpaces.filter(
      (ws) => ws.access.type === 'Private' || ws.access.type === 'Admin Only',
    );
    const privateDashboards = usedDashboards.filter(
      (d) => d.access.type === 'Private' || d.access.type === 'Admin Only',
    );
    const privateReports = usedReports.filter(
      (r) => r.access.type === 'Private' || r.access.type === 'Admin Only',
    );

    if (
      privateWorkspaces.length > 0 ||
      privateDashboards.length > 0 ||
      privateReports.length > 0
    ) {
      setIsShowingWarning(true);
      const warnings = [
        'Access level of the following assets will change from Admin Only to Users',
        ...privateWorkspaces.map((ws) => `- ${ws.title}`),
        ...privateDashboards.map((d) => `- ${d.name}`),
        ...privateReports.map((r) => `- ${r.name}`),
      ];
      setWarningContent(warnings);
    } else {
      setIsShowingWarning(false);
      setWarningContent([]);
    }
  }, [allDashboards, allReports, allWorkSpaces, inviteRows]);

  return {
    isShowingWarning,
    warningContent,
  };
};

const InviteUsersFormContainer = ({
  close,
  hasUnsavedChangesRef,
}: {
  close: () => void;
  hasUnsavedChangesRef: React.MutableRefObject<boolean>;
}) => {
  const { usedSeats, maxSeats, isSeatsLimited } =
    useContext(UsersSettingsContext);
  const { api } = useContext(CloudFunctionClientContext);
  const defaultPermissions = useDefaultPermissions();
  const { trackEvent } = useContext(AnalyticsContext);
  const { grantableRoles } = useContext(RolesContext);
  const { workSpaces, reports, dashboards } = useAvailableAssets();

  const [rows, setRows] = useState<UserInvitationRow[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowingSuccessFeedback, setIsShowingSuccessFeedback] =
    useState<boolean>(false);
  const [isShowingProblemsFeedback, setIsShowingProblemsFeedback] =
    useState<boolean>(false);
  const { isShowingWarning, warningContent } = useWarning(rows);

  const isAtSeatLimit =
    maxSeats === undefined
      ? false
      : isSeatsLimited
        ? rows.length + usedSeats >= maxSeats
        : false;

  useEffect(() => {
    const hasUnsavedWork = rows.some(
      (r) =>
        r.email !== '' ||
        r.assets.workSpaceIds.length > 0 ||
        r.assets.dashboardIds.length > 0 ||
        r.assets.reportIds.length > 0,
    );
    hasUnsavedChangesRef.current = hasUnsavedWork;
  }, [hasUnsavedChangesRef, rows]);

  const onAddAnotherClicked = useCallback(() => {
    if (grantableRoles.length === 0) {
      return;
    }

    if (isAtSeatLimit) {
      return;
    }

    setRows((currentRows) => [
      ...currentRows,
      {
        id: aguid(),
        email: '',
        roles: [grantableRoles.filter((r) => r.visible)[0]],
        permissions: defaultPermissions,
        assets: { workSpaceIds: [], dashboardIds: [], reportIds: [] },
      },
    ]);
  }, [defaultPermissions, grantableRoles, isAtSeatLimit]);

  const onRemoveClicked = useCallback((row: UserInvitationRow) => {
    setRows((currentRows) => currentRows.filter((r) => r.id !== row.id));
  }, []);

  const updateRow = useCallback((row: UserInvitationRow) => {
    setRows((currentRows) =>
      currentRows.map((r) => {
        if (r.id === row.id) {
          return row;
        } else {
          return r;
        }
      }),
    );
  }, []);

  const sendInvites = useCallback(async () => {
    setIsLoading(true);
    const inviteRequests = rows.map((r) =>
      inviteUser({
        email: r.email,
        isBillingInvite: false,
        isTmsInvite: false,
        roles: r.roles.map((ro) => ro.id),
        permissions: r.permissions,
        assignedAssets: r.assets,
        api,
      }).then((response) => {
        hasUnsavedChangesRef.current = false;

        if (response.ok) {
          trackEvent('Sign Ups - User Invite', {
            email: r.email,
            isAdmin: r.roles.some((x) => x.id === USER_ROLES.ADMIN)
              ? 'true'
              : 'false',
            role: r.roles[0].id,
          });
          return {
            id: r.id,
            ok: true,
          };
        } else {
          return {
            id: r.id,
            ok: false,
          };
        }
      }),
    );
    const responses = await Promise.all(inviteRequests);
    const problems = responses.filter((r) => !r.ok);
    if (problems.length === 0) {
      setIsShowingSuccessFeedback(true);
    } else {
      setRows((currentRows) => {
        const newRows = currentRows.map((row) => {
          const hasProblem = problems.some((p) => p.id === row.id);
          return {
            ...row,
            hasProblem,
          };
        });

        return newRows;
      });
      setIsShowingProblemsFeedback(true);
    }
    setIsLoading(false);
  }, [api, hasUnsavedChangesRef, rows, trackEvent]);

  // Set the initial row
  useEffect(() => {
    if (rows.length === 0) {
      onAddAnotherClicked();
    }
  }, [onAddAnotherClicked, rows.length]);

  const isSendDisabled = rows.some((r) => !emailRegex.test(r.email));

  const onRetryClicked = useCallback(() => {
    setRows((currentRows) => currentRows.filter((r) => !!r.hasProblem));
    setIsShowingProblemsFeedback(false);
  }, []);

  if (isShowingProblemsFeedback) {
    return (
      <ProblemsFeedback
        close={close}
        onRetryClicked={onRetryClicked}
        problems={rows.filter((r) => !!r.hasProblem)}
        ok={rows.filter((r) => !r.hasProblem)}
      />
    );
  } else if (isShowingSuccessFeedback) {
    return <SuccessFeedback close={close} />;
  } else {
    return (
      <InviteUsersForm
        rows={rows}
        roles={grantableRoles.filter((r) =>
          [USER_ROLES.VIEWER, USER_ROLES.EDITOR, USER_ROLES.ADMIN].includes(
            r.id,
          ),
        )}
        onAddAnotherClicked={onAddAnotherClicked}
        onRemoveClicked={onRemoveClicked}
        close={close}
        updateRow={updateRow}
        sendInvites={sendInvites}
        isLoading={isLoading}
        workSpaces={workSpaces}
        reports={reports}
        dashboards={dashboards}
        isSendDisabled={isSendDisabled}
        isShowingWarning={isShowingWarning}
        warningContent={warningContent}
        isAtSeatLimit={isAtSeatLimit}
      />
    );
  }
};

export default InviteUsersFormContainer;
