import { useContext, useEffect, useState } from 'react';
import AccountPickerContext from '../contexts/AccountPickerContext';
import STORE from '../store';

const useMetricsListGadgets = () => {
  const [metricLists, setMetricLists] = useState<MetricListGadgetType[]>([]);
  const [metricListsLookup, setMetricListsLookup] = useState<{
    [id: string]: MetricListGadgetType | undefined;
  }>({});
  const [isLoading, setLoading] = useState(true);
  const { selectedAccountId } = useContext(AccountPickerContext);

  useEffect(() => {
    setLoading(true);
    const listener = STORE.visualisations
      .getMetricListsRef({
        accountId: selectedAccountId,
      })
      .onSnapshot((snapshot) => {
        const data: MetricListGadgetType[] = [];
        snapshot.docs.forEach((d) => data.push(d.data()));
        setMetricLists(data);
        setLoading(false);
      });
    return () => {
      listener();
    };
  }, [selectedAccountId]);

  useEffect(() => {
    const newLookup = {} as { [id: string]: MetricListGadgetType | undefined };
    metricLists.forEach((gadget) => {
      newLookup[gadget.id] = gadget;
    });
    setMetricListsLookup(newLookup);
  }, [metricLists]);

  return {
    metricLists,
    metricListsLookup,
    isLoading,
  };
};

export default useMetricsListGadgets;
