import React, { useContext, useEffect, useState } from 'react';
import AccountContext from '../../../contexts/AccountContext';
import moment from 'moment';
import getDateBuckets from '../../GoalShow/getDateBuckets';
import formatDateLabel, {
  formatVerboseWeek,
} from '../../../components/V5Gadget/formatDateLabel';
import isIsoDate from '../../../types/scorecardDates/isIsoDate';
import {
  convertIsoDateToWeekDate,
  convertWeekDateToIsoDate,
  floorIsoToWeekStart,
} from '../../../types/scorecardDates/convertDates';

const toIso = (
  date: string | Scorecards.WeekDate | undefined,
  weekStartsOn: WeekStartsOn,
) => {
  if (!date) {
    return undefined;
  }

  if (isIsoDate(date)) {
    return date;
  }

  return convertWeekDateToIsoDate(date, weekStartsOn);
};

const useStartDateOptions = ({
  cadence,
  startDate,
  setStartDate,
  weekStartsOnOverride,
  minDate,
  isFutureAllowed,
}: {
  cadence: 'week' | 'month';
  setStartDate: React.Dispatch<
    React.SetStateAction<Scorecards.IsoDate | Scorecards.WeekDate | undefined>
  >;
  weekStartsOnOverride?: WeekStartsOn;
  startDate?: Scorecards.IsoDate | Scorecards.WeekDate;
  minDate?: Scorecards.IsoDate | Scorecards.WeekDate;
  isFutureAllowed?: boolean;
}) => {
  const { weekStartsOn, demoAccountNow, isDemoAccount } =
    useContext(AccountContext);
  const [options, setOptions] = useState<DropdownOption[]>([]);
  const [todayOption, setTodayOption] = useState<DropdownOption | undefined>();
  const [selectedLabel, setSelectedLabel] = useState<string | undefined>();

  useEffect(() => {
    const startDateToUse = (() => {
      const startDateIso = toIso(
        startDate,
        weekStartsOnOverride ? weekStartsOnOverride : weekStartsOn,
      );
      return moment(
        startDateIso
          ? startDateIso
          : isDemoAccount
            ? demoAccountNow
            : undefined,
      )
        .subtract({ year: 1 })
        .format('YYYY-MM-DD');
    })();
    setSelectedLabel(undefined);
    setOptions(
      getDateBuckets({
        startDate: startDateToUse,
        endDate: moment(isDemoAccount ? demoAccountNow : undefined)
          .add({ year: isFutureAllowed ? 1 : 0 })
          .format('YYYY-MM-DD'),
        interval: cadence,
        weekStartsOn: weekStartsOnOverride
          ? weekStartsOnOverride
          : weekStartsOn,
      })
        .reverse()
        .map((date) => {
          const label =
            cadence === 'week'
              ? formatVerboseWeek({
                  startDate: date,
                  isYearImportant: true,
                  isShort: false,
                })
              : formatDateLabel(
                  date,
                  cadence,
                  false,
                  undefined,
                  undefined,
                  true,
                );
          return {
            label,
            value: date,
            onSelected: () => {
              if (cadence === 'week') {
                setStartDate(
                  convertIsoDateToWeekDate(
                    date,
                    weekStartsOnOverride ? weekStartsOnOverride : weekStartsOn,
                  ),
                );
              } else {
                setStartDate(date);
              }
            },
          };
        })
        .filter((date) => {
          if (!minDate) {
            return true;
          }
          if (isIsoDate(minDate)) {
            return date.value >= minDate;
          } else {
            return (
              date.value >=
              convertWeekDateToIsoDate(
                minDate,
                weekStartsOnOverride ? weekStartsOnOverride : weekStartsOn,
              )
            );
          }
        }),
    );
  }, [
    cadence,
    minDate,
    setStartDate,
    startDate,
    weekStartsOnOverride,
    weekStartsOn,
    isDemoAccount,
    demoAccountNow,
    isFutureAllowed,
  ]);

  useEffect(() => {
    if (!startDate) {
      setSelectedLabel(undefined);
      return;
    }
    if (isIsoDate(startDate)) {
      const selectedOption = options.find((o) => o.value === startDate);
      if (selectedOption) {
        setSelectedLabel(selectedOption.label);
      } else {
        setSelectedLabel(undefined);
      }
    } else {
      const sDate = convertWeekDateToIsoDate(
        startDate,
        weekStartsOnOverride ? weekStartsOnOverride : weekStartsOn,
      );

      const selectedOption = options.find((o) => o.value === sDate);
      if (selectedOption) {
        setSelectedLabel(selectedOption.label);
      } else {
        setSelectedLabel(undefined);
      }
    }
  }, [options, startDate, weekStartsOn, weekStartsOnOverride]);

  useEffect(() => {
    const currentDate = (() => {
      const now = moment(isDemoAccount ? demoAccountNow : undefined);
      if (cadence === 'month') {
        return now.startOf('month').format('YYYY-MM-DD');
      } else {
        return floorIsoToWeekStart(
          now.format('YYYY-MM-DD'),
          weekStartsOnOverride || weekStartsOn,
        );
      }
    })();

    const currentOption = options.find((o) => {
      return o.value === currentDate;
    });
    setTodayOption(currentOption);
  }, [
    cadence,
    demoAccountNow,
    isDemoAccount,
    options,
    weekStartsOn,
    weekStartsOnOverride,
  ]);

  return {
    startDateLabel: selectedLabel,
    startDateOptions: options,
    todayOption,
  };
};

export default useStartDateOptions;
