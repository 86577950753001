import React, { ChangeEvent } from 'react';
import {
  GadgetFormInner,
  GadgetFormInnerContent,
  GadgetFormInnerTopBar,
  GadgetFormPreview,
  GadgetFormWrapper,
} from '../V5GadgetForm/V5GadgetForm';
import Typography from 'kingpin/atoms/Typography';
import { Section } from '../MyAccount/Profile/styles';
import Inputs from '../Inputs';
import RankingMetricInput from './RankingMetricInput';
import AddRankingMetricButton from './AddRankingMetricButton';
import RankingMatrixSortingInput from './RankingMatrixSortingInput';
import Row from '../Common/Row';
import Preview from './Preview';
import Button from 'kingpin/atoms/Button';

const RankingMatrixForm = ({
  isGadgetBuilder,
  name,
  description,
  metrics,
  updateMetric,
  onMetricAdded,
  removeMetric,
  groupByField,
  groupingOptions,
  sorting,
  setSorting,
  isValid,
  draftGadget,
  onNameChanged,
  onDescriptionChanged,
  onSave,
  isEditing,
  onDelete,
  onSaveAs,
  isLoading,
}: {
  // Base
  isGadgetBuilder?: boolean;
  name: string;
  onNameChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  description: string;
  onDescriptionChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  // Ranking Matrix
  metrics: VisualisationDefinitions.RankingMatrixMetric[];
  updateMetric: (
    newMetric: VisualisationDefinitions.RankingMatrixMetric,
  ) => void;
  onMetricAdded: (metric: Metrics.Metric) => void;
  removeMetric: (
    removedMetric: VisualisationDefinitions.RankingMatrixMetric,
  ) => void;
  groupByField?: string;
  groupingOptions: DropdownOption[];
  sorting: VisualisationDefinitions.RankingMatrixSorting;
  setSorting: React.Dispatch<
    React.SetStateAction<VisualisationDefinitions.RankingMatrixSorting>
  >;
  // Misc
  isEditing: boolean;
  onDelete: () => void;
  onSaveAs: () => void;
  isValid: boolean;
  draftGadget?: VisualisationDefinitions.RankingMatrix;
  isLoading: boolean;
  onSave: () => void;
}) => (
  <GadgetFormWrapper>
    <GadgetFormInner isGadgetBuilder={!!isGadgetBuilder}>
      <GadgetFormInnerTopBar isGadgetBuilder={!!isGadgetBuilder}>
        <Typography.Header type="H4">
          Matrix (% of total, Rank)
        </Typography.Header>
      </GadgetFormInnerTopBar>
      <GadgetFormInnerContent isGadgetBuilder={!!isGadgetBuilder}>
        <Section>
          <div style={{ marginBottom: 4 }}>
            <Typography.Body type="Label">Name</Typography.Body>
          </div>
          <Inputs.TextInput
            placeholder="Name"
            value={name}
            onChange={onNameChanged}
            data-testid="name"
          />
        </Section>
        <Section>
          <div style={{ marginBottom: 4 }}>
            <Typography.Body type="Label">Description</Typography.Body>
          </div>
          <Inputs.TextInput
            placeholder="Description"
            value={description}
            onChange={onDescriptionChanged}
          />
        </Section>
        <Section>
          <div style={{ marginBottom: 4 }}>
            <Typography.Body type="Label">Metrics</Typography.Body>
          </div>
          {metrics.map((metric) => (
            <RankingMetricInput
              key={metric.key}
              metric={metric}
              updateMetric={updateMetric}
              removeMetric={removeMetric}
              groupByField={groupByField}
            />
          ))}
          <AddRankingMetricButton
            onMetricAdded={onMetricAdded}
            groupByField={groupByField}
          />
        </Section>
        <Section>
          <div style={{ marginBottom: 4 }}>
            <Typography.Body type="Label">Grouping</Typography.Body>
          </div>
          <Inputs.Dropdown
            isSearchEnabled
            isDisabled={groupingOptions.length === 0}
            options={groupingOptions}
            selectedLabel={groupByField}
            testId="grouping"
          />
        </Section>
        <Section>
          <div style={{ marginBottom: 4 }}>
            <Typography.Body type="Label">Sorting</Typography.Body>
          </div>
          <RankingMatrixSortingInput
            sorting={sorting}
            setSorting={setSorting}
            metrics={metrics}
          />
        </Section>
        {isGadgetBuilder && (
          <Section>
            <>
              {isEditing && (
                <Row centerAlign spaceBetween style={{ marginBottom: 12 }}>
                  <Button
                    onClick={onDelete}
                    isDisabled={isLoading || !isValid}
                    isLoading={isLoading}
                    type="Secondary"
                    size="Small"
                    label="Delete"
                  />
                  <Row>
                    <div style={{ marginRight: 12 }}>
                      <Button
                        onClick={onSaveAs}
                        isLoading={isLoading}
                        isDisabled={isLoading || !isValid}
                        testId={
                          isLoading || !isValid ? 'loading-save-as' : 'save-as'
                        }
                        label="Save as"
                        type="Primary"
                        size="Small"
                      />
                    </div>
                    <Button
                      onClick={onSave}
                      isLoading={isLoading}
                      isDisabled={isLoading || !isValid}
                      testId={
                        isLoading || !isValid ? 'loading-update' : 'update'
                      }
                      label="Update"
                      type="Primary"
                      size="Small"
                    />
                  </Row>
                </Row>
              )}
              {!isEditing && (
                <Row centerAlign style={{ marginBottom: 12 }}>
                  <Button
                    type="Primary"
                    size="Small"
                    onClick={onSave}
                    isLoading={isLoading}
                    isDisabled={isLoading || !isValid}
                    testId={isLoading ? 'loading-create' : 'create'}
                    label="Create"
                  />
                </Row>
              )}
            </>
          </Section>
        )}
      </GadgetFormInnerContent>
    </GadgetFormInner>
    {isGadgetBuilder && (
      <GadgetFormPreview>
        {draftGadget && <Preview gadget={draftGadget} />}
        {!draftGadget && (
          <Typography.Body type="Body 12">
            Please fill out the form for a preview
          </Typography.Body>
        )}
      </GadgetFormPreview>
    )}
  </GadgetFormWrapper>
);

export default RankingMatrixForm;
