import React from 'react';
import Button from 'kingpin/atoms/Button';

const GroupStepButtonOverride =
  ({
    label,
    isDisabled,
    setWizardState,
  }: {
    label: string;
    isDisabled: boolean;
    setWizardState: (value: React.SetStateAction<Costs.WizardState>) => void;
  }) =>
  ({ open }: { open: () => void }) => {
    const onClick = () => {
      setWizardState((wizardState) => ({
        ...wizardState,
        config: {
          ...wizardState.config,
          groupStep: {
            ...wizardState.config.groupStep,
            currentGroup: [],
          },
        },
      }));
      open();
    };

    return (
      <div style={{ marginBottom: '32px' }}>
        <Button
          type="Tertiary"
          size="Small"
          onClick={onClick}
          label={label}
          isDisabled={isDisabled}
        />
      </div>
    );
  };

export default GroupStepButtonOverride;
