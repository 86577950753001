import Inputs from 'components/Inputs';
import React, { useCallback, useEffect, useState } from 'react';
import useUsers from '../../hooks/useUsers';

const MultiUserSelectContainer = ({
  selectedUsers,
  setSelectedUsers,
  isOnlyMarkStaff,
  allowedUserIds,
  isSingle,
}: {
  selectedUsers: string[];
  setSelectedUsers: React.Dispatch<React.SetStateAction<string[]>>;
  isOnlyMarkStaff?: boolean;
  allowedUserIds?: string[];
  isSingle?: boolean;
}) => {
  const users = useUsers(isOnlyMarkStaff, allowedUserIds);

  const buildOptions = useCallback((): DropdownOption[] => {
    return users.map((u) => ({
      key: u.id,
      label: u.displayName,
      isSelected: selectedUsers.some((su) => su === u.id),
      onSelected: () =>
        setSelectedUsers((currentSelected) => {
          const isSelected = currentSelected.some((cu) => cu === u.id);
          if (isSingle) {
            if (isSelected) {
              return [];
            }
            return [u.id];
          } else {
            if (isSelected) {
              return currentSelected.filter((cu) => cu !== u.id);
            }
            return [...currentSelected, u.id];
          }
        }),
    }));
  }, [isSingle, selectedUsers, setSelectedUsers, users]);
  const [options, setOptions] = useState<DropdownOption[]>(() =>
    buildOptions(),
  );
  useEffect(() => {
    setOptions(buildOptions());
  }, [buildOptions]);

  return (
    <Inputs.Dropdown
      isMulti
      options={options}
      placeholder={isSingle ? 'Select User' : 'Select Users'}
      isSearchEnabled
      testId="user-selector"
    />
  );
};

export default MultiUserSelectContainer;
