import { useCallback } from 'react';

const useValidateText = () => {
  // Note: This script has been copied and pasted from gql
  // for validation purposes
  const validateText = useCallback(({ text }: { text: string }) => {
    const charPattern = new RegExp('(:|;|<|>)');
    const htmlPattern = new RegExp(
      '</?\\w+((\\s+\\w+(\\s*=\\s*(?:".*?"|\'.*?\'|[^\'">\\s]+))?)+\\s*|\\s*)/?>',
    );
    const handlebarsPattern = new RegExp('{{{?(#[a-z]+ )?[a-z]+.[a-z]*}?}}');
    const numberPattern = new RegExp('[0-9]+');

    if (typeof text !== 'string') return false;
    if (charPattern.test(text)) return false;
    if (htmlPattern.test(text)) return false;
    if (handlebarsPattern.test(text)) return false;

    let isValid = true;
    try {
      if (!numberPattern.test(text)) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const x = JSON.parse(text);
        isValid = false;
      }
    } catch (_) {
      //
    }

    return isValid;
  }, []);

  return validateText;
};

export default useValidateText;
