import React from 'react';

interface GroupedLoadingContextType {
  isLoading: boolean;
  addLoader: (l: Loader) => void;
  removeLoader: (l: Loader) => void;
  updateLoader: (l: Loader) => void;
  restartLoading: () => void;
}

const GroupedLoadingContext = React.createContext<GroupedLoadingContextType>(
  {} as GroupedLoadingContextType,
);

export default GroupedLoadingContext;
