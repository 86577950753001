import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import Button from 'kingpin/atoms/Button';

import Inputs from 'components/Inputs';
import Typography from 'kingpin/atoms/Typography';
import Row from 'components/Common/Row';
import ReportDrillDowns from '../../../../components/ReportDrillDowns/ReportDrillDowns';
import ReportDrillDownsProvider from '../../../../contextProviders/ReportDrillDownsProvider';
import Form from '../../../../kingpin/forms/Form';
import FormHeader from '../../../../kingpin/forms/FormHeader';
import FormContent from '../../../../kingpin/forms/FormContent';
import AccessInput from '../../../../components/AccessInput';

const FormInputItem = styled.div`
  margin-bottom: 16px;
  &:last-of-type {
    margin-bottom: 0px;
  }
`;

const EntityForm = ({
  name,
  onNameChanged,
  entityDataset,
  entityDatasetOptions,
  primaryFieldOptions,
  secondaryFieldOptions,
  contextFieldOptions,
  isEnabled,
  setIsEnabled,
  isActiveFilters,
  setIsActiveFilters,
  access,
  setAccess,
  onSave,
  isLoading,
  isAccessOnly,
  isReadOnly,
  isEditing,
  close,
  isValid,
}: {
  name: string;
  onNameChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  entityDataset?: string;
  entityDatasetOptions: DropdownOption[];
  primaryFieldOptions: DropdownOption[];
  secondaryFieldOptions: DropdownOption[];
  contextFieldOptions: DropdownOption[];
  isEnabled: boolean;
  setIsEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  isActiveFilters: FilterPlate[];
  setIsActiveFilters: React.Dispatch<React.SetStateAction<FilterPlate[]>>;
  access: ResourceAccess;
  setAccess: React.Dispatch<React.SetStateAction<ResourceAccess>>;
  onSave: () => void;
  isLoading: boolean;
  isEditing: boolean;
  isAccessOnly?: boolean;
  isReadOnly: boolean;
  close: () => void;
  isValid: boolean;
}) => (
  <Form>
    <FormHeader
      title={isEditing ? `Editing ${name}` : `New Entity`}
      onClose={close}
    />
    <FormContent>
      {!isAccessOnly && (
        <>
          <FormInputItem>
            <Typography.Body type="Label">Entity Name</Typography.Body>
            <Inputs.TextInput
              value={name}
              onChange={onNameChanged}
              readOnly={isReadOnly}
              data-testid={'entity-name'}
              width="100%"
              inputSize="Small"
            />
          </FormInputItem>
          <FormInputItem>
            <Typography.Body type="Label">Select Dataset</Typography.Body>
            <Inputs.Dropdown
              isSearchEnabled
              fullWidth
              isDisabled={isReadOnly}
              options={entityDatasetOptions}
              placeholder="Select Dataset"
              testId={'select-dataset'}
            />
          </FormInputItem>
          <FormInputItem>
            <Typography.Body type="Label">Select Primary Field</Typography.Body>
            <Inputs.Dropdown
              isSearchEnabled
              fullWidth
              isDisabled={isReadOnly}
              options={primaryFieldOptions}
              placeholder="Select Field"
              testId={'select-field'}
            />
          </FormInputItem>
          <FormInputItem>
            <Typography.Body type="Label">
              Select Secondary Entity Field(s)
            </Typography.Body>
            <Inputs.Dropdown
              isSearchEnabled
              fullWidth
              isDisabled={isReadOnly}
              options={secondaryFieldOptions}
              placeholder="Select Field(s)"
              isMulti
              testId={'select-secondary'}
            />
          </FormInputItem>
          <FormInputItem>
            <Typography.Body type="Label">
              Select Context Field(s)
            </Typography.Body>
            <Inputs.Dropdown
              isSearchEnabled
              isDisabled={isReadOnly}
              options={contextFieldOptions}
              placeholder="Select Field(s)"
              isMulti
              testId={'select-context'}
            />
          </FormInputItem>
          <FormInputItem>
            <Typography.Body type="Label">Is Active Filter</Typography.Body>
            <ReportDrillDownsProvider
              drillDowns={isActiveFilters}
              setDrillDowns={setIsActiveFilters}
              dataType={entityDataset}
            >
              <ReportDrillDowns
                drillDowns={isActiveFilters}
                scope={window.emptyArray}
              />
            </ReportDrillDownsProvider>
          </FormInputItem>
          {isEditing && (
            <FormInputItem>
              <Typography.Body type="Label">Is Enabled</Typography.Body>
              <Inputs.Toggle
                value={isEnabled}
                onChange={setIsEnabled}
                isDisabled={isReadOnly}
                testId={`toggle-is-enabled`}
              />
            </FormInputItem>
          )}
        </>
      )}
      <FormInputItem>
        <AccessInput access={access} setAccess={setAccess} />
      </FormInputItem>
    </FormContent>
    <Row spaceBetween centerAlign>
      <Button onClick={close} label="Cancel" type="Tertiary" size="Small" />
      <Button
        type="Primary"
        size="Small"
        label={isEditing ? 'Update' : 'Create'}
        onClick={isReadOnly ? window.tokenFunction : onSave}
        isLoading={isLoading}
        isDisabled={!isValid}
      />
    </Row>
  </Form>
);

export default EntityForm;
