import { useContext, useEffect, useState } from 'react';
import DriverScoreContext from '../contexts/DriverScoreContext';

const enrichExpectation = (
  rawExpectation: DriverScoreRawExpectation,
  scoreDocument: ElasticDocument,
): DriverScoreExpectation => {
  return {
    category: rawExpectation.category,
    label: rawExpectation.label,
    description: rawExpectation.description,
    actualValue: scoreDocument[rawExpectation.actualValueField] as any as
      | number
      | null,
    expectedValue: scoreDocument[
      rawExpectation.expectedValueField
    ] as any as number,
    passes: rawExpectation.passes,
  };
};

export const buildDriverScoreExpectations = (
  category: string,
  driver: string,
  bonusPeriod: BonusPeriod,
  driverScore: ElasticDocument,
): DriverScoreExpectation[] => {
  if (!driverScore.expectations) {
    return [];
  }

  const rawExpectations =
    driverScore.expectations as any as DriverScoreRawExpectation[];
  return rawExpectations
    .filter((rawExpectation) => rawExpectation.category === category)
    .map((rawExpectation) => enrichExpectation(rawExpectation, driverScore));
};

const useDriverBonusFeedback = (
  category: string,
  driver: string,
  bonusPeriod?: BonusPeriod,
) => {
  const [isCategoryPassed, setIsCategoryPassed] = useState<boolean>(false);
  const [data, setData] = useState<DriverScoreExpectation[]>([]);
  const { driverScore } = useContext(DriverScoreContext);

  useEffect(() => {
    if (!driverScore.expectations || !bonusPeriod) {
      return;
    }

    setData(
      buildDriverScoreExpectations(category, driver, bonusPeriod, driverScore),
    );
    setIsCategoryPassed(driverScore[category] as any as boolean);
  }, [bonusPeriod, driver, category, driverScore]);

  return {
    data,
    isCategoryPassed,
  };
};

export default useDriverBonusFeedback;
