import React, { useCallback, useEffect, useState } from 'react';
import FieldPickerPopup from './FieldPickerPopup';
import useSelectedFields from './useSelectedFields';
import useDataTypeOptions from './useDataTypeOptions';
import useFieldOptions from './useFieldOptions';

const FieldPickerPopupContainer = ({
  initialFields,
  onFieldsConfirmed,
  close,
  datasetsGreenList,
  fieldRedList,
  fieldTypeRedList,
  isPerformanceDatasetsAllowed,
  isSaving,
}: {
  initialFields: {
    dataType: string;
    fields: string[];
  }[];
  onFieldsConfirmed: (
    confirmedFields: { dataType: string; fields: string[] }[],
  ) => void;
  close: () => void;
  datasetsGreenList?: string[];
  fieldRedList?: string[];
  fieldTypeRedList?: FleetOps.FieldType[];
  isPerformanceDatasetsAllowed?: boolean;
  isSaving?: boolean;
}) => {
  const [isConfirmEnabled, setIsConfirmEnabled] = useState<boolean>(false);
  const { selectedFields, selectedFieldsCount, onFieldAdded, onFieldRemoved } =
    useSelectedFields(initialFields);
  const { selectedDataType, dataTypeOptions, allowedDataTypes } =
    useDataTypeOptions(datasetsGreenList, isPerformanceDatasetsAllowed);
  const { searchText, onSearchTextChanged, fieldOptions } = useFieldOptions({
    allowedDataTypes,
    selectedDataType,
    selectedFields,
    fieldRedList,
    fieldTypeRedList,
  });
  const onConfirmClicked = useCallback(() => {
    onFieldsConfirmed(selectedFields);
    close();
  }, [close, onFieldsConfirmed, selectedFields]);

  // setIsConfirmEnabled
  useEffect(() => {
    setIsConfirmEnabled(
      initialFields.length > 0 ||
        (initialFields.length === 0 && selectedFieldsCount > 0),
    );
  }, [initialFields.length, selectedFieldsCount]);

  const onCancelClicked = useCallback(() => {
    close();
  }, [close]);

  return (
    <FieldPickerPopup
      selectedFieldsCount={selectedFieldsCount}
      onFieldAdded={onFieldAdded}
      onFieldRemoved={onFieldRemoved}
      dataTypeOptions={dataTypeOptions}
      searchText={searchText}
      onSearchTextChanged={onSearchTextChanged}
      fieldOptions={fieldOptions}
      isConfirmEnabled={isConfirmEnabled}
      onConfirmClicked={onConfirmClicked}
      onCancelClicked={onCancelClicked}
      isSaving={isSaving}
    />
  );
};

export default FieldPickerPopupContainer;
