import { useEffect, useState } from 'react';
import { useGetTargetBands } from './useGetTargetBands';

const useTargetBands = (
  kpi: Scorecards.ScorecardKpi | Scorecards.ManualKpiRow,
  period: Period,
) => {
  const [targetBandsForPeriod, setTargetBandsForPeriod] = useState<
    number[] | undefined
  >();
  const [labelBandsForPeriod, setLabelBandsForPeriod] = useState<
    string[] | undefined
  >();
  const [successThreshold, setSuccessThreshold] = useState<
    number | undefined
  >();
  const [isDynamic, setIsDynamic] = useState<boolean>(false);
  const getTargetBands = useGetTargetBands(kpi);

  useEffect(() => {
    const newTargetBands = getTargetBands(period);
    setTargetBandsForPeriod(newTargetBands.targetBandsForPeriod);
    setLabelBandsForPeriod(newTargetBands.labelBandsForPeriod);
    setSuccessThreshold(newTargetBands.successThreshold);
    setIsDynamic(newTargetBands.isDynamic);
  }, [getTargetBands, period]);

  return {
    isDynamic,
    targetBandsForPeriod,
    labelBandsForPeriod,
    successThreshold,
  };
};

export default useTargetBands;
