import React from 'react';
import * as Highchart from 'highcharts';
import chartColors from '../../theme/chartColors';
import * as _ from 'lodash';
import termToRangeDrill from './termToRangeDrill';
import deselectedColor from './deselectedColor';
import { HighchartsReactRefObject } from 'highcharts-react-official';
import drillDownTypeCheckers from '../../components/ReportDrillDownForm/drilldownTypeCheckers';

const buildSyncKeywordChart = ({
  isLastDrillForField,
  drillDowns,
  isRangeChart,
  chartDef,
  chartRef,
  chartType,
}: {
  isLastDrillForField: boolean;
  drillDowns: ReportDrillDownType[];
  isRangeChart?: boolean;
  chartDef?: V5ChartDefinition;
  chartRef?: React.MutableRefObject<HighchartsReactRefObject | null>;
  chartType?: string;
}) => {
  return (drillDown?: ReportDrillDownType) => {
    if (!chartRef || !chartRef.current || !chartRef.current.chart) {
      return;
    }
    const lastDrill = drillDowns[drillDowns.length - 1];

    // Do not allow anything to be in the light gray 'selected' state
    if (chartRef && chartRef.current) {
      const c = chartRef.current.chart;
      c.getSelectedSeries().forEach((s) => {
        s.select(false);
      });

      c.getSelectedPoints().forEach((s) => {
        s.select(false);
      });
    }

    // Reset colors if this chart shouldn't be highlighted
    if (!isLastDrillForField || !(drillDown || lastDrill)) {
      if ((chartRef.current.chart as Highchart.Chart).series.length === 0) {
        return;
      }
      const chart = chartRef.current.chart;
      chart.series.forEach((s) => {
        s.data.forEach((d) => {
          if (chartType === 'treeMap' || chartType === 'pie') {
            // @ts-ignore
            d.update({ color: chartColors[d.index] }, false);
          } else {
            d.update(
              // @ts-ignore
              { color: chartColors[s.userOptions.colorIndex || 0] },
              false,
            );
          }
        });
      });
      chart.redraw();
      return;
    }

    // Highlight the selected colors
    if (chartRef.current) {
      const chart = chartRef.current.chart;

      chart.series.forEach((s) => {
        s.data.forEach((d) => {
          const colorIndex = (() => {
            if (chartType === 'treeMap' || chartType === 'pie') {
              // @ts-ignore
              return d.index;
            }
            // @ts-ignore
            return s.userOptions.colorIndex || 0;
          })();
          const isSelected = (() => {
            const currentKeywordValues = (() => {
              if (drillDown) {
                if (drillDownTypeCheckers.isTextDrillDown(drillDown)) {
                  return drillDown.keywordValues;
                }
                return [];
              }

              if (
                lastDrill &&
                drillDownTypeCheckers.isTextDrillDown(lastDrill)
              ) {
                return lastDrill.keywordValues;
              }

              return [];
            })();

            if (chartType === 'treeMap' || chartType === 'pie') {
              if (
                d.name.toString() === '(Blank)' &&
                drillDownTypeCheckers.isTextDrillDown(lastDrill) &&
                lastDrill.keywordsExists === false
              ) {
                return true;
              }
              return currentKeywordValues.includes(d.name.toString());
            }

            if (chartType === 'stackedBar') {
              return currentKeywordValues.includes(d.series.name);
            }

            try {
              if (isRangeChart) {
                const currentRangeFilter = (() => {
                  if (drillDown) {
                    if (drillDownTypeCheckers.isNumberDrillDown(drillDown)) {
                      return drillDown.rangeValue;
                    }
                    return undefined;
                  }

                  if (drillDownTypeCheckers.isNumberDrillDown(lastDrill)) {
                    return lastDrill.rangeValue;
                  }

                  return undefined;
                })();

                return _.isEqual(
                  currentRangeFilter,
                  termToRangeDrill(
                    (d.category || d.name).toString(),
                    chartDef as V5ChartDefinition,
                  ),
                );
              } else {
                return currentKeywordValues.includes(
                  (d.category || d.name).toString(),
                );
              }
            } catch (ex) {
              return '';
            }
          })();

          // Bottleneck
          d.update(
            {
              color: isSelected
                ? chartColors[colorIndex || 0]
                : deselectedColor,
            },
            false,
          );
        });
      });
      chart.redraw();
    }
  };
};

export default buildSyncKeywordChart;
