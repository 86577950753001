import { useContext, useEffect, useState } from 'react';
import CostsWizardContext from '../../context/CostsWizardContext';
import { INTERVAL_MAP } from '../../consts';
import AccountContext from 'contexts/AccountContext';

const useIntervalOptions = () => {
  const { wizardState, setWizardState } = useContext(CostsWizardContext);
  const { weekStartsOn } = useContext(AccountContext);
  const [options, setOptions] = useState<DropdownOption[]>([]);

  useEffect(() => {
    const intervalModes = ['Monthly', 'Weekly'];

    const newOptions = intervalModes.map((mode) => {
      const interval = INTERVAL_MAP[mode as 'Monthly' | 'Weekly'];
      return {
        label: mode,
        value: interval,
        isSelected: wizardState.config.basicStep.interval === interval,
        onSelected: () => {
          setWizardState({
            ...wizardState,
            config: {
              ...wizardState.config,
              basicStep: {
                ...wizardState.config.basicStep,
                interval,
                startDate: undefined,
                startOfWeek: interval === 'week' ? weekStartsOn : undefined,
              },
            },
          });
        },
      };
    });

    setOptions(newOptions);
  }, [setWizardState, weekStartsOn, wizardState]);

  return options;
};

export default useIntervalOptions;
