import React from 'react';
import useVisualisationUsageInfo from '../../hooks/useGadgetUsageInfo';
import usePopup from 'hooks/usePopup';
import InlineDialog from 'components/InlineDialog';
import Typography from 'kingpin/atoms/Typography';
import VisualisationUsageDetails from './VisualisationUsageDetails';

const VisualisationUsageDetailsContainer = ({
  chatDefinition,
}: {
  chatDefinition: VisualisationDefinition;
}) => {
  const { reports } = useVisualisationUsageInfo(chatDefinition);

  const { isOpen, open, close } = usePopup();

  return (
    <InlineDialog
      isOpen={isOpen}
      onClose={close}
      content={<VisualisationUsageDetails reports={reports} />}
    >
      <Typography.Body type="Link" onClick={isOpen ? close : open}>
        Usage
      </Typography.Body>
    </InlineDialog>
  );
};

export default VisualisationUsageDetailsContainer;
