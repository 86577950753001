import { useCallback, useContext, useEffect } from 'react';
import _ from 'lodash';
import TargetFormContext from '../contexts/TargetFormContext';
import useGetInitialGroups from './useGetInitialGroups';
import dataTypeTargetTransforms from '../dataTypeTargetTransforms';
import TargetListContext from '../contexts/TargetListContext';

const isTargetGroupsEqual = (
  a: Targets.Wizard.TargetGroup[],
  b: Targets.Wizard.TargetGroup[],
) => {
  // We have a bunch of nested keys that need scrubbing to give a good
  // equality check
  const aT: Targets.Wizard.DataTypeTarget = {
    id: '',
    dataType: '',
    target: '',
    effectiveDate: '',
    groupField: '',
    groups: a,
    updatedOn: '',
    updatedBy: '',
    isBelowTargetDesirable: false,
    isFullPeriod: false,
    outputFields: [],
  };
  const bT: Targets.Wizard.DataTypeTarget = {
    ...aT,
    groups: b,
    isBelowTargetDesirable: false,
    isFullPeriod: false,
    outputFields: [],
  };

  const persistedAT = dataTypeTargetTransforms.toPersisted(aT);
  const persistedBT = dataTypeTargetTransforms.toPersisted(bT);

  return _.isEqual(persistedAT, persistedBT);
};

const useManageHasUnsavedChanges = () => {
  const {
    editingTarget,
    isEditing,
    groups,
    effectiveDate,
    isTargetedByGroup,
    isBelowTargetDesirable,
  } = useContext(TargetFormContext);
  const { setIsAddTargetPrimary, setHasUnsavedChanges, hasUnsavedChanges } =
    useContext(TargetListContext);
  const getInitialGroups = useGetInitialGroups();

  const getHasUnsavedChangesWhileEditing = useCallback(() => {
    if (!editingTarget) {
      return false;
    }

    return (
      !isTargetGroupsEqual(groups, editingTarget.groups) ||
      isBelowTargetDesirable !== editingTarget.isBelowTargetDesirable
    );
  }, [editingTarget, groups, isBelowTargetDesirable]);

  const hasDefaultGroupChanged = useCallback(() => {
    const initialGroups = getInitialGroups(isTargetedByGroup);
    return !isTargetGroupsEqual(initialGroups, groups);
  }, [getInitialGroups, groups, isTargetedByGroup]);

  const getHasUnsavedChangesCreating = useCallback(() => {
    return hasDefaultGroupChanged() || effectiveDate !== undefined;
  }, [effectiveDate, hasDefaultGroupChanged]);

  const getHasUnsavedChanges = useCallback(() => {
    if (isEditing) {
      return getHasUnsavedChangesWhileEditing();
    }
    return getHasUnsavedChangesCreating();
  }, [
    getHasUnsavedChangesCreating,
    getHasUnsavedChangesWhileEditing,
    isEditing,
  ]);
  useEffect(() => {
    setHasUnsavedChanges(getHasUnsavedChanges());
  }, [getHasUnsavedChanges, setHasUnsavedChanges]);

  useEffect(() => {
    setIsAddTargetPrimary(!hasUnsavedChanges);
  }, [hasUnsavedChanges, setIsAddTargetPrimary, setHasUnsavedChanges]);
};

export default useManageHasUnsavedChanges;
