import React from 'react';
import styled from 'styled-components';
import CopyRulesFieldPicker from './CopyRulesFieldPicker';
import ChevronRightSrc from '../../images/chevron-right.png';
import usePopup from '../../../../hooks/usePopup';
import InlineDialog from '../../../InlineDialog';
import Typography from 'kingpin/atoms/Typography';
import { ListItem } from '../../../Inputs/Dropdown';
import Row from '../../../Common/Row';

const Icon = styled.img``;

const CopyRulesToField = ({
  target,
  closeParent,
  lockParent,
  unlockParent,
  isCloseDisabled,
}: {
  target: Targets.Wizard.DataTypeTarget;
  closeParent: () => void;
  lockParent: () => void;
  unlockParent: () => void;
  isCloseDisabled: boolean;
}) => {
  const { isOpen, open, close } = usePopup();

  return (
    <InlineDialog
      placement="right-start"
      isOpen={isOpen}
      onClose={isCloseDisabled ? undefined : close}
      content={
        <CopyRulesFieldPicker
          close={close}
          target={target}
          closeParent={closeParent}
          lockParent={lockParent}
          unlockParent={unlockParent}
        />
      }
    >
      <ListItem onClick={open}>
        <Row centerAlign spaceBetween style={{ width: '100%' }}>
          <Typography.Body type="Body 12">Copy Rules to Field</Typography.Body>
          {!isOpen && <Icon src={ChevronRightSrc} />}
        </Row>
      </ListItem>
    </InlineDialog>
  );
};

export default CopyRulesToField;
