import { gql } from '@apollo/client';
import React, { ChangeEvent, useCallback, useContext, useState } from 'react';

import DriverScoreContext from '../../../../contexts/DriverScoreContext';
import CreateOverrideButton from './CreateOverrideButton';
import usePopup from '../../../../hooks/usePopup';
import CurrentUserContext from '../../../../contexts/CurrentUserContext';
import toSentenceCase from '../../../../services/toSentenceCase';
import BonusPeriodsContext from '../../../../contexts/BonusPeriodsContext';
import AnalyticsContext from '../../../../contexts/AnalyticsContext';
import RemoveOverrideButton from './RemoveOverrideButton';
import useOverride from '../../../../hooks/useOverride';
import BoardSlideOutContext from '../../../../contexts/BoardSlideOutContext';
import BoardContext from '../../../../contexts/BoardContext';
import isDriverPerformanceBoard from '../../../../isDriverPerformanceBoard';
import WorkSpaceContext from '../../../../contexts/WorkSpaceContext';
import GqlClientContext from '../../../../contexts/GqlClientContext';

const CategoryOverrideButtonContainer = ({
  category,
}: {
  category: string;
}) => {
  const { client } = useContext(GqlClientContext);
  const { setGridRequiresRefresh } = useContext(BoardSlideOutContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const { selectedBonusPeriod } = useContext(BonusPeriodsContext);
  const { driverScore, setDriverScore } = useContext(DriverScoreContext);
  const { id: currentUserId, isFleetOpsStaff } = useContext(CurrentUserContext);
  const { board } = useContext(BoardContext);
  const { driverBonusWorkSpace: workSpace } = useContext(WorkSpaceContext);
  const { displayName } = useContext(CurrentUserContext);
  const { isOpen, open, close } = usePopup();
  const override = useOverride(category);
  const [note, setNote] = useState<string>('');
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const onNoteChanged = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setNote(event.target.value);
  };

  const onClose = useCallback(() => {
    setNote('');
    close();
  }, [close]);

  const onCreated = useCallback(() => {
    if (!selectedBonusPeriod || note === '') {
      return;
    }
    const mutation = {
      mutation: gql`
        mutation overrideScoringCategory(
          $scoringPeriodId: String!
          $scoringCategory: ScoringCategory!
          $id: String!
          $override: Boolean!
          $comment: String!
        ) {
          overrideScoringCategory(
            scoringPeriodId: $scoringPeriodId
            scoringCategory: $scoringCategory
            id: $id
            override: $override
            comment: $comment
          )
        }
      `,
      variables: {
        scoringPeriodId: selectedBonusPeriod.id,
        scoringCategory: category,
        id: driverScore.id,
        override: !driverScore[category],
        comment: note,
      },
    };
    setIsSaving(true);
    client.mutate(mutation).then((response) => {
      trackEvent('Driver Bonus - Category Overridden', {
        category,
        bonusPeriodId: selectedBonusPeriod.id,
        bonusPeriodLabel: selectedBonusPeriod.label,
        bonusPeriodStatus: selectedBonusPeriod.status,
      });
      setDriverScore(response.data.overrideScoringCategory);
      setIsSaving(false);
      onClose();
      setGridRequiresRefresh(true);
    });
  }, [
    selectedBonusPeriod,
    note,
    category,
    driverScore,
    client,
    trackEvent,
    setDriverScore,
    onClose,
    setGridRequiresRefresh,
  ]);

  const onRemoveClicked = useCallback(() => {
    if (!selectedBonusPeriod) {
      return;
    }
    const mutation = {
      mutation: gql`
        mutation removeScoringCategoryOverride(
          $scoringPeriodId: String!
          $scoringCategory: ScoringCategory!
          $id: String!
        ) {
          removeScoringCategoryOverride(
            scoringPeriodId: $scoringPeriodId
            scoringCategory: $scoringCategory
            id: $id
          )
        }
      `,
      variables: {
        scoringPeriodId: selectedBonusPeriod.id,
        scoringCategory: category,
        id: driverScore.id,
      },
    };
    setIsSaving(true);
    client.mutate(mutation).then((response) => {
      trackEvent('Driver Bonus - Category Override Removed', {
        category,
        bonusPeriodId: selectedBonusPeriod.id,
        bonusPeriodLabel: selectedBonusPeriod.label,
        bonusPeriodStatus: selectedBonusPeriod.status,
      });
      setDriverScore(response.data.removeScoringCategoryOverride);
      setIsSaving(false);
      setGridRequiresRefresh(true);
    });
  }, [
    category,
    client,
    driverScore.id,
    selectedBonusPeriod,
    setDriverScore,
    setGridRequiresRefresh,
    trackEvent,
  ]);

  if (
    !selectedBonusPeriod ||
    !['in review', 'closed', 'pending sign off'].includes(
      selectedBonusPeriod.status,
    )
  ) {
    return null;
  }

  if (
    isDriverPerformanceBoard(board) &&
    workSpace &&
    (workSpace.ownerId === currentUserId ||
      board.assignedToId.includes(currentUserId) ||
      isFleetOpsStaff)
  ) {
    if (override) {
      return (
        <RemoveOverrideButton
          onRemoveClicked={onRemoveClicked}
          isSaving={isSaving}
        />
      );
    } else {
      return (
        <CreateOverrideButton
          currentUserName={displayName}
          category={toSentenceCase(category)}
          driver={driverScore.driver as string}
          isOpen={isOpen}
          open={open}
          onClose={onClose}
          note={note}
          onNoteChanged={onNoteChanged}
          onCreated={onCreated}
          isSaving={isSaving}
          isClosed={selectedBonusPeriod.status === 'closed'}
          isInReview={selectedBonusPeriod.status === 'in review'}
          isPendingSignOff={selectedBonusPeriod.status === 'pending sign off'}
        />
      );
    }
  } else {
    return null;
  }
};

export default CategoryOverrideButtonContainer;
