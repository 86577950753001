import { ApisauceInstance, ApiResponse } from 'apisauce';

const getGroupMemberships = (
  api: ApisauceInstance,
  groupId: string,
): Promise<ApiResponse<{ users: MicrosoftSso.User[] }>> =>
  api.get<{ users: MicrosoftSso.User[] }>(
    `microsoftSso/group_memberships/${groupId}`,
  );

export default getGroupMemberships;
