import React from 'react';
import styled from 'styled-components';
import Typography from 'kingpin/atoms/Typography';
import UserWithAccess from './UserWithAccess';

const Grid = styled.div`
  border: 1px solid #e0e0e0;
`;

const UsersWithAccess = ({
  type,
  typeId,
  sharedWith,
  contentName,
  refreshSharedWith,
}: {
  type: 'report' | 'dashboard' | 'scorecard' | 'workSpace';
  typeId: string;
  sharedWith: (UserManagement.PendingUser | UserManagement.SignedUpUser)[];
  contentName: string;
  refreshSharedWith: () => void;
}) => {
  if (sharedWith.length === 0) {
    return null;
  }

  return (
    <div>
      <div style={{ marginBottom: 8 }}>
        <Typography.Body type={'Label'} color={'#333333'}>
          Already Shared With
        </Typography.Body>
      </div>
      <Grid>
        {sharedWith.map((user) => (
          <UserWithAccess
            type={type}
            typeId={typeId}
            user={user}
            contentName={contentName}
            key={user.id}
            refreshSharedWith={refreshSharedWith}
          />
        ))}
      </Grid>
    </div>
  );
};

export default UsersWithAccess;
