import isV5ChartDef from '../../types/visTypeCheckers/isV5ChartDef';
import aguid from 'aguid';
import { toFilterPlate } from '../../migrateScope';
import drillDownTypeCheckers from '../../components/ReportDrillDownForm/drilldownTypeCheckers';

const buildOnKeywordDrillDown = ({
  chartDef,
  drillDowns,
  field,
  setDrillDowns,
  isLastDrillForField,
  maybeOpenPopupReport,
  track,
}: {
  chartDef?: V5ChartDefinition;
  drillDowns: ReportDrillDownType[];
  field: string;
  setDrillDowns: (drills: FilterPlate[]) => void;
  isLastDrillForField: boolean;
  maybeOpenPopupReport?: (drillDown?: ReportDrillDownType) => void;
  track: () => void;
}) => {
  return (term: string, ctrlKey?: boolean) => {
    track();
    if (
      field === 'dayOfWeek' ||
      (isV5ChartDef(chartDef) &&
        chartDef.dimensionA &&
        chartDef.dimensionA.rangeInput)
    ) {
      alert('Coming soon...');
      return;
    }

    const newDrill: ReportDrillDownType = {
      id: aguid(),
      field,
      fieldType: 'text',
      mode: 'editing' as 'editing',
      keywordValues: [],
      appliedByInteractionWithChartDefId: chartDef ? chartDef.id : undefined,
    };
    if (term === '(Blank)') {
      newDrill.keywordsExists = false;
    } else {
      newDrill.keywordValues = [term];
    }

    if (maybeOpenPopupReport) {
      maybeOpenPopupReport(newDrill);
      return;
    }

    if (isLastDrillForField) {
      const currentDrill = drillDowns[drillDowns.length - 1];
      const { currentKeywordValues, currentKeywordsExists } = (() => {
        if (drillDownTypeCheckers.isTextDrillDown(currentDrill)) {
          return {
            currentKeywordValues: currentDrill.keywordValues,
            currentKeywordsExists: currentDrill.keywordsExists,
          };
        }
        return {
          currentKeywordValues: [],
          currentKeywordsExists: undefined,
        };
      })();

      const isDeselectingTerm = currentKeywordValues.includes(term);
      const isDeselectingBlanks =
        term === '(Blank)' && currentKeywordsExists === false;

      const updatedDrill = (() => {
        if (isDeselectingTerm) {
          return {
            ...currentDrill,
            keywordValues: currentKeywordValues.filter((t) => t !== term),
            keywordsExists: ctrlKey ? currentKeywordsExists : undefined,
          };
        } else if (isDeselectingBlanks) {
          return {
            ...currentDrill,
            keywordsExists: undefined,
            keywordValues: ctrlKey ? currentKeywordValues : [],
          };
        } else {
          return {
            ...currentDrill,
            keywordValues: ctrlKey ? [...currentKeywordValues, term] : [term],
            keywordsExists: ctrlKey ? currentKeywordsExists : undefined,
          };
        }
      })();
      const isDrillRemoved =
        updatedDrill.keywordValues &&
        updatedDrill.keywordValues.length === 0 &&
        updatedDrill.keywordsExists === undefined;
      if (isDrillRemoved) {
        setDrillDowns(
          drillDowns.filter((d) => d.id !== currentDrill.id).map(toFilterPlate),
        );
      } else {
        setDrillDowns(
          drillDowns
            .map((d) => {
              if (d.id === currentDrill.id) {
                return updatedDrill;
              }
              return d;
            })
            .map(toFilterPlate),
        );
      }
    } else {
      setDrillDowns([...drillDowns, newDrill].map(toFilterPlate));
    }
  };
};

export default buildOnKeywordDrillDown;
