import Colors from '../../../../theme/colors';

export const isGood = (isAboveTarget: boolean, aboveTargetIsGood: boolean) => {
  if (isAboveTarget) {
    if (aboveTargetIsGood) {
      return true;
    } else {
      return false;
    }
  } else {
    if (aboveTargetIsGood) {
      return false;
    } else {
      return true;
    }
  }
};

const getColor = (isAboveTarget: boolean, aboveTargetIsGood: boolean) => {
  if (isGood(isAboveTarget, aboveTargetIsGood)) {
    return Colors.MEDIUM_SEA_GREEN;
  } else {
    return Colors.RADICAL_RED;
  }
};

export default getColor;
