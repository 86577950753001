import { useContext, useEffect, useState } from 'react';
import isDefined from '../../../isDefined';
import GqlClientContext from '../../../contexts/GqlClientContext';
import AccountPickerContext from '../../../contexts/AccountPickerContext';
import getFilterValues from '../../../api/getFilterValues';

export const useGroupNameValues = ({
  dataType,
  groupField,
  mustExistFilters,
}: {
  groupField?: string;
  dataType: string;
  mustExistFilters?: string[];
}) => {
  const { client } = useContext(GqlClientContext);
  const { selectedAccount } = useContext(AccountPickerContext);
  const [values, setValues] = useState<string[]>([]);

  useEffect(() => {
    if (groupField === undefined || dataType === undefined) {
      return;
    }
    let isActive = true;

    const filterInput = {
      dataType: [dataType],
      mustExistFilters,
    };

    getFilterValues({
      field: groupField,
      filterInput,
      dateScope: {},
      accountId: selectedAccount.accountId,
      client,
    }).then((values) => {
      if (!isActive) {
        return;
      }
      setValues(values);
    });

    return () => {
      isActive = false;
    };
  }, [
    client,
    dataType,
    groupField,
    mustExistFilters,
    selectedAccount.accountId,
  ]);

  return values;
};

const useIsDuplicateRuleAvailable = ({
  groups,
  dataType,
  groupField,
}: {
  groupField?: string;
  dataType: string;
  groups: Targets.Wizard.TargetGroup[];
}) => {
  const [isDuplicateRuleAvailable, setIsDuplicateRuleAvailable] =
    useState<boolean>(false);
  const groupNameValues = useGroupNameValues({ groupField, dataType });

  useEffect(() => {
    const usedGroupNames = groups
      .map((g) => g.groupName)
      .filter(isDefined)
      .reduce((a, b) => {
        return [...a, ...b];
      }, [] as string[]);

    setIsDuplicateRuleAvailable(
      groupNameValues.some((term) => !usedGroupNames.includes(term)),
    );
  }, [groupNameValues, groups]);

  return { isDuplicateRuleAvailable, groupNameValues };
};

export default useIsDuplicateRuleAvailable;
