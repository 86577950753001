import { useCallback, useContext } from 'react';
import _ from 'lodash';
import GoalsContext from 'contexts/GoalsContext';
import useGetIsUsedInMetricId from './useGetIsUsedInMetricId';
import filterPlateTypeCheckers from 'types/filterPlateTypeCheckers';

const useGetGoalUsage = () => {
  const { goals } = useContext(GoalsContext);
  const getIsUsedInMetricId = useGetIsUsedInMetricId();

  const getGoalUsage = useCallback(
    (filter: DatasetFilter): GeneralGoal[] => {
      const usage: GeneralGoal[] = [];

      goals.forEach((g) => {
        const isUsedInMetric = getIsUsedInMetricId({
          filter,
          metricId: g.metricId,
        });

        const isUsedInKpisMetric = g.kpis.some((k) =>
          getIsUsedInMetricId({ filter, metricId: k.metricId }),
        );

        const isUsedInDrillDowns = g.drillDowns.find(
          (plate) =>
            filterPlateTypeCheckers.isDataset(plate) &&
            plate.datasetFilterId === filter.id,
        );

        const isUsedInKpisDrillDowns = g.kpis.find((kpi) =>
          kpi.drillDowns.find(
            (plate) =>
              filterPlateTypeCheckers.isDataset(plate) &&
              plate.datasetFilterId === filter.id,
          ),
        );

        if (
          isUsedInMetric ||
          isUsedInKpisMetric ||
          isUsedInDrillDowns ||
          isUsedInKpisDrillDowns
        ) {
          usage.push(g);
        }
      });

      return _.uniqBy(usage, 'id');
    },
    [getIsUsedInMetricId, goals],
  );

  return getGoalUsage;
};

export default useGetGoalUsage;
