import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import ScorecardContext from 'contexts/ScorecardContext';
import { Y_SCROLL_BAR_WIDTH } from '../../../../global-styles';
import NavSideBarContext from '../../../../contexts/NavSideBarContext';
const BASE_RIGHT_SHADOW_RIGHT = -10;

const useShadows = () => {
  const { isLoading } = useContext(ScorecardContext);
  const { isOpen } = useContext(NavSideBarContext);

  const [isShadowLeftVisible, setIsShadowLeftVisible] =
    useState<boolean>(false);
  const [isShadowRightVisible, setIsShadowRightVisible] =
    useState<boolean>(false);
  const [width, setWidth] = useState<number | undefined>(undefined);
  const [height, setHeight] = useState<number>(0);
  const [rightShadowRightPos, setRightShadowRightPos] = useState<number>(
    BASE_RIGHT_SHADOW_RIGHT,
  );
  const kpisScrollerRef = useRef<HTMLDivElement>(null);
  const gridRef = useRef<HTMLDivElement | null>(null);
  const [isGridRefSet, setIsGridRefSet] = useState<boolean>(false);

  const handleScrolling = useCallback(() => {
    const kpiScrollerElem = kpisScrollerRef.current;
    setIsShadowLeftVisible(
      !!kpiScrollerElem && kpiScrollerElem.scrollLeft !== 0,
    );

    if (!kpiScrollerElem) {
      setIsShadowRightVisible(false);
      return;
    }

    const isScrollBarYThere =
      kpiScrollerElem.clientHeight !== kpiScrollerElem.scrollHeight;
    if (isScrollBarYThere) {
      setIsShadowRightVisible(
        kpiScrollerElem.scrollLeft !==
          kpiScrollerElem.scrollWidth -
            kpiScrollerElem.offsetWidth +
            Y_SCROLL_BAR_WIDTH,
      );
      setRightShadowRightPos(BASE_RIGHT_SHADOW_RIGHT + Y_SCROLL_BAR_WIDTH);
    } else {
      setIsShadowRightVisible(
        kpiScrollerElem.scrollLeft !==
          kpiScrollerElem.scrollWidth - kpiScrollerElem.offsetWidth,
      );
      setRightShadowRightPos(BASE_RIGHT_SHADOW_RIGHT);
    }
  }, []);

  const handleResize = useCallback(() => {
    const kpiScrollerElem = kpisScrollerRef.current;
    if (!kpiScrollerElem || isLoading) {
      return;
    }
    setWidth(kpiScrollerElem.scrollWidth);

    kpiScrollerElem.scrollLeft = kpiScrollerElem.scrollWidth;
  }, [isLoading]);

  useEffect(() => {
    handleResize();
  }, [handleResize, isOpen]);

  useEffect(() => {
    const kpiScrollerElem = kpisScrollerRef.current;
    if (!kpiScrollerElem || isLoading) {
      return;
    }
    handleResize();
    kpiScrollerElem.addEventListener('scroll', handleScrolling);
    window.addEventListener('resize', handleResize);
    return () => {
      kpiScrollerElem.removeEventListener('scroll', handleScrolling);
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize, handleScrolling, isLoading]);

  const measuredRef = useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
      gridRef.current = node;
      setIsGridRefSet(true);
    }
  }, []);

  useEffect(() => {
    const node = gridRef.current;
    if (!node || process.env.NODE_ENV === 'test') {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      setHeight(node.getBoundingClientRect().height);
    });

    resizeObserver.observe(node);

    return () => {
      resizeObserver.disconnect();
    };
  }, [gridRef, isGridRefSet]);

  return {
    isShadowLeftVisible,
    isShadowRightVisible,
    rightShadowRightPos,
    height,
    width,
    kpisScrollerRef,
    gridRef: measuredRef,
  };
};

export default useShadows;
