import styled from 'styled-components';

const ColDiv = styled.div<{ isEnd?: boolean; flex?: number }>`
  flex: ${(props) => props.flex || 0.5};
  display: flex;
  justify-content: ${(props) => (props.isEnd ? 'flex-end' : 'flex-start')};
  margin-right: 8px;
  overflow: hidden;
`;

export default ColDiv;
