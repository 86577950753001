import React from 'react';
import ModalDialog, { ModalTransition } from '@atlaskit/modal-dialog';
import Button from 'kingpin/atoms/Button';
import NewCommitPopup from '../CustomerLaneCommitsBoard/NewCommitPopup';

const NewCommitButton = ({
  isOpen,
  open,
  close,
}: {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  headingText: string;
}) => (
  <div style={{ marginRight: 8 }}>
    <Button
      size="Small"
      type="Primary"
      onClick={isOpen ? close : open}
      label="Add Commitment"
    />
    <ModalTransition>
      {isOpen && (
        <ModalDialog
          onClose={close}
          shouldScrollInViewport={false}
          width={'98vw'}
          autoFocus={false}
        >
          <NewCommitPopup />
        </ModalDialog>
      )}
    </ModalTransition>
  </div>
);

export default NewCommitButton;
