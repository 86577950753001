const toPersistedPerformanceMetric = (
  metric: PerformanceConfiguration.Client.PerformanceMetric,
): PerformanceConfiguration.Persisted.PerformanceMetric => {
  return {
    dateField: metric.dateField,
    datePeriodLength: metric.datePeriodLength,
    datePeriodType: metric.datePeriodType,
    fieldName: metric.fieldName === '' ? undefined : metric.fieldName,
    metricId: metric.metricId,
  };
};

export default toPersistedPerformanceMetric;
