import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import ScorecardContext from 'contexts/ScorecardContext';
import LocalTimelineContext from 'contexts/Timeline/LocalTimelineContext';
import AnalyticsContext from 'contexts/AnalyticsContext';
import Typography from 'kingpin/atoms/Typography';
import Button from 'kingpin/atoms/Button';
import ContextMenu, { Option, Break } from 'kingpin/atoms/ContextMenu';
import Row from 'components/Common/Row';
import ShowOnMouseOverUnlessTour from '../../ShowOnMouseOverUnlessTour';
import { KPI_GROUP_ROW_HEIGHT } from './KpiRowRight/constants';
import PermissionGates from 'components/PermissionGates';
import { Col } from './Kpis';
import GroupRowForm from './GroupRowForm';
import PERMISSIONS from 'permissionDefinitions';
import usePopup from 'hooks/usePopup';

const Wrapper = styled.div`
  background-color: #f4f4f4;
  border-bottom: 1px solid #f0f0f0;

  .showOnMouseOver {
    visibility: hidden;
  }

  &:hover {
    .showOnMouseOver {
      visibility: visible;
      width: unset;
    }
  }
`;

const GroupingRowLeft = ({
  row,
  dragHandleProps,
}: {
  row: Scorecards.EmptyRow | undefined;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
}) => {
  const { scorecard, updateScorecard, setHoveredKpiId, hoveredKpiId } =
    useContext(ScorecardContext);
  const { addEvent } = useContext(LocalTimelineContext);
  const { trackEvent } = useContext(AnalyticsContext);

  const removeRow = useCallback(() => {
    if (!row || !scorecard) {
      return;
    }

    const newScorecard: Scorecards.Scorecard = {
      ...scorecard,
      kpis: scorecard.kpis.filter((kpi) => kpi !== row),
    };

    updateScorecard(newScorecard).then(async () => {
      if (addEvent) {
        await addEvent({
          actionText: 'removed Grouping row',
          contextText: row.label,
        });
      }
      trackEvent('KPI List - Group Row - Deleted');
    });
  }, [addEvent, row, scorecard, trackEvent, updateScorecard]);

  const onKpiMouseEnter = useCallback(() => {
    setHoveredKpiId(row ? row.id : undefined);
  }, [row, setHoveredKpiId]);

  const {
    open: openGroup,
    close: closeGroup,
    isOpen: isGroupOpen,
  } = usePopup();

  const handleOpenEditGroup = useCallback(() => {
    trackEvent('KPI List - Edit Group - Clicked');
    openGroup();
  }, [openGroup, trackEvent]);

  if (!row) {
    return null;
  }

  const isHovered = hoveredKpiId === row.id;

  return (
    <Wrapper onMouseEnter={onKpiMouseEnter}>
      <Col
        maxWidth
        style={{
          height: `${KPI_GROUP_ROW_HEIGHT - 1}px`,
        }}
      >
        <Row centerAlign spaceBetween style={{ width: '100%' }}>
          <Row centerAlign style={{ width: 'calc(100% - 24px)' }}>
            {!!dragHandleProps && (
              <ShowOnMouseOverUnlessTour>
                <div {...dragHandleProps}>
                  <Button icon={'drag'} type="Ghost" size="Small" />
                </div>
              </ShowOnMouseOverUnlessTour>
            )}
            <div
              style={{
                maxWidth: 'calc(100% - 16px)',
                marginLeft: dragHandleProps ? undefined : '8px',
                overflow: 'hidden',
              }}
            >
              <Typography.Body type="Label" isEllipsis>
                {row ? row.label : 'Group Row'}
              </Typography.Body>
            </div>
          </Row>

          <div style={{ marginRight: 4, width: '24px' }}>
            <PermissionGates.Has
              requiredPermission={PERMISSIONS.SCORECARDS.CRUD_SCORECARD}
            >
              <ShowOnMouseOverUnlessTour isForcedVisible={isHovered}>
                <ContextMenu
                  buttonType={'Ghost'}
                  testId={`group-edit-${row.label}`}
                >
                  <Option label={'Edit Group'} onClick={handleOpenEditGroup} />
                  <Break />
                  <Option label={'Delete'} onClick={removeRow} isDanger />
                </ContextMenu>
              </ShowOnMouseOverUnlessTour>
            </PermissionGates.Has>
          </div>
        </Row>
      </Col>
      <ModalTransition>
        {isGroupOpen && (
          <Modal shouldScrollInViewport={false} autoFocus={false}>
            <GroupRowForm close={closeGroup} editingRow={row} />
          </Modal>
        )}
      </ModalTransition>
    </Wrapper>
  );
};

export default GroupingRowLeft;
