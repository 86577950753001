import moment from 'moment';
import formatDateLabel from '../../components/V5Gadget/formatDateLabel';
import { useContext, useEffect, useState } from 'react';
import AccountContext from '../../contexts/AccountContext';

const getDateBuckets = ({
  startDate,
  endDate,
  interval,
  weekStartsOn,
}: {
  startDate: string;
  endDate: string;
  interval: FleetOps.Interval;
  weekStartsOn: WeekStartsOn;
}) => {
  if (interval === 'auto') {
    return [];
  } else if (interval === 'week') {
    const s = moment(startDate).startOf('year');
    const dates = [] as string[];
    while (s.format('YYYY-MM-DD') < endDate) {
      const buckets = getWeeks({
        currentYear: s.year(),
        weekStartsOn,
      });
      dates.push(
        ...buckets.filter((week) => week < endDate && week >= startDate),
      );
      s.add({ year: 1 });
    }
    return dates;
  } else {
    const dates = [] as string[];
    const e = moment(endDate).endOf(interval);
    const nIntervals = Math.abs(
      moment(startDate).startOf(interval).diff(e, interval),
    );

    for (let i = 0; i <= nIntervals; i++) {
      const nextDate = moment(startDate).startOf(interval).add(i, interval);
      dates.push(nextDate.format('YYYY-MM-DD'));
    }

    return dates;
  }
};

export const useGoalDateBuckets = (goal: GeneralGoal) => {
  const { weekStartsOn, isDemoAccount, demoAccountNow } =
    useContext(AccountContext);
  const [dateBuckets, setDateBuckets] = useState<string[]>([]);
  const [currentDateBucket, setCurrentDateBucket] = useState<string>(
    () => goal.fixedStartDate,
  );
  const [lastCompletedDateBucket, setLastCompletedDateBucket] =
    useState<string>(() => goal.fixedStartDate);

  useEffect(() => {
    const newDateBuckets = getDateBuckets({
      startDate: goal.fixedStartDate,
      endDate: goal.fixedEndDate,
      interval: goal.cadence,
      weekStartsOn,
    });

    setDateBuckets(newDateBuckets);
  }, [goal, weekStartsOn]);

  useEffect(() => {
    if (dateBuckets.length === 0) {
      return;
    }

    const today = moment().format('YYYY-MM-DD');
    const newLastBucket = isDemoAccount
      ? goal.cadence === 'month'
        ? '2021-11-01'
        : '2021-11-28'
      : (() => {
          let lastBucket = dateBuckets[0];
          dateBuckets.forEach((b) => {
            if (today > b) {
              lastBucket = b;
            }
          });
          return lastBucket;
        })();

    setLastCompletedDateBucket(newLastBucket);
  }, [dateBuckets, goal.cadence, isDemoAccount]);

  useEffect(() => {
    if (dateBuckets.length === 0) {
      return;
    }

    const today = moment().format('YYYY-MM-DD');
    const newCurrentBucket =
      isDemoAccount && demoAccountNow
        ? goal.cadence === 'month'
          ? moment(demoAccountNow).startOf('month').format('YYYY-MM-DD')
          : demoAccountNow
        : (() => {
            let lastBucket = dateBuckets[0];
            dateBuckets.forEach((b) => {
              if (today >= b) {
                lastBucket = b;
              }
            });
            return lastBucket;
          })();
    setCurrentDateBucket(newCurrentBucket);
  }, [dateBuckets, demoAccountNow, goal.cadence, isDemoAccount]);

  return {
    dateBuckets,
    currentDateBucket,
    lastCompletedDateBucket,
  };
};

const startOfWeekToDayName = (startOfWeek: WeekStartsOn): string => {
  switch (startOfWeek) {
    case 'SUN':
      return 'Sunday';
    case 'SAT':
      return 'Saturday';
    case 'FRI':
      return 'Friday';
    case 'THUR':
      return 'Thursday';
    case 'WED':
      return 'Wednesday';
    case 'TUE':
      return 'Tuesday';
    default:
    case 'MON':
      return 'Monday';
  }
};

export const findStartOfYear = (
  year: number,
  startOfWeek: WeekStartsOn,
): moment.Moment => {
  const dayName = startOfWeekToDayName(startOfWeek);
  const weekday = moment().day(dayName).weekday();

  const startOfYear = moment(`${year}-01-01`);
  while (startOfYear.weekday() !== weekday) {
    startOfYear.add(1, 'day');
  }
  return startOfYear;
};

const getWeeks = ({
  currentYear,
  weekStartsOn,
}: {
  currentYear: number;
  weekStartsOn: WeekStartsOn;
}) => {
  const weeks = [] as string[];
  const startOfYear = findStartOfYear(currentYear, weekStartsOn);
  while (startOfYear.year() === currentYear) {
    const isoDate = startOfYear.format('YYYY-MM-DD');
    weeks.push(isoDate);
    startOfYear.add({ week: 1 });
  }

  return weeks;
};

export const buildWeeksLayout = ({
  currentYear,
  weekStartsOn,
  interval,
  startDate,
  endDate,
}: {
  currentYear: number;
  weekStartsOn: WeekStartsOn;
  interval: FleetOps.Interval;
  startDate: string;
  endDate: string;
}) => {
  const weekIsoDates = getWeeks({ currentYear, weekStartsOn }).filter(
    (d) => d >= startDate && d < endDate,
  );
  const weeks = [] as { label: string; number: number; isoDate: string }[][];
  const numRows = Math.ceil(weekIsoDates.length / 2);
  for (let i = 0; i < numRows; i++) {
    weeks.push([]);
  }

  weekIsoDates.forEach((isoDate, index) => {
    const label = formatDateLabel(isoDate, interval, true);
    if (index < numRows) {
      weeks[index].push({
        isoDate,
        label,
        number: index,
      });
    } else {
      weeks[index % numRows].push({
        isoDate,
        label,
        number: index,
      });
    }
  });

  return weeks;
};

export default getDateBuckets;
