import moment from 'moment';
import { Link } from 'react-router-dom';
import Typography from 'kingpin/atoms/Typography';
import React, { useContext } from 'react';
import LocalTasksContext from '../../contexts/Tasks/LocalTasksContext';

const OverdueTasksLink = ({ link }: { link?: string }) => {
  const { tasks } = useContext(LocalTasksContext);
  const overdueTasks = tasks.filter(
    (t) => !t.isComplete && t.dueDate < moment().format('YYYY-MM-DD'),
  );

  if (overdueTasks.length === 0 || !link) {
    return null;
  } else {
    return (
      <Link to={link}>
        <Typography.Body type="Link">
          {`${overdueTasks.length} overdue task${
            overdueTasks.length > 1 ? 's' : ''
          }`}
        </Typography.Body>
      </Link>
    );
  }
};

export default OverdueTasksLink;
