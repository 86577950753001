import React from 'react';
import styled from 'styled-components';

import EmptyKpis from './EmptyKpis';
import Row from '../../Common/Row';
import {
  KPI_LEFT_COL_MAX_WIDTH,
  KPI_LEFT_COL_MIN_WIDTH,
  KPI_COL_WIDTH,
} from './constants';

import KpiRow from './KpiRowRight';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import Loading from '../../Loading';
import KpiRowLeft from './KpiRowLeft';
import ColumnHeadingsLeft from './ColumnHeadingsLeft';
import ColumnHeadingsRight from './ColumnHeadingsRight';
import GroupingRowLeft from './GroupingRowLeft';
import GroupingRowRight from './GroupingRowRight';
import { isEmptyRow, isKpiRow, isManualKpiRow } from 'hooks/kpiTypeCheckers';
import Typography from 'kingpin/atoms/Typography';
import AddKpiTypesButton from './AddKpiTypesButton';
import ManualKpiRowLeft from './ManualKpiRowLeft';
import ManualKpiRowRight from './ManualKpiRowRight/ManualKpiRowRight';
import SlideOut from './Slideout';
import { SLIDE_OUT_WIDTH } from './Slideout/Slideout';
import { Z_INDEX } from '../../../constants';

export const Col = styled.div<{
  alignRight?: boolean;
  minWidth?: boolean;
  maxWidth?: boolean;
}>`
  flex: 1;
  max-width: ${(props) =>
    props.maxWidth ? `${KPI_LEFT_COL_MAX_WIDTH}px` : undefined};
  min-width: ${(props) =>
    props.minWidth
      ? `${KPI_COL_WIDTH}px`
      : props.maxWidth
        ? `${KPI_LEFT_COL_MIN_WIDTH}px`
        : undefined};
  width: ${(props) =>
    props.minWidth || props.maxWidth ? undefined : `${KPI_COL_WIDTH}px`};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => (props.alignRight ? 'flex-end' : 'flex-start')};
`;

const KpisWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Wrapper = styled.div<{ isWorkspace?: boolean; isDashboard?: boolean }>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  overflow: hidden;
`;

const Left = styled.div<{ isSlideoutOpen?: boolean }>`
  width: ${(props) =>
    props.isSlideoutOpen ? `calc(100% - ${SLIDE_OUT_WIDTH}px)` : '100%'};
`;

const KpiListRightWrapper = styled.div`
  height: 100%;
`;

const KpiListLeftWrapper = styled.div<{ isShadowLeftVisible: boolean }>`
  position: sticky;
  right: 10px;
  box-shadow: ${(props) =>
    props.isShadowLeftVisible
      ? '8px 0px 10px rgba(0, 0, 0, 0.04)'
      : '1px 0px 0px rgba(0, 0, 0, 0.04)'};
`;

const InnerScrollableContent = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: auto;
  overscroll-behavior-x: contain;
`;

const DraggableKpiListLeft = ({
  kpis,
  onDragEnd,
  isShadowLeftVisible,
}: {
  kpis: (
    | Scorecards.ScorecardKpi
    | Scorecards.EmptyRow
    | Scorecards.ManualKpiRow
  )[];
  onDragEnd: (result: DropResult) => void;
  isShadowLeftVisible: boolean;
}) => {
  return (
    <>
      <KpiListLeftWrapper isShadowLeftVisible={isShadowLeftVisible}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={'kpis'}>
            {(provided) => (
              <div ref={provided.innerRef}>
                <ColumnHeadingsLeft />
                {kpis.map((kpi, index) => (
                  <Draggable draggableId={kpi.id} index={index} key={kpi.id}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        {isKpiRow(kpi) && (
                          <KpiRowLeft
                            kpi={kpi}
                            dragHandleProps={provided.dragHandleProps}
                          />
                        )}
                        {isEmptyRow(kpi) && (
                          <GroupingRowLeft
                            row={kpi}
                            dragHandleProps={provided.dragHandleProps}
                          />
                        )}
                        {isManualKpiRow(kpi) && (
                          <ManualKpiRowLeft
                            kpi={kpi}
                            dragHandleProps={provided.dragHandleProps}
                          />
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </KpiListLeftWrapper>
    </>
  );
};

const KpiListLeft = ({
  kpis,
  isShadowLeftVisible,
}: {
  kpis: (
    | Scorecards.ScorecardKpi
    | Scorecards.EmptyRow
    | Scorecards.ManualKpiRow
  )[];
  isShadowLeftVisible: boolean;
}) => {
  return (
    <KpiListLeftWrapper isShadowLeftVisible={isShadowLeftVisible}>
      <ColumnHeadingsLeft />
      {kpis.map((kpi) => (
        <div key={kpi.id}>
          {isKpiRow(kpi) && <KpiRowLeft kpi={kpi} key={kpi.id} />}

          {isEmptyRow(kpi) && <GroupingRowLeft row={kpi} />}
          {isManualKpiRow(kpi) && <ManualKpiRowLeft kpi={kpi} key={kpi.id} />}
        </div>
      ))}
    </KpiListLeftWrapper>
  );
};

const KpiListRight = ({
  kpis,
}: {
  kpis: (
    | Scorecards.ScorecardKpi
    | Scorecards.EmptyRow
    | Scorecards.ManualKpiRow
  )[];
}) => {
  return (
    <>
      <ColumnHeadingsRight />
      {kpis.map((kpi) => (
        <div key={kpi.id}>
          {isEmptyRow(kpi) && <GroupingRowRight key={kpi.id} kpiId={kpi.id} />}
          {isKpiRow(kpi) && <KpiRow kpi={kpi} key={kpi.id} />}
          {isManualKpiRow(kpi) && <ManualKpiRowRight kpi={kpi} key={kpi.id} />}
        </div>
      ))}
    </>
  );
};

const Kpis = ({
  isEmpty,
  kpis,
  onDragEnd,
  isLoading,
  canDragAndDrop,
  selectedPeriods,
  kpisScrollerRef,
  kpisListDivRef,
  hasCRUDPermissions,
  isSlideoutOpen,
  isShadowLeftVisible,
  isShadowRightVisible,
  rightShadowRightPos,
  onKpiMouseLeave,
  isWorkspace,
  isDashboard,
  height,
  width,
  gridRef,
}: {
  isEmpty: boolean;
  kpis: (
    | Scorecards.ScorecardKpi
    | Scorecards.EmptyRow
    | Scorecards.ManualKpiRow
  )[];
  onDragEnd: (result: DropResult) => void;
  isLoading: boolean;
  canDragAndDrop: boolean;
  selectedPeriods: Period[];
  kpisScrollerRef: React.MutableRefObject<HTMLDivElement | null>;
  kpisListDivRef: React.MutableRefObject<HTMLDivElement | null>;
  hasCRUDPermissions: boolean;
  isSlideoutOpen: boolean;
  isShadowLeftVisible: boolean;
  isShadowRightVisible: boolean;
  rightShadowRightPos: number;
  onKpiMouseLeave: () => void;
  isWorkspace: boolean;
  isDashboard: boolean;
  height: number;
  width?: number;
  gridRef: (node: HTMLDivElement) => void;
}) => (
  <Wrapper isWorkspace={isWorkspace} isDashboard={isDashboard}>
    <Left isSlideoutOpen={isSlideoutOpen}>
      <KpisWrapper ref={kpisListDivRef} data-testid="kpis">
        {kpis.length > 0 && (
          <Row spaceBetween centerAlign style={{ padding: '16px 24px' }}>
            <Typography.Header type="H5">KPIs</Typography.Header>
            {hasCRUDPermissions && <AddKpiTypesButton />}
          </Row>
        )}

        {isLoading && <Loading />}
        {!isLoading && (
          <>
            {isEmpty && <EmptyKpis />}
            {!isEmpty && (
              <InnerScrollableContent
                className="hiding-scrollbar"
                ref={kpisScrollerRef}
              >
                <div
                  ref={gridRef}
                  onMouseLeave={onKpiMouseLeave}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    height: 'fit-content',
                    width,
                  }}
                >
                  <div
                    style={{
                      position: 'sticky',
                      alignSelf: 'flex-start',
                      left: '0px',
                      zIndex: Z_INDEX.SCORECARD_KPIS,
                      maxWidth: `${KPI_LEFT_COL_MAX_WIDTH}px`,
                      minWidth: `${KPI_LEFT_COL_MIN_WIDTH}px`,
                      backgroundColor: 'white',
                    }}
                  >
                    {canDragAndDrop && (
                      <DraggableKpiListLeft
                        kpis={kpis}
                        onDragEnd={onDragEnd}
                        isShadowLeftVisible={isShadowLeftVisible}
                      />
                    )}
                    {!canDragAndDrop && (
                      <KpiListLeft
                        kpis={kpis}
                        isShadowLeftVisible={isShadowLeftVisible}
                      />
                    )}
                  </div>

                  <KpiListRightWrapper style={{ flex: selectedPeriods.length }}>
                    <KpiListRight kpis={kpis} />
                  </KpiListRightWrapper>

                  {isShadowRightVisible && (
                    <div
                      style={{
                        height: `${height}px`,
                        position: 'absolute',
                        width: '10px',
                        zIndex: Z_INDEX.SCORECARD_SHADOWS,
                        right: rightShadowRightPos,
                        boxShadow: '-8px 0px 10px rgba(0, 0, 0, 0.08)',
                      }}
                    />
                  )}
                </div>
              </InnerScrollableContent>
            )}
          </>
        )}
      </KpisWrapper>
    </Left>
    <SlideOut />
  </Wrapper>
);

export default Kpis;
