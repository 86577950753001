import React, { useContext, useEffect, useState } from 'react';
import aguid from 'aguid';

import Loading from './Loading';
import GroupedLoadingContext from '../../contexts/GroupedLoadingContext';
import FlexCentered from '../Common/FlexCentered';
import Typography from 'kingpin/atoms/Typography';
import Card from 'components/Common/Card';
import LandingPage from '../LandingPage';

const LoadingContainer = ({
  isSmall,
  isBlocking,
  isGrouped,
}: {
  isSmall?: boolean;
  isBlocking?: boolean;
  isGrouped?: boolean; // override to show red spinner
}) => {
  const [id] = useState<string>(aguid());
  const GroupedLoading = useContext(GroupedLoadingContext);
  const isGroupedLoading = Object.keys(GroupedLoading).length > 0;
  const [isShowingRefreshButton, setIsShowingRefreshButton] =
    useState<boolean>(false);

  useEffect(() => {
    if (!isGroupedLoading) {
      return;
    }

    GroupedLoading.addLoader({ id, isLoading: true });
    return () => {
      GroupedLoading.removeLoader({ id, isLoading: true });
    };
    // eslint-disable-next-line
  }, [id]);

  // For blocking loads, show refresh button after loading for 60 seconds
  useEffect(() => {
    if (!isBlocking) {
      return;
    }

    if (isGroupedLoading && GroupedLoading.isLoading) {
      return;
    }

    const interval = setInterval(() => {
      setIsShowingRefreshButton(true);
    }, 1000 * 60);
    return () => {
      clearInterval(interval);
    };
  }, [GroupedLoading.isLoading, isBlocking, isGroupedLoading]);

  if (isGroupedLoading && GroupedLoading.isLoading) {
    return null;
  }

  if (isShowingRefreshButton) {
    return (
      <LandingPage style={{ paddingTop: 0, overflowY: 'hidden' }}>
        <FlexCentered style={{ height: '100vh' }}>
          {!isShowingRefreshButton && (
            <Loading isSmall={isSmall} isGrouped={isGrouped} />
          )}
          {isShowingRefreshButton && (
            <Card style={{ padding: 16 }}>
              <Typography.Header type="H4">
                This is taking longer than expected
              </Typography.Header>

              <Typography.Body type="Body 12">
                Please check your internet connection, refresh this page or
                contact Customer Support if this problem continues.
              </Typography.Body>
              <div style={{ marginTop: 24 }}>
                <Loading isSmall={isSmall} isGrouped={isGrouped} />
              </div>
            </Card>
          )}
        </FlexCentered>
      </LandingPage>
    );
  } else {
    return (
      <FlexCentered style={{ height: isBlocking ? '100vh' : '100%' }}>
        <Loading isSmall={isSmall} isGrouped={isGrouped} />
      </FlexCentered>
    );
  }
};

export default LoadingContainer;
