import aguid from 'aguid';
import { DateTime } from 'luxon';

const contentShared = ({
  sharedByDisplayName,
  contentName,
  link,
  shareMessage,
}: {
  sharedByDisplayName: string;
  contentName: string;
  link: string;
  shareMessage: string;
}): Notifications.BasicNotification => ({
  id: aguid(),
  isBasic: true,
  type: 'CONTENT_SHARED',
  seen: false,
  read: false,
  deleted: false,
  createdOn: DateTime.utc().toISO(),
  emailSent: false,
  link,
  inAppSubject: `${sharedByDisplayName} shared '${contentName}' with you`,
  inAppBody: shareMessage,
  inAppLinkText: 'View Content',
  emailSubject: `${sharedByDisplayName} shared '${contentName}' with you on FleetOps`,
  emailBodyLn1: `${sharedByDisplayName} shared '${contentName}' with you`,
  emailBodyLn2: shareMessage,
  emailLinkText: 'Log in to FleetOps',
});

export default contentShared;
