import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

const useIsOnProductTour = () => {
  const [isTourModalOpen, setIsTourModalOpen] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    const interval = setInterval(() => {
      setIsTourModalOpen(
        document.getElementsByClassName('intercom-tour-frame').length > 0,
      );
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const params = new URLSearchParams(location.search);
  return params.has('product_tour_id') || isTourModalOpen;
};

export default useIsOnProductTour;
