import { useCallback, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import isoDateToAmerican from 'isoDateToAmerican';

import useTrendData from './useTrendData';

const useSeries = ({ metric }: { metric: MetricInput }) => {
  const {
    isLoading: isLoadingData,
    data,
    interval,
  } = useTrendData({
    metric,
  });
  const [series, setSeries] = useState<
    Highcharts.SeriesLineOptions[] | undefined
  >(undefined);
  const [categories, setCategories] = useState<string[]>([]);

  const buildPoint = useCallback(
    (d: MetricResponse): DatasetTrendButton.PointOptionsObject => {
      if (interval === undefined) {
        const e = new Error();
        e.name = 'interval not defined';
        throw e;
      }
      return {
        y: d[metric.id],
        date: d['date'],
        interval,
        field: metric.field,
        dataset: metric.dataType,
      };
    },
    [interval, metric.dataType, metric.field, metric.id],
  );

  useEffect(() => {
    if (!data || isLoadingData) {
      setSeries(undefined);
      return;
    }

    const s = {
      name: `${metric.field} ${metric.aggFunc}`,
      type: 'line' as 'line',
      data: data.map(buildPoint),
      marker: {
        symbol: 'circle',
        enabled: false,
        animation: false,
      },
    };

    setSeries([s]);
    setCategories(data.map((d) => isoDateToAmerican(d['date'])));
  }, [buildPoint, data, isLoadingData, metric.aggFunc, metric.field]);

  return {
    series,
    categories,
    interval,
    isLoading: isLoadingData || series === undefined,
  };
};

export default useSeries;
