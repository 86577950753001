import React, { useCallback, useContext, useEffect, useState } from 'react';
import AccessInput from './AccessInput';
import CurrentUserContext from '../../contexts/CurrentUserContext';
import useUsers from '../../hooks/useUsers';
import RolesContext from '../../contexts/RolesContext';
import PERMISSIONS from '../../permissionDefinitions';
import { PortalsContext } from '../../contextProviders/PortalsProvider';

const AccessInputContainer = ({
  access,
  setAccess,
  canSetToPrivate,
  isVisibleInPortalEnabledAccount,
}: {
  access: ResourceAccess;
  setAccess: React.Dispatch<React.SetStateAction<ResourceAccess>>;
  canSetToPrivate?: boolean;
  isVisibleInPortalEnabledAccount?: boolean;
}) => {
  const { isPortalsEnabled } = useContext(PortalsContext);
  const { currentPermissions } = useContext(RolesContext);
  const { isFleetOpsStaff } = useContext(CurrentUserContext);
  const allUsers = useUsers();
  const [users, setUsers] = useState<string[]>(
    access.userIds
      ? access.userIds.filter((uid) => allUsers.some((au) => au.id === uid))
      : [],
  );
  const currentUser = useContext(CurrentUserContext);

  useEffect(() => {
    setAccess((a) => {
      if (a.type === 'Users') {
        return {
          ...a,
          userIds: users,
        };
      } else {
        return {
          version: '2',
          type: a.type,
        };
      }
    });
  }, [currentUser.id, setAccess, users]);

  const onAccessTypeChanged = useCallback(
    (newType: AccessType) => {
      if (
        newType === 'Private' ||
        newType === 'Public' ||
        newType === 'Admin Only'
      ) {
        setAccess({
          version: '2',
          type: newType,
        });
      }
      if (newType === 'Users') {
        setAccess({
          version: '2',
          type: 'Users',
          userIds: [],
        });
      }
      setUsers([]);
    },
    [setAccess],
  );
  const [options, setOptions] = useState<DropdownOption[]>([]);

  useEffect(() => {
    const newOptions = [] as DropdownOption[];
    if (
      currentPermissions.includes(
        PERMISSIONS.CONTENT_ACCESS.SET_ANY_AVAILABLE_TO_USERS_OR_PUBLIC,
      )
    ) {
      newOptions.push({
        label: 'Users',
        isSelected: access.type === 'Users',
        onSelected: () => onAccessTypeChanged('Users'),
      });
    }
    if (
      currentPermissions.includes(
        PERMISSIONS.CONTENT_ACCESS.SET_ANY_AVAILABLE_TO_USERS_OR_PUBLIC,
      )
    ) {
      newOptions.push({
        label: 'Public',
        isSelected: access.type === 'Public',
        onSelected: () => onAccessTypeChanged('Public'),
      });
    }
    if (
      currentPermissions.includes(
        PERMISSIONS.CONTENT_ACCESS.SET_ANY_AVAILABLE_TO_ADMIN_ONLY,
      )
    ) {
      newOptions.push({
        label: 'Admin Only',
        isSelected: access.type === 'Admin Only',
        onSelected: () => onAccessTypeChanged('Admin Only'),
      });
    }
    if (
      canSetToPrivate ||
      currentPermissions.includes(
        PERMISSIONS.CONTENT_ACCESS.SET_ANY_AVAILABLE_TO_PRIVATE,
      )
    ) {
      newOptions.push({
        label: 'Private',
        isSelected: access.type === 'Private',
        onSelected: () => onAccessTypeChanged('Private'),
      });
    }
    setOptions(newOptions);
  }, [
    access.type,
    canSetToPrivate,
    currentPermissions,
    isFleetOpsStaff,
    onAccessTypeChanged,
  ]);

  if (isPortalsEnabled && !isVisibleInPortalEnabledAccount) {
    return null;
  }

  return <AccessInput access={access} setUsers={setUsers} options={options} />;
};

export default AccessInputContainer;
