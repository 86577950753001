import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ModalTransition } from '@atlaskit/modal-dialog';
import Inputs from 'components/Inputs';
import ContextMenu from 'components/ContextMenu';
import usePopup from 'hooks/usePopup';
import AnalyticsContext from 'contexts/AnalyticsContext';
import getIdentifier from 'getIdentifier';
import useConfirmation from 'screens/DataManager/DatasetDefinitions/DatasetsIndex/PerformanceDatasetWizard/useConfirmation';

import TargetFormContext from '../../contexts/TargetFormContext';
import DuplicateGroupModal from './DuplicateGroupModal';

const GroupContextMenu = ({ group }: { group: Targets.Wizard.TargetGroup }) => {
  const { isOpen, open, close } = usePopup();
  const {
    isOpen: isDuplicateOpen,
    open: openDuplicate,
    close: closeDuplicate,
  } = usePopup();
  const {
    getConfirmation: getRemoveRuleConfirmation,
    ConfirmationModal: RemoveRuleConfirmationModal,
  } = useConfirmation({
    confirmText: 'Delete',
    title: 'Delete Rule',
    body: `Are you sure you want to remove this rule?`,
  });
  const { trackEvent } = useContext(AnalyticsContext);

  const {
    setGroups,
    isDuplicateRuleAvailable,
    groups,
    nextGroupColorIndex,
    setNextGroupColorIndex,
  } = useContext(TargetFormContext);

  const onDeleteClicked = useCallback(async () => {
    const isConfirmed = await getRemoveRuleConfirmation();
    if (!isConfirmed) {
      return;
    }
    trackEvent('Targets - Rule Deleted');
    setGroups((currentGroups) =>
      currentGroups.filter((g) => g.key !== group.key),
    );
    close();
  }, [close, getRemoveRuleConfirmation, group.key, setGroups, trackEvent]);
  const onDuplicateClicked = useCallback(() => {
    trackEvent('Targets - Duplicate Rule Clicked');
    openDuplicate();
  }, [openDuplicate, trackEvent]);
  const onDuplicateConfirmed = useCallback(
    (groupName: string[]) => {
      const newGroup: Targets.Wizard.TargetGroup = {
        ...group,
        key: getIdentifier(undefined, true),
        groupName,
        colorIndex: nextGroupColorIndex,
      };

      setGroups((currentGroups) => [newGroup, ...currentGroups]);
      setNextGroupColorIndex((c) => c + 1);
      closeDuplicate();
      trackEvent('Targets - Rule Duplicated');
      close();
    },
    [
      close,
      closeDuplicate,
      group,
      nextGroupColorIndex,
      setGroups,
      setNextGroupColorIndex,
      trackEvent,
    ],
  );

  const [options, setOptions] = useState<
    { label: string; onClick: () => void }[]
  >([]);

  useEffect(() => {
    setOptions(() => {
      const newOptions = [];
      if (isDuplicateRuleAvailable) {
        newOptions.push({
          label: 'Duplicate Rule',
          onClick: onDuplicateClicked,
        });
      }

      if (groups.length > 2) {
        newOptions.push({
          label: 'Delete Rule',
          onClick: onDeleteClicked,
        });
      }

      return newOptions;
    });
  }, [
    groups.length,
    isDuplicateRuleAvailable,
    onDeleteClicked,
    onDuplicateClicked,
  ]);

  return (
    <>
      <ContextMenu
        isOpen={isOpen}
        open={isOpen ? close : open}
        close={close}
        testId={'group-context-menu'}
      >
        <Inputs.ListPicker options={options} />
      </ContextMenu>
      <ModalTransition>
        {isDuplicateOpen && (
          <DuplicateGroupModal
            close={closeDuplicate}
            onDuplicateConfirmed={onDuplicateConfirmed}
          />
        )}
      </ModalTransition>
      {RemoveRuleConfirmationModal}
    </>
  );
};

export default GroupContextMenu;
