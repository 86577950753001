import React from 'react';
import styled from 'styled-components';
import Typography from 'kingpin/atoms/Typography';
import Button from 'kingpin/atoms/Button';
import FlexCentered from './Common/FlexCentered';

const Icon = styled.img`
  height: 110px;
  margin-bottom: 34px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EmptyState = ({
  text,
  iconSrc,
  buttonText,
  onButtonClicked,
}: {
  text: string;
  iconSrc: string;
  buttonText?: string;
  onButtonClicked?: () => void;
}) => (
  <FlexCentered style={{ height: 'calc(100vh - 180px)' }}>
    <Content>
      <Icon src={iconSrc} />
      <div style={{ marginBottom: 24 }}>
        <Typography.Header type={'H5'}>{text}</Typography.Header>
      </div>
      {!!onButtonClicked && !!buttonText && (
        <Button
          onClick={onButtonClicked}
          type="Primary"
          size="Small"
          label={buttonText}
          icon="add"
        />
      )}
    </Content>
  </FlexCentered>
);

export default EmptyState;
