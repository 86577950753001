import React, { useCallback } from 'react';
import styled from 'styled-components';
import Typography from 'kingpin/atoms/Typography';
import Button from 'kingpin/atoms/Button';

import { ReactComponent as NoSearchIcon } from '../images/no-search.svg';
import useEnabledEntities from '../hooks/useEnabledEntities';

const WrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 70px;
`;

const YouCanSearchFor = () => {
  const enabledEntities = useEnabledEntities();

  return (
    <div style={{ marginBottom: 16 }}>
      <div style={{ marginBottom: 8, textAlign: 'center' }}>
        <Typography.Body type="Body 12">You can search for</Typography.Body>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <ul>
          {enabledEntities.map((e) => (
            <li key={e}>{e}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const NoSearchResults = ({
  searchText,
  isActiveFilterDisabled,
  setIsActiveFilterDisabled,
}: {
  searchText: string;
  isActiveFilterDisabled: boolean;
  setIsActiveFilterDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const onShowInactiveClicked = useCallback(() => {
    setIsActiveFilterDisabled(true);
  }, [setIsActiveFilterDisabled]);

  let message: JSX.Element;
  if (isActiveFilterDisabled) {
    message = (
      <>
        <Typography.Body type="Body 12">
          We could not find any matches for
        </Typography.Body>
        <Typography.Body type="Body 12">{`"${searchText}"`}</Typography.Body>
      </>
    );
  } else {
    message = (
      <>
        <Typography.Body type="Body 12">
          We cannot find any results matching your search, Try Again with a
          different term.
        </Typography.Body>
      </>
    );
  }

  return (
    <WrapperDiv>
      <div style={{ marginBottom: 16 }}>
        <NoSearchIcon />
      </div>
      <div style={{ marginBottom: 8 }}>{message}</div>
      <YouCanSearchFor />
      {!isActiveFilterDisabled && (
        <Button
          type="Tertiary"
          size="Small"
          onClick={onShowInactiveClicked}
          label="Show Inactive"
        />
      )}
    </WrapperDiv>
  );
};

export default NoSearchResults;
