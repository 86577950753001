import React, { useCallback, useContext, useState } from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import ScorecardContext from 'contexts/ScorecardContext';
import AnalyticsContext from 'contexts/AnalyticsContext';
import usePopup from 'hooks/usePopup';
import { ScoringBandColors } from 'screens/ScorecardsIndex/constants';
import ContextMenu, { Break, Option } from 'kingpin/atoms/ContextMenu';
import ScorecardKpiForm from './ScorecardKpiForm';
import GroupRowForm from './GroupRowForm';
import ManualRowForm from './ManualRowForm';

const AddKpiTypesButton = () => {
  const { scorecard, isAddKpiEnabled } = useContext(ScorecardContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const { isOpen: isKpiOpen, open: openKpi, close: closeKpi } = usePopup();
  const {
    isOpen: isCloseConfirmationOpen,
    open: openCloseConfirmation,
    close: closeCloseConfirmation,
  } = usePopup();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const numColors = ScoringBandColors[scorecard.scoringBandId].length;

  const onKpiClicked = useCallback(() => {
    openKpi();
    trackEvent('KPI List - Add KPI Clicked', {
      scorecardName: scorecard.title,
      scorecardId: scorecard.id,
    });
  }, [openKpi, scorecard.id, scorecard.title, trackEvent]);

  const onCloseClicked = useCallback(() => {
    if (hasUnsavedChanges) {
      openCloseConfirmation();
      return;
    }

    closeKpi();
  }, [closeKpi, hasUnsavedChanges, openCloseConfirmation]);

  const onCloseConfirmed = useCallback(() => {
    closeCloseConfirmation();
    closeKpi();
    setHasUnsavedChanges(false);
  }, [closeKpi, closeCloseConfirmation]);

  const {
    open: openManual,
    close: closeManual,
    isOpen: isManualOpen,
  } = usePopup();

  const onManualCloseClicked = useCallback(() => {
    if (hasUnsavedChanges) {
      openCloseConfirmation();
      return;
    }

    closeManual();
  }, [closeManual, hasUnsavedChanges, openCloseConfirmation]);

  const onManualCloseConfirmed = useCallback(() => {
    closeCloseConfirmation();
    closeManual();
    setHasUnsavedChanges(false);
  }, [closeCloseConfirmation, closeManual]);

  const {
    open: openGroup,
    close: closeGroup,
    isOpen: isGroupOpen,
  } = usePopup();

  const onGroupClicked = useCallback(() => {
    openGroup();
    trackEvent('KPI List - Add Group Clicked');
  }, [openGroup, trackEvent]);

  const onManualClicked = useCallback(() => {
    openManual();
    trackEvent('KPI List - Add Manual KPI - Clicked');
  }, [openManual, trackEvent]);

  const handleCloseForm = useCallback(() => {
    closeGroup();
  }, [closeGroup]);

  if (!isAddKpiEnabled) {
    return null;
  }

  return (
    <>
      <ContextMenu testId="scorecard-add-menu" label="Add" icon="add">
        <Option label="KPI" onClick={onKpiClicked} />
        <Option label="Manual KPI" onClick={onManualClicked} />
        <Break />
        <Option label="Group" onClick={onGroupClicked} />
      </ContextMenu>
      <ModalTransition>
        {(isKpiOpen || isManualOpen) && (
          <Modal
            shouldScrollInViewport={false}
            autoFocus={false}
            width={Math.max(600, numColors * 150)}
          >
            {isKpiOpen ? (
              <ScorecardKpiForm
                close={onCloseClicked}
                setHasUnsavedChanges={setHasUnsavedChanges}
                isCloseConfirmationOpen={isCloseConfirmationOpen}
                onCloseConfirmed={onCloseConfirmed}
                onCloseConfirmationClosed={closeCloseConfirmation}
              />
            ) : (
              <ManualRowForm
                close={onManualCloseClicked}
                setHasUnsavedChanges={setHasUnsavedChanges}
                isCloseConfirmationOpen={isCloseConfirmationOpen}
                onCloseConfirmed={onManualCloseConfirmed}
                onCloseConfirmationClosed={closeCloseConfirmation}
              />
            )}
          </Modal>
        )}
        {isGroupOpen && (
          <Modal shouldScrollInViewport={false} autoFocus={false}>
            <GroupRowForm close={handleCloseForm} />
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};

export default AddKpiTypesButton;
