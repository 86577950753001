import React, { useCallback, useContext, useState } from 'react';

import { Break, Option } from 'kingpin/atoms/ContextMenu';
import useMetricUsageReport from '../../../../hooks/useMetricUsageReport';
import metricTypeCheckers from '../../../../types/metricTypeCheckers';
import LocalTimelineProvider from '../../../../contextProviders/TimelineProvider/LocalTimelineProvider';
import AccountPickerContext from '../../../../contexts/AccountPickerContext';
import LocalTimelineContext from '../../../../contexts/Timeline/LocalTimelineContext';
import updateMetric from '../../../../api/metrics/updateMetric';
import updateCompoundMetric from '../../../../api/compoundMetrics/updateCompoundMetric';
import CurrentUserContext from '../../../../contexts/CurrentUserContext';

const ArchiveMetricButton = ({
  metric,
}: {
  metric: Metrics.NormalMetric | Metrics.CompoundMetric;
}) => {
  const currentUser = useContext(CurrentUserContext);
  const { addEvent } = useContext(LocalTimelineContext);
  const { selectedAccountId } = useContext(AccountPickerContext);

  const onArchived = useCallback(() => {
    if (!addEvent) {
      return;
    }

    return addEvent({
      actionText: 'Archived metric',
      contextText: `"${metric.name}"`,
    });
  }, [addEvent, metric.name]);

  const onArchiveClicked = useCallback(() => {
    if (metricTypeCheckers.isNormalMetric(metric)) {
      const newMetric = {
        ...metric,
        updatedBy: currentUser.id,
        status: 'archived' as 'archived',
      };
      updateMetric(metric.id, newMetric, selectedAccountId).then(onArchived);
    } else if (metricTypeCheckers.isCompoundMetric(metric)) {
      const newMetric = {
        ...metric,
        updatedBy: currentUser.id,
        status: 'archived' as 'archived',
      };
      updateCompoundMetric(metric.id, newMetric, selectedAccountId).then(
        onArchived,
      );
    }
  }, [currentUser.id, metric, onArchived, selectedAccountId]);

  return (
    <>
      <Break />
      <Option onClick={onArchiveClicked} label={'Archive'} isDanger />
    </>
  );
};

const Gate = ({
  metric,
}: {
  metric: Metrics.NormalMetric | Metrics.CompoundMetric;
}) => {
  const [timelineDestination] = useState<Timeline.EventSource>({
    type: metricTypeCheckers.isCompoundMetric(metric)
      ? 'CompoundMetric'
      : 'Metric',
    id: metric.id,
  });
  const usageReport = useMetricUsageReport(metric);

  if (!usageReport) {
    return null;
  }

  if (usageReport.usageCount > 0 || metric.status === 'archived') {
    // Separate condition clause for testing purposes
    return <div data-testid="cannot-archive" />;
  }

  return (
    <LocalTimelineProvider
      destination={timelineDestination}
      sources={window.emptyArray}
    >
      <ArchiveMetricButton metric={metric} />
    </LocalTimelineProvider>
  );
};

export default Gate;
