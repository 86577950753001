const Measurements = {
  TOP_NAV_BAR_HEIGHT: '42px',
  BOT_NAV_BAR_HEIGHT: '39px',
  SIDE_BAR_WIDTH: '66px',
  DISPATCH_ISSUES_LIST_WIDTH: '348px',
  MEETINGS_ACTIONS_LIST_WIDTH: '348px',
  DISPATCH_ISSUES_LIST_PADDING: '8px',
  NOTIFICATION_WIDTH: '287px',
  TIMELINE_HEADER_HEIGHT: '37px',
  TIMELINE_FOOTER_HEIGHT: '33px',
  JOB_DETAILS_TOP_BAR_HEIGHT: '41px',
  JOB_DETAILS_MAP_HEIGHT: '470px',
  MEETINGS_TOP_BAR_HEIGHT: '58px',
  MEETING_TOP_BAR_HEIGHT: '116px',
  MEETINGS_TOP_BAR_HEIGHT_WALLBOARD: '0px',
  INLINE_DIALOG_PADDING_HORIZONTAL: '12px',
  INLINE_DIALOG_PADDING_VERTICAL: '15px',
  CARD_PADDING: '13px 13px',
  CARD_PADDING_SINGLE: '13px',
  COUNTER_CARD_PADDING: '-13px -13px',
  TEAM_TOP_BAR_HEIGHT: '47px',
};

export default Measurements;
