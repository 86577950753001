import React, { useCallback, useEffect, useState } from 'react';
import useIsTargetValid from './useIsTargetValid';
import useOnSaveClicked from './useOnSaveClicked';
import useGetForcedGroupField from './useGetForcedGroupField';
import useGetInitialGroups from './useGetInitialGroups';
import getIdentifier from '../../../getIdentifier';

const useManageGroupsOperator = ({
  setGroups,
  isBelowTargetDesirable,
  isGridMode,
}: {
  setGroups: React.Dispatch<React.SetStateAction<Targets.Wizard.TargetGroup[]>>;
  isBelowTargetDesirable: boolean;
  isGridMode: boolean;
}) => {
  useEffect(() => {
    setGroups((groups) => {
      return groups.map((g) => {
        return {
          ...g,
          targets: g.targets.map((t) => {
            return {
              ...t,
              categories: t.categories.map((c) => {
                const operator = (() => {
                  if (isGridMode) {
                    if (c.isLastRow) {
                      return isBelowTargetDesirable ? 'gt' : 'lt';
                    }

                    return isBelowTargetDesirable ? 'lte' : 'gte';
                  }

                  return isBelowTargetDesirable ? 'lte' : 'gte';
                })();
                return {
                  ...c,
                  operator,
                };
              }),
            };
          }),
        };
      });
    });
  }, [isBelowTargetDesirable, isGridMode, setGroups]);
};

// When outputs change, update the categories
const useManageCategoryOutputs = ({
  setGroups,
  outputs,
}: {
  setGroups: React.Dispatch<React.SetStateAction<Targets.Wizard.TargetGroup[]>>;
  outputs: Targets.Wizard.OutputField[];
}) => {
  const syncOutputsInGroup = useCallback(
    ({
      group,
      outputs,
    }: {
      group: Targets.Wizard.TargetGroup;
      outputs: Targets.Wizard.OutputField[];
    }) => {
      const newGroup = {
        ...group,
      };
      newGroup.targets.forEach((t) => {
        t.categories.forEach((c) => {
          outputs.forEach((o) => {
            if (!c.outputs.some((co) => co.fieldName === o.fieldName)) {
              c.outputs.push({
                fieldName: o.fieldName,
                fieldValue: '',
                fieldType: o.fieldType,
              });
            }
          });
        });
      });

      return newGroup;
    },
    [],
  );

  useEffect(() => {
    setGroups((currentGroups) =>
      currentGroups.map((g) => syncOutputsInGroup({ group: g, outputs })),
    );
  }, [outputs, setGroups, syncOutputsInGroup]);
};

const useManageGridModeTransition = ({
  isGridMode,
  setGroups,
  outputs,
  isBelowTargetDesirable,
}: {
  isGridMode: boolean;
  setGroups: React.Dispatch<React.SetStateAction<Targets.Wizard.TargetGroup[]>>;
  outputs: Targets.Wizard.OutputField[];
  isBelowTargetDesirable: boolean;
}) => {
  useEffect(() => {
    if (!isGridMode) {
      return;
    }

    setGroups((currentGroups) => {
      return currentGroups.map((g) => {
        const newTargets: Targets.Wizard.Target[] = g.targets.map((t) => {
          const hasLastRow = t.categories.some((c) => c.isLastRow);
          const hasNoTargets = t.categories.length === 0;
          if (hasLastRow || hasNoTargets) {
            return t;
          }

          const secondLastRow = t.categories[t.categories.length - 1];
          const value = secondLastRow.value;

          const newCategory: Targets.Wizard.Categorisation = {
            key: getIdentifier(undefined, true),
            value,
            outputs: outputs.map((o) => ({
              fieldName: o.fieldName,
              fieldValue: '',
              fieldType: o.fieldType,
            })),
            isLastRow: true,
            operator: isBelowTargetDesirable ? 'gt' : 'lt',
          };

          const newTarget: Targets.Wizard.Target = {
            ...t,
            categories: [...t.categories, newCategory],
          };

          return newTarget;
        });

        return {
          ...g,
          targets: newTargets,
        };
      });
    });
  }, [isBelowTargetDesirable, isGridMode, outputs, setGroups]);
};

const useFormState = ({
  dataType,
  target,
  isCopyingFromTarget,
  targetField,
}: {
  dataType: string;
  target?: Targets.Wizard.DataTypeTarget;
  isCopyingFromTarget: boolean;
  targetField: string;
}) => {
  const getForcedGroupField = useGetForcedGroupField();
  const getInitialGroups = useGetInitialGroups();

  const [isShowingValidationFeedback, setIsShowingValidationFeedback] =
    useState<boolean>(false);
  const [isEditing] = useState<boolean>(
    target !== undefined && isCopyingFromTarget === false,
  );
  const [effectiveDate, setEffectiveDate] = useState<string | undefined>(
    target && !isCopyingFromTarget ? target.effectiveDate : undefined,
  );
  const [groupField, setGroupField] = useState<string | undefined>(
    target ? target.groupField : getForcedGroupField(targetField),
  );
  const [isBelowTargetDesirable, setIsBelowTargetDesirable] = useState<boolean>(
    target ? target.isBelowTargetDesirable : false,
  );
  const [isTrackingFullPeriod, setIsTrackingFullPeriod] = useState<boolean>(
    target ? target.isFullPeriod : false,
  );
  const isTargetedByGroup = groupField !== undefined && groupField !== '*';
  const [groups, setGroups] = useState<Targets.Wizard.TargetGroup[]>(() =>
    target ? target.groups : getInitialGroups(isTargetedByGroup),
  );
  const [outputs, setOutputs] = useState<Targets.Wizard.OutputField[]>(
    target ? target.outputFields : [],
  );
  const isGridMode = outputs.length > 0;
  useManageGroupsOperator({ setGroups, isBelowTargetDesirable, isGridMode });
  useManageCategoryOutputs({
    setGroups,
    outputs,
  });
  useManageGridModeTransition({
    isGridMode,
    setGroups,
    outputs,
    isBelowTargetDesirable,
  });

  const isValid = useIsTargetValid({ groups, groupField, effectiveDate });
  const { onSaveClickedConfirmed, isSaving } = useOnSaveClicked({
    dataType,
    effectiveDate,
    groupField,
    isTrackingFullPeriod,
    groups,
    isEditing,
    isValid,
    target,
    setIsShowingValidationFeedback,
  });

  useEffect(() => {
    if (!isShowingValidationFeedback) {
      return;
    }

    if (isShowingValidationFeedback && isValid) {
      setIsShowingValidationFeedback(false);
    }
  }, [isShowingValidationFeedback, isValid]);

  return {
    isEditing,
    effectiveDate,
    setEffectiveDate,
    groupField,
    setGroupField,
    isBelowTargetDesirable,
    setIsBelowTargetDesirable,
    groups,
    setGroups,
    outputs,
    setOutputs,
    isValid,
    onSaveClickedConfirmed,
    isSaving,
    isTargetedByGroup,
    isShowingValidationFeedback,
    setIsShowingValidationFeedback,
    isTrackingFullPeriod,
    setIsTrackingFullPeriod,
    isGridMode,
  };
};

export default useFormState;
