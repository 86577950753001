import BILLING_PERMISSIONS from './billing';
import CUSTOMER_COMMITMENTS_PERMISSIONS from './customerCommitments';
import DASHBOARD_MANAGEMENT_PERMISSIONS from './dashboardManagement';
import DATA_MANAGEMENT_PERMISSIONS from './dataManagement';
import DRIVER_BONUS_PERMISSIONS from './driverBonus';
import GOAL_MANAGEMENT_PERMISSIONS from './goalManagement';
import INTERNAL_USE_PERMISSIONS from './internalUse';
import REPORT_MANAGEMENT_PERMISSIONS from './reportManagement';
import SCORECARD_MANAGEMENT_PERMISSIONS from './scorecardManagement';
import USER_MANAGEMENT_PERMISSIONS from './userManagement';
import VIEW_DATA_PERMISSIONS from './viewData';
import WORKSPACE_MANAGEMENT_PERMISSIONS from './workspaceMangement';
import CONTENT_ACCESS_PERMISSIONS from './contentAccess';
import PERFORMANCE_BOARD_PERMISSIONS from './performanceBoard';

const PERMISSIONS = {
  BILLING: BILLING_PERMISSIONS,
  CUSTOMER_COMMITMENTS: CUSTOMER_COMMITMENTS_PERMISSIONS,
  DASHBOARDS: DASHBOARD_MANAGEMENT_PERMISSIONS,
  DATA_MANAGEMENT: DATA_MANAGEMENT_PERMISSIONS,
  DRIVER_BONUS: DRIVER_BONUS_PERMISSIONS,
  GOALS: GOAL_MANAGEMENT_PERMISSIONS,
  INTERNAL_USE: INTERNAL_USE_PERMISSIONS,
  REPORT_MANAGEMENT: REPORT_MANAGEMENT_PERMISSIONS,
  SCORECARDS: SCORECARD_MANAGEMENT_PERMISSIONS,
  USER_MANAGEMENT: USER_MANAGEMENT_PERMISSIONS,
  VIEW_DATA: VIEW_DATA_PERMISSIONS,
  WORKSPACES: WORKSPACE_MANAGEMENT_PERMISSIONS,
  CONTENT_ACCESS: CONTENT_ACCESS_PERMISSIONS,
  PERFORMANCE_BOARD: PERFORMANCE_BOARD_PERMISSIONS,
};

export default PERMISSIONS;
