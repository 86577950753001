import React, { ChangeEvent, useContext, useState } from 'react';
import RenameBoard from './RenameBoard';
import ModalDialog, { ModalTransition } from '@atlaskit/modal-dialog';
import BoardContext from '../../../contexts/BoardContext';
import updateBoard from '../../../api/boards/updateBoard';
import AccountPickerContext from '../../../contexts/AccountPickerContext';
import isPerformanceBoard from '../../../isPerformanceBoard';

const RenameBoardContainer = ({ close }: { close: () => void }) => {
  const { board } = useContext(BoardContext);
  const { accountRef } = useContext(AccountPickerContext);

  const [name, setName] = useState(board.name);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const onNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  if (isPerformanceBoard(board)) {
    return null;
  }

  const onSave = () => {
    setIsLoading(true);
    const newBoard = {
      ...board,
      name,
    };
    updateBoard(newBoard, accountRef).then(() => {
      setIsLoading(false);
      close();
    });
  };

  return (
    <RenameBoard
      name={name}
      onNameChanged={onNameChanged}
      onSave={onSave}
      isLoading={isLoading}
      close={close}
    />
  );
};

const Gate = ({ isOpen, close }: { isOpen: boolean; close: () => void }) => (
  <ModalTransition>
    {isOpen && (
      <ModalDialog
        onClose={close}
        shouldScrollInViewport={false}
        autoFocus={false}
      >
        <RenameBoardContainer close={close} />
      </ModalDialog>
    )}
  </ModalTransition>
);

export default Gate;
