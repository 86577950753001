import React from 'react';
import ListItemWrapper from '../DatasetDefinitions/ListItemWrapper';
import EntityListItemLink from './EntityListItemLink';
import EntitySettingsButton from './EntitySettingsButton';
import PERMISSIONS from '../../../permissionDefinitions';
import PermissionGates from 'components/PermissionGates';

const ListItem = ({ entity }: { entity: EntityDetails.Entity }) => {
  return (
    <ListItemWrapper
      style={{ justifyContent: 'space-between', alignItems: 'center' }}
    >
      <EntityListItemLink entity={entity} />
      <PermissionGates.Has
        requiredPermission={PERMISSIONS.DATA_MANAGEMENT.ENTITY_CONFIGURATION}
        fallback={<EntitySettingsButton entity={entity} isAccessOnly />}
      >
        <EntitySettingsButton entity={entity} />
      </PermissionGates.Has>
    </ListItemWrapper>
  );
};

export default ListItem;
