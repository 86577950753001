import React, { useCallback, useContext, useEffect, useState } from 'react';
import useFilterValues from 'hooks/useFilterValues';
import ReportDrillDownFormContext from 'contexts/ReportDrillDownFormContext';
import useDateScope from 'hooks/useDateScope';
import Preview from './Preview';
import useContextualDataTypes from '../../../hooks/useContextualDataTypes';
import getIdentifier from '../../../../../getIdentifier';

const PreviewContainer = ({
  wildcardFilter,
  editingId,
}: {
  wildcardFilter: WildcardFilter;
  editingId?: string;
}) => {
  const { field, dataset, onDrillDownConfirmed } = useContext(
    ReportDrillDownFormContext,
  );
  const dataTypes = useContextualDataTypes();
  const dateScope = useDateScope({});
  if (!field) {
    throw new Error('Field must be defined');
  }

  const [filterInput, setFiletInput] = useState<FilterInput>({
    dataType: dataTypes,
    keywords: [],
    ranges: [],
    booleanFilters: [],
    wildcardFilters: [wildcardFilter],
  });
  useEffect(() => {
    setFiletInput({
      dataType: dataTypes,
      keywords: [],
      ranges: [],
      booleanFilters: [],
      wildcardFilters: [wildcardFilter],
    });
  }, [dataTypes, wildcardFilter]);
  const { values: previewItems, isLoading: isLoadingPreviewItems } =
    useFilterValues({
      field,
      dateScope,
      filterInput,
      limit: 20,
    });

  const onItemSelected = useCallback(
    (item: string) => {
      const newDrillDown: TextDrillDown = {
        id: editingId ? editingId : getIdentifier(undefined, true),
        field,
        fieldType: 'text' as 'text',
        keywordValues: [item],
        isExclude: false,
        dataset,
        mode: 'editing' as 'editing',
        keywordsExists: undefined,
      };

      onDrillDownConfirmed(newDrillDown);
    },
    [dataset, editingId, field, onDrillDownConfirmed],
  );

  return (
    <Preview
      previewItems={previewItems}
      isLoadingPreviewItems={isLoadingPreviewItems}
      onItemSelected={onItemSelected}
    />
  );
};

// useFilterValues does not update on filterInput change due to render loop
// This is the force it to update
const RefreshGate = ({
  wildcardFilter,
  editingId,
}: {
  wildcardFilter: WildcardFilter;
  editingId?: string;
}) => {
  return (
    <PreviewContainer
      key={`${wildcardFilter.pattern}-${wildcardFilter.exclude}`}
      wildcardFilter={wildcardFilter}
      editingId={editingId}
    />
  );
};

export default RefreshGate;
