import moment from 'moment';
import { DateTime } from 'luxon';

export const getEndOf = (date: number, interval?: FleetOps.Interval) => {
  if (!interval) {
    return date;
  }

  switch (interval) {
    case 'month':
      return DateTime.fromMillis(date).plus({ month: 1 }).toMillis();
    case 'week':
      return DateTime.fromMillis(date).plus({ week: 1 }).toMillis();
    case 'year':
      return DateTime.fromMillis(date).plus({ year: 1 }).toMillis();
    case 'quarter':
      return DateTime.fromMillis(date).plus({ quarter: 1 }).toMillis();
    default:
    case 'auto':
    case 'day':
    case 'hour':
      return date;
  }
};

export const getIsLastDatePartial = ({
  date,
  interval,
  dateScope,
}: {
  date: string | number;
  interval?: FleetOps.Interval;
  dateScope?: DateRangeInput;
}) => {
  const dateToCompareTo = (() => {
    if (!dateScope || !dateScope.endDate) {
      const today = moment.utc().startOf('day');
      return today;
    }
    return moment.utc(dateScope.endDate).startOf('day');
  })();

  const endDate = (() => {
    const e = getEndOf(
      typeof date === 'string' ? DateTime.fromISO(date).toMillis() : date,
      interval,
    );
    return moment.utc(e).startOf('day');
  })();

  return dateToCompareTo.isBefore(endDate) && !dateToCompareTo.isSame(endDate);
};

export const removePartialIntervalsDates = (
  data: string[],
  interval?: FleetOps.Interval,
  dateScope?: DateRangeInput,
) => {
  // Last Item
  const isLastItemPartial = getIsLastDatePartial({
    date: data[data.length - 1],
    interval,
    dateScope,
  });

  // First Item
  const isFirstItemPartial = (() => {
    if (
      !interval ||
      interval === 'auto' ||
      !dateScope ||
      !dateScope.startDate
    ) {
      return false;
    }

    const dateRangeStart = dateScope.startDate;
    const firstItemDate = data[0];
    return moment.utc(firstItemDate).isBefore(moment.utc(dateRangeStart));
  })();

  if (isFirstItemPartial && isLastItemPartial) {
    return data.slice(1, data.length - 1);
  } else if (isFirstItemPartial) {
    return data.slice(1);
  } else if (isLastItemPartial) {
    return data.slice(0, data.length - 1);
  } else {
    return data;
  }
};

export const removePartialFirstInterval = (
  data: [number, number][],
  interval?: FleetOps.Interval,
  dateScope?: DateRangeInput,
) => {
  if (data.length === 0) {
    return [];
  }

  // First Item
  const isFirstItemPartial = (() => {
    if (
      !interval ||
      interval === 'auto' ||
      !dateScope ||
      !dateScope.startDate
    ) {
      return false;
    }

    const dateRangeStart = dateScope.startDate;
    const firstItemDate = data[0][0];

    return (
      moment.utc(firstItemDate).isBefore(moment.utc(dateRangeStart)) &&
      !moment.utc(firstItemDate).isSame(moment.utc(dateRangeStart))
    );
  })();

  if (isFirstItemPartial) {
    return data.slice(1);
  } else {
    return data;
  }
};

const removePartialIntervals = (
  data: [number, number][],
  interval?: FleetOps.Interval,
  dateScope?: DateRangeInput,
) => {
  if (data.length === 0) {
    return [];
  }

  const dataWithoutFirstPartial = removePartialFirstInterval(
    data,
    interval,
    dateScope,
  );

  // Last Item
  const lastItem = dataWithoutFirstPartial[dataWithoutFirstPartial.length - 1];
  const isLastItemPartial = getIsLastDatePartial({
    date: lastItem[0],
    dateScope,
    interval,
  });

  if (isLastItemPartial) {
    return dataWithoutFirstPartial.slice(0, dataWithoutFirstPartial.length - 1);
  } else {
    return dataWithoutFirstPartial;
  }
};

export default removePartialIntervals;
