import React, { ChangeEvent } from 'react';
import Form from 'kingpin/forms/Form';
import FormHeader from 'kingpin/forms/FormHeader';
import FormContent from 'kingpin/forms/FormContent';
import Button from 'kingpin/atoms/Button';
import Row from 'components/Common/Row';
import FlexCentered from 'components/Common/FlexCentered';
import Loading from 'components/Loading';
import SuccessMessage from '../SuccessMessage';
import UserMultiSelect from './UserMultiSelect';
import AdditionalContentGate from './AdditionalContentGate';
import UsersWithAccess from './UsersWithAccess';
import getTypeName from '../getContentTypeName';

const ShareModal = ({
  selectedUsers,
  setSelectedUsers,
  sharedWith,
  isLoadingSharedWith,
  contentName,
  type,
  typeId,
  isLoading,
  successMessage,
  onShareClicked,
  close,
  refreshSharedWith,
}: {
  selectedUsers: (UserManagement.SignedUpUser | UserManagement.PendingUser)[];
  setSelectedUsers: React.Dispatch<
    React.SetStateAction<
      (UserManagement.SignedUpUser | UserManagement.PendingUser)[]
    >
  >;
  sharedWith: (UserManagement.SignedUpUser | UserManagement.PendingUser)[];
  isLoadingSharedWith: boolean;
  shareMessage: string;
  onShareMessageChanged: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  contentName: string;
  type: 'report' | 'dashboard' | 'scorecard' | 'workSpace';
  typeId: string;
  isLoading: boolean;
  successMessage?: string;
  onShareClicked: () => void;
  close: () => void;
  refreshSharedWith: () => void;
}) => (
  <Form>
    <FormHeader
      title={`Share '${contentName}'`}
      subTitle={
        type === 'workSpace'
          ? `This ${getTypeName(type)} will appear in the users' "Workspaces" screen`
          : `This ${getTypeName(type)} will appear in the users' "Shared" screen`
      }
      onClose={close}
    />
    <FormContent>
      {isLoadingSharedWith && (
        <FlexCentered>
          <Loading />
        </FlexCentered>
      )}
      {!isLoadingSharedWith && (
        <>
          <Row spaceBetween centerAlign>
            <UserMultiSelect
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              sharedWith={sharedWith}
              type={type}
              typeId={typeId}
            />
            <Button
              label={'Share'}
              size={'Medium'}
              type={'Primary'}
              isLoading={isLoading}
              isDisabled={selectedUsers.length === 0 || isLoading}
              onClick={onShareClicked}
              testId={'share-confirm'}
            />
          </Row>
          <AdditionalContentGate
            successMessage={successMessage}
            isShared={sharedWith.length > 0}
          >
            <>
              {successMessage && (
                <div style={{ marginBottom: 16 }}>
                  <SuccessMessage successMessage={successMessage} />
                </div>
              )}
              {sharedWith.length > 0 && (
                <UsersWithAccess
                  type={type}
                  typeId={typeId}
                  sharedWith={sharedWith}
                  contentName={contentName}
                  refreshSharedWith={refreshSharedWith}
                />
              )}
            </>
          </AdditionalContentGate>
        </>
      )}
    </FormContent>
  </Form>
);

export default ShareModal;
