import React from 'react';
import DetailsSlideOutNav from './DetailsSlideOutNav';

const DetailsSlideOutNavContainer = ({
  activeNavElement,
}: {
  activeNavElement: SlideOutNavElement;
}) => {
  return <DetailsSlideOutNav activeNavElement={activeNavElement} />;
};

export default DetailsSlideOutNavContainer;
