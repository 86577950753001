import getDayOfWeekComparison from './getDayOfWeekComparison';
import getNumberOfDays from './getNumberOfDays';
import { DateTime } from 'luxon';
import isV5ChartDef from '../../../types/visTypeCheckers/isV5ChartDef';

const getComparisonConcerns = ({
  relativeDateRange,
  comparison,
  chartDef,
  isMetricListItem,
  currentDateRange,
}: {
  relativeDateRange?: RelativeDateRange;
  comparison?: PersistedComparisonType;
  chartDef?: VisualisationDefinition;
  isMetricListItem?: boolean;
  currentDateRange: DateRangeInput;
}) => {
  const isDateRangeAtLeastAWeek =
    currentDateRange.startDate &&
    currentDateRange.endDate &&
    Math.abs(
      DateTime.fromISO(currentDateRange.startDate).diff(
        DateTime.fromISO(currentDateRange.endDate),
        'days',
      ).days,
    ) >= 6;

  if (!chartDef || !isV5ChartDef(chartDef)) {
    return {
      isSmartCard: false,
      isHistogram: false,
      isSmartCellMatrix: false,
      isDayOfWeekImportant:
        isMetricListItem &&
        isDateRangeAtLeastAWeek &&
        relativeDateRange &&
        !['month', 'year', 'quarter'].includes(relativeDateRange.interval),
    };
  } else {
    const isSmartCard = chartDef && chartDef.gadgetType === 'smartCard';
    const isHistogram =
      chartDef && !chartDef.dimensionB && chartDef.trendByCalendarInterval;
    const isSmartCellMatrix =
      chartDef &&
      chartDef.gadgetType === 'matrix' &&
      chartDef.series.some(
        (s) =>
          s.matrixCellType === 'delta' || s.matrixCellType === 'percentDelta',
      );

    const isLastYearComparison =
      comparison && comparison.compareType === 'lastYear';
    const isTrendingByWeekOrDay =
      !!chartDef &&
      !!chartDef.trendByCalendarInterval &&
      ['week', 'day'].includes(chartDef.trendByCalendarInterval);
    const isWeekOrDayRollingWindow =
      relativeDateRange &&
      !['month', 'year', 'quarter'].includes(relativeDateRange.interval);
    const isSmartCardLike =
      isSmartCellMatrix || isMetricListItem || isSmartCard;

    const isDayOfWeekImportant =
      isTrendingByWeekOrDay ||
      (isSmartCardLike &&
        isDateRangeAtLeastAWeek &&
        isWeekOrDayRollingWindow &&
        isLastYearComparison);

    return {
      isSmartCard,
      isHistogram,
      isSmartCellMatrix,
      isDayOfWeekImportant,
    };
  }
};

const getVisSpecificComparison = ({
  relativeDateRange,
  comparison,
  chartDef,
  isMetricListItem,
  currentDateRange,
}: {
  relativeDateRange?: RelativeDateRange;
  comparison: PersistedComparisonType;
  chartDef?: VisualisationDefinition;
  isMetricListItem?: boolean;
  currentDateRange: DateRangeInput;
}) => {
  const { isSmartCard, isHistogram, isSmartCellMatrix, isDayOfWeekImportant } =
    getComparisonConcerns({
      relativeDateRange,
      comparison,
      chartDef,
      isMetricListItem,
      currentDateRange,
    });

  const isRelativeDateRangeWithToday =
    relativeDateRange && !!relativeDateRange.currentToDate;

  if (
    (isHistogram || isSmartCellMatrix || isMetricListItem || isSmartCard) &&
    isDayOfWeekImportant &&
    !isRelativeDateRangeWithToday
  ) {
    return getDayOfWeekComparison(currentDateRange, comparison);
  }
  const requiresDayOfWeekComparison =
    relativeDateRange &&
    (relativeDateRange.interval === 'day' ||
      relativeDateRange.interval === 'week');
  const numberOfDays = getNumberOfDays(currentDateRange);

  if (
    !isRelativeDateRangeWithToday &&
    isDayOfWeekImportant &&
    (requiresDayOfWeekComparison ||
      (!relativeDateRange &&
        ((numberOfDays < 7 && numberOfDays % 6 === 0) ||
          (numberOfDays % 7) % 6 === 0)))
  ) {
    return getDayOfWeekComparison(currentDateRange, comparison);
  }
};

export default getVisSpecificComparison;
