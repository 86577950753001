import React, { useContext, useEffect, useState } from 'react';
import DatasetDefinitionsContext from '../../contexts/DatasetDefinitionsContext';
import useGaugeFormDataTypes from './useGaugeFormDataTypes';
import Inputs from '../Inputs';

const useGaugeFormPeerGroupOptions = ({
  selectedMetricId,
  setPeerGroup,
  peerGroup,
  setEntity,
}: {
  selectedMetricId?: string;
  peerGroup?: string;
  setPeerGroup: React.Dispatch<React.SetStateAction<string | undefined>>;
  setEntity: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
  const dataTypes = useGaugeFormDataTypes(selectedMetricId);
  const { datasets } = useContext(DatasetDefinitionsContext);
  const [groupOptions, setGroupOptions] = useState<DropdownOption[]>([]);

  useEffect(() => {
    const options = [] as DropdownOption[];
    dataTypes.forEach((dt) => {
      const set = datasets.find((s) => s.type === dt);
      if (set) {
        set.fields.forEach((f) => {
          if (f.type === 'text') {
            options.push({
              label: f.field,
              onSelected: () => {
                setPeerGroup(f.field);
                setEntity(undefined);
              },
            });
          }
        });
      }
    });

    setGroupOptions(options);
  }, [dataTypes, datasets, peerGroup, setEntity, setPeerGroup]);

  return {
    groupOptions,
  };
};

const PeerGroupInput = ({
  peerGroup,
  setPeerGroup,
  metricId,
  setEntity,
}: {
  peerGroup?: string;
  setPeerGroup: React.Dispatch<React.SetStateAction<string | undefined>>;
  metricId?: string;
  setEntity: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
  const { groupOptions } = useGaugeFormPeerGroupOptions({
    setPeerGroup,
    selectedMetricId: metricId,
    peerGroup,
    setEntity,
  });

  return <Inputs.Dropdown options={groupOptions} selectedLabel={peerGroup} />;
};

export default PeerGroupInput;
