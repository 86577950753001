import React from 'react';
import Typography from 'kingpin/atoms/Typography';
import {
  ClickEater,
  ValueItem,
  WrapperInner,
  WrapperOuter,
} from '../../../OmniSearch/SearchResults/SearchResults';
import Card from '../../../Common/Card';
import buildCustomerSearchItemLabel from './buildCustomerSearchItemLabel';

const Results = ({
  onItemSelected,
  searchResults,
  hasResults,
  close,
}: {
  onItemSelected: (item: CustomerSearchResult) => void;
  searchResults: CustomerSearchResult[];
  hasResults: boolean;
  close: () => void;
}) => (
  <React.Fragment>
    <WrapperOuter>
      <WrapperInner>
        <Card style={{ maxHeight: 400, overflowY: 'scroll', padding: 8 }}>
          {hasResults && (
            <React.Fragment>
              {searchResults.map((item) => (
                <ValueItem
                  key={Math.random()}
                  onClick={() => {
                    onItemSelected(item);
                  }}
                >
                  <Typography.Body type={'Body 12'}>
                    {buildCustomerSearchItemLabel(item)}
                  </Typography.Body>
                </ValueItem>
              ))}
            </React.Fragment>
          )}
          {!hasResults && (
            <Typography.Body type="Label">No results found</Typography.Body>
          )}
        </Card>
      </WrapperInner>
    </WrapperOuter>
    <ClickEater onClick={close} />
  </React.Fragment>
);

export default Results;
