import aguid from 'aguid';
import { DateTime } from 'luxon';

// max sendgrid personalization size is 10,000 bytes.
// source: https://docs.sendgrid.com/api-reference/mail-send/mail-send
// (request body -> custom_args)
export const getEmailHtmlContent = (
  comment: CommentType,
): string | undefined => {
  const html = `${comment.text}`.replaceAll('"', "'");
  return `<!DOCTYPE html><div style='line-height: 22px; font-size: 18px'>${html}</div></html>`;
};

const newComment = ({
  commenter,
  comment,
  link,
  emailBodyLn1,
  emailBodyLn1Extra,
}: {
  commenter: string;
  comment: CommentType;
  link: string;
  emailBodyLn1: string;
  emailBodyLn1Extra?: string;
}): Notifications.BasicNotification => ({
  id: aguid(),
  isBasic: true,
  type: 'MENTIONED_IN_COMMENT',
  seen: false,
  read: false,
  deleted: false,
  createdOn: DateTime.utc().toISO(),
  emailSent: false,
  link,
  inAppSubject: `${commenter} commented on a ${comment.commentableType}`,
  emailSubject: `${commenter} commented on a ${comment.commentableType}`,
  inAppLinkText: 'View Comment',
  emailBodyLn1: `${commenter} ${emailBodyLn1}`,
  emailBodyLn1Extra,
  emailHtmlContent: getEmailHtmlContent(comment),
  emailLinkText: 'Log in to reply',
});

export default newComment;
