import styled from 'styled-components';
import {
  COL_BORDER_COLOR,
  HEADER_SPAN_CSS,
  HEADERS_BORDER_COLOR,
  SPAN_CSS,
} from '../Grid/StyleOverrides';

const MetricListColumn = styled.div<{
  flex: number;
  isClickable?: boolean;
  isHeader?: boolean;
  rowHeight: number;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: ${(props) => props.rowHeight}px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: ${(props) => props.flex};

  ${(props) =>
    props.isClickable &&
    `
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  `}

  span {
    ${(props) => props.isHeader && HEADER_SPAN_CSS}
    ${(props) => !props.isHeader && SPAN_CSS}
  }

  padding: 0px 8px;
  &:first-of-type {
    padding-left: 16px;
  }
  &:last-of-type {
    padding-right: 16px;
  }

  &:not(:last-of-type) {
    border-right: 1px solid
      ${(props) => (props.isHeader ? HEADERS_BORDER_COLOR : COL_BORDER_COLOR)};
  }
`;

export default MetricListColumn;
