import React from 'react';
import styled from 'styled-components';
import Row from 'components/Common/Row';
import Typography from 'kingpin/atoms/Typography';
import MicrosoftIcon from '../MicrosoftIcon';

const HeadingDiv = styled(Row)`
  margin-bottom: 24px;
  padding-bottom: 24px;
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.08) inset;
`;

const Heading = () => (
  <HeadingDiv>
    <MicrosoftIcon />
    <Typography.Header type="H4">Microsoft SSO Integration</Typography.Header>
  </HeadingDiv>
);

export default Heading;
