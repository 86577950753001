import React, { useCallback, useEffect, useState } from 'react';
import Dropdown from '../../../../components/Inputs/Dropdown';
import useUpdateView from 'screens/DataManager/DatasetDefinitions/FieldSlideOut/useUpdateView';
import useTrackSettingsChanged from 'screens/DataManager/DatasetDefinitions/FieldSlideOut/useTrackFieldSettingsChanged';
import { buildFormatting } from 'screens/DataManager/DatasetDefinitions/FieldCellTypeDropdown';
import { CURRENCY_TYPES } from 'hooks/useValueFormatters';

const useCurrencyTypeOptions = ({
  field,
  fieldView,
  baseView,
}: {
  field: FleetOps.Field;
  fieldView: FleetOps.BaseViewField;
  baseView: FleetOps.BaseView;
}) => {
  const updateView = useUpdateView({ field, baseView });
  const trackSettingsChanged = useTrackSettingsChanged({ field, baseView });

  const getCurrencyOptions = useCallback(
    () =>
      CURRENCY_TYPES.map((t) => ({
        label: t,
        value: t,
        onSelected: () => {
          const newView = {
            ...fieldView,
            formatting: buildFormatting('Currency', t as CurrencyType),
          };

          updateView(newView);
          trackSettingsChanged();
        },
      })),
    [fieldView, trackSettingsChanged, updateView],
  );
  const [currencyTypeOptions, setCurrecyTypeOptions] =
    useState<DropdownOption[]>(getCurrencyOptions);
  useEffect(() => {
    setCurrecyTypeOptions(getCurrencyOptions());
  }, [getCurrencyOptions]);

  return currencyTypeOptions;
};

const CurrencyTypeDropdown = ({
  field,
  fieldView,
  baseView,
  testId,
}: {
  field: FleetOps.Field;
  fieldView: FleetOps.BaseViewField;
  baseView: FleetOps.BaseView;
  testId?: string;
}) => {
  const currencyTypeOptions = useCurrencyTypeOptions({
    field,
    fieldView,
    baseView,
  });
  return (
    <Dropdown
      options={currencyTypeOptions}
      testId={testId}
      selectedLabel={fieldView.formatting.currencyType || 'USD'}
    />
  );
};

export default CurrencyTypeDropdown;
