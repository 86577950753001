import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

const createTargetsMutation = async ({
  client,
  dataType,
  effectiveDate,
  cohortFieldName,
  cohorts,
}: {
  client: ApolloClient<NormalizedCacheObject>;
  dataType: string;
  effectiveDate: string;
  cohortFieldName: string;
  cohorts: Targets.Persisted.Cohort[];
}): Promise<void> => {
  const mutation = {
    mutation: gql`
      mutation CreateTargets(
        $dataType: String!
        $effectiveDate: Date!
        $cohortFieldName: String!
        $cohorts: [CohortsInput!]
        $targetType: TargetType!
        $categorisationFields: [CategoryFieldValuesInput!]
      ) {
        createTargets(
          dataType: $dataType
          effectiveDate: $effectiveDate
          cohortFieldName: $cohortFieldName
          cohorts: $cohorts
          targetType: $targetType
          categorisationFields: $categorisationFields
        )
      }
    `,
    variables: {
      dataType,
      effectiveDate,
      cohortFieldName,
      cohorts,
      targetType: 'simple',
      categorisationFields: [],
    },
  };

  await client.mutate(mutation);
};

export default createTargetsMutation;
