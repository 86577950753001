import { useCallback, useContext } from 'react';
import LocalTimelineContext from 'contexts/Timeline/LocalTimelineContext';
import isNormalMetric from 'types/metricTypeCheckers/isNormalMetric';
import { DATASET_FILTER_RESOURCE } from '../../../../../../constants';
import DatasetFiltersContext from 'screens/DataManager/DatasetFilters/context/DatasetFiltersContext';

const useUpdateDatasetFilterMetricTimeline = () => {
  const { addEvent } = useContext(LocalTimelineContext);
  const { datasetFilterIdLookup } = useContext(DatasetFiltersContext);

  const updateDatasetFilterMetricTimeline = useCallback(
    async ({
      selectedMetric,
      metricDraft,
    }: {
      selectedMetric: Metrics.NormalMetric | Metrics.CompoundMetric | undefined;
      metricDraft: Metrics.NormalMetric | Metrics.CompoundMetric | undefined;
    }) => {
      if (!addEvent || !metricDraft) {
        return;
      }
      const oldDatasetFilterIds = isNormalMetric(selectedMetric)
        ? selectedMetric?.datasetFilterIds || []
        : [];

      const newDatasetFilterIds = isNormalMetric(metricDraft)
        ? metricDraft?.datasetFilterIds || []
        : [];

      const removedDatasetFilterIds = oldDatasetFilterIds.filter(
        (id) => !newDatasetFilterIds.includes(id),
      );

      const addedDatasetFilterIds = newDatasetFilterIds.filter(
        (id) => !oldDatasetFilterIds.includes(id),
      );

      const removedDatasetFilterIdsPromises = removedDatasetFilterIds.map(
        (id) => {
          const datasetFilter = datasetFilterIdLookup[id];

          return addEvent({
            actionText: `removed ${datasetFilter?.name} filter`,
            contextText: `from "${metricDraft.name}" metric`,
            destinationOverride: { id, type: DATASET_FILTER_RESOURCE },
          });
        },
      );

      const addedDatasetFilterIdsPromises = addedDatasetFilterIds.map((id) => {
        const datasetFilter = datasetFilterIdLookup[id];

        return addEvent({
          actionText: `added ${datasetFilter?.name} filter`,
          contextText: `to "${metricDraft.name}" metric`,
          destinationOverride: { id, type: DATASET_FILTER_RESOURCE },
        });
      });

      await Promise.all([
        ...removedDatasetFilterIdsPromises,
        ...addedDatasetFilterIdsPromises,
      ]);
    },
    [addEvent, datasetFilterIdLookup],
  );

  return updateDatasetFilterMetricTimeline;
};

export default useUpdateDatasetFilterMetricTimeline;
