import React from 'react';
import MultiUserSelect from '../MultiUserSelect';
import Typography from 'kingpin/atoms/Typography';
import Inputs from 'components/Inputs';

const AccessInput = ({
  access,
  setUsers,
  options,
}: {
  access: ResourceAccess;
  setUsers: React.Dispatch<React.SetStateAction<string[]>>;
  options: DropdownOption[];
}) => (
  <div>
    <div style={{ marginBottom: 16 }}>
      <div style={{ marginBottom: 4 }}>
        <Typography.Body type="Label">Access</Typography.Body>
      </div>
      <Inputs.Dropdown options={options} />
    </div>
    {access.userIds && (
      <div>
        <div style={{ marginBottom: 4 }}>
          <Typography.Body type={'Label'}>Users</Typography.Body>
        </div>
        <MultiUserSelect
          selectedUsers={access.userIds}
          setSelectedUsers={setUsers}
          isOnlyMarkStaff
        />
      </div>
    )}
  </div>
);

export default AccessInput;
