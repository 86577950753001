import React, { useCallback, useContext, useState } from 'react';
import { GridApi } from 'ag-grid-community';

import DashboardGadgetContext from 'contexts/DashboardGadgetContext';
import DashboardGadgetFilteringButton from '../../DashboardGadgetFilteringButton';
import Row from '../../Common/Row';
import ExportButton from '../../Grid/ExportButton';
import DashboardGadgetBottomBar from '../../DashboardGadgetBottomBar';
import DashboardGadgetReportLinkButton from '../../DashboardGadgetReportLinkButton';
import ShowOnMouseOverUnlessTour from '../../ShowOnMouseOverUnlessTour';
import MetricsInfoButton from '../../MetricsInfoButton';
import CopyGadgetButton from '../../CopyGadgetButton';
import CanvasCard from '../../Visualisations/CanvasCard';
import CanvasCardTitle from '../../CanvasCardTitle';
import MatrixContent from './MatrixContent';
import buildShowAllKey from '../buildShowAllKey';
import isSingleMetricDateMatrix from '../../../types/visTypeCheckers/isSingleMetricDateMatrix';
import ContextMenu from 'kingpin/atoms/ContextMenu';

export const TopBar = ({
  gridApi,
  exportName,
  chartDefinition,
  title,
}: {
  gridApi?: GridApi;
  exportName?: string;
  chartDefinition?: VisualisationDefinition;
  title: string;
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const onMenuOpen = useCallback(() => {
    setIsMenuOpen(true);
  }, []);
  const onMenuClose = useCallback(() => {
    setIsMenuOpen(false);
  }, []);
  const { dashboardGadget } = useContext(DashboardGadgetContext);
  return (
    <Row
      spaceBetween
      style={{
        zIndex: 'unset',
        width: '100%',
        height: '100%',
      }}
      centerAlign
    >
      <CanvasCardTitle title={title} />
      <Row centerAlign>
        <ShowOnMouseOverUnlessTour>
          <div
            style={{ marginRight: 8 }}
            id={
              chartDefinition && isSingleMetricDateMatrix(chartDefinition)
                ? buildShowAllKey({
                    dashboardGadget,
                    chartDef: chartDefinition,
                  })
                : undefined
            }
          />
        </ShowOnMouseOverUnlessTour>
        <ShowOnMouseOverUnlessTour>
          <DashboardGadgetReportLinkButton />
        </ShowOnMouseOverUnlessTour>

        {!!chartDefinition && (
          <ShowOnMouseOverUnlessTour>
            <div style={{ marginRight: 8 }}>
              <MetricsInfoButton visualisation={chartDefinition} />
            </div>
          </ShowOnMouseOverUnlessTour>
        )}
        <ShowOnMouseOverUnlessTour isForcedVisible={isMenuOpen}>
          <ContextMenu
            buttonType="Ghost"
            onClose={onMenuClose}
            onOpen={onMenuOpen}
          >
            <DashboardGadgetFilteringButton />
            <>
              {gridApi !== undefined && exportName !== undefined && (
                <ExportButton
                  overrideGridApi={gridApi}
                  exportName={exportName}
                  isContextMenuItem
                />
              )}
            </>
            <CopyGadgetButton />
          </ContextMenu>
        </ShowOnMouseOverUnlessTour>
      </Row>
    </Row>
  );
};

const MatrixContainer = ({
  chartDefinition,
}: {
  chartDefinition: V5ChartDefinition;
}) => {
  const { dashboardGadget } = useContext(DashboardGadgetContext);
  const [gridApi, setGridApi] = useState<GridApi>();

  return (
    <CanvasCard
      topBar={
        <TopBar
          title={chartDefinition.name}
          gridApi={gridApi}
          exportName={chartDefinition.name}
          chartDefinition={chartDefinition}
        />
      }
      content={
        <MatrixContent
          chartDefinition={chartDefinition}
          gridApi={gridApi}
          setGridApi={setGridApi}
        />
      }
      bottomBar={dashboardGadget ? <DashboardGadgetBottomBar /> : undefined}
      isContentWithoutPadding
    />
  );
};

export default MatrixContainer;
