import React, { useCallback, useContext, useEffect, useState } from 'react';

import useGetMetricUsage from '../FilterSlideOut/UsageTab/hooks/useGetMetricUsage';
import useGetCompoundUsage from '../FilterSlideOut/UsageTab/hooks/useGetCompoundUsage';
import useGetScorecardUsage from '../FilterSlideOut/UsageTab/hooks/useGetScorecardUsage';
import useGetReportUsage from '../FilterSlideOut/UsageTab/hooks/useGetReportUsage';
import useGetGoalUsage from '../FilterSlideOut/UsageTab/hooks/useGetGoalUsage';
import useGetPerformanceUsage from '../FilterSlideOut/UsageTab/hooks/useGetPerformanceUsage';
import useGetChartDefinitionUsage from '../FilterSlideOut/UsageTab/hooks/useGetChartDefinitionUsage';
import useGetDashboardGadgetUsage from '../FilterSlideOut/UsageTab/hooks/useGetDashboardGadgetUsage';
import useGetBoardUsage from '../FilterSlideOut/UsageTab/hooks/useGetBoardUsage';

import DatasetFiltersUsageLookupContext from '../context/DatasetFiltersUsageLookupContext';
import DatasetFiltersContext from '../context/DatasetFiltersContext';

const DatasetFiltersUsageLookupProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { datasetFilters: allDatasetFilters } = useContext(
    DatasetFiltersContext,
  );
  const getMetricUsage = useGetMetricUsage();
  const getCompoundUsage = useGetCompoundUsage();
  const getScorecardUsage = useGetScorecardUsage();
  const getReportUsage = useGetReportUsage();
  const getGoalUsage = useGetGoalUsage();
  const getPerformanceUsage = useGetPerformanceUsage();
  const getChartDefinitionUsage = useGetChartDefinitionUsage();
  const getDashboardGadgetUsage = useGetDashboardGadgetUsage();
  const getBoardUsage = useGetBoardUsage();

  const [datasetFiltersLookup, setDatasetFiltersLookup] =
    useState<FleetOps.DatasetFiltersUsageLookup>({});

  const getDatasetFiltersUsage = useCallback(
    (datasetFilter: DatasetFilter): FleetOps.DatasetFiltersLookupResults => {
      const mtr = getMetricUsage(datasetFilter);
      const cms = getCompoundUsage(datasetFilter);
      const scs = getScorecardUsage(datasetFilter);
      const rs = getReportUsage(datasetFilter);
      const gs = getGoalUsage(datasetFilter);
      const perf = getPerformanceUsage(datasetFilter);
      const cds = getChartDefinitionUsage(datasetFilter);
      const dgs = getDashboardGadgetUsage(datasetFilter);
      const bs = getBoardUsage(datasetFilter);

      const usageCount =
        mtr.length +
        cms.length +
        scs.length +
        rs.length +
        gs.length +
        perf.length +
        cds.length +
        dgs.length +
        bs.length;

      const baseUsage = {
        metrics: mtr,
        compoundMetrics: cms,
        scorecards: scs,
        reports: rs,
        goals: gs,
        performanceConfigurations: perf,
        chartDefinitions: cds,
        dashboardGadgets: dgs,
        boards: bs,
        usageCount,
      };

      return baseUsage;
    },
    [
      getBoardUsage,
      getChartDefinitionUsage,
      getCompoundUsage,
      getDashboardGadgetUsage,
      getGoalUsage,
      getMetricUsage,
      getPerformanceUsage,
      getReportUsage,
      getScorecardUsage,
    ],
  );

  useEffect(() => {
    const newLookup: FleetOps.DatasetFiltersUsageLookup = {};
    allDatasetFilters.forEach((df) => {
      newLookup[df.id] = getDatasetFiltersUsage(df);
    });
    setDatasetFiltersLookup(newLookup);
  }, [allDatasetFilters, getDatasetFiltersUsage]);

  return (
    <DatasetFiltersUsageLookupContext.Provider value={{ datasetFiltersLookup }}>
      {children}
    </DatasetFiltersUsageLookupContext.Provider>
  );
};

export default DatasetFiltersUsageLookupProvider;
