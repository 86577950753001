import React from 'react';

export interface ImpersonatorContextType {
  impersonatorId?: string;
  setImpersonatorId: React.Dispatch<React.SetStateAction<string | undefined>>;
  viewingAppAs?: UserManagement.SignedUpUser | UserManagement.PendingUser;
}

const ImpersonatorContext = React.createContext<ImpersonatorContextType>(
  {} as ImpersonatorContextType,
);

export default ImpersonatorContext;
