import { useContext, useEffect, useState } from 'react';
import MetricOptionsContext from 'contexts/MetricOptionsContext';
import useMetric from 'hooks/useMetric';
import isDefined from 'isDefined';
import metricTypeCheckers from 'types/metricTypeCheckers';

const DYNAMIC_TARGET_AGG_FUNCS = ['count', 'sum'];

const useIsDynamicTarget = (
  metricId?: string,
  kpi?: Scorecards.ScorecardKpi | Scorecards.ManualKpiRow,
) => {
  const [isDynamicTarget, setIsDynamicTarget] = useState<boolean>(false);
  const metric = useMetric(metricId);
  const { normalMetrics } = useContext(MetricOptionsContext);

  useEffect(() => {
    if (!metric) {
      return;
    }

    if (metricTypeCheckers.isNormalMetric(metric)) {
      setIsDynamicTarget(DYNAMIC_TARGET_AGG_FUNCS.includes(metric.aggFunc));
    } else if (metricTypeCheckers.isCompoundMetric(metric)) {
      const components = metric.metricIds
        .map((mid) => normalMetrics.find((m) => m.id === mid))
        .filter(isDefined);
      const hasNonProrateFunc = components.some(
        (m) => !DYNAMIC_TARGET_AGG_FUNCS.includes(m.aggFunc),
      );
      const hasNonProrateOperator =
        metric.expression.includes('/') || metric.expression.includes('*');
      if (hasNonProrateFunc || hasNonProrateOperator) {
        setIsDynamicTarget(false);
      } else {
        setIsDynamicTarget(true);
      }
    } else {
      setIsDynamicTarget(false);
    }
  }, [metric, normalMetrics]);

  if (kpi && kpi.dynamicTargetOverride !== undefined) {
    return kpi.dynamicTargetOverride;
  }
  return isDynamicTarget;
};

export default useIsDynamicTarget;
