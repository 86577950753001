import React, { useContext } from 'react';
import styled from 'styled-components';
import Modal from '@atlaskit/modal-dialog';

import Row from '../../../../components/Common/Row';
import SideBar from './SideBar';
import Colors2 from '../../../../theme/Colors2';
import MetricPopupConstants from './constants';
import BottomBar from './BottomBar';
import TopBarInner from './TopBarInner';
import MetricForm from './MetricForm';
import Flash from '../../../../components/Flash';
import useWindowSize from '../../../../hooks/useWindowSize';
import CloseButton from '../../../../components/CloseButton';
import Title from './Title';
import SingleUseMetricPopupContext from '../../../../contexts/SingleUseMetricPopupContext';
import InSituMetricEditorContext from '../../../../contexts/InSituMetricEditorContext';
import MEDIA from '../../../../media';

const PopupInnerDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const MetricListDiv = styled.div`
  width: 280px;
  border-right: 1px solid ${Colors2.Grey['8']};

  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const MainContentOuterDiv = styled.div`
  height: 100%;
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const MainContentInnerDiv = styled(Row)`
  flex: 1;
  justify-content: space-between;
`;

const MainContentHeader = styled.div`
  padding: ${MetricPopupConstants.PADDING};
  border-bottom: 1px solid ${Colors2.Grey['8']};

  @media ${MEDIA.QUERIES.SMALL_HEIGHT} {
    padding: ${MetricPopupConstants.PADDING_SMALL};
  }
`;

const Form = styled.div`
  width: 525px;
  border-right: 1px solid ${Colors2.Grey['8']};
  overflow-y: auto;
`;

const PreviewDiv = styled.div`
  padding: ${MetricPopupConstants.PADDING};
  flex: 1;

  @media ${MEDIA.QUERIES.SMALL_HEIGHT} {
    padding: ${MetricPopupConstants.PADDING_SMALL};
  }
`;

const MetricPopup = ({
  close,
  selectedMetric,
  setSelectedMetric,
  id,
  setId,
  isFlashVisible,
  setIsFlashVisible,
  flashMessage,
  showFlash,
  hasUnsavedChanges,
  setHasUnsavedChanges,
  mode,
  forcedDataset,
}: {
  close: () => void;
  selectedMetric?: Metrics.NormalMetric | Metrics.CompoundMetric;
  setSelectedMetric?: React.Dispatch<
    React.SetStateAction<
      Metrics.NormalMetric | Metrics.CompoundMetric | undefined
    >
  >;
  id: string;
  setId: React.Dispatch<React.SetStateAction<string>>;
  isFlashVisible: boolean;
  setIsFlashVisible: React.Dispatch<React.SetStateAction<boolean>>;
  flashMessage: string;
  showFlash: (message: string) => void;
  hasUnsavedChanges: boolean;
  setHasUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  mode: 'Metric' | 'CompoundMetric';
  forcedDataset?: string;
}) => {
  const { isInSituEditor } = useContext(InSituMetricEditorContext);
  const { isSingleUsePopup } = useContext(SingleUseMetricPopupContext);
  const { windowWidth, windowHeight } = useWindowSize();

  return (
    <Modal
      width={
        setSelectedMetric === undefined
          ? '50vw'
          : windowWidth <= MEDIA.MEASUREMENTS.WIDTH_BREAKPOINT_MEDIUM &&
              windowWidth > MEDIA.MEASUREMENTS.WIDTH_BREAKPOINT_SMALL
            ? '90vw'
            : windowWidth <= MEDIA.MEASUREMENTS.WIDTH_BREAKPOINT_SMALL
              ? '95vw'
              : '75vw'
      }
      height={
        windowHeight <= MEDIA.MEASUREMENTS.HEIGHT_BREAKPOINT
          ? MetricPopupConstants.POPUP_HEIGHT_SMALL
          : MetricPopupConstants.POPUP_HEIGHT
      }
      onClose={close}
      shouldScrollInViewport={false}
      autoFocus={false}
    >
      <Flash
        isFlashVisible={isFlashVisible}
        setIsFlashVisible={setIsFlashVisible}
        message={flashMessage}
        type="info"
      />
      <PopupInnerDiv>
        <Row style={{ flex: 1 }}>
          {!!setSelectedMetric && (
            <MetricListDiv>
              <SideBar
                hasUnsavedChanges={hasUnsavedChanges}
                selectedMetric={selectedMetric}
                setSelectedMetric={setSelectedMetric}
                setId={setId}
                mode={mode}
              />
            </MetricListDiv>
          )}
          <MainContentOuterDiv>
            <MainContentHeader>
              <TopBarInner>
                <Title
                  selectedMetric={selectedMetric}
                  hasUnsavedChanges={hasUnsavedChanges}
                />
                <Row centerAlign>
                  <div
                    id={
                      isInSituEditor
                        ? MetricPopupConstants.CORE_TOGGLE_IN_SITU_DIV_ID
                        : isSingleUsePopup
                          ? MetricPopupConstants.CORE_TOGGLE_SINGLE_USE_DIV_ID
                          : MetricPopupConstants.CORE_TOGGLE_DIV_ID
                    }
                  />
                  <CloseButton close={close} />
                </Row>
              </TopBarInner>
            </MainContentHeader>
            <MainContentInnerDiv key={id}>
              <Form>
                <MetricForm
                  selectedMetric={selectedMetric}
                  id={id}
                  showFlash={showFlash}
                  setHasUnsavedChanges={setHasUnsavedChanges}
                  mode={mode}
                  forcedDataset={forcedDataset}
                />
              </Form>
              <PreviewDiv
                id={
                  isInSituEditor
                    ? MetricPopupConstants.PREVIEW_IN_SITU_DIV_ID
                    : isSingleUsePopup
                      ? MetricPopupConstants.PREVIEW_SINGLE_USE_DIV_ID
                      : MetricPopupConstants.PREVIEW_DIV_ID
                }
              />
            </MainContentInnerDiv>
          </MainContentOuterDiv>
        </Row>
        <BottomBar close={close} />
      </PopupInnerDiv>
    </Modal>
  );
};

export default MetricPopup;
