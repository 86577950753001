import React from 'react';
import Inputs from 'components/Inputs';
import Typography from 'kingpin/atoms/Typography';
import Group from '../Group';

const StackedAreaOptionsInput = ({
  altPivot,
  onAltPivotChanged,
}: {
  altPivot: boolean;
  onAltPivotChanged: (newPivot: boolean) => void;
}) => (
  <Group title={'Stacked Bar Options'}>
    <Typography.Body type={'Label'}>Alt pivot</Typography.Body>
    <Inputs.Toggle value={altPivot} onChange={onAltPivotChanged} />
  </Group>
);

export default StackedAreaOptionsInput;
