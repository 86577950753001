import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Inputs from 'components/Inputs';
import Row from 'components/Common/Row';
import WorkSpacesContext from 'contexts/WorkSpacesContext';
import { isTargetsApp } from 'isWorkSpace';
import Dropdown from 'components/Inputs/Dropdown';
import Typography from 'kingpin/atoms/Typography';
import TextInput from 'kingpin/atoms/TextInput';
import targetAppTypeCheckers from '../../../../screens/TargetsAppShow/targetAppTypeCheckers';

const TargetsLinkDiv = styled.div`
  background-color: #fbfbfb;
  padding: 16px;
  gap: 16px;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: #f0f0f0;
`;

const EnabledContent = ({
  targetsLink,
  setTargetsLink,
}: {
  targetsLink: EngagementPortalTargetLink;
  setTargetsLink: React.Dispatch<
    React.SetStateAction<EngagementPortalTargetLink>
  >;
}) => {
  const { allWorkSpaces } = useContext(WorkSpacesContext);

  const getOptions = useCallback((): DropdownOption[] => {
    return allWorkSpaces
      .filter(isTargetsApp)
      .filter(targetAppTypeCheckers.isConfiguredApp)
      .map((targetApp) => {
        return {
          label: targetApp.title,
          onSelected: () => {
            setTargetsLink({
              isEnabled: true,
              targetAppId: targetApp.id,
              navLabel: targetApp.title,
            });
          },
          isSelected:
            targetsLink.isEnabled && targetsLink.targetAppId === targetApp.id,
        };
      });
  }, [allWorkSpaces, setTargetsLink, targetsLink]);
  const [options, setOptions] = useState<DropdownOption[]>([]);
  useEffect(() => {
    setOptions(getOptions());
  }, [getOptions]);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setTargetsLink((currentLink) => {
        if (!currentLink.isEnabled) {
          return currentLink;
        }

        return {
          ...currentLink,
          navLabel: event.target.value,
        };
      });
    },
    [setTargetsLink],
  );

  if (!targetsLink.isEnabled) {
    return null;
  }

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <div style={{ marginBottom: 8 }}>
          <Typography.Body type={'Label'}>
            Select an admin app to copy targets into this app
          </Typography.Body>
        </div>
        <Dropdown options={options} />
      </div>
      <div style={{ marginBottom: 16 }}>
        <div style={{ marginBottom: 8 }}>
          <Typography.Body type={'Label'}>Nav Label</Typography.Body>
        </div>
        <TextInput
          value={targetsLink.navLabel}
          onChange={onChange}
          data-testid={'target-nav-label'}
        />
      </div>
    </div>
  );
};

const TargetLinkInput = ({
  targetsLink,
  setTargetsLink,
}: {
  targetsLink: EngagementPortalTargetLink;
  setTargetsLink: React.Dispatch<
    React.SetStateAction<EngagementPortalTargetLink>
  >;
}) => {
  const { allWorkSpaces } = useContext(WorkSpacesContext);

  const onTargetLinkToggled = useCallback(
    (newValue: boolean) => {
      if (newValue) {
        setTargetsLink({
          isEnabled: true,
          targetAppId: allWorkSpaces
            .filter(isTargetsApp)
            .filter(targetAppTypeCheckers.isConfiguredApp)[0].id,
          navLabel: '',
        });
      } else {
        setTargetsLink({
          isEnabled: false,
        });
      }
    },
    [allWorkSpaces, setTargetsLink],
  );

  if (allWorkSpaces.filter(isTargetsApp).length === 0) {
    return null;
  }

  return (
    <TargetsLinkDiv>
      <Row centerAlign>
        <div style={{ marginRight: 12 }}>
          <Inputs.Toggle
            value={targetsLink.isEnabled}
            onChange={onTargetLinkToggled}
            testId={'targets-toggle'}
          />
        </div>
        <Typography.Body type={'Label'}>Enable Targets</Typography.Body>
      </Row>
      {targetsLink.isEnabled && (
        <div style={{ marginTop: 16 }}>
          <EnabledContent
            targetsLink={targetsLink}
            setTargetsLink={setTargetsLink}
          />
        </div>
      )}
    </TargetsLinkDiv>
  );
};

export default TargetLinkInput;
