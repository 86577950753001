import moment from 'moment';

const removeTodayIfItIsZero = (data: [number, number][]) => {
  if (data.length === 0) {
    return [];
  }

  const today = moment.utc().startOf('day');
  const lastItem = data[data.length - 1];
  const isLastItemForToday = moment
    .utc(lastItem[0])
    .isBetween(today, today.endOf('day'));

  if (isLastItemForToday) {
    return data.slice(0, data.length - 1);
  }
  return data;
};

export default removeTodayIfItIsZero;
