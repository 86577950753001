import { useContext, useEffect, useState } from 'react';
import getFilterValues from '../api/getFilterValues';
import AccountPickerContext from '../contexts/AccountPickerContext';
import GqlClientContext from '../contexts/GqlClientContext';

const useFilterValues = ({
  field,
  value,
  dateScope,
  filterInput,
  limit,
}: {
  field?: string;
  value?: string;
  dateScope: DateRangeInput;
  filterInput?: FilterInput;
  limit?: number;
}) => {
  const { client } = useContext(GqlClientContext);
  const { selectedAccount } = useContext(AccountPickerContext);
  const [values, setValues] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!field) {
      return;
    }

    let isActive = true;
    setIsLoading(true);

    getFilterValues({
      field,
      value,
      filterInput,
      dateScope,
      limit,
      accountId: selectedAccount.accountId,
      client,
    }).then((newValues) => {
      if (!isActive) {
        return;
      }
      setValues(newValues);
      setIsLoading(false);
    });

    return () => {
      isActive = false;
    };
  }, [
    client,
    dateScope,
    field,
    filterInput,
    limit,
    selectedAccount.accountId,
    value,
  ]);

  return {
    values,
    isLoading,
  };
};

export default useFilterValues;
