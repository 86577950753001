import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

const updateTargetsMutation = async ({
  client,
  id,
  cohorts,
}: {
  client: ApolloClient<NormalizedCacheObject>;
  id: string;
  cohorts: Targets.Persisted.Cohort[];
}): Promise<void> => {
  const mutation = {
    mutation: gql`
      mutation UpdateTargets(
        $id: String!
        $cohorts: [CohortsInput!]!
        $targetType: TargetType!
        $categorisationFields: [CategoryFieldValuesInput!]
      ) {
        updateTargets(
          cohorts: $cohorts
          id: $id
          targetType: $targetType
          categorisationFields: $categorisationFields
        )
      }
    `,
    variables: {
      id,
      cohorts,
      categorisationFields: [],
      targetType: 'simple',
    },
  };

  await client.mutate(mutation);
};

export default updateTargetsMutation;
