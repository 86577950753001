import React from 'react';
import styled from 'styled-components';
import Colors from 'theme/colors';
import moment from 'moment';
import Typography from 'kingpin/atoms/Typography';

import Row from '../Common/Row';
import Delta from '../Gadget/Delta';
import getComparison from './Matrix/getComparison';

const MetricName = styled.div`
  margin-bottom: 12px;
`;

const TimeSeriesComparisonTooltip = ({
  currentDateText,
  previousDateText,
  metric,
  currentValue,
  currentFormatted,
  prevValue,
  prevFormatted,
  currentDateRange,
  previousDateRange,
  formatMetricOverride,
}: {
  currentDateText: string | JSX.Element;
  previousDateText: string | JSX.Element;
  metric: Metrics.Metric;
  currentValue?: number;
  currentFormatted: string | undefined;
  prevValue?: number;
  prevFormatted: string | undefined;
  currentDateRange: DateRangeInput;
  previousDateRange: DateRangeInput;
  // Certain uses of this element are outside of the context tree,
  // such as a highchart tooltip. For these cases, we need to inject this
  // method instead of using the hook.
  formatMetricOverride?: (args: {
    metricId: string;
    value: string | number | null | undefined;
  }) => string;
}) => {
  if (prevValue === undefined || prevValue === null) {
    return (
      <div style={{ padding: 8, minWidth: 240 }}>
        <Row style={{ marginBottom: 24 }} centerAlign>
          <MetricName>
            <Typography.Body type={'Body 14'}>{metric.name}</Typography.Body>
          </MetricName>
        </Row>
        <div>
          <Typography.Body type={'Body 12'}>{currentDateText}</Typography.Body>
        </div>
        <div>
          <Typography.Body type={'Body 12'}>
            <b>{currentFormatted}</b>
          </Typography.Body>
        </div>
      </div>
    );
  }

  const { delta: rawDelta, isGood } = getComparison({
    value: currentValue || 0,
    previous: prevValue,
    formatting: metric.formatting,
    type: 'delta',
  });
  const { delta } = getComparison({
    value: currentValue || 0,
    previous: prevValue,
    formatting: metric.formatting,
    type: 'percentDelta',
  });

  return (
    <div style={{ padding: 8, minWidth: 160, zIndex: 9000000000000 }}>
      <div style={{ marginBottom: 24 }}>
        <Row style={{ justifyContent: 'center' }} centerAlign>
          <Delta
            isGood={isGood}
            delta={delta}
            isBoth
            rawDelta={rawDelta}
            metricId={metric.id}
            formatMetricOverride={formatMetricOverride}
            isGrid={false}
          />
        </Row>
      </div>

      <Row centerAlign>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: 12,
          }}
        >
          {currentDateRange.startDate === currentDateRange.endDate && (
            <div style={{ marginBottom: 8 }}>
              <Typography.Body type="Body 12">
                {moment.utc(currentDateRange.startDate).format('ddd')}
              </Typography.Body>
            </div>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginBottom: 12,
            }}
          >
            <Typography.Body type="Body 12">{currentFormatted}</Typography.Body>
          </div>
          <div>
            <Typography.Body type="Body 12">{currentDateText}</Typography.Body>
          </div>
        </div>
        <div
          style={{
            color: Colors.GREY,
            marginLeft: 16,
            marginRight: 16,
          }}
        >
          <Typography.Body type="Body 12">vs</Typography.Body>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: 12,
          }}
        >
          {(previousDateRange.startDate === previousDateRange.endDate ||
            !previousDateRange.endDate) && (
            <div style={{ marginBottom: 8 }}>
              <Typography.Body type="Body 12">
                {moment.utc(previousDateRange.startDate).format('ddd')}
              </Typography.Body>
            </div>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginBottom: 12,
            }}
          >
            <Typography.Body type="Body 12">{prevFormatted}</Typography.Body>
          </div>
          <div>
            <Typography.Body type="Body 12">{previousDateText}</Typography.Body>
          </div>
        </div>
      </Row>
    </div>
  );
};

export default TimeSeriesComparisonTooltip;
