import { useContext, useEffect, useState } from 'react';
import ImpersonatorContext from '../../contexts/ImpersonatorContext';
import CurrentUserContext from '../../contexts/CurrentUserContext';
import AccountPickerContext from '../../contexts/AccountPickerContext';
import useProductTierOpenedGates from './useProductTierOpenedGates';

export const usePersistedGates = () => {
  const [gates, setGates] = useState<FleetOps.AccountFeatureGates>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { accountRef } = useContext(AccountPickerContext);

  useEffect(() => {
    const listener = accountRef.onSnapshot((snapshot) => {
      const newAccountData = snapshot.data();
      if (newAccountData) {
        const gates = newAccountData.featureGates
          ? (newAccountData.featureGates as FleetOps.AccountFeatureGates)
          : ({} as FleetOps.AccountFeatureGates);
        setGates(gates);
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      setGates(undefined);
      return;
    });

    return () => {
      listener();
    };
  }, [accountRef]);

  return {
    gates,
    isLoadingGates: isLoading,
  };
};

const useFeatureGates = () => {
  const { impersonatorId } = useContext(ImpersonatorContext);
  const { isFleetOpsStaff } = useContext(CurrentUserContext);
  const [isDriverBonusSummaryEnabled, setIsDriverBonusSummaryEnabled] =
    useState<boolean>(false);
  const [isGoalsEnabled, setIsGoalsEnabled] = useState<boolean>(false);
  const [isMultipleCardsEnabled, setIsMultipleCardsEnabled] =
    useState<boolean>(false);
  const [isSelfServeEnabled, setIsSelfServeEnabled] = useState<boolean>(false);
  const [isEntityDetailsEnabled, setIsEntityDetailsEnabled] =
    useState<boolean>(false);
  const [isFileUploadEnabled, setIsFileUploadEnabled] =
    useState<boolean>(false);
  const [isCustomerAwardsTrackerEnabled, setIsCustomerAwardsTrackerEnabled] =
    useState<boolean>(false);
  const [
    isManualKpiPermissionManagementEnabled,
    setIsManualKpiPermissionManagementEnabled,
  ] = useState<boolean>(false);
  const [isDriverBonusEnabled, setIsDriverBonusEnabled] =
    useState<boolean>(false);
  const [isActivityCostsEnabled, setIsActivityCostsEnabled] =
    useState<boolean>(false);
  const [isPortalsSEPreviewEnabled, setIsPortalsSEPreviewEnabled] =
    useState<boolean>(false);
  const [isPortalsUIPerUserToggleEnabled, setIsPortalsUIPerUserToggleEnabled] =
    useState<boolean>(false);
  const [
    isPortalsAccountWideToggleEnabled,
    setIsPortalsAccountWideToggleEnabled,
  ] = useState<boolean>(false);
  const {
    isPerformanceEnabled,
    isTemplatesEnabled,
    isViewerRoleAssignmentEnabled,
    isMicrosoftSsoEnabled,
  } = useProductTierOpenedGates();
  const { accountRef } = useContext(AccountPickerContext);
  const { gates, isLoadingGates } = usePersistedGates();

  useEffect(() => {
    if (!gates) {
      return;
    }

    const isStaff = !!isFleetOpsStaff && impersonatorId === undefined;

    setIsGoalsEnabled(!!gates.goals || isStaff);
    setIsMultipleCardsEnabled(!!gates.multipleCards);
    setIsDriverBonusSummaryEnabled(!!gates.driverBonusSummary || isStaff);
    setIsSelfServeEnabled(!!gates.selfServe || isStaff);
    setIsEntityDetailsEnabled(!!gates.entityDetails);
    setIsActivityCostsEnabled(!!gates.activityCosts);
    setIsFileUploadEnabled(!!gates.fileUpload);
    setIsCustomerAwardsTrackerEnabled(!!gates.customerAwardsTracker);
    setIsManualKpiPermissionManagementEnabled(
      !!gates.manualKpiPermissionManagement,
    );
    setIsDriverBonusEnabled(!!gates.driverBonus);
    setIsPortalsSEPreviewEnabled(!!gates.portalsSEPreview);
    setIsPortalsUIPerUserToggleEnabled(!!gates.portalsUIPerUserToggle);
    setIsPortalsAccountWideToggleEnabled(!!gates.portalsAccountWideToggle);
  }, [accountRef, gates, impersonatorId, isFleetOpsStaff]);

  return {
    isGoalsEnabled,
    isMultipleCardsEnabled,
    isDriverBonusSummaryEnabled,
    isSelfServeEnabled,
    isMicrosoftSsoEnabled,
    isEntityDetailsEnabled,
    isActivityCostsEnabled,
    isFileUploadEnabled,
    isCustomerAwardsTrackerEnabled,
    isManualKpiPermissionManagementEnabled,
    isDriverBonusEnabled,
    isPerformanceEnabled,
    isTemplatesEnabled,
    isViewerRoleAssignmentEnabled,
    isPortalsSEPreviewEnabled,
    isPortalsUIPerUserToggleEnabled,
    isPortalsAccountWideToggleEnabled,
    isLoading: isLoadingGates,
  };
};

export default useFeatureGates;
