import React, { useContext, useState } from 'react';
import DashboardGadgetContext from '../contexts/DashboardGadgetContext';
import DashboardContextMenuProvider from './DashboardContextMenuProvider';
import useDashboardGadgetDataTypes from '../hooks/useDashboardGadgetDataTypes';
import ReportDrillDownsProvider from './ReportDrillDownsProvider';
import VariableFiltersContext from '../contexts/VariableFiltersContext';

const DashboardGadgetProvider = ({
  children,
  dashboardGadget,
}: {
  children: JSX.Element | JSX.Element[];
  dashboardGadget: DashboardGadget;
}) => {
  const dataTypes = useDashboardGadgetDataTypes(dashboardGadget);
  const { variableFilters } = useContext(VariableFiltersContext);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  return (
    <DashboardGadgetContext.Provider
      value={{
        dashboardGadget,
        isEditing,
        setIsEditing,
      }}
    >
      <ReportDrillDownsProvider
        drillDowns={dashboardGadget.drillDowns}
        scope={dashboardGadget.scope}
        variableDrillDowns={variableFilters}
        isForDashboardCard
        dataTypes={dataTypes}
      >
        <DashboardContextMenuProvider>{children}</DashboardContextMenuProvider>
      </ReportDrillDownsProvider>
    </DashboardGadgetContext.Provider>
  );
};

export default DashboardGadgetProvider;
