import React, { useCallback, useContext, useEffect, useState } from 'react';
import { GridApi, ColumnApi } from 'ag-grid-community';

import GridContext from '../contexts/GridContext';
import BaseViewsContext from '../contexts/BaseViewsContext';
import getBaseViewFields from '../components/Grid/getBaseViewFields';
import Flash from '../components/Flash';

const aguid = require('aguid');

const GridProvider = ({
  children,
  dataType,
  metricFiltering,
  dateScopeOverride,
  gridQueryOverride,
  gridCountQueryOverride,
  gridTotalsQueryOverride,
  baseViewOverride,
  sortOverride,
  titleOverride,
  columnApiRef,
  gridApi,
  setGridApi,
}: {
  children: JSX.Element | JSX.Element[];
  dataType: string;
  metricFiltering?: FilterInput;
  dateScopeOverride?: DateRangeInput;
  gridQueryOverride?: string;
  gridCountQueryOverride?: string;
  gridTotalsQueryOverride?: string;
  baseViewOverride?: FleetOps.BaseView;
  sortOverride?: SimpleGridSort[];
  titleOverride?: string;
  columnApiRef?: React.MutableRefObject<ColumnApi | undefined>;
  gridApi?: GridApi;
  setGridApi?: React.Dispatch<React.SetStateAction<GridApi | undefined>>;
}) => {
  const [internalGridApi, setInternalGridApi] = useState<GridApi>();
  const [gridId, setGridId] = useState<string>(aguid());
  const [columnApi, setColumnApi] = useState<ColumnApi>();
  const [currentGroup, setCurrentGroup] = useState<string | undefined>();
  const [isFlashVisible, setIsFlashVisible] = useState<boolean>(false);
  const [sort, setSort] = useState<SimpleGridSort[]>([]);
  const { baseViews } = useContext(BaseViewsContext);
  const baseView = baseViewOverride ? baseViewOverride : baseViews[dataType];

  const restartGrid = useCallback((noFlash?: boolean) => {
    setGridId(aguid());
    if (noFlash) {
      return;
    }
    setIsFlashVisible(true);
  }, []);

  const groups = baseView
    ? getBaseViewFields(baseView)
        .filter((fv) => fv && fv.type === 'text')
        .map((fv) => fv.field)
    : [];

  useEffect(() => {
    if (columnApiRef) {
      columnApiRef.current = columnApi;
    }
  }, [columnApi, columnApiRef]);

  useEffect(() => {
    if (!sortOverride || !gridApi) {
      return;
    }

    gridApi.applyColumnState({
      state: sortOverride.map((sort) => ({
        colId: sort.field,
        sort: sort.direction,
      })),
      defaultState: { sort: null },
    });

    return () => {
      if (gridApi.isDestroyed()) {
        return;
      }

      gridApi.applyColumnState({
        state: sortOverride.map((sort) => ({
          colId: sort.field,
          sort: sort.direction,
        })),
        defaultState: { sort: null },
      });
    };
  }, [gridApi, sortOverride]);

  return (
    <GridContext.Provider
      value={{
        gridId,
        gridApi: gridApi ? gridApi : internalGridApi,
        setGridApi: setGridApi ? setGridApi : setInternalGridApi,
        columnApi,
        setColumnApi,
        dataType,
        groups,
        currentGroup,
        setCurrentGroup,
        metricFiltering,
        dateScopeOverride,
        gridQueryOverride,
        gridCountQueryOverride,
        gridTotalsQueryOverride,
        baseViewOverride,
        sortOverride,
        titleOverride: titleOverride
          ? titleOverride
          : baseView
            ? baseView.nameAlias
            : undefined,
        sort,
        setSort,
        restartGrid,
      }}
    >
      <div style={{ width: '100%', height: '100%' }}>
        <Flash
          isFlashVisible={isFlashVisible}
          setIsFlashVisible={setIsFlashVisible}
          type="info"
          message="Your search session has expired, you will need to start scrolling from the beginning again. We are sorry for the inconvenience."
        />
        {children}
      </div>
    </GridContext.Provider>
  );
};

export default GridProvider;
