import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import AddScorecardForm from './AddScorecardForm';
import ScorecardsContext from '../../../contexts/ScorecardsContext';
import buildCardLayout from '../../../contextProviders/WidgetGalleryProvider/buildCardLayout';
import findStartingY from '../../../contextProviders/WidgetGalleryProvider/findStartingY';

const AddScorecardFormContainer = ({
  close,
  isOpen,
  setCurrentCanvas,
  startEditing,
  canvasMode,
}: {
  close: () => void;
  isOpen: boolean;
  setCurrentCanvas: React.Dispatch<React.SetStateAction<Canvas>>;
  startEditing: () => void;
  canvasMode: CanvasMode;
}) => {
  const { scorecards } = useContext(ScorecardsContext);

  const [searchText, setSearchText] = useState<string>('');
  const [filteredScorecards, setFilteredScorecards] =
    useState<Scorecards.Scorecard[]>(scorecards);
  const [selectedScorecardId, setSelectedScorecardId] = useState<
    string | undefined
  >();

  const onAddClicked = useCallback(() => {
    if (!selectedScorecardId) {
      return;
    }

    setCurrentCanvas((c) => {
      const newCard = (() => {
        return {
          layout: buildCardLayout(0, findStartingY(c.cards, canvasMode)),
          content: {
            type: 'Scorecard' as 'Scorecard',
            scorecardId: selectedScorecardId,
          },
        };
      })();

      return {
        ...c,
        cards: [...c.cards, newCard],
      };
    });
    startEditing();
    close();
  }, [canvasMode, close, selectedScorecardId, setCurrentCanvas, startEditing]);

  const onSearchTextChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
    },
    [],
  );

  const onScorecardClicked = useCallback((scorecard: Scorecards.Scorecard) => {
    setSelectedScorecardId(scorecard.id);
  }, []);

  useEffect(() => {
    setFilteredScorecards(
      scorecards.filter((s) =>
        s.title.toLowerCase().includes(searchText.toLowerCase()),
      ),
    );
  }, [scorecards, searchText]);

  return (
    <AddScorecardForm
      isOpen={isOpen}
      close={close}
      searchText={searchText}
      selectedScorecardId={selectedScorecardId}
      onSearchTextChanged={onSearchTextChanged}
      filteredScorecards={filteredScorecards}
      onScorecardClicked={onScorecardClicked}
      onAddClicked={onAddClicked}
    />
  );
};

export default AddScorecardFormContainer;
