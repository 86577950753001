import styled from 'styled-components';

const StretchImg = styled.img<{ isEditing: boolean }>`
  width: 100%;
  height: 100%;
  ${(props) =>
    props.isEditing &&
    `
    height: calc(100% - 36px)
  `}
`;

export default StretchImg;
