import { useCallback, useContext, useEffect, useState } from 'react';
import getEntityOptions from './getEntityOptions';
import GqlClientContext from '../../../../../../contexts/GqlClientContext';
import PerformanceWizardContext from '../PerformanceWizardContext';
import useConfirmation from '../useConfirmation';
import usePerformanceMetricDatasets from '../FieldsStep/usePerformanceMetricDatasets';
import DatasetDefinitionsContext from '../../../../../../contexts/DatasetDefinitionsContext';

const useGetDatasetsForEntity = () => {
  const { datasets } = useContext(DatasetDefinitionsContext);

  const getDatasetsForEntity = useCallback(
    (entity: string | undefined) => {
      if (!entity) {
        return [];
      }

      return datasets
        .filter((ds) => ds.fields.some((f) => f.field === entity))
        .map((ds) => ds.type);
    },
    [datasets],
  );

  return getDatasetsForEntity;
};

const useGetIsConfirmationRequired = () => {
  const metricDatasets = usePerformanceMetricDatasets();
  const getDatasetForEntity = useGetDatasetsForEntity();

  const getIsConfirmationRequired = useCallback(
    (newEntity: string) => {
      const newDatasets = getDatasetForEntity(newEntity);
      return metricDatasets.some((oldSet) => !newDatasets.includes(oldSet));
    },
    [getDatasetForEntity, metricDatasets],
  );

  return getIsConfirmationRequired;
};

const useEntityOptions = () => {
  const { wizardState, setWizardState } = useContext(PerformanceWizardContext);
  const { client } = useContext(GqlClientContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [entityOptions, setEntityOptions] = useState<DropdownOption[]>([]);
  const [availableEntities, setAvailableEntities] = useState<
    { entity: string; dataTypes: string[] }[]
  >([]);
  const [entityDataTypeOptions, setEntityDataTypeOptions] = useState<
    DropdownOption[]
  >([]);

  const { getConfirmation, ConfirmationModal } = useConfirmation({
    confirmText: 'Change entity',
    title: 'Update Entity?',
    body: 'By changing the entity, you will clear all selected metrics and fields',
  });
  const getIsConfirmationRequired = useGetIsConfirmationRequired();

  // Get the entities
  useEffect(() => {
    let isActive = true;
    getEntityOptions(client).then((newEntities) => {
      if (isActive) {
        setAvailableEntities(newEntities);
        setIsLoading(false);
      }
    });
    return () => {
      isActive = false;
    };
  }, [client]);

  // Remove any un-found entities from the config
  useEffect(() => {
    if (availableEntities.length === 0) {
      return;
    }
    const selectedEntity = wizardState.config.datasetStep.entity;

    if (
      selectedEntity !== undefined &&
      !availableEntities.map((e) => e.entity).includes(selectedEntity)
    ) {
      setWizardState((s) => ({
        ...s,
        config: {
          ...s.config,
          datasetStep: {
            ...s.config.datasetStep,
            entity: undefined,
            entityDataTypes: [],
            entityWindowDateField: undefined,
          },
          fieldsStep: { fields: [] },
          metricsStep: { metrics: [] },
        },
      }));
    }
  }, [
    availableEntities,
    setWizardState,
    wizardState.config.datasetStep.entity,
  ]);

  // Build entity options
  useEffect(() => {
    const newOptions = availableEntities.map((e) => ({
      value: e.entity,
      label: e.entity,
      isSelected: wizardState.config.datasetStep.entity === e.entity,
      onSelected: async () => {
        const shouldConfirm = getIsConfirmationRequired(e.entity);
        const confirmation = shouldConfirm ? await getConfirmation() : true;
        if (confirmation) {
          setWizardState((s) => ({
            ...s,
            config: {
              ...s.config,
              datasetStep: {
                ...s.config.datasetStep,
                entity: e.entity,
                entityDataTypes:
                  e.dataTypes.length > 1 ? [e.dataTypes[0]] : e.dataTypes,
                entityWindowDateField: undefined,
              },
            },
          }));
        }
      },
    }));

    setEntityOptions(newOptions);
  }, [
    availableEntities,
    getConfirmation,
    getIsConfirmationRequired,
    setWizardState,
    wizardState.config.datasetStep.entity,
  ]);

  // setEntityDataTypeOptions
  useEffect(() => {
    const selectedEntity = wizardState.config.datasetStep.entity;
    const selectedEntityDataTypes =
      wizardState.config.datasetStep.entityDataTypes || [];

    if (!selectedEntity) {
      setEntityDataTypeOptions([]);
      return;
    }

    const dataTypesForEntity = availableEntities.find(
      (e) => e.entity === selectedEntity,
    );
    if (!dataTypesForEntity) {
      setEntityDataTypeOptions([]);
      return;
    }

    const newOptions = dataTypesForEntity.dataTypes.map((dataType) => {
      const isSelected = selectedEntityDataTypes.includes(dataType);
      const newDataTypesOnSelected = isSelected
        ? selectedEntityDataTypes.filter((dt) => dt !== dataType)
        : [...selectedEntityDataTypes, dataType];

      return {
        value: dataType,
        label: dataType,
        isSelected: selectedEntityDataTypes.includes(dataType),
        onSelected: () => {
          setWizardState((s) => ({
            ...s,
            config: {
              ...s.config,
              datasetStep: {
                ...s.config.datasetStep,
                entityDataTypes: newDataTypesOnSelected,
                entityWindowDateField: undefined,
              },
            },
          }));
        },
      };
    });
    setEntityDataTypeOptions(newOptions);
  }, [
    availableEntities,
    setWizardState,
    wizardState.config.datasetStep.entity,
    wizardState.config.datasetStep.entityDataTypes,
  ]);

  return {
    entityOptions,
    entityDataTypeOptions,
    isLoading,
    ConfirmationModal,
  };
};

export default useEntityOptions;
