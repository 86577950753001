import { useCallback, useContext, useEffect, useState } from 'react';
import TargetListContext from '../contexts/TargetListContext';

const useTargetsForField = (field: string) => {
  const { targets, dataset } = useContext(TargetListContext);
  const getTargetsForField = useCallback(() => {
    return targets.filter((t) => t.target === field && t.dataType === dataset);
  }, [dataset, field, targets]);
  const [targetsForField, setTargetsForField] = useState<
    Targets.Wizard.DataTypeTarget[]
  >(() => getTargetsForField());

  useEffect(() => {
    setTargetsForField(getTargetsForField());
  }, [getTargetsForField]);

  return targetsForField;
};

export default useTargetsForField;
