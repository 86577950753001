import React from 'react';
import usePopup from '../../hooks/usePopup';
import MetricPickerPopup from '../Inputs/MetricPicker/MetricPickerPopup';
import Button from 'kingpin/atoms/Button';

const AddRankingMetricButton = ({
  onMetricAdded,
  groupByField,
}: {
  onMetricAdded: (metric: Metrics.Metric) => void;
  groupByField?: string;
}) => {
  const { isOpen, open, close } = usePopup();

  return (
    <div>
      <Button
        onClick={open}
        type="Secondary"
        size="Small"
        label="Add Metric"
        icon="add"
      />
      <MetricPickerPopup
        isOpen={isOpen}
        close={close}
        setSelectedMetric={onMetricAdded}
        datasetMustIncludeField={groupByField}
      />
    </div>
  );
};

export default AddRankingMetricButton;
