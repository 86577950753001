/* eslint-disable no-console */
import { ApisauceInstance, create } from 'apisauce';
import firebase from 'firebase/compat/app';
import 'firebase/auth';
import env from '../.env.json';

import captureException, { addBreadCrumb } from './captureException';
import { AUTH_ERROR_MSG_KEY } from '../screens/Login/ErrorBanner';

const buildCloudFunctionClient = ({
  accountId,
  buildNumber,
  region,
  isApiGateway,
}: {
  accountId?: string;
  buildNumber?: number;
  region: string;
  isApiGateway?: boolean;
}) => {
  const api = create({
    baseURL: isApiGateway
      ? env.API_GATEWAY_URL
      : env.USE_LOCAL_FUNCTIONS
        ? `http://localhost:5000/${env.PROJECT_ID}/${region}`
        : `https://${region}-${env.PROJECT_ID}.cloudfunctions.net`,
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      'x-account-id': accountId,
      'x-build-number': buildNumber,
    },
  });
  addProblemMonitor(api);
  setAuthorizationToken(api);
  return api;
};

export const addProblemMonitor = (api: ApisauceInstance) => {
  api.addMonitor((response) => {
    if (
      (response.status === 401 &&
        response.data.error === 'Unauthenticated: Token revoked') ||
      (response.status === 403 &&
        response.data.error ===
          'Forbidden: Session identity provider not permitted')
    ) {
      const errorMessage =
        'Your organization has enabled SSO. Please sign in with Microsoft';
      window.localStorage.setItem(AUTH_ERROR_MSG_KEY, errorMessage);
      firebase.auth().signOut();

      return;
    }

    if (response.problem) {
      if (response.data && response.data.errorMessageWithGuid) {
        addBreadCrumb({
          type: 'error',
          message: 'errorMessageWithGuid',
          data: response.data.errorMessageWithGuid,
        });
      }

      addBreadCrumb({
        type: 'error',
        message: 'response',
        data: response,
      });
      if (response.config) {
        const newError = new Error(
          `${response.problem} at ${response.config.url}`,
        );
        newError.name = `${response.config.method} ${response.config.url}: ${response.status}`;

        captureException(newError);
      }
    }
  });
};

export const setAuthorizationToken = (api: ApisauceInstance) => {
  api.addAsyncRequestTransform((request) => async () => {
    const user = firebase.auth().currentUser;
    if (user) {
      const token = await user.getIdToken();
      const authHeader = `Bearer ${token}`;
      request.headers.authorization = authHeader;
    }
  });
};

export default buildCloudFunctionClient;
