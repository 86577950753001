import React, { useCallback, useEffect, useState } from 'react';
import useAlreadyInUseOptions from './useAlreadyInUseOptions';

const useFilteredOptions = (options: ExclusiveDropdownOption[]) => {
  const [searchText, setSearchText] = useState<string>('');
  const onSearchTextChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
    },
    [],
  );

  const getFilteredOptions = useCallback((): ExclusiveDropdownOption[] => {
    if (searchText === '') {
      return options;
    }

    return options.filter((o) =>
      o.label.toLowerCase().includes(searchText.toLowerCase()),
    );
  }, [options, searchText]);
  const [filteredOptions, setFilteredOptions] = useState<
    ExclusiveDropdownOption[]
  >(() => getFilteredOptions());
  useEffect(() => {
    setFilteredOptions(getFilteredOptions());
  }, [getFilteredOptions]);

  const { alreadyInUseOptions, notAlreadyInUseOptions } =
    useAlreadyInUseOptions(filteredOptions);

  return {
    alreadyInUseOptions,
    notAlreadyInUseOptions,
    searchText,
    onSearchTextChanged,
  };
};

export default useFilteredOptions;
