import { DateTime } from 'luxon';
import getNumberOfDays from './getNumberOfDays';

const getCalendarComparison = (
  d: DateRangeInput,
  comparison: PersistedComparisonType,
  interval: FleetOps.Interval | undefined,
  relativeDateRange?: RelativeDateRange,
): DateRangeInput => {
  const isHourly = interval === 'hour';
  const baseDateRange = (() => {
    const s = d.startDate ? DateTime.fromISO(d.startDate) : DateTime.local();
    const e = d.endDate
      ? isHourly
        ? DateTime.fromISO(d.endDate).plus({
            hours: 23,
            minutes: 59,
            seconds: 59,
          })
        : DateTime.fromISO(d.endDate)
      : undefined;

    if (relativeDateRange) {
      if (comparison.compareType === 'previous') {
        const isSingleInterval =
          relativeDateRange.n === 1 || relativeDateRange.type === 'current';
        // Special cases:
        // Month
        if (relativeDateRange.interval === 'month' && isSingleInterval) {
          if (e) {
            return {
              startDate: s.minus({ month: 1 }),
              endDate: e.minus({ month: 1 }),
            };
          } else {
            return {
              startDate: s.minus({ month: 1 }),
            };
          }
        }
        // Week
        if (relativeDateRange.interval === 'week' && isSingleInterval) {
          if (e) {
            return {
              startDate: s.minus({ week: 1 }),
              endDate: e.minus({ week: 1 }),
            };
          } else {
            return {
              startDate: s.minus({ week: 1 }),
            };
          }
        }
        // Quarter
        if (relativeDateRange.interval === 'quarter') {
          if (e) {
            return {
              startDate: s.minus({
                quarter: relativeDateRange.n ? relativeDateRange.n : 1,
              }),
              endDate: e.minus({
                quarter: relativeDateRange.n ? relativeDateRange.n : 1,
              }),
            };
          } else {
            return {
              startDate: s.minus({ quarter: 1 }),
            };
          }
        }

        // Year
        if (relativeDateRange.interval === 'year' && isSingleInterval) {
          if (e) {
            return {
              startDate: s.minus({ year: 1 }),
              endDate: e.minus({ year: 1 }),
            };
          } else {
            return {
              startDate: s.minus({ year: 1 }),
            };
          }
        }

        const diffDays = getNumberOfDays(d);
        if (e) {
          return {
            startDate: s.minus({ days: diffDays + 1 }),
            endDate: e.minus({ days: diffDays + 1 }),
          };
        } else {
          return {
            startDate: s.minus({ days: diffDays + 1 }),
          };
        }
      }
    }

    if (comparison.compareType === 'previous') {
      const diffDays = getNumberOfDays(d);
      if (e) {
        return {
          startDate: s.minus({ days: diffDays + 1 }),
          endDate: e.minus({ days: diffDays + 1 }),
        };
      } else {
        return {
          startDate: s.minus({ days: diffDays + 1 }),
        };
      }
    } else {
      if (e) {
        return {
          startDate: s.minus({ year: 1 }),
          endDate: e.minus({ year: 1 }),
        };
      } else {
        return {
          startDate: s.minus({ year: 1 }),
        };
      }
    }
  })();

  if (!isHourly) {
    return {
      startDate: baseDateRange.startDate.toISODate(),
      endDate: baseDateRange.endDate
        ? baseDateRange.endDate.toISODate()
        : undefined,
    };
  }

  return {
    startDate: baseDateRange.startDate.toISO(),
    endDate: baseDateRange.endDate ? baseDateRange.endDate.toISO() : undefined,
  };
};

export default getCalendarComparison;
