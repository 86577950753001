import { useCallback, useContext } from 'react';
import LocalTimelineContext from 'contexts/Timeline/LocalTimelineContext';

const useUpdateMetricTimeline = () => {
  const { addEvent } = useContext(LocalTimelineContext);

  const updateMetricTimeline = useCallback(
    async ({
      metricDraft,
      oldFilterDescription,
      newFilterDescription,
    }: {
      metricDraft: Metrics.NormalMetric | Metrics.CompoundMetric | undefined;
      oldFilterDescription: string | undefined;
      newFilterDescription: string | undefined;
    }) => {
      if (!addEvent || !metricDraft) {
        return;
      }

      const hasOldFilterDesc =
        !!oldFilterDescription && oldFilterDescription !== '';
      const hasNewFilterDesc =
        !!newFilterDescription && newFilterDescription !== '';

      const contentText = (() => {
        if (hasOldFilterDesc && hasNewFilterDesc) {
          return `from "${oldFilterDescription}" to "${newFilterDescription}"`;
        } else if (!hasOldFilterDesc && hasNewFilterDesc) {
          return `from no filter to "${newFilterDescription}"`;
        } else if (hasOldFilterDesc && !hasNewFilterDesc) {
          return `from "${oldFilterDescription}" to no filter`;
        } else {
          return undefined;
        }
      })();

      await addEvent({
        actionText: 'Saved changes',
        contextText: `on "${metricDraft.name}"`,
        contentText,
      });
    },
    [addEvent],
  );
  return updateMetricTimeline;
};

export default useUpdateMetricTimeline;
