import useMetric from './useMetric';
import { useCallback, useEffect, useState } from 'react';

const useV5FixedTrendCardDefinition = (chartDef: V5ChartDefinition) => {
  const getDef = useCallback(() => {
    return {
      ...chartDef,
      dimensionA: undefined,
      dimensionB: undefined,
      series: [chartDef.series[0]],
      trendByCalendarInterval: 'month' as 'month',
      trendByFixedIntervalDays: undefined,
    };
  }, [chartDef]);
  const [def, setDef] = useState<V5ChartDefinition>(() => getDef());
  useEffect(() => {
    setDef(getDef());
  }, [getDef]);
  const metricId = chartDef.series[0].metricId;
  const metric = useMetric(metricId);

  if (!metric) {
    throw new Error(`Metric ${metricId} not found`);
  }

  return {
    fixedTrendCardDefinition: def,
  };
};

export default useV5FixedTrendCardDefinition;
