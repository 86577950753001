import React from 'react';
import styled from 'styled-components';
import Colors2 from '../../../theme/Colors2';
import Typography from 'kingpin/atoms/Typography';
import Icon from '../../../kingpin/atoms/Icon';

const ContentDiv = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const DiffBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
`;

const BlockWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1;

  width: 400px;
`;

const Block = styled.div`
  background-color: ${Colors2.Grey['9']};
  padding: 16px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  gap: 4px;
  flex: 1;
  white-space: nowrap;
`;

const IconCircle = styled.div<{ iconBackgroundColor: string; size: number }>`
  border-radius: ${(props) => props.size / 2}px;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  background-color: ${(props) => props.iconBackgroundColor};
  display: flex;
`;

export const ArrowIcon = ({
  textColor,
  iconBackgroundColor,
  icon,
  size = 16,
}: {
  textColor: string;
  iconBackgroundColor: string;
  icon: Kingpin.Icon;
  size?: number;
}) => (
  <IconCircle iconBackgroundColor={iconBackgroundColor} size={size}>
    <Icon icon={icon} color={textColor} height={size} width={size} />
  </IconCircle>
);

const ExclusionAlert = ({
  excludedDateRangeLabels,
}: {
  excludedDateRangeLabels: string[];
}) => {
  if (excludedDateRangeLabels.length === 0) {
    return null;
  }

  return (
    <AlertDiv>
      <div style={{ marginBottom: 4 }}>
        <Typography.Body type="Body 12">
          Excluded from the average calculation:
        </Typography.Body>
      </div>
      {excludedDateRangeLabels.map((label) => (
        <div key={label}>
          <Typography.Body type="Annotation">{label}</Typography.Body>
        </div>
      ))}
    </AlertDiv>
  );
};

const AlertDiv = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;

  border-radius: 8px;
  border: 1px solid #dedede;
`;

const DiffTooltip = ({
  textColor,
  iconBackgroundColor,
  icon,
  formatted,
  actualDateRange,
  actualDayOfWeekHeading,
  avgDateRange,
  avgDayOfWeekHeading,
  excludedDateRangeLabels,
}: {
  textColor: string;
  iconBackgroundColor: string;
  icon: Kingpin.Icon;
  formatted: {
    diff: string;
    diffPercentage: string;
    actual: string;
    avg: string;
  };
  actualDateRange: string;
  actualDayOfWeekHeading?: string;
  avgDateRange: string;
  avgDayOfWeekHeading?: string;
  excludedDateRangeLabels: string[];
}) => {
  return (
    <ContentDiv>
      <DiffBlock>
        <ArrowIcon
          iconBackgroundColor={iconBackgroundColor}
          icon={icon}
          textColor={textColor}
        />
        <Typography.Header type="H3" color={textColor}>
          {`${formatted.diff} ${formatted.diffPercentage}`}
        </Typography.Header>
      </DiffBlock>
      <BlockWrapper>
        <Block>
          {actualDayOfWeekHeading && (
            <Typography.Header type="H6">
              {actualDayOfWeekHeading}
            </Typography.Header>
          )}
          <Typography.Header type="H4">{formatted.actual}</Typography.Header>
          <Typography.Header type="H6" color={Colors2.Grey['4']}>
            {actualDateRange}
          </Typography.Header>
        </Block>
        <Typography.Header type="H6" color={Colors2.Grey['4']}>
          VS
        </Typography.Header>
        <Block>
          {avgDayOfWeekHeading && (
            <Typography.Header type="H6">
              {avgDayOfWeekHeading}
            </Typography.Header>
          )}
          <Typography.Header type="H4">{formatted.avg}</Typography.Header>
          <Typography.Header type="H6" color={Colors2.Grey['4']}>
            {avgDateRange}
          </Typography.Header>
        </Block>
      </BlockWrapper>
      <ExclusionAlert excludedDateRangeLabels={excludedDateRangeLabels} />
    </ContentDiv>
  );
};

export default DiffTooltip;
