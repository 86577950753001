import React from 'react';

interface ReportDrillDownContextType {
  isOpen: boolean;
}

const ReportDrillDownContext = React.createContext({
  isOpen: false,
} as ReportDrillDownContextType);

export default ReportDrillDownContext;
