import React from 'react';
import GeneralGoalProvider from './GeneralGoalProvider';
import ErrorBoundary from '../../components/Common/ErrorBoundary';
import visTypeCheckers from '../../types/visTypeCheckers';

const GoalProvider = ({
  goal,
  children,
  isList,
}: {
  goal: GeneralGoal;
  children: JSX.Element | JSX.Element[];
  isList?: boolean;
}) => {
  if (visTypeCheckers.isGeneralGoal(goal)) {
    return (
      <ErrorBoundary>
        <GeneralGoalProvider goal={goal} isList={isList}>
          {children}
        </GeneralGoalProvider>
      </ErrorBoundary>
    );
  } else {
    return null;
  }
};

export default GoalProvider;
