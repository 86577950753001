import React, { useCallback, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import Button from 'kingpin/atoms/Button';

import STORE from 'store';
import getIdentifier from 'getIdentifier';
import getTimeStamp from 'getTimeStamp';
import Tooltip from 'components/Tooltip';
import addTimelineEvent from 'contextProviders/TimelineProvider/addTimelineEvent';
import CurrentUserContext from 'contexts/CurrentUserContext';
import DatasetDefinitionsContext from 'contexts/DatasetDefinitionsContext';
import GqlClientContext from 'contexts/GqlClientContext';
import AccountPickerContext from 'contexts/AccountPickerContext';

import createPerformanceDatasetConfig from './createPerformanceDatasetConfig';

import PerformanceWizardContext from './PerformanceWizardContext';

import toPersistedPerformanceMetric from './toPersistedPerformanceMetric';

const CreateButton = () => {
  const { datasets, performanceConfigs, refreshPerformanceConfigs } =
    useContext(DatasetDefinitionsContext);
  const { client } = useContext(GqlClientContext);
  const currentUser = useContext(CurrentUserContext);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const [isValid, setIsValid] = useState<boolean>(false);
  const { wizardState, setIsSaving, isReadOnly, isSaving, close } = useContext(
    PerformanceWizardContext,
  );

  const getCreateArgs = useCallback(():
    | PerformanceConfiguration.CreatePerformanceDatasetArgs
    | undefined => {
    const {
      name,
      entity,
      entityDataTypes,
      entityWindowLength,
      entityWindowDateField,
      interval,
      intervalLength,
      startDate,
      startOfWeek,
    } = wizardState.config.datasetStep;
    const { metrics } = wizardState.config.metricsStep;
    const { fields } = wizardState.config.fieldsStep;

    const isNameUnique =
      !datasets.some((ds) => ds.name === name) &&
      !datasets.some((ds) => ds.type === name) &&
      !performanceConfigs.some((config) => config.name === name);

    if (
      !!name &&
      name !== '' &&
      !!entity &&
      entity !== '' &&
      !!interval &&
      !!intervalLength &&
      !!startDate &&
      startDate !== '' &&
      metrics.length > 0 &&
      isNameUnique
    ) {
      return {
        name: name.replaceAll('  ', ' '),
        entity,
        entityWindowDateField,
        entityWindowLength,
        entityDataTypes: entityDataTypes || [],
        startOfWeek,
        startDate,
        interval,
        intervalLength,
        metrics: metrics.map(toPersistedPerformanceMetric),
        fields,
      };
    }

    return undefined;
  }, [
    datasets,
    performanceConfigs,
    wizardState.config.datasetStep,
    wizardState.config.fieldsStep,
    wizardState.config.metricsStep,
  ]);

  useEffect(() => {
    setIsValid(getCreateArgs() !== undefined);
  }, [getCreateArgs]);

  const createTimelineEvent = useCallback(
    async (destination: Timeline.EventSource) => {
      const event = {
        id: getIdentifier(),
        timelineableId: destination.id,
        timelineableType: destination.type,
        // The timestamp of the perf doc is set in GQL. Let's push this up a
        // little to ensure the LocalTimeline finds this event correctly
        createdOn: moment(getTimeStamp()).add({ minute: 2 }).toISOString(),
        createdBy: currentUser.id,
        userId: currentUser.id,
        headlineText: {
          userDisplayName: currentUser.displayName,
          actionText: 'Created the dataset',
          contextText: `"${wizardState.config.datasetStep.name}"`,
        },
      };

      return addTimelineEvent(event, selectedAccountId);
    },
    [
      currentUser.displayName,
      currentUser.id,
      selectedAccountId,
      wizardState.config.datasetStep.name,
    ],
  );

  const onCreateClicked = useCallback(async () => {
    if (isSaving || wizardState.isEditing) {
      return;
    }
    const createArgs = getCreateArgs();
    if (!createArgs) {
      return;
    }

    setIsSaving(true);
    const id = await createPerformanceDatasetConfig({
      client,
      createArgs,
    });
    const timelineEventDestination = {
      type: 'PerformanceDataset' as 'PerformanceDataset',
      id,
    };
    await createTimelineEvent(timelineEventDestination);

    await STORE.savedProgresses
      .getPerformanceDatasetsRef({
        accountId: selectedAccountId,
      })
      .doc(wizardState.firestoreId)
      .delete();

    await refreshPerformanceConfigs();
    setIsSaving(false);
    close();
  }, [
    client,
    close,
    createTimelineEvent,
    getCreateArgs,
    isSaving,
    refreshPerformanceConfigs,
    selectedAccountId,
    setIsSaving,
    wizardState.firestoreId,
    wizardState.isEditing,
  ]);

  if (wizardState.isEditing || isReadOnly) {
    return null;
  }

  return (
    <Tooltip content={isValid ? undefined : 'Invalid inputs'}>
      <Button
        onClick={onCreateClicked}
        label="Create dataset"
        isDisabled={isSaving || !isValid}
        type="Primary"
        size="Small"
      />
    </Tooltip>
  );
};

export default CreateButton;
