import { DateTime } from 'luxon';

export const MOCK_TIMESTAMP = '2042-01-03';

const getTimeStamp = () => {
  if (process.env.NODE_ENV === 'test') {
    return MOCK_TIMESTAMP;
  }

  return DateTime.utc().toISO();
};

export default getTimeStamp;
