import formatFloat from '../../../api/getChartSeries/formatFloat';

const getComparison = ({
  value,
  previous,
  formatting,
  type,
}: {
  value: number;
  previous: number;
  formatting: MetricFormatting;
  type: MatrixCellType;
}): { delta: number; isGood: boolean } => {
  const { positiveDeltaIsGood, precision } = formatting;
  const cF = formatFloat(value, precision);
  const pF = formatFloat(previous, precision);
  const rawDelta = type === 'percentDelta' ? ((cF - pF) / pF) * 100 : cF - pF;
  const delta = formatFloat(rawDelta, 2);
  const isGood = positiveDeltaIsGood ? delta >= 0 : delta <= 0;

  return {
    delta,
    isGood,
  };
};

export default getComparison;
