import React, { useEffect, useState } from 'react';
import Inputs from '../../../../components/Inputs';

const GeoPointFormatInput = ({
  fieldView,
  updateView,
}: {
  fieldView: FleetOps.BaseViewField;
  updateView: (newView: FleetOps.BaseViewField) => void;
}) => {
  const [options, setOptions] = useState<DropdownOption[]>([]);
  useEffect(() => {
    setOptions([
      {
        label: 'long, lat',
        onSelected: () => {
          const newView = {
            ...fieldView,
            geoPointDisplay: 'long, lat' as 'long, lat',
          };
          updateView(newView);
        },
        isSelected: fieldView.geoPointDisplay === 'long, lat',
      },
      {
        label: 'lat, long',
        onSelected: () => {
          const newView = {
            ...fieldView,
            geoPointDisplay: 'lat, long' as 'lat, long',
          };
          updateView(newView);
        },
        isSelected: fieldView.geoPointDisplay === 'lat, long',
      },
    ]);
  }, [fieldView, updateView]);

  return <Inputs.Dropdown options={options} placeholder="Lat Long Order" />;
};

export default GeoPointFormatInput;
