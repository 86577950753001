import React from 'react';
import Typography from 'kingpin/atoms/Typography';
import Row from 'components/Common/Row';
import Button from 'kingpin/atoms/Button';

import StepContent, { StepContentBody } from '../StepContent';
import AddPerformanceMetricButton from './AddPerformanceMetricButton';
import PerformanceMetricsList from './PerformanceMetricsList';
import SaveAndCloseButton from '../SaveAndCloseButton';

const MetricsStep = ({
  metrics,
  intervalLabel,
  goToNextStep,
  goToPreviousStep,
  isNextStepEnabled,
  isSaving,
  isReadOnly,
}: {
  metrics: PerformanceConfiguration.Client.PerformanceMetric[];
  intervalLabel: string;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  isNextStepEnabled: boolean;
  isSaving: boolean;
  isReadOnly: boolean;
}) => (
  <StepContent>
    <StepContentBody>
      <Typography.Header type="H4">Dataset Metrics</Typography.Header>
      {metrics.length === 0 && (
        <div>
          <Typography.Body type="Body 12">
            {`Get records every ${intervalLabel} from your metrics by adding them to the dataset`}
          </Typography.Body>
          <AddPerformanceMetricButton label="Select A Metric" />
        </div>
      )}
      {metrics.length !== 0 && (
        <>
          <Row spaceBetween centerAlign style={{ marginBottom: 8 }}>
            <Typography.Body type="Body 12">
              {`Get records every ${intervalLabel} from your metrics by adding them to the dataset`}
            </Typography.Body>
            <AddPerformanceMetricButton label="Add Additional Metric" />
          </Row>
          <PerformanceMetricsList isReadOnly={isReadOnly} />
        </>
      )}
    </StepContentBody>
    <Row spaceBetween>
      <Button
        onClick={goToPreviousStep}
        label="Back"
        type="Tertiary"
        size="Small"
      />
      <Row>
        <SaveAndCloseButton />
        <Button
          onClick={goToNextStep}
          label="Next Step"
          isDisabled={!isNextStepEnabled || isSaving}
          type="Primary"
          size="Small"
        />
      </Row>
    </Row>
  </StepContent>
);

export default MetricsStep;
