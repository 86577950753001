import React, { useCallback, useContext, useEffect } from 'react';
import SideBar from './SideBar';
import getIdentifier from '../../../../../getIdentifier';
import MetricFilteringContext from '../../../../../contexts/MetricFilteringContext';
import usePopup from '../../../../../hooks/usePopup';
import { UnsavedChangesConfirmation } from '../../../../../components/ConfirmationModal';
import getMetricListItemId from '../getMetricListItemId';
import AnalyticsContext from '../../../../../contexts/AnalyticsContext';

const SideBarContainer = ({
  selectedMetric,
  setSelectedMetric,
  setId,
  mode,
  hasUnsavedChanges,
}: {
  selectedMetric?: Metrics.NormalMetric | Metrics.CompoundMetric;
  setSelectedMetric: React.Dispatch<
    React.SetStateAction<
      Metrics.NormalMetric | Metrics.CompoundMetric | undefined
    >
  >;
  setId: React.Dispatch<React.SetStateAction<string>>;
  mode: 'Metric' | 'CompoundMetric';
  hasUnsavedChanges: boolean;
}) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const {
    isOpen: isNewClickConfirmOpen,
    open: openNewConfirm,
    close: closeNewConfirm,
  } = usePopup();

  const {
    searchText,
    onSearchTextChanged,
    filteredMetrics,
    filteredCompoundMetrics,
    filterPlates,
  } = useContext(MetricFilteringContext);

  const onNewClickedConfirmed = useCallback(() => {
    trackEvent('Data Manager - Create Metric clicked', {
      metricType: mode === 'Metric' ? 'normal' : 'compound',
    });
    if (isNewClickConfirmOpen) {
      trackEvent('Metric Builder - Changes discarded', {
        metricType: mode === 'Metric' ? 'normal' : 'compound',
      });
    }
    setSelectedMetric(undefined);
    setId(getIdentifier());
    closeNewConfirm();
  }, [
    closeNewConfirm,
    isNewClickConfirmOpen,
    mode,
    setId,
    setSelectedMetric,
    trackEvent,
  ]);

  const onNewClicked = useCallback(() => {
    if (hasUnsavedChanges) {
      openNewConfirm();
      return;
    }

    onNewClickedConfirmed();
  }, [hasUnsavedChanges, onNewClickedConfirmed, openNewConfirm]);

  useEffect(() => {
    if (!selectedMetric) {
      return;
    }

    const elem = document.getElementById(getMetricListItemId(selectedMetric));
    if (elem && !!elem.scrollIntoView) {
      elem.scrollIntoView();
    }
    // We only want to do this on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SideBar
        onNewClicked={onNewClicked}
        searchText={searchText}
        onSearchTextChanged={onSearchTextChanged}
        selectedMetric={selectedMetric}
        setSelectedMetric={setSelectedMetric}
        metrics={mode === 'Metric' ? filteredMetrics : filteredCompoundMetrics}
        filterPlates={filterPlates}
        hasUnsavedChanges={hasUnsavedChanges}
      />
      <UnsavedChangesConfirmation
        isOpen={isNewClickConfirmOpen}
        close={closeNewConfirm}
        onConfirmed={onNewClickedConfirmed}
      />
    </>
  );
};

export default SideBarContainer;
