const buildGroupBy = (
  def: V5ChartDefinition,
  isIgnoringLimit?: boolean,
): V5GroupBy | undefined => {
  const { dimensionA, dimensionB, series } = def;
  const sortBySeries = series.find((s) => s.sortByMetricId);
  const sortByMetricId = (() => {
    if (sortBySeries !== undefined) {
      return sortBySeries.metricId;
    }
    return undefined;
  })();

  if (
    dimensionA &&
    (dimensionA.fieldType === 'text' || dimensionA.fieldType === 'boolean')
  ) {
    if (sortByMetricId) {
      return {
        field: dimensionA.field,
        type: dimensionA.fieldType,
        sort: def.groupBySortBy,
        limit: isIgnoringLimit ? undefined : def.groupByLimit,
        sortByMetric: sortByMetricId,
      };
    }

    return {
      field: dimensionA.field,
      type: dimensionA.fieldType,
      sort: def.groupBySortBy,
      limit: isIgnoringLimit ? undefined : def.groupByLimit,
    };
  }

  if (dimensionB && dimensionB.fieldType === 'text') {
    return {
      field: dimensionB.field,
      type: dimensionB.fieldType,
      sort: undefined,
      limit: isIgnoringLimit ? undefined : def.groupByLimit,
      sortByMetric: sortByMetricId,
    };
  }
  return undefined;
};

export default buildGroupBy;
