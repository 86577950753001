import React from 'react';
import styled from 'styled-components';
import Row from 'components/Common/Row';
import Colors2 from 'theme/Colors2';

import useSearchPlaceholder from '../hooks/useSearchPlaceholder';
import GLOBAL_SEARCH from '../constants';
import TextInput from '../../../kingpin/atoms/TextInput';
import Button from '../../../kingpin/atoms/Button';

const SearchBarDiv = styled.div`
  height: ${GLOBAL_SEARCH.TOP_BAR_HEIGHT}px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px -1px 0px 0px #00000014 inset;
  background-color: ${Colors2.Grey['9']};
  padding: 0px 24px;
`;

const SearchBar = ({
  searchText,
  onSearchTextChanged,
  close,
}: {
  searchText: string;
  onSearchTextChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
  close: () => void;
}) => {
  const placeholder = useSearchPlaceholder();

  return (
    <SearchBarDiv>
      <div
        style={{
          marginRight: 8,
        }}
      >
        <Button
          size={'Medium'}
          type={'Secondary'}
          icon={'cross'}
          onClick={close}
        />
      </div>
      <Row centerAlign style={{ width: '100%' }}>
        <TextInput
          value={searchText}
          onChange={onSearchTextChanged}
          placeholder={placeholder}
          data-testid="global-search-input"
          icon="search"
          inputSize="Medium"
          width="100%"
          autoFocus
        />
      </Row>
    </SearchBarDiv>
  );
};

export default SearchBar;
