import { useCallback, useContext, useEffect, useState } from 'react';

import AccountPickerContext from '../../contexts/AccountPickerContext';
import NotificationFactory from '../../NotificationFactory';
import CurrentUserContext from '../../contexts/CurrentUserContext';
import NotificationsContext from '../../contexts/NotificationsContext';
import {
  buildShowWorkSpace,
  buildShowGlobalGoal,
  buildShowGlobalKpiList,
} from '../../navigation/appRoutes';
import setTask from './setTask';
import deleteTask from './deleteTask';
import GlobalTasksContext from '../../contexts/Tasks/GlobalTasksContext';
import { buildTabLink } from 'components/WorkSpaces/ShowTopBarTabs';
import { isTargetsApp } from 'types/workSpaceTypeCheckers';
import { PortalsContext } from '../PortalsProvider';
import AvailableWorkSpacesContext from '../../contexts/AvailableWorkSpacesContext';

const useTasks = (
  sources: Tasks.TaskSource[],
  destination: Tasks.TaskSource | undefined,
) => {
  const { selectedPortal } = useContext(PortalsContext);
  const { tasks, isLoading } = useContext(GlobalTasksContext);
  const { sendNotification } = useContext(NotificationsContext);
  const { availableWorkSpaces } = useContext(AvailableWorkSpacesContext);
  const currentUser = useContext(CurrentUserContext);
  const { accountRef } = useContext(AccountPickerContext);
  const [filteredTasks, setFilteredTasks] = useState<Tasks.Task[]>([]);

  useEffect(() => {
    setFilteredTasks(
      tasks.filter((task) => {
        return sources.some(
          (s) => s.type === task.taskableType && s.id === task.taskableId,
        );
      }),
    );
  }, [sources, tasks]);

  const buildItemLink = useCallback(
    (task: Tasks.Task) => {
      if (task.taskableType === 'Goal') {
        return buildShowGlobalGoal(task.taskableId);
      } else if (task.taskableType === 'Scorecard') {
        return buildShowGlobalKpiList({
          scorecardId: task.taskableId,
          portal: selectedPortal,
        });
      } else if (task.taskableType === 'WorkSpace') {
        const workSpace = availableWorkSpaces.find(
          (ws) => ws.id === task.taskableId,
        );
        if (!workSpace) {
          return buildShowWorkSpace('');
        }

        const tab = (() => {
          if (isTargetsApp(workSpace)) {
            return workSpace.tabs.find((t) => t.type === 'tasks');
          }
          return workSpace.tabs.find((t) => t.type === 'tasks');
        })();

        return buildTabLink({
          workSpace,
          tab,
          portal: selectedPortal,
        });
      } else {
        if (process.env.NODE_ENV === 'development') {
          const error = new Error();
          error.name = `Unknown type: ${task.taskableType}`;
          throw error;
        }
        return undefined;
      }
    },
    [availableWorkSpaces, selectedPortal],
  );

  const sendCreationNotifications = useCallback(
    async (newTask: Tasks.Task) => {
      const link = buildItemLink(newTask);
      if (!link) {
        return;
      }
      const assignedN = NotificationFactory.assignedToTask({
        taskCreatorName: currentUser.displayName,
        link,
        task: newTask,
      });

      await sendNotification(assignedN, newTask.assignedTo);
    },
    [currentUser.displayName, buildItemLink, sendNotification],
  );

  const sendUpdatedNotifications = useCallback(
    async (newTask: Tasks.Task, oldTask: Tasks.Task) => {
      if (newTask.assignedTo === oldTask.assignedTo) {
        return;
      }
      const link = buildItemLink(newTask);
      if (!link) {
        return;
      }
      const assignedN = NotificationFactory.assignedToTask({
        taskCreatorName: currentUser.displayName,
        link,
        task: newTask,
      });
      const unAssignedN = NotificationFactory.unAssignedFromTask({
        link,
        task: newTask,
      });
      await sendNotification(assignedN, newTask.assignedTo);
      await sendNotification(unAssignedN, oldTask.assignedTo);
    },
    [currentUser.displayName, buildItemLink, sendNotification],
  );

  const onTaskDeleted = useCallback(
    async (task: Tasks.Task) => {
      await deleteTask(task, accountRef);
    },
    [accountRef],
  );

  const onTaskCompletedToggled = useCallback(
    async (task: Tasks.Task) => {
      const newTask = {
        ...task,
        isComplete: !task.isComplete,
      };

      await setTask(newTask, accountRef);
    },
    [accountRef],
  );

  const onTaskUpdated = useCallback(
    async (updatedTask: Tasks.Task, oldTask: Tasks.Task) => {
      await setTask(updatedTask, accountRef);
      await sendUpdatedNotifications(updatedTask, oldTask);
    },
    [accountRef, sendUpdatedNotifications],
  );

  const onTaskCreated = useCallback(
    async (newTaskProps: Tasks.TaskCreationProps) => {
      if (!destination) {
        return;
      }
      const newTask = {
        ...newTaskProps,
        taskableType: destination.type,
        taskableId: destination.id,
      };
      await setTask(newTask, accountRef);
      await sendCreationNotifications(newTask);
    },
    [accountRef, destination, sendCreationNotifications],
  );

  return {
    tasks: filteredTasks,
    isLoading,
    onTaskCompletedToggled,
    onTaskUpdated,
    onTaskCreated: destination ? onTaskCreated : undefined,
    onTaskDeleted,
  };
};

export default useTasks;
