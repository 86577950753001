import React, { useCallback, useEffect, useState } from 'react';

const useClearButtons = ({
  filters,
  dataset,
  setFilters,
}: {
  filters: MetricFilters;
  setFilters: React.Dispatch<React.SetStateAction<MetricFilters>>;
  dataset?: string;
}) => {
  const [isClearButtonVisible, setIsClearButtonVisible] =
    useState<boolean>(false);
  useEffect(() => {
    if (dataset !== undefined) {
      setIsClearButtonVisible(
        filters.searchText !== undefined ||
          filters.status !== undefined ||
          filters.isUsed !== undefined,
      );
      return;
    }

    setIsClearButtonVisible(
      filters.searchText !== undefined ||
        filters.status !== undefined ||
        filters.dataset !== undefined ||
        filters.isUsed !== undefined,
    );
  }, [
    dataset,
    filters.dataset,
    filters.isUsed,
    filters.searchText,
    filters.status,
  ]);

  const onClearDatasetsClicked = useCallback(() => {
    setFilters((f) => ({
      ...f,
      dataset: undefined,
    }));
  }, [setFilters]);
  const onClearStatusClicked = useCallback(() => {
    setFilters((f) => ({
      ...f,
      status: undefined,
    }));
  }, [setFilters]);
  const onClearUsageClicked = useCallback(() => {
    setFilters((f) => ({
      ...f,
      isUsed: undefined,
    }));
  }, [setFilters]);

  const onClearFiltersClicked = useCallback(() => {
    if (dataset !== undefined) {
      setFilters({ dataset });
      return;
    }

    setFilters({});
  }, [dataset, setFilters]);

  return {
    isClearButtonVisible,
    onClearDatasetsClicked,
    onClearStatusClicked,
    onClearUsageClicked,
    onClearFiltersClicked,
  };
};

export default useClearButtons;
