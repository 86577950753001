import { useContext } from 'react';
import ReportDrillDownFormContext from 'contexts/ReportDrillDownFormContext';
import drilldownTypeCheckers from '../drilldownTypeCheckers';

const useEditingDrillDown = () => {
  const { drillDown } = useContext(ReportDrillDownFormContext);
  const { editingTextDrillDown, editingWildcardDrillDown } = (() => {
    if (drillDown && drilldownTypeCheckers.isTextDrillDown(drillDown)) {
      return {
        editingTextDrillDown: drillDown,
        editingWildcardDrillDown: undefined,
      };
    }

    if (drillDown && drilldownTypeCheckers.isWildcardDrillDown(drillDown)) {
      return {
        editingTextDrillDown: undefined,
        editingWildcardDrillDown: drillDown,
      };
    }

    return {
      editingTextDrillDown: undefined,
      editingWildcardDrillDown: undefined,
    };
  })();

  return {
    editingTextDrillDown,
    editingWildcardDrillDown,
  };
};

export default useEditingDrillDown;
