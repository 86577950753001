import { useCallback, useContext } from 'react';
import _ from 'lodash';

import DashboardMetricUsageLookupContext from '../../contexts/DashboardMetricUsageLookupContext';

const useGetDashboardGadgetUsage = () => {
  const { dashboardMetricUsageLookup } = useContext(
    DashboardMetricUsageLookupContext,
  );

  const getDashboardGadgetUsage = useCallback(
    (
      metric: Metrics.NormalMetric | Metrics.CompoundMetric,
    ): FleetOps.DashboardGadgetUsage[] => {
      const usage = [] as FleetOps.DashboardGadgetUsage[];
      const dashboardUsage = dashboardMetricUsageLookup[metric.id];
      if (!dashboardUsage) {
        return [];
      }

      dashboardUsage.forEach((dUsage) => {
        const { dashboard, gadgetId, gadgetName, gadgetType } = dUsage;
        usage.push({
          id: `${gadgetId} - ${dashboard.id}`,
          gadgetId,
          gadgetName,
          gadgetType,
          dashboardId: dashboard.id,
          dashboardName: dashboard.name,
        });
      });

      return _.uniqBy(usage, 'id');
    },
    [dashboardMetricUsageLookup],
  );

  return getDashboardGadgetUsage;
};

export default useGetDashboardGadgetUsage;
