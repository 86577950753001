import React from 'react';

interface ReportsContextType {
  allReports: PersistedReportType[];
  availableReports: PersistedReportType[];
  isLoading: boolean;
}
const ReportsContext = React.createContext<ReportsContextType>({
  allReports: [],
  availableReports: [],
  isLoading: true,
});

export default ReportsContext;
