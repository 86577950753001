import React from 'react';

export interface DateInputContextType {
  dateRange?: DateRangeInput; // custom date range input
  relativeDateRange?: RelativeDateRange;
  advancedRelativeDateRange?: AdvancedRelativeDateRange;
  setDateRange: (d: DateRangeInput | undefined) => void;
  setRelativeDateRange: (d: RelativeDateRange | undefined) => void;
  setAdvancedRelativeDateRange: React.Dispatch<
    React.SetStateAction<AdvancedRelativeDateRange | undefined>
  >;
  dateField: string;
  dateFieldName: string;
  setDateField: React.Dispatch<React.SetStateAction<string>>;
  setDateFieldName: React.Dispatch<React.SetStateAction<string>>;
  dataTypes: string[];
  setDataTypes: React.Dispatch<React.SetStateAction<string[]>>;
  recentlyUsedRollingWindows: RecentlyUsedRollingWindow[];
  isManuallyEntered: boolean;
  setIsManuallyEntered: React.Dispatch<React.SetStateAction<boolean>>;
}

const DateInputContext = React.createContext<DateInputContextType>({
  dateField: 'date',
} as DateInputContextType);

export default DateInputContext;
