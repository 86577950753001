import React from 'react';
import Badge from '../../../../components/Badge';

const ErrorBadge = () => (
  <div style={{ marginLeft: 8 }}>
    <Badge text={'Error'} badgeType={'Danger'} />
  </div>
);

export default ErrorBadge;
