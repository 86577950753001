import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import Cell from './V5Gadget/Matrix/Cell';
import ensureNDecimalPlaces from '../hooks/useValueFormatters/ensureNDecimalPlaces';
import formatFloat from '../api/getChartSeries/formatFloat';
import numberToCommaString from '../hooks/useValueFormatters/numberToCommaString';
import { useSettings } from './NumberCell';
import useObfuscator from '../hooks/useObfuscator';

const PercentageCell = (props: ICellRendererParams) => {
  const { value } = props;
  const { precision, isCommasDisabled } = useSettings(props);
  const { isObfuscating, obfuscatedValue } = useObfuscator({
    value: value,
    field: undefined,
    isPercentage: true,
  });

  if (value === undefined || value === null || value === '-') {
    return <Cell rightAlign>-</Cell>;
  }

  const valueToUse = isObfuscating ? obfuscatedValue : value;

  const formatted = `${ensureNDecimalPlaces({
    value: numberToCommaString({
      value: formatFloat(valueToUse, precision),
      isCommasDisabled,
    }),
    precision,
  })}%`;

  return (
    <Cell rightAlign>
      <span>{formatted}</span>
    </Cell>
  );
};

export default PercentageCell;
