import { useContext } from 'react';
import BoardContext from '../contexts/BoardContext';
import BonusPeriodsContext from '../contexts/BonusPeriodsContext';
import WorkSpaceContext from '../contexts/WorkSpaceContext';
import isDriverPerformanceBoard from '../isDriverPerformanceBoard';
import DashboardGadgetContext from '../contexts/DashboardGadgetContext';
import MetricListContext from '../contexts/MetricListContext';

const useSelectedBonusPeriod = () => {
  const { selectedBonusPeriod, bonusPeriods } = useContext(BonusPeriodsContext);
  const { board } = useContext(BoardContext);
  const { workSpace } = useContext(WorkSpaceContext);
  const { dashboardGadget } = useContext(DashboardGadgetContext);
  const { metricList } = useContext(MetricListContext);

  if (board && isDriverPerformanceBoard(board)) {
    return {
      selectedBonusPeriod,
      bonusPeriods,
    };
  } else if (workSpace && workSpace.campaignType === 'driverBonus') {
    return {
      selectedBonusPeriod,
      bonusPeriods,
    };
  } else if (dashboardGadget && dashboardGadget.isBonusPeriodMode) {
    return {
      selectedBonusPeriod,
      bonusPeriods,
    };
  } else if (metricList && metricList.isBonusPeriodMode) {
    return {
      selectedBonusPeriod,
      bonusPeriods,
    };
  } else {
    return {
      selectedBonusPeriod: undefined,
      bonusPeriods: undefined,
    };
  }
};

export default useSelectedBonusPeriod;
