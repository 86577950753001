import React, { useContext, useState } from 'react';
import BaseViewsContext from '../../../contexts/BaseViewsContext';
import CanvasCard from '../../Visualisations/CanvasCard';
import DashboardGadgetBottomBar from '../../DashboardGadgetBottomBar';
import { TopBar } from '../Matrix';
import SimpleGridContent from './SimpleGridContent';
import { GridApi } from 'ag-grid-community';
import DashboardGadgetContext from '../../../contexts/DashboardGadgetContext';

const WrappedTopBar = ({
  chartDefinition,
  gridApi,
}: {
  chartDefinition: V5ChartDefinition;
  gridApi?: GridApi;
}) => {
  if (gridApi) {
    return (
      <TopBar
        exportName={chartDefinition.name}
        gridApi={gridApi}
        title={chartDefinition.name}
      />
    );
  } else {
    return null;
  }
};

const SimpleGridContainer = ({
  chartDefinition,
  simpleGridConfig,
  parentBaseView,
}: {
  chartDefinition: V5ChartDefinition;
  simpleGridConfig: SimpleGridConfig;
  parentBaseView: FleetOps.BaseView;
}) => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const { dashboardGadget } = useContext(DashboardGadgetContext);

  return (
    <CanvasCard
      topBar={
        <WrappedTopBar chartDefinition={chartDefinition} gridApi={gridApi} />
      }
      content={
        <SimpleGridContent
          simpleGridConfig={simpleGridConfig}
          parentBaseView={parentBaseView}
          chartDefinition={chartDefinition}
          gridApi={gridApi}
          setGridApi={setGridApi}
        />
      }
      bottomBar={dashboardGadget ? <DashboardGadgetBottomBar /> : undefined}
      isContentWithoutPadding
    />
  );
};

const Gate = ({ chartDefinition }: { chartDefinition: V5ChartDefinition }) => {
  const { simpleGridConfig } = chartDefinition;
  const { baseViews } = useContext(BaseViewsContext);

  if (!simpleGridConfig) {
    return null;
  }

  const parentBaseView = baseViews[simpleGridConfig.dataType];
  if (!parentBaseView || simpleGridConfig.fields.length === 0) {
    return null;
  }
  return (
    <SimpleGridContainer
      chartDefinition={chartDefinition}
      simpleGridConfig={simpleGridConfig}
      parentBaseView={parentBaseView}
    />
  );
};

export default Gate;
