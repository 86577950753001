import React, { useCallback, useContext, useEffect, useState } from 'react';
import TargetBands from './TargetBands';
import TargetBandsForm from '../TargetBandsForm';
import ScorecardContext from '../../../../../contexts/ScorecardContext';
import { ScoringBandColors } from '../../../../../screens/ScorecardsIndex/constants';
import useTargetBandsLabels from '../hooks/useTargetBandsLabels';
import onRemoveClicked from '../onRemoveClicked';

const TargetBandsContainer = ({
  startDate,
  targetBands,
  targets,
  setTargets,
  isColorOrderReversed,
  onTargetBandSaved,
  isAddingNewTargetBand,
  isEditingDateTarget,
  setIsEditingDateTarget,
  setCanSaveKpi,
  markHasUnsavedChanges,
}: {
  startDate: Scorecards.WeekDate | Scorecards.IsoDate;
  targetBands: string[];
  targets: Scorecards.IsoTargets | Scorecards.WeekTargets;
  setTargets: React.Dispatch<
    React.SetStateAction<Scorecards.IsoTargets | Scorecards.WeekTargets>
  >;
  isColorOrderReversed: boolean;
  onTargetBandSaved: (
    newStartDate: Scorecards.IsoDate | Scorecards.WeekDate,
    newBands: string[],
  ) => void;
  isAddingNewTargetBand: boolean;
  isEditingDateTarget: boolean;
  setIsEditingDateTarget: React.Dispatch<React.SetStateAction<boolean>>;
  setCanSaveKpi: React.Dispatch<React.SetStateAction<boolean>>;
  markHasUnsavedChanges: () => void;
}) => {
  const { scorecard } = useContext(ScorecardContext);
  const { dateLabel } = useTargetBandsLabels(targetBands, targets, startDate);
  const [colors, setColors] = useState<string[]>([]);
  useEffect(() => {
    setColors(ScoringBandColors[scorecard.scoringBandId]);
  }, [scorecard.scoringBandId]);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const onSaved = useCallback(
    (
      newStartDate: Scorecards.IsoDate | Scorecards.WeekDate,
      newBands: string[],
    ) => {
      onTargetBandSaved(newStartDate, newBands);
      setIsEditing(false);
      setIsEditingDateTarget(false);
    },
    [onTargetBandSaved, setIsEditingDateTarget],
  );

  const onCancelled = useCallback(() => {
    setIsEditing(false);
    setIsEditingDateTarget(false);
  }, [setIsEditingDateTarget]);

  const onEditClicked = useCallback(() => {
    setIsEditing(true);
    setIsEditingDateTarget(true);
  }, [setIsEditingDateTarget]);

  const onRemoveClickedCallback = useCallback(() => {
    onRemoveClicked({ setTargets, startDate });
  }, [setTargets, startDate]);

  if (isEditing) {
    return (
      <TargetBandsForm
        targets={targets}
        targetBands={targetBands}
        initialStartDate={startDate}
        isStartDateEditable={
          Object.values(targets.targets).length > 1 ||
          Object.keys(targets.targets)[0] !== scorecard.startDate
        }
        isColorOrderReversed={isColorOrderReversed}
        onCancelled={onCancelled}
        onSaved={onSaved}
        setCanSaveKpi={setCanSaveKpi}
        markHasUnsavedChanges={markHasUnsavedChanges}
      />
    );
  } else {
    return (
      <TargetBands
        colors={colors}
        bands={targetBands}
        scoringBandId={scorecard.scoringBandId}
        isColorOrderReversed={isColorOrderReversed}
        dateLabel={dateLabel}
        onEditClicked={onEditClicked}
        onRemoveClicked={onRemoveClickedCallback}
        isAddingNewTargetBand={isAddingNewTargetBand}
        isEditingDateTarget={isEditingDateTarget}
      />
    );
  }
};

export default TargetBandsContainer;
