import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import * as _ from 'lodash';
import Button from 'kingpin/atoms/Button';
import Typography from 'kingpin/atoms/Typography';

import Colors2 from '../../../../../theme/Colors2';
import Row from '../../../../Common/Row';
import {
  ScoringBandColors,
  getTextColor,
} from '../../../../../screens/ScorecardsIndex/constants';
import Inputs from '../../../../Inputs';
import Dropdown from '../../../../Inputs/Dropdown';
import abbreviateNumber from '../../../../../utils/abbreviateNumber';
import InputError from '../../../../InputError';
import isIsoDate from '../../../../../types/scorecardDates/isIsoDate';
import { KPI_ROW_HEIGHT } from '../../KpiRowRight/constants';

const PLACE_HOLDER_VALUE = '';

export const BAND_ROW_HEIGHT = 42;
export const CELL_PREVIEW_PADDING = 8;

const TargetRowElement = styled(Row)`
  height: ${BAND_ROW_HEIGHT}px;
`;

export const CellPreview = styled.div<{
  onClick?: () => void;
  cellColor: string;
  isTooltip?: boolean;
  isSelected?: boolean;
  showFullBorder?: boolean;
}>`
  height: ${KPI_ROW_HEIGHT}px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: relative;

  background-color: ${(props) => props.cellColor};
  padding: ${CELL_PREVIEW_PADDING}px;

  border-bottom: 1px solid ${Colors2.GridBorder};
  border-right: 1px solid ${Colors2.GridBorder};

  border: ${(props) =>
    props.isSelected
      ? `1px solid ${Colors2.AvatarColors[2].text}`
      : props.showFullBorder
        ? `1px solid ${Colors2.GridBorder}`
        : undefined};

  &:hover {
    border: ${(props) =>
      props.isSelected
        ? `1px solid ${Colors2.AvatarColors[2].text}`
        : '1px solid #B1D9FF'};
  }

  ${(props) =>
    props.onClick &&
    `
      cursor: pointer;
      &:hover {
        span {
          text-decoration: underline;
        }  
      }
    `};
`;

export const Column = styled.div`
  height: ${BAND_ROW_HEIGHT}px;
  width: 150px;
  margin-right: 8px;
  display: flex;
`;

const useTargetHelpers = ({
  scoringBandId,
  bandIndex,
  currentTargetBandInputs,
  setCurrentTargetBandInputs,
}: {
  startDate?: string;
  scoringBandId: Scorecards.ScoringBandId;
  bandIndex: number;
  currentTargetBandInputs: (string | undefined)[];
  setCurrentTargetBandInputs: React.Dispatch<
    React.SetStateAction<(string | undefined)[]>
  >;
}) => {
  const getPreviousBandValue = useCallback(() => {
    if (bandIndex === 0) {
      return undefined;
    }

    return currentTargetBandInputs[bandIndex - 1];
  }, [bandIndex, currentTargetBandInputs]);

  const updateTarget = useCallback(
    (newValue: string) => {
      setCurrentTargetBandInputs((currentBands) => {
        const newBands = [...currentBands];
        newBands[bandIndex] = newValue;
        return newBands;
      });
    },
    [bandIndex, setCurrentTargetBandInputs],
  );

  const buildLabel = useCallback(() => {
    // First
    if (bandIndex === 0) {
      return 'when <=';
    }

    const colors = ScoringBandColors[scoringBandId];
    const previousBandValue = getPreviousBandValue();

    // Last
    if (bandIndex === colors.length - 1) {
      return `when > ${previousBandValue || PLACE_HOLDER_VALUE}`;
    }

    // Between
    return `when > ${previousBandValue || PLACE_HOLDER_VALUE} and <=`;
  }, [bandIndex, getPreviousBandValue, scoringBandId]);

  return {
    updateTarget,
    buildLabel,
  };
};

export const BandPreview = ({
  bands,
  bandIndex,
  scoringBandId,
  isColorOrderReversed,
  isTooltip,
  testId,
}: {
  bands: string[] | (string | undefined)[];
  bandIndex: number;
  scoringBandId: Scorecards.ScoringBandId;
  isColorOrderReversed: boolean;
  isTooltip?: boolean;
  testId?: string;
}) => {
  const [cellLabel, setCellLabel] = useState<string>('');

  const getCurrentBandValue = useCallback(() => {
    return bands[bandIndex];
  }, [bandIndex, bands]);

  const getPreviousBandValue = useCallback(() => {
    if (bandIndex === 0) {
      return undefined;
    }

    return bands[bandIndex - 1];
  }, [bandIndex, bands]);

  const shortenNumber = useCallback((n: string | number) => {
    const abbreviated = abbreviateNumber(
      typeof n === 'string' ? Number.parseFloat(n) : n,
    );
    if (abbreviated) {
      return abbreviated.abbreviated;
    }
    return n;
  }, []);

  const buildIncrement = useCallback((precision: number) => {
    let n = '0.';
    for (let i = 0; i < precision; i++) {
      if (i === precision - 1) {
        n += '1';
      } else {
        n += '0';
      }
    }

    return Number.parseFloat(n);
  }, []);

  const buildIncrementedFromLabel = useCallback(
    (value: string) => {
      const currentBandValue = getCurrentBandValue();

      const currentValuePrecision =
        currentBandValue && currentBandValue.includes('.')
          ? currentBandValue.split('.')[1].length
          : undefined;

      const precision = value.includes('.')
        ? value.split('.')[1].length
        : currentValuePrecision;
      const lhsIncrement = precision ? buildIncrement(precision) : 1;

      const lhs = _.round(Number.parseFloat(value) + lhsIncrement, precision);
      const lhsShortened = abbreviateNumber(lhs);
      return lhsShortened ? lhsShortened.abbreviated : lhs.toString(10);
    },
    [buildIncrement, getCurrentBandValue],
  );

  const buildCellLabel = useCallback(() => {
    const currentBandValue = getCurrentBandValue();
    // First
    if (bandIndex === 0) {
      if (currentBandValue === undefined) {
        return PLACE_HOLDER_VALUE;
      } else {
        return `<= ${shortenNumber(currentBandValue)}`;
      }
    }

    const colors = ScoringBandColors[scoringBandId];
    const previousBandValue = getPreviousBandValue();

    // Last
    if (bandIndex === colors.length - 1) {
      if (previousBandValue === undefined) {
        return PLACE_HOLDER_VALUE;
      } else {
        return `> ${shortenNumber(previousBandValue)}`;
      }
    }

    if (previousBandValue === undefined || currentBandValue === undefined) {
      return PLACE_HOLDER_VALUE;
    }

    // Between
    const lhs = buildIncrementedFromLabel(previousBandValue.toString());
    const rhs = shortenNumber(currentBandValue);
    return `${lhs} - ${rhs}`;
  }, [
    getCurrentBandValue,
    bandIndex,
    scoringBandId,
    getPreviousBandValue,
    buildIncrementedFromLabel,
    shortenNumber,
  ]);

  useEffect(() => {
    setCellLabel(buildCellLabel());
  }, [buildCellLabel]);

  const bandColors = ScoringBandColors[scoringBandId];
  const bandIndexToUse = isColorOrderReversed
    ? bandColors.length - 1 - bandIndex
    : bandIndex;
  const cellColor = bandColors[bandIndexToUse];

  const textColor = getTextColor({ color: cellColor });

  return (
    <CellPreview
      cellColor={cellColor}
      isTooltip={isTooltip}
      data-testid={testId}
      showFullBorder
    >
      <Typography.Body type="Body 12" color={textColor || Colors2.Grey['1']}>
        {cellLabel}
      </Typography.Body>
    </CellPreview>
  );
};

const TargetBandInput = ({
  testId,
  isColorOrderReversed,
  scoringBandId,
  bandIndex,
  currentTargetBandInputs,
  setCurrentTargetBandInputs,
  markHasUnsavedChanges,
  errors,
}: {
  testId: string;
  isColorOrderReversed: boolean;
  scoringBandId: Scorecards.ScoringBandId;
  bandIndex: number;
  currentTargetBandInputs: (string | undefined)[];
  setCurrentTargetBandInputs: React.Dispatch<
    React.SetStateAction<(string | undefined)[]>
  >;
  markHasUnsavedChanges: () => void;
  errors: {
    isVisible: boolean;
    issues: {
      targets: boolean;
      startDate: boolean;
    };
  };
}) => {
  const { updateTarget, buildLabel } = useTargetHelpers({
    scoringBandId,
    bandIndex,
    currentTargetBandInputs,
    setCurrentTargetBandInputs,
  });
  const isLastInput = ScoringBandColors[scoringBandId].length - 1 === bandIndex;
  const [inputValue, setInputValue] = useState<string>(() => {
    if (isLastInput) {
      return '';
    }

    const x = currentTargetBandInputs[bandIndex];
    if (x === undefined) {
      return '';
    }

    return x;
  });
  const [label, setLabel] = useState<string>(() => buildLabel());

  useEffect(() => {
    setLabel(buildLabel());
  }, [buildLabel]);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      markHasUnsavedChanges();
      setInputValue(event.target.value);
      updateTarget(event.target.value);
    },
    [markHasUnsavedChanges, updateTarget],
  );

  return (
    <div style={{ marginBottom: 4 }}>
      <TargetRowElement centerAlign>
        <Column>
          <Typography.Body type="Label" color={Colors2.Grey['5']}>
            {label}
          </Typography.Body>
        </Column>
        {!isLastInput && (
          <Column>
            <Inputs.TextInput
              value={inputValue}
              onChange={onChange}
              type="number"
              data-testid={testId}
              style={{ width: '100%' }}
            />
          </Column>
        )}
        <Column>
          <BandPreview
            bands={currentTargetBandInputs}
            bandIndex={bandIndex}
            scoringBandId={scoringBandId}
            isColorOrderReversed={isColorOrderReversed}
            testId={`${testId}-preview`}
          />
        </Column>
      </TargetRowElement>
      {errors.isVisible &&
        errors.issues.targets &&
        !isLastInput &&
        inputValue === '' && <InputError text="Target is required" />}
    </div>
  );
};

const Actions = ({
  isEditing,
  onSaveClicked,
  onCancelClicked,
}: {
  isEditing: boolean;
  onSaveClicked: () => void;
  onCancelClicked: () => void;
}) => (
  <Row centerAlign>
    <div style={{ marginRight: 8 }}>
      <Button
        onClick={onSaveClicked}
        testId={'manual-add-band'}
        label={isEditing ? 'Update' : 'Add'}
        type="Primary"
        size="Small"
      />
    </div>
    <Button
      label={'Cancel'}
      onClick={onCancelClicked}
      type="Tertiary"
      size="Small"
    />
  </Row>
);

const generateKey = ({
  index,
  startDate,
}: {
  index: number;
  startDate?: Scorecards.WeekDate | Scorecards.IsoDate;
}) => {
  if (!startDate) {
    return `${index}`;
  } else if (isIsoDate(startDate)) {
    return `${index}-${startDate}`;
  } else {
    return `${index}-${startDate.year}-${startDate.week}`;
  }
};

const TargetBandsForm = ({
  isEditing,
  isColorOrderReversed,
  scoringBandId,
  startDate,
  startDateOptions,
  startDateLabel,
  isStartDateEditable,
  currentTargetBandInputs,
  setCurrentTargetBandInputs,
  onSaveClicked,
  onCancelClicked,
  errors,
  markHasUnsavedChanges,
  todayOption,
}: {
  isEditing: boolean;
  isColorOrderReversed: boolean;
  scoringBandId: Scorecards.ScoringBandId;
  startDate?: Scorecards.WeekDate | Scorecards.IsoDate;
  startDateOptions: DropdownOption[];
  startDateLabel?: string;
  isStartDateEditable: boolean;
  currentTargetBandInputs: (string | undefined)[];
  setCurrentTargetBandInputs: React.Dispatch<
    React.SetStateAction<(string | undefined)[]>
  >;
  onSaveClicked: () => void;
  onCancelClicked: () => void;
  errors: {
    isVisible: boolean;
    issues: {
      targets: boolean;
      startDate: boolean;
    };
  };
  markHasUnsavedChanges: () => void;
  todayOption?: DropdownOption;
}) => (
  <div>
    <div style={{ marginBottom: 16 }}>
      <div style={{ marginBottom: 8 }}>
        <Typography.Body type="Label">
          Change cell background according to the following rules:
        </Typography.Body>
      </div>

      {ScoringBandColors[scoringBandId].map((_color, index) => (
        <TargetBandInput
          testId={generateKey({ index, startDate })}
          key={generateKey({ index, startDate })}
          isColorOrderReversed={isColorOrderReversed}
          scoringBandId={scoringBandId}
          bandIndex={index}
          currentTargetBandInputs={currentTargetBandInputs}
          setCurrentTargetBandInputs={setCurrentTargetBandInputs}
          errors={errors}
          markHasUnsavedChanges={markHasUnsavedChanges}
        />
      ))}
      {isStartDateEditable && (
        <Row centerAlign>
          <Column>
            <Typography.Body type="Label" color={Colors2.Grey['5']}>
              Starting from
            </Typography.Body>
          </Column>
          <Column>
            <Dropdown
              unsetWidth
              options={startDateOptions}
              selectedLabel={startDateLabel}
              hasError={errors.isVisible && errors.issues.startDate}
              defaultScrollToLabel={todayOption ? todayOption.label : undefined}
            />
          </Column>
          <Column>
            <Actions
              isEditing={isEditing}
              onSaveClicked={onSaveClicked}
              onCancelClicked={onCancelClicked}
            />
          </Column>
        </Row>
      )}
      {errors.isVisible && errors.issues.startDate && (
        <InputError text="Start date is required" />
      )}
      {!isStartDateEditable && (
        <Row centerAlign>
          {startDate && (
            <Column>
              <div>
                <div>
                  <Typography.Body type="Label" color={Colors2.Grey['5']}>
                    Starting from:
                  </Typography.Body>
                </div>
                <div>
                  <Typography.Body type="Label" color={Colors2.Grey['5']}>
                    {startDateLabel}
                  </Typography.Body>
                </div>
              </div>
            </Column>
          )}

          <Column>
            <Actions
              isEditing={isEditing}
              onSaveClicked={onSaveClicked}
              onCancelClicked={onCancelClicked}
            />
          </Column>
        </Row>
      )}
    </div>
  </div>
);

export default TargetBandsForm;
