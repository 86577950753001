import React, { useCallback, useContext, useState } from 'react';
import { ModalTransition } from '@atlaskit/modal-dialog';

import usePopup from '../../../../hooks/usePopup';
import DuplicatePopup from './DuplicatePopup';
import ViewInfoPopup from './ViewInfoPopup';
import AnalyticsContext from '../../../../contexts/AnalyticsContext';
import ArchiveMetricButton from './ArchiveMetricButton';
import getIdentifier from '../../../../getIdentifier';
import ContextMenu, { Option } from 'kingpin/atoms/ContextMenu';

const ViewInfoButton = ({ openInfoPopup }: { openInfoPopup: () => void }) => {
  return <Option onClick={openInfoPopup} label={'View Info'} icon={'eye'} />;
};

const DuplicateMetricButton = ({
  openDuplicatePopup,
}: {
  openDuplicatePopup: () => void;
}) => {
  return (
    <Option
      onClick={openDuplicatePopup}
      label="Duplicate"
      icon="note-stack-add"
    />
  );
};

const ContentMenu = ({
  metric,
  openMetricPopup,
}: {
  metric: Metrics.NormalMetric | Metrics.CompoundMetric;
  openMetricPopup: (
    metric: Metrics.NormalMetric | Metrics.CompoundMetric,
  ) => void;
}) => {
  const [duplicateId, setDuplicateId] = useState<string>(() => getIdentifier());
  const { trackEvent } = useContext(AnalyticsContext);
  const {
    isOpen: isInfoPopupOpen,
    open: openInfoPopup,
    close: closeInfoPopup,
  } = usePopup();
  const {
    isOpen: isDuplicatePopupOpen,
    open: openDuplicatePopup,
    close: closeDuplicatePopup,
  } = usePopup();

  const onInfoPopupOpened = useCallback(() => {
    openInfoPopup();
    trackEvent('Metrics - View Info clicked', {
      metricType: metric.type,
    });
  }, [metric.type, openInfoPopup, trackEvent]);

  const onDuplicatePopupOpened = useCallback(() => {
    openDuplicatePopup();
    trackEvent('Metrics - Duplicate clicked', {
      metricType: metric.type,
    });
  }, [metric.type, openDuplicatePopup, trackEvent]);

  const onDuplicatePopupClosed = useCallback(() => {
    setDuplicateId(getIdentifier());
    closeDuplicatePopup();
  }, [closeDuplicatePopup]);

  return (
    <>
      <ContextMenu testId={`context-menu-${metric.id}`} placement={'left'}>
        <ViewInfoButton openInfoPopup={onInfoPopupOpened} />
        <DuplicateMetricButton openDuplicatePopup={onDuplicatePopupOpened} />
        <ArchiveMetricButton metric={metric} />
      </ContextMenu>

      <ModalTransition>
        {isInfoPopupOpen && (
          <ViewInfoPopup metric={metric} close={closeInfoPopup} />
        )}
      </ModalTransition>
      <ModalTransition>
        {isDuplicatePopupOpen && (
          <DuplicatePopup
            metric={metric}
            close={onDuplicatePopupClosed}
            duplicateId={duplicateId}
            openMetricPopup={openMetricPopup}
          />
        )}
      </ModalTransition>
    </>
  );
};

export default ContentMenu;
