import React, { useContext, useEffect, useState } from 'react';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import GqlClientContext from '../contexts/GqlClientContext';
import Loading from '../components/Loading';
import buildApolloClient from '../services/buildApolloClient';
import AccountPickerContext from '../contexts/AccountPickerContext';
import VersionContext from '../contexts/VersionContext';
import BoardContext from '../contexts/BoardContext';
import WallboardContext from '../contexts/WallboardContext';
import DashboardContext from '../contexts/DashboardContext';
import ReportContext from '../contexts/ReportContext';
import GoalContext from '../contexts/GoalContext';
import WeekStartsOnOverrideContext from '../contexts/WeekStartsOnOverrideContext';
import AccountContext from '../contexts/AccountContext';
import CurrentUserContext from '../contexts/CurrentUserContext';

const useSource = () => {
  const [source, setSource] = useState<
    'report' | 'dashboard' | 'wallboard' | 'board' | 'goal' | undefined
  >();
  const [sourceId, setSourceId] = useState<string | undefined>();
  const boardContext = useContext(BoardContext);
  const { isWallboardUser, wallBoardId } = useContext(CurrentUserContext);
  const dashboardContext = useContext(DashboardContext);
  const reportContext = useContext(ReportContext);
  const goalContext = useContext(GoalContext);

  useEffect(() => {
    // Order matters here.
    // Content which can be popped up should be checked for earlier on
    if (isWallboardUser) {
      setSource('wallboard');
      setSourceId(wallBoardId);
    } else if (reportContext.report) {
      setSource('report');
      setSourceId(reportContext.report.id);
    } else if (boardContext.board) {
      setSource('board');
      setSourceId(boardContext.board.id);
    } else if (goalContext.goal) {
      setSource('goal');
      setSourceId(goalContext.goal.id);
    } else if (dashboardContext.dashboard) {
      setSource('dashboard');
      setSourceId(dashboardContext.dashboard.id);
    } else {
      setSource(undefined);
      setSourceId(undefined);
    }
  }, [
    boardContext.board,
    dashboardContext.dashboard,
    goalContext.goal,
    isWallboardUser,
    reportContext.report,
    wallBoardId,
  ]);

  return {
    source,
    sourceId,
  };
};

interface GqlClientProviderProps {
  children: JSX.Element | JSX.Element[];
  visId?: string;
  visName?: string;
  visType?: string;
  batchMax?: number;
}

const GqlClientProvider = ({
  children,
  visId,
  visName,
  visType,
  batchMax,
}: GqlClientProviderProps) => {
  const { weekStartsOnOverride } = useContext(WeekStartsOnOverrideContext);
  const { buildNumber } = useContext(VersionContext);
  const { selectedAccount } = useContext(AccountPickerContext);
  const { weekStartsOn, carrierName, timezone } = useContext(AccountContext);
  const { source, sourceId } = useSource();
  const [client, setClient] = useState<
    ApolloClient<NormalizedCacheObject> | undefined
  >();

  useEffect(() => {
    const newClient = buildApolloClient({
      accountId: selectedAccount.accountId,
      buildNumber,
      visId,
      visName,
      visType,
      source,
      sourceId,
      startOfWeek: weekStartsOnOverride ? weekStartsOnOverride : weekStartsOn,
      batchMax,
      carrierName,
      timezone,
    });
    setClient(newClient);
  }, [
    batchMax,
    buildNumber,
    selectedAccount.accountId,
    source,
    sourceId,
    weekStartsOnOverride,
    visId,
    visName,
    weekStartsOn,
    carrierName,
    visType,
    timezone,
  ]);

  if (!client) {
    return <Loading />;
  }

  return (
    <GqlClientContext.Provider value={{ client }}>
      {children}
    </GqlClientContext.Provider>
  );
};

const Gate = (props: GqlClientProviderProps) => {
  const { isWallboard } = useContext(WallboardContext);

  if (isWallboard) {
    return <>{props.children}</>;
  }
  return <GqlClientProvider {...props} />;
};

export default Gate;
