import styled from 'styled-components';
import Colors from '../theme/colors';

const LandingPage = styled.div`
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  background-color: ${Colors.WHITE};
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;

  padding-top: 10vh;
  font-family: Inter, 'Montserrat', sans-serif !important;
  color: ${Colors.WHITE};
  background-size: cover;
`;

export default LandingPage;
