import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Colors2 from '../../theme/Colors2';
import Typography from 'kingpin/atoms/Typography';
import Row from '../../components/Common/Row';
import captureException from '../../services/captureException';
import Button from '../../kingpin/atoms/Button';
import Icon from '../../kingpin/atoms/Icon';

const ErrorBannerDiv = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 0px 8px;
  height: 40px;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${Colors2.Secondary.error};
`;

export const AUTH_ERROR_MSG_KEY = 'auth-error-message';
export const SSO_SETUP_ERROR_MSG_KEY = 'sso-setup-error-message';

const useErrorMessage = (errorKey: string) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    try {
      setErrorMessage(window.localStorage.getItem(errorKey));
      window.localStorage.removeItem(errorKey);
    } catch (ex) {
      captureException(ex);
    }
  }, [errorKey]);

  return errorMessage;
};

const ErrorBanner = ({ errorKey }: { errorKey: string }) => {
  const errorMessage = useErrorMessage(errorKey);
  const [isDismissed, setIsDismissed] = useState<boolean>(false);

  useEffect(() => {
    setIsDismissed(false);
  }, [errorMessage]);

  const dismiss = useCallback(() => {
    setIsDismissed(true);
    window.localStorage.removeItem(AUTH_ERROR_MSG_KEY);
  }, []);

  if (isDismissed || !errorMessage) {
    return null;
  }

  return (
    <ErrorBannerDiv>
      <div />
      <Row centerAlign>
        <div style={{ marginRight: 8, display: 'flex' }}>
          <Icon icon={'warning'} color={'white'} />
        </div>
        <Typography.Header type="H5" color="white">
          {errorMessage}
        </Typography.Header>
      </Row>
      <Button
        size={'Small'}
        type={'Secondary'}
        onClick={dismiss}
        icon="cross"
      />
    </ErrorBannerDiv>
  );
};

export default ErrorBanner;
