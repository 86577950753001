import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import ExportReportButton from './ExportReportButton';
import usePopup from '../../hooks/usePopup';
import ReportContext from '../../contexts/ReportContext';
import GroupedLoadingContext from '../../contexts/GroupedLoadingContext';
import captureException from '../../services/captureException';
import PopupInnerContext from '../../contexts/PopupInnerContext';

const ExportReportButtonContainer = ({
  exportReport,
}: {
  exportReport: (userIds: string[], notes: string) => Promise<void>;
}) => {
  const {
    isOpen: isExportingModalOpen,
    open: openExportingModal,
    close: closeExportingModal,
  } = usePopup();
  const { setIsExporting, isExporting } = useContext(ReportContext);
  const { isPopup } = useContext(PopupInnerContext);
  const GroupedLoading = useContext(GroupedLoadingContext);
  const [exportReportUserIds, setExportReportUserIds] = useState<string[]>([]);
  const [loadingHasBegun, setLoadingHasBegun] = useState<boolean>(false);
  const [notes, setNotes] = useState<string>('');

  const onExport = () => {
    if (!setIsExporting) {
      captureException(new Error(`setIsExporting is not defined`));
      alert('Coming soon...');
      return;
    }

    openExportingModal();
  };

  const startExport = () => {
    if (setIsExporting) {
      GroupedLoading.restartLoading();
      setIsExporting(true);
    }
  };

  const onNotesChanged = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const newNotes = event.target.value;
    setNotes(newNotes);
  };

  useEffect(() => {
    if (
      loadingHasBegun &&
      isExporting &&
      !GroupedLoading.isLoading &&
      setIsExporting
    ) {
      // Give charts time to animate
      setTimeout(() => {
        exportReport(exportReportUserIds, notes).then(() => {
          setExportReportUserIds([]);
          setIsExporting(false);
          setNotes('');
          closeExportingModal();
        });
      }, 1000);
    }
  }, [
    GroupedLoading.isLoading,
    closeExportingModal,
    exportReport,
    exportReportUserIds,
    isExporting,
    loadingHasBegun,
    notes,
    setIsExporting,
  ]);

  useEffect(() => {
    if (isExporting && GroupedLoading.isLoading) {
      setLoadingHasBegun(true);
    }

    if (loadingHasBegun && !GroupedLoading.isLoading) {
      setLoadingHasBegun(false);
    }
  }, [GroupedLoading.isLoading, isExporting, loadingHasBegun]);

  if (isPopup) {
    return null;
  }

  return (
    <ExportReportButton
      onExport={onExport}
      isExporting={!!isExporting}
      startExport={startExport}
      closeExportingModal={closeExportingModal}
      exportReportUserIds={exportReportUserIds}
      isExportingModalOpen={isExportingModalOpen}
      setExportReportUserIds={setExportReportUserIds}
      notes={notes}
      onNotesChanged={onNotesChanged}
    />
  );
};

export default ExportReportButtonContainer;
