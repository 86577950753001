import React, { useContext } from 'react';
import Typography from 'kingpin/atoms/Typography';
import RadioInputV2 from 'components/RadioInputV2';
import Dropdown from 'components/Inputs/Dropdown';
import Button from 'kingpin/atoms/Button';
import Row from 'components/Common/Row';
import DragAndDropList from 'components/DragAndDropList';
import CostsWizardContext from '../../context/CostsWizardContext';
import Colors2 from 'theme/Colors2';
import GroupListItem from '../GroupListItem';
import StepHeader from '../StepHeader';
import { AccordionContent } from '../ConfigSteps';
import { RADIO_OPTIONS, RADIO_OPTIONS_MAP } from '../../consts';
import ConfirmationButton from '../ConfirmationButton';
import GroupStepButtonOverride from './GroupStepButtonOverride';
import Inputs from 'components/Inputs';

const GroupStep = ({
  groupFieldsOptions,
  popupOptions,
  groupNameValues,
  handleAddGroup,
  updateGroupOrder,
  getGroupByName,
  dataType,
  genGroupName,
}: {
  groupFieldsOptions: DropdownOption[];
  popupOptions: ExclusiveDropdownOption[];
  groupNameValues: string[];
  handleAddGroup: () => void;
  updateGroupOrder: (newOrder: string[]) => void;
  getGroupByName: ({ name }: { name: string }) => Costs.Group | undefined;
  dataType: string | undefined;
  genGroupName: ({ fields }: { fields: string[] }) => string;
}) => {
  const { wizardState, setWizardState, isEditing, hasEditPermission } =
    useContext(CostsWizardContext);

  const ConfirmationButtonComponent = ConfirmationButton({
    popupOptions,
    handleUpdate: handleAddGroup,
    isEditing: false,
  });

  const ButtonOverrideComponent = GroupStepButtonOverride({
    label: `Select ${wizardState.config.groupStep.field}(s)`,
    isDisabled: !hasEditPermission,
    setWizardState,
  });

  return (
    <>
      <StepHeader headerStep="group" headerText="2. Groups" />
      <AccordionContent
        isActive={wizardState.currentStep === 'group'}
        style={{
          height: wizardState.currentStep === 'group' ? '544px' : '0',
          transition: 'height: .2s ease-out',
        }}
      >
        <div style={{ padding: '32px' }}>
          <div style={{ marginBottom: '16px' }}>
            <Typography.Header type="H5">Apply my costs</Typography.Header>
          </div>
          <div style={{ marginBottom: '8px' }}>
            <RadioInputV2
              isLarge
              setSelected={(newType) => {
                if (newType === wizardState.config.groupStep.type) {
                  return;
                }
                setWizardState((s) => ({
                  ...s,
                  config: {
                    ...s.config,
                    groupStep: {
                      ...s.config.groupStep,
                      type: newType,
                      groups: [],
                      field: undefined,
                    },
                  },
                }));
              }}
              options={RADIO_OPTIONS.map((option) => ({
                value: option,
                label: `${option} ${wizardState.config.basicStep.interval}`,
                testId: option,
              }))}
              selected={wizardState.config.groupStep.type}
              isDisabled={
                (isEditing && !wizardState.isDraft) || !hasEditPermission
              }
            />
          </div>

          {wizardState.config.groupStep.type ===
            RADIO_OPTIONS_MAP.separately && (
            <div style={{ display: 'inline-block', marginBottom: '32px' }}>
              <Dropdown
                options={groupFieldsOptions}
                placeholder="Select Group"
                isDisabled={
                  (isEditing && !wizardState.isDraft) || !hasEditPermission
                }
              />
            </div>
          )}

          {wizardState.config.groupStep.type === RADIO_OPTIONS[1] &&
            wizardState.config.groupStep.field && (
              <>
                <div style={{ marginBottom: '4px' }}>
                  <Typography.Header type="H5">
                    {`Add ${wizardState.config.groupStep.field}(s) for individual cost setting`}
                  </Typography.Header>
                </div>
                <div style={{ display: 'inline-block' }}>
                  <Inputs.ExclusiveDropdown
                    placeholder={'Add Costs For:'}
                    options={popupOptions}
                    isAlreadyInUseDisabled
                    ConfirmationButton={ConfirmationButtonComponent}
                    ButtonOverride={ButtonOverrideComponent}
                  />
                </div>
                {wizardState.config.groupStep.groups.length !== 0 && (
                  <>
                    <Row centerAlign style={{ marginBottom: '8px' }}>
                      <div style={{ marginRight: 4 }}>
                        <Typography.Header type="H5">
                          Added Costs
                        </Typography.Header>
                      </div>

                      <Typography.Header type="H5" color={Colors2.Grey['4']}>
                        {`(${wizardState.config.groupStep.groups.length})`}
                      </Typography.Header>
                    </Row>
                    <div
                      style={{
                        marginBottom: '24px',
                        maxHeight: '139px',
                        paddingTop: '1px',
                        overflow: 'scroll',
                      }}
                    >
                      <DragAndDropList
                        items={wizardState.config.groupStep.groups.map(
                          (g) => g.groupName,
                        )}
                        onOrderChanged={updateGroupOrder}
                        droppableId="groupId"
                        renderItem={(name) => (
                          <GroupListItem
                            group={getGroupByName({ name })}
                            groupNameValues={groupNameValues}
                            wizardState={wizardState}
                            setWizardState={setWizardState}
                            dataType={dataType}
                            genGroupName={genGroupName}
                            isEditing={isEditing}
                          />
                        )}
                      />
                    </div>
                  </>
                )}
              </>
            )}

          <Row style={{ justifyContent: 'flex-end' }}>
            <Button
              testId="cost-next-group"
              onClick={() => {
                setWizardState((s) => ({
                  ...s,
                  currentStep: 'categories',
                }));
              }}
              label="Next Step"
              type="Tertiary"
              size="Small"
            />
          </Row>
        </div>
      </AccordionContent>
    </>
  );
};

export default GroupStep;
