import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

const buildQuery = ({ dataset }: { dataset: string }) => ({
  query: gql`
    query getTargetEntityFields($dataType: String!) {
      getTargetEntityFields(dataType: $dataType)
    }
  `,
  variables: {
    dataType: dataset,
  },
});

const getTargetEntityFields = async (
  client: ApolloClient<NormalizedCacheObject>,
  dataset: string,
): Promise<string[]> => {
  const query = buildQuery({ dataset });
  const response = await client.query(query);
  return response.data.getTargetEntityFields;
};

export default getTargetEntityFields;
