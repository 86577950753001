import React from 'react';
import styled from 'styled-components';
import Typography from 'kingpin/atoms/Typography';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  height: 270px;
  border-radius: 3px;
  margin-bottom: 16px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  text-align: center;
`;

const EmptyVis = ({ isFieldRemoved }: { isFieldRemoved?: boolean }) => {
  return (
    <Wrapper>
      <Content>
        <Typography.Header type="H5">Preview</Typography.Header>
        <Typography.Body type="Body 12">
          {isFieldRemoved
            ? 'Performance Field was removed. Please select different field'
            : 'No Metrics Added Yet'}
        </Typography.Body>
      </Content>
    </Wrapper>
  );
};

export default EmptyVis;
