import { useEffect, useState } from 'react';
import toSentenceCase from '../../../services/toSentenceCase';
import useRankingUsedMetrics from '../../RankingMatrixForm/useRankingUsedMetrics';
import MetricHeaderCell from '../../MetricHeaderCell';
import { NUMBER_CELL } from '../../Grid/constants';

const useColDefs = (gadget: VisualisationDefinitions.RankingMatrix) => {
  const usedMetrics = useRankingUsedMetrics(gadget.metrics);
  const [colDefs, setColDefs] = useState<MatrixColDef[]>([]);

  useEffect(() => {
    if (usedMetrics.length > 0) {
      const newColDefs = [] as MatrixColDef[];
      const groupDef = {
        headerName: toSentenceCase(gadget.groupByField),
        headerTooltip: gadget.groupByField,
        field: gadget.groupByField,
        cellRenderer: 'groupingCellRenderer',
        suppressHeaderMenuButton: true,
        isLeftAlign: true,
        suppressMovable: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        sort:
          gadget.sorting.mode === 'alphabetical'
            ? gadget.sorting.order
            : undefined,
      };
      newColDefs.push(groupDef);

      gadget.metrics.forEach((rankingMetric) => {
        const actualMetric = usedMetrics.find(
          (m) => m.id === rankingMetric.metricId,
        );
        if (!actualMetric) {
          return;
        }

        const cellRenderer = (() => {
          if (actualMetric.formatting.postfix === 'percentage') {
            return 'percentageCell';
          } else if (actualMetric.formatting.prefix === 'currency') {
            return 'currencyCell';
          }

          return NUMBER_CELL;
        })();

        const displayName = rankingMetric.displayName
          ? rankingMetric.displayName
          : actualMetric.name;
        newColDefs.push({
          headerName: displayName,
          headerTooltip: displayName,
          headerComponent: MetricHeaderCell,
          headerComponentParams: {
            metric: actualMetric,
            rightAlign: true,
            alias: displayName,
          },
          field: rankingMetric.metricId,
          cellRenderer,
          cellRendererParams: {
            fieldView: undefined,
            metric: actualMetric,
          },
          suppressHeaderMenuButton: true,
          headerClass: 'rightAlignedHeader',
          minWidth: displayName.length * 9,
          wrapHeaderText: true,
          autoHeaderHeight: true,
          sort:
            gadget.sorting.mode === 'metric' &&
            gadget.sorting.metricId === rankingMetric.metricId
              ? gadget.sorting.order
              : undefined,
        });

        if (rankingMetric.isRankingEnabled) {
          newColDefs.push({
            headerName: `${displayName} (Rank)`,
            headerTooltip: `${displayName} (Rank)`,
            headerComponent: MetricHeaderCell,
            headerComponentParams: {
              metric: actualMetric,
              rightAlign: true,
              alias: 'Rank',
            },
            field: `${rankingMetric.metricId}_rank`,
            cellRenderer: NUMBER_CELL,
            suppressHeaderMenuButton: true,
            headerClass: 'rightAlignedHeader',
            minWidth: displayName.length * 9,
            wrapHeaderText: true,
            autoHeaderHeight: true,
          });
        }

        if (rankingMetric.isPercentageOfTotalEnabled) {
          newColDefs.push({
            headerName: `${displayName} (% of total)`,
            headerTooltip: `${displayName} (% of total)`,
            headerComponent: MetricHeaderCell,
            headerComponentParams: {
              metric: actualMetric,
              rightAlign: true,
              alias: '% of total',
            },
            field: `${rankingMetric.metricId}_percentage`,
            cellRenderer: 'percentageCell',
            cellRendererParams: {
              fieldView: undefined,
              metric: actualMetric,
            },
            suppressHeaderMenuButton: true,
            headerClass: 'rightAlignedHeader',
            minWidth: displayName.length * 9,
            wrapHeaderText: true,
            autoHeaderHeight: true,
          });
        }
      });

      setColDefs(newColDefs);
    }
  }, [
    gadget.groupByField,
    gadget.metrics,
    gadget.sorting.metricId,
    gadget.sorting.mode,
    gadget.sorting.order,
    usedMetrics,
  ]);

  return {
    colDefs,
  };
};

export default useColDefs;
