import React, { useContext } from 'react';
import WidgetGalleryContext from '../../../contexts/WidgetGalleryContext';
import TextInput from '../../../kingpin/atoms/TextInput';

const SearchBoxContainer = () => {
  const { searchText, setSearchText } = useContext(WidgetGalleryContext);

  return (
    <div style={{ width: 500 }}>
      <TextInput
        value={searchText}
        onChange={(event) => setSearchText(event.target.value)}
        icon="search"
        inputSize="Small"
        placeholder={'Search widgets'}
      />
    </div>
  );
};

export default SearchBoxContainer;
