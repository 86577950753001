import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

const buildQuery = ({ dataset }: { dataset: string }) => ({
  query: gql`
    query getTargets($dataType: String!) {
      getTargets(dataType: $dataType) {
        id
        dataType
        effectiveDate
        cohortFieldName
        cohorts {
          cohortName
          filteredCategorisations {
            categories {
              field
              gte
              lte
              gt
              lt
              outputs {
                fieldName
                fieldType
                fieldValueString
                fieldValueFloat
              }
              trackProgress
            }
            rules {
              field
              condition
              value
            }
          }
        }
        targetType
        categorisationFields {
          fieldNameSuffix
          fieldNameAlias
          fieldType
          possibleStringValues
          possibleFloatValues
        }
        updatedOn
        updatedBy
      }
    }
  `,
  variables: {
    dataType: dataset,
  },
});

const getTargets = async (
  client: ApolloClient<NormalizedCacheObject>,
  dataset: string,
): Promise<Targets.Persisted.DataTypeTarget[]> => {
  const query = buildQuery({ dataset });
  const response = await client.query(query);
  return response.data.getTargets;
};

export default getTargets;
