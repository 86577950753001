import React, { ChangeEvent, useCallback, useContext, useState } from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import Button from 'kingpin/atoms/Button';

import usePopup from '../../../hooks/usePopup';
import GoalsContext from '../../../contexts/GoalsContext';
import Typography from 'kingpin/atoms/Typography';
import Search from '../../Search';
import styled from 'styled-components';

import setWorkSpace from '../../../api/workspaces/setWorkSpace';
import Loading from '../../Loading/Loading';
import AccountPickerContext from '../../../contexts/AccountPickerContext';
import Form from 'kingpin/forms/Form';
import FormHeader from '../../../kingpin/forms/FormHeader';
import FormContent from '../../../kingpin/forms/FormContent';

export const List = styled.div`
  height: 500px;
  overflow-y: auto;
  margin-bottom: 24px;
`;

export const Item = styled.div<{ noHover?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 48px;

  padding-left: 16px;
  padding-right: 8px;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.08);

  ${(props) =>
    !props.noHover &&
    `
    cursor: pointer;
    &:hover {
    background-color: #f2f2f2;
  }`}
`;

const AddGoalPopup = ({
  workSpace,
  close,
}: {
  workSpace: GeneralWorkSpace;
  close: () => void;
}) => {
  const { accountRef } = useContext(AccountPickerContext);
  const { goals } = useContext(GoalsContext);
  const [searchText, setSearchText] = useState<string>('');
  const eligibleGoals = goals.filter(
    (g) =>
      !workSpace.goalIds.includes(g.id) &&
      g.title.toLowerCase().includes(searchText.toLowerCase()),
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const onSearchTextChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };
  const onClearClicked = () => {
    setSearchText('');
  };

  const onGoalSelected = useCallback(
    (goal: GeneralGoal) => {
      if (isLoading) {
        return;
      }
      setIsLoading(true);
      const newWorkSpace = {
        ...workSpace,
        goalIds: [...workSpace.goalIds, goal.id],
      };

      setWorkSpace(newWorkSpace, accountRef).then(() => {
        setIsLoading(false);
        close();
      });
    },
    [accountRef, close, isLoading, workSpace],
  );

  return (
    <Form>
      <FormHeader title={'Goals'} onClose={close} />
      <FormContent>
        <div style={{ marginBottom: 16 }}>
          <Search
            searchText={searchText}
            onSearchTextChanged={onSearchTextChanged}
            onClearClicked={onClearClicked}
          />
        </div>
        <List>
          {isLoading && <Loading />}
          {!isLoading && (
            <>
              {eligibleGoals.map((goal) => (
                <Item key={goal.id} onClick={() => onGoalSelected(goal)}>
                  <Typography.Body type="Link">{goal.title}</Typography.Body>
                </Item>
              ))}
            </>
          )}
        </List>
      </FormContent>
      <Button
        size={'Small'}
        type={'Secondary'}
        label={'Cancel'}
        onClick={close}
      />
    </Form>
  );
};

const AddGoalButtonContainer = ({
  workSpace,
}: {
  workSpace: GeneralWorkSpace;
}) => {
  const { isOpen, open, close } = usePopup();

  return (
    <>
      <Button
        onClick={open}
        label="Add Goal"
        icon="add"
        type="Primary"
        size="Small"
      />
      <ModalTransition>
        {isOpen && (
          <Modal
            onClose={close}
            shouldScrollInViewport={false}
            autoFocus={false}
            width={800}
          >
            <AddGoalPopup workSpace={workSpace} close={close} />
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};

export default AddGoalButtonContainer;
