import styled, { css } from 'styled-components';

const centerAlignStyles = css`
  align-items: center;
`;

const spaceBetweenStyles = css`
  justify-content: space-between;
`;

const boxShadowStyles = css`
  box-shadow: 0px 1px 0px 0px #00000014 inset;
`;

const Row = styled.div<{
  centerAlign?: boolean;
  spaceBetween?: boolean;
  isBoxShadowTop?: boolean;
}>`
  display: flex;
  flex-direction: row;
  ${(props) => props.centerAlign && centerAlignStyles}
  ${(props) => props.spaceBetween && spaceBetweenStyles}
  ${(props) => props.isBoxShadowTop && boxShadowStyles}
`;

export default Row;
