import React, { useCallback } from 'react';
import styled from 'styled-components';

import TickSrc from '../images/tick.png';
import Row from '../../Common/Row';
import Typography from 'kingpin/atoms/Typography';
import Loading from '../../Loading';
import Tooltip from '../../Tooltip';
import Colors2 from 'theme/Colors2';
import useTargetsForField from '../hooks/useTargetsForField';
import useHasUnsavedChanges from '../hooks/useHasUnsavedChanges';
import useWarningConfirmation from '../../ConfirmationModals/hooks/useWarningConfirmation';
import { ModalTransition } from '@atlaskit/modal-dialog';
import TextInput from '../../../kingpin/atoms/TextInput';

const ContainerElem = styled.div`
  padding: 24px 32px;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const TopBarElem = styled.div<{ hasTopBorder?: boolean }>`
  background-color: ${Colors2.Grey['9']};

  width: 100%;
  height: 54px;
  display: flex;
  padding: 0px 32px;
  border-bottom: 1px solid #ebebeb;
  ${(props) => props.hasTopBorder && 'border-top: 1px solid #EBEBEB;'};
`;

const EllipsisElem = styled.div`
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const TopBar = () => {
  return (
    <TopBarElem>
      <Row spaceBetween centerAlign>
        <Typography.Header type="H5">
          Select A Targetable Field
        </Typography.Header>
      </Row>
    </TopBarElem>
  );
};

export const ItemElem = styled.div<{ isSelected?: boolean }>`
  height: 48px;
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 4px;

  display: flex;
  padding: 0px 16px;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  position: relative;

  background-color: ${(props) => (props.isSelected ? '#f9f9f9' : 'unset')};

  &:hover {
    background-color: #fcfcfc;
  }
`;

export const ListWrap = styled.div`
  flex: 1;
  overflow: scroll;
`;

export const List = styled.div`
  max-height: 0;
`;

const TargetCount = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-right: 8px;
  background-color: ${Colors2.AvatarColors['4'].background};
  text-align: center;
`;

const Item = ({
  field,
  onFieldSelected,
  isSelected,
}: {
  field: { rawField: string; label: string };
  onFieldSelected: (f: string) => void;
  isSelected: boolean;
}) => {
  const { getConfirmationThat, DangerConfirmation } = useWarningConfirmation({
    title: 'Unsaved Changes',
    continueText: `Continue to ${field.label} without saving`,
  });
  const hasUnsavedChanges = useHasUnsavedChanges();

  const targetsForField = useTargetsForField(field.rawField);
  const onSelected = useCallback(() => {
    if (!hasUnsavedChanges) {
      onFieldSelected(field.rawField);
      return;
    }

    getConfirmationThat(
      'If you navigate away, all changes made will be lost.' +
        ' Are sure you want to cancel Target setting?',
    ).then((isConfirmed) => {
      if (isConfirmed) {
        onFieldSelected(field.rawField);
      }
    });
  }, [field.rawField, getConfirmationThat, hasUnsavedChanges, onFieldSelected]);

  return (
    <>
      <ItemElem
        onClick={onSelected}
        isSelected={isSelected}
        data-testid={`${field.rawField}-selected:${isSelected}`}
      >
        <Row
          centerAlign
          spaceBetween
          style={{ flex: 1, overflow: 'hidden' }}
          data-testid={`${field.label}-selected:${isSelected}`}
        >
          <EllipsisElem>
            <Tooltip content={field.label}>
              <Typography.Header type="H5">{field.label}</Typography.Header>
            </Tooltip>
          </EllipsisElem>
          {targetsForField.length > 0 && (
            <TargetCount>
              <Typography.Body type="Body 12">
                {targetsForField.length}
              </Typography.Body>
            </TargetCount>
          )}
          {isSelected && <img src={TickSrc} alt="ticked" />}
        </Row>
      </ItemElem>
      <ModalTransition>{DangerConfirmation}</ModalTransition>
    </>
  );
};

const Fields = ({
  selectedField,
  onFieldSelected,
  isLoadingTargetFields,
  fields,
}: {
  selectedField?: string;
  onFieldSelected: (f: string) => void;
  isLoadingTargetFields: boolean;
  fields: { rawField: string; label: string }[];
}) => {
  if (isLoadingTargetFields) {
    return <Loading />;
  }

  return (
    <ListWrap>
      <List>
        {fields.map((f) => (
          <Item
            key={f.rawField}
            field={f}
            onFieldSelected={onFieldSelected}
            isSelected={selectedField === f.rawField}
          />
        ))}
      </List>
    </ListWrap>
  );
};

const TargetFieldList = ({
  selectedField,
  onFieldSelected,
  isLoadingTargetFields,
  fields,
  searchText,
  onSearchTextChanged,
}: {
  selectedField?: string;
  onFieldSelected: (f: string) => void;
  isLoadingTargetFields: boolean;
  fields: { rawField: string; label: string }[];
  searchText: string;
  onSearchTextChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => (
  <>
    <TopBar />
    <ContainerElem>
      <div style={{ marginBottom: 16 }}>
        <TextInput
          value={searchText}
          onChange={onSearchTextChanged}
          icon="search"
          inputSize="Small"
        />
      </div>

      <Fields
        selectedField={selectedField}
        onFieldSelected={onFieldSelected}
        isLoadingTargetFields={isLoadingTargetFields}
        fields={fields}
      />
    </ContainerElem>
  </>
);

export default TargetFieldList;
