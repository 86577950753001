import buildIsoWeekCompatabilityError from 'types/scorecardDates/buildIsoWeekCompatabilityError';
import isIsoDate from 'types/scorecardDates/isIsoDate';
import isIsoTargets from 'types/scorecardDates/isIsoTargets';

const onRemoveClicked = ({
  setTargets,
  startDate,
}: {
  setTargets: React.Dispatch<
    React.SetStateAction<Scorecards.IsoTargets | Scorecards.WeekTargets>
  >;
  startDate: Scorecards.WeekDate | Scorecards.IsoDate;
}) => {
  setTargets((currentTargets) => {
    if (isIsoTargets(currentTargets) && isIsoDate(startDate)) {
      const newTargets = { ...currentTargets };
      delete newTargets.targets[startDate];
      return newTargets;
    } else if (!isIsoTargets(currentTargets) && !isIsoDate(startDate)) {
      const newTargets = { ...currentTargets };
      if (newTargets.targets[startDate.year] !== undefined) {
        // @ts-ignore
        delete newTargets.targets[startDate.year][startDate.week];
        // @ts-ignore
        if (Object.keys(newTargets.targets[startDate.year]).length === 0) {
          delete newTargets.targets[startDate.year];
        }
      }

      return newTargets;
    } else {
      throw buildIsoWeekCompatabilityError();
    }
  });
};

export default onRemoveClicked;
