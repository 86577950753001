import { useCallback } from 'react';

const useParseQueryParams = () => {
  const parseQueryParams = useCallback(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    return {
      token: params.get('token') as string,
      email: decodeURIComponent(params.get('email') as string),
      carrierName: params.get('carrierName') as string,
      userId: params.get('userId') as string,
    };
  }, []);

  return parseQueryParams;
};

export default useParseQueryParams;
