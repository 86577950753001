import { sanitizeFilter } from './utils/buildFilterInput';

const mergeRanges = (a: RangeInput[], b: RangeInput[]): RangeInput[] => {
  if (a && b) {
    return [...a, ...b];
  }

  if (a) {
    return a;
  }

  if (b) {
    return b;
  }

  return [];
};

const getWildCards = (fi: FilterInput) => {
  if (fi.wildcardFilters) {
    return fi.wildcardFilters;
  }
  return [];
};
// Todo: merge filters
const mergeFilterInputs = (a: FilterInput, b: FilterInput): FilterInput => {
  return sanitizeFilter({
    keywords: [...(a.keywords || []), ...(b.keywords || [])],
    ranges: mergeRanges(a.ranges || [], b.ranges || []),
    booleanFilters: [...(a.booleanFilters || []), ...(b.booleanFilters || [])],
    wildcardFilters: [...getWildCards(a), ...getWildCards(b)],
    mustExistFilters: [
      ...(a.mustExistFilters || []),
      ...(b.mustExistFilters || []),
    ],
    mustNotExistFilters: [
      ...(a.mustNotExistFilters || []),
      ...(b.mustNotExistFilters || []),
    ],
  });
};

export default mergeFilterInputs;
