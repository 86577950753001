import React, { useContext } from 'react';
import AddNewRewardButton from './AddNewRewardButton';
import CommitmentQueryContext from '../../../../contexts/CommitmentQueryContext';

const AddNewRewardButtonContainer = () => {
  const { isQueryValid, openSlideOut, configForQuery, mode } = useContext(
    CommitmentQueryContext,
  );
  const onClick = () => {
    if (!isQueryValid || !!configForQuery) {
      return;
    }
    openSlideOut();
  };

  return (
    <AddNewRewardButton
      isQueryValid={isQueryValid}
      onClick={onClick}
      configForQuery={configForQuery}
      mode={mode}
    />
  );
};

export default AddNewRewardButtonContainer;
