import React, { useContext } from 'react';
import BonusPeriodSelector from './BonusPeriodSelector';
import BonusPeriodsContext from '../../../../contexts/BonusPeriodsContext';

const BonusPeriodSelectorContainer = ({
  onDatePickerOpen,
  onDatePickerClose,
}: {
  onDatePickerOpen?: () => void;
  onDatePickerClose?: () => void;
}) => {
  const { bonusPeriods, selectedBonusPeriod, setSelectedBonusPeriod } =
    useContext(BonusPeriodsContext);

  if (!selectedBonusPeriod) {
    return null;
  }

  return (
    <BonusPeriodSelector
      bonusPeriods={bonusPeriods}
      setSelectedBonusPeriod={setSelectedBonusPeriod}
      selectedPeriod={selectedBonusPeriod}
      onDatePickerOpen={onDatePickerOpen}
      onDatePickerClose={onDatePickerClose}
    />
  );
};

export default BonusPeriodSelectorContainer;
