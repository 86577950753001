import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Tooltip from '../../../components/Tooltip';

const EllipsisCss = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
`;

const LinkCss = css`
  color: #0041ea !important;
  font-weight: 400;
  cursor: pointer;
  &:hover {
    color: #1275f7 !important;
  }
`;

export const Body14 = styled.span<{
  isEllipsis: boolean;
  isLink?: boolean;
  color?: string;
  textAlign?: 'left' | 'right' | 'center';
}>`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};

  margin: 0;
  color: ${(props) => props.color || '#252525'} !important;
  ${(props) => props.isEllipsis && EllipsisCss};
  ${(props) => props.isLink && LinkCss};
`;

const BodyBold = styled(Body14)`
  font-weight: 600;
`;

const BodyLink = styled(Body14)`
  color: #0041ea !important;
  font-weight: 400;
  cursor: pointer;
  &:hover {
    color: #1275f7 !important;
  }
`;

// Need to export this to support animated color on RelativeInput.tsx
export const Body12 = styled.span<{
  isEllipsis: boolean;
  isLink?: boolean;
  color?: string;
  textAlign?: 'left' | 'right' | 'center';
}>`
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};

  margin: 0;
  color: ${(props) => props.color || '#252525'} !important;
  ${(props) => props.isEllipsis && EllipsisCss};
  ${(props) => props.isLink && LinkCss};
`;

const Body13 = styled.span<{
  isEllipsis: boolean;
  isLink?: boolean;
  color?: string;
  textAlign?: 'left' | 'right' | 'center';
}>`
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};

  margin: 0;
  color: ${(props) => props.color || '#252525'} !important;
  ${(props) => props.isEllipsis && EllipsisCss};
  ${(props) => props.isLink && LinkCss};
`;

const ButtonText = styled.span<{
  isEllipsis: boolean;
  color?: string;
  textAlign?: 'left' | 'right' | 'center';
}>`
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};

  margin: 0;
  color: ${(props) => props.color || '#333333'} !important;
  ${(props) => props.isEllipsis && EllipsisCss};
`;

const Label = styled.span<{
  isEllipsis: boolean;
  color?: string;
  textAlign?: 'left' | 'right' | 'center';
}>`
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};

  margin: 0;
  color: ${(props) => props.color || '#1a1919'} !important;
  ${(props) => props.isEllipsis && EllipsisCss};
`;

const Placeholder = styled.span<{
  isEllipsis: boolean;
  color?: string;
  textAlign?: 'left' | 'right' | 'center';
}>`
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};

  margin: 0;
  color: ${(props) => props.color || '#8e8e8e'} !important;
  ${(props) => props.isEllipsis && EllipsisCss};
`;

const Badge = styled.span<{
  isEllipsis: boolean;
  color?: string;
  textAlign?: 'left' | 'right' | 'center';
}>`
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};

  margin: 0;
  color: #252525;
  ${(props) => props.isEllipsis && EllipsisCss};
`;

const Annotation = styled.span<{
  isEllipsis: boolean;
  color?: string;
  textAlign?: 'left' | 'right' | 'center';
}>`
  color: #1a1919;
  font-family: Inter;
  font-size: 10px;
  font-style: italic;
  font-weight: 500;
  line-height: 14px;
`;

type BodyType =
  | 'Body 12'
  | 'Body 13'
  | 'Body 14'
  | 'Body Bold'
  | 'Link'
  | 'Button Text'
  | 'Label'
  | 'Placeholder'
  | 'Badge'
  | 'Annotation';

const Body = (props: {
  type: BodyType;
  children:
    | string
    | string[]
    | number
    | undefined
    | JSX.Element
    | (JSX.Element | string | number | undefined)[]
    | JSX.Element[];
  isEllipsis?: boolean;
  isLink?: boolean;
  ellipsisPosition?: 'left' | 'bottom' | 'top' | 'right';
  textAlign?: 'center' | 'left' | 'right';
  color?: string;
  onClick?: () => void;
  ['data-testid']?: string;
}) => {
  const {
    type,
    children,
    isEllipsis = false,
    isLink = false,
    color,
    onClick,
    textAlign,
    ellipsisPosition,
  } = props;

  const ref = useRef<HTMLSpanElement>(null);
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false);
  useEffect(() => {
    const e = ref.current;
    if (!e) {
      return;
    }
    setIsOverflowing(e.offsetWidth < e.scrollWidth);
  }, [children]);

  const Component = (() => {
    if (type === 'Body 12') {
      return (
        <Body12
          ref={ref}
          isEllipsis={isEllipsis}
          isLink={isLink}
          onClick={onClick}
          color={color}
          textAlign={textAlign}
          data-testid={props['data-testid']}
        >
          {children}
        </Body12>
      );
    }
    if (type === 'Body 13') {
      return (
        <Body13
          ref={ref}
          isLink={isLink}
          isEllipsis={isEllipsis}
          onClick={onClick}
          color={color}
          textAlign={textAlign}
          data-testid={props['data-testid']}
        >
          {children}
        </Body13>
      );
    }
    if (type === 'Body 14') {
      return (
        <Body14
          ref={ref}
          isLink={isLink}
          isEllipsis={isEllipsis}
          color={color}
          onClick={onClick}
          textAlign={textAlign}
          data-testid={props['data-testid']}
        >
          {children}
        </Body14>
      );
    }
    if (type === 'Link') {
      return (
        <BodyLink
          ref={ref}
          isEllipsis={isEllipsis}
          onClick={onClick}
          textAlign={textAlign}
          data-testid={props['data-testid']}
          color={color}
        >
          {children}
        </BodyLink>
      );
    }
    if (type === 'Button Text') {
      return (
        <ButtonText
          ref={ref}
          isEllipsis={isEllipsis}
          onClick={onClick}
          textAlign={textAlign}
          data-testid={props['data-testid']}
          color={color}
        >
          {children}
        </ButtonText>
      );
    }
    if (type === 'Label') {
      return (
        <Label
          ref={ref}
          isEllipsis={isEllipsis}
          onClick={onClick}
          textAlign={textAlign}
          data-testid={props['data-testid']}
          color={color}
        >
          {children}
        </Label>
      );
    }
    if (type === 'Placeholder') {
      return (
        <Placeholder
          ref={ref}
          isEllipsis={isEllipsis}
          onClick={onClick}
          textAlign={textAlign}
          data-testid={props['data-testid']}
          color={color}
        >
          {children}
        </Placeholder>
      );
    }
    if (type === 'Badge') {
      return (
        <Badge
          ref={ref}
          isEllipsis={isEllipsis}
          onClick={onClick}
          textAlign={textAlign}
          data-testid={props['data-testid']}
          color={color}
        >
          {children}
        </Badge>
      );
    }
    if (type === 'Body Bold') {
      return (
        <BodyBold
          ref={ref}
          isEllipsis={isEllipsis}
          onClick={onClick}
          color={color}
          textAlign={textAlign}
          data-testid={props['data-testid']}
        >
          {children}
        </BodyBold>
      );
    }
    if (type === 'Annotation') {
      return (
        <Annotation
          ref={ref}
          isEllipsis={isEllipsis}
          onClick={onClick}
          color={color}
          textAlign={textAlign}
          data-testid={props['data-testid']}
        >
          {children}
        </Annotation>
      );
    }

    console.warn(`Unknown body type encountered: ${type}`);
    return (
      <Body12
        ref={ref}
        isEllipsis={isEllipsis}
        onClick={onClick}
        color={color}
        textAlign={textAlign}
        data-testid={props['data-testid']}
      >
        {children}
      </Body12>
    );
  })();

  if (isEllipsis) {
    return (
      <Tooltip
        content={isOverflowing ? children : undefined}
        position={ellipsisPosition}
      >
        {Component}
      </Tooltip>
    );
  }

  return <>{Component}</>;
};

export default Body;
