import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

interface AggregationBucket {
  [metricIdOrPrimaryField: string]: string | number | undefined;
}

const runAggregation = async ({
  client,
  metrics,
  dateScope,
  filters,
  groupBy,
}: {
  client: ApolloClient<NormalizedCacheObject>;
  metrics: MetricInput[];
  dateScope: DateRangeInput;
  filters: FilterInput[];
  groupBy: V5GroupBy;
}) => {
  const query = {
    query: gql`
      query aggregateCompoundMetric(
        $metrics: [MetricInput]!
        $filters: [FilterInput]!
        $dateScope: [DateRangeInput!]!
        $expressions: [Expression2]!
        $groupBy: GroupBy
      ) {
        aggregateCompoundMetric(
          metrics: $metrics
          filters: $filters
          dateScope: $dateScope
          expressions: $expressions
          groupBy: $groupBy
        )
      }
    `,
    variables: {
      metrics,
      expressions: [],
      filters,
      dateScope,
      groupBy,
    },
  };

  const response = await client.query(query);
  return response.data.aggregateCompoundMetric as AggregationBucket[];
};

export default runAggregation;
