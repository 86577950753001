import _ from 'lodash';

const getAllValuesFor = (data: V5ChartData, key: string): string[] => {
  try {
    const values = [] as string[];
    Object.values(data).forEach(({ response }) => {
      values.push(
        ...Object.values(response).map((item) => item[key].toString()),
      );
    });

    return _.uniq(values);
  } catch (ex) {
    return [];
  }
};

export default getAllValuesFor;
