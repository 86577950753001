import React from 'react';

type DefinitionsLookup = {
  [id: string]:
    | V5ChartDefinition
    | VisualisationDefinitions.SingleMetricDateMatrix
    | VisualisationDefinitions.Gauge
    | VisualisationDefinitions.Reminders
    | VisualisationDefinitions.RankingMatrix
    | undefined;
};

interface ChartDefinitionsContextType {
  definitions: VisualisationDefinition[];
  definitionsLookup: DefinitionsLookup;
  isLoading: boolean;
}
const ChartDefinitionsContext =
  React.createContext<ChartDefinitionsContextType>({
    definitions: [],
    definitionsLookup: {},
    isLoading: true,
  });

export default ChartDefinitionsContext;
