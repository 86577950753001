import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import ImpersonatorContext from 'contexts/ImpersonatorContext';
import AccountPickerContext from '../../contexts/AccountPickerContext';
import appRoutes from '../../navigation/appRoutes';
import AccountPicker from './AccountPicker';
import NavSideBarContext from '../../contexts/NavSideBarContext';

const AccountPickerContainer = () => {
  const { isOpen } = useContext(NavSideBarContext);
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [options, setOptions] = useState<DropdownOption[]>([]);
  const { accounts, setSelectedAccount, selectedAccount } =
    useContext(AccountPickerContext);
  const { impersonatorId } = useContext(ImpersonatorContext);

  useEffect(() => {
    setIsVisible(impersonatorId ? false : accounts.length > 1);
  }, [accounts.length, impersonatorId]);

  useEffect(() => {
    const newOptions: DropdownOption[] = _.sortBy(accounts, 'accountName').map(
      (a) => ({
        label: a.accountName,
        isSelected: a.accountId === selectedAccount.accountId,
        onSelected: () => {
          setSelectedAccount(a);
          navigate(appRoutes.home);
        },
      }),
    );
    setOptions(newOptions);
  }, [accounts, navigate, selectedAccount.accountId, setSelectedAccount]);

  if (!isVisible) {
    return null;
  }

  if (!isOpen) {
    return null;
  }

  return (
    <AccountPicker isSearchVisible={options.length > 5} options={options} />
  );
};

export default AccountPickerContainer;
