import React from 'react';

interface UserSettingsContextType {
  selectedUser?: UserManagement.PendingUser | UserManagement.SignedUpUser;
  users: (UserManagement.PendingUser | UserManagement.SignedUpUser)[];
  isLoading: boolean;
  isSsoAccount: boolean;
  isLoadingIsSsoAccount: boolean;
  isSeatsLimited: boolean;
  maxSeats?: number;
  usedSeats: number;
  hasSeatsRemaining: boolean;
}

const UsersSettingsContext = React.createContext<UserSettingsContextType>({
  users: [],
  isLoading: true,
  isSsoAccount: false,
  isLoadingIsSsoAccount: false,
  isSeatsLimited: false,
  maxSeats: undefined,
  usedSeats: 0,
  hasSeatsRemaining: true,
});

export default UsersSettingsContext;
