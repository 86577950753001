import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 16px 24px;
`;

const ALL_SETS_LIST_HEIGHT_OFFSET = 240;
const SPECIFIC_SET_LIST_HEIGHT_OFFSET = 340;

const ListWrapper = styled.div<{ isSpecificDataSet: boolean }>`
  height: calc(
    100vh -
      ${(props) =>
        props.isSpecificDataSet
          ? SPECIFIC_SET_LIST_HEIGHT_OFFSET
          : ALL_SETS_LIST_HEIGHT_OFFSET}px
  );
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 1px;
`;

const List = styled.div`
  overflow-y: auto;
`;

const MetricListStyles = {
  Wrapper,
  List,
  ListWrapper,
  ALL_SETS_LIST_HEIGHT_OFFSET,
  SPECIFIC_SET_LIST_HEIGHT_OFFSET,
};

export default MetricListStyles;
