import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import WidgetList from './WidgetList';
import WidgetGalleryContext from '../../../contexts/WidgetGalleryContext';

const WidgetListContainer = ({ style }) => {
  const {
    definitions,
    toggleDefinitionSelected,
    selectedDefinitions,
    definitionsOnDashboard,
  } = useContext(WidgetGalleryContext);

  return (
    <WidgetList
      definitions={definitions}
      style={style}
      toggleDefinitionSelected={toggleDefinitionSelected}
      selectedDefinitions={selectedDefinitions}
      definitionsOnDashboard={definitionsOnDashboard}
    />
  );
};

WidgetListContainer.propTypes = {
  style: PropTypes.object,
};

export default WidgetListContainer;
