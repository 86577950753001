import React from 'react';

import Row from '../../../../Common/Row';
import Typography from 'kingpin/atoms/Typography';
import { BandPreview } from '../TargetBandsForm/TargetBandsForm';
import Button from 'kingpin/atoms/Button';

const TargetBands = ({
  colors,
  bands,
  scoringBandId,
  isColorOrderReversed,
  dateLabel,
  onEditClicked,
  onRemoveClicked,
  isAddingNewTargetBand,
  isEditingDateTarget,
}: {
  colors: string[];
  bands: string[];
  scoringBandId: Scorecards.ScoringBandId;
  isColorOrderReversed: boolean;

  dateLabel: string;
  onEditClicked: () => void;
  onRemoveClicked: () => void;
  isAddingNewTargetBand: boolean;
  isEditingDateTarget: boolean;
}) => (
  <Row centerAlign style={{ marginBottom: 16 }}>
    {colors.map((color, index) => (
      <div style={{ width: 100, marginRight: 8 }} key={`${index}-${color}`}>
        <BandPreview
          bands={bands}
          bandIndex={index}
          scoringBandId={scoringBandId}
          isColorOrderReversed={isColorOrderReversed}
        />
      </div>
    ))}
    <div
      style={{
        marginRight: 8,
      }}
    >
      <Typography.Body type="Body 12">{dateLabel}</Typography.Body>
    </div>
    {!isEditingDateTarget && !isAddingNewTargetBand && (
      <>
        <div style={{ marginRight: 8 }}>
          <Button
            type="Ghost"
            size="Small"
            onClick={onEditClicked}
            icon={'edit-filled'}
          />
        </div>
        <div style={{ marginRight: 8 }}>
          <Button
            type="Ghost"
            size="Small"
            onClick={onRemoveClicked}
            icon="cross"
            testId={`removeBand-${dateLabel}`}
          />
        </div>
      </>
    )}
  </Row>
);

export default TargetBands;
