import styled from 'styled-components';
import Colors2 from '../../../theme/Colors2';

const ListItemWrapper = styled.div<{
  isSelected?: boolean;
  isLast?: boolean;
}>`
  height: 56px;
  width: 100%;

  border-bottom: 1px solid ${Colors2.Border};

  ${(props) => props.isLast && `box-shadow: unset;`}
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  padding: 0px 12px;

  a {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .showOnMouseOver {
    visibility: ${process.env.NODE_ENV === 'test' ? '' : 'hidden'};
  }

  &:hover {
    background-color: ${Colors2.Grey['9']};

    .showOnMouseOver {
      visibility: visible;
    }
  }
`;

export default ListItemWrapper;
