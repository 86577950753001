import { useCallback } from 'react';
import { ALL_GROUP_NAME, RADIO_OPTIONS_MAP } from '../../consts';

const useStateTransformers = () => {
  const toWizard = useCallback(
    (
      persistedState: Costs.PersistedCostModel,
      allCategories: Costs.CostCategoryField[],
    ): Costs.WizardState => {
      return {
        id: persistedState.id,
        currentStep: 'base',
        config: {
          basicStep: {
            interval: persistedState.interval,
            startOfWeek: persistedState.startOfWeek,
            activityCostCalculationMode:
              persistedState.activityCostCalculationMode,
            startDate: persistedState.startDate,
            field: persistedState.dateField,
          },
          groupStep: {
            type: persistedState.groupFieldName
              ? RADIO_OPTIONS_MAP.separately
              : RADIO_OPTIONS_MAP.evenly,
            field:
              persistedState.groupFieldName === null
                ? undefined
                : persistedState.groupFieldName,
            currentGroup: [],
            groups: persistedState.groups || [],
          },
          categoriesStep: {
            categories: persistedState.categories.map((cat) => {
              const field = allCategories.find(
                (originalCat) => originalCat.fieldName === cat.costFieldName,
              );
              return field
                ? { name: field.name, fieldName: field.fieldName }
                : { name: 'default', fieldName: 'default' };
            }),
          },
          outputsStep: {
            marginExcludePassThru: persistedState.marginExcludePassThru,
            marginExcludeFSC: persistedState.marginExcludeFSC,
          },
        },
        isValid: true,
        isDraft: !!persistedState.isDraft,
        hasBreakingChanges: false,
      };
    },
    [],
  );

  const toCreate = useCallback(
    (wizardState: Costs.WizardState): Costs.CreateCostModelInput => {
      if (
        !wizardState.config.basicStep.field ||
        !wizardState.config.basicStep.startDate
      ) {
        const error = new Error();
        error.name = 'Basic step: Field or/and startDate undefined';
        throw error;
      }

      return {
        modelType: 'activity' as 'activity',
        groupFieldName: wizardState.config.groupStep.field,
        groups:
          wizardState.config.groupStep.groups.length !== 0
            ? wizardState.config.groupStep.groups
            : [{ groupName: ALL_GROUP_NAME }],
        interval: wizardState.config.basicStep.interval,
        startOfWeek: wizardState.config.basicStep.startOfWeek,
        dateField: wizardState.config.basicStep.field,
        startDate: wizardState.config.basicStep.startDate,
        categories: wizardState.config.categoriesStep.categories.map(
          (category) => {
            return {
              costFieldName: category.fieldName,
              costValueType: 'totalMiles' as 'totalMiles',
            };
          },
        ),
        marginExcludeFSC: wizardState.config.outputsStep.marginExcludeFSC,
        marginExcludePassThru:
          wizardState.config.outputsStep.marginExcludePassThru,
        activityCostCalculationMode:
          wizardState.config.basicStep.activityCostCalculationMode,
      };
    },
    [],
  );

  const toUpdate = useCallback(
    (wizardState: Costs.WizardState): Costs.UpdateCostModelInput => {
      if (!wizardState.id) {
        const error = new Error();
        error.name = 'Id is undefined. Cannot update';
        throw error;
      }

      return {
        costModelId: wizardState.id,
        groupFieldName: wizardState.config.groupStep.field || null,
        groups:
          wizardState.config.groupStep.groups.length !== 0
            ? wizardState.config.groupStep.groups
            : [{ groupName: ALL_GROUP_NAME }],
        interval: wizardState.config.basicStep.interval,
        startOfWeek: wizardState.config.basicStep.startOfWeek || null,
        dateField: wizardState.config.basicStep.field || '',
        startDate: wizardState.config.basicStep.startDate || '',
        categories: wizardState.config.categoriesStep.categories.map(
          (category) => {
            return {
              costFieldName: category.fieldName,
              costValueType: 'totalMiles' as 'totalMiles',
            };
          },
        ),
        activityCostCalculationMode:
          wizardState.config.basicStep.activityCostCalculationMode,
        marginExcludeFSC: wizardState.config.outputsStep.marginExcludeFSC,
        marginExcludePassThru:
          wizardState.config.outputsStep.marginExcludePassThru,
        costsReconciliationType: wizardState.hasBreakingChanges
          ? 'delete'
          : 'reconcile',
        isDraft: wizardState.isDraft,
      };
    },
    [],
  );

  return {
    toWizard,
    toCreate,
    toUpdate,
  };
};

export default useStateTransformers;
