import React, { useCallback, useEffect, useState } from 'react';
import { css } from 'styled-components';

// Import this into the scrollable div,
// ex:
// const MyList = styled.div<{ isScrolling: boolean }>`
//   overflow-y: scroll;
//   ${props => props.isScrolling && ScrollingDivShadowCss}
// `
export const ScrollingDivShadowCss = css`
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    pointer-events: none;
    box-shadow: inset 0px -13px 17px -10px #e3e3e3;
  }
`;

const useShadowIfDivIsScrolling = (
  elemRef: React.RefObject<HTMLDivElement> | null,
) => {
  const getIsScrolling = useCallback(() => {
    if (!elemRef || !elemRef.current) {
      return false;
    }
    const element = elemRef.current;
    return element.offsetHeight < element.scrollHeight;
  }, [elemRef]);

  const [isScrolling, setIsScrolling] = useState(() => getIsScrolling());

  // Can be used within a useEffect or useLayoutEffect by consuming component
  const determineIfIsScrolling = useCallback(() => {
    setIsScrolling(getIsScrolling());
  }, [getIsScrolling]);

  useEffect(() => {
    determineIfIsScrolling();

    window.addEventListener('resize', determineIfIsScrolling);
    return () => {
      window.removeEventListener('resize', determineIfIsScrolling);
    };
  }, [determineIfIsScrolling]);

  return { isScrolling, determineIfIsScrolling };
};

export default useShadowIfDivIsScrolling;
