import React from 'react';
import Row from '../../Common/Row';
import Colors2 from '../../../theme/Colors2';
import toSentenceCase from '../../../services/toSentenceCase';
import Typography from 'kingpin/atoms/Typography';
import Card from 'components/Common/Card/index';
import Icon from '../../../kingpin/atoms/Icon';

const DriverDataHeaderSection = ({
  section,
  data,
}: {
  section: BoardSlideOutDataSection;
  data: { [key: string]: number | string | undefined };
}) => (
  <div style={{ marginBottom: 22 }}>
    <Typography.Header type="H5">
      {toSentenceCase(section.dataType)}
    </Typography.Header>
    <Card style={{ padding: '8px 18px' }}>
      {section.cardDef.map((cd) => (
        <Row style={{ marginBottom: 4 }} key={cd.field} centerAlign>
          <div style={{ flex: 1, marginRight: 8 }}>
            <Typography.Body type="Body 12">
              {toSentenceCase(cd.field)}
            </Typography.Body>
          </div>
          <div style={{ flex: 2 }}>
            {data[cd.field] === 0 && (
              <Icon icon="cross" color={Colors2.Secondary.error} />
            )}
            {data[cd.field] === 1 && (
              <Icon icon="checkmark" color={Colors2.Secondary.success} />
            )}
          </div>
        </Row>
      ))}
    </Card>
  </div>
);

export default DriverDataHeaderSection;
