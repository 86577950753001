import React from 'react';
import styled from 'styled-components';
import Spinner from 'components/AtlassianTemp/Spinner';
import Typography from 'kingpin/atoms/Typography';
import tinyColor from 'tinycolor2';

import Colors from '../../theme/colors';
import captureException from '../../services/captureException';
import useAvatarUrl from '../../hooks/useAvatarUrl';
import Tooltip from '../Tooltip';
import useUser from '../../hooks/useUser';

const Icon = styled.div<{ isLarge?: boolean; noMargin?: boolean }>`
  height: ${(props) => (props.isLarge ? '130px' : '24px')};
  min-height: ${(props) => (props.isLarge ? '130px' : '24px')};
  width: ${(props) => (props.isLarge ? '130px' : '24px')};
  min-width: ${(props) => (props.isLarge ? '130px' : '24px')};
  border-radius: ${(props) => (props.isLarge ? '65px' : '12px')};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-right: ${(props) => (props.noMargin ? '0px' : '8px')};

  .showOnMouseOver {
    visibility: hidden;
  }

  &:hover {
    > .showOnMouseOver {
      visibility: visible;
    }
  }
`;

const Avatar = styled.img<{ isLarge?: boolean }>`
  width: ${(props) => (props.isLarge ? '130px' : '24px')};
  height: ${(props) => (props.isLarge ? '130px' : '24px')};
`;

const InitialsIcon = styled.div<{
  backgroundColor: string;
  fontColor: string;
  isLarge?: boolean;
  noMargin?: boolean;
  isSmall?: boolean;
}>`
  height: ${(props) =>
    props.isLarge ? '130px' : props.isSmall ? '16px' : '24px'};
  min-height: ${(props) =>
    props.isLarge ? '130px' : props.isSmall ? '16px' : '24px'};
  width: ${(props) =>
    props.isLarge ? '130px' : props.isSmall ? '16px' : '24px'};
  min-width: ${(props) =>
    props.isLarge ? '130px' : props.isSmall ? '16px' : '24px'};
  border-radius: ${(props) => (props.isLarge ? '65px' : '12px')};
  overflow: hidden;
  margin-right: ${(props) => (props.noMargin ? '0px' : '8px')};
  display: flex;
  justify-content: center;
  align-items: center;

  .showOnMouseOver {
    visibility: hidden;
  }

  &:hover {
    > .showOnMouseOver {
      visibility: visible;
    }
  }

  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.fontColor};
`;

const InitialTextDiv = styled.div<{ isLarge?: boolean; isSmall?: boolean }>`
  width: ${(props) =>
    props.isLarge ? '130px' : props.isSmall ? '16px' : '24px'};
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const hash = (str: string) =>
  str
    .split('')
    .reduce(
      (accumulator, currentChar) =>
        accumulator + currentChar.charCodeAt(0) * 13,
      str.split('')[0].charCodeAt(0) * 13,
    );

const backgroundColor = (id?: string) => {
  if (!id) {
    return Colors.NAVY_BLUE;
  }

  const colors = [
    '#d0c6e2',
    '#b5e4f6',
    '#ffec96',
    '#cbeed2',
    '#90b097',
    '#f8afba',
    '#00C7E6',
    '#2684FF',
    '#8777D9',
    '#5E6C84',
    '#FF8F73',
    '#FFE380',
    '#79F2C0',
    '#79E2F2',
    '#4C9AFF',
    '#998DD9',
    '#7A869A',
  ];
  return colors[hash(id) % colors.length];
};

const fontColor = (id?: string) => {
  const background = tinyColor(backgroundColor(id));
  return tinyColor
    .mostReadable(
      background,
      [tinyColor(Colors.WHITE), tinyColor(Colors.GUN_METAL)],
      {
        includeFallbackColors: true,
      },
    )
    .toHexString();
};

const initials = ({
  firstName,
  lastName,
  displayName,
  isEllipsis,
}: {
  firstName?: string;
  lastName?: string;
  displayName?: string;
  isEllipsis?: boolean;
}) => {
  try {
    if (displayName === 'FleetOps Admin') {
      return 'FA';
    }

    if (!firstName) {
      return '?';
    }

    if (isEllipsis) {
      return `${firstName}${lastName}`;
    }

    const firstInitial = firstName[0].toUpperCase();
    let secondInitial = '';
    if (lastName) {
      secondInitial = lastName[0].toUpperCase();
    }

    return `${firstInitial}${secondInitial}`;
  } catch (ex) {
    captureException(ex);
    return '?';
  }
};

const InitialsText = ({
  text,
  color,
  isLarge,
  isSmall,
}: {
  text: string;
  color: string;
  isLarge?: boolean;
  isSmall?: boolean;
}) => {
  const textToUse = isSmall ? text[0] : text;
  if (isLarge) {
    return (
      <Typography.Header type="H1" color={color}>
        {textToUse}
      </Typography.Header>
    );
  }

  if (isSmall) {
    return (
      <Typography.Body type="Button Text" color={color}>
        {textToUse}
      </Typography.Body>
    );
  }

  return (
    <Typography.Body type="Body 12" color={color}>
      {textToUse}
    </Typography.Body>
  );
};

const UserIcon = ({
  id,
  noMargin,
  isEllipsis,
  isLarge,
  displayName,
  tooltip,
  email,
  textOverride,
  isSmall,
}: {
  id: string;
  noMargin?: boolean;
  isEllipsis?: boolean;
  isLarge?: boolean;
  displayName?: string;
  tooltip?: string;
  email?: string;
  textOverride?: string;
  isSmall?: boolean;
}) => {
  const { isLoading, avatarUrl } = useAvatarUrl(id);
  if (isLoading) {
    return (
      <Icon noMargin={noMargin} isLarge={isLarge}>
        <Spinner size={isLarge ? 'large' : undefined} />
      </Icon>
    );
  }

  const splitName = displayName ? displayName.split(' ') : undefined;
  const firstName = splitName ? splitName[0] : '';
  const lastName = splitName && splitName.length > 1 ? splitName[1] : '';

  if (avatarUrl) {
    return (
      <Tooltip content={displayName}>
        <Icon noMargin={noMargin} isLarge={isLarge}>
          <Avatar src={avatarUrl} isLarge={isLarge} />
        </Icon>
      </Tooltip>
    );
  }
  const isStaff = email && email.includes('@fleetops.com');

  return (
    <Tooltip content={tooltip ? tooltip : displayName}>
      <InitialsIcon
        backgroundColor={backgroundColor(isStaff ? undefined : id)}
        fontColor={fontColor(isStaff ? undefined : id)}
        noMargin={noMargin}
        isLarge={isLarge}
        isSmall={isSmall}
      >
        {isStaff && (
          <InitialTextDiv>
            <InitialsText
              color={fontColor(isStaff ? undefined : id)}
              isLarge={isLarge}
              isSmall={isSmall}
              text={
                textOverride ||
                initials({
                  firstName: 'FleetOps',
                  lastName: 'Admin',
                  displayName: 'FleetOps Admin',
                  isEllipsis,
                })
              }
            />
          </InitialTextDiv>
        )}
        {!isStaff && (
          <InitialTextDiv>
            <InitialsText
              color={fontColor(isStaff ? undefined : id)}
              isLarge={isLarge}
              isSmall={isSmall}
              text={
                textOverride ||
                initials({ firstName, lastName, displayName, isEllipsis })
              }
            />
          </InitialTextDiv>
        )}
      </InitialsIcon>
    </Tooltip>
  );
};

export const UserIconFromId = ({
  userId,
  noMargin,
}: {
  userId: string;
  noMargin?: boolean;
}) => {
  const { user } = useUser(userId);
  if (!user) {
    return null;
  }

  return <UserIcon {...user} noMargin={noMargin} />;
};

export default UserIcon;
