const Colors = {
  GAINSBORO: '#E5E5E5',
  BLUE_GREY: '#FAFBFC',
  SOLITUDE: '#F4F5F7',
  SATURATED_SOLITUDE: '#F7F9FB',
  WHITE_SMOKE: '#F8F8F8',
  WHITE: '#FFFFFF',
  SATURATED_ZIRCON: '#E0E3E7',
  ZIRCON: '#eeeff1',
  SATURATED_NAVY_BLUE: '#E5F0FA',
  LAVENDER: '#E5F0FA',
  LOGAN: '#97A0AF',
  GREY: '#A1A1A1',
  SHADY_LADY: '#979797',
  SHUTTLE_GREY: '#5C636D',
  BLACK: '#000',
  RADICAL_RED: '#FF3366',
  RADICAL_RED_BACKDROP: '#FDF2F2',
  AMBER: '#ffbf00',
  OUTRAGEOUS_ORANGE: '#FF5630',
  MEDIUM_SEA_GREEN: '#36B37E',
  MEDIUM_SEA_GREEN_BACKDROP: '#EBFAE2',
  GUN_METAL: '#2B3238',
  NAVY_BLUE: '#016CD1',
  DODGER_BLUE: '#24A0FC',

  FLEETOPS_PURPLE: '#352d43',
  SATURATED_FLEETOPS_PURPLE: '#e2dbf2',
  FLEETOPS_GREY: '#e9eaec',
  FLEETOPS_RED: '#df3d55',
  SECONDARY_MENU: '#3f364e',
  SECONDARY_MENU_SELECTED: '#352d43',
  SECONDARY_MENU_ICON: '#71687f',

  TITLE_TEXT: '#352d43',
  ANOTHER_TITLE_TEXT: '#1E1A26',
  TITLE_TEXT_DARK_MODE: '#FFFFFF',
  TEXT: '#3f354e',
  LIGHT_TEXT: '#a99cc0',
  LIGHT_STROKE: '#e1dfea',
  TEAMS_CLOSE: '#CCD0D6',
};

export default Colors;
