import React, { ChangeEvent } from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import { Label } from '../MyAccount/Profile/styles';
import Button from 'kingpin/atoms/Button';

import Row from '../Common/Row';
import styled from 'styled-components';
import Loading from '../Loading';
import Card from '../Common/Card';

const ModalWrapper = styled.div`
  padding: 8px 16px;
`;

const Video = styled.video`
  width: 100%;
`;

const ContentWrapper = styled.div`
  margin-bottom: 8px;
`;

const VideoCardForm = ({
  onSave,
  close,
  isOpen,
  onChange,
  isUploadingVideo,
  videoSrc,
  isValid,
}: {
  onSave: () => void;
  close: () => void;
  isOpen: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  isUploadingVideo: boolean;
  videoSrc?: string;
  isValid: boolean;
}) => (
  <ModalTransition>
    {isOpen && (
      <Modal onClose={close} shouldScrollInViewport={false} autoFocus={false}>
        <ModalWrapper>
          <ContentWrapper>
            <Label>Video</Label>
            <input type="file" accept="video/*" onChange={onChange} />
          </ContentWrapper>
          <ContentWrapper>
            {isUploadingVideo && <Loading />}
            {!isUploadingVideo && videoSrc && (
              <Card style={{ padding: 8 }}>
                <Video src={videoSrc} autoPlay loop />
              </Card>
            )}
          </ContentWrapper>
          <Row spaceBetween centerAlign style={{ marginTop: 12 }}>
            <Button onClick={close} type="Ghost" size="Small" label="Cancel" />
            <Button
              onClick={onSave}
              isDisabled={!isValid}
              size={'Small'}
              type="Primary"
              label="Save"
            />
          </Row>
        </ModalWrapper>
      </Modal>
    )}
  </ModalTransition>
);

export default VideoCardForm;
