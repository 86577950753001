import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import CustomerSearch from './CustomerSearch';
import CommitmentQueryContext from '../../../../contexts/CommitmentQueryContext';
import getCustomers from '../../../../api/getCustomers';
import usePopup from '../../../../hooks/usePopup';
import GqlClientContext from '../../../../contexts/GqlClientContext';

const CustomerSearchContainer = () => {
  const { client } = useContext(GqlClientContext);

  // state
  const { isOpen, open, close } = usePopup();
  const {
    customerValue,
    customerField,
    setCustomerValue,
    setCustomerField,
    mode,
  } = useContext(CommitmentQueryContext);
  const [searchText, setSearchText] = useState<string>('');
  const [searchResults, setSearchResults] = useState<CustomerSearchResult[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedResult, setSelectedResult] = useState<
    CustomerSearchResult | undefined
  >();
  const isFilled = !!customerValue && customerValue !== '';

  // effects
  useEffect(() => {
    setIsLoading(true);
    getCustomers(searchText, client).then((results) => {
      setSearchResults(results);
      setIsLoading(false);
      if (
        !selectedResult &&
        !!customerValue &&
        customerValue !== '' &&
        !!customerField
      ) {
        const selected = results.find(
          (r) => r[customerField] === customerValue,
        );
        setSelectedResult(selected);
      }
    });
  }, [client, customerField, customerValue, searchText, selectedResult]);

  // interactions
  const onSearchTextChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    open();
  };

  const onSearchFocus = () => {
    open();
  };

  const onSearchBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    // @ts-ignore
    if (event.relatedTarget && event.relatedTarget.nodeName === 'INPUT') {
      close();
    }
  };

  const onRemove = () => {
    setCustomerField(undefined);
    setCustomerValue(undefined);
  };

  const onItemSelected = (item: CustomerSearchResult) => {
    setSelectedResult(item);
    if (item.customer) {
      setCustomerValue(item.customer);
      setCustomerField('customer');
    } else if (item.billTo) {
      setCustomerValue(item.billTo);
      setCustomerField('billTo');
    }
    close();
  };

  return (
    <CustomerSearch
      isFilled={isFilled}
      searchText={searchText}
      onSearchTextChanged={onSearchTextChanged}
      onSearchFocus={onSearchFocus}
      onSearchBlur={onSearchBlur}
      searchResults={searchResults}
      isLoading={isLoading}
      onRemove={onRemove}
      onItemSelected={onItemSelected}
      isOpen={isOpen}
      close={close}
      selectedResult={selectedResult}
      isDisabled={mode === 'renew' || mode === 'modify'}
    />
  );
};

export default CustomerSearchContainer;
