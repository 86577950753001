import Typography from 'kingpin/atoms/Typography';
import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import Colors2 from 'theme/Colors2';
import Button from 'kingpin/atoms/Button';
import CostsWizardContext from '../context/CostsWizardContext';
import { RADIO_OPTIONS_MAP } from '../consts';

const Wrapper = styled.div<{ isActive: boolean; isFilled: boolean }>`
  border-bottom: 1px solid ${Colors2.Grey['8']};
  padding: 16px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: ${(props) => (props.isActive || props.isFilled ? 1 : 0.5)};
  background: ${(props) => (props.isActive ? '#f6f6f6' : '#fff')};
  cursor: pointer;
`;

const StepHeader = ({
  headerStep,
  headerText,
}: {
  headerStep: Costs.WizardStep;
  headerText: string;
}) => {
  const { wizardState, setWizardState } = useContext(CostsWizardContext);

  const checkIfFilled = useCallback(
    (headerStep: Costs.WizardStep) => {
      if (headerStep === 'base') {
        return (
          !!wizardState.config.basicStep.startDate &&
          !!wizardState.config.basicStep.field
        );
      } else if (headerStep === 'group') {
        return (
          wizardState.config.groupStep.type === RADIO_OPTIONS_MAP.evenly ||
          (!!wizardState.config.groupStep.field &&
            wizardState.config.groupStep.groups.length !== 0)
        );
      } else if (headerStep === 'categories') {
        return wizardState.config.categoriesStep.categories.length !== 0;
      } else {
        return true;
      }
    },
    [
      wizardState.config.basicStep.field,
      wizardState.config.basicStep.startDate,
      wizardState.config.categoriesStep.categories.length,
      wizardState.config.groupStep.field,
      wizardState.config.groupStep.groups.length,
      wizardState.config.groupStep.type,
    ],
  );

  const isFilled = checkIfFilled(headerStep);

  return (
    <Wrapper
      isActive={wizardState.currentStep === headerStep}
      isFilled={isFilled}
      onClick={() => {
        setWizardState((s) => ({
          ...s,
          currentStep:
            wizardState.currentStep === headerStep ? undefined : headerStep,
        }));
      }}
    >
      <Typography.Body type="Body Bold">{headerText}</Typography.Body>
      <Button
        type="Ghost"
        size="Small"
        icon={
          wizardState.currentStep === headerStep ? 'chevron-down' : 'chevron-up'
        }
      />
    </Wrapper>
  );
};

export default StepHeader;
