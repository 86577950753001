import React from 'react';

interface PeriodsContextType {
  periods: Period[];
  selectedPeriod?: Period;
  setSelectedPeriod: (p: Period | undefined) => void;
}

const PeriodsContext = React.createContext<PeriodsContextType>({
  periods: [],
  setSelectedPeriod: () => {},
});

export default PeriodsContext;
