import React, { useContext } from 'react';
import styled from 'styled-components';
import ReactPortal from '../../ReactPortal';
import Typography from 'kingpin/atoms/Typography';
import Row from '../../Common/Row';
import { TARGET_SAVE_DIV_ID } from '../constants';
import TargetFormContext from '../contexts/TargetFormContext';
import { TopBarElem } from '../TargetFieldList/TargetFieldList';
import EffectiveDateInput from './EffectiveDateInput';
import GroupFieldInput from './GroupFieldInput';
import GroupsList from './GroupsList';
import SaveButton from './SaveButton';
import Inputs from 'components/Inputs';
import AddRuleButton from './AddRuleButton';
import AddFieldButton from './AddFieldButton';

const ContainerElem = styled.div`
  padding: 0px 36px 0px 32px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ebebeb;
`;

const TopBar = () => {
  const { isEditing } = useContext(TargetFormContext);
  return (
    <TopBarElem>
      <Row spaceBetween centerAlign>
        <Typography.Header type="H5">
          {isEditing ? 'Update Target' : 'Configure New Target'}
        </Typography.Header>
      </Row>
    </TopBarElem>
  );
};

const TrackingFullPeriodInput = () => {
  const { isTrackingFullPeriod, setIsTrackingFullPeriod } =
    useContext(TargetFormContext);

  return (
    <Row style={{ marginRight: 16 }} centerAlign>
      <div style={{ marginRight: 4 }}>
        <Inputs.Checkbox
          testId={`isTrackingFullPeriod-${isTrackingFullPeriod}`}
          isChecked={isTrackingFullPeriod}
          onToggled={setIsTrackingFullPeriod}
        />
      </div>
      <Typography.Body type="Body 12">Tracking full period</Typography.Body>
    </Row>
  );
};

const BelowTargetIsGoodInput = () => {
  const { isBelowTargetDesirable, setIsBelowTargetDesirable } =
    useContext(TargetFormContext);

  return (
    <Row style={{ marginRight: 16 }} centerAlign>
      <div style={{ marginRight: 4 }}>
        <Inputs.Checkbox
          testId={`isBelowTargetDesirable-${isBelowTargetDesirable}`}
          isChecked={isBelowTargetDesirable}
          onToggled={setIsBelowTargetDesirable}
        />
      </div>
      <Typography.Body type="Body 12">
        Below target is desirable
      </Typography.Body>
    </Row>
  );
};

const GeneralInputs = ({
  dataType,
  effectiveDate,
  setEffectiveDate,
  groupField,
  setGroupField,
}: {
  dataType: string;
  effectiveDate?: string;
  setEffectiveDate: React.Dispatch<React.SetStateAction<string | undefined>>;
  groupField?: string;
  setGroupField: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => (
  <ContainerElem>
    <Row spaceBetween centerAlign style={{ height: 64 }}>
      <Row centerAlign style={{ height: 64 }}>
        <EffectiveDateInput
          dataType={dataType}
          effectiveDate={effectiveDate}
          setEffectiveDate={setEffectiveDate}
        />
        {!!effectiveDate && (
          <GroupFieldInput
            dataType={dataType}
            groupField={groupField}
            setGroupField={setGroupField}
          />
        )}
      </Row>
      <Row style={{ justifyContent: 'flex-end', gap: 8 }}>
        <AddRuleButton />
        <AddFieldButton />
      </Row>
    </Row>
    <Row centerAlign style={{ marginBottom: 8 }}>
      <BelowTargetIsGoodInput />
      <TrackingFullPeriodInput />
    </Row>
  </ContainerElem>
);

const TargetForm = ({
  dataType,
  effectiveDate,
  setEffectiveDate,
  groupField,
  setGroupField,
}: {
  dataType: string;
  effectiveDate?: string;
  setEffectiveDate: React.Dispatch<React.SetStateAction<string | undefined>>;
  groupField?: string;
  setGroupField: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => (
  <>
    <TopBar />
    <GeneralInputs
      dataType={dataType}
      effectiveDate={effectiveDate}
      setEffectiveDate={setEffectiveDate}
      groupField={groupField}
      setGroupField={setGroupField}
    />
    {groupField !== undefined && <GroupsList />}
    <ReactPortal elementId={TARGET_SAVE_DIV_ID}>
      <SaveButton />
    </ReactPortal>
  </>
);

export default TargetForm;
