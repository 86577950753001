import getPerformanceBoardsRef from './getPerformanceBoardsRef';
import getTargetsApps from './getTargetsApps';
import getEntityDetailsApps from './getEntityDetailsApps';
import getExecutivePortalsRef from './getExecutivePortalsRef';
import getEngagementPortalsRef from './getEngagementPortalsRef';

const contentDistributions = {
  getPerformanceBoardsRef,
  getTargetsApps,
  getEntityDetailsApps,
  getExecutivePortalsRef,
  getEngagementPortalsRef,
};

export default contentDistributions;
