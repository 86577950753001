import React, { useCallback, useContext } from 'react';
import usePopup from 'hooks/usePopup';
import AnalyticsContext from 'contexts/AnalyticsContext';
import InlineDialog from '../../InlineDialog';
import { DropdownButton } from '../Dropdown';
import FilterBuilderContext from './FilterBuilderContext';
import FilterForm from './FilterForm';
import Button from 'kingpin/atoms/Button';

const AddFilterButton = ({
  addFilter,
  isPlaceholder,
  trackingEvents,
  isInvalid,
}: {
  addFilter: (filter: FilterBuilder.Client.FieldFilter) => void;
  isPlaceholder: boolean;
  trackingEvents?: FilterBuilder.TrackingEvents;
  isInvalid?: boolean;
}) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const { filters } = useContext(FilterBuilderContext);
  const { isOpen, open, close } = usePopup();
  const onClicked = useCallback(() => {
    if (trackingEvents) {
      trackEvent(trackingEvents.onAddFilterClicked);
    }
    open();
  }, [open, trackEvent, trackingEvents]);

  return (
    <InlineDialog
      content={<FilterForm addFilter={addFilter} close={close} />}
      onClose={close}
      isOpen={isOpen}
    >
      <>
        {isPlaceholder && (
          <DropdownButton
            isOpen={isOpen}
            open={open}
            close={close}
            placeholder="Set Condition"
            testId={'add-filter'}
            hasError={isInvalid}
          />
        )}
        {!isPlaceholder && (
          <Button
            type="Tertiary"
            size="Small"
            onClick={onClicked}
            icon={'add'}
            label={filters.length === 1 ? 'And' : undefined}
            testId="add-filter"
          />
        )}
      </>
    </InlineDialog>
  );
};

export default AddFilterButton;
