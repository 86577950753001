import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Colors from '../../theme/colors';
import appRoutes from '../../navigation/appRoutes';

const Styles = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 32px;

  a {
    color: ${Colors.TITLE_TEXT};
    text-decoration: underline;
  }
`;

export const LogInLink = () => (
  <Styles>
    <Link to={appRoutes.home}>Log In</Link>
  </Styles>
);
