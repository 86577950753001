import React from 'react';

interface TargetsAppContextType {
  app: TargetsApp.ConfiguredApp;
  updateApp: (newApp: TargetsApp.ConfiguredApp) => Promise<void>;
  tabs: TargetsApp.ClientTab[];
  selectedTab?: TargetsApp.ClientTab; // Can be undefined be content not found
  performanceConfig: FleetOps.PerformanceDatasetConfig;
  fieldsWhichHaveTargets: string[];
  isLoadingFieldsWhichHaveTargets: boolean;
  reasonCodes: ReasonCode[];
}

const TargetsAppContext = React.createContext<TargetsAppContextType>(
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  undefined!,
);

export default TargetsAppContext;
