import React, { useContext } from 'react';

import useGroupNameOptions from '../../hooks/useGroupNameOptions';
import TargetFormContext from '../../contexts/TargetFormContext';
import Inputs from '../../../Inputs';
import Typography from 'kingpin/atoms/Typography';

const GroupNameInput = ({ group }: { group: Targets.Wizard.TargetGroup }) => {
  const { groupField, isShowingValidationFeedback } =
    useContext(TargetFormContext);

  const options = useGroupNameOptions(group);

  if (groupField === '*') {
    return null;
  }

  return (
    <>
      <div style={{ marginRight: 16 }}>
        <Typography.Body type="Body 12">For</Typography.Body>
      </div>
      <div style={{ marginRight: 16, width: 200 }}>
        <Inputs.MultiSelectDropdown
          placeholder={`Select ${groupField}(s)`}
          options={options}
          testId="group-name-input"
          hasError={
            isShowingValidationFeedback &&
            (group.groupName === undefined ||
              (group.groupName && group.groupName.length === 0))
          }
        />
      </div>
    </>
  );
};

export default GroupNameInput;
