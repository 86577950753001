import GridLayout from 'react-grid-layout';

import {
  compact,
  sortLayoutItemsByRowCol,
  // @ts-ignore
} from 'react-grid-layout/build/utils';

export const getLayout = (
  card: CanvasCard.Card,
  canvasMode: CanvasMode,
): CardLayout => {
  if (canvasMode === 'mobile') {
    return {
      ...card.layout,
      x: 0,
      w: 12,
      h: 15,
    };
  } else {
    return card.layout;
  }
};

const buildLayout = ({
  cards,
  isEditing,
  canvasMode,
}: {
  cards: CanvasCard.Card[];
  isEditing: boolean;
  canvasMode: CanvasMode;
}): GridLayout.Layout[] => {
  const baseLayout = cards.map((c) => ({
    ...c.layout,
    isDraggable: undefined,
    isResizable: undefined,
    static: isEditing ? false : true,
  }));

  const netLayout = (() => {
    if (canvasMode === 'mobile') {
      const ordered = sortLayoutItemsByRowCol(
        baseLayout,
      ) as GridLayout.Layout[];
      const mobileLayout = ordered.map((layout, index) => {
        return {
          ...layout,
          x: 0,
          y: index * 15,
          h: 15,
          w: 12,
        };
      });
      return compact(mobileLayout);
    } else {
      return baseLayout;
    }
  })();

  return netLayout;
};

export default buildLayout;
