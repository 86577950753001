import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { DropResult } from 'react-beautiful-dnd';
import _ from 'lodash';

import SimpleGridConfigInput from './SimpleGridConfigInput';
import BaseViewsContext from '../../../contexts/BaseViewsContext';
import isDefined from '../../../isDefined';
import useGetFieldLabel from '../../../hooks/useGetFieldLabel';
import useDataSourceOptions from './useDataSourceOptions';
import ReportDrillDownsContext from 'contexts/ReportDrillDownsContext';

const SimpleGridConfigInputContainer = ({
  gridConfig,
  setGridConfig,
}: {
  gridConfig: SimpleGridConfig;
  setGridConfig: React.Dispatch<
    React.SetStateAction<SimpleGridConfig | undefined>
  >;
}) => {
  const { dataType, sort, fields, disableLinks } = gridConfig;
  const { baseViews } = useContext(BaseViewsContext);
  const { setDrillDowns } = useContext(ReportDrillDownsContext);
  const selectedBaseView = baseViews[dataType];
  const [fieldOptions, setFieldOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const { getFieldLabel } = useGetFieldLabel();
  const onDataTypeChanged = useCallback(
    (newDataType: string) => {
      setGridConfig({
        ...gridConfig,
        dataType: newDataType,
        fields: [],
        sort: [],
        fieldAliases: [],
      });
    },
    [gridConfig, setGridConfig],
  );

  const dataSourceOptions = useDataSourceOptions({
    onDataTypeChanged,
    setDrillDowns,
  });

  useEffect(() => {
    if (!selectedBaseView) {
      setFieldOptions([]);
      return;
    }
    const availableFields = Object.values(selectedBaseView.fields).filter(
      isDefined,
    );
    const newOptions = availableFields
      .filter((f) => !gridConfig.fields.includes(f.field))
      .map((f) => ({
        label: getFieldLabel({
          field: f.field,
          dataType: selectedBaseView.type,
          isVerbose: false,
        }),
        value: f.field,
      }));
    setFieldOptions(newOptions);
  }, [getFieldLabel, gridConfig.fields, selectedBaseView]);

  // Interactions
  const onAddField = (field: string) => {
    const baseViewField = selectedBaseView
      ? selectedBaseView.fields[field]
      : undefined;

    setGridConfig({
      ...gridConfig,
      fields: [...fields, field],
      fieldAliases: [
        ...(gridConfig.fieldAliases || []),
        {
          field,
          alias:
            baseViewField && baseViewField.nameAlias
              ? baseViewField.nameAlias
              : _.startCase(field),
        },
      ],
    });
  };

  const onRemoveField = (field: string) => {
    setGridConfig({
      ...gridConfig,
      fields: fields.filter((f) => f !== field),
      sort: sort.filter((s) => s.field !== field),
      fieldAliases: gridConfig.fieldAliases
        ? gridConfig.fieldAliases.filter((a) => a.field !== field)
        : undefined,
    });
  };

  const onAddSort = (field: string, direction: SortBy) => {
    setGridConfig({
      ...gridConfig,
      sort: [...sort, { field, direction }],
    });
  };

  const onRemoveSort = (field: string) => {
    setGridConfig({
      ...gridConfig,
      sort: sort.filter((s) => s.field !== field),
    });
  };

  const onDisableLinksChanged = (newValue: boolean) => {
    setGridConfig({
      ...gridConfig,
      disableLinks: newValue,
    });
  };

  const onFieldAliasChanged = (
    field: string,
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setGridConfig((currentConfig) => {
      if (currentConfig) {
        return {
          ...currentConfig,
          fieldAliases: (currentConfig.fieldAliases || []).map((fa) => {
            if (fa.field === field) {
              return {
                field: fa.field,
                alias: event.target.value,
              };
            } else {
              return fa;
            }
          }),
        };
      } else {
        return undefined;
      }
    });
  };

  const onDrop = (result: DropResult) => {
    const { draggableId, destination } = result;
    if (!destination) {
      return;
    }

    if (gridConfig.fieldAliases) {
      const itemsWithoutDropped = gridConfig.fieldAliases.filter(
        (wb) => wb.field !== draggableId,
      );
      const theItem = gridConfig.fieldAliases.find(
        (wbId) => wbId.field === draggableId,
      );
      if (!theItem) {
        throw new Error('Item not found');
      }

      const newFields = [
        ...itemsWithoutDropped.slice(0, destination.index),
        theItem,
        ...itemsWithoutDropped.slice(
          destination.index,
          itemsWithoutDropped.length,
        ),
      ];

      setGridConfig({
        ...gridConfig,
        fieldAliases: newFields,
      });
    } else {
      const itemsWithoutDropped = gridConfig.fields.filter(
        (wb) => wb !== draggableId,
      );
      const theItem = gridConfig.fields.find((wbId) => wbId === draggableId);
      if (!theItem) {
        throw new Error('Item not found');
      }

      const newFields = [
        ...itemsWithoutDropped.slice(0, destination.index),
        draggableId,
        ...itemsWithoutDropped.slice(
          destination.index,
          itemsWithoutDropped.length,
        ),
      ];

      setGridConfig({
        ...gridConfig,
        fields: newFields,
      });
    }
  };

  return (
    <SimpleGridConfigInput
      dataType={dataType}
      dataSourceOptions={dataSourceOptions}
      fieldOptions={fieldOptions}
      fields={fields}
      onAddField={onAddField}
      onRemoveField={onRemoveField}
      sort={sort}
      onAddSort={onAddSort}
      onRemoveSort={onRemoveSort}
      onDrop={onDrop}
      disableLinks={disableLinks}
      onDisableLinksChanged={onDisableLinksChanged}
      fieldAliases={gridConfig.fieldAliases || []}
      onFieldAliasChanged={onFieldAliasChanged}
    />
  );
};

export default SimpleGridConfigInputContainer;
