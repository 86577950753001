import React, { useCallback, useContext, useState } from 'react';
import isDefined from 'isDefined';
import CostsWizardContext from '../../context/CostsWizardContext';
import useGroupFieldsOptions from '../hooks/useGroupFieldsOptions';
import useGroupValuesOptions from '../hooks/useGroupValuesOptions';
import GroupStep from './GroupStep';
import { useGroupNameValues } from 'components/TargetManager/hooks/useIsDuplicateRuleAvailable';

const GroupStepContainer = () => {
  const { wizardState, setWizardState } = useContext(CostsWizardContext);
  const { options: groupFieldsOptions, dataType } = useGroupFieldsOptions();
  const [mustExistFilters] = useState<string[]>(['truck']);

  const groupNameValues = useGroupNameValues({
    groupField: wizardState.config.groupStep.field,
    dataType: dataType || '',
    mustExistFilters,
  });

  const popupOptions = useGroupValuesOptions({
    dataType,
    groupNameValues,
  });

  const genGroupName = useCallback(
    ({ fields }: { fields: string[] }) => {
      const nameFields = fields.join(', ');
      const name = wizardState.config.groupStep.field + ' ' + nameFields;

      return name;
    },
    [wizardState.config.groupStep.field],
  );

  const handleAddGroup = useCallback(() => {
    const fields = wizardState.config.groupStep.currentGroup;
    const newGroups = [
      { groupName: genGroupName({ fields }), fields },
      ...wizardState.config.groupStep.groups,
    ];
    setWizardState({
      ...wizardState,
      config: {
        ...wizardState.config,
        groupStep: {
          ...wizardState.config.groupStep,
          currentGroup: [],
          groups:
            wizardState.config.groupStep.groups.length === 0
              ? [
                  ...newGroups,
                  {
                    groupName: `All Other ${wizardState.config.groupStep.field}(s)`,
                  },
                ]
              : newGroups,
        },
      },
    });
  }, [genGroupName, setWizardState, wizardState]);

  const getGroupByName = useCallback(
    ({ name }: { name: string }) => {
      return wizardState.config.groupStep.groups.find(
        (g) => g.groupName === name,
      );
    },
    [wizardState.config.groupStep.groups],
  );

  const updateGroupOrder = useCallback(
    (newOrder: string[]) => {
      const newGroups = newOrder
        .map((name) => getGroupByName({ name }))
        .filter(isDefined);

      setWizardState({
        ...wizardState,
        config: {
          ...wizardState.config,
          groupStep: {
            ...wizardState.config.groupStep,
            currentGroup: [],
            groups: newGroups,
          },
        },
      });
    },
    [getGroupByName, setWizardState, wizardState],
  );

  return (
    <GroupStep
      groupFieldsOptions={groupFieldsOptions}
      popupOptions={popupOptions}
      groupNameValues={groupNameValues}
      handleAddGroup={handleAddGroup}
      updateGroupOrder={updateGroupOrder}
      getGroupByName={getGroupByName}
      dataType={dataType}
      genGroupName={genGroupName}
    />
  );
};

export default GroupStepContainer;
