import React from 'react';
import MetricListsContext from '../contexts/MetricListsContext';
import useMetricListGadgets from '../hooks/useMetricListGadgets';

const MetricListsProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { metricLists, metricListsLookup, isLoading } = useMetricListGadgets();

  return (
    <MetricListsContext.Provider
      value={{ metricListsLookup, metricLists, isLoading }}
    >
      {children}
    </MetricListsContext.Provider>
  );
};

export default MetricListsProvider;
