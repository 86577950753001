import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

const buildQuery = (value: string) => {
  return {
    query: gql`
      query getCustomers($value: String!, $limit: Int) {
        getCustomers(value: $value, limit: $limit) {
          billTo
          customer
        }
      }
    `,
    variables: {
      value,
      limit: 100,
    },
  };
};

const getCustomers = async (
  searchText: string,
  client: ApolloClient<NormalizedCacheObject>,
): Promise<CustomerSearchResult[]> => {
  const query = buildQuery(searchText);
  const response = await client.query(query);
  return response.data.getCustomers;
};

export default getCustomers;
