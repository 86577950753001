import React from 'react';

interface DatasetFiltersUsageLookupContextType {
  datasetFiltersLookup: FleetOps.DatasetFiltersUsageLookup;
}
const DatasetFiltersUsageLookupContext =
  React.createContext<DatasetFiltersUsageLookupContextType>({
    datasetFiltersLookup: {},
  });

export default DatasetFiltersUsageLookupContext;
