import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import WildcardPicker from './WildcardPicker';
import ReportDrillDownFormContext from '../../../../contexts/ReportDrillDownFormContext';
import useEditingDrillDown from '../useEditingDrillDown';
import getIdentifier from '../../../../getIdentifier';

const WildcardPickerContainer = ({
  mode,
  editingId,
}: {
  mode: TextFilterMode;
  editingId?: string;
}) => {
  const { editingWildcardDrillDown } = useEditingDrillDown();
  const { field, isEditing, dataset, onDrillDownConfirmed } = useContext(
    ReportDrillDownFormContext,
  );
  const isExclude =
    mode === 'does not contain' ||
    mode === 'does not start with' ||
    mode === 'does not end with';

  if (!field) {
    throw new Error('Wildcard Filter field not set');
  }

  const [patternInput, setPatterInput] = useState<string>(
    editingWildcardDrillDown
      ? editingWildcardDrillDown.wildcardFilter.pattern.replaceAll('*', '')
      : '',
  );

  const [wildcardFilter, setWildcardFilter] = useState<WildcardFilter>(
    editingWildcardDrillDown
      ? editingWildcardDrillDown.wildcardFilter
      : {
          field,
          pattern: '',
          exclude: isExclude,
        },
  );

  useEffect(() => {
    const newPattern = (() => {
      if (mode === 'contains' || mode === 'does not contain') {
        return `${patternInput}`;
      } else if (mode === 'starts with' || mode === 'does not start with') {
        return `${patternInput}*`;
      } else if (mode === 'ends with' || mode === 'does not end with') {
        return `*${patternInput}`;
      }
      return patternInput;
    })();

    const newWildcardFilter: WildcardFilter = {
      pattern: newPattern,
      exclude: isExclude,
      field,
    };

    setWildcardFilter(newWildcardFilter);
  }, [field, isExclude, mode, patternInput]);

  const onValueChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const newPatternValue = event.target.value;
    setPatterInput(newPatternValue);
  }, []);

  const onConfirm = useCallback(() => {
    const newDrillDown: WildCardDrillDown = {
      id: editingId ? editingId : getIdentifier(undefined, true),
      field,
      fieldType: 'text',
      dataset,
      mode: editingWildcardDrillDown
        ? editingWildcardDrillDown.mode
        : 'editing',
      isExclude,
      appliedByInteractionWithChartDefId: editingWildcardDrillDown
        ? editingWildcardDrillDown.appliedByInteractionWithChartDefId
        : undefined,
      wildcardFilter,
    };

    onDrillDownConfirmed(newDrillDown);
  }, [
    dataset,
    editingId,
    editingWildcardDrillDown,
    field,
    isExclude,
    onDrillDownConfirmed,
    wildcardFilter,
  ]);

  const isValid = patternInput !== '';

  return (
    <WildcardPicker
      pattern={patternInput}
      onValueChanged={onValueChanged}
      onConfirm={onConfirm}
      isEditing={isEditing}
      wildcardFilter={wildcardFilter}
      mode={mode}
      isValid={isValid}
      editingId={editingId}
    />
  );
};

export default WildcardPickerContainer;
