import React from 'react';

interface GlowingDivContextType {
  startGlow: () => void;
}

const GlowingDivContext = React.createContext<GlowingDivContextType>({
  startGlow: () => {
    console.warn('startGlow is not defined');
  },
});

export default GlowingDivContext;
