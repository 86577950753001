import React from 'react';

interface V5GadgetFormContextType {
  isEditing: boolean;
  series: V5ChartDefinitionSeries[];
  setSeries: React.Dispatch<React.SetStateAction<V5ChartDefinitionSeries[]>>;
  dimensionA?: Dimension;
  setDimensionA: React.Dispatch<React.SetStateAction<Dimension | undefined>>;
  dimensionB?: Dimension;
  setDimensionB: React.Dispatch<React.SetStateAction<Dimension | undefined>>;
  stackedBarOptions: StackedBarOptions | undefined;
  setStackedBarOptions: (opts: StackedBarOptions) => void;
  stackedAreaOptions: StackedAreaOptions | undefined;
  setStackedAreaOptions: (opts: StackedAreaOptions) => void;
  draftChart?: V5ChartDefinition;
  isLoading: boolean;
  selectedSource?: GadgetSource;
  onSave: () => void;
  onSaveAs: () => void;
  onDelete: () => void;
  isDimensionalChart: boolean;
  isMultipleDimensionChart: boolean;
  isMultipleSeriesChart: boolean;
  isMultipleYAxisChart: boolean;
  showDataLabels?: boolean;
  setShowDataLabels: React.Dispatch<React.SetStateAction<undefined | boolean>>;
  groupByLimitMode: 'top n' | 'show all' | undefined;
  setGroupByLimitMode: React.Dispatch<
    React.SetStateAction<'top n' | 'show all' | undefined>
  >;
  setGroupByLimit: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const V5GadgetFormContext = React.createContext<V5GadgetFormContextType>({
  isEditing: false,
  series: [],
  setSeries: () => {},
  setDimensionA: () => {},
  setDimensionB: () => {},
  stackedBarOptions: undefined,
  setStackedBarOptions: () => {},
  stackedAreaOptions: undefined,
  setStackedAreaOptions: () => {},
  draftChart: {
    id: '',
    name: '',
    gadgetType: 'bar',
    series: [],
  },
  isLoading: false,
  onSave: () => {},
  onSaveAs: () => {},
  onDelete: () => {},
  isDimensionalChart: false,
  isMultipleDimensionChart: false,
  isMultipleSeriesChart: false,
  isMultipleYAxisChart: false,
  showDataLabels: false,
  setShowDataLabels: () => {},
  groupByLimitMode: undefined,
  setGroupByLimitMode: () => {},
  setGroupByLimit: () => {},
});

export default V5GadgetFormContext;
