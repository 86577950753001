import base from './baseOptions';
import formatFloat from '../../../api/getChartSeries/formatFloat';
import buildDrillDown from '../../../buildDrillDown';

const pie = ({
  formatMetric,
  title,
  precision,
  openMenu,
  groupField,
  fieldType,
  chartDef,
  fontSize,
}) => ({
  ...base(formatMetric),
  chart: {
    ...base.chart,
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie',
  },
  title: {
    ...base(formatMetric).title,
    text: title,
  },
  plotOptions: {
    pie: {
      borderRadius: '0px',
      innerSize: '50%',
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        formatter: function () {
          const name = this.point.name;
          return `<span style="color: #434348">${name}</span>`;
        },
        style: {
          fontSize,
        },
      },

      showInLegend: true,
      point: {
        events: {
          click: function (e) {
            const terms = e.point.series.data;
            const selectedIndex = e.point.x;

            if (openMenu) {
              const { metricIds } = this.series.userOptions;
              const selectedTerm = terms.find((t, index) => {
                return index === selectedIndex;
              });
              openMenu(
                metricIds,
                buildDrillDown(
                  groupField,
                  fieldType,
                  selectedTerm.name,
                  chartDef,
                ),
              );
            } else {
              const { onDrillDown } = this.series.userOptions;
              if (!onDrillDown) {
                alert('Coming soon...');
                return;
              }
              onDrillDown(terms[selectedIndex].name, e.ctrlKey || e.metaKey);
            }
          },
        },
      },
    },
  },

  legend: {
    ...base(formatMetric).legend,
    enabled: false,
    verticalAlign: 'bottom',
    align: 'left',
    y: 68,
    style: {
      labels: {
        fontSize,
      },
    },
  },

  tooltip: {
    ...base(formatMetric).tooltip,
    pointFormatter: function () {
      const seriesname = this.series.name;
      const value = formatFloat(this.y, precision);
      return `<span style="color:{point.color};">\u25CF </span><b><span style="color: #878F9D;">${seriesname}</span><b> ${value}%</b><br/>`;
    },
  },
});

export default pie;
