import React from 'react';
import Inputs from '../../Inputs';
import KeywordPicker from './KeywordPicker';
import WildcardPicker from './WildcardPicker';
import useEditingDrillDown from './useEditingDrillDown';
import useTextFilterMode from './useTextFilterMode';
import Alert from '../../../kingpin/Alert';

const TextDrillDownForm = ({ editingId }: { editingId?: string }) => {
  const { mode, modeOptions } = useTextFilterMode();

  const isKeywordMode =
    !!mode && (mode === 'is one of' || mode === 'is not one of');
  const isWildcardMode = !!mode && !isKeywordMode;

  return (
    <div style={{ padding: '8px 0px' }}>
      <div style={{ marginBottom: 8, padding: '0px 8px' }}>
        <Inputs.Dropdown options={modeOptions} placeholder="" />
      </div>
      {mode !== 'unknown' && (
        <>
          {isKeywordMode && <KeywordPicker mode={mode} editingId={editingId} />}
          {isWildcardMode && (
            <WildcardPicker mode={mode} editingId={editingId} />
          )}
        </>
      )}
      {mode === 'unknown' && (
        <Alert
          title={'Unknown Filter Found'}
          body={'Please rebuild this filter'}
          type={'Warning'}
        />
      )}
    </div>
  );
};

const Gate = () => {
  const { editingTextDrillDown, editingWildcardDrillDown } =
    useEditingDrillDown();
  const editingId = editingTextDrillDown
    ? editingTextDrillDown.id
    : editingWildcardDrillDown
      ? editingWildcardDrillDown.id
      : undefined;

  return <TextDrillDownForm editingId={editingId} />;
};

export default Gate;
