import firebase from 'firebase/compat/app';

const createComment = async (
  comment: CommentType,
  accountRef: firebase.firestore.DocumentReference,
) => {
  return accountRef.collection('comments').doc(comment.id).set(comment);
};

export default createComment;
