import React, { useCallback, useContext, useEffect, useState } from 'react';
import FilterBuilderContext from '../FilterBuilderContext';
import GqlClientContext from '../../../../contexts/GqlClientContext';
import AccountPickerContext from '../../../../contexts/AccountPickerContext';
import getFilterValues from '../../../../api/getFilterValues';
import relativeDateRangeToDateRange from '../../../../relativeDateRangeToDateRange';
import { LAST_180_DAYS } from '../../../DateInput/constants';

const DEMO_NCI_ACCOUNT_ID = 'demonci';

const useTextValues = (field: string | undefined) => {
  const { client } = useContext(GqlClientContext);
  const { selectedAccount } = useContext(AccountPickerContext);
  const { datasetDefinition } = useContext(FilterBuilderContext);
  const [values, setValues] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (field === undefined) {
      setValues([]);
      return;
    }

    let isActive = true;

    const filterInput = {
      dataType: [datasetDefinition.type],
    } as FilterInput;

    setIsLoading(true);
    const dateScope = relativeDateRangeToDateRange({
      relativeDateRange: LAST_180_DAYS,
      startOfWeek: 'SUN', // does not matter
    });

    getFilterValues({
      field,
      filterInput,
      dateScope:
        selectedAccount.accountId === DEMO_NCI_ACCOUNT_ID
          ? { ...dateScope, startDate: undefined }
          : dateScope,
      accountId: selectedAccount.accountId,
      client,
    }).then((values) => {
      if (!isActive) {
        return;
      }
      setValues(values);
      setIsLoading(false);
    });

    return () => {
      isActive = false;
    };
  }, [client, datasetDefinition.type, field, selectedAccount.accountId]);

  return { values, isLoading };
};

const useTextOptions = ({
  value,
  setValue,
  field,
}: {
  value: string | number | string[] | boolean | undefined;
  setValue: React.Dispatch<
    React.SetStateAction<string | number | string[] | boolean | undefined>
  >;
  field?: string;
}) => {
  const { values: textValues, isLoading } = useTextValues(field);
  const [options, setOptions] = useState<DropdownOption[]>([]);

  const getIsSelected = useCallback(
    (v: string) => {
      if (!Array.isArray(value)) {
        return false;
      }

      return value.includes(v);
    },
    [value],
  );

  useEffect(() => {
    setOptions(
      textValues.map((v) => ({
        label: v,
        isSelected: getIsSelected(v),
        onSelected: () => {
          setValue((currentValue) => {
            if (!Array.isArray(currentValue)) {
              return [v];
            }

            const isSelected = getIsSelected(v);
            if (isSelected) {
              return currentValue.filter((cv) => cv !== v);
            } else {
              return [...currentValue, v];
            }
          });
        },
      })),
    );
  }, [getIsSelected, setValue, textValues]);

  return { options, isLoading };
};

export default useTextOptions;
