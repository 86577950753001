import { useCallback, useEffect, useState } from 'react';
import {
  IPaceHistogramResponseHistogramPoint,
  IPaceRow,
  IPaceRowHistogram,
} from '../types';
import usePaceQuery from './usePaceQuery';

const useRowData = ({
  paceMatrix,
}: {
  paceMatrix: VisualisationDefinitions.PaceMatrix;
}) => {
  const {
    response,
    isLoadingResponse,
    getActualDateRange,
    getAverageDateRange,
    getExcludedDateRangeLabels,
  } = usePaceQuery({ paceMatrix });
  const [totalsRowData, setTotalsRowData] = useState<IPaceRow[]>([]);
  const [rowData, setRowData] = useState<IPaceRow[]>([]);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);

  const buildHistogramRowData = useCallback(
    (points: IPaceHistogramResponseHistogramPoint[]): IPaceRowHistogram => {
      const histogram: IPaceRowHistogram = {};
      points.forEach((p) => {
        histogram[p.date] = {
          date: p.date,
          average: p.average,
          actual: p.actual,
          actualDiff: p.actualDiff,
        };
      });
      return histogram;
    },
    [],
  );

  useEffect(() => {
    if (isLoadingResponse || !response) {
      setIsLoadingData(true);
      setRowData([]);
      setTotalsRowData([]);
      return;
    }

    setRowData(
      response.terms.map((r) => ({
        groupByField: r.name,
        histogram: buildHistogramRowData(r.histogram),
        totals: {
          actual: r.actual,
          actualDiff: r.actualDiff,
          pace: r.pace,
          paceDiff: r.paceDiff,
        },
      })),
    );
    setTotalsRowData([
      {
        groupByField: '',
        histogram: buildHistogramRowData(response.totals.histogram),
        totals: {
          actual: response.totals.actual,
          actualDiff: response.totals.actualDiff,
          pace: response.totals.pace,
          paceDiff: response.totals.paceDiff,
        },
      },
    ]);
    setIsLoadingData(false);
  }, [buildHistogramRowData, isLoadingResponse, response]);

  return {
    rowData,
    totalsRowData,
    isLoadingData,
    getActualDateRange,
    getAverageDateRange,
    getExcludedDateRangeLabels,
  };
};

export default useRowData;
