import React, { useContext, useState } from 'react';
import { ChartTopBar } from '../Chart';
import CanvasCard from '../Visualisations/CanvasCard';
import RemindersGadgetContent from './RemindersGadgetContent';
import DashboardGadgetContext from '../../contexts/DashboardGadgetContext';
import ShowOnMouseOverUnlessTour from '../ShowOnMouseOverUnlessTour';
import Row from '../Common/Row';
import DateInput from '../DateInput';
import Inputs from '../Inputs';
import CardContext from '../../contexts/CardContext';
import CanvasContext from '../../contexts/CanvasContext';

const EventTypeSelector = ({
  allowedEvents,
  eventTypes,
  setEventTypes,
  isSmall,
  onOpened,
  onClosed,
}: {
  allowedEvents: string[];
  eventTypes: string[];
  setEventTypes: React.Dispatch<React.SetStateAction<string[]>>;
  isSmall?: boolean;
  onOpened: () => void;
  onClosed: () => void;
}) => {
  const options = allowedEvents.map((t) => ({
    label: t,
    isSelected: eventTypes.includes(t),
    onSelected: () => {
      setEventTypes((currentTypes) => {
        if (currentTypes.includes(t)) {
          return currentTypes.filter((ct) => ct !== t);
        } else {
          return [...currentTypes, t];
        }
      });
    },
  }));

  const selectedLabel = (() => {
    if (eventTypes.length === 0) {
      return 'Type: All';
    } else if (eventTypes.length === 1) {
      return `Type: ${eventTypes[0]}`;
    } else if (eventTypes.length === 2) {
      return `Type: ${eventTypes[0]} and 1 other`;
    } else {
      return `Type: ${eventTypes[0]} and ${eventTypes.length - 1} others`;
    }
  })();

  const onClearClicked = () => {
    setEventTypes([]);
  };

  return (
    <div style={{ width: isSmall ? 100 : 'unset' }}>
      <Inputs.Dropdown
        options={options}
        selectedLabel={selectedLabel}
        isMulti
        onClearClicked={onClearClicked}
        onOpen={onOpened}
        onClose={onClosed}
        buttonStyle={{ height: 24 }}
      />
    </div>
  );
};

const BottomBar = ({
  onDatePickerOpen,
  onDatePickerClose,
}: {
  onDatePickerOpen: () => void;
  onDatePickerClose: () => void;
  isForcedVisible: boolean;
}) => {
  const { dashboardGadget, isEditing } = useContext(DashboardGadgetContext);
  if (dashboardGadget === undefined || isEditing) {
    return null;
  }

  return (
    <ShowOnMouseOverUnlessTour isForcedVisible={true}>
      <Row>
        <DateInput
          onDatePickerOpen={onDatePickerOpen}
          onDatePickerClose={onDatePickerClose}
        />
      </Row>
    </ShowOnMouseOverUnlessTour>
  );
};

const RemindersGadgetContainer = ({
  gadget,
}: {
  gadget: VisualisationDefinitions.Reminders;
}) => {
  const [allowedEvents, setAllowedEvents] = useState<string[]>(gadget.events);
  const [eventTypes, setEventTypes] = useState<string[]>([]);
  const { i, w } = useContext(CardContext);
  const { setCardOnTopI } = useContext(CanvasContext);
  const isSmall = w < 4;
  const [isForcedVisible, setIsForcedVisible] = useState<boolean>(false);
  const onDatePickerOpen = () => {
    setCardOnTopI(i);
    setIsForcedVisible(true);
  };
  const onDatePickerClose = () => {
    setIsForcedVisible(false);
  };

  return (
    <CanvasCard
      isContentWithoutPadding
      content={
        <RemindersGadgetContent
          gadget={gadget}
          eventTypes={eventTypes}
          allowedEvents={allowedEvents}
          setAllowedEvents={setAllowedEvents}
        />
      }
      bottomBar={
        <BottomBar
          isForcedVisible={isForcedVisible}
          onDatePickerClose={onDatePickerClose}
          onDatePickerOpen={onDatePickerOpen}
        />
      }
      topBar={
        <ChartTopBar
          chartDefinition={gadget}
          isForcedVisible={isForcedVisible}
          extraControl={
            <EventTypeSelector
              allowedEvents={allowedEvents}
              eventTypes={eventTypes}
              setEventTypes={setEventTypes}
              isSmall={isSmall}
              onOpened={onDatePickerOpen}
              onClosed={onDatePickerClose}
            />
          }
        />
      }
    />
  );
};

export default RemindersGadgetContainer;
