import { Route, Routes } from 'react-router-dom';
import React, { lazy } from 'react';
import appRoutes from './appRoutes';

const SharedIndex = lazy(() => import('../screens/SharedIndex'));
const WorkSpacesIndex = lazy(() => import('../screens/WorkSpacesIndex'));
const WorkSpacesShow = lazy(() => import('../screens/WorkSpacesShow'));
const DashboardsIndex = lazy(() => import('../screens/Dashboards'));
const DashboardShow = lazy(() => import('../screens/DashboardShow'));
const ReportsIndex = lazy(() => import('../screens/Reports'));
const ReportShow = lazy(() => import('../screens/ReportShow'));
const ScorecardsIndex = lazy(() => import('../screens/ScorecardsIndex'));
const ScorecardShow = lazy(() => import('../screens/ScorecardShow'));
const TargetsAppShow = lazy(() => import('../screens/TargetsAppShow'));
const PortalUsers = lazy(() => import('../screens/PortalUsers'));

const ExecutivePortalSwitch = ({ portal }: { portal: ExecutivePortal }) => {
  return (
    <Routes>
      <Route path={appRoutes.loggedIn.shared} element={<SharedIndex />} />
      <Route
        path={appRoutes.loggedIn.workspaces}
        element={<WorkSpacesIndex />}
      />
      <Route
        path={appRoutes.loggedIn.showWorkSpace}
        element={<WorkSpacesShow />}
      />
      <Route
        path={appRoutes.loggedIn.showWorkSpaceTab}
        element={<WorkSpacesShow />}
      />
      <Route
        path={appRoutes.loggedIn.dashboards}
        element={<DashboardsIndex />}
      />
      <Route
        path={appRoutes.loggedIn.showDashboard}
        element={<DashboardShow />}
      />
      <Route path={appRoutes.loggedIn.reports} element={<ReportsIndex />} />
      <Route path={appRoutes.loggedIn.showReport} element={<ReportShow />} />
      <Route path={appRoutes.loggedIn.kpiLists} element={<ScorecardsIndex />} />
      <Route
        path={appRoutes.loggedIn.showTargetApp}
        element={<TargetsAppShow />}
      />
      <Route
        path={appRoutes.loggedIn.showGlobalKpiList}
        element={<ScorecardShow />}
      />
      <Route path={'/users'} element={<PortalUsers portal={portal} />} />
    </Routes>
  );
};

export default ExecutivePortalSwitch;
