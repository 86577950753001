import isAdvancedRollingWindow from './isAdvancedRollingWindow';

const isDateRangeValid = (
  dateRange: RelativeDateRange | AdvancedRelativeDateRange,
) => {
  const isRangeValid = (range: RelativeDateRange) => {
    if (range.type === 'current') {
      return true;
    }

    return range.n !== undefined;
  };
  if (isAdvancedRollingWindow(dateRange)) {
    const isToValid = isRangeValid(dateRange.to);
    const isFromValid = isRangeValid(dateRange.from);
    return isToValid && isFromValid;
  }

  return isRangeValid(dateRange);
};

export default isDateRangeValid;
