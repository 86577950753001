import moment from 'moment';
import getEndMoment from './getEndMoment';

export const getRemainingIntervals = (
  goal: GeneralGoal,
  isDemoAccount: boolean,
  demoAccountNow?: string,
) => {
  const endMoment = getEndMoment(goal);
  if (!endMoment) {
    return 0;
  }

  const now = moment
    .utc(isDemoAccount && demoAccountNow ? demoAccountNow : undefined)
    .startOf('day');
  const { fixedStartDate } = goal;
  const startMoment = moment.utc(fixedStartDate);
  const hasStarted = now.isAfter(startMoment);
  const hasEnded = now.isAfter(endMoment);

  if (!hasStarted) {
    return Math.abs(startMoment.diff(endMoment, 'days')) + 1;
  }

  if (hasEnded) {
    return 0;
  }

  return Math.abs(now.diff(endMoment, 'days')) + 1;
};

export default getRemainingIntervals;
