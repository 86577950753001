import React, { useContext } from 'react';

import Button from 'kingpin/atoms/Button';
import TargetsDragHandleContext from '../../contexts/TargetsDragHandleContext';

const DragHandle = () => {
  const { dragHandleProps, isGroupBlock } = useContext(
    TargetsDragHandleContext,
  );

  if (!dragHandleProps) {
    return null;
  }

  return (
    <div
      {...dragHandleProps}
      style={{ marginRight: 8 }}
      data-testid={isGroupBlock ? 'group-drag-handle' : 'target-drag-handle'}
    >
      <Button icon="drag" type="Ghost" size="Small" />
    </div>
  );
};

export default DragHandle;
