import React, { useCallback, useContext, useState } from 'react';
import PublishMetricsPopup from './PublishMetricsPopup';
import AccountPickerContext from '../../../../contexts/AccountPickerContext';
import ValidationReportContext from '../../../../contexts/ValidationReportContext';
import CurrentUserContext from '../../../../contexts/CurrentUserContext';
import getTimeStamp from '../../../../getTimeStamp';
import updateMetric from '../../../../api/metrics/updateMetric';
import getIdentifier from '../../../../getIdentifier';
import addTimelineEvent from '../../../../contextProviders/TimelineProvider/addTimelineEvent';
import updateCompoundMetric from '../../../../api/compoundMetrics/updateCompoundMetric';

const PublishMetricsPopupContainer = ({ close }: { close: () => void }) => {
  const { selectedAccountId } = useContext(AccountPickerContext);
  const { metrics, compoundMetrics } = useContext(ValidationReportContext);
  const currentUser = useContext(CurrentUserContext);

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [unpublishedMetrics, setUnpublishedMetrics] = useState<
    {
      metric: Metrics.NormalMetric;
      isConfirmed: boolean;
    }[]
  >(() =>
    metrics
      .filter((m) => m.status !== 'core' && m.status !== 'single use')
      .map((m) => ({ metric: m, isConfirmed: true })),
  );
  const [unpublishedCompoundMetrics, setUnpublishedCompoundMetrics] = useState<
    {
      compoundMetric: Metrics.CompoundMetric;
      isConfirmed: boolean;
    }[]
  >(() =>
    compoundMetrics
      .filter((m) => m.status !== 'core' && m.status !== 'single use')
      .map((cm) => ({ compoundMetric: cm, isConfirmed: true })),
  );

  const onPublishConfirmed = useCallback(() => {
    setIsSaving(true);
    const metricPromises = unpublishedMetrics
      .filter((m) => m.isConfirmed)
      .map((m) => {
        const newM = {
          ...m.metric,
          status: 'core' as 'core',
          updatedBy: currentUser.id,
          updatedOn: getTimeStamp(),
        };

        return updateMetric(newM.id, newM, selectedAccountId).then(() => {
          const event = {
            id: getIdentifier(),
            timelineableId: newM.id,
            timelineableType: 'Metric' as 'Metric',
            createdOn: getTimeStamp(),
            createdBy: currentUser.id,
            userId: currentUser.id,
            headlineText: {
              userDisplayName: currentUser.displayName,
              actionText: 'Added metric to core list',
              contextText: `"${newM.name}"`,
            },
          };

          return addTimelineEvent(event, selectedAccountId);
        });
      });

    const compoundMetricPromises = unpublishedCompoundMetrics
      .filter((cm) => cm.isConfirmed)
      .map((m) => {
        const newM = {
          ...m.compoundMetric,
          status: 'core' as 'core',
        };

        return updateCompoundMetric(newM.id, newM, selectedAccountId).then(
          () => {
            const event = {
              id: getIdentifier(),
              timelineableId: newM.id,
              timelineableType: 'CompoundMetric' as 'CompoundMetric',
              createdOn: getTimeStamp(),
              createdBy: currentUser.id,
              userId: currentUser.id,
              headlineText: {
                userDisplayName: currentUser.displayName,
                actionText: 'Added metric to core list',
                contextText: `"${newM.name}"`,
              },
            };

            return addTimelineEvent(event, selectedAccountId);
          },
        );
      });

    Promise.all([...metricPromises, ...compoundMetricPromises]).then(() => {
      setIsSaving(false);
      close();
    });
  }, [
    selectedAccountId,
    close,
    currentUser.displayName,
    currentUser.id,
    unpublishedCompoundMetrics,
    unpublishedMetrics,
  ]);

  const onMetricToggled = useCallback((metric: Metrics.Metric) => {
    setUnpublishedMetrics((up) =>
      up.map((m) => {
        if (m.metric.id === metric.id) {
          return {
            ...m,
            isConfirmed: !m.isConfirmed,
          };
        }

        return m;
      }),
    );
  }, []);

  const onCompoundMetricToggled = useCallback(
    (compoundMetric: Metrics.CompoundMetric) => {
      setUnpublishedCompoundMetrics((up) =>
        up.map((cm) => {
          if (cm.compoundMetric.id === compoundMetric.id) {
            return {
              ...cm,
              isConfirmed: !cm.isConfirmed,
            };
          }

          return cm;
        }),
      );
    },
    [],
  );

  const getSelectedCount = useCallback(() => {
    const confirmedM = unpublishedMetrics.filter((m) => m.isConfirmed);
    const confirmedCM = unpublishedCompoundMetrics.filter(
      (cm) => cm.isConfirmed,
    );

    return confirmedM.length + confirmedCM.length;
  }, [unpublishedCompoundMetrics, unpublishedMetrics]);

  return (
    <PublishMetricsPopup
      close={close}
      onPublishConfirmed={onPublishConfirmed}
      isSaving={isSaving}
      unpublishedCompoundMetrics={unpublishedCompoundMetrics}
      unpublishedMetrics={unpublishedMetrics}
      onCompoundMetricToggled={onCompoundMetricToggled}
      onMetricToggled={onMetricToggled}
      getSelectedCount={getSelectedCount}
    />
  );
};

export default PublishMetricsPopupContainer;
