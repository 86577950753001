import isNormalMetric from './isNormalMetric';

const isSingleUseMetric = (
  m: Metrics.Metric | undefined,
): m is Metrics.SingleUseMetric => {
  if (!m) {
    return false;
  }

  return isNormalMetric(m) && m.status === 'single use';
};

export default isSingleUseMetric;
