const injectAggFuncs = (
  datasets: FleetOps.DatasetDefinition[],
  aggFuncDefs: FleetOps.FieldAggFuncDefinition[],
): FleetOps.DatasetDefinition[] => {
  return datasets.map((ds) => {
    return {
      ...ds,
      fields: ds.fields.map((field) => {
        const aggFuncsDef = aggFuncDefs.find((f) => f.fieldType === field.type);
        if (aggFuncsDef) {
          return {
            ...field,
            aggFuncs: aggFuncsDef.aggFuncs,
          };
        } else {
          return {
            ...field,
            aggFuncs: [],
          };
        }
      }),
    };
  });
};

export default injectAggFuncs;
