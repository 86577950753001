import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import Colors2 from 'theme/Colors2';
import Typography from 'kingpin/atoms/Typography';
import Row from 'components/Common/Row';
import Tooltip from 'components/Tooltip';
import { Col } from './ActivityGrid';
import CostsShowContext from '../context/CostsShowContext';
import ActivityGridContext from '../context/ActivityGridContext';
import { COSTS_LIST_ITEM_HEIGHT } from '../consts';
import useHoverRow from './hooks/useHoverRow';

const HeadingCol = styled.div<{
  strongBorderRight?: boolean;
}>`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${COSTS_LIST_ITEM_HEIGHT}px;
  padding: 8px 0;
  border-right: ${(props) =>
    props.strongBorderRight
      ? `1px solid ${Colors2.Grey['7']}`
      : `1px solid ${Colors2.GridBorder}`};
  border-bottom: 1px solid ${Colors2.GridBorder};
`;

const BoldTextOnHover = styled.div<{ isHovered: boolean }>`
  ${(props) =>
    props.isHovered &&
    `
  span {
    font-weight: 600 !important;
  }
`}
  span {
    font-weight: 500;
  }
`;

const ColumnItem = ({
  interval,
  isLastInterval,
  isHovered,
}: {
  interval: Period;
  isLastInterval: boolean;
  isHovered: boolean;
}) => {
  const { isSavingError, invalidIntervals, checkIsCpmEstimated } =
    useContext(ActivityGridContext);
  const { costModel } = useContext(CostsShowContext);
  const { disableHover } = useHoverRow();

  const isEstimated = useMemo(
    () => checkIsCpmEstimated(interval.startDate),
    [checkIsCpmEstimated, interval.startDate],
  );

  const isInvalid =
    isSavingError &&
    invalidIntervals.find(
      (invalidInterval) => invalidInterval.startDate === interval.startDate,
    );

  return (
    <Col minWidth style={{ flexDirection: 'column' }}>
      <div
        onMouseEnter={disableHover}
        style={{
          backgroundColor: isHovered ? '#F9F9F9' : undefined,
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Tooltip
          content={
            isLastInterval || isEstimated
              ? `The current ${costModel ? costModel.interval : ''} (${
                  interval.label
                }) displays estimated Costs`
              : undefined
          }
        >
          <div
            style={{
              borderRight: `1px solid ${
                isInvalid ? Colors2.Secondary.error : Colors2.Grey['7']
              }`,
              borderBottom: `1px solid ${
                isInvalid ? Colors2.Secondary.error : Colors2.GridBorder
              }`,
              borderTop: `1px solid ${
                isInvalid ? Colors2.Secondary.error : Colors2.GridBorder
              }`,
              borderLeft: isInvalid
                ? `1px solid ${Colors2.Secondary.error}`
                : undefined,
              height: `${COSTS_LIST_ITEM_HEIGHT}px`,
              display: 'flex',
              width: '100%',
              padding: '8px 0',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <BoldTextOnHover style={{ display: 'flex' }} isHovered={isHovered}>
              <Typography.Body type="Body 12" color={Colors2.Grey['1']}>
                {`${interval.label}${isLastInterval ? ' - Ongoing' : ''}`}
              </Typography.Body>
            </BoldTextOnHover>
          </div>
        </Tooltip>
      </div>

      <Row
        centerAlign
        onMouseEnter={disableHover}
        style={{
          backgroundColor: isHovered ? '#F9F9F9' : undefined,
          width: '100%',
        }}
      >
        {!isLastInterval && (
          <HeadingCol>
            <BoldTextOnHover isHovered={isHovered} style={{ display: 'flex' }}>
              <Typography.Body type="Body 12">Cost</Typography.Body>
            </BoldTextOnHover>
          </HeadingCol>
        )}

        <HeadingCol strongBorderRight>
          <Row centerAlign>
            <BoldTextOnHover style={{ display: 'flex' }} isHovered={isHovered}>
              <Typography.Body type="Body 12">CPM</Typography.Body>
            </BoldTextOnHover>
            {(isLastInterval || isEstimated) && (
              <div
                data-testid={`estimated-${interval.startDate}`}
                style={{
                  backgroundColor: Colors2.AvatarColors[1].background,
                  borderRadius: '4px',
                  marginLeft: '8px',
                }}
              >
                <div style={{ padding: '0 4px', display: 'flex' }}>
                  <Typography.Body
                    type="Body 12"
                    color={Colors2.AvatarColors[1].text}
                  >
                    Estimated
                  </Typography.Body>
                </div>
              </div>
            )}
          </Row>
        </HeadingCol>
      </Row>
    </Col>
  );
};

const ColumnHeadingsRight = () => {
  const { intervals, focusedInterval } = useContext(ActivityGridContext);

  return (
    <Row
      centerAlign
      style={{
        position: 'sticky',
        top: 0,
        backgroundColor: 'white',
        zIndex: 1,
      }}
    >
      {intervals.map((p) => (
        <ColumnItem
          key={p.label}
          interval={p}
          isLastInterval={p === intervals[intervals.length - 1]}
          isHovered={p === focusedInterval}
        />
      ))}
    </Row>
  );
};

export default ColumnHeadingsRight;
