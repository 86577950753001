import { useCallback, useContext, useEffect, useState } from 'react';
import useKpiFormatter from '../hooks/useKpiFormatter';
import { useGetTargetBands } from '../hooks/useGetTargetBands';
import useKpiValue from '../hooks/useKpiValue';
import useKpiColor from '../hooks/useKpiColor';
import ScorecardContext from 'contexts/ScorecardContext';
import chartColors from 'theme/chartColors';
import { getColName } from '../ColumnHeadingsRight';
import WallboardContext from 'contexts/WallboardContext';
import { getChartColor } from 'screens/ScorecardsIndex/constants';
import ChartColors from 'theme/chartColors';

const useOptions = ({
  kpi,
  metric,
  trend,
  slideoutPeriod,
}: {
  kpi: Scorecards.ScorecardKpi | Scorecards.ManualKpiRow;
  metric: Metrics.Metric | undefined;
  trend: Goals.TrendResult[];
  slideoutPeriod: Period;
}) => {
  const { scorecard } = useContext(ScorecardContext);
  const { isWallboard } = useContext(WallboardContext);
  const [options, setOptions] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const getTargetBands = useGetTargetBands(kpi);
  const getKpiValue = useKpiValue();
  const getKpiColor = useKpiColor();

  const { name, manualKpiFormatting } = useKpiFormatter({
    kpi,
    value: undefined,
    metric,
  });

  const { selectedPeriods } = useContext(ScorecardContext);

  const buildPoints = useCallback(
    ({
      isTarget,
    }: {
      isTarget: boolean;
    }): KpiTooltipChartTypes.PointOptionsObject[] => {
      return selectedPeriods.map((period) => {
        const { successThreshold } = getTargetBands(period);
        const value = getKpiValue({ trend, period });
        const { targetBandsForPeriod } = getTargetBands(period);
        const color = getKpiColor({ kpi, value, targetBandsForPeriod });
        const nonTargetColor =
          slideoutPeriod === period ? '#6464c8' : ChartColors[0];

        const colorToUse = isTarget
          ? undefined
          : color === 'unset'
            ? nonTargetColor
            : getChartColor({ color });

        return {
          y: isTarget ? successThreshold : value,
          color: colorToUse,
          actual: value,
          target: successThreshold,
          period,
          isColorOrderReversed: kpi.isColorOrderReversed,
          metric,
          manualKpiFormatting,
          type: isTarget
            ? 'Threshold'
            : colorToUse === nonTargetColor
              ? 'Non-Target'
              : 'Actual',
        };
      });
    },
    [
      getKpiColor,
      getKpiValue,
      getTargetBands,
      kpi,
      manualKpiFormatting,
      metric,
      selectedPeriods,
      slideoutPeriod,
      trend,
    ],
  );

  useEffect(() => {
    const formatting = metric ? metric.formatting : manualKpiFormatting || {};

    const marker = {
      enabled: false,
      symbol: 'circle',
      fillColor: 'white',
      lineWidth: 1,
      lineColor: chartColors[2],
      radius: 3,
    };

    const newOptions = {
      accessibility: {
        enabled: false,
      },
      title: {
        enabled: false,
        text: '',
      },
      chart: {
        type: 'column',
        styledMode: false,
        height: '200px',
      },
      xAxis: {
        type: 'datetime',
        categories: selectedPeriods.map((period) =>
          getColName(period, scorecard.cadence),
        ),
        labels: {
          style: {
            fontSize: isWallboard ? '14px' : '10px',
          },
        },
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      series: [
        {
          prefix: formatting.prefix,
          postfix: formatting.postfix,
          precision: formatting.precision,
          data: buildPoints({ isTarget: false }),
          id: kpi.id,
          kpiId: kpi.id,
          colorIndex: 2,
          marker,
          name: `${name} (Actual)`,
        },
        {
          prefix: formatting.prefix,
          postfix: formatting.postfix,
          precision: formatting.precision,
          name: `${name} (Target)`,
          kpiId: kpi.id,
          data: buildPoints({ isTarget: true }),
          type: 'line',
          dashStyle: 'dash', // css is making this transparent
          marker: {
            symbol: 'c-rect',
            lineWidth: 2,
            fillColor: '#352d43',
            lineColor: '#352d43',
            radius: 5,
          },
        },
      ],
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        shared: true,
        shadow: false,
        borderRadius: 8,
        padding: 0,
        outside: true,
        split: false,
        borderWidth: 0,
        backgroundColor: 'transparent',
      },
    };

    setIsLoading(true);
    setOptions(newOptions);
  }, [
    buildPoints,
    getKpiColor,
    getKpiValue,
    getTargetBands,
    isWallboard,
    kpi,
    kpi.id,
    manualKpiFormatting,
    metric,
    name,
    scorecard.cadence,
    selectedPeriods,
    slideoutPeriod,
    trend,
  ]);

  useEffect(() => {
    if (options) {
      setIsLoading(false);
    }
  }, [options]);

  return { options, isLoading };
};

export default useOptions;
