import { useCallback, useContext } from 'react';
import metricTypeCheckers from '../types/metricTypeCheckers';
import getIdentifier from '../getIdentifier';
import getTimeStamp from '../getTimeStamp';
import createMetric from '../api/metrics/createMetric';
import visTypeCheckers from '../types/visTypeCheckers';
import MetricOptionsContext from '../contexts/MetricOptionsContext';
import AccountPickerContext from '../contexts/AccountPickerContext';
import LocalTimelineContext from '../contexts/Timeline/LocalTimelineContext';
import CurrentUserContext from '../contexts/CurrentUserContext';

const useCopyVisHelpers = () => {
  const { metricOptionsLookup } = useContext(MetricOptionsContext);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const { addEvent } = useContext(LocalTimelineContext);
  const { id: currentUserId } = useContext(CurrentUserContext);

  const getMetricIdForNewVis = useCallback(
    async (metricId: string): Promise<string> => {
      const metric = metricOptionsLookup[metricId];
      if (!metric) {
        const e = new Error(metricId);
        e.name = 'getMetricIdForNewVis: Metric not found';
        throw e;
      }

      if (!metricTypeCheckers.isSingleUseMetric(metric)) {
        return metricId;
      }

      const newMetric = {
        ...metric,
        id: getIdentifier(),
        createdBy: currentUserId,
        createdOn: getTimeStamp(),
        updatedBy: currentUserId,
        updatedOn: getTimeStamp(),
      };

      await createMetric(newMetric, selectedAccountId);
      if (addEvent) {
        await addEvent({
          actionText: 'Created metric',
          contextText: `"${newMetric.name}"`,
          destinationOverride: {
            type: 'Metric',
            id: newMetric.id,
          },
        });
      }

      return newMetric.id;
    },
    [addEvent, currentUserId, metricOptionsLookup, selectedAccountId],
  );

  const generateNewVisDef = useCallback(
    async (vis: ExtendedVisualisationDefinition) => {
      if (visTypeCheckers.isSingleMetricDateMatrix(vis)) {
        return {
          ...vis,
          metricId: await getMetricIdForNewVis(vis.metricId),
        };
      } else if (visTypeCheckers.isPaceMatrix(vis)) {
        return {
          ...vis,
          metricId: await getMetricIdForNewVis(vis.metricId),
        };
      } else if (visTypeCheckers.isGauge(vis)) {
        return {
          ...vis,
          metricId: await getMetricIdForNewVis(vis.metricId),
        };
      } else if (visTypeCheckers.isRemindersGadget(vis)) {
        return vis;
      } else if (visTypeCheckers.isRankingMatrix(vis)) {
        const newMetricsPromises = vis.metrics.map(async (m) => {
          return {
            ...m,
            metricId: await getMetricIdForNewVis(m.metricId),
          };
        });
        const newMetrics = await Promise.all(newMetricsPromises);

        return {
          ...vis,
          metrics: newMetrics,
        };
      } else if (visTypeCheckers.isV5ChartDef(vis)) {
        const newSeriesPromises = vis.series.map(async (s) => {
          return {
            ...s,
            metricId: await getMetricIdForNewVis(s.metricId),
          };
        });
        const newSeries = await Promise.all(newSeriesPromises);
        return {
          ...vis,
          series: newSeries,
        };
      } else {
        const e = new Error();
        e.name = 'Could not generateNewVisDef for unknown vis type';
        throw e;
      }
    },
    [getMetricIdForNewVis],
  );

  const generateNewGoalDef = useCallback(
    async (goal: GeneralGoal) => {
      const newKpis = await Promise.all(
        goal.kpis.map(async (kpi) => ({
          ...kpi,
          metricId: await getMetricIdForNewVis(kpi.metricId),
        })),
      );
      return {
        ...goal,
        metricId: await getMetricIdForNewVis(goal.metricId),
        kpis: newKpis,
      };
    },
    [getMetricIdForNewVis],
  );

  return {
    generateNewVisDef,
    getMetricIdForNewVis,
    generateNewGoalDef,
  };
};

export default useCopyVisHelpers;
