const CELL_PREFIX = 'PACE_MATRIX';
export const PACE_CELLS = {
  GROUP: 'groupingCellRenderer',
  DIFF: `${CELL_PREFIX}_DIFF`,
  CELL: `${CELL_PREFIX}_CELL`,
};

export const ALLOWED_PACE_OPTIONS: RelativeDateRangeType[] = [
  'last 4 weeks',
  'last 13 weeks',
];
