import React from 'react';
import DatasetFiltersProvider from '../../screens/DataManager/DatasetFilters/providers/DatasetFiltersProvider';
import DatasetDefinitionsProvider from '../DatasetDefinitionsProvider';
import BaseViewsProvider from '../BaseViewsProvider';

const DatasetProviders = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => (
  <DatasetFiltersProvider>
    <DatasetDefinitionsProvider>
      <BaseViewsProvider>{children}</BaseViewsProvider>
    </DatasetDefinitionsProvider>
  </DatasetFiltersProvider>
);

export default DatasetProviders;
