import React, { useContext } from 'react';
import DateFieldsProvider from '../../../../contextProviders/DateFieldsProvider';
import { buildDateFieldOptions } from '../../../DateInput/DateFieldPicker';
import DateFieldsContext from '../../../../contexts/DateFieldsContext';
import Inputs from '../../../Inputs';
import useGetDatasetLabel from '../../../Inputs/MetricPicker/MetricPickerPopup/hooks/useGetDatasetLabel';

const DateFieldPicker = ({
  dateField,
  setDateField,
  dataTypes,
  trackQueryDateSelected,
}: {
  dateField?: string;
  setDateField: React.Dispatch<React.SetStateAction<string | undefined>>;
  dataTypes: string[];
  trackQueryDateSelected?: () => void;
}) => {
  const getDatasetLabel = useGetDatasetLabel();
  const { dateFields } = useContext(DateFieldsContext);

  const baseOptions = buildDateFieldOptions({
    dataTypes,
    dateFields,
    dateField,
    setDateField,
    trackQueryDateSelected,
    getDatasetLabel,
  }).map((o) => ({
    ...o,
    isSelected: dateField === o.value,
  }));

  const defaultOption = {
    label: 'None',
    value: undefined,
    onSelected: () => {
      setDateField(undefined);
    },
  };

  const options = [defaultOption, ...baseOptions];

  return (
    <div data-testid="date-field-picker">
      <Inputs.Dropdown
        options={options}
        selectedLabel={dateField}
        unsetWidth
        placeholder="Select query date"
      />
    </div>
  );
};

const Gate = ({
  dateField,
  setDateField,
  dataTypes,
  trackQueryDateSelected,
}: {
  dateField?: string;
  setDateField: React.Dispatch<React.SetStateAction<string | undefined>>;
  dataTypes: string[];
  trackQueryDateSelected?: () => void;
}) => {
  return (
    <DateFieldsProvider dataTypes={dataTypes}>
      <DateFieldPicker
        dateField={dateField}
        setDateField={setDateField}
        dataTypes={dataTypes}
        trackQueryDateSelected={trackQueryDateSelected}
      />
    </DateFieldsProvider>
  );
};

export default Gate;
