import React, { ComponentType } from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import usePopup from '../../../hooks/usePopup';
import FieldPickerPopup from './FieldPickerPopup';
import useModalWidth from '../../../hooks/useModalWidth';

const FieldsPicker = ({
  selectedFields,
  onFieldsConfirmed,
  Button,
  fieldRedList,
  fieldTypeRedList,
  datasetsGreenList,
  isPerformanceDatasetsAllowed,
  isSaving,
}: {
  selectedFields: {
    dataType: string;
    fields: string[];
  }[];
  onFieldsConfirmed: (
    confirmedFields: { dataType: string; fields: string[] }[],
  ) => void;
  Button: ComponentType<{ onClick: () => void }>;
  fieldRedList?: string[];
  fieldTypeRedList?: FleetOps.FieldType[];
  datasetsGreenList?: string[];
  // isPerformanceDatasetsAllowed was added in a rushed context.
  // -> Is there something better we can reach for here?
  isPerformanceDatasetsAllowed?: boolean;
  isSaving?: boolean;
}) => {
  const { isOpen, open, close } = usePopup();
  const modalWidth = useModalWidth();

  return (
    <div>
      <Button onClick={open} />
      <ModalTransition>
        {isOpen && (
          <Modal
            onClose={close}
            shouldScrollInViewport={false}
            autoFocus={false}
            width={modalWidth}
          >
            <FieldPickerPopup
              close={close}
              initialFields={selectedFields}
              onFieldsConfirmed={onFieldsConfirmed}
              datasetsGreenList={datasetsGreenList}
              fieldRedList={fieldRedList}
              fieldTypeRedList={fieldTypeRedList}
              isSaving={isSaving}
              isPerformanceDatasetsAllowed={isPerformanceDatasetsAllowed}
            />
          </Modal>
        )}
      </ModalTransition>
    </div>
  );
};

export default FieldsPicker;
