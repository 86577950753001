import React, { ChangeEvent } from 'react';

import Inputs from 'components/Inputs';
import Typography from 'kingpin/atoms/Typography';
import ShadowedList from 'components/ShadowedList';
import { LabelDiv } from 'screens/Boards/PerformanceBoardForm';
import IntervalPicker from '../../IntervalPicker/IntervalPicker';
import EmptyVis from '../../Sections/EmptyVis';
import Metric from '../../Sections/Metric';
import WidgetFooter from '../../WidgetFooter';
import { WidgetWrapper } from '../MetricSlideoutWizard';
import MetricListInput from './MetricListInput';

const MetricSlideoutWizard = ({
  saveWidget,
  title,
  onTitleChanged,
  widgetDateRange,
  setWidgetDateRange,
  isDisabled,
  filterInput,
  dateScope,
  metricIds,
  setMetricIds,
  metricSection,
  close,
}: {
  saveWidget: () => void;
  title: string;
  onTitleChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  widgetDateRange: PerformanceBoardTypes.SlideOut.SlideOutDateRange;
  setWidgetDateRange: React.Dispatch<
    React.SetStateAction<PerformanceBoardTypes.SlideOut.SlideOutDateRange>
  >;
  isDisabled: boolean;
  filterInput: FilterInput | undefined;
  metricIds: string[];
  setMetricIds: React.Dispatch<React.SetStateAction<string[]>>;
  dateScope: DateRangeInput | undefined;
  metricSection: PerformanceBoardTypes.SlideOut.MetricsSection | undefined;
  close: () => void;
}) => {
  return (
    <>
      <ShadowedList>
        <WidgetWrapper>
          <div style={{ marginBottom: 24 }}>
            <LabelDiv>
              <Typography.Body type="Label">Widget Title</Typography.Body>
            </LabelDiv>
            <Inputs.TextInput
              value={title}
              placeholder="Enter Title"
              onChange={onTitleChanged}
              maxLength={40}
              autoFocus
              data-testid={'title'}
            />
          </div>
          <div style={{ marginBottom: 24 }}>
            <IntervalPicker
              widgetDateRange={widgetDateRange}
              setWidgetDateRange={setWidgetDateRange}
            />
          </div>
          <div style={{ marginBottom: 24 }}>
            <MetricListInput
              metricIds={metricIds}
              setMetricIds={setMetricIds}
            />
          </div>
          {isDisabled || !filterInput || !dateScope || !metricSection ? (
            <EmptyVis />
          ) : (
            <>
              <LabelDiv>
                <Typography.Body type="Label">Preview</Typography.Body>
              </LabelDiv>
              <Metric section={metricSection} />
            </>
          )}
        </WidgetWrapper>
      </ShadowedList>
      <WidgetFooter
        saveWidget={saveWidget}
        sectionType="Metric Widget"
        isDisabled={isDisabled}
        close={close}
      />
    </>
  );
};

export default MetricSlideoutWizard;
