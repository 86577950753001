import React from 'react';

export interface FeatureGatesContextType {
  isGoalsEnabled: boolean;
  isDriverBonusSummaryEnabled: boolean;
  isLoading: boolean;
  isMultipleCardsEnabled: boolean;
  isSelfServeEnabled: boolean;
  isMicrosoftSsoEnabled: boolean;
  isEntityDetailsEnabled: boolean;
  isActivityCostsEnabled: boolean;
  isFileUploadEnabled: boolean;
  isCustomerAwardsTrackerEnabled: boolean;
  isManualKpiPermissionManagementEnabled: boolean;
  isDriverBonusEnabled: boolean;
  isPerformanceEnabled: boolean;
  isTemplatesEnabled: boolean;
  isViewerRoleAssignmentEnabled: boolean;
  isPortalsSEPreviewEnabled: boolean;
  isPortalsUIPerUserToggleEnabled: boolean;
  isPortalsAccountWideToggleEnabled: boolean;
}

const DEFAULT_VALUE = process.env.NODE_ENV === 'test';

const FeatureGatesContext = React.createContext<FeatureGatesContextType>({
  isGoalsEnabled: DEFAULT_VALUE,
  isDriverBonusSummaryEnabled: DEFAULT_VALUE,
  isLoading: process.env.NODE_ENV === 'test' ? false : true,
  isMultipleCardsEnabled: DEFAULT_VALUE,
  isSelfServeEnabled: DEFAULT_VALUE,
  isMicrosoftSsoEnabled: DEFAULT_VALUE,
  isEntityDetailsEnabled: DEFAULT_VALUE,
  isActivityCostsEnabled: DEFAULT_VALUE,
  isFileUploadEnabled: DEFAULT_VALUE,
  isCustomerAwardsTrackerEnabled: DEFAULT_VALUE,
  isManualKpiPermissionManagementEnabled: DEFAULT_VALUE,
  isDriverBonusEnabled: DEFAULT_VALUE,
  isPerformanceEnabled: DEFAULT_VALUE,
  isTemplatesEnabled: DEFAULT_VALUE,
  isViewerRoleAssignmentEnabled: DEFAULT_VALUE,
  isPortalsSEPreviewEnabled: DEFAULT_VALUE,
  isPortalsUIPerUserToggleEnabled: DEFAULT_VALUE,
  isPortalsAccountWideToggleEnabled: DEFAULT_VALUE,
});

export default FeatureGatesContext;
