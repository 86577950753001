import styled from 'styled-components';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import Modal from '@atlaskit/modal-dialog';
import Button from 'kingpin/atoms/Button';

import TargetFormContext from '../../contexts/TargetFormContext';
import Typography from 'kingpin/atoms/Typography';
import Inputs from '../../../Inputs';
import Row from '../../../Common/Row';
import isDefined from '../../../../isDefined';
import useGetFieldLabel from '../../../../hooks/useGetFieldLabel';

const ModalDiv = styled.div`
  padding: 24px 32px;
`;

const useLabels = () => {
  const { groupField, dataType } = useContext(TargetFormContext);
  const { getFieldLabel } = useGetFieldLabel();

  if (groupField === undefined) {
    return {
      modalTitle: '',
      dropdownPlaceholder: '',
    };
  }

  const dropdownPlaceholder = `Select ${getFieldLabel({
    field: groupField,
    dataType,
  })}`;
  const modalTitle = `Duplicate Rule to another ${getFieldLabel({
    field: groupField,
    dataType,
  })}`;
  return {
    modalTitle,
    dropdownPlaceholder,
  };
};

const DuplicateGroupModal = ({
  onDuplicateConfirmed,
  close,
}: {
  onDuplicateConfirmed: (groupName: string[]) => void;
  close: () => void;
}) => {
  const { groups, groupNameValues } = useContext(TargetFormContext);
  const { modalTitle, dropdownPlaceholder } = useLabels();
  const [selectedGroupName, setSelectedGroupName] = useState<string[]>([]);
  const [options, setOptions] = useState<DropdownOption[]>([]);
  useEffect(() => {
    const usedGroupNames = groups
      .map((g) => g.groupName)
      .filter(isDefined)
      .reduce((a, b) => [...a, ...b], []);
    const availableNames = groupNameValues.filter(
      (g) => !usedGroupNames.includes(g),
    );
    setOptions(
      availableNames.map((name) => ({
        label: name,
        onSelected: () => {
          setSelectedGroupName((current) => {
            if (current.includes(name)) {
              return current.filter((c) => c !== name);
            }

            return [...current, name];
          });
        },
        isSelected: selectedGroupName.includes(name),
      })),
    );
  }, [groupNameValues, groups, selectedGroupName]);

  const onPrimaryClicked = useCallback(() => {
    if (selectedGroupName.length === 0) {
      return;
    }

    onDuplicateConfirmed(selectedGroupName);
  }, [onDuplicateConfirmed, selectedGroupName]);

  const selectedLabel =
    selectedGroupName.length === 0 ? undefined : selectedGroupName.join(', ');

  return (
    <Modal onClose={close} shouldScrollInViewport={false} autoFocus={false}>
      <ModalDiv>
        <Typography.Header type="H5">{modalTitle}</Typography.Header>
        <div style={{ marginBottom: 16 }}>
          <Inputs.Dropdown
            options={options}
            placeholder={dropdownPlaceholder}
            isSearchEnabled
            isMulti
            selectedLabel={selectedLabel}
          />
        </div>
        <Row centerAlign spaceBetween>
          <Button onClick={close} label={'Cancel'} type="Ghost" size="Small" />
          <Button
            onClick={onPrimaryClicked}
            label={'Duplicate'}
            isDisabled={selectedGroupName.length === 0}
            type="Primary"
            size="Small"
          />
        </Row>
      </ModalDiv>
    </Modal>
  );
};

export default DuplicateGroupModal;
