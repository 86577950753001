import React, { useCallback, useContext, useEffect } from 'react';
import ExportButton from './ExportButton';
import GridContext from '../../../contexts/GridContext';
import moment from 'moment';
import { ColDef, GridApi, ProcessCellForExportParams } from 'ag-grid-community';
import CardContext from '../../../contexts/CardContext';
import ReportContext from '../../../contexts/ReportContext';
import getComparison from '../../V5Gadget/Matrix/getComparison';
import AnalyticsContext from '../../../contexts/AnalyticsContext';
import WorkSpaceContext from '../../../contexts/WorkSpaceContext';
import BonusPeriodsContext from '../../../contexts/BonusPeriodsContext';
import ConfirmationModal from '../../ConfirmationModal';
import usePopup from '../../../hooks/usePopup';
import { HISTOGRAM_CELL_RENDERER } from '../../SingleMetricDateMatrix/SingleMetricDateMatrixContent/constants';
import isSingleMetricDateMatrix from '../../../types/visTypeCheckers/isSingleMetricDateMatrix';
import dateRangeInputToAmericanLabel from '../../../dateRangeInputToAmericanLabel';
import delay from '../../../delay';
import { getProgressCellText } from '../../ProgressCellRenderer';
import { getContractProgressCellText } from '../../ContractProgressCell';
import { DATE_CELL, GEO_POINT_CELL } from '../constants';
import useDateScope from '../../../hooks/useDateScope';
import useValueFormatters from 'hooks/useValueFormatters';

interface FleetOpsGridColDef extends ColDef {
  cellRendererParams:
    | {
        fieldView?: FleetOps.BaseViewField;
        cell?: BoardProgressCell;
      }
    | undefined;
}

const processCellCallback =
  (
    formatValue: ({
      formatting,
      value,
    }: {
      formatting: MetricFormatting;
      value: number;
    }) => string,
    formatField: ({
      dataset,
      field,
      value,
    }: {
      dataset: string;
      field: string;
      value: string | number | null | undefined | number[];
    }) => string | number,
  ) =>
  (params: ProcessCellForExportParams) => {
    const colDef = params.column.getUserProvidedColDef() as
      | FleetOpsGridColDef
      | undefined;
    if (!colDef) {
      return '';
    }
    const cellRenderer = colDef.cellRenderer;
    const cellParams = colDef.cellRendererParams;

    if (cellRenderer === 'progressCell' && params.node) {
      if (!cellParams) {
        return '';
      }
      if (!cellParams.cell) {
        return '';
      }

      const cell = cellParams.cell as BoardProgressCell;
      const fieldView = cellParams.fieldView as
        | FleetOps.BaseViewField
        | undefined;
      return getProgressCellText(
        params.node.data,
        cell,
        formatValue,
        fieldView,
      );
    }

    if (cellRenderer === 'contractProgressCell' && params.node) {
      return getContractProgressCellText(params.node.data, formatValue);
    }

    if (
      cellParams &&
      cellRenderer === DATE_CELL &&
      cellParams.fieldView &&
      cellParams.fieldView.dateFormat
    ) {
      if (params.value) {
        return moment.utc(params.value).format(cellParams.fieldView.dateFormat);
      }
      return '';
    }

    if (cellParams && cellRenderer === GEO_POINT_CELL && cellParams.fieldView) {
      return formatField({
        value: params.value,
        field: cellParams.fieldView.field,
        dataset: cellParams.fieldView.dataset,
      });
    }

    if (params.value !== undefined && Array.isArray(params.value)) {
      return params.value.join(', ');
    }

    if (params.value && typeof params.value === 'object') {
      const { value, formatting, type } = params.value;
      if (!params.value) {
        return '-';
      }

      const precision = formatting ? formatting.precision : 2;
      const prefix = formatting ? formatting.prefix : undefined;
      const postfix = formatting ? formatting.postfix : undefined;

      const updatedFormatting = { precision, prefix, postfix };

      const formatted = formatValue({ value, formatting: updatedFormatting });
      const previous =
        cellRenderer === HISTOGRAM_CELL_RENDERER
          ? params.value.comparison
          : params.value.previous;
      if (type === 'percentDelta' || type === 'delta') {
        if (previous === 0) {
          return type === 'percentDelta' ? '' : formatted;
        }
        const { delta } = getComparison({
          value: value as number,
          previous: previous as number,
          formatting,
          type,
        });
        if (delta === Infinity) {
          return '';
        }

        if (delta === null || delta === undefined) {
          return '-';
        }

        if (delta >= 0) {
          return `+ ${formatValue({ value: delta, formatting })}`;
        } else if (prefix !== 'currency') {
          return `- ${formatValue({ value: Math.abs(delta), formatting })}`;
        }

        return `${formatValue({ value: delta, formatting })}`;
      } else {
        return formatted;
      }
    }

    if (typeof params.value === 'string') {
      return params.value.replace(
        /[^a-zA-Z0-9, !@#$%^&*()\-=_+`.;:'"?|[\]\\/{}]/g,
        ' ',
      );
    }
    return params.value;
  };

const processSingleMetricDateMatrixHeaderCallback = (
  params: ProcessCellForExportParams,
) => {
  const colDef = params.column.getColDef();
  if (colDef.headerName === 'Chg') {
    return 'Chg';
  }

  if (colDef.cellRenderer === 'groupingCellRenderer') {
    return colDef.field || '';
  }

  if (colDef.field) {
    try {
      // @ts-ignore
      const interval = params.api.getRowNode(1).data[colDef.field]
        .interval as string;
      // @ts-ignore
      const valueDateRange = params.api.getRowNode(1).data[colDef.field]
        .valueDateRange as DateRangeInput;

      if (interval && valueDateRange) {
        if (interval === 'day') {
          return moment.utc(colDef.field).format("ddd, MMM DD'YY");
        } else {
          return dateRangeInputToAmericanLabel(valueDateRange);
        }
      }
    } catch (ex) {
      console.warn(`${ex.name}: ${ex.message}`);
      return colDef.field || '';
    }
    return colDef.field || '';
  }

  return '';
};

const ExportButtonContainer = ({
  overrideGridApi,
  exportName,
  isContextMenuItem,
}: {
  overrideGridApi?: GridApi;
  exportName?: string;
  isContextMenuItem?: boolean;
}) => {
  const { isOpen, open, close } = usePopup();
  const { workSpace } = useContext(WorkSpaceContext);
  const { selectedBonusPeriod } = useContext(BonusPeriodsContext);
  const { i, chartDefinition } = useContext(CardContext);
  const { gridApi, dataType, gridQueryOverride } = useContext(GridContext);
  const { setGridExportCallbacks } = useContext(ReportContext);
  const dateScope = useDateScope({});
  const { trackEvent } = useContext(AnalyticsContext);
  const { formatValue, formatField } = useValueFormatters();
  const isNonClosedBonusExport =
    workSpace &&
    workSpace.campaignType === 'driverBonus' &&
    selectedBonusPeriod &&
    selectedBonusPeriod.status !== 'closed';
  const domId = `export-button-${i}`;
  const getFileName = useCallback(() => {
    const name = exportName ? exportName : dataType;
    const prefix = `FleetOps ${name} export`;
    const postfix = `${dateScope.startDate} - ${dateScope.endDate}`;
    const fileName = `${prefix} - ${postfix}`.replaceAll('.', '');
    return fileName;
  }, [dataType, dateScope.endDate, dateScope.startDate, exportName]);

  const exportDataAsCsv = useCallback(async () => {
    if (!gridApi && !overrideGridApi) {
      throw new Error('Grid API missing');
    }
    const gridApiToUse = overrideGridApi
      ? overrideGridApi
      : (gridApi as GridApi);

    const buttonDom = document.getElementById(domId);
    if (buttonDom) {
      buttonDom.scrollIntoView(true);
      await delay(5000);
    }

    const fileName = getFileName();
    const csv = gridApiToUse.getDataAsCsv({
      fileName,
      processCellCallback: processCellCallback(formatValue, formatField),
      processHeaderCallback: isSingleMetricDateMatrix(chartDefinition)
        ? processSingleMetricDateMatrixHeaderCallback
        : undefined,
    });

    if (csv) {
      return {
        content: csv.replace(/€/g, ''),
        fileName,
      };
    } else {
      window.alert('Something went wrong!');
      throw new Error(`Failed to export ${fileName}`);
    }
  }, [
    gridApi,
    overrideGridApi,
    domId,
    getFileName,
    formatValue,
    formatField,
    chartDefinition,
  ]);

  const downloadDataAsCsv = async () => {
    if (!gridApi && !overrideGridApi) {
      throw new Error('Grid API missing');
    }
    const gridApiToUse = overrideGridApi
      ? overrideGridApi
      : (gridApi as GridApi);
    const name = exportName ? exportName : dataType;
    const fileName = getFileName();

    trackEvent('Grid - Exported', {
      name,
    });

    const currentDocs = (() => {
      try {
        // @ts-ignore
        return gridApiToUse.rowModel.datasource.documents.length;
      } catch (ex) {
        return '?';
      }
    })();
    const availableDocCount = (() => {
      try {
        // @ts-ignore
        return gridApiToUse.rowModel.datasource.searchResult.count;
      } catch (ex) {
        return '?';
      }
    })();
    const hasAllDocs = (() => {
      try {
        if (gridQueryOverride) {
          return true;
        }

        // @ts-ignore
        if (!gridApiToUse.rowModel.datasource) {
          return true;
        }

        return (
          // @ts-ignore
          gridApiToUse.rowModel.datasource.searchResult.count === currentDocs
        );
      } catch (ex) {
        return false;
      }
    })();

    if (
      hasAllDocs ||
      window.confirm(
        `Only the data loaded so far (${currentDocs} / ${availableDocCount} items) will be exported.
Please scroll down to the end before exporting to get all data.`,
      )
    ) {
      const buttonDom = document.getElementById(domId);
      if (buttonDom) {
        buttonDom.scrollIntoView(true);
        await delay(1000);
      }
      gridApiToUse.exportDataAsExcel({
        fileName,
        processCellCallback: processCellCallback(formatValue, formatField),
        processHeaderCallback: isSingleMetricDateMatrix(chartDefinition)
          ? processSingleMetricDateMatrixHeaderCallback
          : undefined,
      });
    }
  };

  useEffect(() => {
    if (!i || !setGridExportCallbacks) {
      return;
    }

    setGridExportCallbacks((cbs) => [
      ...cbs.filter((cb) => cb.cardI !== i),
      { cardI: i, callback: exportDataAsCsv },
    ]);
  }, [exportDataAsCsv, i, setGridExportCallbacks]);

  return (
    <>
      <ExportButton
        id={domId}
        isContextMenuItem={isContextMenuItem}
        onClick={isNonClosedBonusExport ? open : downloadDataAsCsv}
      />
      <ConfirmationModal
        isOpen={isOpen}
        close={close}
        title={'Export Grid?'}
        body={
          'Bonus payments should not be made until the cycle is closed and signed off'
        }
        confirmText="Export"
        onConfirmed={downloadDataAsCsv}
      />
    </>
  );
};

export default ExportButtonContainer;
