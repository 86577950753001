import React, { ChangeEvent } from 'react';
import Button from 'kingpin/atoms/Button';
import Row from '../../Common/Row';
import TextInput from '../../../kingpin/atoms/TextInput';
import Form from 'kingpin/forms/Form';
import FormHeader from '../../../kingpin/forms/FormHeader';
import FormContent from '../../../kingpin/forms/FormContent';

const ReasonCodeForm = ({
  close,
  code,
  onCodeChanged,
  onSave,
  isSaving,
}: {
  close: () => void;
  code: string;
  onCodeChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  onSave: () => void;
  isSaving: boolean;
}) => (
  <Form>
    <FormHeader title={'Add Reason Code'} onClose={close} />
    <FormContent>
      <TextInput
        label={'Code'}
        value={code}
        onChange={onCodeChanged}
        inputSize="Small"
      />
    </FormContent>
    <Row style={{ justifyContent: 'space-between' }}>
      <Button
        type="Secondary"
        size="Small"
        onClick={close}
        isDisabled={isSaving}
        label="Cancel"
      />
      <Button type="Primary" size="Small" onClick={onSave} label="Save" />
    </Row>
  </Form>
);

export default ReasonCodeForm;
