import STORE from '../../store';

const createGoal = async ({
  goal,
  accountId,
}: {
  goal: GeneralGoal;
  accountId: string;
}) => {
  await STORE.visualisations.getGoalsRef({ accountId }).doc(goal.id).set(goal);
  return goal.id;
};

export default createGoal;
