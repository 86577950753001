import { useContext, useEffect, useState } from 'react';
import MetricUsageLookupContext from '../contexts/MetricUsageLookupContext';
import metricTypeCheckers from '../types/metricTypeCheckers';

const useMetricUsageReport = (
  metric: Metrics.NormalMetric | Metrics.CompoundMetric | undefined,
) => {
  const { metricLookup, compoundMetricLookup } = useContext(
    MetricUsageLookupContext,
  );
  const [usageReport, setUsageReport] = useState<
    FleetOps.MetricLookupResults | undefined
  >();
  useEffect(() => {
    if (!metric) {
      setUsageReport(undefined);
      return;
    }

    const m = (() => {
      if (metricTypeCheckers.isCompoundMetric(metric)) {
        return compoundMetricLookup[metric.id];
      } else {
        return metricLookup[metric.id];
      }
    })();

    setUsageReport(m);
  }, [compoundMetricLookup, metric, metricLookup]);

  return usageReport;
};

export default useMetricUsageReport;
