import React from 'react';
import Typography from 'kingpin/atoms/Typography';

const FieldSelectionTop = () => (
  <div>
    <Typography.Header type={'H5'}>Pick a filter</Typography.Header>
  </div>
);

export default FieldSelectionTop;
