import { useContext, useEffect, useState } from 'react';
import RolesContext from '../contexts/RolesContext';
import CurrentUserContext from '../contexts/CurrentUserContext';
import WorkSpacesContext from '../contexts/WorkSpacesContext';
import useHasAccess from '../hooks/useHasAccess';
import AvailableWorkSpacesContext from 'contexts/AvailableWorkSpacesContext';

const AvailableWorkSpacesProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { currentPermissions } = useContext(RolesContext);
  const currentUser = useContext(CurrentUserContext);
  const { allWorkSpaces } = useContext(WorkSpacesContext);
  const hasAccess = useHasAccess();

  const [availableWorkSpaces, setAvailableWorkSpaces] = useState<
    (WorkSpace | TargetsApp.App)[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setAvailableWorkSpaces(
      allWorkSpaces.filter((w) =>
        hasAccess({
          access: w.access,
          resource: w,
          type: 'workspace',
          typeId: w.id,
        }),
      ),
    );

    setIsLoading(false);
  }, [allWorkSpaces, currentUser, isLoading, currentPermissions, hasAccess]);

  return (
    <AvailableWorkSpacesContext.Provider
      value={{ availableWorkSpaces, isLoading }}
    >
      {children}
    </AvailableWorkSpacesContext.Provider>
  );
};

export default AvailableWorkSpacesProvider;
