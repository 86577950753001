import React, { useRef } from 'react';
import V5Gadget from './V5Gadget';
import ErrorBoundary from '../Common/ErrorBoundary';
import GqlClientProvider from '../../contextProviders/GqlClientProvider';

const V5GadgetContainer = ({
  chartDefinition,
  useSmallNoDataMessage,
}: {
  chartDefinition: V5ChartDefinition;
  useSmallNoDataMessage?: boolean;
}) => {
  const chartRef = useRef<any>();
  return (
    <GqlClientProvider
      visName={chartDefinition.name}
      visId={chartDefinition.id}
      visType={chartDefinition.gadgetType}
    >
      <ErrorBoundary>
        <V5Gadget
          chartDefinition={chartDefinition}
          chartRef={chartRef}
          useSmallNoDataMessage={useSmallNoDataMessage || false}
        />
      </ErrorBoundary>
    </GqlClientProvider>
  );
};

export default V5GadgetContainer;
