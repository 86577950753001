import React, { useCallback, useContext, useEffect, useState } from 'react';
import UsersContext from 'contexts/UsersContext';
import CurrentUserContext from 'contexts/CurrentUserContext';
import userTypeCheckers from 'contextProviders/UsersProvider/userTypeCheckers';

import UsersLosingAccessWarning from './UsersLosingAccessWarning';
import CurrentUserLosingAccess from './CurrentUserLosingAccess';
import ConfigureMicrosoftSsoContext from '../../contexts/ConfigureMicrosoftSsoContext';
import AnalyticsContext from '../../../../../../contexts/AnalyticsContext';

const useUsersLosingAccess = (
  permittedUsers: MicrosoftSso.UserWithMemberships[],
) => {
  const { allUsers } = useContext(UsersContext);
  const currentUser = useContext(CurrentUserContext);
  const { setIsValid } = useContext(ConfigureMicrosoftSsoContext);
  const [usersLosingAccess, setUsersLosingAccess] = useState<
    (UserManagement.SignedUpUser | UserManagement.PendingUser)[]
  >([]);
  const [isCurrentUserLosingAccess, setIsCurrentUserLosingAccess] =
    useState<boolean>(true);

  useEffect(() => {
    setUsersLosingAccess(
      allUsers
        .filter(userTypeCheckers.isSignedUpOrPendingUser)
        .filter((u) => !u.isDeleted)
        .filter((u) => u.email !== undefined)
        .filter(
          (u) =>
            !permittedUsers.some(
              (pu) => pu.email.toLowerCase() === u.email.toLowerCase(),
            ),
        ),
    );
  }, [allUsers, permittedUsers]);

  useEffect(() => {
    const currentUserEmail = currentUser.email
      ? currentUser.email.toLowerCase()
      : '';
    setIsCurrentUserLosingAccess(
      !permittedUsers.some((pu) => pu.email.toLowerCase() === currentUserEmail),
    );
  }, [currentUser, currentUser.email, permittedUsers, usersLosingAccess]);

  useEffect(() => {
    setIsValid(isCurrentUserLosingAccess === false);
  }, [isCurrentUserLosingAccess, setIsValid]);

  return {
    usersLosingAccess,
    isCurrentUserLosingAccess,
  };
};

const useOnExportListClicked = (
  usersLosingAccess: (
    | UserManagement.SignedUpUser
    | UserManagement.PendingUser
  )[],
) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const buildCsv = useCallback(() => {
    const headers = `email\r\n`;
    const content = usersLosingAccess.map((user) => user.email).join('\r\n');
    return `${headers}${content}`;
  }, [usersLosingAccess]);

  const onExportListClicked = useCallback(() => {
    trackEvent('SSO Configurations - Microsoft - Exported missing users list');
    // Create a blob
    const blob = new Blob([buildCsv()], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    // Create a link to download it
    const pom = document.createElement('a');
    pom.href = url;
    pom.setAttribute('download', 'users-losing-access.csv');
    pom.click();
  }, [buildCsv, trackEvent]);

  return onExportListClicked;
};

const UsersLosingAccessWarningContainer = ({
  permittedUsers,
  selectedGroups,
}: {
  permittedUsers: MicrosoftSso.UserWithMemberships[];
  selectedGroups: MicrosoftSso.Group[];
}) => {
  const { usersLosingAccess, isCurrentUserLosingAccess } =
    useUsersLosingAccess(permittedUsers);
  const onExportListClicked = useOnExportListClicked(usersLosingAccess);

  if (selectedGroups.length === 0) {
    return null;
  }

  if (isCurrentUserLosingAccess) {
    return <CurrentUserLosingAccess />;
  }

  if (usersLosingAccess.length === 0) {
    return null;
  }

  return (
    <UsersLosingAccessWarning
      usersLosingAccess={usersLosingAccess}
      onExportListClicked={onExportListClicked}
    />
  );
};

export default UsersLosingAccessWarningContainer;
