import React, { useCallback, useContext } from 'react';
import _ from 'lodash';

import GoalsContext from '../contexts/GoalsContext';
import useGoals from '../hooks/goals/useGoals';
import updateGoal from '../hooks/goals/updateGoal';
import deleteGoal from '../hooks/goals/deleteGoal';
import MetricOptionsContext from '../contexts/MetricOptionsContext';
import isDefined from '../isDefined';
import AccountPickerContext from '../contexts/AccountPickerContext';

const GoalsProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { goals, isLoadingGoals } = useGoals();
  const { metricOptions } = useContext(MetricOptionsContext);
  const { selectedAccountId } = useContext(AccountPickerContext);

  const usedMetrics = _.uniq(
    goals
      .map((g) => metricOptions.find((m) => m.id === g.metricId))
      .filter(isDefined),
  );

  const goalMetrics = usedMetrics.map((m) => ({
    label: m.name,
    id: m.id,
  }));

  const onDeleteGoal = useCallback(
    async (goalId: string) => {
      const goal = goals.find((g) => g.id === goalId);
      if (goal) {
        await deleteGoal(goal, selectedAccountId);
      }
    },
    [selectedAccountId, goals],
  );

  const onUpdateGoal = useCallback(
    (newGoal: GeneralGoal) => {
      return updateGoal(newGoal, selectedAccountId);
    },
    [selectedAccountId],
  );

  return (
    <GoalsContext.Provider
      value={{
        goals,
        goalMetrics,
        loadingGoals: isLoadingGoals,
        updateGoal: onUpdateGoal,
        deleteGoal: onDeleteGoal,
      }}
    >
      {children}
    </GoalsContext.Provider>
  );
};

export default GoalsProvider;
