import React from 'react';

interface SpecialMetricsContextType {
  specialMetrics: Metrics.SpecialMetric[];
  isLoading: boolean;
}

const SpecialMetricsContext = React.createContext<SpecialMetricsContextType>({
  specialMetrics: window.emptyArray,
  isLoading: process.env.NODE_ENV === 'test' ? false : true,
} as SpecialMetricsContextType);

export default SpecialMetricsContext;
