const isPendingUser = (
  user: UserManagement.User,
): user is UserManagement.PendingUser => user.type === 'Pending';

const isWallboardUser = (
  user: UserManagement.User,
): user is UserManagement.WallboardUser => user.type === 'Wallboard';

const isSignedUpUser = (
  user: UserManagement.User,
): user is UserManagement.SignedUpUser => user.type === 'SignedUp';

const isFleetOpsStaffUser = (
  user: UserManagement.User,
): user is UserManagement.FleetOpsStaffUser => user.type === 'FleetOpsStaff';

const isNotWallboardUser = (
  user: UserManagement.User,
): user is
  | UserManagement.SignedUpUser
  | UserManagement.PendingUser
  | UserManagement.FleetOpsStaffUser =>
  user.type === 'SignedUp' ||
  user.type === 'Pending' ||
  user.type === 'FleetOpsStaff';

const isSignedUpOrPendingUser = (
  user: UserManagement.User,
): user is UserManagement.SignedUpUser | UserManagement.PendingUser =>
  isSignedUpUser(user) || isPendingUser(user);

const isNormalContentSettings = (
  settings: UserManagement.ContentSettings,
): settings is UserManagement.NormalContentSettings =>
  settings.mode === 'normal';

const isEngagementContentSettings = (
  settings: UserManagement.ContentSettings,
): settings is UserManagement.EngagementContentSettings =>
  settings.mode === 'engagement';

const userTypeCheckers = {
  isPendingUser,
  isWallboardUser,
  isSignedUpUser,
  isNotWallboardUser,
  isFleetOpsStaffUser,
  isSignedUpOrPendingUser,
  isNormalContentSettings,
  isEngagementContentSettings,
};

export default userTypeCheckers;
