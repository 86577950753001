import { useCallback, useContext, useEffect, useState } from 'react';
import CostsShowContext from '../../context/CostsShowContext';
import { ALL_GROUP_NAME } from '../../consts';
import useIntervals from './useIntervals';
import useGetCostValue from './useGetCostValue';
import useGetTotalsValue from './useGetTotalsValue';
import useGetCpmValue from './useGetCpmValue';

const useCostIntervalLookup = (
  activityCosts: Costs.PersistedCostModel | undefined,
) => {
  const { intervalCosts, refreshCosts } = useContext(CostsShowContext);
  const [costsLookup, setCostsLookup] = useState<Costs.CostIntervalLookup>({});
  const { res: intervals } = useIntervals({ activityCosts });

  const getIsInitialAllGroup = useCallback(
    (groupDefinition: Costs.Group) => {
      const IsPersistedIntervalsEmpty =
        !intervalCosts || intervalCosts.length === 0;

      return (
        !groupDefinition.fields &&
        groupDefinition.groupName !== ALL_GROUP_NAME &&
        IsPersistedIntervalsEmpty
      );
    },
    [intervalCosts],
  );

  const getCostValue = useGetCostValue(costsLookup, getIsInitialAllGroup);

  const { getTotalsValue, getPersistableCosts } = useGetTotalsValue({
    activityCosts,
    intervals,
    costsLookup,
    setCostsLookup,
    getCostValue,
    getIsInitialAllGroup,
  });

  const getCpmValue = useGetCpmValue({
    intervals,
    getCostValue,
    getTotalsValue,
  });

  const getPersistedValue = useCallback(
    ({
      startDate,
      groupDefinition,
      costFieldName,
    }: {
      startDate: string;
      groupDefinition: Costs.Group;
      costFieldName: string;
    }): Costs.CategoryParams => {
      if (!intervalCosts) {
        return getIsInitialAllGroup(groupDefinition)
          ? { cost: 0, totals: undefined }
          : { cost: undefined, totals: undefined };
      }

      const getValue = (interval: Costs.IntervalCosts) => {
        const group = interval.groupCosts.find(
          (g) => g.groupDefinition.groupName === groupDefinition.groupName,
        );
        if (!group) {
          return getIsInitialAllGroup(groupDefinition)
            ? { cost: 0, totals: undefined }
            : { cost: undefined, totals: undefined };
        }

        const cost = group.costs.find((c) => c.costFieldName === costFieldName);
        if (!cost) {
          return getIsInitialAllGroup(groupDefinition)
            ? { cost: 0, totals: group.totals }
            : {
                cost: undefined,
                totals: group.totals,
              };
        }

        return {
          cost: cost.value,
          totals: group.totals,
        };
      };

      const interval = intervalCosts.find((i) => i.startDate === startDate);
      if (!interval) {
        return getIsInitialAllGroup(groupDefinition)
          ? {
              cost: 0,
              totals: undefined,
            }
          : {
              cost: undefined,
              totals: undefined,
            };
      }

      return getValue(interval);
    },
    [getIsInitialAllGroup, intervalCosts],
  );

  const checkIsValidCost = useCallback(
    (costInterval: Costs.IntervalCostsInput): boolean => {
      return costInterval.groupCosts.some((g) => {
        return g.costs.some((c) => {
          const cost = getCostValue({
            startDate: costInterval.startDate,
            groupDefinition: g.groupDefinition,
            costFieldName: c.costFieldName,
          });

          if (getIsInitialAllGroup(g.groupDefinition)) {
            return cost !== 0;
          } else {
            return cost !== undefined;
          }
        });
      });
    },
    [getCostValue, getIsInitialAllGroup],
  );

  const checkIsCpmEstimated = useCallback(
    (startDate: string): boolean => {
      // Estimated if current interval has no cost values but there is a past interval with cpm defined.
      const newIntervals = getPersistableCosts();

      const interval = newIntervals.find((i) => i.startDate === startDate);

      if (!interval) {
        return false;
      }

      const previousCostIntervals = newIntervals
        .filter((ci) => ci.startDate < startDate)
        .some(checkIsValidCost);

      return !checkIsValidCost(interval) && previousCostIntervals;
    },
    [checkIsValidCost, getPersistableCosts],
  );

  const updateCostValue = useCallback(
    ({
      startDate,
      groupDefinitionName,
      costFieldName,
      newValue,
    }: {
      startDate: string;
      groupDefinitionName: string;
      costFieldName: string;
      newValue: number | undefined;
    }) => {
      setCostsLookup((currentLookup) => {
        const interval = currentLookup[startDate];
        if (interval === undefined) {
          return currentLookup;
        }

        const newInterval = {
          ...interval,
          groups: {
            ...interval.groups,
            [groupDefinitionName]: {
              ...interval.groups[groupDefinitionName],
              costs: {
                ...interval.groups[groupDefinitionName].costs,
                [costFieldName]: newValue,
              },
            },
          },
        };

        const newLookup = {
          ...currentLookup,
          [startDate]: newInterval,
        };

        return newLookup;
      });
    },
    [],
  );

  const getDefaultCostLookup = useCallback(() => {
    if (activityCosts === undefined) {
      return {};
    }

    const newCostModel: Costs.CostIntervalLookup = {};
    intervals.forEach((interval) => {
      const groupsModel = activityCosts.groups;
      const groups: {
        [groupDefinitionName: string]: {
          groupDefinition: Costs.Group;
          costs: { [fieldName: string]: number | undefined };
          totals: Costs.IntervalTotals | undefined;
        };
      } = {};
      groupsModel.forEach((g) => {
        const costs: { [category: string]: number | undefined } = {};
        let totals: Costs.IntervalTotals | undefined = undefined;
        activityCosts.categories.forEach((c) => {
          const persistedValue = getPersistedValue({
            startDate: interval.startDate,
            groupDefinition: g,
            costFieldName: c.costFieldName,
          });

          costs[c.costFieldName] = persistedValue.cost;
          totals = persistedValue.totals;
        });

        groups[g.groupName] = {
          groupDefinition: g,
          costs,
          totals,
        };
      });

      const newInteval = {
        startDate: interval.startDate,
        endDate: interval.endDate,
        groups,
      };

      newCostModel[interval.startDate] = newInteval;
    });

    return newCostModel;
  }, [activityCosts, getPersistedValue, intervals]);

  useEffect(() => {
    setCostsLookup(getDefaultCostLookup());
  }, [getDefaultCostLookup]);

  return {
    costsLookup,
    getCostValue,
    getCpmValue,
    updateCostValue,
    getPersistableCosts,
    checkIsCpmEstimated,
    intervals,
    intervalCosts,
    refreshCosts,
    getDefaultCostLookup,
    setCostsLookup,
    isLoading: Object.keys(costsLookup).length === 0,
  };
};

export default useCostIntervalLookup;
