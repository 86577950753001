import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import Button from 'kingpin/atoms/Button';

import ConfigureMetricList from './ConfigureMetricList';
import ComparisonContext from '../../../contexts/ComparisonContext';
import updateMetricListGadget from '../../../api/metricListGadgets/updateMetricListGadget';
import DashboardContext from '../../../contexts/DashboardContext';
import BonusPeriodsContext from '../../../contexts/BonusPeriodsContext';
import useTemplateInstances from '../../../hooks/dashboards/useTemplateInstances';

import Row from '../../Common/Row';
import styled from 'styled-components';

import { MetricListGadgetContainer } from '../index';
import Card from '../../Common/Card';
import { DateTime } from 'luxon';
import CurrentUserContext from '../../../contexts/CurrentUserContext';
import DateInputContext, {
  DateInputContextType,
} from '../../../contexts/DateInputContext';
import withDateFilter from '../../../hocs/withDateFIlter';
import DashboardGadgetDateScopeProvider from '../../../contextProviders/DashboardGadgetDateScopeProvider';
import useDataTypesFromSeriesAndMetricListItems from '../../../hooks/useDataTypesFromSeriesAndMetricListItems';
import AccountPickerContext from '../../../contexts/AccountPickerContext';
import Form from '../../../kingpin/forms/Form';
import FormHeader from '../../../kingpin/forms/FormHeader';
import FormContent from '../../../kingpin/forms/FormContent';

const Left = styled.div`
  overflow-y: auto;
  flex: 2;
`;
const Right = styled.div`
  overflow-y: auto;
  flex: 5;
`;

const ConfigureMetricListContainer = ({
  metricListGadget,
  originalListContext,
  close,
}: {
  metricListGadget: MetricListGadgetType;
  originalListContext: DateInputContextType;
  close: () => void;
}) => {
  const {
    dateField,
    dateRange,
    relativeDateRange,
    advancedRelativeDateRange,
    setDateRange,
    setDateField,
    setRelativeDateRange,
    setAdvancedRelativeDateRange,
    setDataTypes,
  } = useContext(DateInputContext);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const { id: currentUserId } = useContext(CurrentUserContext);
  const { setIsEditingCard, dashboard } = useContext(DashboardContext);
  const templateInstances = useTemplateInstances(dashboard);
  const { selectedBonusPeriod } = useContext(BonusPeriodsContext);

  const [name, setName] = useState<string>(metricListGadget.name);
  const [weekStartsOnOverride, setWeekStartsOnOverride] = useState<
    WeekStartsOn | undefined
  >(metricListGadget.weekStartsOnOverride);
  const [currentComparison, setCurrentComparison] = useState<
    PersistedComparisonType | undefined
  >(metricListGadget.comparison);
  const [metricList, setMetricList] = useState<MetricListItemType[]>(
    metricListGadget.list,
  );
  const [metricListOrder, setMetricListOrder] = useState<string[]>(
    metricListGadget.listOrder,
  );
  const [isBonusPeriodMode, setIsBonusPeriodMode] = useState<boolean>(
    metricListGadget.isBonusPeriodMode,
  );
  const dataTypes = useDataTypesFromSeriesAndMetricListItems(metricList);

  const [draftGadget, setDraftGadget] =
    useState<MetricListGadgetType>(metricListGadget);

  useEffect(() => {
    setDataTypes(dataTypes);
  }, [dataTypes, setDataTypes]);

  useEffect(() => {
    setDateRange(metricListGadget.dateRange);
    setRelativeDateRange(metricListGadget.relativeDateRange);
    setAdvancedRelativeDateRange(metricListGadget.advancedRelativeDateRange);
    setDateField(metricListGadget.dateField);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIsEditingCard(true);
    return () => {
      setIsEditingCard(false);
    };
  }, [setIsEditingCard]);

  const onNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const onAddMetricItem = (newMetricItem: MetricListItemType) => {
    setMetricList([...metricList, newMetricItem]);
    setMetricListOrder([...metricListOrder, newMetricItem.id]);
  };

  const onIsBonusPeriodModeChanged = (newStatus: boolean) => {
    setIsBonusPeriodMode(newStatus);
    if (newStatus) {
      setDateRange(undefined);
      setRelativeDateRange(undefined);
    }
  };

  useEffect(() => {
    const newDraft = {
      ...metricListGadget,
      name,
      comparison: currentComparison,
      dateField,
      dateRange,
      relativeDateRange,
      advancedRelativeDateRange,
      list: metricList,
      listOrder: metricListOrder,
      isBonusPeriodMode,
      updatedBy: currentUserId,
      updatedOn: DateTime.utc().toISO(),
      weekStartsOnOverride,
    };

    setDraftGadget(newDraft);
  }, [
    advancedRelativeDateRange,
    currentComparison,
    currentUserId,
    dateField,
    dateRange,
    isBonusPeriodMode,
    metricList,
    metricListGadget,
    metricListOrder,
    name,
    relativeDateRange,
    weekStartsOnOverride,
  ]);

  const onSave = async () => {
    if (templateInstances.length > 0) {
      const confirmed = window.confirm(
        `${templateInstances.length} dashboard${
          templateInstances.length !== 1 ? 's' : ''
        } will be affected. Are you sure you want to make this change?`,
      );
      if (!confirmed) {
        return;
      }
    }
    updateMetricListGadget(draftGadget, selectedAccountId).then(() => {
      originalListContext.setDateField(dateField);
      originalListContext.setDateRange(dateRange);
      originalListContext.setRelativeDateRange(relativeDateRange);
      originalListContext.setAdvancedRelativeDateRange(
        advancedRelativeDateRange,
      );
      close();
    });
  };

  return (
    <Form
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: 1,
      }}
    >
      <div>
        <FormHeader title={'Configure Metric List'} onClose={close} />
        <FormContent>
          <Row>
            <Left>
              <ComparisonContext.Provider
                value={{
                  currentComparison,
                  setCurrentComparison,
                }}
              >
                <ConfigureMetricList
                  name={name}
                  onNameChanged={onNameChanged}
                  metricList={metricList}
                  setMetricList={setMetricList}
                  metricListOrder={metricListOrder}
                  setMetricListOrder={setMetricListOrder}
                  onAddMetricItem={onAddMetricItem}
                  isBonusPeriodMode={isBonusPeriodMode}
                  onIsBonusPeriodModeChanged={onIsBonusPeriodModeChanged}
                  selectedBonusPeriod={selectedBonusPeriod}
                  weekStartsOnOverride={weekStartsOnOverride}
                  setWeekStartsOnOverride={setWeekStartsOnOverride}
                />
              </ComparisonContext.Provider>
            </Left>
            <Right>
              <Card
                style={{
                  padding: 0,
                  display: 'flex',
                  flex: 1,
                  width: '100%',
                  height: '100%',
                  paddingTop: 0,
                  border: undefined,
                  boxShadow: undefined,
                }}
              >
                <DashboardGadgetDateScopeProvider dashboardGadget={draftGadget}>
                  <MetricListGadgetContainer
                    metricListGadget={draftGadget}
                    isPreview
                  />
                </DashboardGadgetDateScopeProvider>
              </Card>
            </Right>
          </Row>
        </FormContent>
      </div>

      <Row
        spaceBetween
        centerAlign
        style={{
          borderTop: '1px solid #f2f2f2',
          padding: '10px 6px',
        }}
      >
        <Button onClick={close} type="Secondary" size="Small" label="Close" />
        <Button onClick={onSave} label="Save" type="Primary" size="Small" />
      </Row>
    </Form>
  );
};

export default withDateFilter(ConfigureMetricListContainer, {});
