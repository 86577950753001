import React, { useContext } from 'react';
import TargetFormContext from '../../contexts/TargetFormContext';
import Typography from '../../../../kingpin/atoms/Typography';
import Colors2 from '../../../../theme/Colors2';
import { BadgeDiv } from '../../../Badge';

const RuleNumber = ({ group }: { group: Targets.Wizard.TargetGroup }) => {
  const { groups } = useContext(TargetFormContext);
  const index = groups.findIndex((g) => g.key === group.key);
  const text = `RULE ${index + 1}`;
  const { colorIndex } = group;
  const backgroundColor = Object.values(Colors2.AvatarColors)[colorIndex]
    .background;
  const textColor = Object.values(Colors2.AvatarColors)[colorIndex].text;

  return (
    <div style={{ marginRight: 16 }}>
      <BadgeDiv backgroundColor={backgroundColor} borderColor={textColor}>
        <Typography.Body type={'Badge'}>{text}</Typography.Body>
      </BadgeDiv>
    </div>
  );
};

export default RuleNumber;
