import React from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import Button from 'kingpin/atoms/Button';
import Typography from 'kingpin/atoms/Typography';

import Row from '../../Common/Row';
import colors from '../../../theme/colors';
import WarningTextDiv from '../NewCommitPopup/NewCommitmentSlideOut/WarningTextDiv';
import Icon from '../../../kingpin/atoms/Icon';
import Colors2 from '../../../theme/Colors2';
import Form from '../../../kingpin/forms/Form';
import FormHeader from '../../../kingpin/forms/FormHeader';
import FormContent from '../../../kingpin/forms/FormContent';

const DeleteCommitmentPopup = ({
  isOpen,
  close,
  onDeleteConfirmed,
  isSaving,
}: {
  isOpen: boolean;
  close: () => void;
  onDeleteConfirmed: () => void;
  isSaving: boolean;
}) => (
  <ModalTransition>
    {isOpen && (
      <Modal onClose={close} shouldScrollInViewport={false} autoFocus={false}>
        <Form>
          <FormHeader title="Delete Commitment" onClose={close} />
          <FormContent>
            <WarningTextDiv>
              <Row centerAlign>
                <Icon icon="warning" color={colors.RADICAL_RED} />
                <Typography.Body type="Body 14" color={Colors2.Secondary.error}>
                  Warning:
                </Typography.Body>
              </Row>
              <Typography.Body type="Body 14">
                All history associated with this commitment will be deleted.
              </Typography.Body>
              <br />
              <Typography.Body type="Body 14">
                This action can not be undone.
              </Typography.Body>
              <br />
            </WarningTextDiv>
          </FormContent>
          <Row spaceBetween centerAlign>
            <Button
              onClick={close}
              label={'Cancel'}
              size="Small"
              type="Ghost"
            />
            <Button
              onClick={onDeleteConfirmed}
              isLoading={isSaving}
              isDisabled={isSaving}
              label="Confirm"
              type="Primary"
              size="Small"
            />
          </Row>
        </Form>
      </Modal>
    )}
  </ModalTransition>
);

export default DeleteCommitmentPopup;
