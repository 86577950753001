import React, { useCallback, useEffect, useState } from 'react';
import useDatasetFilters from '../hooks/useDatasetFilters';
import DatasetFiltersContext, {
  DatasetFilterLookup,
  DatasetFilterIdLookup,
} from '../context/DatasetFiltersContext';
import Loading from 'components/Loading';
import isDefined from 'isDefined';

export const datasetFilterToPlate = (
  dsFilter: DatasetFilter,
): DatasetFilterPlate => ({
  id: dsFilter.id,
  type: 'Dataset',
  datasetFilterId: dsFilter.id,
});

const useDatasetFiltersLookup = (
  datasetFilters: DatasetFilter[],
): DatasetFilterLookup => {
  const buildLookup = useCallback(() => {
    const newLookup: DatasetFilterLookup = {};
    datasetFilters.forEach((dsFilter) => {
      const currentLookupForDataset = newLookup[dsFilter.dataset];
      if (currentLookupForDataset === undefined) {
        newLookup[dsFilter.dataset] = [dsFilter];
      } else {
        newLookup[dsFilter.dataset] = [...currentLookupForDataset, dsFilter];
      }
    });

    return newLookup;
  }, [datasetFilters]);

  const [datasetFiltersLookup, setDatasetFiltersLookup] =
    useState<DatasetFilterLookup>(() => buildLookup());

  useEffect(() => {
    setDatasetFiltersLookup(buildLookup());
  }, [buildLookup]);

  return datasetFiltersLookup;
};

const useDatasetFilterIdLookup = (
  datasetFilters: DatasetFilter[],
): DatasetFilterIdLookup => {
  const buildLookup = useCallback(() => {
    const newLookup: DatasetFilterIdLookup = {};
    datasetFilters.forEach((dsFilter) => {
      newLookup[dsFilter.id] = dsFilter;
    });

    return newLookup;
  }, [datasetFilters]);

  const [datasetFiltersLookup, setDatasetFiltersLookup] =
    useState<DatasetFilterIdLookup>(() => buildLookup());

  useEffect(() => {
    setDatasetFiltersLookup(buildLookup());
  }, [buildLookup]);

  return datasetFiltersLookup;
};

const DatasetFiltersProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { datasetFilters, isLoading } = useDatasetFilters();
  const datasetFilterLookup = useDatasetFiltersLookup(datasetFilters);
  const datasetFilterIdLookup = useDatasetFilterIdLookup(datasetFilters);

  const getDefaultDatasetFilterPlates = useCallback(
    ({ dataset }: { dataset: string }) => {
      const currentDatasetFilters = datasetFilterLookup[dataset] || [];
      const defaultDatasetFilters: DatasetFilterPlate[] = currentDatasetFilters
        .filter(
          (dsFilter) => dsFilter.dataset === dataset && dsFilter.isDefault,
        )
        .map(datasetFilterToPlate);

      return defaultDatasetFilters;
    },
    [datasetFilterLookup],
  );

  const getDataTypeFilterPlates = useCallback(
    (dataTypes: string[]): DatasetFilter[] => {
      return dataTypes
        .map((dt) => datasetFilterLookup[dt])
        .filter(isDefined)
        .reduce((a, b) => [...a, ...b], []);
    },
    [datasetFilterLookup],
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <DatasetFiltersContext.Provider
      value={{
        datasetFilters,
        getDefaultDatasetFilterPlates,
        getDataTypeFilterPlates,
        datasetFilterLookup,
        datasetFilterIdLookup,
      }}
    >
      {children}
    </DatasetFiltersContext.Provider>
  );
};

export default DatasetFiltersProvider;
