import React, { useContext } from 'react';
import GeneralGoalCard from './GeneralGoalCard';
import { useGoalLink } from '../../GoalListItem/GeneralGoalListItem';
import GeneralGoalContext from '../../../../contexts/GeneralGoalContext';

const GeneralGoalCardContainer = ({
  isCompact,
  workSpaceName,
  workSpaceId,
}: {
  isCompact?: boolean;
  workSpaceName?: string;
  workSpaceId?: string;
}) => {
  const { goal } = useContext(GeneralGoalContext);
  const link = useGoalLink(goal);

  return (
    <GeneralGoalCard
      goal={goal}
      link={link}
      isCompact={isCompact}
      workSpaceId={workSpaceId}
      workspaceName={workSpaceName}
    />
  );
};

export default GeneralGoalCardContainer;
