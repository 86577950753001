import styled from 'styled-components';

const Plate = styled.div`
  background-color: #fbfbfe;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
`;
const LeftPlate = styled.div`
  border-right: 1px solid #e9e9e9;
  padding: 4px 12px;
`;
const MiddlePlate = styled.div`
  border-right: 1px solid #e9e9e9;
  padding: 4px 12px;
`;
const RightPlate = styled.div`
  padding: 4px 12px;
`;

const PlateStyles = {
  Plate,
  LeftPlate,
  MiddlePlate,
  RightPlate,
};

export default PlateStyles;
