import React, { useContext } from 'react';
import MetricFilteringContext from '../../../../contexts/MetricFilteringContext';
import NormalMetricList from './NormalMetricList';
import CompoundMetricList from './CompoundMetricList';

const MetricList = ({
  mode,
  openMetricSlideOut,
  openMetricPopup,
  onCreateMetricClicked,
}: {
  mode: 'Metric' | 'CompoundMetric';
  openMetricSlideOut: (
    m: Metrics.NormalMetric | Metrics.CompoundMetric,
  ) => void;
  openMetricPopup: (m: Metrics.NormalMetric | Metrics.CompoundMetric) => void;
  onCreateMetricClicked: () => void;
}) => {
  const {
    filteredCompoundMetrics,
    filteredMetrics,
    isLoadingMetrics,
    isLoadingCompoundMetrics,
  } = useContext(MetricFilteringContext);

  if (mode === 'Metric') {
    return (
      <NormalMetricList
        isLoading={isLoadingMetrics}
        metrics={filteredMetrics}
        openMetricSlideOut={openMetricSlideOut}
        openMetricPopup={openMetricPopup}
        onCreateMetricClicked={onCreateMetricClicked}
      />
    );
  } else if (mode === 'CompoundMetric') {
    return (
      <CompoundMetricList
        isLoading={isLoadingCompoundMetrics}
        metrics={filteredCompoundMetrics}
        openMetricSlideOut={openMetricSlideOut}
        openMetricPopup={openMetricPopup}
        onCreateMetricClicked={onCreateMetricClicked}
      />
    );
  } else {
    return null;
  }
};

export default MetricList;
