const isOldType = (
  dateRange: RelativeDateRange | RelativeDateRangeType,
): dateRange is RelativeDateRangeType => {
  return typeof dateRange === 'string';
};

const migrateRelativeDateRange = (
  dateRange: RelativeDateRange | RelativeDateRangeType,
): RelativeDateRange => {
  if (isOldType(dateRange)) {
    switch (dateRange) {
      case 'yesterday':
        return {
          type: 'last',
          interval: 'day',
          n: 1,
          currentToDate: false,
        };
      case 'last 7 days':
        return {
          type: 'last',
          interval: 'day',
          n: 7,
          currentToDate: false,
        };
      case 'last 14 days':
        return {
          type: 'last',
          interval: 'day',
          n: 14,
          currentToDate: false,
        };
      case 'last 30 days':
        return {
          type: 'last',
          interval: 'day',
          n: 30,
          currentToDate: false,
        };
      case 'last 60 days':
        return {
          type: 'last',
          interval: 'day',
          n: 60,
          currentToDate: false,
        };
      case 'last 90 days':
        return {
          type: 'last',
          interval: 'day',
          n: 90,
          currentToDate: false,
        };
      case 'last 180 days':
        return {
          type: 'last',
          interval: 'day',
          n: 180,
          currentToDate: false,
        };
      case 'last 365 days':
        return {
          type: 'last',
          interval: 'day',
          n: 365,
          currentToDate: false,
        };
      case 'this week':
        return {
          type: 'current',
          interval: 'week',
          currentToDate: false,
        };
      case 'last week':
        return {
          type: 'last',
          interval: 'week',
          n: 1,
          currentToDate: false,
        };
      case 'last 4 weeks':
        return {
          type: 'last',
          interval: 'week',
          n: 4,
          currentToDate: false,
        };
      case 'last 13 weeks':
        return {
          type: 'last',
          interval: 'week',
          n: 13,
          currentToDate: false,
        };
      case 'this month':
        return {
          type: 'current',
          interval: 'month',
          currentToDate: false,
        };
      case 'last month':
        return {
          type: 'last',
          interval: 'month',
          n: 1,
          currentToDate: false,
        };
      case 'this year':
        return {
          type: 'current',
          interval: 'year',
          currentToDate: false,
        };
      case 'last year':
        return {
          type: 'last',
          interval: 'year',
          n: 1,
          currentToDate: false,
        };
      case 'today':
        return {
          type: 'current',
          interval: 'day',
          currentToDate: false,
        };
      case 'tomorrow':
        return {
          type: 'next',
          interval: 'day',
          n: 1,
          currentToDate: false,
        };
      case 'week to date':
        return {
          type: 'current',
          interval: 'week',
          currentToDate: true,
        };
      case 'month to date':
        return {
          type: 'current',
          interval: 'month',
          currentToDate: true,
        };
      case 'year to date':
        return {
          type: 'current',
          interval: 'year',
          currentToDate: true,
        };
      case 'next 2 days':
        return {
          type: 'next',
          interval: 'day',
          n: 2,
          currentToDate: true,
        };
      case 'next 5 days':
        return {
          type: 'next',
          interval: 'day',
          n: 5,
          currentToDate: true,
        };
      case 'next 7 days':
        return {
          type: 'next',
          interval: 'day',
          n: 7,
          currentToDate: true,
        };
      case 'next 30 days':
        return {
          type: 'next',
          interval: 'day',
          n: 30,
          currentToDate: true,
        };
      default:
        return {
          type: 'last',
          interval: 'day',
          n: 30,
          currentToDate: true,
        };
    }
  } else {
    return dateRange;
  }
};

export default migrateRelativeDateRange;
