import React from 'react';
import styled from 'styled-components';

import Typography from '../../kingpin/atoms/Typography';
import Colors from '../../theme/colors';
import { TOP_BAR_HEIGHT } from '../../constants';
import Colors2 from '../../theme/Colors2';
import Row from '../../components/Common/Row';

const TopBar = styled.div`
  background-color: ${Colors.WHITE};
  width: 100%;
  height: ${TOP_BAR_HEIGHT}px;
  border-bottom: 1px solid ${Colors2.Border};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px;
`;

const SettingsTopBar = ({
  title,
  rightButton,
  leftButton,
}: {
  title: string;
  rightButton?: JSX.Element;
  leftButton?: JSX.Element;
}) => (
  <TopBar>
    <Row centerAlign>
      {leftButton}
      <Typography.Header type="H4">{title}</Typography.Header>
    </Row>
    {rightButton}
  </TopBar>
);

export default SettingsTopBar;
