import firebase from 'firebase/compat/app';
import withoutNulls from './search/withoutNulls';

const createNotification = async (
  notification: Notifications.Notification,
  userId: string,
  accountRef: firebase.firestore.DocumentReference,
) => {
  return accountRef
    .collection('users')
    .doc(userId)
    .collection('notifications-v2')
    .doc(notification.id)
    .set(withoutNulls(notification));
};

export default createNotification;
