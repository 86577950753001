import React from 'react';

interface SavedFiltersContextType {
  savedFilters: SavedFilter[];
  savedFiltersLookup: { [id: string]: SavedFilter | undefined };
  isLoading: boolean;
}

const SavedFiltersContext = React.createContext<SavedFiltersContextType>({
  savedFilters: [],
  savedFiltersLookup: {},
  isLoading: process.env.NODE_ENV === 'test' ? false : true,
});

export default SavedFiltersContext;
