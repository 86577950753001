/* eslint-disable */
import React from 'react';
import useV5FixedTrendCard from '../../../../hooks/useV5FixedTrendCard';
import { TrendCardContent } from '../../../Gadget/TrendCard';
import useMetric from '../../../../hooks/useMetric';
import Loading from '../../../Loading';

const FixedTrendCardContentContainer = ({
  chartDefinition,
}: {
  chartDefinition: V5ChartDefinition;
}) => {
  const { trendItems } = useV5FixedTrendCard(chartDefinition);
  const { series } = chartDefinition;
  const metric = useMetric(series[0].metricId);
  if (!metric) {
    return <Loading />;
  }
  const { positiveDeltaIsGood } = metric.formatting;

  return (
    <TrendCardContent
      items={trendItems}
      positiveDeltaIsGood={positiveDeltaIsGood}
      metricId={metric.id}
    />
  );
};

export default FixedTrendCardContentContainer;
