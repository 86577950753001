import React from 'react';
import Typography from 'kingpin/atoms/Typography';
import useGetFieldLabel from 'hooks/useGetFieldLabel';
import GlobalSearchStyles from './styles';

const EntityHeaderRow = ({ entity }: { entity: EntityDetails.Entity }) => {
  const { getFieldLabel } = useGetFieldLabel();

  return (
    <GlobalSearchStyles.GridHeaderRow>
      <GlobalSearchStyles.Col>
        <Typography.Header type="H5">
          {getFieldLabel({
            field: entity.primaryField,
            dataType: entity.entityDataset,
          })}
        </Typography.Header>
      </GlobalSearchStyles.Col>
      {entity.secondaryFields.map((f) => (
        <GlobalSearchStyles.Col key={f}>
          <Typography.Header type="H5">
            {getFieldLabel({
              field: f,
              dataType: entity.entityDataset,
            })}
          </Typography.Header>
        </GlobalSearchStyles.Col>
      ))}
      {entity.contextFields.map((f) => (
        <GlobalSearchStyles.Col key={f}>
          <Typography.Header type="H5">
            {getFieldLabel({
              field: f,
              dataType: entity.entityDataset,
            })}
          </Typography.Header>
        </GlobalSearchStyles.Col>
      ))}
    </GlobalSearchStyles.GridHeaderRow>
  );
};

export default EntityHeaderRow;
