import React from 'react';

const IFrameGadget = ({ snippet }: { snippet: string }) => (
  <div style={{ margin: 13, width: '100%', height: '100%' }}>
    <div
      dangerouslySetInnerHTML={{ __html: snippet }}
      style={{ height: 'calc(100% - 26px)' }}
    />
  </div>
);

export default IFrameGadget;
