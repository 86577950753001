import { useCallback, useEffect, useState } from 'react';
import useGetMetricDatasets from './useGetMetricDatasets';
import useGetDatasetLabel from './useGetDatasetLabel';

export const useGetMetricDatasetLabel = () => {
  const getMetricDatasets = useGetMetricDatasets();
  const getDatasetLabel = useGetDatasetLabel();

  const getMetricDatasetLabel = useCallback(
    (metric: Metrics.Metric | undefined) => {
      if (!metric) {
        return {
          datasetLabel: '',
          datasets: [],
        };
      }

      const datasets = getMetricDatasets(metric);
      if (datasets.length === 0) {
        return {
          datasetLabel: '',
          datasets: [],
        };
      } else if (datasets.length === 1) {
        return {
          datasetLabel: getDatasetLabel(datasets[0]),
          datasets: datasets,
        };
      } else {
        return {
          datasetLabel: datasets
            .map(getDatasetLabel)
            .join(', ')
            .replace('^,', ''),
          datasets,
        };
      }
    },
    [getDatasetLabel, getMetricDatasets],
  );

  return getMetricDatasetLabel;
};

const useMetricDatasetLabel = (metric: Metrics.Metric | undefined) => {
  const [datasetLabel, setDatasetLabel] = useState<string>('');
  const [datasets, setDatasets] = useState<FleetOps.DatasetDefinition[]>([]);
  const getDatasetLabel = useGetMetricDatasetLabel();

  // Find the dataset definitions used
  useEffect(() => {
    const { datasetLabel: newLabel, datasets: newDatasets } =
      getDatasetLabel(metric);
    setDatasetLabel(newLabel);
    setDatasets(newDatasets);
  }, [getDatasetLabel, metric]);

  return { datasetLabel, datasets };
};

export default useMetricDatasetLabel;
