import React, { useContext, useEffect, useState } from 'react';
import ReportDateDrillDownOptionsContext from 'contexts/ReportDateDrillDownOptionsContext';
import moment from 'moment';
import relativeDateRangeToDateRange from '../relativeDateRangeToDateRange';
import migrateRelativeDateRange from '../migrateRelativeDateRange';
import DateInputContext from '../contexts/DateInputContext';

const getOptions = (
  dateRange: DateRangeInput,
  unit: 'day' | 'week' | 'month' | 'quarter' | 'year',
): DateOption[] => {
  const getStartMoment = () => moment.utc(dateRange.startDate);
  const getEndMoment = () => moment.utc(dateRange.endDate);
  const firstIntervalStartMoment = getStartMoment().startOf(unit);
  const lastIntervalEndMoment = getEndMoment().endOf(unit);

  const x = lastIntervalEndMoment.diff(firstIntervalStartMoment, unit);
  const result = [];
  for (let i = 0; i < x; i++) {
    const s = getStartMoment().startOf(unit).add(i, unit);
    const offset = unit === 'day' ? 0 : 1;
    const e = getStartMoment()
      .startOf(unit)
      .add(i + offset, unit);
    result.push({
      label: s.format('YYYY-MM-DD'),
      value: {
        startDate: s.format('YYYY-MM-DD'),
        endDate: e.format('YYYY-MM-DD'),
      },
    });
  }

  return result;
};

const ReportDateDrillDownOptionsProvider = ({
  children,
  startOfWeek,
}: {
  children: JSX.Element | JSX.Element[];
  startOfWeek: WeekStartsOn;
}) => {
  const { relativeDateRange, dateRange } = useContext(DateInputContext);

  const [dayOptions, setDayOptions] = useState<DateOption[]>([]);
  const [weekOptions, setWeekOptions] = useState<DateOption[]>([]);
  const [monthOptions, setMonthOptions] = useState<DateOption[]>([]);
  const [quarterOptions, setQuarterOptions] = useState<DateOption[]>([]);
  const [yearOptions, setYearOptions] = useState<DateOption[]>([]);

  useEffect(() => {
    if (!relativeDateRange || dateRange) {
      return;
    }

    const workingDateRange = dateRange
      ? dateRange
      : relativeDateRangeToDateRange({
          relativeDateRange: migrateRelativeDateRange(relativeDateRange),
          startOfWeek,
        });

    setDayOptions(getOptions(workingDateRange, 'day'));
    setWeekOptions(getOptions(workingDateRange, 'week'));
    setMonthOptions(getOptions(workingDateRange, 'month'));
    setQuarterOptions(getOptions(workingDateRange, 'quarter'));
    setYearOptions(getOptions(workingDateRange, 'year'));
  }, [dateRange, relativeDateRange, startOfWeek]);

  return (
    <ReportDateDrillDownOptionsContext.Provider
      value={{
        dayOptions,
        weekOptions,
        monthOptions,
        quarterOptions,
        yearOptions,
      }}
    >
      {children}
    </ReportDateDrillDownOptionsContext.Provider>
  );
};

export default ReportDateDrillDownOptionsProvider;
