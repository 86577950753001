import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import aguid from 'aguid';

const buildQuery = ({
  accountId,
  orderNumber,
}: {
  accountId: string;
  orderNumber: string;
}) => ({
  query: gql`
    query GetTimeline($accountId: String!, $orderNo: String!) {
      getTimeline(accountId: $accountId, orderNo: $orderNo) {
        truck
        trailer
        driver
        event {
          ... on Move {
            startTimeStamp
            endTimeStamp
            transitTimeDurationMinutes
            line
          }

          ... on Stop {
            customer
            location
            cityAndState
            zipCode
            stage
            arrivedTimeStamp
            departedTimeStamp
            dwellTimeDurationMinutes
            etaOnTarget
            turnAroundOnTarget
            point
          }
        }
      }
    }
  `,
  variables: {
    accountId,
    orderNo: orderNumber,
  },
});

const getTimelineEvents = async (
  accountId: string,
  orderNumber: string,
  client: ApolloClient<NormalizedCacheObject>,
) => {
  const query = buildQuery({ accountId, orderNumber });
  const response = await client.query(query);
  return response.data.getTimeline.map((t: any) => ({
    ...t,
    clientId: aguid(),
  }));
};

export default getTimelineEvents;
