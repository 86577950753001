const MODIFY_COMMISSIONS: FleetOps.Permission =
  'fleetops.permissions.modify_commissions';
const TOGGLE_ON_BOARDING: FleetOps.Permission =
  'fleetops.permissions.toggle_on_boarding';
const TOGGLE_CONNECTORS: FleetOps.Permission =
  'fleetops.permissions.toggle_connectors';
const SET_QUOTE: FleetOps.Permission = 'fleetops.permissions.sales_set_quotes';
const MANAGE_PRODUCT_TIER: FleetOps.Permission =
  'fleetops.permissions.manage_product_tier';

const INTERNAL_USE_PERMISSIONS = {
  MODIFY_COMMISSIONS,
  TOGGLE_ON_BOARDING,
  TOGGLE_CONNECTORS,
  SET_QUOTE,
  MANAGE_PRODUCT_TIER,
};
Object.freeze(INTERNAL_USE_PERMISSIONS);

export default INTERNAL_USE_PERMISSIONS;
