import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import styled from 'styled-components';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Row from '../../Common/Row';
import Typography from 'kingpin/atoms/Typography';
import Colors2 from '../../../theme/Colors2';
import colors from '../../../theme/colors';
import CustomCalendarHeader from './CustomCalendarHeader';
import DateFieldPicker from '../DateFieldPicker';
import Tooltip from '../../Tooltip';
import AnalyticsContext from '../../../contexts/AnalyticsContext';
import TextInput from '../../../kingpin/atoms/TextInput';
import { MAX_WIDTH_CALENDAR_INPUT } from '../constants';

export const CalendarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .fleetops-calendar {
    border: unset;
    width: 100%;
    height: 275px;
    .react-datepicker__month-container {
      width: 100%;
    }

    .react-datepicker__day--in-range {
      background-color: #ffeff2;
      color: ${colors.GUN_METAL};
    }

    .react-datepicker__day--range-start,
    .react-datepicker__day--range-end {
      background-color: ${colors.FLEETOPS_RED};
      color: ${colors.WHITE};
    }

    .react-datepicker__header {
      background-color: ${colors.WHITE};
      border-bottom: unset;
    }

    .react-datepicker__day-name {
      color: #828282;
    }

    .react-datepicker__day--today {
      text-decoration: underline;
    }

    .react-datepicker__day-names {
      display: flex;
      justify-content: space-between;
    }

    .react-datepicker__week {
      display: flex;
      justify-content: space-between;
    }

    .react-datepicker__month {
      margin: 0px;
    }
  }
`;

const Wrapper = styled.div`
  max-width: ${MAX_WIDTH_CALENDAR_INPUT}px;
  flex-direction: column;
  padding: 12px;
  border-radius: 8px;
  align-items: center;
`;

const DateInputWrapper = styled.div<{ isSelected: boolean; isValid: boolean }>`
  padding: 4px 8px;
  border-radius: 8px;
  border: solid 1px
    ${(props) =>
      props.isSelected ? Colors2.Secondary.info : Colors2.Grey['7']};
  ${(props) =>
    !props.isValid &&
    `box-shadow:inset 0px 0px 0px 2px ${Colors2.Secondary.error};`}

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

const SmartDateInput = ({
  dateTime,
  onDateChanged,
  isStartDate,
  isSelected,
  setCurrentlySelecting,
  onClosed,
}: {
  dateTime: string; // YYYY-MM-DDThh:mm:ss
  onDateChanged: (newDate: string) => void;
  isStartDate?: boolean;
  isSelected: boolean;
  setCurrentlySelecting: React.Dispatch<
    React.SetStateAction<'startDate' | 'endDate'>
  >;
  onClosed: () => void;
}) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const [date, setDate] = useState(() => dateTime.split('T')[0]);
  const [day, setDay] = useState<string>(() => date.split('-')[2]);
  const [month, setMonth] = useState<string>(() => date.split('-')[1]);
  const [year, setYear] = useState<string>(() => date.split('-')[0]);
  const dayRef = useRef<HTMLInputElement>(null);
  const monthRef = useRef<HTMLInputElement>(null);
  const yearRef = useRef<HTMLInputElement>(null);

  const [hasUpdated, setHasUpdated] = useState<boolean>(false);

  useEffect(() => {
    setDate(dateTime.split('T')[0]);
  }, [dateTime]);

  useEffect(() => {
    setDay(date.split('-')[2]);
    setMonth(date.split('-')[1]);
    setYear(date.split('-')[0]);
  }, [date]);

  useEffect(() => {
    if (monthRef.current && isStartDate) {
      monthRef.current.focus();
    }
  }, [isStartDate]);

  const getIsValidDate = useCallback(
    () =>
      moment(`${year}-${month}-${day}`).isValid() &&
      Number.parseInt(year) >= 1970,
    [day, month, year],
  );

  const trackKeyboardUsed = useCallback(() => {
    trackEvent('DateInput - Keyboard used');
  }, [trackEvent]);

  const buildDate = useCallback(() => {
    let d = day;
    let m = month;

    if (d.length === 1) {
      d = `0${d}`;
    }

    if (m.length === 1) {
      m = `0${m}`;
    }

    return `${year}-${m}-${d}`;
  }, [day, month, year]);

  const onBlur = useCallback(() => {
    const isDayFocused =
      dayRef.current && dayRef.current === document.activeElement;
    const isMonthFocused =
      monthRef.current && monthRef.current === document.activeElement;
    const isYearFocused =
      yearRef.current && yearRef.current === document.activeElement;

    if (isDayFocused || isMonthFocused || isYearFocused) {
      return;
    }

    if (getIsValidDate() && hasUpdated) {
      trackKeyboardUsed();
      onDateChanged(buildDate());
    }
    setHasUpdated(false);
  }, [buildDate, getIsValidDate, hasUpdated, onDateChanged, trackKeyboardUsed]);

  const onFocus = useCallback(() => {
    setCurrentlySelecting(isStartDate ? 'startDate' : 'endDate');
    const isDayFocused =
      dayRef.current && dayRef.current === document.activeElement;
    const isMonthFocused =
      monthRef.current && monthRef.current === document.activeElement;
    const isYearFocused =
      yearRef.current && yearRef.current === document.activeElement;

    if (isDayFocused) {
      if (dayRef.current) {
        dayRef.current.select();
      }
    }
    if (isMonthFocused) {
      if (monthRef.current) {
        monthRef.current.select();
      }
    }
    if (isYearFocused) {
      if (yearRef.current) {
        yearRef.current.select();
      }
    }
  }, [isStartDate, setCurrentlySelecting]);

  const isValidDate = getIsValidDate();

  return (
    <DateInputWrapper isValid={isValidDate} isSelected={isSelected}>
      <Row centerAlign>
        <div
          style={{
            marginRight: 4,
          }}
        >
          <Tooltip content="MM">
            <TextInput
              type="number"
              isTransparentBorders
              inputVariant="Date"
              width="32px"
              placeholder="MM"
              onFocus={onFocus}
              forwardRef={monthRef}
              value={month}
              onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (
                  event.code === 'Slash' ||
                  event.code === 'Minus' ||
                  event.code === 'Tab'
                ) {
                  if (dayRef.current) {
                    dayRef.current.focus();
                    event.preventDefault();
                  }
                }

                if (event.code === 'Enter') {
                  if (getIsValidDate() && hasUpdated) {
                    onDateChanged(buildDate());
                  }
                  setHasUpdated(false);
                  setTimeout(onClosed, 400);
                }
              }}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                event.preventDefault();
                setMonth(event.target.value);
                setHasUpdated(true);
                if (event.target.value.length > 1) {
                  setTimeout(() => {
                    if (dayRef.current) {
                      dayRef.current.focus();
                    }
                  }, 100);
                }
              }}
              onBlur={onBlur}
            />
          </Tooltip>
        </div>
        <div style={{ marginRight: 4 }}>
          <Typography.Body type="Body 12">/</Typography.Body>
        </div>
        <div style={{ marginRight: 4 }}>
          <Tooltip content="DD">
            <TextInput
              type="number"
              isTransparentBorders
              inputVariant="Date"
              width="32px"
              placeholder="DD"
              onFocus={onFocus}
              forwardRef={dayRef}
              value={day}
              onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (
                  event.code === 'Slash' ||
                  event.code === 'Minus' ||
                  event.code === 'Tab'
                ) {
                  if (yearRef.current) {
                    yearRef.current.focus();
                    event.preventDefault();
                  }
                }

                if (event.code === 'Enter') {
                  if (getIsValidDate() && hasUpdated) {
                    onDateChanged(buildDate());
                  }
                  setHasUpdated(false);
                  setTimeout(onClosed, 400);
                }
              }}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                event.preventDefault();
                setDay(event.target.value);
                setHasUpdated(true);
                if (event.target.value.length > 1) {
                  setTimeout(() => {
                    if (yearRef.current) {
                      yearRef.current.focus();
                    }
                  }, 100);
                }
              }}
              onBlur={onBlur}
            />
          </Tooltip>
        </div>
        <div style={{ marginRight: 4 }}>
          <Typography.Body type="Body 12">/</Typography.Body>
        </div>
        <Tooltip content="YYYY">
          <TextInput
            isTransparentBorders
            inputVariant="Date"
            width="40px"
            placeholder="YYYY"
            type="number"
            onFocus={onFocus}
            forwardRef={yearRef}
            value={year}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.code === 'Slash' || event.code === 'Minus') {
                if (yearRef.current) {
                  onBlur();
                  event.preventDefault();
                }
              }

              if (event.code === 'Enter') {
                if (getIsValidDate() && hasUpdated) {
                  onDateChanged(buildDate());
                }
                setHasUpdated(false);
                setTimeout(onClosed, 400);
              }
            }}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              event.preventDefault();
              setYear(event.target.value);
              setHasUpdated(true);
            }}
            onBlur={onBlur}
          />
        </Tooltip>
      </Row>
    </DateInputWrapper>
  );
};

const CalendarInput = ({
  startDate,
  onStartDateUpdated,
  endDate,
  onEndDateUpdated,
  currentlySelecting,
  setCurrentlySelecting,
  onCalendarDateChange,
  onClosed,
  isDateFieldPickerDisabled,
}: {
  startDate: string;
  onStartDateUpdated: (newDate: string) => void;
  endDate: string;
  onEndDateUpdated: (newDate: string) => void;
  currentlySelecting: 'startDate' | 'endDate';
  setCurrentlySelecting: React.Dispatch<
    React.SetStateAction<'startDate' | 'endDate'>
  >;
  onCalendarDateChange: (newDate: Date) => void;
  onClosed: () => void;
  isDateFieldPickerDisabled?: boolean;
}) => (
  <Wrapper>
    <div style={{ marginBottom: 12, padding: 2 }}>
      <Row centerAlign spaceBetween>
        <div style={{ marginRight: 4 }}>
          <SmartDateInput
            isSelected={currentlySelecting === 'startDate'}
            dateTime={startDate}
            onDateChanged={onStartDateUpdated}
            setCurrentlySelecting={setCurrentlySelecting}
            isStartDate
            onClosed={onClosed}
          />
        </div>
        <div style={{ marginRight: 4 }}>
          <Typography.Body type="Body 12">—</Typography.Body>
        </div>
        <SmartDateInput
          isSelected={currentlySelecting === 'endDate'}
          dateTime={endDate}
          onDateChanged={onEndDateUpdated}
          setCurrentlySelecting={setCurrentlySelecting}
          onClosed={onClosed}
        />
      </Row>
    </div>
    <div style={{ marginBottom: 12 }}>
      <CalendarWrapper id={'calendar'}>
        <DatePicker
          calendarClassName={'fleetops-calendar'}
          selected={
            currentlySelecting === 'startDate'
              ? moment(startDate).toDate()
              : moment(endDate).toDate()
          }
          renderCustomHeader={CustomCalendarHeader}
          startDate={moment(startDate).toDate()}
          endDate={moment(endDate).toDate()}
          inline
          onChange={onCalendarDateChange}
          disabledKeyboardNavigation
        />
      </CalendarWrapper>
    </div>
    {!isDateFieldPickerDisabled && (
      <div style={{ padding: 2 }}>
        <DateFieldPicker />
      </div>
    )}
  </Wrapper>
);

export default CalendarInput;
