import React from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';

import PopupGrid from '../PopupGrid';
import Loading from '../Loading';
import GridProvider from '../../contextProviders/GridProvider';
import { Option } from 'kingpin/atoms/ContextMenu';

const PopupGridButton = ({
  isGridOpen,
  openGrid,
  closeGrid,
  isLoadingGridDefinitions,
  dataType,
  metricFiltering,
  gridQueryOverride,
  gridCountQueryOverride,
  gridTotalsQueryOverride,
  dateScopeOverride,
}: {
  isGridOpen: boolean;
  openGrid: () => void;
  closeGrid: () => void;
  isLoadingGridDefinitions: boolean;
  dataType: string;
  metricFiltering?: FilterInput;
  gridQueryOverride?: string;
  gridCountQueryOverride?: string;
  gridTotalsQueryOverride?: string;
  dateScopeOverride?: DateRangeInput;
}) => (
  <React.Fragment>
    <Option
      label={'View Raw Data'}
      onClick={openGrid}
      icon={'table'}
      shouldHideInsteadOfCloseMenu
    />
    <ModalTransition>
      {isGridOpen && (
        <Modal
          width={'80vw'}
          height={'90vh'}
          onClose={closeGrid}
          shouldScrollInViewport={false}
          autoFocus={false}
        >
          {isLoadingGridDefinitions && <Loading />}
          {!isLoadingGridDefinitions && (
            <GridProvider
              dataType={dataType}
              metricFiltering={metricFiltering}
              gridQueryOverride={gridQueryOverride}
              gridCountQueryOverride={gridCountQueryOverride}
              gridTotalsQueryOverride={gridTotalsQueryOverride}
              dateScopeOverride={dateScopeOverride}
            >
              <PopupGrid close={closeGrid} />
            </GridProvider>
          )}
        </Modal>
      )}
    </ModalTransition>
  </React.Fragment>
);

export default PopupGridButton;
