import STORE from 'store';

const getPreviousUploads = async (
  accountId: string,
  dataType: string,
): Promise<DataManager.PreviousUpload[]> => {
  const data: DataManager.PreviousUpload[] = [];
  const docs = await STORE.getPreviousUploadsRef({
    accountId,
  })
    .where('dataType', '==', dataType)
    .get();

  docs.forEach((d) => {
    data.push(d.data());
  });

  return data;
};

export default getPreviousUploads;
