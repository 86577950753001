import { useContext, useEffect, useState } from 'react';
import AccountPickerContext from '../contexts/AccountPickerContext';
import isDriverPerformanceBoard from '../isDriverPerformanceBoard';
import useUsers from './useUsers';
import STORE from '../store';

const removeDeletedAssignees = (board: Board, users: UserManagement.User[]) => {
  if (isDriverPerformanceBoard(board)) {
    if (board.assignedToId) {
      return {
        ...board,
        assignedToId: board.assignedToId.filter((id) =>
          users.some((u) => u.id === id),
        ),
      };
    } else {
      return board;
    }
  } else {
    return board;
  }
};

const useBoards = () => {
  const users = useUsers();
  const [otherBoards, setOtherBoards] = useState<Board[]>([]);
  const [performanceBoards, setPerformanceBoards] = useState<
    PerformanceBoardTypes.Board[]
  >([]);
  const [boards, setBoards] = useState<(PerformanceBoardTypes.Board | Board)[]>(
    [],
  );
  const { accountRef, selectedAccountId } = useContext(AccountPickerContext);

  useEffect(() => {
    const listener = STORE.getBoardsRef({
      accountId: selectedAccountId,
    }).onSnapshot((s) => {
      const data: Board[] = [];
      s.forEach((doc) => {
        const board = doc.data();
        data.push(removeDeletedAssignees(board, users));
      });
      setOtherBoards(data.filter((b) => !b.isHidden));
    });

    return () => {
      listener();
    };
  }, [accountRef, selectedAccountId, users]);

  useEffect(() => {
    const listener = STORE.contentDistributions
      .getPerformanceBoardsRef({
        accountId: selectedAccountId,
      })
      .onSnapshot((snapshot) => {
        const data: PerformanceBoardTypes.Board[] = [];
        snapshot.docs.forEach((doc) => data.push(doc.data()));
        setPerformanceBoards(data.filter((b) => !b.isHidden));
      });

    return () => {
      listener();
    };
  }, [selectedAccountId]);

  useEffect(() => {
    setBoards([...performanceBoards, ...otherBoards]);
  }, [otherBoards, performanceBoards]);

  return boards;
};

export default useBoards;
