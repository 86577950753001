import isV5ChartDef from './types/visTypeCheckers/isV5ChartDef';

const getGadgetType = (def: VisualisationDefinition) => {
  if (isV5ChartDef(def)) {
    return def.gadgetType;
  } else {
    return def.type;
  }
};

export default getGadgetType;
