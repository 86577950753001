import Row from '../Common/Row';
import ShowOnMouseOverUnlessTour from '../ShowOnMouseOverUnlessTour';
import DateInput from '../DateInput';
import React, { useContext } from 'react';
import CanvasContext from '../../contexts/CanvasContext';
import CardContext from '../../contexts/CardContext';
import MetricListContext from '../../contexts/MetricListContext';

const MetricListBottomBar = ({ isPreview }: { isPreview?: boolean }) => {
  const { metricList } = useContext(MetricListContext);
  const { setCardOnTopI } = useContext(CanvasContext);
  const { i } = useContext(CardContext);

  const onDatePickerOpen = () => {
    setCardOnTopI(i);
  };

  if (isPreview || !metricList) {
    return null;
  }

  return (
    <Row centerAlign>
      <ShowOnMouseOverUnlessTour isForcedVisible={true}>
        <DateInput
          isBonusPeriodMode={metricList.isBonusPeriodMode}
          onDatePickerOpen={onDatePickerOpen}
        />
      </ShowOnMouseOverUnlessTour>
    </Row>
  );
};

export default MetricListBottomBar;
