import React from 'react';
import GeneralOnTargetBadge from './GeneralOnTargetBadge';
import visTypeCheckers from '../../../types/visTypeCheckers';

const OnTargetBadge = ({
  goal,
  isCompact,
}: {
  goal: GeneralGoal;
  isCompact?: boolean;
}) => {
  if (visTypeCheckers.isGeneralGoal(goal)) {
    return <GeneralOnTargetBadge isCompact={isCompact} />;
  }
  return null;
};

export default OnTargetBadge;
