import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import AccountContext from '../../../contexts/AccountContext';
import useMetricMatrix from '../../../hooks/useMetricMatrix';
import getGroupByField from '../../../hooks/useMetricMatrix/getGroupByField';
import stackedBar from '../highchartOptions/stackedBarOptions';
import { ChartContent } from '../../Chart';
import {
  buildCategories,
  buildCategoriesAlt,
  buildStackedSeries,
  buildStackedSeriesAlt,
} from '../stackedFormatters';
import toAutoInterval from '../toAutoInterval';
import useChartDrillDowns from '../../../hooks/useChartDrillDowns';
import useMetric from '../../../hooks/useMetric';
import Loading from '../../Loading';
import CardContext from '../../../contexts/CardContext';
import { DEFAULT_FORMATTING } from '../../../constants';
import useValueFormatters from '../../../hooks/useValueFormatters';
import ChartColorsContext from '../../../contexts/ChartColorsContext';

const StackedBarContent = ({
  chartDefinition,
  chartRef,
}: {
  chartDefinition: V5ChartDefinition;
  chartRef: React.MutableRefObject<any>;
}) => {
  // Context
  const { i } = useContext(CardContext);
  const { unitsLocale } = useContext(AccountContext);
  const { getChartColor, getChartColorIndex } = useContext(ChartColorsContext);

  // State
  const [series, setSeries] = useState<StackedSeries[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [termLookup, setTermLookup] = useState<
    { [term: string]: string } | undefined
  >();
  const [autoInterval, setAutoInterval] = useState<AutoInterval | undefined>();
  const [groupKey] = useState(getGroupByField(chartDefinition));
  const [options, setOptions] = useState<Highcharts.Options | undefined>();

  // Hooks
  const { formatMetric } = useValueFormatters();
  const { isLoading, rowData } = useMetricMatrix({
    chartDef: chartDefinition,
    skip2DRowDataFormatting: true,
  });
  const metric = useMetric(chartDefinition.series[0].metricId);
  const { onDrillDown, syncChart } = useChartDrillDowns(
    chartDefinition.dimensionA ? chartDefinition.dimensionA.field : '',
    chartRef,
    'stackedBar',
    chartDefinition,
    autoInterval,
  );

  useEffect(() => {
    syncChart();
  }, [syncChart]);

  // Effects
  useEffect(() => {
    if (!rowData) {
      return;
    }
    if (chartDefinition.trendByCalendarInterval !== 'auto') {
      return;
    }
    try {
      // @ts-ignore
      const aI = Object.keys(rowData[0])['interval'] as string;
      const newAI = toAutoInterval(aI);
      setAutoInterval((currentAi) =>
        _.isEqual(currentAi, newAI) ? currentAi : newAI,
      );
    } catch (ex) {
      return undefined;
    }
  }, [chartDefinition.trendByCalendarInterval, rowData]);

  useEffect(() => {
    const altPivot = (() => {
      if (chartDefinition.stackedBarOptions) {
        return chartDefinition.stackedBarOptions.altPivot;
      }

      return false;
    })();

    if (!metric) {
      return;
    }

    if (!isLoading) {
      if (altPivot) {
        setSeries(
          buildStackedSeriesAlt({
            rowData,
            groupKey,
            chartDef: chartDefinition,
            getChartColor,
            getChartColorIndex,
            autoInterval,
          }),
        );
        setCategories(buildCategoriesAlt(rowData, groupKey));
      } else {
        const { prettyDates: newCategories, termLookup: newTermLookup } =
          buildCategories(rowData, groupKey, chartDefinition, autoInterval);
        setSeries(
          buildStackedSeries({
            rowData,
            groupKey,
            newCategories,
            newTermLookup,
            getChartColor,
            getChartColorIndex,
            metricId: metric.id,
          }),
        );
        setCategories(newCategories);
        setTermLookup(newTermLookup);
      }
    }
  }, [
    groupKey,
    rowData,
    isLoading,
    chartDefinition,
    autoInterval,
    metric,
    getChartColor,
    getChartColorIndex,
  ]);

  useEffect(() => {
    const direction = (() => {
      if (chartDefinition.stackedBarOptions) {
        return chartDefinition.stackedBarOptions.direction;
      }

      return 'horizontal';
    })();
    const asPercentage = (() => {
      if (chartDefinition.stackedBarOptions) {
        return chartDefinition.stackedBarOptions.asPercentage;
      }

      return false;
    })();

    const xAxis = {
      categories,
      labels: {
        style: {
          fontSize: 11,
        },
      },
    };

    if (!termLookup) {
      return;
    }

    const formatting = metric ? metric.formatting : DEFAULT_FORMATTING;
    const { precision, postfix, prefix, positiveDeltaIsGood } = formatting;

    const newOptions = stackedBar({
      formatMetric,
      title: ' ',
      series: series.map((s) => ({
        ...s,
        onDrillDown,
        termLookup,
        precision,
        postfix,
        prefix,
        positiveDeltaIsGood,
        animation: false,
      })),
      xAxis,
      direction,
      asPercentage,
      fontSize: 11,
    });
    setOptions(newOptions as any as Highcharts.Options);
  }, [
    autoInterval,
    categories,
    chartDefinition,
    formatMetric,
    groupKey,
    isLoading,
    metric,
    onDrillDown,
    rowData,
    series,
    termLookup,
    unitsLocale,
  ]);

  if (groupKey === undefined) {
    throw new Error(
      `Group key not found for chartDefId: ${chartDefinition.id}`,
    );
  }

  if (!options) {
    return <Loading />;
  }

  return (
    <ChartContent chartRef={chartRef} options={options} key={`chart-${i}`} />
  );
};

export default StackedBarContent;
