import React, { useCallback, useContext, useEffect, useState } from 'react';
import { CellPreview } from './CategoryItemRight';
import Colors2 from 'theme/Colors2';
import Typography from 'kingpin/atoms/Typography';
import useValueFormatters from 'hooks/useValueFormatters';
import ActivityGridContext from '../context/ActivityGridContext';
import useLockedDebouncedEffect from 'hooks/useLockedDebouncedEffect';
import Loading from 'components/Loading/Loading';

interface GetFormattedValueArgs {
  startDate: string;
  groupDefinition: Costs.Group;
  costFieldName: string;
}

const CostPerMileCell = ({
  interval,
  fieldName,
  group,
  hoverId,
}: {
  interval: Period;
  fieldName: string;
  group: Costs.Group;
  hoverId: string;
}) => {
  const { focusedInterval, hoveredCategory, getCpmValue } =
    useContext(ActivityGridContext);
  const [value, setValue] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [args, setArgs] = useState<GetFormattedValueArgs | undefined>();
  const { formatValue } = useValueFormatters();

  useEffect(() => {
    setArgs({
      startDate: interval.startDate,
      groupDefinition: group,
      costFieldName: fieldName,
    });
  }, [fieldName, group, interval.startDate]);

  const formatOngoingValue = useCallback(
    async (args: GetFormattedValueArgs) => {
      setIsLoading(true);
      const value = (await getCpmValue(args)) || 0;

      const formatting = {
        prefix: 'currency' as 'currency',
        currencyType: 'USD' as 'USD',
        precision: 3,
      };

      const formatted = formatValue({ value, formatting });

      return formatted;
    },
    [formatValue, getCpmValue],
  );

  const responseHandler = useCallback((newValue: string) => {
    setValue(newValue);
    setIsLoading(false);
  }, []);

  useLockedDebouncedEffect({
    args,
    callback: formatOngoingValue,
    responseHandler,
  });

  return (
    <div style={{ width: '100%' }}>
      <CellPreview
        style={{
          padding: '6px 8px',
          opacity: 1,
          backgroundColor:
            focusedInterval === interval
              ? '#F9F9F9'
              : hoverId === hoveredCategory
                ? undefined
                : '#ffffff',
          borderRight: `1px solid ${Colors2.Grey['7']}`,
        }}
      >
        {isLoading && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Loading isTiny />
          </div>
        )}
        {!isLoading && (
          <div style={{ marginRight: 4, display: 'flex' }}>
            <Typography.Body
              data-testid={`cpm-${interval.startDate}-${group.groupName}-${fieldName}`}
              type="Body 12"
              color={Colors2.Grey['3']}
            >
              {value}
            </Typography.Body>
          </div>
        )}
      </CellPreview>
    </div>
  );
};

export default CostPerMileCell;
