import React, { useContext, useEffect, useState } from 'react';
import RolesContext from '../../contexts/RolesContext';
import isDefined from '../../isDefined';
import useIsDriverBonusRolesEnabled from '../../components/InviteUsersForm/useIsDriverBonusRolesEnabled';
import USER_ROLES from '../../roles';
import PERMISSIONS from '../../permissionDefinitions';

import useCurrentPermissions from './useCurrentPermissions';
import usePermissionsApi from './usePermissionsApi';
import useAvailableRoles from './useAvailableRoles';
import Loading from '../../components/Loading';
import FeatureGatesContext from '../../contexts/FeatureGatesContext';
import { FEATURE_GATES } from '../../components/FeatureGate';

const PRIMARY_ROLES = [USER_ROLES.VIEWER, USER_ROLES.EDITOR, USER_ROLES.ADMIN];
const ADDITIONAL_ROLES = [USER_ROLES.BOARD_OWNER, USER_ROLES.CAMPAIGN_OWNER];

const GRANTABLE_ROLES_CONFIG = [
  {
    role: USER_ROLES.VIEWER,
    requiredPermission: PERMISSIONS.USER_MANAGEMENT.INVITE_USER,
    requiredGate: FEATURE_GATES.VIEWER_ROLE_ASSIGNMENT,
  },
  {
    role: USER_ROLES.EDITOR,
    requiredPermission: PERMISSIONS.USER_MANAGEMENT.GRANT_EDITOR_ROLE,
  },
  {
    role: USER_ROLES.ADMIN,
    requiredPermission: PERMISSIONS.USER_MANAGEMENT.GRANT_ADMIN_ROLE,
  },
  {
    role: USER_ROLES.CAMPAIGN_OWNER,
    requiredPermission: PERMISSIONS.USER_MANAGEMENT.GRANT_CAMPAIGN_OWNER_ROLE,
  },
  {
    role: USER_ROLES.BOARD_OWNER,
    requiredPermission: PERMISSIONS.USER_MANAGEMENT.GRANT_BOARD_OWNER_ROLE,
  },
];

const RolesProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { isViewerRoleAssignmentEnabled } = useContext(FeatureGatesContext);
  const [primaryRoles, setPrimaryRoles] = useState<FleetOps.RoleDefinition[]>(
    [],
  );
  const [additionalRoles, setAdditionalRoles] = useState<
    FleetOps.RoleDefinition[]
  >([]);
  const [grantableRoles, setGrantableRoles] = useState<
    FleetOps.RoleDefinition[]
  >([]);

  const api = usePermissionsApi();
  const { currentPermissions, isLoading } = useCurrentPermissions(api);
  const availableRoles = useAvailableRoles({ api, currentPermissions });
  const isDriverBonusRolesEnabled = useIsDriverBonusRolesEnabled();

  useEffect(() => {
    setPrimaryRoles(availableRoles.filter((r) => PRIMARY_ROLES.includes(r.id)));
    if (isDriverBonusRolesEnabled) {
      setAdditionalRoles(
        availableRoles.filter((r) => ADDITIONAL_ROLES.includes(r.id)),
      );
    } else {
      setAdditionalRoles([]);
    }
  }, [availableRoles, isDriverBonusRolesEnabled]);

  useEffect(() => {
    const newRoles = GRANTABLE_ROLES_CONFIG.map((config) => {
      const hasPermission = currentPermissions.includes(
        config.requiredPermission,
      );
      const hasGate =
        config.requiredGate === undefined
          ? true
          : config.requiredGate === FEATURE_GATES.VIEWER_ROLE_ASSIGNMENT &&
            isViewerRoleAssignmentEnabled;

      if (hasPermission && hasGate) {
        return availableRoles.find((r) => r.id === config.role);
      }
      return undefined;
    }).filter(isDefined);
    setGrantableRoles(newRoles);
  }, [availableRoles, currentPermissions, isViewerRoleAssignmentEnabled]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <RolesContext.Provider
      value={{
        availableRoles,
        additionalRoles,
        grantableRoles,
        currentPermissions,
        primaryRoles,
        isLoading,
      }}
    >
      {children}
    </RolesContext.Provider>
  );
};

export default RolesProvider;
