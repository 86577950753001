const buildCustomerSearchItemLabel = (item: CustomerSearchResult) => {
  if (item.billTo && item.customer) {
    return `${item.customer} (${item.billTo})`;
  } else if (item.billTo) {
    return item.billTo;
  } else {
    return item.customer;
  }
};

export default buildCustomerSearchItemLabel;
