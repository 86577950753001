const displayTime = (minutes, opts = { fullUnits: false }) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  if (hours > 0) {
    if (opts.fullUnits) {
      return `${hours}hrs ${remainingMinutes}mins`;
    }
    return `${hours}h ${remainingMinutes}m`;
  }

  if (opts.fullUnits) {
    return `${remainingMinutes}mins`;
  }
  return `${remainingMinutes}m`;
};

export default displayTime;
