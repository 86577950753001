import metricTypeCheckers from '../../types/metricTypeCheckers';

const getMetricDescription = (metric: Metrics.Metric): string => {
  if (metric.description) {
    return metric.description;
  }

  if (metricTypeCheckers.isNormalMetric(metric)) {
    return `${metric.aggFunc} of ${metric.field}`;
  }

  return '';
};

export default getMetricDescription;
