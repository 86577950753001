import React, { Suspense } from 'react';
import FlexCentered from './components/Common/FlexCentered';
import Loading from './components/Loading';

const FleetOpsSuspense = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => (
  <Suspense
    fallback={
      <FlexCentered style={{ height: '100%' }}>
        <Loading />
      </FlexCentered>
    }
  >
    {children}
  </Suspense>
);

export default FleetOpsSuspense;
