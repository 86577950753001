import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import UsersSettingsProvider from 'contextProviders/UsersSettingsProvider';
import NAVIGATION from 'kingpin/navigation/constants';

import UsersSidebar from './UsersSidebar';
import ExportUsersButton from '../ExportUsersButton';
import Colors2 from '../../../theme/Colors2';
import AccountLevelPortalSwitch from './AccountLevelPortalSwitch';
import FeatureGate, { FEATURE_GATES } from '../../../components/FeatureGate';

const UsersNavOutletDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  width: 100%;
`;

const UserTopBar = styled.div`
  height: ${NAVIGATION.TOP_HEIGHT}px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${Colors2.Border};
  padding: 0px 16px;
  gap: 8px;
`;

const UsersNav = () => {
  return (
    <UsersSettingsProvider>
      <UsersSidebar />
      <UsersNavOutletDiv>
        <UserTopBar>
          <FeatureGate featureName={FEATURE_GATES.PORTALS_ACCOUNT_WIDE_TOGGLE}>
            <AccountLevelPortalSwitch />
          </FeatureGate>
          <ExportUsersButton />
        </UserTopBar>
        <Outlet />
      </UsersNavOutletDiv>
    </UsersSettingsProvider>
  );
};

export default UsersNav;
