import React, { useState } from 'react';
import SlideOutContext from '../contexts/SlideoutContext';

const SlideOutProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [slideoutKpi, setSlideoutKpi] = useState<
    Scorecards.ScorecardKpi | Scorecards.ManualKpiRow
  >();
  const [slideoutPeriod, setSlideoutPeriod] = useState<Period>();
  const [slideoutTrend, setSlideoutTrend] = useState<Goals.TrendResult[]>([]);
  const [slideoutMetric, setSlideoutMetric] = useState<Metrics.Metric>();

  return (
    <SlideOutContext.Provider
      value={{
        isOpen,
        setIsOpen,
        slideoutKpi,
        setSlideoutKpi,
        slideoutPeriod,
        setSlideoutPeriod,
        slideoutTrend,
        setSlideoutTrend,
        slideoutMetric,
        setSlideoutMetric,
      }}
    >
      {children}
    </SlideOutContext.Provider>
  );
};

export default SlideOutProvider;
