import { Link } from 'react-router-dom';
import React from 'react';
import Typography from 'kingpin/atoms/Typography';
import Row from '../../components/Common/Row';
import appRoutes from '../../navigation/appRoutes';
import { ReactComponent as Dot } from './dot.svg';

const AuthLinks = () => (
  <Row centerAlign style={{ justifyContent: 'center', paddingLeft: 12 }}>
    <Link to={appRoutes.wallboardSetup}>
      <Typography.Body type="Body 12">Access Wallboard</Typography.Body>
    </Link>
    <div style={{ marginRight: 4, marginLeft: 4 }}>
      <Dot />
    </div>
    <Link to={appRoutes.forgotPassword}>
      <Typography.Body type="Body 12">I forgot my password</Typography.Body>
    </Link>
  </Row>
);

export default AuthLinks;
