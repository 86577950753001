import React, { useContext, useEffect, useState } from 'react';
import { ApisauceInstance } from 'apisauce';
import Loading from '../components/Loading';
import CloudFunctionClientContext from 'contexts/CloudFunctionClientContext';
import AccountPickerContext from '../contexts/AccountPickerContext';
import buildCloudFunctionClient from '../services/buildCloudFunctionClient';
import VersionContext from '../contexts/VersionContext';

const CloudFunctionClientProvider = ({
  children,
  accountId,
}: {
  children: JSX.Element | JSX.Element[];
  accountId?: string;
}) => {
  const { buildNumber } = useContext(VersionContext);
  const [api, setApi] = useState<ApisauceInstance | undefined>();
  const [usEastApi, setUsEastApi] = useState<ApisauceInstance | undefined>();
  const [apiGateway, setApiGateway] = useState<ApisauceInstance | undefined>();
  const { selectedAccount } = useContext(AccountPickerContext);

  useEffect(() => {
    const currentAccountId = accountId
      ? accountId
      : selectedAccount === undefined
        ? undefined
        : selectedAccount.accountId === 'na'
          ? undefined
          : selectedAccount.accountId;

    setApi(
      buildCloudFunctionClient({
        accountId: currentAccountId,
        buildNumber,
        region: 'europe-west1',
      }),
    );

    setUsEastApi(
      buildCloudFunctionClient({
        accountId: currentAccountId,
        buildNumber,
        region: 'us-east4',
      }),
    );

    setApiGateway(
      buildCloudFunctionClient({
        accountId: currentAccountId,
        buildNumber,
        region: 'us-east4',
        isApiGateway: true,
      }),
    );
  }, [accountId, buildNumber, selectedAccount, selectedAccount.accountId]);

  if (!api || !usEastApi || !apiGateway) {
    return <Loading />;
  }

  return (
    <CloudFunctionClientContext.Provider value={{ api, usEastApi, apiGateway }}>
      {children}
    </CloudFunctionClientContext.Provider>
  );
};

export default CloudFunctionClientProvider;
