import React, { ChangeEvent } from 'react';

import styled from 'styled-components';
import Typography from 'kingpin/atoms/Typography';
import Inputs from 'components/Inputs';
import { GEO_POINT_CELL } from 'components/Grid/constants';

import GeoPointFormatInput from './GeoPointFormatInput';
import CurrencyTypeDropdown from './CurrencyTypeDropdown';
import FieldCellTypeDropdown from '../FieldCellTypeDropdown';
import Form from '../../../../kingpin/forms/Form';
import FormHeader from '../../../../kingpin/forms/FormHeader';
import FormContent from '../../../../kingpin/forms/FormContent';

const FieldsForm = styled(Form)`
  position: fixed;
  right: 0px;
  top: 0px;
  height: 100vh;
  width: 430px;
  box-shadow:
    0 0 0 1px rgba(53, 45, 67, 0.07),
    -1px 0 3px 0 rgba(53, 45, 67, 0.12),
    -1px 0 12px 5px rgba(53, 45, 67, 0.1);
  display: flex;
  flex-direction: column;
  background-color: white;
`;

const Section = styled.div`
  margin-bottom: 16px;
`;

const FieldSlideOut = ({
  fieldDef,
  fieldView,
  baseView,
  field,
  showInGrid,
  onShowInGridChanged,
  displayName,
  onDisplayNameChanged,
  description,
  onDescriptionChanged,
  totalOptions,
  totalsAggFunc,
  cellType,
  positiveDeltaIsGood,
  onPositiveDeltaIsGoodChanged,
  dateFormatOptions,
  dateFormat,
  precision,
  onPrecisionChanged,
  onClose,
  isCommasDisabled,
  onIsCommasDisabledChanged,
  updateView,
}: {
  fieldDef: FleetOps.Field;
  fieldView: FleetOps.BaseViewField;
  baseView: FleetOps.BaseView;
  field: string;
  showInGrid: boolean;
  onShowInGridChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  displayName?: string;
  onDisplayNameChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  description?: string;
  onDescriptionChanged: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  totalOptions: DropdownOption[];
  totalsAggFunc?: AggFunc;
  cellType?: FleetOps.CellType;
  positiveDeltaIsGood: boolean;
  onPositiveDeltaIsGoodChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  dateFormatOptions: DropdownOption[];
  dateFormat?: FleetOps.DateFormat;
  precision?: number;
  onPrecisionChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  onClose: () => void;
  isCommasDisabled: boolean;
  onIsCommasDisabledChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  updateView: (newView: FleetOps.BaseViewField) => void;
}) => (
  <FieldsForm data-testid={`${field}-slide-out`}>
    <FormHeader title={field} onClose={onClose} />
    <FormContent>
      <Section>
        <div style={{ marginBottom: 4 }}>
          <Typography.Body type="Label">Enabled</Typography.Body>
        </div>
        <Inputs.Toggle
          value={showInGrid}
          onChange={(newValue) => {
            // @ts-ignore
            onShowInGridChanged({ target: { checked: newValue } });
          }}
          testId={'slideout-visible'}
        />
      </Section>
      <Section>
        <div style={{ marginBottom: 4 }}>
          <Typography.Body type="Label">Display Name</Typography.Body>
        </div>
        <Inputs.TextInput
          value={displayName || ''}
          onChange={onDisplayNameChanged}
          data-testid={'slideout-name'}
        />
      </Section>
      <Section>
        <div style={{ marginBottom: 4 }}>
          <Typography.Body type="Label">Description</Typography.Body>
        </div>
        <Inputs.TextArea
          value={description}
          placeholder={'Add a description...'}
          onChange={onDescriptionChanged}
          rows={description ? description.split('\n').length + 1 : 2}
          data-testid={'slideout-description'}
        />
      </Section>
      <Section>
        <div style={{ marginBottom: 4 }}>
          <Typography.Body type="Label">Totals Row formula</Typography.Body>
        </div>
        <Inputs.Dropdown
          options={totalOptions}
          selectedLabel={totalsAggFunc ? totalsAggFunc : 'None'}
          testId={'slideout-totals'}
        />
      </Section>
      <Section>
        <div style={{ marginBottom: 4 }}>
          <Typography.Body type="Label">Cell Type</Typography.Body>
        </div>
        <FieldCellTypeDropdown
          field={fieldDef}
          fieldView={fieldView}
          baseView={baseView}
          testId={'slideout-cellType'}
        />
      </Section>
      {cellType && cellType === 'Currency' && (
        <Section>
          <div style={{ marginBottom: 4 }}>
            <Typography.Body type="Label">Currency Type</Typography.Body>
          </div>
          <CurrencyTypeDropdown
            field={fieldDef}
            fieldView={fieldView}
            baseView={baseView}
            testId={'slideout-currencyType'}
          />
        </Section>
      )}
      {fieldDef.type !== 'geo_point' && (
        <Section>
          <div style={{ marginBottom: 4 }}>
            <Typography.Body type="Label">
              Positive Delta is good
            </Typography.Body>
          </div>
          <Inputs.Toggle
            value={positiveDeltaIsGood}
            onChange={(newValue) => {
              onPositiveDeltaIsGoodChanged({
                // @ts-ignore
                target: {
                  checked: newValue,
                },
              });
            }}
          />
        </Section>
      )}
      {cellType &&
        (cellType === 'Currency' ||
          cellType === 'Number' ||
          cellType === 'Percentage') && (
          <>
            {fieldDef.type === 'float' && (
              <Section>
                <div style={{ marginBottom: 4 }}>
                  <Typography.Body type="Label">Precision</Typography.Body>
                </div>
                <Inputs.TextInput
                  type="number"
                  value={
                    precision !== undefined && !isNaN(precision)
                      ? precision.toString(10)
                      : ''
                  }
                  onChange={onPrecisionChanged}
                />
              </Section>
            )}
            <Section>
              <div style={{ marginBottom: 4 }}>
                <Typography.Body type="Label">
                  Use comma (,) separator
                </Typography.Body>
              </div>
              <Inputs.Toggle
                value={!isCommasDisabled}
                onChange={(newValue) => {
                  onIsCommasDisabledChanged({
                    // @ts-ignore
                    target: {
                      checked: newValue,
                    },
                  });
                }}
              />
            </Section>
          </>
        )}
      {cellType && cellType === GEO_POINT_CELL && (
        <Section>
          <Typography.Body type="Label">Lat Long Order</Typography.Body>
          <GeoPointFormatInput fieldView={fieldView} updateView={updateView} />
        </Section>
      )}
      {cellType && cellType === 'Date' && (
        <Section>
          <Typography.Body type="Label">Date Format</Typography.Body>
          <Inputs.Dropdown
            options={dateFormatOptions}
            selectedLabel={dateFormat ? dateFormat : 'None'}
          />
        </Section>
      )}
    </FormContent>
  </FieldsForm>
);

export default FieldSlideOut;
