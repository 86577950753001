import React, { FunctionComponent, SVGProps } from 'react';
import { IconWrapper } from 'kingpin/atoms/Icon';

import { ReactComponent as BlueDot } from './colors/blue.svg';
import { ReactComponent as GreenDot } from './colors/green.svg';
import { ReactComponent as YellowDot } from './colors/yellow.svg';
import { ReactComponent as OrangeDot } from './colors/orange.svg';
import { ReactComponent as RedDot } from './colors/red.svg';
import { ReactComponent as MagentaDot } from './colors/magenta.svg';
import { ReactComponent as NavyDot } from './colors/navy.svg';
import { ReactComponent as GreyDot } from './colors/grey.svg';
import { ReactComponent as LightGreyDot } from './colors/lightGrey.svg';

const getColor = (
  color: PortalColor,
): FunctionComponent<
  SVGProps<SVGSVGElement> & { title?: string | undefined }
> => {
  switch (color) {
    case 'blue':
      return BlueDot;
    case 'green':
      return GreenDot;
    case 'yellow':
      return YellowDot;
    case 'orange':
      return OrangeDot;
    case 'red':
      return RedDot;
    case 'magenta':
      return MagentaDot;
    case 'navy':
      return NavyDot;
    case 'grey':
      return GreyDot;
    case 'lightgrey':
      return LightGreyDot;
    default:
      return LightGreyDot;
  }
};

const ColorDot = ({
  color,
  width,
  height,
}: {
  color: PortalColor;
  width: number;
  height: number;
}) => {
  const Icon = getColor(color);
  return (
    <IconWrapper height={height} width={width}>
      <Icon width={width} height={height} />
    </IconWrapper>
  );
};

export default ColorDot;
