import React, { useContext, useState } from 'react';
import useFilterInput from '../../hooks/useFilterInput';
import useGaugeFormDataTypes from './useGaugeFormDataTypes';
import Input from '../Inputs';
import getFilterValues from '../../api/getFilterValues';
import useDateScope from '../../hooks/useDateScope';
import AccountPickerContext from '../../contexts/AccountPickerContext';
import GqlClientContext from '../../contexts/GqlClientContext';
import useNetworkingEffect from '../../hooks/useNetworkingEffect';

const useGaugeFormEntityOptions = ({
  metricId,
  peerGroup,
  entity,
  setEntity,
}: {
  metricId: string;
  peerGroup: string;
  entity?: string;
  setEntity: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
  const { client } = useContext(GqlClientContext);
  const { selectedAccount } = useContext(AccountPickerContext);
  const dataTypes = useGaugeFormDataTypes(metricId);
  const filterInput = useFilterInput(peerGroup, dataTypes);
  const dateScope = useDateScope({});
  const [entityOptions, setEntityOptions] = useState<DropdownOption[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useNetworkingEffect(() => {
    setIsLoading(true);
    let isActive = true;
    getFilterValues({
      field: peerGroup,
      filterInput,
      dateScope,
      accountId: selectedAccount.accountId,
      client,
    }).then((baseOptions: string[]) => {
      if (!isActive) {
        return;
      }
      setEntityOptions(
        baseOptions.map((o) => ({
          label: o,
          onSelected: () => {
            setEntity(o);
          },
        })),
      );
      setIsLoading(false);
    });
    return () => {
      isActive = false;
    };
  }, [
    client,
    dateScope,
    entity,
    filterInput,
    peerGroup,
    selectedAccount.accountId,
    setEntity,
  ]);

  return { isLoading, entityOptions };
};

const EntityInput = ({
  peerGroup,
  metricId,
  entity,
  setEntity,
}: {
  peerGroup: string;
  metricId: string;
  entity?: string;
  setEntity: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
  const { isLoading, entityOptions: options } = useGaugeFormEntityOptions({
    metricId,
    peerGroup,
    entity,
    setEntity,
  });

  return (
    <Input.Dropdown
      options={options}
      selectedLabel={entity}
      isSearchEnabled
      isDisabled={isLoading}
    />
  );
};

const Gate = ({
  peerGroup,
  metricId,
  entity,
  setEntity,
}: {
  peerGroup?: string;
  metricId?: string;
  entity?: string;
  setEntity: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
  if (!peerGroup || !metricId) {
    return null;
  }

  return (
    <EntityInput
      entity={entity}
      setEntity={setEntity}
      peerGroup={peerGroup}
      metricId={metricId}
    />
  );
};

export default Gate;
