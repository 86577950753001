import React from 'react';
import styled from 'styled-components';

import MetricPickerPopupConstants from '../constants';
import Row from '../../../../Common/Row';
import Colors2 from '../../../../../theme/Colors2';
import CheckmarkIcon from '../images/Checkmark.svg';

const SideBarDiv = styled.div`
  height: calc(${MetricPickerPopupConstants.POPUP_HEIGHT} - 75px);
  border-right: 1px solid #e5e5e5;
  width: ${MetricPickerPopupConstants.SIDE_BAR_WIDTH};
  display: flex;
  flex-direction: column;
  padding-left: ${MetricPickerPopupConstants.PADDING_HORIZONTAL};
  padding-right: ${MetricPickerPopupConstants.PADDING_HORIZONTAL};
  padding-top: ${MetricPickerPopupConstants.PADDING_TOP};
`;

const ListOuterDiv = styled.div`
  flex: 1;
  overflow-y: auto;
  margin-bottom: 16px;
`;

const SideBarContentDiv = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
`;

const ListInnerDiv = styled.div`
  overflow-y: auto;
`;

const ListItem = styled(Row)<{ isSelected: boolean }>`
  background-color: ${(props) => (props.isSelected ? '#F7F7F7' : 'white')};
  height: 40px;
  border-radius: 8px;
  cursor: pointer;
  padding: 8px 10px;

  &:hover {
    background-color: ${Colors2.Grey['9']};
  }
`;

const IconImg = styled.img`
  width: 12px;
  height: 12px;
`;

const CheckMark = () => <IconImg src={CheckmarkIcon} />;

const PopupSideBar = {
  SideBarContentDiv,
  SideBarDiv,
  ListOuterDiv,
  ListInnerDiv,
  ListItem,
  CheckMark,
};

export default PopupSideBar;
