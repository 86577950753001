import * as _ from 'lodash';

const formatFloat = (float, precision = 1) => {
  if (!float) {
    return 0;
  }

  const x = _.round(float, precision);
  if (isNaN(x)) {
    return float;
  } else {
    return x;
  }
};

export default formatFloat;
