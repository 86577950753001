import { ChangeEvent, useCallback, useEffect, useState } from 'react';

const useDatasetsSearchControls = () => {
  const [searchText, setSearchText] = useState<string>('');
  const [sortBy, setSortBy] = useState<'Alphabetical' | 'Last Updated'>(
    'Alphabetical',
  );
  const [sortByOptions, setSortByOptions] = useState<DropdownOption[]>([]);

  const onSearchTextChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const onSortSelected = useCallback(
    (newSortBy: 'Alphabetical' | 'Last Updated') => {
      setSortBy(newSortBy);
    },
    [],
  );

  useEffect(() => {
    setSortByOptions([
      {
        label: 'Alphabetical',
        isSelected: sortBy === 'Alphabetical',
        onSelected: () => {
          onSortSelected('Alphabetical');
        },
      },
      {
        label: 'Last Updated',
        isSelected: sortBy === 'Last Updated',
        onSelected: () => {
          onSortSelected('Last Updated');
        },
      },
    ]);
  }, [onSortSelected, sortBy]);

  return {
    searchText,
    onSearchTextChanged,
    sortBy,
    sortByOptions,
  };
};

export default useDatasetsSearchControls;
