import React, { useEffect, useState } from 'react';
import DriverBonusConfigContext from '../contexts/DriverBonusConfigContext';
import useAllWorkSpaces from '../hooks/workspaces/useAllWorkSpaces';
import { isDriverBonusWorkSpace } from '../isWorkSpace';

const DriverBonusConfigProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { allWorkSpaces } = useAllWorkSpaces();
  const [workspace, setWorkspace] = useState<WorkSpace | undefined>();

  useEffect(() => {
    const ws = allWorkSpaces
      .filter(isDriverBonusWorkSpace)
      .find((ws) => ws.campaignType === 'driverBonus');
    setWorkspace(ws);
  }, [allWorkSpaces]);

  return (
    <DriverBonusConfigContext.Provider value={{ workspace }}>
      {children}
    </DriverBonusConfigContext.Provider>
  );
};

export default DriverBonusConfigProvider;
