import styled, { css } from 'styled-components';

const IsVariableStyles = css`
  color: #dc9a14;
  border: 1px solid #dc9a14;
  background-color: rgba(243, 194, 95, 0.3);
`;

const VariableButton = styled.div<{
  isVariable: boolean;
  isListItem?: boolean;
}>`
  width: ${(props) => (props.isListItem ? '32px' : '68px')};
  margin-left: ${(props) => (props.isListItem ? '0px' : '8px')};
  margin-right: ${(props) => (props.isListItem ? '12px' : '0px')};
  height: 22px;
  background-color: #f2f2f2;
  border: 1px solid rgba(220, 220, 220, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  ${(props) => props.isVariable && IsVariableStyles};

  &:hover {
    opacity: 0.7;
  }
`;

export default VariableButton;
