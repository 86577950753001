import React, { useContext } from 'react';
import AccountContext from '../../contexts/AccountContext';
import Inputs from '../Inputs';
import Row from '../Common/Row';
import Typography from '../../kingpin/atoms/Typography';
import buildWeekStartsOnLabel from './buildWeekStartsOnLabel';

const STARTS = [
  'MON',
  'TUE',
  'WED',
  'THUR',
  'FRI',
  'SAT',
  'SUN',
] as WeekStartsOn[];

const WeekStartsOnOverrideSelector = ({
  weekStartsOnOverride,
  setWeekStartsOnOverride,
  isLabelDisabled,
  isDisabled,
  testId,
}: {
  weekStartsOnOverride?: WeekStartsOn;
  setWeekStartsOnOverride: (
    newStart: WeekStartsOn,
  ) => void | React.Dispatch<React.SetStateAction<WeekStartsOn | undefined>>;
  isLabelDisabled?: boolean;
  isDisabled?: boolean;
  testId?: string;
}) => {
  const { weekStartsOn } = useContext(AccountContext);
  const weekStartToUse = weekStartsOnOverride
    ? weekStartsOnOverride
    : weekStartsOn;

  const options = STARTS.map((ws) => ({
    label: buildWeekStartsOnLabel(ws),
    onSelected: () => {
      setWeekStartsOnOverride(ws);
    },
  }));

  const selectedLabel = buildWeekStartsOnLabel(weekStartToUse);

  return (
    <Inputs.Dropdown
      placeholder={`${
        isLabelDisabled ? '' : 'Reporting Week: '
      }${buildWeekStartsOnLabel(weekStartToUse)}`}
      options={options}
      selectedLabel={selectedLabel}
      unsetWidth
      testId={testId}
      isDisabled={isDisabled}
      renderSelectedLabel={
        selectedLabel
          ? () => (
              <Row centerAlign>
                <div style={{ marginRight: 4, display: 'flex' }}>
                  <Typography.Body type={'Placeholder'}>
                    Reporting Week:
                  </Typography.Body>
                </div>
                <Typography.Body type={'Label'}>
                  {selectedLabel}
                </Typography.Body>
              </Row>
            )
          : undefined
      }
    />
  );
};

export default WeekStartsOnOverrideSelector;
