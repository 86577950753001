import React from 'react';
import { GetContextMenuItemsParams } from 'ag-grid-community';

interface CommitmentQueryContextType {
  customerField?: 'customer' | 'billTo' | undefined;
  setCustomerField: React.Dispatch<
    React.SetStateAction<'customer' | 'billTo' | undefined>
  >;

  customerValue?: string;
  setCustomerValue: React.Dispatch<React.SetStateAction<string | undefined>>;

  originField?: OriginField;
  setOriginField: React.Dispatch<React.SetStateAction<OriginField | undefined>>;

  originValue?: string;
  setOriginValue: React.Dispatch<React.SetStateAction<string | undefined>>;

  destinationField?: DestinationField;
  setDestinationField: React.Dispatch<
    React.SetStateAction<DestinationField | undefined>
  >;

  destinationValue?: string;
  setDestinationValue: React.Dispatch<React.SetStateAction<string | undefined>>;

  commodity?: string;
  setCommodity: React.Dispatch<React.SetStateAction<string | undefined>>;

  isQueryValid: boolean;
  queryFilterInput: FilterInput;

  selectedRateDoc?: RateDoc;
  setSelectedRateDoc: React.Dispatch<React.SetStateAction<RateDoc | undefined>>;

  isPopupOpen: boolean;
  openPopup: () => void;
  closePopup: () => void;

  isSlideOutOpen: boolean;
  openSlideOut: () => void;
  closeSlideOut: () => void;

  selectedCommitmentDoc?: Commitment;
  setSelectedCommitmentDoc: React.Dispatch<
    React.SetStateAction<Commitment | undefined>
  >;

  mode: CommitmentFormPopupMode;
  setMode: React.Dispatch<React.SetStateAction<CommitmentFormPopupMode>>;

  clearState: () => void;
  configForQuery?: Commitment;

  isFlashVisible: boolean;
  setIsFlashVisible: React.Dispatch<React.SetStateAction<boolean>>;

  getContextMenuItems: (
    params: GetContextMenuItemsParams,
  ) => { name: string; action: () => void }[];
}

const CommitmentQueryContext = React.createContext<CommitmentQueryContextType>(
  {} as CommitmentQueryContextType,
);

export default CommitmentQueryContext;
