import React, { useContext } from 'react';
import MetricPopupConstants from './constants';
import styled from 'styled-components';

import Row from '../../../../components/Common/Row';
import Button from 'kingpin/atoms/Button';
import Colors2 from '../../../../theme/Colors2';
import SingleUseMetricPopupContext from '../../../../contexts/SingleUseMetricPopupContext';
import InSituMetricEditorContext from '../../../../contexts/InSituMetricEditorContext';
import MEDIA from '../../../../media';

const BottomBarDiv = styled(Row)`
  padding: ${MetricPopupConstants.PADDING};
  border-top: 1px solid ${Colors2.Grey['8']};

  @media ${MEDIA.QUERIES.SMALL_HEIGHT} {
    padding: ${MetricPopupConstants.PADDING_SMALL};
  }
`;

const BottomBar = ({ close }: { close: () => void }) => {
  const { isInSituEditor } = useContext(InSituMetricEditorContext);
  const { isSingleUsePopup, cancelButtonText } = useContext(
    SingleUseMetricPopupContext,
  );

  return (
    <BottomBarDiv spaceBetween>
      <div>
        <Button
          type="Tertiary"
          size="Small"
          onClick={close}
          label={isSingleUsePopup ? cancelButtonText : 'Cancel'}
          icon={isSingleUsePopup ? 'chevron-left' : undefined}
        />
      </div>
      <Row>
        <div
          id={
            isInSituEditor
              ? MetricPopupConstants.PRIMARY_ACTION_IN_SITU_DIV_ID
              : isSingleUsePopup
                ? MetricPopupConstants.PRIMARY_ACTION_SINGLE_USE_DIV_ID
                : MetricPopupConstants.PRIMARY_ACTION_DIV_ID
          }
        />
      </Row>
    </BottomBarDiv>
  );
};

export default BottomBar;
