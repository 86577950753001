import { useCallback, useContext } from 'react';
import ScorecardContext from 'contexts/ScorecardContext';
import { ScoringBandColors } from 'screens/ScorecardsIndex/constants';

const useKpiColor = () => {
  const { scorecard } = useContext(ScorecardContext);

  const getKpiColor = useCallback(
    ({
      kpi,
      value,
      targetBandsForPeriod,
    }: {
      kpi: Scorecards.ScorecardKpi | Scorecards.ManualKpiRow;
      value: number | undefined;
      targetBandsForPeriod: number[] | undefined;
    }) => {
      if (!targetBandsForPeriod || value === undefined) {
        return 'unset';
      }

      if (kpi.isTargetsDisabled) {
        return 'unset';
      }

      const colors = [...ScoringBandColors[scorecard.scoringBandId]];
      if (kpi.isColorOrderReversed) {
        colors.reverse();
      }
      const colorToUse = colors.find((c, index) => {
        if (index === 0) {
          const t = targetBandsForPeriod[0];
          return value <= t;
        } else if (index === colors.length - 1) {
          const lastT = targetBandsForPeriod[index - 1];
          return value > lastT;
        } else {
          const t = targetBandsForPeriod[index];
          const previousT = targetBandsForPeriod[index - 1];
          return value > previousT && value <= t;
        }
      });

      return colorToUse || 'unset';
    },
    [scorecard.scoringBandId],
  );

  return getKpiColor;
};

export default useKpiColor;
