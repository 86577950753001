const TRUCK_MANUFACTURERS = [
  'Mack',
  'Western Star',
  'DAF',
  'Scania',
  'Navistar',
  'Freightliner',
  'Isuzu Motors Ltd.',
  'Peterbilt',
  'Daimler AG',
  'Kenworth',
  'Hino Motors',
  'Dongfeng Motor Corporation',
  'Ford',
  'Iveco',
  'Renault',
  'Daimler  North America',
  'Foton',
  'Ashok Leyland',
  'Volvo',
  'Eicher Motors',
  'Sinotruk',
  'Tata Motors',
  'Volvo',
  'UD',
];

export default TRUCK_MANUFACTURERS;
