import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import Row from '../../../../components/Common/Row';
import { NabTabLinkMargin, NavTabButton } from '../../../../components/NavTab';
import LocalTimelineProvider from '../../../../contextProviders/TimelineProvider/LocalTimelineProvider';
import Timeline from '../../../../components/Timeline';
import UsageTab from './UsageTab';
import DetailsTab from './DetailsTab';
import Headings from './Headings';
import useMetricUsageReport from '../../../../hooks/useMetricUsageReport';
import useMetricTimelineSources from './useMetricTimelineSources';
import useMetricTimelineStartsOn from '../hooks/useMetricTimelineStartsOn';
import AnalyticsContext from '../../../../contexts/AnalyticsContext';

const TabContent = styled.div`
  height: calc(100vh - 170px);
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
`;

const TabInner = styled.div`
  overflow-y: auto;
  padding: 1px;
  padding-right: 4px;
`;

const Tabs = ({
  metric,
}: {
  metric: Metrics.NormalMetric | Metrics.CompoundMetric;
}) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const usageReport = useMetricUsageReport(metric);
  const [usageLabel, setUsageLabel] = useState<string>('');
  useEffect(() => {
    setUsageLabel(
      usageReport ? `Usage (${usageReport.usageCount.toString(10)})` : 'Usage',
    );
  }, [metric.id, usageReport]);
  const [selectedTab, setSelectedTab] = useState<
    'details' | 'usage' | 'timeline'
  >('details');

  const goToDetails = useCallback(() => {
    setSelectedTab('details');
  }, []);

  const goToUsage = useCallback(() => {
    setSelectedTab('usage');
    trackEvent('Metrics - Usage opened', { metricType: metric.type });
  }, [metric.type, trackEvent]);

  const goToHistory = useCallback(() => {
    setSelectedTab('timeline');
    trackEvent('Metrics - Timeline opened', { metricType: metric.type });
  }, [metric.type, trackEvent]);

  return (
    <div>
      <Row style={{ marginBottom: 16 }}>
        <div style={{ marginRight: NabTabLinkMargin }}>
          <NavTabButton
            onClick={goToDetails}
            isSelected={selectedTab === 'details'}
            title={'Details'}
          />
        </div>
        <div style={{ marginRight: NabTabLinkMargin }}>
          <NavTabButton
            onClick={goToUsage}
            isSelected={selectedTab === 'usage'}
            title={usageLabel}
          />
        </div>
        <NavTabButton
          onClick={goToHistory}
          isSelected={selectedTab === 'timeline'}
          title={'Timeline'}
        />
      </Row>
      <TabContent>
        <TabInner>
          {selectedTab === 'details' && <DetailsTab metric={metric} />}
          {selectedTab === 'usage' && <UsageTab metric={metric} />}
          {selectedTab === 'timeline' && <Timeline isFullHeight />}
        </TabInner>
      </TabContent>
    </div>
  );
};

const MetricSlideOut = ({
  close,
  metric,
  onEditClicked,
}: {
  close: () => void;
  metric: Metrics.NormalMetric | Metrics.CompoundMetric;
  onEditClicked: () => void;
}) => {
  const sources = useMetricTimelineSources(metric);
  const timelineStartsOn = useMetricTimelineStartsOn(metric);

  return (
    <div>
      <Headings metric={metric} close={close} onEditClicked={onEditClicked} />
      <LocalTimelineProvider sources={sources} startsOn={timelineStartsOn}>
        <Tabs metric={metric} />
      </LocalTimelineProvider>
    </div>
  );
};

export default MetricSlideOut;
