const POPUP_HEIGHT = '80vh';
const PADDING_HORIZONTAL = '24px';
const PADDING_TOP = '32px';
const BOTTOM_BAR_HEIGHT = '76px';
const TOP_BAR_HEIGHT = '152px';
const SIDE_BAR_WIDTH = '280px';
const POPUP_WIDTH = '50vw';

const MetricPickerPopupConstants = {
  POPUP_WIDTH,
  POPUP_HEIGHT,
  PADDING_HORIZONTAL,
  PADDING_TOP,
  BOTTOM_BAR_HEIGHT,
  TOP_BAR_HEIGHT,
  SIDE_BAR_WIDTH,
};

export default MetricPickerPopupConstants;
