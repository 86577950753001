import { useContext, useEffect, useState } from 'react';
import AccountContext from '../../../contexts/AccountContext';
import useMetric from '../../../hooks/useMetric';

// Resolve breaking changes introduced by within GQL in (FWA-5443)
// https://gitlab.com/fleetops-web/graphql/-/blob/eecb9cd75fabb40cf9119fad438b4a163b6513fa/src/graphql/aggregations/transformResponse.js#L141
// https://gitlab.com/fleetops-web/graphql/-/blob/eecb9cd75fabb40cf9119fad438b4a163b6513fa/src/intervals.js
const INTERVALS = {
  AUTO: 'auto',
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  QUARTER: 'quarter',
  YEAR: 'year',
};
const DATE_MATH_TO_INTERVAL_MAP = {
  '1d': INTERVALS.DAY,
  '7d': INTERVALS.WEEK,
  '1M': INTERVALS.MONTH,
  '3M': INTERVALS.QUARTER,
  '1Y': INTERVALS.YEAR,
} as { [key: string]: FleetOps.NonAutoInterval };

const useRowData = ({
  response,
  colDefs,
  gadget,
}: {
  response: SingleMetricDateMatrixResponse | undefined;
  colDefs: MatrixColDef[];
  gadget: VisualisationDefinitions.SingleMetricDateMatrix;
}) => {
  const { unitsLocale } = useContext(AccountContext);
  const metric = useMetric(gadget.metricId);
  const [rowData, setRowData] = useState<MatrixRow[]>([]);
  const [totalsRowData, setTotalsRowData] = useState<MatrixRow[]>([]);

  useEffect(() => {
    if (response && colDefs.length > 0 && !!metric) {
      const newRowData = response.terms.map((term) => {
        const row = {} as MatrixRow;
        row[gadget.groupByField] = term.group;
        term.histogram.forEach((histogramPoint) => {
          row[histogramPoint.date] = {
            ...histogramPoint,
            formatting: metric.formatting,
            interval: DATE_MATH_TO_INTERVAL_MAP[histogramPoint.interval],
            type: 'value',
            unitsLocale,
            metricId: gadget.metricId,
          };
          row[`${histogramPoint.date}-delta`] = {
            ...histogramPoint,
            formatting: metric.formatting,
            interval: DATE_MATH_TO_INTERVAL_MAP[histogramPoint.interval],
            type: 'delta',
            unitsLocale,
            metricId: gadget.metricId,
          };
          row[`${histogramPoint.date}-percentDelta`] = {
            ...histogramPoint,
            formatting: metric.formatting,
            interval: DATE_MATH_TO_INTERVAL_MAP[histogramPoint.interval],
            type: 'percentDelta',
            unitsLocale,
            metricId: gadget.metricId,
          };
        });

        return row;
      });

      setRowData(newRowData);
    }
  }, [
    colDefs.length,
    gadget.groupByField,
    gadget.metricId,
    metric,
    response,
    unitsLocale,
  ]);

  useEffect(() => {
    if (response && colDefs.length > 0 && !!metric) {
      const row = {} as MatrixRow;
      row[gadget.groupByField] = `Totals (${response.totalTerms})`;

      response.histogram.forEach((histogramPoint) => {
        row[histogramPoint.date] = {
          ...histogramPoint,
          formatting: metric.formatting,
          interval: DATE_MATH_TO_INTERVAL_MAP[histogramPoint.interval],
          type: 'value',
          unitsLocale,
          metricId: gadget.metricId,
        };
        row[`${histogramPoint.date}-delta`] = {
          ...histogramPoint,
          formatting: metric.formatting,
          interval: DATE_MATH_TO_INTERVAL_MAP[histogramPoint.interval],
          type: 'delta',
          unitsLocale,
          metricId: gadget.metricId,
        };
        row[`${histogramPoint.date}-percentDelta`] = {
          ...histogramPoint,
          formatting: metric.formatting,
          interval: DATE_MATH_TO_INTERVAL_MAP[histogramPoint.interval],
          type: 'percentDelta',
          unitsLocale,
          metricId: gadget.metricId,
        };
      });

      setTotalsRowData([row]);
    }
  }, [
    colDefs.length,
    gadget.groupByField,
    gadget.metricId,
    metric,
    response,
    unitsLocale,
  ]);

  return {
    rowData,
    totalsRowData,
  };
};

export default useRowData;
