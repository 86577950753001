import React, { useCallback, useContext, useEffect, useState } from 'react';

import Row from 'components/Common/Row';
import Typography from 'kingpin/atoms/Typography';
import Inputs from 'components/Inputs';
import InlineDialog from 'components/InlineDialog';
import usePopup from 'hooks/usePopup';

import ConfigureMicrosoftSsoContext from '../contexts/ConfigureMicrosoftSsoContext';
import NoGroupsFoundMessage from './NoGroupsFoundMessage';
import GroupOptionsList from './GroupOptionsList';
import AnalyticsContext from '../../../../../contexts/AnalyticsContext';

const useFilteredGroupOptions = () => {
  const { isOpen, open, close } = usePopup();
  const { availableGroups, setSelectedGroups, selectedGroups } = useContext(
    ConfigureMicrosoftSsoContext,
  );
  const { trackEvent } = useContext(AnalyticsContext);
  const [searchText, setSearchText] = useState<string>('');
  const [options, setOptions] = useState<DropdownOption[]>([]);
  const onSearchTextChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      open();

      setSearchText(event.target.value);
    },
    [open],
  );
  const onSearchInputFocus = useCallback(() => {
    open();
  }, [open]);

  useEffect(() => {
    setOptions(
      availableGroups
        .filter(
          (g) =>
            g.displayName.toLowerCase().includes(searchText.toLowerCase()) ||
            g.id.toLowerCase().includes(searchText.toLowerCase()),
        )
        .filter((g) => !selectedGroups.some((sg) => sg.id === g.id))
        .map((g) => {
          const isSelected = selectedGroups.some((sg) => sg.id === g.id);
          return {
            value: g.id,
            label: g.displayName,
            isSelected,
            onSelected: () => {
              setSelectedGroups((currentGroups) => {
                if (isSelected) {
                  trackEvent('SSO Configurations - Microsoft - Group Removed');
                  return currentGroups.filter((cg) => cg.id !== g.id);
                } else {
                  trackEvent('SSO Configurations - Microsoft - Group Added');
                  return [...currentGroups, g];
                }
              });
              setSearchText('');
              close();
            },
          };
        }),
    );
  }, [
    availableGroups,
    close,
    searchText,
    selectedGroups,
    setSelectedGroups,
    trackEvent,
  ]);

  return {
    searchText,
    onSearchTextChanged,
    options,
    isOpen,
    close,
    onSearchInputFocus,
  };
};

const GroupOptionsDialog = ({
  options,
  searchText,
}: {
  options: DropdownOption[];
  searchText: string;
}) => {
  return (
    <div style={{ padding: '12px 16px' }}>
      {options.length === 0 && <NoGroupsFoundMessage searchText={searchText} />}
      {options.length > 0 && (
        <GroupOptionsList options={options} hasSearchText={searchText !== ''} />
      )}
    </div>
  );
};

const AddGroupInput = () => {
  const {
    searchText,
    onSearchTextChanged,
    options,
    isOpen,
    close,
    onSearchInputFocus,
  } = useFilteredGroupOptions();

  return (
    <div>
      <Row spaceBetween centerAlign>
        <div style={{ width: '100%' }}>
          <Typography.Header type="H5">
            Search For Your Group ID/Name
          </Typography.Header>
          <Row centerAlign>
            <InlineDialog
              placement="bottom-start"
              content={
                <GroupOptionsDialog options={options} searchText={searchText} />
              }
              isOpen={isOpen}
              onClose={close}
            >
              <Inputs.TextInput
                value={searchText}
                onChange={onSearchTextChanged}
                onFocus={onSearchInputFocus}
                inputSize="Small"
              />
            </InlineDialog>
          </Row>
        </div>
      </Row>
    </div>
  );
};

export default AddGroupInput;
