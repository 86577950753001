import React, { useContext } from 'react';
import styled from 'styled-components';

import CanvasContext from '../../contexts/CanvasContext';
import WallboardContext from '../../contexts/WallboardContext';
import ErrorBoundary from '../Common/ErrorBoundary';
import Colors2 from '../../theme/Colors2';

const Wrapper = styled.div<{ isEditing: boolean }>`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  .showOnMouseOver {
    opacity: 0;
    width: 0;
  }

  &:hover {
    .showOnMouseOver {
      opacity: 1;
      width: unset;
    }
  }
`;

const Header = styled.div`
  position: relative;
  height: 40px;
  padding: 0px 8px;
  border-bottom: 1px solid ${Colors2.Border};
`;
const Main = styled.div<{ isContentWithoutPadding?: boolean }>`
  flex: 1;
  flex-shrink: 1;
  overflow-y: auto;
`;

const Footer = styled.div<{ isAbsolute?: boolean }>`
  padding: 8px;
  width: 100%;
  ${(props) =>
    props.isAbsolute &&
    `
      position: absolute;
      bottom: 0px;
      z-index: 1000001;
    `}
`;

const CanvasCard = ({
  topBar,
  content,
  bottomBar,
  isBottomBarAbsolute,
  isContentWithoutPadding,
}: {
  topBar?: JSX.Element | JSX.Element[];
  content: JSX.Element | JSX.Element[];
  bottomBar?: JSX.Element | JSX.Element[];
  isBottomBarAbsolute?: boolean;
  isContentWithoutPadding?: boolean;
}) => {
  const { isWallboard } = useContext(WallboardContext);
  const { isEditing } = useContext(CanvasContext);

  return (
    <Wrapper isEditing={isEditing}>
      {topBar && <Header>{topBar}</Header>}
      <Main
        className="hiding-scrollbar"
        isContentWithoutPadding={isContentWithoutPadding || isWallboard}
      >
        <ErrorBoundary isRetryable>{content}</ErrorBoundary>
      </Main>
      {!isWallboard && !!bottomBar && (
        <Footer isAbsolute={isBottomBarAbsolute}>{bottomBar}</Footer>
      )}
    </Wrapper>
  );
};

export default CanvasCard;
