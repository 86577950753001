import { useCallback, useEffect, useState } from 'react';

const useInitialWizardState = (config: FleetOps.PerformanceDatasetConfig) => {
  const buildWizardState = useCallback(() => {
    return {
      id: config.id,
      currentStep: 'metrics' as 'metrics',
      isEditing: true,
      isDatasetStepComplete: true,
      isMetricsStepComplete: true,
      isFieldsStepComplete: true,
      config: {
        datasetStep: {
          entity: config.entity,
          entityDataTypes: config.entityDataTypes,
          entityWindowLength: config.entityWindowLength,
          entityWindowDateField: config.entityWindowDateField,
          name: config.name,
          startDate: config.startDate,
          startOfWeek: config.startOfWeek,
          interval: config.interval,
          intervalLength: config.intervalLength,
        },
        metricsStep: {
          metrics: config.metrics,
        },
        fieldsStep: {
          fields: config.fields,
        },
      },
      isValid: true,
    };
  }, [
    config.entity,
    config.entityDataTypes,
    config.entityWindowDateField,
    config.entityWindowLength,
    config.fields,
    config.id,
    config.interval,
    config.intervalLength,
    config.metrics,
    config.name,
    config.startDate,
    config.startOfWeek,
  ]);

  const [initialState, setInitialState] =
    useState<PerformanceConfiguration.WizardState>(() => buildWizardState());

  useEffect(() => {
    setInitialState(buildWizardState());
  }, [buildWizardState]);

  return initialState;
};

export default useInitialWizardState;
