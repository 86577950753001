import React, { useContext } from 'react';

import DragHandle from './DragHandle';
import GroupNameInput from './GroupNameInput';
import TargetValueInput from './TargetValueInput';
import AddTargetButton from './AddTargetButton';
import GroupContextMenu from './GroupContextMenu';
import GroupLabel from './GroupLabel';
import Row from '../../../Common/Row';
import TargetFormContext from '../../contexts/TargetFormContext';
import Typography from 'kingpin/atoms/Typography';
import useEntityField from '../../hooks/useEntityField';
import useAvgValueForGroup from '../../hooks/useAvgValueForGroup';

const FlatGroupInput = ({ group }: { group: Targets.Wizard.TargetGroup }) => {
  const { isTargetedByGroup, dataType } = useContext(TargetFormContext);
  const entityField = useEntityField(dataType);
  const { avgValueTooltip } = useAvgValueForGroup({ group });

  return (
    <>
      <Row
        centerAlign
        spaceBetween
        style={{
          marginBottom: 8,
        }}
      >
        <Row centerAlign style={{ overflow: 'hidden' }}>
          <DragHandle />
          <GroupLabel group={group} isFallbackGroup={false} />
          {isTargetedByGroup === false && (
            <div style={{ marginLeft: 16, marginRight: 16 }}>
              <Typography.Body type="Body 12">{`For all ${entityField}(s)`}</Typography.Body>
            </div>
          )}
        </Row>
        <Row style={{ justifyContent: 'flex-end' }}>
          {isTargetedByGroup && <GroupContextMenu group={group} />}
        </Row>
      </Row>
      <Row style={{ marginBottom: 8, marginLeft: 16 }}>
        <GroupNameInput group={group} />
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            overflow: 'hidden',
            marginBottom: 8,
          }}
        >
          <TargetValueInput
            target={group.targets[0]}
            group={group}
            avgValueTooltip={avgValueTooltip}
          />
        </div>
      </Row>

      <div style={{ width: 140 }}>
        <AddTargetButton group={group} />
      </div>
    </>
  );
};

export default FlatGroupInput;
