import React, { useCallback, useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import BaseViewsContext from '../../../../contexts/BaseViewsContext';
import isDefined from '../../../../isDefined';
import useGetFieldLabel from 'hooks/useGetFieldLabel';

const useFieldOptions = ({
  allowedDataTypes,
  selectedDataType,
  selectedFields,
  fieldRedList,
  fieldTypeRedList,
}: {
  allowedDataTypes: string[];
  selectedDataType?: string;
  selectedFields: {
    dataType: string;
    fields: string[];
  }[];
  fieldRedList?: string[];
  fieldTypeRedList?: FleetOps.FieldType[];
}) => {
  const { getFieldLabel } = useGetFieldLabel();
  const { baseViews } = useContext(BaseViewsContext);
  const [fieldOptions, setFieldOptions] = useState<
    {
      dataType: string;
      field: string;
      fieldLabel: string;
      isSelected: boolean;
      isDisabled: boolean;
    }[]
  >([]);
  const [searchText, setSearchText] = useState<string>('');
  const onSearchTextChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
    },
    [],
  );

  const getIsSelected = useCallback(
    ({ dataType, field }: { dataType: string; field: string }) => {
      const dataTypeFields = selectedFields.find(
        (f) => f.dataType === dataType,
      );
      if (!dataTypeFields) {
        return false;
      }

      return dataTypeFields.fields.includes(field);
    },
    [selectedFields],
  );

  const getIsDisabled = useCallback(
    ({ dataType, field }: { dataType: string; field: string }) => {
      if (getIsSelected({ dataType, field })) {
        return false;
      }

      let isDisabled = false;
      selectedFields.forEach((datasetFields) => {
        datasetFields.fields.forEach((f) => {
          if (f === field) {
            isDisabled = true;
          }
        });
      });
      return isDisabled;
    },
    [getIsSelected, selectedFields],
  );

  // setFieldOptions
  useEffect(() => {
    const newOptions = allowedDataTypes.reduce(
      (options, dataType) => {
        if (!!selectedDataType && selectedDataType !== dataType) {
          return options;
        }

        const bv = baseViews[dataType];
        if (!bv) {
          return options;
        }

        const additionalOptions = Object.values(bv.fields)
          .filter(isDefined)
          .filter((f) => f.isVisible)
          .filter((f) => {
            if (!fieldRedList) {
              return true;
            }

            return !fieldRedList.includes(f.field);
          })
          .filter((f) => {
            if (!fieldTypeRedList) {
              return true;
            }

            return !fieldTypeRedList.includes(f.type);
          })
          .map((field) => ({
            dataType,
            field: field.field,
            fieldLabel: getFieldLabel({ field: field.field, dataType }),
            isSelected: getIsSelected({
              field: field.field,
              dataType,
            }),
            isDisabled: getIsDisabled({ dataType, field: field.field }),
          }));
        return [...options, ...additionalOptions];
      },
      [] as {
        dataType: string;
        field: string;
        fieldLabel: string;
        isSelected: boolean;
        isDisabled: boolean;
      }[],
    );

    const filteredOptions =
      searchText === ''
        ? newOptions
        : newOptions.filter((o) =>
            o.fieldLabel.toLowerCase().includes(searchText.toLowerCase()),
          );

    const sortedOptions = _.sortBy(filteredOptions, 'fieldLabel');
    setFieldOptions(sortedOptions);
  }, [
    allowedDataTypes,
    baseViews,
    fieldRedList,
    fieldTypeRedList,
    getFieldLabel,
    getIsDisabled,
    getIsSelected,
    searchText,
    selectedDataType,
  ]);

  return {
    searchText,
    onSearchTextChanged,
    fieldOptions,
  };
};

export default useFieldOptions;
