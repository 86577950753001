import React from 'react';

import GridCellErrorBoundary from '../../GridCellErrorBoundary';
import Cell from '../../V5Gadget/Matrix/Cell';
import { IPaceDiffCellRendererParams } from './types';
import useMetric from '../../../hooks/useMetric';
import useValueFormatters from '../../../hooks/useValueFormatters';
import Tooltip from '../../Tooltip';
import DiffTooltip, { ArrowIcon } from './DiffTooltip';
import Row from '../../Common/Row';
import getDiffColors from './getDiffColors';
import getDiffPercentage from './getDiffPercentage';

const PaceDiffCell = (props: IPaceDiffCellRendererParams) => {
  const metric = useMetric(props.metricId);
  const { formatMetric } = useValueFormatters();
  const actual = props.getActual(props.data);
  const average = props.getAverage(props.data);
  const diff = props.getDiff(props.data);

  if (actual === null || diff === null || average === null || !metric) {
    return (
      <Cell>
        <span>-</span>
      </Cell>
    );
  }

  const { positiveDeltaIsGood } = metric.formatting;
  const { textColor, iconBackgroundColor } = getDiffColors({
    diff,
    positiveDeltaIsGood,
  });
  const diffPercentage = getDiffPercentage({
    actual,
    average,
  });

  const formattedDiff = formatMetric({ metricId: metric.id, value: diff });
  const icon = diff < 0 ? 'arrow-down' : 'arrow-up';

  return (
    <Cell>
      <Tooltip
        isAltTooltip
        isFlexAnchor
        content={
          <DiffTooltip
            iconBackgroundColor={iconBackgroundColor}
            textColor={textColor}
            icon={icon}
            actualDateRange={props.actualDateRangeText}
            actualDayOfWeekHeading={props.actualDayOfWeekHeading}
            avgDateRange={props.avgDateRangeText}
            avgDayOfWeekHeading={props.avgDayOfWeekHeading}
            excludedDateRangeLabels={props.excludedDateRangeLabels}
            formatted={{
              actual: formatMetric({ value: actual, metricId: metric.id }),
              avg: formatMetric({ value: average, metricId: metric.id }),
              diff: formattedDiff,
              diffPercentage: diffPercentage,
            }}
          />
        }
      >
        <Row style={{ gap: 4 }} centerAlign>
          <ArrowIcon
            iconBackgroundColor={iconBackgroundColor}
            icon={icon}
            textColor={textColor}
            size={12}
          />
          <span style={{ color: textColor }}>{formattedDiff}</span>
        </Row>
      </Tooltip>
    </Cell>
  );
};

const Gate = (params: IPaceDiffCellRendererParams) => (
  <GridCellErrorBoundary
    params={params}
    childComponent={<PaceDiffCell {...params} />}
  />
);

export default Gate;
