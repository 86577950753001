import React from 'react';

interface BonusPeriodsContextType {
  bonusPeriods: BonusPeriod[];
  bonusPeriodOptions: { label: string; value: string }[];
  selectedBonusPeriod?: BonusPeriod;
  setSelectedBonusPeriod: (bp: BonusPeriod | undefined) => void;
}

const BonusPeriodsContext = React.createContext<BonusPeriodsContextType>({
  bonusPeriods: [],
  bonusPeriodOptions: [],
  setSelectedBonusPeriod: () => {},
});

export default BonusPeriodsContext;
