import React from 'react';

interface VersionContextType {
  buildNumber?: number;
}

const VersionContext = React.createContext<VersionContextType>({
  buildNumber: undefined,
});

export default VersionContext;
