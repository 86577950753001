import React from 'react';

interface VariableFiltersContextType {
  variableFilters: VariableDrillDownType[];
  setVariableFilters: React.Dispatch<
    React.SetStateAction<VariableDrillDownType[]>
  >;
}

const VariableFiltersContext = React.createContext<VariableFiltersContextType>({
  variableFilters: [],
  setVariableFilters: () => {},
});

export default VariableFiltersContext;
