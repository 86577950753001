import { ApiResponse, ApisauceInstance } from 'apisauce';

const postUpload = ({
  formData,
  headers,
  dataType,
  provider,
  api,
}: {
  formData: FormData;
  headers: { [key: string]: string };
  dataType: string;
  provider: string;
  api: ApisauceInstance;
}): Promise<ApiResponse<unknown, unknown>> =>
  api.post(`/uploads/${provider}/${dataType}`, formData, {
    headers,
  });

export default postUpload;
