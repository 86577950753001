import isComparisonSeriesItem from '../../isComparisonSeriesItem';

const getCurrentDateTimeFor = (
  x: number,
  series: (HistogramSeriesItem | ComparisonSeriesItem | BarSeriesItem)[],
) => {
  const thisPeriodSeries = series[0];
  if (isComparisonSeriesItem(thisPeriodSeries)) {
    const matchingDataItem = thisPeriodSeries.data.find((d) => d.x === x);
    if (!matchingDataItem) {
      return x;
    }
    return matchingDataItem.datetime;
  } else {
    return x;
  }
};

export default getCurrentDateTimeFor;
