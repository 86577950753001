import baseOptions from './baseOptions';
import abbreviateNumber from '../../../utils/abbreviateNumber';

const horizontalBar = ({
  title,
  showLegend,
  openMenu,
  groupField,
  showDataLabels,
  fontSize,
  isHistogram,
  formatMetric,
}) => ({
  ...baseOptions(formatMetric),
  chart: {
    ...baseOptions(formatMetric).chart,
    styledMode: false,
    type: 'bar',
    marginTop: 24,
  },
  title: {
    ...baseOptions(formatMetric).title,
    text: title,
  },
  yAxis: [
    {
      ...baseOptions(formatMetric).yAxis[0],
      title: {
        enabled: false,
      },
    },
  ],
  plotOptions: {
    bar: {
      borderRadius: '5%',
      dataLabels: {
        enabled: !!showDataLabels,
        color: 'black',
        formatter: function () {
          const { y, series } = this;
          const { step, metricId } = series.userOptions;
          const index = this.point.index;
          const isHidden = isHistogram && !!step && index % step !== 0;
          const abbreviated = abbreviateNumber(y);
          const formattedY = formatMetric({
            metricId,
            value: abbreviated ? abbreviated.abbreviated : y,
          });
          if (isHidden) {
            return '';
          }
          return `<b>${formattedY}</b>`;
        },
        style: {
          fontSize,
        },
      },
      point: {
        events: {
          click: function (e) {
            const selectedIndex = e.point.x;
            const terms = e.point.series.data;
            const series = e.point.series.chart.series;
            if (openMenu) {
              const { metricIds } = this.series.userOptions;
              const selectedTerm = terms.find((t, index) => {
                return index === selectedIndex;
              });
              openMenu(selectedTerm.name, metricIds, groupField);
            } else {
              // @ts-ignore
              const { onDrillDown } = this.series.userOptions;
              if (!onDrillDown) {
                alert('Coming soon...');
                return;
              }
              if (series.length > 1) {
                // multi series
                series.forEach((s) => {
                  s.data.forEach((d, dataIndex) => {
                    if (dataIndex === selectedIndex) {
                      onDrillDown(d.category, e.ctrlKey || e.metaKey);
                    }
                  });
                });
              } else {
                // single series
                terms.forEach((t, index) => {
                  if (index === selectedIndex) {
                    onDrillDown(t.category, e.ctrlKey || e.metaKey);
                  }
                });
              }
            }
          },
        },
      },
    },
  },
  legend: {
    ...baseOptions(formatMetric).legend,
    enabled: showLegend,
  },
});

export default horizontalBar;
