import React, { useContext } from 'react';
import DetailsSlideOutSummary from './DetailsSlideOutSummary';
import OrderDetailsContext from '../../contexts/OrderDetailsContext';

const DetailsSlideOutSummaryContainer = () => {
  const { orderSummary } = useContext(OrderDetailsContext);

  if (!orderSummary) {
    return null;
  }

  return <DetailsSlideOutSummary orderSummary={orderSummary} />;
};

export default DetailsSlideOutSummaryContainer;
