import React, { useContext } from 'react';
import styled from 'styled-components';

import Row from '../Common/Row';
import OpenTasksLink from '../Goals/OpenTasksLink';
import OverdueTasksLink from '../Goals/OverDueTasksLink';
import Typography from 'kingpin/atoms/Typography';
import { Link } from 'react-router-dom';
import UserBadgesRow from './AssignedUsers';
import Kpis from './Kpis';
import Card from '../Common/Card';
import PermissionGates from '../PermissionGates';
import PERMISSIONS from '../../permissionDefinitions';
import MetricsInfoButton from '../MetricsInfoButton';
import ShowOnMouseOverUnlessTour from '../ShowOnMouseOverUnlessTour';
import ScorecardMoreMenu from '../../screens/ScorecardShow/ScorecardMoreMenu';
import ScorecardContext from 'contexts/ScorecardContext';

const InnerSection = styled.div`
  height: 100%;
  width: 100%;
  padding: 0px 16px;
`;

const FullWidth = styled.div`
  height: 100%;
  width: 100%;
`;

const ScorecardWrapperCard = styled(Card)<{
  noMarginRight?: boolean;
  isFullWidth?: boolean;
  maxHeight?: number;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;

  .showOnMouseOver {
    visibility: hidden;
  }

  &:hover {
    .showOnMouseOver {
      visibility: visible;
    }
  }
`;

export const ScorecardWrapper = ({
  children,
  noMarginRight,
  isFullWidth,
  maxHeight,
}: {
  children: JSX.Element | JSX.Element[];
  noMarginRight?: boolean;
  isFullWidth?: boolean;
  maxHeight?: number;
}) => (
  <ScorecardWrapperCard
    noMarginRight={noMarginRight}
    isFullWidth={isFullWidth}
    maxHeight={maxHeight}
    hideBorders={isFullWidth}
  >
    {children}
  </ScorecardWrapperCard>
);

const KpiVerticalScrollArea = ({ children }: { children: JSX.Element }) => {
  const { kpisListDivParentScrollerRef } = useContext(ScorecardContext);

  return (
    <div
      ref={kpisListDivParentScrollerRef}
      style={{
        flex: 1,
        maxHeight: 'calc(100% - 88px)',
        overflowY: 'scroll',
        overflowX: 'hidden',
      }}
    >
      {children}
    </div>
  );
};

const Scorecard = ({
  link,
  scorecard,
}: {
  link: string;
  scorecard: Scorecards.Scorecard;
}) => (
  <>
    <div style={{ height: 48 }}>
      <InnerSection>
        <Row style={{ height: 48 }} centerAlign spaceBetween>
          <Link to={link}>
            <Typography.Body type="Link">{scorecard.title}</Typography.Body>
          </Link>
          <Row centerAlign>
            <PermissionGates.Has
              requiredPermission={PERMISSIONS.SCORECARDS.CRUD_SCORECARD}
            >
              <div style={{ marginRight: 8 }}>
                <ScorecardMoreMenu scorecard={scorecard} isEditOnly />
              </div>
            </PermissionGates.Has>
            <ShowOnMouseOverUnlessTour>
              <MetricsInfoButton visualisation={scorecard} />
            </ShowOnMouseOverUnlessTour>
          </Row>
        </Row>
      </InnerSection>
    </div>
    <KpiVerticalScrollArea>
      <FullWidth>
        <Kpis />
      </FullWidth>
    </KpiVerticalScrollArea>
    <div>
      <InnerSection>
        <Row spaceBetween>
          <Row centerAlign style={{ height: 40 }}>
            <OpenTasksLink link={link} />
            <OverdueTasksLink link={link} />
          </Row>
          <UserBadgesRow userIds={scorecard.assigneeId} />
        </Row>
      </InnerSection>
    </div>
  </>
);

export default Scorecard;
