import firebase from 'firebase/compat/app';

const deleteSavedFilter = async (
  filter: SavedFilter,
  accountRef: firebase.firestore.DocumentReference,
) => {
  return accountRef.collection('savedFilters').doc(filter.id).delete();
};

export default deleteSavedFilter;
