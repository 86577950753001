import React, { useCallback, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import DragHandleContext from './DragHandleContext';
import DragAndDropListContext from './DragAndDropListContext';

interface ItemWithKey {
  key: string;
}

interface ItemWithId {
  id: string;
}

interface ItemWithField {
  field: string;
}

type DraggableItem = ItemWithKey | ItemWithId | ItemWithField | string;

const getKey = (item: DraggableItem): string => {
  if (typeof item === 'string') {
    return item;
  }

  if ('id' in item) {
    return item.id;
  }
  if ('key' in item) {
    return item.key;
  }
  if ('field' in item) {
    return item.field;
  }

  const e = new Error();
  e.name = 'DragAndDropList: key not found';
  throw e;
};

export const getReorderedItems = <Type extends DraggableItem>({
  items,
  dropResult,
}: {
  items: Type[];
  dropResult: DropResult;
}) => {
  const { draggableId, destination } = dropResult;
  if (!destination) {
    return items;
  }

  const droppedItem = items.find((i) => getKey(i) === draggableId);
  if (!droppedItem) {
    return items;
  }
  const itemsWithoutDropped = items.filter((i) => getKey(i) !== draggableId);

  return [
    ...itemsWithoutDropped.slice(0, destination.index),
    droppedItem,
    ...itemsWithoutDropped.slice(destination.index, itemsWithoutDropped.length),
  ];
};

const DragAndDropList = <Type extends DraggableItem>({
  items,
  onOrderChanged,
  droppableId,
  renderItem,
}: {
  items: Type[];
  onOrderChanged: (newOrder: Type[]) => void;
  droppableId: string;
  renderItem: (item: Type) => JSX.Element;
}) => {
  const [isDragging, setIsDragging] = useState<boolean>(false);
  // const renderDraggable = useInlineDialogFix();

  const onDragEnd = useCallback(
    (dr: DropResult) => {
      const newOrder = getReorderedItems({ items, dropResult: dr });
      onOrderChanged(newOrder);
      setIsDragging(false);
    },
    [items, onOrderChanged],
  );

  return (
    <DragAndDropListContext.Provider value={{ isDragging, setIsDragging }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={droppableId}>
          {(provided) => (
            <div ref={provided.innerRef}>
              {items.map((i, index) => (
                <Draggable
                  key={getKey(i)}
                  draggableId={getKey(i)}
                  index={index}
                >
                  {(draggableProvider) => (
                    <div
                      ref={draggableProvider.innerRef}
                      {...draggableProvider.draggableProps}
                    >
                      <DragHandleContext.Provider
                        value={{
                          dragHandleProps: draggableProvider.dragHandleProps
                            ? draggableProvider.dragHandleProps
                            : undefined,
                          draggableProps: draggableProvider.draggableProps
                            ? draggableProvider.draggableProps
                            : undefined,
                        }}
                      >
                        {renderItem(i)}
                      </DragHandleContext.Provider>
                      {draggableProvider.placeholder}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </DragAndDropListContext.Provider>
  );
};

export default DragAndDropList;
