import React, { useCallback, useContext } from 'react';
import Badge from 'components/Badge';
import Row from 'components/Common/Row';
import Typography from 'kingpin/atoms/Typography';
import DatasetFiltersContext from 'screens/DataManager/DatasetFilters/context/DatasetFiltersContext';
import Icon from 'kingpin/atoms/Icon';
import ReportDrillDownsContext from 'contexts/ReportDrillDownsContext';
import filterPlateTypeCheckers from 'types/filterPlateTypeCheckers';

const BadgeItem = ({ drillDown }: { drillDown: DatasetFilterPlate }) => {
  const { drillDowns, setDrillDowns } = useContext(ReportDrillDownsContext);
  const { datasetFilters } = useContext(DatasetFiltersContext);
  const datasetFilter = datasetFilters.find(
    (df) => df.id === drillDown.datasetFilterId,
  );

  const onRemove = useCallback(() => {
    setDrillDowns(drillDowns.filter((d) => d.id !== drillDown.id));
  }, [drillDown.id, drillDowns, setDrillDowns]);

  return (
    <Badge
      text={datasetFilter?.name || ''}
      badgeType="Default"
      iconBefore={
        <div style={{ display: 'flex' }} onClick={onRemove}>
          <Icon icon="cross" />
        </div>
      }
    />
  );
};

const DatasetFilterBadges = ({ drillDowns }: { drillDowns: FilterPlate[] }) => {
  const { datasetFilters } = useContext(DatasetFiltersContext);

  const defaultFilterIds = datasetFilters
    .filter((df) => df.isDefault === true)
    .map((df) => df.id);

  const defaultDrillDowns = drillDowns
    .filter(filterPlateTypeCheckers.isDataset)
    .filter((d) => defaultFilterIds.includes(d.datasetFilterId));

  if (defaultDrillDowns.length === 0) {
    return null;
  }

  return (
    <Row centerAlign style={{ flexWrap: 'wrap', gap: 8, marginTop: '4px' }}>
      <div style={{ display: 'flex' }}>
        <Typography.Body type="Label">
          Default Filter(s) applied:
        </Typography.Body>
      </div>
      {defaultDrillDowns.map((d) => (
        <BadgeItem key={d.id} drillDown={d} />
      ))}
    </Row>
  );
};

export default DatasetFilterBadges;
