import React from 'react';

import Row from '../Common/Row';
import ShowOnMouseOverUnlessTour from 'components/ShowOnMouseOverUnlessTour';
import DateInput from '../DateInput';

const DashboardGadgetBottomBar = ({
  onDatePickerOpen,
  onDatePickerClose,
  isBonusPeriodMode,
}: {
  isNarrow: boolean;
  onDatePickerOpen: () => void;
  onDatePickerClose: () => void;
  isForcedVisible: boolean;
  timeAgo?: string;
  isBonusPeriodMode?: boolean;
}) => (
  <div>
    <ShowOnMouseOverUnlessTour isForcedVisible={true}>
      <Row spaceBetween centerAlign>
        <ShowOnMouseOverUnlessTour isForcedVisible={true}>
          <div
            onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              event.stopPropagation();
            }}
          >
            <DateInput
              isDashboardCard
              isBonusPeriodMode={isBonusPeriodMode}
              onDatePickerOpen={onDatePickerOpen}
              onDatePickerClose={onDatePickerClose}
            />
          </div>
        </ShowOnMouseOverUnlessTour>
      </Row>
    </ShowOnMouseOverUnlessTour>
  </div>
);

export default DashboardGadgetBottomBar;
