import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface BaseArgs {
  value?: string;
  onChange:
    | React.Dispatch<React.SetStateAction<string | undefined>>
    | ((date: string) => void);
  [key: string]: any;
}

const DateTimePicker = ({ value, onChange }: BaseArgs) => {
  return (
    <DatePicker
      selected={value ? new Date(value) : undefined}
      onChange={(d) => {
        if (Array.isArray(d) || !d) {
          return;
        }

        const asIso = d.toISOString();
        onChange(asIso);
      }}
    />
  );
};

export default DateTimePicker;
