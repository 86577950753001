import React from 'react';

interface GoalsContextType {
  loadingGoals: boolean;
  goals: GeneralGoal[];
  goalMetrics: { label: string; id: string }[];
  updateGoal: (newGoal: GeneralGoal) => Promise<void>;
  deleteGoal: (goalId: string) => Promise<void>;
}

const GoalsContext = React.createContext<GoalsContextType>({
  goals: [],
  goalMetrics: [],
  loadingGoals: true,
  updateGoal: async () => {},
  deleteGoal: async () => {},
});

export default GoalsContext;
