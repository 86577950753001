import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import useMetricMatrix from '../../../hooks/useMetricMatrix';
import getGroupByField from '../../../hooks/useMetricMatrix/getGroupByField';
import useChartDrillDowns from '../../../hooks/useChartDrillDowns';
import { ChartContent } from '../../Chart';
import {
  buildCategories,
  buildCategoriesAlt,
  buildStackedSeries,
  buildStackedSeriesAlt,
} from '../stackedFormatters';
import stackedArea from '../highchartOptions/stackedAreaOptions';
import formatDateLabel from '../formatDateLabel';
import toAutoInterval from '../toAutoInterval';
import useValueFormatters from '../../../hooks/useValueFormatters';
import ChartColorsContext from '../../../contexts/ChartColorsContext';

const StackedAreaContent = ({
  chartDefinition,
  chartRef,
}: {
  chartDefinition: V5ChartDefinition;
  chartRef: React.MutableRefObject<any>;
}) => {
  const { isLoading, rowData } = useMetricMatrix({
    chartDef: chartDefinition,
    skip2DRowDataFormatting: true,
  });
  const { getChartColor, getChartColorIndex } = useContext(ChartColorsContext);
  const [series, setSeries] = useState<StackedSeries[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [autoInterval, setAutoInterval] = useState<AutoInterval | undefined>();
  const { formatMetric } = useValueFormatters();
  const groupKey = getGroupByField(chartDefinition);
  if (groupKey === undefined) {
    throw new Error(
      `Group key not found for chartDefId: ${chartDefinition.id}`,
    );
  }
  useEffect(() => {
    if (!rowData) {
      return;
    }
    if (chartDefinition.trendByCalendarInterval !== 'auto') {
      return;
    }

    try {
      // @ts-ignore
      const aI = Object.keys(rowData[0])['interval'] as string;
      const newAI = toAutoInterval(aI);
      setAutoInterval((currentAi) =>
        _.isEqual(currentAi, newAI) ? currentAi : newAI,
      );
    } catch (ex) {
      return undefined;
    }
  }, [chartDefinition.trendByCalendarInterval, rowData]);
  const dateKey = (() => {
    if (
      chartDefinition.trendByCalendarInterval &&
      chartDefinition.trendByCalendarInterval !== 'auto'
    ) {
      return chartDefinition.trendByCalendarInterval;
    }
    return 'date';
  })();

  const { onDrillDown } = useChartDrillDowns(
    dateKey,
    chartRef,
    'stackedArea',
    chartDefinition,
  );

  const altPivot = (() => {
    if (chartDefinition.stackedAreaOptions) {
      return chartDefinition.stackedAreaOptions.altPivot;
    }

    return false;
  })();

  const xAxis = {
    categories,
  };
  const interval = (() => {
    if (
      chartDefinition.trendByCalendarInterval &&
      chartDefinition.trendByCalendarInterval !== 'auto'
    ) {
      return chartDefinition.trendByCalendarInterval;
    }
    return undefined;
  })();

  const options = stackedArea({
    title: ' ',
    formatMetric,
    series: series.map((s) => ({ ...s, onDrillDown, interval })),
    xAxis: {
      ...xAxis,
      labels: {
        // @ts-ignore
        formatter: function () {
          if (
            chartDefinition.trendByCalendarInterval &&
            chartDefinition.trendByCalendarInterval !== 'auto'
          ) {
            // @ts-ignore
            return formatDateLabel(
              // @ts-ignore
              this.value,
              chartDefinition.trendByCalendarInterval,
            );
          }
          // @ts-ignore
          return this.value;
        },
      },
    },
  });
  useEffect(() => {
    if (!isLoading) {
      if (altPivot) {
        setSeries(
          buildStackedSeriesAlt({
            rowData,
            groupKey,
            chartDef: chartDefinition,
            autoInterval,
            getChartColor,
            getChartColorIndex,
          }),
        );
        setCategories(buildCategoriesAlt(rowData, groupKey));
      } else {
        const { prettyDates: newCategories, termLookup } = buildCategories(
          rowData,
          groupKey,
          chartDefinition,
          autoInterval,
        );
        setSeries(
          buildStackedSeries({
            rowData,
            groupKey,
            newCategories,
            newTermLookup: termLookup,
            getChartColor,
            getChartColorIndex,
          }),
        );
        setCategories(newCategories);
      }
    }
  }, [
    groupKey,
    rowData,
    isLoading,
    altPivot,
    chartDefinition,
    autoInterval,
    getChartColor,
    getChartColorIndex,
  ]);

  return (
    <ChartContent
      chartRef={chartRef}
      options={options}
      key={chartDefinition.id}
    />
  );
};

export default StackedAreaContent;
