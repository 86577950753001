import React from 'react';

interface WallboardsContextType {
  wallBoards: Wallboard[];
  isLoading: boolean;
}

const WallboardsContext = React.createContext<WallboardsContextType>({
  wallBoards: [],
  isLoading: false,
});

export default WallboardsContext;
