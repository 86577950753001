import React from 'react';

interface ChartColorsContextType {
  getChartColor: (term: string) => string;
  getChartColorIndex: (term: string) => number | undefined;
}

const ChartColorsContext = React.createContext<ChartColorsContextType>({
  getChartColor: () => {
    const e = new Error();
    e.name = 'getChartColor is not defined';
    throw e;
  },
  getChartColorIndex: () => {
    const e = new Error();
    e.name = 'getChartColorIndex is not defined';
    throw e;
  },
});

export default ChartColorsContext;
