import React from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import Badge from 'components/Badge';
import Row from 'components/Common/Row';
import UserIcon from 'components/Common/UserIcon';
import Form from 'kingpin/forms/Form';
import FormHeader from 'kingpin/forms/FormHeader';
import FormContent from 'kingpin/forms/FormContent';
import Typography from 'kingpin/atoms/Typography';
import Button from 'kingpin/atoms/Button';

import PortalAccessAlert from './PortalAccessAlert';

const RemoveUserAccessConfirmation = ({
  isOpen,
  close,
  user,
  onRemoveConfirmed,
  isLoading,
  contentName,
  type,
  typeId,
}: {
  isOpen: boolean;
  close: () => void;
  user: UserManagement.PendingUser | UserManagement.SignedUpUser;
  onRemoveConfirmed: () => Promise<void>;
  isLoading: boolean;
  contentName: string;
  type: 'dashboard' | 'report' | 'scorecard' | 'workSpace';
  typeId: string;
}) => {
  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={close} shouldScrollInViewport={false} autoFocus={false}>
          <Form>
            <FormHeader
              title={`Remove '${contentName}' From`}
              onClose={isLoading ? undefined : close}
              titleExtra={
                <div style={{ marginLeft: 8 }}>
                  <Badge
                    badgeType={'Default'}
                    text={user.displayName}
                    iconBefore={
                      <div style={{ marginRight: 8, display: 'flex' }}>
                        <UserIcon {...user} noMargin isSmall />
                      </div>
                    }
                  />
                </div>
              }
            />
            <FormContent>
              <Typography.Body type={'Body 13'} color={'#333333'}>
                {`Are you sure you want to remove this dashboard from "${user.displayName}'s" shared space?`}
              </Typography.Body>
              <PortalAccessAlert
                type={type}
                typeId={typeId}
                user={user}
                contentName={contentName}
              />
            </FormContent>
            <Row spaceBetween centerAlign>
              <Button
                size={'Small'}
                type={'Primary'}
                label="Cancel"
                onClick={close}
              />
              <Button
                size={'Small'}
                type={'Danger Secondary'}
                label="Remove Access"
                onClick={onRemoveConfirmed}
                isLoading={isLoading}
                isDisabled={isLoading}
                testId={`confirm-revoke`}
              />
            </Row>
          </Form>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default RemoveUserAccessConfirmation;
