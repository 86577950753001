import React, { ChangeEvent } from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import Button from 'kingpin/atoms/Button';

import Row from '../../Common/Row';
import Typography from 'kingpin/atoms/Typography';
import Search from '../../Search';

import { Item, List } from 'components/Goals/AddGoalButton';
import Icon from '../../../kingpin/atoms/Icon';
import Form from '../../../kingpin/forms/Form';
import FormHeader from '../../../kingpin/forms/FormHeader';
import FormContent from '../../../kingpin/forms/FormContent';

const AddGoalForm = ({
  isOpen,
  close,
  searchText,
  selectedGoalId,
  onSearchTextChanged,
  filteredGoals,
  onGoalClicked,
  onAddClicked,
}: {
  isOpen: boolean;
  close: () => void;
  searchText: string;
  selectedGoalId?: string;
  onSearchTextChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  filteredGoals: GeneralGoal[];
  onGoalClicked: (Goal: GeneralGoal) => void;
  onAddClicked: () => void;
}) => (
  <ModalTransition>
    {isOpen && (
      <Modal onClose={close} shouldScrollInViewport={false} autoFocus={false}>
        <Form>
          <FormHeader title={'Add Goal'} onClose={close} />
          <FormContent>
            <div style={{ marginBottom: 12 }}>
              <Search
                searchText={searchText}
                onSearchTextChanged={onSearchTextChanged}
                isShort
              />
            </div>
            <List className="hiding-scrollbar">
              {filteredGoals.map((goal) => (
                <Item key={goal.id} onClick={() => onGoalClicked(goal)}>
                  <Typography.Body type="Link">{goal.title}</Typography.Body>
                  {goal.id === selectedGoalId && <Icon icon="checkmark" />}
                </Item>
              ))}
            </List>
          </FormContent>

          <Row spaceBetween centerAlign>
            <Button onClick={close} label="Cancel" type="Ghost" size="Small" />
            <Button
              onClick={onAddClicked}
              isDisabled={selectedGoalId === undefined}
              label="Add"
              type="Primary"
              size="Small"
            />
          </Row>
        </Form>
      </Modal>
    )}
  </ModalTransition>
);

export default AddGoalForm;
