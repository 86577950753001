import { DateTime } from 'luxon';
import firebase from 'firebase/compat/app';
import { FAVOURITES_ORDER_DOC_ID } from '../constants';

const setFavouritesOrder = async ({
  order,
  userId,
  accountRef,
}: {
  order: string[];
  userId: string;
  accountRef: firebase.firestore.DocumentReference;
}) => {
  return accountRef
    .collection('users')
    .doc(userId)
    .collection('favourites')
    .doc(FAVOURITES_ORDER_DOC_ID)
    .set({
      order,
      updatedAt: DateTime.utc().toISO(),
    });
};

export default setFavouritesOrder;
