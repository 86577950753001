import React, { useContext, useEffect, useState } from 'react';
import TargetForm from './TargetForm';
import TargetFormContext from '../contexts/TargetFormContext';
import useFormState from '../hooks/useFormState';
import useDropHandlers from '../hooks/useDropHandlers';
import useIsDuplicateRuleAvailable from '../hooks/useIsDuplicateRuleAvailable';
import GlowingDivContext from '../contexts/GlowingDivContext';
import Colors2 from '../../../theme/Colors2';
import useManageHasUnsavedChanges from '../hooks/useManageHasUnsavedChanges';

const ProvideUnsavedChangesManager = () => {
  useManageHasUnsavedChanges();
  return null;
};

const TargetFormContainer = ({
  dataType,
  targetField,
  target,
  isCopyingFromTarget,
}: {
  dataType: string;
  targetField: string;
  target?: Targets.Wizard.DataTypeTarget;
  isCopyingFromTarget: boolean;
}) => {
  const { startGlow } = useContext(GlowingDivContext);
  const [nextGroupColorIndex, setNextGroupColorIndex] = useState(
    target
      ? (target.groups.length - 1) % Object.values(Colors2.AvatarColors).length
      : 1,
  );
  const {
    isEditing,
    effectiveDate,
    setEffectiveDate,
    groupField,
    setGroupField,
    isBelowTargetDesirable,
    setIsBelowTargetDesirable,
    groups,
    setGroups,
    outputs,
    setOutputs,
    isValid,
    onSaveClickedConfirmed,
    isSaving,
    isTargetedByGroup,
    isShowingValidationFeedback,
    setIsShowingValidationFeedback,
    isTrackingFullPeriod,
    setIsTrackingFullPeriod,
    isGridMode,
  } = useFormState({ dataType, target, isCopyingFromTarget, targetField });
  const { groupNameValues, isDuplicateRuleAvailable } =
    useIsDuplicateRuleAvailable({
      groups,
      groupField,
      dataType,
    });
  const onDragEnd = useDropHandlers(setGroups);

  useEffect(() => {
    if (!isEditing) {
      const timeout = setTimeout(startGlow, 100);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isEditing, startGlow]);

  return (
    <TargetFormContext.Provider
      value={{
        editingTarget: target,
        dataType,
        targetField,
        effectiveDate,
        groupField,
        groups,
        outputs,
        setOutputs,
        isGridMode,
        onDragEnd,
        setGroups,
        isTargetedByGroup,
        isValid,
        isSaving,
        onSaveClickedConfirmed,
        isCopyingFromTarget,
        isDuplicateRuleAvailable,
        groupNameValues,
        isEditing,
        nextGroupColorIndex,
        setNextGroupColorIndex,
        isShowingValidationFeedback,
        setIsShowingValidationFeedback,
        isBelowTargetDesirable,
        setIsBelowTargetDesirable,
        isTrackingFullPeriod,
        setIsTrackingFullPeriod,
      }}
    >
      <TargetForm
        dataType={dataType}
        effectiveDate={effectiveDate}
        setEffectiveDate={setEffectiveDate}
        groupField={groupField}
        setGroupField={setGroupField}
      />
      <ProvideUnsavedChangesManager />
    </TargetFormContext.Provider>
  );
};

export default TargetFormContainer;
