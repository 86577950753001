import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import DragHandle from './DragHandle';
import TargetValueInput from './TargetValueInput';
import TargetFormContext from '../../contexts/TargetFormContext';
import Typography from 'kingpin/atoms/Typography';
import Row from '../../../Common/Row';
import CloseButton from '../../../CloseButton';
import Inputs from '../../../Inputs';
import Colors2 from '../../../../theme/Colors2';
import Tooltip from '../../../Tooltip';
import useHasUnsavedChanges from '../../hooks/useHasUnsavedChanges';
import GlowingDiv from 'components/TargetManager/GlowingDiv';
import GroupsScrollingContext from '../../contexts/GroupsScrollingContext';
import useTargetFormCallbacks from '../../hooks/useTargetFormCallbacks';

const TargetBlockDiv = styled(motion.div)`
  border: 1px solid ${Colors2.Grey['6']};
  border-radius: 6px;
  background-color: #f4f4f4;

  padding: 16px 56px 16px 69px;

  display: flex;
  flex-direction: row;

  position: relative;
`;

const variants = {
  targetOpened: {
    height: 'unset',
    opacity: 1,
  },
  targetEntering: {
    height: 0,
    opacity: 0,
  },
};

const ClosePosition = styled.div`
  position: absolute;
  top: 6px;
  right: 4px;
`;

const FIELD_TYPE_GREEN_LIST = ['text', 'boolean'] as FleetOps.FieldType[];
const FIELD_GREEN_LIST = ['workingDay', 'businessDay'];
const FIELD_RED_LIST = ['reasonNote', 'id', 'reasonCode'];

const TARGET_FILTER_EVENTS = {
  onFilterAdded: 'Targets - Nested Condition Added',
  onAddFilterClicked: 'Targets - Add Nested Condition Clicked',
} as FilterBuilder.TrackingEvents;

const TargetInput = ({
  group,
  target,
}: {
  group: Targets.Wizard.TargetGroup;
  target: Targets.Wizard.Target;
}) => {
  const hasUnsavedChanges = useHasUnsavedChanges();
  const { determineIfIsScrolling } = useContext(GroupsScrollingContext);
  const [filters, setFilters] = useState<FilterBuilder.Client.FieldFilter[]>(
    () => target.rules,
  );
  const { setGroups, dataType, groupField, isShowingValidationFeedback } =
    useContext(TargetFormContext);
  const { updateTarget } = useTargetFormCallbacks();

  const updateFilters = useCallback(
    (newFilters: FilterBuilder.Client.FieldFilter[]) => {
      setFilters(newFilters);
      const newTarget = {
        ...target,
        rules: newFilters,
      };
      updateTarget({ target: newTarget, group });
    },
    [group, target, updateTarget],
  );

  const fieldFilterPredicate = useCallback(
    (field: FleetOps.Field) => {
      if (groupField === field.field) {
        return false;
      }

      if (FIELD_GREEN_LIST.includes(field.field)) {
        return true;
      }

      if (FIELD_RED_LIST.includes(field.field)) {
        return false;
      }

      if (FIELD_TYPE_GREEN_LIST.includes(field.type)) {
        return true;
      }

      return false;
    },
    [groupField],
  );

  const onRemoveClicked = useCallback(() => {
    setGroups((currentGroups) => {
      return currentGroups.map((g) => {
        if (g.key !== group.key) {
          return g;
        }

        if (!g.targets) {
          const error = new Error();
          error.name = 'Targets not defined';
          throw error;
        }

        const targetMatch = g.targets.find((t) => t.key === target.key);
        if (!targetMatch) {
          const error = new Error();
          error.name = 'Target not found';
          throw error;
        }

        const newTargets = g.targets.filter((t) => {
          return t.key !== target.key;
        });

        return {
          ...g,
          targets: newTargets,
        };
      });
    });
  }, [group.key, setGroups, target.key]);

  useEffect(() => {
    if (hasUnsavedChanges) {
      const elem = document.getElementById(group.key);
      if (!elem || !elem.scrollIntoView) {
        return;
      }

      elem.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ marginBottom: 8 }}>
      <GlowingDiv glowOnMount={hasUnsavedChanges}>
        <TargetBlockDiv
          data-testid="target-input"
          animate="targetOpened"
          variants={variants}
          initial={hasUnsavedChanges ? 'targetEntering' : 'targetOpened'}
          onAnimationComplete={determineIfIsScrolling}
        >
          <ClosePosition>
            <Tooltip content="Delete Condition">
              <CloseButton close={onRemoveClicked} testId="remove-target" />
            </Tooltip>
          </ClosePosition>
          <Row centerAlign style={{ height: 32 }}>
            <div>
              <DragHandle />
            </div>
            <Typography.Body type="Body 12">{'If'}</Typography.Body>
          </Row>

          <div
            style={{
              marginLeft: 16,
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              overflow: 'hidden',
            }}
          >
            <Row style={{ minHeight: 32, marginBottom: 8 }} centerAlign>
              <Inputs.FilterBuilder
                dataset={dataType}
                filters={filters}
                updateFilters={updateFilters}
                fieldFilterPredicate={fieldFilterPredicate}
                trackingEvents={TARGET_FILTER_EVENTS}
                isInvalid={isShowingValidationFeedback && filters.length === 0}
              />
            </Row>
            <TargetValueInput target={target} group={group} />
          </div>
        </TargetBlockDiv>
      </GlowingDiv>
    </div>
  );
};

export default TargetInput;
