import React, { useContext } from 'react';

import PerformanceBoardSettingsContext from '../../../contexts/PerformanceBoardSettingsContext';
import Button from 'kingpin/atoms/Button';
import DragAndDropList from 'components/DragAndDropList';
import Row from 'components/Common/Row';
import BoardSettingsHeader from '../../BoardSettingsHeader/BoardSettingsHeader';
import QuickFiltersListItem from './QuickFiltersListItem';

const QuickFilters = ({
  orderedFilters,
  deleteFilter,
  editFilter,
  updateFilters,
}: {
  orderedFilters: SavedFilter[];
  deleteFilter: (f: SavedFilter) => void;
  editFilter: (f: SavedFilter) => void;
  updateFilters: ({
    mode,
    filter,
    newOrder,
  }: {
    mode: 'set' | 'delete' | 'reorder';
    filter?: SavedFilter | undefined;
    newOrder?: string[] | undefined;
  }) => void;
}) => {
  const { setNavState } = useContext(PerformanceBoardSettingsContext);

  return (
    <>
      <BoardSettingsHeader
        title="Quick Filters"
        description="Add quick filters that can be toggled on/off"
      />
      <Row style={{ marginBottom: '16px' }}>
        <Button
          type="Tertiary"
          size="Small"
          onClick={() => setNavState({ mode: 'Quick Filters Config' })}
          icon="add"
          label="New Quick Filter"
          testId={'add-filter'}
        />
      </Row>
      <DragAndDropList
        items={orderedFilters.map((f) => f.id || '')}
        onOrderChanged={(newOrder) =>
          updateFilters({ mode: 'reorder', newOrder })
        }
        droppableId="fieldId"
        renderItem={(filterId) => (
          <QuickFiltersListItem
            filter={orderedFilters.find((f) => f.id === filterId)}
            deleteFilter={deleteFilter}
            editFilter={editFilter}
          />
        )}
      />
    </>
  );
};

export default QuickFilters;
