import React, { ChangeEvent } from 'react';
import SearchResults from './SearchResults';
import TextInput from '../../kingpin/atoms/TextInput';

const OmniSearch = ({
  searchText,
  onSearchTextChanged,
  isLoading,
  searchFields,
  searchResults,
  onSearchItemClicked,
  selectedSearchResults,
  isOpen,
  close,
  autoFocus,
  onSearchFocus,
  onSearchBlur,
  searchPlaceholder,
}: {
  searchText: string;
  onSearchTextChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  isLoading: boolean;
  searchFields: string[];
  onSearchItemClicked: (field: string, value: string) => void;
  searchResults?: MultiFieldSearchResults;
  selectedSearchResults: MultiFieldSearchResults;
  isOpen: boolean;
  close: () => void;
  autoFocus: boolean;
  onSearchFocus?: () => void;
  onSearchBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  searchPlaceholder?: string;
}) => (
  <div style={{ width: '100%', fontSize: 11 }}>
    <TextInput
      value={searchText}
      onChange={onSearchTextChanged}
      icon="search"
      inputSize="Small"
      autoFocus={autoFocus}
      isLoading={isLoading}
      placeholder={searchPlaceholder}
      onBlur={onSearchBlur}
      onFocus={onSearchFocus}
    />

    {isOpen && searchResults && (
      <SearchResults
        searchResults={searchResults}
        searchFields={searchFields}
        onSearchItemClicked={onSearchItemClicked}
        selectedSearchResults={selectedSearchResults}
        close={close}
      />
    )}
  </div>
);

export default OmniSearch;
