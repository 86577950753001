import React from 'react';
import firebase from 'firebase/compat/app';

interface AccountPickerContextType {
  isLoading: boolean;
  accounts: FleetOps.UserAccountAccess[];
  selectedAccountId: string;
  selectedAccount: FleetOps.UserAccountAccess;
  setSelectedAccount: React.Dispatch<
    React.SetStateAction<FleetOps.UserAccountAccess | undefined>
  >;
  accountRef: firebase.firestore.DocumentReference;
}

const AccountPickerContext = React.createContext<AccountPickerContextType>({
  isLoading: true,
  accounts: [],
  selectedAccountId: 'na',
  selectedAccount: {
    accountId: 'na',
    accountName: 'na',
    isDemoAccount: false,
    isTemplateAccount: false,
    roles: [],
    permissions: [],
  },
  setSelectedAccount: () => {},
  // @ts-ignore
  accountRef: window.defaultAccountRef,
});

export default AccountPickerContext;
