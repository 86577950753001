import React from 'react';

interface GlobalTasksContextType {
  tasks: Tasks.Task[];
  isLoading: boolean;
}

const GlobalTasksContext = React.createContext<GlobalTasksContextType>({
  tasks: [],
  isLoading: true,
} as GlobalTasksContextType);

export default GlobalTasksContext;
