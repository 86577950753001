import { useEffect } from 'react';
import { hideIntercomButton, showIntercomButton } from '../openIntercom';

const useHideIntercom = () => {
  useEffect(() => {
    hideIntercomButton();
    return () => {
      showIntercomButton();
    };
  }, []);
};

export default useHideIntercom;
