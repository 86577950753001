import firebase from 'firebase/compat/app';

const deleteTask = (
  task: Tasks.Task,
  accountRef: firebase.firestore.DocumentReference,
) => {
  return accountRef.collection('tasks').doc(task.id).delete();
};

export default deleteTask;
