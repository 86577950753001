import { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import ChartDefinitionsContext from '../../contexts/ChartDefinitionsContext';
import useDataTypesFromSeriesAndMetricListItems, {
  useDataTypesFromMetricIds,
} from '../../hooks/useDataTypesFromSeriesAndMetricListItems';
import isV5ChartDef from '../../types/visTypeCheckers/isV5ChartDef';
import isSingleMetricDateMatrix from '../../types/visTypeCheckers/isSingleMetricDateMatrix';
import isDefined from '../../isDefined';
import DashboardGadgetsContext from '../../contexts/DashboardGadgetsContext';
import canvasContentTypeCheckers from '../../types/cardTypeCheckers';
import isPaceMatrix from '../../types/visTypeCheckers/isPaceMatrix';

export const useDataTypesOnReportCanvas = (
  canvas: Canvas | undefined,
): string[] => {
  const [dataTypes, setDataTypes] = useState<string[]>([]);
  const { definitions } = useContext(ChartDefinitionsContext);
  const { allGadgets } = useContext(DashboardGadgetsContext);
  const [metricIds, setMetricIds] = useState<string[]>([]);
  const [usedDefinitions, setUsedDefinitions] = useState<
    VisualisationDefinition[]
  >([]);
  const [series, setSeries] = useState<V5ChartDefinitionSeries[]>([]);
  const fromSeries = useDataTypesFromSeriesAndMetricListItems(series);
  const fromMetricIds = useDataTypesFromMetricIds(metricIds);

  useEffect(() => {
    if (!canvas) {
      setUsedDefinitions([]);
      return;
    }

    const usedDefinitions = canvas.cards
      .map((c) => {
        if (canvasContentTypeCheckers.isDashboardGadget(c)) {
          const g = allGadgets.find(
            (g) => g.id === c.content.dashboardGadgetId,
          );
          if (g) {
            return g.chartDef;
          }
          return undefined;
        } else if (canvasContentTypeCheckers.isChartDefinition(c)) {
          return definitions.find((d) => d.id === c.content.chartDefinitionId);
        }

        return undefined;
      })
      .filter(isDefined);
    setUsedDefinitions(usedDefinitions);
  }, [allGadgets, canvas, definitions]);

  useEffect(() => {
    const newSeries = usedDefinitions.reduce((acc, chartDef) => {
      if (chartDef && isV5ChartDef(chartDef)) {
        return [...acc, ...chartDef.series];
      } else {
        return acc;
      }
    }, [] as V5ChartDefinitionSeries[]);
    setSeries(newSeries);
  }, [usedDefinitions]);

  useEffect(() => {
    const metricIds = usedDefinitions
      .filter((d) => isSingleMetricDateMatrix(d) || isPaceMatrix(d))
      .map((d) => d.metricId);

    setMetricIds(metricIds);
  }, [definitions, usedDefinitions]);

  useEffect(() => {
    const dataTypeFromGrids = usedDefinitions.reduce((acc, chartDef) => {
      if (
        chartDef &&
        isV5ChartDef(chartDef) &&
        chartDef.gadgetType === 'simpleGrid' &&
        chartDef.simpleGridConfig
      ) {
        return [...acc, chartDef.simpleGridConfig.dataType];
      }
      return acc;
    }, [] as string[]);

    setDataTypes(
      _.uniq([...fromSeries, ...dataTypeFromGrids, ...fromMetricIds]),
    );
  }, [fromMetricIds, fromSeries, usedDefinitions]);

  return dataTypes;
};

export default useDataTypesOnReportCanvas;
