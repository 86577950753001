import { useContext } from 'react';
import CardContext from '../contexts/CardContext';
import isV5ChartDef from '../types/visTypeCheckers/isV5ChartDef';

const useIsGridLinksDisabled = () => {
  const { chartDefinition } = useContext(CardContext);

  return (
    chartDefinition &&
    isV5ChartDef(chartDefinition) &&
    chartDefinition.simpleGridConfig &&
    chartDefinition.simpleGridConfig.disableLinks
  );
};

export default useIsGridLinksDisabled;
