import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import buildCalculateScoresQuery from './buildCalculateScoresQuery';

const calculateGeneralGoal = async (
  goalInput: Scorecards.ScorecardInput,
  client: ApolloClient<NormalizedCacheObject>,
): Promise<Goals.MetricResult[]> => {
  const query = buildCalculateScoresQuery(goalInput);
  const response = await client.query(query);
  return response.data.calculateScores;
};

export default calculateGeneralGoal;
