import React, { useCallback, useContext, useEffect, useState } from 'react';
import useAllowedMetrics from './useAllowedMetrics';
import useAllowedDatasets from './useAllowedDatasets';
import useGetDatasetLabel from './useGetDatasetLabel';
import useGetMetricDatasets from './useGetMetricDatasets';
import metricTypeCheckers from '../../../../../types/metricTypeCheckers';
import AnalyticsContext from '../../../../../contexts/AnalyticsContext';

const useFilteredMetrics = (constraints: MetricPickerOptionConstraints) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const [showAllConstraint] = useState<MetricPickerOptionConstraints>({
    ...constraints,
    datasetMustBeSharedWithMetricIds: undefined,
    datasetMustIncludeField: undefined,
  });
  const [isShowAllEnabled, setIsShowAlLEnabled] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [selectedDataset, setSelectedDataset] = useState<
    FleetOps.DatasetDefinition | undefined
  >();
  const allowedMetrics = useAllowedMetrics(
    isShowAllEnabled ? showAllConstraint : constraints,
  );
  const allowedDatasets = useAllowedDatasets(constraints);
  const allowedDatasetsWhenShowingAll = useAllowedDatasets(showAllConstraint);
  const getDatasetLabel = useGetDatasetLabel();
  const getMetricDatasets = useGetMetricDatasets();
  const [filteredMetrics, setFilteredMetrics] = useState<Metrics.Metric[]>([]);
  const [filteredCoreMetrics, setFilteredCoreMetrics] = useState<
    Metrics.Metric[]
  >([]);
  const [filteredOtherMetrics, setFilteredOtherMetrics] = useState<
    Metrics.Metric[]
  >([]);
  const [datasetOptions, setDatasetOptions] = useState<DropdownOption[]>([]);
  const [otherDatasetOptions, setOtherDatasetOptions] = useState<
    DropdownOption[]
  >([]);

  const onSearchTextChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
    },
    [],
  );

  const onShowAllDatasetsClicked = useCallback(() => {
    setIsShowAlLEnabled(true);
  }, []);

  useEffect(() => {
    if (allowedDatasets.length === 1) {
      setSelectedDataset((ds) => {
        if ((ds && ds.type === allowedDatasets[0].type) || isShowAllEnabled) {
          return ds;
        }
        return allowedDatasets[0];
      });
      setDatasetOptions([
        {
          value: allowedDatasets[0].type,
          label: getDatasetLabel(allowedDatasets[0]),
          isSelected:
            selectedDataset && selectedDataset.type === allowedDatasets[0].type,
          onSelected: () => {
            setSelectedDataset(allowedDatasets[0]);
            trackEvent('Metric Picker - Filtered by dataset');
          },
        },
      ]);
      return;
    }

    const newOptions = allowedDatasets.map((dataset) => {
      return {
        value: dataset.type,
        label: getDatasetLabel(dataset),
        isSelected: selectedDataset && selectedDataset.type === dataset.type,
        onSelected: () => {
          setSelectedDataset(dataset);
        },
      };
    });

    setDatasetOptions([
      {
        label: 'All Dataset Metrics',
        isSelected: selectedDataset === undefined,
        onSelected: () => {
          setSelectedDataset(undefined);
        },
      },
      ...newOptions,
    ]);
  }, [
    allowedDatasets,
    getDatasetLabel,
    isShowAllEnabled,
    selectedDataset,
    trackEvent,
  ]);

  useEffect(() => {
    const newOptions = allowedDatasetsWhenShowingAll
      .filter((ds) => !allowedDatasets.some((o) => o.type === ds.type))
      .map((dataset) => {
        return {
          value: dataset.type,
          label: getDatasetLabel(dataset),
          isSelected: selectedDataset && selectedDataset.type === dataset.type,
          onSelected: () => {
            setSelectedDataset(dataset);
          },
        };
      });

    setOtherDatasetOptions(newOptions);
  }, [
    allowedDatasets,
    allowedDatasetsWhenShowingAll,
    getDatasetLabel,
    selectedDataset,
  ]);

  useEffect(() => {
    const allowedDatasetTypes = (
      isShowAllEnabled ? allowedDatasetsWhenShowingAll : allowedDatasets
    ).map((d) => d.type);
    setFilteredMetrics(
      allowedMetrics
        .filter((m) => {
          const usedDatasetsTypes = getMetricDatasets(m).map((d) => d.type);
          return allowedDatasetTypes.some((ds) =>
            usedDatasetsTypes.includes(ds),
          );
        })
        .filter((m) => {
          if (searchText === '') {
            return true;
          }
          return m.name.toLowerCase().includes(searchText.toLowerCase());
        })
        .filter((m) => {
          const usedDatasetsTypes = getMetricDatasets(m).map((d) => d.type);
          if (selectedDataset) {
            return usedDatasetsTypes.includes(selectedDataset.type);
          }

          return true;
        }),
    );
  }, [
    allowedDatasets,
    allowedDatasetsWhenShowingAll,
    allowedMetrics,
    getMetricDatasets,
    isShowAllEnabled,
    searchText,
    selectedDataset,
  ]);

  useEffect(() => {
    setFilteredCoreMetrics(
      filteredMetrics.filter((m) => {
        if (metricTypeCheckers.isSpecialMetric(m)) {
          return false;
        }

        return m.status === 'core';
      }),
    );
  }, [filteredMetrics]);

  useEffect(() => {
    setFilteredOtherMetrics(
      filteredMetrics.filter((m) => {
        if (metricTypeCheckers.isSpecialMetric(m)) {
          return true;
        }

        return m.status === 'public';
      }),
    );
  }, [filteredMetrics]);

  return {
    filteredCoreMetrics,
    filteredOtherMetrics,
    datasetOptions,
    searchText,
    onSearchTextChanged,
    onShowAllDatasetsClicked,
    isShowAllEnabled,
    otherDatasetOptions,
  };
};

export default useFilteredMetrics;
