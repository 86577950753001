import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import KpiForm from './KpiForm';
import { useDataTypesFromMetricId } from '../../../../hooks/useDataTypesFromSeriesAndMetricListItems';
import moment from 'moment';
import DateFieldsProvider from '../../../../contextProviders/DateFieldsProvider';
import withDateFilter from '../../../../hocs/withDateFIlter';
import DateInputContext from '../../../../contexts/DateInputContext';
import ReportDrillDownsProvider from '../../../../contextProviders/ReportDrillDownsProvider';

const aguid = require('aguid');

const KpiFormContainer = ({
  kpi,
  startDate,
  endDate,
  cadence,
  onClose,
  onSave,
}: {
  kpi?: GoalKPI;
  startDate: string;
  endDate?: string;
  cadence: FleetOps.Interval;
  onClose: () => void;
  onSave: (kpi: GoalKPI) => Promise<void>;
}) => {
  const { setDateField: setContextDateField } = useContext(DateInputContext);
  const [id] = useState<string>(kpi ? kpi.id : aguid());
  const [name, setName] = useState<string | undefined>(kpi ? kpi.name : '');
  const [metricId, setMetricId] = useState<string | undefined>(
    kpi ? kpi.metricId : undefined,
  );
  const [drillDowns, setDrillDowns] = useState<FilterPlate[]>(
    kpi ? kpi.drillDowns : [],
  );

  const [targetMode, setTargetMode] = useState<
    'basic' | 'advanced' | undefined
  >(kpi ? kpi.targetMode : 'basic');
  const [basicTarget, setBasicTarget] = useState<number | undefined>(
    kpi ? kpi.basicTarget : 0,
  );
  const [advancedTarget, setAdvancedTarget] = useState<GoalAdvancedTarget>(
    kpi ? kpi.advancedTarget : {},
  );
  const [aboveTargetIsGood, setAboveTargetIsGood] = useState<boolean>(
    kpi ? kpi.aboveTargetIsGood : true,
  );
  const [aboveTargetIsGoodOptions, setAboveTargetIsGoodOptions] = useState<
    RadioOption[]
  >([]);
  useEffect(() => {
    setAboveTargetIsGoodOptions([
      {
        label: 'Good',
        onSelected: () => setAboveTargetIsGood(true),
        key: 'good',
        isSelected: aboveTargetIsGood,
      },
      {
        label: 'Bad',
        onSelected: () => setAboveTargetIsGood(false),
        key: 'bad',
        isSelected: !aboveTargetIsGood,
      },
    ]);
  }, [aboveTargetIsGood]);

  const [reportDrillDownId, setReportDrillDownId] = useState<
    string | undefined
  >(kpi ? kpi.reportDrillDownId : undefined);
  const [boardDrillDownId, setBoardDrillDownId] = useState<string | undefined>(
    kpi ? kpi.boardDrillDownId : undefined,
  );
  const [dateField, setDateField] = useState<string | undefined>(
    kpi ? kpi.dateField : undefined,
  );

  useEffect(() => {
    if (dateField) {
      setContextDateField(dateField);
    }
  }, [dateField, setContextDateField]);

  const [draftKpi, setDraftKpi] = useState<GoalKPI | undefined>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const dataTypes = useDataTypesFromMetricId(metricId);

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  useEffect(() => {
    if (!metricId) {
      setDraftKpi(undefined);
      return;
    }

    setDraftKpi({
      id,
      name,
      metricId,
      targetMode,
      basicTarget: basicTarget ? basicTarget : 0,
      advancedTarget,
      aboveTargetIsGood,
      drillDowns,
      reportDrillDownId,
      boardDrillDownId,
      dateField,
    });
  }, [
    aboveTargetIsGood,
    advancedTarget,
    basicTarget,
    boardDrillDownId,
    dateField,
    drillDowns,
    id,
    metricId,
    name,
    reportDrillDownId,
    targetMode,
  ]);

  const save = useCallback(() => {
    if (draftKpi) {
      setIsSaving(true);
      onSave(draftKpi).then(() => {
        setIsSaving(false);
      });
    }
  }, [draftKpi, onSave]);

  const isValid = !!draftKpi;

  return (
    <DateFieldsProvider dataTypes={dataTypes}>
      <ReportDrillDownsProvider
        dataTypes={dataTypes}
        drillDowns={drillDowns}
        setDrillDowns={setDrillDowns}
      >
        <KpiForm
          dataTypes={dataTypes}
          name={name}
          onNameChange={onNameChange}
          metricId={metricId}
          setMetricId={setMetricId}
          drillDowns={drillDowns}
          setDrillDowns={setDrillDowns}
          cadence={cadence}
          targetMode={targetMode}
          setTargetMode={setTargetMode}
          basicTarget={basicTarget ? basicTarget : 0}
          setBasicTarget={setBasicTarget}
          advancedTarget={advancedTarget}
          setAdvancedTarget={setAdvancedTarget}
          startDate={startDate}
          endDate={
            endDate ? endDate : moment().add({ year: 10 }).format('YYYY-MM-DD')
          }
          isEditing={!!kpi}
          aboveTargetIsGoodOptions={aboveTargetIsGoodOptions}
          onClose={onClose}
          onSave={save}
          isValid={isValid}
          isSaving={isSaving}
          reportId={reportDrillDownId}
          setReportId={setReportDrillDownId}
          boardId={boardDrillDownId}
          setBoardId={setBoardDrillDownId}
          dateField={dateField}
          setDateField={setDateField}
        />
      </ReportDrillDownsProvider>
    </DateFieldsProvider>
  );
};

export default withDateFilter(KpiFormContainer, {});
