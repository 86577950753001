import React from 'react';
import StepContent, { StepContentBody } from '../StepContent';
import Typography from 'kingpin/atoms/Typography';
import Row from '../../../../../../components/Common/Row';
import Button from 'kingpin/atoms/Button';
import AddFieldsButton from './AddFieldsButton';
import FieldsList from './FieldsList';
import SaveAndCloseButton from '../SaveAndCloseButton';

const getFieldsCount = (
  keywords: PerformanceConfiguration.PerformanceKeyword[],
): number => {
  let count = 0;
  keywords.forEach(({ fields }) => {
    count += fields.length;
  });

  return count;
};

const FieldsStep = ({
  fields,
  datasetName,
  goToNextStep,
  goToPreviousStep,
  isSaving,
  isReadOnly,
}: {
  fields: PerformanceConfiguration.PerformanceKeyword[];
  datasetName: string;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  isSaving: boolean;
  isReadOnly: boolean;
}) => (
  <StepContent>
    <StepContentBody>
      <Typography.Header type="H4">Add Fields (optional)</Typography.Header>
      {fields.length === 0 && (
        <div>
          <Typography.Body type="Body 12">
            {`Include extra fields on ${datasetName} dataset to provide additional context.`}
          </Typography.Body>
          <AddFieldsButton label="Select Field(s)" />
        </div>
      )}
      {fields.length !== 0 && (
        <>
          <Row spaceBetween centerAlign style={{ marginBottom: 8 }}>
            <Typography.Body type="Body 12">
              {`${getFieldsCount(
                fields,
              )} Fields added to ${datasetName} thus far:`}
            </Typography.Body>
            <AddFieldsButton label="Add Additional Field(s)" />
          </Row>
          <FieldsList isReadOnly={isReadOnly} />
        </>
      )}
    </StepContentBody>
    <Row spaceBetween>
      <Button
        onClick={goToPreviousStep}
        label="Back"
        type="Tertiary"
        size="Small"
      />
      <Row>
        <SaveAndCloseButton />
        <Button
          onClick={goToNextStep}
          label={fields.length === 0 ? 'Skip Step' : 'Next Step'}
          isDisabled={isSaving}
          type="Primary"
          size="Small"
        />
      </Row>
    </Row>
  </StepContent>
);

export default FieldsStep;
