import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import GeneralPeriodSelector from './GeneralPeriodSelector';
import PeriodsContext from '../../contexts/PeriodsContext';

const GeneralPeriodSelectorContainer = ({
  onDatePickerOpen,
  onDatePickerClose,
}: {
  onDatePickerOpen?: () => void;
  onDatePickerClose?: () => void;
}) => {
  const { periods, selectedPeriod, setSelectedPeriod } =
    useContext(PeriodsContext);
  const [options, setOptions] = useState<DropdownOption[]>([]);

  useEffect(() => {
    const newOptions = _.sortBy(periods, 'startDate')
      .reverse()
      .map((bp) => {
        return {
          key: bp.startDate,
          label: bp.label,
          onSelected: () => setSelectedPeriod(bp),
        };
      });

    setOptions(newOptions);
  }, [periods, setSelectedPeriod]);

  return (
    <GeneralPeriodSelector
      options={options}
      selectedPeriod={selectedPeriod}
      onDatePickerOpen={onDatePickerOpen}
      onDatePickerClose={onDatePickerClose}
    />
  );
};

export default GeneralPeriodSelectorContainer;
