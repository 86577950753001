import { useCallback, useContext, useEffect, useState } from 'react';
import SelectedEntityContext from '../contexts/SelectedEntityContext';

const usePerformanceFilterInput = () => {
  const { entityField, selectedEntity } = useContext(SelectedEntityContext);

  const getFilterInput = useCallback(() => {
    if (!selectedEntity) {
      return;
    }

    const selectedEntityFilterInput = {
      keywords: [{ field: entityField, values: [selectedEntity] }],
    };

    return selectedEntityFilterInput;
  }, [entityField, selectedEntity]);

  const [filterInput, setFilterInput] = useState<FilterInput | undefined>(() =>
    getFilterInput(),
  );

  useEffect(() => {
    setFilterInput(getFilterInput());
  }, [getFilterInput]);

  return filterInput;
};

export default usePerformanceFilterInput;
