import styled from 'styled-components';
import Row from '../../Common/Row';
import {
  KPI_EXTENDED_ROW_HEIGHT,
  KPI_ROW_HEIGHT,
} from './KpiRowRight/constants';
import Colors2 from 'theme/Colors2';

const KpiRowElement = styled(Row)<{
  isLastRow?: boolean;
  isExtended?: boolean;
  isHighlighted?: boolean;
  isHovered?: boolean;
}>`
  height: ${(props) =>
    props.isExtended
      ? KPI_ROW_HEIGHT + KPI_EXTENDED_ROW_HEIGHT
      : KPI_ROW_HEIGHT}px;

  background-color: ${(props) =>
    props.isHighlighted
      ? Colors2.AvatarColors[2].background
      : props.isExtended
        ? '#F9F9F9'
        : props.isHovered
          ? '#E5F0FA'
          : undefined};

  transition: all 0.2s ease;

  .showOnMouseOver {
    visibility: hidden;
  }

  &:hover {
    .showOnMouseOver {
      visibility: visible;
      width: unset;
    }
  }
`;

export default KpiRowElement;
