import React from 'react';
import Tooltip from '../../../Tooltip';

import FlexCentered from '../../../Common/FlexCentered';
import ScalingNumber from '../../../ScalingNumber';
import Delta from '../../../Gadget/Delta';
import Colors from '../../../../theme/colors';
import Row from '../../../Common/Row';
import DeltaTooltip from '../../../Gadget/DeltaTooltip';
import Loading from '../../../Loading';

interface Props extends SmartCardProps {
  isCompact?: boolean;
  isLoading?: boolean;
  h: number;
}

const SmartCardContent = ({
  current,
  delta,
  tooltipProps,
  smartCardComparison,
  rawDelta,
  isGood,
  isCompact,
  isLoading,
  metricId,
}: Props) => (
  <FlexCentered
    style={{
      height: '100%',
      width: '100%',
    }}
  >
    {isLoading && <Loading />}
    {!isLoading && (
      <>
        {isCompact && (
          <React.Fragment>
            <div style={{ alignSelf: 'center', textAlign: 'center' }}>
              <Tooltip
                content={
                  tooltipProps ? (
                    <DeltaTooltip
                      metric={tooltipProps.metric}
                      current={tooltipProps.current}
                      previous={tooltipProps.previous}
                      currentDateRange={tooltipProps.currentDateRange}
                      previousDateRange={tooltipProps.previousDateRange}
                    />
                  ) : undefined
                }
                isAltTooltip
              >
                <ScalingNumber
                  number={current}
                  metricId={metricId}
                  color={isGood ? Colors.MEDIUM_SEA_GREEN : Colors.RADICAL_RED}
                />
              </Tooltip>
            </div>
          </React.Fragment>
        )}
        {!isCompact && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: 8,
            }}
          >
            <div>
              <ScalingNumber number={current} metricId={metricId} />
            </div>

            {delta !== Infinity && tooltipProps && (
              <Row style={{ justifyContent: 'center', width: '100%' }}>
                <Tooltip
                  isAltTooltip
                  content={
                    <DeltaTooltip
                      metric={tooltipProps.metric}
                      current={tooltipProps.current}
                      previous={tooltipProps.previous}
                      currentDateRange={tooltipProps.currentDateRange}
                      previousDateRange={tooltipProps.previousDateRange}
                    />
                  }
                >
                  <React.Fragment>
                    {smartCardComparison === 'delta' && (
                      <Delta
                        isGood={isGood}
                        delta={rawDelta}
                        isRaw={true}
                        metricId={metricId}
                        isGrid={false}
                      />
                    )}
                    {smartCardComparison === 'percentDelta' && (
                      <Delta
                        isGood={isGood}
                        delta={delta}
                        metricId={metricId}
                        isGrid={false}
                      />
                    )}
                    {smartCardComparison === 'both' && (
                      <Delta
                        isGood={isGood}
                        delta={delta}
                        rawDelta={rawDelta}
                        isBoth
                        metricId={metricId}
                        isGrid={false}
                      />
                    )}
                  </React.Fragment>
                </Tooltip>
              </Row>
            )}
          </div>
        )}
      </>
    )}
  </FlexCentered>
);

export default SmartCardContent;
