import React, { useCallback, useContext } from 'react';
import ComparisonSelector from './ComparisonSelector';
import ComparisonContext from '../../../contexts/ComparisonContext';
import AnalyticsContext from '../../../contexts/AnalyticsContext';
import ReportContext from '../../../contexts/ReportContext';
import ChartDefinitionsContext from '../../../contexts/ChartDefinitionsContext';
import isSingleMetricDateMatrix from '../../../types/visTypeCheckers/isSingleMetricDateMatrix';
import CardContext from '../../../contexts/CardContext';
import PopupContext from '../../../contexts/PopupContext';
import isDefined from '../../../isDefined';
import cardTypeCheckers from '../../../types/cardTypeCheckers';
import isPaceMatrix from '../../../types/visTypeCheckers/isPaceMatrix';

const useHasCompareToVis = () => {
  const { report } = useContext(ReportContext);
  const { chartDefinition } = useContext(CardContext);
  const { definitions } = useContext(ChartDefinitionsContext);
  if (chartDefinition) {
    return (
      isSingleMetricDateMatrix(chartDefinition) || isPaceMatrix(chartDefinition)
    );
  }

  return (
    !!report &&
    report.canvas.cards
      .filter(cardTypeCheckers.isChartDefinition)
      .map((c) => definitions.find((d) => d.id === c.content.chartDefinitionId))
      .filter(isDefined)
      .filter((def) => isSingleMetricDateMatrix(def) || isPaceMatrix(def))
      .length > 0
  );
};

const ComparisonSelectorContainer = ({
  isReport,
  alwaysShowCompareTo,
  isOnlyPreviousAllowed,
}: {
  isReport?: boolean;
  alwaysShowCompareTo?: boolean;
  isOnlyPreviousAllowed?: boolean;
}) => {
  const { isOpen: isPopupReportOpen } = useContext(PopupContext);
  const shouldShowCompareTo = useHasCompareToVis() || !!alwaysShowCompareTo;
  const { currentComparison, setCurrentComparison } =
    useContext(ComparisonContext);
  const { trackEvent } = useContext(AnalyticsContext);

  const track = useCallback(() => {
    if (isReport && !isPopupReportOpen) {
      trackEvent('Report - Comparison Changed');
    }
  }, [isPopupReportOpen, isReport, trackEvent]);

  if (!setCurrentComparison) {
    throw new Error('setCurrentComparison not available in context');
  }

  return (
    <ComparisonSelector
      currentComparison={currentComparison}
      setCurrentComparison={setCurrentComparison}
      track={track}
      shouldShowCompareTo={shouldShowCompareTo}
      isOnlyPreviousAllowed={!!isOnlyPreviousAllowed}
    />
  );
};

export default ComparisonSelectorContainer;
