import React, { useContext } from 'react';
import { GridApi } from 'ag-grid-community';
import Row from 'components/Common/Row';
import ExportButton from 'components/Grid/ExportButton';
import PeriodSelector from 'components/Board/PeriodSelector';
import SignOff from 'components/Board/BonusSignOff';
import OmniSearch from 'components/OmniSearch';
import ReportDrillDowns from 'components/ReportDrillDowns';
import { SALES_COMMISSION_ACTIONS_ELEMENT_ID } from 'components/Board/constants';
import QuickFilters from 'components/CustomerLaneCommitsBoard/QuickFilters';
import Grid from '../PerformanceBoard/Grid';
import PermissionGates from 'components/PermissionGates';
import PERMISSIONS from 'permissionDefinitions';
import PerformanceBoardSettings from '../PerformanceBoardSettings';
import BoardContext from '../../../contexts/BoardContext';
import WorkSpaceContext from '../../../contexts/WorkSpaceContext';
import {
  BoardFilterBar,
  BoardMainContent,
  BoardTopBar,
  BoardWrapper,
} from '../../CustomerLaneCommitsBoard/CustomerLaneCommitBoard';
import Typography from 'kingpin/atoms/Typography';

const PerformanceBoard = ({
  board,
  gridApi,
  setGridApi,
  sortOverride,
  dataTypes,
  onManualFilterChanged,
  onQuickFilterChanged,
}: {
  board: PerformanceBoardTypes.Board;
  gridApi: GridApi | undefined;
  setGridApi: React.Dispatch<React.SetStateAction<GridApi | undefined>>;
  sortOverride: SimpleGridSort;
  dataTypes: string[];
  onQuickFilterChanged: () => void;
  onManualFilterChanged: () => void;
}) => {
  const { quickFilters } = useContext(BoardContext);
  const { workSpace } = useContext(WorkSpaceContext);
  const isWorkSpace = !!workSpace;
  const hasQuickFilters = quickFilters.length > 0;

  return (
    <BoardWrapper>
      <BoardTopBar>
        <Typography.Header type="H5">{board.name}</Typography.Header>
        <Row centerAlign>
          <Row style={{ marginRight: 8 }} centerAlign>
            {gridApi && (
              <div style={{ marginRight: 8 }}>
                <ExportButton
                  overrideGridApi={gridApi}
                  exportName={board.name}
                />
              </div>
            )}
            <PeriodSelector isWorkSpaceTopBar={false} />
          </Row>
          <SignOff />
          <PermissionGates.Has
            requiredPermission={PERMISSIONS.PERFORMANCE_BOARD.VIEW_SETTINGS}
          >
            <PerformanceBoardSettings />
          </PermissionGates.Has>
        </Row>
      </BoardTopBar>
      <BoardFilterBar hasBorder={hasQuickFilters}>
        <Row centerAlign>
          <div style={{ marginRight: 8, width: 150 }}>
            <OmniSearch.DrillDownBuilder
              dataTypes={dataTypes}
              onManualFilterChanged={onManualFilterChanged}
            />
          </div>
          <ReportDrillDowns onManualFilterChanged={onManualFilterChanged} />
        </Row>
        <div id={SALES_COMMISSION_ACTIONS_ELEMENT_ID} />
      </BoardFilterBar>
      {hasQuickFilters && (
        <BoardFilterBar>
          <QuickFilters onQuickFilterChanged={onQuickFilterChanged} />
        </BoardFilterBar>
      )}
      <BoardMainContent
        isWorkSpace={isWorkSpace}
        hasQuickFilters={hasQuickFilters}
      >
        <Grid
          board={board}
          sortOverride={sortOverride}
          key={board.updatedOn}
          setGridApi={setGridApi}
        />
      </BoardMainContent>
    </BoardWrapper>
  );
};

export default PerformanceBoard;
