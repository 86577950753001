import { useCallback, useContext } from 'react';
import CurrentUserContext from 'contexts/CurrentUserContext';
import getIdentifier from 'getIdentifier';
import getTimeStamp from 'getTimeStamp';

const useReducerProps = (
  editingSection: PerformanceBoardTypes.SlideOut.PerformanceSection | undefined,
) => {
  const { id: currentUserId } = useContext(CurrentUserContext);

  const performanceReducer = useCallback(
    (
      state: PerformanceWidgetReducer.WizardState,
      action: PerformanceWidgetReducer.WizardAction,
    ): PerformanceWidgetReducer.WizardState => {
      const { type, payload } = action;
      switch (type) {
        case 'UPDATE TITLE':
          return {
            ...state,
            title: payload.newTitle,
          };
        case 'SET SHOW REASON CODES':
          return {
            ...state,
            showReasonCodes: payload.newShowReasonCode,
          };
        case 'SET IS GRID HIDDEN':
          return {
            ...state,
            isGridHidden: payload.newIsGridHidden,
          };
        case 'UPDATE PERFORMANCE SECTION':
          return {
            ...state,
            performanceSection: payload.newSection,
          };
        case 'UPDATE WIDGET DATE RANGE':
          return {
            ...state,
            widgetDateRange: payload.newWidgetDateRange,
          };
        default:
          return state;
      }
    },
    [],
  );

  const initialState = {
    id: editingSection ? editingSection.id : getIdentifier(),
    createdBy: editingSection ? editingSection.createdBy : currentUserId,
    createdOn: editingSection ? editingSection.createdOn : getTimeStamp(),
    title: editingSection ? editingSection.title : '',
    showReasonCodes: editingSection ? editingSection.isReasonCodeEnabled : true,
    widgetDateRange: {
      mode: editingSection ? editingSection.dateRange.mode : 'Current Interval',
      n:
        editingSection && editingSection.dateRange.mode === 'Last'
          ? editingSection.dateRange.n
          : 1,
      isToDate: editingSection ? editingSection.dateRange.isToDate : false,
    },
    performanceSection: undefined,
    isGridHidden: editingSection ? editingSection.isGridHidden : false,
  };

  return { performanceReducer, initialState };
};

export default useReducerProps;
