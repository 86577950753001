const EDIT_SETTINGS: FleetOps.Permission =
  'fleetops.permissions.workspace_edit_settings';
const ADD_REMOVE_TABS: FleetOps.Permission =
  'fleetops.permissions.workspace_add_remove_tabs';
const DELETE: FleetOps.Permission = 'fleetops.permissions.workspace_delete';
const CREATE: FleetOps.Permission = 'fleetops.permissions.workspace_create';
const CREATE_ADVANCED: FleetOps.Permission =
  'fleetops.permissions.workspace_create_advanced';
const EDIT_BOARD_SETTINGS: FleetOps.Permission =
  'fleetops.permissions.workspace_edit_board_settings';

const WORKSPACE_MANAGEMENT_PERMISSIONS = {
  EDIT_SETTINGS,
  ADD_REMOVE_TABS,
  DELETE,
  CREATE,
  CREATE_ADVANCED,
  EDIT_BOARD_SETTINGS,
};
Object.freeze(WORKSPACE_MANAGEMENT_PERMISSIONS);

export default WORKSPACE_MANAGEMENT_PERMISSIONS;
