import styled, { css } from 'styled-components';

export const HIDE_UNLESS_HOVER_CLASS = 'showOnMouseOver';

export const HideUnlessMouseOverCSS = css`
  .${HIDE_UNLESS_HOVER_CLASS} {
    visibility: hidden;
    width: 0px;
  }

  &:hover {
    .${HIDE_UNLESS_HOVER_CLASS} {
      visibility: visible;
      width: unset;
    }
  }
`;

const HideUnlessMouseOver = styled.div`
  justify-content: column;
  width: 100%;
  height: 100%;
  ${HideUnlessMouseOverCSS};
`;

export default HideUnlessMouseOver;
