import React, { useContext, useEffect, useState } from 'react';

import usePopup from '../../../../hooks/usePopup';
import SlideOut from '../../../../components/Slideout';
import Button from 'kingpin/atoms/Button';
import MetricOptionsContext from '../../../../contexts/MetricOptionsContext';
import isDefined from '../../../../isDefined';
import metricTypeCheckers from '../../../../types/metricTypeCheckers';
import LocalTimelineProvider from 'contextProviders/TimelineProvider/LocalTimelineProvider';
import Timeline from '../../../../components/Timeline';
import Typography from 'kingpin/atoms/Typography';
import CloseButton from '../../../../components/CloseButton';
import Row from '../../../../components/Common/Row';

const TimelineButton = ({
  config,
}: {
  config: FleetOps.PerformanceDatasetConfig;
}) => {
  const { isOpen, open, close } = usePopup();
  const { metricOptionsLookup } = useContext(MetricOptionsContext);
  const [sources, setSources] = useState<Timeline.EventSource[]>([]);
  useEffect(() => {
    const perfDatasetSource = {
      id: config.id,
      type: 'PerformanceDataset' as 'PerformanceDataset',
    };
    const usedMetrics = config.metrics
      .map((m) => metricOptionsLookup[m.metricId])
      .filter(isDefined);
    const normalMetricSources = usedMetrics
      .filter(metricTypeCheckers.isNormalMetric)
      .map((m) => ({
        type: 'Metric' as 'Metric',
        id: m.id,
      }));
    const compoundMetricSources = usedMetrics
      .filter(metricTypeCheckers.isCompoundMetric)
      .map((m) => ({
        type: 'CompoundMetric' as 'CompoundMetric',
        id: m.id,
      }));

    setSources([
      perfDatasetSource,
      ...normalMetricSources,
      ...compoundMetricSources,
    ]);
  }, [config.id, config.metrics, metricOptionsLookup]);

  return (
    <>
      <Button
        type="Tertiary"
        size="Small"
        onClick={open}
        icon="chart-outlined"
      />
      <LocalTimelineProvider sources={sources} startsOn={config.createdOn}>
        <SlideOut isOpen={isOpen} close={close}>
          <Row centerAlign spaceBetween style={{ marginBottom: 32 }}>
            <Typography.Header type="H5">Timeline</Typography.Header>
            <CloseButton close={close} />
          </Row>

          <Timeline isFullHeight />
        </SlideOut>
      </LocalTimelineProvider>
    </>
  );
};

export default TimelineButton;
