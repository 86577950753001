import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Colors2 from 'theme/Colors2';

import AccountPickerContext from 'contexts/AccountPickerContext';
import deleteComment from 'api/deleteComment';
import Row from 'components/Common/Row';
import Typography from 'kingpin/atoms/Typography';
import UserFromId from 'components/UserFromId';
import AnalyticsContext from 'contexts/AnalyticsContext';
import ContextMenu, { Option } from 'kingpin/atoms/ContextMenu';

const NoteWrapper = styled.div<{ isExtended: boolean }>`
  display: ${(props) => (props.isExtended ? 'block' : '-webkit-box')};
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 4px;
  overflow: hidden;
`;

const NoteView = ({
  note,
  setIsEditing,
}: {
  note: CommentType;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { accountRef } = useContext(AccountPickerContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const [isExtended, setIsExtended] = useState<boolean>(false);

  const onDeleteClicked = useCallback(() => {
    if (!note) {
      return;
    }
    deleteComment(note, accountRef).then(() => {
      setIsEditing(false);
      trackEvent('KPI List - Note - Deleted');
    });
  }, [accountRef, note, setIsEditing, trackEvent]);

  return (
    <div
      style={{
        padding: '16px',
        border: `1px solid ${Colors2.Grey['8']}`,
        borderRadius: '8px',
        marginBottom: '16px',
      }}
    >
      <Row spaceBetween centerAlign style={{ marginBottom: 8 }}>
        <UserFromId userId={note.createdBy} />

        <ContextMenu placement="bottom-end" testId="note-menu">
          <div style={{ padding: 8 }}>
            <Option
              onClick={() => {
                setIsEditing(true);
              }}
              label="Edit"
            />
            <Option onClick={onDeleteClicked} label="Delete" />
          </div>
        </ContextMenu>
      </Row>
      <NoteWrapper isExtended={isExtended}>
        <Typography.Body type="Body 12">{note.text}</Typography.Body>
      </NoteWrapper>
      <Row spaceBetween>
        <Typography.Body
          type="Link"
          onClick={() => {
            setIsExtended(!isExtended);
          }}
        >
          {isExtended ? 'show less' : 'read more'}
        </Typography.Body>
        <Typography.Body type="Body 12" color={Colors2.Grey['5']}>
          {moment(note.createdOn).fromNow()}
        </Typography.Body>
      </Row>
    </div>
  );
};

export default NoteView;
