import React from 'react';
import ManualRowForm from './ManualRowForm';
import { UnsavedChangesConfirmation } from 'components/ConfirmationModal';
import useManualForm from '../hooks/useManualForm';

const ManualRowFormContainer = ({
  manualKpi,
  close,
  setHasUnsavedChanges,
  isCloseConfirmationOpen,
  onCloseConfirmed,
  onCloseConfirmationClosed,
}: {
  manualKpi?: Scorecards.ManualKpiRow;
  close: () => void;
  setHasUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  isCloseConfirmationOpen: boolean;
  onCloseConfirmed: () => void;
  onCloseConfirmationClosed: () => void;
}) => {
  const {
    isShowingAdvanced,
    onAdvancedToggled,
    formattingOptions,
    formatting,
    currencyType,
    setCurrencyType,
    saveRow,
    label,
    onNameChanged,
    reportDrillDownId,
    setReportDrillDownId,
    trackDrillDownAdded,
    markHasUnsavedChanges,
    targets,
    setTargets,
    setCanSaveKpi,
    setIsTargetsDisabled,
    isTargetsDisabled,
    setProrationOverride,
    setIsColorOrderReversed,
    isColorOrderReversed,
    dynamicTargetOverride,
    isValid,
  } = useManualForm({
    manualKpi,
    close,
    setHasUnsavedChanges,
    isCloseConfirmationOpen,
    onCloseConfirmed,
    onCloseConfirmationClosed,
  });

  return (
    <>
      <ManualRowForm
        manualKpi={manualKpi}
        isShowingAdvanced={isShowingAdvanced}
        onAdvancedToggled={onAdvancedToggled}
        formattingOptions={formattingOptions}
        formatting={formatting}
        currencyType={currencyType}
        setCurrencyType={setCurrencyType}
        saveRow={saveRow}
        label={label}
        onNameChanged={onNameChanged}
        reportDrillDownId={reportDrillDownId}
        setReportDrillDownId={setReportDrillDownId}
        trackDrillDownAdded={trackDrillDownAdded}
        markHasUnsavedChanges={markHasUnsavedChanges}
        targets={targets}
        setTargets={setTargets}
        setCanSaveKpi={setCanSaveKpi}
        setIsTargetsDisabled={setIsTargetsDisabled}
        isTargetsDisabled={isTargetsDisabled}
        setProrationOverride={setProrationOverride}
        setIsColorOrderReversed={setIsColorOrderReversed}
        isColorOrderReversed={isColorOrderReversed}
        dynamicTargetOverride={dynamicTargetOverride}
        isValid={isValid}
        close={close}
      />
      <UnsavedChangesConfirmation
        isOpen={isCloseConfirmationOpen}
        close={onCloseConfirmationClosed}
        onConfirmed={onCloseConfirmed}
      />
    </>
  );
};

export default ManualRowFormContainer;
