import React from 'react';

interface LocalTimelineContextType {
  events: Timeline.Event[];
  isLoading: boolean;
  addEvent?: ({
    actionText,
    contentText,
    contextText,
    interaction,
    isContentTextRich,
    destinationOverride,
  }: {
    actionText: string;
    contextText: string;
    contentText?: string;
    interaction?: Timeline.Interaction;
    isContentTextRich?: boolean;
    destinationOverride?: Timeline.EventSource;
  }) => Promise<void>;
}

const LocalTimelineContext = React.createContext<LocalTimelineContextType>({
  events: [],
  isLoading: true,
});

export default LocalTimelineContext;
