import { useCallback, useContext, useEffect, useState } from 'react';
import MetricsContext from '../contexts/MetricsContext';
import CompoundMetricsContext from '../contexts/CompoundMetricsContext';
import SpecialMetricsContext from '../contexts/SpecialMetricsContext';
import DatasetDefinitionsContext from '../contexts/DatasetDefinitionsContext';
import isDefined from '../isDefined';
import metricTypeCheckers from '../types/metricTypeCheckers';

const useMetricOptions = () => {
  const { datasets } = useContext(DatasetDefinitionsContext);
  const { allMetrics } = useContext(MetricsContext);
  const { allCompoundMetrics } = useContext(CompoundMetricsContext);
  const { specialMetrics } = useContext(SpecialMetricsContext);

  const getMetricOptions = useCallback(() => {
    return [...allMetrics, ...allCompoundMetrics, ...specialMetrics];
  }, [allCompoundMetrics, allMetrics, specialMetrics]);
  const getMetricOptionsNoSpecials = useCallback(() => {
    return [...allMetrics, ...allCompoundMetrics];
  }, [allCompoundMetrics, allMetrics]);
  const getNormalMetrics = useCallback(() => {
    return getMetricOptions().filter(metricTypeCheckers.isNormalMetric);
  }, [getMetricOptions]);
  const getDatasetMetricOptions = useCallback(() => {
    const datasetMetricOptions = {} as {
      [dataset: string]: {
        metrics: Metrics.NormalMetric[];
        compoundMetrics: Metrics.CompoundMetric[];
        specialMetrics: Metrics.SpecialMetric[];
      };
    };

    datasets.forEach((ds) => {
      const dsMetrics = allMetrics.filter((m) => m.dataType === ds.type);
      const dsCompounds = allCompoundMetrics.filter((cm) => {
        const internalMetrics = cm.metricIds
          .map((mid) => allMetrics.find((m) => m.id === mid))
          .filter(isDefined);
        return internalMetrics.some((m) => m.dataType === ds.type);
      });
      const dsSpecials = specialMetrics.filter((sm) => sm.dataType === ds.type);

      datasetMetricOptions[ds.type] = {
        metrics: dsMetrics,
        compoundMetrics: dsCompounds,
        specialMetrics: dsSpecials,
      };
    });

    return datasetMetricOptions;
  }, [allCompoundMetrics, allMetrics, datasets, specialMetrics]);

  const [normalMetrics, setNormalMetrics] = useState<Metrics.NormalMetric[]>(
    () => getNormalMetrics(),
  );
  const [metricOptions, setMetricOptions] = useState<Metrics.Metric[]>(() =>
    getMetricOptions(),
  );
  const [metricOptionsNoSpecials, setMetricOptionsNoSpecials] = useState<
    (Metrics.NormalMetric | Metrics.CompoundMetric)[]
  >(() => getMetricOptionsNoSpecials());
  const [datasetMetricOptions, setDatasetMetricOptions] = useState<{
    [dataset: string]: {
      metrics: Metrics.NormalMetric[];
      compoundMetrics: Metrics.CompoundMetric[];
      specialMetrics: Metrics.SpecialMetric[];
    };
  }>(() => getDatasetMetricOptions());
  const [metricOptionsLookup, setMetricOptionsLookup] = useState<{
    [metricId: string]: Metrics.Metric | undefined;
  }>({});

  useEffect(() => {
    const newLookup = {} as { [metricId: string]: Metrics.Metric };
    metricOptions.forEach((m) => {
      newLookup[m.id] = m;
    });
    setMetricOptionsLookup(newLookup);
  }, [metricOptions]);

  useEffect(() => {
    setMetricOptions(getMetricOptions());
  }, [allCompoundMetrics, allMetrics, specialMetrics, getMetricOptions]);

  useEffect(() => {
    setMetricOptionsNoSpecials(getMetricOptionsNoSpecials());
  }, [getMetricOptionsNoSpecials]);

  useEffect(() => {
    setNormalMetrics(getNormalMetrics());
  }, [getNormalMetrics, metricOptions]);

  useEffect(() => {
    setDatasetMetricOptions(getDatasetMetricOptions());
  }, [getDatasetMetricOptions]);

  return {
    metricOptions,
    metricOptionsLookup,
    metricOptionsNoSpecials,
    normalMetrics,
    datasetMetricOptions,
  };
};

export default useMetricOptions;
