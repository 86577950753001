import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react';

import CurrentUserContext from 'contexts/CurrentUserContext';
import usePerformanceFieldsOptions from 'components/PerformanceBoards/hooks/usePerformanceFieldsOptions';
import getTimeStamp from 'getTimeStamp';
import usePerformanceSlideOutFilterInput from 'components/PerformanceBoards/hooks/usePerformanceSlideOutFilterInput';
import useFullDataInterval from 'components/PerformanceBoards/hooks/useFullDataInterval';
import useSaveWidget from 'components/PerformanceBoards/hooks/useSaveWidget';
import useReducerProps from './reducer/useReducerProps';
import PerformanceSlideoutWizard from './PerformanceSlideoutWizard';

const PerformanceSlideoutWizardContainer = ({
  close,
  editingSection,
}: {
  close: () => void;
  editingSection: PerformanceBoardTypes.SlideOut.PerformanceSection | undefined;
}) => {
  const { id: currentUserId } = useContext(CurrentUserContext);
  const { performanceReducer, initialState } = useReducerProps(editingSection);
  const [state, dispatch] = useReducer(performanceReducer, initialState);

  const filterInput = usePerformanceSlideOutFilterInput();
  const { dateScope } = useFullDataInterval(state.widgetDateRange);
  const saveWidget = useSaveWidget({
    section: state.performanceSection,
    editingSection,
  });

  const { fieldsOptions, selectedField, isPerformanceFieldRemoved } =
    usePerformanceFieldsOptions({
      initialSelectedField: editingSection
        ? editingSection.performanceField
        : undefined,
    });

  const onTitleChanged = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    dispatch({
      type: 'UPDATE TITLE' as 'UPDATE TITLE',
      payload: { newTitle: event.target.value },
    });
  };

  const isDisabled =
    state.title === '' ||
    !selectedField ||
    (!state.widgetDateRange.n && state.widgetDateRange.mode === 'Last');

  useEffect(() => {
    if (isDisabled) {
      dispatch({
        type: 'UPDATE PERFORMANCE SECTION' as 'UPDATE PERFORMANCE SECTION',
        payload: { newSection: undefined },
      });
      return;
    }

    const newPerformanceSection = {
      id: state.id,
      createdBy: state.createdBy,
      updatedBy: currentUserId,
      createdOn: state.createdOn,
      updatedOn: getTimeStamp(),
      sectionType: 'Performance Graph' as 'Performance Graph',
      title: state.title,
      performanceField: selectedField,
      dateRange: state.widgetDateRange,
      isReasonCodeEnabled: state.showReasonCodes,
      isGridHidden: state.isGridHidden,
    };

    dispatch({
      type: 'UPDATE PERFORMANCE SECTION' as 'UPDATE PERFORMANCE SECTION',
      payload: { newSection: newPerformanceSection },
    });
  }, [
    currentUserId,
    isDisabled,
    selectedField,
    state.createdBy,
    state.createdOn,
    state.id,
    state.isGridHidden,
    state.showReasonCodes,
    state.title,
    state.widgetDateRange,
  ]);

  const setWidgetDateRange = useCallback(
    (newWidgetDateRange: PerformanceBoardTypes.SlideOut.SlideOutDateRange) => {
      dispatch({
        type: 'UPDATE WIDGET DATE RANGE' as 'UPDATE WIDGET DATE RANGE',
        payload: {
          newWidgetDateRange: newWidgetDateRange,
        },
      });
    },
    [],
  );

  return (
    <PerformanceSlideoutWizard
      saveWidget={saveWidget}
      onTitleChanged={onTitleChanged}
      setWidgetDateRange={setWidgetDateRange}
      isDisabled={isDisabled}
      filterInput={filterInput}
      dateScope={dateScope}
      state={state}
      dispatch={dispatch}
      isPerformanceFieldRemoved={isPerformanceFieldRemoved}
      fieldsOptions={fieldsOptions}
      close={close}
    />
  );
};

export default PerformanceSlideoutWizardContainer;
