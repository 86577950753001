import Typography from 'kingpin/atoms/Typography';
import React from 'react';
import isoDateToAmerican from '../isoDateToAmerican';

const ConfigureGadgetBonusPeriodInfo = ({
  selectedBonusPeriod,
}: {
  selectedBonusPeriod?: BonusPeriod;
}) => (
  <div style={{ marginBottom: 12 }}>
    <Typography.Body type="Body 12">
      This will set the date range to the most recent period which is ready for
      review
    </Typography.Body>
    <br />
    {selectedBonusPeriod && (
      <React.Fragment>
        <Typography.Body type="Body 12">
          {`Currently, that is ${
            selectedBonusPeriod.label
          } which is ${isoDateToAmerican(
            selectedBonusPeriod.startDate,
          )} - ${isoDateToAmerican(selectedBonusPeriod.endDate)}`}
        </Typography.Body>
      </React.Fragment>
    )}
    {!selectedBonusPeriod && (
      <Typography.Body type="Body 12">
        However, no bonus period was found for this account, so this will lead
        to unexpected behaviour
      </Typography.Body>
    )}
  </div>
);

export default ConfigureGadgetBonusPeriodInfo;
