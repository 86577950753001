import React from 'react';

import Row from '../../Common/Row';
import Button from '../../../kingpin/atoms/Button';
import Typography from 'kingpin/atoms/Typography';

const ValueSelectionTop = ({
  fieldName,
  onBackPressed,
}: {
  fieldName?: string;
  onBackPressed?: () => void;
}) => (
  <Row centerAlign>
    {onBackPressed && (
      <div style={{ marginRight: 8 }}>
        <Button
          type="Secondary"
          size="Small"
          onClick={onBackPressed}
          icon={'chevron-left'}
        />
      </div>
    )}
    <Typography.Header type="H5">{fieldName}</Typography.Header>
  </Row>
);

export default ValueSelectionTop;
