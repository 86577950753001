import React from 'react';
import styled from 'styled-components';

import SummaryIcon from 'images/details-slideout/summary.svg';
import MetricIcon from 'images/details-slideout/graph-bar.svg';
import TruckIcon from 'images/details-slideout/truck.svg';

import NewCommitmentSlideOutConstants from './constants';

const Wrapper = styled.div`
  width: 48px;
  height: 100%;
  background-color: #fbfbfe;
  box-shadow: 1px 0 0 0 rgba(53, 45, 67, 0.07);
  padding: 24px 8px;
`;

const Button = styled.div<{ isActive?: boolean }>`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 6px;
  border-radius: 4px;
  cursor: pointer;
  ${(props) => props.isActive && 'background-color: #ededf2;'}

  &:hover {
    opacity: 0.7;
  }
`;

const Icon = styled.img``;

const scrollTo = (id: string) => {
  const scroller = document.getElementById(
    NewCommitmentSlideOutConstants.NAV_SCROLLER_ID,
  );

  const summaryElem = document.getElementById(
    NewCommitmentSlideOutConstants.SUMMARY_ID,
  );
  const awardedLoadsElem = document.getElementById(
    NewCommitmentSlideOutConstants.AWARDED_LOADS_ID,
  );
  const revenueElem = document.getElementById(
    NewCommitmentSlideOutConstants.REVENUE_ID,
  );

  if (!scroller || !summaryElem || !awardedLoadsElem || !revenueElem) {
    return;
  }

  if (id === NewCommitmentSlideOutConstants.SUMMARY_ID) {
    scroller.scrollTop = 0;
  } else if (id === NewCommitmentSlideOutConstants.AWARDED_LOADS_ID) {
    scroller.scrollTop = summaryElem.clientHeight;
  } else if (id === NewCommitmentSlideOutConstants.REVENUE_ID) {
    scroller.scrollTop =
      summaryElem.clientHeight + awardedLoadsElem.clientHeight;
  }
};

const Nav = ({
  activeNavElement,
}: {
  activeNavElement: NewCommitmentSlideOutNavElement;
}) => (
  <Wrapper style={{ height: '100%' }}>
    <Button
      isActive={'summary' === activeNavElement}
      onClick={() => scrollTo(NewCommitmentSlideOutConstants.SUMMARY_ID)}
    >
      <Icon src={SummaryIcon} />
    </Button>
    <Button
      isActive={'awarded-loads' === activeNavElement}
      onClick={() => scrollTo(NewCommitmentSlideOutConstants.AWARDED_LOADS_ID)}
    >
      <Icon src={MetricIcon} />
    </Button>
    <Button
      isActive={'revenue' === activeNavElement}
      onClick={() => scrollTo(NewCommitmentSlideOutConstants.REVENUE_ID)}
    >
      <Icon src={TruckIcon} />
    </Button>
  </Wrapper>
);

export default Nav;
