import React, { useEffect, useState } from 'react';

import MetricActions from './MetricActions';
import useActions from './useActions';
import useEditingState from './useEditingState';
import Row from '../../../../../components/Common/Row';
import MetricStatusBadge from './MetricStatusBadge';
import SaveToDataManagerCheckbox from './SaveToDataManagerCheckbox';
import useSaveMetric from '../../hooks/useSaveMetric';
import PermissionGates from '../../../../../components/PermissionGates';
import PERMISSIONS from '../../../../../permissionDefinitions';
import useMetricUsageReport from 'hooks/useMetricUsageReport';

const MetricActionsContainer = ({
  selectedMetric,
  metricDraft,
  setStatus,
  showFlash,
}: {
  selectedMetric?: Metrics.NormalMetric | Metrics.CompoundMetric;
  metricDraft?: Metrics.NormalMetric | Metrics.CompoundMetric;
  setStatus: React.Dispatch<React.SetStateAction<Metrics.MetricStatus>>;
  showFlash: (message: string) => void;
}) => {
  const editingState = useEditingState({ selectedMetric, metricDraft });
  const usageReport = useMetricUsageReport(metricDraft);
  const usageCount = usageReport ? usageReport.usageCount : 0;
  const [isDisabled, setIsDisabled] = useState<boolean>(
    editingState === 'invalid form',
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { onSave, ConfirmationModal } = useSaveMetric({
    metricDraft,
    selectedMetric,
    setIsLoading,
    setStatus,
    showFlash,
  });
  const [isEditing] = useState<boolean>(!!selectedMetric);

  useEffect(() => {
    setIsDisabled(editingState === 'invalid form');
  }, [editingState]);

  const { primaryAction, secondaryActions } = useActions(
    onSave,
    editingState,
    isEditing,
    usageCount,
  );

  return (
    <>
      <Row centerAlign>
        <PermissionGates.Has
          requiredPermission={
            PERMISSIONS.DATA_MANAGEMENT.REUSABLE_METRICS_CONFIG
          }
        >
          <SaveToDataManagerCheckbox />
        </PermissionGates.Has>
        {metricDraft && metricDraft.status === 'core' && (
          <MetricStatusBadge status={metricDraft.status} />
        )}
        <MetricActions
          selectedMetric={selectedMetric}
          primaryAction={primaryAction}
          secondaryActions={secondaryActions}
          isDisabled={isDisabled || isLoading}
          isLoading={isLoading}
        />
      </Row>
      {ConfirmationModal}
    </>
  );
};

export default MetricActionsContainer;
