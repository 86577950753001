import React from 'react';
import styled from 'styled-components';
import Typography from 'kingpin/atoms/Typography';

import Colors2 from '../../../../theme/Colors2';
import { OverriddenBy } from '../../../DriverBonus/DriverCell';
import Icon from '../../../../kingpin/atoms/Icon';

const Banner = styled.div`
  border-radius: 4px;
  background-color: ${Colors2.AvatarColors['2'].background};
  padding: 10px 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 8px;
`;

const OverrideBanner = ({
  label,
  overriddenBy,
  isOverriddenAfterClosed,
  note,
}: {
  label: string;
  overriddenBy: string;
  isOverriddenAfterClosed: boolean;
  note: string;
}) => (
  <Banner>
    <div style={{ marginRight: 8 }}>
      <Icon icon="edit-filled" color={Colors2.AvatarColors['2'].text} />
    </div>
    <div>
      <div style={{ marginBottom: 12 }}>
        <Typography.Body type="Body 12">{label}</Typography.Body>
        <OverriddenBy userId={overriddenBy} />
        {isOverriddenAfterClosed && (
          <Typography.Body type="Body 12">
            Overridden after Bonus was closed
          </Typography.Body>
        )}
      </div>
      <Typography.Body type="Body 12">{`Note: ${note}`}</Typography.Body>
    </div>
  </Banner>
);

export default OverrideBanner;
