import { useContext, useEffect, useState } from 'react';
import AccountPickerContext from '../contexts/AccountPickerContext';
import STORE from '../store';

const useChartDefinitions = () => {
  const [chartDefs, setChartDefs] = useState<V5ChartDefinition[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { selectedAccountId } = useContext(AccountPickerContext);

  useEffect(() => {
    setIsLoading(true);

    const listener = STORE.visualisations
      .getChartDefsRef({ accountId: selectedAccountId })
      .orderBy('name')
      .onSnapshot((snapshot) => {
        const data = [] as V5ChartDefinition[];
        snapshot.docs.forEach((d) => data.push(d.data()));
        setChartDefs(data.filter((d) => !d.isHidden));
        setIsLoading(false);
      });
    return () => {
      listener();
    };
  }, [selectedAccountId]);

  return { chartDefs, isLoading };
};

export default useChartDefinitions;
