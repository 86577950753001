import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import Modal from '@atlaskit/modal-dialog';

import TopBar from './TopBar';
import getIdentifier from '../../../../../getIdentifier';
import DatasetSetUpStep from './DatasetSetUpStep';
import AccountContext from '../../../../../contexts/AccountContext';
import MetricsStep from './MetricsStep';
import FieldsStep from './FieldsStep';
import PerformanceWizardContext from './PerformanceWizardContext';
import ConfirmationStep from './ConfirmationStep';
import useConfirmation from './useConfirmation';
import useModalWidth from '../../../../../hooks/useModalWidth';
import _ from 'lodash';

const DEFAULT_STATE: PerformanceConfiguration.WizardState = {
  isEditing: false,
  currentStep: 'dataset set up' as 'dataset set up',
  isDatasetStepComplete: false,
  isMetricsStepComplete: false,
  isFieldsStepComplete: false,
  config: {
    datasetStep: {
      interval: 'week' as 'week',
      intervalLength: 1,
    },
    fieldsStep: {
      fields: [],
    },
    metricsStep: {
      metrics: [],
    },
  },
  isValid: false,
};

const PerformanceDatasetWizardContainer = ({
  initialState,
  close,
  isReadOnly,
}: {
  initialState?: PerformanceConfiguration.WizardState;
  close: () => void;
  isReadOnly?: boolean;
}) => {
  const { weekStartsOn } = useContext(AccountContext);
  const isMounted = useRef<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const { ConfirmationModal, getConfirmation } = useConfirmation({
    title: 'Unsaved Changes',
    body:
      'If you navigate away, all changes made will be lost. Are you sure' +
      ' you want to discard these changes?',
    confirmText: 'Yes, Discard Changes',
  });

  const [originalConfig] = useState<
    PerformanceConfiguration.WizardState | undefined
  >(initialState);
  const [wizardState, setWizardState] =
    useState<PerformanceConfiguration.WizardState>(() => {
      if (initialState) {
        return { ...initialState };
      }
      return {
        ...DEFAULT_STATE,
        firestoreId: getIdentifier(),
        config: {
          ...DEFAULT_STATE.config,
          datasetStep: {
            ...DEFAULT_STATE.config.datasetStep,
            startOfWeek: weekStartsOn,
          },
        },
      };
    });

  useEffect(() => {
    if (isMounted.current) {
      setHasUnsavedChanges(true);
    } else {
      isMounted.current = true;
    }
  }, [wizardState]);

  const shouldConfirm = useCallback(() => {
    if (!hasUnsavedChanges) {
      return false;
    }

    if (initialState === undefined) {
      return false;
    }

    const compareTo = {
      ...wizardState,
      currentStep: initialState.currentStep,
    };

    return !_.isEqual(initialState, compareTo);
  }, [hasUnsavedChanges, initialState, wizardState]);

  const onCloseClicked = useCallback(async () => {
    if (shouldConfirm()) {
      const confirmation = await getConfirmation();
      if (confirmation) {
        close();
      }
    } else {
      close();
    }
  }, [close, getConfirmation, shouldConfirm]);

  const goToPreviousStep = useCallback(() => {
    if (wizardState.currentStep === 'metrics') {
      setWizardState((s) => ({ ...s, currentStep: 'dataset set up' }));
    } else if (wizardState.currentStep === 'fields') {
      setWizardState((s) => ({ ...s, currentStep: 'metrics' }));
    } else if (wizardState.currentStep === 'confirmation') {
      setWizardState((s) => ({ ...s, currentStep: 'fields' }));
    }
  }, [wizardState.currentStep]);

  const goToNextStep = useCallback(() => {
    if (wizardState.currentStep === 'dataset set up') {
      setWizardState((s) => ({
        ...s,
        currentStep: 'metrics',
        isDatasetStepComplete: true,
      }));
    } else if (wizardState.currentStep === 'metrics') {
      setWizardState((s) => ({
        ...s,
        currentStep: 'fields',
        isMetricsStepComplete: true,
      }));
    } else if (wizardState.currentStep === 'fields') {
      setWizardState((s) => ({
        ...s,
        currentStep: 'confirmation',
        isFieldsStepComplete: true,
      }));
    }
  }, [wizardState.currentStep]);
  const modalWidth = useModalWidth();

  return (
    <>
      <Modal
        width={modalWidth}
        height={'80vh'}
        onClose={onCloseClicked}
        shouldScrollInViewport={false}
        autoFocus={false}
      >
        <PerformanceWizardContext.Provider
          value={{
            wizardState,
            originalConfig,
            setWizardState,
            isSaving,
            setIsSaving,
            close,
            isReadOnly: !!isReadOnly,
          }}
        >
          <TopBar close={onCloseClicked} />
          {wizardState.currentStep === 'dataset set up' && (
            <DatasetSetUpStep
              goToNextStep={goToNextStep}
              onCloseClicked={onCloseClicked}
            />
          )}
          {wizardState.currentStep === 'metrics' && (
            <MetricsStep
              goToNextStep={goToNextStep}
              goToPreviousStep={goToPreviousStep}
              isSaving={isSaving}
            />
          )}
          {wizardState.currentStep === 'fields' && (
            <FieldsStep
              goToNextStep={goToNextStep}
              goToPreviousStep={goToPreviousStep}
              isSaving={isSaving}
            />
          )}
          {wizardState.currentStep === 'confirmation' && (
            <ConfirmationStep goToPreviousStep={goToPreviousStep} />
          )}
        </PerformanceWizardContext.Provider>
      </Modal>
      {ConfirmationModal}
    </>
  );
};

export default PerformanceDatasetWizardContainer;
