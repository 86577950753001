import React, { useCallback, useContext, useState } from 'react';

import MetricsContext from '../../../contexts/MetricsContext';
import useNetworkingEffect from '../../../hooks/useNetworkingEffect';
import CompoundMetricsContext from '../../../contexts/CompoundMetricsContext';
import useGetFilteredAndSortedMetrics from './useGetFilteredAndSortedMetrics';
import useFilterOptions from './useFilterOptions';
import useSortOptions from './useSortOptions';
import useClearButtons from './useClearButtons';

const useFilteredMetrics = ({
  dataset,
  initialSortMode,
  mode,
}: {
  dataset?: string;
  initialSortMode?: SortMode;
  mode: 'Metric' | 'CompoundMetric';
}) => {
  const { allMetrics } = useContext(MetricsContext);
  const { allCompoundMetrics } = useContext(CompoundMetricsContext);

  const [isLoadingMetrics, setIsLoadingMetrics] = useState<boolean>(true);
  const [isLoadingCompoundMetrics, setIsLoadingCompoundMetrics] =
    useState<boolean>(true);
  const [filters, setFilters] = useState<MetricFilters>(
    dataset
      ? {
          dataset,
        }
      : {},
  );
  const [filteredMetrics, setFilteredMetrics] = useState<
    Metrics.NormalMetric[]
  >([]);
  const [filteredCompoundMetrics, setFilteredCompoundMetrics] = useState<
    Metrics.CompoundMetric[]
  >([]);
  const [isDatasetFilterDisabled] = useState<boolean>(!!dataset);
  const [sortMode, setSortMode] = useState<SortMode>(
    initialSortMode ? initialSortMode : 'Alphabetical',
  );

  const { statusOptions, datasetOptions, usageOptions, filterPlates } =
    useFilterOptions({
      filters,
      setFilters,
      isDatasetFilterDisabled,
      mode,
    });
  const sortOptions = useSortOptions({ sortMode, setSortMode });

  const getFilteredAndSortedMetrics = useGetFilteredAndSortedMetrics();

  useNetworkingEffect(() => {
    const sorted = getFilteredAndSortedMetrics({
      metrics: allMetrics,
      filters,
      sortMode,
    });
    setFilteredMetrics(sorted);
    setIsLoadingMetrics(false);
  }, [allMetrics, filters, getFilteredAndSortedMetrics, sortMode]);

  useNetworkingEffect(() => {
    const sorted = getFilteredAndSortedMetrics({
      metrics: allCompoundMetrics,
      filters,
      sortMode,
    });
    setFilteredCompoundMetrics(sorted);
    setIsLoadingCompoundMetrics(false);
  }, [allCompoundMetrics, filters, getFilteredAndSortedMetrics, sortMode]);

  const onSearchTextChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue =
        event.target.value === '' ? undefined : event.target.value;
      setFilters((f) => ({
        ...f,
        searchText: newValue,
      }));
    },
    [],
  );

  const {
    isClearButtonVisible,
    onClearDatasetsClicked,
    onClearStatusClicked,
    onClearUsageClicked,
    onClearFiltersClicked,
  } = useClearButtons({ filters, setFilters, dataset });

  return {
    isLoadingMetrics,
    isLoadingCompoundMetrics,
    isDatasetFilterDisabled,
    filteredMetrics,
    filteredCompoundMetrics,
    searchText: filters.searchText || '',
    onSearchTextChanged,
    datasetOptions,
    statusOptions,
    usageOptions,
    sortOptions,
    filterPlates,
    onClearFiltersClicked,
    isClearButtonVisible,
    onClearDatasetsClicked:
      filters.dataset !== undefined && dataset === undefined
        ? onClearDatasetsClicked
        : undefined,
    onClearUsageClicked:
      filters.isUsed !== undefined ? onClearUsageClicked : undefined,
    onClearStatusClicked:
      filters.status !== undefined ? onClearStatusClicked : undefined,
  };
};

export default useFilteredMetrics;
