import { useContext, useEffect, useState } from 'react';
import AccountPickerContext from '../../contexts/AccountPickerContext';
import getBonusPeriodsRef from './getBonusPeriodsRef';

const useBonusPeriods = () => {
  const [bonusPeriods, setBonusPeriods] = useState<BonusPeriod[]>([]);
  const [currentBonusPeriod, setCurrentBonusPeriod] = useState<
    BonusPeriod | undefined
  >();
  const { accountRef } = useContext(AccountPickerContext);

  useEffect(() => {
    const listener = getBonusPeriodsRef(accountRef).onSnapshot(
      (snapshot: any) => {
        const data: BonusPeriod[] = [];
        snapshot.docs.forEach((d: any) =>
          data.push({
            ...d.data(),
          }),
        );
        setBonusPeriods(
          data.map((bp) => {
            if (!bp.status) {
              return {
                ...bp,
                status: 'in review',
              };
            }
            return bp;
          }),
        );
        setCurrentBonusPeriod(data.find((bp) => bp.status === 'active'));
      },
    );
    return () => {
      listener();
    };
  }, [accountRef]);

  const bonusPeriodOptions = bonusPeriods.map((bp) => ({
    label: `${bp.label} - ${bp.status}`,
    value: bp.id,
  }));

  return {
    bonusPeriods,
    bonusPeriodOptions,
    currentBonusPeriod,
  };
};

export default useBonusPeriods;
