import firebase from 'firebase/compat/app';
import withoutNulls from '../api/search/withoutNulls';

const userProfileConvertor = {
  toFirestore(
    userProfile: UserManagement.UserProfile,
  ): firebase.firestore.DocumentData {
    return withoutNulls(userProfile);
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot<UserManagement.UserProfile>,
    options: firebase.firestore.SnapshotOptions,
  ): UserManagement.UserProfile {
    return snapshot.data(options);
  },
};

const getUserProfilesRef = ({
  userId,
  db,
}: {
  userId: string;
  db?: firebase.firestore.Firestore;
}) => {
  return (db ? db : firebase.firestore())
    .collection('userProfiles')
    .doc(userId)
    .withConverter(userProfileConvertor);
};

export default getUserProfilesRef;
