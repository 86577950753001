/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import useIsFireTv from './useIsFireTv';
import WallboardContext from '../contexts/WallboardContext';

const TABLET_WIDTH = 1280;
const MOBILE_WIDTH = 800;
const SMALL_FONT_WIDTH = 1500;
const SMALLER_DESKTOP = 1366;

function useWindowSize() {
  const { isWallboard } = useContext(WallboardContext);
  const isFireTv = useIsFireTv();
  const isClient = typeof window === 'object';

  function getSize() {
    return {
      width: isClient ? window.innerWidth : 1920,
      height: isClient ? window.innerHeight : 1080,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  const useTabletLayout =
    windowSize.width < TABLET_WIDTH &&
    windowSize.width > MOBILE_WIDTH &&
    !isFireTv &&
    !isWallboard;
  const useMobileLayout =
    windowSize.width < MOBILE_WIDTH && !isFireTv && !isWallboard;
  const useSmallText =
    windowSize.width > TABLET_WIDTH && windowSize.width < SMALL_FONT_WIDTH;
  const useDesktopLayout = !useMobileLayout && !useTabletLayout;
  const useSmallerDesktopLayout = windowSize.width <= SMALLER_DESKTOP;

  return {
    windowWidth: windowSize.width,
    windowHeight: windowSize.height,
    windowSize,
    useTabletLayout,
    useMobileLayout,
    useSmallText,
    useDesktopLayout,
    useSmallerDesktopLayout,
    isMobile:
      process.env.NODE_ENV === 'test'
        ? false
        : useMobileLayout || useTabletLayout,
  };
}

export default useWindowSize;
