import React, { useEffect, useState } from 'react';
import ReportDrillDownsContext from '../contexts/ReportDrillDownsContext';
import useToDrillDowns from 'components/Report/useToReportDrillDown';

const ReportDrillDownsProvider = ({
  children,
  drillDowns,
  setDrillDowns,
  scope,
  setScope,
  variableDrillDowns,
  dataTypes,
  dataType,
  quickFilters,
  isForDashboardCard,
  isForDatasetFilterPicker,
}: {
  children: JSX.Element | JSX.Element[];
  drillDowns?: FilterPlate[];
  setDrillDowns?: React.Dispatch<React.SetStateAction<FilterPlate[]>>;
  scope?: FilterPlate[];
  setScope?: React.Dispatch<React.SetStateAction<FilterPlate[]>>;
  variableDrillDowns?: VariableDrillDownType[];
  dataTypes?: string[];
  dataType?: string;
  quickFilters?: FilterInput[];
  isForDashboardCard?: boolean;
  isForDatasetFilterPicker?: boolean;
}) => {
  const [netDrillDowns, setNetDrillDowns] = useState<ReportDrillDownType[]>([]);
  const [netScope, setNetScope] = useState<ReportDrillDownType[]>([]);
  const [dataTypeAsArray, setDataTypeAsArray] = useState<string[] | undefined>(
    dataType ? [dataType] : undefined,
  );
  const toDrillDowns = useToDrillDowns();

  useEffect(() => {
    setDataTypeAsArray(dataType ? [dataType] : undefined);
  }, [dataType]);

  useEffect(() => {
    setNetDrillDowns(
      toDrillDowns({
        plates: drillDowns || window.emptyArray,
        variableDrillDowns: variableDrillDowns || window.emptyArray,
      }),
    );

    setNetScope(
      toDrillDowns({
        plates: scope || window.emptyArray,
        variableDrillDowns: variableDrillDowns || window.emptyArray,
      }),
    );
  }, [drillDowns, scope, toDrillDowns, variableDrillDowns]);

  return (
    <ReportDrillDownsContext.Provider
      value={{
        drillDowns: drillDowns || window.emptyArray,
        setDrillDowns: setDrillDowns || window.tokenFunction,
        netDrillDowns,
        scope: scope || window.emptyArray,
        setScope: setScope || window.tokenFunction,
        netScope,
        dataTypes: dataTypes || dataTypeAsArray || window.emptyArray,
        quickFilters: quickFilters || window.emptyArray,
        isForDashboardCard,
        isForDatasetFilterPicker,
      }}
    >
      {children}
    </ReportDrillDownsContext.Provider>
  );
};

export default ReportDrillDownsProvider;
