import React, { useCallback, useContext } from 'react';
import StepHeader from './StepHeader';
import { AccordionContent } from './ConfigSteps';
import CostsWizardContext from '../context/CostsWizardContext';
import Typography from 'kingpin/atoms/Typography';
import Row from 'components/Common/Row';
import Checkbox from 'components/Inputs/Checkbox';

const OutputsStep = () => {
  const { wizardState, setWizardState, hasEditPermission } =
    useContext(CostsWizardContext);

  const toggleExcludeFSC = useCallback(() => {
    setWizardState({
      ...wizardState,
      config: {
        ...wizardState.config,
        outputsStep: {
          ...wizardState.config.outputsStep,
          marginExcludeFSC: !wizardState.config.outputsStep.marginExcludeFSC,
        },
      },
    });
  }, [setWizardState, wizardState]);

  const toggleExcludePassThru = useCallback(() => {
    setWizardState({
      ...wizardState,
      config: {
        ...wizardState.config,
        outputsStep: {
          ...wizardState.config.outputsStep,
          marginExcludePassThru:
            !wizardState.config.outputsStep.marginExcludePassThru,
        },
      },
    });
  }, [setWizardState, wizardState]);

  return (
    <>
      <StepHeader headerStep={'outputs'} headerText="4. Outputs" />
      <AccordionContent
        isActive={wizardState.currentStep === 'outputs'}
        style={{
          height: wizardState.currentStep === 'outputs' ? '244px' : '0',
          transition: 'height: .2s  ease-out',
        }}
      >
        <div style={{ padding: '32px', marginBottom: '24px' }}>
          <div style={{ marginBottom: '24px' }}>
            <Typography.Header type="H5">
              Activities will be enriched with margin, margin calculation is
              based on total revenue.
            </Typography.Header>
          </div>
          <div style={{ marginBottom: 8 }}>
            <Typography.Body type={'Label'}>Exclude</Typography.Body>
          </div>
          <div>
            <Row centerAlign>
              <div style={{ marginRight: '8px' }}>
                <Checkbox
                  isChecked={wizardState.config.outputsStep.marginExcludeFSC}
                  onToggled={toggleExcludeFSC}
                  isDisabled={!hasEditPermission}
                  testId="checkbox-marginExcludeFSC"
                />
              </div>

              <Typography.Body type="Body 14">
                Fuel costs from margin calculation
              </Typography.Body>
            </Row>
          </div>
          <Row centerAlign style={{ marginBottom: '24px' }}>
            <div style={{ marginRight: '8px' }}>
              <Checkbox
                isChecked={wizardState.config.outputsStep.marginExcludePassThru}
                onToggled={toggleExcludePassThru}
                isDisabled={!hasEditPermission}
                testId="checkbox-marginExcludePassThru"
              />
            </div>

            <Typography.Body type="Body 14">
              Pass through from margin calculation
            </Typography.Body>
          </Row>
        </div>
      </AccordionContent>
    </>
  );
};

export default OutputsStep;
