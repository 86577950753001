import React from 'react';
import Inputs from 'components/Inputs';

const DataTypePicker = ({
  dataTypeOptions,
}: {
  dataTypeOptions: DropdownOption[];
}) => (
  <div style={{ marginRight: 12 }}>
    <Inputs.Dropdown options={dataTypeOptions} placeholder="Dataset" />
  </div>
);

export default DataTypePicker;
