import styled from 'styled-components';

const FlexCentered = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: ${(props) => (props.row ? 'row' : 'column')};
  align-items: center;
  justify-content: center;
`;

export default FlexCentered;
