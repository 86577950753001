import React, { useContext } from 'react';
import V5GadgetFormContext from '../../../contexts/V5GadgetFormContext';
import Label from '../Label';
import Inputs from '../../Inputs';
import { InputWrapper } from '../V5GadgetForm';

const DataLabelsInputContainer = () => {
  const { showDataLabels, setShowDataLabels, draftChart } =
    useContext(V5GadgetFormContext);

  if (
    draftChart &&
    (draftChart.gadgetType === 'bar' ||
      draftChart.gadgetType === 'horizontalBar' ||
      draftChart.gadgetType === 'combo' ||
      draftChart.gadgetType === 'treeMap')
  ) {
    return (
      <InputWrapper>
        <Label>Show data labels</Label>
        <Inputs.Toggle value={!!showDataLabels} onChange={setShowDataLabels} />
      </InputWrapper>
    );
  }
  return null;
};

export default DataLabelsInputContainer;
