import { buildContentViewIdAlt } from '../../buildContentViewId';
import unmarkItemAsFavourite from '../unmarkItemAsFavourite';
import setFavouritesOrder from '../setFavouritesOrder';
import firebase from 'firebase/compat/app';

const unFavourite = async ({
  type,
  typeId,
  userId,
  currentOrder,
  accountRef,
}: {
  type:
    | 'report'
    | 'dashboard'
    | 'wallboard'
    | 'workspace'
    | 'scorecard'
    | 'targetsApp';
  typeId: string;
  userId: string;
  currentOrder: string[];
  accountRef: firebase.firestore.DocumentReference;
}): Promise<void> => {
  const newOrder = currentOrder.filter(
    (id) => id !== buildContentViewIdAlt({ typeId, type }),
  );

  await unmarkItemAsFavourite({ type, typeId, userId, accountRef });
  await setFavouritesOrder({
    order: newOrder,
    userId,
    accountRef,
  });
  return;
};

export default unFavourite;
