import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import useValueFormatters from 'hooks/useValueFormatters';
import Cell from './V5Gadget/Matrix/Cell';

interface NumberCellRendererParams extends ICellRendererParams {
  fieldView?: FleetOps.BaseViewField;
  metric?: Metrics.Metric;
}

export const useSettings = (props: ICellRendererParams) => {
  const { colDef } = props;
  try {
    const cellRendererParams = colDef
      ? (colDef.cellRendererParams as NumberCellRendererParams)
      : undefined;
    if (cellRendererParams && cellRendererParams.fieldView) {
      const fieldView = cellRendererParams.fieldView;
      return {
        precision: fieldView.precision,
        isCommasDisabled: !!fieldView.formatting.isCommasDisabled,
        type: fieldView.type,
        currencyType:
          fieldView.cellType === 'Currency'
            ? fieldView.formatting.currencyType || 'USD'
            : undefined,
      };
    } else if (cellRendererParams && cellRendererParams.metric) {
      const metric = cellRendererParams.metric;

      return {
        precision: metric.formatting.precision,
        isCommasDisabled: !!metric.formatting.isCommasDisabled,
        type: 'long',
        currencyType:
          metric.formatting.prefix === 'currency'
            ? metric.formatting.currencyType || 'USD'
            : undefined,
      };
    } else {
      return {
        precision: 2,
        isCommasDisabled: false,
        type: 'long',
      };
    }
  } catch (ex) {
    return {
      precision: 2,
      isCommasDisabled: false,
      type: 'long',
    };
  }
};

const NumberCell = (props: ICellRendererParams) => {
  const { value } = props;
  const { precision, isCommasDisabled, type } = useSettings(props);
  const { formatValue } = useValueFormatters();

  const formatting = {
    precision: type === 'int' || type === 'long' ? 0 : precision,
    isCommasDisabled,
  } as MetricFormatting;

  if (Array.isArray(value)) {
    const numberValues = value.filter((v) => typeof v === 'number');
    const formattedValues = numberValues.map((v) =>
      formatValue({ value: v, formatting }),
    );
    return (
      <Cell rightAlign>
        <span>{formattedValues.join(', ')}</span>
      </Cell>
    );
  } else if (
    value === undefined ||
    value === null ||
    value === '-' || // in the event we decide against the below number check...
    typeof value !== 'number'
  ) {
    return (
      <Cell rightAlign>
        <span>-</span>
      </Cell>
    );
  } else {
    const formatted = formatValue({ value, formatting });

    return (
      <Cell rightAlign>
        <span>{formatted}</span>
      </Cell>
    );
  }
};

export default NumberCell;
