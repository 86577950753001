import getIdentifier from '../../getIdentifier';
import Colors2 from '../../theme/Colors2';
import captureException from '../../services/captureException';
import _ from 'lodash';

export const getTargetField = (
  target: Targets.Persisted.DataTypeTarget,
): string => {
  try {
    return target.cohorts[0].filteredCategorisations[0].categories[0].field;
  } catch (ex) {
    const e = new Error(target.id);
    e.name = 'Target Field Not Found';
    captureException(e);
    return '';
  }
};

const getOutputFields = (
  target: Targets.Persisted.DataTypeTarget,
): Targets.Wizard.OutputField[] => {
  try {
    const outputFields: Targets.Wizard.OutputField[] = [];
    target.cohorts.forEach((cohort) => {
      cohort.filteredCategorisations.forEach((fc) => {
        fc.categories.forEach((c) => {
          if (c.outputs) {
            c.outputs.forEach((o) => {
              if (o.fieldType === 'string') {
                outputFields.push({
                  fieldName: o.fieldName,
                  fieldType: 'text',
                });
              } else {
                outputFields.push({
                  fieldName: o.fieldName,
                  fieldType: 'number',
                });
              }
            });
          }
        });
      });
    });

    return _.uniqBy(outputFields, 'fieldName');
  } catch (ex) {
    return [];
  }
};

const toWizardCategory = (
  category: Targets.Persisted.Categorisation,
  isLastRow: boolean,
): Targets.Wizard.Categorisation => {
  const { value, operator } = (() => {
    if (category.gt !== undefined && category.gt !== null) {
      return { value: category.gt, operator: 'gt' as 'gt' };
    }
    if (category.gte !== undefined && category.gte !== null) {
      return { value: category.gte, operator: 'gte' as 'gte' };
    }
    if (category.lt !== undefined && category.lt !== null) {
      return { value: category.lt, operator: 'lt' as 'lt' };
    }
    if (category.lte !== undefined && category.lte !== null) {
      return { value: category.lte, operator: 'lte' as 'lte' };
    }

    const e = new Error();
    e.name =
      'TargetManager: Failed to transform persisted category to wizard category';
    throw e;
  })();

  if (value === null) {
    const e = new Error();
    e.name =
      'TargetManager: Failed to transform persisted category to wizard category';
    throw e;
  }

  return {
    key: getIdentifier(undefined, true),
    value: value.toString(),
    operator,
    isLastRow,
    outputs: category.outputs
      ? category.outputs.map((o): Targets.Wizard.CategorisationOutputField => {
          if (o.fieldType === 'string') {
            return {
              fieldName: o.fieldName,
              fieldType: 'text',
              fieldValue: o.fieldValueString || '',
            };
          } else {
            return {
              fieldName: o.fieldName,
              fieldType: 'number',
              fieldValue: (o.fieldValueFloat || 0).toString(),
            };
          }
        })
      : [],
  };
};

const toWizard = (
  target: Targets.Persisted.DataTypeTarget,
): Targets.Wizard.DataTypeTarget => {
  const isTargetedByGroup = target.cohortFieldName !== '*';
  const isBelowTargetDesirable = target.cohorts.some((c) =>
    c.filteredCategorisations.some((fc) =>
      fc.categories.some((r) => r.lte !== undefined && r.lte !== null),
    ),
  );
  const isFullPeriod = target.cohorts.some((c) =>
    c.filteredCategorisations.some((fc) =>
      fc.categories.some((r) => r.trackProgress === 'full_period'),
    ),
  );

  return {
    id: target.id,
    dataType: target.dataType,
    target: getTargetField(target),
    effectiveDate: target.effectiveDate,
    updatedBy: target.updatedBy,
    updatedOn: target.updatedOn,
    groupField: target.cohortFieldName,
    outputFields: getOutputFields(target),
    groups: [
      ...target.cohorts.map((g, index) => {
        return {
          ...g,
          key: getIdentifier(undefined, true),
          groupName: g.cohortName,
          isFallback: isTargetedByGroup && index === target.cohorts.length - 1,
          targets: g.filteredCategorisations.map((fc, index) => {
            return {
              ...fc,
              isFallback: index === g.filteredCategorisations.length - 1,
              key: getIdentifier(undefined, true),
              categories: fc.categories.map((c, index) => {
                const isLastRow =
                  index === fc.categories.length - 1 &&
                  fc.categories.some((c) => c.outputs && c.outputs.length > 0);
                return toWizardCategory(c, isLastRow);
              }),
              rules: fc.rules.map((r) => {
                return {
                  ...r,
                  key: getIdentifier(undefined, true),
                };
              }),
            };
          }),
          colorIndex: index % Object.values(Colors2.AvatarColors).length,
        };
      }),
    ],
    isBelowTargetDesirable,
    isFullPeriod,
  };
};

export const transformCategory = ({
  field,
  isTrackingFullPeriod,
  category,
  categoryIndex,
  categories,
}: {
  field: string;
  isTrackingFullPeriod: boolean;
  category: Targets.Wizard.Categorisation;
  categoryIndex: number;
  categories: Targets.Wizard.Categorisation[];
}): Targets.Persisted.Categorisation => {
  const base: Targets.Persisted.Categorisation = {
    field: field,
    outputs: category.outputs.map((o): Targets.Persisted.OutputField => {
      if (o.fieldType === 'number') {
        return {
          fieldType: 'float',
          fieldValueFloat: Number.parseFloat(o.fieldValue),
          fieldName: o.fieldName,
        };
      }
      return {
        fieldType: 'string',
        fieldValueString: o.fieldValue,
        fieldName: o.fieldName,
      };
    }),
    trackProgress: isTrackingFullPeriod
      ? ('full_period' as 'full_period')
      : ('to_date' as 'to_date'),
  };

  base[category.operator] = Number.parseFloat(category.value);

  if (
    categoryIndex !== 0 &&
    categoryIndex !== categories.length - 1 &&
    categories.length > 2
  ) {
    // Ensure only one category matches any value
    const previousCategory = categories[categoryIndex - 1];
    const bandingOperator =
      category.operator === 'gte' ? ('lt' as 'lt') : ('gt' as 'gt');
    base[bandingOperator] = Number.parseFloat(previousCategory.value);
  }

  return base;
};

const toPersisted = (
  target: Targets.Wizard.DataTypeTarget,
): Targets.Persisted.DataTypeTarget => {
  return {
    id: target.id,
    dataType: target.dataType,
    effectiveDate: target.effectiveDate,
    cohortFieldName: target.groupField,
    cohorts: target.groups.map((currentG) => ({
      cohortName: currentG.groupName ? currentG.groupName : [],
      filteredCategorisations: currentG.targets.map((currentT) => ({
        rules: currentT.rules.map((currentR) => ({
          field: currentR.field,
          condition: currentR.condition,
          value: currentR.value,
        })),
        categories: currentT.categories.map((c, categoryIndex) =>
          transformCategory({
            category: c,
            field: target.target,
            isTrackingFullPeriod: target.isFullPeriod,
            categories: currentT.categories,
            categoryIndex,
          }),
        ),
      })),
    })),
    targetType: 'simple',
    categorisationFields: [],
    updatedBy: target.updatedBy,
    updatedOn: target.updatedOn,
  };
};

const dataTypeTargetTransforms = {
  toWizard,
  toPersisted,
};

export default dataTypeTargetTransforms;
