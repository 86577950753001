import React from 'react';
import Row from '../../../Common/Row';
import GroupLabel from './GroupLabel';
import TargetValueInput from './TargetValueInput';

const FallbackGroupInput = ({
  group,
}: {
  group: Targets.Wizard.TargetGroup;
}) => {
  return (
    <>
      <Row centerAlign spaceBetween>
        <Row centerAlign style={{ overflow: 'hidden' }}>
          <div style={{ paddingLeft: 55 }}>
            <GroupLabel group={group} isFallbackGroup />
          </div>
          <div
            style={{
              paddingLeft: 16,
              marginRight: 16,
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              overflow: 'hidden',
            }}
          >
            <TargetValueInput target={group.targets[0]} group={group} />
          </div>
        </Row>
      </Row>
    </>
  );
};

export default FallbackGroupInput;
