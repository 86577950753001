import React from 'react';
import FeatureGatesContext from '../../contexts/FeatureGatesContext';
import useFeatureGates from './useFeatureGates';

const FeatureGatesProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const {
    isGoalsEnabled,
    isMultipleCardsEnabled,
    isDriverBonusSummaryEnabled,
    isSelfServeEnabled,
    isMicrosoftSsoEnabled,
    isEntityDetailsEnabled,
    isActivityCostsEnabled,
    isFileUploadEnabled,
    isCustomerAwardsTrackerEnabled,
    isManualKpiPermissionManagementEnabled,
    isDriverBonusEnabled,
    isPerformanceEnabled,
    isTemplatesEnabled,
    isViewerRoleAssignmentEnabled,
    isPortalsSEPreviewEnabled,
    isPortalsUIPerUserToggleEnabled,
    isPortalsAccountWideToggleEnabled,
    isLoading,
  } = useFeatureGates();

  return (
    <FeatureGatesContext.Provider
      value={{
        isGoalsEnabled,
        isMultipleCardsEnabled,
        isDriverBonusSummaryEnabled,
        isSelfServeEnabled,
        isMicrosoftSsoEnabled,
        isEntityDetailsEnabled,
        isActivityCostsEnabled,
        isFileUploadEnabled,
        isCustomerAwardsTrackerEnabled,
        isManualKpiPermissionManagementEnabled,
        isDriverBonusEnabled,
        isPerformanceEnabled,
        isTemplatesEnabled,
        isViewerRoleAssignmentEnabled,
        isPortalsSEPreviewEnabled,
        isPortalsUIPerUserToggleEnabled,
        isPortalsAccountWideToggleEnabled,
        isLoading,
      }}
    >
      {children}
    </FeatureGatesContext.Provider>
  );
};

export default FeatureGatesProvider;
