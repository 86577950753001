import React, { useCallback, useEffect, useState } from 'react';
import Flash from './Flash';

const FlashContainer = ({
  message,
  type,
  isFlashVisible,
  setIsFlashVisible,
  isPermanent,
}: {
  isFlashVisible: boolean;
  setIsFlashVisible: React.Dispatch<React.SetStateAction<boolean>>;
  message: string;
  type: 'success' | 'info';
  isPermanent?: boolean;
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(true);

  const onVisibilityChanged = useCallback(() => {
    setIsVisible(document.visibilityState === 'visible');
  }, []);

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChanged);
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChanged);
    };
  }, [onVisibilityChanged]);

  useEffect(() => {
    if (isFlashVisible && !isPermanent && isVisible) {
      setTimeout(
        () => {
          setIsFlashVisible(false);
        },
        process.env.NODE_ENV === 'test' ? 100 : 6000,
      );
    }
  }, [isFlashVisible, isPermanent, isVisible, setIsFlashVisible]);

  const onDismissed = useCallback(() => {
    setIsFlashVisible(false);
  }, [setIsFlashVisible]);

  return (
    <Flash
      isVisible={isFlashVisible}
      message={message}
      type={type}
      onDismissed={onDismissed}
    />
  );
};

export default FlashContainer;
