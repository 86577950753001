import * as _ from 'lodash';
import buildBaseFieldViewDefault from './buildBaseFieldViewDefault';

const buildBaseView = ({
  persistedBaseView,
  dataset,
}: {
  persistedBaseView?: FleetOps.BaseView;
  dataset: FleetOps.DatasetDefinition;
}): FleetOps.BaseView => {
  const base = persistedBaseView
    ? { ...persistedBaseView }
    : {
        version: 1,
        type: dataset.type,
        fields: {},
        fieldOrder: [],
        nameAlias: dataset.name ? dataset.name : undefined,
        isHidden: process.env.NODE_ENV === 'test' ? false : true,
      };
  base.description = dataset.description;

  // Auto generate fields which are not persisted yet
  const fieldsToAdd = dataset.fields.filter(
    (f) => !Object.keys(base.fields).includes(f.field),
  );
  fieldsToAdd.forEach((field) => {
    const baseFieldView = buildBaseFieldViewDefault(field, dataset);
    base.fields[field.field] = baseFieldView;
    base.fieldOrder = _.uniq([...base.fieldOrder, field.field]);
  });

  // Catch any fields on the BV not found on the fieldOrder
  Object.keys(base.fields)
    .filter((field) => !base.fieldOrder.includes(field))
    .forEach((f) => {
      base.fieldOrder = _.uniq([...base.fieldOrder, f]);
    });

  // Remove fields which are no longer on the data set
  const fieldsToRemove = Object.keys(base.fields).filter(
    (persistedField) =>
      !dataset.fields.find((df) => df.field === persistedField),
  );

  fieldsToRemove.forEach((field) => {
    delete base.fields[field];
    base.fieldOrder = base.fieldOrder.filter((f) => f !== field);
  });

  return base;
};

export default buildBaseView;
