import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import FavouritesContext from 'contexts/FavouritesContext';
import CurrentUserContext from 'contexts/CurrentUserContext';
import AccountPickerContext from 'contexts/AccountPickerContext';
import setFavouritesOrder from 'api/setFavouritesOrder';
import { buildContentViewIdAlt } from 'buildContentViewId';
import { sortFavourites } from 'hooks/useFavourites';
import FavouriteItemContainer from './FavouriteItem';

const FavoritesListWrap = styled.div`
  flex: 1;
  width: 100%;
  padding: 8px 10px;
  overflow-y: auto;
`;

const FavoritesList = ({
  filteredFavorites,
}: {
  filteredFavorites: ContentView[];
}) => {
  const { favouritesOrder, setFavourites } = useContext(FavouritesContext);
  const currentUser = useContext(CurrentUserContext);
  const { accountRef } = useContext(AccountPickerContext);

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const { draggableId, destination } = result;
      if (!destination) {
        return;
      }

      const favoritesWithoutDropped = favouritesOrder.filter(
        (f) => f !== draggableId,
      );
      const theFavourite = favouritesOrder.find((f) => f === draggableId);
      if (!theFavourite) {
        throw new Error('Item not found');
      }

      const newOrder = [
        ...favoritesWithoutDropped.slice(0, destination.index),
        theFavourite,
        ...favoritesWithoutDropped.slice(
          destination.index,
          favoritesWithoutDropped.length,
        ),
      ];

      setFavouritesOrder({
        order: newOrder,
        userId: currentUser.id,
        accountRef,
      });
      setFavourites(
        sortFavourites({
          favourites: filteredFavorites,
          order: newOrder,
        }),
      );
    },
    [
      accountRef,
      currentUser.id,
      favouritesOrder,
      filteredFavorites,
      setFavourites,
    ],
  );

  return (
    <FavoritesListWrap>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={'favorites-drop'}>
          {(provided) => (
            <div ref={provided.innerRef}>
              {filteredFavorites.map((item, index) => (
                <Draggable
                  draggableId={buildContentViewIdAlt({
                    type: item.type,
                    typeId: item.typeId,
                  })}
                  index={index}
                  key={item.typeId}
                >
                  {(provided) => (
                    <FavouriteItemContainer provided={provided} item={item} />
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </FavoritesListWrap>
  );
};

export default FavoritesList;
