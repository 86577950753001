import React from 'react';

interface NewCommitSlideOutContextType {
  draftCommit: Commitment;
  setDraftCommit: React.Dispatch<React.SetStateAction<Commitment>>;
  hasManualLineHaulCharge: boolean;
  setHasManualLineHaulCharge: React.Dispatch<React.SetStateAction<boolean>>;
  showWarnings: boolean;
}

const NewCommitSlideOutContext = React.createContext(
  {} as NewCommitSlideOutContextType,
);

export default NewCommitSlideOutContext;
