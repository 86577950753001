import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Colors from '../../../theme/colors';
import HighchartStyles from '../../HighchartStyles';
import RenderWhenInView from '../../Comments/RenderWhenInView';
import chartDefinitionPropType from '../../../propTypes/chartDefinition';
import WidgetPreview from '../WidgetPreview';

const selectedStyles = css`
  border: 1px solid ${Colors.NAVY_BLUE};
`;

const IsOnDashboardMarker = styled.div`
  position: absolute;
  bottom: 16px;
  left: 16px;
  width: 18px;
  height: 18px;
  border-radius: 9px;
  background-color: ${Colors.OUTRAGEOUS_ORANGE};
`;

const Item = styled.div`
  position: relative;
  background-color: ${Colors.WHITE};
  width: calc(50% - 16px);
  height: 230px;

  border-radius: 2px;
  box-shadow:
    0 0 0 1px rgba(63, 63, 68, 0.07),
    0 1px 2px 0 rgba(63, 63, 68, 0.12),
    0 1px 1px 0 rgba(63, 63, 68, 0.07);

  display: inline-block;
  margin: 5px 8px;

  cursor: pointer;
  border: 1px solid transparent;
  ${(props) => props.selected && selectedStyles};
  &:hover {
    opacity: 0.9;
  }
`;

const List = styled.div`
  overflow-y: auto;

  border-bottom: 1px solid ${Colors.GAINSBORO};
`;

const ListInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;
`;

const WidgetList = ({
  definitions,
  style,
  toggleDefinitionSelected,
  selectedDefinitions,
  definitionsOnDashboard,
}) => (
  <HighchartStyles>
    <List className="hiding-scrollbar" style={style}>
      <ListInner>
        {definitions.map((def) => (
          <Item
            onClick={() => toggleDefinitionSelected(def.id)}
            selected={selectedDefinitions.includes(def.id)}
            key={def.id}
          >
            <RenderWhenInView>
              <WidgetPreview definition={def} />
            </RenderWhenInView>
            {definitionsOnDashboard.includes(def.id) && <IsOnDashboardMarker />}
          </Item>
        ))}
      </ListInner>
    </List>
  </HighchartStyles>
);

WidgetList.propTypes = {
  definitions: PropTypes.arrayOf(chartDefinitionPropType),
  selectedDefinitions: PropTypes.arrayOf(PropTypes.string),
  definitionsOnDashboard: PropTypes.arrayOf(PropTypes.string),
  toggleDefinitionSelected: PropTypes.func,
  style: PropTypes.object,
};

export default WidgetList;
