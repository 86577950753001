import React from 'react';
import Tooltip from '../Tooltip';
import Icon from '../../kingpin/atoms/Icon';

import styled from 'styled-components';
import Colors2 from '../../theme/Colors2';

const IconSize = styled.div`
  display: flex;
  svg {
    width: 16px;
    height: 16px;
  }
`;

const InfoIcon = ({
  tooltip,
  isGrey,
}: {
  tooltip?: string;
  isGrey?: boolean;
}) => (
  <div style={{ cursor: 'help' }}>
    <Tooltip content={tooltip}>
      <IconSize>
        <Icon icon={'info'} color={isGrey ? '#828282' : '#352D43'} />
      </IconSize>
    </Tooltip>
  </div>
);

export const InfoIconNoTooltip = ({
  testId,
  isSmall,
}: {
  testId?: string;
  isSmall?: boolean;
}) => (
  <div data-testid={testId} style={{ cursor: 'help', display: 'flex' }}>
    <Icon
      icon={'info'}
      color={isSmall ? Colors2.Grey['6'] : '#828282'}
      width={isSmall ? 14 : undefined}
      height={isSmall ? 14 : undefined}
    />
  </div>
);

export default InfoIcon;
