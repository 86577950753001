import React from 'react';
import styled from 'styled-components';

import Loading from 'components/Loading';
import Row from 'components/Common/Row';
import Inputs from 'components/Inputs';
import Badge from 'components/Badge';
import Colors2 from 'theme/Colors2';
import Typography from 'kingpin/atoms/Typography';
import Button from 'kingpin/atoms/Button';

import useFilteredUsers from '../hooks/useFilteredUsers';
import UsersLosingAccessWarning from './UsersLosingAccessWarning';
import Tooltip from '../../../../../components/Tooltip';
import TextInput from '../../../../../kingpin/atoms/TextInput';

const Grid = styled.div`
  border: 1px solid #e9e9e9;
  border-top: 0px;
  border-radius: 0px 0px 6px 6px;
  margin-bottom: 16px;
  overflow-y: scroll;
`;

const HeadersDiv = styled.div`
  border: 1px solid #e9e9e9;
  border-radius: 6px 6px 0px 0px;
  padding: 2px 24px 0px 24px;
  background-color: ${Colors2.Grey['9']};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const GROUPS_WIDTH = 300;

const Headers = () => (
  <HeadersDiv>
    <Row style={{ flex: 1, height: 40 }} centerAlign>
      <div style={{ flex: 1 }}>
        <Typography.Body type="Body 12">Email</Typography.Body>
      </div>
      <div style={{ flex: 1 }}>
        <Typography.Body type="Body 12">Name</Typography.Body>
      </div>
      <div style={{ flex: 1 }} />
    </Row>
    <Row style={{ width: GROUPS_WIDTH, height: 40 }} centerAlign>
      <Typography.Body type="Body 12">Group</Typography.Body>
    </Row>
  </HeadersDiv>
);

const UserRow = ({
  user,
  isLastRow,
}: {
  user: MicrosoftSso.UserWithMemberships;
  isLastRow: boolean;
}) => {
  const groupText =
    user.groupMemberships.length === 1
      ? user.groupMemberships[0].displayName
      : `${user.groupMemberships.length} Groups`;

  return (
    <Row
      spaceBetween
      centerAlign
      style={{
        height: 40,
        borderBottom: isLastRow ? undefined : 'solid 1px #E9E9E9',
        padding: '0px 24px',
      }}
    >
      <Row style={{ flex: 1 }}>
        <div style={{ flex: 1 }}>
          <Typography.Body type="Body 12">{user.email}</Typography.Body>
        </div>
        <div style={{ flex: 1 }}>
          <Typography.Body type="Body 12">{user.displayName}</Typography.Body>
        </div>
        <div style={{ flex: 1 }} />
      </Row>
      <div style={{ width: GROUPS_WIDTH }}>
        <Badge text={groupText} badgeType="Default" />
      </div>
    </Row>
  );
};

const UsersGrid = ({
  selectedGroups,
}: {
  selectedGroups: MicrosoftSso.Group[];
}) => {
  const {
    filteredUsers,
    searchText,
    onSearchTextChanged,
    groupFilterOptions,
    isLoading,
    users,
    refreshUsers,
  } = useFilteredUsers();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div
      style={{
        marginTop: 16,
        overflow: 'hidden',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <UsersLosingAccessWarning
        permittedUsers={users}
        selectedGroups={selectedGroups}
      />
      <Row spaceBetween centerAlign style={{ marginBottom: 16, marginTop: 8 }}>
        <Row centerAlign>
          <div style={{ marginRight: 8 }}>
            <Inputs.Dropdown options={groupFilterOptions} />
          </div>
          <TextInput
            value={searchText}
            onChange={onSearchTextChanged}
            placeholder="Search Users"
            autoFocus={false}
            icon="search"
          />
        </Row>
        <Tooltip
          content={
            'If you do not see your changes after refreshing this list,' +
            ' please try again in 15 seconds.'
          }
        >
          <Button
            type="Tertiary"
            size="Small"
            onClick={refreshUsers}
            label="Refresh Preview"
            icon="refresh"
          />
        </Tooltip>
      </Row>

      {selectedGroups.length > 0 && (
        <>
          <Headers />
          <Grid>
            {filteredUsers.map((u, index) => (
              <UserRow
                user={u}
                key={u.email}
                isLastRow={index === filteredUsers.length - 1}
              />
            ))}
          </Grid>
        </>
      )}
      {selectedGroups.length === 0 && (
        <div style={{ padding: 32 }}>
          <Typography.Header type="H5">No groups added yet</Typography.Header>
        </div>
      )}
    </div>
  );
};

export default UsersGrid;
