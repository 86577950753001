import React from 'react';
import Typography from 'kingpin/atoms/Typography';
import styled from 'styled-components';
import Card from '../../../../Common/Card';
import Button from '../../../../../kingpin/atoms/Button';
import Colors2 from '../../../../../theme/Colors2';

const ReasonCodeElement = styled(Card)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  padding: 0 16px;
  height: 42px;
  background-color: white;
  border: 1px solid ${Colors2.Border};
  justify-content: space-between;
`;

const ReasonCodesListItem = ({
  code,
  handleRemove,
}: {
  code: ReasonCode;
  handleRemove: () => void;
}) => {
  return (
    <ReasonCodeElement>
      <Typography.Body type="Body 12">{code.code}</Typography.Body>
      <Button
        size={'Small'}
        type={'Secondary'}
        icon="cross"
        onClick={handleRemove}
      />
    </ReasonCodeElement>
  );
};

export default ReasonCodesListItem;
