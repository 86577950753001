import React from 'react';

import FilterPlates from 'components/MetricsInfoButton/MetricInfoPopup/FilterPlates';
import DetailsTabItem from './DetailsTabItem';

const Filters = ({ filters }: { filters: FilterInput }) => {
  if (Object.keys(filters).length) {
    return (
      <>
        <DetailsTabItem header="Filters">
          <FilterPlates filters={filters} />
        </DetailsTabItem>
      </>
    );
  }
  return null;
};

export default Filters;
