export const toInterval = (
  autoInterval: string | undefined,
): 'hour' | 'day' | 'week' | 'month' | 'year' | undefined => {
  if (autoInterval === undefined) {
    return undefined;
  }

  if (/h/.exec(autoInterval)) {
    return 'hour';
  } else if (/7d/.exec(autoInterval)) {
    return 'week';
  } else if (/d/.exec(autoInterval)) {
    return 'day';
  } else if (/w/.exec(autoInterval)) {
    return 'week';
  } else if (/M/.exec(autoInterval)) {
    return 'month';
  } else if (/Y/.exec(autoInterval)) {
    return 'year';
  } else {
    return undefined;
  }
};

const castToWeek = (aI: AutoInterval): AutoInterval => {
  if (aI.interval === 'day' && aI.numIntervals === 7) {
    return {
      interval: 'week',
      numIntervals: 1,
    };
  }
  return aI;
};

const toAutoInterval = (
  autoIntervalResponse?: string,
): AutoInterval | undefined => {
  const i = toInterval(autoIntervalResponse);
  if (i === undefined || autoIntervalResponse === undefined) {
    return undefined;
  }
  const n = /\d/.exec(autoIntervalResponse);
  if (!i || !n) {
    return undefined;
  }

  const numIntervals = Number.parseInt(n[0]);
  return castToWeek({
    interval: i,
    numIntervals,
  });
};

export default toAutoInterval;
