import React from 'react';
import { FleetopsGridCellRendererParams } from '../types/agGrid';
import Button from '../kingpin/atoms/Button';
import Cell from './V5Gadget/Matrix/Cell';
import GridCellErrorBoundary from './GridCellErrorBoundary';
import EntityLink from './V5Gadget/Matrix/GroupingCellRenderer/EntityLink';
import Row from './Common/Row';
import Tooltip from './Tooltip';

const CustomerWithHoverMenuRenderer = (
  params: FleetopsGridCellRendererParams,
) => {
  const customer = params.data.customer
    ? params.data.customer
    : params.data.billTo;
  const isTotalRow =
    params.node.rowPinned && params.node.rowPinned === 'bottom';

  const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const rowNode = params.node;
    const column = params.column;
    const value = params.value;

    // @ts-ignore
    params.api.menuService.contextMenuFactory.showMenu(
      rowNode,
      column,
      value,
      event,
    );
  };

  if (isTotalRow) {
    return <Cell>{customer}</Cell>;
  }
  return (
    <Cell>
      <Row centerAlign spaceBetween>
        <Row centerAlign>
          <div className="ag-row-show-on-hover" style={{ marginRight: 8 }}>
            <Button
              onClick={onClick}
              icon={'dots-menu'}
              size={'Small'}
              type={'Ghost'}
            />
          </div>
          <Tooltip content={customer}>
            <span>{customer}</span>
          </Tooltip>
        </Row>
        <EntityLink
          colDef={params.colDef}
          value={customer}
          node={params.node}
        />
      </Row>
    </Cell>
  );
};

const Gate = (params: FleetopsGridCellRendererParams) => (
  <GridCellErrorBoundary
    params={params}
    childComponent={<CustomerWithHoverMenuRenderer {...params} />}
  />
);

export default Gate;
