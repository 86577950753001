const aguid = require('aguid');

export const MOCK_IDENTIFIER = 'mock-identifier';

const getIdentifier = (determinedBy?: string, ignoreTestEnv?: boolean) => {
  if (process.env.NODE_ENV === 'test' && !ignoreTestEnv) {
    return MOCK_IDENTIFIER;
  }

  return aguid(determinedBy);
};

export default getIdentifier;
