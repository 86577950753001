import React from 'react';
import Loading from '../../Loading';
import Comment from '../Comment';
import styled from 'styled-components';

const List = styled.div<{ noScroll?: boolean }>`
  overflow-y: auto;
  padding-right: 12px;
  max-height: ${(props) => (props.noScroll ? 'unset' : '300px')};
`;

const CommentList = ({
  comments,
  isLoading,
  noScroll,
}: {
  comments: CommentType[];
  isLoading: boolean;
  noScroll?: boolean;
}) => (
  <div>
    {isLoading && <Loading isSmall />}
    {!isLoading && (
      <List noScroll={noScroll}>
        {comments.map((c) => (
          <Comment key={c.id} comment={c} />
        ))}
      </List>
    )}
  </div>
);

export default CommentList;
