import { useContext, useEffect, useState } from 'react';
import BaseViewsContext from '../../../../contexts/BaseViewsContext';
import isDefined from '../../../../isDefined';
import useGetDatasetLabel from '../../MetricPicker/MetricPickerPopup/hooks/useGetDatasetLabel';
import useGetDatasetDefinition from '../../../../hooks/useGetDatasetDefinition';

const useDataTypeOptions = (
  datasetsGreenList?: string[],
  isPerformanceDatasetsAllowed?: boolean,
) => {
  const getDatasetLabel = useGetDatasetLabel();
  const [selectedDataType, setSelectedDataType] = useState<
    string | undefined
  >();
  const { baseViews } = useContext(BaseViewsContext);
  const [dataTypeOptions, setDataTypeOptions] = useState<DropdownOption[]>([]);
  const [allowedDataTypes, setAllowedDataTypes] = useState<string[]>([]);
  const getDatasetDefinition = useGetDatasetDefinition();

  // setAllowedDataTypes
  useEffect(() => {
    const filteredBaseViews = Object.values(baseViews)
      .filter(isDefined)
      .filter((bv) => {
        return !bv.isHidden;
      })
      .filter((bv) => {
        if (isPerformanceDatasetsAllowed) {
          return true;
        }
        const ds = getDatasetDefinition(bv.type);
        const isPerformanceDataset = ds ? ds.isPerformance : false;
        return !isPerformanceDataset;
      });

    setAllowedDataTypes(
      filteredBaseViews
        .map((bv) => bv.type)
        .filter((ds) => {
          if (!datasetsGreenList) {
            return true;
          }

          return datasetsGreenList.includes(ds);
        }),
    );
  }, [
    baseViews,
    datasetsGreenList,
    getDatasetDefinition,
    isPerformanceDatasetsAllowed,
  ]);

  // setDataTypeOptions
  useEffect(() => {
    const newOptions = allowedDataTypes.map((dataType) => ({
      label: getDatasetLabel(dataType),
      isSelected: selectedDataType === dataType,
      onSelected: () => {
        setSelectedDataType(dataType);
      },
    }));

    setDataTypeOptions([
      {
        label: 'All Dataset Fields',
        isSelected: selectedDataType === undefined,
        onSelected: () => {
          setSelectedDataType(undefined);
        },
      },
      ...newOptions,
    ]);
  }, [allowedDataTypes, baseViews, getDatasetLabel, selectedDataType]);

  return {
    selectedDataType,
    dataTypeOptions,
    allowedDataTypes,
  };
};

export default useDataTypeOptions;
