import React from 'react';
import styled from 'styled-components';
import Colors2 from '../../../theme/Colors2';
import { OPTION_WIDTH } from './Option';

const OuterDiv = styled.div`
  width: ${OPTION_WIDTH}px;
  padding: 8px 0px;
`;

const Divider = styled.div`
  height: 1px;
  width: ${OPTION_WIDTH}px;
  background-color: ${Colors2.Grey['9']};
`;

const Break = () => {
  return (
    <OuterDiv>
      <Divider />
    </OuterDiv>
  );
};

export default Break;
