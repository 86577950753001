import useQueryParams from './useQueryParams';
import { useCallback, useContext, useEffect, useState } from 'react';
import { CurrentUserContentSettingsContext } from '../contextProviders/CurrentUserContentSettingsProvider';

const useIsEngagementMode = () => {
  const { contentSettings } = useContext(CurrentUserContentSettingsContext);

  const urlParams = useQueryParams();
  const [isSimpleViewDemo] = useState<boolean>(() => urlParams.has('d'));

  const getIsEngagementMode = useCallback(() => {
    return contentSettings.mode === 'engagement';
  }, [contentSettings.mode]);
  const [isEngagementMode, setIsEngagementMode] = useState<boolean>();
  useEffect(() => {
    setIsEngagementMode(getIsEngagementMode());
  }, [getIsEngagementMode]);

  return isEngagementMode || isSimpleViewDemo;
};

export default useIsEngagementMode;
