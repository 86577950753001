import React from 'react';
import Row from '../../../../components/Common/Row';

const TopBarInner = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => (
  <Row style={{ height: 32 }} centerAlign spaceBetween>
    {children}
  </Row>
);

export default TopBarInner;
