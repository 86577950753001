import React, { ChangeEvent } from 'react';
import styled from 'styled-components';

import buildCustomerSearchItemLabel from './buildCustomerSearchItemLabel';
import Results from './Results';
import Icon from '../../../../kingpin/atoms/Icon';
import Colors2 from '../../../../theme/Colors2';
import TextInput from '../../../../kingpin/atoms/TextInput';

export const CommitmentQueryInputButtonDiv = styled.div`
  display: flex;
  border-radius: 8px;
  font-family: Inter;
  font-size: 13px;
  height: 32px;
  font-style: normal;
  font-weight: 400;
  align-items: center;
  justify-content: space-between;

  gap: 8px;
  padding: 3px 12px;

  border: 1px solid #e0e0e0;
  box-shadow:
    0px 3px 2px -1px rgba(0, 0, 0, 0.02),
    0px 1px 1px -1px rgba(0, 0, 0, 0.04);
`;

const CustomerSearch = ({
  isFilled,
  searchResults,
  searchText,
  onSearchTextChanged,
  onSearchFocus,
  onSearchBlur,
  isLoading,
  onRemove,
  selectedResult,
  onItemSelected,
  isOpen,
  close,
  isDisabled,
}: {
  isFilled: boolean;
  searchResults: CustomerSearchResult[];
  searchText: string;
  onSearchTextChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  onSearchFocus: () => void;
  onSearchBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  isLoading: boolean;
  onRemove: () => void;
  selectedResult?: CustomerSearchResult;
  onItemSelected: (item: CustomerSearchResult) => void;
  isOpen: boolean;
  close: () => void;
  isDisabled?: boolean;
}) => (
  <React.Fragment>
    {isFilled && (
      <CommitmentQueryInputButtonDiv
        style={{
          width: '100%',
          minWidth: 158,
          cursor: isDisabled ? 'not-allowed' : 'auto',
        }}
      >
        {selectedResult && buildCustomerSearchItemLabel(selectedResult)}
        {!isDisabled && (
          <div
            style={{ padding: 4, marginTop: 3, cursor: 'pointer' }}
            onClick={onRemove}
          >
            <Icon icon="cross" color={Colors2.Grey['4']} />
          </div>
        )}
      </CommitmentQueryInputButtonDiv>
    )}
    {!isFilled && (
      <div style={{ width: '100%' }}>
        <TextInput
          value={searchText}
          onChange={onSearchTextChanged}
          isLoading={isLoading}
          placeholder="Customer"
          inputSize="Small"
          icon="search"
          onFocus={onSearchFocus}
          onBlur={onSearchBlur}
        />

        {isOpen && (
          <Results
            close={close}
            onItemSelected={onItemSelected}
            searchResults={searchResults}
            hasResults={searchResults.length > 0}
          />
        )}
      </div>
    )}
  </React.Fragment>
);

export default CustomerSearch;
