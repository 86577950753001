import { useCallback, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import PerformanceWizardContext from '../PerformanceWizardContext';
import MetricOptionsContext from '../../../../../../contexts/MetricOptionsContext';
import DatasetDefinitionsContext from '../../../../../../contexts/DatasetDefinitionsContext';
import isDefined from '../../../../../../isDefined';
import metricTypeCheckers from '../../../../../../types/metricTypeCheckers';
import useGetIsExistingMetric from './PerformanceMetricItem/useGetIsExistingMetric';
import { PERFORMANCE_FIELD_TYPE_RED_LIST } from '../FieldsStep/AddFieldsButton';

const NON_ALPHA_REGEX = /[^a-zA-Z]/g;

const usePossibleDatasetFields = () => {
  const { wizardState } = useContext(PerformanceWizardContext);
  const { datasets } = useContext(DatasetDefinitionsContext);
  const getPossibleDataFields = useCallback(() => {
    const existingDataset = datasets.find(
      (ds) => ds.type === wizardState.config.datasetStep.name,
    );

    return [existingDataset, ...datasets]
      .filter(isDefined)
      .filter((ds) => !ds.isPerformance)
      .map((dataset) =>
        dataset.fields.filter((f) => {
          if (PERFORMANCE_FIELD_TYPE_RED_LIST.includes(f.type)) {
            return false;
          }
          return true;
        }),
      )
      .reduce((a, b) => [...a, ...b], []);
  }, [datasets, wizardState.config.datasetStep.name]);

  const [possibleDatasetFields, setPossibleDatasetFields] = useState<
    FleetOps.Field[]
  >(() => getPossibleDataFields());

  useEffect(() => {
    setPossibleDatasetFields(getPossibleDataFields());
  }, [getPossibleDataFields]);

  return possibleDatasetFields;
};

const useGetIsMetricNameValid = () => {
  const { wizardState } = useContext(PerformanceWizardContext);
  const { metricOptionsLookup } = useContext(MetricOptionsContext);
  const possibleDatasetFields = usePossibleDatasetFields();
  const getIsExistingMetric = useGetIsExistingMetric();

  const getMetricField = useCallback(
    (pm: PerformanceConfiguration.Client.PerformanceMetric) => {
      if (pm.fieldName && pm.fieldName !== '') {
        return {
          isDefaultFieldName: false,
          fieldName: pm.fieldName,
        };
      }

      const m = metricOptionsLookup[pm.metricId];
      if (!m) {
        return {
          isDefaultFieldName: true,
          fieldName: '',
        };
      }
      if (metricTypeCheckers.isNormalMetric(m)) {
        return {
          isDefaultFieldName: true,
          fieldName: m.field,
        };
      }

      return {
        isDefaultFieldName: true,
        fieldName: m.name,
      };
    },
    [metricOptionsLookup],
  );

  const getIsOnlyMetricForField = useCallback(
    ({
      pMetric,
      otherMetrics,
    }: {
      pMetric: PerformanceConfiguration.Client.PerformanceMetric;
      otherMetrics: PerformanceConfiguration.Client.PerformanceMetric[];
    }) => {
      const metric = metricOptionsLookup[pMetric.metricId];
      if (!metric) {
        return false;
      }
      if (!metricTypeCheckers.isNormalMetric(metric)) {
        return true;
      }

      return !otherMetrics
        .map((m) => metricOptionsLookup[m.metricId])
        .filter(metricTypeCheckers.isNormalMetric)
        .some((m) => m.field === metric.field);
    },
    [metricOptionsLookup],
  );

  const getIsUnique = useCallback(
    ({
      pMetric,
      otherMetrics,
    }: {
      pMetric: PerformanceConfiguration.Client.PerformanceMetric;
      otherMetrics: PerformanceConfiguration.Client.PerformanceMetric[];
    }) =>
      !otherMetrics.some(
        (m) =>
          getMetricField(m).fieldName === getMetricField(pMetric).fieldName,
      ),
    [getMetricField],
  );

  const getIsSanitized = useCallback(
    ({
      metric,
    }: {
      metric: PerformanceConfiguration.Client.PerformanceMetric;
    }) => {
      return !NON_ALPHA_REGEX.test(getMetricField(metric).fieldName);
    },
    [getMetricField],
  );

  const getIsCamelCase = useCallback(
    ({
      metric,
    }: {
      metric: PerformanceConfiguration.Client.PerformanceMetric;
    }) => {
      return (
        getIsSanitized({ metric }) &&
        _.camelCase(getMetricField(metric).fieldName) ===
          getMetricField(metric).fieldName
      );
    },
    [getIsSanitized, getMetricField],
  );

  const getHasFieldConflict = useCallback(
    ({
      metric,
    }: {
      metric: PerformanceConfiguration.Client.PerformanceMetric;
    }) => {
      const { fieldName } = getMetricField(metric);

      return possibleDatasetFields.some(
        (field) =>
          field.field === fieldName &&
          !PERFORMANCE_FIELD_TYPE_RED_LIST.includes(field.type),
      );
    },
    [getMetricField, possibleDatasetFields],
  );

  const getIsMetricNameValid = useCallback(
    (performanceMetric: PerformanceConfiguration.Client.PerformanceMetric) => {
      const { fieldName, isDefaultFieldName } =
        getMetricField(performanceMetric);
      const isExistingMetric = getIsExistingMetric(performanceMetric);
      if (isExistingMetric) {
        return {
          isValid: true,
          error: '',
        };
      }

      const otherMetrics = wizardState.config.metricsStep.metrics.filter(
        (m) => m.key !== performanceMetric.key,
      );

      const hasFieldConflict = getHasFieldConflict({
        metric: performanceMetric,
      });
      if (hasFieldConflict) {
        return {
          isValid: false,
          error: isDefaultFieldName
            ? `This field is reserved (Defaulting to ${fieldName})`
            : `This field is reserved`,
        };
      }

      const isOnlyMetricForField = getIsOnlyMetricForField({
        pMetric: performanceMetric,
        otherMetrics,
      });
      if (
        isOnlyMetricForField &&
        (!performanceMetric.fieldName || performanceMetric.fieldName === '')
      ) {
        return {
          isValid: true,
          error: '',
        };
      }

      const isUniq = getIsUnique({ pMetric: performanceMetric, otherMetrics });
      if (!isUniq) {
        return {
          isValid: false,
          error: isDefaultFieldName
            ? `Field is not unique (Defaulting to ${fieldName})`
            : 'Field is not unique',
        };
      }

      const isSanitized = getIsSanitized({ metric: performanceMetric });
      if (!isSanitized) {
        return {
          isValid: false,
          error:
            'Invalid characters found. Please only use letters from the' +
            ' english alphabet.',
        };
      }

      const isCamelCase = getIsCamelCase({ metric: performanceMetric });
      if (!isCamelCase) {
        return {
          isValid: false,
          error: 'Field must be in camelCase format',
        };
      }

      return {
        isValid: true,
        error: '',
      };
    },
    [
      getHasFieldConflict,
      getIsCamelCase,
      getIsExistingMetric,
      getIsOnlyMetricForField,
      getIsSanitized,
      getIsUnique,
      getMetricField,
      wizardState.config.metricsStep.metrics,
    ],
  );

  return getIsMetricNameValid;
};

export default useGetIsMetricNameValid;
