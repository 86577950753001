import React, { ChangeEvent } from 'react';
import Button from 'kingpin/atoms/Button';
import Typography from 'kingpin/atoms/Typography';
import Form from 'kingpin/forms/Form';

import Row from '../../../components/Common/Row';
import Inputs from '../../../components/Inputs';
import MultiUserSelect from '../../../components/MultiUserSelect';
import ScoringBandPicker from './ScoringBandPicker';
import ScorecardUsageWarning from '../../../components/Scorecard/ScorecardUsageWarning';
import WeekStartsOnOverrideSelector from '../../../components/Report/WeekStartsOnOverrideSelector';
import FormHeader from '../../../kingpin/forms/FormHeader';
import FormContent from '../../../kingpin/forms/FormContent';
import Colors2 from 'theme/Colors2';

const ScorecardForm = ({
  isEditing,
  close,
  title,
  onTitleChanged,
  assigneeId,
  setAssigneeId,
  cadence,
  cadenceOptions,
  startDateLabel,
  startDateOptions,
  scoringBandId,
  setScoringBandId,
  isSaving,
  onSaveClicked,
  isValid,
  scorecard,
  isCopyingScorecard,
  weekStartsOnOverride,
  setWeekStartsOnOverride,
  todayOption,
}: {
  isEditing: boolean;
  close: () => void;
  title: string;
  onTitleChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  assigneeName?: string;
  assigneeId: string[];
  setAssigneeId: React.Dispatch<React.SetStateAction<string[]>>;
  cadence: 'week' | 'month';
  cadenceOptions: RadioOption[];
  startDateLabel?: string;
  startDateOptions: DropdownOption[];
  scoringBandId: Scorecards.ScoringBandId;
  setScoringBandId: React.Dispatch<
    React.SetStateAction<Scorecards.ScoringBandId>
  >;
  isSaving: boolean;
  onSaveClicked: () => void;
  isValid: boolean;
  scorecard?: Scorecards.Scorecard;
  isCopyingScorecard?: boolean;
  weekStartsOnOverride?: WeekStartsOn;
  setWeekStartsOnOverride: React.Dispatch<
    React.SetStateAction<WeekStartsOn | undefined>
  >;
  todayOption?: DropdownOption;
}) => (
  <Form>
    <FormHeader
      title={
        isCopyingScorecard
          ? 'Copy KPI List'
          : isEditing
            ? 'Edit KPI List'
            : 'Create KPI List'
      }
      onClose={close}
    />
    {scorecard && !isCopyingScorecard && (
      <ScorecardUsageWarning scorecard={scorecard} />
    )}
    <FormContent>
      <div style={{ marginBottom: 16 }}>
        <div style={{ marginBottom: 4 }}>
          <Typography.Body type="Label">
            What should the KPI List be called?
          </Typography.Body>
        </div>

        <Inputs.TextInput
          value={title}
          onChange={onTitleChanged}
          data-testid="scorecard-title"
          inputSize="Small"
        />
      </div>
      <div style={{ marginBottom: 16 }}>
        <div style={{ marginBottom: 4 }}>
          <Typography.Body type="Label">
            Assignee{' '}
            <span style={{ color: Colors2.Grey['5'] }}>(optional)</span>
          </Typography.Body>
        </div>
        <MultiUserSelect
          selectedUsers={assigneeId}
          setSelectedUsers={setAssigneeId}
          isOnlyMarkStaff
        />
      </div>
      {!isCopyingScorecard && (
        <>
          <div style={{ marginBottom: 16 }}>
            <div style={{ marginBottom: 4 }}>
              <Typography.Body type="Label">Select interval</Typography.Body>
            </div>
            <Inputs.Radio options={cadenceOptions} />
          </div>
          {cadence === 'week' && (
            <div style={{ marginBottom: 16 }}>
              <div style={{ marginBottom: 4 }}>
                <Typography.Body type="Label">Reporting Week</Typography.Body>
              </div>
              <WeekStartsOnOverrideSelector
                setWeekStartsOnOverride={setWeekStartsOnOverride}
                weekStartsOnOverride={weekStartsOnOverride}
                isLabelDisabled
                testId="scorecard-reporting-week"
              />
            </div>
          )}
          <div style={{ marginBottom: 16 }}>
            <div style={{ marginBottom: 4 }}>
              <Typography.Body type="Label">Start from</Typography.Body>
            </div>
            <Inputs.Dropdown
              placeholder="Select interval"
              options={startDateOptions}
              selectedLabel={startDateLabel}
              unsetWidth
              testId={'start-from'}
              defaultScrollToLabel={todayOption ? todayOption.label : undefined}
            />
          </div>
          <div style={{ marginBottom: 16 }}>
            <div style={{ marginBottom: 4 }}>
              <Typography.Body type="Label">
                Select color bands for targets
              </Typography.Body>
            </div>

            <ScoringBandPicker
              scoringBandId={scoringBandId}
              setScoringBandId={setScoringBandId}
            />
          </div>
        </>
      )}
    </FormContent>
    <Row centerAlign spaceBetween>
      <div />
      <Row centerAlign>
        <div style={{ marginRight: 8 }}>
          <Button
            type="Tertiary"
            size="Small"
            label={'Cancel'}
            onClick={close}
            isDisabled={isSaving}
            isLoading={isSaving}
          />
        </div>

        <Button
          type="Primary"
          size="Small"
          onClick={onSaveClicked}
          isLoading={isSaving}
          isDisabled={isSaving || !isValid}
          label={isCopyingScorecard ? 'Copy' : isEditing ? 'Save' : 'Create'}
        />
      </Row>
    </Row>
  </Form>
);

export default ScorecardForm;
