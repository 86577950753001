import React from 'react';

interface DriverScoreContextType {
  driverScore: ElasticDocument;
  setDriverScore: React.Dispatch<
    React.SetStateAction<ElasticDocument | undefined>
  >;
}

const DriverScoreContext = React.createContext<DriverScoreContextType>({
  driverScore: {},
  // @ts-ignore
  setDriverScore: () => {
    console.error('DriverScoreContext.setDriverScore is not implemented');
  },
});

export default DriverScoreContext;
