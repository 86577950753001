import React from 'react';
import styled from 'styled-components';
import Icon from 'kingpin/atoms/Icon';
import Colors2 from 'theme/Colors2';

const OnboardingTopBarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100%;
  height: 52px;
  border-bottom: 1px solid ${Colors2.Border};
`;

const OnboardingTopBar = () => {
  return (
    <OnboardingTopBarWrapper>
      <Icon icon={'branding-large'} width={98} height={22} />
    </OnboardingTopBarWrapper>
  );
};

export default OnboardingTopBar;
