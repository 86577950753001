import Board from './PerformanceBoard';
import EntityCell from './PerformanceEntityCell';
import SlideOut from './PerformanceBoardSlideOut';

const PerformanceBoards = {
  Board,
  SlideOut,
  EntityCell,
};

export default PerformanceBoards;
