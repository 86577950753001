import Typography from 'kingpin/atoms/Typography';
import React from 'react';
import { List, ListItem } from './Dropdown';
import Tooltip from '../Tooltip';

const ListPicker = ({
  options,
}: {
  options: {
    label: string;
    onClick: () => void;
    isDisabled?: boolean;
    tooltip?: string;
  }[];
}) => (
  <List>
    {options.map((o) => (
      <Tooltip key={o.label} content={o.tooltip}>
        <ListItem onClick={o.onClick} isDisabled={o.isDisabled}>
          <Typography.Body type="Body 12">{o.label}</Typography.Body>
        </ListItem>
      </Tooltip>
    ))}
  </List>
);

export default ListPicker;
