import React from 'react';
import Row from '../../../Common/Row';
import BottomBar from './BottomBar';
import SideBar from './SideBar';
import List from './List';
import PopupStyles from '../../MetricPicker/MetricPickerPopup/PopupStyles';

const FieldPickerPopup = ({
  selectedFieldsCount,
  onFieldAdded,
  onFieldRemoved,
  dataTypeOptions,
  searchText,
  onSearchTextChanged,
  fieldOptions,
  isConfirmEnabled,
  onConfirmClicked,
  onCancelClicked,
  isSaving,
}: {
  selectedFieldsCount: number;
  onFieldAdded: (f: { field: string; dataType: string }) => void;
  onFieldRemoved: (f: { field: string; dataType: string }) => void;
  dataTypeOptions: DropdownOption[];
  searchText: string;
  onSearchTextChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
  fieldOptions: {
    field: string;
    dataType: string;
    fieldLabel: string;
    isSelected: boolean;
    isDisabled: boolean;
  }[];
  isConfirmEnabled: boolean;
  onConfirmClicked: () => void;
  onCancelClicked: () => void;
  isSaving?: boolean;
}) => (
  <PopupStyles.PopupContent.ContentOuter>
    <Row>
      <SideBar dataTypeOptions={dataTypeOptions} />
      <PopupStyles.PopupContent.ContentDiv>
        <List
          searchText={searchText}
          onSearchTextChanged={onSearchTextChanged}
          onFieldAdded={onFieldAdded}
          onFieldRemoved={onFieldRemoved}
          fieldOptions={fieldOptions}
        />
      </PopupStyles.PopupContent.ContentDiv>
    </Row>
    <BottomBar
      isConfirmEnabled={isConfirmEnabled}
      onCancelClicked={onCancelClicked}
      selectedFieldsCount={selectedFieldsCount}
      onConfirmClicked={onConfirmClicked}
      isSaving={isSaving}
    />
  </PopupStyles.PopupContent.ContentOuter>
);

export default FieldPickerPopup;
