import React, { useEffect, useState } from 'react';
import usePopup from '../../../hooks/usePopup';
import InlineDialog from '../../InlineDialog';
import { DropdownButton } from '../Dropdown';
import FilterForm from './FilterForm';
import Tooltip from '../../Tooltip';

const useLabel = (filter: FilterBuilder.Client.FieldFilter) => {
  const [label, setLabel] = useState<string>('');

  useEffect(() => {
    const valueLabel = (() => {
      if (Array.isArray(filter.value)) {
        return filter.value.join(', ');
      }
      if (filter.value === undefined) {
        return '';
      }
      if (
        typeof filter.value === 'number' ||
        typeof filter.value === 'string'
      ) {
        return filter.value;
      }
      if (typeof filter.value === 'boolean') {
        if (filter.value === true) {
          return 'True';
        }
        if (filter.value === false) {
          return 'False';
        }
      }

      const e = new Error();
      e.name = 'Unexpected filter value';
      throw e;
    })();

    setLabel(`${filter.field} ${filter.condition} ${valueLabel}`);
  }, [filter.condition, filter.field, filter.value]);

  return label;
};

const Filter = ({
  filter,
  updateFilter,
  removeFilter,
}: {
  filter: FilterBuilder.Client.FieldFilter;
  updateFilter: (f: FilterBuilder.Client.FieldFilter) => void;
  removeFilter: (f: FilterBuilder.Client.FieldFilter) => void;
}) => {
  const { isOpen, open, close } = usePopup();
  const label = useLabel(filter);

  return (
    <div>
      <InlineDialog
        isOpen={isOpen}
        onClose={close}
        content={
          <FilterForm
            close={close}
            filter={filter}
            updateFilter={updateFilter}
            removeFilter={removeFilter}
          />
        }
      >
        <div style={{ maxWidth: 240 }}>
          <Tooltip content={label}>
            <DropdownButton
              testId={'filter-plate'}
              isOpen={isOpen}
              open={open}
              close={close}
              selectedLabel={label}
              fullWidth
            />
          </Tooltip>
        </div>
      </InlineDialog>
    </div>
  );
};

export default Filter;
