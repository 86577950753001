import React from 'react';
import usePopup from '../../../hooks/usePopup';
import { DropdownButton } from '../Dropdown';
import useFilteredOptions from './useFilteredOptions';
import useSelectedLabel from './useSelectedLabel';
import InlineDialog from '../../InlineDialog';

import styled from 'styled-components';
import Typography from 'kingpin/atoms/Typography';
import Checkbox from '../Checkbox';
import Tooltip from '../../Tooltip';
import TextInput from '../../../kingpin/atoms/TextInput';
import Colors2 from '../../../theme/Colors2';

const PopupDiv = styled.div`
  padding: 16px;
`;

const TitleDiv = styled.div`
  padding-bottom: 16px;
  margin-bottom: 8px;
  border-bottom: 1px solid ${Colors2.Border};
`;

const OptionDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;

  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const OptionList = styled.div`
  max-height: 140px;
  overflow-y: scroll;
`;

const AlreadyInUseDiv = styled.div`
  margin-top: 16px;
  border-bottom: 1px solid ${Colors2.Border};
  padding-top: 8px;
`;

const NotInUseDiv = styled.div`
  border-bottom: 1px solid ${Colors2.Border};
`;

const Option = ({ option }: { option: ExclusiveDropdownOption }) => {
  return (
    <OptionDiv onClick={option.onSelected}>
      <div>
        <Typography.Body type="Body 12">{option.label}</Typography.Body>
        {option.isInUseElsewhere !== undefined && (
          <Typography.Body type="Body 12">
            {option.isInUseElsewhere.reason}
          </Typography.Body>
        )}
      </div>
      <Tooltip
        content={
          option.isInUseElsewhere ? option.isInUseElsewhere.tooltip : undefined
        }
      >
        <Checkbox
          isChecked={option.isSelected}
          onToggled={window.tokenFunction}
          testId={`checkbox-${option.label}`}
        />
      </Tooltip>
    </OptionDiv>
  );
};

const NotInUseList = ({ options }: { options: ExclusiveDropdownOption[] }) => (
  <NotInUseDiv>
    <OptionList>
      {options.map((o) => (
        <Option option={o} key={o.key} />
      ))}
    </OptionList>
  </NotInUseDiv>
);

const AlreadyInUseList = ({
  options,
}: {
  options: ExclusiveDropdownOption[];
}) => {
  if (options.length === 0) {
    return null;
  }

  return (
    <AlreadyInUseDiv>
      <div style={{ padding: 8 }}>
        <Typography.Body type="Body 12">Already In Use</Typography.Body>
      </div>
      <OptionList style={{ borderTop: `1px solid ${Colors2.Border}` }}>
        {options.map((o) => (
          <Option option={o} key={o.key} />
        ))}
      </OptionList>
    </AlreadyInUseDiv>
  );
};

const DropDownPopup = ({
  options,
  title,
  isAlreadyInUseDisabled,
  ConfirmationButton,
}: {
  options: ExclusiveDropdownOption[];
  title: string;
  isAlreadyInUseDisabled?: boolean;
  ConfirmationButton?: JSX.Element;
}) => {
  const {
    alreadyInUseOptions,
    notAlreadyInUseOptions,
    searchText,
    onSearchTextChanged,
  } = useFilteredOptions(options);

  return (
    <PopupDiv>
      <TitleDiv>
        <Typography.Header type="H5">{title}</Typography.Header>
      </TitleDiv>
      <div style={{ marginBottom: 8 }}>
        <TextInput
          value={searchText}
          onChange={onSearchTextChanged}
          placeholder="Search"
          icon="search"
          width="100%"
          autoFocus
        />
      </div>
      <NotInUseList options={notAlreadyInUseOptions} />
      {!isAlreadyInUseDisabled && (
        <AlreadyInUseList options={alreadyInUseOptions} />
      )}
      {ConfirmationButton}
    </PopupDiv>
  );
};

const ExclusiveDropdown = ({
  placeholder,
  options,
  testId,
  hasError,
  isAlreadyInUseDisabled,
  ButtonOverride,
  ConfirmationButton,
}: {
  placeholder: string;
  options: ExclusiveDropdownOption[];
  testId?: string;
  hasError?: boolean;
  isAlreadyInUseDisabled?: boolean;
  ButtonOverride?: ({ open }: { open: () => void }) => JSX.Element | null;
  ConfirmationButton?: ({ close }: { close: () => void }) => JSX.Element | null;
}) => {
  const { isOpen, open, close } = usePopup();
  const selectedLabel = useSelectedLabel(options);

  const ButtonContent = ButtonOverride ? (
    <ButtonOverride open={open} />
  ) : (
    <Tooltip content={selectedLabel}>
      <DropdownButton
        isOpen={isOpen}
        open={open}
        close={close}
        placeholder={placeholder}
        selectedLabel={selectedLabel}
        testId={testId}
        hasError={hasError}
      />
    </Tooltip>
  );

  const ConfirmationButtonContent = ConfirmationButton ? (
    <ConfirmationButton close={close} />
  ) : undefined;

  return (
    <InlineDialog
      content={
        <DropDownPopup
          options={options}
          title={placeholder}
          ConfirmationButton={ConfirmationButtonContent}
          isAlreadyInUseDisabled={isAlreadyInUseDisabled}
        />
      }
      isOpen={isOpen}
      onClose={close}
    >
      {ButtonContent}
    </InlineDialog>
  );
};

export default ExclusiveDropdown;
