import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import AnalyticsContext from 'contexts/AnalyticsContext';
import ScorecardContext from 'contexts/ScorecardContext';
import _ from 'lodash';

const useManualFormState = ({
  manualKpi,
  setHasUnsavedChanges,
}: {
  manualKpi?: Scorecards.ManualKpiRow;
  setHasUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { scorecard } = useContext(ScorecardContext);
  const { trackEvent } = useContext(AnalyticsContext);

  const [label, setLabel] = useState<string>(manualKpi ? manualKpi.label : '');
  const [formatting, setFormatting] = useState<Scorecards.ManualKpiRowFormat>(
    manualKpi ? manualKpi.formatting : 'Number',
  );
  const [currencyType, setCurrencyType] = useState<CurrencyType | undefined>(
    manualKpi ? manualKpi.currencyType : undefined,
  );
  const [reportDrillDownId, setReportDrillDownId] = useState<
    string | undefined
  >(manualKpi ? manualKpi.reportDrillDownId : undefined);

  const [targets, setTargets] = useState<
    Scorecards.WeekTargets | Scorecards.IsoTargets
  >(
    manualKpi
      ? _.cloneDeep(manualKpi.targets)
      : scorecard.cadence === 'week'
        ? ({ mode: 'Week', targets: {} } as Scorecards.WeekTargets)
        : ({ mode: 'ISO', targets: {} } as Scorecards.IsoTargets),
  );
  const [isTargetsDisabled, setIsTargetsDisabled] = useState<boolean>(
    manualKpi ? manualKpi.isTargetsDisabled : false,
  );
  const [dynamicTargetOverride, setProrationOverride] = useState<
    boolean | undefined
  >(manualKpi ? manualKpi.dynamicTargetOverride : undefined);
  const [isColorOrderReversed, setIsColorOrderReversed] = useState<boolean>(
    manualKpi ? manualKpi.isColorOrderReversed : false,
  );
  const [canSaveKpi, setCanSaveKpi] = useState<boolean>(true);

  const markHasUnsavedChanges = useCallback(() => {
    setHasUnsavedChanges(true);
  }, [setHasUnsavedChanges]);

  useEffect(() => {
    return () => {
      setHasUnsavedChanges(false);
    };
  }, [setHasUnsavedChanges]);

  const onNameChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setLabel(event.target.value);
      markHasUnsavedChanges();
    },
    [markHasUnsavedChanges],
  );

  const [isShowingAdvanced, setIsShowingAdvanced] = useState<boolean>(false);

  const onAdvancedToggled = useCallback(() => {
    if (isShowingAdvanced) {
      setIsShowingAdvanced(false);
    } else {
      setIsShowingAdvanced(true);
    }
  }, [isShowingAdvanced]);

  const formattingOptions = ['Number', 'Percentage', 'Currency'].map(
    (format) => ({
      label: format,
      value: format,
      isSelected: formatting === format,
      onSelected: () => {
        setFormatting(format as Scorecards.ManualKpiRowFormat);
        markHasUnsavedChanges();
      },
    }),
  );

  const trackDrillDownAdded = useCallback(
    ({
      type,
      id,
      name,
    }: {
      type: 'Report' | 'Board';
      id: string;
      name: string;
    }) => {
      trackEvent('KPI List - Manual KPI - DrillDown added', {
        scorecardName: scorecard.title,
        scorecardId: scorecard.id,
        drillDownType: type,
        drillDownId: id,
        drillDownName: name,
      });
    },
    [scorecard.id, scorecard.title, trackEvent],
  );

  return {
    label,
    canSaveKpi,
    setCanSaveKpi,
    onNameChanged,
    onAdvancedToggled,
    isShowingAdvanced,
    formattingOptions,
    trackDrillDownAdded,
    targets,
    setTargets,
    isTargetsDisabled,
    setIsTargetsDisabled,
    formatting,
    currencyType,
    setCurrencyType,
    reportDrillDownId,
    setReportDrillDownId,
    dynamicTargetOverride,
    setProrationOverride,
    isColorOrderReversed,
    setIsColorOrderReversed,
    markHasUnsavedChanges,
  };
};

export default useManualFormState;
