import React from 'react';
import styled from 'styled-components';
import Colors from './theme/colors';

const Page = styled.div`
  width: 100vw;
  height: 100vh;
  padding-top: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${Colors.FLEETOPS_PURPLE};
`;

const Title = styled.h1`
  color: white;
`;

const Info = styled.div`
  border-radius: 6px;
  background-color: white;
  color: ${Colors.FLEETOPS_PURPLE};
  max-width: 600px;
  padding: 24px;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 24px;
`;

const Legal = styled.div`
  font-size: 11px;
  color: white;

  a {
    color: white;
    text-decoration: underline;
  }
`;

const UnsupportedBrowser = () => (
  <Page>
    <Title>FleetOps</Title>
    <Info>
      <p>
        Your browser does not support modern security features that are
        necessary to protect your data
      </p>
      <p>
        Please use another browser to log in; Google Chrome, Mozilla Firefox,
        and Microsoft Edge are all good choices.
      </p>
    </Info>
    <Legal>
      © 2019 FleetOps ltd. All rights reserved.{' '}
      <a
        href={'https://fleetops.com/privacy'}
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy
      </a>
      {' and '}
      <a
        href={'https://fleetops.com/terms'}
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms of Service
      </a>{' '}
    </Legal>
  </Page>
);

export default UnsupportedBrowser;
