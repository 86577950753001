import { useCallback, useContext } from 'react';
import CostsShowContext from '../../context/CostsShowContext';

const useGetCpmValue = ({
  intervals,
  getCostValue,
  getTotalsValue,
}: {
  intervals: Period[];
  getCostValue: ({
    startDate,
    groupDefinition,
    costFieldName,
  }: {
    startDate: string;
    groupDefinition: Costs.Group;
    costFieldName: string;
  }) => number | undefined;
  getTotalsValue: ({
    startDate,
    endDate,
    groupDefinition,
  }: {
    startDate: string;
    endDate: string;
    groupDefinition: Costs.Group;
  }) => Promise<Costs.IntervalTotals | undefined>;
}) => {
  const { intervalCosts } = useContext(CostsShowContext);

  const getCpmValue = useCallback(
    async ({
      startDate,
      groupDefinition,
      costFieldName,
    }: {
      startDate: string;
      groupDefinition: Costs.Group;
      costFieldName: string;
    }): Promise<number | undefined> => {
      const interval = intervalCosts
        ? intervalCosts.find((i) => i.startDate === startDate)
        : undefined;

      if (!interval) {
        const previousCostIntervals = intervals.filter(
          (ci) =>
            ci.startDate <= startDate &&
            getCostValue({
              startDate: ci.startDate,
              groupDefinition,
              costFieldName,
            }) !== undefined,
        );

        if (previousCostIntervals.length > 0) {
          const lastInterval =
            previousCostIntervals[previousCostIntervals.length - 1];
          if (lastInterval) {
            const cost =
              getCostValue({
                startDate: lastInterval.startDate,
                groupDefinition,
                costFieldName,
              }) || 0;
            const totals = await getTotalsValue({
              startDate: lastInterval.startDate,
              endDate: lastInterval.endDate,
              groupDefinition,
            });

            return totals
              ? totals.totalMiles !== 0
                ? cost / totals.totalMiles
                : 0
              : undefined;
          } else {
            return undefined;
          }
        }

        return undefined;
      }

      const cost =
        getCostValue({
          startDate,
          groupDefinition,
          costFieldName,
        }) || 0;

      const totals = await getTotalsValue({
        startDate,
        endDate: interval.endDate,
        groupDefinition,
      });

      return totals
        ? totals.totalMiles !== 0
          ? cost / totals.totalMiles
          : 0
        : undefined;
    },
    [getCostValue, getTotalsValue, intervalCosts, intervals],
  );

  return getCpmValue;
};

export default useGetCpmValue;
