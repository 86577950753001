const numberToCommaString = ({
  value,
  isCommasDisabled,
}: {
  value: number;
  isCommasDisabled?: boolean;
}) => {
  if (isCommasDisabled) {
    return value.toString();
  }

  const asString = value.toString();
  const hasDecimal = asString.split('.').length > 1;
  if (hasDecimal) {
    const n = asString.split('.')[0];
    const d = asString.split('.')[1];
    const commaN = n.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return `${commaN}.${d}`;
  } else {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
};

export default numberToCommaString;
