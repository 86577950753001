import React, { useCallback, useContext } from 'react';
import Inputs from '../../../../../../components/Inputs';
import Button from 'kingpin/atoms/Button';
import Row from '../../../../../../components/Common/Row';
import PerformanceWizardContext from '../PerformanceWizardContext';
import useDatasetRedList from './useDatasetRedList';
import useMetricFieldRedList from './useMetricFieldRedList';
import MetricOptionsContext from '../../../../../../contexts/MetricOptionsContext';
import _ from 'lodash';
import getIdentifier from '../../../../../../getIdentifier';

const PERFORMANCE_AGG_FUNC_GREEN_LIST = [
  'count' as 'count',
  'sum' as 'sum',
  'min' as 'min',
  'max' as 'max',
  'count_first_day' as 'count_first_day',
  'count_last_day' as 'count_last_day',
  'sum_first_day' as 'sum_first_day',
  'sum_last_day' as 'sum_last_day',
  'avg_per_week' as 'avg_per_week',
];

const getFieldName = (metric: Metrics.Metric) => {
  const base = _.camelCase(metric.name);
  return base.replaceAll(/[^a-zA-Z]/g, '');
};

const AddPerformanceMetricButton = ({ label }: { label: string }) => {
  const { setWizardState, wizardState, isReadOnly } = useContext(
    PerformanceWizardContext,
  );
  const datasetRedList = useDatasetRedList();
  const fieldRedList = useMetricFieldRedList();
  const { metricOptionsLookup } = useContext(MetricOptionsContext);

  const onMetricSelected = useCallback(
    (metricId: string) => {
      const metric = metricOptionsLookup[metricId];
      setWizardState((s) => ({
        ...s,
        config: {
          ...s.config,
          metricsStep: {
            ...s.config.metricsStep,
            metrics: [
              ...s.config.metricsStep.metrics,
              {
                key: getIdentifier(undefined, true),
                metricId,
                fieldName: metric ? getFieldName(metric) : undefined,
                dateField: 'date',
                datePeriodType: 'to_date',
                datePeriodLength: 1,
              },
            ],
          },
        },
      }));
    },
    [metricOptionsLookup, setWizardState],
  );

  if (isReadOnly) {
    return null;
  }

  return (
    <Row>
      <div>
        <Inputs.MetricPicker
          datasetRedList={datasetRedList}
          fieldRedList={fieldRedList}
          aggFuncGreenList={PERFORMANCE_AGG_FUNC_GREEN_LIST}
          isShowAllDatasetsButtonDisabled
          noSpecials
          datasetMustIncludeField={wizardState.config.datasetStep.entity}
          setMetricId={onMetricSelected}
          Button={(props) => (
            <Button
              type="Tertiary"
              size="Small"
              onClick={props.onClick}
              label={label}
            />
          )}
        />
      </div>
    </Row>
  );
};

export default AddPerformanceMetricButton;
