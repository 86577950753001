import React, { useCallback, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import AnalyticsContext from '../../../contexts/AnalyticsContext';
import Row from '../../Common/Row';
import Dropdown from '../../Inputs/Dropdown';
import Button from '../../../kingpin/atoms/Button';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const MonthPicker = ({
  date,
  changeMonth,
}: {
  date: Date;
  changeMonth: (month: number) => void;
  decreaseMonth(): void;
  increaseMonth(): void;
}) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const buildOptions = useCallback((): DropdownOption[] => {
    return months.map((m, index) => ({
      label: m,
      isSelected: moment(date).format('MMMM') === m,
      onSelected: () => {
        trackEvent('DateInput - Calendar Month Selected');
        changeMonth(index);
      },
    }));
  }, [changeMonth, date, trackEvent]);
  const [options, setOptions] = useState<DropdownOption[]>(() =>
    buildOptions(),
  );
  useEffect(() => {
    setOptions(buildOptions());
  }, [buildOptions]);

  return (
    <div style={{ width: 100 }}>
      <Dropdown options={options} />
    </div>
  );
};

const nextYear = moment.utc().add({ year: 1 }).format('YYYY');
const thisYear = moment.utc().format('YYYY');
const years = [nextYear, thisYear];
for (let i = 1; i < 20; i++) {
  years.push(moment.utc().subtract({ year: i }).format('YYYY'));
}

const YearPicker = ({
  date,
  changeYear,
}: {
  date: Date;
  changeYear: (year: number) => void;
  decreaseYear(): void;
  increaseYear(): void;
}) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const buildOptions = useCallback((): DropdownOption[] => {
    return years.map((y) => ({
      label: y,
      isSelected: moment(date).format('YYYY') === y,
      onSelected: () => {
        trackEvent('DateInput - Calendar Year Selected');
        changeYear(Number.parseInt(y));
      },
    }));
  }, [changeYear, date, trackEvent]);
  const [options, setOptions] = useState<DropdownOption[]>(() =>
    buildOptions(),
  );
  useEffect(() => {
    setOptions(buildOptions());
  }, [buildOptions]);

  return (
    <div style={{ width: 100 }}>
      <Dropdown options={options} />
    </div>
  );
};

const CustomCalendarHeader = ({
  date,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  changeYear,
  decreaseYear,
  increaseYear,
}: {
  date: Date;
  changeMonth(month: number): void;
  decreaseMonth(): void;
  increaseMonth(): void;
  changeYear(year: number): void;
  decreaseYear(): void;
  increaseYear(): void;
}) => {
  return (
    <Row spaceBetween centerAlign style={{ marginBottom: 8 }}>
      <div style={{ marginRight: 8 }}>
        <Button
          size={'Small'}
          type={'Secondary'}
          testId={'decrease-month'}
          onClick={decreaseMonth}
          icon={'chevron-left'}
        />
      </div>
      <div style={{ marginRight: 8 }}>
        <MonthPicker
          decreaseMonth={decreaseMonth}
          increaseMonth={increaseMonth}
          changeMonth={changeMonth}
          date={date}
        />
      </div>
      <div style={{ marginRight: 8 }}>
        <YearPicker
          increaseYear={increaseYear}
          decreaseYear={decreaseYear}
          changeYear={changeYear}
          date={date}
        />
      </div>
      <Button
        size={'Small'}
        type={'Secondary'}
        testId={'increase-month'}
        onClick={increaseMonth}
        icon={'chevron-right'}
      />
    </Row>
  );
};

export default CustomCalendarHeader;
