import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ModalTransition } from '@atlaskit/modal-dialog';
import Button from 'kingpin/atoms/Button';

import MetricsContext from '../../../contexts/MetricsContext';
import usePopup from '../../../hooks/usePopup';
import MetricSlideOut from './MetricSlideOut';
import SlideOut from '../../../components/Slideout';
import MetricPopup from './MetricPopup';
import useQueryParams from '../../../hooks/useQueryParams';
import { UnsavedChangesConfirmation } from '../../../components/ConfirmationModal';
import ReactPortal from '../../../components/ReactPortal';
import { DATA_MANAGER_TOP_ACTION_DIV_ID } from '../AllSets/constants';
import MetricFilteringProvider from '../../../contextProviders/MetricFilteringProvider';
import MetricList from './MetricList';
import CompoundMetricsContext from '../../../contexts/CompoundMetricsContext';
import AnalyticsContext from '../../../contexts/AnalyticsContext';

const useMetricsQueryParams = () => {
  const query = useQueryParams();
  const getParam = useCallback(
    (name: string) => {
      if (query.has(name)) {
        return query.get(name) as string;
      }
      return undefined;
    },
    [query],
  );

  const [queryParams, setQueryParams] = useState<{
    metricId?: string;
    dataset?: string;
  }>({
    metricId: getParam('metricId'),
    dataset: getParam('dataset'),
  });

  useEffect(() => {
    setQueryParams({
      metricId: getParam('metricId'),
      dataset: getParam('dataset'),
    });
  }, [getParam, query]);

  return queryParams;
};

const useSelectedMetric = (mode: 'Metric' | 'CompoundMetric') => {
  const { metricId } = useMetricsQueryParams();
  const { allMetrics } = useContext(MetricsContext);
  const { allCompoundMetrics } = useContext(CompoundMetricsContext);

  const getUrlMetric = useCallback(() => {
    if (mode === 'Metric') {
      return metricId ? allMetrics.find((m) => m.id === metricId) : undefined;
    }

    return metricId
      ? allCompoundMetrics.find((m) => m.id === metricId)
      : undefined;
  }, [allCompoundMetrics, allMetrics, metricId, mode]);

  const [selectedMetric, setSelectedMetric] = useState<
    Metrics.NormalMetric | Metrics.CompoundMetric | undefined
  >(() => getUrlMetric());

  useEffect(() => {
    if (!metricId) {
      return;
    }
    setSelectedMetric(getUrlMetric());
  }, [allMetrics, getUrlMetric, metricId]);

  return {
    selectedMetric,
    setSelectedMetric,
  };
};

const MetricsContainer = ({
  mode,
  forcedDataset,
}: {
  mode: 'Metric' | 'CompoundMetric';
  forcedDataset?: string;
}) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const { metricId, dataset } = useMetricsQueryParams();
  const navigate = useNavigate();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const {
    isOpen: isConfirmOpen,
    open: openConfirm,
    close: closeConfirm,
  } = usePopup();

  const { selectedMetric, setSelectedMetric } = useSelectedMetric(mode);
  const [, setForcedDatasets] = useState<string[] | undefined>();
  const {
    isOpen: isSlideOutOpen,
    open: openSlideOut,
    close: closeSlideOut,
  } = usePopup();
  const {
    isOpen: isPopUpOpen,
    open: openPopUp,
    close: closePopUp,
  } = usePopup(!!metricId);

  useEffect(() => {
    setHasUnsavedChanges(false);
  }, [selectedMetric]);

  useEffect(() => {
    setForcedDatasets(dataset ? [dataset] : undefined);
  }, [dataset]);

  const openMetricSlideOut = useCallback(
    (metric: Metrics.NormalMetric | Metrics.CompoundMetric) => {
      openSlideOut();
      setSelectedMetric(metric);
    },
    [openSlideOut, setSelectedMetric],
  );

  const openMetricPopup = useCallback(
    (metric?: Metrics.NormalMetric | Metrics.CompoundMetric) => {
      setSelectedMetric(metric);
      closeSlideOut();
      openPopUp();
    },
    [closeSlideOut, openPopUp, setSelectedMetric],
  );

  const onSlideOutDismissed = useCallback(() => {
    closeSlideOut();
    setSelectedMetric(undefined);
  }, [closeSlideOut, setSelectedMetric]);

  const onPopupCloseConfirmed = useCallback(() => {
    closePopUp();
    closeConfirm();
    if (isConfirmOpen) {
      trackEvent('Metric Builder - Changes discarded', {
        metricType: mode === 'Metric' ? 'normal' : 'compound',
      });
    }
    setSelectedMetric(undefined);
  }, [
    closeConfirm,
    closePopUp,
    isConfirmOpen,
    mode,
    setSelectedMetric,
    trackEvent,
  ]);

  const onPopupDismissed = useCallback(() => {
    if (!hasUnsavedChanges) {
      onPopupCloseConfirmed();
      return;
    }

    openConfirm();
  }, [hasUnsavedChanges, onPopupCloseConfirmed, openConfirm]);

  const onCreateMetricClicked = useCallback(() => {
    trackEvent('Data Manager - Create Metric clicked', { metricType: mode });
    openPopUp();
  }, [mode, openPopUp, trackEvent]);

  useEffect(() => {
    if (selectedMetric) {
      navigate({ search: `metricId=${selectedMetric.id}` });
    } else {
      navigate({ search: '' });
    }
  }, [navigate, selectedMetric]);

  return (
    <>
      <MetricList
        mode={mode}
        openMetricSlideOut={openMetricSlideOut}
        openMetricPopup={openMetricPopup}
        onCreateMetricClicked={onCreateMetricClicked}
      />
      <ReactPortal elementId={DATA_MANAGER_TOP_ACTION_DIV_ID}>
        <Button
          label="Create Metric"
          onClick={onCreateMetricClicked}
          icon="add"
          type="Primary"
          size="Small"
        />
      </ReactPortal>
      <SlideOut isOpen={isSlideOutOpen} close={onSlideOutDismissed}>
        {isSlideOutOpen && !!selectedMetric && (
          <MetricSlideOut
            close={onSlideOutDismissed}
            metric={selectedMetric}
            openMetricPopup={openMetricPopup}
          />
        )}
      </SlideOut>
      <ModalTransition>
        {isPopUpOpen && (
          <MetricPopup
            mode={mode}
            close={onPopupDismissed}
            hasUnsavedChanges={hasUnsavedChanges}
            selectedMetric={selectedMetric}
            setSelectedMetric={setSelectedMetric}
            setHasUnsavedChanges={setHasUnsavedChanges}
            forcedDataset={forcedDataset}
          />
        )}
      </ModalTransition>
      <UnsavedChangesConfirmation
        onConfirmed={onPopupCloseConfirmed}
        close={closeConfirm}
        isOpen={isConfirmOpen}
      />
    </>
  );
};

const Gate = ({ mode }: { mode: 'Metric' | 'CompoundMetric' }) => {
  const { dataset } = useParams<{ dataset?: string }>();

  return (
    <MetricFilteringProvider dataset={dataset} mode={mode} key={mode}>
      <MetricsContainer mode={mode} forcedDataset={dataset} />
    </MetricFilteringProvider>
  );
};

export default Gate;
