import { useContext, useEffect, useState } from 'react';
import CostsWizardContext from '../../context/CostsWizardContext';
import useGetActivitiesDateFields from './useGetActivitiesDateFields';

const useDateFieldsOptions = () => {
  const { wizardState, setWizardState } = useContext(CostsWizardContext);
  const [options, setOptions] = useState<DropdownOption[]>([]);
  const { dateFields, isLoading } = useGetActivitiesDateFields();

  // Build entity options
  useEffect(() => {
    if (dateFields) {
      const newOptions = dateFields.map((df) => ({
        value: df,
        label: df,
        isSelected: wizardState.config.basicStep.field === df,
        onSelected: async () => {
          setWizardState((s) => ({
            ...s,
            config: {
              ...s.config,
              basicStep: { ...s.config.basicStep, field: df },
            },
          }));
        },
      }));
      setOptions(newOptions);
    }
  }, [dateFields, setWizardState, wizardState.config.basicStep.field]);
  return { options, isLoading };
};

export default useDateFieldsOptions;
