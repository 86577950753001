import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import Colors2 from 'theme/Colors2';
import NavSideBarContext from 'contexts/NavSideBarContext';
import Icon from 'kingpin/atoms/Icon';
import Typography from 'kingpin/atoms/Typography';
import TextInput from 'kingpin/atoms/TextInput';
import FavouritesContext from 'contexts/FavouritesContext';
import FavoritesList from './FavoritesList';
import { Z_INDEX } from '../../constants';
import NAVIGATION from './constants';

const MainNavTopDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 12px;
  margin-bottom: 16px;
  height: ${NAVIGATION.TOP_HEIGHT}px;
  border-bottom: solid 1px ${Colors2.Border};
`;

const DarkRightOverlayDiv = styled.div<{
  isOpen: boolean;
}>`
  width: ${(props) =>
    `calc(100vw - ${props.isOpen ? NAVIGATION.OPENED_NAV_WIDTH : NAVIGATION.COLLAPSED_NAV_WIDTH}px - ${NAVIGATION.OPENED_NAV_WIDTH}px)`};
  height: 100vh;
  position: absolute;
  right: 0;
  background-color: #000;
  z-index: ${Z_INDEX.INLINE_DIALOG};
  opacity: 0.4;
`;

const FavoritesSectionDiv = styled.div<{
  isOpen: boolean;
}>`
  width: ${NAVIGATION.OPENED_NAV_WIDTH}px;
  z-index: ${Z_INDEX.INLINE_DIALOG};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: ${(props) =>
    `${props.isOpen ? NAVIGATION.OPENED_NAV_WIDTH : NAVIGATION.COLLAPSED_NAV_WIDTH}px`};
  background-color: #ffffff;
  height: 100vh;
  border-right: solid 1px ${Colors2.Border};
`;

const FavoritesSection = () => {
  const { isOpen } = useContext(NavSideBarContext);
  const { favourites, isOpenSlideout, closeSlideout } =
    useContext(FavouritesContext);

  const [searchText, setSearchText] = useState<string>('');
  const [filteredFavorites, setFilteredFavorites] = useState<ContentView[]>([]);

  useEffect(() => {
    if (searchText === '') {
      setFilteredFavorites(favourites);
      return;
    }

    setFilteredFavorites(
      favourites.filter((f) =>
        f.name.toLowerCase().includes(searchText.toLowerCase()),
      ),
    );
  }, [favourites, searchText]);

  const onSearchTextChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
    },
    [],
  );

  const favoritesSectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutSideClick = (event: MouseEvent) => {
      const favoritesSectionElem = favoritesSectionRef.current;
      if (
        favoritesSectionElem &&
        !favoritesSectionElem.contains(event.target as Node)
      ) {
        closeSlideout();
      }
    };

    const timeout = setTimeout(() => {
      window.addEventListener('click', handleOutSideClick);
    }, 50);

    return () => {
      window.removeEventListener('click', handleOutSideClick);
      clearTimeout(timeout);
    };
  }, [closeSlideout]);

  if (!isOpenSlideout) {
    return null;
  }

  return (
    <>
      <FavoritesSectionDiv isOpen={isOpen} ref={favoritesSectionRef}>
        <MainNavTopDiv>
          <div style={{ marginRight: '8px', display: 'flex' }}>
            <Icon icon={'star - empty'} color="#252525" />
          </div>
          <Typography.Header type="H3">Favorites</Typography.Header>
        </MainNavTopDiv>
        {favourites.length === 0 && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              marginTop: '24px',
            }}
          >
            <div style={{ display: 'flex', marginBottom: '8px' }}>
              <Icon icon={'star - empty'} width={42} height={42} />
            </div>
            <div style={{ marginBottom: '8px' }}>
              <Typography.Header type="H5">
                No favorites added yet
              </Typography.Header>
            </div>

            <div style={{ padding: '0 32px', display: 'flex' }}>
              <Typography.Body
                type="Body 13"
                color={Colors2.Grey['5']}
                textAlign="center"
              >
                Content that you favorite will appear here
              </Typography.Body>
            </div>
          </div>
        )}
        {favourites.length !== 0 && (
          <>
            <div style={{ padding: '0px 8px', width: '100%', marginBottom: 4 }}>
              <TextInput
                value={searchText}
                onChange={onSearchTextChanged}
                inputSize="Small"
                placeholder={'Search'}
                icon="search"
                width={'100%'}
                autoFocus
              />
            </div>
            <FavoritesList filteredFavorites={filteredFavorites} />
          </>
        )}
      </FavoritesSectionDiv>
      <DarkRightOverlayDiv isOpen={isOpen} />
    </>
  );
};

const Gate = () => {
  const { isOpenSlideout } = useContext(FavouritesContext);
  if (!isOpenSlideout) {
    return null;
  }

  return <FavoritesSection />;
};

export default Gate;
