import React from 'react';

interface DataManagerSpecificSetContextType {
  baseView: FleetOps.BaseView;
  datasetDef: FleetOps.DatasetDefinition;
  isOnSpecificSet: boolean;
}

const DataManagerSpecificSetContext =
  React.createContext<DataManagerSpecificSetContextType>({
    isOnSpecificSet: false,
  } as DataManagerSpecificSetContextType);

export default DataManagerSpecificSetContext;
