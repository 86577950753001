import React, { useCallback, useContext, useEffect, useState } from 'react';
import AnalyticsContext from 'contexts/AnalyticsContext';
import ScorecardContext from 'contexts/ScorecardContext';
import ManualKpiInputContext from './ManualKpiInputContext';
import ToastContext from 'contexts/ToastContext';

const ManualKpiInputProvider = ({
  kpi,
  children,
}: {
  kpi: Scorecards.ManualKpiRow;
  children: JSX.Element | JSX.Element[];
}) => {
  const { scorecard, updateScorecard, setManualKpiIdBeingEdited, periods } =
    useContext(ScorecardContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const { showToast } = useContext(ToastContext);

  const buildInitialKpiData = useCallback(() => {
    const initialData: Goals.TrendResult[] = [];

    periods.forEach((period) => {
      const existingKpi = kpi.data.find((d) => d.date === period.startDate);
      if (existingKpi) {
        initialData.push(existingKpi);
      } else {
        initialData.push({ date: period.startDate });
      }
    });

    return initialData;
  }, [kpi.data, periods]);

  const [values, setValues] =
    useState<Goals.TrendResult[]>(buildInitialKpiData);

  useEffect(() => {
    if (periods.length !== 0 && values.length === 0) {
      setValues(buildInitialKpiData());
    }
  }, [buildInitialKpiData, periods.length, values.length]);

  const onSave = useCallback(() => {
    const newKpis = scorecard.kpis.map((k) => {
      if (k.id === kpi.id) {
        return { ...k, data: values };
      } else {
        return k;
      }
    });
    const newScorecard = {
      ...scorecard,
      kpis: newKpis,
    };

    updateScorecard(newScorecard).then(async () => {
      trackEvent('KPI List - Manual KPI - Values Entered', {
        scorecardId: scorecard.id,
        kpiId: kpi.id,
      });
      setManualKpiIdBeingEdited(undefined);
      showToast('Manual KPI Added');
    });
  }, [
    kpi.id,
    scorecard,
    setManualKpiIdBeingEdited,
    showToast,
    trackEvent,
    updateScorecard,
    values,
  ]);

  const onDiscardChanges = useCallback(() => {
    setValues(kpi.data);
    setManualKpiIdBeingEdited(undefined);
  }, [kpi.data, setManualKpiIdBeingEdited]);

  return (
    <ManualKpiInputContext.Provider
      value={{
        onSave,
        onDiscardChanges,
        values,
        setValues,
      }}
    >
      {children}
    </ManualKpiInputContext.Provider>
  );
};

export default ManualKpiInputProvider;
