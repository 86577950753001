import styled from 'styled-components';
import PERFORMANCE_WIZARD_CONSTANTS from './constants';

const StepContent = styled.div`
  padding: ${PERFORMANCE_WIZARD_CONSTANTS.MODAL_PADDING_VERTICAL}px
    ${PERFORMANCE_WIZARD_CONSTANTS.MODAL_PADDING_HORIZONTAL}px;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  overflow-y: hidden;
`;

export const StepContentBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
`;

export default StepContent;
