import isCommentSection from './isCommentSection';
import isGridSection from './isGridSection';
import isMetricSection from './isMetricSection';
import isPerformanceSection from './isPerformanceSection';
import isTargetProgressSection from './isTargetProgressSection';
import isPropertiesSection from './isPropertiesSection';

const sectionTypeCheckers = {
  isPerformanceSection,
  isTargetProgressSection,
  isMetricSection,
  isPropertiesSection,
  isGridSection,
  isCommentSection,
};

export default sectionTypeCheckers;
