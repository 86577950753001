const COMMODITIES = [
  'Live animals and fish',
  'Cereal grains',
  'Other agricultural products',
  'Feed and products of animal origin',
  'Meat and seafood',
  'Milled grain products',
  'Other prepared foodstuffs',
  'Alcoholic beverages',
  'Tobacco products',
  'Building stone',
  'Natural sands',
  'Gravel and crushed stone',
  'Other nonmetallic minerals',
  'Metallic ores',
  'Coal',
  'Crude petroleum',
  'Gasoline, kerosene, and ethanol',
  'Diesel and other fuel oils',
  'Other fossil fuel products',
  'Basic chemicals',
  'Pharmaceutical products',
  'Fertilizers',
  'Chemical products and preparations',
  'Plastics and rubber',
  'Logs and wood in the rough',
  'Wood products',
  'Pulp, paper, and paperboard',
  'Paper or paperboard articles',
  'Printed products',
  'Textiles and leather',
  'Nonmetallic mineral products',
  'Metal in basic shapes',
  'Articles of metal',
  'Machinery',
  'Electronics',
  'Motorized and other vehicles',
  'Other transportation equipment',
  'Precision instruments',
  'Furniture',
  'Miscellaneous manufactured products',
  'Waste and scrap',
  'Mixed freight',
];

export default COMMODITIES;
