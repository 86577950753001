import aguid from 'aguid';
import { DateTime } from 'luxon';
import { buildShowGlobalGoal } from '../navigation/appRoutes';

const madeAccountableForGoal = ({
  editorName,
  goal,
}: {
  editorName: string;
  goal: GeneralGoal;
}): Notifications.BasicNotification => ({
  id: aguid(),
  isBasic: true,
  type: 'MADE_ACCOUNTABLE_FOR_GOAL',
  seen: false,
  read: false,
  deleted: false,
  createdOn: DateTime.utc().toISO(),
  emailSent: false,
  link: buildShowGlobalGoal(goal.id),
  inAppSubject: `${editorName} has made you accountable for the goal`,
  inAppBody: `${goal.title}`,
  inAppLinkText: 'View goal',
  emailSubject: `${editorName} has made you Accountable for a Goal on FleetOps`,
  emailBodyLn1: `${editorName} has made you Accountable for the goal - ${goal.title}`,
  emailBodyLn2: '',
  emailLinkText: 'Log in to view Goal Details',
});

export default madeAccountableForGoal;
