import React, { useCallback, useContext, useState } from 'react';
import Button from 'kingpin/atoms/Button';
import InlineDialog from 'components/InlineDialog';
import Typography from 'kingpin/atoms/Typography';
import { List, ListItem } from 'components/Inputs/Dropdown';
import usePopup from 'hooks/usePopup';
import { ModalTransition } from '@atlaskit/modal-dialog';
import FileUploadModal from './FileUploadModal';
import useGetUploadDataTypes from './hooks/useGetUploadDataTypes';
import AnalyticsContext from 'contexts/AnalyticsContext';
import Loading from 'components/Loading';

const UploadFilesButton = () => {
  const { trackEvent } = useContext(AnalyticsContext);

  const [dataType, setDataType] = useState<DataManager.UploadDataType>();
  const { isOpen, open, close } = usePopup();
  const {
    isOpen: isUploadOpen,
    open: openUpload,
    close: closeUpload,
  } = usePopup();

  const { dataTypes, isLoading } = useGetUploadDataTypes();

  const openUploadModal = useCallback(
    (dt: DataManager.UploadDataType) => {
      trackEvent('Data Manager - Upload Data - File type selected', {
        type: dt.dataType,
      });

      setDataType(dt);
      close();
      openUpload();
    },
    [close, openUpload, trackEvent],
  );

  const onUploadSuccess = useCallback(() => {
    if (!dataType) {
      return;
    }
    trackEvent('Data Manager - Upload Data - Success', {
      type: dataType.name,
    });
  }, [dataType, trackEvent]);

  const onUploadFailure = useCallback(() => {
    if (!dataType) {
      return;
    }
    trackEvent('Data Manager - Upload Data - Failure', {
      type: dataType.name,
    });
  }, [dataType, trackEvent]);

  const openTypes = useCallback(() => {
    trackEvent('Data Manager - Upload Data - Clicked');
    open();
  }, [open, trackEvent]);

  return (
    <>
      <InlineDialog
        isOpen={isOpen}
        onClose={close}
        content={
          <>
            {dataTypes && (
              <List>
                {dataTypes.map((dt) => (
                  <ListItem
                    key={dt.dataType}
                    onClick={() => openUploadModal(dt)}
                  >
                    <Typography.Body type="Body 12">{dt.name}</Typography.Body>
                  </ListItem>
                ))}
              </List>
            )}
            {isLoading && <Loading isSmall />}
          </>
        }
      >
        <Button
          type="Primary"
          size="Small"
          onClick={openTypes}
          icon={'fileUpload'}
          label="Upload Data"
        />
      </InlineDialog>
      <ModalTransition>
        {isUploadOpen && dataType && (
          <FileUploadModal
            dataType={dataType}
            close={closeUpload}
            onUploadSuccess={onUploadSuccess}
            onUploadFailure={onUploadFailure}
          />
        )}
      </ModalTransition>
    </>
  );
};

export default UploadFilesButton;
