import React from 'react';
import Typography from 'kingpin/atoms/Typography';
import styled from 'styled-components';
import Row from 'components/Common/Row';
import ActiveFilterToggle from '../ActiveFilterTogle';
import EntityResults from './EntityResults';

const WrapperDiv = styled.div`
  padding: 24px 200px;
`;

const SearchResultsContainer = ({
  results,
  onEntityNavigate,
  setSelectedEntity,
  isActiveFilterDisabled,
  setIsActiveFilterDisabled,
}: {
  results: GlobalSearch.SearchResults;
  onEntityNavigate: (entity: EntityDetails.Entity) => void;
  setSelectedEntity: React.Dispatch<
    React.SetStateAction<EntityDetails.Entity | undefined>
  >;
  isActiveFilterDisabled: boolean;
  setIsActiveFilterDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <WrapperDiv>
      <div style={{ marginBottom: 32 }}>
        <Row centerAlign spaceBetween>
          <Typography.Header type="H5">
            {`Showing results for "${results.searchText}"`}
          </Typography.Header>
          <ActiveFilterToggle
            isActiveFilterDisabled={isActiveFilterDisabled}
            setIsActiveFilterDisabled={setIsActiveFilterDisabled}
          />
        </Row>
      </div>
      {results.results.map((result) => (
        <EntityResults
          key={result.entity.id}
          result={result}
          onEntityNavigate={onEntityNavigate}
          isShowingFirstFour={true}
          setSelectedEntity={setSelectedEntity}
        />
      ))}
    </WrapperDiv>
  );
};

export default SearchResultsContainer;
