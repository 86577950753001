import React from 'react';

interface DashboardContextMenuContextType {
  isOpen: boolean;
  openMenu: (metricIds: string[], drillDown: ReportDrillDownType) => void;
  drillDown?: ReportDrillDownType;
  closeMenu: () => void;
  metricIds: string[];
  selectedMetric?: Metrics.NormalMetric | Metrics.SpecialMetric;
  setSelectedMetric: React.Dispatch<
    React.SetStateAction<
      Metrics.NormalMetric | Metrics.SpecialMetric | undefined
    >
  >;
}

const DashboardContextMenuContext =
  React.createContext<DashboardContextMenuContextType>(
    {} as DashboardContextMenuContextType,
  );

export default DashboardContextMenuContext;
