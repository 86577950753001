import React, { useCallback, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import firebase from 'firebase/compat/app';

import NotificationsContext from '../contexts/NotificationsContext';
import AccountPickerContext from '../contexts/AccountPickerContext';
import CurrentUserContext from '../contexts/CurrentUserContext';
import { wasToday, wasYesterday } from '../utils/friendlyTimestampFormat';
import captureException from '../services/captureException';
import createNotification from '../api/createNotification';

const DATE_BUCKETS = ['today', 'yesterday', 'older'];

const timeAgo = (createdOn: string) => {
  if (wasToday(createdOn)) {
    return DATE_BUCKETS[0];
  }

  if (wasYesterday(createdOn)) {
    return DATE_BUCKETS[1];
  }

  return DATE_BUCKETS[2];
};

const NotificationsProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  // Context
  const { accountRef } = useContext(AccountPickerContext);
  const currentUser = useContext(CurrentUserContext);

  // State
  const [selectedNotificationId, setSelectedNotificationId] = useState<
    string | undefined
  >();
  const [notifications, setNotifications] = useState<
    Notifications.Notification[]
  >([]);
  const [groupedNotifications, setGroupedNotifications] = useState<
    {
      title: string;
      notifications: Notifications.Notification[];
    }[]
  >([]);
  const [hasUnReadNotifications, setHasUnReadNotifications] =
    useState<boolean>(false);
  const [hasUnSeenNotifications, setHasUnSeenNotifications] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);

  // Callbacks
  const getNotificationRef = useCallback(
    (id: string) => {
      if (process.env.NODE_ENV === 'test' || currentUser.isWallboardUser) {
        return;
      }

      return accountRef
        .collection('users')
        .doc(currentUser.id)
        .collection('notifications-v2')
        .doc(id);
    },
    [accountRef, currentUser.id, currentUser.isWallboardUser],
  );

  // noinspection DuplicatedCode
  const markAllNotificationsAsSeen = useCallback(async () => {
    const notificationsToMark = notifications
      .filter((n) => !n.seen)
      .slice(0, 499); // batch has a max of 500 writes

    if (notificationsToMark.length === 0) {
      return;
    }
    const db = firebase.firestore();
    const batch = db.batch();
    notificationsToMark.forEach((n) => {
      const ref = getNotificationRef(n.id);
      if (ref) {
        batch.update(ref, { seen: true });
      }
    });
    await batch.commit();
  }, [getNotificationRef, notifications]);

  // noinspection DuplicatedCode
  const markAllNotificationsAsRead = useCallback(async () => {
    const notificationsToMark = notifications
      .filter((n) => !n.read)
      .slice(0, 499); // batch has a max of 500 writes

    if (notificationsToMark.length === 0) {
      return;
    }
    const db = firebase.firestore();
    const batch = db.batch();
    notificationsToMark.forEach((n) => {
      const ref = getNotificationRef(n.id);
      if (ref) {
        batch.update(ref, { read: true });
      }
    });
    await batch.commit();
  }, [getNotificationRef, notifications]);

  // noinspection DuplicatedCode
  const deleteAllNotifications = useCallback(async () => {
    const notificationsToMark = notifications
      .filter((n) => !n.deleted)
      .slice(0, 499); // batch has a max of 500 writes

    if (notificationsToMark.length === 0) {
      return;
    }
    const db = firebase.firestore();
    const batch = db.batch();
    notificationsToMark.forEach((n) => {
      const ref = getNotificationRef(n.id);
      if (ref) {
        batch.update(ref, { deleted: true });
      }
    });
    await batch.commit();
  }, [getNotificationRef, notifications]);

  const openPanel = useCallback(() => {
    setIsPanelOpen(true);
    markAllNotificationsAsSeen().catch(() => {
      const e = new Error('');
      e.name = 'Failed to markAllNotificationsAsSeen';
      captureException(e);
    });
  }, [markAllNotificationsAsSeen]);

  const closePanel = useCallback(() => {
    setIsPanelOpen(false);
  }, []);

  const sendNotification = useCallback(
    async (
      notification: Notifications.Notification,
      destinationUserId: string,
    ) => {
      if (destinationUserId === currentUser.id) {
        return;
      }
      await createNotification(notification, destinationUserId, accountRef);
      return;
    },
    [accountRef, currentUser.id],
  );

  // Effects
  // Read notifications
  useEffect(() => {
    if (process.env.NODE_ENV === 'test') {
      return;
    }

    accountRef
      .collection('users')
      .doc(currentUser.id)
      .collection('notifications-v2')
      .onSnapshot((snapshot: any) => {
        const data = [] as Notifications.Notification[];
        snapshot.docs.forEach((d: any) => data.push(d.data()));
        setNotifications(data.filter((d) => !d.deleted));
        setIsLoading(false);
      });
  }, [accountRef, currentUser.id]);

  // Group notifications
  useEffect(() => {
    const sortedNotifications = _.sortBy(notifications, 'createdOn').reverse();
    const groups = DATE_BUCKETS.map((bucket) => ({
      title: bucket,
      notifications: sortedNotifications.filter(
        (notification) => timeAgo(notification.createdOn) === bucket,
      ),
    })).filter((g) => g.notifications.length > 0);
    setGroupedNotifications(groups);
  }, [notifications]);

  useEffect(() => {
    setHasUnReadNotifications(notifications.some((n) => !n.read));
    setHasUnSeenNotifications(notifications.some((n) => !n.seen));
  }, [notifications]);

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        isLoading,
        groupedNotifications,
        isPanelOpen,
        openPanel,
        closePanel,
        markAllNotificationsAsRead,
        markAllNotificationsAsSeen,
        getNotificationRef,
        selectedNotificationId,
        setSelectedNotificationId,
        deleteAllNotifications,
        hasUnReadNotifications,
        hasUnSeenNotifications,
        sendNotification,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;
