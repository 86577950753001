import React, { useCallback, useEffect, useState } from 'react';
import MetricPopup from './MetricPopup';
import withDateFilter from '../../../../hocs/withDateFIlter';
import { LAST_30_DAYS_INCLUDING_TODAY } from '../../../../components/DateInput/constants';
import getIdentifier from '../../../../getIdentifier';
import LocalTimelineProvider from '../../../../contextProviders/TimelineProvider/LocalTimelineProvider';
import useMetricTimelineSources from '../MetricSlideOut/useMetricTimelineSources';
import useMetricTimelineStartsOn from '../hooks/useMetricTimelineStartsOn';

const MetricPopupContainer = ({
  close,
  selectedMetric,
  setSelectedMetric,
  hasUnsavedChanges,
  setHasUnsavedChanges,
  mode,
  forcedDataset,
}: {
  close: () => void;
  selectedMetric?: Metrics.NormalMetric | Metrics.CompoundMetric;
  setSelectedMetric?: React.Dispatch<
    React.SetStateAction<
      Metrics.NormalMetric | Metrics.CompoundMetric | undefined
    >
  >;
  hasUnsavedChanges: boolean;
  setHasUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  mode: 'Metric' | 'CompoundMetric';
  forcedDataset?: string;
}) => {
  const [id, setId] = useState<string>(() =>
    selectedMetric ? selectedMetric.id : getIdentifier(),
  );
  const sources = useMetricTimelineSources(selectedMetric);
  const [destination, setDestination] = useState<
    Timeline.EventSource | undefined
  >();
  const timelineStartsOn = useMetricTimelineStartsOn(selectedMetric);

  const [isFlashVisible, setIsFlashVisible] = useState<boolean>(false);
  const [flashMessage, setFlashMessage] = useState<string>('');

  const showFlash = useCallback((message: string) => {
    setFlashMessage(message);
    setIsFlashVisible(true);
  }, []);

  useEffect(() => {
    const s = {
      id,
      type: mode,
    };
    setDestination(s);
  }, [id, mode]);

  useEffect(() => {
    setId(selectedMetric ? selectedMetric.id : getIdentifier());
  }, [selectedMetric]);

  return (
    <LocalTimelineProvider
      sources={sources}
      destination={destination}
      startsOn={timelineStartsOn}
    >
      <MetricPopup
        close={close}
        setSelectedMetric={setSelectedMetric}
        selectedMetric={selectedMetric}
        id={id}
        setId={setId}
        isFlashVisible={isFlashVisible}
        setIsFlashVisible={setIsFlashVisible}
        flashMessage={flashMessage}
        showFlash={showFlash}
        hasUnsavedChanges={hasUnsavedChanges}
        setHasUnsavedChanges={setHasUnsavedChanges}
        mode={mode}
        forcedDataset={forcedDataset}
      />
    </LocalTimelineProvider>
  );
};

export default withDateFilter(MetricPopupContainer, {
  initialRelativeDateRange: LAST_30_DAYS_INCLUDING_TODAY,
});
