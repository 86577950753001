import { useCallback, useEffect, useState } from 'react';
import useEnabledEntities from './useEnabledEntities';

const useSearchPlaceholder = () => {
  const enabledEntities = useEnabledEntities();

  const getPlaceholder = useCallback(() => {
    if (enabledEntities.length >= 4) {
      return `Search ${enabledEntities.slice(0, 3).join(', ')} and more...`;
    } else if (enabledEntities.length === 3) {
      return `Search ${enabledEntities.slice(0, 2).join(', ')} and ${
        enabledEntities[2]
      }`;
    } else if (enabledEntities.length === 1) {
      return `Search for ${enabledEntities[0]}`;
    } else {
      return 'Searchable items not found';
    }
  }, [enabledEntities]);
  const [placeholder, setPlaceholder] = useState<string>(getPlaceholder);
  useEffect(() => {
    setPlaceholder(getPlaceholder());
  }, [getPlaceholder]);

  return placeholder;
};

export default useSearchPlaceholder;
