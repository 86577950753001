import React, { useContext, useEffect, useState } from 'react';
import StackedBarOptionsInput from './StackedBarOptionsInput';
import V5GadgetFormContext from '../../../contexts/V5GadgetFormContext';

const StackedBarOptionsInputContainer = ({
  stackedBarOptions,
}: {
  stackedBarOptions: StackedBarOptions;
}) => {
  const { direction, asPercentage, altPivot } = stackedBarOptions;
  const { setStackedBarOptions } = useContext(V5GadgetFormContext);

  const [directionOptions, setDirectionOptions] = useState<RadioOption[]>([]);
  useEffect(() => {
    setDirectionOptions([
      {
        label: 'Horizontal',
        key: 'h',
        isSelected: direction === 'horizontal',
        onSelected: () => {
          setStackedBarOptions({
            ...stackedBarOptions,
            direction: 'horizontal',
          });
        },
      },
      {
        label: 'Vertical',
        key: 'v',
        isSelected: direction === 'vertical',
        onSelected: () => {
          setStackedBarOptions({
            ...stackedBarOptions,
            direction: 'vertical',
          });
        },
      },
    ]);
  }, [direction, setStackedBarOptions, stackedBarOptions]);

  const onAsPercentageChanged = (newValue: boolean) => {
    setStackedBarOptions({
      ...stackedBarOptions,
      asPercentage: newValue,
    });
  };

  const onAltPivotChanged = (newValue: boolean) => {
    setStackedBarOptions({
      ...stackedBarOptions,
      altPivot: newValue,
    });
  };

  return (
    <StackedBarOptionsInput
      directionOptions={directionOptions}
      asPercentage={asPercentage}
      onAsPercentageChanged={onAsPercentageChanged}
      altPivot={altPivot}
      onAltPivotChanged={onAltPivotChanged}
    />
  );
};

const Gate = () => {
  const { stackedBarOptions } = useContext(V5GadgetFormContext);

  if (!stackedBarOptions) {
    return null;
  }

  return (
    <StackedBarOptionsInputContainer stackedBarOptions={stackedBarOptions} />
  );
};

export default Gate;
