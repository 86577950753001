import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import Colors2 from '../../../../theme/Colors2';
import Typography from 'kingpin/atoms/Typography';
import useUser from '../../../../hooks/useUser';
import getUserDisplayName from '../../../../contextProviders/UsersProvider/getUserDisplayName';

const Div = styled.div`
  background-color: ${Colors2.Grey['9']};
  padding: 8px;
`;

const LastUpdatedAt = ({
  target,
}: {
  target: Targets.Wizard.DataTypeTarget;
}) => {
  const { user } = useUser(target.updatedBy);
  const date = moment(target.updatedOn).format('YYYY-MM-DD');

  return (
    <Div>
      <Typography.Body type="Body 12">
        {`Last Updated: ${date} by ${user ? getUserDisplayName(user) : '-'}`}
      </Typography.Body>
    </Div>
  );
};

export default LastUpdatedAt;
