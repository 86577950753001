import React, { useContext, useEffect, useState } from 'react';
import ModalDialog, { ModalTransition } from '@atlaskit/modal-dialog';
import { DropResult } from 'react-beautiful-dnd';

import MetricsComponent from './Metrics';
import BoardContext from '../../../../contexts/BoardContext';
import updateBoard from '../../../../api/boards/updateBoard';
import isDefined from '../../../../isDefined';
import MetricOptionsContext from '../../../../contexts/MetricOptionsContext';
import AccountPickerContext from '../../../../contexts/AccountPickerContext';
import isPerformanceBoard from '../../../../isPerformanceBoard';

const MetricsContainer = ({
  isOpen,
  close,
  board,
}: {
  isOpen: boolean;
  close: () => void;
  board: Board;
}) => {
  const { accountRef } = useContext(AccountPickerContext);
  const { metricOptions } = useContext(MetricOptionsContext);

  const [metrics, setMetrics] = useState<Metrics.Metric[]>([]);
  const { metricIds } = board;
  const [isAddingNewMetric, setIsAddingNewMetric] = useState<boolean>(false);

  useEffect(() => {
    const ms = (metricIds || [])
      .map((mId) => {
        const m = metricOptions.find((m) => m.id === mId);
        return m;
      })
      .filter(isDefined);
    setMetrics(ms);
  }, [metricOptions, metricIds]);

  const onDragEnd = (result: DropResult) => {
    const { draggableId, destination } = result;
    if (!destination) {
      return;
    }

    const itemsWithoutDropped = (metricIds || []).filter(
      (mId) => mId !== draggableId,
    );

    const newOrder = [
      ...itemsWithoutDropped.slice(0, destination.index),
      draggableId,
      ...itemsWithoutDropped.slice(
        destination.index,
        itemsWithoutDropped.length,
      ),
    ];

    const newBoard = {
      ...board,
      metricIds: newOrder,
    };
    updateBoard(newBoard, accountRef);
  };

  const removeMetric = (metric: Metrics.Metric) => {
    const newMetricIds = (board.metricIds || []).filter((m) => m !== metric.id);
    const newBoard = { ...board, metricIds: newMetricIds };
    updateBoard(newBoard, accountRef);
  };

  const onAddMetric = (metric: Metrics.Metric) => {
    const newBoard = {
      ...board,
      metricIds: [...(board.metricIds || []), metric.id],
    } as Board;
    updateBoard(newBoard, accountRef).then(() => {
      setIsAddingNewMetric(false);
    });
  };

  return (
    <ModalTransition>
      {isOpen && (
        <ModalDialog
          onClose={close}
          shouldScrollInViewport={false}
          autoFocus={false}
        >
          <MetricsComponent
            metrics={metrics}
            onDragEnd={onDragEnd}
            close={close}
            isAddingNewMetric={isAddingNewMetric}
            setIsAddingNewMetric={setIsAddingNewMetric}
            removeMetric={removeMetric}
            onAddMetric={onAddMetric}
          />
        </ModalDialog>
      )}
    </ModalTransition>
  );
};

const Gate = ({ isOpen, close }: { isOpen: boolean; close: () => void }) => {
  const { board } = useContext(BoardContext);
  if (isPerformanceBoard(board)) {
    return null;
  }

  return <MetricsContainer isOpen={isOpen} close={close} board={board} />;
};

export default Gate;
