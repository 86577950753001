import React, { useContext, useEffect, useState } from 'react';
import useIntervalLabel from '../useIntervalLabel';
import MetricsStep from './MetricsStep';
import PerformanceWizardContext from '../PerformanceWizardContext';
import useGetIsMetricNameValid from './useGetIsMetricNameValid';

const MetricsStepContainer = ({
  goToNextStep,
  goToPreviousStep,
  isSaving,
}: {
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  isSaving: boolean;
}) => {
  const { wizardState, isReadOnly } = useContext(PerformanceWizardContext);
  const getIsMetricNameInvalid = useGetIsMetricNameValid();
  const intervalLabel = useIntervalLabel();
  const [isNextStepEnabled, setIsNextStepEnabled] = useState<boolean>(false);

  // setIsNextStepEnabled
  useEffect(() => {
    const performanceMetrics = wizardState.config.metricsStep.metrics;
    const isAFieldNameInvalid = performanceMetrics.some(
      (pm) => !getIsMetricNameInvalid(pm).isValid,
    );

    return setIsNextStepEnabled(
      wizardState.config.metricsStep.metrics.length > 0 && !isAFieldNameInvalid,
    );
  }, [
    getIsMetricNameInvalid,
    wizardState.config.metricsStep.metrics,
    wizardState.config.metricsStep.metrics.length,
  ]);

  return (
    <MetricsStep
      metrics={wizardState.config.metricsStep.metrics}
      intervalLabel={intervalLabel}
      goToNextStep={goToNextStep}
      goToPreviousStep={goToPreviousStep}
      isNextStepEnabled={isNextStepEnabled}
      isSaving={isSaving}
      isReadOnly={isReadOnly}
    />
  );
};

export default MetricsStepContainer;
