import { useContext } from 'react';
import PeriodsContext from 'contexts/PeriodsContext';
import SelectedEntityContext from '../contexts/SelectedEntityContext';
import SlideOutContext from '../contexts/SlideOutContext';
import { ENTITY_VALUE_QUERY_PARAM } from '../providers/SelectedEntityProvider';
import { PERIOD_QUERY_PARAM } from '../providers/SelectedEntityProvider';
import useGetFieldLabel from 'hooks/useGetFieldLabel';

const useCommentsWidgetProps = () => {
  const { selectedEntity, entityField } = useContext(SelectedEntityContext);
  const { selectedPeriod } = useContext(PeriodsContext);
  const { board } = useContext(SlideOutContext);
  const { getFieldLabel } = useGetFieldLabel();

  if (!selectedPeriod) {
    return {};
  }

  const commentableId = `${selectedEntity} - ${selectedPeriod.startDate}`;
  const commentableType = board.id;
  const emailLink = `${window.location.pathname}?${PERIOD_QUERY_PARAM}=${selectedPeriod.startDate}&${ENTITY_VALUE_QUERY_PARAM}=${selectedEntity}`;

  const mentionedEmailBody = `mentioned you on a ${getFieldLabel({
    field: entityField,
    dataType: board.dataType,
  })}`;

  const watcherEmailBody = `commented on an ${getFieldLabel({
    field: entityField,
    dataType: board.dataType,
  })} - ${selectedEntity}`;

  return {
    commentableId,
    commentableType,
    emailLink,
    mentionedEmailBody,
    watcherEmailBody,
  };
};

export default useCommentsWidgetProps;
