import React, { ChangeEvent, useContext, useEffect } from 'react';
import Revenue from './Revenue';
import NewCommitSlideOutContext from '../../../../../contexts/NewCommitSlideOutContext';
import formatFloat from '../../../../../api/getChartSeries/formatFloat';

const RevenueContainer = () => {
  // State
  const {
    draftCommit,
    setDraftCommit,
    hasManualLineHaulCharge,
    setHasManualLineHaulCharge,
    showWarnings,
  } = useContext(NewCommitSlideOutContext);
  const { rate, mileage, lineHaulCharge } = draftCommit;

  // Effects
  useEffect(() => {
    if (hasManualLineHaulCharge) {
      return;
    }
    if (!rate || !mileage) {
      return;
    }

    const newLineHaul = formatFloat(rate * mileage, 2);
    if (draftCommit.lineHaulCharge === newLineHaul) {
      // Break out of loop
      return;
    }
    const newDraft = {
      ...draftCommit,
      lineHaulCharge: newLineHaul,
    };
    setDraftCommit(newDraft);
  }, [
    rate,
    mileage,
    lineHaulCharge,
    hasManualLineHaulCharge,
    draftCommit,
    setDraftCommit,
  ]);

  // Interactions
  const onRateChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const newRate = Number.parseFloat(event.target.value);
    if (isNaN(newRate)) {
      const newCommit = {
        ...draftCommit,
        rate: 0,
      } as Commitment;
      setDraftCommit(newCommit);
    } else {
      const newCommit = {
        ...draftCommit,
        rate: Number.parseFloat(
          Number.parseFloat(event.target.value).toFixed(2),
        ),
      } as Commitment;
      setDraftCommit(newCommit);
    }
  };

  const onMileageChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const newCommit = {
      ...draftCommit,
      mileage: Number.parseFloat(event.target.value),
    } as Commitment;
    setDraftCommit(newCommit);
  };

  const onLineHaulChargeChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const newCommit = {
      ...draftCommit,
      lineHaulCharge: Number.parseFloat(event.target.value),
    } as Commitment;
    setHasManualLineHaulCharge(true);
    setDraftCommit(newCommit);
  };

  return (
    <Revenue
      rate={rate}
      onRateChanged={onRateChanged}
      mileage={mileage}
      onMileageChanged={onMileageChanged}
      lineHaulCharge={lineHaulCharge}
      onLineHaulChargeChanged={onLineHaulChargeChanged}
      showWarnings={showWarnings}
    />
  );
};

export default RevenueContainer;
