import React from 'react';

interface ReportContextType {
  report?: PersistedReportType;
  isEditing?: boolean;
  currentScope: FilterPlate[];
  currentDrillDowns: FilterPlate[];
  reset: () => void;
  isExporting?: boolean;
  setIsExporting?: (bool: boolean) => void;
  gridExportCallbacks?: GridExportCallback[];
  setGridExportCallbacks?: React.Dispatch<
    React.SetStateAction<GridExportCallback[]>
  >;
}
const ReportContext = React.createContext<ReportContextType>(
  {} as ReportContextType,
);

export default ReportContext;
