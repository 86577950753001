import React, { useCallback, useContext, useEffect, useState } from 'react';
import EntityDefinitionsContext from 'contexts/EntityDefinitionsContext';
import useDatasetDef from 'components/Inputs/FilterBuilder/useDatasetDef';
import useGetFieldLabel from 'hooks/useGetFieldLabel';

const useFieldGreenList = ({
  editingEntity,
  entityDataset,
  secondaryFields,
}: {
  editingEntity?: EntityDetails.Entity;
  entityDataset: string | undefined;
  secondaryFields: string[];
}) => {
  const { entityDefinitions, isLoading } = useContext(EntityDefinitionsContext);
  const datasetDef = useDatasetDef(entityDataset);

  const buildGreenList = useCallback(() => {
    if (datasetDef === undefined || isLoading) {
      return [];
    }

    const datasetFields = datasetDef.fields
      .filter((f) => f.type === 'text')
      .map((f) => f.field);
    return datasetFields
      .filter((f1) => {
        // Not used in secondary fields
        return !secondaryFields.some((f2) => f1 === f2);
      })
      .filter((f1) => {
        // Not used in another entity def
        return !entityDefinitions
          .filter((e) => {
            if (!editingEntity) {
              return true;
            }
            return editingEntity.id !== e.id;
          })
          .some((e) => {
            const isPrimary = e.primaryField === f1;
            const isSecondary = e.secondaryFields.some((f2) => f2 === f1);
            return isPrimary || isSecondary;
          });
      });
  }, [
    datasetDef,
    editingEntity,
    entityDefinitions,
    isLoading,
    secondaryFields,
  ]);
  const [greenList, setGreenList] = useState<string[]>(buildGreenList);
  useEffect(() => {
    setGreenList(buildGreenList());
  }, [buildGreenList]);

  return greenList;
};

const usePrimaryFieldOptions = ({
  entityDataset,
  primaryField,
  setPrimaryField,
  secondaryFields,
  editingEntity,
}: {
  entityDataset: string | undefined;
  primaryField: string | undefined;
  setPrimaryField: React.Dispatch<React.SetStateAction<string | undefined>>;
  secondaryFields: string[];
  editingEntity?: EntityDetails.Entity;
}) => {
  const { getFieldLabel } = useGetFieldLabel();
  const fieldGreenList = useFieldGreenList({
    entityDataset,
    secondaryFields,
    editingEntity,
  });
  const buildOptions = useCallback(() => {
    if (!entityDataset) {
      return [];
    }

    return fieldGreenList.map((f) => ({
      value: f,
      label: getFieldLabel({ field: f, dataType: entityDataset }),
      isSelected: primaryField === f,
      onSelected: () => {
        setPrimaryField(f);
      },
    }));
  }, [
    fieldGreenList,
    getFieldLabel,
    entityDataset,
    primaryField,
    setPrimaryField,
  ]);
  const [options, setOptions] = useState<DropdownOption[]>(buildOptions);
  useEffect(() => {
    setOptions(buildOptions());
  }, [buildOptions]);

  return options;
};

export default usePrimaryFieldOptions;
