import { useCallback, useContext, useEffect, useState } from 'react';
import PerformanceWizardContext from './PerformanceWizardContext';

const useIntervalLabel = () => {
  const { wizardState } = useContext(PerformanceWizardContext);

  const getLabel = useCallback(() => {
    const { intervalLength, interval } = wizardState.config.datasetStep;
    if (intervalLength === 1) {
      return interval;
    }
    return `${intervalLength} ${interval}s`;
  }, [wizardState.config.datasetStep]);
  const [label, setLabel] = useState<string>(() => getLabel());
  useEffect(() => {
    setLabel(getLabel());
  }, [getLabel]);

  return label;
};

export default useIntervalLabel;
