import React from 'react';

interface WallboardContextType {
  wallboard?: Wallboard;
  isWallboard: boolean;
  isWallboardBuilder?: boolean;
  weatherMapZoom?: '4' | '5' | '6' | '7';
  dashboardZoom: number;
}

const WallboardContext = React.createContext<WallboardContextType>({
  isWallboard: false,
  dashboardZoom: 1,
});

export default WallboardContext;
