import React, { useCallback } from 'react';
import useDatasets from './useDatasets';
import DatasetDefinitionsContext from '../../contexts/DatasetDefinitionsContext';
import usePerformanceDatasetConfigs from './usePerformanceDatasetConfigs';

const DatasetDefinitionsProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { datasets, isLoading, performanceDatasets } = useDatasets();
  const {
    performanceConfigs,
    performanceMetricIds,
    refreshPerformanceConfigs,
    isLoading: isLoadingPerformanceSets,
  } = usePerformanceDatasetConfigs(datasets);

  const getPerformanceConfig = useCallback(
    (dataset: string) => {
      return performanceConfigs.find((c) => c.dataType === dataset);
    },
    [performanceConfigs],
  );

  return (
    <DatasetDefinitionsContext.Provider
      value={{
        datasets,
        performanceDatasets,
        performanceConfigs,
        getPerformanceConfig,
        performanceMetricIds,
        refreshPerformanceConfigs,
        isLoading: isLoading || isLoadingPerformanceSets,
      }}
    >
      {children}
    </DatasetDefinitionsContext.Provider>
  );
};

export default DatasetDefinitionsProvider;
