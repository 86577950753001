import withoutNulls from '../../../api/search/withoutNulls';
import firebase from 'firebase/compat/app';

const createRecentlyUsedRollingWindow = async ({
  userId,
  window,
  accountRef,
}: {
  userId: string;
  window: RecentlyUsedRollingWindow;
  accountRef: firebase.firestore.DocumentReference;
}) => {
  if (process.env.NODE_ENV === 'test') {
    return;
  }
  await accountRef
    .collection('users')
    .doc(userId)
    .collection('recentlyUsedRollingWindows')
    .add(withoutNulls(window));
};

export default createRecentlyUsedRollingWindow;
