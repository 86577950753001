import captureException from 'services/captureException';
import isGauge from 'types/visTypeCheckers/isGauge';
import isPaceMatrix from 'types/visTypeCheckers/isPaceMatrix';
import isRankingMatrix from 'types/visTypeCheckers/isRankingMatrix';
import isRemindersGadget from 'types/visTypeCheckers/isRemindersGadget';
import isSingleMetricDateMatrix from 'types/visTypeCheckers/isSingleMetricDateMatrix';
import isV5ChartDef from 'types/visTypeCheckers/isV5ChartDef';

const isFilterUsedInChart = (
  filter: DatasetFilter,
  def: VisualisationDefinition,
  getIsUsedInMetricId: ({
    filter,
    metricId,
  }: {
    filter: DatasetFilter;
    metricId: string;
  }) => boolean,
) => {
  if (isV5ChartDef(def)) {
    return def.series.some((s) =>
      getIsUsedInMetricId({ filter, metricId: s.metricId }),
    );
  } else if (isSingleMetricDateMatrix(def)) {
    return getIsUsedInMetricId({ filter, metricId: def.metricId });
  } else if (isRankingMatrix(def)) {
    return def.metrics.some((m) =>
      getIsUsedInMetricId({ filter, metricId: m.metricId }),
    );
  } else if (isGauge(def)) {
    return getIsUsedInMetricId({ filter, metricId: def.metricId });
  } else if (isRemindersGadget(def)) {
    return false;
  } else if (isPaceMatrix(def)) {
    return getIsUsedInMetricId({ filter, metricId: def.metricId });
  } else {
    const e = new Error();
    e.name = `Unknown definition type`;
    captureException(e);
    return false;
  }
};

export default isFilterUsedInChart;
