import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import Typography from 'kingpin/atoms/Typography';
import usePopup from 'hooks/usePopup';
import { FILTER_PLATE_HEIGHT, FILTER_PLATE_MAX_WIDTH } from './Interactive';
import Icon from 'kingpin/atoms/Icon';
import { Link } from 'react-router-dom';
import SPECIFIC_SET_ROUTES from 'screens/DataManager/SpecificSet/routes';
import Colors2 from 'theme/Colors2';
import DetailsCard from 'screens/DataManager/DatasetFilters/FilterSlideOut/DetailsCard';
import DatasetFiltersContext from 'screens/DataManager/DatasetFilters/context/DatasetFiltersContext';
import ReportDrillDownsContext from 'contexts/ReportDrillDownsContext';
import Tooltip from 'components/Tooltip';
import { PortalsContext } from '../../contextProviders/PortalsProvider';

const OuterDiv = styled.div<{ isOpen: boolean }>`
  max-width: ${FILTER_PLATE_MAX_WIDTH}px;
  height: ${FILTER_PLATE_HEIGHT}px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
  padding: 0px 4px;
  background-color: ${(props) => (props.isOpen ? '#EFF3FF' : '#E6ECFF')};
`;

const CrossDiv = styled.div`
  display: flex;
  margin-right: 4px;

  &:hover {
    opacity: 0.5;
  }
`;

const DatasetPlate = ({
  datasetFilter,
  onRemoved,
}: {
  datasetFilter: DatasetFilter;
  onRemoved: () => void;
}) => {
  const { selectedPortal } = useContext(PortalsContext);
  const { isOpen, open, close } = usePopup();

  return (
    <Tooltip isAltTooltip content={<DetailsCard filter={datasetFilter} />}>
      <OuterDiv
        isOpen={isOpen}
        onMouseEnter={open}
        onMouseLeave={close}
        data-testid={`${datasetFilter.name}-plate`}
      >
        <CrossDiv onClick={onRemoved}>
          <Icon icon={'cross'} />
        </CrossDiv>

        <Link
          to={SPECIFIC_SET_ROUTES.buildFilters({
            dataset: datasetFilter.dataset,
            filterId: datasetFilter.id,
            portal: selectedPortal,
          })}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <div style={{ display: 'flex', marginRight: '4px' }}>
            <Typography.Body type="Button Text" isEllipsis>
              {datasetFilter.name}
            </Typography.Body>
          </div>

          <div style={{ display: 'flex' }}>
            <Icon icon="open-link" color={Colors2.Secondary.info} />
          </div>
        </Link>
      </OuterDiv>
    </Tooltip>
  );
};

const Gate = ({
  datasetFilterId,
  isScope,
}: {
  datasetFilterId: string;
  isScope: boolean;
}) => {
  const { datasetFilterIdLookup } = useContext(DatasetFiltersContext);
  const { drillDowns, setDrillDowns, scope, setScope } = useContext(
    ReportDrillDownsContext,
  );

  const datasetFilter = datasetFilterIdLookup[datasetFilterId];

  const onRemoved = useCallback(() => {
    if (isScope) {
      setScope(scope.filter((d) => d.id !== datasetFilterId));
    } else {
      setDrillDowns(drillDowns.filter((d) => d.id !== datasetFilterId));
    }
  }, [datasetFilterId, drillDowns, isScope, scope, setDrillDowns, setScope]);

  if (!datasetFilter) {
    return null;
  }

  return <DatasetPlate datasetFilter={datasetFilter} onRemoved={onRemoved} />;
};

export default Gate;
