import React, { useContext } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';

import GroupInput from './GroupInput';
import TargetFormContext from '../contexts/TargetFormContext';
import TargetsDragHandleContext from '../contexts/TargetsDragHandleContext';
import { GROUPS_DROPPABLE_ID } from '../constants';
import GroupListsWrapper from './GroupsListWrapper';

const DraggableWrapper = ({
  group,
  index,
  children,
}: {
  group: Targets.Wizard.TargetGroup;
  index: number;
  children: JSX.Element | JSX.Element[];
}) => (
  <Draggable draggableId={group.key} index={index} type={GROUPS_DROPPABLE_ID}>
    {(draggableProvider) => (
      <>
        <div
          ref={draggableProvider.innerRef}
          {...draggableProvider.draggableProps}
        >
          <TargetsDragHandleContext.Provider
            value={{
              isGroupBlock: true,
              dragHandleProps: draggableProvider.dragHandleProps
                ? draggableProvider.dragHandleProps
                : undefined,
            }}
          >
            {children}
          </TargetsDragHandleContext.Provider>
        </div>
      </>
    )}
  </Draggable>
);

const DroppableWrapper = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  return (
    <Droppable droppableId={GROUPS_DROPPABLE_ID} type={GROUPS_DROPPABLE_ID}>
      {(provided) => (
        <div ref={provided.innerRef}>
          {children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

const GroupsList = () => {
  const { groups, isTargetedByGroup } = useContext(TargetFormContext);
  const fallbackGroup = groups.find((g) => g.isFallback);
  const normalGroups = groups.filter((g) => !g.isFallback);

  if (!fallbackGroup && isTargetedByGroup) {
    return null;
  }

  if (isTargetedByGroup) {
    return (
      <GroupListsWrapper>
        <DroppableWrapper>
          {normalGroups.map((group, index) => (
            <DraggableWrapper key={group.key} group={group} index={index}>
              <GroupInput key={group.key} group={group} />
            </DraggableWrapper>
          ))}
        </DroppableWrapper>
        <>
          {fallbackGroup && (
            <GroupInput key={fallbackGroup.key} group={fallbackGroup} />
          )}
        </>
      </GroupListsWrapper>
    );
  }

  return (
    <GroupListsWrapper>
      {normalGroups.map((group) => (
        <GroupInput key={group.key} group={group} />
      ))}
    </GroupListsWrapper>
  );
};

export default GroupsList;
