import React, { useContext } from 'react';
import DetailsSlideOutComments from './DetailsSlideOutComments';
import DetailsSlideOutContext from '../../contexts/DetailsSlideOutContext';

const DetailsSlideOutCommentsContainer = () => {
  const { selectedOrderNumber } = useContext(DetailsSlideOutContext);
  if (!selectedOrderNumber) {
    return null;
  }

  return <DetailsSlideOutComments selectedOrderNumber={selectedOrderNumber} />;
};

export default DetailsSlideOutCommentsContainer;
