import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import TargetProgressSlideoutWizard from './TargetProgressSlideoutWizard';
import CurrentUserContext from '../../../../../contexts/CurrentUserContext';
import getIdentifier from '../../../../../getIdentifier';
import useSaveWidget from '../../../hooks/useSaveWidget';
import getTimeStamp from '../../../../../getTimeStamp';
import useTargetFieldOptions from './useTargetFieldOptions';

const TargetProgressSlideoutWizardContainer = ({
  close,
  editingSection,
}: {
  close: () => void;
  editingSection:
    | PerformanceBoardTypes.SlideOut.TargetProgressSection
    | undefined;
}) => {
  const { id: currentUserId } = useContext(CurrentUserContext);

  const [id] = useState<string>(
    editingSection ? editingSection.id : getIdentifier(),
  );
  const [createdBy] = useState<string>(
    editingSection ? editingSection.createdBy : currentUserId,
  );
  const [createdOn] = useState<string>(
    editingSection ? editingSection.createdOn : getTimeStamp(),
  );
  const [title, setTitle] = useState<string>(
    editingSection ? editingSection.title : '',
  );
  const [fields, setFields] = useState<string[]>(
    editingSection ? editingSection.fields : [],
  );
  const targetFieldOptions = useTargetFieldOptions({ fields, setFields });
  const [section, setSection] = useState<
    PerformanceBoardTypes.SlideOut.TargetProgressSection | undefined
  >();

  const saveWidget = useSaveWidget({
    section,
    editingSection,
  });

  const isDisabled = fields.length === 0 || title === '';

  const onTitleChanged = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setTitle(event.target.value);
  };

  useEffect(() => {
    if (isDisabled) {
      setSection(undefined);
      return;
    }

    const newSection: PerformanceBoardTypes.SlideOut.TargetProgressSection = {
      id: id,
      createdBy: createdBy,
      updatedBy: currentUserId,
      createdOn: createdOn,
      updatedOn: getTimeStamp(),
      sectionType: 'Target Progress',
      title: title,
      fields: fields,
    };

    setSection(newSection);
  }, [
    createdBy,
    createdOn,
    currentUserId,
    editingSection,
    fields,
    id,
    isDisabled,
    title,
  ]);

  return (
    <TargetProgressSlideoutWizard
      saveWidget={saveWidget}
      title={title}
      onTitleChanged={onTitleChanged}
      targetFieldOptions={targetFieldOptions}
      isDisabled={isDisabled}
      sectionDraft={section}
      close={close}
    />
  );
};

export default TargetProgressSlideoutWizardContainer;
