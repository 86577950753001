import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import Typography from 'kingpin/atoms/Typography';
import { Body12 } from 'kingpin/atoms/Typography/Body';
import Colors2 from '../../../theme/Colors2';
import DateFieldPicker from '../DateFieldPicker';
import RelativeDateBuilder from './RelativeDateBuilder';
import Tooltip from '../../Tooltip';
import Divider from '../../Common/Card/Divider';
import Icon from '../../../kingpin/atoms/Icon';

const List = styled.div`
  padding: 8px 0px;
  background-color: white;
`;
const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  padding-left: 8px;
  padding-right: 16px;
  height: 32px;

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background-color: ${Colors2.Primary['8']}80;
  }
`;

const GREY = Colors2.Grey['1'];
const PURPLE = Colors2.Primary['1'];

const AnimatedLabel = styled(Body12)<{ isAlerting: boolean }>`
  @keyframes color-change {
    0% {
      color: ${GREY};
    }
    30% {
      color: ${PURPLE};
    }
    70% {
      color: ${PURPLE};
    }
    100% {
      color: ${GREY};
    }
  }

  ${(props) =>
    props.isAlerting ? 'animation: color-change 1.5s infinite;' : ''}
  ${(props) => (props.isAlerting ? 'font-weight: 600;' : '')}
`;

const RollingWrapper = styled.div``;

const FromLabel = ({ fromType }: { fromType: 'last' | 'current' | 'next' }) => {
  const [isAlerting, setIsAlerting] = useState<boolean>(false);
  const [isFromEndOf, setIsFromEndOf] = useState<boolean>(fromType === 'next');

  const lastPropsRef = useRef<{
    fromType: 'last' | 'current' | 'next';
  }>({
    fromType,
  });

  useEffect(() => {
    if (_.isEqual(lastPropsRef.current, { fromType })) {
      return;
    }

    const newIsFromEndOf = fromType === 'next';
    setIsFromEndOf(newIsFromEndOf);
    if (newIsFromEndOf !== isFromEndOf) {
      setIsAlerting(true);
    }
    lastPropsRef.current = { fromType };
  }, [fromType, isFromEndOf]);

  useEffect(() => {
    if (!isAlerting) {
      return;
    }

    const timeout = setTimeout(() => {
      setIsAlerting(false);
    }, 1500);
    return () => {
      clearTimeout(timeout);
    };
  }, [isAlerting]);

  const getLabel = useCallback(() => {
    if (isFromEndOf) {
      return 'end';
    } else {
      return 'start';
    }
  }, [isFromEndOf]);

  return (
    <>
      <Typography.Body type="Body 12">From the </Typography.Body>
      <AnimatedLabel isAlerting={isAlerting} isEllipsis={false}>
        {getLabel()}
      </AnimatedLabel>
      <Typography.Body type="Body 12"> of</Typography.Body>
    </>
  );
};

const ToLabel = ({
  fromType,
  toType,
}: {
  fromType: 'last' | 'current' | 'next';
  toType: 'last' | 'current' | 'next';
}) => {
  const [isAlerting, setIsAlerting] = useState<boolean>(false);
  const [isFromEndOf, setIsFromEndOf] = useState<boolean>(
    fromType === 'next' || toType === 'next',
  );

  const lastPropsRef = useRef<{
    fromType: 'last' | 'current' | 'next';
    toType: 'last' | 'current' | 'next';
  }>({
    fromType,
    toType,
  });

  useEffect(() => {
    if (_.isEqual(lastPropsRef.current, { fromType, toType })) {
      return;
    }

    const newIsFromEndOf = fromType === 'next' || toType === 'next';
    setIsFromEndOf(newIsFromEndOf);
    if (newIsFromEndOf !== isFromEndOf) {
      setIsAlerting(true);
    }
    lastPropsRef.current = { fromType, toType };
  }, [fromType, isFromEndOf, toType]);

  useEffect(() => {
    if (!isAlerting) {
      return;
    }

    const timeout = setTimeout(() => {
      setIsAlerting(false);
    }, 1500);
    return () => {
      clearTimeout(timeout);
    };
  }, [isAlerting]);

  const getLabel = useCallback(() => {
    if (isFromEndOf) {
      return 'end';
    } else {
      return 'start';
    }
  }, [isFromEndOf]);

  return (
    <>
      <Typography.Body type="Body 12">To the </Typography.Body>
      <AnimatedLabel isAlerting={isAlerting} isEllipsis={false}>
        {getLabel()}
      </AnimatedLabel>
      <Typography.Body type="Body 12"> of</Typography.Body>
    </>
  );
};

const RelativeInput = ({
  quickOptions,
  updateRelativeDate,
  updateAdvancedDate,
  relativeDate,
  advancedRelativeDate,
  mode,
  setMode,
  lastRollingRef,
  lastAdvancedRef,
  isInvalid,
  maxWidth,
}: {
  quickOptions: { label: string; onClick: () => void; isSelected: boolean }[];
  updateRelativeDate: (newR: RelativeDateRange) => void;
  updateAdvancedDate: (newA: AdvancedRelativeDateRange) => void;
  relativeDate?: RelativeDateRange;
  advancedRelativeDate?: AdvancedRelativeDateRange;
  mode: 'rolling' | 'advanced' | 'custom';
  setMode: React.Dispatch<
    React.SetStateAction<'rolling' | 'advanced' | 'custom'>
  >;
  lastRollingRef: React.MutableRefObject<RelativeDateRange>;
  lastAdvancedRef: React.MutableRefObject<AdvancedRelativeDateRange>;
  isInvalid?: boolean;
  maxWidth?: number | undefined;
}) => {
  return (
    <div style={{ maxWidth }}>
      <RollingWrapper>
        {mode !== 'advanced' && (
          <div style={{ marginTop: 12 }}>
            <RelativeDateBuilder
              testPrefix={''}
              current={
                mode === 'rolling' && relativeDate
                  ? relativeDate
                  : lastRollingRef.current
              }
              setCurrent={updateRelativeDate}
              isPlaceholder={mode === 'custom'}
              onModeTogglePressed={() => {
                setMode('advanced');
                updateAdvancedDate(lastAdvancedRef.current);
              }}
            />
          </div>
        )}
        {mode === 'advanced' && !!advancedRelativeDate && (
          <div style={{ marginTop: 12 }}>
            {isInvalid && (
              <div style={{ marginBottom: 8 }}>
                <div style={{ paddingLeft: 8 }}>
                  <Typography.Body
                    type="Body 12"
                    color={Colors2.Secondary.error}
                  >
                    Invalid date: end date is before start date
                  </Typography.Body>
                </div>
              </div>
            )}
            <div style={{ marginBottom: 8 }}>
              <div style={{ paddingLeft: 8 }}>
                <FromLabel fromType={advancedRelativeDate.from.type} />
              </div>
              <div>
                <RelativeDateBuilder
                  isAdvanced
                  testPrefix={'from-'}
                  current={advancedRelativeDate.from}
                  setCurrent={(newDate: RelativeDateRange) =>
                    updateAdvancedDate({
                      from: newDate,
                      to: advancedRelativeDate.to,
                    })
                  }
                />
              </div>
            </div>
            <div>
              <div style={{ paddingLeft: 8 }}>
                <ToLabel
                  fromType={advancedRelativeDate.from.type}
                  toType={advancedRelativeDate.to.type}
                />
              </div>
              <RelativeDateBuilder
                isAdvanced
                testPrefix={'to-'}
                current={advancedRelativeDate.to}
                setCurrent={(newDate: RelativeDateRange) =>
                  updateAdvancedDate({
                    from: advancedRelativeDate.from,
                    to: newDate,
                  })
                }
                onModeTogglePressed={() => {
                  setMode('rolling');
                  updateRelativeDate(lastRollingRef.current);
                }}
              />
            </div>
          </div>
        )}
      </RollingWrapper>
      <Divider style={{ marginBottom: 8 }} />
      <List>
        <div style={{ paddingLeft: 8 }}>
          <Typography.Body type="Body 12">Recently Used</Typography.Body>
        </div>
        {quickOptions.map((o) => (
          <Tooltip content={`${o.label}`} key={o.label}>
            <ListItem onClick={o.onClick}>
              <Typography.Body
                type="Body 12"
                color={o.isSelected ? Colors2.Secondary.info : undefined}
              >
                {o.label}
              </Typography.Body>
              {o.isSelected && (
                <Icon color={Colors2.Secondary.info} icon="checkmark" />
              )}
            </ListItem>
          </Tooltip>
        ))}
      </List>
      <Divider style={{ marginBottom: 8 }} />
      <div
        style={{
          marginBottom: 8,
          paddingLeft: 8,
          paddingRight: 8,
        }}
      >
        <DateFieldPicker />
      </div>
    </div>
  );
};

export default RelativeInput;
