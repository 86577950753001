import { useCallback, useContext, useEffect, useState } from 'react';
import STORE from '../../store';
import AccountPickerContext from '../../contexts/AccountPickerContext';

const useEntityDetailsApps = () => {
  const { selectedAccountId } = useContext(AccountPickerContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [entityDetailsApps, setEntityDetailsApps] = useState<
    EntityDetails.App[]
  >([]);

  useEffect(() => {
    let isActive = true;
    setIsLoading(true);
    setEntityDetailsApps([]);

    STORE.contentDistributions
      .getEntityDetailsApps({ accountId: selectedAccountId })
      .onSnapshot((snapShot) => {
        if (!isActive) {
          return;
        }

        const newApps: EntityDetails.App[] = [];
        snapShot.docs.forEach((d) => {
          newApps.push(d.data());
        });

        setEntityDetailsApps(newApps.filter((a) => !a.isHidden));
        setIsLoading(false);
      });

    return () => {
      isActive = false;
    };
  }, [selectedAccountId]);

  const localUpdateOrAddApp = useCallback((app: EntityDetails.App) => {
    setEntityDetailsApps((currentApps) => {
      const isUpdating = currentApps.some((a) => a.id === app.id);
      if (isUpdating) {
        return currentApps.map((a) => {
          if (a.id === app.id) {
            return app;
          }
          return a;
        });
      } else {
        return [...currentApps, app];
      }
    });
  }, []);

  return {
    entityDetailsApps,
    localUpdateOrAddApp,
    isLoading,
  };
};

export default useEntityDetailsApps;
