import { useCallback, useContext } from 'react';
import _ from 'lodash';

import metricTypeCheckers from '../../types/metricTypeCheckers';
import CompoundMetricsContext from '../../contexts/CompoundMetricsContext';

const useGetCompoundUsage = () => {
  const { allCompoundMetrics } = useContext(CompoundMetricsContext);

  const getCompoundUsage = useCallback(
    (
      metric: Metrics.NormalMetric | Metrics.CompoundMetric,
    ): Metrics.CompoundMetric[] => {
      if (metricTypeCheckers.isCompoundMetric(metric)) {
        return [];
      }

      const usage = [] as Metrics.CompoundMetric[];
      allCompoundMetrics
        .filter((cm) => cm.status !== 'archived')
        .forEach((cm) => {
          if (cm.metricIds.some((mid) => mid === metric.id)) {
            usage.push(cm);
          }
        });

      return _.uniqBy(usage, 'id');
    },
    [allCompoundMetrics],
  );

  return getCompoundUsage;
};

export default useGetCompoundUsage;
