import React, { useCallback, useMemo } from 'react';
import useGroupValuesOptions from './hooks/useGroupValuesOptions';
import GroupButtonOverride from './GroupButtonOverride';
import ConfirmationButton from './ConfirmationButton';
import Inputs from 'components/Inputs';

const GroupListItem = ({
  group,
  groupNameValues,
  wizardState,
  setWizardState,
  dataType,
  genGroupName,
  isEditing,
}: {
  group: Costs.Group | undefined;
  groupNameValues: string[];
  wizardState: Costs.WizardState;
  setWizardState: React.Dispatch<React.SetStateAction<Costs.WizardState>>;
  dataType: string | undefined;
  genGroupName: ({ fields }: { fields: string[] }) => string;
  isEditing: boolean;
}) => {
  const onRemoveClicked = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      if (!group) {
        return;
      }
      setWizardState((wizardState) => ({
        ...wizardState,
        config: {
          ...wizardState.config,
          groupStep: {
            ...wizardState.config.groupStep,
            groups:
              wizardState.config.groupStep.groups.length === 2
                ? []
                : wizardState.config.groupStep.groups.filter(
                    (g) => g.groupName !== group.groupName,
                  ),
          },
        },
      }));
    },
    [group, setWizardState],
  );

  const isAllOther =
    !!group &&
    group.groupName === `All Other ${wizardState.config.groupStep.field}(s)`;

  const popupOptions = useGroupValuesOptions({
    dataType,
    groupNameValues,
    editingName: group ? group.groupName : undefined,
  });

  const onEditClick = useCallback(() => {
    if (!group) {
      return;
    }

    setWizardState((wizardState) => ({
      ...wizardState,
      config: {
        ...wizardState.config,
        groupStep: {
          ...wizardState.config.groupStep,
          currentGroup: group.fields || [],
        },
      },
    }));
  }, [group, setWizardState]);

  const handleUpdate = useCallback(() => {
    if (!group) {
      return;
    }

    const newGroups = wizardState.config.groupStep.groups.map((g) => {
      if (g.groupName === group.groupName) {
        const fields = wizardState.config.groupStep.currentGroup;
        return {
          groupName: genGroupName({ fields }),
          fields,
        };
      } else {
        return g;
      }
    });

    setWizardState((wizardState) => ({
      ...wizardState,
      config: {
        ...wizardState.config,
        groupStep: {
          ...wizardState.config.groupStep,
          groups: newGroups,
        },
      },
    }));
  }, [
    genGroupName,
    group,
    setWizardState,
    wizardState.config.groupStep.currentGroup,
    wizardState.config.groupStep.groups,
  ]);

  const ButtonOverrideComponent = useMemo(
    () =>
      GroupButtonOverride({
        isAllOther,
        isEditing,
        onEditClick,
        onRemoveClicked,
        isDraft: wizardState.isDraft,
        group,
      }),
    [
      group,
      isAllOther,
      isEditing,
      onEditClick,
      onRemoveClicked,
      wizardState.isDraft,
    ],
  );

  const ConfirmationButtonComponent = useMemo(
    () =>
      ConfirmationButton({
        popupOptions,
        handleUpdate,
        isEditing: true,
      }),
    [handleUpdate, popupOptions],
  );

  if (!group) {
    return null;
  }

  return (
    <Inputs.ExclusiveDropdown
      placeholder={'Add Costs For:'}
      options={popupOptions}
      isAlreadyInUseDisabled
      ConfirmationButton={ConfirmationButtonComponent}
      ButtonOverride={ButtonOverrideComponent}
    />
  );
};

export default GroupListItem;
