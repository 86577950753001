import firebase from 'firebase/compat/app';
import STORE_CONSTANTS from '../constants';
import withoutNulls from '../../api/search/withoutNulls';

export const TARGET_APPS_DOC = 'targetsApps';
export const CURRENT_TARGET_APPS_VERSION = 'v1';

// TargetsApp.ClientTab is a superset of TargetsApp.PersistedTab.
// This can cause typescript to not warn us about writing
// the client version into firestore
// This transformer handles that case
const clientTabToPersisted = (
  tab: TargetsApp.ClientTab | TargetsApp.PersistedTab,
): TargetsApp.PersistedTab => {
  if (tab.typeId === undefined) {
    return {
      type: tab.type,
    };
  }

  return {
    type: tab.type,
    typeId: tab.typeId,
  };
};

const targetAppSateConverter = {
  toFirestore(app: TargetsApp.App): firebase.firestore.DocumentData {
    return withoutNulls({ ...app, tabs: app.tabs.map(clientTabToPersisted) });
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ): TargetsApp.App {
    const data = {
      ...snapshot.data(options),
    };
    return data as TargetsApp.App;
  },
};

const getTargetsApps = ({
  accountId,
  db,
}: {
  accountId: string;
  db?: firebase.firestore.Firestore;
}) => {
  return (db ? db : firebase.firestore())
    .collection('accounts')
    .doc(accountId)
    .collection(STORE_CONSTANTS.CONTENT_DISTRIBUTIONS_COLLECTION)
    .doc(TARGET_APPS_DOC)
    .collection(CURRENT_TARGET_APPS_VERSION)
    .withConverter(targetAppSateConverter);
};

export default getTargetsApps;
