import React, { useCallback, useContext, useState } from 'react';
import CalendarInput from './CalendarInput';
import DateInputContext from '../../../contexts/DateInputContext';
import moment from 'moment';
import AnalyticsContext from '../../../contexts/AnalyticsContext';
import useDateScope from '../../../hooks/useDateScope';
import PeriodsContext from '../../../contexts/PeriodsContext';

const CalendarInputContainer = ({
  onClosed,
  isDateFieldPickerDisabled,
}: {
  onClosed: () => void;
  isDateFieldPickerDisabled?: boolean;
}) => {
  const {
    setDateRange,
    setRelativeDateRange,
    setAdvancedRelativeDateRange,
    setIsManuallyEntered,
  } = useContext(DateInputContext);
  const { setSelectedPeriod } = useContext(PeriodsContext);
  const [currentlySelecting, setCurrentlySelecting] = useState<
    'startDate' | 'endDate'
  >('startDate');
  const netDateRange = useDateScope({});
  const { trackEvent } = useContext(AnalyticsContext);

  const startDate = netDateRange.startDate
    ? netDateRange.startDate
    : moment().format('YYYY-MM-DD');
  const endDate = netDateRange.endDate
    ? netDateRange.endDate
    : moment().format('YYYY-MM-DD');

  const onDateRangeUpdated = useCallback(
    (newDate: DateRangeInput) => {
      setDateRange(newDate);
      setRelativeDateRange(undefined);
      setAdvancedRelativeDateRange(undefined);
      setIsManuallyEntered(true);
      setSelectedPeriod(undefined);
    },
    [
      setAdvancedRelativeDateRange,
      setDateRange,
      setIsManuallyEntered,
      setRelativeDateRange,
      setSelectedPeriod,
    ],
  );

  const isValidRange = useCallback((range: DateRangeInput) => {
    if (!range.startDate || !moment(range.startDate).isValid()) {
      return false;
    }
    if (!range.endDate || !moment(range.endDate).isValid()) {
      return false;
    }
    if (
      !!range.startDate &&
      !!range.endDate &&
      range.endDate < range.startDate
    ) {
      return false;
    }

    return true;
  }, []);

  const onStartDateUpdated = useCallback(
    (newDate: string) => {
      const newDateRange = {
        ...netDateRange,
        startDate: newDate,
        endDate:
          netDateRange.endDate && netDateRange.endDate < newDate
            ? newDate
            : netDateRange.endDate,
      };
      if (!isValidRange(newDateRange)) {
        return;
      }
      onDateRangeUpdated(newDateRange);
      setCurrentlySelecting('endDate');
      trackEvent('DateInput - Query date selected');
    },
    [isValidRange, netDateRange, onDateRangeUpdated, trackEvent],
  );

  const onEndDateUpdated = useCallback(
    (newDate: string) => {
      const newDateRange = {
        ...netDateRange,
        startDate:
          netDateRange.startDate && netDateRange.startDate > newDate
            ? newDate
            : netDateRange.startDate,
        endDate: newDate,
      };
      if (!isValidRange(newDateRange)) {
        return;
      }
      onDateRangeUpdated(newDateRange);
      trackEvent('DateInput - Query date selected');
    },
    [netDateRange, isValidRange, onDateRangeUpdated, trackEvent],
  );

  const onCalendarDateChange = (newDate: Date) => {
    const newDateIso = moment(newDate.toDateString()).format('YYYY-MM-DD');
    if (currentlySelecting === 'startDate') {
      onStartDateUpdated(newDateIso);
      trackEvent('DateInput - Calendar Start Date Picked');
    } else {
      onEndDateUpdated(newDateIso);
      trackEvent('DateInput - Calendar End Date Picked');
    }
    setRelativeDateRange(undefined);
  };

  return (
    <CalendarInput
      currentlySelecting={currentlySelecting}
      setCurrentlySelecting={setCurrentlySelecting}
      startDate={startDate}
      onStartDateUpdated={onStartDateUpdated}
      endDate={endDate}
      onEndDateUpdated={onEndDateUpdated}
      onCalendarDateChange={onCalendarDateChange}
      onClosed={onClosed}
      isDateFieldPickerDisabled={isDateFieldPickerDisabled}
    />
  );
};

export default CalendarInputContainer;
