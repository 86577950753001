import { GRID_PAGE_SIZE } from '../../constants';
import { ColDef, GridOptions } from 'ag-grid-community';

export const BASE_GRID_OPTIONS: GridOptions = {
  sideBar: false,
  animateRows: false,
  suppressColumnMoveAnimation: false,
  enableRangeSelection: false,
  suppressPropertyNamesCheck: true,
  suppressColumnVirtualisation: process.env.NODE_ENV === 'test',
  enableBrowserTooltips: true,
  sortingOrder: ['desc', 'asc', null],
  blockLoadDebounceMillis: 300,
  // https://github.com/ag-grid/ag-grid/issues/6562#issuecomment-1547488910
  suppressBrowserResizeObserver: true,
};

// Do not enable reactiveCustomComponents, despite the warnings
// It breaks adding reason codes on a board

const buildGridOptions = ({
  columnDefs = [],
  rowHeight,
  rowModelType = 'serverSide',
  suppressColumnVirtualisation,
}: {
  columnDefs?: ColDef[];
  rowModelType?: 'serverSide' | 'clientSide';
  rowHeight: number; // See: RowHeightProvider
  suppressColumnVirtualisation?: boolean;
}): GridOptions => ({
  columnDefs,
  rowModelType,
  rowHeight,
  cacheBlockSize:
    rowModelType && rowModelType === 'clientSide' ? undefined : GRID_PAGE_SIZE,
  ...BASE_GRID_OPTIONS,
  columnTypes: {
    dimension: {
      enableRowGroup: true,
    },
    valueColumn: {
      aggFunc: 'sum',
      enableValue: true,
      cellClass: 'number',
      allowedAggFuncs: ['avg', 'sum', 'min', 'max'],
    },
  },
  defaultColDef: {
    sortable: true,
    cellRenderer: 'normalCellRenderer',
    cellRendererParams: {
      isLeftAlign: true,
    },
    wrapHeaderText: true,
    autoHeaderHeight: false,
    resizable: true,
    headerComponentParams: {
      template:
        '<div class="ag-cell-label-container" role="presentation">' +
        '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
        '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
        '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
        '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
        '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
        '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
        '  </div>' +
        '</div>',
    },
  },
  suppressColumnVirtualisation:
    suppressColumnVirtualisation || process.env.NODE_ENV === 'test',
  singleClickEdit: true,
  stopEditingWhenCellsLoseFocus: true,
});

export default buildGridOptions;
