import React, { useCallback, useState } from 'react';
import ChartColorsContext from 'contexts/ChartColorsContext';
import chartColors from '../theme/chartColors';

const ChartColorsProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [assignedColors, setAssignedColors] = useState<{
    [key: string]: string | undefined;
  }>({});

  const getChartColor = useCallback(
    (term: string): string => {
      const assignedColor = assignedColors[term];
      if (assignedColor !== undefined) {
        return assignedColor;
      }

      let nextColor = chartColors[0];
      setAssignedColors((current) => {
        if (Object.keys(current).length === 0) {
          return {
            ...current,
            [term]: nextColor,
          };
        }

        const nextColorIndex = Object.keys(current).length;
        nextColor = chartColors[nextColorIndex];
        return {
          ...current,
          [term]: nextColor,
        };
      });

      return nextColor;
    },
    [assignedColors],
  );

  const getChartColorIndex = useCallback(
    (term: string): number | undefined => {
      const assignedColor = assignedColors[term];
      if (assignedColor !== undefined) {
        return chartColors.findIndex((c) => c === assignedColor);
      }
      return undefined;
    },
    [assignedColors],
  );

  return (
    <ChartColorsContext.Provider
      value={{
        getChartColor,
        getChartColorIndex,
      }}
    >
      {children}
    </ChartColorsContext.Provider>
  );
};

export default ChartColorsProvider;
