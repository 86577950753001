import React, { useContext } from 'react';
import CanvasCard from '../../Visualisations/CanvasCard';
import { ChartTopBar } from '../../Chart';
import BarContent from './BarContent';
import DashboardGadgetBottomBar from '../../DashboardGadgetBottomBar';
import DashboardGadgetContext from '../../../contexts/DashboardGadgetContext';

const BarContainer = ({
  chartDefinition,
  chartRef,
  isHorizontal,
  isCombo,
  useSmallNoDataMessage,
}: {
  chartDefinition: V5ChartDefinition;
  chartRef: React.MutableRefObject<any>;
  isHorizontal?: boolean;
  isCombo?: boolean;
  useSmallNoDataMessage: boolean;
}) => {
  const { dashboardGadget } = useContext(DashboardGadgetContext);

  return (
    <CanvasCard
      topBar={<ChartTopBar chartDefinition={chartDefinition} />}
      content={
        <BarContent
          chartDefinition={chartDefinition}
          chartRef={chartRef}
          isHorizontal={isHorizontal}
          isCombo={isCombo}
          useSmallNoDataMessage={useSmallNoDataMessage}
        />
      }
      bottomBar={dashboardGadget ? <DashboardGadgetBottomBar /> : undefined}
    />
  );
};

export default BarContainer;
