import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import getIdentifier from 'getIdentifier';
import { CURRENT_ENTITY_DEFINITIONS_VERSION } from 'store/getEntityDefinitionsRef';
import usePrimaryDatasetOptions from './usePrimaryDatasetOptions';
import usePrimaryFieldOptions from './usePrimaryFieldOptions';
import useSecondaryFieldOptions from './useSecondaryFieldOptions';
import useContextFieldOptions from './useContextFieldOptions';
import fiToDrillDowns from '../../Metrics/MetricPopup/MetricForm/NormalMetricForm/fiToDrillDowns';
import { toPlates } from '../../../Boards/PerformanceBoardForm';
import DatasetDefinitionsContext from '../../../../contexts/DatasetDefinitionsContext';
import { usePlatesToFilterInput } from '../../../GoalShow/useGoalData';
import getTimeStamp from '../../../../getTimeStamp';
import CurrentUserContext from '../../../../contexts/CurrentUserContext';

const useFormState = (entity: EntityDetails.Entity | undefined) => {
  const { id: currentUserId } = useContext(CurrentUserContext);
  const { datasets } = useContext(DatasetDefinitionsContext);
  const [id] = useState<string>(entity ? entity.id : getIdentifier());
  const [name, setName] = useState<string>(entity ? entity.name : '');
  const onNameChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const newName = event.target.value;
    setName(newName);
  }, []);

  const [primaryField, setPrimaryField] = useState<string | undefined>(
    entity ? entity.primaryField : undefined,
  );
  const [access, setAccess] = useState<ResourceAccess>(
    entity
      ? entity.access
      : {
          type: 'Admin Only',
          version: '2',
        },
  );
  const [entityDataset, setEntityDataset] = useState<string | undefined>(
    entity ? entity.entityDataset : undefined,
  );
  const [secondaryFields, setSecondaryFields] = useState<string[]>(
    entity ? entity.secondaryFields : [],
  );
  const [contextFields, setContextFields] = useState<string[]>(
    entity ? entity.contextFields : [],
  );
  const [isActiveFilters, setIsActiveFilters] = useState<FilterPlate[]>(() =>
    entity ? toPlates(fiToDrillDowns(entity.isActiveFilter, datasets)) : [],
  );
  const [isEnabled, setIsEnabled] = useState<boolean>(
    entity ? entity.isEnabled : false,
  );
  const platesToFilterInput = usePlatesToFilterInput();

  const entityDatasetOptions = usePrimaryDatasetOptions({
    entityDataset,
    setPrimaryField,
    setEntityDataset,
    setSecondaryFields,
  });
  const primaryFieldOptions = usePrimaryFieldOptions({
    entityDataset,
    primaryField,
    setPrimaryField,
    secondaryFields,
    editingEntity: entity,
  });
  const secondaryFieldOptions = useSecondaryFieldOptions({
    entityDataset,
    primaryField,
    secondaryFields,
    setSecondaryFields,
    editingEntity: entity,
  });
  const contextFieldOptions = useContextFieldOptions({
    entityDataset,
    primaryField,
    contextFields,
    setContextFields,
  });

  const getNewDefinition = useCallback((): EntityDetails.Entity | undefined => {
    if (
      name &&
      primaryField &&
      entityDataset &&
      (secondaryFields.length > 0 || contextFields.length > 0)
    ) {
      return {
        id,
        name,
        version: CURRENT_ENTITY_DEFINITIONS_VERSION,
        entityDataset,
        primaryField,
        access,
        secondaryFields,
        contextFields,
        isEnabled,
        isActiveFilter: platesToFilterInput(isActiveFilters),
        updatedBy: currentUserId,
        updatedOn: getTimeStamp(),
        createdBy: entity ? entity.createdBy : currentUserId,
        createdOn: entity ? entity.createdOn : getTimeStamp(),
      };
    }
    return undefined;
  }, [
    access,
    contextFields,
    currentUserId,
    entity,
    entityDataset,
    id,
    isActiveFilters,
    isEnabled,
    name,
    platesToFilterInput,
    primaryField,
    secondaryFields,
  ]);

  const [isValid, setIsValid] = useState<boolean>(false);
  useEffect(() => {
    setIsValid(!!getNewDefinition());
  }, [getNewDefinition]);

  return {
    name,
    onNameChanged,
    entityDataset,
    entityDatasetOptions,
    primaryFieldOptions,
    access,
    setAccess,
    secondaryFieldOptions,
    contextFieldOptions,
    isActiveFilters,
    setIsActiveFilters,
    isEnabled,
    setIsEnabled,
    getNewDefinition,
    isValid,
  };
};

export default useFormState;
