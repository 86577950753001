import React from 'react';
import { ModalTransition } from '@atlaskit/modal-dialog';

import usePopup from 'hooks/usePopup';
import SettingsButton from 'components/CustomerLaneCommitsBoard/Settings/SettingsButton';
import SettingsWizard from './SettingsWizard/SettingsWizard';
import PerformanceBoardSettingsProvider from './providers/PerformanceBoardSettingsProvider';

const PerformanceBoardSettingsContainer = () => {
  const { isOpen, open, close } = usePopup();

  return (
    <>
      <SettingsButton open={open} isOpen={isOpen} close={close} />
      <ModalTransition>
        {isOpen && (
          <PerformanceBoardSettingsProvider>
            <SettingsWizard close={close} />
          </PerformanceBoardSettingsProvider>
        )}
      </ModalTransition>
    </>
  );
};

export default PerformanceBoardSettingsContainer;
