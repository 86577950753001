import React, { useContext, useEffect, useState } from 'react';

import DeleteCommitmentPopup from './DeleteCommitmentPopup';
import CommitmentQueryContext from '../../../contexts/CommitmentQueryContext';
import usePopup from '../../../hooks/usePopup';
import deleteCommitment from '../../../api/commitments/deleteCommitment';
import AccountPickerContext from '../../../contexts/AccountPickerContext';

const DeleteCommitmentPopupContainer = () => {
  const { accountRef } = useContext(AccountPickerContext);

  // State
  const { mode, selectedCommitmentDoc, setSelectedCommitmentDoc, setMode } =
    useContext(CommitmentQueryContext);
  const { isOpen, open, close } = usePopup();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  // Effects
  useEffect(() => {
    if (!isOpen) {
      if (mode === 'delete') {
        open();
      }
    }
  }, [isOpen, mode, open]);

  // Functions
  const onClose = () => {
    setSelectedCommitmentDoc(undefined);
    setMode('create');
    close();
  };

  const onDeleteConfirmed = () => {
    if (!selectedCommitmentDoc) {
      return;
    }

    setIsSaving(true);
    deleteCommitment(selectedCommitmentDoc, accountRef).then(() => {
      setIsSaving(false);
      onClose();
    });
  };

  return (
    <DeleteCommitmentPopup
      isOpen={isOpen}
      close={onClose}
      onDeleteConfirmed={onDeleteConfirmed}
      isSaving={isSaving}
    />
  );
};

export default DeleteCommitmentPopupContainer;
