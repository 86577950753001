import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import GoalsContext from 'contexts/GoalsContext';
import buildCardLayout from 'contextProviders/WidgetGalleryProvider/buildCardLayout';
import findStartingY from 'contextProviders/WidgetGalleryProvider/findStartingY';
import AddGoalForm from './AddGoalForm';

const AddGoalFormContainer = ({
  close,
  isOpen,
  setCurrentCanvas,
  startEditing,
  canvasMode,
}: {
  close: () => void;
  isOpen: boolean;
  setCurrentCanvas: React.Dispatch<React.SetStateAction<Canvas>>;
  startEditing: () => void;
  canvasMode: CanvasMode;
}) => {
  const { goals } = useContext(GoalsContext);

  const [searchText, setSearchText] = useState<string>('');
  const [filteredGoals, setFilteredGoals] = useState<GeneralGoal[]>(goals);
  const [selectedGoalId, setSelectedGoalId] = useState<string | undefined>();

  const onAddClicked = useCallback(() => {
    if (!selectedGoalId) {
      return;
    }

    setCurrentCanvas((c) => {
      const newCard = (() => {
        return {
          layout: {
            ...buildCardLayout(0, findStartingY(c.cards, canvasMode)),
            h: 20,
            w: 12,
            minH: 20,
            minW: 12,
          },
          content: {
            type: 'Goal' as 'Goal',
            goalId: selectedGoalId,
          },
        };
      })();

      return {
        ...c,
        cards: [...c.cards, newCard],
      };
    });
    startEditing();
    close();
  }, [canvasMode, close, selectedGoalId, setCurrentCanvas, startEditing]);

  const onSearchTextChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
    },
    [],
  );

  const onGoalClicked = useCallback((goal: GeneralGoal) => {
    setSelectedGoalId(goal.id);
  }, []);

  useEffect(() => {
    setFilteredGoals(
      goals.filter((goal) =>
        goal.title.toLowerCase().includes(searchText.toLowerCase()),
      ),
    );
  }, [goals, searchText]);
  return (
    <AddGoalForm
      isOpen={isOpen}
      close={close}
      searchText={searchText}
      selectedGoalId={selectedGoalId}
      onSearchTextChanged={onSearchTextChanged}
      filteredGoals={filteredGoals}
      onGoalClicked={onGoalClicked}
      onAddClicked={onAddClicked}
    />
  );
};

export default AddGoalFormContainer;
