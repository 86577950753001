import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import Button from 'kingpin/atoms/Button';
import ScorecardContext from 'contexts/ScorecardContext';
import CommentsProvider from 'contextProviders/CommentsProvider';
import CommentsContext from 'contexts/CommentsContext';
import SlideOutContext from '../contexts/SlideoutContext';
import useKpiValue from '../hooks/useKpiValue';
import useTargetBands from '../hooks/useTargetBands';
import useKpiFormatter from '../hooks/useKpiFormatter';
import useOpenKpiPopup from '../hooks/useOpenKpiPopup';
import useScorecardPopupCommentId from 'hooks/useScorecardPopupCommentId';
import { getColName } from '../ColumnHeadingsRight';

import Typography from 'kingpin/atoms/Typography';
import Row from 'components/Common/Row';
import NoteWizard from './NoteWizard';
import SlideoutHeader from './SlideoutHeader';
import SlideOut from './Slideout';
import KpiGrid from './KpiGrid';
import TargetProgress from './TargetProgress';
import KpiHighcharts from './KpiHighcharts';
import NoteView from './NoteView';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

const SlideOutContainer = ({
  period,
  kpi,
  trend,
  metric,
}: {
  period: Period;
  kpi: Scorecards.ScorecardKpi | Scorecards.ManualKpiRow;
  trend: Goals.TrendResult[];
  metric?: Metrics.Metric;
}) => {
  const { scorecard } = useContext(ScorecardContext);
  const getKpiValue = useKpiValue();
  const value = getKpiValue({ trend, period });
  const { name, formattedValue, manualKpiFormatting } = useKpiFormatter({
    kpi,
    value,
    metric,
  });
  const { successThreshold } = useTargetBands(kpi, period);
  const onClick = useOpenKpiPopup(kpi, period);

  const { comments } = useContext(CommentsContext);
  const [note, setNote] = useState<CommentType | undefined>();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  useEffect(() => {
    setIsEditing(false);
  }, [kpi, period, trend, metric]);

  useEffect(() => {
    if (comments.length === 0) {
      setNote(undefined);
      return;
    }

    setNote(comments[comments.length - 1]);
  }, [comments]);

  return (
    <SlideOut>
      <Content>
        <SlideoutHeader name={name} />
        <div
          style={{
            height: '100%',
            overflow: 'scroll',
            padding: '16px 24px',
          }}
        >
          <Row spaceBetween style={{ marginBottom: '24px' }}>
            <div>
              <div style={{ marginBottom: 8 }}>
                <Typography.Header type="H5">
                  {getColName(period, scorecard.cadence, true)}
                </Typography.Header>
              </div>
              <div style={{ marginBottom: 12 }}>
                <Typography.Header type="H2">
                  {formattedValue || '-'}
                </Typography.Header>
              </div>
              <TargetProgress
                isReversed={kpi.isColorOrderReversed}
                successThreshold={successThreshold}
                value={value}
                metric={metric}
                manualKpiFormatting={manualKpiFormatting}
              />
            </div>
            <Row>
              {!note && (
                <Button
                  type="Tertiary"
                  size="Small"
                  label="Add Note"
                  icon="comment"
                  onClick={() => {
                    setIsEditing(true);
                  }}
                />
              )}

              {(!!kpi.reportDrillDownId || !!kpi.boardDrillDownId) &&
                onClick && (
                  <div style={{ marginLeft: '16px' }}>
                    <Button
                      type="Primary"
                      size="Small"
                      label="Drilldown"
                      icon="chart-filled"
                      onClick={onClick}
                    />
                  </div>
                )}
            </Row>
          </Row>
          {isEditing && (
            <NoteWizard
              lastComment={note}
              period={period}
              kpi={kpi}
              name={name}
              setIsEditing={setIsEditing}
            />
          )}
          {note && !isEditing && (
            <NoteView note={note} setIsEditing={setIsEditing} />
          )}
          <KpiHighcharts
            kpi={kpi}
            trend={trend}
            metric={metric}
            slideoutPeriod={period}
          />
          <KpiGrid
            kpi={kpi}
            trend={trend}
            metric={metric}
            slideoutPeriod={period}
          />
        </div>
      </Content>
    </SlideOut>
  );
};

const Gate = () => {
  const { slideoutKpi, slideoutPeriod, slideoutTrend, slideoutMetric } =
    useContext(SlideOutContext);
  const { commentableId, commentableType } = useScorecardPopupCommentId({
    period: slideoutPeriod,
    kpi: slideoutKpi,
  });

  if (!slideoutKpi || !slideoutPeriod || !slideoutTrend) {
    return null;
  }

  return (
    <CommentsProvider
      commentableType={commentableType}
      commentableId={commentableId}
    >
      <SlideOutContainer
        kpi={slideoutKpi}
        period={slideoutPeriod}
        trend={slideoutTrend}
        metric={slideoutMetric}
      />
    </CommentsProvider>
  );
};

export default Gate;
