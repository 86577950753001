import React, { ChangeEvent } from 'react';
import styled from 'styled-components';

import TopBarInner from '../TopBarInner';
import Typography from 'kingpin/atoms/Typography';
import AddButton from '../AddButton';
import MetricPopupConstants from '../constants';
import FilterMenu from './FilterMenu';
import MetricListFilterPlates from './MetricListFilterPlates';
import Tooltip from '../../../../../components/Tooltip';
import MetricListItem from './MetricListItem';
import MEDIA from '../../../../../media';
import TextInput from '../../../../../kingpin/atoms/TextInput';
import Row from '../../../../../components/Common/Row';

const SideBarDiv = styled.div`
  height: calc(${MetricPopupConstants.POPUP_HEIGHT} - 79px);
  display: flex;
  flex-direction: column;

  @media ${MEDIA.QUERIES.SMALL_HEIGHT} {
    height: calc(${MetricPopupConstants.POPUP_HEIGHT_SMALL} - 57px);
  }
`;

const ListOuterDiv = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const SideBarContentDiv = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;

  padding-left: ${MetricPopupConstants.PADDING};
  padding-right: ${MetricPopupConstants.PADDING};
`;

const ListInnerDiv = styled.div`
  overflow-y: auto;
`;

const HeadingDiv = styled.div`
  padding: ${MetricPopupConstants.PADDING};
  @media ${MEDIA.QUERIES.SMALL_HEIGHT} {
    padding: ${MetricPopupConstants.PADDING_SMALL};
  }
`;

const SideBar = ({
  onNewClicked,
  searchText,
  onSearchTextChanged,
  metrics,
  selectedMetric,
  setSelectedMetric,
  filterPlates,
  hasUnsavedChanges,
}: {
  onNewClicked: () => void;
  searchText: string;
  onSearchTextChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  metrics: (Metrics.NormalMetric | Metrics.CompoundMetric)[];
  selectedMetric?: Metrics.NormalMetric | Metrics.CompoundMetric;
  setSelectedMetric: React.Dispatch<
    React.SetStateAction<
      Metrics.NormalMetric | Metrics.CompoundMetric | undefined
    >
  >;
  filterPlates: { key: string; label: string; onRemoved: () => void }[];
  hasUnsavedChanges: boolean;
}) => (
  <SideBarDiv>
    <HeadingDiv>
      <TopBarInner>
        <Typography.Header type="H5">All Metrics</Typography.Header>
        <Tooltip content="Add New Metric">
          <AddButton onClick={onNewClicked} />
        </Tooltip>
      </TopBarInner>
    </HeadingDiv>
    <SideBarContentDiv>
      <div style={{ marginBottom: 16 }}>
        <Row spaceBetween centerAlign style={{ position: 'relative' }}>
          <TextInput
            value={searchText}
            onChange={onSearchTextChanged}
            icon="search"
            placeholder="Search"
            width="100%"
            autoFocus
          />
          <div style={{ position: 'absolute', right: 0, top: 0 }}>
            <FilterMenu filteredMetrics={metrics} />
          </div>
        </Row>
        <MetricListFilterPlates filterPlates={filterPlates} />
      </div>
      <ListOuterDiv>
        <ListInnerDiv>
          {metrics.map((m) => (
            <MetricListItem
              metric={m}
              key={m.id}
              selectedMetric={selectedMetric}
              hasUnsavedChanges={hasUnsavedChanges}
              setSelectedMetric={setSelectedMetric}
            />
          ))}
        </ListInnerDiv>
      </ListOuterDiv>
    </SideBarContentDiv>
  </SideBarDiv>
);

export default SideBar;
