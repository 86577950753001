import React from 'react';
import DriverDataHeaderSection from './DriverDataHeaderSection';
import { useSectionData } from '../BoardDataCardSection';

const DriverDataHeaderSectionContainer = ({
  section,
}: {
  section: BoardSlideOutDataSection;
}) => {
  const { data } = useSectionData(section);

  if (data.length === 0) {
    return null;
  }

  return (
    <DriverDataHeaderSection
      section={section}
      data={data[0] as { [key: string]: number | string | undefined }}
    />
  );
};

export default DriverDataHeaderSectionContainer;
