import React from 'react';
import Typography from 'kingpin/atoms/Typography';
import Loading from '../../../../Loading';

import styled from 'styled-components';
import { FieldItem } from '../../KeywordPicker/KeywordPicker';

const HEIGHT = 180;

const List = styled.div`
  max-height: ${HEIGHT}px;
  overflow-y: scroll;
  padding-top: 8px;
`;

const Preview = ({
  previewItems,
  onItemSelected,
  isLoadingPreviewItems,
}: {
  previewItems: string[];
  onItemSelected: (item: string) => void;
  isLoadingPreviewItems: boolean;
}) => (
  <div style={{ padding: '0px 8px' }}>
    {isLoadingPreviewItems && (
      <div style={{ height: HEIGHT }}>
        <Loading isSmall />
      </div>
    )}
    {!isLoadingPreviewItems && previewItems.length > 0 && (
      <List>
        <Typography.Body type="Label" color={'#8E8E8E'}>
          Similar Matches Found
        </Typography.Body>
        {previewItems.map((i) => (
          <FieldItem
            key={i}
            style={{ padding: '4px 0px' }}
            onClick={() => onItemSelected(i)}
          >
            <Typography.Body type="Button Text">{i}</Typography.Body>
          </FieldItem>
        ))}
      </List>
    )}
    {!isLoadingPreviewItems && previewItems.length === 0 && (
      <p>No items matched your expression</p>
    )}
  </div>
);

export default Preview;
