import React from 'react';
import HighchartStyles from 'components/HighchartStyles';
import FlexCentered from 'components/Common/FlexCentered';
import Loading from 'components/Loading/Loading';
import useOptions from './useOptions';
import ChartV2 from 'components/ChartV2';
import KpiTooltip from './KpiTooltip';
import Card from 'components/Common/Card';

const KpiHighcharts = ({
  kpi,
  trend,
  metric,
  slideoutPeriod,
}: {
  kpi: Scorecards.ScorecardKpi | Scorecards.ManualKpiRow;
  trend: Goals.TrendResult[];
  metric?: Metrics.Metric;
  slideoutPeriod: Period;
}) => {
  const { options, isLoading } = useOptions({
    kpi,
    metric,
    trend,
    slideoutPeriod,
  });

  if (!trend || !options || isLoading) {
    return (
      <FlexCentered style={{ height: '100%' }}>
        <Loading />
      </FlexCentered>
    );
  }

  return (
    <div data-testid={'chart-preview'}>
      <Card
        style={{ height: '208px', marginBottom: '16px', paddingTop: '8px' }}
      >
        <HighchartStyles
          style={{
            width: '100%',
            height: '100%',
          }}
          isGoalTrendLine
        >
          <ChartV2 options={options} TooltipComponent={KpiTooltip} />
        </HighchartStyles>
      </Card>
    </div>
  );
};

export default KpiHighcharts;
