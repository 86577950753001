import React from 'react';
import ListWrapper from '../ListWrapper';
import List from '../List';
import Inputs from '../../../../components/Inputs';
import Row from '../../../../components/Common/Row';
import Loading from '../../../../components/Loading';
import Headers from './Headers';
import ListItem from './ListItem';
import TextInput from '../../../../kingpin/atoms/TextInput';

const getKey = (item: DataManager.DatasetListItem): string => {
  if (item.type === 'savedWizard') {
    return item.content.firestoreId || Math.random().toString(10);
  } else if (item.type === 'performanceConfig') {
    return `perf-config-${item.content.id}`;
  } else {
    return `baseView-${item.content.type}`;
  }
};

const DatasetsIndex = ({
  searchText,
  onSearchTextChanged,
  sortByOptions,
  isLoading,
  datasetList,
}: {
  searchText: string;
  onSearchTextChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
  sortByOptions: DropdownOption[];
  isLoading: boolean;
  datasetList: DataManager.DatasetListItem[];
}) => (
  <div>
    <div style={{ padding: '16px  24px' }}>
      <Row centerAlign spaceBetween>
        <div style={{ width: 400 }}>
          <TextInput
            value={searchText}
            onChange={onSearchTextChanged}
            icon="search"
            inputSize="Small"
            placeholder="Search"
            autoFocus
          />
        </div>
        <div>
          <Inputs.Dropdown options={sortByOptions} label="Sort By" unsetWidth />
        </div>
      </Row>
    </div>
    <ListWrapper>
      <Headers />
      <List>
        {isLoading && <Loading />}
        {!isLoading && (
          <>
            {datasetList.map((item) => (
              <ListItem key={getKey(item)} item={item} />
            ))}
          </>
        )}
      </List>
    </ListWrapper>
  </div>
);

export default DatasetsIndex;
