import React, { useCallback, useContext } from 'react';
import Row from 'components/Common/Row';
import Button from 'kingpin/atoms/Button';
import AnalyticsContext from 'contexts/AnalyticsContext';

const WidgetFooter = ({
  saveWidget,
  sectionType,
  isDisabled,
  close,
}: {
  saveWidget: () => void;
  sectionType: PerformanceBoardTypes.SlideOut.SectionType;
  isDisabled: boolean;
  close: () => void;
}) => {
  const { trackEvent } = useContext(AnalyticsContext);

  const saveWidgetHandler = useCallback(() => {
    saveWidget();
    trackEvent('Board - Slide out - Save Widget Clicked', {
      sectionType,
    });
  }, [saveWidget, sectionType, trackEvent]);

  const cancelHandle = useCallback(() => {
    close();
    trackEvent('Board - Slide out - Edit Cancelled');
  }, [close, trackEvent]);
  return (
    <Row spaceBetween centerAlign style={{ height: '72px', padding: '0 32px' }}>
      <Button
        onClick={cancelHandle}
        label={'Cancel'}
        type="Tertiary"
        size="Small"
      />
      <Button
        label="Save Widget"
        onClick={saveWidgetHandler}
        isDisabled={isDisabled}
        type="Primary"
        size="Small"
      />
    </Row>
  );
};

export default WidgetFooter;
