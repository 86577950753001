import React from 'react';

import Colors2 from '../../../theme/Colors2';
import Row from '../../../components/Common/Row';
import { Body14 } from '../../../kingpin/atoms/Typography/Body';
import Icon from '../../../kingpin/atoms/Icon';

const PerformanceDatasetsSaveWarning = ({
  metricName,
  affectedDatasets,
}: {
  metricName: string;
  affectedDatasets: { id: string; name: string }[];
}) => (
  <Row
    style={{
      backgroundColor: Colors2.AvatarColors['8'].background,
      padding: 16,
      marginBottom: 32,
    }}
  >
    <div style={{ marginRight: 8 }}>
      <Icon icon="warning" color={Colors2.AvatarColors['8'].text} />
    </div>

    <Body14 isEllipsis={false}>
      <b>{metricName}</b> is used as a field in {affectedDatasets.length}{' '}
      performance datasets.{'\n'}Editing this metric will result in changes to
      the datasets as well as any metrics built using the field on those
      datasets.
      {'\n\n'}
      Used in{'\n'}
      {affectedDatasets.map(({ name }) => `• ${name}`).join('\n')}
    </Body14>
  </Row>
);

export default PerformanceDatasetsSaveWarning;
