import React from 'react';
import Row from 'components/Common/Row';
import Typography from 'kingpin/atoms/Typography';
import Badge from 'components/Badge';
import { ReactComponent as BadgeClose } from '../../images/badge-close.svg';

const FilterBy = ({
  selectedEntity,
  onRemoveClicked,
}: {
  selectedEntity: EntityDetails.Entity;
  onRemoveClicked: () => void;
}) => {
  return (
    <Row centerAlign>
      <div style={{ marginRight: 8 }}>
        <Typography.Body type="Body 12">Filter By:</Typography.Body>
      </div>
      <Badge
        text={selectedEntity.name}
        badgeType={'Default'}
        iconAfter={
          <BadgeClose
            onClick={onRemoveClicked}
            style={{ marginLeft: 8, cursor: 'pointer' }}
          />
        }
      />
    </Row>
  );
};

export default FilterBy;
