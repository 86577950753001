import React, { useContext } from 'react';
import CostsShowContext from '../context/CostsShowContext';
import Loading from 'components/Loading/Loading';
import useGetCostModel from '../CostsShow/hooks/useGetCostModel';
import useIntervalCosts from '../CostsShow/hooks/useIntervalCosts';
import NavSideBarContext from 'contexts/NavSideBarContext';
import { CLOSED_NAV_WIDTH, OPENED_NAV_WIDTH } from 'navigation/styles';

const CostsShowProvider = ({
  children,
  modelType,
}: {
  children: JSX.Element | JSX.Element[];
  modelType: Costs.ModelType;
}) => {
  const { isOpen: isNavOpen } = useContext(NavSideBarContext);
  const {
    costModel,
    isLoading: isLoadingModel,
    refreshModel,
  } = useGetCostModel(modelType);
  const {
    intervalCosts,
    isLoading: isLoadingCosts,
    refreshCosts,
  } = useIntervalCosts(costModel ? costModel.id : undefined);

  if (isLoadingModel || isLoadingCosts) {
    return (
      <div
        style={{
          width: `calc(100vw - ${
            isNavOpen ? OPENED_NAV_WIDTH : CLOSED_NAV_WIDTH
          }px)`,
          height: '100vh',
          display: 'flex',
        }}
      >
        <Loading />
      </div>
    );
  }

  return (
    <CostsShowContext.Provider
      value={{
        costModel,
        refreshModel,
        intervalCosts,
        refreshCosts,
        isLoadingCosts,
        isLoadingModel,
      }}
    >
      {children}
    </CostsShowContext.Provider>
  );
};

export default CostsShowProvider;
