import { useContext, useEffect, useState } from 'react';
import AccountPickerContext from '../../contexts/AccountPickerContext';
import STORE from '../../store';

const useDashboardGadgets = () => {
  const [dashboardGadgets, setDashboardGadgets] = useState<DashboardGadget[]>(
    [],
  );
  const [dashboardGadgetsLookup, setDashboardGadgetsLookup] = useState<{
    [id: string]: DashboardGadget | undefined;
  }>({});
  const [isLoading, setIsLoading] = useState(true);
  const { selectedAccountId } = useContext(AccountPickerContext);

  useEffect(() => {
    setIsLoading(true);

    const listener = STORE.visualisations
      .getDashboardGadgetsRef({ accountId: selectedAccountId })
      .onSnapshot((snapshot) => {
        const data = [] as DashboardGadget[];
        snapshot.docs.forEach((d) => data.push(d.data()));
        setDashboardGadgets(data);
        setIsLoading(false);
      });
    return () => {
      listener();
    };
  }, [selectedAccountId]);

  useEffect(() => {
    const newLookup = {} as { [id: string]: DashboardGadget | undefined };
    dashboardGadgets.forEach((gadget) => {
      newLookup[gadget.id] = gadget;
    });
    setDashboardGadgetsLookup(newLookup);
  }, [dashboardGadgets]);

  return { dashboardGadgets, isLoading, dashboardGadgetsLookup };
};

export default useDashboardGadgets;
