import React, { useCallback, useContext } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import GridCellErrorBoundary from '../GridCellErrorBoundary';
import PerformanceBoardContexts from './contexts';
import Cell from '../V5Gadget/Matrix/Cell';
import Tooltip from '../Tooltip';
import BoardContext from 'contexts/BoardContext';
import isPerformanceBoard from 'isPerformanceBoard';
import RolesContext from 'contexts/RolesContext';
import PERFORMANCE_BOARD_PERMISSIONS from 'permissionDefinitions/performanceBoard';

const PerformanceEntityCell = (props: ICellRendererParams) => {
  const { onEntitySelected } = useContext(
    PerformanceBoardContexts.SelectedEntity,
  );
  const { currentPermissions } = useContext(RolesContext);
  const { board } = useContext(BoardContext);
  const { value } = props;
  const onClick = useCallback(() => {
    if (props.node.rowPinned) {
      return;
    }
    onEntitySelected(value);
  }, [onEntitySelected, props.node.rowPinned, value]);

  if (value === undefined || value === null) {
    return (
      <Cell>
        <span>-</span>
      </Cell>
    );
  }

  if (Array.isArray(value)) {
    const text = value.join(', ');
    const shouldTooltip = text.length && text.length > 14;
    return (
      <Cell>
        <Tooltip content={shouldTooltip ? text : undefined}>
          <span>{text}</span>
        </Tooltip>
      </Cell>
    );
  }

  const hasEditPermission = currentPermissions.includes(
    PERFORMANCE_BOARD_PERMISSIONS.MODIFY_WIDGETS,
  );

  const isLinkDisabled =
    (isPerformanceBoard(board) &&
      board.slideout.length === 0 &&
      !hasEditPermission) ||
    !!props.node.rowPinned;

  return (
    <Cell onClick={onClick} disableLink={isLinkDisabled}>
      <span style={{ color: isLinkDisabled ? undefined : '#0041ea' }}>
        {value}
      </span>
    </Cell>
  );
};

const Gate = (params: ICellRendererParams) => (
  <GridCellErrorBoundary
    params={params}
    childComponent={<PerformanceEntityCell {...params} />}
  />
);

export default Gate;
