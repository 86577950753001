import React, { ChangeEvent } from 'react';
import { Label } from '../../MyAccount/Profile/styles';
import Loading from '../../Loading';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import styled from 'styled-components';
import Card from '../../Common/Card';

const ModalWrapper = styled.div`
  padding: 8px 16px;
`;

const Video = styled.video`
  width: 100%;
`;

const ContentWrapper = styled.div`
  margin-bottom: 8px;
`;

const VideoCardEditForm = ({
  close,
  isOpen,
  videoSrc,
  onChange,
  isUploadingVideo,
}: {
  close: () => void;
  isOpen: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  videoSrc: string;
  isUploadingVideo: boolean;
}) => (
  <ModalTransition>
    {isOpen && (
      <Modal onClose={close} shouldScrollInViewport={false} autoFocus={false}>
        <ModalWrapper>
          <ContentWrapper>
            <Label>Video</Label>
            <input type="file" accept="video/*" onChange={onChange} />
          </ContentWrapper>
          <ContentWrapper>
            {isUploadingVideo && <Loading />}
            {!isUploadingVideo && videoSrc && (
              <Card style={{ padding: 8 }}>
                <Video src={videoSrc} autoPlay loop />
              </Card>
            )}
          </ContentWrapper>
        </ModalWrapper>
      </Modal>
    )}
  </ModalTransition>
);

export default VideoCardEditForm;
