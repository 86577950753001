import React, { ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'kingpin/atoms/Button';
import Typography from 'kingpin/atoms/Typography';

import Colors from 'theme/colors';
import Card from '../Common/Card';
import Branding from '../Branding';
import Inputs from '../Inputs';
import Row from '../Common/Row';
import Colors2 from '../../theme/Colors2';
import PasswordInput from 'screens/AcceptInvite/PasswordInput';

const Legal = styled.div`
  margin-top: 24px;

  a {
    color: ${Colors.LIGHT_TEXT};
    text-decoration: underline;
  }
`;

const Heading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 34px;
`;

const ResetPasswordForm = ({
  email,
  isOnBoardingFlow,
  isLoading,
  name,
  password,
  onPasswordChanged,
  passwordErrors,
  onSubmit,
  isValid,
  isHidingPassword,
  setIsHidingPassword,
}: {
  email: string;
  name: string;
  isOnBoardingFlow: boolean;
  password: string;
  onPasswordChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  passwordErrors?: string[];
  onSubmit: () => void;
  isLoading: boolean;
  isValid: boolean;
  isHidingPassword: boolean;
  setIsHidingPassword: React.Dispatch<React.SetStateAction<boolean>>;
}) => (
  <React.Fragment>
    <Branding />
    <Card style={{ width: 466, padding: '31px 44px 31px 32px' }}>
      <Heading>
        <Typography.Header type={'H4'}>
          {isOnBoardingFlow ? `Welcome ${name}` : 'Reset password'}
        </Typography.Header>
      </Heading>
      <div style={{ marginBottom: 32 }}>
        <Typography.Body type="Label">Email</Typography.Body>
        <Inputs.TextInput
          value={email}
          disabled={true}
          readOnly
          onChange={window.tokenFunction}
          state={'Disabled'}
        />
      </div>
      <PasswordInput
        password={password}
        onPasswordChanged={onPasswordChanged}
        passwordErrors={passwordErrors}
        isHidingPassword={isHidingPassword}
        setIsHidingPassword={setIsHidingPassword}
        label="New Password"
      />
      <Row style={{ justifyContent: 'flex-end' }}>
        <Button
          onClick={onSubmit}
          isDisabled={!isValid || isLoading}
          isLoading={isLoading}
          label={isOnBoardingFlow ? 'Sign up' : 'Save Password'}
          type="Primary"
          size="Small"
        />
      </Row>
    </Card>
    <Legal>
      <Typography.Body type={'Label'} color={Colors2.Grey['5']}>
        By signing up for FleetOps, you agree to our{' '}
        <a
          href={'https://fleetops.com/terms'}
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Service
        </a>{' '}
        and{' '}
        <a
          href={'https://fleetops.com/privacy'}
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
      </Typography.Body>
    </Legal>
  </React.Fragment>
);

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func,
  validatePassword: PropTypes.func,
  isLoading: PropTypes.bool,
  isOnBoardingFlow: PropTypes.bool,
  name: PropTypes.string,
};

export default ResetPasswordForm;
