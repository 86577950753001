import isChartDefinition from './isChartDefinition';
import isDashboardGadget from './isDashboardGadget';
import isIFrame from './isIFrame';
import isImage from './isImage';
import isText from './isText';
import isMetricList from './IsMetricList';
import isVideo from './isVideo';
import isScorecard from './isScorecard';
import isGoal from './isGoal';

const cardTypeCheckers = {
  isChartDefinition,
  isDashboardGadget,
  isIFrame,
  isImage,
  isText,
  isMetricList,
  isVideo,
  isScorecard,
  isGoal,
};

export default cardTypeCheckers;
