import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Typography from 'kingpin/atoms/Typography';

import Button from 'kingpin/atoms/Button';
import Row from '../../Common/Row';

const SelectedCount = styled.div`
  margin-right: 8px;
`;

const Buttons = ({
  style,
  cancel,
  addToReport,
  startConfigureDashboardGadget,
  saving,
  selectedCount,
  isDashboard,
}) => (
  <Row style={{ ...style, justifyContent: 'flex-end', height: 56 }} centerAlign>
    {selectedCount > 0 && (
      <SelectedCount>
        <Typography.Body type={'Body 12'}>
          {`${selectedCount} selected`}
        </Typography.Body>
      </SelectedCount>
    )}
    <Button onClick={cancel} isDisabled={saving}>
      Cancel
    </Button>
    <div style={{ marginRight: 8 }} />
    {isDashboard && selectedCount > 0 && (
      <Button
        onClick={startConfigureDashboardGadget}
        type="Primary"
        size="Small"
        label="Next"
      />
    )}
    {!isDashboard && (
      <Button
        onClick={addToReport}
        isDisabled={saving}
        isLoading={saving}
        type="Primary"
        size="Small"
        label="Add to Report"
      >
        Add to Report
      </Button>
    )}
  </Row>
);

Buttons.propTypes = {
  style: PropTypes.object,
  addToReport: PropTypes.func,
  startConfigureDashboardGadget: PropTypes.func,
  cancel: PropTypes.func,
  saving: PropTypes.bool,
  selectedCount: PropTypes.number,
  isDashboard: PropTypes.bool,
};

export default Buttons;
