import { css } from 'styled-components';

const ShowOnMouseOverCss = css`
  .showOnMouseOver {
    visibility: hidden;
    width: 0px;
  }

  &:hover {
    .showOnMouseOver {
      visibility: visible;
      width: unset;
    }
  }
`;

export default ShowOnMouseOverCss;
