import { useState, useCallback, useEffect } from 'react';

const useFieldPickerState = ({
  dataset,
  fields,
  setFields,
}: {
  dataset: string | undefined;
  fields: string[];
  setFields: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const [datasetGreenList, setDatasetGreenList] = useState<string[]>(
    dataset ? [dataset] : [],
  );
  const [selectedFields, setSelectedFields] = useState<
    {
      dataType: string;
      fields: string[];
    }[]
  >(() => (dataset ? [{ dataType: dataset, fields }] : []));

  const onFieldsConfirmed = useCallback(
    (newSelectedFields: { fields: string[]; dataType: string }[]) => {
      setSelectedFields(newSelectedFields);
      const releventFields = newSelectedFields.find(
        (fieldsInDataset) => fieldsInDataset.dataType === dataset,
      );
      setFields(releventFields ? releventFields.fields : []);
    },
    [dataset, setFields],
  );

  useEffect(() => {
    setDatasetGreenList(dataset ? [dataset] : []);
  }, [dataset]);

  useEffect(() => {
    setSelectedFields(dataset ? [{ dataType: dataset, fields }] : []);
  }, [fields, dataset]);

  return {
    selectedFields,
    onFieldsConfirmed,
    datasetGreenList,
  };
};

export default useFieldPickerState;
