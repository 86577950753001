import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import getMetrics from './getMetrics';
import getLimitSetKeywordFilter from './getLimitSetKeyworkFilter';

const getTopNTermsResponses = async ({
  chartDef,
  filterInput,
  usedMetrics,
  isDashboardGadget,
  currentDateScope,
  previousDateScope,
  toMetricInput,
  client,
  groupByField,
  comparison,
}: {
  chartDef: V5ChartDefinition;
  filterInput: FilterInput;
  usedMetrics: Metrics.Metric[];
  isDashboardGadget?: boolean;
  currentDateScope: DateRangeInput;
  comparison?: PersistedComparisonType;
  previousDateScope: DateRangeInput;
  toMetricInput: (metric: Metrics.NormalMetric | MetricInput) => MetricInput;
  client: ApolloClient<NormalizedCacheObject>;
  groupByField: string;
}) => {
  const groupedMetricsRequest = await getMetrics({
    chartDef,
    filterInput,
    usedMetrics,
    isDashboardGadget,
    dateScope: currentDateScope,
    toMetricInput,
    client,
  });

  const limitedKeywordFilter = getLimitSetKeywordFilter(
    groupedMetricsRequest,
    groupByField,
    chartDef.dimensionA,
  );

  const groupedMetricsComparisonRequest =
    comparison && chartDef.series.some((s) => !!s.matrixCellType)
      ? getMetrics({
          chartDef,
          filterInput,
          usedMetrics,
          isDashboardGadget,
          dateScope: previousDateScope,
          toMetricInput,
          client,
          limitedKeywordFilter,
        })
      : Promise.resolve([]);
  const totalsRequest = getMetrics({
    chartDef,
    filterInput,
    getTotals: true,
    usedMetrics,
    isDashboardGadget,
    dateScope: currentDateScope,
    toMetricInput,
    client,
    limitedKeywordFilter,
  });
  const totalsComparisonRequest =
    comparison && chartDef.series.some((s) => !!s.matrixCellType)
      ? getMetrics({
          chartDef,
          filterInput,
          getTotals: true,
          usedMetrics,
          isDashboardGadget,
          dateScope: previousDateScope,
          toMetricInput,
          client,
          limitedKeywordFilter,
        })
      : Promise.resolve([]);

  const responses = await Promise.all([
    groupedMetricsRequest,
    totalsRequest,
    groupedMetricsComparisonRequest,
    totalsComparisonRequest,
  ]);

  return responses;
};

export default getTopNTermsResponses;
