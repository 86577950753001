import { useEffect, useState } from 'react';

const useMetricTimelineStartsOn = (
  metric: Metrics.NormalMetric | Metrics.CompoundMetric | undefined,
) => {
  const [timelineStartsOn, setTimelineStartsOn] = useState<string | undefined>(
    metric ? metric.createdOn : undefined,
  );

  useEffect(() => {
    setTimelineStartsOn(metric ? metric.createdOn : undefined);
  }, [metric]);

  return timelineStartsOn;
};

export default useMetricTimelineStartsOn;
