import React from 'react';
import { Option } from '../../kingpin/atoms/ContextMenu';

const CopyGadgetButton = ({ onClick }: { onClick: () => void }) => (
  <Option
    label={'Copy Gadget'}
    icon={'copy'}
    data-testid="copy-gadget"
    onClick={onClick}
  />
);

export default CopyGadgetButton;
