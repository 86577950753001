const getLimitSetKeywordFilter = (
  response: MetricsResponse,
  groupByField: string,
  dimension?: Dimension,
): KeywordFilter | undefined => {
  if (groupByField === 'date' || groupByField === 'dayOfWeek' || !dimension) {
    return undefined;
  }

  if (dimension.fieldType !== 'text') {
    return undefined;
  }

  const isBlankTermPresent = (
    response.map((h) => h[groupByField]) as string[]
  ).some((t) => t === '(Blank)');
  const terms = (response.map((h) => h[groupByField]) as string[]).filter(
    (t) => t !== '(Blank)',
  );

  const keywordFilter = {
    field: groupByField,
    values: terms,
    exists: isBlankTermPresent ? false : undefined,
  } as KeywordFilter;

  return keywordFilter;
};

export default getLimitSetKeywordFilter;
