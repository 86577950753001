import STORE from '../store';

const createDashboardGadget = async (
  gadget: DashboardGadget,
  accountId: string,
) => {
  return STORE.visualisations
    .getDashboardGadgetsRef({ accountId })
    .doc(gadget.id)
    .set(gadget);
};

export default createDashboardGadget;
