import firebase from 'firebase/compat/app';
import { DASHBOARDS_COLLECTION } from '../constants';
import withoutNulls from '../api/search/withoutNulls';

const dashboardConverter = {
  toFirestore(
    dashboard: PersistedDashboardType,
  ): firebase.firestore.DocumentData {
    return withoutNulls(dashboard);
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot<PersistedDashboardType>,
    options: firebase.firestore.SnapshotOptions,
  ): PersistedDashboardType {
    return snapshot.data(options);
  },
};

const getDashboardsRef = ({
  accountId,
  db,
}: {
  accountId: string;
  db?: firebase.firestore.Firestore;
}) => {
  return (db ? db : firebase.firestore())
    .collection('accounts')
    .doc(accountId)
    .collection(DASHBOARDS_COLLECTION)
    .withConverter(dashboardConverter);
};

export default getDashboardsRef;
