import styled from 'styled-components';
import Colors from '../theme/colors';

const HighchartStyles = styled.div<{
  isGoalTrendLine?: boolean;
}>`
  ${(props) =>
    props.isGoalTrendLine &&
    `
  .highcharts-series-0 .highcharts-graph,
    .highcharts-series-1 .highcharts-graph {
      stroke: transparent;
    }
  `}

  h1 {
    margin: 20px 0 0;
  }

  .number-chart {
    display: flex;
    flex-direction: column;
    padding: 24px 24px 16px;
  }

  .number-chart-title {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  }

  .number-chart-title-dot {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    margin-right: 4px;
    display: block;
  }

  .orange {
    background: #ffab00;
  }

  .blue {
    background: ${Colors.TEXT};
  }

  .highcharts-tooltip-container {
    z-index: 9999 !important;
  }

  .number-chart-number {
    font-weight: 700;
    margin-bottom: 12px;
  }

  .number-chart-increase {
    display: flex;
    align-items: center;
  }

  .number-chart-increase-arrow {
    background: rgba(54, 179, 126, 0.15);
    border-radius: 12px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 4px;
  }

  .number-chart-increase-number {
    color: #36b37e;
    font-weight: 700;
  }

  .number-chart-increase-since {
    color: #97a0af;
    margin: 1px 0 0;
    margin-left: 6px;
  }

  .type-orange {
    color: #ffab00 !important;
  }

  @import url('https://fonts.googleapis.com/css?family=Roboto:400,700');

  /**
 * @license Highcharts
 *
 * (c) 2009-2016 Torstein Honsi
 *
 * License: www.highcharts.com/license
 */
  .highcharts-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    text-align: left;
    line-height: normal;
    z-index: 0;
    /* #1072 */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-family: Inter, 'Roboto', Arial, Helvetica, sans-serif;
  }

  .highcharts-root {
    display: block;
    font-size: unset !important;
  }

  .highcharts-root text {
    stroke-width: 0;
  }

  .highcharts-strong {
    font-weight: bold;
  }

  .highcharts-emphasized {
    font-style: italic;
  }

  .highcharts-anchor {
    cursor: pointer;
  }

  .highcharts-background {
    fill: none;
  }

  .highcharts-plot-border,
  .highcharts-plot-background {
    fill: none;
  }

  .highcharts-label-box {
    fill: none;
  }

  .highcharts-contextbutton {
  }

  .highcharts-tracker-line {
    stroke-linejoin: round;
    stroke: rgba(192, 192, 192, 0.0001);
    stroke-width: 22;
    fill: none;
  }

  .highcharts-tracker-area {
    fill: rgba(192, 192, 192, 0.0001);
    stroke-width: 0;
  }

  /* Titles */
  .highcharts-title {
    font-family:
      SF Pro Text,
      'Helvetica Neue',
      Helvetica,
      Arial,
      sans-serif;
    fill: ${Colors.TEXT};
    font-weight: 700;
    color: #1a1a1a;
    top: 0px !important;
  }

  .highcharts-subtitle {
    fill: #7cb5ec;
  }

  /* Axes */
  .highcharts-axis {
  }

  .highcharts-axis.highcharts-color-0 .highcharts-axis-line {
    stroke: #36b37e;
  }
  .highcharts-axis.highcharts-color-0 text {
    fill: #1a1a1a;
  }
  .highcharts-axis.highcharts-color-1 .highcharts-axis-line {
    stroke: ${Colors.TEXT};
  }
  .highcharts-axis.highcharts-color-1 text {
    fill: #1a1a1a;
  }

  .highcharts-yaxis .highcharts-axis-line {
    stroke-width: 0;
  }

  /*//bottom axis line*/
  .highcharts-axis-line {
    fill: none;
    stroke: #f7f7fc;
    stroke-width: 1;
  }

  .highcharts-axis-title {
    fill: #97a0af;
  }

  .highcharts-yaxis-labels {
    fill: #878f9d !important;
    cursor: default;
  }

  .highcharts-tick {
    stroke: #fff;
    stroke-width: 0;
  }

  .highcharts-yaxis .highcharts-tick {
    stroke-width: 0;
  }

  .highcharts-minor-grid-line {
    stroke: transparent;
  }

  .highcharts-minor-tick {
    stroke: transparent;
  }

  .highcharts-crosshair-thin {
    stroke-width: 1px;
    stroke: #cccccc;
  }

  .highcharts-crosshair-category {
    stroke: #ccd6eb;
    stroke-opacity: 0.25;
  }

  /* Tooltip */
  .highcharts-tooltip {
    z-index: 99999 !important;
    cursor: default;
    pointer-events: none;
    white-space: nowrap;
    transition: stroke 150ms;
    background: #ffffff;
    /*box-shadow: 0 2px 8px 0 rgba(31,45,61,0.05);*/
  }

  .highcharts-tooltip-box {
    fill: #fff;
    fill-opacity: 1;
    stroke-width: 0;
    background: #ffffff;
    /*box-shadow: 0 2px 8px 0 rgba(31,45,61,0.05);*/
  }

  .highcharts-tooltip text {
    fill: #172b4d;
    padding: 20px !important;
  }

  .highcharts-tooltip .highcharts-strong {
    text-align: right !important;
  }

  .highcharts-tooltip .highcharts-header {
    font-weight: 700;
  }

  .highcharts-tooltip-box .highcharts-label-box {
    fill: #fff;
    fill-opacity: 0.85;
  }

  .highcharts-selection-marker {
    fill: #335cad;
    fill-opacity: 0.25;
  }

  .highcharts-graph {
    fill: none;
    stroke-width: 3px;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .highcharts-state-hover .highcharts-graph {
    stroke-width: 5px;
  }

  .highcharts-state-hover path {
    transition: stroke-width 50;
    /* quick in */
  }

  .highcharts-state-normal path {
    transition: stroke-width 150ms;
    /* slow out */
  }

  /* Legend hover affects points and series */
  g.highcharts-series,
  .highcharts-point,
  .highcharts-markers,
  .highcharts-data-labels {
    transition: opacity 100ms;
  }

  .highcharts-legend-series-active
    g.highcharts-series:not(.highcharts-series-hover),
  .highcharts-legend-point-active
    .highcharts-point:not(.highcharts-point-hover),
  .highcharts-legend-series-active
    .highcharts-markers:not(.highcharts-series-hover),
  .highcharts-legend-series-active
    .highcharts-data-labels:not(.highcharts-series-hover) {
    opacity: 0.3;
  }

  /* Series options */
  /* Default colors */

  .highcharts-area {
    stroke-width: 0;
  }

  .highcharts-markers {
    stroke-width: 1px;
    stroke: none;
  }

  .highcharts-data-label {
    font-weight: bold;
  }

  .highcharts-data-label-box {
    fill: none;
    stroke-width: 0;
  }

  .highcharts-data-label text,
  text.highcharts-data-label {
    fill: #000;
  }

  .highcharts-data-label-connector {
    fill: none;
  }

  .highcharts-halo {
    fill-opacity: 0.25;
    stroke-width: 0;
  }

  .highcharts-column-series rect.highcharts-point {
    stroke: none;
  }

  .highcharts-column-series .highcharts-point {
    transition: fill-opacity 250ms;
  }

  .highcharts-column-series .highcharts-point-hover {
    fill-opacity: 0.9;
    transition: fill-opacity 20ms;
  }

  .highcharts-pie-series .highcharts-point {
    stroke-linejoin: round;
    stroke: none;
  }

  .highcharts-pie-series .highcharts-point-hover {
    fill-opacity: 0.75;
    transition: fill-opacity 50ms;
  }

  .highcharts-funnel-series .highcharts-point {
    stroke-linejoin: round;
    stroke: none;
  }

  .highcharts-funnel-series .highcharts-point-hover {
    fill-opacity: 0.75;
    transition: fill-opacity 50ms;
  }

  .highcharts-pyramid-series .highcharts-point {
    stroke-linejoin: round;
    stroke: none;
  }

  .highcharts-pyramid-series .highcharts-point-hover {
    fill-opacity: 0.75;
    transition: fill-opacity 50ms;
  }

  .highcharts-solidgauge-series .highcharts-point {
    stroke-width: 0;
  }

  .highcharts-treemap-series .highcharts-point {
    stroke-width: 1px;
    stroke: #e6e6e6;
    transition:
      stroke 250ms,
      fill 250ms,
      fill-opacity 250ms;
  }

  .highcharts-treemap-series .highcharts-point-hover {
    stroke: #999999;
    transition:
      stroke 25ms,
      fill 25ms,
      fill-opacity 25ms;
  }

  .highcharts-treemap-series .highcharts-above-level {
    display: none;
  }

  .highcharts-treemap-series .highcharts-internal-node {
    fill: none;
  }

  .highcharts-treemap-series .highcharts-internal-node-interactive {
    fill-opacity: 0.15;
    cursor: pointer;
  }

  .highcharts-treemap-series .highcharts-internal-node-interactive:hover {
    fill-opacity: 0.75;
  }

  /* Legend */
  .highcharts-legend-box {
    fill: #fafbfc;
    stroke-width: 0;
    border-radius: 3px;
    rx: 2;
    ry: 2;
  }

  .highcharts-legend-item > text {
    fill: #1a1a1a;
    font-family: Inter, 'Roboto', arial, sans-serif;
    font-weight: 400;
    cursor: pointer;
    stroke-width: 0;
  }

  .highcharts-legend-item:hover text {
    fill: #1a1a1a;
  }

  .highcharts-legend-item-hidden * {
    fill: #cccccc !important;
    stroke: #cccccc !important;
    transition: fill 250ms;
  }

  .highcharts-legend-nav-active {
    fill: #003399;
    cursor: pointer;
  }

  .highcharts-legend-nav-inactive {
    fill: #cccccc;
  }

  circle.highcharts-legend-nav-active,
  circle.highcharts-legend-nav-inactive {
    /* tracker */
    fill: rgba(192, 192, 192, 0.0001);
  }

  .highcharts-legend-title-box {
    fill: none;
    stroke-width: 0;
  }

  /* Bubble legend */
  .highcharts-bubble-legend-symbol {
    stroke-width: 2;
    fill-opacity: 0.5;
  }

  .highcharts-bubble-legend-connectors {
    stroke-width: 1;
  }

  .highcharts-bubble-legend-labels {
    fill: #000;
  }

  /* Loading */
  .highcharts-loading {
    position: absolute;
    background-color: none;
    opacity: 0.5;
    text-align: center;
    z-index: 10;
    transition: opacity 250ms;
  }

  .highcharts-loading-hidden {
    height: 0 !important;
    opacity: 0;
    overflow: hidden;
    transition:
      opacity 250ms,
      height 250ms step-end;
  }

  .highcharts-loading-inner {
    font-weight: bold;
    position: relative;
    top: 45%;
  }

  /* Plot bands and polar pane backgrounds */
  .highcharts-plot-band,
  .highcharts-pane {
    fill: #fff;
    fill-opacity: 0.05;
  }

  .highcharts-plot-line {
    fill: none;
    stroke: #999999;
    stroke-width: 1px;
  }

  /* Highcharts More and modules */
  .highcharts-boxplot-box {
    fill: none;
  }

  .highcharts-boxplot-median {
    stroke-width: 2px;
  }

  .highcharts-bubble-series .highcharts-point {
    fill-opacity: 0.5;
  }

  .highcharts-errorbar-series .highcharts-point {
    stroke: #fff;
  }

  .highcharts-gauge-series .highcharts-data-label-box {
    stroke: #cccccc;
    stroke-width: 1px;
  }

  .highcharts-gauge-series .highcharts-dial {
    fill: #fff;
    stroke-width: 0;
  }

  .highcharts-polygon-series .highcharts-graph {
    fill: inherit;
    stroke-width: 0;
  }

  .highcharts-waterfall-series .highcharts-graph {
    stroke: #000;
    stroke-dasharray: 1, 3;
  }

  .highcharts-sankey-series .highcharts-point {
    stroke-width: 0;
  }

  .highcharts-sankey-series .highcharts-link {
    transition:
      fill 250ms,
      fill-opacity 250ms;
    fill-opacity: 0.5;
  }

  .highcharts-sankey-series .highcharts-point-hover.highcharts-link {
    transition:
      fill 50ms,
      fill-opacity 50ms;
    fill-opacity: 1;
  }

  .highcharts-venn-series .highcharts-point {
    fill-opacity: 0.75;
    stroke: #cccccc;
    transition:
      stroke 250ms,
      fill-opacity 250ms;
  }

  .highcharts-venn-series .highcharts-point-hover {
    fill-opacity: 1;
    stroke: #cccccc;
  }

  /* Highstock */
  .highcharts-navigator-mask-outside {
    fill-opacity: 0;
  }

  .highcharts-navigator-mask-inside {
    fill: #6685c2;
    /* navigator.maskFill option */
    fill-opacity: 0.25;
    cursor: ew-resize;
  }

  .highcharts-navigator-outline {
    stroke: #cccccc;
    fill: none;
  }

  .highcharts-navigator-handle {
    stroke: #cccccc;
    fill: #f2f2f2;
    cursor: ew-resize;
  }

  .highcharts-navigator-series {
    fill: #36b37e;
    stroke: #36b37e;
  }

  .highcharts-navigator-series .highcharts-graph {
    stroke-width: 1px;
  }

  .highcharts-navigator-xaxis .highcharts-axis-line {
    stroke-width: 0;
  }

  .highcharts-grid-line {
    fill: none;
    stroke: #f7f7fc;
  }

  .highcharts-xaxis-grid .highcharts-grid-line {
    stroke-width: 0px;
  }

  .highcharts-navigator-xaxis .highcharts-grid-line {
    stroke-width: 1px;
    stroke: #fff;
  }

  .highcharts-navigator-xaxis.highcharts-axis-labels {
    fill: #999999;
  }

  .highcharts-navigator-yaxis .highcharts-grid-line {
    stroke-width: 0;
  }

  .highcharts-grid-line {
    @media only screen and (min-width: 2000px) {
      stroke-width: 3px;
    }
  }

  .highcharts-scrollbar-thumb {
    fill: #cccccc;
    stroke: #cccccc;
    stroke-width: 1px;
  }

  .highcharts-scrollbar-button {
    fill: #e6e6e6;
    stroke: #cccccc;
    stroke-width: 1px;
  }

  .highcharts-scrollbar-arrow {
    fill: #666666;
  }

  .highcharts-scrollbar-rifles {
    stroke: #666666;
    stroke-width: 1px;
  }

  .highcharts-scrollbar-track {
    fill: #f2f2f2;
    stroke: #c0c0c8;
    stroke-width: 1px;
  }

  .highcharts-range-label rect {
    fill: none;
  }

  .highcharts-range-label text {
    fill: #666666;
  }

  .highcharts-range-input rect {
    fill: none;
  }

  .highcharts-range-input text {
    fill: #000;
  }

  .highcharts-range-input {
    stroke-width: 1px;
    stroke: #cccccc;
  }

  input.highcharts-range-selector {
    position: absolute;
    border: 0;
    width: 1px;
    /* Chrome needs a pixel to see it */
    height: 1px;
    padding: 0;
    text-align: center;
    left: -9em;
    /* #4798 */
  }

  .highcharts-crosshair-label text {
    fill: none;
  }

  .highcharts-crosshair-label .highcharts-label-box {
    fill: inherit;
  }

  .highcharts-candlestick-series .highcharts-point {
    stroke: #fff;
    stroke-width: 1px;
  }

  .highcharts-candlestick-series .highcharts-point-up {
    fill: none;
  }

  .highcharts-ohlc-series .highcharts-point-hover {
    stroke-width: 3px;
  }

  .highcharts-flags-series .highcharts-point .highcharts-label-box {
    stroke: #999999;
    fill: none;
    transition: fill 250ms;
  }

  .highcharts-flags-series .highcharts-point-hover .highcharts-label-box {
    stroke: #fff;
    fill: #ccd6eb;
  }

  .highcharts-flags-series .highcharts-point text {
    fill: #fff;
    font-weight: bold;
  }

  /* Highmaps */
  .highcharts-map-series .highcharts-point {
    transition:
      fill 500ms,
      fill-opacity 500ms,
      stroke-width 250ms;
    stroke: #cccccc;
  }

  .highcharts-map-series .highcharts-point-hover {
    transition:
      fill 0ms,
      fill-opacity 0ms;
    fill-opacity: 0.5;
    stroke-width: 2px;
  }

  .highcharts-mapline-series .highcharts-point {
    fill: none;
  }

  .highcharts-heatmap-series .highcharts-point {
    stroke-width: 0;
  }

  .highcharts-map-navigation {
    font-weight: bold;
    text-align: center;
  }

  .highcharts-coloraxis {
    stroke-width: 0;
  }

  .highcharts-coloraxis-marker {
    fill: #999999;
  }

  .highcharts-null-point {
    fill: #f7f7f7;
  }

  /* 3d charts */
  .highcharts-3d-frame {
    fill: transparent;
  }

  /* Exporting module */
  .highcharts-contextbutton {
    fill: #fff;
    /* needed to capture hover */
    stroke: none;
    stroke-linecap: round;
  }

  .highcharts-contextbutton:hover {
    fill: #e6e6e6;
    stroke: #e6e6e6;
  }

  .highcharts-button-symbol {
    stroke: #666666;
    stroke-width: 3px;
  }

  .highcharts-menu {
    border: 1px solid #999999;
    background: none;
    padding: 5px 0;
    /*box-shadow: 3px 3px 10px #888;*/
  }

  .highcharts-menu-item {
    padding: 0.5em 1em;
    background: none;
    color: #000;
    cursor: pointer;
    transition:
      background 250ms,
      color 250ms;
  }

  .highcharts-menu-item:hover {
    background: #335cad;
    color: none;
  }

  /* Drilldown module */
  .highcharts-drilldown-point {
    cursor: pointer;
  }

  .highcharts-drilldown-data-label text,
  text.highcharts-drilldown-data-label,
  .highcharts-drilldown-axis-label {
    cursor: pointer;
    fill: #003399;
    font-weight: bold;
    text-decoration: underline;
  }

  /* No-data module */
  .highcharts-no-data text {
    font-weight: bold;
    fill: #666666;
  }

  /* Drag-panes module */
  .highcharts-axis-resizer {
    cursor: ns-resize;
    stroke: black;
    stroke-width: 2px;
  }

  /* Bullet type series */
  .highcharts-bullet-target {
    stroke-width: 0;
  }

  /* Lineargauge type series */
  .highcharts-lineargauge-target {
    stroke-width: 1px;
    stroke: #000;
  }

  .highcharts-lineargauge-target-line {
    stroke-width: 1px;
    stroke: #000;
  }

  /* Annotations module */
  .highcharts-annotation-label-box {
    stroke-width: 1px;
    stroke: #fff;
    fill: #fff;
    fill-opacity: 0.75;
  }

  .highcharts-annotation-label text {
    fill: #e6e6e6;
  }

  /* Gantt */
  .highcharts-treegrid-node-collapsed,
  .highcharts-treegrid-node-expanded {
    cursor: pointer;
  }

  .highcharts-point-connecting-path {
    fill: none;
  }

  .highcharts-grid-axis .highcharts-tick {
    stroke-width: 1px;
  }

  .highcharts-grid-axis .highcharts-axis-line {
    stroke-width: 1px;
  }

  .highcharts-tooltip-value {
    color: red !important;
    padding-left: 20px;
  }

  /* Bar */

  /* Custom styles */
  .dotted-line-series {
    stroke: ${Colors.TEAMS_CLOSE};
    fill: ${Colors.LOGAN};
    stroke-dasharray: 10, 5;
  }
`;

export default HighchartStyles;
