import { useState } from 'react';
import data from '../data/driverMetricDefinitions.json';

export const toSpecialMetrics = (data: any[]): Metrics.SpecialMetric[] => {
  return data
    .map((d) => {
      const {
        id,
        name,
        dataType,
        description,
        dateField,
        query,
        precision,
        postfix,
        prefix,
        positiveDeltaIsGood,
        gridQueries,
        metrics,
      } = d;

      return {
        id,
        name,
        description,
        formatting: {
          precision,
          postfix,
          prefix,
          positiveDeltaIsGood,
        },
        query,
        dataType,
        dateField,
        gridQueries,
        metrics,
        type: 'special' as 'special',
      };
    })
    .map((m) => {
      if (!m.formatting.prefix) {
        delete m.formatting['prefix'];
      }

      if (!m.formatting.postfix) {
        delete m.formatting['postfix'];
      }

      return m;
    });
};

const useSpecialMetrics = () => {
  const [specialMetrics] = useState<Metrics.SpecialMetric[]>(
    toSpecialMetrics(data),
  );
  const [isLoading] = useState(false);

  return {
    specialMetrics,
    isLoading,
  };
};

export default useSpecialMetrics;
