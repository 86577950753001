import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Card from 'components/Common/Card';
import Row from 'components/Common/Row';
import Typography from 'kingpin/atoms/Typography';
import Button from 'kingpin/atoms/Button';

import { ReactComponent as Logo } from './Logo.svg';
import sendMicrosoftGuestLinkEmail from './sendMicrosoftGuestLinkEmail';
import CloudFunctionClientContext from '../../contexts/CloudFunctionClientContext';
import captureException from '../../services/captureException';

const Page = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const MicrosoftTenantRequired = ({
  email,
  setIsTenantRequired,
}: {
  email: string;
  setIsTenantRequired: React.Dispatch<
    React.SetStateAction<
      | {
          isRequired: boolean;
          email: string;
        }
      | undefined
    >
  >;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { usEastApi } = useContext(CloudFunctionClientContext);

  const sendLinkingEmail = useCallback(() => {
    setIsLoading(true);
    sendMicrosoftGuestLinkEmail({
      api: usEastApi,
      email,
    }).then((response) => {
      if (response.ok) {
        setIsLoading(false);
      } else {
        const e = new Error();
        e.name = 'Failed to send guest linking email';
        e.message = email;
        captureException(e);
        alert('Something went wrong!');
      }
    });
  }, [email, usEastApi]);

  useEffect(() => {
    sendLinkingEmail();
  }, [sendLinkingEmail]);

  const onBackClicked = useCallback(() => {
    setIsTenantRequired(undefined);
  }, [setIsTenantRequired]);

  return (
    <Page>
      <Row
        style={{
          marginBottom: 32,
        }}
      >
        <Logo />
      </Row>
      <Card
        style={{
          padding: 32,
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography.Header type="H1">
          Additional steps required
        </Typography.Header>
        <Typography.Body type="Body 12">
          We have sent an email to {email} if you have access to Fleetops.
        </Typography.Body>
        <Typography.Body type="Body 12">
          Please follow the link provided within it to continue signing into
          FleetOps.
        </Typography.Body>

        <Row
          style={{
            justifyContent: 'center',
            marginBottom: 16,
            flex: 1,
            width: '100%',
          }}
        >
          <Button
            type="Tertiary"
            size="Small"
            onClick={sendLinkingEmail}
            label={'Resend email'}
            isDisabled={isLoading}
            isLoading={isLoading}
          />
        </Row>
        <Row style={{ justifyContent: 'center' }}>
          <Typography.Body type="Link" onClick={onBackClicked}>
            Back to Login
          </Typography.Body>
        </Row>
      </Card>
    </Page>
  );
};

export default MicrosoftTenantRequired;
