import useCommitments from './useCommitments';
import { CUSTOMER_LANE_COMMITMENTS } from '../constants';

const useCommitment = (id?: string) => {
  const commitments = useCommitments(CUSTOMER_LANE_COMMITMENTS);
  if (!id) {
    return undefined;
  }
  return commitments.find((c) => c.id === id);
};

export default useCommitment;
