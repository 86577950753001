import toRangeInput from '../../components/Report/toRangeInput';
import drilldownTypeCheckers from '../../components/ReportDrillDownForm/drilldownTypeCheckers';
import isDefined from '../../isDefined';

export const sanitizeFilter = (fi: FilterInput): FilterInput => {
  const newFi = {} as FilterInput;

  const {
    mustExistFilters,
    mustNotExistFilters,
    keywords,
    booleanFilters,
    wildcardFilters,
    ranges,
    dataType,
  } = fi;

  if (mustExistFilters && mustExistFilters.length > 0) {
    newFi.mustExistFilters = mustExistFilters;
  }
  if (mustNotExistFilters && mustNotExistFilters.length > 0) {
    newFi.mustNotExistFilters = mustNotExistFilters;
  }
  if (keywords && keywords.length > 0) {
    newFi.keywords = keywords;
  }
  if (booleanFilters && booleanFilters.length > 0) {
    newFi.booleanFilters = booleanFilters;
  }
  if (wildcardFilters && wildcardFilters.length > 0) {
    newFi.wildcardFilters = wildcardFilters;
  }
  if (ranges && ranges.length > 0) {
    newFi.ranges = ranges;
  }
  if (dataType && dataType.length > 0) {
    newFi.dataType = dataType;
  }

  return newFi;
};

const buildFilterInput = ({
  scopes,
  drillDowns,
  dataType,
}: {
  scopes: ReportDrillDownType[];
  drillDowns: ReportDrillDownType[];
  dataType?: string[];
}): FilterInput => {
  const mergedDrillDowns = [...scopes, ...drillDowns];

  const keywords = [] as KeywordFilter[];
  mergedDrillDowns.forEach((drillDown) => {
    if (drilldownTypeCheckers.isTextDrillDown(drillDown)) {
      keywords.push({
        field: drillDown.field,
        values: drillDown.keywordValues,
        exclude: drillDown.isExclude,
        exists: drillDown.keywordsExists,
      });
    }
  });

  const ranges = (() => {
    const rangeDrills = mergedDrillDowns.filter(
      drilldownTypeCheckers.isNumberDrillDown,
    );
    return rangeDrills
      .map((d) => {
        if (!d.rangeValue) {
          return undefined;
        }
        return toRangeInput(d.field, d.rangeValue);
      })
      .filter(isDefined);
  })();

  const booleanFilters = ((): BooleanInput[] => {
    const boolDrills = mergedDrillDowns
      .filter(drilldownTypeCheckers.isBooleanDrillDown)
      .filter((d) => d.booleanValue !== undefined);
    return boolDrills.map((d) => ({ field: d.field, value: !!d.booleanValue }));
  })();

  const wildcardFilters = (() => {
    const wildcardFilters = mergedDrillDowns.filter(
      drilldownTypeCheckers.isWildcardDrillDown,
    );
    return wildcardFilters.map((f) => f.wildcardFilter);
  })();

  const { mustExistFilters, mustNotExistFilters } = (() => {
    const existenceFilters = mergedDrillDowns.filter(
      (f) => f.exists !== undefined,
    );

    const mustExistFilters = existenceFilters
      .filter((f) => f.exists === true)
      .map((f) => f.field);
    const mustNotExistFilters = existenceFilters
      .filter((f) => f.exists === false)
      .map((f) => f.field);

    return {
      mustExistFilters,
      mustNotExistFilters,
    };
  })();

  return sanitizeFilter({
    keywords,
    ranges,
    booleanFilters,
    wildcardFilters,
    mustExistFilters,
    mustNotExistFilters,
    dataType,
  });
};

export default buildFilterInput;
