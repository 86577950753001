import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import usePopup from 'hooks/usePopup';
import Button from 'kingpin/atoms/Button';

import InlineDialog from '../../InlineDialog';
import Row from '../../Common/Row';
import { UserIconFromId } from '../../Common/UserIcon';
import Typography from 'kingpin/atoms/Typography';
import List from '../../List';
import useSearchUsers from './useSearchUsers';
import TextInput from '../../../kingpin/atoms/TextInput';

const UserItemDiv = styled.div<{ onClick?: () => void }>`
  padding: 4px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
  ${(props) => (props.onClick ? 'cursor: pointer;' : '')}

  .showOnMouseOver {
    visibility: hidden;
  }

  &:hover {
    .clickable {
      ${(props) => (props.onClick ? 'opacity: 0.7;' : '')}
    }

    .showOnMouseOver {
      visibility: visible;
    }
  }
`;

const UserItem = ({
  selectedUsers,
  user,
  onUserAdded,
}: {
  selectedUsers: (
    | UserManagement.PendingUser
    | UserManagement.SignedUpUser
    | UserManagement.FleetOpsStaffUser
  )[];
  user:
    | UserManagement.PendingUser
    | UserManagement.SignedUpUser
    | UserManagement.FleetOpsStaffUser;
  onUserAdded: (
    user:
      | UserManagement.PendingUser
      | UserManagement.SignedUpUser
      | UserManagement.FleetOpsStaffUser,
  ) => void;
}) => {
  const [isSelected, setIsSelected] = useState<boolean>(
    selectedUsers.some((u) => u.id === user.id),
  );
  useEffect(() => {
    setIsSelected(selectedUsers.some((u) => u.id === user.id));
  }, [selectedUsers, user.id]);
  const onAddClicked = useCallback(() => {
    if (isSelected) {
      return;
    }

    onUserAdded(user);
  }, [isSelected, onUserAdded, user]);

  return (
    <UserItemDiv onClick={isSelected ? undefined : onAddClicked}>
      <Row centerAlign className="clickable">
        <UserIconFromId userId={user.id} />
        <Typography.Body type="Body 12">{user.displayName}</Typography.Body>
      </Row>
      {isSelected && (
        <Button
          label="Added"
          icon="checkmark"
          isDisabled
          type="Ghost"
          size="Small"
        />
      )}
      {!isSelected && (
        <div className="showOnMouseOver">
          <Button
            onClick={onAddClicked}
            label={'Add'}
            icon={'add'}
            type="Primary"
            size="Small"
          />
        </div>
      )}
    </UserItemDiv>
  );
};

const UserList = ({
  users,
  selectedUsers,
  onUserAdded,
  searchDivRef,
}: {
  users: (
    | UserManagement.PendingUser
    | UserManagement.SignedUpUser
    | UserManagement.FleetOpsStaffUser
  )[];
  selectedUsers: (
    | UserManagement.PendingUser
    | UserManagement.SignedUpUser
    | UserManagement.FleetOpsStaffUser
  )[];
  onUserAdded: (
    user:
      | UserManagement.PendingUser
      | UserManagement.SignedUpUser
      | UserManagement.FleetOpsStaffUser,
  ) => void;
  searchDivRef: React.MutableRefObject<HTMLDivElement | null>;
}) => {
  const searchDivWidth = searchDivRef.current
    ? searchDivRef.current.clientWidth
    : undefined;
  return (
    <div style={{ width: searchDivWidth, maxHeight: '400px' }}>
      {users.map((u) => (
        <UserItem
          key={u.id}
          selectedUsers={selectedUsers}
          user={u}
          onUserAdded={onUserAdded}
        />
      ))}
    </div>
  );
};

const AddUsersSearch = ({
  selectedUsers,
  onUserAdded,
}: {
  selectedUsers: (
    | UserManagement.PendingUser
    | UserManagement.SignedUpUser
    | UserManagement.FleetOpsStaffUser
  )[];
  onUserAdded: (
    user:
      | UserManagement.PendingUser
      | UserManagement.SignedUpUser
      | UserManagement.FleetOpsStaffUser,
  ) => void;
}) => {
  const { filteredUsers, searchText, onSearchTextChanged } = useSearchUsers();
  const { isOpen, open, close } = usePopup();
  const searchDivRef = useRef<HTMLDivElement | null>(null);

  return (
    <>
      <InlineDialog
        isOpen={isOpen}
        onClose={close}
        placement={'bottom-start'}
        content={
          <List
            noMargin
            noBorder
            Body={
              <UserList
                users={filteredUsers}
                onUserAdded={onUserAdded}
                selectedUsers={selectedUsers}
                searchDivRef={searchDivRef}
              />
            }
            Headers={null}
          />
        }
      >
        <div ref={searchDivRef}>
          <TextInput
            value={searchText}
            onChange={onSearchTextChanged}
            placeholder="Search To Add Users"
            icon="search"
            width="100%"
            onFocus={open}
          />
        </div>
      </InlineDialog>
    </>
  );
};

export default AddUsersSearch;
