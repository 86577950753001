import React from 'react';
import styled from 'styled-components';

import Typography from 'kingpin/atoms/Typography';
import Button from 'kingpin/atoms/Button';
import Colors2 from '../../../../../theme/Colors2';

const OuterDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 4px;
`;
const PlateDiv = styled.div`
  padding: 0px 8px;
  margin-right: 4px;

  margin-bottom: 4px;
  background-color: ${Colors2.AvatarColors['1'].background};
  border-radius: 3px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const BtnDiv = styled.div`
  margin-left: 8px;
`;

const MetricListFilterPlates = ({
  filterPlates,
}: {
  filterPlates: { key: string; label: string; onRemoved?: () => void }[];
}) => {
  return (
    <OuterDiv>
      {filterPlates.map((p) => (
        <PlateDiv key={p.key}>
          <Typography.Body type="Body 12">{p.label}</Typography.Body>
          {p.onRemoved && (
            <BtnDiv>
              <Button
                onClick={p.onRemoved}
                icon="cross"
                type="Ghost"
                size="Small"
              />
            </BtnDiv>
          )}
        </PlateDiv>
      ))}
    </OuterDiv>
  );
};

export default MetricListFilterPlates;
