import { useCallback, useEffect } from 'react';
import { GridApi, ModelUpdatedEvent } from 'ag-grid-community';

const useRowHighlighter = (
  gridApi: GridApi | undefined,
  entityField: string | undefined,
  selectedFieldValue?: string,
) => {
  const updateHighlightedRows = useCallback(
    (event: ModelUpdatedEvent) => {
      if (event.api.isDestroyed()) {
        return;
      }
      event.api.forEachNode((node) => {
        if (!node.data || !entityField) {
          return;
        }
        const f = node.data[entityField];
        if (f !== selectedFieldValue) {
          node.setSelected(false);
        } else {
          node.setSelected(true);
        }
      });
    },
    [entityField, selectedFieldValue],
  );

  useEffect(() => {
    if (gridApi) {
      gridApi.addEventListener('modelUpdated', updateHighlightedRows);
      return () => {
        if (gridApi.isDestroyed()) {
          return;
        }
        gridApi.removeEventListener('modelUpdated', updateHighlightedRows);
      };
    }
  }, [gridApi, updateHighlightedRows]);
};

export default useRowHighlighter;
