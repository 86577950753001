import Row from '../Common/Row';
import CanvasCardTitle from '../CanvasCardTitle';
import ShowOnMouseOverUnlessTour from '../ShowOnMouseOverUnlessTour';
import EditListButton from './EditListButton';
import CopyGadgetButton from '../CopyGadgetButton';
import React, { useCallback, useContext, useState } from 'react';
import MetricListContext from '../../contexts/MetricListContext';
import MetricsInfoButton from '../MetricsInfoButton';
import ContextMenu from '../../kingpin/atoms/ContextMenu';

const MetricListTopBar = ({ isPreview }: { isPreview?: boolean }) => {
  const { metricList } = useContext(MetricListContext);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const onMenuOpen = useCallback(() => {
    setIsMenuOpen(true);
  }, []);
  const onMenuClose = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  if (!metricList) {
    return null;
  }

  return (
    <Row spaceBetween centerAlign style={{ height: '100%' }}>
      <CanvasCardTitle title={metricList.name} />
      {!isPreview && (
        <Row>
          <ShowOnMouseOverUnlessTour>
            <div style={{ marginRight: 8 }}>
              <MetricsInfoButton visualisation={metricList} />
            </div>
          </ShowOnMouseOverUnlessTour>
          <ShowOnMouseOverUnlessTour isForcedVisible={isMenuOpen}>
            <ContextMenu
              buttonType="Ghost"
              onOpen={onMenuOpen}
              onClose={onMenuClose}
            >
              <EditListButton metricListGadget={metricList} />
              <CopyGadgetButton />
            </ContextMenu>
          </ShowOnMouseOverUnlessTour>
        </Row>
      )}
    </Row>
  );
};

export default MetricListTopBar;
