import { useCallback, useContext } from 'react';
import DatasetFiltersContext from 'screens/DataManager/DatasetFilters/context/DatasetFiltersContext';
import mergeFilterInputs from 'mergeFilterInputs';
import metricTypeCheckers from 'types/metricTypeCheckers';
import isDefined from 'isDefined';

const useToMetricInput = () => {
  const { datasetFilterIdLookup } = useContext(DatasetFiltersContext);

  const toMetricInput = useCallback(
    (metric: Metrics.NormalMetric | MetricInput): MetricInput => {
      const baseMetricInput: MetricInput = {
        id: metric.id,
        field: metric.field,
        filters: metric.filters,
        aggFunc: metric.aggFunc,
        dataType: metric.dataType,
      };

      if (
        !metricTypeCheckers.isNormalMetric(metric) ||
        !metric.datasetFilterIds
      ) {
        return baseMetricInput;
      }

      const usedDatasetFilters: FilterInput[] = metric.datasetFilterIds
        .map((dsFilterId) => datasetFilterIdLookup[dsFilterId])
        .filter(isDefined)
        .map((dsFilter) => dsFilter.filterInput);

      const datasetFilterInput: FilterInput = usedDatasetFilters.reduce(
        (a, b) => {
          return mergeFilterInputs(a, b);
        },
        {},
      );

      const newFilterInput: FilterInput = mergeFilterInputs(
        baseMetricInput.filters,
        datasetFilterInput,
      );

      return {
        ...baseMetricInput,
        filters: newFilterInput,
      };
    },
    [datasetFilterIdLookup],
  );

  return toMetricInput;
};

export default useToMetricInput;
