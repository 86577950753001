import { useCallback, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import useAggregateMetricsGroupByInput from './useAggregateMetricsGroupByInput';
import aggregateMetricsGroupByInput from './aggregateMetricsGroupByInput';
import GqlClientContext from '../../../contexts/GqlClientContext';

const useRankingMatrixData = (
  gadget: VisualisationDefinitions.RankingMatrix,
) => {
  const { client } = useContext(GqlClientContext);
  const input = useAggregateMetricsGroupByInput(gadget);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [terms, setTerms] = useState<
    { [column: string]: string | number | undefined }[] | undefined
  >(undefined);
  const [totals, setTotals] = useState<
    { [column: string]: string | number | undefined }[] | undefined
  >(undefined);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const executeQuery = useCallback(
    _.debounce((args: Queries.AggregateMetricsGroupByInput) => {
      aggregateMetricsGroupByInput(client, args).then((response) => {
        setTerms(response.terms);
        const totalsWithCount = {
          [gadget.groupByField]: response.terms.length,
          ...response.totals,
        };
        setTotals([totalsWithCount]);
        setIsLoading(false);
      });
    }, 500),
    [gadget.groupByField],
  );

  useEffect(() => {
    setIsLoading(true);
    setTotals(undefined);
    setTerms(undefined);
    if (!input) {
      return;
    }

    executeQuery(input);
  }, [client, executeQuery, input]);

  return {
    isLoading,
    terms,
    totals,
  };
};

export default useRankingMatrixData;
