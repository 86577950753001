import React from 'react';
import WidgetsIcon from '../../../images/widgets.svg';
import EmptyState from '../../EmptyState';

const EmptyReport = ({
  onAddWidgetsClicked,
}: {
  onAddWidgetsClicked: () => void;
}) => (
  <EmptyState
    text={'There are no widgets to display'}
    iconSrc={WidgetsIcon}
    buttonText={'Add Widgets'}
    onButtonClicked={onAddWidgetsClicked}
  />
);

export default EmptyReport;
