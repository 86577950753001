import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import Button from 'kingpin/atoms/Button';
import ReportDrillDownsProvider from 'contextProviders/ReportDrillDownsProvider';
import BoardContext from 'contexts/BoardContext';
import PerformanceBoardSettingsContext from '../../../contexts/PerformanceBoardSettingsContext';
import getIdentifier from 'getIdentifier';
import { LabelDiv } from 'screens/Boards/PerformanceBoardForm';
import Typography from 'kingpin/atoms/Typography';
import Inputs from 'components/Inputs';
import Row from 'components/Common/Row';
import ReportDrillDown from 'components/ReportDrillDown';
import BoardSettingsHeader from '../../BoardSettingsHeader/BoardSettingsHeader';
import AddFilterButton from '../../AddBoardFilterButton';
import useUpdateFilters from '../../hooks/useUpdateFilters';

const QuickFiltersConfig = ({ quickFilter }: { quickFilter?: SavedFilter }) => {
  const { board } = useContext(BoardContext);
  const { setNavState, navState, setHasUnsavedChanges } = useContext(
    PerformanceBoardSettingsContext,
  );
  const [filterName, setFilterName] = useState<string>(
    quickFilter ? quickFilter.name || '' : '',
  );
  const [drillDowns, setDrillDowns] = useState<FilterPlate[]>(
    quickFilter ? quickFilter.drillDowns : window.emptyArray,
  );
  const { updateFilters } = useUpdateFilters();

  useEffect(() => {
    setHasUnsavedChanges(true);
  }, [setHasUnsavedChanges]);

  const onFilterNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setFilterName(event.target.value);
  };

  const isValid = drillDowns.length !== 0 && filterName !== '';

  const onSave = useCallback(() => {
    const filter = {
      version: '2',
      id: quickFilter ? quickFilter.id : getIdentifier(),
      scope: [],
      drillDowns,
      source: `${board.dataType} - board: ${board.id}`,
      name: filterName,
      dateField: 'date',
    } as SavedFilter;
    updateFilters({ mode: 'set', filter });
  }, [
    board.dataType,
    board.id,
    drillDowns,
    filterName,
    quickFilter,
    updateFilters,
  ]);

  return (
    <ReportDrillDownsProvider
      drillDowns={drillDowns}
      setDrillDowns={setDrillDowns}
      dataTypes={board ? [board.dataType] : window.emptyArray}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <div style={{ flex: 1 }}>
          <BoardSettingsHeader title="New Quick Filter" />
          <div style={{ marginBottom: '24px' }}>
            <LabelDiv>
              <Typography.Body type="Label">Filter Name</Typography.Body>
            </LabelDiv>
            <Inputs.TextInput
              value={filterName}
              onChange={onFilterNameChanged}
              maxLength={40}
              autoFocus
              data-testid={'filterName'}
              style={{ flex: '1' }}
            />
          </div>
          <div style={{ marginBottom: 8 }}>
            <Typography.Body type="Body 12">Add Filter Rules</Typography.Body>
          </div>

          <Row style={{ marginBottom: '16px' }}>
            <AddFilterButton />
          </Row>
          <>
            {drillDowns.length !== 0 && (
              <div style={{ marginBottom: '32px', marginLeft: '1px' }}>
                {drillDowns.map((d) => (
                  <div key={d.id} style={{ marginBottom: '8px' }}>
                    <ReportDrillDown drillDown={d} isScope={false} />
                  </div>
                ))}
              </div>
            )}
          </>
        </div>

        <Row style={{ padding: '8px 0', height: '46px' }} spaceBetween>
          <Button
            onClick={() => setNavState({ mode: 'Quick Filters' })}
            label={'Cancel'}
            type="Tertiary"
            size="Small"
          />
          <Button
            type="Primary"
            size="Small"
            isDisabled={!isValid}
            onClick={onSave}
            label={navState.editQuickFilter ? 'Save Changes' : 'Create Filter'}
          />
        </Row>
      </div>
    </ReportDrillDownsProvider>
  );
};

export default QuickFiltersConfig;
