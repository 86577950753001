import React, { useCallback, useEffect, useState } from 'react';
import LandingPage from './LandingPage';
import Branding from './Branding';
import Typography from 'kingpin/atoms/Typography';
import reloadWhenSafe from '../reloadWhenSafe';
import useLockedDebouncedEffect from '../hooks/useLockedDebouncedEffect';

interface ReloadCallbackArgs {
  isWallboard: boolean;
  isBlocked: boolean;
  isOnline: boolean;
  isVisible: boolean;
}

const OnlineOnly = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [reloadCallbackArgs, setReloadCallbackArgs] =
    useState<ReloadCallbackArgs>({
      isWallboard: window.location.href.toLowerCase().includes('wallboard'),
      isBlocked: false,
      isOnline: true,
      isVisible: true,
    });

  const onVisibilityChanged = useCallback(() => {
    setReloadCallbackArgs((current) => ({
      ...current,
      isVisible: document.visibilityState === 'visible',
    }));
  }, []);

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChanged);
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChanged);
    };
  }, [onVisibilityChanged]);

  useEffect(() => {
    window.addEventListener('online', () => {
      setReloadCallbackArgs((current) => ({
        ...current,
        isOnline: true,
        isBlocked: current.isWallboard ? false : current.isBlocked,
      }));
    });
    window.addEventListener('offline', () => {
      setReloadCallbackArgs((current) => ({
        ...current,
        isOnline: false,
        isBlocked: true,
      }));
    });
  }, []);

  const reloadCallback = useCallback(
    async ({
      isOnline,
      isBlocked,
      isVisible,
      isWallboard,
    }: ReloadCallbackArgs) => {
      if (isWallboard) {
        // Frequent page reloads on Samsung Devices (1GB memory)
        // can lead to a memory error as the GB takes some time to run
        return;
      }
      if (isOnline && isBlocked && isVisible) {
        reloadWhenSafe();
      }
    },
    [],
  );

  useLockedDebouncedEffect({
    args: reloadCallbackArgs,
    callback: reloadCallback,
    responseHandler: window.tokenFunction,
  });

  if (!reloadCallbackArgs.isBlocked) {
    return <>{children}</>;
  } else {
    return (
      <LandingPage style={{ height: 'unset', minHeight: '100vh' }}>
        <Branding />
        <Typography.Header type="H4">
          Please check your internet connection
        </Typography.Header>
      </LandingPage>
    );
  }
};

export default OnlineOnly;
