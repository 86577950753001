import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import Button from 'kingpin/atoms/Button';

import InlineDialog from '../../../../../../components/InlineDialog';
import MetricFilteringContext from '../../../../../../contexts/MetricFilteringContext';
import FilterBy from './FilterBy';
import Menu from './Menu';

const DialogDiv = styled.div`
  width: 200px;
`;

const FilterPopup = ({
  close,
  step,
  setStep,
}: {
  close: () => void;
  step: 'Filter by' | 'Datasets' | 'Status' | 'Usage';
  setStep: React.Dispatch<
    React.SetStateAction<'Filter by' | 'Datasets' | 'Status' | 'Usage'>
  >;
}) => {
  const {
    datasetOptions,
    usageOptions,
    statusOptions,
    isDatasetFilterDisabled,
  } = useContext(MetricFilteringContext);

  const onBackClicked = useCallback(() => {
    setStep('Filter by');
  }, [setStep]);

  if (step === 'Filter by') {
    return (
      <FilterBy
        setStep={setStep}
        close={close}
        isDatasetFilterDisabled={isDatasetFilterDisabled}
      />
    );
  } else if (step === 'Datasets') {
    return (
      <Menu
        onBackClicked={onBackClicked}
        options={datasetOptions}
        close={close}
        label={'Dateset filter'}
      />
    );
  } else if (step === 'Status') {
    return (
      <Menu
        onBackClicked={onBackClicked}
        options={statusOptions}
        close={close}
        label={'Status filter'}
      />
    );
  } else if (step === 'Usage') {
    return (
      <Menu
        onBackClicked={onBackClicked}
        options={usageOptions}
        close={close}
        label={'Usage filter'}
      />
    );
  } else {
    return null;
  }
};

const FilterMenu = ({
  isOpen,
  open,
  close,
  step,
  setStep,
}: {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  step: 'Filter by' | 'Datasets' | 'Status' | 'Usage';
  setStep: React.Dispatch<
    React.SetStateAction<'Filter by' | 'Datasets' | 'Status' | 'Usage'>
  >;
}) => (
  <InlineDialog
    onClose={close}
    isOpen={isOpen}
    content={
      <DialogDiv>
        <FilterPopup close={close} step={step} setStep={setStep} />
      </DialogDiv>
    }
  >
    <Button
      icon={'filters'}
      onClick={isOpen ? close : open}
      type="Ghost"
      size="Small"
    />
  </InlineDialog>
);

export default FilterMenu;
