const isTextDrillDown = (
  drillDown: ReportDrillDownType,
): drillDown is TextDrillDown => {
  return drillDown.fieldType === 'text' && !('wildcardFilter' in drillDown);
};

const isDateDrillDown = (
  drillDown: ReportDrillDownType,
): drillDown is DateDrillDown => {
  return drillDown.fieldType === 'date';
};

export const NUMBER_FIELD_TYPES = ['int', 'long', 'float'];

const isNumberDrillDown = (
  drillDown: ReportDrillDownType,
): drillDown is NumberDrillDown => {
  return NUMBER_FIELD_TYPES.includes(drillDown.fieldType);
};

const isBooleanDrillDown = (
  drillDown: ReportDrillDownType,
): drillDown is BooleanDrillDown => {
  return drillDown.fieldType === 'boolean';
};

const isWildcardDrillDown = (
  drillDown: ReportDrillDownType,
): drillDown is WildCardDrillDown => {
  return drillDown.fieldType === 'text' && 'wildcardFilter' in drillDown;
};

const drillDownTypeCheckers = {
  isTextDrillDown,
  isDateDrillDown,
  isNumberDrillDown,
  isBooleanDrillDown,
  isWildcardDrillDown,
};

export default drillDownTypeCheckers;
