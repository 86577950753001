import React from 'react';
import Typography from 'kingpin/atoms/Typography';
import FleetOpsGrey from '../../images/fleetops-gray.svg';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import Tooltip from '../Tooltip';
import FlexCentered from '../Common/FlexCentered';
import CanvasCardTitle from '../CanvasCardTitle';
import Colors2 from '../../theme/Colors2';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
`;

type Size = 'normal' | 'small' | 'tiny';

const NoDataToDisplay = ({ size, name }: { size: Size; name?: string }) => {
  if (size === 'small') {
    return (
      <FlexCentered style={{ width: '100%', height: '100%' }}>
        <Wrapper>
          <ReactSVG
            src={FleetOpsGrey}
            beforeInjection={(svg) => {
              svg.setAttribute('style', 'width: 63px');
              svg.setAttribute('style', 'height: 60px');
            }}
            style={{ marginBottom: 6 }}
          />
          {name && <CanvasCardTitle title={name} />}
          <Tooltip content={'Try using different filters and date ranges'}>
            <Typography.Body type={'Body 14'} color={Colors2.Grey['5']}>
              No data to display
            </Typography.Body>
          </Tooltip>
        </Wrapper>
      </FlexCentered>
    );
  }

  if (size === 'tiny') {
    return (
      <FlexCentered style={{ width: '100%', height: '100%' }}>
        <Wrapper>
          <ReactSVG
            src={FleetOpsGrey}
            beforeInjection={(svg) => {
              svg.setAttribute('style', 'width: 30px');
              svg.setAttribute('style', 'height: 36px');
            }}
          />
          {name && <CanvasCardTitle title={name} />}
          <Tooltip content={'Try using different filters and date ranges'}>
            <Typography.Body type={'Body 14'} color={Colors2.Grey['5']}>
              No data to display
            </Typography.Body>
          </Tooltip>
        </Wrapper>
      </FlexCentered>
    );
  }

  return (
    <FlexCentered style={{ width: '100%', height: '100%' }}>
      <Wrapper>
        <ReactSVG
          src={FleetOpsGrey}
          beforeInjection={(svg) => {
            svg.setAttribute('style', 'width: 63px');
            svg.setAttribute('style', 'height: 60px');
          }}
          style={{ marginBottom: 6 }}
        />
        <div>
          <Typography.Header type={'H5'}>No data to display</Typography.Header>
        </div>
        {name && <CanvasCardTitle title={name} />}

        <Typography.Body type={'Body 14'} color={Colors2.Grey['5']}>
          Try using different filters and date ranges
        </Typography.Body>
      </Wrapper>
    </FlexCentered>
  );
};

export default NoDataToDisplay;
