import React from 'react';

import Row from '../../../../../components/Common/Row';
import CloseButton from '../../../../../components/CloseButton';
import WizardStep from './WizardStep';

import styled from 'styled-components';
import PERFORMANCE_WIZARD_CONSTANTS from './constants';
import { TOP_BAR_HEIGHT } from '../../../../../constants';
import Colors2 from '../../../../../theme/Colors2';

const TopBarDiv = styled.div`
  border-bottom: 1px solid ${Colors2.Border};
  height: ${TOP_BAR_HEIGHT}px;
  padding: 0px 24px;
  ${PERFORMANCE_WIZARD_CONSTANTS.MODAL_PADDING_HORIZONTAL}px;
`;

const TopBar = ({ close }: { close: () => void }) => (
  <TopBarDiv>
    <Row centerAlign spaceBetween style={{ height: '100%' }}>
      <Row centerAlign style={{ height: '100%' }}>
        <WizardStep
          step={'dataset set up'}
          number={1}
          text={'Dataset Set Up'}
        />
        <WizardStep step={'metrics'} number={2} text={'Metrics'} />
        <WizardStep step={'fields'} number={3} text={'Fields'} />
        <WizardStep step={'confirmation'} number={4} text={'Confirmation'} />
      </Row>
      <CloseButton close={close} />
    </Row>
  </TopBarDiv>
);

export default TopBar;
