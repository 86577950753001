import React, { useContext, useState } from 'react';
import ReasonCodes from './ReasonCodes';
import ModalDialog, { ModalTransition } from '@atlaskit/modal-dialog';
import useReasonCodes from '../../hooks/useReasonCodes';
import deleteReasonCode from '../../api/reasonCodes/deleteReasonCode';
import AccountPickerContext from '../../contexts/AccountPickerContext';

const ReasonCodesContainer = ({
  close,
  dataType,
}: {
  close: () => void;
  dataType: string;
}) => {
  const { accountRef } = useContext(AccountPickerContext);
  const reasonCodes = useReasonCodes(dataType);
  const [isAddingNewReasonCode, setIsAddingNewReasonCode] =
    useState<boolean>(false);

  const onDeleteCodeClicked = (code: ReasonCode) => {
    deleteReasonCode(code, accountRef);
  };

  return (
    <ReasonCodes
      close={close}
      reasonCodes={reasonCodes}
      isAddingNewReasonCode={isAddingNewReasonCode}
      setIsAddingNewReasonCode={setIsAddingNewReasonCode}
      onDeleteCodeClicked={onDeleteCodeClicked}
      dataType={dataType}
    />
  );
};

const Gate = ({
  isOpen,
  close,
  dataType,
}: {
  isOpen: boolean;
  close: () => void;
  dataType: string;
}) => {
  return (
    <ModalTransition>
      {isOpen && (
        <ModalDialog
          onClose={close}
          shouldScrollInViewport={false}
          autoFocus={false}
        >
          <ReasonCodesContainer close={close} dataType={dataType} />
        </ModalDialog>
      )}
    </ModalTransition>
  );
};

export default Gate;
