import React from 'react';

interface FilterBuilderContextType {
  filters: FilterBuilder.Client.FieldFilter[];
  datasetDefinition: FleetOps.DatasetDefinition;
  // Test for if a field is allowed to be selected in this filter
  fieldFilterPredicate?: (field: FleetOps.Field) => boolean;
}

const FilterBuilderContext = React.createContext<FilterBuilderContextType>({
  filters: [],
  datasetDefinition: { fields: [], type: 'NA', version: -1 },
});

export default FilterBuilderContext;
