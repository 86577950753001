import firebase from 'firebase/compat/app';
import { buildContentViewIdAlt } from '../../buildContentViewId';

const updateFavouriteName = async ({
  type,
  typeId,
  newName,
  userId,
  accountRef,
}: {
  type: 'report' | 'dashboard' | 'wallboard' | 'workspace';
  typeId: string;
  newName: string;
  userId: string;
  accountRef: firebase.firestore.DocumentReference;
}): Promise<void> => {
  const currentDoc = await accountRef
    .collection('users')
    .doc(userId)
    .collection('favourites')
    .doc(buildContentViewIdAlt({ typeId, type }))
    .get();

  if (currentDoc.exists) {
    const data = currentDoc.data();
    if (data) {
      return accountRef
        .collection('users')
        .doc(userId)
        .collection('favourites')
        .doc(buildContentViewIdAlt({ typeId, type }))
        .set({
          version: '1',
          type,
          typeId,
          name: newName,
          createdOn: data.createdOn,
        });
    }
  }
};

export default updateFavouriteName;
