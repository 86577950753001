import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';

import Typography from 'kingpin/atoms/Typography';
import Row from '../../../../../components/Common/Row';
import Inputs from '../../../../../components/Inputs';
import Tooltip from '../../../../../components/Tooltip';
import InfoIconSrc from '../images/info.svg';
import Colors2 from '../../../../../theme/Colors2';
import SingleUseMetricPopupContext from '../../../../../contexts/SingleUseMetricPopupContext';
import AnalyticsContext from '../../../../../contexts/AnalyticsContext';

const InfoIcon = styled.img`
  width: 13px;
  cursor: help;
  align-self: center;
`;

const CoreToggle = ({
  status,
  setStatus,
  selectedMetric,
}: {
  status: Metrics.MetricStatus;
  setStatus: React.Dispatch<React.SetStateAction<Metrics.MetricStatus>>;
  selectedMetric: Metrics.NormalMetric | Metrics.CompoundMetric | undefined;
}) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const { isSingleUsePopup } = useContext(SingleUseMetricPopupContext);
  const [isToggled, setIsToggled] = useState<boolean>(status === 'core');
  const onToggled = useCallback(
    (newValue: boolean) => {
      if (newValue) {
        trackEvent('Metric Builder - Core Metric - Toggled on');
      } else {
        trackEvent('Metric Builder - Core Metric - Toggled off');
      }
      setIsToggled(newValue);
      if (newValue) {
        setStatus('core');
        return;
      }

      if (selectedMetric && selectedMetric.status !== 'core') {
        setStatus(selectedMetric.status);
        return;
      }

      setStatus('public');
    },
    [selectedMetric, setStatus, trackEvent],
  );

  if (isSingleUsePopup) {
    return null;
  }

  if (status === 'public' || status === 'core') {
    return (
      <Row centerAlign style={{ marginRight: 48 }}>
        <div style={{ marginRight: 8 }}>
          <Typography.Body type="Label">Core Metric</Typography.Body>
        </div>
        <div style={{ marginRight: 8 }}>
          <Inputs.Toggle
            testId={'core-toggle'}
            value={isToggled}
            onChange={onToggled}
            color={Colors2.Secondary.success}
          />
        </div>
        <Tooltip content="Help users find this metric by adding it to the core list inside the metric picker">
          <div style={{ display: 'flex' }}>
            <InfoIcon src={InfoIconSrc} />
          </div>
        </Tooltip>
      </Row>
    );
  }

  return null;
};

export default CoreToggle;
