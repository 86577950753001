import React from 'react';
import Typography from 'kingpin/atoms/Typography';
import PopupSideBar from '../../MetricPicker/MetricPickerPopup/PopupStyles/PopupSideBar';
import Row from '../../../Common/Row';

const SideBar = ({
  dataTypeOptions,
}: {
  dataTypeOptions: DropdownOption[];
}) => (
  <PopupSideBar.SideBarDiv>
    <Row spaceBetween centerAlign style={{ marginBottom: 10 }}>
      <Typography.Header type="H5">Filter by Dataset</Typography.Header>
    </Row>
    <PopupSideBar.SideBarContentDiv>
      <PopupSideBar.ListOuterDiv>
        <PopupSideBar.ListInnerDiv>
          <div style={{ marginTop: 8 }}>
            {dataTypeOptions.map((o) => (
              <PopupSideBar.ListItem
                data-testid={`dataset-${o.value}`}
                isSelected={!!o.isSelected}
                key={o.label}
                onClick={o.onSelected}
                centerAlign
                spaceBetween
              >
                <Typography.Body type="Body 12">{o.label}</Typography.Body>
                {o.isSelected && <PopupSideBar.CheckMark />}
              </PopupSideBar.ListItem>
            ))}
          </div>
        </PopupSideBar.ListInnerDiv>
      </PopupSideBar.ListOuterDiv>
    </PopupSideBar.SideBarContentDiv>
  </PopupSideBar.SideBarDiv>
);

export default SideBar;
