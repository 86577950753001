import React, { ChangeEvent } from 'react';
import Button from 'kingpin/atoms/Button';

import {
  GadgetFormInner,
  GadgetFormInnerContent,
  GadgetFormInnerTopBar,
  GadgetFormPreview,
  GadgetFormWrapper,
  InputWrapper,
} from '../../V5GadgetForm/V5GadgetForm';
import Typography from 'kingpin/atoms/Typography';
import { Section } from '../../MyAccount/Profile/styles';
import toSentenceCase from '../../../services/toSentenceCase';
import GroupByInput from '../GroupByInput';
import Preview from './Preview';
import Row from '../../Common/Row';
import Inputs from '../../Inputs';

const Label = ({ children }: { children: string }) => (
  <div style={{ marginBottom: 4 }}>
    <Typography.Body type="Label">{children}</Typography.Body>
  </div>
);

const getServerSortLabel = (
  sortBy: VisualisationDefinitions.ServerSortBy,
  metricName: string | undefined,
  groupBy: string | undefined,
): string => {
  if (sortBy === 'metric (asc)') {
    if (metricName) {
      return `${metricName} (asc)`;
    } else {
      return sortBy;
    }
  } else if (sortBy === 'metric (desc)') {
    if (metricName) {
      return `${metricName} (desc)`;
    } else {
      return sortBy;
    }
  } else if (sortBy === 'alpha (asc)') {
    if (groupBy) {
      return `${groupBy} (asc)`;
    } else {
      return sortBy;
    }
  } else if (sortBy === 'alpha (desc)') {
    if (groupBy) {
      return `${groupBy} (desc)`;
    } else {
      return sortBy;
    }
  } else {
    const error = new Error(`Unknown sortBy: ${sortBy}`);
    error.name = 'getServerSortLabel';
    throw error;
  }
};

const SingleMetricDateMatrixForm = ({
  name,
  onNameChanged,
  description,
  onDescriptionChanged,
  metricId,
  setMetricId,
  interval,
  setInterval,
  clientSortBy,
  setClientSortBy,
  serverSortBy,
  setServerSortBy,
  groupBy,
  setGroupBy,
  limit,
  onLimitChanged,
  draftGadget,
  comparisonCellFormat,
  setComparisonCellFormat,
  isValid,
  isEditing,
  onSave,
  isLoading,
  onDelete,
  onSaveAs,
  metricDisplayName,
  onMetricDisplayNameChanged,
  setMetricDisplayName,
  isGadgetBuilder,
  isSizedToFitOverridden,
  onIsSizedToFitChange,
  canDelete,
}: {
  name: string;
  onNameChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  description: string;
  onDescriptionChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  metricId?: string;
  setMetricId: React.Dispatch<React.SetStateAction<string | undefined>>;
  interval: FleetOps.Interval;
  setInterval: React.Dispatch<React.SetStateAction<FleetOps.Interval>>;
  clientSortBy?: VisualisationDefinitions.ClientSortBy;
  setClientSortBy: React.Dispatch<
    React.SetStateAction<VisualisationDefinitions.ClientSortBy | undefined>
  >;
  serverSortBy: VisualisationDefinitions.ServerSortBy;
  setServerSortBy: React.Dispatch<
    React.SetStateAction<VisualisationDefinitions.ServerSortBy>
  >;
  groupBy?: string;
  setGroupBy: React.Dispatch<React.SetStateAction<string | undefined>>;
  limit: string;
  onLimitChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  draftGadget?: VisualisationDefinitions.SingleMetricDateMatrix;
  comparisonCellFormat: MatrixCellType;
  setComparisonCellFormat: React.Dispatch<React.SetStateAction<MatrixCellType>>;

  isValid: boolean;
  isEditing: boolean;
  isLoading: boolean;

  onSave: () => void;
  onDelete: () => void;
  onSaveAs: () => void;

  metricDisplayName: string;
  onMetricDisplayNameChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  setMetricDisplayName: React.Dispatch<React.SetStateAction<string>>;
  isGadgetBuilder?: boolean;
  isSizedToFitOverridden?: boolean;
  onIsSizedToFitChange: (newValue: boolean) => void;
  canDelete: boolean;
}) => (
  <GadgetFormWrapper>
    <GadgetFormInner isGadgetBuilder={!!isGadgetBuilder}>
      <GadgetFormInnerTopBar isGadgetBuilder={!!isGadgetBuilder}>
        <Typography.Header type="H4">
          Single Metric Date Matrix
        </Typography.Header>
      </GadgetFormInnerTopBar>
      <GadgetFormInnerContent isGadgetBuilder={!!isGadgetBuilder}>
        <Section>
          <Label>Name</Label>
          <Inputs.TextInput
            placeholder="Name"
            value={name}
            onChange={onNameChanged}
          />
        </Section>
        <Section>
          <Label>Description</Label>
          <Inputs.TextInput
            placeholder="Name"
            value={description}
            onChange={onDescriptionChanged}
          />
        </Section>
        <Section>
          <Label>Metric</Label>
          <Inputs.MetricPicker
            metricId={metricId}
            setMetricId={setMetricId}
            noSpecials
            setMetricDisplayName={setMetricDisplayName}
          />
        </Section>
        <Section>
          <Label>Metric Display Name</Label>
          <Inputs.TextInput
            value={metricDisplayName}
            onChange={onMetricDisplayNameChanged}
            placeholder="Display name"
          />
        </Section>
        <Section>
          <Label>Group By</Label>
          <GroupByInput groupBy={groupBy} setGroupBy={setGroupBy} />
        </Section>
        <Section>
          <Label>Interval</Label>
          <Inputs.Dropdown
            options={['auto', 'day', 'week', 'month', 'quarter', 'year'].map(
              (i) => ({
                label: toSentenceCase(i),
                onSelected: () => {
                  setInterval(i as FleetOps.Interval);
                },
              }),
            )}
            selectedLabel={toSentenceCase(interval)}
          />
        </Section>
        <Section>
          <Row>
            <div style={{ flex: 1, marginRight: 12 }}>
              <Label>Server Sort Order</Label>
              <Inputs.Dropdown
                options={(
                  [
                    'alpha (asc)',
                    'alpha (desc)',
                    'metric (asc)',
                    'metric (desc)',
                  ] as VisualisationDefinitions.ServerSortBy[]
                ).map((sort) => ({
                  label: toSentenceCase(
                    getServerSortLabel(sort, metricDisplayName, groupBy),
                  ),
                  onSelected: () => {
                    setServerSortBy(sort);
                  },
                }))}
                selectedLabel={toSentenceCase(
                  getServerSortLabel(serverSortBy, metricDisplayName, groupBy),
                )}
              />
            </div>
            <div style={{ flex: 1 }}>
              <Label>Limit</Label>
              <Inputs.TextInput
                value={limit}
                onChange={onLimitChanged}
                type="number"
              />
            </div>
          </Row>
        </Section>
        <Section>
          <Label>Client Sort Order</Label>
          <Inputs.Dropdown
            options={(
              [
                undefined,
                'improvement (asc)',
                'improvement (desc)',
                'improvement % (asc)',
                'improvement % (desc)',
                'change (asc)',
                'change (desc)',
                'change % (asc)',
                'change % (desc)',
              ] as VisualisationDefinitions.ClientSortBy[]
            ).map((sort) => ({
              label: sort ? toSentenceCase(sort) : '',
              onSelected: () => {
                setClientSortBy(sort);
              },
            }))}
            selectedLabel={clientSortBy ? toSentenceCase(clientSortBy) : ''}
          />
        </Section>
        <Section>
          <Label>Comparison Cell Type</Label>
          <Inputs.Dropdown
            selectedLabel={toSentenceCase(comparisonCellFormat)}
            options={['delta', 'percentDelta', 'both'].map((o) => ({
              label: toSentenceCase(o),
              onSelected: () => {
                setComparisonCellFormat(o as MatrixCellType);
              },
            }))}
          />
        </Section>
        <Section>
          <InputWrapper>
            <Label>Is sized to fit</Label>
            <Inputs.Toggle
              value={!!isSizedToFitOverridden}
              onChange={onIsSizedToFitChange}
            />
          </InputWrapper>
        </Section>
        {isGadgetBuilder && (
          <Section>
            <>
              {isEditing && (
                <Row centerAlign spaceBetween style={{ marginBottom: 12 }}>
                  <div>
                    {canDelete && (
                      <Button
                        onClick={onDelete}
                        isDisabled={isLoading || !isValid}
                        isLoading={isLoading}
                        label="Delete"
                        type="Secondary"
                        size="Small"
                      />
                    )}
                  </div>
                  <Row>
                    <div style={{ marginRight: 12 }}>
                      <Button
                        onClick={onSaveAs}
                        isLoading={isLoading}
                        isDisabled={isLoading || !isValid}
                        label="Save as"
                        type="Primary"
                        size="Small"
                      />
                    </div>
                    <Button
                      onClick={onSave}
                      isLoading={isLoading}
                      isDisabled={isLoading || !isValid}
                      label="Update"
                      type="Primary"
                      size="Small"
                    />
                  </Row>
                </Row>
              )}
              {!isEditing && (
                <Row centerAlign style={{ marginBottom: 12 }}>
                  <Button
                    onClick={onSave}
                    isLoading={isLoading}
                    isDisabled={isLoading || !isValid}
                    type="Primary"
                    size="Small"
                    label="Create"
                  />
                </Row>
              )}
            </>
          </Section>
        )}
      </GadgetFormInnerContent>
    </GadgetFormInner>
    {isGadgetBuilder && (
      <GadgetFormPreview>
        {draftGadget && <Preview gadget={draftGadget} />}
        {!draftGadget && (
          <Typography.Body type="Body 12">
            Please fill out the form for a preview
          </Typography.Body>
        )}
      </GadgetFormPreview>
    )}
  </GadgetFormWrapper>
);

export default SingleMetricDateMatrixForm;
