import React, { ChangeEvent } from 'react';
import Button from 'kingpin/atoms/Button';

import Loading from '../../Loading';
import { Label } from '../../MyAccount/Profile/styles';
import Row from '../../Common/Row';
import UserPicker from '../../UserPicker';
import Tooltip from '../../Tooltip';
import TextArea from 'components/Inputs/TextArea';
import Form from 'kingpin/forms/Form';
import FormHeader from 'kingpin/forms/FormHeader';
import FormContent from 'kingpin/forms/FormContent';

const ExportModal = ({
  setSelectedUserIds,
  selectedUserIds,
  isExporting,
  startExport,
  notes,
  onNotesChanged,
  isDisabled,
  close,
}: {
  setSelectedUserIds: React.Dispatch<React.SetStateAction<string[]>>;
  selectedUserIds: string[];
  isExporting: boolean;
  startExport: () => void;
  notes: string;
  onNotesChanged: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  isDisabled: boolean;
  close: () => void;
}) => (
  <Form>
    <FormHeader title={'Share Report'} onClose={close} />
    <FormContent>
      {isExporting && (
        <React.Fragment>
          <Label>Sharing...</Label>
          <Loading />
        </React.Fragment>
      )}
      {!isExporting && (
        <React.Fragment>
          <UserPicker
            memberUserIds={selectedUserIds}
            setMemberUserIds={setSelectedUserIds}
            excludeCurrentUser={false}
            isLarge={false}
            placeholder="Enter name"
            excludeInvites
          />
          <div style={{ marginTop: 12 }}>
            <h3>Note</h3>
            <TextArea
              onChange={onNotesChanged}
              value={notes}
              placeholder="Add a message"
              style={{
                resize: 'none',
              }}
            />
          </div>
        </React.Fragment>
      )}
    </FormContent>
    <Row spaceBetween>
      <div />
      <Tooltip
        content={
          isDisabled ? 'You must first select users to share this with' : ''
        }
      >
        <Button
          onClick={startExport}
          isDisabled={isDisabled}
          label="Share"
          type="Primary"
          size="Small"
        />
      </Tooltip>
    </Row>
  </Form>
);

export default ExportModal;
