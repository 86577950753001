import React from 'react';
import PropTypes from 'prop-types';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import Row from '../Common/Row';
import SearchBox from './SearchBox';
import WidgetList from './WidgetList';
import Buttons from './Buttons';
import ConfigureDashboardGadget from '../ConfigureDashboardGadget';
import ChartTypePicker from './ChartTypePicker';
import DataTypePicker from './DataTypePicker';

const GUTTER = 22;

const WidgetGallery = ({
  isOpen,
  close,
  isConfiguringDashboardGadget,
  dashboardGadgetChart,
  isCreatingNewChart,
}: {
  isOpen: boolean;
  close: () => void;
  isConfiguringDashboardGadget: boolean;
  dashboardGadgetChart?: VisualisationDefinition;
  isCreatingNewChart?: boolean;
}) => (
  <ModalTransition>
    {isOpen && (
      <Modal
        width={'95vw'}
        height={'80vh'}
        onClose={close}
        shouldScrollInViewport={false}
        autoFocus={false}
      >
        {isConfiguringDashboardGadget &&
          (dashboardGadgetChart || isCreatingNewChart) && (
            <ConfigureDashboardGadget
              dashboardGadgetChart={dashboardGadgetChart}
              close={close}
            />
          )}
        {!isConfiguringDashboardGadget && (
          <React.Fragment>
            <div
              style={{
                flex: 1,
                width: '100%',
                height: '80vh',
              }}
            >
              <Row
                style={{
                  paddingTop: 16,
                  marginBottom: 16,
                  paddingRight: GUTTER,
                  paddingLeft: GUTTER,
                }}
                centerAlign
              >
                <SearchBox />
                <ChartTypePicker />
                <DataTypePicker />
              </Row>
              <div
                style={{
                  height: 'calc(80vh - 122px)',
                  overflowY: 'auto',
                  borderBottom: '1px solid #f2f2f2',
                }}
              >
                <WidgetList
                  style={{
                    paddingLeft: GUTTER,
                    paddingRight: GUTTER,
                  }}
                />
              </div>
              <Buttons
                style={{
                  paddingLeft: GUTTER,
                  paddingRight: GUTTER,
                }}
              />
            </div>
          </React.Fragment>
        )}
      </Modal>
    )}
  </ModalTransition>
);

WidgetGallery.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
};

export default WidgetGallery;
