const CREATE_BOARD: FleetOps.Permission =
  'fleetops.permissions.performance_board_create_board';
const VIEW_SETTINGS: FleetOps.Permission =
  'fleetops.permissions.performance_board_view_settings';
const MODIFY_WIDGETS: FleetOps.Permission =
  'fleetops.permissions.performance_board_modify_widgets';

const PERFORMANCE_BOARD_PERMISSIONS = {
  CREATE_BOARD,
  VIEW_SETTINGS,
  MODIFY_WIDGETS,
};
Object.freeze(PERFORMANCE_BOARD_PERMISSIONS);

export default PERFORMANCE_BOARD_PERMISSIONS;
