import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import UpArrowGreenSrc from 'images/up-arrow-green.svg';
import DownArrowRedSrc from 'images/down-arrow-red.svg';

import Cell from './V5Gadget/Matrix/Cell';
import colors from '../theme/colors';
import Row from './Common/Row';
import styled from 'styled-components';
import GridCellErrorBoundary from './GridCellErrorBoundary';

export const ArrowBackdrop = styled.div<{ color: string }>`
  width: 14px;
  height: 14px;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
  background-color: ${(props) => props.color}20;
`;

export const Arrow = styled.img`
  width: 14px;
  height: 14px;
`;

const ContractOnTargetCellRenderer = (props: ICellRendererParams) => {
  const delta = props.data.contractDifferenceInProgress;
  if (!delta) {
    return null;
  }

  if (delta >= 0) {
    const color = colors.MEDIUM_SEA_GREEN;
    return (
      <Cell rightAlign>
        <Row centerAlign style={{ justifyContent: 'flex-end' }}>
          <ArrowBackdrop color={color}>
            <Arrow src={UpArrowGreenSrc} />
          </ArrowBackdrop>
          <span>{Math.floor(delta)}</span>
        </Row>
      </Cell>
    );
  } else {
    const color = colors.RADICAL_RED;
    return (
      <Cell rightAlign>
        <Row centerAlign style={{ justifyContent: 'flex-end' }}>
          <ArrowBackdrop color={color}>
            <Arrow src={DownArrowRedSrc} />
          </ArrowBackdrop>
          <span>{Math.floor(delta)}</span>
        </Row>
      </Cell>
    );
  }
};

const Gate = (params: ICellRendererParams) => (
  <GridCellErrorBoundary
    params={params}
    childComponent={<ContractOnTargetCellRenderer {...params} />}
  />
);

export default Gate;
