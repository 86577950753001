const THREE_LETTER_CODES = [
  'AAB',
  'AYT',
  'SPN',
  'MUG',
  'XGA',
  'FFU',
  'DDW',
  'UQN',
  'REC',
  'GMC',
  'LSO',
  'ICC',
  'TSN',
  'EUO',
  'OTF',
  'AZD',
  'ESI',
  'DSU',
  'RKN',
  'ISC',
  'WHI',
  'MVU',
  'DGM',
  'SCY',
  'ZDT',
  'FEW',
  'DEX',
  'GJV',
  'LYV',
  'PVJ',
  'WFO',
  'QHW',
  'CYF',
  'BSB',
  'TOF',
  'FWH',
  'XEL',
  'CWF',
  'QMT',
  'UXN',
  'PIS',
  'RRM',
  'GHY',
  'JSY',
  'KFG',
  'VCP',
  'CGH',
  'RYL',
  'NBY',
  'BMQ',
];

export default THREE_LETTER_CODES;
