import React from 'react';
import Typography from 'kingpin/atoms/Typography';
import PopupStyles from '../../MetricPicker/MetricPickerPopup/PopupStyles';
import FieldListItem from './FieldListItem';
import TextInput from '../../../../kingpin/atoms/TextInput';

const List = ({
  searchText,
  onSearchTextChanged,
  onFieldAdded,
  onFieldRemoved,
  fieldOptions,
}: {
  onFieldAdded: (f: { field: string; dataType: string }) => void;
  onFieldRemoved: (f: { field: string; dataType: string }) => void;
  searchText: string;
  onSearchTextChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
  fieldOptions: {
    field: string;
    dataType: string;
    fieldLabel: string;
    isSelected: boolean;
    isDisabled: boolean;
  }[];
}) => (
  <>
    <PopupStyles.PopupContent.ListTopDiv>
      <div style={{ marginBottom: 16 }}>
        <Typography.Header type="H4">Add Field(s)</Typography.Header>
      </div>
      <TextInput
        value={searchText}
        onChange={onSearchTextChanged}
        placeholder="Search"
        icon="search"
        width="100%"
        autoFocus
      />
      <div style={{ marginBottom: 12 }} />
    </PopupStyles.PopupContent.ListTopDiv>
    <PopupStyles.PopupContent.ListOuterOuterDiv>
      <PopupStyles.PopupContent.ListOuterDiv>
        {fieldOptions.length > 0 && (
          <PopupStyles.PopupContent.ListInnerDiv>
            {fieldOptions.map((fo) => (
              <FieldListItem
                key={`${fo.field} - ${fo.dataType}`}
                fieldOption={fo}
                onFieldAdded={onFieldAdded}
                onFieldRemoved={onFieldRemoved}
              />
            ))}
          </PopupStyles.PopupContent.ListInnerDiv>
        )}
        {fieldOptions.length === 0 && (
          <Typography.Header type="H5">No fields found</Typography.Header>
        )}
      </PopupStyles.PopupContent.ListOuterDiv>
    </PopupStyles.PopupContent.ListOuterOuterDiv>
  </>
);

export default List;
