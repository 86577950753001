import React, { useCallback, useContext, useState } from 'react';
import ConfirmationStep from './ConfirmationStep';
import PerformanceWizardContext from '../PerformanceWizardContext';

const ConfirmationStepContainer = ({
  goToPreviousStep,
}: {
  goToPreviousStep: () => void;
}) => {
  const { wizardState } = useContext(PerformanceWizardContext);
  const [selectedTab, setSelectedTab] = useState<
    'details' | 'metrics' | 'fields'
  >('details');

  const goToDetails = useCallback(() => {
    setSelectedTab('details');
  }, []);

  const goToMetrics = useCallback(() => {
    setSelectedTab('metrics');
  }, []);

  const goToFields = useCallback(() => {
    setSelectedTab('fields');
  }, []);

  return (
    <ConfirmationStep
      goToPreviousStep={goToPreviousStep}
      selectedTab={selectedTab}
      goToDetails={goToDetails}
      goToMetrics={goToMetrics}
      goToFields={goToFields}
      isEditing={wizardState.isEditing}
    />
  );
};

export default ConfirmationStepContainer;
