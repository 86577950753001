import React from 'react';
import styled from 'styled-components';

import SummaryIcon from 'images/details-slideout/summary.svg';
import MetricIcon from 'images/details-slideout/graph-bar.svg';
import CommentsIcon from 'images/details-slideout/comments.svg';
import PermissionGates from '../../PermissionGates';
import PERMISSIONS from '../../../permissionDefinitions';

const Wrapper = styled.div`
  width: 48px;
  height: 100%;
  background-color: #fbfbfe;
  box-shadow: 1px 0 0 0 rgba(53, 45, 67, 0.07);
  padding: 24px 8px;
`;

const Button = styled.div<{ isActive?: boolean }>`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 6px;
  border-radius: 4px;
  cursor: pointer;
  ${(props) => props.isActive && 'background-color: #ededf2;'}

  &:hover {
    opacity: 0.7;
  }
`;

const Icon = styled.img``;

const scrollTo = (id: string) => {
  const scroller = document.getElementById('details-slide-scroll-area');

  const commitmentElem = document.getElementById('details-slide-commitment');
  const timelineElem = document.getElementById('details-slide-performance');
  const commentsElem = document.getElementById('details-slide-comments');

  if (!scroller || !timelineElem || !commentsElem) {
    return;
  }

  if (!commitmentElem) {
    if (id === 'details-slide-performance') {
      scroller.scrollTop = 0;
    } else if (id === 'details-slide-comments') {
      scroller.scrollTop = timelineElem.clientHeight;
    }
  } else {
    if (id === 'details-slide-commitment') {
      scroller.scrollTop = 0;
    } else if (id === 'details-slide-performance') {
      scroller.scrollTop = commitmentElem.clientHeight;
    } else if (id === 'details-slide-comments') {
      scroller.scrollTop =
        commitmentElem.clientHeight + timelineElem.clientHeight;
    }
  }
};

const Nav = ({
  activeNavElement,
}: {
  activeNavElement: CustomerLaneSlideOutNavElement;
}) => (
  <Wrapper>
    <PermissionGates.Has
      requiredPermission={PERMISSIONS.CUSTOMER_COMMITMENTS.MANAGE_COMMITMENTS}
    >
      <Button
        isActive={'commitment' === activeNavElement}
        onClick={() => scrollTo('details-slide-commitment')}
      >
        <Icon src={SummaryIcon} />
      </Button>
    </PermissionGates.Has>
    <Button
      isActive={'performance' === activeNavElement}
      onClick={() => scrollTo('details-slide-performance')}
    >
      <Icon src={MetricIcon} />
    </Button>
    <Button
      isActive={'comments' === activeNavElement}
      onClick={() => scrollTo('details-slide-comments')}
    >
      <Icon src={CommentsIcon} />
    </Button>
  </Wrapper>
);

export default Nav;
