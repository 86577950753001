import { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import getTargetFields from '../api/getTargetFields';
import GqlClientContext from '../../../contexts/GqlClientContext';
import useGetFieldLabel from '../../../hooks/useGetFieldLabel';

const useTargetFields = (dataset: string) => {
  const { client } = useContext(GqlClientContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [targetFields, setTargetFields] = useState<
    { rawField: string; label: string }[]
  >([]);
  const { getFieldLabel } = useGetFieldLabel();

  useEffect(() => {
    setIsLoading(true);
    getTargetFields(client, dataset).then((tFields) => {
      setTargetFields(
        _.sortBy(
          tFields.map((f) => ({
            rawField: f,
            label: getFieldLabel({ field: f, dataType: dataset }),
          })),
          'label',
        ),
      );
      setIsLoading(false);
    });
  }, [client, dataset, getFieldLabel]);

  return {
    targetFields,
    isLoadingTargetFields: isLoading,
  };
};

export default useTargetFields;
