import React, { useContext } from 'react';
import CommentList from './CommentList';
import CommentsContext from '../../../contexts/CommentsContext';

const CommentListContainer = ({
  isReversed,
  noScroll,
}: {
  isReversed: boolean;
  noScroll?: boolean;
}) => {
  const { comments, isLoading } = useContext(CommentsContext);
  return (
    <CommentList
      comments={isReversed ? comments.slice().reverse() : comments}
      isLoading={isLoading}
      noScroll={noScroll}
    />
  );
};

export default CommentListContainer;
