import { useCallback, useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';

const useImageSrc = (path?: string) => {
  const [imgSrc, setImgSrc] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const onUrlFound = useCallback((downloadUrl: string) => {
    setImgSrc(downloadUrl);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (!path) {
      return;
    }
    setIsLoading(true);
    const optimizedSrc = (() => {
      const is4k = window.screen.availWidth * window.devicePixelRatio >= 3840;

      const paths = path.split('/');
      const base = `${paths.slice(0, paths.length - 1).join('/')}/resized/${
        paths[paths.length - 1]
      }`;

      if (is4k) {
        return `${base}_3840x2160`;
      } else {
        return `${base}_1920x1080`;
      }
    })();

    firebase
      .storage()
      .ref(optimizedSrc)
      .getDownloadURL()
      .then(onUrlFound)
      .catch(() => {
        firebase.storage().ref(path).getDownloadURL().then(onUrlFound);
      });
  }, [onUrlFound, path]);

  return {
    imgSrc,
    isLoading,
  };
};

export default useImageSrc;
