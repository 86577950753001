import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { PaceMatrixFormTypes } from './types';
import getIdentifier from '../../../getIdentifier';

const useFormState = ({
  editingPaceGadget,
  onDraftChanged,
}: {
  editingPaceGadget?: VisualisationDefinitions.PaceMatrix;
  onDraftChanged?: React.Dispatch<
    React.SetStateAction<VisualisationDefinition | undefined>
  >;
}): PaceMatrixFormTypes.FormState => {
  const [id] = useState(
    editingPaceGadget ? editingPaceGadget.id : getIdentifier(),
  );
  const [name, setName] = useState<string>(
    editingPaceGadget ? editingPaceGadget.name : '',
  );
  const [description, setDescription] = useState<string>(
    editingPaceGadget ? editingPaceGadget.description : '',
  );
  const [metricId, setMetricId] = useState<string | undefined>(
    editingPaceGadget ? editingPaceGadget.metricId : undefined,
  );
  const [metricDisplayName, setMetricDisplayName] = useState<
    string | undefined
  >(
    editingPaceGadget && editingPaceGadget.metricDisplayName
      ? editingPaceGadget.metricDisplayName
      : undefined,
  );
  const [groupByField, setGroupByField] = useState<string | undefined>(
    editingPaceGadget ? editingPaceGadget.groupByField : undefined,
  );
  const [hasTotalsCol, setHasTotalsCol] = useState<boolean>(
    editingPaceGadget ? editingPaceGadget.hasTotalsCol : true,
  );
  const [hasPaceCol, setHasPaceCol] = useState<boolean>(
    editingPaceGadget ? editingPaceGadget.hasPaceCol : true,
  );
  const [isTotalsAndPacePinnedRight, setIsTotalsAndPacePinnedRight] =
    useState<boolean>(
      editingPaceGadget ? editingPaceGadget.isTotalsAndPacePinnedRight : true,
    );
  const [interval, setInterval] = useState<'day'>(
    editingPaceGadget ? editingPaceGadget.interval : 'day',
  );
  const [intervalOptions, setIntervalOptions] = useState<DropdownOption[]>([]);
  const [sortOrder, setSortOrder] = useState<PaceMatrixFormTypes.ISortOrder>(
    editingPaceGadget
      ? editingPaceGadget.sortOrder
      : { by: 'Term', direction: 'asc' },
  );

  const [isCardMode, setIsCardMode] = useState<boolean>(
    editingPaceGadget ? editingPaceGadget.isCardMode : false,
  );

  useEffect(() => {
    setIntervalOptions([
      {
        label: 'Day',
        value: 'day',
        isSelected: 'day' === interval,
        onSelected: () => {
          setInterval(() => 'day');
        },
      },
    ]);
  }, [interval]);

  const [draftGadget, setDraftGadget] = useState<
    VisualisationDefinitions.PaceMatrix | undefined
  >();

  const onNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const onMetricDisplayNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setMetricDisplayName(event.target.value);
  };

  const onDescriptionChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const buildDraft = useCallback(():
    | VisualisationDefinitions.PaceMatrix
    | undefined => {
    if (!metricId) {
      return undefined;
    }
    if (!groupByField) {
      return undefined;
    }

    return {
      id,
      name,
      description,
      type: 'PaceMatrix',
      metricId,
      metricDisplayName,
      interval,
      groupByField,
      hasTotalsCol,
      hasPaceCol,
      isTotalsAndPacePinnedRight,
      isCardMode,
      sortOrder,
    };
  }, [
    sortOrder,
    description,
    groupByField,
    hasPaceCol,
    hasTotalsCol,
    id,
    interval,
    isCardMode,
    isTotalsAndPacePinnedRight,
    metricDisplayName,
    metricId,
    name,
  ]);

  useEffect(() => {
    const newDraft = buildDraft();
    setDraftGadget(newDraft);
    if (onDraftChanged) {
      onDraftChanged(newDraft);
    }
  }, [buildDraft, onDraftChanged]);

  return {
    name,
    onNameChanged,
    description,
    onDescriptionChanged,
    metricId,
    setMetricId,
    metricDisplayName,
    onMetricDisplayNameChanged,
    groupByField,
    setGroupByField,
    intervalOptions,
    hasTotalsCol,
    setHasTotalsCol,
    hasPaceCol,
    setHasPaceCol,
    isTotalsAndPacePinnedRight,
    setIsTotalsAndPacePinnedRight,
    isCardMode,
    setIsCardMode,
    draftGadget,
    sortOrder,
    setSortOrder,
    isValid: !!draftGadget,
  };
};

export default useFormState;
