import React from 'react';

export interface AuthenticationContextType {
  authenticated: boolean; // Don't forget to update screens/SignOut
  isLoading: boolean;
  uid?: string;
  isSuperAdmin?: boolean;
  isWallboardUser?: boolean;
  wallBoardAccountId?: string;
}

const AuthenticationContext = React.createContext<AuthenticationContextType>({
  uid: 'NA',
  authenticated: false,
  isLoading: true,
});

export default AuthenticationContext;
