import styled, { createGlobalStyle, css } from 'styled-components';
import Colors2 from './theme/Colors2';
export const Y_SCROLL_BAR_WIDTH = 4;

const GlobalCss = css`
  html,
  body {
    height: 100%;
    width: 100%;

    font-family: Inter;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-synthesis: none;
  }

  //atlaskit modal body
  .css-1j124qj {
    border-radius: 8px !important;
  }

  .highcharts-tooltip-container {
    z-index: 9001 !important;
  }

  a {
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
  }

  ::-webkit-scrollbar {
    position: absolute;
    width: ${Y_SCROLL_BAR_WIDTH}px;
    height: 9px;
    // ag-grid ignores the height.
    // See StyleOverrides ln .ag-body-horizontal-scroll
    background-color: transparent;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #dcdcdc;
    border-radius: 7px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #c3c3c3;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  .ag-popup-editor {
    background-color: white;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.15) !important;
    border: 1px solid ${Colors2.Border};
    border-radius: 8px;
    z-index: 90001 !important;
  }
`;

const GlobalStyle = createGlobalStyle`
  ${GlobalCss}
`;

// GlobalStyle doesn't work for our SRR pattern to build the error reports
// Additionally, it has issues for rendering storybook
export const GlobalStyleForTests = styled.div`
  ${GlobalCss}
`;

export default GlobalStyle;
