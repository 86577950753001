import React, { useCallback, useContext, useEffect, useState } from 'react';
import userTypeCheckers from '../../../contextProviders/UsersProvider/userTypeCheckers';
import getUserDisplayName from '../../../contextProviders/UsersProvider/getUserDisplayName';
import UsersContext from '../../../contexts/UsersContext';

const useSearchUsers = () => {
  const { allUsers } = useContext(UsersContext);
  const [filteredUsers, setFilteredUsers] = useState<
    (
      | UserManagement.SignedUpUser
      | UserManagement.FleetOpsStaffUser
      | UserManagement.PendingUser
    )[]
  >([]);
  const [searchText, setSearchText] = useState<string>('');
  const onSearchTextChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
    },
    [],
  );

  useEffect(() => {
    if (searchText === '') {
      setFilteredUsers(allUsers.filter(userTypeCheckers.isNotWallboardUser));
      return;
    }

    setFilteredUsers(
      allUsers
        .filter(userTypeCheckers.isNotWallboardUser)
        .filter((u) => getUserDisplayName(u).includes(searchText)),
    );
  }, [searchText, allUsers]);

  return {
    filteredUsers,
    searchText,
    onSearchTextChanged,
  };
};

export default useSearchUsers;
