import { Link } from 'react-router-dom';
import React, { useContext } from 'react';

import Typography from 'kingpin/atoms/Typography';
import ListItemWrapper from '../../../DatasetDefinitions/ListItemWrapper';
import Column from '../../../DatasetDefinitions/Column';
import { buildReportShow } from '../../../../../navigation/appRoutes';
import Card from '../../../../../components/Common/Card';
import { PortalsContext } from '../../../../../contextProviders/PortalsProvider';

const ReportUsage = ({
  reports,
  usedId,
}: {
  reports: FleetOps.MetricReportUsage[];
  usedId: string;
}) => {
  const { selectedPortal } = useContext(PortalsContext);

  return (
    <>
      {reports.length > 0 && (
        <div style={{ marginBottom: 24 }}>
          <div style={{ marginBottom: '8px' }}>
            <Typography.Header type="H5">{`Reports (${reports.length})`}</Typography.Header>
          </div>
          <Card>
            {reports.map((r, index) => (
              <ListItemWrapper
                key={`${r.id}-${usedId}`}
                isLast={reports.length - 1 === index}
              >
                <Column
                  style={{
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                  }}
                >
                  <div>
                    <Link
                      to={buildReportShow({
                        id: r.reportId,
                        portal: selectedPortal,
                      })}
                      target="_blank"
                    >
                      <Typography.Body type="Body 12" color="#0041ea">
                        {r.reportName}
                      </Typography.Body>
                    </Link>
                  </div>
                  {r.gadgetName && (
                    <div>
                      <Link
                        to={buildReportShow({
                          id: r.reportId,
                          portal: selectedPortal,
                        })}
                        target="_blank"
                      >
                        <Typography.Body type="Body 12" color="#0041ea">
                          {r.gadgetName}
                        </Typography.Body>
                      </Link>
                    </div>
                  )}
                </Column>
              </ListItemWrapper>
            ))}
          </Card>
        </div>
      )}
    </>
  );
};

export default ReportUsage;
