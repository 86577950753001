import React from 'react';

interface OnboardingContextType {
  isOnboarding: boolean;
}
const OnboardingContext = React.createContext<OnboardingContextType>({
  isOnboarding: false,
});

export default OnboardingContext;
