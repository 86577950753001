import { useContext } from 'react';
import DriverScoreContext from '../contexts/DriverScoreContext';

const useOverride = (category: string) => {
  const { driverScore } = useContext(DriverScoreContext);

  if (!driverScore.overrides) {
    return undefined;
  }

  return driverScore.overrides.find((o: any) => o.category === category);
};

export default useOverride;
