import { useCallback, useContext, useEffect, useState } from 'react';
import GqlClientContext from '../../../contexts/GqlClientContext';
import getTargets from '../api/getTargets';
import dataTypeTargetTransforms from '../dataTypeTargetTransforms';

const useTargets = (dataset: string) => {
  const { client } = useContext(GqlClientContext);
  const [targets, setTargets] = useState<Targets.Wizard.DataTypeTarget[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const refreshTargets = useCallback(async () => {
    setIsLoading(true);
    const newTargets = await getTargets(client, dataset);
    setTargets(
      newTargets.map(dataTypeTargetTransforms.toWizard).sort((a, b) => {
        if (a.effectiveDate < b.effectiveDate) {
          return 1;
        } else if (a.effectiveDate === b.effectiveDate) {
          return 0;
        } else {
          return -1;
        }
      }),
    );
    setIsLoading(false);
  }, [client, dataset]);

  useEffect(() => {
    refreshTargets();
  }, [refreshTargets]);

  return {
    targets,
    isLoadingTargets: isLoading,
    refreshTargets,
  };
};

export default useTargets;
