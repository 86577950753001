import React, { ChangeEvent, useCallback, useContext, useState } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import Button from 'kingpin/atoms/Button';
import moment from 'moment';

import Cell from '../V5Gadget/Matrix/Cell';
import CurrentUserContext from '../../contexts/CurrentUserContext';
import usePopup from '../../hooks/usePopup';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import Typography from 'kingpin/atoms/Typography';
import Row from '../Common/Row';
import Inputs from '../Inputs';
import CloudFunctionClientContext from '../../contexts/CloudFunctionClientContext';
import RolesContext from '../../contexts/RolesContext';
import PERMISSIONS from '../../permissionDefinitions';

const SalesCommissionCell = (props: ICellRendererParams) => {
  const { api } = useContext(CloudFunctionClientContext);
  const { email } = useContext(CurrentUserContext);
  const { currentPermissions } = useContext(RolesContext);

  const { value, node } = props;
  const { id, date: dataDate } = node.data;
  const { isOpen, open, close } = usePopup();
  const [date, setDate] = useState<string>(() => moment().format('YYYY-MM-DD'));
  const [status, setStatus] = useState<'paid' | 'due' | undefined>(value);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const isTodayOrPast = dataDate <= moment().format('YYYY-MM-DD');

  const hubspotCompanyId = node.data.hubspotCompanyId as string;

  const statusOptions = [
    {
      label: 'Paid',
      onSelected: () => {
        setStatus('paid');
        setDate(moment().format('YYYY-MM-DD'));
      },
      isSelected: status === 'paid',
    },
    {
      label: 'Due',
      onSelected: () => {
        setStatus('due');
      },
      isSelected: status === 'due',
    },
  ];

  const onDateChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value);
  }, []);

  const onSave = useCallback(() => {
    setIsSaving(true);
    if (!status) {
      return;
    }

    const body =
      status === 'paid'
        ? {
            paidBy: email,
            paidOn: date,
          }
        : {};

    api
      .patch(`/commission/${hubspotCompanyId}/commission/${id}/${status}`, body)
      .then((response) => {
        if (response.ok) {
          node.updateData(response.data);
          setIsSaving(false);
          close();
        }
      });
  }, [api, close, date, email, hubspotCompanyId, id, node, status]);

  if (
    currentPermissions.includes(PERMISSIONS.INTERNAL_USE.MODIFY_COMMISSIONS)
  ) {
    return (
      <>
        <Cell onClick={isTodayOrPast ? open : undefined}>
          <span>{value ? value : isTodayOrPast ? 'Set' : ''}</span>
        </Cell>
        <ModalTransition>
          {isOpen && (
            <Modal
              onClose={close}
              shouldScrollInViewport={false}
              autoFocus={false}
              width={400}
            >
              <div
                style={{ marginTop: 16, marginBottom: 16, padding: '0px 16px' }}
              >
                <Typography.Header type="H5">
                  Update commission status
                </Typography.Header>
                <div style={{ marginBottom: 24 }}>
                  <div style={{ marginBottom: 12 }}>
                    <Typography.Body type="Label">Status</Typography.Body>
                    <Inputs.Dropdown
                      options={statusOptions}
                      selectedLabel={status}
                    />
                  </div>
                  <div style={{ marginBottom: 12 }}>
                    {status === 'paid' && (
                      <div>
                        <Typography.Body type="Label">Paid On</Typography.Body>
                        <Inputs.TextInput
                          type="date"
                          value={date}
                          onChange={onDateChange}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <Row style={{ justifyContent: 'flex-end' }}>
                  <Button
                    onClick={onSave}
                    isLoading={isSaving}
                    isDisabled={isSaving}
                    type="Primary"
                    size="Small"
                    label="Save"
                  />
                </Row>
              </div>
            </Modal>
          )}
        </ModalTransition>
      </>
    );
  }

  return (
    <Cell>
      <span>{value}</span>
    </Cell>
  );
};

export default SalesCommissionCell;
