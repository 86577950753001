import React, { useContext } from 'react';
import ReportDrillDowns from './ReportDrillDowns';
import ReportDrillDownsContext from '../../contexts/ReportDrillDownsContext';

const ReportDrillDownsContainer = ({
  isScope,
  isReport,
  isDashboardCard,
  onManualFilterChanged,
  onFilterAdded,
  onFilterRemoved,
  testIdPrefix,
  label,
}: {
  isScope?: boolean;
  isReport?: boolean;
  isDashboardCard?: boolean;
  onManualFilterChanged?: () => void;
  onFilterAdded?: () => void;
  onFilterRemoved?: () => void;
  testIdPrefix?: string;
  label?: string;
}) => {
  const { drillDowns, scope } = useContext(ReportDrillDownsContext);

  return (
    <ReportDrillDowns
      drillDowns={drillDowns}
      scope={scope}
      isScope={!!isScope}
      isReport={isReport}
      isDashboardCard={isDashboardCard}
      onManualFilterChanged={onManualFilterChanged}
      onFilterAdded={onFilterAdded}
      onFilterRemoved={onFilterRemoved}
      testIdPrefix={testIdPrefix}
      label={label}
    />
  );
};

export default ReportDrillDownsContainer;
