import React from 'react';

interface InSituMetricEditorContextType {
  isInSituEditor: boolean;
  onInSituEditorSaved: (
    metric: Metrics.NormalMetric | Metrics.CompoundMetric,
  ) => void;
}

const InSituMetricEditorContext =
  React.createContext<InSituMetricEditorContextType>({
    isInSituEditor: false,
    onInSituEditorSaved: () => {
      const e = new Error();
      e.name = 'onInSituEditorSaved is not defined';
      throw e;
    },
  });

export default InSituMetricEditorContext;
