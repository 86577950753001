import React, { useEffect, useState } from 'react';

const useBooleanOptions = ({
  value,
  setValue,
}: {
  value: string | number | string[] | boolean | undefined;
  setValue: React.Dispatch<
    React.SetStateAction<string | number | string[] | boolean | undefined>
  >;
}) => {
  const [options, setOptions] = useState<DropdownOption[]>([]);

  useEffect(() => {
    setOptions([
      {
        label: 'True',
        isSelected: typeof value === 'boolean' && value === true,
        onSelected: () => {
          setValue(true);
        },
      },
      {
        label: 'False',
        isSelected: typeof value === 'boolean' && value === false,
        onSelected: () => {
          setValue(false);
        },
      },
    ]);
  }, [setValue, value]);

  return options;
};

export default useBooleanOptions;
