import React from 'react';

interface OrderDetailsContextType {
  orderNumber: string;
  events: TimelineEvent[];
  orderSummary?: OrderSummary;
  selectedEvent?: TimelineEvent;
  setSelectedEvent: React.Dispatch<
    React.SetStateAction<TimelineEvent | undefined>
  >;
  comments: CommentType[];
  isLoadingComments: boolean;
  isLoading: boolean;
}

const OrderDetailsContext = React.createContext<OrderDetailsContextType>(
  {} as OrderDetailsContextType,
);

export default OrderDetailsContext;
