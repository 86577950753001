import React, { useContext } from 'react';
import { ModalTransition } from '@atlaskit/modal-dialog';
import { useLocation } from 'react-router-dom';
import PERMISSIONS from '../../../../permissionDefinitions';
import BaseViewsContext from '../../../../contexts/BaseViewsContext';
import Button from 'kingpin/atoms/Button';
import usePopup from '../../../../hooks/usePopup';
import PerformanceDatasetWizard from './PerformanceDatasetWizard';
import PermissionGates from '../../../../components/PermissionGates';
import Row from 'components/Common/Row';
import UploadFilesButton from './FileUploads/UploadFilesButton';
import FeatureGate, { FEATURE_GATES } from 'components/FeatureGate';

const CreatePerformanceDatasetButton = () => {
  const { search } = useLocation();
  const { isLoading } = useContext(BaseViewsContext);
  const params = new URLSearchParams(search);
  const { isOpen, open, close } = usePopup(params.has('create_dataset'));

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Row>
        <FeatureGate featureName={FEATURE_GATES.FILE_UPLOAD}>
          <PermissionGates.Has
            requiredPermission={PERMISSIONS.DATA_MANAGEMENT.UPLOAD_FILES}
          >
            <div style={{ marginRight: '16px' }}>
              <UploadFilesButton />
            </div>
          </PermissionGates.Has>
        </FeatureGate>

        <PermissionGates.Has
          requiredPermission={
            PERMISSIONS.DATA_MANAGEMENT.UPDATE_PERFORMANCE_DATASET_CONFIG
          }
        >
          <Button
            label="Create Dataset"
            icon="add"
            onClick={open}
            type="Primary"
            size="Small"
          />
        </PermissionGates.Has>
      </Row>
      <ModalTransition>
        {isOpen && <PerformanceDatasetWizard close={close} />}
      </ModalTransition>
    </>
  );
};

export default CreatePerformanceDatasetButton;
