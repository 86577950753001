import React, { useContext } from 'react';
import DashboardGadgetReportLinkButton from './DashboardGadgetReportLinkButton';
import ReportDrillDownsContext from '../../contexts/ReportDrillDownsContext';
import usePopupCallback from '../../hooks/usePopupCallback';
import DashboardGadgetContext from '../../contexts/DashboardGadgetContext';

const DashboardGadgetReportLinkButtonContainer = () => {
  const { dashboardGadget } = useContext(DashboardGadgetContext);
  const onClick = usePopupCallback(dashboardGadget);
  if (onClick) {
    const testId = `popup-${dashboardGadget.id}`;
    return (
      <DashboardGadgetReportLinkButton onClick={onClick} testId={testId} />
    );
  }
  return null;
};

const RenderGate = () => {
  const { isForDashboardCard } = useContext(ReportDrillDownsContext);
  if (!isForDashboardCard) {
    return null;
  }

  return <DashboardGadgetReportLinkButtonContainer />;
};

export default RenderGate;
