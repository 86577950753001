import React, { useContext, useEffect, useState } from 'react';

import Typography from 'kingpin/atoms/Typography';
import getMetricDescription from '../../../../utils/metrics/getMetricDescription';
import MetricPreview from '../MetricPreview';
import Card from '../../../../components/Common/Card';
import metricTypeCheckers from '../../../../types/metricTypeCheckers';
import SPECIFIC_SET_ROUTES from '../../SpecificSet/routes';
import CompoundMetricsContext from '../../../../contexts/CompoundMetricsContext';
import Expression from '../../../../components/MetricsInfoButton/Expression';
import ConditionalLink from '../ConditionalLink';
import useGetDatasetLabel from '../../../../components/Inputs/MetricPicker/MetricPickerPopup/hooks/useGetDatasetLabel';
import { Link } from 'react-router-dom';
import { getMetricLink } from '../../AllSets/routes';
import useOriginMetric from '../../../../hooks/useOriginMetric';
import Filters from './Filters';
import DetailsTabItem from './DetailsTabItem';
import { PortalsContext } from '../../../../contextProviders/PortalsProvider';

const FieldOriginalMetric = ({ metric }: { metric: Metrics.NormalMetric }) => {
  const originMetric = useOriginMetric(metric);
  const { selectedPortal } = useContext(PortalsContext);
  if (!originMetric) {
    return null;
  }

  return (
    <DetailsTabItem header="Field Origin">
      <>
        {metricTypeCheckers.isSpecialMetric(originMetric) && (
          <Typography.Body type="Body 12">{originMetric.name}</Typography.Body>
        )}
        {!metricTypeCheckers.isSpecialMetric(originMetric) && (
          <Link
            to={getMetricLink({
              metric: originMetric,
              portal: selectedPortal,
            })}
            target="_blank"
          >
            <Typography.Body type="Body 12">
              {originMetric.name}
            </Typography.Body>
          </Link>
        )}
      </>
    </DetailsTabItem>
  );
};

const NormalDetailsTab = ({
  metric,
  isPreviewHidden,
}: {
  metric: Metrics.NormalMetric;
  isPreviewHidden?: boolean;
}) => {
  const { selectedPortal } = useContext(PortalsContext);
  const getDatasetLabel = useGetDatasetLabel();
  return (
    <div>
      <Card
        style={{ marginBottom: isPreviewHidden ? 0 : 24, padding: 16 }}
        hideBorders={isPreviewHidden}
      >
        <DetailsTabItem header="Description">
          <Typography.Body type="Body 12">
            {getMetricDescription(metric)}
          </Typography.Body>
        </DetailsTabItem>

        <DetailsTabItem header="Dataset">
          <ConditionalLink
            to={SPECIFIC_SET_ROUTES.buildBase(metric.dataType, selectedPortal)}
            text={getDatasetLabel(metric.dataType)}
            dataset={metric.dataType}
            noMargin
          />
        </DetailsTabItem>

        <DetailsTabItem header="Field">
          <ConditionalLink
            to={`${SPECIFIC_SET_ROUTES.buildBase(metric.dataType, selectedPortal)}?field=${
              metric.field
            }`}
            text={metric.field}
            dataset={metric.dataType}
            noMargin
          />
        </DetailsTabItem>

        <FieldOriginalMetric metric={metric} />
        <DetailsTabItem header="Aggregation">
          <Typography.Body type="Body 12">{metric.aggFunc}</Typography.Body>
        </DetailsTabItem>

        <Filters filters={metric.filters} />
      </Card>
      {!isPreviewHidden && <MetricPreview metric={metric} />}
    </div>
  );
};

const CompoundDetailsTab = ({
  metric,
  isPreviewHidden,
}: {
  metric: Metrics.CompoundMetric;
  isPreviewHidden?: boolean;
}) => {
  const { compoundMetricInputsLookup } = useContext(CompoundMetricsContext);
  const [usedMetrics, setUsedMetrics] = useState<Metrics.NormalMetric[]>([]);
  useEffect(() => {
    const newUsed = compoundMetricInputsLookup[metric.id];
    if (newUsed) {
      setUsedMetrics(newUsed);
    } else {
      setUsedMetrics([]);
    }
  }, [compoundMetricInputsLookup, metric.id]);

  return (
    <div>
      <Card
        style={{ marginBottom: isPreviewHidden ? 0 : 24, padding: 16 }}
        hideBorders={isPreviewHidden}
      >
        <div style={{ marginBottom: 24 }}>
          <Typography.Header type="H5">Description</Typography.Header>
          <Typography.Body type="Body 12">
            {getMetricDescription(metric)}
          </Typography.Body>
        </div>
        <div style={{ marginBottom: 24 }}>
          <Typography.Header type="H5">Expression</Typography.Header>

          <Expression
            isLinks
            expression={metric.expression}
            usedMetrics={usedMetrics}
          />
        </div>
      </Card>
      {!isPreviewHidden && <MetricPreview metric={metric} />}
    </div>
  );
};

const DetailsTab = ({
  metric,
  isPreviewHidden,
}: {
  metric: Metrics.NormalMetric | Metrics.CompoundMetric;
  isPreviewHidden?: boolean;
}) => {
  if (metricTypeCheckers.isNormalMetric(metric)) {
    return (
      <NormalDetailsTab metric={metric} isPreviewHidden={isPreviewHidden} />
    );
  }

  return (
    <CompoundDetailsTab metric={metric} isPreviewHidden={isPreviewHidden} />
  );
};

export default DetailsTab;
