import React from 'react';
import MetricListItem from './MetricListItem';
import Typography from 'kingpin/atoms/Typography';
import PopupStyles from './PopupStyles';

const List = ({
  metrics,
  selectedMetricId,
  onMetricClicked,
}: {
  metrics: Metrics.Metric[];
  selectedMetricId?: string;
  onMetricClicked: (metric: Metrics.Metric) => void;
}) => (
  <PopupStyles.PopupContent.ListOuterOuterDiv>
    <PopupStyles.PopupContent.ListOuterDiv>
      {metrics.length > 0 && (
        <PopupStyles.PopupContent.ListInnerDiv>
          {metrics.map((m) => (
            <MetricListItem
              key={m.id}
              metric={m}
              selectedMetricId={selectedMetricId}
              onMetricClicked={onMetricClicked}
            />
          ))}
        </PopupStyles.PopupContent.ListInnerDiv>
      )}
      {metrics.length === 0 && (
        <Typography.Header type="H5">No metrics found</Typography.Header>
      )}
    </PopupStyles.PopupContent.ListOuterDiv>
  </PopupStyles.PopupContent.ListOuterOuterDiv>
);

export default List;
