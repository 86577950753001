import React from 'react';
import useDateFields from '../hooks/useDateFields';
import DateFieldsContext from 'contexts/DateFieldsContext';

const DateFieldsProvider = ({
  children,
  dataTypes,
}: {
  children: JSX.Element | JSX.Element[];
  dataTypes: string[];
}) => {
  const { dateFields } = useDateFields({ dataTypes });

  return (
    <DateFieldsContext.Provider value={{ dateFields }}>
      {children}
    </DateFieldsContext.Provider>
  );
};

export default DateFieldsProvider;
