import { FlexDiv } from './styles';
import React from 'react';

const Flexed = ({
  flex,
  style,
  children,
}: {
  flex: number;
  style?: any;
  children?: JSX.Element | JSX.Element[] | string | undefined | null | number;
}) => <FlexDiv style={{ ...style, flex }}>{children}</FlexDiv>;

export default Flexed;
