import React, { useContext, useEffect, useState } from 'react';
import OrderDetailsContext from '../contexts/OrderDetailsContext';
import AccountContext from '../contexts/AccountContext';
import getTimelineEvents from '../api/getTimelineEvents';
import getOrderSummary from '../api/getOrderSummary';
import useComments from '../hooks/useComments';
import GqlClientContext from '../contexts/GqlClientContext';

const OrderDetailsProvider = ({
  orderNumber,
  children,
}: {
  orderNumber: string;
  children: JSX.Element | JSX.Element[];
}) => {
  const { client } = useContext(GqlClientContext);
  const [events, setEvents] = useState<TimelineEvent[]>([]);
  const [orderSummary, setOrderSummary] = useState<OrderSummary | undefined>();
  const [selectedEvent, setSelectedEvent] = useState<
    TimelineEvent | undefined
  >();
  const { comments, isLoading: isLoadingComments } = useComments(
    orderNumber,
    'jobDetails',
  );
  const [isLoadingEvents, setIsLoadingEvents] = useState<boolean>(true);
  const [isLoadingSummary, setIsLoadingSummary] = useState<boolean>(true);

  const { id } = useContext(AccountContext);
  useEffect(() => {
    getTimelineEvents(id, orderNumber, client).then((r) => {
      setEvents(r);
      setIsLoadingEvents(false);
    });
    getOrderSummary(id, orderNumber, client).then((r) => {
      setOrderSummary(r);
      setIsLoadingSummary(false);
    });
  }, [client, id, orderNumber]);

  const isLoading = isLoadingEvents || isLoadingSummary || isLoadingComments;

  return (
    <OrderDetailsContext.Provider
      value={{
        orderNumber,
        events,
        orderSummary,
        selectedEvent,
        setSelectedEvent,
        comments,
        isLoadingComments,
        isLoading,
      }}
    >
      {children}
    </OrderDetailsContext.Provider>
  );
};

export default OrderDetailsProvider;
