import React, { useCallback } from 'react';
import useGetDatasetLabel from '../../MetricPicker/MetricPickerPopup/hooks/useGetDatasetLabel';
import PopupStyles from '../..//MetricPicker/MetricPickerPopup/PopupStyles';
import Typography from 'kingpin/atoms/Typography';
import Tooltip from '../../../Tooltip';

const FieldListItem = ({
  fieldOption,
  onFieldAdded,
  onFieldRemoved,
}: {
  fieldOption: {
    field: string;
    dataType: string;
    fieldLabel: string;
    isSelected: boolean;
    isDisabled: boolean;
  };
  onFieldAdded: (f: { field: string; dataType: string }) => void;
  onFieldRemoved: (f: { field: string; dataType: string }) => void;
}) => {
  const getDatasetLabel = useGetDatasetLabel();
  const { field, dataType, fieldLabel, isSelected, isDisabled } = fieldOption;
  const dataTypeLabel = getDatasetLabel(dataType);
  const onClicked = useCallback(() => {
    if (isDisabled) {
      return;
    }

    if (isSelected) {
      onFieldRemoved({ field, dataType });
    } else {
      onFieldAdded({ field, dataType });
    }
  }, [isDisabled, dataType, field, isSelected, onFieldAdded, onFieldRemoved]);

  return (
    <Tooltip
      content={isDisabled ? 'This field has already been selected' : undefined}
    >
      <PopupStyles.PopupContent.ListItemDiv
        isSelected={isSelected}
        onClick={onClicked}
        isDisabled={isDisabled}
        data-testid={`select-${dataType}-${field}`}
      >
        <PopupStyles.PopupContent.DetailsCol>
          <PopupStyles.PopupContent.EllipsisDiv>
            <Typography.Body type="Body 12">{fieldLabel}</Typography.Body>
          </PopupStyles.PopupContent.EllipsisDiv>
          <PopupStyles.PopupContent.EllipsisDiv>
            <Typography.Body type="Body 12">{`Dataset: ${dataTypeLabel}`}</Typography.Body>
          </PopupStyles.PopupContent.EllipsisDiv>
        </PopupStyles.PopupContent.DetailsCol>

        <PopupStyles.PopupContent.StatusIcons
          isSelected={isSelected}
          isCore={false}
        />
      </PopupStyles.PopupContent.ListItemDiv>
    </Tooltip>
  );
};

export default FieldListItem;
