import React from 'react';
import UserIcon from '../Common/UserIcon';
import Row from '../Common/Row';
import Badge from '../Badge';

const AccessUsers = ({
  users,
  type,
}: {
  users: (
    | UserManagement.SignedUpUser
    | UserManagement.PendingUser
    | UserManagement.FleetOpsStaffUser
  )[];
  type: 'Users' | 'Public' | 'Private' | 'Admin Only';
}) => (
  <Row centerAlign style={{ marginRight: 10 }}>
    {type === 'Users' && (
      <>
        {users.slice(0, 3).map((u) => (
          <UserIcon {...u} key={u.id} noMargin />
        ))}
        {users.length > 3 && (
          <UserIcon
            {...users[3]}
            key={users[3].id}
            noMargin
            isEllipsis
            displayName={`+ ${users.length - 3}`}
            tooltip={users
              .slice(3)
              .map((u) => u.displayName)
              .join(', ')}
          />
        )}
      </>
    )}
    {type === 'Private' && <Badge text={'Private'} badgeType={'Default'} />}
    {type === 'Public' && <Badge text={'Public'} badgeType={'Default'} />}
    {type === 'Admin Only' && (
      <Badge text={'Admin Only'} badgeType={'Default'} />
    )}
  </Row>
);

export default AccessUsers;
