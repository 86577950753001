import React, { useCallback, useContext, useEffect, useState } from 'react';
import EntityDefinitionsContext from 'contexts/EntityDefinitionsContext';
import useDatasetDef from 'components/Inputs/FilterBuilder/useDatasetDef';
import useGetFieldLabel from 'hooks/useGetFieldLabel';

const useFieldGreenList = ({
  entityDataset,
  primaryField,
}: {
  entityDataset: string | undefined;
  primaryField: string | undefined;
}) => {
  const { isLoading } = useContext(EntityDefinitionsContext);
  const datasetDef = useDatasetDef(entityDataset);

  const buildGreenList = useCallback(() => {
    if (datasetDef === undefined || isLoading) {
      return [];
    }

    const datasetFields = datasetDef.fields
      .filter(
        (f) => f.type === 'text' || f.type === 'date' || f.type === 'dateText',
      )
      .map((f) => f.field);
    return datasetFields.filter((f1) => {
      // Not used in primary field
      return primaryField !== f1;
    });
  }, [datasetDef, isLoading, primaryField]);
  const [greenList, setGreenList] = useState<string[]>(buildGreenList);
  useEffect(() => {
    setGreenList(buildGreenList());
  }, [buildGreenList]);

  return greenList;
};

const useContextFieldOptions = ({
  entityDataset,
  primaryField,
  contextFields,
  setContextFields,
}: {
  entityDataset: string | undefined;
  primaryField: string | undefined;
  contextFields: string[];
  setContextFields: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const { getFieldLabel } = useGetFieldLabel();
  const fieldGreenList = useFieldGreenList({
    entityDataset,
    primaryField,
  });
  const buildOptions = useCallback(() => {
    if (!entityDataset) {
      return [];
    }

    return fieldGreenList.map((f1) => {
      return {
        value: f1,
        label: getFieldLabel({ field: f1, dataType: entityDataset }),
        isSelected: contextFields.some((f2) => f2 === f1),
        onSelected: () => {
          setContextFields((current) => {
            const isSelected = current.some((f2) => f2 === f1);
            if (isSelected) {
              return current.filter((f2) => f2 !== f1);
            } else {
              return [...current, f1];
            }
          });
        },
      };
    });
  }, [
    fieldGreenList,
    getFieldLabel,
    entityDataset,
    contextFields,
    setContextFields,
  ]);
  const [options, setOptions] = useState<DropdownOption[]>(buildOptions);
  useEffect(() => {
    setOptions(buildOptions());
  }, [buildOptions]);

  return options;
};

export default useContextFieldOptions;
