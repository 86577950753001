import React from 'react';
import styled from 'styled-components';

import BrandIcon from '../images/brand-dark.svg';

const Image = styled.img`
  width: 280px;
`;

const Branding = ({ marginBottom }: { marginBottom?: number }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: marginBottom ? marginBottom : 32,
    }}
  >
    <Image src={BrandIcon} />
  </div>
);

export default Branding;
