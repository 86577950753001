import { useCallback, useContext } from 'react';
import DatasetFiltersContext from 'screens/DataManager/DatasetFilters/context/DatasetFiltersContext';
import filterPlateTypeCheckers from 'types/filterPlateTypeCheckers';

const useSelectDefaultDatasetFilters = () => {
  const { getDefaultDatasetFilterPlates } = useContext(DatasetFiltersContext);

  const handleSelectDefaultDatasetFilters = useCallback(
    ({
      dataset,
      setDrillDowns,
    }: {
      dataset: string;
      setDrillDowns: React.Dispatch<React.SetStateAction<FilterPlate[]>>;
    }) => {
      if (!setDrillDowns) {
        return;
      }

      setDrillDowns((currentDrillDowns) => {
        const nonDatasetFilters = currentDrillDowns.filter(
          filterPlateTypeCheckers.isNotDataset,
        );

        const defaultDatasetFilters = getDefaultDatasetFilterPlates({
          dataset,
        });

        return [...defaultDatasetFilters, ...nonDatasetFilters];
      });
    },
    [getDefaultDatasetFilterPlates],
  );
  return handleSelectDefaultDatasetFilters;
};

export default useSelectDefaultDatasetFilters;
