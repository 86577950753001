const POPUP_HEIGHT = '70vh';
const POPUP_WIDTH = '35vw';
const POPUP_WIDTH_SMALL = '47vw';
const SIDEBAR_WIDTH = '280px';
const CARD_PADDING_HORIZONTAL = '16px';
const CARD_PADDING_VERTICAL = '16px';
const TOP_BAR_HEIGHT = 32;

const MetricInfoConstants = {
  POPUP_HEIGHT,
  POPUP_WIDTH,
  POPUP_WIDTH_SMALL,
  SIDEBAR_WIDTH,
  CARD_PADDING_HORIZONTAL,
  CARD_PADDING_VERTICAL,
  TOP_BAR_HEIGHT,
};

export default MetricInfoConstants;
