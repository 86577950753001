import React, { useContext } from 'react';
import QuickFilters from './QuickFilters';
import BoardContext from '../../../contexts/BoardContext';

const QuickFiltersContainer = ({
  onQuickFilterChanged,
}: {
  onQuickFilterChanged?: () => void;
}) => {
  const { board, quickFilters, selectedQuickFilters, setSelectedQuickFilters } =
    useContext(BoardContext);
  const { quickFilterOrder } = board;
  const onFilterToggled = (filter: SavedFilter) => {
    const isSelected = selectedQuickFilters.some((f) => f.id === filter.id);
    if (isSelected) {
      setSelectedQuickFilters((fs) => fs.filter((f) => f.id !== filter.id));
    } else {
      setSelectedQuickFilters((fs) => [...fs, filter]);
    }
    if (onQuickFilterChanged) {
      onQuickFilterChanged();
    }
  };

  const orderedFilters = quickFilterOrder
    .map((filterId) => quickFilters.find((f) => f.id === filterId))
    .filter((f) => !!f) as SavedFilter[];
  const filtersNotInOrder = quickFilters.filter(
    (f) => !quickFilterOrder.includes(f.id),
  );
  orderedFilters.push(...filtersNotInOrder);

  return (
    <QuickFilters
      quickFilters={orderedFilters}
      onFilterToggled={onFilterToggled}
      selectedQuickFilters={selectedQuickFilters}
    />
  );
};

export default QuickFiltersContainer;
