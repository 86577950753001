import React from 'react';

import styled from 'styled-components';
import Row from '../../Common/Row';
import QueryInputRow from './QueryInputRow';
import QueryLoads from './QueryLoads';
import AddNewRewardButton from './AddNewRewardButton';
import QueryLaneCommitConfigurations from './QueryLaneCommitConfigurations';
import Form from 'kingpin/forms/Form';

const GridWrapper = styled.div`
  height: 600px;
`;

const NewCommitPopup = () => (
  <Form>
    <Row centerAlign spaceBetween style={{ marginBottom: 24 }}>
      <QueryInputRow />
      <Row centerAlign>
        <QueryLoads />
        <AddNewRewardButton />
      </Row>
    </Row>

    <GridWrapper>
      <QueryLaneCommitConfigurations />
    </GridWrapper>
  </Form>
);

export default NewCommitPopup;
