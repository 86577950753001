import ScorecardContext from 'contexts/ScorecardContext';
import LocalTimelineContext from 'contexts/Timeline/LocalTimelineContext';
import { useCallback, useContext } from 'react';
import getIdentifier from 'getIdentifier';
import AnalyticsContext from 'contexts/AnalyticsContext';
import useManualFormState from './useManualFormState';
import _ from 'lodash';

const useManualForm = ({
  manualKpi,
  setHasUnsavedChanges,
  onCloseConfirmed,
}: {
  manualKpi?: Scorecards.ManualKpiRow;
  close: () => void;
  setHasUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  isCloseConfirmationOpen: boolean;
  onCloseConfirmed: () => void;
  onCloseConfirmationClosed: () => void;
}) => {
  const { addEvent } = useContext(LocalTimelineContext);
  const { trackEvent } = useContext(AnalyticsContext);

  const {
    label,
    canSaveKpi,
    setCanSaveKpi,
    onNameChanged,
    onAdvancedToggled,
    isShowingAdvanced,
    formattingOptions,
    trackDrillDownAdded,
    targets,
    setTargets,
    isTargetsDisabled,
    setIsTargetsDisabled,
    formatting,
    currencyType,
    setCurrencyType,
    reportDrillDownId,
    setReportDrillDownId,
    dynamicTargetOverride,
    setProrationOverride,
    isColorOrderReversed,
    setIsColorOrderReversed,
    markHasUnsavedChanges,
  } = useManualFormState({ manualKpi, setHasUnsavedChanges });

  const {
    periods,
    scorecard,
    updateScorecard,
    setManualKpiIdBeingEdited,
    highlightKpi,
  } = useContext(ScorecardContext);
  const buildNewManualKpi = useCallback(():
    | Scorecards.ManualKpiRow
    | undefined => {
    if (
      (Object.keys(targets.targets).length === 0 && !isTargetsDisabled) ||
      label === ''
    ) {
      return undefined;
    }

    const newData = periods.map((period) => ({
      date: period.startDate,
    }));

    return {
      type: 'manualKpiRow',
      id: manualKpi ? manualKpi.id : getIdentifier(),
      data: manualKpi ? manualKpi.data : newData,
      label,
      formatting,
      currencyType,
      reportDrillDownId,
      isTargetsDisabled,
      dynamicTargetOverride,
      targets,
      isColorOrderReversed,
    };
  }, [
    currencyType,
    dynamicTargetOverride,
    formatting,
    isColorOrderReversed,
    isTargetsDisabled,
    label,
    manualKpi,
    periods,
    reportDrillDownId,
    targets,
  ]);

  const isValid = buildNewManualKpi() !== undefined && canSaveKpi;

  const saveRow = useCallback(() => {
    const newKpi = buildNewManualKpi();
    if (!newKpi) {
      return;
    }

    const newKpis = (() => {
      if (manualKpi) {
        return scorecard.kpis.map((kpi) => {
          if (kpi.id === manualKpi.id) {
            return newKpi;
          }
          return kpi;
        });
      }

      return [newKpi, ...scorecard.kpis];
    })();

    const newScorecard = {
      ...scorecard,
      kpis: newKpis,
    };

    const onSuccess = () => {
      setHasUnsavedChanges(false);
      onCloseConfirmed();
      if (!manualKpi) {
        setManualKpiIdBeingEdited(newKpi.id);
        highlightKpi(newKpi.id);
        trackEvent('KPI List - Manual KPI - Saved');
      } else {
        trackEvent('KPI List - Manual KPI - Edit Saved');
      }
    };
    updateScorecard(newScorecard)
      .then(async () => {
        if (addEvent) {
          if (!manualKpi) {
            await addEvent({
              actionText: 'added Manual KPI',
              contextText: label,
            });
          } else {
            const hasChangedMetricName = manualKpi.label !== label;
            if (hasChangedMetricName) {
              await addEvent({
                actionText: 'changed Manual KPI label',
                contextText: `from ${manualKpi.label} to ${label}`,
              });
            }

            const hasChangedTargets = !_.isEqual(
              newKpi.targets,
              manualKpi.targets,
            );
            if (hasChangedTargets) {
              await addEvent({
                actionText: 'changed targets',
                contextText: `for ${label}`,
              });
            }
          }
        }
      })
      .then(onSuccess);
  }, [
    buildNewManualKpi,
    scorecard,
    updateScorecard,
    manualKpi,
    setHasUnsavedChanges,
    onCloseConfirmed,
    trackEvent,
    setManualKpiIdBeingEdited,
    highlightKpi,
    addEvent,
    label,
  ]);
  return {
    isShowingAdvanced,
    onAdvancedToggled,
    formattingOptions,
    formatting,
    currencyType,
    setCurrencyType,
    saveRow,
    label,
    onNameChanged,
    reportDrillDownId,
    setReportDrillDownId,
    trackDrillDownAdded,
    markHasUnsavedChanges,
    targets,
    setTargets,
    setCanSaveKpi,
    setIsTargetsDisabled,
    isTargetsDisabled,
    setProrationOverride,
    setIsColorOrderReversed,
    isColorOrderReversed,
    dynamicTargetOverride,
    isValid,
  };
};

export default useManualForm;
