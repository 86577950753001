import withoutNulls from './search/withoutNulls';
import firebase from 'firebase/compat/app';

const createSavedFilter = async (
  filter: SavedFilter,
  accountRef: firebase.firestore.DocumentReference,
) => {
  await accountRef
    .collection('savedFilters')
    .doc(filter.id)
    .set(withoutNulls(filter));
  return filter.id;
};

export default createSavedFilter;
