import React, { useContext } from 'react';
import NewCommitButton from './NewCommitButton';
import CustomerLaneSlideOutContext from '../../contexts/CustomerLaneSlideOut';
import CommitmentQueryContext from '../../contexts/CommitmentQueryContext';

const getHeadingText = (mode: CommitmentFormPopupMode): string => {
  switch (mode) {
    case 'end':
      return 'End Commitment';
    case 'modify':
      return 'Modify Commitment';
    case 'renew':
      return 'Renew Commitment';
    case 'create':
    default:
      return 'Create Commitment';
  }
};

const NewCommitButtonContainer = () => {
  const { close: closeLaneSummary } = useContext(CustomerLaneSlideOutContext);
  const { mode } = useContext(CommitmentQueryContext);
  const { isPopupOpen, openPopup, closePopup } = useContext(
    CommitmentQueryContext,
  );
  const headingText = getHeadingText(mode);

  const onOpen = () => {
    closeLaneSummary();
    openPopup();
  };

  return (
    <NewCommitButton
      isOpen={isPopupOpen}
      open={onOpen}
      close={closePopup}
      headingText={headingText}
    />
  );
};

export default NewCommitButtonContainer;
