import React from 'react';

interface WidgetGalleryContextType {
  isOpen: boolean;
  open: (createNewChart?: boolean) => void;
  close: () => void;
  searchText: string;
  setSearchText: (newText: string) => void;
  definitions: VisualisationDefinition[];
  selectedDefinitions: string[];
  toggleDefinitionSelected: (defId: string) => void;
  saving: boolean;
  addToReport: () => void;
  definitionsOnDashboard: string[];
  setSelectedTag: (newTag: string | undefined) => void;
  selectedTag: string | undefined;
  isDashboard?: boolean;
  isConfiguringDashboardGadget: boolean;
  startConfigureDashboardGadget: () => void;
  onConfigureSave: (g: DashboardGadget) => Promise<void>;
  selectedChartType?:
    | GadgetType
    | 'SingleMetricDateMatrix'
    | 'RankingMatrix'
    | 'PaceMatrix';
  setSelectedChartType: React.Dispatch<
    React.SetStateAction<
      | GadgetType
      | 'SingleMetricDateMatrix'
      | 'RankingMatrix'
      | 'PaceMatrix'
      | undefined
    >
  >;
  dataTypeOptions: { label: string; value: string }[];
  selectedDataSet?: string;
  onDataTypeSelected: (newSet: string) => void;
  isCreatingNewChart: boolean;
  resetDashboardGadgetEditorRef: React.MutableRefObject<
    (() => void) | undefined
  >;
}

const WidgetGalleryContext = React.createContext<WidgetGalleryContextType>(
  {} as any as WidgetGalleryContextType,
);

export default WidgetGalleryContext;
