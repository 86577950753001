import React from 'react';
import Typography from 'kingpin/atoms/Typography';
import FlexCentered from '../../Common/FlexCentered';
import AddKpiTypesButton from './AddKpiTypesButton';

const EmptyKpis = () => {
  return (
    <FlexCentered style={{ height: 'calc(100% - 36px)', marginTop: 24 }}>
      <div style={{ marginBottom: '16px' }}>
        <Typography.Body type="Body 12">
          Get started by adding the first KPI
        </Typography.Body>
      </div>
      <AddKpiTypesButton />
    </FlexCentered>
  );
};

export default EmptyKpis;
