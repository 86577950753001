import React, { useContext, useEffect } from 'react';

import ProgressBar from './ProgressBar';
import useGoalProgressBar from '../../../../../../hooks/goals/useGoalProgressBar';
import getTimeText from '../../getTimeText';
import getTimeTooltip from '../../getTimeTooltip';
import GeneralGoalContext from '../../../../../../contexts/GeneralGoalContext';

const ProgressBarContainer = ({
  setTimeText,
  setTimeTooltip,
  noPadding,
  setIsAboveTarget,
  isCard,
}: {
  setTimeText?: (text: string) => void;
  setTimeTooltip?: (text: string) => void;
  noPadding?: boolean;
  setIsAboveTarget?: (b: boolean) => void;
  isCard?: boolean;
}) => {
  const { goal } = useContext(GeneralGoalContext);
  const {
    currentValue,
    performanceText,
    aboveTargetIsGood,
    isAboveTarget,
    progressPercent,
    remainingIntervals,
    isLoading,
    dateRange,
    targetValueForNow,
    isAboveTargetForNow,
    remaining,
  } = useGoalProgressBar(goal);

  useEffect(() => {
    if (dateRange && setTimeText && setTimeTooltip) {
      setTimeText(getTimeText(goal, dateRange));
      setTimeTooltip(getTimeTooltip(goal, dateRange));
    }
  }, [goal, dateRange, setTimeText, setTimeTooltip]);

  useEffect(() => {
    if (setIsAboveTarget) {
      setIsAboveTarget(isAboveTargetForNow);
    }
  }, [isAboveTargetForNow, setIsAboveTarget]);

  return (
    <ProgressBar
      currentValue={currentValue}
      performanceText={performanceText}
      aboveTargetIsGood={aboveTargetIsGood}
      isAboveTarget={isAboveTarget}
      progressPercent={progressPercent}
      remainingIntervals={remainingIntervals}
      isLoading={isLoading}
      targetValueForNow={targetValueForNow}
      isAboveTargetForNow={isAboveTargetForNow}
      remaining={remaining}
      noPadding={noPadding}
      isCard={isCard}
    />
  );
};

export default ProgressBarContainer;
