import { useCallback, useContext } from 'react';
import { gql } from '@apollo/client';
import GqlClientContext from '../../contexts/GqlClientContext';
import sanitiseConfig from './sanitiseConfig';
import captureException from '../../services/captureException';
import CurrentUserContext from '../../contexts/CurrentUserContext';

const useGetPerformanceDatasetConfigs = () => {
  const { isWallboardUser } = useContext(CurrentUserContext);
  const { client } = useContext(GqlClientContext);

  const getPerformanceConfigs = useCallback(async (): Promise<
    FleetOps.PerformanceDatasetConfig[]
  > => {
    if (isWallboardUser) {
      return [];
    }

    const query = gql`
      query GetPerformanceDataConfig {
        getPerformanceDataConfig {
          id
          name
          dataType
          entity
          entityDataTypes
          entityWindowLength
          entityWindowDateField
          interval
          intervalLength
          status
          startDate
          startOfWeek
          metrics {
            metricId
            fieldName
            dateField
            datePeriodType
            datePeriodLength
          }
          fields {
            dataType
            fields
          }
          createdOn
          createdBy
          updatedOn
          updatedBy
        }
      }
    `;

    const response = await client.query({ query }).catch((ex) => {
      captureException(ex);
      return {
        data: {
          getPerformanceDataConfig: [],
        },
      };
    });
    const configs = response.data
      .getPerformanceDataConfig as FleetOps.PerformanceDatasetConfig[];
    return configs.map(sanitiseConfig);
  }, [client, isWallboardUser]);

  return getPerformanceConfigs;
};

export default useGetPerformanceDatasetConfigs;
