const ensureNDecimalPlaces = ({
  value,
  precision,
}: {
  value: string;
  precision?: number;
}) => {
  if (precision === undefined || precision === 0) {
    return value;
  }

  const postfix = (() => {
    if (value.includes('.')) {
      const decimals = value.split('.')[1].length;
      if (decimals === precision) {
        return '';
      } else if (decimals >= 1) {
        const required = precision - decimals;
        let x = '';
        for (let i = required; i > 0; i--) {
          x += '0';
        }
        return x;
      } else {
        return '';
      }
    } else {
      let x = '.';
      for (let i = precision; i > 0; i--) {
        x += '0';
      }
      return x;
    }
  })();

  return `${value}${postfix}`;
};

export default ensureNDecimalPlaces;
