import * as Sentry from '@sentry/browser';
import { HttpClient as HttpClientIntegration } from '@sentry/integrations';
import { withoutGraphQLFetch } from 'apollo-link-sentry';
import env from '.env.json';

Sentry.init({
  dsn: 'https://998b8a11f1204d8cb38c5ad38ef411b6@sentry.io/1378793',
  release: process.env.REACT_APP_BUILD_NUMBER,
  environment: env.SENTRY_ENV,
  maxBreadcrumbs: 20,
  normalizeDepth: 10,
  integrations: [new Sentry.BrowserTracing(), new HttpClientIntegration()],
  // for Sentry.BrowserTracing
  tracesSampleRate: 1.0,
  // for Sentry.BrowserTracing
  tracePropagationTargets: ['localhost', /^https:\/\/app\.fleetops\.com/],
  beforeBreadcrumb: withoutGraphQLFetch((breadcrumb) => {
    if (
      breadcrumb.category === 'graphql.query' ||
      breadcrumb.category === 'graphql.mutation'
    ) {
      if (breadcrumb.data) {
        breadcrumb.message = JSON.stringify(breadcrumb.data.variables);
      }
    }

    if (
      breadcrumb.category === 'xhr' &&
      breadcrumb.data &&
      breadcrumb.data.url &&
      breadcrumb.data.url.includes('firestore.googleapis')
    ) {
      return null;
    }

    if (breadcrumb.category && breadcrumb.category.includes('ui.')) {
      return null;
    }

    if (Object.keys(breadcrumb).length === 1) {
      // Remove "Generic" events with no meaningful info
      return null;
    }

    return breadcrumb;
  }),
  beforeSend(event, hint) {
    if (window.location.hostname === 'localhost') {
      return null;
    }

    if (
      hint &&
      hint.originalException &&
      // @ts-ignore
      hint.originalException.graphQLErrors &&
      // @ts-ignore
      hint.originalException.graphQLErrors.length > 0
    ) {
      // @ts-ignore
      const { name, message } = hint.originalException.graphQLErrors[0];
      if (
        name &&
        event.exception &&
        event.exception.values &&
        Array.isArray(event.exception.values) &&
        event.exception.values.length > 0
      ) {
        event.exception.values[0].type = name;
      }
      event.fingerprint = [name, message];
      return event;
    }

    if (
      hint &&
      hint.originalException &&
      // @ts-ignore
      hint.originalException.networkError &&
      // @ts-ignore
      hint.originalException.networkError.name
    ) {
      // @ts-ignore
      const { name, message } = hint.originalException.networkError;
      if (
        name &&
        event.exception &&
        event.exception.values &&
        Array.isArray(event.exception.values) &&
        event.exception.values.length > 0
      ) {
        event.exception.values[0].type = name;
      }
      event.fingerprint = [name, message];
      return event;
    }

    return event;
  },
});
