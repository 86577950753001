import React, { useCallback, useContext, useEffect, useState } from 'react';
import CloseButton from '../../../../../../../components/CloseButton';
import PerformanceWizardContext from '../../PerformanceWizardContext';
import { useGetDataTypesFromMetricId } from '../../../../../../../hooks/useDataTypesFromSeriesAndMetricListItems';
import isDefined from '../../../../../../../isDefined';
import useConfirmation from '../../useConfirmation';
import useGetFieldLabel from 'hooks/useGetFieldLabel';
import useGetDatasetLabel from '../../../../../../../components/Inputs/MetricPicker/MetricPickerPopup/hooks/useGetDatasetLabel';

const useDependantKeywords = (
  performanceMetric: PerformanceConfiguration.Client.PerformanceMetric,
) => {
  const getDataTypesFromMetricId = useGetDataTypesFromMetricId();
  const { wizardState } = useContext(PerformanceWizardContext);
  const [dependantKeywords, setDependantKeywords] = useState<
    PerformanceConfiguration.PerformanceKeyword[]
  >([]);
  const { getFieldLabel } = useGetFieldLabel();
  const getDatasetLabel = useGetDatasetLabel();
  const [warningMessageList, setWarningMessageList] = useState<string>('');

  // setDependantKeywords
  useEffect(() => {
    const dataTypesFromOtherMetrics = wizardState.config.metricsStep.metrics
      .map((m) => m.metricId)
      .filter((mid) => performanceMetric.metricId !== mid)
      .map(getDataTypesFromMetricId)
      .filter(isDefined)
      .reduce((a, b) => [...a, ...b], [] as string[]);

    setDependantKeywords(
      wizardState.config.fieldsStep.fields.filter((keyword) => {
        return !dataTypesFromOtherMetrics.includes(keyword.dataType);
      }),
    );
  }, [
    getDataTypesFromMetricId,
    performanceMetric.metricId,
    wizardState.config.fieldsStep.fields,
    wizardState.config.metricsStep.metrics,
  ]);

  // setWarningMessageList
  useEffect(() => {
    if (dependantKeywords.length === 0) {
      setWarningMessageList('');
      return;
    }

    let msg = '';
    dependantKeywords.forEach(({ fields, dataType }) => {
      fields.forEach((field) => {
        msg += `${getDatasetLabel(dataType)}: ${getFieldLabel({
          field,
          dataType,
          isVerbose: true,
        })}\n`;
      });
    });
    setWarningMessageList(msg);
  }, [dependantKeywords, getDatasetLabel, getFieldLabel]);

  return {
    dependantKeywords,
    warningMessageList,
  };
};

const RemoveButton = ({
  performanceMetric,
}: {
  performanceMetric: PerformanceConfiguration.Client.PerformanceMetric;
}) => {
  const { setWizardState } = useContext(PerformanceWizardContext);
  const { dependantKeywords, warningMessageList } =
    useDependantKeywords(performanceMetric);
  const { getConfirmation, ConfirmationModal } = useConfirmation({
    confirmText: 'Remove',
    title: 'Remove Metric and dependant Keyword Fields?',
    body: `By removing this metric, you will also be removing the following fields:\n${warningMessageList}\nAre you sure?`,
  });

  const onRemoveClicked = useCallback(async () => {
    if (dependantKeywords.length > 0) {
      const confirmation = await getConfirmation();
      if (!confirmation) {
        return;
      }

      setWizardState((s) => ({
        ...s,
        config: {
          ...s.config,
          metricsStep: {
            ...s.config.metricsStep,
            metrics: s.config.metricsStep.metrics.filter(
              (m) => m.key !== performanceMetric.key,
            ),
          },
          fieldsStep: {
            ...s.config.fieldsStep,
            fields: s.config.fieldsStep.fields.filter(({ dataType }) => {
              if (dependantKeywords.some((d) => d.dataType === dataType)) {
                return false;
              }
              return true;
            }),
          },
        },
      }));
    } else {
      setWizardState((s) => ({
        ...s,
        config: {
          ...s.config,
          metricsStep: {
            ...s.config.metricsStep,
            metrics: s.config.metricsStep.metrics.filter(
              (m) => m.key !== performanceMetric.key,
            ),
          },
        },
      }));
    }
  }, [
    dependantKeywords,
    getConfirmation,
    performanceMetric.key,
    setWizardState,
  ]);

  return (
    <>
      <CloseButton
        isNarrow
        close={onRemoveClicked}
        testId={`remove-${performanceMetric.metricId}`}
      />
      {ConfirmationModal}
    </>
  );
};

export default RemoveButton;
