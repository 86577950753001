import { useCallback } from 'react';
import getIdentifier from '../../../getIdentifier';
import useGetInitialCategories from './useGetInitialCategories';

const useGetInitialGroups = () => {
  const getInitialCategories = useGetInitialCategories();

  const getInitialGroups = useCallback(
    (isTargetedByGroup: boolean): Targets.Wizard.TargetGroup[] => {
      const categories = getInitialCategories();
      if (isTargetedByGroup) {
        return [
          {
            key: getIdentifier(undefined, true),
            groupName: [],
            isFallback: false,
            targets: [
              {
                isFallback: true,
                key: getIdentifier(undefined, true),
                categories,
                rules: [],
              },
            ],
            colorIndex: 0,
          },
          {
            key: getIdentifier(undefined, true),
            groupName: ['*'],
            isFallback: true,
            targets: [
              {
                isFallback: true,
                key: getIdentifier(undefined, true),
                categories: [],
                rules: [],
              },
            ],
            colorIndex: 0,
          },
        ];
      } else {
        return [
          {
            key: getIdentifier(undefined, true),
            groupName: ['*'],
            isFallback: false,
            targets: [
              {
                isFallback: true,
                key: getIdentifier(undefined, true),
                categories,
                rules: [],
              },
            ],
            colorIndex: 0,
          },
        ];
      }
    },
    [getInitialCategories],
  );

  return getInitialGroups;
};

export default useGetInitialGroups;
