import React, { useCallback } from 'react';
import styled from 'styled-components';
import { IHeaderParams, IHeaderGroupParams } from 'ag-grid-community';
import Cell from './V5Gadget/Matrix/Cell';

interface HeaderParams extends IHeaderParams {
  metric: Metrics.Metric;
  rightAlign: boolean;
  alias?: string;
  sort?: 'asc' | 'desc';
}

interface GroupHeaderParams extends IHeaderGroupParams {
  metric: Metrics.Metric;
  rightAlign: boolean;
  alias?: string;
}

const Wrapper = styled.div<{ rightAlign?: boolean; centerAlign: boolean }>`
  overflow: unset !important;
  text-overflow: clip !important;
  white-space: normal !important;
  cursor: pointer;
  flex: 1;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: ${(props) =>
    props.centerAlign
      ? 'center'
      : props.rightAlign
        ? 'flex-end'
        : 'flex-start'};
  align-items: center;
`;

const isHeaderParams = (
  params: HeaderParams | GroupHeaderParams,
): params is HeaderParams => {
  // @ts-ignore
  return params.progressSort !== undefined;
};

const MetricHeaderCell = (props: HeaderParams | GroupHeaderParams) => {
  const { metric, rightAlign, alias } = props;
  const sort = isHeaderParams(props) ? props.column.getSort() : undefined;
  const onSort = useCallback(() => {
    if (isHeaderParams(props)) {
      const nextSort = (() => {
        if (!sort) {
          return 'desc';
        } else if (sort === 'desc') {
          return 'asc';
        } else {
          return null;
        }
      })();

      props.column.setSort(nextSort, 'uiColumnSorted');
      props.setSort(nextSort);
    }
  }, [props, sort]);

  return (
    <Cell
      onClick={isHeaderParams(props) ? onSort : undefined}
      disableLink
      rightAlign={rightAlign}
    >
      <Wrapper rightAlign={rightAlign} centerAlign={!isHeaderParams(props)}>
        {rightAlign && <SortArrow sort={sort} />}
        <span>{alias ? alias : metric.name}</span>
        {!rightAlign && <SortArrow sort={sort} />}
      </Wrapper>
    </Cell>
  );
};

const SortArrow = ({ sort }: { sort?: 'asc' | 'desc' | null }) => (
  <>
    {sort && sort === 'asc' && (
      <span
        style={{ marginRight: 4, minWidth: 15 }}
        className="ag-header-icon ag-sort-ascending-icon"
        aria-hidden="true"
      >
        <span className="ag-icon ag-icon-asc" unselectable="on" />
      </span>
    )}
    {sort && sort === 'desc' && (
      <span
        style={{ marginLeft: 4, minWidth: 15 }}
        className="ag-header-icon ag-sort-descending-icon"
        aria-hidden="true"
      >
        <span className="ag-icon ag-icon-desc" unselectable="on" />
      </span>
    )}
  </>
);

export default MetricHeaderCell;
