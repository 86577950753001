import React, { ChangeEvent } from 'react';

import ListItemWrapper from '../../ListItemWrapper';
import Column from '../../Column';
import Typography from 'kingpin/atoms/Typography';
import Inputs from '../../../../../components/Inputs';
import toSentenceCase from '../../../../../services/toSentenceCase';
import DragHandle from '../../../../../components/DragAndDropList/DragHandle';

const FieldListItem = ({
  isSelected,
  currentName,
  field,
  fieldView,
  onDisplayNameChanged,
  onShowInGridChanged,
  onDescriptionChanged,
  canDrag,
  onSelected,
}: {
  isSelected: boolean;
  currentName?: string;
  field: FleetOps.Field;
  fieldView: FleetOps.BaseViewField;
  onDisplayNameChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  onShowInGridChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  onDescriptionChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  canDrag: boolean;
  onSelected: () => void;
}) => (
  <ListItemWrapper isSelected={isSelected}>
    <Column style={{ flex: 2 }}>
      <Typography.Body type="Link" onClick={onSelected}>
        {field.field}
      </Typography.Body>
    </Column>
    <Column style={{ flex: 2 }}>
      <Inputs.TextInput
        data-testid={`${field.field}-name`}
        value={currentName || ''}
        onChange={onDisplayNameChanged}
        placeholder="Add a display name..."
      />
    </Column>
    <Column style={{ flex: 2 }}>
      <Inputs.TextInput
        data-testid={`${field.field}-description`}
        style={{ marginTop: 9, marginBottom: 9 }}
        value={fieldView.description || ''}
        onChange={onDescriptionChanged}
        placeholder={'Add a description...'}
      />
    </Column>
    <Column style={{ flex: 2 }}>
      <Typography.Body type="Body 12">
        {toSentenceCase(field.type)}
      </Typography.Body>
    </Column>
    <Column style={{ flex: 2 }}>
      <Typography.Body type="Body 12">
        {fieldView.cellType || ''}{' '}
        {fieldView.cellType === 'Currency'
          ? `(${fieldView.formatting.currencyType || 'USD'})`
          : ''}
      </Typography.Body>
    </Column>
    <Column style={{ flex: 1 }}>
      <Inputs.TextInput
        data-testid={`${field.field}-visible`}
        style={{ width: 13 }}
        type="checkbox"
        checked={fieldView.isVisible}
        value=""
        onChange={onShowInGridChanged}
      />
    </Column>
    <Column style={{ justifyContent: 'flex-end' }}>
      {canDrag && <DragHandle testId={`${field.field}-drag`} />}
    </Column>
  </ListItemWrapper>
);

export default FieldListItem;
