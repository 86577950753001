import React, { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import CostsWizardProvider from '../providers/CostsWizardProvider';
import GqlClientContext from 'contexts/GqlClientContext';
import CostsShowContext from '../context/CostsShowContext';
import CostsWizardContext from '../context/CostsWizardContext';
import AnalyticsContext from 'contexts/AnalyticsContext';
import useStateTransformers from './hooks/useStateTransformers';
import useConfirmation from 'screens/DataManager/DatasetDefinitions/DatasetsIndex/PerformanceDatasetWizard/useConfirmation';
import updateCostModel from '../api/updateCostModel';
import createCostModel from '../api/createCostModel';
import Colors2 from 'theme/Colors2';
import Button from 'kingpin/atoms/Button';
import Row from 'components/Common/Row';
import ConfigSteps from './ConfigSteps';
import Loading from 'components/Loading/Loading';
import COSTS_SET_ROUTES from '../../CostsSet/routes';
import { PortalsContext } from '../../../../contextProviders/PortalsProvider';

const ActivitySettingsCreation = () => {
  const {
    wizardState,
    isEditing,
    persistedWizardState,
    defaultWizardState,
    setWizardState,
    hasEditPermission,
  } = useContext(CostsWizardContext);
  const { refreshModel, refreshCosts, costModel, intervalCosts } =
    useContext(CostsShowContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const { selectedPortal } = useContext(PortalsContext);
  const { client } = useContext(GqlClientContext);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const navigate = useNavigate();
  const { toCreate, toUpdate } = useStateTransformers();
  const { getConfirmation, ConfirmationModal } = useConfirmation({
    title: 'Changed fields will erase all costs.',
    confirmText: 'Update anyway',
  });

  const saveCostModelCallback = useCallback(async () => {
    if (isSaving || !wizardState.isValid || !hasEditPermission) {
      return;
    }
    setIsSaving(true);
    const createArgs = toCreate(wizardState);

    if (isEditing) {
      const updateArgs = toUpdate(wizardState);

      await updateCostModel({
        updateArgs,
        client,
      });
    } else {
      await createCostModel({
        createArgs,
        client,
      });
    }

    refreshModel().then(() => {
      refreshCosts().then(() => {
        setIsSaving(false);

        trackEvent('Costs - Settings saved', {
          costModel: costModel?.modelType,
        });
        navigate(COSTS_SET_ROUTES.buildActivityCostsBase(selectedPortal));
      });
    });
  }, [
    client,
    costModel?.modelType,
    hasEditPermission,
    isEditing,
    isSaving,
    navigate,
    refreshCosts,
    refreshModel,
    selectedPortal,
    toCreate,
    toUpdate,
    trackEvent,
    wizardState,
  ]);

  const handleSaveCostModel = useCallback(async () => {
    if (
      isEditing &&
      wizardState.isDraft &&
      intervalCosts?.length !== 0 &&
      wizardState.hasBreakingChanges
    ) {
      const isConfirmed = await getConfirmation();
      if (!isConfirmed) {
        return;
      }
      saveCostModelCallback();
    } else {
      saveCostModelCallback();
    }
  }, [
    getConfirmation,
    intervalCosts?.length,
    isEditing,
    saveCostModelCallback,
    wizardState.hasBreakingChanges,
    wizardState.isDraft,
  ]);

  const discardChanges = useCallback(() => {
    setWizardState({
      ...(persistedWizardState || defaultWizardState),
      currentStep: wizardState.currentStep,
    });

    trackEvent('Costs - Settings - Changes discarded', {
      costModel: costModel?.modelType,
    });
  }, [
    costModel?.modelType,
    defaultWizardState,
    persistedWizardState,
    setWizardState,
    trackEvent,
    wizardState.currentStep,
  ]);

  const getIsDisabled = useCallback(() => {
    if (persistedWizardState) {
      return _.isEqual(toUpdate(persistedWizardState), toUpdate(wizardState));
    } else {
      if (
        wizardState.config.basicStep.field &&
        wizardState.config.basicStep.startDate &&
        defaultWizardState.config.basicStep.field &&
        defaultWizardState.config.basicStep.startDate
      ) {
        return _.isEqual(toCreate(defaultWizardState), toCreate(wizardState));
      } else {
        return true;
      }
    }
  }, [
    defaultWizardState,
    persistedWizardState,
    toCreate,
    toUpdate,
    wizardState,
  ]);

  return (
    <div
      style={{
        width: '438px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRight: `1px solid ${Colors2.Grey['8']}`,
        background: '#ffffff',
      }}
    >
      <div style={{ flex: 1 }}>
        <ConfigSteps />
      </div>
      <Row
        centerAlign
        spaceBetween
        style={{
          height: '53px',
          borderTop: `1px solid ${Colors2.Grey['8']}`,
          padding: '0 24px',
        }}
      >
        <div style={{ display: 'inline-block' }}>
          <Button
            type="Tertiary"
            size="Small"
            onClick={discardChanges}
            label="Discard Changes"
            isDisabled={getIsDisabled()}
          />
        </div>
        <div style={{ display: 'inline-block' }}>
          <Button
            type="Primary"
            size="Small"
            label={'Save & Close'}
            isDisabled={
              !wizardState.isValid || !hasEditPermission || getIsDisabled()
            }
            onClick={handleSaveCostModel}
          />
        </div>
      </Row>
      {ConfirmationModal}
    </div>
  );
};

const Gate = () => {
  const {
    costModel: initialState,
    isLoadingCosts,
    isLoadingModel,
  } = useContext(CostsShowContext);

  if (isLoadingCosts || isLoadingModel) {
    return <Loading />;
  }

  return (
    <CostsWizardProvider initialState={initialState}>
      <ActivitySettingsCreation />
    </CostsWizardProvider>
  );
};

export default Gate;
