import { useCallback, useContext, useEffect, useState } from 'react';
import TargetFormContext from '../contexts/TargetFormContext';
import getIdentifier from '../../../getIdentifier';
import AnalyticsContext from '../../../contexts/AnalyticsContext';

const useGroupNameOptions = (group: Targets.Wizard.TargetGroup) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const { setGroups, groups, groupNameValues } = useContext(TargetFormContext);
  const [options, setOptions] = useState<ExclusiveDropdownOption[]>([]);

  const getIsUsedElsewhere = useCallback(
    (value: string): undefined | { reason: string; tooltip: string } => {
      const usedInIndex = groups.findIndex(
        (g) =>
          g.groupName && g.groupName.includes(value) && g.key !== group.key,
      );
      if (usedInIndex === -1) {
        return undefined;
      }

      // Defensive check
      const usedInGroups = groups.filter(
        (g) => g.groupName && g.groupName.includes(value),
      );
      if (usedInGroups.length > 1) {
        const e = new Error();
        e.name = 'Corrupted state: groupName used in multiple target rules';
        throw e;
      }

      const ruleNo = usedInIndex + 1;
      const reason = `(Rule ${ruleNo})`;
      const tooltip = `Selecting '${value}' will remove it from Rule ${ruleNo}`;
      return {
        reason,
        tooltip,
      };
    },
    [group.key, groups],
  );

  useEffect(() => {
    const { groupName } = group;
    const newOptions = groupNameValues.map((value) => {
      const isSelected = !!groupName && groupName.includes(value);
      return {
        key: getIdentifier(undefined, true),
        label: value,
        isSelected,
        isInUseElsewhere: getIsUsedElsewhere(value),
        onSelected: () => {
          if (!isSelected) {
            trackEvent('Targets - Rule - Dimension Selected');
          }
          setGroups((currentGroups) => {
            return currentGroups.map((g) => {
              if (g.key === group.key) {
                const newGroupName =
                  g.groupName === undefined
                    ? [value]
                    : isSelected
                      ? g.groupName.filter((gn) => gn !== value)
                      : [...g.groupName, value];
                return {
                  ...g,
                  groupName: newGroupName,
                };
              } else {
                const newGroupName =
                  g.groupName === undefined
                    ? undefined
                    : g.groupName.filter((gn) => gn !== value);
                return {
                  ...g,
                  groupName: newGroupName,
                };
              }
            });
          });
        },
      };
    });

    setOptions(newOptions);
  }, [getIsUsedElsewhere, group, groupNameValues, setGroups, trackEvent]);

  return options;
};

export default useGroupNameOptions;
