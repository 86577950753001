import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import RightArrowSrc from '../images/right-arrow.png';
import useModeOptions from '../hooks/useModeOptions';
import useGroupFieldOptions from '../hooks/useGroupFieldOptions';
import Row from '../../Common/Row';
import Typography from 'kingpin/atoms/Typography';
import Inputs from '../../../components/Inputs';
import TargetFormContext from '../contexts/TargetFormContext';
import useGetForcedGroupField from '../hooks/useGetForcedGroupField';
import useDangerConfirmation from '../../ConfirmationModals/hooks/useDangerConfirmation';
import { ModalTransition } from '@atlaskit/modal-dialog';
import TargetListContext from '../contexts/TargetListContext';
import useGetFieldLabel from '../../../hooks/useGetFieldLabel';
import deleteTargetsMutation from '../api/deleteTargets';
import GqlClientContext from '../../../contexts/GqlClientContext';

const RightArrowImg = styled.img`
  margin-left: 16px;
  margin-right: 16px;
`;

const GroupPicker = ({
  dataType,
  groupField,
  setGroupField,
  isDisabled,
  getConfirmation,
}: {
  dataType: string;
  groupField?: string;
  setGroupField: React.Dispatch<React.SetStateAction<string | undefined>>;
  isDisabled: boolean;
  getConfirmation: () => Promise<boolean>;
}) => {
  const { isShowingValidationFeedback } = useContext(TargetFormContext);
  const groupFieldOptions = useGroupFieldOptions({
    dataType,
    setGroupField,
    groupField,
    getConfirmation,
  });

  return (
    <Row centerAlign>
      <RightArrowImg src={RightArrowSrc} />
      <Inputs.Dropdown
        options={groupFieldOptions}
        placeholder="Select Group"
        isSearchEnabled
        isDisabled={isDisabled}
        hasError={isShowingValidationFeedback && groupField === undefined}
      />
    </Row>
  );
};

const useGroupChangeConfirmation = () => {
  const { client } = useContext(GqlClientContext);
  const [hasConfirmed, setHasConfirmed] = useState<boolean>(false);
  const { getConfirmationThat, DangerConfirmation } = useDangerConfirmation({
    title: 'Targets will be deleted',
    checkboxText: 'I understand that my current targets will be deleted',
  });
  const { targetsForField, refreshTargets } = useContext(TargetListContext);
  const { targetField, dataType } = useContext(TargetFormContext);
  const { getFieldLabel } = useGetFieldLabel();
  const getConfirmation = useCallback(() => {
    if (targetsForField.length !== 1 || hasConfirmed) {
      return Promise.resolve(true);
    }
    return getConfirmationThat(
      `Changing how you set targets will delete all targets on ${getFieldLabel({
        field: targetField,
        dataType,
      })}`,
    ).then((result) => {
      if (result) {
        return deleteTargetsMutation({
          client,
          id: targetsForField[0].id,
        }).then(() => {
          return refreshTargets().then(() => {
            setHasConfirmed(result);
            return result;
          });
        });
      } else {
        setHasConfirmed(result);
        return result;
      }
    });
  }, [
    client,
    dataType,
    getConfirmationThat,
    getFieldLabel,
    hasConfirmed,
    refreshTargets,
    targetField,
    targetsForField,
  ]);

  return {
    DangerConfirmation,
    getConfirmation,
  };
};

const GroupFieldInput = ({
  dataType,
  groupField,
  setGroupField,
}: {
  dataType: string;
  groupField?: string;
  setGroupField: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
  const { getConfirmation, DangerConfirmation } = useGroupChangeConfirmation();
  const { targetField, isShowingValidationFeedback } =
    useContext(TargetFormContext);
  const getForcedGroupField = useGetForcedGroupField();

  const { modeOptions, mode } = useModeOptions({
    dataType,
    groupField,
    setGroupField,
    getConfirmation,
  });
  const isDisabled = getForcedGroupField(targetField) !== undefined;

  return (
    <Row centerAlign>
      <div style={{ marginRight: 16 }}>
        <Typography.Body type="Body 12">Set Targets On</Typography.Body>
      </div>
      <Inputs.Dropdown
        unsetWidth={mode !== undefined}
        placeholder="Select Option"
        options={modeOptions}
        isDisabled={isDisabled}
        testId={`mode-dropdown-disabled:${isDisabled}`}
        hasError={isShowingValidationFeedback && mode === undefined}
      />
      {mode === 'By Group' && (
        <GroupPicker
          dataType={dataType}
          groupField={groupField}
          setGroupField={setGroupField}
          isDisabled={isDisabled}
          getConfirmation={getConfirmation}
        />
      )}
      <ModalTransition>{DangerConfirmation}</ModalTransition>
    </Row>
  );
};

export default GroupFieldInput;
