import firebase from 'firebase/compat/app';
import STORE_CONSTANTS from '../constants';
import withoutNulls from '../../api/search/withoutNulls';

export const SCORECARDS_DOC = 'scorecards';
export const CURRENT_SCORECARDS_VERSION = 'v3';

export const scorecardsConverter = {
  toFirestore(
    scorecard: Scorecards.Scorecard,
  ): firebase.firestore.DocumentData {
    return withoutNulls({ ...scorecard });
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ): Scorecards.Scorecard {
    const data = snapshot.data(options);
    return data as Scorecards.Scorecard;
  },
};

const getScorecardsRef = ({
  accountId,
  db,
}: {
  accountId: string;
  db?: firebase.firestore.Firestore;
}) => {
  return (db ? db : firebase.firestore())
    .collection('accounts')
    .doc(accountId)
    .collection(STORE_CONSTANTS.VISUALISATIONS_COLLECTION)
    .doc(SCORECARDS_DOC)
    .collection(CURRENT_SCORECARDS_VERSION)
    .withConverter(scorecardsConverter);
};

export default getScorecardsRef;
