import React, { useContext, useEffect, useState } from 'react';
import MetricOptionsContext from '../contexts/MetricOptionsContext';
import useMetricOptions from '../hooks/useMetricOptions';
import DatasetDefinitionsContext from '../contexts/DatasetDefinitionsContext';

const MetricOptionsProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { datasets, isLoading: isLoadingDatasets } = useContext(
    DatasetDefinitionsContext,
  );
  const {
    metricOptions,
    metricOptionsLookup,
    metricOptionsNoSpecials,
    normalMetrics,
    datasetMetricOptions,
  } = useMetricOptions();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const hasNoOptions =
      metricOptions.length === 0 ||
      Object.keys(metricOptionsLookup).length === 0;
    if (!hasNoOptions) {
      setIsLoading(false);
    } else if (!isLoadingDatasets && datasets.length === 0) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [
    datasets.length,
    isLoadingDatasets,
    metricOptions.length,
    metricOptionsLookup,
  ]);

  return (
    <MetricOptionsContext.Provider
      value={{
        metricOptions,
        metricOptionsLookup,
        metricOptionsNoSpecials,
        normalMetrics,
        datasetMetricOptions,
        isLoading,
      }}
    >
      {children}
    </MetricOptionsContext.Provider>
  );
};

export default MetricOptionsProvider;
