import React from 'react';
import TargetList from './TargetList';
import useTargetBuckets from '../hooks/useTargetBuckets';

const TargetListContainer = ({
  selectedTarget,
  onAddTargetClicked,
  isCreatingNewTarget,
}: {
  selectedTarget?: Targets.Wizard.DataTypeTarget;
  onAddTargetClicked: () => void;
  isCreatingNewTarget: boolean;
}) => {
  const { pastTargets, futureTargets, currentTarget } = useTargetBuckets();

  return (
    <TargetList
      pastTargets={pastTargets}
      futureTargets={futureTargets}
      currentTarget={currentTarget}
      selectedTarget={selectedTarget}
      isCreatingNewTarget={isCreatingNewTarget}
      onAddTargetClicked={onAddTargetClicked}
    />
  );
};

export default TargetListContainer;
