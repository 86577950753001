import styled from 'styled-components';
import PropTypes from 'prop-types';

const Content = styled.div`
  width: 100%;
  height: ${(props) =>
    props.heightOffset ? `calc(100% - ${props.heightOffset})` : '100%'};
  display: flex;
  flex-direction: ${(props) => (props.row ? 'row' : 'column')};
  background-color: ${(props) => props.backgroundColor};
  overflow: hidden;
`;
Content.propTypes = {
  heightOffset: PropTypes.string,
};
Content.defaultProps = {
  row: true,
  backgroundColor: 'white',
};

export default Content;
