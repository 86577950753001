import addedAsCollaboratorOfGoal from './addedAsCollaboratorOfGoal';
import assignedToTask from './assignedToTask';
import bonusPeriodClosed from './bonusPeriodClosed';
import bonusPeriodReadyForSignOff from './bonusPeriodReadyForSignOff';
import madeAccountableForGoal from './madeAccountableForGoal';
import unAssignedFromTask from './unAssignedFromTask';
import mentionedInComment from './mentionedInComment';
import assignedToScorecard from './assignedToScorecard';
import newComment from './newComment';
import contentShared from './contentShared';

const NotificationFactory = {
  addedAsCollaboratorOfGoal,
  assignedToScorecard,
  assignedToTask,
  bonusPeriodClosed,
  bonusPeriodReadyForSignOff,
  madeAccountableForGoal,
  mentionedInComment,
  unAssignedFromTask,
  newComment,
  contentShared,
};

export default NotificationFactory;
