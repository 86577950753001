import React from 'react';
import Typography from 'kingpin/atoms/Typography';
import PlateStyles from './PlateStyles';

const BooleanFilterPlate = ({ filter }: { filter: BooleanInput }) => {
  return (
    <div className="filter-plate">
      <PlateStyles.Plate>
        <PlateStyles.LeftPlate>
          <Typography.Body type="Body 12">{filter.field}</Typography.Body>
        </PlateStyles.LeftPlate>
        <PlateStyles.MiddlePlate>
          <Typography.Body type="Body 12">is</Typography.Body>
        </PlateStyles.MiddlePlate>
        <PlateStyles.RightPlate>
          <Typography.Body type="Body 12">
            {filter.value ? 'True' : 'False'}
          </Typography.Body>
        </PlateStyles.RightPlate>
      </PlateStyles.Plate>
    </div>
  );
};

export default BooleanFilterPlate;
