import { useCallback, useContext } from 'react';
import metricTypeCheckers from '../../../../../types/metricTypeCheckers';
import isDefined from '../../../../../isDefined';
import CompoundMetricsContext from '../../../../../contexts/CompoundMetricsContext';
import DatasetDefinitionsContext from '../../../../../contexts/DatasetDefinitionsContext';
const _ = require('lodash');

const useGetMetricDatasets = () => {
  const { compoundMetricInputsLookup } = useContext(CompoundMetricsContext);
  const { datasets: datasetDefinitions } = useContext(
    DatasetDefinitionsContext,
  );

  const getMetricDatasets = useCallback(
    (metric: Metrics.Metric) => {
      if (metricTypeCheckers.isCompoundMetric(metric)) {
        const lookup = compoundMetricInputsLookup[metric.id];
        if (!lookup) {
          return [];
        }

        const usedDs = _.uniqBy(
          lookup
            .map((m) => {
              return datasetDefinitions.find((d) => d.type === m.dataType);
            })
            .filter(isDefined),
          'type',
        ) as FleetOps.DatasetDefinition[];
        return usedDs;
      }

      const ds = datasetDefinitions.find((d) => d.type === metric.dataType);
      return ds ? [ds] : [];
    },
    [compoundMetricInputsLookup, datasetDefinitions],
  );

  return getMetricDatasets;
};

export default useGetMetricDatasets;
