import firebase from 'firebase/compat/app';
import { REPORTS_COLLECTION } from '../constants';

const deleteReport = async (
  id: string,
  accountRef: firebase.firestore.DocumentReference,
) => {
  return accountRef.collection(REPORTS_COLLECTION).doc(id).delete();
};

export default deleteReport;
