import PERMISSIONS from '../../permissionDefinitions';

const getPermissionLabel = (p: FleetOps.Permission) => {
  switch (p) {
    case PERMISSIONS.VIEW_DATA.VIEW_REVENUE:
      return 'Revenue';
    case PERMISSIONS.VIEW_DATA.VIEW_ACCIDENTS:
      return 'Accidents';
    case PERMISSIONS.VIEW_DATA.VIEW_DRIVER_PAY:
      return 'Driver Pay';
    case PERMISSIONS.BILLING.VIEW_BILLING:
      return 'Manage Billing';
    case PERMISSIONS.BILLING.EDIT_BILLING:
      return 'Manage Billing';
    case PERMISSIONS.DATA_MANAGEMENT.INTEGRATIONS_CONFIG_VIEW:
      return 'View Connectors';
    case PERMISSIONS.DATA_MANAGEMENT.INTEGRATIONS_CONFIG_MODIFY:
      return 'Manage Connectors';
    case PERMISSIONS.USER_MANAGEMENT.CREATE_WALLBOARD:
      return 'Manage Wallboards';
    case PERMISSIONS.SCORECARDS.MANUAL_KPI_ENTRY:
      return 'Manual KPI Entry';
    default:
      // eslint-disable-next-line no-case-declarations
      const error = new Error(p);
      error.name = 'Unexpected Permission';
      throw error;
  }
};

export default getPermissionLabel;
