import React from 'react';

import { buildShowPerformanceAppTab } from '../../../navigation/appRoutes';
import Row from 'components/Common/Row';
import { NabTabLinkMargin, NavTabLink } from 'components/NavTab';
import Colors2 from 'theme/Colors2';
import { TOP_BAR_HEIGHT, Z_INDEX } from '../../../constants';
import Button from '../../../kingpin/atoms/Button';

const ShowTopBarTabs = ({
  tabs,
  workSpaceId,
  isBonusTabAvailable,
  moveTabs,
  isLeftVisible,
  isRightVisible,
  scrollerRef,
  contentRef,
  isTaskTabHighlighted,
  taskTabRef,
}: {
  tabs: EnrichedWorkSpaceTab[];
  workSpaceId: string;
  isBonusTabAvailable: boolean;
  moveTabs: ({ right }: { right: boolean }) => void;
  isLeftVisible: boolean;
  isRightVisible: boolean;
  scrollerRef: React.RefObject<HTMLDivElement> | null;
  contentRef: React.RefObject<HTMLDivElement> | null;
  isTaskTabHighlighted: boolean;
  taskTabRef: React.MutableRefObject<HTMLDivElement | null>;
}) => (
  <Row
    ref={scrollerRef}
    style={{
      width: '100%',
      overflow: 'hidden',
      position: 'relative',
    }}
  >
    {isLeftVisible && (
      <Row
        style={{
          height: TOP_BAR_HEIGHT - 1,
          position: 'sticky',
          left: '0px',
          zIndex: Z_INDEX.TABS_ROW,
          pointerEvents: 'none',
        }}
      >
        <div
          style={{
            width: '120px',
            height: TOP_BAR_HEIGHT - 1,
            background: 'linear-gradient(to left, transparent, #fff)',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            pointerEvents: 'none',
          }}
        >
          <div style={{ pointerEvents: 'auto' }}>
            <Button
              size={'Medium'}
              type={'Ghost'}
              icon={'chevron-left'}
              onClick={() => {
                moveTabs({ right: false });
              }}
            />
          </div>
        </div>
      </Row>
    )}

    <Row ref={contentRef}>
      {isBonusTabAvailable && (
        <div style={{ marginRight: NabTabLinkMargin }}>
          <NavTabLink
            title={'Summary'}
            to={buildShowPerformanceAppTab({
              workSpaceId,
              tabType: 'bonusSummary',
              tabId: '1',
            })}
          />
        </div>
      )}
      {tabs.map((tab) => (
        <div
          ref={tab.type === 'tasks' ? taskTabRef : undefined}
          style={{
            backgroundColor:
              tab.type === 'tasks' && isTaskTabHighlighted
                ? Colors2.AvatarColors[2].background
                : undefined,
            marginRight: NabTabLinkMargin,
          }}
          key={tab.typeId}
        >
          <NavTabLink
            id={tab.typeId}
            title={tab.name}
            to={tab.link}
            tabInfo={{
              type: tab.type,
              typeId: tab.typeId,
            }}
          />
        </div>
      ))}
    </Row>

    {isRightVisible && (
      <Row
        style={{
          height: TOP_BAR_HEIGHT - 1,
          position: 'sticky',
          right: '0px',
          pointerEvents: 'none',
          zIndex: Z_INDEX.TABS_ROW,
        }}
      >
        <div
          style={{
            width: '120px',
            height: TOP_BAR_HEIGHT - 1,
            background: 'linear-gradient(to right, transparent, #fff)',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            pointerEvents: 'none',
          }}
        >
          <div
            style={{
              pointerEvents: 'auto',
            }}
          >
            <Button
              size={'Medium'}
              type={'Ghost'}
              icon={'chevron-right'}
              onClick={() => {
                moveTabs({ right: true });
              }}
            />
          </div>
        </div>
      </Row>
    )}
  </Row>
);

export default ShowTopBarTabs;
