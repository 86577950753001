import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CurrentUserContext from 'contexts/CurrentUserContext';
import EntityDefinitionsContext from 'contexts/EntityDefinitionsContext';
import useGetFieldLabel from 'hooks/useGetFieldLabel';
import AccountPickerContext from 'contexts/AccountPickerContext';
import getIdentifier from 'getIdentifier';
import getTimeStamp from 'getTimeStamp';
import STORE from 'store';

import { useEntityValues } from 'screens/EntityDetailsShow/SelectedEntityProvider/useEntityOptions';
import { useBuildEntityLink } from '../../../components/GlobalSearch/hooks/useEntityLink';

const useOnEntityLinkClicked = (entity: EntityDetails.Entity) => {
  const currentUser = useContext(CurrentUserContext);
  const buildEntityLink = useBuildEntityLink(entity);

  const { values: entityValues, isLoading: isLoadingEntityValues } =
    useEntityValues(entity);
  const {
    isLoading: isLoadingEntityDefinitions,
    entityDetailsApps,
    localUpdateOrAddApp,
  } = useContext(EntityDefinitionsContext);
  const { getFieldLabel } = useGetFieldLabel();
  const { selectedAccountId } = useContext(AccountPickerContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);

  const navigateToApp = useCallback(
    (app: EntityDetails.App) => {
      const link = buildEntityLink(entityValues[0], app);
      if (link) {
        navigate(link);
      } else {
        alert('Something went wrong!');
      }
    },
    [buildEntityLink, entityValues, navigate],
  );

  const getIsReady = useCallback(() => {
    if (
      isLoadingEntityValues ||
      entityValues.length === 0 ||
      isLoadingEntityDefinitions
    ) {
      return false;
    }

    return true;
  }, [entityValues.length, isLoadingEntityDefinitions, isLoadingEntityValues]);

  useEffect(() => {
    setIsReady(getIsReady());
  }, [getIsReady]);

  const onClick = useCallback(async () => {
    if (isLoading) {
      return;
    }
    if (!isReady) {
      alert('Something went wrong');
      return;
    }
    setIsLoading(true);
    const app = entityDetailsApps.find((a) => a.entityId === entity.id);
    if (!app) {
      const newApp: EntityDetails.App = {
        id: getIdentifier(),
        entityId: entity.id,
        tabs: [],
        updatedOn: getTimeStamp(),
        createdOn: getTimeStamp(),
        updatedBy: currentUser.id,
        createdBy: currentUser.id,
        title: `${getFieldLabel({
          field: entity.primaryField,
          dataType: entity.entityDataset,
        })} Details`,
      };

      await STORE.contentDistributions
        .getEntityDetailsApps({
          accountId: selectedAccountId,
        })
        .doc(newApp.id)
        .set(newApp);
      localUpdateOrAddApp(newApp);
      navigateToApp(newApp);
      return;
    }

    navigateToApp(app);
    setIsLoading(false);
  }, [
    isLoading,
    isReady,
    entityDetailsApps,
    entity.id,
    entity.primaryField,
    entity.entityDataset,
    currentUser.id,
    getFieldLabel,
    selectedAccountId,
    localUpdateOrAddApp,
    navigateToApp,
  ]);

  return {
    isLoading: isLoadingEntityValues || isLoadingEntityDefinitions,
    hasNoEntities: entityValues.length === 0 && !isLoadingEntityDefinitions,
    onClick: isReady ? onClick : undefined,
  };
};

export default useOnEntityLinkClicked;
