import React from 'react';
import Typography from 'kingpin/atoms/Typography';
import PlateStyles from './PlateStyles';

const MustNotExistFilterPlate = ({ field }: { field: string }) => {
  return (
    <div className="filter-plate">
      <PlateStyles.Plate>
        <PlateStyles.LeftPlate>
          <Typography.Body type="Body 12">{field}</Typography.Body>
        </PlateStyles.LeftPlate>
        <PlateStyles.MiddlePlate>
          <Typography.Body type="Body 12">must not</Typography.Body>
        </PlateStyles.MiddlePlate>
        <PlateStyles.RightPlate>
          <Typography.Body type="Body 12">exist</Typography.Body>
        </PlateStyles.RightPlate>
      </PlateStyles.Plate>
    </div>
  );
};

export default MustNotExistFilterPlate;
