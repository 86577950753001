import { useCallback, useContext } from 'react';
import BoardsContext from 'contexts/BoardsContext';
import PopupContext from 'contexts/PopupContext';
import ReportsContext from 'contexts/ReportsContext';
import ScorecardContext from 'contexts/ScorecardContext';
import { isKpiRow } from 'hooks/kpiTypeCheckers';

const useOpenKpiPopup = (
  kpi: Scorecards.ScorecardKpi | Scorecards.ManualKpiRow | undefined,
  period: Period | undefined,
) => {
  const { allReports } = useContext(ReportsContext);
  const { boards } = useContext(BoardsContext);
  const {
    setUnSavedFilter,
    openPopupReport,
    openPopupBoard,
    setSelectedBoard,
    setSelectedReport,
  } = useContext(PopupContext);
  const { setPoppedUpKpi, periods } = useContext(ScorecardContext);

  const openKpiPopup = useCallback(() => {
    if (!kpi || !period) {
      return;
    }

    if (kpi.reportDrillDownId || kpi.boardDrillDownId) {
      setPoppedUpKpi(kpi);
    }

    if (kpi.reportDrillDownId) {
      const report = allReports.find((r) => r.id === kpi.reportDrillDownId);
      if (report) {
        setSelectedReport(report);
        setUnSavedFilter({
          drillDowns: isKpiRow(kpi) ? kpi.drillDowns : window.emptyArray,
          dateScope: {},
          scope: isKpiRow(kpi) ? kpi.scopeDrillDowns : window.emptyArray,
          dateField: isKpiRow(kpi) ? kpi.queryDateField || 'date' : 'date',
          dateRange: {
            startDate: period.startDate,
            endDate: period.endDate,
          },
        });
        openPopupReport([], 'Scorecard', {
          availablePeriods: periods,
          selectedPeriod: period,
          kpi,
        });
      }
    } else if (isKpiRow(kpi) && kpi.boardDrillDownId) {
      const board = boards.find((b) => b.id === kpi.boardDrillDownId);
      if (board) {
        setSelectedBoard(board);
        const dateRange = {
          startDate: period.startDate,
          endDate: period.endDate,
        };
        openPopupBoard({
          popupFilters: kpi.drillDowns,
          scope: kpi.scopeDrillDowns,
          popupDateRange: dateRange,
          origin: 'Goal',
          scorecardPeriodSettings: {
            availablePeriods: periods,
            selectedPeriod: period,
            kpi,
          },
        });
      }
    }
  }, [
    kpi,
    setPoppedUpKpi,
    allReports,
    setSelectedReport,
    setUnSavedFilter,
    period,
    openPopupReport,
    periods,
    boards,
    setSelectedBoard,
    openPopupBoard,
  ]);

  return kpi && period ? openKpiPopup : undefined;
};

export default useOpenKpiPopup;
