import { useCallback, useContext } from 'react';
import cardTypeCheckers from '../../types/cardTypeCheckers';
import captureException from '../../services/captureException';
import _ from 'lodash';
import ChartDefinitionsContext from '../../contexts/ChartDefinitionsContext';
import ReportsContext from '../../contexts/ReportsContext';
import isUsedInChart from './isUsedInChart';

const useGetReportUsage = () => {
  const { definitionsLookup } = useContext(ChartDefinitionsContext);
  const { allReports } = useContext(ReportsContext);

  const getReportUsage = useCallback(
    (
      metric: Metrics.NormalMetric | Metrics.CompoundMetric,
    ): FleetOps.MetricReportUsage[] => {
      const usage = [] as FleetOps.MetricReportUsage[];
      allReports.forEach((r) => {
        r.canvas.cards.forEach((card) => {
          if (cardTypeCheckers.isChartDefinition(card)) {
            const def = definitionsLookup[card.content.chartDefinitionId];
            if (!def) {
              return;
            }
            if (isUsedInChart(metric, def)) {
              usage.push({
                id: `${def.id}-${r.id}`,
                gadgetName: def.name,
                gadgetId: def.id,
                reportId: r.id,
                reportName: r.name,
              });
            }
          } else {
            const error = new Error();
            error.name = `Unknown card type on report: ${r.id} (${card.content.type})`;
            captureException(error);
          }
        });
      });

      return _.uniqBy(usage, 'id');
    },
    [allReports, definitionsLookup],
  );

  return getReportUsage;
};

export default useGetReportUsage;
