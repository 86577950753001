import { useEffect, useState } from 'react';
import useGetFieldLabel from '../../../../hooks/useGetFieldLabel';

const useFieldLabel = (
  metric: Metrics.NormalMetric | Metrics.CompoundMetric,
) => {
  const { getMetricFieldLabel } = useGetFieldLabel();
  const [fieldLabel, setFieldLabel] = useState<string>(() =>
    getMetricFieldLabel(metric),
  );
  useEffect(() => {
    setFieldLabel(getMetricFieldLabel(metric));
  }, [getMetricFieldLabel, metric]);

  return fieldLabel;
};

export default useFieldLabel;
