import React from 'react';

interface MetricMatrixContextType {
  onDrillDown: (term: string, hasMeta: boolean) => void;
}

const MetricMatrixContext = React.createContext<MetricMatrixContextType>(
  {} as MetricMatrixContextType,
);

export default MetricMatrixContext;
