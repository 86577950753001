import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import usePopup from 'hooks/usePopup';
import FadeIn from 'components/FadeIn';
import NavItem from 'kingpin/navigation/NavItem';

import useSearch from './hooks/useSearch';
import GLOBAL_SEARCH from './constants';
import useIsEnabled from './hooks/useIsEnabled';
import NoSearchInput from './components/NoSearchInput';
import NoSearchResults from './components/NoSearchResults';
import LoadingSearch from './components/LoadingSearch';
import SearchResults from './components/SearchResults';
import SeeMoreEntityResults from './components/SeeMoreEntityResults';
import SearchBar from './components/SearchBar';
import AnalyticsContext from '../../contexts/AnalyticsContext';

const Scrollable = styled.div`
  max-height: calc(100% - ${GLOBAL_SEARCH.TOP_BAR_HEIGHT}px);
  position: relative;
  overflow-y: scroll;
`;

const GlobalSearchContainer = () => {
  const { trackEvent } = useContext(AnalyticsContext);
  const isEnabled = useIsEnabled();
  const { isOpen, open, close } = usePopup();
  const [selectedEntity, setSelectedEntity] = useState<
    EntityDetails.Entity | undefined
  >();

  const onOpened = useCallback(() => {
    trackEvent('Entity Details - Search - Opened');
    open();
  }, [open, trackEvent]);

  const onSearchCancelled = useCallback(() => {
    trackEvent('Entity Details - Search - Cancelled');
    close();
  }, [close, trackEvent]);

  const onEntityNavigate = useCallback(
    (entity: EntityDetails.Entity) => {
      trackEvent('Entity Details - App Opened', {
        entityName: entity.name,
        entityPrimaryField: entity.primaryField,
        source: 'search',
      });
      close();
    },
    [close, trackEvent],
  );

  const {
    searchText,
    onSearchTextChanged,
    isNoResults,
    isLoading,
    isActiveFilterDisabled,
    setIsActiveFilterDisabled,
    results,
  } = useSearch({
    isOpen,
    onSearchCancelled,
    setSelectedEntity,
  });

  if (!isEnabled) {
    return null;
  }

  let component: JSX.Element;
  if (searchText === '') {
    component = <NoSearchInput />;
  } else if (isLoading) {
    component = <LoadingSearch />;
  } else if (isNoResults) {
    component = (
      <NoSearchResults
        isActiveFilterDisabled={isActiveFilterDisabled}
        setIsActiveFilterDisabled={setIsActiveFilterDisabled}
        searchText={searchText}
      />
    );
  } else if (selectedEntity) {
    component = (
      <SeeMoreEntityResults
        result={results}
        onEntityNavigate={onEntityNavigate}
        selectedEntity={selectedEntity}
        setSelectedEntity={setSelectedEntity}
        isActiveFilterDisabled={isActiveFilterDisabled}
        setIsActiveFilterDisabled={setIsActiveFilterDisabled}
      />
    );
  } else {
    component = (
      <SearchResults
        results={results}
        onEntityNavigate={onEntityNavigate}
        setSelectedEntity={setSelectedEntity}
        isActiveFilterDisabled={isActiveFilterDisabled}
        setIsActiveFilterDisabled={setIsActiveFilterDisabled}
      />
    );
  }

  return (
    <>
      <FadeIn isOpen={isOpen}>
        <>
          {isOpen && (
            <SearchBar
              searchText={searchText}
              onSearchTextChanged={onSearchTextChanged}
              close={onSearchCancelled}
            />
          )}
        </>
        <Scrollable>{component}</Scrollable>
      </FadeIn>
      <NavItem
        to={'#'}
        label="Search"
        isActiveOverride={false}
        onClick={onOpened}
        icon="search"
        tooltip="Search"
      />
    </>
  );
};

export default GlobalSearchContainer;
