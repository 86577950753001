import { useEffect, useState } from 'react';
import useFieldDefinitions from '../../../../../../hooks/useFieldDefinitions';

const useFieldDef = ({
  field,
  selectedMetric,
}: {
  field: string;
  selectedMetric?: Metrics.NormalMetric;
}) => {
  const [fieldDef, setFieldDef] = useState<FleetOps.Field | undefined>();
  const [dataSourceForFieldFilter] = useState<string[] | undefined>(
    selectedMetric ? [selectedMetric.dataType] : undefined,
  );
  const { fieldDefinitions } = useFieldDefinitions(dataSourceForFieldFilter);

  useEffect(() => {
    setFieldDef(
      field ? fieldDefinitions.find((d) => d.field === field) : undefined,
    );
  }, [field, fieldDefinitions]);

  return fieldDef;
};

export default useFieldDef;
