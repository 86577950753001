import React, { useContext } from 'react';
import NewCommitmentSlideOutHeading from './NewCommitmentSlideOutHeading';
import CommitmentQueryContext from '../../../../../contexts/CommitmentQueryContext';

const getHeadingText = (mode: CommitmentFormPopupMode): string => {
  switch (mode) {
    case 'end':
      return 'End Award';
    case 'modify':
      return 'Modify Award';
    case 'renew':
      return 'Renew Award';
    case 'create':
    default:
      return 'Add New Award';
  }
};

const NewCommitmentSlideOutHeadingContainer = () => {
  const { closeSlideOut, mode } = useContext(CommitmentQueryContext);
  const headingText = getHeadingText(mode);

  return (
    <NewCommitmentSlideOutHeading
      close={closeSlideOut}
      headingText={headingText}
    />
  );
};

export default NewCommitmentSlideOutHeadingContainer;
