import React, { ChangeEvent } from 'react';
import styled from 'styled-components';

import Modal from '@atlaskit/modal-dialog';
import Row from 'components/Common/Row';
import Typography from 'kingpin/atoms/Typography';
import CloseButton from 'components/CloseButton';
import Button from 'kingpin/atoms/Button';
import Colors2 from 'theme/Colors2';
import Loading from 'components/Loading/Loading';
import PreviousUploadItem from '../PreviousUploadItem';
import { TopBarDiv } from 'screens/Boards/PerformanceBoardForm';
import UploadSection from './UploadSection';
import WarningSvg from '../icons/warning.svg';
import SuccessSvg from '../icons/success.svg';

const ModalContent = styled.div`
  padding: 32px 40px;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  overflow-y: hidden;
`;

const UploadServiceFailureModal = ({
  close,
  dataType,
  file,
  setFile,
  onFileUpload,
  isUploading,
  inputFile,
  onFileChange,
  isLoading,
  sortedPreviousUploads,
  errors,
  configError,
  isUploadSuccess,
  uploadedFileName,
}: {
  close: () => void;
  dataType: DataManager.UploadDataType;
  file: File | undefined;
  setFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  onFileUpload: () => void;
  isUploading: boolean;
  inputFile: React.MutableRefObject<HTMLInputElement | null>;
  onFileChange: (e: ChangeEvent<HTMLInputElement>) => void;
  isLoading: boolean;
  sortedPreviousUploads: DataManager.PreviousUpload[];
  errors: string[];
  configError: string | undefined;
  isUploadSuccess: boolean | undefined;
  uploadedFileName: string | undefined;
}) => {
  const MODAL_WIDTH = '520px';

  return (
    <Modal
      width={MODAL_WIDTH}
      height={errors.length === 0 ? '632px' : '760px'}
      onClose={close}
      autoFocus={false}
    >
      {isUploadSuccess && (
        <Row
          data-testid={'success-upload'}
          style={{
            backgroundColor: Colors2.Secondary.success,
            padding: '16px',
            position: 'absolute',
            width: MODAL_WIDTH,
            top: 0,
          }}
          centerAlign
        >
          <img src={SuccessSvg} alt="success" style={{ marginRight: '8px' }} />
          <Typography.Body type="Label" color={'#FFFFFF'}>{`${
            uploadedFileName || 'File '
          } uploaded successfully`}</Typography.Body>
        </Row>
      )}

      {configError && (
        <Row
          data-testid={'failure-upload'}
          style={{
            backgroundColor: Colors2.Secondary.error,
            padding: '16px',
            position: 'absolute',
            width: MODAL_WIDTH,
            top: 0,
          }}
          centerAlign
        >
          <img src={WarningSvg} alt="error" style={{ marginRight: '8px' }} />
          <Typography.Body
            type="Label"
            color={'#FFFFFF'}
          >{`Config error: ${configError}`}</Typography.Body>
        </Row>
      )}
      <ModalContent>
        <div>
          <TopBarDiv>
            <Row
              centerAlign
              spaceBetween
              style={{ height: '100%', paddingBottom: 16 }}
            >
              <Row centerAlign style={{ height: '100%' }}>
                <Typography.Header type="H3">
                  {`Upload ${dataType.name}`}
                </Typography.Header>
              </Row>
              <CloseButton close={close} />
            </Row>
          </TopBarDiv>

          {errors.length !== 0 && (
            <div
              style={{
                backgroundColor: '#FFE3E3',
                padding: '16px',
                borderRadius: '8px',
                width: '100%',
                marginBottom: '20px',
                height: 'fit-content',
                overflow: 'hidden',
                transition: 'height .2s ease-out',
              }}
            >
              <Row style={{ paddingBottom: '12px' }}>
                <img
                  src={WarningSvg}
                  style={{ marginRight: '8px' }}
                  alt="warning"
                />
                <Typography.Body type={'Body 12'}>
                  {`Couldn't upload the file because of ${errors.length} issue(s)`}
                </Typography.Body>
              </Row>
              <Typography.Body type={'Body 12'}>
                {`Missing column(s): ${errors
                  .map((e) => e.replace('Missing column', ''))
                  .join(', ')}`}
              </Typography.Body>
            </div>
          )}

          <UploadSection
            file={file}
            setFile={setFile}
            onFileUpload={onFileUpload}
            isUploading={isUploading}
            inputFile={inputFile}
            onFileChange={onFileChange}
            fileType={dataType.fileType}
            dataType={dataType.dataType}
          />

          <div style={{ marginBottom: 16 }}>
            <Typography.Body type="Label">
              {`Previous Uploads (${sortedPreviousUploads.length})`}
            </Typography.Body>
          </div>

          <>
            {isLoading && <Loading />}
            {!isLoading && (
              <>
                {sortedPreviousUploads.length === 0 && (
                  <Typography.Body type="Body 14">
                    No Previous Uploads
                  </Typography.Body>
                )}
                {sortedPreviousUploads.length !== 0 && (
                  <div
                    style={{
                      border: `1px solid ${Colors2.Grey['8']}`,
                      borderRadius: '4px',
                    }}
                  >
                    <div
                      style={{
                        maxHeight: '144px',
                        overflowY: 'scroll',
                      }}
                    >
                      {sortedPreviousUploads.map((previousUpload) => (
                        <PreviousUploadItem
                          key={previousUpload.id}
                          previousUpload={previousUpload}
                          isLast={
                            sortedPreviousUploads[
                              sortedPreviousUploads.length - 1
                            ].id === previousUpload.id
                          }
                        />
                      ))}
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        </div>
        <Row style={{ justifyContent: 'flex-end' }}>
          <Button
            size={'Small'}
            type={'Tertiary'}
            label="Close"
            onClick={close}
          />
        </Row>
      </ModalContent>
    </Modal>
  );
};

export default UploadServiceFailureModal;
