import { buildContentViewIdAlt } from '../buildContentViewId';
import firebase from 'firebase/compat/app';

const unmarkItemAsFavourite = async ({
  type,
  typeId,
  userId,
  accountRef,
}: {
  type:
    | 'report'
    | 'dashboard'
    | 'wallboard'
    | 'workspace'
    | 'scorecard'
    | 'targetsApp';
  typeId: string;
  userId: string;
  accountRef: firebase.firestore.DocumentReference;
}) => {
  return accountRef
    .collection('users')
    .doc(userId)
    .collection('favourites')
    .doc(buildContentViewIdAlt({ type, typeId }))
    .delete();
};

export default unmarkItemAsFavourite;
