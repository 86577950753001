import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

const deleteTargetsMutation = async ({
  client,
  id,
}: {
  client: ApolloClient<NormalizedCacheObject>;
  id: string;
}): Promise<void> => {
  const mutation = {
    mutation: gql`
      mutation DeleteTargets($id: String!) {
        deleteTargets(id: $id)
      }
    `,
    variables: {
      id,
    },
  };

  await client.mutate(mutation);
};

export default deleteTargetsMutation;
