import React, { useCallback, useEffect, useState } from 'react';
import usePopup from '../../../../../hooks/usePopup';
import getMetricListItemId from '../getMetricListItemId';
import Typography from 'kingpin/atoms/Typography';
import CheckmarkIcon from '../images/Checkmark.svg';
import { UnsavedChangesConfirmation } from '../../../../../components/ConfirmationModal';
import styled from 'styled-components';
import Row from '../../../../../components/Common/Row';
import Colors2 from '../../../../../theme/Colors2';

const ListItem = styled(Row)<{ isSelected: boolean }>`
  background-color: ${(props) => (props.isSelected ? '#F7F7F7' : 'white')};
  height: 40px;
  border-radius: 8px;
  cursor: pointer;
  padding: 8px 10px;

  &:hover {
    background-color: ${Colors2.Grey['9']};
  }
`;

const IconImg = styled.img`
  width: 12px;
  height: 12px;
`;

const MetricListItem = ({
  metric,
  selectedMetric,
  setSelectedMetric,
  hasUnsavedChanges,
}: {
  metric: Metrics.NormalMetric | Metrics.CompoundMetric;
  selectedMetric?: Metrics.NormalMetric | Metrics.CompoundMetric;
  setSelectedMetric: React.Dispatch<
    React.SetStateAction<
      Metrics.NormalMetric | Metrics.CompoundMetric | undefined
    >
  >;
  hasUnsavedChanges: boolean;
}) => {
  const {
    isOpen: isConfirmOpen,
    open: openConfirm,
    close: closeConfirm,
  } = usePopup();

  const [isSelected, setIsSelected] = useState<boolean>(false);
  const onClickConfirmed = useCallback(() => {
    setSelectedMetric(metric);
    closeConfirm();
  }, [closeConfirm, metric, setSelectedMetric]);

  const onClick = useCallback(() => {
    if (!!selectedMetric && metric.id === selectedMetric.id) {
      return;
    }

    if (hasUnsavedChanges) {
      openConfirm();
      return;
    }
    onClickConfirmed();
  }, [
    hasUnsavedChanges,
    metric.id,
    onClickConfirmed,
    openConfirm,
    selectedMetric,
  ]);

  useEffect(() => {
    setIsSelected(!!selectedMetric && selectedMetric.id === metric.id);
  }, [metric.id, selectedMetric]);

  return (
    <>
      <ListItem
        id={getMetricListItemId(metric)}
        isSelected={isSelected}
        spaceBetween
        centerAlign
        onClick={onClick}
      >
        <Typography.Body type="Body 12">{metric.name}</Typography.Body>
        {isSelected && <IconImg src={CheckmarkIcon} />}
      </ListItem>
      <UnsavedChangesConfirmation
        isOpen={isConfirmOpen}
        close={closeConfirm}
        onConfirmed={onClickConfirmed}
      />
    </>
  );
};

export default MetricListItem;
