import { useParams } from 'react-router-dom';

const useEntityParams = () => {
  const { field, value, tabType, tabId } = useParams<{
    field: string;
    value: string;
    tabType?: string;
    tabId?: string;
  }>();

  return {
    field: decodeURIComponent(field || ''),
    value: decodeURIComponent(value || ''),
    tabType,
    tabId,
  };
};

export default useEntityParams;
