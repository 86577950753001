import React, { useContext, useEffect, useState } from 'react';
import QueryLoads from './QueryLoads';
import CommitmentQueryContext from '../../../../contexts/CommitmentQueryContext';
import getTotalLoads from '../../../../api/getTotalLoads';
import GqlClientContext from '../../../../contexts/GqlClientContext';
import useDateScope from '../../../../hooks/useDateScope';

const QueryLoadsContainer = () => {
  const { client } = useContext(GqlClientContext);

  // State
  const { isQueryValid, queryFilterInput } = useContext(CommitmentQueryContext);
  const dateScope = useDateScope({});
  const [loadsCount, setLoadsCount] = useState<number | undefined>();
  const [earliest, setEarliest] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Effects
  useEffect(() => {
    if (!isQueryValid) {
      setLoadsCount(undefined);
      setEarliest(undefined);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    getTotalLoads({
      filters: [queryFilterInput],
      dateScope,
      client,
    }).then(({ total, earliest }: { total: number; earliest?: string }) => {
      setLoadsCount(total);
      setEarliest(earliest);
      setIsLoading(false);
    });
  }, [client, dateScope, isQueryValid, queryFilterInput]);

  if (loadsCount === undefined || earliest === undefined) {
    return null;
  }

  const hasLoads = loadsCount > 0;

  return (
    <QueryLoads
      isLoading={isLoading}
      loadsCount={loadsCount}
      earliest={earliest}
      hasLoads={hasLoads}
    />
  );
};

export default QueryLoadsContainer;
