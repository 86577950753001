import { DateTime } from 'luxon';
import getNumberOfDays from './getNumberOfDays';

const getDayOfWeekComparison = (
  d: DateRangeInput,
  comparison: PersistedComparisonType,
): DateRangeInput => {
  const s = d.startDate ? DateTime.fromISO(d.startDate) : DateTime.local();
  const e = d.endDate ? DateTime.fromISO(d.endDate) : undefined;
  if (comparison.compareType === 'lastYear') {
    const sObj = s.minus({ year: 1 }).set({ weekday: s.weekday });
    if (e) {
      const eObj = sObj.plus({ days: e.diff(s, 'days').days });

      return {
        startDate: sObj.toISODate(),
        endDate: eObj.toISODate(),
      };
    } else {
      return {
        startDate: sObj.toISODate(),
      };
    }
  } else if (comparison.compareType === 'previous') {
    const numDays = getNumberOfDays(d);
    const s = d.startDate ? DateTime.fromISO(d.startDate) : DateTime.local();
    const e = d.endDate ? DateTime.fromISO(d.endDate) : undefined;
    const diffWeeks = Math.floor(numDays / 7) + 1;
    const sObj = s.minus({ weeks: diffWeeks }).set({ weekday: s.weekday });
    if (e) {
      const eObj = e.minus({ weeks: diffWeeks }).set({ weekday: e.weekday });
      return {
        startDate: sObj.toISODate(),
        endDate: eObj.toISODate(),
      };
    } else {
      return {
        startDate: sObj.toISODate(),
      };
    }
  } else {
    const error = new Error(`Unhandled comparison: ${comparison.compareType}`);
    error.name = 'Unhandled comparison';
    throw error;
  }
};

export default getDayOfWeekComparison;
