import React from 'react';
import Typography from 'kingpin/atoms/Typography';
import TimelineEvent from '../OrderDetailsTimeline/TimelineEvent';

const DetailsSlideOutTimeline = ({ events }: { events: TimelineEvent[] }) => (
  <div style={{ marginBottom: 24 }}>
    <div style={{ marginBottom: 24 }}>
      <Typography.Header type={'H5'}>Timeline</Typography.Header>
    </div>
    <div>
      {events.map((e) => (
        <TimelineEvent key={e.clientId} timelineEvent={e} />
      ))}
    </div>
  </div>
);

export default DetailsSlideOutTimeline;
