const PERSON_NAMES = [
  'James',
  'John',
  'Robert',
  'Michael',
  'William',
  'David',
  'Richard',
  'Charles',
  'Joseph',
  'Thomas',
  'Christopher',
  'Daniel',
  'Paul',
  'Mark',
  'Donald',
  'George',
  'Kenneth',
  'Steven',
  'Edward',
  'Brian',
  'Ronald',
  'Anthony',
  'Kevin',
  'Jason',
  'Jeff',
];

export default PERSON_NAMES;
