import { useCallback, useContext, useEffect, useState } from 'react';
import CostsWizardContext from '../../context/CostsWizardContext';

const useGroupValuesOptions = ({
  dataType,
  groupNameValues,
  editingName,
}: {
  dataType: string | undefined;
  groupNameValues: string[];
  editingName?: string;
}) => {
  const { wizardState, setWizardState } = useContext(CostsWizardContext);
  const [options, setOptions] = useState<ExclusiveDropdownOption[]>([]);

  const getIsUsedElsewhere = useCallback(
    (value: string): undefined | { reason: string; tooltip: string } => {
      if (
        wizardState.config.groupStep.groups.find(
          (group) =>
            group.fields &&
            group.groupName !== editingName &&
            group.fields.includes(value),
        )
      ) {
        return { reason: value, tooltip: value };
      } else {
        return undefined;
      }
    },
    [editingName, wizardState.config.groupStep.groups],
  );

  useEffect(() => {
    if (!dataType) {
      return;
    }
    const newOptions = groupNameValues.map((value) => {
      const isSelected =
        wizardState.config.groupStep.currentGroup.includes(value);
      return {
        key: value,
        label: value,
        isSelected,
        isInUseElsewhere: getIsUsedElsewhere(value),
        onSelected: () => {
          setWizardState({
            ...wizardState,
            config: {
              ...wizardState.config,
              groupStep: {
                ...wizardState.config.groupStep,
                currentGroup: isSelected
                  ? wizardState.config.groupStep.currentGroup.filter(
                      (val) => val !== value,
                    )
                  : [...wizardState.config.groupStep.currentGroup, value],
              },
            },
          });
        },
      };
    });

    setOptions(newOptions);
  }, [
    dataType,
    getIsUsedElsewhere,
    groupNameValues,
    setWizardState,
    wizardState,
  ]);

  return options;
};

export default useGroupValuesOptions;
