import React, {
  MouseEventHandler,
  useCallback,
  useContext,
  useState,
} from 'react';
import { CSVLink } from 'react-csv';
import Button from 'kingpin/atoms/Button';
import MetricFilteringContext from '../../../contexts/MetricFilteringContext';
import MetricUsageLookupContext from '../../../contexts/MetricUsageLookupContext';
import { useGetMetricDatasetLabel } from '../../../components/Inputs/MetricPicker/MetricPickerPopup/hooks/useMetricdatasetLabel';
import useGetFieldLabel from '../../../hooks/useGetFieldLabel';
import UsersContext from '../../../contexts/UsersContext';
import getUserDisplayName from '../../../contextProviders/UsersProvider/getUserDisplayName';

const HEADINGS = [
  'Metric Name',
  'Descriptions',
  'Dataset',
  'Fields',
  'Usage',
  'Last Updated By',
];

const DownloadMetricsReport = ({
  mode,
}: {
  mode: 'Metric' | 'CompoundMetric';
}) => {
  const { filteredCompoundMetrics, filteredMetrics } = useContext(
    MetricFilteringContext,
  );
  const { metricLookup, compoundMetricLookup } = useContext(
    MetricUsageLookupContext,
  );
  const { allUsers } = useContext(UsersContext);

  const { getMetricFieldLabel } = useGetFieldLabel();
  const getMetricDatasetLabel = useGetMetricDatasetLabel();
  const getUsage = useCallback(
    (metric: Metrics.NormalMetric | Metrics.CompoundMetric) => {
      const report =
        mode === 'Metric'
          ? metricLookup[metric.id]
          : compoundMetricLookup[metric.id];
      if (!report) {
        return 'Error';
      }

      return report.usageCount.toString(10);
    },
    [compoundMetricLookup, metricLookup, mode],
  );
  const getLastUpdatedBy = useCallback(
    (metric: Metrics.NormalMetric | Metrics.CompoundMetric) => {
      const u = allUsers.find((u) => u.id === metric.updatedBy);
      if (!u) {
        return 'Error';
      }

      return getUserDisplayName(u);
    },
    [allUsers],
  );

  const [csvData, setCsvData] = useState<string[][]>([]);

  const metricToCsvRow = useCallback(
    (metric: Metrics.NormalMetric): string[] => {
      return [
        metric.name,
        metric.description,
        getMetricDatasetLabel(metric).datasetLabel,
        getMetricFieldLabel(metric),
        getUsage(metric),
        getLastUpdatedBy(metric),
      ];
    },
    [getLastUpdatedBy, getMetricDatasetLabel, getMetricFieldLabel, getUsage],
  );

  const compoundMetricToCsvRow = useCallback(
    (metric: Metrics.CompoundMetric): string[] => {
      return [
        metric.name,
        metric.description,
        getMetricDatasetLabel(metric).datasetLabel,
        getMetricFieldLabel(metric),
        getUsage(metric),
        getLastUpdatedBy(metric),
      ];
    },
    [getLastUpdatedBy, getMetricDatasetLabel, getMetricFieldLabel, getUsage],
  );

  const onDownloadClicked = useCallback(
    (
      event: MouseEventHandler<HTMLAnchorElement>,
      done: (proceed?: boolean) => void,
    ) => {
      const newData = [HEADINGS];
      if (mode === 'Metric') {
        filteredMetrics.forEach((m) => {
          newData.push(metricToCsvRow(m));
        });
      } else if (mode === 'CompoundMetric') {
        filteredCompoundMetrics.forEach((m) => {
          newData.push(compoundMetricToCsvRow(m));
        });
      }
      setCsvData(newData);
      done(true);
    },
    [
      compoundMetricToCsvRow,
      filteredCompoundMetrics,
      filteredMetrics,
      metricToCsvRow,
      mode,
    ],
  );

  return (
    <CSVLink
      asyncOnClick
      data={csvData}
      onClick={onDownloadClicked}
      filename={mode === 'Metric' ? 'Metrics' : 'Compound Metrics'}
    >
      <Button label="Export" type="Ghost" size="Small" />
    </CSVLink>
  );
};

export default DownloadMetricsReport;
