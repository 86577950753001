import React, { useState } from 'react';
import ImageGadget from './ImageGadget';
import Loading from '../Loading';
import usePopup from '../../hooks/usePopup';
import useImageSrc from './useImageSrc';

const ImageGadgetContainer = ({
  card,
  isEditing,
}: {
  card: CanvasCard.ImageCard;
  isEditing: boolean;
}) => {
  const {
    isOpen: isEditFormOpen,
    open: openEditForm,
    close: closeEditForm,
  } = usePopup();
  const [imageLayout] = useState<ImageLayout>(
    card.content.imageLayout || 'stretch',
  );
  const { imgSrc, isLoading } = useImageSrc(card.content.imageSrc);

  if (!!imgSrc && !isLoading) {
    return (
      <ImageGadget
        src={imgSrc}
        isEditing={isEditing}
        imageLayout={imageLayout}
        closeEditForm={closeEditForm}
        openEditForm={openEditForm}
        isEditFormOpen={isEditFormOpen}
        card={card}
      />
    );
  } else {
    return <Loading />;
  }
};

export default ImageGadgetContainer;
