import React from 'react';
import Typography from 'kingpin/atoms/Typography';
import Badge from 'components/Badge';
import ItemRow from '../ItemRow';

const WizardItem = ({
  name,
  onEditClicked,
  entity,
}: {
  name?: string;
  onEditClicked: () => void;
  entity: string;
}) => (
  <ItemRow
    datasetCol={
      <>
        <Typography.Body type="Link" onClick={onEditClicked}>
          {name}
        </Typography.Body>
        <div style={{ marginLeft: 8 }}>
          <Badge text={'Saved progress'} badgeType={'Info'} />
        </div>
      </>
    }
    descriptionCol={<Typography.Body type="Body 12">{entity}</Typography.Body>}
  />
);

export default WizardItem;
