import React, { ChangeEvent } from 'react';
import Button from 'kingpin/atoms/Button';
import ReportDrillDowns from '../../../../ReportDrillDowns';
import Row from '../../../../Common/Row';
import TextInput from '../../../../../kingpin/atoms/TextInput';
import Form from 'kingpin/forms/Form';
import FormHeader from '../../../../../kingpin/forms/FormHeader';
import FormContent from '../../../../../kingpin/forms/FormContent';

const NewQuickFilterForm = ({
  name,
  onNameChanged,
  close,
  onSave,
  isSaving,
  isValid,
}: {
  name: string;
  onNameChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  close: () => void;
  onSave: () => void;
  isSaving: boolean;
  isValid: boolean;
}) => (
  <Form>
    <FormHeader title={'New Quick Filter'} onClose={close} />
    <FormContent>
      <div style={{ marginBottom: 16 }}>
        <TextInput label={'Name'} value={name} onChange={onNameChanged} />
      </div>
      <div style={{ marginBottom: 16 }}>
        <ReportDrillDowns />
      </div>
    </FormContent>
    <Row style={{ justifyContent: 'space-between' }}>
      <Button
        type="Secondary"
        size="Small"
        onClick={close}
        isDisabled={isSaving}
        label="Cancel"
      />
      <Button
        type="Primary"
        size="Small"
        isDisabled={isSaving || !isValid}
        isLoading={isSaving}
        onClick={onSave}
        label="Save"
      />
    </Row>
  </Form>
);

export default NewQuickFilterForm;
