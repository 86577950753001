import isDefined from '../../../../../isDefined';
import { useCallback, useContext, useEffect, useState } from 'react';
import BaseViewsContext from '../../../../../contexts/BaseViewsContext';
import useGetDatasetDefinition from '../../../../../hooks/useGetDatasetDefinition';
import CurrentUserContext from '../../../../../contexts/CurrentUserContext';
import ImpersonatorContext from '../../../../../contexts/ImpersonatorContext';

const useNormalDatasetsList = (searchText: string) => {
  const {
    baseViews,
    baseViewsLastUpdatedAtLookup,
    isLoading: isLoadingBvs,
  } = useContext(BaseViewsContext);
  const { isFleetOpsStaff } = useContext(CurrentUserContext);
  const { impersonatorId } = useContext(ImpersonatorContext);
  const getDatasetDefinition = useGetDatasetDefinition();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [normalDatasetList, setNormalDatasetList] = useState<
    {
      item: DataManager.DatasetListItem;
      name: string;
      lastUpdated?: number;
    }[]
  >([]);

  const getFilteredBaseViews = useCallback(
    () =>
      Object.values(baseViews)
        .filter(
          (v) =>
            v &&
            (v.type.toLowerCase().includes(searchText.toLowerCase()) ||
              (v.nameAlias &&
                v.nameAlias.toLowerCase().includes(searchText.toLowerCase()))),
        )
        .filter(isDefined)
        .filter((bv) => {
          const ds = getDatasetDefinition(bv.type);
          const isPerformanceDataset = ds ? ds.isPerformance : false;
          return !isPerformanceDataset;
        })
        .filter((bv) => {
          if (isFleetOpsStaff && !impersonatorId) {
            return true;
          }

          if (bv.isHidden) {
            return false;
          }

          return true;
        })
        .map((item) => ({
          item: {
            type: 'baseView' as 'baseView',
            content: item,
          },
          name: item.nameAlias ? item.nameAlias : item.type,
          lastUpdated: baseViewsLastUpdatedAtLookup[item.type],
        })),
    [
      baseViews,
      baseViewsLastUpdatedAtLookup,
      getDatasetDefinition,
      impersonatorId,
      isFleetOpsStaff,
      searchText,
    ],
  );

  useEffect(() => {
    if (isLoadingBvs) {
      setIsLoading(true);
      return;
    }
    setNormalDatasetList(getFilteredBaseViews());
    setIsLoading(false);
  }, [getFilteredBaseViews, isLoadingBvs]);

  return { normalDatasetList, isLoading };
};

export default useNormalDatasetsList;
