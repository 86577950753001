import React from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import usePopup from 'hooks/usePopup';
import Button from 'kingpin/atoms/Button';

import EntityForm from './EntityForm';

const CreateEntityButton = () => {
  const { isOpen, open, close } = usePopup();

  return (
    <>
      <Button
        onClick={open}
        label="Create Entity"
        type="Primary"
        size="Small"
      />
      <ModalTransition>
        {isOpen && (
          <Modal
            shouldScrollInViewport={false}
            autoFocus={false}
            onClose={close}
          >
            <EntityForm close={close} />
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};

export default CreateEntityButton;
