import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import PopupGridMetricPickerButton from './PopupGridMetricPickerButton';
import usePopup from '../../../hooks/usePopup';
import AnalyticsContext from '../../../contexts/AnalyticsContext';
import isEmptyFilter from '../../../isEmptyFilter';
import MetricOptionsContext from '../../../contexts/MetricOptionsContext';
import isDefined from '../../../isDefined';
import BaseViewsContext from '../../../contexts/BaseViewsContext';
import usePopupDateRangeOverride from '../usePopupDateRangeOverride';

const PopupGridMetricPickerButtonContainer = ({
  metricIds,
}: {
  metricIds: string[];
}) => {
  const { normalMetrics } = useContext(MetricOptionsContext);
  const [metrics, setMetrics] = useState<Metrics.NormalMetric[]>([]);
  const [selectedMetric, setSelectedMetric] = useState<
    Metrics.NormalMetric | undefined
  >();
  const dateScopeOverride = usePopupDateRangeOverride(selectedMetric);
  const { baseViews } = useContext(BaseViewsContext);
  const [options, setOptions] = useState<
    { label: string; metric: Metrics.NormalMetric }[]
  >([]);
  const {
    isOpen: isGridOpen,
    open: openGrid,
    close: closeGrid,
  } = usePopup(false, undefined, true);
  const { trackEvent } = useContext(AnalyticsContext);

  useEffect(() => {
    const dataTypes = _.uniq(metrics.map((m) => m.dataType));

    const newOptions = [] as {
      label: string;
      metric: Metrics.NormalMetric;
    }[];
    dataTypes.forEach((dt) => {
      const baseView = baseViews[dt];
      const dtLabel =
        baseView && baseView.nameAlias ? baseView.nameAlias : _.startCase(dt);
      const metricsForDt = metrics.filter((m) => m.dataType === dt);
      const metricsWithFilter = metricsForDt.filter(
        (m) => !isEmptyFilter(m.filters),
      );
      const metricsWithoutFilter = metricsForDt.filter((m) =>
        isEmptyFilter(m.filters),
      );
      newOptions.push(
        ...metricsWithFilter.map((m) => ({
          label: `${dtLabel} (${m.name})`,
          metric: m,
        })),
      );
      if (metricsWithoutFilter.length > 0) {
        newOptions.push({
          label: metricsWithoutFilter[0].name,
          metric: metricsWithoutFilter[0],
        });
      }
    });
    setOptions(newOptions);
  }, [baseViews, metrics]);

  useEffect(() => {
    const ms = metricIds
      .map((mId) => normalMetrics.find((m) => m.id === mId))
      .filter(isDefined);
    setMetrics(ms);
  }, [normalMetrics, metricIds]);

  const selectMetric = (m: Metrics.NormalMetric) => {
    setSelectedMetric(m);
    openGrid();
    trackEvent('Card - Popup Grid Opened');
  };

  const onCloseGrid = () => {
    setSelectedMetric(undefined);
    closeGrid();
  };

  return (
    <PopupGridMetricPickerButton
      options={options}
      isGridOpen={isGridOpen}
      closeGrid={onCloseGrid}
      metric={selectedMetric}
      selectMetric={selectMetric}
      dateScopeOverride={dateScopeOverride}
    />
  );
};

export default PopupGridMetricPickerButtonContainer;
