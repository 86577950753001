const TRUCK_MODELS = [
  'Volvo VNR 300',
  'Volvo VNR 400',
  'Volvo VNR 640',
  'Volvo VNL 300',
  'Volvo VNL 860',
  'Volvo VAH 300',
  'Volvo VAH 600 Flatroof Sleeper',
];

export default TRUCK_MODELS;
