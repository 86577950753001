import React from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';

import SpinnerTrackAndCart from './SpinnerTrackAndCart.svg';

const SpinnerDiv = styled.div<{ color: string }>`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  color: ${(props) => props.color};

  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }

  .track {
    position: absolute;
  }

  .spinner {
    position: absolute;
    animation: rotate 1.5s linear infinite;
  }
`;

const Spinner = ({ color }: { color: string }) => (
  <SpinnerDiv color={color}>
    <ReactSVG src={SpinnerTrackAndCart} className="spinner" />
  </SpinnerDiv>
);

export default Spinner;
