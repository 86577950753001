import styled from 'styled-components';
import Colors2 from '../../../theme/Colors2';

const GridRowBase = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const GridHeaderRow = styled(GridRowBase)`
  padding: 8px 16px;
  background-color: ${Colors2.Grey['9']};
  border-width: 1px 0px;
  border-style: solid;
  border-color: ${Colors2.Grey['8']};
`;
const GridRow = styled(GridRowBase)`
  padding: 16px;
  box-shadow: 0px -1px 0px 0px #00000014 inset;
  &:hover {
    background-color: #f7f7fb;
  }
`;
const Col = styled.div`
  flex: 1;
`;

const GlobalSearchStyles = {
  GridHeaderRow,
  GridRow,
  Col,
};

export default GlobalSearchStyles;
