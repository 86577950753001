import React from 'react';
import styled from 'styled-components';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import GridLayout, { Layout as LayoutProp } from 'react-grid-layout';

import Colors from 'theme/colors';
import ReportCanvasCard from '../ReportCanvasCard';
import { getLayout } from './buildLayout';
import RenderWhenInView from '../Comments/RenderWhenInView';
import ChartColorsProvider from '../../contextProviders/ChartColorsProvider';
import { CANVAS_ITEM_MARGIN, CANVAS_PADDING_VERTICAL } from './canvasConstants';

interface WrapperProps {
  isEditing: boolean;
  isMobile: boolean;
}
const Wrapper = styled.div<WrapperProps>`
  .react-grid-item.react-grid-placeholder {
    background: ${Colors.NAVY_BLUE} !important;
  }
  .react-grid-item {
    z-index: 1;
  }
`;

const ReportCanvas = ({
  cards,
  layout,
  onLayoutChange,
  removeGadget,
  isEditing,
  cardOnTopI,
  canvasMode,
  onResizeStart,
  onResizeStop,
  draggingI,
  isWindowResizing,
  canvasRowHeight,
  canvasWidth,
}: {
  cards: CanvasCard.Card[];
  layout: GridLayout.Layout[];
  onLayoutChange: (newLayout: GridLayout.Layout[]) => void;
  removeGadget: (i: string) => void;
  isEditing: boolean;
  cardOnTopI?: string;
  canvasMode: CanvasMode;
  onResizeStart: (
    layout: LayoutProp,
    oldItem: any,
    newItem: any,
    placeholder: any,
    e: MouseEvent,
    element: HTMLElement,
  ) => void;
  onResizeStop: () => void;
  draggingI?: string;
  isWindowResizing: boolean;
  canvasRowHeight: number;
  canvasWidth: number;
}) => (
  <div>
    <ChartColorsProvider>
      <Wrapper isEditing={isEditing} isMobile={canvasMode === 'mobile'}>
        <GridLayout
          width={canvasWidth}
          key={canvasMode}
          className="layout"
          layout={layout}
          cols={12}
          margin={[CANVAS_ITEM_MARGIN, CANVAS_ITEM_MARGIN]}
          containerPadding={[24, CANVAS_PADDING_VERTICAL]}
          rowHeight={canvasRowHeight}
          draggableHandle=".draggableHandle"
          onLayoutChange={onLayoutChange}
          useCSSTransforms={false}
          // @ts-ignore
          onResizeStart={onResizeStart}
          onResizeStop={onResizeStop}
        >
          {cards.map((c) => (
            <div
              style={{
                zIndex: getLayout(c, canvasMode).i === cardOnTopI ? 1 : 0,
                width: '100%',
                height: '100%',
              }}
              key={`${getLayout(c, canvasMode).i}`}
            >
              <div
                style={{
                  width: '100%',
                  height: '100%',
                }}
                key={`${getLayout(c, canvasMode).w}-${
                  getLayout(c, canvasMode).h
                }`}
              >
                <RenderWhenInView>
                  <ReportCanvasCard
                    card={c}
                    onRemoveClicked={() =>
                      removeGadget(getLayout(c, canvasMode).i)
                    }
                    isEditing={isEditing}
                    canvasMode={canvasMode}
                    isDragging={
                      (!!draggingI &&
                        draggingI === getLayout(c, canvasMode).i) ||
                      isWindowResizing
                    }
                  />
                </RenderWhenInView>
              </div>
            </div>
          ))}
        </GridLayout>
      </Wrapper>
    </ChartColorsProvider>
  </div>
);

export default ReportCanvas;
