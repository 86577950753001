import React from 'react';

interface MetricsInfoPopupContextType {
  vis?: ExtendedVisualisationDefinition | Metrics.Metric;
}

const MetricsInfoPopupContext =
  React.createContext<MetricsInfoPopupContextType>({});

export default MetricsInfoPopupContext;
