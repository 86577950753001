import Colors2 from '../../../../theme/Colors2';
import FlexCentered from '../../../../components/Common/FlexCentered';
import Typography from 'kingpin/atoms/Typography';
import Row from '../../../../components/Common/Row';
import DateInput from '../../../../components/DateInput';
import React from 'react';
import Loading from '../../../../components/Loading';

const MetricPreview = ({
  data,
  isLoading,
}: {
  data: string;
  isLoading: boolean;
}) => {
  return (
    <div
      style={{
        backgroundColor: Colors2.Grey['8'],
        padding: 16,
        height: 180,
        position: 'relative',
        borderRadius: 4,
      }}
    >
      <FlexCentered style={{ height: '100%' }}>
        {isLoading && <Loading />}
        {!isLoading && <Typography.Header type="H1">{data}</Typography.Header>}
      </FlexCentered>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          paddingTop: 8,
          paddingLeft: 16,
          paddingRight: 16,
        }}
      >
        <Row centerAlign spaceBetween>
          <Typography.Header type="H5">Preview</Typography.Header>
          <DateInput />
        </Row>
      </div>
    </div>
  );
};

export default MetricPreview;
