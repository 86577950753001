import React, { useCallback, useEffect, useState } from 'react';
import EntityDefinitionsContext from 'contexts/EntityDefinitionsContext';
import useEntityDefinitions from './useEntityDefinitions';
import useEntityDetailsApps from './useEntityDetailsApps';

interface EntityLookup {
  [field: string]:
    | {
        entity: EntityDetails.Entity;
        app: EntityDetails.App;
      }
    | undefined;
}

const EntityDefinitionsProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { entityDefinitions, isLoading: isLoadingEntityDefinitions } =
    useEntityDefinitions();
  const {
    entityDetailsApps,
    isLoading: isLoadingEntityDetailsApps,
    localUpdateOrAddApp,
  } = useEntityDetailsApps();

  const [entityLookup, setEntityLookup] = useState<EntityLookup>({});

  useEffect(() => {
    const newLookup: EntityLookup = {};
    entityDefinitions.forEach((def) => {
      const app = entityDetailsApps.find((a) => a.entityId === def.id);
      if (app) {
        newLookup[def.primaryField] = {
          entity: def,
          app,
        };
      }
    });
    setEntityLookup(newLookup);
  }, [entityDefinitions, entityDetailsApps]);

  const getEntityDefinitionFor = useCallback(
    (field: string) => {
      return entityLookup[field];
    },
    [entityLookup],
  );

  return (
    <EntityDefinitionsContext.Provider
      value={{
        entityDefinitions,
        getEntityDefinitionFor,
        entityDetailsApps,
        localUpdateOrAddApp,
        isLoading: isLoadingEntityDetailsApps || isLoadingEntityDefinitions,
      }}
    >
      {children}
    </EntityDefinitionsContext.Provider>
  );
};

export default EntityDefinitionsProvider;
