const isSpecialMetric = (
  m: Metrics.Metric | ExtendedVisualisationDefinition | undefined,
): m is Metrics.SpecialMetric => {
  if (!m) {
    return false;
  }

  return 'type' in m ? m.type === 'special' : false;
};

export default isSpecialMetric;
