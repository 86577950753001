import React, { useCallback, useContext, useState } from 'react';

import usePopup from '../../hooks/usePopup';
import WidgetGalleryContext from '../../contexts/WidgetGalleryContext';
import ReportMenu from './ReportMenu';
import ReportForm from '../ReportForm';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import AnalyticsContext from '../../contexts/AnalyticsContext';
import useOnValidationReportRemoved from './useOnValidationReportMoved';
import ReportContext from '../../contexts/ReportContext';

const ReportMenuContainer = ({
  startEditing,
  saveReportAs,
  onDelete,
}: {
  startEditing: () => void;
  saveReportAs: (name: string) => Promise<void>;
  onDelete: () => void;
}) => {
  const { report } = useContext(ReportContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const { onValidationReportRemoved, isValidationReport } =
    useOnValidationReportRemoved();

  const {
    isOpen: saveAsIsOpen,
    open: openSaveAs,
    close: closeSaveAs,
  } = usePopup();
  const {
    isOpen: isSettingsOpen,
    open: openSettings,
    close: closeSettings,
  } = usePopup();

  const { open: openWGallery } = useContext(WidgetGalleryContext);
  const [savingAs, setSavingAs] = useState(false);

  const onStartEditing = () => {
    startEditing();
  };

  const openWidgetGallery = useCallback(() => {
    openWGallery();
    trackEvent('Report - Show - Add Gadget Clicked');
  }, [openWGallery, trackEvent]);

  const onOpenSettings = () => {
    openSettings();
  };

  const onSaveAs = ({ name }: { name: string }) => {
    setSavingAs(true);
    saveReportAs(name).then(() => {
      setSavingAs(false);
      closeSaveAs();
    });
  };

  return (
    <React.Fragment>
      <ReportMenu
        onStartEditing={onStartEditing}
        openWidgetGallery={openWidgetGallery}
        onOpenSettings={onOpenSettings}
        onSaveAs={openSaveAs}
        onDelete={onDelete}
        onValidationReportRemoved={onValidationReportRemoved}
        isValidationReport={isValidationReport}
        report={report}
      />
      <ModalTransition>
        {isSettingsOpen && (
          <Modal>
            <ReportForm isOpen={isSettingsOpen} close={closeSettings} />
          </Modal>
        )}
      </ModalTransition>
      <ModalTransition>
        {saveAsIsOpen && (
          <Modal>
            <ReportForm
              close={closeSaveAs}
              isOpen={saveAsIsOpen}
              onSaveAs={onSaveAs}
              isSaveAs
              isSavingAs={savingAs}
            />
          </Modal>
        )}
      </ModalTransition>
    </React.Fragment>
  );
};

export default ReportMenuContainer;
