import STORE from 'store';

const updatePreviousUploads = async (
  previousUpload: DataManager.PreviousUpload,
  accountId: string,
) => {
  await STORE.getPreviousUploadsRef({ accountId })
    .doc(previousUpload.id)
    .set(previousUpload);

  return previousUpload.id;
};

export default updatePreviousUploads;
