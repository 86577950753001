import React, { ChangeEvent, useContext, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/auth';

import WallBoardSetUp from './WallBoardSetUp';
import CloudFunctionClientContext from '../../contexts/CloudFunctionClientContext';

const WallBoardSetUpContainer = () => {
  const { api } = useContext(CloudFunctionClientContext);
  const [accessCode, setAccessCode] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onAccessCodeChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setAccessCode(event.target.value);
  };

  const onLogInClicked = () => {
    setIsLoading(true);
    api.post(`/auth/token`, { token: accessCode }).then((response) => {
      if (response.ok) {
        setIsLoading(false);
        // @ts-ignore
        const { customToken } = response.data;
        firebase
          .auth()
          .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
          .then(() => {
            return firebase.auth().signInWithCustomToken(customToken as string);
          })
          .then(() => {
            setIsLoading(false);
          });
      } else {
        setIsLoading(false);
        alert('Setup Failed. Has your code expired or already been used?');
      }
    });
  };

  return (
    <WallBoardSetUp
      accessCode={accessCode}
      onAccessCodeChange={onAccessCodeChanged}
      onLogInClicked={onLogInClicked}
      isLoading={isLoading}
    />
  );
};

export default WallBoardSetUpContainer;
