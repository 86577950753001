import styled from 'styled-components';

const WarningTextDiv = styled.div`
  margin-bottom: 16px;

  svg {
    fill: white !important;
  }
`;

export default WarningTextDiv;
