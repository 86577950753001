import React from 'react';
import Tooltip from '../Tooltip';
import styled from 'styled-components';

import Row from '../Common/Row';
import ReactQuill from 'react-quill';
import ShowOnMouseOverUnlessTour from '../ShowOnMouseOverUnlessTour';
import Inputs from '../Inputs';
import Typography from 'kingpin/atoms/Typography';
import FleetOpsStaffOnly from '../Common/FleetOpsStaffOnly';
import Button from 'kingpin/atoms/Button';
import { Z_INDEX } from '../../constants';

const Wrapper = styled.div<{
  isEditing: boolean;
  isCenterAlign: boolean;
}>`
  padding: 0px !important;
  display: flex;
  flex-direction: column;
  flex: 1;

  h1 {
    margin: 0px !important;
  }

  .showOnMouseOver {
    visibility: hidden;
  }

  &:hover {
    > .showOnMouseOver {
      visibility: visible;
    }
  }

  .ql-container.ql-snow {
    ${(props) => !props.isEditing && 'border: none;'}
  }

  ${(props) => props.isEditing && 'height: calc(100% - 40px);'}

  .ql-container {
    font-size: unset;
  }

  .ql-editor {
    padding: 0.6em 0.8em 0em 0.8em !important;
  }

  ${(props) =>
    props.isCenterAlign &&
    `
    .ql-editor {
      display: flex !important;
      align-items: center !important;
    }
  `}
`;

const toolbarOptions = [
  [{ header: 1 }, { header: 2 }],

  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  [{ color: [] }, { background: [] }],
  ['blockquote'],
  [{ list: 'ordered' }, { list: 'bullet' }],
];

const TextGadget = ({
  startEditing,
  text,
  isEditing,
  onChange,
  isCenterAlign,
  onAlignChanged,
  stopEditing,
  isEditingCanvas,
}: {
  startEditing: () => void;
  text: string;
  isEditing: boolean;
  onChange: (newText: string) => void;
  isCenterAlign: boolean;
  onAlignChanged: (newAlign: boolean) => void;
  stopEditing: () => void;
  isEditingCanvas: boolean;
}) => (
  <Wrapper isEditing={isEditing} isCenterAlign={isCenterAlign}>
    {!isEditing && !isEditingCanvas && (
      <ShowOnMouseOverUnlessTour>
        <Row
          style={{
            zIndex: Z_INDEX.TEXT_GADGET,
            position: 'absolute',
            right: 13,
            top: 5,
            justifyContent: 'flex-end',
          }}
        >
          <Tooltip content={'Edit'}>
            <div style={{ marginLeft: 8 }}>
              <Button
                onClick={startEditing}
                type="Secondary"
                size="Small"
                icon={'edit-filled'}
              />
            </div>
          </Tooltip>
        </Row>
      </ShowOnMouseOverUnlessTour>
    )}
    {isEditing && (
      <Row spaceBetween style={{ marginTop: 8, marginBottom: 8 }}>
        <FleetOpsStaffOnly>
          <div>
            <Typography.Body type="Label">Center align</Typography.Body>
            <Inputs.Checkbox
              isChecked={isCenterAlign}
              onToggled={onAlignChanged}
            />
          </div>
        </FleetOpsStaffOnly>
        <Button
          onClick={stopEditing}
          label="Done"
          type="Primary"
          size="Small"
        />
      </Row>
    )}
    <ReactQuill
      value={text}
      readOnly={!isEditing}
      onChange={onChange}
      modules={isEditing ? { toolbar: toolbarOptions } : { toolbar: false }}
      style={{ width: '100%' }}
    />
  </Wrapper>
);

export default TextGadget;
