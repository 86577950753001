import { ApisauceInstance } from 'apisauce';

const getUsedSeats = async (api: ApisauceInstance): Promise<number> => {
  const r = await api.get<{ usedSeats: number }>('/users/usedSeats');
  if (r.ok && r.data) {
    return r.data.usedSeats;
  }
  const e = new Error();
  e.name = 'getUsedSeats: failed to fetch';
  throw e;
};

export default getUsedSeats;
