import baseOptions from './baseOptions';
import base from './baseOptions';
import buildDrillDown from '../../../buildDrillDown';

const treeMapOptions = ({
  title,
  openMenu,
  groupField,
  fieldType,
  chartDef,
  fontSize,
  formatMetric,
}) => ({
  ...baseOptions(formatMetric),
  chart: {
    ...baseOptions(formatMetric).chart,
    type: 'treemap',
  },
  title: {
    ...baseOptions(formatMetric).title,
    text: title,
  },
  yAxis: [
    {
      ...baseOptions(formatMetric).yAxis[0],
      title: {
        enabled: false,
      },
    },
  ],
  plotOptions: {
    series: {
      allowPointSelect: true,
      borderRadius: 2,
      point: {
        events: {
          click: function (e) {
            const selectedIndex = e.point.x;
            const terms = e.point.series.data;
            const series = e.point.series.chart.series;
            if (openMenu) {
              const { metricIds } = this.series.userOptions;
              const selectedTerm = terms.find((t, index) => {
                return index === selectedIndex;
              });
              openMenu(
                metricIds,
                buildDrillDown(
                  groupField,
                  fieldType,
                  selectedTerm.name,
                  chartDef,
                ),
              );
            } else {
              // @ts-ignore
              const { onDrillDown } = this.series.userOptions;
              if (!onDrillDown) {
                alert('Coming soon...');
                return;
              }
              if (series.length > 1) {
                // multi series
                series.forEach((s) => {
                  s.data.forEach((d, dataIndex) => {
                    if (dataIndex === selectedIndex) {
                      onDrillDown(d.name, e.ctrlKey || e.metaKey);
                    }
                  });
                });
              } else {
                // single series
                terms.forEach((t, index) => {
                  if (index === selectedIndex) {
                    onDrillDown(t.name, e.ctrlKey || e.metaKey);
                  }
                });
              }
            }
          },
        },
      },
    },
    treemap: {
      layoutAlgorithm: 'squarified',
      dataLabels: {
        style: {
          fontWeight: 'normal',
          textOutline: '0px',
          fontSize,
        },
      },
    },
  },
  tooltip: {
    ...base(formatMetric).tooltip,
    pointFormatter: function () {
      const { metricId } = this.series.userOptions;
      const bucketName = this.name;
      const value = formatMetric({
        metricId,
        value: this.value,
      });

      return `<span style="color:{point.color}">\u25CF </span><b><span style="color: #878F9D;">${bucketName}</span><b> ${value}</b><br/>`;
    },
  },
});

export default treeMapOptions;
