import React, { useState } from 'react';
import ReportEditingButtons from './ReportEditingButtons';
import ReportForm from '../ReportForm';
import usePopup from '../../hooks/usePopup';

const ReportEditingButtonsContainer = ({
  onSave,
  onCancel,
  isEditing,
  saveEditAs,
}: {
  onSave: () => void;
  onCancel: () => void;
  isEditing: boolean;
  saveEditAs: (name: string) => Promise<any>;
}) => {
  const {
    isOpen: saveAsIsOpen,
    open: openSaveAs,
    close: closeSaveAs,
  } = usePopup();
  const [savingAs, setSavingAs] = useState(false);

  const onSaveAs = ({ name }: { name: string }) => {
    setSavingAs(true);
    saveEditAs(name).then(() => {
      setSavingAs(false);
      closeSaveAs();
    });
  };

  if (!isEditing) {
    return null;
  }

  return (
    <React.Fragment>
      <ReportEditingButtons
        onSave={onSave}
        onCancel={onCancel}
        onSaveAs={openSaveAs}
      />
      <ReportForm
        close={closeSaveAs}
        isOpen={saveAsIsOpen}
        onSaveAs={onSaveAs}
        isSaveAs
        isSavingAs={savingAs}
      />
    </React.Fragment>
  );
};

export default ReportEditingButtonsContainer;
