import { useEffect, useState } from 'react';
import { useDataTypesFromMetricIds } from '../../hooks/useDataTypesFromSeriesAndMetricListItems';

const useGaugeFormDataTypes = (selectedMetricId?: string) => {
  const [selectedIds, setSelectedIds] = useState<string[]>(
    selectedMetricId ? [selectedMetricId] : window.emptyArray,
  );
  const dataTypes = useDataTypesFromMetricIds(selectedIds);

  useEffect(() => {
    // Prevent looping
    setSelectedIds(selectedMetricId ? [selectedMetricId] : window.emptyArray);
  }, [selectedMetricId]);

  return dataTypes;
};

export default useGaugeFormDataTypes;
