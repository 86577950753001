import firebase from 'firebase/compat/app';

const getBonusPeriodsRef = (accountRef: firebase.firestore.DocumentReference) =>
  accountRef
    .collection('scoring')
    .doc('drivers')
    .collection('periods')
    .orderBy('startDate', 'desc');

export default getBonusPeriodsRef;
