import React from 'react';

import ErrorImg from '../images/error.png';
import Row from './Common/Row';
import Typography from 'kingpin/atoms/Typography';

const InputError = ({ text }: { text: string }) => (
  <Row centerAlign style={{ marginBottom: 4 }}>
    <img src={ErrorImg} style={{ marginRight: 4 }} alt="error" />
    <Typography.Body type="Body 12">{text}</Typography.Body>
  </Row>
);

export default InputError;
