import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import ActivityGridContext from '../../context/ActivityGridContext';

const useShadows = () => {
  const { intervals, costScrollerRef } = useContext(ActivityGridContext);
  const isLoading = !intervals;

  const [isShadowLeftVisible, setIsShadowLeftVisible] =
    useState<boolean>(false);
  const [isShadowRightVisible, setIsShadowRightVisible] =
    useState<boolean>(false);
  const [width, setWidth] = useState<number | undefined>(undefined);
  const [height, setHeight] = useState<number>(0);
  const gridRef = useRef<HTMLDivElement | null>(null);
  const [isGridRefSet, setIsGridRefSet] = useState<boolean>(false);

  const handleScrolling = useCallback(() => {
    const costScrollerElem = costScrollerRef.current;
    setIsShadowLeftVisible(
      !!costScrollerElem && costScrollerElem.scrollLeft !== 0,
    );
    setIsShadowRightVisible(
      !!costScrollerElem &&
        costScrollerElem.scrollLeft <
          costScrollerElem.scrollWidth - costScrollerElem.offsetWidth,
    );
  }, [costScrollerRef]);

  const handleResize = useCallback(() => {
    const costScrollerElem = costScrollerRef.current;
    if (!costScrollerElem || isLoading) {
      return;
    }
    setWidth(costScrollerElem.scrollWidth);

    costScrollerElem.scrollLeft = costScrollerElem.scrollWidth;
  }, [costScrollerRef, isLoading]);

  useEffect(() => {
    const costScrollerElem = costScrollerRef.current;
    if (!costScrollerElem || isLoading) {
      return;
    }
    handleResize();
    costScrollerElem.addEventListener('scroll', handleScrolling);
    window.addEventListener('resize', handleResize);
    return () => {
      costScrollerElem.removeEventListener('scroll', handleScrolling);
      window.removeEventListener('resize', handleResize);
    };
  }, [costScrollerRef, handleResize, handleScrolling, isLoading]);

  const measuredRef = useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
      gridRef.current = node;
      setIsGridRefSet(true);
    }
  }, []);

  useEffect(() => {
    const node = gridRef.current;
    if (!node || process.env.NODE_ENV === 'test') {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      setHeight(node.getBoundingClientRect().height);
    });

    resizeObserver.observe(node);

    return () => {
      resizeObserver.disconnect();
    };
  }, [gridRef, isGridRefSet]);

  return {
    isShadowLeftVisible,
    isShadowRightVisible,
    height,
    width,
    costScrollerRef,
    gridRef: measuredRef,
  };
};

export default useShadows;
