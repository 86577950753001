import { useEffect, useState } from 'react';
import getSampleFile from '../../api/getSampleFile';

const useGetSampleFile = () => {
  const [fileSrc, setFileSrc] = useState<string>();

  useEffect(() => {
    let isActive = true;

    getSampleFile().then((data) => {
      if (!isActive) {
        return;
      }
      setFileSrc(data);
    });

    return () => {
      isActive = false;
    };
  }, []);

  return fileSrc;
};

export default useGetSampleFile;
