export const YESTERDAY = {
  type: 'last' as 'last',
  n: 1,
  interval: 'day' as 'day',
  currentToDate: false,
};

export const LAST_MONTH = {
  type: 'last' as 'last',
  n: 1,
  interval: 'month' as 'month',
  currentToDate: false,
};

export const YEAR_TO_DATE = {
  type: 'current' as 'current',
  interval: 'year' as 'year',
  currentToDate: true,
};

export const CURRENT_QUARTER = {
  type: 'current' as 'current',
  interval: 'quarter' as 'quarter',
  currentToDate: true,
};

export const NEXT_WEEK = {
  type: 'current' as 'current',
  interval: 'week' as 'week',
  currentToDate: false,
  n: 1,
};

export const LAST_180_DAYS = {
  type: 'last' as 'last',
  interval: 'day' as 'day',
  currentToDate: false,
  n: 180,
};

export const LAST_13_WEEKS = {
  type: 'last' as 'last',
  interval: 'week' as 'week',
  currentToDate: false,
  n: 13,
};

export const LAST_3_YEARS_TO_DATE = {
  type: 'last' as 'last',
  interval: 'year' as 'year',
  currentToDate: true,
  n: 3,
};

export const LAST_365_DAYS_INCLUDING_TODAY = {
  type: 'last' as 'last',
  interval: 'day' as 'day',
  currentToDate: true,
  n: 365,
};

export const LAST_30_DAYS = {
  type: 'last' as 'last',
  interval: 'day' as 'day',
  currentToDate: false,
  n: 30,
};

export const LAST_30_DAYS_INCLUDING_TODAY = {
  type: 'last' as 'last',
  interval: 'day' as 'day',
  currentToDate: true,
  n: 30,
};

export const MAX_WIDTH_CURRENT_DATE_RANGE = 288;
export const MAX_WIDTH_NON_CURRENT_DATE_RANGE = 428;
export const MAX_WIDTH_CALENDAR_INPUT = 342;
