import React from 'react';
import Group from '../Group';
import Typography from '../../../kingpin/atoms/Typography';
import Inputs from '../../Inputs';

const StackedBarOptionsInput = ({
  directionOptions,
  asPercentage,
  onAsPercentageChanged,
  altPivot,
  onAltPivotChanged,
}: {
  directionOptions: RadioOption[];
  asPercentage: boolean;
  onAsPercentageChanged: (newValue: boolean) => void;
  altPivot: boolean;
  onAltPivotChanged: (newValue: boolean) => void;
}) => {
  return (
    <Group title={'Stacked Bar Options'}>
      <div style={{ marginBottom: 16 }}>
        <div style={{ marginBottom: 4 }}>
          <Typography.Body type="Label">Direction</Typography.Body>
        </div>
        <Inputs.Radio options={directionOptions} />
      </div>
      <div style={{ marginBottom: 16 }}>
        <div style={{ marginBottom: 4 }}>
          <Typography.Body type="Label">As Percentage</Typography.Body>
        </div>
        <Inputs.Toggle value={asPercentage} onChange={onAsPercentageChanged} />
      </div>
      <div>
        <div style={{ marginBottom: 4 }}>
          <Typography.Body type="Label">Alt pivot</Typography.Body>
        </div>
        <Inputs.Toggle value={altPivot} onChange={onAltPivotChanged} />
      </div>
    </Group>
  );
};

export default StackedBarOptionsInput;
