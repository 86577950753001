import styled from 'styled-components';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import LocalTasksContext from '../../contexts/Tasks/LocalTasksContext';
import WorkSpaceContext from '../../contexts/WorkSpaceContext';
import AnalyticsContext from '../../contexts/AnalyticsContext';
import LocalTimelineContext from '../../contexts/Timeline/LocalTimelineContext';
import Icon from '../../kingpin/atoms/Icon';

const IsDoneCircle = styled.div`
  height: 20px;
  width: 20px;
  background-color: #6fcd88;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NotDoneCircle = styled.div`
  box-sizing: border-box;
  height: 20px;
  width: 20px;
  border: 1px solid #c3c3c3;
  background-color: #ffffff;
  cursor: pointer;
  border-radius: 10px;
`;

const DoneCircle = ({ task }: { task: Tasks.Task }) => {
  const [isDone, setIsDone] = useState<boolean>(task.isComplete);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { addEvent } = useContext(LocalTimelineContext);
  const { onTaskCompletedToggled } = useContext(LocalTasksContext);
  const { workSpace } = useContext(WorkSpaceContext);
  const { trackEvent } = useContext(AnalyticsContext);

  useEffect(() => {
    setIsDone(task.isComplete);
  }, [task.isComplete]);

  const createToggledTimelineEvent = useCallback(async () => {
    if (!addEvent) {
      return;
    }

    if (task.isComplete) {
      await addEvent({
        actionText: 're-opened a task',
        contextText: task.title,
      });
    } else {
      await addEvent({
        actionText: 'completed a task',
        contextText: task.title,
      });
    }
  }, [addEvent, task.isComplete, task.title]);

  const onClick = useCallback(() => {
    setIsLoading(true);
    onTaskCompletedToggled(task).then(() => {
      createToggledTimelineEvent().then(() => {
        const workSpaceType = workSpace ? workSpace.campaignType : undefined;
        if (task.isComplete) {
          trackEvent('Task - Marked as not done', {
            taskType: task.taskableType,
            workSpaceType,
          });
        } else {
          trackEvent('Task - Marked as done', {
            taskType: task.taskableType,
            workSpaceType,
          });
        }
        setIsDone(!task.isComplete);
        setIsLoading(false);
      });
    });
  }, [
    createToggledTimelineEvent,
    onTaskCompletedToggled,
    task,
    trackEvent,
    workSpace,
  ]);

  if (isDone) {
    return (
      <IsDoneCircle
        onClick={isLoading ? undefined : onClick}
        data-testid="uncomplete-task"
      >
        <Icon icon="checkmark" color="#FFFFFF" />
      </IsDoneCircle>
    );
  } else {
    return (
      <NotDoneCircle
        onClick={isLoading ? undefined : onClick}
        data-testid="complete-task"
      />
    );
  }
};

export default DoneCircle;
