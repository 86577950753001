import { useCallback, useContext, useEffect, useState } from 'react';
import CurrentUserContext from '../../contexts/CurrentUserContext';
import useRecents from '../useRecents';
import 'firebase/compat/firestore';
import buildContentViewId, {
  buildContentViewIdAlt,
} from '../../buildContentViewId';
import trackRecent from './trackRecent';
import trackLastViewedAt from './trackLastViewedAt';
import FavouritesContext from '../../contexts/FavouritesContext';
import WallboardContext from '../../contexts/WallboardContext';
import AccountPickerContext from '../../contexts/AccountPickerContext';
import firebase from 'firebase/compat';
import useLockedDebouncedEffect from '../useLockedDebouncedEffect';

const useBaseShouldTrack = ({
  type,
  typeId,
  name,
}: {
  type:
    | 'report'
    | 'dashboard'
    | 'wallboard'
    | 'workspace'
    | 'board'
    | 'scorecard'
    | 'targetsApp';
  typeId?: string;
  name?: string;
}) => {
  const { isWallboardUser } = useContext(CurrentUserContext);
  const { isWallboard } = useContext(WallboardContext);
  const { isLoading: isLoadingRecents } = useRecents();
  const { isLoading: isLoadingFavourites } = useContext(FavouritesContext);

  const getBaseShouldTrack = useCallback(() => {
    if (
      isWallboardUser ||
      !typeId ||
      !name ||
      isLoadingRecents ||
      isLoadingFavourites ||
      isWallboard
    ) {
      return false;
    }

    if (
      window.location.href.toLowerCase().includes('/workspaces/') &&
      type !== 'workspace'
    ) {
      if (type !== 'board') {
        return false;
      }
    }

    if (
      window.location.href.toLowerCase().includes('/wallboard/') &&
      type !== 'wallboard'
    ) {
      return false;
    }

    if (
      window.location.href.toLowerCase().includes('/wallBoardConfig/') &&
      type !== 'wallboard'
    ) {
      return false;
    }

    if (
      window.location.href.toLowerCase().includes('/dashboards/') &&
      type === 'report'
    ) {
      return false;
    }

    if (
      window.location.href.toLowerCase().includes('/kpi-lists/') &&
      (type === 'report' || type === 'board')
    ) {
      return false;
    }

    if (
      window.location.href.toLowerCase().includes('/target-apps/') &&
      type !== 'targetsApp'
    ) {
      return false;
    }

    if (window.location.href.toLowerCase().includes('/entities/')) {
      return false;
    }

    return true;
  }, [
    isLoadingFavourites,
    isLoadingRecents,
    isWallboard,
    isWallboardUser,
    name,
    type,
    typeId,
  ]);

  const [baseShouldTrack, setBaseShouldTrack] = useState<boolean>(() =>
    getBaseShouldTrack(),
  );

  useEffect(() => {
    setBaseShouldTrack(getBaseShouldTrack());
  }, [getBaseShouldTrack]);

  return baseShouldTrack;
};

interface TrackRecentArgs {
  userId: string;
  type:
    | 'report'
    | 'dashboard'
    | 'wallboard'
    | 'workspace'
    | 'board'
    | 'scorecard'
    | 'targetsApp';
  name?: string;
  typeId: string;
  accountRef: firebase.firestore.DocumentReference;
}

const useTrackRecent = ({
  type,
  typeId,
  name,
}: {
  type:
    | 'report'
    | 'dashboard'
    | 'wallboard'
    | 'workspace'
    | 'board'
    | 'scorecard'
    | 'targetsApp';
  typeId?: string;
  name?: string;
}) => {
  const baseShouldTrack = useBaseShouldTrack({
    type,
    name,
    typeId,
  });
  const [hasTrackedRecent, setHasTrackedRecent] = useState<boolean>(false);
  const { favourites } = useContext(FavouritesContext);
  const { id: userId } = useContext(CurrentUserContext);

  const { accountRef } = useContext(AccountPickerContext);
  const [args, setArgs] = useState<TrackRecentArgs>();
  useEffect(() => {
    if (!baseShouldTrack || hasTrackedRecent || !typeId) {
      setArgs(undefined);
      return;
    }

    if (
      favourites.some(
        (f) =>
          buildContentViewId(f) === buildContentViewIdAlt({ type, typeId }),
      )
    ) {
      // Do not track favourites
      setArgs(undefined);
      return;
    }

    setArgs({
      userId,
      type,
      typeId,
      accountRef,
      name,
    });
  }, [
    accountRef,
    baseShouldTrack,
    favourites,
    hasTrackedRecent,
    name,
    type,
    typeId,
    userId,
  ]);

  const responseHandler = useCallback(() => {
    setHasTrackedRecent(true);
  }, []);

  useLockedDebouncedEffect({
    args,
    callback: trackRecent,
    responseHandler,
  });
};

const useTrackLastViewed = ({
  type,
  typeId,
  name,
}: {
  type:
    | 'report'
    | 'dashboard'
    | 'wallboard'
    | 'workspace'
    | 'board'
    | 'scorecard'
    | 'targetsApp';
  typeId?: string;
  name?: string;
}) => {
  const baseShouldTrack = useBaseShouldTrack({
    type,
    name,
    typeId,
  });
  const [hasTrackedLastViewed, setHasTrackedLastViewed] =
    useState<boolean>(false);
  const { favourites } = useContext(FavouritesContext);
  const { id: userId } = useContext(CurrentUserContext);

  const { accountRef } = useContext(AccountPickerContext);
  const [args, setArgs] = useState<TrackRecentArgs>();
  useEffect(() => {
    if (!baseShouldTrack || hasTrackedLastViewed || !typeId) {
      setArgs(undefined);
      return;
    }

    setArgs({
      userId,
      type,
      typeId,
      accountRef,
      name,
    });
  }, [
    accountRef,
    baseShouldTrack,
    favourites,
    hasTrackedLastViewed,
    name,
    type,
    typeId,
    userId,
  ]);

  const responseHandler = useCallback(() => {
    setHasTrackedLastViewed(true);
  }, []);

  useLockedDebouncedEffect({
    args,
    callback: trackLastViewedAt,
    responseHandler,
  });
};

const useContentViewTracking = ({
  type,
  typeId,
  name,
}: {
  type:
    | 'report'
    | 'dashboard'
    | 'wallboard'
    | 'workspace'
    | 'board'
    | 'scorecard'
    | 'targetsApp';
  typeId?: string;
  name?: string;
}) => {
  useTrackLastViewed({ typeId, type, name });
  useTrackRecent({ typeId, type, name });
};

export default useContentViewTracking;
