import React, { useCallback, useContext, useEffect, useState } from 'react';

import Form from 'kingpin/forms/Form';
import FormHeader from 'kingpin/forms/FormHeader';
import FormContent from 'kingpin/forms/FormContent';
import Button from 'kingpin/atoms/Button';
import TextInput from 'kingpin/atoms/TextInput';
import Typography from 'kingpin/atoms/Typography';
import Row from '../../../Common/Row';
import Dropdown from '../../../Inputs/Dropdown';
import TargetFormContext from '../../contexts/TargetFormContext';
import useValidateFieldName from './useValidateFieldName';
import TargetListContext from '../../contexts/TargetListContext';

const AddFieldForm = ({ close }: { close: () => void }) => {
  const [fieldName, setFieldName] = useState<string>('');
  const [fieldType, setFieldType] = useState<'text' | 'number'>('text');
  const [typeOptions, setTypeOptions] = useState<DropdownOption[]>([]);
  const { setOutputs } = useContext(TargetFormContext);
  const { selectedField } = useContext(TargetListContext);
  const [isShowingValidationFeedback, setIsShowingValidationFeedback] =
    useState<boolean>(false);
  const isValid = useValidateFieldName(fieldName);

  useEffect(() => {
    setTypeOptions([
      {
        label: 'Numeric',
        value: 'number',
        isSelected: fieldType === 'number',
        onSelected: () => {
          setFieldType('number');
        },
      },
      {
        label: 'Text',
        value: 'text',
        isSelected: fieldType === 'text',
        onSelected: () => {
          setFieldType('text');
        },
      },
    ]);
  }, [fieldType]);

  const onFieldNameChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFieldName(event.target.value);
    },
    [],
  );

  const onAddClicked = useCallback(() => {
    if (!isValid) {
      setIsShowingValidationFeedback(true);
      return;
    }

    setOutputs((current) => [...current, { fieldName, fieldType }]);
    close();
  }, [close, fieldName, fieldType, isValid, setOutputs]);

  return (
    <Form>
      <FormHeader title={'New Field'} onClose={close} />
      <FormContent>
        <div>
          <div style={{ marginBottom: 16 }}>
            <div style={{ marginBottom: 8 }}>
              <Typography.Body type={'Label'}>Field Name</Typography.Body>
            </div>
            <TextInput
              data-testid={'field-name-input'}
              autoFocus
              value={fieldName}
              onChange={onFieldNameChanged}
              errorMessage={
                isShowingValidationFeedback && !isValid
                  ? `Field names must be camelCase, unique, have no special characters and be prefixed with "${selectedField}"`
                  : undefined
              }
              state={
                isShowingValidationFeedback && !isValid ? 'Error' : undefined
              }
            />
          </div>

          <div style={{ marginBottom: 16 }}>
            <div style={{ marginBottom: 8 }}>
              <Typography.Body type={'Label'}>Field Type</Typography.Body>
            </div>
            <Dropdown options={typeOptions} />
          </div>
        </div>
      </FormContent>
      <Row style={{ justifyContent: 'space-between' }} centerAlign>
        <Button
          size={'Medium'}
          type={'Secondary'}
          label={'Back'}
          onClick={close}
        />
        <Button
          size={'Medium'}
          type={'Primary'}
          label={'Add Field'}
          testId="add-field-save"
          onClick={onAddClicked}
        />
      </Row>
    </Form>
  );
};

export default AddFieldForm;
