import { useCallback } from 'react';
import isDefined from '../../../isDefined';
import useValueFormatters from '../../../hooks/useValueFormatters';

const useBuildResultMatch = () => {
  const { formatField } = useValueFormatters();

  const useBuildResultMatch = useCallback(
    ({
      response,
      entity,
      searchArgs,
    }: {
      response: GlobalSearch.AggregationBucket;
      entity: EntityDetails.Entity;
      searchArgs: GlobalSearch.SearchArgs;
    }): GlobalSearch.ResultMatch | undefined => {
      const { secondaryMetrics, contextMetrics } = searchArgs;
      const primaryFieldValue = response[entity.primaryField];
      if (
        primaryFieldValue === undefined ||
        typeof primaryFieldValue !== 'string'
      ) {
        return undefined;
      }
      const secondaryFields = secondaryMetrics
        .map((m) => {
          const field = m.field;
          const value = response[m.id];
          if (!value) {
            return {
              field,
              value: '-',
              formattedValue: '-',
            };
          }

          return {
            field,
            value,
            formattedValue: formatField({
              field,
              dataset: entity.entityDataset,
              value,
            }),
          };
        })
        .filter(isDefined);
      const contextFields = contextMetrics
        .map((m) => {
          const field = m.field;
          const value = response[m.id];
          if (!value) {
            return {
              field,
              value: '-',
              formattedValue: '-',
            };
          }

          return {
            field,
            value,
            formattedValue: formatField({
              field,
              dataset: entity.entityDataset,
              value,
            }),
          };
        })
        .filter(isDefined);
      return {
        primaryFieldValue,
        secondaryFields,
        contextFields,
      };
    },
    [formatField],
  );

  return useBuildResultMatch;
};

export default useBuildResultMatch;
