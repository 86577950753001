import Typography from 'kingpin/atoms/Typography';
import React from 'react';

const NoGroupsFoundMessage = ({ searchText }: { searchText: string }) => (
  <div>
    <div
      style={{
        boxShadow: '0px -1px 0px 0px #00000014 inset',
        marginBottom: 8,
        paddingBottom: 8,
      }}
    >
      <Typography.Body type="Body 12">
        {`We cannot find a match for "${searchText}"`}
      </Typography.Body>
    </div>
    <div>
      <Typography.Header type="H5">Please Try:</Typography.Header>
      <ul style={{ paddingLeft: 16 }}>
        <li>
          <Typography.Body type="Body 12">
            Make sure you are entering the correct Group ID from azure
          </Typography.Body>
        </li>
        <li>
          <Typography.Body type="Body 12">
            Try with an alternative group
          </Typography.Body>
        </li>
      </ul>
    </div>
  </div>
);

export default NoGroupsFoundMessage;
