import React from 'react';
import Button from 'kingpin/atoms/Button';
import Card from '../Common/Card';
import Branding from '../Branding';
import Typography from 'kingpin/atoms/Typography';
import Row from '../Common/Row';
import styled from 'styled-components';
import Colors from '../../theme/colors';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .iycCLd {
    background-color: ${Colors.WHITE} !important;
  }
`;

const InvalidCode = ({
  isOnBoardingFlow,
  goBack,
}: {
  isOnBoardingFlow: boolean;
  goBack: () => void;
}) => (
  <React.Fragment>
    <Branding />
    <Card style={{ width: 466, padding: '31px 44px 31px 32px' }}>
      <Layout style={{ marginBottom: 24 }}>
        {isOnBoardingFlow && (
          <Typography.Header type="H4">Sign up</Typography.Header>
        )}
        {!isOnBoardingFlow && (
          <Typography.Header type="H4">Reset password</Typography.Header>
        )}
      </Layout>
      <Typography.Body type="Body 12">
        {isOnBoardingFlow
          ? 'Your welcome link is no longer valid'
          : 'Your reset link is no longer valid'}
      </Typography.Body>
      <Typography.Body type="Body 12">
        Try sending a new reset email
      </Typography.Body>
      <Row style={{ justifyContent: 'flex-end' }}>
        <Button onClick={goBack} label="Go back" type="Primary" size="Small" />
      </Row>
    </Card>
  </React.Fragment>
);

export default InvalidCode;
