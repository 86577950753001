import React from 'react';
import Commentss from '../../../Comments';
import Typography from 'kingpin/atoms/Typography';

const Comments = ({
  commitmentId,
  linkTo,
  watcherEmailBody,
  mentionedEmailBody,
}: {
  commitmentId: string;
  linkTo: string;
  watcherEmailBody: string;
  mentionedEmailBody: string;
}) => (
  <div>
    <div style={{ marginBottom: 24 }}>
      <Typography.Header type={'H5'}>Notes</Typography.Header>
    </div>
    <Commentss
      commentableId={commitmentId}
      commentableType="Lane"
      linkTo={linkTo}
      emailLink={window.location.pathname}
      watcherEmailBody={watcherEmailBody}
      mentionedEmailBody={mentionedEmailBody}
    />
  </div>
);

export default Comments;
