import React, { useContext } from 'react';
import styled from 'styled-components';
import Row from '../../../components/Common/Row';
import Inputs from '../../../components/Inputs';
import MetricFilteringContext from '../../../contexts/MetricFilteringContext';
import Button from 'kingpin/atoms/Button';
import DownloadMetricsReport from './DownloadMetricsReport';
import TextInput from '../../../kingpin/atoms/TextInput';

const FilterItem = styled.div`
  margin-right: 16px;
  min-width: 160px;
`;

const MetricFiltersBar = ({ mode }: { mode: 'Metric' | 'CompoundMetric' }) => {
  const {
    searchText,
    onSearchTextChanged,
    statusOptions,
    datasetOptions,
    usageOptions,
    sortOptions,
    isDatasetFilterDisabled,
    onClearDatasetsClicked,
    onClearStatusClicked,
    onClearUsageClicked,
    onClearFiltersClicked,
    isClearButtonVisible,
  } = useContext(MetricFilteringContext);

  return (
    <Row style={{ marginBottom: 16 }} spaceBetween>
      <Row>
        <FilterItem>
          <TextInput
            value={searchText}
            onChange={onSearchTextChanged}
            icon="search"
            inputSize="Small"
            placeholder="Search"
            data-testid="search"
          />
        </FilterItem>
        <FilterItem>
          <Inputs.Dropdown
            options={statusOptions}
            placeholder="Core & Other metrics"
            unsetWidth
            onClearClicked={onClearStatusClicked}
          />
        </FilterItem>
        <FilterItem>
          <Inputs.Dropdown
            options={datasetOptions}
            placeholder="All Datasets"
            unsetWidth
            isDisabled={isDatasetFilterDisabled}
            onClearClicked={onClearDatasetsClicked}
          />
        </FilterItem>
        <FilterItem>
          <Inputs.Dropdown
            options={usageOptions}
            placeholder="Usage: Any"
            unsetWidth
            onClearClicked={onClearUsageClicked}
          />
        </FilterItem>
        {isClearButtonVisible && (
          <Button
            label="Clear Filters"
            onClick={onClearFiltersClicked}
            type="Ghost"
            size="Small"
          />
        )}
      </Row>
      <Row centerAlign>
        <FilterItem>
          <Inputs.Dropdown
            options={sortOptions}
            placeholder="Sort By"
            unsetWidth
            label={'Sort By:'}
          />
        </FilterItem>
        <DownloadMetricsReport mode={mode} />
      </Row>
    </Row>
  );
};

export default MetricFiltersBar;
