import React, { useCallback, useContext, useEffect, useState } from 'react';
import EntityDefinitionsContext from 'contexts/EntityDefinitionsContext';

const useEntityList = () => {
  const { entityDefinitions, isLoading } = useContext(EntityDefinitionsContext);
  const [items, setItems] = useState<EntityDetails.Entity[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const onSearchTextChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
    },
    [],
  );

  useEffect(() => {
    if (isLoading) {
      setItems([]);
      return;
    }

    setItems(
      entityDefinitions.filter(
        (e) =>
          e.name.toLowerCase().includes(searchText.toLowerCase()) ||
          e.primaryField.toLowerCase().includes(searchText.toLowerCase()),
      ),
    );
  }, [entityDefinitions, isLoading, searchText]);

  return {
    searchText,
    onSearchTextChanged,
    items,
    isLoading,
  };
};

export default useEntityList;
