import React from 'react';
import useUser from '../hooks/useUser';
import Row from './Common/Row';
import UserIcon from './Common/UserIcon';
import Typography from 'kingpin/atoms/Typography';
import getUserDisplayName from '../contextProviders/UsersProvider/getUserDisplayName';

const UserFromId = ({ userId }: { userId: string }) => {
  const { user } = useUser(userId);
  if (!user) {
    return <div />;
  }

  return (
    <Row centerAlign>
      <div style={{ marginRight: '6px' }}>
        <UserIcon {...user} noMargin />
      </div>
      <Typography.Body type="Body 12">
        {getUserDisplayName(user)}
      </Typography.Body>
    </Row>
  );
};

export default UserFromId;
