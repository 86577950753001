import React from 'react';

export interface FavouritesContextType {
  favourites: ContentView[];
  setFavourites: React.Dispatch<React.SetStateAction<ContentView[]>>;
  favouritesOrder: string[];
  isLoading: boolean;
  getIsFavourite: (
    type:
      | 'report'
      | 'dashboard'
      | 'wallboard'
      | 'workspace'
      | 'scorecard'
      | 'targetsApp',
    typeId: string,
  ) => boolean;
  onFavouriteToggled: (
    type:
      | 'report'
      | 'dashboard'
      | 'wallboard'
      | 'workspace'
      | 'scorecard'
      | 'targetsApp',
    typeId: string,
    name: string,
  ) => void;
  openSlideout: () => void;
  closeSlideout: () => void;
  isOpenSlideout: boolean;
}

const FavouritesContext = React.createContext<FavouritesContextType>({
  favourites: [],
  setFavourites: () => {},
  favouritesOrder: [],
  isLoading: true,
  getIsFavourite: () => false,
  onFavouriteToggled: () => false,
  openSlideout: () => {},
  closeSlideout: () => {},
  isOpenSlideout: false,
});

export default FavouritesContext;
