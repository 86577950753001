import React from 'react';

interface SingleMetricMatrixContextType {
  isAllDataLoaded: boolean;
  onShowAllPressed: () => void;
  isLoadingAll: boolean;
  currentItems: number;
  totalItems: number;
  orderedBy: string;
  isAllDataInitiallyAvailable: boolean;
  chartDef?: VisualisationDefinitions.SingleMetricDateMatrix;
}

const SingleMetricMatrixContext =
  React.createContext<SingleMetricMatrixContextType>({
    isAllDataLoaded: true,
    onShowAllPressed: () => {},
    isLoadingAll: false,
    currentItems: 0,
    totalItems: 0,
    orderedBy: 'Metric',
    isAllDataInitiallyAvailable: true,
  });

export default SingleMetricMatrixContext;
