import { useEffect } from 'react';

const DELAY = 300;

/**
 * An effect for managing asynchronous side effects
 * It will ensure:
 * - The callback will be debounced
 * - No race conditions will occur
 *
 * This is useful for networking which dependent on many sources
 * of filtering, which will be built over the course of a number of renders
 */
const useLockedDebouncedEffect = <ArgsType, ResponseType>({
  args,
  callback,
  responseHandler,
}: {
  args: ArgsType | undefined;
  callback: (args: ArgsType) => Promise<ResponseType>;
  responseHandler: (response: ResponseType) => void;
}) => {
  useEffect(() => {
    if (args === undefined) {
      return;
    }

    let isActive = true;
    const timeoutId = setTimeout(async () => {
      if (!isActive) {
        return;
      }
      const response = await callback(args);
      if (!isActive) {
        return;
      }
      responseHandler(response);
    }, DELAY);

    return () => {
      isActive = false;
      clearTimeout(timeoutId);
    };
  }, [args, callback, responseHandler]);
};

export default useLockedDebouncedEffect;
