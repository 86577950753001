import { buildDateInputFromTerm } from './hooks/useChartDrillDowns/buildOnDateDrillDown';
import isDefined from './isDefined';

const DEFAULT_ID = 'na';

const buildDrillDown = (
  field: string,
  fieldType: FleetOps.FieldType,
  term: string,
  chartDef: VisualisationDefinition,
  autoInterval?: AutoInterval,
): ReportDrillDownType | undefined => {
  if (fieldType === 'text') {
    const newTextDrillDown: TextDrillDown = {
      id: DEFAULT_ID,
      field,
      mode: 'plate',
      fieldType,
      keywordValues: [term],
    };
    return newTextDrillDown;
  }

  if (fieldType === 'dateText' || fieldType === 'date') {
    const newDateDrillDown: DateDrillDown = {
      id: DEFAULT_ID,
      field,
      mode: 'plate',
      fieldType,
      dateRangeValues: [
        buildDateInputFromTerm(chartDef, term, field, autoInterval),
      ].filter(isDefined),
    };
    return newDateDrillDown;
  }

  if (fieldType === 'float' || fieldType === 'long') {
    const newNumberDrillDown: NumberDrillDown = {
      id: DEFAULT_ID,
      field,
      mode: 'plate',
      fieldType,
    };
    return newNumberDrillDown;
  }

  if (fieldType === 'boolean') {
    const newBooleanDrillDown: BooleanDrillDown = {
      id: DEFAULT_ID,
      field,
      mode: 'plate',
      fieldType,
      booleanValue: term === 'true',
    };
    return newBooleanDrillDown;
  }
};

export default buildDrillDown;
