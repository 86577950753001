import React from 'react';
import Button from 'kingpin/atoms/Button';

import Branding from '../Branding';
import Card from '../Common/Card';
import Typography from 'kingpin/atoms/Typography';
import Inputs from '../Inputs';

const CantSignInForm = ({
  email,
  onEmailChanged,
  isLoading,
  onSubmit,
}: {
  email: string;
  onEmailChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isLoading: boolean;
  onSubmit: () => void;
}) => (
  <React.Fragment>
    <Branding />
    <Card style={{ width: 466, padding: '24px 40px' }}>
      <div style={{ marginBottom: 16 }}>
        <Typography.Header type="H4">{'Reset your password'}</Typography.Header>
        <Typography.Body type="Body 12">
          Please fill out this form and we will send you an email with a
          recovery link
        </Typography.Body>
      </div>
      <div>
        <div style={{ marginBottom: 32 }}>
          <Typography.Body type="Label">Email</Typography.Body>
          <Inputs.TextInput value={email} onChange={onEmailChanged} />
        </div>
        <Button
          onClick={onSubmit}
          isLoading={isLoading}
          type="Primary"
          size="Small"
          label="Send recovery link"
        />
      </div>
    </Card>
  </React.Fragment>
);

export default CantSignInForm;
