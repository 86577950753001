import React, { ChangeEvent, useEffect, useState } from 'react';
import aguid from 'aguid';

import DimensionRangeInput from './DimensionRangeInput';
import isCustomRangeInput from '../../../isCustomRangeInput';
import toSentenceCase from '../../../services/toSentenceCase';

const DimensionRangeInputContainer = ({
  input,
  setInput,
}: {
  input?: CustomDimensionRangeInput | FixedDimensionRangeInput;
  setInput: (i: CustomDimensionRangeInput | FixedDimensionRangeInput) => void;
}) => {
  const [mode, setMode] = useState<'fixed' | 'custom'>(
    input ? (isCustomRangeInput(input) ? 'custom' : 'fixed') : 'fixed',
  );
  const [items, setItems] = useState<CustomDimensionInputItem[]>(
    input ? (isCustomRangeInput(input) ? input.items : []) : [],
  );
  const [fixed, setFixed] = useState<number | undefined>(
    input ? (isCustomRangeInput(input) ? undefined : input.fixed) : 100,
  );

  const onAddItem = () => {
    setItems((i) => [...i, { id: aguid(), label: '' }]);
  };

  useEffect(() => {
    if (mode === 'fixed') {
      setInput({
        mode,
        fixed: fixed || 0,
      });
    }
    if (mode === 'custom') {
      setInput({
        mode,
        items,
      });
    }
  }, [setInput, mode, items, fixed]);

  const onModeChanged = (newMode: string) => {
    if (newMode === 'fixed') {
      setMode('fixed');
      setItems([]);
    } else if (newMode === 'custom') {
      setMode('custom');
      setFixed(undefined);
    }
  };
  const [modeOptions, setModeOptions] = useState<RadioOption[]>([]);
  useEffect(() => {
    setModeOptions(
      ['fixed', 'custom'].map((m) => ({
        key: m,
        label: toSentenceCase(m),
        onSelected: () => onModeChanged(m),
        isSelected: m === mode,
      })),
    );
  }, [mode]);

  const onFixedChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setFixed(Number(event.target.value));
  };

  return (
    <DimensionRangeInput
      mode={mode}
      modeOptions={modeOptions}
      onAddItem={onAddItem}
      items={items}
      setItems={setItems}
      fixed={fixed}
      onFixedChanged={onFixedChanged}
    />
  );
};

export default DimensionRangeInputContainer;
