import styled from 'styled-components';

const CardRowSection = styled.div`
  flex: 1;
  margin-right: 11px;

  :last-of-type {
    margin-right: 0px;
  }
`;

export default CardRowSection;
