import React, { useCallback, useEffect, useState } from 'react';
import { ModalTransition } from '@atlaskit/modal-dialog';

import usePopup from '../../../hooks/usePopup';
import MetricPopup from '../../../screens/DataManager/Metrics/MetricPopup';
import { UnsavedChangesConfirmation } from '../../ConfirmationModal';
import InSituMetricEditorContext from '../../../contexts/InSituMetricEditorContext';
import metricTypeCheckers from '../../../types/metricTypeCheckers';
import SingleUseMetricPopupContext from 'contexts/SingleUseMetricPopupContext';

const MetricEditor = ({
  isOpen,
  close,
  selectedMetric,
  singleUseEditCancelButtonText,
}: {
  isOpen: boolean;
  close: () => void;
  selectedMetric?: Metrics.Metric;
  singleUseEditCancelButtonText: string;
}) => {
  const {
    isOpen: isConfirmOpen,
    open: openConfirm,
    close: closeConfirm,
  } = usePopup();

  const [isSaveToDataManager, setIsSaveToDataManager] =
    useState<boolean>(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const onPopupCloseConfirmed = useCallback(() => {
    closeConfirm();
    close();
  }, [close, closeConfirm]);

  const onPopupDismissed = useCallback(() => {
    if (!hasUnsavedChanges) {
      onPopupCloseConfirmed();
      return;
    }

    openConfirm();
  }, [hasUnsavedChanges, onPopupCloseConfirmed, openConfirm]);

  const onInSituEditorSaved = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (_metric: Metrics.NormalMetric | Metrics.CompoundMetric) => {
      close();
    },
    [close],
  );

  const onSingleUseMetricSaved = useCallback(() => {
    close();
  }, [close]);

  useEffect(() => {
    setHasUnsavedChanges(false);
    setIsSaveToDataManager(false);
  }, [isOpen]);

  if (
    !!selectedMetric &&
    (metricTypeCheckers.isNormalMetric(selectedMetric) ||
      metricTypeCheckers.isCompoundMetric(selectedMetric))
  ) {
    return (
      <SingleUseMetricPopupContext.Provider
        value={{
          isSingleUsePopup:
            metricTypeCheckers.isSingleUseMetric(selectedMetric),
          isSaveToDataManager,
          setIsSaveToDataManager,
          onSingleUseMetricSaved,
          cancelButtonText: singleUseEditCancelButtonText,
        }}
      >
        <InSituMetricEditorContext.Provider
          value={{
            isInSituEditor: true,
            onInSituEditorSaved,
          }}
        >
          <ModalTransition>
            {isOpen && (
              <MetricPopup
                mode="Metric"
                close={onPopupDismissed}
                hasUnsavedChanges={hasUnsavedChanges}
                setHasUnsavedChanges={setHasUnsavedChanges}
                selectedMetric={selectedMetric}
              />
            )}
          </ModalTransition>
          <UnsavedChangesConfirmation
            onConfirmed={onPopupCloseConfirmed}
            close={closeConfirm}
            isOpen={isConfirmOpen}
          />
        </InSituMetricEditorContext.Provider>
      </SingleUseMetricPopupContext.Provider>
    );
  }

  return null;
};

export default MetricEditor;
