import { useCallback } from 'react';

const useGetCostValue = (
  costsLookup: Costs.CostIntervalLookup,
  getIsInitialAllGroup: (groupDefinition: Costs.Group) => boolean,
) => {
  const getCostValue = useCallback(
    ({
      startDate,
      groupDefinition,
      costFieldName,
    }: {
      startDate: string;
      groupDefinition: Costs.Group;
      costFieldName: string;
    }) => {
      const i = costsLookup[startDate];
      if (!i) {
        return getIsInitialAllGroup(groupDefinition) ? 0 : undefined;
      }

      const group = i.groups[groupDefinition.groupName];
      if (!group) {
        return getIsInitialAllGroup(groupDefinition) ? 0 : undefined;
      }

      return group.costs[costFieldName] !== undefined
        ? group.costs[costFieldName]
        : getIsInitialAllGroup(groupDefinition)
          ? 0
          : undefined;
    },
    [costsLookup, getIsInitialAllGroup],
  );

  return getCostValue;
};

export default useGetCostValue;
