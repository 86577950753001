import React, { useCallback, useMemo, useState } from 'react';
import useConfirmationProps from './useConfirmationProps';
import DangerConfirmationModal from '../DangerConfirmationModal';

const useDangerConfirmation = ({
  title,
  checkboxText,
}: {
  title: string;
  checkboxText: string;
}) => {
  const [body, setBody] = useState<string>('');
  const { getConfirmation, onConfirmed, onCancelled, isOpen } =
    useConfirmationProps();

  const DangerConfirmation = useMemo(
    () => (
      <DangerConfirmationModal
        isOpen={isOpen}
        close={onCancelled}
        title={title}
        body={body}
        onConfirmed={onConfirmed}
        checkboxText={checkboxText}
      />
    ),
    [body, checkboxText, isOpen, onCancelled, onConfirmed, title],
  );

  const getConfirmationThat = useCallback(
    (confirmationBody: string) => {
      setBody(confirmationBody);
      return getConfirmation();
    },
    [getConfirmation],
  );

  return { getConfirmationThat, DangerConfirmation };
};

export default useDangerConfirmation;
