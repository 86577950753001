import React, { ChangeEvent } from 'react';
import Row from '../../Common/Row';
import Typography from 'kingpin/atoms/Typography';
import Inputs from '../../Inputs';
import MultiUserSelect from '../../MultiUserSelect';
import Button from 'kingpin/atoms/Button';
import Form from '../../../kingpin/forms/Form';
import FormHeader from '../../../kingpin/forms/FormHeader';
import FormContent from '../../../kingpin/forms/FormContent';

const TaskForm = ({
  title,
  onTitleChanged,
  dueDate,
  onDueDateChanged,
  assignedTo,
  setAssignedTo,
  assignedToName,
  description,
  onDescriptionChanged,
  isValid,
  isEditing,
  onCancelled,
  onSave,
  isLoading,
  assignableUserIds,
  hideTitle,
}: {
  title: string;
  onTitleChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  dueDate: string;
  onDueDateChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  assignedTo: string[];
  setAssignedTo: React.Dispatch<React.SetStateAction<string[]>>;
  assignedToName: string;
  description: string;
  onDescriptionChanged: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  isValid: boolean;
  isEditing: boolean;
  onCancelled: () => void;
  onSave: () => void;
  isLoading: boolean;
  assignableUserIds?: string[];
  hideTitle?: boolean;
}) => (
  <Form>
    {!hideTitle && (
      <FormHeader
        title={isEditing ? 'Update Task' : 'Create Task'}
        onClose={onCancelled}
      />
    )}
    <FormContent>
      <div style={{ marginBottom: 24 }}>
        <Typography.Body type="Label">Title</Typography.Body>
        <Inputs.TextInput
          value={title}
          onChange={onTitleChanged}
          data-testid={'task-title'}
          inputSize="Small"
        />
      </div>
      <div style={{ marginBottom: 24 }}>
        <Typography.Body type="Label">Description</Typography.Body>
        <Inputs.TextArea value={description} onChange={onDescriptionChanged} />
      </div>
      <Row style={{ marginBottom: 24 }}>
        <div style={{ marginRight: 8, flex: 1 }}>
          <Typography.Body type="Label">Due Date</Typography.Body>
          <Inputs.TextInput
            type={'date'}
            value={dueDate}
            onChange={onDueDateChanged}
            inputSize="Small"
          />
        </div>
        <div style={{ flex: 1 }}>
          <Typography.Body type="Label"> Assignee</Typography.Body>
          <MultiUserSelect
            selectedUsers={assignedTo}
            setSelectedUsers={setAssignedTo}
            isSingle
            allowedUserIds={assignableUserIds}
          />
        </div>
      </Row>
    </FormContent>
    <Row spaceBetween centerAlign>
      <Button
        onClick={onCancelled}
        type="Secondary"
        size="Small"
        label="Cancel"
      />
      <Button
        onClick={onSave}
        isDisabled={!isValid || isLoading}
        isLoading={isLoading}
        type="Primary"
        size="Small"
        label="Save"
      />
    </Row>
  </Form>
);

export default TaskForm;
