import React, { useContext } from 'react';
import styled from 'styled-components';
import Colors2 from 'theme/Colors2';

import AddGroupInput from './AddGroupInput';
import CurrentGroups from './CurrentGroups';
import ConfigureMicrosoftSsoContext from '../contexts/ConfigureMicrosoftSsoContext';

const AddGroupCardDiv = styled.div<{ hasGroups: boolean }>`
  border-radius: 6px;
  background-color: ${Colors2.Grey['9']};
  padding: 16px;
  box-shadow: ${(props) =>
    props.hasGroups ? '0px -1px 0px 0px #00000014 inset' : 'unset'};
  border: 1px solid ${Colors2.Grey['7']};
`;

const GroupInputCard = () => {
  const { selectedGroups } = useContext(ConfigureMicrosoftSsoContext);

  return (
    <AddGroupCardDiv hasGroups={selectedGroups.length > 0}>
      <AddGroupInput />
      {selectedGroups.length > 0 && <CurrentGroups />}
    </AddGroupCardDiv>
  );
};

export default GroupInputCard;
