import React from 'react';
import styled from 'styled-components';
import { ModalTransition } from '@atlaskit/modal-dialog';

import Typography from 'kingpin/atoms/Typography';
import Colors2 from 'theme/Colors2';

const IconWrapper = styled.div`
  margin-right: 8px;
`;

const NavItemWrapper = styled.div<{ isActive: boolean }>`
  padding: 8px;
  display: flex;
  border-radius: 4px;
  align-items: center;
  cursor: pointer;
  background-color: ${(props) =>
    props.isActive ? Colors2.Grey['8'] : undefined};
`;

const SettingsNavItem = ({
  IconSvg,
  label,
  isActive,
  handleNavClick,
  DangerConfirmation,
}: {
  IconSvg: JSX.Element;
  label: string;
  isActive: boolean;
  handleNavClick: () => void;
  DangerConfirmation: JSX.Element;
}) => {
  return (
    <>
      <div onClick={handleNavClick}>
        <NavItemWrapper isActive={isActive}>
          <IconWrapper>{IconSvg}</IconWrapper>
          <div style={{ marginBottom: 1 }}>
            <Typography.Body type="Body 12">{label}</Typography.Body>
          </div>
        </NavItemWrapper>
      </div>
      <ModalTransition>{DangerConfirmation}</ModalTransition>
    </>
  );
};

export default SettingsNavItem;
