import { useContext } from 'react';
import ScorecardContext from '../contexts/ScorecardContext';

const useScorecardPopupCommentId = ({
  period,
  kpi,
}: {
  period?: Period;
  kpi?: Scorecards.ScorecardKpi | Scorecards.ManualKpiRow;
}) => {
  const { scorecard } = useContext(ScorecardContext);
  const commentableId =
    !!scorecard && !!period && !!kpi
      ? `${scorecard.id}-${kpi.id}-${period.startDate}`
      : undefined;

  return {
    commentableId,
    commentableType: 'KPI',
    scorecard,
  };
};

export default useScorecardPopupCommentId;
