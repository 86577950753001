import React from 'react';

interface GoalContextType {
  goal: GeneralGoal;
  dateBuckets: string[];
  currentDateBucket: string;
  lastCompletedDateBucket: string;
}
const GoalContext = React.createContext<GoalContextType>({} as GoalContextType);

export default GoalContext;
