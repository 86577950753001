import firebase from 'firebase/compat/app';
import { REPORTS_COLLECTION } from '../constants';
import withoutNulls from '../api/search/withoutNulls';

const reportConverter = {
  toFirestore(report: PersistedReportType): firebase.firestore.DocumentData {
    return withoutNulls(report);
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot<PersistedReportType>,
    options: firebase.firestore.SnapshotOptions,
  ): PersistedReportType {
    return snapshot.data(options);
  },
};

const getReportsRef = ({
  accountId,
  db,
}: {
  accountId: string;
  db?: firebase.firestore.Firestore;
}) => {
  return (db ? db : firebase.firestore())
    .collection('accounts')
    .doc(accountId)
    .collection(REPORTS_COLLECTION)
    .withConverter(reportConverter);
};

export default getReportsRef;
