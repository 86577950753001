import React, { useContext } from 'react';
import EditListButton from './EditListButton';
import usePopup from '../../../hooks/usePopup';
import DashboardContext from '../../../contexts/DashboardContext';
import DateInputContext from '../../../contexts/DateInputContext';

const EditListButtonContainer = ({
  metricListGadget,
}: {
  metricListGadget: MetricListGadgetType;
}) => {
  const { isOpen, open, close } = usePopup(false, undefined, true);
  const { templatedFrom } = useContext(DashboardContext);
  const originalListContext = useContext(DateInputContext);

  if (templatedFrom) {
    return null;
  }
  return (
    <EditListButton
      open={open}
      isOpen={isOpen}
      close={close}
      metricListGadget={metricListGadget}
      originalListContext={originalListContext}
    />
  );
};

export default EditListButtonContainer;
