import React, { useContext } from 'react';
import UsersSettingsContext from '../../../contexts/UsersSettingsContext';
import Alert from '../../../kingpin/Alert';
import Typography from 'kingpin/atoms/Typography';

const SeatsInfoBody = () => {
  return (
    <Typography.Body type={'Body 12'}>
      For more seats{' '}
      <a href="mailto:support@fleetops.com?subject=Additional Seats">
        Contact Us
      </a>
    </Typography.Body>
  );
};

const SeatsInfo = () => {
  const { isSeatsLimited, usedSeats, maxSeats } =
    useContext(UsersSettingsContext);

  if (!isSeatsLimited) {
    return null;
  }

  return (
    <div style={{ marginTop: 12 }}>
      <Alert
        title={`Seats Available: ${usedSeats}/${maxSeats}`}
        body={<SeatsInfoBody />}
        type={'Info'}
      />
    </div>
  );
};

export default SeatsInfo;
