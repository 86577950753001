import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

const buildQuery = () => ({
  query: gql`
    query getActivityDateFields {
      getActivityDateFields
    }
  `,
});

const getActivityDateFields = async (
  client: ApolloClient<NormalizedCacheObject>,
): Promise<string[]> => {
  const gqlQuery = buildQuery();
  const response = await client.query(gqlQuery);
  return response.data.getActivityDateFields;
};

export default getActivityDateFields;
