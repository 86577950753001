import React from 'react';

interface BulkGridActionsContextType {
  selectedItems: string[];
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>;
  onBulkActionCommitted: () => void;
  isBulkActionLoading: boolean;
}

const BulkGridActionsContext = React.createContext<BulkGridActionsContextType>({
  selectedItems: [],
  setSelectedItems: () => {},
  onBulkActionCommitted: () => {},
  isBulkActionLoading: false,
});

export default BulkGridActionsContext;
