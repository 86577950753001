import React, { useContext, useEffect, useState } from 'react';
import Typography from 'kingpin/atoms/Typography';
import CommentsContext from 'contexts/CommentsContext';
import ScorecardContext from 'contexts/ScorecardContext';
import {
  getTextColor,
  ScoringBandColors,
} from 'screens/ScorecardsIndex/constants';
import { BandPreview } from '../ScorecardKpiForm/TargetBandsForm/TargetBandsForm';
import Row from 'components/Common/Row';

const ShortenedText = ({
  baseText,
  maxLength,
}: {
  baseText: string;
  maxLength: number;
}) => {
  const [shortenedText, setShortenedText] = useState<string>('');

  useEffect(() => {
    if (baseText.length > maxLength) {
      const copy = `${baseText}`;
      setShortenedText(copy.slice(0, maxLength) + '...');
    } else {
      setShortenedText(baseText);
    }
  }, [baseText, maxLength]);

  return (
    <Typography.Body type="Body 12" isEllipsis>
      {shortenedText}
    </Typography.Body>
  );
};

const PerformanceTooltip = ({
  kpi,
  targetBandsForPeriod,
  formattedValue,
  cellColor,
  isDynamic,
}: {
  kpi: Scorecards.ScorecardKpi | Scorecards.ManualKpiRow;
  targetBandsForPeriod?: string[];
  formattedValue: string;
  cellColor: string;
  isDynamic: boolean;
}) => {
  const { comments } = useContext(CommentsContext);
  const { scorecard } = useContext(ScorecardContext);
  const [prefix, setPrefix] = useState<string>('');
  const [colors, setColors] = useState<string[]>([]);
  const [note, setNote] = useState<CommentType | undefined>();

  useEffect(() => {
    if (comments.length === 0) {
      setNote(undefined);
      return;
    }

    setNote(comments[comments.length - 1]);
  }, [comments]);

  useEffect(() => {
    setColors(
      kpi.isColorOrderReversed
        ? [...ScoringBandColors[scorecard.scoringBandId]].reverse()
        : ScoringBandColors[scorecard.scoringBandId],
    );
  }, [kpi.isColorOrderReversed, scorecard.scoringBandId]);

  useEffect(() => {
    const isOnTargetIndex = kpi.isColorOrderReversed ? 0 : colors.length - 1;
    const currentColorIndex = colors.findIndex((c) => c === cellColor);
    if (isOnTargetIndex === currentColorIndex) {
      setPrefix(`On ${isDynamic ? 'dynamic' : ''} target`);
    } else {
      setPrefix(`Below ${isDynamic ? 'dynamic' : ''} target`);
    }
  }, [
    cellColor,
    colors,
    isDynamic,
    kpi.isColorOrderReversed,
    scorecard.scoringBandId,
  ]);

  return (
    <div style={{ padding: 0 }}>
      <div style={{ marginBottom: 8 }}>
        {!kpi.isTargetsDisabled && (
          <div style={{ marginRight: 4 }}>
            <Typography.Body type="Body 12">{prefix}:</Typography.Body>
          </div>
        )}

        <div style={{ marginBottom: 4 }}>
          <Typography.Header
            type="H5"
            color={getTextColor({ color: cellColor })}
          >
            {formattedValue ? formattedValue : '-'}
          </Typography.Header>
        </div>
      </div>

      {!kpi.isTargetsDisabled && targetBandsForPeriod && (
        <div style={{ marginBottom: 8 }}>
          <div style={{ marginBottom: 4 }}>
            <Typography.Body type="Body 12">Target bands:</Typography.Body>
          </div>
          <Row>
            {colors.map((color, index) => (
              <div
                style={{
                  marginRight: colors.length - 1 === index ? 0 : 8,
                  position: 'relative',
                }}
                key={`${index}-${color}`}
              >
                <BandPreview
                  bands={targetBandsForPeriod}
                  bandIndex={index}
                  scoringBandId={scorecard.scoringBandId}
                  isColorOrderReversed={kpi.isColorOrderReversed}
                  isTooltip
                />
              </div>
            ))}
          </Row>
        </div>
      )}

      {note && (
        <div style={{ marginBottom: 8 }}>
          <div style={{ marginBottom: 4 }}>
            <Typography.Body type={'Body 12'}>Notes:</Typography.Body>
          </div>
          <ShortenedText baseText={note.text} maxLength={100} />
        </div>
      )}
    </div>
  );
};

export default PerformanceTooltip;
