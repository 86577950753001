import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Typography from 'kingpin/atoms/Typography';
import Row from 'components/Common/Row';

import EntityResults from '../SearchResults/EntityResults';
import { ReactComponent as BackArrow } from '../../images/back-arrow.svg';
import ActiveFilterToggle from '../ActiveFilterTogle';
import FilterBy from './FilterBy';

const WrapperDiv = styled.div`
  padding: 24px 200px;
`;

const BackRow = styled(Row)`
  margin-bottom: 8px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const useResults = ({
  result,
  selectedEntity,
}: {
  result: GlobalSearch.SearchResults;
  selectedEntity: EntityDetails.Entity;
}) => {
  const getResults = useCallback(() => {
    const newResult = result.results.find(
      (r) => r.entity.id === selectedEntity.id,
    );
    if (!newResult) {
      const e = new Error();
      e.name = 'Entity results not found';
      throw e;
    }
    return newResult;
  }, [result.results, selectedEntity.id]);

  const [results, setResults] = useState<GlobalSearch.Result>(getResults);

  useEffect(() => {
    setResults(getResults());
  }, [getResults]);

  return results;
};

const EntitySearchResults = ({
  result,
  onEntityNavigate,
  selectedEntity,
  setSelectedEntity,
  isActiveFilterDisabled,
  setIsActiveFilterDisabled,
}: {
  result: GlobalSearch.SearchResults;
  onEntityNavigate: (entity: EntityDetails.Entity) => void;
  selectedEntity: EntityDetails.Entity;
  setSelectedEntity: React.Dispatch<
    React.SetStateAction<EntityDetails.Entity | undefined>
  >;
  isActiveFilterDisabled: boolean;
  setIsActiveFilterDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const entityResults = useResults({ result, selectedEntity });
  const onBackClicked = useCallback(() => {
    setSelectedEntity(undefined);
  }, [setSelectedEntity]);

  return (
    <WrapperDiv>
      <div style={{ marginBottom: 32 }}>
        <Row spaceBetween centerAlign>
          <div>
            <BackRow centerAlign onClick={onBackClicked}>
              <div style={{ marginRight: 8 }}>
                <BackArrow />
              </div>
              <Typography.Body type="Link">
                Back to all search results
              </Typography.Body>
            </BackRow>
            <Typography.Header type="H5">
              {`Showing results for "${entityResults.searchText}"`}
            </Typography.Header>
          </div>
          <Row centerAlign>
            <div style={{ marginRight: 16 }}>
              <FilterBy
                selectedEntity={selectedEntity}
                onRemoveClicked={onBackClicked}
              />
            </div>
            <ActiveFilterToggle
              isActiveFilterDisabled={isActiveFilterDisabled}
              setIsActiveFilterDisabled={setIsActiveFilterDisabled}
            />
          </Row>
        </Row>
      </div>
      <EntityResults
        result={entityResults}
        onEntityNavigate={onEntityNavigate}
        isShowingFirstFour={false}
        setSelectedEntity={setSelectedEntity}
      />
    </WrapperDiv>
  );
};

export default EntitySearchResults;
