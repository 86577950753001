import React, { useCallback, useEffect, useState } from 'react';

import styled from 'styled-components';
import Colors2 from '../../theme/Colors2';
import Typography from 'kingpin/atoms/Typography';
import Row from '../Common/Row';
import Inputs from '../Inputs';
import Button from '../../kingpin/atoms/Button';

const ItemWrapper = styled.div`
  margin-bottom: 16px;
  background-color: ${Colors2.Grey['9']};
  padding: 12px;
  border-radius: 8px;
`;

const RankingMetricInput = ({
  metric,
  updateMetric,
  removeMetric,
  groupByField,
}: {
  metric: VisualisationDefinitions.RankingMatrixMetric;
  updateMetric: (
    newMetric: VisualisationDefinitions.RankingMatrixMetric,
  ) => void;
  removeMetric: (
    newMetric: VisualisationDefinitions.RankingMatrixMetric,
  ) => void;
  groupByField?: string;
}) => {
  const [metricId, setMetricId] = useState<string | undefined>(metric.metricId);

  useEffect(() => {
    if (metric.metricId === metricId) {
      return;
    }

    if (!metricId) {
      setMetricId(metric.metricId);
      return;
    }

    updateMetric({
      ...metric,
      metricId,
    });
  }, [metric, metricId, updateMetric]);

  const toggleRanking = useCallback(() => {
    updateMetric({
      ...metric,
      isRankingEnabled: !metric.isRankingEnabled,
    });
  }, [metric, updateMetric]);

  const togglePercentage = useCallback(() => {
    updateMetric({
      ...metric,
      isPercentageOfTotalEnabled: !metric.isPercentageOfTotalEnabled,
    });
  }, [metric, updateMetric]);

  return (
    <ItemWrapper>
      <div style={{ marginBottom: 8 }}>
        <Typography.Body type="Label">Metric</Typography.Body>
        <Row spaceBetween centerAlign>
          <Inputs.MetricPicker
            metricId={metric.metricId}
            setMetricId={setMetricId}
            datasetMustIncludeField={groupByField}
          />
          <div style={{ marginBottom: 8 }}>
            <Button
              onClick={() => removeMetric(metric)}
              icon="cross"
              size="Small"
              type="Secondary"
            />
          </div>
        </Row>
      </div>

      <div style={{ marginBottom: 12 }}>
        <Typography.Body type="Label">Display Name</Typography.Body>
        <Inputs.TextInput
          value={metric.displayName || ''}
          onChange={(event) => {
            updateMetric({
              ...metric,
              displayName: event.target.value,
            });
          }}
        />
      </div>
      <Row style={{ marginBottom: 12 }}>
        <Typography.Body type="Label">Ranking Column</Typography.Body>
        <div style={{ marginLeft: 8 }}>
          <Inputs.Toggle
            value={metric.isRankingEnabled}
            onChange={toggleRanking}
            testId="ranking"
          />
        </div>
      </Row>
      <Row style={{ marginBottom: 12 }}>
        <Typography.Body type="Label">
          Percentage of Total Column
        </Typography.Body>
        <div style={{ marginLeft: 8 }}>
          <Inputs.Toggle
            value={metric.isPercentageOfTotalEnabled}
            onChange={togglePercentage}
          />
        </div>
      </Row>
    </ItemWrapper>
  );
};

export default RankingMetricInput;
