import { useCallback, useContext, useEffect, useState } from 'react';
import { IBasePaceCalcArgs, IPaceGroupByInput } from '../types';
import GqlClientContext from 'contexts/GqlClientContext';
import useDateScope from 'hooks/useDateScope';
import useFilterInput from 'hooks/useFilterInput';
import useComparisonPeriod from './useComparisonPeriod';

const useBaseArgs = ({
  paceMatrix,
}: {
  paceMatrix: VisualisationDefinitions.PaceMatrix;
}) => {
  const { client } = useContext(GqlClientContext);
  const dateScope = useDateScope({});
  const filters = useFilterInput();
  const comparisonPeriod = useComparisonPeriod();

  const getArgs = useCallback((): IBasePaceCalcArgs | undefined => {
    if (!comparisonPeriod) {
      return;
    }
    const groupBy: IPaceGroupByInput = { field: paceMatrix.groupByField };

    return {
      client,
      dateScope: [dateScope],
      filters: [filters],
      groupBy,
      comparisonPeriod,
    };
  }, [client, comparisonPeriod, dateScope, filters, paceMatrix.groupByField]);
  const [args, setArgs] = useState<IBasePaceCalcArgs | undefined>(() =>
    getArgs(),
  );
  useEffect(() => {
    setArgs(getArgs());
  }, [getArgs]);

  return args;
};

export default useBaseArgs;
