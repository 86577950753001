import styled, { css } from 'styled-components';
import Colors from '../../theme/colors';
import MEDIA_QUERIES from '../../mediaQueries';
import Colors2 from 'theme/Colors2';

export const PADDING = 4;
export const GADGET_HEADERS_BACKGROUND_COLOR = '#f0f0f0';
export const HEADERS_BACKGROUND_COLOR = '#FFFFFF';
export const HEADERS_BORDER_COLOR = Colors2.Border;
export const HEADERS_BORDER_BOTTOM_COLOR = '#d8d8d8';
export const COL_BORDER_COLOR = Colors2.Border;
export const ODD_ROW_COLOR = '#f7f9fb';
export const ODD_ROW_HOVER = '#eeeff0';
export const EVEN_ROW_HOVER = '#f4f5f6';
export const GRID_BORDERS_CSS = css`
  border: 1px solid ${HEADERS_BORDER_COLOR} !important;
`;
export const GRID_DARK_HEADER_CSS = css`
  .ag-theme-fleetops {
    --ag-header-background-color: ${GADGET_HEADERS_BACKGROUND_COLOR} !important;
  }
`;
export const ROW_HEIGHTS = {
  XXS: 20,
  XS: 24,
  S: 26,
  M: 26,
  L: 28,
};

export const HEADER_SPAN_CSS = css`
  font-family: Inter;
  font-weight: 550;
  letter-spacing: 0em;
  color: #1a1919;

  @media ${MEDIA_QUERIES.DESKTOP_XXS} {
    font-size: 8px;
    --ag-icon-size: 8px;
    line-height: normal;
  }
  @media ${MEDIA_QUERIES.DESKTOP_XS} {
    font-size: 8px;
    --ag-icon-size: 8px;
    line-height: normal;
  }
  @media ${MEDIA_QUERIES.DESKTOP_S} {
    font-size: 10px;
    --ag-icon-size: 10px;
    line-height: normal;
  }
  @media ${MEDIA_QUERIES.DESKTOP_M} {
    font-size: 11px;
    --ag-icon-size: 11px;
    line-height: normal;
  }
  @media ${MEDIA_QUERIES.DESKTOP_L} {
    font-size: 11px;
    --ag-icon-size: 12px;
    line-height: normal;
  }
`;
export const SPAN_CSS = css`
  font-family: Inter;
  font-weight: 400;
  letter-spacing: 0em;
  color: #1a1919;

  @media ${MEDIA_QUERIES.DESKTOP_XXS} {
    font-size: 8px;
    line-height: normal;
  }
  @media ${MEDIA_QUERIES.DESKTOP_XS} {
    font-size: 9px;
    line-height: normal;
  }
  @media ${MEDIA_QUERIES.DESKTOP_S} {
    font-size: 10px;
    line-height: normal;
  }
  @media ${MEDIA_QUERIES.DESKTOP_M} {
    font-size: 11px;
    line-height: normal;
  }
  @media ${MEDIA_QUERIES.DESKTOP_L} {
    font-size: 11px;
    line-height: normal;
  }
`;

const SEARCH_HEADER_SPAN_CSS = css`
  font-family: Inter;
  font-weight: 550;
  letter-spacing: 0em;
  color: #1a1919;

  @media ${MEDIA_QUERIES.DESKTOP_XXS} {
    font-size: 10px;
    --ag-icon-size: 10px;
    line-height: normal;
  }
  @media ${MEDIA_QUERIES.DESKTOP_XS} {
    font-size: 10px;
    --ag-icon-size: 10px;
    line-height: normal;
  }
  @media ${MEDIA_QUERIES.DESKTOP_S} {
    font-size: 11px;
    --ag-icon-size: 11px;
    line-height: normal;
  }
  @media ${MEDIA_QUERIES.DESKTOP_M} {
    font-size: 13px;
    --ag-icon-size: 14px;
    line-height: normal;
  }
  @media ${MEDIA_QUERIES.DESKTOP_L} {
    font-size: 13px;
    --ag-icon-size: 14px;
    line-height: normal;
  }
`;
const SEARCH_SPAN_CSS = css`
  font-family: Inter;
  font-weight: 400;
  letter-spacing: 0em;
  color: #1a1919;

  @media ${MEDIA_QUERIES.DESKTOP_XXS} {
    font-size: 10px;
    line-height: normal;
  }
  @media ${MEDIA_QUERIES.DESKTOP_XS} {
    font-size: 11px;
    line-height: normal;
  }
  @media ${MEDIA_QUERIES.DESKTOP_S} {
    font-size: 12px;
    line-height: normal;
  }
  @media ${MEDIA_QUERIES.DESKTOP_M} {
    font-size: 13px;
    line-height: normal;
  }
  @media ${MEDIA_QUERIES.DESKTOP_L} {
    font-size: 13px;
    line-height: normal;
  }
`;

const StyleOverrides = styled.div<{
  clickable?: boolean;
  isPerformanceSettings?: boolean;
}>`
  .ag-theme-fleetops {
    --ag-header-background-color: ${HEADERS_BACKGROUND_COLOR};
    --ag-header-foreground-color: #13171b;

    --ag-background-color: #ffffff;
    --ag-odd-row-background-color: ${ODD_ROW_COLOR};
    --ag-foreground-color: #2b3238;
    --ag-secondary-foreground-color: #13171b;
    --ag-borders: 1px solid;
    --ag-borders-critical: 1px solid;
    --ag-borders-secondary: 1px solid;
    --ag-border-color: #e5e5f2;
    --ag-secondary-border-color: #e5e5f2;
    --ag-row-border-style: solid;
    --ag-row-border-width: 1px;
    --ag-row-border-color: #e5e5f2;
    --ag-selected-row-background-color: #e5f0fa;
    --ag-row-hover-color: ${EVEN_ROW_HOVER};
    --ag-cell-horizontal-padding: ${PADDING}px;
    --ag-control-panel-background-color: #ffffff;

    --ag-header-column-separator-display: none;

    @media ${MEDIA_QUERIES.DESKTOP_XXS} {
      --ag-row-height: 22px;
      --ag-header-height: 22px;
    }
    @media ${MEDIA_QUERIES.DESKTOP_XS} {
      --ag-row-height: 26px;
      --ag-header-height: 26px;
    }
    @media ${MEDIA_QUERIES.DESKTOP_S} {
      --ag-row-height: 26px;
      --ag-header-height: 26px;
    }
    @media ${MEDIA_QUERIES.DESKTOP_M} {
      --ag-row-height: 28px;
      --ag-header-height: 28px;
    }
    @media ${MEDIA_QUERIES.DESKTOP_L} {
      --ag-row-height: 31px;
      --ag-header-height: 31px;
    }
  }

  @supports (-moz-transform: translate(0, 0)) {
    .ag-body-viewport {
      scrollbar-width: thin !important;
    }
  }

  .ag-header-icon {
    margin-left: 0 !important;
    min-width: 8px !important;
  }

  .ag-root-wrapper {
    border: none;
  }

  .ag-cell-label-container {
    padding: 0px;
  }

  .ag-floating-bottom-container {
    .ag-row {
      border-bottom: none;
    }
  }

  .ag-header-cell-comp-wrapper {
    height: 100%;
  }

  .ag-body-vertical-scroll {
    position: absolute;
    right: 0px;
  }

  .ag-header-cell:first-of-type {
    padding-left: ${PADDING * 2}px;
  }

  .ag-cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
  }

  .right-aligned-cell {
    justify-content: flex-end;
  }

  .ag-cell:first-of-type {
    padding-left: ${PADDING * 2}px;
  }

  .ag-header-cell:last-of-type {
    padding-right: ${PADDING * 2}px;
  }

  .ag-cell:last-of-type {
    padding-right: ${PADDING * 2}px;
  }

  .ag-row-odd.ag-row-hover::before {
    background-color: ${ODD_ROW_HOVER} !important;
  }

  .ag-react-container {
    width: 100%;
  }

  .ag-root {
    border: none !important;
  }

  .ag-header-cell-label {
    span {
      line-height: unset;
    }
  }

  .ag-header-group-cell:not(:last-of-type) {
    border-right: 1px solid ${HEADERS_BORDER_COLOR};
  }

  .ag-header {
    border-color: ${HEADERS_BORDER_COLOR} !important;
    border-bottom-color: ${HEADERS_BORDER_BOTTOM_COLOR} !important;
  }

  .ag-header-cell {
    border-color: ${HEADERS_BORDER_COLOR} !important;
  }

  .ag-header-group-cell {
    span {
      &:not(.ag-icon) {
        ${HEADER_SPAN_CSS}
      }
    }
  }

  .ag-row-pinned {
    span {
      &:not(.ag-icon) {
        ${HEADER_SPAN_CSS}
      }
    }
  }
  .ag-header-cell {
    &:not(:last-of-type) {
      border-right: 1px solid;
    }
    span {
      &:not(.ag-icon) {
        ${HEADER_SPAN_CSS}
      }
    }
  }

  .ag-cell {
    &:last-of-type {
      border-right: none;
    }

    span {
      ${SPAN_CSS}
    }
  }

  .ag-numeric-cell {
    justify-content: flex-end;
  }

  .ag-center-cols-container,
  .ag-header-container,
  .ag-header-row,
  .ag-floating-bottom-container {
    min-width: 100% !important;
  }

  .ag-popup {
    z-index: 90001 !important;
  }

  .ag-popup-editor {
    z-index: 90001 !important;
  }

  .ag-row {
    cursor: ${(props) => (props.clickable ? 'pointer' : 'auto')};

    &:first-of-type {
      padding-left: 20px;
    }
  }

  .ag-row-show-on-hover {
    display: none;
  }

  .ag-row-hover {
    .ag-row-show-on-hover {
      display: block;
    }
  }

  .ag-row-selected {
    background-color: ${Colors.SATURATED_NAVY_BLUE} !important;
  }

  .ag-row-group-expanded {
    background-color: #f7f8fa !important;
  }

  .ag-row-group-contracted {
    background-color: #f7f8fa !important;
  }

  .ag-header-cell:not(:first-child) {
    &:hover {
      border-right: 1px solid ${Colors.LOGAN};
      border-left: 1px solid ${Colors.LOGAN};
    }
  }

  .ag-header-cell:nth-child(2) {
    &:hover {
      border-left: 0px;
    }
  }

  // ag-grid ignores the height of global scrollbar css.
  // See global-styles.js ::-webkit-scrollbar
  .ag-body-horizontal-scroll,
  .ag-body-horizontal-scroll-viewport,
  .ag-body-horizontal-scroll-container {
    height: 9px !important;
    max-height: 9px !important;
    min-height: 9px !important;
  }

  .ag-center-cols-viewport {
    overscroll-behavior-x: contain !important;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .ag-side-bar {
    border: 0px solid !important;
    background-color: ${Colors.WHITE} !important;
  }

  .ag-header-group-cell {
    justify-content: center;
  }

  .ag-header-group-cell-label {
    justify-content: center;
  }

  .ag-floating-bottom-container {
    font-weight: bold;
    //border-top: 1px solid #e6e6ea !important;
    color: ${Colors.FLEETOPS_PURPLE} !important;

    .ag-row {
      background-color: ${Colors.WHITE} !important;
    }

    .ag-row-hover {
      background-color: ${Colors.WHITE} !important;
    }
  }

  .ag-status-bar {
    padding-left: 0px !important;
  }

  .rightAlignedHeader {
    .ag-header-cell-label {
      justify-content: flex-end;
    }

    text-align: right;
  }

  .ag-watermark {
    display: none !important;
  }

  .ag-row-group.ag-row-odd {
    background-color: #f2f6f8 !important;
  }

  .ag-row-group.ag-row-even {
    background-color: #ffffff !important;
  }

  .ag-pinned-left-cols-container {
    ${(props) =>
      props.isPerformanceSettings &&
      'border-right: 1px solid #e6e6ea !important;'}
  }

  .ag-pinned-right-cols-container {
    ${(props) =>
      props.isPerformanceSettings &&
      'border-left: 1px solid #e6e6ea !important;'}
  }

  .ag-theme-balham .ag-header-cell::after,
  .ag-theme-balham .ag-header-group-cell::after,
  .ag-header-cell-resize::after {
    background-color: transparent !important;
  }

  .ag-theme-fleetops-search {
    .ag-header-group-cell {
      span {
        &:not(.ag-icon) {
          ${SEARCH_HEADER_SPAN_CSS}
        }
      }
    }

    .ag-row-pinned {
      span {
        &:not(.ag-icon) {
          ${SEARCH_HEADER_SPAN_CSS}
        }
      }
    }
    .ag-header-cell {
      &:not(:last-of-type) {
        border-right: 1px solid;
      }
      span {
        &:not(.ag-icon) {
          ${SEARCH_HEADER_SPAN_CSS}
        }
      }
    }

    .ag-cell {
      &:last-of-type {
        border-right: none;
      }

      span {
        ${SEARCH_SPAN_CSS}
      }
    }
  }
`;

export default StyleOverrides;
