import React, { useCallback, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';

import Row from '../../Common/Row';
import { UserIconFromId } from '../../Common/UserIcon';
import Typography from 'kingpin/atoms/Typography';
import ContentEditable from 'react-contenteditable';
import LocalTasksContext from '../../../contexts/Tasks/LocalTasksContext';
import usePopup from '../../../hooks/usePopup';
import useOpenKpiPopup from 'components/Scorecard/Kpis/hooks/useOpenKpiPopup';
import TaskPopup, { TASK_POPUP_MODAL_HEIGHT } from '../../TasksList/TaskPopup';
import ScorecardsContext from '../../../contexts/ScorecardsContext';
import ScorecardContext from '../../../contexts/ScorecardContext';
import { useNavigate } from 'react-router-dom';
import {
  buildShowCampaignGoal,
  buildShowGlobalKpiList,
  buildShowWorkSpaceTab,
} from '../../../navigation/appRoutes';
import AnalyticsContext from '../../../contexts/AnalyticsContext';
import WorkSpaceContext from '../../../contexts/WorkSpaceContext';
import GoalContext from '../../../contexts/GoalContext';
import GoalsContext from '../../../contexts/GoalsContext';
import { isKpiRow } from 'hooks/kpiTypeCheckers';
import { PortalsContext } from '../../../contextProviders/PortalsProvider';

const isShowNote = (
  interaction: Timeline.Interaction,
): interaction is Timeline.ShowNoteInteraction => {
  return interaction.type === 'Show Note';
};

const isShowAllComments = (
  interaction: Timeline.Interaction,
): interaction is Timeline.ShowAllCommentsInteraction =>
  interaction.type === 'Show All Comments';

const useScorecardFromId = (id: string) => {
  const { scorecards } = useContext(ScorecardsContext);
  const [scorecard, setScorecard] = useState<Scorecards.Scorecard>();

  useEffect(() => {
    setScorecard(scorecards.find((s) => s.id === id));
  }, [id, scorecards]);

  return scorecard;
};

const useGoalFromId = (id: string) => {
  const { goals } = useContext(GoalsContext);
  const [goal, setGoal] = useState<GeneralGoal>();

  useEffect(() => {
    setGoal(goals.find((s) => s.id === id));
  }, [id, goals]);

  return goal;
};

const Interaction = ({
  event,
  interaction,
}: {
  event: Timeline.Event;
  interaction: Timeline.Interaction;
}) => {
  if (isShowNote(interaction)) {
    return (
      <ShowNoteInteraction
        interaction={interaction}
        scorecardId={event.timelineableId}
      />
    );
  } else if (isShowAllComments(interaction)) {
    return <ShowAllCommentsInteraction interaction={interaction} />;
  } else {
    return null;
  }
};

const AutoInteraction = ({ event }: { event: Timeline.Event }) => {
  const { scorecard } = useContext(ScorecardContext);
  const { goal } = useContext(GoalContext);

  if (event.timelineableType === 'Scorecard' && !scorecard) {
    return <OpenScorecardInteraction scorecardId={event.timelineableId} />;
  } else if (event.timelineableType === 'Goal' && !goal) {
    return <OpenGoalInteraction goalId={event.timelineableId} />;
  }

  return null;
};

const OpenScorecardInteraction = ({ scorecardId }: { scorecardId: string }) => {
  const { selectedPortal } = useContext(PortalsContext);
  const navigate = useNavigate();
  const { workSpace } = useContext(WorkSpaceContext);
  const scorecard = useScorecardFromId(scorecardId);

  const onClick = useCallback(() => {
    const currentWorkSpaceTab = workSpace
      ? workSpace.tabs.find(
          (t) => t.type === 'scorecards' && t.typeId === scorecardId,
        )
      : undefined;
    if (workSpace && !!currentWorkSpaceTab) {
      navigate(
        buildShowWorkSpaceTab({
          workSpaceId: workSpace.id,
          tabId: currentWorkSpaceTab.typeId,
          tabType: currentWorkSpaceTab.type,
        }),
      );
      return;
    }

    navigate(
      buildShowGlobalKpiList({
        scorecardId,
        portal: selectedPortal,
      }),
    );
  }, [navigate, scorecardId, selectedPortal, workSpace]);

  if (!scorecard) {
    return null;
  }

  return (
    <div onClick={onClick}>
      <Typography.Body type="Link">View KPI List</Typography.Body>
    </div>
  );
};

const OpenGoalInteraction = ({ goalId }: { goalId: string }) => {
  const navigate = useNavigate();
  const { workSpace } = useContext(WorkSpaceContext);
  const goal = useGoalFromId(goalId);

  const onClick = useCallback(() => {
    if (!workSpace || !goal) {
      return;
    }
    const tab = workSpace.tabs.find((t) => t.type === 'goals');

    if (!tab) {
      return;
    }
    navigate(buildShowCampaignGoal(goalId, workSpace.id, tab.typeId));
  }, [goal, goalId, navigate, workSpace]);

  if (!workSpace || !goal) {
    return null;
  }
  const tab = workSpace.tabs.find((t) => t.type === 'goals');
  if (!tab) {
    return null;
  }

  return (
    <div onClick={onClick}>
      <Typography.Body type="Link">View Goal</Typography.Body>
    </div>
  );
};

const ShowAllCommentsInteraction = ({
  interaction,
}: {
  interaction: Timeline.ShowAllCommentsInteraction;
}) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const { tasks } = useContext(LocalTasksContext);
  const { scorecard } = useContext(ScorecardContext);
  const { workSpace } = useContext(WorkSpaceContext);

  const [selectedTask, setSelectedTask] = useState<Tasks.Task | undefined>();
  const { isOpen, open, close } = usePopup();
  useEffect(() => {
    setSelectedTask(tasks.find((t) => t.id === interaction.taskId));
  }, [interaction.taskId, tasks]);

  const onOpenClicked = useCallback(() => {
    const source = scorecard ? 'Scorecard' : workSpace ? 'Workspace' : 'NA';

    trackEvent('Timeline - Show all comments Clicked', {
      source,
    });
    open();
  }, [scorecard, workSpace, trackEvent, open]);

  if (!selectedTask) {
    return null;
  }

  return (
    <>
      <div onClick={onOpenClicked}>
        <Typography.Body type="Link">Show all comments</Typography.Body>
      </div>
      <ModalTransition>
        {isOpen && (
          <Modal
            onClose={close}
            shouldScrollInViewport={false}
            autoFocus={false}
            width={1000}
            height={TASK_POPUP_MODAL_HEIGHT}
          >
            <TaskPopup task={selectedTask} onClose={close} />
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};

const useShowNoteOnClick = (
  scorecard: Scorecards.Scorecard | undefined,
  interaction: Timeline.ShowNoteInteraction,
) => {
  const { selectedPortal } = useContext(PortalsContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const { scorecard: contextScorecard } = useContext(ScorecardContext);
  const { workSpace } = useContext(WorkSpaceContext);

  const [kpi, setKpi] = useState<Scorecards.ScorecardKpi>();
  const [isOnScorecardScreen] = useState(!!contextScorecard);
  const openKpiPopup = useOpenKpiPopup(kpi, interaction.period);
  const navigate = useNavigate();

  useEffect(() => {
    if (!scorecard) {
      return;
    }
    const newKpi = scorecard.kpis
      .filter(isKpiRow)
      .find((k) => k.id === interaction.kpiId);
    setKpi(newKpi);
  }, [interaction.kpiId, scorecard]);

  const onClick = useCallback(() => {
    if (!scorecard || !kpi) {
      return;
    }
    const source = scorecard ? 'Scorecard' : workSpace ? 'Workspace' : 'NA';
    trackEvent('Timeline - Show Note Clicked', { source });
    if (isOnScorecardScreen && openKpiPopup) {
      openKpiPopup();
      return;
    }

    navigate(
      buildShowGlobalKpiList({
        scorecardId: scorecard.id,
        kpiId: kpi.id,
        periodStart: interaction.period.startDate,
        periodEnd: interaction.period.endDate,
        portal: selectedPortal,
      }),
    );
  }, [
    scorecard,
    kpi,
    workSpace,
    trackEvent,
    isOnScorecardScreen,
    openKpiPopup,
    navigate,
    interaction.period.startDate,
    interaction.period.endDate,
    selectedPortal,
  ]);

  return scorecard ? onClick : undefined;
};

const ShowNoteInteraction = ({
  interaction,
  scorecardId,
}: {
  interaction: Timeline.ShowNoteInteraction;
  scorecardId: string;
}) => {
  const scorecard = useScorecardFromId(scorecardId);
  const onClick = useShowNoteOnClick(scorecard, interaction);

  if (!onClick) {
    return null;
  }

  return (
    <div onClick={onClick}>
      <Typography.Body type="Link">View Note</Typography.Body>
    </div>
  );
};

const Event = ({ event }: { event: Timeline.Event }) => (
  <Row
    style={{
      borderBottom: '1px solid #E9E9E9',
      padding: '12px 8px',
    }}
  >
    <div>
      <UserIconFromId userId={event.createdBy} />
    </div>

    <div style={{ flex: 1, overflow: 'hidden' }}>
      <div style={{ marginBottom: 8 }}>
        <Row style={{ height: 24 }} centerAlign>
          <div style={{ marginRight: '4px' }}>
            <Typography.Body type="Body 12">
              {event.headlineText.userDisplayName}
            </Typography.Body>
          </div>
          <div style={{ marginRight: '4px' }}>
            <Typography.Body type="Body 12">
              {event.headlineText.actionText}
            </Typography.Body>
          </div>
          <div style={{ marginRight: '4px' }}>
            <Typography.Body type="Body 12">
              {event.headlineText.contextText}
            </Typography.Body>
          </div>
        </Row>
        {!!event.contentText && (
          <div>
            {event.isContentTextRich && (
              <ContentEditable
                html={event.contentText}
                disabled
                onChange={window.tokenFunction}
                style={{ height: '100%' }}
              />
            )}
            {!event.isContentTextRich && (
              <Typography.Body type="Label" isEllipsis>
                {event.contentText}
              </Typography.Body>
            )}
          </div>
        )}
      </div>
      <Row spaceBetween>
        <div>
          {event.interaction && (
            <Interaction interaction={event.interaction} event={event} />
          )}
          {!event.interaction && <AutoInteraction event={event} />}
        </div>
        <Typography.Body type="Body 12">
          {moment(event.createdOn).format('MMM DD, YYYY, h:mm a')}
        </Typography.Body>
      </Row>
    </div>
  </Row>
);

export default Event;
