import { useCallback, useState } from 'react';
import usePopup from '../../../hooks/usePopup';

const useConfirmationProps = () => {
  const { isOpen, open, close } = usePopup();
  const [resolve, setResolve] = useState<((value: boolean) => void) | null>(
    null,
  );

  const getConfirmation = useCallback(async (): Promise<boolean> => {
    return new Promise((resolve) => {
      open();
      setResolve(() => (value: boolean) => resolve(value));
    });
  }, [open]);

  const onConfirmed = useCallback(() => {
    if (resolve) {
      resolve(true);
      close();
    }
  }, [close, resolve]);

  const onCancelled = useCallback(() => {
    if (resolve) {
      resolve(false);
      close();
    }
  }, [close, resolve]);

  return { getConfirmation, onConfirmed, onCancelled, isOpen };
};

export default useConfirmationProps;
