import React, { useContext } from 'react';
import OverrideBanner from './OverrideBanner';
import useOverride from '../../../../hooks/useOverride';
import toSentenceCase from '../../../../services/toSentenceCase';
import DriverScoreContext from '../../../../contexts/DriverScoreContext';
import BonusPeriodsContext from '../../../../contexts/BonusPeriodsContext';

const OverrideBannerContainer = ({ category }: { category: string }) => {
  const override = useOverride(category);
  const { selectedBonusPeriod } = useContext(BonusPeriodsContext);
  const { driverScore } = useContext(DriverScoreContext);
  if (!override || !selectedBonusPeriod) {
    return null;
  }
  const overriddenOn = override.overriddenOn as string;
  const isOverriddenAfterClosed = (() => {
    try {
      return (
        selectedBonusPeriod.status === 'closed' &&
        overriddenOn >
          // @ts-ignore
          new Date(selectedBonusPeriod.lastUpdate.seconds * 1000).toISOString()
      );
    } catch (ex) {
      return false;
    }
  })();

  const label = `${toSentenceCase(category)} Override for ${
    driverScore.driver
  }`;
  const overriddenBy = override.overriddenBy;

  return (
    <OverrideBanner
      label={label}
      overriddenBy={overriddenBy}
      isOverriddenAfterClosed={isOverriddenAfterClosed}
      note={override.comment}
    />
  );
};

export default OverrideBannerContainer;
