import React from 'react';
import Inputs from '../Inputs';
import Typography from 'kingpin/atoms/Typography';
import Row from '../Common/Row';
import CurrencyTypeDropdown from './CurrencyTypeDropdown';

const FormattingInput = ({
  precision,
  setPrecision,
  prefix,
  prefixOptions,
  currencyType,
  setCurrencyType,
  postfix,
  postfixOptions,
  positiveDeltaIsGood,
  setPositiveDeltaIsGood,
  isDisabled,
}: {
  precision?: number;
  setPrecision: React.Dispatch<React.SetStateAction<number | undefined>>;
  prefix?: PreOrPostFix;
  prefixOptions: DropdownOption[];
  currencyType?: CurrencyType;
  setCurrencyType: React.Dispatch<
    React.SetStateAction<CurrencyType | undefined>
  >;
  postfix?: PreOrPostFix;
  postfixOptions: DropdownOption[];
  positiveDeltaIsGood: boolean;
  setPositiveDeltaIsGood: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;
  isDisabled?: boolean;
}) => (
  <React.Fragment>
    <div style={{ marginBottom: 16 }}>
      <Typography.Body type="Label">Precision</Typography.Body>
      <Inputs.Number
        value={precision}
        setValue={setPrecision}
        placeholder="0"
        readOnly={isDisabled}
      />
    </div>
    <div style={{ marginBottom: 16 }}>
      <Typography.Body type="Label">Prefix</Typography.Body>
      <Inputs.Dropdown
        selectedLabel={prefix}
        options={prefixOptions}
        placeholder="Choose prefix"
        isDisabled={isDisabled}
      />
    </div>
    {prefix === 'currency' && (
      <div style={{ marginBottom: 16 }}>
        <Typography.Body type="Label">Currency Type</Typography.Body>
        <CurrencyTypeDropdown
          currencyType={currencyType}
          setCurrencyType={setCurrencyType}
        />
      </div>
    )}
    <div style={{ marginBottom: 16 }}>
      <Typography.Body type="Label">Suffix</Typography.Body>
      <Inputs.Dropdown
        selectedLabel={postfix}
        options={postfixOptions}
        placeholder="Choose suffix"
        isDisabled={isDisabled}
      />
    </div>
    <div style={{ marginBottom: 16 }}>
      <Row centerAlign>
        <div style={{ marginRight: 4 }}>
          <Inputs.Checkbox
            isChecked={positiveDeltaIsGood}
            onToggled={setPositiveDeltaIsGood}
            isDisabled={isDisabled}
          />
        </div>
        <Typography.Body type="Body 12">
          Increase in the metric is desirable
        </Typography.Body>
      </Row>
    </div>
  </React.Fragment>
);

export default FormattingInput;
