import { ApisauceInstance } from 'apisauce';

const getIsInvitationValid = ({
  token,
  api,
}: {
  token: string;
  api: ApisauceInstance;
}) =>
  api.get<{
    isValid: boolean;
    isExpired: boolean;
    isUsed: boolean;
  }>(`invitations/isValid/${token}`);

export default getIsInvitationValid;
