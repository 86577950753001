import React from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import styled from 'styled-components';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';

import colors from '../../../../theme/colors';
import MetricItemEditForm from '../MetricItemEditForm';
import ConfirmationModal from '../../../ConfirmationModal';
import Button from '../../../../kingpin/atoms/Button';
import { ODD_ROW_COLOR } from '../../../Grid/StyleOverrides';

export const MetricConfigListItem = styled.div<{ isOdd: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  cursor: pointer;
  height: 32px;
  margin-bottom: 8px;
  background-color: ${(props) => (props.isOdd ? ODD_ROW_COLOR : 'white')};
  border: 1px solid ${colors.ZIRCON};
  border-radius: 3px;
  padding-left: 4px;

  &:hover {
    background-color: #ededed;
  }
`;

export const MetricConfigRemoveListItem = ({
  onClick,
}: {
  onClick: () => void;
}) => (
  <Button
    onClick={(event) => {
      event.preventDefault();
      event.stopPropagation();
      onClick();
    }}
    type="Secondary"
    size="Small"
    icon="cross"
  />
);

const MetricList = ({
  onDragEnd,
  metricList,
  metricBeingRemoved,
  isRemovingMetric,
  onRemoveItemClicked,
  onRemoveItemCanceled,
  onRemoveItemConfirmed,
  editingMetric,
  startEditingMetric,
  closeMetricEdit,
  isMetricEditOpen,
  setMetricList,
}: {
  onDragEnd: (result: DropResult) => void;
  metricList: MetricListItemType[];
  metricBeingRemoved?: MetricListItemType;
  isRemovingMetric: boolean;
  onRemoveItemClicked: (itemId: string) => void;
  onRemoveItemCanceled: () => void;
  onRemoveItemConfirmed: () => void;
  editingMetric?: MetricListItemType;
  startEditingMetric: (item: MetricListItemType) => void;
  closeMetricEdit: () => void;
  isMetricEditOpen: boolean;
  setMetricList: React.Dispatch<React.SetStateAction<MetricListItemType[]>>;
}) => (
  <React.Fragment>
    <div
      style={{
        marginBottom: 24,
      }}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={'quick-filters-drop'}>
          {(provided) => (
            <div ref={provided.innerRef}>
              {metricList.map((metricItem, index) => (
                <Draggable
                  draggableId={metricItem.id}
                  index={index}
                  key={metricItem.id}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <MetricConfigListItem
                        isOdd={index % 2 === 1}
                        onClick={() => {
                          startEditingMetric(metricItem);
                        }}
                      >
                        {metricItem.name}
                        <MetricConfigRemoveListItem
                          onClick={() => onRemoveItemClicked(metricItem.id)}
                        />
                      </MetricConfigListItem>
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
    <ModalTransition>
      {isMetricEditOpen && !!editingMetric && (
        <Modal
          onClose={closeMetricEdit}
          shouldScrollInViewport={false}
          autoFocus={false}
        >
          <MetricItemEditForm
            metricItem={editingMetric}
            setMetricList={setMetricList}
            close={closeMetricEdit}
          />
        </Modal>
      )}
    </ModalTransition>
    <ConfirmationModal
      title={
        metricBeingRemoved
          ? `Remove ${metricBeingRemoved.name}`
          : 'Remove a metric'
      }
      close={onRemoveItemCanceled}
      isOpen={isRemovingMetric}
      onConfirmed={onRemoveItemConfirmed}
      body={'Are you sure you want to remove this metric?'}
      confirmText={'Remove'}
      isLoading={false}
      cancelText={'Cancel'}
    />
  </React.Fragment>
);

export default MetricList;
