import React from 'react';
import styled from 'styled-components';
import Typography from 'kingpin/atoms/Typography';
import { InputRow } from '../../../CustomerLaneCommitsBoard/SlideOut/styles';
import Colors2 from '../../../../theme/Colors2';

const Row = styled(InputRow)`
  height: 30px;
  border-bottom: 1px solid #f2f2f2;
  padding: 0px 4px;
  margin-bottom: 0px;
  &:last-child {
    border-bottom: 0;
  }
`;

const Metric = ({
  value,
  label,
  testId,
}: {
  value: string;
  label: string;
  testId: string;
}) => (
  <Row data-testid={testId}>
    <Typography.Body type="Label">{`${label} `}</Typography.Body>
    <Typography.Body type="Label" color={Colors2.Grey['4']}>
      {value}
    </Typography.Body>
  </Row>
);

export default Metric;
