import firebase from 'firebase/compat/app';
import STORE_CONSTANTS from '../constants';

export const EXCLUDED_PACE_DATES_DOC = 'excludedPaceDates-v1';

export interface IExcludedPaceDates {
  dateRanges: DateRangeInput[];
}

const converter = {
  toFirestore(
    excludedDates: IExcludedPaceDates,
  ): firebase.firestore.DocumentData {
    return excludedDates;
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ): IExcludedPaceDates {
    const data = {
      ...snapshot.data(options),
    };
    return data as IExcludedPaceDates;
  },
};

const getExcludedPaceDatesRef = ({
  accountId,
  db,
}: {
  accountId: string;
  db?: firebase.firestore.Firestore;
}) => {
  return (db ? db : firebase.firestore())
    .collection('accounts')
    .doc(accountId)
    .collection(STORE_CONSTANTS.VISUALISATIONS_COLLECTION)
    .doc(EXCLUDED_PACE_DATES_DOC)
    .withConverter(converter);
};

export default getExcludedPaceDatesRef;
