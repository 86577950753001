import React, { ChangeEvent, useContext, useEffect, useState } from 'react';

import getTimeStamp from 'getTimeStamp';
import getIdentifier from 'getIdentifier';
import CurrentUserContext from 'contexts/CurrentUserContext';
import usePerformanceSlideOutFilterInput from 'components/PerformanceBoards/hooks/usePerformanceSlideOutFilterInput';
import useSaveWidget from 'components/PerformanceBoards/hooks/useSaveWidget';
import PropertiesSlideoutWizard from './PropertiesSlideoutWizard';

const PropertiesSlideoutWizardContainer = ({
  close,
  editingSection,
}: {
  close: () => void;
  editingSection: PerformanceBoardTypes.SlideOut.PropertiesSection | undefined;
}) => {
  const { id: currentUserId } = useContext(CurrentUserContext);

  const [id] = useState<string>(
    editingSection ? editingSection.id : getIdentifier(),
  );
  const [createdBy] = useState<string>(
    editingSection ? editingSection.createdBy : currentUserId,
  );
  const [createdOn] = useState<string>(
    editingSection ? editingSection.createdOn : getTimeStamp(),
  );
  const [title, setTitle] = useState<string>(
    editingSection ? editingSection.title : '',
  );
  const [fields, setFields] = useState<string[]>(
    editingSection ? editingSection.fields : [],
  );
  const [dataset, setDataset] = useState<string | undefined>(
    editingSection ? editingSection.dataset : undefined,
  );
  const [propertiesSection, setPropertiesSection] = useState<
    PerformanceBoardTypes.SlideOut.PropertiesSection | undefined
  >();

  const filterInput = usePerformanceSlideOutFilterInput();
  const saveWidget = useSaveWidget({
    section: propertiesSection,
    editingSection,
  });

  const isDisabled =
    !dataset || dataset.length === 0 || fields.length === 0 || title === '';

  const onTitleChanged = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setTitle(event.target.value);
  };

  useEffect(() => {
    if (isDisabled) {
      setPropertiesSection(undefined);
      return;
    }

    const newPropertiesSection = {
      id: id,
      createdBy: createdBy,
      updatedBy: currentUserId,
      createdOn: createdOn,
      updatedOn: getTimeStamp(),
      sectionType: 'Properties' as 'Properties',
      title: title,
      dataset: dataset,
      fields: fields,
    };

    setPropertiesSection(newPropertiesSection);
  }, [
    createdBy,
    createdOn,
    currentUserId,
    dataset,
    editingSection,
    fields,
    id,
    isDisabled,
    title,
  ]);

  return (
    <PropertiesSlideoutWizard
      saveWidget={saveWidget}
      title={title}
      onTitleChanged={onTitleChanged}
      fields={fields}
      setFields={setFields}
      dataset={dataset}
      setDataset={setDataset}
      isDisabled={isDisabled}
      filterInput={filterInput}
      propertiesSection={propertiesSection}
      close={close}
    />
  );
};

export default PropertiesSlideoutWizardContainer;
