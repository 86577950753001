import TARGET_APPS from './constants';

const isConfiguredApp = (
  app: TargetsApp.App,
): app is TargetsApp.ConfiguredApp =>
  app.status === TARGET_APPS.CONFIGURED_STATUS;

const isUnConfiguredApp = (
  app: TargetsApp.App,
): app is TargetsApp.UnConfiguredApp =>
  app.status === TARGET_APPS.INITIAL_SETUP_STATUS;

const targetAppTypeCheckers = {
  isConfiguredApp,
  isUnConfiguredApp,
};

export default targetAppTypeCheckers;
