import React, { useContext, useEffect, useState, useCallback } from 'react';
import _ from 'lodash';
import usePopup from '../../../hooks/usePopup';
import { DropdownButton, List, ListItem } from '../../Inputs/Dropdown';
import useFieldDefinitions from '../../../hooks/useFieldDefinitions';
import InlineDialog from '../../InlineDialog';
import toSentenceCase from '../../../services/toSentenceCase';
import Typography from 'kingpin/atoms/Typography';
import DatasetDefinitionsContext from '../../../contexts/DatasetDefinitionsContext';
import TextInput from '../../../kingpin/atoms/TextInput';

const useGroupByFields = () => {
  const { datasets } = useContext(DatasetDefinitionsContext);
  const [types, setTypes] = useState<string[] | undefined>();
  const [groupByFields, setGroupByFields] = useState<string[]>([]);
  const { fieldDefinitions } = useFieldDefinitions(types);

  useEffect(() => {
    setTypes(datasets.map((set) => set.type));
  }, [datasets]);

  useEffect(() => {
    setGroupByFields(
      _.uniq(
        fieldDefinitions.filter((d) => d.type === 'text').map((d) => d.field),
      ),
    );
  }, [fieldDefinitions]);

  return groupByFields;
};

const GroupByInputContainer = ({
  groupBy,
  setGroupBy,
}: {
  groupBy?: string;
  setGroupBy: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
  const { isOpen, open, close } = usePopup();
  const groupByFields = useGroupByFields();

  const [searchText, setSearchText] = useState<string>('');
  const onSearchTextChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
    },
    [],
  );
  const filteredFields = groupByFields.filter((f) =>
    f.toLowerCase().includes(searchText.toLowerCase()),
  );
  const isDisabled = groupByFields.length === 0;

  return (
    <InlineDialog
      isOpen={isOpen}
      onClose={close}
      content={
        <div style={{ padding: '8px' }}>
          <TextInput
            autoFocus
            value={searchText}
            onChange={onSearchTextChanged}
            icon="search"
            inputSize="Small"
            placeholder="Search"
          />
          <List style={{ maxHeight: 400, overflowY: 'scroll' }}>
            {filteredFields.map((f) => (
              <ListItem key={f} onClick={() => setGroupBy(f)}>
                <Typography.Body type="Label">
                  {toSentenceCase(f)}
                </Typography.Body>
              </ListItem>
            ))}
            {filteredFields.length === 0 && (
              <Typography.Body type="Body 12">No items found</Typography.Body>
            )}
          </List>
        </div>
      }
    >
      <DropdownButton
        isOpen={isOpen}
        open={open}
        close={close}
        isDisabled={isDisabled}
        selectedLabel={groupBy ? toSentenceCase(groupBy) : groupBy}
        isPlaceholder={groupBy === undefined}
        placeholder={'Group By'}
      />
    </InlineDialog>
  );
};

export default GroupByInputContainer;
