import {
  IServerSideGetRowsParams,
  IServerSideDatasource,
} from 'ag-grid-community';
import moment from 'moment';

import getGridRows from '../../api/getGridRows';
import fetchTotalsGroupedByTerm from '../../api/fetchTotalsGroupedByTerm';
import getGridRowCount from '../../api/getGridRowCount';
import {
  CONTRACT_ON_TARGET_CELL_FIELD,
  CONTRACT_PROGRESS_CELL_FIELD,
  COUNT_QUERY,
  ON_TARGET_CELL_FIELD,
  PROGRESS_CELL_FIELD,
  RECORDS_QUERY,
  TOTALS_GROUPED_BY_TERM_QUERY,
} from './gridConstants';
import getBaseViewFields from './getBaseViewFields';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { CUSTOMER_LANE_COMMITMENTS } from '../../constants';

const getGroupBy = (params: IServerSideGetRowsParams) => {
  if (params.request.rowGroupCols.length > 0) {
    const groupsToUse = params.request.rowGroupCols.slice(
      params.request.groupKeys.length,
      params.request.groupKeys.length + 1,
    );
    // single level of grouping supported
    if (groupsToUse.length === 1) {
      return groupsToUse[0].field;
    }
  }
};

const getGridSortFields = ({
  params,
}: {
  params: IServerSideGetRowsParams;
}): GridSortField[] => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return params.request.sortModel.map((s: any) => ({
    field: s.colId,
    sort: s.sort,
  }));
};

const getGroupByRows = ({
  filterInput,
  dateScope,
  baseView,
  params,
  groupBy,
  sort,
  client,
}: {
  filterInput: FilterInput[];
  dateScope: DateRangeInput;
  baseView: FleetOps.BaseView;
  params: IServerSideGetRowsParams;
  groupBy: string;
  sort?: SimpleGridSort[];
  client: ApolloClient<NormalizedCacheObject>;
}) => {
  const { type } = baseView;
  const sortBy = getGridSortFields({ params });
  if (sortBy.length === 0 && !!sort) {
    sort.forEach((s) => {
      sortBy.push({ field: s.field, sort: s.direction });
    });
  }

  const aggFields = getBaseViewFields(baseView).map((c) => ({
    field: c.field,
    aggFunc: c.aggFunc,
  })) as GridAggregateField[];

  fetchTotalsGroupedByTerm({
    query: TOTALS_GROUPED_BY_TERM_QUERY,
    dataType: type,
    dateScope,
    filters: filterInput.map((f) =>
      getFilterInputWithGrouping({ filterInput: f, params }),
    ),
    fields: aggFields.filter((af) => af.field !== groupBy && !!af.aggFunc),
    groupBy,
    sortBy: sortBy.length > 0 ? [sortBy[0]] : ([] as GridSortField[]),
    client,
  }).then((rows) => {
    params.success({ rowData: rows, rowCount: rows.length });
  });
};

const getFilterInputWithGrouping = ({
  filterInput,
  params,
}: {
  filterInput: FilterInput;
  params: IServerSideGetRowsParams;
}): FilterInput => {
  if (params.request.groupKeys.length === 0) {
    return filterInput;
  }

  const result = { ...filterInput };
  params.request.groupKeys.forEach((key, index) => {
    const groupedField = params.request.rowGroupCols[index].field;
    if (!groupedField) {
      return;
    }

    if (groupedField !== 'date') {
      // @ts-ignore
      result.keywords = result.keywords
        .filter((f) => f.field !== groupedField)
        .concat([{ field: groupedField, values: [key] }]);
    }
  });

  return result;
};

const getDateScopeWithGrouping = ({
  dateScope,
  params,
}: {
  dateScope: DateRangeInput;
  params: IServerSideGetRowsParams;
}) => {
  if (params.request.groupKeys.length === 0) {
    return dateScope;
  }

  let result = { ...dateScope };
  params.request.groupKeys.forEach((key, index) => {
    const groupedField = params.request.rowGroupCols[index].field;
    if (!groupedField) {
      return;
    }
    if (groupedField === 'date') {
      result = {
        ...result,
        startDate: moment.utc(key).format('YYYY-MM-DD'),
        endDate: moment.utc(key).format('YYYY-MM-DD'),
      };
    }
  });

  return result;
};

const getRows = ({
  filterInput,
  dateScope,
  baseView,
  params,
  gridQueryOverride,
  gridCountQueryOverride,
  sort,
  client,
}: {
  filterInput: FilterInput[];
  dateScope: DateRangeInput;
  baseView: FleetOps.BaseView;
  params: IServerSideGetRowsParams;
  gridQueryOverride?: string;
  gridCountQueryOverride?: string;
  sort?: SimpleGridSort[];
  client: ApolloClient<NormalizedCacheObject>;
}) => {
  const { startRow, endRow } = params.request;
  const { type: dataType } = baseView;
  const from = startRow;
  const limit = (() => {
    if (endRow === undefined || startRow === undefined) {
      return 10;
    }
    return endRow - startRow;
  })();

  const sortBy = getGridSortFields({ params });
  if (sortBy.length === 0 && !!sort) {
    sort.forEach((s) => {
      sortBy.push({ field: s.field, sort: s.direction });
    });
  }

  if (sortBy.length === 1 && sortBy[0].field === ON_TARGET_CELL_FIELD) {
    sortBy.push({ field: 'differenceInProgress', sort: sortBy[0].sort });
  }
  if (
    sortBy.length === 1 &&
    sortBy[0].field === CONTRACT_ON_TARGET_CELL_FIELD
  ) {
    sortBy.push({
      field: 'contractDifferenceInProgress',
      sort: sortBy[0].sort,
    });
  }
  if (sortBy.length === 1 && sortBy[0].field === PROGRESS_CELL_FIELD) {
    sortBy.push({ field: 'difference', sort: sortBy[0].sort });
  }
  if (sortBy.length === 1 && sortBy[0].field === CONTRACT_PROGRESS_CELL_FIELD) {
    sortBy.push({ field: 'contractDifference', sort: sortBy[0].sort });
  }
  if (dataType === CUSTOMER_LANE_COMMITMENTS && sortBy.length === 0) {
    sortBy.push({ field: 'customer', sort: 'asc' });
    sortBy.push({ field: 'lane', sort: 'asc' });
    sortBy.push({ field: 'date', sort: 'desc' });
  }

  getGridRows({
    client,
    query: gridQueryOverride ? gridQueryOverride : RECORDS_QUERY,
    from,
    limit,
    sortBy,
    dataType,
    filters: filterInput.map((f) =>
      getFilterInputWithGrouping({ filterInput: f, params }),
    ),
    dateScope: getDateScopeWithGrouping({ dateScope, params }),
  }).then(async (rowData) => {
    const rowCount = await getGridRowCount({
      client,
      dataType,
      query: gridCountQueryOverride ? gridCountQueryOverride : COUNT_QUERY,
      filters: filterInput.map((f) =>
        getFilterInputWithGrouping({ filterInput: f, params }),
      ),
      dateScope,
    });

    params.success({
      rowData,
      rowCount,
    });
  });
};

const ServerSideDataSource = ({
  filterInput,
  dateScope,
  baseView,
  gridQueryOverride,
  gridCountQueryOverride,
  sort,
  client,
}: {
  filterInput: FilterInput[];
  dateScope: DateRangeInput;
  baseView: FleetOps.BaseView;
  gridQueryOverride?: string;
  gridCountQueryOverride?: string;
  sort?: SimpleGridSort[];
  client: ApolloClient<NormalizedCacheObject>;
}): IServerSideDatasource => {
  return {
    getRows: (params: IServerSideGetRowsParams) => {
      const groupBy = getGroupBy(params);
      if (groupBy) {
        getGroupByRows({
          filterInput,
          baseView,
          params,
          groupBy,
          sort,
          client,
          dateScope,
        });
      } else {
        getRows({
          dateScope,
          filterInput,
          baseView,
          params,
          gridQueryOverride,
          gridCountQueryOverride,
          sort,
          client,
        });
      }
    },
  };
};

export default ServerSideDataSource;
