import React from 'react';
import styled from 'styled-components';

import TargetValueInput from './TargetValueInput';

import Typography from 'kingpin/atoms/Typography';

const FallbackTargetBlockDiv = styled.div`
  padding: 0px 56px 0px 56px;

  display: flex;
  flex-direction: row;
`;

const FallbackTargetInput = ({
  group,
  target,
}: {
  group: Targets.Wizard.TargetGroup;
  target: Targets.Wizard.Target;
}) => {
  return (
    <FallbackTargetBlockDiv>
      <Typography.Body type="Body 12">{'Otherwise'}</Typography.Body>
      <div
        style={{
          marginLeft: 16,
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <TargetValueInput target={target} group={group} />
      </div>
    </FallbackTargetBlockDiv>
  );
};

export default FallbackTargetInput;
