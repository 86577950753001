import React, { useEffect, useState } from 'react';
import toSentenceCase from '../../../services/toSentenceCase';
import Inputs from 'components/Inputs';
import Typography from 'kingpin/atoms/Typography';
import Row from '../../Common/Row';

export const getComparisonLabel = (comparison?: PersistedComparisonType) => {
  if (!comparison) {
    return 'None';
  }

  if (comparison.compareType === 'previous') {
    return 'Previous Period';
  }

  if (comparison.compareType === 'lastYear') {
    return 'Same Period - Previous Year';
  }

  if (
    comparison.compareType === 'compareTo' &&
    !!comparison.relativeDateOption
  ) {
    return toSentenceCase(comparison.relativeDateOption);
  }

  return 'unknown';
};

const buildOptions = (
  shouldShowCompareTo: boolean,
  isOnlyPreviousAllowed: boolean,
) => {
  if (shouldShowCompareTo) {
    return [
      {
        value: undefined,
        label: 'None',
      },
      { value: { compareType: 'previous' }, label: 'Previous Period' },
      {
        value: { compareType: 'lastYear' },
        label: 'Same Period - Previous Year',
      },
      {
        value: {
          compareType: 'compareTo',
          relativeDateOption: 'last 7 days',
        },
        label: 'Previous 7 days',
      },
      {
        value: {
          compareType: 'compareTo',
          relativeDateOption: 'last 14 days',
        },
        label: 'Previous 14 days',
      },
      {
        value: {
          compareType: 'compareTo',
          relativeDateOption: 'last 30 days',
        },
        label: 'Previous 30 days',
      },
      {
        value: {
          compareType: 'compareTo',
          relativeDateOption: 'last 60 days',
        },
        label: 'Previous 60 days',
      },
      {
        value: {
          compareType: 'compareTo',
          relativeDateOption: 'last 90 days',
        },
        label: 'Previous 90 days',
      },
      {
        value: {
          compareType: 'compareTo',
          relativeDateOption: 'last 180 days',
        },
        label: 'Previous 180 days',
      },
      {
        value: {
          compareType: 'compareTo',
          relativeDateOption: 'last 365 days',
        },
        label: 'Previous 365 days',
      },
      {
        value: {
          compareType: 'compareTo',
          relativeDateOption: 'last week',
        },
        label: 'Previous week',
      },
      {
        value: {
          compareType: 'compareTo',
          relativeDateOption: 'last 4 weeks',
        },
        label: 'Last 4 weeks',
      },
      {
        value: {
          compareType: 'compareTo',
          relativeDateOption: 'last 13 weeks',
        },
        label: 'Last 13 weeks',
      },
      {
        value: {
          compareType: 'compareTo',
          relativeDateOption: 'last month',
        },
        label: 'Previous month',
      },
      {
        value: {
          compareType: 'compareTo',
          relativeDateOption: 'last year',
        },
        label: 'Previous year',
      },
    ] as { label: string; value: PersistedComparisonType | undefined }[];
  } else if (isOnlyPreviousAllowed) {
    return [
      {
        label: 'None',
        value: undefined,
      },
      {
        value: {
          compareType: 'previous',
        },
        label: 'Previous Period',
      },
    ] as { label: string; value: PersistedComparisonType | undefined }[];
  } else {
    return [
      {
        label: 'None',
        value: undefined,
      },
      {
        value: {
          compareType: 'previous',
        },
        label: 'Previous Period',
      },
      {
        value: {
          compareType: 'lastYear',
        },
        label: 'Same Period - Previous Year',
      },
    ] as { label: string; value: PersistedComparisonType | undefined }[];
  }
};

const ComparisonSelector = ({
  currentComparison,
  setCurrentComparison,
  track,
  shouldShowCompareTo,
  isOnlyPreviousAllowed,
}: {
  currentComparison?: PersistedComparisonType;
  setCurrentComparison: (c: PersistedComparisonType | undefined) => void;
  track: () => void;
  shouldShowCompareTo: boolean;
  isOnlyPreviousAllowed: boolean;
}) => {
  const [options, setOptions] = useState<DropdownOption[]>([]);
  const [selectedLabel, setSelectedLabel] = useState<string | undefined>();

  useEffect(() => {
    const selectedOption = options.find((o) => o.isSelected);
    if (selectedOption) {
      setSelectedLabel(selectedOption.label);
      return;
    }
    setSelectedLabel(undefined);
  }, [options]);

  useEffect(() => {
    setOptions(
      buildOptions(shouldShowCompareTo, isOnlyPreviousAllowed).map((o) => {
        const isSelected = (() => {
          if (currentComparison === undefined) {
            return false;
          }
          if (o.value === undefined) {
            return false;
          }
          return (
            o.value.compareType === currentComparison.compareType &&
            o.value.relativeDateOption === currentComparison.relativeDateOption
          );
        })();
        return {
          label: o.label,
          isSelected,
          onSelected: () => {
            setCurrentComparison(o.value);
            track();
          },
        };
      }),
    );
  }, [
    currentComparison,
    isOnlyPreviousAllowed,
    setCurrentComparison,
    shouldShowCompareTo,
    track,
  ]);

  return (
    <Inputs.Dropdown
      options={options}
      placeholder="Select a comparison"
      renderSelectedLabel={
        selectedLabel
          ? () => (
              <Row centerAlign>
                <div style={{ marginRight: 4, display: 'flex' }}>
                  <Typography.Body type={'Placeholder'}>
                    Comparison:
                  </Typography.Body>
                </div>
                <Typography.Body type={'Label'}>
                  {selectedLabel}
                </Typography.Body>
              </Row>
            )
          : undefined
      }
    />
  );
};

export default ComparisonSelector;
