import React from 'react';
import styled from 'styled-components';
import Row from '../Common/Row';
import Tooltip from '../Tooltip';
import VideoCardEditForm from './VideoCardEditForm';
import ShowOnMouseOverUnlessTour from '../ShowOnMouseOverUnlessTour';
import Button from '../../kingpin/atoms/Button';

const Video = styled.video`
  width: 100%;
  height: 100%;
`;

const Wrapper = styled.div`
  padding: 16px;
  display: flex;
  flex: 1;

  .showOnMouseOver {
    visibility: hidden;
  }

  &:hover {
    > .showOnMouseOver {
      visibility: visible;
    }
  }
`;

const VideoGadget = ({
  src,
  setSrc,
  storagePath,
  openEditForm,
  closeEditForm,
  isEditFormOpen,
}: {
  src: string;
  setSrc: React.Dispatch<React.SetStateAction<string | undefined>>;
  storagePath: string;
  openEditForm: () => void;
  closeEditForm: () => void;
  isEditFormOpen: boolean;
}) => (
  <Wrapper>
    <ShowOnMouseOverUnlessTour>
      <Row
        style={{
          position: 'absolute',
          right: 13,
          top: 5,
          justifyContent: 'flex-end',
        }}
      >
        <Tooltip content={'Edit'}>
          <div style={{ marginLeft: 8 }}>
            <Button
              onClick={openEditForm}
              size="Small"
              type="Secondary"
              icon={'edit-filled'}
            />
          </div>
        </Tooltip>
      </Row>
    </ShowOnMouseOverUnlessTour>
    <div style={{ padding: 16 }}>
      <Video src={src} autoPlay loop />
    </div>
    <VideoCardEditForm
      close={closeEditForm}
      isOpen={isEditFormOpen}
      storagePath={storagePath}
      originalSrc={src}
      setSrc={setSrc}
    />
  </Wrapper>
);

export default VideoGadget;
