import React, { ChangeEvent } from 'react';

interface LocalizedTasksContextType {
  tasks: Tasks.Task[];
  isLoading: boolean;
  sources: Tasks.TaskSource[];
  destination?: Tasks.TaskSource;
  onTaskCompletedToggled: (task: Tasks.Task) => Promise<void>;
  onTaskUpdated: (
    updatedTask: Tasks.Task,
    oldTask: Tasks.Task,
  ) => Promise<void>;
  onTaskCreated?: (newTaskProps: Tasks.TaskCreationProps) => Promise<void>;
  onTaskDeleted: (task: Tasks.Task) => Promise<void>;
  searchText: string;
  onSearchTextChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  isShowingCompleted: boolean;
  setIsShowingCompleted: React.Dispatch<React.SetStateAction<boolean>>;
  assignableUserIds?: string[];
  isNoTasks: boolean;
  isAllTasksComplete: boolean;
}

const LocalTasksContext = React.createContext<LocalizedTasksContextType>({
  tasks: [],
  isLoading: true,
  sources: [],
  destination: undefined,

  onTaskCompletedToggled: () => {
    alert('Something went wrong');
    return Promise.resolve();
  },
  onTaskUpdated: () => {
    alert('Something went wrong');
    return Promise.resolve();
  },
  onTaskDeleted: () => {
    alert('Something went wrong');
    return Promise.resolve();
  },
  searchText: '',
  onSearchTextChanged: () => {},
  isShowingCompleted: false,
  setIsShowingCompleted: () => {},
  isNoTasks: false,
  isAllTasksComplete: false,
} as LocalizedTasksContextType);

export default LocalTasksContext;
