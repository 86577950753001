import firebase from 'firebase/compat/app';
import withoutNulls from '../api/search/withoutNulls';
import STORE_CONSTANTS from './constants';

const reasonCodeConverter = {
  toFirestore(reasonCode: ReasonCode): firebase.firestore.DocumentData {
    return withoutNulls({ ...reasonCode });
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ): ReasonCode {
    const data = snapshot.data(options);
    return data as ReasonCode;
  },
};

const getReasonCodesRef = ({
  accountId,
  dataset,
  db,
}: {
  accountId: string;
  dataset: string;
  db?: firebase.firestore.Firestore;
}) => {
  return (db ? db : firebase.firestore())
    .collection('accounts')
    .doc(accountId)
    .collection(STORE_CONSTANTS.REASON_CODES_COLLECTION)
    .where('boardType', '==', dataset)
    .withConverter(reasonCodeConverter);
};

export default getReasonCodesRef;
