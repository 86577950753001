const CREATE: FleetOps.Permission =
  'fleetops.permissions.dashboard_management_create';
const CREATE_TEMPLATE: FleetOps.Permission =
  'fleetops.permissions.dashboard_management_create_template';
const EDIT: FleetOps.Permission =
  'fleetops.permissions.dashboard_management_edit';
const DELETE_CREATED_BY_ME: FleetOps.Permission =
  'fleetops.permissions.dashboard_management_delete_created_by_me';
const DELETE_ANY_DASHBOARD: FleetOps.Permission =
  'fleetops.permissions.dashboard_management_delete_any';

const DASHBOARD_MANAGEMENT_PERMISSIONS = {
  CREATE,
  CREATE_TEMPLATE,
  EDIT,
  DELETE_CREATED_BY_ME,
  DELETE_ANY_DASHBOARD,
};
Object.freeze(DASHBOARD_MANAGEMENT_PERMISSIONS);

export default DASHBOARD_MANAGEMENT_PERMISSIONS;
