import { useContext, useEffect, useState } from 'react';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import MetricOptionsContext from '../../../contexts/MetricOptionsContext';
import useFilterInput from '../../../hooks/useFilterInput';
import isDefined from '../../../isDefined';
import getPeerComparison from './getPeerComparison';
import useDateScope from '../../../hooks/useDateScope';
import GqlClientContext from '../../../contexts/GqlClientContext';
import useNetworkingEffect from '../../../hooks/useNetworkingEffect';
import metricTypeCheckers from '../../../types/metricTypeCheckers';

const usePeerComparison = ({
  gauge,
}: {
  gauge: VisualisationDefinitions.Gauge;
}) => {
  const { client } = useContext(GqlClientContext);
  const { metricOptions } = useContext(MetricOptionsContext);
  const [data, setData] = useState<GaugeData | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [args, setArgs] = useState<
    | {
        metrics: MetricInput[];
        expressions: Expression2[];
        dateScope: DateRangeInput;
        filterInput: FilterInput[];
        peerGroup: string;
        entity: string;
        client: ApolloClient<NormalizedCacheObject>;
      }
    | undefined
  >();
  const [formatting, setFormatting] = useState<MetricFormatting | undefined>();
  const filterInput = useFilterInput();
  const dateScope = useDateScope({});

  useEffect(() => {
    const usedMetric = metricOptions.find((m) => m.id === gauge.metricId);
    if (!usedMetric) {
      return;
    }

    if (metricTypeCheckers.isCompoundMetric(usedMetric)) {
      const metrics = usedMetric.metricIds
        .map((mid) => metricOptions.find((mo) => mo.id === mid))
        .filter(isDefined)
        .filter(metricTypeCheckers.isNormalMetric);

      if (metrics.length !== usedMetric.metricIds.length) {
        return; // Missing some metrics
      }

      setArgs({
        metrics,
        expressions: [{ id: usedMetric.id, expression: usedMetric.expression }],
        filterInput: [filterInput],
        peerGroup: gauge.peerGroup,
        entity: gauge.entity,
        dateScope,
        client,
      });
    } else if (metricTypeCheckers.isNormalMetric(usedMetric)) {
      setArgs({
        metrics: [usedMetric],
        expressions: [{ id: usedMetric.id, expression: usedMetric.id }],
        filterInput: [filterInput],
        peerGroup: gauge.peerGroup,
        entity: gauge.entity,
        dateScope,
        client,
      });
    }
    setFormatting(usedMetric.formatting);
  }, [
    client,
    dateScope,
    filterInput,
    gauge.entity,
    gauge.metricId,
    gauge.peerGroup,
    metricOptions,
  ]);

  useNetworkingEffect(() => {
    if (args) {
      let isActive = true;
      setIsLoading(true);
      getPeerComparison(args).then((response) => {
        if (!isActive) {
          return;
        }
        const d = response.find((r) => r.id === gauge.metricId);
        if (d) {
          setData(d);
        }
        setIsLoading(false);
      });

      return () => {
        isActive = false;
      };
    }
  }, [client, args, gauge.metricId]);

  return {
    isLoading,
    data,
    formatting,
  };
};

export default usePeerComparison;
