import React, { useCallback } from 'react';
import InlineDialog, { Placement } from '../InlineDialog';
import Button from 'kingpin/atoms/Button';

const ContextMenu = ({
  open,
  close,
  isOpen,
  testId,
  placement,
  children,
}: {
  open: () => void;
  close: () => void;
  isOpen: boolean;
  testId?: string;
  placement?: Placement;
  children: JSX.Element;
}) => {
  const onClick = useCallback(
    (event: React.MouseEvent<any>) => {
      event.stopPropagation();
      if (isOpen) {
        close();
      } else {
        open();
      }
    },
    [close, isOpen, open],
  );

  return (
    <InlineDialog
      content={children}
      isOpen={isOpen}
      onClose={close}
      placement={placement}
    >
      <Button
        onClick={onClick}
        icon="dots-menu"
        testId={testId}
        type="Ghost"
        size="Small"
      />
    </InlineDialog>
  );
};

export default ContextMenu;
