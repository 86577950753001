import React, { useEffect, useState } from 'react';
import Typography from 'kingpin/atoms/Typography';
import Tooltip from '../../../Tooltip';
import PlateStyles from './PlateStyles';

const ValuesPlate = ({
  terms,
  fieldName,
}: {
  terms: string[];
  fieldName: string;
}) => {
  const [tooltipContent] = useState(() => terms.join(', '));

  if (terms.length === 0) {
    return <PlateStyles.RightPlate />;
  }

  if (terms.length === 1) {
    return (
      <PlateStyles.RightPlate>
        <Typography.Body type="Body 12">{terms[0]}</Typography.Body>
      </PlateStyles.RightPlate>
    );
  }

  return (
    <PlateStyles.RightPlate>
      <Tooltip content={tooltipContent}>
        <Typography.Body type="Body 12">{`${terms.length} ${fieldName}s`}</Typography.Body>
      </Tooltip>
    </PlateStyles.RightPlate>
  );
};

const KeywordFilterPlate = ({ filter }: { filter: KeywordFilter }) => {
  const [terms] = useState<string[]>(() => {
    if (filter.exists === undefined || filter.exists === true) {
      return filter.values;
    }

    return ['Blanks', ...filter.values];
  });
  const [testId, setTestId] = useState<string>();
  const [middleText] = useState<string>(() => {
    if (filter.exclude) {
      if (terms.length === 1) {
        return 'is not';
      } else {
        return 'is not one of';
      }
    } else {
      if (terms.length === 1) {
        return 'is';
      } else {
        return 'is one of';
      }
    }
  });

  useEffect(() => {
    setTestId(filter.field + middleText + terms.join(', '));
  }, [filter, middleText, terms]);

  return (
    <div className="filter-plate" data-testid={testId}>
      <PlateStyles.Plate>
        <PlateStyles.LeftPlate>
          <Typography.Body type="Body 12">{`${filter.field}`}</Typography.Body>
        </PlateStyles.LeftPlate>
        <PlateStyles.MiddlePlate>
          <Typography.Body type="Body 12">{middleText}</Typography.Body>
        </PlateStyles.MiddlePlate>
        <ValuesPlate terms={terms} fieldName={filter.field} />
      </PlateStyles.Plate>
    </div>
  );
};

export default KeywordFilterPlate;
