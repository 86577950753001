import { useCallback, useContext, useEffect, useState } from 'react';
import CloudFunctionClientContext from '../../../../../contexts/CloudFunctionClientContext';
import captureException from '../../../../../services/captureException';
import getAvailableGroups from '../api/getAvailableGroups';

const useAvailableGroups = () => {
  const { usEastApi: api } = useContext(CloudFunctionClientContext);
  const [availableGroups, setAvailableGroups] = useState<MicrosoftSso.Group[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const getGroups = useCallback(() => {
    return getAvailableGroups(api).then((res) => {
      if (res.ok && res.data && res.data.groups) {
        return res.data.groups;
      }
      const e = new Error();
      e.name = 'Failed to fetch groups';
      throw e;
    });
  }, [api]);

  useEffect(() => {
    getGroups()
      .then((groups) => {
        if (groups) {
          setAvailableGroups(groups);
        }
        setIsLoading(false);
      })
      .catch((ex) => {
        captureException(ex);
        alert('Something went wrong. Please contact support@fleetops.com');
      });
  }, [getGroups]);

  return {
    availableGroups,
    isLoading,
  };
};

export default useAvailableGroups;
