import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import BonusSignOff from './BonusSignOff';
import BoardContext from '../../../contexts/BoardContext';
import CurrentUserContext from '../../../contexts/CurrentUserContext';
import updateBoard from '../../../api/boards/updateBoard';
import BonusPeriodsContext from '../../../contexts/BonusPeriodsContext';
import isDriverPerformanceBoard from '../../../isDriverPerformanceBoard';
import BonusSignOffConfirmation from '../BonusSignOffConfirmation/BonusSignOffConfirmation';
import WorkSpaceContext from '../../../contexts/WorkSpaceContext';
import isDefined from '../../../isDefined';
import AccountPickerContext from '../../../contexts/AccountPickerContext';
import useUsers from '../../../hooks/useUsers';
import BoardsContext from '../../../contexts/BoardsContext';
import useGrantRevokeRole from '../../../hooks/useGrantRevokeRole';
import Roles from '../../../roles';

const BonusSignOffContainer = ({
  board,
  selectedBonusPeriod,
  workSpace,
}: {
  board: DriverPerformanceBoard;
  selectedBonusPeriod: BonusPeriod;
  workSpace: DriverBonusWorkSpace;
}) => {
  const users = useUsers();
  const { accountRef } = useContext(AccountPickerContext);
  const { boards } = useContext(BoardsContext);
  const { id: currentUserId } = useContext(CurrentUserContext);
  const [assignedUsers, setAssignedUsers] = useState<string[]>(
    () => board.assignedToId,
  );
  const [workSpaceUsers, setWorkSpaceUsers] = useState<
    (
      | UserManagement.SignedUpUser
      | UserManagement.PendingUser
      | UserManagement.FleetOpsStaffUser
    )[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isCurrentUserAssigned = assignedUsers.includes(currentUserId);
  const [isSignedOff, setIsSignedOff] = useState<boolean>(false);
  const [signedOffById, setSignedOffById] = useState<string | undefined>();
  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  const { grantRole, revokeRole } = useGrantRevokeRole();

  useEffect(() => {
    if (selectedBonusPeriod && board.signOffs) {
      const c = board.signOffs[selectedBonusPeriod.id];
      if (typeof c === 'object') {
        setSignedOffById(c.signedOffById);
      } else {
        setSignedOffById(undefined);
      }
      setIsSignedOff(!!c);
    }
  }, [board.signOffs, selectedBonusPeriod]);

  useEffect(() => {
    const userIds = workSpace.access.userIds;
    if (userIds === undefined) {
      setWorkSpaceUsers([]);
    } else {
      setWorkSpaceUsers(
        userIds.map((uid) => users.find((u) => u.id === uid)).filter(isDefined),
      );
    }
  }, [users, workSpace.access.userIds]);

  useEffect(() => {
    if (
      !isLoading &&
      !_.isEqual(assignedUsers, board.assignedToId) &&
      !(assignedUsers.length === 0 && board.assignedToId === undefined)
    ) {
      const newBoard = {
        ...board,
        assignedToId: assignedUsers,
        assignedById: currentUserId,
      };
      const newlyAssignedUsers = assignedUsers.filter((uid) => {
        return !board.assignedToId.includes(uid);
      });
      const removedUsers = board.assignedToId.filter((uid) => {
        return !assignedUsers.includes(uid);
      });

      const driverBonusBoardsExcludingCurrent = boards
        .filter(isDriverPerformanceBoard)
        .filter((b) => !b.notAssignable)
        .filter((b) => !!b.assignedToId)
        .filter((b) => b.id !== board.id);

      const isUserAssignedToAnyBoardOtherThanCurrent = (userId: string) => {
        return driverBonusBoardsExcludingCurrent.some((board) =>
          board.assignedToId.includes(userId),
        );
      };

      const usersToGrant = newlyAssignedUsers.filter(
        (u) => !isUserAssignedToAnyBoardOtherThanCurrent(u),
      );

      const usersToRevoke = removedUsers.filter(
        (u) => !isUserAssignedToAnyBoardOtherThanCurrent(u),
      );

      setIsLoading(true);
      const grantRequests = usersToGrant.map((u) =>
        grantRole(u, Roles.BOARD_OWNER),
      );
      const revokeRequests = usersToRevoke.map((u) =>
        revokeRole(u, Roles.BOARD_OWNER),
      );

      Promise.all([...grantRequests, ...revokeRequests]).then(() => {
        updateBoard(newBoard, accountRef).then(() => {
          setIsLoading(false);
        });
      });
    }
  }, [
    assignedUsers,
    board,
    currentUserId,
    isLoading,
    accountRef,
    boards,
    grantRole,
    revokeRole,
  ]);

  const onSignedOff = () => {
    if (selectedBonusPeriod) {
      setIsSignedOff(true);
      const newSignOffs = {
        ...board.signOffs,
      } as { [key: string]: boolean | { signedOffById?: string } };
      newSignOffs[selectedBonusPeriod.id] = { signedOffById: currentUserId };
      const newBoard = {
        ...board,
        signOffs: newSignOffs,
      };
      setIsLoading(true);
      updateBoard(newBoard, accountRef).then(() => {
        setIsLoading(false);
        setIsConfirming(false);
      });
    }
  };

  const onSignedOffChanged = (newStatus: boolean) => {
    if (newStatus) {
      setIsConfirming(true);
    }
  };

  const assignedUserDocs = assignedUsers
    .map((id) => workSpaceUsers.find((u) => u.id === id))
    .filter(isDefined);
  const assignedLabelOverride =
    assignedUserDocs.length === 0
      ? 'Assign Users'
      : assignedUserDocs.map((u) => u.displayName).join(', ');

  const isSignOffReady = selectedBonusPeriod.status === 'pending sign off';

  return (
    <React.Fragment>
      <BonusSignOff
        isLoading={isLoading}
        assignedUsers={assignedUsers}
        setAssignedUsers={setAssignedUsers}
        isCurrentUserAssigned={isCurrentUserAssigned}
        isSignedOff={isSignedOff}
        signedOffById={signedOffById}
        onSignedOffChanged={onSignedOffChanged}
        isSignOffReady={isSignOffReady}
        assignedLabelOverride={assignedLabelOverride}
      />

      <BonusSignOffConfirmation
        board={board}
        close={() => {
          setIsConfirming(false);
        }}
        isOpen={isConfirming}
        onConfirm={onSignedOff}
        isLoading={isLoading}
      />
    </React.Fragment>
  );
};

const Gate = () => {
  const { driverBonusWorkSpace: workSpace } = useContext(WorkSpaceContext);
  const { board } = useContext(BoardContext);
  const { selectedBonusPeriod } = useContext(BonusPeriodsContext);

  if (isDriverPerformanceBoard(board) && !!selectedBonusPeriod && !!workSpace) {
    if (board.notAssignable) {
      return null;
    }

    return (
      <BonusSignOffContainer
        board={board}
        selectedBonusPeriod={selectedBonusPeriod}
        workSpace={workSpace}
      />
    );
  } else {
    return null;
  }
};

export default Gate;
