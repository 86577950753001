import React, { useContext, useState } from 'react';
import handleViewport, { InjectedViewportProps } from 'react-in-viewport';
import CurrentUserContext from '../../contexts/CurrentUserContext';
import WallboardContext from '../../contexts/WallboardContext';

const IS_TEST_ENV = process.env.NODE_ENV === 'test';

interface Props extends InjectedViewportProps<HTMLDivElement> {
  children: JSX.Element | JSX.Element[];
}

const RenderWhenInView = ({ children, enterCount, forwardedRef }: Props) => {
  if (enterCount === 0) {
    return <div ref={forwardedRef} />;
  }
  return children;
};

const Wrapped = IS_TEST_ENV
  ? ({ children }: { children: JSX.Element | JSX.Element[] }) => <>{children}</>
  : // @ts-ignore
    handleViewport(RenderWhenInView);

const Gate = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const [isSupported] = useState<boolean>(!!window.IntersectionObserver);
  const { isWallboard } = useContext(WallboardContext);
  const { isWallboardUser } = useContext(CurrentUserContext);

  if (isWallboardUser || isWallboard) {
    return <>{children}</>;
  }

  if (isSupported) {
    return <Wrapped>{children}</Wrapped>;
  } else {
    return <>{children}</>;
  }
};

export default Gate;
