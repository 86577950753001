import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

const createPerformanceDatasetConfig = async ({
  createArgs,
  client,
}: {
  createArgs: PerformanceConfiguration.CreatePerformanceDatasetArgs;
  client: ApolloClient<NormalizedCacheObject>;
}): Promise<string> => {
  const mutation = {
    mutation: gql`
      mutation createPerformanceDatasetConfig(
        $name: String!
        $entity: String!
        $entityWindowLength: Int
        $entityWindowDateField: String
        $entityDataTypes: [String]
        $interval: Interval!
        $intervalLength: Int = 1
        $startDate: Date!
        $startOfWeek: StartOfWeek
        $metrics: [PerformanceMetricInput]!
        $fields: [PerformanceFieldsInput!]!
      ) {
        createPerformanceDatasetConfig(
          name: $name
          entity: $entity
          entityWindowLength: $entityWindowLength
          entityWindowDateField: $entityWindowDateField
          entityDataTypes: $entityDataTypes
          interval: $interval
          intervalLength: $intervalLength
          startDate: $startDate
          startOfWeek: $startOfWeek
          metrics: $metrics
          fields: $fields
        )
      }
    `,
    variables: {
      ...createArgs,
    },
  };

  const response = await client.mutate(mutation);
  return response.data.createPerformanceDatasetConfig;
};

export default createPerformanceDatasetConfig;
