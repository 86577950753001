import React from 'react';
import styled, { css } from 'styled-components';

const BorderCss = css`
  box-shadow:
    0px 1px 3px rgba(63, 63, 68, 0.12),
    0px 0px 0px 1px rgba(63, 63, 68, 0.07);
`;
const Positioner = styled.div<{ noMargin?: boolean }>`
  margin-bottom: ${(props) => (props.noMargin ? '0px' : '16px')};
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;
const Outer = styled.div<{ noBorder?: boolean }>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 1px;

  ${(props) => !props.noBorder && BorderCss}
`;
const Inner = styled.div`
  margin: 1px;
  overflow-y: auto;
`;
const HeaderWrapper = styled.div`
  margin: 1px;
`;

const List = ({
  Headers,
  Body,
  noBorder,
  noMargin,
}: {
  Headers: JSX.Element | null;
  Body: JSX.Element;
  noBorder?: boolean;
  noMargin?: boolean;
}) => (
  <Positioner noMargin={noMargin}>
    <Outer noBorder={noBorder}>
      {Headers ? <HeaderWrapper>{Headers}</HeaderWrapper> : null}
      <Inner>{Body}</Inner>
    </Outer>
  </Positioner>
);

export default List;
