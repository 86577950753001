import React from 'react';
import Button from 'kingpin/atoms/Button';

import PopupStyles from '../../MetricPicker/MetricPickerPopup/PopupStyles';
import Row from '../../../Common/Row';
import Typography from 'kingpin/atoms/Typography';

const SelectedFields = ({
  selectedFieldsCount,
}: {
  selectedFieldsCount: number;
}) => (
  <Row centerAlign style={{ marginRight: 24 }}>
    <Typography.Body type="Body 12">Selected Field(s):</Typography.Body>
    <Typography.Body type="Body 12">{`${selectedFieldsCount} Fields`}</Typography.Body>
  </Row>
);

const BottomBar = ({
  isConfirmEnabled,
  onCancelClicked,
  selectedFieldsCount,
  onConfirmClicked,
  isSaving,
}: {
  selectedFieldsCount: number;
  isConfirmEnabled: boolean;
  onConfirmClicked: () => void;
  onCancelClicked: () => void;
  isSaving?: boolean;
}) => (
  <PopupStyles.PopupContent.BottomBarDiv centerAlign spaceBetween>
    <Button
      onClick={onCancelClicked}
      label="Cancel"
      type="Tertiary"
      size="Small"
    />
    <Row centerAlign>
      <SelectedFields selectedFieldsCount={selectedFieldsCount} />
      <Button
        onClick={onConfirmClicked}
        label="Confirm Selection"
        isDisabled={!isConfirmEnabled}
        isLoading={isSaving}
        type="Primary"
        size="Small"
      />
    </Row>
  </PopupStyles.PopupContent.BottomBarDiv>
);

export default BottomBar;
