import React from 'react';
import DatasetsIndex from './DatasetsIndex';
import useDatasetList from './hooks/useDatasetsIndex';
import ReactPortal from '../../../../components/ReactPortal';
import { DATA_MANAGER_TOP_ACTION_DIV_ID } from '../../AllSets/constants';
import CreatePerformanceDatasetButton from './CreatePerformanceDatasetButton';

const DatasetsIndexContainer = () => {
  const {
    searchText,
    onSearchTextChanged,
    sortByOptions,
    isLoading,
    datasetList,
  } = useDatasetList();

  return (
    <>
      <DatasetsIndex
        searchText={searchText}
        onSearchTextChanged={onSearchTextChanged}
        sortByOptions={sortByOptions}
        isLoading={isLoading}
        datasetList={datasetList}
      />
      <ReactPortal elementId={DATA_MANAGER_TOP_ACTION_DIV_ID}>
        <CreatePerformanceDatasetButton />
      </ReactPortal>
    </>
  );
};

export default DatasetsIndexContainer;
