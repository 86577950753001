import React from 'react';
import ContextMethodNotFound from '../../../errors/ContextMethodNotFoundError';

interface TargetListContextType {
  dataset: string;
  selectedField?: string;
  targets: Targets.Wizard.DataTypeTarget[];
  targetsForField: Targets.Wizard.DataTypeTarget[];
  onCopyRulesToField: ({
    field,
    target,
  }: {
    field: string;
    target: Targets.Wizard.DataTypeTarget;
  }) => void;
  onTargetSelected: (target: Targets.Wizard.DataTypeTarget) => void;
  onDuplicateRules: (target: Targets.Wizard.DataTypeTarget) => void;
  refreshTargets: () => Promise<void>;
  onTargetCreated: () => void;
  onTargetUpdated: () => void;
  isAddTargetPrimary: boolean;
  setIsAddTargetPrimary: React.Dispatch<React.SetStateAction<boolean>>;
  hasUnsavedChanges: boolean;
  setHasUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const TargetListContext = React.createContext<TargetListContextType>({
  dataset: 'NA',
  targets: [],
  targetsForField: [],
  onCopyRulesToField: () => {
    throw new ContextMethodNotFound('onCopyRulesToField');
  },
  onTargetSelected: () => {
    throw new ContextMethodNotFound('onTargetSelected');
  },
  onDuplicateRules: () => {
    throw new ContextMethodNotFound('onDuplicateRules');
  },
  refreshTargets: () => {
    throw new ContextMethodNotFound('refreshTargets');
  },
  onTargetUpdated: () => {
    throw new ContextMethodNotFound('onTargetUpdated');
  },
  onTargetCreated: () => {
    throw new ContextMethodNotFound('onTargetCreated');
  },
  isAddTargetPrimary: true,
  setIsAddTargetPrimary: () => {
    throw new ContextMethodNotFound('setIsAddTargetPrimary');
  },
  hasUnsavedChanges: false,
  setHasUnsavedChanges: () => {
    throw new ContextMethodNotFound('setHasUnsavedChanges');
  },
});

export default TargetListContext;
