import React from 'react';
import Button from 'kingpin/atoms/Button';
import Row from '../../Common/Row';
import Inputs from 'components/Inputs';

const BooleanPicker = ({
  isValid,
  onConfirm,
  isEditing,
  options,
}: {
  isValid: boolean;
  onConfirm: () => void;
  isEditing: boolean;
  options: RadioOption[];
}) => (
  <div style={{ padding: 8 }}>
    <Inputs.Radio options={options} />
    <Row centerAlign style={{ justifyContent: 'flex-end', marginTop: 16 }}>
      <Button
        onClick={onConfirm}
        isDisabled={!isValid}
        type="Primary"
        size="Small"
        label={isEditing ? 'Update' : 'Add'}
      />
    </Row>
  </div>
);

export default BooleanPicker;
