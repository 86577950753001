import { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import AccountPickerContext from '../contexts/AccountPickerContext';

const useComments = (
  commentableId: string,
  commentableType: CommentableType,
) => {
  const [comments, setComments] = useState<CommentType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { accountRef } = useContext(AccountPickerContext);

  useEffect(() => {
    if (process.env.NODE_ENV === 'test') {
      return () => {};
    }

    const listener = (accountRef as any)
      .collection('comments')
      .where('commentableType', '==', commentableType)
      .where('commentableId', '==', commentableId)
      .onSnapshot((snapshot: any) => {
        const data: CommentType[] = [];
        snapshot.docs.forEach((d: any) => data.push({ id: d.id, ...d.data() }));
        setComments(_.sortBy(data, 'createdOn'));
        setIsLoading(false);
      });
    return () => {
      listener();
    };
  }, [setComments, isLoading, accountRef, commentableType, commentableId]);

  return {
    comments,
    isLoading,
  };
};

export default useComments;
