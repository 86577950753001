import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import appRoutes from './appRoutes';
import LoginScreen from '../screens/Login';
import ResetPasswordScreen from '../screens/ResetPassword';
import WelcomeScreen from '../screens/AcceptInvite';
import CantSignInScreen from '../screens/CantSignIn';
import ExpiredInvitation from '../screens/ExpiredInvitation';
import WallBoardSetUp from '../screens/WallBoardSetUp';
import AcceptInviteScreen from '../screens/AcceptInvite';
import InvitationAlreadyAccepted from '../screens/InvitationAlreadyAccepted';

const RedirectToLogin = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(appRoutes.home);
  }, [navigate]);

  return null;
};

const UnAuthenticated = () => (
  <div>
    <Routes>
      <Route path={appRoutes.home} element={<LoginScreen />} />
      <Route path={appRoutes.logIn} element={<LoginScreen />} />
      <Route path={appRoutes.welcome} element={<WelcomeScreen />} />
      <Route path={appRoutes.acceptInvite} element={<AcceptInviteScreen />} />
      <Route path={appRoutes.forgotPassword} element={<CantSignInScreen />} />
      <Route
        path={appRoutes.expiredInvitation}
        element={<ExpiredInvitation />}
      />
      <Route
        path={appRoutes.alreadyAcceptedInvitation}
        element={<InvitationAlreadyAccepted />}
      />
      <Route path={appRoutes.resetPassword} element={<ResetPasswordScreen />} />
      <Route path={appRoutes.wallboardSetup} element={<WallBoardSetUp />} />
      <Route path="*" element={<RedirectToLogin />} />
    </Routes>
  </div>
);

export default UnAuthenticated;
