const cadenceToLabel = (c: CommitmentCadence) => {
  if (c === 'weekly') {
    return 'Week';
  } else if (c === 'monthly') {
    return 'Month';
  } else if (c === 'quarterly') {
    return 'Quarter';
  } else if (c === 'yearly') {
    return 'Year';
  } else {
    return c;
  }
};

export default cadenceToLabel;
