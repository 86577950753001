import firebase from 'firebase/compat/app';
import withoutNulls from '../search/withoutNulls';
import { WORKSPACES_COLLECTION } from '../../constants';

const setWorkSpace = async (
  workspace: WorkSpace,
  accountRef: firebase.firestore.DocumentReference,
) => {
  return accountRef
    .collection(WORKSPACES_COLLECTION)
    .doc(workspace.id)
    .set(withoutNulls(workspace));
};

export default setWorkSpace;
