import React, { useContext, useState } from 'react';

import DashboardGadgetBottomBar from './DashboardGadgetBottomBar';
import DashboardGadgetContext from '../../contexts/DashboardGadgetContext';
import CanvasContext from '../../contexts/CanvasContext';
import CardContext from '../../contexts/CardContext';
import useLastUpdatedAt from '../../hooks/dashboards/useLastUpdatedAt';

const DashboardGadgetBottomBarContainer = ({
  dashboardGadget,
}: {
  dashboardGadget: DashboardGadget;
}) => {
  const { timeAgo } = useLastUpdatedAt(dashboardGadget);

  const { setCardOnTopI } = useContext(CanvasContext);
  const { i, w } = useContext(CardContext);
  const [isForcedVisible, setIsForcedVisible] = useState<boolean>(false);
  const onDatePickerOpen = () => {
    setCardOnTopI(i);
    setIsForcedVisible(true);
  };
  const onDatePickerClose = () => {
    setIsForcedVisible(false);
  };
  const isNarrow = w <= 2;

  return (
    <DashboardGadgetBottomBar
      isNarrow={isNarrow}
      onDatePickerOpen={onDatePickerOpen}
      onDatePickerClose={onDatePickerClose}
      isForcedVisible={isForcedVisible}
      timeAgo={timeAgo}
      isBonusPeriodMode={dashboardGadget.isBonusPeriodMode}
    />
  );
};

const RenderGate = () => {
  const { dashboardGadget, isEditing } = useContext(DashboardGadgetContext);
  if (dashboardGadget === undefined || isEditing) {
    return null;
  }
  return (
    <DashboardGadgetBottomBarContainer dashboardGadget={dashboardGadget} />
  );
};

export default RenderGate;
