import { CUSTOMER_LANE_COMMITMENTS, TARGET_PERFORMANCE_APP } from './constants';

export const isDriverBonusWorkSpace = (
  workSpace: WorkSpace | TargetsApp.App,
): workSpace is DriverBonusWorkSpace =>
  workSpace.campaignType === 'driverBonus';

export const isGeneralWorkSpace = (
  workSpace: WorkSpace | TargetsApp.App,
): workSpace is GeneralWorkSpace => workSpace.campaignType === 'general';

export const isCustomerLaneCommitmentsWorkSpace = (
  workSpace: WorkSpace | TargetsApp.App,
): workSpace is CustomerLaneCommitmentsWorkSpace =>
  workSpace.campaignType === CUSTOMER_LANE_COMMITMENTS;

export const isTargetsApp = (
  app: WorkSpace | TargetsApp.App,
): app is TargetsApp.App => app.campaignType === TARGET_PERFORMANCE_APP;

export const isWorkSpace = (
  workSpace: WorkSpace | TargetsApp.App,
): workSpace is WorkSpace => !isTargetsApp(workSpace);
