import React, { useContext } from 'react';
import Comments from './Comments';
import CustomerLaneSlideOutContext from '../../../../contexts/CustomerLaneSlideOut';
import BoardContext from '../../../../contexts/BoardContext';
import { buildCustomerLaneSlideOutSearch } from '../../../../navigation/appRoutes';
import GoalContext from '../../../../contexts/GoalContext';

const CommentsContainer = () => {
  const { goal } = useContext(GoalContext);
  const { documentId, lane, customer } = useContext(
    CustomerLaneSlideOutContext,
  );
  const { board } = useContext(BoardContext);
  if (!documentId || !lane || !customer || !board) {
    return null;
  }
  const search = buildCustomerLaneSlideOutSearch({
    boardId: board.id,
    lane,
    documentId,
    customer,
    customerType: 'customer',
  });

  if (goal) {
    return null;
  }

  return (
    <Comments
      commitmentId={documentId}
      linkTo={`/boards/${board.id}${search}`}
      mentionedEmailBody={`mentioned you on a Lane - ${lane}`}
      watcherEmailBody={`comments on the Lane - ${lane}`}
    />
  );
};

export default CommentsContainer;
