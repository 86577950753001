import React from 'react';
import MicrosoftImg from './images/microsoft.png';

const MicrosoftIcon = () => (
  <img
    src={MicrosoftImg}
    alt="Microsoft"
    style={{ marginRight: 10, width: 25, height: 25 }}
  />
);

export default MicrosoftIcon;
