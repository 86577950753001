import React, { useContext } from 'react';

import Typography from 'kingpin/atoms/Typography';
import Row from '../../../../components/Common/Row';
import SingleUseMetricPopupContext from '../../../../contexts/SingleUseMetricPopupContext';
import InfoIcon from '../../../../components/InfoIcon';
import Colors2 from '../../../../theme/Colors2';

const Title = ({
  selectedMetric,
  hasUnsavedChanges,
}: {
  selectedMetric: Metrics.NormalMetric | Metrics.CompoundMetric | undefined;
  hasUnsavedChanges: boolean;
}) => {
  const { isSingleUsePopup } = useContext(SingleUseMetricPopupContext);

  return (
    <Row centerAlign>
      <Typography.Header type="H5">
        {isSingleUsePopup
          ? 'Create Single-Use Metric'
          : selectedMetric
            ? 'Edit Metric'
            : 'Create Metric'}
      </Typography.Header>
      {isSingleUsePopup && (
        <div style={{ marginLeft: 4 }}>
          <InfoIcon tooltip="Single-Use Metrics are local to the object they are created on and are not available for re-use through the metric picker" />
        </div>
      )}
      {hasUnsavedChanges && (
        <div style={{ marginLeft: 14 }}>
          <Typography.Body type="Body 12" color={Colors2.Secondary.error}>
            Unsaved Changes
          </Typography.Body>
        </div>
      )}
    </Row>
  );
};

export default Title;
