import React from 'react';
import Cell from './V5Gadget/Matrix/Cell';
import GridCellErrorBoundary from './GridCellErrorBoundary';
import useValueFormatters from '../hooks/useValueFormatters';
import { FleetopsGridCellRendererParams } from '../types/agGrid';

const GeoPointCell = (props: FleetopsGridCellRendererParams) => {
  const { value, colDef } = props;
  const { cellRendererParams } = colDef;
  const { fieldView } = cellRendererParams;
  const { formatField } = useValueFormatters();

  if (!value || value === '-') {
    return (
      <Cell>
        <span>-</span>
      </Cell>
    );
  }

  if (!fieldView) {
    return (
      <Cell>
        <span>{value}</span>
      </Cell>
    );
  }

  const formatted = formatField({
    value,
    dataset: fieldView.dataset,
    field: fieldView.field,
  });

  return (
    <Cell>
      <span>{formatted.toString()}</span>
    </Cell>
  );
};

const Gate = (params: FleetopsGridCellRendererParams) => (
  <GridCellErrorBoundary
    params={params}
    childComponent={<GeoPointCell {...params} />}
  />
);

export default Gate;
