import React, { useState, useMemo, useCallback } from 'react';
import usePopup from 'hooks/usePopup';
import ConfirmationModalComponent from 'components/ConfirmationModal';

const useConfirmation = ({
  title,
  body,
  bodyComponent,
  confirmText,
  noMarginBottom,
}: {
  title: string;
  body?: string;
  bodyComponent?: JSX.Element;
  confirmText: string;
  noMarginBottom?: boolean;
}) => {
  const { isOpen, open, close } = usePopup();
  const [resolve, setResolve] = useState<((value: boolean) => void) | null>(
    null,
  );

  const getConfirmation = useCallback(async (): Promise<boolean> => {
    return new Promise((resolve) => {
      open();
      setResolve(() => (value: boolean) => resolve(value));
    });
  }, [open]);

  const onConfirmed = useCallback(() => {
    if (resolve) {
      resolve(true);
      close();
    }
  }, [close, resolve]);

  const onCancelled = useCallback(() => {
    if (resolve) {
      resolve(false);
      close();
    }
  }, [close, resolve]);

  const ConfirmationModal = useMemo(
    () => (
      <ConfirmationModalComponent
        onConfirmed={onConfirmed}
        confirmText={confirmText}
        isOpen={isOpen}
        close={onCancelled}
        title={title}
        body={body}
        bodyComponent={bodyComponent}
        noMarginBottom={noMarginBottom}
      />
    ),
    [
      body,
      bodyComponent,
      confirmText,
      isOpen,
      noMarginBottom,
      onCancelled,
      onConfirmed,
      title,
    ],
  );

  return { getConfirmation, ConfirmationModal };
};

export default useConfirmation;
