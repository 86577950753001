import React, { ChangeEvent, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Colors from '../theme/colors';
import SearchImage from '../images/search.svg';
import Loading from './Loading/Loading';
import Row from './Common/Row';
import { Z_INDEX } from '../constants';
import Button from '../kingpin/atoms/Button';
import TextInput from '../kingpin/atoms/TextInput';

const SearchBox = styled.div<{ isShort?: boolean; noBorder?: boolean }>`
  height: ${(props) => (props.isShort ? '36px' : '42px')};
  flex-direction: row;
  align-items: center;
  display: flex;
  background-color: ${Colors.WHITE};
  border: ${(props) => (props.noBorder ? 'unset' : '1px solid #dfe1e5')};
  border-radius: 3px;
  cursor: text;
  position: relative;
  font-size: 11px;
`;

const SearchIcon = styled.img`
  width: 12px;
  margin-bottom: 1px;
  margin-right: 6px;
`;

const PlaceHolder = styled.div`
  z-index: 1;
  padding-left: 11px;
  position: absolute;
  opacity: 0.5;
  color: ${Colors.GUN_METAL};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Search = ({
  searchText,
  onSearchTextChanged,
  autoFocus,
  isLoading,
  onSearchFocus,
  onSearchBlur,
  searchPlaceholder,
  isShort,
  noBorder,
  onClearClicked,
  iconRight,
}: {
  searchText: string;
  onSearchTextChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
  isLoading?: boolean;
  onSearchFocus?: () => void;
  onSearchBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  searchPlaceholder?: string;
  isShort?: boolean;
  noBorder?: boolean;
  onClearClicked?: () => void;
  iconRight?: JSX.Element;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.select();
    }
  }, [autoFocus]);

  return (
    <SearchBox isShort={isShort} noBorder={noBorder}>
      {searchText === '' && (
        <PlaceHolder>
          <Row centerAlign>
            <SearchIcon src={SearchImage} />
            <span>{searchPlaceholder ? searchPlaceholder : 'Search'}</span>
          </Row>
        </PlaceHolder>
      )}
      <TextInput
        value={searchText}
        onChange={onSearchTextChanged}
        icon={'search'}
        onFocus={onSearchFocus}
        onBlur={onSearchBlur}
        testId="search"
        forwardRef={inputRef}
      />
      {!isLoading && onClearClicked && searchText !== '' && (
        <div
          style={{
            position: 'absolute',
            right: 8,
            zIndex: Z_INDEX.SEARCH_OVERLAY_BUTTONS,
          }}
        >
          <Button
            size={'Small'}
            type={'Secondary'}
            onClick={onClearClicked}
            icon="cross"
          />
        </div>
      )}
      {!isLoading && iconRight && (
        <div
          style={{
            position: 'absolute',
            right: 8,
            zIndex: Z_INDEX.SEARCH_OVERLAY_BUTTONS,
          }}
        >
          {iconRight}
        </div>
      )}
      <div style={{ position: 'absolute', right: 8 }}>
        {isLoading && <Loading isTiny />}
      </div>
    </SearchBox>
  );
};

export default Search;
