import { useContext } from 'react';
import moment from 'moment';
import ScorecardContext from 'contexts/ScorecardContext';
import formatDateLabel, {
  formatVerboseWeek,
} from 'components/V5Gadget/formatDateLabel';
import isIsoTargets from 'types/scorecardDates/isIsoTargets';

const useTargetBandsLabels = (
  bands: string[],
  targets: Scorecards.WeekTargets | Scorecards.IsoTargets,
  startDate: Scorecards.WeekDate | Scorecards.IsoDate,
) => {
  const { scorecard, toIsoDate } = useContext(ScorecardContext);
  const { cadence } = scorecard;

  const dateLabel = (() => {
    const allTargetDates = (() => {
      if (isIsoTargets(targets)) {
        return Object.keys(targets.targets).sort();
      }

      const dates = [] as string[];
      Object.entries(targets.targets).forEach(([year, weeks]) => {
        if (weeks) {
          Object.entries(weeks).forEach(([week]) => {
            const weekDate = {
              week,
              year,
            };

            dates.push(toIsoDate(weekDate));
          });
        }
      });

      return dates;
    })();
    const currentIndex = allTargetDates.findIndex(
      (x) => x === toIsoDate(startDate),
    );
    const nextTargetDate =
      currentIndex === allTargetDates.length - 1
        ? undefined
        : allTargetDates[currentIndex + 1];
    if (!nextTargetDate) {
      if (cadence === 'week') {
        return `from ${formatVerboseWeek({
          startDate: toIsoDate(startDate),
          isShort: true,
          isYearImportant: true,
        })}`;
      }
      return `from ${formatDateLabel(toIsoDate(startDate), cadence)}`;
    }

    if (
      Math.abs(
        moment(toIsoDate(startDate)).diff(moment(nextTargetDate), cadence),
      ) === 1
    ) {
      if (cadence === 'week') {
        return formatVerboseWeek({
          startDate: toIsoDate(startDate),
          isShort: true,
          isYearImportant: true,
        });
      }
      return `${formatDateLabel(toIsoDate(startDate), cadence)}`;
    }

    if (cadence === 'week') {
      return `${formatVerboseWeek({
        startDate: toIsoDate(startDate),
        isShort: true,
        isYearImportant: true,
      })} - ${formatVerboseWeek({
        startDate: nextTargetDate,
        isShort: true,
        isYearImportant: true,
      })}`;
    }
    return `${formatDateLabel(
      toIsoDate(startDate),
      cadence,
    )} - ${formatDateLabel(nextTargetDate, cadence)}`;
  })();

  return {
    dateLabel,
  };
};

export default useTargetBandsLabels;
