import React, { ErrorInfo, useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import Button from 'kingpin/atoms/Button';
import Typography from 'kingpin/atoms/Typography';

import captureException from '../../services/captureException';
import FleetOpsGrey from '../../images/fleetops-gray.svg';
import reloadWhenSafe from '../../reloadWhenSafe';
import Row from './Row';
import FlexCentered from './FlexCentered';
import AuthenticationContext, {
  AuthenticationContextType,
} from '../../contexts/AuthenticationContext';

interface ErrorBoundaryProps {
  children: JSX.Element | JSX.Element[];
  onErrorCallback?: () => void;
  isRetryable?: boolean;
  isHiddenError?: boolean;
  isReloadable?: boolean;
  shouldWallboardAutoReloadApp?: boolean;
  fallback?: JSX.Element;
}

interface ErrorBoundaryState {
  hasError: boolean;
  isWallboardReloading: boolean;
}

const GreyIcon = () => (
  <ReactSVG
    src={FleetOpsGrey}
    style={{
      marginBottom: 12,
      display: 'flex',
      justifyContent: 'center',
    }}
    beforeInjection={(svg) => {
      svg.setAttribute('style', 'width: 63px');
      svg.setAttribute('style', 'height: 72px');
    }}
  />
);

const WallboardAppReloadCountdown = () => {
  const [countDown, setCountdown] = useState<number>(60);
  useEffect(() => {
    const i = setInterval(() => {
      setCountdown((c) => {
        if (c <= 0) {
          return 0;
        }
        return c - 1;
      });
    }, 1000);

    return () => {
      clearInterval(i);
    };
  }, []);

  useEffect(() => {
    if (countDown <= 0) {
      reloadWhenSafe();
    }
  }, [countDown]);

  return (
    <div style={{ marginBottom: 16 }}>
      <Typography.Body type={'Body 14'}>
        The application will restart in {countDown} seconds
      </Typography.Body>
    </div>
  );
};

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  static contextType = AuthenticationContext;
  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, isWallboardReloading: false };
    this.onRetry = this.onRetry.bind(this);
    this.onReloadApp = this.onReloadApp.bind(this);
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (this.props.onErrorCallback) {
      this.props.onErrorCallback();
    }

    captureException(error);
    const authContext = this.context as AuthenticationContextType;
    if (
      authContext &&
      authContext.isWallboardUser &&
      this.props.shouldWallboardAutoReloadApp
    ) {
      this.setState({
        hasError: true,
        isWallboardReloading: true,
      });
    }
  }

  onRetry() {
    this.setState({ hasError: false, isWallboardReloading: false });
  }

  onReloadApp() {
    reloadWhenSafe();
  }

  render() {
    const { fallback, isHiddenError, isReloadable, isRetryable } = this.props;
    if (this.state.hasError) {
      if (isHiddenError) {
        return null;
      }

      if (fallback) {
        return <>{fallback}</>;
      }

      return (
        <FlexCentered style={{ height: '100%' }}>
          <React.Fragment>
            <GreyIcon />
            <div style={{ marginBottom: 16 }}>
              <Typography.Body type={'Body 14'}>
                Something went wrong
              </Typography.Body>
            </div>
            {!this.state.isWallboardReloading && (
              <>
                {isRetryable && (
                  <Row style={{ justifyContent: 'center' }}>
                    <Button
                      onClick={this.onRetry}
                      label="Retry"
                      type="Tertiary"
                      size="Small"
                    />
                  </Row>
                )}
                {isReloadable && (
                  <Row style={{ justifyContent: 'center' }}>
                    <Button
                      onClick={this.onReloadApp}
                      label="Restart Application"
                      type="Primary"
                      size="Small"
                    />
                  </Row>
                )}
              </>
            )}
            {this.state.isWallboardReloading && <WallboardAppReloadCountdown />}
          </React.Fragment>
        </FlexCentered>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
