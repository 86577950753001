import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

const buildQuery = ({
  fields,
  value,
  dataTypes,
  filters,
  dateScope,
}: {
  fields: string[];
  value: string;
  dataTypes?: string[];
  filters?: FilterInput[];
  dateScope: DateRangeInput;
}) => {
  return {
    query: gql`
      query multiFieldSearch(
        $fields: [String]!
        $value: String!
        $dataType: [String]
        $filters: [FilterInput]
        $dateScope: [DateRangeInput!]
        $limit: Int
      ) {
        multiFieldSearch(
          fields: $fields
          value: $value
          limit: $limit
          dataType: $dataType
          filters: $filters
          dateScope: $dateScope
        )
      }
    `,
    variables: {
      fields,
      value,
      filters,
      dataType: dataTypes,
      dateScope: [dateScope],
      limit: 20,
    },
  };
};

const multiFieldSearch = async ({
  dataTypes,
  fields,
  value,
  filters,
  dateScope,
  client,
}: {
  dataTypes?: string[];
  fields: string[];
  value: string;
  filters?: FilterInput[];
  dateScope: DateRangeInput;
  client: ApolloClient<NormalizedCacheObject>;
}): Promise<MultiFieldSearchResults> => {
  const query = buildQuery({
    fields,
    value,
    dataTypes,
    filters,
    dateScope,
  });
  const response = await client.query(query);
  return response.data.multiFieldSearch;
};

export default multiFieldSearch;
