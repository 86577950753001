import { useEffect, useState } from 'react';
import useGetOriginMetric from './useGetOriginMetric';

// Find the metric (if any) which this metric's field
// is derived from on
const useOriginMetric = (metric: Metrics.NormalMetric) => {
  const getOriginMetric = useGetOriginMetric();
  const [originMetric, setOriginMetric] = useState<Metrics.Metric>();
  useEffect(() => {
    setOriginMetric(getOriginMetric(metric));
  }, [getOriginMetric, metric]);

  return originMetric;
};

export default useOriginMetric;
