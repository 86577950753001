import React from 'react';

interface AnalyticsContextType {
  trackEvent: (
    eventType: Analytics.EventType,
    eventProperties?: { [key: string]: string | undefined },
  ) => void;
  updateVitallyAccountTrait: ({
    traitValue,
    traitName,
  }: {
    traitName: string;
    traitValue: string | boolean;
  }) => void;
}

const AnalyticsContext = React.createContext<AnalyticsContextType>({
  trackEvent: () => {},
  updateVitallyAccountTrait: () => {},
} as AnalyticsContextType);

export default AnalyticsContext;
