import styled from 'styled-components';
import Colors2 from '../../../../theme/Colors2';
import { motion } from 'framer-motion';
import MetricPopupConstants from './constants';
import Row from '../../../../components/Common/Row';
import React, { useEffect, useState } from 'react';
import Typography from 'kingpin/atoms/Typography';
import ChevronSrc from './images/Chevron.svg';
import useWindowSize from '../../../../hooks/useWindowSize';
import MEDIA from '../../../../media';

const DURATION = 300;

const AccordionContent = styled(motion.div)<{ noBorder?: boolean }>`
  border-bottom: ${(props) =>
    props.noBorder ? 'unset' : `1px solid ${Colors2.Grey['8']}`};

  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const AccordionInner = styled.div`
  overflow-y: auto;
  padding: 12px 1px;
`;

const accordionVariants = {
  opened: {
    // Need to inject this to re-use this component
    height: `calc(${MetricPopupConstants.POPUP_HEIGHT} - 530px)`,
  },
  openedBreakpoint: {
    height: `calc(${MetricPopupConstants.POPUP_HEIGHT_SMALL} - 460px)`,
  },
  closing: {
    height: 0,
  },
  closed: {
    height: 0,
  },
};

const AccordionChevron = styled(motion.img)`
  width: 8px;
  height: 12px;
`;

const Icon = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 0.5em;
`;

const chevronVariants = {
  down: {
    rotate: '90deg',
  },
  right: {
    rotate: '0deg',
  },
};

export const AnimatedAccordionChevron = ({ isOpen }: { isOpen: boolean }) => (
  <AccordionChevron
    variants={chevronVariants}
    animate={isOpen ? 'down' : 'right'}
    src={ChevronSrc}
  />
);

const AccordionButton = styled(Row)<{ isDisabled?: boolean }>`
  height: 56px;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};

  &:hover {
    opacity: 0.7;
  }
`;

const Accordion = ({
  isOpen,
  onToggled,
  children,
  iconSrc,
  text,
  isDisabled,
  noBorder,
}: {
  isOpen: boolean;
  onToggled: () => void;
  children: JSX.Element | JSX.Element[];
  iconSrc: string;
  text: string;
  isDisabled?: boolean;
  noBorder?: boolean;
}) => {
  const [isCloseComplete, setIsCloseComplete] = useState<boolean>(true);
  const { windowHeight } = useWindowSize();

  useEffect(() => {
    if (isOpen) {
      setIsCloseComplete(false);
      return;
    }
    const t = setTimeout(() => {
      setIsCloseComplete(true);
    }, DURATION);

    return () => {
      clearTimeout(t);
    };
  }, [isOpen]);

  const isBreakpoint = windowHeight <= MEDIA.MEASUREMENTS.HEIGHT_BREAKPOINT;

  return (
    <>
      <AccordionButton
        centerAlign
        spaceBetween
        onClick={isDisabled ? undefined : onToggled}
        isDisabled={isDisabled}
      >
        <Row centerAlign>
          <Icon src={iconSrc} />
          <Typography.Body type="Body 12">{text}</Typography.Body>
        </Row>
        <AnimatedAccordionChevron isOpen={isOpen} />
      </AccordionButton>
      <AccordionContent
        variants={accordionVariants}
        animate={
          isOpen
            ? isBreakpoint
              ? 'openedBreakpoint'
              : 'opened'
            : isCloseComplete
              ? 'closed'
              : 'closing'
        }
        noBorder={noBorder}
      >
        {isOpen && <AccordionInner>{children}</AccordionInner>}
      </AccordionContent>
    </>
  );
};

export default Accordion;
