import React, { ChangeEvent, useContext } from 'react';

import Inputs from 'components/Inputs';
import Row from 'components/Common/Row';
import Button from 'kingpin/atoms/Button';
import Typography from 'kingpin/atoms/Typography';
import Dropdown from 'components/Inputs/Dropdown';
import ReportDrillDown from 'components/ReportDrillDown';
import { LabelDiv } from 'screens/Boards/PerformanceBoardForm';
import BoardSettingsHeader from '../BoardSettingsHeader/BoardSettingsHeader';
import AddFilterButton from '../AddBoardFilterButton';
import ReportDrillDownsProvider from 'contextProviders/ReportDrillDownsProvider';
import EntityDetailsContext from '../../../../../screens/EntityDetailsShow/EntityDetailsContext';
import EntityFilterToggle from '../../../../ConfigureDashboardGadget/EntityFilterToggle';

const BoardGeneralSettings = ({
  board,
  baseView,
  title,
  onTitleChanged,
  fieldOptions,
  sortOptions,
  plates,
  setPlates,
  isEntityFilterEnabled,
  setIsEntityFilterEnabled,
  hasUnsavedChanges,
  handleSave,
}: {
  board: PerformanceBoardTypes.Board;
  baseView: FleetOps.BaseView | undefined;
  title: string;
  onTitleChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  fieldOptions:
    | {
        label: string;
        value: string;
        isSelected: boolean;
        onSelected: () => void;
      }[]
    | undefined;
  sortOptions: {
    label: string;
    value: string;
    isSelected: boolean;
    onSelected: () => void;
  }[];
  plates: FilterPlate[];
  setPlates: React.Dispatch<React.SetStateAction<FilterPlate[]>>;
  isEntityFilterEnabled: boolean;
  setIsEntityFilterEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  hasUnsavedChanges: boolean;
  handleSave: () => void;
}) => {
  const entityDetailsContext = useContext(EntityDetailsContext);
  const isEntityDetailsApp = !!entityDetailsContext;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <div>
        <BoardSettingsHeader
          title="General"
          description={`Dataset: ${
            baseView && baseView.nameAlias ? baseView.nameAlias : board.dataType
          }`}
        />
        <div style={{ marginBottom: '16px' }}>
          <LabelDiv>
            <Typography.Body type="Label">Board Name</Typography.Body>
          </LabelDiv>
          <Inputs.TextInput
            value={title}
            onChange={onTitleChanged}
            maxLength={40}
            data-testid={'title'}
            inputSize="Small"
          />
        </div>
        <div style={{ marginBottom: '16px' }}>
          <LabelDiv>
            <Typography.Body type="Label">Order Table By</Typography.Body>
          </LabelDiv>
          {fieldOptions && (
            <Row>
              <div style={{ marginRight: '8px', flex: '1' }}>
                <Dropdown options={fieldOptions} />
              </div>
              <div style={{ flex: '1' }}>
                <Dropdown options={sortOptions} />
              </div>
            </Row>
          )}
        </div>
        <LabelDiv>
          <Typography.Body type="Label">Define Scope</Typography.Body>
        </LabelDiv>

        <ReportDrillDownsProvider
          drillDowns={plates}
          setDrillDowns={setPlates}
          dataTypes={board ? [board.dataType] : window.emptyArray}
        >
          <Row style={{ marginBottom: '16px' }}>
            <AddFilterButton text="Add Filter" />
          </Row>
        </ReportDrillDownsProvider>
        {isEntityDetailsApp && (
          <div style={{ marginTop: 16 }}>
            <EntityFilterToggle
              isEntityFilterEnabled={isEntityFilterEnabled}
              setIsEntityFilterEnabled={setIsEntityFilterEnabled}
            />
          </div>
        )}
      </div>

      <div style={{ flex: 1, overflowY: 'scroll' }}>
        {plates.length !== 0 && (
          <ReportDrillDownsProvider
            drillDowns={plates}
            setDrillDowns={setPlates}
            dataTypes={board ? [board.dataType] : window.emptyArray}
          >
            <div
              style={{
                marginBottom: '32px',
                paddingTop: '1px',
                paddingLeft: '1px',
              }}
            >
              {plates.map((d) => (
                <div key={d.id} style={{ marginBottom: '8px' }}>
                  <ReportDrillDown drillDown={d} isScope={false} />
                </div>
              ))}
            </div>
          </ReportDrillDownsProvider>
        )}
      </div>

      <Row
        style={{ padding: '8px 0', height: '46px', justifyContent: 'flex-end' }}
      >
        <Button
          type="Primary"
          size="Small"
          isDisabled={title.trim() === '' || !hasUnsavedChanges}
          onClick={handleSave}
          label="Save"
        />
      </Row>
    </div>
  );
};

export default BoardGeneralSettings;
