import React from 'react';

interface ActivityGridContextType {
  costScrollerRef: React.MutableRefObject<HTMLDivElement | null>;
  costListDivRef: React.MutableRefObject<HTMLDivElement | null>;
  costListDivParentScrollerRef: React.MutableRefObject<HTMLDivElement | null>;
  intervals: Period[];
  getCostValue: (args: {
    startDate: string;
    groupDefinition: Costs.Group;
    costFieldName: string;
  }) => number | undefined;
  getCpmValue: (args: {
    startDate: string;
    groupDefinition: Costs.Group;
    costFieldName: string;
  }) => Promise<number | undefined>;
  updateCostValue: (args: {
    startDate: string;
    groupDefinitionName: string;
    costFieldName: string;
    newValue: number | undefined;
  }) => void;
  getPersistableCosts: () => Costs.IntervalCostsInput[];
  discardChanges: () => void;
  saveChanges: () => void;
  isDiscardedChanges: boolean;
  setIsDiscardedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  checkIsIntervalEstimated: (startDate: string) => boolean;
  getMutatedCosts: () => Costs.IntervalCostsInput[];
  checkIsCpmEstimated: (startDate: string) => boolean;
  setIsPendingUnfocus: React.Dispatch<React.SetStateAction<boolean>>;
  focusedInterval: Period | undefined;
  setFocusedInterval: React.Dispatch<React.SetStateAction<Period | undefined>>;
  hasUnsavedChanges: boolean;
  hoveredCategory: string | undefined;
  setHoveredCategory: React.Dispatch<React.SetStateAction<string | undefined>>;
  invalidIntervals: Costs.IntervalCostsInput[];
  isSavingError: boolean;
  setIsSavingError: React.Dispatch<React.SetStateAction<boolean>>;
  toName: ({ fieldName }: { fieldName: string }) => string;
  isValid: boolean;
}

const ActivityGridContext = React.createContext<ActivityGridContextType>(
  {} as ActivityGridContextType,
);

export default ActivityGridContext;
