import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import GlowingDivContext from './contexts/GlowingDivContext';
import Colors2 from '../../theme/Colors2';

const Div = styled.div<{ isGlowing: boolean }>`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  outline-width: 2px;
  outline-style: solid;
  border-radius: 6px;
  transition: outline-color 1s ease-out;

  ${(props) =>
    props.isGlowing
      ? `outline-color: ${Colors2.Secondary.info}AA;`
      : 'outline-color: transparent'}
`;

const GlowingDiv = ({
  children,
  glowOnMount,
}: {
  children: JSX.Element | JSX.Element[];
  glowOnMount?: boolean;
}) => {
  const [isGlowing, setIsGlowing] = useState<boolean>(false);
  const startGlow = useCallback(() => {
    setIsGlowing(true);
    setTimeout(() => {
      setIsGlowing(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (glowOnMount) {
      startGlow();
    }
  }, [glowOnMount, startGlow]);

  return (
    <GlowingDivContext.Provider
      value={{
        startGlow,
      }}
    >
      <Div isGlowing={isGlowing}>{children}</Div>
    </GlowingDivContext.Provider>
  );
};

export default GlowingDiv;
