import React, { useContext } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import GridCellErrorBoundary from '../../../GridCellErrorBoundary';
import Cell from '../../../V5Gadget/Matrix/Cell';
import Icon from '../../../../kingpin/atoms/Icon';
import colors from '../../../../theme/colors';
import Tooltip from '../../../Tooltip';
import Row from '../../../Common/Row';
import Typography from '../../../../kingpin/atoms/Typography';
import useValueFormatters from '../../../../hooks/useValueFormatters';
import useGetFieldLabel from '../../../../hooks/useGetFieldLabel';
import AccountContext from '../../../../contexts/AccountContext';
import SlideOutContext from '../../contexts/SlideOutContext';

export interface StatusCellProps {
  totalField: string;
  targetField: string;
  differenceField: string;
  dataset: string;
}

export interface StatusCellRendererParams extends ICellRendererParams {
  cell: StatusCellProps;
}

export const PerformanceStatusProgress = ({
  StatusIcon,
  targetName,
  dataset,
  field,
  actual,
  target,
  isCell,
}: {
  StatusIcon: JSX.Element;
  targetName: string;
  dataset: string;
  field: string;
  actual: number;
  target: number;
  isCell?: boolean;
}) => {
  const { formatField } = useValueFormatters();
  const formattedActual = formatField({ field, dataset, value: actual });
  const formattedTarget = formatField({ field, dataset, value: target });
  const performanceText = `${formattedActual} / ${formattedTarget}`;

  return (
    <Row style={{ width: isCell ? '225px' : '100%' }} spaceBetween centerAlign>
      <Row centerAlign>
        <div style={{ marginRight: 8, display: 'flex' }}>{StatusIcon}</div>
        <div>
          <Typography.Body type={'Button Text'}>{targetName}</Typography.Body>
        </div>
      </Row>

      <Typography.Body type={'Button Text'}>{performanceText}</Typography.Body>
    </Row>
  );
};

const PerformanceStatusCell = (params: StatusCellRendererParams) => {
  const { isDemo6 } = useContext(AccountContext);
  const { cell, data } = params;
  const field = cell.totalField;
  const { performanceDataset } = useContext(SlideOutContext);
  const { getFieldLabel } = useGetFieldLabel();
  const trimbleActualField =
    field === 'casualMiles' && isDemo6 ? 'CasualMiles' : field;
  const trimbleField =
    field === 'casualMiles' && isDemo6 ? 'Casual Miles' : field;

  const actual = data[trimbleActualField];
  const target = data[`${trimbleField}Target`];
  const diff = (() => {
    const dataDiff = data[`${trimbleField}Difference`];
    if (dataDiff !== undefined) {
      return dataDiff;
    }

    return target - actual;
  })();

  const isGood = (() => {
    if (field === 'casualMiles') {
      return !(diff >= 0);
    }

    return diff >= 0;
  })();
  const color = isGood ? colors.MEDIUM_SEA_GREEN : colors.RADICAL_RED;
  const targetName = getFieldLabel({
    field: field === 'casualMiles' ? trimbleActualField : field,
    dataType: performanceDataset,
  });

  const StatusIcon = (
    <Icon icon={isGood ? 'checkmark' : 'cross'} color={color} />
  );

  return (
    <Cell rightAlign>
      <Tooltip
        isAltTooltip
        content={
          <PerformanceStatusProgress
            field={cell.totalField}
            dataset={cell.dataset}
            target={target}
            actual={actual}
            StatusIcon={StatusIcon}
            targetName={targetName}
            isCell
          />
        }
      >
        {StatusIcon}
      </Tooltip>
    </Cell>
  );
};

const Gate = (params: StatusCellRendererParams) => (
  <GridCellErrorBoundary
    params={params}
    childComponent={<PerformanceStatusCell {...params} />}
  />
);

export default Gate;
