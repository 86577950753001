import React, { useContext, useEffect, useState } from 'react';
import aguid from 'aguid';
import { DateTime } from 'luxon';

import NewCommitmentSlideOut from './NewCommitmentSlideOut';
import NewCommitmentSlideOutConstants from './constants';
import CommitmentQueryContext from '../../../../contexts/CommitmentQueryContext';
import NewCommitSlideOutContext from '../../../../contexts/NewCommitSlideOutContext';
import setCommitment from '../../../../api/commitments/setCommitment';
import withoutNulls from '../../../../api/search/withoutNulls';
import deleteCommitment from '../../../../api/commitments/deleteCommitment';
import {
  DESTINATION_FIELDS,
  DESTINATION_ZIP_CODE,
  ORIGIN_FIELDS,
  ORIGIN_ZIP_CODE,
} from '../../../OmniSearch/constants';
import AccountPickerContext from '../../../../contexts/AccountPickerContext';
import { CUSTOMER_LANE_COMMITMENTS } from '../../../../constants';

const buildLane = ({
  originField,
  originValue,
  destinationField,
  destinationValue,
}: {
  originField?: string;
  originValue?: string;
  destinationField?: string;
  destinationValue?: string;
}): Lane => {
  const lane = {} as { [key: string]: string };
  if (originField && originValue) {
    if (
      originField === ORIGIN_ZIP_CODE &&
      originValue[originValue.length - 1] === '*'
    ) {
      lane[originField] = originValue.substr(0, originValue.length - 1);
    } else {
      lane[originField] = originValue;
    }
  }
  if (destinationField && destinationValue) {
    if (
      destinationField === DESTINATION_ZIP_CODE &&
      destinationValue[destinationValue.length - 1] === '*'
    ) {
      lane[destinationField] = destinationValue.substr(
        0,
        destinationValue.length - 1,
      );
    } else {
      lane[destinationField] = destinationValue;
    }
  }
  return lane;
};

const buildInitialCommit = ({
  customerValue,
  customerField,
  originField,
  originValue,
  destinationField,
  destinationValue,
  commodity,
  selectedRateDoc,
  selectedCommitmentDoc,
  mode,
}: {
  customerValue?: string;
  customerField?: 'customer' | 'billTo';
  originField?: string;
  originValue?: string;
  destinationField?: string;
  destinationValue?: string;
  commodity?: string;
  selectedRateDoc?: RateDoc;
  selectedCommitmentDoc?: Commitment;
  mode: CommitmentFormPopupMode;
}): Commitment => {
  if (selectedCommitmentDoc) {
    const wip = { ...selectedCommitmentDoc };
    if (mode === 'renew') {
      delete wip.startDate;
      delete wip.endDate;
    }
    return wip;
  } else {
    return {
      id: aguid(),
      type: CUSTOMER_LANE_COMMITMENTS,
      customer:
        customerField && customerField === 'customer'
          ? customerValue
          : undefined,
      billTo:
        customerField && customerField === 'billTo' ? customerValue : undefined,
      lane: buildLane({
        originField,
        originValue,
        destinationField,
        destinationValue,
      }),
      loads: undefined,
      rate:
        selectedRateDoc && selectedRateDoc.rate
          ? typeof selectedRateDoc.rate === 'string'
            ? Number.parseFloat(selectedRateDoc.rate)
            : selectedRateDoc.rate
          : undefined,
      mileage:
        selectedRateDoc && selectedRateDoc.mileage
          ? selectedRateDoc.mileage
          : undefined,
      lineHaulCharge:
        selectedRateDoc && selectedRateDoc.lineHaulCharge
          ? selectedRateDoc.lineHaulCharge
          : undefined,
      cadence: 'yearly',
      commodity,
      startDate:
        selectedRateDoc && selectedRateDoc.effectiveStartDate
          ? selectedRateDoc.effectiveStartDate
          : DateTime.local().startOf('day').toISODate(),
      endDate:
        selectedRateDoc && selectedRateDoc.effectiveStartDate
          ? DateTime.fromISO(selectedRateDoc.effectiveStartDate)
              .plus({ year: 1 })
              .toISODate()
          : DateTime.local().startOf('day').plus({ year: 1 }).toISODate(),
    };
  }
};

const isCommitValid = (draftCommit: Commitment): boolean => {
  const {
    customer,
    lane,
    loads,
    cadence,
    startDate,
    endDate,
    lineHaulCharge,
    billTo,
  } = draftCommit;

  const hasOrigin = !!Object.keys(lane).find((field) =>
    ORIGIN_FIELDS.includes(field),
  );
  const hasDestination = !!Object.keys(lane).find((field) =>
    DESTINATION_FIELDS.includes(field),
  );
  const hasCustomer =
    (!!customer && customer !== '') || (!!billTo && billTo !== '');
  const hasStartDate = !!startDate && startDate !== '';
  const hasEndDate = !!endDate && endDate !== '';

  const isEndDateInPast =
    !!endDate &&
    DateTime.fromISO(endDate).toSeconds() < DateTime.local().toSeconds();

  const rateIsNaN = draftCommit.rate ? isNaN(draftCommit.rate) : false;

  return (
    hasOrigin &&
    hasDestination &&
    hasCustomer &&
    hasStartDate &&
    hasEndDate &&
    !!loads &&
    !!cadence &&
    !!lineHaulCharge &&
    !isEndDateInPast &&
    !rateIsNaN
  );
};

const getActionButtonText = (mode: CommitmentFormPopupMode): string => {
  switch (mode) {
    case 'end':
      return 'End Award';
    case 'modify':
      return 'Modify Award';
    case 'renew':
      return 'Renew Award';
    case 'create':
    default:
      return 'Create Award';
  }
};

const getWarningText = (
  mode: CommitmentFormPopupMode,
  isHarshCaseModify: boolean,
): string | undefined => {
  switch (mode) {
    case 'modify':
      if (isHarshCaseModify) {
        return (
          'All history associated with this commitment will be' +
          ' deleted and recreated using new parameters. Any reason codes collected to date for this lane will be deleted.'
        );
      } else {
        return undefined;
      }
    default:
      return undefined;
  }
};

const NewCommitmentSlideOutContainer = () => {
  const { accountRef } = useContext(AccountPickerContext);

  // State
  const [activeNavElement, setActiveNavElement] =
    useState<NewCommitmentSlideOutNavElement>('summary');
  const {
    customerField,
    customerValue,
    originField,
    originValue,
    destinationField,
    destinationValue,
    commodity,
    closeSlideOut,
    closePopup,
    selectedRateDoc,
    selectedCommitmentDoc,
    mode,
    clearState,
    setCustomerField,
    setCustomerValue,
    isFlashVisible,
    setIsFlashVisible,
  } = useContext(CommitmentQueryContext);

  const [draftCommit, setDraftCommit] = useState<Commitment>(
    buildInitialCommit({
      customerValue,
      customerField,
      originValue,
      originField,
      destinationValue,
      destinationField,
      commodity,
      selectedRateDoc,
      selectedCommitmentDoc,
      mode,
    }),
  );
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isHarshCaseModify, setIsHarshCaseModify] = useState<boolean>(false);
  const [hasManualLineHaulCharge, setHasManualLineHaulCharge] =
    useState<boolean>(false);
  const [showWarnings, setShowWarnings] = useState<boolean>(false);
  const isValid = isCommitValid(draftCommit);
  const actionButtonText = getActionButtonText(mode);
  const warningText = getWarningText(mode, isHarshCaseModify);

  // Effects
  useEffect(() => {
    // Update draft if query fields change
    setDraftCommit((currentDraft) => ({
      ...currentDraft,
      lane: buildLane({
        originValue,
        originField,
        destinationValue,
        destinationField,
      }),
      commodity,
      customer: customerField === 'customer' ? customerValue : undefined,
      billTo: customerField === 'billTo' ? customerValue : undefined,
    }));
  }, [
    commodity,
    customerField,
    customerValue,
    destinationField,
    destinationValue,
    originField,
    originValue,
  ]);

  useEffect(() => {
    if (!selectedCommitmentDoc || mode !== 'modify') {
      return;
    }

    const newIsHarshCase = (() => {
      const startDateChanged =
        selectedCommitmentDoc.startDate !== draftCommit.startDate;
      const endDateChanged =
        selectedCommitmentDoc.endDate !== draftCommit.endDate;
      const loadsChanged = selectedCommitmentDoc.loads !== draftCommit.loads;
      const cadenceChanged =
        selectedCommitmentDoc.cadence !== draftCommit.cadence;

      return (
        startDateChanged || endDateChanged || loadsChanged || cadenceChanged
      );
    })();

    setIsHarshCaseModify(newIsHarshCase);
  }, [
    draftCommit.cadence,
    draftCommit.endDate,
    draftCommit.loads,
    draftCommit.startDate,
    mode,
    selectedCommitmentDoc,
  ]);

  // Interactions
  const onActionButtonClicked = () => {
    if (!isValid) {
      setShowWarnings(true);
    } else {
      if (mode === 'create') {
        onCreate();
      } else if (mode === 'renew') {
        onRenew();
      } else if (mode === 'modify') {
        onModify();
      }
    }
  };

  const onCreate = () => {
    if (!isValid) {
      alert('Invalid commit');
      return;
    }
    setIsSaving(true);
    setCommitment(withoutNulls(draftCommit), accountRef).then(() => {
      setIsSaving(false);
      // setDraft must come before clearState
      setDraftCommit({
        ...buildInitialCommit({
          customerField: draftCommit.billTo ? 'billTo' : 'customer',
          customerValue: draftCommit.billTo
            ? draftCommit.billTo
            : draftCommit.customer,
          mode: 'create',
        }),
        startDate: draftCommit.startDate,
        endDate: draftCommit.endDate,
      });
      clearState();
      setCustomerField(draftCommit.customer ? 'customer' : 'billTo');
      setCustomerValue(
        draftCommit.customer ? draftCommit.customer : draftCommit.billTo,
      );
      setIsFlashVisible(true);
      setShowWarnings(false);
      const scroller = document.getElementById(
        NewCommitmentSlideOutConstants.NAV_SCROLLER_ID,
      );
      if (scroller) {
        scroller.scrollTop = 0;
      }
    });
  };

  const onRenew = () => {
    if (!selectedCommitmentDoc || !draftCommit.startDate) {
      return;
    }

    setIsSaving(true);
    const newEndDate = DateTime.fromISO(draftCommit.startDate)
      .minus({ day: 1 })
      .toISODate();
    const updatedCommit = {
      ...selectedCommitmentDoc,
      endDate: newEndDate,
    };
    setCommitment(withoutNulls(updatedCommit), accountRef).then(() => {
      setCommitment(
        withoutNulls({ ...draftCommit, id: aguid() }),
        accountRef,
      ).then(() => {
        setIsSaving(false);
        closeSlideOut();
        closePopup();
      });
    });
  };

  const onModify = () => {
    if (!selectedCommitmentDoc) {
      return;
    }

    setIsSaving(true);
    if (isHarshCaseModify) {
      deleteCommitment(selectedCommitmentDoc, accountRef).then(() => {
        setCommitment(
          withoutNulls({ ...draftCommit, id: aguid() }),
          accountRef,
        ).then(() => {
          setIsSaving(false);
          closeSlideOut();
          closePopup();
        });
      });
    } else {
      setCommitment(
        withoutNulls({ ...draftCommit, id: selectedCommitmentDoc.id }),
        accountRef,
      ).then(() => {
        setIsSaving(false);
        closeSlideOut();
        closePopup();
      });
    }
  };

  const onNavScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const elem = event.currentTarget;
    const summaryElem = document.getElementById(
      NewCommitmentSlideOutConstants.SUMMARY_ID,
    );
    const awardedLoadsElem = document.getElementById(
      NewCommitmentSlideOutConstants.AWARDED_LOADS_ID,
    );
    const revenueElem = document.getElementById(
      NewCommitmentSlideOutConstants.REVENUE_ID,
    );

    if (!summaryElem || !awardedLoadsElem || !revenueElem) {
      return;
    }

    if (
      elem.scrollTop >=
      summaryElem.clientHeight + awardedLoadsElem.clientHeight
    ) {
      setActiveNavElement('revenue');
    } else if (elem.scrollTop >= summaryElem.clientHeight) {
      setActiveNavElement('awarded-loads');
    } else {
      setActiveNavElement('summary');
    }
  };

  return (
    <NewCommitSlideOutContext.Provider
      value={{
        draftCommit,
        setDraftCommit,
        hasManualLineHaulCharge,
        setHasManualLineHaulCharge,
        showWarnings,
      }}
    >
      <NewCommitmentSlideOut
        activeNavElement={activeNavElement}
        onNavScroll={onNavScroll}
        onActionButtonClicked={onActionButtonClicked}
        isSaving={isSaving}
        actionButtonText={actionButtonText}
        warningText={warningText}
        showWarnings={showWarnings}
        isFlashVisible={isFlashVisible}
        setIsFlashVisible={setIsFlashVisible}
      />
    </NewCommitSlideOutContext.Provider>
  );
};

const Gate = () => {
  const { selectedRateDoc } = useContext(CommitmentQueryContext);
  const [key, setKey] = useState(
    selectedRateDoc ? selectedRateDoc.id : 'manual',
  );

  // Fix/Dates not being preserved after completing a form
  useEffect(() => {
    if (selectedRateDoc && key !== selectedRateDoc.id) {
      setKey(selectedRateDoc.id);
    }
  }, [key, selectedRateDoc]);

  return <NewCommitmentSlideOutContainer key={key} />;
};

export default Gate;
