import React from 'react';

interface CostsShowContextType {
  costModel: Costs.PersistedCostModel | undefined;
  refreshModel: () => Promise<void>;
  intervalCosts: Costs.IntervalCosts[] | undefined;
  refreshCosts: () => Promise<void>;
  isLoadingCosts: boolean;
  isLoadingModel: boolean;
}

const CostsShowContext = React.createContext<CostsShowContextType>(
  {} as CostsShowContextType,
);

export default CostsShowContext;
