import moment from 'moment';

const formatFullDate = (date: string) => moment.utc(date).format('Do MMM YYYY');

const getTimeTooltip = (goal: GeneralGoal, dateRange: DateRangeInput) => {
  const startD = dateRange.startDate
    ? dateRange.startDate
    : new Date().toISOString();
  const endD = dateRange.endDate ? dateRange.endDate : new Date().toISOString();

  return `${formatFullDate(startD)} - ${formatFullDate(endD)}`;
};
export default getTimeTooltip;
