import { GridOptions } from 'ag-grid-community';
import { useCallback, useContext, useState } from 'react';
import { RowHeightContext } from 'contextProviders/RowHeightProvider';
import { BASE_GRID_OPTIONS } from 'components/Grid/buildGridOptions';

import { IPaceRow } from '../types';
import useColDefs from './useColDefs';

const useGridOptions = ({
  paceMatrix,
  getActualDateRange,
  getAverageDateRange,
  getExcludedDateRangeLabels,
}: {
  paceMatrix: VisualisationDefinitions.PaceMatrix;
  getActualDateRange: () => string;
  getAverageDateRange: () => string;
  getExcludedDateRangeLabels: () => string[];
}) => {
  const { rowHeight } = useContext(RowHeightContext);
  const colDefs = useColDefs({
    paceMatrix,
    getAverageDateRange,
    getActualDateRange,
    getExcludedDateRangeLabels,
  });
  const buildOptions = useCallback((): GridOptions<IPaceRow> => {
    return {
      ...BASE_GRID_OPTIONS,
      columnDefs: colDefs,
      rowModelType: 'clientSide',
      rowHeight,
      autoSizeStrategy: {
        type: 'fitCellContents',
      },
      suppressColumnVirtualisation: true,
    };
  }, [colDefs, rowHeight]);
  const [gridOptions] = useState<GridOptions<IPaceRow>>(() => buildOptions());

  return { gridOptions, colDefs };
};

export default useGridOptions;
