import firebase from 'firebase/compat/app';
import withoutNulls from '../search/withoutNulls';
import { BOARDS_COLLECTION } from '../../constants';

const updateBoard = async (
  board: BaseBoard,
  accountRef: firebase.firestore.DocumentReference,
) => {
  return accountRef
    .collection(BOARDS_COLLECTION)
    .doc(board.id)
    .set(withoutNulls(board));
};

export default updateBoard;
