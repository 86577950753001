import { useContext, useEffect, useState } from 'react';
import TargetListContext from '../contexts/TargetListContext';
import moment from 'moment';

const useTargetBuckets = () => {
  const { targetsForField } = useContext(TargetListContext);

  const [futureTargets, setFutureTargets] = useState<
    Targets.Wizard.DataTypeTarget[]
  >([]);
  const [currentTarget, setCurrentTarget] = useState<
    Targets.Wizard.DataTypeTarget | undefined
  >();
  const [pastTargets, setPartTargets] = useState<
    Targets.Wizard.DataTypeTarget[]
  >([]);

  useEffect(() => {
    const today = moment().format('YYYY-MM-DD');

    const newFutureTargets = targetsForField.filter(
      (t) => t.effectiveDate > today,
    );
    const basePastTargets = targetsForField.filter(
      (t) => t.effectiveDate <= today,
    );
    const currentTarget =
      basePastTargets.length > 0 ? basePastTargets[0] : undefined;
    const pastTargets = basePastTargets.filter((t) => {
      if (currentTarget) {
        return t.id !== currentTarget.id;
      }

      return true;
    });

    setFutureTargets(newFutureTargets);
    setCurrentTarget(currentTarget);
    setPartTargets(pastTargets);
  }, [targetsForField]);

  return {
    futureTargets,
    currentTarget,
    pastTargets,
  };
};

export default useTargetBuckets;
