import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FeatureGatesContext from '../../contexts/FeatureGatesContext';
import STORE from '../../store';
import AccountPickerContext from '../../contexts/AccountPickerContext';
import appRoutes from '../../navigation/appRoutes';
import CurrentUserContext from '../../contexts/CurrentUserContext';
import ImpersonatorContext from '../../contexts/ImpersonatorContext';

const useIsPortalUIToggleEnabled = (
  isPortalsEnabledAtAccountLevel: boolean | undefined,
) => {
  const currentUser = useContext(CurrentUserContext);
  const { viewingAppAs } = useContext(ImpersonatorContext);
  const { isPortalsUIPerUserToggleEnabled, isPortalsSEPreviewEnabled } =
    useContext(FeatureGatesContext);

  const getIsPortalUIToggleEnabled = useCallback(() => {
    if (isPortalsEnabledAtAccountLevel) {
      return false;
    }

    if (viewingAppAs) {
      return isPortalsUIPerUserToggleEnabled && viewingAppAs.ui === 'portals';
    }

    return (
      (isPortalsUIPerUserToggleEnabled && currentUser.ui === 'portals') ||
      (isPortalsSEPreviewEnabled && !!currentUser.isFleetOpsStaff)
    );
  }, [
    currentUser.isFleetOpsStaff,
    currentUser.ui,
    isPortalsEnabledAtAccountLevel,
    isPortalsSEPreviewEnabled,
    isPortalsUIPerUserToggleEnabled,
    viewingAppAs,
  ]);
  const [isPortalUIToggleEnabled, setIsPortalUIToggleEnabled] =
    useState<boolean>(() => getIsPortalUIToggleEnabled());

  useEffect(() => {
    setIsPortalUIToggleEnabled(getIsPortalUIToggleEnabled());
  }, [getIsPortalUIToggleEnabled]);

  return isPortalUIToggleEnabled;
};

const useIsPortalsEnabled = () => {
  const navigate = useNavigate();
  const currentUser = useContext(CurrentUserContext);
  const { viewingAppAs } = useContext(ImpersonatorContext);
  const [isLoading, setIsLoading] = useState(true);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const [isPortalsEnabledAtAccountLevel, setIsPortalsEnabledAtAccountLevel] =
    useState<boolean | undefined>(undefined);
  const isPortalUIToggleEnabled = useIsPortalUIToggleEnabled(
    isPortalsEnabledAtAccountLevel,
  );

  const getIsPortalsEnabled = useCallback(() => {
    if (isPortalsEnabledAtAccountLevel) {
      return true;
    }

    if (viewingAppAs && viewingAppAs.ui === 'portals') {
      return true;
    }

    if (currentUser && currentUser.ui === 'portals') {
      return true;
    }

    return false;
  }, [currentUser, isPortalsEnabledAtAccountLevel, viewingAppAs]);
  const [isPortalsEnabled, setIsPortalsEnabled] = useState<boolean>(false);
  useEffect(() => {
    setIsPortalsEnabled(getIsPortalsEnabled());
  }, [getIsPortalsEnabled]);

  const onPreviewNewUiClicked = useCallback(() => {
    setIsPortalsEnabled(true);
    navigate(appRoutes.home);
  }, [navigate]);

  const onRevertToOldUiClicked = useCallback(() => {
    setIsPortalsEnabled(false);
    navigate(appRoutes.home);
  }, [navigate]);

  useEffect(() => {
    setIsLoading(true);
    STORE.getAccountRef({ accountId: selectedAccountId })
      .get()
      .then((doc) => {
        const account = doc.data();
        if (account) {
          setIsPortalsEnabledAtAccountLevel(account.isPortalsEnabled);
          setIsLoading(false);
        }
      });
  }, [selectedAccountId]);

  return {
    isPortalsEnabledAtAccountLevel,
    isPortalsEnabled,
    isPortalUIToggleEnabled,
    onPreviewNewUiClicked,
    onRevertToOldUiClicked,
    isLoading,
  };
};

export default useIsPortalsEnabled;
