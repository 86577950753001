import React from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import { Option } from 'kingpin/atoms/ContextMenu';
import GridProvider from '../../../contextProviders/GridProvider';
import PopupGrid from '../../PopupGrid';

const PopupGridMetricPickerButton = ({
  options,
  closeGrid,
  isGridOpen,
  selectMetric,
  dateScopeOverride,
  metric,
}: {
  options: { label: string; metric: Metrics.NormalMetric }[];
  closeGrid: () => void;
  isGridOpen: boolean;
  selectMetric: (m: Metrics.NormalMetric) => void;
  dateScopeOverride?: DateRangeInput;
  metric?: Metrics.NormalMetric;
}) => (
  <React.Fragment>
    {options.length > 1 && (
      <Option
        label={'View Raw Data'}
        subMenuPlacement="left"
        subMenu={
          <>
            {options.map((option) => (
              <Option
                key={option.metric.id}
                label={option.label}
                shouldHideInsteadOfCloseMenu
                onClick={() => {
                  selectMetric(option.metric);
                }}
              />
            ))}
          </>
        }
      />
    )}
    {options.length === 1 && (
      <Option
        label={'View Raw Data'}
        icon={'table'}
        shouldHideInsteadOfCloseMenu
        onClick={() => {
          selectMetric(options[0].metric);
        }}
      />
    )}

    <ModalTransition>
      {metric && isGridOpen && (
        <Modal
          width={'80vw'}
          height={'90vh'}
          onClose={closeGrid}
          shouldScrollInViewport={false}
          autoFocus={false}
        >
          <GridProvider
            dataType={metric.dataType}
            dateScopeOverride={dateScopeOverride}
            metricFiltering={metric.filters}
          >
            <PopupGrid close={closeGrid} />
          </GridProvider>
        </Modal>
      )}
    </ModalTransition>
  </React.Fragment>
);

export default PopupGridMetricPickerButton;
