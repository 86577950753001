import React, { useContext, useEffect, useState } from 'react';
import AccountPickerContext from '../../contexts/AccountPickerContext';

export type SeatLimitation =
  | {
      isLimited: true;
      allowedSeats: number;
    }
  | {
      isLimited: false;
    };

type IProductTierSettingsPersistedData =
  | {
      productTier?: FleetOps.TProductTier;
      seatLimitation?: SeatLimitation;
      isSSODisabled?: boolean;
    }
  | undefined;

export interface IProductTierSettings {
  productTier: FleetOps.TProductTier;
  seatLimitation: SeatLimitation;
  isSSODisabled?: boolean;
}

export const DEFAULT_PRODUCT_TIER_SETTINGS: IProductTierSettings = {
  productTier: 'Enterprise',
  seatLimitation: {
    isLimited: false,
  },
  isSSODisabled: false,
};

export const ProductTierContext = React.createContext<IProductTierSettings>(
  DEFAULT_PRODUCT_TIER_SETTINGS,
);

const ProductTierProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { accountRef } = useContext(AccountPickerContext);
  const [settings, setSettings] = useState<IProductTierSettings>(
    DEFAULT_PRODUCT_TIER_SETTINGS,
  );

  useEffect(() => {
    accountRef.onSnapshot((snapshot) => {
      const data = snapshot.data();
      if (!data) {
        return;
      }

      const persistedSettings: IProductTierSettingsPersistedData | undefined =
        data.productTierSettings;
      if (persistedSettings === undefined) {
        setSettings(DEFAULT_PRODUCT_TIER_SETTINGS);
        return;
      }

      if (persistedSettings.seatLimitation === undefined) {
        persistedSettings.seatLimitation =
          DEFAULT_PRODUCT_TIER_SETTINGS.seatLimitation;
      }

      const newSettings: IProductTierSettings = {
        productTier:
          persistedSettings.productTier ||
          DEFAULT_PRODUCT_TIER_SETTINGS.productTier,
        isSSODisabled:
          persistedSettings.isSSODisabled ||
          DEFAULT_PRODUCT_TIER_SETTINGS.isSSODisabled,
        seatLimitation:
          persistedSettings.seatLimitation ||
          DEFAULT_PRODUCT_TIER_SETTINGS.seatLimitation,
      };

      setSettings(newSettings);
    });
  }, [accountRef]);

  return (
    <ProductTierContext.Provider value={settings}>
      {children}
    </ProductTierContext.Provider>
  );
};

export default ProductTierProvider;
