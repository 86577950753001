import isV5ChartDef from './isV5ChartDef';

const isSingleMetricDateMatrix = (
  def?: ExtendedVisualisationDefinition,
): def is VisualisationDefinitions.SingleMetricDateMatrix => {
  if (isV5ChartDef(def)) {
    return false;
  }

  return !!def && def.type === 'SingleMetricDateMatrix';
};

export default isSingleMetricDateMatrix;
