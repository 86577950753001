import React, { useCallback, useContext, useEffect, useState } from 'react';
import PerformanceWizardContext from '../../PerformanceWizardContext';
import Row from '../../../../../../../components/Common/Row';
import Inputs from '../../../../../../../components/Inputs';
import Button from 'kingpin/atoms/Button';
import usePerformanceMetricDatasets from '../usePerformanceMetricDatasets';
import isDefined from '../../../../../../../isDefined';

export const PERFORMANCE_FIELD_TYPE_RED_LIST: FleetOps.FieldType[] = [
  'float' as 'float',
  'long' as 'long',
  'date' as 'date',
];

const BASE_FIELD_RED_LIST = ['reasonCode', 'reasonNote', 'id'];

const AddFieldsButtonContainer = ({ label }: { label: string }) => {
  const { setWizardState, wizardState, isReadOnly } = useContext(
    PerformanceWizardContext,
  );
  const [fieldRedList, setFieldRedList] = useState<string[] | undefined>();
  const datasetsGreenList = usePerformanceMetricDatasets();

  const onFieldsConfirmed = useCallback(
    (confirmedFields: { fields: string[]; dataType: string }[]) => {
      setWizardState((currentState) => {
        return {
          ...currentState,
          config: {
            ...currentState.config,
            fieldsStep: {
              ...currentState.config.fieldsStep,
              fields: confirmedFields,
            },
          },
        };
      });
    },
    [setWizardState],
  );

  useEffect(() => {
    const metricFieldNames = wizardState.config.metricsStep.metrics
      .map((m) => m.fieldName)
      .filter(isDefined);

    if (wizardState.config.datasetStep.entity) {
      setFieldRedList([
        ...BASE_FIELD_RED_LIST,
        wizardState.config.datasetStep.entity,
        ...metricFieldNames,
      ]);
    } else {
      setFieldRedList([...BASE_FIELD_RED_LIST, ...metricFieldNames]);
    }
  }, [
    wizardState.config.datasetStep.entity,
    wizardState.config.metricsStep.metrics,
  ]);

  if (isReadOnly) {
    return null;
  }

  return (
    <Row>
      <div>
        <Inputs.FieldsPicker
          fieldRedList={fieldRedList}
          datasetsGreenList={datasetsGreenList}
          selectedFields={wizardState.config.fieldsStep.fields}
          fieldTypeRedList={PERFORMANCE_FIELD_TYPE_RED_LIST}
          onFieldsConfirmed={onFieldsConfirmed}
          Button={(props) => (
            <Button
              type="Tertiary"
              size="Small"
              onClick={props.onClick}
              label={label}
            />
          )}
        />
      </div>
    </Row>
  );
};

export default AddFieldsButtonContainer;
