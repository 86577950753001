import React from 'react';
import Typography from 'kingpin/atoms/Typography';
import styled from 'styled-components';
import Icon from '../kingpin/atoms/Icon';
import Colors2 from '../theme/Colors2';
import Row from './Common/Row';
import Inputs from './Inputs';
import Tooltip from './Tooltip';
import ConfigureGadgetBonusPeriodInfo from './ConfigGadgetBonusPeriodInfo';

const Wrapper = styled.div`
  svg {
    cursor: help;
  }
`;

const BonusPeriodModeToggle = ({
  isBonusPeriodMode,
  onIsBonusPeriodModeChanged,
  selectedBonusPeriod,
}: {
  isBonusPeriodMode: boolean;
  onIsBonusPeriodModeChanged: (newStatus: boolean) => void;
  selectedBonusPeriod?: BonusPeriod;
}) => (
  <Wrapper>
    <Row centerAlign>
      <div style={{ marginRight: 8 }}>
        <Inputs.Toggle
          value={isBonusPeriodMode}
          onChange={onIsBonusPeriodModeChanged}
        />
      </div>
      <div style={{ marginRight: 8 }}>
        <Typography.Body type="Label">Use Driver Bonus Period</Typography.Body>
      </div>

      <Tooltip
        isAltTooltip
        content={
          <ConfigureGadgetBonusPeriodInfo
            selectedBonusPeriod={selectedBonusPeriod}
          />
        }
      >
        <Icon icon={'info'} color={Colors2.Grey['3']} height={14} width={14} />
      </Tooltip>
    </Row>
  </Wrapper>
);

export default BonusPeriodModeToggle;
