import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ModalTransition } from '@atlaskit/modal-dialog';
import AnalyticsContext from 'contexts/AnalyticsContext';
import Inputs from 'components/Inputs';
import Row from 'components/Common/Row';
import Typography from 'kingpin/atoms/Typography';
import useDangerConfirmation from 'components/ConfirmationModals/hooks/useDangerConfirmation';
import Loading from 'components/Loading';
import useTargetFields from '../../hooks/useTargetFields';
import TargetListContext from '../../contexts/TargetListContext';
import useGetForcedGroupField from '../../hooks/useGetForcedGroupField';
import Button from '../../../../kingpin/atoms/Button';

const HeadingDiv = styled.div`
  padding: 8px;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.08);
`;

const useOptions = ({
  target,
  closeParent,
  getConfirmationThat,
  lockParent,
  unlockParent,
}: {
  target: Targets.Wizard.DataTypeTarget;
  closeParent: () => void;
  getConfirmationThat: (confirmationBody: string) => Promise<boolean>;
  lockParent: () => void;
  unlockParent: () => void;
}) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const { dataset, onCopyRulesToField, targets } =
    useContext(TargetListContext);
  const { targetFields, isLoadingTargetFields } = useTargetFields(dataset);
  const [options, setOptions] = useState<DropdownOption[]>([]);
  const getForcedGroupField = useGetForcedGroupField();

  const getIsDisabled = useCallback(
    (field: string) => {
      const currentField = target.groupField;
      const newField = getForcedGroupField(field);
      return newField !== currentField && newField !== undefined;
    },
    [getForcedGroupField, target.groupField],
  );

  const buildConfirmationBody = useCallback((date: string) => {
    return `There is already a target starting on ${date}.\nThis target will be deleted. If you have Reason codes set they may become invalid.`;
  }, []);

  const isDateUsed = useCallback(
    (field: string) => {
      const targetsInField = targets.filter((t) => t.target === field);
      return targetsInField.some(
        (t) => t.effectiveDate === target.effectiveDate,
      );
    },
    [target.effectiveDate, targets],
  );

  useEffect(() => {
    if (dataset === 'NA') {
      const e = new Error();
      e.name = 'Target list: Dataset is not defined';
      throw e;
    }
    if (isLoadingTargetFields) {
      setOptions([]);
      return;
    }

    setOptions(
      targetFields
        .filter((f) => f.rawField !== target.target)
        .map((f) => ({
          label: f.label,
          tooltip: getIsDisabled(f.rawField)
            ? 'This field already has targets' +
              ' which are grouped in a manner which is not compatible with this' +
              ' target'
            : undefined,
          isDisabled: getIsDisabled(f.rawField),
          onSelected: async () => {
            lockParent();
            const isConfirmed = isDateUsed(f.rawField)
              ? await getConfirmationThat(
                  buildConfirmationBody(target.effectiveDate),
                )
              : true;
            unlockParent();

            if (isConfirmed) {
              onCopyRulesToField({
                field: f.rawField,
                target,
              });
              trackEvent('Targets - Rule Copied To Field');
              closeParent();
            }
          },
        })),
    );
  }, [
    trackEvent,
    buildConfirmationBody,
    closeParent,
    dataset,
    getConfirmationThat,
    getIsDisabled,
    isDateUsed,
    isLoadingTargetFields,
    lockParent,
    onCopyRulesToField,
    target,
    targetFields,
    unlockParent,
  ]);

  return { options, isLoading: isLoadingTargetFields };
};

const CopyRulesFieldPicker = ({
  target,
  close,
  closeParent,
  lockParent,
  unlockParent,
}: {
  target: Targets.Wizard.DataTypeTarget;
  close: () => void;
  closeParent: () => void;
  lockParent: () => void;
  unlockParent: () => void;
}) => {
  const { getConfirmationThat, DangerConfirmation } = useDangerConfirmation({
    title: 'Target Already Exists',
    checkboxText:
      'I understand that my current targets will be replaced with new targets and this action cannot be undone',
  });
  const { options, isLoading } = useOptions({
    target,
    closeParent,
    getConfirmationThat,
    lockParent,
    unlockParent,
  });

  return (
    <div>
      <HeadingDiv>
        <Row centerAlign>
          <div style={{ marginRight: 8 }}>
            <Button
              type="Ghost"
              size="Small"
              onClick={close}
              icon="chevron-left"
            />
          </div>

          <Typography.Body type="Body 12">Copy Rules To</Typography.Body>
        </Row>
      </HeadingDiv>

      {isLoading && (
        <div style={{ padding: 16, width: 240 - 16 }}>
          <Loading isSmall />
        </div>
      )}
      {!isLoading && (
        <>
          <Inputs.SearchableListPicker options={options} />
          {options.length === 0 && (
            <div style={{ paddingLeft: 16 }}>
              <Typography.Body type="Body 12">
                No fields available
              </Typography.Body>
            </div>
          )}
        </>
      )}

      <ModalTransition>{DangerConfirmation}</ModalTransition>
    </div>
  );
};

export default CopyRulesFieldPicker;
