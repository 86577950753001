import React, { useEffect, useState } from 'react';
import GeneralGoalCard from './GeneralGoalCard';
import TasksProvider from '../../../contextProviders/TasksProvider/LocalTasksProvider';

const GoalCard = ({
  goal,
  isCompact,
  workSpaceName,
  workSpaceId,
}: {
  goal: GeneralGoal;
  isCompact?: boolean;
  workSpaceName?: string;
  workSpaceId?: string;
}) => {
  const [taskSources, setTaskSources] = useState<Tasks.TaskSource[]>([]);
  const [taskDestination, setTaskDestination] = useState<
    Tasks.TaskSource | undefined
  >();

  useEffect(() => {
    setTaskSources([
      {
        type: 'Goal',
        id: goal.id,
      },
    ]);

    setTaskDestination({
      type: 'Goal',
      id: goal.id,
    });
  }, [goal.id]);

  return (
    <TasksProvider sources={taskSources} destination={taskDestination}>
      <GeneralGoalCard
        isCompact={isCompact}
        workSpaceId={workSpaceId}
        workSpaceName={workSpaceName}
      />
    </TasksProvider>
  );
};

export default GoalCard;
