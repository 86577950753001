import { useCallback, useContext } from 'react';
import createReasonCode from 'api/reasonCodes/createReasonCode';
import deleteReasonCode from 'api/reasonCodes/deleteReasonCode';
import AccountPickerContext from 'contexts/AccountPickerContext';
import BoardContext from 'contexts/BoardContext';
import getIdentifier from 'getIdentifier';

const useUpdateReasonCodes = () => {
  const { board } = useContext(BoardContext);
  const { accountRef } = useContext(AccountPickerContext);

  const updateReasonCodes = useCallback(
    ({
      mode,
      code,
      addCode,
    }: {
      mode: 'add' | 'remove';
      code?: ReasonCode;
      addCode?: string;
    }) => {
      if (mode === 'remove' && code) {
        deleteReasonCode(code, accountRef);
      } else if (mode === 'add' && addCode) {
        const newReasonCode = {
          id: getIdentifier(),
          version: '2' as '2',
          boardType: board.dataType,
          code: addCode,
        };

        createReasonCode(newReasonCode, accountRef);
      }
    },
    [accountRef, board.dataType],
  );

  return updateReasonCodes;
};

export default useUpdateReasonCodes;
