import firebase from 'firebase/compat/app';
import STORE_CONSTANTS from '../constants';
import withoutNulls from '../../api/search/withoutNulls';

export const REMINDERS_DOC = 'reminders';
export const CURRENT_REMINDERS_VERSION = 'v1';

const remindersConverter = {
  toFirestore(
    reminders: VisualisationDefinitions.Reminders,
  ): firebase.firestore.DocumentData {
    return withoutNulls({ ...reminders });
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ): VisualisationDefinitions.Reminders {
    const data = snapshot.data(options);
    return data as VisualisationDefinitions.Reminders;
  },
};

const getRemindersRef = ({
  accountId,
  db,
}: {
  accountId: string;
  db?: firebase.firestore.Firestore;
}) => {
  return (db ? db : firebase.firestore())
    .collection('accounts')
    .doc(accountId)
    .collection(STORE_CONSTANTS.VISUALISATIONS_COLLECTION)
    .doc(REMINDERS_DOC)
    .collection(CURRENT_REMINDERS_VERSION)
    .withConverter(remindersConverter);
};

export default getRemindersRef;
