import React from 'react';
import useFilteredMetrics from './useFilteredMetrics';
import MetricFilteringContext from '../../contexts/MetricFilteringContext';

const MetricFilteringProvider = ({
  children,
  dataset,
  mode,
}: {
  children: JSX.Element | JSX.Element[];
  dataset?: string;
  mode: 'Metric' | 'CompoundMetric';
}) => {
  const {
    filteredMetrics,
    filteredCompoundMetrics,
    isLoadingMetrics,
    isLoadingCompoundMetrics,
    searchText,
    onSearchTextChanged,
    datasetOptions,
    usageOptions,
    statusOptions,
    sortOptions,
    filterPlates,
    isDatasetFilterDisabled,
    onClearDatasetsClicked,
    onClearStatusClicked,
    onClearUsageClicked,
    onClearFiltersClicked,
    isClearButtonVisible,
  } = useFilteredMetrics({ dataset, mode });

  return (
    <MetricFilteringContext.Provider
      value={{
        filteredMetrics,
        filteredCompoundMetrics,
        isLoadingMetrics,
        isLoadingCompoundMetrics,
        searchText,
        onSearchTextChanged,
        datasetOptions,
        usageOptions,
        statusOptions,
        sortOptions,
        filterPlates,
        isDatasetFilterDisabled,
        onClearFiltersClicked,
        isClearButtonVisible,
        onClearDatasetsClicked,
        onClearStatusClicked,
        onClearUsageClicked,
      }}
    >
      {children}
    </MetricFilteringContext.Provider>
  );
};

export default MetricFilteringProvider;
