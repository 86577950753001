import { useContext, useEffect, useState } from 'react';
import STORE from 'store';
import AccountPickerContext from 'contexts/AccountPickerContext';

const useDatasetFilters = () => {
  const [datasetFilters, setDatasetFilters] = useState<DatasetFilter[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { selectedAccountId } = useContext(AccountPickerContext);

  useEffect(() => {
    const listener = STORE.filterDefinitions
      .getDatasetFilterRef({
        accountId: selectedAccountId,
      })
      .onSnapshot((snapshot) => {
        const data: DatasetFilter[] = [];
        snapshot.docs.forEach((d) => {
          data.push(d.data());
        });

        const newData = data.filter((df) => !df.isHidden);

        setDatasetFilters(newData);
        setIsLoading(false);
      });
    return () => {
      listener();
    };
  }, [selectedAccountId]);

  return {
    datasetFilters,
    isLoading,
  };
};

export default useDatasetFilters;
