import React from 'react';
import Alert from 'kingpin/Alert';
import Icon from 'kingpin/atoms/Icon';
import Typography from 'kingpin/atoms/Typography';
import Row from 'components/Common/Row';

const SuccessMessage = ({ successMessage }: { successMessage: string }) => {
  return (
    <Alert
      title={
        <Row centerAlign>
          <div style={{ display: 'flex', marginRight: 8 }}>
            <Icon
              icon={'circleCheckmark'}
              width={16}
              height={16}
              color={'#36B37E'}
            />
          </div>
          <Typography.Body type={'Label'}>{successMessage}</Typography.Body>
        </Row>
      }
      type={'Success'}
    />
  );
};

export default SuccessMessage;
