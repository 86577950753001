import React from 'react';
import Inputs from 'components/Inputs';

const AccountPicker = ({
  isSearchVisible,
  options,
}: {
  isSearchVisible: boolean;
  options: DropdownOption[];
}) => (
  <div style={{ marginBottom: 8 }}>
    <Inputs.Dropdown options={options} isSearchEnabled={isSearchVisible} />
  </div>
);

export default AccountPicker;
