import React from 'react';
import styled from 'styled-components';
import Typography from 'kingpin/atoms/Typography';
import Tooltip from '../Tooltip';
import Delta from './Delta';
import Row from '../Common/Row';
import useWindowSize from '../../hooks/useWindowSize';
import ScalingNumber from '../ScalingNumber';
import PopupGridButton from '../PopupGridButton';
import DashboardGadgetFilteringButton from '../DashboardGadgetFilteringButton';
import DashboardGadgetReportLinkButton from '../DashboardGadgetReportLinkButton';
import ShowOnMouseOverUnlessTour from '../ShowOnMouseOverUnlessTour';
import CopyGadgetButton from '../CopyGadgetButton';
import CanvasCardTitle from '../CanvasCardTitle';
import MetricsInfoButton from '../MetricsInfoButton';
import Colors2 from '../../theme/Colors2';
import ContextMenu from '../../kingpin/atoms/ContextMenu';

const Metrics = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  width: 100%;
  height: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Inner = styled.div`
  height: 4em;
`;

const LabelDiv = styled.div`
  text-transform: uppercase;
`;

const F1 = styled.div<{ end?: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.end ? 'flex-end' : 'flex-start')};
  flex: 1;
`;

interface MetricProp extends TrendCardItem {
  positiveDeltaIsGood?: boolean;
  metricId: string;
}

const Metric = ({
  label,
  dateLabelTooltip,
  value,
  delta,
  positiveDeltaIsGood,
  dateLabel,
  metricId,
}: MetricProp) => {
  const isGood = positiveDeltaIsGood
    ? delta && delta >= 0
    : delta && delta <= 0;

  return (
    <Wrapper>
      <Inner>
        <Tooltip content={dateLabelTooltip}>
          <React.Fragment>
            <LabelDiv>
              <Typography.Body type={'Label'}>{label}</Typography.Body>
            </LabelDiv>
            <div>
              <Typography.Body type={'Body 12'} color={Colors2.Grey['5']}>
                {dateLabel}
              </Typography.Body>
            </div>
          </React.Fragment>
        </Tooltip>

        <div style={{ fontSize: '2em', lineHeight: '2.2em' }}>
          <ScalingNumber number={value} center metricId={metricId} />
          {delta && (
            <Delta
              isGood={isGood as boolean}
              delta={delta}
              metricId={metricId}
              isGrid={false}
            />
          )}
        </div>
      </Inner>
    </Wrapper>
  );
};

const MobileLabel = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const MetricMobile = ({
  label,
  dateLabelTooltip,
  value,
  delta,
  positiveDeltaIsGood,
  dateLabel,
  metricId,
}: MetricProp) => {
  const isGood = positiveDeltaIsGood
    ? delta && delta >= 0
    : delta && delta <= 0;

  return (
    <Row
      centerAlign
      style={{ justifyContent: 'space-between', marginBottom: 8 }}
    >
      <F1>
        <Tooltip content={dateLabelTooltip}>
          <MobileLabel>
            <React.Fragment>
              <LabelDiv>
                <Typography.Body type={'Label'}>{label}</Typography.Body>
              </LabelDiv>
              <Typography.Body type={'Body 12'} color={Colors2.Grey['5']}>
                {dateLabel}
              </Typography.Body>
            </React.Fragment>
          </MobileLabel>
        </Tooltip>
      </F1>
      <F1>
        <ScalingNumber number={value} metricId={metricId} center />
      </F1>
      <F1 end>
        {delta && (
          <Delta
            isGood={isGood as boolean}
            delta={delta}
            mobile
            metricId={metricId}
            isGrid={false}
          />
        )}
      </F1>
    </Row>
  );
};

const invalidDateMessage =
  'This visualisation is not compatible with the selected date range';

export const TrendCardTopBar = ({
  name,
  chartDefinition,
}: {
  name: string;
  chartDefinition: V5ChartDefinition;
}) => (
  <Row spaceBetween centerAlign>
    <CanvasCardTitle title={name} />
    <ShowOnMouseOverUnlessTour>
      <Row>
        <DashboardGadgetReportLinkButton />
        <MetricsInfoButton visualisation={chartDefinition} />
        <ContextMenu>
          <PopupGridButton chartDefinition={chartDefinition} />
          <DashboardGadgetFilteringButton />
          <CopyGadgetButton />
        </ContextMenu>
      </Row>
    </ShowOnMouseOverUnlessTour>
  </Row>
);

export const TrendCardContent = ({
  items,
  positiveDeltaIsGood,
  metricId,
}: {
  items: TrendCardItem[];
  positiveDeltaIsGood?: boolean;
  metricId: string;
}) => {
  const { useDesktopLayout } = useWindowSize();

  if (useDesktopLayout) {
    return (
      <Metrics style={{ flexDirection: 'row', lineHeight: '1.3em' }}>
        {items.map((i) => (
          <Metric
            {...i}
            key={i.label}
            positiveDeltaIsGood={positiveDeltaIsGood}
            metricId={metricId}
          />
        ))}
        {items.length === 0 && (
          <Wrapper>
            <LabelDiv>
              <Typography.Body type={'Label'}>
                {invalidDateMessage}
              </Typography.Body>
            </LabelDiv>
          </Wrapper>
        )}
      </Metrics>
    );
  } else {
    return (
      <Metrics>
        {items.map((i) => (
          <MetricMobile
            {...i}
            key={i.label}
            positiveDeltaIsGood={positiveDeltaIsGood}
            metricId={metricId}
          />
        ))}
        {items.length === 0 && (
          <Wrapper>
            <LabelDiv>
              <Typography.Body type={'Label'}>
                {invalidDateMessage}
              </Typography.Body>
            </LabelDiv>
          </Wrapper>
        )}
      </Metrics>
    );
  }
};
