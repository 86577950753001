import React, { useContext } from 'react';
import SingleMetricMatrixContext from '../../../../contexts/SingleMetricMatrixContext';
import Button from 'kingpin/atoms/Button';
import Tooltip from '../../../Tooltip';
import buildShowAllKey from '../../../V5Gadget/buildShowAllKey';
import ReactPortal from 'components/ReactPortal';
import DashboardGadgetContext from 'contexts/DashboardGadgetContext';

const ShowAllContainer = () => {
  const {
    isAllDataLoaded,
    onShowAllPressed,
    isLoadingAll,
    currentItems,
    totalItems,
    orderedBy,
    isAllDataInitiallyAvailable,
    chartDef,
  } = useContext(SingleMetricMatrixContext);
  const { dashboardGadget } = useContext(DashboardGadgetContext);

  if (isAllDataInitiallyAvailable || totalItems === 0 || !chartDef) {
    return null;
  }

  return (
    <ReactPortal
      elementId={buildShowAllKey({
        chartDef,
        dashboardGadget,
      })}
    >
      <div>
        <Tooltip
          content={`Currently displaying ${currentItems} / ${totalItems} rows ordered by ${orderedBy}`}
        >
          <>
            {isAllDataLoaded && (
              <Button
                type="Tertiary"
                size="Small"
                label={'Showing all'}
                onClick={() => {}}
                isDisabled
                isLoading={false}
              />
            )}
            {!isAllDataLoaded && (
              <Button
                type="Tertiary"
                size="Small"
                label={'Load all'}
                onClick={onShowAllPressed}
                isDisabled={isLoadingAll}
                isLoading={isLoadingAll}
              />
            )}
          </>
        </Tooltip>
      </div>
    </ReactPortal>
  );
};

export default ShowAllContainer;
