import React from 'react';
import styled from 'styled-components';
import Typography from '../../../kingpin/atoms/Typography';

const SectionDiv = styled.div`
  margin-bottom: 16px;
`;

const LabeledSection = ({
  label,
  children,
}: {
  label: string;
  children: JSX.Element | JSX.Element[];
}) => {
  return (
    <SectionDiv>
      <div style={{ marginBottom: 4 }}>
        <Typography.Body type="Label">{label}</Typography.Body>
        {children}
      </div>
    </SectionDiv>
  );
};

export default LabeledSection;
