import { useCallback, useContext, useEffect, useState } from 'react';
import * as aguid from 'aguid';
import ConfigureMicrosoftSsoContext from '../contexts/ConfigureMicrosoftSsoContext';
import getGroupMemberships from '../api/getGroupMemberships';
import CloudFunctionClientContext from '../../../../../contexts/CloudFunctionClientContext';
import isDefined from '../../../../../isDefined';

const useUsersInSelectedGroups = () => {
  const { usEastApi: api } = useContext(CloudFunctionClientContext);
  const { selectedGroups } = useContext(ConfigureMicrosoftSsoContext);
  const [users, setUsers] = useState<MicrosoftSso.UserWithMemberships[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [refreshKey, setRefreshKey] = useState<string>(aguid());

  const getUsers = useCallback(async (): Promise<
    MicrosoftSso.UserWithMemberships[]
  > => {
    const membershipRequests = selectedGroups.map((g) =>
      getGroupMemberships(api, g.id).then((response) => {
        if (response.ok && response.data) {
          return {
            groupUsers: response.data.users,
            groupId: g.id,
          };
        }

        return undefined;
      }),
    );
    const memberships = await Promise.all(membershipRequests);
    const newUsers: {
      [email: string]: MicrosoftSso.UserWithMemberships | undefined;
    } = {};
    memberships.filter(isDefined).forEach(({ groupId, groupUsers }) => {
      groupUsers.forEach((user) => {
        const u = newUsers[user.email] || {
          groupMemberships: [],
          email: user.email,
          displayName: user.displayName,
        };
        const group = selectedGroups.find((g) => g.id === groupId);
        if (!group) {
          const e = new Error();
          e.name = 'Microsoft SSO: Group not found';
          throw e;
        }
        u.groupMemberships.push(group);
        newUsers[user.email] = u;
      });
    });

    return Object.values(newUsers).filter(isDefined);
  }, [api, selectedGroups]);

  useEffect(() => {
    let isActive = true;
    setIsLoading(true);
    getUsers().then((newUsers) => {
      if (!isActive) {
        return;
      }

      setUsers(newUsers);
      setIsLoading(false);
    });

    return () => {
      isActive = false;
    };
  }, [getUsers, refreshKey]);

  const refreshUsers = useCallback(() => {
    setRefreshKey(aguid());
  }, []);

  return {
    users,
    isLoading,
    refreshUsers,
  };
};

export default useUsersInSelectedGroups;
