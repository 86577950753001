import styled from 'styled-components';
import Colors2 from '../theme/Colors2';

const LinkWrapper = styled.div`
  &:hover {
    span {
      text-decoration: underline;
      color: ${Colors2.Primary['5']};
    }
  }
`;

export default LinkWrapper;
