import React, { useCallback, useContext } from 'react';
import { KPI_GROUP_ROW_HEIGHT } from './KpiRowRight/constants';
import ScorecardContext from 'contexts/ScorecardContext';
import Colors2 from 'theme/Colors2';

const GroupingRowRight = ({ kpiId }: { kpiId: string }) => {
  const { setHoveredKpiId } = useContext(ScorecardContext);
  const onKpiMouseEnter = useCallback(() => {
    setHoveredKpiId(kpiId);
  }, [kpiId, setHoveredKpiId]);
  return (
    <div
      onMouseEnter={onKpiMouseEnter}
      style={{
        height: `${KPI_GROUP_ROW_HEIGHT}px`,
        width: '100%',
        backgroundColor: '#f4f4f4',
        borderBottom: `1px solid ${Colors2.GridBorder}`,
        borderRight: `1px solid ${Colors2.GridBorder}`,
      }}
    ></div>
  );
};

export default GroupingRowRight;
