import { useCallback, useContext, useEffect, useState } from 'react';
import PerformanceWizardContext from '../../PerformanceWizardContext';

const useDatePeriodOptions = (
  performanceMetric: PerformanceConfiguration.Client.PerformanceMetric,
) => {
  const { setWizardState } = useContext(PerformanceWizardContext);

  const onDatePeriodTypeSelected = useCallback(
    (periodType: 'to_date' | 'full_period') => {
      setWizardState((s) => ({
        ...s,
        config: {
          ...s.config,
          metricsStep: {
            ...s.config.metricsStep,
            metrics: s.config.metricsStep.metrics.map((m) => {
              if (m.key === performanceMetric.key) {
                return {
                  ...m,
                  datePeriodType: periodType,
                };
              }
              return m;
            }),
          },
        },
      }));
    },
    [performanceMetric.key, setWizardState],
  );

  const buildOptions = useCallback(() => {
    return [
      {
        label: 'To Date',
        value: 'to_date',
        isSelected: performanceMetric.datePeriodType === 'to_date',
        onSelected: () => onDatePeriodTypeSelected('to_date'),
      },
      {
        label: 'Full Period',
        value: 'full_period',
        isSelected: performanceMetric.datePeriodType === 'full_period',
        onSelected: () => onDatePeriodTypeSelected('full_period'),
      },
    ];
  }, [onDatePeriodTypeSelected, performanceMetric.datePeriodType]);

  const [options, setOptions] = useState<DropdownOption[]>(buildOptions());

  useEffect(() => {
    setOptions(buildOptions());
  }, [buildOptions]);

  return options;
};

export default useDatePeriodOptions;
