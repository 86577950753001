import React from 'react';

interface GeneralGoalContextType {
  goal: GeneralGoal;
  dateBuckets: string[];
  primaryKpiData: Goals.MetricResult;
  primaryKpiDataCumulated?: Goals.MetricResult;
  secondaryKpisData: Goals.MetricResult[];
  goalInput: Goals.GeneralGoalInput;
  target: number;
  targetValueForNow: number;
  isPrimaryCumulative: boolean;
  currentDateBucket: string;
  lastCompletedDateBucket: string;
  currentValue: number;
}
const GeneralGoalContext = React.createContext<GeneralGoalContextType>(
  {} as GeneralGoalContextType,
);

export default GeneralGoalContext;
