import baseOptions from './baseOptions';
import formatDateLabel from '../formatDateLabel';

const stackedBar = ({
  title,
  series,
  xAxis,
  direction,
  asPercentage,
  fontSize,
  formatMetric,
}) => ({
  ...baseOptions(formatMetric),
  chart: {
    ...baseOptions(formatMetric).chart,
    type: direction === 'vertical' ? 'column' : 'bar',
    marginTop: 24,
  },
  title: {
    ...baseOptions(formatMetric).title,
    text: title,
  },
  yAxis: [
    {
      ...baseOptions(formatMetric).yAxis[0],
      title: {
        enabled: false,
      },
      reversedStacks: false,
      labels: {
        style: {
          fontSize,
        },
      },
    },
  ],
  xAxis,
  series,
  plotOptions: {
    column: {
      stacking: asPercentage ? 'percent' : 'present',
    },
    series: {
      stacking: asPercentage ? 'percent' : 'present',
      findNearestPointBy: 'xy',
      allowPointSelect: true,
      borderRadius: 2,
      point: {
        events: {
          click: function (e) {
            const { onDrillDown } = this.series.userOptions;
            if (!onDrillDown) {
              alert('Coming soon...');
              return;
            }
            const selectedIndex = e.point.x;
            const terms = e.point.series.data;
            terms.forEach((t, index) => {
              if (index === selectedIndex) {
                onDrillDown(t.series.name, e.ctrlKey || e.metaKey);
              }
            });
          },
        },
      },
    },
  },
  tooltip: {
    ...baseOptions(formatMetric).tooltip,
    formatter: function () {
      const { x, points } = this;
      if (points.length === 0) {
        return '';
      }

      const { metricId, interval } = points[0].series.userOptions;
      const headerText = (() => {
        if (interval) {
          return formatDateLabel(x, interval);
        } else {
          return x;
        }
      })();

      let pointsHtml = '';
      points.forEach((p) => {
        if (p.y > 0) {
          const formattedY = formatMetric({
            metricId,
            value: p.y,
          });

          pointsHtml += `<div><span style="color:${p.color}">● </span><span style="color: #878F9D;">${p.series.name}: </span><b>${formattedY}</b></div><br/>`;
        }
      });

      return `<div>${headerText}<br/>${pointsHtml}`;
    },
  },
});

export default stackedBar;
