import React from 'react';
import Button from 'kingpin/atoms/Button';

import LandingPage from '../components/LandingPage';
import Branding from '../components/Branding';
import Card from '../components/Common/Card';
import Typography from 'kingpin/atoms/Typography';
import Row from '../components/Common/Row';
import { Link } from 'react-router-dom';
import appRoutes from '../navigation/appRoutes';
import styled from 'styled-components';
import Colors from '../theme/colors';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .iycCLd {
    background-color: ${Colors.WHITE} !important;
  }
`;

const InvitationAlreadyAccepted = () => (
  <LandingPage>
    <Branding />
    <Card style={{ width: 466, padding: '31px 44px 31px 32px' }}>
      <Layout>
        <Typography.Header type="H4">
          This invitation has already been accepted.
        </Typography.Header>
        <Row style={{ marginTop: 48 }}>
          <Link to={appRoutes.logIn}>
            <Button label="Log in" type="Primary" size="Small" />
          </Link>
        </Row>
      </Layout>
    </Card>
  </LandingPage>
);

export default InvitationAlreadyAccepted;
