import { useCallback, useContext, useEffect, useState } from 'react';
import getCostModel from '../../api/getCostModel';
import GqlClientContext from 'contexts/GqlClientContext';

const useGetCostModel = (costModelType: Costs.ModelType) => {
  const { client } = useContext(GqlClientContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [costModel, setCostModel] = useState<Costs.PersistedCostModel>();

  const getCostModelCallback = useCallback(async () => {
    const res = await getCostModel(costModelType, client);
    return res.length === 0 ? undefined : res[res.length - 1];
  }, [client, costModelType]);

  const refreshModel = useCallback(async () => {
    setIsLoading(true);
    const newModel = await getCostModelCallback();
    setCostModel(newModel);
    setIsLoading(false);
    return;
  }, [getCostModelCallback]);

  useEffect(() => {
    let isActive = true;
    setIsLoading(true);
    getCostModelCallback().then((model) => {
      if (isActive) {
        setCostModel(model);
        setIsLoading(false);
      }
    });

    return () => {
      isActive = false;
    };
  }, [client, costModelType, getCostModelCallback]);

  return { costModel, isLoading, refreshModel };
};

export default useGetCostModel;
