import { useCallback, useContext } from 'react';
import getUserAccountAccess from '../api/getUserAccountAccess';
import updateUserRole from '../api/updateUserRole';
import RolesContext from '../contexts/RolesContext';
import usePermissionsApi from '../contextProviders/RolesProvider/usePermissionsApi';
import CloudFunctionClientContext from '../contexts/CloudFunctionClientContext';

export const useGetCurrentAccessProfile = () => {
  const api = usePermissionsApi();

  const getCurrentAccessProfile = useCallback(
    async (userId: string) => {
      return getUserAccountAccess(userId, api).then((response) => {
        if (response.data && response.data.userProfile) {
          const { roles, permissions } = response.data.userProfile;
          return {
            roles: roles || [],
            permissions: permissions || [],
          };
        }

        const error = new Error(
          `Could not getCurrentAccessProfile of ${userId}`,
        );
        error.name = 'Granting/Revoking role failed';
        throw error;
      });
    },
    [api],
  );

  return getCurrentAccessProfile;
};

const useGrantRevokeRole = () => {
  const { api } = useContext(CloudFunctionClientContext);
  const { primaryRoles } = useContext(RolesContext);
  const getCurrentAccessProfile = useGetCurrentAccessProfile();

  const grantRole = useCallback(
    async (userId: string, role: string) => {
      const currentAccessProfile = await getCurrentAccessProfile(userId);

      const newRoles = [...currentAccessProfile.roles, role];
      const newPermissions = currentAccessProfile.permissions;

      await updateUserRole(userId, newRoles, newPermissions, api);
    },
    [api, getCurrentAccessProfile],
  );

  const revokeRole = useCallback(
    async (userId: string, role: string) => {
      const currentAccessProfile = await getCurrentAccessProfile(userId);

      const newRoles = currentAccessProfile.roles.filter((r) => r !== role);
      const newPermissions = currentAccessProfile.permissions;

      await updateUserRole(userId, newRoles, newPermissions, api);
    },
    [api, getCurrentAccessProfile],
  );

  const setPrimaryRole = useCallback(
    async (userId: string, role: string) => {
      const primaryRoleIds = primaryRoles.map((primaryRole) => primaryRole.id);

      const currentAccessProfile = await getCurrentAccessProfile(userId);
      const rolesWithoutAnyPrimary = currentAccessProfile.roles.filter(
        (r) => !primaryRoleIds.includes(r),
      );
      const newRoles = [...rolesWithoutAnyPrimary, role];
      const newPermissions = currentAccessProfile.permissions;

      await updateUserRole(userId, newRoles, newPermissions, api);
    },
    [api, getCurrentAccessProfile, primaryRoles],
  );

  return { grantRole, revokeRole, setPrimaryRole };
};

export default useGrantRevokeRole;
