import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

const buildQuery = (
  metric: MetricInput,
  filterInput: FilterInput[],
  dateScope: DateRangeInput,
  toMetricInput: (metric: Metrics.NormalMetric | MetricInput) => MetricInput,
  groupBy?: V5GroupBy,
  trendByCalendarInterval?: FleetOps.Interval,
  trendByFixedIntervalDays?: number,
  groupByDayOfWeek?: boolean,
) => {
  return {
    query: gql`
      query aggregateMetric(
        $metric: MetricInput!
        $filters: [FilterInput]!
        $dateScope: [DateRangeInput!]!
        $groupBy: GroupBy
        $trendByCalendarInterval: Interval
        $trendByFixedIntervalDays: Int
        $groupByDayOfWeek: Boolean
      ) {
        aggregateMetric(
          metric: $metric
          dateScope: $dateScope
          filters: $filters
          groupBy: $groupBy
          trendByCalendarInterval: $trendByCalendarInterval
          trendByFixedIntervalDays: $trendByFixedIntervalDays
          groupByDayOfWeek: $groupByDayOfWeek
        )
      }
    `,
    variables: {
      metric: toMetricInput(metric),
      filters: filterInput,
      dateScope,
      groupBy,
      trendByCalendarInterval,
      trendByFixedIntervalDays,
      groupByDayOfWeek,
    },
  };
};

const aggregateMetric = async (
  metric: MetricInput,
  filterInput: FilterInput[],
  dateScope: DateRangeInput,
  toMetricInput: (metric: Metrics.NormalMetric | MetricInput) => MetricInput,
  client: ApolloClient<NormalizedCacheObject>,
  groupBy?: V5GroupBy,
  trendByCalendarInterval?: FleetOps.Interval,
  trendByFixedIntervalDays?: number,
  groupByDayOfWeek?: boolean,
): Promise<MetricResponse[]> => {
  const query = buildQuery(
    metric,
    filterInput,
    dateScope,
    toMetricInput,
    groupBy,
    trendByCalendarInterval,
    trendByFixedIntervalDays,
    groupByDayOfWeek,
  );
  const response = await client.query(query);
  return response.data.aggregateMetric;
};

export default aggregateMetric;
