import React, { ChangeEvent, useCallback, useContext, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import * as aguid from 'aguid';

import ImageCardEditForm from './ImageCardEditForm';
import captureException from '../../../services/captureException';
import DashboardContext from '../../../contexts/DashboardContext';
import useImageSrc from '../useImageSrc';
import updateDashboard from '../../../api/updateDashboard';
import DashboardsContext from '../../../contexts/DashboardsContext';
import AccountContext from '../../../contexts/AccountContext';
import CanvasContext from '../../../contexts/CanvasContext';
import buildCardLayout from '../../../contextProviders/WidgetGalleryProvider/buildCardLayout';
import findStartingY from '../../../contextProviders/WidgetGalleryProvider/findStartingY';
import AccountPickerContext from '../../../contexts/AccountPickerContext';

const ImageCardEditFormContainer = ({
  close,
  isOpen,
  card,
  startEditing,
  setCurrentCanvas,
  canvasMode,
}: {
  close: () => void;
  isOpen: boolean;
  card?: CanvasCard.ImageCard;
  startEditing?: () => void;
  setCurrentCanvas?: React.Dispatch<React.SetStateAction<Canvas>>;
  canvasMode?: CanvasMode;
}) => {
  const { accountRef } = useContext(AccountPickerContext);
  const { id: accountId } = useContext(AccountContext);
  const [storagePath, setStoragePath] = useState<string | undefined>(
    card ? card.content.imageSrc : undefined,
  );
  const [imageLayout, setImageLayout] = useState<'stretch' | 'center'>(
    card ? card.content.imageLayout || 'stretch' : 'stretch',
  );
  const [isUploadingImage, setIsUploadingImage] = useState<boolean>(false);
  const { dashboard } = useContext(DashboardContext);
  const { updateCard } = useContext(CanvasContext);
  const { isLoading, imgSrc } = useImageSrc(storagePath);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { addOrUpdateDashboard } = useContext(DashboardsContext);

  const onLayoutToggled = (event: ChangeEvent<HTMLInputElement>) => {
    const newLayout = event.target.checked ? 'stretch' : 'center';
    setImageLayout(newLayout);
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newPath = `accounts/${accountId}/imageCards/${aguid()}`;

    if (event.target.files && event.target.files.length === 1) {
      setIsUploadingImage(true);
      const file = event.target.files[0];
      firebase
        .storage()
        .ref(newPath)
        .put(file, { cacheControl: 'max-age=604800' })
        .then(() => {
          setIsUploadingImage(false);
          setStoragePath(newPath);
        })
        .catch((ex) => {
          captureException(ex);
        });
    }
  };

  const onSave = useCallback(() => {
    if (isSaving || isUploadingImage) {
      return;
    }

    if (card) {
      // Updating
      const newCard = {
        ...card,
        content: {
          ...card.content,
          imageSrc: storagePath,
          imageLayout,
        },
      } as CanvasCard.ImageCard;

      if (dashboard) {
        const newCanvasCards = dashboard.canvas.cards.map((c) => {
          if (c.layout.i === card.layout.i) {
            return newCard;
          } else {
            return c;
          }
        });
        const newDashboard = {
          ...dashboard,
          canvas: {
            ...dashboard.canvas,
            cards: newCanvasCards,
          },
        };

        updateDashboard({
          id: newDashboard.id,
          newDashboard,
          accountRef,
        }).then(() => {
          setIsSaving(false);
          addOrUpdateDashboard(newDashboard);
          updateCard(newCard);
          close();
        });
      }
    } else if (setCurrentCanvas && canvasMode && storagePath && startEditing) {
      // Create new
      setCurrentCanvas((c) => {
        const newCard = (() => {
          return {
            layout: buildCardLayout(0, findStartingY(c.cards, canvasMode)),
            content: {
              type: 'Image' as 'Image',
              imageSrc: storagePath,
            },
          };
        })();

        return {
          ...c,
          cards: [...c.cards, newCard],
        };
      });
      startEditing();
      close();
    }
  }, [
    accountRef,
    addOrUpdateDashboard,
    canvasMode,
    card,
    close,
    dashboard,
    imageLayout,
    isSaving,
    isUploadingImage,
    setCurrentCanvas,
    startEditing,
    storagePath,
    updateCard,
  ]);

  return (
    <ImageCardEditForm
      close={close}
      isOpen={isOpen}
      isUploadingImage={isUploadingImage}
      imageLayout={imageLayout}
      onChange={onChange}
      onLayoutToggled={onLayoutToggled}
      imgSrc={imgSrc}
      isLoadingImgSrc={isLoading}
      onSave={onSave}
      isSaving={isSaving}
      hasImage={!!storagePath}
    />
  );
};

export default ImageCardEditFormContainer;
