import React from 'react';

interface MetricListsContextType {
  metricLists: MetricListGadgetType[];
  metricListsLookup: { [id: string]: MetricListGadgetType | undefined };
  isLoading: boolean;
}

const MetricListsContext = React.createContext<MetricListsContextType>({
  metricListsLookup: {},
  metricLists: [],
  isLoading: false,
});

export default MetricListsContext;
