import isV5ChartDef from './isV5ChartDef';

const isGauge = (
  def: undefined | ExtendedVisualisationDefinition,
): def is VisualisationDefinitions.Gauge => {
  if (isV5ChartDef(def)) {
    return false;
  }

  return def !== undefined && def.type === 'Gauge';
};

export default isGauge;
