import React, { useContext, useEffect, useState } from 'react';
import EmptyDashboard from './EmptyDashboard';
import usePopup from '../../../hooks/usePopup';
import WidgetGalleryContext from '../../../contexts/WidgetGalleryContext';
import aguid from 'aguid';
import createMetricListGadget from '../../../api/metricListGadgets/createMetricListGadget';
import buildCardLayout from 'contextProviders/WidgetGalleryProvider/buildCardLayout';
import findStartingY from 'contextProviders/WidgetGalleryProvider/findStartingY';
import IFrameForm from '../../IFrameForm';
import VideoCardForm from '../../VideoCardForm';
import TextCardForm from '../../TextCardForm';
import CurrentUserContext from '../../../contexts/CurrentUserContext';
import ImageCardEditForm from '../../ImageGadget/ImageCardEditForm';
import AccountPickerContext from '../../../contexts/AccountPickerContext';
import AddScorecardForm from '../../Dashboard/AddScorecardForm';
import AddGoalForm from 'components/Dashboard/AddGoalForm';
import WallboardContext from 'contexts/WallboardContext';
import RolesContext from 'contexts/RolesContext';
import GOAL_MANAGEMENT_PERMISSIONS from 'permissionDefinitions/goalManagement';

const WIDGET_OPTIONS = [
  'Add Gadget from Gallery',
  'Create new Gadget',
  'Add Metric List',
  'Add KPI List',
  'Add Image Card',
  'Add Video Card',
  'Add Text Card',
  'Add IFrame',
];

const hasEditingSearchQuery = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const isEditing =
    urlParams.has('editing') && urlParams.get('editing') === 'true';
  return isEditing;
};

const useWidgetOptions = () => {
  const { currentPermissions } = useContext(RolesContext);
  const { isWallboard } = useContext(WallboardContext);

  const [selectedWidgetOption, setSelectedWidgetOption] = useState<
    string | undefined
  >();
  const [widgetOptions, setWidgetOptions] = useState<RadioOption[]>([]);
  const hasCreateGoalPermission = currentPermissions.includes(
    GOAL_MANAGEMENT_PERMISSIONS.CRUD_GOALS,
  );

  useEffect(() => {
    const base = WIDGET_OPTIONS.map((o) => ({
      key: o,
      label: o,
      isSelected: selectedWidgetOption === o,
      onSelected: () => {
        setSelectedWidgetOption(o);
      },
    }));
    const extras: RadioOption[] = [];

    if (hasCreateGoalPermission && !isWallboard) {
      extras.push({
        key: 'add goal',
        label: "Add Goal'",
        isSelected: selectedWidgetOption === 'Add Goal',
        onSelected: () => {
          setSelectedWidgetOption('Add Goal');
        },
      });
    }

    const newOptions = [...base, ...extras];
    setWidgetOptions(newOptions);
  }, [hasCreateGoalPermission, isWallboard, selectedWidgetOption]);

  return {
    widgetOptions,
    selectedWidgetOption,
  };
};

const EmptyDashboardContainer = ({
  startEditing,
  setCurrentCanvas,
  canvasMode,
}: {
  startEditing: () => void;
  setCurrentCanvas: React.Dispatch<React.SetStateAction<Canvas>>;
  canvasMode: CanvasMode;
}) => {
  const { selectedAccountId } = useContext(AccountPickerContext);
  const { id: currentUserId } = useContext(CurrentUserContext);
  const { isOpen, open, close } = usePopup(hasEditingSearchQuery());
  const {
    isOpen: isIFrameFormOpen,
    open: openIFrameForm,
    close: closeIFrameForm,
  } = usePopup();
  const {
    isOpen: isImageCardFormOpen,
    open: openImageCardForm,
    close: closeImageCardForm,
  } = usePopup();
  const {
    isOpen: isVideoCardFormOpen,
    open: openVideoCardForm,
    close: closeVideoCardForm,
  } = usePopup();
  const {
    isOpen: isTextCardFormOpen,
    open: openTextCardForm,
    close: closeTextCardForm,
  } = usePopup();
  const {
    isOpen: isAddScorecardFormOpen,
    open: openAddScorecardForm,
    close: closeAddScorecardForm,
  } = usePopup(false, close);
  const {
    isOpen: isAddGoalFormOpen,
    open: openAddGoalForm,
    close: closeAddGoalForm,
  } = usePopup();

  const { open: openWidgetGallery } = useContext(WidgetGalleryContext);
  const { widgetOptions, selectedWidgetOption } = useWidgetOptions();

  const onMetricListAdded = async () => {
    const metricListId = aguid();
    await createMetricListGadget({
      id: metricListId,
      name: 'Metric List',
      currentUserId,
      accountId: selectedAccountId,
    });

    setCurrentCanvas((c) => {
      const newCard = (() => {
        return {
          layout: buildCardLayout(0, findStartingY(c.cards, canvasMode)),
          content: {
            type: 'Metric List' as 'Metric List',
            metricListId,
          },
        };
      })();

      return {
        ...c,
        cards: [...c.cards, newCard],
      };
    });
  };

  const onAddClicked = () => {
    if (!selectedWidgetOption) {
      return;
    }

    switch (selectedWidgetOption) {
      case 'Add Gadget from Gallery':
        openWidgetGallery();
        break;
      case 'Create new Gadget':
        openWidgetGallery(true);
        break;
      case 'Add Goal':
        openAddGoalForm();
        break;
      case 'Add KPI List':
        openAddScorecardForm();
        break;
      case 'Add Metric List':
        onMetricListAdded();
        break;
      case 'Add Image Card':
        openImageCardForm();
        break;
      case 'Add Video Card':
        openVideoCardForm();
        break;
      case 'Add Text Card':
        openTextCardForm();
        break;
      case 'Add IFrame':
        openIFrameForm();
        break;
    }
  };

  return (
    <React.Fragment>
      <EmptyDashboard
        isOpen={isOpen}
        open={open}
        close={close}
        isDisabled={selectedWidgetOption === undefined}
        widgetOptions={widgetOptions}
        onAddClicked={onAddClicked}
      />
      <AddGoalForm
        close={closeAddGoalForm}
        isOpen={isAddGoalFormOpen}
        setCurrentCanvas={setCurrentCanvas}
        startEditing={startEditing}
        canvasMode={canvasMode}
      />
      <AddScorecardForm
        close={closeAddScorecardForm}
        isOpen={isAddScorecardFormOpen}
        setCurrentCanvas={setCurrentCanvas}
        startEditing={startEditing}
        canvasMode={canvasMode}
      />
      <IFrameForm
        close={closeIFrameForm}
        isOpen={isIFrameFormOpen}
        setCurrentCanvas={setCurrentCanvas}
        startEditing={startEditing}
        canvasMode={canvasMode}
      />
      {isImageCardFormOpen && (
        <ImageCardEditForm
          close={closeImageCardForm}
          isOpen={isImageCardFormOpen}
          setCurrentCanvas={setCurrentCanvas}
          startEditing={startEditing}
          canvasMode={canvasMode}
        />
      )}
      <VideoCardForm
        close={closeVideoCardForm}
        isOpen={isVideoCardFormOpen}
        setCurrentCanvas={setCurrentCanvas}
        startEditing={startEditing}
        canvasMode={canvasMode}
      />
      <TextCardForm
        close={closeTextCardForm}
        isOpen={isTextCardFormOpen}
        setCurrentCanvas={setCurrentCanvas}
        startEditing={startEditing}
        canvasMode={canvasMode}
      />
    </React.Fragment>
  );
};

export default EmptyDashboardContainer;
