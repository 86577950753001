import { useContext, useEffect, useState } from 'react';
import AccountPickerContext from '../contexts/AccountPickerContext';
import STORE from '../store';

const useReasonCodes = (dataset: string) => {
  const { selectedAccountId } = useContext(AccountPickerContext);
  const [reasonCodes, setReasonCodes] = useState<ReasonCode[]>([]);

  useEffect(() => {
    const listener = STORE.getReasonCodesRef({
      accountId: selectedAccountId,
      dataset,
    }).onSnapshot((snapshot) => {
      const data: ReasonCode[] = [];
      snapshot.docs.forEach((doc) => data.push(doc.data()));
      setReasonCodes(data);
    });

    return () => {
      listener();
    };
  }, [dataset, selectedAccountId]);

  return reasonCodes;
};

export default useReasonCodes;
