const buildWeekStartsOnLabel = (weekStartsOn: WeekStartsOn) => {
  switch (weekStartsOn) {
    case 'MON':
      return 'Mon - Sun';
    case 'TUE':
      return 'Tue - Mon';
    case 'WED':
      return 'Wed - Tue';
    case 'THUR':
      return 'Thur - Wed';
    case 'FRI':
      return 'Fri - Thur';
    case 'SAT':
      return 'Sat - Fri';
    case 'SUN':
    default:
      return 'Sun - Sat';
  }
};

export default buildWeekStartsOnLabel;
