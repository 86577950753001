import { useCallback, useContext } from 'react';

import useGetInitialGroups from './useGetInitialGroups';
import TargetFormContext from '../contexts/TargetFormContext';

const useTargetFormCallbacks = () => {
  const { setGroups } = useContext(TargetFormContext);
  const getInitialGroups = useGetInitialGroups();

  const resetGroups = useCallback(
    (isTargetedByGroup: boolean) => {
      setGroups(getInitialGroups(isTargetedByGroup));
    },
    [getInitialGroups, setGroups],
  );

  const updateTarget = useCallback(
    ({
      target,
      group,
    }: {
      target: Targets.Wizard.Target;
      group: Targets.Wizard.TargetGroup;
    }) => {
      setGroups((currentGroups) => {
        return currentGroups.map((g) => {
          if (g.key !== group.key) {
            return g;
          }

          if (!g.targets) {
            const error = new Error();
            error.name = 'Targets not defined';
            throw error;
          }

          const targetMatch = g.targets.find((t) => t.key === target.key);
          if (!targetMatch) {
            const error = new Error();
            error.name = 'Target not found';
            throw error;
          }

          const newTargets = g.targets.map((t) => {
            if (t.key === target.key) {
              return target;
            }
            return t;
          });

          return {
            ...g,
            targets: newTargets,
          };
        });
      });
    },
    [setGroups],
  );

  return {
    updateTarget,
    resetGroups,
  };
};

export default useTargetFormCallbacks;
