import React from 'react';

import dateRangeInputToAmericanLabel from '../../dateRangeInputToAmericanLabel';
import TimeSeriesComparisonTooltip from '../V5Gadget/TimeSeriesComparisonTooltip';
import useValueFormatters from '../../hooks/useValueFormatters';

export const DeltaTooltip = ({
  metric,
  current,
  previous,
  currentDateRange,
  previousDateRange,
}: {
  metric: Metrics.Metric;
  current: number;
  previous: number;
  currentDateRange: DateRangeInput;
  previousDateRange: DateRangeInput;
  // eslint-disable-next-line react/display-name
}) => {
  const { formatMetric } = useValueFormatters();
  const currentPeriodLabel = dateRangeInputToAmericanLabel(currentDateRange);
  const previousPeriodLabel = dateRangeInputToAmericanLabel(previousDateRange);
  const currentFormatted = formatMetric({
    metricId: metric.id,
    value: current,
  });
  const prevFormatted = formatMetric({ metricId: metric.id, value: previous });

  return (
    <TimeSeriesComparisonTooltip
      currentDateText={currentPeriodLabel}
      previousDateText={previousPeriodLabel}
      metric={metric}
      prevValue={previous}
      prevFormatted={prevFormatted}
      currentValue={current}
      currentFormatted={currentFormatted}
      currentDateRange={currentDateRange}
      previousDateRange={previousDateRange}
      formatMetricOverride={formatMetric}
    />
  );
};

export default DeltaTooltip;
