import React, { useContext } from 'react';
import styled from 'styled-components';

import NavSideBarContext from '../contexts/NavSideBarContext';
import CurrentUserContext from '../contexts/CurrentUserContext';

export const Layout = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  position: relative;
`;

export const OPENED_NAV_WIDTH = 227;
export const CLOSED_NAV_WIDTH = 47;

export const ScreenWrapperElement = styled.div<{
  isNavOpen: boolean;
  isScrollable?: boolean;
  isFullWidth?: boolean;
}>`
  height: 100%;
  width: calc(
    100% -
      ${(props) =>
        props.isFullWidth
          ? '0px'
          : props.isNavOpen
            ? `${OPENED_NAV_WIDTH}px`
            : `${CLOSED_NAV_WIDTH}px`}
  );
  background-color: white;
  ${(props) => (props.isScrollable ? `overflow-y: auto;` : '')}
`;

export const ScreenWrapper = ({
  children,
  isScrollable,
  testId,
  isFullWidth,
}: {
  children: JSX.Element | (JSX.Element | false)[];
  isScrollable?: boolean;
  testId?: string;
  isFullWidth?: boolean;
}) => {
  const { isOpen } = useContext(NavSideBarContext);
  const { isWallboardUser } = useContext(CurrentUserContext);

  const isWallBoardPreview = window.location.href
    .toLowerCase()
    .includes('wallboard');
  const isNavOpen = isWallboardUser || isWallBoardPreview ? false : isOpen;

  return (
    <ScreenWrapperElement
      isNavOpen={isNavOpen}
      isScrollable={isScrollable}
      data-testid={testId}
      isFullWidth={isFullWidth || isWallBoardPreview}
    >
      {children}
    </ScreenWrapperElement>
  );
};
