/* eslint-disable no-undef */
import captureException from './services/captureException';

const openIntercom = () => {
  try {
    // @ts-ignore
    Intercom('show');
  } catch (ex) {
    captureException(ex);
  }
};

export const hideIntercomButton = () => {
  try {
    const elem = document.getElementsByClassName('intercom-launcher')[0];
    if (elem) {
      // @ts-ignore
      elem.style.display = 'none';
    }
  } catch (ex) {
    captureException(ex);
  }
};

export const showIntercomButton = () => {
  try {
    const elem = document.getElementsByClassName('intercom-launcher')[0];
    if (elem) {
      // @ts-ignore
      elem.style.display = 'block';
    }
  } catch (ex) {
    captureException(ex);
  }
};

export default openIntercom;
