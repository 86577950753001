import { useCallback, useContext } from 'react';
import relativeDateRangeToDateRange from 'relativeDateRangeToDateRange';
import AccountContext from 'contexts/AccountContext';
import getDateBuckets from 'screens/GoalShow/getDateBuckets';
import moment from 'moment';

export const getCostDateLabelValuePair = ({
  date,
  interval,
}: {
  date: string;
  interval: Costs.IntervalType;
}) => {
  if (interval === 'month') {
    return {
      value: date,
      label: moment(date).format("MMMM 'YY"),
    };
  }
  const startMoment = moment(date);
  const endMoment = startMoment.add(6, 'days');

  return {
    value: date,
    label: `${moment(date).format('DD')} - ${endMoment.format("DD MMM 'YY")}`,
  };
};

const useGetLastQuarter = ({
  wizardState,
}: {
  wizardState: Costs.WizardState;
}) => {
  const { weekStartsOn } = useContext(AccountContext);

  const getLastQuarter = useCallback(() => {
    if (!wizardState) {
      return [];
    }
    const { startDate, endDate } = relativeDateRangeToDateRange({
      relativeDateRange: {
        interval: wizardState.config.basicStep.interval,
        n: wizardState.config.basicStep.interval === 'week' ? 13 : 3,
        type: 'last' as 'last',
        currentToDate: false,
      },
      startOfWeek: wizardState.config.basicStep.startOfWeek || weekStartsOn,
    });

    if (!startDate || !endDate) {
      const error = new Error();
      error.name = 'startDate or/and endDate undefined';
      throw error;
    }

    const startDates = getDateBuckets({
      startDate,
      endDate,
      weekStartsOn: wizardState.config.basicStep.startOfWeek || weekStartsOn,
      interval: wizardState.config.basicStep.interval,
    });

    const interval = wizardState.config.basicStep.interval;
    if (wizardState.config.basicStep.interval === 'month') {
      return startDates.map((date) =>
        getCostDateLabelValuePair({ date, interval }),
      );
    } else {
      return startDates.map((date) => {
        return getCostDateLabelValuePair({ date, interval });
      });
    }
  }, [weekStartsOn, wizardState]);

  return getLastQuarter;
};

export default useGetLastQuarter;
