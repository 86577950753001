import React from 'react';

import EntityLink from '../V5Gadget/Matrix/GroupingCellRenderer/EntityLink';
import { FleetopsGridCellRendererParams } from '../../types/agGrid';
import GridCellErrorBoundary from '../GridCellErrorBoundary';
import Cell from '../V5Gadget/Matrix/Cell';
import Row from '../Common/Row';
import Tooltip from '../Tooltip';

const EntityCell = (props: FleetopsGridCellRendererParams) => {
  const { value, colDef, node } = props;

  return (
    <Cell>
      <Row centerAlign>
        <Tooltip content={value}>
          <span>{value}</span>
        </Tooltip>
        <EntityLink colDef={colDef} value={value} node={node} />
      </Row>
    </Cell>
  );
};

const Gate = (params: FleetopsGridCellRendererParams) => (
  <GridCellErrorBoundary
    params={params}
    childComponent={<EntityCell {...params} />}
  />
);

export default Gate;
