import React from 'react';
import MetricItem from './MetricItem';
import useMetricListItem from '../../../hooks/useMetricListItem';
import useMetric from '../../../hooks/useMetric';
import usePopupCallback from '../../../hooks/usePopupCallback';
import metricTypeCheckers from '../../../types/metricTypeCheckers';

const MetricItemContainer = ({
  item,
  comparison,
  isBonusPeriodMode,
  bonusPeriodId,
}: {
  item: MetricListItemType;
  comparison?: PersistedComparisonType;
  isBonusPeriodMode: boolean;
  bonusPeriodId?: string;
}) => {
  const { name } = item;
  const {
    isLoading,
    current,
    currentFilterInput,
    previous,
    previousFilterInput,
    popupReportFilter,
    currentDateRange,
    previousDateRange,
  } = useMetricListItem(item, isBonusPeriodMode, comparison);
  const metric = useMetric(item.metricId);

  const onNameClicked = usePopupCallback(
    item,
    popupReportFilter,
    !!metric && metricTypeCheckers.isNormalMetric(metric) ? metric : undefined,
    isBonusPeriodMode,
    bonusPeriodId,
  );

  return (
    <MetricItem
      onNameClicked={onNameClicked}
      name={name}
      current={current}
      currentFilterInput={currentFilterInput}
      previous={previous}
      previousFilterInput={previousFilterInput}
      metric={metric}
      currentDateRange={currentDateRange}
      previousDateRange={previousDateRange}
      isLoading={isLoading}
    />
  );
};

export default MetricItemContainer;
