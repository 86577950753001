import React from 'react';

interface DashboardGadgetsContextType {
  allGadgets: DashboardGadget[];
  dashboardGadgetsLookup: { [id: string]: DashboardGadget | undefined };
  isLoading: boolean;
}

const DashboardGadgetsContext =
  React.createContext<DashboardGadgetsContextType>({
    allGadgets: [] as DashboardGadget[],
    dashboardGadgetsLookup: {},
    isLoading: true,
  });

export default DashboardGadgetsContext;
