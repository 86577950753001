import React, { ChangeEvent, useEffect, useState } from 'react';
import TextInput from '../../kingpin/atoms/TextInput';

const NumberInput = ({
  value,
  setValue,
  placeholder,
  tabIndex,
  readOnly,
  testId,
}: {
  value?: number;
  setValue: (newValue: number | undefined) => void;
  placeholder?: string;
  tabIndex?: number;
  readOnly?: boolean;
  testId?: string;
  noBorders?: boolean;
}) => {
  const [v, setV] = useState<string>(value ? value.toString(10) : '');

  useEffect(() => {
    setV(value ? value.toString(10) : '');
  }, [value]);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newV = event.target.value;
    setV(newV);
    if (newV === '') {
      setValue(undefined);
    } else {
      const asNum = Number.parseFloat(newV);
      if (isNaN(asNum)) {
        setValue(0);
      } else {
        setValue(asNum);
      }
    }
  };

  return (
    <TextInput
      value={v}
      onChange={onChange}
      type="number"
      placeholder={placeholder}
      tabIndex={tabIndex}
      readOnly={readOnly}
      data-testid={testId}
      width="100%"
    />
  );
};

export default NumberInput;
