import Typography from 'kingpin/atoms/Typography';
import React from 'react';
import {
  ColorOrderCheckbox,
  DisableTargetsCheckbox,
  DynamicTargetCheckbox,
} from '../ScorecardKpiForm/KpiForm';
import Button from 'kingpin/atoms/Button';
import TargetBandsForm from '../ScorecardKpiForm/TargetBandsForm';
import TargetBands from '../ScorecardKpiForm/TargetBands';
import targetDateToString from 'types/scorecardDates/targetDateToString';

const ManualTargets = ({
  scorecard,
  isShowingAdvanced,
  isTargetsDisabled,
  setIsTargetsDisabled,
  dynamicTargetOverride,
  onDynamicTargetOverrideToggled,
  markHasUnsavedChanges,
  isColorOrderReversed,
  onReverseColorOrderToggled,
  isAddingNewTargetBand,
  isEditingDateTarget,
  onAddClicked,
  targets,
  setTargets,
  onNewTargetBandCancelled,
  onNewTargetBandSaved,
  setCanSaveKpi,
  sortedTargetBands,
  onTargetBandSaved,
  setIsEditingDateTarget,
}: {
  scorecard: Scorecards.Scorecard;
  isShowingAdvanced: boolean;
  isTargetsDisabled: boolean;
  setIsTargetsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  dynamicTargetOverride?: boolean;
  onDynamicTargetOverrideToggled: (newValue: boolean) => void;
  markHasUnsavedChanges: () => void;
  isColorOrderReversed: boolean;
  onReverseColorOrderToggled: () => void;
  isAddingNewTargetBand: boolean;
  isEditingDateTarget: boolean;
  onAddClicked: () => void;
  targets: Scorecards.WeekTargets | Scorecards.IsoTargets;
  setTargets: React.Dispatch<
    React.SetStateAction<Scorecards.WeekTargets | Scorecards.IsoTargets>
  >;
  onTargetBandSaved: (
    newStartDate: Scorecards.IsoDate | Scorecards.WeekDate,
    newBands: string[],
  ) => void;
  onNewTargetBandSaved: (
    newStartDate: Scorecards.IsoDate | Scorecards.WeekDate,
    newBands: string[],
  ) => void;
  onNewTargetBandCancelled: () => void;
  setCanSaveKpi: React.Dispatch<React.SetStateAction<boolean>>;
  sortedTargetBands: {
    date: Scorecards.IsoDate | Scorecards.WeekDate;
    band: string[] | undefined;
  }[];
  setIsEditingDateTarget: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <>
      <div style={{ marginBottom: 16 }}>
        <Typography.Body type="Label">Targets</Typography.Body>
        <DisableTargetsCheckbox
          isTargetsDisabled={isTargetsDisabled}
          setIsTargetsDisabled={setIsTargetsDisabled}
          markHasUnsavedChanges={markHasUnsavedChanges}
        />
        {!isTargetsDisabled && isShowingAdvanced && (
          <>
            <DynamicTargetCheckbox
              dynamicTargetOverride={dynamicTargetOverride}
              isDynamicTargetByDefault={false}
              onDynamicTargetOverrideToggled={onDynamicTargetOverrideToggled}
            />
            <ColorOrderCheckbox
              isColorOrderReversed={isColorOrderReversed}
              onReverseColorOrderToggled={onReverseColorOrderToggled}
            />
          </>
        )}
      </div>

      {!isTargetsDisabled && (
        <>
          <div style={{ marginBottom: 16 }}>
            {!isAddingNewTargetBand && !isEditingDateTarget && (
              <div style={{ width: 'fit-content', marginBottom: 16 }}>
                <Button
                  type="Tertiary"
                  size="Small"
                  label={'Add new'}
                  onClick={onAddClicked}
                />
              </div>
            )}
            {isAddingNewTargetBand && (
              <TargetBandsForm
                initialStartDate={
                  Object.keys(targets.targets).length === 0
                    ? scorecard.startDate
                    : undefined
                }
                targets={targets}
                isStartDateEditable={Object.keys(targets.targets).length > 0}
                isColorOrderReversed={isColorOrderReversed}
                onCancelled={onNewTargetBandCancelled}
                onSaved={onNewTargetBandSaved}
                setCanSaveKpi={setCanSaveKpi}
                markHasUnsavedChanges={markHasUnsavedChanges}
              />
            )}

            {sortedTargetBands.map(({ date, band }) => {
              if (band === undefined) {
                return null;
              }

              return (
                <TargetBands
                  key={`${targetDateToString(date)}-bands`}
                  startDate={date}
                  targetBands={band}
                  targets={targets}
                  setTargets={setTargets}
                  isColorOrderReversed={isColorOrderReversed}
                  onTargetBandSaved={onTargetBandSaved}
                  isAddingNewTargetBand={isAddingNewTargetBand}
                  isEditingDateTarget={isEditingDateTarget}
                  setIsEditingDateTarget={setIsEditingDateTarget}
                  setCanSaveKpi={setCanSaveKpi}
                  markHasUnsavedChanges={markHasUnsavedChanges}
                />
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default ManualTargets;
