import React, { useCallback, useContext } from 'react';
import AnalyticsContext from 'contexts/AnalyticsContext';
import Typography from 'kingpin/atoms/Typography';
import Row from 'components/Common/Row';
import useDatasetDef from './useDatasetDef';
import Filter from './Filter';
import AddFilterButton from './AddFilterButton';
import FilterBuilderContext from './FilterBuilderContext';

const FilterBuilder = ({
  dataset,
  filters,
  updateFilters,
  fieldFilterPredicate,
  trackingEvents,
  isInvalid,
}: {
  dataset: FleetOps.DatasetDefinition;
  filters: FilterBuilder.Client.FieldFilter[];
  updateFilters: (newFilter: FilterBuilder.Client.FieldFilter[]) => void;
  fieldFilterPredicate?: (field: FleetOps.Field) => boolean;
  trackingEvents?: FilterBuilder.TrackingEvents;
  isInvalid?: boolean;
}) => {
  const { trackEvent } = useContext(AnalyticsContext);

  const addFilter = useCallback(
    (filter: FilterBuilder.Client.FieldFilter) => {
      if (trackingEvents) {
        trackEvent(trackingEvents.onFilterAdded);
      }
      updateFilters([...filters, filter]);
    },
    [filters, trackEvent, trackingEvents, updateFilters],
  );

  const updateFilter = useCallback(
    (filter: FilterBuilder.Client.FieldFilter) => {
      updateFilters(
        filters.map((f) => {
          if (f.key === filter.key) {
            return filter;
          }

          return f;
        }),
      );
    },
    [filters, updateFilters],
  );

  const removeFilter = useCallback(
    (filter: FilterBuilder.Client.FieldFilter) => {
      updateFilters(
        filters.filter((f) => {
          return f.key !== filter.key;
        }),
      );
    },
    [filters, updateFilters],
  );

  const isShowingPlaceholder = filters.length === 0;
  return (
    <FilterBuilderContext.Provider
      value={{
        filters,
        datasetDefinition: dataset,
        fieldFilterPredicate,
      }}
    >
      <Row
        style={{ flexWrap: 'wrap', gap: '4px', marginBottom: 8 }}
        centerAlign
      >
        {filters.map((f, index) => (
          <div key={f.key}>
            <Filter
              key={f.key}
              filter={f}
              updateFilter={updateFilter}
              removeFilter={removeFilter}
            />
            {index < filters.length - 1 && (
              <div style={{ marginRight: 8, marginLeft: 8 }}>
                <Typography.Body type="Body 12">&</Typography.Body>
              </div>
            )}
          </div>
        ))}
        <AddFilterButton
          addFilter={addFilter}
          isPlaceholder={isShowingPlaceholder}
          trackingEvents={trackingEvents}
          isInvalid={isInvalid}
        />
      </Row>
    </FilterBuilderContext.Provider>
  );
};

const Gate = ({
  dataset,
  filters,
  updateFilters,
  fieldFilterPredicate,
  trackingEvents,
  isInvalid,
}: {
  dataset: string;
  filters: FilterBuilder.Client.FieldFilter[];
  updateFilters: (newFilter: FilterBuilder.Client.FieldFilter[]) => void;
  fieldFilterPredicate?: (field: FleetOps.Field) => boolean;
  trackingEvents?: FilterBuilder.TrackingEvents;
  isInvalid?: boolean;
}) => {
  const datasetDef = useDatasetDef(dataset);

  if (!datasetDef) {
    return null;
  }

  return (
    <FilterBuilder
      dataset={datasetDef}
      filters={filters}
      updateFilters={updateFilters}
      fieldFilterPredicate={fieldFilterPredicate}
      trackingEvents={trackingEvents}
      isInvalid={isInvalid}
    />
  );
};

export default Gate;
