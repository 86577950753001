import React, { useContext, useEffect, useState } from 'react';
import BoardDataGridSection from './BoardDataGridSection';
import { useSectionData } from '../BoardDataCardSection';
import AccountContext from '../../../contexts/AccountContext';
import BaseViewsContext from '../../../contexts/BaseViewsContext';
import useValueFormatters from '../../../hooks/useValueFormatters';

const BoardDataGridSectionContainer = ({
  section,
}: {
  section: BoardSlideOutGridSection;
}) => {
  const { unitsLocale } = useContext(AccountContext);
  const { baseViews } = useContext(BaseViewsContext);
  const { formatField } = useValueFormatters();
  const { label } = section;
  const {
    data,
    isLoading,
    filterLabel,
    isV2FilterOn,
    isFilterToggle,
    onFilterToggled,
  } = useSectionData(section);
  const [grid, setGrid] = useState<{ [field: string]: string | number }[]>([]);

  useEffect(() => {
    const newGrid = data.map((d) => {
      const row = {} as { [field: string]: string | number };
      section.cardDef.forEach(({ field }) => {
        row[field] = formatField({
          field,
          value: d[field],
          dataset: section.dataType,
        });
      });
      return row;
    });
    setGrid(newGrid);
  }, [
    baseViews,
    data,
    formatField,
    section.cardDef,
    section.dataType,
    unitsLocale,
  ]);

  return (
    <BoardDataGridSection
      label={label}
      grid={grid}
      isLoading={isLoading}
      filterLabel={filterLabel}
      isV2FilterOn={isV2FilterOn}
      isFilterToggle={isFilterToggle}
      onFilterToggled={onFilterToggled}
    />
  );
};

export default BoardDataGridSectionContainer;
