import { useCallback, useContext } from 'react';
import PerformanceWizardContext from '../../PerformanceWizardContext';

const useGetIsExistingMetric = () => {
  const { originalConfig } = useContext(PerformanceWizardContext);

  const getIsExistingMetric = useCallback(
    (pm: PerformanceConfiguration.Client.PerformanceMetric) => {
      return (
        !!originalConfig &&
        originalConfig.config.metricsStep.metrics.some(
          (m) => m.key === pm.key,
        ) &&
        originalConfig.firestoreId === undefined
      );
    },
    [originalConfig],
  );

  return getIsExistingMetric;
};

export default useGetIsExistingMetric;
