import React, { useCallback, useContext, useEffect, useState } from 'react';
import PortalPickerStyles from './styles';
import Row from '../../../components/Common/Row';
import Typography from '../../atoms/Typography';
import Icon from '../../atoms/Icon';
import { PortalsContext } from '../../../contextProviders/PortalsProvider';

const PortalOption = ({
  portal,
  close,
}: {
  portal: Portal;
  close: () => void;
}) => {
  const { selectedPortal, availablePortals, onPortalSelected } =
    useContext(PortalsContext);

  const getIsSelected = useCallback((): boolean => {
    if (!selectedPortal) {
      return false;
    }

    return selectedPortal.id === portal.id;
  }, [portal.id, selectedPortal]);

  const getIsAvailable = useCallback((): boolean => {
    return availablePortals.some((p) => p.id === portal.id);
  }, [availablePortals, portal.id]);

  const [isSelected, setIsSelected] = useState<boolean>(() => getIsSelected());
  const [isAvailable, setIsAvailable] = useState<boolean>(() =>
    getIsAvailable(),
  );

  useEffect(() => {
    setIsSelected(getIsSelected());
  }, [getIsSelected]);

  useEffect(() => {
    setIsAvailable(getIsAvailable());
  }, [getIsAvailable]);

  const onClick = useCallback(() => {
    if (!isAvailable) {
      return;
    }

    onPortalSelected(portal);
    close();
  }, [close, isAvailable, onPortalSelected, portal]);

  const { color, name } = portal;

  return (
    <PortalPickerStyles.PortalOptionDiv
      isSelected={isSelected}
      onClick={onClick}
    >
      <Row centerAlign>
        <div style={{ marginRight: 8, display: 'flex' }}>
          <PortalPickerStyles.ColorDot color={color} width={8} height={8} />
        </div>
        <Typography.Body type={'Button Text'}>{name}</Typography.Body>
      </Row>
      {isSelected && <Icon icon={'checkmark'} color={'#1A1919'} />}
    </PortalPickerStyles.PortalOptionDiv>
  );
};

export default PortalOption;
