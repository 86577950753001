import styled from 'styled-components';
import MetricInfoConstants from '../constants';

const CardInner = styled.div`
  padding: ${MetricInfoConstants.CARD_PADDING_VERTICAL}
    ${MetricInfoConstants.CARD_PADDING_HORIZONTAL};

  .filter-plate {
    margin-bottom: 4px;
  }

  .filter-plate:last-of-type {
    margin-bottom: 0px;
  }
`;

export default CardInner;
