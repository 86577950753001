import React from 'react';
import Button from 'kingpin/atoms/Button';

import Row from '../../Common/Row';
import Loading from '../../Loading/Loading';
import useUser from '../../../hooks/useUser';
import Tooltip from '../../Tooltip';
import MultiUserSelect from '../../MultiUserSelect';
import UserIcon from '../../Common/UserIcon';
import Typography from 'kingpin/atoms/Typography';
import PermissionGates from '../../PermissionGates';
import PERMISSIONS from '../../../permissionDefinitions';
import userTypeCheckers from '../../../contextProviders/UsersProvider/userTypeCheckers';
import Badge from '../../Badge';

const UserIconWrapper = ({ userId }: { userId?: string }) => {
  const { user } = useUser(userId);

  if (!user) {
    return null;
  }

  return (
    <div style={{ marginRight: -12 }}>
      <UserIcon {...user} />
    </div>
  );
};

const SignedOffByTooltip = ({
  signedOffById,
  children,
}: {
  signedOffById?: string;
  children: JSX.Element | JSX.Element[];
}) => {
  const { user } = useUser(signedOffById);

  if (!user) {
    return <>{children}</>;
  }

  if (userTypeCheckers.isWallboardUser(user)) {
    return null;
  }

  return (
    <Tooltip content={`Signed off by ${user.displayName}`}>
      <>{children}</>
    </Tooltip>
  );
};

const BonusSignOff = ({
  isLoading,
  assignedUsers,
  setAssignedUsers,
  isCurrentUserAssigned,
  isSignedOff,
  signedOffById,
  onSignedOffChanged,
  isSignOffReady,
  assignedLabelOverride,
}: {
  isLoading: boolean;
  assignedUsers: string[];
  setAssignedUsers: React.Dispatch<React.SetStateAction<string[]>>;
  isCurrentUserAssigned: boolean;
  isSignedOff: boolean;
  signedOffById?: string;
  onSignedOffChanged: (newStatus: boolean) => void;
  isSignOffReady: boolean;
  assignedLabelOverride: string;
}) => (
  <Row centerAlign>
    {isLoading && (
      <div style={{ marginRight: 4 }}>
        <Loading isTiny />
      </div>
    )}

    <PermissionGates.Has
      requiredPermission={PERMISSIONS.USER_MANAGEMENT.GRANT_BOARD_OWNER_ROLE}
      fallback={
        <div>
          <Row style={{ justifyContent: 'flex-end' }} centerAlign>
            <div style={{ width: 80, marginRight: 4 }}>
              <Typography.Body type="Label">Assigned to:</Typography.Body>
            </div>
            {assignedUsers.map((userId) => (
              <UserIconWrapper userId={userId} key={userId} />
            ))}
          </Row>
        </div>
      }
    >
      <div
        style={{
          width: 200,
          marginRight: 16,
        }}
      >
        <Row centerAlign>
          <div style={{ width: 80, marginRight: 4 }}>
            <Typography.Body type="Label">Assigned to:</Typography.Body>
          </div>
          <div style={{ width: 110 }}>
            <MultiUserSelect
              selectedUsers={assignedUsers}
              setSelectedUsers={setAssignedUsers}
            />
          </div>
        </Row>
      </div>
    </PermissionGates.Has>
    {isCurrentUserAssigned && !isSignedOff && (
      <React.Fragment>
        <Row centerAlign>
          <Tooltip
            content={
              isSignOffReady
                ? undefined
                : 'You will be notified when this board is ready for sign off'
            }
          >
            <Button
              isDisabled={!isSignOffReady}
              onClick={() => onSignedOffChanged(true)}
              testId="sign off toggle"
              type="Primary"
              size="Small"
              label="Sign off"
            />
          </Tooltip>
        </Row>
      </React.Fragment>
    )}
    {isSignedOff && (
      <div style={{ marginLeft: 8 }}>
        <SignedOffByTooltip signedOffById={signedOffById}>
          <Badge badgeType="Success" text="Signed Off" />
        </SignedOffByTooltip>
      </div>
    )}
  </Row>
);

export default BonusSignOff;
