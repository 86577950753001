import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { ListItem } from './Dropdown';
import Tooltip from '../Tooltip';
import Typography from 'kingpin/atoms/Typography';
import TextInput from '../../kingpin/atoms/TextInput';
import Icon from '../../kingpin/atoms/Icon';

const RecentsDiv = styled.div`
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.08);
  margin-bottom: 8px;
`;

export const List = styled.div`
  padding: 0px 8px;
  max-height: 200px;
  overflow-y: auto;
`;

const useSearchOptions = (
  options: DropdownOption[],
  recentOptions?: DropdownOption[],
) => {
  const [searchText, setSearchText] = useState<string>('');
  const [filteredOptions, setFilteredOptions] =
    useState<DropdownOption[]>(options);
  const [filteredRecentOptions, setFilteredRecentOptions] = useState<
    DropdownOption[] | undefined
  >(recentOptions);

  const onSearchTextChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
    },
    [],
  );

  useEffect(() => {
    if (searchText === '') {
      setFilteredOptions(options);
      setFilteredRecentOptions(recentOptions);
      return;
    }

    setFilteredOptions(
      options.filter((o) =>
        o.label.toLowerCase().includes(searchText.toLowerCase()),
      ),
    );
    if (recentOptions) {
      setFilteredRecentOptions(
        recentOptions.filter((o) =>
          o.label.toLowerCase().includes(searchText.toLowerCase()),
        ),
      );
    }
  }, [options, recentOptions, searchText]);

  return {
    searchText,
    onSearchTextChanged,
    filteredOptions,
    filteredRecentOptions,
  };
};

const OptionsList = ({ options }: { options: DropdownOption[] }) => {
  return (
    <List>
      {options.map((o) => (
        <Tooltip key={o.label} content={o.tooltip ? o.tooltip : o.label}>
          <ListItem
            onClick={o.isDisabled ? undefined : o.onSelected}
            isDisabled={o.isDisabled}
          >
            <Typography.Body type="Body 12">{o.label}</Typography.Body>
            {o.isSelected && <Icon icon="checkmark" />}
          </ListItem>
        </Tooltip>
      ))}
    </List>
  );
};

const Recents = ({
  filteredRecentOptions,
}: {
  filteredRecentOptions?: DropdownOption[];
}) => {
  if (!filteredRecentOptions || filteredRecentOptions.length === 0) {
    return null;
  }

  return (
    <RecentsDiv>
      <div style={{ padding: 8 }}>
        <Typography.Body type="Label">RECENT</Typography.Body>
      </div>
      <OptionsList options={filteredRecentOptions} />
    </RecentsDiv>
  );
};

const SearchableListPicker = ({
  options,
  recentOptions,
}: {
  options: DropdownOption[];
  recentOptions?: DropdownOption[];
}) => {
  const {
    searchText,
    onSearchTextChanged,
    filteredOptions,
    filteredRecentOptions,
  } = useSearchOptions(options, recentOptions);

  return (
    <div>
      <div style={{ padding: 8 }}>
        <TextInput
          value={searchText}
          onChange={onSearchTextChanged}
          icon="search"
          placeholder="Search"
          autoFocus
        />
      </div>
      <Recents filteredRecentOptions={filteredRecentOptions} />
      <OptionsList options={filteredOptions} />
    </div>
  );
};

export default SearchableListPicker;
