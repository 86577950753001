import React from 'react';

import ListItemWrapper from '../ListItemWrapper';
import Column from '../Column';
import Typography from 'kingpin/atoms/Typography';

const Headers = () => (
  <ListItemWrapper
    style={{
      alignItems: 'center',
      backgroundColor: '#F8F8F8',
      borderRadius: '8px',
      borderBottom: 'unset',
      boxShadow: 'unset',
      height: '48px',
    }}
  >
    <Column style={{ flex: 2 }}>
      <Typography.Body type="Label">Field</Typography.Body>
    </Column>
    <Column style={{ flex: 2 }}>
      <Typography.Body type="Label">Display Name</Typography.Body>
    </Column>
    <Column style={{ flex: 2 }}>
      <Typography.Body type="Label">Description</Typography.Body>
    </Column>
    <Column style={{ flex: 2 }}>
      <Typography.Body type="Label">Field Type</Typography.Body>
    </Column>
    <Column style={{ flex: 2 }}>
      <Typography.Body type="Label">Cell Type</Typography.Body>
    </Column>
    <Column style={{ flex: 1 }}>
      <Typography.Body type="Label">Enabled</Typography.Body>
    </Column>
    {/* Drag handle */}
    <Column />
  </ListItemWrapper>
);

export default Headers;
