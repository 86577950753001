import firebase from 'firebase/compat/app';

const accountConverter = {
  toFirestore(): firebase.firestore.DocumentData {
    throw new Error(`The client should never write to accounts directly`);
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot<FleetOps.Account>,
    options: firebase.firestore.SnapshotOptions,
  ): FleetOps.Account {
    const account = snapshot.data(options);
    return account;
  },
};

const getAccountsRef = ({ db }: { db?: firebase.firestore.Firestore }) => {
  return (db ? db : firebase.firestore())
    .collection('accounts')
    .withConverter(accountConverter);
};

export default getAccountsRef;
