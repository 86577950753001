import { useCallback, useContext, useState } from 'react';
import STORE from 'store';
import AccountPickerContext from 'contexts/AccountPickerContext';
import AnalyticsContext from '../../../../contexts/AnalyticsContext';

const useOnSave = ({
  getNewDefinition,
  isValid,
  close,
}: {
  getNewDefinition: () => EntityDetails.Entity | undefined;
  isValid: boolean;
  close: () => void;
}) => {
  const { updateVitallyAccountTrait } = useContext(AnalyticsContext);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const onSave = useCallback(async () => {
    if (!isValid || isLoading) {
      return;
    }

    const newDef = getNewDefinition();
    if (!newDef) {
      return;
    }

    setIsLoading(true);

    await STORE.getEntityDefinitionsRef({
      accountId: selectedAccountId,
    })
      .doc(newDef.id)
      .set(newDef);
    updateVitallyAccountTrait({
      traitName: `Entity App - ${newDef.name}: enabled`,
      traitValue: newDef.isEnabled,
    });

    setIsLoading(false);
    close();
  }, [
    close,
    getNewDefinition,
    isLoading,
    isValid,
    selectedAccountId,
    updateVitallyAccountTrait,
  ]);

  return { onSave, isLoading };
};

export default useOnSave;
