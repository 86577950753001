import React, { useContext } from 'react';
import Row from 'components/Common/Row';

import TargetFormContext from '../../../contexts/TargetFormContext';
import ModeDropdown from './ModeDropdown';
import SimpleInput from './SimpleInput';
import GridInput from './GridInput';

const TargetValueInput = ({
  target,
  group,
  avgValueTooltip,
}: {
  target: Targets.Wizard.Target;
  group: Targets.Wizard.TargetGroup;
  avgValueTooltip?: string;
}) => {
  const { isGridMode } = useContext(TargetFormContext);

  return (
    <>
      <Row centerAlign>
        <ModeDropdown target={target} group={group} />
        {!isGridMode && (
          <SimpleInput
            target={target}
            group={group}
            avgValueTooltip={avgValueTooltip}
          />
        )}
      </Row>
      {isGridMode && <GridInput target={target} group={group} />}
    </>
  );
};

export default TargetValueInput;
