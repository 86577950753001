import { useCallback, useContext } from 'react';

import ReportsContext from '../contexts/ReportsContext';
import PopupContext from '../contexts/PopupContext';
import BoardsContext from '../contexts/BoardsContext';
import DateInputContext from '../contexts/DateInputContext';
import { useLocation, useNavigate } from 'react-router-dom';
import VariableFiltersContext from '../contexts/VariableFiltersContext';
import useSelectedBonusPeriod from './useSelectedBonusPeriod';
import SavedFiltersContext from '../contexts/SavedFiltersContext';
import useDateScope from './useDateScope';
import isDefined from '../isDefined';
import useToPlatesWithoutVariables from './usePlatesWithoutVariables';

const isDashboardGadget = (
  drillDownable: DashboardGadget | DrillDownable | undefined,
): drillDownable is DashboardGadget => {
  if (drillDownable === undefined) {
    return false;
  }

  // @ts-ignore
  return !!drillDownable.chartDef;
};

const isMetric = (
  drillDownable: MetricListItemType | DrillDownable | undefined,
): drillDownable is MetricListItemType => {
  if (drillDownable === undefined) {
    return false;
  }

  // @ts-ignore
  return !!drillDownable.savedFilterId;
};

const getMetricFilter = (
  metricItem: MetricListItemType,
  savedFilters: SavedFilter[],
) => {
  const maybeFilter = savedFilters.find(
    (sf) => sf.id === metricItem.savedFilterId,
  );
  if (!maybeFilter) {
    return [];
  }

  return [...maybeFilter.drillDowns];
};

const isMouseEvent = (
  r?: ReportDrillDownType | MouseEvent,
): r is MouseEvent => {
  // @ts-ignore
  return r && r.button !== undefined;
};

const usePopupCallback = (
  drillDownable:
    | DashboardGadget
    | MetricListItemType
    | DrillDownable
    | undefined,
  unSavedFilter?: UnSavedFilter,
  metric?: Metrics.NormalMetric,
  isBonusPeriodMode?: boolean,
  bonusPeriodId?: string,
  isCardOnClick?: boolean,
  term?: string,
  drillDownFromInteraction?: ReportDrillDownType,
) => {
  const reportDrillDownId = drillDownable
    ? drillDownable.reportDrillDownId
    : undefined;
  const boardDrillDownId = drillDownable
    ? drillDownable.boardDrillDownId
    : undefined;
  const location = useLocation();
  const navigate = useNavigate();
  const { savedFilters } = useContext(SavedFiltersContext);
  const { allReports } = useContext(ReportsContext);
  const { boards } = useContext(BoardsContext);
  const {
    openPopupReport,
    openPopupBoard,
    setSelectedReport,
    setDashboardGadget,
    setUnSavedFilter,
    setSelectedBoard,
  } = useContext(PopupContext);
  const { selectedBonusPeriod } = useSelectedBonusPeriod();
  const { variableFilters } = useContext(VariableFiltersContext);
  const { dateField, dateRange, relativeDateRange, advancedRelativeDateRange } =
    useContext(DateInputContext);
  const dateScope = useDateScope({});
  const selectedReport = allReports.find(
    (r) => r.id === reportDrillDownId && reportDrillDownId !== undefined,
  );
  const selectedBoard = boards.find(
    (b) => b.id === boardDrillDownId && boardDrillDownId !== undefined,
  );
  const toPlatesWithoutVariables = useToPlatesWithoutVariables();

  const popupReport = useCallback(
    (drillDown?: ReportDrillDownType | MouseEvent) => {
      if (!selectedReport) {
        return;
      }

      const drillDownableWithDateField = (() => {
        const drillDownableWithFixedFilters = isDashboardGadget(drillDownable)
          ? {
              ...drillDownable,
              dateField,
              dateRange,
              relativeDateRange,
              advancedRelativeDateRange,
              drillDowns: toPlatesWithoutVariables({
                plates: drillDownable.drillDowns,
                variableDrillDowns: variableFilters,
              }),
              scope: toPlatesWithoutVariables({
                plates: drillDownable.scope,
                variableDrillDowns: variableFilters,
              }),
            }
          : drillDownable;
        if (isDashboardGadget(drillDownable) && isCardOnClick) {
          return {
            ...drillDownable,
            dateRange,
            relativeDateRange,
            advancedRelativeDateRange,
            dateField,
            drillDowns: toPlatesWithoutVariables({
              plates: drillDownable.drillDowns,
              variableDrillDowns: variableFilters,
            }),
            scope: toPlatesWithoutVariables({
              plates: drillDownable.scope,
              variableDrillDowns: variableFilters,
            }),
          } as DashboardGadget;
        }

        return drillDownableWithFixedFilters;
      })();
      setDashboardGadget(
        isDashboardGadget(drillDownableWithDateField)
          ? drillDownableWithDateField
          : undefined,
      );
      openPopupReport(
        drillDown && !isMouseEvent(drillDown) ? [drillDown] : [],
        'Dashboard',
      );
      setUnSavedFilter(unSavedFilter);
      setSelectedReport(selectedReport);
    },

    [
      advancedRelativeDateRange,
      dateField,
      dateRange,
      drillDownable,
      isCardOnClick,
      openPopupReport,
      relativeDateRange,
      selectedReport,
      setDashboardGadget,
      setSelectedReport,
      setUnSavedFilter,
      toPlatesWithoutVariables,
      unSavedFilter,
      variableFilters,
    ],
  );

  const popupBoard = useCallback(() => {
    if (!selectedBoard) {
      return;
    }

    const plates = (() => {
      if (isDashboardGadget(drillDownable)) {
        const drillDownPlate = drillDownFromInteraction
          ? {
              id: drillDownFromInteraction.id,
              type: 'Fixed' as 'Fixed',
              fixedValue: drillDownFromInteraction,
            }
          : undefined;

        return [...drillDownable.drillDowns, drillDownPlate].filter(isDefined);
      } else if (isMetric(drillDownable)) {
        return getMetricFilter(drillDownable, savedFilters);
      } else {
        return [];
      }
    })();

    setDashboardGadget(
      isDashboardGadget(drillDownable) ? drillDownable : undefined,
    );
    openPopupBoard({
      popupDateRange: dateScope,
      popupFilters: toPlatesWithoutVariables({
        plates,
        variableDrillDowns: variableFilters,
      }),
      scope: isDashboardGadget(drillDownable)
        ? toPlatesWithoutVariables({
            plates: drillDownable.scope,
            variableDrillDowns: variableFilters,
          })
        : [],
      bonusPeriodId: bonusPeriodId
        ? bonusPeriodId
        : selectedBonusPeriod
          ? selectedBonusPeriod.id
          : undefined,
      origin: 'Dashboard',
    });
    setUnSavedFilter(unSavedFilter);
    setSelectedBoard(selectedBoard);
    if (selectedBoard.category === 'Driver Performance' && term) {
      const newSearch = `driver=${term}`;
      navigate({ search: newSearch }, { replace: location.search.length > 0 });
    }
  }, [
    bonusPeriodId,
    dateScope,
    drillDownFromInteraction,
    drillDownable,
    location.search.length,
    navigate,
    openPopupBoard,
    savedFilters,
    selectedBoard,
    selectedBonusPeriod,
    setDashboardGadget,
    setSelectedBoard,
    setUnSavedFilter,
    term,
    toPlatesWithoutVariables,
    unSavedFilter,
    variableFilters,
  ]);

  if (!drillDownable) {
    return undefined;
  } else if (reportDrillDownId && !!selectedReport) {
    return popupReport;
  } else if (boardDrillDownId && !!selectedBoard) {
    return popupBoard;
  } else {
    return undefined;
  }
};

export default usePopupCallback;
