import React, { useContext, useEffect, useState } from 'react';
import ImpersonatorContext from 'contexts/ImpersonatorContext';
import UsersContext from '../contexts/UsersContext';
import userTypeCheckers from './UsersProvider/userTypeCheckers';

const ImpersonatorProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { allUsers } = useContext(UsersContext);
  const [impersonatorId, setImpersonatorId] = useState<string | undefined>();
  const [viewingAppAs, setViewingAppAs] = useState<
    UserManagement.SignedUpUser | UserManagement.PendingUser | undefined
  >();
  useEffect(() => {
    if (!impersonatorId) {
      setViewingAppAs(undefined);
      return;
    }

    const viewingAs = allUsers
      .filter(userTypeCheckers.isSignedUpOrPendingUser)
      .find((u) => u.id === impersonatorId);
    if (!viewingAs) {
      setViewingAppAs(undefined);
      return;
    }

    setViewingAppAs(viewingAs);
  }, [allUsers, impersonatorId]);

  return (
    <ImpersonatorContext.Provider
      value={{
        impersonatorId,
        setImpersonatorId,
        viewingAppAs,
      }}
    >
      {children}
    </ImpersonatorContext.Provider>
  );
};

export default ImpersonatorProvider;
