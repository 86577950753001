import React, { useContext, useEffect, useState } from 'react';
import ReportCanvasCard from './ReportCanvasCard';
import ChartDefinitionsContext from '../../contexts/ChartDefinitionsContext';
import DashboardGadgetsContext from '../../contexts/DashboardGadgetsContext';
import CardContext from 'contexts/CardContext';
import ReportContext from '../../contexts/ReportContext';
import HighchartStyles from '../HighchartStyles';
import { getLayout } from '../ReportCanvas/buildLayout';
import BonusPeriodsProvider from '../../contextProviders/BonusPeriodsProvider';
import cardTypeCheckers from '../../types/cardTypeCheckers';

const ReportCanvasCardContainer = ({
  card,
  onRemoveClicked,
  isEditing,
  canvasMode,
  isDragging,
}: {
  card: CanvasCard.Card;
  onRemoveClicked: () => void;
  isEditing: boolean;
  canvasMode: CanvasMode;
  isDragging: boolean;
}) => {
  const { definitionsLookup } = useContext(ChartDefinitionsContext);
  const { dashboardGadgetsLookup } = useContext(DashboardGadgetsContext);
  const [dashboardGadget, setDashboardGadget] = useState<
    DashboardGadget | undefined
  >();
  const [chartDefinition, setChartDefinition] = useState<
    VisualisationDefinition | undefined
  >();

  useEffect(() => {
    setDashboardGadget(
      cardTypeCheckers.isDashboardGadget(card)
        ? dashboardGadgetsLookup[card.content.dashboardGadgetId]
        : undefined,
    );
  }, [card, dashboardGadgetsLookup]);

  useEffect(() => {
    setChartDefinition(
      dashboardGadget
        ? dashboardGadget.chartDef
        : cardTypeCheckers.isChartDefinition(card)
          ? definitionsLookup[card.content.chartDefinitionId]
          : undefined,
    );
  }, [definitionsLookup, card, dashboardGadget]);

  const { isExporting } = useContext(ReportContext);

  return (
    <div id={card.layout.i} style={{ width: '100%', height: '100%' }}>
      <CardContext.Provider
        value={{
          i: getLayout(card, canvasMode).i,
          w: getLayout(card, canvasMode).w,
          h: getLayout(card, canvasMode).h,
          chartDefinition,
        }}
      >
        <BonusPeriodsProvider>
          <CardOnClickProvider
            dashboardGadget={dashboardGadget}
            card={card}
            onRemoveClicked={onRemoveClicked}
            isEditing={isEditing}
            chartDefinition={chartDefinition}
            isExporting={!!isExporting}
            isDragging={isDragging}
          />
        </BonusPeriodsProvider>
      </CardContext.Provider>
    </div>
  );
};

const CardOnClickProvider = ({
  dashboardGadget,
  card,
  onRemoveClicked,
  isEditing,
  chartDefinition,
  isExporting,
  isDragging,
}: {
  dashboardGadget: DashboardGadget | undefined;
  card: CanvasCard.Card;
  onRemoveClicked: () => void;
  isEditing: boolean;
  chartDefinition: VisualisationDefinition | undefined;
  isExporting: boolean;
  isDragging: boolean;
}) => {
  return (
    <HighchartStyles style={{ width: '100%', height: '100%' }}>
      <ReportCanvasCard
        card={card}
        onRemoveClicked={onRemoveClicked}
        isEditing={isEditing}
        chartDefinition={chartDefinition}
        dashboardGadget={dashboardGadget}
        isExporting={!!isExporting}
        isDragging={isDragging}
      />
    </HighchartStyles>
  );
};

export default ReportCanvasCardContainer;
