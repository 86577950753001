import React from 'react';
import Typography from 'kingpin/atoms/Typography';
import toSentenceCase from '../../../services/toSentenceCase';
import Row from '../../Common/Row';
import Card from 'components/Common/Card/index';
import Colors2 from '../../../theme/Colors2';
import CrossSrc from '../../../images/statusCross.png';
import CheckSrc from '../../../images/statusCheck.png';
import Tooltip from '../../Tooltip';
import CategoryOverrideButton from './CategoryOverrideButton';
import OverrideBanner from './OverrideBanner';
import Icon from '../../../kingpin/atoms/Icon';

const StatusFlag = ({
  isPassed,
  isUnsure,
}: {
  isPassed: boolean;
  isUnsure: boolean;
}) => {
  if (isUnsure) {
    return <Icon icon="minus" color={Colors2.Secondary.warning} />;
  } else if (isPassed) {
    return <img src={CheckSrc} alt="passed" />;
  } else {
    return <img src={CrossSrc} alt="missed" />;
  }
};

const DriverSummarySection = ({
  data,
  category,
  isCategoryPassed,
  isTooltip,
  onInfoTooltipRead,
}: {
  data: DriverScoreExpectation[];
  category: string;
  isCategoryPassed: boolean;
  isTooltip?: boolean;
  onInfoTooltipRead: () => void;
}) => {
  const renderReasonLines = () => {
    return (
      <div>
        {data.map((d) => (
          <Row centerAlign key={`${d.label}-${d.category}`} spaceBetween>
            <Row centerAlign style={{ flex: 2, marginRight: 24 }}>
              <div style={{ marginRight: 8, minWidth: 24 }}>
                <StatusFlag
                  isPassed={d.passes}
                  isUnsure={d.actualValue === null}
                />
              </div>
              <Typography.Body type="Body 12">
                {toSentenceCase(d.label, true)}
              </Typography.Body>
            </Row>
            <Row
              centerAlign
              style={{ flex: 1, justifyContent: 'flex-end', minWidth: 100 }}
            >
              <div style={{ marginRight: 8, display: 'flex' }}>
                {d.expectedValue === undefined && (
                  <Typography.Body type="Body 12">{`${d.actualValue}`}</Typography.Body>
                )}
                {d.expectedValue !== undefined && (
                  <Typography.Body type="Body 12">
                    {`${d.actualValue === null ? '-' : d.actualValue} / ${
                      d.expectedValue
                    }`}
                  </Typography.Body>
                )}
              </div>

              {d.description && !isTooltip && (
                <Tooltip
                  content={d.description}
                  onLongOpenClosed={onInfoTooltipRead}
                >
                  <Icon icon="info" color={Colors2.Grey['2']} />
                </Tooltip>
              )}
            </Row>
          </Row>
        ))}
      </div>
    );
  };

  if (!isTooltip) {
    return (
      <div>
        <Row spaceBetween centerAlign style={{ marginBottom: 8 }}>
          <Row centerAlign>
            <div style={{ marginRight: 8 }}>
              <Typography.Header type="H5">
                {toSentenceCase(category, true)}
              </Typography.Header>
            </div>

            {!!isCategoryPassed && <img src={CheckSrc} alt="passed" />}
            {!isCategoryPassed && <img src={CrossSrc} alt="missed" />}
          </Row>
          <CategoryOverrideButton category={category} />
        </Row>
        <OverrideBanner category={category} />

        <div>
          <Card style={{ marginBottom: 22, padding: '8px 18px' }}>
            {renderReasonLines()}
          </Card>
        </div>
      </div>
    );
  } else {
    return renderReasonLines();
  }
};

export default DriverSummarySection;
