import React, { useContext } from 'react';
import _ from 'lodash';
import DimensionsInput from './DimensionsInput';
import V5GadgetFormContext from '../../../contexts/V5GadgetFormContext';
import useFieldDefinitions from '../../../hooks/useFieldDefinitions';
import useDataTypesFromSeriesAndMetricListItems from '../../../hooks/useDataTypesFromSeriesAndMetricListItems';

const DimensionsInputContainer = () => {
  const {
    dimensionA,
    setDimensionA,
    dimensionB,
    setDimensionB,
    series,
    isMultipleDimensionChart,
  } = useContext(V5GadgetFormContext);
  const usedDataTypes = useDataTypesFromSeriesAndMetricListItems(series);

  const { fieldDefinitions } = useFieldDefinitions(usedDataTypes);
  const availableDimensions = _.uniqBy(fieldDefinitions, (field) => field.field)
    .filter((d) => d.type !== 'date' && d.field !== 'dayOfWeek')
    .map(
      (f) =>
        ({
          field: f.field,
          fieldType: f.type,
          isGroupByDayOfWeek: false,
        }) as Dimension,
    )
    .concat([
      {
        field: 'date',
        fieldType: 'date',
        isGroupByDayOfWeek: false,
      } as Dimension,
    ])
    .concat([
      {
        field: 'dayOfWeek',
        isGroupByDayOfWeek: true,
      } as Dimension,
    ]);

  return (
    <DimensionsInput
      dimensionA={dimensionA}
      setDimensionA={setDimensionA}
      dimensionB={dimensionB}
      setDimensionB={setDimensionB}
      availableDimensions={_.sortBy(availableDimensions, 'field')}
      isMultipleDimensionChart={isMultipleDimensionChart}
    />
  );
};

export default DimensionsInputContainer;
