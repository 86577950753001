import React, { ChangeEvent } from 'react';
import Typography from 'kingpin/atoms/Typography';
import InlineDialog from '../../InlineDialog';
import Search from '../../Search';
import {
  FieldGroup,
  ValueItem,
} from '../../OmniSearch/SearchResults/SearchResults';
import { DropdownButton } from '../Dropdown';
import Card from '../../Common/Card';
import Icon from '../../../kingpin/atoms/Icon';

const AssetPicker = ({
  searchText,
  onSearchTextChanged,
  selectedWorkSpaceIds,
  selectedReportIds,
  selectedDashboardIds,
  setSelectedWorkSpaceIds,
  setSelectedReportIds,
  setSelectedDashboardIds,

  isOpen,
  open,
  close,
  hasResults,
  shouldShowClearSelection,
  clearSelection,
  filteredSelectedReports,
  filteredSelectedDashboards,
  filteredSelectedWorkspaces,
  filteredReports,
  filteredDashboards,
  filteredWorkSpaces,
  selectedCount,
}: {
  searchText: string;
  onSearchTextChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  selectedWorkSpaceIds: string[];
  selectedReportIds: string[];
  selectedDashboardIds: string[];
  setSelectedWorkSpaceIds: (ids: string[]) => void;
  setSelectedReportIds: (ids: string[]) => void;
  setSelectedDashboardIds: (ids: string[]) => void;

  isOpen: boolean;
  open: () => void;
  close: () => void;
  hasResults: boolean;
  shouldShowClearSelection: boolean;
  clearSelection: () => void;
  filteredSelectedReports: PersistedReportType[];
  filteredSelectedDashboards: PersistedDashboardType[];
  filteredSelectedWorkspaces: (WorkSpace | TargetsApp.App)[];
  filteredReports: PersistedReportType[];
  filteredDashboards: PersistedDashboardType[];
  filteredWorkSpaces: (WorkSpace | TargetsApp.App)[];
  selectedCount: number;
}) => (
  <div>
    <InlineDialog
      onClose={close}
      isOpen={isOpen}
      content={
        <Card style={{ marginTop: 4 }}>
          <div style={{ marginBottom: 4 }}>
            <Search
              searchText={searchText}
              onSearchTextChanged={onSearchTextChanged}
            />
          </div>
          <div style={{ maxHeight: 400, overflowY: 'scroll', padding: 8 }}>
            {hasResults && (
              <>
                {shouldShowClearSelection && (
                  <div style={{ marginBottom: 24 }}>
                    <ValueItem onClick={clearSelection}>
                      Clear Selected Items
                    </ValueItem>
                    {/* Selected Items */}
                    {filteredSelectedDashboards.map((dashboard) => (
                      <ValueItem
                        key={`${dashboard.id} - selected`}
                        onClick={() => {
                          if (selectedDashboardIds.includes(dashboard.id)) {
                            setSelectedDashboardIds(
                              selectedDashboardIds.filter(
                                (w) => w !== dashboard.id,
                              ),
                            );
                          } else {
                            setSelectedDashboardIds([
                              ...selectedDashboardIds,
                              dashboard.id,
                            ]);
                          }
                        }}
                      >
                        {dashboard.name}
                        {selectedDashboardIds.includes(dashboard.id) && (
                          <div style={{ marginLeft: 8 }}>
                            <Icon icon="checkmark" />
                          </div>
                        )}
                      </ValueItem>
                    ))}
                    {filteredSelectedReports.map((report) => (
                      <ValueItem
                        key={`${report.id}-selected`}
                        onClick={() => {
                          if (selectedReportIds.includes(report.id)) {
                            setSelectedReportIds(
                              selectedReportIds.filter((w) => w !== report.id),
                            );
                          } else {
                            setSelectedReportIds([
                              ...selectedReportIds,
                              report.id,
                            ]);
                          }
                        }}
                      >
                        {report.name}
                        {selectedReportIds.includes(report.id) && (
                          <div style={{ marginLeft: 8 }}>
                            <Icon icon="checkmark" />
                          </div>
                        )}
                      </ValueItem>
                    ))}
                    {filteredSelectedWorkspaces.map((workSpace) => (
                      <ValueItem
                        key={`${workSpace.id} - selected`}
                        onClick={() => {
                          if (selectedWorkSpaceIds.includes(workSpace.id)) {
                            setSelectedWorkSpaceIds(
                              selectedWorkSpaceIds.filter(
                                (w) => w !== workSpace.id,
                              ),
                            );
                          } else {
                            setSelectedWorkSpaceIds([
                              ...selectedWorkSpaceIds,
                              workSpace.id,
                            ]);
                          }
                        }}
                      >
                        {workSpace.title}
                        {selectedWorkSpaceIds.includes(workSpace.id) && (
                          <div style={{ marginLeft: 8 }}>
                            <Icon icon="checkmark" />
                          </div>
                        )}
                      </ValueItem>
                    ))}
                  </div>
                )}

                {/* Dashboards */}
                {filteredDashboards.length > 0 && (
                  <FieldGroup>
                    <div>
                      <Typography.Body type={'Label'}>
                        Dashboards
                      </Typography.Body>
                    </div>
                    {filteredDashboards.map((dashboard) => (
                      <ValueItem
                        key={dashboard.id}
                        onClick={() => {
                          if (selectedDashboardIds.includes(dashboard.id)) {
                            setSelectedDashboardIds(
                              selectedDashboardIds.filter(
                                (w) => w !== dashboard.id,
                              ),
                            );
                          } else {
                            setSelectedDashboardIds([
                              ...selectedDashboardIds,
                              dashboard.id,
                            ]);
                          }
                        }}
                      >
                        {dashboard.name}
                        {selectedDashboardIds.includes(dashboard.id) && (
                          <div style={{ marginLeft: 8 }}>
                            <Icon icon="checkmark" />
                          </div>
                        )}
                      </ValueItem>
                    ))}
                  </FieldGroup>
                )}

                {/* Reports */}
                {filteredReports.length > 0 && (
                  <FieldGroup>
                    <div>
                      <Typography.Body type={'Label'}>Reports</Typography.Body>
                    </div>
                    {filteredReports.map((report) => (
                      <ValueItem
                        key={report.id}
                        onClick={() => {
                          if (selectedReportIds.includes(report.id)) {
                            setSelectedReportIds(
                              selectedReportIds.filter((w) => w !== report.id),
                            );
                          } else {
                            setSelectedReportIds([
                              ...selectedReportIds,
                              report.id,
                            ]);
                          }
                        }}
                      >
                        {report.name}
                        {selectedReportIds.includes(report.id) && (
                          <div style={{ marginLeft: 8 }}>
                            <Icon icon="checkmark" />
                          </div>
                        )}
                      </ValueItem>
                    ))}
                  </FieldGroup>
                )}

                {/* WorkSpaces */}
                {filteredWorkSpaces.length > 0 && (
                  <FieldGroup>
                    <div>
                      <Typography.Body type={'Label'}>
                        Workspaces
                      </Typography.Body>
                    </div>
                    {filteredWorkSpaces.map((workSpace) => (
                      <ValueItem
                        key={workSpace.id}
                        onClick={() => {
                          if (selectedWorkSpaceIds.includes(workSpace.id)) {
                            setSelectedWorkSpaceIds(
                              selectedWorkSpaceIds.filter(
                                (w) => w !== workSpace.id,
                              ),
                            );
                          } else {
                            setSelectedWorkSpaceIds([
                              ...selectedWorkSpaceIds,
                              workSpace.id,
                            ]);
                          }
                        }}
                      >
                        {workSpace.title}
                        {selectedWorkSpaceIds.includes(workSpace.id) && (
                          <div style={{ marginLeft: 8 }}>
                            <Icon icon="checkmark" />
                          </div>
                        )}
                      </ValueItem>
                    ))}
                  </FieldGroup>
                )}
              </>
            )}
            {!hasResults && (
              <div>
                <Typography.Body type={'Label'}>
                  No results found
                </Typography.Body>
              </div>
            )}
          </div>
        </Card>
      }
    >
      <DropdownButton
        close={close}
        isOpen={isOpen}
        open={open}
        selectedLabel={
          selectedCount === 0 ? 'Selected Assets' : `${selectedCount} items`
        }
      />
    </InlineDialog>
  </div>
);

export default AssetPicker;
