import React, { useCallback, useContext } from 'react';

import PerformanceDatasetMetricsContext from '../contexts/PerformanceDatasetMetricsContext';
import MetricUsageLookupContext from '../contexts/MetricUsageLookupContext';
import DatasetDefinitionsContext from '../contexts/DatasetDefinitionsContext';

const PerformanceDatasetMetricsProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { metricLookup } = useContext(MetricUsageLookupContext);
  const { performanceMetricIds } = useContext(DatasetDefinitionsContext);

  const getIsPerformanceDatasetMetric = useCallback(
    (metricId?: string) => {
      if (metricId === undefined) {
        return false;
      }

      if (performanceMetricIds.includes(metricId)) {
        return true;
      }

      const usageReport = metricLookup[metricId];
      if (!usageReport) {
        return false;
      }

      return usageReport.compoundMetrics.length > 0;
    },
    [metricLookup, performanceMetricIds],
  );

  return (
    <PerformanceDatasetMetricsContext.Provider
      value={{
        getIsPerformanceDatasetMetric,
      }}
    >
      {children}
    </PerformanceDatasetMetricsContext.Provider>
  );
};

export default PerformanceDatasetMetricsProvider;
