import aguid from 'aguid';
import { DateTime } from 'luxon';
import { buildShowGlobalGoal } from '../navigation/appRoutes';

const assignedToScorecard = ({
  editorName,
  goal,
}: {
  editorName: string;
  goal: Scorecards.Scorecard;
}): Notifications.BasicNotification => ({
  id: aguid(),
  isBasic: true,
  type: 'ASSIGNED_TO_SCORECARD',
  seen: false,
  read: false,
  deleted: false,
  createdOn: DateTime.utc().toISO(),
  emailSent: false,
  link: buildShowGlobalGoal(goal.id),
  inAppSubject: `${editorName} has assigned you to a Scorecard`,
  inAppBody: `${goal.title}`,
  inAppLinkText: 'View KPI List',
  emailSubject: `${editorName} has assigned you to a Scorecard on FleetOps`,
  emailBodyLn1: `${editorName} has assigned you to the Scorecard - ${goal.title}`,
  emailBodyLn2: '',
  emailLinkText: 'Log in to view KPI List Details',
});

export default assignedToScorecard;
