import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Typography from 'kingpin/atoms/Typography';
import Row from 'components/Common/Row';
import getIdentifier from 'getIdentifier';
import Colors2 from 'theme/Colors2';

const TextRow = styled(Row)`
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const RenderLetter = ({
  character,
  isHighlighted,
  isLink,
}: {
  character: string;
  isHighlighted: boolean;
  isLink: boolean;
}) => {
  if (isLink) {
    return (
      <div
        style={
          isHighlighted
            ? {
                backgroundColor: Colors2.AvatarColors['1'].background,
              }
            : undefined
        }
        data-testid={`${character}-highlighted:${isHighlighted}`}
      >
        <Typography.Body type="Link">{character}</Typography.Body>
      </div>
    );
  } else {
    return (
      <div
        style={
          isHighlighted
            ? {
                backgroundColor: Colors2.AvatarColors['1'].background,
              }
            : undefined
        }
      >
        <Typography.Body type="Body 12">{character}</Typography.Body>
      </div>
    );
  }
};

const HighlightedText = ({
  text,
  searchText,
  isLink,
}: {
  text: string;
  searchText: string;
  isLink: boolean;
}) => {
  const buildSections = useCallback((): {
    key: string;
    character: string;

    isHighlighted: boolean;
  }[] => {
    const re = new RegExp(searchText, 'gi');
    const matches = text.matchAll(re);
    const matchBounds: { start: number; end: number }[] = [];

    for (const match of matches) {
      const index = match.index;
      if (index !== undefined) {
        matchBounds.push({ start: index, end: index + searchText.length });
      }
    }

    const characters = text.split('');
    return characters.map((character, index) => {
      const isHighlighted = matchBounds.some(
        (b) => b.start <= index && b.end > index,
      );
      return {
        key: getIdentifier(undefined, true),
        isHighlighted,
        character,
      };
    });
  }, [searchText, text]);

  const [sections, setSections] = useState<
    {
      key: string;
      character: string;
      isHighlighted: boolean;
    }[]
  >(buildSections);
  useEffect(() => {
    setSections(buildSections());
  }, [buildSections]);

  return (
    <TextRow data-testid={`highlighted-text-${text}`}>
      {sections.map(({ key, character, isHighlighted }) => (
        <RenderLetter
          key={key}
          character={character}
          isHighlighted={isHighlighted}
          isLink={isLink}
        />
      ))}
    </TextRow>
  );
};

export default HighlightedText;
