import { isManualKpiInfo } from 'hooks/kpiTypeCheckers';

const getMetricInfoLabel = (
  metricInfo: MetricsInfoButton.MetricInfo | MetricsInfoButton.ManualKpiInfo,
) => {
  if (isManualKpiInfo(metricInfo)) {
    return metricInfo.label;
  } else {
    return metricInfo.metricAlias
      ? metricInfo.metricAlias
      : metricInfo.metric.name;
  }
};

export default getMetricInfoLabel;
