import firebase from 'firebase/compat/app';
import withoutNulls from '../api/search/withoutNulls';

const accountConverter = {
  toFirestore(account: FleetOps.Account): firebase.firestore.DocumentData {
    return withoutNulls(account);
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot<FleetOps.Account>,
    options: firebase.firestore.SnapshotOptions,
  ): FleetOps.Account {
    const account = snapshot.data(options);
    return account;
  },
};

const getAccountRef = ({
  accountId,
  db,
}: {
  accountId: string;
  db?: firebase.firestore.Firestore;
}) => {
  return (db ? db : firebase.firestore())
    .collection('accounts')
    .doc(accountId)
    .withConverter(accountConverter);
};

export default getAccountRef;
