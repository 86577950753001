import Typography from 'kingpin/atoms/Typography';
import React from 'react';

const Label = ({ children }: { children: string }) => (
  <div style={{ marginBottom: 4 }}>
    <Typography.Body type="Label">{children}</Typography.Body>
  </div>
);

export default Label;
