import React, { useContext, useEffect, useRef, useState } from 'react';
import moment from 'moment';

import AccountContext from '../../../contexts/AccountContext';
import FlexCentered from '../../../components/Common/FlexCentered';
import Loading from '../../../components/Loading/Loading';
import formatFloat from '../../../api/getChartSeries/formatFloat';
import { getKpiTargetForMonth } from '../../../components/Goals/GeneralGoalForm/KpiForm/AdvancedTargetInput/MonthlyTargetBuilder';
import chartColors from '../../../theme/chartColors';
import timeSeries from '../../../components/V5Gadget/highchartOptions/timeSeriesOptions';
import formatDateLabel from '../../../components/V5Gadget/formatDateLabel';
import OnTargetBadge from '../OnTargetBadge';
import HighchartStyles from '../../../components/HighchartStyles';
import Chart from '../../../components/Chart';
import GoalContext from '../../../contexts/GoalContext';
import useValueFormatters from '../../../hooks/useValueFormatters';
import WallboardContext from 'contexts/WallboardContext';

const GoalTrendChart = ({
  goal,
  isCompact,
  metric,
  trend,
  target,
  advancedTarget,
  targetMode,
  isCumulative,
  cadence,
}: {
  goal: GeneralGoal;
  isCompact?: boolean;
  metric?: Metrics.Metric;
  trend?: Goals.TrendResult[];
  cadence: FleetOps.Interval;
  target: number;
  advancedTarget: GoalAdvancedTarget;
  targetMode?: 'basic' | 'advanced';
  isCumulative: boolean;
}) => {
  const { unitsLocale } = useContext(AccountContext);
  const [options, setOptions] = useState<any | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { dateBuckets } = useContext(GoalContext);
  const { formatMetric } = useValueFormatters();
  const { isWallboard } = useContext(WallboardContext);

  const chartRef = useRef<any>();

  useEffect(() => {
    if (!trend || !metric) {
      return;
    }

    const trendLine = dateBuckets.map((date, index) => {
      const t = target === undefined ? 0 : target;
      if (!trend) {
        return 0;
      }
      const { formatting } = metric;

      if (targetMode === 'basic') {
        if (isCumulative) {
          const perBucket = t / dateBuckets.length;
          return formatFloat(perBucket * (index + 1), formatting.precision);
        } else {
          return formatFloat(target, formatting.precision);
        }
      } else {
        if (isCumulative) {
          let total = 0;
          for (let i = 0; i <= index; i++) {
            const d = dateBuckets[i];
            total += getKpiTargetForMonth(advancedTarget, d);
          }
          return formatFloat(total, formatting.precision);
        } else {
          return formatFloat(
            getKpiTargetForMonth(advancedTarget, date),
            formatting.precision,
          );
        }
      }
    });

    const marker = {
      enabled: false,
      symbol: 'circle',
      fillColor: 'white',
      lineWidth: 1,
      lineColor: chartColors[2],
      radius: 3,
    };

    const { formatting } = metric;

    const newOptions = {
      ...timeSeries({
        showLegend: true,
        title: metric.name,
        openMenu: undefined,
        chartDef: undefined,
        fontSize: 11,
        formatMetric,
      }),
      title: {
        enabled: false,
        text: '',
      },
      chart: {
        type: 'column',
        styledMode: false,
      },
      xAxis: {
        type: 'datetime',
        categories: dateBuckets.map((d) =>
          cadence === 'month'
            ? moment(d).format("MMM 'YY")
            : cadence === 'week'
              ? formatDateLabel(d, cadence, true)
              : moment(d).format('YYYY-MM-DD'),
        ),
        labels: {
          style: {
            fontSize: isWallboard ? '0.8em' : undefined,
          },
          step:
            dateBuckets.length > 12 ? Math.floor(dateBuckets.length / 6) : 1,
        },
      },
      series: [
        {
          prefix: formatting.prefix,
          postfix: formatting.postfix,
          precision: formatting.precision,
          data: trend.map((t) => formatFloat(t.value, formatting.precision)),
          id: metric.id,
          metricId: metric.id,
          colorIndex: 2,
          marker,
          name: `${metric.name} (Actual)`,
        },
        {
          prefix: formatting.prefix,
          postfix: formatting.postfix,
          precision: formatting.precision,
          name: `${metric.name} (Target)`,
          metricId: metric.id,
          data: trendLine,
          type: 'line',
          dashStyle: 'dash', // css is making this transparent
          linkedTo: metric.id,
          marker: {
            symbol: 'c-rect',
            lineWidth: 2,
            fillColor: '#352d43',
            lineColor: '#352d43',
            radius: 5,
          },
        },
      ],
      legend: {
        enabled: false,
      },
    };

    setIsLoading(true);
    setOptions(newOptions);
  }, [
    advancedTarget,
    cadence,
    dateBuckets,
    formatMetric,
    isCumulative,
    isWallboard,
    metric,
    target,
    targetMode,
    trend,
    unitsLocale,
  ]);

  useEffect(() => {
    if (options) {
      setIsLoading(false);
    }
  }, [options]);

  if (!trend || !metric || !options || isLoading) {
    return (
      <FlexCentered style={{ height: '100%' }}>
        <Loading />
      </FlexCentered>
    );
  }

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <OnTargetBadge goal={goal} isCompact={isCompact} />
      {!isCompact && (
        <HighchartStyles
          style={{ width: '100%', height: 'calc(100% - 40px)' }}
          isGoalTrendLine
        >
          <Chart options={options} chartRef={chartRef} />
        </HighchartStyles>
      )}
    </div>
  );
};

export default GoalTrendChart;
