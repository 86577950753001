const removeFutureValues = (data: [number, number][]) => {
  const lastNonFutureIndex = (() => {
    const now = new Date().getTime();
    let result = 0;
    data.forEach(([timestamp], index) => {
      if (timestamp <= now) {
        result = index + 1;
      }
    });
    return result;
  })();

  return data.slice(0, lastNonFutureIndex);
};

export default removeFutureValues;
