import getChartDefsRef from './getChartDefsRef';
import getMetricListsRef from './getMetricListsRef';
import getSingleMetricDateMatricesRef from './getSingleMetricDateMatricesRef';
import getPaceMatricesRef from './getPaceMatricesRef';
import getExcludedPaceDatesRef from './getExcludedPaceDatesRef';
import getRankingMatricesRef from './getRankingMatricesRef';
import getGaugesRef from './getGaugesRef';
import getScorecardsRef from './getScorecardsRef';
import getGoalsRef from './getGoalsRef';
import getRemindersRef from './getRemindersRef';
import getDashboardGadgetsRef from './getDashboardGadgetsRef';

const visualisationDefinitionsStore = {
  getChartDefsRef,
  getMetricListsRef,
  getSingleMetricDateMatricesRef,
  getPaceMatricesRef,
  getRankingMatricesRef,
  getRemindersRef,
  getGaugesRef,
  getScorecardsRef,
  getGoalsRef,
  getDashboardGadgetsRef,
  getExcludedPaceDatesRef,
};

export default visualisationDefinitionsStore;
