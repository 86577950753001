import React from 'react';
import Colors from '../../../theme/colors';
import styled from 'styled-components';
import toSentenceCase from '../../../services/toSentenceCase';
import UserIcon from '../../Common/UserIcon';
import Row from '../../Common/Row';
import ContentEditable from 'react-contenteditable';
import Typography from 'kingpin/atoms/Typography';
import Loading from '../../Loading/Loading';

const Content = styled.div`
  max-width: 40em;
  color: ${Colors.GUN_METAL} !important;
`;

const CommentDiv = styled.div`
  padding: 32px 0px;
  border-bottom: 1px solid #e9e9e9;
  &:last-child {
    border-bottom: unset;
  }
`;

const CommentTopRow = styled(Row)`
  margin-bottom: 8px;

  div {
    margin-right: 4px;
  }
`;

const Comment = ({
  text,
  date,
  user,
  attachedFiles,
}: {
  text: string;
  date: string;
  user:
    | UserManagement.FleetOpsStaffUser
    | UserManagement.SignedUpUser
    | UserManagement.PendingUser;
  attachedFiles: {
    fileName: string;
    isLoading: boolean;
    downloadUrl?: string;
  }[];
}) => (
  <CommentDiv>
    <CommentTopRow centerAlign>
      <UserIcon {...user} />
      <div>
        <Typography.Body type="Body 12">
          {toSentenceCase(user.displayName)}
        </Typography.Body>
      </div>
      <div>
        <Typography.Body type="Body 12">{date}</Typography.Body>
      </div>
    </CommentTopRow>
    <Content>
      <ContentEditable
        html={text}
        disabled
        onChange={window.tokenFunction}
        style={{ fontSize: 12 }}
      />
    </Content>
    {attachedFiles.length > 0 && (
      <div style={{ marginTop: 12 }}>
        <div style={{ marginBottom: 8 }}>
          <Typography.Body type="Label">Attached files</Typography.Body>
        </div>

        {attachedFiles.map((file) => (
          <Row key={file.fileName} centerAlign>
            {file.isLoading && (
              <div style={{ width: 30 }}>
                <Loading isTiny />
              </div>
            )}
            {file.downloadUrl && (
              <a
                data-testid={`download-link-${file.fileName}`}
                href={file.downloadUrl}
                download
                target="_blank"
                rel="noreferrer"
              >
                <Typography.Body type="Link">{file.fileName}</Typography.Body>
              </a>
            )}
            {!file.downloadUrl && (
              <Typography.Body type="Body 12">{file.fileName}</Typography.Body>
            )}
          </Row>
        ))}
      </div>
    )}
  </CommentDiv>
);

export default Comment;
