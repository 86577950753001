import { useCallback, useEffect, useState } from 'react';
import useDatasetsSearchControls from './useDatasetsSearchControls';
import useNormalDatasetsList from './useNormalDatasetsList';
import usePerformanceDatasetsList from './usePerformanceDatasetsList';

const useDatasetList = () => {
  const { searchText, onSearchTextChanged, sortBy, sortByOptions } =
    useDatasetsSearchControls();
  const { normalDatasetList, isLoading: isLoadingNormal } =
    useNormalDatasetsList(searchText);
  const { performanceDatasetList, isLoading: isLoadingPerf } =
    usePerformanceDatasetsList(searchText);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [datasetList, setDatasetList] = useState<DataManager.DatasetListItem[]>(
    [],
  );
  const getDatasetList = useCallback(() => {
    const baseList = [...normalDatasetList, ...performanceDatasetList];

    if (sortBy === 'Alphabetical') {
      const sortedAndFiltered = baseList.sort((a, b) => {
        const aName = a.name.toLowerCase();
        const bName = b.name.toLowerCase();

        if (aName > bName) {
          return 1;
        } else if (aName === bName) {
          return 0;
        } else {
          return -1;
        }
      });
      return sortedAndFiltered.map((i) => i.item);
    } else if (sortBy === 'Last Updated') {
      const sortedAndFiltered = baseList.sort((a, b) => {
        const aLastUpdated = a.lastUpdated;
        const bLastUpdated = b.lastUpdated;

        if (aLastUpdated === undefined || bLastUpdated === undefined) {
          if (aLastUpdated === undefined && bLastUpdated === undefined) {
            return 0;
          } else if (aLastUpdated === undefined && !!bLastUpdated) {
            return 1;
          } else {
            return -1;
          }
        } else {
          if (aLastUpdated > bLastUpdated) {
            return 1;
          } else if (aLastUpdated === bLastUpdated) {
            return 0;
          } else {
            return -1;
          }
        }
      });

      return sortedAndFiltered.map((i) => i.item);
    } else {
      return baseList.map((i) => i.item);
    }
  }, [normalDatasetList, performanceDatasetList, sortBy]);

  useEffect(() => {
    if (isLoadingNormal || isLoadingPerf) {
      setIsLoading(true);
      return;
    }

    setDatasetList(getDatasetList());
    setIsLoading(false);
  }, [getDatasetList, isLoadingNormal, isLoadingPerf]);

  return {
    datasetList,
    searchText,
    onSearchTextChanged,
    sortByOptions,
    isLoading,
  };
};

export default useDatasetList;
