const isNormalMetric = (
  m: Metrics.Metric | ExtendedVisualisationDefinition | undefined,
): m is Metrics.NormalMetric => {
  if (!m) {
    return false;
  }

  return 'type' in m ? m.type === 'normal' : false;
};

export default isNormalMetric;
