import React from 'react';
import captureException from '../services/captureException';

interface EntityDefinitionsContextType {
  entityDefinitions: EntityDetails.Entity[];
  getEntityDefinitionFor: (
    field: string,
  ) => { entity: EntityDetails.Entity; app: EntityDetails.App } | undefined;
  entityDetailsApps: EntityDetails.App[];
  // localUpdateOrAddApp -> prevent race conditions
  localUpdateOrAddApp: (app: EntityDetails.App) => void;
  isLoading: boolean;
}

const EntityDefinitionsContext =
  React.createContext<EntityDefinitionsContextType>({
    entityDefinitions: [],
    getEntityDefinitionFor: () => {
      const e = new Error();
      e.name = 'getEntityDefinitionFor is not defined';
      captureException(e);

      return undefined;
    },
    localUpdateOrAddApp: () => {
      const e = new Error();
      e.name = 'localUpdateOrAddApp is not defined';
      captureException(e);

      return undefined;
    },
    entityDetailsApps: [],
    isLoading: true,
  });

export default EntityDefinitionsContext;
