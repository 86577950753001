import useGetFieldLabel from '../../../../../hooks/useGetFieldLabel';
import { useCallback, useContext } from 'react';
import BoardContext from '../../../../../contexts/BoardContext';

const useGetFieldDisplayName = () => {
  const { board } = useContext(BoardContext);
  const { getFieldLabel } = useGetFieldLabel();

  const getFieldDisplayName = useCallback(
    (column: PerformanceBoardTypes.Column) => {
      if (column.type === 'progress') {
        return `${getFieldLabel({
          field: column.field,
          dataType: board.dataType,
        })} (Progress Field)`;
      } else if (column.type === 'status') {
        return `${getFieldLabel({
          field: column.field,
          dataType: board.dataType,
        })} (Status)`;
      } else {
        return getFieldLabel({
          field: column.field,
          dataType: board.dataType,
        });
      }
    },
    [board.dataType, getFieldLabel],
  );

  return getFieldDisplayName;
};

export default useGetFieldDisplayName;
