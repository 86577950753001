import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

const buildQuery = ({
  filters,
  dateScope,
}: {
  filters: FilterInput[];
  dateScope: DateRangeInput;
}) => ({
  query: gql`
    query GetTotalLoads(
      $filters: [FilterInput]!
      $dateScope: [DateRangeInput!]!
    ) {
      getTotalLoads(filters: $filters, dateScope: $dateScope) {
        total
        earliest
      }
    }
  `,
  variables: {
    filters,
    dateScope,
  },
});

const getTotalLoads = async ({
  filters,
  dateScope,
  client,
}: {
  filters: FilterInput[];
  dateScope: DateRangeInput;
  client: ApolloClient<NormalizedCacheObject>;
}) => {
  const query = buildQuery({ filters, dateScope });
  const response = await client.query(query);
  return response.data.getTotalLoads;
};

export default getTotalLoads;
