import React, { useCallback, useContext, useEffect, useState } from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';

import MetricPickerPopup from './MetricPickerPopup';
import useFilteredMetrics from './hooks/useFilteredMetrics';
import useNavigationState from './hooks/useNavigationState';
import useConstraints from './hooks/useConstraints';
import usePopup from '../../../../hooks/usePopup';
import SingleUseMetricBuilderPopup from './SingleUseMetricBuilderPopup';
import AnalyticsContext from '../../../../contexts/AnalyticsContext';
import isSpecialMetric from '../../../../types/metricTypeCheckers/isSpecialMetric';
import useModalWidth from '../../../../hooks/useModalWidth';

const MetricPickerPopupContainer = ({
  constraints,
  selectedMetric,
  setSelectedMetric,
  close,
  openSingleUseBuilder,
  isShowAllDatasetsButtonDisabled,
}: {
  constraints: MetricPickerOptionConstraints;
  selectedMetric?: Metrics.Metric;
  setSelectedMetric: (m: Metrics.Metric) => void;
  close: () => void;
  openSingleUseBuilder: () => void;
  isShowAllDatasetsButtonDisabled?: boolean;
}) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const {
    datasetOptions,
    filteredCoreMetrics,
    filteredOtherMetrics,
    searchText,
    onSearchTextChanged,
    otherDatasetOptions,
    isShowAllEnabled,
    onShowAllDatasetsClicked,
  } = useFilteredMetrics(constraints);
  const navigationState = useNavigationState({
    filteredCoreMetrics,
    filteredOtherMetrics,
  });
  const [currentSelectedMetric, setCurrentSelectedMetric] = useState<
    Metrics.Metric | undefined
  >(selectedMetric);
  const onMetricClicked = useCallback((metric: Metrics.Metric) => {
    setCurrentSelectedMetric(metric);
  }, []);
  const onMetricConfirmed = useCallback(() => {
    if (currentSelectedMetric) {
      trackEvent('Metric Picker - Metric Selected', {
        isCore: isSpecialMetric(currentSelectedMetric)
          ? 'false'
          : currentSelectedMetric.status === 'core'
            ? 'true'
            : 'false',
        type: currentSelectedMetric.type,
      });
      setSelectedMetric(currentSelectedMetric);
      close();
    }
  }, [close, currentSelectedMetric, setSelectedMetric, trackEvent]);
  const onCreateSingleUseMetricClicked = useCallback(() => {
    trackEvent('Metric Picker - Clicked Create Single-use metric');
    openSingleUseBuilder();
  }, [openSingleUseBuilder, trackEvent]);

  return (
    <MetricPickerPopup
      datasetOptions={datasetOptions}
      otherDatasetOptions={otherDatasetOptions}
      isShowAllEnabled={isShowAllEnabled}
      onShowAllDatasetsClicked={onShowAllDatasetsClicked}
      navigationState={navigationState}
      filteredMetrics={
        navigationState.currentMode === 'core'
          ? filteredCoreMetrics
          : filteredOtherMetrics
      }
      searchText={searchText}
      onSearchTextChanged={onSearchTextChanged}
      onMetricClicked={onMetricClicked}
      onCreateSingleUseMetricClicked={onCreateSingleUseMetricClicked}
      currentSelectedMetric={currentSelectedMetric}
      onMetricConfirmed={onMetricConfirmed}
      isShowAllDatasetsButtonDisabled={isShowAllDatasetsButtonDisabled}
      close={close}
    />
  );
};

const Gate = ({
  isOpen,
  close,
  selectedMetric,
  setSelectedMetric,
  noCompound,
  noSpecial,
  datasetMustIncludeField,
  metricIdsToConstrainBy,
  metricIdRedList,
  aggFuncRedList,
  aggFuncGreenList,
  datasetRedList,
  fieldRedList,
  isShowAllDatasetsButtonDisabled,
}: {
  isOpen: boolean;
  close: () => void;
  selectedMetric?: Metrics.Metric;
  setSelectedMetric: (m: Metrics.Metric) => void;
  noCompound?: boolean;
  noSpecial?: boolean;
  datasetMustIncludeField?: string;
  metricIdsToConstrainBy?: string[];
  metricIdRedList?: string[];
  datasetRedList?: string[];
  aggFuncRedList?: AggFunc[];
  aggFuncGreenList?: AggFunc[];
  fieldRedList?: string[];
  isShowAllDatasetsButtonDisabled?: boolean;
}) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const constraints = useConstraints({
    noCompound,
    noSpecial,
    datasetMustIncludeField,
    metricIdsToConstrainBy,
    hideDatasetsWithNoMetrics: true,
    metricIdRedList,
    aggFuncRedList,
    aggFuncGreenList,
    datasetRedList,
    fieldRedList,
  });
  const {
    isOpen: isSingleUseBuilderOpen,
    open: openSingleUseBuilder,
    close: closeSingleUseBuilder,
  } = usePopup();

  useEffect(() => {
    if (isOpen) {
      trackEvent('Metric Picker - Opened');
    }
  }, [isOpen, trackEvent]);

  const onSingleUseBuilderClosed = useCallback(() => {
    trackEvent('Single use metric - Back to metric picker clicked');
    closeSingleUseBuilder();
  }, [closeSingleUseBuilder, trackEvent]);
  const onSingleUseMetricSaved = useCallback(
    (metric: Metrics.Metric) => {
      setSelectedMetric(metric);
      closeSingleUseBuilder();
      close();
    },
    [close, closeSingleUseBuilder, setSelectedMetric],
  );
  const modalWidth = useModalWidth();

  return (
    <>
      <ModalTransition>
        {isOpen && (
          <Modal
            onClose={close}
            shouldScrollInViewport={false}
            autoFocus={false}
            width={modalWidth}
          >
            <MetricPickerPopupContainer
              selectedMetric={selectedMetric}
              setSelectedMetric={setSelectedMetric}
              constraints={constraints}
              openSingleUseBuilder={openSingleUseBuilder}
              close={close}
              isShowAllDatasetsButtonDisabled={isShowAllDatasetsButtonDisabled}
            />
          </Modal>
        )}
      </ModalTransition>
      <SingleUseMetricBuilderPopup
        close={onSingleUseBuilderClosed}
        onSingleUseMetricSaved={onSingleUseMetricSaved}
        isOpen={isSingleUseBuilderOpen}
        cancelButtonText="Back to Metric Picker"
      />
    </>
  );
};

export default Gate;
