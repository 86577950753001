import useGetDatasetDefinition from '../../../hooks/useGetDatasetDefinition';
import { useEffect, useState } from 'react';

const useDatasetDef = (dataset: string | undefined) => {
  const getDatasetDefinition = useGetDatasetDefinition();
  const [datasetDef, setDatasetDef] = useState<FleetOps.DatasetDefinition>();
  useEffect(() => {
    if (!dataset) {
      setDatasetDef(undefined);
      return;
    }

    setDatasetDef(getDatasetDefinition(dataset));
  }, [dataset, getDatasetDefinition]);

  return datasetDef;
};

export default useDatasetDef;
