import styled from 'styled-components';
import Typography from 'kingpin/atoms/Typography';
import Row from '../Common/Row';
import React from 'react';
import Button from 'kingpin/atoms/Button';
import Form from '../../kingpin/forms/Form';
import FormHeader from '../../kingpin/forms/FormHeader';
import FormContent from '../../kingpin/forms/FormContent';
import Icon from '../../kingpin/atoms/Icon';
import Colors2 from '../../theme/Colors2';

const IconDiv = styled.div`
  margin-right: 10px;
`;

const SuccessFeedback = ({ close }: { close: () => void }) => (
  <Form>
    <FormHeader title="Invite Successful" onClose={close} />
    <FormContent>
      <Row
        style={{ marginBottom: 64, marginTop: 64, justifyContent: 'center' }}
        centerAlign
      >
        <IconDiv>
          <Icon icon="circleCheckmark" color={Colors2.Secondary.success} />
        </IconDiv>
        <Typography.Header type="H5">Invites Sent</Typography.Header>
      </Row>
    </FormContent>

    <Row style={{ justifyContent: 'flex-end' }}>
      <Button type="Primary" size="Small" onClick={close} label="Okay" />
    </Row>
  </Form>
);

export default SuccessFeedback;
