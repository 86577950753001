import React, { useCallback, useContext, useEffect, useState } from 'react';
import CommitmentQueryContext from '../../../../contexts/CommitmentQueryContext';
import useCommitments from '../../../../hooks/useCommitments';
import { AgGridReact } from 'ag-grid-react';
import Typography from 'kingpin/atoms/Typography';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { defaultFormatter } from '../../../Grid/findCellValueFormatter';
import AccountContext from '../../../../contexts/AccountContext';
import StyleOverrides from '../../../Grid/StyleOverrides';
import buildGridOptions from '../../../Grid/buildGridOptions';
import styled from 'styled-components';
import NewCommitmentSlideOut from '../NewCommitmentSlideOut';
import { DATE_CELL, NUMBER_CELL } from '../../../Grid/constants';
import GridFrameworkComponents from '../../../Grid/GridFrameworkComponents';
import { CUSTOMER_LANE_COMMITMENTS, Z_INDEX } from '../../../../constants';
import { RowHeightContext } from '../../../../contextProviders/RowHeightProvider';

const GridWrapper = styled.div`
  flex-direction: row;
  display: flex;
  flex: 1;
  position: relative;
  width: 100%;
  height: 500px;
`;

const buildColDef = ({
  field,
  cellRenderer,
  cellRendererParams,
}: {
  field: string;
  cellRenderer?: string;
  cellRendererParams?: any;
}) => ({
  headerName: field,
  valueFormatter: defaultFormatter,
  field,
  isLeftAlign: true,
  suppressHeaderMenuButton: true,
  cellRenderer,
  wrapHeaderText: true,
  autoHeaderHeight: true,
  cellRendererParams,
});

const buildColDefs = (unitsLocale: string, commitments: Commitment[]) => {
  const usedFields = [] as string[];
  if (commitments.some((c) => c.commodity)) {
    usedFields.push('commodity');
  }
  if (commitments.some((c) => c.customer)) {
    usedFields.push('customer');
  }
  if (commitments.some((c) => c.billTo)) {
    usedFields.push('billTo');
  }
  if (commitments.some((c) => c.cadence)) {
    usedFields.push('cadence');
  }
  if (commitments.some((c) => c.endDate)) {
    usedFields.push('endDate');
  }
  if (commitments.some((c) => c.lineHaulCharge)) {
    usedFields.push('lineHaulCharge');
  }
  if (commitments.some((c) => c.loads)) {
    usedFields.push('loads');
  }
  if (commitments.some((c) => c.mileage)) {
    usedFields.push('mileage');
  }
  if (commitments.some((c) => c.rate)) {
    usedFields.push('rate');
  }
  if (commitments.some((c) => c.startDate)) {
    usedFields.push('startDate');
  }
  if (commitments.some((c) => c.lane.destinationArea)) {
    usedFields.push('destinationArea');
  }
  if (commitments.some((c) => c.lane.destinationRegion)) {
    usedFields.push('destinationRegion');
  }
  if (commitments.some((c) => c.lane.destinationState)) {
    usedFields.push('destinationState');
  }
  if (commitments.some((c) => c.lane.destinationZipCode)) {
    usedFields.push('destinationZipCode');
  }
  if (commitments.some((c) => c.lane.finishCityAndState)) {
    usedFields.push('finishCityAndState');
  }
  if (commitments.some((c) => c.lane.originRegion)) {
    usedFields.push('originRegion');
  }
  if (commitments.some((c) => c.lane.originState)) {
    usedFields.push('originState');
  }
  if (commitments.some((c) => c.lane.originZipCode)) {
    usedFields.push('originZipCode');
  }
  if (commitments.some((c) => c.lane.pickUpArea)) {
    usedFields.push('pickUpArea');
  }
  if (commitments.some((c) => c.lane.startCityAndState)) {
    usedFields.push('startCityAndState');
  }

  return [
    buildColDef({
      field: 'customer',
      cellRenderer: 'customerWithHoverMenuCell',
    }),
    buildColDef({
      field: 'billTo',
      cellRenderer: 'customerWithHoverMenuCell',
    }),

    buildColDef({
      field: 'startDate',
      cellRenderer: DATE_CELL,
      cellRendererParams: {
        fieldView: {
          dateFormat: 'MMM/DD/YY',
        },
      },
    }),
    buildColDef({
      field: 'endDate',
      cellRenderer: DATE_CELL,
      cellRendererParams: {
        fieldView: {
          dateFormat: 'MMM/DD/YY',
        },
      },
    }),

    buildColDef({ field: 'startCityAndState' }),
    buildColDef({ field: 'originState' }),
    buildColDef({ field: 'pickUpArea' }),
    buildColDef({ field: 'originRegion' }),
    buildColDef({ field: 'originZipCode' }),

    buildColDef({ field: 'finishCityAndState' }),
    buildColDef({ field: 'destinationState' }),
    buildColDef({ field: 'destinationArea' }),
    buildColDef({ field: 'destinationRegion' }),
    buildColDef({ field: 'destinationZipCode' }),

    buildColDef({ field: 'commodity' }),

    buildColDef({
      field: 'loads',
      cellRenderer: NUMBER_CELL,
      cellRendererParams: {
        fieldView: {
          precision: 2,
        },
      },
    }),
    buildColDef({ field: 'cadence' }),
    buildColDef({
      field: 'mileage',
      cellRenderer: NUMBER_CELL,
      cellRendererParams: {
        fieldView: {
          precision: 2,
        },
      },
    }),
    buildColDef({
      field: 'rate',
      cellRenderer: NUMBER_CELL,
      cellRendererParams: {
        fieldView: {
          precision: 2,
        },
      },
    }),
    buildColDef({
      field: 'lineHaulCharge',
      cellRenderer: NUMBER_CELL,
      cellRendererParams: {
        fieldView: {
          precision: 2,
        },
      },
    }),
    buildColDef({
      field: 'targetCadence',
      cellRenderer: NUMBER_CELL,
      cellRendererParams: {
        fieldView: {
          precision: 2,
        },
      },
    }),
    buildColDef({
      field: 'targetLoads',
      cellRenderer: NUMBER_CELL,
      cellRendererParams: {
        fieldView: {
          precision: 2,
        },
      },
    }),
  ].filter((def) => usedFields.some((f) => def.field === f));
};

const toRowItem = (commitment: Commitment): ElasticDocument => {
  const item = {
    ...commitment,
  } as any;

  item.startCityAndState = item.lane.startCityAndState;
  item.originState = item.lane.originState;
  item.pickUpArea = item.lane.pickUpArea;
  item.originRegion = item.lane.originRegion;
  item.originZipCode = item.lane.originZipCode;

  item.finishCityAndState = item.lane.finishCityAndState;
  item.destinationState = item.lane.destinationState;
  item.destinationArea = item.lane.destinationArea;
  item.destinationRegion = item.lane.destinationRegion;
  item.destinationZipCode = item.lane.destinationZipCode;
  item.configId = item.id;

  delete item['lane'];
  return item;
};

const useFilteredCommitments = () => {
  const { unitsLocale } = useContext(AccountContext);

  const {
    customerField,
    customerValue,
    originField,
    originValue,
    destinationField,
    destinationValue,
    commodity,
  } = useContext(CommitmentQueryContext);
  const commitments = useCommitments(CUSTOMER_LANE_COMMITMENTS);
  const [colDefs, setColDefs] = useState<any>(undefined);

  useEffect(() => {
    if (commitments.length > 0) {
      setColDefs(buildColDefs(unitsLocale, commitments));
    }
  }, [commitments, unitsLocale]);

  const hasFilterInputs = !!(
    customerValue ||
    originValue ||
    destinationValue ||
    commodity
  );

  const filterCustomer = (commitment: Commitment): boolean => {
    if (customerValue && customerField) {
      if (customerField === 'billTo') {
        return commitment.billTo === customerValue;
      } else if (customerField === 'customer') {
        return commitment.customer === customerValue;
      }
    }
    return true;
  };

  const filterOrigin = (commitment: Commitment): boolean => {
    if (originValue && originField) {
      return (
        !!commitment.lane[originField] &&
        commitment.lane[originField] === originValue
      );
    }
    return true;
  };

  const filterDestination = (commitment: Commitment): boolean => {
    if (destinationValue && destinationField) {
      return (
        !!commitment.lane[destinationField] &&
        commitment.lane[destinationField] === destinationValue
      );
    }
    return true;
  };

  const filterCommodity = (commitment: Commitment): boolean => {
    if (commodity) {
      return commitment.commodity === commodity;
    }
    return true;
  };

  const filteredCommitments = commitments
    .filter(filterCustomer)
    .filter(filterOrigin)
    .filter(filterDestination)
    .filter(filterCommodity);

  return {
    filteredCommitments: filteredCommitments.map(toRowItem),
    hasFilterInputs,
    colDefs,
  };
};

const QueryLaneCommitConfigurations = () => {
  const { rowHeight } = useContext(RowHeightContext);
  const { filteredCommitments, colDefs } = useFilteredCommitments();
  const [gridApi, setGridApi] = useState<GridApi | undefined>();
  const { getContextMenuItems, isSlideOutOpen } = useContext(
    CommitmentQueryContext,
  );

  const onGridReady = useCallback((event: GridReadyEvent) => {
    setGridApi(event.api);
  }, []);

  useEffect(() => {
    if (gridApi) {
      gridApi.setRowData(filteredCommitments);
    }
  }, [filteredCommitments, gridApi]);

  useEffect(() => {
    if (gridApi && colDefs) {
      gridApi.setColumnDefs(colDefs);
      gridApi.sizeColumnsToFit();
    }
  }, [colDefs, gridApi]);

  return (
    <div>
      <Typography.Header type="H4">{`Matching commitments (${filteredCommitments.length}):`}</Typography.Header>
      <GridWrapper>
        <StyleOverrides clickable={false} style={{ flex: 1 }}>
          <div
            style={{ height: 500, width: '100%' }}
            className="ag-theme-balham"
          >
            <AgGridReact
              gridOptions={buildGridOptions({
                columnDefs: colDefs,
                rowModelType: 'clientSide',
                rowHeight,
              })}
              columnDefs={colDefs}
              onGridReady={onGridReady}
              components={GridFrameworkComponents}
              getContextMenuItems={getContextMenuItems}
            />
          </div>
        </StyleOverrides>
        {isSlideOutOpen && (
          <div
            style={{
              height: '100%',
              minWidth: 450,
              width: '35%',
              zIndex: Z_INDEX.CUSTOMER_LANE_CONFIG_SLIDEOUT,
              overflowY: 'scroll',
              paddingLeft: 4,
            }}
          >
            <NewCommitmentSlideOut />
          </div>
        )}
      </GridWrapper>
    </div>
  );
};

export default QueryLaneCommitConfigurations;
