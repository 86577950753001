import Colors from '../../../theme/colors';

const getDiffColors = ({
  positiveDeltaIsGood,
  diff,
}: {
  positiveDeltaIsGood?: boolean;
  diff: number;
}) => {
  if (positiveDeltaIsGood || positiveDeltaIsGood === undefined) {
    if (diff >= 0) {
      return {
        textColor: Colors.MEDIUM_SEA_GREEN,
        iconBackgroundColor: Colors.MEDIUM_SEA_GREEN_BACKDROP,
      };
    }
    return {
      textColor: Colors.RADICAL_RED,
      iconBackgroundColor: Colors.RADICAL_RED_BACKDROP,
    };
  }

  if (diff >= 0) {
    return {
      textColor: Colors.RADICAL_RED,
      iconBackgroundColor: Colors.RADICAL_RED_BACKDROP,
    };
  }
  return {
    textColor: Colors.MEDIUM_SEA_GREEN,
    iconBackgroundColor: Colors.MEDIUM_SEA_GREEN_BACKDROP,
  };
};

export default getDiffColors;
