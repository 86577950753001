import React, { useContext, useEffect, useState } from 'react';
import GeneralGoalContext from 'contexts/GeneralGoalContext';
import Loading from '../../components/Loading/Loading';
import AccountContext from '../../contexts/AccountContext';
import getDateBuckets, {
  useGoalDateBuckets,
} from '../../screens/GoalShow/getDateBuckets';
import moment from 'moment';
import getNumberIntervals from '../../utils/goalsHelpers/getNumberIntervals';
import getRemainingIntervals from '../../utils/goalsHelpers/getRemainingIntervals';
import { getKpiTargetForMonth } from '../../components/Goals/GeneralGoalForm/KpiForm/AdvancedTargetInput/MonthlyTargetBuilder';
import FlexCentered from '../../components/Common/FlexCentered';
import Card from '../../components/Common/Card';
import { useGeneralGoalData } from '../../screens/GoalShow/useGoalData';
import GoalContext from 'contexts/GoalContext';

const useBasicProratedTarget = ({
  goal,
  isCumulative,
}: {
  goal: GeneralGoal;
  isCumulative: boolean;
}) => {
  const { isDemoAccount, demoAccountNow } = useContext(AccountContext);

  if (goal.targetMode === 'advanced') {
    return {
      target: 0,
      targetValueForNow: 0,
    };
  }

  const numberIntervals = getNumberIntervals(goal);
  const remainingIntervals = getRemainingIntervals(
    goal,
    !!isDemoAccount,
    demoAccountNow,
  );
  const intervalsPassed = numberIntervals - remainingIntervals;
  const t = goal.target === undefined ? 0 : goal.target;

  const targetValueForNow = (() => {
    if (isCumulative) {
      return (t / numberIntervals) * intervalsPassed;
    } else {
      return t;
    }
  })();

  return {
    targetValueForNow,
    target: t,
  };
};

const useAdvancedProratedTarget = ({
  goal,
  isCumulative,
}: {
  goal: GeneralGoal;
  isCumulative: boolean;
}) => {
  const { isDemoAccount, weekStartsOn, demoAccountNow } =
    useContext(AccountContext);

  if (goal.targetMode === 'basic') {
    return {
      target: 0,
      targetValueForNow: 0,
    };
  }
  const dateIntervals = getDateBuckets({
    startDate: goal.fixedStartDate,
    endDate: goal.fixedEndDate,
    interval: goal.cadence,
    weekStartsOn,
  });
  const todayIso =
    isDemoAccount && demoAccountNow
      ? demoAccountNow
      : moment().format('YYYY-MM-DD');
  const completedIntervals = dateIntervals.filter((d) => {
    const endOfBucketIso = moment(d)
      .endOf(goal.cadence === 'auto' ? 'month' : goal.cadence)
      .format('YYYY-MM-DD');
    return todayIso > endOfBucketIso;
  });

  if (!isCumulative) {
    return {
      targetValueForNow: getKpiTargetForMonth(
        goal.advancedTarget,
        completedIntervals[completedIntervals.length - 1],
      ),
      target: getKpiTargetForMonth(
        goal.advancedTarget,
        dateIntervals[dateIntervals.length - 1],
      ),
    };
  }

  const sumOfCompletedIntervals = completedIntervals.reduce(
    (total, currentDate) => {
      return total + getKpiTargetForMonth(goal.advancedTarget, currentDate);
    },
    0,
  );
  const currentIntervalStart = moment(todayIso)
    .startOf(goal.cadence === 'auto' ? 'month' : goal.cadence)
    .format('YYYY-MM-DD');
  const currentIntervalEnd = moment(currentIntervalStart)
    .endOf(goal.cadence === 'auto' ? 'month' : goal.cadence)
    .format('YYYY-MM-DD');
  const currentIntervalTarget = getKpiTargetForMonth(
    goal.advancedTarget,
    currentIntervalStart,
  );
  const daysInCurrentInterval = Math.abs(
    moment(currentIntervalStart).diff(moment(currentIntervalEnd), 'days'),
  );
  const daysPassedInCurrentInterval = Math.abs(
    moment(currentIntervalStart).diff(moment(todayIso), 'days'),
  );
  const proratedCurrentIntervalTarget =
    (currentIntervalTarget / daysInCurrentInterval) *
    daysPassedInCurrentInterval;

  const target = dateIntervals.reduce((total, currentDate) => {
    return total + getKpiTargetForMonth(goal.advancedTarget, currentDate);
  }, 0);

  return {
    targetValueForNow: sumOfCompletedIntervals + proratedCurrentIntervalTarget,
    target,
  };
};

export const useProratedTarget = ({
  goal,
  isCumulative,
}: {
  goal: GeneralGoal;
  isCumulative: boolean;
}) => {
  const basicTarget = useBasicProratedTarget({ goal, isCumulative });
  const advancedTarget = useAdvancedProratedTarget({
    goal,
    isCumulative,
  });

  if (goal.targetMode === 'basic') {
    return basicTarget;
  } else {
    return advancedTarget;
  }
};

const GeneralGoalProvider = ({
  children,
  goal,
  isList,
}: {
  children: JSX.Element | JSX.Element[];
  goal: GeneralGoal;
  isList?: boolean;
}) => {
  const { dateBuckets, currentDateBucket, lastCompletedDateBucket } =
    useGoalDateBuckets(goal);
  const [currentValue, setCurrentValue] = useState<number>(0);
  const {
    primaryKpiData,
    primaryKpiDataCumulated,
    secondaryKpisData,
    isLoading,
    isPrimaryCumulative,
    goalInput,
  } = useGeneralGoalData(goal);
  const { target, targetValueForNow } = useProratedTarget({
    goal,
    isCumulative: !!isPrimaryCumulative,
  });

  useEffect(() => {
    const newCurrentValue = (() => {
      if (!primaryKpiData) {
        return;
      }
      if (primaryKpiDataCumulated) {
        return primaryKpiDataCumulated.value;
      } else if (primaryKpiData.trend) {
        const lastCompleted = primaryKpiData.trend.find(
          (t) => t.date === lastCompletedDateBucket,
        );
        if (lastCompleted) {
          return lastCompleted.value;
        }
      }

      return 0;
    })();

    if (typeof newCurrentValue === 'number') {
      setCurrentValue(newCurrentValue);
    }
  }, [
    isPrimaryCumulative,
    lastCompletedDateBucket,
    primaryKpiData,
    primaryKpiDataCumulated,
  ]);

  if (isLoading || !primaryKpiData || !secondaryKpisData || !goalInput) {
    if (isList) {
      return (
        <Card
          style={{
            marginBottom: 14,
            width: '100%',
            minHeight: 86,
          }}
        >
          <FlexCentered style={{ height: '100%' }}>
            <Loading />
          </FlexCentered>
        </Card>
      );
    } else {
      return (
        <FlexCentered style={{ height: '100%' }}>
          <Loading />
        </FlexCentered>
      );
    }
  }

  return (
    <GoalContext.Provider
      value={{
        goal,
        dateBuckets,
        currentDateBucket,
        lastCompletedDateBucket,
      }}
    >
      <GeneralGoalContext.Provider
        value={{
          goal,
          dateBuckets,
          primaryKpiData,
          primaryKpiDataCumulated,
          secondaryKpisData,
          goalInput,
          target,
          targetValueForNow,
          isPrimaryCumulative,
          currentDateBucket,
          lastCompletedDateBucket,
          currentValue,
        }}
      >
        {children}
      </GeneralGoalContext.Provider>
    </GoalContext.Provider>
  );
};

export default GeneralGoalProvider;
