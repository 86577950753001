import React from 'react';
import styled from 'styled-components';

import Row from 'components/Common/Row';
import DragAndDropList from 'components/DragAndDropList';
import Typography from 'kingpin/atoms/Typography';
import Colors2 from 'theme/Colors2';
import FieldItem from './FieldItem';

export const Col = styled.div<{ width: number; centerAlign?: boolean }>`
  display: flex;
  width: ${(props) => props.width}px;
  flex-direction: column;
  text-align: ${(props) => (props.centerAlign ? 'center' : 'left')};
  flex-wrap: wrap;

  word-wrap: break-word;
`;

const FieldsGrid = ({
  searchText,
  filteredColumns,
  columnOrder,
  updateColumnsOrder,
}: {
  searchText: string;
  filteredColumns: PerformanceBoardTypes.Column[];
  columnOrder: PerformanceBoardTypes.Column[];
  updateColumnsOrder: ({
    newOrder,
  }: {
    newOrder: PerformanceBoardTypes.Column[];
  }) => void;
}) => {
  return (
    <>
      <Row
        centerAlign
        style={{
          height: '40px',
          padding: '8px',
          position: 'sticky',
          top: 0,
          backgroundColor: Colors2.Grey['9'],
          border: `1px solid ${Colors2.Grey['8']}`,
          margin: '-1px 0',
        }}
      >
        <Col width={73} centerAlign>
          <Typography.Body type="Body 12">#</Typography.Body>
        </Col>
        <Col width={225}>
          <Typography.Body type="Body 12">Field Name</Typography.Body>
        </Col>
        <Col width={73} centerAlign>
          <Typography.Body type="Body 12">Hidden</Typography.Body>
        </Col>
        <Col width={73} centerAlign>
          <Typography.Body type="Body 12">Displayed</Typography.Body>
        </Col>
      </Row>
      <DragAndDropList
        items={filteredColumns}
        onOrderChanged={(newOrder) => updateColumnsOrder({ newOrder })}
        droppableId="fieldId"
        renderItem={(column) => (
          <FieldItem
            column={column}
            searchText={searchText}
            columnOrder={columnOrder}
            updateColumnsOrder={updateColumnsOrder}
          />
        )}
      />
    </>
  );
};

export default FieldsGrid;
