import base from './baseOptions';
import epochToIsoDate from '../../../epochToIsoDate';
import buildDrillDown from '../../../buildDrillDown';
import moment from 'moment';

const timeSeries = ({
  showLegend,
  title,
  openMenu,
  chartDef,
  fontSize,
  formatMetric,
}) => ({
  ...base(formatMetric),
  chart: {
    ...base(formatMetric).chart,
    marginRight: 12,
    marginTop: 24,
  },
  plotOptions: {
    series: {
      label: {
        connectorAllowed: true,
      },
      point: {
        events: {
          click: function (e) {
            const selectedIndex = e.point.x;
            const terms = e.point.series.data;
            const selected = terms.find((t) => t.x === selectedIndex);
            const { onDrillDown } = this.series.userOptions;
            if (openMenu) {
              if (chartDef.trendByCalendarInterval === 'auto') {
                return;
              }
              const { metricIds } = this.series.userOptions;
              const d = buildDrillDown(
                'date',
                'date',
                moment.utc(selected.datetime).format('YYYY-MM-DD'),
                chartDef,
              );
              openMenu(metricIds, d);
            } else {
              if (!onDrillDown) {
                return;
              }

              onDrillDown(
                epochToIsoDate(
                  selected.datetime || selected.x,
                  chartDef.trendByCalendarInterval === 'hour',
                ),
              );
            }
          },
        },
      },
    },
  },
  xAxis: {
    ...base(formatMetric).xAxis[0],
    type: 'datetime',
    dateTimeLabelFormats: {
      // don't display the dummy year
      month: '%m/%e',
      year: '%b',
    },
    labels: {
      style: {
        whiteSpace: 'nowrap',
        fontSize,
      },
      autoRotation: 0,
    },
  },
  yAxis: {
    title: {
      enabled: false,
    },
    labels: {
      style: {
        fontSize,
      },
    },
  },
  legend: {
    ...base(formatMetric).legend,
    enabled: showLegend,
  },
  title: {
    ...base(formatMetric).title,
    text: title,
  },
});

export default timeSeries;
