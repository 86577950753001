import React from 'react';
import BASE_APPLICATION_URL from 'baseApplicationUrl';
import appRoutes from 'navigation/appRoutes';

const REDIRECT_LINK = `${BASE_APPLICATION_URL}${appRoutes.loggedIn.microsoftSsoRedirect}`;
const CLIENT_ID = '4a19b00c-c10e-4a5b-ad35-06721611784f';
const CONSENT_LINK = `https://login.microsoftonline.com/common/adminconsent?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_LINK}`;

const ConsentLink = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => (
  <a href={CONSENT_LINK} target={'_blank'} rel="noreferrer">
    {children}
  </a>
);

export default ConsentLink;
