import { useContext, useEffect, useState } from 'react';
import AccountPickerContext from '../contexts/AccountPickerContext';

export const ACTIVE_QUICK_FILTER_ID = 'ACTIVE_QUICK_FILTER';
export const CLOSED_QUICK_FILTER_ID = 'CLOSED_QUICK_FILTER';

const useQuickFilters = (source: string) => {
  const [quickFilters, setQuickFilters] = useState<SavedFilter[]>([]);
  const { accountRef } = useContext(AccountPickerContext);

  useEffect(() => {
    if (process.env.NODE_ENV === 'test') {
      return;
    }
    const listener = accountRef
      .collection('savedFilters')
      .where('source', '==', source)
      .onSnapshot((s) => {
        const data = [] as SavedFilter[];
        s.docs.forEach((d: any) => data.push(d.data()));
        setQuickFilters([...data]);
      });

    return () => {
      listener();
    };
  }, [accountRef, source]);

  return quickFilters;
};

export default useQuickFilters;
