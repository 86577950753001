import React, { useContext, useEffect, useRef, useState } from 'react';
import colors from 'theme/colors';
import Chart from '../../../Chart';
import AccountContext from '../../../../contexts/AccountContext';
import areaOptions from '../../../V5Gadget/highchartOptions/areaOptions';
import Card from '../../../Common/Card/index';
import formatFloat from '../../../../api/getChartSeries/formatFloat';
import BoardContext from '../../../../contexts/BoardContext';
import isCustomerLaneBoard from '../../../../isCustomerLaneBoard';
import Colors from '../../../../theme/colors';
import baseOptions from '../../../V5Gadget/highchartOptions/baseOptions';
import { getDateLabel } from '../Performance';
import useValueFormatters from '../../../../hooks/useValueFormatters';

const useLineChart = ({
  data,
  config,
}: {
  data: MetricsResponse;
  config: TargetVisConfig;
}) => {
  const { unitsLocale } = useContext(AccountContext);
  const { formatMetric } = useValueFormatters();
  const [options, setOptions] = useState<any>();

  useEffect(() => {
    if (data.length === 0) {
      setOptions(undefined);
      return;
    }

    const target = data[0][config.cadenceTargetField];

    const zones = [
      {
        value: target,
        color: colors.RADICAL_RED,
      },
      { color: colors.MEDIUM_SEA_GREEN },
    ];

    const series = [
      {
        data: data
          .map((d) => [
            new Date(d.date).getTime(),
            formatFloat(d[config.cadenceTotalField], 0),
          ])
          .reverse(),
        name: 'performance',
        zones,
        threshold: target,
        marker: {
          enabled: false,
          symbol: 'circle',
        },
      },
    ];
    const options = {
      ...areaOptions({
        title: '',
        sparkline: true,
        doubleSparkHeight: true,
        formatMetric,
      }),
      series,
    };
    setOptions(options);
  }, [
    config.cadenceTargetField,
    config.cadenceTotalField,
    data,
    formatMetric,
    unitsLocale,
  ]);

  return options;
};

export const useBarOptions = ({
  data,
  config,
  cadence,
}: {
  data: MetricsResponse;
  config: TargetVisConfig;
  cadence?: 'day' | 'week' | 'month';
}) => {
  const { formatMetric } = useValueFormatters();
  const [options, setOptions] = useState<any>();

  useEffect(() => {
    const options = {
      accessibility: {
        enabled: false,
      },
      chart: {
        type: 'column',
      },
      title: {
        useHTML: true,
        text: '',
      },
      plotOptions: {
        column: {
          zones: [
            {
              value: 0,
              color: Colors.RADICAL_RED,
            },
            {
              color: Colors.MEDIUM_SEA_GREEN,
            },
          ],
        },
      },
      tooltip: {
        ...baseOptions(formatMetric).tooltip,
        formatter: function () {
          // @ts-ignore
          const { x, points } = this;
          if (points.length === 0) {
            return '';
          }

          const headerText = getDateLabel(x, cadence);

          let pointsHtml = '';
          // @ts-ignore
          points.forEach((p) => {
            const formattedY = formatMetric({
              value: p.y,
              metricId: p.series.metricId,
            });
            pointsHtml += `<div><span style="color:${p.color}">● </span><span style="color: #878F9D;">${p.series.name}: </span><b>${formattedY}</b></div><br/>`;
          });

          return `<div>${
            cadence === 'week' ? 'Week' : ''
          } ${headerText}<br/>${pointsHtml}`;
        },
      },
      xAxis: {
        categories: data
          .map((d) => d[config.dateKey ? config.dateKey : 'date'])
          .reverse(),
        visible: false,
      },
      series: [
        {
          data: data
            .map((d) => formatFloat(d[config.cadenceDifferenceField], 0))
            .reverse(),
          name: config.title,
        },
      ],
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      yAxis: {
        title: {
          enabled: false,
        },
        visible: true,
      },
    };

    setOptions(options);
  }, [
    config.cadenceDifferenceField,
    config.dateKey,
    config.title,
    data,
    formatMetric,
    cadence,
  ]);

  return options;
};

const PerformanceChartContainer = ({
  data,
  config,
  cadence,
}: {
  data: MetricsResponse;
  config: TargetVisConfig;
  cadence?: 'day' | 'week' | 'month';
}) => {
  const { board } = useContext(BoardContext);
  const chartRef = useRef<any>();
  const areaOptions = useLineChart({ data, config });
  const barOptions = useBarOptions({ data, config, cadence });

  if (data.length === 0 || !areaOptions || !barOptions) {
    return null;
  }

  return (
    <Card
      style={{
        marginBottom: 16,
        height: isCustomerLaneBoard(board) ? 'unset' : 200,
        position: 'relative',
        borderRadius: '4px',
        overflow: 'hidden',
      }}
    >
      <Chart
        chartRef={chartRef}
        options={isCustomerLaneBoard(board) ? areaOptions : barOptions}
      />
    </Card>
  );
};

export default PerformanceChartContainer;
