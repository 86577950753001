import React from 'react';
import styled from 'styled-components';
import Button from 'kingpin/atoms/Button';

import { UserInvitationRow } from './types';
import Row from '../Common/Row';
import Typography from 'kingpin/atoms/Typography';
import Form from '../../kingpin/forms/Form';
import FormHeader from '../../kingpin/forms/FormHeader';
import FormContent from '../../kingpin/forms/FormContent';
import Icon from '../../kingpin/atoms/Icon';
import Colors2 from '../../theme/Colors2';

const IconDiv = styled.div`
  margin-left: 8px;
`;

const Col = styled.div`
  flex: 1;
`;

const ProblemsFeedback = ({
  close,
  onRetryClicked,
  problems,
  ok,
}: {
  close: () => void;
  onRetryClicked: () => void;
  problems: UserInvitationRow[];
  ok: UserInvitationRow[];
}) => (
  <Form>
    <FormHeader title={'Something went wrong'} onClose={close} />
    <FormContent>
      <Row style={{ justifyContent: 'center', marginBottom: 24 }}>
        <Typography.Header type="H5">
          {`${ok.length} of ${problems.length + ok.length} Invites Sent`}
        </Typography.Header>
      </Row>
      {ok.length > 0 && (
        <Row style={{ marginBottom: 24 }}>
          <Col>
            <Typography.Body type="Body 12">
              {`The following invite${ok.length > 1 ? 's were' : ' was'} sent:`}
            </Typography.Body>
          </Col>
          <Col>
            {ok.map((invite) => (
              <Row centerAlign key={invite.id}>
                <Typography.Body type="Body 12">{invite.email}</Typography.Body>
                <IconDiv>
                  <Icon
                    icon="circleCheckmark"
                    color={Colors2.Secondary.success}
                  />
                </IconDiv>
              </Row>
            ))}
          </Col>
        </Row>
      )}

      <Row style={{ marginBottom: 24 }}>
        <Col>
          <Typography.Body type="Body 12">
            {`The following invite${
              problems.length > 1 ? 's were' : ' was'
            } not sent:`}
          </Typography.Body>
          <br />
          <Typography.Body type="Body 12">
            {`Email${problems.length > 1 ? 's' : ''} already in use:`}
          </Typography.Body>
        </Col>
        <Col>
          {problems.map((invite) => (
            <Row centerAlign key={invite.id}>
              <Typography.Body type="Body 12">{invite.email}</Typography.Body>
              <div style={{ marginLeft: 8 }}>
                <IconDiv>
                  <Icon icon={'warning'} color={Colors2.Secondary.error} />
                </IconDiv>
              </div>
            </Row>
          ))}
        </Col>
      </Row>
    </FormContent>

    <Row style={{ justifyContent: 'flex-end' }}>
      <Button
        onClick={onRetryClicked}
        label="Retry"
        type="Primary"
        size="Small"
      />
    </Row>
  </Form>
);

export default ProblemsFeedback;
