import React from 'react';

interface MetricUsageLookupContextType {
  metricLookup: FleetOps.MetricUsageLookup;
  compoundMetricLookup: FleetOps.MetricUsageLookup;
}
const MetricUsageLookupContext =
  React.createContext<MetricUsageLookupContextType>({
    metricLookup: {},
    compoundMetricLookup: {},
  });

export default MetricUsageLookupContext;
