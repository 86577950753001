const getMatrixDefaultSort = (chartDef: V5ChartDefinition) => {
  const s = chartDef.series.find((s) => s.sortByComparison);
  if (!s || !s.sortByComparison) {
    return undefined;
  }

  return {
    defaultSortMode: s.sortByComparison,
    defaultSortMetricId: s.metricId,
  };
};

export default getMatrixDefaultSort;
