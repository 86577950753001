/* eslint-disable react/prop-types */
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';

import Cell from './V5Gadget/Matrix/Cell';
import Colors from '../theme/colors';
import BoardContext from '../contexts/BoardContext';

import styled from 'styled-components';
import assignReasonCode from '../api/reasonCodes/assignReasonCode';
import Loading from './Loading';
import { ICellEditorParams, ICellRendererParams } from 'ag-grid-community';
import Row from './Common/Row';
import Button from 'kingpin/atoms/Button';
import GridCellErrorBoundary from './GridCellErrorBoundary';
import GqlClientContext from '../contexts/GqlClientContext';
import AnalyticsContext from '../contexts/AnalyticsContext';
import Typography from 'kingpin/atoms/Typography';
import removeReasonCode from '../api/reasonCodes/removeReasonCode';
import Tooltip from './Tooltip';
import useValidateText from 'hooks/useValidateText';
import useHideIntercom from '../hooks/useHideIntercom';
import Icon from '../kingpin/atoms/Icon';
import Colors2 from '../theme/Colors2';
import KingpinBadge from './Badge';
import TextInput from '../kingpin/atoms/TextInput';
import TextArea from './Inputs/TextArea';

const List = styled.div`
  max-height: 160px;
  overflow-y: scroll;
  margin-top: 8px;
  padding: 0px 12px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
  height: 32px;
  &:hover {
    background-color: #ededed;
  }
`;

const Popup = styled.div`
  padding: 24px 16px;
  background-color: white;
`;

export const Badge = styled.div`
  border-radius: 2px;
  background-color: #f4f2f7;
  padding: 6px 8px;
`;

export const BadgeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 2em;
`;

const Warning = styled.div`
  color: ${Colors.RADICAL_RED};
  font-size: 13px;
`;

const INVALID_REASON_NOTE_TOOLTIP =
  'Your summary contains illegal characters (":", ";", "<", ">").';

interface ReasonCodePickerState {
  reasonCode: string[] | undefined;
  reasonNote: string | undefined;
}

export class ReasonCodePickerRenderer extends React.Component<
  ICellEditorParams,
  ReasonCodePickerState
> {
  static contextType = BoardContext;

  constructor(props: ICellEditorParams) {
    super(props);
    this.state = {
      reasonCode: this.props.value,
      reasonNote: this.props.data.reasonNote,
    };
  }

  setValue = ({
    reasonCode,
    reasonNote,
  }: {
    reasonCode: string[] | undefined;
    reasonNote: string | undefined;
  }) => {
    this.setState({ reasonCode });
    // @ts-ignore
    if (this.props.api.getColumn('reasonNote')) {
      // @ts-ignore
      this.props.node.setDataValue('reasonNote', reasonNote);
    }
  };

  // @ts-ignore
  componentDidUpdate(
    prevProps: ICellRendererParams,
    prevState: ReasonCodePickerState,
    snapshot?: any,
  ) {
    if (this.state.reasonCode !== prevState.reasonCode) {
      this.props.stopEditing();
    }
  }

  getValue() {
    // Called when stopEditing is called to get the new state
    return this.state.reasonCode;
  }

  isPopup() {
    return true;
  }

  render() {
    // @ts-ignore
    const hasOnClick = !!this.context.board;

    if (hasOnClick) {
      return (
        <ReasonPicker
          // @ts-ignore
          documentId={this.props.data.id}
          agGridProps={this.props}
          setValue={this.setValue}
        />
      );
    } else {
      return null;
    }
  }
}

const ReasonPicker = ({
  documentId,
  agGridProps,
  setValue,
}: {
  documentId: string;
  agGridProps: ICellEditorParams;
  setValue: ({
    reasonCode,
    reasonNote,
  }: {
    reasonCode: string[] | undefined;
    reasonNote: string | undefined;
  }) => void;
}) => {
  const { trackEvent } = useContext(AnalyticsContext);
  const { client } = useContext(GqlClientContext);
  const { reasonCodes, board } = useContext(BoardContext);
  const selectedCodes = agGridProps.value
    ? (agGridProps.value as string[])
    : [];
  const selectedReasonCodes = (() => {
    if (Array.isArray(selectedCodes)) {
      return selectedCodes
        .map((c) => reasonCodes.find((rc) => rc.code === c))
        .filter((rc) => !!rc) as ReasonCode[];
    } else {
      return [];
    }
  })();

  const [searchText, setSearchText] = useState<string>('');
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [wipCodes, setWipCodes] = useState<ReasonCode[]>(selectedReasonCodes);
  const [reasonSummary, setReasonSummary] = useState<string | undefined>(
    agGridProps.data.reasonNote,
  );
  const [isReasonSummaryValid, setIsReasonSummaryValid] =
    useState<boolean>(true);
  const [isValid, setIsValid] = useState<boolean>(false);
  const hasNoCodes = wipCodes.length === 0;
  const validateText = useValidateText();

  // setIsReasonSummaryValid
  useEffect(() => {
    if (reasonSummary === undefined || reasonSummary === null) {
      setIsReasonSummaryValid(true);
      return;
    }

    setIsReasonSummaryValid(validateText({ text: reasonSummary }));
  }, [reasonSummary, validateText]);

  useHideIntercom();

  const onSearchTextChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const filteredReasonCodes = reasonCodes.filter((r) =>
    r.code.toLowerCase().includes(searchText.toLowerCase()),
  );
  const hasFilteredCodes = filteredReasonCodes.length > 0;

  // setIsValid
  useEffect(() => {
    const isCodeValid =
      selectedCodes.length === 0 ? wipCodes.length !== 0 : true;
    const newIsValid = isCodeValid && isReasonSummaryValid;
    setIsValid(newIsValid);
  }, [isReasonSummaryValid, selectedCodes.length, wipCodes.length]);

  const toggleWipCode = (code: ReasonCode) => {
    const isExisting = !!wipCodes.find((c) => c.id === code.id);
    if (isExisting) {
      setWipCodes((codes) => codes.filter((c) => c.id !== code.id));
    } else {
      setWipCodes((codes) => [...codes, code]);
    }
  };

  const onReasonSummaryChanged = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setReasonSummary(event.target.value);
  };

  const onSave = () => {
    if (!isValid) {
      return;
    }

    setIsSaving(true);

    if (wipCodes.length > 0) {
      assignReasonCode({
        documentId,
        documentType: board.dataType,
        reasonCodes: wipCodes,
        reasonNote: reasonSummary,
        client,
      }).then(() => {
        const newCodes = wipCodes.map((c) => c.code);
        setValue({
          reasonCode: newCodes,
          reasonNote: reasonSummary,
        });

        trackEvent('Reason code - Add code clicked');
        if (reasonSummary) {
          trackEvent('Reason code - Summary - added');
        }
        setIsSaving(false);
      });
    } else {
      removeReasonCode({
        documentId,
        documentType: board.dataType,
        client,
      }).then(() => {
        setValue({
          reasonCode: undefined,
          reasonNote: undefined,
        });

        setIsSaving(false);
      });
    }
  };

  return (
    <Popup>
      {isSaving && <Loading />}
      {!isSaving && (
        <React.Fragment>
          <div style={{ fontSize: 11 }}>
            <div style={{ marginBottom: 16 }}>
              <TextInput
                value={searchText}
                onChange={onSearchTextChanged}
                icon="search"
                autoFocus
                inputSize="Small"
              />
              <List>
                {!hasFilteredCodes && <div>No Reason Codes were found</div>}
                {hasFilteredCodes &&
                  filteredReasonCodes.map((r) => (
                    <Item
                      key={r.id}
                      onClick={() => {
                        toggleWipCode(r);
                      }}
                    >
                      <Row
                        centerAlign
                        style={{
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                      >
                        <Typography.Body type="Body 12">
                          {r.code}
                        </Typography.Body>
                        {!!wipCodes.find((c) => c.id === r.id) && (
                          <Icon icon="checkmark" />
                        )}
                      </Row>
                    </Item>
                  ))}
              </List>
            </div>

            <div style={{ marginBottom: 16 }}>
              <div style={{ marginBottom: 4 }}>
                <Typography.Body type="Label">Summary</Typography.Body>
              </div>
              <TextArea
                isReadOnly={hasNoCodes}
                readOnly={hasNoCodes}
                value={reasonSummary}
                onChange={onReasonSummaryChanged}
              />
              {hasNoCodes && reasonSummary && (
                <Warning>* This will be lost</Warning>
              )}
            </div>
            <Row
              centerAlign
              style={{ justifyContent: 'space-between', width: '100%' }}
            >
              <Button
                size="Small"
                type="Ghost"
                onClick={() => agGridProps.stopEditing()}
                label="Cancel"
              />
              <Tooltip
                content={
                  isReasonSummaryValid ? undefined : INVALID_REASON_NOTE_TOOLTIP
                }
              >
                <Button
                  size={'Small'}
                  type={'Primary'}
                  label="Save"
                  isDisabled={!isValid}
                  onClick={onSave}
                />
              </Tooltip>
            </Row>
          </div>
        </React.Fragment>
      )}
    </Popup>
  );
};

interface ReasonCodeCellParams extends ICellRendererParams {
  isOnClickEnabled: boolean;
}

class ReasonCodeCellRenderer extends React.Component<ReasonCodeCellParams> {
  render() {
    const { isOnClickEnabled } = this.props;
    const value = this.props.value;
    const { node } = this.props;

    if (node.rowPinned) {
      return <Cell />;
    }

    const onClick = () => {
      // Do nothing
    };

    if (isOnClickEnabled) {
      if (value && value.length !== 0) {
        return (
          <Cell onClick={onClick}>
            <KingpinBadge
              text={Array.isArray(value) ? value.join(', ') : value}
              badgeType={'Default'}
            />
          </Cell>
        );
      } else {
        return (
          <Cell
            onClick={onClick}
            style={{ color: Colors.NAVY_BLUE, cursor: 'pointer' }}
          >
            <span style={{ color: Colors2.Secondary.info }}>Add code</span>
          </Cell>
        );
      }
    } else {
      if (value && value.length !== 0) {
        return (
          <Cell>
            <KingpinBadge
              text={Array.isArray(value) ? value.join(', ') : value}
              badgeType={'Default'}
            />
          </Cell>
        );
      } else {
        return <Cell>-</Cell>;
      }
    }
  }
}

const Gate = (params: ICellRendererParams) => {
  const { board } = useContext(BoardContext);

  return (
    <GridCellErrorBoundary
      params={params}
      childComponent={
        <ReasonCodeCellRenderer {...params} isOnClickEnabled={!!board} />
      }
    />
  );
};

export default Gate;
