import React, { useEffect, useState } from 'react';
import VideoGadget from './VideoGadget';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import Loading from '../Loading';
import usePopup from '../../hooks/usePopup';

const VideoGadgetContainer = ({ storagePath }: { storagePath: string }) => {
  const {
    isOpen: isEditFormOpen,
    open: openEditForm,
    close: closeEditForm,
  } = usePopup();

  const [downloadUrl, setDownloadUrl] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    firebase
      .storage()
      .ref(storagePath)
      .getDownloadURL()
      .then((downloadUrl) => {
        setDownloadUrl(downloadUrl);
        setIsLoading(false);
      });
  }, [storagePath]);

  if (downloadUrl && !isLoading) {
    return (
      <VideoGadget
        src={downloadUrl}
        setSrc={setDownloadUrl}
        storagePath={storagePath}
        isEditFormOpen={isEditFormOpen}
        openEditForm={openEditForm}
        closeEditForm={closeEditForm}
      />
    );
  } else {
    return <Loading />;
  }
};

export default VideoGadgetContainer;
