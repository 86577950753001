import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import Cell from './V5Gadget/Matrix/Cell';
import { useSettings } from './NumberCell';
import useValueFormatters from 'hooks/useValueFormatters';
import useObfuscator from '../hooks/useObfuscator';

const CurrencyCell = (props: ICellRendererParams) => {
  const { value } = props;
  const { precision, isCommasDisabled, currencyType } = useSettings(props);
  const { formatValue } = useValueFormatters();
  const { isObfuscating, obfuscatedValue } = useObfuscator({
    value: value,
    field: undefined,
  });

  if (value === undefined || value === null || value === '-') {
    return <Cell>-</Cell>;
  }
  if (typeof value !== 'number') {
    return <Cell>-</Cell>;
  }

  const formatting = {
    prefix: 'currency',
    currencyType,
    precision,
    isCommasDisabled,
  } as MetricFormatting;
  const formatted = formatValue({ value, formatting });

  return (
    <Cell rightAlign>
      <span>{isObfuscating ? obfuscatedValue : formatted}</span>
    </Cell>
  );
};

export default CurrencyCell;
