import React from 'react';

interface BaseViewsContextType {
  baseViews: {
    [dataset: string]: FleetOps.BaseView | undefined;
  };
  baseViewsLastUpdatedAtLookup: { [dataset: string]: number | undefined };
  dataTypes: string[];
  dataTypeOptions: { label: string; value: string }[];
  isLoading: boolean;
  updateBaseView: (baseView: FleetOps.BaseView) => void;
  getDatasetLabel: (dataset: string) => string;
}

const BaseViewsContext = React.createContext<BaseViewsContextType>({
  baseViews: {},
  baseViewsLastUpdatedAtLookup: {},
  dataTypes: [],
  dataTypeOptions: [],
  isLoading: true,
  updateBaseView: () => {},
  getDatasetLabel: (ds: string) => ds,
});

export default BaseViewsContext;
