import React, { useContext } from 'react';
import styled from 'styled-components';
import Row from '../../../components/Common/Row';
import { NabTabLinkMargin, NavTabLink } from '../../../components/NavTab';

import {
  buildShowCompoundMetrics,
  buildShowCosts,
  buildShowDatasets,
  buildShowEntities,
  buildShowMetrics,
  buildShowSearch,
} from './routes';
import TopBarTitleRow from '../TopBarTitleRow';
import PermissionGates from '../../../components/PermissionGates';
import PERMISSIONS from '../../../permissionDefinitions';

import { TOP_BAR_BOX_SHADOW_CSS } from '../../../components/ResourceTopBar';
import FeatureGate, { FEATURE_GATES } from '../../../components/FeatureGate';
import { PortalsContext } from '../../../contextProviders/PortalsProvider';

const Wrapper = styled.div`
  background-color: white;
  ${TOP_BAR_BOX_SHADOW_CSS};
`;

const TopNavigator = () => {
  const { selectedPortal } = useContext(PortalsContext);

  return (
    <Wrapper>
      <TopBarTitleRow />
      <Row style={{ padding: '0px 24px' }}>
        <div style={{ marginRight: NabTabLinkMargin }}>
          <NavTabLink title="Datasets" to={buildShowDatasets(selectedPortal)} />
        </div>
        <PermissionGates.Has
          requiredPermission={
            PERMISSIONS.DATA_MANAGEMENT.REUSABLE_METRICS_CONFIG
          }
        >
          <div style={{ marginRight: NabTabLinkMargin }}>
            <NavTabLink
              title="Metrics"
              to={buildShowMetrics(selectedPortal)}
              onClickEvent="Data Manager - Clicked Metrics"
            />
          </div>
          <div style={{ marginRight: NabTabLinkMargin }}>
            <NavTabLink
              title="Compound Metrics"
              to={buildShowCompoundMetrics(selectedPortal)}
              onClickEvent="Data Manager - Clicked Compound Metrics"
            />
          </div>
        </PermissionGates.Has>
        <div style={{ marginRight: NabTabLinkMargin }}>
          <NavTabLink
            title="Search Data"
            to={buildShowSearch(selectedPortal)}
            onClickEvent="Data Manager - Clicked Search Raw Data"
          />
        </div>
        <FeatureGate featureName={FEATURE_GATES.ACTIVITY_COSTS}>
          <PermissionGates.Has
            requiredPermission={PERMISSIONS.DATA_MANAGEMENT.ACTIVITY_COSTS_VIEW}
          >
            <div style={{ marginRight: NabTabLinkMargin }}>
              <NavTabLink
                title="Costs"
                to={buildShowCosts(selectedPortal)}
                onClickEvent="Data Manager - Clicked Costs"
              />
            </div>
          </PermissionGates.Has>
        </FeatureGate>
        <FeatureGate featureName={FEATURE_GATES.ENTITY_DETAILS}>
          <div style={{ marginRight: NabTabLinkMargin }}>
            <NavTabLink
              title="Entities"
              isBeta
              to={buildShowEntities(selectedPortal)}
            />
          </div>
        </FeatureGate>
      </Row>
    </Wrapper>
  );
};

export default TopNavigator;
