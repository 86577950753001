import { useCallback, useContext, useState } from 'react';
import ContextMenuContext from '../kingpin/atoms/ContextMenu/ContextMenuContext';

const usePopup = (
  defaultOpen = false,
  onOpened?: () => void,
  isMoreMenuLinked?: boolean,
) => {
  const { closeMenu } = useContext(ContextMenuContext);
  const [isOpen, setOpen] = useState(defaultOpen);
  const open = useCallback(() => {
    setOpen(true);
    if (onOpened) {
      onOpened();
    }
  }, [onOpened]);

  const close = useCallback(() => {
    setOpen(false);
    if (isMoreMenuLinked) {
      closeMenu();
    }
  }, [closeMenu, isMoreMenuLinked]);

  const [position, setPosition] = useState({ x: 0, y: 0 });
  return { isOpen, open, close, position, setPosition };
};

export default usePopup;
