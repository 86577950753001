import { useContext, useEffect, useState } from 'react';
import CostsWizardContext from '../../context/CostsWizardContext';
import useGetLastQuarter, {
  getCostDateLabelValuePair,
} from './useGetLastQuarter';
import _ from 'lodash';
import isDefined from '../../../../../isDefined';

const useStartDateOptions = () => {
  const { wizardState, setWizardState, persistedWizardState } =
    useContext(CostsWizardContext);
  const [options, setOptions] = useState<DropdownOption[]>([]);
  const getLastQuarter = useGetLastQuarter({ wizardState });

  useEffect(() => {
    const intervalDates = getLastQuarter().reverse();
    const persistedStartDate =
      persistedWizardState &&
      persistedWizardState.config.basicStep.interval ===
        wizardState.config.basicStep.interval
        ? persistedWizardState.config.basicStep.startDate
        : undefined;
    const interval = wizardState.config.basicStep.interval;
    const persistedBaseOption = persistedStartDate
      ? getCostDateLabelValuePair({
          date: persistedStartDate,
          interval,
        })
      : undefined;

    const baseOptions = _.uniqBy(
      _.sortBy(
        [persistedBaseOption, ...intervalDates].filter(isDefined),
        'value',
      ),
      'value',
    );
    const newOptions = baseOptions.map((date) => ({
      label: date.label,
      value: date.value,
      isSelected: wizardState.config.basicStep.startDate === date.value,
      onSelected: () => {
        setWizardState({
          ...wizardState,
          config: {
            ...wizardState.config,
            basicStep: {
              ...wizardState.config.basicStep,
              startDate: date.value,
            },
          },
        });
      },
    }));

    setOptions(newOptions);
  }, [getLastQuarter, persistedWizardState, setWizardState, wizardState]);

  return options;
};

export default useStartDateOptions;
