import Button from 'kingpin/atoms/Button';
import React from 'react';

const SettingsButton = ({
  open,
  isOpen,
  close,
}: {
  open?: () => void;
  isOpen?: boolean;
  close?: () => void;
}) => (
  <Button
    size={'Small'}
    type="Secondary"
    testId={'board-settings'}
    icon={'settings-filled'}
    onClick={isOpen ? close : open}
  />
);

export default SettingsButton;
