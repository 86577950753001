import { useCallback, useContext } from 'react';
import _ from 'lodash';

import captureException from '../../../services/captureException';
import MetricUsageLookupContext from '../../../contexts/MetricUsageLookupContext';
import CompoundMetricsContext from '../../../contexts/CompoundMetricsContext';
import metricTypeCheckers from '../../../types/metricTypeCheckers';
import CurrentUserContext from '../../../contexts/CurrentUserContext';
import useGetFieldLabel from '../../../hooks/useGetFieldLabel';

const useGetFilteredAndSortedMetrics = () => {
  const { isFleetOpsStaff } = useContext(CurrentUserContext);
  const { metricLookup, compoundMetricLookup } = useContext(
    MetricUsageLookupContext,
  );
  const { compoundMetricInputsLookup } = useContext(CompoundMetricsContext);
  const { getMetricFieldLabel } = useGetFieldLabel();

  const isNotSingleUse = useCallback(
    <T extends Metrics.NormalMetric | Metrics.CompoundMetric>(metric: T) =>
      metric.status !== 'single use',
    [],
  );

  const isMatchingSearchText = useCallback(
    <T extends Metrics.NormalMetric | Metrics.CompoundMetric>(
      m: T,
      filters: MetricFilters,
    ) => {
      if (filters.searchText === undefined) {
        return true;
      }

      const isNameMatched = m.name
        .toLowerCase()
        .includes(filters.searchText.toLowerCase());

      if (isFleetOpsStaff) {
        const isFieldMatched = getMetricFieldLabel(m)
          .toLowerCase()
          .includes(filters.searchText.toLowerCase());
        return isFieldMatched || isNameMatched;
      }

      return isNameMatched;
    },
    [getMetricFieldLabel, isFleetOpsStaff],
  );

  const isMatchingUsageFilter = useCallback(
    <T extends Metrics.NormalMetric | Metrics.CompoundMetric>(
      m: T,
      filters: MetricFilters,
    ) => {
      if (filters.isUsed === undefined) {
        return true;
      }

      const usageReport = metricTypeCheckers.isNormalMetric(m)
        ? metricLookup[m.id]
        : compoundMetricLookup[m.id];
      if (!usageReport) {
        const error = new Error();
        error.name = `Metric usage not found for: ${m.id}`;
        captureException(error);
        return false;
      }

      if (filters.isUsed) {
        return usageReport.usageCount > 0;
      } else {
        return usageReport.usageCount === 0;
      }
    },
    [compoundMetricLookup, metricLookup],
  );

  const isMatchingDatasetFilter = useCallback(
    <T extends Metrics.NormalMetric | Metrics.CompoundMetric>(
      m: T,
      filters: MetricFilters,
    ) => {
      if (filters.dataset === undefined) {
        return true;
      }

      if (metricTypeCheckers.isCompoundMetric(m)) {
        const inputMetrics = compoundMetricInputsLookup[m.id];
        if (!inputMetrics) {
          return false;
        }

        return inputMetrics.some((im) => im.dataType === filters.dataset);
      } else if (metricTypeCheckers.isNormalMetric(m)) {
        return m.dataType === filters.dataset;
      }

      return false;
    },
    [compoundMetricInputsLookup],
  );

  const isMatchingStatusFilter = useCallback(
    <T extends Metrics.NormalMetric | Metrics.CompoundMetric>(
      m: T,
      filters: MetricFilters,
    ) => {
      if (filters.status === undefined) {
        return m.status !== 'archived';
      }

      return filters.status === m.status;
    },
    [],
  );

  const getFilteredAndSortedMetrics = useCallback(
    <T extends Metrics.NormalMetric | Metrics.CompoundMetric>({
      metrics,
      filters,
      sortMode,
    }: {
      metrics: T[];
      filters: MetricFilters;
      sortMode: SortMode;
    }) => {
      const newFilteredMetrics = metrics.filter((m) => {
        return (
          isNotSingleUse(m) &&
          isMatchingSearchText(m, filters) &&
          isMatchingDatasetFilter(m, filters) &&
          isMatchingUsageFilter(m, filters) &&
          isMatchingStatusFilter(m, filters)
        );
      });

      const sorted = _.sortBy(newFilteredMetrics, (m) => {
        if (sortMode === 'Alphabetical') {
          return m.name;
        }

        const report = metricTypeCheckers.isNormalMetric(m)
          ? metricLookup[m.id]
          : compoundMetricLookup[m.id];
        if (!report) {
          return 0;
        }

        return -report.usageCount;
      });

      return sorted;
    },
    [
      compoundMetricLookup,
      isMatchingDatasetFilter,
      isMatchingSearchText,
      isMatchingStatusFilter,
      isMatchingUsageFilter,
      isNotSingleUse,
      metricLookup,
    ],
  );

  return getFilteredAndSortedMetrics;
};

export default useGetFilteredAndSortedMetrics;
