import React, { useContext, useEffect, useState } from 'react';
import AccountPickerContext from '../../contexts/AccountPickerContext';
import getTimelineEventsSnapshot from './getTimelineEventsSnapshot';
import _ from 'lodash';
import GlobalTimelineContext from 'contexts/Timeline/GlobalTimelineContext';
import CurrentUserContext from '../../contexts/CurrentUserContext';

const GlobalTimelineProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { isWallboardUser } = useContext(CurrentUserContext);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [events, setEvents] = useState<Timeline.Event[]>([]);

  useEffect(() => {
    if (isWallboardUser) {
      return;
    }

    const listener = getTimelineEventsSnapshot({
      accountId: selectedAccountId,
    }).onSnapshot((snapshot: any) => {
      const data: Timeline.Event[] = [];
      snapshot.docs.forEach((d: any) => data.push({ id: d.id, ...d.data() }));
      setEvents(_.sortBy(data, 'createdOn').reverse());
      setIsLoading(false);
    });
    return () => {
      listener();
    };
  }, [isWallboardUser, selectedAccountId]);

  return (
    <GlobalTimelineContext.Provider
      value={{
        events,
        isLoading,
      }}
    >
      {children}
    </GlobalTimelineContext.Provider>
  );
};

export default GlobalTimelineProvider;
