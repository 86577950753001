import firebase from 'firebase/compat/app';
import { BOARDS_COLLECTION } from '../../constants';

const deleteBoard = async ({
  boardId,
  accountRef,
}: {
  boardId: string;
  accountRef: firebase.firestore.DocumentReference;
}) => {
  return accountRef.collection(BOARDS_COLLECTION).doc(boardId).delete();
};

export default deleteBoard;
