import { useContext, useEffect, useState } from 'react';
import CompoundMetricsContext from '../contexts/CompoundMetricsContext';

const useCompoundMetricWithMetricDefs = (compoundMetricId?: string) => {
  const { getCompoundMetricWithMetricDefs } = useContext(
    CompoundMetricsContext,
  );
  const [def, setDef] = useState<
    Metrics.CompoundMetricWithMetricDefs | undefined
  >();

  useEffect(() => {
    if (!compoundMetricId) {
      setDef(undefined);
      return;
    }

    setDef(getCompoundMetricWithMetricDefs(compoundMetricId));
  }, [compoundMetricId, getCompoundMetricWithMetricDefs]);

  return def;
};

export default useCompoundMetricWithMetricDefs;
