import React, { useCallback, useContext, useState } from 'react';
import useConfirmation from 'screens/DataManager/DatasetDefinitions/DatasetsIndex/PerformanceDatasetWizard/useConfirmation';
import GqlClientContext from 'contexts/GqlClientContext';
import AnalyticsContext from 'contexts/AnalyticsContext';
import { ListItem } from 'components/Inputs/Dropdown';
import Typography from 'kingpin/atoms/Typography';
import Loading from 'components/Loading/Loading';

import deleteTargetsMutation from '../../api/deleteTargets';
import TargetListContext from '../../contexts/TargetListContext';

const DeleteRules = ({
  target,
  lockParent,
  unLockParent,
  close,
}: {
  target: Targets.Wizard.DataTypeTarget;
  lockParent: () => void;
  unLockParent: () => void;
  close: () => void;
}) => {
  const { client } = useContext(GqlClientContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const { refreshTargets } = useContext(TargetListContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getConfirmation, ConfirmationModal } = useConfirmation({
    confirmText: 'Delete',
    title: 'Delete Target?',
    body: `By removing this target, you will be changing the targets for any documents currently affected by this target`,
  });
  const onClicked = useCallback(async () => {
    lockParent();
    const isConfirmed = await getConfirmation();
    unLockParent();
    if (!isConfirmed) {
      return;
    }

    setIsLoading(true);
    deleteTargetsMutation({
      client,
      id: target.id,
    }).then(() => {
      trackEvent('Targets - Target Deleted');
      close();
      refreshTargets();
    });
  }, [
    trackEvent,
    client,
    close,
    getConfirmation,
    lockParent,
    refreshTargets,
    target.id,
    unLockParent,
  ]);

  return (
    <>
      <ListItem onClick={onClicked}>
        <Typography.Body type="Body 12">Delete</Typography.Body>
        {isLoading && <Loading isTiny />}
      </ListItem>
      {ConfirmationModal}
    </>
  );
};

export default DeleteRules;
