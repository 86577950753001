import React from 'react';
import Typography from 'kingpin/atoms/Typography';

import FleetOpsGrey from '../images/fleetops-gray.svg';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import FlexCentered from './Common/FlexCentered';
import CanvasCardTitle from './CanvasCardTitle';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
`;

const IncompatibleComparison = ({ name }: { name?: string }) => {
  return (
    <FlexCentered style={{ width: '100%', height: '100%' }}>
      <Wrapper>
        {name && <CanvasCardTitle title={name} />}
        <div style={{ marginBottom: 24 }}>
          <Typography.Header type={'H5'}>
            Incompatible comparison selected
          </Typography.Header>
        </div>
        <ReactSVG
          src={FleetOpsGrey}
          style={{ marginBottom: 12 }}
          beforeInjection={(svg) => {
            svg.setAttribute('style', 'width: 63px');
            svg.setAttribute('style', 'height: 72px');
          }}
        />
        <Typography.Body type={'Body 14'}>
          Try using another comparison type
        </Typography.Body>
      </Wrapper>
    </FlexCentered>
  );
};

export default IncompatibleComparison;
