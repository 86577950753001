import React from 'react';
import DOMPurify from 'dompurify';

import IFrameGadget from './IFrameGadget';

const IFrameGadgetContainer = ({ snippet }: { snippet: string }) => {
  return (
    <IFrameGadget
      snippet={DOMPurify.sanitize(snippet, {
        ALLOWED_TAGS: ['iframe'],
      })}
    />
  );
};

export default IFrameGadgetContainer;
