import React, { ChangeEvent, useCallback, useContext, useState } from 'react';
import moment from 'moment';

import TaskForm from './TaskForm';
import CurrentUserContext from '../../../contexts/CurrentUserContext';
import { useCollaboratorNames } from '../../Goals/GeneralGoalForm';
import AnalyticsContext from '../../../contexts/AnalyticsContext';
import LocalTasksContext from '../../../contexts/Tasks/LocalTasksContext';
import WorkSpaceContext from '../../../contexts/WorkSpaceContext';
import LocalTimelineContext from '../../../contexts/Timeline/LocalTimelineContext';
import getIdentifier from '../../../getIdentifier';
import getTimeStamp from '../../../getTimeStamp';
import ToastContext from 'contexts/ToastContext';

const TaskFormContainer = ({
  task,
  close,
  onCancelled,
  hideTitle,
  destination,
}: {
  task?: Tasks.Task;
  close: () => void;
  onCancelled: () => void;
  hideTitle?: boolean;
  destination: Tasks.TaskSource;
}) => {
  const { onTaskCreated, onTaskUpdated, assignableUserIds } =
    useContext(LocalTasksContext);
  const { showToast } = useContext(ToastContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const { addEvent } = useContext(LocalTimelineContext);
  const currentUser = useContext(CurrentUserContext);
  const { workSpace, highlightTaskTab } = useContext(WorkSpaceContext);
  const [id] = useState<string>(task ? task.id : getIdentifier());
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [title, setTitle] = useState<string>(task ? task.title : '');
  const [description, setDescription] = useState<string>(
    task ? task.description : '',
  );
  const [dueDate, setDueDate] = useState<string>(
    task ? task.dueDate : moment().format('YYYY-MM-DD'),
  );
  const [assignedTo, setAssignedTo] = useState<string[]>(
    task ? [task.assignedTo] : [],
  );
  const assignedToName = useCollaboratorNames(assignedTo);

  const onTitleChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const onDescriptionChanged = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value);
  };

  const onDueDateChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setDueDate(event.target.value);
  };

  const isValid = title !== '' && assignedTo.length === 1;

  const createEditedTimelineEvents = useCallback(
    async (newTask: Tasks.Task) => {
      if (!addEvent || !task) {
        return;
      }

      const isTitleChanged = newTask.title !== task.title;
      if (isTitleChanged) {
        await addEvent({
          actionText: 'updated the title on',
          contentText: `${task.title} -> ${newTask.title}`,
          contextText: task.title,
        });
      }

      const isDueDateChanged = newTask.dueDate !== task.dueDate;
      if (isDueDateChanged) {
        await addEvent({
          actionText: 'updated the due date on',
          contentText: `${task.dueDate} -> ${newTask.dueDate}`,
          contextText: task.title,
        });
      }

      const isDescriptionChanged = newTask.description !== task.description;
      if (isDescriptionChanged) {
        await addEvent({
          actionText: 'updated the description on',
          contentText: `${task.description} -> ${newTask.description}`,
          contextText: task.title,
        });
      }
    },
    [addEvent, task],
  );

  const onSave = useCallback(async () => {
    if (title === '' || assignedTo.length !== 1) {
      return undefined;
    }
    setIsLoading(true);
    if (task) {
      if (!onTaskUpdated) {
        return;
      }
      const updatedTask = {
        ...task,
        title,
        description,
        dueDate,
        assignedTo: assignedTo[0],
        updatedBy: currentUser.id,
        updatedOn: getTimeStamp(),
        isComplete: task.isComplete,
      };
      onTaskUpdated(updatedTask, task).then(() => {
        createEditedTimelineEvents(updatedTask).then(() => {
          setIsLoading(false);
          close();
        });
      });
    } else {
      if (!onTaskCreated) {
        return;
      }
      const newTask = {
        id,
        title,
        description,
        dueDate,
        assignedTo: assignedTo[0],
        createdBy: currentUser.id,
        createdOn: getTimeStamp(),
        updatedBy: currentUser.id,
        updatedOn: getTimeStamp(),
        isComplete: false,
        taskableType: destination.type,
        taskableId: destination.id,
      };

      onTaskCreated(newTask).then(async () => {
        const workSpaceType = workSpace ? workSpace.campaignType : undefined;
        showToast('Task Added', 108);
        highlightTaskTab();
        trackEvent('Task - Created', {
          taskType: destination.type,
          workSpaceType,
        });

        if (addEvent) {
          await addEvent({
            actionText: 'created a task',
            contentText: newTask.description,
            contextText: newTask.title,
          });
        }

        setIsLoading(false);
        close();
      });
    }
  }, [
    title,
    assignedTo,
    task,
    onTaskUpdated,
    description,
    dueDate,
    currentUser.id,
    createEditedTimelineEvents,
    showToast,
    close,
    onTaskCreated,
    id,
    destination.type,
    destination.id,
    workSpace,
    highlightTaskTab,
    trackEvent,
    addEvent,
  ]);

  return (
    <TaskForm
      title={title}
      onTitleChanged={onTitleChanged}
      dueDate={dueDate}
      onDueDateChanged={onDueDateChanged}
      assignedTo={assignedTo}
      setAssignedTo={setAssignedTo}
      assignedToName={assignedToName}
      description={description}
      onDescriptionChanged={onDescriptionChanged}
      isValid={isValid}
      isEditing={!!task}
      onCancelled={onCancelled}
      onSave={onSave}
      isLoading={isLoading}
      assignableUserIds={assignableUserIds}
      hideTitle={hideTitle}
    />
  );
};

const Gate = ({
  task,
  close,
  onCancelled,
  hideTitle,
}: {
  task?: Tasks.Task;
  close: () => void;
  onCancelled: () => void;
  hideTitle?: boolean;
}) => {
  const { destination } = useContext(LocalTasksContext);
  if (!destination) {
    return null;
  }

  return (
    <TaskFormContainer
      close={close}
      onCancelled={onCancelled}
      destination={destination}
      task={task}
      hideTitle={hideTitle}
    />
  );
};

export default Gate;
