import React from 'react';
import styled from 'styled-components';

import Typography from 'kingpin/atoms/Typography';
import isoDateToAmerican from 'isoDateToAmerican';
import toSentenceCase from 'services/toSentenceCase';
import useValueFormatters from 'hooks/useValueFormatters';

const TooltipDiv = styled.div`
  min-width: 140px;
  padding: 12px;
  border-radius: 2px;
  box-shadow:
    0px 0px 0px 1px rgba(63, 63, 68, 0.07),
    0px 1px 3px 0px rgba(63, 63, 68, 0.12),
    0px 1px 12px 5px rgba(63, 63, 68, 0.1);
`;

const TEST_ID = 'dataset-search-trend-tooltip';

const Tooltip = ({
  point,
}: {
  point: DatasetTrendButton.TrendPoint | undefined;
}) => {
  const { formatField } = useValueFormatters();
  if (!point) {
    return null;
  }

  const { date, interval, y, field, dataset } = point;

  if (interval === undefined) {
    return null;
  }

  const formatted = formatField({ value: y, field, dataset });

  if (interval === 'day') {
    return (
      <TooltipDiv data-testid={TEST_ID}>
        <div style={{ marginBottom: 4 }}>
          <Typography.Header type="H5">
            {isoDateToAmerican(date)}
          </Typography.Header>
        </div>
        <Typography.Body type="Body 12">{formatted}</Typography.Body>
      </TooltipDiv>
    );
  }

  return (
    <TooltipDiv data-testid={TEST_ID}>
      <div style={{ marginBottom: 4 }}>
        <Typography.Header type="H5">{`${toSentenceCase(
          interval,
        )} starting ${isoDateToAmerican(date)}`}</Typography.Header>
      </div>
      <Typography.Body type="Body 12">{formatted}</Typography.Body>
    </TooltipDiv>
  );
};

export default Tooltip;
