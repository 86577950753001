import { useCallback, useContext } from 'react';
import store from 'store';

import getIdentifier from 'getIdentifier';
import getTimeStamp from 'getTimeStamp';
import AccountPickerContext from 'contexts/AccountPickerContext';
import CurrentUserContext from 'contexts/CurrentUserContext';
import SlideOutContext from 'components/PerformanceBoards/contexts/SlideOutContext';

const useCreateCommentsSection = () => {
  const { setNavState, board, flash } = useContext(SlideOutContext);
  const { selectedAccountId } = useContext(AccountPickerContext);
  const { id: currentUserId } = useContext(CurrentUserContext);

  const createCommentsSection = useCallback(() => {
    const commentsSection = {
      id: getIdentifier(),
      createdBy: currentUserId,
      updatedBy: currentUserId,
      createdOn: getTimeStamp(),
      updatedOn: getTimeStamp(),
      sectionType: 'Comments' as 'Comments',
      title: 'Comments',
    };

    const newSlideout = [{ ...commentsSection }, ...board.slideout];

    const newBoard = {
      ...board,
      slideout: newSlideout,
      updatedBy: currentUserId,
      updatedOn: getTimeStamp(),
    };

    store.contentDistributions
      .getPerformanceBoardsRef({
        accountId: selectedAccountId,
      })
      .doc(board.id)
      .set(newBoard)
      .then(() => {
        setNavState({
          slideoutMode: 'Home',
        });
        flash.setFlashMessage('Comments Added');
        flash.setIsVisible(true);
      });
  }, [board, currentUserId, flash, selectedAccountId, setNavState]);

  return createCommentsSection;
};

export default useCreateCommentsSection;
