import React, { useCallback } from 'react';
import useIsSelected from './hooks/useIsSelected';
import useIsCore from './hooks/useIsCore';
import Typography from 'kingpin/atoms/Typography';
import useMetricDatasetLabel from './hooks/useMetricdatasetLabel';
import Tooltip from '../../../Tooltip';
import PopupStyles from './PopupStyles';

const MetricListItem = ({
  metric,
  selectedMetricId,
  onMetricClicked,
}: {
  metric: Metrics.Metric;
  selectedMetricId?: string;
  onMetricClicked: (metric: Metrics.Metric) => void;
}) => {
  const isSelected = useIsSelected({ metric, selectedMetricId });
  const isCore = useIsCore({ metric, selectedMetricId });
  const { datasetLabel } = useMetricDatasetLabel(metric);
  const onClicked = useCallback(() => {
    onMetricClicked(metric);
  }, [metric, onMetricClicked]);

  return (
    <PopupStyles.PopupContent.ListItemDiv
      isSelected={isSelected}
      onClick={onClicked}
      data-testid={`select-${metric.id}`}
    >
      <PopupStyles.PopupContent.DetailsCol>
        <Tooltip content={metric.name}>
          <PopupStyles.PopupContent.EllipsisDiv>
            <Typography.Body type="Body 12">{metric.name}</Typography.Body>
          </PopupStyles.PopupContent.EllipsisDiv>
        </Tooltip>
        <PopupStyles.PopupContent.EllipsisDiv>
          <Typography.Body type="Body 12">{`Dataset: ${datasetLabel}`}</Typography.Body>
        </PopupStyles.PopupContent.EllipsisDiv>
        {metric.description !== '' && (
          <Tooltip content={metric.description}>
            <PopupStyles.PopupContent.EllipsisDiv>
              <Typography.Body type="Body 12">
                {metric.description}
              </Typography.Body>
            </PopupStyles.PopupContent.EllipsisDiv>
          </Tooltip>
        )}
      </PopupStyles.PopupContent.DetailsCol>

      <PopupStyles.PopupContent.StatusIcons
        isSelected={isSelected}
        isCore={isCore}
      />
    </PopupStyles.PopupContent.ListItemDiv>
  );
};
export default MetricListItem;
