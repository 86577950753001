import React, { useContext, useEffect, useState } from 'react';
import BaseViewsContext from 'contexts/BaseViewsContext';
import useSelectDefaultDatasetFilters from 'hooks/useSelectDefaultDatasetFilters';

const useDataSourceOptions = ({
  onDataTypeChanged,
  setDrillDowns,
}: {
  onDataTypeChanged: (newType: string) => void;
  setDrillDowns: React.Dispatch<React.SetStateAction<FilterPlate[]>>;
}) => {
  const { dataTypeOptions } = useContext(BaseViewsContext);
  const [dataSourceOptions, setDataSourceOptions] = useState<DropdownOption[]>(
    [],
  );
  const handleSelectDefaultDatasetFilters = useSelectDefaultDatasetFilters();

  useEffect(() => {
    setDataSourceOptions(
      dataTypeOptions.map(({ label, value }) => {
        return {
          label,
          value,
          onSelected: () => {
            const dataset = value;

            handleSelectDefaultDatasetFilters({
              dataset,
              setDrillDowns,
            });

            onDataTypeChanged(dataset);
          },
        };
      }),
    );
  }, [
    dataTypeOptions,
    handleSelectDefaultDatasetFilters,
    onDataTypeChanged,
    setDrillDowns,
  ]);

  return dataSourceOptions;
};

export default useDataSourceOptions;
