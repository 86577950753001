import STORE from '../../store';

const createMetric = async (
  metric: Metrics.NormalMetric,
  selectedAccountId: string,
) => {
  return STORE.metricDefinitions
    .getNormalMetricsRef({ accountId: selectedAccountId })
    .doc(metric.id)
    .set(metric);
};

export default createMetric;
