import React, { useContext } from 'react';
import styled from 'styled-components';
import { getColName } from '../ColumnHeadingsRight';
import ScorecardContext from 'contexts/ScorecardContext';
import TargetProgress from './TargetProgress';
import Typography from 'kingpin/atoms/Typography';
import Row from 'components/Common/Row';
import Colors2 from 'theme/Colors2';
import useValueFormatters from 'hooks/useValueFormatters';

const TooltipDiv = styled.div`
  min-width: 140px;
  padding: 4px 8px 2px 8px;
  border-radius: 2px;
  box-shadow:
    0px 0px 0px 1px rgba(63, 63, 68, 0.07),
    0px 1px 3px 0px rgba(63, 63, 68, 0.12),
    0px 1px 12px 5px rgba(63, 63, 68, 0.1);
`;

const RenderPoint = ({
  point,
}: {
  point: KpiTooltipChartTypes.KpiTooltipPoint;
}) => {
  const { scorecard } = useContext(ScorecardContext);
  const { formatMetric, formatValue } = useValueFormatters();

  if (point.type !== 'Non-Target') {
    const actual = point.actual;
    const isGood =
      actual !== undefined
        ? point.isColorOrderReversed
          ? actual <= point.target
          : actual > point.target
        : undefined;
    return (
      <>
        <div>
          <Typography.Body type="Label" color={Colors2.Grey['5']}>
            {`${getColName(point.period, scorecard.cadence)}`}
          </Typography.Body>
        </div>
        <Row>
          {point.actual ? (
            <div style={{ marginRight: 4 }}>
              <Typography.Body
                type="Body 14"
                color={
                  isGood ? Colors2.Secondary.success : Colors2.Primary['1']
                }
              >
                {point.metric
                  ? formatMetric({
                      metricId: point.metric.id,
                      value: point.actual,
                    })
                  : formatValue({
                      value: point.actual,
                      formatting: point.manualKpiFormatting || {},
                    })}
              </Typography.Body>
            </div>
          ) : (
            <div style={{ marginRight: 4 }}>
              <Typography.Body type="Body 14">-</Typography.Body>
            </div>
          )}
          <div style={{ marginRight: 4 }}>
            <Typography.Body type="Body 14">/</Typography.Body>
          </div>

          <Typography.Body type="Body 14">
            {point.metric
              ? formatMetric({
                  metricId: point.metric.id,
                  value: point.target,
                })
              : formatValue({
                  value: point.target,
                  formatting: point.manualKpiFormatting || {},
                })}
          </Typography.Body>
        </Row>

        <TargetProgress
          isReversed={point.isColorOrderReversed}
          successThreshold={point.target}
          value={point.actual}
          metric={point.metric}
          manualKpiFormatting={point.manualKpiFormatting}
        />
      </>
    );
  } else {
    return (
      <div>
        <div>
          <Typography.Body type="Label" color={Colors2.Grey['5']}>
            {`${getColName(point.period, scorecard.cadence)}`}
          </Typography.Body>
        </div>

        {point.actual ? (
          <Typography.Body type="Body 14">
            {point.metric
              ? formatMetric({
                  metricId: point.metric.id,
                  value: point.actual,
                })
              : formatValue({
                  value: point.actual,
                  formatting: point.manualKpiFormatting || {},
                })}
          </Typography.Body>
        ) : (
          <Typography.Body type="Body 14">-/</Typography.Body>
        )}
      </div>
    );
  }
};

const KpiTooltip = ({
  points,
}: {
  points: KpiTooltipChartTypes.KpiTooltipPoint[] | undefined;
}) => {
  if (!points || points.length === 0) {
    return null;
  }

  const thresholdPoint = points.find((p) => p.type === 'Threshold');
  const actualPoint = points.find((p) => p.type === 'Actual');
  const nonTargetPoint = points.find((p) => p.type === 'Non-Target');

  if (actualPoint) {
    return (
      <TooltipDiv style={{ backgroundColor: 'white' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
          <RenderPoint
            point={actualPoint}
            key={`${actualPoint.period.startDate}-${actualPoint.x}-${actualPoint.color}`}
          />
        </div>
      </TooltipDiv>
    );
  } else if (thresholdPoint) {
    return (
      <TooltipDiv style={{ backgroundColor: 'white' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
          <RenderPoint
            point={thresholdPoint}
            key={`${thresholdPoint.period.startDate}-${thresholdPoint.x}-${thresholdPoint.color}`}
          />
        </div>
      </TooltipDiv>
    );
  } else if (nonTargetPoint) {
    return (
      <TooltipDiv style={{ backgroundColor: 'white' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
          <RenderPoint
            point={nonTargetPoint}
            key={`${nonTargetPoint.period.startDate}-${nonTargetPoint.x}-${nonTargetPoint.color}`}
          />
        </div>
      </TooltipDiv>
    );
  } else {
    return null;
  }
};
export default KpiTooltip;
