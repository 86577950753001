import isDefined from './isDefined';
import captureException from './services/captureException';

const withMetricDefs = (
  compoundMetric: Metrics.CompoundMetric,
  normalMetrics: Metrics.NormalMetric[],
): Metrics.CompoundMetricWithMetricDefs => {
  const metricDefs = compoundMetric.metricIds
    .map((id) => normalMetrics.find((m) => m.id === id))
    .filter(isDefined);

  if (metricDefs.length !== compoundMetric.metricIds.length) {
    const e = new Error('Missing metrics!');
    e.name = `Missing metrics: ${compoundMetric.name}`;
    captureException(e);
  }

  const withMetricDefs = {
    ...compoundMetric,
    metrics: metricDefs,
  };
  // @ts-ignore
  delete withMetricDefs['metricIds'];

  return withMetricDefs;
};

export default withMetricDefs;
