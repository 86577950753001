import Label from './Label';
import Inputs from '../Inputs';
import React, { useContext } from 'react';
import V5GadgetFormContext from '../../contexts/V5GadgetFormContext';

const LimitInput = ({
  groupByLimit,
  onGroupByLimitChange,
}: {
  groupByLimit?: number;
  onGroupByLimitChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const { groupByLimitMode } = useContext(V5GadgetFormContext);
  if (groupByLimitMode === 'show all') {
    return null;
  }

  return (
    <div style={{ marginBottom: 8 }}>
      <Label>Limit</Label>
      <Inputs.TextInput
        type="number"
        value={groupByLimit ? groupByLimit.toString(10) : ''}
        onChange={onGroupByLimitChange}
      />
    </div>
  );
};

export default LimitInput;
