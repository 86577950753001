import React, { useContext, useEffect, useState } from 'react';
import Menu from './Menu';
import usePopup from '../../../../../hooks/usePopup';
import GoalsContext from '../../../../../contexts/GoalsContext';
import GoalForm from '../../../GeneralGoalForm';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import WorkSpaceContext from '../../../../../contexts/WorkSpaceContext';
import { isGeneralWorkSpace } from '../../../../../isWorkSpace';
import setWorkSpace from '../../../../../api/workspaces/setWorkSpace';
import AccountPickerContext from '../../../../../contexts/AccountPickerContext';

const MenuContainer = ({ goal }: { goal: GeneralGoal }) => {
  const { accountRef } = useContext(AccountPickerContext);
  const { isOpen, open, close } = usePopup();
  const { generalWorkSpace: workSpace } = useContext(WorkSpaceContext);
  const {
    isOpen: isEditFormOpen,
    open: openEditForm,
    close: closeEditForm,
  } = usePopup();
  const [isCopyingGoal, setIsCopyingGoal] = useState<boolean>(false);

  const { deleteGoal } = useContext(GoalsContext);

  const onEditClicked = () => {
    close();
    openEditForm();
  };

  const onDeleteClicked = () => {
    if (workSpace) {
      if (!isGeneralWorkSpace(workSpace)) {
        return;
      }
      const newWorkSpace: GeneralWorkSpace = {
        ...workSpace,
        goalIds: workSpace.goalIds.filter((gId) => gId !== goal.id),
      };
      setWorkSpace(newWorkSpace, accountRef);
    } else {
      if (window.confirm('This cannot be undone. Are you sure?')) {
        deleteGoal(goal.id).then(() => {});
      }
    }
  };

  const onCopyClicked = () => {
    close();
    setIsCopyingGoal(true);
    openEditForm();
  };

  useEffect(() => {
    if (!isEditFormOpen) {
      setIsCopyingGoal(false);
    }
  }, [isEditFormOpen]);

  return (
    <React.Fragment>
      <Menu
        isOpen={isOpen}
        open={open}
        close={close}
        onEditClicked={onEditClicked}
        onDeleteClicked={onDeleteClicked}
        onCopyClicked={onCopyClicked}
        isWorkspace={!!workSpace && isGeneralWorkSpace(workSpace)}
      />
      <ModalTransition>
        {isEditFormOpen && (
          <Modal shouldScrollInViewport={false} autoFocus={false}>
            <GoalForm
              close={closeEditForm}
              goal={goal}
              isCopyingGoal={isCopyingGoal}
            />
          </Modal>
        )}
      </ModalTransition>
    </React.Fragment>
  );
};

export default MenuContainer;
