import React, { useCallback, useEffect, useState } from 'react';
import { ROW_HEIGHTS } from '../components/Grid/StyleOverrides';
import { BREAK_POINTS } from '../mediaQueries';

const useGridRowHeight = () => {
  const [browserWidth, setBrowserWidth] = useState(window.innerWidth);
  const onResize = useCallback(() => {
    return setBrowserWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  const getRowHeight = useCallback(() => {
    if (browserWidth <= BREAK_POINTS.DESKTOP_XXS) {
      return ROW_HEIGHTS.XXS;
    } else if (browserWidth <= BREAK_POINTS.DESKTOP_XS) {
      return ROW_HEIGHTS.XS;
    } else if (browserWidth <= BREAK_POINTS.DESKTOP_S) {
      return ROW_HEIGHTS.S;
    } else if (browserWidth <= BREAK_POINTS.DESKTOP_M) {
      return ROW_HEIGHTS.M;
    } else {
      return ROW_HEIGHTS.L;
    }
  }, [browserWidth]);
  const [rowHeight, setRowHeight] = useState<number>(() => getRowHeight());

  useEffect(() => {
    setRowHeight(getRowHeight());
  }, [getRowHeight]);

  return rowHeight;
};

export const RowHeightContext = React.createContext({
  rowHeight: ROW_HEIGHTS.L,
});

/*
 * I can't figure out how to get --ag-row-height to work
 * So now this exists ¯\_(ツ)_/¯
 */
const RowHeightProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const rowHeight = useGridRowHeight();

  return (
    <RowHeightContext.Provider value={{ rowHeight }}>
      {children}
    </RowHeightContext.Provider>
  );
};

export default RowHeightProvider;
