import React, { useCallback, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import Button from 'kingpin/atoms/Button';
import Typography from 'kingpin/atoms/Typography';
import Row from '../../../../../Common/Row';
import Inputs from '../../../../../Inputs';
import Colors2 from '../../../../../../theme/Colors2';
import getDateBuckets, {
  buildWeeksLayout,
  findStartOfYear,
} from '../../../../../../screens/GoalShow/getDateBuckets';
import AccountContext from '../../../../../../contexts/AccountContext';
import Form from '../../../../../../kingpin/forms/Form';
import FormHeader from '../../../../../../kingpin/forms/FormHeader';
import FormContent from '../../../../../../kingpin/forms/FormContent';

const List = styled.div`
  overflow-y: auto;
  max-height: 50vh;
  padding-right: 16px;
  padding-left: 16px;
`;

const MONTHS_LAYOUT = [
  [
    { label: 'January', number: 1 },
    { label: 'July', number: 7 },
  ],
  [
    { label: 'February', number: 2 },
    { label: 'August', number: 8 },
  ],
  [
    { label: 'March', number: 3 },
    { label: 'September', number: 9 },
  ],
  [
    { label: 'April', number: 4 },
    { label: 'October', number: 10 },
  ],
  [
    { label: 'May', number: 5 },
    { label: 'November', number: 11 },
  ],
  [
    { label: 'June', number: 6 },
    { label: 'December', number: 12 },
  ],
];

const RowButton = styled(Row)`
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

const getPredictedKpiTarget = (
  advancedTarget: GoalAdvancedTarget,
  date: string,
) => {
  const tmpT = {
    ...advancedTarget,
    [date]: 0,
  };
  const allKeys = Object.keys(tmpT).sort();
  const thisKeyIndex = allKeys.findIndex((currentKey) => currentKey === date);

  if (thisKeyIndex === 0) {
    if (allKeys.length > 1) {
      const nextValueKey = allKeys[thisKeyIndex + 1];
      const v = tmpT[nextValueKey];

      if (v) {
        return v;
      }
    }
  } else {
    const previousValueKey = allKeys[thisKeyIndex - 1];
    const v = tmpT[previousValueKey];

    if (v) {
      return v;
    }
  }

  return 100;
};

// For use in the vis
export const getKpiTargetForMonth = (
  advancedTarget: GoalAdvancedTarget,
  date: string,
) => {
  if (advancedTarget[date] !== undefined) {
    return advancedTarget[date] as number;
  }

  return getPredictedKpiTarget(advancedTarget, date);
};

const MonthlyTargetBuilderContainer = ({
  startDate,
  endDate,
  interval,
  advancedTarget,
  setAdvancedTarget,
  onSave,
  onClose,
}: {
  startDate: string;
  endDate: string;
  interval: FleetOps.Interval;
  advancedTarget: GoalAdvancedTarget;
  setAdvancedTarget: React.Dispatch<React.SetStateAction<GoalAdvancedTarget>>;
  onSave: () => void;
  onClose: () => void;
}) => {
  const { weekStartsOn } = useContext(AccountContext);

  const [currentYear, setCurrentYear] = useState<number>(() => {
    const dates = getDateBuckets({
      startDate,
      endDate,
      interval,
      weekStartsOn,
    });
    if (dates.length > 0) {
      return moment(dates[0]).year();
    } else {
      return moment().year();
    }
  });

  const [layout, setLayout] = useState<
    { label: string; number: number; isoDate?: string }[][]
  >([]);

  const buildBucketsForYear = useCallback(
    (year: number) => {
      const dateBuckets = getDateBuckets({
        startDate,
        endDate,
        interval,
        weekStartsOn,
      });
      const yearStart = `${year}-01-01`;
      const yearEnd = `${year}-12-31`;
      return dateBuckets
        .filter((b) => b >= yearStart && b < yearEnd)
        .filter((b) => b >= startDate && b < endDate);
    },
    [endDate, interval, startDate, weekStartsOn],
  );

  const [eligibleBuckets, setEligibleBuckets] = useState<string[]>(
    buildBucketsForYear(currentYear),
  );

  useEffect(() => {
    if (interval === 'month') {
      setEligibleBuckets(buildBucketsForYear(currentYear));
    } else if (interval === 'week') {
      const newLayout = buildWeeksLayout({
        currentYear,
        weekStartsOn,
        interval,
        startDate: moment(`${currentYear}-01-01`).format('YYYY-MM-DD'),
        endDate,
      });
      const buckets = newLayout.reduce((a, b) => {
        if (b.length === 2) {
          return [...a, b[0].isoDate, b[1].isoDate];
        } else if (b.length === 1) {
          return [...a, b[0].isoDate];
        } else {
          return a;
        }
      }, [] as string[]);
      setEligibleBuckets(buckets);
    } else {
      setEligibleBuckets([]);
    }
  }, [
    buildBucketsForYear,
    currentYear,
    endDate,
    interval,
    startDate,
    weekStartsOn,
  ]);

  const hasPreviousYear = useCallback(() => {
    return buildBucketsForYear(currentYear - 1).length > 0;
  }, [buildBucketsForYear, currentYear]);

  const hasNextYear = useCallback(() => {
    return buildBucketsForYear(currentYear + 1).length > 0;
  }, [buildBucketsForYear, currentYear]);

  useEffect(() => {
    if (interval === 'month') {
      setLayout(MONTHS_LAYOUT);
    } else if (interval === 'week') {
      setLayout(
        buildWeeksLayout({
          currentYear,
          weekStartsOn,
          interval,
          startDate,
          endDate,
        }),
      );
    }
  }, [currentYear, endDate, interval, startDate, weekStartsOn]);

  const getKey = useCallback(
    (intervalNumber: number) => {
      if (interval === 'month') {
        if (intervalNumber < 10) {
          return `${currentYear}-0${intervalNumber}-01`;
        } else {
          return `${currentYear}-${intervalNumber}-01`;
        }
      } else if (interval === 'week') {
        const startOfYear = findStartOfYear(currentYear, weekStartsOn);
        return startOfYear.add({ week: intervalNumber }).format('YYYY-MM-DD');
      } else {
        return '';
      }
    },
    [currentYear, interval, weekStartsOn],
  );

  const getValue = useCallback(
    (month: number) => {
      const key = getKey(month);
      if (advancedTarget[key] !== undefined) {
        return advancedTarget[key];
      } else {
        return undefined;
      }
    },
    [advancedTarget, getKey],
  );

  const getPlaceholder = useCallback(
    (month: number) => {
      const key = getKey(month);
      if (advancedTarget[key]) {
        return undefined;
      } else {
        return getPredictedKpiTarget(advancedTarget, key).toString(10);
      }
    },
    [advancedTarget, getKey],
  );

  const onValueChanced = useCallback(
    (month: number) => (newValue: number | undefined) => {
      const key = getKey(month);
      if (newValue === undefined) {
        setAdvancedTarget((currentT) => {
          const newT = {
            ...currentT,
          };
          delete newT[key];
          return newT;
        });
      } else {
        setAdvancedTarget((currentT) => ({
          ...currentT,
          [key]: newValue,
        }));
      }
    },
    [getKey, setAdvancedTarget],
  );

  const getPairKey = useCallback(
    (
      pair: {
        label: string;
        number: number;
      }[],
    ) => {
      if (pair.length === 1) {
        return `${pair[0].label}-${pair[0].number}`;
      } else {
        return `${pair[0].label}-${pair[0].number}::${pair[1].label}-${pair[1].number}`;
      }
    },
    [],
  );

  return (
    <Form>
      <FormHeader title={'Advanced Setup'} onClose={onClose} />
      <FormContent>
        <Row
          spaceBetween
          style={{ backgroundColor: Colors2.Grey['8'], marginBottom: 8 }}
        >
          <div style={{ marginLeft: 8, width: 45 }}>
            {hasPreviousYear() && (
              <RowButton onClick={() => setCurrentYear((cy) => cy - 1)}>
                <Typography.Body type="Body 12">{`< ${
                  currentYear - 1
                }`}</Typography.Body>
              </RowButton>
            )}
          </div>
          <div>
            <Typography.Body type="Body 12">{currentYear}</Typography.Body>
          </div>
          <div style={{ marginRight: 8, width: 45 }}>
            {hasNextYear() && (
              <RowButton onClick={() => setCurrentYear((cy) => cy + 1)}>
                <Typography.Body type="Body 12">{`${
                  currentYear + 1
                } >`}</Typography.Body>
              </RowButton>
            )}
          </div>
        </Row>
        <List style={{ marginBottom: 48 }}>
          {layout.map((pair) => (
            <Row key={getPairKey(pair)} spaceBetween>
              <>
                {pair.map(({ label, number }, index) => (
                  <Row
                    key={getKey(number)}
                    centerAlign
                    style={{
                      flex: 1,
                      marginRight: index === 0 ? 32 : 0,
                    }}
                    spaceBetween
                  >
                    <MonthInputGate
                      date={getKey(number)}
                      eligibleBuckets={eligibleBuckets}
                    >
                      <Typography.Body type="Body 12">{label}</Typography.Body>
                      <div style={{ width: 160, marginBottom: 8 }}>
                        <Inputs.Number
                          testId={`${interval}-${number}`}
                          tabIndex={number}
                          key={getKey(number)}
                          placeholder={getPlaceholder(number)}
                          value={getValue(number)}
                          setValue={onValueChanced(number)}
                        />
                      </div>
                    </MonthInputGate>
                  </Row>
                ))}
                {pair.length === 1 && <Row style={{ flex: 1 }} />}
              </>
            </Row>
          ))}
        </List>
      </FormContent>

      <Row spaceBetween centerAlign style={{ marginBottom: 24 }}>
        <Button onClick={onClose} type="Ghost" size="Small" label="Cancel" />
        <Button
          onClick={onSave}
          testId={'save-adv-target'}
          type="Primary"
          size="Small"
          label="Save"
        />
      </Row>
    </Form>
  );
};

const MonthInputGate = ({
  date,
  eligibleBuckets,
  children,
}: {
  date: string;
  eligibleBuckets: string[];
  children: JSX.Element | JSX.Element[];
}) => {
  if (eligibleBuckets.includes(date)) {
    return <>{children}</>;
  }

  return null;
};

export default MonthlyTargetBuilderContainer;
