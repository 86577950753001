import React from 'react';

interface KpiSlideOutContextType {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  slideoutKpi: Scorecards.ScorecardKpi | Scorecards.ManualKpiRow | undefined;
  setSlideoutKpi: React.Dispatch<
    React.SetStateAction<
      Scorecards.ScorecardKpi | Scorecards.ManualKpiRow | undefined
    >
  >;
  slideoutPeriod: Period | undefined;
  setSlideoutPeriod: React.Dispatch<React.SetStateAction<Period | undefined>>;
  slideoutTrend: Goals.TrendResult[];
  setSlideoutTrend: React.Dispatch<React.SetStateAction<Goals.TrendResult[]>>;
  slideoutMetric: Metrics.Metric | undefined;
  setSlideoutMetric: React.Dispatch<
    React.SetStateAction<Metrics.Metric | undefined>
  >;
}

const SlideOutContext = React.createContext<KpiSlideOutContextType>(
  {} as KpiSlideOutContextType,
);

export default SlideOutContext;
