import React from 'react';
import styled from 'styled-components';
import CloseSvg from '../images/close-2.svg';
import DarkCloseSvg from '../images/close-2-dark.svg';
import Button from 'kingpin/atoms/Button';

const CloseImg = styled.img`
  width: 9px;
  height: 9px;
`;

export const CloseIcon = ({ isDark }: { isDark?: boolean }) => (
  <CloseImg src={isDark ? DarkCloseSvg : CloseSvg} />
);

const CloseButton = ({
  close,
  testId,
  isDisabled,
  isSaving,
}: {
  close: () => void;
  testId?: string;
  isNarrow?: boolean;
  isDark?: boolean;
  isSaving?: boolean;
  isDisabled?: boolean;
}) => (
  <Button
    isLoading={isSaving}
    isDisabled={isDisabled}
    onClick={close}
    icon="cross"
    testId={testId}
    type="Ghost"
    size="Small"
  />
);

export default CloseButton;
