import React, { useContext } from 'react';
import Row from 'components/Common/Row';
import Typography from 'kingpin/atoms/Typography';
import { Col, CostRowElement } from './ActivityGrid';
import useHoverRow from './hooks/useHoverRow';
import ActivityGridContext from '../context/ActivityGridContext';
import Colors2 from 'theme/Colors2';

const CategoryItemLeft = ({
  label,
  hoverId,
}: {
  label: string;
  hoverId: string;
}) => {
  const { triggerHover } = useHoverRow(hoverId);
  const { hoveredCategory } = useContext(ActivityGridContext);
  return (
    <CostRowElement
      onMouseEnter={triggerHover}
      centerAlign
      style={{
        width: '100%',
        borderBottom: `1px solid ${Colors2.GridBorder}`,
        borderLeft: `1px solid ${Colors2.GridBorder}`,
      }}
      isHovered={hoverId === hoveredCategory}
    >
      <Col maxWidth>
        <Row centerAlign spaceBetween style={{ width: '100%' }}>
          <Row centerAlign>
            <div
              style={{
                marginLeft: '24px',
                display: 'flex',
                paddingRight: '24px',
                whiteSpace: 'nowrap',
              }}
            >
              <Typography.Body type="Body 12">{label}</Typography.Body>
            </div>
          </Row>
        </Row>
      </Col>
    </CostRowElement>
  );
};

export default CategoryItemLeft;
