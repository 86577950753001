import { useContext } from 'react';
import DatasetDefinitionsContext from 'contexts/DatasetDefinitionsContext';

const useGetTMS = () => {
  const { datasets } = useContext(DatasetDefinitionsContext);
  const ICCDatasets = ['jobs', 'dispatches', 'movements'];
  const TMWDatasets = ['orders', 'legs', 'stops'];
  const McLeodDatasets = ['orders', 'movements', 'stops'];
  const TruckmateDatasets = ['orders', 'legsum', 'stops'];

  if (ICCDatasets.every((TMSDb) => datasets.find((db) => db.type === TMSDb))) {
    return { tms: 'ICC' as 'ICC', orders: ['Dispatches', 'Movements'] };
  } else if (
    TMWDatasets.every((TMSDb) => datasets.find((db) => db.type === TMSDb))
  ) {
    return { tms: 'TMW' as 'TMW', orders: ['Legs', 'Stops'] };
  } else if (
    McLeodDatasets.every((TMSDb) => datasets.find((db) => db.type === TMSDb))
  ) {
    return { tms: 'MCLeod' as 'MCLeod', orders: ['Movements', 'Stops'] };
  } else if (
    TruckmateDatasets.every((TMSDb) => datasets.find((db) => db.type === TMSDb))
  ) {
    return { tms: 'Truckmate' as 'Truckmate', orders: ['Legsum', 'Stops'] };
  } else {
    return { tms: 'ICC' as 'ICC', orders: ['Dispatches', 'Movements'] };
  }
};

export default useGetTMS;
