import { useCallback, useContext, useState } from 'react';
import UsersContext from 'contexts/UsersContext';
import AccountPickerContext from 'contexts/AccountPickerContext';
import userTypeCheckers from 'contextProviders/UsersProvider/userTypeCheckers';
import useLockedDebouncedEffect from 'hooks/useLockedDebouncedEffect';
import STORE from 'store';

const useSharedWith = ({
  type,
  typeId,
}: {
  type: 'report' | 'dashboard' | 'scorecard' | 'workSpace';
  typeId: string;
}) => {
  const { selectedAccountId } = useContext(AccountPickerContext);
  const { allUsers } = useContext(UsersContext);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sharedWith, setSharedWith] = useState<
    (UserManagement.SignedUpUser | UserManagement.PendingUser)[]
  >([]);

  const getSharedWith = useCallback(async (): Promise<
    (UserManagement.SignedUpUser | UserManagement.PendingUser)[]
  > => {
    setIsLoading(true);
    const users = allUsers.filter(userTypeCheckers.isSignedUpOrPendingUser);
    const promises = users.map(async (user) => {
      const sharedDoc = await STORE.users
        .getSharedContentRef({
          accountId: selectedAccountId,
          userId: user.id,
        })
        .get();

      const sharedContent = sharedDoc.data();
      if (!sharedContent) {
        return {
          user,
          isShared: false,
        };
      }

      const isShared = (() => {
        if (type === 'dashboard') {
          return sharedContent.dashboardIds.includes(typeId);
        } else if (type === 'report') {
          return sharedContent.reportIds.includes(typeId);
        } else if (type === 'scorecard') {
          return sharedContent.scorecardIds.includes(typeId);
        } else if (type === 'workSpace') {
          return sharedContent.workspaceIds.includes(typeId);
        } else {
          return false;
        }
      })();

      return {
        user,
        isShared,
      };
    });
    const results = await Promise.all(promises);
    const newSharedWith = results.filter((r) => r.isShared).map((r) => r.user);
    return newSharedWith;
  }, [allUsers, selectedAccountId, type, typeId]);

  const responseHandler = useCallback(
    (
      newSharedWith: (
        | UserManagement.SignedUpUser
        | UserManagement.PendingUser
      )[],
    ) => {
      setSharedWith(newSharedWith);
      setIsLoading(false);
    },
    [],
  );

  useLockedDebouncedEffect({
    callback: getSharedWith,
    responseHandler,
    args: window.emptyObject,
  });

  const refreshSharedWith = useCallback(() => {
    setIsLoading(true);
    getSharedWith().then(responseHandler);
  }, [getSharedWith, responseHandler]);

  return {
    sharedWith,
    isLoadingSharedWith: isLoading,
    refreshSharedWith,
  };
};

export default useSharedWith;
