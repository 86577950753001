import React, { useCallback, useContext } from 'react';
import StepHeader from './StepHeader';
import { AccordionContent } from './ConfigSteps';
import CostsWizardContext from '../context/CostsWizardContext';
import Typography from 'kingpin/atoms/Typography';
import Row from 'components/Common/Row';
import Dropdown from 'components/Inputs/Dropdown';
import useCategoriesOptions from './hooks/useCategoriesOptions';
import Colors2 from 'theme/Colors2';
import Column from 'screens/DataManager/DatasetDefinitions/Column';
import Button from 'kingpin/atoms/Button';

const CategoryListItem = ({
  category,
  setWizardState,
  isEditing,
  isDraft,
}: {
  category: Costs.CostCategoryField;
  setWizardState: React.Dispatch<React.SetStateAction<Costs.WizardState>>;
  isEditing: boolean;
  isDraft: boolean;
}) => {
  const onRemoveClicked = useCallback(() => {
    setWizardState((wizardState) => ({
      ...wizardState,
      config: {
        ...wizardState.config,
        categoriesStep: {
          categories: wizardState.config.categoriesStep.categories.filter(
            (cat) => cat !== category,
          ),
        },
      },
    }));
  }, [category, setWizardState]);

  return (
    <Row
      centerAlign
      spaceBetween
      style={{
        border: `1px solid ${Colors2.Grey['8']}`,
        marginTop: '-1px',
      }}
    >
      <Column style={{ minHeight: 42 }}>
        <div style={{ marginLeft: 16, display: 'flex' }}>
          <Typography.Body type="Body 12">{category.name}</Typography.Body>
        </div>
      </Column>
      {(!isEditing || isDraft) && (
        <div style={{ marginRight: '8px' }}>
          <Button
            type="Ghost"
            size="Small"
            testId={`remove-cat-${category.name}`}
            onClick={onRemoveClicked}
            icon={'cross'}
          />
        </div>
      )}
    </Row>
  );
};

const CategoriesStep = () => {
  const { wizardState, setWizardState, isEditing, hasEditPermission } =
    useContext(CostsWizardContext);

  const { options: categoriesOptions, isLoading } = useCategoriesOptions();

  return (
    <>
      <StepHeader headerStep={'categories'} headerText="3. Cost Categories" />
      <AccordionContent
        isActive={wizardState.currentStep === 'categories'}
        style={{
          height: wizardState.currentStep === 'categories' ? '360px' : '0',
          transition: 'height: .2s  ease-out',
        }}
      >
        <div style={{ padding: '32px' }}>
          <div style={{ marginBottom: '4px' }}>
            <Typography.Header type="H5">
              Add additional Cost Categories e.g Fuel Cost
            </Typography.Header>
          </div>
          <Row style={{ marginBottom: '24px' }}>
            <Dropdown
              options={categoriesOptions}
              placeholder="Select Category"
              isDisabled={!hasEditPermission}
            />
          </Row>
          {!isLoading &&
            wizardState.config.categoriesStep.categories.length !== 0 && (
              <div style={{ marginBottom: '24px' }}>
                <Row centerAlign style={{ marginBottom: '8px' }}>
                  <div style={{ marginRight: 4 }}>
                    <Typography.Header type="H5">Added Costs</Typography.Header>
                  </div>

                  <Typography.Header type="H5" color={Colors2.Grey['4']}>
                    {`(${wizardState.config.categoriesStep.categories.length})`}
                  </Typography.Header>
                </Row>
                <div
                  style={{
                    maxHeight: '139px',
                    overflow: 'scroll',
                    paddingTop: '1px',
                  }}
                >
                  {wizardState.config.categoriesStep.categories.map((cat) => (
                    <CategoryListItem
                      key={cat.fieldName}
                      category={cat}
                      setWizardState={setWizardState}
                      isEditing={isEditing}
                      isDraft={wizardState.isDraft}
                    />
                  ))}
                </div>
              </div>
            )}
          <Row style={{ justifyContent: 'flex-end' }}>
            <Button
              type="Tertiary"
              size="Small"
              testId="cost-next-categories"
              onClick={() => {
                setWizardState((s) => ({
                  ...s,
                  currentStep: 'outputs',
                }));
              }}
              label="Next Step"
            />
          </Row>
        </div>
      </AccordionContent>
    </>
  );
};

export default CategoriesStep;
