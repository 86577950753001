import { useCallback, useEffect, useState } from 'react';
import metricTypeCheckers from '../../../../../types/metricTypeCheckers';

const useIsCore = ({
  metric,
  selectedMetricId,
}: {
  metric: Metrics.Metric;
  selectedMetricId?: string;
}) => {
  const getIsCore = useCallback(
    () =>
      metricTypeCheckers.isNormalMetric(metric) ||
      metricTypeCheckers.isCompoundMetric(metric)
        ? metric.status === 'core'
        : false,
    [metric],
  );
  const [isCore, setIsCore] = useState<boolean>(
    !!selectedMetricId && selectedMetricId === metric.id,
  );
  useEffect(() => {
    setIsCore(getIsCore());
  }, [getIsCore]);

  return isCore;
};

export default useIsCore;
