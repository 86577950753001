import { useCallback, useContext } from 'react';
import AnalyticsContext from '../../../../contexts/AnalyticsContext';

const useTrackSettingsChanged = ({
  field,
  baseView,
}: {
  field: FleetOps.Field;
  baseView: FleetOps.BaseView;
}) => {
  const { trackEvent } = useContext(AnalyticsContext);

  const trackSettingsChanged = useCallback(() => {
    trackEvent('Datasets - Field - Settings Changed', {
      datasetName: baseView.type,
      field: field.field,
    });
  }, [baseView.type, field.field, trackEvent]);

  return trackSettingsChanged;
};

export default useTrackSettingsChanged;
