import React, { useCallback, useContext } from 'react';
import Button from 'kingpin/atoms/Button';
import DragHandleContext from './DragHandleContext';
import DragAndDropListContext from './DragAndDropListContext';

const DragHandle = ({
  testId,
  noMargin,
  setIsItemDragging,
}: {
  testId?: string;
  noMargin?: boolean;
  setIsItemDragging?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { dragHandleProps, draggableProps } = useContext(DragHandleContext);
  const { setIsDragging } = useContext(DragAndDropListContext);

  const onMouseDown = useCallback(
    (event: React.MouseEvent<any>) => {
      if (!dragHandleProps) {
        return;
      }

      setIsDragging(true);
      if (setIsItemDragging) {
        setIsItemDragging(true);
      }
      if (dragHandleProps.onMouseDown) {
        dragHandleProps.onMouseDown(event);
      }
    },
    [dragHandleProps, setIsItemDragging, setIsDragging],
  );

  const onTouchStart = useCallback(
    (event: React.TouchEvent<any>) => {
      if (!dragHandleProps) {
        return;
      }

      setIsDragging(true);
      if (setIsItemDragging) {
        setIsItemDragging(true);
      }

      if (dragHandleProps.onTouchStart) {
        dragHandleProps.onTouchStart(event);
      }
    },
    [dragHandleProps, setIsItemDragging, setIsDragging],
  );

  const onMouseUp = useCallback(() => {
    if (!dragHandleProps) {
      return;
    }

    setIsDragging(false);
  }, [dragHandleProps, setIsDragging]);

  const onTouchEnd = useCallback(() => {
    if (!dragHandleProps) {
      return;
    }

    setIsDragging(false);
  }, [dragHandleProps, setIsDragging]);

  if (!dragHandleProps || !draggableProps) {
    return null;
  }

  return (
    <div
      {...dragHandleProps}
      {...draggableProps}
      style={{ marginRight: noMargin ? 0 : 8 }}
      data-testid={testId}
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      onMouseUp={onMouseUp}
      onTouchEnd={onTouchEnd}
    >
      <Button type="Ghost" size="Small" icon="drag" />
    </div>
  );
};

export default DragHandle;
