import { useState } from 'react';

const useIsFireTv = () => {
  const [isFireTv] = useState<boolean>(() =>
    /\bSilk\b/.test(navigator.userAgent),
  );

  return isFireTv;
};

export default useIsFireTv;
