import React from 'react';
import styled from 'styled-components';
import CreateComment from './CreateComment';
import CommentList from './CommentList';

const Wrapper = styled.div``;

const Comments = ({
  inputOnTop,
  disableBottomBorder,
  emailBodyLn1Extra,
  mentionedEmailBody,
  watcherEmailBody,
  emailLink,
  noScroll,
  sendButtonText,
}: {
  inputOnTop?: boolean;
  disableBottomBorder?: boolean;
  mentionedEmailBody: string;
  watcherEmailBody: string;
  emailBodyLn1Extra?: string;
  emailLink: string;
  noScroll?: boolean;
  sendButtonText?: string;
}) => (
  <Wrapper>
    {inputOnTop && (
      <CreateComment
        mentionedEmailBody={mentionedEmailBody}
        watcherEmailBody={watcherEmailBody}
        link={emailLink}
        emailBodyLn1Extra={emailBodyLn1Extra}
        sendButtonText={sendButtonText}
      />
    )}
    <CommentList isReversed={!!inputOnTop} noScroll={noScroll} />
    {!inputOnTop && (
      <CreateComment
        disableBottomBorder={disableBottomBorder}
        mentionedEmailBody={mentionedEmailBody}
        watcherEmailBody={watcherEmailBody}
        emailBodyLn1Extra={emailBodyLn1Extra}
        link={emailLink}
        sendButtonText={sendButtonText}
      />
    )}
  </Wrapper>
);

export default Comments;
