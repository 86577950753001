import React from 'react';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

interface GqlClientContextType {
  client: ApolloClient<NormalizedCacheObject>;
}

const GqlClientContext = React.createContext<GqlClientContextType>({
  // @ts-ignore
  client: {},
});

export default GqlClientContext;
