import isV5ChartDef from './isV5ChartDef';

const isMetricList = (
  def?: ExtendedVisualisationDefinition,
): def is MetricListGadgetType => {
  if (isV5ChartDef(def)) {
    return false;
  }

  if (!def) {
    return false;
  }

  return def.type === 'Metric List';
};

export default isMetricList;
