import React from 'react';
import styled from 'styled-components';

import Colors from '../../theme/colors';
import Row from '../Common/Row';
import useValueFormatters from '../../hooks/useValueFormatters';
import Icon from '../../kingpin/atoms/Icon';
import Typography from '../../kingpin/atoms/Typography';

const MEDIUM = 16;
const SMALL = 12;

export const ArrowBackdrop = styled.div<{
  isGood: boolean;
  isGrid: boolean;
}>`
  width: ${(props) => (props.isGrid ? SMALL : MEDIUM)}px;
  height: ${(props) => (props.isGrid ? SMALL : MEDIUM)}px;
  border-radius: ${(props) => (props.isGrid ? SMALL / 2 : MEDIUM / 2)}px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  background-color: ${(props) =>
    props.isGood ? `${Colors.MEDIUM_SEA_GREEN}20` : `${Colors.RADICAL_RED}20`};
`;

export const DeltaNumber = ({
  isGood,
  isGrid,
  children,
}: {
  isGood: boolean;
  isGrid: boolean;
  children: JSX.Element | JSX.Element[] | null | undefined | string;
}) => {
  if (children === null) {
    return null;
  }

  if (isGrid) {
    return (
      <div style={{ display: 'flex' }}>
        <span
          style={{
            color: isGood ? Colors.MEDIUM_SEA_GREEN : Colors.RADICAL_RED,
          }}
        >
          {children}
        </span>
      </div>
    );
  }

  return (
    <div style={{ display: 'flex' }}>
      <Typography.Body
        type={'Body Bold'}
        color={isGood ? Colors.MEDIUM_SEA_GREEN : Colors.RADICAL_RED}
      >
        {children}
      </Typography.Body>
    </div>
  );
};

export const ArrowElem = ({
  isGood,
  delta,
  isGrid,
}: {
  isGood: boolean;
  delta: number;
  isGrid: boolean;
}) => (
  <ArrowBackdrop isGood={isGood} isGrid={isGrid}>
    {delta >= 0 &&
      (isGood ? (
        <Icon
          icon={'arrow-up'}
          color={Colors.MEDIUM_SEA_GREEN}
          width={isGrid ? SMALL : MEDIUM}
          height={isGrid ? SMALL : MEDIUM}
        />
      ) : (
        <Icon
          icon={'arrow-up'}
          color={Colors.RADICAL_RED}
          width={isGrid ? SMALL : MEDIUM}
          height={isGrid ? SMALL : MEDIUM}
        />
      ))}
    {delta < 0 &&
      (isGood ? (
        <Icon
          icon={'arrow-down'}
          color={Colors.MEDIUM_SEA_GREEN}
          width={isGrid ? SMALL : MEDIUM}
          height={isGrid ? SMALL : MEDIUM}
        />
      ) : (
        <Icon
          icon={'arrow-down'}
          color={Colors.RADICAL_RED}
          width={isGrid ? SMALL : MEDIUM}
          height={isGrid ? SMALL : MEDIUM}
        />
      ))}
  </ArrowBackdrop>
);

const DeltaNumberElem = ({
  isRaw,
  isGood,
  delta,
  rawDelta,
  isBoth,
  isGrid,
  metricId,
  formatMetricOverride,
}: {
  isRaw?: boolean;
  isGood: boolean;
  delta: number;
  metricId: string;
  rawDelta?: number;
  isBoth?: boolean;
  isGrid: boolean;
  // Certain uses of this element are outside of the context tree,
  // such as a highchart tooltip. For these cases, we need to inject this
  // method instead of using the hook.
  formatMetricOverride?: (args: {
    metricId: string;
    value: string | number | null | undefined;
  }) => string;
}) => {
  const { formatMetric } = useValueFormatters();
  if (isBoth && rawDelta) {
    const n = (() => {
      return formatMetricOverride
        ? formatMetricOverride({
            metricId,
            value: rawDelta,
          })
        : formatMetric({
            metricId,
            value: rawDelta,
          });
    })();

    const d = Math.abs(delta);
    return (
      <DeltaNumber
        isGood={isGood}
        isGrid={isGrid}
      >{`${n} (${d}%)`}</DeltaNumber>
    );
  }

  return (
    <React.Fragment>
      {isRaw && (
        <DeltaNumber isGood={isGood} isGrid={isGrid}>
          {formatMetric({ value: Math.abs(delta), metricId })}
        </DeltaNumber>
      )}
      {!isRaw && (
        <DeltaNumber
          isGood={isGood}
          isGrid={isGrid}
        >{`${Math.abs(delta)}%`}</DeltaNumber>
      )}
    </React.Fragment>
  );
};

const Delta = ({
  isGood,
  delta,
  mobile,
  isGrid,
  isRaw,
  rawDelta,
  isBoth,
  leftAlign,
  metricId,
  formatMetricOverride,
}: {
  isGood: boolean;
  delta: number;
  mobile?: boolean;
  isGrid: boolean;
  isRaw?: boolean;
  rawDelta?: number;
  isBoth?: boolean;
  leftAlign?: boolean;
  metricId: string;
  // Certain uses of this element are outside of the context tree,
  // such as a highchart tooltip. For these cases, we need to inject this
  // method instead of using the hook.
  formatMetricOverride?: (args: {
    metricId: string;
    value: string | number | null | undefined;
  }) => string;
}) => {
  if (delta === Infinity || Number.isNaN(delta)) {
    return null;
  }

  return (
    <Row
      centerAlign
      style={{
        width: mobile || isGrid ? 'unset' : '100%',
        justifyContent: leftAlign ? 'flexStart' : 'center',
      }}
    >
      <Row
        centerAlign
        style={{
          width: mobile || isGrid ? 'unset' : '100%',
          justifyContent: leftAlign ? 'flexStart' : 'center',
        }}
      >
        <ArrowElem isGood={isGood} delta={delta} isGrid={isGrid} />
        <DeltaNumberElem
          delta={delta}
          isGood={isGood}
          isRaw={isRaw}
          rawDelta={rawDelta}
          isGrid={isGrid}
          isBoth={isBoth}
          metricId={metricId}
          formatMetricOverride={formatMetricOverride}
        />
      </Row>
    </Row>
  );
};

export default Delta;
