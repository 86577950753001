import React from 'react';
import Typography from 'kingpin/atoms/Typography';
import CreatingBadge from '../../../Badges/CreatingBadge';
import ErrorBadge from '../../../Badges/ErrorBadge';
import ItemRow from '../ItemRow';
import usePopup from '../../../../../../hooks/usePopup';
import useInitialWizardState from '../../../DatasetShow/AddPerformanceDatasetFieldsButton/useInitialWizardState';
import { ModalTransition } from '@atlaskit/modal-dialog';
import PerformanceDatasetWizard from '../../PerformanceDatasetWizard';

const NoBaseViewItem = ({
  config,
  dataType,
  status,
}: {
  config: FleetOps.PerformanceDatasetConfig;
  dataType?: string;
  status?: FleetOps.PerformanceDatasetStatus;
}) => {
  const { isOpen, open, close } = usePopup();
  const initialWizardState = useInitialWizardState(config);

  return (
    <>
      <ItemRow
        datasetCol={
          <>
            <Typography.Body type="Link" onClick={open}>
              {dataType}
            </Typography.Body>
            {status && status === 'creating' && <CreatingBadge />}
            {status && status === 'error' && <ErrorBadge />}
          </>
        }
      />
      <ModalTransition>
        {isOpen && (
          <PerformanceDatasetWizard
            close={close}
            initialState={initialWizardState}
            isReadOnly
          />
        )}
      </ModalTransition>
    </>
  );
};

export default NoBaseViewItem;
