import React from 'react';
import Spinner from 'components/AtlassianTemp/Spinner';
import Tooltip from '../../../../../Tooltip';

import { Line } from 'rc-progress';
import Row from '../../../../../Common/Row';
import getColor from '../../getColor';

import styled from 'styled-components';
import Column from '../../Column';
import Loading from '../../../../../Loading';
import Flexed from '../../Flexed';
import Typography from 'kingpin/atoms/Typography';
import FlexCentered from '../../../../../Common/FlexCentered';
import Icon from '../../../../../../kingpin/atoms/Icon';
import Colors2 from '../../../../../../theme/Colors2';

const FlexTooltip = styled.div`
  width: 100%;
  height: 100%;

  > div {
    width: 100%;
    height: 100%;
  }
  .inner-tooltip {
    width: 100%;
    height: 100%;
  }
`;

export const FlexedWrapper = ({
  isCard,
  flex,
  noPadding,
  spaceBetween,
  children,
}: {
  isCard?: boolean;
  flex: number;
  noPadding?: boolean;
  spaceBetween?: boolean;
  children: JSX.Element | JSX.Element[];
}) => {
  if (isCard) {
    return <div style={{ width: '100%' }}>{children}</div>;
  } else {
    return (
      <Flexed
        flex={flex}
        style={{
          padding: noPadding ? 0 : undefined,
          justifyContent: spaceBetween ? 'space-between' : undefined,
        }}
      >
        {children}
      </Flexed>
    );
  }
};

const ProgressBar = ({
  currentValue,
  performanceText,
  aboveTargetIsGood,
  progressPercent,
  remainingIntervals,
  isLoading,
  isAboveTargetForNow,
  remaining,
  noPadding,
  isCard,
}: {
  currentValue?: string;
  performanceText: string;
  aboveTargetIsGood: boolean;
  isAboveTarget: boolean;
  progressPercent: number;
  remainingIntervals: string;
  isLoading: boolean;
  targetValueForNow: string;
  isAboveTargetForNow: boolean;
  remaining: string;
  noPadding?: boolean;
  isCard?: boolean;
}) => (
  <React.Fragment>
    {isCard && (
      <Row centerAlign style={{ marginBottom: 16 }}>
        <div style={{ marginRight: 8 }}>
          <Typography.Header type="H5">Goal Progress</Typography.Header>
        </div>
        <Typography.Header
          type="H5"
          color={Colors2.Grey['5']}
        >{`(${remainingIntervals})`}</Typography.Header>
      </Row>
    )}
    {isLoading && (
      <FlexCentered
        style={{ marginTop: isCard ? 32 : 0, height: 'calc(100% - 38px)' }}
      >
        <Flexed flex={7}>
          <Loading isSmall />
        </Flexed>
      </FlexCentered>
    )}
    {!isLoading && (
      <FlexCentered
        style={{
          flex: 7,
          height: 'calc(100% - 38px)',
          width: '100%',
          flexDirection: isCard ? 'column' : 'row',
        }}
      >
        <FlexedWrapper flex={3} noPadding={noPadding} isCard={isCard}>
          <Column style={{ width: '100%', alignItems: 'center' }}>
            <>
              {currentValue !== undefined && (
                <Typography.Body type={'Body 14'}>
                  <b>{currentValue}</b>
                </Typography.Body>
              )}
              {currentValue === undefined && <Spinner />}
            </>
          </Column>
          <Column style={{ width: '100%', alignItems: 'center' }}>
            <Row centerAlign>
              {isAboveTargetForNow && (
                <Icon
                  icon="arrow-up"
                  color={getColor(isAboveTargetForNow, aboveTargetIsGood)}
                />
              )}
              {!isAboveTargetForNow && (
                <Icon
                  icon="arrow-down"
                  color={getColor(isAboveTargetForNow, aboveTargetIsGood)}
                />
              )}

              <Typography.Header
                type={'H5'}
                color={getColor(isAboveTargetForNow, aboveTargetIsGood)}
              >
                {performanceText}
              </Typography.Header>
            </Row>
          </Column>
        </FlexedWrapper>
        <FlexedWrapper
          flex={4}
          spaceBetween
          noPadding={noPadding}
          isCard={isCard}
        >
          <FlexTooltip>
            <Tooltip content={`Remaining: ${remaining}`}>
              <div className={'inner-tooltip'}>
                <Column>
                  <Line
                    percent={progressPercent}
                    strokeWidth={3}
                    trailWidth={3}
                    strokeColor={getColor(
                      isAboveTargetForNow,
                      aboveTargetIsGood,
                    )}
                  />
                </Column>
                {!isCard && (
                  <>
                    <Column>
                      <Typography.Header type={'H5'} color={Colors2.Grey['5']}>
                        {remainingIntervals}
                      </Typography.Header>
                    </Column>
                  </>
                )}
              </div>
            </Tooltip>
          </FlexTooltip>
        </FlexedWrapper>
      </FlexCentered>
    )}
  </React.Fragment>
);

export default ProgressBar;
