import React from 'react';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import DatePicker from 'components/AtlassianTemp/DateTimePicker';
import { DateTime } from 'luxon';
import Typography from 'kingpin/atoms/Typography';

import Row from '../../Common/Row';
import Button from 'kingpin/atoms/Button';
import { InputWrapper } from '../NewCommitPopup/NewCommitmentSlideOut/styles';
import DatePickerIconFix from '../../DatePickerIconFix';
import colors from '../../../theme/colors';
import WarningTextDiv from '../NewCommitPopup/NewCommitmentSlideOut/WarningTextDiv';
import Icon from '../../../kingpin/atoms/Icon';
import Colors2 from '../../../theme/Colors2';

const EndCommitmentPopup = ({
  isOpen,
  close,
  onEndConfirmed,
  endDate,
  onEndDateChanged,
  isSaving,
}: {
  isOpen: boolean;
  close: () => void;
  onEndConfirmed: () => void;
  endDate?: string;
  onEndDateChanged: (newEndDate: string) => void;
  isSaving: boolean;
}) => (
  <ModalTransition>
    {isOpen && (
      <Modal onClose={close} shouldScrollInViewport={false} autoFocus={false}>
        <div style={{ padding: '16px 24px' }}>
          <div style={{ marginBottom: 24 }}>
            <Typography.Header type={'H5'}>End Commitment</Typography.Header>
          </div>
          <InputWrapper>
            <div style={{ marginBottom: 4 }}>
              <Typography.Body type={'Label'}>End Date</Typography.Body>
            </div>
            <DatePickerIconFix>
              <DatePicker
                innerProps={{
                  style: {
                    width: '100%',
                    border: '1px solid #dfe1e5',
                    borderRadius: 3,
                  },
                }}
                value={endDate}
                onChange={onEndDateChanged}
                placeholder={DateTime.local()
                  .startOf('day')
                  .plus({ year: 1 })
                  .toISODate()}
                appearance="subtle"
              />
            </DatePickerIconFix>
          </InputWrapper>
          <WarningTextDiv>
            <Row centerAlign>
              <Icon icon="warning" color={colors.RADICAL_RED} />
              <Typography.Body type={'Body 14'} color={Colors2.Secondary.error}>
                Warning:
              </Typography.Body>
            </Row>
            <Typography.Body type={'Body 14'}>
              This commitment will be terminated on the end date specified.
            </Typography.Body>
            <br />
            <Typography.Body type={'Body 14'}>
              All history from effective start date to the end date specified
              will be preserved.
            </Typography.Body>
            <br />
            <Typography.Body type={'Body 14'}>
              Anything outside this will be deleted
            </Typography.Body>
          </WarningTextDiv>
          <Row style={{ marginBottom: 24 }} spaceBetween centerAlign>
            <Button
              onClick={close}
              type="Secondary"
              size="Small"
              label="Cancel"
            />
            <Button
              type="Primary"
              size="Small"
              onClick={onEndConfirmed}
              isDisabled={isSaving}
              isLoading={isSaving}
              label="Confirm"
            />
          </Row>
        </div>
      </Modal>
    )}
  </ModalTransition>
);

export default EndCommitmentPopup;
