import React, { useContext, useEffect, useState } from 'react';
import CustomerLaneSlideOut from './SlideOut';
import CustomerLaneSlideOutContext from '../../../contexts/CustomerLaneSlideOut';
import Loading from '../../Loading';
import useBoardMetricFilter from '../../../hooks/useBoardMetricFilter';
import AnalyticsContext from '../../../contexts/AnalyticsContext';
import BoardContext from '../../../contexts/BoardContext';

const SlideOutContainer = () => {
  const { board } = useContext(BoardContext);
  const { trackEvent } = useContext(AnalyticsContext);
  const { lane, documentId } = useContext(CustomerLaneSlideOutContext);
  const [activeNavElement, setActiveNavElement] =
    useState<CustomerLaneSlideOutNavElement>('commitment');
  const [config] = useState<TargetVisConfig>({
    title: 'Performance',
    titleLabel: 'Loads completed',
    totalField: 'contractTotalLoads',
    targetField: 'contractCommitment',
    differenceField: 'contractDifferenceInProgress',
    cadenceTotalField: 'totalLoads',
    cadenceTargetField: 'commitment',
    cadenceDifferenceField: 'difference',
    targetHeader: 'Target',
    totalHeader: 'Total Loads',
    differenceHeader: 'Difference',
  });
  const {
    filterInput: gridFilter,
    dateScope: gridDateScope,
    isLoading: isLoadingGridFilter,
  } = useBoardMetricFilter(documentId, true);
  const {
    filterInput: chartFilter,
    dateScope: chartDateScope,
    isLoading: isLoadingChartFilter,
  } = useBoardMetricFilter(documentId, false);

  useEffect(() => {
    trackEvent('Board - Slide out opened', {
      boardName: board.name,
      boardId: board.id,
      boardDataType: board.dataType,
    });
  }, [board.dataType, board.id, board.name, trackEvent]);

  const onNavScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const elem = event.currentTarget;
    const commitmentElem = document.getElementById('details-slide-commitment');
    const performanceElem = document.getElementById(
      'details-slide-performance',
    );
    const commentsElem = document.getElementById('details-slide-comments');

    if (!commitmentElem || !performanceElem || !commentsElem) {
      return;
    }

    if (
      elem.scrollTop >=
      commitmentElem.clientHeight + performanceElem.clientHeight
    ) {
      setActiveNavElement('comments');
    } else if (elem.scrollTop >= commitmentElem.clientHeight) {
      setActiveNavElement('performance');
    } else {
      setActiveNavElement('commitment');
    }
  };

  if (!lane || isLoadingGridFilter || isLoadingChartFilter) {
    return <Loading />;
  }
  return (
    <CustomerLaneSlideOut
      activeNavElement={activeNavElement}
      onNavScroll={onNavScroll}
      config={config}
      gridFilter={gridFilter}
      metricsFilter={chartFilter}
      chartDateScope={chartDateScope}
      gridDateScope={gridDateScope}
    />
  );
};

export default SlideOutContainer;
