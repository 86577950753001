import { useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import useRankingUsedMetrics from '../../RankingMatrixForm/useRankingUsedMetrics';
import MetricOptionsContext from '../../../contexts/MetricOptionsContext';
import useFilterInput from '../../../hooks/useFilterInput';
import useDateScope from '../../../hooks/useDateScope';
import metricTypeCheckers from '../../../types/metricTypeCheckers';
import useToMetricInput from 'hooks/useToMetricInput';

const useAggregateMetricsGroupByInput = (
  gadget: VisualisationDefinitions.RankingMatrix,
) => {
  const usedMetrics = useRankingUsedMetrics(gadget.metrics);
  const { normalMetrics } = useContext(MetricOptionsContext);
  const [metrics, setMetrics] = useState<MetricInput[] | undefined>(undefined);
  const [expressions, setExpressions] = useState<Expression2[] | undefined>(
    undefined,
  );
  const [rankings, setRankings] = useState<Queries.RankInput[] | undefined>(
    undefined,
  );
  const [groupBy, setGroupBy] = useState<Queries.GroupBy2 | undefined>();
  const filterInput = useFilterInput(gadget.groupByField);
  const toMetricInput = useToMetricInput();
  const [input, setInput] = useState<
    Queries.AggregateMetricsGroupByInput | undefined
  >();
  const dateScope = useDateScope({});

  useEffect(() => {
    if (metrics && expressions && rankings && groupBy) {
      setInput({
        metrics,
        expressions,
        ranking: rankings,
        groupBy,
        filters: [filterInput],
        dateScope: [dateScope],
      } as Queries.AggregateMetricsGroupByInput);
    } else {
      setInput(undefined);
    }
  }, [dateScope, expressions, filterInput, groupBy, metrics, rankings]);

  useEffect(() => {
    const newMetrics = [] as MetricInput[];
    const newExpressions = [] as Expression2[];
    usedMetrics.forEach((metric) => {
      if (metricTypeCheckers.isNormalMetric(metric)) {
        newMetrics.push({
          id: metric.id,
          field: metric.field,
          dataType: metric.dataType,
          aggFunc: metric.aggFunc,
          filters: metric.filters,
        });
      } else if (metricTypeCheckers.isCompoundMetric(metric)) {
        const internalMetrics = normalMetrics
          .filter((m) => metric.metricIds.includes(m.id))
          .map(toMetricInput);
        newMetrics.push(...internalMetrics);
        setMetrics((currentMetrics) => [
          ...(currentMetrics ? currentMetrics : []),
          ...internalMetrics,
        ]);

        newExpressions.push({
          id: metric.id,
          expression: metric.expression,
        });
      }
    });

    setMetrics(_.uniqBy(newMetrics, 'id'));
    setExpressions(_.uniqBy(newExpressions, 'id'));
  }, [normalMetrics, toMetricInput, usedMetrics]);

  useEffect(() => {
    const newRankings = [] as Queries.RankInput[];
    gadget.metrics.forEach((m) => {
      const actualMetric = usedMetrics.find((usedM) => usedM.id === m.metricId);
      const order = (() => {
        if (!actualMetric) {
          return 'asc';
        }

        const formatting = actualMetric.formatting;
        return formatting.positiveDeltaIsGood ? 'desc' : 'asc';
      })();
      if (m.isRankingEnabled) {
        newRankings.push({
          metricId: m.metricId,
          order,
        });
      }
    });

    setRankings(newRankings);
  }, [gadget.metrics, usedMetrics]);

  useEffect(() => {
    setGroupBy({
      field: gadget.groupByField,
      sortType: gadget.sorting.mode === 'alphabetical' ? 'term' : 'metric',
      sortMetric: gadget.sorting.metricId,
      sort: gadget.sorting.order,
    });
  }, [
    gadget.groupByField,
    gadget.sorting.metricId,
    gadget.sorting.mode,
    gadget.sorting.order,
  ]);

  return input;
};

export default useAggregateMetricsGroupByInput;
