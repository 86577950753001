import React, { useContext } from 'react';
import FieldsStep from './FieldsStep';
import PerformanceWizardContext from '../PerformanceWizardContext';

const FieldsStepContainer = ({
  goToNextStep,
  goToPreviousStep,
  isSaving,
}: {
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  isSaving: boolean;
}) => {
  const { wizardState, isReadOnly } = useContext(PerformanceWizardContext);

  return (
    <FieldsStep
      fields={wizardState.config.fieldsStep.fields}
      datasetName={wizardState.config.datasetStep.name || ''}
      goToNextStep={goToNextStep}
      goToPreviousStep={goToPreviousStep}
      isSaving={isSaving}
      isReadOnly={isReadOnly}
    />
  );
};

export default FieldsStepContainer;
