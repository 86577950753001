import portalTypeCheckers from '../types/portalTypeCheckers';

const getPortalSlug = (portal: Portal | undefined) => {
  if (!portal) {
    return '';
  }

  if (portalTypeCheckers.isAdminPortal(portal)) {
    return 'admin/';
  }

  return `${portal.urlSlug}/`;
};

export default getPortalSlug;
