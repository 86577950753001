import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import Typography from 'kingpin/atoms/Typography';
import ListItemWrapper from '../../../DatasetDefinitions/ListItemWrapper';
import Column from '../../../DatasetDefinitions/Column';
import { buildDashboardShow } from '../../../../../navigation/appRoutes';
import Card from '../../../../../components/Common/Card';
import { PortalsContext } from '../../../../../contextProviders/PortalsProvider';

const DashboardGadgetUsage = ({
  dashboardGadgets,
  usedId,
}: {
  dashboardGadgets: FleetOps.DashboardGadgetUsage[];
  usedId: string;
}) => {
  const { selectedPortal } = useContext(PortalsContext);

  return (
    <>
      {dashboardGadgets.length > 0 && (
        <div style={{ marginBottom: 24 }}>
          <div style={{ marginBottom: '8px' }}>
            <Typography.Header type="H5">
              {`Dashboard Gadgets (${dashboardGadgets.length})`}
            </Typography.Header>
          </div>

          <Card>
            {dashboardGadgets.map((dg, index) => (
              <ListItemWrapper
                key={`${dg.id}-${usedId}`}
                isLast={dashboardGadgets.length - 1 === index}
              >
                <Column
                  style={{
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                  }}
                >
                  <div>
                    <Link
                      to={buildDashboardShow({
                        id: dg.dashboardId,
                        portal: selectedPortal,
                      })}
                      target="_blank"
                    >
                      <Typography.Body type="Body 12" color="#0041ea">
                        {dg.dashboardName}
                      </Typography.Body>
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={buildDashboardShow({
                        id: dg.dashboardId,
                        portal: selectedPortal,
                      })}
                      target="_blank"
                    >
                      <Typography.Body type="Body 12" color="#0041ea">
                        {dg.gadgetName}
                      </Typography.Body>
                    </Link>
                  </div>
                </Column>
              </ListItemWrapper>
            ))}
          </Card>
        </div>
      )}
    </>
  );
};

export default DashboardGadgetUsage;
