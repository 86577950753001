import styled from 'styled-components';
import Colors2 from '../../theme/Colors2';

const TextArea = styled.textarea<{ isReadOnly?: boolean }>`
  background: ${Colors2.Grey['10']};
  resize: none;

  width: 100%;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 8px;
  letter-spacing: -0.0024em;
  padding: 6px 12px;
  min-height: 100px;

  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;

  box-shadow:
    0px 3px 2px -1px rgba(0, 0, 0, 0.02),
    0px 1px 1px -1px rgba(0, 0, 0, 0.04);

  &:focus {
    /* 03 Secondary / 03.01 Blue */

    border: ${(props) => (props.isReadOnly ? 'unset' : '1px solid #1252f7')};

    box-shadow: ${(props) =>
      props.isReadOnly ? 'unset' : '0px 0px 0px 2px rgba(93, 138, 255, 0.25)'};
  }

  &:focus-within {
    outline: unset;
  }

  ${(props) =>
    props.isReadOnly &&
    `
    resize: none;
    cursor: not-allowed !important;
  `}
`;

export default TextArea;
