import React, { useContext, useEffect, useState } from 'react';
import chartColors from '../../../../theme/chartColors';
import mapGraphqlDayNumberToString from '../../../../mapGraphqlDayNumberToString';
import AccountContext from '../../../../contexts/AccountContext';
import useV5ChartData from '../../../../hooks/useV5ChartData';
import Loading from '../../../Loading';
import treeMapOptions from '../../highchartOptions/treeMapOptions';
import { ChartContent } from '../../../Chart';
import useChartDrillDowns from '../../../../hooks/useChartDrillDowns';
import DashboardContextMenuContext from '../../../../contexts/DashboardContextMenuContext';
import useMetric from '../../../../hooks/useMetric';
import FlexCentered from '../../../Common/FlexCentered';
import useValueFormatters from '../../../../hooks/useValueFormatters';

interface TreeMapSeriesItemData {
  name: string;
  value: number;
  color: string;
}

interface TreeMapSeriesItem {
  name: string;
  data: TreeMapSeriesItemData[];
  prefix?: PreOrPostFix;
  postfix?: PreOrPostFix;
  precision?: number;
  metricId: string;
}

const getSeriesData = (
  data: V5ChartData,
  chartDef: V5ChartDefinition,
): TreeMapSeriesItemData[] => {
  const { response, metric } = Object.values(data)[0];

  if (chartDef.groupByDayOfWeek) {
    return Object.values(response).map((i, index) => ({
      name: mapGraphqlDayNumberToString(i['dayOfWeek'] as string),
      value: i[metric.id] as number,
      color: chartColors[index],
    }));
  }

  if (chartDef.trendByCalendarInterval || chartDef.trendByFixedIntervalDays) {
    return Object.values(response).map((i, index) => ({
      name: i['date'] as string,
      value: i[metric.id] as number,
      color: chartColors[index],
    }));
  }

  if (chartDef.dimensionA) {
    const key = chartDef.dimensionA.field;
    return Object.values(response).map((i, index) => ({
      name: i[key] as string,
      value: i[metric.id] as number,
      color: chartColors[index],
    }));
  }
  return [];
};

const buildSeries = (
  data: V5ChartData,
  chartDef: V5ChartDefinition,
): TreeMapSeriesItem[] => {
  const { metric } = Object.values(data)[0];
  const seriesData = getSeriesData(data, chartDef);

  return [
    {
      name: chartDef.name,
      data: seriesData,
      prefix: metric.formatting.prefix,
      postfix: metric.formatting.postfix,
      precision: metric.formatting.precision,
      metricId: metric.id,
    },
  ];
};

// eslint-disable-next-line
const TreeMapContent = React.memo(
  ({
    chartDefinition,
    chartRef,
    useSmallNoDataMessage,
  }: {
    chartDefinition: V5ChartDefinition;
    chartRef: React.MutableRefObject<any>;
    useSmallNoDataMessage: boolean;
  }) => {
    // Context
    const { unitsLocale } = useContext(AccountContext);
    const { openMenu } = useContext(DashboardContextMenuContext);

    // State
    const [groupField] = useState(() => {
      if (chartDefinition.dimensionA) {
        return chartDefinition.dimensionA.field;
      }

      return 'date';
    });
    const [options, setOptions] = useState<Highcharts.Options | undefined>();
    const [isNoDataToDisplay, setIsNoDataToDisplay] = useState<boolean>(false);

    // Hooks
    const metric = useMetric(
      chartDefinition.series.length > 0
        ? chartDefinition.series[0].metricId
        : undefined,
    );
    const { data } = useV5ChartData(chartDefinition, undefined);
    const { formatMetric } = useValueFormatters();

    const { onDrillDown, syncChart } = useChartDrillDowns(
      groupField,
      chartRef,
      'treeMap',
      chartDefinition,
    );
    setTimeout(syncChart);

    // Effects
    useEffect(() => {
      if (!data) {
        return;
      }

      const series = buildSeries(data, chartDefinition);
      const newOptions = {
        ...treeMapOptions({
          title: ' ',
          openMenu,
          groupField,
          fieldType: chartDefinition.dimensionA
            ? chartDefinition.dimensionA.fieldType
            : undefined,
          chartDef: chartDefinition,
          fontSize: 11,
          formatMetric,
        }),
        series: series.map((s: any) => ({
          ...s,
          onDrillDown,
          animation: false,
          dataLabels: {
            enabled: true,
            // @ts-ignore
            formatter: function () {
              // @ts-ignore
              const key = this.key;

              if (metric && chartDefinition.showDataLabels) {
                const formatted = formatMetric({
                  // @ts-ignore
                  value: this.point.value,
                  metricId: metric.id,
                });

                return `<div><span>${key}:</span></div><br/><span>${formatted}</span></div>`;
              }
              return `<div><span>${key}</span></div>`;
            },
          },
        })),
        yAxis: undefined,
        xAxis: undefined,
      };

      setOptions(newOptions as any as Highcharts.Options);
      setIsNoDataToDisplay(
        series.length === 1 &&
          (series[0].data.length === 0 ||
            !series[0].data.some((d) => d.value !== null)),
      );
    }, [
      chartDefinition,
      data,
      formatMetric,
      groupField,
      metric,
      onDrillDown,
      openMenu,
      unitsLocale,
    ]);

    if (!options) {
      return (
        <FlexCentered style={{ height: '100%' }}>
          <Loading />
        </FlexCentered>
      );
    }

    return (
      <ChartContent
        options={options}
        chartRef={chartRef}
        useSmallNoDataMessage={useSmallNoDataMessage}
        noDataToDisplay={isNoDataToDisplay}
      />
    );
  },
);

export default TreeMapContent;
