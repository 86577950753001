import React, { createContext } from 'react';
import useEngagementPortals from './useEngagementPortals';

interface EngagementPortalsContextType {
  availableEngagementPortals: EngagementPortal[];
  allEngagementPortals: EngagementPortal[];
}

export const EngagementPortalsContext =
  createContext<EngagementPortalsContextType>({
    availableEngagementPortals: [],
    allEngagementPortals: [],
  });

const EngagementPortalsProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { availableEngagementPortals, allEngagementPortals } =
    useEngagementPortals();

  return (
    <EngagementPortalsContext.Provider
      value={{
        availableEngagementPortals,
        allEngagementPortals,
      }}
    >
      {children}
    </EngagementPortalsContext.Provider>
  );
};

export default EngagementPortalsProvider;
