import React, { useContext } from 'react';
import Timeline from './Timeline';
import LocalTimelineContext from '../../contexts/Timeline/LocalTimelineContext';

const TimelineContainer = ({ isFullHeight }: { isFullHeight?: boolean }) => {
  const { events, isLoading } = useContext(LocalTimelineContext);

  return (
    <Timeline
      events={events}
      isLoading={isLoading}
      isFullHeight={isFullHeight}
    />
  );
};

export default TimelineContainer;
