import firebase from 'firebase/compat/app';
import STORE_CONSTANTS from './constants';
import withoutNulls from '../api/search/withoutNulls';

export const CURRENT_ENTITY_DEFINITIONS_VERSION = 1;

const entityDefinitionConverter = {
  toFirestore(entity: EntityDetails.Entity): firebase.firestore.DocumentData {
    return withoutNulls({ ...entity });
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ): EntityDetails.Entity {
    const data = {
      ...snapshot.data(options),
    };
    return data as EntityDetails.Entity;
  },
};

const getEntityDefinitionsRef = ({
  accountId,
  db,
}: {
  accountId: string;
  db?: firebase.firestore.Firestore;
}) => {
  return (db ? db : firebase.firestore())
    .collection('accounts')
    .doc(accountId)
    .collection(STORE_CONSTANTS.ENTITY_DEFINITIONS_COLLECTION)
    .withConverter(entityDefinitionConverter);
};

export default getEntityDefinitionsRef;
