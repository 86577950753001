import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

export const buildAggregateSpecialMetricQuery = (
  metrics: MetricInput[],
  expressions: Expression2[],
  filterInput: FilterInput[],
  dateScope: DateRangeInput,
  queryName: string,
  trendByCalendarInterval?: FleetOps.Interval,
) => {
  return {
    query: gql`
      query ${queryName}(
        $metrics: [MetricInput]!
        $filters: [FilterInput]!
        $dateScope: [DateRangeInput!]!
        $expressions: [Expression2]!
        $trendByCalendarInterval: Interval
      ) {
        ${queryName}(
          metrics: $metrics
          filters: $filters
          dateScope: $dateScope
          expressions: $expressions
          trendByCalendarInterval: $trendByCalendarInterval
        )
      }
    `,
    variables: {
      metrics,
      filters: filterInput,
      dateScope,
      expressions,
      trendByCalendarInterval,
    },
  };
};

const aggregateSpecialMetrics = async ({
  client,
  filterInput,
  dateScope,
  trendByCalendarInterval,
  queryName,
}: {
  client: ApolloClient<NormalizedCacheObject>;
  filterInput: FilterInput[];
  dateScope: DateRangeInput;
  trendByCalendarInterval?: FleetOps.Interval;
  queryName: string;
}): Promise<MetricResponse[]> => {
  const metrics = [] as MetricInput[];
  const expressions = [] as Expression2[];
  const query = buildAggregateSpecialMetricQuery(
    metrics,
    expressions,
    filterInput,
    dateScope,
    queryName,
    trendByCalendarInterval,
  );

  const response = await client.query(query);
  return response.data[queryName];
};

export default aggregateSpecialMetrics;
