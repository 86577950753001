import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import ScorecardContext from 'contexts/ScorecardContext';
import { ScoringBandColors } from 'screens/ScorecardsIndex/constants';
import { CellPreview } from '../ScorecardKpiForm/TargetBandsForm/TargetBandsForm';
import Tooltip from 'components/Tooltip';
import useKpiFormatter from '../hooks/useKpiFormatter';
import CommentsProvider from 'contextProviders/CommentsProvider';
import useScorecardPopupCommentId from 'hooks/useScorecardPopupCommentId';
import Inputs from 'components/Inputs';
import PerformanceTooltip from '../KpiRowRight/PerformanceTooltip';
import useTargetBands from '../hooks/useTargetBands';

const KpiInput = ({
  initialData,
  values,
  setValues,
  kpi,
  period,
  invalidPeriods,
  setInvalidPeriods,
}: {
  initialData: Goals.TrendResult | undefined;
  values: Goals.TrendResult[];
  setValues: React.Dispatch<React.SetStateAction<Goals.TrendResult[]>>;
  kpi: Scorecards.ManualKpiRow;
  period: Period;
  invalidPeriods: string[];
  setInvalidPeriods: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const { scorecard } = useContext(ScorecardContext);
  const [isInvalidInput, setIsInvalidInput] = useState<boolean>(false);
  const [kpiInputValue, setKpiInputValue] = useState<string | undefined>(
    initialData && initialData.value !== undefined
      ? String(initialData.value)
      : undefined,
  );
  const [color, setColor] = useState<string>('unset');
  const { targetBandsForPeriod, isDynamic, labelBandsForPeriod } =
    useTargetBands(kpi, period);
  const { formattedValue } = useKpiFormatter({
    kpi,
    value: kpiInputValue !== undefined ? Number(kpiInputValue) : undefined,
  });
  const { commentableId, commentableType } = useScorecardPopupCommentId({
    period,
    kpi,
  });

  useEffect(() => {
    if (
      !targetBandsForPeriod ||
      kpiInputValue === undefined ||
      kpi.isTargetsDisabled
    ) {
      setColor('unset');
      return;
    }

    const numValue = Number(kpiInputValue);

    const colors = [...ScoringBandColors[scorecard.scoringBandId]];
    if (kpi.isColorOrderReversed) {
      colors.reverse();
    }
    const colorToUse = colors.find((c, index) => {
      if (index === 0) {
        const t = targetBandsForPeriod[0];
        return numValue <= t;
      } else if (index === colors.length - 1) {
        const lastT = targetBandsForPeriod[index - 1];
        return numValue > lastT;
      } else {
        const t = targetBandsForPeriod[index];
        const previousT = targetBandsForPeriod[index - 1];
        return numValue > previousT && numValue <= t;
      }
    });

    setColor(colorToUse || 'unset');
  }, [
    kpi.isColorOrderReversed,
    kpi.isTargetsDisabled,
    kpiInputValue,
    scorecard.scoringBandId,
    setColor,
    targetBandsForPeriod,
  ]);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue =
        event.target.value === '' ? undefined : event.target.value;

      const isInvalid = !!newValue && isNaN(Number(newValue));

      setKpiInputValue(newValue);
      setIsInvalidInput(isInvalid);

      if (isInvalid) {
        setInvalidPeriods([...invalidPeriods, period.startDate]);
      } else {
        setInvalidPeriods(invalidPeriods.filter((p) => p !== period.startDate));
      }

      setValues(
        values.map((val) => {
          if (initialData && val.date === initialData.date) {
            return {
              date: val.date,
              value: newValue !== undefined ? Number(newValue) : undefined,
            };
          } else {
            return val;
          }
        }),
      );
    },
    [
      initialData,
      invalidPeriods,
      period.startDate,
      setInvalidPeriods,
      setValues,
      values,
    ],
  );

  return (
    <CommentsProvider
      commentableType={commentableType}
      commentableId={commentableId}
    >
      <div style={{ width: '100%' }}>
        <Tooltip
          content={
            isInvalidInput ? (
              'This is numeric only input'
            ) : kpi.isTargetsDisabled ? null : (
              <PerformanceTooltip
                formattedValue={formattedValue || ''}
                kpi={kpi}
                cellColor={color}
                targetBandsForPeriod={labelBandsForPeriod}
                isDynamic={isDynamic}
              />
            )
          }
          isAltTooltip
        >
          <CellPreview
            cellColor={color}
            data-testid={`${kpi.id}-${period.startDate}`}
            style={{ padding: '4px', opacity: 1 }}
          >
            <Inputs.TextInput
              value={kpiInputValue || ''}
              state={isInvalidInput ? 'Error' : undefined}
              onChange={onChange}
              inputVariant="Grid"
              data-testid={`input-${kpi.id}-${period.startDate}`}
            />
          </CellPreview>
        </Tooltip>
      </div>
    </CommentsProvider>
  );
};

export default KpiInput;
