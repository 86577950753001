import React from 'react';
import { IServerSideDatasource } from 'ag-grid-community';

interface GridDataSourceContextType {
  dataSource?: IServerSideDatasource | DataSource;
}

const GridDataSourceContext = React.createContext<GridDataSourceContextType>({
  dataSource: undefined,
});

export default GridDataSourceContext;
