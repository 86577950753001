import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import WidgetGalleryContext from '../../contexts/WidgetGalleryContext';
import ChartDefinitionsContext from '../../contexts/ChartDefinitionsContext';
import usePopup from '../../hooks/usePopup';
import useWindowSize from '../../hooks/useWindowSize';
import { getCanvasMode } from '../../components/ReportCanvas';
import isV5ChartDef from '../../types/visTypeCheckers/isV5ChartDef';
import AnalyticsContext from '../../contexts/AnalyticsContext';
import findStartingY from './findStartingY';
import buildCardLayout from './buildCardLayout';
import DashboardContext from '../../contexts/DashboardContext';
import useTemplateInstances from '../../hooks/dashboards/useTemplateInstances';
import MetricOptionsContext from '../../contexts/MetricOptionsContext';
import BaseViewsContext from '../../contexts/BaseViewsContext';
import cardTypeCheckers from '../../types/cardTypeCheckers';
import WallboardContext from '../../contexts/WallboardContext';
import metricTypeCheckers from '../../types/metricTypeCheckers';
import isDefined from '../../isDefined';

const WidgetGalleryProvider = ({
  children,
  startEditing,
  currentCanvas,
  setCurrentCanvas,
  pinDashboardGadgetToCanvas,
  isDashboard,
  createAndAddDashboardWidget,
}: {
  children: JSX.Element | JSX.Element[];
  startEditing: () => void;
  currentCanvas: Canvas;
  setCurrentCanvas: (newCanvas: Canvas) => void;
  pinDashboardGadgetToCanvas?: Canvas;
  isDashboard?: boolean;
  createAndAddDashboardWidget?: (
    gadget: DashboardGadget,
    card: CanvasCard.Card,
  ) => Promise<void>;
}) => {
  const { dashboard } = useContext(DashboardContext);
  const { isWallboard } = useContext(WallboardContext);
  const { dataTypeOptions } = useContext(BaseViewsContext);
  const templateInstances = useTemplateInstances(dashboard);
  const { isMobile } = useWindowSize();
  const { metricOptions } = useContext(MetricOptionsContext);
  const canvasMode = getCanvasMode({
    isMobile,
  });
  const { definitions } = useContext(ChartDefinitionsContext);
  const [selectedDefinitions, setSelectedDefinitions] = useState<string[]>([]);
  const { isOpen, open: openPopup, close: closePopup } = usePopup();
  const [selectedTag, setSelectedTag] = useState<string | undefined>(undefined);
  const [isCreatingNewChart, setIsCreatingNewChart] = useState<boolean>(false);
  const [searchText, setSearchText] = useState('');
  const [selectedChartType, setSelectedChartType] = useState<
    | GadgetType
    | 'SingleMetricDateMatrix'
    | 'RankingMatrix'
    | 'PaceMatrix'
    | undefined
  >();
  const [selectedDataType, setSelectedDataType] = useState<
    string | undefined
  >();
  const resetDashboardGadgetEditorRef = useRef<() => void | undefined>();
  const [saving] = useState(false);
  const [filteredDefinitions, setFilteredDefinitions] = useState<
    VisualisationDefinition[]
  >([]);
  const { trackEvent } = useContext(AnalyticsContext);

  const [isConfiguringDashboardGadget, setIsConfiguringDashboardGadget] =
    useState<boolean>(false);

  const open = (createNewChart?: boolean) => {
    if (resetDashboardGadgetEditorRef.current) {
      resetDashboardGadgetEditorRef.current();
    }

    openPopup();
    if (createNewChart) {
      setIsCreatingNewChart(createNewChart);
      setIsConfiguringDashboardGadget(true);
    }
  };

  const close = () => {
    setSelectedDefinitions([]);
    setIsConfiguringDashboardGadget(false);
    setSelectedChartType(undefined);
    setIsCreatingNewChart(false);
    closePopup();
  };

  const definitionsOnDashboard = currentCanvas.cards
    .filter(cardTypeCheckers.isChartDefinition)
    .map((c) => c.content.chartDefinitionId);

  useEffect(() => {
    const newFilteredDefinitions = definitions
      .filter(
        (o) =>
          o.name.toLowerCase().includes(searchText.toLowerCase()) ||
          o.id.toLowerCase().includes(searchText.toLowerCase()) ||
          (o.description &&
            o.description.toLowerCase().includes(searchText.toLowerCase())),
      )
      .filter((d) => {
        if (selectedChartType) {
          if (isV5ChartDef(d)) {
            return d.gadgetType === selectedChartType;
          } else {
            return d.type === selectedChartType;
          }
        }
        return true;
      })
      .filter((d) => {
        if (selectedDataType) {
          if (isV5ChartDef(d)) {
            const metricIds = d.series.map((s) => s.metricId);
            const metrics = metricIds
              .map((mId) => metricOptions.find((m) => m.id === mId))
              .filter((m) => !!m) as Metrics.Metric[];
            const dataTypesOfChart = metrics.reduce((allSources, aMetric) => {
              if (metricTypeCheckers.isCompoundMetric(aMetric)) {
                const underlyingMetrics = aMetric.metricIds
                  .map((mid2) =>
                    metricOptions.find((bMetric) => bMetric.id === mid2),
                  )
                  .filter(isDefined)
                  .filter(metricTypeCheckers.isNormalMetric);

                const underlyingDataSources = underlyingMetrics.reduce(
                  (allSources, bMetric) => {
                    return [...allSources, bMetric.dataType];
                  },
                  [] as string[],
                );
                return [...allSources, ...underlyingDataSources];
              } else {
                return [...allSources, aMetric.dataType];
              }
            }, [] as string[]);
            return dataTypesOfChart.includes(selectedDataType);
          }
        }
        return true;
      })
      .filter((def) => !def.isHidden);

    setFilteredDefinitions(newFilteredDefinitions);
  }, [
    definitions,
    metricOptions,
    searchText,
    selectedChartType,
    selectedDataType,
  ]);

  const toggleDefinitionSelected = (definitionId: string) => {
    const isSelected = selectedDefinitions.includes(definitionId);
    if (isSelected) {
      if (isDashboard) {
        setSelectedDefinitions([definitionId]);
      } else {
        setSelectedDefinitions(
          selectedDefinitions.filter((d) => d !== definitionId),
        );
      }
    } else {
      if (isDashboard) {
        setSelectedDefinitions([definitionId]);
      } else {
        setSelectedDefinitions([...selectedDefinitions, definitionId]);
      }
    }
  };

  const onDataTypeSelected = (newSet: string) => {
    if (!newSet || newSet === '') {
      setSelectedDataType(undefined);
    } else {
      setSelectedDataType(newSet);
    }
  };

  // For reports
  const addToReport = () => {
    const { cards } = currentCanvas;
    const startingY = findStartingY(cards, canvasMode);
    const newCards = selectedDefinitions.map((d, index) => {
      const card = (() => {
        const newLayout = buildCardLayout(
          (index % 3) * 4,
          startingY + Math.floor(index / 3) * 7,
        );
        return {
          layout: newLayout,
          content: {
            type: 'Chart Definition' as 'Chart Definition',
            chartDefinitionId: d,
          },
        };
      })();
      const def = definitions.find((def) => def.id === d);
      if (!def) {
        throw new Error(`Definition id: ${d} not found`);
      }

      return card;
    });

    trackEvent('Report - Show - Gadget added');
    setCurrentCanvas({
      ...currentCanvas,
      cards: [...currentCanvas.cards, ...newCards],
    });
    close();
    startEditing();
  };

  const startConfigureDashboardGadget = () => {
    setIsConfiguringDashboardGadget(true);
  };

  const onConfigureSave = async (gadget: DashboardGadget) => {
    const { cards } = pinDashboardGadgetToCanvas
      ? pinDashboardGadgetToCanvas
      : currentCanvas;
    const card = (() => {
      return {
        layout: buildCardLayout(0, findStartingY(cards, canvasMode)),
        content: {
          type: 'Dashboard Gadget' as 'Dashboard Gadget',
          dashboardGadgetId: gadget.id,
        },
      };
    })();
    if (createAndAddDashboardWidget && !!dashboard) {
      trackEvent('Dashboard - Edit - Card Added', {
        type: isV5ChartDef(gadget.chartDef) ? gadget.chartDef.gadgetType : '',
        dashboardId: dashboard.id,
        dashboardName: dashboard.name,
        isWallboardSlide: isWallboard ? 'true' : 'false',
      });
      const isConfirmed =
        templateInstances.length === 0 ||
        window.confirm(
          `${templateInstances.length} dashboard${
            templateInstances.length !== 1 ? 's' : ''
          } will be affected. Are you sure you want to make this change?`,
        );
      if (isConfirmed) {
        await createAndAddDashboardWidget(gadget, card)
          .then(close)
          .then(startEditing);
      }
    }
  };

  return (
    <WidgetGalleryContext.Provider
      value={{
        isOpen,
        open,
        close,
        selectedTag,
        setSelectedTag,
        searchText,
        setSearchText,
        definitions: filteredDefinitions,
        selectedDefinitions,
        toggleDefinitionSelected,
        saving,
        addToReport,
        definitionsOnDashboard,
        isDashboard,
        startConfigureDashboardGadget,
        isConfiguringDashboardGadget,
        onConfigureSave,
        selectedChartType,
        setSelectedChartType,
        dataTypeOptions,
        selectedDataSet: selectedDataType,
        onDataTypeSelected,
        isCreatingNewChart,
        resetDashboardGadgetEditorRef,
      }}
    >
      {children}
    </WidgetGalleryContext.Provider>
  );
};

WidgetGalleryProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default WidgetGalleryProvider;
