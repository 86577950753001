import React from 'react';
import getCurrentDateTimeFor from './getCurrentDateTimeFor';
import getCompareDateTimeFor from './getCompareDateTimeFor';
import TimeSeriesComparisonTooltip from './TimeSeriesComparisonTooltip';
import { DateTime } from 'luxon';
import dateRangeInputToAmericanLabel from '../../dateRangeInputToAmericanLabel';
import moment from 'moment';
import captureException from '../../services/captureException';
import { renderToString } from 'react-dom/server';

const getDateRange = (
  dateSeconds: number,
  interval: FleetOps.Interval,
): DateRangeInput => {
  const date = DateTime.fromMillis(dateSeconds, { zone: 'utc' });

  if (interval === 'hour') {
    return {
      startDate: date.toISO(),
      endDate: date.toISO(),
    };
  } else if (interval === 'day') {
    return {
      startDate: date.toISODate(),
      endDate: date.toISODate(),
    };
  } else if (interval === 'week') {
    return {
      startDate: date.toISODate(),
      endDate: date.plus({ week: 1 }).minus({ day: 1 }).toISODate(),
    };
  } else if (interval === 'month') {
    return {
      startDate: date.toISODate(),
      endDate: date.plus({ month: 1 }).minus({ day: 1 }).toISODate(),
    };
  } else if (interval === 'quarter') {
    return {
      startDate: date.toISODate(),
      endDate: date.plus({ quarter: 1 }).minus({ day: 1 }).toISODate(),
    };
  } else if (interval === 'year') {
    return {
      startDate: date.toISODate(),
      endDate: date.plus({ year: 1 }).minus({ day: 1 }).toISODate(),
    };
  } else {
    return {
      startDate: date.toISODate(),
      endDate: date.toISODate(),
    };
  }
};

const buildTooltipFormatter = ({
  series,
  autoInterval,
  data,
  formatMetric,
}: {
  series: (HistogramSeriesItem | ComparisonSeriesItem | BarSeriesItem)[];
  autoInterval?: AutoInterval;
  data: V5ChartData;
  formatMetric: (args: {
    metricId: string;
    value: string | number | null | undefined;
  }) => string;
}) => {
  return function () {
    try {
      // @ts-ignore
      const { x: baseX, points } = this;
      const x = (() => {
        if (typeof baseX === 'string') {
          return moment.utc(baseX).valueOf();
        } else {
          return baseX;
        }
      })();
      if (points.length === 0) {
        return '';
      }

      const currentDateTime = getCurrentDateTimeFor(x, series);
      const prevDateTime = getCompareDateTimeFor(x, series);

      // @ts-ignore
      const { interval } = points[0].series.userOptions;
      const currentDateRange = getDateRange(
        currentDateTime,
        autoInterval ? autoInterval.interval : interval,
      );
      const currentDateText = dateRangeInputToAmericanLabel(currentDateRange);

      const previousDateRange = getDateRange(
        prevDateTime,
        autoInterval ? autoInterval.interval : interval,
      );
      const previousDateText = dateRangeInputToAmericanLabel(previousDateRange);
      // @ts-ignore
      const metric = Object.values(data)[0].metric;
      const currentValue = points[0].y;
      const prevValue = points[1] ? points[1].y : undefined;
      const currentFormatted =
        metric && currentValue !== undefined
          ? formatMetric({ metricId: metric.id, value: currentValue })
          : undefined;
      const prevFormatted =
        metric && prevValue !== undefined
          ? formatMetric({ metricId: metric.id, value: prevValue })
          : undefined;

      return renderToString(
        <TimeSeriesComparisonTooltip
          currentDateText={currentDateText}
          previousDateText={previousDateText}
          metric={metric}
          currentValue={currentValue}
          currentFormatted={currentFormatted}
          prevValue={prevValue}
          prevFormatted={prevFormatted}
          currentDateRange={currentDateRange}
          previousDateRange={previousDateRange}
          formatMetricOverride={formatMetric}
        />,
      );
    } catch (ex) {
      captureException(ex);
      // @ts-ignore
      return this.y;
    }
  };
};

export default buildTooltipFormatter;
