import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import useComments from '../hooks/useComments';
import CommentsContext from '../contexts/CommentsContext';

const CommentsProvider = ({
  commentableType,
  commentableId,
  linkTo,
  children,
  extraWatcherIds,
}: {
  commentableType: CommentableType;
  commentableId: string;
  linkTo?: string;
  children: JSX.Element | JSX.Element[];
  extraWatcherIds?: string[];
}) => {
  const [watchers, setWatchers] = useState<string[]>([]);
  const { comments, isLoading } = useComments(commentableId, commentableType);

  useEffect(() => {
    const newWatchers = _.uniq([
      ...comments.map((c) => c.createdBy),
      ...comments.reduce(
        (allMentions, c) => [...allMentions, ...c.mentionedUsers],
        [] as string[],
      ),
      ...(extraWatcherIds ? extraWatcherIds : []),
    ]);
    setWatchers(newWatchers);
  }, [comments, extraWatcherIds]);

  return (
    <CommentsContext.Provider
      value={{
        comments,
        isLoading,
        commentableType,
        commentableId,
        linkTo,
        watchers,
      }}
    >
      {children}
    </CommentsContext.Provider>
  );
};

const Gate = ({
  commentableType,
  commentableId,
  linkTo,
  children,
  extraWatcherIds,
}: {
  commentableType?: CommentableType;
  commentableId?: string;
  linkTo?: string;
  children: JSX.Element | JSX.Element[];
  extraWatcherIds?: string[];
}) => {
  if (!!commentableId && !!commentableType) {
    return (
      <CommentsProvider
        commentableType={commentableType}
        commentableId={commentableId}
        extraWatcherIds={extraWatcherIds}
        linkTo={linkTo}
      >
        {children}
      </CommentsProvider>
    );
  } else {
    return <>{children}</>;
  }
};

export default Gate;
