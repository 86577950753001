import { Dispatch, SetStateAction, useCallback, useContext } from 'react';
import getTotalMilesAndDays from '../../api/getTotalMilesAndDays';
import GqlClientContext from 'contexts/GqlClientContext';
import { ALL_GROUP_NAME } from '../../consts';

const useGetTotalsValue = ({
  activityCosts,
  intervals,
  costsLookup,
  setCostsLookup,
  getCostValue,
  getIsInitialAllGroup,
}: {
  activityCosts: Costs.PersistedCostModel | undefined;
  intervals: Period[];
  costsLookup: Costs.CostIntervalLookup;
  setCostsLookup: Dispatch<SetStateAction<Costs.CostIntervalLookup>>;
  getCostValue: ({
    startDate,
    groupDefinition,
    costFieldName,
  }: {
    startDate: string;
    groupDefinition: Costs.Group;
    costFieldName: string;
  }) => number | undefined;
  getIsInitialAllGroup: (groupDefinition: Costs.Group) => boolean;
}) => {
  const { client } = useContext(GqlClientContext);

  const getPersistableCosts = useCallback((): Costs.IntervalCostsInput[] => {
    if (activityCosts === undefined) {
      return [];
    }

    return intervals.map(({ startDate, endDate }) => {
      const groupCosts = activityCosts.groups.map((g) => {
        const costParams = activityCosts.categories.map((category) => {
          const costValue = getCostValue({
            startDate,
            groupDefinition: g,
            costFieldName: category.costFieldName,
          });
          return {
            costFieldName: category.costFieldName,
            cost: costValue,
          };
        });

        return {
          groupDefinition: g,
          costs: costParams.map((param) => ({
            costFieldName: param.costFieldName,
            value: param.cost,
          })),
          totals: undefined,
        };
      });

      return {
        startDate,
        endDate,
        groupCosts,
      };
    });
  }, [activityCosts, getCostValue, intervals]);

  const getTotalsValue = useCallback(
    async ({
      startDate,
      endDate,
      groupDefinition,
    }: {
      startDate: string;
      endDate: string;
      groupDefinition: Costs.Group;
    }): Promise<Costs.IntervalTotals | undefined> => {
      const checkIsEmptyInterval = () => {
        if (getIsInitialAllGroup(groupDefinition)) {
          const newInterval = getPersistableCosts().find(
            (i) => i.startDate === startDate,
          );

          if (!newInterval) {
            return true;
          }

          const group = newInterval.groupCosts.find(
            (groupCost) =>
              groupCost.groupDefinition.groupName === groupDefinition.groupName,
          );

          return group ? group.costs.every((cost) => cost.value === 0) : true;
        } else {
          return false;
        }
      };

      if (checkIsEmptyInterval()) {
        return undefined;
      }

      // check the lookup
      const i = costsLookup[startDate];
      const totals = i ? i.groups[groupDefinition.groupName].totals : undefined;
      if (totals === undefined && activityCosts) {
        // if not in lookup - fetch it
        const totals = await getTotalMilesAndDays(
          {
            modelType: activityCosts.modelType,
            dateField: activityCosts.dateField,
            startDate,
            endDate,
            groupFieldName: activityCosts.groupFieldName || ALL_GROUP_NAME,
            groups: activityCosts.groups,
          },
          client,
        );

        // memorize
        setCostsLookup((currentLookup) => {
          const interval = currentLookup[startDate];
          if (interval === undefined) {
            return currentLookup;
          }

          const newGroups: {
            [groupDefinitionName: string]: {
              groupDefinition: Costs.Group;
              costs: { [fieldName: string]: number | undefined };
              totals: Costs.IntervalTotals | undefined;
            };
          } = {};

          activityCosts.groups.forEach((group, index) => {
            const costs = interval.groups[group.groupName].costs;
            const totalsValue = totals[index];

            newGroups[group.groupName] = {
              groupDefinition: group,
              costs,
              totals: totalsValue || 0,
            };
          });

          const newInterval = {
            ...interval,
            groups: newGroups,
          };

          const newLookup = {
            ...currentLookup,
            [startDate]: newInterval,
          };

          return newLookup;
        });

        const groupIndex = activityCosts.groups.findIndex(
          (group) => group.groupName === groupDefinition.groupName,
        );

        return totals[groupIndex];
      }

      return totals;
    },
    [
      activityCosts,
      client,
      costsLookup,
      getIsInitialAllGroup,
      getPersistableCosts,
      setCostsLookup,
    ],
  );

  return { getTotalsValue, getPersistableCosts };
};

export default useGetTotalsValue;
