import firebase from 'firebase/compat/app';

const deleteCommitment = async (
  c: Commitment,
  accountRef: firebase.firestore.DocumentReference,
) => {
  return accountRef.collection('commitments').doc(c.id).delete();
};

export default deleteCommitment;
