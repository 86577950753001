import React, { useContext, useEffect, useState } from 'react';
import FeatureGatesContext from '../contexts/FeatureGatesContext';
import DatasetDefinitionsContext from '../contexts/DatasetDefinitionsContext';
import CompoundMetricsContext from '../contexts/CompoundMetricsContext';
import SpecialMetricsContext from '../contexts/SpecialMetricsContext';
import AccountContext from '../contexts/AccountContext';
import FlexCentered from '../components/Common/FlexCentered';
import Loading from '../components/Loading';
import BaseViewsContext from '../contexts/BaseViewsContext';
import ChartDefinitionsContext from '../contexts/ChartDefinitionsContext';
import MetricOptionsContext from '../contexts/MetricOptionsContext';
import SavedFiltersContext from '../contexts/SavedFiltersContext';
import SplashScreenProviders from '../contextProviders/SplashScreenProviders';

const useIsLoadingCriticalData = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { isLoading: isLoadingAccount } = useContext(AccountContext);
  const { isLoading: isLoadingGates } = useContext(FeatureGatesContext);
  const { isLoading: isLoadingSavedFilters } = useContext(SavedFiltersContext);

  const { isLoading: isLoadingDatasets } = useContext(
    DatasetDefinitionsContext,
  );
  const { isLoading: isLoadingBaseViews } = useContext(BaseViewsContext);
  const { isLoading: isLoadingMetrics } = useContext(MetricOptionsContext);
  const { isLoading: isLoadingCompoundMetrics } = useContext(
    CompoundMetricsContext,
  );
  const { isLoading: isLoadingSpecialMetrics } = useContext(
    SpecialMetricsContext,
  );
  const { isLoading: isLoadingChartDefs } = useContext(ChartDefinitionsContext);

  useEffect(() => {
    setIsLoading(
      isLoadingGates ||
        isLoadingDatasets ||
        isLoadingMetrics ||
        isLoadingCompoundMetrics ||
        isLoadingSpecialMetrics ||
        isLoadingAccount ||
        isLoadingBaseViews ||
        isLoadingChartDefs ||
        isLoadingSavedFilters,
    );
  }, [
    isLoadingAccount,
    isLoadingBaseViews,
    isLoadingChartDefs,
    isLoadingCompoundMetrics,
    isLoadingDatasets,
    isLoadingGates,
    isLoadingMetrics,
    isLoadingSpecialMetrics,
    isLoadingSavedFilters,
  ]);

  return isLoading;
};

export const SplashScreenLoadingGate = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const isLoading = useIsLoadingCriticalData();
  if (isLoading) {
    return (
      <FlexCentered style={{ height: '100vh' }} data-testid="splash-screen">
        <Loading isBlocking isGrouped />
      </FlexCentered>
    );
  } else {
    return <>{children}</>;
  }
};

const SplashScreen = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  return (
    <SplashScreenProviders>
      <SplashScreenLoadingGate>{children}</SplashScreenLoadingGate>
    </SplashScreenProviders>
  );
};

export default SplashScreen;
