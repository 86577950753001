import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import Cell from './V5Gadget/Matrix/Cell';
import colors from '../theme/colors';
import Row from './Common/Row';
import { DeltaNumber } from './OnTargetCellRenderer';
import Tooltip from './Tooltip';
import { TooltipContent } from './ProgressCellRenderer';
import isoDateToAmerican from '../isoDateToAmerican';
import GridCellErrorBoundary from './GridCellErrorBoundary';
import useValueFormatters from 'hooks/useValueFormatters';
export const getContractProgressCellText = (
  data: any,
  formatValue: ({
    formatting,
    value,
  }: {
    formatting: MetricFormatting;
    value: number;
  }) => string,
) => {
  const total = data.contractTotalLoads;
  const commitment = data.contractCommitment;
  if (total === undefined || commitment === undefined) {
    return '-';
  }
  if (typeof total !== 'number' || typeof commitment !== 'number') {
    return '';
  }

  const formatting = {} as MetricFormatting;
  const totalFormatted = formatValue({ formatting, value: total });
  const commitmentFormatted = formatValue({ formatting, value: commitment });

  return `${totalFormatted} / ${commitmentFormatted}`;
};

const ContractProgressCellRenderer = (params: ICellRendererParams) => {
  const { formatValue } = useValueFormatters();
  const diff = params.data.contractDifferenceInProgress;
  const date = params.data.effectiveStartDate;
  const cellText = getContractProgressCellText(params.data, formatValue);
  if (cellText === '-') {
    return <Cell rightAlign>{cellText}</Cell>;
  }
  const isGood = diff >= 0;
  const color = isGood ? colors.MEDIUM_SEA_GREEN : colors.RADICAL_RED;

  const adverb = isGood ? 'ahead of' : 'behind';
  const diffFormatted = formatValue({
    formatting: {},
    value: Math.abs(diff),
  });
  const onTargetText = `${diffFormatted} ${adverb} target`;
  const dateText = date ? `since ${isoDateToAmerican(date)}` : '';

  return (
    <Cell rightAlign>
      <Tooltip
        isAltTooltip
        content={
          <TooltipContent
            tooltipTitle={'Loads Completed'}
            progressText={cellText}
            onTargetText={onTargetText}
            dateText={dateText}
            isGood={isGood}
          />
        }
      >
        <Row centerAlign style={{ justifyContent: 'flex-end' }}>
          <DeltaNumber color={color}>{cellText}</DeltaNumber>
        </Row>
      </Tooltip>
    </Cell>
  );
};

const Gate = (params: ICellRendererParams) => (
  <GridCellErrorBoundary
    params={params}
    childComponent={<ContractProgressCellRenderer {...params} />}
  />
);

export default Gate;
