import React from 'react';
import styled from 'styled-components';

import colors from '../theme/colors';
import Colors2 from '../theme/Colors2';

const List = styled.div`
  overflow-y: auto;
`;

const OptionWrapper = styled.div<{ isDisabled?: boolean; isLarge?: boolean }>`
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  margin-bottom: ${(props) => (props.isLarge ? '8px' : '0')};

  &:hover {
    opacity: 0.7;
  }
`;

const OptionCircleOuter = styled.div<{
  isSelected: boolean;
  noMargin?: boolean;
}>`
  border: 2px solid
    ${(props) => (props.isSelected ? colors.NAVY_BLUE : colors.LOGAN)};
  background-color: white;
  width: 18px;
  height: 18px;
  border-radius: 11px;
  margin-right: ${(props) => (props.noMargin ? '0px' : '12px')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OptionCircleInner = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 8px;
  border: 2px solid white;
  background-color: ${colors.NAVY_BLUE};
`;

const Text = styled.div<{ isDisabled?: boolean }>`
  color: ${(props) => (props.isDisabled ? Colors2.Grey['5'] : colors.TEXT)};
  line-height: 16px;
  font-size: 14px;
`;

export const RadioOptionItem = ({
  testId,
  isSelected,
  onSelected,
  text,
  isDisabled,
  noMargin,
  isLarge,
}: {
  testId?: string;
  isSelected: boolean;
  onSelected: () => void;
  text: string;
  isDisabled?: boolean;
  noMargin?: boolean;
  isLarge?: boolean;
}) => (
  <OptionWrapper
    onClick={isDisabled ? undefined : onSelected}
    data-testid={testId}
    isDisabled={isDisabled}
    isLarge={isLarge}
  >
    <OptionCircleOuter isSelected={isSelected} noMargin={noMargin}>
      {isSelected && <OptionCircleInner />}
    </OptionCircleOuter>
    <Text isDisabled={isDisabled}>{text}</Text>
  </OptionWrapper>
);

const RadioInputV2 = ({
  selected,
  setSelected,
  options,
  isDisabled,
  isLarge,
}: {
  selected?: string;
  setSelected: (newValue: string | undefined) => void;
  options: { label: string; value: string; testId?: string }[];
  isDisabled?: boolean;
  isLarge?: boolean;
}) => {
  return (
    <List>
      {options.map((o) => (
        <RadioOptionItem
          key={o.value}
          isSelected={o.value === selected}
          text={o.label}
          isDisabled={isDisabled}
          onSelected={() => {
            setSelected(o.value);
          }}
          isLarge={isLarge}
          testId={o.testId}
        />
      ))}
    </List>
  );
};

export default RadioInputV2;
