import { useContext, useEffect, useState } from 'react';
import ExcludedPaceDatesContext from '../contexts/ExcludedPaceDatesContext';
import STORE from '../store';
import AccountPickerContext from '../contexts/AccountPickerContext';
import { IExcludedPaceDates } from '../store/visualisations/getExcludedPaceDatesRef';

const DEFAULT_EXCLUDED_PACE_DATES: IExcludedPaceDates = {
  dateRanges: [],
};

const ExcludedPaceDatesProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { selectedAccountId } = useContext(AccountPickerContext);
  const [excludedDates, setExcludedDates] = useState<IExcludedPaceDates>(
    DEFAULT_EXCLUDED_PACE_DATES,
  );
  useEffect(() => {
    const listener = STORE.visualisations
      .getExcludedPaceDatesRef({ accountId: selectedAccountId })
      .onSnapshot((snapshot) => {
        const newData = snapshot.data();
        if (newData) {
          setExcludedDates({
            ...newData,
            dateRanges: newData.dateRanges.sort((a, b) => {
              const aS = a.startDate || '';
              const bS = b.startDate || '';
              if (aS < bS) {
                return -1;
              } else if (aS > bS) {
                return 1;
              } else {
                return 0;
              }
            }),
          });
        } else {
          setExcludedDates(DEFAULT_EXCLUDED_PACE_DATES);
        }
      });

    return () => {
      listener();
    };
  }, [selectedAccountId]);

  return (
    <ExcludedPaceDatesContext.Provider value={excludedDates}>
      {children}
    </ExcludedPaceDatesContext.Provider>
  );
};

export default ExcludedPaceDatesProvider;
