import STORE from '../../store';

const saveRemindersGadget = async (
  gadget: VisualisationDefinitions.Reminders,
  accountId: string,
) => {
  return STORE.visualisations
    .getRemindersRef({ accountId })
    .doc(gadget.id)
    .set(gadget);
};

export default saveRemindersGadget;
