import React from 'react';
import styled from 'styled-components';
import Typography from 'kingpin/atoms/Typography';

import PortalWithAccess from './PortalWithAccess';
import usePortalsWithAccess from './usePortalsWithAccess';

const Grid = styled.div`
  border: 1px solid #e0e0e0;
`;

const PortalsWithAccess = ({
  type,
  typeId,
  contentName,
}: {
  type: ExecutivePortalPublishableContentType;
  typeId: string;
  contentName: string;
}) => {
  const portalsWithAccess = usePortalsWithAccess({
    type,
    typeId,
  });

  if (portalsWithAccess.length === 0) {
    return null;
  }

  return (
    <div>
      <div style={{ marginBottom: 8 }}>
        <Typography.Body type={'Label'} color={'#333333'}>
          Portals with access
        </Typography.Body>
      </div>
      <Grid>
        {portalsWithAccess.map((portal) => (
          <PortalWithAccess
            type={type}
            typeId={typeId}
            portal={portal}
            contentName={contentName}
            key={portal.id}
          />
        ))}
      </Grid>
    </div>
  );
};

export default PortalsWithAccess;
