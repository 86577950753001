import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';

const buildQuery = () => ({
  query: gql`
    query getCostCategories {
      getCostCategories {
        name
        fieldName
      }
    }
  `,
});

const getCostCategories = async (
  client: ApolloClient<NormalizedCacheObject>,
): Promise<Costs.CostCategoryField[]> => {
  const gqlQuery = buildQuery();
  const response = await client.query(gqlQuery);
  return response.data.getCostCategories;
};

export default getCostCategories;
