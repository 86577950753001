import baseOptions from './baseOptions';
import formatDateLabel from '../formatDateLabel';

const stackedArea = ({ title, series, xAxis, formatMetric }) => ({
  ...baseOptions(formatMetric),
  chart: {
    ...baseOptions(formatMetric).chart,
    type: 'area',
  },
  title: {
    ...baseOptions(formatMetric).title,
    text: title,
  },
  yAxis: [
    {
      ...baseOptions(formatMetric).yAxis[0],
      title: {
        enabled: false,
      },
    },
  ],
  xAxis,
  series,
  plotOptions: {
    area: {
      stacking: 'normal',
    },
    series: {
      findNearestPointBy: 'xy',
      allowPointSelect: true,
      borderRadius: 2,
      marker: {
        enabled: false,
        symbol: 'circle',
      },
      point: {
        events: {
          click: function (e) {
            const { onDrillDown } = this.series.userOptions;
            if (!onDrillDown) {
              alert('Coming soon...');
              return;
            }
            const selectedIndex = e.point.x;
            const terms = e.point.series.data;
            const series = e.point.series.chart.series;
            if (series.length > 1) {
              // multi series
              series[0].data.forEach((d, dataIndex) => {
                if (dataIndex === selectedIndex) {
                  onDrillDown(d.category, e.ctrlKey || e.metaKey);
                }
              });
            } else {
              // single series
              terms.forEach((t, index) => {
                if (index === selectedIndex) {
                  onDrillDown(t.category, e.ctrlKey || e.metaKey);
                }
              });
            }
          },
        },
      },
    },
  },
  tooltip: {
    ...baseOptions(formatMetric).tooltip,
    formatter: function () {
      const { x, points } = this;
      if (points.length === 0) {
        return '';
      }

      const { metricId, interval } = points[0].series.userOptions;
      const headerText = (() => {
        if (interval) {
          return formatDateLabel(x, interval);
        } else {
          return x;
        }
      })();

      let pointsHtml = '';
      points.forEach((p) => {
        if (p.y > 0) {
          const formattedY = formatMetric({
            value: p.y,
            metricId,
          });
          pointsHtml += `<div><span style="color:${p.color}">● </span><span style="color: #878F9D;">${p.series.name}: </span><b>${formattedY}</b></div><br/>`;
        }
      });

      return `<div>${headerText}<br/>${pointsHtml}`;
    },
  },
});

export default stackedArea;
