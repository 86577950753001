import React from 'react';
import styled from 'styled-components';

import Typography from 'kingpin/atoms/Typography';
import { DATA_MANAGER_TOP_ACTION_DIV_ID } from './AllSets/constants';
import Row from '../../components/Common/Row';
import TopBarBackRow from './TopBarBackRow';
import { TOP_BAR_HEIGHT } from '../../constants';
import Colors2 from '../../theme/Colors2';

const OuterDiv = styled.div`
  border-bottom: 1px solid ${Colors2.Border};
  background: #fff;
  height: ${TOP_BAR_HEIGHT}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 24px;
`;

const TopBarTitleRow = ({ baseView }: { baseView?: FleetOps.BaseView }) => {
  return (
    <>
      <OuterDiv>
        <Row centerAlign spaceBetween>
          <Typography.Header type="H3">Data Manager</Typography.Header>
          <div id={DATA_MANAGER_TOP_ACTION_DIV_ID} />
        </Row>
      </OuterDiv>
      <TopBarBackRow baseView={baseView} />
    </>
  );
};

export default TopBarTitleRow;
