import React, { useCallback, useContext } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import TargetContextMenu from './TargetContextMenu';
import TargetListContext from '../contexts/TargetListContext';
import Row from '../../Common/Row';
import Typography from 'kingpin/atoms/Typography';
import isDefined from '../../../isDefined';
import Colors2 from '../../../theme/Colors2';
import { TARGET_LIST_ITEM_DATE_FORMAT } from '../constants';
import useHasUnsavedChanges from '../hooks/useHasUnsavedChanges';
import useWarningConfirmation from '../../ConfirmationModals/hooks/useWarningConfirmation';
import { ModalTransition } from '@atlaskit/modal-dialog';
import Icon from '../../../kingpin/atoms/Icon';

const Item = styled.div`
  background-color: ${Colors2.Grey['9']};
  padding: 8px 16px;
  cursor: pointer;
  margin-bottom: 16px;
  border-radius: 3px;

  .showOnMouseOver {
    visibility: hidden;
  }

  :hover {
    .showOnMouseOver {
      visibility: visible;
    }
  }
`;

const useRulesText = (target: Targets.Wizard.DataTypeTarget) => {
  const { groups } = target;
  const withoutFallbacks = groups.filter((g) => !g.isFallback);
  if (withoutFallbacks.length === 0) {
    return '0 Rules';
  }

  if (withoutFallbacks.length > 1) {
    const values = withoutFallbacks
      .map((g) => g.targets)
      .reduce((a, b) => [...a, ...b], [])
      .map((t) => t.categories.map((c) => c.value))
      .reduce((a, b) => [...a, ...b], [])
      .filter(isDefined)
      .map((v) => Number.parseFloat(v));
    const sorted = values.sort((a, b) => {
      if (a > b) {
        return 1;
      } else if (a === b) {
        return 0;
      } else {
        return -1;
      }
    });

    const smallest = sorted[0];
    const largest = sorted[sorted.length - 1];

    if (smallest && largest) {
      return `${withoutFallbacks.length} Rules (${smallest} - ${largest})`;
    }
  }

  return withoutFallbacks[0].targets[0].categories[0].value;
};

const TargetListItem = ({
  target,
  isSelected,
}: {
  target: Targets.Wizard.DataTypeTarget;
  isSelected: boolean;
}) => {
  const { getConfirmationThat, DangerConfirmation } = useWarningConfirmation({
    title: 'Unsaved Changes',
    continueText: `Continue to target without saving`,
  });
  const hasUnsavedChanges = useHasUnsavedChanges();
  const { onTargetSelected } = useContext(TargetListContext);
  const rulesText = useRulesText(target);
  const startDateLabel = moment(target.effectiveDate).format(
    TARGET_LIST_ITEM_DATE_FORMAT,
  );
  const onClick = useCallback(() => {
    if (!hasUnsavedChanges) {
      onTargetSelected(target);
      return;
    }

    getConfirmationThat(
      'If you navigate away, all changes made will be lost.' +
        ' Are sure you want to cancel Target setting?',
    ).then((isConfirmed) => {
      if (isConfirmed) {
        onTargetSelected(target);
      }
    });
  }, [getConfirmationThat, hasUnsavedChanges, onTargetSelected, target]);

  return (
    <>
      <Item onClick={onClick}>
        <Row centerAlign spaceBetween>
          <div>
            <Typography.Header type="H5">{startDateLabel}</Typography.Header>
            <Typography.Body type="Body 12">{rulesText}</Typography.Body>
          </div>
          <Row centerAlign>
            <div>
              <TargetContextMenu target={target} />
            </div>
            {isSelected && (
              <div style={{ marginLeft: 8, display: 'flex' }}>
                <Icon icon={'checkmark'} />
              </div>
            )}
          </Row>
        </Row>
      </Item>
      <ModalTransition>{DangerConfirmation}</ModalTransition>
    </>
  );
};

export default TargetListItem;
