// https://codepen.io/ciaranhan/pen/LvgQYy
import chartColors from '../../../theme/chartColors';
import Colors from '../../../theme/colors';
import Colors2 from '../../../theme/Colors2';

const normalLegend = {
  enabled: true,
  align: 'left',
  itemStyle: {
    fontSize: '0.7em',
  },
};

const base = (formatMetric) => {
  return {
    accessibility: {
      enabled: false,
    },
    chart: {
      styledMode: false,
    },
    scrollbar: {
      enabled: false,
    },
    rangeSelector: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    title: {
      useHTML: true,
      align: 'left',
      style: {
        color: Colors.TITLE_TEXT,
      },
    },
    xAxis: [],
    yAxis: [],
    exporting: {
      enabled: false,
    },

    tooltip: {
      shadow: {
        color: 'black',
        opacity: 0.04,
        offsetY: 3,
      },
      pointFormatter: function () {
        const seriesname = this.series.name;
        const value = formatMetric({
          metricId: this.series.userOptions.metricId,
          value: this.y,
        });

        return `<span style="color:${this.color}">● </span><b><span style="color: #878F9D;">${seriesname}</span><b> ${value}</b><br/>`;
      },
      borderRadius: 8,
      padding: 12,
      shared: true,
      outside: true,
      split: false,
      borderColor: Colors2.Border,
      shadowOpacity: 0.05,
      borderWidth: 1,
    },
    legend: normalLegend,
    colors: chartColors,
  };
};

export default base;
