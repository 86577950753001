import React from 'react';

interface DataDictionaryContextType {
  selectedDataset?: FleetOps.DatasetDefinition;
  selectedBaseView?: FleetOps.BaseView;
  selectedField?: FleetOps.Field;
  selectedBaseFieldView?: FleetOps.BaseViewField;
  setField: (f: string | undefined) => void;
}

const DataDictionaryContext = React.createContext<DataDictionaryContextType>({
  setField: () => {},
});

export default DataDictionaryContext;
