import isCustomRangeInput from '../../isCustomRangeInput';
import toRangeAggregation from './toRangeAggregation';

const buildGroupByRanges = (
  def: V5ChartDefinition,
): V5RangeAggregation | undefined => {
  if (def.dimensionA && def.dimensionA.rangeInput) {
    if (isCustomRangeInput(def.dimensionA.rangeInput)) {
      return toRangeAggregation(
        def.dimensionA.rangeInput,
        def.dimensionA.field,
      );
    }
  }

  if (def.dimensionB && def.dimensionB.rangeInput) {
    if (isCustomRangeInput(def.dimensionB.rangeInput)) {
      return toRangeAggregation(
        def.dimensionB.rangeInput,
        def.dimensionB.field,
      );
    }
  }
  return undefined;
};

export default buildGroupByRanges;
