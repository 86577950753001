import { DateTime } from 'luxon';

const getNumberOfDays = (d: DateRangeInput) => {
  const s = d.startDate ? DateTime.fromISO(d.startDate) : DateTime.local();
  const e = d.endDate ? DateTime.fromISO(d.endDate) : undefined;
  const diffDays = e ? Math.abs(Math.round(s.diff(e, 'days').days)) : 0;
  return diffDays;
};

export default getNumberOfDays;
