import { useCallback, useContext, useEffect, useState } from 'react';
import getGridData from './api/getGridData';
import GqlClientContext from 'contexts/GqlClientContext';

const useGridDocs = ({
  dataType,
  filters,
  dateScope,
}: {
  dataType: string;
  filters: FilterInput;
  dateScope: DateRangeInput | undefined;
}) => {
  const { client } = useContext(GqlClientContext);
  const [docs, setDocs] = useState<ElasticDocument[]>([]);
  const [isMoreDocsAvailable, setIsMoreDocsAvailable] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getData = useCallback(async () => {
    if (!dateScope) {
      return;
    }

    const data = await getGridData({
      dataType,
      filters: [filters],
      dateScope,
      sortBy: [],
      client,
    });
    return data;
  }, [client, dataType, dateScope, filters]);

  useEffect(() => {
    let isActive = true;
    setIsLoading(true);
    getData().then((data) => {
      if (!isActive) {
        return;
      }

      if (!data) {
        return;
      }

      setDocs(() => data.documents);
      if (data.count > 500) {
        setIsMoreDocsAvailable(true);
      } else {
        setIsMoreDocsAvailable(false);
      }
      setIsLoading(false);
    });

    return () => {
      isActive = false;
    };
  }, [getData]);

  return { docs, isMoreDocsAvailable, isLoading };
};

export default useGridDocs;
