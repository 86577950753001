/* eslint-disable */
import React from 'react';
import useV5RollingTrendCard from '../../../../hooks/useV5RollingTrendCard';
import { TrendCardContent } from '../../../Gadget/TrendCard';
import useMetric from '../../../../hooks/useMetric';
import Loading from '../../../Loading';

const RollingTrendCardContainer = ({
  chartDefinition,
}: {
  chartDefinition: V5ChartDefinition;
}) => {
  const { trendItems } = useV5RollingTrendCard(chartDefinition);
  const metric = useMetric(chartDefinition.series[0].metricId);
  if (!metric) {
    return <Loading />;
  }
  const { positiveDeltaIsGood } = metric.formatting;

  return (
    <TrendCardContent
      items={trendItems}
      positiveDeltaIsGood={positiveDeltaIsGood}
      metricId={metric.id}
    />
  );
};

export default RollingTrendCardContainer;
