import * as Sentry from '@sentry/browser';
import { ServerError } from '@apollo/client/link/utils';
import { ServerParseError } from '@apollo/client/link/http';

const captureException = (error: Error | ServerError | ServerParseError) => {
  Sentry.withScope((scope) => {
    scope.setFingerprint([
      error.name && error.name !== '' ? error.name : error.message,
    ]);
    Sentry.captureException(error);
  });
};

export const addBreadCrumb = (breadcrumb: Sentry.Breadcrumb) => {
  Sentry.addBreadcrumb(breadcrumb);
};

export default captureException;
