import React, { useCallback, useContext, useEffect, useState } from 'react';
import TextCardForm from './TextCardForm';
import buildCardLayout from 'contextProviders/WidgetGalleryProvider/buildCardLayout';
import findStartingY from 'contextProviders/WidgetGalleryProvider/findStartingY';
import aguid from 'aguid';
import AccountPickerContext from '../../contexts/AccountPickerContext';

const TextCardFormContainer = ({
  close,
  isOpen,
  setCurrentCanvas,
  startEditing,
  canvasMode,
}: {
  close: () => void;
  isOpen: boolean;
  setCurrentCanvas: React.Dispatch<React.SetStateAction<Canvas>>;
  startEditing: () => void;
  canvasMode: CanvasMode;
}) => {
  const { accountRef } = useContext(AccountPickerContext);
  const [textContentId, setTextContentId] = useState<string>(aguid());
  const [text, setText] = useState<string>('');

  useEffect(() => {
    if (!isOpen) {
      setTextContentId(aguid());
      setText('');
    }
  }, [isOpen]);

  const onChange = useCallback(
    (content: string) => {
      if (process.env.NODE_ENV === 'test') {
        return;
      }

      setText(content);
      accountRef
        .collection('textCardContents')
        .doc(textContentId)
        .set({ textContent: content, isCenterAlign: false });
    },
    [accountRef, textContentId],
  );

  const onSave = useCallback(() => {
    setCurrentCanvas((c) => {
      const newCard = (() => {
        return {
          layout: buildCardLayout(0, findStartingY(c.cards, canvasMode)),
          content: {
            type: 'Text' as 'Text',
            textContentId,
          },
        };
      })();

      return {
        ...c,
        cards: [...c.cards, newCard],
      };
    });
    startEditing();
    close();
  }, [canvasMode, close, setCurrentCanvas, startEditing, textContentId]);

  return (
    <TextCardForm
      text={text}
      onChange={onChange}
      onSave={onSave}
      close={close}
      isOpen={isOpen}
    />
  );
};

export default TextCardFormContainer;
