import React, { useCallback } from 'react';
import ListElements from '../../../ListElements';
import Row from '../../../../../../components/Common/Row';
import Typography from 'kingpin/atoms/Typography';
import Button from 'kingpin/atoms/Button';

const FilterBy = ({
  setStep,
  close,
  isDatasetFilterDisabled,
}: {
  setStep: React.Dispatch<
    React.SetStateAction<'Filter by' | 'Datasets' | 'Status' | 'Usage'>
  >;
  close: () => void;
  isDatasetFilterDisabled: boolean;
}) => {
  const onDatasetsClicked = useCallback(() => {
    setStep('Datasets');
  }, [setStep]);
  const onStatusClicked = useCallback(() => {
    setStep('Status');
  }, [setStep]);
  const onUsageClicked = useCallback(() => {
    setStep('Usage');
  }, [setStep]);

  return (
    <div>
      <ListElements.ListDiv>
        <Row spaceBetween centerAlign>
          <Typography.Body type="Body 12">Filter By</Typography.Body>
          <Button onClick={close} icon="cross" type="Ghost" size="Small" />
        </Row>
        {!isDatasetFilterDisabled && (
          <ListElements.ListItem onClick={onDatasetsClicked}>
            <Typography.Body type="Body 12">Datasets</Typography.Body>
          </ListElements.ListItem>
        )}
        <ListElements.ListItem onClick={onStatusClicked}>
          <Typography.Body type="Body 12">Status</Typography.Body>
        </ListElements.ListItem>
        <ListElements.ListItem onClick={onUsageClicked}>
          <Typography.Body type="Body 12">Usage</Typography.Body>
        </ListElements.ListItem>
      </ListElements.ListDiv>
    </div>
  );
};

export default FilterBy;
