import React from 'react';

interface WorkSpaceContextType {
  refreshWorkSpace: () => void;
  workSpace?: WorkSpace;
  driverBonusWorkSpace?: DriverBonusWorkSpace;
  generalWorkSpace?: GeneralWorkSpace;
  customerLaneCommitmentsWorkSpace?: CustomerLaneCommitmentsWorkSpace;
  isCampaignOwner?: boolean;
  isBonusSummarySelected: boolean;
  onTabDeleted?: () => void;
  highlightTaskTab: () => void;
  isTaskTabHighlighted: boolean;
  selectedTab?: WorkSpaceTab;
}

const WorkSpaceContext = React.createContext<WorkSpaceContextType>({
  isBonusSummarySelected: false,
  refreshWorkSpace: () => {},
  highlightTaskTab: () => {},
  isTaskTabHighlighted: false,
});

export default WorkSpaceContext;
