import React from 'react';
import styled from 'styled-components';
import Typography from 'kingpin/atoms/Typography';
import { ReactComponent as SearchIcon } from '../images/search.svg';
import useEnabledEntities from '../hooks/useEnabledEntities';

const WrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 70px;
`;

const YouCanSearchFor = () => {
  const enabledEntities = useEnabledEntities();

  return (
    <div style={{ marginBottom: 16 }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <ul>
          {enabledEntities.map((e) => (
            <li key={e}>
              <Typography.Body type={'Label'}>{e}</Typography.Body>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const NoSearchInput = () => (
  <WrapperDiv>
    <div style={{ marginBottom: 16 }}>
      <SearchIcon width={52} height={52} />
    </div>
    <Typography.Header type="H5">
      Type something to start searching, You can search for
    </Typography.Header>
    <YouCanSearchFor />
  </WrapperDiv>
);

export default NoSearchInput;
