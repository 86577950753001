import useGetDatasetLastUpdatedAt from '../../hooks/dashboards/useGetDatasetLastUpdatedAt';
import { useEffect, useState } from 'react';

const useBaseViewsLastUpdatedAtLookup = (
  datasets: FleetOps.DatasetDefinition[],
) => {
  const getDatasetLastUpdatedAt = useGetDatasetLastUpdatedAt();
  const [baseViewsLastUpdatedAtLookup, setBaseViewsLastUpdatedAtLookup] =
    useState<{ [dataset: string]: number | undefined }>({});

  useEffect(() => {
    if (process.env.NODE_ENV === 'test') {
      return;
    }
    const promises = datasets.map(async (ds) => {
      const lastUpdate = await getDatasetLastUpdatedAt(ds.type);
      return {
        type: ds.type,
        lastUpdate,
      };
    });
    Promise.all(promises).then((result) => {
      const newLookup = {} as { [dataset: string]: number | undefined };
      result.forEach(({ type, lastUpdate }) => {
        newLookup[type] = lastUpdate;
      });
      setBaseViewsLastUpdatedAtLookup(newLookup);
    });
  }, [datasets, getDatasetLastUpdatedAt]);

  return baseViewsLastUpdatedAtLookup;
};

export default useBaseViewsLastUpdatedAtLookup;
