import { useCallback, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import useGetDatasetDefinition from 'hooks/useGetDatasetDefinition';
import isDefined from 'isDefined';
import useGetFieldLabel from 'hooks/useGetFieldLabel';
import PerformanceWizardContext from '../PerformanceWizardContext';

const useEntityWindowDateFieldOptions = () => {
  const { wizardState, setWizardState } = useContext(PerformanceWizardContext);
  const getDatasetDefinition = useGetDatasetDefinition();
  const { getFieldLabel } = useGetFieldLabel();
  const getEligibleFields = useCallback(
    (entityDataTypes: string[]) => {
      const datasets = entityDataTypes
        .map(getDatasetDefinition)
        .filter(isDefined);
      const allDateFields = datasets.reduce((dateFields, currentDataset) => {
        return [
          ...dateFields,
          ...currentDataset.fields.filter(
            (f) => f.type === 'date' && f.field !== 'recordTimeStamp',
          ),
        ];
      }, [] as FleetOps.Field[]);
      const commonDateFields = allDateFields
        .filter((dateField) => {
          return datasets.every((ds) =>
            ds.fields.some((f) => f.field === dateField.field),
          );
        })
        .map((f) => f.field);

      return _.uniq(commonDateFields);
    },
    [getDatasetDefinition],
  );

  const setEntityDateField = useCallback(
    (field: string) => {
      setWizardState((currentState) => ({
        ...currentState,
        config: {
          ...currentState.config,
          datasetStep: {
            ...currentState.config.datasetStep,
            entityWindowDateField: field,
          },
        },
      }));
    },
    [setWizardState],
  );

  const getOptions = useCallback((): DropdownOption[] => {
    const { entityDataTypes } = wizardState.config.datasetStep;
    if (!entityDataTypes || entityDataTypes.length === 0) {
      return [];
    }

    const fields = getEligibleFields(entityDataTypes);
    if (fields.length === 0) {
      return [];
    }

    return fields.map((field) => ({
      label: getFieldLabel({
        field,
        dataType: entityDataTypes[0],
      }),
      value: field,
      isSelected:
        wizardState.config.datasetStep.entityWindowDateField === field,
      onSelected: () => {
        setEntityDateField(field);
      },
    }));
  }, [
    getEligibleFields,
    getFieldLabel,
    setEntityDateField,
    wizardState.config.datasetStep,
  ]);

  const [options, setOptions] = useState<DropdownOption[]>(() => getOptions());
  useEffect(() => {
    setOptions(getOptions());
  }, [getOptions]);

  return options;
};

export default useEntityWindowDateFieldOptions;
